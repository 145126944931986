/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceTask
 */
export interface MaintenanceTask {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceTask
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceTask
     */
    taskTypeName: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceTask
     */
    dueDateLeftSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceTask
     */
    dueMileageLeftKilometers?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceTask
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceTask
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceTask
     */
    taskTypeCategory?: string;
}

export function MaintenanceTaskFromJSON(json: any): MaintenanceTask {
    return MaintenanceTaskFromJSONTyped(json, false);
}

export function MaintenanceTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'taskTypeName': json['taskTypeName'],
        'dueDateLeftSeconds': !exists(json, 'dueDateLeftSeconds') ? undefined : json['dueDateLeftSeconds'],
        'dueMileageLeftKilometers': !exists(json, 'dueMileageLeftKilometers') ? undefined : json['dueMileageLeftKilometers'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'monitoredObjectId': !exists(json, 'monitoredObjectId') ? undefined : json['monitoredObjectId'],
        'taskTypeCategory': !exists(json, 'taskTypeCategory') ? undefined : json['taskTypeCategory'],
    };
}

export function MaintenanceTaskToJSON(value?: MaintenanceTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'taskTypeName': value.taskTypeName,
        'dueDateLeftSeconds': value.dueDateLeftSeconds,
        'dueMileageLeftKilometers': value.dueMileageLeftKilometers,
        'userId': value.userId,
        'monitoredObjectId': value.monitoredObjectId,
        'taskTypeCategory': value.taskTypeCategory,
    };
}


