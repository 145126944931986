import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GetUserQuery } from 'generated/graphql';
import { TLangCodesISO6391 } from 'generated/main-data-api';
import { ConfigProvider, notification, message } from 'antd';
import i18next from 'i18next';
import { Conf, confDefault } from 'conf';

import { Logic } from 'logic/logic';
import { exponea } from 'logic/exponea';
import { Role } from 'logic/auth';
import NavBar from 'common/components/Navbar';
import { AddressIdentification, VehicleIdentification, Theme } from 'common/components/Settings';

import Map from 'modules/map/MapModule';
import TrackingModule from 'modules/tracking/TrackingModule';
import MessagingModule from 'modules/messaging/MessagingModule';
import RouteOverviewModule from 'modules/routing/route-overview/RouteOverviewModule';
import SuperAdminModule from 'modules/superadmin/SuperAdminModule';
import PlannerModule from 'modules/routing/planner/PlannerModule';
import PlannerNewModule from 'modules/routing/planner-new/PlannerModule';
import ManagementModule from 'modules/management/ManagementModule';
import PartnerModule from 'modules/partner/PartnerModule';
import StatisticsModule from 'modules/statistics/StatisticsModule';
import DispatcherBoardDetailModule from 'modules/dispatcher-board-detail/DispatcherBoardDetailModule';
import DispatcherBoardModule from 'modules/dispatcher-board/DispatcherBoardModule';

import AlarmsModule from './modules/alarms/AlarmsModule';
import { PartnerUserModel } from './logic/partner/logic/partner-user';
import { getLocale } from 'antd-locale';
import { Button } from 'common/components';
import { Locale } from 'antd/lib/locale-provider';
import DocsModule from 'modules/docs/DocsModule';
import { ControlPanel } from 'modules/map/components/MapControlsBar';
import MaintenanceNotificationsModule from 'modules/maintenance-notifications/MaintenanceNotificationsModule';
import { ChannelsResponse } from 'logic/messaging';
import ForbiddenPage from './common/components/ForbiddenPage';

interface Props extends WithTranslation {
    logic: Logic;
    embedded: boolean;
    embeddedMap?: boolean;
    theme?: Theme;
}

interface State {
    user?: GetUserQuery['get_User'];
    newUser?: PartnerUserModel;
    newMessageChannelIds?: string[];
    error?: string;
    addressIdentification: AddressIdentification;
    vehicleIdentification: VehicleIdentification;
    theme: Theme;
    settingsOpen: boolean;
    alarmsOpen: boolean;
    alarmsLength: number;
    maintenanceNotificationsOpen: boolean;
    maintenanceNotificationsLength: number;
    docsActive: boolean;
    messagesOpen: boolean;
    lang: string;
    demoModeActive: boolean;
}

const conf: Conf = { ...confDefault, ...((window as any).CONF as Conf) };
const logic = new Logic(conf);
export const LogicContext = React.createContext<Logic>(logic);

export enum RouteNames {
    DEFAULT = '/',
    ALARMS = '/alarms',
    TRACKING = '/tracking',
    SCHEDULING_ORDERS = '/scheduling/orders',
    SCHEDULING_PLANNER = '/scheduling/planner',
    SCHEDULING_PLANNER_NEW = '/scheduling/planner-new',
    SCHEDULING_DISPATCHER_BOARD = '/scheduling/dispatcher-board',
    SCHEDULING_DISPATCHER_BOARD_DETAIL = '/scheduling/dispatcher-board/detail',
    STATISTICS = '/statistics',
    STATISTICS_COMPANY_PROFILE = '/statistics/company-profile',
    STATISTICS_COMPANY_PROFILE_DASHBOARD = '/statistics/company-profile/dashboard',
    STATISTICS_COMPANY_PROFILE_COSTS = '/statistics/company-profile/costs',
    STATISTICS_COMPANY_PROFILE_TOTAL_COST_OF_OWNERSHIP = '/statistics/company-profile/total-cost-of-ownership',
    STATISTICS_JOURNEYS_ACTIVITY = '/statistics/journeys-activity',
    STATISTICS_AETR = '/statistics/aetr',
    STATISTICS_FUEL_CONSUMPTION = '/statistics/fuel-consumption',
    STATISTICS_EXPENSES = '/statistics/expenses',
    STATISTICS_BORDER_CROSSING = '/statistics/border-crossing/',
    STATISTICS_DRIVER_BEHAVIOR = '/statistics/driver-behavior',
    STATISTICS_DRIVER_BEHAVIOR_LIST = '/statistics/driver-behavior/list',
    STATISTICS_DRIVER_BEHAVIOR_DETAIL = '/statistics/driver-behavior/detail',
    STATISTICS_DRIVER_BEHAVIOR_VEHICLES = '/statistics/driver-behavior/vehicles',
    STATISTICS_DRIVER_BEHAVIOR_VEHICLES_LIST = '/statistics/driver-behavior/vehicles/list',
    STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL = '/statistics/driver-behavior/vehicles/detail',
    STATISTICS_DRIVER_BEHAVIOR_TRUCKS = '/statistics/driver-behavior/trucks',
    STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST = '/statistics/driver-behavior/trucks/list',
    STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL = '/statistics/driver-behavior/trucks/detail',
    STATISTICS_MAINTENANCE = '/statistics/maintenance',
    STATISTICS_ALLOWANCES = '/statistics/allowances',
    STATISTICS_ALLOWANCES_LIST = '/statistics/allowances/list',
    STATISTICS_ALLOWANCES_DRIVER_DETAIL = '/statistics/allowances/detail',
    STATISTICS_ALLOWANCES_DRIVER_DETAIL_PARAM = '/statistics/allowances/detail/:id',
    STATISTICS_STATISTIC_REPORT = '/statistics/statistic-report',
    STATISTICS_COLD_CHAIN = '/statistics/cold-chain',
    USER_SETTINGS_LANGUAGES = '/user-settings/languages',
    USER_SETTINGS_LOGOUT = '/user-settings/logout',
    USERS = '/users',
    SUPERADMIN = '/superadmin',
    MESSAGING = '/messaging',
    SETTINGS = '/settings',
    SETTINGS_FLEET = '/settings/fleet',
    SETTINGS_USERS = '/settings/users',
    SETTINGS_USERS_CREATE = '/settings/users-create',
    SETTINGS_ROLES = '/settings/roles',
    SETTINGS_FUEL_CARDS = '/settings/fuel-cards',
    SETTINGS_TACHO_CARDS = '/settings/tachograph-cards',
    SETTINGS_OBU = '/settings/obu',
    SETTINGS_DEVICES = '/settings/devices',
    SETTINGS_COMPANY = '/settings/company',
    SETTINGS_VEHICLES_REMOTE_MEMORY = '/settings/remote-memory-vehicles',
    SETTINGS_DRIVER_CARDS_REMOTE_MEMORY = '/settings/remote-memory-driver-cards',
    SETTINGS_CUSTOM_PLACES = '/settings/custom-places',
    PARTNER = '/partner',
    PARTNER_PARTNERS = '/partner/partners',
    PARTNER_COMPANIES = '/partner/companies',
    PARTNER_VEHICLES = '/partner/vehicles',
    PARTNER_OBU = '/partner/obu',
    PARTNER_CCR = '/partner/ccr',

    PARTNER_INVOICE = '/partner/invoice',
    PARTNER_USERS = '/partner/users',
    PARTNER_WHITELABEL = '/partner/whitelabel',
    DOCS = '/docs',
    SETTINGS_CUSTOMER_API = '/settings/customer-api',
    SETTINGS_CUSTOMER_API_INTRODUCTION = '/settings/customer-api/introduction',
    SETTINGS_CUSTOMER_API_REFERENCE = '/settings/customer-api/reference',
    SETTINGS_SYSTEM_CONNECTIONS = '/settings/system-connections',
    SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS = '/settings/system-connections/customer-access',
    SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API = '/settings/system-connections/customer-api',
    SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS = '/settings/system-connections/other-systems',
    SETTINGS_CONTACT_LIST = '/settings/contacts'
}

export const rbac = (roles: Role[], rolesAllowed: Role[], module: JSX.Element) => (): JSX.Element => {
    if (rolesAllowed.some(r => roles.includes(r))) {
        return module;
    } else {
        return <ForbiddenPage type="403" availableRoles={roles} />;
    }
};

//@ts-ignore
export const permissionsRouteMap: Record<RouteNames, Role[]> = {
    [RouteNames.TRACKING]: [Role.LM_R, Role.LT_R],
    [RouteNames.SCHEDULING_PLANNER]: [Role.PLN_R],
    [RouteNames.SCHEDULING_PLANNER_NEW]: [Role.PLN_R_2],
    [RouteNames.SCHEDULING_ORDERS]: [Role.ROW_R],
    [RouteNames.SCHEDULING_DISPATCHER_BOARD]: [Role.BRD_R],
    [RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL]: [Role.ROW_R, Role.BRD_R],
    [RouteNames.PARTNER]: [Role.PM_R],
    [RouteNames.STATISTICS_COMPANY_PROFILE]: [Role.CP_R, Role.DBHD_R],
    [RouteNames.STATISTICS_JOURNEYS_ACTIVITY]: [Role.JA_R],
    [RouteNames.STATISTICS_FUEL_CONSUMPTION]: [Role.FC_R],
    [RouteNames.STATISTICS_AETR]: [Role.AEI_R],
    [RouteNames.STATISTICS_EXPENSES]: [Role.EX_R],
    [RouteNames.STATISTICS_DRIVER_BEHAVIOR]: [Role.DBH_R],
    [RouteNames.STATISTICS_BORDER_CROSSING]: [Role.BC_R],
    [RouteNames.STATISTICS_MAINTENANCE]: [Role.MTN_R],
    [RouteNames.STATISTICS_ALLOWANCES]: [Role.DIT_R],
    [RouteNames.STATISTICS_STATISTIC_REPORT]: [Role.FC_R],
    [RouteNames.STATISTICS_COLD_CHAIN]: [Role.CLD_R],
    [RouteNames.SETTINGS_FLEET]: [Role.V_R],
    [RouteNames.SETTINGS_USERS]: [Role.USR_R],
    [RouteNames.SETTINGS_ROLES]: [Role.USR_W],
    [RouteNames.SETTINGS_FUEL_CARDS]: [Role.EX_R],
    [RouteNames.SETTINGS_TACHO_CARDS]: [Role.TC_R],
    [RouteNames.SETTINGS_OBU]: [Role.V_R],
    [RouteNames.SETTINGS_DEVICES]: [Role.V_R],
    [RouteNames.SETTINGS_COMPANY]: [Role.V_R],
    [RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY]: [Role.CRD_R],
    [RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY]: [Role.TRD_R],
    [RouteNames.SETTINGS_CUSTOM_PLACES]: [Role.POI_R],
    [RouteNames.SETTINGS_SYSTEM_CONNECTIONS]: [Role.CA_R, Role.CAC_R, Role.OAC_R, Role.PUESC],
    [RouteNames.SETTINGS_CONTACT_LIST]: [Role.CL_R]
};

export function useLogic() {
    return React.useContext(LogicContext);
}

export type WithLogic = {
    logic: Logic;
};

export function withLogicContext<P extends WithLogic>(
    Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithLogic>> {
    return function BoundComponent(props: Omit<P, keyof WithLogic>) {
        return (
            <LogicContext.Consumer>
                {value => <Component {...(props as unknown as P)} logic={value} />}
            </LogicContext.Consumer>
        );
    };
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            addressIdentification: this.props.logic.settings().getProp('addressIdentification'),
            vehicleIdentification: this.props.logic.settings().getProp('vehicleIdentification'),
            theme: props.theme ?? this.props.logic.settings().getProp('theme'),
            settingsOpen: false,
            lang: this.props.logic.auth().user().lang,
            alarmsOpen: false,
            alarmsLength: 0,
            maintenanceNotificationsOpen: false,
            maintenanceNotificationsLength: 0,
            docsActive: false,
            messagesOpen: false,
            demoModeActive: this.props.logic.demo().isActive
        };
        this._setWhitelabel();
        this._setTheme(props.theme ?? this.props.logic.settings().getProp('theme'));
        this.props.logic.ga().pageview(window.location.hash);
        this.props.logic.exponea().pageview(window.location.hash);

        window.addEventListener('popstate', () => {
            this.props.logic.ga().pageview(window.location.hash);
            if (window.location.hash.indexOf('?') === -1) {
                this.props.logic.exponea().pageview(window.location.hash);
            }
        });
    }

    componentDidMount() {
        const roles = this.props.logic.auth().roles();

        if ([Role.LMM_R, Role.LMT_R]?.some(r => roles.includes(r))) {
            if (this.props.logic.demo().isActive) {
                const unreadChannels = this.props.logic
                    .demo()
                    .data.messaging.channels.channels.filter(channel => channel.channel.newMsgCount > 0)
                    .map(channel => channel.channel.id);

                this.setState({
                    newMessageChannelIds: unreadChannels
                });
            } else {
                this.props.logic
                    .messaging()
                    .channels()
                    .then(res => {
                        const newMsgChannels = res.channels.filter(c => c.newMsgCount > 0);
                        const newMessageChannelIds = newMsgChannels.map(c => c.id);
                        this.setState({
                            newMessageChannelIds
                        });
                    });
            }
        }

        if (roles.includes(Role.IA_R)) {
            if (this.props.logic.demo().isActive) {
                this.props.logic.alarms().init();
                this.setState({
                    alarmsLength: this.props.logic.alarms().alarms.length
                });
            } else {
                this.props.logic.alarms().alarmsUpdates.subscribe(() => {
                    this.setState({
                        alarmsLength:
                            this.props.logic.alarms()?.getInstantAndGeolocationAlarmsNotAcknowledged().length ?? 0
                    });
                });
            }
        }

        roles.includes(Role.MTN_N) &&
            this.props.logic.maintenanceNotifications().maintenanceNotificationsUpdates.subscribe(() => {
                this.setState({
                    maintenanceNotificationsLength:
                        this.props.logic.maintenanceNotifications()?.getAllMaintenanceNotifications().length ?? 0
                });
            });

        this.props.logic.map().setLoading(true);
        this.props.logic.map().setFuelLoading(true);
        this.props.logic.map().setParkingLoading(true);
        this.props.logic.map().setWashLoading(true);
        this.props.logic.map().setPoiLoading(true);

        this.props.logic.map().setControlsDisabled(true);

        roles.includes(Role.OP_R) && this.props.logic.poi().init();

        const promisesFuelStations = roles.includes(Role.FST_R)
            ? [
                  this.props.logic.poi().fuelStations(),
                  roles.includes(Role.OP_R)
                      ? this.props.logic
                            .poi()
                            .exchangeRate()
                            .then(_ => this.props.logic.poi().fuelStationsPrices())
                      : undefined
              ]
            : undefined;
        const promiseParking = roles.includes(Role.FST_R) ? this.props.logic.poi().parkings() : undefined;
        const promiseWashers = roles.includes(Role.TRVS) ? this.props.logic.poi().washers() : undefined;
        const promisePois = roles.includes(Role.OPOI_R) ? this.props.logic.poi().pois() : undefined;

        promisesFuelStations &&
            Promise.all(promisesFuelStations).then(() => {
                this.props.logic.map().setControlsDisabled(false, ControlPanel.FUEL);
                this.props.logic.map().setFuelLoading(false);
            });

        promiseParking?.then(() => {
            this.props.logic.map().setControlsDisabled(false, ControlPanel.PARKING);
            this.props.logic.map().setParkingLoading(false);
        });

        promiseWashers?.then(() => {
            this.props.logic.map().setControlsDisabled(false, ControlPanel.WASH);
            this.props.logic.map().setWashLoading(false);
        });

        promisePois?.then(() => {
            this.props.logic.map().setControlsDisabled(false, ControlPanel.POI);
            this.props.logic.map().setPoiLoading(false);
        });
    }

    componentWillUnmount() {
        this.props.logic.messaging().destroy();
    }

    render() {
        const { logic } = this.props;
        const roles: Role[] = logic.auth().roles();
        const locale: Locale = getLocale(this.state.lang);

        return (
            <LogicContext.Provider value={logic}>
                <Router>
                    <ConfigProvider locale={locale}>
                        {!this.props.embedded && (
                            <>
                                <NavBar
                                    logic={logic}
                                    newMessagesLength={
                                        this.state.newMessageChannelIds ? this.state.newMessageChannelIds.length : 0
                                    }
                                    addressIdentification={this.state.addressIdentification}
                                    vehicleIdentification={this.state.vehicleIdentification}
                                    theme={this.state.theme}
                                    settingsVisible={this.state.settingsOpen}
                                    lang={this.state.lang}
                                    newAlarmsLength={this.state.alarmsLength}
                                    demoMode={this.state.demoModeActive}
                                    alarmsActive={this.state.alarmsOpen}
                                    maintenanceNotificationsActive={this.state.maintenanceNotificationsOpen}
                                    newMaintenanceNotificationsLength={this.state.maintenanceNotificationsLength}
                                    messagesActive={this.state.messagesOpen}
                                    docsActive={this.state.docsActive}
                                    companyName={logic.auth().client()?.name ?? undefined}
                                    onMessaging={this._onMessaging}
                                    onLogout={this._onLogout}
                                    onLangChange={this._onLangChange}
                                    onAddressIdentificationChange={this._onAddressIdentificationChange}
                                    onVehicleIdentificationChange={this._onVehicleIdentificationChange}
                                    onThemeChange={this._onThemeChange}
                                    onSettingsToggle={this._onSettingsToggle}
                                    onAlarmsClick={this._onAlarmsClick}
                                    onMaintenanceNotificationsClick={this._onMaintenanceNotificationsClick}
                                    onDocsClick={this._onDocsClick}
                                    onDemoModeToggle={this._onDemoModeToggle}
                                    onCompanyNameCopy={this._onCompanyNameCopy}
                                />

                                <MessagingModule
                                    logic={logic}
                                    onMessage={this._onMessage}
                                    onMessageSeen={this._onMessageSeen}
                                    onMessageUnsee={this._onMessageUnsee}
                                />
                                {roles.includes(Role.MTN_N) && (
                                    <MaintenanceNotificationsModule
                                        logic={logic}
                                        open={this.state.maintenanceNotificationsOpen}
                                        onTaskOpenClick={this._onMaintenanceNotificationsClick}
                                    />
                                )}
                                {roles.includes(Role.IA_R) && (
                                    <AlarmsModule
                                        logic={logic}
                                        open={this.state.alarmsOpen}
                                        onClose={this._onAlarmsClose}
                                    />
                                )}
                            </>
                        )}
                        {this.state.docsActive && <DocsModule logic={logic} onClose={this._onDocsClick} />}
                    </ConfigProvider>

                    {!this.props.embedded || this.props.embeddedMap ? (
                        <Map logic={logic} embedded={this.props.embedded} />
                    ) : null}

                    <ConfigProvider locale={locale}>
                        <Switch>
                            {this.props.logic.auth().impersonator() && (
                                <Route
                                    path={RouteNames.ALARMS}
                                    render={rbac(
                                        roles,
                                        [Role.IA_R],
                                        <AlarmsModule
                                            logic={logic}
                                            renderTable
                                            open={this.state.alarmsOpen}
                                            onClose={this._onAlarmsClose}
                                        />
                                    )}
                                />
                            )}
                            <Route
                                path={RouteNames.TRACKING}
                                render={rbac(roles, [Role.LM_R, Role.LT_R], <TrackingModule logic={logic} />)}
                            />
                            <Route
                                exact
                                path={RouteNames.SCHEDULING_PLANNER}
                                render={rbac(
                                    roles,
                                    [Role.PLN_R, Role.PLN_R_2],
                                    logic.conf.settings.plannerOldAvailable ? (
                                        <PlannerModule logic={logic} />
                                    ) : (
                                        <PlannerNewModule logic={logic} />
                                    )
                                )}
                            />
                            {logic.conf.settings.plannerOldAvailable && (
                                <Route
                                    exact
                                    path={RouteNames.SCHEDULING_PLANNER_NEW}
                                    render={rbac(roles, [Role.PLN_R_2], <PlannerNewModule logic={logic} />)}
                                />
                            )}
                            <Route
                                exact
                                path={RouteNames.SCHEDULING_ORDERS}
                                render={rbac(
                                    roles,
                                    [Role.ROW_R],
                                    <RouteOverviewModule logic={logic} embedded={this.props.embedded} />
                                )}
                            />
                            <Route
                                exact
                                path={RouteNames.SCHEDULING_DISPATCHER_BOARD}
                                render={rbac(roles, [Role.BRD_R], <DispatcherBoardModule />)}
                            />
                            <Route
                                exact
                                path={RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL}
                                render={rbac(
                                    roles,
                                    [Role.ROW_R, Role.BRD_R],
                                    <DispatcherBoardDetailModule logic={logic} />
                                )}
                            />
                            <Route
                                path={RouteNames.SETTINGS}
                                render={() => <ManagementModule embedded={this.props.embedded} logic={logic} />}
                            />
                            <Route
                                path={RouteNames.STATISTICS}
                                render={() => <StatisticsModule embedded={this.props.embedded} logic={logic} />}
                            />
                            <Route
                                path={RouteNames.SUPERADMIN}
                                render={() =>
                                    logic.auth().superadmin() ? (
                                        <SuperAdminModule logic={logic} />
                                    ) : (
                                        <ForbiddenPage type="403" availableRoles={roles} />
                                    )
                                }
                            />
                            <Route
                                path={RouteNames.PARTNER}
                                render={rbac(roles, [Role.PM_R], <PartnerModule logic={logic} />)}
                            />
                            <Route exact path={RouteNames.DOCS} render={() => <DocsModule logic={logic} />} />
                            <Route
                                exact
                                path={RouteNames.DEFAULT}
                                render={() => {
                                    let redirectRoute: RouteNames = RouteNames.TRACKING;
                                    Object.keys(permissionsRouteMap).every(route => {
                                        if (permissionsRouteMap[route as RouteNames].some(r => roles.includes(r))) {
                                            redirectRoute = route as RouteNames;
                                            return false;
                                        }
                                        return true;
                                    });
                                    return <Redirect to={redirectRoute} />;
                                }}
                            />
                            <Route path="*" render={() => <ForbiddenPage type="404" availableRoles={roles} />} />
                        </Switch>
                        {this.state.error && (
                            <div className="t-card t-small t-center t-default">
                                <p>{this.state.error}</p>
                            </div>
                        )}
                    </ConfigProvider>
                </Router>
            </LogicContext.Provider>
        );
    }

    private _onDocsClick = () => {
        this.setState(state => ({ docsActive: !state.docsActive }));
    };

    private _onAlarmsClick = () => {
        this.setState(state => ({ alarmsOpen: !state.alarmsOpen }));
    };

    private _onMaintenanceNotificationsClick = () => {
        this.setState(state => ({ maintenanceNotificationsOpen: !state.maintenanceNotificationsOpen }));
    };

    private _onAlarmsClose = () => {
        this.setState({ alarmsOpen: false });
    };

    private _onDemoModeToggle = (demoModeActive: boolean) => {
        this.setState({ demoModeActive }, () => {
            if (demoModeActive) {
                this.props.logic.demo().enable();
            } else {
                this.props.logic.demo().disable();
            }
        });

        this.props.logic.exponea().trackEvent(exponea.module.userSettings, {
            status: exponea.status.actionTaken,
            action: demoModeActive ? exponea.action.showDemo : exponea.action.hideDemo
        });
    };

    private _onCompanyNameCopy = () => {
        message.info(this.props.t('settings.companyNameCopied'), 2);
    };

    private _onLogout = async () => {
        try {
            await this.props.logic.auth().logout();
        } catch (err) {
            notification.error({
                message: this.props.t('Notifications.logoutError'),
                description: (
                    <Button
                        type="primary"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {this.props.t('common.refreshPage')}
                    </Button>
                ),
                duration: null, // with null will never close automatically
                placement: 'bottomRight'
            });
            console.log('Keycloack logout err: ', err);
        }
    };

    private _onLangChange = (lang: string) => {
        this.setState({ lang: lang }, () => {
            i18next.changeLanguage(lang).then(() => {
                this.props.logic
                    .api()
                    .userApi.updateUser({
                        userUpdateBody: {
                            language: lang as TLangCodesISO6391
                        }
                    })
                    .then(resp => {
                        console.info('[MAIN_DATA_NEW] changed user:', resp);
                        this.props.logic
                            .auth()
                            .updateToken(-1)
                            .then(_e => {
                                console.info('[SSO UPDATE TOKEN] token:', this.props.logic.auth().user());
                                this.props.logic.settings().setProp('lang', lang);
                                this.props.logic.exponea().trackEvent(exponea.module.userSettings, {
                                    status: exponea.status.actionTaken,
                                    action: exponea.action.selectLanguage,
                                    language: lang
                                });
                                this.props.logic.exponea().updateAttributes({
                                    language: lang
                                });
                            });
                    });
            });
        });
    };

    private _onMessaging = () => {
        this.props.logic.messaging().activeChange();
        this.setState({
            messagesOpen: this.props.logic.messaging().active()
        });
    };

    private _onMessage = async (): Promise<ChannelsResponse> => {
        return this._onMessageIncomming();
    };

    private _onMessageSeen = async (): Promise<ChannelsResponse> => {
        return this._onMessageIncomming();
    };
    private _onMessageUnsee = async (): Promise<ChannelsResponse> => {
        return this._onMessageIncomming();
    };

    private _onMessageIncomming = async (): Promise<ChannelsResponse> => {
        const channels = await this.props.logic.messaging().channels();
        const newMsgChannels = channels.channels.filter(c => c.newMsgCount > 0);
        const newMessageChannelIds = newMsgChannels.map(c => c.id);
        this.setState({
            newMessageChannelIds
        });
        return channels;
    };

    private _onAddressIdentificationChange = (setting: AddressIdentification) => {
        this.props.logic.settings().setProp('addressIdentification', setting);
        this.setState({
            addressIdentification: this.props.logic.settings().getProp('addressIdentification')
        });
        this.props.logic.exponea().trackEvent(exponea.module.userSettings, {
            status: exponea.status.actionTaken,
            action: setting
        });
    };

    private _onVehicleIdentificationChange = (setting: VehicleIdentification) => {
        this.props.logic.settings().setProp('vehicleIdentification', setting);
        this.setState({
            vehicleIdentification: this.props.logic.settings().getProp('vehicleIdentification')
        });
        this.props.logic.exponea().trackEvent(exponea.module.userSettings, {
            status: exponea.status.actionTaken,
            action: setting
        });
    };

    private _onThemeChange = (setting: Theme) => {
        const previousTheme = this.props.logic.settings().getProp('theme');
        this.props.logic.settings().setProp('theme', setting);

        this.setState(
            {
                theme: this.props.logic.settings().getProp('theme')
            },
            () => {
                const actualStyle = document.createElement('link');
                actualStyle.rel = 'stylesheet';
                actualStyle.type = 'text/css';
                actualStyle.id = setting;
                actualStyle.href = confDefault.settings.themes.find(t => t.name === setting)?.path ?? '';
                document.head.appendChild(actualStyle);

                setTimeout(function () {
                    const previousStyle = document.getElementById(previousTheme);
                    if (previousStyle) {
                        previousStyle.remove();
                    }
                }, 1000);
            }
        );
    };

    private _setTheme = (actual: Theme) => {
        const defaultTheme = confDefault.settings.theme;

        if (actual !== defaultTheme) {
            const linkActual = document.createElement('link');
            linkActual.rel = 'stylesheet';
            linkActual.type = 'text/css';
            linkActual.id = actual;
            linkActual.href = confDefault.settings.themes.find(t => t.name === actual)?.path ?? '';
            document.head.appendChild(linkActual);

            const defaultStyles = document.getElementById(defaultTheme);
            if (defaultStyles) {
                defaultStyles.remove();
            }
        }

        confDefault.settings.themes.forEach(t => {
            const themeAssetId = `theme-prefetch-${t.name}`;
            if (!document.getElementById(themeAssetId)) {
                const linkPrefetch = document.createElement('link');
                linkPrefetch.rel = 'prefetch';
                linkPrefetch.type = 'text/css';
                linkPrefetch.id = themeAssetId;
                linkPrefetch.href = t.path;
                document.head.appendChild(linkPrefetch);
            }
        });
    };

    private _onSettingsToggle = () => {
        if (!this.state.settingsOpen) {
            this.props.logic.exponea().trackEvent(exponea.module.userSettings, {
                status: exponea.status.screenShown
            });
        }
        this.setState(state => ({ settingsOpen: !state.settingsOpen }));
    };

    private _setWhitelabel = () => {
        Promise.all([
            this.props.logic.partner().whitelabel().getWhitelabel(),
            this.props.logic.auth().loadNewClient()
        ]).then(res => {
            const [whitelabel, newClient] = res;
            if (whitelabel[0]?.content) {
                Object.keys(whitelabel[0].content).forEach(t => {
                    document.body.style.setProperty(`--${t}`, whitelabel[0]?.content?.[t] ?? '');
                });
            }
            const logo = document.querySelector('.navbar-logo div.logo') as HTMLDivElement;
            if (newClient?.logo !== '' && logo !== null) {
                logo.style.backgroundImage = `url("${newClient?.logo}")`;
            }
        });
    };
}

export default withTranslation()(App);
