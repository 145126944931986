/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoredObjectGroup
 */
export interface WriteOnlyMonitoredObjectGroup {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectGroup
     */
    color?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectGroup
     */
    monitoredObjectGroupType?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyMonitoredObjectGroup
     */
    automaticVehicleAdd?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectGroup
     */
    clientId?: number;
}

export function WriteOnlyMonitoredObjectGroupFromJSON(json: any): WriteOnlyMonitoredObjectGroup {
    return WriteOnlyMonitoredObjectGroupFromJSONTyped(json, false);
}

export function WriteOnlyMonitoredObjectGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoredObjectGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'monitoredObjectGroupType': !exists(json, 'monitored_object_group_type') ? undefined : json['monitored_object_group_type'],
        'automaticVehicleAdd': !exists(json, 'automatic_vehicle_add') ? undefined : json['automatic_vehicle_add'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function WriteOnlyMonitoredObjectGroupToJSON(value?: WriteOnlyMonitoredObjectGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color': value.color,
        'monitored_object_group_type': value.monitoredObjectGroupType,
        'automatic_vehicle_add': value.automaticVehicleAdd,
        'client_id': value.clientId,
    };
}


