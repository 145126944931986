import { DATE_FORMAT, TIME_FORMAT_SHORT } from 'domain-constants';
import { LoadingInformation, Availability } from 'generated/dff-api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as icons from 'resources/images/dispatcher-board';

interface Props {
    places: LoadingInformation[];
}

const DFFProposalDetailRoute = (props: Props) => {
    const [origin, setOrigin] = useState<LoadingInformation>();
    const [destination, setDestination] = useState<LoadingInformation>();
    const [placeCount, setPlaceCount] = useState(0);

    useEffect(() => {
        if (props.places.length > 1) {
            const places = [...props.places];

            setOrigin(places.shift());
            setDestination(places.pop());
            setPlaceCount(places.length);
        }
    }, [props.places]);

    const loadingAvailableLabel = (availability: Availability) => {
        const availableFrom = moment(availability.availableFrom);
        const availableUntil = moment(availability.availableUntil);

        return `${availableFrom.format(DATE_FORMAT)} ${availableFrom.format(
            TIME_FORMAT_SHORT
        )} - ${availableUntil.format(DATE_FORMAT)} ${availableUntil.format(TIME_FORMAT_SHORT)}`;
    };

    return (
        <>
            {origin && destination && (
                <div className="dff-proposal-detail-route">
                    <div className="dff-proposal-detail-route-place">
                        <img className="dff-proposal-detail-route-place-icon" src={icons.load} alt="loading" />

                        <div className="dff-proposal-detail-route-place-location">
                            <div className="dff-proposal-detail-route-place-location-address">
                                {`(${origin.location.address.countryCode}) ${origin.location.address.postalCode} ${origin.location.address.city}, ${origin.location.address.street}`}
                            </div>
                            <div className="dff-proposal-detail-route-place-location-date">
                                {loadingAvailableLabel(origin.loadingWindow)}
                            </div>
                        </div>

                        <div className="dff-proposal-detail-route-place-country">
                            <span
                                className={`flag-icon flag-icon-squared flag-icon-${origin.location.address.countryCode.toLowerCase()}`}
                            />
                        </div>
                    </div>

                    {placeCount > 0 && (
                        <>
                            <img
                                className="dff-proposal-detail-route-place-arrow"
                                src={icons.proposalArrowRight}
                                alt="route"
                            />

                            <div className="dff-proposal-detail-route-place count">
                                <span>{placeCount}x</span>
                                <img
                                    className="dff-proposal-detail-route-place-icon"
                                    src={icons.unload}
                                    alt="loading"
                                />
                            </div>
                        </>
                    )}

                    <img className="dff-proposal-detail-route-place-arrow" src={icons.proposalArrowRight} alt="route" />

                    <div className="dff-proposal-detail-route-place">
                        <img className="dff-proposal-detail-route-place-icon" src={icons.unload} alt="loading" />

                        <div className="dff-proposal-detail-route-place-location">
                            <div className="dff-proposal-detail-route-place-location-address">
                                {`(${destination.location.address.countryCode}) ${destination.location.address.postalCode} ${destination.location.address.city}, ${destination.location.address.street}`}
                            </div>
                            <div className="dff-proposal-detail-route-place-location-date">
                                {loadingAvailableLabel(destination.loadingWindow)}
                            </div>
                        </div>

                        <div className="dff-proposal-detail-route-place-country">
                            <span
                                className={`flag-icon flag-icon-squared flag-icon-${destination.location.address.countryCode.toLowerCase()}`}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DFFProposalDetailRoute;
