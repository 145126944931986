/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportEventRuleConfig,
    TransportEventRuleConfigFromJSON,
    TransportEventRuleConfigFromJSONTyped,
    TransportEventRuleConfigToJSON,
} from './';

/**
 * { name: transport_corridor_leave, config: [{ name: distance, value: 1000  }] } in meters { name: transport_poi_arrival, config: [] } { name: transport_poi_departure, config: [] } { name: transport_poi_close, config: [{ name: duration, value: 600  }] } in seconds
 * @export
 * @interface TransportEventRule
 */
export interface TransportEventRule {
    /**
     * 
     * @type {string}
     * @memberof TransportEventRule
     */
    name?: string;
    /**
     * 
     * @type {Array<TransportEventRuleConfig>}
     * @memberof TransportEventRule
     */
    config?: Array<TransportEventRuleConfig>;
}

export function TransportEventRuleFromJSON(json: any): TransportEventRule {
    return TransportEventRuleFromJSONTyped(json, false);
}

export function TransportEventRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportEventRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'config': !exists(json, 'config') ? undefined : ((json['config'] as Array<any>).map(TransportEventRuleConfigFromJSON)),
    };
}

export function TransportEventRuleToJSON(value?: TransportEventRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'config': value.config === undefined ? undefined : ((value.config as Array<any>).map(TransportEventRuleConfigToJSON)),
    };
}


