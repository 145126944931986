import {
    MaintenanceTaskSummaryList,
    NotificationReceiversFromJSON,
    ReadOnlyMaintenanceTaskFromJSON,
    TaskTypeCategoryEnum,
    TaskTypeToJSON
} from 'generated/new-main';
import moment from 'moment';
import { trailers, vehicles } from './vehicles';
import { dispatchers, users } from './users';
import { vehicleStates } from './vehicles-states';

const dispatcher = dispatchers.find(user => user.id === 1001);
const receivers = [
    {
        entity: 'user',
        entity_id: dispatcher?.id,
        channel: 'email',
        name: dispatcher?.contact?.['emails']
    },
    {
        entity: 'user',
        entity_id: dispatcher?.id,
        channel: 'app',
        name: dispatcher?.name + ' ' + dispatcher?.surname
    }
];

export const taskTypes = [
    {
        id: '1',
        name: 'Annual taxation',
        category: TaskTypeCategoryEnum.Company,
        created_at: moment().subtract(22, 'month').toISOString(),
        deleted_at: null,
        repetition_due_date_type: 'year',
        repetition_due_date_value: 1,
        deleted: 0,
        customizable: true
    },
    {
        id: '2',
        name: 'Oil check',
        category: TaskTypeCategoryEnum.Vehicle,
        created_at: moment().subtract(12, 'month').toISOString(),
        deleted_at: null,
        repetition_due_date_type: 'year',
        repetition_due_date_value: 1,
        deleted: 0,
        customizable: true
    },
    {
        id: '3',
        name: 'Axle replacement',
        category: TaskTypeCategoryEnum.Trailer,
        created_at: moment().subtract(12, 'month').toISOString(),
        deleted_at: null,
        repetition_due_date_type: null,
        repetition_due_date_value: 5,
        deleted: 0,
        customizable: true
    },
    {
        id: '4',
        name: 'Company education',
        category: TaskTypeCategoryEnum.Driver,
        created_at: moment().subtract(12, 'month').toISOString(),
        deleted_at: null,
        repetition_due_date_type: null,
        repetition_due_date_value: null,
        deleted: 0,
        customizable: true
    }
];

export const dataMaintenanceTasks = [
    // VEHICLE overdue
    {
        id: '1',
        task_type: taskTypes[1],
        monitored_object: vehicles[2].id,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: (vehicleStates[2].odometer ?? 0) / 1000 - 3065,
        due_date: moment().add(-7, 'days').toISOString(),
        repetition_due_date_type: taskTypes[1].repetition_due_date_type,
        repetition_due_date_value: taskTypes[1].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: 40000,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: 500,
        notification_recievers: receivers,
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completion_time: null,
        completion_mileage_kilometers: null,
        completed_by: null,
        deleted: 0,
        state: 'overdue',
        due_date_left_seconds: 3600 * 24 * 7,
        due_mileage_left_kilometers: 3065,
        expenses: {
            price_total_wo_vat: 130,
            vat: 20,
            currency: 'EUR',
            items: [
                {
                    name: 'Oil - 5 liters',
                    price_wo_vat: 80,
                    type: 'part'
                },
                {
                    name: 'Servis',
                    price_wo_vat: 50,
                    type: 'labour'
                }
            ]
        },
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: `Very important`,
        client: 100
    },
    // VEHICLE duesoon
    {
        id: '2',
        task_type: taskTypes[1],
        monitored_object: vehicles[1].id,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: 550000,
        due_date: moment().add(10, 'days').toISOString(),
        repetition_due_date_type: taskTypes[1].repetition_due_date_type,
        repetition_due_date_value: taskTypes[1].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: 40000,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: 500,
        notification_recievers: receivers,
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completion_time: null,
        completion_mileage_kilometers: null,
        completed_by: null,
        deleted: 0,
        state: 'duesoon',
        due_date_left_seconds: 3600 * 24 * 10,
        due_mileage_left_kilometers: null,
        expenses: {
            price_total_wo_vat: 110,
            vat: 20,
            currency: 'EUR',
            items: [
                {
                    name: 'Oil - 5 liters',
                    price_wo_vat: 60,
                    type: 'part'
                },
                {
                    name: 'Servis',
                    price_wo_vat: 50,
                    type: 'labour'
                }
            ]
        },
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: `Very important`,
        client: 100
    },
    // Company - ACTIVE
    {
        id: '3',
        task_type: taskTypes[0],
        monitored_object: null,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: null,
        due_date: moment().add(2, 'months').toISOString(),
        repetition_due_date_type: taskTypes[0].repetition_due_date_type,
        repetition_due_date_value: taskTypes[0].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: null,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: null,
        notification_recievers: receivers,
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completion_time: null,
        completion_mileage_kilometers: null,
        completed_by: null,
        deleted: 0,
        state: 'active',
        due_date_left_seconds: 3600 * 24 * 61,
        due_mileage_left_kilometers: null,
        expenses: {},
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: null,
        client: 100
    },
    // Driver - ACTIVE
    {
        id: '4',
        task_type: taskTypes[3],
        monitored_object: null,
        user: users[0].id,
        place: null,
        contact: null,
        due_mileage_kilometers: null,
        due_date: moment().add(136, 'days').toISOString(),
        repetition_due_date_type: taskTypes[3].repetition_due_date_type,
        repetition_due_date_value: taskTypes[3].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: null,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: null,
        notification_recievers: receivers,
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completion_time: null,
        completion_mileage_kilometers: null,
        completed_by: null,
        deleted: 0,
        state: 'active',
        due_date_left_seconds: 3600 * 24 * 136,
        due_mileage_left_kilometers: null,
        expenses: {},
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: null,
        client: 100
    },
    // VEHICLE active
    {
        id: '5',
        task_type: taskTypes[1],
        monitored_object: vehicles[0].id,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: 150000,
        due_date: moment().add(2, 'months').toISOString(),
        repetition_due_date_type: taskTypes[1].repetition_due_date_type,
        repetition_due_date_value: taskTypes[1].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: 40000,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: 500,
        notification_recievers: receivers,
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completion_time: null,
        completion_mileage_kilometers: null,
        completed_by: null,
        deleted: 0,
        state: 'active',
        due_date_left_seconds: 3600 * 24 * 60,
        due_mileage_left_kilometers: null,
        expenses: {
            price_total_wo_vat: 110,
            vat: 20,
            currency: 'EUR',
            items: [
                {
                    name: 'Oil - 5 liters',
                    price_wo_vat: 60,
                    type: 'part'
                },
                {
                    name: 'Servis',
                    price_wo_vat: 50,
                    type: 'labour'
                }
            ]
        },
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: `Very important`,
        client: 100
    },
    // Company - DONE
    {
        id: '6',
        task_type: taskTypes[0],
        monitored_object: null,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: null,
        due_date: moment()
            .subtract(365 - 61, 'days')
            .toISOString(),
        repetition_due_date_type: taskTypes[0].repetition_due_date_type,
        repetition_due_date_value: taskTypes[0].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: null,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: null,
        notification_recievers: receivers,
        created_at: moment().subtract(22, 'month').toISOString(),
        updated_at: moment().subtract(22, 'month').toISOString(),
        created_by: null,
        completion_time: moment()
            .subtract(365 - 61, 'days')
            .toISOString(),
        completion_mileage_kilometers: null,
        completed_by: 1,
        deleted: 0,
        state: 'done',
        due_date_left_seconds: null,
        due_mileage_left_kilometers: null,
        expenses: {},
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: null,
        client: 100
    },
    // VEHICLE done
    {
        id: '7',
        task_type: taskTypes[1],
        monitored_object: vehicles[2].id,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: (vehicleStates[2].odometer ?? 0) / 1000 - 40000 - 2065,
        due_date: moment()
            .subtract(365 + 7, 'days')
            .toISOString(),
        completion_time: moment()
            .subtract(365 + 7, 'days')
            .toISOString(),
        completion_mileage_kilometers: (vehicleStates[2].odometer ?? 0) / 1000 - 40000 - 1000 - 2065,
        repetition_due_date_type: taskTypes[1].repetition_due_date_type,
        repetition_due_date_value: taskTypes[1].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: 40000,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: 500,
        notification_recievers: [
            {
                entity: 'user',
                entity_id: dispatcher?.id,
                channel: 'email',
                name: dispatcher?.name + '' + dispatcher?.surname
            },
            {
                entity: 'user',
                entity_id: dispatcher?.id,
                channel: 'app',
                name: dispatcher?.name + '' + dispatcher?.surname
            }
        ],
        created_at: moment().subtract(10, 'month').toISOString(),
        updated_at: moment().subtract(10, 'month').toISOString(),
        created_by: null,
        completed_by: {
            id: dispatcher?.id,
            name: dispatcher?.name,
            surname: dispatcher?.surname,
            sso_id: dispatcher?.ssoId
        },
        deleted: 0,
        state: 'done',
        due_date_left_seconds: 3600 * 24 * 61,
        due_mileage_left_kilometers: null,
        expenses: {
            price_total_wo_vat: 110,
            vat: 20,
            currency: 'EUR',
            items: [
                {
                    name: 'Oil - 5 liters',
                    price_wo_vat: 60,
                    type: 'part'
                },
                {
                    name: 'Servis',
                    price_wo_vat: 50,
                    type: 'labour'
                }
            ]
        },
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: null,
        client: 100
    },
    // TRAILER done
    {
        id: '8',
        task_type: taskTypes[2],
        monitored_object: trailers[0].id,
        user: null,
        place: null,
        contact: null,
        due_mileage_kilometers: null,
        due_date: moment().subtract(85, 'days').toISOString(),
        repetition_due_date_type: taskTypes[2].repetition_due_date_type,
        repetition_due_date_value: taskTypes[2].repetition_due_date_value,
        repetition_bind_to_due_date: false,
        repetition_due_mileage_kilometers: null,
        notification_due_date_days: 14,
        notification_due_mileage_kilometers: null,
        notification_recievers: [
            {
                entity: 'user',
                entity_id: dispatcher?.id,
                channel: 'email',
                name: dispatcher?.name + '' + dispatcher?.surname
            },
            {
                entity: 'user',
                entity_id: dispatcher?.id,
                channel: 'app',
                name: dispatcher?.name + '' + dispatcher?.surname
            }
        ],
        created_at: moment().subtract(20, 'month').toISOString(),
        updated_at: moment().subtract(20, 'month').toISOString(),
        created_by: null,
        completion_time: moment().subtract(100, 'days').toISOString(),
        completion_mileage_kilometers: null,
        completed_by: {
            id: dispatcher?.id,
            name: dispatcher?.name,
            surname: dispatcher?.surname,
            sso_id: dispatcher?.ssoId
        },
        deleted: 0,
        state: 'done',
        due_date_left_seconds: 3600 * 24 * 61,
        due_mileage_left_kilometers: null,
        expenses: {
            price_total_wo_vat: 390,
            vat: 20,
            currency: 'EUR',
            items: [
                {
                    name: 'Servis',
                    price_wo_vat: 390,
                    type: 'labour'
                }
            ]
        },
        last_evaluation_mileage: 0,
        last_evaluation_timestamp: null,
        notes: null,
        client: 100
    }
];

export const maintenanceTasksSummary: MaintenanceTaskSummaryList = {
    active: dataMaintenanceTasks.filter(d => d.state === 'active').length,
    duesoon: dataMaintenanceTasks.filter(d => d.state === 'duesoon').length,
    overdue: dataMaintenanceTasks.filter(d => d.state === 'overdue').length
};
export const maintenanceTasks = dataMaintenanceTasks.map(e => ReadOnlyMaintenanceTaskFromJSON(e));
export const maintenanceTaskTypes = taskTypes
    .map(e => TaskTypeToJSON(e))
    .map((e, i) => ({ ...e, id: (i + 1).toString() }));
export const maintenanceTaskReceivers = receivers.map(e => NotificationReceiversFromJSON(e));
