/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelInfo,
    ChannelInfoFromJSON,
    ChannelInfoFromJSONTyped,
    ChannelInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChannelsResponse
 */
export interface ChannelsResponse {
    /**
     * 
     * @type {Array<ChannelInfo>}
     * @memberof ChannelsResponse
     */
    channels: Array<ChannelInfo>;
}

export function ChannelsResponseFromJSON(json: any): ChannelsResponse {
    return ChannelsResponseFromJSONTyped(json, false);
}

export function ChannelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(ChannelInfoFromJSON)),
    };
}

export function ChannelsResponseToJSON(value?: ChannelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(ChannelInfoToJSON)),
    };
}


