/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoutingEmissionStandard {
    NONE = 'NONE',
    EURO0 = 'EURO_0',
    EURO1 = 'EURO_1',
    EURO2 = 'EURO_2',
    EURO3 = 'EURO_3',
    EURO4 = 'EURO_4',
    EURO5 = 'EURO_5',
    EUROEEV = 'EURO_EEV',
    EURO6 = 'EURO_6',
    EURO6C = 'EURO_6C',
    EURO7 = 'EURO_7'
}

export function RoutingEmissionStandardFromJSON(json: any): RoutingEmissionStandard {
    return RoutingEmissionStandardFromJSONTyped(json, false);
}

export function RoutingEmissionStandardFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutingEmissionStandard {
    return json as RoutingEmissionStandard;
}

export function RoutingEmissionStandardToJSON(value?: RoutingEmissionStandard | null): any {
    return value as any;
}

