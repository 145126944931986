/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FuelStatisticsGroup,
    FuelStatisticsGroupFromJSON,
    FuelStatisticsGroupToJSON,
    FuelStatisticsSort,
    FuelStatisticsSortFromJSON,
    FuelStatisticsSortToJSON,
    FuelStatsGroupByEntity,
    FuelStatsGroupByEntityFromJSON,
    FuelStatsGroupByEntityToJSON,
    FuelStatsGroupByTimespan,
    FuelStatsGroupByTimespanFromJSON,
    FuelStatsGroupByTimespanToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    JourneyStatisticsResponse,
    JourneyStatisticsResponseFromJSON,
    JourneyStatisticsResponseToJSON,
} from '../models';

export interface GetFuelStatisticsV1JourneyStatisticsFuelStatisticsGetRequest {
    dateFrom: string;
    dateTo: string;
    groupByTimespan: FuelStatsGroupByTimespan;
    groupByEntity: FuelStatsGroupByEntity;
    driverIds?: Array<number>;
    sortBy?: FuelStatisticsSort;
    moids?: Array<string>;
}

export interface GetJourneyStatisticsByDaysV1JourneyStatisticsByDaysGetRequest {
    dateFrom: string;
    dateTo: string;
    driverIds?: Array<number>;
    wrapArray?: boolean;
    moids?: Array<string>;
}

export interface GetJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGetRequest {
    dateFrom: string;
    dateTo: string;
    driverIds?: Array<number>;
    wrapArray?: boolean;
    moids?: Array<string>;
}

export interface GetJourneyStatisticsByDriversV1JourneyStatisticsByDriversGetRequest {
    dateFrom: string;
    dateTo: string;
    driverIds?: Array<number>;
    wrapArray?: boolean;
    moids?: Array<string>;
}

export interface GetJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGetRequest {
    dateFrom: string;
    dateTo: string;
    driverIds?: Array<number>;
    wrapArray?: boolean;
    moids?: Array<string>;
}

export interface GetJourneyStatisticsSummaryV1JourneyStatisticsSummaryGetRequest {
    dateFrom: string;
    dateTo: string;
    driverIds?: Array<number>;
    wrapArray?: boolean;
    moids?: Array<string>;
}

/**
 * no description
 */
export class JourneyStatisticsApi extends runtime.BaseAPI {

    /**
     * Get Fuel Statistics
     */
    async getFuelStatisticsV1JourneyStatisticsFuelStatisticsGetRaw(requestParameters: GetFuelStatisticsV1JourneyStatisticsFuelStatisticsGetRequest): Promise<runtime.ApiResponse<Array<FuelStatisticsGroup>>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet.');
        }

        if (requestParameters.groupByTimespan === null || requestParameters.groupByTimespan === undefined) {
            throw new runtime.RequiredError('groupByTimespan','Required parameter requestParameters.groupByTimespan was null or undefined when calling getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet.');
        }

        if (requestParameters.groupByEntity === null || requestParameters.groupByEntity === undefined) {
            throw new runtime.RequiredError('groupByEntity','Required parameter requestParameters.groupByEntity was null or undefined when calling getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.groupByTimespan !== undefined) {
            queryParameters['group_by_timespan'] = requestParameters.groupByTimespan;
        }

        if (requestParameters.groupByEntity !== undefined) {
            queryParameters['group_by_entity'] = requestParameters.groupByEntity;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/fuel-statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FuelStatisticsGroupFromJSON));
    }

    /**
     * Get Fuel Statistics
     */
    async getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet(requestParameters: GetFuelStatisticsV1JourneyStatisticsFuelStatisticsGetRequest): Promise<Array<FuelStatisticsGroup>> {
        const response = await this.getFuelStatisticsV1JourneyStatisticsFuelStatisticsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Journey Statistics By Days
     */
    async getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGetRaw(requestParameters: GetJourneyStatisticsByDaysV1JourneyStatisticsByDaysGetRequest): Promise<runtime.ApiResponse<JourneyStatisticsResponse>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/by-days`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JourneyStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get Journey Statistics By Days
     */
    async getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGet(requestParameters: GetJourneyStatisticsByDaysV1JourneyStatisticsByDaysGetRequest): Promise<JourneyStatisticsResponse> {
        const response = await this.getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Journey Statistics By Days
     */
    async getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGetRaw(requestParameters: GetJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGetRequest): Promise<runtime.ApiResponse<JourneyStatisticsResponse>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/by-months`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JourneyStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get Journey Statistics By Days
     */
    async getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGet(requestParameters: GetJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGetRequest): Promise<JourneyStatisticsResponse> {
        const response = await this.getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Journey Statistics By Drivers
     */
    async getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGetRaw(requestParameters: GetJourneyStatisticsByDriversV1JourneyStatisticsByDriversGetRequest): Promise<runtime.ApiResponse<JourneyStatisticsResponse>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/by-drivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JourneyStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get Journey Statistics By Drivers
     */
    async getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGet(requestParameters: GetJourneyStatisticsByDriversV1JourneyStatisticsByDriversGetRequest): Promise<JourneyStatisticsResponse> {
        const response = await this.getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Journey Statistics By Vehicles
     */
    async getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGetRaw(requestParameters: GetJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGetRequest): Promise<runtime.ApiResponse<JourneyStatisticsResponse>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/by-monitored-objects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JourneyStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get Journey Statistics By Vehicles
     */
    async getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGet(requestParameters: GetJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGetRequest): Promise<JourneyStatisticsResponse> {
        const response = await this.getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Journey Statistics Summary
     */
    async getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGetRaw(requestParameters: GetJourneyStatisticsSummaryV1JourneyStatisticsSummaryGetRequest): Promise<runtime.ApiResponse<JourneyStatisticsResponse>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/journey-statistics/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JourneyStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get Journey Statistics Summary
     */
    async getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGet(requestParameters: GetJourneyStatisticsSummaryV1JourneyStatisticsSummaryGetRequest): Promise<JourneyStatisticsResponse> {
        const response = await this.getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGetRaw(requestParameters);
        return await response.value();
    }

}
