/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyVehicleProfileSerializer
 */
export interface WriteOnlyVehicleProfileSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    euroClass?: WriteOnlyVehicleProfileSerializerEuroClassEnum;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    numberOfAxles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    pricePerKm: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    trailersCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    length?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    tunnel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyVehicleProfileSerializer
     */
    client: number;
}

export function WriteOnlyVehicleProfileSerializerFromJSON(json: any): WriteOnlyVehicleProfileSerializer {
    return WriteOnlyVehicleProfileSerializerFromJSONTyped(json, false);
}

export function WriteOnlyVehicleProfileSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyVehicleProfileSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'euroClass': !exists(json, 'euro_class') ? undefined : json['euro_class'],
        'numberOfAxles': !exists(json, 'number_of_axles') ? undefined : json['number_of_axles'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'pricePerKm': json['price_per_km'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'trailersCount': !exists(json, 'trailers_count') ? undefined : json['trailers_count'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'tunnel': !exists(json, 'tunnel') ? undefined : json['tunnel'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'client': json['client'],
    };
}

export function WriteOnlyVehicleProfileSerializerToJSON(value?: WriteOnlyVehicleProfileSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'euro_class': value.euroClass,
        'number_of_axles': value.numberOfAxles,
        'weight': value.weight,
        'price_per_km': value.pricePerKm,
        'height': value.height,
        'trailers_count': value.trailersCount,
        'width': value.width,
        'length': value.length,
        'tunnel': value.tunnel,
        'is_default': value.isDefault,
        'client': value.client,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyVehicleProfileSerializerEuroClassEnum {
    None = 'None',
    EEV = 'EEV',
    EUROI = 'EURO_I',
    EUROII = 'EURO_II',
    EUROIII = 'EURO_III',
    EUROIV = 'EURO_IV',
    EUROV = 'EURO_V',
    EUROVI = 'EURO_VI'
}


