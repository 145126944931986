import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RadioGroup } from '../RadioGroup';
import qa from 'qa-selectors';
import { Button, Select, Switch, Tooltip } from 'common/components';
import { Row, Col } from 'antd';
import { searched } from 'common/utils/search';
import * as icons from 'resources/images/navbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export enum AddressIdentification {
    Code = 'code',
    CodeAndAddress = 'codeAndAddress',
    Address = 'address'
}

export enum VehicleIdentification {
    RegistrationNumber = 'registrationNumber',
    CustomLabel = 'customLabel'
}

export enum Theme {
    Light = 'light',
    Dark = 'dark'
}

interface Props extends WithTranslation {
    lang: string;
    languages: string[];
    addressIdentification: AddressIdentification;
    vehicleIdentification: VehicleIdentification;
    theme: Theme;
    demoMode?: boolean;
    demoVisible?: boolean;
    companyName?: string;
    onLanguageChange?: (lang: string) => void;
    onAddressIdentificationChange?: (setting: AddressIdentification) => void;
    onVehicleIdentificationChange?: (setting: VehicleIdentification) => void;
    onThemeChange?: (setting: Theme) => void;
    onLogout?: () => void;
    onCancel?: () => void;
    onDemoModeToggle?: (checked: boolean) => void;
    onCompanyNameCopy?: () => void;
}

function SettingCard(props: { title?: ReactNode; srcImage?: string; children: ReactNode }) {
    return (
        <div className="setting-card">
            <div className="heading">
                <label>{props.title}</label>
            </div>
            <div className="content">{props.children}</div>
        </div>
    );
}

function Settings(props: Props) {
    function onLanguageSelect(value: string): void {
        props.onLanguageChange?.(value);
    }

    function onAddressIdentificationChange(code: string): void {
        props.onAddressIdentificationChange?.(code as AddressIdentification);
    }

    function onVehicleIdentificationChange(code: string): void {
        props.onVehicleIdentificationChange?.(code as VehicleIdentification);
    }

    function onThemeChange(code: string): void {
        props.onThemeChange?.(code as Theme);
    }

    return (
        <div className="module module-settings module-right" onMouseLeave={props.onCancel}>
            <div className="settings page">
                <div className="settings-heading">
                    <Row align="middle" gutter={24} className="title">
                        <Col>{props.t('settings.title')}</Col>
                        <Col>
                            <img src={icons.gears} alt={String(props.t('settings.title'))} />
                        </Col>
                    </Row>
                    {props.companyName && (
                        <Row align="middle" className="settings-company-name">
                            <Col>
                                {props.t('settings.companyName')}: {props.companyName}
                            </Col>
                            <Col>
                                <CopyToClipboard text={props.companyName} onCopy={props.onCompanyNameCopy}>
                                    <Tooltip className="tooltip" title={props.t('settings.copyToClipboard')}>
                                        <Button
                                            type="text"
                                            size="small"
                                            icon={
                                                <img
                                                    src={icons.copyToClipboard}
                                                    alt={String(props.t('settings.title'))}
                                                />
                                            }
                                            qa={qa.userSettings.btnCompanyNameCopy}
                                        />
                                    </Tooltip>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="settings-actions">
                    <Button
                        qa={qa.userSettings.logoutButton}
                        onClick={props.onLogout}
                        type="primary"
                        size="middle"
                        icon={<img src={icons.logout} alt={String(props.t('settings.items.logout.button'))} />}
                    >
                        {props.t('settings.items.logout.button')}
                    </Button>
                </div>
                <div className="settings-content">
                    <SettingCard title={props.t('settings.items.language.title')} srcImage="/img-svg/icn-language.svg">
                        <Select
                            data-qa={qa.userSettings.languageSelect}
                            value={props.lang}
                            onChange={onLanguageSelect}
                            showSearch
                            filterOption={(input, option) => searched(input, String(option?.children))}
                            dropdownClassName="settings-language-dropdown"
                        >
                            {props.languages.map((lang, i) => {
                                const getLangFlag = () => {
                                    switch (lang) {
                                        case 'cs':
                                            return 'cz';
                                        case 'da':
                                            return 'dk';

                                        case 'el':
                                            return 'gr';
                                        case 'en':
                                            return 'gb';
                                        case 'et':
                                            return 'ee';
                                        case 'ga':
                                            return 'ie';
                                        case 'sl':
                                            return 'si';
                                        case 'sv':
                                            return 'se';
                                        case 'uk':
                                            return 'ua';
                                        default:
                                            return lang;
                                    }
                                };
                                return (
                                    <Select.Option key={i} value={lang} className="settings-language-option">
                                        <div
                                            className={`flag-icon flag-icon-${String(
                                                getLangFlag()
                                            ).toLocaleLowerCase()}`}
                                        />
                                        {` ${props.t(`NavBar.lang.${lang}`)}`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </SettingCard>
                    <SettingCard
                        title={props.t('settings.items.addressIdentification.title')}
                        srcImage="/img-svg/icn-location.svg"
                    >
                        <RadioGroup
                            type="radio"
                            name=""
                            checked={props.addressIdentification}
                            onChange={onAddressIdentificationChange}
                            options={Object.values(AddressIdentification)
                                .map(key => ({
                                    code: key,
                                    label: props.t(`settings.items.addressIdentification.options.${key}`)
                                }))
                                .filter(e => e.code !== AddressIdentification.CodeAndAddress)}
                        />
                    </SettingCard>
                    <SettingCard
                        title={props.t('settings.items.vehicleIdentification.title')}
                        srcImage="/img-svg/icn-truck.svg"
                    >
                        <RadioGroup
                            type="radio"
                            name=""
                            checked={props.vehicleIdentification}
                            onChange={onVehicleIdentificationChange}
                            options={Object.values(VehicleIdentification).map(key => ({
                                code: key,
                                label: props.t(`settings.items.vehicleIdentification.options.${key}`)
                            }))}
                        />
                    </SettingCard>
                    <SettingCard title={props.t('settings.items.theme.title')} srcImage="/img-svg/icn-color-theme.svg">
                        <RadioGroup
                            type="radio"
                            name=""
                            checked={props.theme}
                            onChange={onThemeChange}
                            options={Object.values(Theme).map(key => ({
                                code: key,
                                label: props.t(`settings.items.theme.options.${key}`)
                            }))}
                        />
                    </SettingCard>
                    {props.demoVisible && (
                        <SettingCard srcImage="/img-svg/icn-color-theme.svg">
                            <Row justify="space-between">
                                <Col>{props.t('settings.items.demoMode.title')}</Col>
                                <Col>
                                    <Switch checked={props.demoMode} onChange={props.onDemoModeToggle} />
                                </Col>
                            </Row>
                        </SettingCard>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Settings);
