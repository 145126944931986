/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpensesItems,
    ExpensesItemsFromJSON,
    ExpensesItemsFromJSONTyped,
    ExpensesItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Expenses
 */
export interface Expenses {
    /**
     * 
     * @type {number}
     * @memberof Expenses
     */
    priceTotalWoVat?: number;
    /**
     * 
     * @type {number}
     * @memberof Expenses
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof Expenses
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Expenses
     */
    contact?: string;
    /**
     * 
     * @type {Array<ExpensesItems>}
     * @memberof Expenses
     */
    items?: Array<ExpensesItems>;
}

export function ExpensesFromJSON(json: any): Expenses {
    return ExpensesFromJSONTyped(json, false);
}

export function ExpensesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Expenses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceTotalWoVat': !exists(json, 'price_total_wo_vat') ? undefined : json['price_total_wo_vat'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ExpensesItemsFromJSON)),
    };
}

export function ExpensesToJSON(value?: Expenses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price_total_wo_vat': value.priceTotalWoVat,
        'vat': value.vat,
        'currency': value.currency,
        'contact': value.contact,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ExpensesItemsToJSON)),
    };
}


