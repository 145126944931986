/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    BackendApiApiV2ActualVehicleStateVehicleFuelTank,
    BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleFuelTankToJSON,
    BackendApiApiV2ActualVehicleStateVehicleGPSData,
    BackendApiApiV2ActualVehicleStateVehicleGPSDataFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleGPSDataFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleGPSDataToJSON,
    BackendApiApiV2ActualVehicleStateVehicleStateData,
    BackendApiApiV2ActualVehicleStateVehicleStateDataFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleStateDataFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleStateDataToJSON,
    BackendApiApiV2ActualVehicleStateVehicleTransport,
    BackendApiApiV2ActualVehicleStateVehicleTransportFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleTransportFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleTransportToJSON,
    CargoWeight,
    CargoWeightFromJSON,
    CargoWeightFromJSONTyped,
    CargoWeightToJSON,
    OdometerState,
    OdometerStateFromJSON,
    OdometerStateFromJSONTyped,
    OdometerStateToJSON,
    VehicleAdblue,
    VehicleAdblueFromJSON,
    VehicleAdblueFromJSONTyped,
    VehicleAdblueToJSON,
    VehicleCarBattery,
    VehicleCarBatteryFromJSON,
    VehicleCarBatteryFromJSONTyped,
    VehicleCarBatteryToJSON,
    VehicleCharging,
    VehicleChargingFromJSON,
    VehicleChargingFromJSONTyped,
    VehicleChargingToJSON,
    VehicleDriverNew,
    VehicleDriverNewFromJSON,
    VehicleDriverNewFromJSONTyped,
    VehicleDriverNewToJSON,
    VehicleExternalDevices,
    VehicleExternalDevicesFromJSON,
    VehicleExternalDevicesFromJSONTyped,
    VehicleExternalDevicesToJSON,
    VehicleWeight,
    VehicleWeightFromJSON,
    VehicleWeightFromJSONTyped,
    VehicleWeightToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleStateFull
 */
export interface VehicleStateFull {
    /**
     * 
     * @type {string}
     * @memberof VehicleStateFull
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {BackendApiApiV2ActualVehicleStateVehicleGPSData}
     * @memberof VehicleStateFull
     */
    gpsData?: BackendApiApiV2ActualVehicleStateVehicleGPSData;
    /**
     * 
     * @type {BackendApiApiV2ActualVehicleStateVehicleStateData}
     * @memberof VehicleStateFull
     */
    stateData?: BackendApiApiV2ActualVehicleStateVehicleStateData;
    /**
     * 
     * @type {Array<BackendApiApiV2ActualVehicleStateVehicleFuelTank>}
     * @memberof VehicleStateFull
     */
    fuelTanks?: Array<BackendApiApiV2ActualVehicleStateVehicleFuelTank>;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateFull
     */
    address?: string;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof VehicleStateFull
     */
    addressStructured?: Array<AddressStructured>;
    /**
     * 
     * @type {Array<BackendApiApiV2ActualVehicleStateVehicleTransport>}
     * @memberof VehicleStateFull
     */
    activeTransports: Array<BackendApiApiV2ActualVehicleStateVehicleTransport>;
    /**
     * 
     * @type {Date}
     * @memberof VehicleStateFull
     */
    lastChangeState?: Date;
    /**
     * 
     * @type {VehicleCharging}
     * @memberof VehicleStateFull
     */
    charging?: VehicleCharging;
    /**
     * 
     * @type {VehicleWeight}
     * @memberof VehicleStateFull
     */
    weight?: VehicleWeight;
    /**
     * 
     * @type {CargoWeight}
     * @memberof VehicleStateFull
     */
    cargoWeight?: CargoWeight;
    /**
     * 
     * @type {VehicleAdblue}
     * @memberof VehicleStateFull
     */
    adblueLevel?: VehicleAdblue;
    /**
     * 
     * @type {Array<VehicleDriverNew>}
     * @memberof VehicleStateFull
     */
    drivers?: Array<VehicleDriverNew>;
    /**
     * 
     * @type {Array<VehicleExternalDevices>}
     * @memberof VehicleStateFull
     */
    externalDevices?: Array<VehicleExternalDevices>;
    /**
     * 
     * @type {OdometerState}
     * @memberof VehicleStateFull
     */
    odometerGps?: OdometerState;
    /**
     * 
     * @type {OdometerState}
     * @memberof VehicleStateFull
     */
    odometerCan?: OdometerState;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateFull
     */
    licencePlate: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateFull
     */
    vehicleExternalId?: string;
    /**
     * 
     * @type {VehicleCarBattery}
     * @memberof VehicleStateFull
     */
    carBattery?: VehicleCarBattery;
    /**
     * 
     * @type {Array}
     * @memberof VehicleStateFull
     */
    tlmCoreEvents?: [];
    /**
     * 
     * @type {Array}
     * @memberof VehicleStateFull
     */
    puescEvents?: [];
    /**
     * 
     * @type {boolean}
     * @memberof VehicleStateFull
     */
    idle?: boolean;
}

export function VehicleStateFullFromJSON(json: any): VehicleStateFull {
    return VehicleStateFullFromJSONTyped(json, false);
}

export function VehicleStateFullFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleStateFull {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitoredObjectId'],
        'gpsData': !exists(json, 'gpsData') ? undefined : BackendApiApiV2ActualVehicleStateVehicleGPSDataFromJSON(json['gpsData']),
        'stateData': !exists(json, 'stateData') ? undefined : BackendApiApiV2ActualVehicleStateVehicleStateDataFromJSON(json['stateData']),
        'fuelTanks': !exists(json, 'fuelTanks') ? undefined : ((json['fuelTanks'] as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSON)),
        'address': !exists(json, 'address') ? undefined : json['address'],
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON)),
        'activeTransports': ((json['activeTransports'] as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleTransportFromJSON)),
        'lastChangeState': !exists(json, 'lastChangeState') ? undefined : (new Date(json['lastChangeState'])),
        'charging': !exists(json, 'charging') ? undefined : VehicleChargingFromJSON(json['charging']),
        'weight': !exists(json, 'weight') ? undefined : VehicleWeightFromJSON(json['weight']),
        'cargoWeight': !exists(json, 'cargoWeight') ? undefined : CargoWeightFromJSON(json['cargoWeight']),
        'adblueLevel': !exists(json, 'adblueLevel') ? undefined : VehicleAdblueFromJSON(json['adblueLevel']),
        'drivers': !exists(json, 'drivers') ? undefined : ((json['drivers'] as Array<any>).map(VehicleDriverNewFromJSON)),
        'externalDevices': !exists(json, 'externalDevices') ? undefined : ((json['externalDevices'] as Array<any>).map(VehicleExternalDevicesFromJSON)),
        'odometerGps': !exists(json, 'odometerGps') ? undefined : OdometerStateFromJSON(json['odometerGps']),
        'odometerCan': !exists(json, 'odometerCan') ? undefined : OdometerStateFromJSON(json['odometerCan']),
        'licencePlate': json['licencePlate'],
        'vehicleExternalId': !exists(json, 'vehicleExternalId') ? undefined : json['vehicleExternalId'],
        'carBattery': !exists(json, 'carBattery') ? undefined : VehicleCarBatteryFromJSON(json['carBattery']),
        'tlmCoreEvents': !exists(json, 'tlmCoreEvents') ? undefined : json['tlmCoreEvents'],
        'puescEvents': !exists(json, 'puescEvents') ? undefined : json['puescEvents'],
        'idle': !exists(json, 'idle') ? undefined : json['idle'],
    };
}

export function VehicleStateFullToJSON(value?: VehicleStateFull | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoredObjectId': value.monitoredObjectId,
        'gpsData': BackendApiApiV2ActualVehicleStateVehicleGPSDataToJSON(value.gpsData),
        'stateData': BackendApiApiV2ActualVehicleStateVehicleStateDataToJSON(value.stateData),
        'fuelTanks': value.fuelTanks === undefined ? undefined : ((value.fuelTanks as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleFuelTankToJSON)),
        'address': value.address,
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
        'activeTransports': ((value.activeTransports as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleTransportToJSON)),
        'lastChangeState': value.lastChangeState === undefined ? undefined : (value.lastChangeState.toISOString()),
        'charging': VehicleChargingToJSON(value.charging),
        'weight': VehicleWeightToJSON(value.weight),
        'cargoWeight': CargoWeightToJSON(value.cargoWeight),
        'adblueLevel': VehicleAdblueToJSON(value.adblueLevel),
        'drivers': value.drivers === undefined ? undefined : ((value.drivers as Array<any>).map(VehicleDriverNewToJSON)),
        'externalDevices': value.externalDevices === undefined ? undefined : ((value.externalDevices as Array<any>).map(VehicleExternalDevicesToJSON)),
        'odometerGps': OdometerStateToJSON(value.odometerGps),
        'odometerCan': OdometerStateToJSON(value.odometerCan),
        'licencePlate': value.licencePlate,
        'vehicleExternalId': value.vehicleExternalId,
        'carBattery': VehicleCarBatteryToJSON(value.carBattery),
        'tlmCoreEvents': value.tlmCoreEvents,
        'puescEvents': value.puescEvents,
        'idle': value.idle,
    };
}


