import { Expense } from 'generated/graphql';
import { vehicles } from './vehicles';
import { users } from './users';
import { vehicleStates } from './vehicles-states';
import { dates } from './transports';
import moment from 'moment';

const defaultConfiguration = {
    fuel: {
        source: 'probe',
        calibration: [
            {
                curve: [
                    [-1, 5],
                    [0, 0],
                    [8, 41.2371],
                    [29, 51.5464],
                    [112, 103.093],
                    [203, 159.794],
                    [260, 206.186],
                    [338, 257.732],
                    [432, 319.588],
                    [495, 360.825],
                    [573, 412.371],
                    [650, 463.918],
                    [722, 515.464],
                    [797, 567.01],
                    [847, 597.938],
                    [858, 608.247],
                    [879, 618.557],
                    [890, 624.742]
                ],
                probeId: 0
            },
            {
                curve: [
                    [-1, 5],
                    [0, 0],
                    [13, 20.6186],
                    [125, 51.5464],
                    [285, 103.093],
                    [449, 154.639],
                    [613, 206.186],
                    [771, 257.732],
                    [892, 293.814],
                    [911, 298.969],
                    [950, 309.278],
                    [963, 312.371]
                ],
                probeId: 1
            }
        ],
        consumption_type: 'cumulative',
        consumption_multiplier: 0.001
    },
    driver_authentication_types: ['driver_card']
};

const defaultFraudDetection = {
    state: 'FRAUD_DETECTED',
    monitoredObjectPositionData: {
        lat: 42.65479,
        lon: 2.86182,
        time: '2020-09-02T23:48:37.000Z',
        __typename: 'ExpensePositionData'
    },
    expensePositionData: {
        lat: 48.897275,
        lon: 2.358644,
        time: '2020-09-03T00:00:00.000Z',
        __typename: 'ExpensePositionData'
    },
    checked: null,
    __typename: 'FraudDetectionResult'
};

const defaultExpenseMetadata = {
    externalTransactionId: null,
    merchantId: null,
    terminalId: null,
    authCode: null,
    fuelCardCode: null,
    fuelCardIssuer: null,
    fuelCardType: null,
    merchantCountry: null,
    __typename: 'ExpenseMetadata'
};

export const expenses = [
    {
        id: '1',
        monitoredObject: {
            id: vehicles[0].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[0].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[0].odometer! - 100000,
        clientId: 100,
        driverName: `${users[0].name} ${users[0].surname}`,
        type: 'online',
        expenseType: 'fuel',
        timestamp: moment(dates[0].times[0])
            .add(7 * 3600 + 5 * 60, 'seconds')
            .toISOString(),
        originalUnitPriceInclVat: 790 / 800 + 5 / 800,
        originalTotalPriceInclVat: 790 + 5,
        unitPriceInclVat: 790 / 800,
        unitPriceVat: (790 / 800) * (100 / 120) * 0.2,
        totalPriceInclVat: 790,
        totalPriceVat: 790 * (100 / 120) * 0.2,
        units: 800,
        currency: 'EUR',
        country: 'AUT',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25e1dd37bba14f9aa7358',
            name: 'Turmol - Volkermarkt',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    {
        id: '2',
        monitoredObject: {
            id: vehicles[1].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[1].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: 62808037.65,
        clientId: 100,
        driverName: `${users[1].name} ${users[1].surname}`,
        type: 'online',
        expenseType: 'fuel',
        timestamp: moment(dates[1].times[0])
            .add(2 * 3600 + 7 * 60, 'seconds')
            .toISOString(),
        originalUnitPriceInclVat: 547 / 630 + 3 / 630,
        originalTotalPriceInclVat: 547 + 3,
        unitPriceInclVat: 547 / 630,
        unitPriceVat: (547 / 630) * (100 / 120) * 0.2,
        totalPriceInclVat: 547,
        totalPriceVat: 547 * (100 / 120) * 0.2,
        units: 630,
        currency: 'EUR',
        country: 'CZE',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25e1dd37bba14f9aa735c',
            name: 'Eurowag - Rozvadov',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: {
            state: 'FRAUD_DETECTED',
            monitoredObjectPositionData: {
                lat: 49.6757145928452,
                lon: 12.600743810898882,
                time: '2020-08-28T07:22:22.000Z',
                __typename: 'ExpensePositionData'
            },
            expensePositionData: {
                lat: 49.67571459284523,
                lon: 12.600743810898882,
                time: '2020-08-28T07:34:56.587Z',
                __typename: 'ExpensePositionData'
            },
            checked: null,
            __typename: 'FraudDetectionResult'
        },
        __typename: 'Expense'
    },
    {
        id: '3',
        monitoredObject: {
            id: vehicles[2].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[2].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: 63241313.55,
        clientId: 100,
        driverName: `${users[2].name} ${users[2].surname}`,
        type: 'online',
        expenseType: 'fuel',
        timestamp: moment(dates[2].times[0]).add('27', 'minutes').toISOString(),
        originalUnitPriceInclVat: 831.6 / 945 + 11 / 945,
        originalTotalPriceInclVat: 831.6 + 11,
        unitPriceInclVat: 831.6 / 945,
        unitPriceVat: (831.6 / 945) * (100 / 120) * 0.2,
        totalPriceInclVat: 831.6,
        totalPriceVat: 831.6 * (100 / 120) * 0.2,
        units: 945,
        currency: 'EUR',
        country: 'POL',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Eurowag - Pruszcz Gdanski',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    {
        id: '4',
        monitoredObject: {
            id: vehicles[3].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[3].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: 63241313.55,
        clientId: 100,
        driverName: `${users[4].name} ${users[4].surname}`,
        type: 'online',
        expenseType: 'fuel',
        timestamp: moment(dates[3].times[0]).toISOString(),
        originalUnitPriceInclVat: 800.2 / 900 + 11 / 900,
        originalTotalPriceInclVat: 800.2 + 11,
        unitPriceInclVat: 800.2 / 900,
        unitPriceVat: (800.2 / 900) * (100 / 120) * 0.2,
        totalPriceInclVat: 800.2,
        totalPriceVat: 800.2 * (100 / 120) * 0.2,
        units: 900,
        currency: 'EUR',
        country: 'HUN',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'OIL! - Mosonmagyaróvár',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    {
        id: '5',
        monitoredObject: {
            id: vehicles[4].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[3].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: 63241313.55,
        clientId: 100,
        driverName: `${users[3].name} ${users[3].surname}`,
        type: 'online',
        expenseType: 'fuel',
        timestamp: moment(dates[4].times[0]).toISOString(),
        originalUnitPriceInclVat: 965.2 / 1025 + 23 / 1025,
        originalTotalPriceInclVat: 965.2 + 23,
        unitPriceInclVat: 965.2 / 1025,
        unitPriceVat: (965.2 / 1025) * (100 / 120) * 0.2,
        totalPriceInclVat: 965.2,
        totalPriceVat: 965.2 * (100 / 120) * 0.2,
        units: 1025,
        currency: 'EUR',
        country: 'BEL',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'ESSO - HEVERLEE-NOOR',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    {
        id: '6',
        monitoredObject: {
            id: vehicles[5].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[5].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: 63241313.55,
        clientId: 100,
        driverName: `${users[5].name} ${users[5].surname}`,
        type: 'manual',
        expenseType: 'fuel',
        timestamp: moment(dates[5].times[0]).toISOString(),
        originalUnitPriceInclVat: 905.2 / 925 + 3 / 925,
        originalTotalPriceInclVat: 905.2 + 3,
        unitPriceInclVat: 905.2 / 925,
        unitPriceVat: (905.2 / 925) * (100 / 120) * 0.2,
        totalPriceInclVat: 905.2,
        totalPriceVat: 905.2 * (100 / 120) * 0.2,
        units: 925,
        currency: 'EUR',
        country: 'CZE',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Q8 - Venezia',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    // EXPENSE 7
    {
        id: '7',
        monitoredObject: {
            id: vehicles[1].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[1].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[1].odometer! - 863132,
        clientId: 100,
        driverName: `${users[1].name} ${users[1].surname}`,
        type: 'manual',
        expenseType: 'washing',
        timestamp: moment().subtract(5, 'days'),
        originalUnitPriceInclVat: 110 / 1 + 0,
        originalTotalPriceInclVat: 110 + 0,
        unitPriceInclVat: 110 / 1,
        unitPriceVat: (110 / 1) * (100 / 120) * 0.2,
        totalPriceInclVat: 110,
        totalPriceVat: 110 * (100 / 120) * 0.2,
        units: 1,
        currency: 'EUR',
        country: 'CZE',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Eurowag - Rozvadov',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    // EXPENSE 8
    {
        id: '8',
        monitoredObject: {
            id: vehicles[1].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[1].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[1].odometer! - 869727,
        clientId: 100,
        driverName: `${users[1].name} ${users[1].surname}`,
        type: 'manual',
        expenseType: 'tires_change',
        timestamp: moment().subtract(6, 'days'),
        originalUnitPriceInclVat: 349 / 1 + 0,
        originalTotalPriceInclVat: 349 + 0,
        unitPriceInclVat: 349 / 1,
        unitPriceVat: (349 / 1) * (100 / 120) * 0.2,
        totalPriceInclVat: 349,
        totalPriceVat: 349 * (100 / 120) * 0.2,
        units: 1,
        currency: 'EUR',
        country: 'CZE',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Eurowag - Rozvadov',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    // EXPENSE 9
    {
        id: '9',
        monitoredObject: {
            id: vehicles[2].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[2].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[2].odometer! - 864863,
        clientId: 100,
        driverName: `${users[2].name} ${users[2].surname}`,
        type: 'manual',
        expenseType: 'maintenance',
        timestamp: moment().subtract(6, 'days'),
        originalUnitPriceInclVat: 840.3 / 1 + 0,
        originalTotalPriceInclVat: 840.3 + 0,
        unitPriceInclVat: 840.3 / 1,
        unitPriceVat: (840.3 / 1) * (100 / 120) * 0.2,
        totalPriceInclVat: 840.3,
        totalPriceVat: 840.3 * (100 / 120) * 0.2,
        units: 1,
        currency: 'EUR',
        country: 'CZE',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Eurowag - Rozvadov',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    // EXPENSE 10
    {
        id: '10',
        monitoredObject: {
            id: vehicles[5].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[5].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[5].odometer! - 664863,
        clientId: 100,
        driverName: `${users[5].name} ${users[5].surname}`,
        type: 'manual',
        expenseType: 'toll_sticker',
        timestamp: moment().subtract(4, 'days'),
        originalUnitPriceInclVat: 50 / 1 + 0,
        originalTotalPriceInclVat: 50 + 0,
        unitPriceInclVat: 50 / 1,
        unitPriceVat: (50 / 1) * (100 / 120) * 0.2,
        totalPriceInclVat: 50,
        totalPriceVat: 50 * (100 / 120) * 0.2,
        units: 1,
        currency: 'EUR',
        country: 'SVK',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Dalioil - Šaštín-Stráže',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    },
    // EXPENSE 11
    {
        id: '11',
        monitoredObject: {
            id: vehicles[5].id,
            clientId: 100,
            monitoringDeviceId: 10567,
            monitoredObjectTypeId: 1,
            vehicleProfileId: null,
            externalId: '10573_NZ 935 FS',
            registrationNumber: vehicles[5].registrationNumber,
            description: '',
            systemDescription: null,
            manufacturerName: null,
            configuration: defaultConfiguration,
            metadata: null,
            deleted: '0',
            vehicleProfile: { id: null, __typename: 'VehicleProfile' },
            fuelCards: null,
            __typename: 'MonitoredObject'
        },
        odometer: vehicleStates[5].odometer! - 664863,
        clientId: 100,
        driverName: `${users[5].name} ${users[5].surname}`,
        type: 'manual',
        expenseType: 'service',
        timestamp: moment().subtract(4, 'days'),
        originalUnitPriceInclVat: 99.9 / 1 + 0,
        originalTotalPriceInclVat: 99.9 + 0,
        unitPriceInclVat: 99.9 / 1,
        unitPriceVat: (99.9 / 1) * (100 / 120) * 0.2,
        totalPriceInclVat: 99.9,
        totalPriceVat: 99.9 * (100 / 120) * 0.2,
        units: 1,
        currency: 'EUR',
        country: 'SVK',
        vatRate: 20,
        state: 'final',
        fuelStation: {
            id: '5df25dd8d37bba14f9aa5050',
            name: 'Dalioil - Šaštín-Stráže',
            center: null,
            polygon: null,
            lat: null,
            lon: null,
            services: [],
            fuelTypes: [],
            __typename: 'FuelStation'
        },
        expenseMetadata: defaultExpenseMetadata,
        fraudDetectionResult: defaultFraudDetection,
        __typename: 'Expense'
    }
] as Expense[];
