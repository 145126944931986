import { Role } from 'logic/auth';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Logic } from '../../logic/logic';
import { rbac, RouteNames } from 'App';
import StatisticsSideBar from './ui/StatisticsSideBar';

import JourneysModule from './modules/journeys/JourneysModule';
import AetrModule from './modules/aetr/AetrModule';
import StatisticsFuelConsumption from './modules/fuel-consumption/FuelConsumptionModule';
import ExpensesModule from './modules/expenses/ExpensesModule';
import CompanyProfileModule from './modules/company-profile/CompanyProfileModule';
import StatisticsDriverBehaviorNew from './modules/driver-behavior-new/DriverBehaviorModule';
import BorderCrossingModule from './modules/border-crossing/StatisticsBorderCrossingModule';
import MaintenanceModule from './modules/maintenance/MaintenanceModule';
import AllowancesModule from './modules/allowance/AllowanceModule';
import StatisticReportModule from './modules/statistic-report/StatisticReportModule';
import ColdchainModule from './modules/coldchain/ColdchainModule';
import ForbiddenPage from 'common/components/ForbiddenPage';
import cs from 'classnames';
import DriverBehaviorModule from './modules/driver-behavior-coach/DriverBehaviorModule';

interface Props extends RouteComponentProps {
    logic: Logic;
    embedded: boolean;
}

function StatisticsModule(props: Props) {
    const roles = props.logic.auth().roles();
    return (
        <div className={cs('statistics module', { embedded: props.embedded })}>
            {!props.embedded && <StatisticsSideBar roles={roles} />}
            <Switch>
                <Route
                    path={RouteNames.STATISTICS_COMPANY_PROFILE}
                    render={rbac(roles, [Role.CP_R, Role.DBHD_R], <CompanyProfileModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.STATISTICS_JOURNEYS_ACTIVITY}
                    render={rbac(roles, [Role.JA_R], <JourneysModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.STATISTICS_FUEL_CONSUMPTION}
                    render={rbac(roles, [Role.FC_R], <StatisticsFuelConsumption logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.STATISTICS_AETR}
                    render={rbac(roles, [Role.AEI_R], <AetrModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.STATISTICS_EXPENSES}
                    render={rbac(roles, [Role.EX_R], <ExpensesModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_DRIVER_BEHAVIOR}
                    render={rbac(
                        roles,
                        [Role.DBH_R],
                        props.logic.conf.settings.driverBehaviorCoachAvailable === true ? (
                            <DriverBehaviorModule logic={props.logic} />
                        ) : (
                            <StatisticsDriverBehaviorNew logic={props.logic} />
                        )
                    )}
                />
                <Route
                    exact
                    path={RouteNames.STATISTICS_BORDER_CROSSING}
                    render={rbac(roles, [Role.BC_R], <BorderCrossingModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_MAINTENANCE}
                    render={rbac(roles, [Role.MTN_R], <MaintenanceModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_ALLOWANCES}
                    render={rbac(roles, [Role.DIT_R], <AllowancesModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_STATISTIC_REPORT}
                    render={rbac(roles, [Role.FC_R], <StatisticReportModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_COLD_CHAIN}
                    render={rbac(roles, [Role.CLD_R], <ColdchainModule logic={props.logic} />)}
                />
                <Route path="*" render={() => <ForbiddenPage type="404" availableRoles={roles} />} />
            </Switch>
        </div>
    );
}

export default withRouter(StatisticsModule);
