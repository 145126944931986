import { Component } from 'react';
import { Logic } from 'logic/logic';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';
import Users from './components/Users';
import { defaultInputs } from './components/UsersDetail';
import { PaginatedResponse, PaginationParams } from '../../../../common/model/pagination';
import { SuperAdminUserModel } from 'modules/superadmin/SuperAdminModule';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { message } from 'antd';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    edit: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
    };
    table: {
        loading: boolean;
        data?: PaginatedResponse<PartnerUserModel[]>;
        create?: PartnerUserModel;
        selected?: PartnerUserModel;
        delete?: PartnerUserModel;
        clients?: PartnerCompanySelectModel[];
    };
}

class PartnerUsersModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            edit: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            }
        };
    }

    componentDidMount() {
        Promise.all([
            this._logic.partner().user().getUserList(this.state.bar.selectedCompany?.id),
            this._logic.partner().companies().getClientList()
        ])
            .then(([data, clients]) => {
                const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));

                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: data,
                        clients: cl,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    }

    render() {
        return (
            <Users
                edit={this.state.edit}
                loading={this.state.table.loading}
                data={this.state.table.data}
                create={this.state.table.create}
                selected={this.state.table.selected}
                delete={this.state.table.delete}
                companies={this.state.bar.companies}
                clients={this.state.table.clients}
                selectedCompany={this.state.bar.selectedCompany}
                onTableRowSelect={this._onTableRowSelect}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onActionsDelete={this._onActionsDelete}
                onDetailActionsCreate={this._onDetailActionsCreate}
                onDetailFormCancel={this._onDetailFormCancel}
                onDetailFormSubmit={this._onDetailFormSubmit}
                onActionsImpersonate={this._onDetailActionsImpersonate}
                onDeleteConfirm={this._onDeleteConfirm}
                onDeleteCancel={this._onDeleteCancel}
                onCreateSubmit={this._onCreateSubmit}
                onCreateCancel={this._onCreateCancel}
                onUsersPaginationChange={this._onUsersPaginationChange}
                onClientsFilterSearch={this._onClientsFilterSearch}
            />
        );
    }

    private _onClientsFilterSearch = async (text: string) => {
        const clients = await this._logic
            .partner()
            .companies()
            .getClientList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text);

        const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner })) ?? [];

        this.setState(state => ({
            table: {
                ...state.table,
                clients: cl
            }
        }));
    };

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onDetailActionsImpersonate = (): void => {
        const selected = this.state.table?.selected;
        this._logic.superadminLogic().impersonate({
            ssoId: selected?.ssoId
        } as SuperAdminUserModel);
    };

    private _onDetailActionsCreate = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                create: {
                    ...defaultInputs,
                    clientId: Number(state.bar.selectedCompany?.id)
                }
            }
        }));
    };

    private _onActionsDelete = (): void => {
        const model = this.state.table.selected;
        this.setState(state => ({
            table: { ...state.table, delete: model }
        }));
    };

    private _onDetailFormCancel = (): void => {
        this.setState({
            edit: false
        });
    };

    private _onDetailFormSubmit = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                selected: undefined
            }
        }));
    };

    private _getUsers = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this._logic
            .partner()
            .user()
            .getUserList(
                this.state.bar.selectedCompany?.id,
                this.state.table.data?.limit,
                this.state.table.data?.offset
            )
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _onDeleteConfirm = (): void => {
        if (this.state.table?.delete) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));
            this.props.logic
                .partner()
                .user()
                .deleteUser(this.state.table?.delete)
                .then(deleted => {
                    this.setState(state => ({
                        table: { ...state.table, delete: undefined }
                    }));
                    if (deleted) {
                        this._getUsers();
                    }
                });
        }
    };

    private _onDeleteCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, delete: undefined }
        }));
    };

    private _onCreateSubmit = (model: PartnerUserModel): void => {
        this.setState(state => ({
            table: { ...state.table, loading: true, create: undefined }
        }));
        this._logic
            .partner()
            .user()
            .addUser(model)
            .then(_res => {
                this._getUsers();
            });
    };

    private _onCreateCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, create: undefined }
        }));
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                },
                table: {
                    ...state.table,
                    data: undefined,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getUsers();
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    ...state.table,
                    data: undefined,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getUsers();
            }
        );
    };

    private _onTableRowSelect = (id: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                selected: state.table?.selected?.id === id ? undefined : state.table?.data?.data.find(d => d.id === id)
            }
        }));
    };

    private _onUsersPaginationChange = (pagination: PaginationParams) => {
        this.setState(state => ({ table: { ...state.table, loading: true } }));
        this._logic
            .partner()
            .user()
            .getUserList(this.state.bar.selectedCompany?.id, pagination.limit, pagination.offset)
            .then(data => {
                this.setState({ table: { data, loading: false } });
            });
    };
}

export default withTranslation()(withRouter(PartnerUsersModule));
