import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { SuperAdmin } from './components/SuperAdmin';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import { debounce } from 'debounce';
import { RouteNames } from 'App';
import { CountrySelectModel } from 'logic/partner/partner';

export type UserId = string | number;

export interface SuperAdminTableData {
    data: SuperAdminCompanyModel[];
}

export interface SuperAdminCompanyModel {
    id: string;
    companyName: string;
    address: string;
    country?: number;
    users: SuperAdminUserModel[];
}

export interface SuperAdminUserModel {
    id: string;
    username: string;
    name: string;
    surname: string;
    ssoId?: string;
}

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface SearchState {
    text: string;
}

interface State {
    search?: SearchState;
    users: {
        search: { text: string };
        ssoUsers: boolean;
        table?: {
            data: SuperAdminUserModel[];
            loading: boolean;
        };
    };
    table: {
        data: SuperAdminCompanyModel[];
        selected?: SuperAdminCompanyModel;
        countryCounter?: CountrySelectModel[];
        loading: boolean;
    };
}

class SuperAdminModule extends Component<Props, State> {
    private _logic: Logic;

    // private _tableData: SuperAdminCompanyModel[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            search: { text: '' },
            users: {
                search: { text: '' },
                ssoUsers: false
            },
            table: {
                data: [],
                loading: false
            }
        };
        this._logic = props.logic;
    }

    componentDidMount() {
        this._logic
            .partner()
            .getCountryList()
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        countryCounter: data
                    }
                }));
            });
    }
    render() {
        return (
            <SuperAdmin
                search={this.state.search}
                users={this.state.users}
                table={this.state.table}
                onBarSearchClick={this._onBarSearchClick}
                onSearchChange={this._onCompanySearchChange}
                onFormCancel={this._onFormCancel}
                onTableRowClick={this._onTableRowClick}
                onUsersSearchChange={this._onUsersSearchChange}
                onSsoUserChecboxChange={this._onSsoUserChecboxChange}
                onUserSubmit={this._onUserSubmit}
            />
        );
    }

    private _onBarSearchClick = () => {
        this.setState(state => ({
            search: state.search ? undefined : { text: '' }
        }));
    };

    private _onCompanySearchChange = debounce(async (text: string) => {
        this.setState(state => ({
            table: { ...state.table, loading: true }
        }));
        if (text && text.length > 2) {
            const data = await this._logic.superadminLogic().getCompaniesNew(text);
            this.setState(state => ({
                table: { ...state.table, loading: false, data },
                users: {
                    search: { text: '' },
                    ssoUsers: state.users.ssoUsers
                }
            }));
        } else {
            this.setState(state => ({
                table: { ...state.table, loading: false, data: [] },
                users: {
                    search: { text: '' },
                    ssoUsers: state.users.ssoUsers
                }
            }));
        }
    }, 500);

    private _onUsersSearchChange = debounce(async (text: string) => {
        const company = this.state.table.selected;
        this.setState(state => ({
            users: { ...state.users, table: { ...state.users.table!, loading: true } }
        }));
        if (company?.id) {
            let data: SuperAdminUserModel[] = [];
            if (this.state.users.ssoUsers) {
                data = await this._logic.superadminLogic().getCompanySSOUser(company.id);
            } else {
                data = await this._logic.superadminLogic().getCompanyUser(company.id, text, ['dispatcher']);
            }
            this.setState(state => ({
                users: { ...state.users, table: { loading: false, data } }
            }));
        } else {
            this.setState(state => ({
                users: { ...state.users, table: { loading: false, data: [] } }
            }));
        }
    }, 500);

    private _onSsoUserChecboxChange = (value: boolean) => {
        this.setState(
            state => ({
                users: { ...state.users, ssoUsers: value }
            }),
            () => this._onUsersSearchChange(this.state.users.search.text)
        );
    };

    private _onTableRowEdit = (id: string) => {
        const company = this.state.table.data.find(company => company.id === id);

        this.setState(state => ({
            table: {
                ...state.table,
                selected: company
            },
            users: {
                ...state.users,
                table: {
                    data: [],
                    loading: true
                }
            }
        }));
        if (company) {
            this._logic
                .superadminLogic()
                .getCompanyUser(company.id, this.state.search?.text, ['dispatcher'])
                .then(users => {
                    this.setState(state => ({
                        users: {
                            ...state.users,
                            table: {
                                data: users || [],
                                loading: false
                            }
                        }
                    }));
                });
        } else {
            this.setState(state => ({
                users: {
                    ...state.users,
                    table: {
                        data: [],
                        loading: false
                    }
                }
            }));
        }
    };

    private _onTableRowClick = (id: string) => {
        this._onTableRowEdit(id);
    };

    private _onFormCancel = async () => {
        this.setState(state => ({
            users: { ...state.users, table: undefined }
        }));
    };

    private _onUserSubmit = async (user: SuperAdminUserModel) => {
        this.props.history.push({
            pathname: RouteNames.TRACKING
        });
        this._logic.superadminLogic().impersonate(user);
    };
}

export default withTranslation()(withRouter(SuperAdminModule));
