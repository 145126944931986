import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type ActiveEventConfig = {
  __typename?: 'ActiveEventConfig';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ActiveEventRules = {
  __typename?: 'ActiveEventRules';
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Array<ActiveEventConfig>>;
};

export type ActualDriverState = {
  __typename?: 'ActualDriverState';
  userId?: Maybe<Scalars['String']>;
  lastUpdateTime?: Maybe<Scalars['String']>;
  state?: Maybe<DriverState>;
};

export type ActualDriverStateFilter = {
  userIds?: Maybe<Array<Scalars['Float']>>;
};

export type AdblueLevelObject = {
  __typename?: 'AdblueLevelObject';
  time?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
};

export type AddressStructured = {
  __typename?: 'AddressStructured';
  lang?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  street_address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type AnnouncementReport = {
  __typename?: 'AnnouncementReport';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['Float']>;
  highPriority?: Maybe<Scalars['Boolean']>;
  monitoredObjectId?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Attachment>>;
  expirationTime?: Maybe<Scalars['String']>;
  visibilityPolicy?: Maybe<Scalars['String']>;
  serverDeliveryTime?: Maybe<Scalars['String']>;
  monitoredObjectDeliveryTime?: Maybe<Scalars['String']>;
  readBy?: Maybe<Array<ReadBy>>;
};

export type Attachment = {
  __typename?: 'Attachment';
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  blobName?: Maybe<Scalars['String']>;
  thumbnailBlobName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  access_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Float']>;
  refresh_expires_in?: Maybe<Scalars['Float']>;
  token_type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AvailailabilityMonObj = {
  __typename?: 'AvailailabilityMonObj';
  id?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Boolean']>;
};

export type Ccr = {
  __typename?: 'CCR';
  id?: Maybe<Scalars['Float']>;
  card_reader_sn?: Maybe<Scalars['String']>;
  active_to?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type CcrInput = {
  card_reader_sn: Scalars['String'];
};

export type CcrStateObject = {
  __typename?: 'CCRStateObject';
  card_reader_sn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type CcrUpdate = {
  id: Scalars['Float'];
};

export type CarBatteryObject = {
  __typename?: 'CarBatteryObject';
  timestamp?: Maybe<Scalars['String']>;
  volts?: Maybe<Scalars['Float']>;
};

export type ChargingObject = {
  __typename?: 'ChargingObject';
  time?: Maybe<Scalars['String']>;
  on?: Maybe<Scalars['Boolean']>;
};

export type Client = {
  __typename?: 'Client';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemId?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  fieldOfActivity?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  billingCode?: Maybe<Scalars['String']>;
  correspondenceAddress?: Maybe<Scalars['String']>;
  contact?: Maybe<ClientContact>;
  emailForInvoicing?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  bankingConnection?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  externalId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CodeNamePair = {
  __typename?: 'CodeNamePair';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  contact: UserContactsInput;
  pin?: Maybe<Scalars['String']>;
  tokens: Array<UserTokenInput>;
  userGroups?: Maybe<Array<Scalars['String']>>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type CustomPlace = {
  __typename?: 'CustomPlace';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  polygon?: Maybe<GeoJsonPolygonType>;
  center?: Maybe<GeoJsonPointType>;
  polygonType?: Maybe<PoiPolygonType>;
  notes?: Maybe<Scalars['String']>;
  forbidden?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  type: PoiType;
  countryCode?: Maybe<Scalars['String']>;
};

export type CustomPlaceAddInput = {
  name: Scalars['String'];
  polygon: GeoJsonPolygonTypeInput;
  center: GeoJsonPointTypeInput;
  polygonType: PoiPolygonType;
  notes: Scalars['String'];
  forbidden: Scalars['Boolean'];
  deleted: Scalars['Float'];
  address: Scalars['String'];
  type: PoiType;
  countryCode: Scalars['String'];
};

export type CustomPlaceUpdateInput = {
  name: Scalars['String'];
  polygon: GeoJsonPolygonTypeInput;
  center: GeoJsonPointTypeInput;
  polygonType: PoiPolygonType;
  notes: Scalars['String'];
  forbidden: Scalars['Boolean'];
  deleted: Scalars['Float'];
  address: Scalars['String'];
  type: PoiType;
  countryCode: Scalars['String'];
  _id: Scalars['String'];
};

export type Dtco = {
  __typename?: 'DTCO';
  downloadStarted?: Maybe<Scalars['String']>;
  downloadedFile?: Maybe<Scalars['String']>;
  dataActivityStart?: Maybe<Scalars['String']>;
  dataActivityEnd?: Maybe<Scalars['String']>;
  sasUrl?: Maybe<Scalars['String']>;
  monitoredObject?: Maybe<DtcoMonitoredObject>;
  driver?: Maybe<DtcoDriver>;
};

export type DtcoDriver = {
  __typename?: 'DTCODriver';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
};

export type DtcoFilter = {
  monitoredObjectIds?: Maybe<Array<Scalars['Float']>>;
  driverIds?: Maybe<Array<Scalars['Float']>>;
  fromDownloadStarted: Scalars['String'];
  toDownloadStarted: Scalars['String'];
};

export type DtcoLink = {
  __typename?: 'DTCOLink';
  sasUrl?: Maybe<Scalars['String']>;
};

export type DtcoLinkFilter = {
  fileName?: Maybe<Scalars['String']>;
};

export type DtcoMonitoredObject = {
  __typename?: 'DTCOMonitoredObject';
  registrationNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
};

export type DailyDriverStatistics = {
  __typename?: 'DailyDriverStatistics';
  id?: Maybe<Scalars['String']>;
  driverID?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<Vehicle>>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  idlingDuration?: Maybe<Scalars['Float']>;
  additionalDeviceDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  averageConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
  averageMovementConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  averageIdlingConsumption?: Maybe<Scalars['Float']>;
  totalAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
  averageAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
};

export type DriverState = {
  __typename?: 'DriverState';
  driverName?: Maybe<Scalars['String']>;
  monitoredObjectId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  drivingType?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  lastDataTime?: Maybe<Scalars['String']>;
  activityStartTime?: Maybe<Scalars['String']>;
  drivingOrWorkingStartTime?: Maybe<Scalars['String']>;
  lastDrivingOrWorkingStartTime?: Maybe<Scalars['String']>;
  lastShortBreakDuration?: Maybe<Scalars['String']>;
  nextBreakStartTime?: Maybe<Scalars['String']>;
  nextBreakStartTime2?: Maybe<Scalars['String']>;
  workingDuration?: Maybe<Scalars['Float']>;
  restingDuration?: Maybe<Scalars['Float']>;
  choppedDrivingTimeCapacity?: Maybe<Scalars['Float']>;
  drivingDuration?: Maybe<Scalars['Float']>;
  weeklyDrivingDuration?: Maybe<Scalars['Float']>;
  biweeklyDrivingDuration?: Maybe<Scalars['Float']>;
  weekStartTime?: Maybe<Scalars['String']>;
  shifts?: Maybe<Scalars['String']>;
  lastWeeklyRestDuration?: Maybe<Scalars['String']>;
  lastWeeklyRestStartTime?: Maybe<Scalars['String']>;
  weeklyRestDebts?: Maybe<Array<WeeklyRestDebt>>;
  dividedDailyRestCandidateStartTime?: Maybe<Scalars['String']>;
  dividedDailyRestCandidateDuration?: Maybe<Scalars['String']>;
  lastDailyRestStartTime?: Maybe<Scalars['String']>;
  lastDailyRestDuration?: Maybe<Scalars['String']>;
  nextDailyRestStartTime?: Maybe<Scalars['String']>;
  reducedDailyRests?: Maybe<Scalars['String']>;
  extendedDailyShifts?: Maybe<Scalars['String']>;
  extendedDailyShiftsIncrementTime?: Maybe<Scalars['String']>;
  nextWeeklyRestStartTime?: Maybe<Scalars['String']>;
  nextWeeklyRestDuration?: Maybe<Scalars['String']>;
  nextWeekWeeklyRestStartTime?: Maybe<Scalars['String']>;
  averageSpeed?: Maybe<Scalars['Float']>;
  nextBreakDuration?: Maybe<Scalars['Float']>;
  currentActivityDuration?: Maybe<Scalars['Float']>;
  drivingDurationRemaining?: Maybe<Scalars['Float']>;
  weeklyDrivingDurationRemaining?: Maybe<Scalars['Float']>;
  biweeklyDrivingDurationRemaining?: Maybe<Scalars['Float']>;
  expectedWeeklyDrivingRange?: Maybe<Scalars['Float']>;
  expectedBiweeklyDrivingRange?: Maybe<Scalars['Float']>;
};

export enum EFuelType {
  Diesel = 'DIESEL',
  Gasoline = 'GASOLINE',
  LngCng = 'LNG_CNG',
  Electro = 'ELECTRO',
  Hybrid = 'HYBRID',
  Hydrogen = 'HYDROGEN'
}

export enum ETrailerType {
  Cooler = 'COOLER',
  CurtainSider = 'CURTAIN_SIDER',
  Container = 'CONTAINER',
  Standard = 'STANDARD'
}

export enum EventRuleNameType {
  TransportCorridorLeave = 'transport_corridor_leave',
  TransportPoiClose = 'transport_poi_close',
  TransportArrival = 'transport_arrival',
  TransportDeparture = 'transport_departure'
}

export type Expense = {
  __typename?: 'Expense';
  id?: Maybe<Scalars['String']>;
  monitoredObject?: Maybe<MonitoredObject>;
  odometer?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  driverName?: Maybe<Scalars['String']>;
  type?: Maybe<ExpenseSource>;
  expenseType?: Maybe<ExpenseType>;
  timestamp?: Maybe<Scalars['String']>;
  originalUnitPriceInclVat?: Maybe<Scalars['Float']>;
  originalTotalPriceInclVat?: Maybe<Scalars['Float']>;
  unitPriceInclVat?: Maybe<Scalars['Float']>;
  unitPriceVat?: Maybe<Scalars['Float']>;
  totalPriceInclVat?: Maybe<Scalars['Float']>;
  totalPriceVat?: Maybe<Scalars['Float']>;
  totalPriceWithoutVat?: Maybe<Scalars['Float']>;
  unitPriceWithoutVat?: Maybe<Scalars['Float']>;
  unitPriceInclVatAverage?: Maybe<Scalars['Float']>;
  unitPriceWithoutVatAverage?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  savedCurrency?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  state?: Maybe<ExpenseState>;
  fuelStation?: Maybe<FuelStation>;
  expenseMetadata?: Maybe<ExpenseMetadata>;
  fraudDetectionResult?: Maybe<FraudDetectionResult>;
  fuelioTotemPrice?: Maybe<Scalars['Float']>;
  fuelioTotemPriceCurrency?: Maybe<Scalars['String']>;
};

export enum ExpenseAggregator {
  Vehicle = 'VEHICLE',
  Date = 'DATE'
}

export type ExpenseFilter = {
  monitoredObjectIds?: Maybe<Array<Scalars['Float']>>;
  type?: Maybe<Array<ExpenseType>>;
  source?: Maybe<Array<ExpenseSource>>;
  paymentStatus?: Maybe<Array<ExpenseState>>;
  userId?: Maybe<Scalars['Float']>;
  startTime?: Scalars['String'];
  endTime?: Scalars['String'];
};

export type ExpenseGroup = {
  __typename?: 'ExpenseGroup';
  exchangeRateErr?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  main?: Maybe<Expense>;
  details?: Maybe<Array<Expense>>;
};

export type ExpenseGroupList = {
  __typename?: 'ExpenseGroupList';
  data?: Maybe<Array<ExpenseGroup>>;
};

export type ExpenseImport = {
  __typename?: 'ExpenseImport';
  status?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Scalars['Float']>>;
};

export type ExpenseInput = {
  id?: Maybe<Scalars['String']>;
  monitoredObjectId: Scalars['Float'];
  expenseType: Scalars['String'];
  timestamp: Scalars['String'];
  originalUnitPriceInclVat?: Maybe<Scalars['Float']>;
  originalTotalPriceInclVat?: Maybe<Scalars['Float']>;
  unitPriceInclVat?: Maybe<Scalars['Float']>;
  unitPriceVat?: Maybe<Scalars['Float']>;
  totalPriceInclVat: Scalars['Float'];
  totalPriceVat?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  fuelStationId?: Maybe<Scalars['String']>;
  fuelCardId?: Maybe<Scalars['Float']>;
  fuelCardCode?: Maybe<Scalars['String']>;
};

export type ExpenseList = {
  __typename?: 'ExpenseList';
  expenses?: Maybe<Array<Expense>>;
  monitoredObjectBasedMapping?: Maybe<Scalars['JSON']>;
  timestampBasedMapping?: Maybe<Scalars['JSON']>;
};

export type ExpenseMetadata = {
  __typename?: 'ExpenseMetadata';
  externalTransactionId?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  fuelCardCode?: Maybe<Scalars['String']>;
  fuelCardIssuer?: Maybe<Scalars['String']>;
  fuelCardType?: Maybe<Scalars['String']>;
  merchantCountry?: Maybe<Scalars['String']>;
};

export type ExpensePositionData = {
  __typename?: 'ExpensePositionData';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
};

export enum ExpenseSource {
  Manual = 'manual',
  Online = 'online',
  Import = 'import',
  Summary = 'summary'
}

export enum ExpenseState {
  Proforma = 'proforma',
  Final = 'final'
}

export enum ExpenseType {
  TechnicalTest = 'technical_test',
  EmissionTest = 'emission_test',
  CollisionInsurance = 'collision_insurance',
  TollSticker = 'toll_sticker',
  Washing = 'washing',
  Service = 'service',
  Maintenance = 'maintenance',
  Toll = 'toll',
  Fuel = 'fuel',
  Other = 'other',
  Adblue = 'adblue',
  TiresPurchase = 'tires_purchase',
  TiresChange = 'tires_change',
  TollDeposit = 'toll_deposit',
  Summary = 'summary'
}

export type ExternalDeviceDataObject = {
  __typename?: 'ExternalDeviceDataObject';
  temperature?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
};

export type ExternalDeviceMetadataObject = {
  __typename?: 'ExternalDeviceMetadataObject';
  registration_number?: Maybe<Scalars['String']>;
  sensor_name?: Maybe<Scalars['String']>;
  sensor_type?: Maybe<Scalars['String']>;
  sensor_zone?: Maybe<Scalars['String']>;
};

export type ExternalDeviceObject = {
  __typename?: 'ExternalDeviceObject';
  serial_number?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  tfrom?: Maybe<Scalars['String']>;
  metadata?: Maybe<ExternalDeviceMetadataObject>;
  data?: Maybe<ExternalDeviceDataObject>;
};

export type FraudDetectionResult = {
  __typename?: 'FraudDetectionResult';
  state?: Maybe<Scalars['String']>;
  monitoredObjectPositionData?: Maybe<ExpensePositionData>;
  expensePositionData?: Maybe<ExpensePositionData>;
  checked?: Maybe<Scalars['Boolean']>;
};

export type FuelCard = {
  __typename?: 'FuelCard';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  fuelCompanyId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  expirationDate?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitDuration?: Maybe<Scalars['Float']>;
  monitoredObject?: Maybe<MonitoredObject>;
  user?: Maybe<User>;
  deleted?: Maybe<Scalars['Float']>;
};

export type FuelCardInput = {
  id: Scalars['Float'];
  number: Scalars['String'];
  holder: Scalars['String'];
  fuelCompanyId: Scalars['Float'];
  expirationDate?: Maybe<Scalars['String']>;
  monitoredObject?: Maybe<MonitoredObjectInput>;
  user?: Maybe<FuelCardUserInput>;
  deleted: Scalars['Float'];
};

export type FuelCardUserInput = {
  id: Scalars['Float'];
};

export type FuelCompany = {
  __typename?: 'FuelCompany';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type FuelPrice = {
  __typename?: 'FuelPrice';
  price?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  sumExtraFees?: Maybe<Scalars['String']>;
};

export type FuelStation = {
  __typename?: 'FuelStation';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  center?: Maybe<GeoJsonPointType>;
  polygon?: Maybe<GeoJsonPolygonType>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  services?: Maybe<Array<CodeNamePair>>;
  fuelTypes?: Maybe<Array<FuelType>>;
};

export type FuelStationFilter = {
  transportId?: Maybe<Array<Scalars['Float']>>;
  encodedPolyline?: Maybe<Array<Scalars['String']>>;
  fuelType?: Maybe<Array<Scalars['String']>>;
  facility?: Maybe<Array<Scalars['String']>>;
};

export type FuelType = {
  __typename?: 'FuelType';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<FuelPrice>;
  code?: Maybe<Scalars['String']>;
};

export type GeoJsonPointType = {
  __typename?: 'GeoJSONPointType';
  type?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Scalars['Float']>>;
  bbox?: Maybe<Array<Scalars['Float']>>;
};

export type GeoJsonPointTypeInput = {
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
  bbox?: Maybe<Array<Scalars['Float']>>;
};

export type GeoJsonPolygonType = {
  __typename?: 'GeoJSONPolygonType';
  type?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Array<Array<Scalars['Float']>>>>;
  bbox?: Maybe<Array<Scalars['Float']>>;
};

export type GeoJsonPolygonTypeInput = {
  type: Scalars['String'];
  coordinates: Array<Array<Array<Scalars['Float']>>>;
  bbox?: Maybe<Array<Scalars['Float']>>;
};

export type InputTrailerProfile = {
  type: ETrailerType;
  weightEmpty: Scalars['Float'];
  weightFull: Scalars['Float'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  cargoType: Scalars['String'];
  numberOfAxles: Scalars['Float'];
};


export type LicensePlate = {
  __typename?: 'LicensePlate';
  licensePlate?: Maybe<Scalars['String']>;
  monitoredObjectId?: Maybe<Scalars['Float']>;
};

export type MessageReport = {
  __typename?: 'MessageReport';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['Float']>;
  recipientId?: Maybe<Scalars['Float']>;
  highPriority?: Maybe<Scalars['Boolean']>;
  transportId?: Maybe<Scalars['String']>;
  monitoredObjectId?: Maybe<Scalars['Float']>;
  senderPlace?: Maybe<MessengerPlace>;
  recipientPlace?: Maybe<MessengerPlace>;
  message?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Attachment>>;
  expirationTime?: Maybe<Scalars['String']>;
  serverDeliveryTime?: Maybe<Scalars['String']>;
  userDelivery?: Maybe<Array<UserDelivery>>;
  readBy?: Maybe<Array<ReadBy>>;
};

export type MessengerPlace = {
  __typename?: 'MessengerPlace';
  type?: Maybe<Scalars['String']>;
  geometry?: Maybe<GeoJsonPointType>;
  properties?: Maybe<PlaceProperties>;
};

export type MobjStat = {
  __typename?: 'MobjStat';
  id?: Maybe<Scalars['String']>;
  monitoredObjectId?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  users?: Maybe<Array<MonitoredObjectStatisticsUser>>;
  totalAverageConsumption?: Maybe<Scalars['Float']>;
  totalAverageMovementConsumption?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
  totalDuration?: Maybe<Scalars['Float']>;
  totalAverageIdlingConsumption?: Maybe<Scalars['Float']>;
  totalIdlingDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
};

export type MonitoredObject = {
  __typename?: 'MonitoredObject';
  id?: Maybe<Scalars['Float']>;
  monitoringDeviceId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  monitoredObjectTypeId?: Maybe<Scalars['Float']>;
  vehicleProfileId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  customLabel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  systemDescription?: Maybe<Scalars['String']>;
  manufacturerName?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  deleted?: Maybe<Scalars['String']>;
  costPerKm?: Maybe<Scalars['JSON']>;
  tankSize?: Maybe<Scalars['Float']>;
  vehicleProfile?: Maybe<VehicleProfile>;
  fuelCards?: Maybe<Array<MonitoredObjectFuelCard>>;
  monitoredObjectGroups?: Maybe<Array<MonitoredObjectGroupType>>;
  roles?: Maybe<Array<Scalars['String']>>;
  Trailers?: Maybe<Array<MonitoredObject>>;
  disabledAt?: Maybe<Scalars['String']>;
};

export type MonitoredObjectFilter = {
  description: Scalars['String'];
  disabled: Scalars['Boolean'];
  includeTrailers: Scalars['Boolean'];
};

export type MonitoredObjectFuelCard = {
  __typename?: 'MonitoredObjectFuelCard';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  fuelCompanyId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  expirationDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitDuration?: Maybe<Scalars['Float']>;
};

export type MonitoredObjectGroupType = {
  __typename?: 'MonitoredObjectGroupType';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  monitoredObjectGroupTypeId?: Maybe<Scalars['Float']>;
};

export type MonitoredObjectGroupTypeType = {
  __typename?: 'MonitoredObjectGroupTypeType';
  id?: Maybe<Scalars['Float']>;
  deleted?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Float']>;
  shape?: Maybe<Scalars['String']>;
};

export type MonitoredObjectInput = {
  id: Scalars['Float'];
};

export type MonitoredObjectQueries = {
  __typename?: 'MonitoredObjectQueries';
  licensePlateMap?: Maybe<Array<LicensePlate>>;
  availableForTransport?: Maybe<Array<AvailailabilityMonObj>>;
};


export type MonitoredObjectQueriesAvailableForTransportArgs = {
  startTime: Scalars['String'];
};

export type MonitoredObjectStatisticsSchema = {
  __typename?: 'MonitoredObjectStatisticsSchema';
  MonitoredObject?: Maybe<Array<MobjStat>>;
};


export type MonitoredObjectStatisticsSchemaMonitoredObjectArgs = {
  monObjId?: Maybe<Array<Scalars['String']>>;
  interval: StatisticsInterval;
  startDateTime?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};

export type MonitoredObjectStatisticsUser = {
  __typename?: 'MonitoredObjectStatisticsUser';
  id?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  idlingDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  averageConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
  averageMovementConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  averageIdlingConsumption?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type MonitoredObjectTypeObject = {
  __typename?: 'MonitoredObjectTypeObject';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MonthlyDriverStatistics = {
  __typename?: 'MonthlyDriverStatistics';
  id?: Maybe<Scalars['Float']>;
  driverID?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  dailyDriverStatisticsIDS?: Maybe<Array<Scalars['String']>>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  idlingDuration?: Maybe<Scalars['Float']>;
  additionalDeviceDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  averageConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
  averageMovementConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  averageIdlingConsumption?: Maybe<Scalars['Float']>;
  totalAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
  averageAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  add_CustomPlace?: Maybe<Scalars['String']>;
  update_CustomPlace?: Maybe<Scalars['Boolean']>;
  delete_CustomPlace?: Maybe<Scalars['Boolean']>;
  update_User?: Maybe<Scalars['Boolean']>;
  create_User?: Maybe<Scalars['Boolean']>;
  delete_User?: Maybe<Scalars['Boolean']>;
  activate_User?: Maybe<Scalars['Boolean']>;
  deactivate_User?: Maybe<Scalars['Boolean']>;
  update_AverageSpeed?: Maybe<Scalars['Boolean']>;
  delete_UserToken?: Maybe<Scalars['Boolean']>;
  deactivate_UserToken?: Maybe<Scalars['Boolean']>;
  add_UserToken?: Maybe<Scalars['Boolean']>;
  update_UserTokens?: Maybe<Scalars['Boolean']>;
  create_UserTokens?: Maybe<Array<UserTokenType>>;
  deleteDuplicatedFuelCards?: Maybe<Scalars['Boolean']>;
  add_MonitoredObjectGroupType?: Maybe<Scalars['Boolean']>;
  update_MonitoredObjectGroupType?: Maybe<Scalars['Boolean']>;
  delete_MonitoredObjectGroupType?: Maybe<Scalars['Boolean']>;
  add_MonitoredObjectGroup?: Maybe<Scalars['Boolean']>;
  update_MonitoredObjectGroup?: Maybe<Scalars['Boolean']>;
  delete_MonitoredObjectGroup?: Maybe<Scalars['Boolean']>;
  create_Trailer?: Maybe<Scalars['Boolean']>;
  delete_Trailer?: Maybe<Scalars['Boolean']>;
  pair_TrailerToVehicle?: Maybe<Scalars['Boolean']>;
  unpair_TrailerFromVehicle?: Maybe<Scalars['Boolean']>;
  update_FuelCards?: Maybe<Scalars['Boolean']>;
  create_FuelCards?: Maybe<Scalars['Boolean']>;
  update_VehicleProfile?: Maybe<VehicleProfile>;
  delete_VehicleProfile?: Maybe<Scalars['Boolean']>;
  create_VehicleProfile?: Maybe<VehicleProfile>;
  create_Transport?: Maybe<Transport>;
  update_Transport?: Maybe<Transport>;
  delete_Transport?: Maybe<Scalars['Boolean']>;
  set_TransportState?: Maybe<Scalars['Boolean']>;
  update_TransportPlace?: Maybe<Scalars['Boolean']>;
  add_TransportPlace?: Maybe<Scalars['Boolean']>;
  update_TransportPlaces?: Maybe<Transport>;
  remove_TransportPlace?: Maybe<Scalars['Boolean']>;
  attach_VehicleToTransport?: Maybe<Scalars['Boolean']>;
  detach_VehicleFromTransport?: Maybe<Scalars['Boolean']>;
  produceEvent?: Maybe<Scalars['Boolean']>;
  assign_CCR?: Maybe<Ccr>;
  unassign_CCR?: Maybe<Ccr>;
  upsert_Expense?: Maybe<Expense>;
  delete_Expense?: Maybe<Scalars['Boolean']>;
  import_Expense?: Maybe<ExpenseImport>;
};


export type MutationAdd_CustomPlaceArgs = {
  placeData: CustomPlaceAddInput;
};


export type MutationUpdate_CustomPlaceArgs = {
  placeData: CustomPlaceUpdateInput;
};


export type MutationDelete_CustomPlaceArgs = {
  id: Scalars['String'];
};


export type MutationUpdate_UserArgs = {
  userInput: UpdateUserInput;
};


export type MutationCreate_UserArgs = {
  userInput: CreateUserInput;
};


export type MutationDelete_UserArgs = {
  userId: Scalars['Float'];
};


export type MutationActivate_UserArgs = {
  userId: Scalars['Float'];
};


export type MutationDeactivate_UserArgs = {
  userId: Scalars['Float'];
};


export type MutationUpdate_AverageSpeedArgs = {
  userId: Scalars['Float'];
  averageSpeed: Scalars['Float'];
};


export type MutationDelete_UserTokenArgs = {
  userId: Scalars['Float'];
  token: Scalars['String'];
};


export type MutationDeactivate_UserTokenArgs = {
  userId: Scalars['Float'];
  token: Scalars['String'];
};


export type MutationAdd_UserTokenArgs = {
  userId: Scalars['Float'];
  token: Scalars['String'];
  tokenType: Scalars['String'];
};


export type MutationUpdate_UserTokensArgs = {
  userTokens: Array<UserTokenInput>;
};


export type MutationCreate_UserTokensArgs = {
  userTokens: Array<UserTokenInput>;
};


export type MutationAdd_MonitoredObjectGroupTypeArgs = {
  shape: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdate_MonitoredObjectGroupTypeArgs = {
  id: Scalars['Float'];
  shape: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDelete_MonitoredObjectGroupTypeArgs = {
  id: Scalars['Float'];
};


export type MutationAdd_MonitoredObjectGroupArgs = {
  clientId: Scalars['Float'];
  name: Scalars['String'];
  color: Scalars['String'];
  groupTypeId: Scalars['Float'];
};


export type MutationUpdate_MonitoredObjectGroupArgs = {
  id: Scalars['Float'];
  name: Scalars['String'];
};


export type MutationDelete_MonitoredObjectGroupArgs = {
  id: Scalars['Float'];
};


export type MutationCreate_TrailerArgs = {
  licensePlateNumber: Scalars['String'];
  ownName: Scalars['String'];
  manufacturerName: Scalars['String'];
  trailerProfile?: Maybe<InputTrailerProfile>;
};


export type MutationDelete_TrailerArgs = {
  id: Scalars['Float'];
};


export type MutationPair_TrailerToVehicleArgs = {
  vehicleId: Scalars['Float'];
  trailerId: Scalars['Float'];
};


export type MutationUnpair_TrailerFromVehicleArgs = {
  vehicleId: Scalars['Float'];
  trailerId: Scalars['Float'];
};


export type MutationUpdate_FuelCardsArgs = {
  fuelCards: Array<FuelCardInput>;
};


export type MutationCreate_FuelCardsArgs = {
  fuelCards: Array<FuelCardInput>;
};


export type MutationUpdate_VehicleProfileArgs = {
  id: Scalars['Float'];
  vehicleProfile: VehicleProfileInput;
};


export type MutationDelete_VehicleProfileArgs = {
  id: Scalars['Float'];
};


export type MutationCreate_VehicleProfileArgs = {
  vehicleProfile: VehicleProfileInput;
};


export type MutationCreate_TransportArgs = {
  transport: TransportInput;
};


export type MutationUpdate_TransportArgs = {
  transport: TransportUpdateType;
};


export type MutationDelete_TransportArgs = {
  transportId: Scalars['String'];
};


export type MutationSet_TransportStateArgs = {
  transportId: Scalars['String'];
  state: TransportState;
};


export type MutationUpdate_TransportPlaceArgs = {
  transportId: Scalars['String'];
  transportPlaceId: Scalars['Float'];
  transportPlace: TransportPlaceInput;
};


export type MutationAdd_TransportPlaceArgs = {
  transportId: Scalars['String'];
  transportPlace: TransportPlaceInput;
};


export type MutationUpdate_TransportPlacesArgs = {
  transportId: Scalars['String'];
  transportPlaces: Array<TransportPlaceInput>;
};


export type MutationRemove_TransportPlaceArgs = {
  transportId: Scalars['String'];
  transportPlaceId: Scalars['String'];
};


export type MutationAttach_VehicleToTransportArgs = {
  transportId: Scalars['String'];
  vehicleId: Scalars['Float'];
  startTime: Scalars['String'];
  transportState?: Maybe<TransportState>;
};


export type MutationDetach_VehicleFromTransportArgs = {
  transportId: Scalars['String'];
  endTime: Scalars['String'];
};


export type MutationProduceEventArgs = {
  eventType: Scalars['String'];
  payload: Scalars['JSON'];
};


export type MutationAssign_CcrArgs = {
  ccrInput: CcrInput;
};


export type MutationUnassign_CcrArgs = {
  ccrUpdate: CcrUpdate;
};


export type MutationUpsert_ExpenseArgs = {
  input: ExpenseInput;
};


export type MutationDelete_ExpenseArgs = {
  id: Scalars['String'];
};


export type MutationImport_ExpenseArgs = {
  file: Array<Scalars['Float']>;
};

export type ObjectType_TrailerLocationInfo = {
  __typename?: 'ObjectType_TrailerLocationInfo';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  addressStructured?: Maybe<Array<AddressStructured>>;
};

export type ObjectType_TrailerPairingInfo = {
  __typename?: 'ObjectType_TrailerPairingInfo';
  vehicleId?: Maybe<Scalars['Float']>;
  pairedAt?: Maybe<Scalars['String']>;
  unpairedAt?: Maybe<Scalars['String']>;
  trailerLocationInfo?: Maybe<ObjectType_TrailerLocationInfo>;
};

export type ParkingLot = {
  __typename?: 'ParkingLot';
  id?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  center?: Maybe<GeoJsonPointType>;
  polygon?: Maybe<GeoJsonPolygonType>;
  notes?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Scalars['String']>>;
};

export type ParkingLotFilter = {
  facility?: Maybe<Array<Scalars['String']>>;
};

export type PlaceFilter = {
  ids?: Maybe<Array<Scalars['String']>>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Float']>;
};

export type PlaceProperties = {
  __typename?: 'PlaceProperties';
  address?: Maybe<Scalars['String']>;
};

export type PlaceSuggestion = {
  __typename?: 'PlaceSuggestion';
  label?: Maybe<Scalars['String']>;
  position?: Maybe<GeoJsonPointType>;
  polygon?: Maybe<GeoJsonPolygonType>;
  source?: Maybe<PlaceSuggestionSource>;
  id?: Maybe<Scalars['String']>;
};

export enum PlaceSuggestionSource {
  Google = 'GOOGLE',
  CustomPlaces = 'CUSTOM_PLACES',
  FuelStations = 'FUEL_STATIONS',
  ParkingLots = 'PARKING_LOTS'
}

export enum PlaceType {
  Start = 'start',
  End = 'end',
  Waypoint = 'waypoint',
  ParkingLot = 'parkingLot',
  FuelStation = 'fuelStation',
  CustomPlace = 'customPlace',
  Wash = 'wash'
}

export type PlacesAlongPolyline = {
  __typename?: 'PlacesAlongPolyline';
  fuelStations?: Maybe<Array<FuelStation>>;
  parkingLots?: Maybe<Array<ParkingLot>>;
  customPlaces?: Maybe<Array<CustomPlace>>;
};

export type PlanTransportInput = {
  origin: GeoJsonPointTypeInput;
  destination: GeoJsonPointTypeInput;
  vehicleWeight?: Maybe<Scalars['Float']>;
  vehicleNoOfAxles?: Maybe<Scalars['Float']>;
  vehicleHeight?: Maybe<Scalars['Float']>;
  vehicleNoOfTrailers?: Maybe<Scalars['Float']>;
  arrivalTime?: Maybe<Scalars['String']>;
  departureTime?: Maybe<Scalars['String']>;
  trafficModel?: Maybe<Scalars['String']>;
};

export enum PoiPolygonType {
  Circle = 'circle',
  Custom = 'custom'
}

export enum PoiType {
  Parking = 'parking',
  FuelStation = 'fuelStation',
  Firm = 'firm',
  OurFirm = 'ourFirm',
  InstallationService = 'installationService'
}

export type Query = {
  __typename?: 'Query';
  get_CustomPlaces?: Maybe<Array<CustomPlace>>;
  get_FuelStations?: Maybe<Array<FuelStation>>;
  get_SomeFuelStations?: Maybe<Array<FuelStation>>;
  get_BestFuelStationPrices?: Maybe<Array<FuelStation>>;
  get_ParkingLots?: Maybe<Array<ParkingLot>>;
  get_SomeParkingLots?: Maybe<Array<ParkingLot>>;
  get_User?: Maybe<User>;
  get_Users?: Maybe<Array<User>>;
  get_UserByToken?: Maybe<User>;
  get_UserByCredentials?: Maybe<User>;
  login_Driver?: Maybe<AuthToken>;
  get_UserTokens?: Maybe<Array<UserTokenType>>;
  get_MonitoredObjects?: Maybe<Array<MonitoredObject>>;
  get_MonitoredObjectGroups?: Maybe<Array<MonitoredObjectGroupType>>;
  get_MonitoredObjectGroupTypes?: Maybe<Array<MonitoredObjectGroupTypeType>>;
  get_MonitoredObject?: Maybe<MonitoredObject>;
  get_FuelCompanies?: Maybe<Array<FuelCompany>>;
  event_IncreaseMonitoredObjectDataRate?: Maybe<Scalars['Boolean']>;
  get_UnpairedMonitoredObjects?: Maybe<Array<MonitoredObject>>;
  get_Trailers?: Maybe<Array<MonitoredObject>>;
  get_UnpairedTrailer?: Maybe<Array<MonitoredObject>>;
  get_TrailerTypes?: Maybe<Array<Scalars['String']>>;
  get_LastTrailerPairingDetails?: Maybe<ObjectType_TrailerPairingInfo>;
  monitoredObject?: Maybe<MonitoredObjectQueries>;
  get_FuelCards?: Maybe<Array<FuelCard>>;
  get_VehicleProfiles?: Maybe<Array<VehicleProfile>>;
  get_DriverDailyStatistic?: Maybe<DailyDriverStatistics>;
  get_DriverDailyStatistics?: Maybe<Array<DailyDriverStatistics>>;
  get_DriverMonthlyStatistic?: Maybe<MonthlyDriverStatistics>;
  get_DriverMonthlyStatistics?: Maybe<Array<MonthlyDriverStatistics>>;
  get_DriverWeeklyStatistic?: Maybe<WeeklyDriverStatistics>;
  get_DriverWeeklyStatistics?: Maybe<Array<WeeklyDriverStatistics>>;
  Statistics?: Maybe<MonitoredObjectStatisticsSchema>;
  Driver?: Maybe<Scalars['Boolean']>;
  plan_Transport?: Maybe<TransportPlanPiece>;
  plan_TransportBatch?: Maybe<Array<TransportPlanPiece>>;
  get_Transport?: Maybe<Transport>;
  get_Transports?: Maybe<Array<Transport>>;
  search?: Maybe<SearchQueries>;
  get_UnreadMessagesCount?: Maybe<Array<UnreadMessagesCount>>;
  get_UnreadAnnouncementsCount?: Maybe<Scalars['Float']>;
  get_Messages?: Maybe<Array<MessageReport>>;
  get_Announcements?: Maybe<Array<AnnouncementReport>>;
  register?: Maybe<RegisterResponse>;
  sendPairingCode?: Maybe<Scalars['String']>;
  registerMobileDevice?: Maybe<RegisterDeviceResult>;
  get_DTCOs?: Maybe<Array<Dtco>>;
  get_DTCOLink?: Maybe<DtcoLink>;
  get_CCRs?: Maybe<Array<Ccr>>;
  subscribe_CCR?: Maybe<Array<CcrStateObject>>;
  get_Client?: Maybe<Client>;
  get_Clients?: Maybe<Array<Client>>;
  get_ClientUsers?: Maybe<Array<SsoUser>>;
  impersonate_User?: Maybe<AuthToken>;
  get_Expenses?: Maybe<ExpenseList>;
  get_GroupedExpenses?: Maybe<ExpenseGroupList>;
  get_ActualDriverStates?: Maybe<Array<ActualDriverState>>;
  get_ActualDriverState?: Maybe<ActualDriverState>;
  vehiclesSubscribe?: Maybe<Array<VehicleStateObject>>;
};


export type QueryGet_CustomPlacesArgs = {
  filter?: Maybe<PlaceFilter>;
};


export type QueryGet_FuelStationsArgs = {
  filter?: Maybe<FuelStationFilter>;
};


export type QueryGet_SomeFuelStationsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGet_BestFuelStationPricesArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  encodedPolyline?: Maybe<Scalars['String']>;
  polygon?: Maybe<GeoJsonPolygonTypeInput>;
};


export type QueryGet_ParkingLotsArgs = {
  filter?: Maybe<ParkingLotFilter>;
};


export type QueryGet_SomeParkingLotsArgs = {
  ids: Array<Scalars['Float']>;
};


export type QueryGet_UserArgs = {
  id?: Maybe<Scalars['Float']>;
};


export type QueryGet_UsersArgs = {
  filter?: Maybe<UserFilter>;
};


export type QueryGet_UserByTokenArgs = {
  token: Scalars['String'];
};


export type QueryGet_UserByCredentialsArgs = {
  loginId: Scalars['Float'];
  password: Scalars['String'];
};


export type QueryLogin_DriverArgs = {
  userId: Scalars['Float'];
  pin: Scalars['String'];
};


export type QueryGet_UserTokensArgs = {
  userId?: Maybe<Array<Scalars['Float']>>;
  deleted?: Maybe<Scalars['Float']>;
};


export type QueryGet_MonitoredObjectsArgs = {
  filter?: Maybe<MonitoredObjectFilter>;
  roles?: Maybe<Array<Scalars['String']>>;
};


export type QueryGet_MonitoredObjectArgs = {
  id: Scalars['Float'];
};


export type QueryEvent_IncreaseMonitoredObjectDataRateArgs = {
  monitoredObjectId: Scalars['Float'];
  interval: Scalars['Float'];
  duration: Scalars['Float'];
};


export type QueryGet_UnpairedMonitoredObjectsArgs = {
  roles?: Maybe<Array<Scalars['String']>>;
};


export type QueryGet_UnpairedTrailerArgs = {
  roles?: Maybe<Array<Scalars['String']>>;
};


export type QueryGet_LastTrailerPairingDetailsArgs = {
  trailerId: Scalars['Float'];
};


export type QueryGet_FuelCardsArgs = {
  deleted?: Maybe<Scalars['Float']>;
};


export type QueryGet_VehicleProfilesArgs = {
  filter?: Maybe<VehicleProfileFilter>;
};


export type QueryGet_DriverDailyStatisticArgs = {
  id: Scalars['String'];
};


export type QueryGet_DriverDailyStatisticsArgs = {
  driverId: Scalars['String'];
};


export type QueryGet_DriverMonthlyStatisticArgs = {
  id: Scalars['String'];
};


export type QueryGet_DriverMonthlyStatisticsArgs = {
  driverId: Scalars['String'];
};


export type QueryGet_DriverWeeklyStatisticArgs = {
  id: Scalars['String'];
};


export type QueryGet_DriverWeeklyStatisticsArgs = {
  driverId: Scalars['String'];
};


export type QueryPlan_TransportArgs = {
  origin: GeoJsonPointTypeInput;
  destination: GeoJsonPointTypeInput;
  vehicleWeight?: Maybe<Scalars['Float']>;
  vehicleNoOfAxles?: Maybe<Scalars['Float']>;
  vehicleHeight?: Maybe<Scalars['Float']>;
  vehicleNoOfTrailers?: Maybe<Scalars['Float']>;
  arrivalTime?: Maybe<Scalars['String']>;
  departureTime?: Maybe<Scalars['String']>;
  trafficModel?: Maybe<Scalars['String']>;
};


export type QueryPlan_TransportBatchArgs = {
  batch: Array<PlanTransportInput>;
};


export type QueryGet_TransportArgs = {
  transportId: Scalars['String'];
};


export type QueryGet_TransportsArgs = {
  monitoredObjectId?: Maybe<Scalars['Float']>;
  driverId?: Maybe<Scalars['Float']>;
  driverName?: Maybe<Scalars['String']>;
  driverSurname?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<TransportState>;
  filter?: Maybe<TransportFilter>;
  overlapFilter?: Maybe<TransportFilter>;
};


export type QueryGet_UnreadMessagesCountArgs = {
  userId: Scalars['Float'];
};


export type QueryGet_UnreadAnnouncementsCountArgs = {
  vehicleId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type QueryGet_MessagesArgs = {
  roomId: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  minDate?: Maybe<Scalars['String']>;
  maxDate?: Maybe<Scalars['String']>;
};


export type QueryGet_AnnouncementsArgs = {
  vehicleId: Scalars['Float'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  minDate?: Maybe<Scalars['String']>;
  maxDate?: Maybe<Scalars['String']>;
};


export type QueryRegisterArgs = {
  pushId: Scalars['String'];
  appVersion: Scalars['String'];
  deviceId: Scalars['String'];
};


export type QuerySendPairingCodeArgs = {
  pairingCode: Scalars['String'];
  cliendId: Scalars['Float'];
  monitoredObjectId: Scalars['Float'];
};


export type QueryRegisterMobileDeviceArgs = {
  input: RegisterDeviceInput;
};


export type QueryGet_DtcOsArgs = {
  DTCOFilter: DtcoFilter;
};


export type QueryGet_DtcoLinkArgs = {
  DTCOLinkFilter: DtcoLinkFilter;
};


export type QuerySubscribe_CcrArgs = {
  serialNumbers: Array<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
};


export type QueryGet_ClientUsersArgs = {
  clientId: Scalars['Float'];
};


export type QueryImpersonate_UserArgs = {
  ssoId: Scalars['String'];
};


export type QueryGet_ExpensesArgs = {
  filter?: Maybe<ExpenseFilter>;
};


export type QueryGet_GroupedExpensesArgs = {
  timezoneOffset: Scalars['Float'];
  filter?: Maybe<ExpenseFilter>;
  aggregator?: Maybe<ExpenseAggregator>;
};


export type QueryGet_ActualDriverStatesArgs = {
  filter?: Maybe<ActualDriverStateFilter>;
};


export type QueryGet_ActualDriverStateArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryVehiclesSubscribeArgs = {
  device: Scalars['String'];
};

export type ReadBy = {
  __typename?: 'ReadBy';
  time?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
  transportId?: Maybe<Scalars['String']>;
  place?: Maybe<MessengerPlace>;
};

export type RegisterDeviceInput = {
  imei: Scalars['String'];
  driverApplicationBuildNumber: Scalars['String'];
  pushServiceId: Scalars['String'];
};

export type RegisterDeviceResult = {
  __typename?: 'RegisterDeviceResult';
  clientId?: Maybe<Scalars['Float']>;
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  clientId?: Maybe<Scalars['Float']>;
  vehicleId?: Maybe<Scalars['Float']>;
  pairingCode?: Maybe<Scalars['String']>;
};

export type SearchQueries = {
  __typename?: 'SearchQueries';
  pointSuggestions?: Maybe<Array<PlaceSuggestion>>;
  pointsAlongPolyline?: Maybe<PlacesAlongPolyline>;
};


export type SearchQueriesPointSuggestionsArgs = {
  searchToken: Scalars['String'];
};


export type SearchQueriesPointsAlongPolylineArgs = {
  encodedPolyline: Scalars['String'];
};

export type SsoUser = {
  __typename?: 'SsoUser';
  id?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export enum StatisticsInterval {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

export enum TaskResultType {
  Pallets = 'pallets',
  Tons = 'tons',
  Items = 'items'
}

export enum TaskType {
  Parking = 'parking',
  Refueling = 'refueling',
  Loading = 'loading',
  Unloading = 'unloading'
}

export type TrailerObject = {
  __typename?: 'TrailerObject';
  id?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  customLabel?: Maybe<Scalars['String']>;
};

export type TrailerWeightObject = {
  __typename?: 'TrailerWeightObject';
  time?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type Transport = {
  __typename?: 'Transport';
  id?: Maybe<Scalars['String']>;
  monitoredObjects?: Maybe<Array<TransportMonitoredObject>>;
  desiredVehicleProfile?: Maybe<Scalars['JSON']>;
  clientId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  fuelTypes?: Maybe<Array<Scalars['String']>>;
  firstRta?: Maybe<Scalars['String']>;
  lastRta?: Maybe<Scalars['String']>;
  places?: Maybe<Array<TransportPlace>>;
  state?: Maybe<TransportState>;
  users?: Maybe<Array<TransportDriver>>;
  costPerKm?: Maybe<TransportCostPerKm>;
  client?: Maybe<Scalars['String']>;
};

export type TransportAddressStructuredInput = {
  lang: Scalars['String'];
  address: Scalars['String'];
  countryCode: Scalars['String'];
  country: Scalars['String'];
  town: Scalars['String'];
  route: Scalars['String'];
  street_address: Scalars['String'];
  postalCode: Scalars['String'];
};

export type TransportCostPerKm = {
  __typename?: 'TransportCostPerKm';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type TransportCostPerKmInput = {
  currency: Scalars['String'];
  value: Scalars['Float'];
};

export type TransportDriver = {
  __typename?: 'TransportDriver';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type TransportDriverInput = {
  id: Scalars['Float'];
  name: Scalars['String'];
  surname: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type TransportEventRule = {
  __typename?: 'TransportEventRule';
  name?: Maybe<EventRuleNameType>;
  config?: Maybe<Array<TransportEventRuleConfigRule>>;
};

export type TransportEventRuleConfigRule = {
  __typename?: 'TransportEventRuleConfigRule';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type TransportEventRuleConfigRuleInput = {
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type TransportEventRuleInput = {
  name: EventRuleNameType;
  config: Array<TransportEventRuleConfigRuleInput>;
};

export type TransportFilter = {
  startTime?: Scalars['String'];
  endTime?: Scalars['String'];
};

export type TransportInput = {
  id?: Maybe<Scalars['String']>;
  monitoredObjects?: Maybe<Array<TransportMonitoredObjectInput>>;
  desiredVehicleProfile?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  fuelTypes?: Maybe<Array<Scalars['String']>>;
  firstRta: Scalars['String'];
  lastRta: Scalars['String'];
  places: Array<TransportPlaceInput>;
  state?: Maybe<TransportState>;
  users?: Maybe<Array<TransportDriverInput>>;
  costPerKm?: Maybe<TransportCostPerKmInput>;
  client?: Maybe<Scalars['String']>;
};

export type TransportMonitoredObject = {
  __typename?: 'TransportMonitoredObject';
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  monitoredObjectId?: Maybe<Scalars['Float']>;
};

export type TransportMonitoredObjectInput = {
  startTime: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  monitoredObjectId: Scalars['Float'];
};

export type TransportPlace = {
  __typename?: 'TransportPlace';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rta?: Maybe<Scalars['String']>;
  rtd?: Maybe<Scalars['String']>;
  ata?: Maybe<Scalars['String']>;
  atd?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  etd?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  actualDistance?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  eventRules?: Maybe<Array<TransportEventRule>>;
  eventStates?: Maybe<Array<TransportPlaceEventState>>;
  type?: Maybe<PlaceType>;
  legs?: Maybe<Array<TransportStep>>;
  tasks?: Maybe<Array<TransportPlaceTask>>;
  center?: Maybe<GeoJsonPointType>;
  polygon?: Maybe<GeoJsonPolygonType>;
  addressStructured?: Maybe<Array<AddressStructured>>;
};

export type TransportPlaceEventState = {
  __typename?: 'TransportPlaceEventState';
  eventRuleName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Float']>;
};

export type TransportPlaceEventStateTypeInput = {
  eventRuleName: Scalars['String'];
  state: Scalars['Float'];
};

export type TransportPlaceInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rta: Scalars['String'];
  rtd?: Maybe<Scalars['String']>;
  ata?: Maybe<Scalars['String']>;
  atd?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  etd?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  actualDistance?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  eventRules?: Maybe<Array<TransportEventRuleInput>>;
  eventStates?: Maybe<Array<TransportPlaceEventStateTypeInput>>;
  type: PlaceType;
  legs?: Maybe<Array<TransportStepInput>>;
  tasks?: Maybe<Array<TransportPlaceTaskInput>>;
  center: GeoJsonPointTypeInput;
  polygon: GeoJsonPolygonTypeInput;
  customPlace?: Maybe<Scalars['String']>;
  addressStructured?: Maybe<Array<TransportAddressStructuredInput>>;
};

export type TransportPlaceTask = {
  __typename?: 'TransportPlaceTask';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  description?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  activityStartTime?: Maybe<Scalars['String']>;
  activityEndTime?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  resultUnit?: Maybe<TaskResultType>;
};

export type TransportPlaceTaskInput = {
  id: Scalars['String'];
  type: TaskType;
  description?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  activityStartTime?: Maybe<Scalars['String']>;
  activityEndTime?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  resultUnit?: Maybe<TaskResultType>;
};

export type TransportPlanPiece = {
  __typename?: 'TransportPlanPiece';
  routesCount?: Maybe<Scalars['Float']>;
  routes?: Maybe<Array<TransportPlanPieceRoute>>;
};

export type TransportPlanPieceRoute = {
  __typename?: 'TransportPlanPieceRoute';
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  route?: Maybe<Scalars['String']>;
  legs?: Maybe<Array<TransportPlanPieceRouteStep>>;
  parkingLots?: Maybe<Array<ParkingLot>>;
  fuelStations?: Maybe<Array<FuelStation>>;
};

export type TransportPlanPieceRouteStep = {
  __typename?: 'TransportPlanPieceRouteStep';
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  route?: Maybe<Scalars['String']>;
};

export enum TransportState {
  Planned = 'planned',
  Active = 'active',
  Rejected = 'rejected',
  Delayed = 'delayed',
  Finished = 'finished',
  Assigned = 'assigned',
  Canceled = 'canceled',
  New = 'new',
  Accepted = 'accepted'
}

export type TransportStep = {
  __typename?: 'TransportStep';
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  route?: Maybe<Scalars['String']>;
};

export type TransportStepInput = {
  distance: Scalars['Float'];
  duration: Scalars['Float'];
  route: Scalars['String'];
};

export type TransportUpdateType = {
  id?: Maybe<Scalars['String']>;
  monitoredObjects?: Maybe<Array<TransportMonitoredObjectInput>>;
  desiredVehicleProfile?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  fuelTypes?: Maybe<Array<Scalars['String']>>;
  firstRta?: Maybe<Scalars['String']>;
  lastRta?: Maybe<Scalars['String']>;
  places?: Maybe<Array<TransportPlaceInput>>;
  state?: Maybe<TransportState>;
  users?: Maybe<Array<TransportDriverInput>>;
  costPerKm?: Maybe<TransportCostPerKmInput>;
  client?: Maybe<Scalars['String']>;
};

export type UnreadMessagesCount = {
  __typename?: 'UnreadMessagesCount';
  roomId?: Maybe<Scalars['String']>;
  unreadMessages?: Maybe<Scalars['Float']>;
};

export type UpdateUserInput = {
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  contact: UserContactsInput;
  pin?: Maybe<Scalars['String']>;
  tokens: Array<UserTokenInput>;
  password?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['Float']>;
  client_id?: Maybe<Scalars['Float']>;
  monitored_object_group_id?: Maybe<Scalars['Float']>;
  sso_id?: Maybe<Scalars['String']>;
  loginCredentials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  contact?: Maybe<UserContactsType>;
  note?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  Groups?: Maybe<Array<UserGroup>>;
  Tokens?: Maybe<Array<UserTokenType>>;
};

export type UserContactsInput = {
  emails: Array<Scalars['String']>;
  phone_numbers: Array<Scalars['String']>;
};

export type UserContactsType = {
  __typename?: 'UserContactsType';
  emails?: Maybe<Array<Scalars['String']>>;
  phone_numbers?: Maybe<Array<Scalars['String']>>;
};

export type UserDelivery = {
  __typename?: 'UserDelivery';
  time?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
};

export type UserFilter = {
  note?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userGroup?: Maybe<Scalars['String']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id?: Maybe<Scalars['Float']>;
  client_id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type UserTokenInput = {
  id: Scalars['Float'];
  token: Scalars['String'];
  holder?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  token_type: Scalars['String'];
  startTime: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  deleted: Scalars['Float'];
};

export type UserTokenType = {
  __typename?: 'UserTokenType';
  id?: Maybe<Scalars['Float']>;
  token?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
  userName?: Maybe<Scalars['String']>;
  userSurname?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Float']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  id?: Maybe<Scalars['Float']>;
  registrationNumber?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  idlingDuration?: Maybe<Scalars['Float']>;
  additionalDeviceDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  averageConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
  averageMovementConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  averageIdlingConsumption?: Maybe<Scalars['Float']>;
  totalAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
  averageAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
};

export type VehicleDriverObject = {
  __typename?: 'VehicleDriverObject';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  state?: Maybe<VehicleDriverStateObject>;
  state_new?: Maybe<VehicleDriverStateNewObject>;
  driver_token?: Maybe<Scalars['String']>;
};

export type VehicleDriverStateNewObject = {
  __typename?: 'VehicleDriverStateNewObject';
  timestamp?: Maybe<Scalars['String']>;
  activityStartTime?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  utilizationStart?: Maybe<Scalars['String']>;
  nextBreakStartTime?: Maybe<Scalars['String']>;
  drivingDuration?: Maybe<Scalars['Float']>;
  restingDuration?: Maybe<Scalars['Float']>;
  workingDuration?: Maybe<Scalars['Float']>;
  dailyUtilizationEnd?: Maybe<Scalars['String']>;
  dailyUtilization?: Maybe<Scalars['Float']>;
  dailyUtilizationMaxHours?: Maybe<Scalars['Float']>;
  dailyDriveMaxHours?: Maybe<Scalars['Float']>;
  weeklyExtendedShifts?: Maybe<Scalars['Float']>;
  drivingDuration14days?: Maybe<Scalars['Float']>;
};

export type VehicleDriverStateObject = {
  __typename?: 'VehicleDriverStateObject';
  activityStartTime?: Maybe<Scalars['String']>;
  drivingOrWorkingStartTime?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  nextBreakStartTime?: Maybe<Scalars['String']>;
  nextBreakStartTime2?: Maybe<Scalars['String']>;
  drivingDuration?: Maybe<Scalars['Float']>;
  restingDuration?: Maybe<Scalars['Float']>;
  workingDuration?: Maybe<Scalars['Float']>;
  biweeklyDrivingDuration?: Maybe<Scalars['Float']>;
  choppedDrivingTimeCapacity?: Maybe<Scalars['Float']>;
  weeklyDrivingDuration?: Maybe<Scalars['Float']>;
};

export type VehicleFuelTankObject = {
  __typename?: 'VehicleFuelTankObject';
  time?: Maybe<Scalars['String']>;
  raw_value?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  liters?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type VehicleGpsDataObject = {
  __typename?: 'VehicleGPSDataObject';
  time?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  alt?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  angle?: Maybe<Scalars['Float']>;
};

export type VehicleProfile = {
  __typename?: 'VehicleProfile';
  id?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  euroClass?: Maybe<Scalars['String']>;
  numberOfAxles?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  pricePerKm?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  trailersCount?: Maybe<Scalars['Float']>;
  tunnel?: Maybe<Scalars['String']>;
};

export type VehicleProfileFilter = {
  euroClass?: Maybe<Scalars['String']>;
  numberOfAxles?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type VehicleProfileInput = {
  euroClass?: Maybe<Scalars['String']>;
  numberOfAxles?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  pricePerKm?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  trailersCount?: Maybe<Scalars['Float']>;
  tunnel?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  fuelType?: Maybe<EFuelType>;
};

export type VehicleStateDataObject = {
  __typename?: 'VehicleStateDataObject';
  time?: Maybe<Scalars['String']>;
  ignition?: Maybe<Scalars['Float']>;
};

export type VehicleStateObject = {
  __typename?: 'VehicleStateObject';
  monitoredObjectId?: Maybe<Scalars['String']>;
  customLabel?: Maybe<Scalars['String']>;
  gpsData?: Maybe<VehicleGpsDataObject>;
  stateData?: Maybe<VehicleStateDataObject>;
  fuelTanks?: Maybe<Array<VehicleFuelTankObject>>;
  external_devices?: Maybe<Array<ExternalDeviceObject>>;
  address?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['Float']>;
  rn?: Maybe<Scalars['String']>;
  driver?: Maybe<VehicleDriverObject>;
  drivers?: Maybe<Array<VehicleDriverObject>>;
  activeTransports?: Maybe<Array<VehicleTransportObject>>;
  last_change_state?: Maybe<Scalars['String']>;
  address_structured?: Maybe<Array<AddressStructured>>;
  trailers?: Maybe<Array<TrailerObject>>;
  monitoredObjectFuelType?: Maybe<EFuelType>;
  monitoredObjectType?: Maybe<MonitoredObjectTypeObject>;
  charging?: Maybe<ChargingObject>;
  weight?: Maybe<TrailerWeightObject>;
  adblue_level?: Maybe<AdblueLevelObject>;
  car_battery?: Maybe<CarBatteryObject>;
  disabledAt?: Maybe<Scalars['String']>;
  monitoredObjectGroups?: Maybe<Array<MonitoredObjectGroupType>>;
  tankSize?: Maybe<Scalars['Float']>;
};

export type VehicleTransportNextWayPointObject = {
  __typename?: 'VehicleTransportNextWayPointObject';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  rta?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  estimatedDistance?: Maybe<Scalars['Float']>;
  remainingDistancePercentage?: Maybe<Scalars['Float']>;
  addressStructured?: Maybe<Array<AddressStructured>>;
};

export type VehicleTransportObject = {
  __typename?: 'VehicleTransportObject';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstRta?: Maybe<Scalars['String']>;
  nextWaypoint?: Maybe<VehicleTransportNextWayPointObject>;
  clientId?: Maybe<Scalars['String']>;
  activeEventRules?: Maybe<Array<ActiveEventRules>>;
};

export type WeeklyDriverStatistics = {
  __typename?: 'WeeklyDriverStatistics';
  id?: Maybe<Scalars['String']>;
  driverID?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  dailyDriverStatisticsIDS?: Maybe<Array<Scalars['String']>>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  idlingDuration?: Maybe<Scalars['Float']>;
  additionalDeviceDuration?: Maybe<Scalars['Float']>;
  totalConsumption?: Maybe<Scalars['Float']>;
  averageConsumption?: Maybe<Scalars['Float']>;
  totalMovementConsumption?: Maybe<Scalars['Float']>;
  averageMovementConsumption?: Maybe<Scalars['Float']>;
  totalIdlingConsumption?: Maybe<Scalars['Float']>;
  averageIdlingConsumption?: Maybe<Scalars['Float']>;
  totalAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
  averageAdditionalDeviceConsumption?: Maybe<Scalars['Float']>;
};

export type WeeklyRestDebt = {
  __typename?: 'WeeklyRestDebt';
  duration?: Maybe<Scalars['Float']>;
  dueTime?: Maybe<Scalars['String']>;
};

export type Assign_CcrMutationVariables = Exact<{
  ccrInput: CcrInput;
}>;


export type Assign_CcrMutation = (
  { __typename?: 'Mutation' }
  & { assign_CCR?: Maybe<(
    { __typename?: 'CCR' }
    & Pick<Ccr, 'id'>
  )> }
);

export type Add_CustomPlaceMutationVariables = Exact<{
  placeData: CustomPlaceAddInput;
}>;


export type Add_CustomPlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'add_CustomPlace'>
);

export type Create_FuelCardsMutationVariables = Exact<{
  fuelCards: Array<FuelCardInput> | FuelCardInput;
}>;


export type Create_FuelCardsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'create_FuelCards'>
);

export type Create_VehicleProfileMutationVariables = Exact<{
  vehicleProfile: VehicleProfileInput;
}>;


export type Create_VehicleProfileMutation = (
  { __typename?: 'Mutation' }
  & { create_VehicleProfile?: Maybe<(
    { __typename?: 'VehicleProfile' }
    & Pick<VehicleProfile, 'id' | 'euroClass' | 'numberOfAxles' | 'weight' | 'name' | 'pricePerKm'>
  )> }
);

export type UpsertExpenseMutationVariables = Exact<{
  input: ExpenseInput;
}>;


export type UpsertExpenseMutation = (
  { __typename?: 'Mutation' }
  & { upsert_Expense?: Maybe<(
    { __typename?: 'Expense' }
    & Pick<Expense, 'id'>
  )> }
);

export type Create_TrailerMutationVariables = Exact<{
  licensePlateNumber: Scalars['String'];
  ownName: Scalars['String'];
  manufacturerName: Scalars['String'];
  trailerProfile?: Maybe<InputTrailerProfile>;
}>;


export type Create_TrailerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'create_Trailer'>
);

export type Get_CustomPlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_CustomPlacesQuery = (
  { __typename?: 'Query' }
  & { get_CustomPlaces?: Maybe<Array<(
    { __typename?: 'CustomPlace' }
    & Pick<CustomPlace, '_id' | 'address' | 'type' | 'polygonType' | 'name' | 'forbidden' | 'notes' | 'countryCode'>
    & { center?: Maybe<(
      { __typename?: 'GeoJSONPointType' }
      & Pick<GeoJsonPointType, 'coordinates'>
    )>, polygon?: Maybe<(
      { __typename?: 'GeoJSONPolygonType' }
      & Pick<GeoJsonPolygonType, 'coordinates'>
    )> }
  )>> }
);

export type Delete_CustomPlaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type Delete_CustomPlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'delete_CustomPlace'>
);

export type Delete_ExpenseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type Delete_ExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'delete_Expense'>
);

export type DeleteProfileMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'delete_VehicleProfile'>
);

export type Delete_TrailerMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type Delete_TrailerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'delete_Trailer'>
);

export type FuelCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type FuelCompaniesQuery = (
  { __typename?: 'Query' }
  & { get_FuelCompanies?: Maybe<Array<(
    { __typename?: 'FuelCompany' }
    & Pick<FuelCompany, 'id' | 'name' | 'label'>
  )>> }
);

export type Get_CcRsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_CcRsQuery = (
  { __typename?: 'Query' }
  & { get_CCRs?: Maybe<Array<(
    { __typename?: 'CCR' }
    & Pick<Ccr, 'id' | 'card_reader_sn' | 'state'>
  )>> }
);

export type Get_FuelCardsQueryVariables = Exact<{
  deleted?: Maybe<Scalars['Float']>;
}>;


export type Get_FuelCardsQuery = (
  { __typename?: 'Query' }
  & { get_FuelCards?: Maybe<Array<(
    { __typename?: 'FuelCard' }
    & Pick<FuelCard, 'id' | 'number' | 'holder' | 'fuelCompanyId' | 'clientId' | 'expirationDate' | 'limitAmount' | 'limitDuration'>
    & { monitoredObject?: Maybe<(
      { __typename?: 'MonitoredObject' }
      & Pick<MonitoredObject, 'id' | 'monitoringDeviceId' | 'clientId' | 'monitoredObjectTypeId' | 'vehicleProfileId' | 'registrationNumber'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'surname'>
    )> }
  )>> }
);

export type GetUserQueryVariables = Exact<{
  id?: Maybe<Scalars['Float']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { get_User?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'sso_id' | 'name' | 'surname' | 'note' | 'monitored_object_group_id' | 'client_id' | 'deleted' | 'active'>
    & { contact?: Maybe<(
      { __typename?: 'UserContactsType' }
      & Pick<UserContactsType, 'emails' | 'phone_numbers'>
    )>, Groups?: Maybe<Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'label' | 'client_id'>
    )>> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  filter?: Maybe<UserFilter>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { get_Users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'sso_id' | 'name' | 'surname' | 'userName' | 'note' | 'monitored_object_group_id' | 'client_id' | 'loginCredentials' | 'deleted' | 'active'>
    & { contact?: Maybe<(
      { __typename?: 'UserContactsType' }
      & Pick<UserContactsType, 'emails' | 'phone_numbers'>
    )>, Tokens?: Maybe<Array<(
      { __typename?: 'UserTokenType' }
      & Pick<UserTokenType, 'id' | 'token' | 'userId' | 'token_type' | 'startTime' | 'endTime' | 'deleted'>
    )>>, Groups?: Maybe<Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'label' | 'client_id'>
    )>> }
  )>> }
);

export type Get_GroupedExpensesQueryVariables = Exact<{
  timezoneOffset: Scalars['Float'];
  aggregator?: Maybe<ExpenseAggregator>;
  filter: ExpenseFilter;
}>;


export type Get_GroupedExpensesQuery = (
  { __typename?: 'Query' }
  & { get_GroupedExpenses?: Maybe<(
    { __typename?: 'ExpenseGroupList' }
    & { data?: Maybe<Array<(
      { __typename?: 'ExpenseGroup' }
      & Pick<ExpenseGroup, 'exchangeRateErr' | 'key'>
      & { main?: Maybe<(
        { __typename?: 'Expense' }
        & Pick<Expense, 'id' | 'unitPriceInclVatAverage' | 'unitPriceWithoutVatAverage' | 'totalPriceWithoutVat' | 'unitPriceWithoutVat' | 'odometer' | 'clientId' | 'driverName' | 'type' | 'expenseType' | 'timestamp' | 'originalUnitPriceInclVat' | 'originalTotalPriceInclVat' | 'unitPriceInclVat' | 'unitPriceVat' | 'totalPriceInclVat' | 'totalPriceVat' | 'saved' | 'savedCurrency' | 'units' | 'currency' | 'country' | 'vatRate' | 'state' | 'fuelioTotemPrice' | 'fuelioTotemPriceCurrency'>
        & { monitoredObject?: Maybe<(
          { __typename?: 'MonitoredObject' }
          & Pick<MonitoredObject, 'id' | 'clientId' | 'disabledAt' | 'monitoringDeviceId' | 'monitoredObjectTypeId' | 'vehicleProfileId' | 'externalId' | 'registrationNumber' | 'description' | 'systemDescription' | 'manufacturerName' | 'configuration' | 'metadata' | 'deleted'>
          & { vehicleProfile?: Maybe<(
            { __typename?: 'VehicleProfile' }
            & Pick<VehicleProfile, 'id'>
          )>, fuelCards?: Maybe<Array<(
            { __typename?: 'MonitoredObjectFuelCard' }
            & Pick<MonitoredObjectFuelCard, 'id'>
          )>> }
        )>, fuelStation?: Maybe<(
          { __typename?: 'FuelStation' }
          & Pick<FuelStation, 'id' | 'name' | 'lat' | 'lon'>
          & { center?: Maybe<(
            { __typename?: 'GeoJSONPointType' }
            & Pick<GeoJsonPointType, 'type' | 'coordinates' | 'bbox'>
          )>, polygon?: Maybe<(
            { __typename?: 'GeoJSONPolygonType' }
            & Pick<GeoJsonPolygonType, 'type' | 'coordinates' | 'bbox'>
          )>, services?: Maybe<Array<(
            { __typename?: 'CodeNamePair' }
            & Pick<CodeNamePair, 'code' | 'name'>
          )>>, fuelTypes?: Maybe<Array<(
            { __typename?: 'FuelType' }
            & Pick<FuelType, 'code' | 'name'>
            & { price?: Maybe<(
              { __typename?: 'FuelPrice' }
              & Pick<FuelPrice, 'price' | 'source' | 'currency' | 'productId' | 'type' | 'sumExtraFees'>
            )> }
          )>> }
        )>, expenseMetadata?: Maybe<(
          { __typename?: 'ExpenseMetadata' }
          & Pick<ExpenseMetadata, 'externalTransactionId' | 'merchantId' | 'terminalId' | 'authCode' | 'fuelCardCode' | 'fuelCardIssuer' | 'fuelCardType' | 'merchantCountry'>
        )>, fraudDetectionResult?: Maybe<(
          { __typename?: 'FraudDetectionResult' }
          & Pick<FraudDetectionResult, 'state' | 'checked'>
          & { monitoredObjectPositionData?: Maybe<(
            { __typename?: 'ExpensePositionData' }
            & Pick<ExpensePositionData, 'lat' | 'lon' | 'time'>
          )>, expensePositionData?: Maybe<(
            { __typename?: 'ExpensePositionData' }
            & Pick<ExpensePositionData, 'lat' | 'lon' | 'time'>
          )> }
        )> }
      )>, details?: Maybe<Array<(
        { __typename?: 'Expense' }
        & Pick<Expense, 'id' | 'odometer' | 'clientId' | 'driverName' | 'type' | 'expenseType' | 'timestamp' | 'originalUnitPriceInclVat' | 'originalTotalPriceInclVat' | 'unitPriceInclVat' | 'unitPriceVat' | 'totalPriceInclVat' | 'totalPriceVat' | 'totalPriceWithoutVat' | 'unitPriceWithoutVat' | 'saved' | 'savedCurrency' | 'units' | 'currency' | 'country' | 'vatRate' | 'state' | 'fuelioTotemPrice' | 'fuelioTotemPriceCurrency'>
        & { monitoredObject?: Maybe<(
          { __typename?: 'MonitoredObject' }
          & Pick<MonitoredObject, 'id' | 'clientId' | 'disabledAt' | 'monitoringDeviceId' | 'monitoredObjectTypeId' | 'vehicleProfileId' | 'externalId' | 'registrationNumber' | 'description' | 'systemDescription' | 'manufacturerName' | 'configuration' | 'metadata' | 'deleted'>
          & { vehicleProfile?: Maybe<(
            { __typename?: 'VehicleProfile' }
            & Pick<VehicleProfile, 'id'>
          )>, fuelCards?: Maybe<Array<(
            { __typename?: 'MonitoredObjectFuelCard' }
            & Pick<MonitoredObjectFuelCard, 'id'>
          )>> }
        )>, fuelStation?: Maybe<(
          { __typename?: 'FuelStation' }
          & Pick<FuelStation, 'id' | 'name' | 'lat' | 'lon'>
          & { center?: Maybe<(
            { __typename?: 'GeoJSONPointType' }
            & Pick<GeoJsonPointType, 'type' | 'coordinates' | 'bbox'>
          )>, polygon?: Maybe<(
            { __typename?: 'GeoJSONPolygonType' }
            & Pick<GeoJsonPolygonType, 'type' | 'coordinates' | 'bbox'>
          )>, services?: Maybe<Array<(
            { __typename?: 'CodeNamePair' }
            & Pick<CodeNamePair, 'code' | 'name'>
          )>>, fuelTypes?: Maybe<Array<(
            { __typename?: 'FuelType' }
            & Pick<FuelType, 'code' | 'name'>
            & { price?: Maybe<(
              { __typename?: 'FuelPrice' }
              & Pick<FuelPrice, 'price' | 'source' | 'currency' | 'productId' | 'type' | 'sumExtraFees'>
            )> }
          )>> }
        )>, expenseMetadata?: Maybe<(
          { __typename?: 'ExpenseMetadata' }
          & Pick<ExpenseMetadata, 'externalTransactionId' | 'merchantId' | 'terminalId' | 'authCode' | 'fuelCardCode' | 'fuelCardIssuer' | 'fuelCardType' | 'merchantCountry'>
        )>, fraudDetectionResult?: Maybe<(
          { __typename?: 'FraudDetectionResult' }
          & Pick<FraudDetectionResult, 'state' | 'checked'>
          & { monitoredObjectPositionData?: Maybe<(
            { __typename?: 'ExpensePositionData' }
            & Pick<ExpensePositionData, 'lat' | 'lon' | 'time'>
          )>, expensePositionData?: Maybe<(
            { __typename?: 'ExpensePositionData' }
            & Pick<ExpensePositionData, 'lat' | 'lon' | 'time'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type ImpersonateUserQueryVariables = Exact<{
  ssoId: Scalars['String'];
}>;


export type ImpersonateUserQuery = (
  { __typename?: 'Query' }
  & { impersonate_User?: Maybe<(
    { __typename?: 'AuthToken' }
    & Pick<AuthToken, 'access_token' | 'refresh_token' | 'expires_in' | 'refresh_expires_in' | 'token_type' | 'user_id'>
  )> }
);

export type Import_ExpenseMutationVariables = Exact<{
  file: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type Import_ExpenseMutation = (
  { __typename?: 'Mutation' }
  & { import_Expense?: Maybe<(
    { __typename?: 'ExpenseImport' }
    & Pick<ExpenseImport, 'status' | 'data'>
  )> }
);

export type IncreaseMoDataRateQueryVariables = Exact<{
  monitoredObjectId: Scalars['Float'];
  interval: Scalars['Float'];
  duration: Scalars['Float'];
}>;


export type IncreaseMoDataRateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'event_IncreaseMonitoredObjectDataRate'>
);

export type PointSuggestionsQueryVariables = Exact<{
  searchToken: Scalars['String'];
}>;


export type PointSuggestionsQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchQueries' }
    & { pointSuggestions?: Maybe<Array<(
      { __typename?: 'PlaceSuggestion' }
      & Pick<PlaceSuggestion, 'label' | 'source' | 'id'>
      & { polygon?: Maybe<(
        { __typename?: 'GeoJSONPolygonType' }
        & Pick<GeoJsonPolygonType, 'type' | 'coordinates' | 'bbox'>
      )>, position?: Maybe<(
        { __typename?: 'GeoJSONPointType' }
        & Pick<GeoJsonPointType, 'type' | 'coordinates' | 'bbox'>
      )> }
    )>> }
  )> }
);

export type Subscribe_CcrQueryVariables = Exact<{
  serialNumbers: Array<Scalars['String']> | Scalars['String'];
  device: Scalars['String'];
}>;


export type Subscribe_CcrQuery = (
  { __typename?: 'Query' }
  & { subscribe_CCR?: Maybe<Array<(
    { __typename?: 'CCRStateObject' }
    & Pick<CcrStateObject, 'card_reader_sn' | 'state'>
  )>> }
);

export type Unassign_CcrMutationVariables = Exact<{
  ccrUpdate: CcrUpdate;
}>;


export type Unassign_CcrMutation = (
  { __typename?: 'Mutation' }
  & { unassign_CCR?: Maybe<(
    { __typename?: 'CCR' }
    & Pick<Ccr, 'id'>
  )> }
);

export type Update_CustomPlaceMutationVariables = Exact<{
  placeData: CustomPlaceUpdateInput;
}>;


export type Update_CustomPlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'update_CustomPlace'>
);

export type Update_FuelCardsMutationVariables = Exact<{
  fuelCards: Array<FuelCardInput> | FuelCardInput;
}>;


export type Update_FuelCardsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'update_FuelCards'>
);

export type Get_VehicleProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_VehicleProfilesQuery = (
  { __typename?: 'Query' }
  & { get_VehicleProfiles?: Maybe<Array<(
    { __typename?: 'VehicleProfile' }
    & Pick<VehicleProfile, 'id' | 'height' | 'length' | 'numberOfAxles' | 'trailersCount' | 'tunnel' | 'weight' | 'width' | 'euroClass' | 'name' | 'pricePerKm'>
  )>> }
);

export type VehiclesSubscribeQueryVariables = Exact<{
  device: Scalars['String'];
}>;


export type VehiclesSubscribeQuery = (
  { __typename?: 'Query' }
  & { vehiclesSubscribe?: Maybe<Array<(
    { __typename?: 'VehicleStateObject' }
    & Pick<VehicleStateObject, 'rn' | 'disabledAt' | 'monitoredObjectFuelType' | 'customLabel' | 'monitoredObjectId' | 'address' | 'odometer' | 'tankSize' | 'last_change_state'>
    & { monitoredObjectType?: Maybe<(
      { __typename?: 'MonitoredObjectTypeObject' }
      & Pick<MonitoredObjectTypeObject, 'id' | 'name' | 'label'>
    )>, monitoredObjectGroups?: Maybe<Array<(
      { __typename?: 'MonitoredObjectGroupType' }
      & Pick<MonitoredObjectGroupType, 'id' | 'name' | 'clientId' | 'color' | 'monitoredObjectGroupTypeId'>
    )>>, trailers?: Maybe<Array<(
      { __typename?: 'TrailerObject' }
      & Pick<TrailerObject, 'id' | 'registrationNumber' | 'customLabel'>
    )>>, gpsData?: Maybe<(
      { __typename?: 'VehicleGPSDataObject' }
      & Pick<VehicleGpsDataObject, 'time' | 'lat' | 'lon' | 'alt' | 'speed' | 'angle'>
    )>, stateData?: Maybe<(
      { __typename?: 'VehicleStateDataObject' }
      & Pick<VehicleStateDataObject, 'time' | 'ignition'>
    )>, driver?: Maybe<(
      { __typename?: 'VehicleDriverObject' }
      & Pick<VehicleDriverObject, 'name' | 'id' | 'driver_token' | 'source' | 'time'>
      & { state?: Maybe<(
        { __typename?: 'VehicleDriverStateObject' }
        & Pick<VehicleDriverStateObject, 'activityStartTime' | 'drivingOrWorkingStartTime' | 'activity' | 'nextBreakStartTime' | 'drivingDuration' | 'restingDuration' | 'workingDuration' | 'biweeklyDrivingDuration' | 'choppedDrivingTimeCapacity' | 'nextBreakStartTime2' | 'weeklyDrivingDuration'>
      )>, state_new?: Maybe<(
        { __typename?: 'VehicleDriverStateNewObject' }
        & Pick<VehicleDriverStateNewObject, 'activityStartTime' | 'activity' | 'utilizationStart' | 'nextBreakStartTime' | 'drivingDuration' | 'workingDuration' | 'restingDuration' | 'dailyUtilizationEnd' | 'dailyUtilization' | 'dailyUtilizationMaxHours' | 'timestamp' | 'dailyDriveMaxHours' | 'weeklyExtendedShifts' | 'drivingDuration14days'>
      )> }
    )>, drivers?: Maybe<Array<(
      { __typename?: 'VehicleDriverObject' }
      & Pick<VehicleDriverObject, 'name' | 'id' | 'driver_token' |  'source' | 'time'>
      & { state?: Maybe<(
        { __typename?: 'VehicleDriverStateObject' }
        & Pick<VehicleDriverStateObject, 'activityStartTime' | 'drivingOrWorkingStartTime' | 'activity' | 'nextBreakStartTime' | 'drivingDuration' | 'restingDuration' | 'workingDuration' | 'biweeklyDrivingDuration' | 'choppedDrivingTimeCapacity' | 'nextBreakStartTime2' | 'weeklyDrivingDuration'>
      )>, state_new?: Maybe<(
        { __typename?: 'VehicleDriverStateNewObject' }
        & Pick<VehicleDriverStateNewObject, 'activityStartTime' | 'activity' | 'utilizationStart' | 'nextBreakStartTime' | 'drivingDuration' | 'workingDuration' | 'restingDuration' | 'dailyUtilizationEnd' | 'dailyUtilization' | 'dailyUtilizationMaxHours' | 'timestamp' | 'dailyDriveMaxHours' | 'weeklyExtendedShifts' | 'drivingDuration14days'>
      )> }
    )>>, fuelTanks?: Maybe<Array<(
      { __typename?: 'VehicleFuelTankObject' }
      & Pick<VehicleFuelTankObject, 'time' | 'raw_value' | 'level' | 'liters' | 'id'>
    )>>, address_structured?: Maybe<Array<(
      { __typename?: 'AddressStructured' }
      & Pick<AddressStructured, 'lang' | 'address' | 'countryCode' | 'country' | 'town' | 'route' | 'street_address' | 'postalCode'>
    )>>, activeTransports?: Maybe<Array<(
      { __typename?: 'VehicleTransportObject' }
      & Pick<VehicleTransportObject, 'id' | 'name' | 'firstRta' | 'clientId'>
      & { nextWaypoint?: Maybe<(
        { __typename?: 'VehicleTransportNextWayPointObject' }
        & Pick<VehicleTransportNextWayPointObject, 'lat' | 'lon' | 'name' | 'rta' | 'eta' | 'estimatedDistance' | 'remainingDistancePercentage'>
        & { addressStructured?: Maybe<Array<(
          { __typename?: 'AddressStructured' }
          & Pick<AddressStructured, 'lang' | 'address' | 'countryCode' | 'country' | 'town' | 'route' | 'street_address' | 'postalCode'>
        )>> }
      )>, activeEventRules?: Maybe<Array<(
        { __typename?: 'ActiveEventRules' }
        & Pick<ActiveEventRules, 'name'>
        & { config?: Maybe<Array<(
          { __typename?: 'ActiveEventConfig' }
          & Pick<ActiveEventConfig, 'name' | 'value'>
        )>> }
      )>> }
    )>>, charging?: Maybe<(
      { __typename?: 'ChargingObject' }
      & Pick<ChargingObject, 'time' | 'on'>
    )>, weight?: Maybe<(
      { __typename?: 'TrailerWeightObject' }
      & Pick<TrailerWeightObject, 'time' | 'weight'>
    )>, adblue_level?: Maybe<(
      { __typename?: 'AdblueLevelObject' }
      & Pick<AdblueLevelObject, 'time' | 'level'>
    )>, car_battery?: Maybe<(
      { __typename?: 'CarBatteryObject' }
      & Pick<CarBatteryObject, 'timestamp' | 'volts'>
    )>, external_devices?: Maybe<Array<(
      { __typename?: 'ExternalDeviceObject' }
      & Pick<ExternalDeviceObject, 'serial_number' | 'device_id' | 'device_type' | 'tfrom'>
      & { metadata?: Maybe<(
        { __typename?: 'ExternalDeviceMetadataObject' }
        & Pick<ExternalDeviceMetadataObject, 'registration_number' | 'sensor_zone' | 'sensor_name' | 'sensor_type'>
      )>, data?: Maybe<(
        { __typename?: 'ExternalDeviceDataObject' }
        & Pick<ExternalDeviceDataObject, 'temperature' | 'error'>
      )> }
    )>> }
  )>> }
);


export const Assign_CcrDocument = gql`
    mutation assign_CCR($ccrInput: CCRInput!) {
  assign_CCR(ccrInput: $ccrInput) {
    id
  }
}
    `;
export type Assign_CcrMutationFn = Apollo.MutationFunction<Assign_CcrMutation, Assign_CcrMutationVariables>;

/**
 * __useAssign_CcrMutation__
 *
 * To run a mutation, you first call `useAssign_CcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssign_CcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCcrMutation, { data, loading, error }] = useAssign_CcrMutation({
 *   variables: {
 *      ccrInput: // value for 'ccrInput'
 *   },
 * });
 */
export function useAssign_CcrMutation(baseOptions?: Apollo.MutationHookOptions<Assign_CcrMutation, Assign_CcrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Assign_CcrMutation, Assign_CcrMutationVariables>(Assign_CcrDocument, options);
      }
export type Assign_CcrMutationHookResult = ReturnType<typeof useAssign_CcrMutation>;
export type Assign_CcrMutationResult = Apollo.MutationResult<Assign_CcrMutation>;
export type Assign_CcrMutationOptions = Apollo.BaseMutationOptions<Assign_CcrMutation, Assign_CcrMutationVariables>;
export const Add_CustomPlaceDocument = gql`
    mutation add_CustomPlace($placeData: CustomPlaceAddInput!) {
  add_CustomPlace(placeData: $placeData)
}
    `;
export type Add_CustomPlaceMutationFn = Apollo.MutationFunction<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>;

/**
 * __useAdd_CustomPlaceMutation__
 *
 * To run a mutation, you first call `useAdd_CustomPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_CustomPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomPlaceMutation, { data, loading, error }] = useAdd_CustomPlaceMutation({
 *   variables: {
 *      placeData: // value for 'placeData'
 *   },
 * });
 */
export function useAdd_CustomPlaceMutation(baseOptions?: Apollo.MutationHookOptions<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>(Add_CustomPlaceDocument, options);
      }
export type Add_CustomPlaceMutationHookResult = ReturnType<typeof useAdd_CustomPlaceMutation>;
export type Add_CustomPlaceMutationResult = Apollo.MutationResult<Add_CustomPlaceMutation>;
export type Add_CustomPlaceMutationOptions = Apollo.BaseMutationOptions<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>;
export const Create_FuelCardsDocument = gql`
    mutation create_FuelCards($fuelCards: [FuelCardInput!]!) {
  create_FuelCards(fuelCards: $fuelCards)
}
    `;
export type Create_FuelCardsMutationFn = Apollo.MutationFunction<Create_FuelCardsMutation, Create_FuelCardsMutationVariables>;

/**
 * __useCreate_FuelCardsMutation__
 *
 * To run a mutation, you first call `useCreate_FuelCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_FuelCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuelCardsMutation, { data, loading, error }] = useCreate_FuelCardsMutation({
 *   variables: {
 *      fuelCards: // value for 'fuelCards'
 *   },
 * });
 */
export function useCreate_FuelCardsMutation(baseOptions?: Apollo.MutationHookOptions<Create_FuelCardsMutation, Create_FuelCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_FuelCardsMutation, Create_FuelCardsMutationVariables>(Create_FuelCardsDocument, options);
      }
export type Create_FuelCardsMutationHookResult = ReturnType<typeof useCreate_FuelCardsMutation>;
export type Create_FuelCardsMutationResult = Apollo.MutationResult<Create_FuelCardsMutation>;
export type Create_FuelCardsMutationOptions = Apollo.BaseMutationOptions<Create_FuelCardsMutation, Create_FuelCardsMutationVariables>;
export const Create_VehicleProfileDocument = gql`
    mutation create_VehicleProfile($vehicleProfile: VehicleProfileInput!) {
  create_VehicleProfile(vehicleProfile: $vehicleProfile) {
    id
    euroClass
    numberOfAxles
    weight
    name
    pricePerKm
  }
}
    `;
export type Create_VehicleProfileMutationFn = Apollo.MutationFunction<Create_VehicleProfileMutation, Create_VehicleProfileMutationVariables>;

/**
 * __useCreate_VehicleProfileMutation__
 *
 * To run a mutation, you first call `useCreate_VehicleProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_VehicleProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleProfileMutation, { data, loading, error }] = useCreate_VehicleProfileMutation({
 *   variables: {
 *      vehicleProfile: // value for 'vehicleProfile'
 *   },
 * });
 */
export function useCreate_VehicleProfileMutation(baseOptions?: Apollo.MutationHookOptions<Create_VehicleProfileMutation, Create_VehicleProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_VehicleProfileMutation, Create_VehicleProfileMutationVariables>(Create_VehicleProfileDocument, options);
      }
export type Create_VehicleProfileMutationHookResult = ReturnType<typeof useCreate_VehicleProfileMutation>;
export type Create_VehicleProfileMutationResult = Apollo.MutationResult<Create_VehicleProfileMutation>;
export type Create_VehicleProfileMutationOptions = Apollo.BaseMutationOptions<Create_VehicleProfileMutation, Create_VehicleProfileMutationVariables>;
export const UpsertExpenseDocument = gql`
    mutation UpsertExpense($input: ExpenseInput!) {
  upsert_Expense(input: $input) {
    id
  }
}
    `;
export type UpsertExpenseMutationFn = Apollo.MutationFunction<UpsertExpenseMutation, UpsertExpenseMutationVariables>;

/**
 * __useUpsertExpenseMutation__
 *
 * To run a mutation, you first call `useUpsertExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExpenseMutation, { data, loading, error }] = useUpsertExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertExpenseMutation, UpsertExpenseMutationVariables>(UpsertExpenseDocument, options);
      }
export type UpsertExpenseMutationHookResult = ReturnType<typeof useUpsertExpenseMutation>;
export type UpsertExpenseMutationResult = Apollo.MutationResult<UpsertExpenseMutation>;
export type UpsertExpenseMutationOptions = Apollo.BaseMutationOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>;
export const Create_TrailerDocument = gql`
    mutation create_Trailer($licensePlateNumber: String!, $ownName: String!, $manufacturerName: String!, $trailerProfile: InputTrailerProfile) {
  create_Trailer(
    licensePlateNumber: $licensePlateNumber
    ownName: $ownName
    manufacturerName: $manufacturerName
    trailerProfile: $trailerProfile
  )
}
    `;
export type Create_TrailerMutationFn = Apollo.MutationFunction<Create_TrailerMutation, Create_TrailerMutationVariables>;

/**
 * __useCreate_TrailerMutation__
 *
 * To run a mutation, you first call `useCreate_TrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_TrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrailerMutation, { data, loading, error }] = useCreate_TrailerMutation({
 *   variables: {
 *      licensePlateNumber: // value for 'licensePlateNumber'
 *      ownName: // value for 'ownName'
 *      manufacturerName: // value for 'manufacturerName'
 *      trailerProfile: // value for 'trailerProfile'
 *   },
 * });
 */
export function useCreate_TrailerMutation(baseOptions?: Apollo.MutationHookOptions<Create_TrailerMutation, Create_TrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_TrailerMutation, Create_TrailerMutationVariables>(Create_TrailerDocument, options);
      }
export type Create_TrailerMutationHookResult = ReturnType<typeof useCreate_TrailerMutation>;
export type Create_TrailerMutationResult = Apollo.MutationResult<Create_TrailerMutation>;
export type Create_TrailerMutationOptions = Apollo.BaseMutationOptions<Create_TrailerMutation, Create_TrailerMutationVariables>;
export const Get_CustomPlacesDocument = gql`
    query get_CustomPlaces {
  get_CustomPlaces {
    _id
    address
    type
    polygonType
    name
    forbidden
    notes
    countryCode
    center {
      coordinates
    }
    polygon {
      coordinates
    }
  }
}
    `;

/**
 * __useGet_CustomPlacesQuery__
 *
 * To run a query within a React component, call `useGet_CustomPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CustomPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CustomPlacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_CustomPlacesQuery(baseOptions?: Apollo.QueryHookOptions<Get_CustomPlacesQuery, Get_CustomPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_CustomPlacesQuery, Get_CustomPlacesQueryVariables>(Get_CustomPlacesDocument, options);
      }
export function useGet_CustomPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_CustomPlacesQuery, Get_CustomPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_CustomPlacesQuery, Get_CustomPlacesQueryVariables>(Get_CustomPlacesDocument, options);
        }
export type Get_CustomPlacesQueryHookResult = ReturnType<typeof useGet_CustomPlacesQuery>;
export type Get_CustomPlacesLazyQueryHookResult = ReturnType<typeof useGet_CustomPlacesLazyQuery>;
export type Get_CustomPlacesQueryResult = Apollo.QueryResult<Get_CustomPlacesQuery, Get_CustomPlacesQueryVariables>;
export const Delete_CustomPlaceDocument = gql`
    mutation delete_CustomPlace($id: String!) {
  delete_CustomPlace(id: $id)
}
    `;
export type Delete_CustomPlaceMutationFn = Apollo.MutationFunction<Delete_CustomPlaceMutation, Delete_CustomPlaceMutationVariables>;

/**
 * __useDelete_CustomPlaceMutation__
 *
 * To run a mutation, you first call `useDelete_CustomPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_CustomPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomPlaceMutation, { data, loading, error }] = useDelete_CustomPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_CustomPlaceMutation(baseOptions?: Apollo.MutationHookOptions<Delete_CustomPlaceMutation, Delete_CustomPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_CustomPlaceMutation, Delete_CustomPlaceMutationVariables>(Delete_CustomPlaceDocument, options);
      }
export type Delete_CustomPlaceMutationHookResult = ReturnType<typeof useDelete_CustomPlaceMutation>;
export type Delete_CustomPlaceMutationResult = Apollo.MutationResult<Delete_CustomPlaceMutation>;
export type Delete_CustomPlaceMutationOptions = Apollo.BaseMutationOptions<Delete_CustomPlaceMutation, Delete_CustomPlaceMutationVariables>;
export const Delete_ExpenseDocument = gql`
    mutation delete_Expense($id: String!) {
  delete_Expense(id: $id)
}
    `;
export type Delete_ExpenseMutationFn = Apollo.MutationFunction<Delete_ExpenseMutation, Delete_ExpenseMutationVariables>;

/**
 * __useDelete_ExpenseMutation__
 *
 * To run a mutation, you first call `useDelete_ExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_ExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDelete_ExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_ExpenseMutation(baseOptions?: Apollo.MutationHookOptions<Delete_ExpenseMutation, Delete_ExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_ExpenseMutation, Delete_ExpenseMutationVariables>(Delete_ExpenseDocument, options);
      }
export type Delete_ExpenseMutationHookResult = ReturnType<typeof useDelete_ExpenseMutation>;
export type Delete_ExpenseMutationResult = Apollo.MutationResult<Delete_ExpenseMutation>;
export type Delete_ExpenseMutationOptions = Apollo.BaseMutationOptions<Delete_ExpenseMutation, Delete_ExpenseMutationVariables>;
export const DeleteProfileDocument = gql`
    mutation deleteProfile($id: Float!) {
  delete_VehicleProfile(id: $id)
}
    `;
export type DeleteProfileMutationFn = Apollo.MutationFunction<DeleteProfileMutation, DeleteProfileMutationVariables>;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileMutation, { data, loading, error }] = useDeleteProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileMutation, DeleteProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileDocument, options);
      }
export type DeleteProfileMutationHookResult = ReturnType<typeof useDeleteProfileMutation>;
export type DeleteProfileMutationResult = Apollo.MutationResult<DeleteProfileMutation>;
export type DeleteProfileMutationOptions = Apollo.BaseMutationOptions<DeleteProfileMutation, DeleteProfileMutationVariables>;
export const Delete_TrailerDocument = gql`
    mutation delete_Trailer($id: Float!) {
  delete_Trailer(id: $id)
}
    `;
export type Delete_TrailerMutationFn = Apollo.MutationFunction<Delete_TrailerMutation, Delete_TrailerMutationVariables>;

/**
 * __useDelete_TrailerMutation__
 *
 * To run a mutation, you first call `useDelete_TrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_TrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrailerMutation, { data, loading, error }] = useDelete_TrailerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_TrailerMutation(baseOptions?: Apollo.MutationHookOptions<Delete_TrailerMutation, Delete_TrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_TrailerMutation, Delete_TrailerMutationVariables>(Delete_TrailerDocument, options);
      }
export type Delete_TrailerMutationHookResult = ReturnType<typeof useDelete_TrailerMutation>;
export type Delete_TrailerMutationResult = Apollo.MutationResult<Delete_TrailerMutation>;
export type Delete_TrailerMutationOptions = Apollo.BaseMutationOptions<Delete_TrailerMutation, Delete_TrailerMutationVariables>;
export const FuelCompaniesDocument = gql`
    query FuelCompanies {
  get_FuelCompanies {
    id
    name
    label
  }
}
    `;

/**
 * __useFuelCompaniesQuery__
 *
 * To run a query within a React component, call `useFuelCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFuelCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<FuelCompaniesQuery, FuelCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FuelCompaniesQuery, FuelCompaniesQueryVariables>(FuelCompaniesDocument, options);
      }
export function useFuelCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuelCompaniesQuery, FuelCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FuelCompaniesQuery, FuelCompaniesQueryVariables>(FuelCompaniesDocument, options);
        }
export type FuelCompaniesQueryHookResult = ReturnType<typeof useFuelCompaniesQuery>;
export type FuelCompaniesLazyQueryHookResult = ReturnType<typeof useFuelCompaniesLazyQuery>;
export type FuelCompaniesQueryResult = Apollo.QueryResult<FuelCompaniesQuery, FuelCompaniesQueryVariables>;
export const Get_CcRsDocument = gql`
    query get_CCRs {
  get_CCRs {
    id
    card_reader_sn
    state
  }
}
    `;

/**
 * __useGet_CcRsQuery__
 *
 * To run a query within a React component, call `useGet_CcRsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CcRsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CcRsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_CcRsQuery(baseOptions?: Apollo.QueryHookOptions<Get_CcRsQuery, Get_CcRsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_CcRsQuery, Get_CcRsQueryVariables>(Get_CcRsDocument, options);
      }
export function useGet_CcRsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_CcRsQuery, Get_CcRsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_CcRsQuery, Get_CcRsQueryVariables>(Get_CcRsDocument, options);
        }
export type Get_CcRsQueryHookResult = ReturnType<typeof useGet_CcRsQuery>;
export type Get_CcRsLazyQueryHookResult = ReturnType<typeof useGet_CcRsLazyQuery>;
export type Get_CcRsQueryResult = Apollo.QueryResult<Get_CcRsQuery, Get_CcRsQueryVariables>;
export const Get_FuelCardsDocument = gql`
    query get_FuelCards($deleted: Float) {
  get_FuelCards(deleted: $deleted) {
    id
    number
    holder
    fuelCompanyId
    clientId
    expirationDate
    limitAmount
    limitDuration
    monitoredObject {
      id
      monitoringDeviceId
      clientId
      monitoredObjectTypeId
      vehicleProfileId
      registrationNumber
    }
    user {
      id
      name
      surname
    }
  }
}
    `;

/**
 * __useGet_FuelCardsQuery__
 *
 * To run a query within a React component, call `useGet_FuelCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_FuelCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_FuelCardsQuery({
 *   variables: {
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGet_FuelCardsQuery(baseOptions?: Apollo.QueryHookOptions<Get_FuelCardsQuery, Get_FuelCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_FuelCardsQuery, Get_FuelCardsQueryVariables>(Get_FuelCardsDocument, options);
      }
export function useGet_FuelCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_FuelCardsQuery, Get_FuelCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_FuelCardsQuery, Get_FuelCardsQueryVariables>(Get_FuelCardsDocument, options);
        }
export type Get_FuelCardsQueryHookResult = ReturnType<typeof useGet_FuelCardsQuery>;
export type Get_FuelCardsLazyQueryHookResult = ReturnType<typeof useGet_FuelCardsLazyQuery>;
export type Get_FuelCardsQueryResult = Apollo.QueryResult<Get_FuelCardsQuery, Get_FuelCardsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: Float) {
  get_User(id: $id) {
    id
    sso_id
    name
    surname
    note
    contact {
      emails
      phone_numbers
    }
    monitored_object_group_id
    client_id
    deleted
    active
    Groups {
      id
      name
      label
      client_id
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($filter: UserFilter) {
  get_Users(filter: $filter) {
    id
    sso_id
    name
    surname
    userName
    note
    contact {
      emails
      phone_numbers
    }
    monitored_object_group_id
    client_id
    loginCredentials
    deleted
    active
    Tokens {
      id
      token
      userId
      token_type
      startTime
      endTime
      deleted
    }
    Groups {
      id
      name
      label
      client_id
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const Get_GroupedExpensesDocument = gql`
    query get_GroupedExpenses($timezoneOffset: Float!, $aggregator: ExpenseAggregator, $filter: ExpenseFilter!) {
  get_GroupedExpenses(
    timezoneOffset: $timezoneOffset
    aggregator: $aggregator
    filter: $filter
  ) {
    data {
      exchangeRateErr
      key
      main {
        id
        unitPriceInclVatAverage
        unitPriceWithoutVatAverage
        totalPriceWithoutVat
        unitPriceWithoutVat
        monitoredObject {
          id
          clientId
          disabledAt
          monitoringDeviceId
          monitoredObjectTypeId
          vehicleProfileId
          externalId
          registrationNumber
          description
          systemDescription
          manufacturerName
          configuration
          metadata
          deleted
          vehicleProfile {
            id
          }
          fuelCards {
            id
          }
        }
        odometer
        clientId
        driverName
        type
        expenseType
        timestamp
        originalUnitPriceInclVat
        originalTotalPriceInclVat
        unitPriceInclVat
        unitPriceVat
        totalPriceInclVat
        totalPriceVat
        saved
        savedCurrency
        units
        currency
        country
        vatRate
        state
        fuelioTotemPrice
        fuelioTotemPriceCurrency
        fuelStation {
          id
          name
          center {
            type
            coordinates
            bbox
          }
          polygon {
            type
            coordinates
            bbox
          }
          lat
          lon
          services {
            code
            name
          }
          fuelTypes {
            code
            name
            price {
              price
              source
              currency
              productId
              type
              sumExtraFees
            }
          }
        }
        expenseMetadata {
          externalTransactionId
          merchantId
          terminalId
          authCode
          fuelCardCode
          fuelCardIssuer
          fuelCardType
          merchantCountry
        }
        fraudDetectionResult {
          state
          monitoredObjectPositionData {
            lat
            lon
            time
          }
          expensePositionData {
            lat
            lon
            time
          }
          checked
        }
      }
      details {
        id
        monitoredObject {
          id
          clientId
          disabledAt
          monitoringDeviceId
          monitoredObjectTypeId
          vehicleProfileId
          externalId
          registrationNumber
          description
          systemDescription
          manufacturerName
          configuration
          metadata
          deleted
          vehicleProfile {
            id
          }
          fuelCards {
            id
          }
        }
        odometer
        clientId
        driverName
        type
        expenseType
        timestamp
        originalUnitPriceInclVat
        originalTotalPriceInclVat
        unitPriceInclVat
        unitPriceVat
        totalPriceInclVat
        totalPriceVat
        totalPriceWithoutVat
        unitPriceWithoutVat
        saved
        savedCurrency
        units
        currency
        country
        vatRate
        state
        fuelioTotemPrice
        fuelioTotemPriceCurrency
        fuelStation {
          id
          name
          center {
            type
            coordinates
            bbox
          }
          polygon {
            type
            coordinates
            bbox
          }
          lat
          lon
          services {
            code
            name
          }
          fuelTypes {
            code
            name
            price {
              price
              source
              currency
              productId
              type
              sumExtraFees
            }
          }
        }
        expenseMetadata {
          externalTransactionId
          merchantId
          terminalId
          authCode
          fuelCardCode
          fuelCardIssuer
          fuelCardType
          merchantCountry
        }
        fraudDetectionResult {
          state
          monitoredObjectPositionData {
            lat
            lon
            time
          }
          expensePositionData {
            lat
            lon
            time
          }
          checked
        }
      }
    }
  }
}
    `;

/**
 * __useGet_GroupedExpensesQuery__
 *
 * To run a query within a React component, call `useGet_GroupedExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_GroupedExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_GroupedExpensesQuery({
 *   variables: {
 *      timezoneOffset: // value for 'timezoneOffset'
 *      aggregator: // value for 'aggregator'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGet_GroupedExpensesQuery(baseOptions: Apollo.QueryHookOptions<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>(Get_GroupedExpensesDocument, options);
      }
export function useGet_GroupedExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>(Get_GroupedExpensesDocument, options);
        }
export type Get_GroupedExpensesQueryHookResult = ReturnType<typeof useGet_GroupedExpensesQuery>;
export type Get_GroupedExpensesLazyQueryHookResult = ReturnType<typeof useGet_GroupedExpensesLazyQuery>;
export type Get_GroupedExpensesQueryResult = Apollo.QueryResult<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>;
export const ImpersonateUserDocument = gql`
    query ImpersonateUser($ssoId: String!) {
  impersonate_User(ssoId: $ssoId) {
    access_token
    refresh_token
    expires_in
    refresh_expires_in
    token_type
    user_id
  }
}
    `;

/**
 * __useImpersonateUserQuery__
 *
 * To run a query within a React component, call `useImpersonateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpersonateUserQuery({
 *   variables: {
 *      ssoId: // value for 'ssoId'
 *   },
 * });
 */
export function useImpersonateUserQuery(baseOptions: Apollo.QueryHookOptions<ImpersonateUserQuery, ImpersonateUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImpersonateUserQuery, ImpersonateUserQueryVariables>(ImpersonateUserDocument, options);
      }
export function useImpersonateUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImpersonateUserQuery, ImpersonateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImpersonateUserQuery, ImpersonateUserQueryVariables>(ImpersonateUserDocument, options);
        }
export type ImpersonateUserQueryHookResult = ReturnType<typeof useImpersonateUserQuery>;
export type ImpersonateUserLazyQueryHookResult = ReturnType<typeof useImpersonateUserLazyQuery>;
export type ImpersonateUserQueryResult = Apollo.QueryResult<ImpersonateUserQuery, ImpersonateUserQueryVariables>;
export const Import_ExpenseDocument = gql`
    mutation import_Expense($file: [Float!]!) {
  import_Expense(file: $file) {
    status
    data
  }
}
    `;
export type Import_ExpenseMutationFn = Apollo.MutationFunction<Import_ExpenseMutation, Import_ExpenseMutationVariables>;

/**
 * __useImport_ExpenseMutation__
 *
 * To run a mutation, you first call `useImport_ExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImport_ExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importExpenseMutation, { data, loading, error }] = useImport_ExpenseMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImport_ExpenseMutation(baseOptions?: Apollo.MutationHookOptions<Import_ExpenseMutation, Import_ExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Import_ExpenseMutation, Import_ExpenseMutationVariables>(Import_ExpenseDocument, options);
      }
export type Import_ExpenseMutationHookResult = ReturnType<typeof useImport_ExpenseMutation>;
export type Import_ExpenseMutationResult = Apollo.MutationResult<Import_ExpenseMutation>;
export type Import_ExpenseMutationOptions = Apollo.BaseMutationOptions<Import_ExpenseMutation, Import_ExpenseMutationVariables>;
export const IncreaseMoDataRateDocument = gql`
    query increaseMODataRate($monitoredObjectId: Float!, $interval: Float!, $duration: Float!) {
  event_IncreaseMonitoredObjectDataRate(
    monitoredObjectId: $monitoredObjectId
    interval: $interval
    duration: $duration
  )
}
    `;

/**
 * __useIncreaseMoDataRateQuery__
 *
 * To run a query within a React component, call `useIncreaseMoDataRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncreaseMoDataRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncreaseMoDataRateQuery({
 *   variables: {
 *      monitoredObjectId: // value for 'monitoredObjectId'
 *      interval: // value for 'interval'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useIncreaseMoDataRateQuery(baseOptions: Apollo.QueryHookOptions<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>(IncreaseMoDataRateDocument, options);
      }
export function useIncreaseMoDataRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>(IncreaseMoDataRateDocument, options);
        }
export type IncreaseMoDataRateQueryHookResult = ReturnType<typeof useIncreaseMoDataRateQuery>;
export type IncreaseMoDataRateLazyQueryHookResult = ReturnType<typeof useIncreaseMoDataRateLazyQuery>;
export type IncreaseMoDataRateQueryResult = Apollo.QueryResult<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>;
export const PointSuggestionsDocument = gql`
    query pointSuggestions($searchToken: String!) {
  search {
    pointSuggestions(searchToken: $searchToken) {
      label
      polygon {
        type
        coordinates
        bbox
      }
      position {
        type
        coordinates
        bbox
      }
      source
      id
    }
  }
}
    `;

/**
 * __usePointSuggestionsQuery__
 *
 * To run a query within a React component, call `usePointSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointSuggestionsQuery({
 *   variables: {
 *      searchToken: // value for 'searchToken'
 *   },
 * });
 */
export function usePointSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<PointSuggestionsQuery, PointSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointSuggestionsQuery, PointSuggestionsQueryVariables>(PointSuggestionsDocument, options);
      }
export function usePointSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointSuggestionsQuery, PointSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointSuggestionsQuery, PointSuggestionsQueryVariables>(PointSuggestionsDocument, options);
        }
export type PointSuggestionsQueryHookResult = ReturnType<typeof usePointSuggestionsQuery>;
export type PointSuggestionsLazyQueryHookResult = ReturnType<typeof usePointSuggestionsLazyQuery>;
export type PointSuggestionsQueryResult = Apollo.QueryResult<PointSuggestionsQuery, PointSuggestionsQueryVariables>;
export const Subscribe_CcrDocument = gql`
    query subscribe_CCR($serialNumbers: [String!]!, $device: String!) {
  subscribe_CCR(serialNumbers: $serialNumbers, device: $device) {
    card_reader_sn
    state
  }
}
    `;

/**
 * __useSubscribe_CcrQuery__
 *
 * To run a query within a React component, call `useSubscribe_CcrQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribe_CcrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribe_CcrQuery({
 *   variables: {
 *      serialNumbers: // value for 'serialNumbers'
 *      device: // value for 'device'
 *   },
 * });
 */
export function useSubscribe_CcrQuery(baseOptions: Apollo.QueryHookOptions<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>(Subscribe_CcrDocument, options);
      }
export function useSubscribe_CcrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>(Subscribe_CcrDocument, options);
        }
export type Subscribe_CcrQueryHookResult = ReturnType<typeof useSubscribe_CcrQuery>;
export type Subscribe_CcrLazyQueryHookResult = ReturnType<typeof useSubscribe_CcrLazyQuery>;
export type Subscribe_CcrQueryResult = Apollo.QueryResult<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>;
export const Unassign_CcrDocument = gql`
    mutation unassign_CCR($ccrUpdate: CCRUpdate!) {
  unassign_CCR(ccrUpdate: $ccrUpdate) {
    id
  }
}
    `;
export type Unassign_CcrMutationFn = Apollo.MutationFunction<Unassign_CcrMutation, Unassign_CcrMutationVariables>;

/**
 * __useUnassign_CcrMutation__
 *
 * To run a mutation, you first call `useUnassign_CcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassign_CcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCcrMutation, { data, loading, error }] = useUnassign_CcrMutation({
 *   variables: {
 *      ccrUpdate: // value for 'ccrUpdate'
 *   },
 * });
 */
export function useUnassign_CcrMutation(baseOptions?: Apollo.MutationHookOptions<Unassign_CcrMutation, Unassign_CcrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Unassign_CcrMutation, Unassign_CcrMutationVariables>(Unassign_CcrDocument, options);
      }
export type Unassign_CcrMutationHookResult = ReturnType<typeof useUnassign_CcrMutation>;
export type Unassign_CcrMutationResult = Apollo.MutationResult<Unassign_CcrMutation>;
export type Unassign_CcrMutationOptions = Apollo.BaseMutationOptions<Unassign_CcrMutation, Unassign_CcrMutationVariables>;
export const Update_CustomPlaceDocument = gql`
    mutation update_CustomPlace($placeData: CustomPlaceUpdateInput!) {
  update_CustomPlace(placeData: $placeData)
}
    `;
export type Update_CustomPlaceMutationFn = Apollo.MutationFunction<Update_CustomPlaceMutation, Update_CustomPlaceMutationVariables>;

/**
 * __useUpdate_CustomPlaceMutation__
 *
 * To run a mutation, you first call `useUpdate_CustomPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_CustomPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomPlaceMutation, { data, loading, error }] = useUpdate_CustomPlaceMutation({
 *   variables: {
 *      placeData: // value for 'placeData'
 *   },
 * });
 */
export function useUpdate_CustomPlaceMutation(baseOptions?: Apollo.MutationHookOptions<Update_CustomPlaceMutation, Update_CustomPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_CustomPlaceMutation, Update_CustomPlaceMutationVariables>(Update_CustomPlaceDocument, options);
      }
export type Update_CustomPlaceMutationHookResult = ReturnType<typeof useUpdate_CustomPlaceMutation>;
export type Update_CustomPlaceMutationResult = Apollo.MutationResult<Update_CustomPlaceMutation>;
export type Update_CustomPlaceMutationOptions = Apollo.BaseMutationOptions<Update_CustomPlaceMutation, Update_CustomPlaceMutationVariables>;
export const Update_FuelCardsDocument = gql`
    mutation update_FuelCards($fuelCards: [FuelCardInput!]!) {
  update_FuelCards(fuelCards: $fuelCards)
}
    `;
export type Update_FuelCardsMutationFn = Apollo.MutationFunction<Update_FuelCardsMutation, Update_FuelCardsMutationVariables>;

/**
 * __useUpdate_FuelCardsMutation__
 *
 * To run a mutation, you first call `useUpdate_FuelCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_FuelCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelCardsMutation, { data, loading, error }] = useUpdate_FuelCardsMutation({
 *   variables: {
 *      fuelCards: // value for 'fuelCards'
 *   },
 * });
 */
export function useUpdate_FuelCardsMutation(baseOptions?: Apollo.MutationHookOptions<Update_FuelCardsMutation, Update_FuelCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_FuelCardsMutation, Update_FuelCardsMutationVariables>(Update_FuelCardsDocument, options);
      }
export type Update_FuelCardsMutationHookResult = ReturnType<typeof useUpdate_FuelCardsMutation>;
export type Update_FuelCardsMutationResult = Apollo.MutationResult<Update_FuelCardsMutation>;
export type Update_FuelCardsMutationOptions = Apollo.BaseMutationOptions<Update_FuelCardsMutation, Update_FuelCardsMutationVariables>;
export const Get_VehicleProfilesDocument = gql`
    query get_VehicleProfiles {
  get_VehicleProfiles {
    id
    height
    length
    numberOfAxles
    trailersCount
    tunnel
    weight
    width
    euroClass
    name
    pricePerKm
  }
}
    `;

/**
 * __useGet_VehicleProfilesQuery__
 *
 * To run a query within a React component, call `useGet_VehicleProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_VehicleProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_VehicleProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_VehicleProfilesQuery(baseOptions?: Apollo.QueryHookOptions<Get_VehicleProfilesQuery, Get_VehicleProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_VehicleProfilesQuery, Get_VehicleProfilesQueryVariables>(Get_VehicleProfilesDocument, options);
      }
export function useGet_VehicleProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_VehicleProfilesQuery, Get_VehicleProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_VehicleProfilesQuery, Get_VehicleProfilesQueryVariables>(Get_VehicleProfilesDocument, options);
        }
export type Get_VehicleProfilesQueryHookResult = ReturnType<typeof useGet_VehicleProfilesQuery>;
export type Get_VehicleProfilesLazyQueryHookResult = ReturnType<typeof useGet_VehicleProfilesLazyQuery>;
export type Get_VehicleProfilesQueryResult = Apollo.QueryResult<Get_VehicleProfilesQuery, Get_VehicleProfilesQueryVariables>;
export const VehiclesSubscribeDocument = gql`
    query vehiclesSubscribe($device: String!) {
  vehiclesSubscribe(device: $device) {
    monitoredObjectType {
      id
      name
      label
    }
    rn
    disabledAt
    monitoredObjectFuelType
    monitoredObjectGroups {
      id
      name
      clientId
      color
      monitoredObjectGroupTypeId
    }
    customLabel
    monitoredObjectId
    address
    odometer
    trailers {
      id
      registrationNumber
      customLabel
    }
    gpsData {
      time
      lat
      lon
      alt
      speed
      angle
    }
    stateData {
      time
      ignition
    }
    driver {
      name
      id
      driver_token
      source
      time
      state {
        activityStartTime
        drivingOrWorkingStartTime
        activity
        nextBreakStartTime
        drivingDuration
        restingDuration
        workingDuration
        biweeklyDrivingDuration
        choppedDrivingTimeCapacity
        nextBreakStartTime2
        weeklyDrivingDuration
      }
      state_new {
        activityStartTime
        activity
        utilizationStart
        nextBreakStartTime
        drivingDuration
        workingDuration
        restingDuration
        dailyUtilizationEnd
        dailyUtilization
        dailyUtilizationMaxHours
        timestamp
        dailyDriveMaxHours
        weeklyExtendedShifts
        drivingDuration14days
      }
    }
    drivers {
      name
      id
      driver_token
      source
      time
      state {
        activityStartTime
        drivingOrWorkingStartTime
        activity
        nextBreakStartTime
        drivingDuration
        restingDuration
        workingDuration
        biweeklyDrivingDuration
        choppedDrivingTimeCapacity
        nextBreakStartTime2
        weeklyDrivingDuration
      }
      state_new {
        activityStartTime
        activity
        utilizationStart
        nextBreakStartTime
        drivingDuration
        workingDuration
        restingDuration
        dailyUtilizationEnd
        dailyUtilization
        dailyUtilizationMaxHours
        timestamp
        dailyDriveMaxHours
        weeklyExtendedShifts
        drivingDuration14days
      }
    }
    tankSize
    fuelTanks {
      time
      raw_value
      level
      liters
      id
    }
    address_structured {
      lang
      address
      countryCode
      country
      town
      route
      street_address
      postalCode
    }
    activeTransports {
      id
      name
      firstRta
      nextWaypoint {
        lat
        lon
        name
        rta
        eta
        estimatedDistance
        remainingDistancePercentage
        addressStructured {
          lang
          address
          countryCode
          country
          town
          route
          street_address
          postalCode
        }
      }
      clientId
      activeEventRules {
        name
        config {
          name
          value
        }
      }
    }
    last_change_state
    charging {
      time
      on
    }
    weight {
      time
      weight
    }
    adblue_level {
      time
      level
    }
    car_battery {
      timestamp
      volts
    }
    external_devices {
      serial_number
      device_id
      device_type
      tfrom
      metadata {
        registration_number
        sensor_zone
        sensor_name
        sensor_type
      }
      data {
        temperature
        error
      }
    }
  }
}
    `;

/**
 * __useVehiclesSubscribeQuery__
 *
 * To run a query within a React component, call `useVehiclesSubscribeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesSubscribeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesSubscribeQuery({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useVehiclesSubscribeQuery(baseOptions: Apollo.QueryHookOptions<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>(VehiclesSubscribeDocument, options);
      }
export function useVehiclesSubscribeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>(VehiclesSubscribeDocument, options);
        }
export type VehiclesSubscribeQueryHookResult = ReturnType<typeof useVehiclesSubscribeQuery>;
export type VehiclesSubscribeLazyQueryHookResult = ReturnType<typeof useVehiclesSubscribeLazyQuery>;
export type VehiclesSubscribeQueryResult = Apollo.QueryResult<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>;