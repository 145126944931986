import { Subject } from 'rxjs';
import { MaintenanceNotificationInDb, MaintenanceNotificationInDbFromJSON } from 'generated/backend-api';
import { Logic } from './logic';

export class MaintenanceNotificationsLogic {
    readonly maintenanceNotificationsUpdates: Subject<MaintenanceNotificationInDb[]>;

    private _logic: Logic;
    maintenanceNotifications: MaintenanceNotificationInDb[];

    constructor(logic: Logic) {
        this._logic = logic;
        this.maintenanceNotifications = [];
        this.maintenanceNotificationsUpdates = new Subject<MaintenanceNotificationInDb[]>();
    }

    init() {
        if (this._logic.demo().isActive) {
            this._getAndSubscribeToMaintenanceNotifications();
        } else {
            this._getAndSubscribeToMaintenanceNotifications();

            this._logic
                .notification()
                .onConnect()
                .subscribe(() => {
                    this._getAndSubscribeToMaintenanceNotifications();
                });

            this._watchNotificationsUpdates();
        }
    }

    private _watchNotificationsUpdates() {
        this._logic.notification().on('maintenance', n => {
            if (n.data?.notifications && Array.isArray(n.data.notifications)) {
                const notificationUpdates = (n.data.notifications as any[]).map(n => {
                    return MaintenanceNotificationInDbFromJSON(n);
                });

                if (notificationUpdates.length === 0) {
                    return;
                }

                let newNotifications: MaintenanceNotificationInDb[] = this.maintenanceNotifications;
                notificationUpdates.forEach(update => {
                    const index = newNotifications.findIndex(a => a.taskId === update.taskId);

                    if (index !== -1) {
                        newNotifications[index] = update;
                    } else {
                        newNotifications = [update, ...newNotifications];
                    }
                });
                this.maintenanceNotifications = newNotifications;
                this.maintenanceNotificationsUpdates.next(this.maintenanceNotifications);
            }
        });
    }

    getAllMaintenanceNotifications(): MaintenanceNotificationInDb[] {
        return this.maintenanceNotifications.filter(notification => !notification.acknowledged) ?? [];
    }

    markNotificationAsSeen(ids: string[]) {
        Promise.all(
            ids.map(notificationId =>
                this._logic.api().maintenanceApi.acknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePost({
                    notificationId
                })
            )
        ).then(async () => {
            this._getAndSubscribeToMaintenanceNotifications();
        });
    }

    private async _getAndSubscribeToMaintenanceNotifications() {
        if (this._logic.demo().isActive) {
            this.maintenanceNotifications = this._logic.demo().data.maintenanceNotifications;
        } else {
            try {
                const params = {
                    subscribeDevice: this._logic.notification().device,
                    subscribeUser: this._logic.auth().keycloak.tokenParsed?.['sub']
                };
                const notifications: MaintenanceNotificationInDb[] = await this._logic
                    .api()
                    .maintenanceApi.getMaintenanceNotificationV1MaintenanceNotificationMyGet(params);

                this.maintenanceNotifications = notifications;
            } catch (e) {
                this.maintenanceNotifications = [];
            }
        }

        this.maintenanceNotificationsUpdates.next(this.maintenanceNotifications);
    }
}
