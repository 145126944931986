import moment from 'moment';
import { Logic } from './logic';
import { VehicleModelMap } from './map/logic/vehicles';
import { Alarm } from 'common/model/alarm';
import { geoAlarmTypes } from 'logic/map/logic/routing';
import { Role } from './auth';
import { monitoredObject } from 'common/demo/vehicles';
import { TripInDatabase, Transport, AlarmType, MonitoredObjectFleetType } from 'generated/backend-api';
import { ReadOnlyContactList } from 'generated/new-main/models';
import { latLngFromGeoJsonPointType, latLngFromGeoJsonPolygonType } from 'common/utils/geo-utils';

export class DispatcherBoardDetailLogic {
    data?: Transport;
    private _trips: TripInDatabase[];
    private _geoAlarms: Alarm[];
    private _clientContactList: ReadOnlyContactList[];

    constructor(private _logic: Logic) {
        this._trips = [];
        this._geoAlarms = [];
        this._clientContactList = [];
    }

    init() {
        this._logic.map().routing().init(this._logic.map().initialPaddingWithLeftComponent);
    }

    async getClientContactList() {
        try {
            this._clientContactList = await this._logic.clientContactLogic().getAllClientContactList();
        } catch (err) {
            console.error('Could not fetch client contact list');
            throw err;
        }
    }

    async transportAndTrips(transportId: string): Promise<Transport> {
        this.destroy();
        if (this._logic.demo().isActive) {
            this.data = await this.transportDetail(transportId);

            const monitoredObject = this.data?.monitoredObjects?.find(
                mo => mo.type !== MonitoredObjectFleetType.Trailer && !mo.endTime
            );
            if (monitoredObject) {
                this._trips = this._logic
                    .demo()
                    .data.trips.filter(
                        t =>
                            t.monitoredObjectId === monitoredObject.monitoredObjectId &&
                            t.drivers &&
                            t.drivers?.length > 0
                    );
            }
            return this.data!;
        }
        await this.getClientContactList();
        this.data = await this.transportDetail(transportId);

        const vehiclesActive = this.data?.monitoredObjects?.filter(
            monitoredObject => !monitoredObject.endTime && monitoredObject.type !== MonitoredObjectFleetType.Trailer
        );
        const primaryVehicleId = vehiclesActive?.[vehiclesActive?.length - 1]?.monitoredObjectId;
        this._logic.plannerLogic().init(false, 'preview', undefined, this.data);
        if (primaryVehicleId && this.data?.places?.[0]?.rta && this.data?.places?.[this.data.places.length - 1]?.rta) {
            const start = moment
                .min(
                    ...[this.data?.places?.[0]?.rta, this.data?.places?.[0]?.ata]
                        .filter(e => e && moment(e).isValid())
                        .map(e => moment(e))
                )
                .utc()
                .toISOString();

            const end = moment
                .max(
                    ...[
                        this.data?.places?.[this.data.places.length - 1]?.rta,
                        this.data?.places?.[this.data.places.length - 1]?.ata
                    ]
                        .filter(e => e && moment(e).isValid())
                        .map(e => moment(e))
                )
                .utc()
                .toISOString();

            this._trips = this._logic.auth().roles().includes(Role.JA_R)
                ? await this._fetchTrips(primaryVehicleId, start, end)
                : [];

            this._geoAlarms = this._logic.auth().roles().includes(Role.IA_R)
                ? (
                      await this._logic.alarms().getAlarms({
                          dateFrom: moment.utc(start).toDate(),
                          dateTo: moment.utc(end).toDate(),
                          monitoredObjectId: monitoredObject.id,
                          active: false
                      })
                  ).filter(a =>
                      [
                          AlarmType.CorridorLeave,
                          AlarmType.PoiArrival,
                          AlarmType.PoiClose,
                          AlarmType.PoiDeparture
                      ].includes(a.alarmType)
                  )
                : [];
        }
        return this.data!;
    }

    async transportDetail(transportId: string) {
        if (this._logic.demo().isActive) {
            const transports = this._logic.demo().data.transports;
            return transports.find(t => t.id === transportId);
        } else {
            this.data = await this._logic.transportLogic().fetchTransport(transportId);
            this._logic.plannerLogic().transformTransport(this.data);
            return {
                ...this.data,
                places: this.data?.places?.map(place => ({
                    ...place,
                    center: latLngFromGeoJsonPointType(place.center ?? {}),
                    polygon: place.polygon ? latLngFromGeoJsonPolygonType(place.polygon) : undefined
                }))
            };
        }
    }

    drawRouteOnMap(vehicle?: VehicleModelMap) {
        const places =
            this.data?.places?.map(place => {
                return {
                    id: place.id ?? '',
                    lat: (place.center as any)?.lat as number,
                    lng: (place.center as any)?.lng as number,
                    disabledDragging: true,
                    crossingPoints: place.crossingPoints
                };
            }) ?? [];

        const routes =
            this.data?.places
                ?.filter(p => p.route)
                .map(p => ({
                    route: p.route!,
                    disabledDragging: true
                })) ?? [];

        const trips = this._trips.reduce<string[]>((a, c) => {
            if (c.metadata?.polyline) {
                a.push(c.metadata.polyline);
            }
            return a;
        }, []);
        this._logic.map().routing().renderRoute(places, routes, trips, true, true, vehicle);
        if (this._geoAlarms.length > 0) {
            this._logic.map().routing().renderAlarms(this._geoAlarms, geoAlarmTypes);
        }
        this._logic.plannerLogic().poisAlongRoute(routes.map(r => r.route));
    }

    destroy() {
        this._logic.apollo().stop();
        this.data = undefined;
        this._trips = [];
        this._geoAlarms = [];
        this._clientContactList = [];
        this._logic.map().sideBarControlsOffResetState();
        this._logic.map().routing().destroy();
        this._logic.map().vehicles().hide();
        this._logic.map().polygons().destroy();
    }

    private async _fetchTrips(
        monitoredObjectId: number,
        startTime: string,
        endTime: string
    ): Promise<TripInDatabase[]> {
        try {
            const res = await this._logic.api().tripApi.getTripsApiV2TripsGet({
                dateFrom: moment(startTime).toDate(),
                dateTo: moment(endTime).toDate(),
                monitoredObjectId: String(monitoredObjectId),
                wrapArray: true,
                sort: 1
            });
            return (res.trips.filter(r => r) || []).filter(
                v => v.monitoredObjectId && v.monitoredObjectId === String(monitoredObjectId)
            );
        } catch (err) {
            console.error('Get trips err', err);
            throw err;
        }
    }

    private _generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}
