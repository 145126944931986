/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleDriverStateNew
 */
export interface VehicleDriverStateNew {
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverStateNew
     */
    activityStartTime: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverStateNew
     */
    activity: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriverStateNew
     */
    timestamp: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriverStateNew
     */
    utilizationStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriverStateNew
     */
    nextBreakStartTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    drivingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    workingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    restingDuration?: number;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriverStateNew
     */
    dailyUtilizationEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    dailyUtilization?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    dailyUtilizationMaxHours?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    dailyDriveMaxHours?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    weeklyExtendedShifts?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverStateNew
     */
    drivingDuration14days?: number;
}

export function VehicleDriverStateNewFromJSON(json: any): VehicleDriverStateNew {
    return VehicleDriverStateNewFromJSONTyped(json, false);
}

export function VehicleDriverStateNewFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDriverStateNew {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityStartTime': json['activityStartTime'],
        'activity': json['activity'],
        'timestamp': (new Date(json['timestamp'])),
        'utilizationStart': !exists(json, 'utilizationStart') ? undefined : (new Date(json['utilizationStart'])),
        'nextBreakStartTime': !exists(json, 'nextBreakStartTime') ? undefined : (new Date(json['nextBreakStartTime'])),
        'drivingDuration': !exists(json, 'drivingDuration') ? undefined : json['drivingDuration'],
        'workingDuration': !exists(json, 'workingDuration') ? undefined : json['workingDuration'],
        'restingDuration': !exists(json, 'restingDuration') ? undefined : json['restingDuration'],
        'dailyUtilizationEnd': !exists(json, 'dailyUtilizationEnd') ? undefined : (new Date(json['dailyUtilizationEnd'])),
        'dailyUtilization': !exists(json, 'dailyUtilization') ? undefined : json['dailyUtilization'],
        'dailyUtilizationMaxHours': !exists(json, 'dailyUtilizationMaxHours') ? undefined : json['dailyUtilizationMaxHours'],
        'dailyDriveMaxHours': !exists(json, 'dailyDriveMaxHours') ? undefined : json['dailyDriveMaxHours'],
        'weeklyExtendedShifts': !exists(json, 'weeklyExtendedShifts') ? undefined : json['weeklyExtendedShifts'],
        'drivingDuration14days': !exists(json, 'drivingDuration14days') ? undefined : json['drivingDuration14days'],
    };
}

export function VehicleDriverStateNewToJSON(value?: VehicleDriverStateNew | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityStartTime': value.activityStartTime,
        'activity': value.activity,
        'timestamp': (value.timestamp.toISOString()),
        'utilizationStart': value.utilizationStart === undefined ? undefined : (value.utilizationStart.toISOString()),
        'nextBreakStartTime': value.nextBreakStartTime === undefined ? undefined : (value.nextBreakStartTime.toISOString()),
        'drivingDuration': value.drivingDuration,
        'workingDuration': value.workingDuration,
        'restingDuration': value.restingDuration,
        'dailyUtilizationEnd': value.dailyUtilizationEnd === undefined ? undefined : (value.dailyUtilizationEnd.toISOString()),
        'dailyUtilization': value.dailyUtilization,
        'dailyUtilizationMaxHours': value.dailyUtilizationMaxHours,
        'dailyDriveMaxHours': value.dailyDriveMaxHours,
        'weeklyExtendedShifts': value.weeklyExtendedShifts,
        'drivingDuration14days': value.drivingDuration14days,
    };
}


