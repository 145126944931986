/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiemsRule2
 */
export interface DiemsRule2 {
    /**
     * 
     * @type {string}
     * @memberof DiemsRule2
     */
    client?: string;
    /**
     * 
     * @type {Date}
     * @memberof DiemsRule2
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DiemsRule2
     */
    deleted?: string;
    /**
     * 
     * @type {Date}
     * @memberof DiemsRule2
     */
    end?: Date;
    /**
     * 
     * @type {string}
     * @memberof DiemsRule2
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof DiemsRule2
     */
    pocketMoney?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiemsRule2
     */
    replacedMeals?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof DiemsRule2
     */
    start?: Date;
    /**
     * 
     * @type {string}
     * @memberof DiemsRule2
     */
    user?: string;
}

export function DiemsRule2FromJSON(json: any): DiemsRule2 {
    return DiemsRule2FromJSONTyped(json, false);
}

export function DiemsRule2FromJSONTyped(json: any, ignoreDiscriminator: boolean): DiemsRule2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': !exists(json, 'client') ? undefined : json['client'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'pocketMoney': !exists(json, 'pocket_money') ? undefined : json['pocket_money'],
        'replacedMeals': !exists(json, 'replaced_meals') ? undefined : json['replaced_meals'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function DiemsRule2ToJSON(value?: DiemsRule2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': value.client,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'deleted': value.deleted,
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'id': value.id,
        'pocket_money': value.pocketMoney,
        'replaced_meals': value.replacedMeals,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'user': value.user,
    };
}


