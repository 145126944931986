/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriveData,
    DriveDataFromJSON,
    DriveDataFromJSONTyped,
    DriveDataToJSON,
    GeneralData,
    GeneralDataFromJSON,
    GeneralDataFromJSONTyped,
    GeneralDataToJSON,
    SavingData,
    SavingDataFromJSON,
    SavingDataFromJSONTyped,
    SavingDataToJSON,
    WearData,
    WearDataFromJSON,
    WearDataFromJSONTyped,
    WearDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverBehaviourScore
 */
export interface DriverBehaviourScore {
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourScore
     */
    overalScore: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourScore
     */
    driveScore: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourScore
     */
    ecoScore: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourScore
     */
    wearTearScore: number;
    /**
     * 
     * @type {string}
     * @memberof DriverBehaviourScore
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourScore
     */
    rank?: number;
    /**
     * 
     * @type {Date}
     * @memberof DriverBehaviourScore
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof DriverBehaviourScore
     */
    periodEnd: Date;
    /**
     * 
     * @type {GeneralData}
     * @memberof DriverBehaviourScore
     */
    generalData: GeneralData;
    /**
     * 
     * @type {DriveData}
     * @memberof DriverBehaviourScore
     */
    driveData: DriveData;
    /**
     * 
     * @type {SavingData}
     * @memberof DriverBehaviourScore
     */
    savingData: SavingData;
    /**
     * 
     * @type {WearData}
     * @memberof DriverBehaviourScore
     */
    wearData: WearData;
}

export function DriverBehaviourScoreFromJSON(json: any): DriverBehaviourScore {
    return DriverBehaviourScoreFromJSONTyped(json, false);
}

export function DriverBehaviourScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overalScore': json['overal_score'],
        'driveScore': json['drive_score'],
        'ecoScore': json['eco_score'],
        'wearTearScore': json['wear_tear_score'],
        'id': json['id'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'periodStart': (new Date(json['period_start'])),
        'periodEnd': (new Date(json['period_end'])),
        'generalData': GeneralDataFromJSON(json['general_data']),
        'driveData': DriveDataFromJSON(json['drive_data']),
        'savingData': SavingDataFromJSON(json['saving_data']),
        'wearData': WearDataFromJSON(json['wear_data']),
    };
}

export function DriverBehaviourScoreToJSON(value?: DriverBehaviourScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overal_score': value.overalScore,
        'drive_score': value.driveScore,
        'eco_score': value.ecoScore,
        'wear_tear_score': value.wearTearScore,
        'id': value.id,
        'rank': value.rank,
        'period_start': (value.periodStart.toISOString()),
        'period_end': (value.periodEnd.toISOString()),
        'general_data': GeneralDataToJSON(value.generalData),
        'drive_data': DriveDataToJSON(value.driveData),
        'saving_data': SavingDataToJSON(value.savingData),
        'wear_data': WearDataToJSON(value.wearData),
    };
}


