import { TripInDatabaseFromJSON } from 'generated/backend-api';
import polyline from '@mapbox/polyline';
import * as turf from '@turf/turf';
import { vehicleStates, chunkPlaces, defaultAddressStructured } from './vehicles-states';
import { transports } from './transports';
import moment from 'moment';
import { EFuelType } from 'generated/graphql';
import { temperatureSensors } from './coldchain';
import { trailers } from './vehicles';
import { users } from './users';

/**
 * Average FC in liter per 1km, ale chunks have different AFC
 */
const defaultCo2OnDiesel = 2640;
const defaultCo2OnPetrol = 2392;
const defaultAFC = 0.2637;
const vehiclesAFC: number[][] = [
    [0.2238, 0.2014, 0.1936, 0.2683, 0.2115, 0.2363, 0.2269],
    [0.3066],
    [0.2616, 0.2865],
    [0.2724, 0.2413],
    [0.339, 0.2953],
    [0.2841, 0.2452],
    [defaultAFC],
    [defaultAFC],
    [defaultAFC],
    [defaultAFC]
];
const vehiclesOdomoter: number[] = vehicleStates.map(v => v.odometer ?? 0);

const drivingDuration = [
    30 * 3600 + 21 * 60,
    2 * 3600 + 1 * 60,
    0 * 3600 + 30 * 60,
    2 * 3600 + 15 * 60,
    5 * 3600 + 3 * 60,
    2 * 3600 + 23 * 60
];

/**
 * chunkPieces - how will trip be diveded into separate chunks,
 * Sum of chunkPieces for each vehicle must be 1.
 */
export const chunkPieces = [
    [0.2305, 0.03, 0.1695, 0.12, 0.14, 0.15, 0.16],
    [1],
    [0.653, 0.347],
    [0.6, 0.4],
    [0.6, 0.4],
    [0.6, 0.4],
    [1],
    [1],
    [1],
    [1]
];

/**
 * Time lenght [seconds] of pause before start of every chunk
 */
export const beforeChunkPause = [
    [0, 14 * 60, 18 * 3600, 15 * 60, 16 * 3600, 15 * 60, 16 * 3600],
    [0],
    [0, 26 * 60],
    [0, 46 * 60],
    [0, 16 * 60],
    [0, 21 * 60],
    [0],
    [0],
    [0],
    [0]
];

export const data = transports
    .filter(t => (t ? t.monitoredObjects && t.monitoredObjects.length > 0 : false))
    .filter(t => t.state !== 'finished')
    .map((t, transportIndex) => {
        const data = polyline.decode(t.places?.filter(p => p.route).reduce((a, c) => a + c.route ?? '', '') ?? '');
        const line = turf.lineString(data);
        const vehiclePosition = turf.point([
            // Only first 6 transports have geocoded places
            transportIndex < 6 ? vehicleStates[transportIndex].gpsData!.lat! : 48.76554,
            transportIndex < 6 ? vehicleStates[transportIndex].gpsData!.lon! : 19.27559
        ]);
        const split = turf.lineSlice(data[0], vehiclePosition, line);
        const splitLine = split.geometry?.coordinates ?? [];
        const pieces = transportIndex < 6 ? chunkPieces[transportIndex].length : 1;
        const chunkDistance = turf.lineDistance(split, { units: 'meters' });
        const vehicleState = vehicleStates.find(
            v => v.monitoredObjectId === String(t.monitoredObjects?.[0]?.monitoredObjectId)
        );

        let chunkSum = 0;
        let currentTripDuration = 0;

        return new Array(pieces)
            .fill(null)
            .map((_, index) => {
                const chunk = Math.round(splitLine.length * chunkPieces[transportIndex][index]);
                const chunkLine = splitLine.slice(chunkSum, Math.min(chunkSum + chunk + 1, splitLine.length));
                chunkSum += chunk;
                return chunkLine;
            })
            .filter(e => e.length > 0)
            .map((tripChunk, index) => {
                const polylineData = polyline.encode(tripChunk as [number, number][]);
                const chunkDuration = drivingDuration[transportIndex] * chunkPieces[transportIndex][index];
                vehiclesOdomoter[transportIndex] += chunkDistance * chunkPieces[transportIndex][index];
                const totalConsumption =
                    ((chunkDistance * chunkPieces[transportIndex][index]) / 1000) *
                        vehiclesAFC[transportIndex][index] ?? defaultAFC;
                const co2Emission = [EFuelType.Diesel, EFuelType.Gasoline].includes(
                    vehicleState?.monitoredObjectFuelType ?? EFuelType.Diesel
                )
                    ? (Math.floor(vehiclesAFC[transportIndex][index] * 1000) / 10) *
                      (EFuelType.Diesel ? defaultCo2OnDiesel : defaultCo2OnPetrol)
                    : 0;

                const trailerIndex = index < 4 ? 0 : index < 7 ? 1 : -1;
                const driver2Index = index < 4 ? 1 : index < 7 ? 0 : -1;
                const temperatureSensorsData = temperatureSensors
                    .filter(
                        sensor =>
                            String(sensor.monitoredObject) === '10' + String(t.monitoredObjects?.[0].monitoredObjectId)
                    )
                    .map(sensor => ({
                        sensor_id: sensor.id,
                        serial_number: sensor.serialNumber,
                        sensor_name: sensor.sensorName,
                        sensor_zone: sensor.sensorZone,
                        sensor_type: sensor.sensorType
                    }));

                const tripLine = {
                    activity_interval: null,
                    monitoredObjectId: String(t.monitoredObjects?.[0].monitoredObjectId),
                    monitoringDeviceId: '',
                    startTime: moment(t.firstRta)
                        .add(currentTripDuration + beforeChunkPause[transportIndex][index], 'second')
                        .toISOString(),
                    endTime: moment(t.firstRta)
                        .add(currentTripDuration + chunkDuration + beforeChunkPause[transportIndex][index], 'second')
                        .toISOString(),
                    placeStart: {
                        name:
                            transportIndex < 6
                                ? chunkPlaces[transportIndex][index][0].address ?? defaultAddressStructured
                                : defaultAddressStructured[0].address,
                        lat: tripChunk[0][0],
                        lon: tripChunk[0][1],
                        addressStructured:
                            transportIndex < 6
                                ? chunkPlaces[transportIndex][index] ?? defaultAddressStructured
                                : defaultAddressStructured
                    },
                    placeEnd: {
                        name:
                            transportIndex < 6
                                ? chunkPlaces[transportIndex][index + 1][0].address
                                : defaultAddressStructured[0].address,
                        lat: tripChunk[tripChunk.length - 1][0],
                        lon: tripChunk[tripChunk.length - 1][1],
                        addressStructured:
                            transportIndex < 6
                                ? chunkPlaces[transportIndex][index + 1] ?? defaultAddressStructured
                                : defaultAddressStructured
                    },
                    distance: chunkDistance * chunkPieces[transportIndex][index],
                    canDistance: 248155,
                    gpsDistance: 244513.19,
                    odometerStart: 198001813,
                    canOdometerStart: 605718600,
                    gpsOdometerStart: 198001813,
                    odometerEnd: vehiclesOdomoter[transportIndex],
                    canOdometerEnd: 605966755,
                    gpsOdometerEnd: 198246326.19,
                    duration: chunkDuration,
                    totalConsumption: totalConsumption,
                    isPrivate: false,
                    drivers: t.users?.[0]
                        ? [
                              {
                                  id: t.users?.[0].id,
                                  name: t.users?.[0].name,
                                  surname: t.users?.[0].surname,
                                  isMain: true
                              }
                          ]
                        : [],
                    passengers: users?.[driver2Index]
                        ? [
                              {
                                  id: users?.[driver2Index].id,
                                  name: users?.[driver2Index].name,
                                  surname: users?.[driver2Index].surname,
                                  isMain: true
                              }
                          ]
                        : [],
                    closed: index === pieces - 1 ? false : true,
                    trailer:
                        trailers.length > 0 && trailers?.[trailerIndex]
                            ? {
                                  monitored_object_id: trailers[trailerIndex].id,
                                  registration_number: trailers[trailerIndex].registrationNumber
                              }
                            : undefined,
                    metadata: {
                        polyline: polylineData
                    },
                    temperatureSensorsInstalled: temperatureSensorsData,
                    temperatureSensors: temperatureSensorsData,
                    id: index.toString() + '__' + t.id,
                    id_new: index.toString() + '__' + t.id,
                    co2_emission: co2Emission,
                    consumption_liters_100km: Math.floor(vehiclesAFC[transportIndex][index] * 1000) / 10,
                    fuel_type: vehicleState?.monitoredObjectFuelType ?? EFuelType.Diesel
                };
                currentTripDuration += chunkDuration + beforeChunkPause[transportIndex][index];
                return tripLine;
            });
    })
    .flat();

export const trips = data.map(e => TripInDatabaseFromJSON(e));
