/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportEventRule,
    TransportEventRuleFromJSON,
    TransportEventRuleFromJSONTyped,
    TransportEventRuleToJSON,
} from './';

/**
 * 
 * @export
 * @interface ColdChainProfileActiveEventRule
 */
export interface ColdChainProfileActiveEventRule {
    /**
     * 
     * @type {Date}
     * @memberof ColdChainProfileActiveEventRule
     */
    checkStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof ColdChainProfileActiveEventRule
     */
    checkEnd: Date;
    /**
     * 
     * @type {TransportEventRule}
     * @memberof ColdChainProfileActiveEventRule
     */
    eventRule: TransportEventRule;
}

export function ColdChainProfileActiveEventRuleFromJSON(json: any): ColdChainProfileActiveEventRule {
    return ColdChainProfileActiveEventRuleFromJSONTyped(json, false);
}

export function ColdChainProfileActiveEventRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColdChainProfileActiveEventRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkStart': (new Date(json['check_start'])),
        'checkEnd': (new Date(json['check_end'])),
        'eventRule': TransportEventRuleFromJSON(json['event_rule']),
    };
}

export function ColdChainProfileActiveEventRuleToJSON(value?: ColdChainProfileActiveEventRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'check_start': (value.checkStart.toISOString()),
        'check_end': (value.checkEnd.toISOString()),
        'event_rule': TransportEventRuleToJSON(value.eventRule),
    };
}


