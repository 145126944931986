import { Line, G2 } from '@ant-design/charts';
import { LineConfig } from '@ant-design/plots';
import { down, up } from 'resources/images/charts';
import numeral from 'numeral';
import { ThemeChartType, Themes, getChartTheme } from '../ChartTimeline';

export interface ChartTrendsModel {
    xIndex: number;
    xVal: string;
    yVal?: number;
    trendDirection?: 'up' | 'down';
}

interface Props {
    data: ChartTrendsModel[];
    color?: string;
    theme?: Themes;
    yAxisStep?: number;
    width?: number;
    height: number;
    lineWidth?: number;
    maxY?: number;
}

export default function ChartTrends({ color, theme, lineWidth = 4, ...props }: Props) {
    const chartTheme = getChartTheme(theme, ThemeChartType.Trend);

    G2.registerShape('point', 'arrow', {
        draw(cfg, container) {
            const current = cfg.data;
            const cx: number = typeof cfg.x === 'number' ? cfg.x : 0;
            const cy: number = typeof cfg.y === 'number' ? cfg.y : 0;
            const yVal = current?.['yVal'];
            const trendVal = current?.['trendVal'];

            if (yVal) {
                const imgArrow: any = container.addShape('image', {
                    attrs: {
                        x: cx - 9,
                        y: cy + 20,
                        width: 18,
                        height: 20,
                        img: trendVal ? (trendVal === 'up' ? up : down) : undefined
                    }
                });
                return imgArrow;
            }
        }
    });

    const max = Math.round(Math.max(...props.data.map(d => d.yVal ?? 0)) * 1.1);
    const min = Math.round(Math.min(...props.data.map(d => d.yVal ?? 100)) * 0.8);

    const config: LineConfig = {
        data: props.data,
        xField: 'xVal',
        yField: 'yVal',
        animation: false,
        style: chartTheme.style,
        width: props.width,
        height: props.height,
        point: {
            shape: 'arrow'
        },
        label: {
            style: chartTheme.label,
            content: (item: any) =>
                item.xIndex + 1 < props.data.length && item.xIndex > 0 ? numeral(item.yVal).format('0,0.0') : ''
        },
        lineStyle: {
            stroke: color ?? '#00E1FF',
            lineWidth: lineWidth
        },
        yAxis: {
            max: props.maxY ?? max,
            min,
            grid: {
                line: {
                    style: {
                        lineWidth: 0
                    }
                }
            },
            line: {
                style: chartTheme.yAxis?.line
            },
            label: {
                style: {
                    fill: chartTheme.yAxis?.label?.fill
                }
            }
        },
        xAxis: {
            line: {
                style: chartTheme.xAxis?.line
            },
            label: {
                style: {
                    fill: chartTheme.xAxis?.label?.fill
                }
            }
        },
        limitInPlot: true,
        smooth: true,
        tooltip: {
            showContent: false,
            showMarkers: false,
            showCrosshairs: false
        }
    };

    return (
        <div className="chart-trends" style={{ width: props.width }}>
            <Line {...config} />
        </div>
    );
}
