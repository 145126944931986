/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20040,
    InlineResponse20040FromJSON,
    InlineResponse20040ToJSON,
    PUESCSentDocumentUpdateState,
    PUESCSentDocumentUpdateStateFromJSON,
    PUESCSentDocumentUpdateStateToJSON,
    ReadOnlyPUESCSentDocumentSerializer,
    ReadOnlyPUESCSentDocumentSerializerFromJSON,
    ReadOnlyPUESCSentDocumentSerializerToJSON,
    WriteOnlyPUESCSentDocumentSerializer,
    WriteOnlyPUESCSentDocumentSerializerFromJSON,
    WriteOnlyPUESCSentDocumentSerializerToJSON,
} from '../models';

export interface PuescSentDocumentCreateRequest {
    data: WriteOnlyPUESCSentDocumentSerializer;
}

export interface PuescSentDocumentListRequest {
    clientId?: string;
    stateIn?: string;
    limit?: number;
    offset?: number;
}

export interface PuescSentDocumentPartialUpdateRequest {
    id: string;
    data: PUESCSentDocumentUpdateState;
}

export interface PuescSentDocumentReadRequest {
    id: string;
}

/**
 * no description
 */
export class PuescSentDocumentApi extends runtime.BaseAPI {

    /**
     */
    async puescSentDocumentCreateRaw(requestParameters: PuescSentDocumentCreateRequest): Promise<runtime.ApiResponse<ReadOnlyPUESCSentDocumentSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling puescSentDocumentCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/puesc-sent-document/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyPUESCSentDocumentSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPUESCSentDocumentSerializerFromJSON(jsonValue));
    }

    /**
     */
    async puescSentDocumentCreate(requestParameters: PuescSentDocumentCreateRequest): Promise<ReadOnlyPUESCSentDocumentSerializer> {
        const response = await this.puescSentDocumentCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async puescSentDocumentListRaw(requestParameters: PuescSentDocumentListRequest): Promise<runtime.ApiResponse<InlineResponse20040>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.stateIn !== undefined) {
            queryParameters['state__in'] = requestParameters.stateIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/puesc-sent-document/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20040FromJSON(jsonValue));
    }

    /**
     */
    async puescSentDocumentList(requestParameters: PuescSentDocumentListRequest): Promise<InlineResponse20040> {
        const response = await this.puescSentDocumentListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async puescSentDocumentPartialUpdateRaw(requestParameters: PuescSentDocumentPartialUpdateRequest): Promise<runtime.ApiResponse<PUESCSentDocumentUpdateState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling puescSentDocumentPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling puescSentDocumentPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/puesc-sent-document/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PUESCSentDocumentUpdateStateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PUESCSentDocumentUpdateStateFromJSON(jsonValue));
    }

    /**
     */
    async puescSentDocumentPartialUpdate(requestParameters: PuescSentDocumentPartialUpdateRequest): Promise<PUESCSentDocumentUpdateState> {
        const response = await this.puescSentDocumentPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async puescSentDocumentReadRaw(requestParameters: PuescSentDocumentReadRequest): Promise<runtime.ApiResponse<ReadOnlyPUESCSentDocumentSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling puescSentDocumentRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/puesc-sent-document/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPUESCSentDocumentSerializerFromJSON(jsonValue));
    }

    /**
     */
    async puescSentDocumentRead(requestParameters: PuescSentDocumentReadRequest): Promise<ReadOnlyPUESCSentDocumentSerializer> {
        const response = await this.puescSentDocumentReadRaw(requestParameters);
        return await response.value();
    }

}
