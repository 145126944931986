import { Row, Col } from 'antd';
import { Button } from 'common/components';
import qa from 'qa-selectors';
import { useTranslation } from 'react-i18next';

interface Props {
    onPair?: () => void;
    onImpersonate?: () => void;
}

function PartnersActions(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="partner-actions">
            <Row gutter={[12, 12]}>
                {props.onImpersonate && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            onClick={props.onImpersonate}
                            icon={<i className="fas fa-fw fa-user" />}
                            qa={qa.partner.partners.detail.btnImpersonate}
                        >
                            {t('Partner.impersonate')}
                        </Button>
                    </Col>
                )}
                {props.onPair && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            onClick={props.onPair}
                            icon={<i className="fas fa-fw fa-link" />}
                            qa={qa.partner.partners.detail.btnPair}
                        >
                            {t('common.pair')}
                        </Button>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default PartnersActions;
