/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobjectGroupsMobjectTypeName,
    MobjectGroupsMobjectTypeNameFromJSON,
    MobjectGroupsMobjectTypeNameFromJSONTyped,
    MobjectGroupsMobjectTypeNameToJSON,
    MonitoringDeviceMine,
    MonitoringDeviceMineFromJSON,
    MonitoringDeviceMineFromJSONTyped,
    MonitoringDeviceMineToJSON,
} from './';

/**
 * 
 * @export
 * @interface MobjectGroupsMobject
 */
export interface MobjectGroupsMobject {
    /**
     * 
     * @type {number}
     * @memberof MobjectGroupsMobject
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof MobjectGroupsMobject
     */
    readonly clientId?: string;
    /**
     * 
     * @type {MobjectGroupsMobjectTypeName}
     * @memberof MobjectGroupsMobject
     */
    monitoredObjectType: MobjectGroupsMobjectTypeName;
    /**
     * 
     * @type {MonitoringDeviceMine}
     * @memberof MobjectGroupsMobject
     */
    monitoringDevice?: MonitoringDeviceMine;
    /**
     * 
     * @type {object}
     * @memberof MobjectGroupsMobject
     */
    costPerKm?: object;
    /**
     * 
     * @type {string}
     * @memberof MobjectGroupsMobject
     */
    readonly fuelType?: string;
}

export function MobjectGroupsMobjectFromJSON(json: any): MobjectGroupsMobject {
    return MobjectGroupsMobjectFromJSONTyped(json, false);
}

export function MobjectGroupsMobjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobjectGroupsMobject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'monitoredObjectType': MobjectGroupsMobjectTypeNameFromJSON(json['monitored_object_type']),
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : MonitoringDeviceMineFromJSON(json['monitoring_device']),
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'fuelType': !exists(json, 'fuel_type') ? undefined : json['fuel_type'],
    };
}

export function MobjectGroupsMobjectToJSON(value?: MobjectGroupsMobject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_type': MobjectGroupsMobjectTypeNameToJSON(value.monitoredObjectType),
        'monitoring_device': MonitoringDeviceMineToJSON(value.monitoringDevice),
        'cost_per_km': value.costPerKm,
    };
}


