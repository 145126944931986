import { Card, Col, Input, Row } from 'antd';
import { Button } from 'common/components';
import { WhitelabelTemplateModel, WhitelabelSettings } from 'logic/partner/logic/partner-whitelabel';
import React, { Component, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import WhitelabelCard from './WhitelabelCard';

interface Props extends WithTranslation {
    model: WhitelabelTemplateModel;
    onSubmit?: (model: WhitelabelTemplateModel) => void;
    onClose?: () => void;
}

export interface lessVariable {
    key: string;
    value: string;
}

interface State {
    content: WhitelabelSettings;
    name: string;
    colorKey?: string;
}

const groups = {
    basic: [
        'tm-primary',
        'tm-primary-contrast',
        'tm-secondary',
        'tm-secondary-contrast',
        'invert',
        'text',
        'text-hightlighted',
        'text-hover',
        'bg',
        'bg-block',
        'border',
        'title',
        'panel-bg',
        'sidebar-img-invert'
    ],
    navbar: [
        'navbar-bg',
        'navbar-submenu-bg',
        'navbar-text',
        'navbar-text-hover',
        'navbar-sub-bg',
        'navbar-clock-text',
        'navbar-distancer'
    ],
    sidebar: ['sidebar-text', 'sidebar-bg', 'sidebar-hover'],
    scroll: ['scroll', 'scroll-thumb', 'scroll-thumb-hover'],
    tag: ['tag-text', 'tag-bg'],
    table: [
        'table-text',
        'table-selected-text',
        'table-bg-even',
        'table-bg-odd',
        'table-hover-bg',
        'table-selected-bg',
        'table-selected-detail-bg',
        'table-border',
        'table-border-horizontal',
        'table-selected-border',
        'table-head-text',
        'table-head-highlight-text',
        'table-head-bg',
        'table-head-border',
        'table-head-sub-bg',
        'table-head-sub-border',
        'table-button-hover-bg',
        'table-tag-text',
        'table-tag-bg'
    ],
    button: ['button-text', 'button-bg', 'button-text-hover', 'button-bg-hover'],
    bar: [
        'bar-input-text',
        'bar-popup-bg',
        'bar-popup-text',
        'bar-popup-border',
        'bar-btn-text',
        'bar-btn-bg',
        'bar-btn-border',
        'bar-btn-hover-bg',
        'bar-btn-hover-border'
    ],
    form: ['form-bg', 'form-block-bg', 'form-input-bg'],
    'statistics-modules': [
        'route-overview-new-text',
        'route-overview-delayed-text',
        'route-overview-active-text',
        'route-overview-completed-text',
        'statistics-costs-label-bg',
        'aetr-highlighted-text',
        'aetr-highlighted-bg-1',
        'aetr-highlighted-bg-2',
        'journeys-input-bg',
        'route-infobox-bg',
        'journeys-selected-text'
    ],
    loader: ['loader-loading-wrap-bg', 'loader-processing-wrap-bg'],
    modules: [
        'filter-text',
        'sidepanel-text',
        'dispatcher-board-text',
        'dispatcher-board-bg',
        'dispatcher-board-container-bg',
        'dispatcher-board-completed-opacity:',
        'dispatcher-board-head-bg',
        'dispatcher-board-head-sub-bg',
        'dispatcher-board-head-active-text',
        'dispatcher-board-transport-active-bg',
        'dispatcher-board-transport-inactive-bg',
        'dispatcher-board-transport-border',
        'dispatcher-board-border',
        'tracking-table-head-bg',
        'planner-profile-values-text',
        'planner-profile-values-bg',
        'poi-card-text',
        'driver-behavior-bg',
        'planner-body-bg',
        'planner-th-heading',
        'planner-bg',
        'planner-group-bg',
        'planner-route-text',
        'planner-route-bg',
        'summary',
        'progress-bg',
        'progress-success',
        'progress-warning',
        'progress-danger'
    ]
};

class WhitelabelTemplate extends Component<Props, State> {
    private _ref = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        this.state = {
            content: props.model.content,
            name: props.model.name
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps: Props) {
        if (prevProps.model.id !== this.props.model.id) {
            this.setState(state => ({
                ...state,
                name: this.props.model.name,
                colorKey: undefined,
                content: this.props.model.content
            }));
            this._ref.current?.scrollIntoView({ block: 'start' });
        }
    }

    render() {
        return (
            <div className="whitelabel-template">
                <div className="whitelabel-template-heading">
                    <Row justify="space-between">
                        <Col>
                            <h2>{this.props.model.name}</h2>
                        </Col>
                        <Col>
                            <Button type="default" onClick={this._onClose}>
                                {this.props.t('common.close')}
                            </Button>
                            <Button type="primary" onClick={this._onSubmit}>
                                {this.props.t('common.save')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="whitelabel-wrapper">
                    <div className="whitelabel-layout">
                        <div className="whitelabel-template-cards" ref={this._ref}>
                            <Card className="whitelabel-card" title={this.props.t('common.label')} bordered={false}>
                                <Row align="middle" gutter={[10, 10]} key="model-name">
                                    <Col span={13}>{this.props.t('common.label')}</Col>
                                    <Col span={8}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this._onNameChange}
                                        />
                                    </Col>
                                </Row>
                            </Card>

                            {Object.keys(groups).map(g => (
                                <WhitelabelCard
                                    colorKey={this.state.colorKey}
                                    key={g}
                                    group={groups[g]}
                                    title={g}
                                    content={this.state.content}
                                    onValueChange={this._onSettingChange}
                                    onColorPickerChange={this._onColorPickerChange}
                                    onColorPickerClick={this._onColorPickerClick}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private _onSubmit = () => {
        const model = {
            id: this.props.model.id,
            client: this.props.model.client,
            name: this.state.name,
            content: this.state.content
        };
        this.props.onSubmit?.(model);
    };

    private _onClose = () => {
        this.props.onClose?.();
    };

    private _onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: e.target.value
        });
    };

    private _onColorPickerClick = (colorKey: string) => {
        this.setState({
            colorKey: this.state.colorKey === colorKey ? undefined : colorKey
        });
    };

    private _onColorPickerChange = (color: string) => {
        if (this.state.colorKey) {
            let content = this.state.content;
            content[this.state.colorKey] = color;

            this.setState({
                content
            });
        }
    };

    private _onSettingChange = (key: string, value: string) => {
        let content = this.state.content;
        content[key] = value;

        this.setState({
            content
        });
    };
}

export default withTranslation()(WhitelabelTemplate);
