import cn from 'classnames';
import { Moment } from 'moment';
import { ChangeEvent, Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TachoCardForm, TachoCardModel } from '../../TachoCardsModule';
import qa from 'qa-selectors';
import { Role } from 'logic/auth';
import TachoCardDetailCard from '../TachoCardsDetailCard';
import FormActions from 'common/components/FormActions';
import { FormValidator, StringValidator } from 'validators';

interface TachoCardsFormValidator {
    expirationDate: string;
    token: string;
}

interface Props extends WithTranslation {
    edit: boolean;
    model: TachoCardModel;
    demoMode?: boolean;
    roles: Role[];
    updateTachoCardLoading: boolean;
    onCheckTokenExist?: (token: string) => Promise<boolean>;
    onFormCancel?: () => void;
    onFormSubmit?: (model: TachoCardModel) => Promise<boolean>;
}

interface State {
    tachoCardForm?: TachoCardForm;
}

const expirationDateValidator = new StringValidator({ required: true });
const tokenValidator = new StringValidator({ required: true, min: 16 });
const formValidator = new FormValidator<TachoCardsFormValidator>().addValidator('token', tokenValidator);
const defaultValidationErrors = {
    expirationDateErr: '',
    tokenErr: ''
};

class TachoCardsDetail extends Component<Props, State> {
    tachoCardModel?: TachoCardModel;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.edit !== this.props.edit) {
            this._onEditTachoCardClick();
        }
    }

    render() {
        const { t, model, demoMode } = this.props;

        return (
            <div className="management-tacho-cards-detail">
                {this.props.edit ? (
                    <form className="tacho-card-form editable" onSubmit={this._onTachoCardFormSubmit}>
                        <div className="t-bar-block">
                            <div className="t-row">
                                <div className="t-half t-padding-small t-bold" title={t('ManagementTachoCards.id')}>
                                    {t('ManagementTachoCards.id')}
                                </div>
                                <div className="t-half">
                                    <input
                                        className={cn('t-input t-padding-small editable', {
                                            error: this.state.tachoCardForm?.tokenErr
                                        })}
                                        onBlur={this._onCheckTokenExist.bind(
                                            undefined,
                                            this.state.tachoCardForm?.token
                                        )}
                                        minLength={16}
                                        maxLength={19}
                                        value={this.state.tachoCardForm?.token}
                                        onChange={this._onTokenChange}
                                        data-qa={qa.tacho.detail.inputToken}
                                    />
                                    {this.state.tachoCardForm?.tokenErr && (
                                        <span className="t-bold t-text-danger">
                                            {['required', 'not_in_range'].includes(this.state.tachoCardForm.tokenErr)
                                                ? t('validator.' + this.state.tachoCardForm?.tokenErr)
                                                : this.state.tachoCardForm.tokenErr}
                                            {this.state.tachoCardForm?.tokenErr === 'not_in_range' &&
                                                ` ${t('validator.min')} 16 ${t('validator.characters_more_as_4')}`}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-padding-small t-bold" title={t('common.name')}>
                                    {t('common.name')}
                                </div>
                                <div className="t-half" data-qa={qa.tacho.detail.fieldName}>
                                    {this.props.model?.name}
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-padding-small t-bold" title={t('common.surname')}>
                                    {t('common.surname')}
                                </div>
                                <div className="t-half" data-qa={qa.tacho.detail.fieldSurname}>
                                    {this.props.model?.surname}
                                </div>
                            </div>
                        </div>
                        <FormActions
                            cancelQa={qa.tacho.detail.btnFormCancel}
                            submitQa={qa.tacho.detail.btnFormSubmit}
                            submitLoading={this.props.updateTachoCardLoading}
                            submitDisabled={demoMode}
                            onCancelClick={this._onFormCancel}
                        />
                    </form>
                ) : (
                    <TachoCardDetailCard data={model} />
                )}
            </div>
        );
    }

    private _onTachoCardFormSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        if (this.state.tachoCardForm) {
            const validationRes = formValidator.validate({
                expirationDate: this.state.tachoCardForm.expirationDate,
                token: this.state.tachoCardForm.token
            });
            this._onCheckTokenExist(this.state.tachoCardForm.token).then(async tokenCheck => {
                if (validationRes.valid && !tokenCheck) {
                    try {
                        await this.props.onFormSubmit?.(this.tachoCardModel!);
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    this.setState(state => ({
                        tachoCardForm: {
                            ...state.tachoCardForm!,
                            tokenErr: tokenCheck
                                ? this.props.t('ManagementTachoCards.tokenUsedErr')
                                : validationRes.err?.token ?? '',
                            expirationDateErr: validationRes.err?.expirationDate ?? ''
                        }
                    }));
                }
            });
        }
    };

    private _onFormCancel = (): void => {
        this.props.onFormCancel?.();
    };

    private _onEditTachoCardClick = (): void => {
        if (this.props.model) {
            this.tachoCardModel = { ...this.props.model };
            this.setState({
                tachoCardForm: {
                    ...defaultValidationErrors,
                    ...this.props.model
                }
            });
        }
    };

    private _onTokenChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = tokenValidator.validate(value);
        if (!validateRes.err) {
            this.tachoCardModel!.token = validateRes.obj as string;
        }
        this.setState(state => ({
            tachoCardForm: {
                ...state.tachoCardForm!,
                token: value,
                tokenErr: validateRes.err as string
            }
        }));
    };

    private _onExpirationDateChange = (expirationDate: Moment) => {
        const value = expirationDate.toISOString();
        const validateRes = expirationDateValidator.validate(value);
        if (!validateRes.err) {
            this.tachoCardModel!.expirationDate = validateRes.obj as string;
        }
        this.setState(state => ({
            tachoCardForm: {
                ...state.tachoCardForm!,
                expirationDate: value,
                expirationDateErr: validateRes.err as string
            }
        }));
    };

    private _onCheckTokenExist = async (token?: string): Promise<boolean> => {
        if (token && token !== this.props.model.token) {
            const tokenCheck = (await this.props.onCheckTokenExist?.(token)) ?? false;
            this.setState(state => ({
                tachoCardForm: {
                    ...state.tachoCardForm!,
                    tokenErr: tokenCheck
                        ? this.props.t('ManagementTachoCards.tokenUsedErr')
                        : state.tachoCardForm?.tokenErr ?? ''
                }
            }));
            return tokenCheck;
        } else {
            return false;
        }
    };
}

export default withTranslation()(TachoCardsDetail);
