import { google } from 'google-maps';
import { LatLng } from 'common/model/geo';

type Center = {
    lat: number;
    lng: number;
};

export class PolygonMapController {
    private _google: google;
    private _map?: google.maps.Map;
    private _polygons: google.maps.Polygon[];

    constructor(google: google, map?: google.maps.Map) {
        this._map = map;
        this._google = google;
        this._polygons = [];
    }

    destroy(): void {
        this._polygons.forEach(p => {
            p.setMap(null);
            this._polygons = [];
        });
    }

    getCenter(): Center {
        const bounds = new this._google.maps.LatLngBounds();
        this._polygons.forEach(polygon => {
            polygon.getPath().forEach(path => bounds.extend(path));
        });
        return {
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng()
        };
    }
    pointInPolygons(latLng: LatLng): boolean {
        const googleLatLng = new this._google.maps.LatLng(latLng.lat, latLng.lng);
        return this._polygons.some(p => this._google.maps.geometry.poly.containsLocation(googleLatLng, p));
    }
    getPolygon(key: string): google.maps.Polygon | undefined {
        return this._polygons.find(p => p.get('id') === key) as google.maps.Polygon | undefined;
    }

    removePolygon(key: string): void {
        const polygon = this._polygons.find(p => p.get('id') === key);
        if (polygon) {
            this._map && polygon.setMap(null);
            this._polygons = this._polygons.filter(polygon => polygon.get('id') !== key);
        }
    }

    renderPolygons(): void {
        this._polygons.forEach(polygon => {
            this._map && polygon.setMap(this._map);
        });

        this._google.maps.event.addListener(this._map!, 'zoom_changed', () => {
            if (this._map && (this._map.getZoom() ?? 0) >= 10) {
                this._polygons.forEach(polygon => polygon.setVisible(true));
            } else {
                this._polygons.forEach(polygon => polygon.setVisible(false));
            }
        });
    }

    addPolygon(coordinates: LatLng[], key: string): void {
        if (this.getPolygon(key) !== undefined) {
            return;
        }

        const createPath = ({ lat, lng }: LatLng) => {
            return new this._google.maps.LatLng(lat, lng);
        };
        const polygon = new this._google.maps.Polygon({
            paths: coordinates.map(createPath),
            strokeColor: '#07ADFA',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: '#07ADFA',
            fillOpacity: 0.2,
            zIndex: -100,
            editable: false // MFR cant edit Planner polygons
        });
        polygon.set('id', key);
        this._polygons.push(polygon);
    }

    // mock(): void {
    //     // michalovce
    //     const p1: [number, number][] = [
    //         [48.74362529228349, 21.88716888427734],
    //         [48.73207771189604, 21.917552947998047],
    //         [48.743851688911946, 21.946735382080078],
    //         [48.76716507955512, 21.931285858154297],
    //         [48.77078560631493, 21.89472198486328],
    //         [48.74362529228349, 21.88716888427734]
    //     ];
    //     // kosice
    //     const p2: [number, number][] = [
    //         [48.75030356411015, 21.231765747070312],
    //         [48.71452483966837, 21.197433471679688],
    //         [48.68506755483561, 21.246185302734375],
    //         [48.6911870246961, 21.279830932617188],
    //         [48.7396631861975, 21.30146026611328],
    //         [48.75030356411015, 21.231765747070312]
    //     ];

    //     this.addPolygon(p1, 'p1');
    //     this.addPolygon(p2, 'p2');
    //     this.renderPolygons();
    // }
}
