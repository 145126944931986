import { AddressStructured } from 'generated/graphql';
import { AddressStructured as AddressStructuredLocal } from 'common/model/address';
import { AddressIdentification } from 'common/components/Settings';
import { EWClient } from '../../generated/main-data-api';

export const toAddress = (
    userLang: string,
    client: EWClient,
    addressStructured: AddressStructured[] | null | undefined,
    setting: AddressIdentification,
    defaultAddress: string | null | undefined
): string => {
    const customName = addressStructured?.find(a => a.lang === 'custom');
    if (customName) {
        return customName.address ?? '';
    }
    const address = selectAddressStructuredByLanguage(addressStructured, userLang, client);
    const defaultAddressHelper = defaultAddress ?? '';

    if (address?.countryCode) {
        const streetAddress =
            // FIXME: backend api types are generated camelCase automatically, we need to unify this
            address?.street_address || (address as any)?.streetAddress
                ? ', ' + (address?.street_address || (address as any)?.streetAddress)
                : '';
        switch (setting) {
            case AddressIdentification.Address:
                return `${address?.countryCode}${address?.town ? ', ' + address.town : ''}${
                    streetAddress.toLowerCase().includes('unnamed road') ? '' : streetAddress
                }`;
            case AddressIdentification.Code:
                return `${address?.countryCode} ${address?.postalCode}`;
            case AddressIdentification.CodeAndAddress:
                return address?.address ?? defaultAddressHelper;
            default:
                return address?.address ?? defaultAddressHelper;
        }
    } else {
        return defaultAddressHelper;
    }
};

export function selectAddressStructuredByLanguage<T extends AddressStructured | AddressStructuredLocal>(
    addressStructured: T[] | null | undefined,
    userLang: string,
    client?: EWClient
) {
    let address: T | undefined;
    const userAddress = addressStructured?.find(a => a.lang === userLang);
    const clientAddress =
        addressStructured?.find(a => a.lang === client?.language) ||
        addressStructured?.find(a => client?.geocodingLanguages?.includes(a.lang as any)) ||
        addressStructured?.find(a => a.lang === 'en');

    if (userAddress) {
        address = userAddress;
    } else if (clientAddress) {
        address = clientAddress;
    } else if (addressStructured?.length && addressStructured?.length > 0) {
        address = addressStructured[0];
    }

    return address;
}

export function toAddressString(
    userLang: string,
    client: EWClient,
    addressStructured: AddressStructured[] | null | undefined,
    setting: AddressIdentification,
    defaultAddress: string | null | undefined
) {
    if (!client) {
        console.error('can not get client from logic, render default address name');
        return defaultAddress;
    }
    return toAddress(userLang, client, addressStructured, setting, defaultAddress);
}
