import { Component } from 'react';
import i18n from 'i18next';
import { Logic } from 'logic/logic';
import cn from 'classnames';
import { debounce } from 'debounce';
import { exponea } from 'logic/exponea';
import { LatLng } from 'common/model/geo';
import { message, Row } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReadOnlyUser } from 'generated/new-main';
import { Role } from 'logic/auth';
import { PlaceOfWorkUser } from 'common/model/place-of-work-user';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { RouteComponentProps, withRouter } from 'react-router';
import { RouteNames } from 'App';
import qs from 'qs';
import { LayoutContent } from 'common/components/Layout/Content';
import TableBar from 'common/components/TableBar';
import { Link } from 'react-router-dom';
import qa from 'qa-selectors';
import { Button, Confirm, HelperModal, Modal } from 'common/components';
import PoiTable from './components/PoiTable';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { pois } from 'resources/images/sidebar';
import Collapse from 'common/components/Collapse';
import PoiDetailCard from './components/PoiDetailCard';
import PoiActions from './components/PoiActions';
import { MessageType } from 'common/components/Confirm';
import { observer } from 'mobx-react';
import CustomPlacesForm, { PolygonExternalValues } from 'common/forms/CustomPlacesForm/CustomPlacesForm';
import PlaceOfWorkForm, { PlaceOfWorkFormModel } from 'common/forms/PlaceOfWorkForm/PlaceOfWorkForm';
import { PoiModel } from 'common/model/poi';
import { Subscription } from 'rxjs';

export interface Params {
    'allowances-driverId'?: string;
    'allowances-start'?: string;
    'allowances-end'?: string;
}

interface Props extends WithTranslation, RouteComponentProps<Params> {
    logic: Logic;
}

interface State {
    formOpen: boolean;
    bar: {
        add: boolean;
        backButtonUrl?: string;
    };
    placeOfWorkUserDelete?: {
        placeOfWork?: PlaceOfWorkUser;
        loading?: boolean;
    };
    placeOfWorkUsersAdd?: {
        visible?: boolean;
        users?: ReadOnlyUser[];
        usersSelected?: ReadOnlyUser[];
    };
    roles: Role[];
    helper?: {
        content: string;
    };
    polygonExternalValues?: PolygonExternalValues;
    autocompleteCustomPlace: google.maps.places.QueryAutocompletePrediction[];
    remove?: PoiModel;
    removePoiLoading: boolean;
}

class PoiModule extends Component<Props, State> {
    mapPoiCreated?: Subscription;
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const roles = this._logic.auth().roles();
        const params: Params = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this.state = {
            formOpen: false,
            bar: {
                add: false,
                backButtonUrl: params['allowances-driverId']
                    ? `${RouteNames.STATISTICS_ALLOWANCES_DRIVER_DETAIL}/${params['allowances-driverId']}?start=${params['allowances-start']}&end=${params['allowances-end']}`
                    : undefined
            },
            roles,
            removePoiLoading: false,
            autocompleteCustomPlace: []
        };
    }

    componentDidMount() {
        (window as any).app.PoiModule = this;

        this._logic.customPlacesLogic().init();
        this.mapPoiCreated = this._logic.dispatcherKitPoi().onPoiCreated.subscribe(() => {
            // this will be triggered when u create poi from map module.
            this._logic.customPlacesLogic().reloadCustomPlaces();
        });
    }

    componentWillUnmount() {
        (window as any).app.PoiModule = undefined;
        this._logic.map().poiOff();
        this._logic.map().poi().destroy();
        this.mapPoiCreated?.unsubscribe();
    }

    render() {
        const selectedCustomPlace = this._logic.customPlacesLogic().selectedCustomPlace;
        const layoutSidePanel = this.state.formOpen || this._logic.customPlacesLogic().selectedCustomPlace;
        return (
            <>
                <LayoutContent
                    className={cn('poi no-padding', {
                        'with-add-poi': layoutSidePanel
                    })}
                    mainSizes={layoutSidePanel ? { xs: 14, sm: 14, md: 14 } : { xs: 24, sm: 24, md: 24 }}
                    extraSizes={layoutSidePanel ? [{ xs: 10, sm: 10, md: 10 }] : undefined}
                    main={
                        <div className="poi-bar-table t-col t-default">
                            <TableBar
                                heading={this.props.t('PoiBar.pointOfInterests')}
                                onHelperClick={this._onBarHelperClick}
                                actions={[
                                    this.state.bar.backButtonUrl && (
                                        <Link to={this.state.bar.backButtonUrl}>
                                            <Button
                                                type="link"
                                                size="large"
                                                qa={qa.poi.bar.btnBack}
                                                title={this.props.t('common.back')}
                                                icon={<i className="allowance-back-button fas fa-arrow-left" />}
                                            />
                                        </Link>
                                    ),
                                    this.state.roles.includes(Role.POI_W) && (
                                        <Button
                                            type="dashed"
                                            size="large"
                                            title={this.props.t('PoiBar.addPoi')}
                                            qa={qa.poi.bar.btnAdd}
                                            onClick={() => this._onBarAddClick?.()}
                                        >
                                            {this.props.t('PoiBar.addPoi')}
                                        </Button>
                                    )
                                ]}
                            />
                            <PoiTable
                                selectedRowId={this._logic.customPlacesLogic().selectedCustomPlace?.id}
                                data={this._logic.customPlacesLogic().customPlacesPoiModel}
                                loading={this._logic.customPlacesLogic().loadingCustomPlaces}
                                onRowSelect={this._onTableRowSelect}
                            />
                        </div>
                    }
                    extra={[
                        layoutSidePanel && (
                            <>
                                <LayoutSidePanel
                                    header={
                                        this._logic.customPlacesLogic().selectedCustomPlace?.id
                                            ? this._logic.customPlacesLogic().selectedCustomPlace?.name
                                            : this.props.t('PoiForm.customPlace')
                                    }
                                    headerIcon={pois}
                                    body={
                                        <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1']}>
                                            <Collapse.Panel header={this.props.t('PoiForm.customPlaceDetail')} key="1">
                                                {this.state.formOpen ? (
                                                    <CustomPlacesForm
                                                        searchResults={this.state.autocompleteCustomPlace.map(cp => ({
                                                            code: cp.place_id ?? '',
                                                            label: cp.description
                                                        }))}
                                                        onSearchPlaceSelect={this._onFormSearchSelect}
                                                        onSearchPlaceTextChange={this._onFormSearchTextChange}
                                                        polygonExternalValues={this.state.polygonExternalValues}
                                                        initialValues={
                                                            this._logic.customPlacesLogic().selectedCustomPlace
                                                        }
                                                        onSubmit={this._onFormSubmit}
                                                        onCancel={this._onFormCancel}
                                                        demoMode={this._logic.demo().isActive}
                                                    />
                                                ) : (
                                                    selectedCustomPlace && (
                                                        <>
                                                            <PoiDetailCard
                                                                data={selectedCustomPlace}
                                                                placeOfWorkUsers={
                                                                    this._logic.customPlacesLogic()
                                                                        .selectedPlacesOfWorkUserExtended
                                                                }
                                                                demoMode={this._logic.demo().isActive}
                                                                onPlaceOfWorkUserDeleteButtonClick={
                                                                    this._onPlaceOfWorkUserDelete
                                                                }
                                                            />
                                                        </>
                                                    )
                                                )}
                                            </Collapse.Panel>
                                        </Collapse>
                                    }
                                    footer={
                                        selectedCustomPlace && (
                                            <PoiActions
                                                model={this._logic.customPlacesLogic().selectedCustomPlace!}
                                                roles={this.state.roles}
                                                onEditClick={this._onActionsUpdateClick}
                                                onAddPlaceOfWorkClick={this._onActionsPlaceOfWorkUsersAdd}
                                                onDeleteClick={this._onActionsDeleteClick}
                                            />
                                        )
                                    }
                                />
                            </>
                        )
                    ]}
                />
                {this.state.placeOfWorkUsersAdd?.visible && this._logic.customPlacesLogic().selectedCustomPlace && (
                    <Modal
                        className="place-of-work-modal"
                        width={350}
                        visible={true}
                        centered={true}
                        closable={false}
                        title={this.props.t('ManagementPoi.addUserToPlaceOfWorkHeader')}
                        footer={null}
                    >
                        <Row className="place-of-work-modal-title">{`${this.props.t(
                            'ManagementPoi.addUserToPlaceOfWorkConfirm'
                        )} ${this._logic.customPlacesLogic().selectedCustomPlace?.name}`}</Row>
                        <Row>
                            <PlaceOfWorkForm
                                onSubmit={this._onPlaceOfWorkUsersAddConfirm}
                                onCancel={() => {
                                    this.setState({
                                        placeOfWorkUsersAdd: {
                                            visible: false
                                        }
                                    });
                                }}
                                demoMode={this._logic.demo().isActive}
                                logic={this._logic}
                            />
                        </Row>
                    </Modal>
                )}

                {this.state.remove && (
                    <Confirm
                        danger
                        header={this.props.t('PoiForm.deleteHeader')}
                        message={this.props.t('PoiForm.deletePoi')}
                        loading={this.state.removePoiLoading}
                        type={MessageType.WARNING}
                        confirmLabel={this.props.t('common.delete')}
                        onCancel={this._onRemoveCancel}
                        onConfirm={this._onRemoveConfirm}
                        confirmDisabled={this._logic.demo().isActive}
                    />
                )}

                {this.state.placeOfWorkUserDelete && (
                    <Confirm
                        danger
                        header={this.props.t('ManagementPoi.deletePlaceOfWorkConfirm')}
                        message={`${this._logic.customPlacesLogic().selectedCustomPlace?.address} : ${this.props.t(
                            'ManagementPoi.deletePlaceOfWorkConfirm'
                        )} ${this.state.placeOfWorkUserDelete?.placeOfWork?.userName} ${
                            this.state.placeOfWorkUserDelete?.placeOfWork?.userSurname
                        }`}
                        type={MessageType.WARNING}
                        loading={this._logic.customPlacesLogic().loadingRemovePlacesOfWork}
                        confirmLabel={this.props.t('common.delete')}
                        confirmDisabled={this.state.placeOfWorkUserDelete?.loading}
                        onCancel={this._onPlaceOfWorkUserDeleteCancel}
                        onConfirm={this._onPlaceOfWorkUserDeleteConfirm}
                    />
                )}

                <HelperModal
                    name="poi"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _editPolygon = (coordinates: LatLng[], center: LatLng): void => {
        this._logic
            .dispatcherKitPoi()
            .geocodeLatLng(center)
            .then(address => {
                this.setState({
                    polygonExternalValues: {
                        address: address.formattedAddress,
                        center,
                        polygon: coordinates,
                        countryCode: address.countryCode
                    }
                });
            });
    };

    private _onBarAddClick = (): void => {
        this._logic.map().setPoiBarPossible(false);
        this._logic.customPlacesLogic().setSelectedCustomPlace(undefined);
        this._logic.map().poiOff();
        this._logic.map().poi().destroy();
        this.setState(state => ({
            formOpen: true,
            bar: {
                ...state.bar,
                add: true
            },
            polygonExternalValues: undefined
        }));

        this._logic.map().poi().onCreatePolygon(this._initialGeocode);
        this._logic.map().poi().onEditPolygon(this._editPolygon);
        this._logic.map().poiOn();
        this._logic.map().poi().onDragEndPolygon(this._editPolygon);
    };

    private _initialGeocode = (lat: number, lng: number): void => {
        const coordinates = this._logic.map().poi().getCoordinatesFromPoint(lat, lng);

        this._logic
            .dispatcherKitPoi()
            .geocodeLatLng({ lat, lng })
            .then(address => {
                this.setState({
                    polygonExternalValues: {
                        address: address.formattedAddress,
                        center: {
                            lat,
                            lng
                        },
                        polygon: coordinates,
                        countryCode: address.countryCode
                    }
                });
            });
    };

    private _onTableRowSelect = (customPlace: PoiModel): void => {
        if (customPlace.id === this._logic.customPlacesLogic().selectedCustomPlace?.id) {
            this._onFormClose();
        } else {
            this._logic.customPlacesLogic().setSelectedCustomPlace(customPlace);
            this._logic.map().poi().destroy();
            this._logic.map().poiOff();
            if (customPlace.polygon) {
                this._logic.map().poi().renderPoiPolygon(customPlace.polygon, false);
            }
            this._logic.map().poiOn();
            this.setState({
                formOpen: false
            });
        }
    };

    private _onFormSubmit = async (poi?: PoiModel): Promise<boolean> => {
        this._logic.exponea().trackEvent(exponea.module.settingsCustomPlaces, {
            status: exponea.status.actionTaken,
            action: exponea.action.addPoi
        });

        try {
            !this._logic.customPlacesLogic().selectedCustomPlace?.id
                ? await this._logic.customPlacesLogic().createPoi({
                      ...poi,
                      ...this.state.polygonExternalValues
                  })
                : await this._logic.customPlacesLogic().updatePoi({
                      id: this._logic.customPlacesLogic().selectedCustomPlace?.id,
                      ...poi,
                      ...this.state.polygonExternalValues
                  });

            if (this._logic.customPlacesLogic().selectedCustomPlace?.id) {
                message.success(this.props.t('ManagementPoi.message.updateSuccess'));
            } else {
                message.success(this.props.t('ManagementPoi.message.createSuccess'));
            }
            this.setState(state => ({
                bar: {
                    ...state.bar,
                    add: false
                },
                formOpen: false
            }));

            this._logic
                .customPlacesLogic()
                .reloadCustomPlaces()
                .then(() => {
                    this._logic.map().poi().destroy();
                    this._logic.map().poiOff();
                    const polygon = this._logic.customPlacesLogic().selectedCustomPlace?.polygon;
                    if (polygon) {
                        this._logic.map().poi().renderPoiPolygon(polygon, false);
                    }
                    this._logic.map().poiOn();
                });
            this._logic.map().setPoiBarPossible(true);
            return true;
        } catch (err) {
            if (poi?.id) {
                console.error(`Create poi error, err: ${err}`);
                message.error(this.props.t('ManagementPoi.message.updateError'));
            } else {
                console.error(`Update poi error, err: ${err}`);
                message.error(this.props.t('ManagementPoi.message.createError'));
            }
        } finally {
            this._logic.map().setPoiBarPossible(true);
            this.setState({
                formOpen: false
            });
        }
        this.props.logic.poi().pois(true);
        this._logic.map().setPoiBarPossible(true);
        return false;
    };

    private _onFormClose = (): void => {
        this._logic.map().poiOff();
        this.setState(state => ({
            bar: {
                ...state.bar,
                add: false
            },
            formOpen: false
        }));
        this._logic.map().poi().destroy();
        this._logic.customPlacesLogic().setSelectedCustomPlace(undefined);
        this._logic.map().setPoiBarPossible(true);
    };

    private _onFormCancel = (): void => {
        this._logic.map().poi().destroy();
        this._logic.map().poiOff();
        this.setState({
            formOpen: false
        });
        this._logic.map().setPoiBarPossible(true);
    };

    private _onRemoveCancel = (): void => {
        this.setState({
            remove: undefined
        });
    };

    private _onRemoveConfirm = async (): Promise<void> => {
        if (this.state.remove?.id) {
            this.setState({
                removePoiLoading: true
            });
            let success = false;
            try {
                await this._logic.dispatcherKitPoi().deletePoi(this.state.remove?.id);
                message.success(this.props.t('ManagementPoi.message.removeSuccess'));
                success = true;
            } catch (err) {
                console.error(`Remove poi error, err: ${err}`);
                message.error(this.props.t('ManagementPoi.message.removeError'));
            } finally {
                if (success) this._logic.customPlacesLogic().reloadCustomPlaces();
                this.setState({
                    remove: undefined
                });
            }
            this._logic.map().poiOff();
            this._logic.map().poi().destroy();

            this.setState({
                removePoiLoading: false
            });
        }
    };

    private _onFormSearchTextChange = debounce(async (text: string): Promise<void> => {
        if (!text) {
            return;
        }

        this._logic
            .geocoding()
            .placesAutocomplete(text, this._logic.geocoding().autocompleteSessionToken())
            .then(suggestions => {
                this.setState({
                    autocompleteCustomPlace: suggestions
                });
            });
    }, 300);

    private _onFormSearchSelect = async (value: string): Promise<void> => {
        const googleSuggestion = this.state.autocompleteCustomPlace.find(s => s.place_id === value);

        if (googleSuggestion) {
            const result = await this._logic
                .geocoding()
                .placesDetail(googleSuggestion?.place_id ?? '', this._logic.geocoding().autocompleteSessionToken());
            if (result.name && result.geometry && result.geometry!.location) {
                const point = result.geometry!.location;

                const coordinates = this._logic.map().poi().renderPoiPolygonFromPoint(point);

                const address = await this._logic.dispatcherKitPoi().geocodeLatLng({
                    lat: result.geometry!.location.lat(),
                    lng: result.geometry!.location.lng()
                });

                this.setState({
                    polygonExternalValues: {
                        address: address.formattedAddress,
                        center: {
                            lat: result.geometry?.location?.lat() ?? 0,
                            lng: result.geometry?.location?.lng() ?? 0
                        },
                        polygon: coordinates,
                        countryCode: address.countryCode
                    }
                });

                this._logic.map().poi().onEditPolygon(this._editPolygon);
            }
        }
    };

    private _onPlaceOfWorkUserDelete = (placeOfWorkId: string): void => {
        const placeOfWork = this._logic
            .customPlacesLogic()
            .selectedPlacesOfWorkUserExtended?.find(place => place.id === placeOfWorkId);
        if (placeOfWork) {
            this.setState({
                placeOfWorkUserDelete: {
                    placeOfWork: placeOfWork
                }
            });
        }
    };

    private _onPlaceOfWorkUserDeleteCancel = (): void => {
        this.setState({ placeOfWorkUserDelete: undefined });
    };

    private _onPlaceOfWorkUserDeleteConfirm = async (): Promise<void> => {
        const placeOfWorkId = this.state.placeOfWorkUserDelete?.placeOfWork?.id;
        let success = false;
        if (placeOfWorkId) {
            try {
                await this._logic.customPlacesLogic().removeUserFromSelectedCustomPlace(placeOfWorkId);
                success = true;
                this.setState({
                    placeOfWorkUserDelete: undefined
                });
                message.success(this.props.t('ManagementPoi.message.deletePlaceOfWorkSuccess'));
            } catch (err) {
                console.error(`Delete user from place of work failed, err: ${err}`);
                message.error(this.props.t('ManagementPoi.message.deletePlaceOfWorkError'));
            } finally {
                if (success) this._logic.customPlacesLogic().reloadCustomPlaces();
            }
        }
    };

    private _onActionsPlaceOfWorkUsersAdd = (): void => {
        this.setState({
            placeOfWorkUsersAdd: {
                visible: true
            }
        });
    };

    private _onPlaceOfWorkUsersAddConfirm = async (formModel: PlaceOfWorkFormModel): Promise<boolean> => {
        this.setState(state => ({ placeOfWorkUsersAdd: { ...state.placeOfWorkUsersAdd, loading: true } }));
        let success = false;
        try {
            await this._logic
                .customPlacesLogic()
                .addUsersToSelectedCustomPlace(formModel.userIds, formModel.start, formModel.end);
            success = true;
            message.success(this.props.t('ManagementPoi.message.createPlaceOfWorkSuccess'));
        } catch (err) {
            message.error(this.props.t('ManagementPoi.message.createPlaceOfWorkError'));
            console.error(`Could not create place of work, err: ${err}`);
        } finally {
            // no reason to fetch all data if we didn't created place of work ()
            if (success) this._logic.customPlacesLogic().reloadCustomPlaces();
            this.setState({
                placeOfWorkUsersAdd: {
                    visible: false
                }
            });
        }

        return success;
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onActionsUpdateClick = (): void => {
        this._logic.map().poiOff();
        this._logic.map().poi().destroy();

        const polygon = this._logic.customPlacesLogic().selectedCustomPlace?.polygon;

        if (polygon) {
            this._logic.map().poi().renderPoiPolygon(polygon, true);

            this._logic.map().poi().onEditPolygon(this._editPolygon);
            this._logic.map().poi().onDragEndPolygon(this._editPolygon);
        }
        this._logic.map().poiOn();

        this.setState({
            formOpen: true,
            polygonExternalValues: {
                address: this._logic.customPlacesLogic().selectedCustomPlace?.address,
                center: this._logic.customPlacesLogic().selectedCustomPlace?.center,
                countryCode: this._logic.customPlacesLogic().selectedCustomPlace?.countryCode,
                polygon: this._logic.customPlacesLogic().selectedCustomPlace?.polygon
            }
        });
    };

    private _onActionsDeleteClick = (): void => {
        this.setState({
            remove: this._logic.customPlacesLogic().selectedCustomPlace
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(observer(PoiModule)));
