/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20043,
    InlineResponse20043FromJSON,
    InlineResponse20043ToJSON,
    ReadOnlyUserRight,
    ReadOnlyUserRightFromJSON,
    ReadOnlyUserRightToJSON,
    WriteOnlyUserRight,
    WriteOnlyUserRightFromJSON,
    WriteOnlyUserRightToJSON,
} from '../models';

export interface UserRightCreateRequest {
    data: WriteOnlyUserRight;
}

export interface UserRightDeleteRequest {
    id: string;
}

export interface UserRightListRequest {
    limit?: number;
    offset?: number;
}

export interface UserRightPartialUpdateRequest {
    id: string;
    data: WriteOnlyUserRight;
}

export interface UserRightReadRequest {
    id: string;
}

export interface UserRightUpdateRequest {
    id: string;
    data: WriteOnlyUserRight;
}

/**
 * no description
 */
export class UserRightApi extends runtime.BaseAPI {

    /**
     */
    async userRightCreateRaw(requestParameters: UserRightCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRight>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRightCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRightToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRightFromJSON(jsonValue));
    }

    /**
     */
    async userRightCreate(requestParameters: UserRightCreateRequest): Promise<ReadOnlyUserRight> {
        const response = await this.userRightCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRightDeleteRaw(requestParameters: UserRightDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyUserRight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRightDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRightFromJSON(jsonValue));
    }

    /**
     */
    async userRightDelete(requestParameters: UserRightDeleteRequest): Promise<ReadOnlyUserRight> {
        const response = await this.userRightDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRightExportRaw(): Promise<runtime.ApiResponse<Array<ReadOnlyUserRight>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/export/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyUserRightFromJSON));
    }

    /**
     */
    async userRightExport(): Promise<Array<ReadOnlyUserRight>> {
        const response = await this.userRightExportRaw();
        return await response.value();
    }

    /**
     */
    async userRightListRaw(requestParameters: UserRightListRequest): Promise<runtime.ApiResponse<InlineResponse20043>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20043FromJSON(jsonValue));
    }

    /**
     */
    async userRightList(requestParameters: UserRightListRequest): Promise<InlineResponse20043> {
        const response = await this.userRightListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRightPartialUpdateRaw(requestParameters: UserRightPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRightPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRightPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRightToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRightFromJSON(jsonValue));
    }

    /**
     */
    async userRightPartialUpdate(requestParameters: UserRightPartialUpdateRequest): Promise<ReadOnlyUserRight> {
        const response = await this.userRightPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRightReadRaw(requestParameters: UserRightReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserRight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRightRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRightFromJSON(jsonValue));
    }

    /**
     */
    async userRightRead(requestParameters: UserRightReadRequest): Promise<ReadOnlyUserRight> {
        const response = await this.userRightReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRightUpdateRaw(requestParameters: UserRightUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRightUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRightUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-right/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRightToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRightFromJSON(jsonValue));
    }

    /**
     */
    async userRightUpdate(requestParameters: UserRightUpdateRequest): Promise<ReadOnlyUserRight> {
        const response = await this.userRightUpdateRaw(requestParameters);
        return await response.value();
    }

}
