/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationManyRes
 */
export interface NotificationManyRes {
    /**
     * 
     * @type {string}
     * @memberof NotificationManyRes
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationManyRes
     */
    device?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationManyRes
     */
    subscribers: number;
}

export function NotificationManyResFromJSON(json: any): NotificationManyRes {
    return NotificationManyResFromJSONTyped(json, false);
}

export function NotificationManyResFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationManyRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': json['user'],
        'device': !exists(json, 'device') ? undefined : json['device'],
        'subscribers': json['subscribers'],
    };
}

export function NotificationManyResToJSON(value?: NotificationManyRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'device': value.device,
        'subscribers': value.subscribers,
    };
}


