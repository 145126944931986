import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import { ReadOnlyMonitoringDeviceType } from 'generated/new-main';

interface Props {
    data: ObuModel;
    deviceTypes?: ReadOnlyMonitoringDeviceType[];
}

export default function ObuDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="tlm-services-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('Partner.sn')}>{props.data.sn}</Descriptions.Item>
                        <Descriptions.Item label={t('Partner.status')}>{props.data.activatedOn}</Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>
                            {props.deviceTypes?.find(o => o.id === Number(props.data.type))?.label}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.company')}>
                            {props.data.clientName ?? props.data.clientId}
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
