/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyAddressNested
 */
export interface WriteOnlyAddressNested {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyAddressNested
     */
    clientId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyAddressNested
     */
    country: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAddressNested
     */
    type?: WriteOnlyAddressNestedTypeEnum;
}

export function WriteOnlyAddressNestedFromJSON(json: any): WriteOnlyAddressNested {
    return WriteOnlyAddressNestedFromJSONTyped(json, false);
}

export function WriteOnlyAddressNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyAddressNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'country': json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'house_number') ? undefined : json['house_number'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function WriteOnlyAddressNestedToJSON(value?: WriteOnlyAddressNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'country': value.country,
        'city': value.city,
        'street': value.street,
        'house_number': value.houseNumber,
        'zip': value.zip,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyAddressNestedTypeEnum {
    Correspondence = 'correspondence',
    Delivery = 'delivery'
}


