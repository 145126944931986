/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalSystemSecretMetadata
 */
export interface ExternalSystemSecretMetadata {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSystemSecretMetadata
     */
    puescActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSystemSecretMetadata
     */
    puescSentFormsActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSystemSecretMetadata
     */
    puescDataAlwaysOn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalSystemSecretMetadata
     */
    volvoUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalSystemSecretMetadata
     */
    volvoPassword?: string;
}

export function ExternalSystemSecretMetadataFromJSON(json: any): ExternalSystemSecretMetadata {
    return ExternalSystemSecretMetadataFromJSONTyped(json, false);
}

export function ExternalSystemSecretMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalSystemSecretMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puescActive': !exists(json, 'puesc_active') ? undefined : json['puesc_active'],
        'puescSentFormsActive': !exists(json, 'puesc_sent_forms_active') ? undefined : json['puesc_sent_forms_active'],
        'puescDataAlwaysOn': !exists(json, 'puesc_data_always_on') ? undefined : json['puesc_data_always_on'],
        'volvoUsername': !exists(json, 'volvo_username') ? undefined : json['volvo_username'],
        'volvoPassword': !exists(json, 'volvo_password') ? undefined : json['volvo_password'],
    };
}

export function ExternalSystemSecretMetadataToJSON(value?: ExternalSystemSecretMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puesc_active': value.puescActive,
        'puesc_sent_forms_active': value.puescSentFormsActive,
        'puesc_data_always_on': value.puescDataAlwaysOn,
        'volvo_username': value.volvoUsername,
        'volvo_password': value.volvoPassword,
    };
}


