/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FuelConsumptionGlobal,
    FuelConsumptionGlobalFromJSON,
    FuelConsumptionGlobalFromJSONTyped,
    FuelConsumptionGlobalToJSON,
    FuelConsumptionReportSummary,
    FuelConsumptionReportSummaryFromJSON,
    FuelConsumptionReportSummaryFromJSONTyped,
    FuelConsumptionReportSummaryToJSON,
    FuelConsumptionReportType,
    FuelConsumptionReportTypeFromJSON,
    FuelConsumptionReportTypeFromJSONTyped,
    FuelConsumptionReportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface FuelConsumption
 */
export interface FuelConsumption {
    /**
     * 
     * @type {FuelConsumptionGlobal}
     * @memberof FuelConsumption
     */
    fuelConsumptionGlobal: FuelConsumptionGlobal;
    /**
     * 
     * @type {FuelConsumptionReportSummary}
     * @memberof FuelConsumption
     */
    fuelConsumptionReportSummary: FuelConsumptionReportSummary;
    /**
     * 
     * @type {FuelConsumptionReportType}
     * @memberof FuelConsumption
     */
    fuelConsumptionReportType1: FuelConsumptionReportType;
    /**
     * 
     * @type {FuelConsumptionReportType}
     * @memberof FuelConsumption
     */
    fuelConsumptionReportType2: FuelConsumptionReportType;
    /**
     * 
     * @type {FuelConsumptionReportType}
     * @memberof FuelConsumption
     */
    fuelConsumptionReportType3: FuelConsumptionReportType;
    /**
     * 
     * @type {FuelConsumptionReportType}
     * @memberof FuelConsumption
     */
    fuelConsumptionReportType4: FuelConsumptionReportType;
}

export function FuelConsumptionFromJSON(json: any): FuelConsumption {
    return FuelConsumptionFromJSONTyped(json, false);
}

export function FuelConsumptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fuelConsumptionGlobal': FuelConsumptionGlobalFromJSON(json['fuel_consumption_global']),
        'fuelConsumptionReportSummary': FuelConsumptionReportSummaryFromJSON(json['fuel_consumption_report_summary']),
        'fuelConsumptionReportType1': FuelConsumptionReportTypeFromJSON(json['fuel_consumption_report_type_1']),
        'fuelConsumptionReportType2': FuelConsumptionReportTypeFromJSON(json['fuel_consumption_report_type_2']),
        'fuelConsumptionReportType3': FuelConsumptionReportTypeFromJSON(json['fuel_consumption_report_type_3']),
        'fuelConsumptionReportType4': FuelConsumptionReportTypeFromJSON(json['fuel_consumption_report_type_4']),
    };
}

export function FuelConsumptionToJSON(value?: FuelConsumption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fuel_consumption_global': FuelConsumptionGlobalToJSON(value.fuelConsumptionGlobal),
        'fuel_consumption_report_summary': FuelConsumptionReportSummaryToJSON(value.fuelConsumptionReportSummary),
        'fuel_consumption_report_type_1': FuelConsumptionReportTypeToJSON(value.fuelConsumptionReportType1),
        'fuel_consumption_report_type_2': FuelConsumptionReportTypeToJSON(value.fuelConsumptionReportType2),
        'fuel_consumption_report_type_3': FuelConsumptionReportTypeToJSON(value.fuelConsumptionReportType3),
        'fuel_consumption_report_type_4': FuelConsumptionReportTypeToJSON(value.fuelConsumptionReportType4),
    };
}


