import { Aggregator, ExpenseState, ExpenseModel, CargoExpense } from 'logic/statistics/statistics-expenses';
import { WithTranslation, withTranslation } from 'react-i18next';
import ExpensesBar from './ExpensesBar';
import ExpensesCargo, { ExpenseMode } from './ExpensesCargo';
import ExpensesTable, { ExpensesModel } from './ExpensesTable';
import Confirm, { MessageType } from 'common/components/Confirm';
import { LayoutContent } from 'common/components/Layout/Content';
import { Role } from 'logic/auth';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import HelperModal from 'common/components/HelperModal';
import { DateRange } from 'common/model/date-time';
import { ExpensesFilterModel } from '../ExpensesModule';

interface Props extends WithTranslation {
    bar: {
        cargo?: boolean;
        dateOpen?: boolean;
        dateChanged?: boolean;
        filterOpen?: boolean;
        backUrl?: boolean;
        fuelOnly?: boolean;
    };
    cargo?: {
        vehicles: {
            label: string;
            code: number;
        }[];
        suppliers: {
            label: string;
            code: string;
        }[];
        date: string;
        mode: ExpenseMode;
        expense?: CargoExpense;
        type?: ExpenseType;
        loading?: boolean;
    };
    dateRange: DateRange;
    filter: ExpensesFilterModel;
    helper?: {
        content: string;
    };
    table: {
        data: ExpensesModel[];
        aggregator: Aggregator;
        expandedRow: string;
        loading: boolean;
        selectedExpense?: string;
    };
    deleteConfirmOpen: boolean;
    roles: Role[];
    lang: string;
    demoMode?: boolean;
    export: {
        enabled: boolean;
        loading: boolean;
    };
    onBarFilterClick?: () => void;
    onBarCargoClick?: () => void;
    onBarBackUrlClick?: () => void;
    onBarResetClick?: () => void;
    onBarHelperClick?: () => void;
    onBarFuelOnlyChange?: (checked: boolean) => void;
    onBarExportClick?: () => void;
    onCargoSupplierChange: (value: string) => void;
    onCargoConfirm: (cargo: CargoExpense) => Promise<boolean>;
    onCargoCancel?: () => void;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onFilterConfirm?: (
        vehiclesChecked: string[],
        type?: ExpenseType,
        payment?: ExpenseState,
        source?: ExpenseSource
    ) => void;
    onFilterCancel?: () => void;
    onHelperClose?: () => void;
    onTableDetailEditClick: (data: ExpenseModel) => void;
    onTableDetailDeleteClick: (id: string) => void;
    onTableDetailDeleteConfirmClick: () => void;
    onTableDetailDeleteCancelClick: () => void;
    onTableRowClick?: (id: string) => void;
    onTableAggregtorChange?: (aggregator: Aggregator) => void;
    onImportSuccess?: () => void;
    onFuelonlyChange?: (checked: boolean) => void;
}

function Expenses(props: Props) {
    return (
        <>
            <LayoutContent
                className="expenses"
                mainSizes={props.bar.cargo && props.cargo ? { xs: 16, sm: 18, md: 18 } : { xs: 24, sm: 24, md: 24 }}
                extraSizes={props.bar.cargo && props.cargo ? [{ xs: 8, sm: 6, md: 6 }] : undefined}
                main={
                    <>
                        <ExpensesBar
                            lang={props.lang}
                            roles={props.roles}
                            cargo={props.bar.cargo}
                            dateOpen={props.bar.dateOpen}
                            dateChanged={props.bar.dateChanged}
                            dateRange={props.dateRange}
                            filter={props.filter}
                            filterOpen={props.bar.filterOpen}
                            type={props.filter.type}
                            fuelOnly={props.bar.fuelOnly}
                            backUrl={props.bar.backUrl}
                            demoMode={props.demoMode}
                            export={props.export}
                            onCargoClick={props.onBarCargoClick}
                            onDateRangeChange={props.onDateRangeChange}
                            onFilterClick={props.onBarFilterClick}
                            onBackUrlClick={props.onBarBackUrlClick}
                            onFuelOnlyChange={props.onBarFuelOnlyChange}
                            onResetClick={props.onBarResetClick}
                            onImportSuccess={props.onImportSuccess}
                            onHelperClick={props.onBarHelperClick}
                            onExportClick={props.onBarExportClick}
                            onFilterConfirm={props.onFilterConfirm}
                            onFilterCancel={props.onFilterCancel}
                        />
                        {props.table && (
                            <ExpensesTable
                                data={props.table.data}
                                loading={props.table.loading}
                                aggregator={props.table.aggregator}
                                expandedRow={props.table.expandedRow}
                                roles={props.roles}
                                type={props.filter.type}
                                selectedExpense={props.table.selectedExpense}
                                onDeleteClick={props.onTableDetailDeleteClick}
                                onEditClick={props.onTableDetailEditClick}
                                onRowClick={props.onTableRowClick}
                                onAggregatorChange={props.onTableAggregtorChange}
                            />
                        )}
                    </>
                }
                extra={
                    props.bar.cargo && props.cargo
                        ? [
                              <ExpensesCargo
                                  mode={props.cargo.mode}
                                  vehicles={props.cargo.vehicles}
                                  suppliers={props.cargo.suppliers}
                                  date={props.cargo.date}
                                  expense={props.cargo.expense}
                                  type={props.cargo.type}
                                  loading={props.cargo.loading}
                                  onSupplierTextChange={props.onCargoSupplierChange!}
                                  onConfirm={props.onCargoConfirm}
                                  onCancel={props.onCargoCancel!}
                              />
                          ]
                        : undefined
                }
            />

            {props.deleteConfirmOpen && (
                <div className="expenses-confirm">
                    <Confirm
                        danger
                        header={props.t('ExpensesConfirm.deleteHeader')}
                        message={`${props.t('ExpensesConfirm.deleteConfirm')}`}
                        type={MessageType.WARNING}
                        confirmLabel={props.t('common.delete')}
                        onCancel={props.onTableDetailDeleteCancelClick}
                        onConfirm={props.onTableDetailDeleteConfirmClick}
                    />
                </div>
            )}

            <HelperModal
                name="expenses"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </>
    );
}

export default withTranslation()(Expenses);
