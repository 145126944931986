/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripTrailer
 */
export interface TripTrailer {
    /**
     * 
     * @type {string}
     * @memberof TripTrailer
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof TripTrailer
     */
    registrationNumber: string;
}

export function TripTrailerFromJSON(json: any): TripTrailer {
    return TripTrailerFromJSONTyped(json, false);
}

export function TripTrailerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripTrailer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'registrationNumber': json['registration_number'],
    };
}

export function TripTrailerToJSON(value?: TripTrailer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'registration_number': value.registrationNumber,
    };
}


