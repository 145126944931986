import { useState } from 'react';
import TableFilter from 'common/components/TableFilter';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { RadioGroup } from 'common/components/RadioGroup';
import { FleetActive, FleetType } from '../../FleetModule';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    fleetType?: FleetType;
    fleetActive?: FleetActive;
    onConfirm?: (filter: { fleetActive: FleetActive | undefined; fleetType: FleetType | undefined }) => void;
    onCancel?: () => void;
}

function FleetFilter(props: Props) {
    const { t } = props;
    const [fleetType, setFleetType] = useState<FleetType | undefined>(props.fleetType);
    const [fleetActive, setFleetActive] = useState<FleetActive | undefined>(props.fleetActive);

    function onResetClick() {
        const defaults = confDefault.settings.management.fleet.filter;
        setFleetType(defaults.fleetType);
        setFleetActive(defaults.fleetActive);
    }

    return (
        <TableFilter
            className="fleet-filter"
            qa={qa.coldChain.filter.section}
            heading={t('ManagementFleetFilter.title')}
            body={
                <Row gutter={[24, 24]}>
                    <Col>
                        <RadioGroup
                            heading={t('ManagementFleetFilter.fleetTypeTitle')}
                            type="radio"
                            options={[
                                {
                                    code: '',
                                    label: t('common.all'),
                                    qa: qa.fleet.filter.radioTypeAll
                                },
                                ...Object.keys(FleetType).map(fleetType => ({
                                    code: FleetType[fleetType],
                                    label: t(`Partner.${String(FleetType[fleetType]).toLowerCase()}`),
                                    qa: qa.common.radio
                                }))
                            ]}
                            checked={fleetType ? fleetType : ''}
                            onChange={code => setFleetType(code ? (code as FleetType) : undefined)}
                            name="type"
                            qa={qa.fleet.filter.fleetType}
                        />
                    </Col>

                    <Col>
                        <RadioGroup
                            heading={t('ManagementFleetFilter.fleetActiveTitle')}
                            type="radio"
                            options={[
                                {
                                    code: '',
                                    label: t('common.all'),
                                    qa: qa.fleet.filter.radioActiveAll
                                },
                                {
                                    code: 'active',
                                    label: t('ManagementFleetFilter.active'),
                                    qa: qa.fleet.filter.radioActive
                                },
                                {
                                    code: 'discharged',
                                    label: t('ManagementFleetFilter.discharged'),
                                    qa: qa.fleet.filter.radioDischarged
                                }
                            ]}
                            checked={fleetActive ? fleetActive : ''}
                            onChange={code => setFleetActive(code ? (code as FleetActive) : undefined)}
                            name="type"
                            qa={qa.expenses.filter.groupVehicles}
                        />
                    </Col>
                </Row>
            }
            cancelButton={{
                onClick: props.onCancel,
                qa: qa.fleet.filter.btnCancel
            }}
            submitButton={{
                onClick: () => props.onConfirm?.({ fleetActive, fleetType }),
                qa: qa.fleet.filter.btnConfirm,
                disabled: props.demoMode
            }}
            resetButton={{
                onClick: onResetClick
            }}
        />
    );
}

export default withTranslation()(FleetFilter);
