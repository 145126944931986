/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ADRClass,
    ADRClassFromJSON,
    ADRClassFromJSONTyped,
    ADRClassToJSON,
    Accessories,
    AccessoriesFromJSON,
    AccessoriesFromJSONTyped,
    AccessoriesToJSON,
    TruckBody,
    TruckBodyFromJSON,
    TruckBodyFromJSONTyped,
    TruckBodyToJSON,
    WayOfLoading,
    WayOfLoadingFromJSON,
    WayOfLoadingFromJSONTyped,
    WayOfLoadingToJSON,
} from './';

/**
 * 
 * @export
 * @interface Requirements
 */
export interface Requirements {
    /**
     * 
     * @type {Array<ADRClass>}
     * @memberof Requirements
     */
    adrClasses: Array<ADRClass>;
    /**
     * 
     * @type {TruckBody}
     * @memberof Requirements
     */
    truckBody: TruckBody;
    /**
     * 
     * @type {Array<Accessories>}
     * @memberof Requirements
     */
    accessories?: Array<Accessories>;
    /**
     * 
     * @type {Array<WayOfLoading>}
     * @memberof Requirements
     */
    waysOfLoading?: Array<WayOfLoading>;
}

export function RequirementsFromJSON(json: any): Requirements {
    return RequirementsFromJSONTyped(json, false);
}

export function RequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Requirements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adrClasses': ((json['adrClasses'] as Array<any>).map(ADRClassFromJSON)),
        'truckBody': TruckBodyFromJSON(json['truckBody']),
        'accessories': !exists(json, 'accessories') ? undefined : ((json['accessories'] as Array<any>).map(AccessoriesFromJSON)),
        'waysOfLoading': !exists(json, 'waysOfLoading') ? undefined : ((json['waysOfLoading'] as Array<any>).map(WayOfLoadingFromJSON)),
    };
}

export function RequirementsToJSON(value?: Requirements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adrClasses': ((value.adrClasses as Array<any>).map(ADRClassToJSON)),
        'truckBody': TruckBodyToJSON(value.truckBody),
        'accessories': value.accessories === undefined ? undefined : ((value.accessories as Array<any>).map(AccessoriesToJSON)),
        'waysOfLoading': value.waysOfLoading === undefined ? undefined : ((value.waysOfLoading as Array<any>).map(WayOfLoadingToJSON)),
    };
}


