import moment from 'moment';
import { Typography } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DroppableProvided } from 'react-beautiful-dnd';
import qa from 'qa-selectors';

import { Loading, Menu, Dropdown, Tooltip } from 'common/components';
import { BoardGridConfig, DispatcherBoardRow, TransportWithUIProperties } from '../../dispatcher-board-logic';
import { NoData } from 'common/components/NoData';

type PropsAndRefCallback = (provided: DroppableProvided, props: React.ComponentProps<any>) => ReactElement;

interface Props {
    header: ReactNode;
    rows: DispatcherBoardRow[];
    left: ReactNode;
    selectedDay: string;
    config: BoardGridConfig;
    loading: boolean;
    freeTransports: DispatcherBoardRow['transports'];
    showFreeTransports: boolean;
    // TODO: Don't now if this is good idea maybe very complicated need to find out better solution
    rowRender?: (children: PropsAndRefCallback, row: DispatcherBoardRow) => ReactNode;
    freeTransportsRender?: (children: PropsAndRefCallback) => ReactNode;
    transportRender: (transport: TransportWithUIProperties, index: number) => ReactNode;
    onCreateTransport: (row: DispatcherBoardRow) => void;
}

export default function BoardLayout({
    header,
    rows,
    left,
    config,
    loading,
    selectedDay,
    showFreeTransports,
    freeTransports,
    transportRender,
    freeTransportsRender = children => children,
    rowRender = children => children,
    onCreateTransport
}: Props) {
    const { t } = useTranslation();

    function getDays(): number[] {
        return [...Array(config.visibleDays)].map((_, i) => i);
    }

    function renderTimeLine() {
        const date = moment(selectedDay).subtract(config.activeDayIndex - 1, 'days');
        const currentDateIndex = moment().startOf('day').diff(date.startOf('day'), 'days');
        const dayWidth = Math.round(100 / config.visibleDays);
        const timelineLeft =
            dayWidth * currentDateIndex + (dayWidth / 24) * (moment().hours() + moment().minutes() / 60);

        return currentDateIndex >= 0 ? (
            <div
                className="board-layout-grid-timeline"
                style={{
                    left: `calc(${timelineLeft}%)`
                }}
            />
        ) : null;
    }

    function transportKey(transport: TransportWithUIProperties) {
        const time = transport.data.firstPlaceRta ? moment.utc(transport.data.firstPlaceRta).toDate().getTime() : 0;
        const key = `${transport.type}-${transport.data.id}-${time}`;
        return key;
    }

    function renderTransports(transports: DispatcherBoardRow['transports']) {
        return transports.map((transportRow, i) => (
            <div key={i} className="board-layout-row-container">
                {transportRow.map((transport, i) => (
                    <React.Fragment key={transportKey(transport)}>
                        {/*
                         * TODO:
                         * element ghost is not absolute because we want to automatically calculate height
                         * of rows based on content, maybe can be improved with css grid
                         */}
                        <div
                            className="board-layout-row-container-element-ghost"
                            style={{ width: `${transport.properties.width}%` }}
                        >
                            {transportRender(transport, i)}
                        </div>
                        <div
                            className="board-layout-row-container-element"
                            style={{
                                width: `${transport.properties.width}%`,
                                marginLeft: `${transport.properties.offset}%`
                            }}
                        >
                            {transportRender(transport, i)}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        ));
    }

    return (
        <div className="board-layout">
            <div className="board-layout-outer-row">
                <div className="board-layout-outer-row-left">{left}</div>
                <div className="board-layout-outer-row-right">{header}</div>
            </div>
            {showFreeTransports &&
                freeTransportsRender((provided, props) => (
                    <div className="board-layout-outer-row" {...props}>
                        <div className="board-layout-outer-row-left" />
                        <div
                            className="board-layout-outer-row-right free-transports"
                            style={{ minHeight: '50px' }}
                            ref={provided.innerRef}
                            data-qa={qa.dispatcherBoard.sectionFreeTransports}
                            {...provided.droppableProps}
                        >
                            {renderTransports(freeTransports)}
                            {provided.placeholder}
                        </div>
                    </div>
                ))}
            <div className="board-layout-data">
                <div className="board-layout-scroll-area">
                    {loading ? (
                        <Loading />
                    ) : rows.length === 0 ? (
                        <div className="board-layout-scroll-area-placeholder">
                            <NoData />
                        </div>
                    ) : (
                        <div className="board-layout-scroll-area-inner">
                            <div className="board-layout-grid">
                                <div className="board-layout-grid-left" />
                                <div className="board-layout-grid-right">
                                    {getDays().map((_, i) => (
                                        <div
                                            key={i}
                                            className="board-layout-grid-right-col"
                                            style={{ width: `${Math.round(100 / config.visibleDays)}%` }}
                                        />
                                    ))}
                                    {rows.length > 0 && renderTimeLine()}
                                </div>
                            </div>
                            {rows.map((row, index) =>
                                rowRender(
                                    (provided, props) => (
                                        <div key={index} className="board-layout-outer-row" {...props}>
                                            <div className="board-layout-outer-row-left">
                                                <div
                                                    className="board-vehicle"
                                                    data-qa={qa.dispatcherBoard.fieldRn}
                                                    data-vehicle-id={row.vehicle.id}
                                                >
                                                    <Tooltip overlay={row.vehicle.registrationNumber}>
                                                        <Typography.Text ellipsis>
                                                            {row.vehicle.registrationNumber}
                                                        </Typography.Text>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div
                                                ref={provided.innerRef}
                                                className="board-layout-outer-row-right"
                                                data-qa={qa.dispatcherBoard.sectionRnRow}
                                                {...provided.droppableProps}
                                            >
                                                <Dropdown
                                                    overlay={
                                                        <Menu>
                                                            <Menu.Item key="1" onClick={() => onCreateTransport(row)}>
                                                                {t(
                                                                    'DispatcherBoard.createTransportFromDispatcherBoard'
                                                                )}
                                                            </Menu.Item>
                                                        </Menu>
                                                    }
                                                    trigger={['contextMenu']}
                                                >
                                                    <div className="board-layout-row" key={row.vehicle.id}>
                                                        {renderTransports(row.transports)}
                                                    </div>
                                                </Dropdown>
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    ),
                                    row
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
