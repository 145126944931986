import React from 'react';
import moment from 'moment';

interface Props {
    format?: string;
    formatLong?: string;
    interval?: number;
}

interface State {
    time: number;
}

const intervalDefault = 1e3;
const formatDefault = 'LTS';
const formatLongDefault = 'LLL';

export default class Clock extends React.Component<Props, State> {
    private _interval: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            time: Date.now()
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.interval !== this.props.interval) {
            this._timer(this.props.interval || intervalDefault);
        }
    }

    componentDidMount() {
        this._timer(this.props.interval || intervalDefault);
    }

    componentWillUnmount() {
        this._timer(0);
    }

    render() {
        const now = moment();
        return (
            <span className="clock" title={now.format(this.props.formatLong || formatLongDefault)}>
                {now.format(this.props.format || formatDefault)}
            </span>
        );
    }

    private _timer(interval = 1e3) {
        if (this._interval) {
            clearInterval(this._interval);
        }
        if (interval) {
            this._interval = setInterval(() => {
                this.setState({ time: Date.now() });
            }, interval);
        }
    }
}
