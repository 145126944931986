/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyTLMBundleSerializer,
    ReadOnlyTLMBundleSerializerFromJSON,
    ReadOnlyTLMBundleSerializerToJSON,
} from '../models';

export interface TlmBundleReadRequest {
    id: string;
}

/**
 * no description
 */
export class TlmBundleApi extends runtime.BaseAPI {

    /**
     */
    async tlmBundleListRaw(): Promise<runtime.ApiResponse<Array<ReadOnlyTLMBundleSerializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tlm-bundle/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyTLMBundleSerializerFromJSON));
    }

    /**
     */
    async tlmBundleList(): Promise<Array<ReadOnlyTLMBundleSerializer>> {
        const response = await this.tlmBundleListRaw();
        return await response.value();
    }

    /**
     */
    async tlmBundleReadRaw(requestParameters: TlmBundleReadRequest): Promise<runtime.ApiResponse<ReadOnlyTLMBundleSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tlmBundleRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tlm-bundle/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTLMBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async tlmBundleRead(requestParameters: TlmBundleReadRequest): Promise<ReadOnlyTLMBundleSerializer> {
        const response = await this.tlmBundleReadRaw(requestParameters);
        return await response.value();
    }

}
