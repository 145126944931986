/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MatchFitness
 */
export interface MatchFitness {
    /**
     * 
     * @type {number}
     * @memberof MatchFitness
     */
    total: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MatchFitness
     */
    fits: Array<string>;
}

export function MatchFitnessFromJSON(json: any): MatchFitness {
    return MatchFitnessFromJSONTyped(json, false);
}

export function MatchFitnessFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchFitness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'fits': json['fits'],
    };
}

export function MatchFitnessToJSON(value?: MatchFitness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'fits': value.fits,
    };
}


