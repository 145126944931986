import { LINE_COLORS_COMPLEX } from 'common/components/SimpleLineGraph/constants/colors';
import { TripTemperatureSensor } from 'generated/backend-api';

export const getAvailableZones = (sensors: TripTemperatureSensor[]) => {
    let filteredZones: number[] = [];

    sensors
        .map(device => device.sensorZone)
        .map(zone => {
            if (!filteredZones.includes(zone)) {
                filteredZones = [...filteredZones, zone];
                return filteredZones;
            }

            return filteredZones;
        });

    return filteredZones;
};

export const getColorByIndex = (index: number) => {
    if (index > LINE_COLORS_COMPLEX.length) {
        return LINE_COLORS_COMPLEX[index % LINE_COLORS_COMPLEX.length];
    }

    return LINE_COLORS_COMPLEX[index];
};
