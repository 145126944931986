import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { rbac, RouteNames, WithLogic } from 'App';
import AllowanceListModule from './modules/AllowanceListModule';
import AllowanceDriverDetailModule from './modules/AllowanceDriverDetailModule';
import { Role } from 'logic/auth';

interface State {
    bulkEditVisible: boolean;
    detail: {
        isOpen: boolean;
    };
}

interface Props extends WithLogic {}

export default class AllowancesModule extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            bulkEditVisible: false,
            detail: {
                isOpen: false
            }
        };
    }

    async componentDidMount() {}

    render() {
        const roles = this.props.logic.auth().roles();
        return (
            <Switch>
                <Route
                    path={RouteNames.STATISTICS_ALLOWANCES_LIST}
                    render={rbac(roles, [Role.DIT_R], <AllowanceListModule />)}
                />
                <Route
                    path={RouteNames.STATISTICS_ALLOWANCES_DRIVER_DETAIL_PARAM}
                    render={rbac(roles, [Role.DIT_R], <AllowanceDriverDetailModule />)}
                />
                <Redirect to={RouteNames.STATISTICS_ALLOWANCES_LIST} />
            </Switch>
        );
    }
}
