/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoutingEmissionStandard,
    RoutingEmissionStandardFromJSON,
    RoutingEmissionStandardFromJSONTyped,
    RoutingEmissionStandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface InputVehicleProfile
 */
export interface InputVehicleProfile {
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    emptyWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    fullWeight?: number;
    /**
     * 
     * @type {RoutingEmissionStandard}
     * @memberof InputVehicleProfile
     */
    euroClass?: RoutingEmissionStandard;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof InputVehicleProfile
     */
    tunnel?: string;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    trailersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof InputVehicleProfile
     */
    numberOfAxles?: number;
}

export function InputVehicleProfileFromJSON(json: any): InputVehicleProfile {
    return InputVehicleProfileFromJSONTyped(json, false);
}

export function InputVehicleProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputVehicleProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'emptyWeight': !exists(json, 'emptyWeight') ? undefined : json['emptyWeight'],
        'fullWeight': !exists(json, 'fullWeight') ? undefined : json['fullWeight'],
        'euroClass': !exists(json, 'euroClass') ? undefined : RoutingEmissionStandardFromJSON(json['euroClass']),
        'length': !exists(json, 'length') ? undefined : json['length'],
        'tunnel': !exists(json, 'tunnel') ? undefined : json['tunnel'],
        'trailersCount': !exists(json, 'trailersCount') ? undefined : json['trailersCount'],
        'numberOfAxles': !exists(json, 'numberOfAxles') ? undefined : json['numberOfAxles'],
    };
}

export function InputVehicleProfileToJSON(value?: InputVehicleProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'emptyWeight': value.emptyWeight,
        'fullWeight': value.fullWeight,
        'euroClass': RoutingEmissionStandardToJSON(value.euroClass),
        'length': value.length,
        'tunnel': value.tunnel,
        'trailersCount': value.trailersCount,
        'numberOfAxles': value.numberOfAxles,
    };
}


