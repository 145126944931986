import React, { Component } from 'react';
import { AlarmInDatabaseWithGPSInfo } from 'generated/backend-api';

import AlarmsUI from './ui/Alarms';
import { Logic } from 'logic/logic';
import AlarmsTable from './ui/AlarmsTable';
import { search } from 'common/utils/search';
import { debounce } from 'debounce';
import { Alarm } from 'common/model/alarm';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

interface Props {
    logic: Logic;
    open: boolean;
    renderTable?: boolean;
    onClose: () => void;
}

interface State {
    alarms: Alarm[];
    fleet: ReadOnlyMonitoredObjectFeSb[];
    table?: {
        data?: AlarmInDatabaseWithGPSInfo[];
        search?: {
            text: string;
        };
    };
}

class AlarmsModule extends Component<Props, State> {
    logic: Logic;

    constructor(props: Props) {
        super(props);
        this.logic = this.props.logic;
        this.state = {
            fleet: [],
            alarms: []
        };
    }

    private _setAlarms = (fleet: ReadOnlyMonitoredObjectFeSb[]) =>
        this.setState({
            fleet,
            alarms: this.props.logic
                .alarms()
                .getInstantAndGeolocationAlarmsNotAcknowledged()
                .map(a => ({
                    ...a,
                    registrationNumber:
                        fleet.find(f => String(f.id) === String(a.monitoredObjectId))?.registrationNumber ??
                        a.monitoredObjectId
                })),

            table: {
                data: this.props.renderTable
                    ? this.props.logic
                          .alarms()
                          .getAllAlarms()
                          .map(a => ({
                              ...a,
                              registrationNumber:
                                  fleet.find(f => String(f.id) === String(a.monitoredObjectId))?.registrationNumber ??
                                  a.monitoredObjectId
                          }))
                    : []
            }
        });

    componentDidMount() {
        if (this.props.logic.demo().isActive) {
            this.props.logic
                .vehicles()
                .getMonitoredObjectFilters(true, true)
                .then(fleet => {
                    this.props.logic.alarms().init();
                    this._setAlarms(fleet);
                });
        } else {
            this.props.logic
                .vehicles()
                .getMonitoredObjectFilters(true, true, undefined, false, false)
                .then(fleet => {
                    this.props.logic.alarms().init();
                    this.props.logic.alarms().alarmsUpdates.subscribe(() => this._setAlarms(fleet));
                });
        }
    }

    render() {
        return this.props.renderTable ? (
            <AlarmsTable data={this.state.table?.data} onSearchChange={this._onSearchInputChange} />
        ) : (
            <AlarmsUI
                open={this.props.open}
                demoMode={this.props.logic.demo().isActive}
                alarms={this.state.alarms}
                onMarkAsSeen={this._onMarkAsSeen}
            />
        );
    }

    private _onMarkAsSeen = (id: string) => {
        this.setState(state => ({
            alarms: state.alarms.map(a => (a.alarmId === id ? { ...a, acknowledged: true } : a))
        }));

        this.logic.alarms().markAlarmsAsSeen([id]);
    };

    private _onSearchInputChange = (text: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                search: {
                    text
                }
            }
        }));
        this._onSearchInputChangeDebounce(text);
    };

    private _onSearchInputChangeDebounce = debounce((text: string): void => {
        const filteredVehicles = search(text, ['monitoredObjectId', 'registrationNumber'], this.state.fleet).map(
            v => v.registrationNumber
        );
        this.setState(state => ({
            table: {
                ...state.table,
                data: this.props.logic
                    .alarms()
                    .getAllAlarms()
                    .map(a => ({
                        ...a,
                        monitoredObjectId:
                            this.state.fleet.find(f => String(f.id) === String(a.monitoredObjectId))
                                ?.registrationNumber ?? a.monitoredObjectId
                    }))
                    .filter(d => filteredVehicles.includes(d.monitoredObjectId))
            }
        }));
    }, 500);
}

export default AlarmsModule;
