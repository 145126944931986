/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BundlesAdminPermissions
 */
export interface BundlesAdminPermissions {
    /**
     * In format `TLM{N}:{permissions}; TLM{M}: {permissions}`, as seen at https://confluence.eurowag.com/pages/viewpage.action?pageId=25657907
     * @type {string}
     * @memberof BundlesAdminPermissions
     */
    permissions: string;
    /**
     * 
     * @type {string}
     * @memberof BundlesAdminPermissions
     */
    accountNumber: string;
}

export function BundlesAdminPermissionsFromJSON(json: any): BundlesAdminPermissions {
    return BundlesAdminPermissionsFromJSONTyped(json, false);
}

export function BundlesAdminPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BundlesAdminPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
        'accountNumber': json['accountNumber'],
    };
}

export function BundlesAdminPermissionsToJSON(value?: BundlesAdminPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
        'accountNumber': value.accountNumber,
    };
}


