/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalInformation,
    AdditionalInformationFromJSON,
    AdditionalInformationFromJSONTyped,
    AdditionalInformationToJSON,
    Goods,
    GoodsFromJSON,
    GoodsFromJSONTyped,
    GoodsToJSON,
    Measurement,
    MeasurementFromJSON,
    MeasurementFromJSONTyped,
    MeasurementToJSON,
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    TaskMediaPayload,
    TaskMediaPayloadFromJSON,
    TaskMediaPayloadFromJSONTyped,
    TaskMediaPayloadToJSON,
    TransportPlaceTaskType,
    TransportPlaceTaskTypeFromJSON,
    TransportPlaceTaskTypeFromJSONTyped,
    TransportPlaceTaskTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportPlaceTask
 */
export interface TransportPlaceTask {
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    id?: string;
    /**
     * 
     * @type {TransportPlaceTaskType}
     * @memberof TransportPlaceTask
     */
    type?: TransportPlaceTaskType;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    action?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransportPlaceTask
     */
    isResolved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    activityStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    activityEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    resultUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    driverNote?: string;
    /**
     * 
     * @type {Array<TaskMediaPayload>}
     * @memberof TransportPlaceTask
     */
    payload?: Array<TaskMediaPayload>;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    contactId?: string;
    /**
     * 
     * @type {Array<Goods>}
     * @memberof TransportPlaceTask
     */
    loadingGoods?: Array<Goods>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransportPlaceTask
     */
    unloadingGoods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransportPlaceTask
     */
    puescComments?: string;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof TransportPlaceTask
     */
    additionalInformation?: AdditionalInformation;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlaceTask
     */
    plannedStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlaceTask
     */
    plannedEnd?: Date;
    /**
     * 
     * @type {Price}
     * @memberof TransportPlaceTask
     */
    shipmentPrice?: Price;
    /**
     * 
     * @type {Array<Measurement>}
     * @memberof TransportPlaceTask
     */
    measurements?: Array<Measurement>;
}

export function TransportPlaceTaskFromJSON(json: any): TransportPlaceTask {
    return TransportPlaceTaskFromJSONTyped(json, false);
}

export function TransportPlaceTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportPlaceTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : TransportPlaceTaskTypeFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'isResolved': !exists(json, 'isResolved') ? undefined : json['isResolved'],
        'activityStartTime': !exists(json, 'activityStartTime') ? undefined : json['activityStartTime'],
        'activityEndTime': !exists(json, 'activityEndTime') ? undefined : json['activityEndTime'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'resultUnit': !exists(json, 'resultUnit') ? undefined : json['resultUnit'],
        'driverNote': !exists(json, 'driverNote') ? undefined : json['driverNote'],
        'payload': !exists(json, 'payload') ? undefined : ((json['payload'] as Array<any>).map(TaskMediaPayloadFromJSON)),
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'loadingGoods': !exists(json, 'loading_goods') ? undefined : ((json['loading_goods'] as Array<any>).map(GoodsFromJSON)),
        'unloadingGoods': !exists(json, 'unloading_goods') ? undefined : json['unloading_goods'],
        'puescComments': !exists(json, 'puesc_comments') ? undefined : json['puesc_comments'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : AdditionalInformationFromJSON(json['additionalInformation']),
        'plannedStart': !exists(json, 'planned_start') ? undefined : (new Date(json['planned_start'])),
        'plannedEnd': !exists(json, 'planned_end') ? undefined : (new Date(json['planned_end'])),
        'shipmentPrice': !exists(json, 'shipment_price') ? undefined : PriceFromJSON(json['shipment_price']),
        'measurements': !exists(json, 'measurements') ? undefined : ((json['measurements'] as Array<any>).map(MeasurementFromJSON)),
    };
}

export function TransportPlaceTaskToJSON(value?: TransportPlaceTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': TransportPlaceTaskTypeToJSON(value.type),
        'description': value.description,
        'action': value.action,
        'isResolved': value.isResolved,
        'activityStartTime': value.activityStartTime,
        'activityEndTime': value.activityEndTime,
        'result': value.result,
        'resultUnit': value.resultUnit,
        'driverNote': value.driverNote,
        'payload': value.payload === undefined ? undefined : ((value.payload as Array<any>).map(TaskMediaPayloadToJSON)),
        'contact_id': value.contactId,
        'loading_goods': value.loadingGoods === undefined ? undefined : ((value.loadingGoods as Array<any>).map(GoodsToJSON)),
        'unloading_goods': value.unloadingGoods,
        'puesc_comments': value.puescComments,
        'additionalInformation': AdditionalInformationToJSON(value.additionalInformation),
        'planned_start': value.plannedStart === undefined ? undefined : (value.plannedStart.toISOString()),
        'planned_end': value.plannedEnd === undefined ? undefined : (value.plannedEnd.toISOString()),
        'shipment_price': PriceToJSON(value.shipmentPrice),
        'measurements': value.measurements === undefined ? undefined : ((value.measurements as Array<any>).map(MeasurementToJSON)),
    };
}


