import React, { Component, ChangeEvent, FormEvent } from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OperationalCostModel, CostUnit } from 'logic/statistics/statistics-company-profile';
import { NumberValidator, FormValidator } from 'validators';
import moment from 'moment';
import { DATE_FORMAT } from 'domain-constants';
import { Button } from 'common/components';

interface OperationalCostsFormValidator {
    fuel: string;
    lngCng: string;
    electro: string;
    additives: string;
    oil: string;
    tires: string;
    maintenance: string;
    liabilityInsurance: string;
    collisionInsurance: string;
    personalInjuryInsurance: string;
    parking: string;
    cabinHeating: string;
    telecomunicationsAndMonitoring: string;
    washing: string;
}

const defaultValidationErrors = {
    fuelErr: '',
    lngCngErr: '',
    electroErr: '',
    additivesErr: '',
    oilErr: '',
    tiresErr: '',
    maintenanceErr: '',
    liabilityInsuranceErr: '',
    collisionInsuranceErr: '',
    personalInjuryInsuranceErr: '',
    parkingErr: '',
    cabinHeatingErr: '',
    telecomunicationsAndMonitoringErr: '',
    washingErr: ''
};

const costValidator = new NumberValidator({
    required: true,
    min: 0
});

const formValidator = new FormValidator<OperationalCostsFormValidator>()
    .addValidator('fuel', costValidator)
    .addValidator('lngCng', costValidator)
    .addValidator('electro', costValidator)
    .addValidator('additives', costValidator)
    .addValidator('oil', costValidator)
    .addValidator('tires', costValidator)
    .addValidator('maintenance', costValidator)
    .addValidator('liabilityInsurance', costValidator)
    .addValidator('collisionInsurance', costValidator)
    .addValidator('personalInjuryInsurance', costValidator)
    .addValidator('parking', costValidator)
    .addValidator('cabinHeating', costValidator)
    .addValidator('telecomunicationsAndMonitoring', costValidator)
    .addValidator('washing', costValidator);

interface Props extends WithTranslation {
    vehicles: string[];
    data: OperationalCostModel;
    onSubmit?: (model: OperationalCostModel, vehicles: string[]) => void;
    onClose?: () => void;
}

interface State {
    fuel: string;
    fuelErr: string;
    lngCng: string;
    lngCngErr: string;
    electro: string;
    electroErr: string;
    additives: string;
    additivesErr: string;
    oil: string;
    oilErr: string;
    tires: string;
    tiresErr: string;
    maintenance: string;
    maintenanceErr: string;
    liabilityInsurance: string;
    liabilityInsuranceErr: string;
    collisionInsurance: string;
    collisionInsuranceErr: string;
    personalInjuryInsurance: string;
    personalInjuryInsuranceErr: string;
    parking: string;
    parkingErr: string;
    cabinHeating: string;
    cabinHeatingErr: string;
    telecomunicationsAndMonitoring: string;
    telecomunicationsAndMonitoringErr: string;
    washing: string;
    washingErr: string;
    dateFrom: string;
    costUnit: CostUnit;
}

class CompanyProfileOperationalCosts extends Component<Props, State> {
    operationalCostModel?: OperationalCostModel;

    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultValidationErrors,
            fuel: String(props.data.costs?.fuel.value),
            lngCng: String(props.data.costs?.lngCng.value),
            electro: String(props.data.costs?.electro.value),
            additives: String(props.data.costs?.additives.value),
            oil: String(props.data.costs?.oil.value),
            tires: String(props.data.costs?.tires.value),
            maintenance: String(props.data.costs?.maintenance.value),
            liabilityInsurance: String(props.data.costs?.liabilityInsurance.value),
            collisionInsurance: String(props.data.costs?.collisionInsurance.value),
            personalInjuryInsurance: String(props.data.costs?.personalInjuryInsurance.value),
            parking: String(props.data.costs?.parking.value),
            cabinHeating: String(props.data.costs?.cabinHeating.value),
            telecomunicationsAndMonitoring: String(props.data.costs?.telecomunicationsAndMonitoring.value),
            washing: String(props.data.costs?.washing.value),
            dateFrom: props.data.dateFrom,
            costUnit: props.data.costs?.fuel.unit ?? CostUnit.PerHalfYear
        };

        this.operationalCostModel = {
            dateFrom: moment().format(DATE_FORMAT),
            costs: {
                // ewTelematics: {
                //     ...this.props.data.costs?.ewTelematics
                // },
                // ewToll: {
                //     ...this.props.data.costs?.ewToll
                // },
                fuel: {
                    ...this.props.data.costs?.fuel!
                },
                lngCng: {
                    ...this.props.data.costs?.lngCng!
                },
                electro: {
                    ...this.props.data.costs?.electro!
                },
                additives: {
                    ...this.props.data.costs?.additives!
                },
                oil: {
                    ...this.props.data.costs?.oil!
                },
                tires: {
                    ...this.props.data.costs?.tires!
                },
                maintenance: {
                    ...this.props.data.costs?.maintenance!
                },
                liabilityInsurance: {
                    ...this.props.data.costs?.liabilityInsurance!
                },
                collisionInsurance: {
                    ...this.props.data.costs?.collisionInsurance!
                },
                personalInjuryInsurance: {
                    ...this.props.data.costs?.personalInjuryInsurance!
                },
                parking: {
                    ...this.props.data.costs?.parking!
                },
                cabinHeating: {
                    ...this.props.data.costs?.cabinHeating!
                },
                telecomunicationsAndMonitoring: {
                    ...this.props.data.costs?.telecomunicationsAndMonitoring!
                },
                washing: {
                    ...this.props.data.costs?.washing!
                }
            }
        };
    }

    render() {
        const { t, data } = this.props;

        return (
            <div className="company-profile-operational-costs t-modal">
                <form
                    className="t-modal-content t-border t-card t-padding-small t-default t-text-inverse"
                    onSubmit={this._onSubmit}
                >
                    <div className="t-bar t-large t-center t-padding-16">
                        <span className="t-uppercase">{t('CompanyProfileOperationalCosts.operationalCosts')}</span>
                    </div>
                    <div className="costs">
                        <div className="t-row">
                            <select className="t-select" value={this.state.costUnit} onChange={this._onCostUnitChange}>
                                {Object.keys(CostUnit).map((u, i) => {
                                    return (
                                        <option key={i} value={CostUnit[u]}>
                                            {t(`CompanyProfile.${String(CostUnit[u])}`)}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="t-row">
                            <div className="t-bar costs-header">
                                <div className="costs-header-label t-bar-item">{t('CompanyProfile.items')}</div>
                                <div className="costs-header-value t-bar-item">{t('common.cost')}</div>
                                <div className="costs-header-currency t-bar-item">{t('common.currency')}</div>
                                <div className="costs-header-date t-bar-item">{t('CompanyProfile.lastUpdate')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.priceFuel')}
                                >
                                    {t('CompanyProfileOperationalCosts.priceFuel')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.fuelErr
                                        })}
                                        onChange={this._onFuelChange}
                                        value={this.state.fuel}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.fuelErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.fuel.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.lngCng')}
                                >
                                    {t('CompanyProfileOperationalCosts.lngCng')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.lngCngErr
                                        })}
                                        onChange={this._onLngCngChange}
                                        value={this.state.lngCng}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.lngCngErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.lngCng.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.electro')}
                                >
                                    {t('CompanyProfileOperationalCosts.electro')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.electroErr
                                        })}
                                        onChange={this._onElectroChange}
                                        value={this.state.electro}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.electroErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.electro.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.priceAdditives')}
                                >
                                    {t('CompanyProfileOperationalCosts.priceAdditives')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.additivesErr
                                        })}
                                        onChange={this._onAdditivesChange}
                                        value={this.state.additives}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.additivesErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.additives.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.priceOil')}
                                >
                                    {t('CompanyProfileOperationalCosts.priceOil')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.oilErr
                                        })}
                                        onChange={this._onOilChange}
                                        value={this.state.oil}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.oilErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.oil.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.priceTires')}
                                >
                                    {t('CompanyProfileOperationalCosts.priceTires')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.tiresErr
                                        })}
                                        onChange={this._onTiresChange}
                                        value={this.state.tires}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.tiresErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.tires.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.maintenance')}
                                >
                                    {t('CompanyProfileOperationalCosts.maintenance')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.maintenanceErr
                                        })}
                                        onChange={this._onMaintenanceChange}
                                        value={this.state.maintenance}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.maintenanceErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.maintenance.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.liabilityInsurance')}
                                >
                                    {t('CompanyProfileOperationalCosts.liabilityInsurance')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.liabilityInsuranceErr
                                        })}
                                        onChange={this._onLiabilityInsuranceChange}
                                        value={this.state.liabilityInsurance}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.liabilityInsuranceErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">
                                    {data.costs?.liabilityInsurance.currency}
                                </div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.collisionInsurance')}
                                >
                                    {t('CompanyProfileOperationalCosts.collisionInsurance')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.collisionInsuranceErr
                                        })}
                                        onChange={this._onCollisionInsuranceChange}
                                        value={this.state.collisionInsurance}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.collisionInsuranceErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">
                                    {data.costs?.collisionInsurance.currency}
                                </div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.personalInjuryInsurance')}
                                >
                                    {t('CompanyProfileOperationalCosts.personalInjuryInsurance')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.personalInjuryInsuranceErr
                                        })}
                                        onChange={this._onPersonalInjuryInsuranceChange}
                                        value={this.state.personalInjuryInsurance}
                                    />
                                    <span className="t-bold t-text-danger">
                                        {this.state.personalInjuryInsuranceErr}
                                    </span>
                                </div>
                                <div className="costs-currency t-bar-item ">
                                    {data.costs?.personalInjuryInsurance.currency}
                                </div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.parking')}
                                >
                                    {t('CompanyProfileOperationalCosts.parking')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.parkingErr
                                        })}
                                        onChange={this._onParkingChange}
                                        value={this.state.parking}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.parkingErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.parking.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.cabinHeating')}
                                >
                                    {t('CompanyProfileOperationalCosts.cabinHeating')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.cabinHeatingErr
                                        })}
                                        onChange={this._onCabinHeatingChange}
                                        value={this.state.cabinHeating}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.cabinHeatingErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.cabinHeating.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.telecomunicationsAndMonitoring')}
                                >
                                    {t('CompanyProfileOperationalCosts.telecomunicationsAndMonitoring')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.telecomunicationsAndMonitoringErr
                                        })}
                                        onChange={this._onTelecomunicationsAndMonitoringChange}
                                        value={this.state.telecomunicationsAndMonitoring}
                                    />
                                    <span className="t-bold t-text-danger">
                                        {this.state.telecomunicationsAndMonitoringErr}
                                    </span>
                                </div>
                                <div className="costs-currency t-bar-item ">
                                    {data.costs?.telecomunicationsAndMonitoring.currency}
                                </div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                        <div className="t-row">
                            <div className="t-bar">
                                <div
                                    className="costs-label t-bar-item t-padding"
                                    title={t('CompanyProfileOperationalCosts.washing')}
                                >
                                    {t('CompanyProfileOperationalCosts.washing')}
                                </div>
                                <div className="costs-value t-bar-item input">
                                    <input
                                        className={cn('t-input t-padding', {
                                            error: this.state?.washingErr
                                        })}
                                        onChange={this._onWashingChange}
                                        value={this.state.washing}
                                    />
                                    <span className="t-bold t-text-danger">{this.state.washingErr}</span>
                                </div>
                                <div className="costs-currency t-bar-item ">{data.costs?.washing.currency}</div>
                                <div className="costs-date t-bar-item ">{moment().format('L')}</div>
                            </div>
                        </div>
                    </div>

                    <footer className="t-row t-margin">
                        <div className="t-half t-padding-tiny">
                            <Button className="t-right t-btn t-primary-secondary t-ripple" onClick={this._onClose}>
                                {t('common.cancel')}
                            </Button>
                        </div>
                        <div className="t-half t-padding-tiny">
                            <Button className="t-left t-primary t-btn t-ripple" htmlType="submit">
                                {t('common.submit')}
                            </Button>
                        </div>
                    </footer>
                </form>
            </div>
        );
    }

    private _onFuelChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                fuel: value,
                fuelErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.fuel.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onLngCngChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                lngCng: value,
                lngCngErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.lngCng.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onElectroChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                electro: value,
                electroErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.electro.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onAdditivesChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                additives: value,
                additivesErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.additives.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onOilChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                oil: value,
                oilErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.oil.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onTiresChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                tires: value,
                tiresErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.tires.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onMaintenanceChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                maintenance: value,
                maintenanceErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.maintenance.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onLiabilityInsuranceChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                liabilityInsurance: value,
                liabilityInsuranceErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.liabilityInsurance.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onCollisionInsuranceChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                collisionInsurance: value,
                collisionInsuranceErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.collisionInsurance.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onPersonalInjuryInsuranceChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                personalInjuryInsurance: value,
                personalInjuryInsuranceErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.personalInjuryInsurance.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onParkingChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                parking: value,
                parkingErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.parking.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onCabinHeatingChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                cabinHeating: value,
                cabinHeatingErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.cabinHeating.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onTelecomunicationsAndMonitoringChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                telecomunicationsAndMonitoring: value,
                telecomunicationsAndMonitoringErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.telecomunicationsAndMonitoring.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onWashingChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = costValidator.validate(value);
        this.setState(
            {
                washing: value,
                washingErr: validateRes.err as string
            },
            () => {
                if (!validateRes.err) {
                    this.operationalCostModel!.costs!.washing.value = validateRes.obj as number;
                }
            }
        );
    };

    private _onCostUnitChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const value = e.target.value;
        this.setState({ costUnit: value as CostUnit });

        this.operationalCostModel!.costs!.fuel.unit = value as CostUnit;
        this.operationalCostModel!.costs!.lngCng.unit = value as CostUnit;
        this.operationalCostModel!.costs!.electro.unit = value as CostUnit;
        this.operationalCostModel!.costs!.additives.unit = value as CostUnit;
        this.operationalCostModel!.costs!.oil.unit = value as CostUnit;
        this.operationalCostModel!.costs!.tires.unit = value as CostUnit;
        this.operationalCostModel!.costs!.maintenance.unit = value as CostUnit;
        this.operationalCostModel!.costs!.liabilityInsurance.unit = value as CostUnit;
        this.operationalCostModel!.costs!.collisionInsurance.unit = value as CostUnit;
        this.operationalCostModel!.costs!.personalInjuryInsurance.unit = value as CostUnit;
        this.operationalCostModel!.costs!.parking.unit = value as CostUnit;
        this.operationalCostModel!.costs!.cabinHeating.unit = value as CostUnit;
        this.operationalCostModel!.costs!.telecomunicationsAndMonitoring.unit = value as CostUnit;
        this.operationalCostModel!.costs!.washing.unit = value as CostUnit;
    };

    private _onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const validationRes = formValidator.validate({
            fuel: this.state.fuel,
            lngCng: this.state.lngCng,
            electro: this.state.electro,
            additives: this.state.additives,
            oil: this.state.oil,
            tires: this.state.tires,
            maintenance: this.state.maintenance,
            liabilityInsurance: this.state.liabilityInsurance,
            collisionInsurance: this.state.collisionInsurance,
            personalInjuryInsurance: this.state.personalInjuryInsurance,
            parking: this.state.parking,
            cabinHeating: this.state.cabinHeating,
            telecomunicationsAndMonitoring: this.state.telecomunicationsAndMonitoring,
            washing: this.state.washing
        });

        if (validationRes.valid) {
            this.props.onSubmit?.(this.operationalCostModel!, this.props.vehicles);
        } else {
            this.setState({
                fuelErr: validationRes.err?.fuel ?? '',
                lngCngErr: validationRes.err?.lngCng ?? '',
                electroErr: validationRes.err?.electro ?? '',
                additivesErr: validationRes.err?.additives ?? '',
                oilErr: validationRes.err?.oil ?? '',
                tiresErr: validationRes.err?.tires ?? '',
                maintenanceErr: validationRes.err?.maintenance ?? '',
                liabilityInsuranceErr: validationRes.err?.liabilityInsurance ?? '',
                collisionInsuranceErr: validationRes.err?.collisionInsurance ?? '',
                personalInjuryInsuranceErr: validationRes.err?.personalInjuryInsurance ?? '',
                parkingErr: validationRes.err?.parking ?? '',
                cabinHeatingErr: validationRes.err?.cabinHeating ?? '',
                telecomunicationsAndMonitoringErr: validationRes.err?.telecomunicationsAndMonitoring ?? '',
                washingErr: validationRes.err?.washing ?? ''
            });
        }
    };

    private _onClose = () => {
        this.props.onClose?.();
    };
}

export default withTranslation()(CompanyProfileOperationalCosts);
