import React from 'react';

import { TrackingTableHead } from '../TrackingModule';

interface Props {
    expanded?: boolean;
    headData?: TrackingTableHead;
    onApply?: (data?: TrackingTableHead) => void;
    onCancel?: () => void;
    onClickRadioPrimary?: (value: string) => void;
    onCheckboxDisplay?: (value: string) => void;
}

export function TrackingSettings(props: Props) {
    // const radioVehicleIdentification = [
    //     { label: 'Actual name', value: 'actual name' },
    //     { label: 'RN', value: 'rn' }
    // ];

    function onClickButtonApply() {
        props.onApply?.(props.headData);
    }

    function onClickButtonCancel() {
        props.onCancel?.();
    }

    // function onClickRadioPrimary(e: ChangeEvent<HTMLInputElement>) {
    //     const value = e.target.value;
    //     props.onClickRadioPrimary && props.onClickRadioPrimary(value);
    // }

    // function onClickCheckboxDisplay(e: ChangeEvent<HTMLInputElement>) {
    //     const value = e.target.value;
    //     const checkbox = e.target.checked;
    //     props.onCheckboxDisplay &&
    //         props.onCheckboxDisplay(
    //             'value: ' + value + ', checkbox: ' + checkbox
    //         );
    // }

    return (
        <div className={`tracking-settings t-modal t-small t-right t-third ${props.expanded ? '' : 't-third-right'}`}>
            <div className="t-row t-padding t-inverse t-center t-medium t-uppercase">
                Displayed list of tracked vehicles
            </div>
            <div className="t-row t-inverse">
                <table className="t-half t-inverse t-padding">
                    <thead>
                        <tr>
                            <th className="t-bar t-inverse t-border-bottom t-uppercase" colSpan={2}>
                                Broken down by column
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.headData?.map((row, i) => (
                            <tr key={i}>
                                <td>{row.label}</td>
                                <td>
                                    <input
                                        className="t-check"
                                        type="checkbox"
                                        name="byColumn"
                                        value={row.value}
                                        checked={row.checked}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* TODO: Uncomment when needed */}
                {/* <table className="t-half t-inverse t-padding">
                    <thead>
                        <tr>
                            <th
                                className="t-bar t-inverse t-border-bottom t-uppercase"
                                colSpan={3}
                            >
                                Vehicle identification
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className="t-center">
                                Display
                            </td>
                            <td
                                className="t-center"
                            >
                                Primary
                            </td>
                        </tr>

                        {radioVehicleIdentification.map((row, i) => (
                            <tr key={i}>
                                <td>{row.label}</td>
                                <td className="t-center">
                                    <input
                                        className="t-check"
                                        type="checkbox"
                                        value={row.value}
                                        onChange={
                                            onClickCheckboxDisplay.bind(undefined)
                                        }
                                    />
                                </td>
                                <td className="t-center">
                                    <input
                                        className="t-radio"
                                        type="radio"
                                        name="primary"
                                        onChange={onClickRadioPrimary.bind(undefined)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}
            </div>
            <div className="t-row t-center t-padding t-dark-secondary">
                <button className="t-btn t-ripple t-primary" onClick={onClickButtonApply}>
                    Apply
                </button>
                <button className="t-button t-ripple" onClick={onClickButtonCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
}
