/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyOperationalCostProperties,
    CompanyOperationalCostPropertiesFromJSON,
    CompanyOperationalCostPropertiesFromJSONTyped,
    CompanyOperationalCostPropertiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyOperationalCost
 */
export interface CompanyOperationalCost {
    /**
     * 
     * @type {number}
     * @memberof CompanyOperationalCost
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyOperationalCost
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyOperationalCost
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyOperationalCost
     */
    dateTo: Date;
    /**
     * 
     * @type {CompanyOperationalCostProperties}
     * @memberof CompanyOperationalCost
     */
    costs: CompanyOperationalCostProperties;
}

export function CompanyOperationalCostFromJSON(json: any): CompanyOperationalCost {
    return CompanyOperationalCostFromJSONTyped(json, false);
}

export function CompanyOperationalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOperationalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clientId': json['client_id'],
        'dateFrom': (new Date(json['date_from'])),
        'dateTo': (new Date(json['date_to'])),
        'costs': CompanyOperationalCostPropertiesFromJSON(json['costs']),
    };
}

export function CompanyOperationalCostToJSON(value?: CompanyOperationalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'date_from': (value.dateFrom.toISOString()),
        'date_to': (value.dateTo.toISOString()),
        'costs': CompanyOperationalCostPropertiesToJSON(value.costs),
    };
}


