import React from 'react';
import { Col, Row } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DocsItem, DocsUserGuide } from '../DocsModule';
import DocsContent from './DocsContent';
import DocsMenu from './DocsMenu';

interface Props extends WithTranslation {
    menuDocs: DocsItem[];
    contentModule: DocsUserGuide;
    contentContent: string;
    onCloseClick?: () => void;
    onMenuUserGuideClick?: (module: DocsUserGuide) => void;
}

function Docs(props: Props) {
    return (
        <div className="module docs">
            <div className="page">
                <Row gutter={20}>
                    <Col span={4}>
                        <DocsMenu
                            docs={props.menuDocs}
                            module={props.contentModule}
                            onUserGuideClick={props.onMenuUserGuideClick}
                        />
                    </Col>
                    <Col span={20}>
                        <DocsContent content={props.contentContent} />
                    </Col>
                </Row>
                <img
                    alt="close"
                    title={props.t('common.close')}
                    className="docs-close"
                    src="/img-svg/icn-close.svg"
                    onClick={props.onCloseClick}
                />
            </div>
        </div>
    );
}

export default withTranslation()(Docs);
