import { Logic } from '../logic';
import {
    CustomPlaceAddInput,
    PoiPolygonType,
    Add_CustomPlaceMutation,
    Add_CustomPlaceMutationVariables,
    Add_CustomPlaceDocument,
    PoiType,
    Delete_CustomPlaceMutation,
    Delete_CustomPlaceMutationVariables,
    Delete_CustomPlaceDocument
} from 'generated/graphql';
import { CustomPlace } from 'generated/backend-api';
import { Geocoding } from '../geocoding';
import { AddressWithCountry, LatLng } from 'common/model/geo';
import {
    latLngFromGeoJsonPointType,
    latLngFromGeoJsonPolygonType,
    toGeoJsonPointTypeInput,
    toGeoJsonPolygonTypeInput
} from '../../common/utils/geo-utils';
import { Subject } from 'rxjs';

import { PoiModel } from 'common/model/poi';

const toPoiModel = (poi: CustomPlace): PoiModel => {
    const { id, type, center, name, address, polygon, notes, forbidden } = poi;
    return {
        type,
        id: id ?? '',
        name: name ?? '',
        forbidden: forbidden ?? false,
        address: address ?? '',
        notes: notes ?? '',
        center: latLngFromGeoJsonPointType(center ?? {}),
        polygon: latLngFromGeoJsonPolygonType(polygon ?? {})?.[0]
    };
};

export class DispatcherKitPoiLogic {
    private _data: PoiModel[];
    private _geocoding: Geocoding;

    data = new Subject<PoiModel[]>();
    loading = new Subject<boolean>();
    error = new Subject<any>();

    // poi is created from map. so on other pages we can create poi from map,
    // need some callback eg. poi settings refresh list of poi.
    onPoiCreated = new Subject<void>();

    constructor(geocoding: Geocoding, private logic: Logic) {
        this._geocoding = geocoding;
        this._data = [];
    }

    async loadData() {
        this.loading.next(true);
        try {
            this._data = await this._getData();
            this.data.next(this._data);
        } catch (err) {
            this.error.next(err);
        } finally {
            this.loading.next(false);
        }
    }

    async createPoi(poi?: PoiModel): Promise<void> {
        await this._createPoi(this._toCreatePoiInput(poi));
        this.onPoiCreated.next();
    }

    async deletePoi(id: string): Promise<void> {
        await this._deletePoi(id);
    }

    geocodeLatLng(location: LatLng): Promise<AddressWithCountry> {
        return this._geocoding.geocodeLatLng(location);
    }

    private _toCreatePoiInput(poi?: PoiModel): CustomPlaceAddInput {
        return {
            name: poi?.name ?? '',
            polygon: toGeoJsonPolygonTypeInput([poi?.polygon ?? []]),
            center: toGeoJsonPointTypeInput(poi?.center ?? { lat: -1, lng: -1 }),
            polygonType: PoiPolygonType.Custom,
            notes: poi?.notes ?? '',
            forbidden: poi?.forbidden ?? false,
            deleted: 0,
            address: poi?.address ?? '',
            type: poi?.type ?? PoiType.Firm,
            countryCode: poi?.countryCode ?? ''
        };
    }

    private async _createPoi(placeData: CustomPlaceAddInput): Promise<void> {
        await this.logic.apollo().mutate<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>({
            mutation: Add_CustomPlaceDocument,
            variables: { placeData }
        });
    }

    private async _deletePoi(id: string) {
        await this.logic.apollo().mutate<Delete_CustomPlaceMutation, Delete_CustomPlaceMutationVariables>({
            mutation: Delete_CustomPlaceDocument,
            variables: { id }
        });
    }

    private async _getData() {
        if (this.logic.demo().isActive) {
            return this.logic
                .demo()
                .data.places.map(p => toPoiModel(p))
                .sort((a, b) => (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }

        const customPlaces = await this.logic.poi().getCustomPlaces();
        return (
            customPlaces
                ?.map(toPoiModel)
                .sort((a, b) => (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) ?? []
        );
    }
}
