import { SelectOption } from 'common/components/Form/Select';
import { PoiType } from 'generated/graphql';
import i18n from 'i18next';
import { LatLng } from './geo';

export interface PoiModel {
    id?: string;
    name?: string;
    type?: PoiType;
    forbidden?: boolean;
    address?: string;
    notes?: string;
    center?: LatLng;
    polygon?: LatLng[];
    countryCode?: string;
}

export function poiTypes(): SelectOption[] {
    return Object.values(PoiType).map(value => {
        return {
            label: i18n.t(`PoiForm.${value}`),
            value: value
        };
    });
}
