/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MatchFitness,
    MatchFitnessFromJSON,
    MatchFitnessFromJSONTyped,
    MatchFitnessToJSON,
    NegotiationState,
    NegotiationStateFromJSON,
    NegotiationStateFromJSONTyped,
    NegotiationStateToJSON,
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    Segment,
    SegmentFromJSON,
    SegmentFromJSONTyped,
    SegmentToJSON,
    Shipment,
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
    Vehicle,
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProposalForClient
 */
export interface ProposalForClient {
    /**
     * 
     * @type {string}
     * @memberof ProposalForClient
     */
    proposalId: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalForClient
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalForClient
     */
    shipperCompanyId: string;
    /**
     * 
     * @type {Vehicle}
     * @memberof ProposalForClient
     */
    vehicle: Vehicle;
    /**
     * 
     * @type {MatchFitness}
     * @memberof ProposalForClient
     */
    matchFitness: MatchFitness;
    /**
     * 
     * @type {NegotiationState}
     * @memberof ProposalForClient
     */
    state: NegotiationState;
    /**
     * 
     * @type {Date}
     * @memberof ProposalForClient
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProposalForClient
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Shipment}
     * @memberof ProposalForClient
     */
    shipment: Shipment;
    /**
     * 
     * @type {Price}
     * @memberof ProposalForClient
     */
    negotiatedPrice?: Price;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof ProposalForClient
     */
    delivery?: Array<Segment>;
    /**
     * 
     * @type {Segment}
     * @memberof ProposalForClient
     */
    pickup?: Segment;
    /**
     * 
     * @type {number}
     * @memberof ProposalForClient
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalForClient
     */
    scoreStars?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProposalForClient
     */
    useJitpay: boolean;
}

export function ProposalForClientFromJSON(json: any): ProposalForClient {
    return ProposalForClientFromJSONTyped(json, false);
}

export function ProposalForClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalForClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': json['proposalId'],
        'shipmentId': json['shipmentId'],
        'shipperCompanyId': json['shipperCompanyId'],
        'vehicle': VehicleFromJSON(json['vehicle']),
        'matchFitness': MatchFitnessFromJSON(json['matchFitness']),
        'state': NegotiationStateFromJSON(json['state']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'shipment': ShipmentFromJSON(json['shipment']),
        'negotiatedPrice': !exists(json, 'negotiatedPrice') ? undefined : PriceFromJSON(json['negotiatedPrice']),
        'delivery': !exists(json, 'delivery') ? undefined : ((json['delivery'] as Array<any>).map(SegmentFromJSON)),
        'pickup': !exists(json, 'pickup') ? undefined : SegmentFromJSON(json['pickup']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'scoreStars': !exists(json, 'scoreStars') ? undefined : json['scoreStars'],
        'useJitpay': json['useJitpay'],
    };
}

export function ProposalForClientToJSON(value?: ProposalForClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'shipmentId': value.shipmentId,
        'shipperCompanyId': value.shipperCompanyId,
        'vehicle': VehicleToJSON(value.vehicle),
        'matchFitness': MatchFitnessToJSON(value.matchFitness),
        'state': NegotiationStateToJSON(value.state),
        'shipment': ShipmentToJSON(value.shipment),
        'negotiatedPrice': PriceToJSON(value.negotiatedPrice),
        'delivery': value.delivery === undefined ? undefined : ((value.delivery as Array<any>).map(SegmentToJSON)),
        'pickup': SegmentToJSON(value.pickup),
        'score': value.score,
        'scoreStars': value.scoreStars,
        'useJitpay': value.useJitpay,
    };
}


