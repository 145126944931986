/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryStays,
    CountryStaysFromJSON,
    CountryStaysFromJSONTyped,
    CountryStaysToJSON,
    FinancialReport,
    FinancialReportFromJSON,
    FinancialReportFromJSONTyped,
    FinancialReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {{ [key: string]: CountryStays; }}
     * @memberof UserData
     */
    countryStays?: { [key: string]: CountryStays; };
    /**
     * 
     * @type {FinancialReport}
     * @memberof UserData
     */
    userFinancialReport?: FinancialReport;
}

export function UserDataFromJSON(json: any): UserData {
    return UserDataFromJSONTyped(json, false);
}

export function UserDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryStays': !exists(json, 'country_stays') ? undefined : (mapValues(json['country_stays'], CountryStaysFromJSON)),
        'userFinancialReport': !exists(json, 'user_financial_report') ? undefined : FinancialReportFromJSON(json['user_financial_report']),
    };
}

export function UserDataToJSON(value?: UserData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_stays': value.countryStays === undefined ? undefined : (mapValues(value.countryStays, CountryStaysToJSON)),
        'user_financial_report': FinancialReportToJSON(value.userFinancialReport),
    };
}


