import cn from 'classnames';
import { ReactNode } from 'react';
import { Progress } from 'common/components';
import { roundToStep } from 'common/utils/averages';
import { star } from 'resources/images/driver-behavior';
import { Colors } from 'modules/statistics/modules/driver-behavior-coach/components/DriverScoreProgress';
import { DriverBehaviorScoreModel } from 'common/model/statistics';
import Trend, { TrendChange } from 'common/components/Trend';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { ScoreDriver } from './scoreDriver';
import { Col, Row } from 'antd';
import { capitalizeFirstLetter } from 'common/utils/textUtils';
import numeral from 'numeral';
import { NoData } from 'common/components/NoData';
import { useTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { Theme } from 'common/components/Settings';

export enum ScoreCardColor {
    Lightblue = 'lightblue',
    Green = 'green',
    Blue = 'blue',
    Purple = 'purple',
    Yellow = 'yellow'
}

interface Props {
    driversList: DriverBehaviorScoreModel[];
    className?: string;
    label: string;
    color: ScoreCardColor;
    trend?: boolean;
    trendChangeForIncrease?: TrendChange;
    theme: Theme;
    description?: ReactNode;
    size: number;
    important?: boolean;
    percent?: boolean;
    fixedValue?: boolean;
    starred?: boolean;
    selected?: boolean;
    qa?: string;
}

function DriverScoreCard(props: Props) {
    const { t } = useTranslation();
    const trendValue = getIncreasePercent(props.driversList[0].value, props.driversList[0].previousValue);
    const increaseDirection = getIncreaseDirection(props.driversList[0].value, props.driversList[0].previousValue);

    return (
        <Row
            align="middle"
            className={cn(`driver-score-card ${props.className ?? ''}`, {
                'driver-score-card-multi': (props.driversList.length ?? 0) > 1,
                'driver-score-card-selected': props.selected
            })}
        >
            <Col className="driver-score-card-container">
                <div
                    className={cn('driver-score-card-label', {
                        'driver-score-card-label-important': props.important,
                        'driver-score-card-label-selected': props.selected
                    })}
                >
                    {props.label}
                </div>
                {(props.driversList?.length ?? 0) === 1 && (
                    <>
                        {props.driversList[0].value === undefined && (
                            <NoData text={t('common.noData')} qa={qa.common.noData} />
                        )}
                        {props.driversList[0].value !== undefined && (
                            <>
                                <Progress
                                    className={cn(`driver-score-progress driver-score-progress-${props.color}`, {
                                        'driver-score-progress-important': props.important
                                    })}
                                    percent={props.driversList[0].value * (props.percent ? 1 : 20)}
                                    type="dashboard"
                                    gapDegree={84}
                                    trailColor="#393939"
                                    strokeWidth={8}
                                    strokeColor={Colors[capitalizeFirstLetter(props.color)] ?? Colors.Yellow}
                                    width={props.size}
                                    format={() => (
                                        <div className="driver-score-card-content" data-qa={props.qa}>
                                            {props.fixedValue
                                                ? props.driversList[0].value
                                                : numeral(roundToStep(props.driversList[0].value)).format('0')}
                                            {props.percent && '%'}
                                        </div>
                                    )}
                                />
                                {props.starred && (
                                    <div className="driver-score-card-star">
                                        <img src={star} alt="star" />
                                    </div>
                                )}
                                {props.trend && (
                                    <div className="driver-score-card-trend">
                                        <Trend
                                            direction={increaseDirection}
                                            trendChangeForIncrease={
                                                props.trendChangeForIncrease ?? TrendChange.POSITIVE
                                            }
                                            value={trendValue}
                                            theme={props.theme}
                                        />
                                    </div>
                                )}
                                {props.description && (
                                    <div className="driver-score-card-description">{props.description}</div>
                                )}
                            </>
                        )}
                    </>
                )}
                {(props.driversList?.length ?? 0) > 1 && (
                    <Row gutter={16}>
                        {props.driversList.map((driver, index) => (
                            <Col span={12} key={index}>
                                <ScoreDriver
                                    data={driver}
                                    trend={props.trend}
                                    trendChangeForIncrease={props.trendChangeForIncrease ?? TrendChange.POSITIVE}
                                    color={props.color}
                                    fixedValue={props.fixedValue}
                                    theme={props.theme}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            </Col>
        </Row>
    );
}

export default DriverScoreCard;
