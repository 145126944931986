/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyExternalSystemAccess
 */
export interface ReadOnlyExternalSystemAccess {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemAccess
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyExternalSystemAccess
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemAccess
     */
    accessType: ReadOnlyExternalSystemAccessAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemAccess
     */
    externalSystemName: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemAccess
     */
    externalSystemVersion: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyExternalSystemAccess
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemAccess
     */
    fetchType?: ReadOnlyExternalSystemAccessFetchTypeEnum;
}

export function ReadOnlyExternalSystemAccessFromJSON(json: any): ReadOnlyExternalSystemAccess {
    return ReadOnlyExternalSystemAccessFromJSONTyped(json, false);
}

export function ReadOnlyExternalSystemAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyExternalSystemAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'accessType': json['access_type'],
        'externalSystemName': json['external_system_name'],
        'externalSystemVersion': json['external_system_version'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'fetchType': !exists(json, 'fetch_type') ? undefined : json['fetch_type'],
    };
}

export function ReadOnlyExternalSystemAccessToJSON(value?: ReadOnlyExternalSystemAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'access_type': value.accessType,
        'external_system_name': value.externalSystemName,
        'external_system_version': value.externalSystemVersion,
        'fetch_type': value.fetchType,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyExternalSystemAccessAccessTypeEnum {
    BasicAuth = 'basic_auth',
    Oauth2 = 'oauth2',
    CertIp = 'cert_ip'
}
/**
* @export
* @enum {string}
*/
export enum ReadOnlyExternalSystemAccessFetchTypeEnum {
    PASSIVE = 'PASSIVE',
    ACTIVE = 'ACTIVE'
}


