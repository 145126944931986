/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectOperationalCostProperties,
    MonitoredObjectOperationalCostPropertiesFromJSON,
    MonitoredObjectOperationalCostPropertiesFromJSONTyped,
    MonitoredObjectOperationalCostPropertiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectOperationalCost
 */
export interface MonitoredObjectOperationalCost {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOperationalCost
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOperationalCost
     */
    monitoredObjectId: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectOperationalCost
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectOperationalCost
     */
    dateTo: Date;
    /**
     * 
     * @type {MonitoredObjectOperationalCostProperties}
     * @memberof MonitoredObjectOperationalCost
     */
    costs: MonitoredObjectOperationalCostProperties;
}

export function MonitoredObjectOperationalCostFromJSON(json: any): MonitoredObjectOperationalCost {
    return MonitoredObjectOperationalCostFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'monitoredObjectId': json['monitored_object_id'],
        'dateFrom': (new Date(json['date_from'])),
        'dateTo': (new Date(json['date_to'])),
        'costs': MonitoredObjectOperationalCostPropertiesFromJSON(json['costs']),
    };
}

export function MonitoredObjectOperationalCostToJSON(value?: MonitoredObjectOperationalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'monitored_object_id': value.monitoredObjectId,
        'date_from': (value.dateFrom.toISOString()),
        'date_to': (value.dateTo.toISOString()),
        'costs': MonitoredObjectOperationalCostPropertiesToJSON(value.costs),
    };
}


