import { Card, Input } from 'antd';
import { Button } from 'common/components';
import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    logo?: string;
    onSubmit?: (logo?: string) => void;
}

interface State {
    logo?: string;
}

class WhitelabelLogo extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="whitelabel-logo">
                <Card className="whitelabel-card" title={this.props.t('common.logo')} bordered={false}>
                    {this.props.logo ? (
                        <Button type="primary" onClick={this._onDelete}>
                            {this.props.t('common.delete')}
                        </Button>
                    ) : (
                        <>
                            <Input className="t-input" type="file" onChange={this._onLogoChange} />
                            <Button type="primary" onClick={this._onSubmit} disabled={this.state.logo ? false : true}>
                                {this.props.t('common.save')}
                            </Button>
                        </>
                    )}
                </Card>
            </div>
        );
    }

    private _onLogoChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        e.preventDefault();
        const [file] = e.target.files ?? [];
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result) {
                this.setState(state => ({
                    ...state,
                    logo: String(reader.result)
                }));
            }
        };

        reader.readAsDataURL(file);
    };

    private _onSubmit = () => {
        if (this.state.logo) {
            this.props.onSubmit?.(this.state.logo);
        }
    };

    private _onDelete = () => {
        this.props.onSubmit?.();
    };
}

export default withTranslation()(WhitelabelLogo);
