import React, { Component } from 'react';
import { Logic } from 'logic/logic';
import Docs from './components/Docs';
import i18next from 'i18next';
import { confDefault } from 'conf';
import { Role } from 'logic/auth';

interface Props {
    logic: Logic;
    onClose?: () => void;
}

export type DocsUserGuide =
    | 'management'
    | 'livemap'
    | 'routeplanning'
    | 'track&trace'
    | 'can'
    | 'legaldrivertimes'
    | 'tachoremotedownload'
    | 'messaging'
    | 'alarms'
    | 'publicapi'
    | 'driverbehaviour'
    | 'driverbehaviourcoach'
    | 'diets(travelallowances)'
    | 'puesc'
    | 'ewFleetDriverApp'
    | 'ewTelematicsApp'
    | 'ewMissionApp';

export interface DocsItem {
    item: DocsUserGuide;
    subitems: string[];
}

const docsStructure: DocsItem[] = [
    {
        item: 'management',
        subitems: []
    },
    {
        item: 'livemap',
        subitems: []
    },
    {
        item: 'routeplanning',
        subitems: []
    },
    {
        item: 'track&trace',
        subitems: []
    },
    {
        item: 'can',
        subitems: []
    },
    {
        item: 'legaldrivertimes',
        subitems: []
    },
    {
        item: 'tachoremotedownload',
        subitems: []
    },
    {
        item: 'messaging',
        subitems: []
    },
    {
        item: 'alarms',
        subitems: []
    },
    {
        item: 'publicapi',
        subitems: []
    },
    {
        item: 'driverbehaviour',
        subitems: []
    },
    {
        item: 'driverbehaviourcoach',
        subitems: []
    },
    {
        item: 'diets(travelallowances)',
        subitems: []
    },
    {
        item: 'puesc',
        subitems: []
    },
    {
        item: 'ewFleetDriverApp',
        subitems: []
    },
    {
        item: 'ewTelematicsApp',
        subitems: []
    },
    {
        item: 'ewMissionApp',
        subitems: []
    }
];

interface State {
    module: DocsUserGuide;
    content: string;
}

class DocsModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {
            module: 'management',
            content: ''
        };

        this._onMenuUserGuideClick(this.state.module);
    }

    componentDidMount() {
        i18next.on('languageChanged', (lang: string) => {
            this._onMenuUserGuideClick(this.state.module, lang);
        });
    }

    render() {
        const structure =
            this._logic.conf.settings.driverBehaviorCoachAvailable === true
                ? docsStructure.filter(d => d.item !== 'driverbehaviour')
                : docsStructure.filter(d => d.item !== 'driverbehaviourcoach');

        return (
            <Docs
                menuDocs={structure}
                contentModule={this.state.module}
                contentContent={this.state.content}
                onCloseClick={this.props.onClose}
                onMenuUserGuideClick={this._onMenuUserGuideClick}
            />
        );
    }

    private _onMenuUserGuideClick = (module: DocsUserGuide, changedLanguage?: string): void => {
        const currentLanguage = changedLanguage ? changedLanguage : this.props.logic.settings().getProp('lang');

        const cointainsModule = confDefault.langsDocs.some(item => {
            if (typeof item === 'object' && item?.[currentLanguage]?.includes(module)) {
                return item?.[currentLanguage].includes(module);
            }
            return false;
        });

        const language =
            confDefault.langsDocs.includes(currentLanguage) || cointainsModule
                ? currentLanguage
                : this._logic.conf.lang;

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html?1`).then(response => {
            response.text().then(html => {
                const parser = new DOMParser();
                const sections = parser.parseFromString(html, 'text/html').getElementsByTagName('section');
                let content = '';

                for (let section of sections) {
                    const role = section.getAttribute('data-role');
                    if (role !== null) {
                        if (
                            this._logic
                                .auth()
                                .roles()
                                .includes(section.getAttribute('data-role') as Role)
                        ) {
                            content += `<section id="${section.id}">${section.innerHTML}</section>`;
                        }
                    } else {
                        content += `<section id="${section.id}">${section.innerHTML}</section>`;
                    }
                }

                this.setState({
                    content,
                    module
                });
            });
        });
    };
}

export default DocsModule;
