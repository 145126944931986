import { ReactNode } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { iconClose } from 'resources/images/common';
import qa from 'qa-selectors';

export type Size = 'small' | 'default' | 'wide' | 'super-wide';

export interface EnhancedModalProps extends ModalProps {
    children: ReactNode;
    size?: Size;
    footer?: ReactNode;
    style?: React.CSSProperties;
    withoutPadding?: boolean;
    icon?: ReactNode;
}

export default function EnhancedModal({
    size = 'default',
    closeIcon,
    width,
    withoutPadding,
    footer,
    style,
    ...props
}: EnhancedModalProps) {
    function selectWidth() {
        switch (size) {
            case 'small':
                return 300;
            case 'default':
                return 520;
            case 'wide':
                return 600;
            case 'super-wide':
                return 720;
            default:
                return 520;
        }
    }

    return (
        <Modal
            closeIcon={closeIcon ?? <img src={iconClose} alt="close" data-qa={qa.common.modal.btnClose} />}
            width={width ?? selectWidth()}
            className={props.className}
            title={
                props.icon ? (
                    <>
                        <div>{props.icon}</div>
                        <div>{props.title}</div>
                    </>
                ) : (
                    props.title
                )
            }
            footer={footer}
            bodyStyle={withoutPadding ? { padding: 0 } : undefined}
            style={footer === null ? { ...style, paddingBottom: 0 } : style}
            {...props}
        />
    );
}

EnhancedModal.confirm = Modal.confirm;
EnhancedModal.error = Modal.error;
EnhancedModal.info = Modal.info;
EnhancedModal.warning = Modal.warning;
