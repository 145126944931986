/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TeamType {
    Alone = 'Alone',
    InTeam = 'InTeam'
}

export function TeamTypeFromJSON(json: any): TeamType {
    return TeamTypeFromJSONTyped(json, false);
}

export function TeamTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamType {
    return json as TeamType;
}

export function TeamTypeToJSON(value?: TeamType | null): any {
    return value as any;
}

