import { Control } from 'common/components/Form/Control';
import { Field, FieldProps } from 'formik';
import { ReactNode } from 'react';
import Switch, { Props as SwitchProps } from 'common/components/Switch';

interface Props extends SwitchProps {
    name: string;
    label?: ReactNode;
    error?: ReactNode;
}

const SwitchField = ({ label, error, name, ...bag }: Props) => (
    <Field name={name}>
        {({ field: { value, ...fieldBag }, form }: FieldProps<any>) => {
            const handleChange = (checked: boolean) => {
                form.setFieldValue(name, checked);
            };
            return (
                <Control label={label} error={error}>
                    <Switch checked={value} {...fieldBag} {...bag} onChange={handleChange} />
                </Control>
            );
        }}
    </Field>
);

export default SwitchField;
