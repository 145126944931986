import React from 'react';
import cn from 'classnames';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'common/components';
import Search from 'common/components/Search';
import { SearchData, SmartBarFilter } from 'modules/tracking/TrackingModule';
import { Col, Row } from 'antd';

interface Props extends WithTranslation {
    expanded?: boolean;
    filtered?: boolean;
    checkboxAll?: boolean;
    filterActive?: boolean;
    settingsActive?: boolean;
    search?: SearchData;
    smartBar?: SmartBarFilter;
    width?: number;
    onCheckboxAllClick?: () => void;
    onExpandClick?: () => void;
    onFilterClick?: () => void;
    onSettingsClick?: () => void;
    onHelperClick?: () => void;
    onSearch?: (text: string) => void;
}

function TrackingBar({
    t,
    expanded,
    filtered,
    search,
    smartBar,
    width,
    onCheckboxAllClick,
    onFilterClick,
    onExpandClick,
    onHelperClick,
    onSearch
}: Props) {
    function getSmartBarTitle() {
        switch (smartBar) {
            case 'onTime':
                return t('TrackingSmartBar.onTime');
            case 'delayed':
                return t('TrackingSmartBar.delayedTransports');
            case 'aetrLow':
                return t('TrackingSmartBar.aetrLow');
            case 'fuelLow':
                return t('TrackingSmartBar.lowFuel');
            case 'alarms':
                return t('common.alarms');
            default:
                return '';
        }
    }

    return (
        <div className="tm-tracking-bar">
            <Row justify="space-between" align="middle" gutter={10}>
                <Col flex="auto">
                    <Row>
                        {!expanded && (
                            <Col flex="36px">
                                <div className="tm-tracking-bar-checker">
                                    <img
                                        className="pointer"
                                        alt="checkbox"
                                        onClick={onCheckboxAllClick}
                                        src="/img-svg/checked-white.svg"
                                    />
                                </div>
                            </Col>
                        )}
                        <Col flex="180px">
                            <Search size="middle" searchActive={true} search={search} onSearch={onSearch} />
                        </Col>
                        <Col span="auto">
                            <Tooltip overlay={getSmartBarTitle()}>
                                <Typography.Text
                                    ellipsis
                                    className="tm-tracking-bar-title"
                                    style={width ? { maxWidth: `${width - 380}px` } : {}}
                                >
                                    {getSmartBarTitle()}
                                </Typography.Text>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col flex="100px">
                    <div className="tm-tracking-bar-controls">
                        <Tooltip title={t('TrackingBar.expandTable')} placement="bottomLeft">
                            <i
                                data-qa={qa.trackingBar.expandTableButton}
                                className={cn('tm-tracking-bar-controls-expand fa fa-indent pointer', {
                                    override: expanded
                                })}
                                onClick={onExpandClick}
                            />
                        </Tooltip>
                        <Tooltip title={t('common.filter')} placement="bottomLeft">
                            <img
                                data-qa={qa.trackingBar.showFilterButton}
                                className={cn('pointer', { override: filtered })}
                                src="/icons/icn-filter.svg"
                                onClick={onFilterClick}
                                alt="filter"
                            />
                        </Tooltip>
                        <Tooltip title={t('common.helper')} placement="bottomLeft">
                            <i
                                data-qa={qa.trackingBar.btnHelper}
                                className={cn('fa fa-question pointer', {
                                    override: expanded
                                })}
                                onClick={onHelperClick}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default withTranslation()(TrackingBar);
