/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyUserTokenUser
 */
export interface ReadOnlyUserTokenUser {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserTokenUser
     */
    lastLogin?: Date | null;
    /**
     * Designates that this user has all permissions without explicitly assigning them.
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    isSuperuser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    ssoId?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyUserTokenUser
     */
    loginCredentials?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    surname: string;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyUserTokenUser
     */
    contact?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    averageSpeed: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    password?: string | null;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    isActive?: boolean;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    isStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    isContactPerson?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    binaryRights?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserTokenUser
     */
    binaryRightsBackup?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    isSsoAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserTokenUser
     */
    binaryRightsDirty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    client?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserTokenUser
     */
    externalSystem?: number | null;
    /**
     * The groups this user belongs to. A user will get all permissions granted to each of their groups.
     * @type {Array<number>}
     * @memberof ReadOnlyUserTokenUser
     */
    groups?: Array<number>;
    /**
     * Specific permissions for this user.
     * @type {Array<number>}
     * @memberof ReadOnlyUserTokenUser
     */
    userPermissions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReadOnlyUserTokenUser
     */
    readonly monitoredObjectGroups?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReadOnlyUserTokenUser
     */
    readonly userGroup?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyUserTokenUser
     */
    userRoles: Array<string>;
}

export function ReadOnlyUserTokenUserFromJSON(json: any): ReadOnlyUserTokenUser {
    return ReadOnlyUserTokenUserFromJSONTyped(json, false);
}

export function ReadOnlyUserTokenUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserTokenUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lastLogin': !exists(json, 'last_login') ? undefined : (json['last_login'] === null ? null : new Date(json['last_login'])),
        'isSuperuser': !exists(json, 'is_superuser') ? undefined : json['is_superuser'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'loginCredentials': !exists(json, 'login_credentials') ? undefined : json['login_credentials'],
        'name': json['name'],
        'surname': json['surname'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'deleted': json['deleted'],
        'active': json['active'],
        'averageSpeed': json['average_speed'],
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isStaff': !exists(json, 'is_staff') ? undefined : json['is_staff'],
        'isContactPerson': !exists(json, 'is_contact_person') ? undefined : json['is_contact_person'],
        'binaryRights': !exists(json, 'binary_rights') ? undefined : json['binary_rights'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'binaryRightsBackup': !exists(json, 'binary_rights_backup') ? undefined : json['binary_rights_backup'],
        'isSsoAdmin': !exists(json, 'is_sso_admin') ? undefined : json['is_sso_admin'],
        'binaryRightsDirty': !exists(json, 'binary_rights_dirty') ? undefined : json['binary_rights_dirty'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'userPermissions': !exists(json, 'user_permissions') ? undefined : json['user_permissions'],
        'monitoredObjectGroups': !exists(json, 'monitored_object_groups') ? undefined : json['monitored_object_groups'],
        'userGroup': !exists(json, 'user_group') ? undefined : json['user_group'],
        'userRoles': json['user_roles'],
    };
}

export function ReadOnlyUserTokenUserToJSON(value?: ReadOnlyUserTokenUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_login': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
        'is_superuser': value.isSuperuser,
        'sso_id': value.ssoId,
        'login_credentials': value.loginCredentials,
        'name': value.name,
        'surname': value.surname,
        'contact': value.contact,
        'note': value.note,
        'deleted': value.deleted,
        'active': value.active,
        'average_speed': value.averageSpeed,
        'language_iso_639_1': value.languageIso6391,
        'password': value.password,
        'is_active': value.isActive,
        'is_staff': value.isStaff,
        'is_contact_person': value.isContactPerson,
        'binary_rights': value.binaryRights,
        'external_id': value.externalId,
        'binary_rights_backup': value.binaryRightsBackup,
        'is_sso_admin': value.isSsoAdmin,
        'binary_rights_dirty': value.binaryRightsDirty,
        'client': value.client,
        'external_system': value.externalSystem,
        'groups': value.groups,
        'user_permissions': value.userPermissions,
        'user_roles': value.userRoles,
    };
}


