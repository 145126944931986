/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MonitoredObjectFleetDynamicData,
    MonitoredObjectFleetDynamicDataFromJSON,
    MonitoredObjectFleetDynamicDataToJSON,
    MonitoredObjectFleetDynamicDataExternalDevices,
    MonitoredObjectFleetDynamicDataExternalDevicesFromJSON,
    MonitoredObjectFleetDynamicDataExternalDevicesToJSON,
} from '../models';

export interface SetDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPostRequest {
    monitoredObjectId: string;
    driverId?: number;
}

export interface SetExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPostRequest {
    monitoredObjectId: string;
    monitoredObjectFleetDynamicDataExternalDevices: Array<MonitoredObjectFleetDynamicDataExternalDevices>;
}

export interface UnpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePostRequest {
    monitoredObjectId: string;
    serialNumber: string;
}

/**
 * no description
 */
export class FleetDynamicDataApi extends runtime.BaseAPI {

    /**
     * Get Fleet Dynamic Data
     */
    async getFleetDynamicDataV2ActualVehicleStateFleetDynamicDataGetRaw(): Promise<runtime.ApiResponse<Array<MonitoredObjectFleetDynamicData>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/fleet-dynamic-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectFleetDynamicDataFromJSON));
    }

    /**
     * Get Fleet Dynamic Data
     */
    async getFleetDynamicDataV2ActualVehicleStateFleetDynamicDataGet(): Promise<Array<MonitoredObjectFleetDynamicData>> {
        const response = await this.getFleetDynamicDataV2ActualVehicleStateFleetDynamicDataGetRaw();
        return await response.value();
    }

    /**
     * Set Default Driver
     */
    async setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPostRaw(requestParameters: SetDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/fleet-dynamic-data/{monitored_object_id}/default-driver`.replace(`{${"monitored_object_id"}}`, encodeURIComponent(String(requestParameters.monitoredObjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Set Default Driver
     */
    async setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPost(requestParameters: SetDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPostRequest): Promise<object> {
        const response = await this.setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set External Devices
     */
    async setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPostRaw(requestParameters: SetExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPost.');
        }

        if (requestParameters.monitoredObjectFleetDynamicDataExternalDevices === null || requestParameters.monitoredObjectFleetDynamicDataExternalDevices === undefined) {
            throw new runtime.RequiredError('monitoredObjectFleetDynamicDataExternalDevices','Required parameter requestParameters.monitoredObjectFleetDynamicDataExternalDevices was null or undefined when calling setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/fleet-dynamic-data/{monitored_object_id}/external-devices`.replace(`{${"monitored_object_id"}}`, encodeURIComponent(String(requestParameters.monitoredObjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.monitoredObjectFleetDynamicDataExternalDevices.map(MonitoredObjectFleetDynamicDataExternalDevicesToJSON),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Set External Devices
     */
    async setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPost(requestParameters: SetExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPostRequest): Promise<object> {
        const response = await this.setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unpair External Device
     */
    async unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePostRaw(requestParameters: UnpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePost.');
        }

        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/fleet-dynamic-data/{monitored_object_id}/unpair-external-device`.replace(`{${"monitored_object_id"}}`, encodeURIComponent(String(requestParameters.monitoredObjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Unpair External Device
     */
    async unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePost(requestParameters: UnpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePostRequest): Promise<object> {
        const response = await this.unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePostRaw(requestParameters);
        return await response.value();
    }

}
