/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InaccessiblePoint
 */
export interface InaccessiblePoint {
    /**
     * 
     * @type {string}
     * @memberof InaccessiblePoint
     */
    type: InaccessiblePointTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InaccessiblePoint
     */
    crossingIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof InaccessiblePoint
     */
    placeIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof InaccessiblePoint
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof InaccessiblePoint
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof InaccessiblePoint
     */
    lng: number;
}

export function InaccessiblePointFromJSON(json: any): InaccessiblePoint {
    return InaccessiblePointFromJSONTyped(json, false);
}

export function InaccessiblePointFromJSONTyped(json: any, ignoreDiscriminator: boolean): InaccessiblePoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'crossingIndex': !exists(json, 'crossingIndex') ? undefined : json['crossingIndex'],
        'placeIndex': !exists(json, 'placeIndex') ? undefined : json['placeIndex'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lat': json['lat'],
        'lng': json['lng'],
    };
}

export function InaccessiblePointToJSON(value?: InaccessiblePoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'crossingIndex': value.crossingIndex,
        'placeIndex': value.placeIndex,
        'name': value.name,
        'lat': value.lat,
        'lng': value.lng,
    };
}

/**
* @export
* @enum {string}
*/
export enum InaccessiblePointTypeEnum {
    Crossing = 'crossing',
    Place = 'place'
}


