/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyInfo,
    CompanyInfoFromJSON,
    CompanyInfoFromJSONTyped,
    CompanyInfoToJSON,
    HistoryItem,
    HistoryItemFromJSON,
    HistoryItemFromJSONTyped,
    HistoryItemToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    VehicleInfo,
    VehicleInfoFromJSON,
    VehicleInfoFromJSONTyped,
    VehicleInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleForClient
 */
export interface VehicleForClient {
    /**
     * 
     * @type {Location}
     * @memberof VehicleForClient
     */
    l: Location;
    /**
     * 
     * @type {Array<HistoryItem>}
     * @memberof VehicleForClient
     */
    history: Array<HistoryItem>;
    /**
     * 
     * @type {number}
     * @memberof VehicleForClient
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleForClient
     */
    vehicleId: string;
    /**
     * 
     * @type {VehicleInfo}
     * @memberof VehicleForClient
     */
    vehicleInfo?: VehicleInfo;
    /**
     * 
     * @type {CompanyInfo}
     * @memberof VehicleForClient
     */
    companyInfo?: CompanyInfo;
    /**
     * 
     * @type {number}
     * @memberof VehicleForClient
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleForClient
     */
    maxWeight?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleForClient
     */
    monitoredObjectId: string;
}

export function VehicleForClientFromJSON(json: any): VehicleForClient {
    return VehicleForClientFromJSONTyped(json, false);
}

export function VehicleForClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleForClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'l': LocationFromJSON(json['L']),
        'history': ((json['history'] as Array<any>).map(HistoryItemFromJSON)),
        'timestamp': json['@timestamp'],
        'vehicleId': json['vehicle_id'],
        'vehicleInfo': !exists(json, 'vehicle_info') ? undefined : VehicleInfoFromJSON(json['vehicle_info']),
        'companyInfo': !exists(json, 'company_info') ? undefined : CompanyInfoFromJSON(json['company_info']),
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'maxWeight': !exists(json, 'max_weight') ? undefined : json['max_weight'],
        'monitoredObjectId': json['monitored_object_id'],
    };
}

export function VehicleForClientToJSON(value?: VehicleForClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'L': LocationToJSON(value.l),
        'history': ((value.history as Array<any>).map(HistoryItemToJSON)),
        '@timestamp': value.timestamp,
        'vehicle_id': value.vehicleId,
        'vehicle_info': VehicleInfoToJSON(value.vehicleInfo),
        'company_info': CompanyInfoToJSON(value.companyInfo),
        'weight': value.weight,
        'max_weight': value.maxWeight,
        'monitored_object_id': value.monitoredObjectId,
    };
}


