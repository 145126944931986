import { ZONE_RULE_NAME } from 'domain-constants';
import {
    AlarmInDatabaseWithGPSInfoFromJSON,
    AlarmType,
    ColdChainProfileActiveEventRuleFromJSON,
    ExternalDeviceType
} from 'generated/backend-api';
import { ExternalDeviceState, ExternalDeviceStateFromJSON } from 'generated/backend-api-live';
import { ReadOnlyProfileSerializerFromJSON, ReadOnlyTemperatureSensorSerializerFromJSON } from 'generated/new-main';
import moment from 'moment';

export const GRAPH_FRACTION_SECONDS = 1763;
export const temperatureStack = [
    [
        2.71227423797636, 2.54842072858921, 2.5927123776463, 2.30035995409732, 2.492192083048153, 2.502013390188862,
        2.8796871298553, 2.18129004188922, 2.12563710343846, 2.00789247952337, 2.433356231624025, 2.65354460808916,
        2.4770359651628, 2.2749694115417, 2.671876379779757, 2.864372166507167, 2.679028629287648, 2.71901514015337,
        2.554123281341585, 2.2883585020342139, 2.356632554330002, 2.30764491793537, 2.52980523423786, 2.68443563568247,
        2.876901443512551, 2.37045855021097, 2.296910476023285, 2.955055874772, 5.752430300439, 14.603719440500072,
        12.88766340298234, 2.9973554904779363, 2.17956536875416, 2.32388306732056, 2.498424625696984, 2.290700909575,
        -15.559637249227286, -12.843852042669, -2.08280985361851, 2.3571641898559008, 2.655508324019266,
        2.4601725844093, 2.08165415507147, 2.6629781465574, 2.063811360237999, 2.027939779544788, 2.115820374050841,
        2.13921399914767, 2.37896173236703
    ],
    [
        1.1979629748939, 1.06276930508093, 1.9701141523021, 1.29775426057078, 1.373928013886875, 1.3168962257626,
        1.539440173985085, 1.600056204563671, 1.098204150059303, 1.51673457600456, 1.36625133451017, 1.848163300942574,
        1.72437109266984, 1.2636207568726, 1.01643374795275, 1.57570339137866, 1.14697158609863, 1.48565292439505,
        1.7870095772359, 1.565780861929703, 1.52234559971293, 1.665305904655817, 1.721430979755837, 1.86633765815238,
        1.03346170425662, 1.6488797892681, 1.313277355956, 1.28213287555644, 3.184703086451186, 3.3222340405685,
        1.3412102673994, 1.64404734143369, 1.873146813233184, 1.51270447240791, 1.20377222902715, 1.643401801893246,
        -3.676030613911564, -3.9772395595199, -3.82724823619953, 1.7616643915324, 1.235607790121819, 1.9071170927913,
        1.381098490778328, 1.754672892612646, 1.86665002750468, 1.65967097297943, 1.71146580728708, 1.687851108823011,
        1.41331391837075
    ],
    [
        0.81125816522607, 0.9838846112651, 0.2539769321743, 0.97956506364052, 0.45017593306954, 0.42974764682428,
        0.19401395221632, 0.8976668633135, 0.37566875422596, 0.2291847841576, 0.183635736401726, 0.39886669386469,
        0.52760626069677, 0.2347018528082, 0.997435145437, 0.840851250176513, 0.5577603633895, 0.9286597108428,
        0.51862620770976, 0.8336166514793, 0.02167197170852, 0.19526153625119, 0.8829262168264, 0.458009435878,
        0.236814371033, 0.389971647061284, 0.3437796926935, 0.358507070462, 5.024872824978267, 5.7561674283625,
        6.258790410283565, 0.99572106631115, 0.90158172462557, 0.21566349180205, 0.8712597231566, 0.01328650673268,
        -4.96467394155741, -4.1236753120077, -7.477521249369, -2.188567158956, 0.2659058856983, 0.44549519447075,
        0.8609108389111, 0.57868979572796, 0.46982776069007, 0.513512957400799, 0.0433238516352, 0.4868834543241,
        0.99896443227948
    ],
    [
        2.313277355956, 2.28213287555644, 2.184703086451186, 2.3222340405685, 2.3412102673994, 2.64404734143369,
        2.873146813233184, 2.51270447240791, 2.20377222902715, 2.643401801893246, 2.676030613911564, 2.9772395595199,
        2.82724823619953, 2.7616643915324, 2.235607790121819, 2.9071170927913, 2.381098490778328, 2.754672892612646,
        2.86665002750468, 2.65967097297943, 2.71146580728708, 2.687851108823011, 2.41331391837075, 2.1979629748939,
        2.06276930508093, 2.9701141523021, 2.29775426057078, 2.373928013886875, 3.3168962257626, 4.539440173985085,
        4.600056204563671, 6.098204150059303, 2.51673457600456, 2.36625133451017, 0.848163300942574, 0.72437109266984,
        -10.2636207568726, -4.01643374795275, -10.57570339137866, -4.14697158609863, 2.48565292439505, 2.7870095772359,
        2.565780861929703, 2.52234559971293, 2.665305904655817, 2.721430979755837, 2.86633765815238, 2.03346170425662,
        2.6488797892681
    ],
    [
        1.89971647061284, 1.3437796926935, 1.5358507070462, 1.024872824978267, 1.47561674283625, 1.58790410283565,
        1.99572106631115, 1.490158172462557, 1.21566349180205, 1.58712597231566, 1.1328650673268, 1.96467394155741,
        1.71236753120077, 1.477521249369, 1.7188567158956, 1.659058856983, 1.44549519447075, 1.78609108389111,
        1.57868979572796, 1.446982776069007, 1.13512957400799, 1.0433238516352, 1.24868834543241, 1.99896443227948,
        1.81125816522607, 1.838846112651, 1.32539769321743, 1.97956506364052, 6.245017593306954, 1.42974764682428,
        2.9401395221632, 12.18976668633135, 1.37566875422596, 1.32291847841576, 1.183635736401726, 1.9886669386469,
        -5.552760626069677, -3.2347018528082, -4.5997435145437, -2.840851250176513, 1.577603633895, 1.39286597108428,
        1.51862620770976, 1.38336166514793, 1.02167197170852, 1.9526153625119, 1.88829262168264, 1.458009435878,
        1.023681437103
    ]
];

export const temperatureSensorsData = [
    {
        id: '1',
        monitored_object: 101,
        sensor_name: 'Thermoking 1',
        serial_number: '00d5-a12a',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '2',
        monitored_object: 101,
        sensor_name: 'Thermoking 2',
        serial_number: 'ca23-2174',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '3',
        monitored_object: 101,
        sensor_name: 'Thermoking 3',
        serial_number: '00d5-bb28',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '4',
        monitored_object: 101,
        sensor_name: 'Thermoking 4',
        serial_number: 'fa21-3219',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 2,
        updated_at: moment().toDate()
    },
    {
        id: '11',
        monitored_object: 102,
        sensor_name: 'Thermoking 5',
        serial_number: 'da24-69ca',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '12',
        monitored_object: 102,
        sensor_name: 'Thermoking 6',
        serial_number: 'fe66-2100',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '13',
        monitored_object: 102,
        sensor_name: 'Thermoking 7',
        serial_number: 'fe66-2185',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 2,
        updated_at: moment().toDate()
    },
    {
        id: '14',
        monitored_object: 102,
        sensor_name: 'Thermoking 8',
        serial_number: 'a800-2360',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 2,
        updated_at: moment().toDate()
    },
    {
        id: '21',
        monitored_object: 103,
        sensor_name: 'Thermoking 9',
        serial_number: '1100-8530',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 1,
        updated_at: moment().toDate()
    },
    {
        id: '22',
        monitored_object: 103,
        sensor_name: 'Thermoking 10',
        serial_number: '1100-6050',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 2,
        updated_at: moment().toDate()
    },
    {
        id: '23',
        monitored_object: 103,
        sensor_name: 'Thermoking 11',
        serial_number: 'a802-3007',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 3,
        updated_at: moment().toDate()
    },
    {
        id: '24',
        monitored_object: 103,
        sensor_name: 'Thermoking 12',
        serial_number: 'a801-1047',
        created_at: moment().toDate(),
        sensor_type: 'temperature_sensor',
        sensor_zone: 3,
        updated_at: moment().toDate()
    }
];

export const temperatureSensors = temperatureSensorsData.map(e => ReadOnlyTemperatureSensorSerializerFromJSON(e));

const eventRules = [
    {
        name: ZONE_RULE_NAME + '1',
        config: [
            {
                name: 'cold_chain_profile_id',
                value: 1
            },
            {
                name: 'above_temperature_threshold',
                value: 6
            },
            {
                name: 'below_temperature_threshold',
                value: -6
            },
            {
                name: 'alarm_timer_seconds',
                value: 120
            }
        ]
    },
    {
        name: ZONE_RULE_NAME + '1',
        config: [
            {
                name: 'cold_chain_profile_id',
                value: 2
            },
            {
                name: 'above_temperature_threshold',
                value: 8
            },
            {
                name: 'below_temperature_threshold',
                value: -8
            },
            {
                name: 'alarm_timer_seconds',
                value: 120
            }
        ]
    },
    {
        name: ZONE_RULE_NAME + '2',
        config: [
            {
                name: 'cold_chain_profile_id',
                value: 3
            },
            {
                name: 'above_temperature_threshold',
                value: 5
            },
            {
                name: 'below_temperature_threshold',
                value: -5
            },
            {
                name: 'alarm_timer_seconds',
                value: 120
            }
        ]
    },
    {
        name: ZONE_RULE_NAME + '3',
        config: [
            {
                name: 'cold_chain_profile_id',
                value: 4
            },
            {
                name: 'above_temperature_threshold',
                value: 6
            },
            {
                name: 'below_temperature_threshold',
                value: -6
            },
            {
                name: 'alarm_timer_seconds',
                value: 3600
            }
        ]
    },
    {
        name: ZONE_RULE_NAME + '3',
        config: [
            {
                name: 'cold_chain_profile_id',
                value: 1
            },
            {
                name: 'above_temperature_threshold',
                value: null
            },
            {
                name: 'below_temperature_threshold',
                value: -11
            },
            {
                name: 'alarm_timer_seconds',
                value: 3600
            }
        ]
    }
];

export const externalDeviceStateData = temperatureSensors.map((sensor, index) => ({
    device_id: sensor.id,
    device_type: ExternalDeviceType.TemperatureSensor as string,
    data: {
        error: 'false',
        temperature:
            temperatureStack[[0, 4, 8].includes(index) ? 0 : (index % (temperatureStack.length - 1)) + 1][
                temperatureStack[0].length - 1
            ]
    },
    monitored_object_state: {
        activeTransports: [
            {
                activeEventRules: [eventRules[1], eventRules[2], eventRules[4]]
            }
        ]
    }
}));

export const externalDeviceState: ExternalDeviceState[] = externalDeviceStateData.map(e =>
    ExternalDeviceStateFromJSON(e)
);

const startDates = [
    moment()
        .subtract(20 * GRAPH_FRACTION_SECONDS, 'seconds')
        .toDate(),
    moment()
        .subtract(13 * GRAPH_FRACTION_SECONDS, 'seconds')
        .toDate()
];
const endDates = [
    moment()
        .subtract(20 * GRAPH_FRACTION_SECONDS - 3600, 'seconds')
        .toDate(),
    moment()
        .subtract(13 * GRAPH_FRACTION_SECONDS - 3600, 'seconds')
        .toDate(),
    moment().add(3600, 'seconds').toDate()
];

const lastGpsPointStartObj = [
    [
        {
            time: startDates[0],
            lat: 42.0466,
            lng: 2.86871,
            address: [
                {
                    lang: 'cs',
                    country: 'Španělsko',
                    countryCode: 'ES',
                    town: 'Medinyà',
                    route: 'Carrer Llevant',
                    address: '(ES) 17482, Medinyà, Carrer Llevant',
                    street_address: 'Carrer Llevant 58',
                    postalCode: '17482',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Spain',
                    countryCode: 'ES',
                    town: 'Medinyà',
                    route: 'Carrer Llevant',
                    address: '(ES) 17482, Medinyà, Carrer Llevant',
                    street_address: 'Carrer Llevant 58',
                    postalCode: '17482',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Španielsko',
                    countryCode: 'ES',
                    town: 'Medinyà',
                    route: 'Carrer Llevant',
                    address: '(ES) 17482, Medinyà, Carrer Llevant',
                    street_address: 'Carrer Llevant 58',
                    postalCode: '17482',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 42.295658,
            lng: 2.94095,
            address: [
                {
                    lang: 'cs',
                    country: 'Španělsko',
                    countryCode: 'ES',
                    town: 'Llers',
                    route: 'Diseminado Disseminat',
                    address: '(ES) 17730, Llers, Diseminado Disseminat',
                    street_address: 'Diseminado Disseminat 11',
                    postalCode: '17730',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Spain',
                    countryCode: 'ES',
                    town: 'Llers',
                    route: 'Diseminado Disseminat',
                    address: '(ES) 17730, Llers, Diseminado Disseminat',
                    street_address: 'Diseminado Disseminat 11',
                    postalCode: '17730',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Španielsko',
                    countryCode: 'ES',
                    town: 'Llers',
                    route: 'Diseminado Disseminat',
                    address: '(ES) 17730, Llers, Diseminado Disseminat',
                    street_address: 'Diseminado Disseminat 11',
                    postalCode: '17730',
                    source: 'google'
                }
            ]
        }
    ],
    [
        {
            time: startDates[0],
            lat: 49.748383,
            lng: 12.835644,
            address: [
                {
                    lang: 'cs',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 ',
                    postalCode: '348 02',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Czechia',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 ',
                    postalCode: '348 02',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 ',
                    postalCode: '348 02',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 49.705637,
            lng: 12.957867,
            address: [
                {
                    lang: 'cs',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Kladruby',
                    route: 'E50',
                    address: '(CZ) 349 61, Kladruby, E50',
                    street_address: 'E50 ',
                    postalCode: '349 61',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Czechia',
                    countryCode: 'CZ',
                    town: 'Kladruby',
                    route: 'E50',
                    address: '(CZ) 349 61, Kladruby, E50',
                    street_address: 'E50 ',
                    postalCode: '349 61',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Kladruby',
                    route: 'E50',
                    address: '(CZ) 349 61, Kladruby, E50',
                    street_address: 'E50 ',
                    postalCode: '349 61',
                    source: 'google'
                }
            ]
        }
    ],
    [
        {
            time: startDates[0],
            lat: 54.134874,
            lng: 18.676168,
            address: [
                {
                    lang: 'cs',
                    country: 'Polsko',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'Długa',
                    address: '(PL) 83-111, Malenin, Długa',
                    street_address: 'Długa 55',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Poland',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'Długa',
                    address: '(PL) 83-111, Malenin, Długa',
                    street_address: 'Długa 55',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Poľsko',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'Długa',
                    address: '(PL) 83-111, Malenin, Długa',
                    street_address: 'Długa 55',
                    postalCode: '83-111',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 54.098101,
            lng: 18.691892,
            address: [
                {
                    lang: 'cs',
                    country: 'Polsko',
                    countryCode: 'PL',
                    town: 'Rukosin',
                    route: 'A1',
                    address: '(PL) 83-111, Rukosin, A1',
                    street_address: 'Droga Europejska 75 ',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Poland',
                    countryCode: 'PL',
                    town: 'Rukosin',
                    route: 'A1',
                    address: '(PL) 83-111, Rukosin, A1',
                    street_address: 'Droga Europejska 75 ',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Poľsko',
                    countryCode: 'PL',
                    town: 'Rukosin',
                    route: 'A1',
                    address: '(PL) 83-111, Rukosin, A1',
                    street_address: 'Droga Europejska 75 ',
                    postalCode: '83-111',
                    source: 'google'
                }
            ]
        }
    ]
];

const lastGpsPointEndObj = [
    [
        {
            time: startDates[0],
            lat: 42.033136,
            lng: 2.834563,
            address: [
                {
                    lang: 'cs',
                    country: 'Španělsko',
                    countryCode: 'ES',
                    town: 'Sant Julià de Ramis',
                    route: 'Carrer Montaspre',
                    address: '(ES) 17481, Sant Julià de Ramis, Carrer Montaspre',
                    street_address: 'Carrer Montaspre 20',
                    postalCode: '17481',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Spain',
                    countryCode: 'ES',
                    town: 'Sant Julià de Ramis',
                    route: 'Carrer Montaspre',
                    address: '(ES) 17481, Sant Julià de Ramis, Carrer Montaspre',
                    street_address: 'Carrer Montaspre 20',
                    postalCode: '17481',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Španielsko',
                    countryCode: 'ES',
                    town: 'Sant Julià de Ramis',
                    route: 'Carrer Montaspre',
                    address: '(ES) 17481, Sant Julià de Ramis, Carrer Montaspre',
                    street_address: 'Carrer Montaspre 20',
                    postalCode: '17481',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 42.204444,
            lng: 2.936782,
            address: [
                {
                    lang: 'cs',
                    country: 'Španělsko',
                    countryCode: 'ES',
                    town: 'Borrassà',
                    route: 'E-15',
                    address: '(ES) 17770, Borrassà, E-15',
                    street_address: 'Autopista del Mediterráneo ',
                    postalCode: '17770',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Spain',
                    countryCode: 'ES',
                    town: 'Borrassà',
                    route: 'E-15',
                    address: '(ES) 17770, Borrassà, E-15',
                    street_address: 'Autopista del Mediterráneo ',
                    postalCode: '17770',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Španielsko',
                    countryCode: 'ES',
                    town: 'Borrassà',
                    route: 'E-15',
                    address: '(ES) 17770, Borrassà, E-15',
                    street_address: 'Autopista del Mediterráneo ',
                    postalCode: '17770',
                    source: 'google'
                }
            ]
        }
    ],
    [
        {
            time: startDates[0],
            lat: 49.747913,
            lng: 12.785318,
            address: [
                {
                    lang: 'cs',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 20',
                    postalCode: '348 02',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Czechia',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 20',
                    postalCode: '348 02',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Bor',
                    route: 'E50',
                    address: '(CZ) 348 02, Bor, E50',
                    street_address: 'E50 20',
                    postalCode: '348 02',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 49.722609,
            lng: 12.89491,
            address: [
                {
                    lang: 'cs',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Benešovice',
                    route: 'E50',
                    address: '(CZ) 349 01, Benešovice, E50',
                    street_address: 'E50 ',
                    postalCode: '349 01',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Czechia',
                    countryCode: 'CZ',
                    town: 'Benešovice',
                    route: 'E50',
                    address: '(CZ) 349 01, Benešovice, E50',
                    street_address: 'E50 ',
                    postalCode: '349 01',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Česko',
                    countryCode: 'CZ',
                    town: 'Benešovice',
                    route: 'E50',
                    address: '(CZ) 349 01, Benešovice, E50',
                    street_address: 'E50 ',
                    postalCode: '349 01',
                    source: 'google'
                }
            ]
        }
    ],
    [
        {
            time: startDates[0],
            lat: 54.125844,
            lng: 18.680832,
            address: [
                {
                    lang: 'cs',
                    country: 'Polsko',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'A1',
                    address: '(PL) 83-111, Malenin, A1',
                    street_address: 'Droga Europejska 75 23',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Poland',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'A1',
                    address: '(PL) 83-111, Malenin, A1',
                    street_address: 'Droga Europejska 75 23',
                    postalCode: '83-111',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Poľsko',
                    countryCode: 'PL',
                    town: 'Malenin',
                    route: 'A1',
                    address: '(PL) 83-111, Malenin, A1',
                    street_address: 'Droga Europejska 75 23',
                    postalCode: '83-111',
                    source: 'google'
                }
            ]
        },
        {
            time: startDates[1],
            lat: 54.085447,
            lng: 18.691292,
            address: [
                {
                    lang: 'cs',
                    country: 'Polsko',
                    countryCode: 'PL',
                    town: 'Lubiszewo Tczewskie',
                    route: 'Działkowa',
                    address: '(PL) 83-112, Lubiszewo Tczewskie, Działkowa',
                    street_address: 'Działkowa 13',
                    postalCode: '83-112',
                    source: 'google'
                },
                {
                    lang: 'en',
                    country: 'Poland',
                    countryCode: 'PL',
                    town: 'Lubiszewo Tczewskie',
                    route: 'Działkowa',
                    address: '(PL) 83-112, Lubiszewo Tczewskie, Działkowa',
                    street_address: 'Działkowa 13',
                    postalCode: '83-112',
                    source: 'google'
                },
                {
                    lang: 'sk',
                    country: 'Poľsko',
                    countryCode: 'PL',
                    town: 'Lubiszewo Tczewskie',
                    route: 'Działkowa',
                    address: '(PL) 83-112, Lubiszewo Tczewskie, Działkowa',
                    street_address: 'Działkowa 13',
                    postalCode: '83-112',
                    source: 'google'
                }
            ]
        }
    ]
];

const temperatureSensorAlarmsDataRaw = [
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureHigh,
        start: startDates[0],
        monitoredObjectId: 101,
        clientId: 8559,
        end: endDates[0],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[0].id,
            temperature_sensor_serial_number: temperatureSensorsData[0].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[0][0],
        last_gps_point_end_obj: lastGpsPointEndObj[0][0],
        address_start: lastGpsPointStartObj[0][0].address,
        address_end: lastGpsPointEndObj[0][0].address,
        alarm_id: '1'
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureLow,
        start: startDates[1],
        monitoredObjectId: 101,
        clientId: 8559,
        end: endDates[1],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[0].id,
            temperature_sensor_serial_number: temperatureSensorsData[0].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[0][1],
        last_gps_point_end_obj: lastGpsPointEndObj[0][1],
        address_start: lastGpsPointStartObj[0][1].address,
        address_end: lastGpsPointEndObj[0][1].address,
        alarm_id: '2'
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureHigh,
        start: startDates[0],
        monitoredObjectId: 102,
        clientId: 8559,
        end: endDates[0],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[4].id,
            temperature_sensor_serial_number: temperatureSensorsData[4].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[1][0],
        last_gps_point_end_obj: lastGpsPointEndObj[1][0],
        address_start: lastGpsPointStartObj[1][0].address,
        address_end: lastGpsPointEndObj[1][0].address,
        alarm_id: '3'
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureLow,
        start: startDates[1],
        monitoredObjectId: 102,
        clientId: 8559,
        end: endDates[2],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[4].id,
            temperature_sensor_serial_number: temperatureSensorsData[4].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[1][1],
        last_gps_point_end_obj: lastGpsPointEndObj[1][1],
        address_start: lastGpsPointStartObj[1][1].address,
        address_end: lastGpsPointEndObj[1][1].address,
        alarm_id: '4'
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureHigh,
        start: startDates[0],
        monitoredObjectId: 103,
        clientId: 8559,
        end: endDates[0],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[8].id,
            temperature_sensor_serial_number: temperatureSensorsData[8].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[2][0],
        last_gps_point_end_obj: lastGpsPointEndObj[2][0],
        address_start: lastGpsPointStartObj[2][0].address,
        address_end: lastGpsPointEndObj[2][0].address,
        alarm_id: '5'
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureLow,
        start: startDates[1],
        monitoredObjectId: 103,
        clientId: 8559,
        end: endDates[1],
        acknowledged: null,
        canceled: false,
        unique_data: {
            temperature_sensor_device_id: temperatureSensorsData[8].id,
            temperature_sensor_serial_number: temperatureSensorsData[8].serial_number,
            temperature_sensor_zone: '1'
        },
        last_gps_point_start_obj: lastGpsPointStartObj[2][1],
        last_gps_point_end_obj: lastGpsPointEndObj[2][1],
        address_start: lastGpsPointStartObj[2][1].address,
        address_end: lastGpsPointEndObj[2][1].address,
        alarm_id: '6'
    }
];

export const temperatureSensorAlarmsData = temperatureSensorAlarmsDataRaw.map(e =>
    AlarmInDatabaseWithGPSInfoFromJSON(e)
);

const temperatureProfilesRaw = [
    {
        check_start: moment().subtract(1, 'year').toDate(),
        check_end: moment().subtract(10, 'minutes').toDate(),
        event_rule: eventRules[0]
    },
    {
        check_start: moment().subtract(10, 'minutes').toDate(),
        check_end: moment().add(1, 'year').toDate(),
        event_rule: eventRules[1]
    },
    {
        check_start: moment().subtract(1, 'year').toDate(),
        check_end: moment().add(1, 'year').toDate(),
        event_rule: eventRules[2]
    },
    {
        check_start: moment().subtract(1, 'year').toDate(),
        check_end: moment().subtract(15, 'minutes').toDate(),
        event_rule: eventRules[3]
    },
    {
        check_start: moment().subtract(10, 'minutes').toDate(),
        check_end: moment().add(1, 'year').toDate(),
        event_rule: eventRules[4]
    }
];

export const temperatureProfiles = temperatureProfilesRaw.map(e => ColdChainProfileActiveEventRuleFromJSON(e));

const coldChainProfileListRaw = eventRules.map((er, index) => ({
    id: String(index + 1),
    above_temperature_threshold: er.config.find(e => e.name === 'above_temperature_threshold')?.value ?? null,
    alarm_timer_seconds: er.config.find(e => e.name === 'alarm_timer_seconds')?.value ?? null,
    below_temperature_threshold: er.config.find(e => e.name === 'below_temperature_threshold')?.value ?? null,
    client: 1,
    name: 'Profile ' + String(index + 1)
}));

export const coldChainProfileList = coldChainProfileListRaw.map(e => ReadOnlyProfileSerializerFromJSON(e));
