import { Col, Row } from 'antd';
import { Input } from 'common/components/Form';
import { durationToFormatedWithSeconds } from 'common/utils/actual';
import { TransportPlaceTask } from 'generated/backend-api';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { fileTypeCustom, fileTypeDoc, fileTypePdf, fileTypeXls } from 'resources/images/common';
import DownloadFile from '../DownloadFile/Index';
import PlannerContentContainer from '../PlannerContentContainer';

interface Props extends WithTranslation {
    task: TransportPlaceTask;
    placeDisabled?: boolean;
}

const ActivityMoreDetails = (props: Props) => {
    const handleValueChange = (name: string, value: any) => {
        switch (name) {
            case 'action':
                props.task.action = value;
                break;
        }
    };

    const { t } = props;

    return (
        <PlannerContentContainer
            className="activity-more-details"
            title={t('PlannerPlacesTask.activityDetails')}
            expanded={false}
        >
            <Row>
                <Col span={props.task.driverNote ? 12 : 24} className="instruction-for-driver">
                    <Row>{t('PlannerPlacesTask.instructionsForDriver')}</Row>
                    <Input.TextArea
                        name="action"
                        disabled={props.placeDisabled}
                        defaultValue={props.task.action}
                        onChange={input => {
                            handleValueChange('action', input.target.value);
                        }}
                    />
                </Col>
                {props.task.driverNote && (
                    <Col span={12}>
                        <Row>{t('PlannerPlacesTask.notesFromDriver')}</Row>
                        <Row className="driver-note">{props.task.driverNote}</Row>
                    </Col>
                )}
            </Row>
            {props.task?.payload && (
                <Row className="planner-driver-media">
                    {props.task?.payload
                        .filter(m => m.thumb)
                        .map(m => (
                            <DownloadFile url={m.url} image={m.thumb} />
                        ))}
                    {props.task?.payload
                        .filter(m => !m.thumb)
                        .map((m, index) => {
                            const ext = m.url.substring(m.url.lastIndexOf('.') + 1).toLowerCase();
                            if (ext === 'pdf') {
                                return <DownloadFile key={index} url={m.url} icon={fileTypePdf} />;
                            } else if (['doc', 'docx'].indexOf(ext) > -1) {
                                return <DownloadFile key={index} url={m.url} icon={fileTypeDoc} />;
                            } else if (['xls', 'xlsx'].indexOf(ext) > -1) {
                                return <DownloadFile key={index} url={m.url} icon={fileTypeXls} />;
                            } else {
                                return <DownloadFile key={index} url={m.url} icon={fileTypeCustom} />;
                            }
                        })}
                </Row>
            )}
            <Row className="activity-task-loading-time">
                {props.task.activityStartTime && (
                    <Col className="col-table">
                        <Row>{t('PlannerPlacesTask.startTime').toUpperCase()}</Row>
                        <Row>{moment(props.task.activityStartTime).format('L LT')}</Row>
                    </Col>
                )}
                {props.task.activityEndTime && (
                    <Col className="col-table">
                        <Row>{props.t('PlannerPlacesTask.endTime').toUpperCase()}</Row>
                        <Row>{moment(props.task.activityEndTime).format('L LT')}</Row>
                    </Col>
                )}
                {props.task.activityStartTime && props.task.activityEndTime && (
                    <Col className="col-table">
                        <Row>{props.t('PlannerPlacesTask.duration').toUpperCase()}</Row>
                        <Row>
                            {durationToFormatedWithSeconds(
                                moment.duration(
                                    moment(props.task.activityEndTime).diff(moment(props.task.activityStartTime))
                                )
                            )}
                        </Row>
                    </Col>
                )}
            </Row>
        </PlannerContentContainer>
    );
};

export default withTranslation()(ActivityMoreDetails);
