import { MouseEvent } from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ChannelDriverInfo } from '../MessagingModule';
import { Col, Row } from 'antd';
import { Button, Dropdown, Menu } from 'common/components';
import Search from 'common/components/Search';
import ButtonHelper from 'common/components/ButtonHelper';
import qa from 'qa-selectors';
import * as messagingIcons from 'resources/images/messaging';

interface Props extends WithTranslation {
    channels: ChannelDriverInfo[];
    checkedChannels?: string[];
    selectedChannel?: string;
    search?: string;
    onSelect?: (channel: ChannelDriverInfo) => void;
    onSearchClick?: () => void;
    onSearch?: (text: string) => void;
    onGroupMessageClick?: () => void;
    onCheck?: (channel: ChannelDriverInfo) => void;
    onUncheck?: (channel: ChannelDriverInfo) => void;
    onLastMessageUnsee?: (channel: ChannelDriverInfo) => void;
    onBarHelperClick?: () => void;
    demoMode?: boolean;
}

function MessagingChannels(props: Props) {
    function onChannelClick(channel: ChannelDriverInfo, e: MouseEvent<HTMLSpanElement>) {
        e.stopPropagation();
        if (props.checkedChannels) {
            if (props.checkedChannels.includes(channel.channel.id)) {
                props.onUncheck?.(channel);
            } else {
                props.onCheck?.(channel);
            }
        } else {
            if (channel.channel.id !== props.selectedChannel) {
                props.onSelect?.(channel);
            }
        }
    }

    function onSearchClick(e: MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        props.onSearchClick?.();
    }

    function onCheckClick(e: MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        props.onGroupMessageClick?.();
    }

    return (
        <div className="messaging-channels">
            <Row className="messaging-bar" align="middle" justify="space-between" gutter={10}>
                <Col flex="auto" className="messaging-channels-head-item">
                    {props.t('common.drivers')}
                </Col>
                <Col>
                    <ButtonHelper onClick={props.onBarHelperClick} />
                </Col>
                <Col>
                    <Row gutter={10}>
                        <Col>
                            <Button
                                type={props.checkedChannels ? 'primary' : 'link'}
                                size="small"
                                onClick={onCheckClick}
                                icon={<i className="fa fa-check" />}
                                qa={qa.messaging.channels.btnSelectChannels}
                            />
                        </Col>
                        <Col>
                            <Button
                                type={props.search === undefined ? 'link' : 'primary'}
                                size="small"
                                onClick={onSearchClick}
                                icon={<i className="fa fa-search" />}
                                disabled={props.demoMode}
                                qa={qa.messaging.channels.btnSearch}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {props.search !== undefined && (
                <div className="messaging-search">
                    <Search
                        searchActive={true}
                        onSearch={props.onSearch}
                        qa={qa.messaging.channels.inputChannelSearch}
                    />
                </div>
            )}
            <div className="messaging-channels-container">
                {props.channels.map(
                    c =>
                        c.driver && (
                            <Dropdown
                                key={c.channel.id}
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            key="unsee-last-message"
                                            onClick={() => props.onLastMessageUnsee?.(c)}
                                            data-qa={qa.messaging.channels.btnUnseeLastMessage}
                                            disabled={props.demoMode}
                                        >
                                            <img src={messagingIcons.messageUnread} alt="" />{' '}
                                            {props.t('MessagingUsers.markLastMessageAsUnread')}
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={[`contextMenu`]}
                            >
                                <Row
                                    align="middle"
                                    className={cn('pointer', {
                                        selected: props.selectedChannel === c.channel.id ? 1 : 0
                                    })}
                                    key={c.channel.id}
                                    onClick={onChannelClick.bind(undefined, c)}
                                    data-qa={qa.messaging.channels.sectionChannel}
                                >
                                    <Col>
                                        {props.checkedChannels ? (
                                            props.checkedChannels.includes(c.channel.id) ? (
                                                <i
                                                    className="messaging-check-icon fa fa-check-circle"
                                                    data-qa={qa.messaging.channels.btnChecked}
                                                />
                                            ) : (
                                                <i
                                                    className="messaging-circle-icon fa fa-circle"
                                                    data-qa={qa.messaging.channels.btnEmpty}
                                                />
                                            )
                                        ) : (
                                            <i className="messaging-user-icon fa fa-user-circle" />
                                        )}
                                        {!props.checkedChannels && c.channel.newMsgCount > 0 && (
                                            <span
                                                className="messaging-new-badge t-badge t-secondary"
                                                data-qa={qa.messaging.channels.fieldUnreadCount}
                                            >
                                                {c.channel.newMsgCount}
                                            </span>
                                        )}
                                    </Col>
                                    <Col data-qa={qa.messaging.channels.fieldName}>
                                        {c.driver ? `${c.driver.surname} ${c.driver.name}` : c.channel.creator}
                                    </Col>
                                </Row>
                            </Dropdown>
                        )
                )}
            </div>
        </div>
    );
}

export default withTranslation()(MessagingChannels);
