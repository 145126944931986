/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BorderCrossingEntry,
    BorderCrossingEntryFromJSON,
    BorderCrossingEntryToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ManualCountryIntervalInDb,
    ManualCountryIntervalInDbFromJSON,
    ManualCountryIntervalInDbToJSON,
} from '../models';

export interface CreateManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRequest {
    driverId: number;
    dateFrom: Date;
    dateTo: Date;
    countryEntry: string;
    countryExit: string;
}

export interface DeleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDeleteRequest {
    countryIntervalId: string;
}

export interface GetBorderCrossingsApiV1BordercrossingsGetRequest {
    driverId?: number;
    clientId?: number;
    dateFrom?: Date;
    dateTo?: Date;
    countries?: Array<string>;
    wrapArray?: boolean;
    monitoredObjectId?: string;
}

export interface GetManualCountryIntervalV1BordercrossingsManualCountryIntervalGetRequest {
    driverId: number;
    dateFrom: Date;
    dateTo: Date;
}

/**
 * no description
 */
export class BorderCrossingApi extends runtime.BaseAPI {

    /**
     * Create Manual Country Interval
     */
    async createManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRaw(requestParameters: CreateManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRequest): Promise<runtime.ApiResponse<ManualCountryIntervalInDb>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost.');
        }

        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost.');
        }

        if (requestParameters.countryEntry === null || requestParameters.countryEntry === undefined) {
            throw new runtime.RequiredError('countryEntry','Required parameter requestParameters.countryEntry was null or undefined when calling createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost.');
        }

        if (requestParameters.countryExit === null || requestParameters.countryExit === undefined) {
            throw new runtime.RequiredError('countryExit','Required parameter requestParameters.countryExit was null or undefined when calling createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.countryEntry !== undefined) {
            queryParameters['country_entry'] = requestParameters.countryEntry;
        }

        if (requestParameters.countryExit !== undefined) {
            queryParameters['country_exit'] = requestParameters.countryExit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/bordercrossings/manual_country_interval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualCountryIntervalInDbFromJSON(jsonValue));
    }

    /**
     * Create Manual Country Interval
     */
    async createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost(requestParameters: CreateManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRequest): Promise<ManualCountryIntervalInDb> {
        const response = await this.createManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete Manual Country Interval
     */
    async deleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDeleteRaw(requestParameters: DeleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDeleteRequest): Promise<runtime.ApiResponse<ManualCountryIntervalInDb>> {
        if (requestParameters.countryIntervalId === null || requestParameters.countryIntervalId === undefined) {
            throw new runtime.RequiredError('countryIntervalId','Required parameter requestParameters.countryIntervalId was null or undefined when calling deleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryIntervalId !== undefined) {
            queryParameters['country_interval_id'] = requestParameters.countryIntervalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/bordercrossings/manual_country_interval`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualCountryIntervalInDbFromJSON(jsonValue));
    }

    /**
     * Delete Manual Country Interval
     */
    async deleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDelete(requestParameters: DeleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDeleteRequest): Promise<ManualCountryIntervalInDb> {
        const response = await this.deleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Border Crossings Api
     */
    async getBorderCrossingsApiV1BordercrossingsGetRaw(requestParameters: GetBorderCrossingsApiV1BordercrossingsGetRequest): Promise<runtime.ApiResponse<Array<BorderCrossingEntry>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.countries) {
            queryParameters['countries'] = requestParameters.countries;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/bordercrossings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorderCrossingEntryFromJSON));
    }

    /**
     * Get Border Crossings Api
     */
    async getBorderCrossingsApiV1BordercrossingsGet(requestParameters: GetBorderCrossingsApiV1BordercrossingsGetRequest): Promise<Array<BorderCrossingEntry>> {
        const response = await this.getBorderCrossingsApiV1BordercrossingsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Manual Country Interval
     */
    async getManualCountryIntervalV1BordercrossingsManualCountryIntervalGetRaw(requestParameters: GetManualCountryIntervalV1BordercrossingsManualCountryIntervalGetRequest): Promise<runtime.ApiResponse<Array<ManualCountryIntervalInDb>>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getManualCountryIntervalV1BordercrossingsManualCountryIntervalGet.');
        }

        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getManualCountryIntervalV1BordercrossingsManualCountryIntervalGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling getManualCountryIntervalV1BordercrossingsManualCountryIntervalGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/bordercrossings/manual_country_interval`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManualCountryIntervalInDbFromJSON));
    }

    /**
     * Get Manual Country Interval
     */
    async getManualCountryIntervalV1BordercrossingsManualCountryIntervalGet(requestParameters: GetManualCountryIntervalV1BordercrossingsManualCountryIntervalGetRequest): Promise<Array<ManualCountryIntervalInDb>> {
        const response = await this.getManualCountryIntervalV1BordercrossingsManualCountryIntervalGetRaw(requestParameters);
        return await response.value();
    }

}
