/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PoiPolygonType {
    Circle = 'circle',
    Custom = 'custom'
}

export function PoiPolygonTypeFromJSON(json: any): PoiPolygonType {
    return PoiPolygonTypeFromJSONTyped(json, false);
}

export function PoiPolygonTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiPolygonType {
    return json as PoiPolygonType;
}

export function PoiPolygonTypeToJSON(value?: PoiPolygonType | null): any {
    return value as any;
}

