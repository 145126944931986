import { Col, Divider, Row } from 'antd';
import { Button } from 'common/components';
import { SelectOption } from 'common/components/Form/Select';
import { CheckBoxField, SelectField, SwitchField } from 'common/fields';
import { Form, Formik, FormikProps } from 'formik';
import { ExternalSystemSecretMetadata } from 'generated/new-main/models';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export interface PuescRegistrationModel {
    externalSystemId?: string | undefined;
    vehicleSelectionType: 'singleVehicle' | 'vehicleGroup';
    automaticVehicles: boolean;
    vehicles?: number[];
    vehiclesGroup?: number[];
    metadata?: ExternalSystemSecretMetadata;
}

interface Props {
    initialValues?: Partial<PuescRegistrationModel>;
    vehicleOptions: SelectOption[];
    vehicleGroupsOptions: SelectOption[];
    onSubmit: (values: PuescRegistrationModel) => Promise<boolean>;
    onCancel?: () => void;
}

function PuescConnectionForm(props: Props) {
    const { t } = useTranslation();

    const schema = Yup.object().shape({});

    function handleSubmit(values: PuescRegistrationModel) {
        return props.onSubmit?.(values);
    }

    return (
        <Formik<PuescRegistrationModel>
            initialValues={{
                externalSystemId: props.initialValues?.externalSystemId ?? '',
                automaticVehicles: props.initialValues?.automaticVehicles ?? false,
                vehicleSelectionType: props.initialValues?.vehicleSelectionType ?? 'singleVehicle',
                vehicles: props.initialValues?.vehicles ?? [],
                vehiclesGroup: props.initialValues?.vehiclesGroup ?? [],
                metadata: props.initialValues?.metadata
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<PuescRegistrationModel>) => {
                return (
                    <Form className="puesc-registration-form">
                        <Row className="form-row" gutter={[20, 10]}>
                            <Col span={24}>
                                <label className="puesc-registration-form-title">
                                    {t('SystemConnectForm.serviceAndFormsActivation')}
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="puesc-registration-form-metadata">
                                    <div className="left">
                                        <div className="title">{t('SystemConnectForm.puescActivation')}</div>
                                        <div className="description">
                                            {t('SystemConnectForm.peuscActivationContent')}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <SwitchField
                                            defaultChecked={props.initialValues?.metadata?.puescActive}
                                            name="metadata.puescActive"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="puesc-registration-form-metadata">
                                    <div className="left">
                                        <div className="title">{t('SystemConnectForm.sentData')}</div>
                                        <div className="description">{t('SystemConnectForm.sentDataContent')}</div>
                                    </div>
                                    <div className="right">
                                        <SwitchField
                                            defaultChecked={props.initialValues?.metadata?.puescDataAlwaysOn}
                                            name="metadata.puescDataAlwaysOn"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-row" gutter={[20, 10]}>
                            <Col span={24}>
                                <label className="puesc-registration-form-title">
                                    {t('SystemConnectForm.formTitle')}
                                </label>
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[10, 20]}>
                            <Col>
                                <CheckBoxField name="automaticVehicles">
                                    {t('SystemConnectForm.autoVehicleToGroup')}
                                </CheckBoxField>
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[10, 20]}>
                            <Col span={24}>
                                <SelectField
                                    mode="multiple"
                                    options={props.vehicleOptions}
                                    defaultValue={props.initialValues?.vehicles}
                                    name="vehicles"
                                    label={t('SystemConnectForm.vehicle')}
                                    placeholder={t('SystemConnectForm.vehicle')}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[10, 20]}>
                            <Col span={24}>
                                <SelectField
                                    mode="multiple"
                                    options={props.vehicleGroupsOptions}
                                    defaultValue={props.initialValues?.vehiclesGroup}
                                    name="vehiclesGroup"
                                    label={t('SystemConnectForm.vehicleGroups')}
                                    placeholder={t('SystemConnectForm.vehicleGroups')}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row className="form-buttons">
                            <Button onClick={props.onCancel}>{t('common.cancel')}</Button>
                            <Button
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting}
                                type="primary"
                                htmlType="submit"
                            >
                                {t('common.confirm')}
                            </Button>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default PuescConnectionForm;
