/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserTokenUserNested,
    UserTokenUserNestedFromJSON,
    UserTokenUserNestedFromJSONTyped,
    UserTokenUserNestedToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserTokenCreate
 */
export interface UserTokenCreate {
    /**
     * 
     * @type {string}
     * @memberof UserTokenCreate
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof UserTokenCreate
     */
    tokenType: UserTokenCreateTokenTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserTokenCreate
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserTokenCreate
     */
    endTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserTokenCreate
     */
    clientId?: number;
    /**
     * 
     * @type {UserTokenUserNested}
     * @memberof UserTokenCreate
     */
    user?: UserTokenUserNested;
    /**
     * 
     * @type {string}
     * @memberof UserTokenCreate
     */
    holder?: string;
    /**
     * 
     * @type {number}
     * @memberof UserTokenCreate
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserTokenCreate
     */
    userId?: number | null;
}

export function UserTokenCreateFromJSON(json: any): UserTokenCreate {
    return UserTokenCreateFromJSONTyped(json, false);
}

export function UserTokenCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTokenCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'tokenType': json['token_type'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'user': !exists(json, 'user') ? undefined : UserTokenUserNestedFromJSON(json['user']),
        'holder': !exists(json, 'holder') ? undefined : json['holder'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function UserTokenCreateToJSON(value?: UserTokenCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'token_type': value.tokenType,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'client_id': value.clientId,
        'user': UserTokenUserNestedToJSON(value.user),
        'holder': value.holder,
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserTokenCreateTokenTypeEnum {
    Ibutton = 'ibutton ',
    Rfid = 'rfid',
    DriverCard = 'driver_card',
    Pin = 'pin',
    App = 'app',
    DefaultDriver = 'default_driver'
}


