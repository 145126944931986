/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectMetadataProfileMergedItemFuelType
 */
export interface MonitoredObjectMetadataProfileMergedItemFuelType {
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObjectMetadataProfileMergedItemFuelType
     */
    md: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectMetadataProfileMergedItemFuelType
     */
    v?: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum;
}

export function MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSON(json: any): MonitoredObjectMetadataProfileMergedItemFuelType {
    return MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSONTyped(json, false);
}

export function MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectMetadataProfileMergedItemFuelType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'md': json['md'],
        'v': !exists(json, 'v') ? undefined : json['v'],
    };
}

export function MonitoredObjectMetadataProfileMergedItemFuelTypeToJSON(value?: MonitoredObjectMetadataProfileMergedItemFuelType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'md': value.md,
        'v': value.v,
    };
}

/**
* @export
* @enum {string}
*/
export enum MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum {
    DIESEL = 'DIESEL',
    GASOLINE = 'GASOLINE',
    LNGCNG = 'LNG_CNG',
    ELECTRO = 'ELECTRO',
    HYBRID = 'HYBRID',
    HYDROGEN = 'HYDROGEN',
    Empty = ''
}


