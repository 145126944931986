import LinkedItem, { LinkedItemType } from 'common/components/LinkedItem';
import { ReadOnlyFuelCardSerializer } from 'generated/new-main';
import { Role } from 'logic/auth';
import { DriverModel } from 'logic/user/users';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FleetModel } from 'modules/management/modules/fleet/FleetModule';
import { FuelCardsLinks, UnpairType } from '../FuelCardsModule';

interface Props extends WithTranslation {
    model: ReadOnlyFuelCardSerializer;
    drivers: DriverModel[];
    monitoredObjects: FleetModel[];
    demoMode?: boolean;
    roles: Role[];
    onUnpair?: (model: UnpairType, type: keyof FuelCardsLinks) => void;
}

function FuelCardsLinkedItems(props: Props) {
    function onUnpairButtonClick(model: UnpairType, type: keyof FuelCardsLinks): void {
        props.onUnpair?.(model, type);
    }

    const { t, model } = props;

    const monitoredObject = props.monitoredObjects.find(d => d.id === props.model?.monitoredObject);
    const driver = props.drivers.find(d => d.id === String(props.model?.user));

    return (
        <>
            {monitoredObject && (
                <LinkedItem
                    parentId={String(props.model.id)}
                    typeName={t('ManagementFuelCards.vehicle')}
                    type={LinkedItemType.VEHICLE}
                    disabled={props.demoMode || !props.roles.includes(Role.EX_W)}
                    items={[
                        {
                            label: t('common.rn'),
                            value:
                                props.monitoredObjects.find(d => d.id === props.model?.monitoredObject)
                                    ?.registrationNumber ?? '',
                            qa: qa.fuel.linkedItems.fieldRn
                        },
                        {
                            label: t('common.type'),
                            value: t(`Partner.${monitoredObject?.type}`),
                            qa: qa.fuel.linkedItems.fieldType
                        }
                    ]}
                    qa={qa.fuel.linkedItems.sectionVehicle}
                    onUnpairClick={onUnpairButtonClick.bind(undefined, model, 'fleet')}
                />
            )}

            {driver && (
                <LinkedItem
                    parentId={String(props.model.id)}
                    typeName={t('common.user')}
                    type={LinkedItemType.USER}
                    disabled={props.demoMode || !props.roles.includes(Role.EX_W)}
                    items={[
                        {
                            label: t('common.name'),
                            value: `${driver?.name} ${driver?.surname}` ?? '',
                            qa: qa.fuel.linkedItems.fieldName
                        }
                    ]}
                    qa={qa.fuel.linkedItems.sectionUser}
                    onUnpairClick={onUnpairButtonClick.bind(undefined, model, 'user')}
                />
            )}
        </>
    );
}

export default withTranslation()(FuelCardsLinkedItems);
