import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import InputField from '../../fields/Input';
import qa from 'qa-selectors';
import { ReadOnlyCurrency, TCOInput, TCOSettingsOutput } from 'generated/new-main';

const autoFillKeyMap = {
    'dieselVehicleDetails.purchaseCost': {
        to: 'lngVehicleDetails.purchaseCost',
        description: 'Purchase cost: + 30 000 EUR',
        second: {
            to: 'lngVehicleDetails.annualInsuranceCost',
            description: 'Annual insurance cost: 2% z pořizovací hodnoty LNG vozu'
        },
        third: {
            to: 'dieselVehicleDetails.annualInsuranceCost',
            description: 'Annual insurance cost: 2% z pořizovací hodnoty diesel vozu'
        }
    },
    'dieselVehicleDetails.annualTollCost': {
        to: 'lngVehicleDetails.annualTollCost',
        description: 'Annual toll cost: = Diesel hodnota'
    },
    'dieselVehicleDetails.annualServiceAndMaintenanceCost': {
        to: 'lngVehicleDetails.annualServiceAndMaintenanceCost',
        description: 'Annual service and maintenance cost: + 15% proti Dieselu'
    },
    'dieselVehicleDetails.fuelAverageConsumption': {
        to: 'lngVehicleDetails.fuelAverageConsumption',
        description: 'Fuel average consumption: - 10% proti Dieselu'
    }
};

interface Props extends WithTranslation {
    lang: string;
    tcoSettings: Omit<TCOSettingsOutput, 'constants'>;
    currency?: ReadOnlyCurrency;
    initialValues: TCOInput;
    loading: boolean;
    onSubmit: (values: TCOInput) => void;
}

function TotalCostOfOwnershipForm({ onSubmit, initialValues, t, ...props }: Props) {
    const units = {
        '&euro;': '€',
        years: t('years')
    };

    const getSchema = () => {
        const shapeObject = {};
        Object.keys(props.tcoSettings).forEach(key => {
            const typedKey = key as keyof typeof props.tcoSettings;
            if (['integer', 'float'].includes(props.tcoSettings[typedKey].type)) {
                if (props.tcoSettings[typedKey].required) {
                    if (typedKey === 'operationalLifetime') {
                        shapeObject[key] = Yup.number().max(10).required(t('common.required'));
                    } else {
                        shapeObject[key] = Yup.number().required(t('common.required'));
                    }
                } else {
                    shapeObject[key] = Yup.number();
                }
            }
            if (['array'].includes(props.tcoSettings[typedKey].type)) {
                const childShapeObject = {};
                // @ts-ignore
                const values = props.tcoSettings[typedKey].values;
                Object.keys(values).forEach(childKey => {
                    const childObject = values[childKey];
                    if (childObject) {
                        if (['integer', 'float'].includes(childObject.type)) {
                            if (childObject.required) {
                                childShapeObject[childKey] = Yup.number().required(t('common.required'));
                            } else {
                                childShapeObject[childKey] = Yup.number();
                            }
                        }
                    } else {
                        // idk what here. we have undefined settings but required in post??
                        childShapeObject[childKey] = Yup.number().required(t('common.required'));
                    }
                });
                shapeObject[key] = Yup.object().shape(childShapeObject);
            }
        });

        return Yup.object(shapeObject);
    };

    function handleOnSubmit(values: TCOInput) {
        onSubmit?.(values);
    }

    function handleAutoFill(
        name: string,
        value: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ): void {
        const typedKey = name as keyof typeof autoFillKeyMap;
        switch (typedKey) {
            case 'dieselVehicleDetails.purchaseCost':
                setFieldValue(autoFillKeyMap[typedKey].to, value + 30000);
                setFieldValue(autoFillKeyMap[typedKey].second.to, (value + 30000) * 0.02);
                setFieldValue(autoFillKeyMap[typedKey].third.to, value * 0.02);
                break;
            case 'dieselVehicleDetails.annualTollCost':
                setFieldValue(autoFillKeyMap[typedKey].to, value);
                break;
            case 'dieselVehicleDetails.annualServiceAndMaintenanceCost':
                setFieldValue(autoFillKeyMap[typedKey].to, value + value * 0.15);
                break;
            case 'dieselVehicleDetails.fuelAverageConsumption':
                setFieldValue(autoFillKeyMap[typedKey].to, value - value * 0.1);
                break;
        }
    }

    return (
        <Formik<TCOInput>
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={getSchema()}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<TCOInput>) => (
                <div className="total-cost-of-ownership-form">
                    <Form>
                        {Object.keys(props.tcoSettings)
                            .sort((a, b) => {
                                if (
                                    ['string', 'number'].includes(typeof initialValues[a]) &&
                                    !['string', 'number'].includes(typeof initialValues[b])
                                )
                                    return -1;
                                if (
                                    !['string', 'number'].includes(typeof initialValues[a]) &&
                                    ['string', 'number'].includes(typeof initialValues[b])
                                )
                                    return 1;
                                return a > b ? 1 : -1;
                            })
                            .map((key, index) => {
                                const typedKey = key as keyof typeof props.tcoSettings;
                                if (['string', 'number'].includes(typeof initialValues[key])) {
                                    const infoContent = props.tcoSettings[typedKey].hint
                                        ? t(`TotalCostOfOwnership.form.${typedKey}.hint`)
                                        : undefined;
                                    return (
                                        <div key={`tco-form-${key}-${index}`}>
                                            <div>
                                                <h3>{t(`TotalCostOfOwnership.form.${typedKey}.title`)}</h3>
                                            </div>
                                            <Row gutter={12}>
                                                <Col xs={24} sm={12} md={8} lg={6} className="info-col">
                                                    <InputField.Number
                                                        name={typedKey}
                                                        label={`${t(`TotalCostOfOwnership.form.${typedKey}.title`)} / ${
                                                            units[props.tcoSettings[typedKey].units] ??
                                                            props.tcoSettings[typedKey].units
                                                        }`}
                                                        qa={qa.totalCostOfOwnership.form[`${typedKey}_${key}`]}
                                                        onChange={val =>
                                                            handleAutoFill(typedKey, val, formik.setFieldValue)
                                                        }
                                                    />
                                                    {infoContent && (
                                                        <Tooltip className="tooltip" title={infoContent}>
                                                            <i className="fa fa-question" />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }

                                return (
                                    <div key={typedKey}>
                                        <div>
                                            <h3>{t(`TotalCostOfOwnership.form.${typedKey}.title`)}</h3>
                                        </div>
                                        <Row gutter={12}>
                                            {Object.keys(props.tcoSettings[typedKey]['values']).map((key, index) => {
                                                const infoContent = props.tcoSettings[typedKey]['values'][key].hint
                                                    ? t(`TotalCostOfOwnership.form.${typedKey}.${key}.hint`)
                                                    : undefined;
                                                return (
                                                    <Col
                                                        key={`col-${typedKey}-${key}-${index}`}
                                                        xs={24}
                                                        sm={12}
                                                        md={8}
                                                        lg={6}
                                                        className="info-col"
                                                        hidden={!props.tcoSettings[typedKey]['values'][key].visible}
                                                    >
                                                        <InputField.Number
                                                            hidden={key === 'gCo2PerUnitProduction'}
                                                            name={`${typedKey}.${key}`}
                                                            label={`${t(
                                                                `TotalCostOfOwnership.form.${typedKey}.${key}.title`
                                                            )} / ${
                                                                units[
                                                                    props.tcoSettings[typedKey]['values'][key].units
                                                                ] ?? props.tcoSettings[typedKey]['values'][key].units
                                                            }`}
                                                            qa={qa.totalCostOfOwnership.form[`${typedKey}_${key}`]}
                                                            onChange={val =>
                                                                handleAutoFill(
                                                                    `${typedKey}.${key}`,
                                                                    val,
                                                                    formik.setFieldValue
                                                                )
                                                            }
                                                        />
                                                        {infoContent && (
                                                            <Tooltip className="tooltip" title={infoContent}>
                                                                <i className="fa fa-question" />
                                                            </Tooltip>
                                                        )}
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </div>
                                );
                            })}
                        <Row gutter={4} justify="space-between" className="total-cost-of-ownership-form-calculate">
                            <Col>
                                <Button
                                    loading={props.loading}
                                    type="primary"
                                    onClick={formik.submitForm}
                                    qa={qa.totalCostOfOwnership.btnCalculate}
                                >
                                    {t('common.calculate')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default withTranslation()(TotalCostOfOwnershipForm);
