import { ReadOnlyMonitoredObjectType } from 'generated/new-main';
import { Logic } from 'logic/logic';

export class VehicleTypes {
    vehicleTypes: ReadOnlyMonitoredObjectType[];

    constructor(private _logic: Logic) {
        this.vehicleTypes = [];
    }

    async getMonitoredObjectsTypes(): Promise<ReadOnlyMonitoredObjectType[]> {
        try {
            if (this.vehicleTypes.length === 0) {
                const resp = await this._logic.api().monitoredObjectTypeApi.monitoredObjectTypeList({});
                this.vehicleTypes = resp.results;
            }
            return this.vehicleTypes;
        } catch (err) {
            console.error('Monitored object type GET err:', err);
            throw err;
        }
    }
}
