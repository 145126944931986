/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20012,
    InlineResponse20012FromJSON,
    InlineResponse20012ToJSON,
    ReadOnlyExternalSystemAccess,
    ReadOnlyExternalSystemAccessFromJSON,
    ReadOnlyExternalSystemAccessToJSON,
    WriteOnlyExternalSystemAccess,
    WriteOnlyExternalSystemAccessFromJSON,
    WriteOnlyExternalSystemAccessToJSON,
} from '../models';

export interface ExternalSystemAccessCreateRequest {
    data: WriteOnlyExternalSystemAccess;
}

export interface ExternalSystemAccessDeleteRequest {
    id: string;
}

export interface ExternalSystemAccessListRequest {
    deleted?: number;
    id?: string;
    accessType?: string;
    externalSystemName?: string;
    externalSystemVersion?: string;
    createdAt?: string;
    fetchType?: string;
    limit?: number;
    offset?: number;
}

export interface ExternalSystemAccessPartialUpdateRequest {
    id: string;
    data: WriteOnlyExternalSystemAccess;
}

export interface ExternalSystemAccessReadRequest {
    id: string;
}

export interface ExternalSystemAccessUpdateRequest {
    id: string;
    data: WriteOnlyExternalSystemAccess;
}

/**
 * no description
 */
export class ExternalSystemAccessApi extends runtime.BaseAPI {

    /**
     */
    async externalSystemAccessCreateRaw(requestParameters: ExternalSystemAccessCreateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemAccess>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemAccessCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemAccessToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemAccessFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessCreate(requestParameters: ExternalSystemAccessCreateRequest): Promise<ReadOnlyExternalSystemAccess> {
        const response = await this.externalSystemAccessCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemAccessDeleteRaw(requestParameters: ExternalSystemAccessDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemAccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemAccessDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemAccessFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessDelete(requestParameters: ExternalSystemAccessDeleteRequest): Promise<ReadOnlyExternalSystemAccess> {
        const response = await this.externalSystemAccessDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemAccessListRaw(requestParameters: ExternalSystemAccessListRequest): Promise<runtime.ApiResponse<InlineResponse20012>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.accessType !== undefined) {
            queryParameters['access_type'] = requestParameters.accessType;
        }

        if (requestParameters.externalSystemName !== undefined) {
            queryParameters['external_system_name'] = requestParameters.externalSystemName;
        }

        if (requestParameters.externalSystemVersion !== undefined) {
            queryParameters['external_system_version'] = requestParameters.externalSystemVersion;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.fetchType !== undefined) {
            queryParameters['fetch_type'] = requestParameters.fetchType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20012FromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessList(requestParameters: ExternalSystemAccessListRequest): Promise<InlineResponse20012> {
        const response = await this.externalSystemAccessListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemAccessPartialUpdateRaw(requestParameters: ExternalSystemAccessPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemAccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemAccessPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemAccessPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemAccessToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemAccessFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessPartialUpdate(requestParameters: ExternalSystemAccessPartialUpdateRequest): Promise<ReadOnlyExternalSystemAccess> {
        const response = await this.externalSystemAccessPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemAccessReadRaw(requestParameters: ExternalSystemAccessReadRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemAccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemAccessRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemAccessFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessRead(requestParameters: ExternalSystemAccessReadRequest): Promise<ReadOnlyExternalSystemAccess> {
        const response = await this.externalSystemAccessReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemAccessUpdateRaw(requestParameters: ExternalSystemAccessUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemAccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemAccessUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemAccessUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-access/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemAccessToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemAccessFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemAccessUpdate(requestParameters: ExternalSystemAccessUpdateRequest): Promise<ReadOnlyExternalSystemAccess> {
        const response = await this.externalSystemAccessUpdateRaw(requestParameters);
        return await response.value();
    }

}
