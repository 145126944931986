/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DevicesPositionResponse,
    DevicesPositionResponseFromJSON,
    DevicesPositionResponseToJSON,
    DevicesPositionResponseP44,
    DevicesPositionResponseP44FromJSON,
    DevicesPositionResponseP44ToJSON,
    DiscoverDevicesResponse,
    DiscoverDevicesResponseFromJSON,
    DiscoverDevicesResponseToJSON,
    VersionResponse,
    VersionResponseFromJSON,
    VersionResponseToJSON,
} from '../models';

export interface DataAtlasDevicePositionsRequest {
    clientId: string;
}

export interface DataAtlasDiscoverDevicesRequest {
    clientId: string;
}

export interface DataDuvenbeckDevicePositionsRequest {
    clientId: string;
}

export interface DataDuvenbeckDiscoverDevicesRequest {
    clientId: string;
}

export interface DataETrackDevicePositionsRequest {
    clientId: string;
}

export interface DataETrackDiscoverDevicesRequest {
    clientId: string;
}

export interface DataLkwwalterDevicePositionsRequest {
    clientId: string;
}

export interface DataLkwwalterDiscoverDevicesRequest {
    clientId: string;
}

export interface DataProject44DevicePositionsRequest {
    clientId: string;
}

export interface DataSixfoldDevicePositionsRequest {
    clientId: string;
}

export interface DataSixfoldDiscoverDevicesRequest {
    clientId: string;
}

export interface DataTranseuDevicePositionsRequest {
    clientId: string;
}

export interface DataTranseuDiscoverDevicesRequest {
    clientId: string;
}

export interface DataTransportManagerDevicePositionsRequest {
    clientId: string;
}

export interface DataTransportManagerDiscoverDevicesRequest {
    clientId: string;
}

/**
 * no description
 */
export class DataApi extends runtime.BaseAPI {

    /**
     */
    async dataAtlasDevicePositionsRaw(requestParameters: DataAtlasDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataAtlasDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/atlas/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataAtlasDevicePositions(requestParameters: DataAtlasDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataAtlasDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataAtlasDiscoverDevicesRaw(requestParameters: DataAtlasDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataAtlasDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/atlas/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataAtlasDiscoverDevices(requestParameters: DataAtlasDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataAtlasDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataAtlasVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/atlas/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataAtlasVersion(): Promise<VersionResponse> {
        const response = await this.dataAtlasVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataDuvenbeckDevicePositionsRaw(requestParameters: DataDuvenbeckDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataDuvenbeckDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/duvenbeck/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataDuvenbeckDevicePositions(requestParameters: DataDuvenbeckDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataDuvenbeckDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataDuvenbeckDiscoverDevicesRaw(requestParameters: DataDuvenbeckDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataDuvenbeckDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/duvenbeck/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataDuvenbeckDiscoverDevices(requestParameters: DataDuvenbeckDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataDuvenbeckDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataDuvenbeckVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/duvenbeck/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataDuvenbeckVersion(): Promise<VersionResponse> {
        const response = await this.dataDuvenbeckVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataETrackDevicePositionsRaw(requestParameters: DataETrackDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataETrackDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/e-track/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataETrackDevicePositions(requestParameters: DataETrackDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataETrackDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataETrackDiscoverDevicesRaw(requestParameters: DataETrackDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataETrackDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/e-track/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataETrackDiscoverDevices(requestParameters: DataETrackDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataETrackDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataETrackVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/e-track/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataETrackVersion(): Promise<VersionResponse> {
        const response = await this.dataETrackVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataLkwwalterDevicePositionsRaw(requestParameters: DataLkwwalterDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataLkwwalterDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/lkwwalter/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataLkwwalterDevicePositions(requestParameters: DataLkwwalterDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataLkwwalterDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataLkwwalterDiscoverDevicesRaw(requestParameters: DataLkwwalterDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataLkwwalterDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/lkwwalter/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataLkwwalterDiscoverDevices(requestParameters: DataLkwwalterDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataLkwwalterDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataLkwwalterVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/lkwwalter/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataLkwwalterVersion(): Promise<VersionResponse> {
        const response = await this.dataLkwwalterVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataProject44DevicePositionsRaw(requestParameters: DataProject44DevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponseP44>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataProject44DevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/project44/{client_id}/positions/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseP44FromJSON(jsonValue));
    }

    /**
     */
    async dataProject44DevicePositions(requestParameters: DataProject44DevicePositionsRequest): Promise<DevicesPositionResponseP44> {
        const response = await this.dataProject44DevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataSixfoldDevicePositionsRaw(requestParameters: DataSixfoldDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataSixfoldDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/sixfold/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataSixfoldDevicePositions(requestParameters: DataSixfoldDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataSixfoldDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataSixfoldDiscoverDevicesRaw(requestParameters: DataSixfoldDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataSixfoldDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/sixfold/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataSixfoldDiscoverDevices(requestParameters: DataSixfoldDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataSixfoldDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataSixfoldVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/sixfold/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataSixfoldVersion(): Promise<VersionResponse> {
        const response = await this.dataSixfoldVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataTranseuDevicePositionsRaw(requestParameters: DataTranseuDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataTranseuDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transeu/{client_id}/positions/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTranseuDevicePositions(requestParameters: DataTranseuDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataTranseuDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataTranseuDiscoverDevicesRaw(requestParameters: DataTranseuDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataTranseuDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transeu/{client_id}/discover_devices/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTranseuDiscoverDevices(requestParameters: DataTranseuDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataTranseuDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataTranseuVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transeu/version/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTranseuVersion(): Promise<VersionResponse> {
        const response = await this.dataTranseuVersionRaw();
        return await response.value();
    }

    /**
     */
    async dataTransportManagerDevicePositionsRaw(requestParameters: DataTransportManagerDevicePositionsRequest): Promise<runtime.ApiResponse<DevicesPositionResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataTransportManagerDevicePositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transport-manager/{client_id}/positions`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevicesPositionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTransportManagerDevicePositions(requestParameters: DataTransportManagerDevicePositionsRequest): Promise<DevicesPositionResponse> {
        const response = await this.dataTransportManagerDevicePositionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataTransportManagerDiscoverDevicesRaw(requestParameters: DataTransportManagerDiscoverDevicesRequest): Promise<runtime.ApiResponse<DiscoverDevicesResponse>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling dataTransportManagerDiscoverDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transport-manager/{client_id}/devices`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoverDevicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTransportManagerDiscoverDevices(requestParameters: DataTransportManagerDiscoverDevicesRequest): Promise<DiscoverDevicesResponse> {
        const response = await this.dataTransportManagerDiscoverDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dataTransportManagerVersionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/data/transport-manager/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     */
    async dataTransportManagerVersion(): Promise<VersionResponse> {
        const response = await this.dataTransportManagerVersionRaw();
        return await response.value();
    }

}
