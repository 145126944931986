/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoredObjectGroupType
 */
export interface WriteOnlyMonitoredObjectGroupType {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectGroupType
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectGroupType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectGroupType
     */
    shape: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectGroupType
     */
    deleted: number;
}

export function WriteOnlyMonitoredObjectGroupTypeFromJSON(json: any): WriteOnlyMonitoredObjectGroupType {
    return WriteOnlyMonitoredObjectGroupTypeFromJSONTyped(json, false);
}

export function WriteOnlyMonitoredObjectGroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoredObjectGroupType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'name': json['name'],
        'shape': json['shape'],
        'deleted': json['deleted'],
    };
}

export function WriteOnlyMonitoredObjectGroupTypeToJSON(value?: WriteOnlyMonitoredObjectGroupType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'name': value.name,
        'shape': value.shape,
        'deleted': value.deleted,
    };
}


