/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * in seconds
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    driving?: number;
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    nonDriving?: number;
}

export function DurationFromJSON(json: any): Duration {
    return DurationFromJSONTyped(json, false);
}

export function DurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Duration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driving': !exists(json, 'driving') ? undefined : json['driving'],
        'nonDriving': !exists(json, 'nonDriving') ? undefined : json['nonDriving'],
    };
}

export function DurationToJSON(value?: Duration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driving': value.driving,
        'nonDriving': value.nonDriving,
    };
}


