/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyMobileApp,
    ReadOnlyMobileAppFromJSON,
    ReadOnlyMobileAppToJSON,
    WriteOnlyMobileApp,
    WriteOnlyMobileAppFromJSON,
    WriteOnlyMobileAppToJSON,
} from '../models';

export interface MobileAppCreateRequest {
    data: WriteOnlyMobileApp;
}

export interface MobileAppDeleteRequest {
    id: string;
}

export interface MobileAppLatestRequest {
    deleted?: number;
    id?: string;
    link?: string;
    createdAt?: string;
    packageName?: string;
    appName?: string;
    versionName?: string;
    versionCode?: number;
}

export interface MobileAppListRequest {
    deleted?: number;
    id?: string;
    link?: string;
    createdAt?: string;
    packageName?: string;
    appName?: string;
    versionName?: string;
    versionCode?: number;
}

export interface MobileAppPartialUpdateRequest {
    id: string;
    data: WriteOnlyMobileApp;
}

export interface MobileAppReadRequest {
    id: string;
}

export interface MobileAppUpdateRequest {
    id: string;
    data: WriteOnlyMobileApp;
}

/**
 * no description
 */
export class MobileAppApi extends runtime.BaseAPI {

    /**
     */
    async mobileAppCreateRaw(requestParameters: MobileAppCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileApp>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileAppCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileAppCreate(requestParameters: MobileAppCreateRequest): Promise<ReadOnlyMobileApp> {
        const response = await this.mobileAppCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppDeleteRaw(requestParameters: MobileAppDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileAppDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileAppDelete(requestParameters: MobileAppDeleteRequest): Promise<ReadOnlyMobileApp> {
        const response = await this.mobileAppDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppLatestRaw(requestParameters: MobileAppLatestRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMobileApp>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.link !== undefined) {
            queryParameters['link'] = requestParameters.link;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        if (requestParameters.appName !== undefined) {
            queryParameters['app_name'] = requestParameters.appName;
        }

        if (requestParameters.versionName !== undefined) {
            queryParameters['version_name'] = requestParameters.versionName;
        }

        if (requestParameters.versionCode !== undefined) {
            queryParameters['version_code'] = requestParameters.versionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/latest/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMobileAppFromJSON));
    }

    /**
     */
    async mobileAppLatest(requestParameters: MobileAppLatestRequest): Promise<Array<ReadOnlyMobileApp>> {
        const response = await this.mobileAppLatestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppListRaw(requestParameters: MobileAppListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMobileApp>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.link !== undefined) {
            queryParameters['link'] = requestParameters.link;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        if (requestParameters.appName !== undefined) {
            queryParameters['app_name'] = requestParameters.appName;
        }

        if (requestParameters.versionName !== undefined) {
            queryParameters['version_name'] = requestParameters.versionName;
        }

        if (requestParameters.versionCode !== undefined) {
            queryParameters['version_code'] = requestParameters.versionCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMobileAppFromJSON));
    }

    /**
     */
    async mobileAppList(requestParameters: MobileAppListRequest): Promise<Array<ReadOnlyMobileApp>> {
        const response = await this.mobileAppListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppPartialUpdateRaw(requestParameters: MobileAppPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileAppPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileAppPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileAppPartialUpdate(requestParameters: MobileAppPartialUpdateRequest): Promise<ReadOnlyMobileApp> {
        const response = await this.mobileAppPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppReadRaw(requestParameters: MobileAppReadRequest): Promise<runtime.ApiResponse<ReadOnlyMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileAppRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileAppRead(requestParameters: MobileAppReadRequest): Promise<ReadOnlyMobileApp> {
        const response = await this.mobileAppReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileAppUpdateRaw(requestParameters: MobileAppUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileAppUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileAppUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileAppUpdate(requestParameters: MobileAppUpdateRequest): Promise<ReadOnlyMobileApp> {
        const response = await this.mobileAppUpdateRaw(requestParameters);
        return await response.value();
    }

}
