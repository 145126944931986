import { Line, G2 } from '@ant-design/charts';
import { LineConfig } from '@ant-design/plots';
import { Annotation } from '@antv/g2plot/lib/types/annotation';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_SHORT, TIME_FORMAT_SHORT, degC } from 'domain-constants';
import moment from 'moment';
import numeral from 'numeral';
import { CHART_THEME_DARK, CHART_THEME_LIGHT, LINE_COLORS_COMPLEX } from '../SimpleLineGraph/constants/colors';
import { Theme } from '../Settings';
import { ShapeAttrs } from '@antv/g-base';

export interface ChartTimelineModel {
    xVal: string;
    yVal?: number;
    series: string;
    customLabel?: string;
}

export interface ChartThemeAxis {
    gridLine?: ShapeAttrs;
    tickLine?: ShapeAttrs;
    line?: ShapeAttrs;
    label?: ShapeAttrs;
}
export interface ChartTheme {
    style?: React.CSSProperties;
    anotations?: React.CSSProperties;
    point?: {
        infoblock?: React.CSSProperties;
        backgroundColor?: string;
    };
    label?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    xAxis?: ChartThemeAxis;
    yAxis?: ChartThemeAxis;
}

export interface ChartRegionDataModel {
    xFrom: number;
    xTo: number;
    yFrom: number | 'min' | 'max';
    yTo: number | 'min' | 'max';
}

export interface ChartPointsDataModel {
    x: string;
    series: string;
    customLabel?: string;
}

export type Themes = Theme | ChartTheme;
export enum ThemeChartType {
    Trend = 'trend',
    Coldchain = 'coldchain'
}

export function getChartTheme(theme?: Themes, themeChartType: ThemeChartType = ThemeChartType.Coldchain): ChartTheme {
    if (theme === Theme.Dark || theme === undefined) {
        return {
            ...CHART_THEME_DARK,
            style: {
                background: themeChartType === ThemeChartType.Trend ? '#2F3031' : CHART_THEME_DARK.style?.background
            },
            xAxis: {
                ...CHART_THEME_DARK.xAxis,
                line: {
                    ...CHART_THEME_DARK.xAxis?.line,
                    lineWidth: themeChartType === ThemeChartType.Trend ? 1 : CHART_THEME_DARK.xAxis?.line?.lineWidth
                }
            }
        };
    } else if (theme === Theme.Light) {
        return CHART_THEME_LIGHT;
    } else {
        return theme;
    }
}

const DEFAULT_Y_AXIS_TICK_COUNT = 7;

interface Props {
    data: ChartTimelineModel[];
    seriesLabels?: {
        [key: string]: string;
    };
    regionData?: ChartRegionDataModel[];
    pointsData?: ChartPointsDataModel[];
    durationDays?: number;
    isSameDay?: boolean;
    isSameWeek?: boolean;
    limits?: {
        dangerPoints?: boolean;
    };
    xTicks?: number[];
    xTickInterval?: number;
    color?: string;
    theme?: Themes;
    padding?: number;
    lineWidth?: number;
    yAxisStep?: number;
    selectedSeries?: string[];
    yAxisMinValue?: number;
    yAxisMaxValue?: number;
    onGraphPointHover?: (timestamp: number) => void;
}

export default function ChartTimeline({
    theme,
    padding = 34,
    yAxisMaxValue = Infinity,
    yAxisMinValue = -Infinity,
    lineWidth = 1,
    xTickInterval = 30,
    ...props
}: Props) {
    const uniqueRegionData = props.regionData?.filter(
        (v, i, a) =>
            a.findIndex(t => t.xFrom === v.xFrom && t.xTo === v.xTo && t.yFrom === v.yFrom && t.yTo === v.yTo) === i
    );
    const dataValues = props.data.filter(d => d.yVal);

    const minValData = Math.min(...dataValues.map(d => d.yVal ?? yAxisMinValue));
    const regionMin = uniqueRegionData?.filter(d => d.yTo !== 'max' && d.xFrom !== d.xTo).map(d => Number(d.yTo)) ?? [];
    const minValRegionMin = Math.min(...regionMin);
    const maxValRegionMin = Math.max(...regionMin);

    const maxValData = Math.max(...dataValues.map(d => d.yVal ?? yAxisMaxValue));
    const regionMax =
        uniqueRegionData?.filter(d => d.yFrom !== 'min' && d.xFrom !== d.xTo).map(d => Number(d.yFrom)) ?? [];
    const maxValRegionMax = Math.max(...regionMax);
    const minValRegionMax = Math.min(...regionMax);

    const minVal = Math.min(minValData, minValRegionMin, minValRegionMax);
    const maxVal = Math.max(maxValData, maxValRegionMax, maxValRegionMin);

    const min = dataValues.length > 0 ? minVal - 8 : yAxisMinValue;
    const max = dataValues.length > 0 ? maxVal + 8 : yAxisMaxValue;
    const onlyDates = (props.xTicks?.[1] ?? 0) - (props.xTicks?.[0] ?? 0) >= 86400 ? true : false;
    const formatTime = (props.xTicks?.[1] ?? 0) - (props.xTicks?.[0] ?? 0) <= 30 ? 'HH:mm:ss' : TIME_FORMAT_SHORT;

    const chartTheme: ChartTheme = getChartTheme(theme);

    let prevValue: number = 0;
    if (props.limits?.dangerPoints) {
        G2.registerShape('point', 'alarm', {
            draw(cfg, container) {
                const current = cfg.data;
                const cx: number = typeof cfg.x === 'number' ? cfg.x : 0;
                const cy: number = typeof cfg.y === 'number' ? cfg.y : 0;
                const xIndex = current?.['xVal'];
                const series = current?.['series'];

                if (
                    props.pointsData
                        ?.filter(d => d.series === series)
                        .map(d => d.x)
                        .includes(xIndex)
                ) {
                    const group: any = container.addGroup();
                    group.addShape('circle', {
                        attrs: {
                            zIndex: 1,
                            x: cx,
                            y: cy,
                            r: 5,
                            fill: '#FF4E59',
                            opacity: 1
                        }
                    });

                    return group;
                }
                return;
            }
        });
    }

    const series = [...new Set(props.data.map(d => d.series))];
    const [formatDate] = DATE_TIME_FORMAT_SHORT.split(' ');

    const config: LineConfig = {
        annotations: uniqueRegionData
            ?.map(
                d =>
                    ({
                        type: 'region',
                        start: [d.xFrom, d.yFrom],
                        end: [d.xTo, d.yTo],
                        style: chartTheme.anotations
                    } as Annotation)
            )
            .concat(
                uniqueRegionData?.map(
                    d =>
                        ({
                            type: 'regionFilter',
                            start: [d.xFrom, d.yFrom],
                            end: [d.xTo, d.yTo],
                            color: '#EB5757'
                        } as Annotation)
                )
            )
            .concat(
                uniqueRegionData?.map(
                    d =>
                        ({
                            type: 'line',
                            start: [d.xFrom, d.yTo === 'max' ? d.yFrom : d.yTo],
                            end: [d.xTo, d.yTo === 'max' ? d.yFrom : d.yTo],
                            style: {
                                lineDash: [4, 4],
                                strokeOpacity: 1,
                                stroke: '#EB5757'
                            }
                        } as Annotation)
                )
            ),
        animation: false,
        data: props.data,
        xField: 'xVal',
        yField: 'yVal',
        seriesField: 'series',
        smooth: true,
        style: chartTheme?.style,
        color: [
            props.color ?? LINE_COLORS_COMPLEX[0],
            LINE_COLORS_COMPLEX[1],
            LINE_COLORS_COMPLEX[2],
            LINE_COLORS_COMPLEX[3],
            LINE_COLORS_COMPLEX[4],
            LINE_COLORS_COMPLEX[5],
            LINE_COLORS_COMPLEX[6],
            LINE_COLORS_COMPLEX[7],
            LINE_COLORS_COMPLEX[8]
        ],
        appendPadding: [10, 16, 0, 0],
        padding: [0, 0, 40, padding],
        lineStyle: {
            lineWidth
        },
        legend: {
            padding: [9999, 0, 0, 0],
            selected: props.selectedSeries
                ? Object.fromEntries(series.map(d => [d, props.selectedSeries?.includes(d) ? true : false]))
                : undefined
        },
        yAxis: {
            max: max === -Infinity ? 50 : max,
            min: min === Infinity ? -50 : min,
            grid: {
                line: {
                    style: chartTheme?.yAxis?.gridLine
                }
            },
            line: {
                style: chartTheme?.yAxis?.line
            },
            label: {
                style: chartTheme?.yAxis?.label
            },
            tickCount: props.yAxisStep ? Math.ceil((max - min) / props.yAxisStep) : DEFAULT_Y_AXIS_TICK_COUNT
        },
        xAxis: {
            tickLine: {
                style: chartTheme?.xAxis?.tickLine
            },
            line: {
                style: chartTheme?.xAxis?.line
            },
            label: {
                style: chartTheme?.xAxis?.label,
                formatter: (v, _item, index) => {
                    if (v === '') {
                        return '';
                    } else if (
                        moment.unix(prevValue).get('date') !== moment.unix(Number(v)).get('date') ||
                        index === 0
                    ) {
                        prevValue = Number(v);
                        if (onlyDates) {
                            return `${moment.unix(Number(v)).format(formatDate)}`;
                        } else if (props.isSameDay) {
                            return `${moment.unix(Number(v)).format(formatTime)}`;
                        } else {
                            return `${moment.unix(Number(v)).format(formatDate)}\n${moment
                                .unix(Number(v))
                                .format(formatTime)}`;
                        }
                    } else {
                        prevValue = Number(v);
                        if (props.isSameDay) {
                            return moment.unix(Number(v)).format(formatTime);
                        } else {
                            return ` \n${moment.unix(Number(v)).format(formatTime)}`;
                        }
                    }
                }
            },
            tickInterval: xTickInterval,
            tickMethod: 'time'
        },
        tooltip: {
            showContent: true,
            showMarkers: true,
            showCrosshairs: true,
            title: title => moment.unix(Number(title)).format(DATE_TIME_FORMAT),
            formatter: tooltip => ({
                name: props.data.find(d => d.series === tooltip.series)?.customLabel ?? tooltip.series,
                value: tooltip.yVal ? `${numeral(tooltip.yVal).format('0.0')}${degC}` : '-'
            }),
            domStyles: {
                'g2-tooltip': chartTheme.tooltip,
                'g2-tooltip-value': {
                    fontWeight: 600
                },
                'g2-tooltip-title': {
                    fontWeight: 600
                }
            }
        },
        onEvent: (_chart, event) => {
            if (event.type === 'tooltip:show') {
                props.onGraphPointHover?.(Number(event.data?.items?.[0].data.xVal));
            }
        },
        point: props.limits?.dangerPoints
            ? {
                  shape: 'alarm'
              }
            : undefined
    };

    return (
        <div className="chart-timeline">
            <Line {...config} />
        </div>
    );
}
