import { ReactNode } from 'react';
import { DriverBehaviorScoreModel, PerfectDriveDriverDetailModel } from 'common/model/statistics';
import { ScoreCardColor } from '.';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import Trend, { TrendChange } from 'common/components/Trend';
import { roundToStep } from 'common/utils/averages';
import { Colors } from '../DriverScoreProgress';
import { capitalizeFirstLetter } from 'common/utils/textUtils';
import cn from 'classnames';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDriverBehaviorName } from 'logic/driver-behavior-coach/driver-behavior-trucks';
import { Theme } from 'common/components/Settings';

interface Props {
    data: DriverBehaviorScoreModel;
    description?: ReactNode;
    selected?: boolean;
    color: ScoreCardColor;
    trend?: boolean;
    trendChangeForIncrease?: TrendChange;
    percent?: boolean;
    fixedValue?: boolean;
    theme: Theme;
}

enum ScoreBgColor {
    Lightblue = '#2b3e41',
    Blue = '#1e232c',
    Green = '#1c2922',
    Yellow = '#2e291c',
    Purple = '#32303e'
}

export function getDriverScoreData(data: PerfectDriveDriverDetailModel, fieldName: string | undefined) {
    if (fieldName) {
        return {
            name: formatDriverBehaviorName(data.name, data.surname, data.tachocard),
            value: data?.scores?.[0]?.[fieldName],
            previousValue: data?.scores?.[1]?.[fieldName]
        };
    } else {
        return data;
    }
}

export function ScoreDriver(props: Props) {
    const trendValue =
        props.data.previousValue !== undefined
            ? getIncreasePercent(props.data.value, props.data.previousValue)
            : undefined;
    const increaseDirection = getIncreaseDirection(props.data.value, props.data.previousValue);

    const { t } = useTranslation();
    return (
        <div
            className={cn(`score-driver score-driver-${props.color}`, {
                'score-driver-selected': props.selected,
                'score-driver-nodata': props.data.value === undefined
            })}
        >
            <div
                className="score-driver-value"
                style={{
                    backgroundColor: ScoreBgColor[capitalizeFirstLetter(props.color)] ?? '',
                    color: Colors[capitalizeFirstLetter(props.color)] ?? ''
                }}
            >
                {props.data.value === undefined && (
                    <div className="no-data-text">{t('common.noData').toLowerCase()}</div>
                )}
                {props.data.value !== undefined && (
                    <>
                        {props.fixedValue ? props.data.value : roundToStep(props.data.value, 1.0)}
                        {props.percent && '%'}
                    </>
                )}
            </div>
            {props.trend && props.data.value !== undefined && (
                <Row align="middle" justify="center" className="score-driver-trend">
                    <Trend
                        direction={increaseDirection}
                        trendChangeForIncrease={props.trendChangeForIncrease ?? TrendChange.POSITIVE}
                        value={trendValue}
                        small={true}
                        theme={props.theme}
                    />
                </Row>
            )}
            {props.trend && props.data.value === undefined && (
                <Row align="middle" justify="center" className="score-driver-trend" />
            )}
            {props.description && <div className="score-driver-description">{props.description}</div>}
            <div className="score-driver-name" title={props.data.name}>
                {props.data.name}
            </div>
        </div>
    );
}
