import { PoiModel } from 'common/model/poi';
import { Logic } from 'logic/logic';
import { action, makeObservable, observable } from 'mobx';
import {
    latLngFromGeoJsonPointType,
    latLngFromGeoJsonPolygonType,
    toGeoJsonPointTypeInput,
    toGeoJsonPolygonTypeInput
} from 'common/utils/geo-utils';
import { DispatcherModel } from 'logic/user/users';
import { CustomPlace } from 'generated/backend-api';
import { PlaceOfWorkUser } from 'common/model/place-of-work-user';
import { CustomPlaceAddInput, CustomPlaceUpdateInput, PoiPolygonType, PoiType } from 'generated/graphql';
import moment from 'moment';

export class CustomPlacesLogic {
    logic: Logic;

    @observable customPlaces: CustomPlace[];
    @observable customPlacesPoiModel: PoiModel[];
    @observable users: DispatcherModel[];
    @observable selectedCustomPlace?: PoiModel;

    @observable loadingCustomPlaces?: boolean;
    @observable loadingUsers?: boolean;
    @observable loadingUpdateCreateCustomPlace?: boolean;
    @observable loadingUpdatePlacesOfWork?: boolean;
    @observable loadingRemovePlacesOfWork?: boolean;
    constructor(logic: Logic) {
        this.logic = logic;

        this.customPlaces = [];
        this.customPlacesPoiModel = [];
        this.users = [];

        makeObservable(this);
    }

    @action
    async init() {
        this.logic.map?.().routing().init(this.logic.map().initialPaddingWithLeftComponent);
        if (this.logic.demo().isActive) {
            this.customPlacesPoiModel = await this._fetchCustomPlaces();
            this.users = [];
        } else {
            this.customPlacesPoiModel = await this._fetchCustomPlaces();
            this.users = await this.logic.users().drivers();
        }
    }

    @action
    setSelectedCustomPlace(customPlace?: PoiModel) {
        this.selectedCustomPlace = customPlace;
    }

    @action
    async reloadCustomPlaces() {
        this.customPlacesPoiModel = await this._fetchCustomPlaces();
        if (this.selectedCustomPlace) {
            this.setSelectedCustomPlace(this.customPlacesPoiModel.find(p => p.id === this.selectedCustomPlace?.id));
        }
    }

    @action
    async createPoi(poi: PoiModel): Promise<void> {
        this.loadingUpdateCreateCustomPlace = true;
        try {
            await this.logic.poi().createCustomPlace(this._toCreatePoiInput(poi));
        } catch (err) {
            console.error(`Failed to update custom places, err: ${err}`);
            throw err;
        } finally {
            this.loadingUpdateCreateCustomPlace = false;
        }
    }

    @action
    async updatePoi(poi: PoiModel): Promise<void> {
        this.loadingUpdateCreateCustomPlace = true;
        try {
            await this.logic.poi().updateCustomPlace(this._toUpdatePoiInput(poi));
        } catch (err) {
            console.error(`Failed to update custom places, err: ${err}`);
            throw err;
        } finally {
            this.loadingUpdateCreateCustomPlace = false;
        }
    }

    @action
    async addUsersToSelectedCustomPlace(userIds: number[], start: string, end: string) {
        this.loadingUpdatePlacesOfWork = true;
        try {
            if (!this.selectedCustomPlace?.id) throw new Error('no custom place selected');
            await this.logic.poi().addPlaceOfWorkUsers(this.selectedCustomPlace.id, userIds, start, end);
        } catch (err) {
            console.error(`Failed to add users to custom place, err: ${err}`);
            throw err;
        } finally {
            this.loadingUpdatePlacesOfWork = false;
        }
    }

    @action
    async removeUserFromSelectedCustomPlace(placeOfWorkId: string) {
        this.loadingRemovePlacesOfWork = true;
        try {
            if (!this.selectedCustomPlace?.id) throw new Error('no custom place selected');
            await this.logic.poi().deletePlaceOfWorkUser(placeOfWorkId, this.selectedCustomPlace.id);
        } catch (err) {
            console.error(`Failed to remove place of work, err: ${err}`);
            throw err;
        } finally {
            this.loadingRemovePlacesOfWork = false;
        }
    }

    // placeAutocomplete(text: string): Promise<QueryAutocompletePrediction[]> {
    //     return this._logic.geocoding().placesAutocomplete(text, this._logic.geocoding().autocompleteSessionToken());
    // }

    // placeDetail(id: string): Promise<PlaceResult> {
    //     return this._logic.geocoding().placesDetail(id, this._geocodingToken);
    //     return place;
    // } // TODO: what is this?

    get selectedPlacesOfWorkUserExtended(): PlaceOfWorkUser[] {
        const customPlace = this.customPlaces.find(p => p.id === this.selectedCustomPlace?.id);
        return (
            customPlace?.placeOfWork?.map(pw => {
                const user = this.users.find(u => u.id === pw.userId.toString());
                return {
                    ...pw,
                    userName: user?.name,
                    userSurname: user?.surname,
                    start: moment(moment.utc(moment(pw.start).format('YYYY-MM-DD HH:mm:ss')).toDate())
                        .local()
                        .toDate(),
                    end: pw.end
                        ? moment(moment.utc(moment(pw.end).format('YYYY-MM-DD HH:mm:ss')).toDate())
                              .local()
                              .toDate()
                        : undefined
                };
            }) ?? []
        );
    }

    @action
    private async _fetchCustomPlaces(): Promise<PoiModel[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.pois;
        }
        try {
            this.loadingCustomPlaces = true;
            this.customPlaces = await this.logic.poi().getCustomPlaces();
            return (
                this.customPlaces
                    ?.map(this._toPoiModel)
                    .sort((a, b) => (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) ?? []
            );
        } catch (err) {
            console.error(`Failed to fetch custom places, err: ${err}`);
            throw err;
        } finally {
            this.loadingCustomPlaces = false;
        }
    }

    private _toPoiModel(poi: CustomPlace): PoiModel {
        const { id, type, center, name, address, polygon, notes, forbidden } = poi;
        return {
            type,
            id: id ?? '',
            name: name ?? '',
            forbidden: forbidden ?? false,
            address: address ?? '',
            notes: notes ?? '',
            center: latLngFromGeoJsonPointType(center ?? {}),
            polygon: latLngFromGeoJsonPolygonType(polygon ?? {})?.[0]
        };
    }

    private _toUpdatePoiInput(poi: PoiModel): CustomPlaceUpdateInput {
        return {
            _id: poi?.id ?? '',
            name: poi?.name ?? '',
            polygon: toGeoJsonPolygonTypeInput([poi?.polygon ?? []]),
            center: toGeoJsonPointTypeInput(
                poi?.center
                    ? poi?.center
                    : {
                          lat: -1,
                          lng: -1
                      }
            ),
            polygonType: PoiPolygonType.Custom,
            notes: poi?.notes ?? '',
            forbidden: poi?.forbidden ?? false,
            deleted: 0,
            address: poi?.address ?? '',
            type: poi?.type ?? PoiType.Firm,
            countryCode: poi?.countryCode ?? ''
        };
    }

    private _toCreatePoiInput(poi: PoiModel): CustomPlaceAddInput {
        return {
            name: poi?.name ?? '',
            polygon: toGeoJsonPolygonTypeInput([poi?.polygon ?? []]),
            center: toGeoJsonPointTypeInput(poi?.center ?? { lat: -1, lng: -1 }),
            polygonType: PoiPolygonType.Custom,
            notes: poi?.notes ?? '',
            forbidden: poi?.forbidden ?? false,
            deleted: 0,
            address: poi?.address ?? '',
            type: poi?.type ?? PoiType.Firm,
            countryCode: poi?.countryCode ?? ''
        };
    }
}
