/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PUESCSentDocumentUpdateState
 */
export interface PUESCSentDocumentUpdateState {
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    state?: PUESCSentDocumentUpdateStateStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PUESCSentDocumentUpdateState
     */
    error?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    sentRefNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    errorCode?: PUESCSentDocumentUpdateStateErrorCodeEnum;
    /**
     * 
     * @type {object}
     * @memberof PUESCSentDocumentUpdateState
     */
    errorDetails?: object | null;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    sentResponseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    oldState: PUESCSentDocumentUpdateStateOldStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    responseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PUESCSentDocumentUpdateState
     */
    responseDocument?: string | null;
}

export function PUESCSentDocumentUpdateStateFromJSON(json: any): PUESCSentDocumentUpdateState {
    return PUESCSentDocumentUpdateStateFromJSONTyped(json, false);
}

export function PUESCSentDocumentUpdateStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PUESCSentDocumentUpdateState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': !exists(json, 'state') ? undefined : json['state'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'sentRefNumber': !exists(json, 'sent_ref_number') ? undefined : json['sent_ref_number'],
        'errorCode': !exists(json, 'error_code') ? undefined : json['error_code'],
        'errorDetails': !exists(json, 'error_details') ? undefined : json['error_details'],
        'sentResponseRaw': !exists(json, 'sent_response_raw') ? undefined : json['sent_response_raw'],
        'oldState': json['old_state'],
        'responseRaw': !exists(json, 'response_raw') ? undefined : json['response_raw'],
        'responseDocument': !exists(json, 'response_document') ? undefined : json['response_document'],
    };
}

export function PUESCSentDocumentUpdateStateToJSON(value?: PUESCSentDocumentUpdateState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'error': value.error,
        'sent_ref_number': value.sentRefNumber,
        'error_code': value.errorCode,
        'error_details': value.errorDetails,
        'sent_response_raw': value.sentResponseRaw,
        'old_state': value.oldState,
        'response_raw': value.responseRaw,
        'response_document': value.responseDocument,
    };
}

/**
* @export
* @enum {string}
*/
export enum PUESCSentDocumentUpdateStateStateEnum {
    Draft = 'draft',
    New = 'new',
    Sent = 'sent',
    Done = 'done'
}
/**
* @export
* @enum {string}
*/
export enum PUESCSentDocumentUpdateStateErrorCodeEnum {
    GENERICERROR = 'GENERIC_ERROR'
}
/**
* @export
* @enum {string}
*/
export enum PUESCSentDocumentUpdateStateOldStateEnum {
    Draft = 'draft',
    New = 'new',
    Sent = 'sent',
    Done = 'done'
}


