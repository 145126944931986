import React, { ChangeEvent, createRef } from 'react';
import { Message, MessagePayload, UploadAttachmentResp } from 'logic/messaging';
import moment from 'moment';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Upload } from 'common/components/Form';
import { UploadChangeParam } from 'antd/lib/upload';
import { Col, Row } from 'antd';
import { Button, Tooltip } from 'common/components';
import { Input } from 'common/components/Form';
import Search from 'common/components/Search';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import * as messagingIcons from 'resources/images/messaging';

interface Props extends WithTranslation {
    title: string;
    driverId: string;
    messages?: Message[];
    userId: string;
    search?: string;
    messageIndexRef: number;
    inputRef: React.RefObject<HTMLInputElement>;
    checkedDriversIds?: string[];
    roles: Role[];

    uploadAttachment?: (attachment: File | Blob) => Promise<UploadAttachmentResp>;
    onUserNameFromId: (userId: string) => string;
    onSearchClick?: () => void;
    onSearch?: (text: string) => void;
    onMessage?: (text: string, payload?: MessagePayload[]) => void;
    onGroupMessage?: (text: string, payload?: MessagePayload[]) => void;
    onMessageUnsee?: (messageId: string) => void;
    onChatFocus?: () => void;
    onScrollTop?: () => void;
    demoMode?: boolean;
}

interface State {
    text: string;
    loading: boolean;
    attachment?: UploadAttachmentResp & { fileName: string; type: MessagePayload['type'] };
}

class MessagingChat extends React.Component<Props, State> {
    private _ref = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            text: '',
            loading: false
        };
    }

    componentDidMount() {
        this._ref.current?.scrollIntoView({
            block: 'end'
        });
    }

    componentDidUpdate(_prevProps: Props) {
        if ((this.props.messages?.length ?? 0) > (_prevProps.messages?.length ?? 0) && this.props.messageIndexRef > 4) {
            this._ref.current?.scrollIntoView({
                block: 'start'
            });
        }
        if (this.props.title !== _prevProps.title || this.props.messages?.[0]?.id !== _prevProps.messages?.[0]?.id) {
            this._ref.current?.scrollIntoView({
                block: 'end'
            });
        }
    }

    render() {
        return this.props.messages ? (
            <>
                <Row className="messaging-bar" align="middle" justify="space-between">
                    <Col>
                        {this.props.t('MessagingChat.conversation')}{' '}
                        <span data-qa={qa.messaging.messages.fieldTitle}>{this.props.title}</span>
                    </Col>
                    <Col>
                        <Button
                            type={this.props.search === undefined ? 'primary' : 'link'}
                            size="small"
                            onClick={this._onSearchClick}
                            icon={<i className="fa fa-search" />}
                            qa={qa.messaging.messages.btnMessagesSearch}
                        />
                    </Col>
                </Row>

                <div
                    className={cn('messaging-chat', {
                        'with-search': this.props.search !== undefined
                    })}
                >
                    {this.props.search !== undefined && (
                        <div className="messaging-search">
                            <Search
                                searchActive={true}
                                onSearch={this._onSearch}
                                qa={qa.messaging.messages.inputMessagesSearch}
                            />
                        </div>
                    )}
                    <div className="messaging-messages" onScroll={this._onScroll}>
                        {this.props.messages.map((m, i) => {
                            const seen = m.seen?.find(mseen => mseen.user);
                            const createdDiffDays = moment().startOf('day').diff(m.created, 'days', true);
                            return (
                                <div key={'message' + m.id}>
                                    {!this.props.messages?.[i - 1]?.created ||
                                    moment(m.created).isAfter(this.props.messages?.[i - 1]?.created, 'date') ? (
                                        <div className="messaging-message-divider">
                                            {createdDiffDays >= 2
                                                ? moment(m.created).format('ddd, L')
                                                : createdDiffDays > 0
                                                ? this.props.t('common.yesterday')
                                                : this.props.t('common.today')}
                                        </div>
                                    ) : null}
                                    <div
                                        className="messaging-message-container"
                                        key={m.id}
                                        data-qa={qa.messaging.messages.sectionMessage}
                                    >
                                        <div
                                            key={m.id}
                                            ref={i === this.props.messageIndexRef ? this._ref : null}
                                            className={cn('messaging-message', {
                                                'messaging-message-highlight': m.sender === this.props.userId ? 1 : 0
                                            })}
                                        >
                                            <div>
                                                <p data-qa={qa.messaging.messages.fieldText}>{m.text}</p>
                                                {m.payload?.map(m => (
                                                    <a
                                                        key={m.url}
                                                        target="_blank"
                                                        href={m.url}
                                                        rel="noopener noreferrer"
                                                    >
                                                        {m.thumb ? (
                                                            <img
                                                                src={m.thumb}
                                                                alt={m.thumb}
                                                                data-qa={qa.messaging.messages.fieldAttachmentImg}
                                                            />
                                                        ) : (
                                                            <p data-qa={qa.messaging.messages.fieldAttachmentUrl}>
                                                                {m.url.slice(0, 40)}...
                                                            </p>
                                                        )}
                                                    </a>
                                                ))}
                                            </div>
                                            <div className="messaging-message-info">
                                                {m.sender === this.props.userId && seen?.user ? (
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            <>
                                                                <div key={m.id + seen.user + m.created}>
                                                                    {this.props.t('MessagingChat.delivered')}:{' '}
                                                                    {moment(m.created).format('LT')}
                                                                </div>
                                                                <div key={m.id + seen.user + seen.date}>
                                                                    {this.props.t('MessagingChat.read')}:{' '}
                                                                    {moment(seen.date).format('LT')}
                                                                </div>
                                                            </>
                                                        }
                                                    >
                                                        <span
                                                            data-qa={qa.messaging.messages.fieldDateTime}
                                                            title={moment(m.created).format('L LT')}
                                                        >
                                                            {moment(m.created).format('LT')}
                                                        </span>
                                                        ,{' '}
                                                        <span data-qa={qa.messaging.messages.fieldSender}>
                                                            {m.sender === this.props.userId
                                                                ? this.props.t('MessagingChat.you')
                                                                : this.props.onUserNameFromId(m.sender)}
                                                        </span>
                                                        <span
                                                            className="messaging-seen"
                                                            data-qa={qa.messaging.messages.fieldSeen}
                                                        >
                                                            <img
                                                                className="messaging-seen-icon"
                                                                alt="check"
                                                                src="/img-svg/double-checked.svg"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <>
                                                        <span
                                                            data-qa={qa.messaging.messages.fieldDateTime}
                                                            title={moment(m.created).format('L LT')}
                                                        >
                                                            {moment(m.created).format('LT')}
                                                        </span>
                                                        ,{' '}
                                                        <span data-qa={qa.messaging.messages.fieldSender}>
                                                            {m.sender === this.props.userId
                                                                ? this.props.t('MessagingChat.you')
                                                                : this.props.onUserNameFromId(m.sender)}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <Row align="middle" className="message-input-bar">
                        {this.state.attachment && (
                            <Col className="message-attachment">
                                {this.state.attachment.thumbUrl && (
                                    <img
                                        className="message-attachment-thumb"
                                        src={this.state.attachment.thumbUrl}
                                        alt={this.state.attachment.thumbUrl}
                                    />
                                )}
                                <div className="message-attachment-name">{this.state.attachment.fileName}</div>
                            </Col>
                        )}
                        {this.state.loading && (
                            <Col className="message-attachment">
                                <i className="fa fa-spinner" />
                            </Col>
                        )}
                        <Col>
                            <input
                                name="text"
                                type="text"
                                value={this.state.text}
                                onChange={this._onChange}
                                autoComplete="off"
                                onKeyDown={this._onKeyDown}
                                placeholder={this.props.t('MessagingChat.messagePlaceholder')}
                                onFocus={this.props.onChatFocus}
                                autoFocus
                                ref={this.props.inputRef}
                                data-qa={qa.messaging.messages.inputMessage}
                            />
                        </Col>
                        <Col>
                            <Upload showUploadList={false} onChange={this._onUploadAttachment}>
                                <Button
                                    type="dashed"
                                    size="middle"
                                    disabled={this.props.demoMode}
                                    qa={qa.messaging.messages.btnAttachment}
                                >
                                    <img src={messagingIcons.addItem} alt="" />
                                </Button>
                            </Upload>
                        </Col>
                        <Col>
                            <Button
                                onClick={this._onSend}
                                type="primary"
                                size="middle"
                                disabled={this.props.demoMode}
                                qa={qa.messaging.messages.btnSend}
                            >
                                <img src={messagingIcons.sendMessage} alt="" />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        ) : this.props.checkedDriversIds ? (
            <>
                <div className="messaging-bar" />
                <div className="group-message-panel">
                    <div className="t-panel">{`${this.props.t('MessagingChat.groupMessagePlaceholder')} ${
                        this.props.checkedDriversIds.length
                    }`}</div>
                    <Row align="middle" className="message-input-bar">
                        {this.state.attachment && (
                            <Col className="message-attachment">
                                {this.state.attachment.thumbUrl && (
                                    <img
                                        className="message-attachment-thumb"
                                        src={this.state.attachment.thumbUrl}
                                        alt={this.state.attachment.thumbUrl}
                                    />
                                )}
                                <div className="message-attachment-name">{this.state.attachment.fileName}</div>
                            </Col>
                        )}
                        {this.state.loading && (
                            <Col className="message-attachment">
                                <i className="fa fa-spinner" />
                            </Col>
                        )}
                        <Col>
                            <Input
                                name="text"
                                type="text"
                                value={this.state.text}
                                onChange={this._onChange}
                                autoComplete="off"
                                onKeyDown={this._onKeyDown}
                                placeholder={this.props.t('MessagingChat.messagePlaceholder')}
                                autoFocus
                                qa={qa.messaging.messages.inputMessage}
                            />
                        </Col>
                        <Col>
                            <Upload showUploadList={false} onChange={this._onUploadAttachment}>
                                <Button
                                    type="dashed"
                                    size="middle"
                                    disabled={this.props.demoMode}
                                    qa={qa.messaging.messages.btnAttachment}
                                >
                                    <img src={messagingIcons.addItem} alt="" />
                                </Button>
                            </Upload>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="middle"
                                disabled={this.state.loading || this.props.demoMode}
                                onClick={this._onSend}
                                qa={qa.messaging.messages.btnSend}
                            >
                                <img src={messagingIcons.sendMessage} alt="" />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        ) : (
            <>
                <div className="messaging-bar" />
                <div className="messaging-chat-no-messages">{this.props.t('MessagingChat.chooseUserPlaceholder')}</div>
            </>
        );
    }

    private _onUploadAttachment = (info: UploadChangeParam) => {
        if (!info.file.originFileObj) {
            return;
        }

        this.setState({ loading: true });

        this.props.uploadAttachment?.(info.file.originFileObj!).then(attachment => {
            this.setState({
                attachment: {
                    ...attachment,
                    fileName: info.file.name,
                    type: info.file.type?.startsWith('image') ? 'image' : 'file'
                },
                loading: false
            });
        });
    };

    private _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.demoMode) {
            return;
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this._onSend();
        }
    };

    private _onSend = () => {
        if ((!this.state.attachment && this.state.text.length === 0) || this.props.demoMode) {
            return;
        }

        this.props.messages &&
            this.props.onMessage?.(
                this.state.text,
                this.state.attachment
                    ? [{ type: 'image', url: this.state.attachment?.url, thumb: this.state.attachment?.thumbUrl }]
                    : []
            );
        this.props.checkedDriversIds &&
            this.props.onGroupMessage?.(
                this.state.text,
                this.state.attachment
                    ? [{ type: 'image', url: this.state.attachment?.url, thumb: this.state.attachment?.thumbUrl }]
                    : []
            );
        this.setState({ text: '', attachment: undefined });
    };

    private _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ text: e.target.value });
    };

    private _onSearchClick = () => {
        this.props.onSearchClick?.();
    };

    private _onSearch = (value: string) => {
        this.props.onSearch?.(value);
    };

    private _onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as HTMLDivElement).scrollTop === 0) {
            this.props.onScrollTop?.();
        }
    };
}

export default withTranslation()(MessagingChat);
