import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { message } from 'antd';
import i18n from 'i18next';
import { Role } from 'logic/auth';

import { exponea } from 'logic/exponea';
import { LayoutContent } from 'common/components/Layout/Content';
import { RouteNames, withLogicContext, WithLogic } from 'App';

import Tabs from '../../components/SystemConnectionTabs';
import OtherSystemsTable from '../../components/OtherSystemsTable';
import SystemConnectionDetail, { ModeType } from '../../components/SystemConnectDetail';
import {
    ReadOnlyExternalSystemAccess,
    ReadOnlyExternalSystemSecret,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectGroup
} from 'generated/new-main/models';
import { SystemConnectFormValues } from '../../components/SystemConnectForm';
import Confirm, { MessageType } from 'common/components/Confirm';
import { Subscription } from 'rxjs';
import { getRegistrationNumber } from 'common/utils/registrationName';
import qa from 'qa-selectors';
import TableBar from 'common/components/TableBar';
import { PuescFormValues } from 'common/forms/PuescForm';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { PuescRegistrationModel } from 'common/forms/PuescRegistration/PuescRegistrationForm';
import { externalSystemCreateError } from 'logic/management/management-external-systems';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { HelperModal } from 'common/components';

export interface OtherSystemsData {
    id: number;
    name: string;
    secret: string;
    vehicles: string;
}

interface Props extends WithTranslation, WithLogic, RouteComponentProps {}

interface State {
    deleteConfirmModal: {
        isOpen: boolean;
        id?: number;
    };
    selectedExternalSystemSecretId?: number;
    detailInitialData: Partial<SystemConnectFormValues>;
    puescInitialData: Partial<PuescFormValues>;
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    vehicleGroups: ReadOnlyMonitoredObjectGroup[];
    detailMode: ModeType;
    accessess: ReadOnlyExternalSystemAccess[];
    secrets: ReadOnlyExternalSystemSecret[];
    loading: boolean;
    vehicleGroupsLoading: boolean;
    vehiclesLoading: boolean;
    systemConnectionLoading: boolean;
    isImpersonator: boolean;
    activatePuescLoading: boolean;
    removeOtherSystemLoading: boolean;
    helper?: {
        content: string;
    };
}

class OtherSystemsModule extends React.Component<Props, State> {
    private _externalSystemDataSubscription?: Subscription;
    private _externalSystemLoadingSubscription?: Subscription;
    constructor(props: Props) {
        super(props);
        this.state = {
            deleteConfirmModal: {
                isOpen: false
            },
            detailInitialData: {},
            puescInitialData: {},
            detailMode: 'DEFAULT',
            accessess: [],
            secrets: [],
            loading: true,
            vehicleGroups: [],
            vehicleGroupsLoading: false,
            vehicles: [],
            vehiclesLoading: false,
            systemConnectionLoading: false,
            isImpersonator: props.logic.auth().impersonator(),
            activatePuescLoading: false,
            removeOtherSystemLoading: false
        };
    }

    componentDidMount() {
        this._externalSystemDataSubscription = this.props.logic.externalSystem().onData.subscribe(data => {
            this.setState({ accessess: data.accesses, secrets: data.secrets });
        });

        this._externalSystemLoadingSubscription = this.props.logic.externalSystem().onLoading.subscribe(loading => {
            this.setState({ loading });
        });

        this.props.logic.externalSystem().loadData();
        this.props.logic
            .vehicles()
            .getMonitoredObjectFilters(true)
            .then(vehicles => {
                this.setState({
                    vehicles,
                    vehiclesLoading: false
                });
            })
            .catch(err => {
                console.error(`Can not get vehicles, err: ${err}`);
                message.error(this.props.t('SystemConnectMessages.unknown'));
                this.setState({
                    vehiclesLoading: false
                });
            });

        this.props.logic
            .vehicles()
            .vehicleGroups()
            .getMonitoredObjectGroupListByName()
            .then(data => {
                this.setState({
                    vehicleGroups: data.results,
                    vehicleGroupsLoading: false
                });
            })
            .catch(err => {
                console.error(`Can not get vehicles groups, err: ${err}`);
                message.error(this.props.t('SystemConnectMessages.unknown'));
                this.setState({
                    vehicleGroupsLoading: false
                });
            });
    }

    componentWillUnmount() {
        this._externalSystemDataSubscription?.unsubscribe();
        this._externalSystemLoadingSubscription?.unsubscribe();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <LayoutContent
                    className="system-connections no-padding"
                    mainSizes={{ xs: 24, sm: 24, md: 18 }}
                    extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                    main={
                        <>
                            <header>
                                <TableBar heading={t('SystemConnections.title')} />
                                <Tabs
                                    active={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS}
                                    onChange={this._onTabChange}
                                    tabs={[
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerApi}>
                                                    {this.props.t('SystemConnections.customerApi')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API,
                                            roles: [Role.CA_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerAccess}>
                                                    {this.props.t('SystemConnections.ewCustomerAccess')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS,
                                            roles: [Role.CAC_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabOtherSystems}>
                                                    {this.props.t('SystemConnections.otherSystems')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS,
                                            roles: [Role.OAC_R, Role.PUESC]
                                        }
                                    ].filter(t =>
                                        this.props.logic
                                            .auth()
                                            .roles()
                                            .some(r => t.roles.includes(r))
                                    )}
                                />
                            </header>
                            <main>
                                <OtherSystemsTable
                                    loading={this.state.loading || this.state.vehiclesLoading}
                                    onEditClick={this._onEditClick}
                                    onTableRowSelect={this._onEditClick}
                                    onRemoveClick={this._onTableRemoveClick}
                                    data={this._getTableData()}
                                />
                            </main>
                        </>
                    }
                    extra={[
                        <LayoutSidePanel
                            header={
                                this.state.detailMode === 'EDIT'
                                    ? this._getExternalSystemData().name
                                    : t('SystemConnectForm.system')
                            }
                            body={
                                <SystemConnectionDetail
                                    editSystemConnectData={this.state.detailInitialData}
                                    editPuescData={this.state.puescInitialData}
                                    mode={this.state.detailMode}
                                    loading={this.state.systemConnectionLoading}
                                    externalSystemData={this._getExternalSystemData()}
                                    clientId={this.props.logic.auth().client()?.id}
                                    puesc={
                                        this.state.secrets.find(
                                            a =>
                                                a.externalSystemAccess?.id ===
                                                this.state.detailInitialData.externalSystemId
                                        )?.externalSystemAccess?.externalSystemName === 'PUESC'
                                    }
                                    isImpersonator={this.state.isImpersonator}
                                    onPuescSystemConnectFormSubmit={this._onPuescSystemConnectFormSubmit}
                                    onSystemConnectFormSubmit={this._onSystemConnectionFormSubmit}
                                    onSystemConnectFormCancel={this._onSystemConnectionFormCancel}
                                    onPuescFormSubmit={this._onPuescFormSubmit}
                                    onCreateNew={this._onDetailCreateNew}
                                    onActivatePuesc={this._onActivatePuesc}
                                    activatePuescLoading={this.state.activatePuescLoading}
                                    externalSystemAccess={this.state.accessess.filter(
                                        access =>
                                            !this.state.secrets?.some(
                                                secrets =>
                                                    secrets.externalSystemAccess?.externalSystemName ===
                                                    access.externalSystemName
                                            )
                                    )}
                                    vehicles={this.state.vehicles}
                                    vehicleGroups={this.state.vehicleGroups}
                                />
                            }
                            onHelperClick={
                                this.state.secrets.find(
                                    a => a.externalSystemAccess?.id === this.state.detailInitialData.externalSystemId
                                )?.externalSystemAccess?.externalSystemName === 'PUESC'
                                    ? this._onPuescHelperClick
                                    : undefined
                            }
                        />
                    ]}
                />

                {this.state.deleteConfirmModal.isOpen && (
                    <div className="expenses-confirm">
                        <Confirm
                            danger
                            header={t('SystemConnections.messages.deleteHeader')}
                            message={t('SystemConnections.messages.deleteConfirm')}
                            type={MessageType.WARNING}
                            loading={this.state.removeOtherSystemLoading}
                            confirmLabel={this.props.t('common.delete')}
                            onCancel={this._onTableRemoveCancelClick}
                            onConfirm={this._onTableRemoveConfirmClick}
                        />
                    </div>
                )}

                <HelperModal
                    name="puesc"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onPuescHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _onSystemConnectionFormCancel = () => {
        this.setState({
            detailMode: 'DEFAULT',
            detailInitialData: {}
        });
    };

    private _onDetailCreateNew = () => {
        this.setState({
            detailMode: 'CREATE',
            selectedExternalSystemSecretId: undefined,
            detailInitialData: {}
        });
    };

    private _getTableData = () => {
        return !this.state.loading && !this.state.vehiclesLoading
            ? this.state.secrets.map(es => ({
                  id: es.id ?? 0,
                  name: es.externalSystemAccess?.externalSystemName ?? '',
                  secret: es.secret ?? '',
                  vehicles: this.state.vehicles
                      .filter(v => {
                          return es.monitoredObjectGroup?.monitoredObjects?.flat().includes(v.id ?? 0);
                      })
                      .map(v => getRegistrationNumber(!!v.disabledAt, v.registrationNumber))
                      .join(', ')
              }))
            : [];
    };

    private _onActivatePuesc = async (values: SystemConnectFormValues) => {
        this.setState({
            activatePuescLoading: true
        });
        try {
            await this.props.logic.externalSystem().activatePuesc(values);
            this.props.logic.externalSystem().loadData();
            message.success(this.props.t('SystemConnectMessages.activatePuescSuccess'));
            this.setState({
                selectedExternalSystemSecretId: undefined,
                detailMode: 'DEFAULT',
                activatePuescLoading: false
            });
        } catch (err) {
            this.props.logic.externalSystem().loadData();
            console.error(`Can not activate PUESC, err: ${err}`);
            message.error(this.props.t('SystemConnectMessages.activatePuescError'));
            this.setState({
                selectedExternalSystemSecretId: undefined,
                detailMode: 'DEFAULT',
                activatePuescLoading: false
            });
        }
    };

    private _onPuescFormSubmit = async (values: PuescFormValues): Promise<boolean> => {
        try {
            values.data?.forEach(d => {
                d.monitoringDeviceId &&
                    this.props.logic
                        .api()
                        .monitoringDeviceApi.monitoringDevicePatchMetadata({
                            id: d.monitoringDeviceId,
                            data: {
                                puesc: {
                                    gps_locator_number: d.gpsLocatorNumber,
                                    state: d.state
                                }
                            }
                        })
                        .then(() => {
                            message.success(this.props.t('SystemConnections.puescForm.updateSuccess'));
                        })
                        .catch(err => {
                            console.error(`Can not update monitoredObjectGroup, err: ${err}`);
                            message.error(this.props.t('SystemConnectMessages.editError'));
                        });
            });

            this.props.logic.externalSystem().loadData();
            this.setState({
                selectedExternalSystemSecretId: undefined,
                detailMode: 'DEFAULT'
            });
            return true;
        } catch (err) {
            message.error(this.props.t('SystemConnectMessages.editError'));
            console.error(`Puesc form submit, err: ${err}`);
        }

        return false;
    };

    private _onPuescSystemConnectFormSubmit = async (values: PuescRegistrationModel) => {
        this.props.logic.exponea().trackEvent('settings_system-connections', {
            status: exponea.status.actionTaken,
            action: 'puesc_edit_save'
        });
        if (this.state.detailInitialData.externalSystemId && this.state.selectedExternalSystemSecretId) {
            try {
                await this.props.logic
                    .externalSystem()
                    .editExternalSystem(
                        this.state.selectedExternalSystemSecretId,
                        this.state.detailInitialData.externalSystemId,
                        values.metadata
                    );
                await this.props.logic
                    .externalSystem()
                    .editSharedVehicles(
                        this.state.detailInitialData.externalSystemId,
                        values.vehicles ?? [],
                        values.vehiclesGroup ?? [],
                        values.automaticVehicles
                    );
                this.props.logic.externalSystem().loadData();
                message.success(this.props.t('SystemConnectMessages.editSuccess'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            } catch (err) {
                this.props.logic.externalSystem().loadData();
                console.error(`Can not update monitoredObjectGroup, err: ${err}`);
                message.error(this.props.t('SystemConnectMessages.editError'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            }
        } else {
            try {
                if (!values.externalSystemId) {
                    console.error('could not create external syste, externalSystemId missing');
                    return false;
                }
                await this.props.logic
                    .externalSystem()
                    .createExternalSecret(
                        values.externalSystemId,
                        values.vehicles,
                        values.vehiclesGroup,
                        values.automaticVehicles,
                        values.metadata
                    );

                this.props.logic.externalSystem().loadData();
                message.success(this.props.t('SystemConnectMessages.createSuccess'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            } catch (err: any) {
                this.props.logic.externalSystem().loadData();
                if (externalSystemCreateError.includes(err.message)) {
                    this._handleExternalSecretError(err.message);
                } else {
                    message.error(this.props.t('SystemConnectMessages.createError'));
                }
                console.error(`Can not create new external access secret, err: ${err}`);
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            }
        }

        return true;
    };

    private _onSystemConnectionFormSubmit = async (values: SystemConnectFormValues) => {
        if (this.state.detailInitialData.externalSystemId) {
            try {
                await this.props.logic
                    .externalSystem()
                    .editSharedVehicles(
                        this.state.detailInitialData.externalSystemId,
                        values.vehicles,
                        values.vehiclesGroup ?? []
                    );
                this.props.logic.externalSystem().loadData();
                message.success(this.props.t('SystemConnectMessages.editSuccess'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            } catch (err) {
                this.props.logic.externalSystem().loadData();
                console.error(`Can not update monitoredObjectGroup, err: ${err}`);
                message.error(this.props.t('SystemConnectMessages.editError'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            }
        } else {
            try {
                await this.props.logic
                    .externalSystem()
                    .createExternalSecret(values.externalSystemId, values.vehicles, values.vehiclesGroup);
                this.props.logic.externalSystem().loadData();
                message.success(this.props.t('SystemConnectMessages.createSuccess'));
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            } catch (err: any) {
                this.props.logic.externalSystem().loadData();
                if (externalSystemCreateError.includes(err.message)) {
                    this._handleExternalSecretError(err.message);
                } else {
                    message.error(this.props.t('SystemConnectMessages.createError'));
                }
                console.error(`Can not create new external access secret, err: ${err}`);
                this.setState({
                    selectedExternalSystemSecretId: undefined,
                    detailMode: 'DEFAULT'
                });
            }
        }
    };

    private _handleExternalSecretError(errorMessage: typeof externalSystemCreateError[number]) {
        switch (errorMessage) {
            case 'externalSecretError':
                message.error(this.props.t('SystemConnectMessages.createError'));
                break;
            case 'missingClient':
                message.error(this.props.t('SystemConnectMessages.missingClientError'));
                break;
            case 'monitoredObjectGroupError':
                message.error(this.props.t('SystemConnectMessages.monitoredObjectGroupError'));
                break;
            case 'replaceAssetError':
                message.error(this.props.t('SystemConnectMessages.replaceAssetError'));
                break;
        }
    }

    private _onEditClick = (id: number) => {
        this.setState({
            detailMode: 'EDIT',
            systemConnectionLoading: true
        });
        const externalSystemSecret = this.state.secrets.find(s => s.id === id);
        const group = externalSystemSecret?.monitoredObjectGroup;

        if (group?.name === 'PUESC') {
            this.props.logic.exponea().trackEvent('settings_system-connections', {
                status: exponea.status.actionTaken,
                action: 'puesc_edit'
            });
        }

        Promise.all([
            this.props.logic.api().monitoredObjectApi.monitoredObjectList({
                idIn: this.state.vehicles.map(v => v.id).toString()
            }),
            this.props.logic.api().monitoringDeviceApi.monitoringDeviceList({
                client: this.props.logic.auth().client()?.id.toString()
            })
        ])
            .then(res => {
                const [monitoredObjects, monitoringDeviceList] = res;
                this.setState({
                    selectedExternalSystemSecretId: id,
                    detailInitialData: {
                        externalSystemId: externalSystemSecret?.externalSystemAccess?.id,
                        vehicles: group?.monitoredObjects,
                        automaticVehicleAdd: group?.automaticVehicleAdd,
                        metadata: externalSystemSecret?.metadata
                    },
                    puescInitialData: {
                        data: group?.monitoredObjects?.length
                            ? group?.monitoredObjects.map(d => {
                                  const monitoringDevice = monitoringDeviceList?.results.find(
                                      v => v.id === monitoredObjects?.results.find(v => v.id === d)?.monitoringDevice
                                  );
                                  return {
                                      monitoredObjectId: d ?? '',
                                      rn: this.state.vehicles.find(v => v.id === d)?.registrationNumber ?? '',
                                      monitoringDeviceId:
                                          monitoredObjects?.results.find(v => v.id === d)?.monitoringDevice ?? 0,
                                      obuSerialNumber: monitoringDevice?.serialNumber ?? '',
                                      gpsLocatorNumber:
                                          monitoringDevice?.metadata?.['puesc']?.['gps_locator_number'] ?? '',
                                      state: monitoringDevice?.metadata?.['puesc']?.['state'] ?? ''
                                  };
                              })
                            : []
                    },
                    systemConnectionLoading: false
                });
            })
            .catch(err => {
                this.setState({
                    detailMode: 'DEFAULT',
                    systemConnectionLoading: false
                });
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _getExternalSystemData = () => {
        const secret = this.state.secrets.find(s => s.id === this.state.selectedExternalSystemSecretId);
        return {
            secret: secret?.secret,
            name: secret?.externalSystemAccess?.externalSystemName
        };
    };

    private _onTableRemoveCancelClick = () => {
        this.setState({
            deleteConfirmModal: {
                isOpen: false,
                id: undefined
            }
        });
    };

    private _onTableRemoveConfirmClick = async () => {
        const id = this.state.deleteConfirmModal.id;
        if (!id) {
            console.error(`no external secret id`);
            message.error(this.props.t('SystemConnectMessages.removeError'));
            return;
        }
        this.setState({
            removeOtherSystemLoading: true
        });

        try {
            await this.props.logic.externalSystem().deleteExternalSecret(id);
            message.success(this.props.t('SystemConnectMessages.removeSuccess'));
            this.setState({
                detailMode: 'DEFAULT',
                selectedExternalSystemSecretId: undefined,
                deleteConfirmModal: {
                    isOpen: false
                }
            });
            this.props.logic.externalSystem().loadData();
        } catch (err) {
            console.error(`Can not create new external access secret, err: ${err}`);
            message.error(this.props.t('SystemConnectMessages.removeError'));
        }

        this.setState({
            removeOtherSystemLoading: false
        });
    };

    private _onTableRemoveClick = (id: number) => {
        this.setState({
            deleteConfirmModal: {
                isOpen: true,
                id
            }
        });
    };

    private _onTabChange = (value: string) => {
        this.props.history.push(value);
    };

    private _onPuescHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onPuescHelperClick = () => {
        const module: DocsUserGuide = 'puesc';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };
}

export default withRouter(withTranslation()(withLogicContext(OtherSystemsModule)));
