import { Component } from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { message, Typography, Row, Col } from 'antd';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { RouteNames, rbac } from 'App';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';

import { DriverBehaviorModel } from 'common/model/statistics';
import { Loading } from 'common/components';
import DriverBehaviorList from './List';
import DriverBehaviorDetail from './Detail';
import DriverLeaderboardTrendsTable from 'modules/statistics/modules/driver-behavior-new/components/DriverLeaderboardTrendsTable';
import { LayoutContent } from 'common/components/Layout/Content';
import qa from 'qa-selectors';
import DriversLeaderboardCard from 'modules/statistics/modules/driver-behavior-new/components/DriversLeaderboardCard';
import { TrendChange } from 'common/components/Trend';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { NoData } from 'common/components/NoData';
import UiTabBar from 'common/components/UiTabBar/UiTabBar';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
}

interface State {
    data: DriverBehaviorModel[];
    loading: boolean;
    sidePanelTab: 'top5improvers' | 'worst5drivers';
}

class DriverBehaviorModule extends Component<Props, State> {
    subscriptionData?: Subscription;
    subscriptionLoading?: Subscription;
    subscriptionError?: Subscription;
    private _logic: Logic;
    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {
            data: [],
            loading: true,
            sidePanelTab: 'top5improvers'
        };
    }

    componentDidMount() {
        this.subscriptionData = this.props.logic
            .driverBehavior()
            .trucks()
            .data.subscribe(data => {
                this.setState({ data: data, loading: false });
            });

        this.subscriptionLoading = this.props.logic
            .driverBehavior()
            .trucks()
            .loading.subscribe(loading => {
                this.setState({ loading });
            });

        this.subscriptionError = this.props.logic
            .driverBehavior()
            .trucks()
            .error.subscribe(error => {
                console.error(`Load data error, err: ${error}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
        this.props.logic.settings().setProp('driverBehavior', { date: moment().utc().startOf('month').toISOString() });
        this.props.logic.driverBehavior().trucks().loadTrendsData(moment.utc().startOf('month').toISOString());
    }

    componentWillUnmount() {
        this.subscriptionData?.unsubscribe();
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionError?.unsubscribe();
    }

    render() {
        const roles = this._logic.auth().roles();
        const { t } = this.props;
        const { loading } = this.state;
        const contextualData = this._logic.driverBehavior().trucks().getContextualData();
        const date = moment(this._logic.driverBehavior().trucks().getDate()).format('YYYY-MM-DD');

        const current = contextualData.find(d => d.start === date);
        const previous = contextualData.find(d => d.start === moment(date).subtract(1, 'month').format('YYYY-MM-DD'));

        return (
            <LayoutContent
                className="driver-behavior"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <Switch>
                        <Route
                            exact
                            path={RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST}
                            render={rbac(roles, [Role.DBH_R], <DriverBehaviorList logic={this._logic} />)}
                        />
                        <Route
                            exact
                            path={`${RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL}/:id`}
                            render={rbac(roles, [Role.DBH_R], <DriverBehaviorDetail logic={this._logic} />)}
                        />
                        <Redirect to={RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST} />
                    </Switch>
                }
                extra={[
                    !loading ? (
                        current ? (
                            <div className="driver-behavior-panel">
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <DriversLeaderboardCard
                                            type="fleetVehicles"
                                            value={Math.round(contextualData?.[0].clientAvgDriverOveralScore * 10) / 10}
                                            trend={
                                                previous && {
                                                    direction: getIncreaseDirection(
                                                        current.clientAvgDriverOveralScore,
                                                        previous.clientAvgDriverOveralScore
                                                    ),
                                                    value: getIncreasePercent(
                                                        current.clientAvgDriverOveralScore,
                                                        previous.clientAvgDriverOveralScore
                                                    ),
                                                    trendChangeForIncrease: TrendChange.POSITIVE
                                                }
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <DriversLeaderboardCard
                                            type="ewVehicles"
                                            value={Math.round(current.crossSystemAvgDriverOveralScore * 10) / 10}
                                            trend={
                                                previous && {
                                                    direction: getIncreaseDirection(
                                                        current.crossSystemAvgDriverOveralScore,
                                                        previous.crossSystemAvgDriverOveralScore
                                                    ),
                                                    value: getIncreasePercent(
                                                        current.crossSystemAvgDriverOveralScore,
                                                        previous.crossSystemAvgDriverOveralScore
                                                    ),
                                                    trendChangeForIncrease: TrendChange.POSITIVE
                                                }
                                            }
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <DriversLeaderboardCard
                                            type="ewDriver"
                                            value={
                                                Math.round(contextualData[0]?.crossSystemBestDriverOveralScore * 10) /
                                                10
                                            }
                                            trend={
                                                previous && {
                                                    direction: getIncreaseDirection(
                                                        contextualData[0]?.crossSystemBestDriverOveralScore,
                                                        contextualData[1]?.crossSystemBestDriverOveralScore
                                                    ),
                                                    value: getIncreasePercent(
                                                        contextualData[0]?.crossSystemBestDriverOveralScore,
                                                        contextualData[1]?.crossSystemBestDriverOveralScore
                                                    ),
                                                    trendChangeForIncrease: TrendChange.POSITIVE
                                                }
                                            }
                                        />
                                    </Col>
                                </Row>

                                {this._logic.driverBehavior().trucks().selectTopDriversTrends().length > 0 && (
                                    <>
                                        <Typography.Title level={4}>{t('DriverBehavior.topDrivers')}</Typography.Title>
                                        <DriverLeaderboardTrendsTable
                                            data={this._logic.driverBehavior().trucks().selectTopDriversTrends()}
                                            loading={loading}
                                            showHeader={false}
                                            onRowClick={this._onTableRowClick}
                                            className="drivers-leaderboard-table-top-drivers"
                                            qa={qa.driverBehaviour.side.topDrivers}
                                        />
                                    </>
                                )}
                                {this._logic.driverBehavior().trucks().selectTopImproversDriversTrends().length > 0 && (
                                    <Row justify="center">
                                        <Col>
                                            <UiTabBar
                                                options={[
                                                    {
                                                        key: 'top5improvers',
                                                        label: t('DriverBehavior.topImprovers')
                                                    },
                                                    {
                                                        key: 'worst5drivers',
                                                        label: t('DriverBehavior.worstDrivers')
                                                    }
                                                ]}
                                                selectedOption={this.state.sidePanelTab}
                                                onSelect={key =>
                                                    this.setState({
                                                        sidePanelTab: key as 'worst5drivers' | 'top5improvers'
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}

                                {this._logic.driverBehavior().trucks().selectTopImproversDriversTrends().length > 0 &&
                                    (this.state.sidePanelTab === 'top5improvers' ? (
                                        <DriverLeaderboardTrendsTable
                                            data={this._logic
                                                .driverBehavior()
                                                .trucks()
                                                .selectTopImproversDriversTrends()}
                                            loading={loading}
                                            showHeader={false}
                                            onRowClick={this._onTableRowClick}
                                            qa={qa.driverBehaviour.side.topImprovers}
                                        />
                                    ) : (
                                        <DriverLeaderboardTrendsTable
                                            data={this._logic.driverBehavior().trucks().selectWorstDriversTrends()}
                                            loading={loading}
                                            showHeader={false}
                                            onRowClick={this._onTableRowClick}
                                            qa={qa.driverBehaviour.side.worstDrivers}
                                        />
                                    ))}
                            </div>
                        ) : (
                            <NoData />
                        )
                    ) : (
                        <Loading />
                    )
                ]}
            />
        );
    }

    private _onTableRowClick = (id: string) => {
        this.props.logic.driverBehavior().trucks().setSelectedDriverId(id);
        if (id === this.props.logic.driverBehavior().trucks().comparedDriverId) {
            this.props.logic.driverBehavior().trucks().comparedDriverId = undefined;
        }
        this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL + '/' + id);
    };
}

export default withRouter(withTranslation()(DriverBehaviorModule));
