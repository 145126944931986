import { Row } from 'antd';
import React from 'react';
import cn from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ObuModel } from 'logic/partner/logic/partner-obu';
import { ObuTypeSelectModel } from 'logic/partner/partner';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { ReadOnlyMonitoringDeviceType } from 'generated/new-main';
import { Pagination, Table } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    loading: boolean;
    data?: PaginatedResponse<ObuModel[]>;
    selected?: ObuModel;
    obuTypeCounter?: ObuTypeSelectModel[];
    deviceTypes?: ReadOnlyMonitoringDeviceType[];
    onTableRowSelect?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function ObuTable(props: Props) {
    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    const { t, data, loading, selected, deviceTypes } = props;

    return (
        <div className="tlm-services-table partner-main-table">
            <Table<ObuModel>
                loading={loading}
                dataSource={data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id.toString()),
                    className: cn({
                        'ant-table-row-selected': selected?.id === record.id
                    })
                })}
            />

            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );

    function createColumns() {
        return [
            {
                title: t('Partner.sn'),
                dataIndex: 'sn',
                key: 'sn',
                width: 30,
                render: sn => sn
            },
            {
                title: t('Partner.client'),
                dataIndex: 'clientName',
                key: 'clientName',
                width: 60,
                render: clientName => clientName
            },
            {
                title: t('common.type'),
                dataIndex: 'type',
                key: 'type',
                width: 60,
                render: type => deviceTypes?.find(o => String(o.id)! === type)?.label
            },
            {
                title: t('Partner.status'),
                dataIndex: 'activatedOn',
                key: 'activatedOn',
                width: 45,
                render: activatedOn => activatedOn
            },
            {
                title: t('common.vehicle'),
                dataIndex: 'vehicleRN',
                key: 'vehicleRN',
                width: 45,
                render: (vehicleRN, record) => getRegistrationNumber(!!record.disabledAt, vehicleRN)
            }
        ] as ColumnType<ObuModel>[];
    }
}

export default withTranslation()(ObuTable);
