import cn from 'classnames';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TachoCardModel } from '../../tachograph-cards/TachoCardsModule';
import { PairingData, UserLinks } from '../UsersModule';
import Search from 'common/components/Search';
import { Loading } from 'common/components/Loading';
import { NoData } from 'common/components/NoData';
import { searched } from 'common/utils/search';
import FormActions from 'common/components/FormActions';
import { ReadOnlyMonitoredObjectFeSb, ReadOnlyUserNested } from 'generated/new-main/models';
import qa from 'qa-selectors';

function isTachoCard(obj: PairingData[]): obj is TachoCardModel[] {
    return 'token' in obj[0];
}

function isMonitoredObject(obj: PairingData[]): obj is ReadOnlyMonitoredObjectFeSb[] {
    return 'registrationNumber' in obj[0];
}

interface Props extends WithTranslation {
    data?: PairingData[]; // TODO: multiple types in future
    model?: ReadOnlyUserNested; // TODO: multiple types in future
    search?: string;
    selected?: PairingData;
    type?: keyof UserLinks;
    onCancelButtonClick?: () => void;
    onConfirmButtonClick?: () => void;
    onPairingItemSelect?: (selected: PairingData) => void;
    onPairingTypeChange?: (type: keyof UserLinks) => void;
    onSearchInputChange?: (text: string) => void;
}

type Links = keyof UserLinks;

function UsersPairing(props: Props) {
    function onConfirm(): void {
        props.onConfirmButtonClick?.();
    }

    function onCancel(): void {
        props.onCancelButtonClick?.();
    }

    function onLinkChange(type: keyof UserLinks): void {
        props.onPairingTypeChange?.(type);
    }

    function onItemSelect(selected: PairingData): void {
        props.onPairingItemSelect?.(selected);
    }

    function esc(e: React.KeyboardEvent<HTMLDivElement>): void {
        if (e.key === 'Escape') {
            props.onCancelButtonClick?.();
        }
    }

    function onSearch(text: string): void {
        props.onSearchInputChange?.(text);
    }

    function searchTachoFilter(data: TachoCardModel): boolean {
        return searched(props.search, data.token);
    }

    function searchMonitoredObject(data: ReadOnlyMonitoredObjectFeSb): boolean {
        return searched(props.search, data.registrationNumber);
    }

    const links: Links[][] = [['tachoCard'], ['monitoredObject']];
    const { t } = props;

    return (
        <div className="t-modal" onKeyDown={esc} tabIndex={0}>
            <div className="management-user-pairing t-modal-content t-card">
                <div className="t-bar t-large t-center t-padding-large">
                    <span className="t-uppercase">{`${t('ManagementUsers.pairing')} - `}</span>
                    <span className="t-bold t-uppercase">{`${props.model?.name} ${props.model?.surname}`}</span>
                </div>
                <div className="t-row">
                    <div
                        className="management-user-pairing-type t-half t-border-right"
                        data-qa={qa.users.pairing.sectionTypes}
                    >
                        {links.map(linkRow =>
                            linkRow.map((link, i, arr) => (
                                <Fragment key={i}>
                                    {i === 0 ? (
                                        <button
                                            className={cn('t-button t-block', {
                                                't-primary': props.type && arr.includes(props.type),
                                                't-bold': props.type && arr.includes(props.type)
                                            })}
                                            onClick={onLinkChange.bind(undefined, link)}
                                        >
                                            <span className="t-left">{t(`common.${link}`)}</span>
                                            {props.type && (
                                                <span className="t-right">
                                                    <i
                                                        className={cn(
                                                            'fas fa-fw',
                                                            arr.includes(props.type)
                                                                ? 'fa-angle-down'
                                                                : 'fa-angle-right'
                                                        )}
                                                    />
                                                </span>
                                            )}
                                        </button>
                                    ) : (
                                        <>
                                            {props.type && arr.includes(props.type) && (
                                                <button
                                                    className={cn('t-button t-block', {
                                                        't-bold': link === props.type
                                                    })}
                                                    onClick={onLinkChange.bind(undefined, link)}
                                                >
                                                    <span className="t-left t-margin-left">{link}</span>
                                                </button>
                                            )}
                                        </>
                                    )}
                                </Fragment>
                            ))
                        )}
                    </div>
                    <div className="t-half t-padding-small" data-qa={qa.users.pairing.sectionItems}>
                        <div className="t-row">
                            <Search searchActive={true} onSearch={onSearch} qa={qa.common.pairing.inputSearch} />
                        </div>
                        <div className="management-user-pairing-items t-margin-top t-margin-bottom t-overflow-y">
                            {!props.data ? (
                                <Loading />
                            ) : !props.data?.length ? (
                                <NoData />
                            ) : (
                                <>
                                    {isTachoCard(props.data) &&
                                        props.data?.filter(searchTachoFilter).map(t => (
                                            <button
                                                key={t.id}
                                                className={cn('t-button t-block t-ripple', {
                                                    't-primary': props.selected === t
                                                })}
                                                data-qa={qa.users.pairing.btnItemTacho}
                                                onClick={onItemSelect.bind(undefined, t)}
                                                title={t.token}
                                            >
                                                <div className="t-row">
                                                    <span className="t-left t-transform-none">{t.token}</span>
                                                </div>
                                            </button>
                                        ))}
                                    {isMonitoredObject(props.data) &&
                                        props.data
                                            ?.filter(searchMonitoredObject)
                                            .filter(mo => !mo.disabledAt)
                                            .map(mo => (
                                                <button
                                                    key={mo.id}
                                                    className={cn('t-button t-block t-ripple', {
                                                        't-primary': props.selected === mo
                                                    })}
                                                    data-qa={qa.users.pairing.btnItemVehicle}
                                                    onClick={onItemSelect.bind(undefined, mo)}
                                                    title={mo.registrationNumber}
                                                >
                                                    <div className="t-row t-transform-none">
                                                        <span className="t-left">{mo.registrationNumber}</span>
                                                    </div>
                                                </button>
                                            ))}
                                </>
                            )}
                        </div>
                        <FormActions
                            submitQa={qa.users.pairing.btnConfirm}
                            cancelQa={qa.users.pairing.btnCancel}
                            submitDisabled={
                                props.selected &&
                                isTachoCard([props.selected]) &&
                                !props.model?.userGroup?.find(r => r.label.toLocaleLowerCase() === 'driver')
                            }
                            onSubmitClick={onConfirm}
                            onCancelClick={onCancel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(UsersPairing);
