import React, { ReactNode } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = [
    ['#2c82be'],
    ['#2c82be', '#53a8e2'],
    ['#2c82be', '#53a8e2', '#c8eaf9'],
    ['#2c82be', '#53a8e2', '#c8eaf9', '#76ddfb'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db', '#7fbae2'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db', '#7fbae2', '#9fcbe9'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db', '#7fbae2', '#9fcbe9', '#bfdff1'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db', '#7fbae2', '#9fcbe9', '#bfdff1', '#dfeef8'],
    ['#1d5880', '#246ea0', '#2b84e0', '#3f98d4', '#5fa9db', '#7fbae2', '#9fcbe9', '#bfdff1', '#dfeef8', '#ffffff'],
    [
        '#164260',
        '#1d5880',
        '#246ea0',
        '#2b84e0',
        '#3f98d4',
        '#5fa9db',
        '#7fbae2',
        '#9fcbe9',
        '#bfdff1',
        '#dfeef8',
        '#ffffff'
    ]
];

interface Props {
    data: { name: ReactNode; value: number }[];
}

export default function PieChartComponent({ data }: Props) {
    return (
        <PieChart width={320} height={164}>
            <Tooltip />
            <Pie
                data={data}
                cx={76}
                cy={76}
                innerRadius={40}
                outerRadius={80}
                dataKey="value"
                strokeOpacity={0}
                fill="#8884d8"
            >
                {data.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={
                            data.length <= COLORS.length
                                ? COLORS[data.length - 1][index % COLORS.length]
                                : COLORS[COLORS.length - 1][index % COLORS.length]
                        }
                    />
                ))}
            </Pie>
            <Legend layout="vertical" verticalAlign="middle" align="right" />
        </PieChart>
    );
}
