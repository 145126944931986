import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { SelectField } from 'common/fields';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';
import { searched } from 'common/utils/search';
import { Input, Select } from 'common/components/Form';
import * as CommonIcons from 'resources/images/common';
import { ReadOnlyMonitoredObjectGroup } from 'generated/new-main/models';
import { RefSelectProps, SelectValue } from 'antd/lib/select';
import { SelectOption } from 'common/components/Form/Select';
import { Confirm } from 'common/components';
import { MessageType } from 'common/components/Confirm';
import cn from 'classnames';

export interface VehicleGroupsFormModel {
    vehicleGroupId: number;
    userIds: number[];
    vehicleIds: number[];
}

interface Props extends WithTranslation {
    initialValues?: Partial<VehicleGroupsFormModel>;
    monitoredObjectGroups: ReadOnlyMonitoredObjectGroup[];
    selectedMonitoredObjectGroupLoading?: boolean;
    userIdsOptions?: SelectOption[];
    vehicleIdsOptions?: SelectOption[];
    onCreateNewGroupSubmit: (values: string) => Promise<ReadOnlyMonitoredObjectGroup>;
    onEditGroupNameSubmit: (id: number, values: string) => Promise<boolean>;
    onDeleteGroupSubmit: (id: number) => Promise<boolean>;
    onSelectMonitoredObjectGroup: (value: number) => void;
    onReplaceAssetSubmit: (values: VehicleGroupsFormModel) => Promise<boolean>;
    onCancel: () => void;
}

interface State {
    crudGroup?: {
        type?: 'edit' | 'create' | 'delete';
        open?: boolean;
        value?: string;
        submitting?: boolean;
        id?: number;
    };
    vehicleGroupId?: number;
    selectedUsers: SelectOption[];
    selectedVehicles: { value: string; key?: number | string }[][];
}

class VehicleGroupsForm extends React.Component<Props, State> {
    private _selectRef: React.RefObject<RefSelectProps> = React.createRef();

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedUsers: [],
            selectedVehicles: []
        };
    }

    render() {
        const schema = Yup.object().shape({
            vehicleGroupId: Yup.number(),
            userIds: Yup.array(),
            vehicleIds: Yup.array()
        });

        return (
            <>
                <Formik<VehicleGroupsFormModel>
                    onSubmit={this._handleSubmit}
                    enableReinitialize
                    validationSchema={schema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    initialValues={{
                        vehicleGroupId: this.props.initialValues?.vehicleGroupId ?? 0,
                        userIds: this.props.initialValues?.userIds ?? [],
                        vehicleIds: this.props.initialValues?.vehicleIds ?? []
                    }}
                >
                    {(formik: FormikProps<VehicleGroupsFormModel>) => {
                        return (
                            <Form className="vehicle-group-form">
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col span={24}>
                                        <SelectField
                                            optionLabelProp="title"
                                            label={this.props.t('VehiclesGroupForm.listVehiclesGroup')}
                                            placeholder={this.props.t('VehiclesGroupForm.createNew')}
                                            innerRef={this._selectRef}
                                            loading={this.state.crudGroup?.submitting}
                                            onClear={() => {
                                                formik.setValues({
                                                    vehicleGroupId: 0,
                                                    userIds: [],
                                                    vehicleIds: []
                                                });
                                                this._onClear();
                                            }}
                                            name="vehicleGroupId"
                                            size="large"
                                            qa={qa.vehicleGroups.selectVehicleGroup}
                                            allowClear={true}
                                            onSelect={this._handleSelect}
                                            onDropdownVisibleChange={() => {
                                                if (this.state.crudGroup?.type !== 'delete') {
                                                    this.setState({
                                                        crudGroup: undefined
                                                    });
                                                }
                                            }}
                                            showSearch
                                            filterOption={(input, option) => searched(input, String(option?.title))}
                                            dropdownRender={menu => (
                                                <div className="vehicle-group-select-dropdown">
                                                    {this.state.crudGroup?.type === 'create' &&
                                                    this.state.crudGroup?.open ? (
                                                        <div className="select-group-item-open dark">
                                                            <Input
                                                                value={this.state.crudGroup?.value}
                                                                onChange={this._onCrudNewGroupNameChange}
                                                                autoFocus
                                                                qa={qa.vehicleGroups.form.inputNewGroupName}
                                                                onPressEnter={e => {
                                                                    if (e.code === 'Enter' && this.state.crudGroup) {
                                                                        if (
                                                                            this.state?.crudGroup?.value &&
                                                                            this.state?.crudGroup?.value?.trim()
                                                                                .length > 0
                                                                        ) {
                                                                            this._onCreateNewGroupSubmit(
                                                                                (id: number) => {
                                                                                    formik.setFieldValue(
                                                                                        'vehicleGroupId',
                                                                                        id
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <div className="select-action-buttons">
                                                                {this.state.crudGroup?.value &&
                                                                this.state.crudGroup?.value.trim().length > 0 &&
                                                                !this.state.crudGroup?.submitting ? (
                                                                    <img
                                                                        src={CommonIcons.checkCircleBlue}
                                                                        alt="accept"
                                                                        onClick={() =>
                                                                            this._onCreateNewGroupSubmit(
                                                                                (id: number) => {
                                                                                    formik.setFieldValue(
                                                                                        'vehicleGroupId',
                                                                                        id
                                                                                    );
                                                                                }
                                                                            )
                                                                        }
                                                                        data-qa={qa.vehicleGroups.form.btnNewGroupSave}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={CommonIcons.checkCircle}
                                                                        alt="add new vehicle group"
                                                                        className="disabled"
                                                                        data-qa={
                                                                            qa.vehicleGroups.form
                                                                                .btnNewGroupSaveDisabled
                                                                        }
                                                                    />
                                                                )}

                                                                <img
                                                                    onClick={this._onCrudGroupClose}
                                                                    src={CommonIcons.closeCircleBlue}
                                                                    alt="close"
                                                                    data-qa={qa.vehicleGroups.form.btnNewGroupCancel}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={this._onCreateNewGroup}
                                                            className="select-group-item dark"
                                                            data-qa={qa.vehicleGroups.form.btnNewGroup}
                                                        >
                                                            <img
                                                                src={CommonIcons.addPlusCircleBold}
                                                                alt="add new vehicle group"
                                                            />
                                                            <span>{this.props.t('VehiclesGroupForm.createNew')}</span>
                                                        </div>
                                                    )}
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            <Select.OptGroup label={this.props.t('VehiclesGroupForm.vehicleGroups')}>
                                                {this.props.monitoredObjectGroups.map((group, index) => (
                                                    <Select.Option
                                                        title={group.name}
                                                        key={`group-${index}-${group.id}`}
                                                        value={group.id ?? 0}
                                                        className={cn({
                                                            disabled:
                                                                this.state.crudGroup?.open &&
                                                                this.state.crudGroup?.id === group.id
                                                        })}
                                                    >
                                                        {this.state.crudGroup?.type === 'edit' &&
                                                        this.state.crudGroup.open &&
                                                        this.state.crudGroup.id === group.id ? (
                                                            <div className="select-group-item-open">
                                                                <Input
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    value={this.state.crudGroup?.value}
                                                                    onChange={this._onCrudNewGroupNameChange}
                                                                    autoFocus
                                                                    qa={qa.vehicleGroups.form.inputEditGroupName}
                                                                    onPressEnter={e => {
                                                                        if (
                                                                            e.code === 'Enter' &&
                                                                            this.state.crudGroup
                                                                        ) {
                                                                            if (
                                                                                this.state?.crudGroup?.value &&
                                                                                this.state?.crudGroup?.value?.trim()
                                                                                    .length > 0
                                                                            ) {
                                                                                this._onEditGroupNameSubmit(
                                                                                    (id: number) => {
                                                                                        formik.setFieldValue(
                                                                                            'vehicleGroupId',
                                                                                            id
                                                                                        );
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="select-action-buttons">
                                                                    {this.state.crudGroup?.value &&
                                                                    this.state.crudGroup?.value.trim().length > 0 &&
                                                                    !this.state.crudGroup?.submitting ? (
                                                                        <img
                                                                            src={CommonIcons.checkCircleBlue}
                                                                            alt="accept"
                                                                            data-qa={
                                                                                qa.vehicleGroups.form.btnEditGroupSave
                                                                            }
                                                                            onClick={() =>
                                                                                this._onEditGroupNameSubmit(
                                                                                    (id: number) => {
                                                                                        formik.setFieldValue(
                                                                                            'vehicleGroupId',
                                                                                            id
                                                                                        );
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={CommonIcons.checkCircle}
                                                                            alt="add new vehicle group"
                                                                            className="disabled"
                                                                        />
                                                                    )}

                                                                    <img
                                                                        onClick={this._onCrudGroupClose}
                                                                        src={CommonIcons.closeCircleBlue}
                                                                        alt="close"
                                                                        data-qa={
                                                                            qa.vehicleGroups.form.btnEditGroupCancel
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="select-group-item-open">
                                                                <div data-qa={qa.vehicleGroups.form.fieldGroupName}>
                                                                    {group.name}
                                                                </div>
                                                                <div className="select-action-buttons">
                                                                    {group.deletable && (
                                                                        <>
                                                                            <img
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    this._onEditGroupName(group.id);
                                                                                }}
                                                                                src={CommonIcons.editPencilFillBold}
                                                                                alt="edit"
                                                                                data-qa={
                                                                                    qa.vehicleGroups.form.btnEditGroup
                                                                                }
                                                                            />
                                                                            <img
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    this._onDeleteGroup(group.id);
                                                                                }}
                                                                                src={CommonIcons.trashFillBold}
                                                                                alt="remove"
                                                                                data-qa={
                                                                                    qa.vehicleGroups.form.btnDeleteGroup
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        </SelectField>
                                    </Col>
                                </Row>

                                {this.state.vehicleGroupId && this.state.vehicleGroupId > 0 && (
                                    <>
                                        <Row className="form-row" gutter={[20, 10]}>
                                            <Col span={24}>
                                                <label className="assign-label">
                                                    {this.props.t('VehiclesGroupForm.assignTitle')}
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="form-row" gutter={[20, 10]}>
                                            <Col span={24}>
                                                <SelectField
                                                    size="large"
                                                    mode="multiple"
                                                    loading={this.props.selectedMonitoredObjectGroupLoading}
                                                    options={this.props.vehicleIdsOptions}
                                                    name="vehicleIds"
                                                    label={this.props.t('common.vehicle')}
                                                    placeholder={this.props.t('common.vehicle')}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-row" gutter={[20, 10]}>
                                            <Col span={24}>
                                                <SelectField
                                                    size="large"
                                                    mode="multiple"
                                                    loading={this.props.selectedMonitoredObjectGroupLoading}
                                                    options={this.props.userIdsOptions}
                                                    name="userIds"
                                                    label={this.props.t('common.users')}
                                                    placeholder={this.props.t('common.users')}
                                                />
                                            </Col>
                                        </Row>
                                        <FormActions
                                            cancelQa={qa.fleet.form.btnCancel}
                                            submitQa={qa.fleet.form.btnSubmit}
                                            submitLoading={formik.isSubmitting}
                                            onCancelClick={this.props.onCancel}
                                        />
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
                {this.state.crudGroup?.type === 'delete' && this.state.crudGroup.open && (
                    <Confirm
                        danger
                        type={MessageType.WARNING}
                        header={this.props.t('VehiclesGroupForm.confirmDeleteHeader')}
                        loading={this.state.crudGroup?.submitting}
                        message={this.props.t('VehiclesGroupForm.confirmDeleteMessage')}
                        confirmLabel={this.props.t('common.delete')}
                        onConfirm={this._onDeleteGroupSubmit}
                        onCancel={() => {
                            this.setState({
                                crudGroup: undefined
                            });
                        }}
                    />
                )}
            </>
        );
    }
    private _onClear = () => {
        this.props.onSelectMonitoredObjectGroup(0);
        this.setState({
            crudGroup: undefined,
            vehicleGroupId: undefined,
            selectedUsers: [],
            selectedVehicles: []
        });
    };

    private _handleSubmit = (values: VehicleGroupsFormModel) => {
        return this.props.onReplaceAssetSubmit?.(values);
    };

    private _handleSelect = (value: SelectValue) => {
        this.setState({
            vehicleGroupId: value as number
        });
        this.props.onSelectMonitoredObjectGroup(value as number);
    };

    private _onEditGroupName = (groupId?: number) => {
        this.setState({
            crudGroup: {
                open: true,
                value: this.props.monitoredObjectGroups.find(group => group.id === groupId)?.name,
                type: 'edit',
                id: groupId
            }
        });
    };

    private _onEditGroupNameSubmit = async (callback: (id: number) => void) => {
        this.setState({
            crudGroup: {
                ...this.state.crudGroup,
                type: 'edit',
                open: true,
                submitting: true
            }
        });

        if (this.state.crudGroup?.value && this.state.crudGroup?.id) {
            try {
                const monitoredObjectGroup = await this.props.onEditGroupNameSubmit(
                    this.state.crudGroup?.id,
                    this.state.crudGroup?.value
                );

                if (monitoredObjectGroup) {
                    if (!this.state.vehicleGroupId) {
                        this.props.onSelectMonitoredObjectGroup(this.state.crudGroup?.id);
                        callback(this.state.crudGroup?.id);
                        this.setState(state => ({
                            crudGroup: undefined,
                            vehicleGroupId: state.vehicleGroupId ? state.vehicleGroupId : this.state.crudGroup?.id
                        }));
                        this._selectRef.current?.blur();
                    } else {
                        this.setState({
                            crudGroup: undefined
                        });
                    }
                } else {
                    this.setState(state => ({
                        crudGroup: {
                            ...state,
                            submitting: false
                        }
                    }));
                }
            } catch (err) {
                this.setState(state => ({
                    crudGroup: {
                        ...state,
                        submitting: false
                    }
                }));
            }
        }

        this.setState({
            crudGroup: undefined
        });
    };

    private _onDeleteGroupSubmit = async () => {
        this.setState(state => ({
            crudGroup: {
                ...state.crudGroup,
                submitting: true
            }
        }));
        if (this.state.crudGroup?.id) {
            try {
                await this.props.onDeleteGroupSubmit(this.state.crudGroup?.id);
                this.setState({
                    crudGroup: undefined
                });
            } catch (err) {
                this.setState(state => ({
                    crudGroup: {
                        ...state.crudGroup,
                        submitting: false
                    }
                }));
            }
        }
    };

    private _onDeleteGroup = (groupId?: number) => {
        this.setState(state => ({
            crudGroup: {
                open: true,
                type: 'delete',
                id: groupId
            },
            vehicleGroupId: state.vehicleGroupId === groupId ? undefined : state.vehicleGroupId
        }));
    };

    private _onCreateNewGroup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        this.setState({
            crudGroup: {
                type: 'create',
                open: true
            }
        });
    };

    private _onCreateNewGroupSubmit = async (callback: (id: number) => void) => {
        this.setState({
            crudGroup: {
                ...this.state.crudGroup,
                type: 'create',
                open: true,
                submitting: true
            }
        });
        if (this.state.crudGroup?.value) {
            try {
                const monitoredObjectGroup = await this.props.onCreateNewGroupSubmit(this.state.crudGroup?.value);
                if (monitoredObjectGroup.id) {
                    callback(monitoredObjectGroup.id);
                    this.setState({
                        crudGroup: undefined,
                        vehicleGroupId: monitoredObjectGroup.id
                    });
                    this._selectRef.current?.blur();
                }
            } catch (err) {
                this.setState(state => ({
                    crudGroup: {
                        ...state.crudGroup,
                        submitting: false
                    }
                }));
            }
        }
    };

    private _onCrudNewGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        this.setState(state => ({
            crudGroup: {
                ...state.crudGroup,
                value: e.target.value
            }
        }));
    };

    private _onCrudGroupClose = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();
        this.setState({
            crudGroup: undefined
        });
    };
}

export default withTranslation()(VehicleGroupsForm);
