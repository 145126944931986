import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Table, Pagination } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import cn from 'classnames';
import { WhitelabelModel, WhitelabelTemplateModel } from 'logic/partner/logic/partner-whitelabel';
import { Button, Col, Row } from 'antd';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import WhitelabelLogo from './WhitelabelLogo';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    data?: PaginatedResponse<WhitelabelTemplateModel[]>;
    loading?: boolean;
    selected?: string;
    active?: WhitelabelModel;
    logo?: string;
    onRowAction?: (id: string) => void;
    onRowDelete?: (id: string) => void;
    onRowActivate?: (id: string) => void;
    onRowDeactivate?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
    onLogoSubmit?: (logo?: string) => void;
}

function WhitelabelTemplates(props: Props) {
    const { loading, data, selected } = props;

    return (
        <div className="whitelabel-templates-table">
            <Table<WhitelabelTemplateModel>
                scroll={{ y: 300 }}
                loading={loading}
                dataSource={data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                onRow={record => ({
                    className: cn({
                        'ant-table-row-selected': record.id === selected
                    })
                })}
            />

            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}

            <WhitelabelLogo logo={props.logo} onSubmit={props.onLogoSubmit} />
        </div>
    );

    function createColumns() {
        return [
            {
                title: props.t(`common.label`),
                dataIndex: 'name',
                key: 'name',
                width: 30,
                render: (_, record) => (
                    <span className={cn({ active: record.id === props.active?.template })}>{record.name}</span>
                )
            },
            {
                title: '',
                dataIndex: 'client',
                key: 'client',
                width: 120,
                align: 'right',
                render: (_, record) => (
                    <Row gutter={8} justify="end">
                        {props.active?.template !== record.id && record.client !== undefined && (
                            <Col>
                                <Button type="primary" onClick={() => props.onRowActivate?.(record.id)}>
                                    <i className="fas fa-fw fa-tint" />
                                    {props.t('common.activate')}
                                </Button>
                            </Col>
                        )}
                        {props.active && props.active.template === record.id && (
                            <Col>
                                <Button type="primary" onClick={() => props.onRowDeactivate?.(props.active!.id!)}>
                                    <i className="fas fa-fw fa-tint-slash" />
                                    {props.t('common.deactivate')}
                                </Button>
                            </Col>
                        )}
                        <Col>
                            <Button type="primary" onClick={() => props.onRowAction?.(record.id)}>
                                {record.client ? (
                                    <>
                                        <i className="fas fa-fw fa-pen" />
                                        {props.t('common.edit')}
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-fw fa-plus" />
                                        {props.t('common.clone')}
                                    </>
                                )}
                            </Button>
                        </Col>
                        {record.client && record.id !== props.active?.template && (
                            <Col>
                                <Button type="default" onClick={() => props.onRowDelete?.(record.id)}>
                                    <i className="fas fa-fw fa-trash-alt" />
                                </Button>
                            </Col>
                        )}
                    </Row>
                )
            }
        ] as ColumnType<WhitelabelTemplateModel>[];
    }
}

export default withTranslation()(WhitelabelTemplates);
