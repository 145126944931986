/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorMessage,
    ApiErrorMessageFromJSON,
    ApiErrorMessageToJSON,
    ApiInfoMessage,
    ApiInfoMessageFromJSON,
    ApiInfoMessageToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    VehicleUnavailability,
    VehicleUnavailabilityFromJSON,
    VehicleUnavailabilityToJSON,
    VehicleUnavailabilityForUpdate,
    VehicleUnavailabilityForUpdateFromJSON,
    VehicleUnavailabilityForUpdateToJSON,
    VehicleUnavailabilityInDB,
    VehicleUnavailabilityInDBFromJSON,
    VehicleUnavailabilityInDBToJSON,
} from '../models';

export interface CreateUnavailabilityV1TransportsUnavailabilityPostRequest {
    xTlmActionId: string;
    vehicleUnavailability: VehicleUnavailability;
}

export interface DeleteUnavailabilityV1TransportsUnavailabilityIdDeleteRequest {
    id: string;
    xTlmResourceVersion: number;
}

export interface GetUnavailabilityV1TransportsUnavailabilityIdGetRequest {
    id: string;
}

export interface ListUnavailabilityV1TransportsUnavailabilityGetRequest {
    startTime: Date;
    endTime: Date;
    moids?: Array<string>;
}

export interface UpdateUnavailabilityV1TransportsUnavailabilityIdPutRequest {
    id: string;
    xTlmResourceVersion: number;
    vehicleUnavailabilityForUpdate: VehicleUnavailabilityForUpdate;
}

/**
 * no description
 */
export class TransportsUnavailabilityApi extends runtime.BaseAPI {

    /**
     * Create an unavailability window for a vehicle.
     * Create Unavailability
     */
    async createUnavailabilityV1TransportsUnavailabilityPostRaw(requestParameters: CreateUnavailabilityV1TransportsUnavailabilityPostRequest): Promise<runtime.ApiResponse<ApiInfoMessage>> {
        if (requestParameters.xTlmActionId === null || requestParameters.xTlmActionId === undefined) {
            throw new runtime.RequiredError('xTlmActionId','Required parameter requestParameters.xTlmActionId was null or undefined when calling createUnavailabilityV1TransportsUnavailabilityPost.');
        }

        if (requestParameters.vehicleUnavailability === null || requestParameters.vehicleUnavailability === undefined) {
            throw new runtime.RequiredError('vehicleUnavailability','Required parameter requestParameters.vehicleUnavailability was null or undefined when calling createUnavailabilityV1TransportsUnavailabilityPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTlmActionId !== undefined && requestParameters.xTlmActionId !== null) {
            headerParameters['x-tlm-action-id'] = String(requestParameters.xTlmActionId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/unavailability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleUnavailabilityToJSON(requestParameters.vehicleUnavailability),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInfoMessageFromJSON(jsonValue));
    }

    /**
     * Create an unavailability window for a vehicle.
     * Create Unavailability
     */
    async createUnavailabilityV1TransportsUnavailabilityPost(requestParameters: CreateUnavailabilityV1TransportsUnavailabilityPostRequest): Promise<ApiInfoMessage> {
        const response = await this.createUnavailabilityV1TransportsUnavailabilityPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a vehicle unavailability window by ID and version. In reality, this action doesn\'t delete the record from DB. Instead, it sets the field `deleted` to `True`.  We don\'t report to user whether the document got updated: Mongo knows whether it needs to update the document or not.  Instead, we check the number of matched_docs. If it\'s zero, we know that user provided an invalid ID or version, therefore we return 404.
     * Delete Unavailability
     */
    async deleteUnavailabilityV1TransportsUnavailabilityIdDeleteRaw(requestParameters: DeleteUnavailabilityV1TransportsUnavailabilityIdDeleteRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUnavailabilityV1TransportsUnavailabilityIdDelete.');
        }

        if (requestParameters.xTlmResourceVersion === null || requestParameters.xTlmResourceVersion === undefined) {
            throw new runtime.RequiredError('xTlmResourceVersion','Required parameter requestParameters.xTlmResourceVersion was null or undefined when calling deleteUnavailabilityV1TransportsUnavailabilityIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTlmResourceVersion !== undefined && requestParameters.xTlmResourceVersion !== null) {
            headerParameters['x-tlm-resource-version'] = String(requestParameters.xTlmResourceVersion);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete a vehicle unavailability window by ID and version. In reality, this action doesn\'t delete the record from DB. Instead, it sets the field `deleted` to `True`.  We don\'t report to user whether the document got updated: Mongo knows whether it needs to update the document or not.  Instead, we check the number of matched_docs. If it\'s zero, we know that user provided an invalid ID or version, therefore we return 404.
     * Delete Unavailability
     */
    async deleteUnavailabilityV1TransportsUnavailabilityIdDelete(requestParameters: DeleteUnavailabilityV1TransportsUnavailabilityIdDeleteRequest): Promise<object> {
        const response = await this.deleteUnavailabilityV1TransportsUnavailabilityIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicle unavailability by ID.
     * Get Unavailability
     */
    async getUnavailabilityV1TransportsUnavailabilityIdGetRaw(requestParameters: GetUnavailabilityV1TransportsUnavailabilityIdGetRequest): Promise<runtime.ApiResponse<VehicleUnavailabilityInDB>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUnavailabilityV1TransportsUnavailabilityIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleUnavailabilityInDBFromJSON(jsonValue));
    }

    /**
     * Get vehicle unavailability by ID.
     * Get Unavailability
     */
    async getUnavailabilityV1TransportsUnavailabilityIdGet(requestParameters: GetUnavailabilityV1TransportsUnavailabilityIdGetRequest): Promise<VehicleUnavailabilityInDB> {
        const response = await this.getUnavailabilityV1TransportsUnavailabilityIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * List vehicle unavailability windows between two selected datetimes, optionally filtering by MonitoredObject IDs.
     * List Unavailability
     */
    async listUnavailabilityV1TransportsUnavailabilityGetRaw(requestParameters: ListUnavailabilityV1TransportsUnavailabilityGetRequest): Promise<runtime.ApiResponse<Array<VehicleUnavailabilityInDB>>> {
        if (requestParameters.startTime === null || requestParameters.startTime === undefined) {
            throw new runtime.RequiredError('startTime','Required parameter requestParameters.startTime was null or undefined when calling listUnavailabilityV1TransportsUnavailabilityGet.');
        }

        if (requestParameters.endTime === null || requestParameters.endTime === undefined) {
            throw new runtime.RequiredError('endTime','Required parameter requestParameters.endTime was null or undefined when calling listUnavailabilityV1TransportsUnavailabilityGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['end_time'] = (requestParameters.endTime as any).toISOString();
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/unavailability/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleUnavailabilityInDBFromJSON));
    }

    /**
     * List vehicle unavailability windows between two selected datetimes, optionally filtering by MonitoredObject IDs.
     * List Unavailability
     */
    async listUnavailabilityV1TransportsUnavailabilityGet(requestParameters: ListUnavailabilityV1TransportsUnavailabilityGetRequest): Promise<Array<VehicleUnavailabilityInDB>> {
        const response = await this.listUnavailabilityV1TransportsUnavailabilityGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a vehicle unavailability window by ID and version.
     * Update Unavailability
     */
    async updateUnavailabilityV1TransportsUnavailabilityIdPutRaw(requestParameters: UpdateUnavailabilityV1TransportsUnavailabilityIdPutRequest): Promise<runtime.ApiResponse<VehicleUnavailabilityInDB>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUnavailabilityV1TransportsUnavailabilityIdPut.');
        }

        if (requestParameters.xTlmResourceVersion === null || requestParameters.xTlmResourceVersion === undefined) {
            throw new runtime.RequiredError('xTlmResourceVersion','Required parameter requestParameters.xTlmResourceVersion was null or undefined when calling updateUnavailabilityV1TransportsUnavailabilityIdPut.');
        }

        if (requestParameters.vehicleUnavailabilityForUpdate === null || requestParameters.vehicleUnavailabilityForUpdate === undefined) {
            throw new runtime.RequiredError('vehicleUnavailabilityForUpdate','Required parameter requestParameters.vehicleUnavailabilityForUpdate was null or undefined when calling updateUnavailabilityV1TransportsUnavailabilityIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTlmResourceVersion !== undefined && requestParameters.xTlmResourceVersion !== null) {
            headerParameters['x-tlm-resource-version'] = String(requestParameters.xTlmResourceVersion);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/unavailability/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleUnavailabilityForUpdateToJSON(requestParameters.vehicleUnavailabilityForUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleUnavailabilityInDBFromJSON(jsonValue));
    }

    /**
     * Update a vehicle unavailability window by ID and version.
     * Update Unavailability
     */
    async updateUnavailabilityV1TransportsUnavailabilityIdPut(requestParameters: UpdateUnavailabilityV1TransportsUnavailabilityIdPutRequest): Promise<VehicleUnavailabilityInDB> {
        const response = await this.updateUnavailabilityV1TransportsUnavailabilityIdPutRaw(requestParameters);
        return await response.value();
    }

}
