import { Component, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import PlacesAutocompleteModule, {
    RouteSelect,
    RouteSelectData
} from 'modules/routing/places-autocomplete/PlacesAutocompleteModule';
import { Button } from 'common/components';
import { Subject } from 'rxjs';
import { RefSelectProps } from 'antd/lib/select';

interface Props extends WithTranslation {
    loading?: boolean;
    onAddRoute?: (value: RouteSelectData) => void;
}

interface State {
    selectedRoute?: RouteSelectData;
}

class PlannerAutocomplete extends Component<Props, State> {
    private _addRouteButtonRef = createRef<HTMLButtonElement>();
    private _autocompleteRef = createRef<RefSelectProps>();
    private _searchValueSubject = new Subject<string>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="planner-autocomplete">
                <PlacesAutocompleteModule
                    size="large"
                    className="planner-autocomplete-suggestions"
                    autofocus
                    updateSearchValue={this._searchValueSubject}
                    innerref={this._autocompleteRef}
                    onSelect={this._onRouteSelect}
                />
                <Button
                    type="dashed"
                    data-qa={qa.schedulingPlanning.addRoute}
                    innerref={this._addRouteButtonRef}
                    disabled={!this.state.selectedRoute}
                    onClick={this._onSubmit}
                    loading={this.props.loading}
                >
                    {this.props.t('PoiInfobox.addToRoute')}
                </Button>
            </div>
        );
    }

    private _onRouteSelect: RouteSelect = data => {
        this.setState(
            {
                selectedRoute: data
            },
            () => {
                this._addRouteButtonRef.current?.focus();
            }
        );
    };

    private _onSubmit = (): void => {
        if (this.state.selectedRoute) {
            this.props.onAddRoute?.(this.state.selectedRoute);
            this.setState({
                selectedRoute: undefined
            });
            this._searchValueSubject.next('');
            this._autocompleteRef.current?.focus();
        }
    };
}

export default withTranslation()(PlannerAutocomplete);
