import { Col, Row } from 'antd';
import { Button } from 'common/components';
import { ReactNode, createRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { bannerArrows, bannerBust, bannerDispatcher, bannerDriver, bannerDriverWheel } from 'resources/images/common';

interface Props {
    className?: string;
    title?: ReactNode | string;
    text?: ReactNode | string;
    icon?: 'bust' | 'driver' | 'driverWheel' | 'dispatcher';
    iconOrientation?: 'left' | 'right';
    iconHeight?: number;
    button?: boolean;
    buttonLabel?: string;
    buttonPositionBottom?: boolean;
    qa?: string;
    qaCloseButton?: string;
    decoration?: boolean;
    onLinkButtonClick?: () => void;
    onCloseButtonClick?: () => void;
}

export default function InfoBanner(props: Props) {
    const [_iconHeight, _setIconHeight] = useState(45);
    const contentRef = createRef<HTMLDivElement>();

    useEffect(() => {
        _setIconHeight(props.iconHeight ? props.iconHeight : contentRef.current?.offsetHeight ?? 70);
    }, [props.iconHeight, contentRef]);

    const getIcon = () => {
        switch (props.icon) {
            case 'driver':
                return bannerDriver;
            case 'driverWheel':
                return bannerDriverWheel;
            case 'bust':
                return bannerBust;
            default:
                return bannerDispatcher;
        }
    };

    const background = {
        backgroundImage: `url("${bannerArrows}")`,
        backgroundRepeat: 'repeat-x'
    };

    const iconStyle = {
        height: `${_iconHeight}px`
    };

    return (
        <div className={`info-banner ${props.className}`} data-qa={props.qa}>
            {props.onCloseButtonClick && (
                <Button
                    className="close-button"
                    type="default"
                    onClick={props.onCloseButtonClick}
                    qa={props.qaCloseButton}
                >
                    X
                </Button>
            )}
            <div className="info-banner-container">
                <Row
                    align="middle"
                    gutter={4}
                    className={cn('info-banner-header', {
                        'info-banner-header-left': props.iconOrientation === 'left',
                        'info-banner-header-right': props.iconOrientation === 'right'
                    })}
                >
                    {props.iconOrientation === 'left' && (
                        <Col className="image">
                            <div className="oval" />
                            <img style={iconStyle} src={getIcon()} alt="info-banner-img" />
                        </Col>
                    )}
                    <Col
                        ref={contentRef}
                        flex={1}
                        style={props.onCloseButtonClick ? { marginRight: '25px' } : undefined}
                    >
                        <Row className="title" dangerouslySetInnerHTML={{ __html: String(props.title) }} />
                        <Row className="text" dangerouslySetInnerHTML={{ __html: String(props.text) }} />
                        {props.button && props.buttonPositionBottom && (
                            <Col className="button button-bottom">
                                <Button type="primary" onClick={props.onLinkButtonClick}>
                                    {props.buttonLabel}
                                </Button>
                            </Col>
                        )}
                    </Col>
                    {props.button && !props.buttonPositionBottom && (
                        <Col className="button">
                            <Button type="primary" onClick={props.onLinkButtonClick}>
                                {props.buttonLabel}
                            </Button>
                        </Col>
                    )}
                    {props.iconOrientation === 'right' && (
                        <Col className="image">
                            <div className="oval" />
                            <img style={iconStyle} src={getIcon()} alt="info-banner-img" />
                        </Col>
                    )}
                </Row>
            </div>
            {props.decoration !== false && <div className="arrows" style={background} />}
        </div>
    );
}
