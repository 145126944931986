/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileFormat,
    FileFormatFromJSON,
    FileFormatToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetStazkaReportV1DocumentStazkaGetRequest {
    transportId: string;
    fileFormat: FileFormat;
}

export interface GetTemperatureLogV1DocumentTemperatureLogGetRequest {
    timestampFrom: Date;
    timestampTo: Date;
    monitoredObjectId: string;
    mock?: boolean;
}

/**
 * no description
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Get Stazka Report
     */
    async getStazkaReportV1DocumentStazkaGetRaw(requestParameters: GetStazkaReportV1DocumentStazkaGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling getStazkaReportV1DocumentStazkaGet.');
        }

        if (requestParameters.fileFormat === null || requestParameters.fileFormat === undefined) {
            throw new runtime.RequiredError('fileFormat','Required parameter requestParameters.fileFormat was null or undefined when calling getStazkaReportV1DocumentStazkaGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.transportId !== undefined) {
            queryParameters['transport_id'] = requestParameters.transportId;
        }

        if (requestParameters.fileFormat !== undefined) {
            queryParameters['file_format'] = requestParameters.fileFormat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/document/stazka`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Stazka Report
     */
    async getStazkaReportV1DocumentStazkaGet(requestParameters: GetStazkaReportV1DocumentStazkaGetRequest): Promise<void> {
        await this.getStazkaReportV1DocumentStazkaGetRaw(requestParameters);
    }

    /**
     * Get Temperature Log
     */
    async getTemperatureLogV1DocumentTemperatureLogGetRaw(requestParameters: GetTemperatureLogV1DocumentTemperatureLogGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.timestampFrom === null || requestParameters.timestampFrom === undefined) {
            throw new runtime.RequiredError('timestampFrom','Required parameter requestParameters.timestampFrom was null or undefined when calling getTemperatureLogV1DocumentTemperatureLogGet.');
        }

        if (requestParameters.timestampTo === null || requestParameters.timestampTo === undefined) {
            throw new runtime.RequiredError('timestampTo','Required parameter requestParameters.timestampTo was null or undefined when calling getTemperatureLogV1DocumentTemperatureLogGet.');
        }

        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling getTemperatureLogV1DocumentTemperatureLogGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestampFrom !== undefined) {
            queryParameters['timestamp_from'] = (requestParameters.timestampFrom as any).toISOString();
        }

        if (requestParameters.timestampTo !== undefined) {
            queryParameters['timestamp_to'] = (requestParameters.timestampTo as any).toISOString();
        }

        if (requestParameters.mock !== undefined) {
            queryParameters['_mock_'] = requestParameters.mock;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/document/temperature-log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Temperature Log
     */
    async getTemperatureLogV1DocumentTemperatureLogGet(requestParameters: GetTemperatureLogV1DocumentTemperatureLogGetRequest): Promise<void> {
        await this.getTemperatureLogV1DocumentTemperatureLogGetRaw(requestParameters);
    }

}
