import { ReactNode, createRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { iconCompanies, iconCountry, iconToll } from 'resources/images/driver-behavior';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'common/components';
import { Row } from 'antd';
import { DriverBehaviorCoachDataMap } from '../DriverBehaviorCoach';
import { DriverStatValueFormat } from '../DriverStatValue';
import { isNumber } from '@turf/turf';
import { roundToStep } from 'common/utils/averages';
import { DriverBehaviorCoachTableLabel } from './DriverBehaviorCoachTableLabel';
import { DriverBehaviorCoachTableStar } from './DriverBehaviorCoachTableStar';
import { formatSecondsToString } from 'common/utils/dateTimeUtils';
import DriverBehaviorIconExclamation from './DriverBehaviorIconExclamation';

export function driverBehaviorCoachTableFormatValue(
    value?: CoachTableValue,
    unit?: DriverStatValueFormat,
    decimalPlaces?: number
): ReactNode | string {
    const step = decimalPlaces && decimalPlaces > 0 ? Math.pow(10, decimalPlaces * -1) : 1;

    if (value === undefined) {
        return '-';
    } else if (isNumber(value)) {
        switch (unit) {
            case 'duration':
            case 'durationTime':
                return formatSecondsToString(Math.round(Number(value)));
            case 'percentage':
                return `${roundToStep(Number(value), step).toFixed(decimalPlaces)}%`;
            case 'ratio':
                return `${roundToStep(Number(value), step).toFixed(decimalPlaces)} : 1`;
            case 'km':
            case 'kmPerHour':
            case 'litres':
            case 'litresPerKm':
            default:
                return `${roundToStep(Number(value), step).toFixed(decimalPlaces)}`;
        }
    }
    return value;
}

type CoachTableValue = ReactNode | string | number | boolean;
export interface DriverBehaviorCoachTableCellValues {
    [key: string]: CoachTableValue;
}
export interface DriverBehaviorCoachTableValues {
    highway?: DriverBehaviorCoachTableCellValues;
    urban?: DriverBehaviorCoachTableCellValues;
    country?: DriverBehaviorCoachTableCellValues;
    other?: DriverBehaviorCoachTableCellValues;
    summary?: DriverBehaviorCoachTableCellValues;
    exclamation?: DriverBehaviorCoachTableCellValues;
}
export interface DriverBehaviorCoachTableModel {
    name?: ReactNode | string;
    values?: DriverBehaviorCoachTableValues;
}

export enum DriverBehaviorCoachTableColors {
    drive = 'rgba(51, 143, 255, 0.16)',
    eco = 'rgba(27, 218, 126, 0.16)',
    wearTear = 'rgba(255, 210, 46, 0.16)'
}

interface Props {
    title: string;
    dataMap?: DriverBehaviorCoachDataMap[];
    labels?: (ReactNode | string)[];
    driversList?: DriverBehaviorCoachTableModel[];
    collapsedRows: number;
    selectedKey?: string;
    color: string;
    hoverColor?: string;
    expanded?: boolean;
    className?: string;
    starColor?: 'green' | 'yellow';
    qa?: string;
    onExpanded?: (expanded: boolean) => void;
}

export default function DriverBehaviorCoachTable(props: Props) {
    const [expanded, setExpanded] = useState(props.expanded);
    const [height, setHeight] = useState('auto');
    const tableRef = createRef<HTMLTableElement>();
    const multi = (props.driversList?.length ?? 0) > 1;

    useEffect(() => {
        const row = tableRef.current?.querySelector(
            `table tbody tr:nth-child(${props.collapsedRows})`
        ) as HTMLTableRowElement;

        setHeight(row ? `${68 + row.offsetTop + row.offsetHeight}px` : 'auto');
        if (!row) {
            setExpanded(true);
        }
    }, [multi, props.collapsedRows, tableRef]);

    const { t } = useTranslation();
    const collapsedHeight = expanded ? 'auto' : height;
    const style = {
        height: collapsedHeight
    };
    const colorStyle = {
        backgroundColor: props.color
    };

    const handlerExpandButtonClicked = () => {
        setExpanded(!expanded);
        props.onExpanded?.(!expanded);
    };

    const getStar = (starWeight?: number): ReactNode | string => {
        if (!starWeight) {
            return '';
        }
        return (
            <DriverBehaviorCoachTableStar
                size={starWeight === 1 ? 8 : starWeight === 2 ? 12 : 16}
                color={props.starColor}
            />
        );
    };

    return (
        <div
            className={cn(`driver-behavior-coach-table${props.className ? ` ${props.className}` : ''}`, {
                'driver-behavior-coach-table-multi': multi,
                'driver-behavior-coach-table-collapsed': expanded !== true
            })}
            style={style}
            data-qa={props.qa}
        >
            <table ref={tableRef}>
                <thead>
                    <tr>
                        <th className="driver-behavior-coach-table-title">{props.title}</th>
                        {multi && <th />}
                        <th className="value">
                            <Tooltip
                                title={t('DriverBehaviorDetail.export.tableTitle.highway')}
                                overlayClassName="management-fleet-module-tooltip"
                            >
                                <img src={iconToll} alt="icon-toll" />
                            </Tooltip>
                        </th>
                        <th className="value">
                            <Tooltip
                                title={t('DriverBehaviorDetail.export.tableTitle.country')}
                                overlayClassName="management-fleet-module-tooltip"
                            >
                                <img src={iconCountry} alt="icon-country" />
                            </Tooltip>
                        </th>
                        <th className="value">
                            <Tooltip
                                title={t('DriverBehaviorDetail.export.tableTitle.city')}
                                overlayClassName="management-fleet-module-tooltip"
                            >
                                <img src={iconCompanies} alt="icon-companies" />
                            </Tooltip>
                        </th>
                        <th className="value">{t('common.other')}</th>
                        <th className="value" style={colorStyle}>
                            {t('common.summary')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.dataMap?.map((dataMap, index) => (
                        <tr
                            key={index}
                            data-index={index}
                            className={cn('', {
                                multi: multi
                            })}
                            style={props.selectedKey === dataMap.key ? colorStyle : undefined}
                        >
                            <td className="title">
                                <DriverBehaviorCoachTableLabel
                                    dataMap={dataMap}
                                    showExclamation={
                                        multi
                                            ? false
                                            : props.driversList?.[0]?.values?.exclamation?.[dataMap.key] === true
                                            ? true
                                            : false
                                    }
                                />
                            </td>
                            {multi && (
                                <td className="name">
                                    {props.driversList?.map((driver, index) => (
                                        <div key={index}>
                                            {driver.name}
                                            {driver.values?.exclamation?.[dataMap.key] === true && (
                                                <DriverBehaviorIconExclamation />
                                            )}
                                        </div>
                                    ))}
                                </td>
                            )}
                            <td className="value">
                                {props.driversList?.map((driver, index) => (
                                    <div key={index}>
                                        {driverBehaviorCoachTableFormatValue(
                                            driver.values?.highway?.[dataMap.key],
                                            dataMap.unit,
                                            dataMap.decimals
                                        )}
                                        {((multi && index > 0) || (!multi && index === 0)) &&
                                            getStar(dataMap.starWeight)}
                                    </div>
                                ))}
                            </td>
                            <td className="value">
                                {props.driversList?.map((driver, index) => (
                                    <div key={index}>
                                        {driverBehaviorCoachTableFormatValue(
                                            driver.values?.country?.[dataMap.key],
                                            dataMap.unit,
                                            dataMap.decimals
                                        )}
                                        {((multi && index > 0) || (!multi && index === 0)) &&
                                            getStar(dataMap.starWeight)}
                                    </div>
                                ))}
                            </td>
                            <td className="value">
                                {props.driversList?.map((driver, index) => (
                                    <div key={index}>
                                        {driverBehaviorCoachTableFormatValue(
                                            driver.values?.urban?.[dataMap.key],
                                            dataMap.unit,
                                            dataMap.decimals
                                        )}
                                        {((multi && index > 0) || (!multi && index === 0)) &&
                                            getStar(dataMap.starWeight)}
                                    </div>
                                ))}
                            </td>
                            <td className="value">
                                {props.driversList?.map((driver, index) => (
                                    <div key={index}>
                                        {driverBehaviorCoachTableFormatValue(
                                            driver.values?.other?.[dataMap.key],
                                            dataMap.unit,
                                            dataMap.decimals
                                        )}
                                        {((multi && index > 0) || (!multi && index === 0)) &&
                                            getStar(dataMap.starWeight)}
                                    </div>
                                ))}
                            </td>
                            <td className="value" style={colorStyle}>
                                {props.driversList?.map((driver, index) => (
                                    <div key={index}>
                                        {driverBehaviorCoachTableFormatValue(
                                            driver.values?.summary?.[dataMap.key],
                                            dataMap.unit,
                                            dataMap.decimals
                                        )}
                                        {((multi && index > 0) || (!multi && index === 0)) &&
                                            getStar(dataMap.starWeight)}
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Row
                className={cn('driver-behavior-coach-table-action', {
                    'driver-behavior-coach-table-action-collapsed': expanded !== true
                })}
                align="middle"
            >
                <Button onClick={handlerExpandButtonClicked}>
                    {expanded ? t('DriverBehaviorCollapse.close') : t('common.showMore')}
                </Button>
            </Row>
        </div>
    );
}
