import moment from 'moment';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import {
    ContactListListTypeEnum,
    MaintenanceTask,
    MaintenanceTaskCompleteRequest,
    MaintenanceTaskList,
    MaintenanceTaskRepetitionDueDateTypeEnum,
    MaintenanceTaskSummaryList,
    NotificationReceivers,
    ReadOnlyContactList,
    ReadOnlyContactListTypeEnum,
    ReadOnlyCountry,
    ReadOnlyMaintenanceTask,
    ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum,
    ReadOnlyMaintenanceTaskStateEnum,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyTaskType,
    ReadOnlyUser,
    ReadOnlyUserRole,
    TaskTypeCategoryEnum,
    WriteOnlyContactListTypeEnum
} from 'generated/new-main';
import { Logic } from 'logic/logic';
import {
    MaintenanceRepetitionIntervalEnum,
    MaintenanceTaskTableModel,
    FilterMaintenanceTasks
} from 'modules/statistics/modules/maintenance/MaintenanceModule';
import { PaginatedResponse } from 'common/model/pagination';
import { DriverModel } from 'logic/user/users';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { RecipientModel } from 'common/forms/MaintenanceForm';
import { ContactList } from 'common/model/client-contact';

export class MaintenanceLogic {
    logic: Logic;

    @observable maintenanceTasks: PaginatedResponse<ReadOnlyMaintenanceTask[]>;
    @observable maintenanceTasksTable: PaginatedResponse<MaintenanceTaskTableModel[]>;
    @observable maintenanceTaskTypes: ReadOnlyTaskType[];
    @observable maintenanceSummary?: MaintenanceTaskSummaryList;
    @observable drivers: DriverModel[];
    @observable vehicles: ReadOnlyMonitoredObjectFeSb[];
    @observable trailers: ReadOnlyMonitoredObjectFeSb[];
    @observable roles: ReadOnlyUserRole[];
    @observable countryList: ReadOnlyCountry[];

    @observable clientContactList: ReadOnlyContactList[];
    @observable maintenanceFilter: FilterMaintenanceTasks;
    @observable usersSearch: ReadOnlyUser[];
    @observable forDeletionMaintenanceTasks: MaintenanceTaskTableModel[];
    @observable forCompletionMaintenanceTasks: MaintenanceTaskTableModel[];
    @observable selectedMaintenanceTask?: MaintenanceTaskTableModel;
    @observable checkedMaintenanceTasks: MaintenanceTaskTableModel[];
    @observable detailedMaintenanceTaskId?: string;
    @observable maintenanceNotificationReceivers?: NotificationReceivers[];

    @observable loadingClientContactList?: boolean;
    @observable loadingMaintenanceTasks?: boolean;
    @observable loadingCreateUpdateMaintenanceTasks?: boolean;
    @observable loadingCompleteMaintenanceTasks?: boolean;
    @observable loadingExport?: boolean;
    @observable loadingInit?: boolean;
    @observable loadingUsersSearch?: boolean;
    @observable loadingMaintenanceNotificationReceivers?: boolean;
    @observable loadingCountryList?: boolean;

    constructor(logic: Logic) {
        this.logic = logic;

        this.maintenanceTasks = {
            data: [],
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET,
            total: 0
        };
        this.maintenanceTasksTable = {
            data: [],
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET,
            total: 0
        };

        this.drivers = [];
        this.vehicles = [];
        this.trailers = [];
        this.roles = [];
        this.clientContactList = [];
        this.countryList = [];
        this.usersSearch = [];
        this.maintenanceFilter = {
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        };
        this.maintenanceTaskTypes = [];
        this.checkedMaintenanceTasks = [];
        this.forDeletionMaintenanceTasks = [];
        this.forCompletionMaintenanceTasks = [];

        makeObservable(this);
    }

    @action
    async init(maintenanceTaskId?: string) {
        try {
            this.loadingInit = true;
            const fleet = await this.logic.vehicles().getMonitoredObjectFilters(false, false, undefined, false, false);
            this.drivers = await this.logic.users().drivers();
            this.drivers = this.drivers.sort(
                (a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
            );
            this.vehicles = fleet.filter(v => (v.fleetType as FleetType) !== FleetType.TRAILER);
            this.trailers = fleet.filter(v => (v.fleetType as FleetType) === FleetType.TRAILER);
            this.roles = await this.logic.users().roles().getUserRoles();
            this._fetchClientContactList();
            this._fetchCountryList();
            this.maintenanceSummary = await this._fetchMaintenanceSummary();
            this.maintenanceTaskTypes = await this._fetchMaintenanceTaskTypes();

            this.maintenanceTasksTable = await this._fetchMaintenanceTasks({
                limit: this.maintenanceTasks.limit,
                offset: this.maintenanceTasks.offset,
                taskId: maintenanceTaskId
            });
        } catch (err) {
            console.error('Loading maintenance init error');
            throw err;
        } finally {
            this.loadingInit = false;
        }
    }

    @action
    async _fetchClientContactList() {
        if (this.logic.demo().isActive) {
            this.clientContactList = this.logic
                .demo()
                .data.contactList.filter(client => client.type?.includes(ReadOnlyContactListTypeEnum.Supplier));
            return;
        }

        this.loadingClientContactList = true;
        try {
            const contactList = await this.logic
                .clientContactLogic()
                .getAllClientContactList([ContactListListTypeEnum.Supplier]);
            runInAction(() => {
                this.clientContactList = contactList;
            });
        } catch (err) {
            console.error('Could not fetch client contact list');
            throw err;
        } finally {
            this.loadingClientContactList = false;
        }
    }

    @action
    async setMaintenanceFilter(filter: FilterMaintenanceTasks) {
        this.maintenanceFilter = filter;
    }

    @action
    async filterUsers(search: string) {
        this.usersSearch = await this._fetchUsersSearch(search);
    }

    @action
    async setSelectedMaintenanceTask(maintenanceTask?: MaintenanceTaskTableModel) {
        this.selectedMaintenanceTask = maintenanceTask;
        if (this.selectedMaintenanceTask?.id) {
            await this._fetchMaintenanceNotificationReceivers(this.selectedMaintenanceTask?.id);
        }
    }

    @action
    async setDetailedMaintenanceTaskId(id?: string) {
        if (id) {
            this.detailedMaintenanceTaskId = id;
            await this._fetchMaintenanceNotificationReceivers(id);
        } else {
            this.detailedMaintenanceTaskId = undefined;
        }
        this.setMaintenanceFilter({ ...this.maintenanceFilter, taskId: undefined });
    }

    @action
    setCheckedMaintenanceTasks(maintenanceTaskIds: string[]) {
        this.checkedMaintenanceTasks = maintenanceTaskIds.flatMap(id =>
            this.maintenanceTasksTable.data.filter(task => task.id === id)
        );
    }

    @action
    unsetCheckedMaintenanceTasks() {
        this.checkedMaintenanceTasks = [];
    }

    @action
    async reloadMaintenance() {
        this.maintenanceTasksTable = await this._fetchMaintenanceTasks({
            ...this.maintenanceFilter,
            taskId: this.detailedMaintenanceTaskId ? this.detailedMaintenanceTaskId : undefined
        });
        this.maintenanceTaskTypes = await this._fetchMaintenanceTaskTypes();
        this.maintenanceSummary = await this._fetchMaintenanceSummary();
        this.maintenanceNotificationReceivers = undefined;

        if (this.selectedMaintenanceTask) {
            this.setSelectedMaintenanceTask(
                this.maintenanceTasksTable.data.find(task => task.id === this.selectedMaintenanceTask?.id)
            );
        }
    }

    @action
    async _fetchMaintenanceNotificationReceivers(maintenanceTaskId: string) {
        try {
            this.loadingMaintenanceNotificationReceivers = true;

            if (this.logic.demo().isActive) {
                this.maintenanceNotificationReceivers = this.logic
                    .demo()
                    .data.maintenanceTaskReceivers.map(receiver => ({
                        ...receiver,
                        name: receiver.name ?? undefined
                    }));
            } else {
                const result = await this.logic
                    .api()
                    .maintenanceTaskApi.maintenanceTaskUpdateNotificationRecievers({ id: maintenanceTaskId });
                this.maintenanceNotificationReceivers = result.map(receiver => ({
                    ...receiver,
                    name: receiver.name ?? undefined
                }));
            }
        } catch (err) {
            console.error(`Failed to fetch maintenance task, err: ${err}`);
            throw err;
        } finally {
            this.loadingMaintenanceNotificationReceivers = false;
        }
    }

    @action
    private async _fetchMaintenanceTask(id: string): Promise<PaginatedResponse<MaintenanceTaskTableModel[]>> {
        try {
            this.loadingMaintenanceTasks = true;

            if (this.logic.demo().isActive) {
                const maintenanceTask = this.logic.demo().data.maintenanceTasks.find(task => task.id === id);
                this.maintenanceTasks = {
                    ...this.maintenanceTasks,
                    data: maintenanceTask ? [maintenanceTask] : []
                };

                return {
                    data: maintenanceTask ? [this._toMaintenanceTaskTableType(maintenanceTask)] : [],
                    limit: 0,
                    offset: 0,
                    total: 0
                };
            }

            const res = await this.logic.api().maintenanceTaskApi.maintenanceTaskRead({ id });

            this.maintenanceTasks = {
                ...this.maintenanceTasks,
                data: [res],
                total: 1
            };

            return {
                data: [this._toMaintenanceTaskTableType(res)],
                limit: 0,
                offset: 0,
                total: 0
            };
        } catch (err) {
            console.error(`Failed to fetch maintenance task, err: ${err}`);
            throw err;
        } finally {
            this.loadingMaintenanceTasks = false;
        }
    }

    @action
    private async _fetchMaintenanceTasks(
        filter: FilterMaintenanceTasks
    ): Promise<PaginatedResponse<MaintenanceTaskTableModel[]>> {
        if (this.logic.demo().isActive) {
            const data = this.logic.demo().data.maintenanceTasks.map(d => this._toMaintenanceTaskTableType(d));
            const maintenanceTasks = filter.taskId ? data.filter(task => task.id === filter.taskId) : data;
            return {
                ...this.maintenanceTasks,
                data: maintenanceTasks,
                total: maintenanceTasks.length
            };
        }

        try {
            this.loadingMaintenanceTasks = true;
            const client = String(this.logic.auth().client()?.id!);

            const res = await this.logic.api().maintenanceTaskApi.maintenanceTaskList({
                ...filter,
                client,
                taskId: filter.taskId ? filter.taskId : undefined
            });

            this.maintenanceTasks = {
                ...this.maintenanceTasks,
                data: res.results,
                total: res.count
            };

            const data = {
                data: this.maintenanceTasks.data.map(d => this._toMaintenanceTaskTableType(d)),
                limit: res.limit ?? filter.limit,
                offset: res.offset ?? filter.offset,
                total: this.maintenanceTasks.total
            };

            if (filter.taskId) {
                this.setSelectedMaintenanceTask(data.data?.find(task => task?.id === filter.taskId));
            }

            return data;
        } catch (err) {
            console.error(`Failed to fetch maintenance tasks, err: ${err}`);
            throw err;
        } finally {
            this.loadingMaintenanceTasks = false;
        }
    }

    @action
    async exportMaintenanceTasks(
        includeStates?: string[],
        monitoredObjects?: number[],
        users?: number[],
        taskTypeIds?: string,
        intervalIsDate?: boolean,
        intervalIsKm?: boolean,
        dueDateGte?: string,
        dueDateLte?: string
    ): Promise<MaintenanceTaskTableModel[]> {
        if (this.logic.demo().isActive) {
            return [];
        }

        try {
            this.loadingExport = true;
            const client = String(this.logic.auth().client()?.id!);

            const res = await this.logic.api().maintenanceTaskApi.maintenanceTaskList({
                client: client,
                includeStates,
                monitoredObjects,
                users,
                intervalIsDate,
                taskTypeIds,
                intervalIsKm,
                dueDateGte,
                dueDateLte
            });

            this.maintenanceTasks = {
                ...this.maintenanceTasks,
                data: res.results,
                total: res.count
            };

            return this.maintenanceTasks.data.map(d => {
                return this._toMaintenanceTaskTableType(d);
            });
        } catch (err) {
            console.error(`Failed to fetch maintenance tasks, err: ${err}`);
            throw err;
        } finally {
            this.loadingExport = false;
        }
    }

    @action
    async _fetchMaintenanceTaskTypes(): Promise<ReadOnlyTaskType[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.maintenanceTaskTypes;
        }

        const clientId = String(this.logic.auth().client()?.id!);
        try {
            const res = await this.logic.api().maintenanceTaskTypeApi.maintenanceTaskTypeList({
                clientIdOrNull: clientId
            });
            return res.results;
        } catch (err) {
            console.error('Get maintenance task type list err', err);
            throw err;
        }
    }

    @action
    async createClientContact(client: ContactList) {
        const clientId = this.logic.auth().client()?.id;
        if (!clientId) {
            throw new Error('No client id in auth');
        }

        const result = await this.logic.clientContactLogic().createClientContact({
            ...client,
            countryId: client.country?.id ?? undefined,
            client: clientId,
            type: [WriteOnlyContactListTypeEnum.Supplier]
        });
        this._fetchClientContactList();
        return result;
    }

    @action
    async _fetchMaintenanceSummary(): Promise<MaintenanceTaskSummaryList> {
        const clientId = String(this.logic.auth().client()?.id!);
        try {
            if (this.logic.demo().isActive) {
                return this.logic.demo().data.maintenanceTasksSummary;
            } else {
                return await this.logic.api().maintenanceTaskApi.maintenanceTaskSummary({
                    client: clientId
                });
            }
        } catch (err) {
            console.error('Get maintenance summary err', err);
            throw err;
        }
    }

    @action
    async _getMaintenanceTaskOfMaintenanceTaskType(maintenanceTaskTypeId: string): Promise<number> {
        try {
            const client = String(this.logic.auth().client()?.id!);

            const res = await this.logic.api().maintenanceTaskApi.maintenanceTaskList({
                client: client,
                taskTypeIds: maintenanceTaskTypeId.toString()
            });
            return res.count;
        } catch (err) {
            console.error(`Failed to count maintenance task, err: ${err}`);
            throw err;
        }
    }

    @action
    async deleteMaintenanceTasks(maintenanceTasks: string[]): Promise<boolean> {
        try {
            await this.logic.statisticsMaintenance().deleteMaintenanceTasks(maintenanceTasks);
            return true;
        } catch (err) {
            console.error(`Failed to delete maintenance tasks, err: ${err}`);
            throw err;
        }
    }

    @action
    async deleteMaintenanceTaskTypeWithTasks(maintenanceTaskTypeId: string): Promise<boolean> {
        try {
            await this.logic.statisticsMaintenance().deleteMaintenanceTaskTypeWithTasks(maintenanceTaskTypeId);
            return true;
        } catch (err) {
            console.error(`Failed to delete maintenance task type with tasks, err: ${err}`);
            throw err;
        }
    }

    @action
    async editMaintenanceTaskType(maintenanceTaskTypeId: string, name: string): Promise<boolean> {
        try {
            await this.logic.statisticsMaintenance().editMaintenanceTaskType(maintenanceTaskTypeId, name);
            return true;
        } catch (err) {
            console.error(`Failed to edit maintenance task, err: ${err}`);
            throw err;
        }
    }

    @action
    async completeMaintenanceTask(completeMaintenanceTask: MaintenanceTaskCompleteRequest): Promise<boolean> {
        this.loadingCompleteMaintenanceTasks = true;
        try {
            await this.logic.statisticsMaintenance().completeMaintenanceTask(completeMaintenanceTask);
            return true;
        } catch (err) {
            console.error(`Failed to complete maintenance task, err: ${err}`);
            throw err;
        } finally {
            this.loadingCreateUpdateMaintenanceTasks = false;
        }
    }

    @action
    async createMaintenanceTask(maintenanceTask: MaintenanceTaskList): Promise<boolean> {
        this.loadingCreateUpdateMaintenanceTasks = true;
        try {
            await this.logic.statisticsMaintenance().createMaintenanceTask(maintenanceTask);
            return true;
        } catch (err) {
            console.error(`Failed to create maintenance task, err: ${err}`);
            throw err;
        } finally {
            this.loadingCreateUpdateMaintenanceTasks = false;
        }
    }

    @action
    async updateMaintenanceTask(maintenanceTask: MaintenanceTask, id: string, forceUpdate?: boolean): Promise<boolean> {
        this.loadingCreateUpdateMaintenanceTasks = true;
        try {
            await this.logic.statisticsMaintenance().updateMaintenanceTask(maintenanceTask, id, forceUpdate);
            return true;
        } catch (err) {
            console.error('Update maintenance task err', err);
            throw err;
        } finally {
            this.loadingCreateUpdateMaintenanceTasks = false;
        }
    }

    @action
    async _fetchUsersSearch(textSearch: string): Promise<ReadOnlyUser[]> {
        try {
            this.loadingUsersSearch = false;
            const res = await this.logic.users().getUsers(DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, textSearch);
            return res.data;
        } catch (err) {
            console.error('Get users err', err);
            throw err;
        } finally {
            this.loadingUsersSearch = false;
        }
    }

    private async _fetchCountryList() {
        this.loadingCountryList = true;
        try {
            const response = await this.logic
                .api()
                .countryApi.countryList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
            runInAction(() => {
                this.countryList = response.results;
            });
        } catch (err) {
            console.error(`could not get country list, err :${err}`);
            throw err;
        } finally {
            runInAction(() => {
                this.loadingCountryList = false;
            });
        }
    }

    private _calculateProgressPercent(progress?: number, all?: number): number {
        const minVisibleProgress = 1;
        return all && progress ? Math.max(100 - (progress / all) * 100, minVisibleProgress) : minVisibleProgress;
    }

    private _toMaintenanceTaskTableType(m: ReadOnlyMaintenanceTask): MaintenanceTaskTableModel {
        const category = this._identifyCategory(m.taskType?.category ?? TaskTypeCategoryEnum.Company);

        const dueDateLeftDays = m.dueDateLeftSeconds
            ? Math.ceil(Number(m.dueDateLeftSeconds) / (3600 * 24))
            : undefined;
        const dueMileageLeftKilometers = m.dueMileageLeftKilometers
            ? Math.abs(Number(m.dueMileageLeftKilometers))
            : undefined;

        const notificationRecipients = this._toMaintenanceTableRecipients(m.notificationRecievers);

        let dueProgressPercent: number | undefined;
        if (
            [ReadOnlyMaintenanceTaskStateEnum.Active, ReadOnlyMaintenanceTaskStateEnum.Duesoon].includes(
                m.state as ReadOnlyMaintenanceTaskStateEnum
            )
        ) {
            if ((m.repetitionDueDateValue && m.repetitionDueDateType) || m.repetitionDueMileageKilometers) {
                const intervalDays =
                    m.repetitionDueDateValue && m.repetitionDueDateType
                        ? this._identifyIntervalDays(m.repetitionDueDateValue, m.repetitionDueDateType)
                        : 0;
                const intervalKilometers = m.repetitionDueMileageKilometers ?? 0;

                dueProgressPercent = Math.max(
                    this._calculateProgressPercent(dueDateLeftDays, intervalDays),
                    this._calculateProgressPercent(dueMileageLeftKilometers, intervalKilometers)
                );
            } else {
                const durationDays = m.createdAt
                    ? moment.duration(moment(m.dueDate).diff(moment(m.createdAt))).asDays()
                    : 0;
                const durationKilometers = m.dueMileageKilometers ?? 0;
                dueProgressPercent = Math.max(
                    this._calculateProgressPercent(dueDateLeftDays, durationDays),
                    durationKilometers
                        ? this._calculateProgressPercent(dueMileageLeftKilometers, durationKilometers)
                        : 0
                );
            }
        }

        return {
            id: m.id ?? '',
            category: category,
            taskType: m.taskType,
            state: m.state,
            clientId: m.client ?? undefined,
            createdAt: moment(m.createdAt).toISOString(),
            createdBy: m.createdBy,
            vehicleId: m.monitoredObject && category === TaskTypeCategoryEnum.Vehicle ? m.monitoredObject : undefined,
            vehicle:
                m.monitoredObject && category === TaskTypeCategoryEnum.Vehicle
                    ? this.vehicles.find(v => v.id === m.monitoredObject)?.registrationNumber
                    : undefined,
            trailerId: m.monitoredObject && category === TaskTypeCategoryEnum.Trailer ? m.monitoredObject : undefined,
            trailer:
                m.monitoredObject && category === TaskTypeCategoryEnum.Trailer
                    ? this.trailers.find(v => v.id === m.monitoredObject)?.registrationNumber
                    : undefined,
            driverId: m.user && category === TaskTypeCategoryEnum.Driver ? m.user : undefined,
            driver:
                m.user && category === TaskTypeCategoryEnum.Driver
                    ? this.drivers
                          .map(d => ({ id: d.id, name: `${d.name} ${d.surname}` }))
                          .find(v => v.id === String(m.user))?.name
                    : undefined,
            company:
                category === TaskTypeCategoryEnum.Company ? String(this.logic.auth().client()?.name ?? '') : undefined,
            dueMileageKilometers: m.dueMileageKilometers ?? undefined,
            dueDate: m.dueDate ? moment(m.dueDate).toISOString() : undefined,
            repetitionBindToDueDate: m.repetitionBindToDueDate,
            repetitionInterval:
                m.repetitionDueDateValue && m.repetitionDueDateType
                    ? this._identifyInterval(m.repetitionDueDateValue, m.repetitionDueDateType)
                    : undefined,
            repetitionDueMileageKilometers: m.repetitionDueMileageKilometers ?? undefined,
            notificationDueDateDays: m.notificationDueDateDays ?? undefined,
            notificationDueMileageKilometers: m.notificationDueMileageKilometers ?? undefined,
            notificiationRecipients: notificationRecipients ?? [],
            repetitionDueDateValue: m.repetitionDueDateValue ?? undefined,
            repetitionDueDateType: this._identifyRepetitionDueDateType(m.repetitionDueDateType),
            notes: m.notes ?? undefined,
            completionTime: m.completionTime ? moment(m.completionTime).toISOString() : undefined,
            completionMileageKilometers: m.completionMileageKilometers ?? undefined,
            completedBy: m.completedBy,
            dueDateLeftDays,
            dueMileageLeftKilometers,
            dueProgressPercent,
            lastEvaluationMileage: m.lastEvaluationMileage ?? undefined,
            expenses: m.expenses
                ? {
                      contact: m.expenses.contact,
                      currency: m.expenses.currency,
                      items: m.expenses?.items ?? [],
                      priceTotalWoVat: m.expenses.priceTotalWoVat,
                      vat: m.expenses.vat
                  }
                : undefined,
            updatedAt: m.updatedAt
        };
    }

    private _toMaintenanceTableRecipients = (notificationRecipients: object | null | undefined): RecipientModel[] => {
        const recipients: RecipientModel[] = [];
        const recipientsSeparated = notificationRecipients
            ? Object.values(notificationRecipients).map(m => {
                  let entityName;
                  switch (m.entity) {
                      case 'user': {
                          const user = this.usersSearch.find(d => Number(d.id) === m.entityId);
                          entityName = `${user?.name} ${user?.surname}`;
                          break;
                      }
                      case 'role':
                          entityName = this.roles.find(d => d.id === m.entityId)?.name;
                          break;
                      case 'external':
                      default:
                          entityName = m.entityId ?? '';
                          break;
                  }
                  return {
                      entity: m.entity,
                      channel: m.channel,
                      entityId: m.entityId,
                      entityName
                  };
              })
            : [];

        recipientsSeparated.forEach(receiver => {
            if (
                recipients.filter(
                    n =>
                        (receiver.entity === 'user' && receiver.entityId === n.userId) ||
                        (receiver.entity === 'role' && receiver.entityId === n.userRoleId)
                ).length === 0
            ) {
                const filtered = recipientsSeparated.filter(
                    r => r.entityId === receiver.entityId && r.entity === receiver.entity
                );
                recipients.push({
                    name: receiver.entityName,
                    emailNotification: filtered?.filter(d => d.channel === 'email')?.length > 0 ? true : false,
                    systemNotification: filtered?.filter(d => d.channel === 'app')?.length > 0 ? true : false,
                    userId: receiver.entity === 'user' ? receiver.entityId : undefined,
                    userRoleId: receiver.entity === 'role' ? receiver.entityId : undefined,
                    email: receiver.entity === 'external'
                });
            }
        });

        return recipients;
    };

    private _identifyRepetitionDueDateType = (
        value?: ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum
    ): MaintenanceTaskRepetitionDueDateTypeEnum | undefined => {
        switch (value) {
            case ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum.Day:
                return MaintenanceTaskRepetitionDueDateTypeEnum.Day;
            case ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum.Month:
                return MaintenanceTaskRepetitionDueDateTypeEnum.Month;
            case ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum.Week:
                return MaintenanceTaskRepetitionDueDateTypeEnum.Week;
            case ReadOnlyMaintenanceTaskRepetitionDueDateTypeEnum.Year:
                return MaintenanceTaskRepetitionDueDateTypeEnum.Year;
            default:
                return undefined;
        }
    };

    private _identifyCategory = (category: string): TaskTypeCategoryEnum => {
        switch (category) {
            case 'vehicle':
                return TaskTypeCategoryEnum.Vehicle;
            case 'trailer':
                return TaskTypeCategoryEnum.Trailer;
            case 'driver':
                return TaskTypeCategoryEnum.Driver;
            case 'company':
            default:
                return TaskTypeCategoryEnum.Company;
        }
    };

    private _identifyInterval = (value: number, type: string): MaintenanceRepetitionIntervalEnum => {
        const interval = `${type}_${value}`;
        switch (interval) {
            case 'day_1':
                return MaintenanceRepetitionIntervalEnum.Day1;
            case 'week_1':
                return MaintenanceRepetitionIntervalEnum.Week1;
            case 'week_2':
                return MaintenanceRepetitionIntervalEnum.Week2;
            case 'month_1':
                return MaintenanceRepetitionIntervalEnum.Month1;
            case 'month_2':
                return MaintenanceRepetitionIntervalEnum.Month2;
            case 'month_3':
                return MaintenanceRepetitionIntervalEnum.Month3;
            case 'month_6':
                return MaintenanceRepetitionIntervalEnum.Month6;
            case 'year_1':
                return MaintenanceRepetitionIntervalEnum.Year1;
            case 'year_2':
                return MaintenanceRepetitionIntervalEnum.Year2;
            default:
                return MaintenanceRepetitionIntervalEnum.Custom;
        }
    };

    private _identifyIntervalDays = (value: number, type: string): number => {
        switch (type) {
            case 'day':
                return value;
            case 'week':
                return 7 * value;
            case 'month':
                return 31 * value;
            case 'year':
                return 365 * value;
            default:
                return 365;
        }
    };

    settings(): {
        vehicles: string[];
        drivers: string[];
        trailers: string[];
        taskTypes: string[];
        intervals: string[];
        status: string[];
        dueDateGte: string;
        dueDateLte: string;
    } {
        const settings = this.logic.settings().getProp('statistics').maintenance?.filter;
        const filter = {
            vehicles: settings?.vehicles ?? [],
            trailers: settings?.trailers ?? [],
            drivers: settings?.drivers ?? [],
            taskTypes: settings?.taskTypes ?? [],
            intervals: settings?.intervals ?? [],
            status: settings?.status ?? [],
            dueDateGte: settings?.dueDateGte ?? '',
            dueDateLte: settings?.dueDateLte ?? ''
        };
        return filter;
    }

    setSettings = (settings: {
        vehicles: string[];
        drivers: string[];
        trailers: string[];
        taskTypes: string[];
        intervals: string[];
        status: string[];
        dueDateGte: string;
        dueDateLte: string;
    }) => {
        const originalSettings = this.logic.settings().getProp('statistics').maintenance?.filter;
        const modifiedSettings = {
            vehicles: settings.vehicles ?? originalSettings.vehicles ?? [],
            trailers: settings.trailers ?? originalSettings.trailers ?? [],
            drivers: settings.drivers ?? originalSettings.drivers ?? [],
            taskTypes: settings.taskTypes ?? originalSettings.taskTypes ?? [],
            intervals: settings.intervals ?? originalSettings.intervals ?? [],
            status: settings.status ?? originalSettings.status ?? [],
            dueDateGte: settings.dueDateGte ?? originalSettings.dueDateGte,
            dueDateLte: settings.dueDateLte ?? originalSettings.dueDateLte
        };
        this.logic.settings().setProp('statistics', {
            ...this.logic.settings().getProps().statistics,
            maintenance: {
                filter: modifiedSettings
            }
        });
    };
}
