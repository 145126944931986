import { H } from 'domain-constants';
import i18next from 'i18next';

export const pxWidthText = (text: string | undefined, fontSize: number = 13): number => {
    return text ? Math.floor(text.length * (fontSize / 2) + 20) : 0;
};

export const pxWidthTextLength = (length: number, fontSize: number = 13): number => {
    return Math.floor(length * (fontSize / 2) + 20);
};

export const pxInputWidthText = (text: string | undefined, fontSize: number = 13): number => {
    const el: HTMLDivElement = document.createElement('div');
    el.classList.add('input-number-postfix-width');
    el.style.fontSize = String(fontSize);
    el.innerText = text ?? '';
    document.body.appendChild(el);
    const width = el.clientWidth + 4;
    el.remove();
    return width;
};

export function objectCompareSame(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !objectCompareSame(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
}

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatGps(value?: number, digits: number = 5): string {
    if (value) {
        return value.toLocaleString('en-EN', { maximumFractionDigits: digits });
    }
    return '';
}

function isObject(object: any): boolean {
    return object != null && typeof object === 'object';
}

export function fixedStringNumber(value: string, scale: number = 1): string {
    if (value === '') {
        return value;
    }
    var val = value.split('.');
    if (val.length > 1 && (val[1]?.length ?? 0) > scale) {
        val[1] = val[1].slice(0, scale);
    }

    return val.join('.');
}

export enum NameFormatType {
    NameFirst = '%N %S',
    SurnameFirst = '%S %N'
}

export function formatName(
    name: string | undefined,
    surname: string | undefined,
    formatType: NameFormatType = NameFormatType.NameFirst
): string {
    return String(formatType)
        .replace('%N', name ?? '')
        .replace('%S', surname ?? '');
}

export function getSlug(url: string, withSlashEnding?: boolean): string {
    const parts = url.split('/');
    return parts[parts.length - (withSlashEnding ? 2 : 1)];
}

export function formatIntervalsToHours(intervalFrom: number, intervalTo: number, delimiter: string): string {
    const intervalFromModulo = intervalFrom % 3600;
    const intervalFromHours =
        intervalFromModulo === 0 ? intervalFrom / 3600 : (intervalFrom - intervalFromModulo) / 3600;

    const intervalToModulo = intervalTo % 3600;
    const intervalToHours = intervalToModulo === 0 ? intervalTo / 3600 : (intervalTo - intervalToModulo) / 3600;

    return `${intervalFromHours}${H} ${delimiter}  ${intervalToHours}${H}`;
}

export function arrayEquals(a: Array<any>, b: Array<any>): boolean {
    if (a === b) return true;
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => arrayEquals(a[k], b[k]));
}

export function translateFormatedText(text?: string) {
    return text?.replaceAll(/{{(.*?)}}/gi, function (word) {
        return i18next.t(word.replace('{{', '').replace('}}', ''));
    });
}

export function camelCaseToSnakeCase(str: string) {
    return str.replaceAll(/([A-Z])/g, function (match: string) {
        return '_' + match.toLowerCase();
    });
}

export function snakeCaseToCamelCase(str: string) {
    return str.replaceAll(/_([a-z])/g, function (match: string) {
        return match[1].toUpperCase();
    });
}
