import cn from 'classnames';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Loading } from 'common/components/Loading';
import Search from 'common/components/Search';
import { FleetLinks, FleetModel, PairingData, FleetType } from '../FleetModule';
import { NoData } from 'common/components/NoData';
import { searched } from 'common/utils/search';
import { DriverModel } from 'logic/user/users';
import qa from 'qa-selectors';
import { ReadOnlyFuelCardSerializer, ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import FormActions from 'common/components/FormActions';

function isFleetModelArr(arr: PairingData[]): arr is ReadOnlyMonitoredObjectFeSb[] {
    return 'registrationNumber' in arr[0];
}

function isFuelCardModelArr(arr: PairingData[]): arr is ReadOnlyFuelCardSerializer[] {
    return 'fuelCompany' in arr[0];
}

function isDriverModelArr(arr: PairingData[]): arr is DriverModel[] {
    return 'pin' in arr[0];
}

type FleetPairingTypes = keyof FleetLinks;

interface Props extends WithTranslation {
    data?: PairingData[];
    model?: FleetModel;
    search?: string;
    selected?: PairingData;
    type?: FleetPairingTypes;
    onCancelButtonClick?: () => void;
    onConfirmButtonClick?: () => void;
    onPairingItemSelect?: (selected: PairingData) => void;
    onPairingTypeChange?: (type: FleetPairingTypes) => void;
    onSearchInputChange?: (text: string) => void;
}

function FleetPairing(props: Props) {
    function onConfirm(): void {
        props.onConfirmButtonClick?.();
    }

    function onCancel(): void {
        props.onCancelButtonClick?.();
    }

    function onLinkChange(type: FleetPairingTypes): void {
        props.onPairingTypeChange?.(type);
    }

    function onItemSelect(selected: PairingData): void {
        props.onPairingItemSelect?.(selected);
    }

    function onSearch(text: string): void {
        props.onSearchInputChange?.(text);
    }

    function searchFleetFilter(data: ReadOnlyMonitoredObjectFeSb): boolean {
        if (props.search) {
            const search = props.search.toLowerCase();
            return (
                data.customLabel?.toLowerCase().includes(search) ||
                data.registrationNumber.toLowerCase().includes(search)
            );
        }
        return true;
    }

    function searchTachoFilter(data: ReadOnlyFuelCardSerializer): boolean {
        if (props.search) {
            return searched(props.search, String(data.id)) || searched(props.search, data.number ?? '');
        }
        return true;
    }

    function searchDriverFilter(data: DriverModel): boolean {
        if (props.search) {
            return (
                searched(props.search, String(data.id)) ||
                searched(props.search, data.username ?? '') ||
                searched(props.search, data.name ?? '') ||
                searched(props.search, data.surname ?? '')
            );
        }
        return true;
    }

    const links: FleetPairingTypes[][] = [['fleet', 'vehicles', 'trailers'], ['fuelCard'], ['defaultDriver']];
    const { t, model } = props;

    return (
        <div className="t-modal">
            <div className="management-fleet-pairing t-modal-content t-card">
                <div className="t-bar t-large t-center t-padding-large">
                    <span className="t-uppercase">
                        {model?.type !== FleetType.TRAILER
                            ? `${t('ManagementFleet.vehiclePairing')} - `
                            : `${t('ManagementFleet.trailerPairing')} - `}
                    </span>
                    <span className="t-bold t-uppercase" data-qa={qa.fleet.pairing.fieldRn}>
                        {model?.registrationNumber}
                    </span>
                </div>
                <div className="t-row">
                    <div className="management-fleet-pairing-type t-half t-border-right">
                        {links.map(linkRow =>
                            linkRow.map((link, i, a) => (
                                <Fragment key={i}>
                                    {i === 0 ? (
                                        <button
                                            disabled={
                                                (model?.type === FleetType.TRAILER && link === 'fuelCard') ||
                                                (model?.type === FleetType.TRAILER && link === 'defaultDriver') ||
                                                (model?.type === FleetType.LIGHT_VEHICLE && link === 'fleet')
                                            }
                                            className={cn('t-button t-block', {
                                                't-primary': props.type && a.includes(props.type),
                                                't-bold': props.type && a.includes(props.type)
                                            })}
                                            onClick={onLinkChange.bind(undefined, link)}
                                            data-qa={qa.fleet.pairing.type[link]}
                                        >
                                            <span className="t-left">{t(`common.${link}`)}</span>
                                            {props.type ? (
                                                <span className="t-right">
                                                    <i
                                                        className={cn(
                                                            'fas fa-fw',
                                                            a.includes(props.type) ? 'fa-angle-down' : 'fa-angle-right'
                                                        )}
                                                    />
                                                </span>
                                            ) : (
                                                <span className="t-right">
                                                    <i className="fas fa-fw fa-angle-right" />
                                                </span>
                                            )}
                                        </button>
                                    ) : (
                                        // sub-buttons
                                        <>
                                            {props.type && a.includes(props.type) && (
                                                <button
                                                    disabled={
                                                        // combination which is not permitted to pair
                                                        model?.type === FleetType.LIGHT_VEHICLE ||
                                                        (model?.type === FleetType.TRAILER && link === 'fuelCard') ||
                                                        (model?.type === FleetType.TRAILER && link === 'trailers') ||
                                                        (model?.type === FleetType.TRAILER &&
                                                            (model?.links?.fleet ?? []).length > 0) ||
                                                        (model?.type === FleetType.VEHICLE && link === 'vehicles') ||
                                                        // TODO: max 1, temporary
                                                        (model?.type === FleetType.VEHICLE && !!model?.trailers?.length)
                                                    }
                                                    className={cn('t-button t-block', {
                                                        't-bold': link === props.type
                                                    })}
                                                    onClick={onLinkChange.bind(undefined, link)}
                                                    data-qa={qa.fleet.pairing.type[link]}
                                                >
                                                    <span className="t-left t-margin-left">{t(`common.${link}`)}</span>
                                                </button>
                                            )}
                                        </>
                                    )}
                                </Fragment>
                            ))
                        )}
                    </div>
                    <div className="t-half t-padding-small">
                        <div className="t-row">
                            <Search searchActive={true} onSearch={onSearch} qa={qa.common.pairing.inputSearch} />
                        </div>
                        <div
                            className={cn('management-fleet-pairing-items t-margin-top t-margin-bottom', {
                                't-overflow-y': props.data
                            })}
                        >
                            {!props.type || props.type === 'fleet' ? (
                                <NoData text={t('Partner.selectItemToLink')} />
                            ) : props.type && !props.data ? (
                                <Loading />
                            ) : !props.data?.length ? (
                                <NoData />
                            ) : (
                                <>
                                    {isFleetModelArr(props.data) &&
                                        props.data?.filter(searchFleetFilter).map(f => (
                                            <button
                                                key={f.id}
                                                className={cn('t-button t-block t-ripple', {
                                                    't-primary': props.selected?.id === f.id
                                                })}
                                                onClick={onItemSelect.bind(undefined, f)}
                                                data-qa={qa.fleet.pairing.item.btnFleet}
                                                title={f.registrationNumber}
                                            >
                                                <div className="t-row">
                                                    <div className="t-twothird">
                                                        <span className="t-left t-transform-none">
                                                            {f.registrationNumber}
                                                        </span>
                                                    </div>
                                                    <div className="t-rest">
                                                        <span className="t-left t-transform-none">{f.customLabel}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        ))}
                                    {isFuelCardModelArr(props.data) &&
                                        props.data?.filter(searchTachoFilter).map(f => (
                                            <button
                                                key={f.id}
                                                className={cn('t-button t-block t-ripple', {
                                                    't-primary': props.selected?.id === f.id
                                                })}
                                                onClick={onItemSelect.bind(undefined, f)}
                                                data-qa={qa.fleet.pairing.item.btnFuelCard}
                                                title={f.number ?? ''}
                                            >
                                                <div className="t-row">
                                                    <span className="t-left t-transform-none">{f.number}</span>
                                                </div>
                                            </button>
                                        ))}
                                    {isDriverModelArr(props.data) &&
                                        props.data?.filter(searchDriverFilter).map(d => (
                                            <button
                                                key={d.id}
                                                className={cn('t-button t-block t-ripple', {
                                                    't-primary': props.selected?.id === d.id
                                                })}
                                                onClick={onItemSelect.bind(undefined, d)}
                                                data-qa={qa.fleet.pairing.item.btnDriver}
                                                title={`${d.name} ${d.surname}`}
                                            >
                                                <div className="t-row">
                                                    <span className="t-left t-transform-none">{`${d.name} ${d.surname}`}</span>
                                                </div>
                                            </button>
                                        ))}
                                </>
                            )}
                        </div>
                        <FormActions
                            submitQa={qa.fleet.pairing.btnSubmit}
                            cancelQa={qa.fleet.pairing.btnCancel}
                            submitText={t('common.save')}
                            submitDisabled={!props.selected || !props.type}
                            onSubmitClick={onConfirm}
                            onCancelClick={onCancel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(FleetPairing);
