/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChannelInfoResponse,
    ChannelInfoResponseFromJSON,
    ChannelInfoResponseToJSON,
    ChannelMessagesResponse,
    ChannelMessagesResponseFromJSON,
    ChannelMessagesResponseToJSON,
    ChannelResponse,
    ChannelResponseFromJSON,
    ChannelResponseToJSON,
    ChannelUsers,
    ChannelUsersFromJSON,
    ChannelUsersToJSON,
    ChannelUsersPatch,
    ChannelUsersPatchFromJSON,
    ChannelUsersPatchToJSON,
    ChannelUsersResponse,
    ChannelUsersResponseFromJSON,
    ChannelUsersResponseToJSON,
    ChannelsResponse,
    ChannelsResponseFromJSON,
    ChannelsResponseToJSON,
    GetChannelUsersResponse,
    GetChannelUsersResponseFromJSON,
    GetChannelUsersResponseToJSON,
    MessageInput,
    MessageInputFromJSON,
    MessageInputToJSON,
    MsgResponse,
    MsgResponseFromJSON,
    MsgResponseToJSON,
    SeenMessagesResponse,
    SeenMessagesResponseFromJSON,
    SeenMessagesResponseToJSON,
    UnseeMessagesResponse,
    UnseeMessagesResponseFromJSON,
    UnseeMessagesResponseToJSON,
    UserMessagesResponse,
    UserMessagesResponseFromJSON,
    UserMessagesResponseToJSON,
} from '../models';

export interface ChannelCreateRequest {
    channelUsers?: ChannelUsers;
}

export interface ChannelEwTruckRequest {
    userId?: string;
}

export interface ChannelInfoRequest {
    id: string;
    userId: string;
}

export interface ChannelMessagesRequest {
    channel: string;
    timestamp?: Date;
    count?: number;
}

export interface ChannelUsersListRequest {
    channel: string;
}

export interface ChannelUsersPatchRequest {
    channel: string;
    channelUsersPatch?: ChannelUsersPatch;
}

export interface ChannelUsersUpdateRequest {
    channel: string;
    channelUsers?: ChannelUsers;
}

export interface GetMessagesByUserRequest {
    user: string;
    from?: Date;
    limit?: number;
}

export interface MessageAddRequest {
    channel: string;
    messageInput?: MessageInput;
}

export interface MessageSeenRequest {
    channel: string;
    seento: Date;
}

export interface MessagesUnseeRequest {
    channel: string;
    messageId: string;
}

export interface UserMessagesRequest {
    from?: Date;
    to?: Date;
    onlyWithPayload?: boolean;
    limit?: number;
    userSsoId?: string;
}

/**
 * no description
 */
export class MessagingApi extends runtime.BaseAPI {

    /**
     */
    async channelCreateRaw(requestParameters: ChannelCreateRequest): Promise<runtime.ApiResponse<ChannelResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChannelUsersToJSON(requestParameters.channelUsers),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelCreate(requestParameters: ChannelCreateRequest): Promise<ChannelResponse> {
        const response = await this.channelCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelEwTruckRaw(requestParameters: ChannelEwTruckRequest): Promise<runtime.ApiResponse<ChannelsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channelEWTruck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelsResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelEwTruck(requestParameters: ChannelEwTruckRequest): Promise<ChannelsResponse> {
        const response = await this.channelEwTruckRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelInfoRaw(requestParameters: ChannelInfoRequest): Promise<runtime.ApiResponse<ChannelInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling channelInfo.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling channelInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelInfo(requestParameters: ChannelInfoRequest): Promise<ChannelInfoResponse> {
        const response = await this.channelInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelMessagesRaw(requestParameters: ChannelMessagesRequest): Promise<runtime.ApiResponse<ChannelMessagesResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling channelMessages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestamp !== undefined) {
            queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages/{channel}`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelMessages(requestParameters: ChannelMessagesRequest): Promise<ChannelMessagesResponse> {
        const response = await this.channelMessagesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelUsersListRaw(requestParameters: ChannelUsersListRequest): Promise<runtime.ApiResponse<GetChannelUsersResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling channelUsersList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel/{channel}/users`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelUsersList(requestParameters: ChannelUsersListRequest): Promise<GetChannelUsersResponse> {
        const response = await this.channelUsersListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelUsersPatchRaw(requestParameters: ChannelUsersPatchRequest): Promise<runtime.ApiResponse<ChannelUsersResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling channelUsersPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel/{channel}/users`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ChannelUsersPatchToJSON(requestParameters.channelUsersPatch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelUsersPatch(requestParameters: ChannelUsersPatchRequest): Promise<ChannelUsersResponse> {
        const response = await this.channelUsersPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelUsersUpdateRaw(requestParameters: ChannelUsersUpdateRequest): Promise<runtime.ApiResponse<ChannelUsersResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling channelUsersUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel/{channel}/users`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChannelUsersToJSON(requestParameters.channelUsers),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelUsersUpdate(requestParameters: ChannelUsersUpdateRequest): Promise<ChannelUsersResponse> {
        const response = await this.channelUsersUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async channelsListRaw(): Promise<runtime.ApiResponse<ChannelsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/channel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelsResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelsList(): Promise<ChannelsResponse> {
        const response = await this.channelsListRaw();
        return await response.value();
    }

    /**
     */
    async getMessagesByUserRaw(requestParameters: GetMessagesByUserRequest): Promise<runtime.ApiResponse<UserMessagesResponse>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling getMessagesByUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/debug/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getMessagesByUser(requestParameters: GetMessagesByUserRequest): Promise<UserMessagesResponse> {
        const response = await this.getMessagesByUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async messageAddRaw(requestParameters: MessageAddRequest): Promise<runtime.ApiResponse<MsgResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling messageAdd.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages/{channel}`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageInputToJSON(requestParameters.messageInput),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MsgResponseFromJSON(jsonValue));
    }

    /**
     */
    async messageAdd(requestParameters: MessageAddRequest): Promise<MsgResponse> {
        const response = await this.messageAddRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async messageSeenRaw(requestParameters: MessageSeenRequest): Promise<runtime.ApiResponse<SeenMessagesResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling messageSeen.');
        }

        if (requestParameters.seento === null || requestParameters.seento === undefined) {
            throw new runtime.RequiredError('seento','Required parameter requestParameters.seento was null or undefined when calling messageSeen.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.seento !== undefined) {
            queryParameters['seento'] = (requestParameters.seento as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages/seen/{channel}`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SeenMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async messageSeen(requestParameters: MessageSeenRequest): Promise<SeenMessagesResponse> {
        const response = await this.messageSeenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async messagesUnseeRaw(requestParameters: MessagesUnseeRequest): Promise<runtime.ApiResponse<UnseeMessagesResponse>> {
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling messagesUnsee.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling messagesUnsee.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages/unsee/{channel}/{message_id}`.replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))).replace(`{${"message_id"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnseeMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async messagesUnsee(requestParameters: MessagesUnseeRequest): Promise<UnseeMessagesResponse> {
        const response = await this.messagesUnseeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userMessagesRaw(requestParameters: UserMessagesRequest): Promise<runtime.ApiResponse<UserMessagesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.onlyWithPayload !== undefined) {
            queryParameters['only_with_payload'] = requestParameters.onlyWithPayload;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.userSsoId !== undefined) {
            queryParameters['user_sso_id'] = requestParameters.userSsoId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async userMessages(requestParameters: UserMessagesRequest): Promise<UserMessagesResponse> {
        const response = await this.userMessagesRaw(requestParameters);
        return await response.value();
    }

}
