import { LatLng } from 'common/model/geo';
import {
    PlaceSuggestion,
    PointSuggestionsDocument,
    PointSuggestionsQuery,
    PointSuggestionsQueryVariables
} from 'generated/graphql';
import { GeocoderResult, QueryAutocompletePrediction } from 'logic/geocoding';
import { Logic } from 'logic/logic';
import { Subject } from 'rxjs';

export type Suggestions = {
    google: QueryAutocompletePrediction[];
    googleGeocoder: GeocoderResult[];
    latLng?: LatLng;
    eurowag: PlaceSuggestion[];
};

export class SuggestionLogic {
    private _suggestionData: Suggestions;
    private _suggestionText?: string;

    suggestionsLoading: Subject<boolean> = new Subject<boolean>();
    suggestionsData: Subject<Suggestions> = new Subject<Suggestions>();
    suggestionsError: Subject<any> = new Subject<any>();

    constructor(private _logic: Logic) {
        this._suggestionData = {
            eurowag: [],
            google: [],
            googleGeocoder: []
        };
    }

    async getSuggestions(text: string) {
        this.suggestionsLoading.next(true);
        try {
            const regexExp =
                // eslint-disable-next-line no-useless-escape
                /^((\-?|\+?)?\d+(\.\d+)?)[ |,]\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
            const regexResult = regexExp.exec(text);
            if (regexResult) {
                const latLng: LatLng = {
                    lat: Number(regexResult[1]),
                    lng: Number(regexResult[4])
                };

                const geocoderResult = await this._logic.geocoding().reverseGeocoding(latLng);
                this._suggestionData.googleGeocoder = geocoderResult;
                this._suggestionData.latLng = latLng;
                this._suggestionData.eurowag = [];
                this._suggestionData.google = [];
            } else {
                const [google, eurowag] = await Promise.all([
                    this._logic
                        .geocoding()
                        .placesAutocomplete(text, this._logic.geocoding().autocompleteSessionToken()),
                    this.getCustomPlaceSuggestions(text)
                ]);
                this._suggestionData.googleGeocoder = [];
                this._suggestionData.latLng = undefined;
                this._suggestionData.eurowag = eurowag;
                this._suggestionData.google = google;
            }

            this.suggestionsData.next(this._suggestionData);
        } catch (err) {
            this.suggestionsError.next(err);
        } finally {
            this.suggestionsLoading.next(false);
        }
    }

    async getCustomPlaceSuggestions(text: string) {
        this._suggestionText = text;
        const { data } = await this._logic.apollo().query<PointSuggestionsQuery, PointSuggestionsQueryVariables>({
            query: PointSuggestionsDocument,
            fetchPolicy: 'no-cache',
            variables: {
                searchToken: text
            }
        });

        if (data?.search?.pointSuggestions) {
            if (this._suggestionText === text) {
                return data.search.pointSuggestions;
            } else {
                throw new Error('Interrupted call with new data...');
            }
        } else {
            throw new Error('No search point suggestions');
        }
    }
}
