import { Tag, Tooltip } from 'common/components';
import { DEFAULT_OPTIMAL_RPM, KG, L, M, T, deg } from 'domain-constants';
import { useTranslation } from 'react-i18next';
import { FleetModel } from '../../FleetModule';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import * as icons from 'resources/images/common';
import { MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum } from 'generated/new-main/models';
import React from 'react';
import { Role } from 'logic/auth';
import { WithLogic } from 'App';

interface Props extends WithLogic {
    data: FleetModel;
}

export default function FleetVehicleDetailCard(props: Props) {
    const { t } = useTranslation();
    const zones = [...new Set(props.data.temperatureSensors?.map(d => d.sensorZone).filter(Number))] as number[];

    return (
        <div className="vehicle-detail-card">
            <>
                <LayoutDetail
                    firstLeft={
                        <>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.rn')}>
                                    <span data-qa={qa.fleet.detail.fieldRN}>{props.data.registrationNumber}</span>
                                    <span style={{ display: 'none' }}>{props.data.serialNumber}</span>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.ownName')}>
                                    <span data-qa={qa.fleet.detail.fieldName}>{props.data.name}</span>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.vehicleGroups')}>
                                    {props.data.monitoredObjectGroups?.map((group, i) => (
                                        <Tag key={`tag-${i}`} qa={qa.fleet.detail.tag}>
                                            {group.name}
                                        </Tag>
                                    ))}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.type')}>
                                    <span data-qa={qa.fleet.detail.fieldType}>{t(`Partner.${props.data.type}`)}</span>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('ManagementFleet.unitSerialNumber')}>
                                    <span data-qa={qa.fleet.detail.fieldUnitSerialNumber}>
                                        {props.data.serialNumber}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('ManagementFleet.optimalRpm')}>
                                    <span data-qa={qa.fleet.detail.fieldOptimalRpm}>
                                        {props.data.optimalRpm ?? DEFAULT_OPTIMAL_RPM}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    }
                    firstRight={
                        <Descriptions layout="vertical" column={1}>
                            <Descriptions.Item label={t('ManagementFleet.vin')}>
                                {props.data.md?.vin && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                <span data-qa={qa.fleet.detail.fieldVin}>{props.data.vin}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('ManagementFleet.tunnel')}>
                                {props.data.md?.tunnel && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                <span data-qa={qa.fleet.detail.fieldTunnel}>{props.data.tunnel}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('common.ems')}>
                                {props.data.md?.emissionClass && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                <span data-qa={qa.fleet.detail.fieldEmissionClass}>{props.data.emissionClass}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('ManagementFleet.axlesCount')}>
                                {props.data?.md?.numberOfAxles && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                <span data-qa={qa.fleet.detail.fieldNumberOfAxles}>
                                    {numeral(props.data.numberOfAxles).format('0,0')}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    }
                    secondLeft={
                        <>
                            {' '}
                            <Descriptions layout="vertical" column={1}>
                                {props.data.fuelType !==
                                MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.ELECTRO ? (
                                    <Descriptions.Item label={t('ManagementFleet.tankVolume')}>
                                        {props.data.tankSize ? (
                                            <>
                                                <span data-qa={qa.fleet.detail.fieldTankSize}>
                                                    {numeral(props.data.tankSize).format('0,0.00')}
                                                </span>{' '}
                                                {props.data.fuelType ===
                                                MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.LNGCNG
                                                    ? KG
                                                    : L}
                                            </>
                                        ) : (
                                            <span data-qa={qa.fleet.detail.fieldTankSize}> </span>
                                        )}
                                    </Descriptions.Item>
                                ) : (
                                    <></>
                                )}
                                <Descriptions.Item label={t('ManagementFleet.fullLoadWeight')}>
                                    {props.data.md?.weightFull && (
                                        <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                            <img src={icons.info} alt="information" />
                                        </Tooltip>
                                    )}
                                    {props.data.weightFull && (
                                        <>
                                            <span data-qa={qa.fleet.detail.fieldFullWeight}>
                                                {numeral(props.data.weightFull).format('0,0.00')}
                                            </span>{' '}
                                            {T}
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    }
                    secondRight={
                        <Descriptions layout="vertical" column={2}>
                            <Descriptions.Item label={t('common.weight')}>
                                {props.data.md?.weight && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                {props.data.weight && (
                                    <>
                                        <span data-qa={qa.fleet.detail.fieldWeight}>
                                            {numeral(props.data.weight).format('0,0.00')}
                                        </span>{' '}
                                        {T}
                                    </>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('ManagementFleet.fuelType')}>
                                <span data-qa={qa.fleet.detail.fieldFuelType}>
                                    {props.data.fuelType
                                        ? t(
                                              `VehicleProfileForm.fuelTypeOptions.${props.data.fuelType.toLowerCase()}`
                                          ) ?? ''
                                        : ''}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('common.length')}>
                                {props.data.md?.length && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                {props.data.length && (
                                    <>
                                        <span data-qa={qa.fleet.detail.fieldLength}>
                                            {numeral(props.data.length).format('0,0.00')}
                                        </span>{' '}
                                        {M}
                                    </>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('common.width')}>
                                {props.data.md?.width && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                {props.data.width && (
                                    <>
                                        <span data-qa={qa.fleet.detail.fieldWidth}>
                                            {numeral(props.data.width).format('0,0.00')}
                                        </span>{' '}
                                        {M}
                                    </>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('common.height')}>
                                {props.data.md?.height && (
                                    <Tooltip placement="top" title={t('ManagementFleet.dataSetInEWClientPortal')}>
                                        <img src={icons.info} alt="information" />
                                    </Tooltip>
                                )}
                                {props.data.height && (
                                    <>
                                        <span data-qa={qa.fleet.detail.fieldHeight}>
                                            {numeral(props.data.height).format('0,0.00')}
                                        </span>{' '}
                                        {M}
                                    </>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                />
                {props.logic.auth().roles().includes(Role.CLD_R) && !!props.data.temperatureSensors?.length && (
                    <Descriptions layout="vertical" column={2}>
                        <Descriptions.Item label={t('ManagementFleet.unitsAndSensors')}>
                            {zones
                                .sort((a, b) => a - b)
                                .map(zone => (
                                    <React.Fragment key={zone}>
                                        <div className="temperature-sensor-zone-label">
                                            {t('Coldchain.zone')} {zone}
                                        </div>
                                        {props.data.temperatureSensors
                                            ?.filter(sensor => sensor.sensorZone === zone)
                                            .map((sensor, i) => (
                                                <Tooltip
                                                    key={i}
                                                    title={`${t('ManagementFleet.sn')}: ${sensor.serialNumber}`}
                                                    placement="top"
                                                    mouseLeaveDelay={0}
                                                >
                                                    <Tag className="temperature-sensor">
                                                        <img
                                                            src={icons.thermostat}
                                                            alt="temperature-sensor"
                                                            height="16px"
                                                            width="16px"
                                                        />
                                                        {sensor.temperatureValue !== undefined && (
                                                            <span className="temperature-sensor-value">
                                                                {sensor.temperatureValue &&
                                                                    numeral(sensor.temperatureValue).format('0,0')}
                                                                {deg}
                                                            </span>
                                                        )}
                                                        <span className="temperature-sensor-name">
                                                            {sensor.sensorName}
                                                        </span>
                                                    </Tag>
                                                </Tooltip>
                                            ))}
                                    </React.Fragment>
                                ))}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </>
        </div>
    );
}
