import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

interface Props extends ButtonProps, React.RefAttributes<HTMLElement> {
    size?: 'large' | 'middle' | 'small';
    qa?: string;
    innerref?: React.Ref<HTMLElement> | undefined;
}

export default function EnhancedButton({ qa, ...props }: Props) {
    return <Button size={props.size ?? 'large'} data-qa={qa} ref={props.innerref} {...props} />;
}
