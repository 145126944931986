/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WAGTPActionCodeContractOperation {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export function WAGTPActionCodeContractOperationFromJSON(json: any): WAGTPActionCodeContractOperation {
    return WAGTPActionCodeContractOperationFromJSONTyped(json, false);
}

export function WAGTPActionCodeContractOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPActionCodeContractOperation {
    return json as WAGTPActionCodeContractOperation;
}

export function WAGTPActionCodeContractOperationToJSON(value?: WAGTPActionCodeContractOperation | null): any {
    return value as any;
}

