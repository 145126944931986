import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Widget from 'common/components/Widget';
import DriversLeaderboardTable from 'modules/statistics/modules/driver-behavior/components/DriversLeaderboardTable';
import { BaseDriverBehaviorModel } from 'common/model/statistics';

interface Props extends WithTranslation {
    data: BaseDriverBehaviorModel[];
    loading: boolean;
    onClick?: () => void;
}

function WorstDriversWidget(props: Props) {
    return (
        <Widget
            loading={props.loading}
            title={props.t('CompanyProfileDashboard.worstDrivers')}
            styleName="sad"
            content={
                props.data.length === 0 ? (
                    <div className="widget-empty">{props.t('utils.empty')}</div>
                ) : (
                    <DriversLeaderboardTable data={props.data ?? []} loading={false} showHeader={false} />
                )
            }
            onClick={props.onClick}
        />
    );
}

export default withTranslation()(WorstDriversWidget);
