import { CheckboxGroupOpt, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { Logic } from 'logic/logic';
import qs from 'qs';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18next';

import { Aetr } from './ui/Aetr';
import { debounce } from 'debounce';
import { Role } from 'logic/auth';
import { exponea } from 'logic/exponea';
import { message } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { getRegistrationNumber } from 'common/utils/registrationName';

export type RouteParams = {
    search?: string;
    driverId?: string;
    vehicleId?: string;
};
export type SearchData = { text?: string; type?: number };

export enum AetrActivity {
    D = 'D',
    W = 'W',
    R = 'R',
    A = 'A'
}

export interface AetrModel {
    driverName: string;
    tachographCard: string;
    current: {
        vehicleNumberPlate: string;
        location: string | undefined;
        drivingDurationRemainingPercentage: number;
        drivingDuration: number;
        drivingDurationRemaining: number;
        weeklyDrivingDurationPercentage: number;
        weeklyDrivingDuration: number;
        weeklyDrivingDurationRemaining: number;
        biweeklyDrivingDuration: number;
        lastWeekDrivingDuration: number;
        maxWeeklyRide: number;
        maxDailyRide: number;
        reliable: boolean;
    };
    evaluation: {
        weekStartTime: string;
        shifts: number;
        lastDailyRestStartTime: string;
        lastDailyRestDuration: number;
        nextDailyRestStartTime: string;
        reducedDailyRests: number;
        extendedDailyShifts: number;
        nextWeekWeeklyRestStartTime: string;
        nextWeeklyRestDuration: number;
        nextWeeklyRestDurationDebt: number;
        averageDriverSpeed: string;
        expectedWeeklyDrivingRange: number;
        expectedBiweeklyDrivingRange: number;
    };
    isCrew?: boolean;
    week?: string;
    dailyPerformances?: DailyPerformance[];
}

export interface AetrInterval {
    activity: AetrActivity;
    startTime: string;
    endTime: string;
    startLocation?: string;
    endLocation?: string;
    reliable: boolean;
}

export interface DailyPerformance {
    date: string;
    intervals: AetrInterval[];
    extendedDrive?: boolean;
    drivingDuration?: number;
    workingDuration?: number;
    restingDuration?: number;
    reducedDailyRest?: boolean;
    reliable: boolean;
}

export interface AetrFilterModel {
    driversOpts: CheckboxGroupOpts;
    vehiclesOpts: CheckboxGroupOpts;
    driversChecked: string[];
    vehiclesChecked: string[];
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
    logic: Logic;
}

interface State {
    bar: {
        search?: boolean;
        filterOpen?: boolean;
    };
    search?: { text: string };
    table: {
        data: AetrModel[];
        previous?: AetrModel[];
        openedWeeks: string[];
        openedDays: string[];
        loading: boolean;
    };
    filter: AetrFilterModel;
    helper?: {
        content: string;
    };
    roles: Role[];
}

const defaultBar = {
    filterOpen: false
};

class AetrModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;

        const settings = this._logic.statisticsAetrReading().settings();
        const roles = this._logic.auth().roles();
        this.state = {
            bar: { ...defaultBar },
            table: { data: [], openedWeeks: [], openedDays: [], loading: true },
            filter: {
                driversOpts: [],
                vehiclesOpts: [],
                driversChecked: settings.drivers,
                vehiclesChecked: settings.vehicles
            },
            roles
        };
    }

    componentDidMount() {
        (window as any).app.AetrModule = this;

        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        this.setState(
            state => ({
                search: {
                    text: params.search ? params.search : ''
                },
                filter: {
                    ...state.filter,
                    vehiclesChecked: params.vehicleId ? [params.vehicleId] : state.filter.vehiclesChecked,
                    driversChecked: params.driverId ? [params.driverId] : state.filter.driversChecked
                }
            }),
            () => {
                this._filterData().then(() => {
                    this._logic
                        .statisticsAetrReading()
                        .init()
                        .then(_res => {
                            this._logic
                                .statisticsAetrReading()
                                .tableData()
                                .then(data => {
                                    const filtered = this._logic
                                        .statisticsAetrReading()
                                        .search(
                                            this.state.search?.text,
                                            data.filter(this._filterDrivers).filter(this._filterVehicles)
                                        );
                                    this.setState(state => ({
                                        table: {
                                            ...state.table,
                                            data: filtered,
                                            loading: false
                                        }
                                    }));
                                })
                                .catch(err => {
                                    this.setState(state => ({
                                        table: {
                                            ...state.table,
                                            loading: false
                                        }
                                    }));
                                    console.error(`Load data error, err: ${err}`);
                                    message.error(this.props.t('common.error.loadDataError'));
                                });
                        })
                        .catch(err => {
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    loading: false
                                }
                            }));
                            console.error(err);
                        });
                    this._logic.settings().onChange(prop => {
                        if (prop.vehicleIdentification) {
                            this._logic.vehicles().changeVehicleIdentification(prop.vehicleIdentification);
                            this._logic
                                .vehicles()
                                .getMonitoredObjectFilters(false, false, [Role.AEI_R])
                                .then(vehiclesData => {
                                    const vehiclesOpts = vehiclesData.map<CheckboxGroupOpt>(v => ({
                                        code: String(v.id),
                                        label: v.registrationNumber,
                                        checked: false
                                    }));
                                    this.setState(state => ({
                                        filter: { ...state.filter, vehiclesOpts }
                                    }));
                                });
                        }
                    });
                });
            }
        );
    }

    componentWillUnmount() {
        this._logic.statisticsAetrReading().destory();
    }

    render() {
        return (
            <Aetr
                bar={this.state.bar}
                search={this.state.search}
                filter={this.state.filter}
                helper={this.state.helper}
                table={this.state.table}
                roles={this.state.roles}
                demoMode={this._logic.demo().isActive}
                onBarSearchChange={this._onSearchChange}
                onBarSearchEsc={this._onSearchEsc}
                onBarFilterClick={this._onBarFilterClick}
                onBarHelperClick={this._onBarHelperClick}
                onBarResetClick={this._onBarResetClick}
                onFilterCancel={this._onFilterCancel}
                onFilterConfirm={this._onFilterConfirm}
                onHelperClose={this._onHelperClose}
                onTableWeeksExpand={this._onTableWeeksExpand}
                onTableDaysExpand={this._onTableDaysExpand}
            />
        );
    }

    private _onSearchChange = debounce((searchText: string) => {
        this.setState(state => ({
            table: { ...state.table, loading: true }
        }));

        this._logic
            .statisticsAetrReading()
            .tableData()
            .then(res => {
                const data = this._logic.statisticsAetrReading().search(searchText, res);
                this.setState(state => ({
                    table: { ...state.table, data, loading: false }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    }, 500);

    private _onSearchEsc = (): void => {
        this.setState({ search: { text: '' } });
    };

    private _onFilterCancel = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultBar,
                filterOpen: !this.state.bar.filterOpen
            }
        }));
    };

    private _filterVehicles = (data: AetrModel): boolean => {
        const vehiclesCheckedLength = this.state.filter.vehiclesChecked.length;
        if (vehiclesCheckedLength > 0 && this.state.filter.vehiclesOpts.length !== vehiclesCheckedLength) {
            return (
                this.state.filter.vehiclesOpts.filter(
                    v =>
                        this.state.filter.vehiclesChecked.includes(v.code) &&
                        v.label === data.current.vehicleNumberPlate
                ).length !== 0
            );
        }
        return true;
    };

    private _filterDrivers = (data: AetrModel): boolean => {
        const driversCheckedLength = this.state.filter.driversChecked.length;
        if (driversCheckedLength > 0 && this.state.filter.driversOpts.length !== driversCheckedLength) {
            return (
                this.state.filter.driversOpts.filter(
                    d => this.state.filter.driversChecked.includes(d.code) && d.label === data.driverName
                ).length !== 0
            );
        }
        return true;
    };
    // TODO: filtrovanie na GQL strane, chyba implementacia
    private _onFilterConfirm = (driversChecked: string[], vehiclesChecked: string[]): void => {
        this._logic.exponea().trackEvent(exponea.module.statisticsAetr, {
            status: exponea.status.actionTaken,
            action: exponea.action.filter
        });

        this._logic.statisticsAetrReading().setSettings({
            drivers: driversChecked.length >= this.state.filter.driversOpts.length ? [] : driversChecked,
            vehicles: vehiclesChecked.length >= this.state.filter.vehiclesOpts.length ? [] : vehiclesChecked
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    ...defaultBar
                },
                filter: { ...state.filter, driversChecked, vehiclesChecked },
                table: { ...state.table, loading: true }
            }),
            () => {
                this._logic
                    .statisticsAetrReading()
                    .tableData()
                    .then(data => {
                        const filtered = data.filter(this._filterDrivers).filter(this._filterVehicles);
                        this.setState(state => ({
                            table: { ...state.table, loading: false, data: filtered }
                        }));
                    })
                    .catch(err => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                    });
            }
        );
    };

    private _filterData = async () => {
        const drivers = await this._logic.users().drivers();
        const driversOpts = drivers.map<CheckboxGroupOpt>(u => ({
            code: u.id,
            label: `${u.name} ${u.surname}`,
            checked: false
        }));

        const vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.AEI_R]);
        const vehiclesOpts = vehicles.map<CheckboxGroupOpt>(v => ({
            code: String(v.id),
            label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
            checked: false
        }));

        this.setState(state => ({
            filter: {
                ...state.filter,
                driversChecked:
                    state.filter.driversChecked.length > 0 && state.filter.driversChecked.length < driversOpts.length
                        ? state.filter.driversChecked
                        : driversOpts.map(v => v.code),
                driversOpts,
                vehiclesChecked:
                    state.filter.vehiclesChecked.length > 0 && state.filter.vehiclesChecked.length < vehiclesOpts.length
                        ? state.filter.vehiclesChecked
                        : vehiclesOpts.map(v => v.code),
                vehiclesOpts
            }
        }));
    };

    private _onBarFilterClick = (): void => {
        this.setState(state => ({
            search: undefined,
            bar: { ...state.bar, ...defaultBar, filterOpen: !state.bar.filterOpen }
        }));
    };

    private _onTableWeeksExpand = (keyWeek: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                openedWeeks: state.table.openedWeeks.includes(keyWeek)
                    ? this.state.table.openedWeeks.filter(d => d !== keyWeek)
                    : this.state.table.openedWeeks.concat(keyWeek)
            }
        }));
    };

    private _onTableDaysExpand = (keyDay: string, tachocard: string, week: string): void => {
        const openedDay = this.state.table.openedDays.includes(keyDay);
        const weekNumber = Number(week);
        if (openedDay) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    openedDays: state.table.openedDays.filter(d => d !== keyDay)
                }
            }));
        } else if (weekNumber === 0) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    openedDays: state.table.openedDays.concat(keyDay)
                }
            }));
        } else {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            this._logic
                .statisticsAetrReading()
                .anotherTableData(tachocard, weekNumber)
                .then(data => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data: state.table.data.map(oldItem =>
                                data?.[0]?.tachographCard === oldItem.tachographCard
                                    ? {
                                          ...(data.find(newItem => newItem.week === oldItem.week) ?? oldItem),
                                          current: oldItem.current, // Dont update expand row data
                                          evaluation: oldItem.evaluation // Dont update expand row data
                                      }
                                    : oldItem
                            ),
                            openedDays: state.table.openedDays.concat(keyDay),
                            loading: false
                        }
                    }));
                })
                .catch(err => {
                    console.error('Table data err', err);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                });
        }
    };

    private _onBarResetClick = (): void => {
        const defaults = confDefault.settings.statistics.aetr.filter;
        this._onFilterConfirm(defaults.drivers, defaults.vehicles);
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'legaldrivertimes';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(AetrModule));
