/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigestDriverBehaviourDriver
 */
export interface DigestDriverBehaviourDriver {
    /**
     * 
     * @type {number}
     * @memberof DigestDriverBehaviourDriver
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DigestDriverBehaviourDriver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DigestDriverBehaviourDriver
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof DigestDriverBehaviourDriver
     */
    tachoCard: string;
}

export function DigestDriverBehaviourDriverFromJSON(json: any): DigestDriverBehaviourDriver {
    return DigestDriverBehaviourDriverFromJSONTyped(json, false);
}

export function DigestDriverBehaviourDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestDriverBehaviourDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tachoCard': json['tachoCard'],
    };
}

export function DigestDriverBehaviourDriverToJSON(value?: DigestDriverBehaviourDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'tachoCard': value.tachoCard,
    };
}


