/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AlarmType {
    UnavailableGps = 'unavailable_gps',
    ConnectionLoss = 'connection_loss',
    MonitoringDeviceSilent = 'monitoring_device_silent',
    BatteryLow = 'battery_low',
    CorridorLeave = 'corridor_leave',
    PoiClose = 'poi_close',
    PoiArrival = 'poi_arrival',
    PoiDeparture = 'poi_departure',
    AggresiveTakeoff = 'aggresive_takeoff',
    DangerousTurnCountry = 'dangerous_turn_country',
    DangerousTurnHighway = 'dangerous_turn_highway',
    DangerousBreakingCity = 'dangerous_breaking_city',
    DangerousBreakingCountry = 'dangerous_breaking_country',
    DangerousBreakingHighway = 'dangerous_breaking_highway',
    Overspeed = 'overspeed',
    PoiEntry = 'poi_entry',
    PoiExit = 'poi_exit',
    PuescSentGeoStale = 'puesc_sent_geo_stale',
    PuescSentGeoError = 'puesc_sent_geo_error',
    DeadMansSwitch = 'dead_mans_switch',
    TransportColdChainProfileTemperatureHigh = 'transport_cold_chain_profile_temperature_high',
    TransportColdChainProfileTemperatureLow = 'transport_cold_chain_profile_temperature_low'
}

export function AlarmTypeFromJSON(json: any): AlarmType {
    return AlarmTypeFromJSONTyped(json, false);
}

export function AlarmTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmType {
    return json as AlarmType;
}

export function AlarmTypeToJSON(value?: AlarmType | null): any {
    return value as any;
}

