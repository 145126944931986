import { ReadOnlyUserNested, UserContact, UserRole, UsersSimpleInfo, UserTokenTokenTypeEnum } from 'generated/new-main';

const userRoles: UserRole[] = [
    {
        id: '1',
        label: 'driver',
        name: 'driver',
        description: '',
        userRights: ['']
    }
];

const client = {
    id: 100,
    children: [],
    addressNew: [],
    externalId: '31034',
    deleted: '0',
    name: 'DEMOCLIENT',
    languageIso6391: 'en',
    isPartner: false,
    styles: {},
    logo: '',
    metadata: { customerNbrList: ['_customer_nbr'] },
    costPerKm: { cost: 283338.6025597481, currency: 'EUR' },
    externalSystem: 3,
    referrer: 361,
    modules: [1, 2, 6, 7, 3, 4, 5]
};

export const users: ReadOnlyUserNested[] = [
    {
        id: 1,
        client: {
            id: 100,
            children: [],
            addressNew: [],
            externalId: '31034',
            deleted: '0',
            name: 'DEMOJUHAS',
            languageIso6391: 'en',
            isPartner: false,
            styles: {},
            logo: '',
            metadata: { customerNbrList: ['juhas_customer_nbr'] },
            costPerKm: { cost: 283338.6025597481, currency: 'EUR' },
            externalSystem: 3,
            referrer: 361,
            modules: [0, 1, 2, 3, 4, 5, 6, 7, 8]
        },
        userGroup: [{ id: 1001, name: 'driver', label: 'Driver', client: 100 }],
        userRoles,
        userTokens: [
            {
                id: 71949,
                token: '1000000003301431',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 1
            }
        ],
        ssoId: '6e6092dc-ae71-4607-b302-6b0a36f099e6',
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000),
            username: 'driver_7gk365z4vb41pj84d6rvu126bx5339hne',
            crmAccountId: '31034',
            crmContactId: '31034driver_7gk365z4vb41pj84d6rvu126bx5339hne'
        },
        name: 'Peter',
        surname: 'Novotny',
        contact: { emails: ['peter.novotny@eurowag.com'], phone_numbers: ['+420932624398'] },
        deleted: 0,
        active: 1,
        averageSpeed: 60,
        isActive: true,
        isContactPerson: false,
        monitoredObjectGroups: [1001]
    },
    {
        id: 2,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72043,
                token: '1000000003399253',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 2
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Radim',
        surname: 'Chudy',
        contact: { emails: ['radim.chudy@eurowag.com'], phone_numbers: ['+420694328843'] },
        deleted: 0,
        active: 1,
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 3,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72062,
                token: '1000000006645035',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 3
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Krsyzstof',
        surname: 'Majer',
        contact: { emails: ['krsyzstof.majer@eurowag.com'], phone_numbers: ['+420339568771'] },
        deleted: 0,
        active: 1,
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 4,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72064,
                token: '1000000008932440',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 4
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Jurgen',
        surname: 'Kohl',
        contact: { emails: ['jurgen.kohl@eurowag.com'], phone_numbers: ['+420833169383'] },
        deleted: 0,
        active: 1,
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 5,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72065,
                token: '1000000008933448',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 5
            }
        ],
        loginCredentials: {},
        name: 'Szandor',
        surname: 'Hadasi',
        contact: { emails: ['szandor.hadasi@eurowag.com'], phone_numbers: ['+420551326997'] },
        deleted: 0,
        active: 1,
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 6,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72066,
                token: '1000000002934449',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 6
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Sebastian',
        surname: 'Pratt',
        contact: { emails: ['sebastian.pratt@eurowag.com'], phone_numbers: ['+420009543936'] },
        deleted: 0,
        active: 1,
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 7,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72067,
                token: '1000000002934450',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 7
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Jony',
        surname: 'Michall',
        contact: { emails: ['michall.jony@eurowag.com'], phone_numbers: ['+420009543778'] },
        deleted: 0,
        active: 1,
        averageSpeed: 68,
        isActive: true,
        isContactPerson: false
    },
    {
        id: 8,
        client,
        userGroup: [],
        userRoles,
        userTokens: [
            {
                id: 72068,
                token: '1000000002934451',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 6
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Martin',
        surname: 'Smith',
        contact: { emails: ['martin.smith@eurowag.com'], phone_numbers: ['+420009543125'] },
        deleted: 0,
        active: 1,
        averageSpeed: 68,
        isActive: true,
        isContactPerson: false
    }
];

export const dispatchers = [
    {
        id: 1001,
        client,
        userGroup: [],
        userRoles: [
            {
                id: '2',
                label: 'Dispatcher',
                name: 'dispatcher',
                description: '',
                userRights: ['']
            }
        ],
        userTokens: [
            {
                id: 72066,
                token: '2934449',
                tokenType: UserTokenTokenTypeEnum.DriverCard,
                startTime: new Date(),
                endTime: new Date(),
                deleted: 0,
                holder: '',
                userId: 6
            }
        ],
        loginCredentials: {
            pin: Math.floor(1000 + Math.random() * 9000)
        },
        name: 'Michael',
        surname: 'Johnson',
        contact: {
            emails: ['michael.johnson@eurowag.com'],
            phone_numbers: ['+420009854140']
        },
        deleted: 0,
        active: 1,
        ssoId: '1',
        averageSpeed: 65,
        isActive: true,
        isContactPerson: false
    }
];

export const usersSimpleInfo: UsersSimpleInfo[] = users.map(d => ({
    id: d.id,
    contact: {
        emails: d.contact?.['emails'],
        phoneNumbers: d.contact?.['phone_numbers']
    } as UserContact
}));
