import { WithTranslation, withTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT, H, KM, L } from 'domain-constants';
import { PointInfo } from 'generated/backend-api-live/models';
import moment from 'moment';

interface Props extends WithTranslation {
    data: PointInfo & {
        addressString: string;
    };
}

const PointInfobox = (props: Props) => {
    return (
        <div className="route-point-infobox">
            <div className="route-point-infobox-title">
                {moment.unix(props.data.timestamp).format(DATE_TIME_FORMAT)}
            </div>
            <div>{props.data.addressString}</div>
            <div>{`${props.data.lat} ${props.data.lon}`}</div>
            <div>{`${props.t('JourneysTable.graph.speed')}: ${props.data.speed} ${KM}/${H}`}</div>
            {props.data.fuelLevel && (
                <div>{`${props.t('JourneysTable.graph.fuelLevel')}: ${props.data.fuelLevel} ${L}`}</div>
            )}
        </div>
    );
};

export default withTranslation()(PointInfobox);
