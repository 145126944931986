import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { roundToStep } from 'common/utils/averages';
import { Table, Rate } from 'common/components';
import { BaseDriverBehaviorModel } from 'common/model/statistics';
import qa from 'qa-selectors';

interface Props {
    data: BaseDriverBehaviorModel[];
    loading: boolean;
    showHeader?: boolean;
    onRowClick?: (id: string) => void;
    className?: string;
    qa?: string;
}

export default function DriversLeaderboardTable(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={cn('drivers-leaderboard-table', props.className)} data-qa={props.qa}>
            <Table<BaseDriverBehaviorModel>
                showHeader={props.showHeader ?? true}
                loading={props.loading}
                columns={[
                    {
                        title: t('DriverBehaviorTable.cols.rank'),
                        key: 'rank',
                        align: 'left',
                        width: 30,
                        dataIndex: 'rank',
                        className: 'drivers-leaderboard-table-rank',
                        render: (_, row) => <span data-qa={qa.driverBehaviour.side.fieldRank}>{row.rank}</span>
                    },
                    {
                        title: t('DriverBehaviorTable.cols.name'),
                        key: 'name',
                        dataIndex: 'name',
                        align: 'left',
                        render: (_, row) => (
                            <div className="drivers-leaderboard-table-name">
                                <span data-qa={qa.driverBehaviour.side.fieldRank}>{row.name}</span>
                                <Rate size="small" value={roundToStep(row.score, 0.5)} disabled allowHalf />
                            </div>
                        )
                    },
                    {
                        title: t('DriverBehaviorTable.cols.score'),
                        key: 'score',
                        dataIndex: 'score',
                        align: 'center',
                        render: (_, row) => (
                            <div
                                className="drivers-leaderboard-table-score"
                                data-qa={qa.driverBehaviour.side.fieldScore}
                            >
                                {roundToStep(row.score, 0.1)}
                            </div>
                        )
                    }
                ]}
                dataSource={props.data.map((e, i) => ({ ...e, key: i }))}
                onRow={record => ({
                    onClick: () => props.onRowClick?.(String(record.driverId))
                })}
            />
        </div>
    );
}
