/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigestDriverBehaviourDriver,
    DigestDriverBehaviourDriverFromJSON,
    DigestDriverBehaviourDriverFromJSONTyped,
    DigestDriverBehaviourDriverToJSON,
    PerfectDriveGroupedByDriver,
    PerfectDriveGroupedByDriverFromJSON,
    PerfectDriveGroupedByDriverFromJSONTyped,
    PerfectDriveGroupedByDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface PerfectDriveDriver
 */
export interface PerfectDriveDriver {
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveDriver
     */
    overalScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveDriver
     */
    driveScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveDriver
     */
    ecoScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveDriver
     */
    wearTearScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveDriver
     */
    rank?: number;
    /**
     * 
     * @type {DigestDriverBehaviourDriver}
     * @memberof PerfectDriveDriver
     */
    driver: DigestDriverBehaviourDriver;
    /**
     * 
     * @type {PerfectDriveGroupedByDriver}
     * @memberof PerfectDriveDriver
     */
    pdGroupByDriver: PerfectDriveGroupedByDriver;
}

export function PerfectDriveDriverFromJSON(json: any): PerfectDriveDriver {
    return PerfectDriveDriverFromJSONTyped(json, false);
}

export function PerfectDriveDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDriveDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overalScore': json['overal_score'],
        'driveScore': json['drive_score'],
        'ecoScore': json['eco_score'],
        'wearTearScore': json['wear_tear_score'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'driver': DigestDriverBehaviourDriverFromJSON(json['driver']),
        'pdGroupByDriver': PerfectDriveGroupedByDriverFromJSON(json['pd_group_by_driver']),
    };
}

export function PerfectDriveDriverToJSON(value?: PerfectDriveDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overal_score': value.overalScore,
        'drive_score': value.driveScore,
        'eco_score': value.ecoScore,
        'wear_tear_score': value.wearTearScore,
        'rank': value.rank,
        'driver': DigestDriverBehaviourDriverToJSON(value.driver),
        'pd_group_by_driver': PerfectDriveGroupedByDriverToJSON(value.pdGroupByDriver),
    };
}


