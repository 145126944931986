import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { TIME_FORMAT_SHORT } from 'domain-constants';

import ProgressBar from '../ProgressBar';
import AlarmRemainsDuration from '../AlarmRemainsDuration';

interface Props {
    drivingDuration?: number;
    workingDuration?: number;
    restingDuration?: number;
    dailyUtilizationMaxHours?: number;
    dailyUtilizationLeft?: number;
    drivingLeft?: number;
}

export default function AETRDataDetail(props: Props) {
    const { t } = useTranslation();

    const dailyUtilizationMaxSeconds = (props.dailyUtilizationMaxHours ?? 0) * 3600;

    return (
        <div className="aetr-data-detail">
            <ProgressBar
                first={props.drivingDuration}
                second={props.workingDuration}
                third={props.restingDuration}
                fourth={props.dailyUtilizationLeft}
                maxWidth={dailyUtilizationMaxSeconds}
                legend={{
                    first: t('VehicleCard.ride'),
                    second: t('VehicleCard.working'),
                    third: t('VehicleCard.resting_pause'),
                    fourth: t('VehicleCard.dailyUtilizationRemains')
                }}
                label={{
                    first: props.drivingDuration
                        ? moment
                              .utc(
                                  moment
                                      .duration(
                                          props.drivingDuration < dailyUtilizationMaxSeconds
                                              ? props.drivingDuration
                                              : dailyUtilizationMaxSeconds,
                                          'seconds'
                                      )
                                      .asMilliseconds()
                              )
                              .format(TIME_FORMAT_SHORT)
                        : '',
                    second: '',
                    third: '',
                    fourth: props.dailyUtilizationLeft
                        ? moment
                              .utc(moment.duration(props.dailyUtilizationLeft, 'seconds').asMilliseconds())
                              .format(TIME_FORMAT_SHORT)
                        : ''
                }}
            />
            <div className="aetr-data-detail-description">
                {props.dailyUtilizationLeft !== undefined && (
                    <Row justify="space-between">
                        <Col>{t('VehicleCard.dailyUtilizationRemains')}</Col>
                        <Col>
                            <AlarmRemainsDuration
                                remainingDuration={props.dailyUtilizationLeft}
                                max={dailyUtilizationMaxSeconds}
                            />
                        </Col>
                    </Row>
                )}
                <Row justify="space-between">
                    <Col>{t('VehicleCard.dailyDriveRemains')}</Col>
                    <Col>
                        <strong>
                            {moment
                                .utc(moment.duration(props.drivingLeft, 'seconds').asMilliseconds())
                                .format(TIME_FORMAT_SHORT)}
                        </strong>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
