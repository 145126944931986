import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    onCloseClick?: () => void;
    content: string;
}

function DocsContent(props: Props) {
    return (
        <div className="docs-content">
            <div className="docs-content-html">
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
        </div>
    );
}

export default withTranslation()(DocsContent);
