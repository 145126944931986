/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaintenanceNotificationEvent,
    MaintenanceNotificationEventFromJSON,
    MaintenanceNotificationEventFromJSONTyped,
    MaintenanceNotificationEventToJSON,
    NotificationTypeEnum,
    NotificationTypeEnumFromJSON,
    NotificationTypeEnumFromJSONTyped,
    NotificationTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface MaintenanceNotificationInDb
 */
export interface MaintenanceNotificationInDb {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceNotificationInDb
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationInDb
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationInDb
     */
    userSsoId?: string;
    /**
     * 
     * @type {NotificationTypeEnum}
     * @memberof MaintenanceNotificationInDb
     */
    notificationType: NotificationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceNotificationInDb
     */
    notified: boolean;
    /**
     * 
     * @type {MaintenanceNotificationEvent}
     * @memberof MaintenanceNotificationInDb
     */
    event: MaintenanceNotificationEvent;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationInDb
     */
    taskId: string;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceNotificationInDb
     */
    acknowledged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationInDb
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof MaintenanceNotificationInDb
     */
    notifyAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceNotificationInDb
     */
    retryCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceNotificationInDb
     */
    emailFailed?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MaintenanceNotificationInDb
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationInDb
     */
    id: string;
}

export function MaintenanceNotificationInDbFromJSON(json: any): MaintenanceNotificationInDb {
    return MaintenanceNotificationInDbFromJSONTyped(json, false);
}

export function MaintenanceNotificationInDbFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceNotificationInDb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userSsoId': !exists(json, 'userSsoId') ? undefined : json['userSsoId'],
        'notificationType': NotificationTypeEnumFromJSON(json['notification_type']),
        'notified': json['notified'],
        'event': MaintenanceNotificationEventFromJSON(json['event']),
        'taskId': json['taskId'],
        'acknowledged': !exists(json, 'acknowledged') ? undefined : json['acknowledged'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'notifyAt': !exists(json, 'notify_at') ? undefined : (new Date(json['notify_at'])),
        'retryCount': !exists(json, 'retry_count') ? undefined : json['retry_count'],
        'emailFailed': !exists(json, 'emailFailed') ? undefined : json['emailFailed'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'id': json['id'],
    };
}

export function MaintenanceNotificationInDbToJSON(value?: MaintenanceNotificationInDb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'userId': value.userId,
        'userSsoId': value.userSsoId,
        'notification_type': NotificationTypeEnumToJSON(value.notificationType),
        'notified': value.notified,
        'event': MaintenanceNotificationEventToJSON(value.event),
        'taskId': value.taskId,
        'acknowledged': value.acknowledged,
        'email': value.email,
        'notify_at': value.notifyAt === undefined ? undefined : (value.notifyAt.toISOString()),
        'retry_count': value.retryCount,
        'emailFailed': value.emailFailed,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'id': value.id,
    };
}


