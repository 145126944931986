/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginUserResponse
 */
export interface LoginUserResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginUserResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserResponse
     */
    refreshToken: string;
}

export function LoginUserResponseFromJSON(json: any): LoginUserResponse {
    return LoginUserResponseFromJSONTyped(json, false);
}

export function LoginUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
    };
}

export function LoginUserResponseToJSON(value?: LoginUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
    };
}


