/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectFleetDynamicDataTrailerDynamic,
    MonitoredObjectFleetDynamicDataTrailerDynamicFromJSON,
    MonitoredObjectFleetDynamicDataTrailerDynamicFromJSONTyped,
    MonitoredObjectFleetDynamicDataTrailerDynamicToJSON,
    MonitoredObjectFleetDynamicDataTrailerManual,
    MonitoredObjectFleetDynamicDataTrailerManualFromJSON,
    MonitoredObjectFleetDynamicDataTrailerManualFromJSONTyped,
    MonitoredObjectFleetDynamicDataTrailerManualToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectFleetDynamicData
 */
export interface MonitoredObjectFleetDynamicData {
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFleetDynamicData
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectFleetDynamicData
     */
    defaultDriverId?: number;
    /**
     * 
     * @type {Array<MonitoredObjectFleetDynamicDataTrailerDynamic>}
     * @memberof MonitoredObjectFleetDynamicData
     */
    trailersDynamic: Array<MonitoredObjectFleetDynamicDataTrailerDynamic>;
    /**
     * 
     * @type {Array<MonitoredObjectFleetDynamicDataTrailerManual>}
     * @memberof MonitoredObjectFleetDynamicData
     */
    trailersManual: Array<MonitoredObjectFleetDynamicDataTrailerManual>;
}

export function MonitoredObjectFleetDynamicDataFromJSON(json: any): MonitoredObjectFleetDynamicData {
    return MonitoredObjectFleetDynamicDataFromJSONTyped(json, false);
}

export function MonitoredObjectFleetDynamicDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFleetDynamicData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'defaultDriverId': !exists(json, 'default_driver_id') ? undefined : json['default_driver_id'],
        'trailersDynamic': ((json['trailers_dynamic'] as Array<any>).map(MonitoredObjectFleetDynamicDataTrailerDynamicFromJSON)),
        'trailersManual': ((json['trailers_manual'] as Array<any>).map(MonitoredObjectFleetDynamicDataTrailerManualFromJSON)),
    };
}

export function MonitoredObjectFleetDynamicDataToJSON(value?: MonitoredObjectFleetDynamicData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'default_driver_id': value.defaultDriverId,
        'trailers_dynamic': ((value.trailersDynamic as Array<any>).map(MonitoredObjectFleetDynamicDataTrailerDynamicToJSON)),
        'trailers_manual': ((value.trailersManual as Array<any>).map(MonitoredObjectFleetDynamicDataTrailerManualToJSON)),
    };
}


