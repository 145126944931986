import { message } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { WithLogic, withLogicContext } from 'App';
import { Button, Dragger, Modal } from 'common/components';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Subscription } from 'rxjs';
import qa from 'qa-selectors';
import * as CommonIcons from 'resources/images/common';

interface Props extends WithLogic, WithTranslation {
    demoMode?: boolean;
}

interface State {
    file?: UploadFile;
    errors?: Array<{
        error: string;
        position: {
            row: number;
            cell: string;
        };
    }>;
    modal: {
        loading?: boolean;
        visible: boolean;
        content: 'IMPORT' | 'EXPORT';
    };
}
class ImportExport extends React.Component<Props, State> {
    subscriptionError?: Subscription;
    subscriptionSuccess?: Subscription;
    subscriptionLoading?: Subscription;

    constructor(props: Props) {
        super(props);
        this.state = {
            modal: {
                content: 'EXPORT',
                visible: false,
                loading: false
            }
        };
    }

    componentDidMount() {
        this.subscriptionError = this.props.logic
            .diems()
            .rates()
            .diemsImportErrors.subscribe(err => {
                message.error(this.props.t('Allowance.message.ImportRatesError'));
                if (Array.isArray(err)) {
                    this.setState(state => ({
                        ...state,
                        errors: err
                    }));
                }
            });

        this.subscriptionSuccess = this.props.logic
            .diems()
            .rates()
            .diemsImportSuccess.subscribe(() => {
                message.success(this.props.t('Allowance.message.importRatesSuccess'));
                this.setState(state => ({
                    ...state,
                    modal: {
                        ...state.modal,
                        loading: false,
                        visible: false
                    }
                }));
            });

        this.subscriptionLoading = this.props.logic
            .diems()
            .rates()
            .diemsImportLoading.subscribe(loading => {
                this.setState(state => ({
                    ...state,
                    modal: {
                        ...state.modal,
                        loading: loading
                    }
                }));
            });
    }
    componentWillUnmount() {
        this.subscriptionError?.unsubscribe();
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionSuccess?.unsubscribe();
    }

    private _onCancel = () => {
        this.setState(state => ({
            modal: {
                ...state.modal,
                visible: false
            },
            file: undefined
        }));
    };

    private _onOkImportClick = () => {
        const file = this.state.file;
        if (file?.originFileObj) {
            this.props.logic
                .diems()
                .rates()
                .importDiemsRates(file.originFileObj)
                .then(_res => {
                    this.setState({
                        file: undefined
                    });
                });
        }
    };

    private _onBeforeUpload = (file: RcFile) => {
        this.setState(state => ({
            ...state,
            file: {
                uid: file.uid,
                name: file.name,
                originFileObj: file,
                size: file.size,
                type: file.type
            },
            errors: undefined
        }));

        return false;
    };

    private _onRemove = () => {
        this.setState({ file: undefined });
    };

    private _defaultAllowanceBtn = () => {
        return (
            <a href="/documents/allowances.xlsx" target="_blank" rel="noopener noreferrer">
                <Button
                    type="link"
                    className="allowances-import-export-default"
                    qa={qa.allowances.importExport.btnDefault}
                >
                    {this.props.t('AllowanceExport.defaultAllowance')}
                </Button>
            </a>
        );
    };

    render() {
        return (
            <div className="allowances-import-export">
                <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                        this.setState({
                            modal: {
                                visible: true,
                                content: 'IMPORT'
                            }
                        });
                    }}
                    disabled={this.props.demoMode}
                    qa={qa.allowances.bar.btnImport}
                    icon={<img alt="edit" src={CommonIcons.import} />}
                >
                    {this.props.t('common.import')}
                </Button>
                <Modal
                    okButtonProps={{
                        disabled: this.state.file === undefined
                    }}
                    onOk={this.state.modal.content === 'IMPORT' ? this._onOkImportClick : this._onCancel}
                    closable={false}
                    confirmLoading={this.state.modal.loading}
                    onCancel={this._onCancel}
                    visible={this.state.modal.visible}
                >
                    <>
                        {this.state.modal.content === 'EXPORT' ? (
                            this._defaultAllowanceBtn()
                        ) : (
                            <>
                                <Dragger
                                    fileList={this.state.file ? [this.state.file] : []}
                                    multiple={false}
                                    accept=".xls,.xlsx"
                                    beforeUpload={this._onBeforeUpload}
                                    onRemove={this._onRemove}
                                >
                                    {this.props.t('AllowanceExport.drop')}
                                </Dragger>
                                {this._defaultAllowanceBtn()}
                                {this.state.errors?.map(err => {
                                    return (
                                        <div className="allowances-import-export-errors">
                                            {err.position.cell}
                                            {err.position.row} : {err.error}
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                </Modal>
            </div>
        );
    }
}

export default withLogicContext(withTranslation()(ImportExport));
