/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceActualVehicleStateModelsVehicleGPSData
 */
export interface ServiceActualVehicleStateModelsVehicleGPSData {
    /**
     * 
     * @type {Date}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    time?: Date;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    lon?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    alt?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    speed?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleGPSData
     */
    angle: number;
}

export function ServiceActualVehicleStateModelsVehicleGPSDataFromJSON(json: any): ServiceActualVehicleStateModelsVehicleGPSData {
    return ServiceActualVehicleStateModelsVehicleGPSDataFromJSONTyped(json, false);
}

export function ServiceActualVehicleStateModelsVehicleGPSDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceActualVehicleStateModelsVehicleGPSData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'alt': !exists(json, 'alt') ? undefined : json['alt'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'angle': json['angle'],
    };
}

export function ServiceActualVehicleStateModelsVehicleGPSDataToJSON(value?: ServiceActualVehicleStateModelsVehicleGPSData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'lat': value.lat,
        'lon': value.lon,
        'alt': value.alt,
        'speed': value.speed,
        'angle': value.angle,
    };
}


