/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListOfChars
 */
export interface ListOfChars {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListOfChars
     */
    activeClients: Array<string>;
}

export function ListOfCharsFromJSON(json: any): ListOfChars {
    return ListOfCharsFromJSONTyped(json, false);
}

export function ListOfCharsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfChars {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeClients': json['active_clients'],
    };
}

export function ListOfCharsToJSON(value?: ListOfChars | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_clients': value.activeClients,
    };
}


