import { Col, Row } from 'antd';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BaseDriverBehaviorModel, DriverBehaviorTrendsModel } from 'common/model/statistics';
import DriverScoresWidget from 'common/reports-widgets/DriverScoresWidget';
import TopDriversWidget from 'common/reports-widgets/TopDriversWidget';
import WorstDriversWidget from 'common/reports-widgets/WorstDriversWidget';
import { DriverBehaviorType } from 'modules/statistics/modules/driver-behavior/components/DriverBehaviorBar';
import SavedWidget from 'common/reports-widgets/SavedWidget';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import { Select } from 'common/components/Form';
import qa from 'qa-selectors';
import TopDriversTrendsWidget from 'common/reports-widgets/TopDriversTrendsWidget';
import WorstDriversTrendsWidget from 'common/reports-widgets/WorstDriversTrendsWidget';
import DtcoCalendar from 'common/components/DtcoCalendar';

interface Props extends WithTranslation {
    filter: DriverBehaviorType;
    date?: string;
    vehicles?: {
        loading: boolean;
        topDrivers?: {
            data: BaseDriverBehaviorModel[];
        };
        worstDrivers?: {
            data: BaseDriverBehaviorModel[];
        };
        driverScores?: {
            trend?: number;
            value?: number;
            currency?: string;
            data: { stars: number; percentage: number }[];
        };
        saved?: {
            trend?: number;
            value: number;
            currency: string;
        };
    };
    trucks?: {
        loading: boolean;
        topDrivers?: {
            dataTrends?: DriverBehaviorTrendsModel[];
        };
        worstDrivers?: {
            dataTrends?: DriverBehaviorTrendsModel[];
        };
        driverScores?: {
            trend?: number;
            value?: number;
            currency?: string;
            data: { stars: number; percentage: number }[];
        };
        saved?: {
            trend?: number;
            value: number;
            currency: string;
        };
    };
    helper?: {
        content: string;
    };
    onBarHelperClick?: () => void;
    onHelperClose?: () => void;
    onFilterChange?: (key: DriverBehaviorType) => void;
    onDateRangeChange?: (value: string) => void;
    onDateNextChange?: () => void;
    onDatePrevChange?: () => void;
    onTrucksWidgetClick?: () => void;
    onVehiclesWidgetClick?: () => void;
    onExpensesWidgetClick?: () => void;
}

function CompanyProfileDashboard(props: Props) {
    function onDateRangeChange(date: moment.Moment) {
        if (date) {
            props.onDateRangeChange?.(date.utc().toISOString());
        }
    }

    function handlerDateNextChange() {
        props.onDateNextChange?.();
    }

    function handlerDatePrevChange() {
        props.onDatePrevChange?.();
    }

    return (
        <div className="company-profile-dashboard">
            <TableBar
                heading={props.t('CompanyProfileDashboard.dashboard')}
                filters={[
                    <DtcoCalendar
                        startDate={moment(props.date).toISOString()}
                        onDatePickerChange={onDateRangeChange}
                        onNextChange={handlerDateNextChange}
                        onPreviousChange={handlerDatePrevChange}
                        data-qa={qa.companyProfile.dashboard.btnDatePicker}
                    />,
                    <Select
                        size="large"
                        value={props.filter}
                        onChange={props.onFilterChange}
                        options={[
                            {
                                label: props.t('DriverBehaviorFilter.lightVehicles'),
                                value: DriverBehaviorType.Light
                            },
                            {
                                label: props.t('DriverBehaviorFilter.trucks'),
                                value: DriverBehaviorType.Trucks
                            }
                        ]}
                    />
                ]}
            />
            {props.filter === DriverBehaviorType.Trucks && (
                <>
                    <Row gutter={[20, 20]}>
                        {props.trucks?.driverScores?.data && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <DriverScoresWidget
                                    loading={props.trucks.loading}
                                    trend={props.trucks.driverScores.trend}
                                    value={props.trucks.driverScores.value}
                                    currency={props.trucks.driverScores.currency}
                                    data={props.trucks.driverScores.data}
                                    onClick={props.onTrucksWidgetClick}
                                />
                            </Col>
                        )}

                        {props.trucks?.topDrivers?.dataTrends && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <TopDriversTrendsWidget
                                    loading={props.trucks.loading}
                                    data={props.trucks.topDrivers.dataTrends}
                                    onClick={props.onTrucksWidgetClick}
                                />
                            </Col>
                        )}

                        {props.trucks?.worstDrivers?.dataTrends && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <WorstDriversTrendsWidget
                                    loading={props.trucks.loading}
                                    data={props.trucks.worstDrivers.dataTrends}
                                    onClick={props.onTrucksWidgetClick}
                                />
                            </Col>
                        )}
                    </Row>
                    {props.vehicles?.saved && (
                        <Row gutter={[20, 20]}>
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <SavedWidget
                                    value={props.vehicles.saved?.value}
                                    currency={props.vehicles.saved?.currency}
                                    trend={props.vehicles.saved?.trend}
                                    loading={props.vehicles.loading}
                                    onClick={props.onExpensesWidgetClick}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {props.filter === DriverBehaviorType.Light && (
                <>
                    <Row gutter={[20, 20]}>
                        {props.vehicles?.driverScores?.data && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <DriverScoresWidget
                                    loading={props.vehicles.loading}
                                    trend={props.vehicles.driverScores.trend}
                                    value={props.vehicles.driverScores.value}
                                    currency={props.vehicles.driverScores.currency}
                                    data={props.vehicles.driverScores.data}
                                    onClick={props.onVehiclesWidgetClick}
                                />
                            </Col>
                        )}

                        {props.vehicles?.topDrivers?.data && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <TopDriversWidget
                                    loading={props.vehicles.loading}
                                    data={props.vehicles.topDrivers.data}
                                    onClick={props.onVehiclesWidgetClick}
                                />
                            </Col>
                        )}

                        {props.vehicles?.worstDrivers?.data && (
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <WorstDriversWidget
                                    loading={props.vehicles.loading}
                                    data={props.vehicles.worstDrivers.data}
                                    onClick={props.onVehiclesWidgetClick}
                                />
                            </Col>
                        )}
                    </Row>
                    {props.vehicles?.saved && (
                        <Row gutter={[20, 20]}>
                            <Col xxl={8} xl={12} lg={24} md={24} sm={24}>
                                <SavedWidget
                                    value={props.vehicles.saved?.value}
                                    currency={props.vehicles.saved?.currency}
                                    trend={props.vehicles.saved?.trend}
                                    loading={props.vehicles.loading}
                                    onClick={props.onExpensesWidgetClick}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            <HelperModal
                name="company-profile"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}

export default withTranslation()(CompanyProfileDashboard);
