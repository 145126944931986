import { Row } from 'antd';
import cn from 'classnames';
import { Button } from 'common/components';

interface TabBarOption {
    key: string;
    label: string;
    disabled?: boolean;
    qa?: string;
}

type Props = {
    options: TabBarOption[];
    selectedOption: string;
    size?: 'large' | 'middle' | 'small';
    className?: string;
    onSelect(key: string): void;
};

const UiTabBar = ({ options, selectedOption, size = 'middle', className, onSelect }: Props) => {
    const handlerTabClick = (option: TabBarOption) => {
        if (!option.disabled) {
            onSelect(option.key);
        }
    };

    return (
        <Row justify="center" align="middle" className={`ui-tab-bar ${className}`}>
            {options.map(option => (
                <Button
                    size={size}
                    key={`ui-tab-bar-option-${option.key}`}
                    onClick={() => handlerTabClick(option)}
                    className={cn('tab-bar-item', {
                        active: selectedOption === option.key,
                        disabled: option.disabled
                    })}
                    qa={option.qa}
                >
                    {option.label}
                </Button>
            ))}
        </Row>
    );
};

export default UiTabBar;
