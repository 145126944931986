/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectOperationalCosts,
    MonitoredObjectOperationalCostsFromJSON,
    MonitoredObjectOperationalCostsFromJSONTyped,
    MonitoredObjectOperationalCostsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectOperationalCost
 */
export interface MonitoredObjectOperationalCost {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOperationalCost
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectOperationalCost
     */
    dateFrom?: Date;
    /**
     * 
     * @type {MonitoredObjectOperationalCosts}
     * @memberof MonitoredObjectOperationalCost
     */
    costs: MonitoredObjectOperationalCosts;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectOperationalCost
     */
    dateTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOperationalCost
     */
    monitoredObject: number;
}

export function MonitoredObjectOperationalCostFromJSON(json: any): MonitoredObjectOperationalCost {
    return MonitoredObjectOperationalCostFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'costs': MonitoredObjectOperationalCostsFromJSON(json['costs']),
        'dateTo': !exists(json, 'date_to') ? undefined : (json['date_to'] === null ? null : new Date(json['date_to'])),
        'monitoredObject': json['monitored_object'],
    };
}

export function MonitoredObjectOperationalCostToJSON(value?: MonitoredObjectOperationalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString()),
        'costs': MonitoredObjectOperationalCostsToJSON(value.costs),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo === null ? null : value.dateTo.toISOString()),
        'monitored_object': value.monitoredObject,
    };
}


