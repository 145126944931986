/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * always return ChannelInfo structure these values should:     - on get    - come from messagign-api     - on create - be default as is defined here
 * @export
 * @interface ChannelInfo
 */
export interface ChannelInfo {
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelInfo
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    creator: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelInfo
     */
    users: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ChannelInfo
     */
    newMsgCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof ChannelInfo
     */
    newMsgFrom?: Date;
}

export function ChannelInfoFromJSON(json: any): ChannelInfo {
    return ChannelInfoFromJSONTyped(json, false);
}

export function ChannelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'created': (new Date(json['created'])),
        'creator': json['creator'],
        'users': json['users'],
        'newMsgCount': !exists(json, 'new_msg_count') ? undefined : json['new_msg_count'],
        'newMsgFrom': !exists(json, 'new_msg_from') ? undefined : (new Date(json['new_msg_from'])),
    };
}

export function ChannelInfoToJSON(value?: ChannelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'created': (value.created.toISOString()),
        'creator': value.creator,
        'users': value.users,
        'new_msg_count': value.newMsgCount,
        'new_msg_from': value.newMsgFrom === undefined ? undefined : (value.newMsgFrom.toISOString()),
    };
}


