/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPRequestBodyObeProvisioning,
    WAGTPRequestBodyObeProvisioningFromJSON,
    WAGTPRequestBodyObeProvisioningFromJSONTyped,
    WAGTPRequestBodyObeProvisioningToJSON,
    WAGTPRequestBodyServiceContractProvisioning,
    WAGTPRequestBodyServiceContractProvisioningFromJSON,
    WAGTPRequestBodyServiceContractProvisioningFromJSONTyped,
    WAGTPRequestBodyServiceContractProvisioningToJSON,
    WAGTPRequestHeader,
    WAGTPRequestHeaderFromJSON,
    WAGTPRequestHeaderFromJSONTyped,
    WAGTPRequestHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface WAGTPRequest
 */
export interface WAGTPRequest {
    /**
     * 
     * @type {WAGTPRequestHeader}
     * @memberof WAGTPRequest
     */
    requestHeader: WAGTPRequestHeader;
    /**
     * 
     * @type {object | WAGTPRequestBodyObeProvisioning | WAGTPRequestBodyServiceContractProvisioning}
     * @memberof WAGTPRequest
     */
    requestBody: object | WAGTPRequestBodyObeProvisioning | WAGTPRequestBodyServiceContractProvisioning;
}

export function WAGTPRequestFromJSON(json: any): WAGTPRequest {
    return WAGTPRequestFromJSONTyped(json, false);
}

export function WAGTPRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestHeader': WAGTPRequestHeaderFromJSON(json['requestHeader']),
        'requestBody': WAGTPRequestBodyServiceContractProvisioningFromJSON(json['requestBody']) //object | WAGTPRequestBodyObeProvisioning | WAGTPRequestBodyServiceContractProvisioningFromJSON(json['requestBody']),
    };
}

export function WAGTPRequestToJSON(value?: WAGTPRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestHeader': WAGTPRequestHeaderToJSON(value.requestHeader),
        'requestBody': WAGTPRequestBodyServiceContractProvisioningFromJSON(value.requestBody) //object | WAGTPRequestBodyObeProvisioning | WAGTPRequestBodyServiceContractProvisioningToJSON(value.requestBody),
    };
}


