/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientSSOIdentities
 */
export interface ClientSSOIdentities {
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    identityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    nav4Id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    username?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSSOIdentities
     */
    emailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientSSOIdentities
     */
    state?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSSOIdentities
     */
    hasCredentials?: boolean;
}

export function ClientSSOIdentitiesFromJSON(json: any): ClientSSOIdentities {
    return ClientSSOIdentitiesFromJSONTyped(json, false);
}

export function ClientSSOIdentitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientSSOIdentities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identityId': !exists(json, 'identityId') ? undefined : json['identityId'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'contactNumber': !exists(json, 'contactNumber') ? undefined : json['contactNumber'],
        'nav4Id': !exists(json, 'nav4Id') ? undefined : json['nav4Id'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'emailVerified': !exists(json, 'emailVerified') ? undefined : json['emailVerified'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'hasCredentials': !exists(json, 'hasCredentials') ? undefined : json['hasCredentials'],
    };
}

export function ClientSSOIdentitiesToJSON(value?: ClientSSOIdentities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identityId': value.identityId,
        'accountNumber': value.accountNumber,
        'contactNumber': value.contactNumber,
        'nav4Id': value.nav4Id,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'username': value.username,
        'emailVerified': value.emailVerified,
        'state': value.state,
        'hasCredentials': value.hasCredentials,
    };
}


