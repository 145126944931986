import { AddressStructured } from 'common/model/address';
import moment, { Moment } from 'moment';
import cn from 'classnames';
import { Role } from 'logic/auth';

interface Props {
    defaultAddress?: string;
    address?: AddressStructured;
    iconUrl: string;
    rta?: string;
    roles?: Role[];
}

export default function AddressDetail(props: Props) {
    function getFormattedDateString(date: Moment) {
        return `${date.format('L')} (${date.format('dddd')}) ${'at'} ${date.format('HH:mm')}`;
    }

    function renderCountry(countryCode: string) {
        return (
            <div className="transport-address-detail-country">
                <span className={cn('flag-icon flag-icon-squared', `flag-icon-${countryCode}`)} />
            </div>
        );
    }

    function getCountry(address?: AddressStructured) {
        const countryCode = address?.countryCode?.toLowerCase();
        return countryCode ?? '';
    }

    return (
        <div className="transport-address-detail">
            <img alt="truck" src={props.iconUrl} />
            <div className="transport-address-detail-data">
                <div className="transport-address-detail-data-text">
                    <span>
                        {props.address
                            ? `${props.address?.country}, ${props.address?.postalCode}, ${props.address?.address}`
                            : props.defaultAddress}
                    </span>
                    {props.roles?.length === 0 ||
                        (props.roles?.includes(Role.ETA_R) && <p>{getFormattedDateString(moment.utc(props.rta))}</p>)}
                </div>
                {props.address && <div>{renderCountry(getCountry(props.address))}</div>}
            </div>
        </div>
    );
}
