/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceTaskDeleteList
 */
export interface MaintenanceTaskDeleteList {
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceTaskDeleteList
     */
    maintenanceTasks: Array<string>;
}

export function MaintenanceTaskDeleteListFromJSON(json: any): MaintenanceTaskDeleteList {
    return MaintenanceTaskDeleteListFromJSONTyped(json, false);
}

export function MaintenanceTaskDeleteListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceTaskDeleteList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maintenanceTasks': json['maintenance_tasks'],
    };
}

export function MaintenanceTaskDeleteListToJSON(value?: MaintenanceTaskDeleteList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maintenance_tasks': value.maintenanceTasks,
    };
}


