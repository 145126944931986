import cn from 'classnames';
import moment from 'moment';
import { Row, Col } from 'antd';
import { Button, Pagination, Progress, Table, Tag } from 'common/components';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MaintenanceTaskTableModel } from 'modules/statistics/modules/maintenance/MaintenanceModule';
import MaintenanceIntro from 'modules/statistics/modules/maintenance/components/maintenance-intro';
import * as CommonIcons from 'resources/images/common';
import { TaskTypeCategoryEnum } from 'generated/new-main';
import { KM, TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import numeral from 'numeral';
import { pxWidthTextLength } from 'common/utils/textUtils';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    data: PaginatedResponse<MaintenanceTaskTableModel[]>;
    selected?: MaintenanceTaskTableModel;
    checked: string[];
    loading?: boolean;
    filtered: boolean;
    onRowCheckboxClick: (ids: string[]) => void;
    onRowClick: (maintenanceTask: MaintenanceTaskTableModel) => void;
    onRowEditClick?: (id: string) => void;
    onRowDuplicateClick?: (id: string) => void;
    onPaginationChange: (pagination: PaginationParams) => void;
    onIntroCreateNewClick?: () => void;
}

function MaintenanceTaskTable(props: Props) {
    const maxLabelLength = Math.max(...props.data.data.map(d => props.t(`Maintenance.dueFilter.${d.state}`).length));
    function getSubjectLabel(value: MaintenanceTaskTableModel) {
        switch (value.category) {
            case TaskTypeCategoryEnum.Vehicle:
                return (
                    <>
                        <img src={CommonIcons.truckCabin} alt="vehicle" />
                        {value.vehicle}
                    </>
                );
            case TaskTypeCategoryEnum.Trailer:
                return (
                    <>
                        <img src={CommonIcons.trailerTwoAxles} alt="trailer" />
                        {value.trailer}
                    </>
                );
            case TaskTypeCategoryEnum.Driver:
                return (
                    <>
                        <img src={CommonIcons.person} alt="person" />
                        {value.driver}
                    </>
                );
            case TaskTypeCategoryEnum.Company:
            default:
                return (
                    <>
                        <img src={CommonIcons.homeWork} alt="company" />
                        {value.company}
                    </>
                );
        }
    }

    const onSelectChange = (selectedRowKeys: React.ReactText[]) => {
        props.onRowCheckboxClick?.(selectedRowKeys as string[]);
    };

    const rowSelection = {
        selectedRowKeys: props.checked,
        onChange: onSelectChange,
        columnWidth: 32
    };

    return (
        <div className="maintenance-table">
            <Table<MaintenanceTaskTableModel>
                locale={
                    !props.loading && props.data.total === 0 && !props.filtered
                        ? {
                              emptyText: <MaintenanceIntro onCreateNewClick={props.onIntroCreateNewClick} />
                          }
                        : { emptyText: <span>{!props.loading && props.t('utils.empty')}</span> }
                }
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                loading={props.loading}
                dataSource={props.data.data.map(d => ({ ...d, key: d.id }))}
                onRow={record => ({
                    className: cn({
                        'ant-table-row-selected': props.selected?.id === record.id
                    }),
                    onClick: () => {
                        props.onRowClick?.(record);
                    }
                })}
                rowSelection={rowSelection}
                columns={[
                    {
                        title: props.t('Maintenance.task.taskType'),
                        width: 180,
                        render: (_, record) => <span data-qa={''}>{record.taskType?.name}</span>
                    },
                    {
                        title: props.t('common.subject'),
                        width: 170,
                        className: 'maintenance-table-subject',
                        render: (_, record) => <span data-qa={''}>{getSubjectLabel(record)}</span>
                    },
                    {
                        title: props.t('Maintenance.task.dueAndDependence'),
                        width: 240,
                        render: (_, record) => (
                            <Row data-qa={''} gutter={[12, 0]} align="middle">
                                <Col>
                                    <Tag
                                        className={`tag-maintenance-state tag-maintenance-state-${record.state}`}
                                        style={{ width: pxWidthTextLength(maxLabelLength) + 20 }}
                                    >
                                        {props.t(`Maintenance.dueFilter.${record.state}`)}
                                    </Tag>
                                    {record.dueProgressPercent !== undefined && (
                                        <Progress
                                            percent={record.dueProgressPercent}
                                            size="small"
                                            showInfo={false}
                                            className={`progress-maintenance progress-maintenance-${record.state}`}
                                        />
                                    )}
                                </Col>
                                <Col>
                                    {record.state !== 'done' && (
                                        <span className="maintenance-table-due-dependence">
                                            {record.dueDateLeftDays !== undefined && (
                                                <>
                                                    {record.dueDateLeftDays === 0 ? (
                                                        props.t('common.today')
                                                    ) : (
                                                        <>
                                                            {Math.abs(record.dueDateLeftDays)}{' '}
                                                            {Math.abs(record.dueDateLeftDays) > 1
                                                                ? props.t('common.days')
                                                                : props.t('common.day')}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {record.dueDateLeftDays !== undefined &&
                                                record.dueMileageLeftKilometers !== undefined &&
                                                ' | '}
                                            {record.dueMileageLeftKilometers !== undefined && (
                                                <>
                                                    {numeral(record.dueMileageLeftKilometers).format('0,0')} {KM}
                                                </>
                                            )}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        )
                    },
                    {
                        title: props.t('Maintenance.task.completedOn'),
                        width: 130,
                        align: 'center',
                        render: (_, record) => (
                            <span data-qa={''}>
                                {record.completionTime ? moment(record.completionTime).format('L') : ''}
                            </span>
                        )
                    },
                    {
                        title: `${props.t('common.price')} ${props.t('common.includingVAT')}`,
                        width: 140,
                        align: 'center',
                        render: (_, record) =>
                            record.expenses?.priceTotalWoVat &&
                            record.expenses.vat && (
                                <span data-qa={''}>
                                    {numeral(record.expenses.priceTotalWoVat * (1 + record.expenses.vat / 100)).format(
                                        '0,0.00'
                                    )}{' '}
                                    {record.expenses?.currency}
                                </span>
                            )
                    },
                    {
                        title: props.t('common.actions'),
                        width: 120,
                        align: 'right',
                        render: (_, record) => (
                            <Row justify="end">
                                {record.state !== 'done' && (
                                    <Col>
                                        <Button
                                            type="text"
                                            icon={<img src={CommonIcons.editPencilFill} alt="edit" />}
                                            title={props.t('common.edit')}
                                            onClick={e => {
                                                e.stopPropagation();
                                                props.onRowEditClick?.(record.id);
                                            }}
                                        />
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                        type="text"
                                        icon={<img src={CommonIcons.documentDuplicate} alt="duplicate" />}
                                        title={props.t('common.duplicate')}
                                        onClick={e => {
                                            e.stopPropagation();
                                            props.onRowDuplicateClick?.(record.id);
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                ]}
            />
            {!props.loading &&
            props.data.total > 0 &&
            props.data?.total > props.data?.limit &&
            props.data?.offset !== undefined &&
            props.data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={props.data?.total}
                        pageSize={props.data?.limit}
                        current={getTableCurrentPage(props.data.offset, props.data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );
}

export default withTranslation()(MaintenanceTaskTable);
