/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvgPricePerKmOfFleetType,
    AvgPricePerKmOfFleetTypeFromJSON,
    AvgPricePerKmOfFleetTypeFromJSONTyped,
    AvgPricePerKmOfFleetTypeToJSON,
    CompanyCostProfile,
    CompanyCostProfileFromJSON,
    CompanyCostProfileFromJSONTyped,
    CompanyCostProfileToJSON,
    ComputedMarketAvgPricePerKmOfFleetType,
    ComputedMarketAvgPricePerKmOfFleetTypeFromJSON,
    ComputedMarketAvgPricePerKmOfFleetTypeFromJSONTyped,
    ComputedMarketAvgPricePerKmOfFleetTypeToJSON,
    MoPricePerKmWithOperationalProfile,
    MoPricePerKmWithOperationalProfileFromJSON,
    MoPricePerKmWithOperationalProfileFromJSONTyped,
    MoPricePerKmWithOperationalProfileToJSON,
    MonitoredObjectCostProfileOfFleetType,
    MonitoredObjectCostProfileOfFleetTypeFromJSON,
    MonitoredObjectCostProfileOfFleetTypeFromJSONTyped,
    MonitoredObjectCostProfileOfFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PricePerKmResult
 */
export interface PricePerKmResult {
    /**
     * 
     * @type {Array<ComputedMarketAvgPricePerKmOfFleetType>}
     * @memberof PricePerKmResult
     */
    marketAvgPricePerKmOfFleetTypes?: Array<ComputedMarketAvgPricePerKmOfFleetType>;
    /**
     * 
     * @type {Array<AvgPricePerKmOfFleetType>}
     * @memberof PricePerKmResult
     */
    defaultMarketAvgPricePerKmOfFleetTypes?: Array<AvgPricePerKmOfFleetType>;
    /**
     * 
     * @type {CompanyCostProfile}
     * @memberof PricePerKmResult
     */
    defaultMarketAvgFixedCostProfile?: CompanyCostProfile;
    /**
     * 
     * @type {Array<MonitoredObjectCostProfileOfFleetType>}
     * @memberof PricePerKmResult
     */
    defaultMarketAvgOperationalCostProfileOfFleetTypes?: Array<MonitoredObjectCostProfileOfFleetType>;
    /**
     * 
     * @type {string}
     * @memberof PricePerKmResult
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof PricePerKmResult
     */
    avgPricePerKm: number;
    /**
     * 
     * @type {Array<AvgPricePerKmOfFleetType>}
     * @memberof PricePerKmResult
     */
    avgPricePerKmOfFleetTypes?: Array<AvgPricePerKmOfFleetType>;
    /**
     * 
     * @type {CompanyCostProfile}
     * @memberof PricePerKmResult
     */
    fixedCostProfile?: CompanyCostProfile;
    /**
     * 
     * @type {Array<MoPricePerKmWithOperationalProfile>}
     * @memberof PricePerKmResult
     */
    mobjects: Array<MoPricePerKmWithOperationalProfile>;
}

export function PricePerKmResultFromJSON(json: any): PricePerKmResult {
    return PricePerKmResultFromJSONTyped(json, false);
}

export function PricePerKmResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricePerKmResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketAvgPricePerKmOfFleetTypes': !exists(json, 'market_avg_price_per_km_of_fleet_types') ? undefined : ((json['market_avg_price_per_km_of_fleet_types'] as Array<any>).map(ComputedMarketAvgPricePerKmOfFleetTypeFromJSON)),
        'defaultMarketAvgPricePerKmOfFleetTypes': !exists(json, 'default_market_avg_price_per_km_of_fleet_types') ? undefined : ((json['default_market_avg_price_per_km_of_fleet_types'] as Array<any>).map(AvgPricePerKmOfFleetTypeFromJSON)),
        'defaultMarketAvgFixedCostProfile': !exists(json, 'default_market_avg_fixed_cost_profile') ? undefined : CompanyCostProfileFromJSON(json['default_market_avg_fixed_cost_profile']),
        'defaultMarketAvgOperationalCostProfileOfFleetTypes': !exists(json, 'default_market_avg_operational_cost_profile_of_fleet_types') ? undefined : ((json['default_market_avg_operational_cost_profile_of_fleet_types'] as Array<any>).map(MonitoredObjectCostProfileOfFleetTypeFromJSON)),
        'currency': json['currency'],
        'avgPricePerKm': json['avg_price_per_km'],
        'avgPricePerKmOfFleetTypes': !exists(json, 'avg_price_per_km_of_fleet_types') ? undefined : ((json['avg_price_per_km_of_fleet_types'] as Array<any>).map(AvgPricePerKmOfFleetTypeFromJSON)),
        'fixedCostProfile': !exists(json, 'fixed_cost_profile') ? undefined : CompanyCostProfileFromJSON(json['fixed_cost_profile']),
        'mobjects': ((json['mobjects'] as Array<any>).map(MoPricePerKmWithOperationalProfileFromJSON)),
    };
}

export function PricePerKmResultToJSON(value?: PricePerKmResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'market_avg_price_per_km_of_fleet_types': value.marketAvgPricePerKmOfFleetTypes === undefined ? undefined : ((value.marketAvgPricePerKmOfFleetTypes as Array<any>).map(ComputedMarketAvgPricePerKmOfFleetTypeToJSON)),
        'default_market_avg_price_per_km_of_fleet_types': value.defaultMarketAvgPricePerKmOfFleetTypes === undefined ? undefined : ((value.defaultMarketAvgPricePerKmOfFleetTypes as Array<any>).map(AvgPricePerKmOfFleetTypeToJSON)),
        'default_market_avg_fixed_cost_profile': CompanyCostProfileToJSON(value.defaultMarketAvgFixedCostProfile),
        'default_market_avg_operational_cost_profile_of_fleet_types': value.defaultMarketAvgOperationalCostProfileOfFleetTypes === undefined ? undefined : ((value.defaultMarketAvgOperationalCostProfileOfFleetTypes as Array<any>).map(MonitoredObjectCostProfileOfFleetTypeToJSON)),
        'currency': value.currency,
        'avg_price_per_km': value.avgPricePerKm,
        'avg_price_per_km_of_fleet_types': value.avgPricePerKmOfFleetTypes === undefined ? undefined : ((value.avgPricePerKmOfFleetTypes as Array<any>).map(AvgPricePerKmOfFleetTypeToJSON)),
        'fixed_cost_profile': CompanyCostProfileToJSON(value.fixedCostProfile),
        'mobjects': ((value.mobjects as Array<any>).map(MoPricePerKmWithOperationalProfileToJSON)),
    };
}


