import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { message } from 'antd';
import { Role } from 'logic/auth';

import { LayoutContent } from 'common/components/Layout/Content';
import { RouteNames, withLogicContext, WithLogic } from 'App';

import Tabs from '../../components/SystemConnectionTabs';
import EWCustomerAccessTable from '../../components/EWCustomerAccessTable';
import GrantAccessForm, { GrantAccessFormValues } from '../../components/GrantAccessForm';
import { CustomerAccessCode } from 'logic/customer-access';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import moment from 'moment';
import { Confirm } from 'common/components';
import { getRegistrationNumber } from 'common/utils/registrationName';
import qa from 'qa-selectors';
import { MessageType } from 'common/components/Confirm';
import TableBar from 'common/components/TableBar';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

interface Props extends WithTranslation, WithLogic, RouteComponentProps {}

interface State {
    codes?: PaginatedResponse<CustomerAccessCode[]>;
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    loading: boolean;
    removeCustomerAccessLoading: boolean;
    removeConfirmModal: {
        isOpen: boolean;
        id?: string;
    };
}

const INITIAL_STATE: State = {
    loading: false,
    vehicles: [],
    removeCustomerAccessLoading: false,
    removeConfirmModal: {
        isOpen: false
    }
};

class CustomerAccessModule extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        this.props.logic
            .vehicles()
            .getMonitoredObjectFilters(false, false, [Role.LM_R])
            .then(vehicles => {
                this.setState({ vehicles });
            });

        this.props.logic.customerAccessLogic().onData.subscribe(codes => {
            this.setState({ codes });
        });

        this.props.logic.customerAccessLogic().onLoading.subscribe(loading => {
            this.setState({ loading });
        });

        this.props.logic.customerAccessLogic().loadData();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <LayoutContent
                    className="system-connections"
                    mainSizes={{ xs: 24, sm: 24, md: 18 }}
                    extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                    main={
                        <>
                            <header>
                                <TableBar heading={t('SystemConnections.title')} />
                                <Tabs
                                    active={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS}
                                    onChange={this._onTabChange}
                                    tabs={[
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerApi}>
                                                    {this.props.t('SystemConnections.customerApi')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API,
                                            roles: [Role.CA_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerAccess}>
                                                    {this.props.t('SystemConnections.ewCustomerAccess')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS,
                                            roles: [Role.CAC_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabOtherSystems}>
                                                    {this.props.t('SystemConnections.otherSystems')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS,
                                            roles: [Role.OAC_R, Role.PUESC]
                                        }
                                    ].filter(t =>
                                        this.props.logic
                                            .auth()
                                            .roles()
                                            .some(r => t.roles?.includes(r))
                                    )}
                                />
                            </header>
                            <main>
                                <EWCustomerAccessTable
                                    loading={this.state.loading}
                                    data={
                                        this.state.codes
                                            ? {
                                                  ...this.state.codes,
                                                  data:
                                                      this.state.codes?.data?.map(c => ({
                                                          access: `${moment(c.start).format('L LT')} - ${moment(
                                                              c.end
                                                          ).format('L LT')}`,
                                                          id: c.id,
                                                          vehicles: c.vehicles
                                                              .map(v =>
                                                                  getRegistrationNumber(
                                                                      !!this.state.vehicles.find(
                                                                          vv => vv.registrationNumber === v
                                                                      )?.disabledAt,
                                                                      v
                                                                  )
                                                              )
                                                              .join(', ')
                                                      })) ?? []
                                              }
                                            : undefined
                                    }
                                    onPaginationChange={this._onPaginationChange}
                                    onOpenLink={this._onOpenLink}
                                    onRemoveClick={this._onRemoveAccess}
                                />
                            </main>
                        </>
                    }
                    extra={[
                        <>
                            <TableBar heading={t('SystemConnections.actions')} />
                            <GrantAccessForm
                                vehicles={this.state.vehicles}
                                onSubmit={this._onSubmit}
                                onCancel={this._onCancel}
                                lang={this.props.logic.auth().user().lang}
                            />
                        </>
                    ]}
                />
                {this.state.removeConfirmModal.isOpen && (
                    <Confirm
                        danger
                        loading={this.state.removeCustomerAccessLoading}
                        header={t('SystemConnections.messages.deleteAccessHeader')}
                        message={t('SystemConnections.messages.deleteConfirm')}
                        type={MessageType.WARNING}
                        confirmLabel={this.props.t('common.delete')}
                        onCancel={this._onRemoveAccessCancel}
                        onConfirm={this._onAccessDeleteConfirm}
                    />
                )}
            </>
        );
    }

    private _onPaginationChange = (pagination: PaginationParams) => {
        this.props.logic.customerAccessLogic().loadData(pagination.limit, pagination.offset);
    };

    private _onRemoveAccess = (id: string) => {
        this.setState({
            removeConfirmModal: {
                isOpen: true,
                id
            }
        });
    };

    private _onRemoveAccessCancel = () => {
        this.setState({
            removeConfirmModal: {
                isOpen: false
            }
        });
    };

    private _onAccessDeleteConfirm = async () => {
        if (!this.state.removeConfirmModal.id) {
            console.error('No access id to be removed');
            return;
        }
        this.setState({
            removeCustomerAccessLoading: true
        });
        try {
            await this.props.logic.customerAccessLogic().deleteAccess(this.state.removeConfirmModal.id);
            this.setState({
                removeConfirmModal: {
                    isOpen: false
                }
            });
            message.success(this.props.t('SystemConnections.messages.deleteAccessSuccess'));
        } catch (err) {
            console.error(`Access deletion failed, err: ${err}`);
            message.error(this.props.t('SystemConnections.messages.deleteAccessError'));
        }
        this.setState({
            removeCustomerAccessLoading: false
        });
    };

    private _onSubmit = async (values: GrantAccessFormValues): Promise<boolean> => {
        try {
            await this.props.logic.customerAccessLogic().createAccess(values);
            message.success(this.props.t('SystemConnections.messages.successfullyCreated'));
            return true;
        } catch (err) {
            console.error(`Create access token failed, err: ${err}`);
            message.error(this.props.t('SystemConnections.message.failedToCreate'));
            return false;
        }
    };
    private _onCancel = (): void => {};

    private _onOpenLink = (id: string) => {
        const link = this.state.codes?.data.find(d => d.id === id);
        if (link?.code) {
            const uri = `${window.location.origin}/customer-access-preview?code=${link?.code}`;
            window.open(uri, '_blank');
        }
    };

    private _onTabChange = (value: string) => {
        this.props.history.push(value);
    };
}

export default withRouter(withTranslation()(withLogicContext(CustomerAccessModule)));
