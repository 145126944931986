/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyDiemsRules2
 */
export interface ReadOnlyDiemsRules2 {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRules2
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyDiemsRules2
     */
    ruleValue: object;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRules2
     */
    readonly deleted?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRules2
     */
    dayFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRules2
     */
    dayTo: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRules2
     */
    ruleName: ReadOnlyDiemsRules2RuleNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRules2
     */
    ruleClass: ReadOnlyDiemsRules2RuleClassEnum;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRules2
     */
    readonly lastModifiedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRules2
     */
    client: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRules2
     */
    user: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRules2
     */
    lastModifiedBy?: number | null;
}

export function ReadOnlyDiemsRules2FromJSON(json: any): ReadOnlyDiemsRules2 {
    return ReadOnlyDiemsRules2FromJSONTyped(json, false);
}

export function ReadOnlyDiemsRules2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyDiemsRules2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleValue': json['rule_value'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'dayFrom': (new Date(json['day_from'])),
        'dayTo': (new Date(json['day_to'])),
        'ruleName': json['rule_name'],
        'ruleClass': json['rule_class'],
        'lastModifiedAt': !exists(json, 'last_modified_at') ? undefined : (new Date(json['last_modified_at'])),
        'client': json['client'],
        'user': json['user'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
    };
}

export function ReadOnlyDiemsRules2ToJSON(value?: ReadOnlyDiemsRules2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rule_value': value.ruleValue,
        'day_from': (value.dayFrom.toISOString().substr(0,10)),
        'day_to': (value.dayTo.toISOString().substr(0,10)),
        'rule_name': value.ruleName,
        'rule_class': value.ruleClass,
        'client': value.client,
        'user': value.user,
        'last_modified_by': value.lastModifiedBy,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyDiemsRules2RuleNameEnum {
    DiemsRate = 'diems_rate'
}
/**
* @export
* @enum {string}
*/
export enum ReadOnlyDiemsRules2RuleClassEnum {
    User = 'user'
}


