import React from 'react';
import { SliderSingleProps } from 'antd/lib/slider';
import { Slider } from 'common/components/Form';
import { Field, FieldProps, getIn } from 'formik';

interface Props extends SliderSingleProps {
    name: string;
    qa?: string;
}
const SliderField = ({ name, qa, ...props }: Props) => {
    return (
        <Field name={name}>
            {({ field: { value }, form }: FieldProps) => {
                const handleChange = (value: number) => {
                    form.setFieldValue(name, value);
                };
                return (
                    <Slider
                        error={getIn(form.touched, name) && getIn(form.errors, name)}
                        value={value || []}
                        onChange={handleChange}
                        data-qa={qa}
                        {...props}
                    />
                );
            }}
        </Field>
    );
};

export default SliderField;
