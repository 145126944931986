import React from 'react';
import { Button, Col, Row } from 'antd';
import { createRef, useEffect, useState } from 'react';
import { iconClose } from 'resources/images/common';
import ButtonHelper from '../ButtonHelper';
import EnhancedModal, { EnhancedModalProps } from '../Modal';
import cn from 'classnames';
import { FormikProps, FormikValues } from 'formik';

export interface ModalFormProps extends EnhancedModalProps {
    buttonsContainerClassName?: string;
    onHelperClick?: () => void;
}

export function formikValuesIsChanged(form: FormikProps<FormikValues>, name: string, value: any): boolean {
    for (let fieldName in form.values) {
        const data = form.getFieldMeta(fieldName);
        if (name !== fieldName) {
            if (!data.error && String(data.value) !== String(data.initialValue)) {
                return true;
            }
        } else {
            if (String(value) !== String(data.initialValue)) {
                return true;
            }
        }
    }
    return false;
}

const ModalForm: React.FC<React.PropsWithChildren<ModalFormProps>> = ({
    onHelperClick,
    buttonsContainerClassName,
    ...props
}) => {
    const _refContent = createRef<HTMLDivElement>();
    const [inicialized, setInicialized] = useState(false);

    useEffect(() => {
        if ((props.visible ?? true) && !inicialized) {
            setTimeout(() => {
                _initButtons(0);
            }, 100);
        }
    });

    const handleButtonCloseClick = (e: React.MouseEvent<HTMLElement>) => {
        props.onCancel?.(e);
    };

    const handleAfterClose = () => {
        setInicialized(false);
        props.afterClose?.();
    };

    const _initButtons = (iteration: number) => {
        if (_refContent.current) {
            const buttonsGroup = _refContent.current.getElementsByClassName(
                buttonsContainerClassName ?? 'buttons-group'
            );
            if (buttonsGroup.length > 0) {
                (buttonsGroup[0] as HTMLDivElement).classList.add('modal-form-footer');
            }
            setInicialized(true);
        } else {
            if (!inicialized && iteration < 20) {
                setTimeout(() => {
                    _initButtons(iteration + 1);
                }, 100);
            }
        }
    };

    return (
        <EnhancedModal
            {...props}
            className={cn(`modal-form ${props.className}`, {
                'modal-form-inicialized': inicialized
            })}
            closable={false}
            footer={null}
            centered={true}
            destroyOnClose
            afterClose={handleAfterClose}
        >
            <Row align="middle" className="modal-form-header">
                <Col>
                    <Row align="middle" className="modal-form-header-title">
                        {props.title}
                    </Row>
                </Col>
                <Row className="modal-form-header-buttons">
                    {onHelperClick && (
                        <Col className="modal-form-header-button">
                            <ButtonHelper onClick={onHelperClick} />
                        </Col>
                    )}
                    {(props.closable ?? true) && (
                        <Col className="modal-form-header-button">
                            <Button
                                size="small"
                                icon={<img src={iconClose} alt="icon-close" />}
                                className="modal-form-header-close-button"
                                onClick={handleButtonCloseClick}
                            />
                        </Col>
                    )}
                </Row>
            </Row>
            <Row ref={_refContent}>{props.children}</Row>
        </EnhancedModal>
    );
};

export default ModalForm;
