/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PointInfo,
    PointInfoFromJSON,
    PointInfoToJSON,
} from '../models';

export interface GetPointInfoV1GraphApiPointInfoGetRequest {
    activityIntervalId: string;
    lat: number;
    lon: number;
    monitoredObjectId: string;
}

export interface WarmV1GraphApiWarmGetRequest {
    activityIntervalIds: Array<string>;
}

/**
 * no description
 */
export class PointInfoApi extends runtime.BaseAPI {

    /**
     * Get Point Info
     */
    async getPointInfoV1GraphApiPointInfoGetRaw(requestParameters: GetPointInfoV1GraphApiPointInfoGetRequest): Promise<runtime.ApiResponse<PointInfo>> {
        if (requestParameters.activityIntervalId === null || requestParameters.activityIntervalId === undefined) {
            throw new runtime.RequiredError('activityIntervalId','Required parameter requestParameters.activityIntervalId was null or undefined when calling getPointInfoV1GraphApiPointInfoGet.');
        }

        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling getPointInfoV1GraphApiPointInfoGet.');
        }

        if (requestParameters.lon === null || requestParameters.lon === undefined) {
            throw new runtime.RequiredError('lon','Required parameter requestParameters.lon was null or undefined when calling getPointInfoV1GraphApiPointInfoGet.');
        }

        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling getPointInfoV1GraphApiPointInfoGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.activityIntervalId !== undefined) {
            queryParameters['activity_interval_id'] = requestParameters.activityIntervalId;
        }

        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }

        if (requestParameters.lon !== undefined) {
            queryParameters['lon'] = requestParameters.lon;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/graph-api/point-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PointInfoFromJSON(jsonValue));
    }

    /**
     * Get Point Info
     */
    async getPointInfoV1GraphApiPointInfoGet(requestParameters: GetPointInfoV1GraphApiPointInfoGetRequest): Promise<PointInfo> {
        const response = await this.getPointInfoV1GraphApiPointInfoGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Warm
     */
    async warmV1GraphApiWarmGetRaw(requestParameters: WarmV1GraphApiWarmGetRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.activityIntervalIds === null || requestParameters.activityIntervalIds === undefined) {
            throw new runtime.RequiredError('activityIntervalIds','Required parameter requestParameters.activityIntervalIds was null or undefined when calling warmV1GraphApiWarmGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.activityIntervalIds) {
            queryParameters['activity_interval_ids'] = requestParameters.activityIntervalIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/graph-api/warm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Warm
     */
    async warmV1GraphApiWarmGet(requestParameters: WarmV1GraphApiWarmGetRequest): Promise<object> {
        const response = await this.warmV1GraphApiWarmGetRaw(requestParameters);
        return await response.value();
    }

}
