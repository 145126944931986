/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Count,
    CountFromJSON,
    CountToJSON,
    GDPRDryRun,
    GDPRDryRunFromJSON,
    GDPRDryRunToJSON,
    InlineResponse20045,
    InlineResponse20045FromJSON,
    InlineResponse20045ToJSON,
    ReadOnlyUserToken,
    ReadOnlyUserTokenFromJSON,
    ReadOnlyUserTokenToJSON,
    UserToken,
    UserTokenFromJSON,
    UserTokenToJSON,
    UserTokenPairUser,
    UserTokenPairUserFromJSON,
    UserTokenPairUserToJSON,
    UserTokenPatch,
    UserTokenPatchFromJSON,
    UserTokenPatchToJSON,
    WriteOnlyUserToken,
    WriteOnlyUserTokenFromJSON,
    WriteOnlyUserTokenToJSON,
} from '../models';

export interface UserTokenCreateRequest {
    data: WriteOnlyUserToken;
}

export interface UserTokenCreateAndPairRequest {
    data: WriteOnlyUserToken;
    onConflict?: string;
}

export interface UserTokenCreateAndPairUserRequest {
    data: UserTokenPairUser;
}

export interface UserTokenDeleteRequest {
    id: number;
}

export interface UserTokenListRequest {
    token?: string;
    userClient?: string;
    userActive?: number;
    userDeleted?: number;
    tokenType?: string;
    client?: string;
    userIn?: string;
    onlyFree?: boolean;
    onlyActive?: boolean;
    limit?: number;
    offset?: number;
}

export interface UserTokenPairUserRequest {
    id: number;
    userId: string;
}

export interface UserTokenPartialUpdateRequest {
    id: number;
    data: UserTokenPatch;
}

export interface UserTokenReadRequest {
    id: number;
}

export interface UserTokenTachoCardAnonymizeRequest {
    data: GDPRDryRun;
}

export interface UserTokenTachoCardCountRequest {
    token?: string;
    userClient?: string;
    userActive?: number;
    userDeleted?: number;
    tokenType?: string;
    client?: string;
    userIn?: string;
    onlyFree?: boolean;
    onlyActive?: boolean;
    limit?: number;
    offset?: number;
}

export interface UserTokenTachoCardGetRequest {
    token?: string;
    userClient?: string;
    userActive?: number;
    userDeleted?: number;
    tokenType?: string;
    client?: string;
    userIn?: string;
    onlyFree?: boolean;
    onlyActive?: boolean;
}

export interface UserTokenUnpairUserRequest {
    id: number;
    userId: string;
}

export interface UserTokenUserTokenMiniListRequest {
    token?: string;
    userClient?: string;
    userActive?: number;
    userDeleted?: number;
    tokenType?: string;
    client?: string;
    userIn?: string;
    onlyFree?: boolean;
    onlyActive?: boolean;
}

/**
 * no description
 */
export class UserTokenApi extends runtime.BaseAPI {

    /**
     */
    async userTokenCreateRaw(requestParameters: UserTokenCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserToken>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserTokenToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenCreate(requestParameters: UserTokenCreateRequest): Promise<ReadOnlyUserToken> {
        const response = await this.userTokenCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenCreateAndPairRaw(requestParameters: UserTokenCreateAndPairRequest): Promise<runtime.ApiResponse<ReadOnlyUserToken>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenCreateAndPair.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onConflict !== undefined) {
            queryParameters['on_conflict'] = requestParameters.onConflict;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/create_full/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserTokenToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenCreateAndPair(requestParameters: UserTokenCreateAndPairRequest): Promise<ReadOnlyUserToken> {
        const response = await this.userTokenCreateAndPairRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenCreateAndPairUserRaw(requestParameters: UserTokenCreateAndPairUserRequest): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenCreateAndPairUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/create-and-pair-user/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserTokenPairUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenCreateAndPairUser(requestParameters: UserTokenCreateAndPairUserRequest): Promise<UserToken> {
        const response = await this.userTokenCreateAndPairUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenDeleteRaw(requestParameters: UserTokenDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyUserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenDelete(requestParameters: UserTokenDeleteRequest): Promise<ReadOnlyUserToken> {
        const response = await this.userTokenDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenListRaw(requestParameters: UserTokenListRequest): Promise<runtime.ApiResponse<InlineResponse20045>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        if (requestParameters.userActive !== undefined) {
            queryParameters['user__active'] = requestParameters.userActive;
        }

        if (requestParameters.userDeleted !== undefined) {
            queryParameters['user__deleted'] = requestParameters.userDeleted;
        }

        if (requestParameters.tokenType !== undefined) {
            queryParameters['token_type'] = requestParameters.tokenType;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.onlyFree !== undefined) {
            queryParameters['only_free'] = requestParameters.onlyFree;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['only_active'] = requestParameters.onlyActive;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20045FromJSON(jsonValue));
    }

    /**
     */
    async userTokenList(requestParameters: UserTokenListRequest): Promise<InlineResponse20045> {
        const response = await this.userTokenListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenPairUserRaw(requestParameters: UserTokenPairUserRequest): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenPairUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userTokenPairUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/pair-user/{user_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenPairUser(requestParameters: UserTokenPairUserRequest): Promise<UserToken> {
        const response = await this.userTokenPairUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenPartialUpdateRaw(requestParameters: UserTokenPartialUpdateRequest): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserTokenPatchToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenPartialUpdate(requestParameters: UserTokenPartialUpdateRequest): Promise<UserToken> {
        const response = await this.userTokenPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenReadRaw(requestParameters: UserTokenReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenRead(requestParameters: UserTokenReadRequest): Promise<ReadOnlyUserToken> {
        const response = await this.userTokenReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenTachoCardAnonymizeRaw(requestParameters: UserTokenTachoCardAnonymizeRequest): Promise<runtime.ApiResponse<Count>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenTachoCardAnonymize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/GDPR-anonymize/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GDPRDryRunToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     */
    async userTokenTachoCardAnonymize(requestParameters: UserTokenTachoCardAnonymizeRequest): Promise<Count> {
        const response = await this.userTokenTachoCardAnonymizeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenTachoCardCountRaw(requestParameters: UserTokenTachoCardCountRequest): Promise<runtime.ApiResponse<Count>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        if (requestParameters.userActive !== undefined) {
            queryParameters['user__active'] = requestParameters.userActive;
        }

        if (requestParameters.userDeleted !== undefined) {
            queryParameters['user__deleted'] = requestParameters.userDeleted;
        }

        if (requestParameters.tokenType !== undefined) {
            queryParameters['token_type'] = requestParameters.tokenType;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.onlyFree !== undefined) {
            queryParameters['only_free'] = requestParameters.onlyFree;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['only_active'] = requestParameters.onlyActive;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/GDPR-count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     */
    async userTokenTachoCardCount(requestParameters: UserTokenTachoCardCountRequest): Promise<Count> {
        const response = await this.userTokenTachoCardCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenTachoCardGetRaw(requestParameters: UserTokenTachoCardGetRequest): Promise<runtime.ApiResponse<Array<ReadOnlyUserToken>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        if (requestParameters.userActive !== undefined) {
            queryParameters['user__active'] = requestParameters.userActive;
        }

        if (requestParameters.userDeleted !== undefined) {
            queryParameters['user__deleted'] = requestParameters.userDeleted;
        }

        if (requestParameters.tokenType !== undefined) {
            queryParameters['token_type'] = requestParameters.tokenType;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.onlyFree !== undefined) {
            queryParameters['only_free'] = requestParameters.onlyFree;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['only_active'] = requestParameters.onlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/GDPR-get/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyUserTokenFromJSON));
    }

    /**
     */
    async userTokenTachoCardGet(requestParameters: UserTokenTachoCardGetRequest): Promise<Array<ReadOnlyUserToken>> {
        const response = await this.userTokenTachoCardGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenUnpairUserRaw(requestParameters: UserTokenUnpairUserRequest): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenUnpairUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userTokenUnpairUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/unpair-user/{user_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     */
    async userTokenUnpairUser(requestParameters: UserTokenUnpairUserRequest): Promise<UserToken> {
        const response = await this.userTokenUnpairUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenUserTokenMiniListRaw(requestParameters: UserTokenUserTokenMiniListRequest): Promise<runtime.ApiResponse<Array<UserToken>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        if (requestParameters.userActive !== undefined) {
            queryParameters['user__active'] = requestParameters.userActive;
        }

        if (requestParameters.userDeleted !== undefined) {
            queryParameters['user__deleted'] = requestParameters.userDeleted;
        }

        if (requestParameters.tokenType !== undefined) {
            queryParameters['token_type'] = requestParameters.tokenType;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.onlyFree !== undefined) {
            queryParameters['only_free'] = requestParameters.onlyFree;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['only_active'] = requestParameters.onlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/user-token-mini/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserTokenFromJSON));
    }

    /**
     */
    async userTokenUserTokenMiniList(requestParameters: UserTokenUserTokenMiniListRequest): Promise<Array<UserToken>> {
        const response = await this.userTokenUserTokenMiniListRaw(requestParameters);
        return await response.value();
    }

}
