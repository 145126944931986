/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyUserHasMonitoredObjectGroup
 */
export interface ReadOnlyUserHasMonitoredObjectGroup {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserHasMonitoredObjectGroup
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserHasMonitoredObjectGroup
     */
    monitoredObjectGroup: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserHasMonitoredObjectGroup
     */
    user: number;
}

export function ReadOnlyUserHasMonitoredObjectGroupFromJSON(json: any): ReadOnlyUserHasMonitoredObjectGroup {
    return ReadOnlyUserHasMonitoredObjectGroupFromJSONTyped(json, false);
}

export function ReadOnlyUserHasMonitoredObjectGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserHasMonitoredObjectGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'monitoredObjectGroup': json['monitored_object_group'],
        'user': json['user'],
    };
}

export function ReadOnlyUserHasMonitoredObjectGroupToJSON(value?: ReadOnlyUserHasMonitoredObjectGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'monitored_object_group': value.monitoredObjectGroup,
        'user': value.user,
    };
}


