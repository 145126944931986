import { useEffect, useRef } from 'react';

export function useInterval<T extends Function>(callback: T, delay: number) {
    const savedCallback = useRef<T>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return;
    }, [delay]);
}
