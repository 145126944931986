import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';
import { Field, FieldProps, getIn } from 'formik';
import { searched } from 'common/utils/search';
import { ReactNode } from 'react';

import { Select } from '../../components/Form';
import { SelectOption } from '../../components/Form/Select';

interface Props {
    name: string;
    className?: string;
    options?: SelectOption[];
    placeholder?: ReactNode;
    label?: ReactNode;
    disabled?: boolean;
    notFoundContent?: ReactNode;
    priorityOptions?: string[];
    mode?: SelectProps<SelectOption>['mode'];
    qa?: string;
    children?: React.ReactNode;
    innerRef?: React.Ref<RefSelectProps> | undefined;
    onChange?: (value: SelectValue) => void;
}

export default function SelectField({
    name,
    className,
    options,
    disabled = false,
    notFoundContent,
    qa,
    onChange,
    innerRef,
    ...bag
}: Props & SelectProps<SelectValue>) {
    return (
        <Field name={name}>
            {({ field: { value, ...fieldBag }, form }: FieldProps<any>) => {
                function handleChange(value: SelectValue) {
                    form.setFieldValue(name, value);
                    onChange?.(value);
                }

                function handleFilterOption(input: string, option: any) {
                    return searched(input.toLowerCase(), option.props.children.toString());
                }

                return (
                    <Select
                        innerRef={innerRef}
                        value={value ? value : undefined}
                        className={className}
                        options={options}
                        error={getIn(form.touched, name) && getIn(form.errors, name)}
                        disabled={disabled}
                        filterOption={handleFilterOption}
                        notFoundContent={notFoundContent}
                        qa={qa}
                        {...fieldBag}
                        {...bag}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
}
