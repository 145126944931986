import { Component, MouseEvent, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import cn from 'classnames';
import qa from 'qa-selectors';
import numeral from 'numeral';
import moment from 'moment';

import { KG, KM, L } from 'domain-constants';
import { Button, Table, Tooltip, AlarmsInfoIcon, Popover, AETRDataDetail } from 'common/components';
import { TrackingModel, MonitoredObjectTypeName, VehicleDelayedFilterCode, NoGPSStatus } from 'common/model/tracking';
import ProgressBar from 'common/components/ProgressBar';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { SettingsRerenderer } from 'common/utils/components/SettingsRerenderer';
import { AddressStructured, EFuelType } from 'generated/graphql';
import { WithLogic, withLogicContext } from 'App';
import { toAddress } from 'common/utils/address';
import { Role } from 'logic/auth';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';
import { pxWidthText } from 'common/utils/textUtils';
import * as aetrIcons from 'resources/images/aetr';
import * as commonIcons from 'resources/images/common';
import { PuescStatus, PuescStatusResponse } from 'generated/backend-api';
import { trailersTrackingModelRN } from 'common/utils/vehicleState';
import { ExternalDeviceType } from 'generated/backend-api-live';

const m2km = (meters: number) => Math.round(meters / 100) / 10;

export enum TachoToIcon {
    driving = 'truck',
    resting = 'bed',
    working = 'wrench'
}

export enum StatusToIcon {
    'stop',
    'play',
    'pause'
}

const notExpandedCols = ['checked', 'alarm', 'RN', 'location', 'puescStatus', 'destination', 'status', 'actual'];

interface Props extends WithTranslation, WithLogic {
    data: TrackingModel[];
    loading?: boolean;
    expanded?: boolean;
    hasDestination: boolean;
    roles: Role[];
    fleetType: ReadOnlyClientFleetTypeEnum;
    onColumnClick?: (text: string) => void;
    onRowClick?: (id: string) => void;
    onRowCheck?: (id: string, checked: boolean) => void;
    onColumnActualClick?: (id: string) => void;
    onAddDestination?: (id: string) => void;
    onAlarmDismiss?: (ids: string[]) => void;
    onRowBarHover?: (row: TrackingModel, position: { x: number; y: number }) => void;
    onRowBarLeave?: () => void;
}

class TrackingTable extends Component<Props, {}> {
    private _ref = createRef<HTMLTableRowElement>();
    private scroll: boolean = true;

    componentDidUpdate() {
        if (this.scroll && this._ref.current) {
            this._ref.current.scrollIntoView({
                block: 'nearest',
                behavior: 'smooth'
            });
            this.scroll = false;
        }
    }

    puescErrorText(puescStatus: PuescStatusResponse): string[] {
        let errors: string[] = [];
        if (puescStatus.puescSentGeoStale) {
            errors = [...errors, this.props.t('TrackingTable.puescErrors.error01')];
        }
        if (puescStatus.puescSentGeoError) {
            errors = [...errors, this.props.t('TrackingTable.puescErrors.error02')];
        }
        if (puescStatus.unavailableGps) {
            errors = [...errors, this.props.t('TrackingTable.puescErrors.error03')];
        }
        if (puescStatus.monitoringDeviceStale) {
            errors = [...errors, this.props.t('TrackingTable.puescErrors.error04')];
        }
        return errors;
    }

    render() {
        const { expanded, loading } = this.props;
        return (
            <div className="tm-tracking-table">
                <Table<TrackingModel>
                    scroll={{
                        y: `calc(100vh - ${expanded ? '197px' : '131px'})`,
                        x: expanded ? this._calculateScroll() : 0
                    }}
                    showHeader={expanded}
                    loading={loading}
                    dataSource={this.props.data.map(e => ({ ...e, key: e.id }))}
                    columns={this._createColumns()
                        .filter(e => (expanded ? e : notExpandedCols.includes(String(e.dataIndex))))
                        .filter(e =>
                            e.dataIndex === 'alarm' && this.props.data.filter(d => d.alarms.length > 0).length === 0
                                ? false
                                : e
                        )}
                    onRow={record => ({
                        onClick: () => this.props.onRowClick?.(record.id),
                        ref: record.selected ? this._ref : null,
                        className: cn({
                            'tm-tracking-table-row-no-gps': !record.location,
                            'tm-tracking-table-row-invalid': record.invalid,
                            'tm-tracking-table-row-selected': record.selected
                        })
                    })}
                />
            </div>
        );
    }

    private _calculateScroll = () => {
        let baseWidth = 905;
        baseWidth += this.props.hasDestination ? 440 : 120;
        return baseWidth;
    };

    private _onAddDestination = (id: string, invalid: boolean, location: string, e: MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
        if (!invalid && location) {
            this.props.onAddDestination?.(id);
        }
    };

    private _onColumnActualClick = (id: string, invalid: boolean, e: MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
        if (!invalid) {
            this.props.onColumnActualClick?.(id);
        }
    };

    private _onCheckboxChange = (id: string, checked: boolean, e: MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
        this.props.onRowCheck?.(id, !checked);
    };

    private _createColumns = () => {
        const { t, expanded } = this.props;
        return [
            {
                title: undefined,
                dataIndex: 'checked',
                key: 'checked',
                onCell: record => {
                    return {
                        onClick: event => {
                            this._onCheckboxChange(record.id, record.checked, event);
                        }
                    };
                },
                width: 45,
                render: (_, record) => (
                    <img
                        alt="checkbox"
                        className={cn('tm-tracking-table-checker pointer', {
                            checked: record.checked && record.location
                        })}
                        style={{ opacity: record.checked && record.location ? 1 : 0.1 }}
                        src="/img-svg/checked-white.svg"
                        data-qa={
                            record.checked && record.location
                                ? qa.trackingTable.imgChecked
                                : qa.trackingTable.imgNotChecked
                        }
                    />
                )
            },
            {
                title: undefined,
                dataIndex: 'alarm',
                key: 'alarm',
                width: 40,
                align: 'center',
                render: (_, record) =>
                    record.alarms.length > 0 && (
                        <AlarmsInfoIcon alarms={record.alarms} onAlarmDismiss={this.props.onAlarmDismiss} />
                    )
            },
            {
                title: t(`TrackingTable.rn`),
                dataIndex: 'RN',
                key: 'RN',
                width: expanded ? 140 : 100,
                render: (rn, row) => {
                    const trailer = trailersTrackingModelRN(
                        [ExternalDeviceType.TrailerId, ExternalDeviceType.TrailerManual],
                        row
                    );
                    return (
                        <Tooltip
                            placement="bottomLeft"
                            mouseLeaveDelay={0}
                            overlayClassName="ant-tooltip-offset-left"
                            title={`${row.RN}\n${trailer?.join(',')}`}
                        >
                            <div className="tracking-table-rn pointer">
                                <div>
                                    <strong data-qa={qa.trackingTable.fieldRn}>{row.RN}</strong>
                                </div>
                                {expanded && (
                                    <span
                                        className="tracking-table-trailer t-tiny"
                                        data-qa={qa.trackingTable.fieldTrailers}
                                    >
                                        {trailer?.join(',')}
                                    </span>
                                )}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: t(`TrackingTable.location`),
                className: 'tm-tracking-table-location',
                dataIndex: 'location',
                key: 'location',
                width: expanded ? 270 : this.props.data.filter(d => d.alarms.length > 0).length ? 180 : 200,
                render: (location, record) => (
                    <>
                        {record.noGPSStatus === NoGPSStatus.SwitzerlandUnavailable ? (
                            <span className="tracking-table-no-gps">
                                CH, {t('TrackingTable.noGpsSwitzerlandLocation')}
                            </span>
                        ) : location ? (
                            <SettingsRerenderer lang addressIdentification>
                                <Tooltip
                                    placement="bottomLeft"
                                    mouseLeaveDelay={0}
                                    overlayClassName="ant-tooltip-offset-left"
                                    title={this._getLocationTitle(location, record.addressStructured)}
                                >
                                    <AddressFormatter
                                        className="pointer"
                                        defaultAddress={location}
                                        addressStructured={record.addressStructured}
                                        qa={qa.trackingTable.fieldLocation}
                                    />
                                </Tooltip>
                            </SettingsRerenderer>
                        ) : (
                            <>{t('TrackingTable.waitingForInstall')}</>
                        )}
                    </>
                )
            },
            this.props.fleetType !== ReadOnlyClientFleetTypeEnum.LightVehicles &&
                !this.props.data.every(t => t.monitoredObjectType?.name === MonitoredObjectTypeName.LIGHT_VEHICLE) && {
                    title: '',
                    dataIndex: 'puescStatus',
                    key: 'puescStatus',
                    width: 35,
                    render: (value, record) =>
                        record.noGPSStatus === NoGPSStatus.SwitzerlandUnavailable ? (
                            <Tooltip
                                placement="top"
                                mouseLeaveDelay={0}
                                title={this.props.t('TrackingTable.noGpsSwitzerland')}
                            >
                                <img width="24" height="24" src={commonIcons.noGps} alt="no-gps" />
                            </Tooltip>
                        ) : value && value?.status !== PuescStatus.NoTransport ? (
                            <Tooltip
                                placement="bottomLeft"
                                mouseLeaveDelay={0}
                                title={
                                    value === PuescStatus.Healthy ? (
                                        <div key="puesc-ok">{this.props.t('TrackingTable.puescErrors.ok')}</div>
                                    ) : value === PuescStatus.Unknown ? (
                                        <div key="puesc-unknown">
                                            {this.props.t('TrackingTable.puescErrors.unknown')}
                                        </div>
                                    ) : (
                                        this.puescErrorText(value).map((error, index) => <div key={index}>{error}</div>)
                                    )
                                }
                            >
                                {value.status === PuescStatus.Healthy ? (
                                    <img src={commonIcons.puesc} className="puesc-icon" alt="Puesc ok" />
                                ) : (
                                    <img src={commonIcons.puescWarning} alt="Puesc warning" />
                                )}
                            </Tooltip>
                        ) : (
                            <img src={commonIcons.arrowRight} alt="to" />
                        )
                },
            this.props.fleetType !== ReadOnlyClientFleetTypeEnum.LightVehicles &&
                !this.props.data.every(t => t.monitoredObjectType?.name === MonitoredObjectTypeName.LIGHT_VEHICLE) && {
                    title: t(`TrackingTable.destination`),
                    className: 'tm-tracking-table-destination',
                    dataIndex: 'destination',
                    key: 'destination',
                    width: expanded
                        ? 270
                        : !this.props.hasDestination
                        ? pxWidthText(this.props.t('TrackingTable.addDestination'))
                        : this.props.data.filter(d => d.alarms.length).length
                        ? 180
                        : 200,
                    render: (destination, record) =>
                        record.monitoredObjectType?.name !== MonitoredObjectTypeName.LIGHT_VEHICLE &&
                        record.location ? (
                            destination ? (
                                <SettingsRerenderer lang addressIdentification>
                                    <Tooltip
                                        placement="bottomLeft"
                                        mouseLeaveDelay={0}
                                        overlayClassName="ant-tooltip-offset-left"
                                        title={this._getLocationTitle(destination, record.destinationAddressStructured)}
                                    >
                                        <AddressFormatter
                                            className="pointer"
                                            defaultAddress={destination}
                                            addressStructured={record.destinationAddressStructured}
                                            qa={qa.trackingTable.fieldDestination}
                                        />
                                    </Tooltip>
                                </SettingsRerenderer>
                            ) : (
                                <Button
                                    qa={qa.trackingTable.addDestinationButton}
                                    size="small"
                                    type="primary"
                                    disabled={
                                        !this.props.roles.includes(Role.PLN_R) || record.invalid || !record.location
                                    }
                                    onClick={this._onAddDestination.bind(
                                        undefined,
                                        record.id,
                                        record.invalid,
                                        record.location
                                    )}
                                >
                                    {t('TrackingTable.addDestination')}
                                </Button>
                            )
                        ) : (
                            ''
                        )
                },
            {
                title: t(`TrackingTable.status`),
                dataIndex: 'status',
                key: 'status',
                width: expanded ? 100 : 30,
                align: 'center',
                render: (_, record) =>
                    record.invalid ? (
                        <Tooltip
                            overlay={
                                <>
                                    {t(`TrackingTable.lastData`)} {moment(record?.gpsDataTime).format('L LT')}
                                </>
                            }
                        >
                            <i className="fa fa-warning" />
                        </Tooltip>
                    ) : record.location ? (
                        <i
                            data-qa={`fieldStatus_${StatusToIcon[record.status]}`}
                            className={cn('fa', `fa-${StatusToIcon[record.status]}`)}
                        />
                    ) : null
            },
            [Role.ETA_R].some(r => this.props.roles.includes(r)) &&
                this.props.hasDestination && {
                    title: t(`TrackingTable.eta`),
                    dataIndex: 'ETA',
                    align: 'center',
                    key: 'eta',
                    width: 100,
                    render: (_, record) =>
                        record.ETA ? (
                            <span
                                className={cn({
                                    't-text-warning':
                                        moment.duration(moment(record.ETA).diff(moment(record.RTA))).asMinutes() <=
                                            30 &&
                                        moment.duration(moment(record.ETA).diff(moment(record.RTA))).asMinutes() > 0,
                                    't-text-danger':
                                        moment.duration(moment(record.ETA).diff(moment(record.RTA))).asMinutes() > 30,
                                    't-text-success':
                                        moment.duration(moment(record.ETA).diff(moment(record.RTA))).asMinutes() <= 0
                                })}
                            >
                                <strong data-qa={qa.trackingTable.fieldEtaTime}>
                                    {moment(record.ETA).format('HH:mm')}
                                </strong>{' '}
                                {moment(record.ETA).isSame(moment(), 'day') ? (
                                    ''
                                ) : (
                                    <span data-qa={qa.trackingTable.fieldEtaDate}>
                                        {moment(record.ETA).format('MM.DD')}{' '}
                                    </span>
                                )}
                            </span>
                        ) : (
                            '-'
                        )
                },
            this.props.roles.includes(Role.ETA_R) &&
                this.props.hasDestination && {
                    title: t(`TrackingTable.rta`),
                    dataIndex: 'RTA',
                    align: 'center',
                    key: 'rta',
                    width: 100,
                    render: (_, record) =>
                        record.RTA ? (
                            <>
                                <strong data-qa={qa.trackingTable.fieldRtaTime}>
                                    {moment(record.RTA).format('HH:mm')}
                                </strong>{' '}
                                {moment(record.RTA).isSame(moment(), 'day') ? (
                                    ''
                                ) : (
                                    <span data-qa={qa.trackingTable.fieldRtaDate}>
                                        {moment(record.RTA).format('MM.DD')}
                                    </span>
                                )}
                            </>
                        ) : (
                            '-'
                        )
                },
            {
                title: t(`TrackingTable.driver`),
                dataIndex: 'driverName',
                width: 160,
                align: 'center',
                key: 'driverName',
                render: value => <span data-qa={qa.trackingTable.fieldDriver}>{value}</span>
            },
            this.props.roles.includes(Role.DRE_R) && {
                title: t(`TrackingTable.tacho`),
                dataIndex: 'tacho',
                width: 100,
                align: 'center',
                key: 'tacho',
                render: (tacho, record) =>
                    tacho && record.monitoredObjectType?.name !== MonitoredObjectTypeName.LIGHT_VEHICLE ? (
                        <img src={aetrIcons[tacho]} data-qa={`fieldStatus_${tacho}`} alt={t(`AetrTable.${tacho}`)} />
                    ) : (
                        '-'
                    )
            },
            this.props.roles.includes(Role.DRE_R) && {
                title: t(`TrackingTable.power`),
                dataIndex: 'power',
                key: 'power',
                width: 100,
                render: (_, record) => {
                    const dailyUtilizationMaxHours = record.dailyUtilizationMaxHours ?? 0;
                    const dailyUtilizationMaxSeconds = dailyUtilizationMaxHours * 3600;
                    const dailyDriveMaxHours = record.dailyDriveMaxHours ?? 0;
                    const dailyDriveMaxSeconds = dailyDriveMaxHours * 3600;
                    const dailyUtilizationLeft: number = record.dailyUtilizationLeft ?? 0;

                    const driving: number = Math.max(record.drivingDuration ?? 0, 0);
                    const drivingLeft: number = dailyDriveMaxSeconds >= driving ? dailyDriveMaxSeconds - driving : 0;

                    return (
                        record.monitoredObjectType?.name !== MonitoredObjectTypeName.LIGHT_VEHICLE && (
                            <Popover
                                content={
                                    <AETRDataDetail
                                        drivingDuration={record.drivingDuration}
                                        restingDuration={record.restingDuration}
                                        workingDuration={record.workingDuration}
                                        drivingLeft={drivingLeft}
                                        dailyUtilizationLeft={dailyUtilizationLeft}
                                        dailyUtilizationMaxHours={dailyUtilizationMaxHours}
                                    />
                                }
                                placement="topRight"
                            >
                                <div data-qa={qa.trackingTable.sectionAetr}>
                                    <ProgressBar
                                        first={record.drivingDuration}
                                        second={record.workingDuration}
                                        third={record.restingDuration}
                                        fourth={dailyUtilizationLeft}
                                        maxWidth={dailyUtilizationMaxSeconds}
                                    />
                                </div>
                            </Popover>
                        )
                    );
                }
            },
            {
                title: t(`TrackingTable.odo`),
                dataIndex: 'odo',
                key: 'odo',
                width: 110,
                align: 'center',
                render: (_, row) =>
                    row.ODO && !row.invalid && row.location ? (
                        <span data-qa={qa.trackingTable.fieldOdo}>
                            {numeral(m2km(Number(row.ODO))).format('0,0')} {KM}
                        </span>
                    ) : (
                        '-'
                    )
            },
            {
                title: t(`TrackingTable.tank`),
                dataIndex: 'tank',
                width: 80,
                align: 'center',
                key: 'tank',
                render: (_, row) => (
                    <span data-qa={qa.trackingTable.fieldTank}>
                        {row.monitoredObjectFuelType !== EFuelType.Electro
                            ? `${numeral(row.tank).format('0,0')} ${
                                  row.monitoredObjectFuelType === EFuelType.LngCng ? KG : L
                              }`
                            : ''}
                    </span>
                )
            },
            this.props.fleetType !== ReadOnlyClientFleetTypeEnum.LightVehicles &&
                this.props.hasDestination && {
                    title: t(`TrackingTable.actual`),
                    key: 'actual',
                    dataIndex: 'actual',
                    align: 'center',
                    width: expanded ? 110 : 80,
                    render: (actual, record) =>
                        record.monitoredObjectType?.name !== MonitoredObjectTypeName.LIGHT_VEHICLE ? (
                            <div className="tm-tracking-table-actual">
                                {actual ? (
                                    <Button
                                        className={cn({
                                            't-text-warning':
                                                moment.duration(moment(record.ETA).diff(record.RTA)).asMinutes() <=
                                                    30 &&
                                                moment.duration(moment(record.ETA).diff(record.RTA)).asMinutes() > 0,
                                            't-text-danger':
                                                moment.duration(moment(record.ETA).diff(record.RTA)).asMinutes() > 30,
                                            't-text-success':
                                                moment.duration(moment(record.ETA).diff(record.RTA)).asMinutes() <= 0,
                                            route: record.route
                                        })}
                                        data-actual={
                                            moment.duration(moment(record.ETA).diff(record.RTA)).asMinutes() || 0
                                        }
                                        size="small"
                                        disabled={
                                            !this.props.roles.includes(Role.JA_R) || record.invalid || !record.location
                                        }
                                        onClick={this._onColumnActualClick.bind(undefined, record.id, record.invalid)}
                                        qa={qa.trackingTable.btnActual}
                                    >
                                        {record.actual === VehicleDelayedFilterCode.ON_TIME
                                            ? t(`TrackingFilter.${record.actual}`)
                                            : record.actual}
                                    </Button>
                                ) : null}
                            </div>
                        ) : (
                            ''
                        )
                }
        ] as ColumnType<TrackingModel>[];
    };

    private _getLocationTitle = (name: string, addressStructured?: AddressStructured[]) => {
        const client = this.props.logic?.auth().client();
        const lang = this.props.logic?.settings().getProp('lang');
        const addressIdentification = this.props.logic?.settings().getProp('addressIdentification');
        if (!client || !lang || !addressIdentification) {
            console.error(
                'can not get "client" || "lang" || "addressIdentification" from logic, render default address name'
            );
            return name;
        }
        return toAddress(lang, client, addressStructured, addressIdentification, name);
    };
}

export default withLogicContext(withTranslation()(TrackingTable));
