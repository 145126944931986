import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EmissionClass, FleetModel, TunnelType } from '../../FleetModule';

import { Role } from 'logic/auth';
import VehicleForm, { VehicleFormModel } from 'common/forms/VehicleForm';
import FleetVehicleDetailCard from '../FleetVehicleDetailCard';
import { DEFAULT_OPTIMAL_RPM } from 'domain-constants';
import { WithLogic } from 'App';

interface Props extends WithTranslation, WithLogic {
    edit: boolean;
    data: FleetModel;
    demoMode?: boolean;
    roles: Role[];
    onFormCancelClick?: () => void;
    onFormSubmitClick?: (data: FleetModel) => void;
}

interface State {}

class FleetVehicleDetail extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        (window as any).app.trailerDetail = this;
    }

    render() {
        const { data } = this.props;

        return (
            <div className="management-vehicle-detail">
                {this.props.edit ? (
                    <VehicleForm
                        mode="EDIT"
                        vehicleType={data.type}
                        initialValues={{
                            id: this.props.data.id,
                            name: this.props.data.name,
                            emissionClass: this.props.data.emissionClass
                                ? EmissionClass[this.props.data.emissionClass]
                                : undefined,
                            fuelType: this.props.data.fuelType,
                            numberOfAxles: this.props.data.numberOfAxles,
                            tankVolume: this.props.data.tankSize,
                            tunnelType: this.props.data.tunnel ? TunnelType[this.props.data.tunnel] : undefined,
                            vin: this.props.data.vin,
                            height: this.props.data.height,
                            length: this.props.data.length,
                            weight: this.props.data.weight,
                            width: this.props.data.width,
                            weightFull: this.props.data.weightFull,
                            optimalRpm: this.props.data.optimalRpm ?? DEFAULT_OPTIMAL_RPM,
                            temperatureSensors: this.props.data?.temperatureSensors
                        }}
                        masterData={this.props.data.md}
                        onCancel={this.props.onFormCancelClick}
                        onSubmit={this._onSubmitForm}
                        roles={this.props.logic.auth().roles()}
                    />
                ) : (
                    <FleetVehicleDetailCard logic={this.props.logic} data={data} />
                )}
            </div>
        );
    }

    private _onSubmitForm = (data: VehicleFormModel): void => {
        if (this.props.data) {
            this.props.onFormSubmitClick?.({
                ...this.props.data,
                name: data.name!,
                vin: data.vin,
                tunnel: data.tunnelType,
                emissionClass: data.emissionClass,
                numberOfAxles: data.numberOfAxles,
                fuelType: data.fuelType,
                tankSize: data.tankVolume ?? 0,
                height: data.height,
                length: data.length,
                weight: data.weight,
                width: data.width,
                optimalRpm: data.optimalRpm,
                temperatureSensors: data.temperatureSensors
            });
        }
    };
}

export default withTranslation()(FleetVehicleDetail);
