import { FunctionComponent, ReactNode } from 'react';
import { Typography } from 'common/components';

interface Props {
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
    className?: string;
    qa?: string;
}

export const Control: FunctionComponent<Props> = ({ label, error, children, className, qa }: Props) => (
    <div className={`control${className ? ` ${className}` : ''}`} data-qa={qa}>
        {label && (
            <Typography.Text ellipsis className="control-label" title={label.toString()}>
                {label}
            </Typography.Text>
        )}
        <div className="control-child">{children}</div>
        {error && <div className="control-error">{error}</div>}
    </div>
);
