import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { roundToDecimals } from 'common/utils/averages';
import { ChartTrendsModel } from 'common/components/ChartTrends';
import { ArrowDot } from '../SimpleLineGraph/components/ArrowDot';
import { GraphLabel } from '../SimpleLineGraph/components/GraphLabel';
import { ThemeChartType, Themes, getChartTheme } from '../ChartTimeline';
import { TrendChange } from '../Trend';

interface Props {
    data: ChartTrendsModel[];
    theme?: Themes;
    color?: string;
    lineWidth?: number;
    maxY?: number;
    minY?: number;
    height?: number;
    width?: number;
    size: 'small' | 'large';
    showPoints?: boolean;
    showLinePoints?: boolean;
    pointRadius?: number;
    pointFill?: string;
    trendChangeForIncrease?: TrendChange;
}

export default function ChartLineTrends(props: Props) {
    const dataMax = Math.max(Math.round(Math.max(...props.data.map(d => d.yVal ?? 0)) * 1.1), 1);
    const dataMin = Math.min(Math.round(Math.min(...props.data.map(d => d.yVal ?? 0)) * 0.8), -1);

    const step = dataMax <= 1 ? 1 : dataMax <= 100 ? 5 : 10;
    const max = props.maxY ? props.maxY : Math.ceil(dataMax / step) * step;
    const min = props.minY ? props.minY : Math.ceil((dataMin - step) / step) * step;
    const tickCount = dataMax <= 1 ? 20 : (max - min) / (dataMax <= 10 ? 1 : step) + 1;

    const chartTheme = getChartTheme(props.theme, ThemeChartType.Trend);

    return (
        <div
            style={{
                height: props.height ? `${props.height}px` : undefined,
                width: props.width ? `${props.width}px` : undefined
            }}
        >
            <ResponsiveContainer>
                <LineChart
                    data={props.data.map((data, index, allData) => ({
                        ...data,
                        yVal: data.yVal ? roundToDecimals(data.yVal, 1) : undefined,
                        prevValue: index > 0 ? allData[index - 1]?.yVal : undefined,
                        nextValue: allData?.[index + 1]?.yVal
                    }))}
                    margin={{
                        top: 17,
                        right: 30,
                        left: 0,
                        bottom: 0
                    }}
                >
                    <XAxis
                        fontSize={props.size === 'large' ? 14 : 11}
                        dataKey="xVal"
                        stroke={String(chartTheme.xAxis?.line?.stroke)}
                    />
                    <YAxis
                        fontSize={props.size === 'large' ? 14 : 11}
                        tickCount={props.size === 'large' ? tickCount : undefined}
                        domain={props.size === 'large' ? [min, max] : undefined}
                        stroke={String(chartTheme?.yAxis?.line?.stroke)}
                    />
                    <Line
                        type="monotone"
                        isAnimationActive={false}
                        dataKey="yVal"
                        fontSize={props.size === 'large' ? 22 : 8}
                        strokeWidth={props.lineWidth ?? 2}
                        stroke={props.color ?? '#00e1ff'}
                        fill={props.pointFill ?? props.color ?? '#00e1ff'}
                        label={
                            props.size === 'large' ? (
                                <GraphLabel stroke={chartTheme.tooltip?.color} size={props.size} />
                            ) : undefined
                        }
                        dot={
                            props.size === 'large' ? (
                                <ArrowDot
                                    pointRadius={props.pointRadius ?? 1}
                                    showPoint={props.showPoints}
                                    showLinePoint={props.showLinePoints}
                                    trendChangeForIncrease={props.trendChangeForIncrease}
                                    theme={props.theme}
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
