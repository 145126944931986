import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, Row } from 'antd';

import { Card, Button } from 'common/components';
import { AlarmType } from 'generated/backend-api';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { AddressStructured } from 'common/model/address';
import { degC } from 'domain-constants';
import numeral from 'numeral';

export interface AlarmUniqueData {
    transport_id?: string;
    place_id?: string;
    temperature_sensor_serial_number: string;
    temperature_sensor_zone: number;
    temperature_sensor_device_id?: string;
    cold_chain_profile?: {
        profile_id: string;
        above_temperature_threshold?: number;
        below_temperature_threshold?: number;
        alarm_timer_seconds?: number;
    };
}

export const getColdchainAlarmDescription = (type: AlarmType, uniqueData?: AlarmUniqueData): string => {
    return [
        AlarmType.TransportColdChainProfileTemperatureHigh,
        AlarmType.TransportColdChainProfileTemperatureLow
    ].includes(type)
        ? ` ${numeral(
              uniqueData?.cold_chain_profile?.[
                  type === AlarmType.TransportColdChainProfileTemperatureHigh
                      ? 'above_temperature_threshold'
                      : 'below_temperature_threshold'
              ]
          ).format('0,0.0')} ${degC}`
        : '';
};
interface Props {
    id: string;
    type: AlarmType;
    acknowledged?: boolean;
    startDateTime?: string;
    description?: string;
    vehicle?: string;
    uniqueData?: AlarmUniqueData;
    address?: AddressStructured[] | string;
    closeButtonId?: string;
    onMarkAsSeen?: (id: string) => void;
    demoMode?: boolean;
}

export default function AlarmBlock({
    id,
    vehicle,
    startDateTime,
    acknowledged,
    type,
    address,
    closeButtonId,
    onMarkAsSeen,
    demoMode,
    uniqueData
}: Props) {
    const { t } = useTranslation();

    function onMarkAsRead() {
        onMarkAsSeen?.(id);
    }

    return (
        <>
            <Card className={cn('alarm-block', { 'alarm-block--unseen': !acknowledged })}>
                <Row className="alarm-block-title" justify="start">
                    <Col xs={closeButtonId ? 12 : 16}>
                        <i className="far fa-bell" />
                        {`${t(`Alarm.types.${type}`)}${getColdchainAlarmDescription(type, uniqueData)}${
                            vehicle ? `, ${vehicle}` : ''
                        }`}
                    </Col>
                    <Col
                        xs={closeButtonId ? 11 : 8}
                        className="alarm-block-date-time"
                        title={startDateTime ? moment.utc(startDateTime).local().format('L LT') : ''}
                    >
                        {startDateTime ? moment.utc(startDateTime).local().format('L LT') : ''}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={23}>
                        <i className="fa fa-map-marker" />
                        {typeof address !== 'string' ? (
                            <AddressFormatter defaultAddress="Undefined address" addressStructured={address} />
                        ) : (
                            address
                        )}
                    </Col>
                    {acknowledged ? (
                        <Col xs={1} className="alarm-block-check-acknowledged">
                            <i className="fas fa-check" />
                        </Col>
                    ) : (
                        <Col xs={1} className="alarm-block-check-button">
                            <Button type="ghost" disabled={acknowledged || demoMode} onClick={onMarkAsRead}>
                                <i className="fas fa-check" />
                            </Button>
                        </Col>
                    )}
                </Row>
            </Card>
            {closeButtonId && (
                <div className="close-icon">
                    <img id={closeButtonId} alt="close" src="/img-svg/icn-close.svg" />
                </div>
            )}
        </>
    );
}
