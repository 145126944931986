import { Ref } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Col, Divider, Row, Typography } from 'antd';
import useDimensions from 'react-cool-dimensions';
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { KM } from 'domain-constants';
import { TransportModel } from 'common/model/transports';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { Button, Popover, Tooltip } from 'common/components';
import * as dispatcherBoardIcons from 'resources/images/dispatcher-board';
import qa from 'qa-selectors';

import { TransportCalculatedProperties } from '../../dispatcher-board-logic';
import AddressDetail from '../AddressDetail/AddressDetail';
import * as icons from 'resources/images/dispatcher-board';
import { VehicleStateObject } from 'generated/graphql';
import TelematicsTransportMap from '../TelematicsTransportMap/TelematicsTransportMap';
import { RouteComponentProps, withRouter } from 'react-router';
import { RouteNames } from 'App';
import qs from 'qs';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectAddressStructuredByLanguage } from 'common/utils/address';
import { EWClient } from 'generated/main-data-api';
import { Role } from 'logic/auth';

interface Props extends RouteComponentProps, WithTranslation {
    transport: TransportModel;
    properties: TransportCalculatedProperties;
    expanded: boolean;
    vehicleState?: VehicleStateObject;
    routes?: string[] | undefined;
    innerRef?: Ref<HTMLDivElement>;
    draggableProps?: DraggableProvidedDraggableProps;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    lang: string;
    client?: EWClient;
    roles: Role[];
    onClick?: (transport: TransportModel) => void;
    onTransportMapAfterInit?: (transportId: string) => void;
}

function TelematicsTransportBlock(props: Props) {
    const { observe, width } = useDimensions();

    const [firstPlace] = props.transport.places;
    const lastPlace = props.transport.places[props.transport.places.length - 1];
    const [lastPlaceAddress] = lastPlace?.addressStructured ?? [];
    const countryCode = lastPlaceAddress?.countryCode?.toLowerCase();
    const disabled = props.properties.timeToFirstRta > 24;

    function getETAWidth() {
        // TODO: Calculate this in properties calculate
        return `${100 * Math.abs(props.properties.etaWidth! / props.properties.width)}%`;
    }

    function getETAInfo() {
        return `${
            props.properties.duration && props.transport.lastPlaceRta
                ? moment.utc(props.transport.lastPlaceRta).add(props.properties.duration, 'seconds').format('LT')
                : ''
        } (${props.properties.actual})`;
    }

    function renderEta() {
        return props.properties.renderEta && props.roles.includes(Role.ETA_R) ? (
            <Tooltip placement="right" title={renderEtaTooltip()}>
                <div
                    className={cn('telematics-transport-eta', getEtaStatusClass())}
                    style={{
                        width: getETAWidth()
                    }}
                    data-qa={qa.dispatcherBoard.status[getEtaStatusClass() ?? '']}
                >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Row align="middle" gutter={10}>
                                <Col>
                                    <img src={dispatcherBoardIcons.arrow} alt="direction" />
                                </Col>
                                {width > 150 && (
                                    <Col>
                                        <span data-qa={qa.dispatcherBoard.etaKm}>
                                            {props.transport.places.reduce((a, c) => a + (c.distance ?? 0), 0) / 1e3}
                                        </span>
                                        {KM}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        {width > 150 && (
                            <Col>
                                <Row align="middle" gutter={10}>
                                    <Col>{getETAInfo()}</Col>
                                    <Col>
                                        <img src={dispatcherBoardIcons.checkpoint} alt="checkpoint" />
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </div>
            </Tooltip>
        ) : null;
    }

    function renderCountry() {
        return (
            <div className="telematics-transport-block-country">
                <span className={cn('flag-icon flag-icon-squared', `flag-icon-${countryCode}`)} />
            </div>
        );
    }

    function renderTransportPopoverContent() {
        return (
            <div className="telematics-transport-block-tooltip">
                <div className="telematics-transport-block-tooltip-wrapper user-name">
                    {props.vehicleState?.driver && props.vehicleState.driver.name}
                </div>
                <div className="telematics-transport-block-tooltip-wrapper">
                    <AddressDetail
                        defaultAddress={firstPlace.name}
                        address={selectAddressStructuredByLanguage(
                            firstPlace.addressStructured,
                            props.lang,
                            props.client
                        )}
                        iconUrl={icons.load}
                        roles={props.roles}
                        rta={firstPlace.rta}
                    />
                </div>
                {props.vehicleState?.address_structured && props.vehicleState?.address_structured.length > 0 && (
                    <div className="telematics-transport-block-tooltip-wrapper actual-position">
                        <img alt="vector" src={icons.centerLocation} />
                        <AddressFormatter
                            defaultAddress=""
                            addressStructured={props.vehicleState.address_structured}
                        />{' '}
                        {props.roles.includes(Role.ETA_R) && props.properties.actual
                            ? props.properties.actual === 'on_time'
                                ? `( ${props.t('TrackingFilter.on_time')} )`
                                : `( ${props.properties.actual} )`
                            : null}
                    </div>
                )}
                <div className="telematics-transport-block-tooltip-wrapper">
                    <AddressDetail
                        defaultAddress={lastPlace.name}
                        address={selectAddressStructuredByLanguage(
                            lastPlace.addressStructured,
                            props.lang,
                            props.client
                        )}
                        iconUrl={icons.unload}
                        roles={props.roles}
                        rta={lastPlace.rta}
                    />
                </div>
                {props.vehicleState && props.roles.includes(Role.LM_R) && (
                    <>
                        <TelematicsTransportMap
                            mapId={`map-${props.vehicleState.monitoredObjectId}-${props.transport.id}`}
                            key={`map-${props.vehicleState.monitoredObjectId}-${props.transport.id}`}
                            vehicleState={props.vehicleState}
                            routes={props.routes}
                            onAfterMapInit={onAfterMapInit}
                        />
                        <Divider />
                        <Button
                            disabled={
                                !props.vehicleState.gpsData ||
                                !props.vehicleState.gpsData.lat ||
                                !props.vehicleState.gpsData.lon
                            }
                            className="telematics-transport-block-tooltip-btn-show-on-map"
                            type="primary"
                            onClick={e => {
                                e.stopPropagation();
                                props.history.push({
                                    pathname: RouteNames.TRACKING,
                                    search: qs.stringify({
                                        vehicleId: props.vehicleState?.monitoredObjectId
                                    })
                                });
                            }}
                        >
                            {props.t('common.showOnMap')}
                        </Button>
                    </>
                )}
            </div>
        );
    }

    function onAfterMapInit() {
        if (props.transport.id) props.onTransportMapAfterInit?.(props.transport.id);
    }

    function getEtaStatusClass() {
        if (props.properties.duration) {
            if (props.properties.duration.asMinutes() <= 0) {
                return 'telematics-transport-eta-on-time';
            }

            if (props.properties.duration.asMinutes() > 0 && props.properties.duration.asMinutes() <= 30) {
                return 'telematics-transport-eta-delay';
            }

            if (props.properties.duration.asMinutes() > 30) {
                return 'telematics-transport-eta-late';
            }
        }

        return undefined;
    }

    function renderEtaTooltip() {
        return `${
            props.properties.duration && props.transport.lastPlaceRta
                ? moment.utc(props.transport.lastPlaceRta).add(props.properties.duration, 'seconds').format('L LT')
                : ''
        } ${
            props.properties.actual === 'on_time'
                ? props.t(`TrackingFilter.${props.properties.actual}`)
                : props.properties.actual
        }`;
    }

    return (
        <div
            className={cn('telematics-transport', {
                'telematics-transport-lite': !props.expanded,
                'telematics-transport-disabled': disabled
            })}
            ref={props.innerRef}
            {...props.draggableProps}
            data-transport-name={props.transport?.name}
            data-vehicle-id={props.transport.vehicle}
        >
            <Popover
                overlayClassName="telematics-transport-popover"
                placement="left"
                autoAdjustOverflow
                content={renderTransportPopoverContent()}
            >
                <div
                    ref={observe}
                    onClick={() => props.onClick?.(props.transport)}
                    className="telematics-transport-block"
                    {...props.dragHandleProps}
                >
                    <Typography.Text ellipsis>
                        <AddressFormatter
                            defaultAddress={lastPlace.name ?? ''}
                            addressStructured={lastPlace.addressStructured}
                        />
                    </Typography.Text>
                    {width > 45 && renderCountry()}
                </div>
            </Popover>
            {renderEta()}
        </div>
    );
}

export default withRouter(withTranslation()(TelematicsTransportBlock));
