import { Col, Row, Table } from 'antd';
import { WithLogic } from 'App';
import { Button } from 'common/components';
import { M3, T } from 'domain-constants';
import { Goods } from 'generated/backend-api';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { useState, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActivityTrash } from 'resources/images/planner';
import { ActivityGood } from '../ActivityCard';
import { EditableCell, EditableRow } from './EditableRow';

interface Props extends WithTranslation, WithLogic {
    goods?: Array<Goods>;
    placeDisabled: boolean;
    editable?: boolean;
    disabled?: boolean;
    rowSelection?: object;
    isNewRow?: boolean;
    onChange?: (record: ActivityGood, dataIndex: string) => void;
    onDelete?: () => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const GoodsTable = (props: Props) => {
    const [_edited, setEdited] = useState(false);
    const [rowCount, setRowCount] = useState(props.goods?.length ?? 0);
    const tableRef = useRef(null);

    const { t } = props;

    const handleDeleteGoods = (id: string) => {
        const index = props.goods?.findIndex(item => item.id === id) ?? -1;

        if (index > -1) {
            props.goods?.splice(index, 1);
        }
        setRowCount(props.goods?.length ?? 0);
        props.onDelete?.();
    };

    const handleEditGoods = (state: boolean) => {
        if (state) {
            setEdited(true);
        }
    };

    const handleEditValue = (dataIndex: string, record: ActivityGood) => {
        if (dataIndex === 'measurements') {
            return props.logic.plannerLogic().getGoodWeight(record, M3);
        } else if (dataIndex === 'grossWeightVolume') {
            return props.logic.plannerLogic().getGoodWeight(record, T);
        } else {
            return record[dataIndex];
        }
    };

    const handleSaveGoods = async (record: ActivityGood, dataIndex: string) => {
        try {
            const row = record;

            const index = props.goods!.findIndex(item => row.id === item.id);
            if (index > -1) {
                const item = props.goods![index];
                let measurements = item.measurements ?? [];

                if (dataIndex === 'measurements') {
                    const measurement = measurements.find(m => m.measurementUnit === M3);
                    if (measurement) {
                        measurement.grossWeightVolume = Number(row.measurements ?? 0);
                    } else {
                        measurements.push({
                            grossWeightVolume: Number(row.measurements ?? 0),
                            measurementUnit: M3
                        });
                    }
                }

                if (dataIndex === 'grossWeightVolume') {
                    const measurement = measurements.find(m => m.measurementUnit === T);
                    if (measurement) {
                        measurement.grossWeightVolume = Number(row.grossWeightVolume ?? 0);
                    } else {
                        measurements.push({
                            grossWeightVolume: Number(row.grossWeightVolume ?? 0),
                            measurementUnit: T
                        });
                    }
                }

                props.goods!.splice(index, 1, {
                    ...item,
                    name: row.name,
                    cnItems: row.cnItems,
                    cmr: row.cmr,
                    grossWeightVolume: row.grossWeightVolume ?? 0,
                    measurements: measurements
                });
                setEdited(false);
                props.onChange?.(record, dataIndex);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleOnRow = async (index: number) => {
        if (index === (props.goods ?? []).length - 1 && props.editable) {
            if ((props.goods?.length ?? 0) > rowCount) {
                setTimeout(() => {
                    if (tableRef.current) {
                        const td = (tableRef.current as HTMLDivElement).querySelector(
                            'tbody tr:last-child td:nth-child(2)'
                        );
                        if (td) {
                            (td as any).firstElementChild?.click();
                        }
                    }
                }, 50);
            }
            if (rowCount !== (props.goods?.length ?? 0)) {
                setRowCount(props.goods?.length ?? 0);
            }
        }
    };

    const getPlaceHolder = (columnName: string): React.ReactNode => {
        let holder = '';

        switch (columnName) {
            case 'name':
                holder = t('PlannerPlacesTask.loadingUnloadingGoodsTable.nameOfGoods');
                break;
            case 'cnItems':
                holder = t('PlannerPlacesTask.loadingUnloadingGoodsTable.cnItems');
                break;
            case 'cmr':
                holder = t('PlannerPlacesTask.loadingUnloadingGoodsTable.CMRNumber');
                break;
            case 'weight':
                holder = t('PlannerPlacesTask.loadingUnloadingGoodsTable.weight');
                break;
            case 'volume':
                holder = t('PlannerPlacesTask.volume');
                break;
        }

        return <span className="holder">{holder}</span>;
    };

    const editable = props.editable !== false && !props.disabled && !props.placeDisabled;

    let goodsColumn: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; dataType?: string })[] = [
        {
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.no'),
            dataIndex: 'index',
            key: 'index',
            width: 56,
            render: (index: string) => <span data-qa={qa.planner.activity.goods.fieldIndex}>{index + 1}</span>
        },
        {
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.nameOfGoods'),
            dataIndex: 'name',
            key: 'name',
            editable: editable,
            width: props.editable ? 175 : props.placeDisabled ? 295 : 235,
            render: (name: string) => (
                <span data-qa={qa.planner.activity.goods.fieldName}>{name === '' ? getPlaceHolder('name') : name}</span>
            )
        },
        {
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.cnItems'),
            dataIndex: 'cnItems',
            key: 'cnItems',
            editable: editable,
            width: 112,
            render: (cnItems: string) => (
                <span data-qa={qa.planner.activity.goods.fieldCnItems}>{cnItems ?? getPlaceHolder('cnItems')}</span>
            )
        },
        {
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.CMRNumber'),
            dataIndex: 'cmr',
            key: 'cmr',
            editable: editable,
            width: 127,
            render: (cmr: string) => (
                <span data-qa={qa.planner.activity.goods.fieldCmrNumber}>{cmr ?? getPlaceHolder('cmr')}</span>
            )
        },
        {
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.weight'),
            dataIndex: 'grossWeightVolume',
            key: 'grossWeightVolume',
            editable: editable,
            dataType: 'number',
            width: 80,
            render: (_, record) => {
                const value = props.logic.plannerLogic().getGoodWeight(record as ActivityGood, T);
                return (
                    <span data-qa={qa.planner.activity.goods.fieldGrossWeightVolume}>
                        {value && `${numeral(value ?? 0).format('0,0.00')} ${T}`}
                        {value === undefined && getPlaceHolder('weight')}
                    </span>
                );
            }
        },
        {
            title: t('PlannerPlacesTask.volume'),
            dataIndex: 'measurements',
            key: 'measurementVolume',
            editable: editable,
            dataType: 'number',
            width: 100,
            render: (_, record) => {
                const value = props.logic.plannerLogic().getGoodWeight(record as ActivityGood, M3);
                return (
                    <span data-qa={qa.planner.activity.goods.fieldMeasurmentUnit}>
                        {value && `${numeral(value ?? 0).format('0,0.00')} ${M3}`}
                        {value === undefined && getPlaceHolder('volume')}
                    </span>
                );
            }
        }
    ];

    if (props.editable) {
        goodsColumn.push({
            title: t('PlannerPlacesTask.loadingUnloadingGoodsTable.actions'),
            dataIndex: 'actions',
            key: 'actions',
            width: 110,
            align: 'center',
            render: (_: any, record) => {
                return (
                    <Row align="middle" justify="center" gutter={[10, 20]}>
                        {!props.placeDisabled && (
                            <Col>
                                <Button
                                    type="link"
                                    className="edit-item-action"
                                    icon={<img src={ActivityTrash} alt="edit-item-delete-action" />}
                                    onClick={() => {
                                        handleDeleteGoods((record as ActivityGood).id);
                                    }}
                                    qa={qa.planner.activity.goods.btnDelete}
                                />
                            </Col>
                        )}
                    </Row>
                );
            }
        });
    }

    const components = props.editable
        ? {
              body: {
                  row: EditableRow,
                  cell: EditableCell
              }
          }
        : undefined;

    const columns = goodsColumn.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ActivityGood) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                dataType: col.dataType ?? 'text',
                onSave: handleSaveGoods,
                onEdit: handleEditGoods,
                onEditValue: handleEditValue
            })
        };
    });

    return (
        <div ref={tableRef}>
            <Table
                className="activity-goods-table"
                rowSelection={props.rowSelection}
                rowClassName={record => {
                    return (record as ActivityGood).disabled ? 'disabled-row' : '';
                }}
                components={components}
                dataSource={props.goods?.map((e, index) => ({
                    ...e,
                    key: e.id,
                    index: index
                }))}
                columns={columns as ColumnTypes}
                pagination={false}
                bordered={false}
                onRow={record => {
                    handleOnRow((record as any).index);
                    return {};
                }}
            />
        </div>
    );
};

export default withTranslation()(GoodsTable);
