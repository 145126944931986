/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TollCostByCountry,
    TollCostByCountryFromJSON,
    TollCostByCountryFromJSONTyped,
    TollCostByCountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportTollCost
 */
export interface TransportTollCost {
    /**
     * 
     * @type {number}
     * @memberof TransportTollCost
     */
    totalCost: number;
    /**
     * 
     * @type {Array<TollCostByCountry>}
     * @memberof TransportTollCost
     */
    tollByCountry: Array<TollCostByCountry>;
}

export function TransportTollCostFromJSON(json: any): TransportTollCost {
    return TransportTollCostFromJSONTyped(json, false);
}

export function TransportTollCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportTollCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCost': json['totalCost'],
        'tollByCountry': ((json['tollByCountry'] as Array<any>).map(TollCostByCountryFromJSON)),
    };
}

export function TransportTollCostToJSON(value?: TransportTollCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCost': value.totalCost,
        'tollByCountry': ((value.tollByCountry as Array<any>).map(TollCostByCountryToJSON)),
    };
}


