import cn from 'classnames';
import { Checkbox } from 'common/components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import qa from 'qa-selectors';

export interface CheckboxGroupOpt {
    code: string;
    label: string;
    checked: boolean;
    qa?: string;
}

export type CheckboxGroupOpts = CheckboxGroupOpt[];

interface Props {
    heading?: string;
    scrolled?: boolean;
    small?: boolean;
    options: CheckboxGroupOpts;
    onChange?: (checkedValues: string[]) => void;
    labelCheckAll?: string;
    qa?: string;
    qaCheckAll?: string;
}

export function CheckboxGroup(props: Props) {
    function onCheckAllChange(e: CheckboxChangeEvent) {
        const value = e.target.checked;
        props.onChange?.(value ? props.options.map(o => o.code) : []);
    }

    function onCheckboxChange(checkedValues: CheckboxValueType[]): void {
        props.onChange?.(checkedValues as string[]);
    }

    const checked = props.options?.filter(o => o.checked === true);
    return (
        <>
            {props.heading && <label className="group-title">{props.heading}</label>}
            <div
                className={cn('checkbox-group', {
                    scroll: props.scrolled,
                    'checkbox-group-small': props.small
                })}
            >
                {props.labelCheckAll && (
                    <Checkbox
                        className="check-all"
                        onChange={onCheckAllChange}
                        checked={checked.length === props.options.length}
                        indeterminate={!!checked.length && checked.length < props.options.length}
                        qa={props.qaCheckAll ?? qa.common.checkboxAll}
                    >
                        {props.labelCheckAll}
                    </Checkbox>
                )}
                <Checkbox.Group
                    onChange={onCheckboxChange}
                    qa={props.qa}
                    options={props.options?.map(o => ({
                        label: o.label,
                        value: o.code,
                        checked: o.checked,
                        qa: o.qa ?? qa.common.checkbox
                    }))}
                    value={props.options?.filter(o => o.checked).map(o => o.code)}
                />
            </div>
        </>
    );
}
