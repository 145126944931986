import { Col, Row } from 'antd';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'common/components';
import * as iconModule from 'resources/images/modules';
import { confDefault } from 'conf';

export type module = { id?: string; name: string; hasRight: boolean };
export type ParentModule = {
    id: number;
    code: string;
    name: string;
    access: boolean;
    subModules: module[];
};

interface Props extends WithTranslation {
    modulesGroups: ParentModule[];
    mode: 'READ' | 'EDIT';
    roleId?: string;
    selectedModuleId?: number;
    demoMode?: boolean;
    onRightChange?: (hasRight: boolean, moduleId?: string) => void;
    onModuleSelect?: (moduleId: number) => void;
}

function UserModules(props: Props) {
    function onModuleSelect(moduleId: number): void {
        props.onModuleSelect?.(moduleId);
    }

    function getModuleGroupButton(parentModule: ParentModule) {
        return (
            <Button
                className={cn(`module-${parentModule.id}`, {
                    selected: parentModule.id === props.selectedModuleId,
                    purchased: props.demoMode || parentModule.access,
                    available: !(props.demoMode || parentModule.access)
                })}
                block
                title={props.t(`UserRights.modules.${parentModule.name.toLowerCase().replace(/ /g, '')}.name`)}
                onClick={onModuleSelect.bind(undefined, parentModule.id)}
                qa={`roles--detail--module-${parentModule.id}`}
            >
                <img
                    className="icon-module"
                    src={iconModule[`module_${parentModule.id}`]}
                    alt={parentModule.name.replace(/ /g, '')}
                />
                {!props.demoMode && !parentModule.access && (
                    <img className="icon-lock" src={`/img-svg/icn-24-lock.svg`} alt="lock" />
                )}
                {props.t(`UserRights.modules.${parentModule.name.toLowerCase().replace(/ /g, '')}.name`)}
            </Button>
        );
    }

    return (
        <div className="user-rights">
            <Row gutter={[15, 15]} justify="start" className="user-rights-group">
                {props.modulesGroups
                    .filter(parentModule => confDefault.visibleModulesIds.includes(parentModule.id)) //  PartnerModule and other modules hidden
                    .sort((a: ParentModule, b: ParentModule) => (a.id > b.id ? 1 : -1))
                    .map((parentModule, index) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                            {getModuleGroupButton(parentModule)}
                        </Col>
                    ))}
            </Row>
        </div>
    );
}

export default withTranslation()(UserModules);
