/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MainDataResOperationResultSummary,
    MainDataResOperationResultSummaryFromJSON,
    MainDataResOperationResultSummaryToJSON,
    MainDataResTagCategoryArray,
    MainDataResTagCategoryArrayFromJSON,
    MainDataResTagCategoryArrayToJSON,
    MainDataResvoid,
    MainDataResvoidFromJSON,
    MainDataResvoidToJSON,
    TagCategoryCreateModel,
    TagCategoryCreateModelFromJSON,
    TagCategoryCreateModelToJSON,
    TagCategoryUpdateModel,
    TagCategoryUpdateModelFromJSON,
    TagCategoryUpdateModelToJSON,
} from '../models';

export interface DeleteRequest {
    id: number;
    performModifications?: boolean;
}

export interface CreateRequest {
    performModifications?: boolean;
    tagCategoryCreateModel?: TagCategoryCreateModel;
}

export interface UpdateRequest {
    performModifications?: boolean;
    tagCategoryUpdateModel?: TagCategoryUpdateModel;
}

/**
 * no description
 */
export class TagCategoryApi extends runtime.BaseAPI {

    /**
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async _delete(requestParameters: DeleteRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagCategoryCreateModelToJSON(requestParameters.tagCategoryCreateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: CreateRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRaw(): Promise<runtime.ApiResponse<MainDataResTagCategoryArray>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResTagCategoryArrayFromJSON(jsonValue));
    }

    /**
     */
    async get(): Promise<MainDataResTagCategoryArray> {
        const response = await this.getRaw();
        return await response.value();
    }

    /**
     */
    async updateRaw(requestParameters: UpdateRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag-categories`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TagCategoryUpdateModelToJSON(requestParameters.tagCategoryUpdateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async update(requestParameters: UpdateRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.updateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async versionRaw(): Promise<runtime.ApiResponse<MainDataResvoid>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/tag-categories/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResvoidFromJSON(jsonValue));
    }

    /**
     */
    async version(): Promise<MainDataResvoid> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
