import { google } from 'google-maps';
import * as turf from '@turf/turf';
import { VehiclesMapController } from './logic/vehicles';
import { RoutingMapController } from './logic//routing';
import { mapStyles } from './style';
import { Role } from 'logic/auth';
import { ParkingsMapController } from './logic//parkings';
import { PoisMapController } from './logic/pois';
import { WashersMapController } from './logic/washers';
import { FuelStationsMapController, PoiModelMap } from './logic/fuelStations';
import { PoiMapController } from './logic//poi';
import { BorderCrossesMapController } from './logic/border-crosses';
import { Fuels, LayerTypes, Services } from 'modules/map/MapModule';
import { TrackingModel } from 'common/model/tracking';
import { ControlPanel, ControlPanelIcon } from 'modules/map/components/MapControlsBar';
import { PolygonMapController } from './logic/polygons';
import { GeoJsonPolygonTypeInput } from '../../generated/graphql';
import qa from 'qa-selectors';
import { Subject } from 'rxjs';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM } from 'domain-constants';

export interface MapConf {
    initBounds: { lat: number; lng: number }[];
    vehicles: {
        vehicleCenterDistance: number;
        clusteringEnabled?: boolean;
    };
}

export class MapLogic {
    readonly zoom = { default: 8, min: DEFAULT_MIN_ZOOM, max: DEFAULT_MAX_ZOOM };
    readonly initialPadding = { left: 60, top: 60, right: 60, bottom: 60 };
    readonly initialPaddingWithRightComponent = { ...this.initialPadding, right: 380 };
    readonly initialPaddingWithLeftComponent = { ...this.initialPadding, left: 750 };
    readonly initialPaddingWithBottomComponent = { ...this.initialPadding, bottom: 300 };
    filterSubject: Subject<[Fuels, Services]>;
    private _padding: google.maps.Padding;
    private _map?: google.maps.Map;
    private _conf: MapConf;
    private _roles: Role[];
    private _google: google;
    private _clickListener?: google.maps.MapsEventListener;
    private _traffic?: google.maps.TrafficLayer;
    private _dragEndListener?: google.maps.MapsEventListener;

    private _vehicles?: VehiclesMapController;
    private _parkings?: ParkingsMapController;
    private _fuelStations?: FuelStationsMapController;
    private _pois?: PoisMapController;
    private _washers?: WashersMapController;
    private _routing?: RoutingMapController;
    private _poi?: PoiMapController;
    private _polygons?: PolygonMapController;
    private _borderCrosses?: BorderCrossesMapController;

    private _fuelControl?: HTMLInputElement;
    private _parkingControl?: HTMLInputElement;
    private _layerControl?: HTMLInputElement;
    private _poiControl?: HTMLInputElement;
    private _washControl?: HTMLInputElement;
    private _centerControl?: HTMLInputElement;

    private _currentSideBarControl: ControlPanel | undefined;
    private _poiBarPossible: boolean;

    private _onClick?: (latLng: { lat: number; lng: number }) => void;
    private _onRightClick?: (latLng: { lat: number; lng: number }) => void;
    private _onDragEnd?: (latLng: { lat?: number; lng?: number }, simple: boolean) => void;
    private _onLoading?: (value: boolean) => void;
    private _onFuelLoading?: (value: boolean) => void;
    private _onParkingLoading?: (value: boolean) => void;
    private _onPoiLoading?: (value: boolean) => void;
    private _onWashLoading?: (value: boolean) => void;
    private _onFuelControlToggle?: (value?: boolean) => void;
    private _onParkingControlToggle?: (value?: boolean) => void;
    private _onLayerControlToggle?: (value?: boolean) => void;
    private _onPoiControlToggle?: (value?: boolean) => void;
    private _onWashControlToggle?: (value?: boolean) => void;
    private _onPoiToggle?: (value?: boolean) => void;
    private _onCenterControlClick?: () => void;
    private _onVehicleCardData?: (value?: TrackingModel) => void;
    private _onControlsOff?: (control?: ControlPanel) => void;
    private _onSideBarControlsOffResetState?: () => void;
    private _onVehicleCardDataUpdate?: (value?: TrackingModel) => void;
    private _onVehicleControlToggle?: (value?: boolean) => void;
    private _onRoutePlanning?: (value?: 'edit' | 'preview') => void;
    private _onResetPoisData?: () => void;
    private _onJourneyGraphToggle?: () => void;

    loading: boolean;
    fuelLoading: boolean;
    parkingLoading: boolean;
    poiLoading: boolean;
    washLoading: boolean;

    journeyGraphVisible: boolean;

    constructor(conf: MapConf, roles: Role[]) {
        this._conf = conf;
        this._roles = roles;
        this._google = (window as any).google;
        this._poiBarPossible = true;
        this.loading = false;
        this.fuelLoading = false;
        this.parkingLoading = false;
        this.poiLoading = false;
        this.washLoading = false;
        this._padding = this.initialPadding;
        this.filterSubject = new Subject<[Fuels, Services]>();
        this.journeyGraphVisible = !!document
            .getElementById('journey-graph-control-toggler')
            ?.classList.contains('active');
    }

    init(element: HTMLElement, simple: boolean = false) {
        this._map = new this._google.maps.Map(element, {
            center: new this._google.maps.LatLng(this._conf.initBounds[0].lat, this._conf.initBounds[0].lng),
            zoom: this.zoom.default,
            styles: mapStyles.default,
            gestureHandling: 'greedy',
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            minZoom: this.zoom.min,
            scaleControl: true
        });

        if (this._onClick) {
            this._clickListener = this._map?.addListener('click', (e: google.maps.MapMouseEvent) => {
                this._onClick?.({
                    lat: e.latLng?.lat() ?? 0,
                    lng: e.latLng?.lng() ?? 0
                });
            });
        }
        if (this._onRightClick) {
            this._clickListener = this._map?.addListener('rightClick', (e: google.maps.MapMouseEvent) => {
                this._onRightClick?.({
                    lat: e.latLng?.lat() ?? 0,
                    lng: e.latLng?.lng() ?? 0
                });
            });
        }

        if (this._onDragEnd) {
            this._dragEndListener = this._map?.addListener('dragend', (e: google.maps.MapMouseEvent) => {
                this._onDragEnd?.(
                    {
                        lat: e?.latLng?.lat() ?? 0,
                        lng: e?.latLng?.lng() ?? 0
                    },
                    simple
                );
            });
        }
        const controls = [
            this._createControlLayers(),
            ...(!simple
                ? [
                      this._roles.includes(Role.OPOI_R) && this._createControlPoi(),
                      this._roles.includes(Role.TRVS) && this._createControlWash(),
                      this._createControlParking(),
                      this._createControlFuel()
                  ]
                : [])
        ];

        document.getElementById('map')?.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement) {
                this.sideBarControlsOff();
                this._hideMapControls();
            } else {
                this._showMapControls();
            }
        });

        this._currentSideBarControl = undefined;

        controls.forEach(control => {
            control && this._map && this._map.controls[this._google.maps.ControlPosition.TOP_RIGHT].push(control);
        });
        this._map.controls[this._google.maps.ControlPosition.BOTTOM_RIGHT].push(this._createControlCenter());

        const defaultBounds = new this._google.maps.LatLngBounds();

        this._conf.initBounds.forEach(place => {
            defaultBounds.extend(place);
        });
        this._map?.fitBounds(defaultBounds);
    }

    addControls(position: google.maps.ControlPosition, controls: Node[]) {
        controls.forEach(control => {
            this._map!.controls[position].push(control);
        });
    }

    setZoom(zoom: number) {
        console.log('setZoom', zoom);
        this._map?.setZoom(zoom);
    }
    panBy(x: number, y: number) {
        console.log('panBy', x, y);
        this._map?.panBy(x, y);
    }

    removeControls(position: google.maps.ControlPosition) {
        this._map!.controls[position].clear();
    }

    getDefaultZoom() {
        return this.zoom;
    }

    destroy() {
        // TODO: Correctly destroy map
        this._map = undefined;

        if (this._clickListener) {
            this._google.maps.event.removeListener(this._clickListener);
        }

        if (this._dragEndListener) {
            this._google.maps.event.removeListener(this._dragEndListener);
        }
    }

    decodePolyline(encodedPath: string): number[][] {
        return this._google.maps.geometry.encoding.decodePath(encodedPath).map(latLng => [latLng.lng(), latLng.lat()]);
    }

    encodePolyline(decodedPath: number[][]): string {
        return this._google.maps.geometry.encoding.encodePath(
            decodedPath.map(e => new this._google.maps.LatLng(e[1], e[0]))
        );
    }

    onClick(cb: (latLng: { lat: number; lng: number }) => void): void {
        if (cb) {
            this._onClick = cb;

            if (this._map) {
                this._clickListener = this._map?.addListener('click', (e: google.maps.MapMouseEvent) => {
                    this._onClick?.({
                        lat: e.latLng?.lat() ?? 0,
                        lng: e.latLng?.lng() ?? 0
                    });
                });
            }
        } else {
            if (this._map) {
                this._google.maps.event.removeListener(this._clickListener!);
            }
            this._onClick = undefined;
        }
    }

    onRightClick(cb: (latLng: { lat: number; lng: number }) => void): void {
        if (cb) {
            this._onRightClick = cb;

            if (this._map) {
                this._clickListener = this._map?.addListener('rightclick', (e: google.maps.MapMouseEvent) => {
                    this._onRightClick?.({
                        lat: e.latLng?.lat() ?? 0,
                        lng: e.latLng?.lng() ?? 0
                    });
                });
            }
        } else {
            if (this._map) {
                this._google.maps.event.removeListener(this._clickListener!);
            }
            this._onRightClick = undefined;
        }
    }

    onDragEnd(cb: (latLng: { lat?: number; lng?: number }, simple: boolean) => void): void {
        this._onDragEnd = cb;
    }

    onControlCenterClick(cb: () => void) {
        this._onCenterControlClick = cb;
    }

    onJourneyGraphToggle(value: boolean) {
        this.journeyGraphVisible = value;
        const button = document.getElementById('journey-graph-control-toggler');
        if (this.journeyGraphVisible) {
            button?.classList.add('active');
        } else {
            button?.classList.remove('active');
        }

        this._onJourneyGraphToggle?.();
    }

    journeyGraphToggle(cb: (value: boolean) => void) {
        this._onJourneyGraphToggle = () => cb(this.journeyGraphVisible);
    }

    vehicles(): VehiclesMapController {
        if (!this._vehicles) {
            this._vehicles = new VehiclesMapController(this._conf, this._google, this, this._map);
        }
        return this._vehicles;
    }

    routing(): RoutingMapController {
        if (!this._routing) {
            this._routing = new RoutingMapController(this._conf, this._google, this, this._map);
        }
        return this._routing;
    }

    fuelStations(): FuelStationsMapController {
        if (!this._fuelStations) {
            this._fuelStations = new FuelStationsMapController(this._google, this, this._map);
        }
        return this._fuelStations;
    }

    parkings(): ParkingsMapController {
        if (!this._parkings) {
            this._parkings = new ParkingsMapController(this._conf, this._google, this, this._map);
        }
        return this._parkings;
    }

    washers(): WashersMapController {
        if (!this._washers) {
            this._washers = new WashersMapController(this._conf, this._google, this, this._map);
        }
        return this._washers;
    }

    pois(): PoisMapController {
        if (!this._pois) {
            this._pois = new PoisMapController(this._conf, this._google, this, this._map);
        }
        return this._pois;
    }

    poi(): PoiMapController {
        if (!this._poi) {
            this._poi = new PoiMapController(this._google, this._map);
        }
        return this._poi;
    }

    borderCrosses(): BorderCrossesMapController {
        if (!this._borderCrosses) {
            this._borderCrosses = new BorderCrossesMapController(this._google, this._map);
        }
        return this._borderCrosses;
    }

    polygons(): PolygonMapController {
        if (!this._polygons) {
            this._polygons = new PolygonMapController(this._google, this._map);
        }
        return this._polygons;
    }

    setCurrentSideBarControl(control: ControlPanel) {
        this._currentSideBarControl = control;
    }

    setPoiBarPossible(possible: boolean) {
        this._poiBarPossible = possible;
    }

    getPoiBarPossible() {
        return this._poiBarPossible;
    }

    layers(type: LayerTypes): void {
        // TODO:
        //  - google.maps.MapTypeId, doesn't work as argument...
        //  - working arguments: 'terrain', 'roadmap', 'hybrid'

        this._map?.setOptions({
            mapTypeId: type,
            styles: type === LayerTypes.HYBRID ? mapStyles.hybrid : mapStyles.default
        });
    }

    traffic(show: boolean): void {
        if (!this._traffic) {
            this._traffic = new this._google.maps.TrafficLayer();
        }

        if (show) {
            this._map && this._traffic && this._traffic.setMap(this._map);
        } else {
            this._map && this._traffic && this._traffic.setMap(null);
        }
    }

    setLoading(loading: boolean) {
        this.loading = loading;
        this._onLoading?.(loading);
    }

    setFuelLoading(loading: boolean) {
        this.fuelLoading = loading;
        this._onFuelLoading?.(loading);
    }

    setWashLoading(loading: boolean) {
        this.washLoading = loading;
        this._onWashLoading?.(loading);
    }

    setParkingLoading(loading: boolean) {
        this.parkingLoading = loading;
        this._onParkingLoading?.(loading);
    }

    setPoiLoading(loading: boolean) {
        this.poiLoading = loading;
        this._onPoiLoading?.(loading);
    }

    setControlsDisabled(loading: boolean, control?: ControlPanel) {
        if ((control === ControlPanel.PARKING || control === undefined) && this._parkingControl) {
            this._parkingControl.disabled = loading;
        }
        if ((control === ControlPanel.FUEL || control === undefined) && this._fuelControl) {
            this._fuelControl.disabled = loading;
        }
        if ((control === ControlPanel.WASH || control === undefined) && this._washControl) {
            this._washControl.disabled = loading;
        }
        if ((control === ControlPanel.POI || control === undefined) && this._poiControl) {
            this._poiControl.disabled = loading;
        }
    }

    sideBarControlsOff(fitBounds: boolean = false, control?: ControlPanel) {
        this._onControlsOff?.(control);

        if (!control || (control === ControlPanel.VEHICLE && control === this._currentSideBarControl)) {
            this._currentSideBarControl = undefined;
            this.setPadding({
                ...this._padding,
                right: this.initialPadding.right
            });
            if (fitBounds) {
                this.vehicles().fitVehicles();
                if (this.routing().haveRoutes()) {
                    this.routing().fitRoute();
                }
            }
            this._onFuelControlToggle?.(false);
            this._onLayerControlToggle?.(false);
            this._onParkingControlToggle?.(false);
            this._onWashControlToggle?.(false);
            this._onPoiControlToggle?.(false);
            this._showMapControls();
        }
    }

    sideBarControlsOffResetState() {
        this._onSideBarControlsOffResetState?.();
        this.setPadding({
            ...this._padding,
            right: this.initialPadding.right
        });
        this.sideBarControlsOff();
        this._fuelStations?.hide();
        this._parkings?.hide();
        this._washers?.hide();
        this._pois?.hide();
    }

    resetPois() {
        this._onResetPoisData?.();
    }

    fuelOn() {
        this._currentSideBarControl = ControlPanel.FUEL;
        this.setPadding({
            ...this._padding,
            right: this.initialPaddingWithRightComponent.right
        });
        this._onFuelControlToggle?.(true);
        this._hideMapControls();
    }

    layerOn() {
        this._currentSideBarControl = ControlPanel.LAYER;
        this.setPadding({
            ...this._padding,
            right: this.initialPaddingWithRightComponent.right
        });
        this._onLayerControlToggle?.(true);
        this._hideMapControls();
    }

    parkingOn() {
        this._currentSideBarControl = ControlPanel.PARKING;
        this.setPadding({
            ...this._padding,
            right: this.initialPaddingWithRightComponent.right
        });
        this._onParkingControlToggle?.(true);
        this._hideMapControls();
    }

    washOn() {
        this._currentSideBarControl = ControlPanel.WASH;
        this.setPadding({
            ...this._padding,
            right: this.initialPaddingWithRightComponent.right
        });
        this._onWashControlToggle?.(true);
        this._hideMapControls();
    }

    poisOn() {
        this._currentSideBarControl = ControlPanel.POI;
        this.setPadding({
            ...this._padding,
            right: this.initialPaddingWithRightComponent.right
        });
        this._onPoiControlToggle?.(true);
        this._hideMapControls();
    }

    vehicleOn() {
        this._currentSideBarControl = ControlPanel.VEHICLE;
        this.setPadding({
            ...this._padding,
            right: 380
        });
        this._onVehicleControlToggle?.(true);
        this._hideMapControls();
    }

    tableOn() {
        this.setPadding({
            ...this._padding,
            left: 790
        });
    }

    tableOff() {
        this.setPadding({
            ...this._padding,
            left: this.initialPadding.left
        });
    }

    poiOn() {
        this.sideBarControlsOff(false);
        this._onPoiToggle?.(true);
    }

    poiOff() {
        this._onPoiToggle?.(false);
        this.sideBarControlsOff();
    }

    vehicleCardData(cardData?: TrackingModel) {
        this._onVehicleCardData?.(cardData);
    }

    vehicleCardDataUpdate(cardData?: TrackingModel) {
        this._onVehicleCardDataUpdate?.(cardData);
    }

    onControlsOff(cb?: (control?: ControlPanel) => void) {
        this._onControlsOff = cb;
    }

    onControlsOffResetState(cb?: () => void) {
        this._onSideBarControlsOffResetState = cb;
    }

    onLoading(cb?: (value: boolean) => void) {
        this._onLoading = cb;
    }

    onFuelLoading(cb?: (value: boolean) => void) {
        this._onFuelLoading = cb;
    }

    onParkingLoading(cb?: (value: boolean) => void) {
        this._onParkingLoading = cb;
    }

    onWashLoading(cb?: (value: boolean) => void) {
        this._onWashLoading = cb;
    }

    onPoiLoading(cb?: (value: boolean) => void) {
        this._onPoiLoading = cb;
    }

    onFuelControlToggle(cb?: (value?: boolean) => void) {
        this._onFuelControlToggle = cb;
    }

    onParkingControlToggle(cb?: (value?: boolean) => void) {
        this._onParkingControlToggle = cb;
    }

    onLayerControlToggle(cb?: (value?: boolean) => void) {
        this._onLayerControlToggle = cb;
    }

    onWashControlToggle(cb?: (value?: boolean) => void) {
        this._onWashControlToggle = cb;
    }

    onPoiControlToggle(cb?: (value?: boolean) => void) {
        this._onPoiControlToggle = cb;
    }

    onPoiToggle(cb?: (value?: boolean) => void) {
        this._onPoiToggle = cb;
    }

    onVehicleControlToggle(cb?: (value?: boolean) => void) {
        this._onVehicleControlToggle = cb;
    }

    onVehicleCardData(cb?: (value?: TrackingModel) => void) {
        this._onVehicleCardData = cb;
    }

    onVehicleCardDataUpdate(cb?: (value?: TrackingModel) => void) {
        this._onVehicleCardDataUpdate = cb;
    }

    onRoutePlanning(cb?: (value?: 'edit' | 'preview') => void) {
        this._onRoutePlanning = cb;
    }

    onResetPoisData(cb: () => void) {
        this._onResetPoisData = cb;
    }

    routePlanningMode(value?: 'edit' | 'preview') {
        this._onRoutePlanning?.(value);
    }

    getMapBounds(): google.maps.LatLngBounds | undefined {
        return this._map?.getBounds() || undefined;
    }

    getCurrentMapPolygon(): GeoJsonPolygonTypeInput {
        const bounds = this._map!.getBounds();
        const nePoint = bounds?.getNorthEast();
        const swPoint = bounds?.getSouthWest();

        const ne = { lng: nePoint?.lng()!, lat: nePoint?.lat()! };
        const sw = { lng: swPoint?.lng()!, lat: swPoint?.lat()! };

        const coordinates: GeoJsonPolygonTypeInput['coordinates'] = [
            [
                [ne.lat, sw.lng],
                [ne.lat, ne.lng],
                [sw.lat, ne.lng],
                [sw.lat, sw.lng],
                [ne.lat, sw.lng]
            ]
        ];

        return {
            type: 'Polygon',
            coordinates
        };
    }

    getCenterBoundsWithDistance(
        google: google,
        gpsData: google.maps.LatLng,
        distanceInMeters = 2000
    ): google.maps.LatLngBounds {
        const bounds = new google.maps.LatLngBounds();
        const geometry = google.maps.geometry.spherical;

        bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 0));
        bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, -90));
        bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 180));
        bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 90));

        return bounds;
    }

    /**
     * Method returns points that fall within (Multi)Polygon(s) created from polyline based on turf's pointsWithinPolygon
     * @external https://turfjs.org/docs/#pointsWithinPolygon
     */
    pointsInPolygon(polygon: number[][][], pois: PoiModelMap[]): PoiModelMap[] {
        const points = turf.points(pois.map(e => [e.position.lng, e.position.lat]));
        const within = turf.pointsWithinPolygon(points, turf.polygon([polygon[0].map(e => [e[1], e[0]])]));

        const filteredPoints = pois.filter(p =>
            within.features.some(
                geoPoint =>
                    geoPoint.geometry?.coordinates[0] === p.position.lng &&
                    geoPoint.geometry?.coordinates[1] === p.position.lat
            )
        );

        return filteredPoints;
    }

    setPadding(padding: number | google.maps.Padding) {
        if (typeof padding === 'number')
            this._padding = {
                top: padding,
                bottom: padding,
                left: padding,
                right: padding
            };
        else {
            this._padding = padding as google.maps.Padding;
        }
    }

    getPadding() {
        return this._padding;
    }

    fitPosition(latLng: google.maps.LatLng, distance: number) {
        const bounds = this.getCenterBoundsWithDistance(this._google, latLng, distance);
        this._map?.fitBounds(bounds, this._padding);
    }

    /**
     * Reduce google bounds in percentage
     */
    reduceBounds(bounds: google.maps.LatLngBounds, percentage: number) {
        const north = bounds.getNorthEast().lat();
        const south = bounds.getSouthWest().lat();
        const east = bounds.getNorthEast().lng();
        const west = bounds.getSouthWest().lng();
        const lowerFactor = percentage / 2 / 100;
        const upperFactor = (100 - percentage / 2) / 100;
        return new this._google.maps.LatLngBounds(
            new this._google.maps.LatLng(south + (north - south) * lowerFactor, west + (east - west) * lowerFactor),
            new this._google.maps.LatLng(south + (north - south) * upperFactor, west + (east - west) * upperFactor)
        );
    }

    private _createControlFuel() {
        this._fuelControl = document.createElement('input');
        this._fuelControl.type = 'image';
        this._fuelControl.disabled = this.loading || !this._roles.includes(Role.FST_R);
        this._fuelControl.src = ControlPanelIcon.FUEL;

        this._fuelControl.className = 'map-control';
        this._fuelControl.setAttribute('data-qa', qa.map.fuelStationsButton);

        if (this._onFuelControlToggle) {
            this._fuelControl.addEventListener('click', () => {
                this.setPadding({
                    ...this._padding,
                    right: this.initialPaddingWithRightComponent.right
                });
                this._onFuelControlToggle?.(true);
                if (this._fuelControl?.classList.contains('map-control-hidden')) {
                    this._showMapControls();
                } else {
                    this._hideMapControls();
                }
            });
        }
        return this._fuelControl;
    }

    private _createControlParking() {
        this._parkingControl = document.createElement('input');
        this._parkingControl.type = 'input';
        this._parkingControl.value = 'P';
        this._parkingControl.disabled = this.loading || !this._roles.includes(Role.FST_R);
        this._parkingControl.className = 'map-control parking';
        this._parkingControl.setAttribute('data-qa', qa.map.parkingLotsButton);

        if (this._onParkingControlToggle) {
            this._parkingControl.addEventListener('click', () => {
                this.setPadding({
                    ...this._padding,
                    right: this.initialPaddingWithRightComponent.right
                });
                this._onParkingControlToggle?.(true);
                if (this._parkingControl?.classList.contains('map-control-hidden')) {
                    this._showMapControls();
                } else {
                    this._hideMapControls();
                }
            });
        }
        return this._parkingControl;
    }

    private _createControlLayers() {
        this._layerControl = document.createElement('input');

        this._layerControl.type = 'image';
        this._layerControl.src = ControlPanelIcon.LAYER;
        this._layerControl.className = 'map-control';
        this._layerControl.setAttribute('data-qa', qa.map.layersButton);

        if (this._onLayerControlToggle) {
            this._layerControl.addEventListener('click', () => {
                this.setPadding({
                    ...this._padding,
                    right: this.initialPaddingWithRightComponent.right
                });
                this._onLayerControlToggle?.(true);
                if (this._layerControl?.classList.contains('map-control-hidden')) {
                    this._showMapControls();
                } else {
                    this._hideMapControls();
                }
            });
        }
        return this._layerControl;
    }

    private _createControlWash() {
        this._washControl = document.createElement('input');

        this._washControl.type = 'image';
        this._washControl.src = ControlPanelIcon.WASH;
        this._washControl.className = 'map-control';
        this._washControl.setAttribute('data-qa', qa.map.btnWash);

        if (this._onWashControlToggle) {
            this._washControl.addEventListener('click', () => {
                this.setPadding({
                    ...this._padding,
                    right: this.initialPaddingWithRightComponent.right
                });
                this._onWashControlToggle?.(true);
                if (this._washControl?.classList.contains('map-control-hidden')) {
                    this._showMapControls();
                } else {
                    this._hideMapControls();
                }
            });
        }
        return this._washControl;
    }

    private _createControlPoi() {
        this._poiControl = document.createElement('input');

        this._poiControl.type = 'image';
        this._poiControl.src = ControlPanelIcon.POI;
        this._poiControl.className = 'map-control';
        this._poiControl.setAttribute('data-qa', qa.map.btnPoi);

        if (this._onPoiControlToggle) {
            this._poiControl.addEventListener('click', () => {
                this.setPadding({
                    ...this._padding,
                    right: this.initialPaddingWithRightComponent.right
                });
                this._onPoiControlToggle?.(true);
                if (this._poiControl?.classList.contains('map-control-hidden')) {
                    this._showMapControls();
                } else {
                    this._hideMapControls();
                }
            });
        }
        return this._poiControl;
    }

    private _hideMapControls() {
        this._fuelControl?.classList.add('map-control-hidden');
        this._parkingControl?.classList.add('map-control-hidden');
        this._washControl?.classList.add('map-control-hidden');
        this._poiControl?.classList.add('map-control-hidden');
        this._layerControl?.classList.add('map-control-hidden');
    }

    private _showMapControls() {
        this._fuelControl?.classList.remove('map-control-hidden');
        this._parkingControl?.classList.remove('map-control-hidden');
        this._washControl?.classList.remove('map-control-hidden');
        this._poiControl?.classList.remove('map-control-hidden');
        this._layerControl?.classList.remove('map-control-hidden');
    }

    private _createControlCenter() {
        this._centerControl = document.createElement('input');

        this._centerControl.type = 'image';
        this._centerControl.src = ControlPanelIcon.CENTER;
        this._centerControl.className = 'map-control map-center-control';
        this._centerControl.setAttribute('data-qa', qa.map.btnCenter);

        if (this._onCenterControlClick) {
            this._centerControl.addEventListener('click', () => {
                this._onCenterControlClick?.();
            });
        }
        this.setControlCenterVisible(false);
        return this._centerControl;
    }

    public setControlCenterVisible(visible: boolean = false) {
        if (visible) {
            this._centerControl?.classList.remove('t-hide');
        } else {
            this._centerControl?.classList.add('t-hide');
        }
    }
}
