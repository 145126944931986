import { PricePerKmResult, PricePerKmResultFromJSON } from 'generated/backend-api';
import { vehicles } from './vehicles';

const operational_cost_profile = [
    {
        monitoredObjectTypeId: 1,
        operational_cost_profile: {
            fuel: { currency: 'EUR', value: 0.25, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.15, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 600, unit: 'perMonth' },
            liability_insurance: { currency: 'EUR', value: 3.5, unit: 'perMonth' },
            collision_insurance: { currency: 'EUR', value: 3, unit: 'perMonth' },
            personal_injury_insurance: { currency: 'EUR', value: 2, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 150, unit: 'perMonth' }
        },
        computed: 1.36
    },
    {
        monitoredObjectTypeId: 8,
        operational_cost_profile: {
            fuel: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.03, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.05, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 300, unit: 'perYear' },
            liability_insurance: { currency: 'EUR', value: 400, unit: 'perYear' },
            collision_insurance: { currency: 'EUR', value: 600, unit: 'perYear' },
            personal_injury_insurance: { currency: 'EUR', value: 2, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 30, unit: 'perMonth' }
        },
        computed: 1.42
    }
];

const monitored_object_operational_cost_profile = [
    {
        monitoredObjectId: vehicles[0].id,
        operational_cost_profile: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)
            ?.operational_cost_profile,
        computed: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)?.computed
    },
    {
        monitoredObjectId: vehicles[1].id,
        operational_cost_profile: {
            fuel: { currency: 'EUR', value: 0.25, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.15, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 700, unit: 'perMonth' },
            liability_insurance: { currency: 'EUR', value: 3.5, unit: 'perMonth' },
            collision_insurance: { currency: 'EUR', value: 3, unit: 'perMonth' },
            personal_injury_insurance: { currency: 'EUR', value: 2, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 180, unit: 'perMonth' }
        },
        computed: 1.38
    },
    {
        monitoredObjectId: vehicles[2].id,
        operational_cost_profile: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)
            ?.operational_cost_profile,
        computed: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)?.computed
    },
    {
        monitoredObjectId: vehicles[3].id,
        operational_cost_profile: {
            fuel: { currency: 'EUR', value: 0.25, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.15, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 800, unit: 'perMonth' },
            liability_insurance: { currency: 'EUR', value: 3.5, unit: 'perMonth' },
            collision_insurance: { currency: 'EUR', value: 3, unit: 'perMonth' },
            personal_injury_insurance: { currency: 'EUR', value: 2, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 175, unit: 'perMonth' }
        },
        computed: 1.39
    },
    {
        monitoredObjectId: vehicles[4].id,
        operational_cost_profile: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)
            ?.operational_cost_profile,
        computed: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)?.computed
    },
    {
        monitoredObjectId: vehicles[5].id,
        operational_cost_profile: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)
            ?.operational_cost_profile,
        computed: operational_cost_profile.find(p => p.monitoredObjectTypeId === 1)?.computed
    },
    {
        monitoredObjectId: vehicles[6].id,
        operational_cost_profile: operational_cost_profile.find(p => p.monitoredObjectTypeId === 8)
            ?.operational_cost_profile,
        computed: operational_cost_profile.find(p => p.monitoredObjectTypeId === 8)?.computed
    }
];

export const costs: PricePerKmResult = PricePerKmResultFromJSON({
    market_avg_price_per_km: {
        currency: 'EUR',
        avg_price_per_km: 6.842011105986848,
        date: '2021-02-01T00:00:26.958000',
        number_of_companies: 3
    },
    default_market_avg_price_per_km: { currency: 'EUR', avg_price_per_km: 1.15 },
    default_market_avg_fixed_cost_profile: {
        salaries: { currency: 'EUR', value: 24851.0, unit: 'perMonth' },
        operations: { currency: 'EUR', value: 2580.0, unit: 'perMonth' },
        crm_insurance: { currency: 'EUR', value: 11596.0, unit: 'perMonth' },
        other: { currency: 'EUR', value: 3000.0, unit: 'perMonth' }
    },
    default_market_avg_price_per_km_of_fleet_types: [
        {
            currency: 'EUR',
            avg_price_per_km: 1.62,
            fleet_type: 'light_vehicle',
            count: 1
        },
        {
            currency: 'EUR',
            avg_price_per_km: 1.38,
            fleet_type: 'vehicle',
            count: 1
        }
    ],
    avg_price_per_km_of_fleet_types: [
        {
            currency: 'EUR',
            avg_price_per_km: 1.36,
            fleet_type: 'vehicle',
            count: 1
        },
        {
            currency: 'EUR',
            avg_price_per_km: 1.51,
            fleet_type: 'light_vehicle',
            count: 1
        }
    ],
    default_market_avg_operational_cost_profile_of_fleet_types: [
        {
            fuel: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.03, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.05, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 300.0, unit: 'perYear' },
            liability_insurance: { currency: 'EUR', value: 400.0, unit: 'perYear' },
            collision_insurance: { currency: 'EUR', value: 600.0, unit: 'perYear' },
            personal_injury_insurance: { currency: 'EUR', value: 2.0, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50.0, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50.0, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 30.0, unit: 'perMonth' },
            lng_cng: { currency: 'EUR', value: 0.24, unit: 'perKilometer' },
            electro: { currency: 'EUR', value: 0.009, unit: 'perKilometer' },
            fleet_type: 'light_vehicle'
        },
        {
            fuel: { currency: 'EUR', value: 0.25, unit: 'perKilometer' },
            additives: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            oil: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            tires: { currency: 'EUR', value: 0.15, unit: 'perKilometer' },
            maintenance: { currency: 'EUR', value: 600.0, unit: 'perMonth' },
            liability_insurance: { currency: 'EUR', value: 3.5, unit: 'perMonth' },
            collision_insurance: { currency: 'EUR', value: 3.0, unit: 'perMonth' },
            personal_injury_insurance: { currency: 'EUR', value: 2.0, unit: 'perMonth' },
            parking: { currency: 'EUR', value: 50.0, unit: 'perMonth' },
            cabin_heating: { currency: 'EUR', value: 0.1, unit: 'perKilometer' },
            telecomunications_and_monitoring: { currency: 'EUR', value: 50.0, unit: 'perMonth' },
            washing: { currency: 'EUR', value: 150.0, unit: 'perMonth' },
            lng_cng: { currency: 'EUR', value: 0.24, unit: 'perKilometer' },
            electro: { currency: 'EUR', value: 0.009, unit: 'perKilometer' },
            fleet_type: 'vehicle'
        }
    ],
    currency: 'EUR',
    avg_price_per_km: 1.36,
    fixed_cost_profile: {
        salaries: { currency: 'EUR', value: 100000.0, unit: 'perMonth' },
        operations: { currency: 'EUR', value: 200000.0, unit: 'perMonth' },
        crm_insurance: { currency: 'EUR', value: 0.0, unit: 'perKilometer' },
        other: { currency: 'EUR', value: 0.0, unit: 'perKilometer' }
    },
    mobjects: vehicles.map(v => {
        return {
            currency: 'EUR',
            avg_price_per_km: monitored_object_operational_cost_profile.find(p => p.monitoredObjectId === v.id)
                ?.computed,
            monitored_object_id: v.id,
            distance: -1.0,
            operational_cost_profile: monitored_object_operational_cost_profile.find(p => p.monitoredObjectId === v.id)
                ?.operational_cost_profile
        };
    })
});
