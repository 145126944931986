/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    ReadOnlyContactList,
    ReadOnlyContactListFromJSON,
    ReadOnlyContactListToJSON,
    ReadOnlyContactListCountrySerializer,
    ReadOnlyContactListCountrySerializerFromJSON,
    ReadOnlyContactListCountrySerializerToJSON,
    WriteOnlyContactList,
    WriteOnlyContactListFromJSON,
    WriteOnlyContactListToJSON,
} from '../models';

export interface ContactListCreateRequest {
    data: WriteOnlyContactList;
}

export interface ContactListDeleteRequest {
    id: string;
}

export interface ContactListFilterCountriesRequest {
    client?: string;
    textSearch?: string;
    type?: string;
    typeOverlap?: string;
    countryIn?: string;
}

export interface ContactListListRequest {
    client?: string;
    textSearch?: string;
    type?: Array<ContactListListTypeEnum>;
    typeOverlap?: Array<ContactListListTypeOverlapEnum>;
    countryIn?: Array<number>;
    limit?: number;
    offset?: number;
}

export interface ContactListPartialUpdateRequest {
    id: string;
    data: WriteOnlyContactList;
}

export interface ContactListReadRequest {
    id: string;
}

export interface ContactListUpdateRequest {
    id: string;
    data: WriteOnlyContactList;
}

/**
 * no description
 */
export class ContactListApi extends runtime.BaseAPI {

    /**
     */
    async contactListCreateRaw(requestParameters: ContactListCreateRequest): Promise<runtime.ApiResponse<ReadOnlyContactList>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactListCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactListFromJSON(jsonValue));
    }

    /**
     */
    async contactListCreate(requestParameters: ContactListCreateRequest): Promise<ReadOnlyContactList> {
        const response = await this.contactListCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListDeleteRaw(requestParameters: ContactListDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyContactList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactListDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactListFromJSON(jsonValue));
    }

    /**
     */
    async contactListDelete(requestParameters: ContactListDeleteRequest): Promise<ReadOnlyContactList> {
        const response = await this.contactListDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListFilterCountriesRaw(requestParameters: ContactListFilterCountriesRequest): Promise<runtime.ApiResponse<Array<ReadOnlyContactListCountrySerializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.textSearch !== undefined) {
            queryParameters['text_search'] = requestParameters.textSearch;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.typeOverlap !== undefined) {
            queryParameters['type_overlap'] = requestParameters.typeOverlap;
        }

        if (requestParameters.countryIn !== undefined) {
            queryParameters['country_in'] = requestParameters.countryIn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/filter-countries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyContactListCountrySerializerFromJSON));
    }

    /**
     */
    async contactListFilterCountries(requestParameters: ContactListFilterCountriesRequest): Promise<Array<ReadOnlyContactListCountrySerializer>> {
        const response = await this.contactListFilterCountriesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListListRaw(requestParameters: ContactListListRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.textSearch !== undefined) {
            queryParameters['text_search'] = requestParameters.textSearch;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.typeOverlap) {
            queryParameters['type_overlap'] = requestParameters.typeOverlap.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.countryIn) {
            queryParameters['country_in'] = requestParameters.countryIn.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     */
    async contactListList(requestParameters: ContactListListRequest): Promise<InlineResponse2003> {
        const response = await this.contactListListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListPartialUpdateRaw(requestParameters: ContactListPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyContactList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactListPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactListPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactListFromJSON(jsonValue));
    }

    /**
     */
    async contactListPartialUpdate(requestParameters: ContactListPartialUpdateRequest): Promise<ReadOnlyContactList> {
        const response = await this.contactListPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListReadRaw(requestParameters: ContactListReadRequest): Promise<runtime.ApiResponse<ReadOnlyContactList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactListRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactListFromJSON(jsonValue));
    }

    /**
     */
    async contactListRead(requestParameters: ContactListReadRequest): Promise<ReadOnlyContactList> {
        const response = await this.contactListReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactListUpdateRaw(requestParameters: ContactListUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyContactList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactListUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactListUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactListFromJSON(jsonValue));
    }

    /**
     */
    async contactListUpdate(requestParameters: ContactListUpdateRequest): Promise<ReadOnlyContactList> {
        const response = await this.contactListUpdateRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ContactListListTypeEnum {
    Client = 'client',
    Supplier = 'supplier',
    Consignee = 'consignee'
}
/**
    * @export
    * @enum {string}
    */
export enum ContactListListTypeOverlapEnum {
    Client = 'client',
    Supplier = 'supplier',
    Consignee = 'consignee'
}
