import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { Select, Radio } from 'common/components/Form';
import { Button } from 'common/components';
import { Row, Col } from 'antd';
import qa from 'qa-selectors';
import { RadioChangeEvent } from 'antd/lib/radio';
import { VehicleDelayedFilterCode, VehicleStatusFilterCode } from '../../../../common/model/tracking';
import { searched } from 'common/utils/search';

export type FilterOpts = {
    delay: VehicleDelayedFilterCode;
    status: VehicleStatusFilterCode;
    monitoredObjectGroupsChecked: string[];
};

interface Props extends WithTranslation {
    expanded?: boolean;
    delayFilter: VehicleDelayedFilterCode;
    statusFilter: VehicleStatusFilterCode;
    monitoredObjectGroupsChecked: string[];
    monitoredObjectGroupsOpts: CheckboxGroupOpts;
    isFilterLoading: boolean;
    onCancel?: () => void;
    onConfirm?: (filter: FilterOpts) => void;
    qa?: string;
}

interface State {
    delay: VehicleDelayedFilterCode;
    status: VehicleStatusFilterCode;
    monitoredObjectGroupsChecked: string[];
}

class TrackingFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            delay: props.delayFilter,
            status: props.statusFilter,
            monitoredObjectGroupsChecked: props.monitoredObjectGroupsChecked
        };
    }

    render() {
        const { t } = this.props;
        const allowedDelayedFilterCodes: VehicleDelayedFilterCode[] = [
            VehicleDelayedFilterCode.ALL_ETAS,
            VehicleDelayedFilterCode.LESS_THAN_30_MINUTES_LATE,
            VehicleDelayedFilterCode.MORE_THAN_30_MINUTES_LATE,
            VehicleDelayedFilterCode.ON_TIME,
            VehicleDelayedFilterCode.RTA_NOT_ENTERED
        ];

        return (
            <div className="tm-tracking-filter">
                <Row gutter={18}>
                    <Col>
                        <label className="tm-tracking-filter-label">{t(`TrackingTable.eta`)}</label>
                        <Radio.Group onChange={this._onFilterETAChange} value={this.state.delay}>
                            {Object.keys(VehicleDelayedFilterCode).map(
                                key =>
                                    allowedDelayedFilterCodes.includes(VehicleDelayedFilterCode[key]) && (
                                        <Radio
                                            qa={`tracking-bar--filter--${VehicleDelayedFilterCode[key]}`}
                                            value={VehicleDelayedFilterCode[key]}
                                            key={key}
                                        >
                                            {t(`TrackingFilter.${VehicleDelayedFilterCode[key]}`)}
                                        </Radio>
                                    )
                            )}
                        </Radio.Group>
                    </Col>
                    <Col>
                        <label className="tm-tracking-filter-label">{t(`TrackingTable.status`)}</label>

                        <Radio.Group onChange={this._onFilterStateChange} value={this.state.status}>
                            {Object.keys(VehicleStatusFilterCode).map(key => (
                                <Radio
                                    value={VehicleStatusFilterCode[key]}
                                    key={key}
                                    qa={`tracking-bar--filter--${VehicleStatusFilterCode[key]}`}
                                >
                                    {t(`TrackingFilter.${VehicleStatusFilterCode[key]}`)}
                                </Radio>
                            ))}
                        </Radio.Group>
                        <label className="tm-tracking-filter-label">{t(`common.vehicleGroups`)}</label>
                        <Select
                            mode="multiple"
                            defaultValue={this.state.monitoredObjectGroupsChecked}
                            onChange={this._onTagOfInputSelectorChange}
                            filterOption={(input, option) => searched(input, String(option?.children))}
                            options={this.props.monitoredObjectGroupsOpts.map(d => ({
                                value: d.code,
                                label: d.label
                            }))}
                        />
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: '16px' }}>
                    <Col xs={12}>
                        <Button
                            block
                            type="default"
                            onClick={this._onCancelClick}
                            qa={qa.trackingBar.filter.CancelButton}
                        >
                            {t('common.cancel')}
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button
                            block
                            type="primary"
                            onClick={this._onConfirmClick}
                            loading={this.props.isFilterLoading}
                            qa={qa.trackingBar.filter.ConfirmButton}
                        >
                            {t('common.confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private _onFilterETAChange = (e: RadioChangeEvent): void => {
        const delay = e.target.value as VehicleDelayedFilterCode;
        this.setState({ delay });
    };

    private _onFilterStateChange = (e: RadioChangeEvent): void => {
        const status = e.target.value as VehicleStatusFilterCode;
        this.setState({ status });
    };

    private _onTagOfInputSelectorChange = (tagsChecked: string[]): void => {
        this.setState({ monitoredObjectGroupsChecked: tagsChecked });
    };

    private _onConfirmClick = (): void => {
        const { delay, status, monitoredObjectGroupsChecked: tagsChecked } = this.state;
        this.props.onConfirm?.({ delay, status, monitoredObjectGroupsChecked: tagsChecked });
    };

    private _onCancelClick = (): void => {
        this.props.onCancel?.();
    };
}

export default withTranslation()(TrackingFilter);
