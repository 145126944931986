import { TrackingModel } from 'common/model/tracking';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';

import { TransportModel } from 'common/model/transports';
import {
    DataSourceTypes,
    Displayed,
    Fuels,
    LayerTypes,
    ParkingControlsForm,
    Services,
    FuelControlsForm
} from '../MapModule';
import ControlsFuel from './ControlsFuel';
import ControlsLayer from './ControlsLayer';
import { ControlPanel } from './MapControlsBar';
import ControlsParking from './ControlsParking';
import ControlsVehicleCard from './ControlsVehicleCard/ControlsVehicleCard';
import { Role } from 'logic/auth';
import ControlsPoi from './ControlsPoi';
import ControlsWash from './ControlsWash';

interface Props {
    vehicle?: TrackingModel;
    transport?: TransportModel;
    toggle: {
        contextMenu?: boolean;
    };
    barMenu: {
        advanced?: boolean;
        panel: ControlPanel;
    };
    fuel: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        fuelData: Fuels;
        fuelStations: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    parking: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        parkings: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    poi: {
        optionsData: Displayed;
        pois: PoiModelMap[];
        loading: boolean;
    };
    wash: {
        optionsData: Displayed;
        washers: PoiModelMap[];
        loading: boolean;
    };
    controlOnRoute?: 'edit' | 'preview';
    roles: Role[];

    layers: {
        display: LayerTypes;
        traffic: boolean;
        borderCrosses: boolean;
    };
    contextMenu: {
        position?: {
            x: number;
            y: number;
        };
    };
    onFuelConfirmClick?: (form: FuelControlsForm) => void;
    onFuelPoiCardClick?: (poi: PoiModelMap) => void;
    onFuelCancelClick?: () => void;
    onFuelFilterClick?: () => void;
    onParkingConfirmClick?: (form: ParkingControlsForm) => void;
    onParkingPoiCardClick?: (poi: PoiModelMap) => void;
    onParkingCancelClick?: () => void;
    onParkingFilterClick?: () => void;
    onPoiConfirmClick?: () => void;
    onPoiCardClick?: (poi: PoiModelMap) => void;
    onWashConfirmClick?: () => void;
    onWashCardClick?: (poi: PoiModelMap) => void;
    onLayersTypeClick?: (type: LayerTypes) => void;
    onLayersTrafficChange?: (checked: boolean) => void;
    onLayersBorderCrossesChange?: (checked: boolean) => void;
    onAddToTransportClick?: (data: PoiMarkerData) => void;
    onSideBarViewChange?: (panel: ControlPanel) => void;
    onRedirectToJourneyActivityClick?: () => void;
    onVehicleCardRedirectToDispatcherBoardClick?: () => void;
    onVehicleCardRedirectToDriverBehavior?: () => void;
    onVehicleCardRedirectToDriverBehaviorLightVehicles?: () => void;
    onVehicleCardRedirectToAetrReportClick?: (driverId?: string) => void;

    onVehicleCardShowRealVsPlannedRouteClick?: () => void;
    onVehicleCardAddDestinationClick?: () => void;
    onVehicleCardTemperateSensorClick?: (monitoredObjectId: string, sensorId: string) => void;
    onFuelEditServicesFilter?: (filter: Services) => void;
    onFuelEditFuelTypesFilter?: (filter: Fuels) => void;
    onVehicleClusteringSwitchClick?: (checked: boolean) => void;
}

export default function Controls(props: Props) {
    const simple = [
        ControlPanel.FUEL,
        ControlPanel.LAYER,
        ControlPanel.PARKING,
        ControlPanel.POI,
        ControlPanel.WASH
    ].some(c => c === props.barMenu?.panel);

    return (
        <>
            {props.vehicle && (
                <ControlsVehicleCard
                    data={props.vehicle}
                    panelType={props.barMenu.panel}
                    transport={props.transport}
                    simpleVehicleView={simple}
                    roles={props.roles}
                    onNavigateToJourney={props.onRedirectToJourneyActivityClick}
                    onNavigateToDispatcherBoard={props.onVehicleCardRedirectToDispatcherBoardClick}
                    onNavigateToDriverBehavior={props.onVehicleCardRedirectToDriverBehavior}
                    onNavigateToDriverBehaviorLightVehicles={props.onVehicleCardRedirectToDriverBehaviorLightVehicles}
                    onNavigateToAetrReport={props.onVehicleCardRedirectToAetrReportClick}
                    onShowRealVsPlannedRouteOnMap={props.onVehicleCardShowRealVsPlannedRouteClick}
                    onTemperatureSensorClick={props.onVehicleCardTemperateSensorClick}
                    onAddDestination={props.onVehicleCardAddDestinationClick}
                />
            )}

            {ControlPanel.FUEL === props.barMenu?.panel && (
                <ControlsFuel
                    data={props.fuel}
                    controlOnRoute={props.controlOnRoute}
                    onPoiCardClick={props.onFuelPoiCardClick}
                    onFilterClick={props.onFuelFilterClick}
                    onCancelClick={props.onFuelCancelClick}
                    onConfirmClick={props.onFuelConfirmClick}
                    onAddToTransportClick={props.onAddToTransportClick}
                    onEditFuelFilter={props.onFuelEditFuelTypesFilter}
                    onEditServicesFilter={props.onFuelEditServicesFilter}
                />
            )}

            {ControlPanel.PARKING === props.barMenu?.panel && (
                <ControlsParking
                    data={props.parking}
                    controlOnRoute={props.controlOnRoute}
                    onPoiCardClick={props.onParkingPoiCardClick}
                    onFilterClick={props.onParkingFilterClick}
                    onCancelClick={props.onParkingCancelClick}
                    onConfirmClick={props.onParkingConfirmClick}
                    onAddToTransportClick={props.onAddToTransportClick}
                />
            )}

            {ControlPanel.WASH === props.barMenu?.panel && (
                <ControlsWash
                    data={props.wash}
                    controlOnRoute={props.controlOnRoute}
                    onWashCardClick={props.onWashCardClick}
                    onBarLabel={props.onSideBarViewChange}
                    onConfirmClick={props.onWashConfirmClick}
                    onAddToTransportClick={props.onAddToTransportClick}
                />
            )}

            {ControlPanel.POI === props.barMenu?.panel && (
                <ControlsPoi
                    data={props.poi}
                    controlOnRoute={props.controlOnRoute}
                    onPoiCardClick={props.onPoiCardClick}
                    onBarLabel={props.onSideBarViewChange}
                    onConfirmClick={props.onPoiConfirmClick}
                    onAddToTransportClick={props.onAddToTransportClick}
                />
            )}

            {ControlPanel.LAYER === props.barMenu?.panel && (
                <ControlsLayer
                    data={props.layers}
                    onLayerClick={props.onLayersTypeClick}
                    onTrafficChange={props.onLayersTrafficChange}
                    onBorderCrossesChange={props.onLayersBorderCrossesChange}
                    onBarLabel={props.onSideBarViewChange}
                    onVehicleClusteringSwitchClick={props.onVehicleClusteringSwitchClick}
                />
            )}
        </>
    );
}
