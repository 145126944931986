/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AvailableMobjectTypes,
    AvailableMobjectTypesFromJSON,
    AvailableMobjectTypesToJSON,
    AvailableMonitoredObjects,
    AvailableMonitoredObjectsFromJSON,
    AvailableMonitoredObjectsToJSON,
    BodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost,
    BodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostFromJSON,
    BodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostToJSON,
    BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost,
    BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostFromJSON,
    BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostToJSON,
    ColdChainProfileActiveEventRule,
    ColdChainProfileActiveEventRuleFromJSON,
    ColdChainProfileActiveEventRuleToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeToJSON,
    TaskMediaPayload,
    TaskMediaPayloadFromJSON,
    TaskMediaPayloadToJSON,
    Transport,
    TransportFromJSON,
    TransportToJSON,
    TransportMonitoringEntryDriver,
    TransportMonitoringEntryDriverFromJSON,
    TransportMonitoringEntryDriverToJSON,
    TransportPlace,
    TransportPlaceFromJSON,
    TransportPlaceToJSON,
    TransportPlaceTask,
    TransportPlaceTaskFromJSON,
    TransportPlaceTaskToJSON,
    TransportState,
    TransportStateFromJSON,
    TransportStateToJSON,
} from '../models';

export interface AddPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPostRequest {
    transportId: string;
    placeId: string;
    taskId: string;
    taskMediaPayload: TaskMediaPayload;
}

export interface AttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostRequest {
    transportId: string;
    monitoredObjectType?: MonitoredObjectFleetType;
    bodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost?: BodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost;
}

export interface AvailableForTransportV1TransportsAvailableMonitoredObjectsGetRequest {
    types: AvailableMobjectTypes;
    startTime?: Date;
    firstRta?: Date;
    lastRta?: Date;
}

export interface CreateV1TransportsPostRequest {
    transport: Transport;
    firstDestinationAta?: Date;
    upsertTemplate?: boolean;
    clientId?: number;
}

export interface DetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostRequest {
    transportId: string;
    monitoredObjectType?: MonitoredObjectFleetType;
    bodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost?: BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost;
}

export interface FindOneV1TransportsTransportIdGetRequest {
    transportId: string;
    clientId?: number;
}

export interface FindV1TransportsGetRequest {
    fromDate?: Date;
    toDate?: Date;
    drivers?: Array<number>;
    subscribeDevice?: string;
    subscribeUser?: string;
    subscribeChanges?: boolean;
    lastUpdated?: Date;
    states?: Array<TransportState>;
    excludeFields?: Array<string>;
    onlyCurrentVehicles?: boolean;
    clientId?: number;
    vehicles?: Array<number>;
    monitoredObjectId?: string;
}

export interface PlanV1TransportsPlanPostRequest {
    transport: Transport;
    clientId?: number;
}

export interface ResolveV1TransportsTransportIdPlacePlaceIdResolvePatchRequest {
    transportId: string;
    placeId: string;
    resolvedByDriverAt: Date;
}

export interface SetAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatchRequest {
    transportId: string;
    placeId: string;
    ata: Date;
}

export interface TransportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGetRequest {
    tsfrom: Date;
    tsto: Date;
    monitoredObjectId: string;
    clientId?: number;
}

export interface TransportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGetRequest {
    transportId: string;
}

export interface UpdatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatchRequest {
    transportId: string;
    placeId: string;
    taskId: string;
    transportPlaceTask: TransportPlaceTask;
}

export interface UpdatePlaceV1TransportsTransportIdPlacePlaceIdPatchRequest {
    transportId: string;
    placeId: string;
    transportPlace: TransportPlace;
}

export interface UpdateV1TransportsTransportIdPatchRequest {
    transportId: string;
    transport: Transport;
    upsertTemplate?: boolean;
    clientId?: number;
}

/**
 * no description
 */
export class TransportsApi extends runtime.BaseAPI {

    /**
     * Updates transport place task partial data
     * Add Place Task Media Payload
     */
    async addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPostRaw(requestParameters: AddPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPostRequest): Promise<runtime.ApiResponse<TaskMediaPayload>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPost.');
        }

        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPost.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPost.');
        }

        if (requestParameters.taskMediaPayload === null || requestParameters.taskMediaPayload === undefined) {
            throw new runtime.RequiredError('taskMediaPayload','Required parameter requestParameters.taskMediaPayload was null or undefined when calling addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/place/{place_id}/task/{task_id}/mediapayload`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))).replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.placeId))).replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskMediaPayloadToJSON(requestParameters.taskMediaPayload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskMediaPayloadFromJSON(jsonValue));
    }

    /**
     * Updates transport place task partial data
     * Add Place Task Media Payload
     */
    async addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPost(requestParameters: AddPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPostRequest): Promise<TaskMediaPayload> {
        const response = await this.addPlaceTaskMediaPayloadV1TransportsTransportIdPlacePlaceIdTaskTaskIdMediapayloadPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Attach monitored object to transport
     * Attach Monitored Object
     */
    async attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostRaw(requestParameters: AttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectType !== undefined) {
            queryParameters['monitored_object_type'] = requestParameters.monitoredObjectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/attach-monitored-object`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostToJSON(requestParameters.bodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Attach monitored object to transport
     * Attach Monitored Object
     */
    async attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost(requestParameters: AttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostRequest): Promise<Transport> {
        const response = await this.attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Available For Transport
     */
    async availableForTransportV1TransportsAvailableMonitoredObjectsGetRaw(requestParameters: AvailableForTransportV1TransportsAvailableMonitoredObjectsGetRequest): Promise<runtime.ApiResponse<AvailableMonitoredObjects>> {
        if (requestParameters.types === null || requestParameters.types === undefined) {
            throw new runtime.RequiredError('types','Required parameter requestParameters.types was null or undefined when calling availableForTransportV1TransportsAvailableMonitoredObjectsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.types !== undefined) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.firstRta !== undefined) {
            queryParameters['first_rta'] = (requestParameters.firstRta as any).toISOString();
        }

        if (requestParameters.lastRta !== undefined) {
            queryParameters['last_rta'] = (requestParameters.lastRta as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/available-monitored-objects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableMonitoredObjectsFromJSON(jsonValue));
    }

    /**
     * Available For Transport
     */
    async availableForTransportV1TransportsAvailableMonitoredObjectsGet(requestParameters: AvailableForTransportV1TransportsAvailableMonitoredObjectsGetRequest): Promise<AvailableMonitoredObjects> {
        const response = await this.availableForTransportV1TransportsAvailableMonitoredObjectsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create
     */
    async createV1TransportsPostRaw(requestParameters: CreateV1TransportsPostRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transport === null || requestParameters.transport === undefined) {
            throw new runtime.RequiredError('transport','Required parameter requestParameters.transport was null or undefined when calling createV1TransportsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.firstDestinationAta !== undefined) {
            queryParameters['first_destination_ata'] = (requestParameters.firstDestinationAta as any).toISOString();
        }

        if (requestParameters.upsertTemplate !== undefined) {
            queryParameters['upsert_template'] = requestParameters.upsertTemplate;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransportToJSON(requestParameters.transport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Create
     */
    async createV1TransportsPost(requestParameters: CreateV1TransportsPostRequest): Promise<Transport> {
        const response = await this.createV1TransportsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * detach monitored object to transport
     * Detach Monitored Object
     */
    async detachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostRaw(requestParameters: DetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling detachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectType !== undefined) {
            queryParameters['monitored_object_type'] = requestParameters.monitoredObjectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/detach-monitored-object`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostToJSON(requestParameters.bodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * detach monitored object to transport
     * Detach Monitored Object
     */
    async detachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost(requestParameters: DetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostRequest): Promise<Transport> {
        const response = await this.detachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get transport by id
     * Find One
     */
    async findOneV1TransportsTransportIdGetRaw(requestParameters: FindOneV1TransportsTransportIdGetRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling findOneV1TransportsTransportIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Get transport by id
     * Find One
     */
    async findOneV1TransportsTransportIdGet(requestParameters: FindOneV1TransportsTransportIdGetRequest): Promise<Transport> {
        const response = await this.findOneV1TransportsTransportIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find
     */
    async findV1TransportsGetRaw(requestParameters: FindV1TransportsGetRequest): Promise<runtime.ApiResponse<Array<Transport>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['from_date'] = (requestParameters.fromDate as any).toISOString();
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['to_date'] = (requestParameters.toDate as any).toISOString();
        }

        if (requestParameters.drivers) {
            queryParameters['drivers'] = requestParameters.drivers;
        }

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        if (requestParameters.subscribeChanges !== undefined) {
            queryParameters['subscribe_changes'] = requestParameters.subscribeChanges;
        }

        if (requestParameters.lastUpdated !== undefined) {
            queryParameters['last_updated'] = (requestParameters.lastUpdated as any).toISOString();
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.excludeFields) {
            queryParameters['exclude_fields'] = requestParameters.excludeFields;
        }

        if (requestParameters.onlyCurrentVehicles !== undefined) {
            queryParameters['only_current_vehicles'] = requestParameters.onlyCurrentVehicles;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.vehicles) {
            queryParameters['vehicles'] = requestParameters.vehicles;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransportFromJSON));
    }

    /**
     * Find
     */
    async findV1TransportsGet(requestParameters: FindV1TransportsGetRequest): Promise<Array<Transport>> {
        const response = await this.findV1TransportsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Plan
     */
    async planV1TransportsPlanPostRaw(requestParameters: PlanV1TransportsPlanPostRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transport === null || requestParameters.transport === undefined) {
            throw new runtime.RequiredError('transport','Required parameter requestParameters.transport was null or undefined when calling planV1TransportsPlanPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransportToJSON(requestParameters.transport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Plan
     */
    async planV1TransportsPlanPost(requestParameters: PlanV1TransportsPlanPostRequest): Promise<Transport> {
        const response = await this.planV1TransportsPlanPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates transport place resolved_by_driver_at - used only by driver app
     * Resolve
     */
    async resolveV1TransportsTransportIdPlacePlaceIdResolvePatchRaw(requestParameters: ResolveV1TransportsTransportIdPlacePlaceIdResolvePatchRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling resolveV1TransportsTransportIdPlacePlaceIdResolvePatch.');
        }

        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling resolveV1TransportsTransportIdPlacePlaceIdResolvePatch.');
        }

        if (requestParameters.resolvedByDriverAt === null || requestParameters.resolvedByDriverAt === undefined) {
            throw new runtime.RequiredError('resolvedByDriverAt','Required parameter requestParameters.resolvedByDriverAt was null or undefined when calling resolveV1TransportsTransportIdPlacePlaceIdResolvePatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.resolvedByDriverAt !== undefined) {
            queryParameters['resolved_by_driver_at'] = (requestParameters.resolvedByDriverAt as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/place/{place_id}/resolve`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))).replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Updates transport place resolved_by_driver_at - used only by driver app
     * Resolve
     */
    async resolveV1TransportsTransportIdPlacePlaceIdResolvePatch(requestParameters: ResolveV1TransportsTransportIdPlacePlaceIdResolvePatchRequest): Promise<Transport> {
        const response = await this.resolveV1TransportsTransportIdPlacePlaceIdResolvePatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates transport ata atd - can be used to mark skipped places as visited
     * Set Ata Atd
     */
    async setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatchRaw(requestParameters: SetAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatchRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatch.');
        }

        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatch.');
        }

        if (requestParameters.ata === null || requestParameters.ata === undefined) {
            throw new runtime.RequiredError('ata','Required parameter requestParameters.ata was null or undefined when calling setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ata !== undefined) {
            queryParameters['ata'] = (requestParameters.ata as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/place/{place_id}/set_ata`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))).replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Updates transport ata atd - can be used to mark skipped places as visited
     * Set Ata Atd
     */
    async setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatch(requestParameters: SetAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatchRequest): Promise<Transport> {
        const response = await this.setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns transport event cold chain profile rules active during a timespan for this vehicle
     * Transport Monitoring Entries Event Rules Cold Chain Profile
     */
    async transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGetRaw(requestParameters: TransportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGetRequest): Promise<runtime.ApiResponse<Array<ColdChainProfileActiveEventRule>>> {
        if (requestParameters.tsfrom === null || requestParameters.tsfrom === undefined) {
            throw new runtime.RequiredError('tsfrom','Required parameter requestParameters.tsfrom was null or undefined when calling transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGet.');
        }

        if (requestParameters.tsto === null || requestParameters.tsto === undefined) {
            throw new runtime.RequiredError('tsto','Required parameter requestParameters.tsto was null or undefined when calling transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGet.');
        }

        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tsfrom !== undefined) {
            queryParameters['tsfrom'] = (requestParameters.tsfrom as any).toISOString();
        }

        if (requestParameters.tsto !== undefined) {
            queryParameters['tsto'] = (requestParameters.tsto as any).toISOString();
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/transport-monitoring-entry/event-rules/cold-chain-profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ColdChainProfileActiveEventRuleFromJSON));
    }

    /**
     * Returns transport event cold chain profile rules active during a timespan for this vehicle
     * Transport Monitoring Entries Event Rules Cold Chain Profile
     */
    async transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGet(requestParameters: TransportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGetRequest): Promise<Array<ColdChainProfileActiveEventRule>> {
        const response = await this.transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Transport Monitoring Entry Drivers
     */
    async transportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGetRaw(requestParameters: TransportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGetRequest): Promise<runtime.ApiResponse<Array<TransportMonitoringEntryDriver>>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling transportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/transports/{transport_id}/transport-monitoring-entry/drivers`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransportMonitoringEntryDriverFromJSON));
    }

    /**
     * Transport Monitoring Entry Drivers
     */
    async transportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGet(requestParameters: TransportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGetRequest): Promise<Array<TransportMonitoringEntryDriver>> {
        const response = await this.transportMonitoringEntryDriversV1TransportsTransportIdTransportMonitoringEntryDriversGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates transport place task partial data
     * Update Place Task
     */
    async updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatchRaw(requestParameters: UpdatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatchRequest): Promise<runtime.ApiResponse<TransportPlaceTask>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatch.');
        }

        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatch.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatch.');
        }

        if (requestParameters.transportPlaceTask === null || requestParameters.transportPlaceTask === undefined) {
            throw new runtime.RequiredError('transportPlaceTask','Required parameter requestParameters.transportPlaceTask was null or undefined when calling updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/place/{place_id}/task/{task_id}`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))).replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.placeId))).replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TransportPlaceTaskToJSON(requestParameters.transportPlaceTask),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportPlaceTaskFromJSON(jsonValue));
    }

    /**
     * Updates transport place task partial data
     * Update Place Task
     */
    async updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatch(requestParameters: UpdatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatchRequest): Promise<TransportPlaceTask> {
        const response = await this.updatePlaceTaskV1TransportsTransportIdPlacePlaceIdTaskTaskIdPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates transport place partial data
     * Update Place
     */
    async updatePlaceV1TransportsTransportIdPlacePlaceIdPatchRaw(requestParameters: UpdatePlaceV1TransportsTransportIdPlacePlaceIdPatchRequest): Promise<runtime.ApiResponse<TransportPlace>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling updatePlaceV1TransportsTransportIdPlacePlaceIdPatch.');
        }

        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling updatePlaceV1TransportsTransportIdPlacePlaceIdPatch.');
        }

        if (requestParameters.transportPlace === null || requestParameters.transportPlace === undefined) {
            throw new runtime.RequiredError('transportPlace','Required parameter requestParameters.transportPlace was null or undefined when calling updatePlaceV1TransportsTransportIdPlacePlaceIdPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}/place/{place_id}`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))).replace(`{${"place_id"}}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TransportPlaceToJSON(requestParameters.transportPlace),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportPlaceFromJSON(jsonValue));
    }

    /**
     * Updates transport place partial data
     * Update Place
     */
    async updatePlaceV1TransportsTransportIdPlacePlaceIdPatch(requestParameters: UpdatePlaceV1TransportsTransportIdPlacePlaceIdPatchRequest): Promise<TransportPlace> {
        const response = await this.updatePlaceV1TransportsTransportIdPlacePlaceIdPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates transport
     * Update
     */
    async updateV1TransportsTransportIdPatchRaw(requestParameters: UpdateV1TransportsTransportIdPatchRequest): Promise<runtime.ApiResponse<Transport>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling updateV1TransportsTransportIdPatch.');
        }

        if (requestParameters.transport === null || requestParameters.transport === undefined) {
            throw new runtime.RequiredError('transport','Required parameter requestParameters.transport was null or undefined when calling updateV1TransportsTransportIdPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.upsertTemplate !== undefined) {
            queryParameters['upsert_template'] = requestParameters.upsertTemplate;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/{transport_id}`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TransportToJSON(requestParameters.transport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportFromJSON(jsonValue));
    }

    /**
     * Updates transport
     * Update
     */
    async updateV1TransportsTransportIdPatch(requestParameters: UpdateV1TransportsTransportIdPatchRequest): Promise<Transport> {
        const response = await this.updateV1TransportsTransportIdPatchRaw(requestParameters);
        return await response.value();
    }

}
