import { ExpensesModel as BaseExpensesModel } from 'modules/statistics/modules/expenses/ui/ExpensesTable';
import i18n from 'i18next';
import {
    ExpenseInput,
    UpsertExpenseDocument,
    UpsertExpenseMutation,
    UpsertExpenseMutationVariables,
    MutationDelete_ExpenseArgs,
    Delete_ExpenseMutation,
    Delete_ExpenseDocument,
    Expense,
    Import_ExpenseMutation,
    Import_ExpenseMutationVariables,
    Import_ExpenseDocument,
    ExpenseSource,
    ExpenseType,
    Get_GroupedExpensesQuery,
    Get_GroupedExpensesQueryVariables,
    Get_GroupedExpensesDocument,
    ExpenseAggregator
} from 'generated/graphql';
import { Logic } from '../logic';
import moment from 'moment';
import { DriverModel } from '../user/users';
import { L, PC, DATE_FORMAT, KM } from 'domain-constants';
import { DateRange } from 'common/model/date-time';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { AvailableCurrencies } from 'common/model/currency';
import numeral from 'numeral';

export enum FraudDetectionState {
    // locationCode not found by places api
    UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
    // no gps data available in small thresholds,
    // but newer gps found than
    // exp. time + small treshold and older than exp. time + small + extra
    UNAVAILABLE_GPS_DATA = 'UNAVAILABLE_GPS_DATA',
    // other
    UNKNOWN = 'UNKNOWN',
    // all data available and no fraud detected - everything looks good
    NO_FRAUD_DETECTED = 'NO_FRAUD_DETECTED',
    // all data available and fraud detected
    FRAUD_DETECTED = 'FRAUD_DETECTED',

    // we should  check these records in the future
    SHOULD_BE_CHECKED_LATER = 'SHOULD_BE_CHECKED_LATER'
}

export interface ExpenseModel {
    id: string;
    date: string;
    monitoredObjectId: number;
    RN: string;
    driver: string;
    userId: string;
    type?: ExpenseType;
    supplier: string;
    cargoCountry: string;
    cargoCurrency: string;
    cargoVAT: number;
    quantityTotal: number;
    quantityUnit: string;
    pricePerUnitWithoutVAT: number;
    pricePerUnitWithVAT: number;
    pricePerUnitWithoutVATAverage?: number;
    pricePerUnitWithVATAverage?: number;
    priceTotalWithoutVAT: number;
    priceTotalWithVAT: number;
    fuelioTotemPrice: number;
    fuelioTotemPriceCurrency: AvailableCurrencies;
    saved: number;
    savedCurrency?: AvailableCurrencies;
    odo: number;
    source?: ExpenseSource;
    state?: ExpenseState;
    fraudDetection: FraudDetectionState;
    note: string;
    fuelStation: {
        id: string;
        name: string;
    };
    rowId?: string;
}

export interface CargoExpense {
    expenseId?: string;
    vehicleId: number;
    date: string;
    type: ExpenseType;
    supplier?: string;
    quantity: string;
    priceTotal: string;
    country?: string;
    fuelStation?: {
        id: string;
        name: string;
    };
}
export interface ExpenseFilter {
    vehicles?: string[];
    dateRange?: {
        start?: string;
        end?: string;
    };
    driverId?: number;
    trailerId?: number;
    type?: ExpenseType[];
    source?: ExpenseSource;
    payment?: ExpenseState;
    aggregator: Aggregator;
}

export enum Aggregator {
    VEHICLE = 'vehicle',
    DATE = 'date'
}

export enum ExpenseState {
    Proforma = 'proforma',
    Final = 'final'
}

interface ExpensesModel extends BaseExpensesModel {
    details: NonNullable<BaseExpensesModel['details']>;
}

export interface Filter {
    vehicles: string[];
    dateRange: DateRange;
    type?: ExpenseType;
    source?: ExpenseSource;
    payment?: ExpenseState;
}

export class StatisticsExpensesLogic {
    private _data: ExpensesModel[];
    private _users: DriverModel[] = [];
    private _vehicles: ReadOnlyMonitoredObjectFeSb[] = [];

    constructor(private _logic: Logic) {
        this._data = [];
    }

    settings(): {
        vehicles: string[];
        type?: ExpenseType;
        source?: ExpenseSource;
        payment?: ExpenseState;
        aggregator: Aggregator;
    } {
        const settings = this._logic.settings().getProp('statistics').expenses.filter;
        const filter = {
            vehicles: settings.vehicles,
            type: settings.type,
            source: settings.source,
            payment: settings.payment,
            aggregator: settings.aggregator
        };
        return filter;
    }

    setSettings(settings: {
        vehicles?: string[];
        type?: ExpenseType;
        source?: ExpenseSource;
        payment?: ExpenseState;
        aggregator?: Aggregator;
    }) {
        const originalSettings = this._logic.settings().getProp('statistics').expenses.filter;
        const modifiedSettings = {
            vehicles: settings.vehicles ?? originalSettings.vehicles,
            type: settings.type ?? originalSettings.type,
            source: settings.source ?? originalSettings.source,
            payment: settings.payment ?? originalSettings.payment,
            aggregator: settings.aggregator ?? originalSettings.aggregator
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProps().statistics,
            expenses: {
                filter: modifiedSettings
            }
        });
    }

    async vehicles() {
        this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters();
        return this._vehicles;
    }

    async users() {
        this._users = await this._logic.users().drivers();
        return this._users;
    }

    async tableData(filter: ExpenseFilter) {
        await this.vehicles();
        await this.users();
        this._data = await this._getGroupedData(filter);
        return {
            data: this._data
        };
    }

    async importExpense(data: number[]) {
        try {
            const res = await this._logic.apollo().query<Import_ExpenseMutation, Import_ExpenseMutationVariables>({
                query: Import_ExpenseDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    file: data
                }
            });
            return res.data.import_Expense;
        } catch (err) {
            console.error('Import expense err', err);
            throw err;
        }
    }

    private _getRegistrationNumber(monitoredObjectId: number | null | undefined): string {
        if (monitoredObjectId) {
            if (this._vehicles) {
                const vehicle = this._vehicles.find(vehiclesEntry => vehiclesEntry.id === monitoredObjectId);
                return vehicle ? getRegistrationNumber(!!vehicle.disabledAt, vehicle.registrationNumber) : '';
            }
            return '';
        }
        return '';
    }

    async createOrUpdateExpense(model: CargoExpense): Promise<boolean> {
        return await this._createOrUpdateExpense(this._toCreateOrUpdateExpenseInput(model));
    }

    async deleteExpense(id: string): Promise<boolean | undefined | null> {
        try {
            const deleteResult = await this._logic.apollo().mutate<Delete_ExpenseMutation, MutationDelete_ExpenseArgs>({
                mutation: Delete_ExpenseDocument,
                variables: { id }
            });
            return deleteResult.data?.delete_Expense;
        } catch (err) {
            console.error('Delete expense err', err);
            throw err;
        }
    }

    private _toExpenseModel(expense: Expense, index: number, mainExpenseUnit?: string): ExpenseModel {
        // originalUnitPriceInclVat => totem price
        // unitPriceInclVat => what he actually payed

        // Totem price shown only when totem is higher than unitPrice else show unitPrice
        // business logic: do not show user that he payed more with our prices "discount";

        return {
            id: expense.id ?? '',
            rowId: String(index),
            date: expense.timestamp ?? '',
            monitoredObjectId: expense.monitoredObject?.id ?? 0,
            RN: (expense.monitoredObject && this._getRegistrationNumber(expense.monitoredObject.id)) ?? '',
            driver: expense.driverName ?? '',
            userId: String(expense.clientId) ?? '',
            type: expense.expenseType ?? undefined,
            supplier: expense.fuelStation ? expense.fuelStation.name ?? '' : '',
            cargoCountry: expense.country ?? '',
            cargoCurrency: expense.currency ?? '',
            cargoVAT: expense.vatRate ?? 0,
            quantityTotal: expense.units ?? 0,
            quantityUnit:
                mainExpenseUnit !== undefined
                    ? mainExpenseUnit
                    : [ExpenseType.Fuel, ExpenseType.Adblue].includes(expense.expenseType as ExpenseType)
                    ? L
                    : PC,
            pricePerUnitWithoutVAT: expense.unitPriceWithoutVat ?? 0,
            pricePerUnitWithVAT: expense.unitPriceInclVat ?? 0,
            priceTotalWithoutVAT: expense.totalPriceWithoutVat ?? 0,
            priceTotalWithVAT: expense.totalPriceInclVat ?? 0,
            pricePerUnitWithoutVATAverage: expense.unitPriceWithoutVatAverage ?? undefined,
            pricePerUnitWithVATAverage: expense.unitPriceInclVatAverage ?? undefined,
            // show Saved/TotemPrice only when saved is greater than 0
            fuelioTotemPrice: expense.saved && expense.saved > 0 ? expense.fuelioTotemPrice ?? 0 : 0,
            fuelioTotemPriceCurrency:
                expense.saved && expense.saved > 0
                    ? (expense.fuelioTotemPriceCurrency as AvailableCurrencies) ?? AvailableCurrencies.EUR
                    : AvailableCurrencies.EUR,
            saved: expense.saved && expense.saved > 0 ? expense.saved : 0,
            savedCurrency:
                expense.saved && expense.saved > 0
                    ? (expense.savedCurrency as AvailableCurrencies)
                    : AvailableCurrencies.EUR,
            odo: expense.odometer ?? 0,
            source: expense.type ?? undefined,
            state: expense.state ?? undefined,
            fraudDetection: expense?.fraudDetectionResult?.state
                ? (expense.fraudDetectionResult.state as FraudDetectionState)
                : FraudDetectionState.UNKNOWN,
            note: '', // TODO:
            fuelStation: {
                id: expense.fuelStation?.id ?? '',
                name: expense.fuelStation?.name ?? ''
            }
        };
    }

    private _toCreateOrUpdateExpenseInput(model: CargoExpense): ExpenseInput {
        return {
            id: model.expenseId,
            monitoredObjectId: model.vehicleId,
            expenseType: model.type,
            timestamp: model.date,
            units: Number(model.quantity.replace(',', '.')), // Change formular to formik
            country: model.country,
            state: ExpenseState.Final,
            fuelStationId: model.supplier,
            totalPriceInclVat: Number(model.priceTotal.replace(',', '.')) // Change formular to formik
        };
    }

    private async _createOrUpdateExpense(input: ExpenseInput): Promise<boolean> {
        try {
            const result = await this._logic.apollo().mutate<UpsertExpenseMutation, UpsertExpenseMutationVariables>({
                mutation: UpsertExpenseDocument,
                variables: { input }
            });
            return result.data?.upsert_Expense?.id ? true : false;
        } catch (err) {
            console.error('Create expense err', err);
            throw err;
        }
    }

    private async _getGroupedData(filter: ExpenseFilter) {
        if (this._logic.demo().isActive) {
            return [];
        }

        try {
            const timezoneOffset = new Date().getTimezoneOffset();
            const expenseAggregator =
                filter.aggregator === Aggregator.DATE ? ExpenseAggregator.Date : ExpenseAggregator.Vehicle;
            const res = await this._logic.apollo().query<Get_GroupedExpensesQuery, Get_GroupedExpensesQueryVariables>({
                query: Get_GroupedExpensesDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    timezoneOffset: timezoneOffset,
                    filter: {
                        monitoredObjectIds:
                            filter.vehicles && filter.vehicles.length > 0 ? filter.vehicles.map(Number) : undefined,
                        type: filter.type ? filter.type : undefined,
                        userId: filter.driverId,
                        source: filter.source ? [filter.source] : undefined,
                        paymentStatus: filter.payment ? [filter.payment] : undefined,
                        startTime: filter.dateRange?.start
                            ? moment(filter.dateRange?.start, DATE_FORMAT).startOf('day').toDate().toISOString()
                            : undefined,
                        endTime: filter.dateRange?.end
                            ? moment(filter.dateRange?.end, DATE_FORMAT).endOf('day').toDate().toISOString()
                            : undefined
                    },
                    aggregator: expenseAggregator
                }
            });

            const { get_GroupedExpenses } = res.data;
            if (get_GroupedExpenses?.data && Array.isArray(get_GroupedExpenses?.data)) {
                const expenseModelData: ExpensesModel[] = get_GroupedExpenses.data.map((groupExpense, groupIndex) => {
                    if (groupExpense.main && groupExpense.details) {
                        const defaultExpense = groupExpense.details[0];
                        const mainExpenseUnit = groupExpense.details.every(
                            expense => expense.expenseType === defaultExpense.expenseType
                        )
                            ? [ExpenseType.Fuel, ExpenseType.Adblue].includes(defaultExpense.expenseType as ExpenseType)
                                ? L
                                : PC
                            : '';
                        return {
                            exchangeRateErr: groupExpense.exchangeRateErr ?? false,
                            main: this._toExpenseModel(groupExpense.main, groupIndex, mainExpenseUnit),
                            details: groupExpense.details.map((detail, detailIndex) => {
                                return this._toExpenseModel(detail!, detailIndex);
                            })
                        };
                    }
                    throw new Error('');
                });

                return expenseModelData;
            }
            return [];
        } catch (err) {
            console.error('Get group expenses err', err);
            throw err;
        }
    }

    async downloadExpensesTemplate(logic: StatisticsExpensesLogic) {
        const workbook: Workbook = await fromBlankAsync();
        workbook.sheet(0).name(normalizeExportSheetName('expenses'));
        workbook.addSheet(normalizeExportSheetName('values'));

        const head = [
            [
                `${i18n.t('ExpensesExport.date').toUpperCase()}*`,
                i18n.t('ExpensesExport.driver').toUpperCase(),
                '',
                `${i18n.t('ExpensesExport.country').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.vehicle').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.expenseType').toUpperCase()}*`,
                i18n.t('ExpensesExport.supplier').toUpperCase(),
                `${i18n.t('ExpensesExport.totalQuantity').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.pricePerUnitWithoutVat').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.pricePerUnitWithVat').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.totalPriceWithoutVat').toUpperCase()}*`,
                `${i18n.t('ExpensesExport.totalPriceWithVat').toUpperCase()}*`,
                i18n.t('ExpensesExport.totemPrice').toUpperCase(),
                i18n.t('ExpensesExport.odometer').toUpperCase()
            ],
            ['', i18n.t('common.name').toUpperCase(), i18n.t('common.surname').toUpperCase()]
        ];

        workbook.sheet(0).range(1, 2, 1, 3).merged(true);
        const workbookHeadRange = workbook.sheet(0).range(1, 1, 3, 14);
        const lastWorkbookHeadRange = workbook.sheet(0).range(3, 1, 3, 14);

        const rows = 500;
        const countryCells = workbook.sheet(0).range(`D4:D${rows}`);
        const expenseTypeCells = workbook.sheet(0).range(`F4:F${rows}`);
        const vehicleCells = workbook.sheet(0).range(`E4:E${rows}`);

        // style head
        workbookHeadRange.style({
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true
        });

        lastWorkbookHeadRange.style({
            bottomBorder: true
        });

        workbookHeadRange.value(head);

        countryCells.dataValidation({
            type: 'list',
            allowBlank: true,
            showInputMessage: false,
            showErrorMessage: true,
            errorTitle: i18n.t('validator.invalid_value'),
            error: `${i18n.t('validator.not_valid')}!`,
            formula1:
                '"BE,BG,CZ,DK,DE,EE,IE,GR,ES,FR,HR,IT,CY,LV,LT,LU,HU,MT,NL,AT,PL,PT,SI,SK,FI,SE,GB,SM,AL,AD,BY,BA,IM,MK,MD,MC,ME,NO,RS,CH,LI,TR,UA,RO,"'
        });

        const types = Object.values(ExpenseType).sort((a, b) => (a > b ? 1 : -1));

        expenseTypeCells.dataValidation({
            type: 'list',
            allowBlank: true,
            showInputMessage: false,
            showErrorMessage: true,
            errorTitle: i18n.t('validator.invalid_value'),
            error: `${i18n.t('validator.not_valid')}!`,
            formula1: `"${types.join(',')}"`
        });

        const vehicles = logic._vehicles
            .map(v => [getRegistrationNumber(!!v.disabledAt, v.registrationNumber).replaceAll(' ', '')])
            .sort((a, b) => {
                return a[0] > b[0] ? 1 : -1;
            });
        workbook.sheet(1).range(`A1:A${vehicles.length}`).value(vehicles);

        vehicleCells.dataValidation({
            type: 'list',
            allowBlank: true,
            showInputMessage: false,
            showErrorMessage: true,
            errorTitle: i18n.t('validator.invalid_value'),
            error: `${i18n.t('validator.not_valid')}!`,
            formula1: '=values!A:A'
        });

        [20, 20, 20, 20, 20, 20, 20, 25, 35, 35, 35, 35, 20, 20].forEach((width, i) => {
            workbook
                .sheet(0)
                .column(i + 1)
                .width(width);
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `exportlist ${moment().format('L')}.xlsx`);
    }

    async downloadExpensesExport(data: BaseExpensesModel[], from: string, to: string) {
        const workbook: Workbook = await fromBlankAsync();
        workbook.sheet(0).name(normalizeExportSheetName(i18n.t('Maintenance.task.expenses')));

        const fromStrFormat = moment(from, DATE_FORMAT).format('L');
        const toStrFormat = moment(to, DATE_FORMAT).format('L');
        const user = this._logic.auth().user();

        const headDescription = [
            [i18n.t('Maintenance.task.expenses'), ''],
            [i18n.t('common.dateFrom'), fromStrFormat],
            [i18n.t('common.dateTo'), toStrFormat],
            [
                i18n.t('common.created'),
                `${moment().format('L LT')} ( ${i18n.t('Maintenance.task.byUser')} ${user.name.toString()})`
            ]
        ];

        const headTable = [
            [
                '',
                '',
                '',
                '',
                '',
                i18n.t('ExpensesTable.cargo'),
                '',
                i18n.t('ExpensesTable.quantity'),
                '',
                i18n.t('ExpensesTable.pricePerUnit'),
                '',
                i18n.t('ExpensesTable.priceTotal'),
                '',
                '',
                '',
                '',
                ''
            ],
            [
                i18n.t('common.date'),
                i18n.t('common.vehicle'),
                i18n.t('ExpensesTable.driver'),
                i18n.t('ExpensesTable.type'),
                i18n.t('ExpensesTable.supplier'),
                i18n.t('ExpensesTable.country'),
                i18n.t('ExpensesTable.VAT'),
                i18n.t('ExpensesTable.total'),
                i18n.t('ExpensesTable.unit'),
                i18n.t('ExpensesTable.withoutVAT'),
                i18n.t('ExpensesTable.withVAT'),
                i18n.t('ExpensesTable.withoutVAT'),
                i18n.t('ExpensesTable.withVAT'),
                i18n.t('ExpensesTable.totemPrice'),
                i18n.t('ExpensesTable.saved'),
                i18n.t('ExpensesTable.odo'),
                i18n.t('ExpensesTable.source'),
                i18n.t('ExpensesTable.state')
            ]
        ];

        const distance = (duration: number) => Math.round(duration / 100) / 10;

        let rows: (string | number)[][] = [];
        data.forEach(summary => {
            summary.details?.forEach(detail => {
                rows.push([
                    moment(detail.date).format('L'),
                    detail.RN,
                    detail.driver || '',
                    `${i18n.t(`ExpensesFilter.${detail.type}`)}`,
                    detail.supplier || '',
                    detail.cargoCountry,
                    detail.cargoVAT / 100,
                    detail.quantityTotal ? detail.quantityTotal : '',
                    [ExpenseType.Fuel, ExpenseType.Adblue].indexOf(detail.type as ExpenseType) !== -1
                        ? detail.quantityUnit
                        : `${i18n.t('common.pc')}`,
                    detail.pricePerUnitWithoutVAT ? detail.pricePerUnitWithoutVAT : '',
                    detail.pricePerUnitWithVAT ? detail.pricePerUnitWithVAT : '',
                    detail.priceTotalWithoutVAT ? detail.priceTotalWithoutVAT : '',
                    detail.priceTotalWithVAT ? detail.priceTotalWithVAT : '',
                    detail.fuelioTotemPrice &&
                    [ExpenseType.Adblue, ExpenseType.Fuel].includes(detail.type as ExpenseType)
                        ? detail.fuelioTotemPrice
                        : '',
                    detail.saved ? detail.saved : '',
                    detail.odo ? `${numeral(distance(detail.odo)).format('0,0.00')} ${KM}` : '',
                    `${i18n.t(`ExpensesFilter.${detail.source}`)}`,
                    `${i18n.t(`ExpensesFilter.${detail.state}`)}`
                ]);
            });
        });

        const descriptionStartRow = 3;
        const descriptionEndRow = 6;
        const headTableStartRow = descriptionEndRow + 2;
        const headTableEndRow = headTableStartRow + 1;
        const bodyTableStartRow = headTableEndRow + 1;
        const bodyTableEndRow = bodyTableStartRow + rows.length;
        const tableColumnsCount = 18;

        const sheet = workbook.sheet(0);
        const workbookDescriptionRange = sheet.range(descriptionStartRow, 1, descriptionEndRow, 2);
        const workbookHeadTableRange = sheet.range(headTableStartRow, 1, headTableEndRow, tableColumnsCount);
        const workbookBodyTableRange = sheet.range(bodyTableStartRow, 1, bodyTableEndRow, tableColumnsCount);

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            fontSize: 14,
            bold: true
        };

        const headTableStyle = {
            fill: {
                color: 'EDEDED',
                type: 'solid'
            },
            border: {
                color: 'AAAAAA',
                style: 'thin'
            },
            bold: true
        };

        const headTableSpaceStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            }
        };

        sheet.range(1, 1, 1, 1).value(this._logic.auth().client()?.name);
        sheet.range(descriptionStartRow, 1, descriptionStartRow, 1).style(titleDescriptionStyle);
        workbookDescriptionRange.value(headDescription);
        workbookHeadTableRange.style(headTableStyle).value(headTable);
        if (rows.length > 0) {
            workbookBodyTableRange.value(rows);
        }

        for (let column = 19; column < 30; column++) {
            sheet.column(column).style({
                border: {
                    color: 'FFFFFF',
                    style: 'thin'
                }
            });
        }

        sheet.column(19).style({
            leftBorder: false
        });

        // head no border
        for (let row = 1; row < 8; row++) {
            sheet.row(row).style({
                border: {
                    color: 'FFFFFF',
                    style: 'thin'
                }
            });
        }

        sheet.freezePanes(0, headTableEndRow);

        // head table title center
        [6, 8, 10, 12].forEach(column => {
            sheet
                .range(headTableStartRow, column, headTableStartRow, column + 1)
                .merged(true)
                .style({
                    horizontalAlignment: 'center'
                });
        });

        // head empty title
        [1, 2, 3, 4, 5, 14, 15, 16, 17, 18].forEach(column => {
            sheet.range(headTableStartRow, column, headTableStartRow, column).style(headTableSpaceStyle);
        });

        let row = bodyTableStartRow;
        data.forEach(summary => {
            summary.details?.forEach(detail => {
                [10, 11, 12, 13, 14, 15].forEach(column => {
                    const currency =
                        column < 14
                            ? detail.cargoCurrency
                            : column === 14
                            ? detail.fuelioTotemPriceCurrency
                            : detail.savedCurrency;

                    const format =
                        column > 11
                            ? '0.00'
                            : detail.type === (ExpenseType.Fuel || ExpenseType.Adblue)
                            ? '0.000'
                            : '0.00';

                    sheet.range(row, column, row, column).style({
                        numberFormat: `${format} "${currency}"`
                    });
                });

                row++;
            });
        });

        // format percentage
        sheet.range(bodyTableStartRow, 7, bodyTableEndRow, 7).style({
            numberFormat: '0 %'
        });

        // format quantity
        sheet.range(bodyTableStartRow, 8, bodyTableEndRow, 8).style({
            numberFormat: '0.00'
        });

        // format currency values right
        [7, 8, 16].forEach(column => {
            sheet.range(bodyTableStartRow, column, bodyTableEndRow, column).style({
                horizontalAlignment: 'right'
            });
        });

        [20, 20, 20, 16, 35, 8, 8, 14, 8, 14, 14, 20, 20, 14, 14, 20, 20, 20].forEach((width, i) => {
            sheet.column(i + 1).width(width);
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${i18n.t('Maintenance.task.expenses')} ${fromStrFormat} - ${toStrFormat}.xlsx`;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }
}
