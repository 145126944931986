import cn from 'classnames';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ColumnType } from 'antd/lib/table';
import { Table } from '..';
import qa from 'qa-selectors';
import Button from 'common/components/Button';
import * as CommonIcons from 'resources/images/common';
import { Loading } from 'common/components/Loading';
import { Tooltip } from 'common/components';
import { DATE_FORMAT, TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';
import { Col, Row } from 'antd';

export interface DateGridTableModel {
    id: string;
    firstColValue: string;
    secondColValue: string;
    downloads: Array<{ date?: string; id: string; fileName?: string; processing: boolean }>;
}

interface Props extends WithTranslation {
    data: DateGridTableModel[];
    loadingDemandIds: string[];
    startDate: string;
    loading?: boolean;
    demoMode?: boolean;
    widthFirstCol?: number;
    onDayDownloadClick?: (id: string) => void;
    onRemoteDownloadClick?: (id: string) => void;
    onMonthlyDownloadClick?: (id: string) => void;
}

function DateGridTable(props: Props) {
    const { data, startDate, loading, t } = props;
    const cellCount = moment(props.startDate).daysInMonth();
    const dateCells = [...Array(cellCount).keys()];

    return (
        <div className="tm-date-grid-table">
            <Table<DateGridTableModel>
                loading={loading}
                scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
                dataSource={data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                bordered
                size="small"
                onRow={() => ({
                    className: 'ant-table-row-lead'
                })}
            />
        </div>
    );

    function createColumns(): ColumnType<DateGridTableModel>[] {
        return (
            [
                {
                    title: t('common.name'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 1534 - cellCount * 44,
                    render: (_, record) => (
                        <span data-qa={qa.remoteDownload.table.fieldName}>{record.firstColValue}</span>
                    )
                },
                moment().format('YYYY MM') === moment(startDate).format('YYYY MM')
                    ? {
                          title: t('RemoteDownload.remoteDownload'),
                          dataIndex: 'remoteDownload',
                          key: 'remoteDownload',
                          align: 'center',
                          width: 110,
                          className: 'remote-download',
                          render: (_, record) => {
                              const todayReadyDownload = record.downloads.filter(
                                  entry => !entry.processing && entry.date === moment().startOf('day').toString()
                              );
                              return moment().format('YYYY MM') === moment(startDate).format('YYYY MM') ? (
                                  props.loadingDemandIds.includes(record.id) ||
                                  record.downloads.filter(d => d.processing).length > 0 ? (
                                      <Row justify="center">
                                          <Col>
                                              <Tooltip
                                                  title={`${props.t('common.requestedOn')} ${
                                                      record.downloads.filter(d => d.processing)?.[0]?.date
                                                          ? moment(
                                                                record.downloads.filter(d => d.processing)?.[0]?.date
                                                            ).format(DATE_FORMAT)
                                                          : ''
                                                  }`}
                                              >
                                                  {' '}
                                                  <Loading type="data" height={40} width={40} />
                                              </Tooltip>
                                          </Col>
                                      </Row>
                                  ) : todayReadyDownload.length === 0 ? (
                                      <Button
                                          block
                                          type="link"
                                          size="middle"
                                          className="remote-download-btn"
                                          disabled={props.demoMode}
                                          icon={
                                              <img
                                                  src={CommonIcons.downloadRemote}
                                                  alt={t('RemoteDownload.remoteDownload')}
                                              />
                                          }
                                          onClick={() => props.onRemoteDownloadClick?.(record.id)}
                                      />
                                  ) : undefined
                              ) : undefined;
                          }
                      }
                    : undefined,
                ...dateCells.map(
                    (c, i) =>
                        ({
                            title: <span data-qa={qa.remoteDownload.table.fieldDay}>{String(i + 1)}.</span>,
                            align: 'center',
                            dataIndex: i,
                            key: i,
                            width: 44,
                            className: cn('table-date', {
                                'table-date-first': i === 0,
                                highlighted:
                                    moment().format('YYYY MM') === moment(startDate).format('YYYY MM') &&
                                    i + 1 === Number(moment().get('date'))
                            }),
                            render: (_, record) => {
                                const cellReadyDownload = record.downloads.filter(
                                    entry =>
                                        !entry.processing &&
                                        entry.date === moment(props.startDate).add(c, 'days').startOf('day').toString()
                                );

                                return (
                                    cellReadyDownload.length > 0 &&
                                    (props.demoMode ? (
                                        <span style={{ cursor: 'not-allowed' }}>
                                            <img alt="download" src={CommonIcons.downloadFileExcel} />
                                        </span>
                                    ) : (
                                        <span
                                            className="pointer"
                                            onClick={() => props.onDayDownloadClick?.(cellReadyDownload[0]?.id)}
                                        >
                                            <img alt="download" src={CommonIcons.downloadFileExcel} />
                                        </span>
                                    ))
                                );
                            }
                        } as ColumnType<DateGridTableModel>)
                ),
                {
                    title: t('RemoteDownload.monthlyDownload'),
                    dataIndex: 'monthlyDownload',
                    key: 'monthlyDownload',
                    align: 'center',
                    width: 110,
                    className: 'monthly-download',
                    render: (_, record) =>
                        record.downloads.filter(d => !d.processing).length > 0 && (
                            <Button
                                block
                                type="link"
                                size="middle"
                                className="monthly-download-btn"
                                disabled={props.demoMode}
                                icon={
                                    <img
                                        src={CommonIcons.downloadFileExcelBlue}
                                        alt={t('RemoteDownload.monthlyDownload')}
                                    />
                                }
                                onClick={() => props.onMonthlyDownloadClick?.(record.id)}
                            />
                        )
                }
            ] as ColumnType<DateGridTableModel>[]
        ).filter(d => d !== undefined);
    }
}

export default withTranslation()(DateGridTable);
