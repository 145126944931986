import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import qs from 'qs';
import moment from 'moment';
import { debounce } from 'debounce';
import { message } from 'antd';
import { Subscription } from 'rxjs';
import { observer } from 'mobx-react';
import { Logic } from 'logic/logic';

import MaintenanceBar from './components/maintenance-bar';
import MaintenanceTaskTable from './components/maintenance-task-table';
import {
    CreatedOrCompletedBy,
    Expenses,
    ExpensesItemsTypeEnum,
    MaintenanceTask,
    MaintenanceTaskList,
    MaintenanceTaskListRepetitionDueDateTypeEnum,
    MaintenanceTaskRepetitionDueDateTypeEnum,
    NotificationReceivers,
    NotificationReceiversEntityEnum,
    ReadOnlyTaskTypeRepetitionDueDateTypeEnum,
    TaskType,
    TaskTypeCategoryEnum,
    TaskTypeRepetitionDueDateTypeEnum
} from 'generated/new-main';
import {
    DEFAULT_MAINTENANCE_NOTIFICATION_DAYS,
    DEFAULT_MAINTENANCE_NOTIFICATION_KM,
    DEFAULT_PAGE_LIMIT,
    DEFAULT_PAGE_OFFSET
} from 'domain-constants';
import * as CommonIcons from 'resources/images/common';
import { PaginationParams } from 'common/model/pagination';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { LayoutContent } from 'common/components/Layout/Content';
import { Confirm, Modal } from 'common/components';
import Collapse from 'common/components/Collapse';
import { MessageType } from 'common/components/Confirm';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import MaintenanceForm, {
    MaintenanceCompletitionModel,
    MaintenanceFormModel,
    RecipientModel,
    RecipientsSearchResult,
    SearchResult,
    TaskTypeSearchResult
} from 'common/forms/MaintenanceForm';
import MaintenanceCompletitionForm from 'common/forms/MaintenanceCompletitionForm';
import MaintenanceDetailCard from './components/maintenance-detail-card';
import MaintenanceActions from './components/maintenance-actions';
import { SearchData } from 'common/components/Search';
import { AvailableCurrencies } from 'common/model/currency';
import { roundToStep } from 'common/utils/averages';
import ClientForm from 'common/forms/ClientForm';
import { searched } from 'common/utils/search';
import { ContactList } from 'common/model/client-contact';
import { exponea } from 'logic/exponea';
import { confDefault } from 'conf';

export interface MaintenanceTaskTableModel {
    id: string;
    category: TaskTypeCategoryEnum;
    taskType?: TaskType;
    state?: string;
    clientId?: number;
    createdAt: string;
    createdBy?: CreatedOrCompletedBy;

    driverId?: number;
    vehicleId?: number;
    trailerId?: number;
    driver?: string;
    vehicle?: string;
    trailer?: string;
    company?: string;

    dueDate?: string;
    dueMileageKilometers?: number;
    repetitionDueMileageKilometers?: number;
    repetitionDueDateType?: MaintenanceTaskRepetitionDueDateTypeEnum;
    repetitionDueDateValue?: number;
    repetitionBindToDueDate?: boolean;
    repetitionInterval?: MaintenanceRepetitionIntervalEnum;

    notificationDueDateDays?: number;
    notificationDueMileageKilometers?: number;
    notificiationRecipients?: RecipientModel[];

    completionTime?: string;
    completionMileageKilometers?: number;
    completedBy?: CreatedOrCompletedBy;

    expenses?: Expenses;

    dueDateLeftDays?: number;
    dueMileageLeftKilometers?: number;
    dueProgressPercent?: number;
    lastEvaluationMileage?: number;

    notes?: string;
    updatedAt?: Date;
}

export interface MaintenanceNotificationReceiver {
    entity: NotificationReceiversEntityEnum;
    channel: 'email' | 'app';
    entityId: number | string;
    name?: string;
}

export enum MaintenanceRepetitionIntervalEnum {
    None = 'none',
    Day1 = 'day_1',
    Week1 = 'week_1',
    Week2 = 'week_2',
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
    Month6 = 'month_6',
    Year1 = 'year_1',
    Year2 = 'year_2',
    Custom = 'custom'
}

export interface DueFilterModel {
    overdueSelected: boolean;
    overdueCount?: number;
    activeSelected: boolean;
    activeCount?: number;
    dueSoonSelected: boolean;
    dueSoonCount?: number;
}

export interface MaintenanceSummary {
    overdue: number;
    active: number;
    dueSoon: number;
}

export interface FilterMaintenanceTasks {
    limit: number;
    offset: number;
    textSearch?: string;
    includeStates?: string[];
    monitoredObjects?: number[];
    users?: number[];
    taskTypeIds?: string;
    intervalIsDate?: boolean;
    intervalIsKm?: boolean;
    dueDateGte?: string;
    dueDateLte?: string;
    taskId?: string;
}

export type RouteParams = {
    task_id?: string;
};

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
    logic: Logic;
}

export const defaultFilters = {
    vehiclesChecked: [],
    trailersChecked: [],
    driversChecked: [],
    taskTypeChecked: [],
    intervalChecked: [],
    stateChecked: [],
    dueDateGte: undefined,
    dueDateLte: undefined
};

interface State {
    search?: SearchData;
    form: {
        isOpen: boolean;
        isLoading: boolean;
        mode: 'CREATE' | 'EDIT';
        method: 'NEW' | 'DUPLICATE';
        initialValues?: Partial<MaintenanceFormModel>;
        editId?: string;
        supplierSelected?: string;
    };
    complete: {
        isOpen: boolean;
        isLoading: boolean;
    };
    delete: {
        isOpen: boolean;
        isLoading: boolean;
    };
    export: {
        isLoading: boolean;
    };
    newSupplier: {
        isOpen: boolean;
    };
    dueFilter: DueFilterModel;
    filter: {
        isOpen: boolean;
        vehiclesOpts: CheckboxGroupOpts;
        driversOpts: CheckboxGroupOpts;
        trailersOpts: CheckboxGroupOpts;
        taskTypeOpts: CheckboxGroupOpts;
        intervalOpts: CheckboxGroupOpts;
        stateOpts: CheckboxGroupOpts;
        vehiclesChecked: string[];
        driversChecked: string[];
        trailersChecked: string[];
        taskTypeChecked: string[];
        intervalChecked: string[];
        stateChecked: string[];
        dueDateGte?: string;
        dueDateLte?: string;
    };
    maintenanceForm: {
        driversOpts?: SearchResult[];
        trailersOpts?: SearchResult[];
        vehiclesOpts?: SearchResult[];
        taskTypesOpts?: TaskTypeSearchResult[];
        recipientsOpts?: RecipientsSearchResult[];
    };
    customTypeDeleteConfirm?: {
        id: string;
        count?: number;
        loading: boolean;
        processing: boolean;
    };
    customTypeEditConfirm?: {
        id: string;
        name: string;
        count?: number;
        loading: boolean;
        processing: boolean;
    };
}

class MaintenanceModule extends React.Component<Props, State> {
    maintenanceCreated?: Subscription;
    maintenanceCompleted?: Subscription;
    maintenanceDeleted?: Subscription;
    maintenanceTaskTypeDeleted?: Subscription;

    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const settings = this._logic.maintenanceLogic().settings();

        this.state = {
            form: {
                isOpen: false,
                isLoading: false,
                mode: 'CREATE',
                method: 'NEW'
            },
            complete: {
                isOpen: false,
                isLoading: false
            },
            delete: {
                isOpen: false,
                isLoading: false
            },
            export: {
                isLoading: false
            },
            newSupplier: {
                isOpen: false
            },
            filter: {
                isOpen: false,
                vehiclesOpts: [],
                driversOpts: [],
                trailersOpts: [],
                taskTypeOpts: [],
                intervalOpts: [],
                stateOpts: [],
                vehiclesChecked: settings.vehicles,
                driversChecked: settings.drivers,
                trailersChecked: settings.trailers,
                taskTypeChecked: settings.taskTypes,
                intervalChecked: settings.intervals,
                stateChecked: settings.status,
                dueDateGte: settings.dueDateGte,
                dueDateLte: settings.dueDateLte
            },
            dueFilter: {
                activeSelected: false,
                dueSoonSelected: false,
                overdueSelected: false
            },
            maintenanceForm: {
                driversOpts: [],
                vehiclesOpts: [],
                trailersOpts: [],
                taskTypesOpts: []
            }
        };
    }

    componentDidMount() {
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this._logic.maintenanceLogic().init(params.task_id);

        this.maintenanceCreated = this._logic.statisticsMaintenance().onMaintenanceCreated.subscribe(() => {
            this._logic.maintenanceLogic().reloadMaintenance();
        });
        this.maintenanceCompleted = this._logic.statisticsMaintenance().onMaintenanceCompleted.subscribe(() => {
            this._logic.maintenanceLogic().reloadMaintenance();
        });
        this.maintenanceDeleted = this._logic.statisticsMaintenance().onMaintenanceDeleted.subscribe(() => {
            this._logic.maintenanceLogic().reloadMaintenance();
        });
        this.maintenanceTaskTypeDeleted = this._logic
            .statisticsMaintenance()
            .onMaintenanceTaskTypeDeleted.subscribe(() => {
                this._logic.maintenanceLogic().setDetailedMaintenanceTaskId(undefined);
                this._logic.maintenanceLogic().reloadMaintenance();
            });

        this.setState(state => ({
            filter: {
                ...state.filter,
                intervalOpts: this._getIntervalOpts(),
                stateOpts: this._getStateOpts()
            }
        }));

        if (params.task_id) {
            this.props.logic.exponea().trackEvent(exponea.module.maintenanceDetail, {
                status: exponea.status.screenShown,
                src: 'notifications'
            });
        }
    }

    componentDidUpdate() {
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        if (params.task_id && params.task_id !== this._logic.maintenanceLogic().detailedMaintenanceTaskId) {
            this._logic.maintenanceLogic().unsetCheckedMaintenanceTasks();
            this._logic.maintenanceLogic().setDetailedMaintenanceTaskId(params.task_id);
            this._logic.maintenanceLogic().reloadMaintenance();
        }
    }

    componentWillUnmount() {
        (window as any).app.MaintenanceTaskModule = undefined;
        this._logic.maintenanceLogic().unsetCheckedMaintenanceTasks();
        this._logic.maintenanceLogic().setDetailedMaintenanceTaskId();
        this._logic.maintenanceLogic().maintenanceTasksTable = {
            data: [],
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET,
            total: 0
        };
        this._logic.maintenanceLogic().setMaintenanceFilter({
            dueDateGte: undefined,
            dueDateLte: undefined,
            includeStates: undefined,
            intervalIsDate: undefined,
            intervalIsKm: undefined,
            monitoredObjects: undefined,
            taskTypeIds: undefined,
            textSearch: undefined,
            taskId: undefined,
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        });

        this.maintenanceCreated?.unsubscribe();
        this.maintenanceCompleted?.unsubscribe();
        this.maintenanceDeleted?.unsubscribe();
        this.maintenanceTaskTypeDeleted?.unsubscribe();
    }

    render() {
        return (
            <LayoutContent
                className="statistics-maintenance no-padding"
                mainSizes={
                    this._logic.maintenanceLogic().selectedMaintenanceTask
                        ? { xs: 24, sm: 24, md: 18 }
                        : { xs: 24, sm: 24, md: 24 }
                }
                extraSizes={
                    this._logic.maintenanceLogic().selectedMaintenanceTask
                        ? [{ xs: 24, sm: 24, md: 6 }]
                        : [{ xs: 0, sm: 0, md: 0 }]
                }
                main={
                    <>
                        <MaintenanceBar
                            search={this.state.search}
                            demoMode={this._logic.demo().isActive}
                            filter={{
                                ...this.state.filter,
                                vehiclesOpts: this._logic.maintenanceLogic().vehicles.map(v => ({
                                    code: String(v.id),
                                    label: v.registrationNumber,
                                    checked: false
                                })),
                                trailersOpts: this._logic.maintenanceLogic().trailers.map(v => ({
                                    code: String(v.id),
                                    label: v.registrationNumber,
                                    checked: false
                                })),
                                driversOpts: this._logic.maintenanceLogic().drivers.map(d => ({
                                    code: String(d.id),
                                    label: `${d.name} ${d.surname}`,
                                    checked: false
                                })),
                                taskTypeOpts: this._logic.maintenanceLogic().maintenanceTaskTypes.map(d => ({
                                    code: String(d.id),
                                    label: d.name,
                                    checked: false
                                }))
                            }}
                            dueFilterExtended={!this._logic.maintenanceLogic().selectedMaintenanceTask}
                            deleteEnabled={this._logic.maintenanceLogic().checkedMaintenanceTasks.length > 0}
                            completeEnabled={
                                this._logic.maintenanceLogic().checkedMaintenanceTasks.filter(d => d.state !== 'done')
                                    .length > 0
                            }
                            dueFilter={{
                                ...this.state.dueFilter,
                                activeCount: this._logic.maintenanceLogic().maintenanceSummary?.active,
                                dueSoonCount: this._logic.maintenanceLogic().maintenanceSummary?.duesoon,
                                overdueCount: this._logic.maintenanceLogic().maintenanceSummary?.overdue
                            }}
                            onBarBackUrlClick={
                                this._logic.maintenanceLogic().detailedMaintenanceTaskId
                                    ? this._onBarBackUrlClick
                                    : undefined
                            }
                            onBarFilterClick={this._onBarFilterClick}
                            onBarCreateNewButtonClick={this._onBarCreateClick}
                            onBarCompleteClick={this._onBarCompleteClick}
                            onBarExportClick={this._onBarExportClick}
                            onBarDeleteClick={this._onBarDeleteClick}
                            onBarSearchChange={this._onBarSearchChange}
                            onBarResetClick={this._onBarResetClick}
                            onDueFilterActiveClick={this._onDueFilterActiveClick}
                            onDueFilterDueSoonClick={this._onDueFilterDueSoonClick}
                            onDueFilterOverdueClick={this._onDueFilterOverdueClick}
                            onFilterConfirmClick={this._onFilterConfirmClick}
                            onFilterCancelClick={this._onFilterCancelClick}
                        />
                        <MaintenanceTaskTable
                            data={this._logic.maintenanceLogic().maintenanceTasksTable}
                            selected={this._logic.maintenanceLogic().selectedMaintenanceTask}
                            checked={this._logic.maintenanceLogic().checkedMaintenanceTasks.map(m => m.id)}
                            loading={
                                this._logic.maintenanceLogic().loadingInit ||
                                this._logic.maintenanceLogic().loadingMaintenanceTasks
                            }
                            filtered={
                                this.state.dueFilter.activeSelected ||
                                this.state.dueFilter.dueSoonSelected ||
                                this.state.dueFilter.overdueSelected ||
                                this.state.search?.text?.length ||
                                this.state.filter.vehiclesChecked.length > 0 ||
                                this.state.filter.driversChecked.length > 0 ||
                                this.state.filter.trailersChecked.length > 0 ||
                                this.state.filter.intervalChecked.length > 0 ||
                                this.state.filter.stateChecked.length > 0 ||
                                this.state.filter.taskTypeChecked.length > 0 ||
                                this.state.filter.dueDateGte !== undefined ||
                                this.state.filter.dueDateLte !== undefined
                                    ? true
                                    : false
                            }
                            onRowClick={this._onTableRowClick}
                            onRowCheckboxClick={this._onTableRowCheckboxClick}
                            onRowEditClick={this._onTableRowEditClick}
                            onRowDuplicateClick={this._onTableRowDuplicateClick}
                            onPaginationChange={this._onTablePaginationChange}
                            onIntroCreateNewClick={this._onBarCreateClick}
                        />
                        <Modal
                            visible={this.state.form.isOpen}
                            title={
                                <>
                                    <img src={CommonIcons.maintenance} alt="maintenance" />
                                    {this.state.form.mode === 'CREATE'
                                        ? this.props.t('Maintenance.task.newMaintenanceTask')
                                        : this.props.t('Maintenance.task.editMaintenanceTask')}
                                </>
                            }
                            closable={true}
                            maskClosable={false}
                            width={730}
                            footer={null}
                            destroyOnClose
                            onCancel={this._onCreateCancelClick}
                            withoutPadding={true}
                            style={{ top: '50px' }}
                        >
                            <MaintenanceForm
                                demoMode={this._logic.demo().isActive}
                                mode={this.state.form.mode}
                                lang={this.props.logic.auth().user().lang}
                                companyName={this._logic.auth().client()?.name ?? ''}
                                onCategoryChange={this._onCategoryChange}
                                onSubmit={this._onFormSubmit}
                                onCancel={this._onFormCancel}
                                currencyOpts={Object.keys(AvailableCurrencies).map(v => ({
                                    label: v.valueOf(),
                                    value: AvailableCurrencies[v]
                                }))}
                                vehicleIdsSearchResults={this.state.maintenanceForm.vehiclesOpts}
                                trailerIdsSearchResults={this.state.maintenanceForm.trailersOpts}
                                driverIdsSearchResults={this.state.maintenanceForm.driversOpts}
                                taskTypeSearchResults={this.state.maintenanceForm.taskTypesOpts}
                                recipientsSearchResultsOpts={this.state.maintenanceForm.recipientsOpts}
                                initialValues={this.state.form.initialValues}
                                taskTypeOpts={this._logic.maintenanceLogic().maintenanceTaskTypes}
                                vehicleOpts={this._logic.maintenanceLogic().vehicles.map(v => ({
                                    code: String(v.id),
                                    label: v.registrationNumber,
                                    checked: false
                                }))}
                                trailerOpts={this._logic.maintenanceLogic().trailers.map(v => ({
                                    code: String(v.id),
                                    label: v.registrationNumber,
                                    checked: false
                                }))}
                                driverOpts={this._logic.maintenanceLogic().drivers.map(d => ({
                                    code: String(d.id),
                                    label: `${d.name} ${d.surname}`,
                                    checked: false
                                }))}
                                clientOpts={this._logic.maintenanceLogic().clientContactList}
                                clientOptsLoading={this._logic.maintenanceLogic().loadingClientContactList}
                                supplierSelected={this.state.form.supplierSelected}
                                onSearchTextChange={this._onSearchTextChange}
                                onSearchTaskTypeTextChange={this._onSearchTaskTypeTextChange}
                                onCustomTypeDeleteClick={this._onCustomTypeDeleteClick}
                                onCustomTypeEditSaveClick={this._onCustomTypeEditSaveClick}
                                onSearchRecipientTextChange={this._onFormSearchRecipientTextChange}
                                onSupplierCreateClick={this._onFormSupplierCreateClick}
                                onSupplierSelectClick={this._onFormSupplierSelectClick}
                            />
                        </Modal>
                        {this.state.delete.isOpen && (
                            <Confirm
                                danger
                                header={this.props.t('Maintenance.delete.header')}
                                message={this.props.t('Maintenance.delete.description')}
                                type={MessageType.WARNING}
                                loading={this.state.delete.isLoading}
                                confirmLabel={this.props.t('common.delete')}
                                confirmDisabled={this._logic.demo().isActive}
                                onCancel={this._onDeleteCancel}
                                onConfirm={this._onDeleteSubmit}
                            />
                        )}

                        <Modal
                            visible={this.state.newSupplier.isOpen}
                            title={this.props.t('ClientContactListForm.modalTitle')}
                            closable={true}
                            width={900}
                            footer={null}
                            destroyOnClose
                            onCancel={this._onCreateSupplierClose}
                        >
                            <ClientForm
                                demoMode={this.props.logic.demo().isActive}
                                countries={this._logic.maintenanceLogic().countryList}
                                onSubmit={this._onCreateSupplierSubmit}
                                onCancel={this._onCreateSupplierClose}
                            />
                        </Modal>

                        <Modal
                            visible={this.state.complete.isOpen}
                            title={this.props.t('Maintenance.complete.dateOfCompletition')}
                            closable={true}
                            maskClosable={false}
                            footer={null}
                            destroyOnClose
                            width={360}
                            onCancel={this._onCompletitionCancel}
                            withoutPadding={true}
                        >
                            <MaintenanceCompletitionForm
                                demoMode={this.props.logic.demo().isActive}
                                lang={this.props.logic.auth().user().lang}
                                odometerOpts={this._logic.maintenanceLogic().checkedMaintenanceTasks.map(m => ({
                                    maintenanceTaskId: m.id,
                                    odometer: m.lastEvaluationMileage ?? 0
                                }))}
                                odometerVisible={
                                    this._logic
                                        .maintenanceLogic()
                                        .forCompletionMaintenanceTasks.filter(d => d.dueMileageKilometers).length > 0
                                }
                                odometerRequired={
                                    this._logic
                                        .maintenanceLogic()
                                        .forCompletionMaintenanceTasks.filter(d => d.dueMileageKilometers !== undefined)
                                        .length > 0
                                }
                                initialValues={{
                                    date: moment().toISOString()
                                }}
                                onCancel={this._onCompletitionCancel}
                                onSubmit={this._onCompletitionSubmit}
                            />
                        </Modal>
                        {this.state.customTypeDeleteConfirm && (
                            <Confirm
                                danger
                                confirmDisabled={this.props.logic.demo().isActive}
                                header={this.props.t('Maintenance.customTaskType.deleteHeading')}
                                message={
                                    <>
                                        <div style={{ margin: '8px 0' }}>
                                            {this.props.t('Maintenance.customTaskType.deleteDescriptionInfo', {
                                                count: this.state.customTypeDeleteConfirm.count
                                            })}
                                        </div>
                                        <div style={{ margin: '8px 0' }}>
                                            {this.props.t('Maintenance.customTaskType.deleteDescriptionQuestion')}
                                        </div>
                                    </>
                                }
                                loading={false}
                                type={MessageType.ERROR}
                                confirmLabel={this.props.t('common.delete')}
                                onCancel={() =>
                                    this.setState(state => ({
                                        ...state,
                                        customTypeDeleteConfirm: undefined
                                    }))
                                }
                                onConfirm={this._onTaskTypeDeleteConfirm}
                            />
                        )}
                        {this.state.customTypeEditConfirm && (
                            <Confirm
                                header={this.props.t('Maintenance.customTaskType.editHeading')}
                                message={
                                    <div style={{ margin: '8px 0' }}>
                                        {this.props.t('Maintenance.customTaskType.editDescription')}
                                    </div>
                                }
                                loading={false}
                                type={MessageType.ERROR}
                                confirmLabel={this.props.t('Maintenance.customTaskType.editConfirm')}
                                onCancel={() =>
                                    this.setState(state => ({
                                        ...state,
                                        customTypeEditConfirm: undefined
                                    }))
                                }
                                onConfirm={this._onTaskTypeEditConfirm}
                            />
                        )}
                    </>
                }
                extra={[
                    this._logic.maintenanceLogic().selectedMaintenanceTask !== undefined ? (
                        <LayoutSidePanel
                            header={this._logic.maintenanceLogic().selectedMaintenanceTask?.taskType?.name}
                            headerIcon={CommonIcons.maintenance}
                            body={
                                <>
                                    <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                        <Collapse.Panel
                                            header={this.props.t('Maintenance.task.maintenanceDetail')}
                                            key="1"
                                        >
                                            <MaintenanceDetailCard
                                                data={this._logic.maintenanceLogic().selectedMaintenanceTask!}
                                                notificationReceivers={
                                                    this._logic.maintenanceLogic().maintenanceNotificationReceivers
                                                }
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </>
                            }
                            footer={
                                this._logic.maintenanceLogic().selectedMaintenanceTask && (
                                    <MaintenanceActions
                                        editDisabled={
                                            this._logic.maintenanceLogic().selectedMaintenanceTask?.state === 'done'
                                        }
                                        onDeleteClick={this._onDetailDeleteClick}
                                        onEditClick={this._onDetailEditClick}
                                        onCompleteClick={this._onDetailCompleteClick}
                                    />
                                )
                            }
                            onCloseClick={this._onLayoutSidePanelCloseClick}
                        />
                    ) : null
                ]}
            />
        );
    }

    private _onDetailDeleteClick = () => {
        if (this._logic.maintenanceLogic().selectedMaintenanceTask) {
            this._logic.maintenanceLogic().forDeletionMaintenanceTasks = [
                this._logic.maintenanceLogic().selectedMaintenanceTask!
            ];
        }
        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: true
            }
        }));
    };

    private _onDetailEditClick = () => {
        this._logic.maintenanceLogic().selectedMaintenanceTask &&
            this._onTableRowEditClick(this._logic.maintenanceLogic().selectedMaintenanceTask!.id);
    };

    private _onDetailCompleteClick = () => {
        if (this._logic.maintenanceLogic().selectedMaintenanceTask) {
            this._logic.maintenanceLogic().forCompletionMaintenanceTasks = [
                this._logic.maintenanceLogic().selectedMaintenanceTask!
            ];
        }
        this.setState(state => ({
            complete: {
                ...state.complete,
                isOpen: true
            }
        }));
    };

    // #region tableBar
    private _onBarCreateClick = () => {
        this.setState(() => ({
            form: {
                isOpen: true,
                isLoading: true,
                mode: 'CREATE',
                method: 'NEW',
                initialValues: {
                    notification: {
                        dueDateDays: DEFAULT_MAINTENANCE_NOTIFICATION_DAYS,
                        dueMileageKilometers: DEFAULT_MAINTENANCE_NOTIFICATION_KM
                    },
                    repetition: {
                        interval: MaintenanceRepetitionIntervalEnum.None
                    }
                }
            }
        }));

        this._logic.exponea().trackEvent(exponea.module.statisticsMaintenance, {
            status: exponea.status.actionTaken,
            action: exponea.action.openNewMaintenance
        });
    };

    private _onBarCompleteClick = () => {
        this._logic.maintenanceLogic().forCompletionMaintenanceTasks = this._logic
            .maintenanceLogic()
            .checkedMaintenanceTasks.filter(task => task.state !== 'done');
        this.setState(state => ({
            complete: {
                ...state.complete,
                isOpen: true
            }
        }));
    };

    private _onBarExportClick = async () => {
        const filter = this._filters();
        const exported = await this._logic
            .maintenanceLogic()
            .exportMaintenanceTasks(
                filter.includeStates,
                filter.monitoredObjects,
                filter.users,
                filter.taskTypeIds,
                filter.intervalIsDate,
                filter.intervalIsKm,
                filter.dueDateGte,
                filter.dueDateLte
            );
        this._logic
            .statisticsMaintenance()
            .downloadMaintenanceExport(exported, this.state.filter.dueDateGte, this.state.filter.dueDateLte);
    };

    private _onBarDeleteClick = () => {
        this._logic.maintenanceLogic().forDeletionMaintenanceTasks =
            this._logic.maintenanceLogic().checkedMaintenanceTasks;
        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: true
            }
        }));

        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: true
            }
        }));
    };

    private _onBarSearchChange = debounce((searchText: string) => {
        this.setState(
            state => ({
                dueFilter: {
                    ...state.dueFilter,
                    activeSelected: false,
                    dueSoonSelected: false,
                    overdueSelected: false
                },
                search: {
                    text: searchText
                }
            }),
            () => {
                this._filterChange();
            }
        );
    }, 500);

    private _onBarBackUrlClick = () => {
        this.props.history.push({
            search: ''
        });
        this._logic.maintenanceLogic().setSelectedMaintenanceTask(undefined);
        this._logic.maintenanceLogic().setDetailedMaintenanceTaskId(undefined);
        this._logic.maintenanceLogic().unsetCheckedMaintenanceTasks();
        this._logic.maintenanceLogic().reloadMaintenance();
    };
    // #endregion

    // #region dueFilter
    private _onDueFilterActiveClick = (selected: boolean) => {
        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    ...defaultFilters
                },
                dueFilter: {
                    ...state.dueFilter,
                    activeSelected: selected
                },
                search: {
                    text: ''
                }
            }),
            () => this._filterChange()
        );
    };

    private _onDueFilterDueSoonClick = (selected: boolean) => {
        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    ...defaultFilters
                },
                dueFilter: {
                    ...state.dueFilter,
                    dueSoonSelected: selected
                },
                search: {
                    text: ''
                }
            }),
            () => this._filterChange()
        );
    };

    private _onDueFilterOverdueClick = (selected: boolean) => {
        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    ...defaultFilters
                },
                dueFilter: {
                    ...state.dueFilter,
                    overdueSelected: selected
                },
                search: {
                    text: ''
                }
            }),
            () => this._filterChange()
        );
    };
    // #endregion

    // #region barFilter
    private _onFilterCancelClick = (): void => {
        this.setState(state => ({
            filter: {
                ...state.filter,
                isOpen: false
            }
        }));
    };

    private _onBarResetClick = () => {
        const defaults = confDefault.settings.statistics.maintenance.filter;
        this._onFilterConfirmClick(
            defaults.vehicles,
            defaults.trailers,
            defaults.drivers,
            defaults.taskTypes,
            defaults.intervals,
            defaults.status,
            defaults.dueDateGte,
            defaults.dueDateLte
        );
    };

    private _onFilterConfirmClick = (
        vehiclesChecked: string[],
        trailersChecked: string[],
        driversChecked: string[],
        taskTypeChecked: string[],
        intervalChecked: string[],
        stateChecked: string[],
        dueDateGte?: string,
        dueDateLte?: string
    ): void => {
        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    isOpen: false,
                    vehiclesChecked,
                    trailersChecked,
                    driversChecked,
                    taskTypeChecked,
                    intervalChecked,
                    stateChecked,
                    dueDateGte,
                    dueDateLte
                },
                dueFilter: {
                    ...state.dueFilter,
                    activeSelected: false,
                    dueSoonSelected: false,
                    overdueSelected: false
                }
            }),
            () => this._filterChange()
        );
    };

    private _filterChange = (pagination?: PaginationParams) => {
        this._logic.maintenanceLogic().setDetailedMaintenanceTaskId();
        this.props.history.push({
            search: ''
        });
        const filter = this._filters();

        this._logic.maintenanceLogic().setSettings({
            drivers: this.state.filter.driversChecked,
            trailers: this.state.filter.trailersChecked,
            vehicles: this.state.filter.vehiclesChecked,
            taskTypes: this.state.filter.taskTypeChecked,
            intervals: this.state.filter.intervalChecked,
            status: this.state.filter.stateChecked,
            dueDateGte: this.state.filter.dueDateGte ?? '',
            dueDateLte: this.state.filter.dueDateLte ?? ''
        });

        this._logic.maintenanceLogic().setMaintenanceFilter({
            ...filter,
            textSearch: this.state.search?.text,
            limit: pagination?.limit ?? DEFAULT_PAGE_LIMIT,
            offset: pagination?.offset ?? DEFAULT_PAGE_OFFSET
        });
        this._logic.maintenanceLogic().reloadMaintenance();
    };

    private _onBarFilterClick = () => {
        this.setState(state => ({
            filter: {
                ...state.filter,
                isOpen: !state.filter.isOpen
            }
        }));
    };
    // #endregion

    // #region table
    private _onTablePaginationChange = (pagination: PaginationParams) => {
        this._filterChange(pagination);
    };

    private _onTableRowCheckboxClick = (maintenanceTaskIds: string[]) => {
        this._logic.maintenanceLogic().setCheckedMaintenanceTasks(maintenanceTaskIds);
    };

    private _onTableRowClick = (maintenance: MaintenanceTaskTableModel) => {
        this._logic
            .maintenanceLogic()
            .setSelectedMaintenanceTask(
                (this._logic.maintenanceLogic().selectedMaintenanceTask &&
                    maintenance.id !== this._logic.maintenanceLogic().selectedMaintenanceTask?.id) ||
                    !this._logic.maintenanceLogic().selectedMaintenanceTask
                    ? maintenance
                    : undefined
            );

        if (this._logic.maintenanceLogic().selectedMaintenanceTask?.id) {
            this.props.logic.exponea().trackEvent(exponea.module.maintenanceDetail, {
                status: exponea.status.screenShown,
                src: 'table'
            });
        }
    };
    // #endregion

    private _getIntervalOpts = (): CheckboxGroupOpts => {
        return [
            {
                code: 'date',
                label: 'date',
                checked: false
            },
            {
                code: 'odometer',
                label: 'kilometer',
                checked: false
            }
        ];
    };

    private _getStateOpts = (): CheckboxGroupOpts => {
        return [
            {
                code: 'active',
                label: 'active',
                checked: false
            },
            {
                code: 'overdue',
                label: 'overdue',
                checked: false
            },
            {
                code: 'duesoon',
                label: 'dueSoon',
                checked: false
            },
            {
                code: 'done',
                label: 'done',
                checked: false
            }
        ];
    };

    private _toInitialValues = (
        maintenanceTask: MaintenanceTaskTableModel,
        receivers?: NotificationReceivers[]
    ): MaintenanceFormModel => {
        return {
            category: maintenanceTask?.category,
            vehicleIds: maintenanceTask?.vehicleId ? [maintenanceTask?.vehicleId] : [],
            trailerIds: maintenanceTask?.trailerId ? [maintenanceTask?.trailerId] : [],
            driverIds: maintenanceTask?.driverId ? [maintenanceTask?.driverId] : [],
            taskType: maintenanceTask?.taskType?.id,
            notes: maintenanceTask?.notes,
            notification: {
                dueDateDays: maintenanceTask?.notificationDueDateDays,
                dueMileageKilometers: maintenanceTask?.notificationDueMileageKilometers,
                recipients: receivers
                    ? maintenanceTask?.notificiationRecipients?.map(receiver => ({
                          ...receiver,
                          name: receiver.userId
                              ? receivers?.filter(r => String(r.entityId) === String(receiver.userId))?.[0]?.name ?? ''
                              : receiver.name
                      }))
                    : undefined
            },
            repetition: {
                date: !!maintenanceTask?.dueDate,
                bindToDueDate: maintenanceTask?.repetitionBindToDueDate,
                odometer: !!maintenanceTask?.dueMileageKilometers,
                interval: maintenanceTask?.repetitionInterval ?? MaintenanceRepetitionIntervalEnum.None,
                dueDate: maintenanceTask?.dueDate,
                dueDateType: maintenanceTask?.repetitionDueDateType,
                dueDateValue: maintenanceTask?.repetitionDueDateValue,
                dueMileageKilometers: maintenanceTask?.dueMileageKilometers,
                dueMileageKilometersInterval: maintenanceTask?.repetitionDueMileageKilometers
            },
            expenses: {
                currency: maintenanceTask?.expenses?.currency,
                priceTotal: maintenanceTask?.expenses?.priceTotalWoVat,
                supplier: maintenanceTask?.expenses?.contact,
                priceTotalWithVat:
                    maintenanceTask?.expenses?.priceTotalWoVat && maintenanceTask?.expenses?.vat
                        ? roundToStep(
                              maintenanceTask.expenses?.priceTotalWoVat * (1 + maintenanceTask.expenses.vat / 100),
                              0.01
                          )
                        : undefined,
                vat: maintenanceTask?.expenses?.vat,
                items: maintenanceTask?.expenses?.items ?? []
            }
        };
    };

    private _onTableRowDuplicateClick = (maintenanceId: string) => {
        const duplicate = this._logic.maintenanceLogic().maintenanceTasksTable.data.find(m => m.id === maintenanceId);

        this.setState(state => ({
            form: {
                ...state.form,
                isOpen: true,
                mode: 'CREATE',
                method: 'DUPLICATE',
                initialValues: duplicate ? this._toInitialValues(duplicate) : undefined,
                supplierSelected: duplicate?.expenses?.contact
            },
            maintenanceForm: {
                ...state.maintenanceForm,
                driversOpts: duplicate?.driverId
                    ? this._logic.maintenanceLogic().drivers.map(u => ({
                          key: Number(u.id),
                          value: `${u.name} ${u.surname}`
                      }))
                    : state.maintenanceForm.driversOpts,
                vehiclesOpts: duplicate?.vehicleId
                    ? this._logic.maintenanceLogic().vehicles.map(v => ({
                          key: Number(v.id),
                          value: v.registrationNumber
                      }))
                    : state.maintenanceForm.vehiclesOpts,
                trailersOpts: duplicate?.trailerId
                    ? this._logic.maintenanceLogic().trailers.map(t => ({
                          key: Number(t.id),
                          value: t.registrationNumber
                      }))
                    : state.maintenanceForm.trailersOpts,
                taskTypesOpts:
                    this._logic
                        .maintenanceLogic()
                        .maintenanceTaskTypes?.filter(d => d.category?.toString() === duplicate?.category?.toString())
                        .map(d => ({
                            id: d.id ?? '',
                            custom: d.customizable ?? false,
                            name: d.name ?? ''
                        })) ?? []
            }
        }));

        this._logic.exponea().trackEvent(exponea.module.statisticsMaintenance, {
            status: exponea.status.actionTaken,
            action: exponea.action.openDuplicateMaintenance
        });
    };

    private _onTableRowEditClick = async (maintenanceTaskId: string) => {
        const edit = this._logic.maintenanceLogic().maintenanceTasksTable.data.find(m => m.id === maintenanceTaskId);
        await this._logic.maintenanceLogic().setSelectedMaintenanceTask(edit);

        this.setState(state => ({
            form: {
                ...state.form,
                isOpen: true,
                mode: 'EDIT',
                editId: edit?.id,
                initialValues: edit
                    ? this._toInitialValues(edit, this._logic.maintenanceLogic().maintenanceNotificationReceivers)
                    : undefined,
                supplierSelected: edit?.expenses?.contact
            },
            maintenanceForm: {
                ...state.maintenanceForm,
                driversOpts: edit?.driverId
                    ? this._logic.maintenanceLogic().drivers.map(u => ({
                          key: Number(u.id),
                          value: `${u.name} ${u.surname}`
                      }))
                    : state.maintenanceForm.driversOpts,
                vehiclesOpts: edit?.vehicleId
                    ? this._logic.maintenanceLogic().vehicles.map(v => ({
                          key: Number(v.id),
                          value: v.registrationNumber
                      }))
                    : state.maintenanceForm.vehiclesOpts,
                trailersOpts: edit?.trailerId
                    ? this._logic.maintenanceLogic().trailers.map(t => ({
                          key: Number(t.id),
                          value: t.registrationNumber
                      }))
                    : state.maintenanceForm.trailersOpts,
                taskTypesOpts:
                    this._logic
                        .maintenanceLogic()
                        .maintenanceTaskTypes?.filter(d => d.category?.toString() === edit?.category?.toString())
                        .map(d => ({
                            id: d.id ?? '',
                            custom: d.customizable ?? false,
                            name: d.name ?? ''
                        })) ?? []
            }
        }));
    };

    private _onCreateCancelClick = () => {
        this.setState(state => ({
            form: {
                ...state.form,
                isOpen: false
            }
        }));
    };

    private _onSearchTextChange = debounce((search: string, category?: TaskTypeCategoryEnum) => {
        let opts;
        switch (category) {
            case TaskTypeCategoryEnum.Driver:
                opts = this._logic.maintenanceLogic().drivers.map(user => ({
                    code: String(user.id),
                    label: `${user.name} ${user.surname}`
                }));
                break;
            case TaskTypeCategoryEnum.Vehicle:
                opts = this._logic.maintenanceLogic().vehicles.map(vehicle => ({
                    code: String(vehicle.id),
                    label: vehicle.registrationNumber
                }));
                break;
            case TaskTypeCategoryEnum.Trailer:
                opts = this._logic.maintenanceLogic().trailers.map(trailer => ({
                    code: String(trailer.id),
                    label: trailer.registrationNumber
                }));
                break;
            default:
                break;
        }

        if (opts) {
            const filteredOpts = opts
                .filter(d => (search !== '' ? searched(search, d.label) : true))
                .map(d => ({
                    key: Number(d.code),
                    value: d.label ?? ''
                }));

            this.setState(state => ({
                ...state,
                maintenanceForm: {
                    ...state.maintenanceForm,
                    driversOpts:
                        category === TaskTypeCategoryEnum.Driver ? filteredOpts : state.maintenanceForm.driversOpts,
                    vehiclesOpts:
                        category === TaskTypeCategoryEnum.Vehicle ? filteredOpts : state.maintenanceForm.vehiclesOpts,
                    trailersOpts:
                        category === TaskTypeCategoryEnum.Trailer ? filteredOpts : state.maintenanceForm.trailersOpts
                }
            }));
        }
    }, 100);

    private _onSearchTaskTypeTextChange = debounce((search: string, category?: TaskTypeCategoryEnum) => {
        this.setState(state => ({
            ...state,
            maintenanceForm: {
                ...state.maintenanceForm,
                taskTypesOpts: this._logic
                    .maintenanceLogic()
                    .maintenanceTaskTypes?.filter(
                        d => (search !== '' ? searched(search, d.name) : true) && d.category === category
                    )
                    .map(d => ({
                        id: d.id ?? '',
                        custom: d.customizable ?? false,
                        name: d.name ?? ''
                    }))
            }
        }));
    }, 100);

    private _onFormSearchRecipientTextChange = debounce(async (search: string) => {
        await this._logic.maintenanceLogic().filterUsers(search);

        const userOpts: RecipientsSearchResult[] = this._logic.maintenanceLogic().usersSearch.map(d => ({
            name: `${d.name} ${d.surname}`,
            userId: Number(d.id)
        }));

        const roleOpts: RecipientsSearchResult[] = this._logic
            .maintenanceLogic()
            .roles.filter(r => (search !== '' && r.name ? searched(search, r.name) : true))
            .map(r => ({
                name: r.name ?? '',
                userRoleId: String(r.id)
            }));

        this.setState(state => ({
            ...state,
            maintenanceForm: {
                ...state.maintenanceForm,
                recipientsOpts: roleOpts.concat(userOpts)
            }
        }));
    }, 400);

    private _onCustomTypeDeleteClick = async (id: string) => {
        const count = await this._logic.maintenanceLogic()._getMaintenanceTaskOfMaintenanceTaskType(id);

        this.setState(state => ({
            ...state,
            customTypeDeleteConfirm: {
                id,
                count,
                processing: false,
                loading: false
            }
        }));
    };

    private _onCustomTypeEditSaveClick = async (id: string, name: string) => {
        const count = await this._logic.maintenanceLogic()._getMaintenanceTaskOfMaintenanceTaskType(id);
        this.setState(state => ({
            ...state,
            customTypeEditConfirm: {
                id,
                name,
                count: count,
                loading: false,
                processing: false
            }
        }));
    };

    private _onCategoryChange = (category: TaskTypeCategoryEnum) => {
        this.setState(state => ({
            ...state,
            maintenanceForm: {
                ...state.maintenanceForm,
                driversOpts:
                    category === TaskTypeCategoryEnum.Driver
                        ? this._logic.maintenanceLogic().drivers.map(u => ({
                              key: Number(u.id),
                              value: `${u.name} ${u.surname}`
                          }))
                        : state.maintenanceForm.driversOpts,
                vehiclesOpts:
                    category === TaskTypeCategoryEnum.Vehicle
                        ? this._logic.maintenanceLogic().vehicles.map(v => ({
                              key: Number(v.id),
                              value: v.registrationNumber
                          }))
                        : state.maintenanceForm.vehiclesOpts,
                trailersOpts:
                    category === TaskTypeCategoryEnum.Trailer
                        ? this._logic.maintenanceLogic().trailers.map(t => ({
                              key: Number(t.id),
                              value: t.registrationNumber
                          }))
                        : state.maintenanceForm.trailersOpts,
                taskTypesOpts:
                    this._logic
                        .maintenanceLogic()
                        .maintenanceTaskTypes?.filter(d => d.category?.toString() === category?.toString())
                        .map(d => ({
                            id: d.id ?? '',
                            custom: d.customizable ?? false,
                            name: d.name ?? ''
                        })) ?? []
            }
        }));
    };

    _toTaskTypeRepetitionDueDateTypeEnum = (
        repetitionDueDateType?: ReadOnlyTaskTypeRepetitionDueDateTypeEnum | MaintenanceTaskRepetitionDueDateTypeEnum
    ): TaskTypeRepetitionDueDateTypeEnum => {
        switch (repetitionDueDateType) {
            case MaintenanceTaskRepetitionDueDateTypeEnum.Day:
            case ReadOnlyTaskTypeRepetitionDueDateTypeEnum.Day: {
                return TaskTypeRepetitionDueDateTypeEnum.Day;
            }
            case MaintenanceTaskRepetitionDueDateTypeEnum.Month:
            case ReadOnlyTaskTypeRepetitionDueDateTypeEnum.Month: {
                return TaskTypeRepetitionDueDateTypeEnum.Month;
            }
            case MaintenanceTaskRepetitionDueDateTypeEnum.Week:
            case ReadOnlyTaskTypeRepetitionDueDateTypeEnum.Week: {
                return TaskTypeRepetitionDueDateTypeEnum.Week;
            }
            default:
            case MaintenanceTaskRepetitionDueDateTypeEnum.Year:
            case ReadOnlyTaskTypeRepetitionDueDateTypeEnum.Year: {
                return TaskTypeRepetitionDueDateTypeEnum.Year;
            }
        }
    };

    _toMaintenanceTaskListRepetitionDueDateTypeEnum = (
        repetitionDueDateType?: MaintenanceTaskRepetitionDueDateTypeEnum
    ): MaintenanceTaskListRepetitionDueDateTypeEnum => {
        switch (repetitionDueDateType) {
            case MaintenanceTaskRepetitionDueDateTypeEnum.Day: {
                return MaintenanceTaskListRepetitionDueDateTypeEnum.Day;
            }
            case MaintenanceTaskRepetitionDueDateTypeEnum.Month: {
                return MaintenanceTaskListRepetitionDueDateTypeEnum.Month;
            }
            case MaintenanceTaskRepetitionDueDateTypeEnum.Week: {
                return MaintenanceTaskListRepetitionDueDateTypeEnum.Week;
            }
            default:
            case MaintenanceTaskRepetitionDueDateTypeEnum.Year: {
                return MaintenanceTaskListRepetitionDueDateTypeEnum.Year;
            }
        }
    };

    private _onFormSubmit = async (model: MaintenanceFormModel): Promise<boolean> => {
        try {
            const selectedTaskType = this._logic
                .maintenanceLogic()
                .maintenanceTaskTypes.find(tt => tt.id === model.taskType);

            const emailReceivers: NotificationReceivers[] =
                model.notification.recipients
                    ?.filter(
                        receiver =>
                            receiver.emailNotification &&
                            (receiver.userId || receiver.userRoleId || receiver.name !== '')
                    )
                    .map(
                        receiver =>
                            ({
                                entity: receiver.email ? 'external' : receiver.userId ? 'user' : 'role',
                                entityId: receiver.userId ?? receiver.userRoleId ?? receiver.name,
                                channel: 'email'
                            } as NotificationReceivers)
                    ) ?? [];
            const systemReceivers: NotificationReceivers[] =
                model.notification.recipients
                    ?.filter(receiver => receiver.systemNotification)
                    .map(
                        receiver =>
                            ({
                                entity: receiver.userId ? 'user' : 'role',
                                entityId: receiver.userId ?? receiver.userRoleId,
                                channel: 'app'
                            } as NotificationReceivers)
                    ) ?? [];

            const maintenanceTask: MaintenanceTaskList = {
                monitoredObjects:
                    model.category === TaskTypeCategoryEnum.Vehicle && model.vehicleIds
                        ? model.vehicleIds
                        : model.category === TaskTypeCategoryEnum.Trailer && model.trailerIds
                        ? model.trailerIds
                        : undefined,
                users: model.category === TaskTypeCategoryEnum.Driver && model.driverIds ? model.driverIds : undefined,
                clientId: this._logic.auth().client()?.id!,
                createdAt: moment().toDate(),
                taskType: selectedTaskType
                    ? {
                          id: selectedTaskType.id,
                          category: model.category!,
                          customizable: selectedTaskType.customizable,
                          repetitionDueDateType: selectedTaskType.repetitionDueDateType
                              ? this._toTaskTypeRepetitionDueDateTypeEnum(selectedTaskType.repetitionDueDateType)
                              : undefined,
                          repetitionDueDateValue: selectedTaskType.repetitionDueDateValue,
                          name: selectedTaskType.name,
                          clientId: this._logic.auth().client()?.id!
                      }
                    : {
                          category: model.category!,
                          name: model.taskTypeNameNew ?? '',
                          clientId: this._logic.auth().client()?.id!,
                          customizable: true,
                          repetitionDueDateType: model.repetition.dueDateType
                              ? this._toTaskTypeRepetitionDueDateTypeEnum(model.repetition.dueDateType)
                              : undefined,
                          repetitionDueDateValue: model.repetition.dueDateValue
                      },
                dueDate: model.repetition.date ? moment(model.repetition.dueDate).startOf('day').toDate() : null,
                dueMileageKilometers: model.repetition.odometer ? model.repetition.dueMileageKilometers : null,
                repetitionDueMileageKilometers: model.repetition.odometer
                    ? model.repetition.dueMileageKilometersInterval
                    : null,
                repetitionDueDateType: model.repetition.dueDateType
                    ? this._toMaintenanceTaskListRepetitionDueDateTypeEnum(model.repetition.dueDateType)
                    : undefined,
                repetitionDueDateValue: model.repetition.dueDateValue,
                repetitionBindToDueDate: model.repetition.date ? model.repetition.bindToDueDate : undefined,
                notificationDueDateDays: model.repetition.date ? model.notification.dueDateDays : null,
                notificationRecievers: emailReceivers?.concat(systemReceivers),
                notificationDueMileageKilometers: model.repetition.odometer
                    ? model.notification.dueMileageKilometers
                    : null,
                expenses: {
                    contact: model.expenses.supplier,
                    currency: model.expenses.currency,
                    priceTotalWoVat: model.expenses.priceTotal,
                    vat: model.expenses.vat,
                    items: model.expenses.items?.length
                        ? model.expenses?.items?.map(item => ({
                              name: item.name ?? '',
                              type: item.type ?? ExpensesItemsTypeEnum.Other,
                              priceWoVat: item.priceWoVat ?? 0
                          }))
                        : undefined
                },
                notes: model.notes
            };
            const edit = this._logic
                .maintenanceLogic()
                .maintenanceTasksTable.data.find(m => m.id === this.state.form.editId);
            const editId = this.state.form.editId;
            if (this.state.form.mode === 'EDIT' && editId) {
                const editMaintenanceTask: MaintenanceTask = {
                    ...maintenanceTask,
                    repetitionDueDateType: model.repetition.dueDateType,
                    place: undefined,
                    contact: undefined,
                    createdAt: undefined,
                    updatedAt: edit?.updatedAt,
                    monitoredObject: maintenanceTask.monitoredObjects?.[0],
                    user: maintenanceTask.users?.[0]
                };

                try {
                    const update = await this._logic
                        .maintenanceLogic()
                        .updateMaintenanceTask(editMaintenanceTask, editId);

                    if (update) {
                        message.success(this.props.t('Maintenance.message.updateSuccess'));
                    } else {
                        message.error(this.props.t('Maintenance.message.updateError'));
                    }
                } catch (err: any) {
                    if (err.json) {
                        (err.json() as Promise<any>).then(data => {
                            if (data['Conflict error']) {
                                Modal.confirm({
                                    title: this.props.t('Maintenance.concurrencyViolation'),
                                    okText: this.props.t('common.confirm'),
                                    onOk: async () => {
                                        try {
                                            const update = await this._logic
                                                .maintenanceLogic()
                                                .updateMaintenanceTask(editMaintenanceTask, editId, true);

                                            if (update) {
                                                message.success(this.props.t('Maintenance.message.updateSuccess'));
                                            } else {
                                                message.error(this.props.t('Maintenance.message.updateError'));
                                            }
                                        } catch (err) {
                                            console.error(`Update maintenance error, err: ${err}`);
                                            message.error(this.props.t('Maintenance.message.updateError'));
                                        }
                                    },
                                    onCancel: () => {
                                        this._logic.maintenanceLogic().reloadMaintenance();
                                    }
                                });
                            } else {
                                throw err;
                            }
                        });
                    }
                }
            } else {
                this._logic.exponea().trackEvent(exponea.module.statisticsMaintenance, {
                    status: exponea.status.actionTaken,
                    action:
                        this.state.form.method === 'NEW'
                            ? exponea.action.submitNewMaintenance
                            : exponea.action.submitDuplicateMaintenance
                });

                const create = await this._logic.maintenanceLogic().createMaintenanceTask(maintenanceTask);
                if (create) {
                    message.success(this.props.t('Maintenance.message.createSuccess'));
                } else {
                    message.error(this.props.t('Maintenance.message.createError'));
                }
            }
            this.setState(state => ({
                form: {
                    ...state.form,
                    isOpen: false
                }
            }));
            return true;
        } catch (err) {
            if (this.state.form.mode === 'EDIT' && this.state.form.editId) {
                console.error(`Update maintenance error, err: ${err}`);
                message.error(this.props.t('Maintenance.message.updateError'));
            } else {
                console.error(`Create maintenance error, err: ${err}`);
                message.error(this.props.t('Maintenance.message.createError'));
            }
        } finally {
            this.setState(state => ({
                form: {
                    ...state.form,
                    isOpen: false
                }
            }));
        }

        return false;
    };

    private _onFormCancel = () => {
        this.setState(state => ({
            form: {
                ...state.form,
                isOpen: false
            }
        }));
    };

    private _onCompletitionSubmit = async (values: MaintenanceCompletitionModel) => {
        try {
            const res = await this._logic.maintenanceLogic().completeMaintenanceTask({
                data: {
                    completionTime: moment(values.date).toDate(),
                    completionMileageKilometers: values.odometer,
                    tasks: this._logic.maintenanceLogic().forCompletionMaintenanceTasks.map(m => m.id)
                }
            });
            if (res) {
                message.success(this.props.t('Maintenance.message.completeSuccess'));
                this._logic.maintenanceLogic().unsetCheckedMaintenanceTasks();
            } else {
                message.error(this.props.t('Maintenance.message.completeError'));
            }
        } catch (err) {
            console.error(`Complete maintenance error, err: ${err}`);
            message.error(this.props.t('Maintenance.message.completeError'));
        } finally {
            this.setState(state => ({
                complete: {
                    ...state.complete,
                    isOpen: false
                }
            }));
        }

        return true;
    };

    private _onDeleteSubmit = async () => {
        try {
            const res = await this._logic
                .maintenanceLogic()
                .deleteMaintenanceTasks(this._logic.maintenanceLogic().forDeletionMaintenanceTasks.map(m => m.id));
            if (res) {
                message.success(this.props.t('Maintenance.message.deleteSuccess'));
                this._logic.maintenanceLogic().unsetCheckedMaintenanceTasks();
            } else {
                message.error(this.props.t('Maintenance.message.deleteError'));
            }
        } catch (err) {
            console.error(`Delete maintenance error, err: ${err}`);
            message.error(this.props.t('Maintenance.message.deleteError'));
        } finally {
            this.setState(state => ({
                delete: {
                    ...state.delete,
                    isOpen: false
                }
            }));
        }

        return true;
    };

    private _onDeleteCancel = () => {
        this._logic.maintenanceLogic().forDeletionMaintenanceTasks = [];
        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: false
            }
        }));
    };

    private _onCompletitionCancel = () => {
        this._logic.maintenanceLogic().forCompletionMaintenanceTasks = [];
        this.setState(state => ({
            complete: {
                ...state.complete,
                isOpen: false
            }
        }));
    };

    private _onTaskTypeDeleteConfirm = async () => {
        try {
            const res = await this._logic
                .maintenanceLogic()
                .deleteMaintenanceTaskTypeWithTasks(this.state.customTypeDeleteConfirm?.id!);

            this.setState(state => ({
                maintenanceForm: {
                    ...state.maintenanceForm,
                    taskTypesOpts: state.maintenanceForm.taskTypesOpts?.filter(
                        tt => tt.id !== this.state.customTypeDeleteConfirm?.id
                    )
                }
            }));
            if (res) {
                message.success(this.props.t('Maintenance.message.deleteTaskTypeSuccess'));
            } else {
                message.error(this.props.t('Maintenance.message.deleteTaskTypeError'));
            }
        } catch (err) {
            console.error(`Task type delete error, err: ${err}`);
            message.error(this.props.t('Maintenance.message.deleteTaskTypeError'));
        } finally {
            this.setState(state => ({
                ...state,
                customTypeDeleteConfirm: undefined
            }));
        }
    };

    private _onTaskTypeEditConfirm = async () => {
        try {
            if (this.state.customTypeEditConfirm?.id && this.state.customTypeEditConfirm?.name) {
                const res = await this._logic
                    .maintenanceLogic()
                    .editMaintenanceTaskType(
                        this.state.customTypeEditConfirm?.id,
                        this.state.customTypeEditConfirm?.name
                    );

                this.setState(state => ({
                    maintenanceForm: {
                        ...state.maintenanceForm,
                        taskTypesOpts: state.maintenanceForm.taskTypesOpts?.map(tt => ({
                            ...tt,
                            name:
                                tt.id === state.customTypeEditConfirm?.id && this.state.customTypeEditConfirm?.name
                                    ? this.state.customTypeEditConfirm?.name
                                    : tt.name
                        }))
                    }
                }));
                if (res) {
                    message.success(this.props.t('Maintenance.message.editTaskTypeSuccess'));
                } else {
                    message.error(this.props.t('Maintenance.message.editTaskTypeError'));
                }
            }
        } catch (err) {
            console.error(`Task type edit error, err: ${err}`);
            message.error(this.props.t('Maintenance.message.editTaskTypeError'));
        } finally {
            this.setState(state => ({
                ...state,
                customTypeEditConfirm: undefined
            }));
        }
    };

    private _filters = () => {
        let dueStates: string[] = [];
        if (this.state.dueFilter.activeSelected) {
            dueStates.push('active');
        }
        if (this.state.dueFilter.dueSoonSelected) {
            dueStates.push('duesoon');
        }
        if (this.state.dueFilter.overdueSelected) {
            dueStates.push('overdue');
        }

        return {
            includeStates:
                dueStates.length > 0
                    ? dueStates
                    : this.state.filter.stateChecked.length > 0
                    ? this.state.filter.stateChecked
                    : undefined,
            monitoredObjects:
                this.state.filter.vehiclesChecked.length > 0 || this.state.filter.trailersChecked.length > 0
                    ? this.state.filter.vehiclesChecked.concat(this.state.filter.trailersChecked).map(d => Number(d))
                    : undefined,
            users:
                this.state.filter.driversChecked.length > 0
                    ? this.state.filter.driversChecked.map(d => Number(d))
                    : undefined,
            taskTypeIds:
                this.state.filter.taskTypeChecked.length > 0 ? this.state.filter.taskTypeChecked.join() : undefined,
            intervalIsDate: this.state.filter.intervalChecked?.includes('date'),
            intervalIsKm: this.state.filter.intervalChecked?.includes('odometer'),
            dueDateGte: this.state.filter.dueDateGte,
            dueDateLte: this.state.filter.dueDateLte
        };
    };

    // #region newSupplier
    private _onFormSupplierCreateClick = () => {
        this.setState({
            newSupplier: {
                isOpen: true
            }
        });
    };

    private _onCreateSupplierSubmit = async (client: ContactList) => {
        try {
            const supplier = await this._logic.maintenanceLogic().createClientContact(client);
            message.success('Client created successfully');

            this.setState(state => ({
                newSupplier: {
                    isOpen: false
                },
                form: {
                    ...state.form,
                    supplierSelected: supplier.id
                }
            }));

            return true;
        } catch (err) {
            message.error('Could not create client, try again later');
            return false;
        }
    };

    private _onCreateSupplierClose = () => {
        this.setState({
            newSupplier: {
                isOpen: false
            }
        });
    };
    // #endregion

    private _onFormSupplierSelectClick = (id: string) => {
        this.setState(state => ({
            form: {
                ...state.form,
                supplierSelected: id
            }
        }));
    };

    private _onLayoutSidePanelCloseClick = () => {
        this._logic.maintenanceLogic().setSelectedMaintenanceTask(undefined);
    };
}

export default withTranslation()(withRouter(observer(MaintenanceModule)));
