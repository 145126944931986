import { withTranslation, WithTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import TableBar from 'common/components/TableBar';

interface Props extends WithTranslation {
    roles: Role[];
    onBackClick?: () => void;
}

function DispatcherBoardDetailBar(props: Props) {
    return (
        <TableBar
            className="disp-board-bar"
            backButton={{
                onClick: props.roles.includes(Role.BRD_R) ? props.onBackClick : undefined,
                title: props.t('DispatcherBoardDetail.backToMainBoard')
            }}
            heading={props.t('DispatcherBoardDetail.dispatcherBoardTransportDetailTitle')}
        />
    );
}

export default withTranslation()(DispatcherBoardDetailBar);
