/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum NegotiationState {
    Generated = 'generated',
    ShipperOffer = 'shipper_offer',
    CarrierOffer = 'carrier_offer',
    Completed = 'completed',
    Declined = 'declined',
    Expired = 'expired'
}

export function NegotiationStateFromJSON(json: any): NegotiationState {
    return NegotiationStateFromJSONTyped(json, false);
}

export function NegotiationStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationState {
    return json as NegotiationState;
}

export function NegotiationStateToJSON(value?: NegotiationState | null): any {
    return value as any;
}

