import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import qs from 'qs';
import i18n from 'i18next';

import { confDefault } from 'conf';
import { Role } from 'logic/auth';
import { exponea } from 'logic/exponea';
import { CheckboxGroupOpt, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { RouteNames } from 'App';

import FuelConsumption from './ui/FuelConsumption';
import { Summary } from './ui/FuelConsumptionTable';
import { RouteParams } from 'modules/statistics/modules/journeys/JourneysModule';
import { message } from 'antd';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { DateRange } from 'common/model/date-time';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { DriverModel } from 'logic/user/users';
import { FuelStatisticsGroup, FuelStatsGroupByEntity, FuelStatsGroupByTimespan } from 'generated/backend-api';

export type FuelConsumptionHead = [[string, number][], string[]];

export type FilterOptsOut = {
    drivers: string[];
    vehicles: string[];
    shortActivity: boolean;
    fuelSuspiciousActivity: boolean;
};

export enum FuelConsumptionDataFilter {
    shortActivity = 'shortActivity',
    fuelSuspiciousActivity = 'fuelSuspiciousActivity'
}

export type GroupByFuelConsumption = {
    date: FuelStatsGroupByTimespan;
    entity: FuelStatsGroupByEntity;
};

export type SettingsOptsOut = {
    grouping: string;
    ident: string;
};

export interface FuelConsumptionFilterModel {
    fullDWL?: boolean;
    driversOpts: CheckboxGroupOpts;
    vehiclesOpts: CheckboxGroupOpts;
    driversChecked: string[];
    vehiclesChecked: string[];
    shortActivity: boolean;
    fuelSuspiciousActivity: boolean;
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

interface State {
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    drivers: DriverModel[];
    bar: {
        summary: boolean;
        filterOpen: boolean;
        settings: boolean;
        dateOpen: boolean;
        dateChanged?: boolean;
        backUrl?: boolean;
        exportEnable: boolean;
        exportProcessing: boolean;
    };
    filter: FuelConsumptionFilterModel;
    helper?: {
        content: string;
    };
    roles: Role[];
    dateRange: DateRange;
    table: FuelConsumptionTableModel;
    summary?: Summary;
    backUrlProps?: string;
    vehicleUnpermissioned?: boolean;
}

export interface FuelConsumptionTableModel {
    data: FuelStatisticsGroup[];
    groupBy: GroupByFuelConsumption;
    summary?: Summary;
    expanded?: string;
    loading: boolean;
}

const defaultValuesBar = {
    summary: false,
    filterOpen: false,
    settings: false,
    dateOpen: false
};

class FuelConsumptionModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const settings = this._logic.statisticsFuelConsumption().settings();
        const defaults = confDefault.settings.statistics.fuelConsumption.filter;

        const params: RouteParams = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        const roles: Role[] = this._logic.auth().roles();
        this.state = {
            vehicles: [],
            drivers: [],
            bar: {
                summary: false,
                filterOpen: false,
                settings: false,
                dateOpen: false,
                exportEnable: false,
                exportProcessing: false
            },
            filter: {
                driversOpts: [],
                vehiclesOpts: [],
                driversChecked: settings.drivers,
                vehiclesChecked: settings.vehicles,
                shortActivity: settings.shortActivity ?? defaults.shortActivity,
                fuelSuspiciousActivity: settings.fuelSuspiciousActivity ?? defaults.fuelSuspiciousActivity
            },
            dateRange: {
                start: params.startDate ? params.startDate : defaults.dateRange.start,
                end: params.endDate ? params.endDate : defaults.dateRange.end
            },
            table: {
                data: [],
                groupBy: {
                    date: settings.groupByTimespan ?? FuelStatsGroupByTimespan.Day,
                    entity: settings.groupByEntity ?? FuelStatsGroupByEntity.Vehicle
                },
                loading: true
            },
            roles
        };
    }

    componentDidMount() {
        (window as any).app.FuelConsumptionModul = this;

        const params: RouteParams = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        const filterParams = params.driverId !== undefined || params.vehicleId !== undefined ? true : false;
        const settings = this._logic.statisticsFuelConsumption().settings();
        const defaults = confDefault.settings.statistics.fuelConsumption.filter;

        this.setState(state => ({
            ...state,
            bar: {
                ...state.bar,
                dateChanged:
                    (params.startDate && params.startDate !== defaults.dateRange.start) ||
                    (params.endDate && params.endDate !== defaults.dateRange.end)
                        ? true
                        : false,
                backUrl: params.startDate && params.endDate ? true : false
            },
            filter: {
                ...state.filter,
                vehiclesChecked:
                    params.vehicleId !== undefined ? [params.vehicleId] : filterParams ? [] : settings.vehicles,
                driversChecked: params.driverId !== undefined ? [params.driverId] : filterParams ? [] : settings.drivers
            },
            backUrlProps: params.startDate && params.endDate ? qs.stringify(params) : undefined
        }));

        this._filterData()
            .then(() => {
                if (!params.vehicleId && params.driverId) {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            groupBy: {
                                date: settings.groupByTimespan,
                                entity: FuelStatsGroupByEntity.Vehicle
                            }
                        }
                    }));
                }

                if ((!params.driverId && params.vehicleId) || (params.driverId && params.vehicleId)) {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            groupBy: {
                                date: settings.groupByTimespan,
                                entity: FuelStatsGroupByEntity.Driver
                            }
                        }
                    }));
                }

                if (params.vehicleId && !this.state.filter.vehiclesOpts.map(v => v.code).includes(params.vehicleId)) {
                    this.setState({ vehicleUnpermissioned: true });
                }

                this._logic
                    .statisticsFuelConsumption()
                    .loadData({
                        drivers: this.state.filter.driversChecked,
                        vehicles: this.state.filter.vehiclesChecked,
                        dateRange: this.state.dateRange,
                        groupByTimespan: this.state.table.groupBy.date,
                        groupByEntity: this.state.table.groupBy.entity
                    })
                    .then(data =>
                        this.setState(state => ({
                            bar: {
                                ...state.bar,
                                exportEnable: data.length > 0
                            },
                            table: { ...state.table, data, loading: false }
                        }))
                    )

                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                    });

                this._logic.settings().onChange(prop => {
                    if (prop.vehicleIdentification) {
                        this._logic.vehicles().changeVehicleIdentification(prop.vehicleIdentification);
                        this._logic
                            .statisticsFuelConsumption()
                            .table({
                                drivers: this.state.filter.driversChecked,
                                vehicles: this.state.filter.vehiclesChecked,
                                dateRange: this.state.dateRange,
                                groupByTimespan: this.state.table.groupBy.date,
                                groupByEntity: this.state.table.groupBy.entity
                            })
                            .then(data =>
                                this.setState(state => ({
                                    bar: {
                                        ...state.bar,
                                        exportEnable: data.length > 0
                                    },
                                    table: { ...state.table, data, loading: false }
                                }))
                            )
                            .catch(err => {
                                message.error(this.props.t('common.error.loadDataError'));
                                console.error(`Load data error, err: ${err}`);
                                this.setState(state => ({
                                    table: {
                                        ...state.table,
                                        loading: false
                                    }
                                }));
                            });
                    }
                });
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(err);
            });
    }

    render() {
        return (
            <FuelConsumption
                demoMode={this._logic.demo().isActive}
                vehicles={this.state.vehicles}
                drivers={this.state.drivers}
                bar={this.state.bar}
                filter={this.state.filter}
                helper={this.state.helper}
                dateRange={this.state.dateRange}
                table={this.state.table}
                vehicleUnpermissioned={this.state.vehicleUnpermissioned}
                roles={this.state.roles}
                onExport={this._onExport}
                onBarSummaryClick={this._onBarSummary}
                onBarFilterClick={this._onBarFilterClick}
                onBarHelperClick={this._onBarHelperClick}
                onBarSettingsClick={this._onBarSettings}
                onBarBackUrlClick={this._onBarBackUrl}
                onBarResetClick={this._onBarResetClick}
                onDateRangeChange={this._onDateRangeChange}
                onFilterConfirm={this._onFilterConfirm}
                onFilterCancel={this._onFilterCancel}
                onHelperClose={this._onHelperClose}
                onSettingsConfirm={this._onSettingsConfirm}
                onSettingsCancel={this._onSettingsCancel}
                onTableItemSelect={this._onItemSelect}
                onTableFilterDate={this._onTableGroupByTimespanChange}
                onTableSwitchDriverVehicle={this._onTableGroupByEntity}
                onVehicleUnpermissionedClose={this._onVehicleUnpermissionedClose}
            />
        );
    }

    private loadData = () => {
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this._logic
                    .statisticsFuelConsumption()
                    .table({
                        drivers: this.state.filter.driversChecked,
                        vehicles: this.state.filter.vehiclesChecked,
                        dateRange: this.state.dateRange,
                        groupByTimespan: this.state.table.groupBy.date,
                        groupByEntity: this.state.table.groupBy.entity
                    })
                    .then(data => {
                        const defaults = confDefault.settings.statistics.fuelConsumption.filter;

                        const filteredData = data.map(d => ({
                            ...d,
                            entries: d.entries?.filter(
                                entry =>
                                    (!this.state.filter.shortActivity ||
                                        entry.stats.distanceKilometers > defaults.shortActivityLimit) &&
                                    (!this.state.filter.fuelSuspiciousActivity ||
                                        entry.stats.consumptionActivityLitersPer100km <
                                            defaults.fuelSuspiciousActivityLimit)
                            )
                        }));
                        this.setState(state => ({
                            bar: {
                                ...state.bar,
                                exportEnable: filteredData.length > 0
                            },
                            table: { ...state.table, data: filteredData, loading: false }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            }
        );
    };

    private _onExport = (): void => {
        const handleError = (err: any) => {
            console.error(`Load data error, err: ${err}`);
            message.error(this.props.t('common.error.loadDataError'));
            this.setState(state => ({
                bar: {
                    ...state.bar,
                    exportProcessing: false
                }
            }));
        };
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    exportProcessing: true
                }
            }),
            () => {
                this._logic
                    .statisticsFuelConsumption()
                    .getFuelConsumption({
                        drivers: this.state.filter.driversChecked,
                        vehicles: this.state.filter.vehiclesChecked,
                        dateRange: this.state.dateRange,
                        groupByTimespan: this.state.table.groupBy.date,
                        groupByEntity: this.state.table.groupBy.entity
                    })
                    .then(data => {
                        const groupedData = data;
                        this._logic
                            .statisticsFuelConsumption()
                            .downloadFuelConsumptionExport(
                                groupedData,
                                this.state.dateRange.start,
                                this.state.dateRange.end,
                                this.state.table.groupBy.entity
                            )
                            .then(() => {
                                this.setState(state => ({
                                    bar: {
                                        ...state.bar,
                                        exportProcessing: false
                                    }
                                }));
                            })
                            .catch(err => {
                                handleError(err);
                            });
                    })
                    .catch(err => {
                        handleError(err);
                    });
            }
        );
    };

    private _onSettingsConfirm = () => undefined;

    private _onBarResetClick = (): void => {
        const defaults = confDefault.settings.statistics.fuelConsumption.filter;
        this._onFilterConfirm(
            defaults.drivers,
            defaults.vehicles,
            defaults.shortActivity,
            defaults.fuelSuspiciousActivity
        );
    };

    private _onBarBackUrl = (): void => {
        const { backUrlProps } = this.state;
        if (backUrlProps) {
            this.props.history.push({
                pathname: RouteNames.STATISTICS_JOURNEYS_ACTIVITY,
                search: backUrlProps
            });
        }
    };

    private _onTableGroupByTimespanChange = (groupByTimespan: FuelStatsGroupByTimespan): void => {
        this._logic.statisticsFuelConsumption().setSettings({ groupByTimespan });
        if (this.state.table) {
            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        groupBy: { ...state.table.groupBy, date: groupByTimespan }
                    }
                }),
                () => {
                    this.loadData();
                }
            );
        }
    };

    private _onTableGroupByEntity = (groupByEntity: FuelStatsGroupByEntity): void => {
        this._logic.statisticsFuelConsumption().setSettings({ groupByEntity });
        if (this.state.table) {
            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        groupBy: {
                            ...state.table.groupBy,
                            entity: groupByEntity
                        }
                    }
                }),
                () => {
                    this.loadData();
                }
            );
        }
    };

    private _filterData = async () => {
        const drivers = await this._logic.users().drivers();
        const driversOpts = drivers.map<CheckboxGroupOpt>(u => ({
            code: u.id,
            label: `${u.name} ${u.surname}`,
            checked: false
        }));

        const vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.FC_R]);
        const vehiclesOpts = vehicles.map<CheckboxGroupOpt>(v => ({
            code: String(v.id),
            label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
            checked: false
        }));

        this.setState(state => ({
            vehicles: vehicles,
            drivers: drivers,
            filter: {
                ...state.filter,
                fullDWL: this._logic.vehicles().fullDWL,
                driversChecked:
                    state.filter.driversChecked.length > 0 && state.filter.driversChecked.length < driversOpts.length
                        ? state.filter.driversChecked
                        : driversOpts.map(v => v.code),
                driversOpts,
                vehiclesChecked:
                    state.filter.vehiclesChecked.length > 0 && state.filter.vehiclesChecked.length < vehiclesOpts.length
                        ? state.filter.vehiclesChecked
                        : vehiclesOpts.map(v => v.code),
                vehiclesOpts
            }
        }));
    };

    private _onBarFilterClick = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultValuesBar,
                filterOpen: !state.bar.filterOpen,
                backUrl: state.bar.backUrl
            }
        }));
    };

    private _onBarSettings = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultValuesBar,
                settings: !state.bar.settings,
                backUrl: state.bar.backUrl
            }
        }));
    };

    private _onSettingsCancel = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultValuesBar,
                backUrl: state.bar.backUrl
            }
        }));
    };

    private _onBarSummary = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultValuesBar,
                summary: !state.bar.summary,
                backUrl: state.bar.backUrl
            },
            summary: state.summary
                ? undefined
                : {
                      journeyLiters: 0,
                      journeyAFC: 0,
                      drivingLiters: 0,
                      drivingDistance: 0,
                      drivingAFC: 0,
                      idlingLiters: 0,
                      idlingTaken: 0,
                      idlingAFC: 0,
                      ptoLiters: 0,
                      ptoTaken: 0,
                      ptoAFC: 0
                  }
        }));
    };

    private _onDateRangeChange = async (dateRange: DateRange): Promise<void> => {
        this._logic.exponea().trackEvent(exponea.module.statisticsFuelConsumption, {
            status: exponea.status.actionTaken,
            action: exponea.action.calendar
        });

        this.props.history.push({
            search: qs.stringify({
                startDate: dateRange.start,
                endDate: dateRange.end
            } as RouteParams)
        });
        const defaults = confDefault.settings.statistics.fuelConsumption.filter;

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    ...defaultValuesBar,
                    dateOpen: false,
                    dateChanged:
                        dateRange.start !== defaults.dateRange.start || dateRange.end !== defaults.dateRange.end,
                    backUrl: state.bar.backUrl
                },
                dateRange,
                table: {
                    ...state.table
                }
            }),
            () => {
                this.loadData();
            }
        );
    };

    private _onItemSelect = (id: string): void => {
        if (this.state.table && this.state.table.data.find((d, i) => i === Number(id))!.entries!.length > 0) {
            const { table } = this.state;
            if (this.state.table.expanded === id) {
                this.setState({ table: { ...table, expanded: undefined } });
            } else {
                this.setState({ table: { ...table, expanded: id } });
            }
        }
    };

    private _onFilterConfirm = (
        driversChecked: string[],
        vehiclesChecked: string[],
        shortActivity: boolean,
        fuelSuspiciousActivity: boolean
    ): void => {
        this._logic.exponea().trackEvent(exponea.module.statisticsFuelConsumption, {
            status: exponea.status.actionTaken,
            action: exponea.action.filter
        });

        this._logic
            .statisticsFuelConsumption()
            .setSettings({ drivers: driversChecked, vehicles: vehiclesChecked, shortActivity, fuelSuspiciousActivity });
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    ...defaultValuesBar,
                    backUrl: state.bar.backUrl
                },
                filter: { ...state.filter, driversChecked, vehiclesChecked, shortActivity, fuelSuspiciousActivity },
                table: {
                    ...state.table
                }
            }),
            () => {
                this.loadData();
            }
        );
    };

    private _onFilterCancel = (): void => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultValuesBar,
                backUrl: state.bar.backUrl
            }
        }));
    };

    private _onVehicleUnpermissionedClose = (): void => {
        this.setState({
            vehicleUnpermissioned: false
        });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'can';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(FuelConsumptionModule));
