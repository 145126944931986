import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import Button from 'common/components/Button';
import qa from 'qa-selectors';

interface Props {
    editDisabled: boolean;
    onEditClick?: () => void;
    onCompleteClick?: () => void;
    onDeleteClick?: () => void;
}

function MaintenanceActions(props: Props) {
    const { t } = useTranslation();
    const { editDisabled } = props;

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={editDisabled}
                        qa={qa.maintenance.detail.btnEdit}
                        onClick={props.onEditClick}
                    >
                        {t('Maintenance.task.editMaintenanceTask')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={editDisabled}
                        qa={qa.maintenance.detail.btnMarkAsComplete}
                        onClick={props.onCompleteClick}
                    >
                        {t('Maintenance.complete.markAsComplete')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button block type="primary" qa={qa.maintenance.detail.btnDelete} onClick={props.onDeleteClick}>
                        {t('Maintenance.delete.deleteTask')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default MaintenanceActions;
