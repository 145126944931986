import React from 'react';
import { Menu, MenuItemProps, MenuProps } from 'antd';

interface Props extends MenuProps {}

function EnhancedMenu(props: Props) {
    return <Menu {...props} />;
}

EnhancedMenu.SubMenu = Menu.SubMenu;

export interface EnhancedMenuItemProps extends MenuItemProps {
    qa?: string;
}

EnhancedMenu.Item = function ({ qa, ...props }: EnhancedMenuItemProps) {
    return <Menu.Item data-qa={qa} {...props} />;
};

export default EnhancedMenu;
