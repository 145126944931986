import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import qs from 'qs';
import moment from 'moment';
import { debounce } from 'debounce';
import i18n from 'i18next';

import Tracking from './components/Tracking';
import { confDefault } from 'conf';
import { Logic } from 'logic/logic';
import { FilterOpts } from './components/TrackingFilter';
import { RouteNames } from 'App';
import { exponea, StatusToExponeaStatus } from 'logic/exponea';
import { CargoExpense } from 'logic/statistics/statistics-expenses';
import {
    TrackingModel,
    VehicleDelayedFilterCode,
    VehicleStatusFilterCode,
    MonitoredObjectTypeName
} from 'common/model/tracking';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { Role } from 'logic/auth';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';

export type RouteParams = {
    delay?: string;
    status?: string;
    rolled?: string;
    expanded?: string;
    vehicleId?: string;
    fromFleet?: string;
};

export enum SearchType {
    all = 'all',
    vehicles = 'vehicles',
    drivers = 'drivers',
    position = 'position'
}

export type SearchData = {
    text?: string;
    type?: SearchType;
};

export type TrackingTableHead = {
    label: string;
    value: string;
    checked: boolean;
}[];

export type SmartBarFilter = 'onTime' | 'delayed' | 'fuelLow' | 'alarms' | 'aetrLow';

interface Props extends RouteComponentProps<{ vehicleId?: string }> {
    logic: Logic;
    simple?: boolean;
}

interface State {
    bar: {
        checkedAll: boolean;
        expanded: boolean;
        filter: boolean;
        filtered: boolean;
        settings: boolean;
        isFilterLoading: boolean;
    };
    smartBar: {
        rolled: boolean;
        active?: SmartBarFilter;
        onTime: number;
        delayed: number;
        fuelLow: number;
        alarms: number;
        aetrLow: number;
    };
    table: {
        data: TrackingModel[];
        loading: boolean;
    };
    roles: Role[];
    fleetType: ReadOnlyClientFleetTypeEnum;
    filter: {
        delay: VehicleDelayedFilterCode;
        status: VehicleStatusFilterCode;
        monitoredObjectGroupsChecked: string[];
        monitoredObjectGroupsOpts: CheckboxGroupOpts;
    };
    search?: { text: string };
    helper?: {
        content: string;
    };
    cargo?: {
        vehicle: {
            code: number;
            label: string;
        };
        suppliers: {
            code: string;
            label: string;
        }[];
        date: string;
    };
    vehicleId?: string;
    locationAlert?: {
        display: boolean;
        vehicleId?: string;
    };
}

class TrackingModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const settings = this._logic.tracking().settings();
        const roles = this._logic.auth().roles();
        const fleetType = this._logic.auth().newClient()?.fleetType ?? ReadOnlyClientFleetTypeEnum.Mixed;
        this.state = {
            table: { data: [], loading: this._logic.tracking().getDataLoading() },
            filter: {
                delay: settings.filter.delay,
                status: settings.filter.status,
                monitoredObjectGroupsChecked: [],
                monitoredObjectGroupsOpts: []
            },
            bar: {
                checkedAll: true,
                expanded: settings.expanded,
                filtered: false,
                filter: false,
                settings: false,
                isFilterLoading: false
            },
            smartBar: {
                rolled: settings.rolled,
                active: undefined,
                onTime: 0,
                delayed: 0,
                fuelLow: 0,
                alarms: 0,
                aetrLow: 0
            },
            roles,
            fleetType
        };
    }

    componentDidMount() {
        (window as any).app.TrackingModule = this;

        const filterDefaults = confDefault.settings.tracking.filter;
        const settings = this._logic.tracking().settings();
        const searchText = this._logic.tracking().searchText();
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        const { tankSizeLimit, vehicleLimit, lightVehicleLimit } = confDefault.settings.tracking.smartFilter.fuelLow;

        this._logic.tracking().onLoading(isLoading => {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: isLoading
                },
                locationAlert: params.fromFleet
                    ? {
                          display: state.locationAlert?.display ?? false,
                          vehicleId: params.vehicleId
                      }
                    : undefined
            }));
        });

        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    active: settings.smartFilter.active,
                    rolled: params.rolled ? params.rolled === 'true' : settings.rolled
                },
                bar: {
                    ...state.bar,
                    checkedAll: !params.vehicleId && !settings.vehicles,
                    expanded: params.expanded ? params.expanded === 'true' : settings.expanded,
                    filtered:
                        (params.delay !== undefined && params.delay !== filterDefaults.delay) ||
                        settings.filter.delay !== filterDefaults.delay ||
                        (params.status !== undefined && params.status !== filterDefaults.status) ||
                        settings.filter.status !== filterDefaults.status ||
                        (settings.filter.monitoredObjectGroupsChecked !== undefined &&
                            settings.filter.monitoredObjectGroupsChecked.length !== 0)
                },
                filter: {
                    delay: params.delay ? (params.delay as VehicleDelayedFilterCode) : settings.filter.delay,
                    status: params.status ? (params.status as VehicleStatusFilterCode) : settings.filter.status,
                    monitoredObjectGroupsOpts: [],
                    monitoredObjectGroupsChecked: settings.filter.monitoredObjectGroupsChecked
                },
                search: {
                    text: searchText
                }
            }),
            () => {
                this._logic.map().setPadding({
                    ...this._logic.map().initialPadding,
                    left: this.state.smartBar.rolled
                        ? this._logic.map().initialPaddingWithLeftComponent.left
                        : this._logic.map().initialPadding.left
                });
                this._logic.tracking().onData((filtered, data) => {
                    const vehicle = data.find(data => data.selected);
                    this._logic.map().vehicleCardDataUpdate(vehicle);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data: filtered
                        },
                        smartBar: {
                            ...state.smartBar,
                            onTime: data.filter(
                                d => !d.invalid && d.location && d.actual === VehicleDelayedFilterCode.ON_TIME
                            ).length,
                            delayed: data.filter(
                                d =>
                                    !d.invalid &&
                                    d.location &&
                                    moment.duration(moment(d.ETA).diff(moment(d.RTA))).asMinutes() > 0
                            ).length,
                            fuelLow: data.filter(
                                d =>
                                    !d.invalid &&
                                    d.location &&
                                    d.tank &&
                                    d.tank !== 0 &&
                                    ((d.tankSize && d.tank < d.tankSize * tankSizeLimit) ||
                                        (!d.tankSize &&
                                            d.tank <
                                                (d.monitoredObjectType?.name === MonitoredObjectTypeName.VEHICLE
                                                    ? vehicleLimit
                                                    : lightVehicleLimit)))
                            ).length,
                            alarms: data.filter(d => d.alarms.length).length,
                            aetrLow: data.filter(
                                d =>
                                    !d.invalid &&
                                    d.location &&
                                    (d.drivingDuration ?? 0) / ((d.dailyDriveMaxHours ?? 0) * 3600) > 0.75
                            ).length
                        },
                        locationAlert: {
                            ...state.locationAlert,
                            display:
                                state.locationAlert?.vehicleId !== undefined &&
                                data.find(d => d.id === params.vehicleId)?.location === ''
                                    ? true
                                    : false
                        }
                    }));
                });
                this._logic
                    .tracking()
                    .init()
                    .then(() => {
                        const params: RouteParams = qs.parse(this.props.history.location.search, {
                            ignoreQueryPrefix: true
                        });
                        let delayEnum = VehicleDelayedFilterCode.ALL_ETAS;
                        switch (settings.smartFilter.active) {
                            case 'onTime':
                                delayEnum = VehicleDelayedFilterCode.ON_TIME;
                                break;
                            case 'delayed':
                                delayEnum = VehicleDelayedFilterCode.LATE;
                                break;
                            default:
                                delayEnum = this.state.filter.delay;
                                break;
                        }
                        this._logic.tracking().filterVehicles({
                            ...this.state.filter,
                            delay: delayEnum,
                            fuelLow: settings.smartFilter.active === 'fuelLow' ? true : undefined,
                            alarms: settings.smartFilter.active === 'alarms' ? true : undefined,
                            aetrLow: settings.smartFilter.active === 'aetrLow' ? true : undefined
                        });

                        if (params.vehicleId) {
                            this._logic.tracking().selectVehicle(params.vehicleId);
                            this._logic.tracking().setSettings({ vehicles: [params.vehicleId] });
                            // else if: url param has priority
                        } else if (settings.vehicles) {
                            if (settings.vehicles.length === 1) {
                                this._logic.tracking().selectVehicle(settings.vehicles[0]);
                            } else {
                                this._logic.tracking().selectTrackedVehicles(settings.vehicles);
                            }
                        }
                    });
                if (!this.props.simple) {
                    if (this.state.smartBar.rolled) {
                        this._logic.tracking().tableOn();
                    }
                }
                this._logic
                    .map()
                    .vehicles()
                    .onClick(vehicle => {
                        const vehicleData = this.state.table.data.find(data => data.id === vehicle.id);

                        if (vehicleData) {
                            if (vehicleData.alarms?.some(a => !a.acknowledged)) {
                                if (this._logic.demo().isActive) {
                                    return;
                                }
                                // TODO:
                                // - request oznacit alarmy ako precitane
                                // - odoslat request na kazde id alarmu
                                this.setState(state => ({
                                    table: {
                                        ...state.table,
                                        data: state.table.data.map(d => {
                                            if (d.id === vehicleData.id) {
                                                d.alarms?.forEach(a => (a.acknowledged = true));
                                            }
                                            return d;
                                        })
                                    }
                                }));
                                return;
                            }
                            if (vehicleData.selected && vehicleData.centered) {
                                this._logic.tracking().unselectVehicle();
                            } else if (!vehicleData.selected || !vehicleData.centered) {
                                this._logic.exponea().trackEvent(exponea.module.vehicleDetail, {
                                    status: exponea.status.screenShown,
                                    src: 'table-map',
                                    vehicle_status: StatusToExponeaStatus[vehicleData.status],
                                    vehicle_transport: vehicleData.destination ? true : false
                                });
                                this._logic.tracking().selectVehicle(vehicleData.id);
                            }
                        }
                    });
            }
        );

        this._logic
            .map()
            .routing()
            .onAlarmAcknowledgedClick(id => {
                this._logic.alarms().markAlarmsAsSeen([id]);
            });
    }

    componentWillUnmount() {
        this._logic.tracking().destroyAdvancedTracking();
        (window as any).app.TrackingModule = undefined;

        if (!this.state.smartBar.active && !this.state.bar.filtered) {
            this.props.logic.exponea().updateAttributes({
                vehicle_count: this.state.table.data.length
            });
        }

        this._logic.tracking().destroy();
    }

    render() {
        return (
            <Tracking
                bar={this.state.bar}
                smartBar={this.state.smartBar}
                cargo={this.state.cargo}
                filter={this.state.filter}
                search={this.state.search}
                helper={this.state.helper}
                table={this.state.table}
                roles={this.state.roles}
                fleetType={this.state.fleetType}
                locationAlert={this.state.locationAlert}
                onAlarmClickDismiss={this._onAlarmClickDismiss}
                onBarCheckAllChecked={this._onCheckAll}
                onBarExpandClick={this._onExpandTableToggle}
                onBarFilterToggle={this._onFilterToggle}
                onBarHelperClick={this._onBarHelperClick}
                onSmartBarRolledClick={this._onSmartBarRolledClick}
                onSmartBarOnTimeClick={this._onSmartBarOnTimeClick}
                onSmartBarDelayedClick={this._onSmartBarDelayedClick}
                onSmartBarFuelLowClick={this._onSmartBarFuelLowClick}
                onSmartBarAlarmsClick={this._onSmartBarAlarmsClick}
                onSmartBarAetrLowClick={this._onSmartBarAetrLowClick}
                onCargoCancel={this._onCargoCancel}
                onCargoConfirm={this._onCargoConfirm}
                onCargoSupplierChange={this._onCargoSupplierChange}
                onFilterConfirm={this._onFilterConfirm}
                onSearchInputChange={this._onSearchInputChange}
                onSearchInputSubmit={this._onSearchInputSubmit}
                onSettingsCancel={this._onSettingsToggle}
                onHelperClose={this._onHelperClose}
                onTableColumnActualClick={this._onTableColumnActualClick}
                onTableColumnClick={this._onTableColumnClick}
                onTableAddDestination={this._onTableAddDestination}
                onTableRowCheck={this._onTableRowCheck}
                onTableRowClick={this._onTableRowClick}
                onLocationAlertClose={this._onLocationAlertClose}
                onLocationAlertBack={this._onLocationAlertBack}
            />
        );
    }

    private _onSearchInputSubmit = (_text: string) => undefined;
    private _onTableColumnClick = (_column: string) => undefined;
    private _onSettingsToggle = () => undefined;

    private _clearUrlParams() {
        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                delay: undefined,
                status: undefined
            } as RouteParams)
        });
    }

    private _onAlarmClickDismiss = (ids: string[]): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        this._logic.tracking().acknowledgeAlarms(ids);
    };

    private _onTableColumnActualClick = (id: string): void => {
        const vehicle = this.state.table.data.find(data => data.id === id);
        if (vehicle) {
            this._logic.tracking().renderRouteOfVehicle(vehicle);
        } else {
            // TODO:
            // - error notification
        }
    };

    private _onTableAddDestination = (id: string): void => {
        this._logic.exponea().trackEvent(exponea.module.trackingTableView, {
            status: exponea.status.actionTaken,
            action: exponea.action.addDestination
        });
        const data = this._logic.tracking().loadVehicle(id);

        if (data?.GPS) {
            this.props.history.push({
                pathname: RouteNames.SCHEDULING_PLANNER,
                search: qs.stringify({
                    vehicleId: id
                })
            });
        }
    };

    private _onSearchInputChange = (text: string): void => {
        this.setState({
            search: {
                text
            }
        });
        this._onSearchInputChangeDebounce(text);
    };

    private _onSearchInputChangeDebounce = debounce((text: string): void => {
        this._logic.exponea().trackEvent(exponea.module.trackingTableView, {
            status: exponea.status.actionTaken,
            action: exponea.action.search
        });

        this._logic.tracking().search(text);
    }, 500);

    private _onTableRowClick = (id: string): void => {
        const vehicle = this.state.table.data.find(data => data.id === id);
        if (vehicle) {
            if (vehicle.selected && vehicle.centered) {
                this.setState(
                    {
                        cargo: undefined,
                        bar: {
                            ...this.state.bar,
                            checkedAll: true
                        }
                    },
                    () => {
                        const params: RouteParams = qs.parse(this.props.location.search, {
                            ignoreQueryPrefix: true,
                            plainObjects: true
                        });
                        this._logic.tracking().unselectVehicle();
                        this._logic.tracking().setSettings({ vehicles: [] });
                        this.props.history.push({
                            search: qs.stringify({
                                ...params,
                                vehicleId: undefined
                            })
                        });
                    }
                );
            } else if (!vehicle.selected || !vehicle.centered) {
                this._logic.exponea().trackEvent(exponea.module.vehicleDetail, {
                    status: exponea.status.screenShown,
                    src: 'table',
                    vehicle_status: StatusToExponeaStatus[vehicle.status],
                    vehicle_transport: vehicle.destination ? true : false
                });

                this.setState(
                    {
                        cargo: undefined
                    },
                    () => {
                        const params: RouteParams = qs.parse(this.props.location.search, {
                            ignoreQueryPrefix: true,
                            plainObjects: true
                        });
                        this._logic.tracking().selectVehicle(id);
                        this._logic.tracking().setSettings({ vehicles: [id] });
                        this.props.history.push({
                            search: qs.stringify({
                                ...params,
                                vehicleId: id
                            })
                        });
                    }
                );
            }
        }
    };

    private _onTableRowCheck = (id: string, checkValue: boolean): void => {
        this._logic.tracking().toggleTrackedVehicle(id, checkValue);

        const allVehicles = this._logic.tracking().getVehiclesData();
        const checkedVehicles: TrackingModel[] = allVehicles.filter(v => v.checked);

        if (checkedVehicles.length === allVehicles.length) {
            this._logic.tracking().setSettings({ vehicles: undefined });
        } else {
            this._logic.tracking().setSettings({ vehicles: checkedVehicles.map(v => v.id) });
        }

        if (checkValue) {
            const uncheckedVehicles: TrackingModel[] = this.state.table.data.filter(
                vehicle => !vehicle.checked && vehicle.id !== id
            );
            this.setState(state => ({
                cargo: undefined,
                bar: {
                    ...state.bar,
                    checkedAll: uncheckedVehicles.length > 0 ? false : true
                }
            }));
        } else {
            this.setState(
                state => ({
                    cargo: undefined,
                    bar: { ...state.bar, checkedAll: false }
                }),
                () => {
                    this.props.history.push({
                        search: qs.stringify({ vehicleId: undefined })
                    });
                }
            );
        }
        this._logic.map().vehicles().fitVehicles();
    };

    private _onCheckAll = (): void => {
        this.setState(
            state => ({
                cargo: undefined,
                bar: { ...state.bar, checkedAll: !state.bar.checkedAll }
            }),
            () => {
                this.props.history.push({
                    search: qs.stringify({ vehicleId: undefined })
                });
                this._logic.tracking().setSettings({ vehicles: this.state.bar.checkedAll ? undefined : [] });
            }
        );
        this._logic.tracking().trackAll();
    };

    private _onFilterConfirm = (filter: FilterOpts) => {
        this.setState(state => ({
            ...state,
            bar: {
                ...state.bar,
                isFilterLoading: true
            }
        }));
        this._logic.exponea().trackEvent(exponea.module.trackingTableView, {
            status: exponea.status.actionTaken,
            action: exponea.action.filter
        });

        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                delay: filter.delay,
                status: filter.status
            } as RouteParams)
        });

        const settings = this._logic.tracking().settings();
        this._logic.tracking().setSettings({
            smartFilter: {
                ...settings.smartFilter,
                active: undefined
            },
            filter: {
                delay: filter.delay,
                status: filter.status,
                monitoredObjectGroupsChecked: filter.monitoredObjectGroupsChecked
            }
        });
        this._logic.tracking().filterVehicles(filter);

        this.setState(state => ({
            filter: {
                ...state.filter,
                ...filter
            },
            bar: {
                ...state.bar,
                filter: !state.bar.filter,
                filtered:
                    filter.delay !== VehicleDelayedFilterCode.ALL_ETAS ||
                    filter.status !== VehicleStatusFilterCode.ALL_VEHICLE_STATUSES ||
                    (filter.monitoredObjectGroupsChecked && filter.monitoredObjectGroupsChecked.length > 0),
                isFilterLoading: false
            },
            smartBar: {
                ...state.smartBar,
                active: undefined
            }
        }));
    };

    private _onExpandTableToggle = (): void => {
        if (!this.state.bar.expanded) {
            this._logic.exponea().trackEvent(exponea.module.trackingTableView, {
                status: exponea.status.actionTaken,
                action: exponea.action.expand
            });
        }

        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                expanded: String(!this.state.bar.expanded)
            } as RouteParams)
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    expanded: !state.bar.expanded
                }
            }),
            () => {
                this._logic.tracking().setSettings({
                    expanded: this.state.bar.expanded
                });
            }
        );
    };

    private _onFilterToggle = (): void => {
        if (this.state.bar.filter === false) {
            this._logic
                .api()
                .monitoredObjectGroupApi.monitoredObjectGroupList({
                    offset: DEFAULT_PAGE_OFFSET,
                    limit: INFINITY_PAGE_LIMIT
                })
                .then(res => {
                    if (res.results.length) {
                        this.setState(state => ({
                            filter: {
                                ...state.filter,
                                monitoredObjectGroupsOpts: [
                                    ...state.filter.monitoredObjectGroupsOpts,
                                    // add new from tags, if any
                                    ...res.results
                                        .filter(
                                            d =>
                                                !state.filter.monitoredObjectGroupsOpts.some(
                                                    t => t.code === String(d.id)
                                                )
                                        )
                                        .map(d => ({ code: String(d.id), label: d.name, checked: false }))
                                ]
                            }
                        }));
                    }
                });
        }

        this.setState(state => ({
            bar: {
                ...state.bar,
                filter: !state.bar.filter,
                settings: false
            }
        }));
    };

    private _onCargoSupplierChange = debounce((value: string): void => {
        this._logic
            .routing()
            .suggestion()
            .getCustomPlaceSuggestions(value)
            .then(fuelStations => {
                const cargoFuelStations = fuelStations
                    .filter(f => f.source === 'FUEL_STATIONS')
                    .map<{
                        code: string;
                        label: string;
                    }>(f => ({
                        code: f.id ?? '',
                        label: f.label ?? ''
                    }));

                this.setState(state => ({
                    cargo: {
                        ...state.cargo!,
                        suppliers: cargoFuelStations
                    }
                }));
            });
    }, 300);

    private _onCargoConfirm = async (cargo: CargoExpense): Promise<void> => {
        await this._logic.statisticsExpenses().createOrUpdateExpense(cargo);
        this.setState({ cargo: undefined });
    };

    private _onCargoCancel = (): void => {
        this.setState({ cargo: undefined });
    };

    private _onLocationAlertClose = (): void => {
        this.setState({ locationAlert: undefined });
    };

    private _onLocationAlertBack = (): void => {
        this.setState({ locationAlert: undefined });
        this.props.history.push({
            pathname: RouteNames.SETTINGS_FLEET
        });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'track&trace';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onSmartBarRolledClick = () => {
        this.setState(
            {
                smartBar: {
                    ...this.state.smartBar,
                    rolled: !this.state.smartBar.rolled
                }
            },
            () => {
                const params: RouteParams = qs.parse(this.props.location.search, {
                    ignoreQueryPrefix: true,
                    plainObjects: true
                });
                this.props.history.push({
                    search: qs.stringify({
                        ...params,
                        rolled: String(this.state.smartBar.rolled)
                    } as RouteParams)
                });

                if (this.state.smartBar.rolled) {
                    this._logic.tracking().tableOn();
                } else {
                    this._logic.tracking().tableOff();
                }

                this._logic.tracking().setSettings({
                    rolled: this.state.smartBar.rolled
                });
            }
        );
    };

    private _onSmartBarOnTimeClick = () => {
        if (this.state.smartBar.active !== 'onTime') {
            this._logic.tracking().trackAll();
        }

        const stateDefaults = confDefault.settings.tracking;
        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    rolled: true,
                    active: state.smartBar.active === 'onTime' ? undefined : 'onTime'
                },
                bar: {
                    ...state.bar,
                    filtered: false
                },
                filter: {
                    ...state.filter,
                    ...stateDefaults.filter
                }
            }),
            () => {
                this._logic.tracking().filterVehicles({
                    ...stateDefaults.filter,
                    delay:
                        this.state.smartBar.active === 'onTime'
                            ? VehicleDelayedFilterCode.ON_TIME
                            : VehicleDelayedFilterCode.ALL_ETAS
                });
                this._logic.tracking().setSettings({
                    filter: stateDefaults.filter,
                    smartFilter: {
                        ...stateDefaults.smartFilter,
                        active: this.state.smartBar.active
                    },
                    vehicles: undefined
                });
                this._clearUrlParams();
                this._logic.tracking().tableOn();
                this._logic.tracking().unselectVehicle();
            }
        );
    };

    private _onSmartBarDelayedClick = () => {
        if (this.state.smartBar.active !== 'delayed') {
            this._logic.tracking().trackAll();
        }

        const stateDefaults = confDefault.settings.tracking;
        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    rolled: true,
                    active: state.smartBar.active === 'delayed' ? undefined : 'delayed'
                },
                bar: {
                    ...state.bar,
                    filtered: false
                },
                filter: {
                    ...state.filter,
                    ...stateDefaults.filter
                }
            }),
            () => {
                this._logic.tracking().filterVehicles({
                    ...stateDefaults.filter,
                    delay:
                        this.state.smartBar.active === 'delayed'
                            ? VehicleDelayedFilterCode.LATE
                            : VehicleDelayedFilterCode.ALL_ETAS
                });
                this._logic.tracking().setSettings({
                    filter: stateDefaults.filter,
                    smartFilter: {
                        ...stateDefaults.smartFilter,
                        active: this.state.smartBar.active
                    },
                    vehicles: undefined
                });
                this._clearUrlParams();
                this._logic.tracking().tableOn();
                this._logic.tracking().unselectVehicle();
            }
        );
    };

    private _onSmartBarFuelLowClick = () => {
        if (this.state.smartBar.active !== 'fuelLow') {
            this._logic.tracking().trackAll();
        }

        const stateDefaults = confDefault.settings.tracking;
        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    rolled: true,
                    active: state.smartBar.active === 'fuelLow' ? undefined : 'fuelLow'
                },
                bar: {
                    ...state.bar,
                    filtered: false
                },
                filter: {
                    ...state.filter,
                    ...stateDefaults.filter
                }
            }),
            () => {
                this._logic.tracking().filterVehicles({
                    ...stateDefaults.filter,
                    fuelLow: this.state.smartBar.active ? true : undefined
                });
                this._logic.tracking().setSettings({
                    filter: stateDefaults.filter,
                    smartFilter: {
                        ...stateDefaults.smartFilter,
                        active: this.state.smartBar.active
                    },
                    vehicles: undefined
                });
                this._clearUrlParams();
                this._logic.tracking().tableOn();
                this._logic.tracking().unselectVehicle();
            }
        );
    };

    private _onSmartBarAlarmsClick = () => {
        if (this.state.smartBar.active !== 'alarms') {
            this._logic.tracking().trackAll();
        }

        const stateDefaults = confDefault.settings.tracking;
        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    rolled: true,
                    active: state.smartBar.active === 'alarms' ? undefined : 'alarms'
                },
                bar: {
                    ...state.bar,
                    filtered: false
                },
                filter: {
                    ...state.filter,
                    filterDefaults: stateDefaults.filter
                }
            }),
            () => {
                this._logic.tracking().filterVehicles({
                    ...stateDefaults.filter,
                    alarms: this.state.smartBar.active ? true : undefined
                });
                this._logic.tracking().setSettings({
                    filter: stateDefaults.filter,
                    smartFilter: {
                        ...stateDefaults.smartFilter,
                        active: this.state.smartBar.active
                    },
                    vehicles: undefined
                });
                this._clearUrlParams();
                this._logic.tracking().tableOn();
                this._logic.tracking().unselectVehicle();
            }
        );
    };

    private _onSmartBarAetrLowClick = () => {
        if (this.state.smartBar.active !== 'aetrLow') {
            this._logic.tracking().trackAll();
        }

        const stateDefaults = confDefault.settings.tracking;
        this.setState(
            state => ({
                smartBar: {
                    ...state.smartBar,
                    rolled: true,
                    active: state.smartBar.active === 'aetrLow' ? undefined : 'aetrLow'
                },
                bar: {
                    ...state.bar,
                    filtered: false
                },
                filter: {
                    ...state.filter,
                    ...stateDefaults.filter
                }
            }),
            () => {
                this._logic.tracking().filterVehicles({
                    ...stateDefaults.filter,
                    aetrLow: this.state.smartBar.active ? true : undefined
                });
                this._logic.tracking().setSettings({
                    filter: stateDefaults.filter,
                    smartFilter: {
                        ...stateDefaults.smartFilter,
                        active: this.state.smartBar.active
                    },
                    vehicles: undefined
                });
                this._clearUrlParams();
                this._logic.tracking().tableOn();
                this._logic.tracking().unselectVehicle();
            }
        );
    };
}

export default withRouter(TrackingModule);
