import cn from 'classnames';
import { Col, Row } from 'antd';
import { Button, Loading, Tooltip } from 'common/components';
import Select, { SelectOption } from 'common/components/Form/Select';
import { getRegistrationNumber } from 'common/utils/registrationName';
import {
    ReadOnlyExternalSystemAccess,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectGroup
} from 'generated/new-main/models';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SystemConnectForm, { SystemConnectFormValues } from '../SystemConnectForm';
import PuescForm, { PuescFormValues } from 'common/forms/PuescForm';
import * as CommonIcons from 'resources/images/common';
import qa from 'qa-selectors';
import PuescConnectionForm, { PuescRegistrationModel } from 'common/forms/PuescRegistration/PuescRegistrationForm';
import { useEffect } from 'react';

export type ModeType = 'DEFAULT' | 'EDIT' | 'CREATE';
interface Props {
    mode: ModeType;
    clientId?: number;
    externalSystemData: {
        name?: string;
        secret?: string;
    };
    loading: boolean;
    puesc: boolean;
    isImpersonator: boolean;
    editSystemConnectData?: Partial<SystemConnectFormValues>;
    editPuescData?: Partial<PuescFormValues>;
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    vehicleGroups: ReadOnlyMonitoredObjectGroup[];
    externalSystemAccess: ReadOnlyExternalSystemAccess[];
    activatePuescLoading: boolean;
    onSystemConnectFormSubmit: (values: SystemConnectFormValues) => void;
    onPuescSystemConnectFormSubmit: (values: PuescRegistrationModel) => Promise<boolean>;
    onSystemConnectFormCancel: () => void;
    onCreateNew: () => void;
    onActivatePuesc?: (values: SystemConnectFormValues) => void;
    onPuescFormSubmit: (values: PuescFormValues) => Promise<boolean>;
}

export default function SystemConnectionDetail(props: Props) {
    const { t } = useTranslation();
    const [state, setState] = React.useState<{ showSecret: boolean; selectedSystemName: string }>({
        showSecret: false,
        selectedSystemName: ''
    });

    useEffect(() => {
        if (props.mode === 'DEFAULT') {
            setState(state => ({
                ...state,
                selectedSystemName: ''
            }));
        }
    }, [props.mode]);
    const [secretTooltipVisible, setSecretTooltipVisible] = React.useState<boolean>(false);
    const [clientIdTooltipVisible, setClientIdTooltipVisible] = React.useState<boolean>(false);
    const vehiclesOptions: SelectOption[] = props.vehicles
        .filter(v => !v.disabledAt)
        .map(v => ({
            label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
            value: v.id ?? 0
        }));
    const vehicleGroupsOptions = props.vehicleGroups
        .map(vg => ({
            label: vg.name,
            value: vg.id
        }))
        .filter(opt => opt.value) as {
        label: string;
        value: number;
    }[];
    const externalSystemOptions: SelectOption[] = props.externalSystemAccess
        .map(es => ({
            label: es.externalSystemName,
            value: es.id!
        }))
        .filter(ex => {
            if (ex.label === 'Agheera') {
                return false;
            }
            if (ex.label === 'Astrata') {
                return false;
            }
            if (ex.label === 'CO3') {
                return false;
            }
            if (ex.label === 'Fourkites') {
                return false;
            }
            if (ex.label === 'Kasasi') {
                return false;
            } else {
                return true;
            }
        });

    return (
        <div className="system-connection-detail">
            {props.mode === 'DEFAULT' ? (
                <Button
                    className="connect-button"
                    type="primary"
                    qa={qa.systemConnections.otherSystems.detail.btnConnectAnother}
                    onClick={props.onCreateNew}
                >
                    {t('SystemConnections.connectAnother')}
                </Button>
            ) : props.loading ? (
                <Loading />
            ) : (
                <>
                    {props.mode === 'EDIT' && !props.puesc && (
                        <>
                            <Row className="info-row">
                                <Col span={6}>{t('common.username')}</Col>
                                <Col span={14}>{props.clientId}</Col>
                                <Col className="info-icon" span={4}>
                                    <Tooltip visible={clientIdTooltipVisible} title={t('common.copiedToClipboard')}>
                                        <img
                                            alt=""
                                            data-qa={qa.systemConnections.otherSystems.detail.btnClientIdCopy}
                                            onClick={() => {
                                                navigator.clipboard.writeText(props.clientId?.toString() ?? '');
                                                setClientIdTooltipVisible(true);
                                                setTimeout(() => {
                                                    setClientIdTooltipVisible(false);
                                                }, 1000);
                                            }}
                                            src={CommonIcons.contentCopy}
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row className="info-row">
                                <Col span={6}>{t('OtherSystemsTable.secret')}</Col>
                                <Col span={14}>
                                    {state.showSecret ? props.externalSystemData.secret : '*'.repeat(30)}
                                </Col>
                                <Col className="info-icon" span={4}>
                                    <i
                                        className={cn('secret-icon fas', {
                                            'fa-eye': !state.showSecret,
                                            'fa-eye-slash': state.showSecret
                                        })}
                                        onClick={() => {
                                            setState(state => ({
                                                ...state,
                                                showSecret: !state.showSecret
                                            }));
                                        }}
                                    />
                                    <Tooltip visible={secretTooltipVisible} title={t('common.copiedToClipboard')}>
                                        <img
                                            alt=""
                                            data-qa={qa.systemConnections.otherSystems.detail.btnSecretCopy}
                                            onClick={() => {
                                                navigator.clipboard.writeText(props.externalSystemData.secret ?? '');
                                                setSecretTooltipVisible(true);
                                                setTimeout(() => {
                                                    setSecretTooltipVisible(false);
                                                }, 1000);
                                            }}
                                            src={CommonIcons.contentCopy}
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </>
                    )}

                    {props.mode === 'CREATE' && (
                        <Row className="system-connection-detail-type-selection">
                            <Col span={24}>
                                <Select
                                    options={externalSystemOptions}
                                    label={t('SystemConnectForm.system')}
                                    placeholder={t('SystemConnectForm.system')}
                                    onChange={val => {
                                        setState(state => ({
                                            ...state,
                                            selectedSystemName:
                                                props.externalSystemAccess.find(es => es.id === val)
                                                    ?.externalSystemName ?? ''
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                    )}

                    {state.selectedSystemName === 'PUESC' || (props.mode === 'EDIT' && props.puesc) ? (
                        <>
                            <PuescConnectionForm
                                onSubmit={values => {
                                    return props.onPuescSystemConnectFormSubmit({
                                        ...values,
                                        externalSystemId: props.externalSystemAccess.find(
                                            es => es.externalSystemName === state.selectedSystemName
                                        )?.id
                                    });
                                }}
                                initialValues={{
                                    vehicles: props.editSystemConnectData?.vehicles,
                                    automaticVehicles: props.editSystemConnectData?.automaticVehicleAdd,
                                    metadata: props.editSystemConnectData?.metadata
                                }}
                                onCancel={props.onSystemConnectFormCancel}
                                vehicleOptions={vehiclesOptions}
                                vehicleGroupsOptions={vehicleGroupsOptions}
                            />
                        </>
                    ) : (
                        (state.selectedSystemName || props.mode === 'EDIT') && (
                            <SystemConnectForm
                                initialValues={
                                    props.mode === 'EDIT'
                                        ? {
                                              vehicles: props.editSystemConnectData?.vehicles,
                                              vehiclesGroup: props.editSystemConnectData?.vehiclesGroup
                                          }
                                        : {}
                                }
                                vehicleGroupsOptions={vehicleGroupsOptions}
                                onSubmit={values => {
                                    console.log(values);
                                    props.onSystemConnectFormSubmit({
                                        ...values,
                                        externalSystemId: values.externalSystemId
                                            ? values.externalSystemId
                                            : props.externalSystemAccess.find(
                                                  es => es.externalSystemName === state.selectedSystemName
                                              )?.id ?? ''
                                    });
                                }}
                                onCancel={props.onSystemConnectFormCancel}
                                onActivatePuesc={props.onActivatePuesc}
                                vehicleOptions={vehiclesOptions}
                            />
                        )
                    )}

                    {props.mode === 'EDIT' && props.puesc && (
                        <PuescForm
                            initialValues={props.editPuescData}
                            isImpersonator={props.isImpersonator}
                            onSubmit={props.onPuescFormSubmit}
                        />
                    )}
                </>
            )}
        </div>
    );
}
