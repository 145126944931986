import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { TableProps } from 'antd/lib/table';
import { SpinIndicator } from 'antd/lib/spin';

import { Loading } from '../Loading';

interface Props {
    size?: 'large' | 'middle' | 'small';
}

export default function EnhancedTable<T extends object = any>({
    pagination = false,
    loading = false,
    ...props
}: TableProps<T> & Props) {
    const { t } = useTranslation();
    return (
        <Table
            pagination={pagination}
            locale={{ emptyText: <span>{!loading && t('utils.empty')}</span> }}
            size={props.size ?? 'large'}
            scroll={{ x: props.scroll?.x ?? 'max-content', y: props.scroll?.y ?? 'max-content' }}
            loading={
                loading && {
                    indicator: (<Loading />) as SpinIndicator
                }
            }
            {...props}
        />
    );
}
