/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AETRReport,
    AETRReportFromJSON,
    AETRReportToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface AetrReportV1AetrReportGetRequest {
    timestamp: Date;
    tachographCard?: string;
    history?: number;
    historyIncludeIntervals?: number;
}

/**
 * no description
 */
export class AetrApi extends runtime.BaseAPI {

    /**
     * Aetr Report
     */
    async aetrReportV1AetrReportGetRaw(requestParameters: AetrReportV1AetrReportGetRequest): Promise<runtime.ApiResponse<Array<AETRReport>>> {
        if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
            throw new runtime.RequiredError('timestamp','Required parameter requestParameters.timestamp was null or undefined when calling aetrReportV1AetrReportGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestamp !== undefined) {
            queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
        }

        if (requestParameters.tachographCard !== undefined) {
            queryParameters['tachograph_card'] = requestParameters.tachographCard;
        }

        if (requestParameters.history !== undefined) {
            queryParameters['history'] = requestParameters.history;
        }

        if (requestParameters.historyIncludeIntervals !== undefined) {
            queryParameters['history_include_intervals'] = requestParameters.historyIncludeIntervals;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/aetr/report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AETRReportFromJSON));
    }

    /**
     * Aetr Report
     */
    async aetrReportV1AetrReportGet(requestParameters: AetrReportV1AetrReportGetRequest): Promise<Array<AETRReport>> {
        const response = await this.aetrReportV1AetrReportGetRaw(requestParameters);
        return await response.value();
    }

}
