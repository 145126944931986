import cn from 'classnames';
import { Loading } from 'common/components/Loading';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createRef } from 'react';
import { UserModel } from '../../../management-users/UsersModule';
import { PairingData, TachoCardModel, TachoCardsLinks } from '../../TachoCardsModule';
import { NoData } from 'common/components/NoData';
import { searched } from 'common/utils/search';
import { Col, Row } from 'antd';
import { Tag } from 'common/components';
import qa from 'qa-selectors';
import Search from 'common/components/Search';
import { Radio } from 'common/components/Form';
import UserDriverForm, { UserDriverFormModel } from 'common/forms/UserDriverForm/UserDriverForm';
import { FormikProps } from 'formik';
import FormActions from 'common/components/FormActions';

export type TachoCardPairingTypes = keyof TachoCardsLinks;

interface Props extends WithTranslation {
    data?: PairingData[];
    model?: TachoCardModel;
    search?: string;
    selected?: PairingData;
    type?: TachoCardPairingTypes;
    pairNewCreated?: boolean;
    onCancelButtonClick?: () => void;
    onConfirmButtonClick?: (newUser?: UserDriverFormModel) => void;
    onPairingItemSelect?: (selected: PairingData) => void;
    onPairingTypeChange?: (type: TachoCardPairingTypes) => void;
    onSearchInputChange?: (text: string) => void;
}

enum userPairType {
    existed,
    new
}

function TachoCardsPairing(props: Props) {
    const userDriverFormikRef = createRef<FormikProps<UserDriverFormModel>>();
    const [selectedUserPairType, setSelectedUserPairType] = useState<userPairType>(userPairType.existed);

    async function onConfirm(): Promise<void> {
        if (selectedUserPairType === userPairType.new) {
            await userDriverFormikRef.current?.submitForm();
            const isValid = userDriverFormikRef.current?.isValid;
            if (!isValid) return;
        }
        props.onConfirmButtonClick?.(userDriverFormikRef.current?.values);
    }

    function onCancel(): void {
        props.onCancelButtonClick?.();
    }

    function onItemSelect(selected: PairingData): void {
        props.onPairingItemSelect?.(selected);
    }

    function onSearch(text: string): void {
        props.onSearchInputChange?.(text);
    }

    function searchFilter(data: UserModel): boolean {
        if (props.search) {
            return searched(props.search, data.name) || searched(props.search, data.surname);
        }
        return true;
    }

    const { t } = props;
    return (
        <>
            <Row className="tacho-pairing">
                <Col span={10}>
                    <div className="tacho-pairing-left">
                        {props.pairNewCreated && (
                            <Tag className="tag-created-new">{`tacho card ${props.model?.token} was created`}</Tag>
                        )}

                        <div>{props.t('ManagementTachoCards.pairWith')}</div>
                        <Radio.Group
                            onChange={e => {
                                setSelectedUserPairType(e.target.value);
                            }}
                            defaultValue={0}
                        >
                            <Radio value={0} qa={qa.tacho.pairing.inputUserFromList}>
                                {props.t('ManagementTachoCards.userFromList')}
                            </Radio>
                            <Radio value={1} qa={qa.tacho.pairing.inputNewUser}>
                                {props.t('ManagementTachoCards.newUser')}
                            </Radio>
                        </Radio.Group>
                    </div>
                </Col>
                <Col span={14}>
                    {selectedUserPairType === userPairType.new ? (
                        <div className="tacho-pairing-right">
                            <UserDriverForm userDriverFormikRef={userDriverFormikRef} />
                        </div>
                    ) : (
                        <div className="tacho-pairing-right">
                            <div className="tacho-pairing-search">
                                <Search searchActive={true} onSearch={onSearch} qa={qa.common.pairing.inputSearch} />
                            </div>
                            <div className="scroll">
                                {!props.data ? (
                                    <Loading />
                                ) : !props.data?.length ? (
                                    <NoData />
                                ) : (
                                    props.type === 'user' &&
                                    props.data?.filter(searchFilter).map(u => (
                                        <div
                                            className={cn('tacho-pairing-user', {
                                                selected: props.selected?.id === u.id
                                            })}
                                            onClick={() => onItemSelect(u)}
                                            key={u.id}
                                            data-qa={qa.tacho.pairing.btnItem}
                                        >
                                            {`${u.name} ${u.surname}`}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            <FormActions
                className="buttons-group"
                submitQa={qa.tacho.pairing.btnFormSubmit}
                cancelQa={qa.tacho.pairing.btnFormCancel}
                submitText={t('common.save')}
                submitDisabled={!props.selected || !props.type}
                onSubmitClick={onConfirm}
                onCancelClick={onCancel}
            />
        </>
    );
}

export default withTranslation()(TachoCardsPairing);
