/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskType
 */
export interface TaskType {
    /**
     * 
     * @type {string}
     * @memberof TaskType
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskType
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TaskType
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskType
     */
    category?: TaskTypeCategoryEnum;
    /**
     * 
     * @type {Date}
     * @memberof TaskType
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaskType
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TaskType
     */
    repetitionDueDateType?: TaskTypeRepetitionDueDateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskType
     */
    repetitionDueDateValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaskType
     */
    readonly deleted?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TaskType
     */
    customizable?: boolean;
}

export function TaskTypeFromJSON(json: any): TaskType {
    return TaskTypeFromJSONTyped(json, false);
}

export function TaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'repetitionDueDateType': !exists(json, 'repetition_due_date_type') ? undefined : json['repetition_due_date_type'],
        'repetitionDueDateValue': !exists(json, 'repetition_due_date_value') ? undefined : json['repetition_due_date_value'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'customizable': !exists(json, 'customizable') ? undefined : json['customizable'],
    };
}

export function TaskTypeToJSON(value?: TaskType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'client_id': value.clientId,
        'category': value.category,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'repetition_due_date_type': value.repetitionDueDateType,
        'repetition_due_date_value': value.repetitionDueDateValue,
        'customizable': value.customizable,
    };
}

/**
* @export
* @enum {string}
*/
export enum TaskTypeCategoryEnum {
    Vehicle = 'vehicle',
    Company = 'company',
    Driver = 'driver',
    Trailer = 'trailer'
}
/**
* @export
* @enum {string}
*/
export enum TaskTypeRepetitionDueDateTypeEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}


