/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FinancialReport,
    FinancialReportFromJSON,
    FinancialReportFromJSONTyped,
    FinancialReportToJSON,
    UserData,
    UserDataFromJSON,
    UserDataFromJSONTyped,
    UserDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface Output
 */
export interface Output {
    /**
     * 
     * @type {{ [key: string]: UserData; }}
     * @memberof Output
     */
    diems?: { [key: string]: UserData; };
    /**
     * 
     * @type {FinancialReport}
     * @memberof Output
     */
    globalFinancialReport?: FinancialReport;
}

export function OutputFromJSON(json: any): Output {
    return OutputFromJSONTyped(json, false);
}

export function OutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): Output {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diems': !exists(json, 'diems') ? undefined : (mapValues(json['diems'], UserDataFromJSON)),
        'globalFinancialReport': !exists(json, 'global_financial_report') ? undefined : FinancialReportFromJSON(json['global_financial_report']),
    };
}

export function OutputToJSON(value?: Output | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diems': value.diems === undefined ? undefined : (mapValues(value.diems, UserDataToJSON)),
        'global_financial_report': FinancialReportToJSON(value.globalFinancialReport),
    };
}


