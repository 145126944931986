import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import { Checkbox } from '../../components/Form';

export interface EnhancedCheckboxOptionType extends CheckboxOptionType {
    qa?: string;
}

interface Props {
    name: string;
    label?: ReactNode;
    className?: string;
    options: EnhancedCheckboxOptionType[];
    disabled?: boolean;
    qa?: string;
}

const CheckboxGroupField = ({ name, label, options, disabled, className, qa }: Props) => {
    return (
        <Field name={name}>
            {({ field: { value }, form }: FieldProps) => {
                const handleChange = (values: CheckboxValueType[]) => {
                    form.setFieldValue(name, values);
                };
                return (
                    <Checkbox.Group
                        error={getIn(form.touched, name) && getIn(form.errors, name)}
                        className={className}
                        value={value || []}
                        onChange={handleChange}
                        options={options}
                        disabled={disabled}
                        data-qa={qa}
                    >
                        {label}
                    </Checkbox.Group>
                );
            }}
        </Field>
    );
};

export default CheckboxGroupField;
