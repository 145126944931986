import React, { Component } from 'react';
import i18n from 'i18next';
import Messaging from './ui/Messaging';
import { Logic } from 'logic/logic';
import { Message, ChannelInfo, MessagePayload, ChannelsResponse } from 'logic/messaging';
import { UserModel } from 'logic/user/users';
import { NotificationMessage } from 'logic/notification-eio';
import { initialData, selectedUser } from './mock-data';
import { Role } from 'logic/auth';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import moment from 'moment';
import { search } from 'common/utils/search';

export interface ChannelDriverInfo {
    channel: ChannelInfo;
    driver?: UserModel;
}

interface Props {
    logic: Logic;
    onMessage?: (channelId: string) => Promise<ChannelsResponse>;
    onMessageSeen?: (channelId: string) => Promise<ChannelsResponse>;
    onMessageUnsee?: (channelId: string) => Promise<ChannelsResponse>;
}

export interface State {
    checkedChannels?: string[];
    active: boolean;
    channels: {
        channels: ChannelDriverInfo[];
        selectedChannel?: string;
        search?: string;
    };
    chat: {
        search?: string;
        title: string;
        userId: string;
        driverId: string;
        messages?: Message[];
        filtered?: Message[];
        messageIndexRef: number;
    };
    roles: Role[];
    helper?: {
        content: string;
    };
}

class MessagingModule extends Component<Props, State> {
    private _logic: Logic;
    private _channelId?: string;
    private _drivers?: UserModel[];
    private _dispatchers?: UserModel[];
    private _messagesLoadCount: number = 10;
    private _channelDriverInfos?: ChannelDriverInfo[];
    private _inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this._inputRef = React.createRef();
        const roles = this._logic.auth().roles();
        this.state = {
            active: this._logic.messaging().active(),
            channels: {
                channels: []
            },
            chat: {
                title: '',
                driverId: '',
                userId: this._logic.auth().user().id,
                messageIndexRef: 0
            },
            roles
        };
    }

    componentDidMount() {
        (window as any).app.Messaging = this;

        if (this._logic.demo().isActive) {
            this.setState(prev => ({
                ...prev,
                channels: {
                    ...this._logic.demo().data.messaging.channels,
                    selectedChannel: undefined
                },
                active: this._logic.messaging().active()
            }));

            this._logic.messaging().onActiveChange(() => {
                this.setState(state => ({
                    active: !state.active
                }));
            });
        } else {
            this._logic.messaging().init(async () => {
                await this._logic.auth().updateToken();
                return this._logic.auth().token();
            });

            this._logic.messaging().onActiveChange(() => {
                if (this.state.active) {
                    this._channelId = undefined;
                    this.setState(state => ({
                        channels: {
                            ...state.channels,
                            selectedChannel: undefined
                        },
                        chat: {
                            title: '',
                            driverId: '',
                            userId: state.chat.userId,
                            messages: undefined,
                            messageIndexRef: 0
                        }
                    }));
                } else {
                    this._logic
                        .messaging()
                        .channels()
                        .then(res => {
                            this._logic
                                .users()
                                .drivers()
                                .then(drivers => {
                                    this._drivers = drivers.filter(d => d.ssoId !== '');
                                    this._channelDriverInfos = this._mapChannelsToDrivers(res.channels, this._drivers);
                                    this.setState(state => ({
                                        channels: {
                                            ...state.channels,
                                            channels: search(
                                                state.channels.search,
                                                ['driver.surname', 'driver.name'],
                                                this._channelDriverInfos ?? []
                                            )
                                        }
                                    }));
                                });
                        });
                }
                this.setState(state => ({
                    active: !state.active
                }));
            });

            this._logic
                .users()
                .dispatchers()
                .then(dispatchers => {
                    this._dispatchers = dispatchers;
                });

            this._logic
                .messaging()
                .channels()
                .then(res => {
                    // service too slow, let's manage users cache
                    if (!this.state.channels.channels.length) {
                        this._logic
                            .users()
                            .drivers()
                            .then(drivers => {
                                this._drivers = drivers.filter(d => d.ssoId !== '');
                                this._channelDriverInfos = this._mapChannelsToDrivers(res.channels, this._drivers);
                                this.setState(state => ({
                                    channels: {
                                        ...state.channels,
                                        channels: search(
                                            state.channels.search,
                                            ['driver.surname', 'driver.name'],
                                            this._channelDriverInfos ?? []
                                        )
                                    }
                                }));
                            });
                    }
                });

            this._logic.messaging().events.on('message', async notifMessage => {
                if (this._logic.auth().user().id !== notifMessage.data.message.sender) {
                    this._messageCb?.(notifMessage).then(channelRes => {
                        if (channelRes?.channels) {
                            const channels = channelRes.channels;
                            this._channelDriverInfos = this._mapChannelsToDrivers(channels, this._drivers);
                            this.setState(state => ({
                                channels: {
                                    ...state.channels,
                                    channels: search(
                                        state.channels.search,
                                        ['driver.surname', 'driver.name'],
                                        this._channelDriverInfos ?? []
                                    )
                                }
                            }));
                        }
                    });
                }
            });

            this._logic.messaging().events.on('message-seen', async (notifMessage: NotificationMessage) => {
                this._messageSeenCb?.(notifMessage).then(channelRes => {
                    if (channelRes?.channels) {
                        this._channelDriverInfos = this._mapChannelsToDrivers(channelRes.channels, this._drivers);
                        this.setState(state => ({
                            channels: {
                                ...state.channels,
                                channels: search(
                                    state.channels.search,
                                    ['driver.surname', 'driver.name'],
                                    this._channelDriverInfos ?? []
                                )
                            }
                        }));
                    }
                });
            });

            this._logic.messaging().events.on('message-unsee', async (notifMessage: NotificationMessage) => {
                if (this._logic.auth().user().id !== notifMessage.data.userId) {
                    this._messageUnseeCb?.(notifMessage).then(channelRes => {
                        if (channelRes?.channels) {
                            this._channelDriverInfos = this._mapChannelsToDrivers(channelRes.channels, this._drivers);
                            this.setState(state => ({
                                channels: {
                                    ...state.channels,
                                    channels: search(
                                        state.channels.search,
                                        ['driver.surname', 'driver.name'],
                                        this._channelDriverInfos ?? []
                                    )
                                }
                            }));
                        }
                    });
                }
            });

            this._logic.messaging().events.on('channel-created', async _message => {
                this._logic
                    .messaging()
                    .channels()
                    .then(channelRes => {
                        this._channelDriverInfos = this._mapChannelsToDrivers(channelRes.channels, this._drivers);
                        this.setState(state => ({
                            channels: {
                                ...state.channels,
                                channels: search(
                                    state.channels.search,
                                    ['driver.surname', 'driver.name'],
                                    this._channelDriverInfos ?? []
                                )
                            }
                        }));
                    });
            });
        }
    }

    private _mapChannelsToDrivers(channels: ChannelInfo[], drivers?: UserModel[]): ChannelDriverInfo[] {
        const channelDriverInfo: ChannelDriverInfo[] = channels
            ?.map(channel => {
                const driver = drivers?.find(d => d.ssoId === channel.creator);
                return {
                    channel,
                    driver
                };
            })
            .sort((a, b) => (moment(a.channel.newMsgFrom).isBefore(b.channel.newMsgFrom) ? -1 : 1));
        return channelDriverInfo;
    }

    componentWillUnmount() {
        (window as any).app.Messaging = undefined;
        this._logic.messaging().destroy();
    }

    render() {
        return (
            this.state.active && (
                <Messaging
                    channels={this.state.channels}
                    chat={this.state.chat}
                    inputRef={this._inputRef}
                    checkedChannels={this.state.checkedChannels}
                    roles={this.state.roles}
                    helper={this.state.helper}
                    uploadAttachment={this._logic.messaging().uploadAttachment}
                    onChannelsUserNameFromId={this._onChannelsUserNameFromId}
                    onChannelsSelect={this._onChannelSelect}
                    onChannelsSearchClick={this._onChannelsSearchClick}
                    onChannelsSearch={this._onChannelsSearch}
                    onChannelsGroupMessageClick={this._onChannelsGroupMessageClick}
                    onChannelsCheck={this._onChannelsCheck}
                    onChannelLastMessageUnsee={this._onChannelLastMessageUnsee}
                    onChatMessageUnsee={this._onChatMessageUnsee}
                    onChannelsUncheck={this._onChannelsUncheck}
                    onChatMessage={this._onChatMessage}
                    onChatGroupMessage={this._onChatGroupMessage}
                    onChatSearchClick={this._onChatSearchClick}
                    onChatSearch={this._onChatSearch}
                    onChatFocus={this._onChatFocus}
                    onChatScrollTop={this._onChatScrollTop}
                    onBarHelperClick={this._onBarHelperClick}
                    onHelperClose={this._onHelperClose}
                    demoMode={this._logic.demo().isActive}
                />
            )
        );
    }

    private _messageCb = async (notifMessage: NotificationMessage): Promise<ChannelsResponse | undefined> => {
        if (this._logic.demo().isActive) {
            return;
        }

        const channels = await this.props.onMessage?.(notifMessage.data.message.channel);
        if (this.state.chat && this._channelId === notifMessage.data.message.channel) {
            this._logic
                .messaging()
                .channelMessages(this._channelId!, new Date(), -this._messagesLoadCount)
                .then(channelMessages => {
                    this.setState(state => ({
                        chat: {
                            ...state.chat,
                            messages: channelMessages.messages,
                            messageIndexRef: channelMessages.messages.length - 1
                        }
                    }));
                });
            if (this._inputRef.current === document.activeElement) {
                this._onChatFocus();
            }
        }
        return channels;
    };

    private _messageSeenCb = async (notifMessage: NotificationMessage): Promise<ChannelsResponse | undefined> => {
        if (this._logic.demo().isActive) {
            return;
        }

        const channels = await this.props.onMessageSeen?.(notifMessage.data.channelId);
        if (this.state.chat && this._channelId === notifMessage.data.channelId) {
            if (this.state.chat.messages) {
                const messages = this.state.chat.messages;
                messages.forEach(m => {
                    if (
                        m.sender !== notifMessage.data.userId &&
                        new Date(m.created) <= new Date(notifMessage.data.seenTo)
                    ) {
                        let seen = [
                            {
                                user: notifMessage.data.userId,
                                date: notifMessage.data.seenTo
                            }
                        ];
                        if (m.seen && !m.seen.find(s => s.user === notifMessage.data.userId)) {
                            seen = [
                                ...m.seen,
                                {
                                    user: notifMessage.data.userId,
                                    date: notifMessage.data.seenTo
                                }
                            ];
                        }
                        m.seen = seen;
                    }
                });
                this.setState(state => ({
                    chat: {
                        ...state.chat,
                        messages
                    }
                }));
            }
        }
        return channels;
    };

    private _messageUnseeCb = async (notifMessage: NotificationMessage): Promise<ChannelsResponse | undefined> => {
        if (this._logic.demo().isActive) {
            return;
        }

        const channels = await this.props.onMessageUnsee?.(notifMessage.data.channelId);
        if (this.state.chat && this._channelId === notifMessage?.data?.channelId) {
            if (this.state.chat.messages) {
                this._channelDriverInfos = this.state.channels.channels.map(channel =>
                    channel.channel.id === notifMessage.data.channelId
                        ? { ...channel, newMsgCount: channel.channel.newMsgCount + 1 }
                        : channel
                );
                this.setState(state => ({
                    chat: {
                        ...state.chat,
                        messages: state.chat.messages?.map(message =>
                            message.id === notifMessage.data.id ? { ...message, seen: undefined } : message
                        )
                    },
                    channels: {
                        ...state.channels,
                        channels: search(
                            state.channels.search,
                            ['driver.surname', 'driver.name'],
                            this._channelDriverInfos ?? []
                        )
                    }
                }));
            }
        }
        return channels;
    };

    private _onChannelSelect = (channelDriverInfo: ChannelDriverInfo): void => {
        const channel = channelDriverInfo.channel;
        const driver = channelDriverInfo.driver;
        const selectedChannel = this.state.channels.selectedChannel;
        if (selectedChannel && channel && selectedChannel === channel.id) {
            this._channelId = undefined;
            this.setState(state => ({
                channels: {
                    ...state.channels,
                    selectedChannel: undefined
                },
                chat: {
                    ...state.chat,
                    title: '',
                    driverId: '',
                    messages: undefined,
                    messageIndexRef: 0
                }
            }));
            return;
        }
        if (channel) {
            const title = channel.title
                ? channel.title
                : `${driver ? driver.surname : ''} ${driver ? driver.name : ''}`;
            this._channelId = channel.id;
            this._inputRef.current?.focus();
            if (this._logic.demo().isActive) {
                this.setState(state => ({
                    channels: {
                        ...state.channels,
                        selectedChannel: channel.id
                    },
                    chat: {
                        ...state.chat,
                        userId: this._logic.demo().data.messaging.messages[channel.id].userId,
                        messages: this._logic.demo().data.messaging.messages[channel.id].messages,
                        title,
                        driverId: this._logic.demo().data.messaging.messages[channel.id].driverId,
                        messageIndexRef: this._logic.demo().data.messaging.messages[channel.id].messages.length - 1
                    }
                }));
            } else {
                this._logic
                    .messaging()
                    .channelMessages(this._channelId!, new Date(), -this._messagesLoadCount)
                    .then(channelMessages => {
                        this.setState(state => ({
                            channels: {
                                ...state.channels,
                                selectedChannel: channel.id
                            },
                            chat: {
                                ...state.chat,
                                messages: channelMessages.messages,
                                title,
                                driverId: driver ? driver?.ssoId : '',
                                messageIndexRef: channelMessages.messages.length - 1
                            }
                        }));
                    });
            }
        }
    };

    private _onChannelLastMessageUnsee = (channelDriverInfo: ChannelDriverInfo): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        const channel = channelDriverInfo.channel;
        this._logic
            .messaging()
            .channelMessages(channel.id, new Date(), -100) // todo need update that custom number count... 100 is rly random
            .then(channelMessages => {
                const messageId = channelMessages?.messages?.[0]?.id;
                if (messageId) {
                    this._logic
                        .messaging()
                        .channelMessageUnsee(channel.id, messageId)
                        .then(() => {
                            this.props.onMessageUnsee?.(channel.id).then(channelRes => {
                                if (channelRes?.channels) {
                                    const channels = channelRes.channels;
                                    this._channelDriverInfos = this._mapChannelsToDrivers(channels, this._drivers);
                                    this.setState(state => ({
                                        channels: {
                                            ...state.channels,
                                            channels: search(
                                                state.channels.search,
                                                ['driver.surname', 'driver.name'],
                                                this._channelDriverInfos ?? []
                                            )
                                        }
                                    }));
                                }
                            });
                        });
                }
            });
    };

    private _onChatMessage = (text: string, payload?: MessagePayload[]): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        if (this._channelId && (text || payload)) {
            this._logic
                .messaging()
                .sendMessage(this._channelId, text, payload)
                .then(() => {
                    this._logic
                        .messaging()
                        .channelMessages(
                            this._channelId!,
                            moment().add(5, 'minutes').toDate(),
                            -(this._messagesLoadCount + 1)
                        )
                        .then(channelMessages => {
                            this.setState(state => ({
                                chat: {
                                    ...state.chat,
                                    messages: channelMessages.messages,
                                    messageIndexRef: channelMessages.messages.length - 1
                                }
                            }));
                        });
                });
        }
    };

    private _onChatMessageUnsee = (messageId: string): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        const selectedChannel = this.state.channels.selectedChannel;
        if (messageId && selectedChannel) {
            this._logic
                .messaging()
                .channelMessageUnsee(selectedChannel, messageId)
                .then(() => {
                    this.setState(state => ({
                        chat: {
                            ...state.chat,
                            messages: state.chat.messages?.map(message =>
                                message.id === messageId ? { ...message, seen: undefined } : message
                            )
                        }
                    }));
                    this.props.onMessageUnsee?.(selectedChannel).then(channelRes => {
                        if (channelRes?.channels) {
                            const channels = channelRes.channels;
                            this._channelDriverInfos = this._mapChannelsToDrivers(channels, this._drivers);
                            this.setState(state => ({
                                channels: {
                                    ...state.channels,
                                    channels: search(
                                        state.channels.search,
                                        ['driver.surname', 'driver.name'],
                                        this._channelDriverInfos ?? []
                                    )
                                }
                            }));
                        }
                    });
                });
        }
    };

    private _onChatGroupMessage = (text: string, payload?: MessagePayload[]): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        if (this.state.checkedChannels && (text || payload)) {
            this.state.checkedChannels.forEach(channelId => {
                this._logic.messaging().sendMessage(channelId, text ?? '', payload);
                this._channelId = undefined;
                this.setState(state => ({
                    checkedChannels: undefined,
                    channels: {
                        ...state.channels,
                        selectedChannel: undefined
                    },
                    chat: {
                        ...state.chat,
                        title: '',
                        driverId: '',
                        messages: undefined,
                        messageIndexRef: 0
                    }
                }));
            });
        }
    };

    private _onChannelsUserNameFromId = (userId: string): string => {
        let user = undefined;
        this._drivers && (user = this._drivers.find(d => d.ssoId === userId));
        !user && this._dispatchers && (user = this._dispatchers.find(d => d.ssoId === userId));
        return user ? `${user.surname} ${user.name}` : userId;
    };

    private _onChatSearchClick = (): void => {
        this.setState(state => ({
            chat: {
                ...state.chat,
                search: state.chat.search === undefined ? '' : undefined,
                filtered: state.chat.filtered === undefined ? state.chat.messages : undefined,
                messageIndexRef: state.chat.messageIndexRef
            }
        }));
    };

    private _onChatSearch = (text: string): void => {
        if (text.length > 0) {
            this.setState(state => ({
                chat: {
                    ...state.chat,
                    search: text ?? '',
                    filtered: search(text, ['text'], state.chat?.messages ?? [])
                }
            }));
        } else {
            this.setState(state => ({
                chat: {
                    ...state.chat,
                    filtered: undefined
                }
            }));
        }
    };

    private _onChannelsSearchClick = (): void => {
        this.setState(state => ({
            channels: {
                ...state.channels,
                channels: this._channelDriverInfos || [],
                search: state.channels.search === undefined ? '' : undefined
            }
        }));
    };

    private _onChannelsSearch = (text: string): void => {
        if (this._channelDriverInfos) {
            this.setState(state => ({
                channels: {
                    ...state.channels,
                    channels: search(text, ['driver.surname', 'driver.name'], this._channelDriverInfos ?? []),
                    search: text
                }
            }));
        }
    };

    private _onChannelsGroupMessageClick = (): void => {
        this._channelId = undefined;
        this.setState(state => ({
            checkedChannels: state.checkedChannels === undefined ? [] : undefined,
            channels: {
                ...state.channels,
                selectedChannel: undefined
            },
            chat: {
                ...state.chat,
                title: '',
                driverId: '',
                messages: undefined,
                messageIndexRef: 0
            }
        }));
    };

    private _onChannelsCheck = (channel: ChannelDriverInfo): void => {
        if (this.state.checkedChannels && channel.channel) {
            const checkedChannels = [...this.state.checkedChannels, channel.channel.id];
            this.setState({
                checkedChannels
            });
        }
    };

    private _onChannelsUncheck = (channel: ChannelDriverInfo): void => {
        if (this.state.checkedChannels && channel.channel) {
            const checkedChannels = this.state.checkedChannels.filter(id => id !== channel.channel!.id);
            this.setState({
                checkedChannels
            });
        }
    };

    private _onChatFocus = async (): Promise<void> => {
        if (this._logic.demo().isActive) {
            return;
        }

        const channels = await this.props.onMessageSeen?.(this._channelId!);
        await this._logic.messaging().channelMessagesSeen(this._channelId!, new Date());
        const channelDriverInfos = channels?.channels
            ? this._mapChannelsToDrivers(channels?.channels, this._drivers)
            : this.state.channels.channels;
        this._channelDriverInfos = channelDriverInfos.map(s => ({
            ...s,
            channel: { ...s.channel, newMsgCount: s.channel.id === this._channelId ? 0 : s.channel.newMsgCount }
        }));
        this.setState(state => ({
            channels: {
                ...state.channels,
                channels: search(
                    state.channels.search,
                    ['driver.surname', 'driver.name'],
                    this._channelDriverInfos ?? []
                )
            }
        }));
    };

    private _onChatScrollTop = (): void => {
        if (this._logic.demo().isActive) {
            return;
        }

        const timestamp = this.state.chat?.messages?.[0] ? new Date(this.state.chat.messages[0].created) : new Date();
        this._channelId &&
            this._logic
                .messaging()
                .channelMessages(this._channelId!, timestamp, -this._messagesLoadCount)
                .then(channelMessages => {
                    this.setState(state => {
                        const messages = [...channelMessages.messages, ...(state.chat.messages ?? [])];
                        return {
                            chat: {
                                ...state.chat,
                                messages: messages,
                                messageIndexRef: messages.length
                            }
                        };
                    });
                });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'messaging';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    mock = () => {
        const messages = {
            setData: () => this.setState({ ...initialData }),
            selectUser: () => this.setState({ ...selectedUser })
        };
        return messages;
    };
}

export default MessagingModule;
