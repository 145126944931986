import { Logic } from 'logic/logic';
import { ContactPerson, ReadOnlyContactPersonNested, ReadOnlyAddressNested } from 'generated/new-main';
import { PartnerAddressModel, sampleAddresses } from './partner-address';

export interface PartnerContactPersonModel {
    id: string;
    name: string;
    surname: string;
    email: string;
    cellPhone: string;
    position: string;
    ssoId?: string;
    address?: PartnerAddressModel[];
    client?: string;
}

export class PartnerContactPersonLogic {
    constructor(private _logic: Logic) {}

    toContactPerson(
        data: ReadOnlyContactPersonNested | ContactPerson,
        addresses?: ReadOnlyAddressNested[]
    ): PartnerContactPersonModel {
        return {
            id: data.id ?? '',
            name: data.name ?? '',
            surname: data.surname ?? '',
            email: data.email ?? '',
            cellPhone: data.cellPhone ?? '',
            position: data.position ?? '',
            client: String(data.client) ?? '',
            ssoId: data.ssoId ?? '',
            address: addresses ? addresses?.map(this._logic.partner().address().toAddress) : []
        };
    }

    async addContactPerson(
        contactPerson: PartnerContactPersonModel,
        clientId: number,
        addressId: string
    ): Promise<PartnerContactPersonModel> {
        try {
            const response = await this._logic.api().contactPersonApi.contactPersonCreate({
                data: {
                    name: contactPerson.name,
                    surname: contactPerson.surname,
                    email: contactPerson.email,
                    cellPhone: contactPerson.cellPhone,
                    position: contactPerson.position,
                    clientId: clientId,
                    address: addressId
                }
            });
            return this.toContactPerson(response);
        } catch (err) {
            console.log('Contact person POST err:', err);
            throw err;
        }
    }

    async getContactPerson(email?: string): Promise<PartnerContactPersonModel[]> {
        try {
            const response = await this._logic.api().contactPersonApi.contactPersonList({
                email: email
            });
            return response.results.map(res => this.toContactPerson(res));
        } catch (err) {
            console.log('Contact person POST err:', err);
            throw err;
        }
    }
}

export const sampleContactPersons: PartnerContactPersonModel[] = [
    {
        id: 'z',
        name: 'b',
        surname: 'b',
        email: 'b',
        cellPhone: 'b',
        position: 'b',
        ssoId: 'a',
        address: sampleAddresses,
        client: 'address'
    },
    {
        id: 'u',
        name: 'b',
        surname: 'b',
        email: 'b',
        cellPhone: 'b',
        position: 'b',
        ssoId: 'a',
        address: sampleAddresses,
        client: 'address'
    }
];
