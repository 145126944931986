import moment from 'moment';
import { BorderCrossingEntryFromJSONTyped } from 'generated/backend-api';
import { vehicles } from './vehicles';
import { users } from './users';
import { dates } from './transports';

const durations: number[][] = [
    [40 * 60, 6 * 3600 + 24 * 60, 13 * 3600 + 25 * 60, 8 * 3600 + 41 * 60],
    [],
    [9 * 3600 + 26 * 60, 3 * 3600 + 1 * 60],
    [2 * 3600 + 33 * 60],
    [1 * 3600 + 58 * 60],
    []
];

const exitAddresses = [
    [
        // exit place vehicle 1
        [
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: 'Bratislava',
                route: 'Kittsee',
                address: '(AT) 851 10, Bratislava, Kittsee',
                street_address: 'Kittsee 1981/27',
                postalCode: '851 10',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: 'Bratislava',
                route: 'Kittsee',
                address: '(AT) 851 10, Bratislava, Kittsee',
                street_address: 'Kittsee 1981/27',
                postalCode: '851 10',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: 'Coccau Valico',
                route: 'Unterthörl',
                address: '(IT) 33018, Coccau Valico, Unterthörl',
                street_address: 'Unterthörl 45',
                postalCode: '33018',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: 'Coccau Valico',
                route: 'Unterthörl',
                address: '(IT) 33018, Coccau Valico, Unterthörl',
                street_address: 'Unterthörl 45',
                postalCode: '33018',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'France',
                countryCode: 'FR',
                town: 'Menton',
                route: 'Avenue Aristide Briand',
                address: '(FR) 06500, Menton, Avenue Aristide Briand',
                street_address: 'Avenue Aristide Briand 32',
                postalCode: '06500',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Francúzsko',
                countryCode: 'FR',
                town: 'Menton',
                route: 'Avenue Aristide Briand',
                address: '(FR) 06500, Menton, Avenue Aristide Briand',
                street_address: 'Avenue Aristide Briand 32',
                postalCode: '06500',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Spain',
                countryCode: 'ES',
                town: 'Le Perthus',
                route: 'Carrer Dr. Subiró',
                address: '(ES) 17709, Le Perthus, Carrer Dr. Subiró',
                street_address: 'Carrer Doctor Subiró 32',
                postalCode: '17709',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Francúzsko',
                countryCode: 'FR',
                town: 'Le Perthus',
                route: 'Rue du Ravin',
                address: '(FR) 66480, Le Perthus, Rue du Ravin',
                street_address: 'Rue du Ravin 21',
                postalCode: '66480',
                source: 'google'
            }
        ]
    ],
    // exit place vehicle 2
    [],
    // exit place vehicle 3
    [
        [
            {
                lang: 'en',
                country: 'Poland',
                countryCode: 'PL',
                town: 'Gorzyczki',
                route: 'D1',
                address: '(PL) 44, Gorzyczki, D1',
                street_address: 'D1 ',
                postalCode: '44',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Poľsko',
                countryCode: 'PL',
                town: 'Gorzyczki',
                route: 'D1',
                address: '(PL) 44, Gorzyczki, D1',
                street_address: 'D1 ',
                postalCode: '44',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: '',
                route: 'E461',
                address: '(AT) 2165, , E461',
                street_address: 'Brünner Straße 361',
                postalCode: '2165',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: '',
                route: 'E461',
                address: '(AT) 2165, , E461',
                street_address: 'Am Zollhaus 361',
                postalCode: '2165',
                source: 'google'
            }
        ]
    ],
    // exit place vehicle 4
    [
        [
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: '',
                route: 'E461',
                address: '(AT) 2165, , E461',
                street_address: 'Brünner Straße 361',
                postalCode: '2165',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: '',
                route: 'E461',
                address: '(AT) 2165, , E461',
                street_address: 'Am Zollhaus 361',
                postalCode: '2165',
                source: 'google'
            }
        ]
    ],
    // exit place vehicle 5
    [
        [
            {
                lang: 'en',
                country: 'Germany',
                countryCode: 'DE',
                town: 'Aachen',
                route: 'E314',
                address: '(DE) 52072, Aachen, E314',
                street_address: 'E314 160',
                postalCode: '52072',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Nemecko',
                countryCode: 'DE',
                town: 'Aachen',
                route: 'Vetschauer Weg',
                address: '(DE) 52072, Aachen, Vetschauer Weg',
                street_address: 'Vetschauer Weg 160',
                postalCode: '52072',
                source: 'google'
            }
        ]
    ],
    // exit place vehicle 6
    []
];

export const borderCrossing = [
    // VEHICLE 1
    {
        monitored_object_id: String(vehicles[0].id),
        client_id: '100',
        country_entry: 'SK',
        country_exit: 'AT',
        driver_id: String(users[0].id),
        distance: 20.6542,
        start: dates.find(d => d.id === '1')?.times[0],
        point_entry: ['2021-02-01T00:54:05', null, null, null, null],
        point_exit: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        end: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .toISOString(),
        duration: durations[0][0],
        address_entry: null,
        address_exit: exitAddresses[0][0]
    },
    {
        monitored_object_id: String(vehicles[0].id),
        client_id: '100',
        country_entry: 'AT',
        country_exit: 'SK',
        driver_id: String(users[0].id),
        distance: 420.2555,
        start: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .add(durations[0][1], 'seconds')
            .toISOString(),
        duration: durations[0][1],
        address_entry: exitAddresses[0][0],
        address_exit: exitAddresses[0][1]
    },
    {
        monitored_object_id: String(vehicles[0].id),
        client_id: '100',
        country_entry: 'IT',
        country_exit: 'AT',
        driver_id: String(users[0].id),
        distance: 806.456,
        start: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .add(durations[0][1], 'seconds')
            .toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .add(durations[0][1], 'seconds')
            .add(durations[0][2], 'seconds')
            .toISOString(),
        duration: durations[0][2],
        address_entry: exitAddresses[0][1],
        address_exit: exitAddresses[0][2]
    },
    {
        monitored_object_id: String(vehicles[0].id),
        client_id: '100',
        country_entry: 'FR',
        country_exit: 'IT',
        driver_id: String(users[0].id),
        distance: 546.6,
        start: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .add(durations[0][1], 'seconds')
            .add(durations[0][2], 'seconds')
            .toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '1')?.times[0])
            .add(durations[0][0], 'seconds')
            .add(durations[0][1], 'seconds')
            .add(durations[0][2], 'seconds')
            .add(durations[0][3], 'seconds')
            .toISOString(),
        duration: durations[0][3],
        address_entry: exitAddresses[0][2],
        address_exit: exitAddresses[0][3]
    },
    // VEHICLE 3
    {
        monitored_object_id: String(vehicles[2].id),
        client_id: '100',
        country_entry: 'PL',
        country_exit: 'CZ',
        driver_id: String(users[2].id),
        distance: 582.9,
        start: moment(dates.find(d => d.id === '2')?.times[0]).toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '2')?.times[0])
            .add(durations[2][0], 'seconds')
            .toISOString(),
        duration: durations[2][0],
        address_entry: null,
        address_exit: exitAddresses[2][0]
    },
    {
        monitored_object_id: String(vehicles[2].id),
        client_id: '100',
        country_entry: 'CZ',
        country_exit: 'AT',
        driver_id: String(users[2].id),
        distance: 243.5,
        start: moment(dates.find(d => d.id === '3')?.times[0])
            .add(durations[2][0], 'seconds')
            .toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '3')?.times[0])
            .add(durations[2][0], 'seconds')
            .add(durations[2][1], 'seconds')
            .toISOString(),
        duration: durations[2][0],
        address_entry: exitAddresses[2][0],
        address_exit: exitAddresses[2][1]
    },
    // VEHICLE 4
    {
        monitored_object_id: String(vehicles[3].id),
        client_id: '100',
        country_entry: 'HU',
        country_exit: 'SK',
        driver_id: String(users[4].id),
        distance: 172.5,
        start: moment(dates.find(d => d.id === '4')?.times[0]).toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '4')?.times[0])
            .add(durations[3][0], 'seconds')
            .toISOString(),
        duration: durations[3][0],
        address_entry: null,
        address_exit: exitAddresses[3][0]
    },
    // VEHICLE 5
    {
        monitored_object_id: String(vehicles[4].id),
        client_id: '100',
        country_entry: 'BE',
        country_exit: 'LX',
        driver_id: String(users[3].id),
        distance: 142.1,
        start: moment(dates.find(d => d.id === '5')?.times[0]).toISOString(),
        point_entry: ['2021-02-01T11:04:27', 43.33981, -1.75302, 13.0, 48.1],
        point_exit: ['2021-02-02T11:42:26', 43.34004, -1.7513, 18.0, 25.900000000000002],
        end: moment(dates.find(d => d.id === '5')?.times[0])
            .add(durations[4][0], 'seconds')
            .toISOString(),
        duration: durations[4][0],
        address_entry: null,
        address_exit: exitAddresses[4][0]
    }
].map(b => BorderCrossingEntryFromJSONTyped(b, true));
