/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPResponseBody,
    WAGTPResponseBodyFromJSON,
    WAGTPResponseBodyFromJSONTyped,
    WAGTPResponseBodyToJSON,
    WAGTPResponseHeader,
    WAGTPResponseHeaderFromJSON,
    WAGTPResponseHeaderFromJSONTyped,
    WAGTPResponseHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface WAGTPResponse
 */
export interface WAGTPResponse {
    /**
     * 
     * @type {WAGTPResponseHeader}
     * @memberof WAGTPResponse
     */
    responseHeader: WAGTPResponseHeader;
    /**
     * 
     * @type {WAGTPResponseBody}
     * @memberof WAGTPResponse
     */
    responseBody: WAGTPResponseBody;
}

export function WAGTPResponseFromJSON(json: any): WAGTPResponse {
    return WAGTPResponseFromJSONTyped(json, false);
}

export function WAGTPResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseHeader': WAGTPResponseHeaderFromJSON(json['responseHeader']),
        'responseBody': WAGTPResponseBodyFromJSON(json['responseBody']),
    };
}

export function WAGTPResponseToJSON(value?: WAGTPResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseHeader': WAGTPResponseHeaderToJSON(value.responseHeader),
        'responseBody': WAGTPResponseBodyToJSON(value.responseBody),
    };
}


