/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegDetails,
    LegDetailsFromJSON,
    LegDetailsFromJSONTyped,
    LegDetailsToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON
} from './';

/**
 *
 * @export
 * @interface Leg
 */
export interface Leg {
    /**
     *
     * @type {string}
     * @memberof Leg
     */
    type: string;
    /**
     *
     * @type {Location}
     * @memberof Leg
     */
    from: Location;
    /**
     *
     * @type {Location}
     * @memberof Leg
     */
    to: Location;
    /**
     *
     * @type {LegDetails}
     * @memberof Leg
     */
    details: LegDetails;
}

export function LegFromJSON(json: any): Leg {
    return LegFromJSONTyped(json, false);
}

export function LegFromJSONTyped(json: any, ignoreDiscriminator: boolean): Leg {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: json['type'],
        from: LocationFromJSON(json['from']),
        to: LocationFromJSON(json['to']),
        details: LegDetailsFromJSON(json['details'])
    };
}

export function LegToJSON(value?: Leg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: value.type,
        from: LocationToJSON(value.from),
        to: LocationToJSON(value.to),
        details: LegDetailsToJSON(value.details)
    };
}
