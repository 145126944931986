import { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal, Button } from 'common/components';
import { Input } from 'common/components/Form';

import CustomerApiVehiclesSelect from '../CustomerApiVehiclesSelect';
import qa from 'qa-selectors';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

interface Props extends WithTranslation {
    keyGeneration: {
        id?: number | null;
        name: string;
        vehicles: string[];
    };
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    visible?: boolean;
    demoMode?: boolean;
    createApiKeyLoading: boolean;
    editApiKeyLoading: boolean;
    onVehicleSelectionChange: (vehicles: string[]) => void;
    onApiKeyNameChange: (text: string) => void;
    onApiKeySave: () => void;
    onApiKeyGenerate: () => void;
    onTableSeeMore?: () => void;
    onClose: () => void;
}

function CustomerAPIModal({ t, ...props }: Props) {
    function onApiKeyNameChange(e: ChangeEvent<HTMLInputElement>) {
        // FIXME: Remove on react update to 17.x
        // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#no-event-pooling
        e.persist();
        props.onApiKeyNameChange(e.target.value);
    }

    return (
        <Modal destroyOnClose visible={props.visible} onCancel={props.onClose} width={600} footer={null}>
            <div className="customer-api-modal">
                <div className="customer-api-modal-header">
                    <h3>
                        {props.keyGeneration.id ? t('CustomerApiModal.editTitle') : t('CustomerApiModal.createTitle')}
                    </h3>
                </div>
                <div className="customer-api-modal-name">
                    <Input
                        type="text"
                        className="t-input t-border"
                        placeholder={t('CustomerApiModal.apiKeyTitle')}
                        value={props.keyGeneration.name}
                        qa={qa.systemConnections.createApiKey.inputName}
                        onChange={onApiKeyNameChange}
                    />
                </div>
                <div className="customer-api-modal-content">
                    <CustomerApiVehiclesSelect
                        selection={props.keyGeneration.vehicles}
                        vehicles={props.vehicles
                            .filter(v => !v.disabledAt)
                            .map(v => ({
                                name: v.registrationNumber,
                                id: v.id?.toString() ?? ''
                            }))}
                        onChange={props.onVehicleSelectionChange}
                    />
                </div>
                {props.keyGeneration.id ? (
                    <Button
                        loading={props.editApiKeyLoading}
                        onClick={props.onApiKeySave}
                        type="primary"
                        disabled={props.demoMode}
                        qa={qa.systemConnections.createApiKey.btnSave}
                    >
                        {t('CustomerApiModal.save')}
                    </Button>
                ) : (
                    <Button onClick={props.onApiKeyGenerate} type="primary" loading={props.createApiKeyLoading}>
                        {t('CustomerApiModal.generate')}
                    </Button>
                )}
            </div>
        </Modal>
    );
}

export default withTranslation()(CustomerAPIModal);
