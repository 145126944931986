import { WithTranslation, withTranslation } from 'react-i18next';
import { AvailableCurrencies } from 'common/model/currency';
import { Col, Row } from 'antd';
import { Button, Select } from 'common/components';
import { SelectValue } from 'antd/lib/select';
import { CostStructure } from 'logic/statistics/statistics-company-profile';
import Search from 'common/components/Search';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import TableBar from 'common/components/TableBar';

interface Props extends WithTranslation {
    heading: string;
    currency?: AvailableCurrencies;
    data?: CostStructure[];
    search?: { text: string };
    loading?: boolean;
    demoMode?: boolean;
    roles: Role[];
    onCurrencyChange?: (currency: AvailableCurrencies) => void;
    onCostEditChange?: () => void;
    onDashboard?: () => void;
    onSearchChange?: (text: string) => void;
    onHelperClick?: () => void;
}

function CompanyProfileBar(props: Props) {
    function onCostEditChange() {
        props.onCostEditChange?.();
    }

    function onSearchChange(text: string) {
        props.onSearchChange?.(text);
    }

    function onCurrencyChange(value: SelectValue) {
        props.onCurrencyChange?.(value as AvailableCurrencies);
    }

    return (
        <div>
            <TableBar
                heading={props.heading}
                filters={[
                    <Row justify="start" gutter={16} className="company-profile-bar">
                        <Col flex="270px">
                            <Button
                                disabled={props.data?.filter(d => d.selected === true).length === 0 || props.loading}
                                onClick={onCostEditChange}
                                block
                                qa={qa.companyProfile.costsCalculator.bar.btnEditSelected}
                            >
                                {props.t('CompanyProfile.editSelectedVehicle')}
                            </Button>
                        </Col>
                        <Col>
                            <div className="company-profile-costs-eur">
                                {props.t('CompanyProfile.costCalculationIn')}
                            </div>
                            <Select
                                disabled={props.loading || props.demoMode}
                                className="company-profile-costs-select-currency"
                                value={props.currency}
                                size="large"
                                onChange={onCurrencyChange}
                                qa={qa.companyProfile.costsCalculator.bar.selectCurrency}
                            >
                                {Object.keys(AvailableCurrencies).map((v, i) => (
                                    <Select.Option key={i} value={AvailableCurrencies[v]}>
                                        {v.valueOf()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col flex="270px">
                            <Search
                                disabled={props.loading}
                                search={props.search}
                                searchActive
                                onSearch={onSearchChange}
                            />
                        </Col>
                    </Row>
                ]}
                backButton={{
                    onClick: props.roles.includes(Role.DBHD_R) ? props.onDashboard : undefined
                }}
                onHelperClick={props.onHelperClick}
            />
        </div>
    );
}

export default withTranslation()(CompanyProfileBar);
