import {
    TransportState,
    TransportPlaceTask,
    TransportMonitoredObject,
    Goods,
    TransportTollCost,
    TransportCrossingPoint,
    MuDataProcessingModelsDriverInfoDriver
} from 'generated/backend-api';
import { PlaceType, VehicleStateObject } from 'generated/graphql';
import { LatLng } from 'common/model/geo';
import { AvailableCurrencies } from 'common/model/currency';
import { AddressStructured } from './address';
import { TransEUCompanyInfo } from 'logic/dff-proposals';
import { VehicleModel } from 'logic/vehicle/vehicles';
import { FleetModel } from 'modules/management/modules/fleet/FleetModule';
import {
    MonitoredObjectTrailerProfile,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyVehicleProfileSerializerEuroClassEnum,
    WriteOnlyVehicleProfileSerializerEuroClassEnum
} from 'generated/new-main';
import {
    Attachment,
    LoadingInformation,
    NegotiableAttributes,
    NegotiationState,
    VehicleProperties,
    Volume
} from 'generated/dff-api';
import { ColdchainTemperatureSensorModel } from 'modules/statistics/modules/coldchain/coldchain-logic';

export interface PuescValidationErrors {
    vehicle: boolean;
    trailer: boolean;
    loadingTask: boolean;
    unloadingTask: boolean;
    allGoodsUnloaded: boolean;
    minimumGoods: boolean;
}

export type GoodsWithAddress = Goods & {
    loading: {
        placeIndex: number;
        placeId: string;
        address: string;
    };
    unloading?: {
        placeId: string;
        address: string;
    };
};

export type VehicleWithAvailability = {
    available: boolean;
    data: VehicleStateObject;
    profile?: VehicleModel;
    fleetModel?: FleetModel;
};

export type TrailerWithAvailability = {
    available: boolean;
    data?: ReadOnlyMonitoredObjectFeSb;
    profile?: MonitoredObjectTrailerProfile;
    sensors?: ColdchainTemperatureSensorModel[];
};

export interface TransportAlarmConfig {
    name: TransportAlarmType;
    config?: {
        name: string;
        value: number;
    };
}

export enum TransportAlarmType {
    CorridorLeave = 'transport_corridor_leave',
    CloseToPoi = 'transport_poi_close',
    TransportArrival = 'transport_arrival',
    TransportDeparture = 'transport_departure'
}

export interface VehicleProfileModel {
    id?: string;
    name?: string;
    isDefault?: boolean;
    euroClass?: ReadOnlyVehicleProfileSerializerEuroClassEnum | WriteOnlyVehicleProfileSerializerEuroClassEnum;
    numberOfAxles?: number;
    pricePerKm?: number;
    weight?: number;
    height?: number;
    width?: number;
    length?: number;
    tunnel?: string;
    trailersCount?: number;
    client?: number;
}

export interface EventState {
    state?: number;
    eventRuleName?: 'transport_arrival' | 'transport_departure' | 'transport_passing' | 'transport_skipping';
}
export interface EventRule {
    channels?: string[];
    type?: 'parking' | 'refueling' | 'loading' | 'unloading';
}

export interface VehiclesRemoteMemoryModel {
    id: string;
    licensePlate: string;
    available?: boolean;
}

export interface PlacesModel {
    id: string;
    rta?: string;
    rtd?: string;
    eta?: string;
    ata?: string;
    atd?: string;
    name?: string;
    note?: string;
    distance?: number;
    duration?: number;
    center: LatLng;
    polygon: LatLng[][];
    eventStates?: EventState[];
    eventRules?: EventRule[];
    route?: string;
    source?: string;
    type?: PlaceType;
    tasks?: TransportPlaceTask[];
    actual?: string;
    addressStructured: AddressStructured[];
    crossingPoints?: TransportCrossingPoint[];
    alarms: TransportAlarmConfig[];
}

export interface TransportModel {
    id?: string;
    externalId?: string;
    name?: string;
    firstPlaceRta?: string;
    lastPlaceRta?: string;
    places: PlacesModel[];
    profile?: string;
    vehicle?: string;
    client?: string;
    clientId?: number;
    customerContactId?: string;
    monitoredObjects?: TransportMonitoredObject[];
    costPerKm?: { currency: AvailableCurrencies; cost: number };
    state: TransportState;
    users?: { name?: string; surname?: string; id?: string }[];
    tollCost?: TransportTollCost;
    omitParamsFromRouting?: boolean;
    pUESCTypeOfNotification?: string;
    version?: number;
    lastDriver?: MuDataProcessingModelsDriverInfoDriver;
    lastTrailer?: TransportMonitoredObject;
    puesc?: boolean;
    metadata?: {
        expiration?: string;
        loadWeightUnit?: string;
        loadWeightValue?: number;
        distanceToTransport?: number;
        transportDistance?: number;
        pickupCosts?: number;
        trailer?: string;
        transportCosts?: number;
        totalDuration?: number;
        totalCosts?: number;
        pickupDuration?: number;
        score: {
            value: number;
            stars: number;
        };
        profit?: number;
        finalPrice?: number;
        timestamp?: string;
        companyInfo?: TransEUCompanyInfo;
        proposal?: {
            state: NegotiationState;
            load: {
                weight: number;
                fluidVolume: Volume;
            };
            vehicle: VehicleProperties;
            places: LoadingInformation[];
            useJitpay?: boolean;
            shipmentId: string;
            attachments: Attachment[];
            negotiableAttributes: NegotiableAttributes[];
        };
    };
}
