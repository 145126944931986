/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectTrailerProfile
 */
export interface MonitoredObjectTrailerProfile {
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectTrailerProfile
     */
    type?: MonitoredObjectTrailerProfileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    weightEmpty?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    weightFull?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectTrailerProfile
     */
    cargoType?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectTrailerProfile
     */
    numberOfAxles?: number;
}

export function MonitoredObjectTrailerProfileFromJSON(json: any): MonitoredObjectTrailerProfile {
    return MonitoredObjectTrailerProfileFromJSONTyped(json, false);
}

export function MonitoredObjectTrailerProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectTrailerProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'weightEmpty': !exists(json, 'weightEmpty') ? undefined : json['weightEmpty'],
        'weightFull': !exists(json, 'weightFull') ? undefined : json['weightFull'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'cargoType': !exists(json, 'cargoType') ? undefined : json['cargoType'],
        'numberOfAxles': !exists(json, 'numberOfAxles') ? undefined : json['numberOfAxles'],
    };
}

export function MonitoredObjectTrailerProfileToJSON(value?: MonitoredObjectTrailerProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'weightEmpty': value.weightEmpty,
        'weightFull': value.weightFull,
        'length': value.length,
        'width': value.width,
        'height': value.height,
        'cargoType': value.cargoType,
        'numberOfAxles': value.numberOfAxles,
    };
}

/**
* @export
* @enum {string}
*/
export enum MonitoredObjectTrailerProfileTypeEnum {
    COOLER = 'COOLER',
    CURTAINSIDER = 'CURTAIN_SIDER',
    CONTAINER = 'CONTAINER',
    STANDARD = 'STANDARD'
}


