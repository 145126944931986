import { users } from './users';
import { vehicles } from './vehicles';
import { AETRReport, AETRReportFromJSONTyped } from 'generated/backend-api/models';
import moment from 'moment';

// Current weeks stats
const extendedDailyShifts = [1, 2, 0, 1, 2, 1];
const reducedDailyRests = [1, 1, 0, 0, 0, 1];

// Current day - Drive time
export const drivingDuration = [
    4 * 3600 + 51 * 60,
    2 * 3600 + 1 * 60,
    0 * 3600 + 30 * 60,
    2 * 3600 + 15 * 60,
    5 * 3600 + 3 * 60,
    8 * 3600 + 23 * 60
];

/**
 * Generated day intervals
 */
const intervalsArray = [
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 0,
        resting_duration: 0,
        working_duration: 0,
        intervals: []
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 0,
        resting_duration: 0,
        working_duration: 0,
        intervals: []
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 0,
        resting_duration: 0,
        working_duration: 0,
        intervals: []
    },
    {
        reduced_daily_rest: false,
        extended_shift: true,
        driving_duration: 26100.0,
        resting_duration: 59820.0,
        working_duration: 480,
        intervals: [
            [0, 19260, 'R', 'daily rest (regular)'],
            [19260, 19740, 'W', ''],
            [19740, 24960, 'D', ''],
            [24960, 29100, 'R', 'break'],
            [29100, 40440, 'D', ''],
            [40440, 43440, 'R', 'break'],
            [43440, 52800, 'D', ''],
            [52800, 71520, 'R', ''],
            [71520, 71700, 'D', ''],
            [71700, 86400, 'R', 'weekly rest (reduced)']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 9420,
        resting_duration: 76620,
        working_duration: 360,
        intervals: [
            [0, 17580, 'R', 'daily rest (regular)'],
            [17580, 17940, 'W', ''],
            [17940, 27360, 'D', ''],
            [27360, 86400, 'R', 'weekly rest (regular)']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 25380,
        resting_duration: 58140,
        working_duration: 120,
        intervals: [
            [0, 21480, 'R', 'daily rest (regular)'],
            [21480, 21600, 'W', ''],
            [21600, 37020, 'D', ''],
            [39780, 49200, 'D', ''],
            [49200, 50100, 'R', ''],
            [50100, 50160, 'D', ''],
            [50160, 51660, 'R', ''],
            [51660, 52140, 'D', ''],
            [52140, 86400, 'R', 'weekly rest (regular)']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 31500,
        resting_duration: 52500,
        working_duration: 2400,
        intervals: [
            [0, 22500, 'R', 'daily rest (regular)'],
            [22500, 24720, 'W', ''],
            [24720, 26220, 'R', ''],
            [26220, 37860, 'D', ''],
            [37860, 40980, 'R', 'break'],
            [40980, 52380, 'D', ''],
            [52380, 55920, 'R', 'break'],
            [55920, 64380, 'D', ''],
            [64380, 64560, 'W', ''],
            [64560, 86400, 'R', 'weekly rest (reduced)']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 9840,
        resting_duration: 22860,
        working_duration: 240,
        intervals: [
            [53460, 53700, 'R', ''],
            [53700, 63360, 'D', ''],
            [63360, 63600, 'W', ''],
            [63600, 63780, 'D', ''],
            [63780, 86400, 'R', 'daily rest (regular)']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 12960,
        resting_duration: 18900,
        working_duration: 600,
        intervals: [
            [53880, 54000, 'D', ''],
            [54000, 54120, 'W', ''],
            [54120, 55320, 'D', ''],
            [55320, 55560, 'W', ''],
            [55560, 65820, 'D', ''],
            [65820, 65940, 'W', ''],
            [66000, 66480, 'D', ''],
            [66480, 66600, 'W', ''],
            [66600, 67440, 'D', ''],
            [67440, 69180, 'R', ''],
            [69180, 69240, 'D', ''],
            [69240, 86400, 'R', '']
        ]
    },
    {
        reduced_daily_rest: false,
        extended_shift: false,
        driving_duration: 13620,
        resting_duration: 72780,
        working_duration: 0,
        intervals: [
            [0, 13620, 'R', ''],
            [13620, 24600, 'D', ''],
            [24600, 25800, 'R', ''],
            [25800, 28440, 'D', ''],
            [28440, 86400, 'R', 'weekly rest (regular)']
        ]
    }
];

/**
 * 15 Different weeks created
 */
const weekTypesArray = [
    [0, 2, 2, 3, 3, 5, 0],
    [1, 6, 8, 4, 0, 2, 6],
    [2, 3, 2, 5, 2, 4, 8],
    [3, 5, 0, 6, 1, 3, 7],
    [4, 1, 4, 7, 4, 2, 6],

    [5, 5, 9, 0, 2, 1, 3],
    [6, 0, 4, 3, 3, 6, 6],
    [7, 5, 2, 4, 4, 5, 2],
    [0, 7, 3, 3, 4, 0, 1],
    [1, 5, 2, 8, 6, 4, 6],

    [4, 3, 5, 4, 8, 4, 1],
    [4, 0, 1, 2, 5, 5, 2],
    [0, 7, 6, 1, 2, 8, 3],
    [7, 4, 7, 8, 1, 7, 7],
    [0, 1, 8, 3, 0, 3, 4]
];

/**
 * @param i - user iterator
 * @param j - week iterator
 * @returns
 */
function getWeekDays(i: number, j: number) {
    return weekTypesArray[(i * 7 + j) % weekTypesArray.length];
}

/**
 * User get week in for order based on function getWeekDays()
 */
export const aetr: AETRReport[] = users
    .map((u, i) => ({
        tachograph_card: 'CZ 0000000001IH6002',
        timestamp: moment.utc().toISOString(),
        user_id: u.id,
        last_activity: {
            activity: 'drive',
            monitored_object_id: String(vehicles[i].id),
            start: '2021-03-29T13:40:00+02:00',
            end: '2021-03-29T21:53:59.012000+02:00',
            tachograph_card: 'CZ 0000000001IH6002',
            last_activity_raw: 'rest',
            last_activity_raw_timestamp: '2021-03-29T13:43:02+02:00',
            client_id: 100,
            crew_start: null,
            crew_end: null
        },
        weekly_data: Array(5)
            .fill(null)
            .map((_, j) => ({
                week_start: moment
                    .utc()
                    .startOf('day')
                    .startOf('week')
                    .subtract(j * 7, 'days')
                    .toISOString(),
                utilization_start: '2021-03-26T18:49:00+01:00',
                extended_shifts: weekES(getWeekDays(i, j), j === 0 ? moment.utc().day() : 7),
                reduced_daily_rests: weekRDS(getWeekDays(i, j), j === 0 ? moment.utc().day() : 7),
                daily_data: null,
                days_driven: weekDaDr(getWeekDays(i, j), j === 0 ? moment.utc().day() : 7),
                driving_duration: weekDD(getWeekDays(i, j), j === 0 ? moment.utc().day() : 7),
                driving_duration_14_days: weekDD(getWeekDays(i, j), 7) * 2,
                drive_max_seconds: 324000,
                weekly_rest_duration: weekRD(getWeekDays(i, j + 1), 7),
                next_weekly_rest_duration: 86400,
                weekly_rest_period: null,
                aetr_intervals: {
                    '0': intervalsArray[getWeekDays(i, j)[0]],
                    '1': intervalsArray[getWeekDays(i, j)[1]],
                    '2': intervalsArray[getWeekDays(i, j)[2]],
                    '3': intervalsArray[getWeekDays(i, j)[3]],
                    '4': intervalsArray[getWeekDays(i, j)[4]],
                    '5': intervalsArray[getWeekDays(i, j)[5]],
                    '6': intervalsArray[getWeekDays(i, j)[6]]
                }
            })),

        current_day: {
            driving_duration: intervalsArray[getWeekDays(i, 0)[moment.utc().day()]].driving_duration,
            working_duration: intervalsArray[getWeekDays(i, 0)[moment.utc().day()]].working_duration,
            resting_duration: intervalsArray[getWeekDays(i, 0)[moment.utc().day()]].resting_duration,
            daily_utilization:
                intervalsArray[getWeekDays(i, 0)[moment.utc().day()]].driving_duration +
                intervalsArray[getWeekDays(i, 0)[moment.utc().day()]].working_duration,
            daily_utilization_max: '2021-03-29T21:07:00+02:00',
            daily_utilization_max_hours: 15,
            is_crew: i === 3 ? true : false,
            day_start: '2021-03-29T06:07:00+02:00',
            drive_max_hours: 10,
            next_break_start_time: '2021-03-29T15:24:00+02:00',
            reduced_daily_rest: reducedDailyRests[i],
            extended_shift: extendedDailyShifts[i]
        }
    }))
    .map(a => AETRReportFromJSONTyped(a, true));

function weekDD(days: number[], end: number) {
    let sum = 0;
    days.forEach((d, i) => (sum += i <= end ? intervalsArray[d].driving_duration : 0));
    return sum;
}

function weekRD(days: number[], end: number) {
    let sum = 0;
    days.forEach((d, i) => (sum += i <= end ? intervalsArray[d].resting_duration : 0));
    return sum;
}

function weekES(days: number[], end: number) {
    let count = 0;
    days.forEach((d, i) => (count += i <= end && intervalsArray[d].extended_shift ? 1 : 0));
    return count;
}

function weekRDS(days: number[], end: number) {
    let count = 0;
    days.forEach((d, i) => (count += i <= end && intervalsArray[d].reduced_daily_rest ? 1 : 0));
    return count;
}

function weekDaDr(days: number[], end: number) {
    let count = 0;
    days.forEach((d, i) => (count += i <= end && intervalsArray[d].driving_duration > 0 ? 1 : 0));
    return count;
}
