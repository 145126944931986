import { ReactNode } from 'react';
import { Popover, Tooltip, Typography } from 'common/components';

interface Props {
    color?: 'yellow' | 'green' | 'blue';
    icon?: ReactNode;
    value: ReactNode;
    description: ReactNode;
    note?: ReactNode;
    development?: ReactNode;
    qa?: string;
    popover?: ReactNode;
}

export default function DriverBehaviorStat(props: Props) {
    return (
        <Popover
            className="driver-behavior-stat"
            overlayClassName="driver-behavior-chart-trend"
            placement="bottomLeft"
            content={
                props.popover && (
                    <>
                        <Typography.Title level={5}>{props.description}</Typography.Title>
                        {props.popover}
                    </>
                )
            }
        >
            {props.icon && <div className="driver-behavior-stat-icon">{props.icon}</div>}
            <div className="driver-behavior-stat-data" data-qa={props.qa}>
                <Typography.Title level={5}>{props.value}</Typography.Title>
                <div className="driver-behavior-stat-data-description">
                    {props.description}{' '}
                    {props.note && (
                        <Tooltip title={props.note}>
                            <i className="fas fa-info-circle" />
                        </Tooltip>
                    )}
                </div>
                {props.development && <div>{props.development}</div>}
            </div>
        </Popover>
    );
}
