import { AutoComplete } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';

export interface Props extends AutoCompleteProps {}

function EnhancedAutocomplete(props: Props) {
    return <AutoComplete {...props} />;
}

EnhancedAutocomplete.Option = AutoComplete.Option;
export default EnhancedAutocomplete;
