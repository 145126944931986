import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { PerfectDriveDriverDetailModel } from 'common/model/statistics';
import DriverVehicleList from '../DriverVehicleList';
import DriverBehaviorTimesGraph from '../DriverBehaviorTimesGraph';
import DriverBehaviorSumarTable from '../DriverBehaviorSumarTable';
import DriverTrendsScoreStats from '../DriverTrendsScoreStats';
import { DriverBehaviorNavTabs } from '../DriverDetailNav';
import DriverBehaviorCoachDataTable from '../DriverBehaviorCoachTable/DriverBehaviorCoachDataTable';
import DriverBehaviorCoachEcoTable from '../DriverBehaviorCoachTable/DriverBehaviorCoachEcoTable';
import DriverBehaviorCoachWearTearTable from '../DriverBehaviorCoachTable/DriverBehaviorCoachWearTearTable';
import DriverBehaviorRecomends from '../DriverBehaviorRecomends';
import { DriverStatValueFormat } from '../DriverStatValue';
import { WithLogic } from 'App';
import {
    PerfectDriveValueKey,
    formatDriverBehaviorName,
    getPerfectDriveExclamation,
    getPerfectDriveValue
} from 'logic/driver-behavior-coach/driver-behavior-trucks';
import { Theme } from 'common/components/Settings';

interface Props extends WithLogic {
    data?: PerfectDriveDriverDetailModel;
    comparedData?: PerfectDriveDriverDetailModel;
    selectedTab: DriverBehaviorNavTabs;
    dataMap: { [key: string]: DriverBehaviorCoachDataMap[] };
    driverList: {
        value: string;
        label: string;
    }[];
    theme: Theme;
    loading?: boolean;
}

export interface DriverBehaviorCoachDataMap {
    key: string;
    values?: {
        highway?: PerfectDriveValueKey;
        urban?: PerfectDriveValueKey;
        country?: PerfectDriveValueKey;
        other?: PerfectDriveValueKey;
        summary?: PerfectDriveValueKey;
        exclamation?: PerfectDriveValueKey;
    };
    title?: string;
    unit?: DriverStatValueFormat;
    unitName?: string;
    icon?: string;
    postfix?: string;
    info?: string;
    recommendKey?: string;
    recommend?: string;
    starWeight?: number;
    fixed?: number;
    diminisher?: number;
    maxValue?: number;
    decimals?: number;
}

export default function DriverBehaviorCoach(props: Props) {
    const [selected, setSelected] = useState<DriverBehaviorCoachDataMap | undefined>();
    const [recomends, setRecomends] = useState<DriverBehaviorCoachDataMap[] | undefined>([]);
    const [oldDriverId, setOldDriverId] = useState<string | undefined>();
    const dataMap = props.dataMap;

    useEffect(() => {
        if (oldDriverId !== props.data?.id) {
            loadRecomends();
        }
    });

    const loadRecomends = () => {
        setOldDriverId(props.data?.id);
        const recomendsList = props.logic.driverBehaviorCoach().trucks().getRecommends()?.slice(0, 3) ?? [];
        setRecomends(recomendsList);

        if (recomendsList && (recomendsList.length ?? 0) > 0) {
            setSelected(getDataMapByKey(recomendsList?.[0]?.key));
        }
    };

    const getDriverData = (data: PerfectDriveDriverDetailModel) => {
        const score = data.scores?.[0];

        return {
            name: formatDriverBehaviorName(data.name, data.surname, data.tachocard),
            vehicles: data.vehicles,
            timesGraph: score
                ? {
                      drivingTime: {
                          value: getPerfectDriveValue('vGlobal.fg0TDrv', score.detail),
                          exclamation: getPerfectDriveExclamation('fg0TDrv', score.detail)
                      },
                      idlingTime: {
                          value: getPerfectDriveValue('vGlobal.fg0TStayIncTStayPto', score.detail),
                          exclamation: getPerfectDriveExclamation('fg0TStayIncTStayPto', score.detail)
                      },
                      idlingTimeShorter2: 100,
                      idlingTimeLonger2: 200,
                      idlingTimeLonger30: 0,
                      idlingTimeLonger60: 300
                  }
                : undefined,
            timesTable: score
                ? {
                      distance: {
                          value: getPerfectDriveValue('vGlobal.fg0DDrv', score.detail),
                          exclamation: getPerfectDriveExclamation('fg0DDrv', score.detail)
                      },
                      fuelConsumption: {
                          value: getPerfectDriveValue('vGlobal.fg0C', score.detail, 0),
                          exclamation: getPerfectDriveExclamation('fg0C', score.detail)
                      },
                      departures: {
                          value: getPerfectDriveValue('vGlobal.fg0CntDpDDrv', score.detail, 0),
                          exclamation: getPerfectDriveExclamation('fg0CntDpDDrv', score.detail)
                      },
                      averageAscent: {
                          value: getPerfectDriveValue('vGlobal.fg0AltIncrDDrv', score.detail, 0),
                          exclamation: getPerfectDriveExclamation('fg0AltIncrDDrv', score.detail)
                      },
                      averageConsumption: {
                          value: getPerfectDriveValue('vGlobal.fg0CDrvDDrv', score.detail, 0),
                          exclamation: getPerfectDriveExclamation('fg0CDrvDDrv', score.detail)
                      },
                      averageWeight: {
                          value: getPerfectDriveValue('vGlobal.fg0MAvg', score.detail, 0),
                          exclamation: getPerfectDriveExclamation('fg0MAvg', score.detail)
                      }
                  }
                : undefined
        };
    };

    const getData = (): any[] => {
        let driverData = [];

        if (props.data) {
            driverData.push(getDriverData(props.data));
        }

        if (props.comparedData) {
            driverData.push(getDriverData(props.comparedData));
        }
        return driverData;
    };

    const getDataMapByKey = (key: string): DriverBehaviorCoachDataMap | undefined => {
        for (let dataKey in dataMap) {
            const data = dataMap[dataKey].find(d => d.key === key);
            if (data) {
                return data;
            }
        }
        return undefined;
    };

    const handlerRecomendsChange = (key: string) => {
        setSelected(getDataMapByKey(key));
    };

    const drivers = getData();
    const multi = drivers.length > 1;

    return (
        <div className="driver-score-stats">
            <Row>
                <Col flex={1}>
                    <DriverTrendsScoreStats
                        selectedTab={props.selectedTab}
                        data={props.data}
                        drivers={props.driverList}
                        comparedData={props.comparedData}
                        theme={props.theme}
                    />
                    <Row justify="space-between" gutter={16}>
                        <Col span={6}>
                            <DriverVehicleList driversList={drivers} />
                        </Col>
                        <Col span={9}>
                            <DriverBehaviorTimesGraph driversList={drivers} />
                        </Col>
                        <Col span={9}>
                            <DriverBehaviorSumarTable driversList={drivers} />
                        </Col>
                    </Row>
                </Col>
                {!multi && (
                    <Col className="recomends-col">
                        <DriverBehaviorRecomends
                            dataList={recomends}
                            icon={selected?.icon}
                            onChange={handlerRecomendsChange}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <DriverBehaviorCoachDataTable
                    data={props.data}
                    comparedData={props.comparedData}
                    dataMap={dataMap.drive}
                    selectedKey={!multi ? selected?.key : undefined}
                />
            </Row>
            <Row>
                <DriverBehaviorCoachEcoTable
                    data={props.data}
                    comparedData={props.comparedData}
                    dataMap={dataMap.eco}
                    selectedKey={!multi ? selected?.key : undefined}
                />
            </Row>
            <Row>
                <DriverBehaviorCoachWearTearTable
                    data={props.data}
                    comparedData={props.comparedData}
                    dataMap={dataMap.wearTear}
                    selectedKey={!multi ? selected?.key : undefined}
                />
            </Row>
        </div>
    );
}
