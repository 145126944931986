import { withTranslation, WithTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import moment, { duration } from 'moment';
import numeral from 'numeral';
import cn from 'classnames';
import { WithLogic, withLogicContext } from 'App';
import { Table } from 'common/components';
import { JourneyStatisticsResponseStats } from 'generated/backend-api';
import {
    DATE_FORMAT,
    H,
    KG,
    KM,
    L,
    M,
    MONTH_YEAR_FORMAT,
    TABLE_SCROLL_Y_DEFAULT,
    TIME_FORMAT_SHORT
} from 'domain-constants';
import TableHeadLabel from 'common/components/TableHeadLabel';
import { DriverModel } from 'logic/user/users';
import qa from 'qa-selectors';
import { StatisticReportGroup } from '../../statistic-report-logic';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

interface Props extends WithTranslation, WithLogic {
    loading?: boolean;
    active: StatisticReportGroup;
    selected?: number;
    data: JourneyStatisticsResponseStats[];
    drivers: DriverModel[];
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    onRowClick: (row: number) => void;
}

function StatisticReportTable(props: Props) {
    function getColumnWidth(columnName?: string): number {
        switch (columnName) {
            case 'day':
                return 134;
            case 'from':
            case 'to':
                return 90;
            case 'month':
                return 154;
            case 'vehicle':
                return 179;
            case 'driver':
                return 234;
            default:
                switch (props.active) {
                    case StatisticReportGroup.ByDays:
                        return 180;
                    case StatisticReportGroup.ByMonths:
                        return 200;
                    case StatisticReportGroup.ByVehicles:
                        return 175;
                    default:
                    case StatisticReportGroup.ByDrivers:
                        return 190;
                }
        }
    }

    return (
        <div className="statistic-report-table">
            <Table<JourneyStatisticsResponseStats>
                dataSource={props.data.map((d, i) => ({ ...d, key: i }))}
                loading={props.loading}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={(_, i) => ({
                    className: cn({
                        'ant-table-row-selected': props.selected !== undefined && props.selected === i
                    }),
                    onClick: () => {
                        i !== undefined && props.onRowClick?.(i);
                    }
                })}
                columns={
                    [
                        props.active === StatisticReportGroup.ByDays &&
                            ({
                                key: 'timestampFrom',
                                title: ({ sortColumns }) => {
                                    const sortedColumn = sortColumns?.find(
                                        ({ column }) => column.key === 'timestampFrom'
                                    );
                                    return (
                                        <TableHeadLabel
                                            title={props.t('common.day')}
                                            sorter={true}
                                            sorterOrder={sortedColumn?.order ?? undefined}
                                        />
                                    );
                                },
                                width: getColumnWidth('day'),
                                sorter: (a, b) => a.timestampFrom.getDate() - b.timestampFrom.getDate(),
                                render: (_: any, record: JourneyStatisticsResponseStats) => (
                                    <span data-qa={qa.statisticReport.table.fieldDay}>
                                        {record.timestampFrom && moment(record.timestampFrom).format(DATE_FORMAT)}
                                    </span>
                                )
                            } as ColumnType<JourneyStatisticsResponseStats>),
                        props.active === StatisticReportGroup.ByDays && {
                            title: <TableHeadLabel title={props.t('common.from')} align="center" />,
                            width: getColumnWidth('from'),
                            align: 'center',
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldFrom}>
                                    {record.activityStart && moment(record.activityStart).format(TIME_FORMAT_SHORT)}
                                </span>
                            )
                        },
                        props.active === StatisticReportGroup.ByDays && {
                            title: <TableHeadLabel title={props.t('common.to')} align="center" />,
                            width: getColumnWidth('to'),
                            align: 'center',
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldTo}>
                                    {record.activityEnd && moment(record.activityEnd).format(TIME_FORMAT_SHORT)}
                                </span>
                            )
                        },
                        props.active === StatisticReportGroup.ByMonths && {
                            title: <TableHeadLabel title={props.t('common.month')} />,
                            width: getColumnWidth('month'),
                            align: 'left',
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldMonth}>
                                    {moment(record.timestampFrom).format(MONTH_YEAR_FORMAT)}
                                </span>
                            )
                        },
                        props.active === StatisticReportGroup.ByVehicles && {
                            title: <TableHeadLabel title={props.t('common.rn')} />,
                            width: getColumnWidth('vehicle'),
                            align: 'center',
                            render: (_: any, record: JourneyStatisticsResponseStats) => {
                                const vehicle = props.vehicles.find(d => String(d.id) === record.monitoredObjectId);
                                return (
                                    <span data-qa={qa.statisticReport.table.fieldRn}>
                                        {vehicle?.registrationNumber}
                                    </span>
                                );
                            }
                        },
                        props.active === StatisticReportGroup.ByVehicles && {
                            title: <TableHeadLabel title={props.t('StatisticReport.stateOfOdometer')} units={KM} />,
                            width: getColumnWidth(),
                            align: 'center',
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldOdometerState}>
                                    {numeral(record.odometerEnd).format('0,0')}
                                </span>
                            )
                        },
                        props.active === StatisticReportGroup.ByDrivers && {
                            title: <TableHeadLabel title={props.t('common.driver')} />,
                            width: getColumnWidth(),
                            align: 'left',
                            render: (_: any, record: JourneyStatisticsResponseStats) => {
                                const driver = props.drivers.find(d => d.id === String(record.driverId));
                                return (
                                    <span data-qa={qa.statisticReport.table.fieldDriver}>
                                        {driver?.name} {driver?.surname}
                                    </span>
                                );
                            }
                        },
                        {
                            key: 'activityTimeSeconds',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'activityTimeSeconds'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('JourneysTable.duration')}
                                        units={`${H}:${M}`}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.activityTimeSeconds - b.activityTimeSeconds,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldDuration}>
                                    {duration(record.activityTimeSeconds, 'seconds').format('hh:mm', {
                                        trim: false
                                    })}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'distanceKilometers',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'distanceKilometers'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('JourneysTable.distance')}
                                        units={KM}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.distanceKilometers - b.distanceKilometers,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldDistance}>
                                    {numeral(record.distanceKilometers).format('0,0')}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'idleTimeSeconds',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'idleTimeSeconds'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('JourneysTable.idling')}
                                        units={`${H}:${M}`}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.idleTimeSeconds - b.idleTimeSeconds,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldIdling}>
                                    {duration(record.idleTimeSeconds, 'seconds').format('hh:mm', {
                                        trim: false
                                    })}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'stoppedTimeSeconds',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'stoppedTimeSeconds'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('JourneysTable.stopped')}
                                        units={`${H}:${M}`}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.stoppedTimeSeconds - b.stoppedTimeSeconds,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldStopped}>
                                    {duration(record.stoppedTimeSeconds, 'seconds').format('hh:mm', {
                                        trim: false
                                    })}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'consumptionLiters',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'consumptionLiters'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('JourneysTable.consumption')}
                                        units={L}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.consumptionLiters - b.consumptionLiters,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldConsumption}>
                                    {numeral(record.consumptionLiters).format('0,0')}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'consumptionLitersPer100km',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'consumptionLitersPer100km'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('common.averageConsumption')}
                                        units={`${L}/100 ${KM}`}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.consumptionLitersPer100km - b.consumptionLitersPer100km,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldAvgConsumption}>
                                    {record.consumptionLitersPer100km < 100 && record.consumptionLitersPer100km !== 0
                                        ? numeral(record.consumptionLitersPer100km).format('0,0.0')
                                        : '-'}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'consumptionIdleLiters',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'consumptionIdleLiters'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('common.consumptionIdling')}
                                        units={L}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.consumptionIdleLiters - b.consumptionIdleLiters,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldAvgConsumptionIdling}>
                                    {numeral(record.consumptionIdleLiters).format('0,0')}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        {
                            key: 'consumptionIdleLitersPer1h',
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(
                                    ({ column }) => column.key === 'consumptionIdleLitersPer1h'
                                );
                                return (
                                    <TableHeadLabel
                                        title={props.t('common.consumptionIdling')}
                                        units={`${L}/1 ${H}`}
                                        sorter={true}
                                        sorterOrder={sortedColumn?.order ?? undefined}
                                    />
                                );
                            },
                            width: getColumnWidth(),
                            align: 'center',
                            sorter: (a, b) => a.consumptionIdleLitersPer1h - b.consumptionIdleLitersPer1h,
                            render: (_: any, record: JourneyStatisticsResponseStats) => (
                                <span data-qa={qa.statisticReport.table.fieldAvgConsumptionIdlingPer1h}>
                                    {numeral(record.consumptionIdleLitersPer1h).format('0,0')}
                                </span>
                            )
                        } as ColumnType<JourneyStatisticsResponseStats>,
                        props.active !== StatisticReportGroup.ByDrivers &&
                            ({
                                key: 'co2Emission',
                                title: ({ sortColumns }) => {
                                    const sortedColumn = sortColumns?.find(
                                        ({ column }) => column.key === 'co2Emission'
                                    );
                                    return (
                                        <TableHeadLabel
                                            title={props.t('common.co2Emission')}
                                            units={KG}
                                            sorter={true}
                                            sorterOrder={sortedColumn?.order ?? undefined}
                                            level="normal"
                                        />
                                    );
                                },
                                width: getColumnWidth(),
                                align: 'center',
                                sorter: (a, b) => (a.co2Emission ?? 0) - (b.co2Emission ?? 0),
                                render: (_: any, record: JourneyStatisticsResponseStats) => (
                                    <span data-qa={qa.statisticReport.table.fieldC02Emission}>
                                        {record.co2Emission ? numeral(record.co2Emission / 1000).format('0,0.0') : '-'}
                                    </span>
                                )
                            } as ColumnType<JourneyStatisticsResponseStats>)
                    ].filter(d => d) as ColumnType<JourneyStatisticsResponseStats>[]
                }
            />
        </div>
    );
}

export default withTranslation()(withLogicContext(StatisticReportTable));
