/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BackendApiApiV2ActualVehicleStateVehicleTransport,
    BackendApiApiV2ActualVehicleStateVehicleTransportFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleTransportFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleTransportToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalDeviceMonitoredObjectState
 */
export interface ExternalDeviceMonitoredObjectState {
    /**
     * 
     * @type {Array<BackendApiApiV2ActualVehicleStateVehicleTransport>}
     * @memberof ExternalDeviceMonitoredObjectState
     */
    activeTransports: Array<BackendApiApiV2ActualVehicleStateVehicleTransport>;
}

export function ExternalDeviceMonitoredObjectStateFromJSON(json: any): ExternalDeviceMonitoredObjectState {
    return ExternalDeviceMonitoredObjectStateFromJSONTyped(json, false);
}

export function ExternalDeviceMonitoredObjectStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDeviceMonitoredObjectState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeTransports': ((json['activeTransports'] as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleTransportFromJSON)),
    };
}

export function ExternalDeviceMonitoredObjectStateToJSON(value?: ExternalDeviceMonitoredObjectState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeTransports': ((value.activeTransports as Array<any>).map(BackendApiApiV2ActualVehicleStateVehicleTransportToJSON)),
    };
}


