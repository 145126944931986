/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleProfileCreateDefault
 */
export interface VehicleProfileCreateDefault {
    /**
     * 
     * @type {string}
     * @memberof VehicleProfileCreateDefault
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleProfileCreateDefault
     */
    clientId: number;
}

export function VehicleProfileCreateDefaultFromJSON(json: any): VehicleProfileCreateDefault {
    return VehicleProfileCreateDefaultFromJSONTyped(json, false);
}

export function VehicleProfileCreateDefaultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleProfileCreateDefault {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'clientId': json['client_id'],
    };
}

export function VehicleProfileCreateDefaultToJSON(value?: VehicleProfileCreateDefault | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'client_id': value.clientId,
    };
}


