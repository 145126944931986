import * as Yup from 'yup';
import { Col, Divider, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components';
import { SelectField, DatePickerField, RadioField } from 'common/fields';
import { SelectOption } from 'common/components/Form/Select';
import { CustomPlaceCrossingType } from 'generated/backend-api/models';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import qa from 'qa-selectors';

export interface PlaceOfWorkCrossingFormModel {
    date?: string;
    type?: CustomPlaceCrossingType;
    placeOfWorkId?: string;
}

interface Props {
    placeOfWorkOpts: SelectOption[];
    lang: string;
    initialValues?: Partial<PlaceOfWorkCrossingFormModel>;
    onSubmit: (values: PlaceOfWorkCrossingFormModel) => void;
    onCancel?: () => void;
}

function PlaceOfWorkCrossingForm({ onSubmit, initialValues, onCancel, placeOfWorkOpts, lang }: Props) {
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        date: Yup.string().required(t('common.required')),
        type: Yup.string().strict(true).oneOf(Object.values(CustomPlaceCrossingType)).required(t('common.required')),
        placeOfWorkId: Yup.string().required(t('common.required'))
    });

    function handleOnSubmit(values: PlaceOfWorkCrossingFormModel) {
        onSubmit?.(values);
    }

    return (
        <Formik<PlaceOfWorkCrossingFormModel>
            initialValues={{
                date: initialValues?.date,
                type: initialValues?.type,
                placeOfWorkId: initialValues?.placeOfWorkId
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<PlaceOfWorkCrossingFormModel>) => (
                <div className="place-of-work-crossing-form">
                    <Form>
                        <Row className="form-row" gutter={[20, 10]}>
                            <Col span={24}>
                                <RadioField.Group
                                    horizontal
                                    label={t('Allowance.placeOfWorkCrossingForm.type')}
                                    name="type"
                                    options={[
                                        {
                                            label: t('common.entry'),
                                            value: CustomPlaceCrossingType.Entry
                                        },
                                        {
                                            label: t('common.exit'),
                                            value: CustomPlaceCrossingType.Exit
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[20, 10]}>
                            <Col span={24}>
                                <DatePickerField
                                    label={t('Allowance.placeOfWorkCrossingForm.date')}
                                    lang={lang}
                                    showTime={{ format: TIME_FORMAT_SHORT }}
                                    name="date"
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[20, 50]}>
                            <Col span={24}>
                                <SelectField
                                    label={t('Allowance.placeOfWorkCrossingForm.placeOfWork')}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    name="placeOfWorkId"
                                    options={placeOfWorkOpts}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[20, 10]} className="form-buttons">
                            <Col span={12}>
                                <Button onClick={onCancel} qa={qa.borderCrossing.placeOfWork.btnCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    disabled={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                    qa={qa.borderCrossing.placeOfWork.btnSubmit}
                                >
                                    {t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default PlaceOfWorkCrossingForm;
