/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyProfileSerializer
 */
export interface WriteOnlyProfileSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyProfileSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyProfileSerializer
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyProfileSerializer
     */
    aboveTemperatureThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyProfileSerializer
     */
    belowTemperatureThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyProfileSerializer
     */
    alarmTimerSeconds?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyProfileSerializer
     */
    client: number;
}

export function WriteOnlyProfileSerializerFromJSON(json: any): WriteOnlyProfileSerializer {
    return WriteOnlyProfileSerializerFromJSONTyped(json, false);
}

export function WriteOnlyProfileSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyProfileSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'aboveTemperatureThreshold': !exists(json, 'above_temperature_threshold') ? undefined : json['above_temperature_threshold'],
        'belowTemperatureThreshold': !exists(json, 'below_temperature_threshold') ? undefined : json['below_temperature_threshold'],
        'alarmTimerSeconds': !exists(json, 'alarm_timer_seconds') ? undefined : json['alarm_timer_seconds'],
        'client': json['client'],
    };
}

export function WriteOnlyProfileSerializerToJSON(value?: WriteOnlyProfileSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'above_temperature_threshold': value.aboveTemperatureThreshold,
        'below_temperature_threshold': value.belowTemperatureThreshold,
        'alarm_timer_seconds': value.alarmTimerSeconds,
        'client': value.client,
    };
}


