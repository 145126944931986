import { Col, Row, Tooltip } from 'antd';
import { Button } from 'common/components';
import { AvailableCurrencies } from 'common/model/currency';
import { VehicleWithAvailability } from 'common/model/transports';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemInfo } from 'resources/images/planner';
import { VehicleProfile } from '../../planner-logic';
import EditItem from '../EditItem';
import { defaultVehicleProfileName } from '../RouteParamsConfigurator';
import VehicleProfileDetail from '../VehicleProfileDetail';

interface Props extends WithTranslation {
    profile: VehicleProfile;
    demoMode?: boolean;
    qaEdit?: string;
    qaDelete?: string;
    qaInfo?: string;
    currency?: AvailableCurrencies;
    onButtonEditClick?: (
        profile: VehicleProfile,
        isVehicle: boolean,
        vehicle: VehicleWithAvailability | undefined
    ) => void;
    onButtonDeleteClick?: (profile: VehicleProfile) => void;
}

const VehicleProfileItem = (props: Props) => {
    const handleButtonEditClick = () => {
        props.onButtonEditClick?.(props.profile, false, undefined);
    };

    const handleButtonDeleteClick = () => {
        props.onButtonDeleteClick?.(props.profile);
    };
    const { t } = props;
    const name =
        props.profile.name === defaultVehicleProfileName ? t('VehicleProfile.defaultProfileName') : props.profile.name;

    return (
        <EditItem
            className="vehicle-profile-item"
            showDelete={!props.profile.isDefault && !props.demoMode}
            showEdit={true}
            qaEdit={props.qaEdit}
            qaDelete={props.qaDelete}
            onButtonEditClick={handleButtonEditClick}
            onButtonDeleteClick={handleButtonDeleteClick}
        >
            <Row align="middle">
                <Col className="vehicle-profile-item-rn">{name}</Col>
                <Col>
                    <Tooltip
                        overlayClassName="vehicle-profile-tooltip"
                        title={<VehicleProfileDetail profile={props.profile} currency={props.currency} />}
                        placement="bottomRight"
                    >
                        <Button
                            type="link"
                            className="edit-item-action info-profile"
                            icon={<img src={ItemInfo} alt="info-vehicle-profile" />}
                            qa={props.qaInfo}
                        />
                    </Tooltip>
                </Col>
            </Row>
        </EditItem>
    );
};

export default withTranslation()(VehicleProfileItem);
