import { WithTranslation, withTranslation } from 'react-i18next';

import { Role } from 'logic/auth';
import FuelCardDetailCard from './FuelCardDetailCard';
import FuelCardsForm, { FuelCardFormModel } from 'common/forms/FuelCardsForm';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';

interface Props extends WithTranslation {
    edit: boolean;
    fuelCompanies: ReadOnlyFuelCompanySerializer[];
    model: ReadOnlyFuelCardSerializer;
    demoMode?: boolean;
    roles: Role[];
    lang: string;
    onFormCancelClick?: () => void;
    onFormSubmitClick?: (values: FuelCardFormModel) => Promise<boolean>;
}

function FuelCardsDetail(props: Props) {
    const { model, fuelCompanies, lang, demoMode } = props;

    return (
        <div className="management-fuel-cards-detail">
            {props.edit ? (
                <FuelCardsForm
                    mode={'EDIT'}
                    initialValues={{
                        number: model.number ?? '',
                        expirationDate: model.expirationDate?.toISOString(),
                        fuelCompany: String(model.fuelCompany),
                        holder: model.holder ?? '',
                        note: model.note ?? ''
                    }}
                    lang={lang}
                    fuelCompanies={fuelCompanies}
                    demoMode={demoMode}
                    onCancel={props.onFormCancelClick}
                    onSubmit={props.onFormSubmitClick}
                />
            ) : (
                <FuelCardDetailCard fuelCompanies={fuelCompanies} data={model} />
            )}
        </div>
    );
}

export default withTranslation()(FuelCardsDetail);
