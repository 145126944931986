/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyGetPricesV1FuelstationsPricesPost
 */
export interface BodyGetPricesV1FuelstationsPricesPost {
    /**
     * 
     * @type {string}
     * @memberof BodyGetPricesV1FuelstationsPricesPost
     */
    client?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyGetPricesV1FuelstationsPricesPost
     */
    fuelStations?: Array<string>;
}

export function BodyGetPricesV1FuelstationsPricesPostFromJSON(json: any): BodyGetPricesV1FuelstationsPricesPost {
    return BodyGetPricesV1FuelstationsPricesPostFromJSONTyped(json, false);
}

export function BodyGetPricesV1FuelstationsPricesPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyGetPricesV1FuelstationsPricesPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': !exists(json, 'client') ? undefined : json['client'],
        'fuelStations': !exists(json, 'fuel_stations') ? undefined : json['fuel_stations'],
    };
}

export function BodyGetPricesV1FuelstationsPricesPostToJSON(value?: BodyGetPricesV1FuelstationsPricesPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': value.client,
        'fuel_stations': value.fuelStations,
    };
}


