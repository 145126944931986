import { LayoutContent } from 'common/components/Layout/Content';
import numeral from 'numeral';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import AllowanceDetailActions from '../components/AllowanceActions/AllowanceDetailActions';
import AllowanceBar from '../components/AllowanceBar/AllowanceBar';
import AllowanceBorderCrossingEdit from '../components/AllowanceBorderCrossingEdit/AllowanceBorderCrossingEdit';
import AllowanceBulkEdit from '../components/AllowanceBulkEdit/AllowanceBulkEdit';
import AllowanceDetailTable, { DiemsDetailTableData } from '../components/AllowanceDetailTable/AllowanceDetailTable';
import AllowanceSummary, { DiemsSummary } from '../components/AllowancesSummary/AllowanceSummary';
import { fromBlankAsync, Workbook, Style, Cell } from 'xlsx-populate';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import i18n from 'i18next';
import Confirm, { MessageType } from 'common/components/Confirm';
import { RouteNames, WithLogic, withLogicContext } from 'App';
import { Col, message, Row } from 'antd';
import { DiemsBulkFormModel } from 'common/forms/DiemsBulkForm/DiemsBulkForm';
import qs from 'qs';
import { DATE_FORMAT, DATE_FORMAT_SHORT_BE } from 'domain-constants';
import { BorderCrossingFormModel } from 'common/forms/BorderCrossingForm';
import AllowancePlaceOfWorkCrossingEdit from '../components/AllowancePlaceOfWorkCrossingEdit/AllowancePlaceOfWorkCrossingEdit';
import { PlaceOfWorkCrossingFormModel } from 'common/forms/PlaceOfWorkCrossingForm';
import { CustomPlace, Rate } from 'generated/backend-api/models';
import { Subscription } from 'rxjs';
import { DriverModel } from 'logic/user/users';
import HelperModal from 'common/components/HelperModal';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import qa from 'qa-selectors';
import { CalculationTypeOptions, pocketMoneyMapper } from 'common/model/allowance';
import { ReadOnlyDiemsRates, ReadOnlyDiemsRules } from 'generated/new-main/models';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { Button, Modal } from 'common/components';
import { Link } from 'react-router-dom';
import { formatDuration } from 'common/utils/dateTimeUtils';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { toAddress } from 'common/utils/address';
import { EWClient } from 'generated/main-data-api';
import { AddressStructured } from 'common/model/address';
import ManualRatesForm, { ManualRatesFormModel } from 'common/forms/ManualRatesForm';

type Params = {
    id?: string;
};

type Query = {
    start?: string;
    end?: string;
};

interface Props extends WithLogic, WithTranslation, RouteComponentProps<Params> {}

interface State {
    placeOfWorkCrossingEditVisible: boolean;
    placeOfWorkCrossingEditProcessing: boolean;
    borderCrossingEditVisible: boolean;
    borderCrossingEditProcessing: boolean;
    exportLoading: boolean;
    date: {
        start: string;
        end: string;
    };
    userData?: DriverModel;
    diems: {
        loading: boolean;
        summaryData?: DiemsSummary;
        tableData?: DiemsDetailTableData[];
        selectedDiemsId?: string;
    };
    diemsRates?: ReadOnlyDiemsRates[];
    diemsRatesLoading?: boolean;
    manualRatesForm: {
        isOpen: boolean;
        initialValues?: Partial<ManualRatesFormModel>;
        current?: {
            domestic?: Rate;
            foreign?: Rate;
        };
    };
    bulk: {
        bulkEditVisible: boolean;
        bulkConfirmVisible: boolean;
        overlappingCount: number;
        overlappingCountLoading?: boolean;
        loading: boolean;
        formData?: { users: string[] } & DiemsBulkFormModel;
    };
    appliedRuleForSelectedRow?: {
        loading: boolean;
        rule?: ReadOnlyDiemsRules;
    };
    userPlaceOfWork: CustomPlace[];
    helper?: {
        content: string;
    };
}

class AllowanceDriverDetailModule extends React.Component<Props, State> {
    private _userId: number;
    private _defaultStartDate: string;
    private _defaultEndDate: string;
    private _diemsOverlappingLoadingSubscription?: Subscription;
    private _diemsOverlappingSubscription?: Subscription;
    private _diemsLoadingSubscription?: Subscription;
    private _diemsErrorSubscription?: Subscription;
    private _diemsDataSubscription?: Subscription;

    constructor(props: Props) {
        super(props);
        const params: Query = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        if (params.start) {
            this._defaultStartDate = params.start;
        } else {
            this._defaultStartDate = moment().startOf('month').format(DATE_FORMAT);
        }
        if (params.end) {
            this._defaultEndDate = params.end;
        } else {
            this._defaultEndDate = moment().format(DATE_FORMAT);
        }

        this.state = {
            diems: {
                loading: true
            },
            date: {
                start: this._defaultStartDate,
                end: this._defaultEndDate
            },
            placeOfWorkCrossingEditProcessing: false,
            placeOfWorkCrossingEditVisible: false,
            borderCrossingEditVisible: false,
            borderCrossingEditProcessing: false,
            bulk: {
                bulkEditVisible: false,
                bulkConfirmVisible: false,
                overlappingCount: 0,
                loading: false
            },
            manualRatesForm: {
                isOpen: false
            },
            exportLoading: false,
            userPlaceOfWork: []
        };

        this._userId = parseInt(this.props.match.params.id ?? '');
    }

    componentDidMount() {
        this.setState(
            state => ({
                date: {
                    start: this._defaultStartDate,
                    end: this._defaultEndDate
                },
                bulk: {
                    ...state.bulk,
                    formData: state.bulk.formData
                        ? {
                              ...state.bulk.formData,
                              date: {
                                  start: this._defaultStartDate,
                                  end: this._defaultEndDate
                              }
                          }
                        : undefined
                }
            }),
            () => {
                this._diemsOverlappingLoadingSubscription = this.props.logic
                    .diems()
                    .rules()
                    .diemsOverlappingLoading.subscribe(loading => {
                        this.setState(state => ({
                            bulk: {
                                ...state.bulk,
                                overlappingCountLoading: loading
                            }
                        }));
                    });

                this._diemsOverlappingSubscription = this.props.logic
                    .diems()
                    .rules()
                    .diemsOverlappingData.subscribe(data => {
                        this.setState(state => ({
                            bulk: {
                                ...state.bulk,
                                overlappingCount: data.count
                            }
                        }));
                    });

                this._diemsLoadingSubscription = this.props.logic.diems().diemsLoading.subscribe(loading => {
                    this.setState(state => ({
                        diems: {
                            ...state.diems,
                            loading
                        }
                    }));
                });
                this._diemsErrorSubscription = this.props.logic.diems().diemsError.subscribe(_err => {
                    message.error(this.props.t('common.error.loadDataError'));
                });
                this._diemsDataSubscription = this.props.logic.diems().diemsData.subscribe(_data => {
                    const tableData = this.props.logic.diems().getDiemsUserTableData(this._userId);
                    const summaryData = this.props.logic.diems().getDiemsUserSummary(this._userId);
                    const userData = this.props.logic.diems().getDiemsUser(this._userId);
                    this.setState(state => ({
                        diems: {
                            ...state.diems,
                            summaryData: summaryData,
                            tableData: tableData
                        },
                        userData: userData
                    }));
                });

                this.props.logic
                    .poi()
                    .getUserCustomPlaces(this._userId)
                    .then(data => {
                        this.setState({
                            userPlaceOfWork: data
                        });
                    });

                this.props.logic
                    .diems()
                    .getDiems(
                        moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                        moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate(),
                        this._userId
                    );
            }
        );
    }

    componentWillUnmount() {
        this._diemsOverlappingLoadingSubscription?.unsubscribe();
        this._diemsDataSubscription?.unsubscribe();
        this._diemsErrorSubscription?.unsubscribe();
        this._diemsLoadingSubscription?.unsubscribe();
        this._diemsOverlappingSubscription?.unsubscribe();
    }

    render() {
        return (
            <LayoutContent
                className="allowance no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <AllowanceBar
                            isSuperAdmin={this.props.logic.auth().superadmin()}
                            title={`${this.state.userData?.name ?? ''} ${this.state.userData?.surname ?? ''}`}
                            showBackButton
                            dateRange={{
                                start: this.state.date.start,
                                end: this.state.date.end
                            }}
                            loading={this.state.diems.loading}
                            demoMode={this.props.logic.demo().isActive}
                            onBulkEdit={this._onBulkGlobalEdit}
                            onCalendarChange={this._onCalendarChange}
                            exportLoading={this.state.exportLoading}
                            onExportClick={this._onExportClick}
                            onBackButtonCLick={this._onBackButtonClick}
                            onHelperClick={this._onBarHelperClick}
                        />
                        <AllowanceSummary {...this.state.diems.summaryData} />
                        <AllowanceDetailTable
                            loading={this.state.diems.loading}
                            data={this.state.diems.tableData ?? []}
                            selectedId={this.state.diems.selectedDiemsId}
                            isPolishDiems={this.props.logic.auth().newEWClient()?.country?.iso2.toUpperCase() === 'PL'}
                            isSlovakDiems={this.props.logic.auth().newEWClient()?.country?.iso2.toUpperCase() === 'SK'}
                            demoMode={this.props.logic.demo().isActive}
                            onPaginationChange={() => {}}
                            onEditDetailClick={this._onBulkDetailEdit}
                            onRateEditClick={this._onManualRateEditClick}
                        />
                        <AllowanceBorderCrossingEdit
                            processing={this.state.borderCrossingEditProcessing}
                            visible={this.state.borderCrossingEditVisible}
                            onSubmit={this._onBorderCrossingSubmit}
                            onCancel={() => {
                                this.setState({
                                    borderCrossingEditVisible: false
                                });
                            }}
                        />
                        <AllowancePlaceOfWorkCrossingEdit
                            customPlaces={this.state.userPlaceOfWork}
                            processing={this.state.placeOfWorkCrossingEditProcessing}
                            visible={this.state.placeOfWorkCrossingEditVisible}
                            onSubmit={this._onPlaceOfWorkCrossingSubmit}
                            onCancel={() => {
                                this.setState({
                                    placeOfWorkCrossingEditVisible: false
                                });
                            }}
                        />
                        {this.state.bulk.bulkEditVisible && (
                            <AllowanceBulkEdit
                                isPolishDiems={
                                    this.props.logic.auth().newEWClient()?.country?.iso2.toUpperCase() === 'PL'
                                }
                                defaultStart={this.state.date.start}
                                defaultEnd={this.state.date.end}
                                visible={this.state.bulk.bulkEditVisible}
                                demoMode={this.props.logic.demo().isActive}
                                onCancel={() => {
                                    this.setState(state => ({
                                        bulk: {
                                            ...state.bulk,
                                            bulkEditVisible: false
                                        }
                                    }));
                                }}
                                onSubmit={this._onBulkCreateClick}
                                onEdit={this._onBulkEditClick}
                            />
                        )}
                        {this.state.bulk.bulkConfirmVisible && (
                            <Confirm
                                type={MessageType.WARNING}
                                message={`${this.props.t('Allowance.bulk.countOfAffectedRules')}: ${
                                    this.state.bulk.overlappingCount
                                }`}
                                loading={this.state.bulk.overlappingCountLoading || this.state.bulk.loading}
                                onConfirm={this._onBulkCreateConfirm}
                                onCancel={this._onBulkCreateCancel}
                            />
                        )}
                        <Modal
                            visible={this.state.manualRatesForm.isOpen}
                            title={this.props.t('Allowance.manualRateForm.editDailyRate')}
                            closable={true}
                            maskClosable={false}
                            width={430}
                            footer={null}
                            destroyOnClose
                            className="manual-rates-form-modal"
                            onCancel={this._onManualRateFormCancel}
                            withoutPadding={false}
                        >
                            <ManualRatesForm
                                initialValues={this.state.manualRatesForm.initialValues}
                                current={this.state.manualRatesForm.current}
                                diemsRates={this.state.diemsRates}
                                diemsRatesLoading={this.state.diemsRatesLoading}
                                demoMode={this.props.logic.demo().isActive}
                                onSubmit={this._onManualRateFormSubmit}
                                onCancel={this._onManualRateFormCancel}
                            />
                        </Modal>
                        <HelperModal
                            name="allowances"
                            content={this.state.helper?.content ?? ''}
                            onClose={this._onHelperClose}
                            visible={!!this.state.helper}
                        />
                    </>
                }
                extra={[
                    <LayoutSidePanel
                        body={
                            <AllowanceDetailActions
                                dateFrom={this.state.date.start}
                                dateTo={this.state.date.end}
                                userId={this._userId}
                                demoMode={this.props.logic.demo().isActive}
                                appliedRuleForSelectedRow={this.state.appliedRuleForSelectedRow}
                                onSubmitRuleForm={this._onBulkCreateClick}
                                onCancelRuleEdit={() => {
                                    this.setState(state => ({
                                        appliedRuleForSelectedRow: undefined,
                                        diems: {
                                            ...state.diems,
                                            selectedDiemsId: undefined
                                        }
                                    }));
                                }}
                                places={this.state.userPlaceOfWork}
                            />
                        }
                        footer={
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                    <Button
                                        block
                                        size="large"
                                        type="primary"
                                        qa={qa.allowances.detail.btnDriverPlaceOfWork}
                                    >
                                        <Link
                                            to={`${RouteNames.SETTINGS_CUSTOM_PLACES}?allowances-driverId=${this._userId}&allowances-start=${this.state.date.start}&allowances-end=${this.state.date.end}`}
                                        >
                                            {this.props.t('Allowance.actions.placeOfWork')}
                                        </Link>
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        block
                                        size="large"
                                        type="primary"
                                        onClick={this._onAddPlaceOfWorkCrossingClick}
                                        disabled={this.props.logic.demo().isActive}
                                        qa={qa.allowances.detail.btnAddPlaceCrossing}
                                    >
                                        {this.props.t('Allowance.actions.addPlaceOfWorkCrossing')}
                                    </Button>
                                </Col>
                            </Row>
                        }
                    />
                ]}
            />
        );
    }

    private _onBackButtonClick = () => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_ALLOWANCES_LIST,
            search: `?start=${this.state.date.start}&end=${this.state.date.end}`
        });
    };

    private _loadTableData(hardReload: boolean) {
        this.props.logic
            .diems()
            .getDiems(
                moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate(),
                this._userId,
                hardReload
            );
    }

    private _loadBorderCrossings() {
        this.props.logic
            .borderCrossing()
            .getManualCrossings(
                this._userId,
                moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate()
            );
    }

    private _loadPlaceOfWorkCrossings() {
        this.props.logic
            .poi()
            .getPlaceOfWorkCrossing(
                [this._userId],
                moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate()
            );
    }

    private _onPlaceOfWorkCrossingSubmit = (data: PlaceOfWorkCrossingFormModel) => {
        this.setState(
            {
                placeOfWorkCrossingEditProcessing: true
            },
            () => {
                if (data.type)
                    this.props.logic
                        .poi()
                        .addManualPlaceOfWorkCrossing(
                            this._userId,
                            data.placeOfWorkId ?? '',
                            moment(data.date).toDate(),
                            data.type
                        )
                        .then(() => {
                            message.success(this.props.t('Allowance.message.createPlaceOfWorkCrossingSuccess'));
                            this._loadTableData(true);
                            this._loadPlaceOfWorkCrossings();
                            this.setState({
                                placeOfWorkCrossingEditVisible: false
                            });
                        })
                        .catch(() => {
                            message.error(this.props.t('Allowance.message.createPlaceOfWorkCrossingError'));
                        })
                        .finally(() => {
                            this.setState({
                                placeOfWorkCrossingEditProcessing: false
                            });
                        });
            }
        );
    };

    private _onBorderCrossingSubmit = (data: BorderCrossingFormModel) => {
        this.setState(
            {
                borderCrossingEditProcessing: true
            },
            () => {
                this.props.logic
                    .borderCrossing()
                    .createManualCrossing({
                        driverId: this._userId,
                        countryEntry: data.countryEnter ?? '',
                        countryExit: data.countryExit ?? '',
                        dateFrom: moment(data.date).toDate(),
                        dateTo: moment(data.date).add(1, 'second').toDate()
                    })
                    .then(() => {
                        message.success(this.props.t('Allowance.message.createBorderCrossingSuccess'));
                        this._loadTableData(true);
                        this._loadBorderCrossings();
                        this.setState({
                            borderCrossingEditVisible: false
                        });
                    })
                    .catch(() => {
                        message.error(this.props.t('Allowance.message.createBorderCrossingError'));
                    })
                    .finally(() => {
                        this.setState({
                            borderCrossingEditProcessing: false
                        });
                    });
            }
        );
    };

    private _onBulkGlobalEdit = () => {
        this.props.logic.diems().rules().getDiemsRules(this._userId);
        this.setState(
            state => ({
                bulk: {
                    ...state.bulk,
                    formData: {
                        start: this.state.date.start,
                        end: this.state.date.end,
                        replacedMeals: [0],
                        pocketMoney: 0,
                        users: []
                    }
                }
            }),
            () => {
                this.setState(state => ({
                    bulk: {
                        ...state.bulk,
                        bulkEditVisible: true
                    }
                }));
            }
        );
    };

    private _onManualRateEditClick = async (id: string) => {
        const data = this.state.diems.tableData?.find(d => d.id === id);

        if (!data) {
            console.error('can not find current data to edit');
        } else {
            const diemsRates = await this.props.logic.api().diemsRateApi.diemsRatesList({
                onDay: moment(data.dateFrom).format(DATE_FORMAT_SHORT_BE),
                countryIso2: this.props.logic.auth().newEWClient()?.country?.iso2,
                foreignCountryIso2: this.state.diems.tableData
                    ?.find(d => d.id === id)
                    ?.appliedDiemsCountries?.toString()
            });

            this.setState(state => ({
                ...state,
                diemsRates,
                diemsRatesLoading: false,
                manualRatesForm: {
                    isOpen: true,
                    current: {
                        domestic: data.manualRate?.domestic,
                        foreign: data.manualRate?.foreign
                    },
                    initialValues: {
                        id: data.manualRate?.id,
                        date: id,
                        dayFrom: moment(data.dateFrom).toISOString(),
                        dayTo: moment(data.dateFrom).endOf('day').toISOString(),
                        user: this._userId
                    }
                }
            }));
        }
    };

    private _onManualRateFormSubmit = async (data: ManualRatesFormModel) => {
        this.setState({
            diemsRatesLoading: true
        });

        const domesticRate = this.state.diemsRates?.find(d => d.id === data.manualRateDomesticId);
        const foreignRate = this.state.diemsRates?.find(d => d.id === data.manualRateForeignId);

        const dayFrom = new Date(data?.date);
        const dayTo = new Date(data?.date);
        dayTo.setDate(dayFrom.getDate() + 1);

        this.props.logic
            .diems()
            .rules()
            .setManualRate(
                dayFrom,
                dayTo,
                data.user,
                {
                    domestic: domesticRate
                        ? {
                              country_iso2: domesticRate.country?.iso2 ?? '',
                              rate: domesticRate.rate,
                              currency: domesticRate.currency?.code ?? '',
                              foreign_country_iso2: domesticRate.foreignCountry?.iso2 ?? '',
                              interval_from: domesticRate.intervalFrom,
                              interval_to: domesticRate.intervalTo
                          }
                        : null,
                    foreign: foreignRate
                        ? {
                              country_iso2: foreignRate.country?.iso2 ?? '',
                              rate: foreignRate.rate,
                              currency: foreignRate.currency?.code ?? '',
                              foreign_country_iso2: foreignRate.foreignCountry?.iso2 ?? '',
                              interval_from: foreignRate.intervalFrom,
                              interval_to: foreignRate.intervalTo
                          }
                        : null
                },
                data.id
            )
            .then(() => {
                this.setState({
                    manualRatesForm: {
                        isOpen: false,
                        initialValues: undefined
                    }
                });
                message.success(this.props.t('Allowance.manualRateForm.message.updateSuccess'));
            })
            .catch(err => {
                this.setState({
                    diemsRatesLoading: false
                });
                console.error(err);
                message.error(this.props.t('Allowance.manualRateForm.message.updateError'));
            })
            .finally(() => {
                this.setState({
                    manualRatesForm: {
                        isOpen: false
                    }
                });
                this._loadTableData(true);
            });
    };

    private _onManualRateFormCancel = () => {
        this.setState({
            manualRatesForm: {
                isOpen: false
            }
        });
    };

    private _onBulkDetailEdit = async (id: string) => {
        this.setState(state => ({
            diems: {
                ...state.diems,
                selectedDiemsId: id
            },
            appliedRuleForSelectedRow: {
                loading: true
            }
        }));
        const data = this.state.diems.tableData?.find(d => d.id === id);
        if (!data) {
            console.error('can not find current data to edit');
        } else {
            const appliedRule = await this.props.logic
                .diems()
                .rules()
                .getAppliedDiemsRule(this._userId, data.originalDateFrom, data.originalDateTo);
            this.setState({
                appliedRuleForSelectedRow: {
                    loading: false,
                    rule: appliedRule
                }
            });
            this.setState(state => ({
                bulk: {
                    ...state.bulk,
                    formData: appliedRule && {
                        start: moment(appliedRule.start).format(DATE_FORMAT),
                        end: moment(appliedRule.end).format(DATE_FORMAT),
                        replacedMeals: appliedRule.replacedMeals ?? [],
                        pocketMoney:
                            parseInt(
                                Object.keys(pocketMoneyMapper).find(
                                    key => pocketMoneyMapper[key] === appliedRule.pocketMoney
                                ) ?? pocketMoneyMapper[0].toString()
                            ) ?? pocketMoneyMapper[0],
                        users: [this._userId.toString()]
                    }
                }
            }));
        }
        this.setState(state => ({
            appliedRuleForSelectedRow: {
                ...state.appliedRuleForSelectedRow,
                loading: false
            }
        }));
    };

    private _onBulkCreateClick = (data: DiemsBulkFormModel) => {
        this.setState(state => ({
            appliedRuleForSelectedRow: undefined,
            diems: {
                ...state.diems,
                selectedDiemsId: undefined
            },
            bulk: {
                ...state.bulk,
                bulkEditVisible: false,
                bulkConfirmVisible: true,
                formData: {
                    ...data,
                    users: [this._userId.toString()]
                }
            }
        }));

        this.props.logic
            .diems()
            .rules()
            .getOverlappingDiemsRules(
                moment.utc(data.start, DATE_FORMAT),
                moment.utc(data.end, DATE_FORMAT).add(1, 'days'),
                [this._userId]
            );
    };

    private _onBulkEditClick = (data: DiemsBulkFormModel, userId?: number | null) => {
        this.setState(state => ({
            bulk: {
                ...state.bulk,
                bulkEditVisible: false,
                bulkConfirmVisible: true,
                formData: {
                    ...data,
                    users: userId ? [userId.toString()] : []
                }
            }
        }));

        this.props.logic
            .diems()
            .rules()
            .getOverlappingDiemsRules(
                moment.utc(data.start, DATE_FORMAT),
                moment.utc(data.end, DATE_FORMAT).add(1, 'days')
            );
    };

    private _onBulkCreateCancel = () => {
        this.setState({
            bulk: {
                bulkConfirmVisible: false,
                bulkEditVisible: false,
                overlappingCount: 0,
                formData: undefined,
                loading: false
            }
        });
    };

    private _onBulkCreateConfirm = () => {
        this.setState(state => ({
            bulk: {
                ...state.bulk,
                loading: true
            }
        }));

        const formData = this.state.bulk.formData;
        if (formData) {
            this.props.logic
                .diems()
                .rules()
                .createDiemsRules(
                    moment.utc(formData.start, DATE_FORMAT).toDate(),
                    moment.utc(formData.end, DATE_FORMAT).add(1, 'days').toDate(),
                    formData.users.map(u => parseInt(u)),
                    formData.replacedMeals ?? [],
                    parseInt(pocketMoneyMapper[formData.pocketMoney ?? 0]),
                    formData.calculationType === CalculationTypeOptions.lastForeign,
                    formData.fixedRate?.domesticRate,
                    formData.fixedRate?.foreignRate
                )
                .then(() => {
                    message.success(this.props.t('Allowance.bulk.message.createSuccess'));
                })
                .catch(err => {
                    console.error(err);
                    message.error(this.props.t('Allowance.bulk.message.createError'));
                })
                .finally(() => {
                    this._loadTableData(true);
                    this.setState({
                        bulk: {
                            bulkConfirmVisible: false,
                            bulkEditVisible: false,
                            overlappingCount: 0,
                            formData: undefined,
                            loading: false
                        }
                    });
                });
        } else {
            this.setState({
                bulk: {
                    bulkConfirmVisible: false,
                    bulkEditVisible: false,
                    overlappingCount: 0,
                    formData: undefined,
                    loading: false
                }
            });
        }
    };

    private _onCalendarChange = (values: [moment.Moment | null, moment.Moment | null] | null) => {
        if (values) {
            const [start, end] = values;
            if (!start || !end) {
                console.error('missing start or end date');
            } else {
                this.props.history.push({
                    search: qs.stringify({
                        start: start.format(DATE_FORMAT),
                        end: end.format(DATE_FORMAT)
                    } as Query)
                });
                this.setState(
                    state => ({
                        date: {
                            start: start.format(DATE_FORMAT),
                            end: end.format(DATE_FORMAT)
                        },
                        diems: {
                            ...state.diems,
                            loading: true
                        }
                    }),
                    () => {
                        this._loadTableData(true);
                        this._loadBorderCrossings();
                        this._loadPlaceOfWorkCrossings();
                    }
                );
            }
        }
    };

    private _onAddPlaceOfWorkCrossingClick = () => {
        this.setState({
            placeOfWorkCrossingEditVisible: true
        });
    };

    private _onAddBorderCrossingClick = () => {
        this.setState({
            borderCrossingEditVisible: true
        });
    };

    private _onExportClick = async (): Promise<void> => {
        const { t } = this.props;
        const diems = this.state.diems.tableData;
        this.setState({ exportLoading: true });
        if (diems && diems.length > 0) {
            const workbook: Workbook = await fromBlankAsync();
            const driverName = this.state.userData
                ? `${this.state.userData.name ? `${this.state.userData.name.slice(0, 1)}. ` : ''} ${
                      this.state.userData.surname
                  }`
                : t('common.unknown');
            const client = this.props.logic.auth().client();
            const clientNew = this.props.logic.auth().newEWClient();

            const sheetName = normalizeExportSheetName(driverName);
            workbook.sheet(0).name(sheetName);

            const head: string[][] = [
                [
                    t('AllowanceExport.title'),
                    '',
                    `${t('AllowanceExport.period')}: ${moment(this.state.date.start, DATE_FORMAT)
                        .local()
                        .format('L')} - ${moment(this.state.date.end, DATE_FORMAT).local().format('L')}`,
                    '',
                    '',
                    ''
                ],
                [],
                [t('common.company'), '', client?.name ?? ''],
                [
                    t('common.address'),
                    '',
                    toAddress(
                        this.props.logic.auth().user().lang,
                        client as EWClient,
                        [
                            {
                                town: (clientNew?.address as any)?.city,
                                country: (clientNew?.address as any)?.country,
                                streetAddress: (clientNew?.address as any)?.street,
                                postalCode: (clientNew?.address as any)?.postcode,
                                countryCode: clientNew?.country?.iso2
                            }
                        ] as AddressStructured[],
                        this.props.logic.settings().getProp('addressIdentification'),
                        t('common.unknown')
                    )
                ],
                [t('common.driver'), '', driverName],
                [],
                [
                    `${t('Allowance.summary.stayLocalTime')} (${t('common.days')[0].toLowerCase()}:${t(
                        'common.hours'
                    )[0].toLowerCase()}:${t('common.minutes')[0].toLowerCase()})`,
                    t('Allowance.summary.countries'),
                    `${t('Allowance.summary.stayAbroadTime')} (${t('common.days')[0].toLowerCase()}:${t(
                        'common.hours'
                    )[0].toLowerCase()}:${t('common.minutes')[0].toLowerCase()})`,
                    t('Allowance.summary.pocketMoney'),
                    t('Allowance.summary.allowance'),
                    t('Allowance.summary.allowanceTotal')
                ],
                [
                    formatDuration((this.state.diems.summaryData?.localStay ?? 0) * 1000),
                    this.state.diems.summaryData?.countries ?? '',
                    formatDuration((this.state.diems.summaryData?.abroadStay ?? 0) * 1000),
                    this.state.diems.summaryData?.pocketMoney
                        ?.map(pocketMoney => `${pocketMoney.currency} ${pocketMoney.value}`)
                        .toString() ?? '',
                    this.state.diems.summaryData?.dailyRates
                        ?.map(dailyRate => `${dailyRate.currency} ${dailyRate.value}`)
                        .toString() ?? '',
                    this.state.diems.summaryData?.allowanceSummary
                        ? `${this.state.diems.summaryData?.allowanceSummary.currency} ${this.state.diems.summaryData?.allowanceSummary.value}`
                        : ''
                ]
            ];

            let data: any[] = [];
            let manuallyChanges = false;

            diems.forEach(row => {
                data.push([
                    moment(row.id).format('L'),
                    moment(row.dateFrom).format('L'),
                    moment(row.dateTo).format('L'),
                    row.domesticTravel,
                    row.abroadTravel,
                    row.country,
                    row.dailyRate,
                    row.pocketMoney,
                    row.shortening ? numeral(row.shortening).format('0,0.00') : '',
                    row.totalRate,
                    row.manualRate?.domestic || row.manualRate?.foreign
                        ? `${row.manualRate.byUserName} ${row.manualRate.byUserSurname}`
                        : '',
                    row.manualRate?.domestic || row.manualRate?.foreign
                        ? moment(row.manualRate.byUserAt).format('L LT')
                        : ''
                ]);
                if (row.manualRate?.domestic || row.manualRate?.foreign) {
                    manuallyChanges = true;
                }

                if (row.children) {
                    row.children.forEach(child => {
                        data.push([
                            '',
                            moment(child.dateFrom).format('L LT'),
                            moment(child.dateTo).format('L LT'),
                            child.domesticTravel,
                            child.abroadTravel,
                            child.country,
                            child.dailyRate,
                            child.pocketMoney,
                            child.shortening ? numeral(row.shortening).format('0,0.00') : '',
                            child.totalRate,
                            child.manualRate?.domestic || child.manualRate?.foreign
                                ? `${child.manualRate.byUserName} ${child.manualRate.byUserSurname}`
                                : '',
                            child.manualRate?.domestic || child.manualRate?.foreign
                                ? moment(child.manualRate.byUserAt).format('L LT')
                                : ''
                        ]);
                        if (child.manualRate?.domestic || child.manualRate?.foreign) {
                            manuallyChanges = true;
                        }
                    });
                }
            });

            let body = [];
            if (manuallyChanges) {
                body.push(['', '', '', '', '', '', '', '', '', t('Allowance.detailTable.totalRate')]);
            }

            body.push([
                t('common.day'),
                t('Allowance.detailTable.dateFrom'),
                t('Allowance.detailTable.dateTo'),
                t('Allowance.detailTable.stayLocalTime'),
                t('Allowance.detailTable.stayAbroadTime'),
                t('Allowance.detailTable.country'),
                t('Allowance.detailTable.rate'),
                t('Allowance.detailTable.supply'),
                t('Allowance.detailTable.shortening'),
                manuallyChanges ? t('Allowance.detailTable.rate') : t('Allowance.detailTable.totalRate'),
                manuallyChanges ? t('Allowance.detailTable.manuallyChangedByUser') : '',
                manuallyChanges ? t('Allowance.detailTable.manuallyChangedDate') : ''
            ]);

            body = [...body, ...data];

            // data push + styles
            // ------------------
            let lastRow = 0;

            workbook.sheet(sheetName).range(1, 3, 1, 6).merged(true);

            lastRow = workbook
                .sheet(sheetName)
                .range(1, 1, head.length, 6)
                .value(head)
                .style({ fill: { color: 'FFFFFF', type: 'solid' } } as Style)
                .forEach((cell: Cell, ri: number) => {
                    if (ri === 0) cell.style({ bold: true, fontSize: 14 } as Style);
                    if ([2, 3, 4].includes(ri)) {
                        cell.style({ bold: true, fill: { color: 'EFEFEF' } });
                    }
                    if ([6, 7].includes(ri)) {
                        cell.style({
                            bold: true,
                            fill: { color: 'EFEFEF' },
                            topBorderStyle: 'medium',
                            bottomBorderStyle: 'medium',
                            rightBorderStyle: 'medium',
                            wrapText: true
                        } as Style);
                    }
                })
                .endCell()
                .rowNumber();

            lastRow = workbook
                .sheet(sheetName)
                .range(lastRow + 2, 1, lastRow + 1 + body.length, manuallyChanges ? 12 : 10)
                .value(body)
                .style('fill', 'FFFFFF')
                .forEach((cell: Cell, ri: number) => {
                    if (ri === 0 || (manuallyChanges && ri === 1)) {
                        cell.style({
                            bold: true,
                            fill: { color: 'D0D0D0' },
                            rightBorderStyle: 'medium',
                            topBorderStyle: 'medium',
                            bottomBorderStyle: 'medium',
                            wrapText: true
                        } as Style);
                    }

                    if (ri > 1 || (!manuallyChanges && ri === 1)) {
                        cell.style({ border: 'medium', fill: { color: 'EFEFEF', type: 'solid' } } as Style);
                    }
                })
                .endCell()
                .rowNumber();

            if (manuallyChanges) {
                workbook
                    .sheet(sheetName)
                    .range(head.length + 2, 1, head.length + 2, 9)
                    .merged(true);
                workbook
                    .sheet(sheetName)
                    .range(head.length + 2, 10, head.length + 2, 12)
                    .merged(true)
                    .style('horizontalAlignment', 'center');
            }

            // column width
            // https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
            [20, 20, 20, 20, 20, 25, 15, 15, 15, 15, 20, 20].forEach((width, i) => {
                // 65 => 'A'...'t'
                const column = String.fromCharCode(65 + i);
                workbook.sheet(sheetName).column(column).width(width);
            });

            // signature
            const startColumn = 7;
            workbook
                .sheet(sheetName)
                .range(lastRow + 2, startColumn + 1, lastRow + 5, startColumn + (manuallyChanges ? 5 : 3))
                .merged(true)
                .style({
                    bold: true,
                    topBorderStyle: 'medium',
                    rightBorderStyle: 'medium',
                    bottomBorderStyle: 'medium'
                } as Style);
            workbook
                .sheet(sheetName)
                .range(lastRow + 2, startColumn, lastRow + 5, startColumn)
                .forEach((cell: Cell, ri: number) => {
                    if (ri === 0) {
                        cell.style({
                            bold: true,
                            borderStyle: 'medium',
                            bottomBorderColor: 'FFFFFF',
                            rightBorderColor: 'FFFFFF'
                        } as Style);
                    } else if (ri === 3) {
                        cell.style({
                            bold: true,
                            borderStyle: 'medium',
                            topBorderColor: 'FFFFFF',
                            rightBorderColor: 'FFFFFF'
                        } as Style);
                    } else if (ri > 0) {
                        cell.style({
                            bold: true,
                            borderStyle: 'medium',
                            topBorderColor: 'FFFFFF',
                            bottomBorderColor: 'FFFFFF',
                            rightBorderColor: 'FFFFFF'
                        } as Style);
                    }
                });

            workbook
                .sheet(sheetName)
                .range(lastRow + 4, startColumn, lastRow + 4, startColumn)
                .style({ fill: { color: 'EFEFEF' } } as Style)
                .value(t('AllowanceExport.signature'));

            // create and download file
            const blob = (await workbook.outputAsync()) as Blob;
            downloadFile(
                blob,
                `Allowance driver_${driverName} ${moment(this.state.date.start, DATE_FORMAT)
                    .local()
                    .format('L')}-${moment(this.state.date.end, DATE_FORMAT).local().format('L')}.xlsx`
            );
            this.setState({ exportLoading: false });
        }
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'diets(travelallowances)';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withLogicContext(withRouter(withTranslation()(AllowanceDriverDetailModule)));
