import i18n from 'i18next';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { SelectOption } from 'common/components/Form/Select';

export const pocketMoneyMapper = {
    0: 0,
    1: 10,
    2: 20,
    3: 30,
    4: 40
};

export const pocketMoneyOptions = {
    0: '0%',
    1: '10%',
    2: '20%',
    3: '30%',
    4: '40%'
};

export enum CalculationTypeOptions {
    default = 1,
    lastForeign = 2,
    fixedRates = 3
}

export function replacementMeals(): CheckboxOptionType[] {
    return [
        {
            label: i18n.t('Allowance.bulk.breakfast'),
            value: 1
        },
        {
            label: i18n.t('Allowance.bulk.lunch'),
            value: 2
        },
        {
            label: i18n.t('Allowance.bulk.dinner'),
            value: 3
        }
    ];
}

export function calculationType(): SelectOption[] {
    return [
        {
            label: i18n.t('Allowance.bulk.calculationTypes.default'),
            value: 1
        },
        {
            label: i18n.t('Allowance.bulk.calculationTypes.lastForeign'),
            value: 2
        },
        {
            label: i18n.t('Allowance.bulk.calculationTypes.fixedRates'),
            value: 3
        }
    ];
}
