import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import qaData from 'qa-selectors';
import * as icons from 'resources/images/common';

interface Props {
    text?: string;
    qa?: string;
}

export function NoData({ text, qa }: Props) {
    const { t } = useTranslation();

    return (
        <div className="no-data">
            <Row align="middle" justify="center" gutter={4} data-qa={qa ?? qaData.common.noData}>
                <Col>
                    <img src={icons.notDisturb} alt="not-disturb" />
                </Col>
                <Col>{text ?? t('common.noData')}</Col>
            </Row>
        </div>
    );
}
