/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoredObjectSharing
 */
export interface WriteOnlyMonitoredObjectSharing {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectSharing
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectSharing
     */
    monitoredObject: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoredObjectSharing
     */
    receiver: number;
}

export function WriteOnlyMonitoredObjectSharingFromJSON(json: any): WriteOnlyMonitoredObjectSharing {
    return WriteOnlyMonitoredObjectSharingFromJSONTyped(json, false);
}

export function WriteOnlyMonitoredObjectSharingFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoredObjectSharing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'monitoredObject': json['monitored_object'],
        'receiver': json['receiver'],
    };
}

export function WriteOnlyMonitoredObjectSharingToJSON(value?: WriteOnlyMonitoredObjectSharing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'monitored_object': value.monitoredObject,
        'receiver': value.receiver,
    };
}


