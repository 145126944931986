import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuperAdminCompanyModel } from '../SuperAdminModule';
import { Loading } from 'common/components/Loading';
import { CountrySelectModel } from 'logic/partner/partner';
import cn from 'classnames';

interface Props extends WithTranslation {
    loading: boolean;
    data: SuperAdminCompanyModel[];
    selected?: SuperAdminCompanyModel;
    countryCounter?: CountrySelectModel[];

    onRowClick?: (id: string) => void;
}

const head = ['companyName', 'address', 'country'];

function SuperAdminTable(props: Props) {
    function onRowClick(id: string) {
        props.onRowClick?.(id);
    }

    const t = (key: string) => (key ? props.i18n.t(`SuperAdminTable.${key}`) : '');

    return (
        <div className="superadmin-table t-default">
            {props.loading ? (
                <Loading />
            ) : (
                <table className="t-table-all t-default t-striped t-hoverable t-small">
                    <thead>
                        <tr>
                            {head.map((colName, i) => (
                                <th key={i}>{t(colName)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(row => (
                            <tr
                                key={row.id}
                                onClick={onRowClick.bind(undefined, row.id)}
                                className={cn({ selected: row.id === props.selected?.id })}
                            >
                                <td>{row.companyName}</td>
                                <td>{row.address}</td>
                                <td>
                                    {props.countryCounter &&
                                        props.countryCounter.length > 0 &&
                                        row.country &&
                                        props.countryCounter.filter(c => c.id === row.country)?.[0]?.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default withTranslation()(SuperAdminTable);
