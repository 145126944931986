/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LibModelsActivityIntervalExternalDevice,
    LibModelsActivityIntervalExternalDeviceFromJSON,
    LibModelsActivityIntervalExternalDeviceFromJSONTyped,
    LibModelsActivityIntervalExternalDeviceToJSON,
    LibModelsDriverInfoDriver,
    LibModelsDriverInfoDriverFromJSON,
    LibModelsDriverInfoDriverFromJSONTyped,
    LibModelsDriverInfoDriverToJSON,
    LibModelsFuelTankLevel,
    LibModelsFuelTankLevelFromJSON,
    LibModelsFuelTankLevelFromJSONTyped,
    LibModelsFuelTankLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface LibModelsActivityIntervalLive
 */
export interface LibModelsActivityIntervalLive {
    /**
     * 
     * @type {LibModelsDriverInfoDriver}
     * @memberof LibModelsActivityIntervalLive
     */
    driver?: LibModelsDriverInfoDriver;
    /**
     * 
     * @type {Array<LibModelsDriverInfoDriver>}
     * @memberof LibModelsActivityIntervalLive
     */
    passengers?: Array<LibModelsDriverInfoDriver>;
    /**
     * 
     * @type {LibModelsDriverInfoDriver}
     * @memberof LibModelsActivityIntervalLive
     */
    defaultDriver?: LibModelsDriverInfoDriver;
    /**
     * 
     * @type {Array<LibModelsActivityIntervalExternalDevice>}
     * @memberof LibModelsActivityIntervalLive
     */
    externalDevices?: Array<LibModelsActivityIntervalExternalDevice>;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityIntervalLive
     */
    lastUpdate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityIntervalLive
     */
    externalDevicesLastTs?: Date;
    /**
     * 
     * @type {number}
     * @memberof LibModelsActivityIntervalLive
     */
    lastCarBatteryVolts?: number;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityIntervalLive
     */
    lastCarBatteryTimestamp?: Date;
    /**
     * 
     * @type {{ [key: string]: LibModelsFuelTankLevel; }}
     * @memberof LibModelsActivityIntervalLive
     */
    lastFuelLevelByTank?: { [key: string]: LibModelsFuelTankLevel; };
}

export function LibModelsActivityIntervalLiveFromJSON(json: any): LibModelsActivityIntervalLive {
    return LibModelsActivityIntervalLiveFromJSONTyped(json, false);
}

export function LibModelsActivityIntervalLiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): LibModelsActivityIntervalLive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': !exists(json, 'driver') ? undefined : LibModelsDriverInfoDriverFromJSON(json['driver']),
        'passengers': !exists(json, 'passengers') ? undefined : ((json['passengers'] as Array<any>).map(LibModelsDriverInfoDriverFromJSON)),
        'defaultDriver': !exists(json, 'default_driver') ? undefined : LibModelsDriverInfoDriverFromJSON(json['default_driver']),
        'externalDevices': !exists(json, 'external_devices') ? undefined : ((json['external_devices'] as Array<any>).map(LibModelsActivityIntervalExternalDeviceFromJSON)),
        'lastUpdate': !exists(json, 'last_update') ? undefined : (new Date(json['last_update'])),
        'externalDevicesLastTs': !exists(json, 'external_devices_last_ts') ? undefined : (new Date(json['external_devices_last_ts'])),
        'lastCarBatteryVolts': !exists(json, 'last_car_battery_volts') ? undefined : json['last_car_battery_volts'],
        'lastCarBatteryTimestamp': !exists(json, 'last_car_battery_timestamp') ? undefined : (new Date(json['last_car_battery_timestamp'])),
        'lastFuelLevelByTank': !exists(json, 'last_fuel_level_by_tank') ? undefined : (mapValues(json['last_fuel_level_by_tank'], LibModelsFuelTankLevelFromJSON)),
    };
}

export function LibModelsActivityIntervalLiveToJSON(value?: LibModelsActivityIntervalLive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': LibModelsDriverInfoDriverToJSON(value.driver),
        'passengers': value.passengers === undefined ? undefined : ((value.passengers as Array<any>).map(LibModelsDriverInfoDriverToJSON)),
        'default_driver': LibModelsDriverInfoDriverToJSON(value.defaultDriver),
        'external_devices': value.externalDevices === undefined ? undefined : ((value.externalDevices as Array<any>).map(LibModelsActivityIntervalExternalDeviceToJSON)),
        'last_update': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString()),
        'external_devices_last_ts': value.externalDevicesLastTs === undefined ? undefined : (value.externalDevicesLastTs.toISOString()),
        'last_car_battery_volts': value.lastCarBatteryVolts,
        'last_car_battery_timestamp': value.lastCarBatteryTimestamp === undefined ? undefined : (value.lastCarBatteryTimestamp.toISOString()),
        'last_fuel_level_by_tank': value.lastFuelLevelByTank === undefined ? undefined : (mapValues(value.lastFuelLevelByTank, LibModelsFuelTankLevelToJSON)),
    };
}


