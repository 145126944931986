/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LatLngLiteral,
    LatLngLiteralFromJSON,
    LatLngLiteralFromJSONTyped,
    LatLngLiteralToJSON,
} from './';

/**
 * The bounds parameter defines the latitude/longitude coordinates of the southwest and northeast corners of this bounding box.
 * @export
 * @interface LatLngBounds
 */
export interface LatLngBounds {
    /**
     * 
     * @type {LatLngLiteral}
     * @memberof LatLngBounds
     */
    northeast: LatLngLiteral;
    /**
     * 
     * @type {LatLngLiteral}
     * @memberof LatLngBounds
     */
    southwest: LatLngLiteral;
}

export function LatLngBoundsFromJSON(json: any): LatLngBounds {
    return LatLngBoundsFromJSONTyped(json, false);
}

export function LatLngBoundsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatLngBounds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'northeast': LatLngLiteralFromJSON(json['northeast']),
        'southwest': LatLngLiteralFromJSON(json['southwest']),
    };
}

export function LatLngBoundsToJSON(value?: LatLngBounds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'northeast': LatLngLiteralToJSON(value.northeast),
        'southwest': LatLngLiteralToJSON(value.southwest),
    };
}


