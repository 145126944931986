import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Partners from './components/Partners';
import { Logic } from 'logic/logic';
import { PairingItemType, CurrencySelectModel, CountrySelectModel } from 'logic/partner/partner';
import { PartnerCompanySelectModel, PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { SuperAdminUserModel } from 'modules/superadmin/SuperAdminModule';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { debounce } from 'debounce';
import { searched } from 'common/utils/search';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { message } from 'antd';
import { PairingItem, PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    edit: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
    };
    table: {
        loading: boolean;
        data?: PaginatedResponse<PartnerCompanyModel[]>;
        selected?: PartnerCompanyModel;
        currencyCounter?: CurrencySelectModel[];
        countryCounter?: CountrySelectModel[];
    };
    pairing?: PartnerPairingModel;
    createLoading: boolean;
    createError?: string;
}

class PartnerPartnersModule extends Component<Props, State> {
    private _logic: Logic;
    items?: PairingItem[];

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            edit: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            },
            createLoading: false
        };
    }

    componentDidMount() {
        this._logic
            .partner()
            .companies()
            .getClientList()
            .then(resp => {
                const p = resp.data.map(d => ({ id: d.id, label: d.name, isPartner: true }));
                this.setState(state => ({
                    bar: {
                        ...state.bar,
                        companies: p
                    }
                }));
            });
        this._getPartners();
        this._getCurrencyCounter();
        this._getCountryCounter();
    }

    render() {
        return (
            <Partners
                edit={this.state.edit}
                loading={this.state.table.loading}
                data={this.state.table.data}
                selected={this.state.table.selected}
                pairing={this.state.pairing}
                companies={this.state.bar.companies}
                currencyCounter={this.state.table.currencyCounter}
                countryCounter={this.state.table.countryCounter}
                selectedCompany={this.state.bar.selectedCompany}
                createLoading={this.state.createLoading}
                createError={this.state.createError}
                onCheckUserEmailExist={this._onCheckUserEmailExist}
                onTableRowSelect={this._onTableRowSelect}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onDetailActionsUnpair={this._onDetailActionsUnpair}
                onPairingKeySelect={this._onPairingKeySelect}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearch={this._onPairingSearch}
                onPairingConfirm={this._onPairingConfirm}
                onPairingCancel={this._onPairingCancel}
                onActionsImpersonate={this._onActionsImpersonate}
                onActionsPair={this._onActionsPair}
                onPaginationChange={this._onPaginationChange}
            />
        );
    }

    private _onPaginationChange = (pagination: PaginationParams) => {
        this._getPartners(pagination.limit, pagination.offset);
    };

    private _onPairingCancel = (): void => {
        this.items = undefined;
        this.setState({ pairing: undefined });
    };

    private _onPairingConfirm = (): void => {
        const selected = this.state.table?.selected;
        const key = this.state.pairing?.keySelected;
        const paired = this.state.pairing?.items!.find(c => c.id === this.state.pairing!.itemIdSelected);

        if (selected && key && paired) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.companies
            };
            const linked: PairingItemType = {
                id: paired?.id,
                key
            };

            this._logic
                .partner()
                .pair(source, linked)
                .then(_res => {
                    this._updateDetailData();
                    this.setState(state => ({ pairing: undefined, table: { ...state.table, loading: false } }));
                })
                .catch(err => {
                    message.error(this.props.t('common.error.loadDataError'));
                    console.error(`Pairing error, err: ${err}`);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                });
        }
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                loading: true
            }
        }));
        switch (this.state.pairing?.keySelected) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(partners => {
                        this.items = partners.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                const filtered = this.items?.filter(c => searched(text, c.value));
                const itemIdSelected = filtered?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                this.setState(state => ({
                    pairing: {
                        ...state.pairing!,
                        search: text ? text : undefined,
                        itemIdSelected,
                        items: filtered ?? undefined,
                        loading: false
                    }
                }));
            }
        }
    }, 500);

    private _onPairingItemSelect = (itemId: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                itemIdSelected: itemId
            }
        }));
    };

    private _onPairingKeySelect = (key: PartnerPairingKey): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                keySelected: key,
                items: undefined,
                loading: true
            }
        }));
        switch (key) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(companies => {
                        this.items = companies.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                break;
            }
        }
    };

    private _onActionsImpersonate = async (): Promise<void> => {
        const selected = this.state.table?.selected;
        if (selected?.contactPerson?.[0]?.ssoId) {
            const user = await this._logic.partner().user().getUser(selected?.contactPerson?.[0]?.ssoId);
            this._logic.superadminLogic().impersonate(user as SuperAdminUserModel);
        }
    };

    private _onActionsPair = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                keys: [PartnerPairingKey.companies]
            }
        }));
    };

    private _onActionsUpdate = (): void => {
        this.setState({
            edit: true
        });
    };

    private _onDetailActionsUnpair = (linked: PairingItemType): void => {
        const selected = this.state.table?.selected;
        if (selected) {
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.partners
            };
            this._logic
                .partner()
                .unpair(source, linked)
                .then(_res => {
                    this._updateDetailData();
                });
        }
    };

    private _getPartners = (limit?: number, offset?: number): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this._logic
            .partner()
            .partners()
            .getPartnerList(
                this.state.bar.selectedCompany?.id,
                limit ?? this.state.table.data?.limit,
                offset ?? this.state.table.data?.offset
            )
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false,
                        data,
                        create: undefined,
                        delete: undefined
                    }
                }));
                this._updateSelected();
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _getCurrencyCounter = (): void => {
        this._logic
            .partner()
            .getCurrencyList()
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        currencyCounter: data
                    }
                }));
            });
    };

    private _getCountryCounter = (): void => {
        this._logic
            .partner()
            .getCountryList()
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        countryCounter: data
                    }
                }));
            });
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                },
                table: {
                    ...state.table,
                    data: undefined,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getPartners();
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    ...state.table,
                    data: undefined,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getPartners();
            }
        );
    };

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onTableRowSelect = (id: string): void => {
        const selected =
            this.state.table?.selected?.id === id ? undefined : this.state.table?.data?.data.find(d => d.id === id);

        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                selected
            }
        }));

        // TODO: REFERER NAME
        // if (selected?.referrer) {
        //     this._logic
        //         .partner()
        //         .companies()
        //         .getCompany(selected?.referrer)
        //         .then(res => {
        //             this.setState(state => ({
        //                 edit: false,
        //                 table: {
        //                     ...state.table,
        //                     selected: selected
        //                         ? {
        //                               ...selected,
        //                               links: selected?.links
        //                                   ? selected?.links?.map(link =>
        //                                         link.key === PartnerPairingKey.partners
        //                                             ? {
        //                                                   ...link,
        //                                                   rows: link.rows?.map(row =>
        //                                                       row.value === String(selected.referrer)
        //                                                           ? { ...row, customName: res?.name }
        //                                                           : row
        //                                                   )
        //                                               }
        //                                             : link
        //                                     )
        //                                   : undefined
        //                           }
        //                         : undefined
        //                 }
        //             }));
        //         });
        // }
    };

    private _onCheckUserEmailExist = async (email: string): Promise<boolean> => {
        return await this._logic
            .partner()
            .contactPerson()
            .getContactPerson(email)
            .then(res => {
                if (res.length === 0) {
                    return false;
                } else {
                    return true;
                }
            });
    };

    private _updateSelected() {
        if (this.state.table.selected) {
            const partner = this.state.table?.data?.data.find(p => p.id === this.state.table.selected?.id);
            if (partner === undefined) {
                this.setState(state => ({
                    edit: false,
                    table: {
                        ...state.table,
                        selected: undefined
                    }
                }));
            }
        }
    }

    private _updateDetailData() {
        this._logic
            .partner()
            .partners()
            .getPartnerList(
                this.state.bar.selectedCompany?.id,
                this.state.table.data?.limit,
                this.state.table.data?.offset
            )
            .then(data => {
                const selected = this.state.table.selected;
                if (selected) {
                    const selectedRow = data.data.find(sr => sr.id === selected.id);
                    if (selectedRow) {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data: {
                                    ...state.table.data!,
                                    data: state.table.data!.data!.map(d => {
                                        if (d.id === selectedRow.id) {
                                            return selectedRow;
                                        } else {
                                            return d;
                                        }
                                    })
                                },
                                selected: selectedRow
                            }
                        }));
                    }
                }
            });
    }
}

export default withTranslation()(withRouter(PartnerPartnersModule));
