import { Component, createRef, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PlacesAutocompleteModule, {
    RouteSelect,
    RouteSelectData
} from 'modules/routing/places-autocomplete-new/PlacesAutocompleteModule';
import { Subject } from 'rxjs';
import { RefSelectProps } from 'antd/lib/select';

interface Props extends WithTranslation {
    loading?: boolean;
    autofocus?: boolean;
    placeHolder?: React.ReactNode;
    dropdownMatchSelectWidth?: number;
    dropdownAlign?: any;
    allowClear?: boolean;
    onAddRoute?: (value: RouteSelectData, element: RefObject<RefSelectProps>) => void;
    onEscape?: () => void;
    onBlur?: () => void;
}

interface State {
    selectedRoute?: RouteSelectData;
}

class PlannerAutocomplete extends Component<Props, State> {
    private _autocompleteRef = createRef<RefSelectProps>();
    private _searchValueSubject = new Subject<string>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="planner-autocomplete-new">
                <PlacesAutocompleteModule
                    size="large"
                    placeHolder={this.props.placeHolder}
                    className="planner-autocomplete-new-suggestions"
                    loading={this.props.loading}
                    autofocus={this.props.autofocus ?? true}
                    updateSearchValue={this._searchValueSubject}
                    dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth}
                    dropdownAlign={this.props.dropdownAlign}
                    innerref={this._autocompleteRef}
                    onSelect={this._onRouteSelect}
                    onEscape={this._onEscape}
                    onBlur={this.props.onBlur}
                    allowClear={this.props.allowClear}
                />
            </div>
        );
    }

    private _onEscape = () => {
        this.props.onEscape?.();
    };

    private _onRouteSelect: RouteSelect = data => {
        this.setState(
            {
                selectedRoute: data
            },
            () => {
                if (data) {
                    this.props.onAddRoute?.(data, this._autocompleteRef);
                    this.setState({
                        selectedRoute: undefined
                    });
                    this._searchValueSubject.next('');
                    this._autocompleteRef.current?.focus();
                }
            }
        );
    };
}

export default withTranslation()(PlannerAutocomplete);
