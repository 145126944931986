/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoredObjectToMonitoredObject
 */
export interface WriteOnlyMonitoredObjectToMonitoredObject {
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMonitoredObjectToMonitoredObject
     */
    validFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMonitoredObjectToMonitoredObject
     */
    validTo?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectToMonitoredObject
     */
    pairInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectToMonitoredObject
     */
    unpairInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoredObjectToMonitoredObject
     */
    source: WriteOnlyMonitoredObjectToMonitoredObjectSourceEnum;
}

export function WriteOnlyMonitoredObjectToMonitoredObjectFromJSON(json: any): WriteOnlyMonitoredObjectToMonitoredObject {
    return WriteOnlyMonitoredObjectToMonitoredObjectFromJSONTyped(json, false);
}

export function WriteOnlyMonitoredObjectToMonitoredObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoredObjectToMonitoredObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validFrom': (new Date(json['valid_from'])),
        'validTo': !exists(json, 'valid_to') ? undefined : (json['valid_to'] === null ? null : new Date(json['valid_to'])),
        'pairInfo': !exists(json, 'pair_info') ? undefined : json['pair_info'],
        'unpairInfo': !exists(json, 'unpair_info') ? undefined : json['unpair_info'],
        'source': json['source'],
    };
}

export function WriteOnlyMonitoredObjectToMonitoredObjectToJSON(value?: WriteOnlyMonitoredObjectToMonitoredObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid_from': (value.validFrom.toISOString()),
        'valid_to': value.validTo === undefined ? undefined : (value.validTo === null ? null : value.validTo.toISOString()),
        'pair_info': value.pairInfo,
        'unpair_info': value.unpairInfo,
        'source': value.source,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyMonitoredObjectToMonitoredObjectSourceEnum {
    TId = 't_id',
    Man = 'man'
}


