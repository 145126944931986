/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCOConstants
 */
export interface TCOConstants {
    /**
     * 
     * @type {string}
     * @memberof TCOConstants
     */
    tel: string;
    /**
     * 
     * @type {string}
     * @memberof TCOConstants
     */
    email: string;
}

export function TCOConstantsFromJSON(json: any): TCOConstants {
    return TCOConstantsFromJSONTyped(json, false);
}

export function TCOConstantsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOConstants {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tel': json['tel'],
        'email': json['email'],
    };
}

export function TCOConstantsToJSON(value?: TCOConstants | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tel': value.tel,
        'email': value.email,
    };
}


