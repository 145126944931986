import { chunkPieces, trips } from './trips';
import { FuelStatisticsGroup, TripInDatabase } from 'generated/backend-api';
import moment from 'moment';
import { DATE_FORMAT_SHORT_BE } from 'domain-constants';

const vehicleIdlingDuration = [
    12 * 60 + 31,
    13 * 66 + 33,
    2 * 60 + 23,
    2 * 60 + 13,
    6 * 60 + 22,
    26 * 60 + 22,
    0,
    0,
    0,
    0
];
const vehicleIdlingConsumption = [3.2, 4.81, 0.71, 0.61, 0.82, 3.82, 0, 0, 0, 0];

const chunkIndlingDuration = chunkPieces.map((p, i) => p.map(c => c * vehicleIdlingDuration[i])).flat();
const chunkIdlingConsumption = chunkPieces.map((p, i) => p.map(c => c * vehicleIdlingConsumption[i])).flat();

export const fuelConsumptions = trips.map(
    (t: TripInDatabase, i: number) =>
        ({
            monitoredObjectId: t.monitoredObjectId,
            dateFrom: moment(t.startTime).format(DATE_FORMAT_SHORT_BE),
            dateTo: moment(t.endTime).add(12, 'hours')?.format(DATE_FORMAT_SHORT_BE),
            stats: {
                distanceKilometers: t.distance,
                idleTimeSeconds: chunkIndlingDuration[i],
                consumptionLiters: t.totalConsumption,
                consumptionIdleLiters: chunkIdlingConsumption[i],
                consumptionActivityLiters: t.totalConsumption ?? 0 - chunkIdlingConsumption[i],
                consumptionActivityLitersPer100km:
                    t.totalConsumption && t.distance && chunkIdlingConsumption[i]
                        ? ((t.totalConsumption ?? 0 - chunkIdlingConsumption[i]) / t.distance) * 100
                        : 0,
                consumptionLitersPer100km:
                    t.totalConsumption && t.distance ? (t.totalConsumption / t.distance) * 100 : 0,
                consumptionIdleLitersPer1h: chunkIdlingConsumption[i] / 3600
            },
            entries: t.drivers?.[0]
                ? [
                      {
                          driverId: t.drivers[0].id,
                          stats: {
                              distanceKilometers: t.distance,
                              idleTimeSeconds: chunkIndlingDuration[i],
                              consumptionLiters: t.totalConsumption,
                              consumptionIdleLiters: chunkIdlingConsumption[i],
                              consumptionActivityLiters: t.totalConsumption ?? 0 - chunkIdlingConsumption[i],
                              consumptionActivityLitersPer100km:
                                  t.totalConsumption && t.distance && chunkIdlingConsumption[i]
                                      ? ((t.totalConsumption ?? 0 - chunkIdlingConsumption[i]) / t.distance) * 100
                                      : 0,
                              consumptionLitersPer100km:
                                  t.totalConsumption && t.distance ? (t.totalConsumption / t.distance) * 100 : 0,
                              consumptionIdleLitersPer1h: chunkIdlingConsumption[i] / 3600
                          }
                      }
                  ]
                : []
        } as FuelStatisticsGroup)
);
