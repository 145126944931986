import { DriverBehaviorLightVehicleModel, DriverBehaviorTrendsModel } from './statistics';
import {
    ActiveEventRules,
    AdblueLevelObject,
    CarBatteryObject,
    ChargingObject,
    EFuelType,
    ExternalDeviceObject,
    MonitoredObjectGroupType,
    TrailerWeightObject
} from 'generated/graphql';
import { AddressStructured } from 'generated/graphql';
import { Alarm } from './alarm';
import { PuescStatusResponse } from 'generated/backend-api';

export type TachoStatus = 'driving' | 'resting' | 'working';

export enum NoGPSStatus {
    SwitzerlandUnavailable = 'switzerlandUnavailable'
}

type GPS = {
    lat?: number;
    lng?: number;
    angle?: number;
    speed?: number;
};

export type TrailerObject = {
    id: string;
    registrationNumber: string;
    customLabel: string;
};

export interface TrackingModel {
    activeTransports?: VehicleTransport[];
    actual?: VehicleDelayedFilterCode.ON_TIME | string;
    alarms: Alarm[];
    puescStatus?: PuescStatusResponse;
    arrivalTimes: { eta?: string; rta?: string };
    centered: boolean;
    checked: boolean;
    customLabel?: string;
    destination?: string;
    destinationAddressStructured?: AddressStructured[];
    ETA: string;
    firstRTA: string;
    GPS?: GPS;
    id: string;
    invalid: boolean;
    lastChangeState?: string;
    gpsDataTime?: string;
    lastUpdated?: string;
    location: string;
    addressStructured: AddressStructured[];
    aetrData: {
        tacho?: TachoStatus | undefined;
        driverName?: string;
        driverId?: string;
        driverTachocard?: string;
        nextBreak?: string;
        drivingDuration?: number;
        workingDuration?: number;
        restingDuration?: number;
        dailyUtilizationLeft?: number;
        dailyUtilizationMaxHours?: number;
        dailyDriveLeft?: number;
        dailyDriveMaxHours?: number;
        biweeklyDrivingLeft?: number;
        weeklyExtendedShifts?: number;
        choppedDrivingTimeCapacity?: number;
        nextBreakStartTime?: string;
        nextBreakStartTime2?: string;
        weeklyDrivingDuration?: number;
        lastWeekDrivingDuration?: number;
        activityStartTime?: string;
        drivingOrWorkingStartTime?: string;
    }[];
    contactsData?: {
        driverId?: string;
        driverPhone?: string;
    }[];
    tacho?: TachoStatus | undefined;
    noGPSStatus?: NoGPSStatus;
    driverName: string;
    driverId: string;
    driverTachocard?: string;
    nextBreak?: string;
    drivingDuration?: number;
    workingDuration?: number;
    restingDuration?: number;
    dailyUtilizationLeft?: number;
    dailyUtilizationMaxHours?: number;
    dailyDriveLeft?: number;
    dailyDriveMaxHours?: number;
    biweeklyDrivingLeft?: number;
    weeklyExtendedShifts?: number;
    choppedDrivingTimeCapacity?: number;
    nextBreakStartTime?: string;
    nextBreakStartTime2?: string;
    weeklyDrivingDuration?: number;
    lastWeekDrivingDuration?: number;
    activityStartTime?: string;
    drivingOrWorkingStartTime?: string;
    ODO: string;
    powerRemaining?: number;
    RN: string;
    route: boolean;
    RTA: string;
    selected: boolean;
    speed?: number;
    monitoredObjectGroups: MonitoredObjectGroupType[];
    status: number;
    tags?: { id: number; label: string }[];
    driverBehaviorTrends?: DriverBehaviorTrendsModel;
    driverBehaviorLightVehicle?: DriverBehaviorLightVehicleModel;
    tank: number;
    tankSize?: number;
    timeLeftToday?: number;
    trailers?: TrailerObject[];
    trailersName: string[];
    monitoredObjectType?: MonitoredObjectType;
    monitoredObjectFuelType?: EFuelType;
    charging?: ChargingObject;
    weight?: TrailerWeightObject;
    adblue?: AdblueLevelObject;
    carBattery?: CarBatteryObject;
    externalDevices?: ExternalDeviceObject[];
}

export type VehicleTransport = {
    firstRta?: string;
    id?: string;
    name?: string;
    nextWaypoint?: {
        estimatedDistance: number;
        eta: string;
        lat: number;
        lon: number;
        remainingDistancePercentage: number;
        rta: string;
        name: string;
        addressStructured: AddressStructured[];
    };
    activeEventRules: ActiveEventRules[];
};

export type MonitoredObjectType = {
    id: string;
    label: string;
    name: string;
};

export enum MonitoredObjectTypeName {
    VEHICLE = 'vehicle',
    LIGHT_VEHICLE = 'light_vehicle',
    TRAILER = 'trailer'
}

export enum VehicleDelayedFilterCode {
    ALL_ETAS = 'all_etas',
    MORE_THAN_30_MINUTES_LATE = 'more_than_30_minutes_late',
    LESS_THAN_30_MINUTES_LATE = 'less_than_30_minutes_late',
    ON_TIME = 'on_time',
    RTA_NOT_ENTERED = 'rta_not_entered',
    LATE = 'late'
}

export enum VehicleStatusFilterCode {
    ALL_VEHICLE_STATUSES = 'all_vehicle_statuses',
    VEHICLES_MOVING = 'vehicles_moving',
    VEHICLES_STANDING = 'vehicles_standing'
}

export type TrackingType = 'TRACKING_LIVE_MAP' | 'TRACKING_ADVANCE_MAP' | 'TRACKING_UNDEFINED';
