import { action, makeObservable, observable, runInAction } from 'mobx';
import { Logic } from 'logic/logic';
import { PaginationParams, PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { ReadOnlyContactList, ReadOnlyCountry, WriteOnlyContactList } from 'generated/new-main';
import { ContactListFiltersType } from './components/contactListFilters/ContactListFilters';

export class ContactListLogic {
    @observable initLoading?: boolean;

    @observable contactListLoading?: boolean;
    @observable contactList: PaginatedResponse<ReadOnlyContactList[]>;

    @observable countryListLoading?: boolean;
    @observable countryList: ReadOnlyCountry[];

    @observable contactListRemoveLoading?: boolean;
    @observable paginationParams: PaginationParams;
    @observable textSearch?: string;
    @observable selectedContact?: ReadOnlyContactList;
    @observable filters: ContactListFiltersType;

    constructor(private _logic: Logic) {
        this.paginationParams = {
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        };
        this.contactList = {
            data: [],
            total: 0,
            ...this.paginationParams
        };
        this.countryList = [];
        this.filters = {
            types: [],
            countries: []
        };

        makeObservable(this);
    }

    @action
    async init() {
        this.initLoading = true;

        await this.getContactList();
        this.getCountryList();

        this.initLoading = false;
    }

    @action
    async reload(withDefaultSettings: boolean = false) {
        if (withDefaultSettings) {
            this.setDefaultSettings();
        }
        await this.getContactList();
    }

    @action
    async getContactList() {
        this.contactListLoading = true;
        try {
            const contactList = await this._logic
                .clientContactLogic()
                .getPaginatedContactList(
                    this.paginationParams,
                    this.textSearch,
                    this.filters.types,
                    this.filters.countries
                );
            runInAction(() => {
                this.contactList = contactList;
            });
        } catch (err) {
            console.error('Could not fetch contact list');
            throw err;
        } finally {
            runInAction(() => {
                this.contactListLoading = false;
            });
        }
    }

    @action
    async getCountryList() {
        this.countryListLoading = true;
        try {
            const response = await this._logic
                .api()
                .countryApi.countryList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
            runInAction(() => {
                this.countryList = response.results;
            });
        } catch (err) {
            console.error(`could not get country list, err :${err}`);
            throw err;
        } finally {
            runInAction(() => {
                this.countryListLoading = false;
            });
        }
    }

    @action
    setDefaultSettings() {
        this.paginationParams = {
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        };
        this.selectedContact = undefined;
    }

    @action
    onPaginationParamsChange(pagination: PaginationParams) {
        this.paginationParams = pagination;
        this.getContactList();
    }

    @action
    onTextSearchChange(textSearch?: string) {
        this.textSearch = textSearch;
        this.setDefaultSettings();
        this.getContactList();
    }

    @action
    setSelectedContact(contact?: ReadOnlyContactList) {
        this.selectedContact = contact;
    }

    @action
    addLastCreatedContact(contact: ReadOnlyContactList) {
        this.contactList.data.unshift(contact);
    }
    @action
    onFiltersChange(filters: ContactListFiltersType) {
        this.filters = filters;
        this.setDefaultSettings();
        this.getContactList();
    }

    async createContactList(contact: WriteOnlyContactList) {
        try {
            return await this._logic.clientContactLogic().createClientContact(contact);
        } catch (err) {
            console.error('Could not create contact list');
            throw err;
        } finally {
            runInAction(() => {
                this.contactListRemoveLoading = false;
            });
        }
    }

    async removeSelectedContactList() {
        this.contactListRemoveLoading = true;
        try {
            if (!this.selectedContact?.id) throw new Error('no selected contact');
            await this._logic.clientContactLogic().removeClientContact(this.selectedContact?.id);
        } catch (err) {
            console.error('Could not remove contact list');
            throw err;
        } finally {
            runInAction(() => {
                this.contactListRemoveLoading = false;
            });
        }
    }

    async updateSelectedContactList(contact: WriteOnlyContactList) {
        try {
            if (!this.selectedContact?.id) throw new Error('no selected contact');
            return await this._logic.clientContactLogic().updateClientContact(this.selectedContact?.id, contact);
        } catch (err) {
            console.error('Could not create contact list');
            throw err;
        } finally {
            runInAction(() => {
                this.contactListRemoveLoading = false;
            });
        }
    }
}
