import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import qs from 'qs';

import { Logic } from 'logic/logic';
import DispatcherBoardDetail from './ui/DispatcherBoardDetail';
import { RouteNames } from 'App';
import { RouteComponentProps, withRouter } from 'react-router';

import { AetrModel } from 'modules/statistics/modules/aetr/AetrModule';
import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';

import { TrackingModel } from 'common/model/tracking';
import { AvailableCurrencies } from 'common/model/currency';
import { Role } from 'logic/auth';
import {
    Transport,
    TransportMonitoredObject,
    TransportPlace,
    TransportPlaceTask,
    TransportState
} from 'generated/backend-api';
import DFFProposalDetail from 'modules/dispatcher-board/components/DFFProposalDetail';
import { TransportModel } from 'common/model/transports';

interface RouteParams {
    startDate?: string;
    endDate?: string;
    editId?: string;
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

interface State {
    transport?: Transport;
    monitoredObject?: TransportMonitoredObject;
    vehicle?: TrackingModel;
    aetrUser?: AetrModel;
    loading: boolean;
    selectedPlace?: TransportPlace;
    task?: TransportPlaceTask;
    roles: Role[];
    clientCost?: PartnerCompanyModel['costPerKm'];
    proposal?: TransportModel;
    showProposalDetail?: boolean;
}

class DispatcherBoardDetailModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;

        this.state = {
            loading: true,
            roles: this._logic.auth().roles()
        };
    }

    componentDidMount() {
        (window as any).app.modul = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        this._logic.map().sideBarControlsOffResetState();
        this._logic.dispatcherBoardDetail().init();

        if (params.editId) {
            this._logic
                .dispatcherBoardDetail()
                .transportAndTrips(params.editId)
                .then(data => {
                    const monitoredObject = this._logic.transportLogic().getActiveMonitoredObject(data);
                    this.setState({ transport: data, monitoredObject });

                    if (
                        monitoredObject &&
                        data?.state &&
                        [TransportState.Active, TransportState.Delayed].includes(data.state)
                    ) {
                        this._logic
                            .vehiclesState()
                            .vehicle(String(monitoredObject.monitoredObjectId), true)
                            .then(vehicle => {
                                if (vehicle?.gpsData?.lat && vehicle?.gpsData?.lon) {
                                    const vehicleModelMap = this._logic
                                        .tracking()
                                        .toMapModel(this._logic.tracking().toTrackingModel(vehicle, []));
                                    this._logic.map().vehicles().setData([vehicleModelMap]);
                                    this._logic.map().vehicles().show();
                                    this._logic.dispatcherBoardDetail().drawRouteOnMap(vehicleModelMap);
                                } else {
                                    this._logic.dispatcherBoardDetail().drawRouteOnMap();
                                }
                                this.setState(state => ({
                                    ...state,
                                    vehicle: this._logic.tracking().toTrackingModel(vehicle ?? {}, [])
                                }));
                            });
                    } else {
                        this._logic.dispatcherBoardDetail().drawRouteOnMap();
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });

            this._logic
                .api()
                .dffProposalApi.getProposalsV3ProposalsMyGetByTransportId({ transportId: params.editId })
                .then(proposal => {
                    this.setState({ proposal: this._logic.dffProposals().dffProposalToTransportModel(proposal) });
                });
        }

        this._logic
            .map()
            .routing()
            .onAlarmAcknowledgedClick(id => {
                this._logic.alarms().markAlarmsAsSeen([id]);
            });
    }

    componentWillUnmount() {
        this._logic.dispatcherBoardDetail().destroy();
        this._logic.plannerLogic().reset(false, true);
    }

    render() {
        return (
            <>
                {this.state.proposal && (
                    <DFFProposalDetail
                        visible={this.state.showProposalDetail!}
                        onClose={() => this._onProposalDetailToggleClick(false)}
                        proposal={this.state.proposal}
                    />
                )}

                <DispatcherBoardDetail
                    data={{
                        transport: this.state.transport,
                        vehicle: this.state.vehicle,
                        selectedPlace: this.state.selectedPlace,
                        plannedCost: this._getPlannedCost(),
                        loading: this.state.loading
                    }}
                    hasAlarmRight={!!this.props.logic.auth().roles().includes(Role.IA_R)}
                    hasProposal={!!this.state.proposal}
                    onAlarmClickOrTaskClick={this._onAlarmClickOrTaskClick}
                    onAlarmClickOrTaskCloseClick={this._onAlarmClickOrTaskCloseClick}
                    roles={this.state.roles}
                    onBarCloseClick={this._onBarCloseClick}
                    onBarEditClick={this._onBarEditClick}
                    onProposalDetailClick={() => this._onProposalDetailToggleClick(true)}
                />
            </>
        );
    }

    private _getPlannedCost = (): Transport['costPerKm'] => {
        const totalTransportDistanceKM =
            (this.state.transport?.places ?? []).reduce((a, c) => (a = a + (c.distance ?? 0)), 0) / 1e3;

        if (this.state.transport?.costPerKm?.value && this.state.transport?.costPerKm?.value > 0) {
            return {
                value: Math.round(this.state.transport?.costPerKm.value * totalTransportDistanceKM),
                currency: this.state.transport?.costPerKm.currency ?? AvailableCurrencies.EUR
            };
        } else if (this.state.clientCost?.cost) {
            return {
                value: Math.round(this.state.clientCost?.cost * totalTransportDistanceKM),
                currency: this.state.clientCost.currency ?? AvailableCurrencies.EUR
            };
        } else {
            return {
                value: 0,
                currency: AvailableCurrencies.EUR
            };
        }
    };

    private _onBarCloseClick = () => {
        this.props.history.push({
            pathname: RouteNames.SCHEDULING_DISPATCHER_BOARD
        });
    };

    private _onBarEditClick = () => {
        if (this.state.transport?.id) {
            this.props.history.push({
                pathname:
                    this.props.logic.conf.settings.plannerOldAvailable && this.state.transport?.version === 2
                        ? RouteNames.SCHEDULING_PLANNER_NEW
                        : RouteNames.SCHEDULING_PLANNER,
                search: qs.stringify({
                    editId: this.state.transport?.id,
                    vehicleId: this.state.monitoredObject?.monitoredObjectId,
                    dispatcherBoard: true
                })
            });
        }
    };

    private _onAlarmClickOrTaskClick = (id: string) => {
        const place = this.state.transport?.places?.find(p => p.id === id);
        if (this.state.selectedPlace?.id !== place?.id) {
            this.setState(
                {
                    selectedPlace: undefined
                },
                () =>
                    this.setState({
                        selectedPlace: place
                    })
            );
        } else if (place) {
            this.setState({
                selectedPlace: place
            });
        }
    };
    private _onAlarmClickOrTaskCloseClick = () => {
        this.setState({
            selectedPlace: undefined
        });
    };

    private _onProposalDetailToggleClick = (visible: boolean) => {
        this.setState({ showProposalDetail: visible });
    };
}

export default withTranslation()(withRouter(DispatcherBoardDetailModule));
