/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomPlaceCrossingWithCustomPlaceData,
    CustomPlaceCrossingWithCustomPlaceDataFromJSON,
    CustomPlaceCrossingWithCustomPlaceDataToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface ManualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGetRequest {
    userIds?: Array<number>;
    start?: Date;
    end?: Date;
}

/**
 * no description
 */
export class PlaceOfWorkCrossingApi extends runtime.BaseAPI {

    /**
     * Manual Place Of Work Crossing List
     */
    async manualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGetRaw(requestParameters: ManualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGetRequest): Promise<runtime.ApiResponse<Array<CustomPlaceCrossingWithCustomPlaceData>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/place_of_work_crossing_manual`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomPlaceCrossingWithCustomPlaceDataFromJSON));
    }

    /**
     * Manual Place Of Work Crossing List
     */
    async manualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGet(requestParameters: ManualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGetRequest): Promise<Array<CustomPlaceCrossingWithCustomPlaceData>> {
        const response = await this.manualPlaceOfWorkCrossingListV1PlacesPlaceOfWorkCrossingManualGetRaw(requestParameters);
        return await response.value();
    }

}
