import { Component } from 'react';
import { CheckboxGroup } from 'common/components/CheckboxGroup';
import TableFilter from 'common/components/TableFilter';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ContactListFiltersType } from '../contactListFilters/ContactListFilters';
import { ContactListListTypeEnum, ReadOnlyCountry } from 'generated/new-main';
import { Checkbox } from 'common/components';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    filters: ContactListFiltersType;
    countries: ReadOnlyCountry[];
    onSubmit?(filters: ContactListFiltersType): void;
    onCancel?: () => void;
}

interface State {
    workingFilters: ContactListFiltersType;
}

class ContactListFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            workingFilters: props.filters
        };
    }

    render() {
        const { t } = this.props;
        return (
            <TableFilter
                className="coldchain-filter"
                qa={qa.coldChain.filter.section}
                heading={t('ContactList.filterName')}
                body={
                    <Row gutter={[24, 24]}>
                        <Col>
                            <label className="group-title">{this.props.t('ClientContactListForm.type')}</label>
                            <div className="control">
                                <div className="ant-checkbox-group">
                                    <div className="ant-checkbox-group-item">
                                        <Checkbox
                                            name="type"
                                            className="checkItem"
                                            checked={this.state.workingFilters.types.includes(
                                                ContactListListTypeEnum.Client
                                            )}
                                            qa={qa.contacts.filter.inputTypeClient}
                                            onChange={() =>
                                                this._handleChangeClientType(ContactListListTypeEnum.Client)
                                            }
                                        >
                                            {this.props.t('ContactList.tableClientTypeValues.client')}
                                        </Checkbox>
                                    </div>
                                    <div className="ant-checkbox-group-item">
                                        <Checkbox
                                            name="type"
                                            className="checkItem"
                                            checked={this.state.workingFilters.types.includes(
                                                ContactListListTypeEnum.Supplier
                                            )}
                                            qa={qa.contacts.filter.inputTypeSupplier}
                                            onChange={() =>
                                                this._handleChangeClientType(ContactListListTypeEnum.Supplier)
                                            }
                                        >
                                            {this.props.t('ContactList.tableClientTypeValues.supplier')}
                                        </Checkbox>
                                    </div>
                                    <div className="ant-checkbox-group-item">
                                        <Checkbox
                                            name="type"
                                            className="checkItem"
                                            checked={this.state.workingFilters.types.includes(
                                                ContactListListTypeEnum.Consignee
                                            )}
                                            qa={qa.contacts.filter.inputTypeSupplier}
                                            onChange={() =>
                                                this._handleChangeClientType(ContactListListTypeEnum.Consignee)
                                            }
                                        >
                                            {this.props.t('ContactList.tableClientTypeValues.consignee')}
                                        </Checkbox>
                                    </div>
                                    <div className="ant-checkbox-group-item">
                                        <Checkbox
                                            name="type"
                                            className="checkItem allTypes"
                                            checked={this.state.workingFilters.types.length === 0}
                                            qa={qa.contacts.filter.inputTypeAll}
                                            onChange={() => this._handleChangeClientType(undefined, true)}
                                        >
                                            {this.props.t('ContactList.tableClientTypeValues.allTypes')}
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <CheckboxGroup
                                heading={this.props.t('ContactList.table.country')}
                                scrolled={true}
                                labelCheckAll={this.props.t('ContactList.tableClientTypeValues.allCountries')}
                                options={this.props.countries.map(country => ({
                                    code: String(country.id),
                                    label: country.name,
                                    checked: country.id
                                        ? this.state.workingFilters.countries.includes(country.id)
                                        : false
                                }))}
                                qaCheckAll={qa.contacts.filter.inputCountryAll}
                                onChange={this._handleSelectCountry}
                            />
                        </Col>
                    </Row>
                }
                cancelButton={{
                    onClick: this.props.onCancel,
                    qa: qa.contacts.filter.btnCancel
                }}
                submitButton={{
                    onClick: () => this.props.onSubmit?.(this.state.workingFilters),
                    qa: qa.contacts.filter.btnConfirm,
                    disabled: this.props.demoMode
                }}
                resetButton={{
                    onClick: this._onResetClick
                }}
            />
        );
    }

    private _onResetClick = () => {
        const defaults = confDefault.settings.management.contactList.filter;
        this.setState({
            workingFilters: {
                countries: defaults.countries,
                types: defaults.types
            }
        });
    };

    private _handleChangeClientType = (type?: ContactListListTypeEnum, selectAll?: boolean) => {
        this.setState(prev => {
            if (selectAll) {
                return {
                    ...prev,
                    workingFilters: {
                        ...prev.workingFilters,
                        types: []
                    }
                };
            }
            if (type) {
                if (prev.workingFilters.types.includes(type)) {
                    return {
                        ...prev,
                        workingFilters: {
                            ...prev.workingFilters,
                            types: prev.workingFilters.types.filter(prevType => prevType !== type)
                        }
                    };
                }

                return {
                    ...prev,
                    workingFilters: {
                        ...prev.workingFilters,
                        types: [...prev.workingFilters.types, type]
                    }
                };
            }

            return prev;
        });
    };

    private _handleSelectCountry = (countries: string[]) => {
        this.setState(prev => {
            return {
                ...prev,
                workingFilters: {
                    ...prev.workingFilters,
                    countries: countries.map(c => Number(c))
                }
            };
        });
    };
}

export default withTranslation()(ContactListFilter);
