import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';

interface Props {
    data: PartnerUserModel;
}

export default function UsersDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="pricing-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>{props.data.name}</Descriptions.Item>
                        <Descriptions.Item label={t('common.surname')}>{props.data.surname}</Descriptions.Item>
                        <Descriptions.Item label={t('PartnerUsers.note')}>{props.data.note}</Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.email')}>
                            {props.data.contact?.emails?.[0]}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.phone')}>
                            {props.data.contact?.phone_numbers?.[0]}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerUsers.contact')}>{props.data.clientName}</Descriptions.Item>
                        <Descriptions.Item label={t('Partner.sso')}>{props.data.ssoId}</Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
