/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMobileDeviceHasMobileApp
 */
export interface WriteOnlyMobileDeviceHasMobileApp {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceHasMobileApp
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMobileDeviceHasMobileApp
     */
    upgradedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceHasMobileApp
     */
    mobileDevice: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceHasMobileApp
     */
    mobileApp: string;
}

export function WriteOnlyMobileDeviceHasMobileAppFromJSON(json: any): WriteOnlyMobileDeviceHasMobileApp {
    return WriteOnlyMobileDeviceHasMobileAppFromJSONTyped(json, false);
}

export function WriteOnlyMobileDeviceHasMobileAppFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMobileDeviceHasMobileApp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'upgradedAt': !exists(json, 'upgraded_at') ? undefined : (json['upgraded_at'] === null ? null : new Date(json['upgraded_at'])),
        'mobileDevice': json['mobile_device'],
        'mobileApp': json['mobile_app'],
    };
}

export function WriteOnlyMobileDeviceHasMobileAppToJSON(value?: WriteOnlyMobileDeviceHasMobileApp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'upgraded_at': value.upgradedAt === undefined ? undefined : (value.upgradedAt === null ? null : value.upgradedAt.toISOString()),
        'mobile_device': value.mobileDevice,
        'mobile_app': value.mobileApp,
    };
}


