/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CostProfileItem,
    CostProfileItemFromJSON,
    CostProfileItemFromJSONTyped,
    CostProfileItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyCostProfile
 */
export interface CompanyCostProfile {
    /**
     * 
     * @type {CostProfileItem}
     * @memberof CompanyCostProfile
     */
    salaries: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof CompanyCostProfile
     */
    operations: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof CompanyCostProfile
     */
    crmInsurance: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof CompanyCostProfile
     */
    other: CostProfileItem;
}

export function CompanyCostProfileFromJSON(json: any): CompanyCostProfile {
    return CompanyCostProfileFromJSONTyped(json, false);
}

export function CompanyCostProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCostProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salaries': CostProfileItemFromJSON(json['salaries']),
        'operations': CostProfileItemFromJSON(json['operations']),
        'crmInsurance': CostProfileItemFromJSON(json['crm_insurance']),
        'other': CostProfileItemFromJSON(json['other']),
    };
}

export function CompanyCostProfileToJSON(value?: CompanyCostProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salaries': CostProfileItemToJSON(value.salaries),
        'operations': CostProfileItemToJSON(value.operations),
        'crm_insurance': CostProfileItemToJSON(value.crmInsurance),
        'other': CostProfileItemToJSON(value.other),
    };
}


