import { Component } from 'react';
import { WithLogic, withLogicContext } from 'App';
import NotificationBlock from '../NotificationBlock';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DriverModel } from 'logic/user/users';
import { MaintenanceNotificationInDb } from 'generated/backend-api';
import { KM } from 'domain-constants';
import numeral from 'numeral';
import moment from 'moment';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

interface Props extends WithTranslation, WithLogic {
    open: boolean;
    fleet: ReadOnlyMonitoredObjectFeSb[];
    drivers: DriverModel[];
    notifications: MaintenanceNotificationInDb[];
    demoMode?: boolean;
    onMarkAsSeen: (id: string) => void;
    onTaskOpen: (id: string) => void;
}

interface State {}

class Notifications extends Component<Props, State> {
    render() {
        const { open, notifications, t } = this.props;
        return open ? (
            <div className="notifications">
                {notifications
                    .filter(notification => !notification.acknowledged)
                    .map((notification, index) => {
                        const driver = notification.event.maintenanceTaskNew.userId
                            ? this.props.drivers.find(d => d.id === notification.event.maintenanceTaskNew.userId)
                            : undefined;

                        const dueDateLeftDays = notification.event.maintenanceTaskNew.dueDateLeftSeconds
                            ? Math.ceil(notification.event.maintenanceTaskNew.dueDateLeftSeconds / (3600 * 24))
                            : undefined;

                        const dueDateLeftDaysValue =
                            dueDateLeftDays !== undefined
                                ? dueDateLeftDays === 0
                                    ? t('common.today')
                                    : `${Math.abs(dueDateLeftDays)} 
                            ${Math.abs(dueDateLeftDays) > 1 ? t('common.days') : t('common.day')}`
                                : '';
                        const dueMileageLeftKilometersValue = notification.event.maintenanceTaskNew
                            .dueMileageLeftKilometers
                            ? `${numeral(
                                  Math.abs(notification.event.maintenanceTaskNew.dueMileageLeftKilometers)
                              ).format('0,0')} ${KM}`
                            : '';

                        return (
                            <NotificationBlock
                                key={index}
                                notificationId={notification.id}
                                taskId={notification.taskId}
                                createdAt={
                                    notification.createdAt ? moment(notification.createdAt).toISOString() : undefined
                                }
                                taskDueValue={`${dueDateLeftDaysValue}${
                                    dueDateLeftDaysValue && dueMileageLeftKilometersValue && ' | '
                                }${dueMileageLeftKilometersValue}`}
                                taskSubject={
                                    (driver
                                        ? `${driver.name} ${driver.surname}`
                                        : notification.event.maintenanceTaskNew.monitoredObjectId
                                        ? this.props.fleet.find(
                                              f =>
                                                  String(f.id) ===
                                                  notification.event.maintenanceTaskNew.monitoredObjectId
                                          )?.registrationNumber
                                        : this.props.logic.auth().client()?.name) ?? ''
                                }
                                taskState={notification.event.maintenanceTaskNew.state}
                                taskTaskTypeName={notification.event.maintenanceTaskNew.taskTypeName}
                                demoMode={this.props.demoMode}
                                onMarkAsSeen={this.props.onMarkAsSeen}
                                onTaskOpen={this.props.onTaskOpen}
                            />
                        );
                    })}
                {notifications.length === 0 && (
                    <div className="notifications-empty">{t('Maintenance.notification.empty')}</div>
                )}
            </div>
        ) : null;
    }
}

export default withTranslation()(withLogicContext(Notifications));
