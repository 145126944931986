import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import qa from 'qa-selectors';
import { DriverBehaviorRankModel } from 'common/model/statistics';
import cn from 'classnames';
import { cupCoach } from 'resources/images/driver-behavior';

interface Props {
    driversList?: DriverBehaviorRankModel[];
    qa?: string;
}

export default function DriveRank({ driversList }: Props) {
    const { t } = useTranslation();
    return (
        <div className="drive-rank-container">
            <div
                className={cn('drive-rank', {
                    'drive-rank-multi': (driversList?.length ?? 0) > 1
                })}
            >
                <>
                    {(driversList?.length ?? 0) === 1 && (
                        <>
                            <Row className="drive-rank-row" align="middle">
                                <Col flex={1} className="drive-rank-title">
                                    {t('DriveRank.title')}
                                </Col>
                                <Col
                                    span={8}
                                    className="drive-rank-value"
                                    data-qa={qa.driverBehaviour.trucks.detail.rank}
                                >
                                    <span>{`${driversList?.[0].rank}/${driversList?.[0].totalDrivers}`}</span>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center">
                                <Col>
                                    <img src={cupCoach} alt="cup" />
                                </Col>
                            </Row>
                        </>
                    )}
                    {(driversList?.length ?? 0) > 1 && (
                        <>
                            <Row className="drive-rank-row drive-rank-title" align="middle">
                                {t('DriveRank.title')}
                            </Row>
                            <Row align="middle">
                                <Col span={12} className="cup">
                                    <img src={cupCoach} alt="cup" />
                                </Col>
                                <Col className="drivers-container" span={12}>
                                    {driversList?.map((driver, index) => (
                                        <div className="drivers-container-driver" key={`driverRank${index}`}>
                                            <Row className="drive-rank-name" title={driver.name}>
                                                {driver.name}
                                            </Row>
                                            <Row justify="start" align="middle">
                                                <Col
                                                    className={cn('drive-rank-value', {
                                                        'no-data-text': !driver.rank
                                                    })}
                                                >
                                                    <div>
                                                        {driver.rank
                                                            ? `${driver.rank}/${driver.totalDrivers}`
                                                            : t('common.noData').toLowerCase()}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            </div>
        </div>
    );
}
