import cn from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'common/components';
import { OtherSystemsData } from '../../modules/other-systems';
import qa from 'qa-selectors';

interface Props {
    data?: OtherSystemsData[];
    loading?: boolean;
    selected?: OtherSystemsData;
    onTableRowSelect?: (id: number) => void;
    onEditClick?: (id: number) => void;
    onRemoveClick?: (id: number) => void;
}

export default function OtherSystemsTable(props: Props) {
    const { t } = useTranslation();

    function createColumns(): ColumnsType<OtherSystemsData> {
        return [
            {
                title: t('OtherSystemsTable.name'),
                dataIndex: 'name',
                key: 'name',
                width: 30,
                render: data => <span data-qa={qa.systemConnections.otherSystems.table.fieldName}>{data}</span>
            },
            {
                title: t('OtherSystemsTable.vehicles'),
                dataIndex: 'vehicles',
                key: 'vehicles',
                width: 70,
                render: data => <span data-qa={qa.systemConnections.otherSystems.table.fieldVehicles}>{data}</span>
            },
            {
                title: '',
                align: 'right',
                dataIndex: 'actions',
                width: 45,
                render: (_, record) => (
                    <Row gutter={10} className="sys-connections-table-actions" justify="end">
                        <Col>
                            <Button
                                type="link"
                                onClick={() => {
                                    props.onEditClick?.(record.id);
                                }}
                                icon={<img alt="edit" src="/img-svg/icn-24-edit.svg" />}
                                qa={qa.systemConnections.otherSystems.table.btnEdit}
                            />
                        </Col>
                        <Col>
                            <Button
                                type="link"
                                onClick={e => {
                                    e.stopPropagation();
                                    props.onRemoveClick?.(record.id);
                                }}
                                icon={<img alt="delete" src="/img-svg/icn-delete.svg" />}
                                qa={qa.systemConnections.otherSystems.table.btnRemove}
                            />
                        </Col>
                    </Row>
                )
            }
        ];
    }

    return (
        <div className="sys-connections-table">
            <Table<OtherSystemsData>
                loading={props.loading}
                dataSource={props.data?.map(e => ({ ...e, key: e.id }))}
                bordered={false}
                columns={createColumns()}
                onRow={record => ({
                    onClick: () => props.onTableRowSelect?.(record.id),
                    className: cn({
                        'ant-table-row-selected': props.selected?.id === record.id
                    })
                })}
            />
        </div>
    );
}
