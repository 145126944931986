import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PlannerContentContainer, { PlannerContentContainerProps } from '../PlannerContentContainer';
import { Col, Row } from 'antd';
import { DatePicker } from 'common/components';
import { DATE_TIME_FORMAT_SHORT } from 'domain-constants';
import moment from 'moment';
import { TransportPlace, TransportPlaceTask, TransportPlaceTaskType } from 'generated/backend-api';
import {
    ActivityParking,
    ActivityRefueling,
    ActivityTrash,
    ActivityWashing,
    ActivityLoading,
    ActivityUnloading,
    RouteTimer
} from 'resources/images/planner';

export interface ActivityBaseProps extends PlannerContentContainerProps {
    task: TransportPlaceTask;
    transportPlace: TransportPlace;
    index: number;
    dateFrom?: Date;
    dateTo?: Date;
    beforeContent?: React.ReactNode;
    placeDisabled?: boolean;
    expanded?: boolean;
    onDateChange?: (taskIndex: number) => void;
    onDelete?: (index: number) => void;
    onExpadedWidth?: (index: number, value: boolean) => void;
}

const ActivityTaskBase = (props: React.PropsWithChildren<ActivityBaseProps>) => {
    const plannedStart = props.task.plannedStart;
    const plannedEnd = props.task.plannedEnd;
    const minPlannedStart =
        props.index === 0
            ? moment(props.transportPlace.rta)
            : moment(props.transportPlace?.tasks?.[props.index - 1]?.plannedEnd);
    const [duration, setDuration] = useState(moment(props.task.plannedEnd).diff(moment(props.task.plannedStart)));

    const handlerDateFromChange = (value: moment.Moment | null) => {
        const diffTime = moment(props.task.plannedEnd).diff(moment(props.task.plannedStart));
        if (value?.isSameOrAfter(moment(minPlannedStart))) {
            props.task.plannedStart = value?.toDate();
        }

        props.task.plannedEnd = moment(props.task.plannedStart)
            .add(diffTime / 1000, 'seconds')
            .toDate();
        setDuration(diffTime);
        props.onDateChange?.(props.index);
    };

    const handlerDisableDateFrom = (value: moment.Moment) => {
        return moment(value).startOf('day').isBefore(moment(minPlannedStart).startOf('day'));
    };

    const handlerDateToChange = (value: moment.Moment | null) => {
        if (value?.isSameOrAfter(moment(plannedStart))) {
            props.task.plannedEnd = value?.toDate();
        }
        setDuration(moment(props.task.plannedEnd).diff(moment(props.task.plannedStart)));
        props.onDateChange?.(props.index);
    };

    const handlerDisableDateTo = (value: moment.Moment) => {
        return moment(value).startOf('day').isBefore(moment(plannedStart).startOf('day'));
    };

    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const handlerDisableTime = (value: moment.Moment | null, date: Date | undefined) => {
        const isStartDay = moment(value).startOf('day').isSame(moment(date).startOf('day'));
        if (isStartDay) {
            if (moment(value).hours() < moment(date).hours()) {
                value?.hours(moment(date).hours());
            }
            if (moment(value).hours() <= moment(date).hours() && moment(value).minutes() < moment(date).minutes()) {
                value?.minutes(moment(date).minutes());
            }
        }
        return {
            disabledHours: () => (isStartDay ? range(0, moment(date).hours() ?? 0) : []),
            disabledMinutes: () =>
                isStartDay && moment(date).hours() === moment(value).hours()
                    ? range(0, moment(date).minutes() ?? 0)
                    : [],
            disabledSeconds: () => []
        };
    };

    const handlerDelete = (event: React.MouseEvent) => {
        props.onDelete?.(props.index);
        event.stopPropagation();
        event.preventDefault();
    };

    const _getIcon = (type: TransportPlaceTaskType | undefined): string => {
        switch (type) {
            case TransportPlaceTaskType.Parking:
                return ActivityParking;
            case TransportPlaceTaskType.Refueling:
                return ActivityRefueling;
            case TransportPlaceTaskType.Washing:
                return ActivityWashing;
            case TransportPlaceTaskType.Loading:
                return ActivityLoading;
            case TransportPlaceTaskType.Unloading:
                return ActivityUnloading;
            default:
                return '';
        }
    };

    const { t } = props;

    return (
        <PlannerContentContainer
            {...props}
            beforeTitle={
                <img className="activity-icon" src={_getIcon(props.task.type)} alt={`activity-${props.task.type}`} />
            }
            title={t(`PlannerPlacesTask.${props.task.type}`)}
            afterTitle={
                !props.placeDisabled && (
                    <img
                        className="activity-icon-trash"
                        src={ActivityTrash}
                        alt="activity-trash"
                        onClick={handlerDelete}
                    />
                )
            }
            className={`activity-task-base ${props.className}`}
        >
            {props.beforeContent}
            <Row align="middle">
                <Col span={9}>
                    <Row>{t('PlannerPlacesTask.startTime')}</Row>
                    <DatePicker.DateTimePicker
                        showTime={{ format: DATE_TIME_FORMAT_SHORT }}
                        disabled={props.placeDisabled}
                        value={moment(plannedStart)}
                        allowClear={false}
                        onChange={handlerDateFromChange}
                        disabledDate={handlerDisableDateFrom}
                        disabledTime={value => {
                            return handlerDisableTime(value, moment(minPlannedStart).toDate());
                        }}
                        showNow={false}
                    />
                </Col>
                <Col span={6} className="stay-col">
                    <Row>{t('PlannerPlacesTask.stay')}</Row>
                    <Row className="activity-stay" align="middle" justify="center">
                        <Row className="activity-stay-content" align="middle">
                            <Col flex="auto">{moment.duration(duration).format('hh:mm', { trim: 'mid' })}</Col>
                            <Col className="activity-stay-content-icon">
                                <img src={RouteTimer} alt="activity-time" />
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col span={9}>
                    <Row>{t('PlannerPlacesTask.endTime')}</Row>
                    <DatePicker.DateTimePicker
                        showTime={{ format: DATE_TIME_FORMAT_SHORT }}
                        disabled={props.placeDisabled}
                        value={moment(plannedEnd)}
                        allowClear={false}
                        onChange={handlerDateToChange}
                        disabledDate={handlerDisableDateTo}
                        disabledTime={value => {
                            return handlerDisableTime(value, plannedStart);
                        }}
                        showNow={false}
                    />
                </Col>
            </Row>
            {props.children}
        </PlannerContentContainer>
    );
};

export default withTranslation()(ActivityTaskBase);
