import { message } from 'antd';
import { WithLogic, withLogicContext } from 'App';
import Confirm, { MessageType } from 'common/components/Confirm';
import { Loading } from 'common/components/Loading';
import { DATE_FORMAT } from 'domain-constants';
import {
    CustomPlace,
    CustomPlaceCrossingTstrWithCustomPlaceData,
    ManualCountryIntervalInDb
} from 'generated/backend-api/models';
import moment from 'moment';
import qa from 'qa-selectors';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Subscription } from 'rxjs';
import { ReadOnlyDiemsRules } from 'generated/new-main/models';
import DiemsBulkForm, { DiemsBulkFormModel } from 'common/forms/DiemsBulkForm/DiemsBulkForm';
import { calculationType, pocketMoneyMapper, replacementMeals } from 'common/model/allowance';
import { Button, Table, Tooltip } from 'common/components';

interface Props extends WithTranslation, WithLogic {
    userId: number;
    dateFrom: string;
    dateTo: string;
    places: CustomPlace[];
    demoMode?: boolean;
    appliedRuleForSelectedRow?: {
        loading: boolean;
        rule?: ReadOnlyDiemsRules;
    };

    onCancelRuleEdit: () => void;
    onSubmitRuleForm: (values: DiemsBulkFormModel) => void;
}

interface State {
    borderCrossingLoading: boolean;
    borderCrossings?: ManualCountryIntervalInDb[];
    placeOfWorkCrossingLoading: boolean;
    placeOfWorkCrossing?: CustomPlaceCrossingTstrWithCustomPlaceData[];
    removeBorderCrossing?: {
        id: string;
        visible: boolean;
    };
    removePlaceOfWorkCrossing?: {
        id: string;
        visible: boolean;
    };
}

class AllowanceDetailActions extends React.Component<Props, State> {
    borderCrossingDataSubscription?: Subscription;
    borderCrossingLoadingSubscription?: Subscription;
    borderCrossingErrorSubscription?: Subscription;
    placeOfWorkCrossingDataSubscription?: Subscription;
    placeOfWorkCrossingLoadingSubscription?: Subscription;
    placeOfWorkCrossingErrorSubscription?: Subscription;
    constructor(props: Props) {
        super(props);
        this.state = {
            borderCrossingLoading: true,
            placeOfWorkCrossingLoading: false
        };
    }

    componentDidMount() {
        this.borderCrossingDataSubscription = this.props.logic.borderCrossing().manualCrossingData.subscribe(data => {
            this.setState({
                borderCrossings: data
            });
        });
        this.borderCrossingLoadingSubscription = this.props.logic
            .borderCrossing()
            .manualCrossingLoading.subscribe(loading => {
                this.setState({
                    borderCrossingLoading: loading
                });
            });
        this.borderCrossingErrorSubscription = this.props.logic.borderCrossing().manualCrossingError.subscribe(err => {
            console.error(err);
            message.error(this.props.t('common.error.loadDataError'));
        });

        this.placeOfWorkCrossingDataSubscription = this.props.logic
            .poi()
            .manualPlaceOfWorkCrossingData.subscribe(data => {
                this.setState({
                    placeOfWorkCrossing: data
                });
            });
        this.placeOfWorkCrossingLoadingSubscription = this.props.logic
            .poi()
            .manualPlaceOfWorkCrossingLoading.subscribe(loading => {
                this.setState({
                    borderCrossingLoading: loading
                });
            });
        this.placeOfWorkCrossingErrorSubscription = this.props.logic
            .poi()
            .manualPlaceOfWorkCrossingError.subscribe(err => {
                console.error(err);
                message.error(this.props.t('common.error.loadDataError'));
            });

        this._loadBorderCrossings();
        this._loadPlaceOfWorkCrossings();
    }

    componentWillUnmount() {
        this.borderCrossingDataSubscription?.unsubscribe();
        this.borderCrossingLoadingSubscription?.unsubscribe();
        this.borderCrossingErrorSubscription?.unsubscribe();

        this.placeOfWorkCrossingDataSubscription?.unsubscribe();
        this.placeOfWorkCrossingLoadingSubscription?.unsubscribe();
        this.placeOfWorkCrossingErrorSubscription?.unsubscribe();
    }

    render() {
        const { places, t } = this.props;
        return (
            <div className="allowance-detail-actions">
                {this.props.appliedRuleForSelectedRow && (
                    <>
                        <h2>{t('Allowance.bulk.title')}</h2>
                        {this.props.appliedRuleForSelectedRow.loading ? (
                            <Loading />
                        ) : (
                            <DiemsBulkForm
                                initialValues={{
                                    start: moment(this.props.appliedRuleForSelectedRow.rule?.start).format(DATE_FORMAT),
                                    end: moment(this.props.appliedRuleForSelectedRow.rule?.end).format(DATE_FORMAT),
                                    pocketMoney:
                                        parseInt(
                                            Object.keys(pocketMoneyMapper).find(
                                                key =>
                                                    pocketMoneyMapper[key] ===
                                                    this.props.appliedRuleForSelectedRow?.rule?.pocketMoney
                                            ) ?? pocketMoneyMapper[0].toString()
                                        ) ?? pocketMoneyMapper[0],
                                    replacedMeals: this.props.appliedRuleForSelectedRow.rule?.replacedMeals ?? []
                                }}
                                isPolishDiems={
                                    this.props.logic.auth().newEWClient()?.country?.iso2.toUpperCase() === 'PL'
                                }
                                demoMode={this.props.logic.demo().isActive}
                                lang={this.props.logic.auth().user().lang}
                                replacementMeals={replacementMeals()}
                                calculationType={calculationType()}
                                onCancel={this.props.onCancelRuleEdit}
                                onSubmit={this.props.onSubmitRuleForm}
                            />
                        )}
                    </>
                )}

                <h2>{t('Allowance.actions.driverPlacesTitle')}</h2>
                <Table<CustomPlace>
                    dataSource={places?.map(d => ({ ...d, key: d.id }))}
                    size="small"
                    columns={[
                        {
                            title: t('common.name'),
                            width: 100,
                            align: 'left',
                            render: (_, record) => <>{record.name ?? ''}</>
                        },
                        {
                            title: t('common.address'),
                            width: 170,
                            className: 'allowance-detail-actions-card-pow-address',
                            align: 'left',
                            render: (_, record) => (
                                <Tooltip title={record.address ?? ''} placement="bottomLeft">
                                    {record.address ?? ''}
                                </Tooltip>
                            )
                        }
                    ]}
                />

                {/* https://jira.eurowag.com/browse/ET-9615 */}
                {/* <h2>{this.props.t('Allowance.actions.BorderCrossingTitle')}</h2>
                <div className="allowance-detail-actions-card">
                    {this.state.borderCrossingLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Button type="primary" onClick={this.props.onAddBorderCrossingClick}>
                                {this.props.t('Allowance.actions.addBorderCrossing')}
                            </Button>
                            {this.state.borderCrossings?.map(crossing => (
                                <div key={crossing.id}>
                                    <Row gutter={[10, 20]}>
                                        <Col span={6}>
                                            {moment(crossing.countryInterval.start).format('L LT')}
                                        </Col>
                                        <Col span={18}>
                                            <div className="allowance-detail-actions-card-row">
                                                <div>
                                                    {crossing.countryInterval.countryEntry} {' -----> '}{' '}
                                                    {crossing.countryInterval.countryExit}
                                                </div>
                                                <img
                                                    onClick={() => this.onRemoveCrossingClick(crossing.id)}
                                                    alt="delete"
                                                    src="/img-svg/icn-24-delete.svg"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </>
                    )}
                </div> */}
                <br />
                <br />
                <h2>{t('Allowance.actions.PlaceOFWorkCrossingTitle')}</h2>
                {this.state.placeOfWorkCrossingLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Table<CustomPlaceCrossingTstrWithCustomPlaceData>
                            dataSource={this.state.placeOfWorkCrossing?.map(d => ({ ...d, key: d.id }))}
                            size="small"
                            columns={[
                                {
                                    title: t('Allowance.placeOfWorkCrossingForm.type'),
                                    width: 100,
                                    align: 'center',
                                    render: (_, record) => <>{t(`common.${record.customPlaceCrossing.type}`)}</>
                                },
                                {
                                    title: t('Allowance.placeOfWorkCrossingForm.date'),
                                    width: 200,
                                    align: 'center',
                                    render: (_, record) => (
                                        <>
                                            {moment(moment.utc(record.customPlaceCrossing.timestamp).toDate()).format(
                                                'L LT'
                                            )}
                                        </>
                                    )
                                },
                                {
                                    title: t('common.name'),
                                    width: 170,
                                    className: 'allowance-detail-actions-card-pow-address',
                                    align: 'left',
                                    render: (_, record) => (
                                        <Tooltip title={record.customPlaceAddress ?? ''} placement="bottomLeft">
                                            {record.customPlaceName ?? ''}
                                        </Tooltip>
                                    )
                                },
                                {
                                    title: '',
                                    width: 25,
                                    align: 'right',
                                    render: (_, record) => (
                                        <Button
                                            type="link"
                                            disabled={this.props.demoMode}
                                            icon={<img src="/img-svg/icn-24-delete.svg" alt="delete" />}
                                            data-qa={qa.allowances.detail.btnRemovePlaceCrossing}
                                            onClick={() => this.onRemovePlaceOfWorkCrossingClick(record.id)}
                                        />
                                    )
                                }
                            ]}
                        />
                    </>
                )}
                <br />
                {this.state.removeBorderCrossing?.visible && (
                    <Confirm
                        danger
                        type={MessageType.WARNING}
                        message={t('Allowance.actions.removeBorderCrossingConfirm')}
                        confirmLabel={this.props.t('common.delete')}
                        onConfirm={this.onRemoveBorderCrossingConfirm}
                        onCancel={this.onRemoveCrossingCancel}
                    />
                )}
                {this.state.removePlaceOfWorkCrossing?.visible && (
                    <Confirm
                        danger
                        type={MessageType.WARNING}
                        message={t('Allowance.actions.removeBorderCrossingConfirm')}
                        confirmLabel={this.props.t('common.delete')}
                        onConfirm={this.onRemovePlaceOfWorkCrossingConfirm}
                        onCancel={this.onRemovePlaceOfWorkCrossingCancel}
                    />
                )}
            </div>
        );
    }

    private _card = <T extends string | number>(
        iconUrl: string,
        title: string,
        content: [key: string, val: string][],
        onRemove?: (id: T) => void,
        id?: T
    ) => {
        return (
            <div className="allowance-detail-actions-card">
                <div className="allowance-detail-actions-card-img">
                    <img alt="place" src={iconUrl} />
                </div>
                <div className="allowance-detail-actions-card-content">
                    <div className="allowance-detail-actions-card-title">{title}</div>
                    {content.map((row, index) => (
                        <div
                            key={`diems-card-content-${index}`}
                            className="allowance-detail-actions-card-key-value-pair"
                        >
                            <span className="key">{row[0]}: </span> <span className="value">{row[1]}</span>
                        </div>
                    ))}
                </div>
                {onRemove && id && (
                    <div className="allowance-detail-actions-card-remove">
                        <Button
                            type="link"
                            disabled={this.props.demoMode}
                            icon={<img src="/img-svg/icn-24-delete.svg" alt="delete" />}
                            data-qa={qa.allowances.detail.btnRemovePlaceCrossing}
                            onClick={() => onRemove(id)}
                        />
                    </div>
                )}
            </div>
        );
    };

    private _loadBorderCrossings = () => {
        this.props.logic
            .borderCrossing()
            .getManualCrossings(
                this.props.userId,
                moment(this.props.dateFrom, DATE_FORMAT).startOf('day').toDate(),
                moment(this.props.dateTo, DATE_FORMAT).add(1, 'days').toDate()
            );
    };

    private _loadPlaceOfWorkCrossings = () => {
        this.props.logic
            .poi()
            .getPlaceOfWorkCrossing(
                [this.props.userId],
                moment(this.props.dateFrom, DATE_FORMAT).startOf('day').toDate(),
                moment(this.props.dateTo, DATE_FORMAT).add(1, 'days').toDate()
            );
    };

    private onRemovePlaceOfWorkCrossingConfirm = () => {
        const id = this.state.removePlaceOfWorkCrossing?.id;
        if (id) {
            this.props.logic
                .poi()
                .deleteManualPlaceOfWorkCrossing(id)
                .then(() => {
                    message.success(this.props.t('Allowance.message.removeCrossingSuccess'));
                    this._loadPlaceOfWorkCrossings();
                })
                .catch(err => {
                    message.error(this.props.t('Allowance.message.removeCrossingError'));
                    console.error(`Can not remove border crossing, err: ${err}`);
                })
                .finally(() => {
                    this.setState({
                        removePlaceOfWorkCrossing: undefined
                    });
                });
        }
    };

    private onRemovePlaceOfWorkCrossingCancel = () => {
        this.setState({
            removePlaceOfWorkCrossing: undefined
        });
    };

    private onRemovePlaceOfWorkCrossingClick = (id: string) => {
        this.setState({
            removePlaceOfWorkCrossing: {
                id: id,
                visible: true
            }
        });
    };

    private onRemoveBorderCrossingConfirm = () => {
        const id = this.state.removeBorderCrossing?.id;
        if (id) {
            this.props.logic
                .borderCrossing()
                .deleteManualCrossing(id)
                .then(() => {
                    message.success(this.props.t('Allowance.message.removeCrossingSuccess'));
                    this._loadBorderCrossings();
                })
                .catch(err => {
                    message.error(this.props.t('Allowance.message.removeCrossingError'));
                    console.error(`Can not remove border crossing, err: ${err}`);
                })
                .finally(() => {
                    this.setState({
                        removeBorderCrossing: undefined
                    });
                });
        }
    };

    private onRemoveCrossingCancel = () => {
        this.setState({
            removeBorderCrossing: undefined
        });
    };

    private onRemoveCrossingClick = (id: string) => {
        this.setState({
            removeBorderCrossing: {
                id: id,
                visible: true
            }
        });
    };
}

export default withLogicContext(withTranslation()(AllowanceDetailActions));
