/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyPUESCSentDocumentSerializer
 */
export interface WriteOnlyPUESCSentDocumentSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    originator: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    originatorId: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    documentRaw: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    documentName: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    documentCancel?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    documentCancelReplaceXpathRead?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    documentCancelReplaceXpathWrite?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    sentResponseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    responseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    responseDocument?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    sentRefNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    error?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    state?: WriteOnlyPUESCSentDocumentSerializerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    errorCode?: WriteOnlyPUESCSentDocumentSerializerErrorCodeEnum;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    errorDetails?: object | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyPUESCSentDocumentSerializer
     */
    deleted: number;
}

export function WriteOnlyPUESCSentDocumentSerializerFromJSON(json: any): WriteOnlyPUESCSentDocumentSerializer {
    return WriteOnlyPUESCSentDocumentSerializerFromJSONTyped(json, false);
}

export function WriteOnlyPUESCSentDocumentSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyPUESCSentDocumentSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'originator': json['originator'],
        'originatorId': json['originator_id'],
        'documentRaw': json['document_raw'],
        'documentName': json['document_name'],
        'documentCancel': !exists(json, 'document_cancel') ? undefined : json['document_cancel'],
        'documentCancelReplaceXpathRead': !exists(json, 'document_cancel_replace_xpath_read') ? undefined : json['document_cancel_replace_xpath_read'],
        'documentCancelReplaceXpathWrite': !exists(json, 'document_cancel_replace_xpath_write') ? undefined : json['document_cancel_replace_xpath_write'],
        'sentResponseRaw': !exists(json, 'sent_response_raw') ? undefined : json['sent_response_raw'],
        'responseRaw': !exists(json, 'response_raw') ? undefined : json['response_raw'],
        'responseDocument': !exists(json, 'response_document') ? undefined : json['response_document'],
        'sentRefNumber': !exists(json, 'sent_ref_number') ? undefined : json['sent_ref_number'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'errorCode': !exists(json, 'error_code') ? undefined : json['error_code'],
        'errorDetails': !exists(json, 'error_details') ? undefined : json['error_details'],
        'deleted': json['deleted'],
    };
}

export function WriteOnlyPUESCSentDocumentSerializerToJSON(value?: WriteOnlyPUESCSentDocumentSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'originator': value.originator,
        'originator_id': value.originatorId,
        'document_raw': value.documentRaw,
        'document_name': value.documentName,
        'document_cancel': value.documentCancel,
        'document_cancel_replace_xpath_read': value.documentCancelReplaceXpathRead,
        'document_cancel_replace_xpath_write': value.documentCancelReplaceXpathWrite,
        'sent_response_raw': value.sentResponseRaw,
        'response_raw': value.responseRaw,
        'response_document': value.responseDocument,
        'sent_ref_number': value.sentRefNumber,
        'error': value.error,
        'state': value.state,
        'error_code': value.errorCode,
        'error_details': value.errorDetails,
        'deleted': value.deleted,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyPUESCSentDocumentSerializerStateEnum {
    Draft = 'draft',
    New = 'new',
    Sent = 'sent',
    Done = 'done'
}
/**
* @export
* @enum {string}
*/
export enum WriteOnlyPUESCSentDocumentSerializerErrorCodeEnum {
    GENERICERROR = 'GENERIC_ERROR'
}


