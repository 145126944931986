/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20029,
    InlineResponse20029FromJSON,
    InlineResponse20029ToJSON,
    InlineResponse20030,
    InlineResponse20030FromJSON,
    InlineResponse20030ToJSON,
    InlineResponse20031,
    InlineResponse20031FromJSON,
    InlineResponse20031ToJSON,
    InlineResponse20032,
    InlineResponse20032FromJSON,
    InlineResponse20032ToJSON,
    MonitoredObject,
    MonitoredObjectFromJSON,
    MonitoredObjectToJSON,
    MonitoredObjectNested,
    MonitoredObjectNestedFromJSON,
    MonitoredObjectNestedToJSON,
    MonitoredObjectOdometersList,
    MonitoredObjectOdometersListFromJSON,
    MonitoredObjectOdometersListToJSON,
    MonitoredObjectPartialUpdate,
    MonitoredObjectPartialUpdateFromJSON,
    MonitoredObjectPartialUpdateToJSON,
    MonitoredObjectToUser,
    MonitoredObjectToUserFromJSON,
    MonitoredObjectToUserToJSON,
    NumberList,
    NumberListFromJSON,
    NumberListToJSON,
    ReadOnlyMonitoredObject,
    ReadOnlyMonitoredObjectFromJSON,
    ReadOnlyMonitoredObjectToJSON,
    ReadOnlyMonitoredObjectFeFleet,
    ReadOnlyMonitoredObjectFeFleetFromJSON,
    ReadOnlyMonitoredObjectFeFleetToJSON,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectFeSbFromJSON,
    ReadOnlyMonitoredObjectFeSbToJSON,
    ReadOnlyMonitoredObjectNested,
    ReadOnlyMonitoredObjectNestedFromJSON,
    ReadOnlyMonitoredObjectNestedToJSON,
    SafeDeleteResponse,
    SafeDeleteResponseFromJSON,
    SafeDeleteResponseToJSON,
    WriteOnlyMonitoredObject,
    WriteOnlyMonitoredObjectFromJSON,
    WriteOnlyMonitoredObjectToJSON,
} from '../models';

export interface MonitoredObjectBackendMoBackendApiAvsApiRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    externalId?: string;
    externalSystem?: string;
    serviceRecordsDateGte?: string;
    serviceRecordsDateLte?: string;
    all?: boolean;
    includeShared?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    includeDisabled?: boolean;
    idIn?: string;
    metadataTrailerIdSerialNumber?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectBulkPartialUpdateRequest {
    data: Array<MonitoredObjectPartialUpdate>;
}

export interface MonitoredObjectCreateRequest {
    data: WriteOnlyMonitoredObject;
    onConflict?: string;
}

export interface MonitoredObjectDeleteRequest {
    id: number;
}

export interface MonitoredObjectFeMoFeFleetRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    externalId?: string;
    externalSystem?: string;
    serviceRecordsDateGte?: string;
    serviceRecordsDateLte?: string;
    all?: boolean;
    includeShared?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    includeDisabled?: boolean;
    idIn?: string;
    metadataTrailerIdSerialNumber?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectFeMoFeFleetOneRequest {
    id: number;
}

export interface MonitoredObjectFeMoFeSbRequest {
    all?: boolean;
    includeShared?: boolean;
    includeDisabled?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    orderBy?: string;
}

export interface MonitoredObjectGetMonitoredObjectsNotForwardedRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    externalId?: string;
    externalSystem?: string;
    serviceRecordsDateGte?: string;
    serviceRecordsDateLte?: string;
    all?: boolean;
    includeShared?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    includeDisabled?: boolean;
    idIn?: string;
    metadataTrailerIdSerialNumber?: string;
}

export interface MonitoredObjectGroupUsersRequest {
    monitoredObjectId: Array<number>;
}

export interface MonitoredObjectListRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    externalId?: string;
    externalSystem?: string;
    serviceRecordsDateGte?: string;
    serviceRecordsDateLte?: string;
    all?: boolean;
    includeShared?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    includeDisabled?: boolean;
    idIn?: string;
    metadataTrailerIdSerialNumber?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectNestedRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    externalId?: string;
    externalSystem?: string;
    serviceRecordsDateGte?: string;
    serviceRecordsDateLte?: string;
    all?: boolean;
    includeShared?: boolean;
    obuPermissions?: string;
    monitoredObjectTypes?: string;
    includeDisabled?: boolean;
    idIn?: string;
    metadataTrailerIdSerialNumber?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectNestedSingleRequest {
    id: number;
}

export interface MonitoredObjectPartialUpdateRequest {
    id: number;
    data: MonitoredObjectPartialUpdate;
}

export interface MonitoredObjectReadRequest {
    id: number;
}

export interface MonitoredObjectReplaceMonitoredObjectGroupsRequest {
    id: number;
    data: NumberList;
}

export interface MonitoredObjectUnpairMonitoringDeviceRequest {
    id: number;
    data: object;
}

export interface MonitoredObjectUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObject;
}

export interface MonitoredObjectUpdateMobjsOdometersRequest {
    data: Array<MonitoredObjectOdometersList>;
}

/**
 * no description
 */
export class MonitoredObjectApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectBackendMoBackendApiAvsApiRaw(requestParameters: MonitoredObjectBackendMoBackendApiAvsApiRequest): Promise<runtime.ApiResponse<InlineResponse20030>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.serviceRecordsDateGte !== undefined) {
            queryParameters['service_records__date__gte'] = requestParameters.serviceRecordsDateGte;
        }

        if (requestParameters.serviceRecordsDateLte !== undefined) {
            queryParameters['service_records__date__lte'] = requestParameters.serviceRecordsDateLte;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.metadataTrailerIdSerialNumber !== undefined) {
            queryParameters['metadata__trailer_id_serial_number'] = requestParameters.metadataTrailerIdSerialNumber;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/backend/actual-vehicle-state-api/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20030FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectBackendMoBackendApiAvsApi(requestParameters: MonitoredObjectBackendMoBackendApiAvsApiRequest): Promise<InlineResponse20030> {
        const response = await this.monitoredObjectBackendMoBackendApiAvsApiRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectBulkPartialUpdateRaw(requestParameters: MonitoredObjectBulkPartialUpdateRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectNested>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectBulkPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/bulk/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(MonitoredObjectPartialUpdateToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectNestedFromJSON));
    }

    /**
     */
    async monitoredObjectBulkPartialUpdate(requestParameters: MonitoredObjectBulkPartialUpdateRequest): Promise<Array<MonitoredObjectNested>> {
        const response = await this.monitoredObjectBulkPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectCreateRaw(requestParameters: MonitoredObjectCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onConflict !== undefined) {
            queryParameters['on_conflict'] = requestParameters.onConflict;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectCreate(requestParameters: MonitoredObjectCreateRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectDeleteRaw(requestParameters: MonitoredObjectDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async monitoredObjectDelete(requestParameters: MonitoredObjectDeleteRequest): Promise<void> {
        await this.monitoredObjectDeleteRaw(requestParameters);
    }

    /**
     */
    async monitoredObjectFeMoFeFleetRaw(requestParameters: MonitoredObjectFeMoFeFleetRequest): Promise<runtime.ApiResponse<InlineResponse20031>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.serviceRecordsDateGte !== undefined) {
            queryParameters['service_records__date__gte'] = requestParameters.serviceRecordsDateGte;
        }

        if (requestParameters.serviceRecordsDateLte !== undefined) {
            queryParameters['service_records__date__lte'] = requestParameters.serviceRecordsDateLte;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.metadataTrailerIdSerialNumber !== undefined) {
            queryParameters['metadata__trailer_id_serial_number'] = requestParameters.metadataTrailerIdSerialNumber;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/fe/fleet/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20031FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectFeMoFeFleet(requestParameters: MonitoredObjectFeMoFeFleetRequest): Promise<InlineResponse20031> {
        const response = await this.monitoredObjectFeMoFeFleetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectFeMoFeFleetOneRaw(requestParameters: MonitoredObjectFeMoFeFleetOneRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectFeFleet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectFeMoFeFleetOne.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/fe/fleet/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFeFleetFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectFeMoFeFleetOne(requestParameters: MonitoredObjectFeMoFeFleetOneRequest): Promise<ReadOnlyMonitoredObjectFeFleet> {
        const response = await this.monitoredObjectFeMoFeFleetOneRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectFeMoFeSbRaw(requestParameters: MonitoredObjectFeMoFeSbRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMonitoredObjectFeSb>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/fe/filters/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMonitoredObjectFeSbFromJSON));
    }

    /**
     */
    async monitoredObjectFeMoFeSb(requestParameters: MonitoredObjectFeMoFeSbRequest): Promise<Array<ReadOnlyMonitoredObjectFeSb>> {
        const response = await this.monitoredObjectFeMoFeSbRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGetMonitoredObjectsNotForwardedRaw(requestParameters: MonitoredObjectGetMonitoredObjectsNotForwardedRequest): Promise<runtime.ApiResponse<NumberList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.serviceRecordsDateGte !== undefined) {
            queryParameters['service_records__date__gte'] = requestParameters.serviceRecordsDateGte;
        }

        if (requestParameters.serviceRecordsDateLte !== undefined) {
            queryParameters['service_records__date__lte'] = requestParameters.serviceRecordsDateLte;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.metadataTrailerIdSerialNumber !== undefined) {
            queryParameters['metadata__trailer_id_serial_number'] = requestParameters.metadataTrailerIdSerialNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/dff-not-forwarded/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NumberListFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGetMonitoredObjectsNotForwarded(requestParameters: MonitoredObjectGetMonitoredObjectsNotForwardedRequest): Promise<NumberList> {
        const response = await this.monitoredObjectGetMonitoredObjectsNotForwardedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupUsersRaw(requestParameters: MonitoredObjectGroupUsersRequest): Promise<runtime.ApiResponse<MonitoredObjectToUser>> {
        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling monitoredObjectGroupUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/group-users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectToUserFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupUsers(requestParameters: MonitoredObjectGroupUsersRequest): Promise<MonitoredObjectToUser> {
        const response = await this.monitoredObjectGroupUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectListRaw(requestParameters: MonitoredObjectListRequest): Promise<runtime.ApiResponse<InlineResponse20029>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.serviceRecordsDateGte !== undefined) {
            queryParameters['service_records__date__gte'] = requestParameters.serviceRecordsDateGte;
        }

        if (requestParameters.serviceRecordsDateLte !== undefined) {
            queryParameters['service_records__date__lte'] = requestParameters.serviceRecordsDateLte;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.metadataTrailerIdSerialNumber !== undefined) {
            queryParameters['metadata__trailer_id_serial_number'] = requestParameters.metadataTrailerIdSerialNumber;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20029FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectList(requestParameters: MonitoredObjectListRequest): Promise<InlineResponse20029> {
        const response = await this.monitoredObjectListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectNestedRaw(requestParameters: MonitoredObjectNestedRequest): Promise<runtime.ApiResponse<InlineResponse20032>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.serviceRecordsDateGte !== undefined) {
            queryParameters['service_records__date__gte'] = requestParameters.serviceRecordsDateGte;
        }

        if (requestParameters.serviceRecordsDateLte !== undefined) {
            queryParameters['service_records__date__lte'] = requestParameters.serviceRecordsDateLte;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.includeShared !== undefined) {
            queryParameters['include_shared'] = requestParameters.includeShared;
        }

        if (requestParameters.obuPermissions !== undefined) {
            queryParameters['obu_permissions'] = requestParameters.obuPermissions;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.metadataTrailerIdSerialNumber !== undefined) {
            queryParameters['metadata__trailer_id_serial_number'] = requestParameters.metadataTrailerIdSerialNumber;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20032FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectNested(requestParameters: MonitoredObjectNestedRequest): Promise<InlineResponse20032> {
        const response = await this.monitoredObjectNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectNestedSingleRaw(requestParameters: MonitoredObjectNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/nested/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectNestedFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectNestedSingle(requestParameters: MonitoredObjectNestedSingleRequest): Promise<ReadOnlyMonitoredObjectNested> {
        const response = await this.monitoredObjectNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectPartialUpdateRaw(requestParameters: MonitoredObjectPartialUpdateRequest): Promise<runtime.ApiResponse<MonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectPartialUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectPartialUpdate(requestParameters: MonitoredObjectPartialUpdateRequest): Promise<MonitoredObject> {
        const response = await this.monitoredObjectPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectReadRaw(requestParameters: MonitoredObjectReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectRead(requestParameters: MonitoredObjectReadRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectReplaceMonitoredObjectGroupsRaw(requestParameters: MonitoredObjectReplaceMonitoredObjectGroupsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectReplaceMonitoredObjectGroups.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectReplaceMonitoredObjectGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/replace-monitored-object-groups/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NumberListToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async monitoredObjectReplaceMonitoredObjectGroups(requestParameters: MonitoredObjectReplaceMonitoredObjectGroupsRequest): Promise<void> {
        await this.monitoredObjectReplaceMonitoredObjectGroupsRaw(requestParameters);
    }

    /**
     */
    async monitoredObjectUnpairMonitoringDeviceRaw(requestParameters: MonitoredObjectUnpairMonitoringDeviceRequest): Promise<runtime.ApiResponse<SafeDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectUnpairMonitoringDevice.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectUnpairMonitoringDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/unpair-monitoring-device/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SafeDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectUnpairMonitoringDevice(requestParameters: MonitoredObjectUnpairMonitoringDeviceRequest): Promise<SafeDeleteResponse> {
        const response = await this.monitoredObjectUnpairMonitoringDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectUpdateRaw(requestParameters: MonitoredObjectUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectUpdate(requestParameters: MonitoredObjectUpdateRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectUpdateMobjsOdometersRaw(requestParameters: MonitoredObjectUpdateMobjsOdometersRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectUpdateMobjsOdometers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/update-mobjs-odometers/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(MonitoredObjectOdometersListToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async monitoredObjectUpdateMobjsOdometers(requestParameters: MonitoredObjectUpdateMobjsOdometersRequest): Promise<void> {
        await this.monitoredObjectUpdateMobjsOdometersRaw(requestParameters);
    }

}
