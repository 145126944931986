import { Row, Col } from 'antd';
import cn from 'classnames';
import { chevronLeft } from 'resources/images/common';

interface Props {
    order?: 'ascend' | 'descend';
}

function Sorter(props: Props) {
    return (
        <div className="sorter">
            <Row>
                <Col>
                    <img
                        className={cn('sorter-up', { 'sorter-active': props.order === 'ascend' })}
                        src={chevronLeft}
                        alt="up"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <img
                        className={cn('sorter-down', { 'sorter-active': props.order === 'descend' })}
                        src={chevronLeft}
                        alt="down"
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Sorter;
