import { Component, ChangeEvent, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CcrModel, CcrModelInput } from '../DriversCardsRemoteMemoryModule';
import { FormValidator, StringValidator } from 'validators';

import * as ccrIcons from 'resources/images/ccr';
import { Button, Tooltip } from 'common/components';
import { Input } from 'common/components/Form';
import { Row, Col } from 'antd';

interface CcrFormValidator {
    serialNumber: string;
}

const nameValidator = new StringValidator(
    {
        required: true,
        regexp: /^\w+( +\w+)*$/
    },
    { required: 'required' }
);

const formValidator = new FormValidator<CcrFormValidator>().addValidator('serialNumber', nameValidator);

const defaultValidationErrors = {
    serialNumberErr: ''
};

interface Props extends WithTranslation {
    data?: CcrModel[];
    onAdd?: (input: CcrModelInput) => void;
    onRemove?: (id: string) => void;
}

interface State {
    input: CcrModelInput;
    serialNumberErr: string;
}

class DriverCardsRemoteMemoryCcrTable extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultValidationErrors,
            input: {
                serialNumber: ''
            }
        };
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <form onSubmit={this._onAdd}>
                    <Row gutter={16} className="drivers-cards-remote-memory-ccr-card-add">
                        <Col xs={16}>
                            <Input
                                value={this.state.input.serialNumber}
                                type="text"
                                size="large"
                                onChange={this._onAddCcrChange}
                                placeholder={t('DriverCardsRemoteMemoryCcrTable.typeCCR')}
                            />
                            {this.state.serialNumberErr && (
                                <span className="t-bold t-text-danger">
                                    {t('validator.' + this.state.serialNumberErr)}
                                </span>
                            )}
                        </Col>
                        <Col xs={8}>
                            <Button
                                className="drivers-cards-remote-memory-ccr-card-button"
                                type="primary"
                                htmlType="submit"
                            >
                                {t('common.add')}
                            </Button>
                        </Col>
                    </Row>
                </form>

                {this.props.data?.map(ccr => (
                    <Row gutter={16} align="middle" key={ccr.id} className="drivers-cards-remote-memory-ccr-card">
                        <Col xs={16} className="drivers-cards-remote-memory-ccr-card-item">
                            <Tooltip
                                title={`${ccr.serialNumber} - ${t(`DriverCardsRemoteMemoryCcrTable.${ccr.state}`)}`}
                                key={ccr.id}
                            >
                                <img
                                    className="drivers-cards-remote-memory-ccr-card-icon"
                                    src={ccrIcons[`${ccr.state}Icon`]}
                                    alt=""
                                    title={ccr.serialNumber}
                                />
                                <span className="drivers-cards-number">{ccr.serialNumber}</span>
                            </Tooltip>
                        </Col>
                        <Col xs={8}>
                            <Button
                                type="default"
                                className="drivers-cards-remote-memory-ccr-card-button"
                                onClick={this._onRemove.bind(undefined, ccr.id)}
                            >
                                {t('common.remove')}
                            </Button>
                        </Col>
                    </Row>
                ))}
            </>
        );
    }

    private _onRemove = (id: string) => {
        this.props.onRemove?.(id);
    };

    private _onAddCcrChange = (event: ChangeEvent) => {
        const value = (event.target as HTMLInputElement).value;
        const validateRes = nameValidator.validate(value);

        this.setState({
            input: {
                serialNumber: value
            },
            serialNumberErr: validateRes.err as string
        });
    };

    private _onAdd = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationRes = formValidator.validate({
            serialNumber: this.state.input.serialNumber
        });
        if (validationRes.valid) {
            this.props.onAdd?.(this.state.input);
            this.setState({
                input: {
                    serialNumber: ''
                }
            });
        } else {
            this.setState({
                serialNumberErr: validationRes.err?.serialNumber ?? ''
            });
        }
    };
}

export default withTranslation()(DriverCardsRemoteMemoryCcrTable);
