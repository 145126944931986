import { Logic } from '../../logic';

export class UserRights {
    constructor(private _logic: Logic) {}

    public async getUserRights() {
        const userRightsResponse = await this._logic.api().userRightApi.userRightList({});

        return userRightsResponse.results;
    }
}
