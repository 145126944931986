import { Notification } from './notification-eio';
import { Events } from './events';
import { Conf } from '../conf';

export interface MessagePayload {
    type: 'image' | 'file';
    url: string;
    thumb?: string;
}

interface MsgResponse {
    message: Message;
}

interface ChannelResponse {
    channel: Channel;
}

export interface ChannelsResponse {
    channels: ChannelInfo[];
}

interface UserMessagesResponse {
    channelMessages: ChannelMessages[];
    user: string;
    from: string;
    limit: number;
}

interface ChannelMessagesResponse {
    messages: Message[];
    user: string;
    channel: string;
    from: string;
    limit: number;
}

export interface Channel {
    id: string;
    title: string;
    created: string;
    creator: string;
    users: string[];
}

export interface ChannelInfo extends Channel {
    newMsgCount: number;
    newMsgFrom: string;
}

export interface Message {
    id: string;
    channel: string;
    sender: string;
    text: string;
    created: string;
    seen?: {
        user: string;
        date: string;
    }[];
    payload?: MessagePayload[];
}

export interface ChannelMessages extends Channel {
    messages: Message[];
}

type Auth = () => Promise<string | undefined>;

export type UploadAttachmentResp = {
    thumbUrl?: string;
    url: string;
};

export class Messaging {
    private _active: boolean;
    private _uri: string;
    private _auth?: Auth;
    private _notification?: Notification;
    private _conf: Conf;

    private _onActiveChange?: () => void;

    readonly events: Events<Messaging>;

    constructor(uri: string, conf: Conf, notification?: Notification) {
        this._conf = conf;
        this._active = false;
        this._uri = uri;
        this.events = new Events<Messaging>();
        if (notification) {
            this._notification = notification;
            notification.on('messaging', message => {
                this.events.emit('message', message);
            });
            notification.on('messaging-seen', seenMessage => {
                this.events.emit('message-seen', seenMessage);
            });
            notification.on('messaging-unsee', unseeMessage => {
                this.events.emit('message-unsee', unseeMessage);
            });
            notification.on('messaging-channel-created', channelId => {
                this.events.emit('channel-created', channelId);
            });
        }
    }

    onActiveChange(cb?: () => void): void {
        this._onActiveChange = cb;
    }

    activeChange(): void {
        this._active = !this._active;
        this._onActiveChange?.();
    }

    active(): boolean {
        return this._active;
    }

    init(auth?: Auth) {
        auth && (this._auth = auth);
    }

    destroy() {
        this.events.off();
        if (this._notification) {
            this._notification.off();
        }
    }

    async channels(): Promise<ChannelsResponse> {
        const token = this._auth && (await this._auth());
        return new Promise<ChannelsResponse>((resolve, reject) => {
            fetch(`${this._uri}/channel`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(r => r.json())
                .then(r => (r.error ? reject(r.error) : resolve(r)))
                .catch(reject);
        });
    }

    async channelCreate(users: string[]): Promise<ChannelResponse> {
        const token = this._auth && (await this._auth());
        return new Promise<ChannelResponse>((resolve, reject) => {
            fetch(`${this._uri}/channel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify({
                    users
                })
            })
                .then(r => r.json())
                .then(r => (r.error ? reject(r.error) : resolve(r)))
                .catch(reject);
        });
    }

    async channelMessages(channelId: string, timestamp?: Date, count?: number): Promise<ChannelMessagesResponse> {
        const token = this._auth && (await this._auth());
        return new Promise<ChannelMessagesResponse>((resolve, reject) => {
            const channelPath = channelId ? channelId : '';
            const timestampQuery = timestamp ? 'timestamp=' + timestamp.toISOString() + '&' : '';
            const countQuery = count ? 'count=' + count : '';
            fetch(`${this._uri}/messages/${channelPath}?${timestampQuery}${countQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then(r => r.json())
                .then(r => (r.error ? reject(r.error) : resolve(r)))
                .catch(reject);
        });
    }

    async messages(from?: Date, limit?: number): Promise<UserMessagesResponse> {
        const token = this._auth && (await this._auth());
        return new Promise<UserMessagesResponse>((resolve, reject) => {
            const fromQuery = from ? 'from=' + from.toISOString() + '&' : '';
            const limitQuery = limit ? 'limit=' + limit : '';
            fetch(`${this._uri}/messages?${fromQuery}${limitQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then(r => r.json())
                .then(r => (r.error ? reject(r.error) : resolve(r)))
                .catch(reject);
        });
    }

    async sendMessage(channelId: string, text: string = '', payload?: MessagePayload[]): Promise<MsgResponse> {
        const token = this._auth && (await this._auth());
        return new Promise<MsgResponse>((resolve, reject) => {
            fetch(`${this._uri}/messages/${channelId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify({
                    text,
                    payload
                })
            })
                .then(r => r.json())
                .then(async r => (r.error ? reject(r.error) : resolve(r)))
                .catch(reject);
        });
    }

    async channelMessagesSeen(channelId: string, seenToDate: Date): Promise<number> {
        const token = this._auth && (await this._auth());
        return new Promise<number>((resolve, reject) => {
            fetch(`${this._uri}/messages/seen/${channelId}?seento=${seenToDate.toISOString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then(r => r.json())
                .then(async r => (r.error ? reject(r.error) : resolve(r.seenMessagesNumber)))
                .catch(reject);
        });
    }

    async channelMessageUnsee(channelId: string, messageId: string): Promise<string> {
        const token = this._auth && (await this._auth());
        return new Promise<string>((resolve, reject) => {
            fetch(`${this._uri}/messages/unsee/${channelId}/${messageId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then(r => r.json())
                .then(async r => {
                    return r.error ? reject(r.error) : resolve(r.unseeMessageNumber);
                })
                .catch(reject);
        });
    }

    uploadAttachment = async (attachment: File | Blob): Promise<UploadAttachmentResp> => {
        const formData = new FormData();
        formData.append('file', attachment);
        const token = this._auth && (await this._auth());

        return new Promise((resolve, reject) => {
            fetch(`${this._conf.notification.engineio.uri}/notification/api/attachment/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(resp => resp.json())
                .then(resp => {
                    resolve(resp);
                })
                .catch(e => reject(e));
        });
    };
}
