/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleExternalDevices
 */
export interface VehicleExternalDevices {
    /**
     * 
     * @type {string}
     * @memberof VehicleExternalDevices
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleExternalDevices
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleExternalDevices
     */
    tfrom?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleExternalDevices
     */
    serialNumber?: string;
    /**
     * 
     * @type {object}
     * @memberof VehicleExternalDevices
     */
    data?: object;
    /**
     * 
     * @type {object}
     * @memberof VehicleExternalDevices
     */
    metadata?: object;
}

export function VehicleExternalDevicesFromJSON(json: any): VehicleExternalDevices {
    return VehicleExternalDevicesFromJSONTyped(json, false);
}

export function VehicleExternalDevicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleExternalDevices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'deviceType': !exists(json, 'device_type') ? undefined : json['device_type'],
        'tfrom': !exists(json, 'tfrom') ? undefined : json['tfrom'],
        'serialNumber': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function VehicleExternalDevicesToJSON(value?: VehicleExternalDevices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'device_type': value.deviceType,
        'tfrom': value.tfrom,
        'serial_number': value.serialNumber,
        'data': value.data,
        'metadata': value.metadata,
    };
}


