/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum LegDataFields {
    Lon = 'lon',
    Lat = 'lat',
    Speeds = 'speeds',
    FuelLevels = 'fuel_levels',
    Polyline = 'polyline',
    Timestamps = 'timestamps'
}

export function LegDataFieldsFromJSON(json: any): LegDataFields {
    return LegDataFieldsFromJSONTyped(json, false);
}

export function LegDataFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegDataFields {
    return json as LegDataFields;
}

export function LegDataFieldsToJSON(value?: LegDataFields | null): any {
    return value as any;
}

