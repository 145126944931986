/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LibModelsActivityIntervalExternalDevice,
    LibModelsActivityIntervalExternalDeviceFromJSON,
    LibModelsActivityIntervalExternalDeviceFromJSONTyped,
    LibModelsActivityIntervalExternalDeviceToJSON,
    LibModelsActivityIntervalLive,
    LibModelsActivityIntervalLiveFromJSON,
    LibModelsActivityIntervalLiveFromJSONTyped,
    LibModelsActivityIntervalLiveToJSON,
    LibModelsDriverInfoDriver,
    LibModelsDriverInfoDriverFromJSON,
    LibModelsDriverInfoDriverFromJSONTyped,
    LibModelsDriverInfoDriverToJSON,
    LibModelsEdit,
    LibModelsEditFromJSON,
    LibModelsEditFromJSONTyped,
    LibModelsEditToJSON,
} from './';

/**
 * 
 * @export
 * @interface LibModelsActivityInterval
 */
export interface LibModelsActivityInterval {
    /**
     * 
     * @type {LibModelsEdit}
     * @memberof LibModelsActivityInterval
     */
    edit?: LibModelsEdit;
    /**
     * 
     * @type {Array<LibModelsEdit>}
     * @memberof LibModelsActivityInterval
     */
    edits?: Array<LibModelsEdit>;
    /**
     * 
     * @type {string}
     * @memberof LibModelsActivityInterval
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof LibModelsActivityInterval
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityInterval
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityInterval
     */
    stop?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityInterval
     */
    lastUpdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof LibModelsActivityInterval
     */
    activityIntervalId?: string;
    /**
     * 
     * @type {Array<LibModelsActivityIntervalExternalDevice>}
     * @memberof LibModelsActivityInterval
     */
    externalDevices?: Array<LibModelsActivityIntervalExternalDevice>;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityInterval
     */
    externalDevicesLastTs?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LibModelsActivityInterval
     */
    _private?: boolean;
    /**
     * 
     * @type {LibModelsDriverInfoDriver}
     * @memberof LibModelsActivityInterval
     */
    driver?: LibModelsDriverInfoDriver;
    /**
     * 
     * @type {Array<LibModelsDriverInfoDriver>}
     * @memberof LibModelsActivityInterval
     */
    passengers?: Array<LibModelsDriverInfoDriver>;
    /**
     * 
     * @type {LibModelsDriverInfoDriver}
     * @memberof LibModelsActivityInterval
     */
    defaultDriver?: LibModelsDriverInfoDriver;
    /**
     * 
     * @type {LibModelsActivityIntervalLive}
     * @memberof LibModelsActivityInterval
     */
    liveData?: LibModelsActivityIntervalLive;
    /**
     * 
     * @type {Array<Date | number>}
     * @memberof LibModelsActivityInterval
     */
    positionStart?: Array<Date | number>;
    /**
     * 
     * @type {Array<Date | number>}
     * @memberof LibModelsActivityInterval
     */
    positionStop?: Array<Date | number>;
    /**
     * 
     * @type {number}
     * @memberof LibModelsActivityInterval
     */
    averageSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof LibModelsActivityInterval
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof LibModelsActivityInterval
     */
    lastCarBatteryVolts?: number;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityInterval
     */
    lastCarBatteryTimestamp?: Date;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LibModelsActivityInterval
     */
    lastFuelLevelByTank?: { [key: string]: object; };
}

export function LibModelsActivityIntervalFromJSON(json: any): LibModelsActivityInterval {
    return LibModelsActivityIntervalFromJSONTyped(json, false);
}

export function LibModelsActivityIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): LibModelsActivityInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'edit': !exists(json, 'edit') ? undefined : LibModelsEditFromJSON(json['edit']),
        'edits': !exists(json, 'edits') ? undefined : ((json['edits'] as Array<any>).map(LibModelsEditFromJSON)),
        'monitoredObjectId': json['monitored_object_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'start': (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
        'lastUpdate': !exists(json, 'last_update') ? undefined : (new Date(json['last_update'])),
        'activityIntervalId': !exists(json, 'activity_interval_id') ? undefined : json['activity_interval_id'],
        'externalDevices': !exists(json, 'external_devices') ? undefined : ((json['external_devices'] as Array<any>).map(LibModelsActivityIntervalExternalDeviceFromJSON)),
        'externalDevicesLastTs': !exists(json, 'external_devices_last_ts') ? undefined : (new Date(json['external_devices_last_ts'])),
        '_private': !exists(json, 'private') ? undefined : json['private'],
        'driver': !exists(json, 'driver') ? undefined : LibModelsDriverInfoDriverFromJSON(json['driver']),
        'passengers': !exists(json, 'passengers') ? undefined : ((json['passengers'] as Array<any>).map(LibModelsDriverInfoDriverFromJSON)),
        'defaultDriver': !exists(json, 'default_driver') ? undefined : LibModelsDriverInfoDriverFromJSON(json['default_driver']),
        'liveData': !exists(json, 'live_data') ? undefined : LibModelsActivityIntervalLiveFromJSON(json['live_data']),
        'positionStart': !exists(json, 'position_start') ? undefined : json['position_start'],
        'positionStop': !exists(json, 'position_stop') ? undefined : json['position_stop'],
        'averageSpeed': !exists(json, 'average_speed') ? undefined : json['average_speed'],
        'maxSpeed': !exists(json, 'max_speed') ? undefined : json['max_speed'],
        'lastCarBatteryVolts': !exists(json, 'last_car_battery_volts') ? undefined : json['last_car_battery_volts'],
        'lastCarBatteryTimestamp': !exists(json, 'last_car_battery_timestamp') ? undefined : (new Date(json['last_car_battery_timestamp'])),
        'lastFuelLevelByTank': !exists(json, 'last_fuel_level_by_tank') ? undefined : json['last_fuel_level_by_tank'],
    };
}

export function LibModelsActivityIntervalToJSON(value?: LibModelsActivityInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'edit': LibModelsEditToJSON(value.edit),
        'edits': value.edits === undefined ? undefined : ((value.edits as Array<any>).map(LibModelsEditToJSON)),
        'monitored_object_id': value.monitoredObjectId,
        'client_id': value.clientId,
        'start': (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
        'last_update': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString()),
        'activity_interval_id': value.activityIntervalId,
        'external_devices': value.externalDevices === undefined ? undefined : ((value.externalDevices as Array<any>).map(LibModelsActivityIntervalExternalDeviceToJSON)),
        'external_devices_last_ts': value.externalDevicesLastTs === undefined ? undefined : (value.externalDevicesLastTs.toISOString()),
        'private': value._private,
        'driver': LibModelsDriverInfoDriverToJSON(value.driver),
        'passengers': value.passengers === undefined ? undefined : ((value.passengers as Array<any>).map(LibModelsDriverInfoDriverToJSON)),
        'default_driver': LibModelsDriverInfoDriverToJSON(value.defaultDriver),
        'live_data': LibModelsActivityIntervalLiveToJSON(value.liveData),
        'position_start': value.positionStart,
        'position_stop': value.positionStop,
        'average_speed': value.averageSpeed,
        'max_speed': value.maxSpeed,
        'last_car_battery_volts': value.lastCarBatteryVolts,
        'last_car_battery_timestamp': value.lastCarBatteryTimestamp === undefined ? undefined : (value.lastCarBatteryTimestamp.toISOString()),
        'last_fuel_level_by_tank': value.lastFuelLevelByTank,
    };
}


