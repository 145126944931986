import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import Button from 'common/components/Button';
import { TachoCardModel } from '../../TachoCardsModule';

interface Props {
    model?: TachoCardModel;
    demoMode?: boolean;
    roles: Role[];
    onUpdateClick?: () => void;
    onPairingClick?: () => void;
    onDeleteClick?: () => void;
}

function TachoCardsActions(props: Props) {
    const { t } = useTranslation();
    const { demoMode } = props;

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={demoMode || !props.roles.includes(Role.TC_W)}
                        qa={qa.tacho.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-pencil-alt" />}
                        onClick={props.onUpdateClick}
                    >
                        {t('ManagementTachoCards.updateData')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={!!props.model?.userId || demoMode || !props.roles.includes(Role.TC_IE)}
                        icon={<i className="fas fa-fw fa-link" />}
                        qa={qa.tacho.detail.btnAddLinkedItem}
                        onClick={() => props.onPairingClick?.()}
                    >
                        {t('ManagementTachoCards.link')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={demoMode || !props.roles.includes(Role.TC_W)}
                        icon={<i className="fas fa-fw fa-trash-alt" />}
                        qa={qa.tacho.detail.btnDelete}
                        onClick={props.onDeleteClick}
                    >
                        {t('ManagementTachoCards.deleteTachoCard')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default TachoCardsActions;
