/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleAdblue
 */
export interface VehicleAdblue {
    /**
     * 
     * @type {Date}
     * @memberof VehicleAdblue
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleAdblue
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleAdblue
     */
    raw?: number;
}

export function VehicleAdblueFromJSON(json: any): VehicleAdblue {
    return VehicleAdblueFromJSONTyped(json, false);
}

export function VehicleAdblueFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleAdblue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': (new Date(json['time'])),
        'level': !exists(json, 'level') ? undefined : json['level'],
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
    };
}

export function VehicleAdblueToJSON(value?: VehicleAdblue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': (value.time.toISOString()),
        'level': value.level,
        'raw': value.raw,
    };
}


