/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectOperationalCosts,
    MonitoredObjectOperationalCostsFromJSON,
    MonitoredObjectOperationalCostsFromJSONTyped,
    MonitoredObjectOperationalCostsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectOperationalCostDefaults
 */
export interface MonitoredObjectOperationalCostDefaults {
    /**
     * 
     * @type {MonitoredObjectOperationalCosts}
     * @memberof MonitoredObjectOperationalCostDefaults
     */
    costs: MonitoredObjectOperationalCosts;
}

export function MonitoredObjectOperationalCostDefaultsFromJSON(json: any): MonitoredObjectOperationalCostDefaults {
    return MonitoredObjectOperationalCostDefaultsFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCostDefaults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costs': MonitoredObjectOperationalCostsFromJSON(json['costs']),
    };
}

export function MonitoredObjectOperationalCostDefaultsToJSON(value?: MonitoredObjectOperationalCostDefaults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costs': MonitoredObjectOperationalCostsToJSON(value.costs),
    };
}


