import { Subject } from 'rxjs';
import i18n from 'i18next';
import moment from 'moment';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { MaintenanceTask, MaintenanceTaskCompleteRequest, MaintenanceTaskList } from 'generated/new-main';
import { Logic } from 'logic/logic';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { MaintenanceTaskTableModel } from 'modules/statistics/modules/maintenance/MaintenanceModule';

export class StatisticsMaintenanceTaskLogic {
    onMaintenanceCreated = new Subject<void>();
    onMaintenanceCompleted = new Subject<void>();
    onMaintenanceDeleted = new Subject<void>();
    onMaintenanceTaskTypeDeleted = new Subject<void>();
    onMaintenanceTaskTypeEdited = new Subject<void>();

    constructor(private _logic: Logic) {}

    async getMaintenanceTasks(
        client?: string,
        includeStates?: string[],
        monitoredObjects?: number[],
        users?: number[],
        taskTypeIds?: string[],
        intervalIsDate?: boolean,
        intervalIsKm?: boolean,
        dateFrom?: string,
        dateTo?: string,
        limit: number = DEFAULT_PAGE_LIMIT,
        offset: number = DEFAULT_PAGE_OFFSET
    ) {
        try {
            const res = await this._logic.api().maintenanceTaskApi.maintenanceTaskList({
                client: client,
                includeStates,
                monitoredObjects,
                users,
                intervalIsDate,
                taskTypeIds: taskTypeIds?.length ? taskTypeIds.join() : undefined,
                intervalIsKm,
                dueDateLte: dateTo,
                dueDateGte: dateFrom,
                limit,
                offset
            });
            return {
                data: res.results,
                total: res.count,
                limit,
                offset
            };
        } catch (err) {
            console.error('Get maintenance task list err', err);
            throw err;
        }
    }

    async downloadMaintenanceExport(maintenanceTasks: MaintenanceTaskTableModel[], from?: string, to?: string) {
        const workbook: Workbook = await fromBlankAsync();
        workbook.sheet(0).name(normalizeExportSheetName(i18n.t('Maintenance.title')));

        const fromStrFormat = from ? `_${moment(from).format('L')}` : '';
        const toStrFormat = to ? `_${moment(to).format('L')}` : '';

        const companyName = this._logic.auth().client()?.name ?? i18n.t('common.unknown');
        const title = [[i18n.t('Maintenance.task.maintenanceAndServices')]];
        const description = [
            [i18n.t('common.dateFrom'), fromStrFormat],
            [i18n.t('common.dateTo'), toStrFormat],
            [
                i18n.t('common.created'),
                ` ${moment().format('L')} (${i18n.t('Maintenance.task.byUser')} ${this._logic.auth().user().name})`
            ]
        ];

        let taskStates = {
            overdue: 0,
            duesoon: 0,
            active: 0,
            done: 0
        };

        let labelsCols: string[] = [];
        let itemsCols: (string | number)[];

        const tableBody = maintenanceTasks?.map(m => {
            itemsCols = [];
            m.expenses?.items?.forEach((item, i) => {
                itemsCols.push(item.name);
                itemsCols.push(item.type);
                itemsCols.push(item.priceWoVat);
                if (i >= labelsCols.length / 3) {
                    labelsCols.push(`${i18n.t('common.item')} ${i}`);
                    labelsCols.push(`${i18n.t('common.type')}`);
                    labelsCols.push(`${i18n.t('Maintenance.task.itemPrice')} ${i}`);
                }
            });

            const overdueDays = moment.duration(moment(m.completionTime).diff(moment(m.dueDate).toDate())).asDays();
            if (m.state && taskStates[m.state] !== undefined) {
                taskStates[m.state]++;
            }

            const completedPlanned =
                m.state && ['active', 'overdue', 'duesoon'].indexOf(m.state) > -1
                    ? ''
                    : m.completionTime
                    ? moment(m.completionTime).isSameOrBefore(m.dueDate)
                        ? i18n.t('common.yes')
                        : i18n.t('common.no')
                    : i18n.t('common.no');

            return [
                m.taskType?.name,
                i18n.t(`common.${m.category}`),
                m.driver ?? m.vehicle ?? m.trailer ?? this._logic.auth().client()?.name ?? '',
                i18n.t(`Maintenance.dueFilter.${m.state}`),
                moment(m.dueDate).format('L'),
                m.completionTime ? moment(m.completionTime).format('L') : '',
                m.dueMileageKilometers,
                m.completionMileageKilometers,
                m.state && !['done', 'overdue'].includes(m.state) && m.dueDateLeftDays !== undefined
                    ? m.dueDateLeftDays === 0
                        ? i18n.t('common.today')
                        : Math.abs(m.dueDateLeftDays)
                    : '',
                m.state && !['done', 'overdue'].includes(m.state) && m.dueMileageLeftKilometers
                    ? m.dueMileageLeftKilometers
                    : '',
                completedPlanned,
                m.state === 'overdue' && m.dueDateLeftDays !== undefined
                    ? m.dueDateLeftDays === 0
                        ? i18n.t('common.today')
                        : Math.abs(m.dueDateLeftDays)
                    : m.state === 'done'
                    ? overdueDays > 0
                        ? Math.ceil(overdueDays)
                        : Math.floor(overdueDays)
                    : '',
                m.state === 'overdue' && m.dueMileageLeftKilometers !== undefined
                    ? m.dueMileageLeftKilometers
                    : m.state === 'done' && m.completionMileageKilometers && m.dueMileageKilometers
                    ? m.completionMileageKilometers - m.dueMileageKilometers
                    : '',
                m.expenses?.priceTotalWoVat,
                m.expenses?.priceTotalWoVat && m.expenses?.vat
                    ? m.expenses.priceTotalWoVat * (1 + m.expenses.vat / 100)
                    : '',
                m.expenses?.vat,
                m.expenses?.contact
                    ? this._logic
                          .maintenanceLogic()
                          .clientContactList.find(contactList => contactList.id === m.expenses?.contact)?.name
                    : ''
            ].concat(itemsCols);
        });

        let taskStatus = [
            [i18n.t('Maintenance.task.taskByStatus'), ''],
            [i18n.t('Maintenance.dueFilter.overdue'), taskStates.overdue],
            [i18n.t('Maintenance.dueFilter.duesoon'), taskStates.duesoon],
            [i18n.t('Maintenance.dueFilter.active'), taskStates.active],
            [i18n.t('Maintenance.dueFilter.done'), taskStates.done]
        ];

        const tableHeader = [
            [
                i18n.t('Maintenance.task.taskType'),
                i18n.t('Maintenance.task.taskFor'),
                i18n.t('common.subject'),
                i18n.t('common.status'),
                i18n.t('Maintenance.task.dueOnDate'),
                i18n.t('Maintenance.task.completedOnDate'),
                i18n.t('Maintenance.task.dueOnKm'),
                i18n.t('Maintenance.task.completedOnKm'),
                i18n.t('Maintenance.task.daysLeft'),
                i18n.t('Maintenance.task.kmLeft'),
                i18n.t('Maintenance.task.completedAsPlanned'),
                i18n.t('Maintenance.task.overdueDays'),
                i18n.t('Maintenance.task.overdueMileage'),
                `${i18n.t('common.priceTotal')} (${i18n.t('common.excludingVAT')})`,
                `${i18n.t('common.priceTotal')} (${i18n.t('common.includingVAT')})`,
                i18n.t('common.vat'),
                i18n.t('common.supplier')
            ].concat(labelsCols)
        ];

        workbook.sheet(0).range(1, 1, 1, 2).merged(true);
        const workbookCompanyRange = workbook.sheet(0).range(1, 1, 1, 2);
        workbook.sheet(0).range(3, 1, 3, 2).merged(true);
        const workbookTitleRange = workbook.sheet(0).range(3, 1, 3, 2);
        workbook.sheet(0).range(6, 2, 6, 3).merged(true);
        const workbookDescriptionRange = workbook.sheet(0).range(4, 1, 6, 3);
        workbook.sheet(0).range(4, 4, 4, 5).merged(true);
        const workbookStatusRange = workbook.sheet(0).range(4, 4, 8, 5);
        const workbookTableHeaderRange = workbook.sheet(0).range(10, 1, 10, tableHeader[0].length);
        const workbookTableBodyRange = workbook.sheet(0).range(11, 1, 11 + tableBody.length - 1, tableHeader[0].length);

        [20, 15, 30, 12, 15, 20, 20, 20, 15, 15, 15, 20, 20, 20, 20, 5, 20].forEach((width, i) => {
            workbook
                .sheet(0)
                .column(i + 1)
                .width(width);
        });

        const companyStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            border: {
                color: 'AAAAAA',
                style: 'thin'
            }
        };

        const titleStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            border: {
                color: 'AAAAAA',
                style: 'thin'
            },
            bold: true,
            fontSize: 14
        };

        const tableHeaderStyle = {
            fill: {
                color: 'EFEFEF',
                type: 'solid'
            },
            bold: true,
            border: {
                color: '222222',
                style: 'thin'
            }
        };

        const dataStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            border: {
                color: 'AAAAAA',
                style: 'thin'
            }
        };

        workbookCompanyRange.style(companyStyle).value(companyName);
        workbookTitleRange.style(titleStyle).value(title);
        workbookDescriptionRange.style(dataStyle).value(description);
        workbookStatusRange.style(companyStyle).value(taskStatus);
        workbookTableHeaderRange.style(tableHeaderStyle).value(tableHeader);
        workbookTableBodyRange.style(dataStyle).value(tableBody);

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('Maintenance.title')}${fromStrFormat}${toStrFormat}.xlsx`);
    }

    async getMaintenanceTaskTypes() {
        try {
            const res = await this._logic.api().maintenanceTaskTypeApi.maintenanceTaskTypeList({});
            return res.results;
        } catch (err) {
            console.error('Get maintenance task type list err', err);
            throw err;
        }
    }

    async completeMaintenanceTask(complete: MaintenanceTaskCompleteRequest): Promise<boolean> {
        try {
            await this._logic.api().maintenanceTaskApi.maintenanceTaskComplete(complete);
            this.onMaintenanceCompleted.next();
            return true;
        } catch (err) {
            console.error('Maintenance task complete err', err);
            throw err;
        }
    }

    async deleteMaintenanceTaskTypeWithTasks(id: string): Promise<boolean> {
        try {
            await this._logic.api().maintenanceTaskTypeApi.maintenanceTaskTypeDeleteWithTasks({
                data: {},
                id
            });
            this.onMaintenanceTaskTypeDeleted.next();
            return true;
        } catch (err) {
            console.error('Delete maintenance task type err', err);
            throw err;
        }
    }

    async editMaintenanceTaskType(id: string, name: string): Promise<boolean> {
        try {
            await this._logic.api().maintenanceTaskTypeApi.maintenanceTaskTypePartialUpdate({
                data: {
                    name
                },
                id
            });
            this.onMaintenanceTaskTypeEdited.next();
            return true;
        } catch (err) {
            console.error('Edit maintenance task type err', err);
            throw err;
        }
    }

    async createMaintenanceTask(maintenanceTaskList: MaintenanceTaskList): Promise<boolean> {
        try {
            await this._logic.api().maintenanceTaskApi.maintenanceTaskCreateMany({
                data: maintenanceTaskList
            });
            this.onMaintenanceCreated.next();
            return true;
        } catch (err) {
            console.error('Create many maintenance task err', err);
            throw err;
        }
    }

    async deleteMaintenanceTasks(maintenanceTasks: string[]): Promise<boolean> {
        try {
            await this._logic.api().maintenanceTaskApi.maintenanceTaskDeleteMany({
                data: {
                    maintenanceTasks
                }
            });
            this.onMaintenanceDeleted.next();
            return true;
        } catch (err) {
            console.error('Delete many maintenance task err', err);
            throw err;
        }
    }

    async updateMaintenanceTask(maintenanceTask: MaintenanceTask, id: string, forceUpdate?: boolean) {
        try {
            await this._logic.api().maintenanceTaskApi.maintenanceTaskPartialUpdate({
                data: maintenanceTask,
                id,
                forceUpdate
            });
            this.onMaintenanceCreated.next();
            return true;
        } catch (err) {
            console.error('create maintenance task err', err);
            throw err;
        }
    }

    async getMaintenanceSummary() {
        try {
            const res = await this._logic.api().maintenanceTaskApi.maintenanceTaskSummary({});
            return res;
        } catch (err) {
            console.error('Get maintenance summary err', err);
            throw err;
        }
    }
}
