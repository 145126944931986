import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'common/components';
import { TachoCardModel } from '../../TachoCardsModule';
import qa from 'qa-selectors';
import { TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';

interface Props extends WithTranslation {
    loading?: boolean;
    data?: TachoCardModel[];
    selected?: TachoCardModel;
    onRowSelect?: (id: number) => void;
}

function TachoCardsTable(props: Props) {
    const { t } = props;

    function onRowClick(id: number) {
        props.onRowSelect?.(id);
    }

    function createColumns(): ColumnsType<TachoCardModel> {
        return [
            {
                title: t('ManagementTachoCards.id'),
                className: 'token-field',
                dataIndex: 'token',
                key: 'token',
                width: 100,
                render: value => <span data-qa={qa.tacho.table.fieldToken}>{value}</span>
            },
            {
                title: t('common.name'),
                dataIndex: 'name',
                key: 'name',
                width: 100,
                render: value => <span data-qa={qa.tacho.table.fieldName}>{value}</span>
            },
            {
                title: t('ManagementTachoCards.surname'),
                dataIndex: 'surname',
                key: 'surname',
                width: 100,
                render: value => <span data-qa={qa.tacho.table.fieldSurname}>{value}</span>
            }
        ];
    }

    return (
        <Table<TachoCardModel>
            loading={props.loading}
            dataSource={props.data?.map(e => ({ ...e, key: e.id }))}
            columns={createColumns()}
            scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
            className="management-tacho-cards-table"
            onRow={record => ({
                onClick: () => onRowClick?.(record.id),
                className: cn({
                    'ant-table-row-selected': props.selected?.id === record.id
                })
            })}
        />
    );
}

export default withTranslation()(TachoCardsTable);
