import { Badge } from 'antd';
import { BadgeProps } from 'antd';

interface Props extends BadgeProps {
    qa?: string;
}

export default function EnhancedBadge(props: Props) {
    return <Badge data-qa={props.qa} {...props} />;
}
