import { starGreen, starYellow } from 'resources/images/driver-behavior';

interface Props {
    size?: number;
    color?: 'green' | 'yellow';
    qa?: string;
}

export function DriverBehaviorCoachTableStar(props: Props) {
    const style = {
        width: `${props.size ?? 20}px`
    };

    const image: string = props.color === 'yellow' ? starYellow : starGreen;

    return (
        <div data-qa={props.qa} style={{ height: '20px' }}>
            <img style={style} src={image} alt="driver-behavior-coach-star" />
        </div>
    );
}
