import { Role } from 'logic/auth';
import { State } from './MessagingModule';

const roles: Role[] = [...Object.values(Role)];

export const initialData: State = {
    active: true,
    channels: {
        channels: [
            {
                channel: {
                    title: '',
                    creator: '01501b0a-2c59-4434-9c91-3c85f133dcae',
                    created: '2020-04-09T14:16:47.057Z',
                    users: [
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2'
                    ],
                    newMsgCount: 2,
                    newMsgFrom: '2020-04-09T14:17:16.490Z',
                    id: '5e8f2e4fa8fd0d00132bf31d'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '43b5424c-50be-42ca-8992-9898d3748e3a',
                    created: '2020-04-17T08:50:43.552Z',
                    users: [
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5e996de344cc7900132d34a3'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                    created: '2020-04-17T10:32:39.392Z',
                    users: [
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d'
                    ],
                    newMsgCount: 2,
                    newMsgFrom: '2020-04-17T11:26:49.463Z',
                    id: '5e9985c744cc7900132d3c30'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'dae22d5a-b523-438f-b595-678d28552d59',
                    created: '2020-04-17T11:45:29.387Z',
                    users: [
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-17T11:48:13.815Z',
                    id: '5e9996d944cc7900132d401f'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                    created: '2020-04-17T11:54:04.255Z',
                    users: [
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5e9998dc44cc7900132d405b'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                    created: '2020-04-21T13:09:40.556Z',
                    users: [
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-21T13:10:36.937Z',
                    id: '5e9ef094a3f84800137c9d19'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '2651789f-98a8-4fba-a917-527541364834',
                    created: '2020-04-22T06:41:46.112Z',
                    users: [
                        '2651789f-98a8-4fba-a917-527541364834',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5e9fe72aa3f84800137cc7b9'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                    created: '2020-04-24T14:18:30.607Z',
                    users: [
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                        '2651789f-98a8-4fba-a917-527541364834'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-24T14:18:47.458Z',
                    id: '5ea2f536be866300136d4512'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                    created: '2020-05-19T11:10:21.717Z',
                    users: [
                        'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5ec3be9db438e6001414a782'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '996',
                    ssoId: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                    name: 'Michal',
                    note: '',
                    surname: 'Roháček',
                    pin: '1234'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                    created: '2020-05-20T11:18:23.159Z',
                    users: [
                        'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67',
                        '9212bd1e-c05f-48b4-9487-6a0e166813d9'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5ec511ffb438e6001414a790'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1028',
                    ssoId: 'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                    name: 'Javier',
                    note: '',
                    surname: 'Fernandez',
                    pin: '0000'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                    created: '2020-05-20T11:20:24.749Z',
                    users: [
                        '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5ec51278b438e6001414a793'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1026',
                    ssoId: '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                    name: 'Francisco',
                    note: '',
                    surname: 'Lachovski',
                    pin: '01234'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                    created: '2020-05-20T11:21:36.308Z',
                    users: [
                        '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67',
                        '9212bd1e-c05f-48b4-9487-6a0e166813d9'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T12:08:59.834Z',
                    id: '5ec512c0b438e6001414a796'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1025',
                    ssoId: '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                    name: 'Anton',
                    note: '',
                    surname: 'Chrobák',
                    pin: '1234'
                }
            }
        ]
    },
    chat: {
        title: '',
        driverId: '',
        userId: '68728890-88b4-463d-b2a9-cec21563a785',
        messageIndexRef: 0
    },
    roles
};

export const selectedUser: State = {
    active: true,
    channels: {
        channels: [
            {
                channel: {
                    title: '',
                    creator: '01501b0a-2c59-4434-9c91-3c85f133dcae',
                    created: '2020-04-09T14:16:47.057Z',
                    users: [
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2'
                    ],
                    newMsgCount: 2,
                    newMsgFrom: '2020-04-09T14:17:16.490Z',
                    id: '5e8f2e4fa8fd0d00132bf31d'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '43b5424c-50be-42ca-8992-9898d3748e3a',
                    created: '2020-04-17T08:50:43.552Z',
                    users: [
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5e996de344cc7900132d34a3'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                    created: '2020-04-17T10:32:39.392Z',
                    users: [
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d'
                    ],
                    newMsgCount: 2,
                    newMsgFrom: '2020-04-17T11:26:49.463Z',
                    id: '5e9985c744cc7900132d3c30'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'dae22d5a-b523-438f-b595-678d28552d59',
                    created: '2020-04-17T11:45:29.387Z',
                    users: [
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-17T11:48:13.815Z',
                    id: '5e9996d944cc7900132d401f'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                    created: '2020-04-17T11:54:04.255Z',
                    users: [
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5e9998dc44cc7900132d405b'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                    created: '2020-04-21T13:09:40.556Z',
                    users: [
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-21T13:10:36.937Z',
                    id: '5e9ef094a3f84800137c9d19'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '2651789f-98a8-4fba-a917-527541364834',
                    created: '2020-04-22T06:41:46.112Z',
                    users: [
                        '2651789f-98a8-4fba-a917-527541364834',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5e9fe72aa3f84800137cc7b9'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                    created: '2020-04-24T14:18:30.607Z',
                    users: [
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '43b5424c-50be-42ca-8992-9898d3748e3a',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '2ce93228-1053-4ee1-8f5d-4dd8d97bea0d',
                        'dae22d5a-b523-438f-b595-678d28552d59',
                        'd20b3084-0ff3-44ea-882a-a35f84e578f0',
                        'de7a025b-9f89-44b6-ae0e-45c33c68d880',
                        '2651789f-98a8-4fba-a917-527541364834'
                    ],
                    newMsgCount: 1,
                    newMsgFrom: '2020-04-24T14:18:47.458Z',
                    id: '5ea2f536be866300136d4512'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                    created: '2020-05-19T11:10:21.717Z',
                    users: [
                        'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5ec3be9db438e6001414a782'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '996',
                    ssoId: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                    name: 'Michal',
                    note: '',
                    surname: 'Roháček',
                    pin: '1234'
                }
            },
            {
                channel: {
                    title: '',
                    creator: 'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                    created: '2020-05-20T11:18:23.159Z',
                    users: [
                        'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67',
                        '9212bd1e-c05f-48b4-9487-6a0e166813d9'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5ec511ffb438e6001414a790'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1028',
                    ssoId: 'a3778a5f-2790-4df8-8caa-b0ea3a9d3382',
                    name: 'Javier',
                    note: '',
                    surname: 'Fernandez',
                    pin: '1234'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                    created: '2020-05-20T11:20:24.749Z',
                    users: [
                        '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5ec51278b438e6001414a793'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1026',
                    ssoId: '34b6ace8-5dd9-48ef-afd9-bd69d08d9fb9',
                    name: 'Francisco',
                    note: '',
                    surname: 'Lachovski',
                    pin: '1234'
                }
            },
            {
                channel: {
                    title: '',
                    creator: '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                    created: '2020-05-20T11:21:36.308Z',
                    users: [
                        '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                        '433efad9-2f28-47e6-88e9-b56d5d84b48c',
                        '68728890-88b4-463d-b2a9-cec21563a785',
                        '3d995bec-6770-4265-a864-ca0f7bf87cd2',
                        '01501b0a-2c59-4434-9c91-3c85f133dcae',
                        'dbcda7d6-9441-48d5-82e3-4d0342717cac',
                        '4307b36e-67f3-42c2-b288-400c3930c74d',
                        '3db448a0-7e3e-4091-9c0c-ae2afe3946bf',
                        '4a58edeb-0aee-4980-9ec3-b735305406dc',
                        'b473c529-66cb-48ea-8e27-7a6bce37ab67',
                        '9212bd1e-c05f-48b4-9487-6a0e166813d9'
                    ],
                    newMsgCount: 0,
                    newMsgFrom: '2020-06-03T11:52:03.937Z',
                    id: '5ec512c0b438e6001414a796'
                },
                driver: {
                    mobile: '',
                    email: '',
                    tachographId: '',
                    id: '1025',
                    ssoId: '0fc980a4-0a31-47f9-8ba4-36e81c4c4bb3',
                    name: 'Anton',
                    note: '',
                    surname: 'Chrobák',
                    pin: '1234'
                }
            }
        ],
        selectedChannel: '5ec3be9db438e6001414a782'
    },
    chat: {
        title: 'Roháček Michal',
        driverId: '21',
        userId: '68728890-88b4-463d-b2a9-cec21563a785',
        messages: [
            {
                channel: '5ec3be9db438e6001414a782',
                sender: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                text: 'Where are you?',
                created: '2020-05-20T04:47:05.658Z',
                seen: [
                    {
                        user: '68728890-88b4-463d-b2a9-cec21563a785',
                        date: '2020-06-03T11:52:03.745Z'
                    }
                ],
                id: '5ec4b649b438e6001414a789'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                text: 'Bla',
                created: '2020-05-20T07:13:02.264Z',
                seen: [
                    {
                        user: '68728890-88b4-463d-b2a9-cec21563a785',
                        date: '2020-06-03T11:52:03.745Z'
                    }
                ],
                id: '5ec4d87eb438e6001414a78a'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'Here',
                created: '2020-05-20T07:13:21.821Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-20T07:13:28.103Z'
                    }
                ],
                id: '5ec4d891b438e6001414a78b'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'Hi',
                created: '2020-05-20T07:29:26.854Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-20T07:32:32.278Z'
                    }
                ],
                id: '5ec4dc56b438e6001414a78c'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'Bye',
                created: '2020-05-20T07:33:00.697Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-20T07:33:11.496Z'
                    }
                ],
                id: '5ec4dd2cb438e6001414a78d'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'Bye',
                created: '2020-05-20T07:33:19.943Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-20T07:33:34.390Z'
                    }
                ],
                id: '5ec4dd3fb438e6001414a78e'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                text: 'Loaded, leaving!',
                created: '2020-05-20T07:33:39.206Z',
                seen: [
                    {
                        user: '68728890-88b4-463d-b2a9-cec21563a785',
                        date: '2020-06-03T11:52:03.745Z'
                    }
                ],
                id: '5ec4dd53b438e6001414a78f'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'Hello guys',
                created: '2020-05-20T11:23:22.349Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-21T09:53:34.932Z'
                    }
                ],
                id: '5ec5132ab438e6001414a79c'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                text: "I'm in front of the ramp.",
                created: '2020-05-21T09:53:41.303Z',
                seen: [
                    {
                        user: '68728890-88b4-463d-b2a9-cec21563a785',
                        date: '2020-06-03T11:52:03.745Z'
                    }
                ],
                id: '5ec64fa5b438e6001414a7b3'
            },
            {
                channel: '5ec3be9db438e6001414a782',
                sender: '68728890-88b4-463d-b2a9-cec21563a785',
                text: 'ok',
                created: '2020-05-21T09:53:47.455Z',
                seen: [
                    {
                        user: 'fbb5d9b7-9e1a-4e74-a965-280e7575115d',
                        date: '2020-05-21T09:53:53.629Z'
                    }
                ],
                id: '5ec64fabb438e6001414a7b4'
            }
        ],
        messageIndexRef: 9
    },
    roles
};
