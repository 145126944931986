/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripSummary
 */
export interface TripSummary {
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    durationSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    distanceMeters?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    odometerEndMeters?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    consumptionLiters100km?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    consumptionLiters?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    stoppedTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TripSummary
     */
    co2Emission?: number;
}

export function TripSummaryFromJSON(json: any): TripSummary {
    return TripSummaryFromJSONTyped(json, false);
}

export function TripSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'durationSeconds': !exists(json, 'duration_seconds') ? undefined : json['duration_seconds'],
        'distanceMeters': !exists(json, 'distance_meters') ? undefined : json['distance_meters'],
        'odometerEndMeters': !exists(json, 'odometer_end_meters') ? undefined : json['odometer_end_meters'],
        'consumptionLiters100km': !exists(json, 'consumption_liters_100km') ? undefined : json['consumption_liters_100km'],
        'consumptionLiters': !exists(json, 'consumption_liters') ? undefined : json['consumption_liters'],
        'stoppedTime': !exists(json, 'stopped_time') ? undefined : json['stopped_time'],
        'co2Emission': !exists(json, 'co2_emission') ? undefined : json['co2_emission'],
    };
}

export function TripSummaryToJSON(value?: TripSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_seconds': value.durationSeconds,
        'distance_meters': value.distanceMeters,
        'odometer_end_meters': value.odometerEndMeters,
        'consumption_liters_100km': value.consumptionLiters100km,
        'consumption_liters': value.consumptionLiters,
        'stopped_time': value.stoppedTime,
        'co2_emission': value.co2Emission,
    };
}


