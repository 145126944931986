import React from 'react';
import { RouteNames } from 'App';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import qa from 'qa-selectors';
import * as sidebarIcons from 'resources/images/sidebar';

interface Props extends WithTranslation {}

function PartnerSideBar(props: Props) {
    const { t } = props;

    return (
        <div className="partner-sidebar sidebar">
            <NavLink
                className="sidebar-item"
                to={RouteNames.PARTNER_PARTNERS}
                activeClassName="sidebar-item-active"
                title={t('common.partners')}
                data-qa={qa.sidebar.partner.btnPartners}
            >
                <img src={sidebarIcons.partners} alt={t('common.partners')} />
            </NavLink>
            <NavLink
                className="sidebar-item"
                to={RouteNames.PARTNER_COMPANIES}
                activeClassName="sidebar-item-active"
                title={t('common.companies')}
                data-qa={qa.sidebar.partner.btnCompanies}
            >
                <img src={sidebarIcons.companies} alt={t('common.companies')} />
            </NavLink>
            <NavLink
                className="sidebar-item"
                to={RouteNames.PARTNER_WHITELABEL}
                activeClassName="sidebar-item-active"
                title={t('common.whitelabel')}
                data-qa={qa.sidebar.partner.btnWhitelabel}
            >
                <img src={sidebarIcons.whitelabel} alt={t('common.whitelabel')} />
            </NavLink>
            <NavLink
                className="sidebar-item sidebar-item-vehicles"
                to={RouteNames.PARTNER_VEHICLES}
                activeClassName="sidebar-item-active"
                data-qa={qa.sidebar.partner.btnVehicles}
                title={t('common.vehicles')}
            >
                <img src={sidebarIcons.vehicles} alt={t('common.vehicles')} />
            </NavLink>
            <NavLink
                className="sidebar-item"
                to={RouteNames.PARTNER_OBU}
                activeClassName="sidebar-item-active"
                title={t('common.obu')}
                data-qa={qa.sidebar.partner.btnObu}
            >
                <img src={sidebarIcons.obu} alt={t('common.obu')} />
            </NavLink>
            <NavLink
                className="sidebar-item"
                to={RouteNames.PARTNER_CCR}
                activeClassName="sidebar-item-active"
                title={t('common.companyCardReader')}
                data-qa={qa.sidebar.partner.ccr}
            >
                <img src={sidebarIcons.ccr} alt={t('common.ccr')} />
            </NavLink>
            <NavLink
                className="sidebar-item sidebar-item-users"
                to={RouteNames.PARTNER_USERS}
                activeClassName="sidebar-item-active"
                title={t('common.users')}
                data-qa={qa.sidebar.partner.users}
            >
                <img src={sidebarIcons.users} alt={t('common.users')} />
            </NavLink>
        </div>
    );
}

export default withTranslation()(PartnerSideBar);
