import React from 'react';
import { Row } from 'antd';
import cn from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { Pagination, Table } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import qa from 'qa-selectors';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    data?: PaginatedResponse<PartnerCompanyModel[]>;
    selected?: PartnerCompanyModel;
    loading: boolean;
    onTableRowSelect?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function PartnersTable(props: Props) {
    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    const { t, data, loading, selected } = props;

    return (
        <div className="partners-table partner-main-table">
            <Table<PartnerCompanyModel>
                loading={loading}
                dataSource={data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id.toString()),
                    className: cn({
                        'ant-table-row-selected': selected?.id === record.id
                    })
                })}
            />

            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );

    function createColumns() {
        return [
            {
                title: t('common.name'),
                dataIndex: 'name',
                key: 'name',
                width: 30,
                render: name => <span data-qa={`${qa.partner.partners.table.fieldName}`}>{name}</span>
            },
            {
                title: t('Partner.ident'),
                dataIndex: 'identificationNumber',
                key: 'identificationNumber',
                width: 60,
                render: identificationNumber => (
                    <span data-qa={qa.partner.partners.table.fieldId}>{identificationNumber}</span>
                )
            },
            {
                title: t('common.email'),
                dataIndex: 'contactPerson',
                key: 'email',
                width: 60,
                render: contactPerson => (
                    <span data-qa={qa.partner.partners.table.fieldEmail}>{contactPerson?.[0]?.email}</span>
                )
            },
            {
                title: t('common.phone'),
                dataIndex: 'contactPerson',
                key: 'cellPhone',
                width: 45,
                render: contactPerson => (
                    <span data-qa={qa.partner.partners.table.fieldPhone}>{contactPerson?.[0]?.cellPhone}</span>
                )
            },
            {
                title: t('common.type'),
                dataIndex: 'companyType',
                key: 'companyType',
                width: 25,
                render: companyType => (
                    <span data-qa={qa.partner.partners.table.fieldType}>{t(`PartnerPartners.${companyType}`)}</span>
                )
            }
        ] as ColumnType<PartnerCompanyModel>[];
    }
}

export default withTranslation()(PartnersTable);
