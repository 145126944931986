import { WithLogic, withLogicContext } from 'App';
import { Tabs } from 'common/components';
import Modal from 'common/components/Modal';
import DiemsBulkForm, { DiemsBulkFormModel } from 'common/forms/DiemsBulkForm/DiemsBulkForm';
import { calculationType, replacementMeals } from 'common/model/allowance';
import { DATE_FORMAT } from 'domain-constants';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import AllowanceRuleTable from '../AllowanceRuleTable/AllowanceRuleTable';

enum TabKeys {
    bulkEdit = '1',
    table = '2'
}
interface Props extends WithTranslation, WithLogic {
    visible: boolean;
    isPolishDiems: boolean;
    defaultStart: string;
    defaultEnd: string;
    demoMode: boolean;
    onSubmit: (data: DiemsBulkFormModel) => void;
    onEdit: (data: DiemsBulkFormModel, userId?: number | null) => void;
    onCancel: () => void;
}

interface State {
    modalWidth: 1200 | 600;
}

class AllowanceBulkEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalWidth: 600
        };
    }

    render() {
        return (
            <Modal
                className="allowance-bulk-edit"
                destroyOnClose
                onCancel={this.props.onCancel}
                closable={true}
                footer={null}
                title={this.props.t('Allowance.bulk.title')}
                visible={this.props.visible}
                width={this.state.modalWidth}
            >
                <Tabs onChange={this._onTabChange} type="card" defaultActiveKey={TabKeys.bulkEdit}>
                    <Tabs.TabPane key={TabKeys.bulkEdit} tab={this.props.t('Allowance.bulk.tabSetRule')}>
                        <DiemsBulkForm
                            isPolishDiems={this.props.isPolishDiems}
                            replacementMeals={replacementMeals()}
                            lang={this.props.logic.auth().user().lang}
                            calculationType={calculationType()}
                            initialValues={{
                                start: moment.utc().format(DATE_FORMAT),
                                end: moment.utc().add(1, 'months').endOf('month').format(DATE_FORMAT)
                            }}
                            applyRowPadding
                            demoMode={this.props.demoMode}
                            onCancel={this.props.onCancel}
                            onSubmit={this.props.onSubmit}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={TabKeys.table} tab={this.props.t('Allowance.bulk.tabEditRule')}>
                        <AllowanceRuleTable
                            lang={this.props.logic.auth().user().lang}
                            loading={this.props.logic.diems().rules().diemsListLoading}
                            data={this.props.logic.diems().rules().diemsRuleTableData}
                            onSubmit={this.props.onEdit}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }

    private _onTabChange = (key: string) => {
        this.setState({
            modalWidth: key === TabKeys.bulkEdit ? 600 : 1200
        });
    };
}

export default withLogicContext(withTranslation()(observer(AllowanceBulkEdit)));
