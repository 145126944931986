import { Sorter } from 'common/components';

interface Props {
    title: React.ReactNode;
    units?: string;
    sorter?: boolean;
    align?: 'left' | 'center' | 'right';
    size?: 'small' | 'medium';
    sorterOrder?: 'ascend' | 'descend';
    level?: 'uppercase' | 'normal';
    qa?: string;
}

export default function TableHeadLabel({ size = 'medium', align = 'center', level = 'uppercase', ...props }: Props) {
    return (
        <div className={`table-head-label table-head-label-${size}`}>
            <div className={`table-head-label-text table-head-label-text-${props.sorter ? 'left' : align}`}>
                <div className={`table-head-label-title table-head-label-${level}`}>{props.title}</div>
                {props.units && <div className="table-head-label-units">[{props.units}]</div>}
            </div>
            {props.sorter && (
                <div>
                    <Sorter order={props.sorterOrder} />
                </div>
            )}
        </div>
    );
}
