import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import cn from 'classnames';
import { DriverBehaviorCoachModel } from 'common/model/statistics';
import { TIME_FORMAT } from 'domain-constants';
import DriverBehaviorProgressBar, { DriverBehaviorProgressBarValue } from '../DriverBehaviorProgressBar';
import { formatSecondsToString } from 'common/utils/dateTimeUtils';
import DriverBehaviorIconExclamation from '../DriverBehaviorCoachTable/DriverBehaviorIconExclamation';

interface Props {
    driversList?: DriverBehaviorCoachModel[];
    qa?: string;
}

export default function DriverBehaviorTimesGraph(props: Props) {
    const driverBehaviorTimesColors = {
        drivingTime: '#29abeb',
        idlingTime: '#0b79d0',
        idlingTimeShorter2: '#ffb547',
        idlingTimeLonger2: '#ff7602',
        idlingTimeLonger30: '#ff4e59',
        idlingTimeLonger60: '#e31b0c'
    };

    const driverBehaviorTimesList = [
        'drivingTime',
        'idlingTime'
        // 'idlingTimeShorter2',
        // 'idlingTimeLonger2',
        // 'idlingTimeLonger30',
        // 'idlingTimeLonger60'
    ];
    const { t } = useTranslation();

    return (
        <div
            className={cn('driver-behavior-times-graph', {
                'driver-behavior-times-graph-multi': (props.driversList?.length ?? 0) > 1
            })}
            data-qa={props.qa}
        >
            <Row className="driver-behavior-times-graph-graphs">
                <>
                    {props.driversList?.map((d, index) => (
                        <Row
                            key={`timegraphbar_${index}`}
                            className="driver-behavior-times-graph-progress-bar"
                            align="middle"
                        >
                            <Col className="graph-driver">
                                {(props.driversList?.length ?? 0) > 1 && <span title={d.name}>{d.name}</span>}
                            </Col>
                            <Col flex={1}>
                                <DriverBehaviorProgressBar
                                    values={driverBehaviorTimesList
                                        .map(
                                            tl =>
                                                ({
                                                    label: `${
                                                        (props.driversList?.length ?? 0) > 1 ? `${d.name}:` : ''
                                                    } ${t(`DriverBehavior.timesGraph.${tl}`)}`,
                                                    value: d?.timesGraph?.[tl].value,
                                                    color: driverBehaviorTimesColors[tl],
                                                    title: formatSecondsToString(d?.timesGraph?.[tl].value ?? 0)
                                                } as DriverBehaviorProgressBarValue)
                                        )
                                        .filter(v => v.value)}
                                />
                            </Col>
                        </Row>
                    ))}
                </>
            </Row>
            <table className="driver-behavior-times-graph-table">
                {(props.driversList?.length ?? 0) > 1 && (
                    <tbody>
                        <tr>
                            <td colSpan={2} />
                            {props.driversList?.map(d => (
                                <td className="driver-behavior-times-graph-table-name" title={d.name}>
                                    {d.name}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                )}
                {driverBehaviorTimesList.map((tl, indexTimes) => (
                    <tbody>
                        <tr key={`timegraph_${indexTimes}`} data-field-name={tl}>
                            <td>
                                <span
                                    className="driver-behavior-times-graph-point"
                                    style={{ backgroundColor: driverBehaviorTimesColors[tl] }}
                                />
                            </td>
                            <td>
                                {`${t(`DriverBehavior.timesGraph.${tl}`)} `}
                                <span className="driver-behavior-times-graph-unit">{`[${TIME_FORMAT.toLowerCase()}]`}</span>
                            </td>
                            {props.driversList?.map((d, index) => {
                                return (
                                    <td
                                        key={index}
                                        className={cn('driver-behavior-times-graph-table-value', {
                                            'no-data-text': !d.timesGraph && indexTimes === 0
                                        })}
                                    >
                                        {d.timesGraph?.[tl].value ? (
                                            formatSecondsToString(d?.timesGraph?.[tl].value ?? 0)
                                        ) : (
                                            <div className="no-data-text">-</div>
                                        )}
                                        {d.timesGraph?.[tl].exclamation && <DriverBehaviorIconExclamation />}
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                ))}
            </table>
        </div>
    );
}
