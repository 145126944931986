/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyDriver
 */
export interface WriteOnlyDriver {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDriver
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDriver
     */
    surname: string;
}

export function WriteOnlyDriverFromJSON(json: any): WriteOnlyDriver {
    return WriteOnlyDriverFromJSONTyped(json, false);
}

export function WriteOnlyDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'surname': json['surname'],
    };
}

export function WriteOnlyDriverToJSON(value?: WriteOnlyDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
    };
}


