/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientForUserBackendInfo,
    ClientForUserBackendInfoFromJSON,
    ClientForUserBackendInfoFromJSONTyped,
    ClientForUserBackendInfoToJSON,
    MobjectGroups,
    MobjectGroupsFromJSON,
    MobjectGroupsFromJSONTyped,
    MobjectGroupsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyClientBackendInfo
 */
export interface ReadOnlyClientBackendInfo {
    /**
     * 
     * @type {Array<MobjectGroups>}
     * @memberof ReadOnlyClientBackendInfo
     */
    readonly monitoredObjectGroups?: Array<MobjectGroups>;
    /**
     * 
     * @type {ClientForUserBackendInfo}
     * @memberof ReadOnlyClientBackendInfo
     */
    client?: ClientForUserBackendInfo;
}

export function ReadOnlyClientBackendInfoFromJSON(json: any): ReadOnlyClientBackendInfo {
    return ReadOnlyClientBackendInfoFromJSONTyped(json, false);
}

export function ReadOnlyClientBackendInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyClientBackendInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectGroups': !exists(json, 'monitored_object_groups') ? undefined : ((json['monitored_object_groups'] as Array<any>).map(MobjectGroupsFromJSON)),
        'client': !exists(json, 'client') ? undefined : ClientForUserBackendInfoFromJSON(json['client']),
    };
}

export function ReadOnlyClientBackendInfoToJSON(value?: ReadOnlyClientBackendInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientForUserBackendInfoToJSON(value.client),
    };
}


