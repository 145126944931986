import { ReactNode } from 'react';
import cn from 'classnames';

import { Progress } from 'common/components';
import { roundToStep } from 'common/utils/averages';
import { star } from 'resources/images/driver-behavior';
import { ThemeChartType, Themes, getChartTheme } from '../ChartTimeline';

export enum ScoreCardColors {
    Lightblue = '#00e1ff',
    Blue = '#338fff',
    Green = '#1bda7e',
    Yellow = '#ffd22e',
    Purple = '#6037d2'
}

interface Props {
    label: string;
    value: number;
    color: 'yellow' | 'lightblue' | 'green' | 'blue' | 'purple';
    theme?: Themes;
    description?: ReactNode;
    size: number;
    important?: boolean;
    percent?: boolean;
    starred?: boolean;
    qa?: string;
}

function ScoreCard(props: Props) {
    const chartTheme = getChartTheme(props.theme, ThemeChartType.Trend);

    return (
        <div className="score-card">
            <div className="score-card-inner">
                <Progress
                    className={cn('score-progress', {
                        'score-progress-blue': props.color === 'blue',
                        'score-progress-yellow': props.color === 'yellow',
                        'score-progress-lightblue': props.color === 'lightblue',
                        'score-progress-green': props.color === 'green',
                        'score-progress-purple': props.color === 'purple',
                        'score-progress-important': props.important
                    })}
                    percent={props.value * (props.percent ? 1 : 20)}
                    type="dashboard"
                    gapDegree={84}
                    trailColor={chartTheme?.style?.backgroundColor}
                    strokeWidth={8}
                    strokeColor={
                        props.color === 'lightblue'
                            ? ScoreCardColors.Lightblue
                            : props.color === 'blue'
                            ? ScoreCardColors.Blue
                            : props.color === 'green'
                            ? ScoreCardColors.Green
                            : props.color === 'purple'
                            ? ScoreCardColors.Purple
                            : ScoreCardColors.Yellow
                    }
                    width={props.size}
                    format={() => (
                        <div className="score-card-content" data-qa={props.qa}>
                            {roundToStep(props.value, 0.1)}
                            {props.percent && '%'}
                        </div>
                    )}
                />
                {props.starred && (
                    <div className="score-card-star">
                        <img src={star} alt="star" />
                    </div>
                )}
                <div
                    className={cn('score-card-label', {
                        'score-card-label-important': props.important
                    })}
                >
                    {props.label}
                </div>
                {props.description && <div className="score-card-description">{props.description}</div>}
            </div>
        </div>
    );
}

export default ScoreCard;
