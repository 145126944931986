import { WithTranslation, withTranslation } from 'react-i18next';
import Table from 'common/components/Table';
import { ColumnType } from 'antd/lib/table';
import { KM, TABLE_SCROLL_Y_SMALL } from 'domain-constants';
import moment from 'moment';
import { BorderCrossingModel } from '../../StatisticsBorderCrossingModule';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { DriverModel } from 'logic/user/users';
import numeral from 'numeral';
import { durationToFormated } from 'common/utils/actual';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import qa from 'qa-selectors';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

interface Props extends WithTranslation {
    data?: PaginatedResponse<BorderCrossingModel[]>;
    vehicles?: ReadOnlyMonitoredObjectFeSb[];
    drivers?: DriverModel[];
    loading: boolean;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function BorderCrossingTable(props: Props) {
    return (
        <div className="border-crossing-table">
            <Table<BorderCrossingModel>
                loading={props.loading}
                dataSource={props.data?.data.map((e, index) => ({ ...e, key: index }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_SMALL }}
            />
        </div>
    );

    function createColumns() {
        return [
            {
                title: props.t('BorderCrossingTable.country'),
                dataIndex: 'country',
                key: 'country',
                width: 28,
                render: country => {
                    return (
                        <div data-qa={qa.borderCrossing.table.fieldCountry}>
                            <div className={`flag-icon flag-icon-${country?.toLocaleLowerCase()}`} />
                            {` ${country}`}
                        </div>
                    );
                }
            },
            {
                title: props.t('common.driver'),
                dataIndex: 'driver',
                key: 'driver',
                width: 45,
                render: driver => {
                    const currentDriver = props.drivers?.find(d => d.id === driver);
                    return (
                        <span data-qa={qa.borderCrossing.table.fieldDriver}>
                            {`${currentDriver?.name ? currentDriver.name : ''} ${
                                currentDriver?.surname ? currentDriver.surname : ''
                            }`}
                        </span>
                    );
                }
            },
            {
                title: props.t('common.vehicle'),
                dataIndex: 'vehicle',
                key: 'vehicle',
                width: 35,
                render: vehicle => {
                    const foundVehicle = props.vehicles?.find(v => v.id === Number(vehicle));
                    const rn = foundVehicle?.disabledAt
                        ? `*${foundVehicle.registrationNumber}`
                        : foundVehicle?.registrationNumber;
                    return <span data-qa={qa.borderCrossing.table.fieldVehicle}>{rn}</span>;
                }
            },
            {
                title: props.t('BorderCrossingTable.entryDate'),
                dataIndex: 'entryDate',
                key: 'entryDate',
                width: 45,
                render: entryDate => (
                    <span data-qa={qa.borderCrossing.table.fieldExitDate}>
                        {entryDate && moment.utc(entryDate).local().format('L LT')}
                    </span>
                )
            },
            {
                title: props.t('BorderCrossingTable.entryLocation'),
                dataIndex: 'entryLocation',
                key: 'entryLocation',
                width: 70,
                render: (entryLocation, record) => (
                    <>
                        <i className="fa fa-map-marker" />{' '}
                        <AddressFormatter
                            qa={qa.borderCrossing.table.fieldEntryLocation}
                            renderAddressTitle
                            defaultAddress={entryLocation ?? ''}
                            addressStructured={record.addressStructuredEntry}
                        />
                    </>
                )
            },
            {
                title: props.t('BorderCrossingTable.exitDate'),
                dataIndex: 'exitDate',
                key: 'exitDate',
                width: 45,
                render: exitDate => (
                    <span data-qa={qa.borderCrossing.table.fieldExitDate}>
                        {exitDate && moment.utc(exitDate).local().format('L LT')}
                    </span>
                )
            },
            {
                title: props.t('BorderCrossingTable.exitLocation'),
                dataIndex: 'exitLocation',
                key: 'exitLocation',
                width: 70,
                render: (exitLocation, record) => (
                    <>
                        <i className="fa fa-dot-circle" />{' '}
                        <AddressFormatter
                            qa={qa.borderCrossing.table.fieldExitLocation}
                            renderAddressTitle
                            defaultAddress={exitLocation ?? ''}
                            addressStructured={record.addressStructuredExit}
                        />
                    </>
                )
            },
            {
                title: props.t('common.distance'),
                dataIndex: 'distance',
                key: 'distance',
                width: 30,
                render: distance => (
                    <span data-qa={qa.borderCrossing.table.fieldDistance}>{`${numeral(distance).format(
                        '0,0'
                    )} ${KM}`}</span>
                )
            },
            {
                title: props.t('common.duration'),
                dataIndex: 'duration',
                key: 'duration',
                width: 30,
                render: duration => (
                    <span data-qa={qa.borderCrossing.table.fieldDuration}>
                        {durationToFormated(moment.duration(duration, 'seconds'))}
                    </span>
                )
            }
        ] as ColumnType<BorderCrossingModel>[];
    }
}

export default withTranslation()(BorderCrossingTable);
