/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientForUserBackendInfo,
    ClientForUserBackendInfoFromJSON,
    ClientForUserBackendInfoFromJSONTyped,
    ClientForUserBackendInfoToJSON,
    MobjectGroups,
    MobjectGroupsFromJSON,
    MobjectGroupsFromJSONTyped,
    MobjectGroupsToJSON,
    UserGroupForUserBackendInfo,
    UserGroupForUserBackendInfoFromJSON,
    UserGroupForUserBackendInfoFromJSONTyped,
    UserGroupForUserBackendInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyUserBackendInfo
 */
export interface ReadOnlyUserBackendInfo {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserBackendInfo
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserBackendInfo
     */
    readonly clientId?: string;
    /**
     * 
     * @type {Array<MobjectGroups>}
     * @memberof ReadOnlyUserBackendInfo
     */
    readonly monitoredObjectGroups?: Array<MobjectGroups>;
    /**
     * 
     * @type {Array<UserGroupForUserBackendInfo>}
     * @memberof ReadOnlyUserBackendInfo
     */
    readonly userGroup?: Array<UserGroupForUserBackendInfo>;
    /**
     * 
     * @type {ClientForUserBackendInfo}
     * @memberof ReadOnlyUserBackendInfo
     */
    client?: ClientForUserBackendInfo;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserBackendInfo
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserBackendInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserBackendInfo
     */
    surname: string;
}

export function ReadOnlyUserBackendInfoFromJSON(json: any): ReadOnlyUserBackendInfo {
    return ReadOnlyUserBackendInfoFromJSONTyped(json, false);
}

export function ReadOnlyUserBackendInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserBackendInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'monitoredObjectGroups': !exists(json, 'monitored_object_groups') ? undefined : ((json['monitored_object_groups'] as Array<any>).map(MobjectGroupsFromJSON)),
        'userGroup': !exists(json, 'user_group') ? undefined : ((json['user_group'] as Array<any>).map(UserGroupForUserBackendInfoFromJSON)),
        'client': !exists(json, 'client') ? undefined : ClientForUserBackendInfoFromJSON(json['client']),
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'name': json['name'],
        'surname': json['surname'],
    };
}

export function ReadOnlyUserBackendInfoToJSON(value?: ReadOnlyUserBackendInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientForUserBackendInfoToJSON(value.client),
        'language_iso_639_1': value.languageIso6391,
        'name': value.name,
        'surname': value.surname,
    };
}


