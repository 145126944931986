/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskMediaPayload
 */
export interface TaskMediaPayload {
    /**
     * 
     * @type {string}
     * @memberof TaskMediaPayload
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof TaskMediaPayload
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof TaskMediaPayload
     */
    thumb?: string;
}

export function TaskMediaPayloadFromJSON(json: any): TaskMediaPayload {
    return TaskMediaPayloadFromJSONTyped(json, false);
}

export function TaskMediaPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskMediaPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'type': json['type'],
        'thumb': !exists(json, 'thumb') ? undefined : json['thumb'],
    };
}

export function TaskMediaPayloadToJSON(value?: TaskMediaPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'type': value.type,
        'thumb': value.thumb,
    };
}


