import { MonitoredObjectFleetDynamicDataFromJSON } from 'generated/backend-api-live';
import { MonitoredObject } from 'generated/graphql';
import {
    MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum,
    MonitoredObjectToMonitoredObjectFromJSON,
    MonitoredObjectWithPairedTrailersFromJSON,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectFromJSON,
    ReadOnlyMonitoredObjectNested,
    ReadOnlyMonitoredObjectType
} from 'generated/new-main';
import moment from 'moment';
import { users } from 'common/demo/users';
import { TunnelType } from 'modules/management/modules/fleet/FleetModule';
import { temperatureSensorsData } from './coldchain';

const defaultWidth = { v: 2.55, md: false };
const defaultHeight = { v: 4, md: false };
const defaultLength = { v: 6.16, md: false };
const defaultEmission = { v: '6', md: false };
const defaultRoles = [
    'D_R', // 0
    'LM_R', // 1
    'POI_R', // 2
    'LT_R', // 3
    'RID_R', // 4
    'JAE_R', // 5,
    'AEI_R', // 6
    'TRD_R', // 7
    'DBH_R' // 12
];
const defaultFuel = {
    calibration: [
        {
            curve: [
                [-1, 1],
                [0, 0],
                [100, 501]
            ],
            probeId: 0
        }
    ]
};

export const monitoredObjectTypes: ReadOnlyMonitoredObjectType[] = [
    {
        id: 1,
        label: 'Vehicle',
        name: 'vehicle'
    },
    {
        id: 2,
        label: 'Trailer',
        name: 'trailer'
    },
    {
        id: 3,
        label: 'HGV less 3.5t',
        name: 'hgv_less_3.5t'
    },
    {
        id: 4,
        label: 'HGV 3,5t-7,5t',
        name: 'hgv_3.5t-7.5t'
    },
    {
        id: 5,
        label: 'HGV 7,5t -12t',
        name: 'hgv_7.5t_-12t'
    },
    {
        id: 6,
        label: 'Trucks',
        name: 'trucks'
    },
    {
        id: 7,
        label: 'Buses',
        name: 'buses'
    },
    {
        id: 8,
        label: 'Light vehicle',
        name: 'light_vehicle'
    }
];

export const trailers = [
    {
        id: 101,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'DE014YE',
        customLabel: 'DE014YE',
        description: null,
        systemDescription: null,
        manufacturerName: 'Schmitz',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'DE014YE',
            licensePlateNumber: 'DE014YE',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'COOLER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'COOLER',
                weightEmpty: 5,
                weightFull: 10,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: null,
        temperature_sensors: temperatureSensorsData
            .filter(tsd => String(tsd.monitored_object) === '101')
            .map(ts => {
                return {
                    id: ts.id,
                    sensorName: ts.sensor_name,
                    sensorType: 'temperature_sensor',
                    sensorZone: ts.sensor_zone,
                    serialNumber: ts.serial_number
                };
            }),
        __typename: 'MonitoredObject'
    },
    {
        id: 102,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: '4A2 9951',
        customLabel: '4A2 9951',
        description: null,
        systemDescription: null,
        manufacturerName: 'Schmitz',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: '4A2 9951',
            licensePlateNumber: '4A2 9951',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'COOLER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'COOLER',
                weightEmpty: 5,
                weightFull: 10,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: null,
        temperature_sensors: temperatureSensorsData
            .filter(tsd => String(tsd.monitored_object) === '102')
            .map(ts => {
                return {
                    id: ts.id,
                    sensorName: ts.sensor_name,
                    sensorType: 'temperature_sensor',
                    sensorZone: ts.sensor_zone,
                    serialNumber: ts.serial_number
                };
            }),
        __typename: 'MonitoredObject'
    },
    {
        id: 103,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'ERA 11 ZX',
        customLabel: 'ERA 11 ZX',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'ERA 11 ZX',
            licensePlateNumber: 'ERA 11 ZX',
            manufacturerName: 'Kogel',
            trailerProfile: {
                cargoType: 'COOLER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'COOLER',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: null,
        temperature_sensors: temperatureSensorsData
            .filter(tsd => String(tsd.monitored_object) === '103')
            .map(ts => {
                return {
                    id: ts.id,
                    sensorName: ts.sensor_name,
                    sensorType: 'temperature_sensor',
                    sensorZone: ts.sensor_zone,
                    serialNumber: ts.serial_number
                };
            }),
        __typename: 'MonitoredObject'
    },
    {
        id: 104,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'SKA 91 UP',
        customLabel: 'SKA 91 UP',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'SKA 91 UP',
            licensePlateNumber: 'SKA 91 UP',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'CONTAINER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'STANDARD',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: null,
        __typename: 'MonitoredObject'
    },
    {
        id: 105,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'KAX 77 AS',
        customLabel: 'KAX 77 AS',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'KAX 77 AS',
            licensePlateNumber: 'KAX 77 AS',
            manufacturerName: 'Kogel',
            trailerProfile: {
                cargoType: 'CONTAINER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'STANDARD',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: null,
        __typename: 'MonitoredObject'
    },
    {
        id: 106,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'BA 850 DE',
        customLabel: 'BA 850 DE',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'BA 850 DE',
            licensePlateNumber: 'BA 850 DE',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'CONTAINER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'STANDARD',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.27, currency: 'EUR' },
        tankSize: null,
        __typename: 'MonitoredObject'
    },
    {
        id: 107,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482000f',
        registrationNumber: 'JA680MA',
        customLabel: 'JA680MA',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'JA680MA',
            licensePlateNumber: 'JA680MA',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'CONTAINER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'STANDARD',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.33, currency: 'EUR' },
        tankSize: null,
        __typename: 'MonitoredObject'
    },
    {
        id: 108,
        monitoringDeviceId: null,
        clientId: 100,
        monitoredObjectTypeId: 2,
        vehicleProfileId: null,
        externalId: '22045_0039d482001f',
        registrationNumber: 'KR2V573',
        customLabel: 'KR2V573',
        description: null,
        systemDescription: null,
        manufacturerName: 'Kogel',
        configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: {}
        },
        metadata: {
            customLabel: 'KR2V573',
            licensePlateNumber: 'KR2V573',
            manufacturerName: 'Schmitz',
            trailerProfile: {
                cargoType: 'CONTAINER',
                height: 3,
                length: 18,
                numberOfAxles: 3,
                type: 'STANDARD',
                weightEmpty: 5,
                weightFull: 12,
                width: 2
            }
        },
        deleted: '0',
        costPerKm: { cost: 1.35, currency: 'EUR' },
        tankSize: null,
        __typename: 'MonitoredObject'
    }
] as MonitoredObject[];

export const vehicles: ReadOnlyMonitoredObjectNested[] = [
    {
        id: 1,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '22045_0039d482000f',
        registrationNumber: 'BL 7367 BX',
        customLabel: 'BL 7367 BX',
        description: null,
        systemDescription: null,
        manufacturerName: 'MAN',
        _configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '9988776655443322',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 950,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 2,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '4A21471',
        registrationNumber: '4A2 1471',
        customLabel: '4A2 1471',
        description: null,
        systemDescription: null,
        manufacturerName: 'Scania',
        _configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 3.5,
                    md: false
                },
                vin: {
                    v: '123456789123456',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 950,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 3,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: 'DriverBeh0_24382',
        registrationNumber: 'ERA 88 TL',
        customLabel: 'ERA 88 TL',
        description: null,
        systemDescription: null,
        manufacturerName: 'Mercedes',
        _configuration: {
            driver_authentication_types: ['driver_card'],
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '654321987654321',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    v: 1500,
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 950,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 4,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '22045_0039d482000f',
        registrationNumber: 'SKA 18 LL',
        customLabel: 'SKA 18 LL',
        description: null,
        systemDescription: null,
        manufacturerName: 'Volvo',
        _configuration: {
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 4,
                    md: false
                },
                weightFull: {
                    v: 4.5,
                    md: false
                },
                vin: {
                    v: '5413219836213',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    v: 1440,
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 900,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 5,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '22045_0039d482000f',
        registrationNumber: 'KAX 04 RE',
        customLabel: 'KAX 04 RE',
        description: null,
        systemDescription: null,
        manufacturerName: 'MAN',
        _configuration: {
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '13549324654',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 1000,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 6,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '22045_0039d482000f',
        registrationNumber: 'LOS 72 IE',
        customLabel: 'LOS 72 IE',
        description: null,
        systemDescription: null,
        manufacturerName: 'Scania',
        _configuration: {
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '95632156549513',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    v: 1440,
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 900,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 1001,
        clientId: 100,
        monitoredObjectTypeId: 8,
        externalId: '22045_0039d482000f',
        registrationNumber: '1A2 6318',
        customLabel: '1A2 6318',
        description: null,
        systemDescription: null,
        manufacturerName: 'VW',
        _configuration: {
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '13338841865',
                    md: false
                },
                numberOfAxles: {
                    v: 1,
                    md: false
                },
                trailersCount: {
                    v: 0,
                    md: false
                },
                optimalRpm: {
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.51, currency: 'EUR' },
        tankSize: 50,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '123456789',
            permissions: {
                roles: defaultRoles
            }
        }
    },
    {
        id: 7,
        clientId: 100,
        monitoredObjectTypeId: 1,
        externalId: '22045_0039d48s2000f',
        registrationNumber: 'PES 92 IE',
        customLabel: 'PES 92 IE',
        description: null,
        systemDescription: null,
        manufacturerName: 'Scania',
        _configuration: {
            fuel: defaultFuel,
            fuelType: 'DIESEL'
        },
        metadata: {
            profile: {
                fuelType: {
                    md: false,
                    v: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.DIESEL
                },
                tunnel: {
                    md: false,
                    v: TunnelType.A
                },
                width: defaultWidth,
                height: defaultHeight,
                length: defaultLength,
                emissionClass: defaultEmission,
                weight: {
                    v: 1.5,
                    md: false
                },
                weightFull: {
                    v: 2.5,
                    md: false
                },
                vin: {
                    v: '95632156549513',
                    md: false
                },
                numberOfAxles: {
                    v: 2,
                    md: false
                },
                trailersCount: {
                    v: 1,
                    md: false
                },
                optimalRpm: {
                    v: 1200,
                    md: false
                }
            }
        },
        deleted: 0,
        costPerKm: { cost: 1.36, currency: 'EUR' },
        tankSize: 900,
        monitoringDevice: {
            monitoringDeviceType: 1,
            clientId: 100,
            serialNumber: '12345a789',
            permissions: {
                roles: defaultRoles
            }
        }
    }
];

export const vehiclesSimple: ReadOnlyMonitoredObjectFeSb[] = vehicles
    .map(vehicle => ({
        id: vehicle.id,
        registrationNumber: vehicle.registrationNumber,
        customLabel: vehicle.customLabel,
        fleetType: 'vehicle'
    }))
    .concat(
        trailers.map(trailer => ({
            id: Number(trailer.id),
            registrationNumber: trailer.registrationNumber ?? '',
            customLabel: trailer.customLabel,
            fleetType: 'trailer'
        }))
    );

export const monitoredObject = ReadOnlyMonitoredObjectFromJSON({});

export const monitoredObjectToMonitoredObject = trailers
    .map((t, i) => ({
        primary_monitored_object: {
            id: vehicles[i].id,
            client_id: 100,
            registration_number: vehicles[i].registrationNumber,
            deleted: 0,
            custom_label: vehicles[i].customLabel
        },
        secondary_monitored_object: {
            id: t.id,
            client_id: 100,
            registration_number: t.registrationNumber,
            deleted: 0,
            custom_label: t.customLabel
        },
        valid_from: moment().subtract(1, 'month').toISOString(),
        valid_to: null,
        pair_info: null,
        unpair_info: null,
        source: 'man'
    }))
    .map(MonitoredObjectToMonitoredObjectFromJSON);

export const pairedTrailers = trailers
    .map((t, i) => ({
        primary_monitored_object: {
            id: vehicles[i].id,
            registration_number: vehicles[i].registrationNumber
        },
        secondary_monitored_objects: [
            {
                id: t.id,
                registration_number: t.registrationNumber,
                pair_info: {
                    lat: 0.0,
                    lon: 0.0,
                    addressStructured: []
                },
                valid_to: null,
                valid_from: moment().subtract(1, 'year').toISOString(),
                unpair_info: null
            }
        ]
    }))
    .map(MonitoredObjectWithPairedTrailersFromJSON);

export const fleetDynamicData = trailers
    .map((t, i) => ({
        monitored_object_id: String(vehicles[i].id),
        default_driver_id: users[i].id,
        trailers_dynamic: [],
        trailers_manual: [
            {
                monitored_object_id: String(t.id),
                paired_since: moment().subtract(1, 'year').toDate()
            }
        ]
    }))
    .map(MonitoredObjectFleetDynamicDataFromJSON);
