/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorderCrossModel,
    BorderCrossModelFromJSON,
    BorderCrossModelFromJSONTyped,
    BorderCrossModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface BorderCrossTwoDirections
 */
export interface BorderCrossTwoDirections {
    /**
     * 
     * @type {number}
     * @memberof BorderCrossTwoDirections
     */
    borderCrossId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorderCrossTwoDirections
     */
    names: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorderCrossTwoDirections
     */
    countries: Array<string>;
    /**
     * 
     * @type {BorderCrossModel}
     * @memberof BorderCrossTwoDirections
     */
    direction1?: BorderCrossModel;
    /**
     * 
     * @type {BorderCrossModel}
     * @memberof BorderCrossTwoDirections
     */
    direction2?: BorderCrossModel;
}

export function BorderCrossTwoDirectionsFromJSON(json: any): BorderCrossTwoDirections {
    return BorderCrossTwoDirectionsFromJSONTyped(json, false);
}

export function BorderCrossTwoDirectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorderCrossTwoDirections {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borderCrossId': json['border_cross_id'],
        'names': json['names'],
        'countries': json['countries'],
        'direction1': !exists(json, 'direction1') ? undefined : BorderCrossModelFromJSON(json['direction1']),
        'direction2': !exists(json, 'direction2') ? undefined : BorderCrossModelFromJSON(json['direction2']),
    };
}

export function BorderCrossTwoDirectionsToJSON(value?: BorderCrossTwoDirections | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'border_cross_id': value.borderCrossId,
        'names': value.names,
        'countries': value.countries,
        'direction1': BorderCrossModelToJSON(value.direction1),
        'direction2': BorderCrossModelToJSON(value.direction2),
    };
}


