/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoringDevicePermissions,
    MonitoringDevicePermissionsFromJSON,
    MonitoringDevicePermissionsFromJSONTyped,
    MonitoringDevicePermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectFeFleetMD
 */
export interface MonitoredObjectFeFleetMD {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectFeFleetMD
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFeFleetMD
     */
    serialNumber: string;
    /**
     * 
     * @type {MonitoringDevicePermissions}
     * @memberof MonitoredObjectFeFleetMD
     */
    permissions: MonitoringDevicePermissions;
}

export function MonitoredObjectFeFleetMDFromJSON(json: any): MonitoredObjectFeFleetMD {
    return MonitoredObjectFeFleetMDFromJSONTyped(json, false);
}

export function MonitoredObjectFeFleetMDFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFeFleetMD {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serialNumber': json['serial_number'],
        'permissions': MonitoringDevicePermissionsFromJSON(json['permissions']),
    };
}

export function MonitoredObjectFeFleetMDToJSON(value?: MonitoredObjectFeFleetMD | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serialNumber,
        'permissions': MonitoringDevicePermissionsToJSON(value.permissions),
    };
}


