/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectProfile,
    MonitoredObjectProfileFromJSON,
    MonitoredObjectProfileFromJSONTyped,
    MonitoredObjectProfileToJSON,
    MonitoredObjectTrailerProfile,
    MonitoredObjectTrailerProfileFromJSON,
    MonitoredObjectTrailerProfileFromJSONTyped,
    MonitoredObjectTrailerProfileToJSON,
    TemperatureSensor,
    TemperatureSensorFromJSON,
    TemperatureSensorFromJSONTyped,
    TemperatureSensorToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectPartialUpdate
 */
export interface MonitoredObjectPartialUpdate {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectPartialUpdate
     */
    moid: number;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObjectPartialUpdate
     */
    costPerKm?: object;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectPartialUpdate
     */
    monitoringDevice?: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectPartialUpdate
     */
    disabledAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectPartialUpdate
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectPartialUpdate
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectPartialUpdate
     */
    customLabel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectPartialUpdate
     */
    tankSize?: number | null;
    /**
     * 
     * @type {MonitoredObjectProfile}
     * @memberof MonitoredObjectPartialUpdate
     */
    profile?: MonitoredObjectProfile;
    /**
     * 
     * @type {MonitoredObjectTrailerProfile}
     * @memberof MonitoredObjectPartialUpdate
     */
    trailerProfile?: MonitoredObjectTrailerProfile;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectPartialUpdate
     */
    trailerIdSerialNumber?: string;
    /**
     * 
     * @type {Array<TemperatureSensor>}
     * @memberof MonitoredObjectPartialUpdate
     */
    temperatureSensors?: Array<TemperatureSensor>;
}

export function MonitoredObjectPartialUpdateFromJSON(json: any): MonitoredObjectPartialUpdate {
    return MonitoredObjectPartialUpdateFromJSONTyped(json, false);
}

export function MonitoredObjectPartialUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectPartialUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moid': json['moid'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : json['monitoring_device'],
        'disabledAt': !exists(json, 'disabled_at') ? undefined : (json['disabled_at'] === null ? null : new Date(json['disabled_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'tankSize': !exists(json, 'tank_size') ? undefined : json['tank_size'],
        'profile': !exists(json, 'profile') ? undefined : MonitoredObjectProfileFromJSON(json['profile']),
        'trailerProfile': !exists(json, 'trailerProfile') ? undefined : MonitoredObjectTrailerProfileFromJSON(json['trailerProfile']),
        'trailerIdSerialNumber': !exists(json, 'trailerIdSerialNumber') ? undefined : json['trailerIdSerialNumber'],
        'temperatureSensors': !exists(json, 'temperature_sensors') ? undefined : ((json['temperature_sensors'] as Array<any>).map(TemperatureSensorFromJSON)),
    };
}

export function MonitoredObjectPartialUpdateToJSON(value?: MonitoredObjectPartialUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moid': value.moid,
        'cost_per_km': value.costPerKm,
        'monitoring_device': value.monitoringDevice,
        'disabled_at': value.disabledAt === undefined ? undefined : (value.disabledAt === null ? null : value.disabledAt.toISOString()),
        'description': value.description,
        'note': value.note,
        'custom_label': value.customLabel,
        'tank_size': value.tankSize,
        'profile': MonitoredObjectProfileToJSON(value.profile),
        'trailerProfile': MonitoredObjectTrailerProfileToJSON(value.trailerProfile),
        'trailerIdSerialNumber': value.trailerIdSerialNumber,
        'temperature_sensors': value.temperatureSensors === undefined ? undefined : ((value.temperatureSensors as Array<any>).map(TemperatureSensorToJSON)),
    };
}


