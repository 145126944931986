import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import qa from 'qa-selectors';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';

interface Props {
    data: ReadOnlyFuelCardSerializer;
    fuelCompanies: ReadOnlyFuelCompanySerializer[];
}

export default function FuelCardDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="trailer-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ManagementFuelCards.number')}>
                            <span data-qa={qa.fuel.detail.fieldNumber}>{props.data.number}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFuelCards.holderName')}>
                            <span data-qa={qa.fuel.detail.fieldHolder}>{props.data.holder}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ManagementFuelCards.fuelCompany')}>
                            <span data-qa={qa.fuel.detail.fieldExpDate}>
                                {props.fuelCompanies?.length
                                    ? props.fuelCompanies.find(f => f.id === props.data?.fuelCompany)?.label
                                    : ''}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFuelCards.expirationDate')}>
                            <span data-qa={qa.fuel.detail.fieldExpDate}>
                                {props.data?.expirationDate ? moment.utc(props.data?.expirationDate).format('L') : ''}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('UsersTable.note')}>
                            <span data-qa={qa.fuel.detail.fieldNote}>{props.data.note}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
