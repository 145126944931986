/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOOutputDetails,
    TCOOutputDetailsFromJSON,
    TCOOutputDetailsFromJSONTyped,
    TCOOutputDetailsToJSON,
    TCOOutputDetailsDiesel,
    TCOOutputDetailsDieselFromJSON,
    TCOOutputDetailsDieselFromJSONTyped,
    TCOOutputDetailsDieselToJSON,
    TCOOutputTotalCosts,
    TCOOutputTotalCostsFromJSON,
    TCOOutputTotalCostsFromJSONTyped,
    TCOOutputTotalCostsToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOOutput
 */
export interface TCOOutput {
    /**
     * 
     * @type {Array<TCOOutputDetails>}
     * @memberof TCOOutput
     */
    lngVehicleDetails: Array<TCOOutputDetails>;
    /**
     * 
     * @type {Array<TCOOutputDetailsDiesel>}
     * @memberof TCOOutput
     */
    dieselVehicleDetails: Array<TCOOutputDetailsDiesel>;
    /**
     * 
     * @type {TCOOutputTotalCosts}
     * @memberof TCOOutput
     */
    total: TCOOutputTotalCosts;
}

export function TCOOutputFromJSON(json: any): TCOOutput {
    return TCOOutputFromJSONTyped(json, false);
}

export function TCOOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lngVehicleDetails': ((json['lng_vehicle_details'] as Array<any>).map(TCOOutputDetailsFromJSON)),
        'dieselVehicleDetails': ((json['diesel_vehicle_details'] as Array<any>).map(TCOOutputDetailsDieselFromJSON)),
        'total': TCOOutputTotalCostsFromJSON(json['total']),
    };
}

export function TCOOutputToJSON(value?: TCOOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lng_vehicle_details': ((value.lngVehicleDetails as Array<any>).map(TCOOutputDetailsToJSON)),
        'diesel_vehicle_details': ((value.dieselVehicleDetails as Array<any>).map(TCOOutputDetailsDieselToJSON)),
        'total': TCOOutputTotalCostsToJSON(value.total),
    };
}


