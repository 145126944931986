/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomPlaceCrossingInDatabase,
    CustomPlaceCrossingInDatabaseFromJSON,
    CustomPlaceCrossingInDatabaseToJSON,
    CustomPlaceCrossingTstrWithCustomPlaceData,
    CustomPlaceCrossingTstrWithCustomPlaceDataFromJSON,
    CustomPlaceCrossingTstrWithCustomPlaceDataToJSON,
    CustomPlaceCrossingType,
    CustomPlaceCrossingTypeFromJSON,
    CustomPlaceCrossingTypeToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface CreateManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPostRequest {
    userId: number;
    customPlaceId: string;
    timestamp: Date;
    type: CustomPlaceCrossingType;
}

export interface CustomPlaceCrossingListV1PlacesCustomPlaceCrossingGetRequest {
    userIds?: Array<number>;
    start?: Date;
    end?: Date;
    manual?: boolean;
}

export interface DeleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDeleteRequest {
    customPlaceCrossingId: string;
}

export interface ManualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGetRequest {
    userIds?: Array<number>;
    start?: Date;
    end?: Date;
}

/**
 * no description
 */
export class CustomPlaceCrossingApi extends runtime.BaseAPI {

    /**
     * Create Manual Custom Place Crossing
     */
    async createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPostRaw(requestParameters: CreateManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPostRequest): Promise<runtime.ApiResponse<CustomPlaceCrossingInDatabase>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost.');
        }

        if (requestParameters.customPlaceId === null || requestParameters.customPlaceId === undefined) {
            throw new runtime.RequiredError('customPlaceId','Required parameter requestParameters.customPlaceId was null or undefined when calling createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost.');
        }

        if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
            throw new runtime.RequiredError('timestamp','Required parameter requestParameters.timestamp was null or undefined when calling createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.customPlaceId !== undefined) {
            queryParameters['custom_place_id'] = requestParameters.customPlaceId;
        }

        if (requestParameters.timestamp !== undefined) {
            queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/custom_place_crossing_manual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomPlaceCrossingInDatabaseFromJSON(jsonValue));
    }

    /**
     * Create Manual Custom Place Crossing
     */
    async createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost(requestParameters: CreateManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPostRequest): Promise<CustomPlaceCrossingInDatabase> {
        const response = await this.createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Custom Place Crossing List
     */
    async customPlaceCrossingListV1PlacesCustomPlaceCrossingGetRaw(requestParameters: CustomPlaceCrossingListV1PlacesCustomPlaceCrossingGetRequest): Promise<runtime.ApiResponse<Array<CustomPlaceCrossingTstrWithCustomPlaceData>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.manual !== undefined) {
            queryParameters['manual'] = requestParameters.manual;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/custom_place_crossing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomPlaceCrossingTstrWithCustomPlaceDataFromJSON));
    }

    /**
     * Custom Place Crossing List
     */
    async customPlaceCrossingListV1PlacesCustomPlaceCrossingGet(requestParameters: CustomPlaceCrossingListV1PlacesCustomPlaceCrossingGetRequest): Promise<Array<CustomPlaceCrossingTstrWithCustomPlaceData>> {
        const response = await this.customPlaceCrossingListV1PlacesCustomPlaceCrossingGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete Manual Custom Place Crossing
     */
    async deleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDeleteRaw(requestParameters: DeleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDeleteRequest): Promise<runtime.ApiResponse<CustomPlaceCrossingInDatabase>> {
        if (requestParameters.customPlaceCrossingId === null || requestParameters.customPlaceCrossingId === undefined) {
            throw new runtime.RequiredError('customPlaceCrossingId','Required parameter requestParameters.customPlaceCrossingId was null or undefined when calling deleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customPlaceCrossingId !== undefined) {
            queryParameters['custom_place_crossing_id'] = requestParameters.customPlaceCrossingId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/custom_place_crossing_manual`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomPlaceCrossingInDatabaseFromJSON(jsonValue));
    }

    /**
     * Delete Manual Custom Place Crossing
     */
    async deleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDelete(requestParameters: DeleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDeleteRequest): Promise<CustomPlaceCrossingInDatabase> {
        const response = await this.deleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Manual Custom Place Crossing List
     */
    async manualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGetRaw(requestParameters: ManualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGetRequest): Promise<runtime.ApiResponse<Array<CustomPlaceCrossingTstrWithCustomPlaceData>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/custom_place_crossing_manual`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomPlaceCrossingTstrWithCustomPlaceDataFromJSON));
    }

    /**
     * Manual Custom Place Crossing List
     */
    async manualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGet(requestParameters: ManualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGetRequest): Promise<Array<CustomPlaceCrossingTstrWithCustomPlaceData>> {
        const response = await this.manualCustomPlaceCrossingListV1PlacesCustomPlaceCrossingManualGetRaw(requestParameters);
        return await response.value();
    }

}
