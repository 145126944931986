import AutoCompleteAntd, { Props as AutocompleteAntProps } from 'common/components/AutoCompleteAntd';
import { OptionsType } from 'rc-select/lib/interface';
import { useState, useEffect } from 'react';

interface Props extends AutocompleteAntProps {
    showArrow?: boolean;
    qa?: string;
    refresh?: number;
}

export interface ClientValue {
    id?: string;
    name: string;
}

const ActivityClient = ({ qa, showArrow = true, ...props }: Props) => {
    const [filteredOptions, setFilteredOptions] = useState<OptionsType | undefined>(props.options);

    useEffect(() => {
        setFilteredOptions(props.options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refresh]);

    const handleSearch = (searchText: string) => {
        const text = searchText.toLowerCase();
        const finds = props.options?.filter(e => {
            const name = (e.value ?? '').toLowerCase();
            return name.indexOf(text) > -1 || e.value === 'newClient';
        });
        setFilteredOptions(!searchText ? props.options ?? [] : finds ?? []);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        props.onKeyUp?.(e);
        if (e.key === 'Escape') {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    return (
        <AutoCompleteAntd
            data-qa={qa}
            className="activity-client"
            style={{ width: '100%' }}
            {...props}
            options={filteredOptions}
            showArrow={showArrow}
            onSearch={handleSearch}
            onKeyDown={handleKeyDown}
        />
    );
};

export default ActivityClient;
