/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformation
     */
    weight?: number;
}

export function AdditionalInformationFromJSON(json: any): AdditionalInformation {
    return AdditionalInformationFromJSONTyped(json, false);
}

export function AdditionalInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function AdditionalInformationToJSON(value?: AdditionalInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weight': value.weight,
    };
}


