/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackendApiApiV2ActualVehicleStateVehicleFuelTank
 */
export interface BackendApiApiV2ActualVehicleStateVehicleFuelTank {
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleFuelTank
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleFuelTank
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleFuelTank
     */
    rawValue: number;
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleFuelTank
     */
    level?: number;
}

export function BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSON(json: any): BackendApiApiV2ActualVehicleStateVehicleFuelTank {
    return BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSONTyped(json, false);
}

export function BackendApiApiV2ActualVehicleStateVehicleFuelTankFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV2ActualVehicleStateVehicleFuelTank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'time': (new Date(json['time'])),
        'rawValue': json['raw_value'],
        'level': !exists(json, 'level') ? undefined : json['level'],
    };
}

export function BackendApiApiV2ActualVehicleStateVehicleFuelTankToJSON(value?: BackendApiApiV2ActualVehicleStateVehicleFuelTank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'time': (value.time.toISOString()),
        'raw_value': value.rawValue,
        'level': value.level,
    };
}


