import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { RouteConfiguration } from '../RouteConfigurator';
import { roundToDecimals } from 'common/utils/averages';
import numeral from 'numeral';

interface Props {
    tollPrice: { price: number; currency: string };
    /**
     * Current price per km
     */
    pricePerKM: RouteConfiguration['pricePerKM'];
    /**
     * In meters
     */
    distance: number;
}

export default function CostPopover({ tollPrice, pricePerKM, distance }: Props) {
    const { t } = useTranslation();

    const excludingTollTotal = () => {
        const value = roundToDecimals(pricePerKM.cost * (distance / 1e3));
        return value;
    };

    const routeCostTotal = () => {
        const value = excludingTollTotal() + (tollPrice.price ?? 0);
        return value;
    };

    return (
        <div className="costs-popover">
            <Row gutter={[4, 8]} className="costs-popover-title">
                <Col span={10} />
                <Col span={7}>{t('Planner.costs.pricePerKm')}</Col>
                <Col span={7}>{t('Planner.costs.total')}</Col>
            </Row>
            <Row gutter={[4, 8]}>
                <Col span={10}>{t('Planner.costs.excludingToll')}</Col>
                <Col span={7}>
                    {numeral(excludingTollTotal() / (distance / 1e3)).format('0,0.00') + ' ' + pricePerKM.currency}
                </Col>
                <Col span={7}>{numeral(excludingTollTotal()).format('0,0.00') + ' ' + pricePerKM.currency}</Col>
            </Row>
            <Row gutter={[4, 8]}>
                <Col span={10}>{t('common.toll')}</Col>
                <Col span={7} />
                <Col span={7}>{numeral(tollPrice.price ?? 0).format('0,0.00') + ' ' + pricePerKM.currency}</Col>
            </Row>
            <Row gutter={[4, 8]}>
                <Col span={10}>{t('common.totalPrice')}</Col>
                <Col span={7}>
                    {numeral(routeCostTotal() / (distance / 1e3)).format('0,0.00') + ' ' + pricePerKM.currency}
                </Col>
                <Col span={7}>{numeral(routeCostTotal()).format('0,0.00') + ' ' + pricePerKM.currency}</Col>
            </Row>
        </div>
    );
}
