/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualCountryInterval
 */
export interface ManualCountryInterval {
    /**
     * 
     * @type {string}
     * @memberof ManualCountryInterval
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualCountryInterval
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof ManualCountryInterval
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManualCountryInterval
     */
    end?: Date;
    /**
     * 
     * @type {Array<object>}
     * @memberof ManualCountryInterval
     */
    pointEntry: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ManualCountryInterval
     */
    pointExit?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ManualCountryInterval
     */
    countryEntry: string;
    /**
     * 
     * @type {string}
     * @memberof ManualCountryInterval
     */
    countryExit?: string;
}

export function ManualCountryIntervalFromJSON(json: any): ManualCountryInterval {
    return ManualCountryIntervalFromJSONTyped(json, false);
}

export function ManualCountryIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualCountryInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': !exists(json, 'monitored_object_id') ? undefined : json['monitored_object_id'],
        'clientId': json['client_id'],
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'pointEntry': json['point_entry'],
        'pointExit': !exists(json, 'point_exit') ? undefined : json['point_exit'],
        'countryEntry': json['country_entry'],
        'countryExit': !exists(json, 'country_exit') ? undefined : json['country_exit'],
    };
}

export function ManualCountryIntervalToJSON(value?: ManualCountryInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'client_id': value.clientId,
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'point_entry': value.pointEntry,
        'point_exit': value.pointExit,
        'country_entry': value.countryEntry,
        'country_exit': value.countryExit,
    };
}


