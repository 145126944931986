import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Loading } from 'common/components/Loading';
import { PairingItem, PartnerPairingKey } from 'common/model/partner-pairing';
import { Button } from 'common/components';
import { NoData } from 'common/components/NoData';
import Search from 'common/components/Search';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    keys: PartnerPairingKey[];
    keySelected?: PartnerPairingKey;
    items?: PairingItem[];
    itemIdSelected?: string;
    search?: string;
    loading?: boolean;
    keySource: PartnerPairingKey;
    pairingLoading?: boolean;
    onKeySelect?: (key: PartnerPairingKey) => void;
    onItemSelect?: (itemId: string) => void;
    onSearch?: (text: string) => void;
    onCancel?: () => void;
    onConfirm?: () => void;
}

function PartnerPairing(props: Props) {
    const { t } = props;

    function onKeyClick(key: PartnerPairingKey): void {
        if (!props.loading) {
            props.onKeySelect?.(key);
        }
    }

    function onItemSelect(itemId: string): void {
        props.onItemSelect?.(itemId);
    }

    function onSearch(text: string): void {
        props.onSearch?.(text);
    }

    function onCancel(): void {
        props.onCancel?.();
    }

    function onConfirm(): void {
        props.onConfirm?.();
    }

    return (
        <div className="t-modal">
            <div className="management-fleet-pairing t-modal-content t-card">
                <div className="t-bar t-large t-center t-padding-large">
                    <span className="t-uppercase">
                        {t('Partner.pairing')}: <span className="t-text-inverse">{t(`common.${props.keySource}`)}</span>
                    </span>
                    {props.keySelected && (
                        <span className="t-padding-small">
                            <i className="fas fa-arrows-alt-h" />{' '}
                            <span className="t-text-inverse t-bold t-uppercase">
                                {t(`common.${props.keySelected}`)}
                            </span>
                        </span>
                    )}
                </div>
                <div className="t-row">
                    <div className="management-fleet-pairing-type t-half t-border-right">
                        {props.keys?.map((k, i) => (
                            <button
                                key={i}
                                className={cn('t-button t-block', {
                                    't-bold t-primary': k === props.keySelected,
                                    disabled: (props.keySelected && props.items) || !props.items
                                })}
                                onClick={onKeyClick.bind(undefined, k)}
                            >
                                <span className="t-left">{t(`common.${k}`)}</span>
                                {props.keySelected ? (
                                    <span className="t-right">
                                        <i
                                            className={cn(
                                                'fas fa-fw',
                                                k === props.keySelected ? 'fa-angle-down' : 'fa-angle-right'
                                            )}
                                        />
                                    </span>
                                ) : (
                                    <span className="t-right">
                                        <i className="fas fa-fw fa-angle-right" />
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                    <div className="t-half t-padding-small">
                        <div className="t-row">
                            <Search searchActive={true} onSearch={onSearch} qa={qa.common.pairing.inputSearch} />
                        </div>
                        <div
                            className={cn('management-fleet-pairing-items t-margin-top t-margin-bottom', {
                                't-overflow-y': props.items
                            })}
                        >
                            {props.loading ? (
                                <Loading />
                            ) : props.keySelected ? (
                                props.items && props.items.length > 0 ? (
                                    props.items?.map(i => (
                                        <button
                                            key={i.id}
                                            className={cn('t-button t-block t-ripple', {
                                                't-primary': i.id === props.itemIdSelected
                                            })}
                                            onClick={onItemSelect.bind(undefined, i.id)}
                                        >
                                            <div className="t-row">
                                                <span className="t-left t-transform-none">{`${i.value}`}</span>
                                            </div>
                                        </button>
                                    ))
                                ) : (
                                    <NoData />
                                )
                            ) : (
                                <NoData text={t('Partner.selectItemToLink')} />
                            )}
                        </div>
                        <div className="t-row">
                            <div className="t-half t-padding-small">
                                <Button
                                    type="default"
                                    className="t-button t-block t-primary-secondary t-ripple"
                                    onClick={onCancel}
                                >
                                    {props.t('common.cancel')}
                                </Button>
                            </div>
                            <div className="t-half t-padding-small">
                                <Button
                                    loading={props.pairingLoading}
                                    type="primary"
                                    className="t-button t-block t-primary t-ripple"
                                    onClick={onConfirm}
                                    disabled={!props.keySelected || !props.itemIdSelected}
                                >
                                    {props.t('common.confirm')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(PartnerPairing);
