/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimePeriod,
    DatetimePeriodFromJSON,
    DatetimePeriodFromJSONTyped,
    DatetimePeriodToJSON,
    Place,
    PlaceFromJSON,
    PlaceFromJSONTyped,
    PlaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleUnavailabilityInDB
 */
export interface VehicleUnavailabilityInDB {
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    reasonMessage: string;
    /**
     * 
     * @type {Place}
     * @memberof VehicleUnavailabilityInDB
     */
    place?: Place;
    /**
     * 
     * @type {DatetimePeriod}
     * @memberof VehicleUnavailabilityInDB
     */
    datetimePeriod: DatetimePeriod;
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    createdBy: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleUnavailabilityInDB
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    modifiedBy: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleUnavailabilityInDB
     */
    modifiedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleUnavailabilityInDB
     */
    version: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUnavailabilityInDB
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityInDB
     */
    actionId: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleUnavailabilityInDB
     */
    clientId: number;
}

export function VehicleUnavailabilityInDBFromJSON(json: any): VehicleUnavailabilityInDB {
    return VehicleUnavailabilityInDBFromJSONTyped(json, false);
}

export function VehicleUnavailabilityInDBFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleUnavailabilityInDB {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonMessage': json['reasonMessage'],
        'place': !exists(json, 'place') ? undefined : PlaceFromJSON(json['place']),
        'datetimePeriod': DatetimePeriodFromJSON(json['datetimePeriod']),
        'monitoredObjectId': json['monitoredObjectId'],
        'id': json['id'],
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
        'modifiedBy': json['modifiedBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
        'version': json['version'],
        'deleted': json['deleted'],
        'actionId': json['actionId'],
        'clientId': json['clientId'],
    };
}

export function VehicleUnavailabilityInDBToJSON(value?: VehicleUnavailabilityInDB | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonMessage': value.reasonMessage,
        'place': PlaceToJSON(value.place),
        'datetimePeriod': DatetimePeriodToJSON(value.datetimePeriod),
        'monitoredObjectId': value.monitoredObjectId,
        'id': value.id,
        'createdBy': value.createdBy,
        'createdAt': (value.createdAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'version': value.version,
        'deleted': value.deleted,
        'actionId': value.actionId,
        'clientId': value.clientId,
    };
}


