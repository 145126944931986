import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import qa from 'qa-selectors';
import { Button } from 'common/components';
import * as icons from 'resources/images/common';
import TableBar from 'common/components/TableBar';
import UiTabBar from 'common/components/UiTabBar/UiTabBar';
import { Select } from 'antd';
import DtcoCalendar from 'common/components/DtcoCalendar';
import { searched } from 'common/utils/search';

export enum DriverBehaviorNavTabs {
    basic = 'basic',
    coach = 'coach'
}

export enum DriverBehaviorNavType {
    Truck = 'truck',
    Vehicle = 'vehicle'
}
interface Props {
    loading: boolean;
    type: DriverBehaviorNavType;
    name: ReactNode;
    selectedTab?: DriverBehaviorNavTabs;
    disabledTabs?: DriverBehaviorNavTabs[];
    disabledComparedDriver?: boolean;
    driverId?: string;
    comparedDriverId?: string;
    drivers: {
        value: string;
        label: string;
    }[];
    avatar?: string;
    date?: string;
    comparedDate?: string;
    tachocard?: string;
    export: {
        processing: boolean;
        enable: boolean;
    };
    demoMode?: boolean;
    onBackButtonClick?: () => void;
    onExportClick?: () => any;
    onDateRangeChange?: (date: string) => void;
    onBarDateNextClick?: () => void;
    onBarDatePreviousClick?: () => void;
    onComparedDateRangeChange?: (date: string) => void;
    onBarComparedDateNextClick?: () => void;
    onBarComparedDatePreviousClick?: () => void;
    onTabChange?: (name: DriverBehaviorNavTabs) => void;
    onCompareDriverSelect?: (name: string | undefined) => void;
    onHelperClick?: () => void;
}

function DriverDetailNav(props: Props) {
    const { t } = useTranslation();

    function handlerDateRangeChange(from: Moment | null) {
        if (from) {
            props.onDateRangeChange?.(from?.toISOString());
        }
    }

    function handlerComparedDateRangeChange(from: Moment | null) {
        if (from) {
            props.onComparedDateRangeChange?.(from?.toISOString());
        }
    }

    const handlerTabClick = (key: string): void => {
        props.onTabChange?.(DriverBehaviorNavTabs[key]);
    };

    const handlerCompareDriverSelect = (value: string) => {
        props.onCompareDriverSelect?.(value === '' ? undefined : value);
    };

    const tabs = [
        { key: DriverBehaviorNavTabs.basic, label: t('DriverBehavior.tabs.basicReport') },
        { key: DriverBehaviorNavTabs.coach, label: t('DriverBehavior.tabs.coachReport') }
    ];

    return (
        <>
            <TableBar
                heading={
                    <>
                        <span data-qa={qa.driverBehaviour.nav.fieldName}>{props.name}</span>
                        {props.comparedDriverId && (
                            <>
                                <span className="driver-detail-nav-vs">{t('DriverBehaviorCharts.vs')}</span>
                                <span data-qa={qa.driverBehaviour.nav.fieldCompareName}>
                                    {props.drivers.find(d => d.value === props.comparedDriverId)?.label}
                                </span>
                            </>
                        )}
                    </>
                }
                backButton={{ onClick: props.onBackButtonClick }}
                className="driver-detail-nav"
                filters={[
                    props.type === DriverBehaviorNavType.Truck ? (
                        <UiTabBar
                            className="driver-behavior-tab-group"
                            options={tabs}
                            selectedOption={String(props.selectedTab)}
                            onSelect={handlerTabClick}
                        />
                    ) : undefined,
                    props.date ? (
                        <DtcoCalendar
                            disabled={props.loading}
                            startDate={props.date}
                            onDatePickerChange={handlerDateRangeChange}
                            onNextChange={props.onBarDateNextClick}
                            onPreviousChange={props.onBarDatePreviousClick}
                        />
                    ) : undefined,
                    props.type === DriverBehaviorNavType.Truck ? (
                        <span className="driver-detail-nav-compare-driver-before">
                            {t('DriverBehavior.compareWith')}
                        </span>
                    ) : undefined,
                    props.type === DriverBehaviorNavType.Truck ? (
                        <Select
                            className="driver-detail-nav-compare-driver"
                            size="large"
                            onSelect={handlerCompareDriverSelect}
                            showSearch
                            filterOption={(input, option) => searched(input, String(option?.children))}
                            placeholder={t('DriverBehavior.selectCompareDriver')}
                            value={props.comparedDriverId ?? ''}
                            dropdownMatchSelectWidth={200}
                            disabled={props.disabledComparedDriver}
                        >
                            <Select.Option key={'noDriver'} value={''}>
                                {t('DriverBehavior.selectCompareDriver')}
                            </Select.Option>
                            {props.drivers
                                .filter(d => d.value !== props.driverId)
                                .map(d => (
                                    <Select.Option key={d.value} value={d.value}>
                                        {d.label}
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : undefined,
                    props.comparedDriverId && !props.disabledComparedDriver && props.comparedDate ? (
                        <DtcoCalendar
                            startDate={props.comparedDate}
                            onDatePickerChange={handlerComparedDateRangeChange}
                            onNextChange={props.onBarComparedDateNextClick}
                            onPreviousChange={props.onBarComparedDatePreviousClick}
                            disabled={props.loading || props.disabledComparedDriver}
                        />
                    ) : undefined
                ]}
                actions={[
                    <Button
                        className="driver-detail-nav-export-btn"
                        type="default"
                        disabled={!props.export.enable}
                        loading={props.export.processing}
                        icon={<img src={icons.buttonExport} alt={t('common.export')} />}
                        onClick={props.onExportClick}
                    >
                        {t('common.export')}
                    </Button>
                ]}
                onHelperClick={props.onHelperClick}
            />
        </>
    );
}

export default DriverDetailNav;
