/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20019,
    InlineResponse20019FromJSON,
    InlineResponse20019ToJSON,
    NumberList,
    NumberListFromJSON,
    NumberListToJSON,
    ReadOnlyMobileDevice,
    ReadOnlyMobileDeviceFromJSON,
    ReadOnlyMobileDeviceToJSON,
    WriteOnlyMobileDevice,
    WriteOnlyMobileDeviceFromJSON,
    WriteOnlyMobileDeviceToJSON,
} from '../models';

export interface MobileDeviceCreateRequest {
    data: WriteOnlyMobileDevice;
}

export interface MobileDeviceDeleteRequest {
    id: string;
}

export interface MobileDeviceListRequest {
    deviceId?: string;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface MobileDevicePartialUpdateRequest {
    id: string;
    data: WriteOnlyMobileDevice;
}

export interface MobileDeviceReadRequest {
    id: string;
}

export interface MobileDeviceUpdateRequest {
    id: string;
    data: WriteOnlyMobileDevice;
}

/**
 * no description
 */
export class MobileDeviceApi extends runtime.BaseAPI {

    /**
     */
    async mobileDeviceClientsRaw(): Promise<runtime.ApiResponse<NumberList>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/clients/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NumberListFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceClients(): Promise<NumberList> {
        const response = await this.mobileDeviceClientsRaw();
        return await response.value();
    }

    /**
     */
    async mobileDeviceCreateRaw(requestParameters: MobileDeviceCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDevice>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceCreate(requestParameters: MobileDeviceCreateRequest): Promise<ReadOnlyMobileDevice> {
        const response = await this.mobileDeviceCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceDeleteRaw(requestParameters: MobileDeviceDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceDelete(requestParameters: MobileDeviceDeleteRequest): Promise<ReadOnlyMobileDevice> {
        const response = await this.mobileDeviceDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceListRaw(requestParameters: MobileDeviceListRequest): Promise<runtime.ApiResponse<InlineResponse20019>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20019FromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceList(requestParameters: MobileDeviceListRequest): Promise<InlineResponse20019> {
        const response = await this.mobileDeviceListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDevicePartialUpdateRaw(requestParameters: MobileDevicePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDevicePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDevicePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceFromJSON(jsonValue));
    }

    /**
     */
    async mobileDevicePartialUpdate(requestParameters: MobileDevicePartialUpdateRequest): Promise<ReadOnlyMobileDevice> {
        const response = await this.mobileDevicePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceReadRaw(requestParameters: MobileDeviceReadRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceRead(requestParameters: MobileDeviceReadRequest): Promise<ReadOnlyMobileDevice> {
        const response = await this.mobileDeviceReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceUpdateRaw(requestParameters: MobileDeviceUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceUpdate(requestParameters: MobileDeviceUpdateRequest): Promise<ReadOnlyMobileDevice> {
        const response = await this.mobileDeviceUpdateRaw(requestParameters);
        return await response.value();
    }

}
