/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum EditSource {
    User = 'user',
    MuStreamEdit = 'mu_stream_edit'
}

export function EditSourceFromJSON(json: any): EditSource {
    return EditSourceFromJSONTyped(json, false);
}

export function EditSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditSource {
    return json as EditSource;
}

export function EditSourceToJSON(value?: EditSource | null): any {
    return value as any;
}

