/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    VehicleStateFull,
    VehicleStateFullFromJSON,
    VehicleStateFullToJSON,
    VehicleStateWithRN,
    VehicleStateWithRNFromJSON,
    VehicleStateWithRNToJSON,
} from '../models';

export interface EwDihApiV2ActualVehicleStateEwDihGetRequest {
    crmAccountNumber: string;
    vehicleIds: Array<string>;
    clientId?: number;
}

export interface FullStateApiV2ActualVehicleStateVehicleStateGetRequest {
    vehicleExternalIds?: Array<string>;
    clientId?: number;
    moids?: Array<string>;
}

export interface GetActualStatesV1ActualvehiclestateGetRequest {
    user?: string;
    device?: string;
}

/**
 * no description
 */
export class VehicleStateApi extends runtime.BaseAPI {

    /**
     * Ew Dih Api
     */
    async ewDihApiV2ActualVehicleStateEwDihGetRaw(requestParameters: EwDihApiV2ActualVehicleStateEwDihGetRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.crmAccountNumber === null || requestParameters.crmAccountNumber === undefined) {
            throw new runtime.RequiredError('crmAccountNumber','Required parameter requestParameters.crmAccountNumber was null or undefined when calling ewDihApiV2ActualVehicleStateEwDihGet.');
        }

        if (requestParameters.vehicleIds === null || requestParameters.vehicleIds === undefined) {
            throw new runtime.RequiredError('vehicleIds','Required parameter requestParameters.vehicleIds was null or undefined when calling ewDihApiV2ActualVehicleStateEwDihGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.crmAccountNumber !== undefined) {
            queryParameters['crmAccountNumber'] = requestParameters.crmAccountNumber;
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicleIds'] = requestParameters.vehicleIds;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/ew-dih/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Ew Dih Api
     */
    async ewDihApiV2ActualVehicleStateEwDihGet(requestParameters: EwDihApiV2ActualVehicleStateEwDihGetRequest): Promise<object> {
        const response = await this.ewDihApiV2ActualVehicleStateEwDihGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Full State Api
     */
    async fullStateApiV2ActualVehicleStateVehicleStateGetRaw(requestParameters: FullStateApiV2ActualVehicleStateVehicleStateGetRequest): Promise<runtime.ApiResponse<Array<VehicleStateFull>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.vehicleExternalIds) {
            queryParameters['vehicleExternalIds'] = requestParameters.vehicleExternalIds;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/vehicle-state/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateFullFromJSON));
    }

    /**
     * Full State Api
     */
    async fullStateApiV2ActualVehicleStateVehicleStateGet(requestParameters: FullStateApiV2ActualVehicleStateVehicleStateGetRequest): Promise<Array<VehicleStateFull>> {
        const response = await this.fullStateApiV2ActualVehicleStateVehicleStateGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Actual States
     */
    async getActualStatesV1ActualvehiclestateGetRaw(requestParameters: GetActualStatesV1ActualvehiclestateGetRequest): Promise<runtime.ApiResponse<Array<VehicleStateWithRN>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.device !== undefined) {
            queryParameters['device'] = requestParameters.device;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/actualvehiclestate/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateWithRNFromJSON));
    }

    /**
     * Get Actual States
     */
    async getActualStatesV1ActualvehiclestateGet(requestParameters: GetActualStatesV1ActualvehiclestateGetRequest): Promise<Array<VehicleStateWithRN>> {
        const response = await this.getActualStatesV1ActualvehiclestateGetRaw(requestParameters);
        return await response.value();
    }

}
