/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyContact
 */
export interface CompanyContact {
    /**
     * 
     * @type {string}
     * @memberof CompanyContact
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContact
     */
    contactPerson: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContact
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContact
     */
    email?: string;
}

export function CompanyContactFromJSON(json: any): CompanyContact {
    return CompanyContactFromJSONTyped(json, false);
}

export function CompanyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'contactPerson': json['contactPerson'],
        'phoneNumber': json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function CompanyContactToJSON(value?: CompanyContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'contactPerson': value.contactPerson,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
    };
}


