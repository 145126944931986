import qa from 'qa-selectors';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { DatePicker } from 'common/components';

import { BoardGridConfig } from '../../dispatcher-board-logic';

interface Props {
    value: string;
    config: BoardGridConfig;
    onDateChange: (date: string) => void;
}

export default function BoardDateSelect(props: Props) {
    const { t } = useTranslation();
    const startDate = moment(props.value).subtract(props.config.activeDayIndex - 1, 'days');

    function handleNextDayClick() {
        props.onDateChange(moment(props.value).add(1, 'day').toISOString());
    }

    function handlePreviousDayClick() {
        props.onDateChange(moment(props.value).subtract(1, 'day').toISOString());
    }

    function handleDayChange(value: Moment | null) {
        if (moment.isMoment(value)) {
            props.onDateChange(value.toISOString());
        }
    }

    return (
        <div className="board-date-select">
            <div className="board-date-select-controls">
                <div
                    className="board-date-select-arrow"
                    onClick={handlePreviousDayClick}
                    data-qa={qa.dispatcherBoard.previousButton}
                >
                    <ArrowLeftOutlined />
                </div>
                <DatePicker.DateTimePicker
                    defaultValue={moment(props.value)}
                    onChange={handleDayChange}
                    showTime={false}
                    trigger={
                        <span className="board-date-select-value" data-qa={qa.common.dateTimePicker.btn}>
                            <i title={t('common.calendar')} className="fa fa-fw fa-calendar" />
                        </span>
                    }
                />
                <div
                    className="board-date-select-arrow"
                    onClick={handleNextDayClick}
                    data-qa={qa.dispatcherBoard.nextButton}
                >
                    <ArrowRightOutlined />
                </div>
            </div>
            <div className="board-date-select-range" data-qa={qa.dispatcherBoard.calendar.fieldDateRange}>
                {`${startDate.format('L')} - ${startDate.add(props.config.visibleDays, 'days').format('L')}`}
            </div>
        </div>
    );
}
