import * as Yup from 'yup';
import FormActions from 'common/components/FormActions';
import { Form, Formik, FormikProps } from 'formik';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DatePickerField } from 'common/fields';
import { Col, Row } from 'antd';
import moment from 'moment';
import * as CommonIcons from 'resources/images/common';
import { useEffect, useState } from 'react';
import { Logic } from 'logic/logic';
import { debounce } from 'debounce';
import { useOptions } from './utils/useOptions';
import MultiSelectAutocompleteField from 'common/fields/AutoCompleteMultiSelect';

export interface PlaceOfWorkFormModel {
    start: string;
    end: string;
    userIds: number[];
}

interface Props extends WithTranslation {
    initialValues?: Partial<PlaceOfWorkFormModel>;
    demoMode: boolean;
    onSubmit: (values: PlaceOfWorkFormModel) => Promise<boolean>;
    onCancel?: () => void;
    logic: Logic;
}

function PlaceOfWorkForm(props: Props) {
    const [selectedUsers, setSelectedUsers] = useState<{ value: string; key: number }[]>([]);
    const { getOptions, options } = useOptions();
    const [search, setSearch] = useState('');

    useEffect(() => {
        getOptions(props.logic);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = debounce((search: string) => {
        getOptions(props.logic, search);
    }, 500);

    return (
        <Formik<PlaceOfWorkFormModel>
            initialValues={{
                userIds: props.initialValues?.userIds ?? [],
                start: props.initialValues?.start ?? moment().toISOString(),
                end: props.initialValues?.end ?? moment().add(1, 'year').toISOString()
            }}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                start: Yup.string().required(props.t('common.required')),
                end: Yup.string().required(props.t('common.required')),
                userIds: Yup.array().min(1, props.t('common.minOneItem'))
            })}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<PlaceOfWorkFormModel>) => {
                return (
                    <Form className="place-of-work-form">
                        <Row className="form-row">
                            <Col span={24}>
                                <MultiSelectAutocompleteField
                                    name="userIds"
                                    placeholderText={props.t('ManagementPoi.placeOfWorkSearch')}
                                    autoFocus
                                    search={false}
                                    onSearch={v => {
                                        setSearch(v);
                                        onSearch(v);
                                    }}
                                    className="place-of-work-form-select"
                                    onSelect={(_val, option) => {
                                        formik.setValues(values => ({
                                            ...values,
                                            userIds: formik.values.userIds.concat([option.key as number])
                                        }));
                                        setSelectedUsers(
                                            selectedUsers.concat([{ key: option.key as number, value: option.value }])
                                        );
                                        setSearch('');
                                    }}
                                    value={search}
                                    options={options.filter(o => !formik.values.userIds.includes(o.key))}
                                />
                            </Col>
                        </Row>
                        {selectedUsers.map(option => {
                            const userName = option.value;

                            if (userName) {
                                return (
                                    <Row key={`selected-user-${option.key}`}>
                                        <Col span={22}>{userName}</Col>
                                        <Col span={2} className="place-of-work-users-delete">
                                            <img
                                                alt="remove-user"
                                                src={CommonIcons.trash}
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        'userIds',
                                                        formik.values.userIds?.filter(id => id !== option.key)
                                                    );
                                                    setSelectedUsers(selectedUsers.filter(u => u.key !== option.key));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                );
                            }
                            return '';
                        })}
                        <Row className="form-row">
                            <Col span={24}>
                                <DatePickerField format="L" label={props.t('common.validFrom')} name="start" />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <DatePickerField format="L" label={props.t('common.validTo')} name="end" />
                            </Col>
                        </Row>

                        <FormActions
                            cancelQa={qa.poi.detail.btnCancel}
                            submitQa={qa.poi.detail.btnSubmit}
                            submitLoading={formik.isSubmitting}
                            submitDisabled={props.demoMode}
                            onCancelClick={props.onCancel}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(PlaceOfWorkForm);
