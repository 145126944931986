/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ADRClass {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _41 = '4.1',
    _42 = '4.2',
    _43 = '4.3',
    _51 = '5.1',
    _52 = '5.2',
    _61 = '6.1',
    _62 = '6.2',
    _7 = '7',
    _8 = '8',
    _9 = '9'
}

export function ADRClassFromJSON(json: any): ADRClass {
    return ADRClassFromJSONTyped(json, false);
}

export function ADRClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): ADRClass {
    return json as ADRClass;
}

export function ADRClassToJSON(value?: ADRClass | null): any {
    return value as any;
}

