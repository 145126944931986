/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface AttachmentV3ProposalsShipmentIdRequest {
    shipmentId: string;
    attachmentId: string;
}

/**
 * no description
 */
export class DffShipmentApi extends runtime.BaseAPI {

    /**
     * Transport Order file
     */
    async attachmentV3ProposalsShipmentIdRaw(requestParameters: AttachmentV3ProposalsShipmentIdRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
            throw new runtime.RequiredError('shipmentId','Required parameter requestParameters.shipmentId was null or undefined when calling attachmentV3ProposalsShipmentId.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling attachmentV3ProposalsShipmentId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/{shipment_id}/attachment/{attachment_id}`.replace(`{${"shipment_id"}}`, encodeURIComponent(String(requestParameters.shipmentId))).replace(`{${"attachment_id"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Transport Order file
     */
    async attachmentV3ProposalsShipmentId(requestParameters: AttachmentV3ProposalsShipmentIdRequest): Promise<Blob> {
        const response = await this.attachmentV3ProposalsShipmentIdRaw(requestParameters);
        return await response.value();
    }

}
