import { TrackingModel } from 'common/model/tracking';
import { ExternalDeviceType } from 'generated/backend-api-live';
import { VehicleStateObject } from 'generated/graphql';

export const trailersVehicleStateObjectRN = (types: ExternalDeviceType[], data?: VehicleStateObject): string[] => {
    return (
        data?.external_devices
            ?.map(externalDevice =>
                types.includes(externalDevice.device_type as ExternalDeviceType) ? externalDevice.device_id ?? '' : ''
            )
            .filter(externalDevice => externalDevice) ?? []
    );
};
export const trailersVehicleStateObjectIds = (types: ExternalDeviceType[], data?: VehicleStateObject): string[] => {
    return (
        data?.external_devices
            ?.map(externalDevice =>
                types.includes(externalDevice.device_type as ExternalDeviceType) ? externalDevice.device_id ?? '' : ''
            )
            .filter(externalDevice => externalDevice) ?? []
    );
};

export const trailersTrackingModelRN = (types: ExternalDeviceType[], data?: TrackingModel): string[] => {
    return (
        data?.externalDevices
            ?.map(externalDevice =>
                types.includes(externalDevice.device_type as ExternalDeviceType)
                    ? externalDevice.metadata?.registration_number ?? ''
                    : ''
            )
            .filter(externalDevice => externalDevice) ?? []
    );
};
