/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportTemplateTransport,
    TransportTemplateTransportFromJSON,
    TransportTemplateTransportFromJSONTyped,
    TransportTemplateTransportToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportTemplate
 */
export interface TransportTemplate {
    /**
     * 
     * @type {TransportTemplateTransport}
     * @memberof TransportTemplate
     */
    transport: TransportTemplateTransport;
    /**
     * 
     * @type {number}
     * @memberof TransportTemplate
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof TransportTemplate
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransportTemplate
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof TransportTemplate
     */
    deactivatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransportTemplate
     */
    deactivatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportTemplate
     */
    previousVersionId?: string;
}

export function TransportTemplateFromJSON(json: any): TransportTemplate {
    return TransportTemplateFromJSONTyped(json, false);
}

export function TransportTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transport': TransportTemplateTransportFromJSON(json['transport']),
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'deactivatedAt': !exists(json, 'deactivated_at') ? undefined : (new Date(json['deactivated_at'])),
        'deactivatedBy': !exists(json, 'deactivated_by') ? undefined : json['deactivated_by'],
        'previousVersionId': !exists(json, 'previous_version_id') ? undefined : json['previous_version_id'],
    };
}

export function TransportTemplateToJSON(value?: TransportTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transport': TransportTemplateTransportToJSON(value.transport),
        'client_id': value.clientId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'deactivated_at': value.deactivatedAt === undefined ? undefined : (value.deactivatedAt.toISOString()),
        'deactivated_by': value.deactivatedBy,
        'previous_version_id': value.previousVersionId,
    };
}


