import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import cn from 'classnames';
import { SortableElement, SortableContainer, SortEvent, SortEndHandler, SortEnd } from 'react-sortable-hoc';
import { Button, Tag, Tooltip } from 'common/components';
import { MonitoredObjectFeFleetTemperatureSensor } from 'generated/new-main';
import { addPlusCircleBoldWhite, checkCircleBlue, closeCircleBlue, info, pencilEditAlt } from 'resources/images/common';
import { chassis, dragAndDrop, zoneDelete } from 'resources/images/coldchain';
import { MAX_TRAILER_TEMPERATURE_ZONES } from 'domain-constants';
import { InputField } from 'common/fields';
import qa from 'qa-selectors';

interface SensorProps {
    sensor: MonitoredObjectFeFleetTemperatureSensor;
    editable: boolean;
    onSensorNameChange?: (sensorId: string, sensorName: string) => void;
}

interface Props {
    sensors?: MonitoredObjectFeFleetTemperatureSensor[];
    zones: number[];
    onZoneAddClick?: () => void;
    onZoneDeleteClick?: (zone: number) => void;
    onSensorDragEnd?: (sensorId: string, targetZone: number) => void;
    onSensorNameChange?: (sensorId: string, name: string) => void;
}

export default function TemperatureZonesEditor(props: Props) {
    const { t } = useTranslation();

    function onSensorDragEnd(sensorId: string, targetZone: number) {
        if (props.zones.includes(targetZone)) {
            props.onSensorDragEnd?.(sensorId, targetZone);
        }
    }

    return props.sensors ? (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h4 className="temperature-zones-heading">{t('ManagementFleet.unitsAndSensors')}</h4>
                </Col>
                <Col>
                    <Tooltip title={t('Coldchain.zoneEditorInfo') as string} placement="left">
                        <img src={info} alt="info" />
                    </Tooltip>
                </Col>
            </Row>
            <Row className="temperature-zones" align="middle">
                {props.zones.length < MAX_TRAILER_TEMPERATURE_ZONES && (
                    <Col span={props.zones.length < MAX_TRAILER_TEMPERATURE_ZONES ? 4 : 0}>
                        <p>
                            <Button
                                className="temperature-zones-add-btn-plus"
                                type="link"
                                icon={<img src={addPlusCircleBoldWhite} alt="add" />}
                                onClick={props.onZoneAddClick}
                            />
                            <Button className="temperature-zones-add-btn" type="link" onClick={props.onZoneAddClick}>
                                {t('Coldchain.addZone')}
                            </Button>
                        </p>
                    </Col>
                )}
                <Col
                    span={props.zones.length < MAX_TRAILER_TEMPERATURE_ZONES ? 20 : 24}
                    className="temperature-zones-trailer"
                >
                    <div className="temperature-zones-trailer-inner">
                        <SortableComponent
                            sensors={props.sensors}
                            zones={props.zones}
                            onSensorDragEnd={onSensorDragEnd}
                            onZoneDeleteClick={props.onZoneDeleteClick}
                            onSensorNameChange={props.onSensorNameChange}
                        />
                    </div>
                </Col>
            </Row>
            <Row justify="end" className="temperature-zones-chassis">
                <Col>
                    <img src={chassis} alt="chassis" />
                </Col>
            </Row>
        </>
    ) : null;
}

function TargetZone() {
    const { t } = useTranslation();
    return (
        <div className="temperature-zones-placeholder">
            <div className="temperature-zones-placeholder-content">
                <img src={dragAndDrop} alt="zone" />
                <div>{t('Coldchain.dragAndDrop')}</div>
            </div>
        </div>
    );
}

const SortableSensor = SortableElement((props: SensorProps) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState<boolean>(false);
    const [sensorName, setSensorName] = useState<string>(props.sensor.sensorName);

    return (
        <Tooltip
            key={props.sensor.id}
            title={`${t('ManagementFleet.sn')}: ${props.sensor.serialNumber}`}
            placement="top"
            mouseLeaveDelay={0}
        >
            <Tag key={props.sensor.id} className={cn('sortable-tag', { 'sortable-tag-edit': edit })}>
                {edit ? (
                    <>
                        <InputField
                            autoFocus
                            name="sensorName"
                            value={sensorName}
                            onChange={e => setSensorName(e.target.value)}
                            qa={qa.coldChain.sensor.fieldSensorName}
                        />
                        <Button
                            type="link"
                            icon={
                                <img
                                    src={closeCircleBlue}
                                    alt="edit"
                                    onClick={() => {
                                        setSensorName(props.sensor.sensorName);
                                        setEdit(false);
                                    }}
                                />
                            }
                            qa={qa.coldChain.sensor.btnCancel}
                        />
                        <Button
                            type="link"
                            icon={
                                <img
                                    src={checkCircleBlue}
                                    alt="cancel"
                                    onClick={() => {
                                        setEdit(false);
                                        props.sensor.id && props.onSensorNameChange?.(props.sensor.id, sensorName);
                                    }}
                                />
                            }
                            disabled={!sensorName.length}
                            qa={qa.coldChain.sensor.btnSave}
                        />
                    </>
                ) : (
                    <>
                        <span className="temperature-zones-zone-sensor-name">{props.sensor.sensorName}</span>
                        {props.editable && (
                            <Button
                                type="link"
                                icon={<img src={pencilEditAlt} alt="edit" />}
                                onClick={() => setEdit(true)}
                                qa={qa.coldChain.sensor.btnEdit}
                            />
                        )}
                    </>
                )}
            </Tag>
        </Tooltip>
    );
});

const SortableList = SortableContainer(
    (props: {
        sensors: MonitoredObjectFeFleetTemperatureSensor[];
        zones: number[];
        hoveringZone: number;
        isDragging: boolean;
        setIsDragging: any;
        onSortEnd?: SortEndHandler;
        onZoneMouseEnter: (zone: number) => void;
        onZoneDeleteClick?: (zone: number) => void;
        onSensorNameChange?: (sensorId: string, sensorName: string) => void;
    }) => (
        <div className="temperature-zones-container">
            {props.zones?.map((zone, indexZone) => (
                <div
                    key={indexZone}
                    className={cn('temperature-zones-zone', {
                        'temperature-zones-zone-target': props.isDragging,
                        'temperature-zones-zone-empty':
                            props.sensors?.filter(sensor => sensor.sensorZone === zone).length === 0
                    })}
                    onMouseEnter={() => props.onZoneMouseEnter(zone)}
                    onMouseLeave={() => props.onZoneMouseEnter(0)}
                >
                    {props.sensors
                        ?.filter(sensor => sensor.sensorZone === zone)
                        .map((sensor, indexSensor) => (
                            <SortableSensor
                                key={sensor.id}
                                sensor={sensor}
                                editable={true}
                                collection={zone}
                                index={indexSensor}
                                onSensorNameChange={props.onSensorNameChange}
                            />
                        ))}

                    {props.sensors?.filter(sensor => sensor.sensorZone === zone).length === 0 && <TargetZone />}

                    {indexZone > 0 && (
                        <Button
                            size="small"
                            type="link"
                            className="temperature-zones-delete"
                            icon={<img src={zoneDelete} alt="zoneDelete" />}
                            onClick={() => props.onZoneDeleteClick?.(zone)}
                        />
                    )}
                    <span className="temperature-zones-zone-index">{indexZone + 1}</span>
                </div>
            ))}
        </div>
    )
);

const SortableComponent = (props: {
    sensors: MonitoredObjectFeFleetTemperatureSensor[];
    zones: number[];
    onSensorDragEnd?: (sensorId: string, targetZone: number) => void;
    onZoneDeleteClick?: (zone: number) => void;
    onSensorNameChange?: (sensorId: string, sensorName: string) => void;
}) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [hoveringZone, setHoveringZone] = useState<number>(0);

    function onSortEnd(sort: SortEnd, _event: SortEvent) {
        setIsDragging(false);
        props.onSensorDragEnd?.(
            props.sensors.filter(s => s.sensorZone === sort.collection)?.[sort.oldIndex].id ?? '',
            hoveringZone
        );
        document.body.style.cursor = 'auto';
    }

    return (
        <div>
            <SortableList
                sensors={props.sensors}
                zones={props.zones}
                updateBeforeSortStart={() => setIsDragging(true)}
                hoveringZone={hoveringZone}
                onZoneMouseEnter={setHoveringZone}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
                onSortEnd={onSortEnd}
                onSortStart={() => (document.body.style.cursor = 'grabbing')}
                onZoneDeleteClick={props.onZoneDeleteClick}
                onSensorNameChange={props.onSensorNameChange}
            />
        </div>
    );
};
