/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomPlace,
    CustomPlaceFromJSON,
    CustomPlaceToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface CustomPlaceFindV1PlacesCustomPlaceGetRequest {
    id?: string;
}

/**
 * no description
 */
export class CustomPlaceApi extends runtime.BaseAPI {

    /**
     * Custom Place Find
     */
    async customPlaceFindV1PlacesCustomPlaceGetRaw(requestParameters: CustomPlaceFindV1PlacesCustomPlaceGetRequest): Promise<runtime.ApiResponse<Array<CustomPlace>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/custom_place`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomPlaceFromJSON));
    }

    /**
     * Custom Place Find
     */
    async customPlaceFindV1PlacesCustomPlaceGet(requestParameters: CustomPlaceFindV1PlacesCustomPlaceGetRequest): Promise<Array<CustomPlace>> {
        const response = await this.customPlaceFindV1PlacesCustomPlaceGetRaw(requestParameters);
        return await response.value();
    }

}
