/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20028,
    InlineResponse20028FromJSON,
    InlineResponse20028ToJSON,
} from '../models';

export interface MonitoredObjectTypeListRequest {
    limit?: number;
    offset?: number;
}

/**
 * no description
 */
export class MonitoredObjectTypeApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectTypeListRaw(requestParameters: MonitoredObjectTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20028>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20028FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeList(requestParameters: MonitoredObjectTypeListRequest): Promise<InlineResponse20028> {
        const response = await this.monitoredObjectTypeListRaw(requestParameters);
        return await response.value();
    }

}
