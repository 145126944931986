import { Tooltip } from 'common/components';
import * as PlannerIcons from 'resources/images/planner';
import cn from 'classnames';
import qa from 'qa-selectors';

interface Props {
    type?: 'warning' | 'error';
    tooltip?: string | React.ReactNode;
    size?: 'small' | 'medium' | 'big';
    className?: string;
}

export function PuescWarning(props: Props) {
    return (
        <>
            {props.tooltip ? (
                <Tooltip className={props.className ?? ''} title={props.tooltip}>
                    <img
                        className={cn('puesc-warning-new', {
                            'size-small': props.size === 'small',
                            'size-medium': props.size === 'medium' || props.size === undefined,
                            'size-big': props.size === 'big'
                        })}
                        src={
                            props.type && props.type === 'error'
                                ? PlannerIcons.PuescValidationError
                                : PlannerIcons.PuescWarning
                        }
                        alt="puesc-warning"
                        data-qa={qa.planner.puescWarning}
                    />
                </Tooltip>
            ) : (
                <img
                    className={cn(`puesc-warning ${props.className}`, {
                        'size-small': props.size === 'small',
                        'size-medium': props.size === 'medium' || props.size === undefined,
                        'size-big': props.size === 'big'
                    })}
                    src={PlannerIcons.PuescWarning}
                    alt="puesc-warning"
                    data-qa={qa.planner.puescWarning}
                />
            )}
        </>
    );
}
