/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileApp
 */
export interface MobileApp {
    /**
     * 
     * @type {string}
     * @memberof MobileApp
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MobileApp
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof MobileApp
     */
    packageName: string;
    /**
     * 
     * @type {string}
     * @memberof MobileApp
     */
    appName: string;
    /**
     * 
     * @type {string}
     * @memberof MobileApp
     */
    versionName: string;
    /**
     * 
     * @type {number}
     * @memberof MobileApp
     */
    versionCode: number;
    /**
     * 
     * @type {Date}
     * @memberof MobileApp
     */
    createdAt: Date;
}

export function MobileAppFromJSON(json: any): MobileApp {
    return MobileAppFromJSONTyped(json, false);
}

export function MobileAppFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileApp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'link': json['link'],
        'packageName': json['package_name'],
        'appName': json['app_name'],
        'versionName': json['version_name'],
        'versionCode': json['version_code'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function MobileAppToJSON(value?: MobileApp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'link': value.link,
        'package_name': value.packageName,
        'app_name': value.appName,
        'version_name': value.versionName,
        'version_code': value.versionCode,
        'created_at': (value.createdAt.toISOString()),
    };
}


