import { AddressIdentification, VehicleIdentification, Theme } from 'common/components/Settings';
import { ExpenseState, Aggregator } from 'logic/statistics/statistics-expenses';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import { Role } from './auth';
import { PlannedTransport, RouteType } from 'generated/routing-api';
import { confDefault } from 'conf';
import { ReadOnlyUser } from 'generated/new-main';
import { LayerTypes } from 'modules/map/MapModule';
import { FuelStatsGroupByEntity, FuelStatsGroupByTimespan, Transport, TransportTollCost } from 'generated/backend-api';
import { FleetActive, FleetType } from 'modules/management/modules/fleet/FleetModule';

export interface SettingsPersist {
    lang: string;
    demoMode: { active: boolean; roles: Role[] };
    addressIdentification: AddressIdentification;
    vehicleIdentification: VehicleIdentification;
    theme: Theme;
    tracking: typeof confDefault['settings']['tracking'];
    map: {
        style: LayerTypes;
        traffic: boolean;
        vehicleClusteringEnabled: boolean;
        parking: boolean;
        fuel: boolean;
        wash: boolean;
        poi: boolean;
    };
    statistics: {
        journeysActivity: {
            expanded: boolean;
            filter: {
                driver: string;
                vehicle: string;
                trailer: string;
            };
            disabledCoachPromo: boolean;
        };
        fuelConsumption: {
            filter: {
                vehicles: string[];
                drivers: string[];
                groupByTimespan: FuelStatsGroupByTimespan;
                groupByEntity: FuelStatsGroupByEntity;
                shortActivity: boolean;
                fuelSuspiciousActivity: boolean;
            };
        };
        aetr: {
            filter: {
                vehicles: string[];
                drivers: string[];
            };
        };
        expenses: {
            filter: {
                vehicles: string[];
                type: ExpenseType;
                payment: ExpenseState;
                source: ExpenseSource;
                aggregator: Aggregator;
            };
        };
        coldChain: {
            filter: {
                vehicles: string[];
                trailers: string[];
                profiles: string[];
            };
        };
        maintenance: {
            filter: {
                vehicles: string[];
                trailers: string[];
                drivers: string[];
                taskTypes: string[];
                intervals: string[];
                status: string[];
                dueDateGte: string;
                dueDateLte: string;
            };
        };
    };
    management: {
        users: {
            // filter: UserFilter;
            lastCreated?: ReadOnlyUser;
            lastCreatedAt?: string;
        };
        fleet: {
            filter: {
                fleetActive?: FleetActive;
                fleetType?: FleetType;
            };
        };
    };
    scheduling: {
        routeOverview: {
            filter: {
                vehicles: string[];
                drivers: string[];
                transportState: string[];
            };
        };
    };
    dispatcherBoard: {
        eta: boolean;
        break: boolean;
        expanded: boolean;
        startDate: string;
    };
    planner: {
        transport?: Transport;
        possibleTransports?: PlannedTransport[];
        tollCostByType?: {
            [key in RouteType]?: TransportTollCost;
        };
        defaultPuescActive?: boolean;
        hideAetrBreaksAlert?: boolean;
    };
    driverBehavior: {
        date?: string;
    };
}

export class Settings<T extends object> {
    readonly name: string;
    readonly props: T;
    private _onChange: ((props: Partial<T>) => void)[];

    constructor(props: T, name: string = 'settings') {
        this.props = props;
        this.name = name;
        this._onChange = [];
        if (window.localStorage[this.name]) {
            this.storeLoad();
        } else {
            this.storeSave();
        }
    }

    getProps(): T {
        return this.props;
    }

    setProps(props: T): this {
        Object.keys(props).forEach(p => ((this.props as any)[p] = props[p]));

        this.storeSave();
        this._onChange.forEach(cb => {
            cb(props);
        });
        return this;
    }

    getProp<P extends T, K extends keyof P>(key: K): P[K] {
        return (this.props as P)[key];
    }

    setProp<P extends T, K extends keyof P>(key: K, value: P[K]): this {
        (this.props as any)[key] = value;
        this.storeSave();
        const props = {} as Partial<T>;
        (props as any)[key] = value;
        this._onChange.forEach(cb => {
            cb(props);
        });
        return this;
    }

    onChange(callback: (props: Partial<T>) => void): this {
        this._onChange.push(callback);
        return this;
    }

    storeLoad(): this {
        const data = JSON.parse(window.localStorage[this.name]);
        Object.keys(data).forEach(k => (this.props[k] = data[k]));
        return this;
    }

    storeSave(): this {
        window.localStorage[this.name] = JSON.stringify(this.props);
        return this;
    }

    storeClear(): this {
        delete window.localStorage[this.name];
        return this;
    }
}

// Test

// const x = { n: 2, s: "s" };
// const s = new Settings<typeof x>(x);
// typeof s.getProp("n"); // number
// typeof s.getProp("s"); // string
