import DateTimeRangePicker from './components/DateTimeRangePicker';
import DateTimePicker from './components/DateTimePicker';
import MonthPicker from './components/MonthPicker';

const DatePicker = {
    DateTimeRangePicker,
    DateTimePicker,
    MonthPicker
};

export default DatePicker;
