/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MuStreamEditAuxDataDriverId,
    MuStreamEditAuxDataDriverIdFromJSON,
    MuStreamEditAuxDataDriverIdFromJSONTyped,
    MuStreamEditAuxDataDriverIdToJSON,
    MuStreamEditAuxDataExternalDeviceUnpair,
    MuStreamEditAuxDataExternalDeviceUnpairFromJSON,
    MuStreamEditAuxDataExternalDeviceUnpairFromJSONTyped,
    MuStreamEditAuxDataExternalDeviceUnpairToJSON,
    MuStreamEditAuxDataExternalDevices,
    MuStreamEditAuxDataExternalDevicesFromJSON,
    MuStreamEditAuxDataExternalDevicesFromJSONTyped,
    MuStreamEditAuxDataExternalDevicesToJSON,
    MuStreamEditAuxDataOdometerSnapshot,
    MuStreamEditAuxDataOdometerSnapshotFromJSON,
    MuStreamEditAuxDataOdometerSnapshotFromJSONTyped,
    MuStreamEditAuxDataOdometerSnapshotToJSON,
    MuStreamEditAuxDataTripType,
    MuStreamEditAuxDataTripTypeFromJSON,
    MuStreamEditAuxDataTripTypeFromJSONTyped,
    MuStreamEditAuxDataTripTypeToJSON,
    MuStreamEditType,
    MuStreamEditTypeFromJSON,
    MuStreamEditTypeFromJSONTyped,
    MuStreamEditTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MuStreamEdit
 */
export interface MuStreamEdit {
    /**
     * 
     * @type {MuStreamEditType}
     * @memberof MuStreamEdit
     */
    type: MuStreamEditType;
    /**
     * 
     * @type {string}
     * @memberof MuStreamEdit
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {Date}
     * @memberof MuStreamEdit
     */
    timestamp?: Date;
    /**
     * 
     * @type {MuStreamEditAuxDataTripType | MuStreamEditAuxDataOdometerSnapshot | MuStreamEditAuxDataExternalDevices | MuStreamEditAuxDataExternalDeviceUnpair | MuStreamEditAuxDataDriverId}
     * @memberof MuStreamEdit
     */
    auxData?: MuStreamEditAuxDataTripType | MuStreamEditAuxDataOdometerSnapshot | MuStreamEditAuxDataExternalDevices | MuStreamEditAuxDataExternalDeviceUnpair | MuStreamEditAuxDataDriverId;
    /**
     * 
     * @type {boolean}
     * @memberof MuStreamEdit
     */
    handled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MuStreamEdit
     */
    clientId?: number;
}

export function MuStreamEditFromJSON(json: any): MuStreamEdit {
    return MuStreamEditFromJSONTyped(json, false);
}

export function MuStreamEditFromJSONTyped(json: any, ignoreDiscriminator: boolean): MuStreamEdit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': MuStreamEditTypeFromJSON(json['type']),
        'monitoredObjectId': json['monitored_object_id'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        // 'auxData': !exists(json, 'aux_data') ? undefined : MuStreamEditAuxDataTripType | MuStreamEditAuxDataOdometerSnapshot | MuStreamEditAuxDataExternalDevices | MuStreamEditAuxDataExternalDeviceUnpair | MuStreamEditAuxDataDriverIdFromJSON(json['aux_data']),
        'handled': !exists(json, 'handled') ? undefined : json['handled'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function MuStreamEditToJSON(value?: MuStreamEdit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MuStreamEditTypeToJSON(value.type),
        'monitored_object_id': value.monitoredObjectId,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        // 'aux_data': MuStreamEditAuxDataTripType | MuStreamEditAuxDataOdometerSnapshot | MuStreamEditAuxDataExternalDevices | MuStreamEditAuxDataExternalDeviceUnpair | MuStreamEditAuxDataDriverIdToJSON(value.auxData),
        'handled': value.handled,
        'client_id': value.clientId,
    };
}


