/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20020,
    InlineResponse20020FromJSON,
    InlineResponse20020ToJSON,
    ReadOnlyModule,
    ReadOnlyModuleFromJSON,
    ReadOnlyModuleToJSON,
    WriteOnlyModule,
    WriteOnlyModuleFromJSON,
    WriteOnlyModuleToJSON,
} from '../models';

export interface ModuleCreateRequest {
    data: WriteOnlyModule;
}

export interface ModuleDeleteRequest {
    id: number;
}

export interface ModuleListRequest {
    limit?: number;
    offset?: number;
}

export interface ModulePartialUpdateRequest {
    id: number;
    data: WriteOnlyModule;
}

export interface ModuleReadRequest {
    id: number;
}

export interface ModuleUpdateRequest {
    id: number;
    data: WriteOnlyModule;
}

/**
 * no description
 */
export class ModuleApi extends runtime.BaseAPI {

    /**
     */
    async moduleCreateRaw(requestParameters: ModuleCreateRequest): Promise<runtime.ApiResponse<ReadOnlyModule>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling moduleCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyModuleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyModuleFromJSON(jsonValue));
    }

    /**
     */
    async moduleCreate(requestParameters: ModuleCreateRequest): Promise<ReadOnlyModule> {
        const response = await this.moduleCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async moduleDeleteRaw(requestParameters: ModuleDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyModule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling moduleDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyModuleFromJSON(jsonValue));
    }

    /**
     */
    async moduleDelete(requestParameters: ModuleDeleteRequest): Promise<ReadOnlyModule> {
        const response = await this.moduleDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async moduleListRaw(requestParameters: ModuleListRequest): Promise<runtime.ApiResponse<InlineResponse20020>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20020FromJSON(jsonValue));
    }

    /**
     */
    async moduleList(requestParameters: ModuleListRequest): Promise<InlineResponse20020> {
        const response = await this.moduleListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async modulePartialUpdateRaw(requestParameters: ModulePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyModule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling modulePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling modulePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyModuleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyModuleFromJSON(jsonValue));
    }

    /**
     */
    async modulePartialUpdate(requestParameters: ModulePartialUpdateRequest): Promise<ReadOnlyModule> {
        const response = await this.modulePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async moduleReadRaw(requestParameters: ModuleReadRequest): Promise<runtime.ApiResponse<ReadOnlyModule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling moduleRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyModuleFromJSON(jsonValue));
    }

    /**
     */
    async moduleRead(requestParameters: ModuleReadRequest): Promise<ReadOnlyModule> {
        const response = await this.moduleReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async moduleUpdateRaw(requestParameters: ModuleUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyModule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling moduleUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling moduleUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/module/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyModuleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyModuleFromJSON(jsonValue));
    }

    /**
     */
    async moduleUpdate(requestParameters: ModuleUpdateRequest): Promise<ReadOnlyModule> {
        const response = await this.moduleUpdateRaw(requestParameters);
        return await response.value();
    }

}
