import cn from 'classnames';
import moment, { duration } from 'moment';
import numeral from 'numeral';
import React, { MouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Loading } from 'common/components/Loading';
import {
    JourneysModel,
    JourneysSumModel,
    OdometerAdjustmentData,
    State,
    ActivityModel,
    IsPrivateData
} from '../JourneysModule';
import { KM, L, H, M, KG, ODOMETER_LIMIT_EDIT_DAYS } from 'domain-constants';
import { Input, Select } from 'common/components/Form';
import { ReadOnlyOdometerAdjustment } from 'generated/new-main';
import { Button, Tooltip } from 'common/components';
import { moduleResolver, Role } from 'logic/auth';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { Row } from 'antd';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import qa from 'qa-selectors';
import { NoData } from 'common/components/NoData';
import { noGpsAlt } from 'resources/images/common';
import { MoneyEuroFilledSmall } from 'resources/images/journeys-activity';

const distance = (distance: number) => distance / 1000;

const timeFromTimestamp = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    return `${hours < 10 ? '0' : ''}${hours}:${min < 10 ? '0' : ''}${min}`;
};

interface Props extends WithTranslation {
    adjustment?: State['adjustment'];
    isPrivate?: State['isPrivate'];
    expanded: boolean;
    vehicle?: string;
    trailer?: string;
    driver?: string;
    data: {
        rows?: JourneysModel[];
        sum?: JourneysSumModel;
        odoAdjustements?: ReadOnlyOdometerAdjustment[];
    };
    selectedJourney?: string;
    loading: boolean;
    showConsumption?: boolean;
    roles: Role[];
    warningSwitzerland: boolean;
    onIsPrivateEditIconClick?: (data: IsPrivateData) => void;
    onIsPrivateSelectChange?: (text: string) => void;
    onAdjustmentEditIconClick?: (data: OdometerAdjustmentData) => void;
    onAdjustmentInputChange?: (text: string) => void;
    onAdjustmentInputKeyDown?: (key: string) => void;
    onCreateCargoClick?: (date: string) => void;
    onJourneySelect?: (id: string) => void;
    onRowExpand?: (id: string) => void;
}

function JourneysTable(props: Props) {
    function onExpandRow(id: string): void {
        props.onRowExpand?.(id);
    }

    function onJourneySelect(id: string, e: MouseEvent<HTMLDivElement>): void {
        e.stopPropagation();
        props.onJourneySelect?.(id);
    }

    function onCreateCargoClick(date: string, e: MouseEvent<HTMLDivElement>): void {
        e.stopPropagation();
        props.onCreateCargoClick?.(date);
    }

    function onEditOdometerIconClick(data: OdometerAdjustmentData, e: MouseEvent<HTMLSpanElement>): void {
        e.stopPropagation();
        props.onAdjustmentEditIconClick?.(data);
    }

    function onIsPrivateEditIconClick(data: IsPrivateData, e: MouseEvent<HTMLSpanElement>): void {
        e.stopPropagation();
        props.onIsPrivateEditIconClick?.(data);
    }

    function onIsPrivateSelectChange(value: string) {
        props.onIsPrivateSelectChange?.(value);
    }

    function onAdjustmentInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key;
        props.onAdjustmentInputKeyDown?.(key);
    }

    function onAdjustmentInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const text = e.target.value;
        props.onAdjustmentInputChange?.(text);
    }

    function hasOdoAdjustement(am: ActivityModel) {
        const odometerAdjustment = props.data.odoAdjustements?.find(a => {
            return moment.utc(a.date).toISOString() === moment.utc(am.odometerSnapshotEndTimestamp).toISOString();
        });
        return odometerAdjustment;
    }

    const { t, data, vehicle, driver, trailer } = props;
    const head: [string, number][] = [
        [t('common.vehicle'), 0],
        [t('common.driver'), 1],
        [t('JourneysTable.driver2'), 16],
        [t('common.trailer'), 2],
        [t('common.from'), 3],
        [t('common.to'), 4],
        [t('JourneysTable.startLocation'), 5],
        [t('JourneysTable.stopLocation'), 6],
        ['', 7],
        [t('common.type'), 8],
        [t('JourneysTable.duration'), 9],
        [t('JourneysTable.stopped'), 10],
        [t('JourneysTable.distance'), 11],
        [t('JourneysTable.odometerEnd'), 12],
        [t('JourneysTable.refueledLitres'), 13],
        [t('common.averageConsumption'), 14],
        [t('JourneysTable.co2'), 15]
    ];

    return (
        <div className="journeys-table t-default">
            {props.loading ? (
                <Loading />
            ) : (
                <>
                    {data?.rows?.length && data.rows.length > 0 ? (
                        data.rows.map(d => {
                            const refueledLiters = d.activities.reduce((sum, num: any) => {
                                return sum + (num.units ?? 0);
                            }, 0);

                            const driver2Column = d.activities.find(a => a.driver2 !== '') !== undefined;
                            const trailerColumn = d.activities.find(a => a.trailerRn !== '') !== undefined;
                            return (
                                <div
                                    key={d.id}
                                    className={cn('t-card', {
                                        't-primary': d.expanded,
                                        't-light-primary': !d.expanded
                                    })}
                                    onClick={onExpandRow.bind(undefined, d.id)}
                                    data-qa={qa.journeys.table.sectionDay}
                                >
                                    <div className="t-bar t-medium">
                                        <div
                                            className={cn('t-bar-item', { expanded: d.expanded })}
                                            data-qa={qa.journeys.table.fieldDate}
                                        >
                                            {moment(d.date).format('L')}
                                        </div>
                                        <Button
                                            className="table-head-button"
                                            type={d.expanded ? 'primary' : 'default'}
                                            size="small"
                                            icon={
                                                <i
                                                    className={cn('fa', {
                                                        'fa-chevron-down': d.expanded,
                                                        'fa-chevron-right': !d.expanded
                                                    })}
                                                />
                                            }
                                            data-qa={qa.journeys.table.day.btnExpand}
                                        />
                                        <Button
                                            className="table-head-button"
                                            type="default"
                                            size="small"
                                            title={t('ExpensesCargo.createCargo')}
                                            icon={<img src={MoneyEuroFilledSmall} alt="money-eur" />}
                                            data-qa={qa.journeys.table.day.btnAddExpense}
                                            onClick={onCreateCargoClick.bind(undefined, moment(d.date).toISOString())}
                                        />
                                        <div className="journeys-table-label t-bar-item t-round t-center t-right">
                                            <i className="fa fa-route" title={t('JourneysTable.ridesThisDay')} />
                                            <span className="t-text-inverse">
                                                <span className="journeys-table-multiply">x</span>
                                                <span data-qa={qa.journeys.table.day.fieldActivitiesCount}>
                                                    {d.activitiesCount}
                                                </span>
                                            </span>
                                        </div>

                                        {d.expensesCount > 0 && (
                                            <div className="journeys-table-label t-bar-item t-round t-center t-right">
                                                <img alt="checkbox" src="/img-svg/journeysFuel.svg" />
                                                <span className="journeys-table-multiply">x</span>
                                                <span
                                                    className="t-text-inverse"
                                                    data-qa={qa.journeys.table.day.fieldExpensesCount}
                                                >
                                                    {d.expensesCount}
                                                </span>
                                            </div>
                                        )}

                                        <div
                                            className="position t-bar-item"
                                            title={
                                                d.activities[0].isPrivate
                                                    ? t('JourneysTable.isPrivate.true')
                                                    : d.activities[0].placeStart.name
                                            }
                                        >
                                            <div className="t-nowrap-with-elipses t-small t-padding-tiny">
                                                <i className="fa fa-map-marker" />
                                                <span className="location">
                                                    {d.activities[0].isPrivate ? (
                                                        t('JourneysTable.isPrivate.true')
                                                    ) : (
                                                        <AddressFormatter
                                                            key={d.activities[0].placeStart.name}
                                                            defaultAddress={d.activities[0].placeStart.name ?? ''}
                                                            addressStructured={
                                                                d.activities[0].placeStart.addressStructured
                                                            }
                                                            qa={qa.journeys.table.fieldPlaceStart}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        {!d.expanded && (
                                            <div
                                                className="position t-bar-item"
                                                title={
                                                    d.activities[d.activities.length - 1].isPrivate
                                                        ? t('JourneysTable.isPrivate.true')
                                                        : d.activities[d.activities.length - 1].placeEnd.name
                                                }
                                            >
                                                <div className="t-nowrap-with-elipses t-small t-padding-tiny">
                                                    <i className="fa fa-dot-circle" />
                                                    <span className="location">
                                                        {d.activities[d.activities.length - 1].isPrivate ? (
                                                            t('JourneysTable.isPrivate.true')
                                                        ) : (
                                                            <AddressFormatter
                                                                defaultAddress={
                                                                    d.activities[d.activities.length - 1].placeEnd
                                                                        .name ?? ''
                                                                }
                                                                addressStructured={
                                                                    d.activities[d.activities.length - 1].placeEnd
                                                                        .addressStructured
                                                                }
                                                                qa={qa.journeys.table.fieldPlaceEnd}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {d.expanded && (
                                        <div
                                            className={cn('journeys-activities-table', {
                                                'journeys-activities-table-light-vehicle':
                                                    d.activities[0].monitoredObjectType === FleetType.LIGHT_VEHICLE
                                            })}
                                            data-qa={qa.journeys.table.sectionActivities}
                                        >
                                            <table className="t-table">
                                                <thead>
                                                    <tr>
                                                        {head.map(
                                                            (headItem, index) =>
                                                                (props.expanded || (!props.expanded && index < 14)) && (
                                                                    <th
                                                                        key={index}
                                                                        title={headItem[0]}
                                                                        className={cn('t-border-secondary', {
                                                                            't-center': [
                                                                                7, 8, 9, 10, 11, 12, 13, 14, 15
                                                                            ].includes(headItem[1]),
                                                                            't-hide':
                                                                                (headItem[1] === 0 && vehicle) ||
                                                                                (headItem[1] === 1 && driver) ||
                                                                                (headItem[1] === 2 &&
                                                                                    (trailer || !trailerColumn)) ||
                                                                                ([2, 5, 10, 12, 13, 14].includes(
                                                                                    headItem[1]
                                                                                ) &&
                                                                                    !props.expanded) ||
                                                                                (headItem[1] === 8 &&
                                                                                    d.activities[0]
                                                                                        .monitoredObjectType ===
                                                                                        FleetType.VEHICLE) ||
                                                                                (headItem[1] === 13 &&
                                                                                    refueledLiters === 0) ||
                                                                                (headItem[1] === 15 &&
                                                                                    !props.showConsumption) ||
                                                                                (headItem[1] === 16 &&
                                                                                    (driver ||
                                                                                        !driver2Column ||
                                                                                        !props.expanded))
                                                                        })}
                                                                    >
                                                                        {headItem[0]}
                                                                        {[9, 10].includes(headItem[1]) && (
                                                                            <div className="units">
                                                                                ({H}:{M})
                                                                            </div>
                                                                        )}
                                                                        {[11, 12].includes(headItem[1]) && (
                                                                            <div className="units">({KM})</div>
                                                                        )}
                                                                        {[13].includes(headItem[1]) && (
                                                                            <div className="units">({L})</div>
                                                                        )}
                                                                        {[14].includes(headItem[1]) && (
                                                                            <div className="units">
                                                                                ({`${L} / 100${KM}`})
                                                                            </div>
                                                                        )}
                                                                        {[15].includes(headItem[1]) && (
                                                                            <div className="units">({KG})</div>
                                                                        )}
                                                                    </th>
                                                                )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {d.activities.map((a, i) => (
                                                        <tr
                                                            key={i}
                                                            onClick={onJourneySelect.bind(undefined, a.id)}
                                                            className={cn('t-hover-text-light-primary', {
                                                                't-text-primary': a.id === props.selectedJourney
                                                            })}
                                                            data-qa={qa.journeys.table.sectionActivity}
                                                        >
                                                            <td
                                                                className={cn({ 't-hide': vehicle })}
                                                                data-qa={qa.journeys.table.fieldVehicle}
                                                                title={a.vehicle}
                                                                data-index={0}
                                                            >
                                                                {a.vehicle}
                                                            </td>
                                                            <td
                                                                className={cn({ 't-hide': driver })}
                                                                data-qa={qa.journeys.table.fieldDriver}
                                                                title={a.driver}
                                                                data-index={1}
                                                            >
                                                                {a.driver}
                                                            </td>
                                                            <td
                                                                className={cn({
                                                                    't-hide':
                                                                        driver || !props.expanded || !driver2Column
                                                                })}
                                                                data-qa={qa.journeys.table.fieldDriver2}
                                                                title={a.driver2}
                                                                data-index={16}
                                                            >
                                                                {a.driver2}
                                                            </td>
                                                            <td
                                                                className={cn({
                                                                    't-hide':
                                                                        !props.expanded || trailer || !trailerColumn
                                                                })}
                                                                title={a.trailerRn}
                                                                data-qa={qa.journeys.table.fieldTrailer}
                                                                data-index={2}
                                                            >
                                                                {a.trailerRn}
                                                            </td>
                                                            <td data-index={3}>
                                                                {moment.utc(a.startTime).local().format('LT')}
                                                            </td>
                                                            <td data-index={4}>
                                                                {moment.utc(a.endTime).local().format('LT')}
                                                            </td>
                                                            <td
                                                                data-index={5}
                                                                className={cn({ 't-hide': !props.expanded })}
                                                            >
                                                                {a.isPrivate ? (
                                                                    <span>{t('JourneysTable.isPrivate.true')}</span>
                                                                ) : (
                                                                    <AddressFormatter
                                                                        defaultAddress={a.placeStart.name ?? ''}
                                                                        addressStructured={
                                                                            a.placeStart.addressStructured
                                                                        }
                                                                        qa={qa.journeys.table.fieldPlaceStart}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td data-index={6}>
                                                                {a.isPrivate ? (
                                                                    <span>{t('JourneysTable.isPrivate.true')}</span>
                                                                ) : props.warningSwitzerland &&
                                                                  !a.closed &&
                                                                  a.placeEnd.addressStructured?.some(
                                                                      a => a.countryCode === 'CH'
                                                                  ) ? (
                                                                    <span className="no-gps">
                                                                        <Tooltip
                                                                            placement="top"
                                                                            mouseLeaveDelay={0}
                                                                            title={t('TrackingTable.noGpsSwitzerland')}
                                                                        >
                                                                            <img src={noGpsAlt} alt="no-gps" />
                                                                        </Tooltip>
                                                                        CH,{' '}
                                                                        {t('TrackingTable.noGpsSwitzerlandLocation')}
                                                                    </span>
                                                                ) : (
                                                                    <AddressFormatter
                                                                        defaultAddress={a.placeEnd.name ?? ''}
                                                                        addressStructured={a.placeEnd.addressStructured}
                                                                        qa={qa.journeys.table.fieldPlaceEnd}
                                                                    />
                                                                )}
                                                                {a.closed === false && (
                                                                    <span>
                                                                        <i className="journeys-table-spinner fas fa-spinner" />
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td data-index={7} className={cn('t-border-secondary')}>
                                                                <div className="t-right">
                                                                    {a.expensesCount > 0 && (
                                                                        <span className="journeys-table-label t-bar-item t-round t-center">
                                                                            <img
                                                                                alt="checkbox"
                                                                                src="/img-svg/journeysFuel.svg"
                                                                            />
                                                                            <span className="journeys-table-multiply">
                                                                                x
                                                                            </span>
                                                                            <span
                                                                                className="t-text-inverse"
                                                                                data-qa={
                                                                                    qa.journeys.table.fieldExpensesCount
                                                                                }
                                                                            >
                                                                                {a.expensesCount}
                                                                            </span>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            {a.monitoredObjectType !== FleetType.VEHICLE && (
                                                                <td
                                                                    data-index={8}
                                                                    className="t-center t-border-secondary"
                                                                >
                                                                    {props.expanded &&
                                                                    a.monitoredObjectType === FleetType.LIGHT_VEHICLE &&
                                                                    a.idNew &&
                                                                    a.closed ? (
                                                                        props.isPrivate?.data?.activityIdNew ===
                                                                        a.idNew ? (
                                                                            <Select
                                                                                value={String(
                                                                                    props.isPrivate.data.state
                                                                                )}
                                                                                size="small"
                                                                                options={[
                                                                                    {
                                                                                        value: 'true',
                                                                                        label: t(
                                                                                            'JourneysTable.isPrivate.true'
                                                                                        )
                                                                                    },
                                                                                    {
                                                                                        value: 'false',
                                                                                        label: t(
                                                                                            'JourneysTable.isPrivate.false'
                                                                                        )
                                                                                    }
                                                                                ]}
                                                                                onSelect={value =>
                                                                                    onIsPrivateSelectChange(value)
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <span>
                                                                                {t(
                                                                                    `JourneysTable.isPrivate.${String(
                                                                                        a.isPrivate
                                                                                    )}`
                                                                                )}{' '}
                                                                                <span
                                                                                    className="t-padding-tiny t-ripple"
                                                                                    onClick={onIsPrivateEditIconClick.bind(
                                                                                        undefined,
                                                                                        {
                                                                                            state: a.isPrivate,
                                                                                            activityIdNew: a.idNew
                                                                                        }
                                                                                    )}
                                                                                    data-qa={qa.journeys.table.btnEdit}
                                                                                >
                                                                                    <i className="far fa-fw fa-edit" />
                                                                                </span>
                                                                            </span>
                                                                        )
                                                                    ) : (
                                                                        <span>
                                                                            {t(
                                                                                `JourneysTable.isPrivate.${String(
                                                                                    a.isPrivate
                                                                                )}`
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            )}
                                                            <td data-index={9} className="t-center t-border-secondary">
                                                                {duration(a.duration, 'seconds').format('hh:mm', {
                                                                    trim: false
                                                                })}
                                                            </td>
                                                            {props.expanded && (
                                                                <td
                                                                    data-index={10}
                                                                    className="t-center t-border-secondary"
                                                                >
                                                                    {a.stopped >= 0 &&
                                                                        duration(a.stopped, 'seconds').format('hh:mm', {
                                                                            trim: false
                                                                        })}
                                                                </td>
                                                            )}
                                                            <td data-index={11} className="t-center t-border-secondary">
                                                                {numeral(distance(a.distance)).format('0,0.0')}
                                                            </td>
                                                            {props.expanded && (
                                                                <>
                                                                    <td
                                                                        data-index={12}
                                                                        className="journeys-activities-odometer t-center t-border-secondary"
                                                                    >
                                                                        {a.monitoredObjectRoles.length === 0 ||
                                                                        !moduleResolver(a.monitoredObjectRoles)
                                                                            ?.map(modul => modul)
                                                                            .includes(5) ||
                                                                        !a.odometerSnapshotEndTimestamp ? (
                                                                            <>
                                                                                {props.adjustment?.data?.activityId ===
                                                                                a.id ? (
                                                                                    <Row justify="center">
                                                                                        <Input
                                                                                            value={
                                                                                                props.adjustment.data
                                                                                                    .state
                                                                                            }
                                                                                            onChange={
                                                                                                onAdjustmentInputChange
                                                                                            }
                                                                                            onKeyDown={
                                                                                                onAdjustmentInputKeyDown
                                                                                            }
                                                                                            className={cn({
                                                                                                error: props.adjustment
                                                                                                    .data.stateErr
                                                                                            })}
                                                                                        />
                                                                                    </Row>
                                                                                ) : (
                                                                                    <Tooltip
                                                                                        overlay={
                                                                                            hasOdoAdjustement(a) && (
                                                                                                <div className="odo-change-overlay">
                                                                                                    <strong>
                                                                                                        {t(
                                                                                                            'JourneysTable.odoChange.title'
                                                                                                        )}
                                                                                                    </strong>
                                                                                                    <div className="odo-change-overlay-row">
                                                                                                        <span>
                                                                                                            {t(
                                                                                                                'JourneysTable.odoChange.date'
                                                                                                            )}
                                                                                                            :
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            {moment(
                                                                                                                hasOdoAdjustement(
                                                                                                                    a
                                                                                                                )?.date
                                                                                                            ).format(
                                                                                                                'L'
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        overlayClassName="odo-change-overlay-wrapper"
                                                                                    >
                                                                                        <Row justify="center">
                                                                                            {hasOdoAdjustement(a) &&
                                                                                                '*'}
                                                                                            {`${numeral(
                                                                                                distance(
                                                                                                    hasOdoAdjustement(a)
                                                                                                        ?.state
                                                                                                        ? hasOdoAdjustement(
                                                                                                              a
                                                                                                          )?.state!
                                                                                                        : a.odometerEnd
                                                                                                )
                                                                                            ).format('0,0')}`}
                                                                                            {a.closed === true &&
                                                                                                moment
                                                                                                    .utc(a.startTime)
                                                                                                    .local()
                                                                                                    .isSameOrAfter(
                                                                                                        moment()
                                                                                                            .startOf(
                                                                                                                'day'
                                                                                                            )
                                                                                                            .local()
                                                                                                            .subtract(
                                                                                                                ODOMETER_LIMIT_EDIT_DAYS,
                                                                                                                'days'
                                                                                                            )
                                                                                                    ) && (
                                                                                                    <span
                                                                                                        className="odometer-edit-icon"
                                                                                                        onClick={onEditOdometerIconClick.bind(
                                                                                                            undefined,
                                                                                                            {
                                                                                                                date: new Date(
                                                                                                                    a.odometerSnapshotEndTimestamp!
                                                                                                                ),
                                                                                                                state: String(
                                                                                                                    a.odometerEnd
                                                                                                                ),
                                                                                                                activityId:
                                                                                                                    a.id
                                                                                                            }
                                                                                                        )}
                                                                                                    >
                                                                                                        <i className="far fa-fw fa-edit" />
                                                                                                    </span>
                                                                                                )}
                                                                                        </Row>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Row justify="center">
                                                                                {numeral(
                                                                                    distance(a.odometerEnd)
                                                                                ).format('0,0')}
                                                                            </Row>
                                                                        )}
                                                                    </td>
                                                                    {props.expanded && (
                                                                        <>
                                                                            {refueledLiters > 0 && (
                                                                                <td
                                                                                    data-index={13}
                                                                                    className="t-center t-border-secondary"
                                                                                >
                                                                                    {numeral(a.units).format('0,0.0') ??
                                                                                        '-'}
                                                                                </td>
                                                                            )}
                                                                            {props.showConsumption && (
                                                                                // Consumption in trip rows is always shown as l / 100km
                                                                                <td
                                                                                    data-index={14}
                                                                                    className="t-center t-border-secondary"
                                                                                >
                                                                                    {numeral(a.afcL100Km).format(
                                                                                        '0,0.0'
                                                                                    )}{' '}
                                                                                </td>
                                                                            )}
                                                                            <td
                                                                                data-index={15}
                                                                                className="t-center t-border-secondary"
                                                                            >
                                                                                {numeral(a.co2Emission / 1000).format(
                                                                                    '0,0.0'
                                                                                )}{' '}
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                {props.expanded && d.sum && (
                                                    <tbody>
                                                        <tr data-qa={qa.journeys.table.sectionActivitySum}>
                                                            <td
                                                                className={cn({ 't-hide': vehicle })}
                                                                data-qa={qa.journeys.table.fieldVehicle}
                                                            />
                                                            <td
                                                                className={cn({ 't-hide': driver })}
                                                                data-qa={qa.journeys.table.fieldDriver}
                                                            />
                                                            <td
                                                                className={cn({ 't-hide': !driver2Column })}
                                                                data-qa={qa.journeys.table.fieldDriver2}
                                                            />
                                                            <td
                                                                className={cn({ 't-hide': trailer || !trailerColumn })}
                                                                data-qa={qa.journeys.table.fieldTrailer}
                                                            />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            {d.activities[0].monitoredObjectType !==
                                                                FleetType.VEHICLE && (
                                                                <td
                                                                    className={
                                                                        d.privateSum ? 't-center sum-layout' : ''
                                                                    }
                                                                >
                                                                    {d.privateSum?.duration
                                                                        ? `${t(
                                                                              'JourneysTable.privateDistance'
                                                                          )}: ${numeral(
                                                                              distance(d.privateSum?.distance)
                                                                          ).format('0,0.0')}`
                                                                        : undefined}
                                                                </td>
                                                            )}
                                                            <td
                                                                className="t-center  sum-layout"
                                                                data-qa={qa.journeys.table.fieldSumDuration}
                                                            >
                                                                {timeFromTimestamp(d.sum.duration)}
                                                            </td>
                                                            {props.expanded && (
                                                                <td
                                                                    className="t-center  sum-layout"
                                                                    data-qa={qa.journeys.table.fieldSumStopped}
                                                                >
                                                                    {timeFromTimestamp(d.sum.stopped)}
                                                                </td>
                                                            )}
                                                            <td
                                                                className="t-center  sum-layout"
                                                                data-qa={qa.journeys.table.fieldSumDistance}
                                                            >
                                                                {numeral(distance(d.sum.distance)).format('0,0.0')}
                                                            </td>
                                                            {props.expanded && (
                                                                <>
                                                                    <td className="t-center  sum-layout">
                                                                        {numeral(distance(d.sum.odometerEnd)).format(
                                                                            '0,0'
                                                                        )}
                                                                    </td>
                                                                    {refueledLiters > 0 && (
                                                                        <td className="t-center  sum-layout">
                                                                            {numeral(d.sum.units).format('0,0.00')}
                                                                        </td>
                                                                    )}
                                                                    {props.showConsumption ? (
                                                                        <td className="t-center  sum-layout">
                                                                            {/* Consumption in trip rows is always shown as l / 100km */}
                                                                            {numeral(d.sum.afcL100Km).format('0,0.0')}
                                                                        </td>
                                                                    ) : (
                                                                        <td />
                                                                    )}
                                                                    <td className="t-center  sum-layout">
                                                                        {numeral(d.sum.co2Emission / 1000).format(
                                                                            '0,0.0'
                                                                        )}
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : data.rows && data.rows.length === 0 ? (
                        <NoData />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}

export default withTranslation()(JourneysTable);
