/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyDiemsRules2,
    ReadOnlyDiemsRules2FromJSON,
    ReadOnlyDiemsRules2ToJSON,
    WriteOnlyDiemsRules2,
    WriteOnlyDiemsRules2FromJSON,
    WriteOnlyDiemsRules2ToJSON,
} from '../models';

export interface DiemsRules2CreateRequest {
    data: WriteOnlyDiemsRules2;
}

export interface DiemsRules2DeleteRequest {
    id: string;
}

export interface DiemsRules2ListRequest {
    client?: string;
    user?: string;
    userIsnull?: boolean;
    userIn?: string;
    dayFromLte?: string;
    dayToGte?: string;
}

export interface DiemsRules2PartialUpdateRequest {
    id: string;
    data: WriteOnlyDiemsRules2;
}

export interface DiemsRules2ReadRequest {
    id: string;
}

export interface DiemsRules2UpdateRequest {
    id: string;
    data: WriteOnlyDiemsRules2;
}

/**
 * no description
 */
export class DiemsRules2Api extends runtime.BaseAPI {

    /**
     */
    async diemsRules2CreateRaw(requestParameters: DiemsRules2CreateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules2>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRules2Create.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRules2ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRules2FromJSON(jsonValue));
    }

    /**
     */
    async diemsRules2Create(requestParameters: DiemsRules2CreateRequest): Promise<ReadOnlyDiemsRules2> {
        const response = await this.diemsRules2CreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRules2DeleteRaw(requestParameters: DiemsRules2DeleteRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRules2Delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRules2FromJSON(jsonValue));
    }

    /**
     */
    async diemsRules2Delete(requestParameters: DiemsRules2DeleteRequest): Promise<ReadOnlyDiemsRules2> {
        const response = await this.diemsRules2DeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRules2ListRaw(requestParameters: DiemsRules2ListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyDiemsRules2>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.userIsnull !== undefined) {
            queryParameters['user__isnull'] = requestParameters.userIsnull;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.dayFromLte !== undefined) {
            queryParameters['day_from__lte'] = requestParameters.dayFromLte;
        }

        if (requestParameters.dayToGte !== undefined) {
            queryParameters['day_to__gte'] = requestParameters.dayToGte;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyDiemsRules2FromJSON));
    }

    /**
     */
    async diemsRules2List(requestParameters: DiemsRules2ListRequest): Promise<Array<ReadOnlyDiemsRules2>> {
        const response = await this.diemsRules2ListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRules2PartialUpdateRaw(requestParameters: DiemsRules2PartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRules2PartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRules2PartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRules2ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRules2FromJSON(jsonValue));
    }

    /**
     */
    async diemsRules2PartialUpdate(requestParameters: DiemsRules2PartialUpdateRequest): Promise<ReadOnlyDiemsRules2> {
        const response = await this.diemsRules2PartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRules2ReadRaw(requestParameters: DiemsRules2ReadRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRules2Read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRules2FromJSON(jsonValue));
    }

    /**
     */
    async diemsRules2Read(requestParameters: DiemsRules2ReadRequest): Promise<ReadOnlyDiemsRules2> {
        const response = await this.diemsRules2ReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRules2UpdateRaw(requestParameters: DiemsRules2UpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRules2Update.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRules2Update.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules-2/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRules2ToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRules2FromJSON(jsonValue));
    }

    /**
     */
    async diemsRules2Update(requestParameters: DiemsRules2UpdateRequest): Promise<ReadOnlyDiemsRules2> {
        const response = await this.diemsRules2UpdateRaw(requestParameters);
        return await response.value();
    }

}
