/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableMobject,
    AvailableMobjectFromJSON,
    AvailableMobjectFromJSONTyped,
    AvailableMobjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface AvailableMonitoredObjects
 */
export interface AvailableMonitoredObjects {
    /**
     * 
     * @type {Array<AvailableMobject>}
     * @memberof AvailableMonitoredObjects
     */
    trailers?: Array<AvailableMobject>;
    /**
     * 
     * @type {Array<AvailableMobject>}
     * @memberof AvailableMonitoredObjects
     */
    vehicles?: Array<AvailableMobject>;
}

export function AvailableMonitoredObjectsFromJSON(json: any): AvailableMonitoredObjects {
    return AvailableMonitoredObjectsFromJSONTyped(json, false);
}

export function AvailableMonitoredObjectsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableMonitoredObjects {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trailers': !exists(json, 'trailers') ? undefined : ((json['trailers'] as Array<any>).map(AvailableMobjectFromJSON)),
        'vehicles': !exists(json, 'vehicles') ? undefined : ((json['vehicles'] as Array<any>).map(AvailableMobjectFromJSON)),
    };
}

export function AvailableMonitoredObjectsToJSON(value?: AvailableMonitoredObjects | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trailers': value.trailers === undefined ? undefined : ((value.trailers as Array<any>).map(AvailableMobjectToJSON)),
        'vehicles': value.vehicles === undefined ? undefined : ((value.vehicles as Array<any>).map(AvailableMobjectToJSON)),
    };
}


