import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { noData, noDataError } from 'resources/images/driver-behavior';

interface Props {
    qa?: string;
}

export default function DriverBehaviorNoData(props: Props) {
    const { t } = useTranslation();

    return (
        <Row align="middle" justify="center" className="driver-behavior-no-data" data-qa={props.qa}>
            <Row className="driver-behavior-no-data-content" align="middle" justify="center">
                <Col className="driver-behavior-no-data-image" data-qa={props.qa}>
                    <img src={noDataError} alt="no-data-error" />
                </Col>
                <Col className="driver-behavior-no-data-text">
                    <img src={noData} alt="no-data" />
                    <span>{t('DriverBehaviorDetail.noData')}</span>
                </Col>
            </Row>
        </Row>
    );
}
