import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import UserProfileForm, { UserProfileFormModel } from 'common/forms/UserProfileForm';
import { ParentModule } from '../user-modules';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { ReadOnlyUser, ReadOnlyUserRight, ReadOnlyUserRole } from 'generated/new-main/models';
import { Loading } from 'common/components';
import { getRoleName } from 'common/utils/components/RoleName';

interface State {
    selectedRoles: Set<string>;
    selectedModule?: ParentModule;
}

interface Props extends WithTranslation {
    lang: string;
    data?: ReadOnlyUser;
    userRights: ReadOnlyUserRight[];
    userRoles: ReadOnlyUserRole[];
    loading?: boolean;
    mode: 'EDIT' | 'CREATE';
    onBackButtonClick: () => void;
    onCancelButtonClick: () => void;
    onSubmitButtonClick: (model: UserProfileFormModel) => Promise<boolean>;
    onUserRolesChange?: (roles: string[]) => void;
}

class UsersCreateNew extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedRoles: new Set<string>()
        };
    }

    render() {
        return (
            <div className="side-panel-user-create-edit">
                {this.props.loading && <Loading />}
                <div className="user-create-edit">
                    <UserProfileForm
                        mode={this.props.mode}
                        initialValues={this._getUserFormInitialValues()}
                        roles={this._getUserRolesOptions()}
                        originalRoles={this.props.userRoles}
                        onCancel={this.props.onCancelButtonClick}
                        onChange={this._onUserProfileFormChange}
                        onSubmit={this._handleSubmit}
                    />
                </div>
            </div>
        );
    }

    private _getUserFormInitialValues = (): Partial<UserProfileFormModel> => {
        return {
            email: (this.props.data?.contact as any)?.emails[0],
            name: this.props.data?.name,
            phone: (this.props.data?.contact as any)?.phone_numbers[0],
            roles: this.props.data?.userRoles ? this.props.data.userRoles.map(ur => ur.id!.toString()) : [],
            surname: this.props.data?.surname,
            username: (this.props.data?.loginCredentials as any)?.username,
            pin: (this.props.data?.loginCredentials as any)?.pin
        };
    };

    private _getUserRolesOptions = (): CheckboxOptionType[] => {
        if (this.props.userRoles.some(r => !r.id)) {
            return [];
        }
        return this.props.userRoles.map(role => ({
            label: getRoleName(role),
            value: role.id! // up here make sure we have id for all the groups
        }));
    };

    private _handleSubmit = (values: UserProfileFormModel) => {
        return this.props.onSubmitButtonClick(values);
    };

    private _onUserProfileFormChange = (values: UserProfileFormModel) => {
        const newRoles = new Set(values.roles ?? []);
        if (
            this.state.selectedRoles.size !== newRoles.size ||
            [...newRoles].every(value => this.state.selectedRoles.has(value))
        ) {
            this.setState(
                {
                    selectedRoles: newRoles
                },
                () => {
                    this.props.onUserRolesChange?.([...newRoles]);
                }
            );
        }
    };
}

export default withTranslation()(UsersCreateNew);
