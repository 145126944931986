/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MobileApp,
    MobileAppFromJSON,
    MobileAppToJSON,
    MobileDeviceHasMobileAppCreateMany,
    MobileDeviceHasMobileAppCreateManyFromJSON,
    MobileDeviceHasMobileAppCreateManyToJSON,
    MobileDeviceHasMobileAppLogCurrentVersion,
    MobileDeviceHasMobileAppLogCurrentVersionFromJSON,
    MobileDeviceHasMobileAppLogCurrentVersionToJSON,
    MobileDeviceHasMobileAppNested,
    MobileDeviceHasMobileAppNestedFromJSON,
    MobileDeviceHasMobileAppNestedToJSON,
    ReadOnlyMobileDeviceHasMobileApp,
    ReadOnlyMobileDeviceHasMobileAppFromJSON,
    ReadOnlyMobileDeviceHasMobileAppToJSON,
    ReadOnlyMobileDeviceHasMobileAppNested,
    ReadOnlyMobileDeviceHasMobileAppNestedFromJSON,
    ReadOnlyMobileDeviceHasMobileAppNestedToJSON,
    WriteOnlyMobileDeviceHasMobileApp,
    WriteOnlyMobileDeviceHasMobileAppFromJSON,
    WriteOnlyMobileDeviceHasMobileAppToJSON,
} from '../models';

export interface MobileDeviceHasMobileAppCreateRequest {
    data: WriteOnlyMobileDeviceHasMobileApp;
}

export interface MobileDeviceHasMobileAppCreateManyRequest {
    data: MobileDeviceHasMobileAppCreateMany;
}

export interface MobileDeviceHasMobileAppDeleteRequest {
    id: string;
}

export interface MobileDeviceHasMobileAppLatestRequest {
    mobileDevice?: string;
    mobileDeviceDeviceId?: string;
    mobileAppPackageName?: string;
}

export interface MobileDeviceHasMobileAppListRequest {
    mobileDevice?: string;
    mobileDeviceDeviceId?: string;
    mobileAppPackageName?: string;
}

export interface MobileDeviceHasMobileAppLogCurrentVersionRequest {
    data: MobileDeviceHasMobileAppLogCurrentVersion;
}

export interface MobileDeviceHasMobileAppPartialUpdateRequest {
    id: string;
    data: WriteOnlyMobileDeviceHasMobileApp;
}

export interface MobileDeviceHasMobileAppReadRequest {
    id: string;
}

export interface MobileDeviceHasMobileAppUpdateRequest {
    id: string;
    data: WriteOnlyMobileDeviceHasMobileApp;
}

/**
 * no description
 */
export class MobileDeviceHasMobileAppApi extends runtime.BaseAPI {

    /**
     */
    async mobileDeviceHasMobileAppCreateRaw(requestParameters: MobileDeviceHasMobileAppCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceHasMobileApp>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceHasMobileAppCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceHasMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceHasMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceHasMobileAppCreate(requestParameters: MobileDeviceHasMobileAppCreateRequest): Promise<ReadOnlyMobileDeviceHasMobileApp> {
        const response = await this.mobileDeviceHasMobileAppCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppCreateManyRaw(requestParameters: MobileDeviceHasMobileAppCreateManyRequest): Promise<runtime.ApiResponse<Array<MobileDeviceHasMobileAppNested>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceHasMobileAppCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/create-many/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobileDeviceHasMobileAppCreateManyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileDeviceHasMobileAppNestedFromJSON));
    }

    /**
     */
    async mobileDeviceHasMobileAppCreateMany(requestParameters: MobileDeviceHasMobileAppCreateManyRequest): Promise<Array<MobileDeviceHasMobileAppNested>> {
        const response = await this.mobileDeviceHasMobileAppCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppDeleteRaw(requestParameters: MobileDeviceHasMobileAppDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceHasMobileAppDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mobileDeviceHasMobileAppDelete(requestParameters: MobileDeviceHasMobileAppDeleteRequest): Promise<void> {
        await this.mobileDeviceHasMobileAppDeleteRaw(requestParameters);
    }

    /**
     */
    async mobileDeviceHasMobileAppLatestRaw(requestParameters: MobileDeviceHasMobileAppLatestRequest): Promise<runtime.ApiResponse<Array<MobileApp>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mobileDevice !== undefined) {
            queryParameters['mobile_device'] = requestParameters.mobileDevice;
        }

        if (requestParameters.mobileDeviceDeviceId !== undefined) {
            queryParameters['mobile_device__device_id'] = requestParameters.mobileDeviceDeviceId;
        }

        if (requestParameters.mobileAppPackageName !== undefined) {
            queryParameters['mobile_app__package_name'] = requestParameters.mobileAppPackageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/latest/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileAppFromJSON));
    }

    /**
     */
    async mobileDeviceHasMobileAppLatest(requestParameters: MobileDeviceHasMobileAppLatestRequest): Promise<Array<MobileApp>> {
        const response = await this.mobileDeviceHasMobileAppLatestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppListRaw(requestParameters: MobileDeviceHasMobileAppListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMobileDeviceHasMobileAppNested>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mobileDevice !== undefined) {
            queryParameters['mobile_device'] = requestParameters.mobileDevice;
        }

        if (requestParameters.mobileDeviceDeviceId !== undefined) {
            queryParameters['mobile_device__device_id'] = requestParameters.mobileDeviceDeviceId;
        }

        if (requestParameters.mobileAppPackageName !== undefined) {
            queryParameters['mobile_app__package_name'] = requestParameters.mobileAppPackageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMobileDeviceHasMobileAppNestedFromJSON));
    }

    /**
     */
    async mobileDeviceHasMobileAppList(requestParameters: MobileDeviceHasMobileAppListRequest): Promise<Array<ReadOnlyMobileDeviceHasMobileAppNested>> {
        const response = await this.mobileDeviceHasMobileAppListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppLogCurrentVersionRaw(requestParameters: MobileDeviceHasMobileAppLogCurrentVersionRequest): Promise<runtime.ApiResponse<MobileApp>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceHasMobileAppLogCurrentVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/log-current-version/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobileDeviceHasMobileAppLogCurrentVersionToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceHasMobileAppLogCurrentVersion(requestParameters: MobileDeviceHasMobileAppLogCurrentVersionRequest): Promise<MobileApp> {
        const response = await this.mobileDeviceHasMobileAppLogCurrentVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppPartialUpdateRaw(requestParameters: MobileDeviceHasMobileAppPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceHasMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceHasMobileAppPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceHasMobileAppPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceHasMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceHasMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceHasMobileAppPartialUpdate(requestParameters: MobileDeviceHasMobileAppPartialUpdateRequest): Promise<ReadOnlyMobileDeviceHasMobileApp> {
        const response = await this.mobileDeviceHasMobileAppPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppReadRaw(requestParameters: MobileDeviceHasMobileAppReadRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceHasMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceHasMobileAppRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceHasMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceHasMobileAppRead(requestParameters: MobileDeviceHasMobileAppReadRequest): Promise<ReadOnlyMobileDeviceHasMobileApp> {
        const response = await this.mobileDeviceHasMobileAppReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceHasMobileAppUpdateRaw(requestParameters: MobileDeviceHasMobileAppUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceHasMobileApp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceHasMobileAppUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceHasMobileAppUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-has-mobile-app/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceHasMobileAppToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceHasMobileAppFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceHasMobileAppUpdate(requestParameters: MobileDeviceHasMobileAppUpdateRequest): Promise<ReadOnlyMobileDeviceHasMobileApp> {
        const response = await this.mobileDeviceHasMobileAppUpdateRaw(requestParameters);
        return await response.value();
    }

}
