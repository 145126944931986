/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatResultStrModel
 */
export interface StatResultStrModel {
    /**
     * 
     * @type {number}
     * @memberof StatResultStrModel
     */
    init: number;
    /**
     * 
     * @type {string}
     * @memberof StatResultStrModel
     */
    sn: string;
    /**
     * 
     * @type {string}
     * @memberof StatResultStrModel
     */
    atr: string;
}

export function StatResultStrModelFromJSON(json: any): StatResultStrModel {
    return StatResultStrModelFromJSONTyped(json, false);
}

export function StatResultStrModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatResultStrModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'init': json['init'],
        'sn': json['sn'],
        'atr': json['atr'],
    };
}

export function StatResultStrModelToJSON(value?: StatResultStrModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'init': value.init,
        'sn': value.sn,
        'atr': value.atr,
    };
}


