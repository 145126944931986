/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemperatureSensor
 */
export interface TemperatureSensor {
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensor
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensor
     */
    sensorName: string;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensor
     */
    sensorType?: TemperatureSensorSensorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TemperatureSensor
     */
    sensorZone?: number;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensor
     */
    serialNumber: string;
}

export function TemperatureSensorFromJSON(json: any): TemperatureSensor {
    return TemperatureSensorFromJSONTyped(json, false);
}

export function TemperatureSensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemperatureSensor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sensorName': json['sensor_name'],
        'sensorType': !exists(json, 'sensor_type') ? undefined : json['sensor_type'],
        'sensorZone': !exists(json, 'sensor_zone') ? undefined : json['sensor_zone'],
        'serialNumber': json['serial_number'],
    };
}

export function TemperatureSensorToJSON(value?: TemperatureSensor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sensor_name': value.sensorName,
        'sensor_type': value.sensorType,
        'sensor_zone': value.sensorZone,
        'serial_number': value.serialNumber,
    };
}

/**
* @export
* @enum {string}
*/
export enum TemperatureSensorSensorTypeEnum {
    TemperatureSensor = 'temperature_sensor'
}


