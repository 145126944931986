import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import qa from 'qa-selectors';

interface Props {
    size?: 'large' | 'middle' | 'small';
    onClick?: () => void;
}

export default function ButtonHelper({ ...props }: Props) {
    const { t } = useTranslation();

    return (
        <Button
            qa={qa.common.btnHelper}
            className="button-helper"
            type="default"
            size={props.size ?? 'small'}
            title={t('common.helper')}
            onClick={props.onClick}
        >
            ?
        </Button>
    );
}
