import { WithTranslation, withTranslation } from 'react-i18next';
import Table from 'common/components/Table';
import { ColumnType } from 'antd/lib/table';
import { PaginationParams } from 'common/model/pagination';
import { Checkbox } from 'common/components';
import { CostStructure } from 'logic/statistics/statistics-company-profile';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cn from 'classnames';
import { MonitoredObjectCostProfile } from 'generated/backend-api';
import numeral from 'numeral';
import { EFuelType } from 'generated/graphql';
import { getRegistrationNumber } from 'common/utils/registrationName';
import qa from 'qa-selectors';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';

interface Props extends WithTranslation {
    data?: CostStructure[];
    vehicles?: ReadOnlyMonitoredObjectFeSb[];
    loading: boolean;
    onCheckboxChange?: (index: number) => void;
    onCheckboxAllChange?: (checked: boolean) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function CompanyProfileCostsTable(props: Props) {
    return (
        <div className="cost-structure-table">
            <Table<CostStructure>
                loading={props.loading}
                dataSource={props.data?.map((e, index) => ({ ...e, key: index }))}
                columns={createColumns()}
                onRow={record => ({
                    onClick: () => onRowClick(record.monitoredObjectId),
                    className: cn({
                        'ant-table-row-selected': record.selected
                    })
                })}
                scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
            />
        </div>
    );

    function onRowClick(index: number | undefined) {
        props.onCheckboxChange?.(index || 0);
    }

    function onCheckboxAllChange(e: CheckboxChangeEvent) {
        props.onCheckboxAllChange?.(e.target.checked);
    }

    function createColumns() {
        return [
            {
                title: (
                    <Checkbox
                        onChange={onCheckboxAllChange}
                        checked={
                            props.data?.filter(d => d.selected === true).length ===
                            (props.data?.length ?? 0) - (props.vehicles?.filter(v => v.disabledAt).length ?? 0)
                        }
                    />
                ),
                dataIndex: 'selected',
                key: 'selected',
                align: 'center',
                width: 20,
                render: (selected: boolean, record) => (
                    <Checkbox
                        disabled={!!props.vehicles?.find(v => v.id === record.monitoredObjectId)?.disabledAt}
                        checked={selected}
                        qa={qa.companyProfile.costsCalculator.table.checkboxRow}
                    />
                )
            },
            {
                title: props.t('common.vehicle'),
                dataIndex: 'monitoredObjectId',
                key: 'monitoredObjectId',
                align: 'center',
                width: 80,
                render: monitoredObjectId => {
                    const vehicle = props.vehicles?.find(v => v.id === monitoredObjectId);
                    return (
                        <span data-qa={qa.companyProfile.costsCalculator.table.fieldRn}>
                            {getRegistrationNumber(!!vehicle?.disabledAt, vehicle?.registrationNumber ?? '')}
                        </span>
                    );
                }
            },
            {
                title: (
                    <img
                        src="/img-svg/gas-station.svg"
                        alt={props.t('CompanyProfileOperationalCosts.dieselGasoline')}
                        title={props.t('CompanyProfileOperationalCosts.dieselGasoline')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'monitoredObjectId',
                key: 'fuel',
                align: 'center',
                width: 28,
                render: monitoredObjectId => {
                    const operationalCostProfile = props.data?.find(
                        d => d.monitoredObjectId === monitoredObjectId
                    )?.operationalCostProfile;
                    return [EFuelType.Diesel, EFuelType.Gasoline, EFuelType.Hybrid].includes(
                        (props.vehicles?.find(v => v.id === monitoredObjectId)?.fuelType as EFuelType) ??
                            EFuelType.Diesel
                    ) ? (
                        renderData(
                            operationalCostProfile?.fuel?.value,
                            operationalCostProfile?.fuel?.unit,
                            qa.companyProfile.costsCalculator.table.fieldFuelValue,
                            qa.companyProfile.costsCalculator.table.fieldFuelUnit
                        )
                    ) : (
                        <div className="fa fa-ban" />
                    );
                }
            },
            {
                title: (
                    <img
                        src="/img-svg/gas-station.svg"
                        alt={props.t('CompanyProfileOperationalCosts.lngCng')}
                        title={props.t('CompanyProfileOperationalCosts.lngCng')}
                        className="icon-background icon-background-lngcng"
                    />
                ),
                dataIndex: 'monitoredObjectId',
                key: 'lngCng',
                align: 'center',
                width: 28,
                render: monitoredObjectId => {
                    const operationalCostProfile = props.data?.find(
                        d => d.monitoredObjectId === monitoredObjectId
                    )?.operationalCostProfile;
                    return [EFuelType.LngCng].includes(
                        (props.vehicles?.find(v => v.id === monitoredObjectId)?.fuelType as EFuelType) ??
                            EFuelType.Diesel
                    ) ? (
                        renderData(
                            operationalCostProfile?.lngCng?.value,
                            operationalCostProfile?.lngCng?.unit,
                            qa.companyProfile.costsCalculator.table.fieldLngCngValue,
                            qa.companyProfile.costsCalculator.table.fieldLngCngUnit
                        )
                    ) : (
                        <div className="fa fa-ban" />
                    );
                }
            },
            {
                title: (
                    <img
                        src="/img-svg/gas-station.svg"
                        alt={props.t('CompanyProfileOperationalCosts.electro')}
                        title={props.t('CompanyProfileOperationalCosts.electro')}
                        className="icon-background icon-background-electro"
                    />
                ),
                dataIndex: 'monitoredObjectId',
                key: 'electro',
                align: 'center',
                width: 28,
                render: monitoredObjectId => {
                    const operationalCostProfile = props.data?.find(
                        d => d.monitoredObjectId === monitoredObjectId
                    )?.operationalCostProfile;
                    return [EFuelType.Electro, EFuelType.Hybrid].includes(
                        (props.vehicles?.find(v => v.id === monitoredObjectId)?.fuelType as EFuelType) ??
                            EFuelType.Diesel
                    ) ? (
                        renderData(
                            operationalCostProfile?.electro?.value,
                            operationalCostProfile?.electro?.unit,
                            qa.companyProfile.costsCalculator.table.fieldElectroValue,
                            qa.companyProfile.costsCalculator.table.fieldElectroUnit
                        )
                    ) : (
                        <div className="fa fa-ban" />
                    );
                }
            },
            {
                title: (
                    <img
                        src="/img-svg/petrol-can.svg"
                        alt={props.t('CompanyProfileOperationalCosts.additives')}
                        title={props.t('CompanyProfileOperationalCosts.additives')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'additives',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.additives?.value,
                        operationalCostProfile?.additives?.unit,
                        qa.companyProfile.costsCalculator.table.fieldAdditivesValue,
                        qa.companyProfile.costsCalculator.table.fieldAdditivesUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/eco-fuel.svg"
                        alt={props.t('CompanyProfileOperationalCosts.oil')}
                        title={props.t('CompanyProfileOperationalCosts.oil')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                className: 'border-left',
                key: 'oil',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.oil?.value,
                        operationalCostProfile?.oil?.unit,
                        qa.companyProfile.costsCalculator.table.fieldOilValue,
                        qa.companyProfile.costsCalculator.table.fieldOilUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/tyre.svg"
                        alt={props.t('CompanyProfileOperationalCosts.tires')}
                        title={props.t('CompanyProfileOperationalCosts.tires')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'tires',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.tires?.value,
                        operationalCostProfile?.tires?.unit,
                        qa.companyProfile.costsCalculator.table.fieldTiresValue,
                        qa.companyProfile.costsCalculator.table.fieldTiresUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/wrench.svg"
                        alt={props.t('CompanyProfileOperationalCosts.maintenance')}
                        title={props.t('CompanyProfileOperationalCosts.maintenance')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'maintenance',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.maintenance?.value,
                        operationalCostProfile?.maintenance?.unit,
                        qa.companyProfile.costsCalculator.table.fieldMaintenanceValue,
                        qa.companyProfile.costsCalculator.table.fieldMaintenanceUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/bankruptcy.svg"
                        alt={props.t('CompanyProfileOperationalCosts.liabilityInsurance')}
                        title={props.t('CompanyProfileOperationalCosts.liabilityInsurance')}
                        className="icon-background"
                    />
                ),
                className: 'border-left',
                dataIndex: 'operationalCostProfile',
                key: 'liabilityInsurance',
                width: 28,
                align: 'center',
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.liabilityInsurance?.value,
                        operationalCostProfile?.liabilityInsurance?.unit,
                        qa.companyProfile.costsCalculator.table.fieldLiabilityInsuranceValue,
                        qa.companyProfile.costsCalculator.table.fieldLiabilityInsuranceUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/fender-bender-1.svg"
                        alt={props.t('CompanyProfileOperationalCosts.collisionInsurance')}
                        title={props.t('CompanyProfileOperationalCosts.collisionInsurance')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'collisionInsurance',
                width: 28,
                align: 'center',
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.collisionInsurance?.value,
                        operationalCostProfile?.collisionInsurance?.unit,
                        qa.companyProfile.costsCalculator.table.fieldCollisionInsuranceValue,
                        qa.companyProfile.costsCalculator.table.fieldCollisionInsuranceUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/injury-1.svg"
                        alt={props.t('CompanyProfileOperationalCosts.personalInjuryInsurance')}
                        title={props.t('CompanyProfileOperationalCosts.personalInjuryInsurance')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'personalInjuryInsurance',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.personalInjuryInsurance?.value,
                        operationalCostProfile?.personalInjuryInsurance?.unit,
                        qa.companyProfile.costsCalculator.table.fieldPersonalInsuranceValue,
                        qa.companyProfile.costsCalculator.table.fieldPersonalInsuranceUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/parking-icon.svg"
                        alt={props.t('CompanyProfileOperationalCosts.parking')}
                        title={props.t('CompanyProfileOperationalCosts.parking')}
                        className="icon-background"
                    />
                ),
                className: 'border-left',
                dataIndex: 'operationalCostProfile',
                key: 'parking',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.parking?.value,
                        operationalCostProfile?.parking?.unit,
                        qa.companyProfile.costsCalculator.table.fieldParkingValue,
                        qa.companyProfile.costsCalculator.table.fieldParkingUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/heated.svg"
                        alt={props.t('CompanyProfileOperationalCosts.cabinHeating')}
                        title={props.t('CompanyProfileOperationalCosts.cabinHeating')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'cabinHeating',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.cabinHeating?.value,
                        operationalCostProfile?.cabinHeating?.unit,
                        qa.companyProfile.costsCalculator.table.fieldHeatingValue,
                        qa.companyProfile.costsCalculator.table.fieldHeatingUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/tower-1.svg"
                        alt={props.t('CompanyProfileOperationalCosts.telecomunicationsAndMonitoring')}
                        title={props.t('CompanyProfileOperationalCosts.telecomunicationsAndMonitoring')}
                        className="icon-background"
                    />
                ),
                dataIndex: 'operationalCostProfile',
                key: 'telecomunicationsAndMonitoring',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.telecomunicationsAndMonitoring?.value,
                        operationalCostProfile?.telecomunicationsAndMonitoring?.unit,
                        qa.companyProfile.costsCalculator.table.fieldTelecommunicationValue,
                        qa.companyProfile.costsCalculator.table.fieldTelecommunicationUnit
                    )
            },
            {
                title: (
                    <img
                        src="/img-svg/hand-washing.svg"
                        alt={props.t('CompanyProfileOperationalCosts.washing')}
                        title={props.t('CompanyProfileOperationalCosts.washing')}
                        className="icon-background"
                    />
                ),
                className: 'border-left',
                dataIndex: 'operationalCostProfile',
                key: 'washing',
                align: 'center',
                width: 28,
                render: (operationalCostProfile: MonitoredObjectCostProfile) =>
                    renderData(
                        operationalCostProfile?.washing?.value,
                        operationalCostProfile?.washing?.unit,
                        qa.companyProfile.costsCalculator.table.fieldWashingValue,
                        qa.companyProfile.costsCalculator.table.fieldWashingUnit
                    )
            },
            {
                title: () => {
                    if (window.innerWidth > 1700) {
                        return props.t('CompanyProfileOperationalCosts.sum');
                    } else {
                        return (
                            <img
                                src="/img-svg/sum-per-km.svg"
                                alt={props.t('CompanyProfileOperationalCosts.sum')}
                                title={props.t('CompanyProfileOperationalCosts.sum')}
                                className="icon-background-text"
                            />
                        );
                    }
                },
                dataIndex: 'avgPricePerKm',
                key: 'sum',
                align: 'center',
                width: 40,
                render: (avgPricePerKm: number) => (
                    <span data-qa={qa.companyProfile.costsCalculator.table.fieldSumPerKmValue}>
                        {numeral(avgPricePerKm).format('0,0.00') ?? 0}
                    </span>
                )
            }
        ] as ColumnType<CostStructure>[];
    }

    function renderData(value?: number, unit?: string, qaValue?: string, qaUnit?: string) {
        return value ? (
            <>
                <span data-qa={qaValue}>{numeral(value).format('0,0.00') ?? 0}</span>{' '}
                {unit ? (
                    <>
                        / <span data-qa={qaUnit}>{props.t(`CompanyProfile.unit.${unit}`)}</span>
                    </>
                ) : (
                    ''
                )}
            </>
        ) : (
            '-'
        );
    }
}

export default withTranslation()(CompanyProfileCostsTable);
