import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { RefSelectProps } from 'antd/lib/select';
import { WithLogic, withLogicContext } from 'App';
import { LatLng } from 'common/model/geo';
import { debounce } from 'debounce';
import { Suggestions } from 'logic/routing/logic/suggestion';
import * as React from 'react';
import { Subject, Subscription } from 'rxjs';
import PlacesAutocomplete from './components/PlacesAutocomplete';

export type RouteSelectData =
    | {
          type: 'LAT_LNG';
          value: LatLng;
      }
    | {
          type: 'GOOGLE_GEOCODER';
          value: Suggestions['googleGeocoder'][0];
      }
    | {
          type: 'GOOGLE';
          value: Suggestions['google'][0];
      }
    | {
          type: 'EUROWAG';
          value: Suggestions['eurowag'][0];
      };

export type RouteSelect = (data?: RouteSelectData) => void;

interface State {
    suggestions: Suggestions;
    searchText: string;
}

interface Props extends WithLogic {
    autofocus?: boolean;
    innerref?: React.RefObject<RefSelectProps>;
    updateSearchValue?: Subject<string>;
    size?: SizeType;
    className?: string;
    tabMenuClassName?: string;
    onSelect?: RouteSelect;
}

class PlacesAutocompleteModule extends React.Component<Props, State> {
    private _suggestionSubscription?: Subscription;

    constructor(props: Props) {
        super(props);
        this.state = {
            searchText: '',
            suggestions: {
                eurowag: [],
                google: [],
                latLng: undefined,
                googleGeocoder: []
            }
        };
    }

    componentDidMount() {
        this._suggestionSubscription = this.props.logic
            .routing()
            .suggestion()
            .suggestionsData.subscribe(suggestions => {
                this.setState({
                    suggestions
                });
            });
    }

    componentWillUnmount() {
        this._suggestionSubscription?.unsubscribe();
    }

    render() {
        return (
            <PlacesAutocomplete
                className={this.props.className}
                tabMenuClassName={this.props.tabMenuClassName}
                autoFocus={this.props.autofocus}
                innerref={this.props.innerref}
                size={this.props.size}
                text={this.state.searchText}
                suggestions={this.state.suggestions}
                updateSearchValue={this.props.updateSearchValue}
                onChange={this._onSearchChange}
                onSelect={this._onSelect}
            />
        );
    }

    private _onSelect = (id?: string | null) => {
        const latLngSuggestion = this.state.suggestions.latLng;
        if (id === 'LAT_LNG' && latLngSuggestion) {
            this.props.onSelect?.({
                type: 'LAT_LNG',
                value: latLngSuggestion
            });
        }

        const googleGeocoderSuggestion = this.state.suggestions.googleGeocoder.find(s => s.place_id === id);
        if (googleGeocoderSuggestion) {
            this.props.onSelect?.({
                type: 'GOOGLE_GEOCODER',
                value: googleGeocoderSuggestion
            });
        }

        const googleSuggestion = this.state.suggestions.google.find(s => s.place_id === id);
        if (googleSuggestion) {
            this.props.onSelect?.({
                type: 'GOOGLE',
                value: googleSuggestion
            });
        }
        const eurowagSuggestion = this.state.suggestions.eurowag.find(s => s.id === id);
        if (eurowagSuggestion) {
            this.props.onSelect?.({
                type: 'EUROWAG',
                value: eurowagSuggestion
            });
        }

        if (eurowagSuggestion || googleSuggestion || googleGeocoderSuggestion || latLngSuggestion) {
            this.setState({
                searchText: '',
                suggestions: {
                    eurowag: [],
                    google: [],
                    googleGeocoder: [],
                    latLng: undefined
                }
            });
        }

        if (!eurowagSuggestion && !googleSuggestion && !googleGeocoderSuggestion && !latLngSuggestion) {
            this.props.onSelect?.(undefined);
        }
    };

    private _onSearchChange = debounce(async (text: string) => {
        if (text === '') {
            this.setState({
                suggestions: {
                    eurowag: [],
                    google: [],
                    googleGeocoder: [],
                    latLng: undefined
                }
            });
        } else {
            this.props.logic.routing().suggestion().getSuggestions(text);
        }
    }, 5e2);
}

export default withLogicContext(PlacesAutocompleteModule);
