import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { roundToStep } from 'common/utils/averages';
import { Table, Rate, Select } from 'common/components';
import { DriverLeaderboardTrendsTableModel } from 'common/model/statistics';
import qa from 'qa-selectors';
import { Col, Row } from 'antd';
import Trend, { TrendChange } from 'common/components/Trend';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { ColumnType } from 'antd/lib/table';

export enum DriverLeaderboardTrendsTableFilter {
    Overal = 'overal',
    Driving = 'driving',
    Eco = 'eco',
    WearTear = 'wearTear'
}

interface Props {
    data: DriverLeaderboardTrendsTableModel[];
    leaderFilter?: DriverLeaderboardTrendsTableFilter;
    loading: boolean;
    showHeader?: boolean;
    showTrend?: boolean;
    onRowClick?: (id: string, name?: string) => void;
    onFilterChange?: (value: DriverLeaderboardTrendsTableFilter) => void;
    className?: string;
    qa?: string;
}

export default function DriverLeaderboardTrendsTable(props: Props) {
    const { t } = useTranslation();

    const handlerFilterSelect = (value: DriverLeaderboardTrendsTableFilter) => {
        props.onFilterChange?.(value);
    };

    const filters = [
        { value: DriverLeaderboardTrendsTableFilter.Overal, label: t('DriverBehaviorTable.filters.overal') },
        { value: DriverLeaderboardTrendsTableFilter.Driving, label: t('DriverBehaviorTable.filters.driving') },
        { value: DriverLeaderboardTrendsTableFilter.Eco, label: t('DriverBehaviorTable.filters.eco') },
        { value: DriverLeaderboardTrendsTableFilter.WearTear, label: t('DriverBehaviorTable.filters.wearTear') }
    ];

    return (
        <div className={cn('drivers-leaderboard-table', props.className)} data-qa={props.qa}>
            {props.leaderFilter && (
                <Row align="middle">
                    <Col flex={1}>{t('DriverBehaviorTable.filterLabel')}</Col>
                    <Col span={10}>
                        <Select
                            className="drivers-leaderboard-table-score-filter"
                            size="middle"
                            onSelect={handlerFilterSelect}
                            value={props.leaderFilter}
                        >
                            {filters.map(f => (
                                <Select.Option key={f.value} value={f.value}>
                                    {f.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            )}
            <Table<DriverLeaderboardTrendsTableModel>
                showHeader={props.showHeader ?? true}
                loading={props.loading}
                columns={
                    [
                        {
                            title: t('DriverBehaviorTable.cols.rank'),
                            key: 'rank',
                            align: 'left',
                            width: 30,
                            dataIndex: 'rank',
                            className: 'drivers-leaderboard-table-rank',
                            render: (_, row: DriverLeaderboardTrendsTableModel) => (
                                <span data-qa={qa.driverBehaviour.side.fieldRank}>{row.rank}</span>
                            )
                        } as ColumnType<DriverLeaderboardTrendsTableModel>,
                        {
                            title: t('DriverBehaviorTable.cols.name'),
                            key: 'name',
                            dataIndex: 'name',
                            align: 'left',
                            render: (_, row: DriverLeaderboardTrendsTableModel) => (
                                <div className="drivers-leaderboard-table-name">
                                    <span data-qa={qa.driverBehaviour.side.fieldRank}>{row.name}</span>
                                    {row.score && (
                                        <Rate
                                            size="small"
                                            value={roundToStep(row.score, 0.5) * 0.05}
                                            disabled
                                            allowHalf
                                        />
                                    )}
                                </div>
                            )
                        } as ColumnType<DriverLeaderboardTrendsTableModel>,
                        props.showTrend
                            ? ({
                                  title: '',
                                  key: 'scores',
                                  dataIndex: 'scores',
                                  align: 'center',
                                  className: 'scores',
                                  render: (_, row: DriverLeaderboardTrendsTableModel) => {
                                      return row?.trend?.[1] !== undefined ? (
                                          <div data-qa={qa.driverBehaviour.side.fieldTrend}>
                                              <Trend
                                                  direction={getIncreaseDirection(row?.trend?.[0], row?.trend?.[1])}
                                                  trendChangeForIncrease={TrendChange.POSITIVE}
                                                  value={getIncreasePercent(row?.trend?.[0], row?.trend?.[1])}
                                              />
                                          </div>
                                      ) : (
                                          <div className="no-data">{t('common.noData').toLowerCase()}</div>
                                      );
                                  }
                              } as ColumnType<DriverLeaderboardTrendsTableModel>)
                            : undefined,
                        {
                            title: t('DriverBehaviorTable.cols.score'),
                            key: 'score',
                            dataIndex: 'score',
                            align: 'center',
                            render: (_, row: DriverLeaderboardTrendsTableModel) => (
                                <div
                                    className="drivers-leaderboard-table-score"
                                    data-qa={qa.driverBehaviour.side.fieldScore}
                                >
                                    {row.score && roundToStep(row.score, 1)}
                                </div>
                            )
                        } as ColumnType<DriverLeaderboardTrendsTableModel>
                    ].filter(c => c) as ColumnType<DriverLeaderboardTrendsTableModel>[]
                }
                dataSource={props.data.map((e, i) => ({ ...e, key: i }))}
                onRow={record => ({
                    onClick: () => props.onRowClick?.(record.id.toString(), record.name)
                })}
            />
        </div>
    );
}
