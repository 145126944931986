import cn from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-cool-dimensions';

import { KM } from 'domain-constants';
import { Button, Popover } from 'common/components';
import { selectAddressStructuredByLanguage } from 'common/utils/address';
import { PlacesModel, TransportModel } from 'common/model/transports';
import { useInterval } from 'common/utils/hooks';
import { EWClient } from 'generated/main-data-api/models';
import * as icons from 'resources/images/dispatcher-board';

import { DISAPPEARING_ANIMATION_LENGTH, TransportCalculatedProperties } from '../../dispatcher-board-logic';
import AddressDetail from '../AddressDetail';
import DFFDataBlock from '../DFFDataBlock';
import numeral from 'numeral';
import { NegotiationState } from 'generated/dff-api';

interface Props {
    transport: TransportModel;
    properties: TransportCalculatedProperties;
    demoMode?: boolean;
    lang: string;
    client?: EWClient;
    overrideVisible?: boolean;
    onDetailClick: (id: string) => void;
}

export default function DFFTransportBlock(props: Props) {
    const { observe, width } = useDimensions();
    const { t } = useTranslation();
    const [showDisappearAnimation, setShowDisappearAnimation] = useState(false);

    useInterval(() => {
        if (
            props.transport.metadata?.expiration &&
            moment().isAfter(
                moment(props.transport.metadata.expiration).subtract(DISAPPEARING_ANIMATION_LENGTH, 'seconds')
            )
        ) {
            setShowDisappearAnimation(true);
        } else if (
            moment().isAfter(
                moment(props.transport.metadata?.proposal?.places[0].loadingWindow.availableUntil)
                    .subtract(props.transport.metadata?.pickupDuration, 'seconds')
                    .subtract(DISAPPEARING_ANIMATION_LENGTH, 'seconds')
            )
        ) {
            setShowDisappearAnimation(true);
        }
    }, 1e3);

    function getCountry(place?: PlacesModel) {
        const [placeAddress] = place?.addressStructured ?? [];
        const countryCode = placeAddress?.countryCode?.toLowerCase();

        return countryCode;
    }

    function renderCountry(countryCode: string) {
        return (
            <div className="dff-transport-block-country">
                <span className={cn('flag-icon flag-icon-squared', `flag-icon-${countryCode}`)} />
            </div>
        );
    }

    function renderTitle() {
        const lastPlace = props.transport.places[props.transport.places.length - 1];
        const [lastPlaceAddress] = lastPlace?.addressStructured ?? [];

        const distance = props.transport.metadata?.transportDistance
            ? `${Math.round(props.transport.metadata.transportDistance / 1000)} ${KM}, `
            : '';

        const address = lastPlaceAddress
            ? `${lastPlaceAddress?.country}, ${lastPlaceAddress?.postalCode}, ${lastPlaceAddress?.address}`
            : '';

        return width > 10 ? `${distance} ${address}` : '';
    }

    function handleDetailClick() {
        props.onDetailClick(props.transport.id ?? '');
    }

    function renderPopoverDetail() {
        const firstAddressStructured = selectAddressStructuredByLanguage(
            props.transport.places?.[0].addressStructured,
            props.lang,
            props.client
        );
        const lastAddressStructured = selectAddressStructuredByLanguage(
            props.transport.places?.[props.transport.places.length - 1].addressStructured,
            props.lang,
            props.client
        );

        return (
            <div className="dff-transport-popover">
                <div className="dff-transport-popover-content">
                    <AddressDetail
                        address={firstAddressStructured}
                        iconUrl={icons.load}
                        rta={props.transport.firstPlaceRta}
                    />
                    <AddressDetail
                        address={lastAddressStructured}
                        iconUrl={icons.unload}
                        rta={props.transport.lastPlaceRta}
                    />
                    <div className="dff-transport-popover-content-data">
                        <Row gutter={8}>
                            <Col span={8}>
                                <DFFDataBlock
                                    label={t('DispatcherBoardDetailTransportDff.costKm')}
                                    value={
                                        props.transport.costPerKm?.cost
                                            ? `${numeral(props.transport.costPerKm?.cost).format('0,0.00')} €`
                                            : '-'
                                    }
                                />
                            </Col>
                            <Col span={8}>
                                <DFFDataBlock
                                    label={t('DispatcherBoardDetailTransportDff.totalDistance')}
                                    value={
                                        props.transport.metadata?.transportDistance
                                            ? `${Math.round(props.transport.metadata?.transportDistance / 1000)} ${KM}`
                                            : '-'
                                    }
                                />
                            </Col>
                            <Col span={8}>
                                <DFFDataBlock
                                    label={t('DispatcherBoardDetailTransportDff.finalPrice')}
                                    value={
                                        props.transport.metadata?.finalPrice
                                            ? `${numeral(props.transport.metadata?.finalPrice).format('0,0')} €`
                                            : '-'
                                    }
                                    highlight
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="dff-transport-popover-actions">
                    <Button type="primary" onClick={handleDetailClick}>
                        {t('DFFTransportBlock.details')}
                    </Button>
                </div>
            </div>
        );
    }

    const getStatusIcon = (state: NegotiationState) => {
        switch (state) {
            case NegotiationState.Generated:
                return icons.newProposal;
            case NegotiationState.CarrierOffer:
                return icons.proposalPending;
            case NegotiationState.ShipperOffer:
                return icons.proposalOffer;
            case NegotiationState.Declined:
                return icons.proposalRejected;
            case NegotiationState.Expired:
                return icons.proposalRejected;
            default:
                return icons.proposalConfirmed;
        }
    };

    return (
        <Popover
            placement="left"
            overlayClassName="dff-transport-popover-overlay"
            content={renderPopoverDetail()}
            visible={props.overrideVisible ?? undefined}
            zIndex={10}
        >
            <div
                ref={observe}
                className={cn('dff-transport-block', {
                    'dff-transport-block-highlight': props.properties.highlight,
                    'dff-transport-block-disappear': showDisappearAnimation,
                    'dff-transport-block-state-pending':
                        props.transport.metadata?.proposal?.state === NegotiationState.Declined ||
                        props.transport.metadata?.proposal?.state === NegotiationState.CarrierOffer
                })}
                onClick={handleDetailClick}
            >
                <div className="dff-transport-block-prefix">
                    <img
                        className={cn({
                            'dff-transport-block-new-icon':
                                props.transport.metadata?.proposal?.state === NegotiationState.Generated
                        })}
                        src={getStatusIcon(props.transport.metadata?.proposal?.state!)}
                        alt="proposal-icon"
                    />
                </div>

                <div className="dff-transport-block-title">{renderTitle()}</div>

                {width > 88 && renderCountry(getCountry(props.transport.places[props.transport.places.length - 1]))}
            </div>
        </Popover>
    );
}
