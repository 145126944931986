/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MonitoredObjectType {
    Vehicle = 'vehicle',
    Trailer = 'trailer',
    HgvLess35t = 'hgv_less_3.5t',
    Hgv35t75t = 'hgv_3.5t-7.5t',
    Hgv75t12t = 'hgv_7.5t-12t',
    HgvOver12t = 'hgv_over_12t',
    Truck = 'truck',
    Bus = 'bus',
    LightVehicle = 'light_vehicle'
}

export function MonitoredObjectTypeFromJSON(json: any): MonitoredObjectType {
    return MonitoredObjectTypeFromJSONTyped(json, false);
}

export function MonitoredObjectTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectType {
    return json as MonitoredObjectType;
}

export function MonitoredObjectTypeToJSON(value?: MonitoredObjectType | null): any {
    return value as any;
}

