/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyCosts,
    CompanyCostsFromJSON,
    CompanyCostsFromJSONTyped,
    CompanyCostsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyCompanyOperationalCost
 */
export interface ReadOnlyCompanyOperationalCost {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyCompanyOperationalCost
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyCompanyOperationalCost
     */
    dateFrom?: Date;
    /**
     * 
     * @type {CompanyCosts}
     * @memberof ReadOnlyCompanyOperationalCost
     */
    costs: CompanyCosts;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyCompanyOperationalCost
     */
    dateTo?: Date | null;
}

export function ReadOnlyCompanyOperationalCostFromJSON(json: any): ReadOnlyCompanyOperationalCost {
    return ReadOnlyCompanyOperationalCostFromJSONTyped(json, false);
}

export function ReadOnlyCompanyOperationalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyCompanyOperationalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'costs': CompanyCostsFromJSON(json['costs']),
        'dateTo': !exists(json, 'date_to') ? undefined : (json['date_to'] === null ? null : new Date(json['date_to'])),
    };
}

export function ReadOnlyCompanyOperationalCostToJSON(value?: ReadOnlyCompanyOperationalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString()),
        'costs': CompanyCostsToJSON(value.costs),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo === null ? null : value.dateTo.toISOString()),
    };
}


