import { TachoCardModel } from 'modules/management/modules/tachograph-cards/TachoCardsModule';
import moment from 'moment';
import { ReadOnlyUserNested, ReadOnlyUserToken, ReadOnlyUserTokenTokenTypeEnum, UserToken } from 'generated/new-main';
import { Logic } from '../logic';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_OFFSET } from 'domain-constants';

export class ManagementCardsLogic {
    private _logic: Logic;
    private _tachoCards: TachoCardModel[];

    constructor(logic: Logic) {
        this._logic = logic;
        this._tachoCards = [];
    }

    unpairUserFromTachoCard(tachoId: number, userId: string): Promise<UserToken> {
        return this._unpairUserFromTachoCard(tachoId, userId);
    }

    async getTachoCards(
        refreshData: boolean = false,
        limit?: number,
        offset?: number,
        onlyFree?: boolean
    ): Promise<TachoCardModel[]> {
        if (refreshData || this._tachoCards.length === 0) {
            if (this._logic.demo().isActive) {
                return new Promise(resolve =>
                    resolve(this._logic.demo().data.tachoCards.map(tc => this._toTachoCardModel(tc)))
                );
            }
            return (this._tachoCards = (await this._getTachoCards(limit, offset, onlyFree)).data);
        } else return this._tachoCards;
    }

    async updateTachoCard(tachoCardModel: TachoCardModel): Promise<TachoCardModel[]> {
        await this._logic.api().userTokenApi.userTokenPartialUpdate({
            id: tachoCardModel.id,
            data: {
                token: tachoCardModel.token
            }
        });

        return (this._tachoCards = await this.getTachoCards(true));
    }

    async deleteTachoCard(tachoCardModel: TachoCardModel): Promise<TachoCardModel[]> {
        await this._logic.api().userTokenApi.userTokenDelete({ id: tachoCardModel.id });

        return (this._tachoCards = await this.getTachoCards(true));
    }

    private _toTachoCardModel(t: ReadOnlyUserToken): TachoCardModel {
        const { id, token, holder, endTime, startTime, user } = t;

        return {
            id: id ?? 0,
            token: token ?? '',
            expirationDate: moment.utc(endTime).toISOString() ?? '',
            startDate: moment.utc(startTime).toISOString() ?? moment().toISOString(),
            holder: holder ?? '',
            userId: user?.id ?? undefined,
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            links: {
                user: user && {
                    id: String(user.id ?? 0),
                    name: `${user.name} ${user.surname}`,
                    relation: '-'
                }
            }
        };
    }

    private async _getTachoCards(
        limit: number = 10e3,
        offset: number = DEFAULT_PAGE_OFFSET,
        onlyFree?: boolean
    ): Promise<PaginatedResponse<TachoCardModel[]>> {
        const requestTachoCards = await this._logic.api().userTokenApi.userTokenList({
            limit,
            offset,
            tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,

            onlyFree
        });
        return {
            data:
                requestTachoCards.results
                    ?.map(t => this._toTachoCardModel(t))
                    .sort((a, b) => (a.token > b.token ? 1 : -1)) ?? [],
            limit: 0,
            offset: 0,
            total: requestTachoCards.count
        };
    }

    private async _unpairUserFromTachoCard(tachoId: number, userId: string): Promise<UserToken> {
        const responseUpdated = await this._logic.api().userTokenApi.userTokenUnpairUser({
            id: tachoId,
            userId
        });
        return responseUpdated;
    }

    async unpairTachoCardFromUser(user: ReadOnlyUserNested, tachoCard: UserToken): Promise<UserToken> {
        return await this._unpairUserFromTachoCard(Number(tachoCard.id), String(user.id));
    }

    async unpairAppFromUser(user: ReadOnlyUserNested, appCard: UserToken): Promise<UserToken> {
        return await this._unpairUserFromTachoCard(Number(appCard.id), String(user.id));
    }

    async pairUserToTachoCard(tachoId: number, userId: string) {
        return await this._logic.api().userTokenApi.userTokenPairUser({
            id: tachoId,
            userId
        });
    }
}
