import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import TableBar from 'common/components/TableBar';
import { WithLogic, withLogicContext } from 'App';
import { SearchData } from 'common/components/Search';
import qa from 'qa-selectors';
import { ContactListFiltersType } from '../contactListFilters/ContactListFilters';
import ContactListFilter from '../contactListFilter/ContactListFilter';
import { Role } from 'logic/auth';
import * as CommonIcons from 'resources/images/common';
import { Button } from 'common/components';

interface Props extends WithTranslation, WithLogic {
    demoMode?: boolean;
    search?: SearchData;
    heading?: string;
    filterOpen?: boolean;
    backButton?: {
        onClick?: () => void;
        qa?: string;
        title?: string;
    };
    className?: string;
    onBarSearchChange?: (text: string) => void;
    onBarFilterClick?: () => void;
    onBarResetClick?: () => void;
    onFilterConfirmClick?: (filters: ContactListFiltersType) => void;
    onFilterCancelClick?: () => void;
    onCreateNewContact?: () => void;
}

function ContactListBar(props: Props) {
    return (
        <>
            <TableBar
                searchInput={{ disabled: props.demoMode }}
                heading={props.heading ?? ''}
                search={props.search}
                onSearchChange={props.onBarSearchChange}
                backButton={props.backButton}
                className={props.className}
                filter={{
                    activeCount:
                        (props.logic.contactListLogic().filters.countries.length > 0 ? 1 : 0) +
                        (props.logic.contactListLogic().filters.types.length > 0 ? 1 : 0),
                    onClick: props.onBarFilterClick,
                    qa: qa.fleet.bar.btnFilter,
                    resetButton: { onClick: props.onBarResetClick }
                }}
                actions={
                    props.logic.auth().roles().includes(Role.CL_W)
                        ? [
                              <Button
                                  icon={<img src={CommonIcons.addPlusCircleWhite} alt="add" />}
                                  type="dashed"
                                  size="large"
                                  onClick={props.onCreateNewContact}
                                  disabled={props.logic.demo().isActive}
                                  qa={qa.contacts.btnNewContact}
                              >
                                  {props.t('ContactList.createNew')}
                              </Button>
                          ]
                        : []
                }
            />
            {props.filterOpen && (
                <ContactListFilter
                    filters={props.logic.contactListLogic().filters}
                    countries={props.logic.contactListLogic().countryList}
                    demoMode={props.demoMode}
                    onSubmit={props.onFilterConfirmClick}
                    onCancel={props.onFilterCancelClick}
                />
            )}
        </>
    );
}

export default withTranslation()(withLogicContext(observer(ContactListBar)));
