/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TripEdit,
    TripEditFromJSON,
    TripEditFromJSONTyped,
    TripEditToJSON,
} from './';

/**
 * 
 * @export
 * @interface BodyEditTripsV2TripsEditPost
 */
export interface BodyEditTripsV2TripsEditPost {
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyEditTripsV2TripsEditPost
     */
    tripIds: Array<string>;
    /**
     * 
     * @type {TripEdit}
     * @memberof BodyEditTripsV2TripsEditPost
     */
    edit: TripEdit;
}

export function BodyEditTripsV2TripsEditPostFromJSON(json: any): BodyEditTripsV2TripsEditPost {
    return BodyEditTripsV2TripsEditPostFromJSONTyped(json, false);
}

export function BodyEditTripsV2TripsEditPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyEditTripsV2TripsEditPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tripIds': json['trip_ids'],
        'edit': TripEditFromJSON(json['edit']),
    };
}

export function BodyEditTripsV2TripsEditPostToJSON(value?: BodyEditTripsV2TripsEditPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trip_ids': value.tripIds,
        'edit': TripEditToJSON(value.edit),
    };
}


