import { Logic } from 'logic/logic';
import { PartnerPairingKey } from 'common/model/partner-pairing';
import {
    ReadOnlyCurrency,
    Country,
    ReadOnlyOBUType,
    ReadOnlyMonitoredObjectGroupType,
    ReadOnlyMonitoredObjectGroup
} from 'generated/new-main';
import { PartnerAddressLogic } from './logic/partner-address';
import { PartnerBundleLogic as PartnerTlmBundlesLogic } from './logic/partner-tlm-bundle';
import { PartnerCcrLogic } from './logic/partner-ccr';
import { PartnerCompaniesLogic } from './logic/partner-companies';
import { PartnerContactPersonLogic } from './logic/partner-contact-person';
import { PartnerInvoicesLogic } from './logic/partner-invoices';
import { PartnerObuLogic } from './logic/partner-obu';
import { PartnerPartnersLogic } from './logic/partner-partners';
import { PartnerUserLogic } from './logic/partner-user';
import { PartnerVehiclesLogic } from './logic/partner-vehicles';
import { PartnerWhitelabelLogic } from './logic/partner-whitelabel';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';

export interface PartnerConfig {
    url: string;
}
export interface PairingItemType {
    id: string;
    key: PartnerPairingKey;
}

export interface CurrencySelectModel {
    id: string;
    code: string;
}

export interface CountrySelectModel {
    id: number;
    name: string;
    code: string;
}
export interface ObuTypeSelectModel {
    id: string;
    name: string;
}
export interface ServiceTypeSelectModel {
    id: string;
    name: string;
}
export interface MonitoredObjectGroupSelectModel {
    id: number;
    name: string;
}
export interface MonitoredObjectGroupTypeSelectModel {
    id: number;
    name: string;
}

export class PartnerLogic {
    private _address?: PartnerAddressLogic;
    private _tlmBundles?: PartnerTlmBundlesLogic;
    private _ccr?: PartnerCcrLogic;
    private _companies?: PartnerCompaniesLogic;
    private _contactPerson?: PartnerContactPersonLogic;
    private _invoices?: PartnerInvoicesLogic;
    private _obu?: PartnerObuLogic;
    private _partners?: PartnerPartnersLogic;
    private _user?: PartnerUserLogic;
    private _vehicles?: PartnerVehiclesLogic;
    private _whitelabel?: PartnerWhitelabelLogic;

    constructor(private _logic: Logic) {}

    address() {
        if (!this._address) {
            this._address = new PartnerAddressLogic(this._logic);
        }

        return this._address;
    }

    tlmBundles() {
        if (!this._tlmBundles) {
            this._tlmBundles = new PartnerTlmBundlesLogic(this._logic);
        }

        return this._tlmBundles;
    }
    ccr() {
        if (!this._ccr) {
            this._ccr = new PartnerCcrLogic(this._logic);
        }

        return this._ccr;
    }
    companies() {
        if (!this._companies) {
            this._companies = new PartnerCompaniesLogic(this._logic);
        }

        return this._companies;
    }
    contactPerson() {
        if (!this._contactPerson) {
            this._contactPerson = new PartnerContactPersonLogic(this._logic);
        }

        return this._contactPerson;
    }
    invoices() {
        if (!this._invoices) {
            this._invoices = new PartnerInvoicesLogic(this._logic);
        }

        return this._invoices;
    }
    obu() {
        if (!this._obu) {
            this._obu = new PartnerObuLogic(this._logic);
        }

        return this._obu;
    }
    partners() {
        if (!this._partners) {
            this._partners = new PartnerPartnersLogic(this._logic);
        }

        return this._partners;
    }
    user() {
        if (!this._user) {
            this._user = new PartnerUserLogic(this._logic);
        }

        return this._user;
    }
    vehicles() {
        if (!this._vehicles) {
            this._vehicles = new PartnerVehiclesLogic(this._logic);
        }

        return this._vehicles;
    }
    whitelabel() {
        if (!this._whitelabel) {
            this._whitelabel = new PartnerWhitelabelLogic(this._logic);
        }

        return this._whitelabel;
    }

    async pair(source: PairingItemType, linked: PairingItemType, permissions?: string): Promise<boolean> {
        console.log('PAIR', source.id, source.key, linked.id, linked.key);
        try {
            if (linked.key === PartnerPairingKey.vehicles) {
                await this._logic.api().monitoredObjectApi.monitoredObjectPartialUpdate({
                    id: Number(linked.id),
                    data: {
                        moid: Number(linked.id),
                        monitoringDevice: Number(source.id)
                    }
                });
            } else if (source.key === PartnerPairingKey.vehicles && linked.key === PartnerPairingKey.obu) {
                await this._logic.api().monitoredObjectApi.monitoredObjectPartialUpdate({
                    id: Number(source.id),
                    data: {
                        moid: Number(source.id),
                        monitoringDevice: Number(linked.id)
                    }
                });
            } else if (source.key === PartnerPairingKey.companies) {
                await this._logic.partner()._partners?.assignReferer(linked.id, source.id);
            } else if (source.key === PartnerPairingKey.obu && linked.key === PartnerPairingKey.tlmBundles) {
                await this._logic.partner().obu().assignObuPermissions(source.id, permissions);
            }
            return true;
        } catch (err) {
            console.log('Pair PATCH err:', err);
            throw err;
        }
    }

    async unpair(source: PairingItemType, linked: PairingItemType): Promise<boolean> {
        console.log('UNPAIR', source.id, source.key, linked.id, linked.key);
        try {
            if (source.key === PartnerPairingKey.obu && linked.key === PartnerPairingKey.vehicles) {
                await this._logic.api().monitoredObjectApi.monitoredObjectUnpairMonitoringDevice({
                    id: Number(linked.id),
                    data: {}
                });
            } else if (source.key === PartnerPairingKey.vehicles && linked.key === PartnerPairingKey.obu) {
                await this._logic.api().monitoredObjectApi.monitoredObjectUnpairMonitoringDevice({
                    id: Number(source.id),
                    data: {}
                });
            } else if (linked.key === PartnerPairingKey.partners) {
                await this._logic.partner()._partners?.assignReferer(source.id, null);
            }
            return true;
        } catch (err) {
            console.log('Unpair PATCH err:', err);
            throw err;
        }
    }

    async getCurrencyList(): Promise<CurrencySelectModel[]> {
        try {
            const response = await this._logic.api().currencyApi.currencyList({
                limit: INFINITY_PAGE_LIMIT,
                offset: DEFAULT_PAGE_OFFSET
            });
            return response.results.map(this._toCurrency);
        } catch (err) {
            console.log('Currency counter GET err:', err);
            throw err;
        }
    }

    async getCountryList(): Promise<CountrySelectModel[]> {
        try {
            if (this._logic.demo().isActive) {
                return this._logic.demo().data.countries.map(a => this._toCountry(a));
            } else {
                const response = await this._logic
                    .api()
                    .countryApi.countryList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
                return response.results.map(this._toCountry);
            }
        } catch (err) {
            console.log('Country counter GET err:', err);
            throw err;
        }
    }
    async getObuTypeList(): Promise<ObuTypeSelectModel[]> {
        try {
            const response = await this._logic
                .api()
                .obuTypeApi.obuTypeList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
            return response.results.map(this._toObuType);
        } catch (err) {
            console.log('Obu type counter GET err:', err);
            throw err;
        }
    }
    // async getServiceTypeList(): Promise<ServiceTypeSelectModel[]> {
    //     try {
    //         const response = await this._logic
    //             .api()
    //             .serviceTypeApi.serviceTypeList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
    //         return response.results.map(this._toServiceType);
    //     } catch (err) {
    //         console.log('Service type counter GET err:', err);
    //         throw err;
    //     }
    // }
    async getMonitoredObjectGroupList(): Promise<MonitoredObjectGroupSelectModel[]> {
        try {
            const response = await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupList({
                limit: INFINITY_PAGE_LIMIT,
                offset: DEFAULT_PAGE_OFFSET
            });
            return response.results.map(this._toMonitoredObjectGroup);
        } catch (err) {
            console.log('Monitored object group counter GET err:', err);
            throw err;
        }
    }
    async getMonitoredObjectGroupTypeList(): Promise<MonitoredObjectGroupTypeSelectModel[]> {
        try {
            const response = await this._logic.api().monitoredObjectGroupTypeApi.monitoredObjectGroupTypeList({
                limit: INFINITY_PAGE_LIMIT,
                offset: DEFAULT_PAGE_OFFSET
            });
            return response.results.map(this._toMonitoredObjectGroupType);
        } catch (err) {
            console.log('Monitored object group type counter GET err:', err);
            throw err;
        }
    }

    private _toCurrency(data: ReadOnlyCurrency): CurrencySelectModel {
        return {
            id: String(data.id),
            code: data.code
        };
    }

    private _toCountry(data: Country): CountrySelectModel {
        return {
            id: data.id!,
            name: data.name,
            code: data.iso2
        };
    }
    private _toObuType(data: ReadOnlyOBUType): ObuTypeSelectModel {
        return {
            id: data.id!,
            name: data.name
        };
    }

    private _toMonitoredObjectGroup(data: ReadOnlyMonitoredObjectGroup): MonitoredObjectGroupSelectModel {
        return {
            id: data.id!,
            name: data.name
        };
    }
    private _toMonitoredObjectGroupType(data: ReadOnlyMonitoredObjectGroupType): MonitoredObjectGroupTypeSelectModel {
        return {
            id: data.id!,
            name: data.name
        };
    }
}

export const sampleCurrency: CurrencySelectModel[] = [
    {
        id: '5',
        code: 'EUR'
    },
    {
        id: '6',
        code: 'CZK'
    }
];

export const sampleCountry: CountrySelectModel[] = [
    {
        id: 1,
        name: 'Slovakia',
        code: '22'
    },
    {
        id: 2,
        name: 'Czechia',
        code: '44'
    }
];

export const sampleObuType: ObuTypeSelectModel[] = [
    {
        id: '1sadfafasdsada',
        name: 'vetronics'
    },
    {
        id: '22131312dsad',
        name: 'other'
    }
];
