/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Leg,
    LegFromJSON,
    LegFromJSONTyped,
    LegToJSON,
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    VehicleForClient,
    VehicleForClientFromJSON,
    VehicleForClientFromJSONTyped,
    VehicleForClientToJSON
} from './';

/**
 *
 * @export
 * @interface ProposalForClient
 */
export interface ProposalForClient {
    /**
     *
     * @type {VehicleForClient}
     * @memberof ProposalForClient
     */
    vehicle: VehicleForClient;
    /**
     *
     * @type {Order}
     * @memberof ProposalForClient
     */
    order: Order;
    /**
     *
     * @type {number}
     * @memberof ProposalForClient
     */
    timestamp: number;
    /**
     *
     * @type {Date}
     * @memberof ProposalForClient
     */
    expirationDate: Date;
    /**
     *
     * @type {string}
     * @memberof ProposalForClient
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ProposalForClient
     */
    type: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ProposalForClient
     */
    tenants: Array<string>;
    /**
     *
     * @type {Date}
     * @memberof ProposalForClient
     */
    creationDate?: Date;
    /**
     *
     * @type {number}
     * @memberof ProposalForClient
     */
    cost?: number;
    /**
     *
     * @type {boolean}
     * @memberof ProposalForClient
     */
    valid?: boolean;
    /**
     *
     * @type {Array<Leg>}
     * @memberof ProposalForClient
     */
    legs?: Array<Leg>;
    /**
     *
     * @type {number}
     * @memberof ProposalForClient
     */
    rating?: number;
    /**
     *
     * @type {number}
     * @memberof ProposalForClient
     */
    externalId: number;
    /**
     *
     * @type {boolean}
     * @memberof ProposalForClient
     */
    removed?: boolean;
}

export function ProposalForClientFromJSON(json: any): ProposalForClient {
    return ProposalForClientFromJSONTyped(json, false);
}

export function ProposalForClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalForClient {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        vehicle: VehicleForClientFromJSON(json['vehicle']),
        order: OrderFromJSON(json['order']),
        timestamp: json['@timestamp'],
        expirationDate: new Date(json['expiration_date']),
        id: json['id'],
        type: json['type'],
        tenants: json['tenants'],
        creationDate: !exists(json, 'creation_date') ? undefined : new Date(json['creation_date']),
        cost: !exists(json, 'cost') ? undefined : json['cost'],
        valid: !exists(json, 'valid') ? undefined : json['valid'],
        legs: !exists(json, 'legs') ? undefined : (json['legs'] as Array<any>).map(LegFromJSON),
        rating: !exists(json, 'rating') ? undefined : json['rating'],
        externalId: json['external_id'],
        removed: !exists(json, 'removed') ? undefined : json['removed']
    };
}

export function ProposalForClientToJSON(value?: ProposalForClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        vehicle: VehicleForClientToJSON(value.vehicle),
        order: OrderToJSON(value.order),
        '@timestamp': value.timestamp,
        expiration_date: value.expirationDate.toISOString(),
        id: value.id,
        type: value.type,
        tenants: value.tenants,
        creation_date: value.creationDate === undefined ? undefined : value.creationDate.toISOString(),
        cost: value.cost,
        valid: value.valid,
        legs: value.legs === undefined ? undefined : (value.legs as Array<any>).map(LegToJSON),
        rating: value.rating,
        external_id: value.externalId,
        removed: value.removed
    };
}
