/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GraphInfo,
    GraphInfoFromJSON,
    GraphInfoToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LegDataFields,
    LegDataFieldsFromJSON,
    LegDataFieldsToJSON,
    TemperatureSensorData,
    TemperatureSensorDataFromJSON,
    TemperatureSensorDataToJSON,
} from '../models';

export interface GetGraphInfoV1GraphApiLegDataGetRequest {
    monitoredObjectId: string;
    activityIntervalIds?: Array<string>;
    timestampFrom?: Date;
    timestampTo?: Date;
    onlyFields?: Array<LegDataFields>;
}

export interface GetTemperatureSensorsDataV1GraphApiTemperatureSensorDataGetRequest {
    timestampFrom: Date;
    timestampTo: Date;
    serialNumbers: Array<string>;
    addMockTemperatures?: boolean;
    clientId?: number;
}

/**
 * no description
 */
export class GraphApiApi extends runtime.BaseAPI {

    /**
     * Get Graph Info
     */
    async getGraphInfoV1GraphApiLegDataGetRaw(requestParameters: GetGraphInfoV1GraphApiLegDataGetRequest): Promise<runtime.ApiResponse<Array<GraphInfo>>> {
        if (requestParameters.monitoredObjectId === null || requestParameters.monitoredObjectId === undefined) {
            throw new runtime.RequiredError('monitoredObjectId','Required parameter requestParameters.monitoredObjectId was null or undefined when calling getGraphInfoV1GraphApiLegDataGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.activityIntervalIds) {
            queryParameters['activity_interval_ids'] = requestParameters.activityIntervalIds;
        }

        if (requestParameters.timestampFrom !== undefined) {
            queryParameters['timestamp_from'] = (requestParameters.timestampFrom as any).toISOString();
        }

        if (requestParameters.timestampTo !== undefined) {
            queryParameters['timestamp_to'] = (requestParameters.timestampTo as any).toISOString();
        }

        if (requestParameters.onlyFields) {
            queryParameters['only_fields'] = requestParameters.onlyFields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/graph-api/leg-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GraphInfoFromJSON));
    }

    /**
     * Get Graph Info
     */
    async getGraphInfoV1GraphApiLegDataGet(requestParameters: GetGraphInfoV1GraphApiLegDataGetRequest): Promise<Array<GraphInfo>> {
        const response = await this.getGraphInfoV1GraphApiLegDataGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Temperature Sensors Data
     */
    async getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGetRaw(requestParameters: GetTemperatureSensorsDataV1GraphApiTemperatureSensorDataGetRequest): Promise<runtime.ApiResponse<Array<TemperatureSensorData>>> {
        if (requestParameters.timestampFrom === null || requestParameters.timestampFrom === undefined) {
            throw new runtime.RequiredError('timestampFrom','Required parameter requestParameters.timestampFrom was null or undefined when calling getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet.');
        }

        if (requestParameters.timestampTo === null || requestParameters.timestampTo === undefined) {
            throw new runtime.RequiredError('timestampTo','Required parameter requestParameters.timestampTo was null or undefined when calling getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet.');
        }

        if (requestParameters.serialNumbers === null || requestParameters.serialNumbers === undefined) {
            throw new runtime.RequiredError('serialNumbers','Required parameter requestParameters.serialNumbers was null or undefined when calling getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestampFrom !== undefined) {
            queryParameters['timestamp_from'] = (requestParameters.timestampFrom as any).toISOString();
        }

        if (requestParameters.timestampTo !== undefined) {
            queryParameters['timestamp_to'] = (requestParameters.timestampTo as any).toISOString();
        }

        if (requestParameters.serialNumbers) {
            queryParameters['serial_numbers'] = requestParameters.serialNumbers;
        }

        if (requestParameters.addMockTemperatures !== undefined) {
            queryParameters['_add_mock_temperatures_'] = requestParameters.addMockTemperatures;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/graph-api/temperature-sensor-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemperatureSensorDataFromJSON));
    }

    /**
     * Get Temperature Sensors Data
     */
    async getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet(requestParameters: GetTemperatureSensorsDataV1GraphApiTemperatureSensorDataGetRequest): Promise<Array<TemperatureSensorData>> {
        const response = await this.getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGetRaw(requestParameters);
        return await response.value();
    }

}
