import React, { ChangeEvent, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    text?: string;
    onChange: (text: string) => void;
}

function SuperAdminSearch(props: Props) {
    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        props.onChange(e.target.value);
    }

    return (
        <div className="superadmin-search t-bar t-default t-padding">
            <form onSubmit={onSubmit}>
                <input
                    className="search t-input t-border"
                    type="text"
                    name="search"
                    autoFocus
                    autoComplete="off"
                    placeholder={props.t('SuperAdminBar.searchPlaceholder')}
                    defaultValue={props.text}
                    onChange={onChange}
                />
            </form>
        </div>
    );
}

export default withTranslation()(SuperAdminSearch);
