import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InputField, SelectField } from 'common/fields';
import { Col, Row } from 'antd';
import { MaintenanceExpenseModel } from '../MaintenanceForm';
import { SelectOption } from 'common/components/Form/Select';
import { Button } from 'common/components';
import { ExpensesItemsTypeEnum } from 'generated/new-main';

interface Props extends WithTranslation {
    initialValues?: Partial<MaintenanceExpenseModel>;
    typeOpts: SelectOption[];
    onSubmit: (values: MaintenanceExpenseModel) => Promise<boolean>;
}

function MaintenanceExpenseForm(props: Props) {
    let form: FormikProps<MaintenanceExpenseModel>;

    const schema = Yup.object().shape({
        name: Yup.string().required(props.t('common.required')),
        taskType: Yup.mixed<ExpensesItemsTypeEnum>().oneOf(Object.values(ExpensesItemsTypeEnum)),
        priceWoVat: Yup.number().min(0.01, props.t('validator.invalid_value')).required(props.t('common.required'))
    });

    function handleOnSubmit(values: MaintenanceExpenseModel) {
        props.onSubmit?.(values);
    }

    return (
        <Formik<MaintenanceExpenseModel>
            initialValues={{
                name: props.initialValues?.name,
                type: props.initialValues?.type,
                priceWoVat: props.initialValues?.priceWoVat
            }}
            onSubmit={val => {
                handleOnSubmit(val);
                form.resetForm();
            }}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {(formik: FormikProps<MaintenanceExpenseModel>) => {
                form = formik;
                return (
                    <Form className="maintenance-expense-form">
                        <Row gutter={[12, 0]}>
                            <Col span={10}>
                                <InputField name="name" size="large" label={props.t('Maintenance.task.newItem')} />
                            </Col>
                            <Col span={5}>
                                <SelectField
                                    options={props.typeOpts}
                                    name="type"
                                    size="large"
                                    label={`${props.t('common.type')} (${props.t('common.optional')})`}
                                />
                            </Col>
                            <Col span={6}>
                                <InputField.Number
                                    name="priceWoVat"
                                    size="large"
                                    min={0.01}
                                    label={`${props.t('Maintenance.task.itemPrice')} (${props.t(
                                        'common.excludingVAT'
                                    )})`}
                                />
                            </Col>
                            <Col span={3}>
                                <Button
                                    block
                                    type="default"
                                    onClick={formik.submitForm}
                                    disabled={formik.isSubmitting}
                                    className="empty-label"
                                >
                                    {props.t('common.add')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(MaintenanceExpenseForm);
