import cn from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { CheckboxGroupField, DatePickerField, InputField, SelectField, SliderField } from 'common/fields';
import { Button } from 'common/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import moment from 'moment';
import { DATE_FORMAT } from 'domain-constants';
import { SelectOption } from 'common/components/Form/Select';
import { CalculationTypeOptions, pocketMoneyOptions } from 'common/model/allowance';
import CetWarning from 'modules/statistics/modules/allowance/components/AllowanceCETWarning/AllowanceCETWarning';
import FormActions from 'common/components/FormActions';

export interface DiemsBulkFormModel {
    start: string;
    end: string;
    replacedMeals?: number[];
    pocketMoney?: number;
    calculationType?: number;
    fixedRate?: {
        domesticRate: number;
        foreignRate: number;
    };
}

interface Props extends WithTranslation {
    initialValues?: Partial<DiemsBulkFormModel>;
    replacementMeals: CheckboxOptionType[];
    calculationType: SelectOption[];
    isPolishDiems?: boolean;
    wide?: boolean;
    closeButtonText?: string;
    submitButtonText?: string;
    lang: string;
    applyRowPadding?: boolean;
    demoMode: boolean;
    onSubmit?: (values: DiemsBulkFormModel) => void;
    onCancel?: () => void;
}

function DiemsBulkForm(props: Props) {
    const schema = Yup.object().shape({
        replacedMeals: Yup.array().of(Yup.number()),
        pocketMoney: Yup.number().required(props.t('common.required')),
        end: Yup.string().required(props.t('common.required')),
        start: Yup.string().required(props.t('common.required')),
        calculationType: Yup.number().required(props.t('common.required')),
        fixedRate: Yup.object().when('calculationType', {
            is: CalculationTypeOptions.fixedRates,
            then: Yup.object({
                domesticRate: Yup.number().required(props.t('common.required')),
                foreignRate: Yup.number().required(props.t('common.required'))
            })
        })
    });

    function handleSubmit(values: DiemsBulkFormModel) {
        values.start = moment(values.start).format(DATE_FORMAT);
        values.end = moment(values.end).format(DATE_FORMAT);
        props.onSubmit?.(values);
    }

    return (
        <Formik<DiemsBulkFormModel>
            initialValues={{
                replacedMeals: props.initialValues?.replacedMeals ?? [],
                pocketMoney: props.initialValues?.pocketMoney ?? 0,
                end: moment.utc(props.initialValues?.end, DATE_FORMAT).format() ?? moment.utc().format(),
                start: moment.utc(props.initialValues?.start, DATE_FORMAT).format() ?? moment.utc().format(),
                calculationType: props.initialValues?.calculationType ?? CalculationTypeOptions.default,
                fixedRate: props.initialValues?.fixedRate
                    ? {
                          domesticRate: props.initialValues.fixedRate.domesticRate,
                          foreignRate: props.initialValues.fixedRate.foreignRate
                      }
                    : undefined
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<DiemsBulkFormModel>) => {
                const leftSide = (
                    <>
                        {!props.isPolishDiems && (
                            <>
                                <Row className="form-row title">
                                    <Col span={24}>{props.t('Allowance.bulk.supply')}</Col>
                                </Row>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <SliderField
                                            min={0}
                                            max={4}
                                            tipFormatter={null}
                                            marks={pocketMoneyOptions}
                                            step={null}
                                            defaultValue={0}
                                            name="pocketMoney"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row className="form-row title" gutter={[16, 24]}>
                            <Col span={24}>{props.t('Allowance.bulk.shortening')}</Col>
                        </Row>
                        <Row className="form-row">
                            <Col span={24}>
                                <CheckboxGroupField
                                    className="replaced-meals"
                                    name="replacedMeals"
                                    options={props.replacementMeals}
                                />
                            </Col>
                        </Row>
                    </>
                );

                const rightSideGlobal = (
                    <Row gutter={[16, 24]} align="middle" justify="space-between" className="form-row">
                        <Col span={12}>
                            <DatePickerField
                                format="L"
                                disabledDate={d => !d || d.isAfter(moment(formik.values.end))}
                                lang={props.lang}
                                label={props.t('common.validFrom')}
                                name="start"
                            />
                        </Col>
                        <Col span={12}>
                            <DatePickerField
                                format="L"
                                disabledDate={d => !d || d.isBefore(moment(formik.values.start))}
                                lang={props.lang}
                                label={props.t('common.validTo')}
                                name="end"
                            />
                        </Col>
                    </Row>
                );

                const rightSidePolish = (
                    <>
                        <Row align="middle" justify="space-between" className="form-row title" gutter={[16, 24]}>
                            <Col>{props.t('Allowance.bulk.diemsCalculationSetting')}</Col>
                            <Col>
                                <Button
                                    qa={qa.common.btnHelper}
                                    className="button-helper"
                                    type="primary"
                                    title={props.t('common.helper')}
                                    size="small"
                                    icon={<img src="/img-svg/icn-help.svg" alt={props.t('common.helper')} />}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]}>
                            <Col span={10}>
                                <SelectField
                                    label={props.t('Allowance.bulk.calculationType')}
                                    name="calculationType"
                                    options={props.calculationType}
                                />
                            </Col>
                            <Col span={7}>
                                <DatePickerField
                                    format="L"
                                    disabled={moment
                                        .utc(props.initialValues?.start, DATE_FORMAT)
                                        .isBefore(moment.utc().subtract(1, 'days'))}
                                    lang={props.lang}
                                    disabledDate={d =>
                                        !d ||
                                        d.isAfter(moment(formik.values.end)) ||
                                        d.isBefore(moment.utc().subtract(1, 'days'))
                                    }
                                    label={props.t('common.validFrom')}
                                    name="start"
                                />
                            </Col>
                            <Col span={7}>
                                <DatePickerField
                                    format="L"
                                    showTime={false}
                                    disabledDate={d =>
                                        !d ||
                                        d.isBefore(moment(formik.values.start)) ||
                                        d.isBefore(moment.utc().subtract(1, 'days'))
                                    }
                                    lang={props.lang}
                                    label={props.t('common.validTo')}
                                    name="end"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} className="form-row utc-time-warning">
                            <Col span={24}>
                                <CetWarning />
                            </Col>
                        </Row>

                        {formik.values.calculationType === CalculationTypeOptions.fixedRates && (
                            <>
                                <Row
                                    align="middle"
                                    justify="space-between"
                                    className="form-row title"
                                    gutter={[16, 24]}
                                >
                                    <Col>{props.t('Allowance.bulk.dailyRates')}</Col>
                                </Row>
                                <Row gutter={[16, 24]} className="form-row">
                                    <Col span={12}>
                                        <InputField.Number
                                            label={props.t('Allowance.bulk.domesticRatePerDay')}
                                            name="fixedRate.domesticRate"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InputField.Number
                                            label={props.t('Allowance.bulk.foreignRatePerDay')}
                                            name="fixedRate.foreignRate"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                );

                return (
                    <Form className="diems-rules-form">
                        {props.wide ? (
                            <Row
                                className={cn({
                                    'row-padding': props.applyRowPadding
                                })}
                                gutter={[64, 24]}
                            >
                                <Col span={12}>{leftSide}</Col>
                                <Col span={12}>{props.isPolishDiems ? rightSidePolish : rightSideGlobal}</Col>
                            </Row>
                        ) : (
                            <div
                                className={cn({
                                    'row-padding': props.applyRowPadding
                                })}
                            >
                                {leftSide}
                                {props.isPolishDiems ? rightSidePolish : rightSideGlobal}
                            </div>
                        )}

                        <div className="row-padding diems-rules-form-footer">
                            <FormActions
                                submitDisabled={props.demoMode}
                                submitLoading={formik.isSubmitting}
                                submitText={props.submitButtonText ?? props.t('common.confirm')}
                                cancelQa={qa.roles.detail.btnFormCancel}
                                submitQa={qa.roles.detail.btnFormSubmit}
                                onCancelClick={props.onCancel}
                                onSubmitClick={formik.submitForm}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(DiemsBulkForm);
