/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyWhiteLabelTemplate
 */
export interface ReadOnlyWhiteLabelTemplate {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyWhiteLabelTemplate
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyWhiteLabelTemplate
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyWhiteLabelTemplate
     */
    name?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyWhiteLabelTemplate
     */
    content: object;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyWhiteLabelTemplate
     */
    client?: number | null;
}

export function ReadOnlyWhiteLabelTemplateFromJSON(json: any): ReadOnlyWhiteLabelTemplate {
    return ReadOnlyWhiteLabelTemplateFromJSONTyped(json, false);
}

export function ReadOnlyWhiteLabelTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyWhiteLabelTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'content': json['content'],
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function ReadOnlyWhiteLabelTemplateToJSON(value?: ReadOnlyWhiteLabelTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'content': value.content,
        'client': value.client,
    };
}


