/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlaceOfWorkInDB,
    PlaceOfWorkInDBFromJSON,
    PlaceOfWorkInDBFromJSONTyped,
    PlaceOfWorkInDBToJSON,
    PoiPolygonType,
    PoiPolygonTypeFromJSON,
    PoiPolygonTypeFromJSONTyped,
    PoiPolygonTypeToJSON,
    PoiType,
    PoiTypeFromJSON,
    PoiTypeFromJSONTyped,
    PoiTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomPlace
 */
export interface CustomPlace {
    /**
     * 
     * @type {string}
     * @memberof CustomPlace
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomPlace
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof CustomPlace
     */
    center: object;
    /**
     * 
     * @type {object}
     * @memberof CustomPlace
     */
    polygon: object;
    /**
     * 
     * @type {PoiPolygonType}
     * @memberof CustomPlace
     */
    polygonType: PoiPolygonType;
    /**
     * 
     * @type {string}
     * @memberof CustomPlace
     */
    notes: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomPlace
     */
    forbidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomPlace
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof CustomPlace
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomPlace
     */
    address: string;
    /**
     * 
     * @type {PoiType}
     * @memberof CustomPlace
     */
    type: PoiType;
    /**
     * 
     * @type {Array<PlaceOfWorkInDB>}
     * @memberof CustomPlace
     */
    placeOfWork?: Array<PlaceOfWorkInDB>;
    /**
     * 
     * @type {string}
     * @memberof CustomPlace
     */
    countryCode?: string;
}

export function CustomPlaceFromJSON(json: any): CustomPlace {
    return CustomPlaceFromJSONTyped(json, false);
}

export function CustomPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'center': json['center'],
        'polygon': json['polygon'],
        'polygonType': PoiPolygonTypeFromJSON(json['polygonType']),
        'notes': json['notes'],
        'forbidden': json['forbidden'],
        'deleted': json['deleted'],
        'clientId': json['clientId'],
        'address': json['address'],
        'type': PoiTypeFromJSON(json['type']),
        'placeOfWork': !exists(json, 'place_of_work') ? undefined : ((json['place_of_work'] as Array<any>).map(PlaceOfWorkInDBFromJSON)),
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
    };
}

export function CustomPlaceToJSON(value?: CustomPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'center': value.center,
        'polygon': value.polygon,
        'polygonType': PoiPolygonTypeToJSON(value.polygonType),
        'notes': value.notes,
        'forbidden': value.forbidden,
        'deleted': value.deleted,
        'clientId': value.clientId,
        'address': value.address,
        'type': PoiTypeToJSON(value.type),
        'place_of_work': value.placeOfWork === undefined ? undefined : ((value.placeOfWork as Array<any>).map(PlaceOfWorkInDBToJSON)),
        'countryCode': value.countryCode,
    };
}


