import { DRIVER_BEHAVIOR_DATE_FORMAT } from 'domain-constants';
import { PerfectDrivePeriod, PerfectDriveScorePeriod } from 'generated/backend-api';
import moment from 'moment';
import { users } from './users';
import { vehicles } from './vehicles';
import { UserTokenTokenTypeEnum } from 'generated/new-main';

const perfectDriverData = [
    {
        overalScore: 79.13493750988474,
        driveScore: 74.22762960846946,
        ecoScore: 66.96081231983796,
        wearTearScore: 96.2163706013468,
        rank: 1,
        driver: {
            id: 0,
            name: '',
            surname: '',
            tachoCard: ''
        },
        pdGroupByDriver: {
            idcar: [356],
            status: [0],
            info: ['the report can be calculated'],
            type: [0],
            vGlobal: {
                fg0TStayIncTStayPtoTREng: -1,
                fg0DateStart: [new Date('2024-02-01T16:58:02')],
                fg0DateEnd: [new Date('2024-02-05T03:26:45')],
                fg0Driver1: ['CZ 00000000007T3004'],
                fg0Driver2: [''],
                fg0TStayIdle: 12862,
                fg0DOdo: 1715.46,
                fg0DDrv: 1698.105,
                fg0TREng: 103571,
                fg0TDrv: 77014,
                fg0AltIncrDDrv: 830.2195682834689,
                fg0CntDpDDrv: 5.476693137350164,
                fg0C: 445.45,
                fg0TStayIdleTREng: 12.418534145658533,
                fg0TStayIncTStayPto: 915,
                fg0CDrvDDrv: 25.563790224986093,
                fg0MAvg: 24.957768070879013,
                fg0DiffDOdoDDrv: 0,
                dDrvWeightCatPerc0: 0,
                dDrvWeightCatPerc1: 0,
                dDrvWeightCatPerc2: 0.081562,
                dDrvWeightCatPerc3: 1.224895,
                dDrvWeightCatPerc4: 82.468987,
                dDrvWeightCatPerc5: 16.087639,
                dDrvWeightCatPerc6: 0,
                dDrvWeightCatPerc7: 0,
                dDrvWeightCatPerc8: 0.136917,
                dDrvWeightCatPerc33: 0,
                loadWeightQ: 16000,
                noLoad: 0,
                loadL5: 1.0535273142709078,
                loadM5: 98.5566263570274,
                loadNan: 0,
                fc1TStayIdleU2Min: 12780,
                fc1TStayIdleU2MinTREng: 12.339361
            },
            drivingMode: {
                drivingModeReportType1: {
                    fd1TDrv: 48183,
                    fd1TREng: 48183,
                    fd1DDrvTDrv: 86.61727165182741,
                    fd1DDrv: 1159.3,
                    fd1CntDp: 1,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 32503,
                    fd1TDrvCcTREng: 67.45740198825312,
                    fd1AltIncrDDrv: 750.9704131803675,
                    roadType: 1,
                    fd1SpendInDrivingMode: 0.4652170974500584
                },
                drivingModeReportType2: {
                    fd1TDrv: 22362,
                    fd1TREng: 22423,
                    fd1DDrvTDrv: 79.53501475717735,
                    fd1DDrv: 494.045,
                    fd1CntDp: 3,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 11938,
                    fd1TDrvCcTREng: 53.239976809525935,
                    fd1AltIncrDDrv: 1001.3257901608152,
                    roadType: 2,
                    fd1SpendInDrivingMode: 0.21649882689169747
                },
                drivingModeReportType3: {
                    fd1TDrv: 3689,
                    fd1TREng: 3964,
                    fd1DDrvTDrv: 35.55109785849823,
                    fd1DDrv: 36.43,
                    fd1CntDp: 19,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 0,
                    fd1TDrvCcTREng: 0,
                    fd1AltIncrDDrv: 883.8869063958279,
                    roadType: 3,
                    fd1SpendInDrivingMode: 0.03827326182039374
                },
                drivingModeReportType4: {
                    fd1TDrv: 2780,
                    fd1TREng: 29001,
                    fd1DDrvTDrv: 10.787050359712229,
                    fd1DDrv: 8.33,
                    fd1CntDp: 70,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 1,
                    fd1TDrvCcTREng: 0.003448156960104824,
                    fd1AltIncrDDrv: 1476.5906362545018,
                    roadType: 4,
                    fd1SpendInDrivingMode: 0.28001081383785037
                },
                drivingModeReportSummary: {
                    fd1TDrv: 77014,
                    fd1TREng: 103571,
                    fd1DDrvTDrv: 79.37748980704806,
                    fd1DDrv: 1698.105,
                    fd1CntDp: 93,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 44442,
                    fd1TDrvCcTREng: 42.9096947987371,
                    fd1AltIncrDDrv: 830.2195682834689
                }
            },
            fuelConsumption: {
                fuelConsumptionGlobal: {
                    fc0CDrv: 434.1,
                    fc0CStayIdle: 0.52,
                    fc0TStayIdleCntDp: 138.30107526881721,
                    fc0CCStayIncCStayPtoDDrv: 26.19390438164896
                },
                fuelConsumptionReportSummary: {
                    fc1CDrvDDrv: 25.563790224986093,
                    fc1TDrvWoCTEcoroll: 17277,
                    fc1TDrvLoad86: 25690,
                    fc1TRDrvLoadOverOpt: 432,
                    fc1CStayIncCStayPto: 0.65,
                    fc1TAccCc: 7942,
                    fc1TDrvAccConst: 55306,
                    fc1TDrvKd: 254,
                    fc1CDrvCcDDrvCc: 24.389205150409563,
                    fc1DDrvTDrvCc: 85.59052247873633,
                    fc1DEcoroll: 128.87500000000003,
                    fc1DEcorollDDrv: 7.589342237376371,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 22.433583504297925,
                    fc1TDrvLoad86TREng: 24.80424056927132,
                    fc1TDrvAccConstTREng: 53.39911751358971,
                    fc1TRDrvLoadOverOptTREng: 0.4171051742283072,
                    fc1TAvgAccBr: 43591,
                    fc1TAccCcTDrv: 10.31241073051653,
                    sc1TDrvWoCTEcorollTDrv: 6.035208,
                    sc1TDrvAccConstTREng: 10.524619,
                    sc1TDrvLoad86TREng: 7.064941,
                    sc1TRDrvLoadOverOptTREng: 2.59855,
                    sc1TAvgAccBr: 5.889231,
                    rc1TDrvWoCTEcorollTDrv: 4.772636,
                    rc1TDrvAccConstTREng: 5.888681,
                    rc1TDrvLoad86TREng: 2.283468,
                    rc1TRDrvLoadOverOptTREng: 1.749936,
                    rc1TAvgAccBr: 5.82717
                },
                fuelConsumptionReportType1: {
                    fc1CDrvDDrv: 24.96333994651945,
                    fc1TDrvWoCTEcoroll: 9948,
                    fc1TDrvLoad86: 23171,
                    fc1TRDrvLoadOverOpt: 134,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 7254,
                    fc1TDrvAccConst: 38929,
                    fc1TDrvKd: 125,
                    fc1CDrvCcDDrvCc: 24.318113441854806,
                    fc1DDrvTDrvCc: 86.51441405408731,
                    fc1DEcoroll: 81.33500000000001,
                    fc1DEcorollDDrv: 7.015871646683344,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 20.646286034493492,
                    fc1TDrvLoad86TREng: 48.08957516136397,
                    fc1TDrvAccConstTREng: 80.79405599485295,
                    fc1TRDrvLoadOverOptTREng: 0.27810638606977567,
                    fc1TAvgAccBr: 40986,
                    fc1TAccCcTDrv: 15.055102422016065,
                    sc1TDrvWoCTEcorollTDrv: 3.57,
                    sc1TDrvAccConstTREng: 5,
                    sc1TDrvLoad86TREng: 5,
                    sc1TRDrvLoadOverOptTREng: 1,
                    sc1TAvgAccBr: 5,
                    rc1TDrvWoCTEcorollTDrv: 3.223884,
                    rc1TDrvAccConstTREng: 2.909888,
                    rc1TDrvLoad86TREng: 1.227254,
                    rc1TRDrvLoadOverOptTREng: 0.722438,
                    rc1TAvgAccBr: 4.981954,
                    roadType: 1,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 3,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 0.5,
                    pc1TAvgAccBr: 5
                },
                fuelConsumptionReportType2: {
                    fc1CDrvDDrv: 25.30133894685707,
                    fc1TDrvWoCTEcoroll: 5347,
                    fc1TDrvLoad86: 2519,
                    fc1TRDrvLoadOverOpt: 178,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 688,
                    fc1TDrvAccConst: 14565,
                    fc1TDrvKd: 129,
                    fc1CDrvCcDDrvCc: 24.59075895611775,
                    fc1DDrvTDrvCc: 83.08225833472945,
                    fc1DEcoroll: 45.48499999999999,
                    fc1DEcorollDDrv: 9.20665121598235,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 23.91109918611931,
                    fc1TDrvLoad86TREng: 11.234000802747179,
                    fc1TDrvAccConstTREng: 64.95562591981448,
                    fc1TRDrvLoadOverOptTREng: 0.7938277661329884,
                    fc1TAvgAccBr: 38244,
                    fc1TAccCcTDrv: 3.0766478848045793,
                    sc1TDrvWoCTEcorollTDrv: 5,
                    sc1TDrvAccConstTREng: 3.64,
                    sc1TDrvLoad86TREng: 2.58,
                    sc1TRDrvLoadOverOptTREng: 5,
                    sc1TAvgAccBr: 3.88,
                    rc1TDrvWoCTEcorollTDrv: 2.951094,
                    rc1TDrvAccConstTREng: 2.618502,
                    rc1TDrvLoad86TREng: 0.648115,
                    rc1TRDrvLoadOverOptTREng: 3.460878,
                    rc1TAvgAccBr: 3.75174,
                    roadType: 2,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2.5,
                    pc1TDrvLoad86TREng: 0.5,
                    pc1TRDrvLoadOverOptTREng: 3.5,
                    pc1TAvgAccBr: 4
                },
                fuelConsumptionReportType3: {
                    fc1CDrvDDrv: 41.86110348613779,
                    fc1TDrvWoCTEcoroll: 1155,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 111,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 740,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 1.4799999999999998,
                    fc1DEcorollDDrv: 4.062585780949767,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 31.309297912713475,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 18.66801210898083,
                    fc1TRDrvLoadOverOptTREng: 2.800201816347124,
                    fc1TAvgAccBr: 38239,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 2.71,
                    sc1TDrvAccConstTREng: 2.98,
                    sc1TDrvLoad86TREng: 1,
                    sc1TRDrvLoadOverOptTREng: 4.63,
                    sc1TAvgAccBr: 2.1,
                    rc1TDrvWoCTEcorollTDrv: 2.305069,
                    rc1TDrvAccConstTREng: 0.54797,
                    rc1TDrvLoad86TREng: 1,
                    rc1TRDrvLoadOverOptTREng: 1.261054,
                    rc1TAvgAccBr: 2.0739,
                    roadType: 3,
                    pc1TDrvWoCTEcorollTDrv: 2.5,
                    pc1TDrvAccConstTREng: 0.5,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 1.5,
                    pc1TAvgAccBr: 2
                },
                fuelConsumptionReportType4: {
                    fc1CDrvDDrv: 53.421368547418965,
                    fc1TDrvWoCTEcoroll: 827,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 9,
                    fc1CStayIncCStayPto: 0.65,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 1072,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 0.5750000000000001,
                    fc1DEcorollDDrv: 6.902761104441778,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 29.74820143884892,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 3.6964242612323712,
                    fc1TRDrvLoadOverOptTREng: 0.031033412640943416,
                    fc1TAvgAccBr: 56364,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 1,
                    sc1TDrvAccConstTREng: 1,
                    sc1TDrvLoad86TREng: 1.03,
                    sc1TRDrvLoadOverOptTREng: 3.12,
                    sc1TAvgAccBr: 1,
                    rc1TDrvWoCTEcorollTDrv: 0.878293,
                    rc1TDrvAccConstTREng: 0.076029,
                    rc1TDrvLoad86TREng: 1.03,
                    rc1TRDrvLoadOverOptTREng: 2.201004,
                    rc1TAvgAccBr: 1,
                    roadType: 4,
                    pc1TDrvWoCTEcorollTDrv: 1,
                    pc1TDrvAccConstTREng: 0,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 2,
                    pc1TAvgAccBr: 1
                }
            },
            wear: {
                wearGlobal: {
                    fw0CntDrvParkBr: 0,
                    fw0VMaxDrvParkBr: 0
                },
                wearReportSummary: {
                    fw1RMaxRet: 2289,
                    fw1TDrvBrRet20: 5597,
                    fw1DDrvBr: 64.25,
                    fw1DDrvRet20: 80.255,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 38.042406093851675,
                    fw1DDrvBrDDrv: 3.783629398653204,
                    fw1DDrvRet20DDrv: 4.726150620839113,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.8005731730110272,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 129.37951422320765,
                    fw1RMaxDrvLoad: 1751,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.988195,
                    rw1DDrvBrDDrv: 2.861793,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.66195,
                    sw1DDrvBrDDrv: 9.500268,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 646
                },
                wearReportType1: {
                    fw1RMaxRet: 2289,
                    fw1TDrvBrRet20: 3186,
                    fw1DDrvBr: 31.75,
                    fw1DDrvRet20: 55.805,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 20.61588889847322,
                    fw1DDrvBrDDrv: 2.738721642370396,
                    fw1DDrvRet20DDrv: 4.813680669369448,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.5689454349968641,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 105.925989821444,
                    fw1RMaxDrvLoad: 1751,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.375,
                    rw1DDrvBrDDrv: 1.237847,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.15,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 239,
                    roadType: 1,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 1.5,
                    pw1DDrvBrDDrv: 1
                },
                wearReportType2: {
                    fw1RMaxRet: 2147,
                    fw1TDrvBrRet20: 1698,
                    fw1DDrvBr: 23.395,
                    fw1DDrvRet20: 20.955,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 37.44598164134846,
                    fw1DDrvBrDDrv: 4.735398597293768,
                    fw1DDrvRet20DDrv: 4.241516461051118,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 1.1164399904557385,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 129.74526611948306,
                    fw1RMaxDrvLoad: 1681,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.553907,
                    rw1DDrvBrDDrv: 1.99911,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 1,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 185,
                    roadType: 2,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 2
                },
                wearReportType3: {
                    fw1RMaxRet: 1424,
                    fw1TDrvBrRet20: 604,
                    fw1DDrvBr: 7.3500000000000005,
                    fw1DDrvRet20: 2.83,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 274.49903925336264,
                    fw1DDrvBrDDrv: 20.175679385122162,
                    fw1DDrvRet20DDrv: 7.768322810870161,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 2.5971731448763262,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 524.2931649739228,
                    fw1RMaxDrvLoad: 1569,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.958383,
                    rw1DDrvBrDDrv: 0.970943,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 3.23,
                    sw1DDrvBrDDrv: 4.94,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 100,
                    roadType: 3,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 1,
                    pw1DDrvBrDDrv: 1
                },
                wearReportType4: {
                    fw1RMaxRet: 1239,
                    fw1TDrvBrRet20: 109,
                    fw1DDrvBr: 1.7549999999999994,
                    fw1DDrvRet20: 0.665,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 1464.5858343337334,
                    fw1DDrvBrDDrv: 21.068427370948374,
                    fw1DDrvRet20DDrv: 7.983193277310925,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 2.6390977443609014,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 1644.657863145258,
                    fw1RMaxDrvLoad: 1334,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 2.747157,
                    rw1DDrvBrDDrv: 0.436572,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 5,
                    sw1DDrvBrDDrv: 1,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 122,
                    roadType: 4,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 2.5,
                    pw1DDrvBrDDrv: 0.5
                }
            },
            totalRanking: {
                ft0TDrvWoCTEcorollTDrv: 79.07989918021107,
                pt0TDrvWoCTEcorollTDrv: 3.953994959010554,
                ft0TDrvAccConstTREng: 55.95149012944181,
                pt0TDrvAccConstTREng: 2.7975745064720914,
                ft0TDrvLoad86TREng: 32.32112449509688,
                pt0TDrvLoad86TREng: 1.616056224754844,
                ft0TRDrvLoadOverOptTREng: 67.34278925351316,
                pt0TRDrvLoadOverOptTREng: 3.3671394626756577,
                ft0TAvgAccBr: 98.94619146783681,
                pt0TAvgAccBr: 4.94730957339184,
                ft0TDrvBrRet20TDrv: 0,
                pt0TDrvBrRet20TDrv: 0,
                ft0CntBrDDrv: 42.647278409449825,
                pt0CntBrDDrv: 2.1323639204724913,
                ft0DDrvBrDDrv: 30.123285282629404,
                pt0DDrvBrDDrv: 1.5061642641314703,
                zt0TDrvWoCTEcorollTDrv: 2,
                zt0TDrvAccConstTREng: 3,
                zt0TDrvLoad86TREng: 2,
                zt0TRDrvLoadOverOptTREng: 1,
                zt0TAvgAccBr: 2,
                zt0TDrvBrRet20TDrv: -1,
                zt0CntBrDDrv: 1,
                zt0DDrvBrDDrv: 2,
                zt0TStayIdleTREng: 1,
                pt0TStayIdle: 96.07417447748028,
                ft0TStayIdle: 12.418534,
                ft0Ranking: 58.39590249526568
            },
            pgnCounters: {
                parkBrSwitch: {
                    fw0CntDrvParkBr: ['FF'],
                    fw0VMaxDrvParkBr: ['FF'],
                    fw1DDrvParkBr: ['FF'],
                    parkBrSwitch: ['FF']
                },
                ptoState: {
                    fc0CCStayIncCStayPtoDDrv: ['FF'],
                    fc1CStayIncCStayPto: ['FF'],
                    fg0TStayIncTStayPto: ['FF'],
                    fg0TStayIncTStayPtoTREng: ['FF'],
                    ptoState: ['FF']
                }
            }
        }
    },
    {
        overalScore: 73.49371236235054,
        driveScore: 63.054918319436844,
        ecoScore: 61.698299792682135,
        wearTearScore: 95.72791897493263,
        rank: 3,
        driver: {
            id: 1,
            name: '',
            surname: '',
            tachoCard: ''
        },
        pdGroupByDriver: {
            idcar: [356],
            status: [0],
            info: ['the report can be calculated'],
            type: [0],
            vGlobal: {
                fg0TStayIncTStayPtoTREng: -1,
                fg0DateStart: [new Date('2024-01-04T17:50:24')],
                fg0DateEnd: [new Date('2024-01-18T06:59:42')],
                fg0Driver1: ['CZ 00000000007T3004'],
                fg0Driver2: [''],
                fg0TStayIdle: 40658,
                fg0DOdo: 4319.55,
                fg0DDrv: 4308.79,
                fg0TREng: 324665,
                fg0TDrv: 199211,
                fg0AltIncrDDrv: 718.4151467117214,
                fg0CntDpDDrv: 4.270340397188071,
                fg0C: 1199.5,
                fg0TStayIdleTREng: 12.523062233379022,
                fg0TStayIncTStayPto: 2259,
                fg0CDrvDDrv: 26.28696223301668,
                fg0MAvg: 26.122746269834426,
                fg0DiffDOdoDDrv: 0,
                dDrvWeightCatPerc0: 0,
                dDrvWeightCatPerc1: 0.020423,
                dDrvWeightCatPerc2: 5.672822,
                dDrvWeightCatPerc3: 8.276337,
                dDrvWeightCatPerc4: 13.381831,
                dDrvWeightCatPerc5: 69.218853,
                dDrvWeightCatPerc6: 3.403044,
                dDrvWeightCatPerc7: 0,
                dDrvWeightCatPerc8: 0.02669,
                dDrvWeightCatPerc33: 0,
                loadWeightQ: 16000,
                noLoad: 0,
                loadL5: 13.93094116909851,
                loadM5: 85.98539265083696,
                loadNan: 0,
                fc1TStayIdleU2Min: 82537,
                fc1TStayIdleU2MinTREng: 25.422204
            },
            drivingMode: {
                drivingModeReportType1: {
                    fd1TDrv: 134877,
                    fd1TREng: 134877,
                    fd1DDrvTDrv: 86.70740007562442,
                    fd1DDrv: 3248.565,
                    fd1CntDp: 2,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 59885,
                    fd1TDrvCcTREng: 44.399712330493706,
                    fd1AltIncrDDrv: 638.3433916206081,
                    roadType: 1,
                    fd1SpendInDrivingMode: 0.41543437081299184
                },
                drivingModeReportType2: {
                    fd1TDrv: 44159,
                    fd1TREng: 44175,
                    fd1DDrvTDrv: 74.2838832401096,
                    fd1DDrv: 911.195,
                    fd1CntDp: 3,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 11557,
                    fd1TDrvCcTREng: 26.161856253537067,
                    fd1AltIncrDDrv: 984.860540279523,
                    roadType: 2,
                    fd1SpendInDrivingMode: 0.13606332681379268
                },
                drivingModeReportType3: {
                    fd1TDrv: 13366,
                    fd1TREng: 13783,
                    fd1DDrvTDrv: 34.44052072422565,
                    fd1DDrv: 127.87,
                    fd1CntDp: 35,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 4,
                    fd1TDrvCcTREng: 0.029021258071537402,
                    fd1AltIncrDDrv: 768.7495112223352,
                    roadType: 3,
                    fd1SpendInDrivingMode: 0.04245299000508216
                },
                drivingModeReportType4: {
                    fd1TDrv: 6809,
                    fd1TREng: 131830,
                    fd1DDrvTDrv: 11.18754589513879,
                    fd1DDrv: 21.16,
                    fd1CntDp: 144,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 2,
                    fd1TDrvCcTREng: 0.001517105362967458,
                    fd1AltIncrDDrv: 1233.4593572778826,
                    roadType: 4,
                    fd1SpendInDrivingMode: 0.4060493123681333
                },
                drivingModeReportSummary: {
                    fd1TDrv: 199211,
                    fd1TREng: 324665,
                    fd1DDrvTDrv: 77.86539899905131,
                    fd1DDrv: 4308.79,
                    fd1CntDp: 184,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 71448,
                    fd1TDrvCcTREng: 22.00668381254524,
                    fd1AltIncrDDrv: 718.4151467117214
                }
            },
            fuelConsumption: {
                fuelConsumptionGlobal: {
                    fc0CDrv: 1132.65,
                    fc0CStayIdle: 2.0349999999999984,
                    fc0TStayIdleCntDp: 220.9673913043478,
                    fc0CCStayIncCStayPtoDDrv: 27.78738346496345
                },
                fuelConsumptionReportSummary: {
                    fc1CDrvDDrv: 26.28696223301668,
                    fc1TDrvWoCTEcoroll: 48090,
                    fc1TDrvLoad86: 65668,
                    fc1TRDrvLoadOverOpt: 1975,
                    fc1CStayIncCStayPto: 2.2,
                    fc1TAccCc: 9589,
                    fc1TDrvAccConst: 118558,
                    fc1TDrvKd: 1182,
                    fc1CDrvCcDDrvCc: 24.958516060210975,
                    fc1DDrvTDrvCc: 85.0218340611354,
                    fc1DEcoroll: 317.6599999999999,
                    fc1DEcorollDDrv: 7.3723713617976205,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 24.14023322005311,
                    fc1TDrvLoad86TREng: 20.22638719911293,
                    fc1TDrvAccConstTREng: 36.51702524140268,
                    fc1TRDrvLoadOverOptTREng: 0.6083193445551569,
                    fc1TAvgAccBr: 46058,
                    fc1TAccCcTDrv: 4.813489214952989,
                    sc1TDrvWoCTEcorollTDrv: 5.181113,
                    sc1TDrvAccConstTREng: 9.315005,
                    sc1TDrvLoad86TREng: 6.355578,
                    sc1TRDrvLoadOverOptTREng: 2.559182,
                    sc1TAvgAccBr: 5.177498,
                    rc1TDrvWoCTEcorollTDrv: 4.156579,
                    rc1TDrvAccConstTREng: 3.654432,
                    rc1TDrvLoad86TREng: 2.335406,
                    rc1TRDrvLoadOverOptTREng: 1.501138,
                    rc1TAvgAccBr: 5.155144
                },
                fuelConsumptionReportType1: {
                    fc1CDrvDDrv: 24.81865069653833,
                    fc1TDrvWoCTEcoroll: 30541,
                    fc1TDrvLoad86: 62414,
                    fc1TRDrvLoadOverOpt: 724,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 8406,
                    fc1TDrvAccConst: 92988,
                    fc1TDrvKd: 519,
                    fc1CDrvCcDDrvCc: 24.251310667834055,
                    fc1DDrvTDrvCc: 85.48294230608666,
                    fc1DEcoroll: 249.39999999999995,
                    fc1DEcorollDDrv: 7.677235948795855,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 22.643593792863125,
                    fc1TDrvLoad86TREng: 46.27475403515796,
                    fc1TDrvAccConstTREng: 68.94281456438088,
                    fc1TRDrvLoadOverOptTREng: 0.5367853674088243,
                    fc1TAvgAccBr: 46726,
                    fc1TAccCcTDrv: 6.232345025467648,
                    sc1TDrvWoCTEcorollTDrv: 3.57,
                    sc1TDrvAccConstTREng: 5,
                    sc1TDrvLoad86TREng: 5,
                    sc1TRDrvLoadOverOptTREng: 1,
                    sc1TAvgAccBr: 5,
                    rc1TDrvWoCTEcorollTDrv: 3.130522,
                    rc1TDrvAccConstTREng: 2.055189,
                    rc1TDrvLoad86TREng: 1.254564,
                    rc1TRDrvLoadOverOptTREng: 0.687758,
                    rc1TAvgAccBr: 4.992396,
                    roadType: 1,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2,
                    pc1TDrvLoad86TREng: 1.5,
                    pc1TRDrvLoadOverOptTREng: 0.5,
                    pc1TAvgAccBr: 5
                },
                fuelConsumptionReportType2: {
                    fc1CDrvDDrv: 29.53813398888273,
                    fc1TDrvWoCTEcoroll: 11277,
                    fc1TDrvLoad86: 3254,
                    fc1TRDrvLoadOverOpt: 996,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 1183,
                    fc1TDrvAccConst: 20103,
                    fc1TDrvKd: 663,
                    fc1CDrvCcDDrvCc: 28.73833977197777,
                    fc1DDrvTDrvCc: 82.64861123128837,
                    fc1DEcoroll: 60.84000000000001,
                    fc1DEcorollDDrv: 6.6769462080015805,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 25.537263072080435,
                    fc1TDrvLoad86TREng: 7.366157328805886,
                    fc1TDrvAccConstTREng: 45.507640067911716,
                    fc1TRDrvLoadOverOptTREng: 2.2546689303904923,
                    fc1TAvgAccBr: 40692,
                    fc1TAccCcTDrv: 2.678955592291492,
                    sc1TDrvWoCTEcorollTDrv: 5,
                    sc1TDrvAccConstTREng: 3.64,
                    sc1TDrvLoad86TREng: 2.58,
                    sc1TRDrvLoadOverOptTREng: 5,
                    sc1TAvgAccBr: 3.88,
                    rc1TDrvWoCTEcorollTDrv: 2.937028,
                    rc1TDrvAccConstTREng: 2.040991,
                    rc1TDrvLoad86TREng: 0.585576,
                    rc1TRDrvLoadOverOptTREng: 1.727867,
                    rc1TAvgAccBr: 3.811556,
                    roadType: 2,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2,
                    pc1TDrvLoad86TREng: 0.5,
                    pc1TRDrvLoadOverOptTREng: 1.5,
                    pc1TAvgAccBr: 4
                },
                fuelConsumptionReportType3: {
                    fc1CDrvDDrv: 35.46570735903652,
                    fc1TDrvWoCTEcoroll: 4150,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 212,
                    fc1CStayIncCStayPto: 0.05,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 2683,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 55.55555555555556,
                    fc1DDrvTDrvCc: 81,
                    fc1DEcoroll: 5.78,
                    fc1DEcorollDDrv: 4.520215844216783,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 31.048930121203053,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 19.466008851483714,
                    fc1TRDrvLoadOverOptTREng: 1.5381266777914822,
                    fc1TAvgAccBr: 40689,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 2.71,
                    sc1TDrvAccConstTREng: 2.98,
                    sc1TDrvLoad86TREng: 1,
                    sc1TRDrvLoadOverOptTREng: 4.63,
                    sc1TAvgAccBr: 2.1,
                    rc1TDrvWoCTEcorollTDrv: 1.953702,
                    rc1TDrvAccConstTREng: 0.649707,
                    rc1TDrvLoad86TREng: 1,
                    rc1TRDrvLoadOverOptTREng: 1.332661,
                    rc1TAvgAccBr: 2.078477,
                    roadType: 3,
                    pc1TDrvWoCTEcorollTDrv: 2,
                    pc1TDrvAccConstTREng: 0.5,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 1.5,
                    pc1TAvgAccBr: 2
                },
                fuelConsumptionReportType4: {
                    fc1CDrvDDrv: 56.238185255198495,
                    fc1TDrvWoCTEcoroll: 2122,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 43,
                    fc1CStayIncCStayPto: 2.15,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 2784,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 1.64,
                    fc1DEcorollDDrv: 7.750472589792058,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 31.16463504185637,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 2.111810665250702,
                    fc1TRDrvLoadOverOptTREng: 0.032617765303800346,
                    fc1TAvgAccBr: 56126,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 1,
                    sc1TDrvAccConstTREng: 1,
                    sc1TDrvLoad86TREng: 1.03,
                    sc1TRDrvLoadOverOptTREng: 3.12,
                    sc1TAvgAccBr: 1,
                    rc1TDrvWoCTEcorollTDrv: 0.912647,
                    rc1TDrvAccConstTREng: 0.145455,
                    rc1TDrvLoad86TREng: 1.03,
                    rc1TRDrvLoadOverOptTREng: 2.274959,
                    rc1TAvgAccBr: 1,
                    roadType: 4,
                    pc1TDrvWoCTEcorollTDrv: 1,
                    pc1TDrvAccConstTREng: 0,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 2.5,
                    pc1TAvgAccBr: 1
                }
            },
            wear: {
                wearGlobal: {
                    fw0CntDrvParkBr: 0,
                    fw0VMaxDrvParkBr: 0
                },
                wearReportSummary: {
                    fw1RMaxRet: 2263,
                    fw1TDrvBrRet20: 15903,
                    fw1DDrvBr: 184.07499999999996,
                    fw1DDrvRet20: 236.68,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 44.745740683579356,
                    fw1DDrvBrDDrv: 4.272081025067358,
                    fw1DDrvRet20DDrv: 5.492957419600397,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.7777378739225955,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 154.59096405255295,
                    fw1RMaxDrvLoad: 1761,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 2.091651,
                    rw1DDrvBrDDrv: 2.42373,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 5.114907,
                    sw1DDrvBrDDrv: 8.264476,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 1928
                },
                wearReportType1: {
                    fw1RMaxRet: 2263,
                    fw1TDrvBrRet20: 8983,
                    fw1DDrvBr: 90.02500000000002,
                    fw1DDrvRet20: 174.26,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 24.287647007217043,
                    fw1DDrvBrDDrv: 2.771223601805722,
                    fw1DDrvRet20DDrv: 5.364214660934908,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.5166131068518307,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 118.63699818227438,
                    fw1RMaxDrvLoad: 1761,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.256019,
                    rw1DDrvBrDDrv: 1.325135,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.15,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 789,
                    roadType: 1,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 1.5,
                    pw1DDrvBrDDrv: 1.5
                },
                wearReportType2: {
                    fw1RMaxRet: 2166,
                    fw1TDrvBrRet20: 4668,
                    fw1DDrvBr: 65.76,
                    fw1DDrvRet20: 53.085,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 57.39715428640412,
                    fw1DDrvBrDDrv: 7.2168964930667965,
                    fw1DDrvRet20DDrv: 5.8258660330664656,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 1.238768013563154,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 182.39784019885974,
                    fw1RMaxDrvLoad: 1742,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.468922,
                    rw1DDrvBrDDrv: 1.591995,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 1,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 523,
                    roadType: 2,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 1.5
                },
                wearReportType3: {
                    fw1RMaxRet: 2023,
                    fw1TDrvBrRet20: 2061,
                    fw1DDrvBr: 24.54,
                    fw1DDrvRet20: 8.595,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 264.3309611324001,
                    fw1DDrvBrDDrv: 19.19136623132869,
                    fw1DDrvRet20DDrv: 6.721670446547273,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 2.8551483420593367,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 595.9177289434582,
                    fw1RMaxDrvLoad: 1560,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.872604,
                    rw1DDrvBrDDrv: 0.980402,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 3.23,
                    sw1DDrvBrDDrv: 4.94,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 338,
                    roadType: 3,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 1,
                    pw1DDrvBrDDrv: 1
                },
                wearReportType4: {
                    fw1RMaxRet: 1391,
                    fw1TDrvBrRet20: 191,
                    fw1DDrvBr: 3.749999999999999,
                    fw1DDrvRet20: 0.74,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 1313.799621928166,
                    fw1DDrvBrDDrv: 17.72211720226842,
                    fw1DDrvRet20DDrv: 3.4971644612476367,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 5.067567567567566,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 1810.018903591682,
                    fw1RMaxDrvLoad: 1498,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 2.522669,
                    rw1DDrvBrDDrv: 0.444621,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 5,
                    sw1DDrvBrDDrv: 1,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 278,
                    roadType: 4,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 2.5,
                    pw1DDrvBrDDrv: 0.5
                }
            },
            totalRanking: {
                ft0TDrvWoCTEcorollTDrv: 80.22561252657341,
                pt0TDrvWoCTEcorollTDrv: 4.01128062632867,
                ft0TDrvAccConstTREng: 39.23166556390025,
                pt0TDrvAccConstTREng: 1.9615832781950127,
                ft0TDrvLoad86TREng: 36.745772686301585,
                pt0TDrvLoad86TREng: 1.8372886343150794,
                ft0TRDrvLoadOverOptTREng: 58.656959303006566,
                pt0TRDrvLoadOverOptTREng: 2.9328479651503288,
                ft0TAvgAccBr: 99.56825527149444,
                pt0TAvgAccBr: 4.978412763574721,
                ft0TDrvBrRet20TDrv: 0,
                pt0TDrvBrRet20TDrv: 0,
                ft0CntBrDDrv: 40.89323317475533,
                pt0CntBrDDrv: 2.044661658737766,
                ft0DDrvBrDDrv: 29.327085580283352,
                pt0DDrvBrDDrv: 1.466354279014168,
                zt0TDrvWoCTEcorollTDrv: 2,
                zt0TDrvAccConstTREng: 3,
                zt0TDrvLoad86TREng: 2,
                zt0TRDrvLoadOverOptTREng: 1,
                zt0TAvgAccBr: 2,
                zt0TDrvBrRet20TDrv: -1,
                zt0CntBrDDrv: 1,
                zt0DDrvBrDDrv: 2,
                zt0TStayIdleTREng: 1,
                pt0TStayIdle: 96.04406661800388,
                ft0TStayIdle: 12.523062,
                ft0Ranking: 55.02152488684307
            },
            pgnCounters: {
                parkBrSwitch: {
                    fw0CntDrvParkBr: ['FF'],
                    fw0VMaxDrvParkBr: ['FF'],
                    fw1DDrvParkBr: ['FF'],
                    parkBrSwitch: ['FF']
                },
                ptoState: {
                    fc0CCStayIncCStayPtoDDrv: ['FF'],
                    fc1CStayIncCStayPto: ['FF'],
                    fg0TStayIncTStayPto: ['FF'],
                    fg0TStayIncTStayPtoTREng: ['FF'],
                    ptoState: ['FF']
                },
                vehicleWeightGross: {
                    fg0MAvg: ['FF'],
                    vehicleWeightGross: ['FF']
                }
            }
        }
    },
    {
        overalScore: 70.0974137845201,
        driveScore: 56.75616377924671,
        ecoScore: 57.41547169014383,
        wearTearScore: 96.12060588416978,
        rank: 4,
        driver: {
            id: 2,
            name: '',
            surname: '',
            tachoCard: ''
        },
        pdGroupByDriver: {
            idcar: [356],
            status: [0],
            info: ['the report can be calculated'],
            type: [0],
            vGlobal: {
                fg0TStayIncTStayPtoTREng: -1,
                fg0DateStart: [new Date('2023-12-01T22:40:40')],
                fg0DateEnd: [new Date('2023-12-27T15:59:52')],
                fg0Driver1: ['CZ 00000000007T3004'],
                fg0Driver2: [''],
                fg0TStayIdle: 33017,
                fg0DOdo: 6226.685,
                fg0DDrv: 6200.195,
                fg0TREng: 359185,
                fg0TDrv: 284106,
                fg0AltIncrDDrv: 732.9446896428261,
                fg0CntDpDDrv: 5.386927346639907,
                fg0C: 1597.95,
                fg0TStayIdleTREng: 9.192199006083216,
                fg0TStayIncTStayPto: 2627,
                fg0CDrvDDrv: 25.23307734676088,
                fg0MAvg: 22.585928361930563,
                fg0DiffDOdoDDrv: 0,
                dDrvWeightCatPerc0: 0,
                dDrvWeightCatPerc1: 0,
                dDrvWeightCatPerc2: 14.465593,
                dDrvWeightCatPerc3: 26.156919,
                dDrvWeightCatPerc4: 37.909937,
                dDrvWeightCatPerc5: 21.454406,
                dDrvWeightCatPerc6: 0,
                dDrvWeightCatPerc7: 0,
                dDrvWeightCatPerc8: 0.013145,
                dDrvWeightCatPerc33: 0,
                loadWeightQ: 16000,
                noLoad: 0,
                loadL5: 35.36735538156463,
                loadM5: 64.5145031728841,
                loadNan: 0,
                fc1TStayIdleU2Min: 39435,
                fc1TStayIdleU2MinTREng: 10.979022
            },
            drivingMode: {
                drivingModeReportType1: {
                    fd1TDrv: 192741,
                    fd1TREng: 192741,
                    fd1DDrvTDrv: 87.05439942721061,
                    fd1DDrv: 4660.82,
                    fd1CntDp: 2,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 107307,
                    fd1TDrvCcTREng: 55.67419490404221,
                    fd1AltIncrDDrv: 663.6600426534387,
                    roadType: 1,
                    fd1SpendInDrivingMode: 0.5366064841237802
                },
                drivingModeReportType2: {
                    fd1TDrv: 62764,
                    fd1TREng: 62839,
                    fd1DDrvTDrv: 76.96861258046015,
                    fd1DDrv: 1341.905,
                    fd1CntDp: 5,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 16657,
                    fd1TDrvCcTREng: 26.507423733668585,
                    fd1AltIncrDDrv: 949.1730040502121,
                    roadType: 2,
                    fd1SpendInDrivingMode: 0.17494884251847934
                },
                drivingModeReportType3: {
                    fd1TDrv: 17090,
                    fd1TREng: 17809,
                    fd1DDrvTDrv: 34.3042715038034,
                    fd1DDrv: 162.85,
                    fd1CntDp: 57,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 0,
                    fd1TDrvCcTREng: 0,
                    fd1AltIncrDDrv: 852.3180841264966,
                    roadType: 3,
                    fd1SpendInDrivingMode: 0.04958169188579701
                },
                drivingModeReportType4: {
                    fd1TDrv: 11511,
                    fd1TREng: 85796,
                    fd1DDrvTDrv: 10.82720875684128,
                    fd1DDrv: 34.62,
                    fd1CntDp: 270,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 0,
                    fd1TDrvCcTREng: 0,
                    fd1AltIncrDDrv: 1117.8509532062394,
                    roadType: 4,
                    fd1SpendInDrivingMode: 0.23886298147194343
                },
                drivingModeReportSummary: {
                    fd1TDrv: 284106,
                    fd1TREng: 359185,
                    fd1DDrvTDrv: 78.56469768325903,
                    fd1DDrv: 6200.195,
                    fd1CntDp: 334,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 123964,
                    fd1TDrvCcTREng: 34.51257708423236,
                    fd1AltIncrDDrv: 732.9446896428261
                }
            },
            fuelConsumption: {
                fuelConsumptionGlobal: {
                    fc0CDrv: 1564.5,
                    fc0CStayIdle: 1.3400000000000003,
                    fc0TStayIdleCntDp: 98.85329341317366,
                    fc0CCStayIncCStayPtoDDrv: 25.74031945769449
                },
                fuelConsumptionReportSummary: {
                    fc1CDrvDDrv: 25.23307734676088,
                    fc1TDrvWoCTEcoroll: 59913,
                    fc1TDrvLoad86: 120527,
                    fc1TRDrvLoadOverOpt: 1480,
                    fc1CStayIncCStayPto: 2,
                    fc1TAccCc: 12158,
                    fc1TDrvAccConst: 182233,
                    fc1TDrvKd: 380,
                    fc1CDrvCcDDrvCc: 24.22959146067717,
                    fc1DDrvTDrvCc: 86.68006840695683,
                    fc1DEcoroll: 397.4849999999995,
                    fc1DEcorollDDrv: 6.41084675562623,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 21.088255791852337,
                    fc1TDrvLoad86TREng: 33.55568857274107,
                    fc1TDrvAccConstTREng: 50.73513648955274,
                    fc1TRDrvLoadOverOptTREng: 0.4120439327922937,
                    fc1TAvgAccBr: 37538,
                    fc1TAccCcTDrv: 4.279388678873378,
                    sc1TDrvWoCTEcorollTDrv: 6.105861,
                    sc1TDrvAccConstTREng: 11.119388,
                    sc1TDrvLoad86TREng: 7.546024,
                    sc1TRDrvLoadOverOptTREng: 2.386166,
                    sc1TAvgAccBr: 6.187047,
                    rc1TDrvWoCTEcorollTDrv: 4.79603,
                    rc1TDrvAccConstTREng: 5.636344,
                    rc1TDrvLoad86TREng: 1.98987,
                    rc1TRDrvLoadOverOptTREng: 1.305165,
                    rc1TAvgAccBr: 6.146976
                },
                fuelConsumptionReportType1: {
                    fc1CDrvDDrv: 24.431323243549414,
                    fc1TDrvWoCTEcoroll: 35131,
                    fc1TDrvLoad86: 107303,
                    fc1TRDrvLoadOverOpt: 298,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 10338,
                    fc1TDrvAccConst: 144927,
                    fc1TDrvKd: 244,
                    fc1CDrvCcDDrvCc: 24.301177269028916,
                    fc1DDrvTDrvCc: 87.00125807263274,
                    fc1DEcoroll: 304.5899999999999,
                    fc1DEcorollDDrv: 6.535116138361917,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 18.227050809116896,
                    fc1TDrvLoad86TREng: 55.67211958016197,
                    fc1TDrvAccConstTREng: 75.19261599763412,
                    fc1TRDrvLoadOverOptTREng: 0.1546116290773629,
                    fc1TAvgAccBr: 36889,
                    fc1TAccCcTDrv: 5.363674568462341,
                    sc1TDrvWoCTEcorollTDrv: 3.57,
                    sc1TDrvAccConstTREng: 5,
                    sc1TDrvLoad86TREng: 5,
                    sc1TRDrvLoadOverOptTREng: 1,
                    sc1TAvgAccBr: 5,
                    rc1TDrvWoCTEcorollTDrv: 2.98277,
                    rc1TDrvAccConstTREng: 2.55278,
                    rc1TDrvLoad86TREng: 1.02088,
                    rc1TRDrvLoadOverOptTREng: 0.812604,
                    rc1TAvgAccBr: 4.983952,
                    roadType: 1,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2.5,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 1,
                    pc1TAvgAccBr: 5
                },
                fuelConsumptionReportType2: {
                    fc1CDrvDDrv: 26.097227449036996,
                    fc1TDrvWoCTEcoroll: 15259,
                    fc1TDrvLoad86: 13223,
                    fc1TRDrvLoadOverOpt: 633,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 1820,
                    fc1TDrvAccConst: 30606,
                    fc1TDrvKd: 128,
                    fc1CDrvCcDDrvCc: 23.755396050984697,
                    fc1DDrvTDrvCc: 84.61091433031159,
                    fc1DEcoroll: 81.765,
                    fc1DEcorollDDrv: 6.093203319161937,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 24.311707348161367,
                    fc1TDrvLoad86TREng: 21.0426645872786,
                    fc1TDrvAccConstTREng: 48.70542179219911,
                    fc1TRDrvLoadOverOptTREng: 1.007336208405608,
                    fc1TAvgAccBr: 31796,
                    fc1TAccCcTDrv: 2.899751449875725,
                    sc1TDrvWoCTEcorollTDrv: 5,
                    sc1TDrvAccConstTREng: 3.64,
                    sc1TDrvLoad86TREng: 2.58,
                    sc1TRDrvLoadOverOptTREng: 5,
                    sc1TAvgAccBr: 3.88,
                    rc1TDrvWoCTEcorollTDrv: 3.194442,
                    rc1TDrvAccConstTREng: 2.465714,
                    rc1TDrvLoad86TREng: 0.363307,
                    rc1TRDrvLoadOverOptTREng: 2.291849,
                    rc1TAvgAccBr: 3.799958,
                    roadType: 2,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2.5,
                    pc1TDrvLoad86TREng: 0.5,
                    pc1TRDrvLoadOverOptTREng: 2.5,
                    pc1TAvgAccBr: 4
                },
                fuelConsumptionReportType3: {
                    fc1CDrvDDrv: 35.24715996315628,
                    fc1TDrvWoCTEcoroll: 5595,
                    fc1TDrvLoad86: 1,
                    fc1TRDrvLoadOverOpt: 348,
                    fc1CStayIncCStayPto: 0.05,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 3130,
                    fc1TDrvKd: 8,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 8.159999999999998,
                    fc1DEcorollDDrv: 5.0107460853546195,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 32.73844353423054,
                    fc1TDrvLoad86TREng: 0.0056151384131618845,
                    fc1TDrvAccConstTREng: 17.5753832331967,
                    fc1TRDrvLoadOverOptTREng: 1.9540681677803358,
                    fc1TAvgAccBr: 29802,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 2.71,
                    sc1TDrvAccConstTREng: 2.98,
                    sc1TDrvLoad86TREng: 1,
                    sc1TRDrvLoadOverOptTREng: 4.63,
                    sc1TAvgAccBr: 2.1,
                    rc1TDrvWoCTEcorollTDrv: 2.336003,
                    rc1TDrvAccConstTREng: 0.43156,
                    rc1TDrvLoad86TREng: 0.949673,
                    rc1TRDrvLoadOverOptTREng: 0.679783,
                    rc1TAvgAccBr: 2.072173,
                    roadType: 3,
                    pc1TDrvWoCTEcorollTDrv: 2.5,
                    pc1TDrvAccConstTREng: 0.5,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 0.5,
                    pc1TAvgAccBr: 2
                },
                fuelConsumptionReportType4: {
                    fc1CDrvDDrv: 52.57076834199884,
                    fc1TDrvWoCTEcoroll: 3928,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 201,
                    fc1CStayIncCStayPto: 1.95,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 3570,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 2.9699999999999998,
                    fc1DEcorollDDrv: 8.578856152513,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 34.12388150464773,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 4.161033148398527,
                    fc1TRDrvLoadOverOptTREng: 0.23427665625437083,
                    fc1TAvgAccBr: 51645,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 1,
                    sc1TDrvAccConstTREng: 1,
                    sc1TDrvLoad86TREng: 1.03,
                    sc1TRDrvLoadOverOptTREng: 3.12,
                    sc1TAvgAccBr: 1,
                    rc1TDrvWoCTEcorollTDrv: 0.878029,
                    rc1TDrvAccConstTREng: 0.235163,
                    rc1TDrvLoad86TREng: 1.03,
                    rc1TRDrvLoadOverOptTREng: 1.818845,
                    rc1TAvgAccBr: 1,
                    roadType: 4,
                    pc1TDrvWoCTEcorollTDrv: 1,
                    pc1TDrvAccConstTREng: 0,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 2,
                    pc1TAvgAccBr: 1
                }
            },
            wear: {
                wearGlobal: {
                    fw0CntDrvParkBr: 0,
                    fw0VMaxDrvParkBr: 0
                },
                wearReportSummary: {
                    fw1RMaxRet: 2244,
                    fw1TDrvBrRet20: 18546,
                    fw1DDrvBr: 240.53000000000017,
                    fw1DDrvRet20: 253.85,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 44.2889296223748,
                    fw1DDrvBrDDrv: 3.8793941158302316,
                    fw1DDrvRet20DDrv: 4.094226068696229,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.9475280677565499,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 141.9310199114706,
                    fw1RMaxDrvLoad: 2066,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.683225,
                    rw1DDrvBrDDrv: 3.11641,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.770538,
                    sw1DDrvBrDDrv: 9.901854,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 2746
                },
                wearReportType1: {
                    fw1RMaxRet: 2128,
                    fw1TDrvBrRet20: 9572,
                    fw1DDrvBr: 112.69500000000001,
                    fw1DDrvRet20: 172.69,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 22.12057105831163,
                    fw1DDrvBrDDrv: 2.417922168202162,
                    fw1DDrvRet20DDrv: 3.7051420136370847,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.6525855579361863,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 97.92268313301093,
                    fw1RMaxDrvLoad: 1742,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.298115,
                    rw1DDrvBrDDrv: 1.521822,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.15,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 1031,
                    roadType: 1,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 1.5,
                    pw1DDrvBrDDrv: 1.5
                },
                wearReportType2: {
                    fw1RMaxRet: 2244,
                    fw1TDrvBrRet20: 6167,
                    fw1DDrvBr: 92.275,
                    fw1DDrvRet20: 68.035,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 55.74165086202078,
                    fw1DDrvBrDDrv: 6.876418226327497,
                    fw1DDrvRet20DDrv: 5.070031037964685,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 1.356287205115014,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 181.6074908432415,
                    fw1RMaxDrvLoad: 1747,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.455118,
                    rw1DDrvBrDDrv: 1.719011,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 1,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 748,
                    roadType: 2,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 1.5
                },
                wearReportType3: {
                    fw1RMaxRet: 2126,
                    fw1TDrvBrRet20: 2477,
                    fw1DDrvBr: 29.195000000000004,
                    fw1DDrvRet20: 11.89,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 280.62634326066933,
                    fw1DDrvBrDDrv: 17.927540681608843,
                    fw1DDrvRet20DDrv: 7.3011974209395145,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 2.4554247266610596,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 653.9760515812096,
                    fw1RMaxDrvLoad: 1740,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.581681,
                    rw1DDrvBrDDrv: 0.719486,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 3.23,
                    sw1DDrvBrDDrv: 4.94,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 457,
                    roadType: 3,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 0.5
                },
                wearReportType4: {
                    fw1RMaxRet: 1862,
                    fw1TDrvBrRet20: 330,
                    fw1DDrvBr: 6.365000000000001,
                    fw1DDrvRet20: 1.235,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 1473.13691507799,
                    fw1DDrvBrDDrv: 18.385326400924328,
                    fw1DDrvRet20DDrv: 3.5673021374927796,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 5.153846153846155,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 2120.161756210284,
                    fw1RMaxDrvLoad: 2066,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 2.178379,
                    rw1DDrvBrDDrv: 0.498078,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 5,
                    sw1DDrvBrDDrv: 1,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 510,
                    roadType: 4,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 2,
                    pw1DDrvBrDDrv: 0.5
                }
            },
            totalRanking: {
                ft0TDrvWoCTEcorollTDrv: 78.54796103675959,
                pt0TDrvWoCTEcorollTDrv: 3.927398051837979,
                ft0TDrvAccConstTREng: 50.689337884563926,
                pt0TDrvAccConstTREng: 2.534466894228196,
                ft0TDrvLoad86TREng: 26.36977888184458,
                pt0TDrvLoad86TREng: 1.318488944092229,
                ft0TRDrvLoadOverOptTREng: 54.69714093625596,
                pt0TRDrvLoadOverOptTREng: 2.734857046812798,
                ft0TAvgAccBr: 99.35234559927451,
                pt0TAvgAccBr: 4.967617279963726,
                ft0TDrvBrRet20TDrv: 0,
                pt0TDrvBrRet20TDrv: 0,
                ft0CntBrDDrv: 35.28375418107061,
                pt0CntBrDDrv: 1.7641877090535307,
                ft0DDrvBrDDrv: 31.472994685800852,
                pt0DDrvBrDDrv: 1.5736497342900426,
                zt0TDrvWoCTEcorollTDrv: 2,
                zt0TDrvAccConstTREng: 3,
                zt0TDrvLoad86TREng: 2,
                zt0TRDrvLoadOverOptTREng: 1,
                zt0TAvgAccBr: 2,
                zt0TDrvBrRet20TDrv: -1,
                zt0CntBrDDrv: 1,
                zt0DDrvBrDDrv: 2,
                zt0TStayIdleTREng: 1,
                pt0TStayIdle: 97.01306053443109,
                ft0TStayIdle: 9.192199,
                ft0Ranking: 55.03618017365304
            },
            pgnCounters: {
                parkBrSwitch: {
                    fw0CntDrvParkBr: ['FF'],
                    fw0VMaxDrvParkBr: ['FF'],
                    fw1DDrvParkBr: ['FF'],
                    parkBrSwitch: ['FF']
                },
                ptoState: {
                    fc0CCStayIncCStayPtoDDrv: ['FF'],
                    fc1CStayIncCStayPto: ['FF'],
                    fg0TStayIncTStayPto: ['FF'],
                    fg0TStayIncTStayPtoTREng: ['FF'],
                    ptoState: ['FF']
                },
                vehicleWeightGross: {
                    fg0MAvg: ['FF'],
                    vehicleWeightGross: ['FF']
                }
            }
        }
    },
    {
        overalScore: 76.98392400234367,
        driveScore: 68.82206430004966,
        ecoScore: 65.4481303208725,
        wearTearScore: 96.68157738610888,
        rank: 2,
        driver: {
            id: 3,
            name: '',
            surname: '',
            tachoCard: ''
        },
        pdGroupByDriver: {
            idcar: [356],
            status: [0],
            info: ['the report can be calculated'],
            type: [0],
            vGlobal: {
                fg0TStayIncTStayPtoTREng: -1,
                fg0DateStart: [new Date('2023-11-21T11:07:56')],
                fg0DateEnd: [new Date('2023-11-29T07:41:00')],
                fg0Driver1: ['CZ 00000000007T3004'],
                fg0Driver2: [''],
                fg0TStayIdle: 30118,
                fg0DOdo: 3825.66,
                fg0DDrv: 3809.34,
                fg0TREng: 237940,
                fg0TDrv: 180539,
                fg0AltIncrDDrv: 743.2783631810235,
                fg0CntDpDDrv: 5.329007124593762,
                fg0C: 1033.3,
                fg0TStayIdleTREng: 12.657812894006893,
                fg0TStayIncTStayPto: 1542,
                fg0CDrvDDrv: 26.362834506764955,
                fg0MAvg: 25.093220518515025,
                fg0DiffDOdoDDrv: 0,
                dDrvWeightCatPerc0: 0.35728,
                dDrvWeightCatPerc1: 0,
                dDrvWeightCatPerc2: 1.269117,
                dDrvWeightCatPerc3: 1.20979,
                dDrvWeightCatPerc4: 52.443599,
                dDrvWeightCatPerc5: 44.696457,
                dDrvWeightCatPerc6: 0,
                dDrvWeightCatPerc7: 0,
                dDrvWeightCatPerc8: 0.023757,
                dDrvWeightCatPerc33: 0,
                loadWeightQ: 16000,
                noLoad: 0.3572797387473946,
                loadL5: 2.324549659521072,
                loadM5: 97.0992350380906,
                loadNan: 0,
                fc1TStayIdleU2Min: 25741,
                fc1TStayIdleU2MinTREng: 10.818274
            },
            drivingMode: {
                drivingModeReportType1: {
                    fd1TDrv: 97310,
                    fd1TREng: 97310,
                    fd1DDrvTDrv: 84.71671976158666,
                    fd1DDrv: 2289.94,
                    fd1CntDp: 1,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 53830,
                    fd1TDrvCcTREng: 55.318055698283835,
                    fd1AltIncrDDrv: 603.4219237185255,
                    roadType: 1,
                    fd1SpendInDrivingMode: 0.40896864755820794
                },
                drivingModeReportType2: {
                    fd1TDrv: 63589,
                    fd1TREng: 63601,
                    fd1DDrvTDrv: 78.08284451713345,
                    fd1DDrv: 1379.225,
                    fd1CntDp: 1,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 28754,
                    fd1TDrvCcTREng: 45.2099809751419,
                    fd1AltIncrDDrv: 955.9716507458901,
                    roadType: 2,
                    fd1SpendInDrivingMode: 0.26729847860805245
                },
                drivingModeReportType3: {
                    fd1TDrv: 12610,
                    fd1TREng: 13334,
                    fd1DDrvTDrv: 33.48770816812053,
                    fd1DDrv: 117.3,
                    fd1CntDp: 55,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 0,
                    fd1TDrvCcTREng: 0,
                    fd1AltIncrDDrv: 858.4825234441605,
                    roadType: 3,
                    fd1SpendInDrivingMode: 0.056039337648146594
                },
                drivingModeReportType4: {
                    fd1TDrv: 7030,
                    fd1TREng: 63695,
                    fd1DDrvTDrv: 11.714082503556188,
                    fd1DDrv: 22.875,
                    fd1CntDp: 146,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 0,
                    fd1TDrvCcTREng: 0,
                    fd1AltIncrDDrv: 1328.9617486338798,
                    roadType: 4,
                    fd1SpendInDrivingMode: 0.267693536185593
                },
                drivingModeReportSummary: {
                    fd1TDrv: 180539,
                    fd1TREng: 237940,
                    fd1DDrvTDrv: 75.95934396446197,
                    fd1DDrv: 3809.34,
                    fd1CntDp: 203,
                    fd1DDrvRet20DDrvBrDDrvParkBr: 0,
                    fd1TDrvCc: 82584,
                    fd1TDrvCcTREng: 34.707909557031186,
                    fd1AltIncrDDrv: 743.2783631810236
                }
            },
            fuelConsumption: {
                fuelConsumptionGlobal: {
                    fc0CDrv: 1004.25,
                    fc0CStayIdle: 1.61,
                    fc0TStayIdleCntDp: 148.36453201970443,
                    fc0CCStayIncCStayPtoDDrv: 27.096557408894995
                },
                fuelConsumptionReportSummary: {
                    fc1CDrvDDrv: 26.362834506764955,
                    fc1TDrvWoCTEcoroll: 43526,
                    fc1TDrvLoad86: 30599,
                    fc1TRDrvLoadOverOpt: 1273,
                    fc1CStayIncCStayPto: 1.1,
                    fc1TAccCc: 6020,
                    fc1TDrvAccConst: 114986,
                    fc1TDrvKd: 648,
                    fc1CDrvCcDDrvCc: 24.760389697612982,
                    fc1DDrvTDrvCc: 82.75479511769834,
                    fc1DEcoroll: 286.82000000000005,
                    fc1DEcorollDDrv: 7.529388292985138,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 24.108918294662097,
                    fc1TDrvLoad86TREng: 12.859964696982432,
                    fc1TDrvAccConstTREng: 48.32562830965791,
                    fc1TRDrvLoadOverOptTREng: 0.5350088257543919,
                    fc1TAvgAccBr: 43546,
                    fc1TAccCcTDrv: 3.334459590448601,
                    sc1TDrvWoCTEcorollTDrv: 6.207016,
                    sc1TDrvAccConstTREng: 10.357501,
                    sc1TDrvLoad86TREng: 6.745721,
                    sc1TRDrvLoadOverOptTREng: 2.840127,
                    sc1TAvgAccBr: 5.790454,
                    rc1TDrvWoCTEcorollTDrv: 4.314691,
                    rc1TDrvAccConstTREng: 5.638335,
                    rc1TDrvLoad86TREng: 3.202437,
                    rc1TRDrvLoadOverOptTREng: 1.358772,
                    rc1TAvgAccBr: 5.730753
                },
                fuelConsumptionReportType1: {
                    fc1CDrvDDrv: 24.033380787269536,
                    fc1TDrvWoCTEcoroll: 23224,
                    fc1TDrvLoad86: 24484,
                    fc1TRDrvLoadOverOpt: 254,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 4684,
                    fc1TDrvAccConst: 71252,
                    fc1TDrvKd: 208,
                    fc1CDrvCcDDrvCc: 23.03413492283746,
                    fc1DDrvTDrvCc: 83.13873304848596,
                    fc1DEcoroll: 174.42999999999998,
                    fc1DEcorollDDrv: 7.617230145768011,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 23.86599527283938,
                    fc1TDrvLoad86TREng: 25.16082622546501,
                    fc1TDrvAccConstTREng: 73.22166272736615,
                    fc1TRDrvLoadOverOptTREng: 0.2610214777515158,
                    fc1TAvgAccBr: 48852,
                    fc1TAccCcTDrv: 4.813482684205118,
                    sc1TDrvWoCTEcorollTDrv: 3.57,
                    sc1TDrvAccConstTREng: 5,
                    sc1TDrvLoad86TREng: 5,
                    sc1TRDrvLoadOverOptTREng: 1,
                    sc1TAvgAccBr: 5,
                    rc1TDrvWoCTEcorollTDrv: 3.206978,
                    rc1TDrvAccConstTREng: 2.557323,
                    rc1TDrvLoad86TREng: 2.032116,
                    rc1TRDrvLoadOverOptTREng: 0.711679,
                    rc1TAvgAccBr: 4.984644,
                    roadType: 1,
                    pc1TDrvWoCTEcorollTDrv: 3,
                    pc1TDrvAccConstTREng: 2.5,
                    pc1TDrvLoad86TREng: 2,
                    pc1TRDrvLoadOverOptTREng: 0.5,
                    pc1TAvgAccBr: 5
                },
                fuelConsumptionReportType2: {
                    fc1CDrvDDrv: 28.824158494806866,
                    fc1TDrvWoCTEcoroll: 14027,
                    fc1TDrvLoad86: 6115,
                    fc1TRDrvLoadOverOpt: 730,
                    fc1CStayIncCStayPto: 0,
                    fc1TAccCc: 1336,
                    fc1TDrvAccConst: 39396,
                    fc1TDrvKd: 440,
                    fc1CDrvCcDDrvCc: 28.03552896648556,
                    fc1DDrvTDrvCc: 82.03602976977116,
                    fc1DEcoroll: 103.83000000000003,
                    fc1DEcorollDDrv: 7.5281408037122315,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 22.05884665586815,
                    fc1TDrvLoad86TREng: 9.614628700806591,
                    fc1TDrvAccConstTREng: 61.94242228895772,
                    fc1TRDrvLoadOverOptTREng: 1.1477806952720868,
                    fc1TAvgAccBr: 36564,
                    fc1TAccCcTDrv: 2.1009923099907217,
                    sc1TDrvWoCTEcorollTDrv: 5,
                    sc1TDrvAccConstTREng: 3.64,
                    sc1TDrvLoad86TREng: 2.58,
                    sc1TRDrvLoadOverOptTREng: 5,
                    sc1TAvgAccBr: 3.88,
                    rc1TDrvWoCTEcorollTDrv: 2.248813,
                    rc1TDrvAccConstTREng: 2.808318,
                    rc1TDrvLoad86TREng: 1.09547,
                    rc1TRDrvLoadOverOptTREng: 2.264377,
                    rc1TAvgAccBr: 3.774808,
                    roadType: 2,
                    pc1TDrvWoCTEcorollTDrv: 2,
                    pc1TDrvAccConstTREng: 3,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 2.5,
                    pc1TAvgAccBr: 4
                },
                fuelConsumptionReportType3: {
                    fc1CDrvDDrv: 37.12702472293267,
                    fc1TDrvWoCTEcoroll: 4079,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 242,
                    fc1CStayIncCStayPto: 0.1,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 2213,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 6.720000000000001,
                    fc1DEcorollDDrv: 5.728900255754478,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 32.34734337827121,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 16.596670166491677,
                    fc1TRDrvLoadOverOptTREng: 1.8149092545372731,
                    fc1TAvgAccBr: 35299,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 2.71,
                    sc1TDrvAccConstTREng: 2.98,
                    sc1TDrvLoad86TREng: 1,
                    sc1TRDrvLoadOverOptTREng: 4.63,
                    sc1TAvgAccBr: 2.1,
                    rc1TDrvWoCTEcorollTDrv: 1.957802,
                    rc1TDrvAccConstTREng: 0.431319,
                    rc1TDrvLoad86TREng: 1,
                    rc1TRDrvLoadOverOptTREng: 1.78106,
                    rc1TAvgAccBr: 2.075889,
                    roadType: 3,
                    pc1TDrvWoCTEcorollTDrv: 2,
                    pc1TDrvAccConstTREng: 0.5,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 2,
                    pc1TAvgAccBr: 2
                },
                fuelConsumptionReportType4: {
                    fc1CDrvDDrv: 55.95628415300546,
                    fc1TDrvWoCTEcoroll: 2196,
                    fc1TDrvLoad86: 0,
                    fc1TRDrvLoadOverOpt: 47,
                    fc1CStayIncCStayPto: 1,
                    fc1TAccCc: 0,
                    fc1TDrvAccConst: 2125,
                    fc1TDrvKd: 0,
                    fc1CDrvCcDDrvCc: 0,
                    fc1DDrvTDrvCc: 0,
                    fc1DEcoroll: 1.84,
                    fc1DEcorollDDrv: 8.043715846994536,
                    fc1RAvgLoad: 0,
                    fc1TDrvWoCTEcorollTDrv: 31.2375533428165,
                    fc1TDrvLoad86TREng: 0,
                    fc1TDrvAccConstTREng: 3.336211633566214,
                    fc1TRDrvLoadOverOptTREng: 0.07378915142475861,
                    fc1TAvgAccBr: 53570,
                    fc1TAccCcTDrv: 0,
                    sc1TDrvWoCTEcorollTDrv: 1,
                    sc1TDrvAccConstTREng: 1,
                    sc1TDrvLoad86TREng: 1.03,
                    sc1TRDrvLoadOverOptTREng: 3.12,
                    sc1TAvgAccBr: 1,
                    rc1TDrvWoCTEcorollTDrv: 0.796507,
                    rc1TDrvAccConstTREng: 0.219467,
                    rc1TDrvLoad86TREng: 1.03,
                    rc1TRDrvLoadOverOptTREng: 1.354697,
                    rc1TAvgAccBr: 1,
                    roadType: 4,
                    pc1TDrvWoCTEcorollTDrv: 1,
                    pc1TDrvAccConstTREng: 0,
                    pc1TDrvLoad86TREng: 1,
                    pc1TRDrvLoadOverOptTREng: 1.5,
                    pc1TAvgAccBr: 1
                }
            },
            wear: {
                wearGlobal: {
                    fw0CntDrvParkBr: 0,
                    fw0VMaxDrvParkBr: 0
                },
                wearReportSummary: {
                    fw1RMaxRet: 2239,
                    fw1TDrvBrRet20: 13317,
                    fw1DDrvBr: 126.41000000000003,
                    fw1DDrvRet20: 209.095,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 40.978227199462374,
                    fw1DDrvBrDDrv: 3.3184226138911215,
                    fw1DDrvRet20DDrv: 5.489008594664691,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.6045577369138433,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 157.11383074233333,
                    fw1RMaxDrvLoad: 1790,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 2.331527,
                    rw1DDrvBrDDrv: 4.431495,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.424671,
                    sw1DDrvBrDDrv: 9.618366,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 1561
                },
                wearReportType1: {
                    fw1RMaxRet: 2239,
                    fw1TDrvBrRet20: 6581,
                    fw1DDrvBr: 43.275,
                    fw1DDrvRet20: 136.37,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 17.511375843908578,
                    fw1DDrvBrDDrv: 1.8897875053494853,
                    fw1DDrvRet20DDrv: 5.955177864922226,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.3173351910244188,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 126.64087268662063,
                    fw1RMaxDrvLoad: 1765,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 1.877289,
                    rw1DDrvBrDDrv: 2.224259,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 4.15,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 401,
                    roadType: 1,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 2,
                    pw1DDrvBrDDrv: 2
                },
                wearReportType2: {
                    fw1RMaxRet: 2223,
                    fw1TDrvBrRet20: 4508,
                    fw1DDrvBr: 56.05500000000001,
                    fw1DDrvRet20: 61.215,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 37.1222969421233,
                    fw1DDrvBrDDrv: 4.064238974786567,
                    fw1DDrvRet20DDrv: 4.438362123656403,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 0.9157069345748591,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 139.6436404502529,
                    fw1RMaxDrvLoad: 1790,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.651098,
                    rw1DDrvBrDDrv: 2.632425,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 1,
                    sw1DDrvBrDDrv: 5,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 512,
                    roadType: 2,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 2.5
                },
                wearReportType3: {
                    fw1RMaxRet: 1611,
                    fw1TDrvBrRet20: 1994,
                    fw1DDrvBr: 23.205000000000002,
                    fw1DDrvRet20: 10.62,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 335.0383631713556,
                    fw1DDrvBrDDrv: 19.782608695652183,
                    fw1DDrvRet20DDrv: 9.053708439897697,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 2.1850282485875714,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 637.68115942029,
                    fw1RMaxDrvLoad: 1553,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 0.684179,
                    rw1DDrvBrDDrv: 1.189185,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 3.23,
                    sw1DDrvBrDDrv: 4.94,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 393,
                    roadType: 3,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 0.5,
                    pw1DDrvBrDDrv: 1
                },
                wearReportType4: {
                    fw1RMaxRet: 1438,
                    fw1TDrvBrRet20: 234,
                    fw1DDrvBr: 3.8749999999999996,
                    fw1DDrvRet20: 0.89,
                    fw1DDrvParkBr: 0,
                    fw1CntBrDDrv: 1114.7540983606557,
                    fw1DDrvBrDDrv: 16.939890710382514,
                    fw1DDrvRet20DDrv: 3.890710382513661,
                    fw1TDrvBrRet20TDrv: 0,
                    fw1DDrvBrDDrvRet20: 4.353932584269662,
                    fw1CntRetExhCntRetLnCntRetEngDDrv: 1796.7213114754102,
                    fw1RMaxDrvLoad: 1433,
                    rw1TDrvBrRet20TDrv: 0,
                    rw1CntBrDDrv: 3.196628,
                    rw1DDrvBrDDrv: 0.481286,
                    sw1TDrvBrRet20TDrv: 0,
                    sw1CntBrDDrv: 5,
                    sw1DDrvBrDDrv: 1,
                    fw1CntRetExh: 0,
                    fw1CntRetLn: 0,
                    fw1CntRetEng: 0,
                    fw1CntBr: 255,
                    roadType: 4,
                    pw1TDrvBrRet20TDrv: 0,
                    pw1CntBrDDrv: 3,
                    pw1DDrvBrDDrv: 0.5
                }
            },
            totalRanking: {
                ft0TDrvWoCTEcorollTDrv: 69.5131303593199,
                pt0TDrvWoCTEcorollTDrv: 3.4756565179659953,
                ft0TDrvAccConstTREng: 54.43721611968931,
                pt0TDrvAccConstTREng: 2.721860805984466,
                ft0TDrvLoad86TREng: 47.473599671575855,
                pt0TDrvLoad86TREng: 2.3736799835787923,
                ft0TRDrvLoadOverOptTREng: 47.8419508739519,
                pt0TRDrvLoadOverOptTREng: 2.392097543697595,
                ft0TAvgAccBr: 98.96898431451679,
                pt0TAvgAccBr: 4.948449215725839,
                ft0TDrvBrRet20TDrv: 0,
                pt0TDrvBrRet20TDrv: 0,
                ft0CntBrDDrv: 52.69378188277205,
                pt0CntBrDDrv: 2.6346890941386025,
                ft0DDrvBrDDrv: 46.07326849714962,
                pt0DDrvBrDDrv: 2.303663424857481,
                zt0TDrvWoCTEcorollTDrv: 2,
                zt0TDrvAccConstTREng: 3,
                zt0TDrvLoad86TREng: 2,
                zt0TRDrvLoadOverOptTREng: 1,
                zt0TAvgAccBr: 2,
                zt0TDrvBrRet20TDrv: -1,
                zt0CntBrDDrv: 1,
                zt0DDrvBrDDrv: 2,
                zt0TStayIdleTREng: 1,
                pt0TStayIdle: 95.98817558917445,
                ft0TStayIdle: 12.657813,
                ft0Ranking: 62.04134698335585
            },
            pgnCounters: {
                parkBrSwitch: {
                    fw0CntDrvParkBr: ['FF'],
                    fw0VMaxDrvParkBr: ['FF'],
                    fw1DDrvParkBr: ['FF'],
                    parkBrSwitch: ['FF']
                },
                ptoState: {
                    fc0CCStayIncCStayPtoDDrv: ['FF'],
                    fc1CStayIncCStayPto: ['FF'],
                    fg0TStayIncTStayPto: ['FF'],
                    fg0TStayIncTStayPtoTREng: ['FF'],
                    ptoState: ['FF']
                },
                vehicleWeightGross: {
                    fg0MAvg: ['FF'],
                    vehicleWeightGross: ['FF']
                }
            }
        }
    }
];

const reportList = [
    [0, 1, 2, 3],
    [1, 2, 3, 0],
    [2, 3, 0, 1],
    [3, 0, 1, 2]
];

export const perfectDriveContextualData = [
    {
        crossSystemBestDriverOveralScore: 67.50322581123056,
        crossSystemAvgDriverOveralScore: 72.50322581123056,
        clientAvgDriverOveralScore: 73.50322581123056,
        month: moment().add(-1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT)
    },
    {
        crossSystemBestDriverOveralScore: 66.0322750032589,
        crossSystemAvgDriverOveralScore: 71.2222750032589,
        clientAvgDriverOveralScore: 75.0322750032589,
        month: moment().format(DRIVER_BEHAVIOR_DATE_FORMAT)
    }
];

export const perfectDriveScoreData = reportList.map((rl, index) => {
    return {
        month: moment()
            .add(index * -1, 'months')
            .format(DRIVER_BEHAVIOR_DATE_FORMAT),
        score: perfectDriverData.map((data, dataIndex) => {
            return {
                overalScore: data.overalScore,
                driveScore: data.driveScore,
                ecoScore: data.ecoScore,
                wearTearScore: data.wearTearScore,
                rank: data.rank,
                driver: {
                    id: users[rl[dataIndex]].id,
                    name: users[rl[dataIndex]].name,
                    surname: users[rl[dataIndex]].surname,
                    tachoCard: users[rl[dataIndex]].userTokens?.find(
                        t => t.tokenType === UserTokenTokenTypeEnum.DriverCard
                    )?.token
                }
            };
        })
    } as PerfectDriveScorePeriod;
});

export const perfectDriveReportData = reportList.map((rl, index) => {
    return {
        month: moment()
            .add(index * -1, 'months')
            .format(DRIVER_BEHAVIOR_DATE_FORMAT),
        driverData: perfectDriverData.map((data, dataIndex) => {
            return {
                ...data,
                driver: {
                    id: users[rl[dataIndex]].id,
                    name: users[rl[dataIndex]].name,
                    surname: users[rl[dataIndex]].surname,
                    tachoCard: data.driver.tachoCard
                },
                pdGroupByDriver: {
                    ...data.pdGroupByDriver,
                    idcar: [vehicles[rl[dataIndex]].id]
                }
            };
        }),
        numberOfDrivers: perfectDriverData.length
    } as PerfectDrivePeriod;
});
