import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import moment from 'moment';
import numeral from 'numeral';

import 'moment/locale/en-gb';
import 'moment/locale/bg';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/el';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/mt';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/sl';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/uk';

import 'numeral/locales/bg';
import 'numeral/locales/cs';
import 'numeral/locales/da-dk';
import 'numeral/locales/de';
import 'numeral/locales/es';
import 'numeral/locales/et';
import 'numeral/locales/fi';
import 'numeral/locales/fr';
import 'numeral/locales/hu';
import 'numeral/locales/it';
import 'numeral/locales/lv';
import 'numeral/locales/nl-nl';
import 'numeral/locales/pl';
import 'numeral/locales/pt-br';
import 'numeral/locales/ru';
import 'numeral/locales/sk';
import 'numeral/locales/sl';
import 'numeral/locales/tr';
import 'numeral/locales/en-gb';

import bg from 'locales/bg.json';
import cs from 'locales/cs.json';
import da from 'locales/da.json';
import de from 'locales/de.json';
import el from 'locales/el.json';
import en from 'locales/en.json';
import es from 'locales/es.json';
import et from 'locales/et.json';
import fi from 'locales/fi.json';
import fr from 'locales/fr.json';
import ga from 'locales/ga.json';
import hr from 'locales/hr.json';
import hu from 'locales/hu.json';
import it from 'locales/it.json';
import lt from 'locales/lt.json';
import lv from 'locales/lv.json';
import mt from 'locales/mt.json';
import nl from 'locales/nl.json';
import pl from 'locales/pl.json';
import pt from 'locales/pt.json';
import ro from 'locales/ro.json';
import ru from 'locales/ru.json';
import sk from 'locales/sk.json';
import sl from 'locales/sl.json';
import sv from 'locales/sv.json';
import tr from 'locales/tr.json';
import uk from 'locales/uk.json';

export const defaultNS = 'ns';

function i18nInit(lang: string) {
    momentNumeralLangChange(lang);

    i18next
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            lng: lang,
            fallbackLng: 'en',
            debug: true,
            resources: {
                bg: { ns: bg },
                cs: { ns: cs },
                da: { ns: da },
                de: { ns: de },
                el: { ns: el },
                en: { ns: en },
                es: { ns: es },
                et: { ns: et },
                fi: { ns: fi },
                fr: { ns: fr },
                ga: { ns: ga },
                hr: { ns: hr },
                hu: { ns: hu },
                it: { ns: it },
                lt: { ns: lt },
                lv: { ns: lv },
                mt: { ns: mt },
                nl: { ns: nl },
                pl: { ns: pl },
                pt: { ns: pt },
                ro: { ns: ro },
                ru: { ns: ru },
                sk: { ns: sk },
                sl: { ns: sl },
                sv: { ns: sv },
                tr: { ns: tr },
                uk: { ns: uk }
            },
            defaultNS
        });

    i18next.on('languageChanged', (lang: string) => {
        console.log('languageChanged,', lang);
        // i18n.reloadResources([lang]);
        momentNumeralLangChange(lang);
    });

    // i18next.on('missingKey', (lang: string, _namespace, _key, _res) => {
    //     i18n.reloadResources([lang]);
    // });

    // i18next.on("initialized", (options: any) => {
    //     console.info("i18n initialized", options);
    // });

    // i18next.on("failedLoading", (lang: Lang, ns: any, msg: string) => {
    //     console.error("Failed to load i18n resources", lang, ns, msg);
    // });

    // i18next.on("onMissingKey", (lang: Lang, ns: any, key: string, res: any) => {
    //     console.error("Failed to load i18n resources", lang, ns, key, res);
    // });

    return i18next;
}

const momentNumeralLangChange = (lang: string): void => {
    switch (lang) {
        case 'el':
            moment.locale('el');
            numeral.locale('en');
            break;
        case 'da':
            moment.locale(lang);
            numeral.locale('da-dk');
            break;
        case 'ga':
            moment.locale('en-gb');
            numeral.locale('en-gb');
            break;
        case 'hr':
            moment.locale('hr');
            numeral.locale('en');
            break;
        case 'lt':
            moment.locale('lt');
            numeral.locale('en');
            break;
        case 'mt':
            moment.locale('mt');
            numeral.locale('en');
            break;
        case 'nl':
            moment.locale('nl');
            numeral.locale('nl-nl');
            break;
        case 'ro':
            moment.locale('ro');
            numeral.locale('en');
            break;
        case 'sv':
            moment.locale('sv');
            numeral.locale('en');
            break;
        case 'uk':
            moment.locale('uk');
            numeral.locale('ru');
            break;
        case 'pt':
            moment.locale(lang);
            numeral.locale('pt-br');
            break;
        case 'en':
            moment.locale('en-gb');
            numeral.locale('en-gb');
            break;
        default:
            moment.locale(lang);
            numeral.locale(lang);
    }
};

export default i18nInit;
