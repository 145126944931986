/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BundlesObePermissions
 */
export interface BundlesObePermissions {
    /**
     * In format `TLM{N}:{permissions}; TLM{M}: {permissions}`, as seen at https://confluence.eurowag.com/pages/viewpage.action?pageId=25657907
     * @type {string}
     * @memberof BundlesObePermissions
     */
    permissions: string;
    /**
     * 
     * @type {string}
     * @memberof BundlesObePermissions
     */
    obeId: string;
}

export function BundlesObePermissionsFromJSON(json: any): BundlesObePermissions {
    return BundlesObePermissionsFromJSONTyped(json, false);
}

export function BundlesObePermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BundlesObePermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
        'obeId': json['obeId'],
    };
}

export function BundlesObePermissionsToJSON(value?: BundlesObePermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
        'obeId': value.obeId,
    };
}


