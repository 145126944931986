import { useTranslation } from 'react-i18next';

import { FleetModel, FleetType } from '../../FleetModule';
import { Modal } from 'common/components';
import TrailerForm, { TrailerFormModel } from 'common/forms/TrailerForm';
import { Role } from 'logic/auth';

interface Props {
    isOpen: boolean;
    roles: Role[];
    onCancelButtonClick?: () => void;
    onSubmitButtonClick?: (model: FleetModel) => void;
}

export default function FleetCreateTrailer(props: Props) {
    const { t } = useTranslation();

    function onSubmit(value: TrailerFormModel) {
        props.onSubmitButtonClick?.({
            name: value.name!,
            registrationNumber: value.registrationNumber!,
            manufacturer: value.manufacturer!,
            cargoType: value.cargoType,
            trailerType: value.trailerType,
            height: value.height,
            length: value.length,
            width: value.width,
            weight: value.weight,
            weightFull: value.weightFull,
            fuelCards: [],
            id: 0,
            note: '',
            tankSize: 0,
            toll: [],
            type: FleetType.TRAILER
        });
    }
    return (
        <Modal
            destroyOnClose
            size="super-wide"
            title={`${t('common.new')} ${t('common.trailer')}`}
            closable={false}
            onCancel={props.onCancelButtonClick}
            footer={null}
            visible={props.isOpen}
        >
            <TrailerForm mode="CREATE" roles={props.roles} onCancel={props.onCancelButtonClick} onSubmit={onSubmit} />
        </Modal>
    );
}
