/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DefaultDriverInfo,
    DefaultDriverInfoFromJSON,
    DefaultDriverInfoToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetInfoV1DefaultdriverGetRequest {
    driverIds?: Array<number>;
    monitoredObjectIds?: Array<string>;
}

export interface SetDefaultDriverV1DefaultdriverPostRequest {
    driverId?: number;
    monitoredObjectId?: string;
}

/**
 * no description
 */
export class DefaultDriverApi extends runtime.BaseAPI {

    /**
     * Get Info
     */
    async getInfoV1DefaultdriverGetRaw(requestParameters: GetInfoV1DefaultdriverGetRequest): Promise<runtime.ApiResponse<Array<DefaultDriverInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverIds) {
            queryParameters['driver_ids'] = requestParameters.driverIds;
        }

        if (requestParameters.monitoredObjectIds) {
            queryParameters['monitored_object_ids'] = requestParameters.monitoredObjectIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/defaultdriver/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefaultDriverInfoFromJSON));
    }

    /**
     * Get Info
     */
    async getInfoV1DefaultdriverGet(requestParameters: GetInfoV1DefaultdriverGetRequest): Promise<Array<DefaultDriverInfo>> {
        const response = await this.getInfoV1DefaultdriverGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set Default Driver
     */
    async setDefaultDriverV1DefaultdriverPostRaw(requestParameters: SetDefaultDriverV1DefaultdriverPostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/defaultdriver/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Set Default Driver
     */
    async setDefaultDriverV1DefaultdriverPost(requestParameters: SetDefaultDriverV1DefaultdriverPostRequest): Promise<object> {
        const response = await this.setDefaultDriverV1DefaultdriverPostRaw(requestParameters);
        return await response.value();
    }

}
