import { Component, ReactNode } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import { WithLogic, withLogicContext } from 'App';
import cn from 'classnames';
import { Control } from 'common/components/Form/Control';
import { SelectOption } from 'common/components/Form/Select';
import { ReadOnlyMonitoredObjectFeSb, ReadOnlyMonitoredObjectGroup } from 'generated/new-main';
import { Logic } from 'logic/logic';
import { withTranslation, WithTranslation } from 'react-i18next';

export enum VehicleSelectTypes {
    vehicles = 'vehicles',
    vehicleGroups = 'vehicleGroups'
}

interface Props extends SelectProps<string>, WithTranslation, WithLogic {
    className?: string;
    warning?: boolean;
    label?: ReactNode;
    error?: ReactNode;
    qa?: string;
    mode?: SelectProps<SelectOption>['mode'];
    width?: number;
    innerRef?: React.Ref<RefSelectProps>;
    logic: Logic;
    types: VehicleSelectTypes[];
}

interface State {
    loading: boolean;
    opts: optsItems;
}

interface optsItem {
    code: string;
    label: string;
}

interface optsItems {
    vehicleOpts: optsItem[];
    vehicleGroupOpts: optsItem[];
}

class VehicleSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            opts: {
                vehicleOpts: [],
                vehicleGroupOpts: []
            }
        };
    }

    componentDidMount(): void {
        this.loadItems();
    }

    async loadItems() {
        if (this.props.types.indexOf(VehicleSelectTypes.vehicles) > -1) {
            const vehicles: ReadOnlyMonitoredObjectFeSb[] =
                (await this.props.logic.vehicles().getMonitoredObjectFilters()) ?? [];
            this.setState(state => ({
                opts: {
                    ...state.opts,
                    vehicleOpts: vehicles
                        .map(d => ({ code: String(d.id), label: d.registrationNumber }))
                        .sort(
                            (a: optsItem, b: optsItem) =>
                                a.label.localeCompare(b.label) || a.label.localeCompare(b.label)
                        )
                }
            }));
        }
        if (this.props.types.indexOf(VehicleSelectTypes.vehicleGroups) > -1) {
            try {
                const vehicleGroups: ReadOnlyMonitoredObjectGroup[] =
                    (await (await this.props.logic.vehicles().vehicleGroups().getAllMonitoredObjectGroups()).results) ??
                    [];
                this.setState(state => ({
                    ...state,
                    opts: {
                        ...state.opts,
                        vehicleGroupOpts: vehicleGroups
                            .map(g => ({ code: String(g.id), label: g.name }))
                            .sort(
                                (a: optsItem, b: optsItem) =>
                                    a.label.localeCompare(b.label) || a.label.localeCompare(b.label)
                            )
                    }
                }));
            } catch (err) {
                console.error(`Loading vehicle groups to VehicleSelect, err: ${err}`);
            }
        }
        this.setState(state => ({
            ...state,
            loading: false
        }));
    }

    render() {
        const typesCount = this.props.types.length;

        return (
            <Control label={this.props.label} error={this.props.error} className={this.props.className}>
                <Select
                    loading={this.state.loading}
                    className={cn({
                        'ant-select-warning': this.props.warning,
                        'ant-select--with-label': this.props.label,
                        empty: !this.props.value && !this.props.defaultValue
                    })}
                    data-qa={this.props.qa}
                    ref={this.props.innerRef}
                    disabled={this.props.disabled || this.state.loading}
                    {...this.props}
                >
                    {this.props.types.indexOf(VehicleSelectTypes.vehicles) > -1 && typesCount > 1 && (
                        <Select.OptGroup
                            label={this.props.t('common.vehicles')}
                            data-group={this.props.t('common.vehicles')}
                        >
                            {this.state.opts.vehicleOpts.map(vehicle => (
                                <Select.Option
                                    key={vehicle.code}
                                    value={vehicle.code}
                                    data-group={this.props.t('common.vehicles')}
                                >
                                    {vehicle.label}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    )}
                    {this.props.types.indexOf(VehicleSelectTypes.vehicles) > -1 &&
                        typesCount === 1 &&
                        this.state.opts.vehicleOpts.map(vehicle => (
                            <Select.Option key={vehicle.code} value={vehicle.code}>
                                {vehicle.label}
                            </Select.Option>
                        ))}
                    {this.props.types.indexOf(VehicleSelectTypes.vehicleGroups) > -1 && typesCount > 1 && (
                        <Select.OptGroup
                            label={this.props.t('common.vehicleGroups')}
                            data-group={this.props.t('common.vehicleGroups')}
                        >
                            {this.state.opts.vehicleGroupOpts.map(group => (
                                <Select.Option
                                    key={`group_${group.code}`}
                                    value={`group_${group.code}`}
                                    data-group={this.props.t('common.vehicleGroups')}
                                >
                                    {group.label}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    )}
                    {this.props.types.indexOf(VehicleSelectTypes.vehicleGroups) > -1 &&
                        typesCount === 1 &&
                        this.state.opts.vehicleGroupOpts.map(group => (
                            <Select.Option key={`group_${group.code}`} value={`group_${group.code}`}>
                                {group.label}
                            </Select.Option>
                        ))}
                </Select>
            </Control>
        );
    }
}

export default withTranslation()(withLogicContext(VehicleSelect));
