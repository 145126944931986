import { Column, ColumnConfig } from '@ant-design/charts';
import numeral from 'numeral';

interface Props {
    dieselGCo2: number;
    lngGCo2: number;
}

export function TCOBarChartEmissions(props: Props) {
    const data = [
        {
            type: 'Diesel',
            emission: props.dieselGCo2
        },
        {
            type: 'LNG',
            emission: props.lngGCo2
        }
    ];
    const config: ColumnConfig = {
        data: data,
        xField: 'type',
        yField: 'emission',
        label: {
            position: 'middle',
            style: {
                fill: '#000000'
            }
        },
        color: function color(_ref) {
            var type = _ref.type;
            if (type === 'LNG') {
                return '#fbd22e';
            }
            return '#588aee';
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false
            }
        },
        meta: {
            emission: {
                formatter: function formatter(val) {
                    return ''.concat(numeral(val).format('0,0.0'), ' tonCO2');
                }
            }
        }
    };
    return <Column {...config} />;
}
