/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppliedDiemsRules,
    AppliedDiemsRulesFromJSON,
    AppliedDiemsRulesFromJSONTyped,
    AppliedDiemsRulesToJSON,
    BorderCrossing,
    BorderCrossingFromJSON,
    BorderCrossingFromJSONTyped,
    BorderCrossingToJSON,
    CountryStayDiem,
    CountryStayDiemFromJSON,
    CountryStayDiemFromJSONTyped,
    CountryStayDiemToJSON,
    DiemsRule2,
    DiemsRule2FromJSON,
    DiemsRule2FromJSONTyped,
    DiemsRule2ToJSON,
    FinancialReport,
    FinancialReportFromJSON,
    FinancialReportFromJSONTyped,
    FinancialReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface CountryStays
 */
export interface CountryStays {
    /**
     * 
     * @type {{ [key: string]: DiemsRule2; }}
     * @memberof CountryStays
     */
    diemsRules?: { [key: string]: DiemsRule2; };
    /**
     * 
     * @type {Array<BorderCrossing>}
     * @memberof CountryStays
     */
    borderCrossings?: Array<BorderCrossing>;
    /**
     * 
     * @type {FinancialReport}
     * @memberof CountryStays
     */
    financialReport?: FinancialReport;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryStays
     */
    visitedCountries?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: CountryStayDiem; }}
     * @memberof CountryStays
     */
    countryStayDiems?: { [key: string]: CountryStayDiem; };
    /**
     * 
     * @type {AppliedDiemsRules}
     * @memberof CountryStays
     */
    appliedDiemsRule?: AppliedDiemsRules;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryStays
     */
    appliedDiemsCountries?: Array<string>;
}

export function CountryStaysFromJSON(json: any): CountryStays {
    return CountryStaysFromJSONTyped(json, false);
}

export function CountryStaysFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryStays {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diemsRules': !exists(json, 'diems_rules') ? undefined : (mapValues(json['diems_rules'], DiemsRule2FromJSON)),
        'borderCrossings': !exists(json, 'border_crossings') ? undefined : ((json['border_crossings'] as Array<any>).map(BorderCrossingFromJSON)),
        'financialReport': !exists(json, 'financial_report') ? undefined : FinancialReportFromJSON(json['financial_report']),
        'visitedCountries': !exists(json, 'visited_countries') ? undefined : json['visited_countries'],
        'countryStayDiems': !exists(json, 'country_stay_diems') ? undefined : (mapValues(json['country_stay_diems'], CountryStayDiemFromJSON)),
        'appliedDiemsRule': !exists(json, 'applied_diems_rule') ? undefined : AppliedDiemsRulesFromJSON(json['applied_diems_rule']),
        'appliedDiemsCountries': !exists(json, 'applied_diems_countries') ? undefined : json['applied_diems_countries'],
    };
}

export function CountryStaysToJSON(value?: CountryStays | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diems_rules': value.diemsRules === undefined ? undefined : (mapValues(value.diemsRules, DiemsRule2ToJSON)),
        'border_crossings': value.borderCrossings === undefined ? undefined : ((value.borderCrossings as Array<any>).map(BorderCrossingToJSON)),
        'financial_report': FinancialReportToJSON(value.financialReport),
        'visited_countries': value.visitedCountries,
        'country_stay_diems': value.countryStayDiems === undefined ? undefined : (mapValues(value.countryStayDiems, CountryStayDiemToJSON)),
        'applied_diems_rule': AppliedDiemsRulesToJSON(value.appliedDiemsRule),
        'applied_diems_countries': value.appliedDiemsCountries,
    };
}


