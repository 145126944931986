import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import {
    ExternalSystemSecretMetadata,
    MonitoredObjectGroup,
    ReadOnlyExternalSystemAccess,
    ReadOnlyExternalSystemSecret,
    ReadOnlyMonitoredObjectGroup
} from 'generated/new-main';
import { Logic } from 'logic/logic';
import { SystemConnectFormValues } from 'modules/management/modules/system-connections/components/SystemConnectForm';
import { Subject } from 'rxjs';

const monitoredObjectGroupError = 'monitoredObjectGroupError';
const missingClientError = 'missingClient';
const replaceAssetError = 'replaceAssetError';
const externalSecretError = 'externalSecretError';

export const externalSystemCreateError = [
    monitoredObjectGroupError,
    missingClientError,
    replaceAssetError,
    externalSecretError
] as const;

export class ExternalSystemLogic {
    error = false;
    loading = false;
    secrets?: ReadOnlyExternalSystemSecret[];
    groups?: ReadOnlyMonitoredObjectGroup[];
    accesses?: ReadOnlyExternalSystemAccess[];

    onData = new Subject<{
        accesses: ReadOnlyExternalSystemAccess[];
        secrets: ReadOnlyExternalSystemSecret[];
    }>();
    onLoading = new Subject<boolean>();
    onError = new Subject<boolean>();

    constructor(private _logic: Logic) {}

    async loadData() {
        this.loading = true;

        if (this._logic.demo().isActive) {
            this.loading = false;
            this.onData.next({ accesses: [], secrets: [] });
            this.onLoading.next(this.loading);
            return { accesses: [], secrets: [] };
        }

        try {
            this.secrets = await this.getExternalSecrets();
            this.accesses = await this._getExternalAccess();
            this.loading = false;
            this.onData.next({ accesses: this.accesses, secrets: this.secrets });
            this.onLoading.next(this.loading);
            return { accesses: this.accesses, secrets: this.secrets };
        } catch (e) {
            console.error(e);
            this.error = true;
            throw e;
        }
    }

    async editSharedVehicles(
        externalSystemId: string,
        vehicles: number[],
        vehicleGroups: number[],
        automaticVehicleAdd?: boolean
    ): Promise<Object> {
        try {
            const secret = this.secrets?.find(s => s.externalSystemAccess?.id === externalSystemId);
            const group = secret?.monitoredObjectGroup;
            const groupId = secret?.monitoredObjectGroup?.id;

            if (!groupId) {
                console.error(`Can not find corespondent group`);
                throw new Error('Can not find corespondent group');
            }

            if (automaticVehicleAdd !== undefined && automaticVehicleAdd !== group?.automaticVehicleAdd) {
                const [_assetResult, updateResult] = await Promise.all([
                    this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupReplaceAsset({
                        id: groupId,
                        data: { monitoredObjects: vehicles, monitoredObjectGroups: vehicleGroups }
                    }),
                    this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupPartialUpdate({
                        id: groupId,
                        data: {
                            name: group?.name ?? '',
                            automaticVehicleAdd
                        }
                    })
                ]);

                return updateResult;
            }
            const request = await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupReplaceAsset({
                id: groupId,
                data: { monitoredObjects: vehicles, monitoredObjectGroups: vehicleGroups }
            });
            return request;
        } catch (err) {
            console.error(`Can not update monitoredObjectGroup, err: ${err}`);
            throw err;
        }
    }

    async activatePuesc(values: SystemConnectFormValues): Promise<ReadOnlyExternalSystemSecret> {
        try {
            const secret = this.secrets?.find(s => s.externalSystemAccess?.id === values.externalSystemId);
            const clientId = this._logic.auth().client()?.id;
            if (!clientId) {
                throw new Error('no client id');
            }

            if (!secret?.id) {
                throw new Error('no secret id');
            }
            const request = await this._logic.api().externalSystemSecretApi.externalSystemSecretUpdate({
                id: secret?.id,
                data: {
                    client: clientId,
                    externalSystemAccessId: values.externalSystemId,
                    metadata: {}
                }
            });
            return request;
        } catch (err) {
            console.error(`Can not activate puesc, err: ${err}`);
            throw err;
        }
    }

    async editExternalSystem(
        externalSystemId: number,
        externalSystemAccessId: string,
        metadata?: ExternalSystemSecretMetadata
    ) {
        try {
            const clientId = this._logic.auth().client()?.id;
            if (!clientId) {
                throw new Error('no client id');
            }
            const externalSecret = await this._logic.api().externalSystemSecretApi.externalSystemSecretPartialUpdate({
                id: externalSystemId,
                data: {
                    client: clientId,
                    externalSystemAccessId: externalSystemAccessId,
                    metadata: {
                        puescActive: !!metadata?.puescActive, // has to use !! since undefined will be not sent as key, but keys are required for api
                        puescDataAlwaysOn: !!metadata?.puescDataAlwaysOn
                        // puescSentFormsActive: boolean TODO: not be implementation
                    }
                }
            });

            return externalSecret;
        } catch (err) {
            console.error(`Can not update external secret, err: ${err}`);
            throw err;
        }
    }

    async createExternalSecret(
        externalSystemAccessId: string,
        vehicles?: number[],
        vehicleGroups?: number[],
        automaticVehicleAdd?: boolean,
        metadata?: ExternalSystemSecretMetadata
    ): Promise<MonitoredObjectGroup> {
        try {
            const clientId = this._logic.auth().client()?.id;
            if (!clientId) {
                throw new Error(missingClientError);
            }

            const externalSecret = await this._logic.api().externalSystemSecretApi.externalSystemSecretCreate({
                data: {
                    client: clientId,
                    externalSystemAccessId,
                    metadata: {
                        puescActive: !!metadata?.puescActive, // has to use !! since undefined will be not sent as key, but keys are required for api
                        puescDataAlwaysOn: !!metadata?.puescDataAlwaysOn
                        // puescSentFormsActive: boolean TODO: not be implementation
                    }
                }
            });

            const group = externalSecret.monitoredObjectGroup;
            const groupId = group?.id;
            if (!groupId) {
                throw new Error(monitoredObjectGroupError);
            }
            try {
                if (automaticVehicleAdd !== group?.automaticVehicleAdd) {
                    const [_assetResult, updateResult] = await Promise.all([
                        this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupReplaceAsset({
                            id: groupId,
                            data: { monitoredObjects: vehicles ?? [], monitoredObjectGroups: vehicleGroups ?? [] }
                        }),
                        this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupPartialUpdate({
                            id: groupId,
                            data: {
                                name: group?.name ?? '',
                                automaticVehicleAdd
                            }
                        })
                    ]);

                    return updateResult;
                }

                const request = await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupReplaceAsset({
                    id: groupId,
                    data: { monitoredObjects: vehicles ?? [], monitoredObjectGroups: vehicleGroups ?? [] }
                });
                return request;
            } catch (err) {
                throw new Error(replaceAssetError);
            }
        } catch (err: any) {
            if (externalSystemCreateError.includes(err.message)) {
                throw err;
            }
            console.error(`Can not create external secret, err: ${err}`);
            throw new Error(externalSecretError);
        }
    }

    async deleteExternalSecret(id: number): Promise<ReadOnlyExternalSystemSecret> {
        try {
            return await this._logic.api().externalSystemSecretApi.externalSystemSecretDelete({
                id: id
            });
        } catch (err) {
            console.error('can not remove Exterlan system secrets');
            throw err;
        }
    }

    private async _getExternalAccess(): Promise<ReadOnlyExternalSystemAccess[]> {
        const resp = await this._logic.api().externalSystemAccessApi.externalSystemAccessList({
            limit: INFINITY_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        });
        return resp.results;
    }

    private async getExternalSecrets(): Promise<ReadOnlyExternalSystemSecret[]> {
        const resp = await this._logic.api().externalSystemSecretApi.externalSystemSecretList({
            limit: INFINITY_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        });
        return resp.results;
    }
}
