import React, { useEffect, useState, useRef } from 'react';
import type { FormInstance } from 'antd/es/form';
import { useContext } from 'react';
import { ActivityGood } from '../ActivityCard';
import { Form, Input, InputNumber, Row } from 'antd';
import { getParents } from 'common/utils';

interface EditableRowProps {
    index: number;
}

export const EditableRowContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableRowContext.Provider value={form}>
                <tr data-index={index} {...props} />
            </EditableRowContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof ActivityGood;
    record: ActivityGood;
    dataType?: string;
    onSave: (record: ActivityGood, index: string) => void;
    onEdit: (state: boolean, dataIndex: string, record: ActivityGood) => void;
    onEditValue: (dataIndex: string, record: ActivityGood) => any;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    editable,
    children,
    dataIndex,
    record,
    dataType,
    onSave,
    onEdit,
    onEditValue,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableRowContext)!;

    useEffect(() => {
        if (editing && inputRef.current) {
            (inputRef.current as HTMLInputElement).focus();
            (inputRef.current as HTMLInputElement).select();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);

        let value = record[dataIndex];
        if (onEditValue) {
            value = onEditValue(dataIndex, record);
        }

        form.setFieldsValue({ [dataIndex]: value });
        onEdit(!editing, dataIndex, record);
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();

            setEditing(false);
            onSave({ ...record, ...values }, dataIndex);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const handleKeypress = async (e: React.KeyboardEvent) => {
        try {
            if (e.key.toLowerCase() === 'tab') {
                const values = await form.validateFields();
                let td = getParents(e.target, 'td');
                setEditing(false);

                onSave({ ...record, ...values }, dataIndex);

                if (td) {
                    td = td.nextSibling;
                    if (td.firstElementChild.classList.contains('editable-cell-value-wrap')) {
                        setTimeout(() => {
                            td.firstElementChild.click();
                        }, 0);
                    }
                }
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        if (editing) {
            if (dataType === 'number') {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <InputNumber
                            min={0}
                            precision={2}
                            ref={inputRef}
                            onPressEnter={handleSave}
                            onKeyDown={handleKeypress}
                            onBlur={handleSave}
                            autoFocus
                        />
                    </Form.Item>
                );
            } else {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <Input
                            ref={inputRef}
                            onKeyDown={handleKeypress}
                            onPressEnter={handleSave}
                            onBlur={handleSave}
                            autoFocus
                        />
                    </Form.Item>
                );
            }
        } else {
            childNode = (
                <Row align="middle" className="editable-cell-value-wrap" onClick={toggleEdit}>
                    {children}
                </Row>
            );
        }
    }

    return <td {...restProps}>{childNode}</td>;
};
