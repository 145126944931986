import { withTranslation, WithTranslation } from 'react-i18next';
import { FuelConsumptionFilterModel, FuelConsumptionTableModel, SettingsOptsOut } from '../FuelConsumptionModule';
import FuelConsumptionBar from './FuelConsumptionBar';
import FuelConsumptionTable from './FuelConsumptionTable';
import { Modal } from 'common/components';
import { Role } from 'logic/auth';
import HelperModal from 'common/components/HelperModal';
import { DateRange } from 'common/model/date-time';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { FuelStatsGroupByEntity, FuelStatsGroupByTimespan } from 'generated/backend-api';
import { DriverModel } from 'logic/user/users';

interface Props extends WithTranslation {
    demoMode?: boolean;
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    drivers: DriverModel[];
    bar: {
        summary: boolean;
        filterOpen: boolean;
        settings: boolean;
        dateOpen: boolean;
        dateChanged?: boolean;
        backUrl?: boolean;
        exportEnable: boolean;
        exportProcessing: boolean;
    };
    filter: FuelConsumptionFilterModel;
    helper?: {
        content: string;
    };
    dateRange: DateRange;
    table?: FuelConsumptionTableModel;
    vehicleUnpermissioned?: boolean;
    roles: Role[];
    onExport?: () => void;
    onBarSummaryClick?: () => void;
    onBarFilterClick?: () => void;
    onBarHelperClick?: () => void;
    onBarSettingsClick?: () => void;
    onBarBackUrlClick?: () => void;
    onBarResetClick?: () => void;
    onFilterConfirm?: (
        driversChecked: string[],
        vehiclesChecked: string[],
        shortActivity: boolean,
        fuelSuspiciousActivity: boolean
    ) => void;
    onFilterCancel?: () => void;
    onHelperClose?: () => void;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onSettingsConfirm?: (settings: SettingsOptsOut) => void;
    onSettingsCancel?: () => void;
    onTableItemSelect?: (id: string) => void;
    onTableFilterDate?: (type: FuelStatsGroupByTimespan) => void;
    onTableSwitchDriverVehicle?: (type: FuelStatsGroupByEntity) => void;
    onVehicleUnpermissionedClose?: () => void;
}

function FuelConsumption(props: Props) {
    return (
        <div className="fuel-consumption page">
            <FuelConsumptionBar
                demoMode={props.demoMode}
                summary={props.bar.summary}
                filter={props.filter}
                filterOpen={props.bar.filterOpen}
                settings={props.bar.settings}
                dateOpen={props.bar.dateOpen}
                dateChanged={props.bar.dateChanged}
                dateRange={props.dateRange}
                backUrl={props.bar.backUrl}
                exportEnable={props.bar.exportEnable}
                exportProcessing={props.bar.exportProcessing}
                onExport={props.onExport}
                onSummaryClick={props.onBarSummaryClick}
                onFilterClick={props.onBarFilterClick}
                onHelperClick={props.onBarHelperClick}
                onSettingsClick={props.onBarSettingsClick}
                onDateRangeChange={props.onDateRangeChange}
                onBackUrlClick={props.onBarBackUrlClick}
                onBarResetClick={props.onBarResetClick}
                onFilterConfirm={props.onFilterConfirm}
                onFilterCancel={props.onFilterCancel}
            />

            {props.table && (
                <FuelConsumptionTable
                    vehicles={props.vehicles}
                    drivers={props.drivers}
                    data={props.table.data}
                    loading={props.table.loading}
                    agregation={props.table.groupBy}
                    summary={props.table.summary}
                    expanded={props.table.expanded}
                    roles={props.roles}
                    onItemSelect={props.onTableItemSelect}
                    groupByTimespanChange={props.onTableFilterDate}
                    groupByEntityChange={props.onTableSwitchDriverVehicle}
                />
            )}

            {props.vehicleUnpermissioned && (
                <Modal
                    cancelButtonProps={{ style: { display: 'none' } }}
                    visible={props.vehicleUnpermissioned}
                    onOk={props.onVehicleUnpermissionedClose}
                    onCancel={props.onVehicleUnpermissionedClose}
                >
                    {props.t('FuelConsumption.vehicleUnpermissioned')}
                </Modal>
            )}

            <HelperModal
                name="fuel-consumption"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}

export default withTranslation()(FuelConsumption);
