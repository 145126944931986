const qa = {
    common: {
        linkedItem: {
            fieldName: 'common--linked-item--field-name',
            fieldType: 'common--linked-item--field-type',
            fieldItem: 'common--linked-item--field-item',
            btnUnpair: 'common--linked-item--btn-unpair'
        },
        modal: {
            btnConfirm: 'common--modal--btn-confirm',
            btnCancel: 'common--modal--btn-cancel',
            btnClose: 'common--modal--btn-close'
        },
        dateTimePicker: {
            btn: 'common--date-time-picker--btn',
            btnNow: 'common--date-time-picker--btn-now'
        },
        pairing: {
            inputSearch: 'common--pairing--input-search'
        },
        inputSearch: 'common--input-search',
        btnBack: 'common--btn-back',
        btnReturn: 'common--btn-return',
        btnHelper: 'common--btn-helper',
        checkbox: 'common--checkbox',
        checkboxAll: 'common--checkbox-all',
        radio: 'common--radio',
        radioButton: 'common--radio-button',
        filter: {
            btnReset: 'common--filter--btn-reset'
        },
        bar: {
            btnReset: 'common--bar--btn-reset'
        },
        dateTimeRangePicker: {
            btn: 'common--date-range--btn',
            monthsFrom: 'common--date-time-range-picker--months-from',
            monthsTo: 'common--date-time-range-picker--months-to',
            yearsFrom: 'common--date-time-range-picker--years-from',
            yearsTo: 'common--date-time-range-picker--years-to',
            prevMonth: 'common--date-time-range-picker--prew-month',
            nextMonth: 'common--date-time-range-picker--next-month',
            currentDate: 'common--date-time-range-picker--current-date',
            startDate: 'common--date-time-range-picker--start-date',
            endDate: 'common--date-time-range-picker--end-date',
            shortcut: 'common--date-time-range-picker--btn-shortcut',
            cancel: 'common--date-time-range-picker--btn-cancel',
            confirm: 'common--date-time-range-picker--btn-confirm'
        },
        noData: 'common--no-data'
    },
    navbar: {
        menuOpenerButton: 'navbar--menu-opener-button',
        userSettingsButton: 'navbar--user-button',
        messagingButton: 'navbar--messaging-button',
        messagingCount: 'navbar--messaging-total-icon',
        docsButton: 'navbar--docs-button',
        profileButton: 'navbar--profile-button',
        logo: 'navbar--logo',
        btnSuperadmin: 'navbar--btn-superadmin',
        btnMap: 'navbar--btn-map',
        btnDispatcherBoard: 'navbar--btn-dispatcher-board',
        btnScheduling: 'navbar--btn-scheduling',
        scheduling: {
            btnPlanner: 'navbar--scheduling--btn-planner',
            btnPlanner2: 'navbar--scheduling--btn-planner-2',
            btnRouteOverview: 'navbar--scheduling--btn-route-overview'
        },
        btnStatistics: 'navbar--btn-statistics',
        statistics: {
            btnCompanyProfile: 'navbar--statistics--btn-statistics--company-profile',
            btnJourneysActivity: 'navbar--statistics--btn-journeys-activity',
            btnFuelConsumption: 'navbar--statistics--btn-fuel-consumption',
            btnAetr: 'navbar--statistics--btn-aetr',
            btnExpenses: 'navbar--statistics--btn-expenses',
            btnDriverBehavior: 'navbar--statistics--btn-driver-behavior',
            btnBorderCrossing: 'navbar--statistics--btn-border-crossing',
            btnMaintenance: 'navbar--statistics--btn-border-maintenance',
            btnAllowances: 'navbar--statistics--btn-border-allowances',
            btnStatisticReport: 'navbar--statistics--btn-statistic-report',
            btnColdchain: 'navbar--statistics--btn-cold-chain'
        },
        btnSettings: 'navbar--btn-settings',
        settings: {
            btnFleet: 'navbar--settings--btn-fleet',
            btnRoles: 'navbar--settings--btn-roles',
            btnUsers: 'navbar--settings--btn-users',
            btnFuelCards: 'navbar--settings--btn-fuel-cards',
            btnTachographCards: 'navbar--settings--btn-tachograph-cards',
            btnDriverCardsRemoteMemory: 'navbar--settings--btn-driver-cards-remote-memory',
            btnVehiclesRemoteMemory: 'navbar--settings--btn-vehicles-remote-memory',
            btnCustomPlaces: 'navbar--settings--btn-custom-places',
            btnSystemConnections: 'navbar--settings--btn-system-connections',
            btnContacts: 'navbar--settings--btn-contacts'
        },
        btnPartner: 'navbar--btn-partner',
        partner: {
            btnPartners: 'navbar--partner--btn-partners',
            btnCompanies: 'navbar--partner--btn-companies',
            btnWhitelabel: 'navbar--partner--btn-whitelabel',
            btnTlmServices: 'navbar--partner--btn-tlm-services',
            btnPricing: 'navbar--partner--btn-pricing',
            btnVehicles: 'navbar--partner--btn-vehicles',
            btnObu: 'navbar--partner--btn-obu',
            btnCcr: 'navbar--partner--btn-ccr',
            btnInvoices: 'navbar--partner--btn-invoices',
            btnUsers: 'navbar--partner--btn-users'
        }
    },
    sidePanel: {
        fieldTitle: 'sidepanel--field-title'
    },
    sidebar: {
        statistics: {
            btnCompanyProfile: 'sidebar--statistics--statistics--company-profile',
            btnJourneysActivity: 'sidebar--statistics--journeys-activity',
            btnFuelConsumption: 'sidebar--statistics--fuel-consumption',
            btnAetr: 'sidebar--statistics--aetr',
            btnExpenses: 'sidebar--statistics--expenses',
            btnDriverBehavior: 'sidebar--statistics--driver-behavior',
            btnBorderCrossing: 'sidebar--statistics--border-crossing',
            btnMaintenance: 'sidebar--statistics--border-maintenance',
            btnAllowances: 'sidebar--statistics--border-allowances',
            btnStatisticReport: 'sidebar--statistics--statistic-report',
            btnColdchain: 'sidebar--statistics--coldchain'
        },
        settings: {
            btnFleet: 'sidebar--settings--fleet',
            btnRles: 'sidebar--settings--roles',
            btnUsers: 'sidebar--settings--users',
            btnFuelCards: 'sidebar--settings--fuel-cards',
            btnTachographCards: 'sidebar--settings--tachograph-cards',
            btnDriverCardsRemoteMemory: 'sidebar--settings--driver-cards-remote-memory',
            btnVehiclesRemoteMemory: 'sidebar--settings--vehicles-remote-memory',
            btnCustomPlaces: 'sidebar--settings--custom-places',
            btnSystemConnections: 'sidebar--settings--system-connections',
            btnContactList: 'sidebar--settings--contact-list'
        },
        partner: {
            btnPartners: 'sidebar--partner--partners',
            btnCompanies: 'sidebar--partner--companies',
            btnWhitelabel: 'sidebar--partner--whitelabel',
            btnTlmServices: 'sidebar--partner--tlm-services',
            btnPricing: 'sidebar--partner--pricing',
            btnVehicles: 'sidebar--partner--vehicles',
            btnObu: 'sidebar--partner--obu',
            ccr: 'sidebar--partner--ccr',
            invoices: 'sidebar--partner--invoices',
            users: 'sidebar--partner--users'
        }
    },
    userSettings: {
        languageSelect: 'user-settings--language-select',
        placesNamingContainer: 'user-settings--places-naming-container',
        vehicleIdentificationContainer: 'user-settings--vehicle-identification-container',
        closeButton: 'user-settings--close-button',
        logoutButton: 'user-settings--logout-button',
        btnCompanyNameCopy: 'user-settings--btn-company-name-copy'
    },
    map: {
        zoomInButton: 'map--zoom-in-button',
        zoomOutButton: 'map--zoom-out-button',
        fullScreenButton: 'map--fullscreen-button',
        parkingLotsButton: 'map--parking-lots-button',
        fuelStationsButton: 'map--fuel-stations-button',
        layersButton: 'map--layers-button',
        btnWash: 'map--btn-wash',
        btnPoi: 'map--btn-poi',
        btnCenter: 'map--btn-center',
        borderCrosing: 'map--border-crossing',
        borderCrossingInfoWidnow: 'map--border-crossing-info-window',
        routeInfobox: {
            fieldDistance: 'map--route-infobox--field-distance',
            fieldPlaceFrom: 'map--route-infobox--field-place-from',
            fieldPlaceTo: 'map--route-infobox--field-place-to',
            fieldTime: 'map--route-infobox--field-time',
            fieldDuration: 'map--route-infobox--field-duration'
        }
    },
    mapContextMenu: {
        btnStartFromHere: 'map-context-menu--btn-start-from-here',
        btnDirectionToHere: 'map-context-menu--btn-direction-to-here',
        btnAddToRoute: 'map-context-menu--btn-add-to-route',
        btnCreatePoi: 'map-context-menu--btn-create-poi'
    },
    mapControls: {
        simpleLayerButton: 'map-controls--simple-layer-button',
        colourLayerButton: 'map-controls--colour-layer-button',
        heightLayerButton: 'map-controls--height-layer-button',
        displayTrafficButton: 'map-controls--display-traffic-button',
        displayBorderCrossesButton: 'map-controls--display-border-crossings-button',
        colouredVehiclesButton: 'map-controls--coloured-vehicles-button',
        labeledVehiclesButton: 'map-controls--labeled-vehicles-button',
        showFuelStationsButton: 'map-controls--show-fuel-stations-button',
        hideFuelStationsButton: 'map-controls--hide-fuel-stations-button',
        mapControls: 'map-controls--fuel-station-owners-container',
        fuelsAndServicesButton: 'map-controls--fuels-and-services-button',
        fuelStationsServicesContainer: 'map-controls--fuel-stations-services-container',
        showParkingLotsButton: 'map-controls--show-parking-lots-button',
        hideParkingLotsButton: 'map-controls--hide-parking-lots-button',
        showPoisButton: 'map-controls--show-pois-button',
        hidePoisButton: 'map-controls--hide-pois-button',
        showWashersButton: 'map-controls--show-washers-button',
        hideWashersButton: 'map-controls--hide-washers-button',
        parkingLotOwnersContainer: 'map-controls--parking-lot-owners-container',
        parkingLotServicesContainer: 'map-controls--parking-lot-services-container',
        closeButton: 'map-controls--close-button',
        infobox: {
            sectionBoxBestPrice: 'map-controls--infobox--section-box-best-price',
            sectionBoxSelected: 'map-controls--infobox--section-box-selected',
            sectionBox: 'map-controls--infobox--section-box',
            fieldFuelName: 'map-controls--field-fuel-name',
            fieldFuelPrice: 'map-controls--field-fuel-price',
            fieldDistance: 'map-controls--field-distance',
            fuel: {
                AdBlue: 'map-controls--infobox--fuel--adblue',
                'AdBlue-BG': 'map-controls--infobox--fuel--adbluebg',
                'AdBlue-can18': 'map-controls--infobox--fuel--adbluecan18',
                'AdBlue-can20': 'map-controls--infobox--fuel--adbluecan20',
                'AdBlue-canwithoutnozzle': 'map-controls--infobox--fuel--adbluecanwithoutnozzle',
                'AdBlueEsso-can': 'map-controls--infobox--fuel--adblueessocan',
                ArticDiesel: 'map-controls--infobox--fuel--articdiesel',
                BA95N: 'map-controls--infobox--fuel--ba95n',
                BA95NPremium: 'map-controls--infobox--fuel--ba95npremium',
                BA98N: 'map-controls--infobox--fuel--ba98n',
                'Biodiesel50/50': 'map-controls--infobox--fuel--biodiesel5050',
                'Biodiesel70/30': 'map-controls--infobox--fuel--biodiesel7030',
                Biodiesel100: 'map-controls--infobox--fuel--biodiesel100',
                Diesel: 'map-controls--infobox--fuel--diesel',
                DieselEconomy: 'map-controls--infobox--fuel--dieseleconomy',
                DieselEWPremium: 'map-controls--infobox--fuel--dieselewpremium',
                'EkoDiesel30/70': 'map-controls--infobox--fuel--ekodiesel3070',
                'LTO-E': 'map-controls--infobox--fuel--ltoe'
            },
            btnAddToRoute: 'map-controls--infobox--btn-add-to-route',
            fieldInTransport: 'map-controls--infobox--field-in-transport'
        }
    },
    trackingBar: {
        search: 'tracking-bar--search',
        searchInput: 'tracking-search--input',
        showFilterButton: 'tracking-bar--show-filter-button',
        expandTableButton: 'tracking-bar--expand-table-button',
        btnHelper: 'tracking-bar--btn-helper',
        filter: {
            EtaAll: 'tracking-bar--filter--all_etas',
            EtaMoreThan30: 'tracking-bar--filter--eta_more_than_30_minutes_late',
            EtaLessThan30: 'tracking-bar--filter--eta-less_than_30_minutes_late',
            EtaOnTime: 'tracking-bar--filter--eta-on-time',
            StatusAll: 'tracking-bar--filter--status-all',
            EtaRtaNotEntered: 'tracking-bar--filter--eta-rta-not-entered',
            StatusVehiclesMoving: 'tracking-bar--filter--status-vehicles-moving',
            StatusVehiclesStanding: 'tracking-bar--filter--status-vehicles-standing',
            VehicleGroups: 'tracking-bar--filter--vehicle-groups',
            ConfirmButton: 'tracking-bar--filter--confirm-button',
            CancelButton: 'tracking-bar--filter--cancel-button'
        }
    },
    trackingSmartBar: {
        btnRolled: 'tracking-smart-bar--btn-rolled',
        btnOnTime: 'tracking-smart-bar--btn-table',
        btnDelayed: 'tracking-smart-bar--btn-delayed',
        btnFuelLow: 'tracking-smart-bar--btn-fuel-low',
        btnAlarms: 'tracking-smart-bar--btn-alarms',
        btnAetrLow: 'tracking-smart-bar--btn-aetr-low'
    },
    trackingTable: {
        addDestinationButton: 'tracking-table--add-destination-button',
        imgChecked: 'tracking-table--image-checked',
        imgNotChecked: 'tracking-table--image-not-checked',
        fieldRn: 'tracking-table--field-fn',
        fieldTrailers: 'tracking-table--field-trailers',
        fieldLocation: 'tracking-table--field-location',
        fieldDestination: 'tracking-table--field-destination',
        fieldEtaTime: 'tracking-table--field-eta-time',
        fieldEtaDate: 'tracking-table--field-eta-date',
        fieldRtaTime: 'tracking-table--field-rta-time',
        fieldRtaDate: 'tracking-table--field-rta-date',
        fieldDriver: 'tracking-table--field-driver',
        fieldStatus_working: 'tracking-table--field-status-working',
        fieldStatus_resting: 'tracking-table--field-status-resting',
        fieldStatus_driving: 'tracking-table--field-status-driving',
        sectionAetr: 'tracking-table--section-aetr',
        fieldOdo: 'tracking-table--field-odo',
        fieldTank: 'tracking-table--field-tank',
        btnActual: 'tracking-table--btn-actual',
        fieldStatus_stop: 'tracking-table--img-status-stop',
        fieldStatus_play: 'tracking-table--img-status-play',
        fieldStatus_pause: 'tracking-table--img-status-pause'
    },
    vehicleCard: {
        btnCopyCoordinatesToClipboard: 'vehicle--card--copy-coordinates',
        rn: 'vehicle-card--rn',
        data: {
            fieldLastUpdate: 'vehicle--card--field-last-update',
            btnLinkToJourneys: 'vehicle-card--btn-link-to-journeys'
        },
        aetr: {
            fieldDriver: 'vehicle-card--aetr--field-driver',
            fieldDriverActive: 'vehicle-card--aetr--field-driver-active',
            fieldDailyDriveLeft: 'vehicle-card--aetr--field-daily-drive-left',
            fieldDailyDriveMax: 'vehicle-card--aetr--field-daily-drive-max',
            fieldDailyWorkLeft: 'vehicle-card--aetr--field-daily-work-left',
            fieldDailyWorkMax: 'vehicle-card--aetr--field-daily-work-max',
            field14daysDriveLeft: 'vehicle-card--aetr--14-days-drive-left',
            field14daysDriveMax: 'vehicle-card--aetr--14-days-drive-max',
            btnLinkToAetr: 'vehicle-card--btn-link-to-aetr'
        },
        transport: {
            sectionPlace: 'vehicle-card--transport--place--section-place',
            place: {
                fieldDate: 'vehicle-card--transport--place--field-date',
                fiedAddress: 'vehicle-card--transport--place--field-address',
                fieldDuration: 'vehicle-card--transport--place--duration--field-duration',
                fieldDistanceTo: 'vehicle-card--transport--place--duration--field-distance-to',
                type: {
                    loading: 'vehicle-card--transport--place--type--loading',
                    unloading: 'vehicle-card--transport--place--type--unloading',
                    refueling: 'vehicle-card--transport--place--type--refueling',
                    parking: 'vehicle-card--transport--place--type--parking'
                }
            }
        }
    },
    dispatcherBoard: {
        showNewTransportsButton: 'dispatcher-board--show-new-transports-button',
        delayButton: 'dispatcher-board--delay-button',
        previousButton: 'dispatcher-board--previous-button',
        nextButton: 'dispatcher-board--next-button',
        dff: {
            convert: {
                linkTransEu: 'dispatcher-board--dff--convert--link-trans-eu'
            }
        },
        bar: {
            expand: 'dispatcher-board--bar--expand',
            truckTime: 'dispatcher-board--bar--trucktime',
            help: 'dispatcher-board--bar--help',
            dffIcon: 'dispatcher-board--bar--dfficon',
            dffManualRefresh: 'dispatcher-board--bar--dff-manual-refresh',
            dffRatingShowAll: 'dispatcher-board--bar--dff-rating-show-all',
            dffRating: {
                1: 'dispatcher-board--bar--dff-rating-1',
                2: 'dispatcher-board--bar--dff-rating-2',
                3: 'dispatcher-board--bar--dff-rating-3',
                4: 'dispatcher-board--bar--dff-rating-4',
                5: 'dispatcher-board--bar--dff-rating-5'
            },
            star: 'dispatcher-board--bar--star',
            settings: 'dispatcher-board--bar--settings'
        },
        calendar: {
            fieldDateRange: 'dispatcher-board--calendar--field-date-range'
        },
        sectionFreeTransports: 'dispatcher-board--section-free-transports',
        fieldRn: 'dispatcher-board--field-rn',
        sectionRnRow: 'dispatcher-board--section-rn-row',
        status: {
            'telematics-transport-eta-on-time': 'dispatcher-board--status--telematics-transport-eta-on-time',
            'telematics-transport-eta-delay': 'dispatcher-board--status--telematics-transport-eta-delay',
            'telematics-transport-eta-late': 'dispatcher-board--status--telematics-transport-eta-late'
        },
        etaKm: 'dispatcher-board--eta-km',
        sectionTransport: 'dispatcher-board--section-transport',
        navigation: {
            sectionDaySelected: 'dispatcher-board--navigation--section-day-selected',
            fieldDate: 'dispatcher-board--section-navigation-day-selected'
        }
    },
    schedulingPlanning: {
        defaultRouteName: 'scheduling-planing--default-route-name',
        addRoute: 'route-planning--add-route-button',
        search: 'route-planning--search',
        showKMButton: 'route-planning--show-km-button',
        activateTasksButton: 'route-planning--activate-tasks-button',
        routePointSearch: 'route-planning--route-point-search',
        cancelWithoutSave: 'route-planning--cancel-without-save',
        btnGenerateRoute: 'route-planning--btn-generate-route',
        saveRoute: 'route-planning--save-route',
        routePointMenu: {
            all: 'route-planning--route-point-menu--all',
            ownPlaces: 'route-planning--route-point-menu--own-places',
            ourCompanies: 'route-planning--route-point-menu--our-companies',
            companies: 'route-planning--route-point-menu--companies',
            mapResult: 'route-planning--route-point-menu--map-results',
            gasStations: 'route-planning--route-point-menu--gas-stations',
            parkingLots: 'route-planning--route-point-menu--parking-lots'
        },
        templates: {
            savesRoutes: 'route-planning--template-saves-routes',
            edit: 'route-planning--template-edit',
            delete: 'route-planning--template-delete'
        },
        trailer: {
            availableTrailer: 'route-planning--available-trailer'
        },
        vehicleProfile: {
            availableVehicle: {
                withGPS: 'route-planning--available-vehicle--with-GPS',
                withoutGPS: 'route-planning--available-vehicle--without-GPS'
            },
            disabledVehicle: {
                withGPS: 'route-planning--disabled-vehicle--with-GPS',
                withoutGPS: 'route-planning--disabled-vehicle--without-GPS'
            },
            noCriteria: 'route-planning--no-criteria',
            profile: 'route--planning--vehicle-profile',
            createProfile: 'route-planning--create-new-profile'
        }
    },
    planner: {
        createVehicleProfile: {
            inputName: 'planner--create-vehicle-profile--input-name',
            inputLength: 'planner--create-vehicle-profile--input-length',
            inputHeight: 'planner--create-vehicle-profile--input-name',
            inputWidth: 'planner--create-vehicle-profile--input-name',
            inputWeight: 'planner--create-vehicle-profile--input-name',
            selectTunnelType: 'planner--create-vehicle-profile--input-name',
            selectEmisionClass: 'planner--create-vehicle-profile--input-select-emision-class',
            selectTrailerCount: 'planner--create-vehicle-profile--input-name',
            inputAxlesCount: 'planner--create-vehicle-profile--input-axles-count',
            inputPricePerKm: 'planner--create-vehicle-profile--input-price-per-km',
            btnSubmit: 'planner--create-vehicle-profile--btn-submit',
            btnCancel: 'planner--create-vehicle-profile--btn-cancel',
            btnEdit: 'planner--vehicle-profile--btn-edit',
            btnDelete: 'planner--vehicle-profile--btn-delete'
        },
        coldchain: {
            btnAddZone: 'planner--coldchain-add-zone',
            btnEditProfile: 'planner--coldchain-edit-profile',
            selectProfile: 'planner--coldchain-select-profile',
            newProfie: 'planner--coldchain-select-new-profile'
        },
        places: {
            btnEdit: 'planner--places--btn-edit',
            btnEditCancel: 'planner--places--btn-edit-cancel',
            btnEditName: 'planner--places--btn-edit-name',
            btnEditNameClear: 'planner--places--btn-edit-name-clear',
            btnEditNameConfirm: 'planner--places--btn-edit-name-confirm',
            btnEditNameCancel: 'planner--places--btn-edit-name-cancel',
            fieldName: 'planner--places--field-name',
            btnTasks: 'planner--places--btn-tasks',
            btnAddTask: 'planner--places--btn-add-task',
            btnAlarms: 'planner--places--btn-alarms',
            btnRemove: 'planner--places--btn-remove',
            inputName: 'planner--places--input-name',
            fieldTime: 'planner--places--field-time',
            fieldDate: 'planner--places--field-date',
            fieldDistance: 'planner--places--field-distance',
            btnDatepicker: 'planner--places--btn-datepicker',
            fieldPuescWarning: 'planner--places--field-puesc-warning',
            sectionPlace: 'planner--places--section-place'
        },
        placeSettings: {
            btnClose: 'planner--place-settings--btn-close',
            inputManualAta: 'planner--place-settings--input-manual-ata',
            btnConfirm: 'planner--place-settings--btn-confirm'
        },
        placesTask: {
            btnType_parking: 'planner--places-task--btn-type-parking',
            btnType_refueling: 'planner--places-task--btn-type-refueling',
            btnType_loading: 'planner--places-task--btn-type-loading',
            btnType_unloading: 'planner--places-task--btn-type-unloading',
            btnType_none: 'planner--places-task--btn-type-none',
            inputInstructions: 'planner--places-task--input-instructions',
            fieldDriverNote: 'planner--places-task--field-driver-note'
        },
        alarms: {
            input_transport_corridor_leave: 'planner--places-task--input-transport-corridor-leave',
            input_transport_poi_close: 'planner--places-task--input-transport-poi-close',
            input_transport_arrival: 'planner--places-task--input-transport-arrival',
            input_transport_departure: 'planner--places-task--input-transport-departure'
        },
        puesc: {
            btnOk: 'planner--puesc--btnOK',
            btnConfirm: 'planner--puesc--btn-confirm',
            btnBackToRoute: 'planner--puesc--btn-back-to-route',
            linkSystemConnection: 'planner--puesc--link-system-connection',
            selectTypeOfGoods: 'planner--puesc--select-type-of-goods',
            optionTypeOfGoods: 'planner--puesc-option-type-of-goods'
        },
        autocomplete: {
            fieldGoogleItem: 'planner--autocomplete--field-google-item',
            fieldEurowagItem: 'planner--autocomplete--field-eurowag-item',
            inputSearch: 'planner--autocomplete--input-search',
            markedString: 'planner--autocomplete--marked-string'
        },
        activity: {
            btnType: {
                loading: 'planner--activity--btn-type--loading',
                unloading: 'planner--activity--btn-type--unloading',
                parking: 'planner--activity--btn-type--parking',
                refueling: 'planner--activity--btn-type--refueling',
                none: 'planner--activity--btn-type--none'
            },
            inputCompanyName: 'planner--activity--input-company-name',
            inputBussinessId: 'planner--activity--input-bussiness-id',
            inputVat: 'planner--activity--input-vat',
            inputTaxId: 'planner--activity--input-tax-id',
            inputOther: 'planner--activity--input-other',
            inputCountry: 'planner--activity--input-country',
            inputCity: 'planner--activity--input-city',
            inputPostalCode: 'planner--activity--input-postal-code',
            inputStreet: 'planner--activity--input-street',
            inputHouseNumber: 'planner--activity--input-house-number',
            inputPremisesNumber: 'planner--activity--input-premises-number',
            inputComments: 'planner--activity--input-comments',
            btnAddNewGood: 'planner--activity--input-add-new-goods',
            checkboxToogleGoods: 'planner--activity--checkbox-toggle-goods',
            hide: {
                address: 'planner--activity--hide--address',
                goods: 'planner--activity--hide--goods',
                puescComments: 'planner--activity--hide--puescComments',
                notifications: 'planner--activity--hide--notifications'
            },
            show: {
                address: 'planner--activity--show--address',
                goods: 'planner--activity--show--goods',
                puescComments: 'planner--activity--show--puescComments',
                notifications: 'planner--activity--show--notifications'
            },
            goods: {
                fieldIndex: 'planner--activity--goods--field-index',
                fieldWasteCode: 'planner--activity--goods--field-waste-code',
                fieldMeasurmentUnit: 'planner--activity--goods--field-measurment-unit',
                fieldGrossWeightVolume: 'planner--activity--goods--field-gross-weight-volume',
                fieldCmrNumber: 'planner--activity--goods--field-cmr-number',
                fieldCnItems: 'planner--activity--goods--field-cn-items',
                fieldName: 'planner--activity--goods--field-name',
                fieldLoading: 'planner--activity--goods--field-loading',
                fieldUnloading: 'planner--activity--goods--field-unloading',
                fieldVolume: 'planner--activity--goods--field-volume',
                inputVatRate: 'planner--activity--goods--input-vat-rate',
                inputCnItems: 'planner--activity--goods--input-cn-items',
                inputCmrNumber: 'planner--activity--goods--input-cmr-number',
                inputWasteCode: 'planner--activity--goods--input-waste-code',
                inputName: 'planner--activity--goods--input-name',
                inputGrossWeightVolume: 'planner--activity--goods--input-gross-weight-volume',
                inputMeasurmentUnit: 'planner--activity--goods--input-measurement-unit',
                btnCancel: 'planner--activity--goods--btn-cancel',
                btnSubmit: 'planner--activity--goods--btn-submit',
                btnEdit: 'planner--activity--goods--btn-edit',
                btnDelete: 'planner--activity--goods--btn-delete'
            }
        },
        puescWarning: 'planner--puesc-warning',
        routeOptions: {
            includeCountry: 'planner--route-options-include-country',
            avoidCountry: 'planner--route-options-avoid-country'
        },
        configurator: {
            inputName: 'planner--configurator--input-name',
            clientName: 'planner--configurator--client-name',
            route: 'planner--configurator--route',
            routeSelected: 'planner--configurator--route-selected',
            fieldCosts: 'planner--configurator--field-costs',
            fieldTollCosts: 'planner--configurator--field-toll-costs',
            fieldDuration: 'planner--configurator--field-duration',
            fieldDistance: 'planner--configurator--field-distance',
            fiedAetrRests: 'planner--configurator--field-aetr-rests',
            selectVehicle: 'planner--configurator--select-vehicle',
            selectTrailer: 'planner--configurator--select-trailer',
            inputCosts: 'planner--configurator--input-costs',
            fieldCurrency: 'planner--configurator--field-currency',
            swtichTollCost: 'planner--configurator--switch-toll-cost',
            transportType: {
                shortest: 'planner--configurator--transport-type-shortest',
                planned: 'planner--configurator--transport-type-planned',
                fastest: 'planner--configurator--transport-type-fastest',
                balanced: 'planner--configurator--transport-type-balanced'
            },
            transportName: {
                inputName: 'planner--configurator--transport-name--input-name',
                btnEdit: 'planner--configurator--transport-name--btn-edit',
                btnCancel: 'planner--configurator--transport-name--btn-cancel',
                btnSave: 'planner--configurator--transport-name--btn-save',
                btnShowRoutes: 'planner--configurator--transport-name--btn-show-routes'
            }
        },
        route: 'planner--configurator--transport-route'
    },
    aetr: {
        bar: {
            btnFilter: 'aetr--bar--btn-filter'
        },
        filter: {
            section: 'aetr--filter--section',
            btnCancel: 'aetr--filter--btn-cancel',
            btnSubmit: 'aetr--filter--btn-submit'
        },
        table: {
            reliable: 'aetr--table--reliable',
            fieldDriver: 'aetr--table--field-driver',
            fieldDoubleCrew: 'aetr--table--field-double-crew',
            fieldRn: 'aetr--table--field-rn',
            fieldCurrentDayDriveTime: 'aetr--table--field-current-day-drive-time',
            fieldCurrentDayTimeLeft: 'aetr--table--field-current-day-time-left',
            fieldWeekDriveTime: 'aetr--table--field-week-drive-time',
            fieldWeekTimeLeft: 'aetr--table--field-week-time-left',
            field14daysDrive: 'aetr--table--field-14-days-drive',
            fieldPreviousWeekRest: 'aetr--table--field-previous-week-rest',
            fieldWeekDaysDriven: 'aetr--table--field-week-days-driven',
            fieldWeekExtendedDrive: 'aetr--table--field-week-extended-drive',
            fieldWeekReducedDailyRest: 'aetr--table--field-week-reduced-daily-rest',
            fieldNextWeekRest: 'aetr--table--field-next-week-rest',
            nextWeeklyRestDuration: 'aetr--table--next-weekly-rest-duration'
        },
        expandedRow: {
            noData: 'aetr--expanded-row--no-data'
        }
    },
    coldChain: {
        bar: {
            btnFilter: 'cold-chain--bar--btn-filter'
        },
        table: {
            fieldName: 'cold-chain--table--field-name',
            fieldZone: 'cold-chain--table--field-zone',
            fieldType: 'cold-chain--table--field-type',
            fieldAssignedToVehicle: 'cold-chain--table--field-assigned-to-icon',
            fieldAssignedToTrailer: 'cold-chain--table--field-assigned-to-icon',
            fieldAssignedToRn: 'cold-chain--table--field-assigned-to-rn',
            fieldStatus: 'cold-chain--table--field-status',
            fieldTemperature: 'cold-chain--table--field-cargo-temperature',
            fieldAlertsConfiguration: 'cold-chain--table--field-alerts-configuration',
            fieldAlertsTemplate: 'cold-chain--table--field-alerts-template',
            btnOpenDetails: 'cold-chain--table--btn-open-details',
            btnAlerts: 'cold-chain--table--btn-alerts',
            fieldProfile: 'cold-chain--table--field-profile',
            fieldTemperatureAbove: 'cold-chain--table--field-temperature-above',
            fieldTemperatureBelow: 'cold-chain--table--field-temperature-below',
            fieldAlarmTimerSeconds: 'cold-chain--table--field-alarm-timer-seconds',
            headingProfile: 'cold-chain--table--heading-profile',
            headingZone: 'cold-chain--table--heading-zone',
            headingName: 'cold-chain--table--heading-name',
            headingType: 'cold-chain--table--heading-type',
            headingTemperature: 'cold-chain--table--heading-temperature',
            headingRn: 'cold-chain--table--heading-rn'
        },
        alertProfilesTable: {
            fieldName: 'cold-chain--table-alert-profiles--field-name',
            fieldAlertsConfiguration: 'cold-chain--table-alert-profiles--field-alerts-configuration',
            btnEdit: 'cold-chain--table-alert-profiles--btn-edit',
            btnDuplicate: 'cold-chain--table-alert-profiles--btn-duplicate',
            btnDelete: 'cold-chain--table-alert-profiles--btn-delete'
        },
        filter: {
            btnSubmit: 'cold-chain--filter--btn-submit',
            btnCancel: 'cold-chain--filter--btn-cancel',
            section: 'cold-chain--filter--section',
            sectionTrailers: 'cold-chain--filter--section-trailers',
            sectionVehicles: 'cold-chain--filter--section-vehicles',
            sectionProfiles: 'cold-chain--filter--section-profiles',
            checkboxTrailer: 'cold-chain--filter--checkbox-trailer',
            checkboxProfile: 'cold-chain--filter--checkbox-profile',
            checkboxVehicle: 'cold-chain--filter--checkbox-vehicle'
        },
        alertFilter: {
            btnSubmit: 'cold-chain--alert-filter--btn-submit',
            btnCancel: 'cold-chain--alert-filter--btn-cancel',
            section: 'cold-chain--alert-filter--section'
        },
        detail: {
            graph: {
                btnDateRange: 'cold-chain--detail--graph--btn-date-range'
            }
        },
        profileForm: {
            name: 'coldchain--profile-form-name',
            aboveTemperatureThreshold: 'coldchain--profile-form-aboveTemperatureThreshold',
            aboveTemperatureThresholdCheckbox: 'coldchain--profile-form-aboveTemperatureThreshold-checkbox',
            belowTemperatureThreshold: 'coldchain--profile-form-belowTemperatureThreshold',
            belowTemperatureThresholdCheckbox: 'coldchain--profile-form-belowTemperatureThreshold-checkbox',
            alarmTimerSeconds: 'coldchain--profile-form-alarmTimerSeconds',
            alarmTimerSecondsCheckbox: 'coldchain--profile-form-alarmTimerSeconds-checkbox',
            listItem: 'coldchain--profile-item',
            infoButton: 'coldchain--profile-item-info',
            editButton: 'coldchain--profile-item-edit',
            deleteButton: 'coldchain--profile-item-delete'
        },
        btnExport: 'coldchain-btn-export',
        sensor: {
            btnCancel: 'cold-chain--sensor-cancel',
            btnEdit: 'cold-chain--sensor-edit',
            btnSave: 'cold-chain--sensor-save',
            fieldSensorName: 'cold-chain--sensor-field-semsor-name'
        }
    },
    vehicleGroups: {
        selectVehicleGroup: 'vehicle-groups--select-vehicle-group',
        form: {
            inputNewGroupName: 'vehicle-groups--form--input-new-group',
            btnNewGroup: 'vehicle-groups--form--btn-new-group',
            btnNewGroupSave: 'vehicle-groups--form--btn-new-group-save',
            btnNewGroupSaveDisabled: 'vehicle-groups--form--btn-new-group-save-disabled',
            btnNewGroupCancel: 'vehicle-groups--form--btn-new-group-cancel',
            inputEditGroupName: 'vehicle-groups--form--input-new-group',
            btnEditGroup: 'vehicle-groups--form--btn-edit-group',
            btnEditGroupSave: 'vehicle-groups--form--btn-edit-group-save',
            btnEditGroupCancel: 'vehicle-groups--form--btn-edit-group-cancel',
            btnDeleteGroup: 'vehicle-groups--form--btn-delete-group',
            fieldGroupName: 'vehicle-groups--form--field-group-name'
        }
    },
    fleet: {
        btnVehicleNew: 'fleet--vehicle-new',
        btnTrailerNew: 'fleet--trailer-new',
        btnVehiclesImport: 'fleet--vehicles-import',
        bar: {
            btnVehicleGroups: 'fleet--bar--btn-vehicle-groups',
            btnFilter: 'fleet--bar--btn-filter'
        },
        filter: {
            section: 'fleet--filter--section',
            fleetType: 'fleet--filter--fleet-type',
            radioActive: 'fleet--filter--radio-active',
            radioDischarged: 'fleet--filter--radio-discharged',
            radioActiveAll: 'fleet--filter--radio-active-all',
            radioTypeAll: 'fleet--filter--radio-type-all',
            btnConfirm: 'fleet--filter--btn-confirm',
            btnCancel: 'fleet--filter--btn-cancel'
        },
        detail: {
            inputRN: 'fleet--detail--input-rn',
            inputRNErr: 'fleet--detail--input-rn-err',
            inputName: 'fleet--detail--input-name',
            inputNameErr: 'fleet--detail--input-name-err',
            inputVin: 'fleet--detail--input-vin',
            selectTunnelType: 'fleet--detail--select-tunnel-type',
            selectTunnelTypeErr: 'fleet--detail--select-tunnel-type-err',
            selectEmisionClass: 'fleet--detail--select-emission-class',
            selectEmisionClassErr: 'fleet--detail--select-emission-class-err',
            inputAxlesCount: 'fleet--detail--input-axels-count',
            inputAxlesCountErr: 'fleet--detail--input-axels-count-err',
            inputWeight: 'fleet--detail--input-weight',
            inputWeightErr: 'fleet--detail--input-weight-err',
            inputLength: 'fleet--detail--input-length',
            inputLengthErr: 'fleet--detail--input-length-err',
            inputWidth: 'fleet--detail--input-width',
            inputWidthErr: 'fleet--detail--input-width-err',
            inputHeight: 'fleet--detail--input-height',
            inputHeightErr: 'fleet--detail--input-height-err',
            inputTank: 'fleet--detail--input-tank',
            inputTankErr: 'fleet--detail--input-tank-err',
            inputOptimalRpm: 'fleet--detail--input-optimal-rpm',
            selectFuelType: 'fleet--detail--select-fuel-type',
            selectFuelTypeErr: 'fleet--detail--select-fuel-type-err',
            fieldRN: 'fleet--detail--field-rn',
            fieldName: 'fleet--detail--field-name',
            fieldType: 'fleet--detail--field-type',
            fieldVin: 'fleet--detail--field-vin',
            fieldManufacturer: 'fleet--detail--field-manufacturer',
            fieldTrailerType: 'fleet--detail--field-trailer-type',
            fieldCountryOfRegistration: 'fleet--detail--field-country-of-registration',
            fieldTypeOfLoad: 'fleet--detail--field-type-of-load',
            fieldTrailerIdSerialNumber: 'fleet--detail--field-trailer-id-serial-number',
            fieldTunnel: 'fleet--detail--field-tunnel',
            fieldEmissionClass: 'fleet--detail--field-emission-class',
            fieldNumberOfAxles: 'fleet--detail--field-number-of-axles',
            fieldWeight: 'fleet--detail--field-weight',
            fieldFullWeight: 'fleet--detail--field-full-weight',
            fieldTankSize: 'fleet--detail--field-tank-size',
            fieldFuelType: 'fleet--detail--field-fuel-type',
            fieldLength: 'fleet--detail--field-length',
            fieldUnitSerialNumber: 'fleet--detail--field-unit-sn',
            fieldWidth: 'fleet--detail--field-width',
            fieldHeight: 'fleet--detail--field-height',
            fieldOptimalRpm: 'fleet--detail--field-optimal-rpm',
            tag: 'fleet--detail--tag',
            btnFormSubmit: 'fleet--detail--btn-submit',
            btnFormCancel: 'fleet--detail--btn-cancel',
            btnNewVehicle: 'fleet--detail--btn-new-vehicle',
            btnNewTrailer: 'fleet--detail--btn-new-trailer',
            btnImportVehicles: 'fleet--detail--btn-import-vehicles',
            btnTags: 'fleet--detail--btn-tags',
            btnServices: 'fleet--detail--btn-services',
            btnUpdate: 'fleet--detail--btn-update',
            btnAddLinkedItem: 'fleet--detail--btn-add-linked-item',
            btnOpenServiceModule: 'fleet--detail--btn-open-service-module',
            btnOperationalCosts: 'fleet--detail--btn-operational-costs',
            btnLocateOnMap: 'fleet--detail--btn-locate-on-map',
            btnDelete: 'fleet--detail--btn-delete',
            btnActivateVehicle: 'fleet--detail--btn-activate-vehicle',
            btnDisableVehicle: 'fleet--detail--btn-disable-vehicle'
        },
        table: {
            vehicle: 'fleet--table--vehicle',
            lightVehicle: 'fleet--table--vehicle',
            trailer: 'fleet--table--trailer',
            fieldRN: 'fleet--table--field-rn',
            fieldName: 'fleet--table--field-name',
            fieldManufacturer: 'fleet--table--field-manufacturer',
            fieldDefaultDriver: 'fleet--table--field-default-driver',
            fieldToll: 'fleet--table--field-toll',
            fieldFuelCards: 'fleet--table--field-fuel-card',
            type: {
                vehicle: 'fleet--table--type--vehicle',
                light_vehicle: 'fleet--table--type--light-vehicle',
                trailer: 'fleet--table--type--trailer'
            },
            module: {
                0: 'fleet-table--module--0',
                1: 'fleet-table--module--1',
                2: 'fleet-table--module--2',
                3: 'fleet-table--module--3',
                4: 'fleet-table--module--4',
                5: 'fleet-table--module--5',
                6: 'fleet-table--module--6',
                7: 'fleet-table--module--7',
                8: 'fleet-table--module--8',
                9: 'fleet-table--module--9',
                10: 'fleet-table--module--10',
                11: 'fleet-table--module--11',
                12: 'fleet-table--module--12',
                13: 'fleet-table--module--13',
                14: 'fleet-table--module--14',
                15: 'fleet-table--module--15',
                16: 'fleet-table--module--16',
                17: 'fleet-table--module--17',
                18: 'fleet-table--module--18',
                19: 'fleet-table--module--19',
                20: 'fleet-table--module--20',
                21: 'fleet-table--module--21',
                22: 'fleet-table--module--22',
                23: 'fleet-table--module--23',
                24: 'fleet-table--module--24',
                25: 'fleet-table--module--25',
                26: 'fleet-table--module--26'
            },
            btnUnpairTrailer: 'fleet--table--btn-unpair-trailer',
            tagGroup: 'fleet--table--tag-group',
            tagModule: 'fleet--table--tag-module'
        },
        fleetVehicleTags: {
            cancelAddEditTag: 'fleet-tags--cancel-add-edit-tags',
            saveAddedTag: 'fleet-tags--save-add-tags',
            saveUpdatedTag: 'fleet-tags--save-updated-tags',
            newTagGroup: 'fleet-tags--new-tag-group',
            cancelAll: 'fleet-tags-cancel-all',
            resertAll: 'fleet-tags--reset-all',
            confirm: 'fleet-tags--confirm',
            tagNewInput: 'fleet-tags--tag-input',
            tagNewErr: 'fleet-tags--tag-err',
            tagEditInput: 'fleet-tags--tag-input',
            tagEditErr: 'fleet-tags--tag-err',
            tagGroupNewInput: 'fleet-tags--tag-group-err',
            tagGroupNewErr: 'fleet-tags--tag-group-err',
            tagGroupEditInput: 'fleet-tags--tag-group-err',
            tagGroupEditErr: 'fleet-tags--tag-group-err',
            group: {
                btnName: 'fleet--tags--group--btn-name',
                btnEdit: 'fleet--tags--group--btn-edit',
                btnDelete: 'fleet--tags--group--btn-delete',
                delete: {
                    btnCancel: 'fleet--tags--group--delete--btn-cancel',
                    btnConfirm: 'fleet--tags--group--delete--btn-confirm'
                }
            }
        },
        form: {
            btnSubmit: 'fleet--form--btn-submit',
            btnCancel: 'fleet--form--btn-cancel'
        },
        trailer: {
            inputName: 'fleet--create-trailer--input-name',
            inputNameErr: 'fleet--create-trailer--input-name-err',
            inputRn: 'fleet--create-trailer--input-rn',
            inputRnErr: 'fleet--create-trailer--input-rn-err',
            inputManufacturer: 'fleet--create-trailer--input-manufacturer',
            inputManufacturerErr: 'fleet--create-trailer--input-manufacturer-err',
            inputCargoType: 'fleet--create-trailer--input-cargo-type',
            inputCargoTypeErr: 'fleet--create-trailer--input-cargo-type-err',
            inputTrailerType: 'fleet--create-trailer--input-trailer-type',
            inputTrailerTypeErr: 'fleet--create-trailer--input-trailer-type-err',
            inputHeight: 'fleet--create-trailer--input-height',
            inputHeightErr: 'fleet--create-trailer--input-height-err',
            inputLength: 'fleet--create-trailer--input-length',
            inputLengthErr: 'fleet--create-trailer--input-length-err',
            inputWidth: 'fleet--create-trailer--input-width',
            inputWidthErr: 'fleet--create-trailer--input-width-err',
            inputWeight: 'fleet--create-trailer--input-weight',
            inputWeightErr: 'fleet--create-trailer--input-weight-err',
            inputFullWeight: 'fleet--create-trailer--input-full-weight',
            inputFullWeightErr: 'fleet--create-trailer--input-full-weight-err',
            btnSubmit: 'fleet--create-trailer--btn-submit',
            btnCancel: 'fleet--create-trailer--btn-cancel'
        },
        pairing: {
            fieldRn: 'fleet--pairing--field-rn',
            type: {
                fuelCard: 'fleet--pairing--type--fuel-card',
                fleet: 'fleet--pairing--type--fleet',
                vehicles: 'fleet--pairing--type--vehicles',
                trailers: 'fleet--pairing--type--trailers',
                defaultDriver: 'fleet--pairing--type--default-driver'
            },
            item: {
                btnFleet: 'fleet--pairing--item--btn-fleet',
                btnFuelCard: 'fleet--pairing--item--btn-fuel-card',
                btnDriver: 'fleet--pairing--item--btn-driver'
            },
            btnSubmit: 'fleet--pairing--item--btn-submit',
            btnCancel: 'fleet--pairing--item--btn-cancel'
        },
        linkedItems: {
            sectionVehicle: 'fleet--linked-items--section-vehicle',
            vehicle: {
                rn: 'fleet--linked-items--vehicle--rn'
            },
            sectionTrailer: 'fleet--linked-items--section-trailer',
            trailer: {
                rn: 'fleet--linked-items--trailer--rn'
            },
            sectionFuelCard: 'fleet--linked-items--section-fuel-card',
            fuelCard: {
                name: 'fleet--linked-items--fuel-card--name'
            },
            sectionDefaultDriver: 'fleet--linked-items--section-default-driver',
            defaultDriver: {
                name: 'fleet--linked-items--default-driver--name'
            },
            fieldRn: 'fleet--linked-items--field-rn',
            fieldLocationWhenPaired: 'fleet--linked-items--field-location-when-paired',
            fieldDateOfPairing: 'fleet--linked-items--field-date-of-pairing',
            fieldType: 'fleet--linked-items--field-type'
        }
    },
    contacts: {
        btnNewContact: 'contacts--btn-new-contact',
        btnFilter: 'contacts--btn-filter',
        table: {
            fieldName: 'contacts--table--field-name',
            fieldBussinessId: 'contacts--table--field-bussiness-id',
            fieldVat: 'contacts--table--field-vat',
            fieldTaxId: 'contacts--table--field-tax-id',
            fieldOtherNotificationNm: 'contacts--table--field-other-notification-nm',
            fieldTypeClient: 'contacts--table--field-name-type-client',
            fieldTypeConsignee: 'contacts--table--field-name-type-consignee',
            fieldTypeSupplier: 'contacts--table--field-name-type-supplier',
            fieldCountry: 'contacts--table--field-country',
            fieldCity: 'contacts--table--field-city',
            fieldPostalCode: 'contacts--table--field-postal-code',
            fieldStreet: 'contacts--table--field-street',
            fieldHouseNumber: 'contacts--table--field-house-number',
            fieldPremisesNumber: 'contacts--table--field-premises-number'
        },
        detail: {
            fieldName: 'contacts--detail--field-name',
            fieldBussinessId: 'contacts--detail--field-bussiness-id',
            fieldVat: 'contacts--detail--field-vat',
            fieldTaxId: 'contacts--detail--field-tax-id',
            fieldOtherNotificationNm: 'contacts--detail--field-other-notification-nm',
            fieldTypeClient: 'contacts--detail--field-name-type-client',
            fieldTypeSupplier: 'contacts--detail--field-name-type-supplier',
            fieldTypeConsignee: 'contacts--detail--field-name-type-consignee',
            fieldCountry: 'contacts--detail--field-country',
            fieldCity: 'contacts--detail--field-city',
            fieldPostalCode: 'contacts--detail--field-postal-code',
            fieldStreet: 'contacts--detail--field-street',
            fieldHouseNumber: 'contacts--detail--field-house-number',
            fieldPremisesNumber: 'contacts--detail--field-premises-number'
        },
        form: {
            inputName: 'contacts--form--input-name',
            inputBussinessId: 'contacts--form--input-bussiness-id',
            inputVat: 'contacts--form--input-vat',
            inputTaxId: 'contacts--form--input-tax-id',
            inputOtherNotificationNm: 'contacts--form--input-other-notification-nm',
            inputTypeClient: 'contacts--form--input-name-type-client',
            inputTypeSupplier: 'contacts--form--field-name-type-supplier',
            inputCountry: 'contacts--form--input-country',
            inputCity: 'contacts--form--input-city',
            inputPostalCode: 'contacts--form--input-postal-code',
            inputStreet: 'contacts--form--input-street',
            inputHouseNumber: 'contacts--form--input-house-number',
            inputPremisesNumber: 'contacts--form--input-premises-number',
            btnCancel: 'contacts--form--btn-cancel',
            btnSubmit: 'contacts--form--btn-submit'
        },
        filter: {
            inputTypeClient: 'contacts--filter--input-type-client',
            inputTypeSupplier: 'contacts--filter--input-type-supplier',
            inputTypeAll: 'contacts--filter--input-type-all',
            inputCountryAll: 'contacts--filter--input-country-all',
            btnCancel: 'contacts--filter--btn-cancel',
            btnConfirm: 'contacts--filter--btn-confirm'
        }
    },
    users: {
        btnNew: 'users--btn-new',
        btnImport: 'users--btn-import',
        table: {
            fieldName: 'users--table--field-name',
            fieldSurname: 'users--table--field-surname',
            fieldEmail: 'users--table--field-email',
            fieldPhone: 'users--table--field-phone',
            fieldDefaultVehicle: 'users--table--field-default-vehicle',
            fieldRoles: 'users--table--field-roles',
            fieldVehicleGroups: 'users--table--field-vehicle-groups'
        },
        detail: {
            btnUpdate: 'users--detail--btn-update',
            btnDelete: 'users--detail--btn-delete',
            btnAddLinkedItem: 'users--detail--btn-add-linked-item',
            btnInviteEWFleet: 'users--detail--btn-invite-ew-fleet',
            btnPlaceOfWorkAdd: 'users--detail--btn-place-of-work-add',
            btnVehicleGroupAdd: 'users--detail--btn-vehicle-group-add',
            fieldName: 'users--detail--field-name',
            fieldSurname: 'users--detail--field-surname',
            fieldEmail: 'users--detail--field-email',
            fieldPhone: 'users--detail--field-phone',
            fieldPin: 'users--detail--field-pin',
            fieldRoles: 'users--detail--field-roles',
            fieldVehicleGroups: 'users--detail--field-vehicle-groups',
            sectionPlaceOfWork: 'users--detail--section-place-of-work',
            placeOfWork: {
                fieldName: 'users--detail--place-of-work--field-name',
                btnDelete: 'users--detail--place-of-work--btn-delete'
            }
        },
        form: {
            btnFormSubmit: 'users--form--btn-submit',
            btnFormCancel: 'users--form--btn-cancel',
            inputName: 'users--form--input-name',
            inputSurname: 'users--form--input-surname',
            inputEmail: 'users--form--input-email',
            inputPhone: 'users--form--input-phone',
            inputUsername: 'users--form--input-username',
            inputPin: 'users--form--input-pin',
            inputRoles: 'users--form--input-roles',
            roles: {
                dispatcher: 'users--form--roles--dispatcher',
                driver: 'users--form--roles--driver',
                owner: 'users--form--roles--owner'
            }
        },
        pairing: {
            btnConfirm: 'users--pairing--btn-confirm',
            btnCancel: 'users--pairing--btn-cancel',
            btnItemTacho: 'users--pairing--btn-item-tacho',
            btnItemVehicle: 'users--pairing--btn-item-vehicle',
            sectionTypes: 'users--pairing--section-types',
            sectionItems: 'users--pairing--section-items'
        },
        linkedItems: {
            sectionApp: 'users--linked-items--section-app',
            sectionTachoCard: 'users--linked-items--section-tacho-card',
            sectionDefaultVehicle: 'users--linked-items--section-default-vehicle',
            fieldToken: 'users--linked-items--field-token',
            fieldRn: 'users--linked-items--field-rn'
        },
        addPlaceOfWork: {
            sectionPlace: 'users--detail--section-place-of-work',
            place: {
                fieldName: 'users--detail--place-of-work--field-name',
                btnDelete: 'users--detail--place-of-work--btn-delete'
            }
        }
    },
    roles: {
        detail: {
            btnUpdate: 'roles--detail--btn-update',
            btnCreate: 'roles--detail--btn-create',
            btnDelete: 'roles--detail--btn-delete',
            btnFormSubmit: 'roles--detail--btn-submit',
            btnFormCancel: 'roles--detail--btn-cancel',
            inputName: 'roles--detail--input-name',
            inputLabel: 'roles--detail--input-label',
            inputDescription: 'roles--detail--input-desription',
            inputRole: 'roles--detail--input-group',
            sectionRights: 'roles--detail--block-rights'
        }
    },
    fuel: {
        btnNew: 'fuel--btn-new',
        bar: {
            btnExport: 'fuel--bar--btn-export',
            btnFilter: 'fuel--bar--btn-filter'
        },
        table: {
            fieldNumber: 'fuel--table--field-number',
            fieldHolder: 'fuel--table--field-holder',
            fieldCompany: 'fuel--table--field-company',
            fieldExpDate: 'fuel--table--field-exp-date'
        },
        new: {
            btnFormSubmit: 'tacho--new--btn-submit',
            btnFormCancel: 'tacho--new--btn-cancel',
            inputNumber: 'fuel--new--input-number',
            inputHolder: 'fuel--new--input-holder',
            selectCompany: 'fuel--new--input-company',
            inputExpDate: 'fuel--new--input-exp-date',
            inputNote: 'fuel--new--input-note'
        },
        detail: {
            btnUpdate: 'fuel--detail--btn-update',
            btnAddLinkedItem: 'fuel--detail--btn-add-linked-item',
            btnDelete: 'fuel--detail--btn-delete',
            btnFormSubmit: 'fuel--detail--btn-submit',
            btnFormCancel: 'fuel--detail--btn-cancel',
            fieldNumber: 'fuel--detail--field-number',
            fieldHolder: 'fuel--detail--field-holder',
            fieldCompany: 'fuel--detail--field-company',
            fieldExpDate: 'fuel--detail--field-exp-date',
            fieldNote: 'fuel--detail--field-note',
            inputNumber: 'fuel--detail--input-number',
            inputHolder: 'fuel--detail--input-holder',
            selectCompany: 'fuel--detail--input-company',
            inputExpDate: 'fuel--detail--input-exp-date',
            inputNote: 'fuel--detail--input-note'
        },
        pairing: {
            btnFormSubmit: 'fuel--pairing--btn-submit',
            btnFormCancel: 'fuel--pairing--btn-cancel',
            btnTypeFleet: 'fuel--pairing--btn-type-fleet',
            btnTypeVehicles: 'fuel--pairing--btn-type-vehicles',
            btnTypeTrailers: 'fuel--pairing--btn-type-trailers',
            btnTypeUser: 'fuel--pairing--btn-type-user',
            btnItemVehicles: 'fuel--pairing--btn-item-vehicles',
            btnItemUser: 'fuel--pairing--btn-item-user',
            fieldNoData: 'fuel--pairing--field-no-data'
        },
        linkedItems: {
            sectionVehicle: 'fuel--linked-items--section-vehicle',
            sectionUser: 'fuel--linked-items--section-user',
            sectionDefaultDriver: 'fuel--linked-items--section-default-driver',
            fieldRn: 'fuel--linked-items--field-rn',
            fieldType: 'fuel--linked-items--field-type',
            fieldName: 'fuel--linked-items--field-name'
        }
    },
    fuelConsumption: {
        filter: {
            section: 'fuel--filter--section',
            btnCancel: 'fuel--filter--btn-cancel',
            btnSubmit: 'fuel--filter--btn-submit',
            groupDrivers: 'fuel--filter--group-drivers',
            groupVehicles: 'fuel--filter--group-vehicles',
            checkboxShortActivity: 'fuel--filter--checkbox-short-activity',
            checkboxSuspiciousActivity: 'fuel--filter--checkbox-suspicious-activity'
        }
    },
    journeys: {
        bar: {
            btnExpanded: 'journeys--bar--btn-expanded'
        },
        filter: {
            inputTrailer: 'journeys--filter--input-trailer',
            inputTrailerCancel: 'journeys--filter--input-trailer-cancel',
            inputVehicle: 'journeys--filter--input-vehicle',
            inputVehicleCancel: 'journeys--filter--input-vehicle-cancel',
            inputDriver: 'journeys--filter--input-driver',
            inputDriverCancel: 'journeys--filter--input-vehicle-cancel',
            btnExport: 'journeys--filter--btn-export',
            btnExportXls: 'journeys--filter--btn-export-xls'
        },
        summary: {
            btnFuelConsumption: 'journeys--filter--btn-fuel-consumption',
            btnExpenses: 'journeys--filter--btn-expenses',
            fieldStopped: 'journeys--filter--field-stopped',
            fieldDuration: 'journeys--filter--field-duration',
            fieldDistance: 'journeys--filter--field-distance',
            fieldOdometerEnd: 'journeys--filter--field-odometer-end',
            fieldAfc: 'journeys--filter--field-afc',
            fieldConsumption: 'journeys--filter--field-consumption',
            fieldUnits: 'journeys--filter--field-units',
            fieldPrice: 'journeys--filter--field-price',
            fieldCo2: 'journeys--filter--field-co2',
            fieldBusinessDistance: 'journeys--filter--field-business-distance',
            fieldPrivateDistance: 'journeys--filter--field-private-distance'
        },
        graph: {
            btnColdChain: 'journeys--graph--btn-cold-chain',
            btnSpeed: 'journeys--graph--btn-speed',
            btnFuelLevels: 'journeys--graph--btn-fuel-levels',
            btnClose: 'journeys--graph--btn-close',
            sectionGraphColdChain: 'journeys--graph--section-graph-cold-chain',
            sectionGraphSpeed: 'journeys--graph--section-graph-speed',
            sectionGraphFuelLevels: 'journeys--graph--section-graph-fuel-levels'
        },
        table: {
            sectionDay: 'journeys--table--section-day',
            fieldDate: 'journeys--table--field-date',
            fieldExpensesCount: 'journeys--table--field-expenses-count',
            fieldRidesCount: 'journeys--table--field-rides-count',
            fieldPlaceStart: 'journeys--table--field-place-start',
            fieldPlaceEnd: 'journeys--table--field-place-end',
            sectionActivities: 'journeys--table--section-activities',
            sectionActivity: 'journeys--table--section-activity',
            sectionActivitySum: 'journeys--table--section-activity-sum',
            fieldDriver: 'journeys--table--field-driver',
            fieldDriver2: 'journeys--table--field-driver-2',
            fieldVehicle: 'journeys--table--field-vehicle',
            fieldSumDuration: 'journeys--table--field-sum-duration',
            fieldSumStopped: 'journeys--table--field-sum-stopped',
            fieldSumDistance: 'journeys--table--field-sum-distance',
            fieldSumOdometerEnd: 'journeys--table--field-sum-odometer-end',
            fieldSumUnits: 'journeys--table--field-sum-units',
            fieldSumPrice: 'journeys--table--field-sum-price',
            fieldTrailer: 'journeys--table--field-trailer',
            btnEdit: 'journeys--table--btn-edit',
            day: {
                fieldActivitiesCount: 'journeys--table--day--field-journeys-count',
                fieldExpensesCount: 'journeys--table--day--field-expenses-count',
                btnAddExpense: 'journeys--table--day--btn-add-expenses',
                btnExpand: 'journeys--table--day--btn-expand'
            }
        }
    },
    tacho: {
        btnNew: 'tacho--btn-new',
        table: {
            fieldToken: 'tacho--table--field-token',
            fieldName: 'tacho--table--field-name',
            fieldSurname: 'tacho--table--field-surname',
            fieldExpDate: 'tacho--table--field-exp-date'
        },
        new: {
            btnFormSubmit: 'tacho--new--btn-submit',
            btnFormCancel: 'tacho--new--btn-cancel',
            inputToken: 'tacho--new--input-token',
            inputCountry: 'tacho--new--input-country',
            inputExpDate: 'tacho--new--input-exp-date'
        },
        detail: {
            btnUpdate: 'tacho--detail--btn-update',
            btnAddLinkedItem: 'tacho--detail--btn-add-linked-item',
            btnDelete: 'tacho--detail--btn-delete',
            inputToken: 'tacho--detail--input-token',
            inputExpDate: 'tacho--detail--input-exp-date',
            btnFormSubmit: 'tacho--detail--btn-submit',
            btnFormCancel: 'tacho--detail--btn-cancel',
            fieldTitle: 'tacho--detail--field-title',
            fieldExpDate: 'tacho--detail--field-exp-date',
            fieldToken: 'tacho--detail--field-token',
            fieldName: 'tacho--detail--field-name',
            fieldSurname: 'tacho--detail--field-surname'
        },
        pairing: {
            btnFormSubmit: 'tacho--pairing--btn-submit',
            btnFormCancel: 'tacho--pairing--btn-cancel',
            inputUserFromList: 'tacho--pairing-input-user-from-list',
            inputNewUser: 'tacho--pairing-input-new-user',
            btnType: 'tacho--pairing--btn-type',
            btnItem: 'tacho--pairing--btn-item'
        },
        linkedItems: {
            sectionUser: 'tacho--linked-items--section-user',
            fieldKey: 'tacho--linked-items--field-key',
            fieldName: 'tacho--linked-items--field-name'
        }
    },
    driverBehaviour: {
        nav: {
            fieldCompareName: 'driver-behaviour--nav--field-compare-name',
            fieldName: 'driver-behaviour--nav--field-name',
            fieldMonth: 'driver-behaviour--nav--field-month',
            tabs: {
                tabBasicReport: 'driver-behaviour--nav-tab-basic-report',
                tabCoachReport: 'driver-behaviour--nav-tab-coach-report'
            }
        },
        filter: {
            btnTrucks: 'driver-behaviour--filter-btn-trucks',
            btnLight: 'driver-behaviour--filter-btn-light',
            inputDriver: 'driver-behavior--filter-input-driver'
        },
        side: {
            fieldRank: 'driver-behaviour--side--field-rank',
            fieldName: 'driver-behaviour--side--field-name',
            fieldScore: 'driver-behaviour--side--field-score',
            fieldTrend: 'driver-behaviour--side--field-trend',
            topDrivers: 'driver-behaviour--side--top-drivers',
            topImprovers: 'driver-behaviour--side--top-improvers',
            btnTopImprovers: 'driver-behaviour--side--btn-top-improvers',
            worstDrivers: 'driver-behaviour--side--worst-drivers',
            btnWorstDrivers: 'driver-behaviour--side--btn-worst-drivers'
        },
        light: {
            table: {
                headingScore: 'driver-behaviour--light-table--heading-score',
                headingEconomy: 'driver-behaviour--light-table--heading-economy',
                headingEcology: 'driver-behaviour--light-table--heading-ecology',
                headingSafety: 'driver-behaviour--light-table--heading-safety',
                fieldRank: 'driver-behaviour--light-table--field-rank',
                fieldName: 'driver-behaviour--light-table--field-name',
                fieldScore: 'driver-behaviour--light-table--field-score',
                fieldEconomy: 'driver-behaviour--light-table--field-economy',
                fieldEcology: 'driver-behaviour--light-table--field-ecology',
                fieldSafety: 'driver-behaviour--light-table--field-safety'
            },
            detail: {
                fieldScore: 'driver-behaviour--light-detail--field-score',
                fieldEconomy: 'driver-behaviour--light-detail--field-economy',
                fieldEcology: 'driver-behaviour--light-detail--field-ecology',
                fieldSafety: 'driver-behaviour--light-detail--field-safety',
                distance: 'driver-behaviour--light-detail--section-distance',
                rank: 'driver-behaviour--light-detail--section-rank',
                total: 'driver-behaviour--light-detail--section-total',
                economy: {
                    dangerousBrakingPenalty: 'driver-behaviour--light-detail--section-economy--braking',
                    cityAggresiveTakeoffPenalty: 'driver-behaviour--light-detail--section-economy--acceleration',
                    countryDangerousTurnPenalty: 'driver-behaviour--light-detail--section-economy--country-cornering',
                    highwayDangerousTurnPenalty: 'driver-behaviour--light-detail--section-economy--highway-cornering'
                },
                ecology: 'driver-behaviour--light-detail--section-ecology',
                safety: 'driver-behaviour--light-detail--section-safety'
            }
        },
        trucks: {
            table: {
                headingScore: 'driver-behaviour--trucks-table--heading-score',
                headingDrive: 'driver-behaviour--trucks-table--heading-driver',
                headingEco: 'driver-behaviour--trucks-table--heading-eco',
                headingWearTear: 'driver-behaviour--trucks-table--heading-wear-tear',
                fieldRank: 'driver-behaviour--trucks-table--field-rank',
                fieldName: 'driver-behaviour--trucks-table--field-name',
                fieldScore: 'driver-behaviour--score',
                fieldDrive: 'driver-behaviour--drive-score',
                fieldDriveKm: 'driver-behaviour--drive-score-km',
                fieldEco: 'driver-behaviour--eco-score',
                fieldWearTear: 'driver-behaviour--wear-tear-score'
            },
            detail: {
                fieldScore: 'driver-behaviour--trucks-detail--field-score',
                fieldDrive: 'driver-behaviour--trucks-detail--field-drive',
                fieldEco: 'driver-behaviour--trucks-detail--field-eco',
                fieldWearTear: 'driver-behaviour--trucks-detail--field-wear-tear',
                distance: 'driver-behaviour--trucks-detail--section-distance',
                rank: 'driver-behaviour--trucks-detail--section-rank',
                total: 'driver-behaviour--trucks-detail--section-total',
                drive: {
                    fullDistance: 'driver-behaviour--trucks-detail--drive-mileage',
                    driverTime: 'driver-behaviour--trucks-detail--drive-driving-time',
                    consumption: 'driver-behaviour--trucks-detail--drive-consumption',
                    avgSpeed: 'driver-behaviour--trucks-detail--drive-average-speed',
                    collapse: 'driver-behaviour--trucks-detail--drive-collapse'
                },
                eco: {
                    idleConsumption: 'driver-behaviour--trucks-detail--eco-idle-consumption',
                    rpmOverTime: 'driver-behaviour--trucks-detail--eco-rpm-over-time',
                    constantAccelerationTime: 'driver-behaviour--trucks-detail--eco-constant-acceleration',
                    driveTime85: 'driver-behaviour--trucks-detail--eco-driving-time-in-85',
                    collapse: 'driver-behaviour--trucks-detail--eco-collapse'
                },
                wearTear: {
                    avgParkingBreakCount: 'driver-behaviour--trucks-detail--wear-tear-parking-brake-activation',
                    avgRetarderCount: 'driver-behaviour--trucks-detail--wear-tear-retarder-activation',
                    avgServiceBrakeCount: 'driver-behaviour--trucks-detail--wear-tear-brake-activation',
                    serviceBrakeVsRetarderPercentage:
                        'driver-behaviour--trucks-detail--wear-tear-ratio-brake-to-retarder',
                    collapse: 'driver-behaviour--trucks-detail--wear-tear-collapse'
                }
            }
        },
        btnCollapse: 'driver-behavior--btn-collapse',
        btnExport: 'driver-behavior--btn-export'
    },
    borderCrossing: {
        filter: {
            inputVehicle: 'border-crossing--table--input-vehicle',
            inputVehicleCancel: 'border-crossing--table--input-vehicle-cancel',
            inputDriver: 'border-crossing--table--input-driver',
            inputDriverCancel: 'border-crossing--table--input-drive-cancel',
            inputCountry: 'border-crossing--table--input-country',
            inputCountryCancel: 'border-crossing--table--input-country-cancel',
            fieldFlag: 'border-crossing--table--field-flag',
            btnExport: 'border-crossing--table--btn-export'
        },
        table: {
            fieldCountry: 'border-crossing--table--field-country',
            fieldDriver: 'border-crossing--table--field-driver',
            fieldVehicle: 'border-crossing--table--field-vehicle',
            fieldEntryDate: 'border-crossing--table--field-entry-date',
            fieldEntryLocation: 'border-crossing--table--field-entry-location',
            fieldExitDate: 'border-crossing--table--field-exit-date',
            fieldExitLocation: 'border-crossing--table--field-exit-location',
            fieldDistance: 'border-crossing--table--field-distance',
            fieldDuration: 'border-crossing--table--field-duration'
        },
        summary: {
            sectionCountry: 'border-crossing--summary--section-country',
            fieldCountry: 'border-crossing--summary--field-country',
            fieldDuration: 'border-crossing--summary--field-duration',
            fieldDistance: 'border-crossing--summary--field-distance',
            fieldEntries: 'border-crossing--summary--field-entries'
        },
        form: {
            btnSubmit: 'border-crossing--form--btn-submit',
            btnCancel: 'border-crossing--form--btn-cancel'
        },
        placeOfWork: {
            btnSubmit: 'border-crossing--place-of-work--btn-submit',
            btnCancel: 'border-crossing--place-of-work--btn-cancel'
        }
    },
    expenses: {
        btnExport: 'expenses--btn-export',
        btnFilter: 'expenses--btn-filter',
        btnFileImport: 'expenses--btn-file-import',
        btnCargo: 'expenses--btn-cargo',
        btnBack: 'expenses--btn-back',
        filter: {
            section: 'expenses--filter--section',
            btnCancel: 'expenses--filter--btn-cancel',
            btnSubmit: 'expenses--filter--btn-submit',
            groupVehicles: 'expenses--filter--group-vehicles',
            groupTypes: 'expenses--filter--group-types',
            groupPayment: 'expenses--filter--group-payment',
            groupSource: 'expenses--filter--group-source',
            radioTypesAll: 'expenses--filter--radio-types-all',
            radioPaymentAll: 'expenses--filter--radio-payment-all',
            radioSourceAll: 'expenses--filter--radio-source-all',
            switchFuelOnly: 'expenses--filter--switch-fuel-only',
            radioState: {
                proforma: 'expenses--filter--radio-source-proforma',
                final: 'expenses--filter--radio-source-final'
            },
            radioSource: {
                manual: 'expenses--filter--radio-state-manual',
                online: 'expenses--filter--radio-state-online',
                import: 'expenses--filter--radio-state-import',
                summary: 'expenses--filter--radio-state-summary'
            }
        },
        cargo: {
            inputVehicle: 'expenses--add--input-vehicle',
            triggerDate: 'expenses--add--trigger-date',
            inputSelectVehicle: 'expenses--add--input-select-vehicle',
            inputType: 'expenses--add--input-type',
            inputSupplier: 'expenses--add--input-supplier',
            inputQuantity: 'expenses--add--input-quantity',
            inputPrice: 'expenses--add--input-price',
            fieldQuantityUnit: 'expenses--add--field-quantity-unit',
            inputCountry: 'expenses--add--field-country',
            btnSubmit: 'expenses--add--btn-submit',
            btnCancel: 'expenses--add--btn-cancel'
        },
        table: {
            sectionGroup: 'expenses--table--group--section-group',
            group: {
                fieldActiveRnGroup: 'expenses--table--group--field-active-rn-group',
                fieldActiveDateGroup: 'expenses--table--group--field-active-date-group',
                fieldDate: 'expenses--table--group--field-group-date',
                fieldRn: 'expenses--table--group--field-group-rn',
                fieldDriver: 'expenses--table--group--field-driver',
                fieldType: 'expenses--table--group--field-type',
                fieldSupplier: 'expenses--table--group--field-supplier',
                fieldCountry: 'expenses--table--group--field-country',
                fieldCurrency: 'expenses--table--group--field-currency',
                fieldVAT: 'expenses--table--group--field-vat',
                fieldQuantityValue: 'expenses--table--group--field-quantity-value',
                fieldQuantityUnit: 'expenses--table--group--field-quantity-unit',
                fieldPricePerUnitWithoutVAT: 'expenses--table--group--field-price-per-unit-without-vat',
                fieldPricePerUnitWithVAT: 'expenses--table--group--field-price-per-unit-with-vat',
                fieldPriceTotalWithoutVAT: 'expenses--table--group--field-price-total-without-vat',
                fieldPriceTotalWithVAT: 'expenses--table--group--field-price-total-with-vat',
                fieldTotemPrice: 'expenses--table--group--field-totem-price',
                fieldSaved: 'expenses--table--group--field-saved',
                fieldOdo: 'expenses--table--group--field-odo',
                fieldSource: 'expenses--table--group--field-source',
                fieldState: 'expenses--table--group--field-state',
                btnChangeGroup: 'expenses--table--group--btn-change-group',
                btnOpen: 'expenses--table--group--btn-open',
                btnClose: 'expenses--table--group--btn-close'
            },
            sectionExpenses: 'expenses--table--group--section-expenses',
            expenses: {
                fieldRn: 'expenses--table--group--field-rn',
                fieldDate: 'expenses--table--group--field-date',
                fieldDriver: 'expenses--table--expenses--field-driver',
                fieldType: 'expenses--table--expenses--field-type',
                fieldSupplier: 'expenses--table--expenses--field-supplier',
                fieldCountry: 'expenses--table--expenses--field-country',
                fieldCurrency: 'expenses--table--expenses--field-currency',
                fieldVAT: 'expenses--table--expenses--field-vat',
                fieldQuantityValue: 'expenses--table--expenses--field-quantity-value',
                fieldQauntityUnit: 'expenses--table--group--field-quantity-unit',
                fieldPricePerUnitWithoutVAT: 'expenses--table--expenses--field-price-per-unit-without-vat',
                fieldPricePerUnitWithVAT: 'expenses--table--expenses--field-price-per-unit-with-vat',
                fieldPriceTotalWithoutVAT: 'expenses--table--expenses--field-price-total-without-vat',
                fieldPriceTotalWithVAT: 'expenses--table--expenses--field-price-total-with-vat',
                fieldTotemPrice: 'expenses--table--expenses--field-totem-price',
                fieldSaved: 'expenses--table--expenses--field-saved',
                fieldOdo: 'expenses--table--expenses--field-odo',
                fieldSource: 'expenses--table--expenses--field-source',
                fieldState: 'expenses--table--expenses--field-state',
                btnEdit: 'expenses--table--expenses--btn-edit',
                btnDelete: 'expenses--table--expenses--btn-delete'
            }
        }
    },
    routeOverview: {
        bar: {
            btnFilter: 'route-overview--bar--btn-filter',
            btnBack: 'route-overview--bar--btn-back'
        },
        filter: {
            btnConfirm: 'route-overview--filter--btn-confirm',
            btnCancel: 'route-overview--filter--btn-cancel',
            section: 'route-overview--filter--section',
            sectionDrivers: 'route-overview--filter--section-drivers',
            sectionVehicles: 'route-overview--filter--section-vehicles',
            sectionStates: 'route-overview--filter--section-states'
        },
        table: {
            btn: {
                routesNew: 'route-overview--table--btn--new-routes',
                routesDelayed: 'route-overview--table--btn--delayed-routes',
                routesActive: 'route-overview--table--btn--active-routes',
                routesFinished: 'route-overview--table--btn--completed-routes'
            },
            routesNew: 'route-overview--table--new-routes',
            routesDelayed: 'route-overview--table--delayed-routes',
            routesActive: 'route-overview--table--active-routes',
            routesFinished: 'route-overview--table--completed-routes',
            fieldName: 'route-overview--table--field-name',
            fieldStartDate: 'route-overview--table--field-start-date',
            fieldStartLocation: 'route-overview--table--field-start-location',
            fieldEndDate: 'route-overview--table--field-end-date',
            fieldEndLocation: 'route-overview--table--field-end-location',
            fieldStatus: 'route-overview--table--field-status',
            fieldVehicle: 'route-overview--table--field-vehicle',
            fieldTrailer: 'route-overview--table--field-trailer',
            fieldRealTrailer: 'route-overview--table--field-real-trailer',
            fieldDriver: 'route-overview--table--field-driver',
            fieldClient: 'route-overview--table--field-client',
            btnAddVehicle: 'route-overview--table--btn-add-vehicle',
            btnAddTrailer: 'route-overview--table--btn-add-trailer',
            fieldEta: 'route-overview--table--field-eta',
            fieldRta: 'route-overview--table--field-rta',
            btnActions: 'route-overview--table--btn-actions',
            btnSetAsComplete: 'route-overview--table--btn-set-as-complete',
            btnSetAsActive: 'route-overview--table--btn-set-as-active',
            btnRemove: 'route-overview--table--btn-remove',
            btnRemoveVehicle: 'route-overview--table--btn-remove-vehicle',
            btnTrackVehicleOnMap: 'route-overview--table--btn-track-vehicle-on-map',
            btnStazkaExport: 'route-overview--table--btn-stazka-export'
        },
        addVehicle: {
            vehicleAvailable: 'route-overview--add-vehicle--vehicle-available',
            vehicleInTransport: 'route-overview--add-vehicle--vehicle-in-transport',
            inputSearch: 'route-overview--add-vehicle--input-search',
            fieldRn: 'route-overview--add-vehicle--field-rn',
            fieldVehicleId: 'route-overview--add-vehicle--field-vehicle-id',
            btnSubmit: 'route-overview--add-vehicle--btn-submit',
            btnCancel: 'route-overview--add-vehicle--btn-cancel'
        }
    },
    companyProfile: {
        costsCalculator: {
            marketAverage: {
                vehicle: {
                    fieldValue: 'company-profile--cost-calculator--market-average--vehicle--field-value',
                    fieldCurrency: 'company-profile--cost-calculator--market-average--vehicle--field-currency'
                },
                light_vehicle: {
                    fieldValue: 'company-profile--cost-calculator--market-average--light-vehicle--field-value',
                    fieldCurrency: 'company-profile--cost-calculator--market-average--light-vehicle--field-currency'
                }
            },
            currentCosts: {
                vehicle: {
                    fieldValue: 'company-profile--cost-calculator--current-costs--vehicle--field-value',
                    fieldCurrency: 'company-profile--cost-calculator--current-costs--vehicle--field-currency'
                },
                light_vehicle: {
                    fieldValue: 'company-profile--cost-calculator--current-costs--light-vehicle--field-value',
                    fieldCurrency: 'company-profile--cost-calculator--current-costs--light-vehicle--field-currency'
                }
            },
            btnEditFixedCosts: 'company-profile--cost-calculator--btn-edit-fixed-costs',
            btnTotalCost: 'company-profile--cost-calculator--btn-total-cost',
            bar: {
                btnEditSelected: 'company-profile--cost-calculator--bar--btn-edit-selected',
                selectCurrency: 'company-profile--cost-calculator--bar--select-currency'
            },
            table: {
                checkboxRow: 'company-profile--cost-calculator--table--checkbox-row',
                fieldRn: 'company-profile--cost-calculator--table--field-rn',
                fieldSumPerKmValue: 'company-profile--cost-calculator--table--field-sum-per-km-value',
                fieldWashingValue: 'company-profile--cost-calculator--table--field-cleaning-value',
                fieldWashingUnit: 'company-profile--cost-calculator--table--field-cleaning-unit',
                fieldTelecommunicationValue: 'company-profile--cost-calculator--table--field-telecommunication-value',
                fieldTelecommunicationUnit: 'company-profile--cost-calculator--table--field-telecommunication-unit',
                fieldHeatingValue: 'company-profile--cost-calculator--table--field-heating-value',
                fieldHeatingUnit: 'company-profile--cost-calculator--table--field-heating-unit',
                fieldParkingValue: 'company-profile--cost-calculator--table--field-parking-value',
                fieldParkingUnit: 'company-profile--cost-calculator--table--field-parking-unit',
                fieldPersonalInsuranceValue: 'company-profile--cost-calculator--table--field-personal-insurance-value',
                fieldPersonalInsuranceUnit: 'company-profile--cost-calculator--table--field-personal-insurance-unit',
                fieldLiabilityInsuranceValue:
                    'company-profile--cost-calculator--table--field-liability-insurance-value',
                fieldLiabilityInsuranceUnit: 'company-profile--cost-calculator--table--field-liability-insurance-unit',
                fieldCollisionInsuranceValue:
                    'company-profile--cost-calculator--table--field-collision-insurance-value',
                fieldCollisionInsuranceUnit: 'company-profile--cost-calculator--table--field-collision-insurance-unit',
                fieldMaintenanceValue: 'company-profile--cost-calculator--table--field-maintenance-value',
                fieldMaintenanceUnit: 'company-profile--cost-calculator--table--field-maintenance-unit',
                fieldTiresValue: 'company-profile--cost-calculator--table--field-tires-value',
                fieldTiresUnit: 'company-profile--cost-calculator--table--field-tires-unit',
                fieldOilValue: 'company-profile--cost-calculator--table--field-oil-value',
                fieldOilUnit: 'company-profile--cost-calculator--table--field-oil-unit',
                fieldAdditivesValue: 'company-profile--cost-calculator--table--field-additives-value',
                fieldAdditivesUnit: 'company-profile--cost-calculator--table--field-additives-unit',
                fieldElectroValue: 'company-profile--cost-calculator--table--field-electro-value',
                fieldElectroUnit: 'company-profile--cost-calculator--table--field-electro-unit',
                fieldLngCngValue: 'company-profile--cost-calculator--table--field-lng-cng-value',
                fieldLngCngUnit: 'company-profile--cost-calculator--table--field-lng-cng-unit',
                fieldFuelValue: 'company-profile--cost-calculator--table--field-fuel-value',
                fieldFuelUnit: 'company-profile--cost-calculator--table--field-fuel-unit'
            }
        },
        dashboard: {
            btnCostCalculator: 'company-profile--dashboard--cost-calculator',
            btnDatePicker: 'company-profile--dashboard--btn-date-picker'
        }
    },
    allowances: {
        bar: {
            btnRules: 'allowances--bar--btn-rules',
            btnImport: 'allowances--bar--btn-import',
            btnExport: 'allowances--bar--btn-export',
            btnEdit: 'allowances--bar--btn-edit',
            btnBack: 'allowances--bar--btn-back'
        },
        summary: {
            fieldCountries: 'allowances--summary--field-countries',
            fieldAbroadTravel: 'allowances--summary--field-abroud-travel',
            fieldDomesticTravel: 'allowances--summary--field-domestic-travel',
            fieldPocketMoney: 'allowances--summary--field-pocket-money',
            fieldPocketMoneyCurrency: 'allowances--summary--field-pocket-money-currency',
            fieldAllowance: 'allowances--summary--field-allowance',
            fieldAllowanceCurrency: 'allowances--summary--field-allowance-currency',
            fieldTotalAllowance: 'allowances--summary--field-total-allowance',
            fieldTotalAllowanceCurrency: 'allowances--summary--field-total-allowance-currency',
            fieldSummary: 'allowances--summary--field-summary',
            fieldSummaryCurrency: 'allowances--summary--field-summary-currency'
        },
        table: {
            fieldName: 'allowances--table--field-name',
            fieldSurname: 'allowances--table--field-surname',
            fieldAbroadTravel: 'allowances--table--field-abroud-travel',
            fieldCountries: 'allowances--table--field-countries',
            fieldDomesticTravel: 'allowances--table--field-domestic-travel',
            fieldDailyRate: 'allowances--table--field-daily-rate',
            fieldPocketMoney: 'allowances--table--field-pocket-money',
            fieldMealShortage: 'allowances--table--field-meal-shortage',
            fieldTotalAllowance: 'allowances--table--field-total-allowance',
            btnShowDetail: 'route-overview--table--btn-show-detail'
        },
        detail: {
            fieldFrom: 'allowances--detail--field-from',
            fieldTo: 'allowances--detail--field-to',
            fieldAbroadTravel: 'allowances--detail--field-abroud-travel',
            fieldDomesticTravel: 'allowances--detail--field-domestic-travel',
            fieldPlaceOfWorkTime: 'allowances--detail--field-place-of-work-time',
            fieldCountry: 'allowances--table--field-country',
            fieldDailyRate: 'allowances--detail--field-daily-rate',
            fieldPocketMoney: 'allowances--detail--field-pocket-money',
            fieldMealShortage: 'allowances--detail--field-meal-shortage',
            fieldDailyTotalRate: 'allowances--detail--field-daily-total-rate',
            btnEdit: 'allowwances--detail--btn-edit',
            btnDriverPlaceOfWork: 'allowances--detail--btn-driver-place-of-work',
            btnRemoveDriverPlaceOfWork: 'allowances--detail--btn-remove-driver-place-of-work',
            btnAddPlaceCrossing: 'allowances--detail--btn-add-place-crossing',
            btnRemovePlaceCrossing: 'allowances--detail--btn-remove-place-crossing'
        },
        importExport: {
            btnDefault: 'allowances--import-export--btn-default'
        }
    },
    remoteDownload: {
        table: {
            btnCalendarPrevious: 'remote-download--table--btn-calendar-previous',
            btnCalendarNext: 'remote-download--table--btn-calendar-previous',
            fieldDay: 'remote-download--table--field-day',
            fieldName: 'remote-download--table--field-name',
            ccr: {
                connected: 'remote-download--table--ccr--field-connected',
                connected_driver: 'remote-download--table--ccr--field-connected-driver',
                connected_company: 'remote-download--table--ccr--field-connected-company',
                unknown: 'remote-download--table--ccr--field-unknown',
                discondected: 'remote-download--table--ccr--field-disconnected'
            }
        }
    },
    poi: {
        table: {
            fieldName: 'poi--table--field-name',
            fieldType: 'poi--table--field-type',
            fieldForbiden: 'poi--table--field-forbidden'
        },
        bar: {
            btnAdd: 'poi--bar--btn-add',
            btnBack: 'poi--bar--btn-back'
        },
        detail: {
            inputName: 'poi--detail--input-name',
            inputNameErr: 'poi--detail--input-name-err',
            fieldName: 'poi--detail--field-name',
            selectType: 'poi--detail--select-type',
            selectTypeErr: 'poi--detail--select-type-err',
            fieldType: 'poi--detail--field-type',
            inputForbidden: 'poi--detail--input-forbidden',
            inputPlaceSearch: 'poi--detail--input-place-search',
            inputAddress: 'poi--detail--input-address',
            inputLat: 'poi--detail--input-lat',
            fieldLat: 'poi--detail--field-lat',
            inputLng: 'poi--detail--input-lng',
            fieldLng: 'poi--detail--field-lng',
            inputNote: 'poi--detail--input-note',
            fieldNote: 'poi--detail--field-note',
            btnSubmit: 'poi--detail--btn-submit',
            btnCancel: 'poi--detail--btn-cancel',
            btnDelete: 'poi--detail--btn-delete',
            btnUpdate: 'poi--detail--btn-update',
            btnAddPlaceOfWork: 'poi--detail--btn-add-place-of-work',
            sectionPlaceOfWork: 'poi--detail--section-place-of-work',
            placeOfWork: {
                fieldName: 'poi--detail--place-of-work--field-name',
                btnDelete: 'poi--detail--place-of-work--btn-delete'
            }
        },
        form: {
            btnFormSubmit: 'poi--form--btn-submit',
            btnFormCancel: 'poi--form--btn-cancel'
        }
    },
    maintenance: {
        bar: {
            btnExport: 'maintenance--bar--btn-export',
            btnDelete: 'maintenance--bar--btn-delete',
            btnMarkAsComplete: 'maintenance--bar--btn-mark-as-complete',
            btnCreateNew: 'maintenance--bar--btn-create-new'
        },
        filter: {
            btnSubmit: 'maintenance--filter--btn-submit',
            btnCancel: 'maintenance--filter--btn-cancel',
            section: 'maintenance--filter--section'
        },
        detail: {
            btnDelete: 'maintenance--detail--btn-delete',
            btnEdit: 'maintenance--detail--btn-edit',
            btnMarkAsComplete: 'maintenance--detail--btn-mark-as-complete',
            fieldState_company: 'maintenance--detail--field-state-company',
            fieldState_driver: 'maintenance--detail--field-state-driver',
            fieldState_vehicle: 'maintenance--detail--field-state-vehicle',
            fieldState_trailer: 'maintenance--detail--field-state-trailer',
            fieldTaskName: 'maintenance--detail--field-task-name',
            fieldDriver: 'maintenance--detail--field-driver',
            fieldVehicle: 'maintenance--detail--field-vehicle',
            fieldTrailer: 'maintenance--detail--field-trailer',
            fieldCompany: 'maintenance--detail--field-company',
            fieldCreatedBy: 'maintenance--detail--field-created-by',
            fieldCompletedBy: 'maintenance--detail--field-completed-by',
            fieldCompletedOnDate: 'maintenance--detail--field-completed-on-date',
            fieldCompletedOnKm: 'maintenance--detail--field-completed-on-km',
            fieldCompletionMileage: 'maintenance--detail--field-completion-mileage',
            fieldDueDate: 'maintenance--detail--field-due-date',
            fieldInterval_custom: 'maintenance--detail--field-interval-custom',
            fieldInterval_day_1: 'maintenance--detail--field-interval-day_1',
            fieldInterval_month_1: 'maintenance--detail--field-interval-month_1',
            fieldInterval_month_2: 'maintenance--detail--field-interval-month_2',
            fieldInterval_month_3: 'maintenance--detail--field-interval-month_3',
            fieldInterval_month_6: 'maintenance--detail--field-interval-month_6',
            fieldInterval_none: 'maintenance--detail--field-interval-none',
            fieldInterval_week_1: 'maintenance--detail--field-interval-week_1',
            fieldInterval_week_2: 'maintenance--detail--field-interval-week_2',
            fieldInterval_year_1: 'maintenance--detail--field-interval-year_1',
            fieldInterval_year_2: 'maintenance--detail--field-interval-year_2',
            fieldDueMileageKm: 'maintenance--detail--field-due-mileage-km',
            inputBindToDueDate: 'maintenance--detail--input-bind-to-due-date',
            fieldDueDateValue: 'maintenance--detail--field-due-date-value',
            fieldIntervalType_day: 'maintenance--detail--field-interval-type_day',
            fieldIntervalType_week: 'maintenance--detail--field-interval-type_week',
            fieldIntervalType_month: 'maintenance--detail--field-interval-type_month',
            fieldIntervalType_year: 'maintenance--detail--field-interval-type_year',
            fieldRepetitionDueMileageKm: 'maintenance--detail--field-repetition-due-mileage-km',
            fieldNotificationDueDateDays: 'maintenance--detail--field-notification-due-date-days',
            fieldNotificationDueMileageKm: 'maintenance--detail--field-notification-due-mileage-km'
        },
        form: {
            categoryBtns: {
                vehicle: 'maintenance--form--category-btns--vehicle',
                trailer: 'maintenance--form--category-btns--trailer',
                driver: 'maintenance--form--category-btns--driver',
                company: 'maintenance--form--category-btns--company'
            },
            err: {
                fieldCategory: 'maintenance--form--err--field-category'
            },
            inputDate: 'maintenance--form--input-date',
            inputOdometer: 'maintenance--form--input-odometer',
            inputDueDate: 'maintenance--form--input-due-date',
            inputDueMileage: 'maintenance--form--input-due-mileage',
            inputDateInterval: 'maintenance--form--input-interval',
            inputDueMileageInterval: 'maintenance--form--input-due-mileage-interval',
            inputDateValue: 'maintenance--form--input-date-value',
            inputDateType: 'maintenance--form--input-date-type',
            inputDateBind: 'maintenance--form--input-date-bind'
        }
    },
    statisticReport: {
        table: {
            group: {
                byDays: 'statistic-report--table--group--by-days',
                byMonths: 'statistic-report--table--group--by-months',
                byVehicles: 'statistic-report--table--group--by-vehicles',
                byDrivers: 'statistic-report--table--group--by-drivers',
                summary: 'statistic-report--table--group--summary'
            },
            fieldDistance: 'statistic-report--summary--field-distance',
            fieldNTrips: 'statistic-report--summary--field-n-trips',
            fieldMaxSpeed: 'statistic-report--summary--field-max-speed',
            fieldAvgSpeed: 'statistic-report--summary--field-avg-speed',
            fieldConsumption: 'statistic-report--summary--field-consumption',
            fieldAvgConsumption: 'statistic-report--summary--field-avg-consumption',
            fieldDuration: 'statistic-report--summary--field-duration',
            fieldIdling: 'statistic-report--summary--field-idling',
            fieldStopped: 'statistic-report--summary--field-stopped',
            fieldRefueled: 'statistic-report--summary--field-refueled',
            fieldDay: 'statistic-report--summary--field-day',
            fieldFrom: 'statistic-report--summary--field-from',
            fieldTo: 'statistic-report--summary--field-to',
            fieldOdometerState: 'statistic-report--summary--field-odometer-state',
            fieldMonth: 'statistic-report--summary--field-month',
            fieldRn: 'statistic-report--summary--field-rn',
            fieldDriver: 'statistic-report--summary--field-driver',
            fieldAvgConsumptionIdling: 'statistic-report--summary--field--avg-consumption-idling',
            fieldAvgConsumptionIdlingPer1h: 'statistic-report--summary--field-avg-consumption-idling-per-1-h',
            fieldC02Emission: 'statistic-report--summary--field-co2-emission'
        },
        filter: {
            inputVehicle: 'statistic-report--filter--input-vehicle',
            inputDriver: 'statistic-report--filter--input-driver'
        },
        summary: {
            sectionDrive: 'statistic-report--summary--section-drive',
            sectionTime: 'statistic-report--summary--section-time',
            sectionFuel: 'statistic-report--summary--section-fuel',
            fieldDistance: 'statistic-report--summary--field-distance',
            fieldNTrips: 'statistic-report--summary--field-n-trips',
            fieldMaxSpeed: 'statistic-report--summary--field-max-speed',
            fieldAvgSpeed: 'statistic-report--summary--field-avg-speed',
            fieldDuration: 'statistic-report--summary--field-duration',
            fieldIdling: 'statistic-report--summary--field-idling',
            fieldStopped: 'statistic-report--summary--field-stopped',
            fieldRefueled: 'statistic-report--summary--field-refueled'
        }
    },
    systemConnections: {
        tabCustomerApi: 'system-connections--tab-customer-api',
        tabCustomerAccess: 'system-connections--tab-customer-access',
        tabOtherSystems: 'system-connections--tab-other-systems',
        btnCopyToken: 'system-connections--btn-copy-token',
        btnGenerateApiKey: 'system-connections--btn-generate-api-key',
        apiKeys: {
            sectionApiKey: 'system-connections--api-keys--section-api-key',
            btnDelete: 'system-connections--api-keys--btn-delete',
            btnEdit: 'system-connections--api-keys--btn-edit',
            fieldUser: 'system-connections--api-keys--field-user',
            fieldVehicles: 'system-connections--api-keys--field-vehicles',
            fieldApiKey: 'system-connections--api-keys--field-api-key'
        },
        createApiKey: {
            inputName: 'system-connections--create-api-key--input-name',
            btnSave: 'system-connections--create-api-key--btn-save',
            btnGenerate: 'system-connections--create-api-key--btn-generate',
            vehicle: {
                btnSelectAll: 'system-connections--create-api-key--btn-select-all',
                inputVehicle: 'system-connections--create-api-key--input-vehicle',
                inputSearch: 'system-connections--create-api-key--input-search'
            }
        },
        form: {
            selectSystem: 'system-connections--form--select-system',
            selectVehicles: 'system-connections--form--select-vehicle',
            btnFormSubmit: 'system-connections--form--btn-submit',
            btnFormCancel: 'system-connections--form--btn-cancel'
        },
        customerAccess: {
            table: {
                fieldVehicles: 'system-connections--customer-access--table--field-vehicles',
                fieldAccess: 'system-connections--customer-access--table--field-access',
                btnOpenLink: 'system-connections--customer-access--table--btn-link',
                btnDelete: 'system-connections--customer-access--table--btn-delete'
            },
            actions: {
                selectVehicle: 'system-connections--customer-access--actions--select-vehicle',
                inputDateFrom: 'system-connections--customer-access--actions--input-date-from',
                inputDateTo: 'system-connections--customer-access--actions--input-date-to',
                btnSubmit: 'system-connections--customer-access--actions--btn-submit',
                btnCancel: 'system-connections--customer-access--actions--btn-cancel'
            }
        },
        otherSystems: {
            table: {
                fieldName: 'system-connections--other-systems--table--field-name',
                fieldVehicles: 'system-connections--other-systems--table--field-vehicles',
                btnEdit: 'system-connections--other-systems--table--btn-edit',
                btnRemove: 'system-connections--other-systems--table--btn-remove'
            },
            detail: {
                btnConnectAnother: 'system-connections--other-systems--detail--btn-connect-another',
                btnClientIdCopy: 'system-connections--other-systems--detail--btn-client-id-copy',
                btnSecretCopy: 'system-connections--other-systems--detail--btn-secret-copy'
            }
        }
    },
    totalCostOfOwnership: {
        form: {
            operationalLifetime_operationalLifetime: 'tco--form--operationalLifetime_operationalLifetime',
            annualMileage_annualMileage: 'tco--form--annualMileage_annualMileage',
            lngVehicleDetails_purchaseCost: 'tco--form--lngVehicleDetails_purchaseCost',
            lngVehicleDetails_annualTollCost: 'tco--form--lngVehicleDetails_annualTollCost',
            lngVehicleDetails_annualInsuranceCost: 'tco--form--lngVehicleDetails_annualInsuranceCost',
            lngVehicleDetails_annualOtherCost: 'tco--form--lngVehicleDetails_annualOtherCost',
            lngVehicleDetails_annualServiceAndMaintenanceCost:
                'tco--form--lngVehicleDetails_annualServiceAndMaintenanceCost',
            lngVehicleDetails_fuelPrice: 'tco--form--lngVehicleDetails_fuelPrice',
            lngVehicleDetails_gCo2PerUnitProduction: 'tco--form--lngVehicleDetails_gCo2PerUnitProduction',
            lngVehicleDetails_residualValue: 'tco--form--lngVehicleDetails_residualValue',
            dieselVehicleDetails_purchaseCost: 'tco--form--dieselVehicleDetails_purchaseCost',
            dieselVehicleDetails_annualTollCost: 'tco--form--dieselVehicleDetails_annualTollCost',
            dieselVehicleDetails_annualInsuranceCost: 'tco--form--dieselVehicleDetails_annualInsuranceCost',
            dieselVehicleDetails_annualOtherCost: 'tco--form--dieselVehicleDetails_annualOtherCost',
            dieselVehicleDetails_annualServiceAndMaintenanceCost:
                'tco--form--dieselVehicleDetails_annualServiceAndMaintenanceCost',
            dieselVehicleDetails_fuelPrice: 'tco--form--dieselVehicleDetails_fuelPrice',
            dieselVehicleDetails_fuelAverageConsumption: 'tco--form--dieselVehicleDetails_fuelAverageConsumption',
            dieselVehicleDetails_gCo2PerUnitProduction: 'tco--form--dieselVehicleDetails_gCo2PerUnitProduction',
            dieselVehicleDetails_residualValue: 'tco--form--dieselVehicleDetails_residualValue',
            dieselVehicleDetails_adbluePrice: 'tco--form--dieselVehicleDetails_adbluePrice',
            dieselVehicleDetails_adblueAverageConsumption: 'tco--form--dieselVehicleDetails_adblueAverageConsumption'
        },
        table: {
            sumLng: {
                purchaseCost: 'tco--table--sum-lng--purchase-cost',
                insuranceCost: 'tco--table--sum-lng--insuranceCost',
                serviceAndMaintenanceCost: 'tco--table--sum-lng--serviceAndMaintenanceCost',
                fuelCost: 'tco--table--sum-lng--fuelCost',
                tollCost: 'tco--table--sum-lng--tollCost',
                otherCost: 'tco--table--sum-lng--otherCost',
                totalCost: 'tco--table--sum-lng--totalCost',
                gCo2Constant: 'tco--table--sum-lng--gCo2Constant',
                gCo2PerUnitProduction: 'tco--table--sum-lng--gCo2PerUnitProduction'
            },
            sumDiesel: {
                purchaseCost: 'tco--table--sum-diesel--purchase-cost',
                insuranceCost: 'tco--table--sum-diesel--insuranceCost',
                serviceAndMaintenanceCost: 'diesel--table--sum-lng--serviceAndMaintenanceCost',
                fuelCost: 'tco--table--sum-diesel--fuelCost',
                tollCost: 'tco--table--sum-diesel--tollCost',
                otherCost: 'tco--table--sum-diesel--otherCost',
                adblueCosts: 'tco--table--sum-diesel--adblueCost',
                totalCost: 'tco--table--sum-diesel--totalCost',
                gCo2Constant: 'tco--table--sum-diesel--gCo2Constant',
                gCo2PerUnitProduction: 'tco--table--sum-diesel--gCo2PerUnitProduction'
            },
            calculated: {
                fieldData: 'tco--table--calculated--field-data'
            }
        },
        btnCalculate: 'total-cost-of-ownership--btn-calculate'
    },
    messaging: {
        channels: {
            sectionChannel: 'messaging--channels--section-channel',
            inputChannelSearch: 'messaging--channels--input-channel-search',
            btnUnseeLastMessage: 'messaging--channels--btn-unsee-last-message',
            btnSelectChannels: 'messaging--channels--btn-select-channels',
            btnSearch: 'messaging--channels--btn-search',
            btnChecked: 'messaging--channels--btn-checked',
            btnEmpty: 'messaging--channels--btn-empty',
            fieldUnreadCount: 'messaging--channels--field-unread-count',
            fieldName: 'messaging--channels--field-name'
        },
        messages: {
            sectionMessage: 'messaging--messages--section-message',
            inputMessage: 'messaging--messages--input-message',
            btnAttachment: 'messaging--messages--btn-attachment',
            btnSend: 'messaging--messages--btn-send',
            btnMessagesSearch: 'messaging--messages--btn-messages-search',
            inputMessagesSearch: 'messaging--messages--input-messages-search',
            fieldSender: 'messaging--messages--field-sender',
            fieldDateTime: 'messaging--messages--field-date-time',
            fieldText: 'messaging--messages--field-text',
            fieldSeen: 'messaging--messages--field-seen',
            fieldTitle: 'messaging--messages--field-title',
            fieldAttachmentImg: 'messaging--messages--field-attachment-img',
            fieldAttachmentUrl: 'messaging--messages--field-attachment-url'
        }
    },
    partner: {
        filter: {
            selectPartner: 'partner--filter--select-partner',
            optionPartner: 'partner--filter--option-partner',
            selectPricingList: 'partner--filter--select-pricing-list',
            btnUnselectPartner: 'partner--filter--btn-unselect-partner'
        },
        partners: {
            btnAdnNewPartner: 'partner--partners--btn-add-new-partner',
            create: {
                inputName: 'partner--partners--create--input-name',
                inputIdentificationNumber: 'partner--partners--create--input-identification-number',
                inputVat: 'partner--partners--create--input-vat',
                inputCreationDate: 'partner--partners--create--input-creation-date',
                selectCompanyType: 'partner--partners--create--select-company-type',
                selectCurrency: 'partner--partners--create--select-currency',
                selectBillingCycle: 'partner--partners--create--select-billing-cycle',
                inputCompanyStreet: 'partner--partners--create--input-company-street',
                inputCompanyStreetNo: 'partner--partners--create--input-company-street-no',
                inputCompanyCity: 'partner--partners--create--input-company-city',
                inputCompanyZipCode: 'partner--partners--create--input-company-zip-code',
                selectCompanyCountry: 'partner--partners--create--select-company-country',
                checkboxCompanyOtherDelivery: 'partner--partners--create--checkbox-company-other-delivery',
                inputDeliveryStreet: 'partner--partners--create--input-delivery-street',
                inputDeliveryStreetNo: 'partner--partners--create--input-delivery-street-no',
                inputDeliveryCity: 'partner--partners--create--input-delivery-city',
                inputDeliveryZipCode: 'partner--partners--create--input-delivery-zip-code',
                selectDeliveryCountry: 'partner--partners--create--select-delivery-country',
                inputPersonName: 'partner--partners--create--input-person-name',
                inputPersonSurname: 'partner--partners--create--input-person-surname',
                inputPersonEmail: 'partner--partners--create--input-person-email',
                inputPersonPhone: 'partner--partners--create--input-person-phone',
                inputPersonPosition: 'partner--partners--create--input-person-position',
                inputPersonStreet: 'partner--partners--create--input-person-street',
                inputPersonStreetNo: 'partner--partners--create--input-person-street-no',
                inputPersonCity: 'partner--partners--create--input-person-city',
                selectPersonCountry: 'partner--partners--create--select-person-country',
                inputPersonZipCode: 'partner--partners--create--input-person-zip-code',
                btnCancel: 'partner--partners--create--btn-cancel',
                btnSave: 'partner--partners--create--btn-save',
                sectionTlmServices: 'partner--partners--create--section-tlm-services'
            },
            table: {
                fieldName: 'partner--partners--table--field-name',
                fieldId: 'partner--partners--table--field-id',
                fieldEmail: 'partner--partners--table--field-email',
                fieldPhone: 'partner--partners--table--field-phone',
                fieldDateAdded: 'partner--partners--table--field-date-added',
                fieldType: 'partner--partners--table--field-type'
            },
            detail: {
                fieldName: 'partner--partners--detail--field-name',
                fieldInvoiceCode: 'partner--partners--detail--field-invoice-code',
                fieldIdentificationNumber: 'partner--partners--detail--field-identification-number',
                fieldVat: 'partner--partners--detail--field-vat',
                fieldCreationDate: 'partner--partners--detail--field-creation-date',
                fieldTerminationDate: 'partner--partners--detail--field-termination-date',
                fieldCompanyType: 'partner--partners--detail--field-company-type',
                fieldCurrency: 'partner--partners--detail--field-currency',
                fieldBillingCycle: 'partner--partners--detail--field-billing-cycle',
                fieldCompanyStreet: 'partner--partners--detail--field-company-street',
                fieldCompanyStreetNo: 'partner--partners--detail--field-company-street-no',
                fieldCompanyCountry: 'partner--partners--detail--field-company-street-country',
                fieldCompanyZipCode: 'partner--partners--detail--field-company-street-zip-code',
                fieldCompanyCity: 'partner--partners--detail--field-company-street-city',
                fieldPersonName: 'partner--partners--detail--field-person-name',
                fieldPersonSurname: 'partner--partners--detail--field-person-surname',
                fieldPersonEmail: 'partner--partners--detail--field-person-email',
                fieldPersonPhone: 'partner--partners--detail--field-person-phone',
                fieldPersonPosition: 'partner--partners--detail--field-person-position',
                fieldPersonStreet: 'partner--partners--detail--field-person-street',
                fieldPersonStreetNo: 'partner--partners--detail--field-person-street-no',
                fieldPersonCountry: 'partner--partners--detail--field-person-street-country',
                fieldPersonZipCode: 'partner--partners--detail--field-person-street-zip-code',
                fieldPersonCity: 'partner--partners--detail--field-person-street-city',
                btnPair: 'partner--partners--btn-pair',
                btnImpersonate: 'partner--partners--btn-impersonate'
            }
        },
        companies: {
            btnAdnNewCompany: 'partner--partners--btn-add-new-company'
        },
        tlmServices: {
            btnAddNewTlmService: 'partner--partners--btn-add-new-tlm-service',
            btnAddNewTlmBundle: 'partner--partners--btn-add-new-tlm-bundle'
        },
        pricing: {
            btnAddNewPricingService: 'partner--partners--btn-add-new-pricing-service',
            btnAddNewPricingBundle: 'partner--partners--btn-add-new-pricing-bundle'
        }
    },
    forbidden: {
        _403: 'page-403',
        _404: 'page-404'
    }
};

export default qa;
