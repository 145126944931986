import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { mapStyles } from 'logic/map/style';
import { google } from 'google-maps';
import ChartTimeline, {
    ChartPointsDataModel,
    ChartRegionDataModel,
    ChartTimelineModel,
    Themes
} from 'common/components/ChartTimeline';
import { Col, Row } from 'antd';
import TableContentCard from 'common/components/TableContentCard';
import { ColdchainAlertFilterParams, ColdchainChartFilterParams } from '../../ColdchainModule';
import {
    ColdchainDetailModel,
    ColdchainTemperatureSensorModel,
    TemperatureSensorDataGraphs
} from '../../coldchain-logic';
import ColdchainChartFilter from '../coldchain-chart-filter';
import { Loading } from 'common/components';
import ColdchainChartLegend from '../coldchain-chart-legend';
import { AlarmInDatabaseWithGPSInfo, ColdChainProfileActiveEventRule } from 'generated/backend-api';
import moment from 'moment';
import { findClosestIndex } from 'common/utils/math';
import { coldChainMarker } from 'resources/images/coldchain';
import { confDefault } from 'conf';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, MAX_ZOOM } from 'domain-constants';
import TableBar from 'common/components/TableBar';
import ColdchainAlertTable from '../coldchain-alert-table';
import { SearchData } from 'common/components/Search';
import ColdchainAlertFilter from '../coldchain-alert-filter';

const MarkerWithLabel = require('@google/markerwithlabel');

interface Props extends WithTranslation {
    map: { open: boolean };
    temperatureSensors?: ColdchainTemperatureSensorModel[];
    temperatureSensorsData?: TemperatureSensorDataGraphs[];
    temperatureSensorsAlarmsData?: AlarmInDatabaseWithGPSInfo[];
    temperatureSensorsDataLoading: boolean;
    temperatureProfiles?: ColdChainProfileActiveEventRule[];
    zones: number[];
    demoMode: boolean;
    chartFilter: ColdchainChartFilterParams;
    search?: SearchData;
    detailData?: ColdchainDetailModel;
    theme: Themes;
    alertFilter: ColdchainAlertFilterParams;
    onChartFilterChange: (coldchainGraphFilter: ColdchainChartFilterParams) => void;
    onChartMapSwitchChange?: (checked: boolean) => void;
    onChartLegendDangerPointsToggle?: () => void;
    onAlertTableRowClick?: () => void;
    onSearchChange?: (value: string) => void;
    onAlertFilterChange?: (alertFilter: ColdchainAlertFilterParams) => void;
}

interface State {
    alertsFilter: {
        open: boolean;
    };
}

class ColdchainChartMap extends Component<Props, State> {
    private _map?: google.maps.Map;
    private _google: google;
    private _polylines: google.maps.Polyline[];
    private _alarms: google.maps.Marker[];
    private _locationMarker?: google.maps.Marker;

    constructor(props: Props) {
        super(props);
        this._google = (window as any).google;
        this._polylines = [];
        this._alarms = [];
        this.state = {
            alertsFilter: {
                open: false
            }
        };
    }

    componentDidMount() {
        const mapElement = document.getElementById('sensor-map');
        mapElement && this._initMap(mapElement);
        this._resetMap();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.temperatureSensorsDataLoading !== this.props.temperatureSensorsDataLoading) {
            if (
                prevProps.temperatureSensorsDataLoading === false &&
                this.props.temperatureSensorsDataLoading === true
            ) {
                this._resetMap();
            } else {
                if (!this._map) {
                    const mapElement = document.getElementById('sensor-map');
                    mapElement && this._initMap(mapElement);
                }
                this._setRoute();
                this._setAlarms();
            }
        }

        if (prevProps.map.open !== this.props.map.open) {
            this._resetMap();
            if (!this._map) {
                const mapElement = document.getElementById('sensor-map');
                mapElement && this._initMap(mapElement);
            }
            this._setRoute();
            this._setAlarms();
            // This is needed when first inicialization was without "map visible"
            setTimeout(() => this.fitBoundsToPolylines(), 10);
        }
    }

    render() {
        const durationDays = moment
            .duration(
                moment(this.props.detailData?.dateRange.end, 'DD.MM.YYYY HH:mm').diff(
                    moment(this.props.detailData?.dateRange.start, 'DD.MM.YYYY HH:mm')
                )
            )
            .asDays();

        const isSameDay = moment(this.props.detailData?.dateRange.start, 'DD.MM.YYYY HH:mm').isSame(
            moment(this.props.detailData?.dateRange.end, 'DD.MM.YYYY HH:mm'),
            'day'
        );

        const isSameWeek = moment(this.props.detailData?.dateRange.start, 'DD.MM.YYYY HH:mm').isSame(
            moment(this.props.detailData?.dateRange.end, 'DD.MM.YYYY HH:mm'),
            'week'
        );

        const noPolyline =
            this.props.temperatureSensorsData?.filter(d => d.polyline !== '') &&
            this.props.temperatureSensorsData?.filter(d => d.polyline !== '').length === 0
                ? true
                : false;

        return (
            <>
                <Row gutter={[32, 32]} className="coldchain-detail-content">
                    <Col span={this.props.map.open ? 18 : 24}>
                        <Row gutter={[32, 32]}>
                            <Col span={24}>
                                <TableContentCard
                                    className="coldchain-detail-status-timeline-report"
                                    content={
                                        <>
                                            <ColdchainChartFilter
                                                zones={this.props.zones}
                                                demoMode={this.props.demoMode}
                                                map={this.props.map}
                                                detailData={this.props.detailData}
                                                filterParams={this.props.chartFilter}
                                                onFilterChange={this._onChartFilterChange}
                                                onMapSwitchChange={this.props.onChartMapSwitchChange}
                                            />
                                            {this.props.temperatureSensorsDataLoading ? (
                                                <Loading />
                                            ) : (
                                                <ChartTimeline
                                                    durationDays={durationDays}
                                                    isSameDay={isSameDay}
                                                    isSameWeek={isSameWeek}
                                                    seriesLabels={this.props.temperatureSensors
                                                        ?.map(sensor => ({ [sensor.serialNumber]: sensor.sensorName }))
                                                        .reduce((a, b) => ({ ...a, ...b }), {})}
                                                    xTicks={this.props.temperatureSensorsData?.[0].timestampsTicks}
                                                    xTickInterval={this.props.temperatureSensorsData?.[0].interval}
                                                    data={this._generateChartData()}
                                                    regionData={this._generateChartRegionData()}
                                                    selectedSeries={this.props.chartFilter.serialNumbers}
                                                    onGraphPointHover={(index: number) => this._setPosition(index)}
                                                    limits={{ dangerPoints: this.props.chartFilter.dangerPoints }}
                                                    pointsData={this._generateChartPoints()}
                                                    yAxisMaxValue={50}
                                                    yAxisMinValue={-50}
                                                    theme={this.props.theme}
                                                />
                                            )}
                                            <ColdchainChartLegend
                                                sensors={this.props.temperatureSensors?.filter(
                                                    sensor => sensor.sensorZone === this.props.detailData?.sensorZone
                                                )}
                                                dangerPoints={this.props.chartFilter.dangerPoints}
                                                selectedSerialNumbers={this.props.chartFilter.serialNumbers}
                                                onSensorToggle={this._onChartLegendSensorToggle}
                                                onDangerPointsToggle={this.props.onChartLegendDangerPointsToggle}
                                            />
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <TableBar
                                className="coldchain-detail-bar"
                                heading={this.props.t('Coldchain.alertsHistory')}
                                filter={{
                                    activeCount:
                                        !this.props.alertFilter.alerting || !this.props.alertFilter.resolved ? 1 : 0,
                                    onClick: this._handleAlertFilterClick,
                                    toHeader: true,
                                    resetButton: {
                                        onClick: () =>
                                            this.props.onAlertFilterChange?.({
                                                alerting: true,
                                                resolved: true
                                            })
                                    }
                                }}
                            />
                        </Row>
                        <Row>
                            <ColdchainAlertTable
                                loading={false}
                                temperatureSensors={this.props.temperatureSensors}
                                temperatureSensorsAlarmsData={this.props.temperatureSensorsAlarmsData}
                                temperatureProfiles={this.props.temperatureProfiles}
                                onRowClick={this.props.onAlertTableRowClick}
                            />
                        </Row>
                    </Col>
                    <Col span={this.props.map.open ? 6 : 0}>
                        {noPolyline && !this.props.temperatureSensorsDataLoading && (
                            <>
                                <div className="cold-chain-map-no-polyline-text">
                                    {this.props.t('common.noMapData')}
                                </div>
                                <div className="cold-chain-map-no-polyline" />
                            </>
                        )}
                        <div className="cold-chain-map" id="sensor-map" />
                    </Col>
                </Row>
                {this.state.alertsFilter.open && (
                    <ColdchainAlertFilter
                        filter={this.props.alertFilter}
                        onCancel={this._handleAlertFilterCancel}
                        onConfirm={this._handleAlertFilterConfirm}
                    />
                )}
            </>
        );
    }

    private _generateChartPoints = (): ChartPointsDataModel[] => {
        const chartPoints: ChartPointsDataModel[] = [];
        this.props.temperatureSensorsAlarmsData
            ?.filter(d => this.props.chartFilter.sensorZone === Number(d.uniqueData?.['temperature_sensor_zone']))
            .forEach(alarm => {
                const series = alarm.uniqueData?.['temperature_sensor_serial_number'];
                const customLabel = this.props.temperatureSensors?.find(
                    sensor => sensor.serialNumber === series
                )?.sensorName;

                const timestampIndexStart = findClosestIndex(
                    Number(
                        moment(moment.utc(moment(alarm?.start).format('YYYY-MM-DD HH:mm:ss')).toDate())
                            .local()
                            .format('X')
                    ),

                    this.props.temperatureSensorsData?.[0].timestamps ?? []
                );
                const timestampIndexEnd = findClosestIndex(
                    Number(
                        moment(moment.utc(moment(alarm?.end).format('YYYY-MM-DD HH:mm:ss')).toDate())
                            .local()
                            .format('X')
                    ),
                    this.props.temperatureSensorsData?.[0].timestamps ?? []
                );
                chartPoints.push({
                    series,
                    x: String(this.props.temperatureSensorsData?.[0].timestamps[timestampIndexStart]),
                    customLabel
                });

                if (moment.utc(alarm?.end).isBefore(moment.utc())) {
                    chartPoints.push({
                        series,
                        x: String(this.props.temperatureSensorsData?.[0].timestamps[timestampIndexEnd]),
                        customLabel
                    });
                }
            });
        return chartPoints;
    };

    private _generateChartData = (): ChartTimelineModel[] => {
        let chartData: ChartTimelineModel[] = [];
        this.props.temperatureSensorsData?.forEach((d, indexSeries) => {
            const series = d.timestamps.map((d, i) => {
                const serialNumber = this.props.temperatureSensorsData?.[indexSeries].serialNumber;
                return {
                    xVal: d.toString(),
                    yVal: this.props.temperatureSensorsData?.[indexSeries].temperatures[i],
                    series: serialNumber,
                    customLabel: this.props.temperatureSensors?.find(sensor => sensor.serialNumber === serialNumber)
                        ?.sensorName
                } as ChartTimelineModel;
            });
            chartData.push(...series);
        });
        return chartData;
    };

    private _generateChartRegionData = (): ChartRegionDataModel[] => {
        let chartRegionData: ChartRegionDataModel[] = [];
        this.props.temperatureSensorsData?.forEach((d, indexSeries) => {
            if (indexSeries === 0) {
                const aboves = (this.props.temperatureProfiles || []).flatMap((rule): ChartRegionDataModel[] => {
                    const aboveThresholdConfig = rule.eventRule.config?.find(
                        config => config.name === 'above_temperature_threshold'
                    );
                    if (aboveThresholdConfig?.value) {
                        return [
                            {
                                xFrom: findClosestIndex(Number(moment(rule.checkStart).format('X')), d.timestamps),
                                xTo: findClosestIndex(Number(moment(rule.checkEnd).format('X')), d.timestamps),
                                yFrom: Number(aboveThresholdConfig.value),
                                yTo: 'max'
                            }
                        ];
                    }
                    return [];
                });
                const belows = (this.props.temperatureProfiles || []).flatMap((rule): ChartRegionDataModel[] => {
                    const belowThresholdConfig = rule.eventRule.config?.find(
                        config => config.name === 'below_temperature_threshold'
                    );
                    if (belowThresholdConfig) {
                        return [
                            {
                                xFrom: findClosestIndex(Number(moment(rule.checkStart).format('X')), d.timestamps),
                                xTo: findClosestIndex(Number(moment(rule.checkEnd).format('X')), d.timestamps),
                                yFrom: 'min',
                                yTo: Number(belowThresholdConfig.value)
                            }
                        ];
                    }
                    return [];
                });
                aboves.forEach(d => chartRegionData.push(d));
                belows.forEach(d => chartRegionData.push(d));
            }
        });
        return chartRegionData;
    };

    private _onChartFilterChange = (coldchainGraphFilter: ColdchainChartFilterParams): void => {
        this.props.onChartFilterChange?.(coldchainGraphFilter);
    };

    private _onChartLegendSensorToggle = (serialNumber: string): void => {
        this.props.onChartFilterChange?.({
            dateRange: this.props.chartFilter.dateRange,
            sensorZone: this.props.chartFilter.sensorZone,
            serialNumbers: this.props.chartFilter.serialNumbers?.includes(serialNumber)
                ? this.props.chartFilter.serialNumbers.filter(d => d !== serialNumber) ?? []
                : this.props.chartFilter.serialNumbers?.concat(serialNumber) ?? [],
            dangerPoints: this.props.chartFilter.dangerPoints
        });
    };

    private _removeLocation() {
        this._locationMarker?.setVisible(false);
        this._locationMarker?.setMap(null);
        this._locationMarker = undefined;
    }

    private _setPosition = (timestamp: number): void => {
        this._removeLocation();

        const timestampIndex = findClosestIndex(
            timestamp,
            this.props.temperatureSensorsData?.[0].timestampsSource ?? []
        );

        if (timestampIndex) {
            const points: google.maps.LatLng[] = this._polylines
                .map(polyline => polyline.getPath().getArray() as google.maps.LatLng[])
                .flat();
            const position = points?.[timestampIndex];
            if (position?.lat() && position?.lng()) {
                const marker = new this._google.maps.Marker({
                    position: {
                        lat: position?.lat(),
                        lng: position?.lng()
                    },
                    icon: {
                        url: '/markers/sensor-position.svg',
                        size: new this._google.maps.Size(22, 22),
                        anchor: new this._google.maps.Point(11, 11)
                    },
                    clickable: false,
                    anchorPoint: new this._google.maps.Point(11, 11)
                });
                this._locationMarker = marker;
                if (this._map) {
                    this._locationMarker?.setMap(this._map);
                }
            }
        }
    };

    private _initMap(element: HTMLElement) {
        this._map = new this._google.maps.Map(element, {
            zoom: 8,
            draggable: true,
            styles: mapStyles.default,
            gestureHandling: 'greedy',
            mapTypeControl: false,
            zoomControl: true,
            fullscreenControl: false,
            streetViewControl: false,
            minZoom: DEFAULT_MIN_ZOOM,
            maxZoom: DEFAULT_MAX_ZOOM,
            center: {
                lat: confDefault.map.initBounds[0].lat,
                lng: confDefault.map.initBounds[0].lng
            }
        });
    }

    private _setAlarms() {
        this.props.temperatureSensorsAlarmsData?.forEach(alarm => {
            const alarmMarker = new MarkerWithLabel({
                position: {
                    lat: alarm.lastGpsPointStartObj?.lat,
                    lng: alarm.lastGpsPointStartObj?.lng
                },
                dragable: false,
                icon: {
                    url: coldChainMarker,
                    size: new this._google.maps.Size(74, 80),
                    anchor: new this._google.maps.Point(37, 48)
                }
            });
            alarmMarker.setMap(this._map);
        });
    }

    private _removeAlarms() {
        this._alarms?.forEach(alarm => {
            alarm.setVisible(false);
            alarm.setMap(null);
        });
        this._alarms = [];
    }

    private _setRoute() {
        this._removeRoute();

        this.props.temperatureSensorsData?.forEach(sensor => {
            if (sensor.polyline) {
                const polyline = new this._google.maps.Polyline({
                    path: this._google.maps.geometry.encoding.decodePath(sensor.polyline),
                    geodesic: true,
                    strokeColor: '#07ADFA',
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });

                this._polylines?.push(polyline);
                if (this._map) {
                    this._polylines?.[this._polylines.length - 1].setMap(this._map);
                }
            }
        });

        this.fitBoundsToPolylines();
    }

    private fitBoundsToPolylines(): void {
        const bounds = new this._google.maps.LatLngBounds();

        if (this._map && this._polylines) {
            this._polylines.forEach(p => p.getPath().forEach(point => bounds.extend(point)));
            const maxZoom = MAX_ZOOM;
            this._map?.setOptions({ maxZoom });
            this._map?.fitBounds(bounds);
            const zoomAfterFit = this._map?.getZoom();
            if (maxZoom && zoomAfterFit && zoomAfterFit >= maxZoom) {
                // Refit bounds for zoomed "short polylines"
                this._map?.fitBounds(bounds);
            }
            this._map?.setOptions({
                maxZoom: DEFAULT_MAX_ZOOM
            });
        }
    }

    private _removeRoute() {
        this._polylines?.forEach(polyline => {
            polyline.setVisible(false);
            polyline.setMap(null);
        });
        this._polylines = [];
    }

    private _resetMap() {
        this._removeAlarms();
        this._removeLocation();
        this._removeRoute();
    }

    private _handleAlertFilterClick = () => {
        this.setState({
            alertsFilter: {
                open: !this.state.alertsFilter.open
            }
        });
    };

    private _handleAlertFilterCancel = () => {
        this.setState({
            alertsFilter: {
                open: false
            }
        });
    };

    private _handleAlertFilterConfirm = (alertFilter: ColdchainAlertFilterParams) => {
        this.props.onAlertFilterChange?.(alertFilter);
        this.setState({
            alertsFilter: {
                open: false
            }
        });
    };
}

export default withTranslation()(ColdchainChartMap);
