export function cleanObject(obj: Object): Object {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

export type FirstArgument<T> = T extends (arg1: infer U, ...args: any[]) => any ? U : any;

export function getParents(elem: any, selector: string) {
    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
}
