/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectFeFleetFuelCard
 */
export interface MonitoredObjectFeFleetFuelCard {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectFeFleetFuelCard
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFeFleetFuelCard
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFeFleetFuelCard
     */
    note?: string | null;
}

export function MonitoredObjectFeFleetFuelCardFromJSON(json: any): MonitoredObjectFeFleetFuelCard {
    return MonitoredObjectFeFleetFuelCardFromJSONTyped(json, false);
}

export function MonitoredObjectFeFleetFuelCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFeFleetFuelCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': json['number'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function MonitoredObjectFeFleetFuelCardToJSON(value?: MonitoredObjectFeFleetFuelCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'note': value.note,
    };
}


