/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalSystemAccess,
    ExternalSystemAccessFromJSON,
    ExternalSystemAccessFromJSONTyped,
    ExternalSystemAccessToJSON,
    ExternalSystemSecretMetadata,
    ExternalSystemSecretMetadataFromJSON,
    ExternalSystemSecretMetadataFromJSONTyped,
    ExternalSystemSecretMetadataToJSON,
    MonitoredObjectGroupNested,
    MonitoredObjectGroupNestedFromJSON,
    MonitoredObjectGroupNestedFromJSONTyped,
    MonitoredObjectGroupNestedToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyExternalSystemSecret
 */
export interface ReadOnlyExternalSystemSecret {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyExternalSystemSecret
     */
    readonly id?: number;
    /**
     * 
     * @type {ExternalSystemAccess}
     * @memberof ReadOnlyExternalSystemSecret
     */
    externalSystemAccess?: ExternalSystemAccess;
    /**
     * 
     * @type {MonitoredObjectGroupNested}
     * @memberof ReadOnlyExternalSystemSecret
     */
    monitoredObjectGroup?: MonitoredObjectGroupNested;
    /**
     * 
     * @type {ExternalSystemSecretMetadata}
     * @memberof ReadOnlyExternalSystemSecret
     */
    metadata?: ExternalSystemSecretMetadata;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyExternalSystemSecret
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyExternalSystemSecret
     */
    secret?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyExternalSystemSecret
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyExternalSystemSecret
     */
    client: number;
}

export function ReadOnlyExternalSystemSecretFromJSON(json: any): ReadOnlyExternalSystemSecret {
    return ReadOnlyExternalSystemSecretFromJSONTyped(json, false);
}

export function ReadOnlyExternalSystemSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyExternalSystemSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalSystemAccess': !exists(json, 'external_system_access') ? undefined : ExternalSystemAccessFromJSON(json['external_system_access']),
        'monitoredObjectGroup': !exists(json, 'monitored_object_group') ? undefined : MonitoredObjectGroupNestedFromJSON(json['monitored_object_group']),
        'metadata': !exists(json, 'metadata') ? undefined : ExternalSystemSecretMetadataFromJSON(json['metadata']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'client': json['client'],
    };
}

export function ReadOnlyExternalSystemSecretToJSON(value?: ReadOnlyExternalSystemSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_system_access': ExternalSystemAccessToJSON(value.externalSystemAccess),
        'monitored_object_group': MonitoredObjectGroupNestedToJSON(value.monitoredObjectGroup),
        'metadata': ExternalSystemSecretMetadataToJSON(value.metadata),
        'secret': value.secret,
        'client': value.client,
    };
}


