/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverBehaviourScore,
    DriverBehaviourScoreFromJSON,
    DriverBehaviourScoreFromJSONTyped,
    DriverBehaviourScoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverBehaviourPeriod
 */
export interface DriverBehaviourPeriod {
    /**
     * 
     * @type {string}
     * @memberof DriverBehaviourPeriod
     */
    start: string;
    /**
     * 
     * @type {Array<DriverBehaviourScore>}
     * @memberof DriverBehaviourPeriod
     */
    score: Array<DriverBehaviourScore>;
}

export function DriverBehaviourPeriodFromJSON(json: any): DriverBehaviourPeriod {
    return DriverBehaviourPeriodFromJSONTyped(json, false);
}

export function DriverBehaviourPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'score': ((json['score'] as Array<any>).map(DriverBehaviourScoreFromJSON)),
    };
}

export function DriverBehaviourPeriodToJSON(value?: DriverBehaviourPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'score': ((value.score as Array<any>).map(DriverBehaviourScoreToJSON)),
    };
}


