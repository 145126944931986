import { Logic } from '../../logic';

export class UserModules {
    private _logic: Logic;

    constructor(logic: Logic) {
        this._logic = logic;
    }

    public async getUserModules() {
        try {
            const userModulesResponse = await this._logic.api().moduleApi.moduleList({});
            return userModulesResponse.results;
        } catch (err) {
            console.error('Error during user modules response', err);
            throw err;
        }
    }
}
