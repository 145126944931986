import { Col, Popover, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TransportPlace } from 'generated/backend-api';
import qa from 'qa-selectors';
import { withTranslation, WithTranslation } from 'react-i18next';
import cn from 'classnames';
import { formatDuration } from 'common/utils/dateTimeUtils';
import { COLD_CHAIN_PROFILE_ID, KM, ZONE_RULE_NAME } from 'domain-constants';
import {
    RouteArrowRight,
    RouteBufferTime,
    RouteColdchain,
    RouteDistance,
    RouteTimer,
    TollField,
    AetrField
} from 'resources/images/planner';
import { duration } from 'moment';
import { WithLogic } from 'App';
import { AvailableCurrencies } from 'common/model/currency';
import numeral from 'numeral';
import { observer } from 'mobx-react';
import { Tooltip } from 'common/components';
import { Role } from 'logic/auth';

interface Props extends WithTranslation, WithLogic {
    transportPlace: TransportPlace;
    show: boolean;
    showToll?: boolean;
    selected?: boolean;
    disabled?: boolean;
    currency?: AvailableCurrencies;
    onRowClick?: (place: TransportPlace) => void;
}

const TransportRoute = (props: Props) => {
    const handleRowClick = () => {
        props.onRowClick?.(props.transportPlace);
    };

    const route = {
        distance: props.transportPlace.distance,
        duration: props.transportPlace.duration,
        durationTime: props.transportPlace.durationBuffer ? props.transportPlace.durationBuffer : 0,
        tollCost: props.transportPlace.routeCosts?.countries
            ? props.transportPlace.routeCosts?.countries.reduce((prevValue, country) => {
                  return prevValue + (country.price.price ? country.price.price : 0);
              }, 0)
            : 0,
        currency: props.currency ?? AvailableCurrencies.EUR
    };

    route.tollCost = props.logic.plannerLogic().getPriceByCurrency(route.tollCost ?? 0, route.currency);

    const { t } = props;
    const coldchains = props.transportPlace.eventRules
        ?.filter(r => r.name?.indexOf(ZONE_RULE_NAME) === 0)
        .map(
            r =>
                props.logic
                    .plannerLogic()
                    .availableColdchainProfiles.find(
                        profile =>
                            profile.id ===
                            props.logic.plannerLogic().getPlaceEventRuleConfigValue(r, COLD_CHAIN_PROFILE_ID)
                    )?.name
        );
    const coldchainsText =
        coldchains && coldchains.length > 0
            ? coldchains.length === 1
                ? coldchains[0]
                : `${coldchains.length} ${t('PlannerColdchain.profiles')}`
            : t('PlannerColdchain.noProfile');

    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    const useColdchain = props.logic.plannerLogic().availableTrailers.find(t => (t.sensors?.length ?? 0) > 0);
    const sensors = props.logic
        .plannerLogic()
        .availableTrailers.find(t => t.data?.id === props.logic.plannerLogic().selectedTrailerId)?.sensors;

    const breakTime =
        props.transportPlace.listOfWtmBreakRest?.reduce((partialSum, item) => {
            return partialSum + (item['activityEnd'] - item['activityStart']);
        }, 0) ?? 0;

    return (
        <Row
            className={cn('transport-route', {
                'transport-route-detail-selected': props.selected,
                'transport-route-detail-disabled': props.disabled
            })}
            onClick={handleRowClick}
        >
            {props.transportPlace.route && (
                <Row
                    className={cn('transport-route-detail', {
                        'transport-route-detail-show': props.show
                    })}
                    data-qa={qa.planner.route}
                    align="middle"
                >
                    <Col>
                        <img src={RouteDistance} alt="distance-icon" />
                        {numeral(route.distance! / 1000).format('0,0.0')} {KM}
                    </Col>
                    <Col>
                        <img src={RouteTimer} alt="timer-icon" />
                        {formatDuration(route.duration! * 1000)}
                    </Col>
                    {props.showToll && (
                        <Col>
                            <img src={TollField} alt="toll-icon" />
                            {props.logic.plannerLogic().tollCostLoading ? (
                                <Spin indicator={antIcon} wrapperClassName="loading-icon" />
                            ) : (
                                `${numeral(route.tollCost).format('0,0.00')} ${route.currency}`
                            )}
                        </Col>
                    )}
                    <Col>
                        <img src={RouteBufferTime} alt="buffer-time-icon" />
                        {duration(route.durationTime, 'seconds').format('h[h] m[m]', {
                            trim: false
                        })}
                    </Col>
                    {breakTime > 0 && (
                        <Col>
                            <Popover
                                overlayClassName="aetr-breaks-popover"
                                content={props.transportPlace.listOfWtmBreakRest?.map(item => (
                                    <Row align="middle" justify="start">
                                        <Col span={12} className="popover-label">
                                            {t(`Planner.${item['activityType']}`)}
                                        </Col>
                                        <Col span={12} className="aetr-breaks-duration">
                                            {duration(item['activityEnd'] - item['activityStart'], 'seconds').format(
                                                'h[h] m[m]'
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                                placement="top"
                            >
                                <img className="aetr-breaks" src={AetrField} alt="aetr-breaks" width="18" />
                                {duration(breakTime, 'seconds').format('h[h] m[m]')}
                            </Popover>
                        </Col>
                    )}
                    {props.logic.auth().roles().includes(Role.CLD_R) &&
                        ((useColdchain && !props.logic.plannerLogic().selectedTrailerId) ||
                            (useColdchain &&
                                props.logic.plannerLogic().selectedTrailerId &&
                                (sensors?.length ?? 0) > 0)) && (
                            <Col className="coldchain-cell">
                                <img src={RouteColdchain} alt="coldchain-icon" />
                                <Tooltip title={coldchainsText}>{coldchainsText}</Tooltip>
                            </Col>
                        )}
                </Row>
            )}
            <img className="transport-route-detail-arrow" src={RouteArrowRight} alt="arrow-right-icon" />
        </Row>
    );
};

export default withTranslation()(observer(TransportRoute));
