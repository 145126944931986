import PartnerPairs from 'modules/partner/components/PartnerPairs';
import { Row, Col } from 'antd';
import { PartnerPairingKey } from 'common/model/partner-pairing';
import { PairingItemType } from 'logic/partner/partner';
import { CcrModel } from 'logic/partner/logic/partner-ccr';

interface Props {
    model: CcrModel;
    onUnpair?: (linked: PairingItemType) => void;
}

export default function CcrLinkedItems(props: Props) {
    function onUnpair(id: string, key: PartnerPairingKey) {
        const linked: PairingItemType = {
            id,
            key
        };
        props.onUnpair?.(linked);
    }

    return (
        <Row>
            <Col span={12}>
                <div className="partner-pairing-list t-overflow-y t-small">
                    {props.model?.links && <PartnerPairs links={props.model?.links} onUnpair={onUnpair} />}
                </div>
            </Col>
        </Row>
    );
}
