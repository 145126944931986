/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripTemperatureSensor
 */
export interface TripTemperatureSensor {
    /**
     * 
     * @type {string}
     * @memberof TripTemperatureSensor
     */
    sensorId: string;
    /**
     * 
     * @type {string}
     * @memberof TripTemperatureSensor
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TripTemperatureSensor
     */
    sensorName: string;
    /**
     * 
     * @type {number}
     * @memberof TripTemperatureSensor
     */
    sensorZone: number;
    /**
     * 
     * @type {string}
     * @memberof TripTemperatureSensor
     */
    sensorType: string;
}

export function TripTemperatureSensorFromJSON(json: any): TripTemperatureSensor {
    return TripTemperatureSensorFromJSONTyped(json, false);
}

export function TripTemperatureSensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripTemperatureSensor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensorId': json['sensor_id'],
        'serialNumber': json['serial_number'],
        'sensorName': json['sensor_name'],
        'sensorZone': json['sensor_zone'],
        'sensorType': json['sensor_type'],
    };
}

export function TripTemperatureSensorToJSON(value?: TripTemperatureSensor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensor_id': value.sensorId,
        'serial_number': value.serialNumber,
        'sensor_name': value.sensorName,
        'sensor_zone': value.sensorZone,
        'sensor_type': value.sensorType,
    };
}


