/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Duration,
    DurationFromJSON,
    DurationFromJSONTyped,
    DurationToJSON,
    ExpenseItem,
    ExpenseItemFromJSON,
    ExpenseItemFromJSONTyped,
    ExpenseItemToJSON,
    LatLng,
    LatLngFromJSON,
    LatLngFromJSONTyped,
    LatLngToJSON,
} from './';

/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {Array<ExpenseItem>}
     * @memberof Segment
     */
    expenseItems: Array<ExpenseItem>;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    distanceKm?: number;
    /**
     * 
     * @type {Duration}
     * @memberof Segment
     */
    duration?: Duration;
    /**
     * 
     * @type {LatLng}
     * @memberof Segment
     */
    fromLocation: LatLng;
    /**
     * 
     * @type {LatLng}
     * @memberof Segment
     */
    toLocation: LatLng;
}

export function SegmentFromJSON(json: any): Segment {
    return SegmentFromJSONTyped(json, false);
}

export function SegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Segment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expenseItems': ((json['expenseItems'] as Array<any>).map(ExpenseItemFromJSON)),
        'distanceKm': !exists(json, 'distanceKm') ? undefined : json['distanceKm'],
        'duration': !exists(json, 'duration') ? undefined : DurationFromJSON(json['duration']),
        'fromLocation': LatLngFromJSON(json['fromLocation']),
        'toLocation': LatLngFromJSON(json['toLocation']),
    };
}

export function SegmentToJSON(value?: Segment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expenseItems': ((value.expenseItems as Array<any>).map(ExpenseItemToJSON)),
        'distanceKm': value.distanceKm,
        'duration': DurationToJSON(value.duration),
        'fromLocation': LatLngToJSON(value.fromLocation),
        'toLocation': LatLngToJSON(value.toLocation),
    };
}


