/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    ReadOnlyContactPersonNested,
    ReadOnlyContactPersonNestedFromJSON,
    ReadOnlyContactPersonNestedToJSON,
    WriteOnlyContactPersonNested,
    WriteOnlyContactPersonNestedFromJSON,
    WriteOnlyContactPersonNestedToJSON,
} from '../models';

export interface ContactPersonCreateRequest {
    data: WriteOnlyContactPersonNested;
}

export interface ContactPersonDeleteRequest {
    id: string;
}

export interface ContactPersonListRequest {
    email?: string;
    limit?: number;
    offset?: number;
}

export interface ContactPersonPartialUpdateRequest {
    id: string;
    data: WriteOnlyContactPersonNested;
}

export interface ContactPersonReadRequest {
    id: string;
}

export interface ContactPersonUpdateRequest {
    id: string;
    data: WriteOnlyContactPersonNested;
}

/**
 * no description
 */
export class ContactPersonApi extends runtime.BaseAPI {

    /**
     */
    async contactPersonCreateRaw(requestParameters: ContactPersonCreateRequest): Promise<runtime.ApiResponse<ReadOnlyContactPersonNested>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactPersonCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactPersonNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactPersonNestedFromJSON(jsonValue));
    }

    /**
     */
    async contactPersonCreate(requestParameters: ContactPersonCreateRequest): Promise<ReadOnlyContactPersonNested> {
        const response = await this.contactPersonCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactPersonDeleteRaw(requestParameters: ContactPersonDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyContactPersonNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactPersonDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactPersonNestedFromJSON(jsonValue));
    }

    /**
     */
    async contactPersonDelete(requestParameters: ContactPersonDeleteRequest): Promise<ReadOnlyContactPersonNested> {
        const response = await this.contactPersonDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactPersonListRaw(requestParameters: ContactPersonListRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     */
    async contactPersonList(requestParameters: ContactPersonListRequest): Promise<InlineResponse2004> {
        const response = await this.contactPersonListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactPersonPartialUpdateRaw(requestParameters: ContactPersonPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyContactPersonNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactPersonPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactPersonPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactPersonNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactPersonNestedFromJSON(jsonValue));
    }

    /**
     */
    async contactPersonPartialUpdate(requestParameters: ContactPersonPartialUpdateRequest): Promise<ReadOnlyContactPersonNested> {
        const response = await this.contactPersonPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactPersonReadRaw(requestParameters: ContactPersonReadRequest): Promise<runtime.ApiResponse<ReadOnlyContactPersonNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactPersonRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactPersonNestedFromJSON(jsonValue));
    }

    /**
     */
    async contactPersonRead(requestParameters: ContactPersonReadRequest): Promise<ReadOnlyContactPersonNested> {
        const response = await this.contactPersonReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async contactPersonUpdateRaw(requestParameters: ContactPersonUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyContactPersonNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contactPersonUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contactPersonUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contact-person/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyContactPersonNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyContactPersonNestedFromJSON(jsonValue));
    }

    /**
     */
    async contactPersonUpdate(requestParameters: ContactPersonUpdateRequest): Promise<ReadOnlyContactPersonNested> {
        const response = await this.contactPersonUpdateRaw(requestParameters);
        return await response.value();
    }

}
