import moment from 'moment';
import {
    CustomPlaceCrossingTstrWithCustomPlaceData,
    CustomPlaceCrossingTstrWithCustomPlaceDataFromJSONTyped,
    Output,
    OutputFromJSON
} from 'generated/backend-api';
import { users } from './users';
import { places } from './places';
import { ReadOnlyDiemsRules, ReadOnlyDiemsRulesFromJSONTyped } from 'generated/new-main';
import { CustomPlace } from 'generated/backend-api';

export const diemsPlacesOfWork: CustomPlace[] = users.map(user => ({
    id: places[0].id ?? '',
    name: places[0].name ?? '',
    center: places[0].center!,
    polygon: places[0].polygon!,
    polygonType: places[0].polygonType!,
    notes: places[0].notes ?? '',
    forbidden: places[0].forbidden ?? false,
    deleted: 0,
    clientId: 100,
    address: places[0].address ?? '',
    type: places[0].type,
    placeOfWork: [
        {
            userId: user.id!,
            start: new Date(),
            end: new Date(),
            deletedAt: new Date(),
            createdAt: new Date(),
            id: places[0].id!
        }
    ],
    countryCode: 'CZ'
}));

const diemsManualPlaceOfWorkCrossingRaw: any = [];

export const diemsManualPlaceOfWorkCrossing: CustomPlaceCrossingTstrWithCustomPlaceData[] =
    diemsManualPlaceOfWorkCrossingRaw.map((a: any) =>
        CustomPlaceCrossingTstrWithCustomPlaceDataFromJSONTyped(a, false)
    );

export const diemsRulesRaw = [
    {
        id: 'c7bb86df-c9ac-4c43-a2e4-8080bf20cd1c',
        pl_fixed_rates: null,
        deleted: 0,
        start: '2021-10-22T00:00:00Z',
        end: '2021-10-16T00:00:00Z',
        replaced_meals: [2],
        pocket_money: 20,
        created_at: '2021-10-22T14:09:32.193687Z',
        concat_foreign_diems: false,
        client: 0,
        user: 3
    },
    {
        id: '96e4d3ed-c836-434f-a32f-5665e23fe8ce',
        pl_fixed_rates: null,
        deleted: 0,
        start: '2021-10-22T00:00:00Z',
        end: '2022-10-02T00:00:00Z',
        replaced_meals: [1, 2],
        pocket_money: 20,
        created_at: '2021-10-22T14:11:14.199443Z',
        concat_foreign_diems: true,
        client: 0,
        user: 2
    },
    {
        id: '409991c6-e534-4251-9a4e-6bbd5193bd7b',
        pl_fixed_rates: {
            foreign_rates: [
                { f: 0, t: 28800, rate: 6.666666666666667 },
                { f: 28800, t: 43200, rate: 10.0 },
                { f: 43200, t: 86400, rate: 20.0 }
            ],
            domestic_rates: [
                { f: 0, t: 28800, rate: 0.0 },
                { f: 28800, t: 43200, rate: 5.0 },
                { f: 43200, t: 86400, rate: 10.0 }
            ],
            domestic_unfinished_days_in_long_trips: [
                { f: 0, t: 28800, rate: 5.0 },
                { f: 28800, t: 86400, rate: 10.0 }
            ],
            foreign_currency: 'EUR'
        },
        deleted: 0,
        start: '2022-10-02T00:00:00Z',
        end: '2022-11-01T00:00:00Z',
        replaced_meals: [2, 3],
        pocket_money: 30,
        created_at: '2021-10-22T14:10:38.510119Z',
        concat_foreign_diems: false,
        client: 0,
        user: 1
    }
];

const countryStays = [
    // 0
    {
        country_stays: {
            [moment().add(-6, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 50.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {},
                        manual_rate: {
                            domestic: true,
                            foreign: true,
                            by_user: '93189',
                            by_user_at: `${moment()
                                .add(+5, 'day')
                                .format('YYYY-MM-DD')}T10:20:00`
                        }
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 230.6
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR']
            },
            [moment().add(-5, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T02:37:20`,
                            point: null,
                            address: []
                        },
                        stay: 16640,
                        stay_foreign: 16640,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T02:37:20`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'CZ',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T10:21:45`,
                            point: null,
                            address: []
                        },
                        stay: 27865,
                        stay_foreign: 27865,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'CZ',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T10:21:45`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 41895,
                        stay_foreign: 41895,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['CZ', 'DE', 'FR'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 33.0,
                        CZK: 20.5
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 155.03085
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['CZ', 'DE', 'FR'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['CZ', 'DE', 'FR']
            },
            [moment().add(-4, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'CZ',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T08:05:37`,
                            point: null,
                            address: []
                        },
                        stay: 36337,
                        stay_foreign: 36337,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T08:05:37`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T20:18:01`,
                            point: null,
                            address: []
                        },
                        stay: 43944,
                        stay_foreign: 43944,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T20:18:01`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SI',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 6119,
                        stay_foreign: 6119,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'CZ', 'SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        CZK: 20.5,
                        EUR: 60.3333333333333
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 280.0610666666665
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'CZ', 'SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'CZ', 'SK']
            },
            [moment().add(-3, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'IT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T16:53:47`,
                            point: null,
                            address: []
                        },
                        stay: 2727 + 65300,
                        stay_foreign: 2727 + 65300,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'IT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T16:53:47`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'IT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 18373,
                        stay_foreign: 18373,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'IT'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 20.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 91.54
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'IT'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['fixed']
            },
            [moment().add(-2, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'IT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T16:30:57`,
                            point: null,
                            address: []
                        },
                        stay: 66657,
                        stay_foreign: 66657,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T16:30:57`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 19743,
                        stay_foreign: 19743,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR', 'IT'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 64.6666666666667
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 295.7400666666668
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR', 'IT'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR', 'IT']
            },
            [moment().add(-1, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'ES',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T14:22:57`,
                            point: null,
                            address: []
                        },
                        stay: 66657,
                        stay_foreign: 66657,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'ES',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T14:22:57`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'ES',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 19743,
                        stay_foreign: 19743,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR', 'ES'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 50.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 228.665
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR', 'ES'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR', 'ES']
            }
        },
        user_financial_report: {
            visited_countries: ['AT', 'CZ', 'DE', 'FR', 'IT', 'SI', 'SK'],
            country_stay_sums: {},
            daily_rate: {
                EUR: 310.0,
                CZK: 41.0
            },
            applied_diems_rule: {
                shortenings: {},
                pocket_money: {}
            },
            daily_total_rate: {
                sum_in_country_origin_currency: 1427.915983333333
            },
            country_origin_currency: 'PLN'
        }
    },
    // 1
    {
        country_stays: {
            [moment().add(-7, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-8, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 52.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 239.824
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT']
            },
            [moment().add(-6, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T06:22:12`,
                            point: null,
                            address: []
                        },
                        stay: 30132,
                        stay_foreign: 30132,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T06:22:12`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 56268,
                        stay_foreign: 56268,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 75.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 343.9425
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'DE']
            },
            [moment().add(-2, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T08:25:50`,
                            point: null,
                            address: []
                        },
                        stay: 37550,
                        stay_foreign: 37550,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T08:25:50`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'CZ',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400 - 37550,
                        stay_foreign: 86400 - 37550,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'CZ', 'SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 196.65189999999998
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'CZ', 'SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'CZ', 'SK']
            },
            [moment().add(-1, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'CZ',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'CZ',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['CZ'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 196.65189999999998
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['CZ'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['CZ']
            }
        },
        user_financial_report: {
            visited_countries: ['AT', 'DE', 'SK', 'CZ'],
            country_stay_sums: {},
            daily_rate: {
                EUR: 213.0
            },
            applied_diems_rule: {
                shortenings: {},
                pocket_money: {}
            },
            daily_total_rate: {
                sum_in_country_origin_currency: 977.0702999999999
            },
            country_origin_currency: 'PLN'
        }
    },
    // 2
    {},
    // 3
    {
        country_stays: {
            [moment().add(-7, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-8, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 198.316
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['SK']
            },
            [moment().add(-6, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 197.19369999999998
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['SK']
            },
            [moment().add(-5, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 196.96579999999997
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['SK']
            },
            [moment().add(-4, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 20.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 91.54
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['fixed']
            },
            [moment().add(-3, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T17:12:31`,
                            point: null,
                            address: []
                        },
                        stay: 69151,
                        stay_foreign: 69151,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T17:12:31`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 17249,
                        stay_foreign: 17249,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 60.3333333333333
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 275.9224333333332
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'SK']
            },
            [moment().add(-2, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T08:22:35`,
                            point: null,
                            address: []
                        },
                        stay: 37355,
                        stay_foreign: 37355,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T08:22:35`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 49045,
                        stay_foreign: 49045,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 75.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 342.9975
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'DE']
            },
            [moment().add(-1, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 49.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 224.09169999999997
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['DE']
            }
        },
        user_financial_report: {
            visited_countries: ['AT', 'DE', 'SK'],
            country_stay_sums: {},
            daily_rate: {
                EUR: 315.3333333333333
            },
            applied_diems_rule: {
                shortenings: {},
                pocket_money: {}
            },
            daily_total_rate: {
                sum_in_country_origin_currency: 1444.641133333333
            },
            country_origin_currency: 'PLN'
        }
    },
    // 4
    {
        country_stays: {
            [moment().add(-7, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-8, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T16:47:26`,
                            point: null,
                            address: []
                        },
                        stay: 67646,
                        stay_foreign: 67646,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T16:47:26`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 18754,
                        stay_foreign: 18754,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 68.3333333333333
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 315.1533333333332
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'DE']
            },
            [moment().add(-6, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 49.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 224.70909999999998
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['DE']
            },
            [moment().add(-5, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 49.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 224.44939999999997
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['DE']
            },
            [moment().add(-4, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 20.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 91.54
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['fixed']
            },
            [moment().add(-3, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 49.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 224.09169999999997
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['DE']
            },
            [moment().add(-2, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T04:04:52`,
                            point: null,
                            address: []
                        },
                        stay: 21892,
                        stay_foreign: 21892,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T04:04:52`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 64508,
                        stay_foreign: 64508,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 68.3333333333333
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 312.50883333333314
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'DE']
            },
            [moment().add(-1, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 52.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 237.8116
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT']
            }
        },
        user_financial_report: {
            visited_countries: ['AT', 'DE'],
            country_stay_sums: {},
            daily_rate: {
                EUR: 337.66666666666663
            },
            applied_diems_rule: {
                shortenings: {},
                pocket_money: {}
            },
            daily_total_rate: {
                sum_in_country_origin_currency: 1547.8779666666662
            },
            country_origin_currency: 'PLN'
        }
    },
    // 5
    {
        country_stays: {
            [moment().add(-7, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-8, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'ES',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T10:32:32`,
                            point: null,
                            address: []
                        },
                        stay: 45152,
                        stay_foreign: 45152,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'ES',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T10:32:32`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 41248,
                        stay_foreign: 41248,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR', 'ES'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 75.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 345.90000000000003
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR', 'ES'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR', 'ES']
            },
            [moment().add(-6, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'ES',
                            date: `${moment().add(-7, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'FR',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T10:21:36`,
                            point: null,
                            address: []
                        },
                        stay: 44496,
                        stay_foreign: 44496,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T10:21:36`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 41904,
                        stay_foreign: 41904,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR', 'ES'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 75.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 343.9425
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR', 'ES'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR', 'ES']
            },
            [moment().add(-5, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-6, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['FR'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 50.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 229.02999999999997
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['FR'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['FR']
            },
            [moment().add(-4, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'FR',
                            date: `${moment().add(-5, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'DE',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T04:04:37`,
                            point: null,
                            address: []
                        },
                        stay: 21877,
                        stay_foreign: 21877,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T04:04:37`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 64523,
                        stay_foreign: 64523,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['DE', 'FR'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 2.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 9.154
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['DE', 'FR'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['fixed']
            },
            [moment().add(-3, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'DE',
                            date: `${moment().add(-4, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T12:44:35`,
                            point: null,
                            address: []
                        },
                        stay: 53075,
                        stay_foreign: 53075,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T12:44:35`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 33325,
                        stay_foreign: 33325,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'DE'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 75.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 342.9975
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'DE'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'DE']
            },
            [moment().add(-2, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'AT',
                            date: `${moment().add(-3, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T06:28:41`,
                            point: null,
                            address: []
                        },
                        stay: 30521,
                        stay_foreign: 30521,
                        stay_domestic: 0
                    },
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T06:28:41`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 55879,
                        stay_foreign: 55879,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['AT', 'SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 69.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 315.55769999999995
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['AT', 'SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['AT', 'SK']
            },

            [moment().add(-1, 'day').format('YYYY-MM-DD')]: {
                diems_rules: {},
                border_crossings: [
                    {
                        entry: {
                            country: 'SK',
                            date: `${moment().add(-2, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        exit: {
                            country: 'SK',
                            date: `${moment().add(-1, 'day').format('YYYY-MM-DD')}T22:00:00`,
                            point: null,
                            address: []
                        },
                        stay: 86400,
                        stay_foreign: 86400,
                        stay_domestic: 0
                    }
                ],
                financial_report: {
                    visited_countries: ['SK'],
                    country_stay_sums: {},
                    daily_rate: {
                        EUR: 43.0
                    },
                    applied_diems_rule: {
                        shortenings: {},
                        pocket_money: {}
                    },
                    daily_total_rate: {
                        sum_in_country_origin_currency: 196.65189999999998
                    },
                    country_origin_currency: 'PLN'
                },
                visited_countries: ['SK'],
                country_stay_diems: {},
                applied_diems_rule: {
                    shortenings: {},
                    pocket_money: {}
                },
                applied_diems_countries: ['SK']
            }
        },
        user_financial_report: {
            visited_countries: ['FR', 'DE', 'SK', 'ES', 'AT'],
            country_stay_sums: {},
            daily_rate: {
                EUR: 389.0
            },
            applied_diems_rule: {
                shortenings: {},
                pocket_money: {}
            },
            daily_total_rate: {
                sum_in_country_origin_currency: 1783.2335999999998
            },
            country_origin_currency: 'PLN'
        }
    }
];

const diemsRaw = {
    diems: {
        [users[0].id ?? '']: countryStays[0],
        [users[1].id ?? '']: countryStays[1],
        [users[3].id ?? '']: countryStays[3],
        [users[4].id ?? '']: countryStays[4],
        [users[5].id ?? '']: countryStays[5]
    },
    global_financial_report: {
        visited_countries: ['AT', 'CZ', 'DE', 'FR', 'IT', 'SI', 'SK', 'ES'],
        country_stay_sums: {},
        daily_rate: {},
        applied_diems_rule: {
            shortenings: {},
            pocket_money: {}
        },
        daily_total_rate: {
            sum_in_country_origin_currency: 99420.45773333327
        },
        country_origin_currency: 'PLN'
    }
};

export const diems: Output = OutputFromJSON(diemsRaw);

export const diemsRules: ReadOnlyDiemsRules[] = diemsRulesRaw.map(a => ReadOnlyDiemsRulesFromJSONTyped(a, false));
