import { TransportEventRule, TransportPlace } from 'generated/backend-api';
import { WithTranslation, withTranslation } from 'react-i18next';
import PlannerContentContainer from '../PlannerContentContainer';
import PlannerCard from '../PlannerCard';
import { Information, TollField } from 'resources/images/planner';
import { Col, InputNumber, Row, TimePicker } from 'antd';
import moment, { duration } from 'moment';
import { useState } from 'react';
import { Checkbox, Tooltip } from 'common/components';
import { Slider } from 'common/components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { KM } from 'domain-constants';
import { AvailableCurrencies } from 'common/model/currency';
import { WithLogic } from 'App';
import numeral from 'numeral';
import { TransportAlarmType } from 'common/model/transports';
import ColdchainContainer from '../Coldchain';
import { Role } from 'logic/auth';

export interface PlaceRouteOptionsResult {
    durationBuffer: number;
    offNotification: boolean;
    corridor: number;
    eventRules?: TransportEventRule[];
    clearPreviousId: boolean;
}

interface Props extends WithTranslation, WithLogic {
    transportPlace?: TransportPlace;
    showToll?: boolean;
    currency?: AvailableCurrencies;
    readonly?: boolean;
    onSubmit?: (options: PlaceRouteOptionsResult) => void;
    onEscape?: (options: PlaceRouteOptionsResult) => void;
}

const PlaceRouteOptionsCard = (props: Props) => {
    const config = props.logic
        .plannerLogic()
        .getPlaceEventRuleConfig(props.transportPlace!, TransportAlarmType.CorridorLeave, 'distance');
    const [offNotification, setOffNotification] = useState(config !== undefined);
    const [corridor, setCorridor] = useState(config ? Number(config.value) / 1000 : 1);

    const [buffer, setBuffer] = useState(
        props.transportPlace?.durationBuffer ? props.transportPlace?.durationBuffer : 0
    );

    const handlerSubmitClick = () => {
        const options: PlaceRouteOptionsResult = {
            durationBuffer: buffer!,
            offNotification: offNotification,
            corridor: corridor * 1000,
            eventRules: props.transportPlace?.eventRules,
            clearPreviousId: true
        };
        props.onSubmit?.(options);
    };

    const handleEscape = (clear: boolean) => {
        const options: PlaceRouteOptionsResult = {
            durationBuffer: buffer!,
            offNotification: offNotification,
            corridor: corridor * 1000,
            eventRules: props.transportPlace?.eventRules,
            clearPreviousId: clear
        };
        props.onEscape?.(options);
    };

    const handleOnChange = (time: moment.Moment | null) => {
        if (time) {
            const value: number = time.hours() * 3600 + time.minutes() * 60;
            setBuffer(value);
        }
    };

    const handlerCorridorChange = (value: number) => {
        setCorridor(value);
    };

    const handlerNotificationChange = (e: CheckboxChangeEvent) => {
        setOffNotification(e.target.checked);
    };

    const { t } = props;
    let sumToll = 0;
    const tollCurrency = props.currency;

    const tolls = props.transportPlace?.routeCosts?.countries?.map((country, index) => {
        const tollDistance = country.segments?.reduce((prevValue, segment) => {
            return prevValue + (segment.tollDistance ? segment.tollDistance : 0);
        }, 0);

        const distanceText =
            tollDistance && tollDistance > 0 ? `${numeral(tollDistance / 1000).format('0,0.00')} ${KM} / ` : '-';

        sumToll += country.price.price;
        let price = props.logic
            .plannerLogic()
            .getPriceByCurrency(country.price.price, props.currency ?? AvailableCurrencies.EUR);

        return (
            <Row key={index} className="toll-row" align="middle">
                <Col span={12}>
                    <span className={`flag-icon flag-icon-squared flag-icon-${country.countryCode.toLowerCase()}`} />
                    <span className="country-name">{t(`common.countries.${country.countryCode}`)}</span>
                </Col>
                <Col span={12} className="toll-text">
                    <img className="toll-icon" src={TollField} alt="toll-icon" />
                    {`${distanceText}${numeral(price).format('0,0.00')} ${props.currency}`}
                </Col>
            </Row>
        );
    });

    sumToll = props.logic.plannerLogic().getPriceByCurrency(sumToll ?? 0, props.currency ?? AvailableCurrencies.EUR);
    const useColdchain = props.logic.plannerLogic().availableTrailers.find(t => (t.sensors?.length ?? 0) > 0);
    const sensors = props.logic
        .plannerLogic()
        .availableTrailers.find(t => t.data?.id === props.logic.plannerLogic().selectedTrailerId)?.sensors;

    return (
        <PlannerCard
            className="place-route-options-card"
            title={t('Planner.RouteOptionsCard.title')}
            onClose={handlerSubmitClick}
            onEscape={handleEscape}
        >
            {props.logic.auth().roles().includes(Role.CLD_R) &&
                ((useColdchain && !props.logic.plannerLogic().selectedTrailerId) ||
                    (useColdchain && props.logic.plannerLogic().selectedTrailerId && (sensors?.length ?? 0) > 0)) && (
                    <ColdchainContainer
                        logic={props.logic}
                        transportPlace={props.transportPlace}
                        readonly={!props.logic.auth().roles().includes(Role.CLD_W) || props.readonly}
                        sensors={sensors}
                    />
                )}
            <PlannerContentContainer key="buffer" title={t('Planner.RouteOptionsCard.bufferTitle')}>
                <Row>
                    <Col span={6}>
                        {`${t('Planner.RouteOptionsCard.buffer')} `}
                        <Tooltip title={t('Planner.RouteOptionsCard.bufferTooltip')}>
                            <img className="information-icon" src={Information} alt="information-icon" />
                        </Tooltip>
                    </Col>
                    <Col span={18} />
                </Row>
                <Row>
                    <Col span={6}>
                        <TimePicker
                            format="HH:mm"
                            defaultValue={moment(
                                duration(buffer, 'seconds').format('hh:mm', {
                                    trim: false
                                }),
                                'HH:mm'
                            )}
                            onChange={handleOnChange}
                            onSelect={handleOnChange}
                            showNow={false}
                            disabled={props.readonly}
                        />
                    </Col>
                    <Col span={1} />
                    <Col span={17}>
                        <Checkbox
                            disabled={props.readonly}
                            checked={offNotification}
                            onChange={handlerNotificationChange}
                        >
                            <span>
                                {`${t('Planner.RouteOptionsCard.offNotificationLabel')} ${t(
                                    'Planner.RouteOptionsCard.offNotificationSublabel'
                                )}`}
                            </span>
                        </Checkbox>
                    </Col>
                </Row>
                {offNotification && (
                    <Row>
                        <Col span={6} />
                        <Col span={1} />
                        <Col span={14}>
                            <Slider
                                disabled={props.readonly}
                                value={corridor}
                                min={1}
                                max={100}
                                onChange={handlerCorridorChange}
                            />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                className="corridor-input"
                                name="corridor"
                                value={corridor}
                                disabled={!offNotification || props.readonly}
                                onChange={handlerCorridorChange}
                                min={1}
                                max={100}
                                step={0}
                            />
                        </Col>
                    </Row>
                )}
            </PlannerContentContainer>
            {props.showToll && (
                <PlannerContentContainer key="tolls" className="toll-container" expandable={false}>
                    {tolls}
                    <Row className="toll-row sum-toll-value">
                        <Col span={12}>{t('Planner.RouteOptionsCard.tollSumar')}</Col>
                        <Col span={12}>{`${numeral(sumToll).format('0,0.00')} ${tollCurrency}`}</Col>
                    </Row>
                </PlannerContentContainer>
            )}
        </PlannerCard>
    );
};

export default withTranslation()(PlaceRouteOptionsCard);
