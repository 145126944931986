import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button } from '../../components';
import { InputField } from '../../fields';
import qa from 'qa-selectors';
import { Goods } from 'generated/backend-api';
import { v4 as uuidv4 } from 'uuid';

interface Props extends WithTranslation {
    type?: 'default' | 'edit';
    initialValues?: Partial<Goods>;
    isPuesc?: boolean;
    onSubmit: (values: Goods) => Promise<boolean>;
    onCancel?: () => void;
}

function TransportGoods({ onSubmit, initialValues, t, onCancel, type }: Props) {
    const schema = Yup.object().shape({
        name: Yup.string().required(t('common.required')),
        grossWeightVolume: Yup.number().nullable().required(t('common.required')),
        measurementUnit: Yup.string().required(t('common.required')),

        cmr: Yup.string(),
        cnItems: Yup.string(),
        vatRate: Yup.string(),
        wasteCode: Yup.string()
    });

    function handleOnSubmit(values: Goods) {
        onSubmit?.(values);
    }

    return (
        <Formik<Goods>
            initialValues={{
                id: initialValues?.id ?? uuidv4(),
                name: initialValues?.name ?? '',
                cmr: initialValues?.cmr ?? '',
                cnItems: initialValues?.cnItems ?? '',
                // @ts-ignore
                grossWeightVolume: initialValues?.grossWeightVolume ?? null,
                measurementUnit: initialValues?.measurementUnit ?? '',
                vatRate: initialValues?.vatRate ?? '',
                wasteCode: initialValues?.wasteCode ?? ''
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<Goods>) => (
                <div className="transport-goods-form">
                    <Form>
                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={8}>
                                <InputField
                                    size="large"
                                    name="vatRate"
                                    label={t('TransportGoodsForm.vatRate')}
                                    placeholder={t('TransportGoodsForm.vatRate')}
                                    qa={qa.planner.activity.goods.inputVatRate}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={8}>
                                <InputField
                                    size="large"
                                    name="cnItems"
                                    label={t('TransportGoodsForm.cnItems')}
                                    placeholder={t('TransportGoodsForm.cnItems')}
                                    qa={qa.planner.activity.goods.inputCnItems}
                                />
                            </Col>
                            <Col span={8}>
                                <InputField.Number
                                    size="large"
                                    name="cmr"
                                    label={t('TransportGoodsForm.crmNumber')}
                                    placeholder={t('TransportGoodsForm.crmNumber')}
                                    qa={qa.planner.activity.goods.inputCmrNumber}
                                />
                            </Col>
                            <Col span={8}>
                                <InputField
                                    size="large"
                                    name="wasteCode"
                                    label={t('TransportGoodsForm.wasteCode')}
                                    placeholder={t('TransportGoodsForm.wasteCode')}
                                    qa={qa.planner.activity.goods.inputWasteCode}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={8}>
                                <InputField
                                    size="large"
                                    name="name"
                                    label={t('TransportGoodsForm.name')}
                                    placeholder={t('TransportGoodsForm.name')}
                                    qa={qa.planner.activity.goods.inputName}
                                />
                            </Col>
                            <Col span={8}>
                                <InputField.Number
                                    size="large"
                                    name="grossWeightVolume"
                                    label={t('TransportGoodsForm.grossWeightVolume')}
                                    placeholder={t('TransportGoodsForm.grossWeightVolume')}
                                    qa={qa.planner.activity.goods.inputGrossWeightVolume}
                                />
                            </Col>
                            <Col span={8}>
                                <InputField
                                    size="large"
                                    name="measurementUnit"
                                    label={t('TransportGoodsForm.measurementUnit')}
                                    placeholder={t('TransportGoodsForm.measurementUnit')}
                                    qa={qa.planner.activity.goods.inputMeasurmentUnit}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[10, 20]} justify="end">
                            <Col>
                                <Button onClick={onCancel} qa={qa.planner.activity.goods.btnCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                    qa={qa.planner.activity.goods.btnSubmit}
                                >
                                    {type === 'edit' ? t('common.edit') : t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default withTranslation()(TransportGoods);
