/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DIHPriceListResult,
    DIHPriceListResultFromJSON,
    DIHPriceListResultFromJSONTyped,
    DIHPriceListResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface DIHPriceTagResponse
 */
export interface DIHPriceTagResponse {
    /**
     * 
     * @type {number}
     * @memberof DIHPriceTagResponse
     */
    errorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceTagResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceTagResponse
     */
    priceListDate?: string;
    /**
     * 
     * @type {Array<DIHPriceListResult>}
     * @memberof DIHPriceTagResponse
     */
    results?: Array<DIHPriceListResult>;
}

export function DIHPriceTagResponseFromJSON(json: any): DIHPriceTagResponse {
    return DIHPriceTagResponseFromJSONTyped(json, false);
}

export function DIHPriceTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DIHPriceTagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'priceListDate': !exists(json, 'priceListDate') ? undefined : json['priceListDate'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(DIHPriceListResultFromJSON)),
    };
}

export function DIHPriceTagResponseToJSON(value?: DIHPriceTagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorCode': value.errorCode,
        'errorMessage': value.errorMessage,
        'priceListDate': value.priceListDate,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(DIHPriceListResultToJSON)),
    };
}


