/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttachmentType,
    AttachmentTypeFromJSON,
    AttachmentTypeFromJSONTyped,
    AttachmentTypeToJSON,
    ContentType,
    ContentTypeFromJSON,
    ContentTypeFromJSONTyped,
    ContentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    filename: string;
    /**
     * 
     * @type {ContentType}
     * @memberof Attachment
     */
    contentType: ContentType;
    /**
     * 
     * @type {AttachmentType}
     * @memberof Attachment
     */
    type: AttachmentType;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'contentType': ContentTypeFromJSON(json['contentType']),
        'type': AttachmentTypeFromJSON(json['type']),
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'contentType': ContentTypeToJSON(value.contentType),
        'type': AttachmentTypeToJSON(value.type),
    };
}


