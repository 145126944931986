import {
    promoTable,
    promoTableLight,
    promoTrands,
    promoTrandsGraph,
    promoTrandsGraphLight,
    promoTrandsLight
} from 'resources/images/driver-behavior';
import { PromoTextBox } from './PromoTextBox';
import { useTranslation } from 'react-i18next';
import { DRIVER_BEHAVIOR_UPGRADE_LINK, L, TIME_FORMAT } from 'domain-constants';
import { DriverBehaviorCoachDataMap } from '../DriverBehaviorCoach';
import { DriverBehaviorCoachTableLabel } from '../DriverBehaviorCoachTable/DriverBehaviorCoachTableLabel';
import { Tooltip } from 'common/components';
import InfoBanner from 'common/components/InfoBanner';
import { Themes } from 'common/components/ChartTimeline';
import { Theme } from 'common/components/Settings';

interface Props {
    className?: string;
    theme?: Themes;
    driveTableMap: DriverBehaviorCoachDataMap[];
}

export function DriverBehaviorPromo(props: Props) {
    const { t } = useTranslation();
    const driverBehaviorTimesList = [
        'distance',
        'fuelConsumption',
        'departures',
        'averageAscent',
        'averageConsumption',
        'averageWeight'
    ];

    const units = {
        distance: t('common.km'),
        fuelConsumption: t('FuelConsumptionTable.liters'),
        departures: `-/100 ${t('common.km')}`,
        averageAscent: `m/100 ${t('common.km')}`,
        averageConsumption: `${L}/100 ${t('common.km')}`,
        averageWeight: t('common.metrics.tonnes')
    };

    const driverBehaviorBarTimesList = [
        'drivingTime',
        'idlingTime',
        'idlingTimeShorter2',
        'idlingTimeLonger2',
        'idlingTimeLonger30',
        'idlingTimeLonger60'
    ];

    return (
        <>
            <InfoBanner
                title={t('DriverBehavior.journeysActivity.title')}
                text={t('DriverBehavior.journeysActivity.text')}
                icon="driverWheel"
                iconOrientation="left"
                button={true}
                buttonLabel={t('common.interested')}
                onLinkButtonClick={() => {
                    window.open(DRIVER_BEHAVIOR_UPGRADE_LINK, '_blank');
                }}
            />
            <div className="driver-behavior-promo-container">
                <div
                    className={`driver-behavior-promo theme-${props.theme}${
                        props.className ? ` ${props.className}` : ''
                    }`}
                >
                    <table>
                        <tr>
                            <td colSpan={2}>
                                <PromoTextBox
                                    className="trends"
                                    title={t('DriverBehaviorDetail.promo.trendsTitle')}
                                    text={t('DriverBehaviorDetail.promo.trendsText')}
                                />
                            </td>
                            <td colSpan={6}>
                                <div className="container container-driver-trends">
                                    <div className="drive-rank-title">{t('DriveRank.title')}</div>
                                    <div className="container-trends">
                                        <Tooltip title={t('DriverBehaviorTable.filters.overal')}>
                                            <div className="driver-score-card-label overall">
                                                {t('DriverBehaviorTable.filters.overal')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.driving')}>
                                            <div className="driver-score-card-label drive">
                                                {t('DriverBehaviorTable.filters.driving')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.eco')}>
                                            <div className="driver-score-card-label eco">
                                                {t('DriverBehaviorTable.filters.eco')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.wearTear')}>
                                            <div className="driver-score-card-label wear">
                                                {t('DriverBehaviorTable.filters.wearTear')}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="recomends-container">
                                        <Tooltip title={t('DriverBehavior.recomends.title')}>
                                            <div className="recomend-title">{t('DriverBehavior.recomends.title')}</div>
                                        </Tooltip>
                                        <div className="recomend-text">{t('DriverBehaviorDetail.promo.recomend')}</div>
                                        <div className="tip-container">
                                            <div className="previous">{t('common.previous')}</div>
                                            <div className="next">{t('DriverBehaviorDetail.nextTip')}</div>
                                        </div>
                                    </div>
                                    <div className="drive-table">
                                        <table>
                                            {driverBehaviorTimesList.map((tl, index) => (
                                                <tr key={`time_${index}`} data-field-name={tl}>
                                                    <td>
                                                        {`${t(`DriverBehavior.timesTable.${tl}`)} `}
                                                        <span className="driver-behavior-times-graph-unit">{`[${units[tl]}]`}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                    <div className="bar-table">
                                        <table>
                                            {driverBehaviorBarTimesList.map(
                                                (tl, index) =>
                                                    index < 2 && (
                                                        <tr key={`timegraph_${index}`} data-field-name={tl}>
                                                            <td>
                                                                {`${t(`DriverBehavior.timesGraph.${tl}`)} `}
                                                                {tl === 'drivingTime' && (
                                                                    <span className="driver-behavior-times-graph-unit">{`[${TIME_FORMAT.toLowerCase()}]`}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                            )}
                                        </table>
                                    </div>
                                    <img
                                        className="driver-behavior-promo-trends"
                                        src={props.theme === Theme.Dark ? promoTrands : promoTrandsLight}
                                        alt="promo-trends"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={8}>
                                <div className="trends-recomends-container">
                                    <PromoTextBox
                                        className="trends-recomends"
                                        title={t('DriverBehaviorDetail.promo.trendsRecomendsTitle')}
                                        text={t('DriverBehaviorDetail.promo.trendsRecomendsText')}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <div className="container container-driver-graph">
                                    <div className="drive-rank-title">{t('DriveRank.title')}</div>
                                    <div className="container-trends">
                                        <Tooltip title={t('DriverBehaviorTable.filters.overal')}>
                                            <div className="driver-score-card-label overall">
                                                {t('DriverBehaviorTable.filters.overal')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.driving')}>
                                            <div className="driver-score-card-label drive">
                                                {t('DriverBehaviorTable.filters.driving')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.eco')}>
                                            <div className="driver-score-card-label eco">
                                                {t('DriverBehaviorTable.filters.eco')}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={t('DriverBehaviorTable.filters.wearTear')}>
                                            <div className="driver-score-card-label wear">
                                                {t('DriverBehaviorTable.filters.wearTear')}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="graph-title">{t('DriverBehaviorCharts.scoreTrend')}</div>
                                    <div className="graph-select">{t('DriverBehaviorCharts.last12Months')}</div>
                                    <img
                                        className="driver-behavior-promo-trends-graph"
                                        src={props.theme === Theme.Dark ? promoTrandsGraph : promoTrandsGraphLight}
                                        alt="promo-trends-graph"
                                    />
                                </div>
                            </td>
                            <td colSpan={2}>
                                <PromoTextBox
                                    className="trends-graph"
                                    title={t('DriverBehaviorDetail.promo.trendsGraphTitle')}
                                    text={t('DriverBehaviorDetail.promo.trendsGraphText')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <PromoTextBox
                                    className="trends-table"
                                    title={t('DriverBehaviorDetail.promo.trendsTableTitle')}
                                    text={t('DriverBehaviorDetail.promo.trendsTableText')}
                                />
                            </td>
                            <td colSpan={6}>
                                <div className="container container-driver-table">
                                    <Tooltip title={t('DriverBehavior.recomends.title')}>
                                        <div className="recomend-title">{t('DriverBehavior.recomends.title')}</div>
                                    </Tooltip>
                                    <div className="tip-container">
                                        <div className="previous">{t('common.previous')}</div>
                                        <div className="next">{t('DriverBehaviorDetail.nextTip')}</div>
                                    </div>
                                    <div className="recomend-text">{t('DriverBehaviorDetail.promo.recomend')}</div>
                                    <Tooltip title={t('common.other')}>
                                        <div className="other">{t('common.other')}</div>
                                    </Tooltip>
                                    <Tooltip title={t('common.summary')}>
                                        <div className="summary">{t('common.summary')}</div>
                                    </Tooltip>
                                    <div className="table-title">{t('DriverBehaviorDetail.ecoData.title')}</div>
                                    <div className="drive-title">
                                        <table>
                                            {props.driveTableMap?.map((dataMap, index) =>
                                                index < 5 ? (
                                                    <tr>
                                                        <td>
                                                            <DriverBehaviorCoachTableLabel
                                                                key={`label_${index}`}
                                                                dataMap={dataMap}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : undefined
                                            )}
                                        </table>
                                    </div>
                                    <img
                                        className="driver-behavior-promo-table"
                                        src={props.theme === Theme.Dark ? promoTable : promoTableLight}
                                        alt="promo-trends-table"
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    );
}
