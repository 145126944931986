import { AetrModel, AetrActivity, DailyPerformance } from 'modules/statistics/modules/aetr/AetrModule';
import { Logic } from '../logic';
import { AETRReport, AETRWeeklyData } from 'generated/backend-api/models';
import { UserModel } from '../user/users';
import { Role } from 'logic/auth';
import { DAILY_RIDE, MAX_WEEKLY_RIDE } from 'domain-constants';
import moment from 'moment';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { search } from 'common/utils/search';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

export class StatisticsAetrReadingLogic {
    private _users: UserModel[];
    private _vehicles: ReadOnlyMonitoredObjectFeSb[];

    constructor(private _logic: Logic) {
        this._users = [];
        this._vehicles = [];
    }

    async init(): Promise<void> {
        this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.AEI_R]);
        this._users = await this._logic.users().drivers();
    }

    settings(): {
        drivers: string[];
        vehicles: string[];
    } {
        const settings = this._logic.settings().getProp('statistics').aetr.filter;
        const filter = {
            drivers: settings.drivers,
            vehicles: settings.vehicles
        };
        return filter;
    }

    setSettings(settings: { drivers?: string[]; vehicles?: string[] }) {
        const originalSettings = this._logic.settings().getProp('statistics').aetr.filter;
        const modifiedSettings = {
            drivers: settings.drivers ?? originalSettings.drivers,
            vehicles: settings.vehicles ?? originalSettings.vehicles
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProps().statistics,
            aetr: { filter: modifiedSettings }
        });
        // this._logic.notification().send(
        //     {
        //         type: 'settings',
        //         data: this._logic.settings().getProps()
        //     },
        //     this._logic.auth().user().id
        // );
    }

    async tableData(): Promise<AetrModel[]> {
        if (this._logic.demo().isActive) {
            return this._toAetrModels(this._logic.demo().data.aetr);
        }

        const aetrReport = await this._logic.api().aetrApi.aetrReportV1AetrReportGet({
            timestamp: new Date(),
            history: 4,
            historyIncludeIntervals: 1
        });
        return this._toAetrModels(aetrReport);
    }

    async anotherTableData(tachoCardNumber: string, week: number): Promise<AetrModel[]> {
        if (this._logic.demo().isActive) {
            return this._toAetrModels(this._logic.demo().data.aetr);
        }
        try {
            const aetrReport = await this._logic.api().aetrApi.aetrReportV1AetrReportGet({
                timestamp: moment().subtract(week, 'weeks').endOf('week').add(1, 'ms').toDate(), // KONIEC_TYZDNA_NEDELA_24:00
                history: 1,
                historyIncludeIntervals: 2,
                tachographCard: tachoCardNumber
            });
            return this._toAetrModels(aetrReport, week);
        } catch (err) {
            console.log('Get aetr report err', err);
            throw err;
        }
    }

    private _toAetrModels(aetrReports: AETRReport[], initialWeek: number = 0): AetrModel[] {
        const aetrModels: AetrModel[] = [];

        if (aetrReports && aetrReports.length > 0) {
            aetrReports.forEach(aetrReport => {
                if (aetrReport.weeklyData && aetrReport?.weeklyData?.length > 0) {
                    let week: number = initialWeek;
                    aetrReport.weeklyData
                        .sort((a, b) => (a.weekStart > b.weekStart ? -1 : 1))
                        .forEach((weeklyData: AETRWeeklyData) => {
                            let daillyPerformances: DailyPerformance[] = [];

                            for (let i = 0; i < 7; i++) {
                                if (
                                    weeklyData.aetrIntervals?.[i] !== undefined &&
                                    moment.utc(weeklyData.weekStart).startOf('day').add(i, 'days') <=
                                        moment.utc().startOf('day')
                                ) {
                                    const daillyPerformance: DailyPerformance = {
                                        date: moment
                                            .utc(weeklyData.weekStart)
                                            .startOf('day')
                                            .add(i, 'days')
                                            .toISOString(),
                                        drivingDuration: weeklyData.aetrIntervals?.[i]?.['driving_duration'] ?? 0,
                                        workingDuration: weeklyData.aetrIntervals?.[i]?.['working_duration'] ?? 0,
                                        restingDuration: weeklyData.aetrIntervals?.[i]?.['resting_duration'] ?? 0,
                                        extendedDrive: weeklyData.aetrIntervals?.[i]?.['extended_shift'] ?? false,
                                        reducedDailyRest:
                                            weeklyData.aetrIntervals?.[i]?.['reduced_daily_rest'] ?? false,
                                        intervals:
                                            weeklyData.aetrIntervals?.[i]?.['intervals'].map((interval: any) => ({
                                                activity: AetrActivity[interval[2]],
                                                startTime: moment
                                                    .utc(weeklyData.weekStart)
                                                    .add(i, 'days')
                                                    .add(interval[0], 'seconds')
                                                    .toISOString(),
                                                endTime: moment
                                                    .utc(weeklyData.weekStart)
                                                    .add(i, 'days')
                                                    .add(interval[1], 'seconds')
                                                    .toISOString()
                                            })) ?? [],
                                        reliable: weeklyData.reliable ?? false
                                    };

                                    daillyPerformances.push(daillyPerformance);
                                }
                            }

                            const driveMaxHours = aetrReport.currentDay?.driveMaxHours
                                ? aetrReport.currentDay?.driveMaxHours * 3600
                                : DAILY_RIDE;
                            const drivingDurationRemainingPercentage =
                                aetrReport.currentDay?.drivingDuration ?? 0
                                    ? 100 -
                                      Math.min((aetrReport.currentDay?.drivingDuration ?? 0) / driveMaxHours, 1) * 100
                                    : 100;
                            const drivingDurationRemaining = Math.max(
                                driveMaxHours - (aetrReport.currentDay?.drivingDuration ?? 0),
                                0
                            );
                            const driveMaxHoursWeekly = MAX_WEEKLY_RIDE; // TODO: BE
                            const weeklyDrivingDurationPercentage = weeklyData.drivingDuration
                                ? 100 - Math.min(weeklyData.drivingDuration / driveMaxHoursWeekly, 1) * 100
                                : 100;
                            const weeklyDrivingDurationRemaining = Math.max(
                                driveMaxHoursWeekly - weeklyData.drivingDuration,
                                0
                            );

                            const aetrModel: AetrModel = {
                                tachographCard: aetrReport.tachographCard,
                                driverName: this._getUserLabel(String(aetrReport.userId)),
                                current: {
                                    vehicleNumberPlate: this._getRegistrationNumber(
                                        aetrReport.lastActivity.monitoredObjectId || ''
                                    ),
                                    location: undefined,
                                    drivingDurationRemainingPercentage: drivingDurationRemainingPercentage,
                                    drivingDuration: aetrReport.currentDay?.drivingDuration ?? 0,
                                    drivingDurationRemaining: drivingDurationRemaining,
                                    weeklyDrivingDurationPercentage: weeklyDrivingDurationPercentage,
                                    weeklyDrivingDuration: weeklyData.drivingDuration ?? 0,
                                    weeklyDrivingDurationRemaining: weeklyDrivingDurationRemaining,
                                    biweeklyDrivingDuration: weeklyData.drivingDuration14Days ?? 0,
                                    lastWeekDrivingDuration: weeklyData.weeklyRestDuration ?? 0,
                                    maxWeeklyRide: driveMaxHoursWeekly,
                                    maxDailyRide: driveMaxHours,
                                    reliable: aetrReport.currentDay?.reliable ?? false
                                },
                                evaluation: {
                                    weekStartTime: '',
                                    shifts: weeklyData.daysDriven ?? 0,
                                    lastDailyRestStartTime: '',
                                    lastDailyRestDuration: weeklyData.weeklyRestDuration ?? 0,
                                    nextDailyRestStartTime: '-',
                                    reducedDailyRests: weeklyData.reducedDailyRests ?? 0,
                                    extendedDailyShifts: weeklyData.extendedShifts ?? 0,
                                    nextWeekWeeklyRestStartTime: '', // TODO: BE
                                    nextWeeklyRestDuration: weeklyData.nextWeeklyRestDuration ?? 0,
                                    nextWeeklyRestDurationDebt: weeklyData.nextWeeklyRestDurationDebt ?? 0,
                                    averageDriverSpeed: '',
                                    expectedWeeklyDrivingRange: 0,
                                    expectedBiweeklyDrivingRange: 0
                                },
                                isCrew: aetrReport.currentDay?.isCrew,
                                week: String(week),
                                dailyPerformances: daillyPerformances
                            };
                            aetrModels.push(aetrModel);

                            week++;
                        });
                }
            });
        }
        return aetrModels;
    }

    private _getRegistrationNumber(monitoredObjectId: string | null | undefined): string {
        if (monitoredObjectId) {
            if (this._vehicles) {
                const vehicle = this._vehicles.find(vehiclesEntry => vehiclesEntry.id === parseInt(monitoredObjectId));
                return vehicle ? getRegistrationNumber(!!vehicle.disabledAt, vehicle.registrationNumber) : '';
            }
            return '';
        }
        return '';
    }

    private _getUserLabel(userId: string | null | undefined) {
        if (userId) {
            if (this._users) {
                const user = this._users.find(userEntry => String(userEntry.id) === String(userId));
                return user ? user.name + ' ' + user.surname : '';
            }
            return '';
        }
        return '';
    }

    destory() {}

    search(searchText: string | undefined, data: AetrModel[]): AetrModel[] {
        return search(searchText, ['driverName', 'current.vehicleNumberPlate'], data);
    }
}
