import { WithTranslation, withTranslation } from 'react-i18next';
import { Aggregator, ExpenseModel } from 'logic/statistics/statistics-expenses';
import cn from 'classnames';
import { KM } from 'domain-constants';
import moment from 'moment';
import numeral from 'numeral';
import { Loading } from 'common/components/Loading';
import { Role } from 'logic/auth';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import { Button, Popover } from 'common/components';
import qa from 'qa-selectors';
import { NoData } from 'common/components/NoData';
import TableHeadLabel from 'common/components/TableHeadLabel';
import { Col, Row } from 'antd';

const distance = (duration: number) => Math.round(duration / 100) / 10;

export interface ExpensesModel {
    main: ExpenseModel;
    details?: ExpenseModel[];
    exchangeRateErr?: boolean;
}

export type ExpensesHead = [[(string | JSX.Element)[], number, number?][], string[]];

interface OwnProps {
    data: ExpensesModel[];
    loading: boolean;
    aggregator: Aggregator;
    expandedRow?: string;
    roles: Role[];
    type?: ExpenseType;
    selectedExpense?: string;
    onDeleteClick: (id: string) => void;
    onEditClick: (data: ExpenseModel) => void;
    onRowClick?: (id: string) => void;
    onAggregatorChange?: (aggregator: Aggregator) => void;
}

type Props = OwnProps & WithTranslation;

function ExpensesTable(props: Props) {
    const { t } = props;

    const head: ExpensesHead = [
        [
            [
                [
                    <span
                        key="vehicle"
                        className={cn({
                            selected: props.aggregator === Aggregator.VEHICLE
                        })}
                        data-qa={
                            props.aggregator === Aggregator.VEHICLE
                                ? qa.expenses.table.group.fieldActiveRnGroup
                                : undefined
                        }
                    >
                        {t('ExpensesTable.vehicle')}
                    </span>,
                    ' ',
                    <i key="exchange" className="fa fa-fw fa-exchange" aria-hidden="true" />,
                    ' ',
                    <span
                        key="date"
                        className={cn({
                            selected: props.aggregator === Aggregator.DATE
                        })}
                        data-qa={
                            props.aggregator === Aggregator.DATE
                                ? qa.expenses.table.group.fieldActiveDateGroup
                                : undefined
                        }
                    >
                        {t('ExpensesTable.date')}
                    </span>
                ],
                1
            ],
            [t('ExpensesTable.driver'), 1],
            // [t('ExpensesTable.fraud'), 1],
            [t('ExpensesTable.type'), 1],
            [t('ExpensesTable.supplier'), 1],
            [t('ExpensesTable.cargo'), 2],
            [t('ExpensesTable.quantity'), 2],
            [t('ExpensesTable.pricePerUnit'), 2],
            [t('ExpensesTable.priceTotal'), 2],
            [t('ExpensesTable.totemPrice'), 1],
            [t('ExpensesTable.saved'), 1],
            [t('ExpensesTable.odo'), 1],
            [t('ExpensesTable.source'), 1],
            [t('ExpensesTable.state'), 1],
            [t('ExpensesTable.actions'), 2]
            // [t('ExpensesTable.note'), 1]
        ],
        [
            '',
            '',
            // '',
            '',
            '',
            t('ExpensesTable.country'),
            t('ExpensesTable.VAT'),
            t('ExpensesTable.total'),
            t('ExpensesTable.unit'),
            t('ExpensesTable.withoutVAT'),
            t('ExpensesTable.withVAT'),
            t('ExpensesTable.withoutVAT'),
            t('ExpensesTable.withVAT'),
            '',
            '',
            '',
            '',
            '',
            '',
            ''
            // ''
        ]
    ];

    const headColdWidth = [180, 200, 90, 200, 70, 50, 100, 50, 110, 110, 130, 130, 100, 100, 100, 90, 80, 50, 50];

    // https://jira.eurowag.com/browse/T033-10910
    // hotfix hide column saved
    // @ts-ignore
    if (window.showExpensesSavedColumn) {
        head[0].splice(9, 0, [t('ExpensesTable.saved'), 1]);
        head[1].splice(15, 0, '');
    }

    const borders = head[0].reduce(
        (a, v) => {
            a.v += v[1];
            a.o.push(a.v);
            return a;
        },
        { v: 0, o: [] as number[] }
    ).o;

    function onRowClick(id: string) {
        props.onRowClick?.(id);
    }

    function onAggregatorChange() {
        props.onAggregatorChange?.(props.aggregator === Aggregator.VEHICLE ? Aggregator.DATE : Aggregator.VEHICLE);
    }

    return (
        <div className="expenses-table">
            {props.loading ? (
                <Loading />
            ) : (
                <table className="t-table t-table-all t-hoverable">
                    <thead>
                        <tr>
                            {head[0].map((h, i) => (
                                <th
                                    key={i}
                                    className={cn([
                                        't-border-primary',
                                        {
                                            't-border-right': head[0].length - 1 > i,
                                            'expense-table-exchange': i === 0
                                        }
                                    ])}
                                    colSpan={h[1]}
                                    onClick={i === 0 ? onAggregatorChange : undefined}
                                >
                                    <TableHeadLabel title={h[0]} />
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {head[1].map((h, i) => {
                                return (
                                    <th
                                        style={{ width: `${headColdWidth[i]}px` }}
                                        key={i}
                                        className={cn([
                                            't-center',
                                            {
                                                't-border-primary': borders.includes(i + 1),
                                                't-border-secondary': !borders.includes(i + 1),
                                                't-border-right': head[1].length - 1 > i
                                            }
                                        ])}
                                    >
                                        <TableHeadLabel title={h} level="normal" />
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {props.data && props.data.length > 0 ? (
                        props.data.map((expense, i) => (
                            <tbody
                                key={i}
                                className={cn({
                                    selected: String(i) === props.expandedRow
                                })}
                            >
                                <tr
                                    onClick={onRowClick.bind(undefined, String(i))}
                                    className={cn({
                                        selected: String(i) === props.expandedRow,
                                        odd: i % 2 && String(i) !== props.expandedRow
                                    })}
                                >
                                    <td className="t-border-primary t-border-right">
                                        <Row gutter={8}>
                                            <Col>
                                                <Button
                                                    type={String(i) === props.expandedRow ? 'primary' : 'default'}
                                                    size="small"
                                                    icon={
                                                        <i
                                                            className={cn(
                                                                'fa',
                                                                {
                                                                    'fa-chevron-down': String(i) === props.expandedRow
                                                                },
                                                                {
                                                                    'fa-chevron-up': String(i) !== props.expandedRow
                                                                }
                                                            )}
                                                        />
                                                    }
                                                    data-qa={
                                                        String(i) === props.expandedRow
                                                            ? qa.expenses.table.group.btnClose
                                                            : qa.expenses.table.group.btnOpen
                                                    }
                                                />
                                            </Col>
                                            {expense.exchangeRateErr && (
                                                <Col>
                                                    <Popover trigger="hover" content={t('ExpensesTable.rateInfo')}>
                                                        <i className="fas fa-exclamation-circle exchange-rate-err" />
                                                    </Popover>
                                                </Col>
                                            )}
                                            <Col>
                                                {props.aggregator === Aggregator.VEHICLE ? (
                                                    <span data-qa={qa.expenses.table.group.fieldRn}>
                                                        {expense.main.RN}
                                                    </span>
                                                ) : (
                                                    <span data-qa={qa.expenses.table.group.fieldDate}>
                                                        {moment(expense.main.date).format('L')}
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className="t-center t-border-primary t-border-right">{expense.main.driver}</td>
                                    {/* <td className="t-center t-border-primary t-border-right" /> */}
                                    <td
                                        className="t-center t-border-primary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldType}
                                    >
                                        {expense.main.type ? t(`ExpensesFilter.${expense.main.type}`) : ''}
                                    </td>
                                    <td className="t-center t-border-primary t-border-right" />
                                    <td
                                        className="t-center t-border-secondary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldCountry}
                                    >
                                        {expense.main.cargoCountry}
                                    </td>
                                    <td
                                        className="t-center t-border-primary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldVAT}
                                    >
                                        {expense.main.cargoVAT > 0 ? expense.main.cargoVAT : ''}
                                    </td>
                                    <td
                                        className="t-center t-border-secondary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldQuantityValue}
                                    >
                                        {!expense.main.quantityUnit
                                            ? ''
                                            : (expense.main.quantityTotal &&
                                                  numeral(expense.main.quantityTotal).format('0,0.00')) ||
                                              '-'}
                                    </td>
                                    <td
                                        className="t-center t-border-primary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldQuantityUnit}
                                    >
                                        {expense.main.quantityUnit ? expense.main.quantityUnit : ''}
                                    </td>
                                    <td className="t-center t-border-secondary t-border-right">
                                        {!expense.main.quantityUnit
                                            ? ''
                                            : (expense.main.pricePerUnitWithoutVATAverage && (
                                                  <>
                                                      <span
                                                          data-qa={qa.expenses.table.group.fieldPricePerUnitWithoutVAT}
                                                      >
                                                          {numeral(expense.main.pricePerUnitWithoutVATAverage).format(
                                                              expense.main.type ===
                                                                  (ExpenseType.Fuel || ExpenseType.Adblue)
                                                                  ? '0,0.000'
                                                                  : '0,0.00'
                                                          )}
                                                      </span>{' '}
                                                      {expense.main.cargoCurrency}
                                                  </>
                                              )) ||
                                              '-'}
                                    </td>
                                    <td className="t-center t-border-primary t-border-right">
                                        {!expense.main.quantityUnit
                                            ? ''
                                            : (expense.main.pricePerUnitWithVATAverage && (
                                                  <>
                                                      <span data-qa={qa.expenses.table.group.fieldPricePerUnitWithVAT}>
                                                          {numeral(expense.main.pricePerUnitWithVATAverage).format(
                                                              expense.main.type ===
                                                                  (ExpenseType.Fuel || ExpenseType.Adblue)
                                                                  ? '0,0.000'
                                                                  : '0,0.00'
                                                          )}
                                                      </span>{' '}
                                                      {expense.main.cargoCurrency}
                                                  </>
                                              )) ||
                                              '-'}
                                    </td>
                                    <td className="t-center t-border-secondary t-border-right">
                                        {(expense.main.priceTotalWithoutVAT && (
                                            <>
                                                <span data-qa={qa.expenses.table.group.fieldPriceTotalWithoutVAT}>
                                                    {numeral(expense.main.priceTotalWithoutVAT).format('0,0.00')}
                                                </span>{' '}
                                                {expense.main.cargoCurrency}
                                            </>
                                        )) ||
                                            '-'}
                                    </td>
                                    <td className="t-center t-border-primary t-border-right">
                                        {(expense.main.priceTotalWithVAT && (
                                            <>
                                                <span data-qa={qa.expenses.table.group.fieldPriceTotalWithVAT}>
                                                    {numeral(expense.main.priceTotalWithVAT).format('0,0.00')}
                                                </span>{' '}
                                                {expense.main.cargoCurrency}
                                            </>
                                        )) ||
                                            '-'}
                                    </td>
                                    <td className="t-center t-border-primary t-border-right">
                                        {/* sum of totem prices in main row does not make sense */}
                                    </td>
                                    {
                                        <td
                                            className="t-center t-border-primary t-border-right"
                                            data-qa={qa.expenses.table.group.fieldSaved}
                                        >
                                            {(expense.main.saved > 0 && (
                                                <>
                                                    <span data-qa={qa.expenses.table.group.fieldPriceTotalWithVAT}>
                                                        {numeral(expense.main.saved).format('0,0.00')}
                                                    </span>{' '}
                                                    {expense.main.cargoCurrency}
                                                </>
                                            )) ||
                                                '-'}
                                        </td>
                                    }
                                    <td className="t-center t-border-primary t-border-right" />
                                    <td
                                        className="t-center t-border-primary t-border-right"
                                        data-qa={qa.expenses.table.group.fieldSource}
                                    >
                                        {expense.main.source ? t(`ExpensesFilter.${expense.main.source}`) : ''}
                                    </td>
                                    <td
                                        className="t-center t-border-primary  t-border-right"
                                        data-qa={qa.expenses.table.group.fieldState}
                                    >
                                        {expense.main.state ? t(`ExpensesFilter.${expense.main.state}`) : ''}
                                    </td>
                                    <td className="t-center t-border-primary" />
                                    <td className="t-center t-border-primary" />
                                </tr>
                                {expense.details &&
                                    String(i) === props.expandedRow &&
                                    expense.details.map((detail, i) => (
                                        <tr
                                            key={i}
                                            className={cn('detail t-small', {
                                                'selected-expense': props.selectedExpense === detail.id
                                            })}
                                        >
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                title={moment(detail.date).format('L LT')}
                                            >
                                                {props.aggregator === Aggregator.DATE ? (
                                                    (
                                                        <span data-qa={qa.expenses.table.expenses.fieldDate}>
                                                            {detail.RN}
                                                        </span>
                                                    ) || '-'
                                                ) : (
                                                    <span data-qa={qa.expenses.table.expenses.fieldRn}>
                                                        {moment(detail.date).format('L LT')}
                                                    </span>
                                                )}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldDriver}
                                            >
                                                {detail.driver || '-'}
                                            </td>
                                            {/* <td className="t-center t-border-primary t-border-right">
                                                {detail.source !== ExpenseSource.Manual ? (
                                                    <div
                                                        className={cn('fa', {
                                                            'fa-check':
                                                                detail.fraudDetection ===
                                                                FraudDetectionState.NO_FRAUD_DETECTED,
                                                            't-text-success':
                                                                detail.fraudDetection ===
                                                                FraudDetectionState.NO_FRAUD_DETECTED,
                                                            'fa-close':
                                                                detail.fraudDetection ===
                                                                FraudDetectionState.FRAUD_DETECTED,
                                                            't-text-danger':
                                                                detail.fraudDetection ===
                                                                FraudDetectionState.FRAUD_DETECTED,
                                                            'fa-question':
                                                                detail.fraudDetection !==
                                                                    FraudDetectionState.NO_FRAUD_DETECTED &&
                                                                detail.fraudDetection !==
                                                                    FraudDetectionState.FRAUD_DETECTED,
                                                            't-text-warning':
                                                                detail.fraudDetection !==
                                                                    FraudDetectionState.NO_FRAUD_DETECTED &&
                                                                detail.fraudDetection !==
                                                                    FraudDetectionState.FRAUD_DETECTED
                                                        })}
                                                        title={
                                                            detail.fraudDetection ===
                                                            FraudDetectionState.NO_FRAUD_DETECTED
                                                                ? t('ExpensesTable.transactionOk')
                                                                : detail.fraudDetection ===
                                                                  FraudDetectionState.FRAUD_DETECTED
                                                                ? t('ExpensesTable.detectedFraud')
                                                                : t('ExpensesTable.cannotMeasure')
                                                        }
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </td> */}
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldType}
                                            >
                                                {t(`ExpensesFilter.${detail.type}`)}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldSupplier}
                                            >
                                                {detail.supplier || '-'}
                                            </td>
                                            <td
                                                className="t-center t-border-secondary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldCountry}
                                            >
                                                {detail.cargoCountry}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldVAT}
                                            >
                                                {detail.cargoVAT}
                                            </td>
                                            <td
                                                className="t-center t-border-secondary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldQuantityValue}
                                            >
                                                {(detail.quantityTotal &&
                                                    numeral(detail.quantityTotal).format('0,0.00')) ||
                                                    '-'}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldQauntityUnit}
                                            >
                                                {[ExpenseType.Fuel, ExpenseType.Adblue].indexOf(
                                                    detail.type as ExpenseType
                                                ) !== -1
                                                    ? detail.quantityUnit
                                                    : t('common.pc')}
                                            </td>
                                            <td className="t-center t-border-secondary t-border-right">
                                                {(detail.pricePerUnitWithoutVAT && (
                                                    <>
                                                        <span
                                                            data-qa={
                                                                qa.expenses.table.group.fieldPricePerUnitWithoutVAT
                                                            }
                                                        >
                                                            {numeral(detail.pricePerUnitWithoutVAT).format(
                                                                detail.type === (ExpenseType.Fuel || ExpenseType.Adblue)
                                                                    ? '0,0.000'
                                                                    : '0,0.00'
                                                            )}
                                                        </span>{' '}
                                                        {detail.cargoCurrency}
                                                    </>
                                                )) ||
                                                    '-'}
                                            </td>
                                            <td className="t-center t-border-primary t-border-right">
                                                {(detail.pricePerUnitWithVAT && (
                                                    <>
                                                        <span
                                                            data-qa={qa.expenses.table.group.fieldPricePerUnitWithVAT}
                                                        >
                                                            {numeral(detail.pricePerUnitWithVAT).format(
                                                                detail.type === (ExpenseType.Fuel || ExpenseType.Adblue)
                                                                    ? '0,0.000'
                                                                    : '0,0.00'
                                                            )}
                                                        </span>{' '}
                                                        {detail.cargoCurrency}
                                                    </>
                                                )) ||
                                                    '-'}
                                            </td>
                                            <td className="t-center t-border-secondary t-border-right">
                                                {(detail.priceTotalWithoutVAT && (
                                                    <>
                                                        <span
                                                            data-qa={qa.expenses.table.group.fieldPriceTotalWithoutVAT}
                                                        >
                                                            {numeral(detail.priceTotalWithoutVAT).format('0,0.00')}
                                                        </span>{' '}
                                                        {detail.cargoCurrency}
                                                    </>
                                                )) ||
                                                    '-'}
                                            </td>
                                            <td className="t-center t-border-primary t-border-right">
                                                {(detail.priceTotalWithVAT && (
                                                    <>
                                                        <span data-qa={qa.expenses.table.group.fieldPriceTotalWithVAT}>
                                                            {numeral(detail.priceTotalWithVAT).format('0,0.00')}
                                                        </span>{' '}
                                                        {detail.cargoCurrency}
                                                    </>
                                                )) ||
                                                    '-'}
                                            </td>
                                            <td className="t-center t-border-primary t-border-right">
                                                {(detail.fuelioTotemPrice &&
                                                    [ExpenseType.Adblue, ExpenseType.Fuel].includes(
                                                        detail.type as ExpenseType
                                                    ) && (
                                                        <>
                                                            <span data-qa={qa.expenses.table.expenses.fieldTotemPrice}>
                                                                {numeral(detail.fuelioTotemPrice).format('0,0.00')}
                                                            </span>{' '}
                                                            {detail.fuelioTotemPriceCurrency}
                                                        </>
                                                    )) ||
                                                    '-'}
                                            </td>
                                            {
                                                <td className="t-center t-border-primary t-border-right">
                                                    {(detail.saved && (
                                                        <>
                                                            <span data-qa={qa.expenses.table.expenses.fieldSaved}>
                                                                {numeral(detail.saved).format('0,0.00')}
                                                            </span>{' '}
                                                            {detail.savedCurrency}
                                                        </>
                                                    )) ||
                                                        '-'}
                                                </td>
                                            }
                                            <td className="t-center t-border-primary t-border-right">
                                                {(detail.odo && (
                                                    <>
                                                        <span data-qa={qa.expenses.table.expenses.fieldOdo}>
                                                            {numeral(distance(detail.odo)).format('0,0.00')}
                                                        </span>{' '}
                                                        {KM}
                                                    </>
                                                )) ||
                                                    '-'}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldSource}
                                            >
                                                {t(`ExpensesFilter.${detail.source}`)}
                                            </td>
                                            <td
                                                className="t-center t-border-primary t-border-right"
                                                data-qa={qa.expenses.table.expenses.fieldState}
                                            >
                                                {t(`ExpensesFilter.${detail.state}`)}
                                            </td>
                                            {detail?.source &&
                                            [ExpenseSource.Manual, ExpenseSource.Import].includes(detail?.source) &&
                                            props.roles.includes(Role.EX_W) ? (
                                                <>
                                                    <td
                                                        onClick={props.onEditClick.bind(undefined, detail)}
                                                        className="t-center t-border-primary pointer"
                                                        data-qa={qa.expenses.table.expenses.btnEdit}
                                                    >
                                                        <img alt="edit" src="/img-svg/icn-24-edit.svg" />
                                                    </td>
                                                    <td
                                                        onClick={props.onDeleteClick.bind(undefined, detail.id)}
                                                        className="t-center t-border-primary pointer"
                                                        data-qa={qa.expenses.table.expenses.btnDelete}
                                                    >
                                                        <img alt="delete" src="/img-svg/icn-24-delete.svg" />
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td />
                                                    <td />
                                                </>
                                            )}
                                        </tr>
                                    ))}
                            </tbody>
                        ))
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={head[1].length}>
                                    <NoData />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            )}
        </div>
    );
}

export default withTranslation()(ExpensesTable);
