/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200FromJSONTyped,
    InlineResponse200ToJSON,
    PlannedTransportPlace,
    PlannedTransportPlaceFromJSON,
    PlannedTransportPlaceFromJSONTyped,
    PlannedTransportPlaceToJSON,
    RouteCostsDetails,
    RouteCostsDetailsFromJSON,
    RouteCostsDetailsFromJSONTyped,
    RouteCostsDetailsToJSON,
    RouteType,
    RouteTypeFromJSON,
    RouteTypeFromJSONTyped,
    RouteTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PlannedTransport
 */
export interface PlannedTransport {
    /**
     * 
     * @type {RouteType}
     * @memberof PlannedTransport
     */
    type: RouteType;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransport
     */
    firstRta: string;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransport
     */
    lastRta?: string;
    /**
     * 
     * @type {number}
     * @memberof PlannedTransport
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof PlannedTransport
     */
    distance?: number;
    /**
     * 
     * @type {Array<PlannedTransportPlace>}
     * @memberof PlannedTransport
     */
    places: Array<PlannedTransportPlace>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PlannedTransport
     */
    possibleAvoidsSygic?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {InlineResponse200}
     * @memberof PlannedTransport
     */
    tollCost?: InlineResponse200;
    /**
     * 
     * @type {RouteCostsDetails}
     * @memberof PlannedTransport
     */
    routeCostsDetails?: RouteCostsDetails;
}

export function PlannedTransportFromJSON(json: any): PlannedTransport {
    return PlannedTransportFromJSONTyped(json, false);
}

export function PlannedTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlannedTransport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': RouteTypeFromJSON(json['type']),
        'firstRta': json['firstRta'],
        'lastRta': !exists(json, 'lastRta') ? undefined : json['lastRta'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'places': ((json['places'] as Array<any>).map(PlannedTransportPlaceFromJSON)),
        'possibleAvoidsSygic': !exists(json, 'possibleAvoidsSygic') ? undefined : json['possibleAvoidsSygic'],
        'tollCost': !exists(json, 'tollCost') ? undefined : InlineResponse200FromJSON(json['tollCost']),
        'routeCostsDetails': !exists(json, 'routeCostsDetails') ? undefined : RouteCostsDetailsFromJSON(json['routeCostsDetails']),
    };
}

export function PlannedTransportToJSON(value?: PlannedTransport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': RouteTypeToJSON(value.type),
        'firstRta': value.firstRta,
        'lastRta': value.lastRta,
        'duration': value.duration,
        'distance': value.distance,
        'places': ((value.places as Array<any>).map(PlannedTransportPlaceToJSON)),
        'possibleAvoidsSygic': value.possibleAvoidsSygic,
        'tollCost': InlineResponse200ToJSON(value.tollCost),
        'routeCostsDetails': RouteCostsDetailsToJSON(value.routeCostsDetails),
    };
}


