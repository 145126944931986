/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCOSettingChar
 */
export interface TCOSettingChar {
    /**
     * 
     * @type {string}
     * @memberof TCOSettingChar
     */
    type: TCOSettingCharTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingChar
     */
    defaultValue: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingChar
     */
    hint: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingChar
     */
    units: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingChar
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingChar
     */
    visible: boolean;
}

export function TCOSettingCharFromJSON(json: any): TCOSettingChar {
    return TCOSettingCharFromJSONTyped(json, false);
}

export function TCOSettingCharFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOSettingChar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'defaultValue': json['default_value'],
        'hint': json['hint'],
        'units': json['units'],
        'required': json['required'],
        'visible': json['visible'],
    };
}

export function TCOSettingCharToJSON(value?: TCOSettingChar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'default_value': value.defaultValue,
        'hint': value.hint,
        'units': value.units,
        'required': value.required,
        'visible': value.visible,
    };
}

/**
* @export
* @enum {string}
*/
export enum TCOSettingCharTypeEnum {
    Integer = 'integer',
    Float = 'float',
    Array = 'array'
}


