import * as ColdchainImages from 'resources/images/coldchain';

export function getMonitoredObjectZoneImage(type: string, zone: number, zoneMax: number) {
    switch (`${type}_${zone}_${zoneMax}`) {
        case 'trailer_1_1': {
            return ColdchainImages.trailer11;
        }
        case 'trailer_1_2': {
            return ColdchainImages.trailer12;
        }
        case 'trailer_1_3': {
            return ColdchainImages.trailer13;
        }
        case 'trailer_2_2': {
            return ColdchainImages.trailer22;
        }
        case 'trailer_2_3': {
            return ColdchainImages.trailer23;
        }
        case 'trailer_3_3': {
            return ColdchainImages.trailer33;
        }
        case 'truck_1_1': {
            return ColdchainImages.truck11;
        }
        case 'truck_1_2': {
            return ColdchainImages.truck12;
        }
        case 'truck_1_3': {
            return ColdchainImages.truck13;
        }
        case 'truck_2_2': {
            return ColdchainImages.truck22;
        }
        case 'truck_2_3': {
            return ColdchainImages.truck23;
        }
        default:
        case 'truck_3_3': {
            return ColdchainImages.truck33;
        }
    }
}
