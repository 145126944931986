import { Rate } from 'antd';
import { RateProps } from 'antd/lib/rate';
import cn from 'classnames';

interface Props extends RateProps {
    color?: 'yellow' | 'purple' | 'green' | 'blue' | 'lightblue';
    size?: 'xsmall' | 'small' | 'default';
}

export default function EnhancedRate({ color, size, ...props }: Props) {
    return (
        <Rate
            className={cn({
                'ant-rate-blue': color === 'blue',
                'ant-rate-lightblue': color === 'lightblue',
                'ant-rate-yellow': color === 'yellow',
                'ant-rate-purple': color === 'purple',
                'ant-rate-green': color === 'green',
                'ant-rate-small': size === 'small',
                'ant-rate-xsmall': size === 'xsmall'
            })}
            {...props}
        />
    );
}
