/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserContact,
    UserContactFromJSON,
    UserContactFromJSONTyped,
    UserContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsersSimpleInfo
 */
export interface UsersSimpleInfo {
    /**
     * 
     * @type {number}
     * @memberof UsersSimpleInfo
     */
    readonly id?: number;
    /**
     * 
     * @type {UserContact}
     * @memberof UsersSimpleInfo
     */
    contact?: UserContact | null;
}

export function UsersSimpleInfoFromJSON(json: any): UsersSimpleInfo {
    return UsersSimpleInfoFromJSONTyped(json, false);
}

export function UsersSimpleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersSimpleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contact': !exists(json, 'contact') ? undefined : UserContactFromJSON(json['contact']),
    };
}

export function UsersSimpleInfoToJSON(value?: UsersSimpleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': UserContactToJSON(value.contact),
    };
}


