import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/components/Form';
import { useState } from 'react';
import { Col, Row } from 'antd';
import numeral from 'numeral';

interface Props {
    proposedPrice?: number | null;
    profit?: number | null;
    readonly?: boolean;
    onProposedPriceChange: (proposedPrice: number | string | null | undefined) => void;
    onSendToShipperClick: (proposedPrice: number | null | undefined) => void;
    onEditClick: (isEdit: boolean) => void;
}

export default function DFFProposePrice(props: Props) {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = (isEdit: boolean) => {
        setIsEditing(isEdit);
        props.onEditClick(isEdit);
    };

    return (
        <div className="dff-proposal-detail-propose-price">
            {isEditing || props.readonly ? (
                <div className="dff-proposal-detail-propose-price-edit">
                    <Row gutter={[16, 8]} align="middle">
                        <Col>
                            <Row gutter={[8, 8]} align="middle">
                                <Col>
                                    {props.readonly ? (
                                        <div className="dff-proposal-detail-propose-price-edit-item">
                                            <div className="dff-proposal-detail-propose-price-edit-item-value">
                                                {props.proposedPrice
                                                    ? `${numeral(props.proposedPrice).format('0,0')} €`
                                                    : '-'}
                                            </div>
                                            <div className="dff-proposal-detail-propose-price-edit-item-label">
                                                {t('DispatcherBoardProposalDetailDff.proposedPriceLabel')}
                                            </div>
                                        </div>
                                    ) : (
                                        <Input.Number
                                            label={t('DispatcherBoardProposalDetailDff.proposedPriceLabel')}
                                            postfix="EUR"
                                            value={props.proposedPrice ?? 0}
                                            min={0}
                                            size="large"
                                            onChange={props.onProposedPriceChange}
                                        />
                                    )}
                                </Col>
                                {/* T035-1587 do not display yet */}
                                {/* <Col>
                                    <div className="dff-proposal-detail-propose-price-edit-item">
                                        <div className="dff-proposal-detail-propose-price-edit-item-value">
                                            {props.profit ? `${props.profit.toFixed(0)} %` : '-'}
                                        </div>
                                        <div className="dff-proposal-detail-propose-price-edit-item-label">
                                            {t('DispatcherBoardProposalDetailDff.profit')}
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>

                        {!props.readonly && (
                            <>
                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={() => props.onSendToShipperClick(props.proposedPrice)}
                                    >
                                        {t('DispatcherBoardProposalDetailDff.sendToShipper')}
                                    </Button>
                                </Col>

                                <Col flex="auto" className="dff-proposal-detail-propose-price-edit-cancel">
                                    <Button
                                        block
                                        type="link"
                                        icon={<i className="fas fa-times" />}
                                        onClick={() => handleEditClick(false)}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            ) : (
                <Button onClick={() => handleEditClick(true)}>
                    {t('DispatcherBoardProposalDetailDff.proposeNewPrice')}
                </Button>
            )}
        </div>
    );
}
