import { Component } from 'react';
import i18n from 'i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Logic } from 'logic/logic';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import BorderCrossingFilter from './components/BorderCrossingFilter';
import BorderCrossingSummary from './components/BorderCrossingSummary';
import BorderCrossingTable from './components/BorderCrossingTable';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { debounce } from 'debounce';
import { DriverModel } from 'logic/user/users';
import { CountrySelectModel } from 'logic/partner/partner';
import { AddressStructured } from 'generated/graphql';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import { DateRange } from 'common/model/date-time';
import { DATE_FORMAT } from 'domain-constants';
import { getRegistrationNumber } from 'common/utils/registrationName';
import TableBar from 'common/components/TableBar';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
}

export interface AutocompleteOpt {
    code: string;
    label: string;
}

export interface BorderCrossingModel {
    id: string;
    country?: string;
    driver?: string;
    vehicle?: string;
    entryDate?: string;
    entryLocation?: string;
    addressStructuredEntry?: AddressStructured[];
    addressStructuredExit?: AddressStructured[];
    exitDate?: string;
    exitLocation?: string;
    distance: number;
    duration: number;
}

export interface BorderCrossingFilterModel {
    vehicle?: string;
    driver?: string;
    countries?: string[];
    dateRange: DateRange;
}

export interface BorderCrossingCountryModel {
    country: string;
    entries: number;
    duration: number;
    distance: number;
}

interface State {
    table: {
        data?: PaginatedResponse<BorderCrossingModel[]>;
        loading: boolean;
    };
    filter: {
        vehicle?: string;
        vehicleOpts: AutocompleteOpt[];
        vehicles: ReadOnlyMonitoredObjectFeSb[];
        vehicleSearch?: string;
        driver?: string;
        driverOpts: AutocompleteOpt[];
        drivers: DriverModel[];
        driverSearch?: string;
        countries?: string[];
        countryOpts: CountrySelectModel[];
        dateRange: DateRange;
        dateChanged?: boolean;
    };
    summary: {
        data: BorderCrossingCountryModel[];
    };
    helper?: {
        content: string;
    };
    export: {
        enable: boolean;
        loading: boolean;
    };
}

class StatisticsBorderCrossingModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {
            table: {
                loading: true
            },
            filter: {
                vehicleOpts: [],
                vehicles: [],
                driverOpts: [],
                drivers: [],
                countryOpts: [],
                dateRange: {
                    start: moment.utc().startOf('month').format(DATE_FORMAT),
                    end: moment.utc().endOf('month').format(DATE_FORMAT)
                }
            },
            summary: {
                data: []
            },
            export: {
                enable: false,
                loading: false
            }
        };
    }

    componentDidMount() {
        this._filterData().then(() => {
            this.setState({ table: { loading: false } });
            this._logic.statisticsBorderCrossing().onDataChange(data => {
                this.setState({
                    table: {
                        data: data,
                        loading: false
                    }
                });
            });
        });
    }

    render() {
        return (
            <>
                <div className="border-crossing page">
                    <TableBar
                        heading={this.props.t('BorderCrossing.title')}
                        onHelperClick={this._onBarHelperClick}
                        filters={[
                            <BorderCrossingFilter
                                roles={this.props.logic.auth().roles()}
                                vehicle={this.state.filter.vehicle}
                                vehicleOpts={this.state.filter.vehicleOpts}
                                driver={this.state.filter.driver}
                                driverOpts={this.state.filter.driverOpts}
                                countries={this.state.filter.countries}
                                countryOpts={this.state.filter.countryOpts}
                                dateRange={this.state.filter.dateRange}
                                loading={this.state.table.loading}
                                exportEnable={this.state.export.enable}
                                exportLoading={this.state.export.loading}
                                onFilterChange={this._onFilterFilterChange}
                                onVehicleTextChange={this._onFilterVehicleTextChange}
                                onDriverTextChange={this._onFilterDriverTextChange}
                                onExport={this._onFilterExport}
                            />
                        ]}
                    />
                    {!this.state.table.loading && this.state.table.data && this.state.summary.data.length > 0 && (
                        <BorderCrossingSummary data={this.state.summary.data} />
                    )}
                    <BorderCrossingTable
                        data={this.state.table.data}
                        vehicles={this.state.filter.vehicles}
                        drivers={this.state.filter.drivers}
                        onPaginationChange={this._onPaginationChange}
                        loading={this.state.table.loading}
                    />
                </div>
                <HelperModal
                    name="border-crossing"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _filterData = async () => {
        const drivers = await this._logic.users().drivers();
        const driverOpts: AutocompleteOpt[] = drivers.map(driver => ({
            code: driver.id.toString(),
            label: `${driver.name} ${driver.surname}`
        }));

        const vehicles = await this._logic.vehicles().getMonitoredObjectFilters();
        const vehicleOpts: AutocompleteOpt[] = vehicles.map(vehicle => ({
            code: String(vehicle.id),
            label: getRegistrationNumber(!!vehicle.disabledAt, vehicle.registrationNumber)
        }));

        const countries = await this._logic.partner().getCountryList();

        this.setState(state => ({
            filter: {
                ...state.filter,
                driverOpts,
                drivers,
                vehicleOpts,
                vehicles,
                countryOpts: countries
            },
            table: {
                loading: false
            }
        }));
    };

    private _onFilterFilterChange = (filter: BorderCrossingFilterModel): void => {
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                },
                filter: {
                    ...state.filter,
                    ...filter,
                    countries: filter.countries
                }
            }),
            () => {
                if (this.state.filter.vehicle || this.state.filter.driver) {
                    this.props.logic
                        .statisticsBorderCrossing()
                        .getBorderCrossingList(
                            this.state.filter.vehicle,
                            this.state.filter.driver,
                            this.state.filter.dateRange.start,
                            this.state.filter.dateRange.end,
                            this.state.filter.countries
                        )
                        .then(res => {
                            this.setState({
                                table: {
                                    data: res,
                                    loading: false
                                },
                                summary: { data: this._summData(res.data) },
                                export: {
                                    enable: res.data.length > 0,
                                    loading: false
                                }
                            });
                        })
                        .catch(err => {
                            console.error('Get border crosing err:', err);
                            this.setState({
                                table: {
                                    loading: false
                                }
                            });
                        });
                } else {
                    this.setState({
                        table: {
                            loading: false
                        }
                    });
                }
            }
        );
    };

    private _onFilterVehicleTextChange = debounce((value: string): void => {
        if (value === '') {
            this._logic
                .vehicles()
                .getMonitoredObjectFilters()
                .then(vehicles => {
                    const vehicleOpts: AutocompleteOpt[] = vehicles.map(vehicle => ({
                        code: String(vehicle.id),
                        label: vehicle.registrationNumber
                    }));
                    this.setState(state => ({
                        filter: {
                            ...state.filter,
                            vehicleOpts,
                            vehicleSearch: value
                        }
                    }));
                });
        } else {
            // TODO: use filter in query instead of filtering results
            this._logic
                .vehicles()
                .getMonitoredObjectFilters()
                .then(vehicles => {
                    const vehicleOpts: AutocompleteOpt[] = vehicles
                        .map(vehicle => ({
                            code: String(vehicle.id),
                            label: vehicle.registrationNumber
                        }))
                        .filter(v =>
                            v.label.replace(/ /g, '').toLowerCase().includes(value.replace(/ /g, '').toLowerCase())
                        );
                    this.setState(state => ({
                        filter: {
                            ...state.filter,
                            vehicleOpts,
                            vehicleSearch: value
                        }
                    }));
                });
        }
    }, 400);

    private _onFilterDriverTextChange = debounce((value: string): void => {
        if (value === '') {
            this._logic
                .users()
                .drivers()
                .then(drivers => {
                    const driversOpts: AutocompleteOpt[] = drivers.map(driver => ({
                        code: driver.id.toString(),
                        label: `${driver.name} ${driver.surname}`
                    }));
                    this.setState(state => ({
                        filter: { ...state.filter, driverOpts: driversOpts }
                    }));
                });
        } else {
            // TODO: use filter in query instead of filtering results
            this._logic
                .users()
                .drivers()
                .then(drivers => {
                    const driversOpts: AutocompleteOpt[] = drivers
                        .map(driver => ({
                            code: driver.id.toString(),
                            label: `${driver.name} ${driver.surname}`
                        }))
                        .filter(v =>
                            v.label.replace(/ /g, '').toLowerCase().includes(value.replace(/ /g, '').toLowerCase())
                        );

                    this.setState(state => ({
                        filter: { ...state.filter, driverOpts: driversOpts }
                    }));
                });
        }
    }, 400);

    private _summData = (data: BorderCrossingModel[]): BorderCrossingCountryModel[] => {
        function sum<T extends BorderCrossingModel, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((sum, num: any) => sum + num, 0);
        }

        const reducedData = data.reduce((a, v) => {
            const key = v.country || '';

            if (!a[key]) {
                a[key] = [];
            }
            a[key].push(v);

            return a;
        }, {} as { [key: string]: BorderCrossingModel[] });

        return Object.keys(reducedData).map(
            (key): BorderCrossingCountryModel => ({
                country: key,
                distance: sum(reducedData[key], 'distance'),
                duration: sum(reducedData[key], 'duration'),
                entries: reducedData[key].length
            })
        );
    };

    private _onFilterExport = (): void => {
        // fetch data when pagination will be implemented on BE... (probably  never)
        const tableData = this.state.table.data?.data;
        if (tableData) {
            this.setState(
                state => ({
                    export: {
                        ...state.export,
                        loading: true
                    }
                }),
                () => {
                    this._logic
                        .borderCrossing()
                        .downloadBorderCrossingExport(
                            this.state.summary.data,
                            tableData,
                            this.state.filter.dateRange.start,
                            this.state.filter.dateRange.end,
                            this.state.filter.drivers,
                            this.state.filter.vehicles
                        )
                        .finally(() => {
                            this.setState(state => ({
                                export: {
                                    ...state.export,
                                    loading: false
                                }
                            }));
                        });
                }
            );
        }
    };

    private _onPaginationChange = (pagination: PaginationParams): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this.props.logic
            .statisticsBorderCrossing()
            .getBorderCrossingList(
                this.state.filter.vehicle,
                this.state.filter.driver,
                this.state.filter.dateRange.start,
                this.state.filter.dateRange.end,
                this.state.filter.countries,
                pagination.limit,
                pagination.offset
            )
            .then(res => {
                this.setState({
                    table: {
                        data: res,
                        loading: false
                    },
                    export: {
                        enable: res.data.length > 0,
                        loading: false
                    }
                });
            })
            .catch(_err => {
                this.setState({
                    table: {
                        loading: false
                    }
                });
            });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'track&trace';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(StatisticsBorderCrossingModule));
