import { ReactNode } from 'react';
import { Input } from 'antd';
import AutoCompleteAntd, { Props as AutocompleteAntProps } from 'common/components/AutoCompleteAntd';
import { Control } from 'common/components/Form/Control';
import { FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import cn from 'classnames';

interface Props extends AutocompleteAntProps {
    label?: ReactNode;
    name: string;
    placeholderText: string;
    search?: boolean;
    showArrow?: boolean;
}

const MultiSelectAutocompleteField = ({
    name,
    label,
    placeholderText,
    search = true,
    showArrow = true,
    ...props
}: Props) => {
    return (
        <FieldArray name={name}>
            {({ form: { errors, touched }, push }: FieldArrayRenderProps) => (
                <Control label={label} error={getIn(touched, name) && getIn(errors, name)}>
                    <AutoCompleteAntd
                        style={{ width: '100%' }}
                        {...props}
                        onSelect={(value, option) => {
                            push(option.key);
                            props.onSelect?.(value, option);
                        }}
                        showArrow={showArrow}
                    >
                        {search ? (
                            <Input.Search size="large" placeholder={placeholderText} />
                        ) : (
                            <Input
                                size="large"
                                placeholder={placeholderText}
                                className={cn({ empty: !props.value || props.value === '' })}
                            />
                        )}
                    </AutoCompleteAntd>
                </Control>
            )}
        </FieldArray>
    );
};

export default MultiSelectAutocompleteField;
