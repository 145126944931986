import React from 'react';
import moment from 'moment';
import cn from 'classnames';

interface Props {
    /**
     * In seconds
     */
    max: number;
    /**
     * In seconds
     */
    remainingDuration: number;
    qa?: string;
}

function AlarmRemainsDuration(props: Props) {
    const remainingDurationHelper: number = props.remainingDuration > props.max ? 0 : props.remainingDuration;

    const lowerAs30Percent: boolean = props.max * 0.3 > remainingDurationHelper;
    const lowerAs20Percent: boolean = props.max * 0.2 > remainingDurationHelper;

    const momentDuration: moment.Duration = moment.duration(remainingDurationHelper, 'seconds');
    const hours = Math.floor(momentDuration.asHours());
    const mins = Math.floor(momentDuration.asMinutes()) - hours * 60;

    return (
        <span
            className={cn('alarm-duration t-bold', {
                't-text-warning': lowerAs30Percent && !lowerAs20Percent,
                't-text-danger': lowerAs20Percent
            })}
            data-qa={props.qa}
        >
            {lowerAs30Percent && <i className="fa fa-warning" />}{' '}
            {`${hours >= 10 ? hours : `0${hours}`}:${mins >= 10 ? mins : `0${mins}`}`}
        </span>
    );
}

export default AlarmRemainsDuration;
