/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FuelStatistics
 */
export interface FuelStatistics {
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    distanceKilometers: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    idleTimeSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionLiters: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionIdleLiters: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionActivityLiters: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionActivityLitersPer100km: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionLitersPer100km: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStatistics
     */
    consumptionIdleLitersPer1h: number;
}

export function FuelStatisticsFromJSON(json: any): FuelStatistics {
    return FuelStatisticsFromJSONTyped(json, false);
}

export function FuelStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distanceKilometers': json['distance_kilometers'],
        'idleTimeSeconds': json['idle_time_seconds'],
        'consumptionLiters': json['consumption_liters'],
        'consumptionIdleLiters': json['consumption_idle_liters'],
        'consumptionActivityLiters': json['consumption_activity_liters'],
        'consumptionActivityLitersPer100km': json['consumption_activity_liters_per_100km'],
        'consumptionLitersPer100km': json['consumption_liters_per_100km'],
        'consumptionIdleLitersPer1h': json['consumption_idle_liters_per_1h'],
    };
}

export function FuelStatisticsToJSON(value?: FuelStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distance_kilometers': value.distanceKilometers,
        'idle_time_seconds': value.idleTimeSeconds,
        'consumption_liters': value.consumptionLiters,
        'consumption_idle_liters': value.consumptionIdleLiters,
        'consumption_activity_liters': value.consumptionActivityLiters,
        'consumption_activity_liters_per_100km': value.consumptionActivityLitersPer100km,
        'consumption_liters_per_100km': value.consumptionLitersPer100km,
        'consumption_idle_liters_per_1h': value.consumptionIdleLitersPer1h,
    };
}


