import { ChangeEvent, Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { CcrModel } from 'logic/partner/logic/partner-ccr';
import { StringValidator, FormValidator } from 'validators';
import moment, { Moment } from 'moment';
import { DatePicker } from 'common/components';
import FormActions from 'common/components/FormActions';

export interface CcrForm {
    id: string;
    ident: string;
    identErr: string;
    sn: string;
    snErr: string;
    createdOn: Moment;
}

export const defaultValidationErrors = {
    identErr: '',
    snErr: ''
};

export const defaultInputs: CcrModel = {
    id: '',
    ident: '',
    sn: '',
    createdOn: moment()
};

export interface CcrFormValidator {
    ident: string;
    sn: string;
}

export const identValidator = new StringValidator({
    required: true
});

export const snValidator = new StringValidator({
    required: true
});

export const formValidator = new FormValidator<CcrFormValidator>()
    .addValidator('ident', identValidator)
    .addValidator('sn', snValidator);

interface Props extends WithTranslation {
    model: CcrModel;
    onFormCancel?: () => void;
    onFormSubmit?: (model: CcrModel) => void;
}

interface State {
    form?: CcrForm;
}

class VehiclesDetail extends Component<Props, State> {
    ccrModel?: CcrModel;

    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: this.props.model.id,
                ident: this.props.model.ident,
                sn: this.props.model.sn,
                createdOn: this.props.model.createdOn
            }
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="partner-sidepanel">
                <div className="partner-detail t-bar-block t-col t-overflow-y t-text-inverse t-half">
                    <div className="t-bar-block t-bold t-center t-uppercase t-large">
                        <div className="t-bar-item">{t('common.detail')}</div>
                    </div>
                    <form className="t-small editable" onSubmit={this._onFormSubmit}>
                        <div className="t-bar-block">
                            <div className="t-row">
                                <div className="t-half t-bold" title={t('common.id')}>
                                    {t('common.id')}
                                </div>
                                <div className="t-half">
                                    <input
                                        className={cn('t-input t-padding-small editable', {
                                            error: this.state.form?.identErr,
                                            success: !this.state.form?.identErr && !!this.state.form?.ident
                                        })}
                                        value={this.state.form ? this.state.form?.ident : this.props.model?.ident}
                                        onChange={this._onIdentChange}
                                    />
                                    {this.state.form?.identErr && (
                                        <span className="t-bold t-text-danger">
                                            {t('validator.' + this.state.form?.identErr)}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="t-row">
                                <div className="t-half t-bold" title={t('Partner.sn')}>
                                    {t('Partner.sn')}
                                </div>
                                <div className="t-half">
                                    <input
                                        className={cn('t-input t-padding-small editable', {
                                            error: this.state.form?.snErr,
                                            success: !this.state.form?.snErr && !!this.state.form?.sn
                                        })}
                                        value={this.state.form ? this.state.form?.sn : this.props.model?.sn}
                                        onChange={this._onSnChange}
                                    />
                                    {this.state.form?.snErr && (
                                        <span className="t-bold t-text-danger">
                                            {t('validator.' + this.state.form?.snErr)}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="t-row">
                                <div className="t-half t-bold" title={t('Partner.createdOn')}>
                                    {t('Partner.createdOn')}
                                </div>
                                <div className="t-half">
                                    <DatePicker.DateTimePicker
                                        defaultValue={this.state.form?.createdOn}
                                        onChange={value => this._onCreatedOnChange(value as Moment)}
                                        showTime={false}
                                        trigger={
                                            <div className="t-input t-padding-small editable">
                                                {moment(this.state.form?.createdOn).format('L') as string}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <FormActions onCancelClick={this._onFormCancel} />
                    </form>
                </div>
            </div>
        );
    }

    private _onFormCancel = (): void => {
        this.ccrModel = undefined;
        this.setState({ form: undefined });
    };

    private _onIdentChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = identValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form!,
                ident: value,
                identErr: validateRes.err as string
            }
        }));
    };

    private _onSnChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = snValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form!,
                sn: value,
                snErr: validateRes.err as string
            }
        }));
    };

    private _onCreatedOnChange = (value: Moment): void => {
        this.setState(state => ({
            form: {
                ...state.form!,
                createdOn: value
            }
        }));
    };

    private _onFormSubmit = (e: FormEvent): void => {
        e.preventDefault();
        if (this.state.form) {
            const validationRes = formValidator.validate({
                ident: this.state.form.ident,
                sn: this.state.form.sn
            });
            if (!validationRes.valid) {
                this.setState(state => ({
                    form: {
                        ...state.form!,
                        identErr: validationRes.err?.ident ?? '',
                        snErr: validationRes.err?.sn ?? ''
                    }
                }));
            }
        }
    };
}

export default withTranslation()(VehiclesDetail);
