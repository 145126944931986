/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20016,
    InlineResponse20016FromJSON,
    InlineResponse20016ToJSON,
    ReadOnlyTaskType,
    ReadOnlyTaskTypeFromJSON,
    ReadOnlyTaskTypeToJSON,
    WriteOnlyTaskType,
    WriteOnlyTaskTypeFromJSON,
    WriteOnlyTaskTypeToJSON,
} from '../models';

export interface MaintenanceTaskTypeCreateRequest {
    data: WriteOnlyTaskType;
}

export interface MaintenanceTaskTypeDeleteRequest {
    id: string;
}

export interface MaintenanceTaskTypeDeleteWithTasksRequest {
    id: string;
    data: object;
}

export interface MaintenanceTaskTypeListRequest {
    clientIdOrNull?: string;
    limit?: number;
    offset?: number;
}

export interface MaintenanceTaskTypePartialUpdateRequest {
    id: string;
    data: WriteOnlyTaskType;
}

export interface MaintenanceTaskTypeReadRequest {
    id: string;
}

export interface MaintenanceTaskTypeUpdateRequest {
    id: string;
    data: WriteOnlyTaskType;
}

/**
 * no description
 */
export class MaintenanceTaskTypeApi extends runtime.BaseAPI {

    /**
     */
    async maintenanceTaskTypeCreateRaw(requestParameters: MaintenanceTaskTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyTaskType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTaskTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTaskTypeFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypeCreate(requestParameters: MaintenanceTaskTypeCreateRequest): Promise<ReadOnlyTaskType> {
        const response = await this.maintenanceTaskTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypeDeleteRaw(requestParameters: MaintenanceTaskTypeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyTaskType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTaskTypeFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypeDelete(requestParameters: MaintenanceTaskTypeDeleteRequest): Promise<ReadOnlyTaskType> {
        const response = await this.maintenanceTaskTypeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypeDeleteWithTasksRaw(requestParameters: MaintenanceTaskTypeDeleteWithTasksRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskTypeDeleteWithTasks.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskTypeDeleteWithTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/{id}/delete/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async maintenanceTaskTypeDeleteWithTasks(requestParameters: MaintenanceTaskTypeDeleteWithTasksRequest): Promise<object> {
        const response = await this.maintenanceTaskTypeDeleteWithTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypeListRaw(requestParameters: MaintenanceTaskTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20016>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientIdOrNull !== undefined) {
            queryParameters['client_id_or_null'] = requestParameters.clientIdOrNull;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20016FromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypeList(requestParameters: MaintenanceTaskTypeListRequest): Promise<InlineResponse20016> {
        const response = await this.maintenanceTaskTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypePartialUpdateRaw(requestParameters: MaintenanceTaskTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyTaskType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTaskTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTaskTypeFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypePartialUpdate(requestParameters: MaintenanceTaskTypePartialUpdateRequest): Promise<ReadOnlyTaskType> {
        const response = await this.maintenanceTaskTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypeReadRaw(requestParameters: MaintenanceTaskTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyTaskType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTaskTypeFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypeRead(requestParameters: MaintenanceTaskTypeReadRequest): Promise<ReadOnlyTaskType> {
        const response = await this.maintenanceTaskTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskTypeUpdateRaw(requestParameters: MaintenanceTaskTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyTaskType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTaskTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTaskTypeFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskTypeUpdate(requestParameters: MaintenanceTaskTypeUpdateRequest): Promise<ReadOnlyTaskType> {
        const response = await this.maintenanceTaskTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
