import React, { ReactNode } from 'react';
import { Input, InputNumber } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Control } from '../Control';
import { InputNumberProps } from 'antd/lib/input-number';
import cn from 'classnames';
import { pxInputWidthText } from 'common/utils/textUtils';

interface Props extends InputProps {
    label?: ReactNode;
    error?: ReactNode;
    type?: 'text' | 'password';
    maxLength?: number;
    qa?: string;
    disabled?: boolean;
    innerRef?: React.RefObject<Input>;
}

interface NumberProps extends InputNumberProps {
    label?: ReactNode;
    error?: ReactNode;
    qa?: string;
    disabled?: boolean;
    postfix?: string;
    addonAfter?: React.ReactNode;
    disableStepIcon?: boolean;
}

function EnhancedInput({ label, error, qa, disabled, maxLength, ...props }: Props) {
    return (
        <Control label={label} error={error}>
            <Input
                ref={props.innerRef}
                data-qa={qa}
                disabled={disabled}
                className={cn({ empty: !props.value || props.value === '' })}
                maxLength={maxLength}
                {...props}
            />
        </Control>
    );
}

EnhancedInput.TextArea = Input.TextArea;
EnhancedInput.Search = Input.Search;

EnhancedInput.Number = function ({ label, error, qa, disabled, maxLength, disableStepIcon, ...props }: NumberProps) {
    const postfixLength = props.postfix ? pxInputWidthText(props.postfix, 14) : 0;
    const postfixStyle = {
        width: postfixLength
    };
    const inputStyle = {
        paddingRight: postfixLength + 8
    };
    return (
        <Control
            label={label}
            error={error}
            className={`control-input-number ${cn({
                'input-number-postfix-disabled': disabled,
                'input-number-postfix-nostep': disableStepIcon
            })}`}
        >
            <InputNumber
                data-qa={qa}
                style={inputStyle}
                disabled={disabled}
                maxLength={maxLength}
                className={cn({ empty: !props.value || props.value === '' })}
                {...props}
            />
            {props.addonAfter && props.addonAfter}
            {props.postfix && (
                <div className="input-number-postfix" style={postfixStyle}>
                    {props.postfix}
                </div>
            )}
        </Control>
    );
};

export default EnhancedInput;
