import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Logic } from 'logic/logic';
import { LayoutContent } from 'common/components/Layout/Content';
import StatisticReportBar, { StatisticReportFilterModel } from './components/StatisticReportBar';
import StatisticReportTable from './components/StatisticReportTable';
import StatisticReportSummary from './components/StatisticReportSummary';
import { SelectOpt } from 'common/components/AutoComplete';
import moment from 'moment';
import { DATE_FORMAT } from 'domain-constants';
import { exponea } from 'logic/exponea';
import UiTabBar from 'common/components/UiTabBar/UiTabBar';
import { StatisticReportGroup } from './statistic-report-logic';

interface Props extends WithTranslation {
    logic: Logic;
}

interface State {
    filter: {
        vehicleOpts: SelectOpt[];
        vehicleGroupOpts: SelectOpt[];
        driverOpts: SelectOpt[];
        dateChanged?: boolean;
    };
    active: StatisticReportGroup;
    selected?: number;
}

class StatisticReportModule extends React.Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;

        this.state = {
            filter: {
                vehicleOpts: [],
                vehicleGroupOpts: [],
                driverOpts: []
            },
            active: StatisticReportGroup.ByDays
        };
    }

    componentDidMount() {
        (window as any).app.StatisticReportModule = this;
        this._logic.statisticReportLogic().init();
    }

    componentWillUnmount() {
        this._logic.statisticReportLogic().setStatisticReportGroup(StatisticReportGroup.ByDays);
        this._logic.statisticReportLogic().setStatisticReportFilter({
            dateRange: {
                start: moment().startOf('month').format(DATE_FORMAT),
                end: moment().format(DATE_FORMAT)
            },
            drivers: [],
            vehicles: []
        });
        (window as any).app.StatisticReportModule = undefined;
    }

    render() {
        const { t } = this.props;

        const filterTabs = [
            { key: StatisticReportGroup.ByDays, label: t('StatisticReport.group.byDays') },
            { key: StatisticReportGroup.ByMonths, label: t('StatisticReport.group.byMonths') },
            { key: StatisticReportGroup.ByVehicles, label: t('StatisticReport.group.byVehicles') },
            { key: StatisticReportGroup.ByDrivers, label: t('StatisticReport.group.byDrivers') },
            { key: StatisticReportGroup.Summary, label: t('common.summary') }
        ];

        return (
            <LayoutContent
                className="statistic-report no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 24 }}
                extraSizes={[{ xs: 0, sm: 0, md: 0 }]}
                main={
                    <>
                        <StatisticReportBar
                            demoMode={this.props.logic.demo().isActive}
                            logic={this._logic}
                            filter={
                                <UiTabBar
                                    className="statistic-report-tab-group"
                                    options={filterTabs}
                                    selectedOption={this._logic.statisticReportLogic().statisticReportGroup}
                                    onSelect={this._onGroupButtonClick}
                                />
                            }
                            loading={
                                this._logic.statisticReportLogic().loadingInit ||
                                !!this._logic.statisticReportLogic().loadingStatisticReport
                            }
                            dateRange={{
                                start: this._logic.statisticReportLogic().statisticReportFilter.dateRange.start,
                                end: this._logic.statisticReportLogic().statisticReportFilter.dateRange.end
                            }}
                            driverOpts={this._logic
                                .statisticReportLogic()
                                .drivers.map(d => ({ code: d.id, label: `${d.name} ${d.surname}` }))}
                            vehicles={this._logic.statisticReportLogic().statisticReportFilter.vehicles}
                            drivers={this._logic.statisticReportLogic().statisticReportFilter.drivers}
                            onFilterChange={this._onBarFilterChange}
                        />

                        {this.state.active === StatisticReportGroup.Summary ? (
                            <StatisticReportSummary
                                data={this._logic.statisticReportLogic().statisticReport}
                                loading={this._logic.statisticReportLogic().loadingStatisticReport}
                            />
                        ) : (
                            <StatisticReportTable
                                data={this._logic.statisticReportLogic().statisticReport}
                                active={this.state.active}
                                drivers={this._logic.statisticReportLogic().drivers}
                                vehicles={this._logic.statisticReportLogic().vehicles}
                                selected={this.state.selected}
                                onRowClick={this._onTableRowClick}
                                loading={
                                    this._logic.statisticReportLogic().loadingInit ||
                                    this._logic.statisticReportLogic().loadingStatisticReport
                                }
                            />
                        )}
                    </>
                }
                extra={[]}
            />
        );
    }

    private _onGroupButtonClick = async (value: StatisticReportGroup) => {
        if (value !== this._logic.statisticReportLogic().statisticReportGroup) {
            this._logic.statisticReportLogic().setStatisticReportGroup(value);
            await this._logic.statisticReportLogic().reloadStatisticReport();
            this.setState({
                active: value
            });
        }

        this._logic.exponea().trackEvent(exponea.module.statisticsStatisticReport, {
            status: exponea.status.actionTaken,
            action: exponea.action.groupBy,
            subject: value
        });
    };

    private _onBarFilterChange = (filter: StatisticReportFilterModel): void => {
        this._logic.statisticReportLogic().setStatisticReportFilter(filter);
        this.props.logic.statisticReportLogic().reloadStatisticReport();
    };

    private _onTableRowClick = (row: number) => {
        this.setState(state => ({
            selected: state.selected !== row ? row : undefined
        }));
    };
}

export default withTranslation()(observer(StatisticReportModule));
