/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiemsRules,
    DiemsRulesFromJSON,
    DiemsRulesFromJSONTyped,
    DiemsRulesToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiemsRulesAffected
 */
export interface DiemsRulesAffected {
    /**
     * 
     * @type {number}
     * @memberof DiemsRulesAffected
     */
    count: number;
    /**
     * 
     * @type {Array<DiemsRules>}
     * @memberof DiemsRulesAffected
     */
    affectedRules: Array<DiemsRules>;
}

export function DiemsRulesAffectedFromJSON(json: any): DiemsRulesAffected {
    return DiemsRulesAffectedFromJSONTyped(json, false);
}

export function DiemsRulesAffectedFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiemsRulesAffected {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'affectedRules': ((json['affected_rules'] as Array<any>).map(DiemsRulesFromJSON)),
    };
}

export function DiemsRulesAffectedToJSON(value?: DiemsRulesAffected | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'affected_rules': ((value.affectedRules as Array<any>).map(DiemsRulesToJSON)),
    };
}


