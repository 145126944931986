/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyPlExciseDutyGoodsType
 */
export interface ReadOnlyPlExciseDutyGoodsType {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPlExciseDutyGoodsType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPlExciseDutyGoodsType
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyPlExciseDutyGoodsType
     */
    typeOfNotification?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyPlExciseDutyGoodsType
     */
    plExciseDutyApplicable: boolean;
}

export function ReadOnlyPlExciseDutyGoodsTypeFromJSON(json: any): ReadOnlyPlExciseDutyGoodsType {
    return ReadOnlyPlExciseDutyGoodsTypeFromJSONTyped(json, false);
}

export function ReadOnlyPlExciseDutyGoodsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyPlExciseDutyGoodsType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'typeOfNotification': !exists(json, 'type_of_notification') ? undefined : json['type_of_notification'],
        'plExciseDutyApplicable': json['pl_excise_duty_applicable'],
    };
}

export function ReadOnlyPlExciseDutyGoodsTypeToJSON(value?: ReadOnlyPlExciseDutyGoodsType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type_of_notification': value.typeOfNotification,
        'pl_excise_duty_applicable': value.plExciseDutyApplicable,
    };
}


