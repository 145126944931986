import { google } from 'google-maps';

import { BorderCrossTwoDirections } from 'generated/backend-api';
import { BorderCrossesMarkerProps } from './border-crosses';
import qa from 'qa-selectors';

export interface BorderCrossingMarkerData {
    data: BorderCrossTwoDirections;
}

const OverlayView = () => {
    return window.google?.maps?.OverlayView ?? null;
};

export class BorderCrossingMarker extends OverlayView() {
    readonly _google: google;
    position: google.maps.LatLng;

    private _props: BorderCrossesMarkerProps;
    private _container?: HTMLDivElement;
    private _icon?: HTMLImageElement;
    onBorderCrossingClick = () => {};

    constructor(google: google, position: google.maps.LatLng, props: BorderCrossesMarkerProps) {
        super();
        this.position = position;
        this._props = props;
        this._google = google;
    }

    getContainer() {
        return this._container;
    }

    onAdd() {
        this._container = this._createContainer();
        // Typings doesn't include this function
        const gm = this._google.maps.OverlayView as any;
        gm.preventMapHitsAndGesturesFrom(this._container);
        this.getPanes()?.overlayMouseTarget.appendChild(this._container);
    }

    draw() {
        if (!this._container) return;

        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        this._container.style.left = divPosition?.x + 'px';
        this._container.style.top = divPosition?.y + 'px';
        if (this.getMap()) {
            this.setIconSize();
        }
    }

    onRemove() {
        if (!this._container) return;

        if (this._container.parentElement) {
            this._container.parentElement.removeChild(this._container);
        }
    }

    getDraggable() {
        return false;
    }

    getPosition() {
        return this.position;
    }

    private _createContainer() {
        let container = document.createElement('div');
        container.style.position = 'absolute';
        container.style.zIndex = String(this._props.zIndex);
        container.style.cursor = 'pointer';

        this._icon = document.createElement('img');
        this._icon.style.position = 'absolute';
        this._icon.setAttribute('data-qa', qa.map.borderCrosing);
        container.addEventListener('click', () => {
            this.onBorderCrossingClick();
        });
        container.appendChild(this._icon);
        return container;
    }

    setIconSize() {
        if (this._props && this._icon) {
            const size = this._props.size;
            this._icon.src = this._props.url;
            this._icon.style.width = size + 'px';
            this._icon.style.top = '-' + this._props.anchorY + 'px';
            this._icon.style.left = '-' + this._props.anchorX + 'px';
        }
    }
}
