/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatedOrCompletedBy
 */
export interface CreatedOrCompletedBy {
    /**
     * 
     * @type {number}
     * @memberof CreatedOrCompletedBy
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatedOrCompletedBy
     */
    ssoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatedOrCompletedBy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedOrCompletedBy
     */
    surname: string;
}

export function CreatedOrCompletedByFromJSON(json: any): CreatedOrCompletedBy {
    return CreatedOrCompletedByFromJSONTyped(json, false);
}

export function CreatedOrCompletedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedOrCompletedBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'name': json['name'],
        'surname': json['surname'],
    };
}

export function CreatedOrCompletedByToJSON(value?: CreatedOrCompletedBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sso_id': value.ssoId,
        'name': value.name,
        'surname': value.surname,
    };
}


