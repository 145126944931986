/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceTasksComplete
 */
export interface MaintenanceTasksComplete {
    /**
     * 
     * @type {Date}
     * @memberof MaintenanceTasksComplete
     */
    completionTime: Date;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceTasksComplete
     */
    completionMileageKilometers?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceTasksComplete
     */
    tasks: Array<string>;
}

export function MaintenanceTasksCompleteFromJSON(json: any): MaintenanceTasksComplete {
    return MaintenanceTasksCompleteFromJSONTyped(json, false);
}

export function MaintenanceTasksCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceTasksComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completionTime': (new Date(json['completion_time'])),
        'completionMileageKilometers': !exists(json, 'completion_mileage_kilometers') ? undefined : json['completion_mileage_kilometers'],
        'tasks': json['tasks'],
    };
}

export function MaintenanceTasksCompleteToJSON(value?: MaintenanceTasksComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completion_time': (value.completionTime.toISOString()),
        'completion_mileage_kilometers': value.completionMileageKilometers,
        'tasks': value.tasks,
    };
}


