/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
} from './';

/**
 * 
 * @export
 * @interface RouteCostsDetailsCountrySegment
 */
export interface RouteCostsDetailsCountrySegment {
    /**
     * 
     * @type {string}
     * @memberof RouteCostsDetailsCountrySegment
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RouteCostsDetailsCountrySegment
     */
    tollDistance?: number;
    /**
     * 
     * @type {Price}
     * @memberof RouteCostsDetailsCountrySegment
     */
    price?: Price;
}

export function RouteCostsDetailsCountrySegmentFromJSON(json: any): RouteCostsDetailsCountrySegment {
    return RouteCostsDetailsCountrySegmentFromJSONTyped(json, false);
}

export function RouteCostsDetailsCountrySegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteCostsDetailsCountrySegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tollDistance': !exists(json, 'toll_distance') ? undefined : json['toll_distance'],
        'price': !exists(json, 'price') ? undefined : PriceFromJSON(json['price']),
    };
}

export function RouteCostsDetailsCountrySegmentToJSON(value?: RouteCostsDetailsCountrySegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'toll_distance': value.tollDistance,
        'price': PriceToJSON(value.price),
    };
}


