/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyFuelCardSerializer
 */
export interface WriteOnlyFuelCardSerializer {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    client?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyFuelCardSerializer
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyFuelCardSerializer
     */
    holder?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    fuelCompany?: number;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyFuelCardSerializer
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyFuelCardSerializer
     */
    startTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyFuelCardSerializer
     */
    endTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    limitAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    limitDuration?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyFuelCardSerializer
     */
    status?: WriteOnlyFuelCardSerializerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyFuelCardSerializer
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    monitoredObject?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyFuelCardSerializer
     */
    user?: number | null;
}

export function WriteOnlyFuelCardSerializerFromJSON(json: any): WriteOnlyFuelCardSerializer {
    return WriteOnlyFuelCardSerializerFromJSONTyped(json, false);
}

export function WriteOnlyFuelCardSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyFuelCardSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': !exists(json, 'client') ? undefined : json['client'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'holder': !exists(json, 'holder') ? undefined : json['holder'],
        'fuelCompany': !exists(json, 'fuel_company') ? undefined : json['fuel_company'],
        'expirationDate': !exists(json, 'expiration_date') ? undefined : (json['expiration_date'] === null ? null : new Date(json['expiration_date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'limitAmount': !exists(json, 'limit_amount') ? undefined : json['limit_amount'],
        'limitDuration': !exists(json, 'limit_duration') ? undefined : json['limit_duration'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'monitoredObject': !exists(json, 'monitored_object') ? undefined : json['monitored_object'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function WriteOnlyFuelCardSerializerToJSON(value?: WriteOnlyFuelCardSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': value.client,
        'number': value.number,
        'holder': value.holder,
        'fuel_company': value.fuelCompany,
        'expiration_date': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString().substr(0,10)),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'limit_amount': value.limitAmount,
        'limit_duration': value.limitDuration,
        'status': value.status,
        'note': value.note,
        'monitored_object': value.monitoredObject,
        'user': value.user,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyFuelCardSerializerStatusEnum {
    InProduction = 'in_production',
    Sent = 'sent',
    WaitingActivation = 'waiting_activation',
    Active = 'active',
    Restricted = 'restricted',
    Blocked = 'blocked',
    Expired = 'expired',
    Cancelled = 'cancelled'
}


