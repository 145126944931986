/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlannedTransportPlacePosition,
    PlannedTransportPlacePositionFromJSON,
    PlannedTransportPlacePositionFromJSONTyped,
    PlannedTransportPlacePositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface RouteWayPoint
 */
export interface RouteWayPoint {
    /**
     * 
     * @type {string}
     * @memberof RouteWayPoint
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RouteWayPoint
     */
    ptvRouteId?: string;
    /**
     * 
     * @type {number}
     * @memberof RouteWayPoint
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof RouteWayPoint
     */
    lng: number;
    /**
     * 
     * @type {string}
     * @memberof RouteWayPoint
     */
    departure?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RouteWayPoint
     */
    useCustomDeparture?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RouteWayPoint
     */
    name?: string;
    /**
     * 
     * @type {Array<PlannedTransportPlacePosition>}
     * @memberof RouteWayPoint
     */
    crossingPoints?: Array<PlannedTransportPlacePosition>;
}

export function RouteWayPointFromJSON(json: any): RouteWayPoint {
    return RouteWayPointFromJSONTyped(json, false);
}

export function RouteWayPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteWayPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ptvRouteId': !exists(json, 'ptvRouteId') ? undefined : json['ptvRouteId'],
        'lat': json['lat'],
        'lng': json['lng'],
        'departure': !exists(json, 'departure') ? undefined : json['departure'],
        'useCustomDeparture': !exists(json, 'useCustomDeparture') ? undefined : json['useCustomDeparture'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'crossingPoints': !exists(json, 'crossingPoints') ? undefined : ((json['crossingPoints'] as Array<any>).map(PlannedTransportPlacePositionFromJSON)),
    };
}

export function RouteWayPointToJSON(value?: RouteWayPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ptvRouteId': value.ptvRouteId,
        'lat': value.lat,
        'lng': value.lng,
        'departure': value.departure,
        'useCustomDeparture': value.useCustomDeparture,
        'name': value.name,
        'crossingPoints': value.crossingPoints === undefined ? undefined : ((value.crossingPoints as Array<any>).map(PlannedTransportPlacePositionToJSON)),
    };
}


