import { Col, Row } from 'antd';
import { Button } from 'common/components';
import { RouteSelectData } from 'modules/routing/places-autocomplete/PlacesAutocompleteModule';
import qa from 'qa-selectors';
import { EditLocationCancel } from 'resources/images/planner';
import PlannerAutocomplete from '../PlannerAutocomplete';

interface Props {
    name?: string;
    onChange?: (value: RouteSelectData) => void;
    onCancel?: () => void;
}

const PlaceLocationEdit = (props: Props) => {
    const handleChange = (value: RouteSelectData) => {
        props.onCancel?.();
        props.onChange?.(value);
    };

    const handleCancelClick = (e: React.MouseEvent<HTMLElement>) => {
        props.onCancel?.();
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Row
            className="place-location-edit"
            align="middle"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <Col span={22}>
                <PlannerAutocomplete
                    onEscape={() => {
                        props.onCancel?.();
                    }}
                    placeHolder={props.name}
                    onAddRoute={handleChange}
                    dropdownAlign={{ offset: [-3, 7] }}
                    dropdownMatchSelectWidth={330}
                />
            </Col>
            <Col span={2}>
                <Button
                    onClick={handleCancelClick}
                    data-qa={qa.planner.places.btnEditCancel}
                    size="middle"
                    type="link"
                    icon={
                        <img
                            src={EditLocationCancel}
                            className="places-configurator-place-actions-edit-cancel"
                            alt="cancel"
                        />
                    }
                />
            </Col>
        </Row>
    );
};

export default PlaceLocationEdit;
