import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import introspectionResult from './apolloIntrospectionResult';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ServerParseError } from 'apollo-link-http-common';
import { Auth } from 'logic/auth';
import { Conf } from 'conf';
import { Logic } from 'logic/logic';

export type ApolloConf = Conf['graphQL'];

function fetchXhr(url: string, options: any): Promise<Response> {
    options = options || {};
    return new Promise<Response>((resolve, reject) => {
        const request = new XMLHttpRequest();
        const keys = [] as any;
        const all = [] as any;
        const headers = {};

        const response = (): any => ({
            ok: ((request.status / 100) | 0) === 2, // 200-299
            statusText: request.statusText,
            status: request.status,
            url: request.responseURL,
            text: () => Promise.resolve(request.responseText),
            json: () => Promise.resolve(JSON.parse(request.responseText)),
            blob: () => Promise.resolve(new Blob([request.response])),
            clone: response,
            headers: {
                keys: () => keys,
                entries: () => all,
                get: (n: any) => headers[n.toLowerCase()],
                has: (n: any) => n.toLowerCase() in headers
            }
        });

        request.open(options.method || 'get', url, true);

        request.onload = () => {
            request.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, ((
                m: string,
                key: any,
                value: any
            ) => {
                keys.push((key = key.toLowerCase()));
                all.push([key, value]);
                headers[key] = headers[key] ? `${headers[key]},${value}` : value;
            }) as any);
            resolve(response());
        };
        request.onerror = reject;
        request.withCredentials = options.credentials === 'include';

        for (const i in options.headers) {
            request.setRequestHeader(i, options.headers[i]);
        }

        request.send(options.body || null);
    });
}

export interface ApolloStats {
    [operation: string]: {
        avg: number;
        count: number;
        min: number;
        max: number;
    };
}

class Apollo {
    readonly stats: ApolloStats;

    client: ApolloClient<NormalizedCacheObject> | null;

    readonly uri: string;

    constructor(conf: ApolloConf, private _logic: Logic) {
        this.stats = {};
        this.client = null;
        this.uri = conf.url;
    }

    initialize(auth: Auth): ApolloClient<NormalizedCacheObject> {
        if (!this.client) {
            const errorLink = onError(errors => {
                if (errors.networkError) {
                    // Check if error response is JSON
                    try {
                        JSON.parse((errors.networkError as ServerParseError).bodyText);
                    } catch (e) {
                        // If not replace parsing error message with real one
                        errors.networkError.message = (errors.networkError as ServerParseError).bodyText;
                    }
                }
            });

            const retryLink = new RetryLink();

            const cache = new InMemoryCache({
                fragmentMatcher: new IntrospectionFragmentMatcher({
                    introspectionQueryResultData: introspectionResult
                })
            });

            // TODO: tato blbost nenasetuje ked su vyplnene fetch options v httpLink nizzsie
            const authLink = setContext(async (_, { headers, fetchOptions }) => {
                let token = null;
                try {
                    await auth.updateToken();
                    token = auth.token();
                } catch (e) {
                    console.error('Error updating keycloak token', e);
                }
                if (!token) {
                    auth.logout();
                }

                const testOwner = document.querySelector('meta[name="X-Test-Owner"]') as HTMLMetaElement;
                const testOwnerHeader = testOwner
                    ? {
                          'X-Test-Owner': testOwner.content
                      }
                    : {};

                return {
                    headers: {
                        ...headers,
                        ...testOwnerHeader,
                        authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*'
                    },
                    fetchOptions
                };
            });

            const httpLink = createHttpLink({
                uri: this.uri,
                fetch: (...pl: any[]) => {
                    const [uri, options] = pl;
                    const body = JSON.parse(options.body);
                    const t0 = performance.now();
                    return fetchXhr(uri, options).then(response => {
                        const t1 = performance.now();
                        const t = t1 - t0;
                        if (!this.stats[body.operationName]) {
                            this.stats[body.operationName] = {
                                avg: t,
                                count: 1,
                                min: t,
                                max: t
                            };
                        } else {
                            const count = this.stats[body.operationName].count + 1;
                            const avg =
                                (t + this.stats[body.operationName].count * this.stats[body.operationName].avg) / count;
                            const min = t < this.stats[body.operationName].min ? t : this.stats[body.operationName].min;
                            const max = t > this.stats[body.operationName].max ? t : this.stats[body.operationName].max;
                            this.stats[body.operationName] = {
                                avg,
                                count,
                                min,
                                max
                            };
                        }
                        const limit = 3e3;
                        if (t > limit) {
                            console.warn(
                                `GraphQL ${body.operationName || ''} ${t} ms (request exceeded ${limit} ms)\n`,
                                body.query,
                                body.variables
                            );
                        }
                        return response;
                    });
                }
            });

            this.client = new ApolloClient({
                link: ApolloLink.from([errorLink, authLink, httpLink, retryLink]),
                cache,
                queryDeduplication: false
            });
            (this.client as any).stats = this.stats;
        }
        return this.client;
    }
}

export default Apollo;
