import Typography from 'antd/lib/typography';
import { TypographyProps } from 'antd/lib/typography/Typography';

interface Props extends TypographyProps {}

function EnhancedTypography(props: Props) {
    return <Typography {...props} />;
}

EnhancedTypography.Text = Typography.Text;
EnhancedTypography.Title = Typography.Title;
EnhancedTypography.Paragraph = Typography.Paragraph;

export default EnhancedTypography;
