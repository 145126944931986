/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface BorderCrossing
 */
export interface BorderCrossing {
    /**
     * 
     * @type {Point}
     * @memberof BorderCrossing
     */
    entry?: Point;
    /**
     * 
     * @type {Point}
     * @memberof BorderCrossing
     */
    exit?: Point;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossing
     */
    powStay?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossing
     */
    stay?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossing
     */
    stayForeign?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossing
     */
    stayDomestic?: number;
}

export function BorderCrossingFromJSON(json: any): BorderCrossing {
    return BorderCrossingFromJSONTyped(json, false);
}

export function BorderCrossingFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorderCrossing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : PointFromJSON(json['entry']),
        'exit': !exists(json, 'exit') ? undefined : PointFromJSON(json['exit']),
        'powStay': !exists(json, 'pow_stay') ? undefined : json['pow_stay'],
        'stay': !exists(json, 'stay') ? undefined : json['stay'],
        'stayForeign': !exists(json, 'stay_foreign') ? undefined : json['stay_foreign'],
        'stayDomestic': !exists(json, 'stay_domestic') ? undefined : json['stay_domestic'],
    };
}

export function BorderCrossingToJSON(value?: BorderCrossing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': PointToJSON(value.entry),
        'exit': PointToJSON(value.exit),
        'pow_stay': value.powStay,
        'stay': value.stay,
        'stay_foreign': value.stayForeign,
        'stay_domestic': value.stayDomestic,
    };
}


