import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { Button } from 'common/components';
import * as icons from 'resources/images/dispatcher-board';

interface Props {
    right: ReactNode;
    newProposals: number;
    onRefreshBoard: () => void;
}

export default function BoardHeader(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="board-header">
            <Row justify="space-between" align="middle">
                {props.newProposals > 0 && (
                    <div onClick={props.onRefreshBoard} className="board-header-new-proposals">
                        <img src={icons.alert} alt="alert" />
                        {t('DispatcherBoard.newProposalsBanner')}
                        <Button type="link">{t('DispatcherBoard.refreshBoard')}</Button>
                    </div>
                )}
                <Col>
                    <h1>{t('DispatcherBoard.dispatcherBoardTitle')}</h1>
                </Col>
                <Col>{props.right}</Col>
            </Row>
        </div>
    );
}
