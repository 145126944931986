import moment, { Moment } from 'moment';
import { Logic } from 'logic/logic';

export interface InvoiceModel {
    id: string;
    invoiceNumber: string;
    createdOn: Moment;
    dueDate: Moment;
}

export class PartnerInvoicesLogic {
    constructor(private _logic: Logic) {}

    async getInvoiceList(_clientId?: string): Promise<InvoiceModel[]> {
        try {
            // const response = await this._logic.api().invoiceApi.invoiceList({
            //    client:clientId
            //});
            // return response.results.map(this._toInvoice);
            return [].map(this._toInvoice);
        } catch (err) {
            console.log('Invoice list GET err:', err);
            throw err;
        }
    }
    async deleteInvoice(_invoice: InvoiceModel): Promise<Boolean> {
        try {
            // const response = await this._logic.api().invoiceApi.invoiceDelete({ id: Number(invoice.id) });
            console.log('Delete invoice');
            return true;
        } catch (err) {
            console.log('Invoice DELETE err:', err);
            throw err;
        }
    }

    private _toInvoice(_data: any): InvoiceModel {
        return {
            id: 'invoice-a',
            invoiceNumber: 'invoice-a',
            createdOn: moment(),
            dueDate: moment()
        };
    }
}

export const sampleInvoices: InvoiceModel[] = [
    {
        id: 'invoice-a',
        invoiceNumber: 'invoice-a',
        createdOn: moment(),
        dueDate: moment()
    },
    {
        id: 'invoice-b',
        invoiceNumber: 'invoice-b',
        createdOn: moment(),
        dueDate: moment()
    }
];
