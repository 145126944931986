import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { getLocale } from 'antd-locale';

import { Logic } from 'logic/logic';
import Map from 'modules/map/MapModule';

import { Locale } from 'antd/lib/locale-provider';
import { LogicContext } from 'App';
import { BrowserRouter as Router } from 'react-router-dom';
import TrackingModule from 'modules/tracking/TrackingModule';
import { Bar, BarItemButton } from 'common/components/Navbar';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'domain-constants';

const tokenErrorCodes = ['4001', '4002', '4003', '4004', '4005', '4006', '4007'] as const;

interface Props extends WithTranslation {
    logic: Logic;
    code: string;
    embedded: boolean;
}

interface State {
    tokenError?: {
        code: typeof tokenErrorCodes[number] | 'unknown';
        date?: string;
    };
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
        this.props.logic.ga().pageview(window.location.hash);
        this.props.logic.exponea().pageview(window.location.hash);

        window.addEventListener('popstate', () => {
            this.props.logic.ga().pageview(window.location.hash);
            if (window.location.hash.indexOf('?') === -1) {
                this.props.logic.exponea().pageview(window.location.hash);
            }
        });
    }

    componentDidMount() {
        this.props.logic
            .customerAccessLogic()
            .getTokenFromCode(this.props.code)
            .then(token => {
                if (token) {
                    this.props.logic.simpleTracking().init();
                    this.props.logic
                        .map()
                        .vehicles()
                        .onClick(vehicle => {
                            this.props.logic.simpleTracking().selectVehicle(vehicle.id);
                        });
                } else {
                    this._handleTokenError();
                }
            })
            .catch(err => {
                this._handleTokenError(err);
            });
    }

    render() {
        const { logic } = this.props;
        const locale: Locale = getLocale(logic.auth().user().lang);

        return (
            <ConfigProvider locale={locale}>
                <LogicContext.Provider value={logic}>
                    {this.state.tokenError ? (
                        this._renderTokenExpired()
                    ) : (
                        <Router>
                            <Bar>
                                <BarItemButton
                                    className="navbar-logo"
                                    onClick={_e => this.props.logic.simpleTracking().init()}
                                >
                                    <div className="logo" />
                                </BarItemButton>
                            </Bar>
                            <Map logic={logic} simple embedded={this.props.embedded} />
                            <TrackingModule logic={logic} simple />
                        </Router>
                    )}
                </LogicContext.Provider>
            </ConfigProvider>
        );
    }

    private _handleTokenError = (err?: any) => {
        if (err?.json) {
            (err.json() as Promise<any>)
                .then(data => {
                    const errorCode = data?.code?.toString();
                    if (errorCode && tokenErrorCodes.includes(errorCode)) {
                        this.setState({
                            tokenError: {
                                code: errorCode,
                                date: data?.date
                            }
                        });
                    } else {
                        this.setState({ tokenError: { code: 'unknown' } });
                    }
                })
                .catch(() => {
                    this.setState({ tokenError: { code: 'unknown' } });
                });
        } else {
            this.setState({ tokenError: { code: 'unknown' } });
        }
    };

    private _renderTokenExpired = () => {
        let date = null;
        if (this.state.tokenError?.date) date = moment(this.state.tokenError.date);
        return (
            <div className="customer-access-expired">
                <h2>
                    {this.props.t(`CustomerAccessPreview.errors.${this.state.tokenError?.code}`)}
                    {date ? ` ${date.format(DATE_TIME_FORMAT)}` : ''}
                </h2>
            </div>
        );
    };
}

export default withTranslation()(App);
