/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrivingModeReportType
 */
export interface DrivingModeReportType {
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1TREng: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1DDrvTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1DDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1CntDp: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1DDrvRet20DDrvBrDDrvParkBr: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1TDrvCc: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1TDrvCcTREng: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1AltIncrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    roadType: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportType
     */
    fd1SpendInDrivingMode: number;
}

export function DrivingModeReportTypeFromJSON(json: any): DrivingModeReportType {
    return DrivingModeReportTypeFromJSONTyped(json, false);
}

export function DrivingModeReportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingModeReportType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fd1TDrv': json['fd1_t_drv'],
        'fd1TREng': json['fd1_t_r_eng'],
        'fd1DDrvTDrv': json['fd1_d_drv_t_drv'],
        'fd1DDrv': json['fd1_d_drv'],
        'fd1CntDp': json['fd1_cnt_dp'],
        'fd1DDrvRet20DDrvBrDDrvParkBr': json['fd1_d_drv_ret_20_d_drv_br_d_drv_park_br'],
        'fd1TDrvCc': json['fd1_t_drv_cc'],
        'fd1TDrvCcTREng': json['fd1_t_drv_cc_t_r_eng'],
        'fd1AltIncrDDrv': json['fd1_alt_incr_d_drv'],
        'roadType': json['road_type'],
        'fd1SpendInDrivingMode': json['fd1_spend_in_driving_mode'],
    };
}

export function DrivingModeReportTypeToJSON(value?: DrivingModeReportType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fd1_t_drv': value.fd1TDrv,
        'fd1_t_r_eng': value.fd1TREng,
        'fd1_d_drv_t_drv': value.fd1DDrvTDrv,
        'fd1_d_drv': value.fd1DDrv,
        'fd1_cnt_dp': value.fd1CntDp,
        'fd1_d_drv_ret_20_d_drv_br_d_drv_park_br': value.fd1DDrvRet20DDrvBrDDrvParkBr,
        'fd1_t_drv_cc': value.fd1TDrvCc,
        'fd1_t_drv_cc_t_r_eng': value.fd1TDrvCcTREng,
        'fd1_alt_incr_d_drv': value.fd1AltIncrDDrv,
        'road_type': value.roadType,
        'fd1_spend_in_driving_mode': value.fd1SpendInDrivingMode,
    };
}


