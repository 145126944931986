/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientLight,
    ClientLightFromJSON,
    ClientLightFromJSONTyped,
    ClientLightToJSON,
    UserGroup,
    UserGroupFromJSON,
    UserGroupFromJSONTyped,
    UserGroupToJSON,
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
    UserToken,
    UserTokenFromJSON,
    UserTokenFromJSONTyped,
    UserTokenToJSON,
    UserTokenUserNestedExternalSystem,
    UserTokenUserNestedExternalSystemFromJSON,
    UserTokenUserNestedExternalSystemFromJSONTyped,
    UserTokenUserNestedExternalSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyUserNested
 */
export interface ReadOnlyUserNested {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserNested
     */
    readonly id?: number;
    /**
     * 
     * @type {ClientLight}
     * @memberof ReadOnlyUserNested
     */
    client?: ClientLight;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof ReadOnlyUserNested
     */
    readonly userGroup?: Array<UserGroup>;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof ReadOnlyUserNested
     */
    readonly userRoles?: Array<UserRole>;
    /**
     * 
     * @type {Array<UserToken>}
     * @memberof ReadOnlyUserNested
     */
    readonly userTokens?: Array<UserToken>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReadOnlyUserNested
     */
    readonly monitoredObjectGroups?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserNested
     */
    lastLogin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    ssoId?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyUserNested
     */
    loginCredentials?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    surname: string;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyUserNested
     */
    contact?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserNested
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserNested
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserNested
     */
    averageSpeed: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    languageIso6391?: string | null;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof ReadOnlyUserNested
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserNested
     */
    isContactPerson?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    binaryRights?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserNested
     */
    binaryRightsBackup?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserNested
     */
    isSsoAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyUserNested
     */
    binaryRightsDirty?: boolean;
    /**
     * 
     * @type {UserTokenUserNestedExternalSystem}
     * @memberof ReadOnlyUserNested
     */
    externalSystem?: UserTokenUserNestedExternalSystem;
}

export function ReadOnlyUserNestedFromJSON(json: any): ReadOnlyUserNested {
    return ReadOnlyUserNestedFromJSONTyped(json, false);
}

export function ReadOnlyUserNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientLightFromJSON(json['client']),
        'userGroup': !exists(json, 'user_group') ? undefined : ((json['user_group'] as Array<any>).map(UserGroupFromJSON)),
        'userRoles': !exists(json, 'user_roles') ? undefined : ((json['user_roles'] as Array<any>).map(UserRoleFromJSON)),
        'userTokens': !exists(json, 'user_tokens') ? undefined : ((json['user_tokens'] as Array<any>).map(UserTokenFromJSON)),
        'monitoredObjectGroups': !exists(json, 'monitored_object_groups') ? undefined : json['monitored_object_groups'],
        'lastLogin': !exists(json, 'last_login') ? undefined : (json['last_login'] === null ? null : new Date(json['last_login'])),
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'loginCredentials': !exists(json, 'login_credentials') ? undefined : json['login_credentials'],
        'name': json['name'],
        'surname': json['surname'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'deleted': json['deleted'],
        'active': json['active'],
        'averageSpeed': json['average_speed'],
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isContactPerson': !exists(json, 'is_contact_person') ? undefined : json['is_contact_person'],
        'binaryRights': !exists(json, 'binary_rights') ? undefined : json['binary_rights'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'binaryRightsBackup': !exists(json, 'binary_rights_backup') ? undefined : json['binary_rights_backup'],
        'isSsoAdmin': !exists(json, 'is_sso_admin') ? undefined : json['is_sso_admin'],
        'binaryRightsDirty': !exists(json, 'binary_rights_dirty') ? undefined : json['binary_rights_dirty'],
        'externalSystem': !exists(json, 'external_system') ? undefined : UserTokenUserNestedExternalSystemFromJSON(json['external_system']),
    };
}

export function ReadOnlyUserNestedToJSON(value?: ReadOnlyUserNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientLightToJSON(value.client),
        'last_login': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
        'sso_id': value.ssoId,
        'login_credentials': value.loginCredentials,
        'name': value.name,
        'surname': value.surname,
        'contact': value.contact,
        'note': value.note,
        'deleted': value.deleted,
        'active': value.active,
        'average_speed': value.averageSpeed,
        'language_iso_639_1': value.languageIso6391,
        'is_active': value.isActive,
        'is_contact_person': value.isContactPerson,
        'binary_rights': value.binaryRights,
        'external_id': value.externalId,
        'binary_rights_backup': value.binaryRightsBackup,
        'is_sso_admin': value.isSsoAdmin,
        'binary_rights_dirty': value.binaryRightsDirty,
        'external_system': UserTokenUserNestedExternalSystemToJSON(value.externalSystem),
    };
}


