/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20023,
    InlineResponse20023FromJSON,
    InlineResponse20023ToJSON,
    ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializer,
    ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializerFromJSON,
    ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializerToJSON,
} from '../models';

export interface MonitoredObjectGroupV2ListRequest {
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupV2VehiclesEndpointRequest {
    id: number;
}

/**
 * no description
 */
export class MonitoredObjectGroupV2Api extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectGroupV2ListRaw(requestParameters: MonitoredObjectGroupV2ListRequest): Promise<runtime.ApiResponse<InlineResponse20023>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-v2/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20023FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupV2List(requestParameters: MonitoredObjectGroupV2ListRequest): Promise<InlineResponse20023> {
        const response = await this.monitoredObjectGroupV2ListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupV2VehiclesEndpointRaw(requestParameters: MonitoredObjectGroupV2VehiclesEndpointRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupV2VehiclesEndpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-v2/{id}/monitored-objects/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializerFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupV2VehiclesEndpoint(requestParameters: MonitoredObjectGroupV2VehiclesEndpointRequest): Promise<ReadOnlyMonitoredObjectGroupHasMonitoredObjectSerializer> {
        const response = await this.monitoredObjectGroupV2VehiclesEndpointRaw(requestParameters);
        return await response.value();
    }

}
