/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeFromJSONTyped,
    ExternalDeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface LibModelsActivityIntervalExternalDevice
 */
export interface LibModelsActivityIntervalExternalDevice {
    /**
     * 
     * @type {string}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    serialNumber: string;
    /**
     * 
     * @type {ExternalDeviceType}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    deviceType: ExternalDeviceType;
    /**
     * 
     * @type {string}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    source: string;
    /**
     * 
     * @type {Date}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    tfrom: Date;
    /**
     * 
     * @type {object}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    metadata: object;
    /**
     * 
     * @type {object}
     * @memberof LibModelsActivityIntervalExternalDevice
     */
    data?: object;
}

export function LibModelsActivityIntervalExternalDeviceFromJSON(json: any): LibModelsActivityIntervalExternalDevice {
    return LibModelsActivityIntervalExternalDeviceFromJSONTyped(json, false);
}

export function LibModelsActivityIntervalExternalDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LibModelsActivityIntervalExternalDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serial_number'],
        'deviceType': ExternalDeviceTypeFromJSON(json['device_type']),
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'source': json['source'],
        'tfrom': (new Date(json['tfrom'])),
        'metadata': json['metadata'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function LibModelsActivityIntervalExternalDeviceToJSON(value?: LibModelsActivityIntervalExternalDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serialNumber,
        'device_type': ExternalDeviceTypeToJSON(value.deviceType),
        'device_id': value.deviceId,
        'source': value.source,
        'tfrom': (value.tfrom.toISOString()),
        'metadata': value.metadata,
        'data': value.data,
    };
}


