/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessagePayload,
    MessagePayloadFromJSON,
    MessagePayloadFromJSONTyped,
    MessagePayloadToJSON,
    MessageSeen,
    MessageSeenFromJSON,
    MessageSeenFromJSONTyped,
    MessageSeenToJSON,
} from './';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    created: Date;
    /**
     * 
     * @type {Array<MessageSeen>}
     * @memberof Message
     */
    seen?: Array<MessageSeen> | null;
    /**
     * 
     * @type {Array<MessagePayload>}
     * @memberof Message
     */
    payload: Array<MessagePayload>;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'channel': json['channel'],
        'sender': json['sender'],
        'text': json['text'],
        'created': (new Date(json['created'])),
        'seen': !exists(json, 'seen') ? undefined : (json['seen'] === null ? null : (json['seen'] as Array<any>).map(MessageSeenFromJSON)),
        'payload': ((json['payload'] as Array<any>).map(MessagePayloadFromJSON)),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'channel': value.channel,
        'sender': value.sender,
        'text': value.text,
        'created': (value.created.toISOString()),
        'seen': value.seen === undefined ? undefined : (value.seen === null ? null : (value.seen as Array<any>).map(MessageSeenToJSON)),
        'payload': ((value.payload as Array<any>).map(MessagePayloadToJSON)),
    };
}


