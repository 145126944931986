/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationMessageBase,
    NotificationMessageBaseFromJSON,
    NotificationMessageBaseFromJSONTyped,
    NotificationMessageBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Publish
 */
export interface Publish {
    /**
     * 
     * @type {NotificationMessageBase}
     * @memberof Publish
     */
    message: NotificationMessageBase;
    /**
     * 
     * @type {string}
     * @memberof Publish
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof Publish
     */
    device?: string | null;
}

export function PublishFromJSON(json: any): Publish {
    return PublishFromJSONTyped(json, false);
}

export function PublishFromJSONTyped(json: any, ignoreDiscriminator: boolean): Publish {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': NotificationMessageBaseFromJSON(json['message']),
        'user': json['user'],
        'device': !exists(json, 'device') ? undefined : json['device'],
    };
}

export function PublishToJSON(value?: Publish | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': NotificationMessageBaseToJSON(value.message),
        'user': value.user,
        'device': value.device,
    };
}


