import React from 'react';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, message } from 'antd';
import { SwaggerUIBundle } from 'swagger-ui-dist';

import { ClientApplication } from 'generated/main-data-api';
import { Role } from 'logic/auth';
import { Button } from 'common/components';
import { LayoutContent } from 'common/components/Layout/Content';
import { RouteNames, withLogicContext, WithLogic } from 'App';

import ApiKeyCard from '../../components/ApiKeyCard';
import Tabs from '../../components/SystemConnectionTabs';
import CustomerApiModal from '../../components/CustomerApiModal';
import 'swagger-ui-themes/themes/3.x/theme-outline.css';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import qa from 'qa-selectors';
import Confirm, { MessageType } from 'common/components/Confirm';
import TableBar from 'common/components/TableBar';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

interface Props extends WithTranslation, WithLogic, RouteComponentProps {}

interface State {
    modalOpen: boolean;
    createApiKeyLoading: boolean;
    editApiKeyLoading: boolean;
    removeApiKeyLoading: boolean;
    removeConfirmModal: {
        isOpen: boolean;
        id?: number;
    };
    applications: ClientApplication[];
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    keyGeneration: { name: string; vehicles: string[]; id: number | null };
    helper?: {
        content: string;
    };
}

const SWAGGER_ID = 'swagger';

class CustomerApiModule extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalOpen: false,
            createApiKeyLoading: false,
            editApiKeyLoading: false,
            removeApiKeyLoading: false,
            removeConfirmModal: {
                isOpen: false
            },
            applications: [],
            vehicles: [],
            keyGeneration: {
                id: null,
                name: '',
                vehicles: []
            }
        };
    }

    componentDidMount() {
        SwaggerUIBundle({
            url: this.props.logic?.conf.api.customer.docs,
            dom_id: '#' + SWAGGER_ID
        });

        this.props.logic
            .customerAPI()
            .getAll()
            .then(applications => {
                this.setState({ applications });
            });

        this.props.logic
            .vehicles()
            .getMonitoredObjectFilters(false, false, [Role.LM_R])
            .then(vehicles => {
                this.setState({ vehicles });
            });
    }

    render() {
        const { t } = this.props;
        const { applications } = this.state;

        return (
            <>
                <LayoutContent
                    className="system-connections"
                    mainSizes={{ xs: 24, sm: 24, md: 18 }}
                    extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                    main={
                        <>
                            <header>
                                <TableBar
                                    heading={t('SystemConnections.title')}
                                    onHelperClick={() => this._onHelperClick?.()}
                                />
                                <Tabs
                                    active={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API}
                                    onChange={this._onTabChange}
                                    tabs={[
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerApi}>
                                                    {this.props.t('SystemConnections.customerApi')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API,
                                            roles: [Role.CA_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabCustomerAccess}>
                                                    {this.props.t('SystemConnections.ewCustomerAccess')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS,
                                            roles: [Role.CAC_R]
                                        },
                                        {
                                            title: (
                                                <span data-qa={qa.systemConnections.tabOtherSystems}>
                                                    {this.props.t('SystemConnections.otherSystems')}
                                                </span>
                                            ),
                                            value: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS,
                                            roles: [Role.OAC_R, Role.PUESC]
                                        }
                                    ].filter(t =>
                                        this.props.logic
                                            .auth()
                                            .roles()
                                            .some(r => t.roles?.includes(r))
                                    )}
                                />
                            </header>
                            <main>
                                <Row gutter={40}>
                                    <Col xs={24} md={18}>
                                        <h3>
                                            <strong>{t('CustomerApiIntroduction.title')}</strong>
                                        </h3>
                                        <p>{t('CustomerApiIntroduction.description1')}</p>
                                        <p>{t('CustomerApiIntroduction.description2')}</p>
                                        <div id={SWAGGER_ID} />
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <div className="system-connections-card">
                                            <Button
                                                qa={qa.systemConnections.btnCopyToken}
                                                type="primary"
                                                block
                                                onClick={this._onCopyTokenClick}
                                                disabled={this.props.logic.demo().isActive}
                                            >
                                                {t('CustomerApiReference.copyToken')}
                                            </Button>
                                            <Button
                                                qa={qa.systemConnections.btnGenerateApiKey}
                                                type="primary"
                                                block
                                                onClick={this._onGenerateApiKeyClick}
                                                disabled={this.props.logic.demo().isActive}
                                            >
                                                {t('CustomerApiIntroduction.generateButton')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </main>
                        </>
                    }
                    extra={[
                        <>
                            <TableBar heading={t('CustomerApi.apiKeys.title')} />
                            {applications.length === 0 && <p>{t('CustomerApi.apiKeys.empty')}</p>}
                            {applications.map(apiKey => (
                                <ApiKeyCard
                                    key={apiKey.id}
                                    id={apiKey.id.toString()}
                                    name={apiKey.name}
                                    apiKey={apiKey.apiKey}
                                    vehicles={apiKey.filter.length}
                                    demoMode={this.props.logic.demo().isActive}
                                    onDelete={() => this._onApiKeyDelete(apiKey.id)}
                                    onUpdate={() => this._onApiKeyUpdate(apiKey.id)}
                                />
                            ))}
                        </>
                    ]}
                />
                <CustomerApiModal
                    visible={this.state.modalOpen}
                    vehicles={this.state.vehicles}
                    keyGeneration={this.state.keyGeneration}
                    demoMode={this.props.logic.demo().isActive}
                    createApiKeyLoading={this.state.createApiKeyLoading}
                    editApiKeyLoading={this.state.editApiKeyLoading}
                    onApiKeyGenerate={this._onApiKeyCreate}
                    onApiKeySave={this._onApiKeyUpdateSave}
                    onApiKeyNameChange={this._onApiKeyNameChange}
                    onVehicleSelectionChange={this._onVehicleSelectionChange}
                    onTableSeeMore={this._onModalSeeMore}
                    onClose={this._onApiKeyEditCreateClose}
                />
                {this.state.removeConfirmModal.isOpen && (
                    <Confirm
                        danger
                        loading={this.state.removeApiKeyLoading}
                        header={t('CustomerApi.deleteHeader')}
                        message={t('CustomerApi.deleteConfirm')}
                        type={MessageType.WARNING}
                        confirmLabel={this.props.t('common.delete')}
                        onCancel={this._onApiKeyDeleteCancel}
                        onConfirm={this._onApiKeyDeleteConfirm}
                    />
                )}

                <HelperModal
                    name="system-connections"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _onTabChange = (value: string) => {
        this.props.history.push(value);
    };

    private _onCopyTokenClick = () => {
        const textField = document.createElement('textarea');
        textField.innerText = this.props.logic.auth().token() || '';
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    private _onVehicleSelectionChange = (selection: string[]) => {
        this.setState(state => ({
            ...state,
            keyGeneration: {
                ...state.keyGeneration,
                vehicles: selection
            }
        }));
    };

    private _onModalSeeMore = () => {
        this.setState({ modalOpen: false }, () => {
            this.props.history.push('/settings/customer-api/reference');
        });
    };

    private _onApiKeyEditCreateClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    private _onApiKeyDeleteCancel = () => {
        this.setState({
            removeConfirmModal: {
                isOpen: false
            }
        });
    };

    private _onGenerateApiKeyClick = () => {
        this.setState({
            modalOpen: true
        });
    };

    private _onApiKeyDelete = (id: number) => {
        this.setState({
            removeConfirmModal: {
                isOpen: true,
                id
            }
        });
    };

    private _onApiKeyDeleteConfirm = async () => {
        if (!this.state.removeConfirmModal.id) {
            console.error('cant remove api key, missing id');
            return;
        }
        this.setState({
            removeApiKeyLoading: true
        });
        try {
            await this.props.logic.customerAPI().removeKey(this.state.removeConfirmModal.id);
            const applications = await this.props.logic?.customerAPI().getAll();
            this.setState({
                applications
            });
            message.success(this.props.t('CustomerApi.message.removeSuccess'));
        } catch (err) {
            console.error(`Create api key failed, err: ${err}`);
            message.error(this.props.t('CustomerApi.message.removeError'));
        } finally {
            this.setState({
                removeConfirmModal: {
                    isOpen: false
                }
            });
        }
        this.setState({
            removeApiKeyLoading: false
        });
    };

    private _onApiKeyUpdate = (id: number) => {
        const app = this.state.applications.find(a => a.id === id);
        this.setState({
            keyGeneration: {
                id,
                name: app?.name || '',
                vehicles: app?.filter || []
            },
            modalOpen: true
        });
    };

    private _onApiKeyNameChange = (name: string) => {
        this.setState(state => ({ keyGeneration: { ...state.keyGeneration, name } }));
    };

    private _onApiKeyCreate = async () => {
        this.setState({
            createApiKeyLoading: true
        });
        const { name, vehicles } = this.state.keyGeneration;
        try {
            await this.props.logic?.customerAPI().generateKey(name, vehicles);
            const applications = await this.props.logic?.customerAPI().getAll();
            this.setState({
                applications,
                modalOpen: false,
                keyGeneration: {
                    id: null,
                    name: '',
                    vehicles: []
                }
            });
            message.success(this.props.t('CustomerApi.message.createSuccess'));
        } catch (err) {
            console.error(`Create api key failed, err: ${err}`);
            message.error(this.props.t('CustomerApi.message.createError'));
        }
        this.setState({
            createApiKeyLoading: false
        });
    };

    private _onApiKeyUpdateSave = async () => {
        this.setState({
            editApiKeyLoading: true
        });
        const { id, name, vehicles } = this.state.keyGeneration;
        try {
            await this.props.logic?.customerAPI().editKey(Number(id), name, vehicles);
            const applications = await this.props.logic?.customerAPI().getAll();
            this.setState({
                applications,
                modalOpen: false,
                keyGeneration: {
                    id: null,
                    name: '',
                    vehicles: []
                }
            });
            message.success(this.props.t('CustomerApi.message.editSuccess'));
        } catch (err) {
            console.error(`Update api key failed, err ${err}`);
            message.error(this.props.t('CustomerApi.message.editError'));
        }
        this.setState({
            editApiKeyLoading: false
        });
    };

    private _onHelperClick = () => {
        const module: DocsUserGuide = 'publicapi';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(withLogicContext(CustomerApiModule)));
