/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvgPricePerKmOfFleetType,
    AvgPricePerKmOfFleetTypeFromJSON,
    AvgPricePerKmOfFleetTypeFromJSONTyped,
    AvgPricePerKmOfFleetTypeToJSON,
    MoPricePerKm,
    MoPricePerKmFromJSON,
    MoPricePerKmFromJSONTyped,
    MoPricePerKmToJSON,
} from './';

/**
 * 
 * @export
 * @interface CostCalculationResult
 */
export interface CostCalculationResult {
    /**
     * 
     * @type {string}
     * @memberof CostCalculationResult
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationResult
     */
    avgPricePerKm: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationResult
     */
    clientId: number;
    /**
     * 
     * @type {Array<AvgPricePerKmOfFleetType>}
     * @memberof CostCalculationResult
     */
    avgPricePerKmOfFleetTypes: Array<AvgPricePerKmOfFleetType>;
    /**
     * 
     * @type {Array<MoPricePerKm>}
     * @memberof CostCalculationResult
     */
    moPricePerKm: Array<MoPricePerKm>;
}

export function CostCalculationResultFromJSON(json: any): CostCalculationResult {
    return CostCalculationResultFromJSONTyped(json, false);
}

export function CostCalculationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostCalculationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'avgPricePerKm': json['avg_price_per_km'],
        'clientId': json['client_id'],
        'avgPricePerKmOfFleetTypes': ((json['avg_price_per_km_of_fleet_types'] as Array<any>).map(AvgPricePerKmOfFleetTypeFromJSON)),
        'moPricePerKm': ((json['mo_price_per_km'] as Array<any>).map(MoPricePerKmFromJSON)),
    };
}

export function CostCalculationResultToJSON(value?: CostCalculationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'avg_price_per_km': value.avgPricePerKm,
        'client_id': value.clientId,
        'avg_price_per_km_of_fleet_types': ((value.avgPricePerKmOfFleetTypes as Array<any>).map(AvgPricePerKmOfFleetTypeToJSON)),
        'mo_price_per_km': ((value.moPricePerKm as Array<any>).map(MoPricePerKmToJSON)),
    };
}


