import moment from 'moment';
import { VehicleDelayedFilterCode } from 'common/model/tracking';

export const actual = (rta: string | undefined | null, eta: string | undefined | null) => {
    if (rta && eta) {
        const momentRta = moment(rta);
        const momentEta = moment(eta);
        const duration = moment.duration(momentEta.diff(momentRta));

        return `${
            duration.asMinutes() <= 0 ? VehicleDelayedFilterCode.ON_TIME : momentEta.diff(momentRta) < 0 ? '- ' : '+ '
        }${
            duration.asMinutes() > 0
                ? Math.abs(duration.get('days')) > 0
                    ? `${Math.abs(duration.get('days'))}d`
                    : Math.abs(duration.get('hours')) > 0
                    ? `${Math.abs(duration.get('hours'))}h ${
                          Math.abs(duration.get('hours')) < 2 && Math.abs(duration.get('minutes')) > 0
                              ? `${Math.abs(duration.get('minutes'))}m`
                              : ''
                      }`
                    : `${Math.abs(duration.get('minutes'))}m`
                : ''
        }`;
    }

    return '';
};

export const durationToFormated = (duration: moment.Duration | undefined | null) => {
    if (duration) {
        return `${
            duration.asMinutes() > 0
                ? `${Math.abs(duration.get('days')) >= 1 ? `${Math.abs(duration.get('days'))}d` : ''} ${
                      Math.abs(duration.get('hours')) >= 1 ? `${Math.abs(duration.get('hours'))}h` : ''
                  } ${Math.abs(duration.get('minutes')) >= 1 ? `${Math.abs(duration.get('minutes'))}m` : ''}`
                : ''
        }`;
    }

    return '';
};

export const durationToFormatedWithSeconds = (duration: moment.Duration | undefined | null) => {
    if (duration) {
        return `${
            duration.asSeconds() > 0
                ? `${Math.abs(duration.get('days')) >= 1 ? `${Math.abs(duration.get('days'))}d` : ''} ${
                      Math.abs(duration.get('hours')) >= 1 ? `${Math.abs(duration.get('hours'))}h` : ''
                  } ${Math.abs(duration.get('minutes')) >= 1 ? `${Math.abs(duration.get('minutes'))}m` : ''} ${
                      Math.abs(duration.get('seconds')) >= 1 ? `${Math.abs(duration.get('seconds'))}s` : ''
                  }`
                : ''
        }`;
    }

    return '';
};
