import cn from 'classnames';
import { Progress, Rate } from 'common/components';
import { ScoreCardColors } from 'common/components/ScoreCard';
import { roundToStep } from 'common/utils/averages';

interface Props {
    value: number;
    color: 'yellow' | 'lightblue' | 'green' | 'blue' | 'purple';
    size: number;
    withRate?: boolean;
    qa?: string;
}

export default function DriverScoreProgress({ value, color = 'green', size, withRate = true, qa }: Props) {
    return (
        <Progress
            className={cn('driver-score-progress', {
                'driver-score-progress-blue': color === 'blue',
                'driver-score-progress-yellow': color === 'yellow',
                'driver-score-progress-lightblue': color === 'lightblue',
                'driver-score-progress-green': color === 'green',
                'driver-score-progress-purple': color === 'purple'
            })}
            percent={value}
            type="dashboard"
            gapDegree={84}
            trailColor="#6c6c6c"
            strokeWidth={8}
            strokeColor={
                color === 'lightblue'
                    ? ScoreCardColors.Lightblue
                    : color === 'blue'
                    ? ScoreCardColors.Blue
                    : color === 'green'
                    ? ScoreCardColors.Green
                    : color === 'purple'
                    ? ScoreCardColors.Purple
                    : ScoreCardColors.Yellow
            }
            format={() => (
                <div className="driver-score-progress-content">
                    <span data-qa={qa}>{roundToStep(value, 0.1)}</span>
                    {withRate && (
                        <Rate
                            size={size > 120 ? 'small' : 'xsmall'}
                            value={roundToStep(value, 0.5)}
                            color={color}
                            disabled
                            allowHalf
                        />
                    )}
                </div>
            )}
            width={size}
        />
    );
}
