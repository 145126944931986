/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VGlobalData
 */
export interface VGlobalData {
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TStayIncTStayPtoTREng: number;
    /**
     * 
     * @type {Array<Date>}
     * @memberof VGlobalData
     */
    fg0DateStart: Array<Date>;
    /**
     * 
     * @type {Array<Date>}
     * @memberof VGlobalData
     */
    fg0DateEnd: Array<Date>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VGlobalData
     */
    fg0Driver1: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VGlobalData
     */
    fg0Driver2: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TStayIdle: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0DOdo: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0DDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TREng: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0AltIncrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0CntDpDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0C: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TStayIdleTREng: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0TStayIncTStayPto: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0CDrvDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0MAvg: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fg0DiffDOdoDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc0: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc1: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc2: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc3: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc4: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc5: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc6: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc7: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc8: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    dDrvWeightCatPerc33: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    loadWeightQ: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    noLoad: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    loadL5: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    loadM5: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    loadNan: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fc1TStayIdleU2Min: number;
    /**
     * 
     * @type {number}
     * @memberof VGlobalData
     */
    fc1TStayIdleU2MinTREng: number;
}

export function VGlobalDataFromJSON(json: any): VGlobalData {
    return VGlobalDataFromJSONTyped(json, false);
}

export function VGlobalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VGlobalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fg0TStayIncTStayPtoTREng': json['fg0_t_stay_inc_t_stay_pto_t_r_eng'],
        'fg0DateStart': json['fg0_date_start'],
        'fg0DateEnd': json['fg0_date_end'],
        'fg0Driver1': json['fg0_driver1'],
        'fg0Driver2': json['fg0_driver2'],
        'fg0TStayIdle': json['fg0_t_stay_idle'],
        'fg0DOdo': json['fg0_d_odo'],
        'fg0DDrv': json['fg0_d_drv'],
        'fg0TREng': json['fg0_t_r_eng'],
        'fg0TDrv': json['fg0_t_drv'],
        'fg0AltIncrDDrv': json['fg0_alt_incr_d_drv'],
        'fg0CntDpDDrv': json['fg0_cnt_dp_d_drv'],
        'fg0C': json['fg0_c'],
        'fg0TStayIdleTREng': json['fg0_t_stay_idle_t_r_eng'],
        'fg0TStayIncTStayPto': json['fg0_t_stay_inc_t_stay_pto'],
        'fg0CDrvDDrv': json['fg0_c_drv_d_drv'],
        'fg0MAvg': json['fg0_m_avg'],
        'fg0DiffDOdoDDrv': json['fg0_diff_d_odo_d_drv'],
        'dDrvWeightCatPerc0': json['d_drv_weight_cat_perc_0'],
        'dDrvWeightCatPerc1': json['d_drv_weight_cat_perc_1'],
        'dDrvWeightCatPerc2': json['d_drv_weight_cat_perc_2'],
        'dDrvWeightCatPerc3': json['d_drv_weight_cat_perc_3'],
        'dDrvWeightCatPerc4': json['d_drv_weight_cat_perc_4'],
        'dDrvWeightCatPerc5': json['d_drv_weight_cat_perc_5'],
        'dDrvWeightCatPerc6': json['d_drv_weight_cat_perc_6'],
        'dDrvWeightCatPerc7': json['d_drv_weight_cat_perc_7'],
        'dDrvWeightCatPerc8': json['d_drv_weight_cat_perc_8'],
        'dDrvWeightCatPerc33': json['d_drv_weight_cat_perc_33'],
        'loadWeightQ': json['load_weight_q'],
        'noLoad': json['no_load'],
        'loadL5': json['load_l_5'],
        'loadM5': json['load_m_5'],
        'loadNan': json['load_nan'],
        'fc1TStayIdleU2Min': json['fc1_t_stay_idle_u_2_min'],
        'fc1TStayIdleU2MinTREng': json['fc1_t_stay_idle_u_2_min_t_r_eng'],
    };
}

export function VGlobalDataToJSON(value?: VGlobalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fg0_t_stay_inc_t_stay_pto_t_r_eng': value.fg0TStayIncTStayPtoTREng,
        'fg0_date_start': value.fg0DateStart,
        'fg0_date_end': value.fg0DateEnd,
        'fg0_driver1': value.fg0Driver1,
        'fg0_driver2': value.fg0Driver2,
        'fg0_t_stay_idle': value.fg0TStayIdle,
        'fg0_d_odo': value.fg0DOdo,
        'fg0_d_drv': value.fg0DDrv,
        'fg0_t_r_eng': value.fg0TREng,
        'fg0_t_drv': value.fg0TDrv,
        'fg0_alt_incr_d_drv': value.fg0AltIncrDDrv,
        'fg0_cnt_dp_d_drv': value.fg0CntDpDDrv,
        'fg0_c': value.fg0C,
        'fg0_t_stay_idle_t_r_eng': value.fg0TStayIdleTREng,
        'fg0_t_stay_inc_t_stay_pto': value.fg0TStayIncTStayPto,
        'fg0_c_drv_d_drv': value.fg0CDrvDDrv,
        'fg0_m_avg': value.fg0MAvg,
        'fg0_diff_d_odo_d_drv': value.fg0DiffDOdoDDrv,
        'd_drv_weight_cat_perc_0': value.dDrvWeightCatPerc0,
        'd_drv_weight_cat_perc_1': value.dDrvWeightCatPerc1,
        'd_drv_weight_cat_perc_2': value.dDrvWeightCatPerc2,
        'd_drv_weight_cat_perc_3': value.dDrvWeightCatPerc3,
        'd_drv_weight_cat_perc_4': value.dDrvWeightCatPerc4,
        'd_drv_weight_cat_perc_5': value.dDrvWeightCatPerc5,
        'd_drv_weight_cat_perc_6': value.dDrvWeightCatPerc6,
        'd_drv_weight_cat_perc_7': value.dDrvWeightCatPerc7,
        'd_drv_weight_cat_perc_8': value.dDrvWeightCatPerc8,
        'd_drv_weight_cat_perc_33': value.dDrvWeightCatPerc33,
        'load_weight_q': value.loadWeightQ,
        'no_load': value.noLoad,
        'load_l_5': value.loadL5,
        'load_m_5': value.loadM5,
        'load_nan': value.loadNan,
        'fc1_t_stay_idle_u_2_min': value.fc1TStayIdleU2Min,
        'fc1_t_stay_idle_u_2_min_t_r_eng': value.fc1TStayIdleU2MinTREng,
    };
}


