import { WithTranslation, withTranslation } from 'react-i18next';
import Table from 'common/components/Table';
import { ColumnType } from 'antd/lib/table';
import { AlarmInDatabaseWithGPSInfo } from 'generated/backend-api';
import Search from 'common/components/Search';
import { VehicleModel } from 'logic/vehicle/vehicles';
import { TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';

interface Props extends WithTranslation {
    data?: AlarmInDatabaseWithGPSInfo[];
    vehicles?: VehicleModel[];
    search?: { text: string };
    loading?: boolean;
    onSearchChange?: (text: string) => void;
}

function AlarmsTable(props: Props) {
    function onSearchChange(text: string) {
        props.onSearchChange?.(text);
    }

    return (
        <>
            <div className="alarms-filter">
                <Search search={props.search} searchActive onSearch={onSearchChange} />
            </div>
            <div className="alarms-table">
                <Table<AlarmInDatabaseWithGPSInfo>
                    loading={props.loading}
                    dataSource={props.data?.map(e => ({ ...e, key: e.alarmId }))}
                    columns={createColumns()}
                    scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
                />
            </div>
        </>
    );

    function createColumns() {
        return [
            {
                title: props.t('common.alarmType'),
                dataIndex: 'alarmType',
                key: 'alarmType',
                align: 'center',
                width: 35,
                render: alarmType => alarmType
            },
            {
                title: props.t('common.start'),
                dataIndex: 'start',
                key: 'start',
                align: 'center',
                width: 35,
                render: start => JSON.stringify(start)
            },
            {
                title: props.t('common.vehicle'),
                dataIndex: 'monitoredObjectId',
                key: 'monitoredObjectId',
                align: 'center',
                width: 35,
                render: monitoredObjectId => monitoredObjectId
            },
            {
                title: props.t('common.client'),
                dataIndex: 'clientId',
                key: 'clientId',
                align: 'center',
                width: 35,
                render: clientId => clientId
            },
            {
                title: props.t('common.end'),
                dataIndex: 'end',
                key: 'end',
                align: 'center',
                width: 35,
                render: end => JSON.stringify(end)
            },
            {
                title: props.t('common.acknowledged'),
                dataIndex: 'acknowledged',
                key: 'acknowledged',
                align: 'center',
                width: 35,
                render: acknowledged => (acknowledged ? 'true' : 'false')
            },
            {
                title: props.t('common.canceled'),
                dataIndex: 'canceled',
                key: 'canceled',
                align: 'center',
                width: 35,
                render: canceled => (canceled ? 'true' : 'false')
            },
            {
                title: props.t('common.uniqueData'),
                dataIndex: 'uniqueData',
                key: 'uniqueData',
                align: 'center',
                width: 35,
                render: uniqueData => JSON.stringify(uniqueData)
            },
            {
                title: props.t('common.lastGpsPointStartObj'),
                dataIndex: 'lastGpsPointStartObj',
                key: 'lastGpsPointStartObj',
                align: 'center',
                width: 35,
                render: lastGpsPointStartObj => JSON.stringify(lastGpsPointStartObj)
            },
            {
                title: props.t('common.lastGpsPointEndObj'),
                dataIndex: 'lastGpsPointEndObj',
                key: 'lastGpsPointEndObj',
                align: 'center',
                width: 35,
                render: lastGpsPointEndObj => JSON.stringify(lastGpsPointEndObj)
            },
            {
                title: props.t('common.addressStart'),
                dataIndex: 'addressStart',
                key: 'addressStart',
                align: 'center',
                width: 35,
                render: addressStart => JSON.stringify(addressStart)
            },
            {
                title: props.t('common.addressEnd'),
                dataIndex: 'addressEnd',
                key: 'addressEnd',
                align: 'center',
                width: 35,
                render: addressEnd => JSON.stringify(addressEnd)
            },
            {
                title: props.t('common.alarmId'),
                dataIndex: 'alarmId',
                key: 'alarmId',
                align: 'center',
                width: 35,
                render: alarmId => JSON.stringify(alarmId)
            }
        ] as ColumnType<AlarmInDatabaseWithGPSInfo>[];
    }
}

export default withTranslation()(AlarmsTable);
