import { ReactNode } from 'react';
import { Row, Col, Divider } from 'antd';

export function LayoutDetail({
    firstLeft,
    firstRight,
    firstTitle,
    secondLeft,
    secondRight,
    secondTitle,
    hasSecondDivider,
    thirdLeft,
    thirdRight,
    thirdTitle,
    forthLeft,
    forthRight,
    forthTitle,
    fifthLeft,
    fifthRight,
    fifthTitle,
    sixthLeft,
    sixthRight,
    sixthTitle
}: {
    firstLeft?: ReactNode;
    firstRight?: ReactNode;
    firstTitle?: string;
    secondLeft?: ReactNode;
    secondRight?: ReactNode;
    secondTitle?: string;
    hasSecondDivider?: boolean;
    thirdLeft?: ReactNode;
    thirdRight?: ReactNode;
    thirdTitle?: string;
    forthLeft?: ReactNode;
    forthRight?: ReactNode;
    forthTitle?: string;
    fifthLeft?: ReactNode;
    fifthRight?: ReactNode;
    fifthTitle?: string;
    sixthLeft?: ReactNode;
    sixthRight?: ReactNode;
    sixthTitle?: string;
}) {
    return (
        <>
            {firstTitle && <h3>{firstTitle}</h3>}
            <Row>
                <Col span={firstRight ? 12 : 24}>{firstLeft}</Col>
                <Col span={12}>{firstRight}</Col>
            </Row>
            {(secondLeft || secondRight) && (
                <>
                    {hasSecondDivider && <Divider />}
                    {secondTitle && <h3>{secondTitle}</h3>}
                    <Row>
                        <Col span={secondRight ? 12 : 24}>{secondLeft}</Col>
                        <Col span={12}>{secondRight}</Col>
                    </Row>
                </>
            )}
            {(thirdLeft || thirdRight) && (
                <>
                    <Divider />
                    {thirdTitle && <h3>{thirdTitle}</h3>}
                    <Row>
                        <Col span={thirdRight ? 12 : 24}>{thirdLeft}</Col>
                        <Col span={12}>{thirdRight}</Col>
                    </Row>
                </>
            )}
            {(forthLeft || forthRight) && (
                <>
                    <Divider />
                    {forthLeft && <h3>{forthTitle}</h3>}
                    <Row>
                        <Col span={forthRight ? 12 : 24}>{forthLeft}</Col>
                        <Col span={12}>{forthRight}</Col>
                    </Row>
                </>
            )}
            {(fifthLeft || fifthRight) && (
                <>
                    <Divider />
                    {fifthLeft && <h3>{fifthTitle}</h3>}
                    <Row>
                        <Col span={fifthRight ? 12 : 24}>{fifthLeft}</Col>
                        <Col span={12}>{fifthRight}</Col>
                    </Row>
                </>
            )}
            {(sixthLeft || sixthRight) && (
                <>
                    <Divider />
                    {sixthLeft && <h3>{sixthTitle}</h3>}
                    <Row>
                        <Col span={sixthRight ? 12 : 24}>{sixthLeft}</Col>
                        <Col span={12}>{sixthRight}</Col>
                    </Row>
                </>
            )}
        </>
    );
}
