/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverInfoDeviceType,
    DriverInfoDeviceTypeFromJSON,
    DriverInfoDeviceTypeFromJSONTyped,
    DriverInfoDeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverInfoDriver
 */
export interface DriverInfoDriver {
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    muDataOriginalJstr?: string;
    /**
     * 
     * @type {DriverInfoDeviceType}
     * @memberof DriverInfoDriver
     */
    deviceType: DriverInfoDeviceType;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    driverToken: string;
    /**
     * 
     * @type {number}
     * @memberof DriverInfoDriver
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    authType?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoDriver
     */
    source?: string;
}

export function DriverInfoDriverFromJSON(json: any): DriverInfoDriver {
    return DriverInfoDriverFromJSONTyped(json, false);
}

export function DriverInfoDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverInfoDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'muDataOriginalJstr': !exists(json, 'mu_data_original_jstr') ? undefined : json['mu_data_original_jstr'],
        'deviceType': DriverInfoDeviceTypeFromJSON(json['device_type']),
        'driverToken': json['driver_token'],
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'authType': !exists(json, 'auth_type') ? undefined : json['auth_type'],
        'source': !exists(json, 'source') ? undefined : json['source'],
    };
}

export function DriverInfoDriverToJSON(value?: DriverInfoDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mu_data_original_jstr': value.muDataOriginalJstr,
        'device_type': DriverInfoDeviceTypeToJSON(value.deviceType),
        'driver_token': value.driverToken,
        'driver_id': value.driverId,
        'name': value.name,
        'surname': value.surname,
        'auth_type': value.authType,
        'source': value.source,
    };
}


