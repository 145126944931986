import * as Yup from 'yup';
import { Form, Formik, FieldArray, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row } from 'antd';

import { Button, Tag } from 'common/components';
import { InputField } from 'common/fields';

export interface PuescModel {
    monitoredObjectId: number;
    rn: string;
    monitoringDeviceId?: number;
    obuSerialNumber: string;
    gpsLocatorNumber: string;
    state: string;
}

export interface PuescFormValues {
    data?: PuescModel[];
}

interface Props {
    isImpersonator: boolean;
    initialValues?: Partial<PuescFormValues>;
    onSubmit: (values: PuescFormValues) => Promise<boolean>;
}

export default function PuescForm({ initialValues, isImpersonator, onSubmit }: Props) {
    const { t } = useTranslation();
    const shape = {};
    const schema = Yup.object().shape(shape);

    function handleOnSubmit(values: PuescFormValues) {
        onSubmit?.(values);
    }

    return (
        <Formik<PuescFormValues>
            initialValues={{
                data: initialValues?.data ?? []
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<PuescFormValues>) => (
                <div className="puesc-form">
                    <Divider />

                    <Form>
                        <FieldArray
                            name="data"
                            render={() => (
                                <div className="puesc-table">
                                    <Row className="puesc-table-row-heading">
                                        <Col span={5}>{t('RouteOverviewTable.vehicleRN')}</Col>
                                        <Col span={7}>{t('SystemConnections.puescForm.obuSerialNumber')}</Col>
                                        <Col span={7}>{t('SystemConnections.puescForm.gpsDeviceLocatorNumber')}</Col>
                                        <Col span={5}>{t('ExpensesTable.state')}</Col>
                                    </Row>
                                    {initialValues?.data?.map((d, index) => (
                                        <Row key={index} className="puesc-table-row">
                                            <Col span={5}>
                                                <div className="puesc-table-row-rn">{d.rn}</div>
                                            </Col>
                                            <Col span={7}>
                                                <div className="puesc-table-row-obu">{d.obuSerialNumber}</div>
                                            </Col>
                                            <Col span={7}>
                                                {isImpersonator ? (
                                                    <InputField name={`data.${index}.gpsLocatorNumber`} />
                                                ) : (
                                                    <div className="puesc-table-row-gps">{d.gpsLocatorNumber}</div>
                                                )}
                                            </Col>
                                            <Col span={5}>
                                                <div className="puesc-table-row-state">
                                                    {d.state && (
                                                        <Tag className={`state-${d.state}`}>
                                                            {t(`SystemConnections.puescForm.${d.state}`)}
                                                        </Tag>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            )}
                        />
                        {isImpersonator && (
                            <Row justify="end">
                                <Col>
                                    <Button loading={formik.isSubmitting} htmlType="submit" type="primary" block>
                                        {t('common.confirm')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            )}
        </Formik>
    );
}
