export const KM: string = 'km';
export const KG: string = 'kg';
export const M: string = 'm';
export const T: string = 't';
export const H: string = 'h';
export const L: string = 'l';
export const PC: string = 'pc';
export const M3: string = 'm3';
export const G: string = 'g';
export const V: string = 'V';
export const EUR: string = 'EUR';
export const deg: string = '°';
export const degC: string = '°C';
export const DATE_FORMAT: string = 'DD.MM.YYYY';
export const DATE_FORMAT_SHORT_BE: string = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT: string = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT_SHORT: string = 'DD.MM. HH:mm';
export const MONTH_YEAR_FORMAT = 'MM-YYYY';
export const TIME_FORMAT: string = 'HH:mm:ss';
export const TIME_FORMAT_SHORT: string = 'HH:mm';
export const DRIVER_BEHAVIOR_DATE_FORMAT: string = 'YYYY-MM';
export const DRIVER_BEHAVIOR_UPGRADE_LINK = 'https://www.dispecer.sk/';
export const GPS_FORMAT = '0.000';
export const DAILY_PERFORMANCE: number = 46800; // 13h
export const DAILY_RIDE: number = 32400; // 9h
export const MIN_WEEKLY_RIDE: number = 129600; // 36h
export const MAX_WEEKLY_RIDE: number = 201600; // 56h
export const MAX_BIWEEKLY_RIDE: number = 324000; // 90h
export const DAILY_RIDE_RESTING_NEED: number = 16200; // 4h 30m
export const DAILY_RESTING_NEED: number = 2700; // 45m
export const NO_DATA_MOVING: number = 3600; // 1h
export const NO_DATA_STANDING: number = 201600; // 56h
export const DEFAULT_PAGE_LIMIT_SHORT: number = 12;
export const DEFAULT_PAGE_LIMIT: number = 20;
export const DEFAULT_PAGE_OFFSET: number = 0;
export const INFINITY_PAGE_LIMIT: number = 1e4;
export const DEFAULT_MAINTENANCE_NOTIFICATION_DAYS: number = 14;
export const DEFAULT_MAINTENANCE_NOTIFICATION_KM: number = 500;
export const DEFAULT_OPTIMAL_RPM = 1440;
export const MAX_ZOOM: number = 16;
export const DEFAULT_MAX_ZOOM: number = 22;
export const DEFAULT_MIN_ZOOM: number = 4;
export const ODOMETER_LIMIT_EDIT_DAYS = 30;
export const INCREASE_VEHICLE_UPDATES_INTERVAL: number = 55000; // 0.9 min
export const AUTO_ZOOM_OUT_INTERVAL: number = 900000; // 15 min
export const MAX_TRAILER_TEMPERATURE_ZONES = 3;
export const ZONE_RULE_NAME = 'transport_cold_chain_zone_';
export const COLD_CHAIN_PROFILE_ID = 'cold_chain_profile_id';
export const TASK_PLACE_TIME_DEFAULT = 3600; // 60 min
export const TASK_PLACE_TIME_REFUELING = 1800; // 30 min

export const DWL_LOCK = 15; // 15 days

export enum MAP_MARKERS_INDEX {
    VEHICLE = 5,
    FUEL_STATION = 4,
    PARKING = 3,
    WASH = 2,
    POI = 1
}

export const PHONE_REGEX = /^$|(^\+{1}[1-9]{1}\d{1,2}\s?-?\d{3}\s?-?\d{3}\s?-?\d{2,3}\s?-?\d{0,4})$/;

// STYLES
export const TABLE_SCROLL_Y_DEFAULT = 'calc(100vh - 375px)'; // with pagging
export const TABLE_SCROLL_Y_WITHOUT_PAGGING = 'calc(100vh - 330px)';
export const TABLE_SCROLL_Y_SMALL = 'calc(100vh - 420px)';
export const TABLE_SCROLL_Y_SUPER_SMALL = 'calc(100vh - 750px)';
