/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlannedTransportPlaceCrossingPoints
 */
export interface PlannedTransportPlaceCrossingPoints {
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlaceCrossingPoints
     */
    ptvRouteId?: string;
    /**
     * 
     * @type {number}
     * @memberof PlannedTransportPlaceCrossingPoints
     */
    lng: number;
    /**
     * 
     * @type {number}
     * @memberof PlannedTransportPlaceCrossingPoints
     */
    lat: number;
}

export function PlannedTransportPlaceCrossingPointsFromJSON(json: any): PlannedTransportPlaceCrossingPoints {
    return PlannedTransportPlaceCrossingPointsFromJSONTyped(json, false);
}

export function PlannedTransportPlaceCrossingPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlannedTransportPlaceCrossingPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ptvRouteId': !exists(json, 'ptvRouteId') ? undefined : json['ptvRouteId'],
        'lng': json['lng'],
        'lat': json['lat'],
    };
}

export function PlannedTransportPlaceCrossingPointsToJSON(value?: PlannedTransportPlaceCrossingPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ptvRouteId': value.ptvRouteId,
        'lng': value.lng,
        'lat': value.lat,
    };
}


