import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { Card, Button, Tag } from 'common/components';
import Descriptions from 'common/components/Descriptions';
import * as CommonIcons from 'resources/images/common';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'domain-constants';

interface Props {
    taskId: string;
    taskState: string;
    taskTaskTypeName: string;
    taskSubject: string;
    taskDueValue: string;
    notificationId?: string;
    createdAt?: string;
    demoMode?: boolean;
    onMarkAsSeen?: (id: string) => void;
    onTaskOpen?: (id: string) => void;
}

export default function NotificationBlock({
    taskId: id,
    taskState,
    taskTaskTypeName,
    taskSubject,
    taskDueValue,
    notificationId,
    createdAt,
    demoMode,
    onMarkAsSeen,
    onTaskOpen
}: Props) {
    const { t } = useTranslation();

    return (
        <Card className="notification-block">
            <Row>
                <Col flex="auto">
                    {createdAt && (
                        <div className="notification-block-alert-time">
                            {moment(createdAt).format(DATE_TIME_FORMAT)}
                        </div>
                    )}
                    <div>
                        <Tag className={`tag-maintenance-state tag-maintenance-state-${taskState}`}>{taskState}</Tag>
                    </div>
                </Col>
                <Col flex="30px">
                    {notificationId && (
                        <Button
                            type="link"
                            className="notification-block-close"
                            disabled={demoMode}
                            icon={<img alt="close" src={CommonIcons.iconClose} />}
                            onClick={() => onMarkAsSeen?.(notificationId)}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('Maintenance.task.taskType')} className="notification-block-label">
                            {taskTaskTypeName}{' '}
                            <span className="notification-block-label-due">
                                {taskState === 'overdue'
                                    ? t('Maintenance.notification.isOverdue')
                                    : t('Maintenance.notification.isDueWithin')}
                            </span>
                            {taskDueValue}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row className="notification-block-footer">
                <Col span={16}>
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.subject')}>{taskSubject}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={8} className="notification-block-footer-action">
                    <Button type="link" onClick={() => onTaskOpen?.(id)}>
                        {t('Maintenance.notification.openTask')}
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
