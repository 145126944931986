/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WayOfLoading {
    Top = 'top',
    Back = 'back',
    Side = 'side'
}

export function WayOfLoadingFromJSON(json: any): WayOfLoading {
    return WayOfLoadingFromJSONTyped(json, false);
}

export function WayOfLoadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): WayOfLoading {
    return json as WayOfLoading;
}

export function WayOfLoadingToJSON(value?: WayOfLoading | null): any {
    return value as any;
}

