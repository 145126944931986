import { WithTranslation, withTranslation } from 'react-i18next';
import PartnerTableBar from 'modules/partner/components/PartnerTableBar';
import ObuTable from '../ObuTable';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import { PartnerCompanyModel, PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import ObuCreate from '../ObuCreate';
import Confirm, { MessageType } from 'common/components/Confirm';
import ObuDetail from '../ObuDetail';
import PartnerPairing from 'modules/partner/components/PartnerPairing';
import { PairingItemType, ObuTypeSelectModel } from 'logic/partner/partner';
import { ReadOnlyMonitoringDeviceType } from 'generated/new-main';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { LayoutContent } from 'common/components/Layout/Content';
import { PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import { Role } from 'logic/auth';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import ObuDetailCard from '../ObuDetailCard';
import ObuLinkedItems from '../ObuLinkedItems';
import ObusActions from '../ObuActions';
import { obu } from 'resources/images/sidebar';
import { Button } from 'common/components';

interface Props extends WithTranslation {
    edit: boolean;
    loading: boolean;
    data?: PaginatedResponse<ObuModel[]>;
    selected?: ObuModel;
    create?: ObuModel;
    delete?: ObuModel;
    obuTypeCounter?: ObuTypeSelectModel[];
    search?: { text: string };
    pairing?: PartnerPairingModel;
    companies?: PartnerCompanySelectModel[];
    clients?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    deviceTypes?: ReadOnlyMonitoringDeviceType[];
    roles: Role[];
    userClient?: PartnerCompanyModel;
    createLoading?: boolean;
    onBarCompanyFilterChange?: (id: string) => void;
    onBarCompanyFilterCancel?: () => void;
    onBarCompanyFilterSearch?: (value: string) => void;
    onTableRowSelect?: (id: string) => void;
    onDetailFormCancel?: () => void;
    onDetailFormSubmit?: (model: ObuModel) => void;
    onDetailActionsUnpair?: (linked: PairingItemType) => void;
    onBarActionsCreate?: () => void;
    onDeleteConfirm?: () => void;
    onDeleteCancel?: () => void;
    onCreateSubmit?: (model: ObuModel) => void;
    onCreateCancel?: () => void;
    onPairingKeySelect?: (key: PartnerPairingKey) => void;
    onPairingItemSelect?: (itemId: string) => void;
    onPairingSearch?: (text: string) => void;
    onPairingConfirm?: () => void;
    onPairingCancel?: () => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
    onActionsUpdate?: () => void;
    onActionsPair?: () => void;
    onClientsFilterSearch?: (text: string) => void;
}

function Obu(props: Props) {
    const { t } = props;

    return (
        <>
            {props.pairing && (
                <PartnerPairing
                    keys={props.pairing.keys}
                    keySelected={props.pairing.keySelected}
                    items={props.pairing.items}
                    itemIdSelected={props.pairing.itemIdSelected}
                    search={props.pairing.search}
                    loading={props.pairing.loading}
                    keySource={PartnerPairingKey.obu}
                    onKeySelect={props.onPairingKeySelect}
                    onItemSelect={props.onPairingItemSelect}
                    onSearch={props.onPairingSearch}
                    onConfirm={props.onPairingConfirm}
                    onCancel={props.onPairingCancel}
                />
            )}

            {props.create && (
                <ObuCreate
                    model={props.create}
                    obuTypeCounter={props.obuTypeCounter}
                    deviceTypes={props.deviceTypes}
                    clients={props.clients}
                    selectedCompany={props.selectedCompany}
                    loading={props.createLoading}
                    onClientSearch={props.onClientsFilterSearch}
                    onSubmit={props.onCreateSubmit}
                    onCancel={props.onCreateCancel}
                />
            )}

            {props.delete && (
                <Confirm
                    danger
                    header={t('PartnerObu.deleteHeader')}
                    message={`${t('PartnerObu.deleteConfirm')} ${props.delete?.id}`}
                    type={MessageType.WARNING}
                    confirmLabel={props.t('common.delete')}
                    onConfirm={props.onDeleteConfirm}
                    onCancel={props.onDeleteCancel}
                />
            )}

            <LayoutContent
                className="partner-obu no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.obu')}
                            filters={[
                                <PartnerTableBar
                                    companies={props.companies}
                                    selectedCompany={props.selectedCompany}
                                    onCompanyFilterChange={props.onBarCompanyFilterChange}
                                    onCompanyFilterCancel={props.onBarCompanyFilterCancel}
                                    onCompanyFilterSearch={props.onBarCompanyFilterSearch}
                                />
                            ]}
                            actions={[
                                props.roles.includes(Role.PM_R) &&
                                    !props.userClient?.referrer &&
                                    props.userClient?.isPartner === true && (
                                        <Button type="dashed" onClick={props.onBarActionsCreate}>
                                            {t('PartnerObu.addNewObu')}
                                        </Button>
                                    )
                            ]}
                        />
                        <ObuTable
                            loading={props.loading}
                            data={props.data}
                            selected={props.selected}
                            obuTypeCounter={props.obuTypeCounter}
                            deviceTypes={props.deviceTypes}
                            onTableRowSelect={props.onTableRowSelect}
                            onPaginationChange={props.onPaginationChange}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            headerIcon={obu}
                            header={props.selected.sn}
                            body={
                                <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                    <Collapse.Panel header={props.t('common.detail')} key="1">
                                        {props.edit ? (
                                            <ObuDetail
                                                model={props.selected}
                                                obuTypeCounter={props.obuTypeCounter}
                                                deviceTypes={props.deviceTypes}
                                                clients={props.clients}
                                                roles={props.roles}
                                                userClient={props.userClient}
                                                onClientSearch={props.onClientsFilterSearch}
                                                onFormCancel={props.onDetailFormCancel}
                                                onFormSubmit={props.onDetailFormSubmit}
                                            />
                                        ) : (
                                            <ObuDetailCard data={props.selected} deviceTypes={props.deviceTypes} />
                                        )}
                                    </Collapse.Panel>

                                    {!props.edit ? (
                                        <Collapse.Panel header={props.t('Partner.linkedItems')} key="2">
                                            <ObuLinkedItems
                                                model={props.selected}
                                                onUnpair={props.onDetailActionsUnpair}
                                            />
                                        </Collapse.Panel>
                                    ) : (
                                        <></>
                                    )}
                                </Collapse>
                            }
                            footer={
                                !props.edit && (
                                    <ObusActions onPair={props.onActionsPair} onUpdate={props.onActionsUpdate} />
                                )
                            }
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(Obu);
