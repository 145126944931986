import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { RadioGroup } from 'common/components/RadioGroup';
import { ExpenseState } from 'logic/statistics/statistics-expenses';
import { CheckboxGroup, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import qa from 'qa-selectors';
import TableFilter from 'common/components/TableFilter';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    vehiclesOpts: CheckboxGroupOpts;
    vehiclesChecked: string[];
    type?: ExpenseType;
    payment?: ExpenseState;
    source?: ExpenseSource;
    demoMode?: boolean;
    onConfirm?: (vehiclesChecked: string[], type?: ExpenseType, payment?: ExpenseState, source?: ExpenseSource) => void;
    onCancel?: () => void;
}

interface State {
    vehiclesChecked: string[];
    type?: ExpenseType;
    payment?: ExpenseState;
    source?: ExpenseSource;
}

class ExpensesFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehiclesChecked: props.vehiclesChecked,
            type: props.type,
            source: props.source,
            payment: props.payment
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.vehiclesChecked.length !== this.props.vehiclesChecked.length) {
            this.setState({ vehiclesChecked: this.props.vehiclesChecked });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <TableFilter
                className="expenses-filter"
                qa={qa.expenses.filter.section}
                heading={t('ExpensesBar.filterName')}
                body={
                    <Row gutter={[24, 24]}>
                        {this.props.vehiclesOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.vehicles')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allVehicles')}
                                    options={this.props.vehiclesOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.vehiclesChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxVehiclesChange}
                                    qa={qa.expenses.filter.groupVehicles}
                                />
                            </Col>
                        )}
                        <Col>
                            <RadioGroup
                                heading={t('ExpensesFilter.type')}
                                scrolled={true}
                                type="radio"
                                options={[
                                    {
                                        code: '',
                                        label: t('ExpensesFilter.allTypes'),
                                        qa: qa.expenses.filter.radioTypesAll
                                    },
                                    ...Object.keys(ExpenseType).map(t => ({
                                        code: ExpenseType[t],
                                        label: this.props.t(`ExpensesFilter.${String(ExpenseType[t]).toLowerCase()}`),
                                        qa: qa.common.radio
                                    }))
                                ]}
                                checked={this.state.type ? this.state.type : ''}
                                onChange={this._onTypeRadioListChange}
                                name="type"
                                qa={qa.expenses.filter.groupVehicles}
                            />
                        </Col>

                        <Col>
                            <RadioGroup
                                heading={t('ExpensesFilter.payment')}
                                type="radio"
                                options={[
                                    {
                                        code: '',
                                        label: t('ExpensesFilter.allPayments'),
                                        qa: qa.expenses.filter.radioPaymentAll
                                    },
                                    ...Object.keys(ExpenseState).map(p => ({
                                        code: ExpenseState[p],
                                        label: this.props.t(`ExpensesFilter.${String(ExpenseState[p]).toLowerCase()}`),
                                        qa: qa.expenses.filter.radioState?.[String(ExpenseState[p]).toLowerCase()]
                                    }))
                                ]}
                                checked={this.state.payment ? this.state.payment : ''}
                                onChange={this._onPaymentRadioListChange}
                                name="payment"
                                qa={qa.expenses.filter.groupPayment}
                            />
                            <RadioGroup
                                heading={t('ExpensesFilter.source')}
                                type="radio"
                                options={[
                                    {
                                        code: '',
                                        label: t('ExpensesFilter.allSources'),
                                        qa: qa.expenses.filter.radioSourceAll
                                    },
                                    ...Object.keys(ExpenseSource).map(s => ({
                                        code: ExpenseSource[s],
                                        label: this.props.t(`ExpensesFilter.${String(ExpenseSource[s]).toLowerCase()}`),
                                        qa: qa.expenses.filter.radioSource?.[ExpenseSource[s]]
                                    }))
                                ]}
                                checked={this.state.source ? this.state.source : ''}
                                onChange={this._onSourceRadioListChange}
                                name="source"
                                qa={qa.expenses.filter.groupSource}
                            />
                        </Col>
                    </Row>
                }
                cancelButton={{
                    onClick: this._onCancelClick,
                    qa: qa.expenses.filter.btnCancel
                }}
                submitButton={{
                    onClick: this._onConfirmClick,
                    qa: qa.expenses.filter.btnSubmit,
                    disabled: this.props.demoMode
                }}
                resetButton={{
                    onClick: this._onResetClick
                }}
            />
        );
    }

    private _onResetClick = () => {
        const defaults = confDefault.settings.statistics.expenses.filter;
        this.setState({
            vehiclesChecked: [],
            type: defaults.type,
            payment: defaults.payment,
            source: defaults.source
        });
    };

    private _onCheckboxVehiclesChange = (vehiclesChecked: string[]) => {
        this.setState({
            vehiclesChecked
        });
    };

    private _onTypeRadioListChange = (code: string) => {
        this.setState({
            type: code !== 'all' ? (code as ExpenseType) : undefined
        });
    };

    private _onSourceRadioListChange = (code: string) => {
        this.setState({
            source: code !== 'all' ? (code as ExpenseSource) : undefined
        });
    };

    private _onPaymentRadioListChange = (code: string) => {
        this.setState({
            payment: code !== 'all' ? (code as ExpenseState) : undefined
        });
    };

    private _onConfirmClick = () => {
        this.props.onConfirm?.(
            this.state.vehiclesChecked.filter(c => !!c),
            this.state.type,
            this.state.payment,
            this.state.source
        );
    };

    private _onCancelClick = () => {
        this.props.onCancel?.();
    };
}

export default withTranslation()(ExpensesFilter);
