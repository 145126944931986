import { MouseEvent, Component } from 'react';
import {
    PoiModelMap,
    FuelTypes,
    fuelTypeToFuelIconName,
    FuelStationServicesTranslations
} from 'logic/map/logic/fuelStations';
import numeral from 'numeral';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';
import { Tag, Button, Row, Col } from 'antd';
import { Checkbox } from '../Form';
import { GPS_FORMAT } from 'domain-constants';
import qa from 'qa-selectors';
import * as fuelIcons from 'resources/images/fuels';
import * as fuelStationServices from 'resources/images/fuelstationsServices';

const EuroWagLogoBlueSvg = '/EuroWag-logo-blue.svg';
const TravisLogoSvg = '/Travis-logo.svg';

interface Props extends WithTranslation {
    type: 'parking' | 'fuelStation' | 'poi' | 'wash';
    distance?: number;
    data: PoiModelMap;
    index: number;
    key: string;
    controlOnRoute?: 'edit' | 'preview';
    onClick?: (poi: PoiModelMap) => void;
    onAddToRouteClick?: (data: PoiMarkerData) => void;
}

class PoiCard extends Component<Props> {
    render() {
        const { key, type, data, distance, controlOnRoute, t } = this.props;
        return (
            <div
                key={key}
                onClick={this._onClick}
                id={`${type}-infobox`}
                className={cn('poi-card', {
                    'poi-card-fuel-station': type === 'fuelStation',
                    'poi-card-parking': type === 'parking',
                    'poi-card-wash': type === 'wash',
                    'poi-card-poi': type === 'poi',
                    'poi-card-best-price': data.bestPrice,
                    selected: data.selected
                })}
                data-qa={
                    data.selected
                        ? qa.mapControls.infobox.sectionBoxSelected
                        : data.bestPrice
                        ? qa.mapControls.infobox.sectionBoxBestPrice
                        : qa.mapControls.infobox.sectionBox
                }
            >
                <header>
                    {['fuelStation', 'parking'].includes(type) && (
                        <img
                            alt="logo"
                            className={cn('poi-card-logo-ew', {
                                selected: data.selected
                            })}
                            src={EuroWagLogoBlueSvg}
                        />
                    )}
                    {['wash'].includes(type) && <img alt="logo" className="poi-card-logo-travis" src={TravisLogoSvg} />}

                    {distance && (
                        <Tag className="poi-card-tag-distance">
                            {'+'}
                            <span data-qa={qa.mapControls.infobox.fieldDistance}>
                                {numeral(distance).format('0,0')}
                            </span>{' '}
                            {t('common.km')}
                        </Tag>
                    )}

                    <div className="poi-card-services">
                        {data.fuelTypes?.map(fuelType => (
                            <img
                                className="poi-card-services-icons"
                                key={fuelType.code}
                                alt={fuelType.name}
                                src={
                                    fuelType.name
                                        ? fuelIcons[fuelTypeToFuelIconName(fuelType.name)]
                                        : fuelStationServices.unspecified
                                }
                                title={
                                    fuelType.name
                                        ? t(`FuelTypes.${fuelType.name}`)
                                        : `${t('common.unknown')}: ${fuelType.code}`
                                }
                                width="30"
                                height="30"
                            />
                        ))}
                        {data.services?.map(service => (
                            <img
                                className="poi-card-services-icons"
                                alt={FuelStationServicesTranslations[service.code]}
                                key={service.code}
                                src={
                                    FuelStationServicesTranslations[service.code]
                                        ? fuelStationServices[FuelStationServicesTranslations[service.code]]
                                        : fuelStationServices.unspecified
                                }
                                title={
                                    FuelStationServicesTranslations[service.code]
                                        ? t(`FuelStationServices.${service.name}`)
                                        : `${t('common.unknown')}: ${service.code}`
                                }
                                width="30"
                                height="30"
                            />
                        ))}
                    </div>
                </header>

                <section>
                    {['wash', 'fuelStation', 'parking'].includes(type) && (
                        <>
                            <Row>
                                <Col span={data.bestPrice ? 16 : 24}>
                                    <label>{data.name}</label>
                                </Col>
                                {data.bestPrice && (
                                    <Col span={8}>
                                        <Tag color="green">{t('FuelStationControls.bestPrice')}</Tag>
                                    </Col>
                                )}
                            </Row>
                            <Row>{data.detailAddress}</Row>
                        </>
                    )}

                    {['poi'].includes(type) && (
                        <>
                            <Row>
                                <Col span={6}>
                                    <label>{t('common.name')}</label>
                                </Col>
                                <Col span={18}>{data.name}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <label>{t('common.address')}</label>
                                </Col>
                                <Col span={18}>{data.detailAddress}</Col>
                            </Row>
                        </>
                    )}

                    {type === 'poi' && (
                        <>
                            <Row>
                                <Col span={6}>
                                    <label>{t('PoiForm.lat')}</label>
                                </Col>
                                <Col span={18}>{numeral(data.position.lat).format(GPS_FORMAT)}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <label>{t('PoiForm.lng')}</label>
                                </Col>
                                <Col span={18}>{numeral(data.position.lng).format(GPS_FORMAT)}</Col>
                            </Row>
                            {data.type && (
                                <Row>
                                    <Col span={6}>
                                        <label>{t('PoiForm.type')}</label>
                                    </Col>
                                    <Col span={18}>{t(`PoiForm.${String(data.type)}`)}</Col>
                                </Row>
                            )}
                            {data.notes && (
                                <Row>
                                    <Col span={6}>
                                        <label>{t('PoiForm.notes')}</label>
                                    </Col>
                                    <Col span={18}>{data.notes}</Col>
                                </Row>
                            )}
                            {data.forbidden && (
                                <Row>
                                    <Col span={6}>
                                        <label>{t('PoiForm.forbidden')}</label>
                                    </Col>
                                    <Col span={18}>
                                        <Checkbox disabled={true} checked={data.forbidden} />
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}

                    {type === 'wash' && (
                        <>
                            <Row className="poi-card-opening-hours-title">
                                <label>{t('common.openingHours')}</label>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(1)}</Col>
                                <Col span={20}>{data.openingHours?.monday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(2)}</Col>
                                <Col span={20}>{data.openingHours?.tuesday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(3)}</Col>
                                <Col span={20}>{data.openingHours?.wednesday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(4)}</Col>
                                <Col span={20}>{data.openingHours?.thursday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(5)}</Col>
                                <Col span={20}>{data.openingHours?.friday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(6)}</Col>
                                <Col span={20}>{data.openingHours?.saturday}</Col>
                            </Row>
                            <Row>
                                <Col span={4}>{moment.weekdaysShort(7)}</Col>
                                <Col span={20}>{data.openingHours?.sunday}</Col>
                            </Row>
                        </>
                    )}
                </section>

                <section className="prices">
                    {data.fuelTypes?.map(fuelType => {
                        const price = fuelType?.price?.price ? Number(fuelType.price.price) : 0;
                        const localizedPrice: string = price >= 0.005 ? numeral(price).format('0,0.000') : '';

                        return (
                            <Row
                                key={fuelType.name}
                                className="poi-card-fuel"
                                justify="space-between"
                                data-qa={qa.mapControls.infobox.fuel[FuelTypes[fuelType.code]]}
                            >
                                <Col className="poi-card-fuel-name" data-qa={qa.mapControls.infobox.fieldFuelName}>
                                    {t(`FuelTypes.${FuelTypes[fuelType.code]}`)}
                                </Col>
                                {fuelType.price && localizedPrice && (
                                    <Col
                                        className="poi-card-fuel-price"
                                        data-qa={qa.mapControls.infobox.fieldFuelPrice}
                                    >
                                        {localizedPrice} {fuelType.price!.currency}
                                    </Col>
                                )}
                            </Row>
                        );
                    })}
                </section>

                {controlOnRoute === 'edit' && (
                    <section>
                        {data.inTransport ? (
                            <Tag
                                className="poi-card-tag-in-transport"
                                data-qa={qa.mapControls.infobox.fieldInTransport}
                            >
                                <i className="far fa-check-circle" />
                                <span>{t('PoiInfobox.inTransport')}</span>
                            </Tag>
                        ) : (
                            <Button
                                type="primary"
                                className="poi-card-btn-add"
                                onClick={this._onAddToRouteClick}
                                data-qa={qa.mapControls.infobox.btnAddToRoute}
                            >
                                <i className="fas fa-plus" />
                                <span>{t('PoiInfobox.addToRoute')}</span>
                            </Button>
                        )}
                    </section>
                )}
            </div>
        );
    }

    private _onClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.props.onClick?.(this.props.data);
    };

    private _onAddToRouteClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.props.onAddToRouteClick?.({
            type: this.props.type,
            data: this.props.data
        });
    };
}

export default withTranslation()(PoiCard);
