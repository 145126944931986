import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import { message } from 'antd';
import { debounce } from 'debounce';
import { Subscription } from 'rxjs';
import { observer } from 'mobx-react';

import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { ReadOnlyFuelCardSerializer } from 'generated/new-main';
import { confDefault } from 'conf';
import { Logic } from 'logic/logic';
import { LinksMetadata } from '../../ManagementModule';
import FuelCards from './ui/FuelCards';
import { exponea } from 'logic/exponea';
import { DriverModel } from 'logic/user/users';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { FuelCardFormModel } from 'common/forms/FuelCardsForm';
import { PaginationParams } from 'common/model/pagination';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { pageAfterDeleteChanged } from 'common/utils/tableUtils';

export interface FuelCardFilter {
    textSearch?: string;
    limit: number;
    offset: number;
}

export interface FuelCardModel {
    id: number;
    number: string;
    holder: string;
    fuelCompany: string;
    expirationDate: string;
    monitoredObjId?: number;
    userId?: number;
    links?: FuelCardsLinks;
}

export interface FuelCardsLinks {
    fleet?: LinkFuelCardVehicle[];
    vehicles?: {}[];
    trailers?: {}[];
    user?: LinkFuelCardUser;
}

export interface LinkFuelCardUser {
    id: string;
    name: string;
}
export interface LinkFuelCardVehicle {
    id: string;
    name: string;
    type: string;
    relation: string;
    rn: string;
    metadata?: LinksMetadata;
}

export interface Pairing {
    data?: PairingData;
    loading?: boolean;
    selected?: PairingDataSelected;
    confirm?: PairingDataSelected;
    type?: keyof FuelCardsLinks;
    search?: string;
}

export interface Unpairing {
    model: UnpairType;
    type: keyof FuelCardsLinks;
}

export interface FuelCompany {
    id: string | number;
    name: string;
    label: string;
}

export type PairingData = {
    fleet?: ReadOnlyMonitoredObjectFeSb[];
    user?: DriverModel[];
};

type PairingDataVehicle = {
    type: 'vehicle';
    data: ReadOnlyMonitoredObjectFeSb;
};

type PairingDataUser = {
    type: 'user';
    data: DriverModel;
};

export type PairingDataSelected = PairingDataVehicle | PairingDataUser;

export type UnpairType = ReadOnlyFuelCardSerializer;

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    search?: {
        text: string;
    };
    pairing?: Pairing;
    unpairing?: Unpairing;
    helper?: {
        content: string;
    };
    create: boolean;
    edit: {
        isOpen: boolean;
        isLoading: boolean;
    };
    delete: {
        isOpen: boolean;
        isLoading: boolean;
    };
    removeFuelCardLoading: boolean;
    pairFuelCardLoading: boolean;
    unpairFuelCardLoading: boolean;
}

class FuelCardsModule extends Component<Props, State> {
    fuelCardChanged?: Subscription;
    private logic: Logic;

    constructor(props: Props) {
        super(props);
        this.logic = props.logic;
        this.state = {
            edit: {
                isOpen: false,
                isLoading: false
            },
            delete: {
                isOpen: false,
                isLoading: false
            },
            create: false,
            removeFuelCardLoading: false,
            pairFuelCardLoading: false,
            unpairFuelCardLoading: false
        };
    }

    componentDidMount() {
        (window as any).app.modul = this;
        this.logic.fuelCard().init();
        this.fuelCardChanged = this.logic.managementFuelCard().onFuelCardChanged.subscribe(() => {
            this.logic.fuelCard().reloadFuelCards();
        });
    }

    componentWillUnmount() {
        this.fuelCardChanged?.unsubscribe();

        this.logic.fuelCard().fuelCards = {
            data: [],
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET,
            total: 0
        };

        this.logic.fuelCard().setFuelCardFilter({
            textSearch: undefined,
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        });
    }

    render() {
        return (
            <FuelCards
                create={this.state.create}
                edit={this.state.edit.isOpen}
                delete={this.state.delete}
                roles={this.logic.auth().roles()}
                loading={this.logic.fuelCard().fuelCardLoading}
                fuelCards={this.logic.fuelCard().fuelCards}
                fuelCompanies={this.logic.fuelCard().fuelCompanies}
                drivers={this.logic.fuelCard().drivers}
                monitoredObjects={this.logic.fuelCard().monitoredObjects}
                selected={this.logic.fuelCard().fuelCardDetail}
                pairing={this.state.pairing}
                search={this.state.search}
                unpairing={this.state.unpairing}
                helper={this.state.helper}
                lang={this.logic.auth().user().lang}
                demoMode={this.logic.demo().isActive}
                removeFuelCardLoading={this.state.removeFuelCardLoading}
                pairFuelCardLoading={this.state.pairFuelCardLoading}
                unpairFuelCardLoading={this.state.unpairFuelCardLoading}
                onBarSearchChange={this._onBarSearchChange}
                onBarHelperClick={this._onBarHelperClick}
                onBarCreateClick={this._onBarCreateClick}
                onCreateSubmitClick={this._onCreateNewSubmitClick}
                onCreateCancelClick={this._onCreateCancelClick}
                onDetailFormCancelClick={this._onDetailFormCancelClick}
                onDetailFormSubmitClick={this._onDetailFormSubmitClick}
                onDetailUnpairClick={this._onDetailUnpairClick}
                onPairingCancelClick={this._onPairingCancelClick}
                onPairingConfirmClick={this._onPairingConfirmClick}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearchChange={this._onPairingSearchChange}
                onPairingTypeChange={this._onPairingTypeChange}
                onConfirmPairingSubmitClick={this._onConfirmPairingSubmitClick}
                onConfirmDeleteCancelClick={this._onConfirmDeleteCancelClick}
                onConfirmDeteleSubmitClick={this._onConfirmDeleteSubmitClick}
                onConfirmUnpairCancelClick={this._onConfirmUnpairCancelClick}
                onConfirmUnpairSubmitClick={this._onConfirmUnpairSubmitClick}
                onTableRowSelect={this._onTableRowSelect}
                onTablePaginationChange={this._onTablePaginationChange}
                onActionsUpdateClick={this._onActionsUpdateClick}
                onActionsPairingClick={this._onActionsPairingClick}
                onActionsDeleteClick={this._onActionsDeleteClick}
                onHelperClose={this._onHelperClose}
            />
        );
    }

    private _onBarSearchChange = debounce((searchText: string): void => {
        this.logic.exponea().trackEvent(exponea.module.settingsFuelCards, {
            status: exponea.status.actionTaken,
            action: exponea.action.search
        });

        this.setState(
            {
                search: {
                    text: searchText
                }
            },
            () => {
                this._filterChange();
            }
        );
    }, 500);

    private _onDetailUnpairClick = (model: UnpairType, type: keyof FuelCardsLinks): void => {
        this.setState({ unpairing: { model, type } });
    };

    private _onConfirmUnpairCancelClick = (): void => {
        this.setState({ unpairing: undefined });
    };

    private _onConfirmUnpairSubmitClick = async (): Promise<void> => {
        this.setState({
            unpairFuelCardLoading: true
        });

        if (this.logic.fuelCard().fuelCardDetail?.id && this.state.unpairing?.type === 'user') {
            try {
                const updated = await this.logic.managementFuelCard().unpairUser(this.logic.fuelCard().fuelCardDetail!);
                if (updated) {
                    message.success(this.props.t('ManagementFuelCards.message.unpairSuccess'));
                } else {
                    message.error(this.props.t('ManagementFuelCards.message.unpairError'));
                }
            } catch (err) {
                console.error(`Unpair fuel card failed, err: ${err}`);
                message.error(this.props.t('ManagementFuelCards.message.unpairError'));
            }
        }

        if (this.logic.fuelCard().fuelCardDetail?.id && this.state.unpairing?.type === 'fleet') {
            try {
                const updated = await this.logic
                    .managementFuelCard()
                    .unpairMonitoredObject(this.logic.fuelCard().fuelCardDetail!);
                if (updated) {
                    message.success(this.props.t('ManagementFuelCards.message.unpairSuccess'));
                } else {
                    message.error(this.props.t('ManagementFuelCards.message.unpairError'));
                }
            } catch (err) {
                console.error(`Unpair fuel card failed, err: ${err}`);
                message.error(this.props.t('ManagementFuelCards.message.unpairError'));
            }
        }

        this.setState({
            unpairing: undefined,
            unpairFuelCardLoading: false
        });
    };

    private _onPairingConfirmClick = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                confirm: state.pairing?.selected
            }
        }));
    };

    private _onConfirmPairingSubmitClick = async (): Promise<void> => {
        this.setState({
            pairFuelCardLoading: true
        });

        if (this.state.pairing?.selected && this.logic.fuelCard().fuelCardDetail) {
            let updated = false;
            try {
                if (this.state.pairing?.selected?.type === 'vehicle') {
                    const fleetModelToPair = this.state.pairing.selected.data;
                    if (this.logic.fuelCard().fuelCardDetail?.id) {
                        updated = await this.logic
                            .managementFuelCard()
                            .updateFuelCard(this.logic.fuelCard().fuelCardDetail?.id!, {
                                ...this.logic.fuelCard().fuelCardDetail!,
                                monitoredObject: fleetModelToPair?.id
                            });
                    }
                } else if (this.state.pairing?.selected?.type === 'user') {
                    const userModelToPair = this.state.pairing.selected.data;

                    if (this.logic.fuelCard().fuelCardDetail?.id) {
                        updated = await this.logic
                            .managementFuelCard()
                            .updateFuelCard(this.logic.fuelCard().fuelCardDetail?.id!, {
                                ...this.logic.fuelCard().fuelCardDetail!,
                                user: Number(userModelToPair?.id)
                            });
                    }
                }
                if (updated) {
                    this.setState({
                        pairing: undefined,
                        pairFuelCardLoading: false
                    });
                    message.success(this.props.t('ManagementFuelCards.message.pairSuccess'));
                } else {
                    message.error(this.props.t('ManagementFuelCards.message.pairError'));
                }
            } catch (err) {
                console.error(`Pair card failed, err: ${err}`);
                message.error(this.props.t('ManagementFuelCards.message.pairError'));
                this.setState({
                    pairFuelCardLoading: false
                });
            }
        }
    };

    private _onActionsDeleteClick = (): void => {
        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: true
            }
        }));
    };

    private _onConfirmDeleteCancelClick = (): void => {
        this.setState(state => ({
            delete: {
                ...state.delete,
                isOpen: false
            }
        }));
    };

    private _onConfirmDeleteSubmitClick = async (): Promise<void> => {
        try {
            const res = await this.logic.managementFuelCard().deleteFuelCard(this.logic.fuelCard().fuelCardDetail?.id);
            if (res) {
                message.success(this.props.t('ManagementFuelCards.message.removeSuccess'));

                if (
                    pageAfterDeleteChanged(this.logic.fuelCard().fuelCards.total, this.logic.fuelCard().fuelCards.limit)
                ) {
                    this._filterChange({
                        limit: this.logic.fuelCard().fuelCardFilter.limit,
                        offset: this.logic.fuelCard().fuelCardFilter.offset - this.logic.fuelCard().fuelCardFilter.limit
                    });
                }
                this.logic.fuelCard().reloadFuelCards();
            } else {
                message.error(this.props.t('ManagementFuelCards.message.removeError'));
            }
        } catch (err) {
            console.error(`Delete maintenance error, err: ${err}`);
            message.error(this.props.t('Maintenance.message.deleteError'));
        } finally {
            this.setState(state => ({
                delete: {
                    ...state.delete,
                    isOpen: false
                }
            }));
        }
    };

    private _onPairingSearchChange = debounce((text: string): void => {
        this.setState(state => ({
            pairing: { ...state.pairing, search: text }
        }));
    }, 500);

    private _onPairingItemSelect = (selected: PairingDataSelected): void => {
        this.setState(state => ({
            pairing: { ...state.pairing, selected }
        }));
    };

    private _onPairingCancelClick = (): void => {
        this.setState({ pairing: undefined });
    };

    private _onPairingTypeChange = (type: keyof FuelCardsLinks): void => {
        if (this.state.pairing?.type === type) {
            return;
        }
        this.setState({ pairing: { type, loading: true } }, () => {
            if (type === 'vehicles') {
                this.logic
                    .vehicles()
                    .getMonitoredObjectFilters()
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                loading: false,
                                data: {
                                    ...state.pairing?.data,
                                    fleet: data
                                }
                            }
                        }));
                    })
                    .catch(err => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                loading: false
                            }
                        }));
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                    });
            } else if (type === 'user') {
                this.logic
                    .users()
                    .drivers()
                    .then(drivers => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                loading: false,
                                data: {
                                    ...state.pairing?.data,
                                    user: drivers
                                }
                            }
                        }));
                    })
                    .catch(err => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                loading: false
                            }
                        }));
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                    });
            }
        });
    };

    private _onActionsPairingClick = (): void => {
        this.setState({ pairing: { type: undefined } });
    };

    private _onCreateNewSubmitClick = async (model: FuelCardFormModel): Promise<boolean> => {
        this.logic.exponea().trackEvent(exponea.module.settingsFuelCards, {
            status: exponea.status.actionTaken,
            action: exponea.action.createFuelCard
        });

        try {
            await this.logic.managementFuelCard().createFuelCard(model);

            message.success(this.props.t('ManagementFuelCards.message.createSuccess'));
            this.setState({
                create: false
            });
            return true;
        } catch (err) {
            console.error(`Create fuel card error, err: ${err}`);
            message.error(this.props.t('ManagementFuelCards.message.createError'));
        }

        return false;
    };

    private _onDetailFormSubmitClick = async (model: FuelCardFormModel): Promise<boolean> => {
        try {
            await this.logic.managementFuelCard().updateFuelCard(this.logic.fuelCard().fuelCardDetail?.id!, {
                ...model,
                fuelCompany: Number(model.fuelCompany),
                expirationDate: moment(model.expirationDate).toDate(),
                note: model.note
            });

            message.success(this.props.t('ManagementFuelCards.message.updateSuccess'));
            this.setState({
                edit: {
                    isLoading: false,
                    isOpen: false
                }
            });
            return true;
        } catch (err) {
            this.setState(state => ({
                edit: {
                    ...state.edit,
                    isLoading: false
                }
            }));
            console.error(`Update fuel card error, err: ${err}`);
            message.error(this.props.t('ManagementFuelCards.message.updateError'));
        }

        return false;
    };

    private _onDetailFormCancelClick = (): void => {
        this.setState({
            edit: {
                isOpen: false,
                isLoading: false
            }
        });
    };

    private _onBarCreateClick = (): void => {
        this.setState({
            create: true
        });
    };

    private _onCreateCancelClick = (): void => {
        this.setState({
            create: false
        });
    };

    private _onTableRowSelect = (id: number): void => {
        this.logic.fuelCard().setFuelCardDetail(id);
    };

    private _filterChange = (pagination?: PaginationParams) => {
        this.logic.fuelCard().setFuelCardDetail();
        this.props.history.push({
            search: ''
        });

        this.logic.fuelCard().setFuelCardFilter({
            textSearch: this.state.search?.text,
            limit: pagination?.limit ?? DEFAULT_PAGE_LIMIT,
            offset: pagination?.offset ?? DEFAULT_PAGE_OFFSET
        });
        this.logic.fuelCard().reloadFuelCards();
    };

    private _onTablePaginationChange = (pagination: PaginationParams): void => {
        this._filterChange(pagination);
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onActionsUpdateClick = () => {
        this.setState(state => ({
            edit: {
                ...state.edit,
                isOpen: true
            }
        }));
    };
}

export default withTranslation()(withRouter(observer(FuelCardsModule)));
