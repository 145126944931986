import React, { ReactNode } from 'react';
import { Slider } from 'antd';
import { Control } from '../Control';
import { SliderSingleProps } from 'antd/lib/slider';

interface Props extends SliderSingleProps {
    label?: ReactNode;
    error?: ReactNode;
    qa?: string;
}

function EnhancedSlider({ label, error, qa, ...props }: Props) {
    return (
        <Control label={label} error={error}>
            <Slider data-qa={qa} {...props} />
        </Control>
    );
}
export default EnhancedSlider;
