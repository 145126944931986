import { WithTranslation, withTranslation } from 'react-i18next';
import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import cn from 'classnames';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';
import { Pagination, Table } from 'common/components';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import qa from 'qa-selectors';
import { getTableCurrentPage } from 'common/utils/tableUtils';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';

interface Props extends WithTranslation {
    loading?: boolean;
    selected?: ReadOnlyFuelCardSerializer;
    fuelCards?: PaginatedResponse<ReadOnlyFuelCardSerializer[]>;
    fuelCompanies: ReadOnlyFuelCompanySerializer[];
    onPaginationChange?: (pagination: PaginationParams) => void;
    onRowSelect?: (id: number) => void;
}

function FuelCardsTable(props: Props) {
    const { t } = props;

    function createColumns(): ColumnsType<ReadOnlyFuelCardSerializer> {
        return [
            {
                title: t('ManagementFuelCards.number'),
                dataIndex: 'number',
                key: 'number',
                width: 100,
                render: value => <span data-qa={qa.fuel.table.fieldNumber}>{value}</span>
            },
            {
                title: t('ManagementFuelCards.holderName'),
                dataIndex: 'holder',
                key: 'holder',
                width: 100,
                render: value => <span data-qa={qa.fuel.table.fieldHolder}>{value}</span>
            },
            {
                title: t('ManagementFuelCards.fuelCompany'),
                dataIndex: 'fuelCompany',
                key: 'fuelCompany',
                align: 'center',
                width: 100,
                render: value => (
                    <span data-qa={qa.fuel.table.fieldCompany}>
                        {props.fuelCompanies.find(fc => fc.id === value)?.label}
                    </span>
                )
            },
            {
                title: t('ManagementFuelCards.expirationDate'),
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                align: 'center',
                width: 100,
                render: value => (
                    <span data-qa={qa.fuel.table.fieldExpDate}>{value ? moment(value).format('L') : ''}</span>
                )
            }
        ];
    }

    return (
        <>
            <Table<ReadOnlyFuelCardSerializer>
                loading={props.loading}
                dataSource={props.fuelCards?.data.map(d => ({ ...d, key: d.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => record.id && props.onRowSelect?.(record.id),
                    className: cn({
                        'ant-table-row-selected': props.selected?.id === record.id
                    })
                })}
            />

            {!props.loading &&
            props.fuelCards?.total &&
            props.fuelCards?.total > 0 &&
            props.fuelCards?.total > props.fuelCards?.limit &&
            props.fuelCards?.offset !== undefined &&
            props.fuelCards?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={props.fuelCards.total}
                        pageSize={props.fuelCards.limit}
                        current={getTableCurrentPage(props.fuelCards.offset, props.fuelCards.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </>
    );
}

export default withTranslation()(FuelCardsTable);
