import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import { Role } from 'logic/auth';
import { Logic } from 'logic/logic';
import { rbac, RouteNames } from 'App';
import ManagementSideBar from './ui/ManagementSideBar';

import DriversCardsRemoteMemoryModule from './modules/driver-cards-remote-memory/DriversCardsRemoteMemoryModule';
import VehiclesRemoteMemoryModule from './modules/vehicles-remote-memory/VehiclesRemoteMemoryModule';
import PoiModule from './modules/custom-places/CustomPlacesModule';
import ManagementFleetModule from './modules/fleet/FleetModule';
import FuelCardsModule from './modules/fuel-cards/FuelCardsModule';
import TachoCardsModule from './modules/tachograph-cards/TachoCardsModule';
import ManagementUserModule from './modules/management-users/UsersModule';
import RolesModule from './modules/roles/RolesModule';
import SystemConnectionsModule from './modules/system-connections/SystemConnectionsModule';
import ContactListModule from './modules/contact-list/ContactListModule';
import ForbiddenPage from 'common/components/ForbiddenPage';
import cs from 'classnames';

export interface LinksMetadata {
    dateOfPairing?: string;
    dateOfLastUnpairing?: string;
    locationOfLastUnpairing?: string;
    locationWhenPaired?: string;
}

interface Props extends RouteComponentProps {
    logic: Logic;
    embedded: boolean;
}

function ManagementModule(props: Props) {
    const roles = props.logic.auth().roles();
    return (
        <div className={cs('management module', { embedded: props.embedded })}>
            {!props.embedded && <ManagementSideBar roles={roles} />}
            <Switch>
                <Route
                    exact
                    path={RouteNames.SETTINGS_FLEET}
                    render={rbac(roles, [Role.V_R], <ManagementFleetModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_USERS}
                    render={rbac(roles, [Role.USR_R], <ManagementUserModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_ROLES}
                    render={rbac(roles, [Role.USR_W], <RolesModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_FUEL_CARDS}
                    render={rbac(roles, [Role.EX_R], <FuelCardsModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_TACHO_CARDS}
                    render={rbac(roles, [Role.TC_R], <TachoCardsModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_OBU}
                    render={rbac(
                        roles,
                        [Role.V_R],
                        <div className="management-obu t-block" style={{ background: '#fff' }} />
                    )}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_DEVICES}
                    render={rbac(
                        roles,
                        [Role.V_R],
                        <div className="management-devices t-block" style={{ background: '#fff' }} />
                    )}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_COMPANY}
                    render={rbac(
                        roles,
                        [Role.V_R],
                        <div className="management-company t-block" style={{ background: '#fff' }} />
                    )}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY}
                    render={rbac(roles, [Role.CRD_R], <DriversCardsRemoteMemoryModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY}
                    render={rbac(roles, [Role.TRD_R], <VehiclesRemoteMemoryModule logic={props.logic} />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_CUSTOM_PLACES}
                    render={rbac(roles, [Role.POI_R], <PoiModule logic={props.logic} />)}
                />
                <Route
                    path={RouteNames.SETTINGS_SYSTEM_CONNECTIONS}
                    render={rbac(
                        roles,
                        [Role.CA_R, Role.CAC_R, Role.OAC_R, Role.PUESC],
                        <SystemConnectionsModule logic={props.logic} />
                    )}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_CONTACT_LIST}
                    render={rbac(roles, [Role.CL_R], <ContactListModule />)}
                />
                <Route path="*" render={() => <ForbiddenPage type="404" availableRoles={roles} />} />
            </Switch>
        </div>
    );
}

export default withRouter(ManagementModule);
