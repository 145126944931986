/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelMessages,
    ChannelMessagesFromJSON,
    ChannelMessagesFromJSONTyped,
    ChannelMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMessagesResponse
 */
export interface UserMessagesResponse {
    /**
     * 
     * @type {Array<ChannelMessages>}
     * @memberof UserMessagesResponse
     */
    channelMessages: Array<ChannelMessages>;
    /**
     * 
     * @type {string}
     * @memberof UserMessagesResponse
     */
    user: string;
    /**
     * 
     * @type {Date}
     * @memberof UserMessagesResponse
     */
    from: Date;
    /**
     * 
     * @type {number}
     * @memberof UserMessagesResponse
     */
    limit: number;
}

export function UserMessagesResponseFromJSON(json: any): UserMessagesResponse {
    return UserMessagesResponseFromJSONTyped(json, false);
}

export function UserMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMessagesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelMessages': ((json['channelMessages'] as Array<any>).map(ChannelMessagesFromJSON)),
        'user': json['user'],
        'from': (new Date(json['from'])),
        'limit': json['limit'],
    };
}

export function UserMessagesResponseToJSON(value?: UserMessagesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelMessages': ((value.channelMessages as Array<any>).map(ChannelMessagesToJSON)),
        'user': value.user,
        'from': (value.from.toISOString()),
        'limit': value.limit,
    };
}


