import { deg, M, T } from 'domain-constants';
import { useTranslation } from 'react-i18next';
import { FleetModel } from '../../FleetModule';
import numeral from 'numeral';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import qa from 'qa-selectors';
import { Tag, Tooltip } from 'common/components';
import { thermostat } from 'resources/images/common';
import React from 'react';
import { WithLogic, withLogicContext } from 'App';
import { Role } from 'logic/auth';

interface Props extends WithLogic {
    data: FleetModel;
}

function FleetTrailerDetailCard(props: Props) {
    const { t } = useTranslation();
    const zones = [...new Set(props.data.temperatureSensors?.map(d => d.sensorZone).filter(Number))] as number[];

    return (
        <div className="trailer-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.registrationNumber')}>
                            <span data-qa={qa.fleet.detail.fieldRN}>{props.data.registrationNumber}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.ownName')}>
                            <span data-qa={qa.fleet.detail.fieldName}>{props.data.name}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.vehicleGroups')}>
                            {props.data.monitoredObjectGroups?.map((group, i) => (
                                <Tag key={`tag-${i}`} qa={qa.fleet.detail.tag}>
                                    {group.name}
                                </Tag>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFleet.trailerType')}>
                            <span data-qa={qa.fleet.detail.fieldTrailerType}>
                                {props.data.trailerType && t(`TrailerTypes.${props.data.trailerType}`)}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFleet.countryOfRegistration')}>
                            <span data-qa={qa.fleet.detail.fieldCountryOfRegistration}>
                                {props.data.countryOfRegistration}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ManagementFleet.typeOfLoad')}>
                            <span data-qa={qa.fleet.detail.fieldTypeOfLoad}>{props.data.cargoType}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.type')}>
                            <span data-qa={qa.fleet.detail.fieldType}>{t(`Partner.${props.data.type}`)}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFleet.serialNumberTrailer')}>
                            <span data-qa={qa.fleet.detail.fieldTrailerIdSerialNumber}>
                                {props.data.trailerIdSerialNumber}
                            </span>
                        </Descriptions.Item>
                        <>
                            {props.data.serialNumber && (
                                <Descriptions.Item label={t('ManagementFleet.unitSerialNumber')}>
                                    <span data-qa={qa.fleet.detail.fieldUnitSerialNumber}>
                                        {props.data.serialNumber}
                                    </span>
                                </Descriptions.Item>
                            )}
                        </>
                        <Descriptions.Item label={t('common.manufacturer')}>
                            <span data-qa={qa.fleet.detail.fieldManufacturer}>{props.data.manufacturer}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                hasSecondDivider={false}
                secondLeft={
                    props.logic.auth().roles().includes(Role.CLD_R) &&
                    !!props.data.temperatureSensors?.length && (
                        <Descriptions layout="vertical" column={2}>
                            <Descriptions.Item label={t('ManagementFleet.unitsAndSensors')}>
                                {zones
                                    .sort((a, b) => a - b)
                                    .map(zone => (
                                        <React.Fragment key={zone}>
                                            <div className="temperature-sensor-zone-label">
                                                {t('Coldchain.zone')} {zone}
                                            </div>
                                            {props.data.temperatureSensors
                                                ?.filter(sensor => sensor.sensorZone === zone)
                                                .map((sensor, i) => (
                                                    <Tooltip
                                                        key={i}
                                                        title={`${t('ManagementFleet.sn')}: ${sensor.serialNumber}`}
                                                        placement="top"
                                                        mouseLeaveDelay={0}
                                                    >
                                                        <Tag className="temperature-sensor">
                                                            <img
                                                                src={thermostat}
                                                                alt="temperature-sensor"
                                                                height="16px"
                                                                width="16px"
                                                            />
                                                            {sensor.temperatureValue !== undefined && (
                                                                <span className="temperature-sensor-value">
                                                                    {sensor.temperatureValue &&
                                                                        numeral(sensor.temperatureValue).format('0,0')}
                                                                    {deg}
                                                                </span>
                                                            )}
                                                            <span className="temperature-sensor-name">
                                                                {sensor.sensorName}
                                                            </span>
                                                        </Tag>
                                                    </Tooltip>
                                                ))}
                                        </React.Fragment>
                                    ))}
                            </Descriptions.Item>
                        </Descriptions>
                    )
                }
                thirdLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.weight')}>
                            {props.data.weight && (
                                <>
                                    <span data-qa={qa.fleet.detail.fieldWeight}>
                                        {numeral(props.data.weight).format('0,0.00')}
                                    </span>{' '}
                                    {T}
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementFleet.fullLoadWeight')}>
                            {props.data.weightFull && (
                                <>
                                    <span data-qa={qa.fleet.detail.fieldFullWeight}>
                                        {numeral(props.data.weightFull).format('0,0.00')}
                                    </span>{' '}
                                    {T}
                                </>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                }
                thirdRight={
                    <Descriptions layout="vertical" column={2}>
                        <Descriptions.Item label={t('common.length')}>
                            {props.data.length && (
                                <>
                                    <span data-qa={qa.fleet.detail.fieldLength}>
                                        {numeral(props.data.length).format('0,0.00')}
                                    </span>{' '}
                                    {M}
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.width')}>
                            {props.data.width && (
                                <>
                                    <span data-qa={qa.fleet.detail.fieldWidth}>
                                        {numeral(props.data.width).format('0,0.00')}
                                    </span>{' '}
                                    {M}
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.height')}>
                            {props.data.height && (
                                <>
                                    <span data-qa={qa.fleet.detail.fieldHeight}>
                                        {numeral(props.data.height).format('0,0.00')}
                                    </span>{' '}
                                    {M}
                                </>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
export default withLogicContext(FleetTrailerDetailCard);
