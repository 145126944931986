import { Button } from 'common/components';
import { ReadOnlyUserRole } from 'generated/new-main/models';

import * as React from 'react';
import UserRoleForm, { UserRoleFormModel } from 'common/forms/UserRoleForm';
import { RadioGroup } from 'common/components/RadioGroup';
import { WithTranslation, withTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { getRoleName } from 'common/utils/components/RoleName';
import { Col, Row } from 'antd';

interface State {
    editRoleActive?: boolean;
}

interface Props extends WithTranslation {
    userRoles: ReadOnlyUserRole[];
    selectedRoleId: string;
    roleEditable?: boolean;
    userRoleFormInitValue?: UserRoleFormModel;
    demoMode?: boolean;
    onRoleChange?: (value?: string) => void;
    onDeleteRole?: (id: string) => void;
    onSubmitRoleForm: (roleData: UserRoleFormModel) => Promise<boolean>;
    onEditRoleFormVisibleChange?: (visible: boolean) => void;
}

class UserRoles extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            editRoleActive: false
        };
    }
    render() {
        return (
            <div className="roles-management">
                {this.state.editRoleActive ? (
                    <UserRoleForm
                        initialValues={this.props.selectedRoleId ? this.props.userRoleFormInitValue : undefined}
                        onSubmit={this._onSubmitRoleForm}
                        onCancel={this._onCancelNewRole}
                    />
                ) : (
                    <Row gutter={12}>
                        <Col>
                            <Button
                                type="dashed"
                                onClick={this._onCreateNewRoleClick}
                                qa={qa.roles.detail.btnCreate}
                                disabled={this.props.demoMode}
                            >
                                {this.props.t('ManagementRoles.createNew')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="default"
                                disabled={
                                    this.props.demoMode ||
                                    !this.props.roleEditable ||
                                    !this.props.userRoles.find(r => r.id === this.props.selectedRoleId)?.client
                                }
                                onClick={this._onEditRoleClick}
                                qa={qa.roles.detail.btnUpdate}
                            >
                                {this.props.t('common.edit')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                danger
                                disabled={
                                    this.props.demoMode ||
                                    !this.props.roleEditable ||
                                    !this.props.userRoles.find(r => r.id === this.props.selectedRoleId)?.client
                                }
                                onClick={this._onDeleteRoleClick}
                                qa={qa.roles.detail.btnDelete}
                            >
                                {this.props.t('common.delete')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <div className="roles-radio">
                    <RadioGroup
                        name={''}
                        checked={this.props.selectedRoleId ?? ''}
                        type={'radio'}
                        onChange={this._onRoleChange}
                        options={this.props.userRoles
                            .filter(ur => ur.name !== 'Guest')
                            .map(r => ({
                                code: r.id ?? '',
                                label: getRoleName(r)
                            }))}
                    />
                </div>
            </div>
        );
    }

    private _onDeleteRoleClick = () => {
        if (this.props.selectedRoleId) this.props.onDeleteRole?.(this.props.selectedRoleId);
    };

    private _onEditRoleClick = () => {
        this.setState({
            editRoleActive: true
        });
        this.props.onEditRoleFormVisibleChange?.(true);
    };
    private _onSubmitRoleForm = async (roleData: UserRoleFormModel) => {
        const result = await this.props.onSubmitRoleForm(roleData);
        if (result) {
            this.setState({
                editRoleActive: false
            });
        }
        return result;
    };
    private _onCancelNewRole = () => {
        this.setState({
            editRoleActive: false
        });
        this.props.onEditRoleFormVisibleChange?.(false);
    };

    private _onCreateNewRoleClick = () => {
        this.props.onRoleChange?.();
        this.props.onEditRoleFormVisibleChange?.(true);
        this.setState({
            editRoleActive: true
        });
    };

    private _onRoleChange = (roleId?: string) => {
        this.setState(
            {
                editRoleActive: false
            },
            () => {
                this.props.onRoleChange?.(roleId);
                this.props.onEditRoleFormVisibleChange?.(false);
            }
        );
    };
}

export default withTranslation()(UserRoles);
