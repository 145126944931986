/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserTokenCreate,
    UserTokenCreateFromJSON,
    UserTokenCreateFromJSONTyped,
    UserTokenCreateToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserTokenPairUser
 */
export interface UserTokenPairUser {
    /**
     * 
     * @type {UserTokenCreate}
     * @memberof UserTokenPairUser
     */
    userToken: UserTokenCreate;
    /**
     * 
     * @type {User}
     * @memberof UserTokenPairUser
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof UserTokenPairUser
     */
    userId?: number;
}

export function UserTokenPairUserFromJSON(json: any): UserTokenPairUser {
    return UserTokenPairUserFromJSONTyped(json, false);
}

export function UserTokenPairUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTokenPairUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userToken': UserTokenCreateFromJSON(json['user_token']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function UserTokenPairUserToJSON(value?: UserTokenPairUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_token': UserTokenCreateToJSON(value.userToken),
        'user': UserToJSON(value.user),
        'user_id': value.userId,
    };
}


