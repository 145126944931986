/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JourneyStatisticsResponseStats
 */
export interface JourneyStatisticsResponseStats {
    /**
     * 
     * @type {Date}
     * @memberof JourneyStatisticsResponseStats
     */
    timestampFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof JourneyStatisticsResponseStats
     */
    timestampTo: Date;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    activityTimeSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    distanceKilometers: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    idleTimeSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    stoppedTimeSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    maxSpeedKmph: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    averageSpeedKmph: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    consumptionLiters: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    consumptionIdleLiters: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    consumptionLitersPer100km: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    consumptionIdleLitersPer1h: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    co2Emission?: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    ntrips: number;
    /**
     * 
     * @type {string}
     * @memberof JourneyStatisticsResponseStats
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    odometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof JourneyStatisticsResponseStats
     */
    odometerEnd?: number;
    /**
     * 
     * @type {Date}
     * @memberof JourneyStatisticsResponseStats
     */
    activityStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JourneyStatisticsResponseStats
     */
    activityEnd?: Date;
}

export function JourneyStatisticsResponseStatsFromJSON(json: any): JourneyStatisticsResponseStats {
    return JourneyStatisticsResponseStatsFromJSONTyped(json, false);
}

export function JourneyStatisticsResponseStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JourneyStatisticsResponseStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestampFrom': (new Date(json['timestamp_from'])),
        'timestampTo': (new Date(json['timestamp_to'])),
        'activityTimeSeconds': json['activity_time_seconds'],
        'distanceKilometers': json['distance_kilometers'],
        'idleTimeSeconds': json['idle_time_seconds'],
        'stoppedTimeSeconds': json['stopped_time_seconds'],
        'maxSpeedKmph': json['max_speed_kmph'],
        'averageSpeedKmph': json['average_speed_kmph'],
        'consumptionLiters': json['consumption_liters'],
        'consumptionIdleLiters': json['consumption_idle_liters'],
        'consumptionLitersPer100km': json['consumption_liters_per_100km'],
        'consumptionIdleLitersPer1h': json['consumption_idle_liters_per_1h'],
        'co2Emission': !exists(json, 'co2_emission') ? undefined : json['co2_emission'],
        'ntrips': json['ntrips'],
        'monitoredObjectId': !exists(json, 'monitored_object_id') ? undefined : json['monitored_object_id'],
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'odometerStart': !exists(json, 'odometer_start') ? undefined : json['odometer_start'],
        'odometerEnd': !exists(json, 'odometer_end') ? undefined : json['odometer_end'],
        'activityStart': !exists(json, 'activity_start') ? undefined : (new Date(json['activity_start'])),
        'activityEnd': !exists(json, 'activity_end') ? undefined : (new Date(json['activity_end'])),
    };
}

export function JourneyStatisticsResponseStatsToJSON(value?: JourneyStatisticsResponseStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp_from': (value.timestampFrom.toISOString()),
        'timestamp_to': (value.timestampTo.toISOString()),
        'activity_time_seconds': value.activityTimeSeconds,
        'distance_kilometers': value.distanceKilometers,
        'idle_time_seconds': value.idleTimeSeconds,
        'stopped_time_seconds': value.stoppedTimeSeconds,
        'max_speed_kmph': value.maxSpeedKmph,
        'average_speed_kmph': value.averageSpeedKmph,
        'consumption_liters': value.consumptionLiters,
        'consumption_idle_liters': value.consumptionIdleLiters,
        'consumption_liters_per_100km': value.consumptionLitersPer100km,
        'consumption_idle_liters_per_1h': value.consumptionIdleLitersPer1h,
        'co2_emission': value.co2Emission,
        'ntrips': value.ntrips,
        'monitored_object_id': value.monitoredObjectId,
        'driver_id': value.driverId,
        'odometer_start': value.odometerStart,
        'odometer_end': value.odometerEnd,
        'activity_start': value.activityStart === undefined ? undefined : (value.activityStart.toISOString()),
        'activity_end': value.activityEnd === undefined ? undefined : (value.activityEnd.toISOString()),
    };
}


