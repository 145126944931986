import qs from 'qs';
import { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { SortEnd, SortEvent } from 'react-sortable-hoc';
import { Col, message, Row } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Subscription } from 'rxjs';
import { RouteNames } from 'App';
import { Button, Loading, Modal } from 'common/components';
import VehicleProfileForm from 'common/forms/VehicleProfileForm';
import { AvailableCurrencies } from 'common/model/currency';
import { Logic } from 'logic/logic';
import { FixedCost, OperationalCost } from 'logic/statistics/statistics-company-profile';
import RouteConfigurator, { RouteConfiguration } from './ui/RouteConfigurator';
import { exponea } from 'logic/exponea';
import { VehicleProfileModel } from 'common/model/transports';
import { TransportEventRule, TransportPlaceTask } from 'generated/backend-api';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import { observer } from 'mobx-react';
import { ExternalSystemSecretMetadata } from 'generated/new-main';
import { RouteSelectData } from '../places-autocomplete/PlacesAutocompleteModule';
import ClientForm from 'common/forms/ClientForm';
import PlannerBar from './ui/PlannerBar';
import PlacesConfigurator from './ui/PlacesConfigurator';
import PlannerAutocomplete from './ui/PlannerAutocomplete';
import PlaceSettings from './ui/PlaceSettings';
import qa from 'qa-selectors';
import { PlanRouteErrorName, TransportType } from '../planner-new/planner-logic';
import { ContactList } from 'common/model/client-contact';
import ModalForm from 'common/components/ModalForm';
import { defaultVehicleProfileName } from '../planner-new/ui/RouteParamsConfigurator';
import * as modalIcons from 'resources/images/modal';
import { VehicleStateObject } from 'generated/graphql';
import { InaccessiblePointError, InaccessiblePointTypeEnum } from 'generated/routing-api';

export interface PuescModalModel {
    open: boolean;
    visited: boolean;
    dataLoading: boolean;
    data?: PuescConnection;
}

export interface PuescConnection {
    connected?: boolean;
    vehicles?: number[];
    metadata?: ExternalSystemSecretMetadata;
}

type RouteParams = {
    editId?: string;
    vehicleId?: string;
    startDate?: string;
    endDate?: string;
    dispatcherBoard?: string;
};

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

interface State {
    createClientProfileOpen: boolean;
    addPlaceTaskOpen: boolean;
    addVehicleProfileOpen: boolean;
    PuescGoodsVerificationOpen: boolean;
    loading: boolean;
    backUrl?: string;
    manualAta?: {
        dateTime: string;
        placeId: string;
    };
    helper?: {
        content: string;
    };
    retriedWaypoints: string[];
    puescModal: PuescModalModel;

    pricePerKm?: number;
    currency?: AvailableCurrencies;
    costPerKm?: { cost: number; currency: AvailableCurrencies };
    costs?: {
        fixed?: FixedCost;
        operational?: {
            monitoredObjectId?: number;
            operationalCost?: OperationalCost;
        }[];
    };
    routingAlert?: boolean;
    vehicleStates?: VehicleStateObject[];
}

export enum Calculation {
    VEHICLE_PROFILE = 'vehicleProfile',
    VEHICLE = 'vehicle',
    WITHOUT_RESTRICTIONS = 'withoutRestrictions'
}

class RoutePlanningScheduling extends Component<Props, State> {
    private _logic: Logic;
    //private _calculateRouteSubscription?: Subscription;
    private _externalSystemDataSubscription?: Subscription;
    private _planRouteErrorSubscription?: Subscription;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;

        this.state = {
            loading: false,
            addPlaceTaskOpen: false,
            addVehicleProfileOpen: false,
            createClientProfileOpen: false,

            retriedWaypoints: [],
            puescModal: {
                open: false,
                visited: false,
                dataLoading: false
            },
            PuescGoodsVerificationOpen: false,
            routingAlert: false
        };
    }

    componentDidMount() {
        const { t } = this.props;
        (window as any).app.PlannerModule = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this._logic
            .vehiclesState()
            .getData(this._logic.notification().device!)
            .then(res => {
                this.setState({ vehicleStates: res });
            });

        this._logic
            .plannerLogic()
            .init(true, 'edit', params.editId, undefined, params.vehicleId ? Number(params.vehicleId) : undefined);

        this._planRouteErrorSubscription = this._logic.plannerLogic().onPlanRouteError.subscribe(async err => {
            if (err.name === PlanRouteErrorName.InaccessiblePointError) {
                this._logic.plannerLogic().clearTransport(true);

                // Show modal only if error is for current latest transport and modal is not already shown
                if (!this.state.routingAlert) {
                    Modal.warning({
                        centered: true,
                        title: t('routing.error.noRouteTitle'),
                        className: 'planner-error-modal',
                        icon: (
                            <div className="planner-error-modal-icon">
                                <img src={modalIcons.warning} alt="warning" />
                            </div>
                        ),
                        content: this._getErrorText(err),
                        okText: t('common.ok'),
                        onOk: () => {
                            this.setState({ routingAlert: false });
                        }
                    });

                    this.setState({ routingAlert: true });
                }
            } else {
                this._logic.plannerLogic().clearTransport(true);
                if (!this.state.routingAlert) {
                    Modal.warning({
                        centered: true,
                        className: 'planner-error-modal',
                        icon: (
                            <div className="planner-error-modal-icon">
                                <img src={modalIcons.error} alt="error" />
                            </div>
                        ),
                        title: t('common.error.somethingWentWrong'),
                        content: (
                            <>
                                <div>{t('common.error.somethingWentWrongDescription')}</div>
                                <div>{t('common.error.temporaryIssueDescription')}</div>
                            </>
                        ),
                        okText: t('common.ok'),
                        onOk: () => {
                            this.setState({ routingAlert: false });
                        }
                    });
                }
                this.setState({ routingAlert: true });
            }
        });

        this._logic.map().filterSubject.subscribe(([fuels, services]) => {
            this._logic.plannerLogic().setFuelDataFilter(fuels);
            this._logic.plannerLogic().setServiceDataFilter(services);
        });

        this._logic
            .vehicles()
            .getMonitoredObjectFilters()
            .then(vehicles => {
                const checkedVehicles = (vehicles ?? []).map(v => Number(v.id));
                this._logic
                    .statisticsCompanyProfile()
                    .pricePerKmCostStructure(checkedVehicles)
                    .then(resp => {
                        this.setState(() => ({
                            costs: {
                                fixed: resp?.fixedCostProfile as FixedCost,
                                operational: resp?.mobjects?.map(mobject => ({
                                    monitoredObjectId: mobject.monitoredObjectId,
                                    operationalCost: mobject.operationalCostProfile as OperationalCost
                                }))
                            },
                            costPerKm: {
                                currency: resp?.currency as AvailableCurrencies,
                                cost: resp?.avgPricePerKm
                            }
                        }));
                    });
            });

        if (params.dispatcherBoard) {
            this.setState({
                backUrl: params.editId
                    ? `${RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL}?editId=${params.editId}`
                    : RouteNames.SCHEDULING_DISPATCHER_BOARD
            });
        } else if (params.editId) {
            this.setState({
                backUrl: `${RouteNames.SCHEDULING_ORDERS}?selected=${params.editId}&startDate=${params.startDate}&endDate=${params.endDate}`
            });
        } else if (params.vehicleId) {
            this.setState({
                backUrl: `${RouteNames.TRACKING}?vehicleId=${params.vehicleId}`
            });
        }

        this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
            status: exponea.status.screenShown,
            src: params.dispatcherBoard
                ? exponea.module.schedulingDispatcherBoard
                : params.editId
                ? exponea.module.schedulingRouteOverview
                : params.vehicleId
                ? exponea.module.trackingTableView
                : undefined
        });
    }

    componentWillUnmount() {
        (window as any).app.PlannerModule = undefined;
        this._logic.plannerLogic().reset(false, true);
        //this._calculateRouteSubscription?.unsubscribe();
        this._externalSystemDataSubscription?.unsubscribe();
        this._planRouteErrorSubscription?.unsubscribe();
    }

    render() {
        const transport = this._logic.plannerLogic().transport;

        return (
            <>
                <Modal
                    visible={this.state.createClientProfileOpen}
                    title={this.props.t('ClientContactListForm.modalNewContact')}
                    closable={true}
                    width={900}
                    footer={null}
                    destroyOnClose
                    onCancel={this._onCreateClientClose}
                >
                    <ClientForm
                        countries={this._logic.plannerLogic().countryList}
                        onSubmit={this._onCreateClientSubmit}
                        onCancel={this._onCreateClientClose}
                        demoMode={this.props.logic.demo().isActive}
                        isClient
                    />
                </Modal>
                <Modal
                    visible={this.state.addPlaceTaskOpen}
                    title={this.props.t('PlannerPlacesTask.activity')}
                    closable={true}
                    width={1200}
                    footer={null}
                    destroyOnClose
                    onCancel={this._onCancelTask}
                >
                    {this.props.logic.plannerLogic().selectedPlaceId && (
                        <PlaceSettings
                            onManualAtaDateChange={this._onManualAtaDateChange}
                            onPlacesTaskSave={(
                                model: TransportPlaceTask,
                                clientContact?: ContactList,
                                config?: TransportEventRule[]
                            ) => this._logic.plannerLogic().saveSelectedTask(model, clientContact, config)}
                            onClose={this._onCancelTask}
                            onRemoveTask={this._onRemoveSelectedTask}
                        />
                    )}
                </Modal>
                <ModalForm
                    visible={this.state.addVehicleProfileOpen}
                    title={this.props.t('VehicleProfile.createVehicleProfile')}
                    closable={true}
                    className={'vehicle-profile-modal'}
                    footer={null}
                    destroyOnClose
                    onCancel={this._onCreateProfileClose}
                    size="super-wide"
                >
                    <VehicleProfileForm
                        demoMode={this._logic.demo().isActive}
                        onSubmit={this._onVehicleProfileSubmit}
                        onCancel={this._onCreateProfileClose}
                    />
                </ModalForm>
                <div className="planner-wrapper module">
                    <div className="planner">
                        {this._logic.plannerLogic().initTransportLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <PlannerBar onHelperClick={this._onBarHelperClick} />
                                <div className="planner-content">
                                    <div className="planner-bar-places-autocomplete">
                                        <PlacesConfigurator
                                            routeLoading={
                                                this._logic.plannerLogic().createRouteLoading ||
                                                this._logic.plannerLogic().calculatingRouteLoading
                                            }
                                            onEditTask={this._onEditTask}
                                            onAddNewTask={this._onAddNewTask}
                                            onSortEnd={this._onPlacesDragAndDrop}
                                            onDeleteClick={this._onPlacesDeleteClick}
                                            demoMode={this._logic.demo().isActive}
                                            isPuescTransportPossible={
                                                this._logic.plannerLogic().isPuescTransportPossible
                                            }
                                            puescActive={this._logic.plannerLogic().transport?.puesc}
                                            onPuescChange={this._onPuescChange}
                                        />
                                        <PlannerAutocomplete
                                            loading={
                                                this._logic.plannerLogic().createRouteLoading ||
                                                this._logic.plannerLogic().calculatingRouteLoading
                                            }
                                            onAddRoute={this._onAddRoute}
                                        />
                                    </div>
                                    {(this._logic.plannerLogic().transport.places?.length ?? 0) > 1 && (
                                        <RouteConfigurator
                                            selectedVehicleId={this._logic.plannerLogic().selectedVehicleId}
                                            companyCostPerKm={{
                                                cost: this._logic.plannerLogic().transport.costPerKm?.value ?? 0,
                                                currency: this._logic.plannerLogic().transport.costPerKm
                                                    ?.currency as AvailableCurrencies
                                            }}
                                            vehicleStates={this.state.vehicleStates}
                                            costs={this.state.costs}
                                            onTransportTypeChange={this._onTransportTypeChange}
                                            onRoutePriceChange={this._onRoutePriceChange}
                                            onCreateProfileClick={this._onCreateProfileClick}
                                            onCreateClientClick={this._onCreateClientClick}
                                            onClientSelect={this._onClientSelect}
                                        />
                                    )}
                                </div>
                                <div className="planner-save">
                                    <Row gutter={20} justify="end">
                                        {transport.places && transport.places.length > 0 && (
                                            <Col>
                                                <Button
                                                    onClick={this._onPlannerCancel}
                                                    type="default"
                                                    disabled={this._logic.plannerLogic().calculatingRouteLoading}
                                                    data-qa={qa.schedulingPlanning.cancelWithoutSave}
                                                >
                                                    {this.props.t('Planner.discardChanges')}
                                                </Button>
                                            </Col>
                                        )}
                                        {transport?.places &&
                                            transport?.places.length > 1 &&
                                            transport?.externalId &&
                                            !transport?.places[transport?.places.length - 1].route && (
                                                <Col>
                                                    <Button
                                                        type="dashed"
                                                        loading={
                                                            this._logic.plannerLogic().generateRouteLoading ||
                                                            this._logic.plannerLogic().calculatingRouteLoading
                                                        }
                                                        onClick={this._onPlannerGenerateRoute}
                                                        qa={qa.schedulingPlanning.btnGenerateRoute}
                                                    >
                                                        {this.props.t('Planner.generateRoute')}
                                                    </Button>
                                                </Col>
                                            )}
                                        {transport?.places && transport?.places.length > 1 && (
                                            <Col>
                                                <Button
                                                    onClick={this._onPlannerSave}
                                                    type="primary"
                                                    loading={this.state.loading}
                                                    disabled={
                                                        (this._logic
                                                            .plannerLogic()
                                                            .transport.places?.filter(d => d.route === undefined)
                                                            .length ?? 0) > 1 ||
                                                        this.state.loading ||
                                                        this._logic.plannerLogic().calculatingRouteLoading ||
                                                        this._logic.plannerLogic().externalSystemLoading ||
                                                        this._logic.demo().isActive
                                                    }
                                                    qa={qa.schedulingPlanning.saveRoute}
                                                >
                                                    {this._logic.plannerLogic().transport.id
                                                        ? this.props.t('Planner.updateRoute')
                                                        : this.props.t('Planner.createRoute')}
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <HelperModal
                    name="planner"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    // #region client list events
    private _onCreateClientClick = () => {
        this.setState({ createClientProfileOpen: true });
    };

    private _onCreateClientClose = () => {
        this.setState({ createClientProfileOpen: false });
    };

    private _onCreateClientSubmit = async (client: ContactList) => {
        try {
            await this._logic.plannerLogic().createClientContact(client);
            message.success('Client created successfully');
            this.setState({
                createClientProfileOpen: false
            });

            return true;
        } catch (err) {
            message.error('Could not create client, try again later');
            return false;
        }
    };

    private _onClientSelect = (clientContactId?: string) => {
        this._logic.plannerLogic().setClientContactId(clientContactId);
    };
    // #endregion

    // #region place events
    private _onCancelTask = () => {
        this.setState({
            addPlaceTaskOpen: false
        });
    };

    private _onRemoveSelectedTask = () => {
        this._logic.plannerLogic().removeSelectedTask();
        this.setState({
            addPlaceTaskOpen: false
        });
    };

    private _onManualAtaDateChange = (dateTime: string): void => {
        this.setState({
            manualAta: this._logic.plannerLogic().selectedPlaceId
                ? {
                      dateTime: dateTime,
                      placeId: this._logic.plannerLogic().selectedPlaceId
                  }
                : undefined
        });
    };
    // #endregion

    // #region vehicleProfile
    private _onCreateProfileClose = () => {
        this.setState({ addVehicleProfileOpen: false });
    };

    private _onVehicleProfileSubmit = async (model: VehicleProfileModel) => {
        try {
            await this._logic.plannerLogic().createProfile(model);
            message.success(this.props.t('VehicleProfile.message.createSuccess'));
        } catch (err) {
            console.error(`Could not created vehicle profile, err: ${err}`);
            message.error(this.props.t('VehicleProfile.message.createError'));
        }

        this.setState({ addVehicleProfileOpen: false });
    };

    private _onCreateProfileClick = () => {
        this.setState({ addVehicleProfileOpen: true });
    };
    // #endregion

    private _onRoutePriceChange = (configuration: RouteConfiguration) => {
        this.setState({
            pricePerKm: configuration.pricePerKM.cost
        });
        this._logic
            .plannerLogic()
            .setTransportCostPerKm(configuration.pricePerKM.cost, configuration.pricePerKM.currency);
    };

    private _onTransportTypeChange = (type: TransportType) => {
        this._logic.plannerLogic().selectTransportType(type);
        this._logic.plannerLogic().drawRouteOnMap();
    };

    private _onPlannerCancel = () => {
        this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
            status: exponea.status.actionTaken,
            action: exponea.action.cancelRoute
        });

        const { backUrl } = this.state;
        if (backUrl) {
            this.props.history.push(backUrl);
        }

        this._logic.plannerLogic().reset();
    };

    private _onPlannerGenerateRoute = async () => {
        await this._logic.plannerLogic().generateRoute();
        await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
        this._logic.plannerLogic().drawRouteOnMap();
    };

    private _onAddRoute = async (data: RouteSelectData) => {
        const transportPlaces = this._logic.plannerLogic().transport.places;
        if (transportPlaces && transportPlaces.length > 0) {
            this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
                status: exponea.status.actionTaken,
                action: exponea.action.addDestinationPointToRoute,
                places_count: transportPlaces.length
            });
        }

        try {
            await this._logic.plannerLogic().createRouteForTransport(data);
            this._logic.plannerLogic().updateTransportNameByPlaces();
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
        } catch (err) {
            console.error(`Could not add route to transport, err: ${err}`);
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlacesDragAndDrop = async (sort: SortEnd, _event: SortEvent) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this._logic.plannerLogic().updatePlaceOrder(sort.oldIndex, sort.newIndex);
            this._logic.plannerLogic().clearCrossingPoints(sort);
            this._logic.map().routing().resetPlaces();
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlacesDeleteClick = async (id: string) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this._logic.map().polygons().removePolygon(id);
            this._logic.plannerLogic().removePlaceFromTransport(id);
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            await this._logic.plannerLogic().drawRouteOnMap();
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlannerSave = async () => {
        this.setState(
            {
                loading: true
            },
            () => {
                if (
                    this._logic.plannerLogic().transport.puesc &&
                    this._logic.plannerLogic().puescSecret?.metadata?.puescActive &&
                    this._logic.plannerLogic().selectedVehicleId &&
                    !this.state.puescModal.visited
                ) {
                    this.setState(state => ({
                        puescModal: {
                            ...state.puescModal,
                            open: true,
                            visited: true
                        },
                        loading: false
                    }));
                } else {
                    this.setState(
                        state => ({
                            puescModal: {
                                ...state.puescModal,
                                visited: false
                            }
                        }),
                        async () => {
                            const params: RouteParams = qs.parse(this.props.history.location.search, {
                                ignoreQueryPrefix: true
                            });
                            this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
                                status: exponea.status.actionTaken,
                                action: exponea.action.saveRoute,
                                src: params.dispatcherBoard
                                    ? exponea.module.schedulingDispatcherBoard
                                    : params.editId
                                    ? exponea.module.schedulingRouteOverview
                                    : params.vehicleId
                                    ? exponea.module.trackingTableView
                                    : undefined
                            });
                            try {
                                await this._logic.plannerLogic().saveTransport();
                            } catch (err) {
                                console.error(`could not save transport, err: ${err}`);
                                this.setState({
                                    loading: false
                                });
                            }

                            this._logic.plannerLogic().reset(false);

                            if (this.state.backUrl) {
                                this.props.history.push(this.state.backUrl);
                            } else {
                                this.props.history.push({
                                    pathname: RouteNames.SCHEDULING_ORDERS
                                });
                            }
                        }
                    );
                }
            }
        );
    };

    private _onEditTask = (placeId: string, taskId: string) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this.props.logic.plannerLogic().setSelectedPlaceId(placeId);
            this.props.logic.plannerLogic().setSelectedTaskId(taskId);
            this.setState({
                addPlaceTaskOpen: true
            });
        }
    };

    private _onAddNewTask = (placeId?: string) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this.props.logic.plannerLogic().setSelectedPlaceId(placeId);
            this.props.logic.plannerLogic().setSelectedTaskId(undefined);
            this.setState({
                addPlaceTaskOpen: true
            });
        }
    };

    private _onSystemConnectionsClick = (): void => {
        this.props.history.push({
            pathname: RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS
        });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'routeplanning';
        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onPuescChange = (active: boolean) => {
        this._logic.plannerLogic().onPuescSwitch(active);
    };

    private _getSelectedVehicleName = (): string => {
        let name = '';
        if (this._logic.plannerLogic().selectedVehicleId) {
            name =
                this._logic
                    .plannerLogic()
                    .availableVehicles.find(
                        vehicle =>
                            vehicle.data.monitoredObjectId === String(this._logic.plannerLogic().selectedVehicleId)
                    )?.data.rn ?? '';
        } else {
            const profileName =
                this.props.logic
                    .plannerLogic()
                    .availableVehicleProfiles.find(
                        profile => profile.id === this._logic.plannerLogic().selectedProfileId
                    )?.name ?? '';
            name =
                profileName === defaultVehicleProfileName
                    ? this.props.t('VehicleProfile.defaultProfileName')
                    : profileName;
        }
        return name;
    };

    _getErrorText(error: InaccessiblePointError): ReactNode {
        const formatValue = (text: string) => {
            return `<span class="message-value">${text}</span>`;
        };

        if (error.points && error.points?.length > 0) {
            const point = error.points[0];
            const logic = this.props.logic.plannerLogic();
            switch (point.type) {
                case InaccessiblePointTypeEnum.Place:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRoutePlaceDescription', {
                                    place: formatValue(point.name ?? ''),
                                    vehicle: formatValue(this._getSelectedVehicleName())
                                })
                            }}
                        />
                    );
                case InaccessiblePointTypeEnum.Crossing:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRouteCrossingDescription', {
                                    number: formatValue(`${(point.crossingIndex ?? 0) + 1}.`),
                                    placeFrom: formatValue(
                                        logic.transport?.places?.[point.placeIndex ?? 0]?.name ?? ''
                                    ),
                                    placeTo: formatValue(
                                        logic.transport?.places?.[(point.placeIndex ?? 0) + 1]?.name ?? ''
                                    ),
                                    vehicle: formatValue(this._getSelectedVehicleName())
                                })
                            }}
                        />
                    );
                default:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRouteDescription', {
                                    vehicle: formatValue(this._getSelectedVehicleName())
                                })
                            }}
                        />
                    );
            }
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.t('routing.error.noRouteDescription', {
                            vehicle: formatValue(this._getSelectedVehicleName())
                        })
                    }}
                />
            );
        }
    }
}

export default withTranslation()(withRouter(observer(RoutePlanningScheduling)));
