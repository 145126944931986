/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleDirectionsResponseRoutes,
    GoogleDirectionsResponseRoutesFromJSON,
    GoogleDirectionsResponseRoutesFromJSONTyped,
    GoogleDirectionsResponseRoutesToJSON,
} from './';

/**
 * 
 * @export
 * @interface GoogleDirectionsResponse
 */
export interface GoogleDirectionsResponse {
    /**
     * 
     * @type {Array<GoogleDirectionsResponseRoutes>}
     * @memberof GoogleDirectionsResponse
     */
    routes: Array<GoogleDirectionsResponseRoutes>;
    /**
     * 
     * @type {number}
     * @memberof GoogleDirectionsResponse
     */
    routesCount: number;
    /**
     * 
     * @type {Date}
     * @memberof GoogleDirectionsResponse
     */
    createdAt: Date;
}

export function GoogleDirectionsResponseFromJSON(json: any): GoogleDirectionsResponse {
    return GoogleDirectionsResponseFromJSONTyped(json, false);
}

export function GoogleDirectionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleDirectionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'routes': ((json['routes'] as Array<any>).map(GoogleDirectionsResponseRoutesFromJSON)),
        'routesCount': json['routesCount'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function GoogleDirectionsResponseToJSON(value?: GoogleDirectionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'routes': ((value.routes as Array<any>).map(GoogleDirectionsResponseRoutesToJSON)),
        'routesCount': value.routesCount,
        'createdAt': (value.createdAt.toISOString()),
    };
}


