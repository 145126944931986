/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AETRDailyData
 */
export interface AETRDailyData {
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    drivingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    workingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    restingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    dailyUtilization: number;
    /**
     * 
     * @type {Date}
     * @memberof AETRDailyData
     */
    dailyUtilizationMax: Date;
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    dailyUtilizationMaxHours: number;
    /**
     * 
     * @type {boolean}
     * @memberof AETRDailyData
     */
    isCrew: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AETRDailyData
     */
    dayStart: Date;
    /**
     * 
     * @type {number}
     * @memberof AETRDailyData
     */
    driveMaxHours?: number;
    /**
     * 
     * @type {Date}
     * @memberof AETRDailyData
     */
    nextBreakStartTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AETRDailyData
     */
    reducedDailyRest?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AETRDailyData
     */
    extendedShift?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AETRDailyData
     */
    reliable?: boolean;
}

export function AETRDailyDataFromJSON(json: any): AETRDailyData {
    return AETRDailyDataFromJSONTyped(json, false);
}

export function AETRDailyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AETRDailyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drivingDuration': json['driving_duration'],
        'workingDuration': json['working_duration'],
        'restingDuration': json['resting_duration'],
        'dailyUtilization': json['daily_utilization'],
        'dailyUtilizationMax': (new Date(json['daily_utilization_max'])),
        'dailyUtilizationMaxHours': json['daily_utilization_max_hours'],
        'isCrew': json['is_crew'],
        'dayStart': (new Date(json['day_start'])),
        'driveMaxHours': !exists(json, 'drive_max_hours') ? undefined : json['drive_max_hours'],
        'nextBreakStartTime': !exists(json, 'next_break_start_time') ? undefined : (new Date(json['next_break_start_time'])),
        'reducedDailyRest': !exists(json, 'reduced_daily_rest') ? undefined : json['reduced_daily_rest'],
        'extendedShift': !exists(json, 'extended_shift') ? undefined : json['extended_shift'],
        'reliable': !exists(json, 'reliable') ? undefined : json['reliable'],
    };
}

export function AETRDailyDataToJSON(value?: AETRDailyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driving_duration': value.drivingDuration,
        'working_duration': value.workingDuration,
        'resting_duration': value.restingDuration,
        'daily_utilization': value.dailyUtilization,
        'daily_utilization_max': (value.dailyUtilizationMax.toISOString()),
        'daily_utilization_max_hours': value.dailyUtilizationMaxHours,
        'is_crew': value.isCrew,
        'day_start': (value.dayStart.toISOString()),
        'drive_max_hours': value.driveMaxHours,
        'next_break_start_time': value.nextBreakStartTime === undefined ? undefined : (value.nextBreakStartTime.toISOString()),
        'reduced_daily_rest': value.reducedDailyRest,
        'extended_shift': value.extendedShift,
        'reliable': value.reliable,
    };
}


