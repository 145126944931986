import cn from 'classnames';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import {
    ObuForm,
    defaultValidationErrors,
    snValidator,
    formValidator,
    clientValidator,
    typeValidator
} from '../ObuDetail';
import { ObuTypeSelectModel } from 'logic/partner/partner';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { ReadOnlyMonitoringDeviceType } from 'generated/new-main';
import { Input, Select } from 'common/components/Form';
import { SelectValue } from 'antd/lib/select';
import { debounce } from 'debounce';
import { Row, Col } from 'antd';
import { Button } from 'common/components';
import { searched } from 'common/utils/search';

interface Props extends WithTranslation {
    model: ObuModel;
    obuTypeCounter?: ObuTypeSelectModel[];
    deviceTypes?: ReadOnlyMonitoringDeviceType[];
    clients?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    loading?: boolean;
    onClientSearch?: (text: string) => void;
    onCancel?: () => void;
    onSubmit?: (model: ObuModel) => void;
}

interface State {
    form: ObuForm;
}

class ObuCreate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: props.model.id,
                sn: props.model.sn,
                type: String(props.model.type),
                client: props.model.clientId ?? props.selectedCompany?.id ?? '',
                activatedOn: props.model.activatedOn ?? ''
            }
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="t-modal">
                <form className="partner-modal-form t-modal-content" onSubmit={this._onSubmit}>
                    <h1>{t('PartnerObu.addNewObu')}</h1>
                    <div className="t-bar-block">
                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.sn')}>
                                {t('Partner.sn')}
                            </div>
                            <div className="t-half">
                                <Input
                                    className={cn({
                                        error: this.state.form.sn,
                                        success: !this.state.form.snErr && !!this.state.form.sn
                                    })}
                                    onChange={this._onSnChange}
                                    value={this.state.form.sn}
                                />
                                {this.state.form.snErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.snErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.type')}>
                                {t('common.type')}
                            </div>
                            <div className="t-half">
                                <Select
                                    className={cn({
                                        error: this.state.form.type,
                                        success: !this.state.form.typeErr && !!this.state.form.type
                                    })}
                                    showSearch
                                    placeholder={t('common.select')}
                                    value={this.state.form?.type}
                                    filterOption={(input, option) => searched(input, String(option?.children))}
                                    size="middle"
                                    options={
                                        this.props.deviceTypes?.map(v => ({ value: String(v.id), label: v.label })) ??
                                        []
                                    }
                                    onChange={this._onTypeChange}
                                />
                                {this.state.form.typeErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.typeErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.company')}>
                                {t('common.company')}
                            </div>
                            <div className="t-half">
                                <Select
                                    className={cn({
                                        error: this.state.form.client,
                                        success: !this.state.form.clientErr && !!this.state.form.client
                                    })}
                                    showSearch
                                    placeholder={t('common.select')}
                                    value={this.props.selectedCompany?.id ?? undefined}
                                    filterOption={false}
                                    size="middle"
                                    options={this.props.clients?.map(v => ({ value: v.id, label: v.label })) ?? []}
                                    onChange={this._onClientChange}
                                    onSearch={this._onClientSearch}
                                />
                                {this.state.form.clientErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.clientErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        {/* <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.activatedOn')}>
                                {t('Partner.activatedOn')}
                            </div>
                            <div className="t-half">
                                <DatePicker
                                    selected={this.state.form.activatedOn}
                                    onChange={this._onActivatedOnChange}
                                    showTimeSelect={false}
                                    showPopperArrow={false}
                                    customInput={
                                        <div className="t-input t-padding-small">
                                            {moment(this.state.form.activatedOn).format('L')}
                                        </div>
                                    }
                                />
                            </div>
                        </div> */}
                    </div>
                    <footer>
                        <Row justify="end" gutter={10}>
                            <Col>
                                <Button type="default" onClick={this._onCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                    </footer>
                </form>
            </div>
        );
    }

    private _onSnChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = snValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                sn: value,
                snErr: validateRes.err as string
            }
        }));
    };

    private _onActivatedOnChange = (value: string): void => {
        this.setState(state => ({
            form: {
                ...state.form,
                activatedOn: value
            }
        }));
    };

    private _onTypeChange = (value: SelectValue) => {
        const validateRes = typeValidator.validate(String(value));
        this.setState(state => ({
            form: {
                ...state.form!,
                type: String(value),
                typeErr: validateRes.err as string
            }
        }));
    };

    private _onClientChange = (value: SelectValue) => {
        const validateRes = clientValidator.validate(String(value));
        this.setState(state => ({
            form: {
                ...state.form!,
                client: String(value),
                clientErr: validateRes.err as string
            }
        }));
    };

    private _onClientSearch = debounce((text: string) => {
        this.props.onClientSearch?.(text);
    }, 4e2);

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const validationRes = formValidator.validate({
            sn: this.state.form.sn,
            type: this.state.form.type,
            client: this.state.form.client
        });
        if (validationRes.valid) {
            const modelCreate: ObuModel = {
                id: '',
                sn: this.state.form.sn,
                activatedOn: this.state.form.activatedOn,
                type: this.state.form.type,
                clientId: this.state.form.client
            };
            this.props.onSubmit?.(modelCreate);
        } else {
            this.setState(state => ({
                form: {
                    ...state.form!,
                    snErr: validationRes.err?.sn ?? '',
                    typeErr: validationRes.err?.type ?? '',
                    clientErr: validationRes.err?.client ?? ''
                }
            }));
        }
    };
}

export default withTranslation()(ObuCreate);
