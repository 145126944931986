import { Col, Row } from 'antd';
import { Button, Tooltip } from 'common/components';
import { AvailableCurrencies } from 'common/model/currency';
import { VehicleWithAvailability } from 'common/model/transports';
import { MonitoredObjectTrailerProfile } from 'generated/new-main';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemInfo } from 'resources/images/planner';
import { VehicleProfile } from '../../planner-logic';
import EditItem from '../EditItem';
import TrailerProfileDetail from '../TrailerProfileDetail';

interface Props extends WithTranslation {
    rn?: string;
    profile?: MonitoredObjectTrailerProfile;
    qaEdit?: string;
    qaDelete?: string;
    qaInfo?: string;
    currency?: AvailableCurrencies;
    onButtonEditClick?: (
        profile: VehicleProfile,
        isVehicle: boolean,
        vehicle: VehicleWithAvailability | undefined
    ) => void;
    onButtonDeleteClick?: (profile: VehicleProfile) => void;
}

const TrailerItem = (props: Props) => {
    return (
        <EditItem className="trailer-item" showDelete={false} showEdit={false}>
            <Row align="middle">
                <Col className="trailer-item-rn">{props.rn}</Col>
                {props.profile && (
                    <Col>
                        <Tooltip
                            overlayClassName="trailer-profile-tooltip"
                            title={<TrailerProfileDetail profile={props.profile} currency={props.currency} />}
                            placement="bottomRight"
                        >
                            <Button
                                type="link"
                                className="edit-item-action info-profile"
                                icon={<img src={ItemInfo} alt="info-trailer-profile" />}
                                qa={props.qaInfo}
                            />
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </EditItem>
    );
};

export default withTranslation()(TrailerItem);
