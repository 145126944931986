import React, { ReactNode, useState } from 'react';
import { Divider, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import qaSelectors from 'qa-selectors';

interface Props {
    title: ReactNode;
    stats: ReactNode[];
    qa?: string;
}

export default function DriverBehaviorCollapse({ stats, title, qa }: Props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    function onOpenToggle() {
        setOpen(s => !s);
    }

    return (
        <div className="driver-behavior-collapse" data-qa={qa}>
            <Row justify="space-between">
                <Col>
                    <h3>{title}</h3>
                </Col>
                {stats.length > 4 && (
                    <Col>
                        <Button
                            className="driver-behavior-collapse-toggle-btn"
                            type="default"
                            onClick={onOpenToggle}
                            size="middle"
                            qa={qaSelectors.driverBehaviour.btnCollapse}
                        >
                            {open ? t('DriverBehaviorCollapse.close') : t('DriverBehaviorCollapse.open')}
                        </Button>
                    </Col>
                )}
            </Row>
            <Row gutter={20}>
                {stats.slice(0, 4).map((e, i) => (
                    <Col key={i} xs={24} md={Math.max(6, Math.round(24 / stats.length))}>
                        {e}
                    </Col>
                ))}
            </Row>
            {stats.length > 4 && open && (
                <>
                    <Divider />
                    <Row style={{ paddingBottom: '20px' }} gutter={20}>
                        {stats.slice(4, stats.length).map((e, i) => (
                            <Col key={i} xs={24} md={6}>
                                {e}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </div>
    );
}
