import React from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { DatePickerField, InputField, SelectField } from 'common/fields';
import { Button } from 'common/components';
import { countriesCodes } from 'common/model/countries';
import { SelectOption } from 'common/components/Form/Select';
import { ExpenseType } from 'generated/graphql';
import qa from 'qa-selectors';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import moment from 'moment';
import { searched } from 'common/utils/search';
import { Select } from 'common/components/Form';

export interface ExpensesFormModel {
    id?: string;
    date?: string;
    monitoredObjectId?: number;
    type?: ExpenseType;
    quantity?: number;
    priceTotal?: number;
    supplier?: string;
    country?: string;
}

interface Props {
    type?: 'default' | 'edit';
    lang: string;
    initialValues?: Partial<ExpensesFormModel>;
    monitoredObjectSelectOpts: SelectOption[];
    supplierOpts: SelectOption[];
    hideSuppliers?: boolean;
    onSupplierSearch?: (value: string) => void;
    onSubmit: (values: ExpensesFormModel) => Promise<boolean>;
    onCancel?: () => void;
}

function ExpensesForm(props: Props) {
    const { t } = useTranslation();

    const countriesOpts: SelectOption[] = countriesCodes
        .map(c => ({
            label: t(`common.countries.${c.iso2}`),
            value: c.iso2
        }))
        .sort((a, b) => (a.label > b.label ? 1 : 0));

    const schema = Yup.object().shape(
        {
            date: Yup.string().required(t('common.required')),
            monitoredObjectId: Yup.number().required(t('common.required')),
            type: Yup.mixed<ExpenseType>().oneOf(Object.values(ExpenseType)).required(t('common.required')),
            quantity: Yup.number().required(t('common.required')).min(0.000001),
            priceTotal: Yup.number().required(t('common.required')).min(0.000001),
            supplier: Yup.string().when('country', {
                is: (country: string) => {
                    return !country;
                },
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            country: Yup.string()
                .when('supplier', {
                    is: (supplier: string) => {
                        return !supplier;
                    },
                    then: Yup.string().required(t('common.required')),
                    otherwise: Yup.string().notRequired()
                })
                .oneOf(countriesCodes.map(c => c.iso2))
        },
        [['supplier', 'country']]
    );

    function handleOnSubmit(values: ExpensesFormModel) {
        props.onSubmit?.(values);
    }

    const expenseTypeOpts = Object.keys(ExpenseType).map(et => {
        return {
            value: ExpenseType[et],
            label: t(`ExpensesFilter.${String(ExpenseType[et]).toLowerCase()}`)
        };
    });

    return (
        <Formik<ExpensesFormModel>
            initialValues={{
                id: props.initialValues?.id,
                date: props.initialValues?.date,
                monitoredObjectId: props.initialValues?.monitoredObjectId,
                type: props.initialValues?.type,
                quantity: props.initialValues?.quantity,
                priceTotal: props.initialValues?.priceTotal,
                country: props.initialValues?.country,
                supplier: props.initialValues?.supplier
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {(formik: FormikProps<ExpensesFormModel>) => (
                <div className="expenses-form">
                    <Form>
                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {t('common.vehicle')}
                            </Col>
                            <Col span={24} xxl={16}>
                                <SelectField
                                    defaultValue={props.initialValues?.monitoredObjectId}
                                    name="monitoredObjectId"
                                    options={props.monitoredObjectSelectOpts}
                                    disabled={!!props.initialValues?.monitoredObjectId}
                                    qa={qa.expenses.cargo.inputVehicle}
                                    showSearch
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {t('ExpensesCargo.date')}
                            </Col>
                            <Col span={24} xxl={16}>
                                <DatePickerField
                                    clearIcon={false}
                                    lang={props.lang}
                                    name="date"
                                    format="L LT"
                                    disabledDate={d => d.isAfter(moment())}
                                    showTime={{ format: TIME_FORMAT_SHORT }}
                                    qa={qa.expenses.cargo.triggerDate}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {t('ExpensesFilter.selectExpenseType')}
                            </Col>
                            <Col span={24} xxl={16}>
                                <SelectField
                                    defaultValue={props.initialValues?.type}
                                    name="type"
                                    options={expenseTypeOpts}
                                    qa={qa.expenses.cargo.inputType}
                                />
                            </Col>
                        </Row>
                        {!props.hideSuppliers && (
                            <Row gutter={[20, 10]} className="form-row">
                                <Col span={24} xxl={8}>
                                    {t('ExpensesCargo.supplier')}
                                </Col>
                                <Col span={24} xxl={16}>
                                    <SelectField
                                        showSearch
                                        allowClear
                                        name="supplier"
                                        options={props.supplierOpts}
                                        onSearch={props.onSupplierSearch}
                                        qa={qa.expenses.cargo.inputSupplier}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {t('ExpensesCargo.country')}
                            </Col>
                            <Col span={24} xxl={16}>
                                <SelectField
                                    name="country"
                                    allowClear
                                    defaultValue={props.initialValues?.country}
                                    qa={qa.expenses.cargo.inputCountry}
                                    showSearch
                                    filterOption={(input, option) =>
                                        searched(input, String(option?.value)) ||
                                        searched(input, String(option?.children))
                                    }
                                >
                                    {countriesOpts.map(country => (
                                        <Select.Option key={country.value} value={country.value}>
                                            <div
                                                className={`flag-icon flag-icon-${String(
                                                    country.value
                                                ).toLocaleLowerCase()}`}
                                                data-country-code={country.value}
                                            />
                                            {` ${country.label} (${country.value})`}
                                        </Select.Option>
                                    ))}
                                </SelectField>
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {t('ExpensesCargo.quantity')}{' '}
                                <span data-qa={qa.expenses.cargo.fieldQuantityUnit}>
                                    {formik.values.type === ExpenseType.Fuel ||
                                    formik.values.type === ExpenseType.Adblue
                                        ? 'L'
                                        : t('common.pc')}
                                </span>
                            </Col>
                            <Col span={24} xxl={16}>
                                <InputField.Number
                                    defaultValue={props.initialValues?.quantity}
                                    name="quantity"
                                    qa={qa.expenses.cargo.inputQuantity}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={24} xxl={8}>
                                {`${t('ExpensesCargo.priceTotal')} ${t('ExpensesTable.withVAT')}`}
                            </Col>
                            <Col span={24} xxl={16}>
                                <InputField.Number
                                    defaultValue={props.initialValues?.priceTotal}
                                    name="priceTotal"
                                    qa={qa.expenses.cargo.inputPrice}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-buttons" justify="end">
                            <Col>
                                <Button onClick={props.onCancel} qa={qa.expenses.cargo.btnCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                    qa={qa.expenses.cargo.btnSubmit}
                                >
                                    {props.type === 'edit' ? t('common.edit') : t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default ExpensesForm;
