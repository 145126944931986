import { useRef, useEffect, useState } from 'react';
import { Col, Row, Input } from 'antd';
import { Button } from 'common/components';
import { CancelCircle, CheckCircle, ItemDelete, ItemEdit } from 'resources/images/planner';
import cn from 'classnames';
import { getParents } from 'common/utils';

export interface EditItemProps {
    disabled?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
    editable?: boolean;
    autoSelectEdited?: boolean;
    label?: string | null;
    labelKey?: string | number;
    qaEdit?: string;
    qaDelete?: string;
    qaInput?: string;
    qaSave?: string;
    qaCancel?: string;
    className?: string;
    onButtonEditClick?: (event: React.MouseEvent) => void;
    onButtonDeleteClick?: (key?: string | number) => void;
    onButtonSaveClick?: (value: string, key: string | number) => void;
    onButtonCancelClick?: () => void;
}

const EditItem = (props: React.PropsWithChildren<EditItemProps>) => {
    const _refInput = useRef(null);
    const _refItem = useRef(null);
    const [edited, setEdited] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [newLabel, setNewLabel] = useState<string | undefined>(undefined);
    const [useListener, setUseListener] = useState(false);

    const init = () => {
        if (edited && _refInput.current) {
            (_refInput.current as HTMLInputElement).focus();
            if (props.autoSelectEdited && !initialized) {
                (_refInput.current as HTMLInputElement).select();
                setInitialized(true);
            }
        }
    };

    useEffect(() => {
        init();
    });

    const getItemOption = () => {
        if (_refItem.current) {
            let item = getParents(_refItem.current, 'div.ant-select-item');
            if (item) {
                return item;
            } else {
                item = getParents(_refItem.current, 'div.ant-select');
                return item;
            }
        }
    };

    const removeListeners = () => {
        setInitialized(false);
        const _itemOption = getItemOption();
        if (_itemOption) {
            _itemOption.removeEventListener('mousedown', handleMousedown);
            _itemOption.removeEventListener('customEvent', handleCustomMousedown);
            _itemOption.removeEventListener('click', handleMouseclick);
            window.removeEventListener('mousedown', handleWindowMousedown);
            setUseListener(false);
        }
    };

    const handleButtonEditClick = (event: React.MouseEvent) => {
        props.onButtonEditClick?.(event);

        if (!useListener) {
            const _itemOption = getItemOption();
            if (_itemOption) {
                _itemOption.addEventListener('customEvent', handleCustomMousedown);
                _itemOption.addEventListener('mousedown', handleMousedown);
                _itemOption.addEventListener('click', handleMouseclick);
                window.addEventListener('mousedown', handleWindowMousedown);
                setUseListener(true);
            }
        }

        if (props.editable && !props.disabled) {
            setEdited(true);
        }
        event.stopPropagation();
        event.preventDefault();
    };

    const handleButtonDeleteClick = (event: React.MouseEvent) => {
        props.onButtonDeleteClick?.(props.labelKey);
        event.stopPropagation();
        event.preventDefault();
    };

    const handleMousedown = (event: React.MouseEvent) => {
        if (event.target['nodeName'] !== 'IMG') {
            event.stopPropagation();
            const customEvent = new Event('customEvent');
            const _itemOption = getItemOption();
            if (_itemOption) {
                _itemOption.dispatchEvent(customEvent);
            }
        }
    };

    const handleCustomMousedown = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        return false;
    };

    const handleMouseclick = (event: React.MouseEvent) => {
        const _item = getParents(event.target, 'div.edit-item');
        if (!_item) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const handleWindowMousedown = (event: MouseEvent) => {
        const _item = getParents(event.target, 'div.edit-item');
        if (!_item) {
            removeListeners();
        }
    };

    const handleLabelKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            removeListeners();
            setEdited(false);
            _saveLabel();
            event.stopPropagation();
            event.preventDefault();
        } else if (event.key === 'Escape') {
            removeListeners();
            setEdited(false);
            props.onButtonCancelClick?.();
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const handleButtonCancelClick = (event: React.MouseEvent) => {
        removeListeners();
        props.onButtonCancelClick?.();
        setEdited(false);
        event.stopPropagation();
        event.preventDefault();
    };

    const handleButtonSaveClick = (event: React.MouseEvent) => {
        removeListeners();
        _saveLabel();
        event.stopPropagation();
        event.preventDefault();
    };

    const _saveLabel = () => {
        props.onButtonSaveClick?.(newLabel ?? props.label ?? '', props.labelKey!);
        setEdited(false);
    };

    const showDelete = (props.showDelete ?? true) && !props.disabled;
    const showEdit = (props.showEdit ?? true) && !props.disabled;

    const content = props.editable ? (
        edited ? (
            <Input
                id="edit_field"
                ref={_refInput}
                size="small"
                defaultValue={props.label ?? ''}
                onKeyDown={handleLabelKeyDown}
                onChange={e => {
                    setNewLabel(e.currentTarget.value);
                }}
                onBlur={() => {
                    props.onButtonCancelClick?.();
                    setEdited(false);
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                data-qa={props.qaInput}
            />
        ) : (
            props.children
        )
    ) : (
        props.children
    );

    const buttonsWidth = !edited ? (props.showEdit ?? true ? 25 : 0) + (props.showDelete ?? true ? 25 : 0) : 50;
    const contentStyle = {
        width: `calc(100% - ${buttonsWidth}px)`
    };

    return (
        <Row
            className={cn(`edit-item ${props.className ?? ''}`, {
                'edit-item-disabled': props.disabled,
                'edit-item-edited': edited
            })}
            align="middle"
            ref={_refItem}
        >
            <Col className="edit-item-content" style={contentStyle}>
                {content}
            </Col>
            {showEdit && !edited && (
                <Col className="edit-item-button">
                    <Button
                        type="link"
                        className="edit-item-action"
                        icon={<img src={ItemEdit} alt="edit-item-edit-action" />}
                        onClick={handleButtonEditClick}
                        qa={props.qaEdit}
                    />
                </Col>
            )}
            {showDelete && !edited && (
                <Col className="edit-item-button">
                    <Button
                        type="link"
                        className="edit-item-action"
                        icon={<img src={ItemDelete} alt="edit-item-delete-action" />}
                        onClick={handleButtonDeleteClick}
                        qa={props.qaDelete}
                    />
                </Col>
            )}
            {edited && (
                <>
                    <Col className="edit-item-button">
                        <Button
                            type="link"
                            className="edit-item-label-action"
                            icon={<img src={CancelCircle} alt="close-item-label-action" />}
                            onClick={handleButtonCancelClick}
                            qa={props.qaCancel}
                        />
                    </Col>
                    <Col className="edit-item-button">
                        <Button
                            type="link"
                            className="edit-item-label-action"
                            icon={<img src={CheckCircle} alt="save-item-label-action" />}
                            onClick={handleButtonSaveClick}
                            qa={props.qaSave}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
};

export default EditItem;
