import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FleetModel } from '../../FleetModule';
import { Role } from 'logic/auth';
import FleetTrailerDetailCard from '../FleetTrailerDetailCard';
import TrailerForm, { TrailerFormModel } from 'common/forms/TrailerForm';

interface Props extends WithTranslation {
    edit: boolean;
    data: FleetModel;
    demoMode?: boolean;
    roles: Role[];
    onFormCancel?: () => void;
    onFormSubmitClick?: (data: FleetModel) => void;
}

interface State {
    trailerInitialFormData?: TrailerFormModel;
}

class FleetTrailerDetail extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        (window as any).app.trailerDetail = this;
    }

    render() {
        const { data } = this.props;
        return (
            <div className="management-trailer-detail">
                {this.props.edit ? (
                    <TrailerForm
                        mode="EDIT"
                        initialValues={{
                            id: this.props.data?.id,
                            cargoType: this.props.data?.cargoType,
                            weightFull: this.props.data?.weightFull,
                            height: this.props.data?.height,
                            length: this.props.data?.length,
                            manufacturer: this.props.data?.manufacturer,
                            name: this.props.data?.name,
                            registrationNumber: this.props.data?.registrationNumber,
                            trailerType: this.props.data?.trailerType,
                            weight: this.props.data?.weight,
                            width: this.props.data?.width,
                            trailerIdSerialNumber: this.props.data?.trailerIdSerialNumber,
                            temperatureSensors: this.props.data?.temperatureSensors
                        }}
                        roles={this.props.roles}
                        onCancel={this._onCancelForm}
                        onSubmit={this._onSubmitForm}
                    />
                ) : (
                    <FleetTrailerDetailCard data={data} />
                )}
            </div>
        );
    }
    private _onCancelForm = () => {
        this.props.onFormCancel?.();
    };

    private _onSubmitForm = (data: TrailerFormModel): void => {
        if (this.props.data) {
            this.props.onFormSubmitClick?.({
                ...this.props.data,
                cargoType: data?.cargoType,
                weightFull: data?.weightFull,
                height: data?.height,
                length: data?.length,
                manufacturer: data?.manufacturer!,
                name: data?.name!,
                trailerIdSerialNumber: data?.trailerIdSerialNumber!,
                trailerType: data?.trailerType,
                weight: data?.weight,
                width: data?.width,
                temperatureSensors: data?.temperatureSensors
            });
        }
    };
}

export default withTranslation()(FleetTrailerDetail);
