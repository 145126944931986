/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RouteType {
    Balanced = 'balanced',
    Shortest = 'shortest',
    Fastest = 'fastest'
}

export function RouteTypeFromJSON(json: any): RouteType {
    return RouteTypeFromJSONTyped(json, false);
}

export function RouteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteType {
    return json as RouteType;
}

export function RouteTypeToJSON(value?: RouteType | null): any {
    return value as any;
}

