/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LatLng
 */
export interface LatLng {
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    lon: number;
}

export function LatLngFromJSON(json: any): LatLng {
    return LatLngFromJSONTyped(json, false);
}

export function LatLngFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatLng {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lon': json['lon'],
    };
}

export function LatLngToJSON(value?: LatLng | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lon': value.lon,
    };
}


