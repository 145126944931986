import { Col, Divider, Row } from 'antd';
import Tooltip from 'common/components/Tooltip';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TcoSumTableData } from '../../total-cost-of-ownership-logic';

interface Props extends WithTranslation {
    data: TcoSumTableData;
}

function TCOSumTable(props: Props) {
    const units = {
        '&euro;': '€',
        years: props.t('years')
    };
    return (
        <div className="tco-sum-table">
            <div>
                <h2>{props.t('TotalCostOfOwnership.resultCalculatedData')}</h2>
            </div>

            <div>
                <h3>{props.t('TotalCostOfOwnership.sumTable.dieselVehicle')}</h3>
            </div>
            <Divider />
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.purchaseCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.purchaseCost}>
                    {numeral(props.data.diesel.purchaseCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.purchaseCost?.units &&
                        (units[props.data.diesel.purchaseCost?.units] ?? props.data.diesel.purchaseCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.insuranceCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.insuranceCost}>
                    {numeral(props.data.diesel.insuranceCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.insuranceCost?.units &&
                        (units[props.data.diesel.insuranceCost?.units] ?? props.data.diesel.insuranceCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.serviceAndMaintenanceCost')}</Col>
                <Col
                    className="text-right"
                    span={12}
                    data-qa={qa.totalCostOfOwnership.table.sumDiesel.serviceAndMaintenanceCost}
                >
                    {numeral(props.data.diesel.serviceAndMaintenanceCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.serviceAndMaintenanceCost?.units &&
                        (units[props.data.diesel.serviceAndMaintenanceCost?.units] ??
                            props.data.diesel.serviceAndMaintenanceCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.fuelCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.fuelCost}>
                    {numeral(props.data.diesel.fuelCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.fuelCost?.units &&
                        (units[props.data.diesel.fuelCost?.units] ?? props.data.diesel.fuelCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.tollCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.tollCost}>
                    {numeral(props.data.diesel.tollCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.tollCost?.units &&
                        (units[props.data.diesel.tollCost?.units] ?? props.data.diesel.tollCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.otherCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.otherCost}>
                    {numeral(props.data.diesel.otherCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.otherCost?.units &&
                        (units[props.data.diesel.otherCost?.units] ?? props.data.diesel.otherCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.adblueCosts')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.adblueCosts}>
                    {numeral(props.data.diesel.adblueCosts?.value).format('0,0.00')}{' '}
                    {props.data.diesel.adblueCosts?.units &&
                        (units[props.data.diesel.adblueCosts?.units] ?? props.data.diesel.adblueCosts?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    {props.t('TotalCostOfOwnership.table.gCo2PerUnitProduction')}
                    <Tooltip
                        className="tooltip"
                        title={`${props.t('TotalCostOfOwnership.sumTable.gCo2ConstantUsed')}: ${
                            props.data.gCo2Constant.diesel
                        }`}
                        qa={qa.totalCostOfOwnership.table.sumDiesel.gCo2Constant}
                    >
                        <i className="fa fa-question" />
                    </Tooltip>
                </Col>
                <Col
                    className="text-right"
                    span={12}
                    data-qa={qa.totalCostOfOwnership.table.sumDiesel.gCo2PerUnitProduction}
                >
                    {numeral(props.data.diesel.gCo2PerUnitProduction?.value).format('0,0.0')}{' '}
                    {props.data.diesel.gCo2PerUnitProduction?.units &&
                        (units[props.data.diesel.gCo2PerUnitProduction?.units] ??
                            props.data.diesel.gCo2PerUnitProduction?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.totalCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumDiesel.totalCost}>
                    {numeral(props.data.diesel.totalCost?.value).format('0,0.00')}{' '}
                    {props.data.diesel.totalCost?.units &&
                        (units[props.data.diesel.totalCost?.units] ?? props.data.diesel.totalCost?.units)}
                </Col>
            </Row>
            <br />
            <br />

            <div>
                <h3>{props.t('TotalCostOfOwnership.sumTable.lngVehicle')}</h3>
            </div>
            <Divider />

            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.purchaseCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.purchaseCost}>
                    {numeral(props.data.lng.purchaseCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.purchaseCost?.units &&
                        (units[props.data.lng.purchaseCost?.units] ?? props.data.lng.purchaseCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.insuranceCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.insuranceCost}>
                    {numeral(props.data.lng.insuranceCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.insuranceCost?.units &&
                        (units[props.data.lng.insuranceCost?.units] ?? props.data.lng.insuranceCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.serviceAndMaintenanceCost')}</Col>
                <Col
                    className="text-right"
                    span={12}
                    data-qa={qa.totalCostOfOwnership.table.sumLng.serviceAndMaintenanceCost}
                >
                    {numeral(props.data.lng.serviceAndMaintenanceCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.serviceAndMaintenanceCost?.units &&
                        (units[props.data.lng.serviceAndMaintenanceCost?.units] ??
                            props.data.lng.serviceAndMaintenanceCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.fuelCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.fuelCost}>
                    {numeral(props.data.lng.fuelCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.fuelCost?.units &&
                        (units[props.data.lng.fuelCost?.units] ?? props.data.lng.fuelCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.tollCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.tollCost}>
                    {numeral(props.data.lng.tollCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.tollCost?.units &&
                        (units[props.data.lng.tollCost?.units] ?? props.data.lng.tollCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.otherCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.otherCost}>
                    {numeral(props.data.lng.otherCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.otherCost?.units &&
                        (units[props.data.lng.otherCost?.units] ?? props.data.lng.otherCost?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    {props.t('TotalCostOfOwnership.table.gCo2PerUnitProduction')}

                    <Tooltip
                        className="tooltip"
                        title={`${props.t('TotalCostOfOwnership.sumTable.gCo2ConstantUsed')}: ${
                            props.data.gCo2Constant.lng
                        }`}
                        qa={qa.totalCostOfOwnership.table.sumLng.gCo2Constant}
                    >
                        <i className="fa fa-question" />
                    </Tooltip>
                </Col>
                <Col
                    className="text-right"
                    span={12}
                    data-qa={qa.totalCostOfOwnership.table.sumLng.gCo2PerUnitProduction}
                >
                    {numeral(props.data.lng.gCo2PerUnitProduction?.value).format('0,0.0')}{' '}
                    {props.data.lng.gCo2PerUnitProduction?.units &&
                        (units[props.data.lng.gCo2PerUnitProduction?.units] ??
                            props.data.lng.gCo2PerUnitProduction?.units)}
                </Col>
            </Row>
            <Row>
                <Col span={12}>{props.t('TotalCostOfOwnership.table.totalCost')}</Col>
                <Col className="text-right" span={12} data-qa={qa.totalCostOfOwnership.table.sumLng.totalCost}>
                    {numeral(props.data.lng.totalCost?.value).format('0,0.00')}{' '}
                    {props.data.lng.totalCost?.units &&
                        (units[props.data.lng.totalCost?.units] ?? props.data.lng.totalCost?.units)}
                </Col>
            </Row>

            {props.data.saving && (
                <>
                    <br />
                    <br />

                    <div className="result">
                        <h3>{props.t('common.result')}</h3>
                        <Divider />
                        <Row>
                            <Col span={12}>{props.t('TotalCostOfOwnership.sumTable.lngSaving')}</Col>
                            <Col className="text-right" span={12}>
                                {numeral(props.data.saving.absolute).format('0,0.00')}{' '}
                                {props.data.saving.units && (units[props.data.saving.units] ?? props.data.saving.units)}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>{props.t('TotalCostOfOwnership.sumTable.lngSaving')}</Col>
                            <Col className="text-right" span={12}>
                                {props.data.saving.percentage} %
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </div>
    );
}

export default withTranslation()(TCOSumTable);
