import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Ccr from './components/Ccr';
import { CcrModel } from 'logic/partner/logic/partner-ccr';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PairingItemType } from 'logic/partner/partner';
import { debounce } from 'debounce';
import { searched } from 'common/utils/search';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { PairingItem, PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import { message } from 'antd';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    edit: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
    };
    table: {
        loading: boolean;
        data?: CcrModel[];
        create?: CcrModel;
        selected?: CcrModel;
        delete?: CcrModel;
    };
    pairing?: PartnerPairingModel;
}

class PartnerCcrModule extends Component<Props, State> {
    private _logic: Logic;
    items?: PairingItem[];

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            edit: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            }
        };
    }

    componentDidMount() {
        Promise.all<CcrModel[]>([this._logic.partner().ccr().getCcrList(this.state.bar.selectedCompany?.id)]).then(
            res => {
                const [data] = res;

                this.setState({
                    table: {
                        loading: false,
                        data
                    }
                });
            }
        );
    }

    render() {
        return (
            <Ccr
                edit={this.state.edit}
                loading={this.state.table.loading}
                data={this.state.table.data}
                create={this.state.table.create}
                selected={this.state.table.selected}
                delete={this.state.table.delete}
                pairing={this.state.pairing}
                companies={this.state.bar.companies}
                selectedCompany={this.state.bar.selectedCompany}
                onTableRowSelect={this._onTableRowSelect}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onDetailFormCancel={this._onDetailFormCancel}
                onDetailFormSubmit={this._onDetailFormSubmit}
                onDeleteConfirm={this._onDeleteConfirm}
                onDeleteCancel={this._onDeleteCancel}
                onCreateSubmit={this._onCreateSubmit}
                onCreateCancel={this._onCreateCancel}
                onPairingKeySelect={this._onPairingKeySelect}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearch={this._onPairingSearch}
                onPairingConfirm={this._onPairingConfirm}
                onPairingCancel={this._onPairingCancel}
            />
        );
    }

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onPairingCancel = (): void => {
        this.items = undefined;
        this.setState({ pairing: undefined });
    };

    private _onPairingConfirm = (): void => {
        const selected = this.state.table?.selected;
        const key = this.state.pairing?.keySelected;
        const linked = this.state.pairing?.items!.find(c => c.id === this.state.pairing!.itemIdSelected);

        if (selected && key && linked) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.companies
            };
            const target: PairingItemType = {
                id: linked?.id,
                key: key
            };

            this._logic
                .partner()
                .pair(source, target)
                .then(_res => {
                    this.setState(state => ({ pairing: undefined, table: { ...state.table, loading: false } }));
                })
                .catch(err => {
                    message.error(this.props.t('common.error.loadDataError'));
                    console.error(`Paring error, err: ${err}`);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                });
        }
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                loading: true
            }
        }));
        switch (this.state.pairing?.keySelected) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(partners => {
                        this.items = partners.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                const filtered = this.items?.filter(c => searched(text, c.value));
                const itemIdSelected = filtered?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                this.setState(state => ({
                    pairing: {
                        ...state.pairing!,
                        search: text ? text : undefined,
                        itemIdSelected,
                        items: filtered ?? undefined,
                        loading: false
                    }
                }));
            }
        }
    }, 500);

    private _onPairingItemSelect = (itemId: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                itemIdSelected: itemId
            }
        }));
    };

    private _onPairingKeySelect = (key: PartnerPairingKey): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                keySelected: key,
                items: undefined,
                loading: true
            }
        }));
        switch (key) {
            case PartnerPairingKey.companies:
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(companies => {
                        this.items = companies.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            default:
                break;
        }
    };

    private _onDetailFormCancel = (): void => {
        this.setState({
            edit: false
        });
    };

    private _onDetailFormSubmit = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                selected: undefined
            }
        }));
    };

    private _getCcr = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this._logic
            .partner()
            .ccr()
            .getCcrList()
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data,
                        loading: false
                    }
                }));
            });
    };

    private _onDeleteConfirm = (): void => {
        if (this.state.table?.delete) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            this.props.logic
                .partner()
                .ccr()
                .deleteCcr(this.state.table?.delete)
                .then(deleted => {
                    if (deleted) {
                        this._getCcr();
                    }
                });
        }
    };

    private _onDeleteCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, delete: undefined }
        }));
    };

    private _onCreateSubmit = (): void => {
        this.setState(state => ({
            table: { ...state.table, create: undefined }
        }));
    };

    private _onCreateCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, create: undefined }
        }));
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                },
                table: {
                    data: undefined,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getCcr();
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    loading: true,
                    data: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getCcr();
            }
        );
    };

    private _onTableRowSelect = (id: string): void => {
        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                selected: state.table?.selected?.id === id ? undefined : state.table?.data?.find(d => d.id === id)
            }
        }));
    };
}

export default withTranslation()(withRouter(PartnerCcrModule));
