import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Widget from 'common/components/Widget';
import { PieChart } from 'common/components/Visualisations';
import { Rate } from 'common/components';
import cn from 'classnames';
import numeral from 'numeral';

export interface DriverScoreDataModel {
    stars: number;
    percentage: number;
}

interface Props extends WithTranslation {
    trend?: number;
    value?: number;
    currency?: string;
    data: DriverScoreDataModel[];
    loading: boolean;
    onClick?: () => void;
}

function DriverScoreWidget(props: Props) {
    return (
        <Widget
            loading={props.loading}
            title={props.t('CompanyProfileDashboard.driverScores')}
            topRight={
                <>
                    {props.value && (
                        <div className="widget-value">
                            <span className="widget-value-prefix">{props.t('CompanyProfileDashboard.avg')}</span>
                            <span className="widget-value-main">
                                {numeral(props.value).format('0.0')}
                                <Rate size="default" color="yellow" value={1} disabled />
                            </span>
                        </div>
                    )}
                    {props.trend && (
                        <div
                            className={cn('widget-trend', {
                                'widget-trend-decreasing': props.trend < 0,
                                'widget-trend-increasing': props.trend > 0
                            })}
                        >
                            <i className="fa fa-line-chart" />
                            {props.trend}%
                        </div>
                    )}
                </>
            }
            content={
                props.data.length === 0 ? (
                    <div className="widget-empty">{props.t('utils.empty')}</div>
                ) : (
                    <PieChart
                        data={props.data.map(d => ({
                            name: <Rate size="xsmall" color="yellow" value={d.stars} disabled allowHalf />,
                            value: d.percentage
                        }))}
                    />
                )
            }
            onClick={props.onClick}
        />
    );
}

export default withTranslation()(DriverScoreWidget);
