import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { VehicleIdentification } from 'common/components/Settings';
import { AvailableCurrencies } from 'common/model/currency';
import { FleetModel, FleetType } from 'modules/management/modules/fleet/FleetModule';
import { FuelCompany } from 'modules/management/modules/fuel-cards/FuelCardsModule';
import { TrailerObject } from 'common/model/tracking';

import {
    Create_TrailerDocument,
    Create_TrailerMutation,
    Create_TrailerMutationVariables,
    Delete_TrailerDocument,
    Delete_TrailerMutation,
    Delete_TrailerMutationVariables,
    FuelCompaniesDocument,
    FuelCompaniesQuery,
    FuelCompaniesQueryVariables,
    FuelCompany as GQLFuelCompany,
    MonitoredObject,
    ETrailerType
} from '../../generated/graphql';
import { Role } from '../auth';
import { Logic } from '../logic';
import { VehicleGroups } from './logic/vehicle-groups';
import {
    MonitoredObjectProfileFuelTypeEnum,
    MonitoredObjectTrailerProfileTypeEnum,
    ReadOnlyMonitoredObject,
    ReadOnlyMonitoredObjectType,
    ReadOnlyMonitoredObjectNested,
    ReadOnlyMonitoredObjectFeFleet,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyUser,
    WriteOnlyVehicleProfileSerializer,
    ReadOnlyVehicleProfileSerializer,
    WriteOnlyVehicleProfileSerializerEuroClassEnum,
    TemperatureSensor
} from 'generated/new-main';
import moment from 'moment';
import { VehicleTypes } from './logic/vehicles-types';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { ExternalDeviceType, MonitoredObjectFleetDynamicData } from 'generated/backend-api-live';
import { VehicleProfileModel } from 'common/model/transports';

interface CostPerKm {
    currency: string;
    cost: number;
}

export interface VehicleModel {
    configuration?: any;
    costPerKm: number;
    customLabel: string;
    currency?: AvailableCurrencies;
    height?: { v: number; md: boolean };
    id: number;
    length?: { v: number; md: boolean };
    manufacturer: string;
    metadata?: any;
    name: string;
    note: string;
    numberOfAxles?: { v: number; md: boolean };
    registrationNumber: string;
    roles?: Role[];
    tankSize: number;
    trailers: TrailerObject[];
    trailersCount?: number;
    trailersNames: string[];
    tunnel?: { v: string; md: boolean };
    type: FleetType;
    weight?: { v: number; md: boolean };
    weightFull?: { v: number; md: boolean };
    width?: { v: number; md: boolean };
    optimalRpm?: { v: number; md: boolean };
    disabledAt?: string;
}

export interface FuelCard {
    id: number;
    number: string;
    fuelCompanyId: number | string;
}

export interface FuelCardInputModel {
    number: string;
    fuelCompanyId: number | string;
}

export interface VehiclesTable {
    data: VehicleModel[];
}

export class VehiclesLogic {
    private _vehicleGroups?: VehicleGroups;
    private _vehicleTypes?: VehicleTypes;
    private readonly _apollo: ApolloClient<NormalizedCacheObject>;
    private _vehicleIdentification?: VehicleIdentification;
    private _logic: Logic;
    private _drivers: ReadOnlyUser[];
    private _fleetDynamicData: MonitoredObjectFleetDynamicData[];
    fullDWL: boolean;
    fleet: FleetModel[];
    monitoredObjectsSimple: ReadOnlyMonitoredObjectFeSb[];

    constructor(apollo: ApolloClient<NormalizedCacheObject>, logic: Logic) {
        this._logic = logic;
        this._apollo = apollo;
        this.fullDWL = false;
        this.fleet = [];
        this._fleetDynamicData = [];
        this.monitoredObjectsSimple = [];
        this._drivers = [];
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');
    }

    vehicleGroups() {
        if (!this._vehicleGroups) {
            this._vehicleGroups = new VehicleGroups(this._logic);
        }
        return this._vehicleGroups;
    }

    vehicleTypes() {
        if (!this._vehicleTypes) {
            this._vehicleTypes = new VehicleTypes(this._logic);
        }
        return this._vehicleTypes;
    }

    destroy() {
        this.fleet = [];
        this._fleetDynamicData = [];
        this.monitoredObjectsSimple = [];
    }

    async getMonitoredObjectFilters(
        all: boolean = false,
        includeShared: boolean = false,
        roles: Role[] | undefined = undefined,
        includeDisabled: boolean = false,
        withoutTrailers: boolean = true,
        withoutDWL: boolean = false
    ): Promise<ReadOnlyMonitoredObjectFeSb[]> {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.vehiclesSimple;
        }

        try {
            this.monitoredObjectsSimple = await this._logic.api().monitoredObjectApi.monitoredObjectFeMoFeSb({
                all,
                includeShared,
                includeDisabled,
                obuPermissions: roles?.toString()
                // monitoredObjectTypes: withoutTrailers
                //     ? Object.keys(FleetType)
                //           .filter(type => type !== 'TRAILER')
                //           .toString()
                //     : undefined
            });

            if (this.monitoredObjectsSimple.every(mo => mo.roles?.includes(Role.DWL))) {
                this.fullDWL = true;
            }
            if (withoutDWL) {
                this.monitoredObjectsSimple = this.monitoredObjectsSimple.filter(
                    monitoredObject => !monitoredObject.roles?.includes(Role.DWL)
                );
            }

            return this.monitoredObjectsSimple
                .filter(mo => {
                    if (withoutTrailers) {
                        return mo.fleetType !== FleetType.TRAILER;
                    }

                    return mo;
                })
                .sort((a, b) => {
                    if (
                        this._logic.settings().getProp('vehicleIdentification') ===
                        VehicleIdentification.RegistrationNumber
                    ) {
                        return a.registrationNumber > b.registrationNumber ? 1 : -1;
                    } else {
                        return (a.customLabel ?? a.registrationNumber) > (b.customLabel ?? b.registrationNumber)
                            ? 1
                            : -1;
                    }
                });
        } catch (err) {
            console.error('Get monitored object filters err, err: ', JSON.stringify(err));
            return [];
        }
    }

    async getClientMonitoredObjects() {
        try {
            return await this._logic.api().monitoredObjectApi.monitoredObjectList({
                all: true
            });
        } catch (err) {
            console.error('Get client monitored objects err, err: ', JSON.stringify(err));
            throw err;
        }
    }

    async getClientMonitoredObjectsIncludeShared(roles: Role[]) {
        try {
            return await this._logic.api().monitoredObjectApi.monitoredObjectList({
                includeShared: true,
                obuPermissions: roles ? roles.toString() : undefined
            });
        } catch (err) {
            console.error('Get client monitored objects err, err: ', JSON.stringify(err));
            throw err;
        }
    }

    async setActiveMonitoredObject(id: number, active: boolean) {
        try {
            return await this._logic.api().monitoredObjectApi.monitoredObjectPartialUpdate({
                data: {
                    moid: id,
                    disabledAt: active ? null : moment().toDate()
                },
                id: id
            });
        } catch (err) {
            console.error('Disable monitored object err, err: ', JSON.stringify(err));
            throw err;
        }
    }

    changeVehicleIdentification(vehicleIdentification: VehicleIdentification) {
        this._vehicleIdentification = vehicleIdentification;
    }

    // ---------------------
    // FUEL
    // ---------------------
    async fuelCompanies(): Promise<FuelCompany[]> {
        const toFuelCompany = (f: GQLFuelCompany): FuelCompany => ({
            id: f.id ?? '',
            name: f.name ?? '',
            label: f.label ?? ''
        });

        const companies = await this._apollo
            .query<FuelCompaniesQuery, FuelCompaniesQueryVariables>({
                query: FuelCompaniesDocument,
                variables: {}
            })
            .then(res => res.data.get_FuelCompanies);

        return companies?.map(toFuelCompany) ?? [];
    }

    // ---------------------
    // VEHICLES
    // ---------------------

    async updateMonitoredObject(fleetModel: FleetModel) {
        return await this._logic.api().monitoredObjectApi.monitoredObjectPartialUpdate({
            id: fleetModel.id,
            data: {
                moid: fleetModel.id,
                customLabel: fleetModel.name,
                description: fleetModel.note, // TODO: same as note, delete in future
                note: fleetModel.note,
                tankSize: fleetModel.tankSize,
                trailerIdSerialNumber: fleetModel.trailerIdSerialNumber ? fleetModel.trailerIdSerialNumber : undefined,
                costPerKm: fleetModel.costPerKm
                    ? { currency: fleetModel.currency, cost: fleetModel.costPerKm }
                    : undefined,
                profile:
                    fleetModel.type === FleetType.VEHICLE || fleetModel.type === FleetType.LIGHT_VEHICLE
                        ? {
                              euroClass: fleetModel.emissionClass,
                              height: fleetModel.height,
                              length: fleetModel.length,
                              numberOfAxles: fleetModel.numberOfAxles,
                              trailersCount: fleetModel.trailersCount,
                              tunnel: fleetModel.tunnel,
                              vin: fleetModel.vin,
                              weight: fleetModel.weight,
                              width: fleetModel.width,
                              fuelType: fleetModel.fuelType as unknown as MonitoredObjectProfileFuelTypeEnum,
                              optimalRpm: fleetModel.optimalRpm
                          }
                        : undefined,
                trailerProfile:
                    fleetModel.type === FleetType.TRAILER
                        ? {
                              type: fleetModel.trailerType as unknown as MonitoredObjectTrailerProfileTypeEnum,
                              cargoType: fleetModel.cargoType,
                              height: fleetModel.height,
                              length: fleetModel.length,
                              weightEmpty: fleetModel.weight,
                              weightFull: fleetModel.weightFull,
                              width: fleetModel.width
                          }
                        : undefined,
                temperatureSensors: fleetModel.temperatureSensors?.map(
                    sensor =>
                        ({
                            id: sensor.id,
                            sensorName: sensor.sensorName,
                            sensorType: sensor.sensorType,
                            sensorZone: sensor.sensorZone,
                            serialNumber: sensor.serialNumber
                        } as TemperatureSensor)
                )
            }
        });
    }

    vehiclesData(roles: Role[] = []): Promise<VehicleModel[]> {
        if (this._logic.demo().isActive) {
            return new Promise(resolve =>
                resolve(this._logic.demo().data.vehicles.map(mo => this.toVehicleModelFromReadOnlyMonitoredObject(mo)))
            );
        }
        return this._vehiclesIncludeSharedData(roles, false);
    }

    vehiclesIncludeSharedData(roles: Role[] = [], includeDischarged: boolean = true): Promise<VehicleModel[]> {
        if (this._logic.demo().isActive) {
            return new Promise(resolve =>
                resolve(this._logic.demo().data.vehicles.map(mo => this.toVehicleModelFromReadOnlyMonitoredObject(mo)))
            );
        }
        return this._vehiclesIncludeSharedData(roles, includeDischarged);
    }

    private async _vehiclesIncludeSharedData(
        roles: Role[] = [],
        includeDisabled: boolean = true
    ): Promise<VehicleModel[]> {
        try {
            const vehicles = await this._logic.vehicles().getClientMonitoredObjectsIncludeShared(roles);

            return (
                vehicles?.results
                    .filter(v => {
                        return includeDisabled
                            ? [1, 2, 8].includes(v.monitoredObjectTypeId ?? 1)
                            : [1, 2, 8].includes(v.monitoredObjectTypeId ?? 1) && !v.disabledAt;
                    })
                    .map(this.toVehicleModelFromReadOnlyMonitoredObject) ?? []
            );
        } catch (err) {
            console.error('GET Monitored objects err', err);
            return [];
        }
    }

    // ---------------------
    // FLEET
    // ---------------------

    getFleetVehicles = async (): Promise<FleetModel[]> => {
        if (this._logic.demo().isActive) {
            let demo = [
                ...this._logic
                    .demo()
                    .data.vehicles.map(mo =>
                        this.toVehicleFleetModel(mo, this._logic.demo().data.monitoredObjectTypes)
                    ),
                ...this._logic.demo().data.trailers.map(t => ({
                    ...trailerToFleet(t, this._logic.demo().data.monitoredObjectTypes),
                    temperatureSensors: this._logic.demo().data.trailers.find(tt => t.id === tt.id)?.[
                        'temperature_sensors'
                    ]
                }))
            ];

            demo.forEach(trailer => {
                if (trailer.type === FleetType.TRAILER) {
                    const vehicle = demo.find(
                        vehicle =>
                            vehicle.type === FleetType.VEHICLE && vehicle.trailers?.find(t => t.id === trailer.id)
                    );
                    if (vehicle) {
                        trailer.links = {
                            fleet: [
                                {
                                    id: String(vehicle?.id),
                                    rn: vehicle.registrationNumber,
                                    name: vehicle.name,
                                    trailerPairingType: vehicle.trailerPairingType
                                }
                            ]
                        };
                    }
                }
            });
            return demo;
        }

        const monitoredObjects = (
            await this._logic.api().monitoredObjectApi.monitoredObjectFeMoFeFleet({
                offset: DEFAULT_PAGE_OFFSET,
                limit: INFINITY_PAGE_LIMIT,
                includeDisabled: true
            })
        ).results;

        this.fleet = monitoredObjects
            .map(monitoredObject => this.toVehicleFleetModelNew(monitoredObject))
            .sort((a, b) => {
                if (
                    this._logic.settings().getProp('vehicleIdentification') === VehicleIdentification.RegistrationNumber
                ) {
                    return a.registrationNumber > b.registrationNumber ? 1 : -1;
                } else {
                    return a.name > b.name ? 1 : -1;
                }
            });
        return this.fleet;
    };

    async getFleetDynamicData(): Promise<MonitoredObjectFleetDynamicData[]> {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.fleetDynamicData;
        }

        return await this._logic.api().fleetDynamicDataApi.getFleetDynamicDataV2ActualVehicleStateFleetDynamicDataGet();
    }

    async getFleetTableData() {
        const [fleetVehicles, fleetDynamicData, drivers, monitoredObjectsSimple] = await Promise.all([
            this.getFleetVehicles(),
            this.getFleetDynamicData(),
            this._logic.users().getUsers(INFINITY_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, undefined, 'driver'),
            this.getMonitoredObjectFilters(true, false, undefined, false, false)
        ]);

        const deviceState = await this._logic
            .coldchainLogic()
            .fetchExternalDeviceState(fleetVehicles.map(a => a.temperatureSensors?.map(b => b.id ?? '') ?? []).flat());

        this.monitoredObjectsSimple = monitoredObjectsSimple;

        if (this._logic.demo().isActive) {
            this._fleetDynamicData = this._logic.demo().data.fleetDynamicData;
            this._drivers = this._logic.demo().data.users;
        } else {
            this._fleetDynamicData = fleetDynamicData;
            this._drivers = drivers.data;
        }

        const fleetVehiclesWithTrailers: FleetModel[] = this._fleetVehiclesWithTrailers(
            fleetVehicles,
            fleetDynamicData
        );

        const tableData = fleetVehiclesWithTrailers
            ?.filter(vehicle => vehicle.type !== FleetType.TRAILER)
            ?.reduce(
                (a: FleetModel[], b: FleetModel) =>
                    a.concat(
                        b,
                        fleetVehiclesWithTrailers.filter(fleet => {
                            const vehicleDynamicData = this._fleetDynamicData.find(
                                data => Number(data.monitoredObjectId) === b.id
                            );
                            return (
                                vehicleDynamicData?.trailersDynamic.some(
                                    data => data.monitoredObjectId === String(fleet.id)
                                ) ||
                                vehicleDynamicData?.trailersManual.some(
                                    data => data.monitoredObjectId === String(fleet.id)
                                )
                            );
                        })
                    ),
                []
            )
            .concat(
                fleetVehiclesWithTrailers?.filter(
                    vehicle => vehicle.type === FleetType.TRAILER && !vehicle.links?.fleet?.length
                )
            )
            ?.map(e => ({ ...e, key: e.id }));

        this.fleet = tableData.map(monitoredObject => ({
            ...monitoredObject,
            temperatureSensors: monitoredObject.temperatureSensors?.map(sensor => ({
                ...sensor,
                temperatureValue: deviceState.find(h => h.deviceId === sensor.id)?.data?.['temperature']
            }))
        }));

        return this.fleet;
    }

    private _fleetVehiclesWithTrailers = (
        fleetVehicles: FleetModel[],
        fleetDynamicData: MonitoredObjectFleetDynamicData[]
    ): FleetModel[] => {
        return fleetVehicles?.map(vehicle => {
            const dynamicData = fleetDynamicData?.find(
                dynamicData => Number(dynamicData?.monitoredObjectId) === vehicle.id
            );
            let defaultDriver: ReadOnlyUser | undefined;

            if (this._logic.demo().isActive) {
                defaultDriver = this._logic
                    .demo()
                    .data.users.find(driver => driver.id === dynamicData?.defaultDriverId) as ReadOnlyUser;
            } else {
                defaultDriver = this._drivers.find(driver => driver.id === dynamicData?.defaultDriverId);
            }

            const pairedTrailers = fleetDynamicData?.filter(
                data =>
                    data.trailersDynamic.some(
                        trailerDynamic => trailerDynamic.monitoredObjectId === String(vehicle.id)
                    ) ||
                    data.trailersManual.some(trailerManual => trailerManual.monitoredObjectId === String(vehicle.id))
            );

            const foundVehicles = fleetVehicles.filter(vehicle =>
                pairedTrailers.some(monitoredObject => monitoredObject.monitoredObjectId === String(vehicle.id))
            );

            const trailers = [
                ...(dynamicData?.trailersDynamic ?? []).map(trailerDynamic => {
                    const trailer = fleetVehicles.find(v => v.id === Number(trailerDynamic?.monitoredObjectId));

                    return trailer
                        ? {
                              ...trailer,
                              serialNumber: trailerDynamic.serialNumber,
                              trailerIdSerialNumber: trailerDynamic.serialNumber,
                              trailerPairingType: ExternalDeviceType.TrailerId
                          }
                        : undefined;
                }),
                ...(dynamicData?.trailersManual ?? []).map(trailersManual => {
                    const trailer = fleetVehicles.find(v => v.id === Number(trailersManual?.monitoredObjectId));
                    return trailer ? { ...trailer, trailerPairingType: ExternalDeviceType.TrailerManual } : undefined;
                })
            ].filter(trailer => trailer) as FleetModel[];

            return {
                ...vehicle,
                trailers,
                trailersCount: trailers.length,
                defaultDriver: defaultDriver
                    ? {
                          driverId: defaultDriver.id,
                          name: `${defaultDriver?.name ?? ''} ${defaultDriver?.surname ?? ''}`
                      }
                    : undefined,
                trailerIdSerialNumber:
                    vehicle.trailerIdSerialNumber ??
                    (pairedTrailers.find(vehicle => vehicle.trailersDynamic.length > 0)?.trailersDynamic ?? []).find(
                        trailerDynamic => trailerDynamic?.monitoredObjectId === String(vehicle.id)
                    )?.serialNumber,
                links: {
                    ...vehicle.links,
                    trailers: trailers.map(trailer => {
                        return {
                            id: (trailer?.id ?? '').toString(),
                            rn: trailer?.registrationNumber ?? '',
                            name: trailer?.name ?? '',
                            type: trailer?.trailerType as any as ETrailerType,
                            trailerPairingType: trailer?.trailerPairingType
                        };
                    }),
                    fleet:
                        vehicle.type === FleetType.TRAILER
                            ? foundVehicles.map(foundVehicle => ({
                                  id: String(foundVehicle?.id),
                                  rn: foundVehicle?.registrationNumber ?? '',
                                  name: foundVehicle?.name ?? ''
                              }))
                            : trailers.map(trailer => {
                                  return {
                                      id: (trailer?.id ?? '').toString(),
                                      rn: trailer?.registrationNumber ?? '',
                                      name: trailer?.name ?? '',
                                      type: trailer?.trailerType as any as ETrailerType,
                                      trailerPairingType: trailer?.trailerPairingType
                                  };
                              }),
                    defaultDriver:
                        defaultDriver && dynamicData?.monitoredObjectId
                            ? {
                                  driverId: dynamicData?.defaultDriverId,
                                  name: defaultDriver?.name,
                                  surname: defaultDriver?.surname,
                                  registrationNumber: vehicle.registrationNumber,
                                  monitoredObjectId: dynamicData?.monitoredObjectId
                              }
                            : undefined
                }
            };
        });
    };

    async getFleetVehicle(id: number): Promise<FleetModel | undefined> {
        return await this._getFleetVehicle(id);
    }

    private _getFleetVehicle = async (id: number): Promise<FleetModel | undefined> => {
        try {
            const [vehicleTypes, monitoredObjectsResponse] = await Promise.all([
                this.vehicleTypes().getMonitoredObjectsTypes(),
                this._logic.api().monitoredObjectApi.monitoredObjectNestedSingle({
                    id
                })
            ]);
            const vehicle = monitoredObjectsResponse;

            if (vehicle) {
                return this.toVehicleFleetModel(vehicle, vehicleTypes);
            }
            return undefined;
        } catch (err) {
            console.error('Monitored object nested err:', err);
            return undefined;
        }
    };

    async updateMonitoredObjectGroupForVehicle(id: number, monitoredObjectGroupIds: number[]) {
        try {
            await this._logic.api().monitoredObjectApi.monitoredObjectReplaceMonitoredObjectGroups({
                data: {
                    i: monitoredObjectGroupIds
                },
                id
            });

            return true;
        } catch (err) {
            console.error('Update monitored object group for vehicle err: ', err);
            throw err;
        }
    }

    async getUnpairedVehicles(): Promise<ReadOnlyMonitoredObjectFeSb[]> {
        if (this.monitoredObjectsSimple.length === 0) {
            await this.getMonitoredObjectFilters(true, false, undefined, false, false);
        }

        return this.monitoredObjectsSimple.filter(vehicle => {
            const vehicleDynamicData = this._fleetDynamicData.find(
                fleetDynamicData => fleetDynamicData.monitoredObjectId === String(vehicle.id)
            );
            return (
                (vehicle.fleetType as FleetType) === FleetType.VEHICLE &&
                (!vehicleDynamicData ||
                    (vehicleDynamicData?.trailersManual?.length === 0 &&
                        vehicleDynamicData?.trailersDynamic?.length === 0))
            );
        });
    }

    async getUnpairedTrailers(): Promise<ReadOnlyMonitoredObjectFeSb[]> {
        if (this.monitoredObjectsSimple.length === 0) {
            await this.getMonitoredObjectFilters(true, false, undefined, false, false);
        }

        return this.monitoredObjectsSimple.filter(
            trailer =>
                (trailer.fleetType as FleetType) === FleetType.TRAILER &&
                !this._fleetDynamicData.some(
                    fleetDynamicData =>
                        fleetDynamicData.trailersManual?.some(
                            trailerManual => trailerManual.monitoredObjectId === String(trailer.id)
                        ) ||
                        fleetDynamicData.trailersDynamic?.some(
                            trailerDynamic => trailerDynamic.monitoredObjectId === String(trailer.id)
                        )
                )
        );
    }

    async createTrailer(model: FleetModel): Promise<boolean> {
        const trailerVariables: Create_TrailerMutationVariables = {
            licensePlateNumber: model.registrationNumber,
            manufacturerName: model.manufacturer,
            ownName: model.name,
            trailerProfile: {
                cargoType: model.cargoType ?? '',
                height: model.height ?? 0,
                length: model.length ?? 0,
                numberOfAxles: model.numberOfAxles ?? 0,
                type: (model.trailerType ?? MonitoredObjectTrailerProfileTypeEnum.CONTAINER) as unknown as ETrailerType,
                weightEmpty: model.weight ?? 0,
                weightFull: model.weightFull ?? 0,
                width: model.width ?? 0
            }
        };
        return await this._createTrailer(trailerVariables);
    }

    async createVehicleProfile(
        vehicleProfile: VehicleProfileModel
    ): Promise<ReadOnlyVehicleProfileSerializer | undefined> {
        const vehicleProfileData: WriteOnlyVehicleProfileSerializer = {
            height: vehicleProfile.height,
            name: vehicleProfile.name,
            numberOfAxles: vehicleProfile.numberOfAxles,
            weight: vehicleProfile.weight,
            pricePerKm: vehicleProfile.pricePerKm ?? 0,
            euroClass: String(vehicleProfile.euroClass) as WriteOnlyVehicleProfileSerializerEuroClassEnum,
            length: vehicleProfile.length,
            trailersCount: vehicleProfile.trailersCount,
            tunnel: vehicleProfile.tunnel,
            width: vehicleProfile.width,
            client: this._logic.auth().client()?.id!
        };
        try {
            const result = await this._logic.api().vehicleProfileApi.vehicleProfileCreate({
                data: vehicleProfileData
            });
            return result;
        } catch (err) {
            console.error(`Could not create vehicle profiles, err: ${err}`);
        }
        return undefined;
    }

    async updateVehicleProfile(
        vehicleProfile: VehicleProfileModel
    ): Promise<ReadOnlyVehicleProfileSerializer | undefined> {
        const vehicleProfileData: WriteOnlyVehicleProfileSerializer = {
            height: vehicleProfile.height,
            name: vehicleProfile.name,
            numberOfAxles: vehicleProfile.numberOfAxles,
            weight: vehicleProfile.weight,
            pricePerKm: vehicleProfile.pricePerKm ?? 0,
            euroClass: String(vehicleProfile.euroClass) as WriteOnlyVehicleProfileSerializerEuroClassEnum,
            length: vehicleProfile.length,
            trailersCount: vehicleProfile.trailersCount,
            tunnel: vehicleProfile.tunnel,
            width: vehicleProfile.width,
            client: this._logic.auth().client()?.id!
        };
        try {
            const result = await this._logic.api().vehicleProfileApi.vehicleProfileUpdate({
                id: Number(vehicleProfile?.id!),
                data: vehicleProfileData
            });
            return result;
        } catch (err) {
            console.error(`Could not update vehicle profiles, err: ${err}`);
        }
        return undefined;
    }

    async deleteVehicleProfile(id: number): Promise<ReadOnlyVehicleProfileSerializer | undefined> {
        try {
            await this._logic.api().vehicleProfileApi.vehicleProfileDelete({
                id: id
            });
            return;
        } catch (err) {
            console.error(`Could not delete vehicle profiles, err: ${err}`);
        }
        return undefined;
    }

    private _createTrailer = async (input: Create_TrailerMutationVariables): Promise<boolean> => {
        const created = await this._apollo
            .mutate<Create_TrailerMutation, Create_TrailerMutationVariables>({
                mutation: Create_TrailerDocument,
                variables: { ...input }
            })
            .then(res => res.data?.create_Trailer);

        return Boolean(created);
    };

    async deleteTrailer(id: number): Promise<boolean> {
        return await this._deleteTrailer(id);
    }

    private _deleteTrailer = async (id: number): Promise<boolean> => {
        const deleted = await this._apollo
            .mutate<Delete_TrailerMutation, Delete_TrailerMutationVariables>({
                mutation: Delete_TrailerDocument,
                variables: { id }
            })
            .then(res => res.data?.delete_Trailer);

        return Boolean(deleted);
    };

    async pairDriverToMonitoredObject(monitoredObjectId: string, driverId: number) {
        return await this._logic
            .api()
            .fleetDynamicDataApi.setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPost(
                {
                    monitoredObjectId,
                    driverId
                }
            );
    }

    async pairTrailerToVehicle(vehicleId: number, trailerId: number): Promise<boolean> {
        const paired = await this._logic
            .api()
            .fleetDynamicDataApi.setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPost(
                {
                    monitoredObjectId: String(vehicleId),
                    monitoredObjectFleetDynamicDataExternalDevices: [
                        {
                            deviceId: String(trailerId),
                            deviceType: ExternalDeviceType.TrailerManual
                        }
                    ]
                }
            );

        return Boolean(paired);
    }

    async unpairDriverFromVehicle(monitoredObjectId: string) {
        return await this._logic
            .api()
            .fleetDynamicDataApi.setDefaultDriverV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdDefaultDriverPost(
                {
                    monitoredObjectId: monitoredObjectId,
                    driverId: undefined
                }
            );
    }

    async unpairTrailerVehicle(vehicleId: number, trailerId: number) {
        const fleetDynamicData = this._fleetDynamicData.find(
            dynamicData => dynamicData.monitoredObjectId === String(vehicleId)
        );
        const dynamicTrailerData = fleetDynamicData?.trailersDynamic.find(
            dynamicTrailer => dynamicTrailer.monitoredObjectId === String(trailerId)
        );
        if (dynamicTrailerData?.monitoredObjectId && dynamicTrailerData.serialNumber) {
            return Boolean(
                await this._logic
                    .api()
                    .fleetDynamicDataApi.unpairExternalDeviceV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdUnpairExternalDevicePost(
                        {
                            monitoredObjectId: String(vehicleId),
                            serialNumber: dynamicTrailerData.serialNumber
                        }
                    )
                    .catch(() => {
                        throw new Error(
                            `Could not unpair objects with ids: "trailerId: ${trailerId}", vehicleId: ${vehicleId}`
                        );
                    })
            );
        } else {
            return Boolean(
                await this._logic
                    .api()
                    .fleetDynamicDataApi.setExternalDevicesV2ActualVehicleStateFleetDynamicDataMonitoredObjectIdExternalDevicesPost(
                        {
                            monitoredObjectId: String(vehicleId),
                            monitoredObjectFleetDynamicDataExternalDevices: []
                        }
                    )
                    .catch(() => {
                        throw new Error(
                            `Could not unpair objects with ids: "trailerId: ${trailerId}", vehicleId: ${vehicleId}`
                        );
                    })
            );
        }
    }

    // ---------------------
    // DATA TRANSFORMATION
    // ---------------------

    toVehicleFleetModelNew(vehicle: ReadOnlyMonitoredObjectFeFleet): FleetModel {
        const isTrailer = vehicle.monitoredObjectType?.id === 2;
        return {
            id: vehicle.id ?? 0,
            tankSize: vehicle.tankSize ?? 0,
            fuelCards: vehicle.fuelCards,
            cargoType: vehicle.metadata?.trailerProfile?.cargoType ?? '',
            registrationNumber: vehicle.registrationNumber,
            name: vehicle.customLabel ?? '',
            manufacturer: vehicle.manufacturerName ?? '',
            serialNumber: vehicle.monitoringDevice?.serialNumber ?? '',
            note: '',
            toll: [],
            links: {
                fuelCard: vehicle.fuelCards.map(card => ({
                    number: card.number,
                    id: card.id,
                    note: card.note
                }))
            },
            type:
                vehicle.monitoredObjectType?.id === 1
                    ? FleetType.VEHICLE
                    : vehicle.monitoredObjectType?.id === 8
                    ? FleetType.LIGHT_VEHICLE
                    : vehicle.monitoredObjectType?.id === 2
                    ? FleetType.TRAILER
                    : FleetType.VEHICLE,
            disabledAt: vehicle.disabledAt ?? undefined,
            monitoredObjectGroups: vehicle.monitoredObjectGroups,
            services: (vehicle.monitoringDevice?.permissions as any)?.roles ?? [],

            fuelType: vehicle.metadata?.profile?.fuelType?.v,
            emissionClass: vehicle.metadata?.profile?.emissionClass?.v ?? '',

            height: isTrailer
                ? vehicle.metadata?.trailerProfile?.height ?? 0
                : vehicle.metadata?.profile?.height?.v ?? 0,
            length: isTrailer
                ? vehicle.metadata?.trailerProfile?.length ?? 0
                : vehicle.metadata?.profile?.length?.v ?? 0,
            weight: isTrailer ? vehicle.metadata?.trailerProfile?.weightEmpty : vehicle.metadata?.profile?.weight?.v,
            width: isTrailer ? vehicle.metadata?.trailerProfile?.width ?? 0 : vehicle.metadata?.profile?.width?.v ?? 0,
            weightFull: isTrailer
                ? vehicle.metadata?.trailerProfile?.weightFull ?? 0
                : vehicle.metadata?.profile?.weightFull?.v ?? ((vehicle.metadata as any)?.weightF3 ?? 0) / 1000,
            optimalRpm: isTrailer ? undefined : vehicle.metadata?.profile?.optimalRpm?.v,

            md: {
                fuelType: vehicle.metadata?.profile?.fuelType?.md ?? false,
                width: vehicle.metadata?.profile?.width?.md ?? false,
                height: vehicle.metadata?.profile?.height?.md ?? false,
                length: vehicle.metadata?.profile?.length?.md ?? false,
                tunnel: vehicle.metadata?.profile?.tunnel?.md ?? false,
                weight: vehicle.metadata?.profile?.weight?.md ?? false,
                weightFull: vehicle.metadata?.profile?.weightFull?.md ?? true,
                numberOfAxles: vehicle.metadata?.profile?.numberOfAxles?.md ?? false,
                trailersCount: vehicle.metadata?.profile?.trailersCount?.md ?? false,
                emissionClass: vehicle.metadata?.profile?.emissionClass?.md ?? false,
                vin: vehicle.metadata?.profile?.vin?.md ?? false,
                optimalRpm: vehicle.metadata?.profile?.optimalRpm?.md ?? false
            },
            monitoredObjectType: vehicle.monitoredObjectType,
            numberOfAxles: isTrailer
                ? vehicle.metadata?.trailerProfile?.numberOfAxles ?? 0
                : vehicle.metadata?.profile?.numberOfAxles?.v ?? 0,
            trailerType: vehicle.metadata?.trailerProfile?.type,
            trailersCount: vehicle.metadata?.profile?.trailersCount.v ?? 0,
            tunnel: vehicle.metadata?.profile?.tunnel?.v ?? '',
            vin: vehicle.metadata?.profile?.vin?.v ?? '',
            trailerIdSerialNumber: vehicle.metadata?.trailerIdSerialNumber,
            temperatureSensors: vehicle.temperatureSensors
        };
    }

    toVehicleFleetModel(vehicle: ReadOnlyMonitoredObjectNested, type: ReadOnlyMonitoredObjectType[]): FleetModel {
        return {
            cargoType: undefined,
            monitoredObjectGroups: [], // in toVehicleFleetModelNew
            fuelType: vehicle.metadata?.profile?.fuelType?.v,
            countryOfRegistration: '',
            emissionClass: vehicle.metadata?.profile?.emissionClass?.v ?? '',
            weightFull: vehicle.metadata?.profile?.weightFull?.v ?? ((vehicle.metadata as any)?.weightF3 ?? 0) / 1000,
            height: vehicle.metadata?.profile?.height?.v ?? 0, // JSON!
            id: vehicle.id ?? 0,
            length: vehicle.metadata?.profile?.length?.v ?? 0, // JSON!
            manufacturer: vehicle.manufacturerName ?? '',
            serialNumber: vehicle.monitoringDevice?.serialNumber ?? '',
            md: {
                fuelType: vehicle.metadata?.profile?.fuelType?.md ?? false,
                width: vehicle.metadata?.profile?.width?.md ?? false,
                height: vehicle.metadata?.profile?.height?.md ?? false,
                length: vehicle.metadata?.profile?.length?.md ?? false,
                tunnel: vehicle.metadata?.profile?.tunnel?.md ?? false,
                weight: vehicle.metadata?.profile?.weight?.md ?? false,
                weightFull: vehicle.metadata?.profile?.weightFull?.md ?? true,
                numberOfAxles: vehicle.metadata?.profile?.numberOfAxles?.md ?? false,
                trailersCount: vehicle.metadata?.profile?.trailersCount?.md ?? false,
                emissionClass: vehicle.metadata?.profile?.emissionClass?.md ?? false,
                vin: vehicle.metadata?.profile?.vin?.md ?? false,
                optimalRpm: vehicle.metadata?.profile?.optimalRpm?.md ?? false
            },
            name: vehicle.customLabel ?? '',
            note: '',
            numberOfAxles: vehicle.metadata?.profile?.numberOfAxles?.v ?? 0, // JSON!
            registrationNumber: vehicle.registrationNumber ?? '',
            sensors: undefined,
            services: (vehicle.monitoringDevice?.permissions as any)?.roles ?? [],
            tankSize: vehicle.tankSize ?? undefined,
            toll: [],
            trailerType: vehicle.metadata?.trailerProfile?.type, // JSON!
            tunnel: vehicle.metadata?.profile?.tunnel?.v ?? '', // JSON!
            type:
                vehicle.monitoredObjectTypeId === 1
                    ? FleetType.VEHICLE
                    : vehicle.monitoredObjectTypeId === 8
                    ? FleetType.LIGHT_VEHICLE
                    : FleetType.VEHICLE, // MFR 11.5.20
            monitoredObjectType: type.find(t => t.id === vehicle.monitoredObjectTypeId),
            vin: vehicle.metadata?.profile?.vin?.v ?? '', // JSON!
            weight: vehicle.metadata?.profile?.weight?.v,
            width: vehicle.metadata?.profile?.width?.v ?? 0, // JSON!
            optimalRpm: vehicle.metadata?.profile?.optimalRpm?.v,
            disabledAt: vehicle.disabledAt ?? undefined,
            trailerIdSerialNumber: vehicle.metadata?.trailerIdSerialNumber
        };
    }

    toVehicleModelFromReadOnlyMonitoredObject = (
        vehicle: ReadOnlyMonitoredObject | ReadOnlyMonitoredObjectNested
    ): VehicleModel => {
        const type =
            vehicle.monitoredObjectTypeId === 1
                ? FleetType.VEHICLE
                : vehicle.monitoredObjectTypeId === 8
                ? FleetType.LIGHT_VEHICLE
                : FleetType.TRAILER;

        return {
            id: vehicle.id ?? 0,
            name: vehicle.registrationNumber ?? '',
            configuration: vehicle._configuration,
            customLabel: vehicle.customLabel ?? '',
            registrationNumber:
                this._vehicleIdentification === VehicleIdentification.CustomLabel && vehicle.customLabel
                    ? vehicle.customLabel
                    : vehicle.registrationNumber ?? '',
            trailers: [],
            trailersNames: [],
            manufacturer: vehicle.manufacturerName ?? '',
            metadata: vehicle.metadata,
            type,
            costPerKm: 0,
            tankSize: vehicle.tankSize ?? 0,
            note: vehicle.description ?? '',
            height: vehicle.metadata?.profile?.height?.v
                ? {
                      md: vehicle.metadata?.profile?.height.md,
                      v: vehicle.metadata?.profile?.height.v
                  }
                : undefined,
            weight: vehicle.metadata?.profile?.weight?.v
                ? {
                      md: vehicle.metadata?.profile?.weight.md,
                      v: vehicle.metadata?.profile?.weight.v
                  }
                : undefined,
            length: vehicle.metadata?.profile?.length
                ? {
                      md: vehicle.metadata?.profile?.length.md,
                      v: vehicle.metadata?.profile?.length.v ?? 0
                  }
                : undefined,
            numberOfAxles: vehicle.metadata?.profile?.numberOfAxles?.v
                ? {
                      md: vehicle.metadata?.profile?.numberOfAxles.md,
                      v: vehicle.metadata?.profile?.numberOfAxles.v
                  }
                : undefined,
            tunnel: vehicle.metadata?.profile?.tunnel?.v
                ? {
                      md: vehicle.metadata?.profile?.tunnel.md,
                      v: vehicle.metadata?.profile?.tunnel.v
                  }
                : undefined,
            width: vehicle.metadata?.profile?.width.v
                ? {
                      md: vehicle.metadata?.profile?.width.md,
                      v: vehicle.metadata?.profile?.width.v
                  }
                : undefined,
            optimalRpm: vehicle.metadata?.profile?.optimalRpm.v
                ? {
                      md: vehicle.metadata?.profile?.optimalRpm.md,
                      v: vehicle.metadata?.profile?.optimalRpm.v
                  }
                : undefined,
            weightFull: {
                md: true,
                v: vehicle.metadata?.profile?.weightFull?.v ?? ((vehicle.metadata as any)?.weightF3 ?? 0) / 1000
            },
            trailersCount: vehicle.metadata?.profile?.trailersCount.v,
            roles: [],
            disabledAt: vehicle.disabledAt ? moment(vehicle.disabledAt).toISOString() : undefined
        };
    };

    toVehicleModel = (vehicle: MonitoredObject): VehicleModel => {
        const {
            id,
            registrationNumber,
            customLabel,
            description,
            manufacturerName,
            vehicleProfile,
            metadata,
            roles,
            Trailers,
            configuration,
            disabledAt
        } = vehicle;

        // JSON type from GQL
        const costPerKm = vehicle.costPerKm as CostPerKm;
        const tankSize = vehicle.tankSize ?? 0;
        const name = vehicleProfile?.name ?? '';
        const type =
            vehicle.monitoredObjectTypeId === 1
                ? FleetType.VEHICLE
                : vehicle.monitoredObjectTypeId === 8
                ? FleetType.LIGHT_VEHICLE
                : FleetType.TRAILER; // MFR 11.5.20

        return {
            id: id ?? 0,
            name,
            configuration: configuration,
            customLabel: customLabel ?? '',
            registrationNumber:
                this._vehicleIdentification === VehicleIdentification.CustomLabel && customLabel
                    ? customLabel
                    : registrationNumber ?? '',
            trailers:
                Trailers?.map(t => ({
                    id: String(t.id ?? ''),
                    customLabel: t.customLabel ?? '',
                    registrationNumber: t.registrationNumber ?? ''
                })) ?? [],
            trailersNames:
                Trailers?.map(t =>
                    this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                        ? t.registrationNumber ?? ''
                        : (t.customLabel ?? '') || (t.registrationNumber ?? '')
                ) ?? [],
            manufacturer: manufacturerName ?? '',
            metadata: metadata,
            costPerKm: costPerKm.cost,
            tankSize,
            type,
            note: description ?? '',
            height: metadata?.profile?.height ?? '',
            weight: metadata?.profile?.weight ?? '',
            length: metadata?.profile?.length ?? '',
            numberOfAxles: metadata?.profile?.numberOfAxles ?? '',
            trailersCount: metadata?.profile?.trailersCount ?? '',
            tunnel: metadata?.profile?.tunnel ?? '',
            width: metadata?.profile?.width ?? '',
            roles: (roles as Role[]) ?? [],
            disabledAt: disabledAt ?? ''
        };
    };
}

const trailerToFleet = (m: MonitoredObject, type: ReadOnlyMonitoredObjectType[]): FleetModel => ({
    cargoType: m.metadata?.trailerProfile?.cargoType ?? '',
    countryOfRegistration: '',
    emissionClass: m.metadata?.trailerProfile?.emissionClass ?? '', // JSON!
    fuelCards: [],
    weightFull: m.metadata?.trailerProfile?.weightFull ?? 0,
    height: m.metadata?.trailerProfile?.height ?? 0, // JSON!
    id: m.id ?? 0,
    length: m.metadata?.trailerProfile?.length ?? 0, // JSON!
    manufacturer: m.manufacturerName ?? '',
    serialNumber: '',
    md: {
        fuelType: m.metadata?.profile?.fuelType?.md ?? false,
        width: m.metadata?.profile?.width?.md ?? false,
        height: m.metadata?.profile?.height?.md ?? false,
        length: m.metadata?.profile?.length?.md ?? false,
        tunnel: m.metadata?.profile?.tunnel?.md ?? false,
        weight: m.metadata?.profile?.weight?.md ?? false,
        weightFull: m.metadata?.profile?.weightFull?.md ?? true,
        numberOfAxles: m.metadata?.profile?.numberOfAxles?.md ?? false,
        trailersCount: m.metadata?.profile?.trailersCount?.md ?? false,
        emissionClass: m.metadata?.profile?.emissionClass?.md ?? false,
        vin: m.metadata?.profile?.vin?.md ?? false,
        optimalRpm: m.metadata?.profile?.optimalRmp?.md ?? false
    },
    name: m.customLabel ?? '',
    note: '',
    numberOfAxles: m.metadata?.trailerProfile?.numberOfAxles ?? 0, // JSON!
    registrationNumber: m.registrationNumber ?? '',
    sensors: undefined,
    services: m.roles ?? [],
    tankSize: m.tankSize ?? 0,
    toll: [],
    trailersCount: m.Trailers?.length,
    trailerType: m.metadata?.trailerProfile?.type ?? '', // JSON!
    tunnel: m.metadata?.trailerProfile?.tunnel ?? '', // JSON!
    type: FleetType.TRAILER,
    monitoredObjectType: type.find(t => t.id === m.monitoredObjectTypeId),
    vin: '',
    weight: m.metadata?.trailerProfile?.weightEmpty ?? 0, // JSON!
    width: m.metadata?.trailerProfile?.width ?? 0 // JSON!,
});
