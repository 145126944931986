/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobileApp,
    MobileAppFromJSON,
    MobileAppFromJSONTyped,
    MobileAppToJSON,
    MobileDevice,
    MobileDeviceFromJSON,
    MobileDeviceFromJSONTyped,
    MobileDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyMobileDeviceHasMobileAppNested
 */
export interface ReadOnlyMobileDeviceHasMobileAppNested {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileDeviceHasMobileAppNested
     */
    id?: string;
    /**
     * 
     * @type {MobileDevice}
     * @memberof ReadOnlyMobileDeviceHasMobileAppNested
     */
    mobileDevice: MobileDevice;
    /**
     * 
     * @type {MobileApp}
     * @memberof ReadOnlyMobileDeviceHasMobileAppNested
     */
    mobileApp: MobileApp;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMobileDeviceHasMobileAppNested
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMobileDeviceHasMobileAppNested
     */
    upgradedAt?: Date | null;
}

export function ReadOnlyMobileDeviceHasMobileAppNestedFromJSON(json: any): ReadOnlyMobileDeviceHasMobileAppNested {
    return ReadOnlyMobileDeviceHasMobileAppNestedFromJSONTyped(json, false);
}

export function ReadOnlyMobileDeviceHasMobileAppNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMobileDeviceHasMobileAppNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mobileDevice': MobileDeviceFromJSON(json['mobile_device']),
        'mobileApp': MobileAppFromJSON(json['mobile_app']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'upgradedAt': !exists(json, 'upgraded_at') ? undefined : (json['upgraded_at'] === null ? null : new Date(json['upgraded_at'])),
    };
}

export function ReadOnlyMobileDeviceHasMobileAppNestedToJSON(value?: ReadOnlyMobileDeviceHasMobileAppNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mobile_device': MobileDeviceToJSON(value.mobileDevice),
        'mobile_app': MobileAppToJSON(value.mobileApp),
        'upgraded_at': value.upgradedAt === undefined ? undefined : (value.upgradedAt === null ? null : value.upgradedAt.toISOString()),
    };
}


