import { google } from 'google-maps';
import { PoiModelMap } from './fuelStations';

import { AlarmType } from 'generated/backend-api';
import * as AlarmIcons from 'resources/images/alarms';
import { fsBestPriceInFuelTypes } from 'common/utils/best-price';
import * as mapMarkers from 'resources/images/map';
import numeral from 'numeral';

export interface PoiMarkerData {
    type: 'parking' | 'fuelStation' | 'poi' | 'alarm' | 'wash' | 'toll';
    distance?: number;
    data: PoiModelMap;
}

const OverlayView = () => {
    return window.google?.maps?.OverlayView ?? null;
};

export class PoiMarker extends OverlayView() {
    readonly _google: google;
    position: google.maps.LatLng;

    private _poiData: PoiMarkerData;
    private _container?: HTMLDivElement;
    private _content?: HTMLDivElement;
    private _onPoiClick?: (data: PoiMarkerData) => void;

    constructor(google: google, position: google.maps.LatLng, data: PoiMarkerData) {
        super();
        this.position = position;
        this._google = google;
        this._poiData = data;
    }

    onPoiClick(cb: (data: PoiMarkerData) => void) {
        this._onPoiClick = cb;
    }

    id() {
        return this._poiData.data.id;
    }

    data(): PoiModelMap {
        return this._poiData.data;
    }

    setData(data: PoiModelMap) {
        this._poiData.data = data;
        this._updateAnchorData();
    }

    onAdd() {
        this._container = this._createContainer();
        // Typings doesn't include this function
        const gm = this._google.maps.OverlayView as any;
        gm.preventMapHitsAndGesturesFrom(this._container);
        this.getPanes()?.floatPane.appendChild(this._container);
    }

    draw() {
        if (!this._container) return;

        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        const display = Math.abs(divPosition?.x) < 4000 && Math.abs(divPosition?.y) < 4000 ? 'block' : 'none';

        if (display === 'block') {
            this._container.style.left = divPosition?.x + 'px';
            this._container.style.top = divPosition?.y + 'px';
        }

        if (this._container.style.display !== display) {
            this._container.style.display = display;
        }
    }

    onRemove() {
        if (!this._container) return;

        if (this._container.parentElement) {
            this._container.parentElement.removeChild(this._container);
        }
    }

    getDraggable() {
        return false;
    }

    getPosition() {
        return this.position;
    }

    open() {
        if (!this._container) return;

        if (this._onPoiClick) {
            this._onPoiClick(this._poiData);
        }
    }

    private _updateAnchorData() {
        const [el] = document.querySelectorAll(`[data-fs-id="${this._poiData.data.id}"]`);
        if (el) {
            if (this._poiData.data.selected) {
                el.classList.add('selected');
            } else {
                el.classList.remove('selected');
            }
            const bestPriceInFuelTypes =
                this._poiData.data?.fuelTypes && fsBestPriceInFuelTypes(this._poiData.data?.fuelTypes);

            const result = `${this._poiData.data.bestPrice ? ' ' : ''}${numeral(
                Number(bestPriceInFuelTypes?.price?.price || 0)
            ).format('0,0.00')} ${bestPriceInFuelTypes?.price?.currency}`;

            el.setAttribute('title', bestPriceInFuelTypes ? result : '');
        }
    }

    private _createContainer() {
        this._content = document.createElement('div');
        this._content.classList.add('poi-marker-bubble');

        const anchorIcon = document.createElement('img');
        switch (this._poiData.type) {
            case 'fuelStation':
                anchorIcon.src = mapMarkers.fuelStation;
                break;
            case 'parking':
                anchorIcon.src = mapMarkers.parking;
                break;
            case 'wash':
                anchorIcon.src = mapMarkers.wash;
                break;
            case 'poi':
                anchorIcon.src = mapMarkers.poi;
                break;
            case 'alarm':
                anchorIcon.src = this.getAlarmIcon();
                break;
            case 'toll':
                anchorIcon.src = mapMarkers.toll;
                break;
            default:
                anchorIcon.src = mapMarkers.parking;
                break;
        }

        const anchor = document.createElement('div');
        anchor.addEventListener('click', () => {
            this.open();
        });

        if (this._poiData.data.selected) {
            anchor.classList.add('selected');
        }

        if (this._poiData.type === 'fuelStation') {
            const bestPriceInFuelTypes =
                this._poiData.data?.fuelTypes && fsBestPriceInFuelTypes(this._poiData.data?.fuelTypes);

            const result = `${this._poiData.data.bestPrice ? ' ' : ''}${numeral(
                Number(bestPriceInFuelTypes?.price?.price || 0)
            ).format('0,0.00')} ${bestPriceInFuelTypes?.price?.currency}`;

            anchor.setAttribute('data-fs-id', this._poiData.data.id);
            anchor.setAttribute('title', bestPriceInFuelTypes ? result : '');
            anchor.classList.add('fs-cluster');
            anchor.classList.add('fs-cluster-icon');
        } else if (this._poiData.type === 'parking') {
            anchor.setAttribute('title', '');
            anchor.classList.add('p-cluster');
            anchor.classList.add('p-cluster-icon');
        } else if (this._poiData.type === 'wash') {
            anchor.setAttribute('title', '');
            anchor.classList.add('wash-cluster');
            anchor.classList.add('wash-cluster-icon');
        } else if (this._poiData.type === 'poi') {
            anchor.setAttribute('title', '');
            anchor.classList.add('poi-cluster');
            anchor.classList.add('poi-cluster-icon');
        } else if (this._poiData.type === 'alarm' && this._poiData.data.alarmType) {
            anchor.classList.add('al-cluster');
            anchor.classList.add('al-cluster-icon');
        }

        anchor.appendChild(anchorIcon);
        anchor.appendChild(this._content);

        const container = document.createElement('div');
        if (this._poiData.type === 'alarm' && this._poiData.data.alarmType) {
            container.classList.add('al-poi-marker-container');
            anchor.classList.add('al-poi-marker-bubble-anchor');
        } else {
            anchor.classList.add('poi-marker-bubble-anchor');
            container.classList.add('poi-marker-container');
        }
        container.appendChild(anchor);

        return container;
    }

    private getAlarmIcon() {
        if (this._poiData.type === 'alarm' && this._poiData.data.alarmType) {
            switch (this._poiData.data.alarmType) {
                case AlarmType.UnavailableGps:
                    return AlarmIcons.noGps;
                case AlarmType.ConnectionLoss:
                    return AlarmIcons.noConnection;
                case AlarmType.BatteryLow:
                    return AlarmIcons.lowBattery;
                case AlarmType.CorridorLeave:
                    return AlarmIcons.outsideCorridor;
                case AlarmType.PoiClose:
                    return AlarmIcons.poiClose;
                case AlarmType.PoiArrival:
                    return AlarmIcons.poiArrival;
                case AlarmType.PoiDeparture:
                    return AlarmIcons.poiDeparture;
                case AlarmType.AggresiveTakeoff:
                    return AlarmIcons.acceleration;
                case AlarmType.DangerousTurnCountry:
                    return AlarmIcons.turn1;
                case AlarmType.DangerousTurnHighway:
                    return AlarmIcons.turn2;
                case AlarmType.DangerousBreakingCity:
                    return AlarmIcons.breaking1;
                case AlarmType.DangerousBreakingCountry:
                    return AlarmIcons.breaking2;
                case AlarmType.DangerousBreakingHighway:
                    return AlarmIcons.breaking3;
                case AlarmType.Overspeed:
                    return AlarmIcons.speed;
                default:
                    return '';
            }
        }
        return '';
    }
}
