import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { PaginatedResponse } from 'common/model/pagination';
import {
    GetUsersDocument,
    GetUsersQuery,
    GetUsersQueryVariables,
    User,
    UserContactsType,
    UserTokenType
} from 'generated/graphql';
import { ReadOnlyUser, WriteOnlyUser } from 'generated/new-main/models';
import { Logic } from '../logic';
import { UserGroups } from './logic/user-groups';
import { UserRights } from './logic/user-rights';
import { UserRoles } from './logic/user-roles';
import { UserTokens } from './logic/user-tokens';
import { UserModules } from './logic/user-modules';
import * as faker from 'faker';
import { UserType } from 'modules/management/modules/management-users/UsersModule';
import { DefaultDriverInfo } from 'generated/backend-api';
import { UserNestedModelModel } from 'common/model/user';
import { DEFAULT_PAGE_LIMIT_SHORT, DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { Conf } from 'conf';

export interface UserModel {
    id: string;
    ssoId: string;
    name: string;
    surname: string;
    mobile: string;
    email: string;
    tachographId: string;
    note: string;
    pin?: string;
    contacts?: UserContactsType;
    userGroups?: string[];
    tokens?: UserTokenType[];
    username?: string;
    password?: string;
    clientId?: number;
    userName?: string;
}

export interface ClientModel {
    id: number;
    name: string;
    externalId: string;
    externalSystemId: number;
    address: string;
    fieldOfActivity: string;
    language: string;
    currency: string;
    billingCode: string;
    correspondenceAddress: string;
    contact: {
        externalId: string;
        status: string;
        title: string;
        name: string;
        surname: string;
        role: string;
        phone: string;
        email: string;
    };
    emailForInvoicing: string;
    identificationNumber: string;
    vatNumber: string;
    taxNumber: string;
    bankingConnection: string;
    timezone: string;
    deleted: number;
    country: string;
}

export interface DriverModel extends UserModel {
    pin: string;
}
export interface DispatcherModel extends UserModel {}

export const toUserModel = (user: User): DriverModel => ({
    mobile: user.contact?.phone_numbers ? user.contact.phone_numbers.join(' ') : '',
    email: user.contact?.emails ? user.contact.emails.join(' ') : '',
    tachographId: user.Tokens?.[0]?.token ?? '',
    id: String(user.id ?? 0),
    ssoId: user.sso_id ?? '',
    name: user.name ?? '',
    note: user.note ?? '',
    surname: user.surname ?? '',
    userName: user.userName ?? '',
    pin: user.loginCredentials ?? '',
    userGroups: [...new Set(user.Groups?.map(g => g.name ?? ''))],
    tokens: user.Tokens?.filter(t => !t.deleted),
    clientId: user.client_id ?? undefined
});

export class UserLogic {
    private _apollo: ApolloClient<NormalizedCacheObject>;

    private _userGroups?: UserGroups;
    private _userRights?: UserRights;
    private _userRoles?: UserRoles;
    private _userTokens?: UserTokens;
    private _userModules?: UserModules;

    defaultDriversInfo: DefaultDriverInfo[];

    constructor(apollo: ApolloClient<NormalizedCacheObject>, private _logic: Logic) {
        this._apollo = apollo;
        this.defaultDriversInfo = [];
    }

    groups() {
        if (!this._userGroups) {
            this._userGroups = new UserGroups(this._logic);
        }
        return this._userGroups;
    }

    rights() {
        if (!this._userRights) {
            this._userRights = new UserRights(this._logic);
        }
        return this._userRights;
    }

    roles() {
        if (!this._userRoles) {
            this._userRoles = new UserRoles(this._logic);
        }
        return this._userRoles;
    }

    tokens() {
        if (!this._userTokens) {
            this._userTokens = new UserTokens(this._logic);
        }
        return this._userTokens;
    }
    modules() {
        if (!this._userModules) {
            this._userModules = new UserModules(this._logic);
        }
        return this._userModules;
    }

    async dispatchersNew() {
        try {
            const userResponse = await this._logic.api().newUserApi.userList({
                limit: INFINITY_PAGE_LIMIT,
                offset: 0,
                userGroupName: 'dispatcher'
            });
            return {
                data: userResponse.results
            };
        } catch (err) {
            console.log('Get users err', err);
            throw err;
        }
    }

    drivers(): Promise<DriverModel[]> {
        if (this._logic.demo().isActive) {
            return new Promise(resolve =>
                resolve(
                    this._logic.demo().data.users.map(u =>
                        toUserModel({
                            name: u.name,
                            surname: u.surname,
                            id: u.id,
                            sso_id: u.ssoId,
                            client_id: u.client ? Number(u.client) : undefined,
                            active: u.active ? Boolean(u.active) : undefined,
                            contact: u.contact,
                            note: u.note,
                            loginCredentials: u.loginCredentials as unknown as string
                        })
                    )
                )
            );
        }
        return this._usersQuery('driver') as Promise<DriverModel[]>;
    }

    dispatchers(): Promise<DispatcherModel[]> {
        return this._usersQuery('dispatcher') as Promise<DispatcherModel[]>;
    }

    async getUsers(
        limit: number = DEFAULT_PAGE_LIMIT_SHORT,
        offset: number = DEFAULT_PAGE_OFFSET,
        search?: string,
        userGroupName?: string
    ): Promise<PaginatedResponse<ReadOnlyUser[]>> {
        try {
            const userResponse = await this._logic.api().newUserApi.userList({
                client: this._logic.auth().client()?.id.toString(),
                limit,
                offset,
                userGroupName: userGroupName ? userGroupName : undefined,
                nameOrSurnameOrUsername: search
            });
            return {
                data: userResponse.results,
                total: userResponse.count,
                limit,
                offset
            };
        } catch (err) {
            console.error('Get users err', err);
            throw err;
        }
    }

    async getUsersNested(
        limit: number = DEFAULT_PAGE_LIMIT_SHORT,
        offset: number = DEFAULT_PAGE_OFFSET,
        search?: string,
        defaultDriverInfo: boolean = false,
        userGroupName?: string
    ): Promise<PaginatedResponse<Array<UserNestedModelModel>>> {
        if (this._logic.demo().isActive) {
            const users = this._logic.demo().data.users;
            let result = users;

            try {
                const fleetDynamicData = this._logic.demo().data.fleetDynamicData;
                const vehicles = this._logic.demo().data.vehiclesSimple;

                result = users.map(user => {
                    const fleedDynamicUserData = fleetDynamicData.find(
                        fleetDynamicData => fleetDynamicData.defaultDriverId === user.id
                    );

                    return {
                        ...user,
                        defaultDriver: [
                            {
                                driverId: fleedDynamicUserData?.defaultDriverId,
                                monitoredObjectId: fleedDynamicUserData?.monitoredObjectId,
                                name: user.name,
                                surname: user.surname,
                                registrationNumber: vehicles.find(
                                    vehicle => vehicle.id === Number(fleedDynamicUserData?.monitoredObjectId)
                                )?.registrationNumber
                            }
                        ]
                    };
                });
            } catch (err) {
                console.error('Default vehicle error', err);
            }
            return {
                data: result,
                limit,
                offset,
                total: result.length
            };
        }
        try {
            const userResponse = await this._logic.api().newUserApi.userNested({
                client: this._logic.auth().client()?.id.toString(),
                limit,
                offset,
                nameOrSurnameOrUsername: search,
                userGroupName: userGroupName ? userGroupName : undefined
            });
            let result = userResponse.results;
            try {
                const fleetDynamicData = await this._logic
                    .api()
                    .fleetDynamicDataApi.getFleetDynamicDataV2ActualVehicleStateFleetDynamicDataGet();
                const vehicles = await this._logic
                    .vehicles()
                    .getMonitoredObjectFilters(true, true, undefined, false, false);

                result =
                    defaultDriverInfo && userResponse.count > 0
                        ? userResponse.results.map(user => {
                              const fleedDynamicUserData = fleetDynamicData.filter(
                                  fleetDynamicData => fleetDynamicData.defaultDriverId === user.id
                              );
                              return {
                                  ...user,
                                  defaultDriver:
                                      fleedDynamicUserData.length > 0
                                          ? fleedDynamicUserData.map(dynamicData => ({
                                                driverId: dynamicData?.defaultDriverId,
                                                monitoredObjectId: dynamicData?.monitoredObjectId,
                                                name: user.name,
                                                surname: user.surname,
                                                registrationNumber: vehicles.find(
                                                    vehicle => String(vehicle.id) === dynamicData?.monitoredObjectId
                                                )?.registrationNumber
                                            }))
                                          : []
                              };
                          })
                        : userResponse.results;
            } catch (err) {
                console.error('Default vehicle error', err);
            }
            return {
                data: result,
                total: userResponse.count,
                limit,
                offset
            };
        } catch (err) {
            console.error('Get users err', err);
            throw err;
        }
    }

    async createUser(user: WriteOnlyUser): Promise<ReadOnlyUser> {
        return await this._createUser(user);
    }

    async deleteUser({ ssoId }: ReadOnlyUser | UserModel): Promise<void> {
        await this._deleteUser(ssoId);
    }

    async updateUser(user: WriteOnlyUser): Promise<ReadOnlyUser> {
        return await this._updateUser(user);
    }

    destroy() {}

    async inviteToEwFleetDriver(user: ReadOnlyUser): Promise<boolean> {
        try {
            if (!user.ssoId) {
                throw new Error('No ssoId provided');
            }
            await this._logic
                .api()
                .newUserApi.userEwDriverRegistrationLinkCreateRaw({ ssoId: user.ssoId, sendSms: true });
            return true;
        } catch (err) {
            console.error('Invite to EW FLEET DRIVER err', err);
            throw err;
        }
    }

    async getUsersForPlaceOfWork(): Promise<ReadOnlyUser[]> {
        try {
            if (this._logic.demo().isActive) {
                return [];
            } else {
                // const response = await this._logic.api().newUserApi.userRead({

                // });
                return mockedDataNewApiData;
            }
        } catch (err) {
            console.error('User list for poi err:', err);
            throw err;
        }
    }

    async resetPassword(ssoId: string) {
        try {
            await this._logic.api().newUserApi.userResetPassword({
                ssoId: ssoId
            });
        } catch (err) {
            console.error('Password reset err: ', err);
            throw err;
        }
    }

    setSettings(settings: Partial<Conf['settings']['management']['users']>) {
        const originalSettings = this._logic.settings().getProp('management').users;
        const modifiedSettings: Conf['settings']['management']['users'] = {
            ...originalSettings,
            // filter: UserFilter.Users, // settings.filter,
            lastCreated: settings.lastCreated,
            lastCreatedAt: settings.lastCreatedAt
        };
        this._logic
            .settings()
            .setProp('management', { ...this._logic.settings().getProps().management, users: modifiedSettings });
    }

    async updateMonitoredObjectGroupForUser(ssoId: string, monitoredObjectGroupIds: number[]) {
        try {
            await this._logic.api().newUserApi.userReplaceMonitoredObjectGroups({
                data: {
                    i: monitoredObjectGroupIds
                },
                ssoId
            });

            return true;
        } catch (err) {
            console.error('Update monitored object group for user err: ', err);
            throw err;
        }
    }

    private async _createUser(userInput: WriteOnlyUser): Promise<ReadOnlyUser> {
        try {
            const response = await this._logic.api().newUserApi.userCreate({
                data: {
                    ...userInput,
                    clientId: this._logic.auth().client()?.id
                }
            });
            return response;
        } catch (err) {
            console.error('Create user err', err);
            throw err;
        }
    }

    private async _updateUser(userInput: WriteOnlyUser): Promise<ReadOnlyUser> {
        try {
            if (!userInput.ssoId) {
                throw new Error('No sso id');
            }
            return await this._logic.api().newUserApi.userPartialUpdate({
                data: userInput,
                ssoId: userInput.ssoId
            });
        } catch (err) {
            console.error('Update user err', err);
            throw err;
        }
    }

    private async _deleteUser(ssoId?: string | null): Promise<boolean> {
        try {
            if (!ssoId) {
                throw new Error('No sso id');
            }
            const res = await this._logic.api().newUserApi.userDelete({
                ssoId
            });

            return Boolean(res);
        } catch (err) {
            console.error('Delete user err', err);
            throw err;
        }
    }

    private async _usersQuery(userGroup?: string): Promise<Array<DispatcherModel | DriverModel>> {
        try {
            const res = await this._apollo.query<GetUsersQuery, GetUsersQueryVariables>({
                query: GetUsersDocument,
                fetchPolicy: 'no-cache',
                variables: { filter: { userGroup } }
            });

            return res.data?.get_Users?.map(toUserModel) ?? [];
        } catch (err) {
            console.error('Get users err', err);
            throw err;
        }
    }
}

export const mockedDataNewApiData: Array<ReadOnlyUser> = Array(4)
    .fill(null)
    .map<ReadOnlyUser>(() => ({
        email: faker.internet.email(),
        deleted: 0,
        active: 1,
        averageSpeed: 0,
        id: faker.datatype.number(),
        mobile: faker.phone.phoneNumber(),
        username: faker.internet.userName(),
        name: faker.name.firstName(),
        surname: faker.name.lastName(),
        note: faker.random.words(),
        pin: faker.datatype.number({ max: 9999, min: 1000 }).toString(),
        ssoId: faker.datatype.uuid(),
        tachographId: faker.datatype.number().toString(),
        contacts: {
            emails: Array(faker.datatype.number({ max: 10 }))
                .fill(null)
                .map(() => faker.internet.email()),
            phone_numbers: Array(faker.datatype.number({ max: 10 }))
                .fill(null)
                .map(() => faker.phone.phoneNumber())
        },
        password: faker.internet.password(),
        tokens: undefined,
        address: `${faker.address.countryCode()}, ${faker.address.streetAddress(true)}`,
        userGroups: [faker.random.arrayElement<UserType>(['dispatcher', 'driver'])],
        workLocation: Array(faker.datatype.number({ min: 1, max: 3 }))
            .fill(null)
            .map(() => faker.address.countryCode())
    }))
    .sort((a, b) => (a.name > b.name ? -1 : 1))
    .sort(a => (a.userRoles?.some(r => r.label === 'driver') ? -1 : 1))
    .sort(a => (a.userRoles?.some(r => r.label === 'dispatcher') ? -1 : 1));
