export const afcL100km = (liters: number | null | undefined, meters: number | null | undefined): number => {
    if (liters && meters) {
        return Math.round((liters / mTokm(meters)) * 1000) / 10;
    } else {
        return 0;
    }
};
export const afcL100km_Km = (liters: number | null | undefined, km: number | null | undefined): number => {
    if (liters && km) {
        return Math.round((liters / km) * 1000) / 10;
    } else {
        return 0;
    }
};
export const mTokm = (meters: number | null | undefined): number => {
    return meters ? Math.round(meters / 100) / 10 : 0;
};

export const afcLh = (liters: number | null | undefined, seconds: number | null | undefined): number => {
    if (liters && seconds) {
        return Math.round((liters / seconds) * 3600 * 10) / 10;
    } else {
        return 0;
    }
};

export function roundToStep(value: number, step: number = 1.0) {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
}

export function roundToDecimals(value: number, scale: number = 1) {
    return Math.round((value + Number.EPSILON) * Math.pow(10, scale)) / Math.pow(10, scale);
}
