import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import qs from 'querystring';

import { MaintenanceNotificationInDb } from 'generated/backend-api';
import Notifications from './ui/Notifications';
import { Logic } from 'logic/logic';
import { RouteNames } from 'App';
import { RouteParams } from 'modules/statistics/modules/maintenance/MaintenanceModule';
import { DriverModel } from 'logic/user/users';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

interface Props extends RouteComponentProps<RouteParams> {
    logic: Logic;
    open: boolean;
    onTaskOpenClick: () => void;
}

interface State {
    notifications: MaintenanceNotificationInDb[];
    fleet: ReadOnlyMonitoredObjectFeSb[];
    drivers: DriverModel[];
}

class MaintenanceNotificationsModule extends Component<Props, State> {
    logic: Logic;

    constructor(props: Props) {
        super(props);
        this.logic = this.props.logic;
        this.state = {
            fleet: [],
            drivers: [],
            notifications: []
        };
    }

    componentDidMount() {
        Promise.all([
            this.props.logic.users().drivers(),
            this.props.logic.vehicles().getMonitoredObjectFilters(false, false, undefined, false, false)
        ]).then(res => {
            const [drivers, fleet] = res;
            this.props.logic.maintenanceNotifications().maintenanceNotificationsUpdates.subscribe(() => {
                this.setState({
                    fleet,
                    drivers,
                    notifications: this.props.logic.maintenanceNotifications().getAllMaintenanceNotifications()
                });
            });
            this.props.logic.maintenanceNotifications().init();
        });
    }

    render() {
        return (
            <Notifications
                demoMode={this.logic.demo().isActive}
                open={this.props.open}
                drivers={this.state.drivers}
                fleet={this.state.fleet}
                notifications={this.state.notifications}
                onMarkAsSeen={this._onMarkAsSeen}
                onTaskOpen={this._onTaskOpen}
            />
        );
    }

    private _onTaskOpen = (id: string) => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_MAINTENANCE,
            search: qs.stringify({
                task_id: id
            } as RouteParams)
        });
        this.props.onTaskOpenClick?.();
    };

    private _onMarkAsSeen = (id: string) => {
        this.setState(state => ({
            notifications: state.notifications.map(notification =>
                notification.taskId === id ? { ...notification, acknowledged: true } : notification
            )
        }));

        this.logic.maintenanceNotifications().markNotificationAsSeen([id]);
    };
}

export default withRouter(MaintenanceNotificationsModule);
