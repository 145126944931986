import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Col, Row } from 'antd';
import { CostFleet } from 'logic/statistics/statistics-company-profile';
import { MonitoredObjectFleetType } from 'generated/backend-api';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import Button from 'common/components/Button';

export enum VehiclesTarget {
    All = 'all',
    Selected = 'selected',
    Single = 'single'
}

interface Props extends WithTranslation {
    data?: {
        currentCostsFleet?: CostFleet[];
        marketAverageFleet?: CostFleet[];
        lastUpdate: string;
    };
    costs: boolean;
    vehicles?: {
        label: string;
        code: string;
        checked: boolean;
    }[];
    vehiclesTarget: VehiclesTarget;
    loading?: boolean;
    demoMode?: boolean;
    roles: Role[];
    onVehiclesTargetChange?: (vehiclesTarget: VehiclesTarget) => void;
    onVehiclesChange?: (vehiclesChecked: string[]) => void;
    onAddServices?: () => void;
    onSetCosts?: () => void;
    onFixedCostsEditChange?: () => void;
    setFixedCosts?: () => void;
    setOperationalCosts?: () => void;
    onActionsTotalCostOfOwnership?: () => void;
}

interface State {
    vehicles?: {
        code: string;
        label: string;
        checked: boolean;
    }[];
}

class CompanyProfileActions extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="company-profile-actions">
                <div className="company-profile-actions-list">
                    {this.props.data && (
                        <div className="company-profile-actions-summary">
                            <div className="company-profile-actions-current-costs">
                                <Row align="middle" gutter={10}>
                                    <Col flex="40px">
                                        <img
                                            className="company-profile-actions-current-costs-icon"
                                            src="/img-svg/tag.svg"
                                            alt={this.props.t('CompanyProfile.marketAverage')}
                                        />
                                    </Col>
                                    <Col flex="auto">
                                        <div className="company-profile-actions-current-costs-text">
                                            {t('CompanyProfile.marketAverage')}:{' '}
                                        </div>
                                        {this.props.data.marketAverageFleet?.map((c, i) => (
                                            <Row key={i}>
                                                <Col flex="30px">
                                                    {c.fleetType === MonitoredObjectFleetType.Vehicle && (
                                                        <img
                                                            className="company-profile-actions-current-costs-fleet-icon"
                                                            src="/img-map/panel-vehicle-selected.svg"
                                                            alt={t('common.vehicle')}
                                                        />
                                                    )}
                                                    {c.fleetType === MonitoredObjectFleetType.LightVehicle && (
                                                        <img
                                                            className="company-profile-actions-current-costs-fleet-icon"
                                                            src="/img-map/panel-light-vehicle-selected.svg"
                                                            alt={t('common.vehicle')}
                                                        />
                                                    )}
                                                </Col>
                                                <Col
                                                    flex="auto"
                                                    className="company-profile-actions-current-costs-value"
                                                >
                                                    <span
                                                        data-qa={
                                                            qa.companyProfile.costsCalculator.marketAverage[c.fleetType]
                                                                ?.fieldValue
                                                        }
                                                    >
                                                        {numeral(c.value).format('0,0.00')}
                                                    </span>{' '}
                                                    <span
                                                        data-qa={
                                                            qa.companyProfile.costsCalculator.marketAverage[c.fleetType]
                                                                ?.fieldCurrency
                                                        }
                                                    >
                                                        {c.currency}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                            <div className="company-profile-actions-current-costs">
                                <Row align="middle" gutter={5}>
                                    <Col flex="40px">
                                        <img
                                            className="company-profile-actions-current-costs-icon"
                                            src="/img-svg/office.svg"
                                            alt={this.props.t('CompanyProfile.currentCostsKm')}
                                        />
                                    </Col>
                                    <Col flex="auto">
                                        <div className="company-profile-actions-current-costs-text">
                                            {t('CompanyProfile.currentCostsKm')}:{' '}
                                        </div>
                                        {this.props.data.currentCostsFleet?.map((c, i) => (
                                            <Row key={i}>
                                                <Col flex="30px">
                                                    {c.fleetType === MonitoredObjectFleetType.Vehicle ? (
                                                        <img
                                                            className="company-profile-actions-current-costs-fleet-icon"
                                                            src="/img-map/panel-vehicle-selected.svg"
                                                            alt={t('common.vehicle')}
                                                        />
                                                    ) : c.fleetType === MonitoredObjectFleetType.LightVehicle ? (
                                                        <img
                                                            className="company-profile-actions-current-costs-fleet-icon"
                                                            src="/img-map/panel-light-vehicle-selected.svg"
                                                            alt={t('common.vehicle')}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </Col>
                                                <Col
                                                    flex="auto"
                                                    className="company-profile-actions-current-costs-value"
                                                >
                                                    <span
                                                        data-qa={
                                                            qa.companyProfile.costsCalculator.currentCosts[c.fleetType]
                                                                ?.fieldValue
                                                        }
                                                    >
                                                        {numeral(c.value).format('0,0.00')}
                                                    </span>{' '}
                                                    <span
                                                        data-qa={
                                                            qa.companyProfile.costsCalculator.currentCosts[c.fleetType]
                                                                ?.fieldCurrency
                                                        }
                                                    >
                                                        {c.currency}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                    <div className="company-profile-actions-heading">{t('CompanyProfile.groupActions')}</div>
                    <Button
                        block
                        disabled={this.props.loading || this.props.demoMode}
                        type="primary"
                        onClick={this.props.onFixedCostsEditChange}
                        className="company-profile-actions-dashboard"
                        qa={qa.companyProfile.costsCalculator.btnEditFixedCosts}
                    >
                        {t('CompanyProfile.editFixedCosts')}
                    </Button>
                    <Button
                        block
                        disabled={!this.props.roles.includes(Role.TCO)}
                        type="primary"
                        onClick={this.props.onActionsTotalCostOfOwnership}
                        className="company-profile-actions-total-cost-of-ownership"
                        qa={qa.companyProfile.costsCalculator.btnTotalCost}
                    >
                        {t('CompanyProfile.totalCostsOfOwnership')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CompanyProfileActions);
