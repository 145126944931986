import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CargoExpense } from 'logic/statistics/statistics-expenses';
import { SelectOpt } from 'common/components/AutoComplete';
import { ExpenseType } from 'generated/graphql';
import ExpensesForm, { ExpensesFormModel } from 'common/forms/ExpensesForm';
import { WithLogic, withLogicContext } from 'App';
import { Loading } from 'common/components';

export type CargoOptions = {
    vehicles: SelectOpt<number>[];
    suppliers: SelectOpt[];
    date: string;
    mode: ExpenseMode;
    expense?: CargoExpense;
    type?: ExpenseType;
};
export type ExpenseMode = 'EDIT' | 'CREATE';

interface Props extends WithLogic {
    mode: ExpenseMode;
    vehicleId?: number;
    vehicles: SelectOpt<number>[];
    suppliers: SelectOpt[];
    date: string;
    expense?: CargoExpense;
    type?: ExpenseType;
    hideSuppliers?: boolean;
    loading?: boolean;
    onSupplierTextChange?: (value: string) => void;
    onConfirm: (model: CargoExpense) => Promise<boolean>;
    onCancel?: () => void;
}
function ExpensesCargo(props: Props) {
    const { t } = useTranslation();
    const onConfirmClick = (data: ExpensesFormModel) => {
        return props.onConfirm({
            expenseId: data.id,
            vehicleId: data.monitoredObjectId as number,
            date: data.date as string,
            type: data.type as ExpenseType,
            supplier: data.supplier ?? '',
            quantity: (data.quantity as number).toString(),
            priceTotal: (data.priceTotal as number).toString(),
            country: data.country
        });
    };
    return (
        <div className="expenses-cargo">
            <h2>{props.mode === 'CREATE' ? t('ExpensesCargo.createCargo') : t('ExpensesCargo.editCargo')}</h2>
            {props.loading ? (
                <Loading />
            ) : (
                <>
                    <ExpensesForm
                        hideSuppliers={props.hideSuppliers}
                        lang={props.logic.auth().user().lang}
                        type={props.mode === 'EDIT' ? 'edit' : 'default'}
                        initialValues={{
                            id: props.expense?.expenseId,
                            date: props.mode === 'EDIT' ? props.expense?.date : props.date || moment().toISOString(),
                            monitoredObjectId: props.mode === 'EDIT' ? props.expense?.vehicleId : props.vehicleId,
                            priceTotal:
                                props.mode === 'EDIT' && props.expense?.priceTotal
                                    ? Number(props.expense.priceTotal)
                                    : undefined,
                            quantity:
                                props.mode === 'EDIT' && props.expense?.quantity
                                    ? Number(props.expense.quantity)
                                    : undefined,
                            supplier: props.expense?.supplier,
                            country: props.expense?.country,
                            type: props.mode === 'EDIT' ? props.expense?.type : props.type
                        }}
                        supplierOpts={props.suppliers.map(s => ({
                            label: s.label,
                            value: s.code
                        }))}
                        monitoredObjectSelectOpts={props.vehicles.map(v => ({
                            label: v.label,
                            value: v.code
                        }))}
                        onSupplierSearch={props.onSupplierTextChange}
                        onCancel={props.onCancel}
                        onSubmit={onConfirmClick}
                    />
                </>
            )}
        </div>
    );
}

export default withLogicContext(ExpensesCargo);
