import { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { NoData } from '../NoData';
import ActiveDot from './components/ActiveDot';
import GraphTooltip from './components/GraphTooltip';
import IdleDot from './components/IdleDot';
import { getGraphConfig, GraphConfigParams, SpecifycSettings } from './getGraphConfig';

interface Props extends WithTranslation {
    data: Record<string, string | number>[];
    graphConfigParams?: GraphConfigParams;
    specifycSettings?: SpecifycSettings;
    hideDots?: boolean;
    onActiveDotMount?(p: { key: string | number; value: string | number; lat: number; lon: number }, i: number): void;
    onActiveDotDestroy?(): void;
    activeGraphDotIndex?: number;
}

function SimpleLineGraph({
    data,
    graphConfigParams,
    specifycSettings,
    onActiveDotMount,
    onActiveDotDestroy,
    activeGraphDotIndex
}: Props) {
    const graphConfig = getGraphConfig(graphConfigParams, specifycSettings);

    useEffect(() => {
        return () => {
            onActiveDotDestroy?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (data.length === 0) {
        return <NoData />;
    }

    return (
        <ResponsiveContainer className={specifycSettings?.qa}>
            <LineChart data={data} margin={graphConfig.marging}>
                <XAxis dataKey={graphConfig.dataKeyX} stroke={graphConfig.axisStroke} style={graphConfig.fontSize} />
                <YAxis stroke={graphConfig.axisStroke} style={graphConfig.fontSize} />
                <Tooltip
                    content={<GraphTooltip valueUnit={graphConfig?.tooltipValueUnit} color={graphConfig.lineStroke} />}
                />
                <Line
                    type="monotone"
                    dataKey={graphConfig.dataKeyY}
                    stroke={graphConfig.lineStroke}
                    dot={<IdleDot activeIndex={activeGraphDotIndex} strokeColor={graphConfig.lineStroke} />}
                    activeDot={
                        <ActiveDot
                            onActiveDotMount={onActiveDotMount}
                            strokeColor={graphConfig.lineStroke}
                            onActiveDotDestroy={onActiveDotDestroy}
                        />
                    }
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default withTranslation()(SimpleLineGraph);
