/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyGetPricesV1FuelstationsPricesPost,
    BodyGetPricesV1FuelstationsPricesPostFromJSON,
    BodyGetPricesV1FuelstationsPricesPostToJSON,
    FuelStationPriceList,
    FuelStationPriceListFromJSON,
    FuelStationPriceListToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SinglePriceResponseModel,
    SinglePriceResponseModelFromJSON,
    SinglePriceResponseModelToJSON,
} from '../models';

export interface ComputePricesSingleFuelstationV1FuelstationsPricesSinglePostRequest {
    fuelStation: string;
}

export interface ComputePricesV1FuelstationsPricesComputePostRequest {
    requestBody?: Array<string>;
}

export interface GetPricesV1FuelstationsPricesPostRequest {
    bodyGetPricesV1FuelstationsPricesPost?: BodyGetPricesV1FuelstationsPricesPost;
}

export interface SubscribeV1FuelstationsPricesSubscribeGetRequest {
    subscribeDevice?: string;
    subscribeUser?: string;
}

/**
 * no description
 */
export class FuelStationsApi extends runtime.BaseAPI {

    /**
     * Get price for specific fuelstation
     * Compute Prices Single Fuelstation
     */
    async computePricesSingleFuelstationV1FuelstationsPricesSinglePostRaw(requestParameters: ComputePricesSingleFuelstationV1FuelstationsPricesSinglePostRequest): Promise<runtime.ApiResponse<SinglePriceResponseModel>> {
        if (requestParameters.fuelStation === null || requestParameters.fuelStation === undefined) {
            throw new runtime.RequiredError('fuelStation','Required parameter requestParameters.fuelStation was null or undefined when calling computePricesSingleFuelstationV1FuelstationsPricesSinglePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fuelStation !== undefined) {
            queryParameters['fuel_station'] = requestParameters.fuelStation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/single`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SinglePriceResponseModelFromJSON(jsonValue));
    }

    /**
     * Get price for specific fuelstation
     * Compute Prices Single Fuelstation
     */
    async computePricesSingleFuelstationV1FuelstationsPricesSinglePost(requestParameters: ComputePricesSingleFuelstationV1FuelstationsPricesSinglePostRequest): Promise<SinglePriceResponseModel> {
        const response = await this.computePricesSingleFuelstationV1FuelstationsPricesSinglePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get prices for specific fuelstations response come later with notification update
     * Compute Prices
     */
    async computePricesV1FuelstationsPricesComputePostRaw(requestParameters: ComputePricesV1FuelstationsPricesComputePostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/compute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get prices for specific fuelstations response come later with notification update
     * Compute Prices
     */
    async computePricesV1FuelstationsPricesComputePost(requestParameters: ComputePricesV1FuelstationsPricesComputePostRequest): Promise<object> {
        const response = await this.computePricesV1FuelstationsPricesComputePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all prices currently in database for client id
     * Get All Prices
     */
    async getAllPricesV1FuelstationsPricesAvailableGetRaw(): Promise<runtime.ApiResponse<Array<FuelStationPriceList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FuelStationPriceListFromJSON));
    }

    /**
     * Get all prices currently in database for client id
     * Get All Prices
     */
    async getAllPricesV1FuelstationsPricesAvailableGet(): Promise<Array<FuelStationPriceList>> {
        const response = await this.getAllPricesV1FuelstationsPricesAvailableGetRaw();
        return await response.value();
    }

    /**
     * Get Prices
     */
    async getPricesV1FuelstationsPricesPostRaw(requestParameters: GetPricesV1FuelstationsPricesPostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyGetPricesV1FuelstationsPricesPostToJSON(requestParameters.bodyGetPricesV1FuelstationsPricesPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Prices
     */
    async getPricesV1FuelstationsPricesPost(requestParameters: GetPricesV1FuelstationsPricesPostRequest): Promise<object> {
        const response = await this.getPricesV1FuelstationsPricesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Load Base Pricelists
     */
    async loadBasePricelistsV1FuelstationsPricesResetQueuePostRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/reset-queue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Load Base Pricelists
     */
    async loadBasePricelistsV1FuelstationsPricesResetQueuePost(): Promise<object> {
        const response = await this.loadBasePricelistsV1FuelstationsPricesResetQueuePostRaw();
        return await response.value();
    }

    /**
     * Load Base Pricelists
     */
    async loadBasePricelistsV1FuelstationsPricesTodaysPricelistsPostRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/todays-pricelists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Load Base Pricelists
     */
    async loadBasePricelistsV1FuelstationsPricesTodaysPricelistsPost(): Promise<object> {
        const response = await this.loadBasePricelistsV1FuelstationsPricesTodaysPricelistsPostRaw();
        return await response.value();
    }

    /**
     * Subscribe for prices updates
     * Subscribe
     */
    async subscribeV1FuelstationsPricesSubscribeGetRaw(requestParameters: SubscribeV1FuelstationsPricesSubscribeGetRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/fuelstations/prices/subscribe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Subscribe for prices updates
     * Subscribe
     */
    async subscribeV1FuelstationsPricesSubscribeGet(requestParameters: SubscribeV1FuelstationsPricesSubscribeGetRequest): Promise<object> {
        const response = await this.subscribeV1FuelstationsPricesSubscribeGetRaw(requestParameters);
        return await response.value();
    }

}
