import { Tooltip } from 'common/components';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as CommonIcons from 'resources/images/common';

interface Props extends WithTranslation {}

function CetWarning(props: Props) {
    return (
        <div className="allowance-cet-warning">
            <Tooltip title={props.t('Allowance.cetWarning')} placement="bottomLeft">
                <img alt="warning" className="pointer" src={CommonIcons.warningYellow} />
            </Tooltip>
        </div>
    );
}

export default withTranslation()(CetWarning);
