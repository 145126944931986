/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    RouteCostsDetails,
    RouteCostsDetailsFromJSON,
    RouteCostsDetailsFromJSONTyped,
    RouteCostsDetailsToJSON,
    TransportCrossingPoint,
    TransportCrossingPointFromJSON,
    TransportCrossingPointFromJSONTyped,
    TransportCrossingPointToJSON,
    TransportEventRule,
    TransportEventRuleFromJSON,
    TransportEventRuleFromJSONTyped,
    TransportEventRuleToJSON,
    TransportPlaceEventState,
    TransportPlaceEventStateFromJSON,
    TransportPlaceEventStateFromJSONTyped,
    TransportPlaceEventStateToJSON,
    TransportPlaceTask,
    TransportPlaceTaskFromJSON,
    TransportPlaceTaskFromJSONTyped,
    TransportPlaceTaskToJSON,
    TransportStep,
    TransportStepFromJSON,
    TransportStepFromJSONTyped,
    TransportStepToJSON,
    WTMInterval,
    WTMIntervalFromJSON,
    WTMIntervalFromJSONTyped,
    WTMIntervalToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportPlace
 */
export interface TransportPlace {
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    name?: string;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof TransportPlace
     */
    addressStructured?: Array<AddressStructured>;
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    note?: string;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    rta?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TransportPlace
     */
    fixedRta?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    rtd?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    ata?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    atd?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TransportPlace
     */
    ataAtdManual?: boolean;
    /**
     * 
     * @type {object}
     * @memberof TransportPlace
     */
    center?: object;
    /**
     * 
     * @type {object}
     * @memberof TransportPlace
     */
    polygon?: object;
    /**
     * 
     * @type {Array<TransportCrossingPoint>}
     * @memberof TransportPlace
     */
    crossingPoints?: Array<TransportCrossingPoint>;
    /**
     * 
     * @type {number}
     * @memberof TransportPlace
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof TransportPlace
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TransportPlace
     */
    durationBuffer?: number;
    /**
     * 
     * @type {Array<TransportStep>}
     * @memberof TransportPlace
     */
    legs?: Array<TransportStep>;
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    route?: string;
    /**
     * 
     * @type {RouteCostsDetails}
     * @memberof TransportPlace
     */
    routeCosts?: RouteCostsDetails;
    /**
     * 
     * @type {Array<TransportEventRule>}
     * @memberof TransportPlace
     */
    eventRules?: Array<TransportEventRule>;
    /**
     * 
     * @type {Array<TransportPlaceEventState>}
     * @memberof TransportPlace
     */
    eventStates?: Array<TransportPlaceEventState>;
    /**
     * 
     * @type {object}
     * @memberof TransportPlace
     */
    metadata?: object;
    /**
     * 
     * @type {Array<TransportPlaceTask>}
     * @memberof TransportPlace
     */
    tasks?: Array<TransportPlaceTask>;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    resolvedByDriverAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof TransportPlace
     */
    estimatedDistance?: number;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    etaComputedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    eta?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportPlace
     */
    etd?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransportPlace
     */
    actualDistance?: string;
    /**
     * 
     * @type {Array<WTMInterval>}
     * @memberof TransportPlace
     */
    listOfWtmBreakRest?: Array<WTMInterval>;
}

export function TransportPlaceFromJSON(json: any): TransportPlace {
    return TransportPlaceFromJSONTyped(json, false);
}

export function TransportPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'rta': !exists(json, 'rta') ? undefined : (new Date(json['rta'])),
        'fixedRta': !exists(json, 'fixedRta') ? undefined : json['fixedRta'],
        'rtd': !exists(json, 'rtd') ? undefined : (new Date(json['rtd'])),
        'ata': !exists(json, 'ata') ? undefined : (new Date(json['ata'])),
        'atd': !exists(json, 'atd') ? undefined : (new Date(json['atd'])),
        'ataAtdManual': !exists(json, 'ata_atd_manual') ? undefined : json['ata_atd_manual'],
        'center': !exists(json, 'center') ? undefined : json['center'],
        'polygon': !exists(json, 'polygon') ? undefined : json['polygon'],
        'crossingPoints': !exists(json, 'crossingPoints') ? undefined : ((json['crossingPoints'] as Array<any>).map(TransportCrossingPointFromJSON)),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationBuffer': !exists(json, 'duration_buffer') ? undefined : json['duration_buffer'],
        'legs': !exists(json, 'legs') ? undefined : ((json['legs'] as Array<any>).map(TransportStepFromJSON)),
        'route': !exists(json, 'route') ? undefined : json['route'],
        'routeCosts': !exists(json, 'route_costs') ? undefined : RouteCostsDetailsFromJSON(json['route_costs']),
        'eventRules': !exists(json, 'eventRules') ? undefined : json['eventRules'],
        'eventStates': !exists(json, 'eventStates') ? undefined : ((json['eventStates'] as Array<any>).map(TransportPlaceEventStateFromJSON)),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TransportPlaceTaskFromJSON)),
        'resolvedByDriverAt': !exists(json, 'resolved_by_driver_at') ? undefined : (new Date(json['resolved_by_driver_at'])),
        'estimatedDistance': !exists(json, 'estimatedDistance') ? undefined : json['estimatedDistance'],
        'etaComputedAt': !exists(json, 'eta_computed_at') ? undefined : (new Date(json['eta_computed_at'])),
        'eta': !exists(json, 'eta') ? undefined : (new Date(json['eta'])),
        'etd': !exists(json, 'etd') ? undefined : (new Date(json['etd'])),
        'actualDistance': !exists(json, 'actualDistance') ? undefined : json['actualDistance'],
        'listOfWtmBreakRest': !exists(json, 'list_of_wtm_break_rest') ? undefined : ((json['list_of_wtm_break_rest'] as Array<any>).map(WTMIntervalFromJSON)),
    };
}

export function TransportPlaceToJSON(value?: TransportPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
        'note': value.note,
        'rta': value.rta === undefined ? undefined : (value.rta.toISOString()),
        'fixedRta': value.fixedRta,
        'rtd': value.rtd === undefined ? undefined : (value.rtd.toISOString()),
        'ata': value.ata === undefined ? undefined : (value.ata.toISOString()),
        'atd': value.atd === undefined ? undefined : (value.atd.toISOString()),
        'ata_atd_manual': value.ataAtdManual,
        'center': value.center,
        'polygon': value.polygon,
        'crossingPoints': value.crossingPoints === undefined ? undefined : ((value.crossingPoints as Array<any>).map(TransportCrossingPointToJSON)),
        'distance': value.distance,
        'duration': value.duration,
        'duration_buffer': value.durationBuffer,
        'legs': value.legs === undefined ? undefined : ((value.legs as Array<any>).map(TransportStepToJSON)),
        'route': value.route,
        'route_costs': RouteCostsDetailsToJSON(value.routeCosts),
        'eventRules': value.eventRules,
        'eventStates': value.eventStates === undefined ? undefined : ((value.eventStates as Array<any>).map(TransportPlaceEventStateToJSON)),
        'metadata': value.metadata,
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TransportPlaceTaskToJSON)),
        'resolved_by_driver_at': value.resolvedByDriverAt === undefined ? undefined : (value.resolvedByDriverAt.toISOString()),
        'estimatedDistance': value.estimatedDistance,
        'eta_computed_at': value.etaComputedAt === undefined ? undefined : (value.etaComputedAt.toISOString()),
        'eta': value.eta === undefined ? undefined : (value.eta.toISOString()),
        'etd': value.etd === undefined ? undefined : (value.etd.toISOString()),
        'actualDistance': value.actualDistance,
        'list_of_wtm_break_rest': value.listOfWtmBreakRest === undefined ? undefined : ((value.listOfWtmBreakRest as Array<any>).map(WTMIntervalToJSON)),
    };
}


