/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputVehicleProfile,
    InputVehicleProfileFromJSON,
    InputVehicleProfileFromJSONTyped,
    InputVehicleProfileToJSON,
    RouteWayPoint,
    RouteWayPointFromJSON,
    RouteWayPointFromJSONTyped,
    RouteWayPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoutePlannerRequestBody
 */
export interface RoutePlannerRequestBody {
    /**
     * 
     * @type {Array<RouteWayPoint>}
     * @memberof RoutePlannerRequestBody
     */
    wayPoints: Array<RouteWayPoint>;
    /**
     * 
     * @type {InputVehicleProfile}
     * @memberof RoutePlannerRequestBody
     */
    profile?: InputVehicleProfile;
    /**
     * If true, the route planner will return toll costs.
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    useToll?: boolean;
    /**
     * If true, the route planner will return toll cost segments details if available.
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    useTollDetails?: boolean;
    /**
     * If true, the route planner will return traffic information. Longer than no realistic (AVERAGE MODE).
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    useRealistic?: boolean;
    /**
     * If true, the route planner will calculate with departures specified in wayPoints.
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    useDeparture?: boolean;
    /**
     * If true, the route planner will avoid highways
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    avoidHighways?: boolean;
    /**
     * If true, the route planner will avoid ferries
     * @type {boolean}
     * @memberof RoutePlannerRequestBody
     */
    avoidFerries?: boolean;
    /**
     * If filled with ISO_3166-1_alpha-2 codes , the route planner will avoid these countries
     * @type {Array<string>}
     * @memberof RoutePlannerRequestBody
     */
    avoidCountries?: Array<string>;
    /**
     * Format is \'iso:avoid\' like \'svk:country\'     iso is alpha-3 code all lowercase: https://sk.wikipedia.org/wiki/ISO_3166-1 If routing should avoid specific types of roads. More values have to be delimited by pipe character. Values: tolls|highways|ferries|unpaved|congestioncharges
     * @type {Array<string>}
     * @memberof RoutePlannerRequestBody
     */
    avoidOptionsSygic?: Array<string>;
}

export function RoutePlannerRequestBodyFromJSON(json: any): RoutePlannerRequestBody {
    return RoutePlannerRequestBodyFromJSONTyped(json, false);
}

export function RoutePlannerRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutePlannerRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wayPoints': ((json['wayPoints'] as Array<any>).map(RouteWayPointFromJSON)),
        'profile': !exists(json, 'profile') ? undefined : InputVehicleProfileFromJSON(json['profile']),
        'useToll': !exists(json, 'useToll') ? undefined : json['useToll'],
        'useTollDetails': !exists(json, 'useTollDetails') ? undefined : json['useTollDetails'],
        'useRealistic': !exists(json, 'useRealistic') ? undefined : json['useRealistic'],
        'useDeparture': !exists(json, 'useDeparture') ? undefined : json['useDeparture'],
        'avoidHighways': !exists(json, 'avoidHighways') ? undefined : json['avoidHighways'],
        'avoidFerries': !exists(json, 'avoidFerries') ? undefined : json['avoidFerries'],
        'avoidCountries': !exists(json, 'avoidCountries') ? undefined : json['avoidCountries'],
        'avoidOptionsSygic': !exists(json, 'avoidOptionsSygic') ? undefined : json['avoidOptionsSygic'],
    };
}

export function RoutePlannerRequestBodyToJSON(value?: RoutePlannerRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wayPoints': ((value.wayPoints as Array<any>).map(RouteWayPointToJSON)),
        'profile': InputVehicleProfileToJSON(value.profile),
        'useToll': value.useToll,
        'useTollDetails': value.useTollDetails,
        'useRealistic': value.useRealistic,
        'useDeparture': value.useDeparture,
        'avoidHighways': value.avoidHighways,
        'avoidFerries': value.avoidFerries,
        'avoidCountries': value.avoidCountries,
        'avoidOptionsSygic': value.avoidOptionsSygic,
    };
}


