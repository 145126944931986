/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyContactList
 */
export interface ReadOnlyContactList {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    id?: string;
    /**
     * 
     * @type {Country}
     * @memberof ReadOnlyContactList
     */
    country?: Country;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyContactList
     */
    type: Array<ReadOnlyContactListTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    premisesNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyContactList
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyContactList
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactList
     */
    otherIdentificationNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyContactList
     */
    isNaturalPerson?: boolean | null;
}

export function ReadOnlyContactListFromJSON(json: any): ReadOnlyContactList {
    return ReadOnlyContactListFromJSONTyped(json, false);
}

export function ReadOnlyContactListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyContactList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
        'type': json['type'],
        'name': json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'house_number') ? undefined : json['house_number'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'premisesNumber': !exists(json, 'premises_number') ? undefined : json['premises_number'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'taxId': !exists(json, 'tax_id') ? undefined : json['tax_id'],
        'businessId': !exists(json, 'business_id') ? undefined : json['business_id'],
        'otherIdentificationNumber': !exists(json, 'other_identification_number') ? undefined : json['other_identification_number'],
        'isNaturalPerson': !exists(json, 'is_natural_person') ? undefined : json['is_natural_person'],
    };
}

export function ReadOnlyContactListToJSON(value?: ReadOnlyContactList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country': CountryToJSON(value.country),
        'type': value.type,
        'name': value.name,
        'city': value.city,
        'street': value.street,
        'house_number': value.houseNumber,
        'postal_code': value.postalCode,
        'premises_number': value.premisesNumber,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'vat': value.vat,
        'tax_id': value.taxId,
        'business_id': value.businessId,
        'other_identification_number': value.otherIdentificationNumber,
        'is_natural_person': value.isNaturalPerson,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyContactListTypeEnum {
    Client = 'client',
    Supplier = 'supplier',
    Consignee = 'consignee'
}


