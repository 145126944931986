/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MuStreamEditType {
    DriverId = 'driver_id',
    TripType = 'trip_type',
    OdometerSnapshot = 'odometer_snapshot',
    ExternalDevices = 'external_devices',
    ExternalDeviceUnpair = 'external_device_unpair'
}

export function MuStreamEditTypeFromJSON(json: any): MuStreamEditType {
    return MuStreamEditTypeFromJSONTyped(json, false);
}

export function MuStreamEditTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MuStreamEditType {
    return json as MuStreamEditType;
}

export function MuStreamEditTypeToJSON(value?: MuStreamEditType | null): any {
    return value as any;
}

