import React from 'react';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { PaginationParams } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT } from 'domain-constants';

interface Props extends Omit<PaginationProps, 'onChange'> {
    onChange?: (pagination: PaginationParams) => void;
}

export default function EnhancedPagination({
    showSizeChanger = false,
    pageSize = DEFAULT_PAGE_LIMIT,
    onChange,
    ...props
}: Props) {
    function onPaginationChange(page: number) {
        onChange?.({
            offset: (page - 1) * pageSize,
            limit: pageSize
        });
    }

    return (
        <Pagination
            showSizeChanger={showSizeChanger}
            defaultPageSize={pageSize}
            onChange={onPaginationChange}
            {...props}
        />
    );
}
