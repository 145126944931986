import cn from 'classnames';
import { RouteNames } from 'App';
import PoiForm from 'common/components/PoiForm';
import { RouteParams } from 'modules/statistics/modules/journeys/JourneysModule';
import { RouteParams as ColdchainRouteParams } from 'modules/statistics/modules/coldchain/ColdchainModule';
import { TrackingModel } from 'common/model/tracking';
import { EFuelType, PlaceType, PoiType } from 'generated/graphql';
import { PoiModelMap, fuelToFuelTypeMap } from 'logic/map/logic/fuelStations';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';
import moment from 'moment';
import qs from 'querystring';
import { Component, MouseEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Logic } from 'logic/logic';
import ContextMenu from './components/ContextMenu';
import Controls from './components/Controls';
import MapControlsBar, { ControlPanel } from './components/MapControlsBar';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'domain-constants';
import { LatLng } from 'common/model/geo';
import { exponea } from 'logic/exponea';
import { TransportModel } from 'common/model/transports';
import { Role } from 'logic/auth';
import { TransportPlace, TransportState } from 'generated/backend-api';
import { PlaceOfWorkUser } from 'common/model/place-of-work-user';
import { message } from 'antd';
import { PoiModel } from 'common/model/poi';
import { Conf } from 'conf';
import { roundToDecimals } from 'common/utils/averages';

export enum DisplayTypes {
    DO_NOT_DISPLAY = 'doNotDisplay',
    ON_THE_ENTIRE_MAP = 'onTheEntireMap',
    ON_ROUTE = 'onRoute'
}

export enum DataSourceTypes {
    ALL = 'all',
    EUROWAG = 'eurowag',
    IMPORTED = 'imported'
}

export enum ServiceTypes {
    TOLL = 'toll',
    WIFI = 'wifi',
    SHOWER = 'shower',
    MARKET = 'market',
    BAR = 'bar',
    ADBLUE = 'adblue'
}

export enum FuelTypes {
    DIESEL1 = 'diesel1',
    DIESEL2 = 'diesel2',
    DIESEL = 'diesel',
    BIODIESEL = 'biodiesel',
    ADBLUE = 'adblue',
    NATURAL95 = 'natural95',
    NATURAL98 = 'natural98',
    CNG = 'cng',
    ELECTRO = 'electro'
}
export enum LayerTypes {
    ROADMAP = 'roadmap',
    HYBRID = 'hybrid',
    TERRAIN = 'terrain'
}

export interface Layers {
    roadmap: boolean;
    hybrid: boolean;
    terrain: boolean;
}

export interface Services {
    // toll?: boolean;
    // wifi?: boolean;
    // market?: boolean;
    // bar?: boolean;
    wc?: boolean;
    shower?: boolean;
    food?: boolean;
    carWash?: boolean;
    special?: boolean;
}

export interface Displayed {
    doNotDisplay?: boolean;
    onTheEntireMap?: boolean;
    onRoute?: boolean;
}

export interface Fuels {
    // diesel1?: boolean;
    // diesel2?: boolean;

    diesel?: boolean;
    biodiesel?: boolean;
    adblue?: boolean;
    natural95?: boolean;
    natural98?: boolean;
    cng?: boolean;
    electro?: boolean;
}

export interface FuelControlsForm {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
    fuelData: Fuels;
}

export interface ParkingControlsForm {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
}

export const defaultFuelServiceFilter: Services = {
    // adBlue: false,
    // bar: false,
    // market: false,
    // toll: false,
    // wifi: false
    wc: false,
    shower: false,
    food: false,
    carWash: false,
    special: false
};

export const fuelTypesMap: { [key in EFuelType]: Array<keyof typeof defaultFuelsFilter> } = {
    DIESEL: ['diesel', 'biodiesel'],
    GASOLINE: ['natural95', 'natural98'],
    LNG_CNG: [],
    ELECTRO: [],
    HYBRID: [],
    HYDROGEN: []
};

export const defaultFuelsFilter: Fuels = {
    // diesel1: false,
    // diesel2: false,
    diesel: false,
    biodiesel: false,
    adblue: false,
    natural95: false,
    natural98: false,
    cng: false,
    electro: false
};

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
    logic: Logic;
    simple?: boolean;
    embedded: boolean;
}

interface State {
    vehicle?: TrackingModel;
    transport?: TransportModel;
    toggle: {
        contextMenu?: boolean;
    };
    parking: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        parkings: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    fuel: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        fuelData: Fuels;
        fuelStations: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    poi: {
        optionsData: Displayed;
        pois: PoiModelMap[];
        loading: boolean;
    };
    wash: {
        optionsData: Displayed;
        washers: PoiModelMap[];
        loading: boolean;
    };
    barMenu?: {
        panel: ControlPanel;
    };
    roles: Role[];
    controlOnRoute?: 'edit' | 'preview';
    layer: { display: LayerTypes; traffic: boolean; borderCrosses: boolean };
    poiBarVisible: boolean;
    addPoi?: {
        model: PoiModel;
        modelEdited?: PoiModel;
        placeOfWorkUsers?: PlaceOfWorkUser[];
        loading?: boolean;
    };
    contextMenu: {
        position?: {
            x: number;
            y: number;
        };
    };
    mapClickLatLng?: LatLng;
    loadingTransportPLanner: boolean;
}

class MapModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const roles: Role[] = this._logic.auth().roles();
        const settings = this.props.logic.settings().getProp('map');
        this.state = {
            toggle: {
                contextMenu: false
            },
            fuel: {
                fuelData: defaultFuelsFilter,
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: false,
                    onRoute: false
                },
                serviceData: defaultFuelServiceFilter,
                fuelStations: [],
                filterOpen: true,
                loading: true
            },
            parking: {
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: false,
                    onRoute: false
                },
                parkings: [],
                serviceData: defaultFuelServiceFilter,
                filterOpen: true,
                loading: true
            },
            wash: {
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: false,
                    onRoute: false
                },
                washers: [],
                loading: true
            },
            poi: {
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: false,
                    onRoute: false
                },
                pois: [],
                loading: true
            },
            controlOnRoute: undefined,
            poiBarVisible: false,
            layer: {
                display: settings.style,
                traffic: settings.traffic,
                borderCrosses: false
            },
            roles,
            contextMenu: {},
            loadingTransportPLanner: false
        };
    }

    componentDidMount() {
        (window as any).app.MapModul = this;

        this._logic.map().onLoading(value => {
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    loading: value
                },
                parking: {
                    ...state.parking,
                    loading: value
                },
                wash: {
                    ...state.wash,
                    loading: value
                },
                poi: {
                    ...state.poi,
                    loading: value
                }
            }));
        });

        this._logic.map().onFuelLoading(value => {
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    loading: value
                }
            }));
        });

        this._logic.map().onParkingLoading(value => {
            this.setState(state => ({
                parking: {
                    ...state.parking,
                    loading: value
                }
            }));
        });

        this._logic.map().onWashLoading(value => {
            this.setState(state => ({
                wash: {
                    ...state.wash,
                    loading: value
                }
            }));
        });

        this._logic.map().onPoiLoading(value => {
            this.setState(state => ({
                poi: {
                    ...state.poi,
                    loading: value
                }
            }));
        });

        this._logic.plannerLogic().onVehicleSet.subscribe(vehicle => {
            const defaultFuelsFilterByMoFuelType = { ...defaultFuelsFilter };
            if (vehicle.monitoredObjectFuelType) {
                const possibleFuels = fuelTypesMap[vehicle.monitoredObjectFuelType];
                possibleFuels?.forEach(f => {
                    defaultFuelsFilterByMoFuelType[f] = true;
                });

                this.setState(state => ({
                    ...state,
                    fuel: {
                        ...state.fuel,
                        fuelData: defaultFuelsFilterByMoFuelType
                    }
                }));

                if (!this.state.barMenu?.panel) {
                    this._logic.map().filterSubject.next([this.state.fuel.fuelData, this.state.fuel.serviceData]);
                }
            }
        });
        this._logic.tracking().onInitVehicles(vehicles => {
            const fuelTypes: Set<EFuelType> = new Set();
            vehicles.forEach(v => {
                if (v.monitoredObjectFuelType) {
                    fuelTypes.add(v.monitoredObjectFuelType);
                }
            });
            const defaultFuelsFilterByMoFuelType = { ...defaultFuelsFilter };
            if (fuelTypes.size > 0) {
                fuelTypes.forEach(ft => {
                    const possibleFuels = fuelTypesMap[ft];
                    possibleFuels?.forEach(f => {
                        defaultFuelsFilterByMoFuelType[f] = true;
                    });
                });

                this.setState(state => ({
                    ...state,
                    fuel: {
                        ...state.fuel,
                        fuelData: defaultFuelsFilterByMoFuelType
                    }
                }));
            }
        });

        this._logic.map().onDragEnd((_latLng: { lat?: number; lng?: number }, simple: boolean): void => {
            // when move map (drag map) set off centering
            if (!simple) {
                this._logic.tracking().centerOffVehicles();
            }
        });

        this._logic.map().onPoiToggle(value => {
            this.setState(state => ({
                poiBarVisible: value ? value : false,
                addPoi: value ? state.addPoi : undefined
            }));
        });

        this._logic.map().onFuelControlToggle(value => {
            if (value) {
                if (this.state.barMenu?.panel !== ControlPanel.FUEL) {
                    this._logic.exponea().trackEvent(exponea.module.mapFuelPanel, {
                        status: exponea.status.screenShown,
                        src: 'map'
                    });
                }
                this._logic.map().setCurrentSideBarControl(ControlPanel.FUEL);
                this.setState({
                    barMenu: { panel: ControlPanel.FUEL }
                });
            }
        });

        this._logic.map().onParkingControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.PARKING);
                this.setState({
                    barMenu: { panel: ControlPanel.PARKING }
                });
            }
        });

        this._logic.map().onWashControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.WASH);
                this.setState({
                    barMenu: { panel: ControlPanel.WASH }
                });
            }
        });

        this._logic.map().onPoiControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.POI);
                this.setState({
                    barMenu: { panel: ControlPanel.POI }
                });
            }
        });

        this._logic.map().onLayerControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.LAYER);
                this.setState({
                    barMenu: { panel: ControlPanel.LAYER }
                });
            }
        });

        this._logic.map().onVehicleControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.VEHICLE);
                this.setState({
                    barMenu: { panel: ControlPanel.VEHICLE }
                });
            }
        });

        this._logic.map().onControlsOff((control?: ControlPanel) => {
            if (!control || control === this.state.barMenu?.panel) {
                this.setState({ barMenu: undefined });
            }
        });

        this._logic.map().onControlsOffResetState(() => {
            const mapSettings = this.props.logic.settings().getProp('map');
            this.setState(state => ({
                controlOnRoute: state.controlOnRoute,
                vehicle: undefined,
                parking: {
                    ...state.parking,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? !mapSettings.parking : true,
                        onTheEntireMap: false,
                        onRoute: state.controlOnRoute ? true : false
                    },
                    subOptionsData: undefined,
                    parkings: []
                },
                fuel: {
                    ...state.fuel,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? !mapSettings.fuel : true,
                        onTheEntireMap: false,
                        onRoute: state.controlOnRoute ? true : false
                    },
                    subOptionsData: undefined,
                    fuelStations: []
                },
                wash: {
                    ...state.wash,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? !mapSettings.wash : true,
                        onTheEntireMap: false,
                        onRoute: state.controlOnRoute ? true : false
                    },
                    washers: []
                },
                poi: {
                    ...state.poi,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? !mapSettings.poi : true,
                        onTheEntireMap: false,
                        onRoute: state.controlOnRoute ? true : false
                    },
                    pois: []
                },
                poiBarVisible: false,
                addPoi: undefined
            }));
        });

        this._logic.map().onResetPoisData(() => {
            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings: []
                },
                fuel: {
                    ...state.fuel,
                    fuelStations: []
                },
                wash: {
                    ...state.wash,
                    washers: []
                },
                poi: {
                    ...state.poi,
                    pois: []
                }
            }));
        });

        this._logic.map().onVehicleCardData(async cardData => {
            this.setState(state => ({
                vehicle: state.controlOnRoute ? undefined : cardData,
                transport: undefined
            }));
            const activeTransport = cardData?.activeTransports?.sort((a, b) =>
                moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1
            )?.[0];
            if (activeTransport?.id) {
                this._logic
                    .dispatcherBoardDetail()
                    .transportDetail(activeTransport?.id)
                    .then(t => {
                        if (t) {
                            this.setState({
                                transport: ![
                                    TransportState.Finished,
                                    TransportState.Canceled,
                                    TransportState.Rejected
                                ].includes(t.state as TransportState)
                                    ? this._logic.transportLogic().toTransport(t)
                                    : undefined
                            });
                        }
                    });
            }
        });

        this._logic.map().onVehicleCardDataUpdate(async cardData => {
            const activeTransport = cardData?.activeTransports?.sort((a, b) =>
                moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1
            )?.[0];
            const stateActiveTransport = this.state.vehicle?.activeTransports?.sort((a, b) =>
                moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1
            )?.[0];
            if (activeTransport?.id && activeTransport?.id !== stateActiveTransport?.id) {
                this._logic
                    .dispatcherBoardDetail()
                    .transportDetail(activeTransport.id)
                    .then(t => {
                        t &&
                            this.setState({
                                transport: this._logic.transportLogic().toTransport(t)
                            });
                    });
            }

            this.setState(state => ({
                vehicle: state.controlOnRoute ? undefined : cardData
            }));
        });

        this._logic.map().onRoutePlanning(value => {
            const mapSettings = this.props.logic.settings().getProp('map');
            this.setState(
                state => ({
                    controlOnRoute: value,
                    parking: {
                        ...state.parking,
                        optionsData: {
                            doNotDisplay: value ? !mapSettings.parking : true,
                            onTheEntireMap: false,
                            onRoute: value ? true : false
                        },
                        subOptionsData: value ? DataSourceTypes.EUROWAG : undefined,
                        parkings: []
                    },
                    fuel: {
                        ...state.fuel,
                        optionsData: {
                            doNotDisplay: value ? !mapSettings.fuel : true,
                            onTheEntireMap: false,
                            onRoute: value ? true : false
                        },
                        subOptionsData: value ? DataSourceTypes.EUROWAG : undefined,
                        fuelStations: []
                    },
                    wash: {
                        ...state.wash,
                        optionsData: {
                            doNotDisplay: value ? !mapSettings.wash : true,
                            onTheEntireMap: false,
                            onRoute: value ? true : false
                        },
                        washers: []
                    },
                    poi: {
                        ...state.poi,
                        optionsData: {
                            doNotDisplay: value ? !mapSettings.poi : true,
                            onTheEntireMap: false,
                            onRoute: value ? true : false
                        },
                        pois: []
                    }
                }),
                () => {
                    this._onFuelOptionsConfirm();
                    this._onParkingOptionsConfirm();
                    this._onWashOptionsConfirm();
                    this._onPoiOptionsConfirm();
                }
            );
        });

        this._logic.map().onControlCenterClick(() => {
            this._logic.map().setControlCenterVisible(false);
            this._logic.tracking().enableVehicleCentered();
        });

        this._logic.plannerLogic().onRemovePlaceFromTransport.subscribe((place: TransportPlace) => {
            const placeCenter = {
                lat: roundToDecimals(place.center?.['lat'], 5),
                lng: roundToDecimals(place.center?.['lng'], 5)
            } as { lat: number; lng: number };
            this.setState(state => {
                const parking = state.parking.parkings.find(
                    p =>
                        roundToDecimals(p.position?.lat, 5) === placeCenter.lat &&
                        roundToDecimals(p.position?.lng, 5) === placeCenter.lng
                );
                const fuelStation = state.fuel.fuelStations.find(
                    f =>
                        roundToDecimals(f.position?.lat, 5) === placeCenter.lat &&
                        roundToDecimals(f.position?.lng, 5) === placeCenter.lng
                );
                const poi = state.poi.pois.find(
                    p =>
                        roundToDecimals(p.position?.lat, 5) === placeCenter.lat &&
                        roundToDecimals(p.position?.lng, 5) === placeCenter.lng
                );
                const wash = state.wash.washers.find(
                    w =>
                        roundToDecimals(w.position?.lat, 5) === placeCenter.lat &&
                        roundToDecimals(w.position?.lng, 5) === placeCenter.lng
                );
                return {
                    parking: {
                        ...state.parking,
                        parkings: parking
                            ? state.parking.parkings.map(p =>
                                  roundToDecimals(p.position?.lat, 5) !== roundToDecimals(parking.position.lat, 5) ||
                                  roundToDecimals(p.position?.lng, 5) !== roundToDecimals(parking.position.lng, 5)
                                      ? p
                                      : {
                                            ...p,
                                            inTransport: false,
                                            selected: true
                                        }
                              )
                            : state.parking.parkings
                    },
                    fuel: {
                        ...state.fuel,
                        fuelStations: fuelStation
                            ? state.fuel.fuelStations.map(f =>
                                  roundToDecimals(f.position?.lat, 5) !==
                                      roundToDecimals(fuelStation.position.lat, 5) ||
                                  roundToDecimals(f.position?.lng, 5) !== roundToDecimals(fuelStation.position.lng, 5)
                                      ? f
                                      : {
                                            ...f,
                                            inTransport: false,
                                            selected: true
                                        }
                              )
                            : state.fuel.fuelStations
                    },
                    wash: {
                        ...state.wash,
                        washers: wash
                            ? state.wash.washers.map(w =>
                                  roundToDecimals(w.position?.lat, 5) !== roundToDecimals(wash.position.lat, 5) ||
                                  roundToDecimals(w.position?.lng, 5) !== roundToDecimals(wash.position.lng, 5)
                                      ? w
                                      : {
                                            ...w,
                                            inTransport: false,
                                            selected: true
                                        }
                              )
                            : state.wash.washers
                    },
                    poi: {
                        ...state.poi,
                        pois: poi
                            ? state.poi.pois.map(p =>
                                  roundToDecimals(p.position?.lat, 5) !== roundToDecimals(poi.position.lat, 5) ||
                                  roundToDecimals(p.position?.lng, 5) !== roundToDecimals(poi.position.lng, 5)
                                      ? p
                                      : {
                                            ...p,
                                            inTransport: false,
                                            selected: true
                                        }
                              )
                            : state.poi.pois
                    }
                };
            });
        });

        this._logic.poi().pricesUpdatesSubject.subscribe(() => {
            const pois = this._logic.poi().fuelStationsWithPrices();
            this._logic
                .map()
                .fuelStations()
                .updatePartialData(
                    this._logic.poi().filterFuelStations(pois, this.state.fuel.fuelData, this.state.fuel.serviceData),
                    true,
                    true
                );
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStation: pois.filter(poi => state.fuel.fuelStations.some(f => f.id === poi.id))
                }
            }));
        });

        const mapElement = document.getElementById('map');
        if (this.props.embedded && mapElement) {
            mapElement.classList.add('embedded');
        }
        this._logic.map().init(mapElement!, this.props.simple);

        this._logic
            .map()
            .fuelStations()
            .onBestPriceChange(bestFs => {
                this._onFuelBestPriceChange(bestFs);
            });

        this._logic
            .map()
            .routing()
            .onBestPriceChange(bestFs => {
                this._onFuelBestPriceChange(bestFs);
            });

        this._logic.map().onRightClick(latLng => {
            this.setState({
                mapClickLatLng: latLng
            });
        });

        this._onLayersTrafficChange(this.state.layer.traffic);
        this._onLayersTypeClick(this.state.layer.display);
    }

    componentWillUnmount() {
        this._logic.map().destroy();
        (window as any).app.MapModul = undefined;
    }

    render() {
        const mapWithPanel = [
            ControlPanel.VEHICLE,
            ControlPanel.FUEL,
            ControlPanel.LAYER,
            ControlPanel.PARKING,
            ControlPanel.WASH,
            ControlPanel.POI
        ].some(c => c === this.state.barMenu?.panel);
        const mapWithPoiPanel = this.state.poiBarVisible;

        return (
            <>
                <ContextMenu
                    position={this.state.contextMenu.position}
                    roles={this.state.roles}
                    controlOnRoute={this.state.controlOnRoute}
                    startPlacePossible={
                        !this._logic.plannerLogic().lockedMonitoredObjectId &&
                        !this._logic.plannerLogic().transport?.places?.[0]?.ata
                    }
                    createPoiPossible={this._logic.map().getPoiBarPossible()}
                    onCreatePoi={this._onMapContextMenuCreatePoi}
                    onAddPlannerPlace={p => {
                        this.setState({ loadingTransportPLanner: true });
                        this._onMapContextMenuAddPlannerPlace(p);
                    }}
                    loading={this.state.loadingTransportPLanner}
                    children={
                        <div
                            id="map"
                            className={cn({
                                mapWithPanel: mapWithPanel,
                                mapWithoutPanel: !mapWithPanel,
                                mapWithPoiPanel
                            })}
                            onClick={this._onMapClick}
                            onContextMenu={this._onMapContextMenu}
                        />
                    }
                />

                {this.state.poiBarVisible && this.state.addPoi && (
                    <PoiForm
                        model={this.state.addPoi.model}
                        modelEdited={this.state.addPoi.modelEdited}
                        placeOfWorkUsers={this.state.addPoi.placeOfWorkUsers}
                        loading={this.state.addPoi?.loading}
                        search={false}
                        searchResults={[]}
                        roles={this.state.roles}
                        removePoiLoading={false}
                        onCancel={this._onPoiFormClose}
                        onSubmit={this._onPoiFormSubmit}
                        demoMode={this._logic.demo().isActive}
                    />
                )}

                {this.state.barMenu &&
                    [
                        ControlPanel.FUEL,
                        ControlPanel.PARKING,
                        ControlPanel.LAYER,
                        ControlPanel.POI,
                        ControlPanel.WASH,
                        ControlPanel.VEHICLE
                    ].includes(this.state.barMenu?.panel) && (
                        <>
                            <MapControlsBar
                                selected={this.state.barMenu.panel}
                                vehicle={this.state.vehicle}
                                fuelLoading={this.state.fuel.loading}
                                parkingLoading={this.state.parking.loading}
                                washLoading={this.state.wash.loading}
                                poiLoading={this.state.poi.loading}
                                roles={this.state.roles}
                                onCancel={this._onMapBarCancel}
                                onBarClick={this._onMapSidebarControl}
                            />
                            <Controls
                                vehicle={this.state.vehicle}
                                transport={this.state.transport}
                                toggle={this.state.toggle}
                                barMenu={this.state.barMenu}
                                fuel={this.state.fuel}
                                parking={this.state.parking}
                                layers={this.state.layer}
                                poi={this.state.poi}
                                wash={this.state.wash}
                                contextMenu={this.state.contextMenu}
                                controlOnRoute={this.state.controlOnRoute}
                                roles={this.state.roles}
                                onParkingConfirmClick={this._onParkingOptionsConfirmClick}
                                onParkingPoiCardClick={poi => this._onParkingClick(poi, true)}
                                onParkingCancelClick={this._onParkingOptionsCancelClick}
                                onParkingFilterClick={this._onParkingFilter}
                                onFuelPoiCardClick={poi => this._onFuelStationClick(poi, true)}
                                onFuelConfirmClick={this._onFuelOptionsConfirmClick}
                                onFuelCancelClick={this._onFuelOptionsCancelClick}
                                onFuelEditServicesFilter={this._onFuelEditServicesFilter}
                                onFuelEditFuelTypesFilter={this._onFuelEditFuelTypesFilter}
                                onFuelFilterClick={this._onFuelFilter}
                                onWashConfirmClick={this._onWashConfirmClick}
                                onWashCardClick={poi => this._onWashClick(poi, true)}
                                onPoiConfirmClick={this._onPoiConfirmClick}
                                onPoiCardClick={poi => this._onPoiClick(poi, true)}
                                onLayersTypeClick={this._onLayersTypeClick}
                                onLayersTrafficChange={this._onLayersTrafficChange}
                                onLayersBorderCrossesChange={this._onLayersBorderCrossesChange}
                                onAddToTransportClick={this._onFuelParkingAddToTransportClick}
                                onSideBarViewChange={this._onMapSidebarControl}
                                onRedirectToJourneyActivityClick={
                                    this.props.simple ? undefined : this._onRedirectToJourneyActivity
                                }
                                onVehicleCardRedirectToAetrReportClick={
                                    this.props.simple ? undefined : this._onVehicleCardRedirectToAetrReportClick
                                }
                                onVehicleCardRedirectToDispatcherBoardClick={
                                    this.props.simple ? undefined : this._onRedirectToDispatcherBoard
                                }
                                onVehicleCardRedirectToDriverBehavior={
                                    this.props.simple ? undefined : this._onRedirectToDriverBehavior
                                }
                                onVehicleCardRedirectToDriverBehaviorLightVehicles={
                                    this.props.simple ? undefined : this._onRedirectToDriverBehaviorLightVehicles
                                }
                                onVehicleCardShowRealVsPlannedRouteClick={
                                    this.props.simple ? undefined : this._showRealVsPlannedRouteOnMap
                                }
                                onVehicleCardAddDestinationClick={
                                    this.props.simple ? undefined : this._onAddDestination
                                }
                                onVehicleClusteringSwitchClick={this._onVehicleClusteringSwitchClick}
                                onVehicleCardTemperateSensorClick={this._onVehicleCardTemperateSensorClick}
                            />
                        </>
                    )}
            </>
        );
    }

    private _onVehicleCardTemperateSensorClick = (monitoredObjectId: string, sensorId: string) => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_COLD_CHAIN,
            search: qs.stringify({
                monitoredObjectId,
                sensorId
            } as ColdchainRouteParams)
        });
    };

    private _onVehicleClusteringSwitchClick = (checked: boolean) => {
        this._logic.map().vehicles().setClusteringEnable(checked);
        const mapSettings = this._logic.settings().getProp('map');
        this._logic.settings().setProp('map', {
            ...mapSettings,
            vehicleClusteringEnabled: checked
        });
    };

    private _onFuelEditServicesFilter = (filter: Services) => {
        this.setState(
            state => ({
                fuel: {
                    ...state.fuel,
                    serviceData: filter
                }
            }),
            () => {
                if (this.state.fuel.optionsData.onTheEntireMap) {
                    this._logic
                        .map()
                        .fuelStations()
                        .setData(
                            this._logic
                                .poi()
                                .filterFuelStations(
                                    this._logic.poi().fuelStationsWithPrices(),
                                    this.state.fuel.fuelData,
                                    this.state.fuel.serviceData
                                )
                        );
                    this._logic.map().fuelStations().show();
                }

                this._logic.map().filterSubject.next([this.state.fuel.fuelData, this.state.fuel.serviceData]);
            }
        );
    };

    private _onFuelEditFuelTypesFilter = (filter: Fuels) => {
        this.setState(
            state => ({
                fuel: {
                    ...state.fuel,
                    fuelData: filter
                }
            }),
            () => {
                if (this.state.fuel.optionsData.onTheEntireMap) {
                    this._logic
                        .map()
                        .fuelStations()
                        .setData(
                            this._logic
                                .poi()
                                .filterFuelStations(
                                    this._logic.poi().fuelStationsWithPrices(),
                                    this.state.fuel.fuelData,
                                    this.state.fuel.serviceData
                                )
                        );
                    this._logic.map().fuelStations().show();
                } else {
                    this._logic.map().filterSubject.next([this.state.fuel.fuelData, this.state.fuel.serviceData]);
                }
            }
        );
    };

    private _onAddDestination = (): void => {
        if (this.state.vehicle) {
            const data = this._logic.tracking().loadVehicle(this.state.vehicle.id);
            if (data?.GPS) {
                this.props.history.push({
                    pathname: RouteNames.SCHEDULING_PLANNER,
                    search: qs.stringify({ vehicleId: this.state.vehicle.id })
                });
            } else {
                // TODO:
                // - allert, unable to find vehicle
            }
        } else {
            // TODO:
            // - allert, vehicle not selected
        }
    };

    private _showRealVsPlannedRouteOnMap = (): void => {
        if (this.state.vehicle) {
            this._logic.tracking().renderRouteOfVehicle(this.state.vehicle);
        } else {
            // TODO:
            // - notification error
        }
    };

    private _onRedirectToDispatcherBoard = (): void => {
        this.props.logic.exponea().trackEvent(exponea.module.vehicleDetail, {
            status: exponea.status.actionTaken,
            action: exponea.action.goToTransportDetail
        });

        const { transport } = this.state;
        if (transport) {
            this.props.history.push({
                pathname: RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL,
                search: qs.stringify({
                    editId: transport.id,
                    vehicleId: transport.vehicle
                })
            });
        } else {
            // TODO:
            // - allert, no transports at the moment
        }
    };

    private _onRedirectToJourneyActivity = (): void => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_JOURNEYS_ACTIVITY,
            search: qs.stringify({
                startDate: moment().startOf('day').format(DATE_TIME_FORMAT),
                endDate: moment().endOf('day').format(DATE_TIME_FORMAT),
                vehicleId: this.state.vehicle?.id,
                selectedDate: moment().format(DATE_FORMAT),
                tableExpanded: String(false)
            } as RouteParams)
        });
    };

    private _onRedirectToDriverBehavior = async () => {
        if (this.state.vehicle?.driverBehaviorTrends?.tachocard) {
            await this._logic.driverBehavior().trucks().loadTrendsData(moment.utc().startOf('month').toISOString());
            this.props.history.push(
                RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL + '/' + this.state.vehicle?.driverId
            );
        }
    };

    private _onRedirectToDriverBehaviorLightVehicles = (): void => {
        if (this.state.vehicle?.driverBehaviorLightVehicle?.driverId) {
            this.props.history.push(
                RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL +
                    '/' +
                    this.state.vehicle?.driverBehaviorLightVehicle?.driverId
            );
        }
    };

    private _onVehicleCardRedirectToAetrReportClick = (driverId?: string): void => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_AETR,
            search: qs.stringify({
                driverId: driverId ? driverId : this.state.vehicle?.driverId,
                vehicleId: this.state.vehicle?.id
            } as RouteParams)
        });
    };

    private _onPoiFormClose = (): void => {
        this.setState({
            poiBarVisible: false,
            addPoi: undefined
        });

        this._logic.map().poiOff();
        this._logic.map().poi().destroy();
    };

    private _onPoiFormSubmit = (model: PoiModel): void => {
        this.setState(state => ({
            addPoi: {
                ...state.addPoi,
                model: state.addPoi?.model!,
                loading: true
            }
        }));
        this._logic
            .dispatcherKitPoi()
            .createPoi(model)
            .then(() => {
                this._logic.map().poiOff();
                this._logic.map().poi().destroy();
                this.props.logic.poi().pois(true);
                message.success(this.props.t('ManagementPoi.message.createSuccess'));
            })
            .catch(err => {
                console.error(`Create poi error, err: ${err}`);
                message.error(this.props.t('ManagementPoi.message.createError'));
            })
            .finally(() => {
                this.setState({
                    poiBarVisible: false,
                    addPoi: undefined
                });
            });
    };

    private _onFuelParkingAddToTransportClick = async (data: PoiMarkerData): Promise<void> => {
        if (data.type === 'fuelStation') {
            const fuelStations = this.state.fuel.fuelStations?.map(fs =>
                data.data.id === fs.id ? { ...fs, inTransport: true } : fs
            );
            this._logic
                .map()
                .routing()
                .updateFuelStation(
                    this._logic
                        .map()
                        .routing()
                        .fuelStations()
                        .map(fs => fs.map(f => (f.id !== data.data.id ? f : { ...data.data, inTransport: true }))),
                    { ...data.data, inTransport: true }
                );
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStations
                }
            }));
        } else if (data.type === 'parking') {
            const parkings = this.state.parking.parkings?.map(fs =>
                data.data.id === fs.id ? { ...fs, inTransport: true } : fs
            );
            this._logic
                .map()
                .routing()
                .updateParking(
                    this._logic
                        .map()
                        .routing()
                        .parkings()
                        .map(ps => ps.map(p => (p.id !== data.data.id ? p : { ...data.data, inTransport: true }))),
                    { ...data.data, inTransport: true }
                );
            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings
                }
            }));
        } else if (data.type === 'wash') {
            const washers = this.state.wash.washers?.map(p =>
                data.data.id === p.id ? { ...p, inTransport: true } : p
            );
            this._logic
                .map()
                .routing()
                .updateWash(
                    this._logic
                        .map()
                        .routing()
                        .washers()
                        .map(ps => ps.map(p => (p.id !== data.data.id ? p : { ...data.data, inTransport: true }))),
                    { ...data.data, inTransport: true }
                );
            this.setState(state => ({
                wash: {
                    ...state.wash,
                    washers
                }
            }));
        } else if (data.type === 'poi') {
            const pois = this.state.poi.pois?.map(p => (data.data.id === p.id ? { ...p, inTransport: true } : p));
            this._logic
                .map()
                .routing()
                .updatePoi(
                    this._logic
                        .map()
                        .routing()
                        .pois()
                        .map(ps => ps.map(p => (p.id !== data.data.id ? p : { ...data.data, inTransport: true }))),
                    { ...data.data, inTransport: true }
                );
            this.setState(state => ({
                poi: {
                    ...state.poi,
                    pois
                }
            }));
        }

        this._logic
            .plannerLogic()
            .addPoiToTransport(data)
            .then(() =>
                this._logic
                    .plannerLogic()
                    .planRoute(this._logic.plannerLogic().transport)
                    .then(() =>
                        this._logic
                            .plannerLogic()
                            .drawRouteOnMap()
                            .then(() => this._logic.plannerLogic().onRouteChange())
                    )
            );
    };

    private _onMapSidebarControl = (panel: ControlPanel): void => {
        if (panel === ControlPanel.FUEL) {
            this._logic.exponea().trackEvent(exponea.module.mapFuelPanel, {
                status: exponea.status.screenShown,
                src: 'bar'
            });
        }

        this.setState(
            state => ({
                barMenu: { panel },
                fuel:
                    panel === ControlPanel.FUEL
                        ? {
                              ...state.fuel,
                              filterOpen: true
                          }
                        : state.fuel,
                parking:
                    panel === ControlPanel.PARKING
                        ? {
                              ...state.parking,
                              filterOpen: true
                          }
                        : state.parking,
                wash:
                    panel === ControlPanel.WASH
                        ? {
                              ...state.wash,
                              filterOpen: true
                          }
                        : state.wash,
                poi:
                    panel === ControlPanel.PARKING
                        ? {
                              ...state.poi,
                              filterOpen: true
                          }
                        : state.poi
            }),
            () => this._logic.map().setCurrentSideBarControl(panel)
        );
    };

    private _onMapClick = () => {
        localStorage.setItem('map-context-disabled', '0');
        this.setState(() => ({
            toggle: {
                contextMenu: false
            }
        }));
    };

    private _onMapContextMenu = (e: MouseEvent): boolean => {
        if (!this.state.addPoi) {
            this.setState({
                toggle: {
                    contextMenu: true
                },
                contextMenu: {
                    position: {
                        x: e.clientX,
                        y: e.clientY
                    }
                }
            });
        }
        return false;
    };

    private _onMapContextMenuCreatePoi = (): void => {
        this.setState({
            toggle: {
                contextMenu: false
            }
        });
        this._logic
            .map()
            .poi()
            .onCreatePolygon((lat, lng) => this._onPoiFormSetLatLng(lat, lng));

        this._logic.map().poi().onEditPolygon(this._editPolygon);

        this._logic.map().poi().onDragEndPolygon(this._editPolygon);
    };

    private _onPoiFormSetLatLng(lat: number, lng: number): void {
        this._logic.map().poiOn();

        const coordinates = this._logic.map().poi().getCoordinatesFromPoint(lat, lng);

        this._logic
            .dispatcherKitPoi()
            .geocodeLatLng({ lat, lng })
            .then(address => {
                this.setState({
                    poiBarVisible: true,
                    addPoi: {
                        model: {
                            id: '',
                            name: '',
                            type: PoiType.Firm,
                            forbidden: false,
                            address: address.formattedAddress,
                            center: {
                                lat,
                                lng
                            },
                            polygon: coordinates,
                            notes: '',
                            countryCode: address.countryCode
                        }
                    }
                });
            });

        this._logic.map().poi().removePolygonListener();
    }

    private _editPolygon = (data: LatLng[], center: { lat: number; lng: number }): void => {
        this._logic
            .dispatcherKitPoi()
            .geocodeLatLng(center)
            .then(address => {
                this.setState(state => ({
                    poiBarVisible: true,
                    addPoi: {
                        model: {
                            ...state.addPoi?.model,
                            address: address.formattedAddress,
                            center,
                            polygon: data,
                            countryCode: address.countryCode
                        },
                        ...state.addPoi,
                        modelEdited: {
                            ...state.addPoi?.model,
                            address: address.formattedAddress,
                            center,
                            polygon: data,
                            countryCode: address.countryCode
                        },
                        placeOfWorkUsers: []
                    }
                }));
            });
    };

    private _onLayersTypeClick = (type: LayerTypes): void => {
        const originalSettings = this._logic.settings().getProp('map');
        const modifiedSettings = {
            ...originalSettings,
            style: type
        };
        this.props.logic.settings().setProp('map', modifiedSettings);
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: type
                }
            }),
            () => this._logic.map().layers(type)
        );
    };

    private _onLayersTrafficChange = (checked: boolean): void => {
        const originalSettings = this._logic.settings().getProp('map');
        const modifiedSettings = {
            ...originalSettings,
            traffic: checked
        };
        this.props.logic.settings().setProp('map', modifiedSettings);
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    traffic: checked
                }
            }),
            () => this._logic.map().traffic(checked)
        );
    };

    private _setMapSettings = (settings: Partial<Conf['settings']['map']>) => {
        const originalSettings = this._logic.settings().getProp('map');
        const modifiedSettings: Conf['settings']['map'] = {
            ...originalSettings,
            ...settings
        };
        this._logic.settings().setProp('map', { ...modifiedSettings });
    };

    private _onLayersBorderCrossesChange = (checked: boolean): void => {
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    borderCrosses: checked
                }
            }),
            () => {
                if (checked) {
                    this._logic
                        .borderCrosses()
                        .borderCrosses()
                        .then(res => this._logic.map().borderCrosses().setData(res));
                } else {
                    this._logic.map().borderCrosses().destroy();
                }
            }
        );
    };

    private _onFuelOptionsConfirmClick = (form: FuelControlsForm): void => {
        this.setState(
            state => {
                return {
                    fuel: {
                        ...state.fuel,
                        optionsData: {
                            doNotDisplay: form.subOptionsData ? false : true,
                            onRoute: state.controlOnRoute && (form.subOptionsData ? true : false),
                            onTheEntireMap: !state.controlOnRoute && (form.subOptionsData ? true : false)
                        },
                        subOptionsData: form.subOptionsData,
                        serviceData: form.serviceData,
                        fuelData: form.fuelData
                    }
                };
            },
            () => {
                if (this.state.fuel.optionsData.onTheEntireMap) {
                    this._logic.exponea().trackEvent(exponea.module.mapFuelPanel, {
                        status: exponea.status.actionTaken,
                        action: exponea.action.showFuelStations
                    });
                } else if (this.state.controlOnRoute) {
                    this._setMapSettings({ fuel: !this.state.fuel.optionsData.doNotDisplay });
                }
                this._onFuelOptionsConfirm();
            }
        );
    };

    private _onWashConfirmClick = (): void => {
        this.setState(
            state => ({
                wash: {
                    ...state.wash,
                    optionsData: {
                        onTheEntireMap: !state.controlOnRoute && state.wash.optionsData.doNotDisplay,
                        onRoute: state.controlOnRoute ? true : false,
                        doNotDisplay: !state.wash.optionsData.doNotDisplay
                    }
                }
            }),
            () => {
                if (this.state.controlOnRoute) {
                    this._setMapSettings({ wash: !this.state.wash.optionsData.doNotDisplay });
                }
                this._onWashOptionsConfirm();
            }
        );
    };

    private _onPoiConfirmClick = (): void => {
        this.setState(
            state => ({
                poi: {
                    ...state.poi,
                    optionsData: {
                        onTheEntireMap: !state.controlOnRoute && state.poi.optionsData.doNotDisplay,
                        onRoute: state.controlOnRoute ? true : false,
                        doNotDisplay: !state.poi.optionsData.doNotDisplay
                    }
                }
            }),
            () => {
                if (this.state.controlOnRoute) {
                    this._setMapSettings({ poi: !this.state.poi.optionsData.doNotDisplay });
                }
                this._onPoiOptionsConfirm();
            }
        );
    };

    private _onFuelBestPriceChange = (bestFs?: PoiModelMap[]) => {
        const selected = this.state.fuel.fuelStations.find(f => f.selected);
        if (bestFs && bestFs.length > 0) {
            const ffsBs = selected
                ? [
                      {
                          ...selected,
                          ...bestFs.find(f => f.id === selected.id),
                          bestPrice: bestFs.find(f => f.id === selected.id) ? true : false
                      },
                      ...bestFs.filter(f => f.id !== selected.id)
                  ]
                : [...bestFs];

            // if we add best price fuel to the route we do not have this fuel in bestFs.
            // add this fuel manual so it wont disappear from side panel
            const bestFuelInTransport = this.state.fuel.fuelStations.find(
                f => f.bestPrice && f.inTransport && !bestFs.some(bf => bf.id === f.id)
            );
            if (bestFuelInTransport) {
                bestFuelInTransport.bestPrice = false;
                ffsBs.push(bestFuelInTransport);
            }

            // if we add best price fuel to the route and we have this fuel in bestFS
            // we need to remove it from bestFS so it wont be displayed twice
            let bestFuelsInTransport = this.state.fuel.fuelStations.filter(
                f => f.inTransport && this.state.fuel.fuelStations.some(bf => bf.id === f.id)
            );
            let bestFuelsNotInTransport = ffsBs.filter(fsx => !bestFuelsInTransport.some(bf => bf.id === fsx.id));

            const fuelStations = this.state.fuel.fuelStations
                .filter(f => f.bestPrice !== true)
                .filter(f => !ffsBs.some(bf => bf.id === f.id));
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStations: [
                        ...bestFuelsNotInTransport,
                        ...bestFuelsInTransport,
                        ...fuelStations
                            .filter(fs => !fs.inTransport)
                            .map(f => ({
                                ...f,
                                inTransport: state.fuel.fuelStations.find(fi => fi.id === f.id)?.inTransport
                            }))
                    ]
                }
            }));
        } else {
            const newFs = this.state.fuel.fuelStations.filter(f => f.bestPrice !== true);

            // if we add best price fuel to the route we do not have this fuel in bestFs.
            // add this fuel manual so it wont disappear from side panel
            const bestFuelInTransport = this.state.fuel.fuelStations.find(f => f.bestPrice && f.inTransport);
            if (bestFuelInTransport) {
                bestFuelInTransport.bestPrice = false;
                newFs.unshift(bestFuelInTransport);
            }

            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStations: selected
                        ? [
                              {
                                  ...selected,
                                  ...newFs.find(f => f.id === selected.id),
                                  bestPrice: newFs.find(f => f.id === selected.id) ? false : true
                              },
                              ...newFs.filter(f => f.id !== selected.id)
                          ]
                        : [
                              ...newFs.map(f => ({
                                  ...f,
                                  inTransport: state.fuel.fuelStations.find(fi => fi.id === f.id)?.inTransport
                              }))
                          ]
                }
            }));
        }
    };

    private _onFuelStationClick = (fuelStation: PoiModelMap, fit?: boolean) => {
        this._logic.exponea().trackEvent(exponea.module.fuelStationDetail, {
            status: exponea.status.screenShown,
            name: fuelStation.name,
            address: fuelStation.detailAddress,
            diesel_price: fuelStation.fuelTypes?.find(f => fuelToFuelTypeMap.diesel.includes(f.code))?.price?.price
        });
        const optionsData = this.state.fuel.optionsData;
        this._logic.map().fuelOn();

        const fuelStations: PoiModelMap[] = this._logic
            .poi()
            .fuelStationsWithPrices()
            .map(f => {
                const fuel: PoiModelMap | undefined = this.state.fuel.fuelStations.find(f => f.id === fuelStation.id);
                return { ...f, inTransport: fuel ? fuel.inTransport : false };
            });
        const detail: PoiModelMap | undefined = fuelStations.find(f => f.id === fuelStation.id);

        const detailed = detail
            ? {
                  inTransport: this.state.fuel.fuelStations?.find(f => f.id === fuelStation.id)?.inTransport ?? false,
                  ...detail,
                  selected: true
              }
            : undefined;
        const prevSelected = fuelStations.find(fuel => fuel.selected);

        if (optionsData.onTheEntireMap) {
            this._logic.tracking().centerOffVehicles();
            if (detailed?.externalId && detailed?.fuelTypes?.every(f => f.price === undefined)) {
                console.info('[FS prices] Going to load price for => ', detailed.externalId);
                if (fuelStation.externalId) {
                    this._logic
                        .api()
                        .fuelStationsApi.computePricesSingleFuelstationV1FuelstationsPricesSinglePost({
                            fuelStation: fuelStation.externalId
                        })
                        .then(resp => {
                            const data = this._logic.poi().updatePricelist(resp.data);
                            console.log('[PRICETAG] - updated fuelstations', data);
                            const pois = this._logic.poi().fuelStationsWithPrices();
                            const updatedFs =
                                ({
                                    ...pois.find(f => f.externalId === fuelStation.externalId),
                                    selected: true
                                } as PoiModelMap) ?? detailed;
                            if (updatedFs) {
                                this._logic.map().fuelStations().updatePartialData([updatedFs], false, true, true);
                                this.setState(state => {
                                    const fuelStations = this._sortPrices(
                                        state.fuel.fuelStations.map(fs =>
                                            updatedFs.externalId === fs.externalId
                                                ? {
                                                      ...fs,
                                                      fuelTypes: [
                                                          ...(fs.fuelTypes ?? []),
                                                          ...(updatedFs.fuelTypes ?? [])
                                                      ]
                                                  }
                                                : fs
                                        ),
                                        detailed
                                    );
                                    return {
                                        fuel: {
                                            ...state.fuel,
                                            fuelStations
                                        }
                                    };
                                });
                            }
                        })
                        .catch(e => {
                            console.log('[PRICETAG] - unable to load data', detailed.externalId, e);
                        });
                }
            }
        }

        if (detailed) {
            detailed.bestPrice = fuelStation.bestPrice;
            if (optionsData.onRoute) {
                detailed.routeIndex = fuelStation.routeIndex;
            }

            const poiToUpdate: PoiModelMap[] = [detailed];
            if (prevSelected && prevSelected.id !== detailed.id) {
                prevSelected.selected = false;
                poiToUpdate.push(prevSelected);
            }

            if (optionsData.onRoute) {
                this._logic.map().routing().updatePartialFuelStationsData(poiToUpdate, false, true, true);
            } else if (optionsData.onTheEntireMap) {
                this._logic.map().fuelStations().updatePartialData(poiToUpdate, false, true, true);
            }
            const bestPrices = this.state.fuel.fuelStations
                .filter(f => f.bestPrice && f.id !== detailed.id)
                .map(f => ({ ...f, selected: false }));
            const ffsBs = [detailed, ...bestPrices];
            const fss = [
                ...ffsBs,
                ...this.state.fuel.fuelStations
                    .filter(fuel => fuel.id !== fuelStation.id && !fuel.bestPrice)
                    .map(f => ({ ...f, selected: false }))
                    .sort((a, b) => (a.bestPrice === b.bestPrice ? 0 : a.bestPrice ? -1 : 1))
            ];
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStations: fss
                }
            }));

            if (fit) {
                const distance = 200;
                const latLng = new google.maps.LatLng(detailed.position.lat, detailed.position.lng);

                if (optionsData.onRoute) {
                    this._logic.map().setPadding(this._logic.map().initialPaddingWithLeftComponent);
                } else if (optionsData.onTheEntireMap) {
                    this._logic.map().setPadding({
                        ...this._logic.map().initialPadding,
                        left:
                            this._logic.tracking().getTrackingType() === 'TRACKING_ADVANCE_MAP'
                                ? this._logic.map().initialPaddingWithLeftComponent.left
                                : this._logic.map().initialPadding.left
                    });
                }

                this._logic.map().fitPosition(latLng, distance);
            }
        }
    };

    private _sortPrices = (fss: PoiModelMap[], detailed: PoiModelMap) => {
        const bestPrices = fss.filter(f => f.bestPrice && f.id !== detailed.id);
        const ffsBs = [detailed, ...bestPrices];
        return [
            ...ffsBs,
            ...fss
                .filter(fuel => fuel.id !== detailed.id && !fuel.bestPrice)
                .sort((a, b) => (a.bestPrice === b.bestPrice ? 0 : a.bestPrice ? -1 : 1))
        ];
    };

    private _onFuelOptionsConfirm = (): void => {
        if (this.state.fuel.optionsData.onTheEntireMap) {
            this._logic
                .poi()
                .fuelStations()
                .then(_fs => {
                    this._logic
                        .map()
                        .fuelStations()
                        .updateData(
                            this._logic
                                .poi()
                                .filterFuelStations(
                                    this._logic.poi().fuelStationsWithPrices(),
                                    this.state.fuel.fuelData,
                                    this.state.fuel.serviceData
                                )
                        );
                });

            this._logic.map().fuelStations().onClick(this._onFuelStationClick);

            this._logic
                .map()
                .fuelStations()
                .onFuelStationClusterClick(() => {
                    this._logic.tracking().centerOffVehicles();
                });
            this._logic
                .poi()
                .fuelStations()
                .then(_fuelStations => {
                    this._logic
                        .map()
                        .fuelStations()
                        .setData(
                            this._logic
                                .poi()
                                .filterFuelStations(
                                    this._logic.poi().fuelStationsWithPrices(),
                                    this.state.fuel.fuelData,
                                    this.state.fuel.serviceData
                                )
                        );
                    this._logic.map().fuelStations().show();
                });

            this._logic.map().routing().hideFuelStations();
            this._logic.map().routing().setFuelStationsRender(true);
        } else if (this.state.fuel.optionsData.onRoute) {
            this._logic.map().routing().onFuelStationClick(this._onFuelStationClick);
            if (this.state.fuel.optionsData.doNotDisplay) {
                this._logic.map().routing().hideFuelStations();
            } else {
                this._logic.map().routing().showFuelStations();
            }
            this._logic.map().routing().setFuelStationsRender(!this.state.fuel.optionsData.doNotDisplay);
        } else if (this.state.fuel.optionsData.doNotDisplay) {
            this._logic.map().fuelStations().hide();
            this._logic.map().fuelStations().onClick(undefined);
            this._logic.map().routing().hideFuelStations();
            this._logic.map().routing().onFuelStationClick(undefined);
            this._logic.map().routing().setFuelStationsRender(false);
            this._logic.poi().unselectFuelStations();
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    fuelStations: []
                }
            }));
        }
    };

    private _onFuelOptionsCancelClick = (): void => {
        this.setState(state => ({
            fuel: {
                ...state.fuel,
                filterOpen: !state.fuel.filterOpen
            }
        }));
    };

    private _onMapBarCancel = (): void => {
        this._logic.map().sideBarControlsOff();
    };

    private _onFuelFilter = (): void => {
        this.setState(state => ({
            fuel: {
                ...state.fuel,
                filterOpen: !state.fuel.filterOpen
            }
        }));
    };

    private _onParkingOptionsConfirmClick = (form: ParkingControlsForm): void => {
        this.setState(
            state => {
                return {
                    parking: {
                        ...state.parking,
                        optionsData: {
                            doNotDisplay: form.subOptionsData ? false : true,
                            onRoute: state.controlOnRoute && (form.subOptionsData ? true : false),
                            onTheEntireMap: !state.controlOnRoute && (form.subOptionsData ? true : false)
                        },
                        subOptionsData: form.subOptionsData,
                        serviceData: form.serviceData
                    }
                };
            },
            () => {
                if (this.state.controlOnRoute) {
                    this._setMapSettings({ parking: !this.state.parking.optionsData.doNotDisplay });
                }
                this._onParkingOptionsConfirm();
            }
        );
    };

    private _onWashClick = (wash: PoiModelMap, fit?: boolean): void => {
        const { routeIndex } = wash;
        const optionsData = this.state.wash.optionsData;
        this._logic.map().washOn();

        let washDetails: PoiModelMap[] | undefined;
        if (optionsData.onRoute) {
            washDetails = this._logic.plannerLogic().setWashersInDetail([wash.id]);
        } else if (optionsData.onTheEntireMap) {
            washDetails = this._logic.poi().setWashersInDetail([wash.id]);
        }

        if (washDetails) {
            if (optionsData.onTheEntireMap) {
                this._logic.tracking().centerOffVehicles();
                this._logic.map().washers().updateDetailData(washDetails);
            }
            const detail: PoiModelMap | undefined = washDetails.find(p => p.id === wash.id);

            const detailed = detail
                ? {
                      inTransport: this.state.wash.washers?.find(p => p.id === wash.id)?.inTransport ?? false,
                      ...detail,
                      selected: true
                  }
                : undefined;

            if (detailed) {
                if (optionsData.onRoute) {
                    detailed.routeIndex = routeIndex;
                }
                const washers = [
                    detailed,
                    ...this.state.wash
                        .washers!.filter(p => p.id !== wash.id)
                        .map(p => ({
                            ...p,
                            selected: false
                        }))
                ];
                this.setState(state => ({
                    wash: {
                        ...state.wash,
                        washers
                    }
                }));

                if (fit) {
                    const distance = 100;
                    const latLng = new google.maps.LatLng(detailed.position.lat, detailed.position.lng);
                    this._logic.map().fitPosition(latLng, distance);
                }
            }
        }

        const filtered = this.state.wash.washers.filter(p => {
            return p.id === wash.id;
        });
        if (filtered.length === 0) {
            const washers = this.state.wash.washers.length > 0 ? [wash, ...this.state.wash.washers] : [wash];

            this.setState(state => ({
                wash: {
                    ...state.wash,
                    washers
                }
            }));
        }
    };

    private _onPoiClick = (poi: PoiModelMap, fit?: boolean): void => {
        const { routeIndex } = poi;
        const optionsData = this.state.poi.optionsData;
        this._logic.map().poisOn();

        let poiDetails: PoiModelMap[] | undefined;
        if (optionsData.onRoute) {
            poiDetails = this._logic.plannerLogic().setPoisInDetail([poi.id]);
        } else if (optionsData.onTheEntireMap) {
            poiDetails = this._logic.poi().poisDetail([poi.id]);
        }

        if (poiDetails) {
            if (optionsData.onTheEntireMap) {
                this._logic.tracking().centerOffVehicles();
                this._logic.map().pois().updateDetailData(poiDetails);
            }
            const detail: PoiModelMap | undefined = poiDetails.find(p => p.id === poi.id);

            const detailed = detail
                ? {
                      inTransport: this.state.poi.pois?.find(p => p.id === poi.id)?.inTransport ?? false,
                      ...detail,
                      selected: true
                  }
                : undefined;
            if (detailed) {
                if (optionsData.onRoute) {
                    detailed.routeIndex = routeIndex;
                }
                const pois = [
                    detailed,
                    ...this.state.poi
                        .pois!.filter(p => p.id !== poi.id)
                        .map(p => ({
                            ...p,
                            selected: false
                        }))
                ];
                this.setState(state => ({
                    poi: {
                        ...state.poi,
                        pois
                    }
                }));

                if (fit) {
                    const distance = 100;
                    const latLng = new google.maps.LatLng(detailed.position.lat, detailed.position.lng);
                    this._logic.map().fitPosition(latLng, distance);
                }
            }
        }

        const filtered = this.state.poi.pois.filter(p => {
            return p.id === poi.id;
        });
        if (filtered.length === 0) {
            const pois = this.state.poi.pois.length > 0 ? [poi, ...this.state.poi.pois] : [poi];

            this.setState(state => ({
                poi: {
                    ...state.poi,
                    pois
                }
            }));
        }
    };

    private _onParkingClick = (parkingPoi: PoiModelMap, fit?: boolean): void => {
        const { routeIndex } = parkingPoi;
        const optionsData = this.state.parking.optionsData;
        this._logic.map().parkingOn();

        let parkingDetails: PoiModelMap[] | undefined;
        if (optionsData.onRoute) {
            parkingDetails = this._logic.plannerLogic().setParkingsInDetail([parkingPoi.id]);
        } else if (optionsData.onTheEntireMap) {
            parkingDetails = this._logic.poi().parkingsDetail([parkingPoi.id]);
        }

        if (parkingDetails) {
            if (optionsData.onTheEntireMap) {
                this._logic.tracking().centerOffVehicles();
                this._logic.map().parkings().updateDetailData(parkingDetails);
            }
            const detail: PoiModelMap | undefined = parkingDetails.find(p => p.id === parkingPoi.id);

            const detailed = detail
                ? {
                      inTransport: this.state.parking.parkings?.find(p => p.id === parkingPoi.id)?.inTransport ?? false,
                      ...detail,
                      selected: true
                  }
                : undefined;

            if (detailed) {
                if (optionsData.onRoute) {
                    detailed.routeIndex = routeIndex;
                }
                const pss = [
                    detailed,
                    ...this.state.parking
                        .parkings!.filter(parking => parking.id !== parkingPoi.id)
                        .map(parking => ({
                            ...parking,
                            selected: false
                        }))
                ];
                this.setState(state => ({
                    parking: {
                        ...state.parking,
                        parkings: pss
                    }
                }));

                if (fit) {
                    const distance = 100;
                    const latLng = new google.maps.LatLng(detailed.position.lat, detailed.position.lng);

                    if (optionsData.onRoute) {
                        this._logic.map().setPadding(this._logic.map().initialPaddingWithLeftComponent);
                    } else if (optionsData.onTheEntireMap) {
                        this._logic.map().setPadding({
                            ...this._logic.map().initialPadding,
                            left:
                                this._logic.tracking().getTrackingType() === 'TRACKING_ADVANCE_MAP'
                                    ? this._logic.map().initialPaddingWithLeftComponent.left
                                    : this._logic.map().initialPadding.left
                        });
                    }

                    this._logic.map().fitPosition(latLng, distance);
                }
            }
        }

        const filtered = this.state.parking.parkings!.filter(parking => {
            return parking.id === parkingPoi.id;
        });
        if (filtered!.length === 0) {
            const pss =
                this.state.parking.parkings!.length > 0 ? [parkingPoi, ...this.state.parking.parkings!] : [parkingPoi];

            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings: pss
                }
            }));
        }
    };

    private _onParkingOptionsConfirm = (): void => {
        if (this.state.parking.optionsData.onTheEntireMap) {
            this._logic.map().parkings().onClick(this._onParkingClick);
            this._logic
                .map()
                .parkings()
                .onParkingClusterClick(() => {
                    this._logic.tracking().centerOffVehicles();
                });
            this._logic
                .poi()
                .parkings()
                .then(parkings => {
                    this._logic.map().parkings().setData(parkings);
                    this._logic.map().parkings().show();
                });
            this._logic.map().routing().hideParkings();
            this._logic.map().routing().setParkingRender(true);
        } else if (this.state.parking.optionsData.onRoute) {
            this._logic.map().routing().onParkingClick(this._onParkingClick);
            if (this.state.parking.optionsData.doNotDisplay) {
                this._logic.map().routing().hideParkings();
            } else {
                this._logic.map().routing().showParkings();
            }
            this._logic.map().routing().setParkingRender(!this.state.parking.optionsData.doNotDisplay);
        } else if (this.state.parking.optionsData.doNotDisplay) {
            this._logic.map().parkings().hide();
            this._logic.map().parkings().onClick(undefined);
            this._logic.map().routing().hideParkings();
            this._logic.map().routing().onParkingClick(undefined);
            this._logic.map().routing().setParkingRender(false);
            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings: []
                }
            }));
        }
    };

    private _onWashOptionsConfirm = (): void => {
        if (this.state.wash.optionsData.onTheEntireMap) {
            this._logic.map().washers().onClick(this._onWashClick);
            this._logic
                .map()
                .washers()
                .onWashClusterClick(() => {
                    this._logic.tracking().centerOffVehicles();
                });

            this._logic
                .poi()
                .washers()
                .then(washers => {
                    this._logic.map().washers().setData(washers);
                    this._logic.map().washers().show();
                })
                .catch(err => {
                    console.error(`Could not load washers, errr: ${err}`);
                    message.error(this.props.t('FuelStationServices.message.carWashLoadError'));
                    this.setState(state => ({
                        wash: {
                            ...state.wash,
                            optionsData: {
                                onTheEntireMap: !state.controlOnRoute && !state.wash.optionsData.onTheEntireMap,
                                onRoute: state.controlOnRoute && !state.wash.optionsData.onRoute,
                                doNotDisplay: !state.wash.optionsData.doNotDisplay
                            }
                        }
                    }));
                });
            this._logic.map().routing().hideWashers();
            this._logic.map().routing().setWashRender(true);
        } else if (this.state.wash.optionsData.onRoute) {
            this._logic.map().routing().onWashClick(this._onWashClick);
            if (this.state.wash.optionsData.doNotDisplay) {
                this._logic.map().routing().hideWashers();
            } else {
                this._logic.map().routing().showWashers();
            }
            this._logic.map().routing().setWashRender(!this.state.wash.optionsData.doNotDisplay);
        } else if (this.state.wash.optionsData.doNotDisplay) {
            this._logic.map().washers().hide();
            this._logic.map().washers().onClick(undefined);
            this._logic.map().routing().hideWashers();
            this._logic.map().routing().onWashClick(undefined);
            this._logic.map().routing().setWashRender(false);
            this.setState(state => ({
                wash: {
                    ...state.wash,
                    washers: []
                }
            }));
        }
    };

    private _onPoiOptionsConfirm = (): void => {
        if (this.state.poi.optionsData.onTheEntireMap) {
            this._logic.map().pois().onClick(this._onPoiClick);
            this._logic
                .map()
                .pois()
                .onPoiClusterClick(() => {
                    this._logic.tracking().centerOffVehicles();
                });

            this._logic
                .poi()
                .pois()
                .then(pois => {
                    this._logic.map().pois().setData(pois);
                    this._logic.map().pois().show();
                });
            this._logic.map().routing().hidePois();
            this._logic.map().routing().setPoiRender(true);
        } else if (this.state.poi.optionsData.onRoute) {
            this._logic.map().routing().onPoiClick(this._onPoiClick);
            if (this.state.poi.optionsData.doNotDisplay) {
                this._logic.map().routing().hidePois();
            } else {
                this._logic.map().routing().showPois();
            }
            this._logic.map().routing().setPoiRender(!this.state.poi.optionsData.doNotDisplay);
        } else if (this.state.poi.optionsData.doNotDisplay) {
            this._logic.map().pois().hide();
            this._logic.map().pois().onClick(undefined);
            this._logic.map().routing().hidePois();
            this._logic.map().routing().onPoiClick(undefined);
            this._logic.map().routing().setPoiRender(false);
            this.setState(state => ({
                poi: {
                    ...state.poi,
                    pois: []
                }
            }));
        }
    };

    private _onParkingOptionsCancelClick = (): void => {
        this.setState(state => ({
            parking: {
                ...state.parking,
                filterOpen: !state.parking.filterOpen
            }
        }));
    };

    private _onParkingFilter = (): void => {
        this.setState(state => ({
            parking: {
                ...state.parking,
                filterOpen: !state.parking.filterOpen
            }
        }));
    };

    private _onMapContextMenuAddPlannerPlace = (type: 'start' | 'waypoint' | 'end'): void => {
        if (this.state.mapClickLatLng?.lat && this.state.mapClickLatLng?.lng) {
            const point: google.maps.LatLng = new google.maps.LatLng(
                this.state.mapClickLatLng.lat,
                this.state.mapClickLatLng.lng
            );
            const places = this._logic.plannerLogic().transport.places;
            const placesLength = places?.length ?? 0;
            let index = 0;
            if (type === 'end') {
                index = placesLength ?? 0;
            } else if (type !== 'start' && places?.length) {
                const nearestpoint = this._logic
                    .map()
                    .routing()
                    .nearestPoint(
                        { lat: point.lat(), lng: point.lng() },
                        this._logic
                            .plannerLogic()
                            .transport?.places!.map(
                                place =>
                                    ([(place.center as LatLng)?.lng ?? 0, (place.center as LatLng)?.lat ?? 0] ?? [
                                        0, 0
                                    ]) as [number, number]
                            )
                    );
                const placeindex = places.findIndex(
                    place =>
                        (place.center as LatLng)?.lat === nearestpoint.geometry.coordinates[0] &&
                        (place.center as LatLng)?.lng === nearestpoint.geometry.coordinates[1]
                );
                index = placeindex === 0 ? 1 : placeindex;
            }
            this._logic
                .dispatcherKitPoi()
                .geocodeLatLng({ lat: point.lat(), lng: point.lng() })
                .then(address => {
                    this._logic
                        .plannerLogic()
                        .addPlaceToTransport(
                            address.formattedAddress,
                            { lat: point.lat(), lng: point.lng() },
                            PlaceType.Waypoint,
                            index
                        )
                        .then(() =>
                            this._logic
                                .plannerLogic()
                                .planRoute(this._logic.plannerLogic().transport)
                                .then(() =>
                                    this._logic
                                        .plannerLogic()
                                        .drawRouteOnMap()
                                        .then(() => {
                                            this._logic.plannerLogic().onRouteChange();
                                            if (type !== 'waypoint' || placesLength <= 2) {
                                                this._logic.plannerLogic().updateTransportNameByPlaces();
                                            }
                                            this.setState({ loadingTransportPLanner: false });
                                        })
                                )
                        );
                });
        } else {
            this.setState({ loadingTransportPLanner: false });
        }
    };
}

export default withTranslation()(withRouter(MapModule));
