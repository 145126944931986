import React, { ReactNode, MouseEvent, useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import cn from 'classnames';

interface Props {
    trigger?: ReactNode;
}

function EnhancedMonthPicker(props: PickerDateProps<Moment>) {
    const [dateTime, setDateTime] = useState(props.defaultValue);

    const onChange = (value: Moment | null) => {
        props.onChange?.(value, value?.toString() ?? '');
        value && setDateTime(value);
    };

    return (
        <DatePicker
            {...props}
            picker="month"
            onChange={(value, _dateString) => onChange(value)}
            value={dateTime}
            monthCellRender={currntDate => (
                <div
                    className={cn('ant-picker-cell-inner', {
                        'ant-picker-cell-now': currntDate.month() === moment().month()
                    })}
                >
                    {currntDate.format('MMM')}
                </div>
            )}
        />
    );
}

function EnhancedMonthPickerWithTrigger({ trigger, ...props }: Props & PickerDateProps<Moment>) {
    const [open, setOpen] = useState(false);

    function onPickerOpen(_e: MouseEvent<HTMLDivElement>) {
        !open && setOpen(true);
    }

    function onOpenChange(value: boolean) {
        setOpen(value);
    }

    if (trigger) {
        return (
            <div
                className={cn('ant-picker-month-wrapper', { 'ant-picker-month-wrapper-disabled': props.disabled })}
                onClick={onPickerOpen}
            >
                {trigger}
                {open && <EnhancedMonthPicker {...props} open={open} onOpenChange={onOpenChange} />}
            </div>
        );
    }

    return <EnhancedMonthPicker {...props} />;
}
export default EnhancedMonthPickerWithTrigger;
