import cn from 'classnames';
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Table, Button, Pagination } from 'common/components';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { Row, Col } from 'antd';
import qa from 'qa-selectors';
import { getTableCurrentPage } from 'common/utils/tableUtils';

export interface EWCustomerAccess {
    id: string;
    access: string;
    vehicles: string;
}

interface Props {
    data?: PaginatedResponse<EWCustomerAccess[]>;
    loading?: boolean;
    selected?: EWCustomerAccess;
    onTableRowSelect?: (id: string) => void;
    onRemoveClick?: (id: string) => void;
    onOpenLink?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

export default function EWCustomerAccessTable(props: Props) {
    const { data, loading } = props;
    const { t } = useTranslation();

    function createColumns(): ColumnsType<EWCustomerAccess> {
        return [
            {
                title: t('EWCustomerAccessTable.vehicle'),
                dataIndex: 'vehicles',
                key: 'vehicles',
                width: 60,
                render: (_, row) => (
                    <span data-qa={qa.systemConnections.customerAccess.table.fieldVehicles}>{row.vehicles}</span>
                )
            },
            {
                title: t('EWCustomerAccessTable.access'),
                dataIndex: 'access',
                key: 'access',
                width: 90,
                render: (_, row) => (
                    <span data-qa={qa.systemConnections.customerAccess.table.fieldAccess}>{row.access}</span>
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                align: 'right',
                width: 100,
                render: (_, v) => (
                    <Row className="sys-connections-table-actions" justify="end" gutter={10}>
                        <Col>
                            <Button
                                onClick={() => props.onOpenLink?.(v.id)}
                                qa={qa.systemConnections.customerAccess.table.btnOpenLink}
                            >
                                {t('EWCustomerAccessTable.openLink')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="link"
                                onClick={() => props.onRemoveClick?.(v.id)}
                                data-qa={qa.systemConnections.customerAccess.table.btnDelete}
                                icon={<img alt="delete" src="/img-svg/icn-delete.svg" />}
                            />
                        </Col>
                    </Row>
                )
            }
        ];
    }

    return (
        <div className="sys-connections-table">
            <Table<EWCustomerAccess>
                loading={loading}
                dataSource={data?.data?.map(e => ({ ...e, key: e.id }))}
                bordered={false}
                columns={createColumns()}
                onRow={record => ({
                    onClick: () => props.onTableRowSelect?.(record.id),
                    className: cn({
                        'ant-table-row-selected': props.selected?.id === record.id
                    })
                })}
            />
            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );
}
