import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { MAP_MARKERS_INDEX } from 'domain-constants';
import { google } from 'google-maps';
import { MapConf, MapLogic } from '../map';
import { PoiModelMap } from './fuelStations';
import { PoiMarker } from './poi-marker';
import * as mapMarkers from 'resources/images/map';

export class PoisMapController {
    private _conf: MapConf;
    private _ctrl: MapLogic;
    private _map?: google.maps.Map;
    private _markers: PoiMarker[];
    private _cluster?: MarkerClusterer;
    private _google: google;

    private _onPoiClick?: (poi: PoiModelMap) => void;
    private _onPoiClusterClick?: () => void;

    private readonly _clusterOptions: MarkerClustererOptions = {
        maxZoom: 20,
        gridSize: 150,
        zoomOnClick: false,
        enableRetinaIcons: false,
        clusterClass: 'poi-cluster',
        styles: [
            {
                url: mapMarkers.poi,
                height: 49,
                width: 64,
                textSize: 18,
                textColor: '#000000'
            }
        ],
        zIndex: MAP_MARKERS_INDEX.POI
    };

    constructor(conf: MapConf, google: google, ctrl: MapLogic, map?: google.maps.Map) {
        this._map = map;
        this._conf = conf;
        this._google = google;
        this._markers = [];
        this._ctrl = ctrl;
    }

    show(): void {
        this._renderCluster();
    }

    hide(): void {
        if (this._cluster) {
            this._cluster.clearMarkers();
            this._cluster.setMap(null);
        }
    }

    onClick(cb?: (poi: PoiModelMap) => void): void {
        this._onPoiClick = cb;
    }

    onPoiClusterClick(cb?: () => void): void {
        this._onPoiClusterClick = cb;
    }

    setData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster!.clearMarkers();
        }
        this._markers = data.map(poi => this._createPoiMarker(poi));
    }

    updateData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster!.clearMarkers();
        }
        this._markers = data.map(poi => this._createPoiMarker(poi));
        this._renderCluster();
    }

    updateDetailData(data: PoiModelMap[]): void {
        this._markers = data.map(poi => this._createPoiMarker(poi));
        this._renderCluster();
    }

    private _onClusterClick = (cluster: MarkerClusterer) => {
        const bounds = new this._google.maps.LatLngBounds();

        cluster.getMarkers().forEach(marker => bounds.extend(marker.getPosition()!));

        this._onPoiClusterClick?.();
        this._map?.fitBounds(bounds, this._ctrl.getPadding());
    };

    private _renderCluster(): void {
        if (this._map) {
            if (this._cluster) {
                this._cluster.clearMarkers();
            }
            this._cluster = new MarkerClusterer(this._map, this._markers as any, this._clusterOptions);
            this._cluster!.addListener('clusterclick', this._onClusterClick);
        }
    }

    private _createPoiMarker(poi: PoiModelMap): PoiMarker {
        const marker = new PoiMarker(this._google, new this._google.maps.LatLng(poi.position.lat, poi.position.lng), {
            type: 'poi',
            data: { ...poi }
        });

        marker.onPoiClick(poiData => {
            if (poiData.type === 'poi') {
                this._onPoiClick?.(poiData.data);
            }
        });

        return marker;
    }
}
