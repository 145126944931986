import { Logic } from 'logic/logic';
import moment from 'moment';
import { CompanyType, BillingCycle, PartnerCompanyModel, Styles } from './partner-partners';
import { ReadOnlyAddressNested, ReadOnlyClient } from 'generated/new-main';
import { PartnerAddressModel } from './partner-address';
import { PartnerContactPersonModel } from './partner-contact-person';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { AvailableCurrencies } from 'common/model/currency';
import { PartnerPairingKey } from 'common/model/partner-pairing';

export class PartnerCompaniesLogic {
    constructor(private _logic: Logic) {}

    toCompany(
        data: ReadOnlyClient,
        addresses?: PartnerAddressModel[],
        contactPersons?: PartnerContactPersonModel[]
    ): PartnerCompanyModel {
        return {
            id: String(data.id),
            name: data.name ?? '',
            referrer: data.referrer ? data.referrer : undefined,
            billingCode: data.billingCode ?? '',
            identificationNumber: data.identificationNumber ?? '',
            vatNumber: data.vatNumber ?? '',
            creationDate: moment(), // TODO fix
            terminationDate: moment().add(1, 'years'), // TODO fix
            companyType: (data.fieldOfActivity as CompanyType) ?? CompanyType.Individual,
            vehiclesNo: 20, // TODO fix
            ssoId: data.externalId,
            billingCycle: BillingCycle.Monthly, // TODO fix
            currency: (data.country?.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR,
            discount: 10, // TODO fix
            logo: data.logo ?? '',
            styles: (data.styles ?? {}) as Styles,
            isPartner: data.isPartner,
            fleetType: data.fleetType,
            address: addresses
                ? addresses
                : (data.addressNew as ReadOnlyAddressNested[])?.map(this._logic.partner().address().toAddress),
            contactPerson: contactPersons
                ? contactPersons
                : data.contactpersonSet?.map(cp => this._logic.partner().contactPerson().toContactPerson(cp)),
            costPerKm: data.costPerKm as PartnerCompanyModel['costPerKm'],
            links: data.referrer
                ? [
                      {
                          id: 'partner' + data.referrer,
                          key: PartnerPairingKey.partners,
                          rows: [
                              {
                                  name: 'partner',
                                  value: String(data.referrer)
                              }
                          ]
                      }
                  ]
                : undefined
        };
    }

    async getCompanyList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        nameIcontains?: string
    ): Promise<PaginatedResponse<PartnerCompanyModel[]>> {
        try {
            const response = await this._logic.api().newClientApi.clientList({
                isPartner: false,
                nameIcontains,
                clientOrReferrer: clientId,
                limit,
                offset
            });
            return {
                data: response.results.map(res => this.toCompany(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Company client list GET err:', err);
            throw err;
        }
    }
    async getClientList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        nameIcontains?: string
    ): Promise<PaginatedResponse<PartnerCompanyModel[]>> {
        try {
            const response = await this._logic.api().newClientApi.clientList({
                nameIcontains,
                clientOrReferrer: clientId,
                limit,
                offset
            });
            return {
                data: response.results.map(res => this.toCompany(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Company client list GET err:', err);
            throw err;
        }
    }
    async getCompany(clientId?: number): Promise<PartnerCompanyModel> {
        try {
            const id = clientId ? clientId : Number(this._logic.auth().newClient()?.id!);
            const response = await this._logic.api().newClientApi.clientRead({ id: id });
            return this.toCompany(response);
        } catch (err) {
            console.log('Company client list GET err:', err);
            throw err;
        }
    }

    async updateCompanyLogo(company: PartnerCompanyModel): Promise<PartnerCompanyModel> {
        try {
            const response = await this._logic.api().newClientApi.clientPartialUpdate({
                id: Number(company.id),
                data: {
                    name: company.name,
                    logo: company.logo
                }
            });
            return this.toCompany(response);
        } catch (err) {
            console.log('Company client POST err:', err);
            throw err;
        }
    }

    async addCompany(company: PartnerCompanyModel): Promise<PartnerCompanyModel> {
        try {
            let contactPersonAddress;
            const responseClient = await this._logic.api().newClientApi.clientCreate({
                data: {
                    externalSystem: 5,
                    language: 'ENG',
                    languageIso6391: 'en',
                    isPartner: false,
                    name: company.name,
                    billingCode: company.billingCode,
                    identificationNumber: company.identificationNumber,
                    vatNumber: company.vatNumber,
                    taxNumber: 'TAX NUMBER', // TODO: Fix VAT
                    // fieldOfActivity: company.personPosition,
                    bankingConnection: 'SK00123asf1384545475',
                    currency: company.currency as string,
                    fieldOfActivity: company.companyType, // TODO field Of axtivity
                    // creationDate: moment(),
                    // terminationDate: moment(),
                    // companyType: CompanyType.Individual,
                    // vehiclesNo: 20,
                    // billingCycle: BillingCycle.Monthly,
                    // discount: 10,
                    // addressStreet: 'b',
                    // addressStreetNo: 'b',
                    // addressCity: 'b',
                    // addressCountry: 'b',
                    // addressZipCode: 'b',
                    // personName: 'b',
                    // personSurname: 'b',
                    // personEmail: 'b',
                    // personPhone: 'b',
                    // personPosition: 'b',
                    // personAddressStreet: 'a',
                    // personAddressStreetNo: 'a',
                    // personAddressCity: 'a',
                    // personAddressCountry: 'a',
                    // personAddressZipCode: 'a',
                    logo: company.logo ?? '',
                    styles: company.styles ?? {}
                }
            });
            if (company.address) {
                await this._logic.partner().address().addAddress(company.address?.[0], responseClient.id); // TODO: now just one address from FE can add
                if (company.address[1]?.city) {
                    await this._logic.partner().address().addAddress(company.address?.[1], responseClient.id); // TODO: now just one address from FE can add
                }
            }
            if (company.contactPerson) {
                if (company.contactPerson?.[0].address) {
                    contactPersonAddress = await this._logic
                        .partner()
                        .address()
                        .addAddress(company.contactPerson?.[0].address?.[0]);
                    // TODO: now just one address from FE can add
                }
                await this._logic
                    .partner()
                    .contactPerson()
                    .addContactPerson(company.contactPerson?.[0], responseClient.id!, contactPersonAddress?.id!); // TODO: now just one address from FE can add

                // TODO :
                //                     /client/${CLIENT_ID}/create_price_lists
                // entpoint na pridelenie prav klientovemu admin userovi (contact_person) a zaroven prekopirovanie price listov
                // await await this._logic.api().newClientApi.clientCreatePriceLists({
                //     id: responseClient.id!,
                //     content_type: 'Service',
                //     objects: company.services
                // });
            }
            return this.toCompany(responseClient);
        } catch (err) {
            console.log('Company client POST err:', err);
            throw err;
        }
    }

    async deleteCompany(company: PartnerCompanyModel): Promise<Boolean> {
        try {
            const response = await this._logic.api().newClientApi.clientDelete({ id: Number(company.id) });
            return response ? true : false;
        } catch (err) {
            console.log('Company client DELETE err:', err);
            throw err;
        }
    }
}
