// TODO: After integration move to common forms
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import { SelectField, DatePickerField } from 'common/fields';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import qa from 'qa-selectors';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

export interface GrantAccessFormValues {
    vehicles: string[];
    start: string;
    end: string;
}

interface Props {
    type?: 'default' | 'edit';
    initialValues?: Partial<GrantAccessFormValues>;
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    lang: string;
    onSubmit?: (values: GrantAccessFormValues) => Promise<boolean>;
    onCancel?: () => void;
}

export default function GrantAccessForm({ initialValues, type, vehicles, lang, onSubmit, onCancel }: Props) {
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        vehicles: Yup.array(Yup.string().required(t('common.required'))).min(1),
        start: Yup.string().required(t('common.required')),
        end: Yup.string().required(t('common.required'))
    });

    function handleOnSubmit(values: GrantAccessFormValues, helpers: FormikHelpers<GrantAccessFormValues>) {
        return onSubmit?.(values).then(success => {
            if (success) {
                helpers.resetForm();
            }
            return success;
        });
    }
    return (
        <Formik<GrantAccessFormValues>
            initialValues={{
                vehicles: initialValues?.vehicles ?? [],
                start: initialValues?.start ?? '',
                end: initialValues?.end ?? ''
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<GrantAccessFormValues>) => (
                <div className="grant-access-form">
                    <Form>
                        <SelectField
                            options={vehicles
                                .filter(v => !v.disabledAt)
                                .map(d => ({
                                    value: d.id ?? 0,
                                    label: d.registrationNumber
                                }))}
                            mode="multiple"
                            name="vehicles"
                            label={t('GrantAccessForm.vehicle')}
                            placeholder={t('GrantAccessForm.vehicle')}
                            qa={qa.systemConnections.customerAccess.actions.selectVehicle}
                        />
                        {formik.values.vehicles.length > 0 && (
                            <>
                                <DatePickerField
                                    name="start"
                                    lang={lang}
                                    showTime={{ format: TIME_FORMAT_SHORT }}
                                    format="L"
                                    disabledDate={current =>
                                        formik.values.end ? current.isAfter(formik.values.end) : false
                                    }
                                    label={t('GrantAccessForm.start')}
                                    placeholder={t('GrantAccessForm.start')}
                                    qa={qa.systemConnections.customerAccess.actions.inputDateFrom}
                                />
                                <DatePickerField
                                    name="end"
                                    lang={lang}
                                    showTime={{ format: TIME_FORMAT_SHORT }}
                                    disabledDate={current =>
                                        formik.values.start ? current.isBefore(formik.values.start) : false
                                    }
                                    label={t('GrantAccessForm.end')}
                                    placeholder={t('GrantAccessForm.end')}
                                    qa={qa.systemConnections.customerAccess.actions.inputDateTo}
                                />
                            </>
                        )}
                        <Button
                            htmlType="submit"
                            type="primary"
                            block
                            qa={qa.systemConnections.customerAccess.actions.btnSubmit}
                            loading={formik.isSubmitting}
                        >
                            {type === 'edit' ? t('common.edit') : t('common.confirm')}
                        </Button>
                        <Button
                            onClick={() => {
                                formik.setValues({ end: '', start: '', vehicles: [] });
                                onCancel?.();
                            }}
                            block
                            qa={qa.systemConnections.customerAccess.actions.btnCancel}
                        >
                            {t('common.cancel')}
                        </Button>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
