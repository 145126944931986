import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { Tooltip, Table } from 'common/components';
import qa from 'qa-selectors';
import { PoiModel } from 'common/model/poi';
import { TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';

interface Props extends WithTranslation {
    selectedRowId?: string;
    data?: PoiModel[];
    loading?: boolean;
    onRowSelect?: (customPlace: PoiModel) => void;
}

function JourneysTable(props: Props) {
    const { t } = props;

    function createColumns(): ColumnsType<PoiModel> {
        return [
            {
                title: t('PoiTable.nameOfPlace'),
                dataIndex: 'name',
                key: 'name',
                width: 80,
                render: value => (
                    <Tooltip placement="topLeft" title={value} qa={qa.poi.table.fieldName}>
                        {value}
                    </Tooltip>
                )
            },
            {
                title: t('PoiTable.typeOfPlace'),
                dataIndex: 'type',
                key: 'type',
                width: 70,
                render: value => (
                    <Tooltip placement="topLeft" title={t(`PoiForm.${String(value)}`)} qa={qa.poi.table.fieldType}>
                        {t(`PoiForm.${String(value)}`)}
                    </Tooltip>
                )
            },
            {
                dataIndex: 'forbidden',
                key: 'forbidden',
                width: 30,
                render: value =>
                    value && (
                        <i
                            className="fa fa-exclamation-triangle t-text-danger t-large"
                            aria-hidden="true"
                            data-qa={qa.poi.table.fieldForbiden}
                        />
                    )
            }
        ];
    }

    return (
        <Table<PoiModel>
            loading={props.loading}
            dataSource={props.data?.map(e => ({ ...e, key: e.id }))}
            columns={createColumns()}
            scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
            onRow={record => ({
                onClick: () => onRowClick?.(record),
                className: cn({
                    'ant-table-row-selected': props.selectedRowId === record.id
                })
            })}
        />
    );

    function onRowClick(customPlace: PoiModel): void {
        props.onRowSelect?.(customPlace);
    }
}

export default withTranslation()(JourneysTable);
