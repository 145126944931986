/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManualRate,
    ManualRateFromJSON,
    ManualRateFromJSONTyped,
    ManualRateToJSON,
} from './';

/**
 * 
 * @export
 * @interface AppliedDiemsRules
 */
export interface AppliedDiemsRules {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof AppliedDiemsRules
     */
    shortenings?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppliedDiemsRules
     */
    pocketMoney?: { [key: string]: number; };
    /**
     * 
     * @type {ManualRate}
     * @memberof AppliedDiemsRules
     */
    manualRate?: ManualRate;
}

export function AppliedDiemsRulesFromJSON(json: any): AppliedDiemsRules {
    return AppliedDiemsRulesFromJSONTyped(json, false);
}

export function AppliedDiemsRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppliedDiemsRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortenings': !exists(json, 'shortenings') ? undefined : json['shortenings'],
        'pocketMoney': !exists(json, 'pocket_money') ? undefined : json['pocket_money'],
        'manualRate': !exists(json, 'manual_rate') ? undefined : ManualRateFromJSON(json['manual_rate']),
    };
}

export function AppliedDiemsRulesToJSON(value?: AppliedDiemsRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortenings': value.shortenings,
        'pocket_money': value.pocketMoney,
        'manual_rate': ManualRateToJSON(value.manualRate),
    };
}


