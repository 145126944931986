/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectFleetDynamicDataTrailerDynamic
 */
export interface MonitoredObjectFleetDynamicDataTrailerDynamic {
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFleetDynamicDataTrailerDynamic
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectFleetDynamicDataTrailerDynamic
     */
    pairedSince: Date;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFleetDynamicDataTrailerDynamic
     */
    serialNumber: string;
}

export function MonitoredObjectFleetDynamicDataTrailerDynamicFromJSON(json: any): MonitoredObjectFleetDynamicDataTrailerDynamic {
    return MonitoredObjectFleetDynamicDataTrailerDynamicFromJSONTyped(json, false);
}

export function MonitoredObjectFleetDynamicDataTrailerDynamicFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFleetDynamicDataTrailerDynamic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'pairedSince': (new Date(json['paired_since'])),
        'serialNumber': json['serial_number'],
    };
}

export function MonitoredObjectFleetDynamicDataTrailerDynamicToJSON(value?: MonitoredObjectFleetDynamicDataTrailerDynamic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'paired_since': (value.pairedSince.toISOString()),
        'serial_number': value.serialNumber,
    };
}


