import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';
import { EFuelType } from 'generated/graphql';
import { ReadOnlyMonitoredObjectType } from 'generated/new-main';

interface Props {
    data: PartnerVehicleModel;
    vehicleTypes?: ReadOnlyMonitoredObjectType[];
}

export default function VehiclesDetailCard(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="tlm-services-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('PartnerVehicles.licensePlate')}>
                            {props.data.licensePlate}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.brand')}>{props.data.brand}</Descriptions.Item>
                        <Descriptions.Item label={t('common.type')}>
                            {t(`Partner.${String(props.vehicleTypes?.find(e => e.id === props.data.typeId)?.name)}`)}
                        </Descriptions.Item>
                        {props.data.fuelType !== EFuelType.Electro ? (
                            <Descriptions.Item label={t('PartnerVehicles.fuelTank')}>
                                {props.data.fuelTank}
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                    </Descriptions>
                }
            />
        </div>
    );
}
