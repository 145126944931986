/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExternalDeviceState,
    ExternalDeviceStateFromJSON,
    ExternalDeviceStateToJSON,
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGetRequest {
    deviceType: ExternalDeviceType;
    deviceId: Array<string>;
}

/**
 * no description
 */
export class ExternalDeviceApi extends runtime.BaseAPI {

    /**
     * Get External Device State
     */
    async getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGetRaw(requestParameters: GetExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGetRequest): Promise<runtime.ApiResponse<Array<ExternalDeviceState>>> {
        if (requestParameters.deviceType === null || requestParameters.deviceType === undefined) {
            throw new runtime.RequiredError('deviceType','Required parameter requestParameters.deviceType was null or undefined when calling getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGet.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceType !== undefined) {
            queryParameters['device_type'] = requestParameters.deviceType;
        }

        if (requestParameters.deviceId) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/external-device-state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalDeviceStateFromJSON));
    }

    /**
     * Get External Device State
     */
    async getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGet(requestParameters: GetExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGetRequest): Promise<Array<ExternalDeviceState>> {
        const response = await this.getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGetRaw(requestParameters);
        return await response.value();
    }

}
