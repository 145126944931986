import { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { InputField, SelectField } from 'common/fields';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';

import {
    MonitoredObjectFeFleetTemperatureSensor,
    MonitoredObjectTrailerProfileTypeEnum
} from 'generated/new-main/models';
import TemperatureZonesEditor from 'modules/management/modules/fleet/components/TemperatureZonesEditor';
import { Role } from 'logic/auth';

export interface TrailerFormModel {
    id?: number;
    name?: string;
    registrationNumber?: string;
    manufacturer?: string;
    cargoType?: string;
    trailerType?: MonitoredObjectTrailerProfileTypeEnum;
    trailerIdSerialNumber?: string;
    height?: number;
    length?: number;
    width?: number;
    weight?: number;
    weightFull?: number;
    temperatureSensors?: MonitoredObjectFeFleetTemperatureSensor[];
}

interface Props extends WithTranslation {
    initialValues?: Partial<TrailerFormModel>;
    mode: 'EDIT' | 'CREATE';
    roles: Role[];
    onSubmit?: (values: TrailerFormModel) => void;
    onCancel?: () => void;
}

function TrailerForm(props: Props) {
    let form: FormikProps<TrailerFormModel>;
    const [zones, setZones] = useState<number[]>(
        Array.from(
            {
                length: Math.max(
                    ...(props.initialValues?.temperatureSensors?.map(sensor => sensor.sensorZone ?? 0) ?? [])
                )
            },
            (_: number, i: number) => i + 1
        )
    );

    const schema = Yup.object().shape({
        name:
            props.mode === 'EDIT'
                ? Yup.string().trim().strict(true)
                : Yup.string().trim().strict(true).required(props.t('common.required')),
        cargoType: Yup.string().trim().strict(true),
        manufacturer:
            props.mode === 'EDIT'
                ? Yup.string().trim().strict(true)
                : Yup.string().trim().strict(true).required(props.t('common.required')),
        registrationNumber:
            props.mode === 'EDIT'
                ? Yup.string()
                      .trim()
                      .strict(true)
                      .max(30, props.t('validator.maxLength', { max: 30 }))
                : Yup.string()
                      .trim()
                      .strict(true)
                      .required(props.t('common.required'))
                      .max(30, props.t('validator.maxLength', { max: 30 })),
        trailerType: Yup.mixed<MonitoredObjectTrailerProfileTypeEnum>().oneOf(
            Object.values(MonitoredObjectTrailerProfileTypeEnum)
        ),
        height: Yup.number()
            .nullable()
            .required(props.t('common.required'))
            .max(50, props.t('validator.maxValue', { max: 50 }))
            .min(1, props.t('validator.minValue', { min: 1 })),
        length: Yup.number()
            .nullable()
            .required(props.t('common.required'))
            .max(50, props.t('validator.maxValue', { max: 50 }))
            .min(1, props.t('validator.minValue', { min: 1 })),
        weight: Yup.number()
            .nullable()
            .required(props.t('common.required'))
            .max(44, props.t('validator.maxValue', { max: 44 }))
            .min(1, props.t('validator.minValue', { min: 1 })),
        width: Yup.number()
            .nullable()
            .required(props.t('common.required'))
            .max(50, props.t('validator.maxValue', { max: 50 }))
            .min(1, props.t('validator.minValue', { min: 1 })),
        weightFull: Yup.number()
            .nullable()
            .required(props.t('common.required'))
            .max(44, props.t('validator.maxValue', { max: 44 }))
            .min(1, props.t('validator.minValue', { min: 1 }))
    });

    function onSensorDragEnd(sensorId: string, targetZone: number) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.id === sensorId
                ? {
                      ...sensor,
                      sensorZone: targetZone
                  }
                : sensor
        );
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function onZoneDeleteClick(zone: number) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.sensorZone && sensor.sensorZone >= zone
                ? {
                      ...sensor,
                      sensorZone: sensor.sensorZone - 1
                  }
                : sensor
        );
        setZones(zones.slice(0, zones.length - 1));
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function onZoneAddClick() {
        setZones(zones.concat(zones.length + 1));
    }

    function onSensorNameChange(sensorId: string, sensorName: string) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.id === sensorId
                ? {
                      ...sensor,
                      sensorName
                  }
                : sensor
        );
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function handleSubmit(values: TrailerFormModel) {
        return props.onSubmit?.(values);
    }

    return (
        <Formik<TrailerFormModel>
            initialValues={{
                id: props.initialValues?.id,
                name: props.initialValues?.name,
                registrationNumber: props.initialValues?.registrationNumber,
                manufacturer: props.initialValues?.manufacturer,
                cargoType: props.initialValues?.cargoType,
                trailerIdSerialNumber: props.initialValues?.trailerIdSerialNumber,
                weightFull: props.initialValues?.weightFull,
                height: props.initialValues?.height,
                length: props.initialValues?.length,
                trailerType: props.initialValues?.trailerType,
                weight: props.initialValues?.weight,
                width: props.initialValues?.width,
                temperatureSensors: props.initialValues?.temperatureSensors
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<TrailerFormModel>) => {
                form = formik;

                return (
                    <Form className="trailer-form">
                        <Row gutter={[20, 10]}>
                            <Col span={props.mode === 'EDIT' ? 24 : 12}>
                                <h2>{props.t('ManagementFleet.registration')}</h2>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField
                                            disabled={props.mode === 'EDIT'}
                                            label={props.t('common.registrationNumber')}
                                            maxLength={30}
                                            name="registrationNumber"
                                            placeholder={props.t('common.registrationNumber')}
                                            qa={qa.fleet.trailer.inputRn}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField
                                            label={props.t('common.ownName')}
                                            data-qa={qa.fleet.trailer.inputName}
                                            name="name"
                                            placeholder={props.t('common.ownName')}
                                            qa={qa.users.form.inputName}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField
                                            label={props.t('common.manufacturer')}
                                            name="manufacturer"
                                            disabled={props.mode === 'EDIT'}
                                            placeholder={props.t('common.manufacturer')}
                                            qa={qa.fleet.trailer.inputManufacturer}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField
                                            label={props.t('ManagementFleet.cargoType')}
                                            name="cargoType"
                                            placeholder={props.t('ManagementFleet.cargoType')}
                                            qa={qa.fleet.trailer.inputCargoType}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <SelectField
                                            label={props.t('ManagementFleet.trailerType')}
                                            name="trailerType"
                                            options={Object.values(MonitoredObjectTrailerProfileTypeEnum).map(
                                                trailerType => {
                                                    return {
                                                        value: trailerType,
                                                        label: props.t(`TrailerTypes.${trailerType}`)
                                                    };
                                                }
                                            )}
                                            qa={qa.fleet.trailer.inputTrailerType}
                                        />
                                    </Col>
                                    {props.mode === 'EDIT' && (
                                        <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                            <InputField
                                                label={props.t('ManagementFleet.serialNumberTrailer')}
                                                name="trailerIdSerialNumber"
                                                placeholder={props.t('ManagementFleet.serialNumberTrailer')}
                                                qa={qa.fleet.trailer.inputCargoType}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {props.roles.includes(Role.CLD_W) &&
                                    props.mode === 'EDIT' &&
                                    !!form.values.temperatureSensors?.length && (
                                        <TemperatureZonesEditor
                                            sensors={form.values.temperatureSensors}
                                            zones={zones}
                                            onZoneDeleteClick={onZoneDeleteClick}
                                            onZoneAddClick={onZoneAddClick}
                                            onSensorDragEnd={onSensorDragEnd}
                                            onSensorNameChange={onSensorNameChange}
                                        />
                                    )}
                            </Col>
                            <Col span={props.mode === 'EDIT' ? 24 : 12}>
                                <h2>{props.t('ManagementFleet.dimensions')}</h2>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField.Number
                                            label={`${props.t('common.height')} [${props.t('common.metrics.m')}]`}
                                            name="height"
                                            qa={qa.fleet.trailer.inputHeight}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField.Number
                                            label={`${props.t('common.length')} [${props.t('common.metrics.m')}]`}
                                            name="length"
                                            qa={qa.fleet.trailer.inputLength}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField.Number
                                            label={`${props.t('common.width')} [${props.t('common.metrics.m')}]`}
                                            name="width"
                                            qa={qa.fleet.trailer.inputWidth}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField.Number
                                            label={`${props.t('common.weight')} [${props.t('common.metrics.tons')}]`}
                                            name="weight"
                                            qa={qa.fleet.trailer.inputWeight}
                                        />
                                    </Col>
                                    <Col span={props.mode === 'EDIT' ? 12 : 24}>
                                        <InputField.Number
                                            label={`${props.t('ManagementFleet.fullLoadWeight')} [${props.t(
                                                'common.metrics.tons'
                                            )}]`}
                                            name="weightFull"
                                            qa={qa.fleet.trailer.inputFullWeight}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <FormActions
                            cancelQa={qa.fleet.trailer.btnCancel}
                            submitQa={qa.fleet.trailer.btnSubmit}
                            submitLoading={formik.isSubmitting}
                            onCancelClick={props.onCancel}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(TrailerForm);
