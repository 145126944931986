import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { InputField } from 'common/fields';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';

export interface UserDriverFormModel {
    name?: string;
    surname?: string;
    username?: string;
    pin?: string;
}

interface Props extends WithTranslation {
    initialValues?: Partial<UserDriverFormModel>;
    userDriverFormikRef?: React.RefObject<FormikProps<UserDriverFormModel>>;
    onSubmit?: (values: UserDriverFormModel) => Promise<boolean>;
    onChange?: (values: UserDriverFormModel) => void;
    onCancel?: () => void;
}

function UserDriverForm(props: Props) {
    const schema = Yup.object().shape({
        name: Yup.string()
            .required(props.t('common.required'))
            .matches(/^[a-zA-ZÀ-ž ]*[a-zA-ZÀ-ž]+[a-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
        surname: Yup.string()
            .required(props.t('common.required'))
            .matches(/^[a-zA-ZÀ-ž ]*[a-zA-ZÀ-ž]+[a-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
        pin: Yup.string()
            .required(props.t('common.required'))
            .min(0)
            .max(8, props.t('validator.not_in_range'))
            .matches(/^[0-9]+$/, props.t('validator.invalid_format')),
        username: Yup.string()
            .required(props.t('common.required'))
            .matches(/^[^\s].+[^\s]$/, props.t('validator.invalid_format'))
    });

    function handleSubmit(values: UserDriverFormModel) {
        return props.onSubmit?.(values);
    }

    return (
        <Formik<UserDriverFormModel>
            initialValues={{
                name: props.initialValues?.name,
                surname: props.initialValues?.surname,
                username: props.initialValues?.username,
                pin: props.initialValues?.pin
            }}
            innerRef={props.userDriverFormikRef}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(_formik: FormikProps<UserDriverFormModel>) => {
                return (
                    <>
                        <Form className="user-form-driver">
                            <label>
                                {props.t('ManagementUsers.createUser')}{' '}
                                <span className="tacho-card-form-users-type">({props.t('common.driver')})</span>
                            </label>
                            <Row className="form-row" gutter={24}>
                                <Col span={24}>
                                    <InputField
                                        size="large"
                                        label={props.t('common.username')}
                                        name="username"
                                        placeholder={props.t('common.username')}
                                        qa={qa.users.form.inputUsername}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-row" gutter={24}>
                                <Col span={24}>
                                    <InputField
                                        size="large"
                                        label={props.t('common.name')}
                                        name="name"
                                        placeholder={props.t('common.name')}
                                        qa={qa.users.form.inputName}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-row" gutter={24}>
                                <Col span={24}>
                                    <InputField
                                        size="large"
                                        label={props.t('common.surname')}
                                        name="surname"
                                        placeholder={props.t('common.surname')}
                                        qa={qa.users.form.inputSurname}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-row" gutter={24}>
                                <Col span={24}>
                                    <InputField
                                        size="large"
                                        label={props.t('common.pin')}
                                        name="pin"
                                        placeholder={props.t('common.pin')}
                                        qa={qa.users.form.inputPin}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(UserDriverForm);
