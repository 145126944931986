import { useTranslation } from 'react-i18next';
import { FleetModel } from '../../FleetModule';
import { Button } from 'common/components';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import { Row, Col } from 'antd';

interface Props {
    data: FleetModel;
    demoMode?: boolean;
    roles: Role[];
    onUpdateClick?: () => void;
    onActiveClick?: () => void;
    onDisableClick?: () => void;
    onPairNewAssetButtonClick?: () => void;
    onAssignToVehicleGroupClick?: () => void;
}

export default function FleetVehicleActions(props: Props) {
    const { data } = props;
    const { t } = useTranslation();

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onUpdateClick}
                        type="primary"
                        disabled={props.demoMode || !props.roles.includes(Role.V_W) || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-pencil-alt" />}
                    >
                        {t('ManagementFleet.updateData')}
                    </Button>
                </Col>
                <Col span={12}>
                    {!!data.disabledAt && (
                        <Button
                            block
                            onClick={() => props.onActiveClick?.()}
                            type="primary"
                            disabled={props.demoMode}
                            qa={qa.fleet.detail.btnActivateVehicle}
                            icon={<i className="fa fa-refresh" />}
                        >
                            {t('ManagementFleet.activateVehicle')}
                        </Button>
                    )}
                    {!data.disabledAt && (
                        <Button
                            block
                            onClick={() => props.onDisableClick?.()}
                            type="primary"
                            disabled={props.demoMode}
                            qa={qa.fleet.detail.btnDisableVehicle}
                            icon={<i className="fas fa-fw fa-ban" />}
                        >
                            {t('ManagementFleet.disableVehicle')}
                        </Button>
                    )}
                </Col>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onAssignToVehicleGroupClick}
                        type="primary"
                        disabled={props.demoMode || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnTags}
                        icon={<i className="fa fa-share-alt" />}
                    >
                        {t('ManagementFleet.assignToVehicleGroup')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onPairNewAssetButtonClick}
                        type="primary"
                        disabled={props.demoMode || !props.roles.includes(Role.V_IE) || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnAddLinkedItem}
                        icon={<i className="fas fa-fw fa-link" />}
                    >
                        {t('ManagementFleet.addAnotherItem')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
