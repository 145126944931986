/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportTemplateRouteOptionsSygic,
    TransportTemplateRouteOptionsSygicFromJSON,
    TransportTemplateRouteOptionsSygicFromJSONTyped,
    TransportTemplateRouteOptionsSygicToJSON,
    TransportTemplateTransportPlace,
    TransportTemplateTransportPlaceFromJSON,
    TransportTemplateTransportPlaceFromJSONTyped,
    TransportTemplateTransportPlaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportTemplateTransport
 */
export interface TransportTemplateTransport {
    /**
     * 
     * @type {string}
     * @memberof TransportTemplateTransport
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransportTemplateTransport
     */
    fixedName?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransportTemplateTransport
     */
    desiredVehicleProfile?: number;
    /**
     * 
     * @type {Array<TransportTemplateTransportPlace>}
     * @memberof TransportTemplateTransport
     */
    places?: Array<TransportTemplateTransportPlace>;
    /**
     * 
     * @type {TransportTemplateRouteOptionsSygic}
     * @memberof TransportTemplateTransport
     */
    routeOptionsSygic?: TransportTemplateRouteOptionsSygic;
    /**
     * 
     * @type {boolean}
     * @memberof TransportTemplateTransport
     */
    puesc?: boolean;
}

export function TransportTemplateTransportFromJSON(json: any): TransportTemplateTransport {
    return TransportTemplateTransportFromJSONTyped(json, false);
}

export function TransportTemplateTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportTemplateTransport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fixedName': !exists(json, 'fixed_name') ? undefined : json['fixed_name'],
        'desiredVehicleProfile': !exists(json, 'desiredVehicleProfile') ? undefined : json['desiredVehicleProfile'],
        'places': !exists(json, 'places') ? undefined : ((json['places'] as Array<any>).map(TransportTemplateTransportPlaceFromJSON)),
        'routeOptionsSygic': !exists(json, 'route_options_sygic') ? undefined : TransportTemplateRouteOptionsSygicFromJSON(json['route_options_sygic']),
        'puesc': !exists(json, 'puesc') ? undefined : json['puesc'],
    };
}

export function TransportTemplateTransportToJSON(value?: TransportTemplateTransport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fixed_name': value.fixedName,
        'desiredVehicleProfile': value.desiredVehicleProfile,
        'places': value.places === undefined ? undefined : ((value.places as Array<any>).map(TransportTemplateTransportPlaceToJSON)),
        'route_options_sygic': TransportTemplateRouteOptionsSygicToJSON(value.routeOptionsSygic),
        'puesc': value.puesc,
    };
}


