import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Vehicles from './components/Vehicles';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';
import { defaultInputs } from './components/VehiclesDetail';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PairingItem, PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import { PairingItemType } from 'logic/partner/partner';
import { ReadOnlyMonitoredObjectType } from '../../../../generated/new-main';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import { PaginatedResponse, PaginationParams } from '../../../../common/model/pagination';
import { debounce } from 'debounce';
import { searched } from 'common/utils/search';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { message } from 'antd';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    edit: boolean;
    pairingLoading: boolean;
    unpairingLoading: boolean;
    createLoading: boolean;
    editLoading: boolean;
    removeLoading: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
        obu?: ObuModel[];
    };
    table: {
        loading: boolean;
        data?: PaginatedResponse<PartnerVehicleModel[]>;
        create?: PartnerVehicleModel;
        clients?: PartnerCompanySelectModel[];
        selected?: PartnerVehicleModel;
        delete?: PartnerVehicleModel;
    };
    pairing?: PartnerPairingModel;
    monitoredObjectTypes?: ReadOnlyMonitoredObjectType[];
}

class PartnerVehiclesModule extends Component<Props, State> {
    private _logic: Logic;
    private _externalSystem?: number;

    items?: PairingItem[];

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            pairingLoading: false,
            unpairingLoading: false,
            createLoading: false,
            editLoading: false,
            removeLoading: false,
            edit: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            }
        };
    }

    componentDidMount() {
        const promiseCompanies = this.state.bar.companies
            ? this._logic.partner().companies().getClientList()
            : undefined;
        promiseCompanies?.then(resp => {
            const companies = resp.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));
            this.setState(state => ({ bar: { ...state.bar, companies } }));
        });

        Promise.all([
            this._logic.partner().vehicles().getVehicleList(this.state.bar.selectedCompany?.id),
            this._logic.partner().companies().getClientList(this.state.bar.selectedCompany?.id),
            this._logic.partner().vehicles().getMonitoredObjectsTypes(),
            this._logic.partner().vehicles().getExternalSystems(),
            this._logic.partner().obu().getObuList()
        ])
            .then(res => {
                const [data, clients, types, externalSystems, obu] = res;
                this._externalSystem = externalSystems.find(s => s.name === 'indirect_partner')?.id;

                const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));

                this.setState(state => ({
                    bar: {
                        ...state.bar,
                        obu: obu.data
                    },
                    table: {
                        loading: false,
                        data: data,
                        clients: cl
                    },
                    monitoredObjectTypes: types
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    }

    render() {
        return (
            <Vehicles
                edit={this.state.edit}
                loading={this.state.table.loading}
                data={this.state.table.data}
                create={this.state.table.create}
                selected={this.state.table.selected}
                delete={this.state.table.delete}
                pairing={this.state.pairing}
                companies={this.state.bar.companies}
                selectedCompany={this.state.bar.selectedCompany}
                vehicleTypes={this.state.monitoredObjectTypes}
                clients={this.state.table.clients}
                pairingLoading={this.state.pairingLoading}
                unpairingLoading={this.state.unpairingLoading}
                createLoading={this.state.createLoading}
                editLoading={this.state.editLoading}
                removeLoading={this.state.removeLoading}
                onTableRowSelect={this._onTableRowSelect}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onDetailFormCancel={this._onDetailFormCancel}
                onDetailFormSubmit={this._onDetailFormSubmit}
                onDetailActionsUnpair={this._onDetailActionsUnpair}
                onBarActionsCreate={this._onDetailActionsCreate}
                onDeleteConfirm={this._onDeleteConfirm}
                onDeleteCancel={this._onDeleteCancel}
                onCreateSubmit={this._onCreateSubmit}
                onCreateCancel={this._onCreateCancel}
                onPairingKeySelect={this._onPairingKeySelect}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearch={this._onPairingSearch}
                onPairingConfirm={this._onPairingConfirm}
                onPairingCancel={this._onPairingCancel}
                onPaginationChange={this._onPaginationChange}
                onActionsUpdate={this._onActionsUpdate}
                onActionsPair={this._onActionsPair}
                onActionsDelete={this._onActionsDelete}
                onClientsFilterSearch={this._onClientsFilterSearch}
            />
        );
    }

    private _onClientsFilterSearch = async (text: string) => {
        const clients = await this._logic
            .partner()
            .companies()
            .getClientList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text);

        const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner })) ?? [];

        this.setState(state => ({
            table: {
                ...state.table,
                clients: cl
            }
        }));
    };

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onPaginationChange = (pagination: PaginationParams) => {
        this.setState(state => ({ table: { ...state.table, loading: true } }));
        this._logic
            .partner()
            .vehicles()
            .getVehicleList(this.state.bar.selectedCompany?.id, pagination.limit, pagination.offset)
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: data,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _onPairingCancel = (): void => {
        this.items = undefined;
        this.setState({ pairing: undefined });
    };

    private _onPairingConfirm = async (): Promise<void> => {
        const selected = this.state.table?.selected;
        const key = this.state.pairing?.keySelected;
        const linked = this.state.pairing?.items!.find(c => c.id === this.state.pairing!.itemIdSelected);

        this.setState({
            pairingLoading: true
        });

        if (selected && key && linked) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.vehicles
            };

            const target: PairingItemType = {
                id: linked?.id,
                key: key
            };

            try {
                await this._logic.partner().pair(source, target);
                message.success(this.props.t('PartnerVehicles.message.pairSuccess'));
                this.setState({ pairing: undefined });
                this._logic
                    .partner()
                    .vehicles()
                    .getVehicleList(
                        this.state.bar.selectedCompany?.id,
                        this.state.table.data?.limit,
                        this.state.table.data?.offset
                    )
                    .then(data => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                selected: data.data.find(vehicle => vehicle.id === state.table.selected?.id),
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                    });
            } catch (err) {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Pair error, err: ${err}`);
                message.error(this.props.t('PartnerVehicles.message.pairError'));
            }
        }

        this.setState({
            pairingLoading: false
        });
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                loading: true
            }
        }));
        switch (this.state.pairing?.keySelected) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(partners => {
                        this.items = partners.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.obu: {
                this._logic
                    .partner()
                    .obu()
                    .getObuList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(obu => {
                        this.items = obu.data.map(p => ({
                            id: p.id,
                            value: p.sn
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                const filtered = this.items?.filter(c => searched(text, c.value));
                const itemIdSelected = filtered?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                this.setState(state => ({
                    pairing: {
                        ...state.pairing!,
                        search: text ? text : undefined,
                        itemIdSelected,
                        items: filtered ?? undefined,
                        loading: false
                    }
                }));
            }
        }
    }, 500);

    private _onPairingItemSelect = (itemId: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                itemIdSelected: itemId
            }
        }));
    };

    private _onPairingKeySelect = (key: PartnerPairingKey): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                keySelected: key,
                items: undefined,
                loading: true
            }
        }));
        switch (key) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(partners => {
                        this.items = partners.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.obu: {
                this._logic
                    .partner()
                    .obu()
                    .getObuList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(obu => {
                        this.items = obu.data.map(p => ({
                            id: p.id,
                            value: p.sn
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                break;
            }
        }
    };

    private _onDetailActionsCreate = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                create: defaultInputs
            }
        }));
    };

    private _onActionsPair = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                keys: [PartnerPairingKey.obu]
            }
        }));
    };

    private _onActionsUpdate = (): void => {
        this.setState({
            edit: true
        });
    };

    private _onDetailActionsUnpair = async (linked: PairingItemType): Promise<void> => {
        this.setState({
            unpairingLoading: true
        });

        const selected = this.state.table?.selected;
        if (selected) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.vehicles
            };

            try {
                await this._logic.partner().unpair(source, linked);
                message.success(this.props.t('PartnerVehicles.message.unpairSuccess'));
                this.setState(
                    state => ({
                        table: {
                            ...state.table,
                            selected: state.table.selected
                                ? {
                                      ...state.table.selected,
                                      links:
                                          state.table.selected?.links && state.table.selected?.links.length > 1
                                              ? state.table.selected?.links?.filter(l => l.id !== linked.id)
                                              : undefined
                                  }
                                : undefined
                        }
                    }),
                    () => this._getVehicles()
                );
            } catch (err) {
                message.error(this.props.t('PartnerVehicles.message.unpairError'));
                console.error(`Unpair error, err: ${err}`);
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
            }
        }

        this.setState({
            unpairingLoading: false
        });
    };

    private _onDetailFormCancel = (): void => {
        this.setState({
            edit: false
        });
    };

    private _onActionsDelete = (): void => {
        const model = this.state.table.selected;
        if (model) {
            this.setState(state => ({
                table: { ...state.table, delete: model }
            }));
        }
    };

    private _onDetailFormSubmit = async (model: PartnerVehicleModel): Promise<void> => {
        this.setState(state => ({ editLoading: true, table: { ...state.table, loading: true } }));
        try {
            await this._logic.partner().vehicles().editVehicle(model, this._externalSystem!);
            message.success(this.props.t('PartnerVehicles.message.updateSuccess'));
            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        selected: undefined
                    }
                }),
                () => {
                    this._getVehicles();
                }
            );
        } catch (err) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: false
                }
            }));
            console.error(`Edit vehicle error, err: ${err}`);
            if ((err as any).status === 409) {
                message.error(this.props.t('PartnerVehicles.message.updateErrorMasterdata'));
            } else {
                message.error(this.props.t('PartnerVehicles.message.updateError'));
            }
        }
        this.setState({
            editLoading: false
        });
    };

    private _getVehicles = (limit?: number, offset?: number): void => {
        this.setState(state => ({ table: { ...state.table, loading: true } }));
        this._logic
            .partner()
            .vehicles()
            .getVehicleList(
                this.state.bar.selectedCompany?.id,
                limit ?? this.state.table.data?.limit,
                offset ?? this.state.table.data?.offset
            )
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: data,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _onDeleteConfirm = async (): Promise<void> => {
        this.setState({
            removeLoading: true
        });

        if (this.state.table?.delete) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));

            try {
                const deleted = await this.props.logic.partner().vehicles().deleteVehicle(this.state.table?.delete);
                if (deleted) {
                    message.success(this.props.t('PartnerVehicles.message.removeSuccess'));
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            delete: undefined
                        }
                    }));
                    this._getVehicles();
                } else {
                    message.error(this.props.t('PartnerVehicles.message.removeError'));
                }
            } catch (err) {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false,
                        delete: undefined
                    }
                }));
                console.error(`Delete vehicle error, err: ${err}`);
                message.error(this.props.t('PartnerVehicles.message.removeError'));
            }
        }

        this.setState({
            removeLoading: false
        });
    };

    private _onDeleteCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, delete: undefined }
        }));
    };

    private _onCreateSubmit = async (model: PartnerVehicleModel): Promise<void> => {
        if (!this._externalSystem) {
            alert('External system is required');
            return;
        }
        this.setState(state => ({ createLoading: true, table: { ...state.table, loading: true } }));

        try {
            await this._logic.partner().vehicles().createVehicle(model, this._externalSystem);
            message.success(this.props.t('PartnerVehicles.message.createSuccess'));
            this.setState(
                state => ({
                    table: { ...state.table, create: undefined }
                }),
                () => {
                    this._getVehicles();
                }
            );
        } catch (err) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: false
                }
            }));
            console.error(`Create vehicle error, err: ${err}`);
            message.error(this.props.t('PartnerVehicles.message.createError'));
        }
        this.setState({
            createLoading: false
        });
    };

    private _onCreateCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, create: undefined }
        }));
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getVehicles();
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getVehicles();
                if (this.state.bar.selectedCompany?.label) {
                    this._onClientsFilterSearch(this.state.bar.selectedCompany?.label);
                }
            }
        );
    };

    private _onTableRowSelect = (id: string): void => {
        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                selected: state.table?.selected?.id === id ? undefined : state.table?.data?.data.find(d => d.id === id)
            }
        }));
    };
}

export default withTranslation()(withRouter(PartnerVehiclesModule));
