/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigestDriverBehaviourDriver,
    DigestDriverBehaviourDriverFromJSON,
    DigestDriverBehaviourDriverFromJSONTyped,
    DigestDriverBehaviourDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneralData
 */
export interface GeneralData {
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    numberOfDays: number;
    /**
     * 
     * @type {Date}
     * @memberof GeneralData
     */
    dateStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof GeneralData
     */
    dateEnd: Date;
    /**
     * 
     * @type {DigestDriverBehaviourDriver}
     * @memberof GeneralData
     */
    driver1: DigestDriverBehaviourDriver;
    /**
     * 
     * @type {DigestDriverBehaviourDriver}
     * @memberof GeneralData
     */
    driver2: DigestDriverBehaviourDriver;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralData
     */
    monitoredObjectId: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    engineOnTime: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    driveTime: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    idlingTime: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    idlingTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    idlingTimePto: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    idlingTimePtoPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralData
     */
    consumption: number;
}

export function GeneralDataFromJSON(json: any): GeneralData {
    return GeneralDataFromJSONTyped(json, false);
}

export function GeneralDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfDays': json['number_of_days'],
        'dateStart': (new Date(json['dateStart'])),
        'dateEnd': (new Date(json['dateEnd'])),
        'driver1': DigestDriverBehaviourDriverFromJSON(json['driver1']),
        'driver2': DigestDriverBehaviourDriverFromJSON(json['driver2']),
        'monitoredObjectId': json['monitored_object_id'],
        'engineOnTime': json['engine_on_time'],
        'driveTime': json['drive_time'],
        'idlingTime': json['idling_time'],
        'idlingTimePercentage': json['idling_time_percentage'],
        'idlingTimePto': json['idling_time_pto'],
        'idlingTimePtoPercentage': json['idling_time_pto_percentage'],
        'distance': json['distance'],
        'consumption': json['consumption'],
    };
}

export function GeneralDataToJSON(value?: GeneralData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_of_days': value.numberOfDays,
        'dateStart': (value.dateStart.toISOString()),
        'dateEnd': (value.dateEnd.toISOString()),
        'driver1': DigestDriverBehaviourDriverToJSON(value.driver1),
        'driver2': DigestDriverBehaviourDriverToJSON(value.driver2),
        'monitored_object_id': value.monitoredObjectId,
        'engine_on_time': value.engineOnTime,
        'drive_time': value.driveTime,
        'idling_time': value.idlingTime,
        'idling_time_percentage': value.idlingTimePercentage,
        'idling_time_pto': value.idlingTimePto,
        'idling_time_pto_percentage': value.idlingTimePtoPercentage,
        'distance': value.distance,
        'consumption': value.consumption,
    };
}


