/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleDirectionRouteParameters
 */
export interface GoogleDirectionRouteParameters {
    /**
     * 
     * @type {string}
     * @memberof GoogleDirectionRouteParameters
     */
    strategy?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof GoogleDirectionRouteParameters
     */
    origin: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GoogleDirectionRouteParameters
     */
    destination: Array<number>;
}

export function GoogleDirectionRouteParametersFromJSON(json: any): GoogleDirectionRouteParameters {
    return GoogleDirectionRouteParametersFromJSONTyped(json, false);
}

export function GoogleDirectionRouteParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleDirectionRouteParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'strategy': !exists(json, 'strategy') ? undefined : json['strategy'],
        'origin': json['origin'],
        'destination': json['destination'],
    };
}

export function GoogleDirectionRouteParametersToJSON(value?: GoogleDirectionRouteParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'strategy': value.strategy,
        'origin': value.origin,
        'destination': value.destination,
    };
}


