import { ReactNode, useState } from 'react';
import { Popover, Tooltip, Typography } from 'common/components';

export enum DriverBehaviorStatColor {
    BLUE = 'blue',
    GREEN = 'green',
    YELLOW = 'yellow'
}
interface Props {
    color?: DriverBehaviorStatColor;
    icon?: ReactNode;
    value: ReactNode;
    description: ReactNode;
    note?: ReactNode;
    development?: ReactNode;
    qa?: string;
    popover?: ReactNode;
}

export default function DriverBehaviorStat(props: Props) {
    const [open, setOpen] = useState(false);
    let startClose = false;

    const hide = () => {
        setOpen(false);
    };

    function close() {
        startClose = true;
        setTimeout(() => {
            if (startClose) {
                hide();
            }
            startClose = false;
        }, 200);
    }

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (
        <Popover
            className="driver-behavior-stat"
            overlayClassName="driver-behavior-chart-trend"
            placement="bottomLeft"
            content={
                props.popover && (
                    <div
                        onMouseLeave={() => {
                            close();
                        }}
                        onMouseOver={() => {
                            startClose = false;
                        }}
                    >
                        <Typography.Title level={5}>{props.description}</Typography.Title>
                        {props.popover}
                    </div>
                )
            }
            trigger={'click'}
            visible={open}
            onVisibleChange={handleOpenChange}
        >
            <div
                onMouseLeave={() => {
                    close();
                }}
                onMouseOver={() => {
                    startClose = false;
                }}
            >
                {props.icon && <div className="driver-behavior-stat-icon">{props.icon}</div>}
                <div className="driver-behavior-stat-data" data-qa={props.qa}>
                    <Typography.Title level={5}>{props.value}</Typography.Title>
                    <div className="driver-behavior-stat-data-description">
                        {props.description}{' '}
                        {props.note && (
                            <Tooltip title={props.note}>
                                <i className="fas fa-info-circle" />
                            </Tooltip>
                        )}
                    </div>
                    {props.development && <div>{props.development}</div>}
                </div>
            </div>
        </Popover>
    );
}
