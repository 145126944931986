import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

type Props = TooltipProps & {
    qa?: string;
};

export default function EnhancedToolTip({ qa, children, ...props }: Props) {
    return <Tooltip {...props}>{qa ? <span data-qa={qa}>{children}</span> : children}</Tooltip>;
}
