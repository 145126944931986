import React, { useState, ReactNode } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Tabs, Select } from 'common/components';
import { Suggestions } from 'logic/routing/logic/suggestion';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Subject } from 'rxjs';
import qa from 'qa-selectors';
import { RefSelectProps } from 'antd/lib/select';

interface Props {
    text: string;
    suggestions: Suggestions;
    autoFocus?: boolean;
    size?: SizeType;
    className?: string;
    tabMenuClassName?: string;
    innerref?: React.RefObject<RefSelectProps>;
    updateSearchValue?: Subject<string>;
    onChange?: (text: string) => void;
    onSelect?: (id?: string | null) => void;
    onBlur?: () => void;
}

interface State {
    searchText?: string;
    activeTabKey: string;
}

export default function PlacesAutocomplete({ suggestions, ...props }: Props) {
    const { t } = useTranslation();
    const [state, setState] = useState<State>({
        searchText: props.text,
        activeTabKey: 'tab-1'
    });

    const [inputValue, setInputValue] = useState<{ value: string; label: string | ReactNode } | undefined>();

    props.updateSearchValue?.subscribe(value => {
        setState(state => ({
            ...state,
            searchText: value
        }));
        if (value === '') {
            setInputValue(undefined);
        }
        props.onChange?.(value);
    });

    function onSearchInputChange(value: string) {
        setState(state => ({
            ...state,
            searchText: value
        }));
        props.onChange?.(value);
    }

    function highlightSubstring(label?: string | null, id?: string | null): React.ReactNode {
        // mongoDB id regex
        if (!id || !label) {
            return label;
        }

        if (!state.searchText) {
            return;
        }

        const checkId = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
        const highlighted = label.replace(
            new RegExp(state.searchText, 'gi'),
            id === 'LAT_LNG'
                ? highlighted => highlighted
                : highlighted => `<mark data-qa=${qa.planner.autocomplete.markedString}>${highlighted}</mark>`
        );

        return (
            <>
                <i
                    className={cn('fa fa-fw', checkId.test(id) ? 'fa-dot-circle' : 'fa-map-marker-alt')}
                    aria-hidden="true"
                />
                <span dangerouslySetInnerHTML={{ __html: highlighted }} />
            </>
        );
    }

    function activeTabChange(key: string) {
        setState(state => ({
            ...state,
            activeTabKey: key
        }));
    }

    const checkForSuggestions: boolean =
        (state.activeTabKey === 'tab-1' && suggestions.google.length > 0) ||
        suggestions.eurowag.length > 0 ||
        suggestions.googleGeocoder.length > 0 ||
        !!suggestions.latLng ||
        (state.activeTabKey === 'tab-2' && suggestions.eurowag.length > 0) ||
        (state.activeTabKey === 'tab-3' && suggestions.google.length > 0) ||
        suggestions.googleGeocoder.length > 0 ||
        !!suggestions.latLng;

    return (
        <div className={`places-autocomplete ${props.className ?? ''}`}>
            <Select
                qa={qa.planner.autocomplete.inputSearch}
                placeholder={t('PlacesAutocomplete.searchInput')}
                innerref={props.innerref}
                autoFocus={props.autoFocus}
                labelInValue
                value={inputValue}
                showArrow={false}
                showSearch
                dropdownClassName="places-autocomplete-dropdown"
                filterOption={false}
                onSearch={onSearchInputChange}
                onSelect={(_val, option) => {
                    setInputValue({
                        label: option.children,
                        value: option.value
                    });
                    props.onSelect?.(option.value?.toString());
                }}
                onBlur={props.onBlur}
                onClear={() => {
                    setInputValue(undefined);
                    props.onSelect?.(undefined);
                }}
                size="large"
                allowClear
                dropdownRender={menu => (
                    <>
                        <div className={`places-autocomplete-tabs ${props.tabMenuClassName ?? ''}`}>
                            <Tabs onChange={activeTabChange} activeKey={state.activeTabKey} defaultActiveKey="tab-1">
                                <Tabs.TabPane tab={t('PlacesAutocomplete.filterAll')} key="tab-1" />
                                <Tabs.TabPane tab={t('PlacesAutocomplete.filterOwn')} key="tab-2" />
                                <Tabs.TabPane tab={t('PlacesAutocomplete.filterMap')} key="tab-3" />
                            </Tabs>
                        </div>
                        {state.searchText !== '' && checkForSuggestions && menu}
                    </>
                )}
            >
                {state.activeTabKey === 'tab-1' && (
                    <>
                        {suggestions.latLng && (
                            <Select.Option
                                key={`google-geocoder-latlng`}
                                value="LAT_LNG"
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(`${suggestions.latLng.lat},${suggestions.latLng.lng}`, 'LAT_LNG')}
                            </Select.Option>
                        )}
                        {(suggestions.googleGeocoder ?? []).map((suggestion, index) => (
                            <Select.Option
                                key={`google-geocoder-${index}`}
                                value={suggestion.place_id ?? ''}
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(suggestion.formatted_address, suggestion.place_id)}
                            </Select.Option>
                        ))}
                        {(suggestions.google ?? []).map((suggestion, index) => (
                            <Select.Option
                                key={`google-${index}`}
                                value={suggestion.place_id ?? ''}
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(suggestion.description, suggestion.place_id)}
                            </Select.Option>
                        ))}
                        {(suggestions.eurowag ?? []).map((suggestion, index) => (
                            <Select.Option
                                key={`eurowag-${index}`}
                                value={suggestion.id ?? ''}
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(suggestion.label, suggestion.id)}
                            </Select.Option>
                        ))}
                    </>
                )}

                {state.activeTabKey === 'tab-2' &&
                    (suggestions.eurowag ?? []).map((suggestion, index) => (
                        <Select.Option
                            key={`eurowag-${index}`}
                            value={suggestion.id ?? ''}
                            className="places-autocomplete-option"
                        >
                            {highlightSubstring(suggestion.label, suggestion.id)}
                        </Select.Option>
                    ))}

                {state.activeTabKey === 'tab-3' && (
                    <>
                        {suggestions.latLng && (
                            <Select.Option
                                key={`google-geocoder-latlng`}
                                value={'LAT_LNG'}
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(`${suggestions.latLng.lat},${suggestions.latLng.lng}`, 'LAT_LNG')}
                            </Select.Option>
                        )}
                        {(suggestions.googleGeocoder ?? []).map((suggestion, index) => (
                            <Select.Option
                                key={`google-geocoder-${index}`}
                                value={suggestion.place_id ?? ''}
                                className="places-autocomplete-option"
                            >
                                {highlightSubstring(suggestion.formatted_address, suggestion.place_id)}
                            </Select.Option>
                        ))}
                        {(suggestions.google ?? []).map((suggestion, index) => (
                            <Select.Option
                                key={`google-${index}`}
                                value={suggestion.place_id ?? ''}
                                className="places-autocomplete-new-option"
                            >
                                {highlightSubstring(suggestion.description, suggestion.place_id)}
                            </Select.Option>
                        ))}
                    </>
                )}
            </Select>
        </div>
    );
}
