import {
    ADRClass,
    Accessories,
    ExpenseItemType,
    LoadType,
    MonitoredObjectType,
    NegotiationState,
    ProposalForClientFromJSON,
    ShipmentState,
    TruckBody,
    UnitCodeWeight,
    WayOfLoading
} from 'generated/dff-api';
import moment from 'moment';
import { transports } from './transports';
import * as faker from 'faker';
import { TransEUCompanyInfo } from '../../logic/dff-proposals';

interface DemoProposal {
    id: number;
    monitored_object_id: number;
    loading_place: string;
    loading_date: string;
    unloading_place: string;
    total_distance: number;
    distance_to_pickup: number;
    duration: number;
    weight: number;
    trailer: string;
    pick_up_costs: number;
    transport_costs: number;
    final_price: number;
    profit: number;
    state: NegotiationState;
}

export const demoProposals: DemoProposal[] = [
    {
        id: 1,
        monitored_object_id: 1,
        loading_place: 'ES, 469 88, Valencia',
        loading_date: moment(transports[0].lastRta).add(360, 'minutes').toISOString(),
        unloading_place: 'FR, 690 00, Lyon',
        total_distance: 961, //km
        distance_to_pickup: 10, //km
        duration: 32, //h
        weight: 20, //t
        trailer: 'cooler',
        pick_up_costs: 86,
        transport_costs: 1196,
        final_price: 1716.8,
        profit: 434.8,
        state: NegotiationState.Generated
    },
    {
        id: 2,
        monitored_object_id: 2,
        loading_place: 'DE, 603 06, Frankfurt',
        loading_date: moment(transports[1].lastRta).add(350, 'minutes').toISOString(),
        unloading_place: 'ES, 28001, Madrid',
        total_distance: 1814,
        distance_to_pickup: 43,
        duration: 30,
        weight: 24,
        trailer: 'tent',
        pick_up_costs: 39,
        transport_costs: 2422,
        final_price: 3295.4,
        profit: 834.4,
        state: NegotiationState.Generated
    },
    {
        id: 3,
        monitored_object_id: 2,
        loading_place: 'DE, 69115, Heidelberg',
        loading_date: moment(transports[1].lastRta).add(130, 'minutes').toISOString(),
        unloading_place: 'PL, 43-450, Ustron',
        total_distance: 1054,
        distance_to_pickup: 20,
        duration: 30,
        weight: 21,
        trailer: 'tent',
        pick_up_costs: 29,
        transport_costs: 1260,
        final_price: 1726.2,
        profit: 437.2,
        state: NegotiationState.Generated
    },
    {
        id: 4,
        monitored_object_id: 2,
        loading_place: 'DE, 70173, Stuttgart',
        loading_date: moment(transports[1].lastRta).add(220, 'minutes').toISOString(),
        unloading_place: 'CZ, 460 01, Liberec',
        total_distance: 547,
        distance_to_pickup: 22,
        duration: 10,
        weight: 26,
        trailer: 'tent',
        pick_up_costs: 67,
        transport_costs: 842,
        final_price: 1217.4,
        profit: 308.4,
        state: NegotiationState.Declined
    },
    {
        id: 5,
        monitored_object_id: 3,
        loading_place: 'IT, 31100, Treviso',
        loading_date: moment(transports[2].lastRta).add(250, 'minutes').toISOString(),
        unloading_place: 'AT, 80 10, Graz',
        total_distance: 457,
        distance_to_pickup: 17,
        duration: 9,
        weight: 6,
        trailer: 'cooler',
        pick_up_costs: 76,
        transport_costs: 526,
        final_price: 806.4,
        profit: 204.4,
        state: NegotiationState.Generated
    },
    {
        id: 6,
        monitored_object_id: 5,
        loading_place: 'ES, 08001, Barcelona',
        loading_date: moment(transports[4].lastRta).add(420, 'minutes').toISOString(),
        unloading_place: 'IT, 34121, Trieste',
        total_distance: 1320,
        distance_to_pickup: 10,
        duration: 25,
        weight: 24,
        trailer: 'tent',
        pick_up_costs: 94,
        transport_costs: 1650,
        final_price: 2336,
        profit: 592,
        state: NegotiationState.Generated
    },
    {
        id: 7,
        monitored_object_id: 5,
        loading_place: 'ES, 170 01, Girona',
        loading_time: 17,
        loading_date: moment(transports[4].lastRta).add(470, 'minutes').toISOString(),
        unloading_place: 'FR, 44 000, Nantes',
        total_distance: 799,
        distance_to_pickup: 19,
        duration: 15,
        weight: 28,
        trailer: 'flatbed',
        pick_up_costs: 83,
        transport_costs: 1060,
        final_price: 1531,
        profit: 388,
        state: NegotiationState.Generated
    },
    {
        id: 8,
        monitored_object_id: 6,
        loading_place: 'HU, 1007, Budapest',
        loading_date: moment(transports[5].lastRta).add(190, 'minutes').toISOString(),
        unloading_place: 'AT, 1010, Vienna',
        total_distance: 243,
        distance_to_pickup: 10,
        duration: 10,
        weight: 28,
        trailer: 'tent',
        pick_up_costs: 40,
        transport_costs: 425,
        final_price: 622.6,
        profit: 157.6,
        state: NegotiationState.Generated
    },
    {
        id: 9,
        monitored_object_id: 6,
        loading_place: 'HU, 2330, Dunaharaszti',
        loading_date: moment(transports[5].lastRta).add(310, 'minutes').toISOString(),
        unloading_place: 'CZ, 110 00, Praga',
        total_distance: 536,
        distance_to_pickup: 20,
        duration: 25,
        weight: 28,
        trailer: 'cooler',
        pick_up_costs: 86,
        transport_costs: 514,
        final_price: 803.2,
        profit: 203.2,
        state: NegotiationState.CarrierOffer
    },
    {
        id: 10,
        monitored_object_id: 6,
        loading_place: 'HU, 8000, Székesfehérvár',
        loading_date: moment(transports[5].lastRta).add(370, 'minutes').toISOString(),
        unloading_place: 'PL, 33-300, Nowy Sacz',
        total_distance: 450,
        distance_to_pickup: 15,
        duration: 9,
        weight: 5.1,
        trailer: 'tent',
        pick_up_costs: 86,
        transport_costs: 730,
        final_price: 1092.8,
        profit: 276.8,
        state: NegotiationState.Generated
    }
].map(t => ({ ...t, duration: t.duration + 10 }));

const data = demoProposals.map(proposal => {
    const loading_date =
        moment(proposal.loading_date) > moment()
            ? moment(proposal.loading_date)
            : moment().add(Math.floor(Math.random() * 420) + 180, 'minutes');
    const unloading_date = moment(loading_date).add(proposal.duration, 'hours').toISOString();
    const shipmentId = faker.datatype.uuid();

    return {
        proposalId: String(proposal.id),
        shipmentId,
        vehicle: {
            location: {
                lat: 51.261434603115035,
                lon: 9.513257575757576
            },
            id: 'Dispecer4178',
            externalId: String(proposal.monitored_object_id),
            properties: {
                type: MonitoredObjectType.Truck,
                cargoSpace: {
                    width: 12,
                    height: 50,
                    length: 6
                },
                adrClasses: [ADRClass._1, ADRClass._41],
                truckBody: TruckBody.BoxTruck,
                accessories: [Accessories.HookLift],
                waysOfLoading: [WayOfLoading.Side]
            }
        },
        shipment: {
            shipmentId,
            ownerId: 'ownerId',
            isFtlOnly: false,
            state: ShipmentState.Accepted,
            receiveProposalsUntil: moment(loading_date).add(3600, 'seconds').toISOString(),
            loadingDetails: {
                location: {
                    lat: 51.36476095,
                    lon: 9.42826346459713,
                    address: {
                        postalCode: proposal.loading_place.split(', ')[1],
                        street: proposal.loading_place.split(', ')[2],
                        countryCode: proposal.loading_place.split(', ')[0],
                        city: '',
                        houseNumber: ''
                    }
                },
                loadingWindow: {
                    availableFrom: loading_date,
                    availableUntil: moment(loading_date).add(30, 'minutes').toISOString()
                },
                note: 'Notes',
                loads: [
                    {
                        loadType: LoadType.Pallets,
                        count: 10,
                        description: 'Rhenus Freight Logistics GmbH & Co. KG Zweigniederlassung Kirchheim - 2A',
                        weight: { unit: UnitCodeWeight.Ton, value: 4 }
                    },
                    {
                        loadType: LoadType.Barrel,
                        count: 5,
                        description: 'Rhenus Freight Logistics GmbH & Co. KG Zweigniederlassung Kirchheim - 1A',
                        weight: { unit: UnitCodeWeight.Ton, value: 2 }
                    }
                ]
            },
            unloadingDetails: [
                {
                    location: {
                        lat: 51.36476095,
                        lon: 9.42826346459713,
                        address: {
                            postalCode: proposal.unloading_place.split(', ')[1],
                            street: proposal.unloading_place.split(', ')[2],
                            countryCode: proposal.unloading_place.split(', ')[0],
                            city: '',
                            houseNumber: ''
                        }
                    },
                    loadingWindow: {
                        availableFrom: unloading_date,
                        availableUntil: moment(unloading_date).add(30, 'minutes').toISOString()
                    },
                    note: 'Notes',
                    loads: [
                        {
                            loadType: LoadType.Pallets,
                            count: 10,
                            description: 'Rhenus Freight Logistics GmbH & Co. KG Zweigniederlassung Kirchheim - 2A',
                            weight: { unit: UnitCodeWeight.Ton, value: 4 }
                        }
                    ]
                }
            ],
            createdAt: moment('2021-02-25T13:07:12+0000').toDate(),
            negotiableAttributes: [],
            shipper: {
                billingInfo: {
                    billingCode: '',
                    companyAddress: { city: 'Ebbs', countryCode: 'at', postalCode: '6341', street: 'Gießenweg 7a' },
                    taxNumber: '528818684',
                    vatNumber: '8133781093'
                },
                companyContact: {
                    companyName: 'Company',
                    contactPerson: 'Sylwia Belfin',
                    phoneNumber: '+421111111111'
                }
            },
            attachments: []
        },
        createdAt: moment().toISOString(),
        state: proposal.state,
        pickup: {
            duration: { driving: 36e5, nonDriving: 0 },
            distanceKm: proposal.distance_to_pickup,
            fromLocation: {
                lat: 51.261434603115035,
                lon: 9.513257575757576
            },
            toLocation: {
                lat: 51.36476095,
                lon: 9.42826346459713
            },
            expenseItems: [
                {
                    value: 50,
                    item: ExpenseItemType.Fuel,
                    currencyCode: 'EUR'
                },
                {
                    value: 2.55,
                    item: ExpenseItemType.Driver,
                    currencyCode: 'EUR'
                },
                {
                    value: 2.85,
                    item: ExpenseItemType.Toll,
                    currencyCode: 'EUR'
                },
                {
                    value: 0.5,
                    item: ExpenseItemType.Adblue,
                    currencyCode: 'EUR'
                },
                {
                    value: 20,
                    item: ExpenseItemType.Oil,
                    currencyCode: 'EUR'
                },
                {
                    value: 47.304099999999934,
                    item: ExpenseItemType.Fixed,
                    currencyCode: 'EUR'
                },
                {
                    value: 8.258199999999999,
                    item: ExpenseItemType.Insurance,
                    currencyCode: 'EUR'
                },
                {
                    value: 222,
                    item: ExpenseItemType.Labor,
                    currencyCode: 'EUR'
                },
                {
                    value: 0.27572,
                    item: ExpenseItemType.Tires,
                    currencyCode: 'EUR'
                },
                {
                    value: 0,
                    item: ExpenseItemType.Vignette,
                    currencyCode: 'EUR'
                }
            ]
        },
        delivery: [
            {
                duration: { driving: proposal.duration * 60 * 60 * 1000, nonDriving: 0 },
                distanceKm: proposal.total_distance,
                fromLocation: {
                    lat: 51.36476095,
                    lon: 9.42826346459713
                },
                toLocation: {
                    lat: 49.872775,
                    lon: 8.651177
                },
                expenseItems: [
                    {
                        value: 100,
                        item: ExpenseItemType.Fuel,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 2.130650785931094,
                        item: ExpenseItemType.Driver,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 2.85,
                        item: ExpenseItemType.Toll,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 0.33899999999999986,
                        item: ExpenseItemType.Adblue,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 0.01808000000000002,
                        item: ExpenseItemType.Oil,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 47.304099999999934,
                        item: ExpenseItemType.Fixed,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 8.258199999999999,
                        item: ExpenseItemType.Insurance,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 2.883760000000001,
                        item: ExpenseItemType.Labor,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 0.27572,
                        item: ExpenseItemType.Tires,
                        currencyCode: 'EUR'
                    },
                    {
                        value: 0,
                        item: ExpenseItemType.Vignette,
                        currencyCode: 'EUR'
                    }
                ]
            }
        ]
    };
});

export const transEUMockInfo: TransEUCompanyInfo[] = [
    {
        name: 'Tirolia Spedition GmbH',
        address: '6341 Ebbs Gießenweg 7a, Austria',
        employeeCount: '5',
        vatNumber: 'ATU32158704',
        contactNumber: '(0043) 537340090',
        contactPerson: 'Sylwia Belfin',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    },
    {
        name: 'Spedvil Małgorzata Zborowska-Pisznot',
        address: '62-600 Powiercie Jaśminowa 24, Poland',
        employeeCount: '8',
        vatNumber: 'PL6772034611',
        contactNumber: '(0048) 535677620',
        contactPerson: 'Damian Pisznot',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    },
    {
        name: 'Extrego S.A.',
        address: '36-072 Świlcza Świlcza 146E, Poland',
        employeeCount: '42',
        vatNumber: '8133781093',
        contactNumber: '(0048) 734734819',
        contactPerson: 'Wojciech Brzykowski',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    },
    {
        name: 'FHU Car-Max Andrzej Okrajni',
        address: '32-086 Węgrzce C-6 NR 8, Poland',
        employeeCount: '66',
        vatNumber: '8133781093',
        contactNumber: '(0048) 606656151',
        contactPerson: 'Andrzej Okrajni',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    },
    {
        name: 'Contargo Network Logistics GmbH',
        address: '95182 Döhlau Industriestraße 6, Germany',
        employeeCount: '2',
        vatNumber: 'DE118554517',
        contactNumber: '(0049) 9286809161',
        contactPerson: 'Markus Bittermann',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    },
    {
        name: 'Torotrans Transport Międzynarodowy i Krajowy - Spedycja Józef Cysewski',
        address: '89-620 Chojnice Bydgoska 1, Poland',
        employeeCount: '2',
        vatNumber: 'PL5550008972',
        contactNumber: '(0048) 512186902',
        contactPerson: 'Tomasz Cysewski',
        paymentStatus: 'good',
        email: 'trans@eu.com',
        taxNumber: '528818684'
    }
];

export const rawProposals = data;
export const proposals = data.map(d => ProposalForClientFromJSON(d));
