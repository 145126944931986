import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { Role } from 'logic/auth';
import { AuditOutlined } from '@ant-design/icons';
import qa from 'qa-selectors';
import { DateRange } from 'common/model/date-time';
import { DATE_FORMAT, DWL_LOCK } from 'domain-constants';
import { DatePicker } from 'common/components';
import moment, { Moment } from 'moment';
import TableBar from 'common/components/TableBar';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import { RouteOverviewFilterModel } from '../../RouteOverviewModule';
import RouteOverviewFilter from '../RouteOverviewFilter';
import { arrayEquals } from 'common/utils/textUtils';
import { confDefault } from 'conf';

interface OwnProps {
    backUrl?: boolean;
    dateOpen?: boolean;
    dateChanged?: boolean;
    dateRange: DateRange;
    filter: RouteOverviewFilterModel;
    filterOpen?: boolean;
    roles: Role[];
    demoMode?: boolean;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onBackUrlClick?: () => void;
    onResetClick?: () => void;
    onFilterClick?: () => void;
    onHelperClick?: () => void;
    onStazkaExportClick?: () => void;
    onFilterCancel: () => void;
    onFilterConfirm: (driversChecked: string[], vehiclesChecked: string[], transitStatesChecked: string[]) => void;
}

type Props = OwnProps & WithTranslation;

function RouteOverviewBar(props: Props) {
    const { t, dateChanged, dateRange, roles } = props;
    const defaults = confDefault.settings.scheduling.routeOverview.filter;

    function _onDateRangeChange(values: [Moment | null, Moment | null] | null): void {
        if (values) {
            const [start, end] = values;
            props.onDateRangeChange?.({
                start: start ? start.format(DATE_FORMAT) : dateRange.start,
                end: end ? end.format(DATE_FORMAT) : dateRange.end
            });
        }
    }

    function _onFilterClick() {
        props.onFilterClick?.();
    }

    function _onBackUrlClick() {
        props.onBackUrlClick?.();
    }

    function _onStazkaExportClick() {
        props.onStazkaExportClick?.();
    }

    return (
        <>
            <TableBar
                heading={t('RouteOverviewBar.name')}
                datePicker={
                    <DatePicker.DateTimeRangePicker
                        defaultValue={[moment(dateRange.start, DATE_FORMAT), moment(dateRange.end, DATE_FORMAT)]}
                        onChange={_onDateRangeChange}
                        maxDate={moment().add(1, 'year').toDate()}
                        minDate={
                            props.filter.fullDWL
                                ? moment()
                                      .subtract(DWL_LOCK - 1, 'days')
                                      .endOf('day')
                                      .toDate()
                                : undefined
                        }
                        disabled={props.demoMode}
                        trigger={
                            <ButtonDateRangeTimeTrigger
                                dateRange={props.dateRange}
                                changed={dateChanged}
                                qa={qa.common.dateTimeRangePicker.btn}
                            />
                        }
                    />
                }
                filter={{
                    activeCount:
                        (arrayEquals(props.filter.vehiclesChecked, defaults.vehicles) ||
                        props.filter.vehiclesChecked.length === props.filter.vehiclesOpts.length
                            ? 0
                            : 1) +
                        (arrayEquals(props.filter.driversChecked, defaults.drivers) ||
                        props.filter.driversChecked.length === props.filter.driversOpts.length
                            ? 0
                            : 1) +
                        (arrayEquals(props.filter.transportStateChecked, defaults.transportState) ||
                        props.filter.transportStateChecked.length === props.filter.transportStateOpts.length
                            ? 0
                            : 1),
                    onClick: _onFilterClick,
                    qa: qa.routeOverview.bar.btnFilter,
                    resetButton: {
                        onClick: props.onResetClick
                    }
                }}
                actions={[
                    <Button
                        className="route-overview-bar-export t-bar-item t-right"
                        title={t('common.stazkaExport')}
                        onClick={_onStazkaExportClick}
                        hidden={!roles.includes(Role.SR_E) || true} // HIDE MFR 30.3.2021
                        disabled={!roles.includes(Role.SR_E)}
                        icon={<AuditOutlined className="stazka-icon" />}
                    />
                ]}
                backButton={
                    props.backUrl
                        ? {
                              qa: qa.routeOverview.bar.btnBack,
                              onClick: _onBackUrlClick
                          }
                        : undefined
                }
                onHelperClick={props.onHelperClick}
            />

            {props.filterOpen && (
                <RouteOverviewFilter
                    driversChecked={props.filter.driversChecked}
                    driversOpts={props.filter.driversOpts}
                    transportStatesChecked={props.filter.transportStateChecked}
                    transportStatesOpts={props.filter.transportStateOpts}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    vehiclesOpts={props.filter.vehiclesOpts}
                    onCancel={props.onFilterCancel}
                    onConfirm={props.onFilterConfirm}
                />
            )}
        </>
    );
}

export default withTranslation()(RouteOverviewBar);
