/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportState,
    TransportStateFromJSON,
    TransportStateFromJSONTyped,
    TransportStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost
 */
export interface BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost {
    /**
     * 
     * @type {number}
     * @memberof BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost
     */
    monitoredObject?: number;
    /**
     * 
     * @type {Date}
     * @memberof BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost
     */
    start?: Date;
    /**
     * 
     * @type {TransportState}
     * @memberof BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost
     */
    state?: TransportState;
}

export function BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostFromJSON(json: any): BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost {
    return BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostFromJSONTyped(json, false);
}

export function BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObject': !exists(json, 'monitored_object') ? undefined : json['monitored_object'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'state': !exists(json, 'state') ? undefined : TransportStateFromJSON(json['state']),
    };
}

export function BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPostToJSON(value?: BodyDetachMonitoredObjectV1TransportsTransportIdDetachMonitoredObjectPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object': value.monitoredObject,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'state': TransportStateToJSON(value.state),
    };
}


