/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiInfoMessage
 */
export interface ApiInfoMessage {
    /**
     * 
     * @type {string}
     * @memberof ApiInfoMessage
     */
    message: string;
}

export function ApiInfoMessageFromJSON(json: any): ApiInfoMessage {
    return ApiInfoMessageFromJSONTyped(json, false);
}

export function ApiInfoMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInfoMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function ApiInfoMessageToJSON(value?: ApiInfoMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}


