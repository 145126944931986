/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyMonitoredObjectFeSb
 */
export interface ReadOnlyMonitoredObjectFeSb {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    customLabel?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    disabledAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    readonly fuelType?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    fleetType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyMonitoredObjectFeSb
     */
    roles?: Array<string>;
}

export function ReadOnlyMonitoredObjectFeSbFromJSON(json: any): ReadOnlyMonitoredObjectFeSb {
    return ReadOnlyMonitoredObjectFeSbFromJSONTyped(json, false);
}

export function ReadOnlyMonitoredObjectFeSbFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMonitoredObjectFeSb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'registrationNumber': json['registration_number'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'disabledAt': !exists(json, 'disabled_at') ? undefined : (json['disabled_at'] === null ? null : new Date(json['disabled_at'])),
        'fuelType': !exists(json, 'fuel_type') ? undefined : json['fuel_type'],
        'fleetType': json['fleet_type'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function ReadOnlyMonitoredObjectFeSbToJSON(value?: ReadOnlyMonitoredObjectFeSb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registration_number': value.registrationNumber,
        'custom_label': value.customLabel,
        'disabled_at': value.disabledAt === undefined ? undefined : (value.disabledAt === null ? null : value.disabledAt.toISOString()),
        'fleet_type': value.fleetType,
        'roles': value.roles,
    };
}


