import { DATE_TIME_FORMAT } from 'domain-constants';
import moment from 'moment';
import { users } from './users';

export const messages = {
    channel0: {
        title: `${users[0].name} ${users[0].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel0',
                sender: '1001',
                text: 'Hello Peter',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[0].name, date: '26.09.2022 10:50' }],
                id: '1'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel0',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[0].name, date: '26.09.2022 10:51' }],
                id: '3'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel0',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[0].name, date: '26.09.2022 10:53' }],
                id: '5'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'Is there any transport available?',
                created: moment('26.09.2022 10:55', DATE_TIME_FORMAT).format('L LT'),
                id: '7'
            },
            {
                channel: 'channel0',
                sender: '1001',
                text: 'Not right now.',
                created: moment('26.09.2022 10:56', DATE_TIME_FORMAT).format('L LT'),
                id: '8'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'And now?',
                created: moment('26.09.2022 10:59', DATE_TIME_FORMAT).format('L LT'),
                id: '9'
            },
            {
                channel: 'channel0',
                sender: users[0].name,
                text: 'Thank you.',
                created: moment('26.09.2022 11:02', DATE_TIME_FORMAT).format('L LT'),
                id: '10'
            }
        ]
    },
    channel1: {
        title: `${users[1].name} ${users[1].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel1',
                sender: '1001',
                text: 'Hello Radim',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[1].name, date: '26.09.2022 10:49' }],
                id: '1'
            },
            {
                channel: 'channel1',
                sender: users[1].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel1',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[1].name, date: '26.09.2022 10:49' }],
                id: '3'
            },
            {
                channel: 'channel1',
                sender: users[1].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel1',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[1].name, date: '26.09.2022 10:49' }],
                id: '5'
            },
            {
                channel: 'channel1',
                sender: users[1].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            }
        ]
    },
    channel2: {
        title: `${users[2].name} ${users[2].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel2',
                sender: '1001',
                text: 'Hello Majer',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[2].name, date: '26.09.2022 10:49' }],
                id: '1'
            },
            {
                channel: 'channel2',
                sender: users[2].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel2',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[2].name, date: '26.09.2022 10:49' }],
                id: '3'
            },
            {
                channel: 'channel2',
                sender: users[2].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel2',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[2].name, date: '26.09.2022 10:49' }],
                id: '5'
            },
            {
                channel: 'channel2',
                sender: users[2].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            }
        ]
    },
    channel3: {
        title: `${users[3].name} ${users[3].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel3',
                sender: '1001',
                text: 'Hello Jurgen',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[3].name, date: '26.09.2022 10:49' }],
                id: '1'
            },
            {
                channel: 'channel3',
                sender: users[3].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel3',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[3].name, date: '26.09.2022 10:49' }],
                id: '3'
            },
            {
                channel: 'channel3',
                sender: users[3].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel3',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[3].name, date: '26.09.2022 10:49' }],
                id: '5'
            },
            {
                channel: 'channel3',
                sender: users[3].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            }
        ]
    },
    channel4: {
        title: `${users[4].name} ${users[4].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel4',
                sender: '1001',
                text: 'Szia Szandor',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[4].name, date: '26.09.2022 10:49' }],
                id: '1'
            },
            {
                channel: 'channel4',
                sender: users[4].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel4',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[4].name, date: '26.09.2022 10:49' }],
                id: '3'
            },
            {
                channel: 'channel4',
                sender: users[4].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel4',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[4].name, date: '26.09.2022 10:49' }],
                id: '5'
            },
            {
                channel: 'channel4',
                sender: users[4].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            }
        ]
    },
    channel5: {
        title: `${users[5].name} ${users[5].surname}`,
        userId: '1001',
        messageIndexRef: 6,
        messages: [
            {
                channel: 'channel5',
                sender: '1001',
                text: 'Hello Sebastian',
                created: moment('26.09.2022 10:49', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[5].name, date: '26.09.2022 10:49' }],
                id: '1'
            },
            {
                channel: 'channel5',
                sender: users[5].name,
                text: 'Hello',
                created: moment('26.09.2022 10:50', DATE_TIME_FORMAT).format('L LT'),
                id: '2'
            },
            {
                channel: 'channel5',
                sender: '1001',
                text: 'Here is an image for you.',
                created: moment('26.09.2022 10:51', DATE_TIME_FORMAT).format('L LT'),
                payload: [
                    {
                        type: 'image',
                        url: 'https://telematics.eurowag.com/img/screen-module-0.png ',
                        thumb: 'https://telematics.eurowag.com/img/screen-module-0.png '
                    }
                ],
                seen: [{ user: users[5].name, date: '26.09.2022 10:49' }],
                id: '3'
            },
            {
                channel: 'channel5',
                sender: users[5].name,
                text: 'Thank you, it is nice',
                created: moment('26.09.2022 10:52', DATE_TIME_FORMAT).format('L LT'),
                id: '4'
            },
            {
                channel: 'channel5',
                sender: '1001',
                text: 'Have a nice day',
                created: moment('26.09.2022 10:53', DATE_TIME_FORMAT).format('L LT'),
                seen: [{ user: users[5].name, date: '26.09.2022 10:49' }],
                id: '5'
            },
            {
                channel: 'channel5',
                sender: users[5].name,
                text: 'Thank you',
                created: moment('26.09.2022 10:54', DATE_TIME_FORMAT).format('L LT'),
                id: '6'
            }
        ]
    }
};

export const channels = {
    channels: users
        .map((user, i) => ({
            channel: {
                title: `${users[i].name} ${users[i].surname}`,
                creator: '1b93c586-63ca-4fcc-b77e-c66cf946697f',
                created: '26.09.2022 10:49',
                users: [],
                newMsgCount: i === 0 ? 2 : 0,
                newMsgFrom: '26.09.2022 10:49',
                id: `channel${i}`
            },
            driver: {
                clientId: user.client?.id,
                email: '',
                id: String(user.id),
                mobile: '',
                name: user.name,
                note: '',
                pin: '0000',
                ssoId: user.ssoId ?? '',
                surname: user.surname,
                tachographId: '',
                tokens: [],
                userGroups: [],
                userName: user.name
            }
        }))
        .filter(channel => channel.driver.id !== '1001')
};
