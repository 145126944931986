/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChannelMessages
 */
export interface ChannelMessages {
    /**
     * 
     * @type {string}
     * @memberof ChannelMessages
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelMessages
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelMessages
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelMessages
     */
    creator: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelMessages
     */
    users: Array<string>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ChannelMessages
     */
    messages: Array<Message>;
}

export function ChannelMessagesFromJSON(json: any): ChannelMessages {
    return ChannelMessagesFromJSONTyped(json, false);
}

export function ChannelMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'created': (new Date(json['created'])),
        'creator': json['creator'],
        'users': json['users'],
        'messages': ((json['messages'] as Array<any>).map(MessageFromJSON)),
    };
}

export function ChannelMessagesToJSON(value?: ChannelMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'created': (value.created.toISOString()),
        'creator': value.creator,
        'users': value.users,
        'messages': ((value.messages as Array<any>).map(MessageToJSON)),
    };
}


