import { Logic } from '../logic';
import { DriverBehaviorTrucks } from './driver-behavior-trucks';
import { DriverBehaviorVehicles } from './driver-behavior-vehicles';

export class DriverBehavior {
    private _trucks?: DriverBehaviorTrucks;
    private _vehicles?: DriverBehaviorVehicles;
    constructor(private _logic: Logic) {}

    trucks() {
        if (!this._trucks) {
            this._trucks = new DriverBehaviorTrucks(this._logic);
        }

        return this._trucks;
    }

    vehicles() {
        if (!this._vehicles) {
            this._vehicles = new DriverBehaviorVehicles(this._logic);
        }

        return this._vehicles;
    }
}
