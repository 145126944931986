import { WithTranslation, withTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import Button from 'common/components/Button';
import TableBar from 'common/components/TableBar';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { SearchData } from 'common/components/Search';
import MaintenanceDueFilter from 'modules/statistics/modules/maintenance/components/maintenance-due-filter';
import MaintenanceFilter from 'modules/statistics/modules/maintenance/components/maintenance-filter';
import { DueFilterModel } from 'modules/statistics/modules/maintenance/MaintenanceModule';
import * as icons from 'resources/images/common';
import { confDefault } from 'conf';
import { arrayEquals } from 'common/utils/textUtils';

interface Props extends WithTranslation {
    demoMode?: boolean;
    search?: SearchData;
    filter: {
        isOpen: boolean;
        vehiclesOpts: CheckboxGroupOpts;
        driversOpts: CheckboxGroupOpts;
        trailersOpts: CheckboxGroupOpts;
        taskTypeOpts: CheckboxGroupOpts;
        intervalOpts: CheckboxGroupOpts;
        stateOpts: CheckboxGroupOpts;
        vehiclesChecked: string[];
        driversChecked: string[];
        trailersChecked: string[];
        taskTypeChecked: string[];
        intervalChecked: string[];
        stateChecked: string[];
        dueDateGte?: string;
        dueDateLte?: string;
    };
    deleteEnabled: boolean;
    completeEnabled: boolean;
    dueFilterExtended: boolean;
    dueFilter: DueFilterModel;
    onBarFilterClick: () => void;
    onBarCreateNewButtonClick: () => void;
    onBarCompleteClick: () => void;
    onBarExportClick: () => void;
    onBarDeleteClick: () => void;
    onBarSearchChange?: (text: string) => void;
    onBarResetClick?: () => void;
    onDueFilterActiveClick?: (selected: boolean) => void;
    onDueFilterDueSoonClick?: (selected: boolean) => void;
    onDueFilterOverdueClick?: (selected: boolean) => void;
    onFilterCancelClick?: () => void;
    onFilterConfirmClick?: (
        vehiclesChecked: string[],
        trailersChecked: string[],
        driversChecked: string[],
        taskTypeChecked: string[],
        interalChecked: string[],
        stateChecked: string[],
        dateFrom?: string,
        dateTo?: string
    ) => void;
    onBarBackUrlClick?: () => void;
}

function MaintenanceBar(props: Props) {
    const deleteDisabled = props.demoMode || !props.deleteEnabled;
    const completeDisabled = props.demoMode || !props.completeEnabled;
    const defaults = confDefault.settings.statistics.maintenance.filter;

    return (
        <>
            <TableBar
                searchInput={{ disabled: props.demoMode }}
                heading={props.t('Maintenance.title')}
                search={props.search}
                onSearchChange={props.onBarSearchChange}
                backButton={
                    props.onBarBackUrlClick
                        ? {
                              onClick: props.onBarBackUrlClick
                          }
                        : undefined
                }
                actions={[
                    <Button
                        type="primary"
                        onClick={props.onBarExportClick}
                        size="large"
                        qa={qa.maintenance.bar.btnExport}
                        icon={<img src={icons.export} alt={props.t('common.export')} />}
                    >
                        {props.t('common.export')}
                    </Button>,
                    <Button
                        type="primary"
                        onClick={props.onBarDeleteClick}
                        size="large"
                        disabled={deleteDisabled}
                        qa={qa.maintenance.bar.btnDelete}
                    >
                        {props.t('common.delete')}
                    </Button>,
                    <Button
                        type="primary"
                        onClick={props.onBarCompleteClick}
                        size="large"
                        disabled={completeDisabled}
                        qa={qa.maintenance.bar.btnMarkAsComplete}
                    >
                        {props.t('Maintenance.complete.markAsComplete')}
                    </Button>,
                    <Button
                        onClick={props.onBarCreateNewButtonClick}
                        type="dashed"
                        size="large"
                        qa={qa.maintenance.bar.btnCreateNew}
                    >
                        {props.t('Maintenance.createNew')}
                    </Button>
                ]}
                filter={{
                    activeCount:
                        (arrayEquals(props.filter.vehiclesChecked, defaults.vehicles) ? 0 : 1) +
                        (arrayEquals(props.filter.trailersChecked, defaults.trailers) ? 0 : 1) +
                        (arrayEquals(props.filter.driversChecked, defaults.drivers) ? 0 : 1) +
                        (arrayEquals(props.filter.intervalChecked, defaults.intervals) ? 0 : 1) +
                        (arrayEquals(props.filter.stateChecked, defaults.status) ? 0 : 1) +
                        (arrayEquals(props.filter.taskTypeChecked, defaults.taskTypes) ? 0 : 1) +
                        (props.filter.dueDateGte !== defaults.dueDateGte ? 1 : 0) +
                        (props.filter.dueDateLte !== defaults.dueDateLte ? 1 : 0),
                    onClick: props.onBarFilterClick,
                    resetButton: { onClick: props.onBarResetClick }
                }}
                filters={[
                    <MaintenanceDueFilter
                        demoMode={props.demoMode}
                        onActiveClick={props.onDueFilterActiveClick}
                        onDueSoonClick={props.onDueFilterDueSoonClick}
                        onOverdueClick={props.onDueFilterOverdueClick}
                        counts={{
                            overdue: props.dueFilter.overdueCount,
                            dueSoon: props.dueFilter.dueSoonCount,
                            active: props.dueFilter.activeCount
                        }}
                        selected={{
                            overdue: props.dueFilter.overdueSelected,
                            active: props.dueFilter.activeSelected,
                            dueSoon: props.dueFilter.dueSoonSelected
                        }}
                        extended={props.dueFilterExtended}
                    />
                ]}
            />
            {props.filter.isOpen && (
                <MaintenanceFilter
                    demoMode={props.demoMode}
                    vehiclesOpts={props.filter.vehiclesOpts}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    trailersOpts={props.filter.trailersOpts}
                    trailersChecked={props.filter.trailersChecked}
                    driversOpts={props.filter.driversOpts}
                    driversChecked={props.filter.driversChecked}
                    taskTypeOpts={props.filter.taskTypeOpts}
                    taskTypeChecked={props.filter.taskTypeChecked}
                    intervalOpts={props.filter.intervalOpts}
                    intervalChecked={props.filter.intervalChecked}
                    stateOpts={props.filter.stateOpts}
                    stateChecked={props.filter.stateChecked}
                    dueDateGte={props.filter.dueDateGte}
                    dueDateLte={props.filter.dueDateLte}
                    onConfirm={props.onFilterConfirmClick}
                    onCancel={props.onFilterCancelClick}
                />
            )}
        </>
    );
}

export default withTranslation()(MaintenanceBar);
