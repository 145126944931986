/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrailerPair
 */
export interface TrailerPair {
    /**
     * 
     * @type {number}
     * @memberof TrailerPair
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TrailerPair
     */
    registrationNumber: string;
    /**
     * 
     * @type {object}
     * @memberof TrailerPair
     */
    pairInfo?: object | null;
    /**
     * 
     * @type {Date}
     * @memberof TrailerPair
     */
    validTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TrailerPair
     */
    validFrom: Date;
    /**
     * 
     * @type {object}
     * @memberof TrailerPair
     */
    unpairInfo?: object | null;
}

export function TrailerPairFromJSON(json: any): TrailerPair {
    return TrailerPairFromJSONTyped(json, false);
}

export function TrailerPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrailerPair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'registrationNumber': json['registration_number'],
        'pairInfo': !exists(json, 'pair_info') ? undefined : json['pair_info'],
        'validTo': !exists(json, 'valid_to') ? undefined : (json['valid_to'] === null ? null : new Date(json['valid_to'])),
        'validFrom': (new Date(json['valid_from'])),
        'unpairInfo': !exists(json, 'unpair_info') ? undefined : json['unpair_info'],
    };
}

export function TrailerPairToJSON(value?: TrailerPair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'registration_number': value.registrationNumber,
        'pair_info': value.pairInfo,
        'valid_to': value.validTo === undefined ? undefined : (value.validTo === null ? null : value.validTo.toISOString()),
        'valid_from': (value.validFrom.toISOString()),
        'unpair_info': value.unpairInfo,
    };
}


