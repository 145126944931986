/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyDiemsRates,
    ReadOnlyDiemsRatesFromJSON,
    ReadOnlyDiemsRatesToJSON,
    WriteOnlyDiemsRates,
    WriteOnlyDiemsRatesFromJSON,
    WriteOnlyDiemsRatesToJSON,
} from '../models';

export interface DiemsRatesCreateRequest {
    data: WriteOnlyDiemsRates;
}

export interface DiemsRatesDeleteRequest {
    id: string;
}

export interface DiemsRatesImportExcelRequest {
    fileUploaded: Blob;
}

export interface DiemsRatesListRequest {
    countryIso3?: string;
    countryIso2?: string;
    foreignCountryIso2?: string;
    startLte?: string;
    endGte?: string;
    intervalFromLte?: number;
    intervalToGte?: number;
    onDay?: string;
}

export interface DiemsRatesPartialUpdateRequest {
    id: string;
    data: WriteOnlyDiemsRates;
}

export interface DiemsRatesReadRequest {
    id: string;
}

export interface DiemsRatesUpdateRequest {
    id: string;
    data: WriteOnlyDiemsRates;
}

/**
 * no description
 */
export class DiemsRatesApi extends runtime.BaseAPI {

    /**
     */
    async diemsRatesCreateRaw(requestParameters: DiemsRatesCreateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRates>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRatesCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRatesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRatesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRatesCreate(requestParameters: DiemsRatesCreateRequest): Promise<ReadOnlyDiemsRates> {
        const response = await this.diemsRatesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRatesDeleteRaw(requestParameters: DiemsRatesDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRates>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRatesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRatesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRatesDelete(requestParameters: DiemsRatesDeleteRequest): Promise<ReadOnlyDiemsRates> {
        const response = await this.diemsRatesDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRatesImportExcelRaw(requestParameters: DiemsRatesImportExcelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileUploaded === null || requestParameters.fileUploaded === undefined) {
            throw new runtime.RequiredError('fileUploaded','Required parameter requestParameters.fileUploaded was null or undefined when calling diemsRatesImportExcel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileUploaded !== undefined) {
            formParams.append('file_uploaded', requestParameters.fileUploaded as any);
        }

        const response = await this.request({
            path: `/diems-rates/import/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async diemsRatesImportExcel(requestParameters: DiemsRatesImportExcelRequest): Promise<void> {
        await this.diemsRatesImportExcelRaw(requestParameters);
    }

    /**
     */
    async diemsRatesListRaw(requestParameters: DiemsRatesListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyDiemsRates>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryIso3 !== undefined) {
            queryParameters['country__iso3'] = requestParameters.countryIso3;
        }

        if (requestParameters.countryIso2 !== undefined) {
            queryParameters['country__iso2'] = requestParameters.countryIso2;
        }

        if (requestParameters.foreignCountryIso2 !== undefined) {
            queryParameters['foreign_country__iso2'] = requestParameters.foreignCountryIso2;
        }

        if (requestParameters.startLte !== undefined) {
            queryParameters['start__lte'] = requestParameters.startLte;
        }

        if (requestParameters.endGte !== undefined) {
            queryParameters['end__gte'] = requestParameters.endGte;
        }

        if (requestParameters.intervalFromLte !== undefined) {
            queryParameters['interval_from__lte'] = requestParameters.intervalFromLte;
        }

        if (requestParameters.intervalToGte !== undefined) {
            queryParameters['interval_to__gte'] = requestParameters.intervalToGte;
        }

        if (requestParameters.onDay !== undefined) {
            queryParameters['on_day'] = requestParameters.onDay;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyDiemsRatesFromJSON));
    }

    /**
     */
    async diemsRatesList(requestParameters: DiemsRatesListRequest): Promise<Array<ReadOnlyDiemsRates>> {
        const response = await this.diemsRatesListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRatesPartialUpdateRaw(requestParameters: DiemsRatesPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRates>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRatesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRatesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRatesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRatesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRatesPartialUpdate(requestParameters: DiemsRatesPartialUpdateRequest): Promise<ReadOnlyDiemsRates> {
        const response = await this.diemsRatesPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRatesReadRaw(requestParameters: DiemsRatesReadRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRates>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRatesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRatesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRatesRead(requestParameters: DiemsRatesReadRequest): Promise<ReadOnlyDiemsRates> {
        const response = await this.diemsRatesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRatesUpdateRaw(requestParameters: DiemsRatesUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRates>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRatesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRatesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRatesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRatesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRatesUpdate(requestParameters: DiemsRatesUpdateRequest): Promise<ReadOnlyDiemsRates> {
        const response = await this.diemsRatesUpdateRaw(requestParameters);
        return await response.value();
    }

}
