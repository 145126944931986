import { Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import { StringValidator, FormValidator, SelectValidator } from 'validators';
import { PairingItemType, ObuTypeSelectModel } from 'logic/partner/partner';
import { PartnerCompanyModel, PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { ReadOnlyMonitoringDeviceType } from 'generated/new-main';
import { Select } from 'common/components/Form';
import { SelectValue } from 'antd/lib/select';
import { debounce } from 'debounce';
import { Role } from 'logic/auth';
import FormActions from 'common/components/FormActions';
import cn from 'classnames';
import { searched } from 'common/utils/search';

export interface ObuForm {
    id: string;
    client: string;
    clientErr: string;
    sn: string;
    snErr: string;
    type: string;
    typeErr: string;
    activatedOn: string;
}

export const defaultValidationErrors = {
    identErr: '',
    snErr: '',
    vehicleBrandErr: '',
    imeiErr: '',
    clientErr: '',
    typeErr: ''
};

export const defaultInputs: ObuModel = {
    id: '',
    sn: '',
    type: ''
};

export interface ObuFormValidator {
    sn: string;
    client: string;
    type: string;
}

export const identValidator = new StringValidator({
    required: true
});

export const snValidator = new StringValidator({
    required: true
});

export const vehicleBrandValidator = new StringValidator({
    required: true
});

export const imeiValidator = new StringValidator({
    required: true
});

export const clientValidator = new SelectValidator({
    required: true
});

export const typeValidator = new SelectValidator({
    required: true
});

export const formValidator = new FormValidator<ObuFormValidator>()
    .addValidator('sn', snValidator)
    .addValidator('client', clientValidator)
    .addValidator('type', typeValidator);

interface Props extends WithTranslation {
    model: ObuModel;
    deviceTypes?: ReadOnlyMonitoringDeviceType[];

    obuTypeCounter?: ObuTypeSelectModel[];
    clients?: PartnerCompanySelectModel[];
    roles: Role[];
    userClient?: PartnerCompanyModel;
    onClientSearch?: (text: string) => void;
    onFormCancel?: () => void;
    onFormSubmit?: (model: ObuModel) => void;
    onActionsPair?: () => void;
    onActionsCreate?: () => void;
    onActionsUnpair?: (linked: PairingItemType) => void;
}

interface State {
    form?: ObuForm;
}

class VehiclesDetail extends Component<Props, State> {
    obuModel?: ObuModel;

    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: this.props.model.id,
                sn: this.props.model.sn,
                type: String(this.props.model.type),
                client: this.props.model.clientId ?? '',
                activatedOn: ''
            }
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="partner-sidepanel">
                <div className="partner-detail">
                    <form className="t-small editable" onSubmit={this._onFormSubmit}>
                        <div className="t-bar-block">
                            <div className="t-row">
                                <div className="t-half t-bold" title={t('common.type')}>
                                    {t('common.type')}
                                </div>
                                <div className="t-half">
                                    <Select
                                        className={cn({
                                            error: this.state.form?.type,
                                            success: !this.state.form?.typeErr && !!this.state.form?.type
                                        })}
                                        showSearch
                                        placeholder={t('common.select')}
                                        value={this.state.form?.type}
                                        filterOption={(input, option) => searched(input, String(option?.children))}
                                        size="small"
                                        options={this.props.deviceTypes?.map(v => ({
                                            value: String(v.id),
                                            label: v.label
                                        }))}
                                        onChange={this._onTypeChange}
                                    />
                                    {this.state.form?.typeErr && (
                                        <span className="t-bold t-text-danger">
                                            {t('validator.' + this.state.form.typeErr)}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="t-row">
                                <div className="t-half t-bold" title={t('common.company')}>
                                    {t('common.company')}
                                </div>
                                <div className="t-half">
                                    <Select
                                        className={cn({
                                            error: this.state.form?.client,
                                            success: !this.state.form?.clientErr && !!this.state.form?.client
                                        })}
                                        showSearch
                                        placeholder={t('common.select')}
                                        value={this.state.form?.client}
                                        filterOption={false}
                                        size="small"
                                        options={this.props.clients?.map(v => ({
                                            value: v.id,
                                            label: v.label
                                        }))}
                                        onChange={this._onClientChange}
                                        onSearch={this._onClientSearch}
                                    />
                                    {this.state.form?.clientErr && (
                                        <span className="t-bold t-text-danger">
                                            {t('validator.' + this.state.form.clientErr)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <FormActions onCancelClick={this.props.onFormCancel} />
                    </form>
                </div>
            </div>
        );
    }

    private _onTypeChange = (value: SelectValue) => {
        const validateRes = typeValidator.validate(String(value));
        this.setState(state => ({
            form: {
                ...state.form!,
                type: String(value),
                typeErr: validateRes.err as string
            }
        }));
    };

    private _onClientChange = (value: SelectValue) => {
        const validateRes = clientValidator.validate(String(value));
        this.setState(state => ({
            form: {
                ...state.form!,
                client: String(value),
                clientErr: validateRes.err as string
            }
        }));
    };

    private _onClientSearch = debounce((text: string) => {
        this.props.onClientSearch?.(text);
    }, 4e2);

    private _onFormSubmit = (e: FormEvent): void => {
        e.preventDefault();
        if (this.state.form) {
            const validationRes = formValidator.validate({
                sn: this.state.form.sn,
                type: this.state.form.type,
                client: this.state.form.client
            });

            if (validationRes.valid) {
                this.props.onFormSubmit?.({
                    ...this.props.model,
                    sn: this.state.form.sn,
                    type: this.state.form.type,
                    clientId: this.state.form.client
                });
            } else {
                this.setState(state => ({
                    form: {
                        ...state.form!,
                        snErr: validationRes.err?.sn ?? '',
                        typeErr: validationRes.err?.type ?? '',
                        clientErr: validationRes.err?.client ?? ''
                    }
                }));
            }
        }
    };
}

export default withTranslation()(VehiclesDetail);
