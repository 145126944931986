/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    RawDataPointGpsModel , RawDataPointStateActivityModel , RawDataPointStateGpsModel , RawDataPointFuelConsumptionModel , RawDataPointFuelLevelModel, RawDataPointCanDistanceModel,
    RawDataPointCanDistanceModelFromJSON,
    RawDataPointCanDistanceModelToJSON,
} from '../models';

export interface PostRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPostRequest {
    sourceDeviceId: string;
    installationId: string;
    rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel: Array<RawDataPointGpsModel | RawDataPointStateActivityModel | RawDataPointStateGpsModel | RawDataPointFuelConsumptionModel | RawDataPointFuelLevelModel | RawDataPointCanDistanceModel>;
}

export interface PostRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPostRequest {
    sourceDeviceId: string;
    installationId: string;
    rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel: Array<RawDataPointGpsModel | RawDataPointStateActivityModel | RawDataPointStateGpsModel | RawDataPointFuelConsumptionModel | RawDataPointFuelLevelModel | RawDataPointCanDistanceModel>;
}

/**
 * no description
 */
export class RawdataApi extends runtime.BaseAPI {

    /**
     * Post Raw Data Status
     */
    async postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPostRaw(requestParameters: PostRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sourceDeviceId === null || requestParameters.sourceDeviceId === undefined) {
            throw new runtime.RequiredError('sourceDeviceId','Required parameter requestParameters.sourceDeviceId was null or undefined when calling postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPost.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPost.');
        }

        if (requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel === null || requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel === undefined) {
            throw new runtime.RequiredError('rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel','Required parameter requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel was null or undefined when calling postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installation_id'] = requestParameters.installationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rawdata/rawDataVersion2/{source_device_id}/status`.replace(`{${"source_device_id"}}`, encodeURIComponent(String(requestParameters.sourceDeviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel.map(item => RawDataPointCanDistanceModelToJSON),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Post Raw Data Status
     */
    async postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPost(requestParameters: PostRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPostRequest): Promise<object> {
        const response = await this.postRawDataStatusV1RawdataRawDataVersion2SourceDeviceIdStatusPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Raw Data
     */
    async postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPostRaw(requestParameters: PostRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sourceDeviceId === null || requestParameters.sourceDeviceId === undefined) {
            throw new runtime.RequiredError('sourceDeviceId','Required parameter requestParameters.sourceDeviceId was null or undefined when calling postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPost.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPost.');
        }

        if (requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel === null || requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel === undefined) {
            throw new runtime.RequiredError('rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel','Required parameter requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel was null or undefined when calling postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installation_id'] = requestParameters.installationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rawdata/rawDataVersion2/{source_device_id}/data`.replace(`{${"source_device_id"}}`, encodeURIComponent(String(requestParameters.sourceDeviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.rawDataPointGpsModelRawDataPointStateActivityModelRawDataPointStateGpsModelRawDataPointFuelConsumptionModelRawDataPointFuelLevelModelRawDataPointCanDistanceModel.map(item => RawDataPointCanDistanceModelToJSON),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Post Raw Data
     */
    async postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPost(requestParameters: PostRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPostRequest): Promise<object> {
        const response = await this.postRawDataV1RawdataRawDataVersion2SourceDeviceIdDataPostRaw(requestParameters);
        return await response.value();
    }

}
