import AutoComplete, { Props as AutoCompleteProps } from 'common/components/AutoCompleteAntd';
import { Control } from 'common/components/Form/Control';
import { Field, FieldProps, getIn } from 'formik';
import { ReactNode } from 'react';

interface Props extends AutoCompleteProps {
    name: string;
    label?: ReactNode;
    customValueEnable?: boolean;
    qa?: string;
}

const AutocompleteField = ({ name, label, qa, customValueEnable, ...props }: Props) => (
    <Field name={name}>
        {({ form: { errors, touched }, form }: FieldProps<any>) => {
            const handleChange = (value: string) => {
                form.setFieldValue(name, value);
            };
            return (
                <Control qa={qa} label={label} error={getIn(touched, name) && getIn(errors, name)}>
                    <AutoComplete onChange={customValueEnable ? handleChange : undefined} {...props} />
                </Control>
            );
        }}
    </Field>
);

export default AutocompleteField;
