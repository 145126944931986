import * as icons from 'resources/images/dispatcher-board';
import { useTranslation } from 'react-i18next';
import { Load } from 'generated/dff-api';
import { Col, Row } from 'antd';
import DffProposalDetailData from '../DFFProposalDetailData/DFFProposalDetailData';

interface Props {
    loads: Load[];
}

export default function DFFProposalDetailLoads(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="dff-proposal-detail-loads">
            {props.loads.map((load, index) => (
                <div className="dff-proposal-detail-loads-item" key={index}>
                    <div className="dff-proposal-detail-loads-item-header">
                        {`${t('DispatcherBoardProposalDetailDff.load')} ${++index}`}
                        <img src={icons.packet2} alt="load" />
                        {`${load.count} x ${load.loadType}`}
                    </div>

                    <div className="dff-proposal-detail-loads-item-content">
                        <Row justify="space-between" style={{ marginBottom: 16 }}>
                            <Col>
                                <DffProposalDetailData
                                    label={t('common.weight')}
                                    value={
                                        load.weight.value ? `${Math.round(load.weight.value)} ${load.weight.unit}` : '-'
                                    }
                                />
                            </Col>
                            <Col>
                                <DffProposalDetailData
                                    label={t('DispatcherBoardProposalDetailDff.fluidVolume')}
                                    value={
                                        load.volumeFluids?.value
                                            ? `${Math.round(load.volumeFluids.value)} ${load.volumeFluids.unit}`
                                            : '-'
                                    }
                                />
                            </Col>
                            <Col>
                                <DffProposalDetailData
                                    label={t('common.width')}
                                    value={
                                        load.width?.value ? `${Math.round(load.width.value)} ${load.width.unit}` : '-'
                                    }
                                />
                            </Col>
                            <Col>
                                <DffProposalDetailData
                                    label={t('common.height')}
                                    value={
                                        load.height?.value
                                            ? `${Math.round(load.height.value)} ${load.height.unit}`
                                            : '-'
                                    }
                                />
                            </Col>
                            <Col>
                                <DffProposalDetailData
                                    label={t('common.length')}
                                    value={
                                        load.length?.value
                                            ? `${Math.round(load.length.value)} ${load.length.unit}`
                                            : '-'
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DffProposalDetailData label={t('common.description')} value={load.description} />
                            </Col>
                        </Row>
                    </div>
                </div>
            ))}
        </div>
    );
}
