/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomPlace,
    CustomPlaceFromJSON,
    CustomPlaceToJSON,
    DriversToCustomPlaces,
    DriversToCustomPlacesFromJSON,
    DriversToCustomPlacesToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PlaceOfWork,
    PlaceOfWorkFromJSON,
    PlaceOfWorkToJSON,
    PlaceOfWorkInDB,
    PlaceOfWorkInDBFromJSON,
    PlaceOfWorkInDBToJSON,
} from '../models';

export interface PlaceOfWorkCreateV1PlacesPlaceOfWorkPostRequest {
    driversToCustomPlaces: DriversToCustomPlaces;
}

export interface PlaceOfWorkDeleteV1PlacesPlaceOfWorkDeleteRequest {
    customPlaceId: string;
    placeOfWorkId: string;
}

export interface PlaceOfWorkListV1PlacesPlaceOfWorkGetRequest {
    userIds?: Array<number>;
    start?: Date;
    end?: Date;
}

export interface PlaceOfWorkUpdateV1PlacesPlaceOfWorkPutRequest {
    customPlaceId: string;
    placeOfWorkId: string;
    placeOfWork: PlaceOfWork;
}

/**
 * no description
 */
export class PlaceOfWorkApi extends runtime.BaseAPI {

    /**
     * Place Of Work Create
     */
    async placeOfWorkCreateV1PlacesPlaceOfWorkPostRaw(requestParameters: PlaceOfWorkCreateV1PlacesPlaceOfWorkPostRequest): Promise<runtime.ApiResponse<Array<PlaceOfWorkInDB>>> {
        if (requestParameters.driversToCustomPlaces === null || requestParameters.driversToCustomPlaces === undefined) {
            throw new runtime.RequiredError('driversToCustomPlaces','Required parameter requestParameters.driversToCustomPlaces was null or undefined when calling placeOfWorkCreateV1PlacesPlaceOfWorkPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/place_of_work`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriversToCustomPlacesToJSON(requestParameters.driversToCustomPlaces),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaceOfWorkInDBFromJSON));
    }

    /**
     * Place Of Work Create
     */
    async placeOfWorkCreateV1PlacesPlaceOfWorkPost(requestParameters: PlaceOfWorkCreateV1PlacesPlaceOfWorkPostRequest): Promise<Array<PlaceOfWorkInDB>> {
        const response = await this.placeOfWorkCreateV1PlacesPlaceOfWorkPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Place Of Work Delete
     */
    async placeOfWorkDeleteV1PlacesPlaceOfWorkDeleteRaw(requestParameters: PlaceOfWorkDeleteV1PlacesPlaceOfWorkDeleteRequest): Promise<runtime.ApiResponse<PlaceOfWorkInDB>> {
        if (requestParameters.customPlaceId === null || requestParameters.customPlaceId === undefined) {
            throw new runtime.RequiredError('customPlaceId','Required parameter requestParameters.customPlaceId was null or undefined when calling placeOfWorkDeleteV1PlacesPlaceOfWorkDelete.');
        }

        if (requestParameters.placeOfWorkId === null || requestParameters.placeOfWorkId === undefined) {
            throw new runtime.RequiredError('placeOfWorkId','Required parameter requestParameters.placeOfWorkId was null or undefined when calling placeOfWorkDeleteV1PlacesPlaceOfWorkDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customPlaceId !== undefined) {
            queryParameters['custom_place_id'] = requestParameters.customPlaceId;
        }

        if (requestParameters.placeOfWorkId !== undefined) {
            queryParameters['place_of_work_id'] = requestParameters.placeOfWorkId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/place_of_work`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaceOfWorkInDBFromJSON(jsonValue));
    }

    /**
     * Place Of Work Delete
     */
    async placeOfWorkDeleteV1PlacesPlaceOfWorkDelete(requestParameters: PlaceOfWorkDeleteV1PlacesPlaceOfWorkDeleteRequest): Promise<PlaceOfWorkInDB> {
        const response = await this.placeOfWorkDeleteV1PlacesPlaceOfWorkDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Place Of Work List
     */
    async placeOfWorkListV1PlacesPlaceOfWorkGetRaw(requestParameters: PlaceOfWorkListV1PlacesPlaceOfWorkGetRequest): Promise<runtime.ApiResponse<Array<CustomPlace>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/place_of_work`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomPlaceFromJSON));
    }

    /**
     * Place Of Work List
     */
    async placeOfWorkListV1PlacesPlaceOfWorkGet(requestParameters: PlaceOfWorkListV1PlacesPlaceOfWorkGetRequest): Promise<Array<CustomPlace>> {
        const response = await this.placeOfWorkListV1PlacesPlaceOfWorkGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Place Of Work Update
     */
    async placeOfWorkUpdateV1PlacesPlaceOfWorkPutRaw(requestParameters: PlaceOfWorkUpdateV1PlacesPlaceOfWorkPutRequest): Promise<runtime.ApiResponse<PlaceOfWorkInDB>> {
        if (requestParameters.customPlaceId === null || requestParameters.customPlaceId === undefined) {
            throw new runtime.RequiredError('customPlaceId','Required parameter requestParameters.customPlaceId was null or undefined when calling placeOfWorkUpdateV1PlacesPlaceOfWorkPut.');
        }

        if (requestParameters.placeOfWorkId === null || requestParameters.placeOfWorkId === undefined) {
            throw new runtime.RequiredError('placeOfWorkId','Required parameter requestParameters.placeOfWorkId was null or undefined when calling placeOfWorkUpdateV1PlacesPlaceOfWorkPut.');
        }

        if (requestParameters.placeOfWork === null || requestParameters.placeOfWork === undefined) {
            throw new runtime.RequiredError('placeOfWork','Required parameter requestParameters.placeOfWork was null or undefined when calling placeOfWorkUpdateV1PlacesPlaceOfWorkPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customPlaceId !== undefined) {
            queryParameters['custom_place_id'] = requestParameters.customPlaceId;
        }

        if (requestParameters.placeOfWorkId !== undefined) {
            queryParameters['place_of_work_id'] = requestParameters.placeOfWorkId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/places/place_of_work`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlaceOfWorkToJSON(requestParameters.placeOfWork),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaceOfWorkInDBFromJSON(jsonValue));
    }

    /**
     * Place Of Work Update
     */
    async placeOfWorkUpdateV1PlacesPlaceOfWorkPut(requestParameters: PlaceOfWorkUpdateV1PlacesPlaceOfWorkPutRequest): Promise<PlaceOfWorkInDB> {
        const response = await this.placeOfWorkUpdateV1PlacesPlaceOfWorkPutRaw(requestParameters);
        return await response.value();
    }

}
