/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DiemsRules,
    DiemsRulesFromJSON,
    DiemsRulesToJSON,
    DiemsRulesAffected,
    DiemsRulesAffectedFromJSON,
    DiemsRulesAffectedToJSON,
    DiemsRulesCreateMany,
    DiemsRulesCreateManyFromJSON,
    DiemsRulesCreateManyToJSON,
    ReadOnlyDiemsRules,
    ReadOnlyDiemsRulesFromJSON,
    ReadOnlyDiemsRulesToJSON,
    WriteOnlyDiemsRules,
    WriteOnlyDiemsRulesFromJSON,
    WriteOnlyDiemsRulesToJSON,
} from '../models';

export interface DiemsRulesCreateRequest {
    data: WriteOnlyDiemsRules;
}

export interface DiemsRulesCreateManyRequest {
    data: DiemsRulesCreateMany;
}

export interface DiemsRulesDeleteRequest {
    id: string;
}

export interface DiemsRulesListRequest {
    client?: string;
    user?: string;
    userIsnull?: boolean;
    userIn?: string;
    startLte?: string;
    endGte?: string;
}

export interface DiemsRulesOverlappingRequest {
    start: string;
    end: string;
    users?: string;
}

export interface DiemsRulesPartialUpdateRequest {
    id: string;
    data: WriteOnlyDiemsRules;
}

export interface DiemsRulesReadRequest {
    id: string;
}

export interface DiemsRulesUpdateRequest {
    id: string;
    data: WriteOnlyDiemsRules;
}

/**
 * no description
 */
export class DiemsRulesApi extends runtime.BaseAPI {

    /**
     */
    async diemsRulesCreateRaw(requestParameters: DiemsRulesCreateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRulesCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRulesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRulesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesCreate(requestParameters: DiemsRulesCreateRequest): Promise<ReadOnlyDiemsRules> {
        const response = await this.diemsRulesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesCreateManyRaw(requestParameters: DiemsRulesCreateManyRequest): Promise<runtime.ApiResponse<Array<DiemsRules>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRulesCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/create-many/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiemsRulesCreateManyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiemsRulesFromJSON));
    }

    /**
     */
    async diemsRulesCreateMany(requestParameters: DiemsRulesCreateManyRequest): Promise<Array<DiemsRules>> {
        const response = await this.diemsRulesCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesDeleteRaw(requestParameters: DiemsRulesDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRulesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRulesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesDelete(requestParameters: DiemsRulesDeleteRequest): Promise<ReadOnlyDiemsRules> {
        const response = await this.diemsRulesDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesListRaw(requestParameters: DiemsRulesListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyDiemsRules>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.userIsnull !== undefined) {
            queryParameters['user__isnull'] = requestParameters.userIsnull;
        }

        if (requestParameters.userIn !== undefined) {
            queryParameters['user__in'] = requestParameters.userIn;
        }

        if (requestParameters.startLte !== undefined) {
            queryParameters['start__lte'] = requestParameters.startLte;
        }

        if (requestParameters.endGte !== undefined) {
            queryParameters['end__gte'] = requestParameters.endGte;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyDiemsRulesFromJSON));
    }

    /**
     */
    async diemsRulesList(requestParameters: DiemsRulesListRequest): Promise<Array<ReadOnlyDiemsRules>> {
        const response = await this.diemsRulesListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesOverlappingRaw(requestParameters: DiemsRulesOverlappingRequest): Promise<runtime.ApiResponse<DiemsRulesAffected>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling diemsRulesOverlapping.');
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError('end','Required parameter requestParameters.end was null or undefined when calling diemsRulesOverlapping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.users !== undefined) {
            queryParameters['users'] = requestParameters.users;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/overlapping/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiemsRulesAffectedFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesOverlapping(requestParameters: DiemsRulesOverlappingRequest): Promise<DiemsRulesAffected> {
        const response = await this.diemsRulesOverlappingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesPartialUpdateRaw(requestParameters: DiemsRulesPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRulesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRulesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRulesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRulesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesPartialUpdate(requestParameters: DiemsRulesPartialUpdateRequest): Promise<ReadOnlyDiemsRules> {
        const response = await this.diemsRulesPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesReadRaw(requestParameters: DiemsRulesReadRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRulesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRulesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesRead(requestParameters: DiemsRulesReadRequest): Promise<ReadOnlyDiemsRules> {
        const response = await this.diemsRulesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async diemsRulesUpdateRaw(requestParameters: DiemsRulesUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDiemsRules>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diemsRulesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling diemsRulesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/diems-rules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDiemsRulesToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDiemsRulesFromJSON(jsonValue));
    }

    /**
     */
    async diemsRulesUpdate(requestParameters: DiemsRulesUpdateRequest): Promise<ReadOnlyDiemsRules> {
        const response = await this.diemsRulesUpdateRaw(requestParameters);
        return await response.value();
    }

}
