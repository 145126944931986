/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PTVTollCostTotalCost
 */
export interface PTVTollCostTotalCost {
    /**
     * 
     * @type {number}
     * @memberof PTVTollCostTotalCost
     */
    amountInTargetCurrency: number;
}

export function PTVTollCostTotalCostFromJSON(json: any): PTVTollCostTotalCost {
    return PTVTollCostTotalCostFromJSONTyped(json, false);
}

export function PTVTollCostTotalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): PTVTollCostTotalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountInTargetCurrency': json['amountInTargetCurrency'],
    };
}

export function PTVTollCostTotalCostToJSON(value?: PTVTollCostTotalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amountInTargetCurrency': value.amountInTargetCurrency,
    };
}


