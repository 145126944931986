import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { DatePickerField, InputField } from 'common/fields';
import { MaintenanceCompletitionModel } from '../MaintenanceForm';
import { Button } from 'common/components';
import MultiSelectAutocompleteField from 'common/fields/AutoCompleteMultiSelect';
import { TIME_FORMAT_SHORT } from 'domain-constants';

interface Props extends WithTranslation {
    demoMode?: boolean;
    odometerVisible: boolean;
    odometerRequired: boolean;
    odometerOpts: {
        maintenanceTaskId: string;
        odometer: number;
    }[];
    lang: string;
    initialValues?: Partial<MaintenanceCompletitionModel>;
    onSubmit: (values: MaintenanceCompletitionModel) => Promise<boolean>;
    onCancel: () => void;
}

function MaintenanceCompletitionForm(props: Props) {
    const [odometerValue, setOdometerValue] = useState<string>(
        props.odometerOpts.length === 1 ? props.odometerOpts?.[0].odometer.toString() : ''
    );

    const schema = Yup.object().shape({
        date: Yup.string().required(props.t('common.required')),
        odometer: Yup.number()
            .nullable()
            .when([], {
                is: () => props.odometerRequired,
                then: Yup.number()
                    .nullable()
                    .min(0, props.t('validator.invalid_value'))
                    .required(props.t('common.required')),
                otherwise: Yup.number().nullable().notRequired()
            })
    });

    function handleOnSubmit(values: MaintenanceCompletitionModel) {
        props.onSubmit?.(values);
    }

    return (
        <Formik<MaintenanceCompletitionModel>
            initialValues={{
                date: props.initialValues?.date,
                odometer: props.odometerOpts.length === 1 ? props.odometerOpts?.[0].odometer : undefined
            }}
            onSubmit={val => {
                handleOnSubmit(val);
            }}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {(formik: FormikProps<MaintenanceCompletitionModel>) => {
                return (
                    <Form className="maintenance-completition-form">
                        <div className="maintenance-completition-form-inputs">
                            <Row gutter={[12, 16]}>
                                <Col>{props.t('Maintenance.complete.description')}</Col>
                                <Col span={24}>
                                    <DatePickerField
                                        size="large"
                                        lang={props.lang}
                                        name="date"
                                        showTime={{ format: TIME_FORMAT_SHORT }}
                                    />
                                </Col>
                                {props.odometerVisible && (
                                    <Col span={24}>
                                        {props.odometerOpts && props.odometerOpts?.length === 1 ? (
                                            <InputField.Number
                                                name="odometer"
                                                size="large"
                                                label={props.t('Maintenance.complete.stateOfOdometer')}
                                            />
                                        ) : (
                                            <MultiSelectAutocompleteField
                                                options={props.odometerOpts?.map(d => ({
                                                    key: d.maintenanceTaskId,
                                                    value: d.odometer
                                                }))}
                                                search={false}
                                                name="odometer"
                                                onChange={val => {
                                                    setOdometerValue(val);
                                                    formik.setFieldValue('odometer', Number(val));
                                                }}
                                                placeholderText={props.t('Maintenance.complete.odometerPlaceholder')}
                                                size="large"
                                                value={odometerValue}
                                                label={props.t('Maintenance.complete.stateOfOdometer')}
                                                onSelect={val => {
                                                    setOdometerValue(val);
                                                    formik.setFieldValue('odometer', Number(val));
                                                }}
                                            />
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <Row gutter={[12, 16]} justify="end" className="buttons-group">
                            <Col>
                                <Button onClick={props.onCancel}>{props.t('common.cancel')}</Button>
                            </Col>
                            <Col>
                                <Button
                                    disabled={formik.isSubmitting || props.demoMode}
                                    type="primary"
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                >
                                    {props.t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(MaintenanceCompletitionForm);
