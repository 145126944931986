import { google } from 'google-maps';
import { LatLng } from 'common/model/geo';

export class PoiMapController {
    private _map?: google.maps.Map;
    private _google: google;
    private _padding: google.maps.Padding;
    private _polygon?: google.maps.Polygon;
    private _circle?: google.maps.Circle;
    private _addPolygon?: google.maps.MapsEventListener;
    private _drawing?: google.maps.drawing.DrawingManager;
    private _isBeingDragged: boolean;

    private _onEditPolygon?: (data: LatLng[], center: LatLng) => void;
    private _onDragEndPolygon?: (data: LatLng[], center: LatLng) => void;
    private _onCreatePolygon?: (lat: number, lng: number) => void;

    readonly initialPadding = {
        left: 850,
        top: 200,
        right: 250,
        bottom: 200
    };

    constructor(google: google, map?: google.maps.Map) {
        this._map = map;
        this._google = google;
        this._padding = this.initialPadding;
        this._isBeingDragged = false;
    }

    onEditPolygon(cb: (data: LatLng[], center: LatLng) => void) {
        this._onEditPolygon = cb;
    }

    onDragEndPolygon(cb: (data: LatLng[], center: LatLng) => void) {
        this._onDragEndPolygon = cb;
    }

    onCreatePolygon(cb: (lat: number, lng: number) => void) {
        const that = this;
        this._onCreatePolygon = cb;
        if (this._map) {
            this._map.setOptions({
                draggableCursor: 'crosshair'
            });

            this._addPolygon = this._google.maps.event.addListener(this._map, 'click', function (evt) {
                that.renderPoiPolygonFromPoint(evt.latLng);
                that._onCreatePolygon?.(evt.latLng.lat(), evt.latLng.lng());
            });
        }
    }

    fitPolygon() {
        const bounds = new this._google.maps.LatLngBounds();
        this._polygon!.getPath().forEach(c => bounds.extend(c));
        this._map?.fitBounds(bounds, this._padding);
    }

    fitCircle() {
        if (this._circle) {
            const bounds = this._circle.getBounds();
            if (bounds) {
                this._map?.fitBounds(bounds, this._padding);
            }
        }
    }

    removePolygonListener(): void {
        this._map?.setOptions({
            draggableCursor: undefined
        });
        this._addPolygon && this._google.maps.event.removeListener(this._addPolygon);
    }

    getCenter() {
        const bounds = new this._google.maps.LatLngBounds();
        this._polygon!.getPath().forEach(c => bounds.extend(c));
        const center = {
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng()
        };
        return center;
    }

    getCoordinatesFromPoint(lat: number, lng: number, radius?: number): LatLng[] {
        const point: google.maps.LatLng = new this._google.maps.LatLng(lat, lng);
        const rad: number = radius ? radius : 100;
        return [
            { lat: this._offsetBearing(point, rad, 0).lat(), lng: this._offsetBearing(point, rad, 0).lng() },
            { lat: this._offsetBearing(point, rad, 60).lat(), lng: this._offsetBearing(point, rad, 60).lng() },
            { lat: this._offsetBearing(point, rad, 120).lat(), lng: this._offsetBearing(point, rad, 120).lng() },
            { lat: this._offsetBearing(point, rad, 180).lat(), lng: this._offsetBearing(point, rad, 180).lng() },
            { lat: this._offsetBearing(point, rad, 240).lat(), lng: this._offsetBearing(point, rad, 240).lng() },
            { lat: this._offsetBearing(point, rad, 300).lat(), lng: this._offsetBearing(point, rad, 300).lng() }
        ];
    }

    renderPoiPolygonFromPoint(point: google.maps.LatLng) {
        const coordinates = this.getCoordinatesFromPoint(point.lat(), point.lng());
        this.renderPoiPolygon(coordinates, true);
        return coordinates;
    }

    renderPoiPolygon(polygon: LatLng[], editable: boolean): void {
        this._removePoiPolygon();
        this._removePoiCircle();

        const google = this._google;
        const path = polygon.map(p => new google.maps.LatLng(p.lat, p.lng));
        this._polygon = new this._google.maps.Polygon({
            paths: path,
            strokeColor: '#07ADFA',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: '#07ADFA',
            fillOpacity: 0.2,
            editable: editable,
            draggable: editable,
            clickable: editable
        });

        const polygonPath = this._polygon;
        const that = this;

        this._google.maps.event.addListener(this._polygon.getPath(), 'set_at', function (_evt) {
            if (!that._isBeingDragged && that._onEditPolygon) {
                let data: LatLng[] = [];
                polygonPath.getPath().forEach(d => data.push({ lat: d.lat(), lng: d.lng() }));
                that._onEditPolygon(data, that.getCenter());
            }
        });

        this._google.maps.event.addListener(this._polygon.getPath(), 'insert_at', function (_evt) {
            if (!that._isBeingDragged && that._onEditPolygon) {
                let data: LatLng[] = [];
                polygonPath.getPath().forEach(d => data.push({ lat: d.lat(), lng: d.lng() }));
                that._onEditPolygon(data, that.getCenter());
            }
        });

        this._google.maps.event.addListener(this._polygon, 'dragstart', () => {
            this._isBeingDragged = true;
        });

        this._google.maps.event.addListener(this._polygon, 'dragend', () => {
            this._isBeingDragged = false;
            if (that._onDragEndPolygon) {
                let data: LatLng[] = [];
                polygonPath.getPath().forEach(d => data.push({ lat: d.lat(), lng: d.lng() }));
                that._onDragEndPolygon(data, that.getCenter());
            }
        });

        if (this._polygon && this._map) {
            this._polygon.setMap(this._map);
            this.fitPolygon();
        }
    }

    renderPoiCircle(circle: { lat: number; lng: number; radius: number }): void {
        this._removePoiPolygon();
        this._removePoiCircle();

        this._circle = new this._google.maps.Circle({
            fillColor: '#07ADFA',
            fillOpacity: 0.2,
            strokeColor: '#07ADFA',
            strokeWeight: 3,
            strokeOpacity: 0.8,
            clickable: true,
            editable: true,
            radius: circle.radius,
            center: {
                lat: circle.lat,
                lng: circle.lng
            }
        });

        if (this._circle && this._map) {
            this._circle.setMap(this._map);
            this.fitCircle();
        }
    }

    destroy(): void {
        this._padding = this.initialPadding;
        this._removePoiPolygon();
        this._removePoiCircle();

        if (this._addPolygon) {
            this._google.maps.event.removeListener(this._addPolygon);
        }

        if (this._map) {
            this._map.setOptions({
                draggableCursor: undefined
            });
        }
    }

    addDrawingManager() {
        if (this._drawing) return;

        this._drawing = new this._google.maps.drawing.DrawingManager({
            drawingControlOptions: {
                drawingModes: [
                    this._google.maps.drawing.OverlayType.CIRCLE,
                    this._google.maps.drawing.OverlayType.POLYGON
                ],
                position: this._google.maps.ControlPosition.TOP_CENTER
            },
            circleOptions: {
                fillColor: '#07ADFA',
                fillOpacity: 0.2,
                strokeColor: '#07ADFA',
                strokeWeight: 3,
                strokeOpacity: 0.8,
                clickable: true,
                editable: true
            },
            polygonOptions: {
                strokeColor: '#07ADFA',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: '#07ADFA',
                fillOpacity: 0.2,
                editable: true
            }
        });

        this._google.maps.event.addListener(this._drawing, 'overlaycomplete', function (event) {
            if (event.type === 'polygon') {
                const coordinates: [[number, number]] = event.overlay
                    .getPath()
                    .g.map((a: google.maps.LatLng) => [a.lat(), a.lng()]);
                alert(coordinates);
            }
            if (event.type === 'circle') {
                const radius = Math.round(event.overlay.getRadius() / 10) * 10;
                const center = event.overlay.getCenter();
                alert('circleRadius: ' + radius + '\nlat: ' + center.lat() + '\nlon: ' + center.lng());
            }
        });

        this._map && this._drawing && this._drawing.setMap(this._map);
    }

    private _removePoiPolygon() {
        this._polygon?.setMap?.(null);
        this._polygon = undefined;
    }

    private _removePoiCircle() {
        this._circle?.setMap?.(null);
        this._circle = undefined;
    }

    private _offsetBearing(point: google.maps.LatLng, dist: number, bearing: number) {
        const { maps } = this._google;
        const latConv =
            maps.geometry.spherical.computeDistanceBetween(point, new maps.LatLng(point.lat() + 0.1, point.lng())) * 10;
        const lngConv =
            maps.geometry.spherical.computeDistanceBetween(point, new maps.LatLng(point.lat(), point.lng() + 0.1)) * 10;
        const lat = (dist * Math.cos((bearing * Math.PI) / 180)) / latConv;
        const lng = (dist * Math.sin((bearing * Math.PI) / 180)) / lngConv;
        return new maps.LatLng(point.lat() + lat, point.lng() + lng);
    }
}
