import { useLogic } from 'App';
import { AddressIdentification } from 'common/components/Settings';
import { AddressStructured } from 'generated/graphql';
import { toAddressString } from 'common/utils/address';
import * as React from 'react';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    defaultAddress: string;
    addressStructured?: AddressStructured[];
    renderAddressTitle?: boolean;
    qa?: string;
}

export function AddressFormatter({ defaultAddress, addressStructured, renderAddressTitle, qa, ...rest }: Props) {
    const logic = useLogic();
    const [addressData, setAddressData] = React.useState({
        lang: logic.settings().getProp('lang'),
        addressIdentification: logic.settings().getProp('addressIdentification'),
        client: logic.auth().client()
    });

    React.useEffect(() => {
        logic.settings().onChange(prop => {
            if (prop.lang || prop.addressIdentification) {
                if (
                    prop.lang !== addressData.lang ||
                    prop.addressIdentification !== addressData.addressIdentification
                ) {
                    setAddressData({
                        ...addressData,
                        ...prop
                    });
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    function getAddressString() {
        return (
            toAddressString(
                addressData.lang,
                addressData.client!,
                addressStructured,
                addressData.addressIdentification ?? AddressIdentification.Address,
                defaultAddress
            ) ?? ''
        );
    }

    return (
        <span data-qa={qa} title={renderAddressTitle ? getAddressString() : undefined} {...rest}>
            {getAddressString()}
        </span>
    );
}
