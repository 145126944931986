import cn from 'classnames';
import * as icons from 'resources/images/dispatcher-board';
import { Tooltip } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Availability, LoadingInformation } from 'generated/dff-api';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT_SHORT } from 'domain-constants';

interface Props {
    places: LoadingInformation[];
}

export default function DFFProposalDetailPlaces(props: Props) {
    const { t } = useTranslation();

    const loadingAvailableLabel = (availability: Availability) => {
        const availableFrom = moment(availability.availableFrom);
        const availableUntil = moment(availability.availableUntil);

        return `${availableFrom.format(DATE_FORMAT)} ${availableFrom.format(
            TIME_FORMAT_SHORT
        )} - ${availableUntil.format(TIME_FORMAT_SHORT)}`;
    };

    return (
        <div className="dff-proposal-detail-places">
            <ul>
                {props.places.map((place, index, places) => (
                    <li
                        key={index}
                        className={cn({ 'dff-proposal-detail-places-item-last': index + 1 === places.length })}
                    >
                        <div className="dff-proposal-detail-places-item dff-proposal-detail-places-item-loading">
                            <img
                                className="dff-proposal-detail-places-item-icon"
                                src={index ? icons.unload : icons.load}
                                alt="loading"
                            />

                            <div className="dff-proposal-detail-places-item-location">
                                <div className="dff-proposal-detail-places-item-location-address">
                                    {place.location.address.street}
                                </div>
                                <div className="dff-proposal-detail-places-item-location-date">
                                    {loadingAvailableLabel(place.loadingWindow)}
                                </div>
                            </div>

                            <Tooltip title={place.note}>
                                <img className="dff-proposal-detail-places-item-note" src={icons.note} alt="note" />
                            </Tooltip>

                            <div className="dff-proposal-detail-places-country">
                                <span
                                    className={`flag-icon flag-icon-squared flag-icon-${place.location.address.countryCode.toLowerCase()}`}
                                />
                            </div>
                        </div>

                        {place.loads.map((load, index) => (
                            <div
                                key={index}
                                className="dff-proposal-detail-places-item dff-proposal-detail-places-item-load"
                            >
                                <img
                                    className="dff-proposal-detail-places-item-icon"
                                    src={icons.packet}
                                    alt="loading"
                                />

                                <div className="dff-proposal-detail-places-item-load">
                                    <div className="dff-proposal-detail-places-item-load-name">{load.description}</div>
                                    <div className="dff-proposal-detail-places-item-load-goods">
                                        {`${load.loadType} (${load.count} pcs)`}
                                    </div>
                                </div>

                                <Tooltip title={load.description}>
                                    <img className="dff-proposal-detail-places-item-note" src={icons.note} alt="note" />
                                </Tooltip>

                                <div className="dff-proposal-detail-places-item-load-weight">
                                    <div className="dff-proposal-detail-places-item-load-weight-label">
                                        {t('common.weight')}
                                    </div>
                                    <div className="dff-proposal-detail-places-item-load-weight-value">
                                        {`${load.weight.value} ${load.weight.unit}`}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </li>
                ))}
            </ul>
        </div>
    );
}
