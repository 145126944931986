import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { Conf } from 'conf';
import { SuperAdminImpersionationToken } from './superadmin';
import { Logic } from './logic';
import { EWClient } from '../generated/main-data-api';
import { PartnerCompanyModel } from './partner/logic/partner-partners';
import { GetUserQuery, GetUserQueryVariables, GetUserDocument } from 'generated/graphql';
import { toUserModel, UserModel } from './user/users';
import { ReadOnlyClient } from 'generated/new-main';
import { RouteNames } from 'App';

export type AuthConf = Conf['auth'];

export interface User {
    id: string;
    name: string;
    email: string;
    lang: string;
    roles: Role[];
}

export enum Role {
    D_R = 'D_R',
    D_W = 'D_W',
    D_IE = 'D_IE',
    USR_R = 'USR_R',
    USR_W = 'USR_W',
    USR_IE = 'USR_IE',
    V_R = 'V_R',
    V_W = 'V_W',
    V_IE = 'V_IE',
    TC_R = 'TC_R',
    TC_W = 'TC_W',
    TC_IE = 'TC_IE',
    OT_R = 'OT_R',
    OT_W = 'OT_W',
    OT_IE = 'OT_IE',
    DM_R = 'DM_R',
    DM_RD = 'DM_RD',
    CL_R = 'CL_R',
    CL_W = 'CL_W',
    CL_IE = 'CL_IE',
    CP_R = 'CP_R',
    TG_R = 'TG_R',
    TG_W = 'TG_W',
    LM_R = 'LM_R',
    FST_R = 'FST_R',
    EX_R = 'EX_R',
    EX_W = 'EX_W',
    EX_IE = 'EX_IE',
    OP_R = 'OP_R',
    TRVS = 'TRVS',
    DSH_CS = 'DSH_CS',
    OPOI_R = 'OPOI_R',
    TCO = 'TCO',
    PLN_R = 'PLN_R',
    PLN_R_2 = 'PLN_R_2',
    PUESC = 'PUESC',
    ETA_R = 'ETA_R',
    VP_R = 'VP_R',
    VR_W = 'VR_W',
    BRD_R = 'BRD_R',
    ROW_R = 'ROW_R',
    POI_R = 'POI_R',
    POI_W = 'POI_W',
    POI_IE = 'POI_IE',
    LT_R = 'LT_R',
    JA_R = 'JA_R',
    JA_W = 'JA_W',
    JAE_R = 'JAE_R',
    BC_R = 'BC_R',
    BC_W = 'BC_W',
    BC_IE = 'BC_IE',
    RID_R = 'RID_R',
    LMC_R = 'LMC_R',
    LTC_R = 'LTC_R',
    JAEE_R = 'JAEE_R',
    FC_R = 'FC_R',
    FC_W = 'FC_W',
    AEI_R = 'AEI_R',
    AER_R = 'AER_R',
    DRE_R = 'DRE_R',
    TRD_R = 'TRD_R',
    CRD_R = 'CRD_R',
    CRD_W = 'CRD_W',
    LMM_R = 'LMM_R',
    LMT_R = 'LMT_R',
    IA_R = 'IA_R',
    IA_I = 'IA_I',
    JAA = 'JAA',
    CA_R = 'CA_R',
    DBH_R = 'DBH_R',
    DBHD_R = 'DBHD_R',
    DBH_IC_JA = 'DBH_IC_JA',
    MTN_R = 'MTN_R',
    MTN_N = 'MTN_N',
    DFF_R = 'DFF_R',
    PM_R = 'PM_R',
    GEOA = 'GEOA',
    CAC_R = 'CAC_R',
    OAC_R = 'OAC_R',
    SR_E = 'SR_E',
    DIT_R = 'DIT_R',
    DIT_W = 'DIT_W',
    TLC_R = 'TLC_R',
    TID_R = 'TID_R',
    CLD_R = 'CLD_R',
    CLD_W = 'CLD_W',
    DWL = 'DWL'
}

export class Auth {
    keycloak: Keycloak.KeycloakInstance;
    private _updateTokenInterval?: NodeJS.Timeout;
    private _client?: EWClient;
    private _newEWClient?: ReadOnlyClient;
    private _user?: UserModel;
    private _newClient?: PartnerCompanyModel;
    private _reload?: boolean;

    constructor(private _conf: AuthConf, private _logic: Logic) {
        const initParams: any = {
            ...this._conf.keycloak
        };
        if (localStorage.identity) {
            const identity = JSON.parse(localStorage.identity);
            // initParams.flow = "implicit";
            initParams.accessToken = identity.accessToken;
            initParams.refreshToken = identity.refreshToken;
        }
        this.keycloak = new Keycloak(initParams);
        this._reload = false;
    }

    async init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const initParams: KeycloakInitOptions = {
                onLoad: 'login-required'
            };
            if (localStorage.identity) {
                const identity = JSON.parse(localStorage.identity);
                console.log('identity:', identity);
                if (window.location.toString().includes('?noRedirect=true')) {
                    window.localStorage.setItem('sid', identity.sessionId);
                }
                initParams.onLoad = 'check-sso';
                // initParams.flow = "hybrid";
                // initParams.idToken = identity.accessToken;
                initParams.token = identity.accessToken;
                initParams.refreshToken = identity.refreshToken;
                initParams.checkLoginIframe = false;
            }

            this.keycloak
                ?.init(initParams)
                .then(authenticated => {
                    if (window.location.toString().includes('?noRedirect=true')) {
                        window.localStorage.setItem('sid', this.keycloak.sessionId ?? '');
                    }
                    resolve(authenticated);
                })
                .catch(e => {
                    console.error('Error authentication keycloak token', e);
                    return reject(e);
                });

            if (!this._updateTokenInterval) {
                this._updateTokenInterval = setInterval(() => {
                    this.updateToken();
                }, 60e3);
            }
        });
    }

    async updateToken(minValidity?: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.keycloak
                ?.updateToken(minValidity || 30)
                .then(updated => {
                    return resolve(updated ? this.keycloak.idToken ?? 'xxx' : 'xxx');
                })
                .catch(e => {
                    console.error('Error update keycloak token', e);
                    return reject(e);
                });
        });
    }

    token(): string | undefined {
        return this.keycloak.token;
    }

    user(): User {
        const data = this.keycloak.tokenParsed || {};
        return {
            id: data[this._conf.tokenUserIdKey],
            name: data[this._conf.tokenUserNameKey],
            email: data[this._conf.tokenUserEmailKey],
            lang: this._conf.tokenLocaleKey
                ? this.langOverlap(data[this._conf.tokenLocaleKey])
                : data['locale']
                ? this.langOverlap(data['locale'])
                : 'en',
            roles: this.roles()
        };
    }

    roles(ignoreDemo: boolean = false): Role[] {
        if (!ignoreDemo && this._logic.demo().isActive) {
            return this._logic.conf.settings.demoMode.roles;
        }

        const token = this.keycloak.tokenParsed;
        const perms = token?.['binaryRights'] || '';
        // // console.log('perms', perms);
        const roles = deserializePermissions(perms);
        // const roles = Object.keys(Role)
        //     .filter(v => isNaN(Number(v)))
        //     .map(v => Role[v]);
        // return [...roles.filter(r => ![Role.AER_R].includes(r)), Role.MTN_R];
        return roles;
    }

    superadmin(): boolean {
        return (
            this.keycloak?.tokenParsed &&
            (this.keycloak?.tokenParsed as any)?.roles &&
            ((this.keycloak?.tokenParsed as any)?.roles as string[]).includes('ROLE_OPERATOR')
        );
    }

    impersonator(): boolean {
        return this.keycloak?.tokenParsed &&
            (this.keycloak?.tokenParsed as any)?.impersonator &&
            (this.keycloak?.tokenParsed as any)?.impersonator?.id
            ? true
            : false;
    }

    userProfile(): Promise<any> {
        return new Promise<any>(() => this.keycloak?.loadUserProfile());
    }

    async logout(): Promise<void> {
        const newHref = window.location.href;
        const noRedirectParam = 'noRedirect=true';
        await window.history.replaceState(
            {},
            '',
            window.location.href.replace(
                `${newHref.split(noRedirectParam).length > 1 ? '?' : ''}${noRedirectParam}`,
                ''
            )
        );
        localStorage.removeItem('identity');
        this.keycloak?.logout();
    }

    newEWClient() {
        return this._newEWClient;
    }

    client() {
        return this._client;
    }

    newClient() {
        return this._newClient;
    }

    newUser() {
        return this._user;
    }

    async loadClient(): Promise<any> {
        try {
            const res = await this._logic.api().clientApi.getByMe();
            const sidFromLocalStorage = window.localStorage.getItem('sid');
            this._client = res.data;

            if (this._logic.demo().isActive && this._client) {
                this._client.name = this._logic.demo().data.client.name;
            }

            if (this._client?.id) {
                this._newEWClient = await this._logic.api().newClientApi.clientRead({ id: this._client?.id });
                if (this._newEWClient) {
                    this.loadNewClient(this._newEWClient);
                }
            }
            if (this._reload) {
                window.location.reload();
            }
            if (this._newEWClient?.ewOffice && sidFromLocalStorage !== this.keycloak.sessionId) {
                const ewOfficeUrls = {
                    'localhost:3000': 'https://dfo-ppe.wag-test.local',
                    'telematics-ppe.eurowag.com': 'https://dfo-ppe.wag-test.local',
                    'telematics-reg.eurowag.com': 'https://dfo-reg.wag-test.local',
                    'telematics-dev.eurowag.com': 'https://dfo-dev.wag-test.local',
                    'telematics.eurowag.com': 'https://office.eurowag.com'
                };
                const url = ewOfficeUrls[window.location.host];
                if (window.location.hash.includes(RouteNames.STATISTICS_MAINTENANCE)) {
                    window.open(url + '/telematics' + window.location.hash.replace('#/statistics', '/fleet'), '_self');
                } else {
                    window.open(url, '_self');
                }
            }
        } catch (err) {
            this._reload = true;
            setTimeout(() => this.loadClient(), Math.random() * 3001); // random value to 0 - 3s
            console.error('Load client err:', err);
            throw err;
        }
    }

    async loadUser(): Promise<UserModel> {
        try {
            const res = await this._logic.apollo().query<GetUserQuery, GetUserQueryVariables>({
                query: GetUserDocument,
                fetchPolicy: 'no-cache',
                variables: {}
            });

            this._user = toUserModel(res.data?.get_User ?? {});
            return this._user;
        } catch (err) {
            setTimeout(() => this.loadUser(), Math.random() * 3001); // random value to 0 - 3s
            console.error('Get user err', err);
            throw err;
        }
    }

    async loadNewClient(client?: ReadOnlyClient): Promise<PartnerCompanyModel> {
        try {
            if (client) {
                this._newClient = this._logic.partner().companies().toCompany(client);
            } else {
                this._newClient = await this._logic.partner().companies()?.getCompany(this.client()?.id);
            }
            return this._newClient;
        } catch (err) {
            console.error('Load new client err:', err);
            throw err;
        }
    }

    impersonate(tokenData: SuperAdminImpersionationToken): void {
        localStorage.identity = JSON.stringify({
            accessToken: tokenData.access_token,
            refreshToken: tokenData.refresh_token
        });
        this.keycloak?.logout();
        // const payload = new URLSearchParams();
        // payload.append(
        //     'grant_type',
        //     'urn:ietf:params:oauth:grant-type:token-exchange'
        // );
        // payload.append('client_id', 'telematics-web');
        // // payload.append('client_id', 'admin-cli');
        // payload.append('requested_subject', 'xuderman');
        // payload.append('subject_token', this.token() || '');
        // const url =
        //     this._conf.keycloak.url +
        //     '/realms/eurowag/protocol/openid-connect/token';
        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         // 'Access-Control-Allow-Headers': '*'
        //         // Authorization: 'Bearer ' + tokenData.access_token
        //     },
        //     credentials: 'include',
        //     body: payload
        // })
        //     .then(res => {
        //         return res.json();
        //     })
        //     .then(json => {
        //         console.log(json);
        //         // this.login(json.refresh_token, 'xuderman');
        //         localStorage.identity = JSON.stringify({
        //             accessToken: json.access_token,
        //             refreshToken: json.refresh_token
        //         });
        //         this.keycloak
        //         .logout();
        //         // return json;
        //         // })
        //         // .then(json => {
        //         //     return new Promise<boolean>((resolve, reject) => {
        //         //         this.keycloak = Keycloak({
        //         //             ...this._conf.keycloak
        //         //         });
        //         //         this.keycloak
        //         //             .init({
        //         //                 onLoad: 'login-required',
        //         //                 token: json.access_token,
        //         //                 refreshToken: json.refresh_token
        //         //             })
        //         //             .success(authenticated => resolve(authenticated))
        //         //             .error(e => reject(e));
        //         //     });
        //     });
    }

    login(refreshToken: string) {
        const payload = new URLSearchParams();
        payload.append('client_id', 'telematics-web');
        // payload.append('client_id', 'admin-cli');
        payload.append('grant_type', 'refresh_token');
        payload.append('refresh_token', refreshToken);
        // payload.append('username', username);
        const url = this._conf.keycloak.url + '/realms/eurowag/protocol/openid-connect/token';
        fetch(url, {
            method: 'POST',
            headers: {
                // 'Access-Control-Allow-Headers': '*'
                // Authorization: 'Bearer ' + tokenData.access_token
            },
            credentials: 'include',
            body: payload
        }).then(response => {
            response.json().then(json => {
                localStorage.identity = JSON.stringify({
                    accessToken: json.access_token,
                    refreshToken: json.refresh_token
                });
            });
        });
    }

    langOverlap(lang: string): string {
        switch (lang) {
            case 'ee': {
                return 'et';
            }
            case 'at': {
                return 'de';
            }
            case 'dk': {
                return 'da';
            }
            case 'si': {
                return 'sl';
            }
            case 'sr': {
                return 'en';
            }
            default: {
                return lang;
            }
        }
    }

    async serializeBinaryRights(): Promise<void> {
        try {
            await this._logic.api().binaryRightsApi.binaryRightsSerialize({});
            await this.updateToken(-1);
        } catch (err) {
            console.error('Cannot serialize rights', err);
            throw err;
        }
    }
}

export const permsMask = {
    TLM00: {
        D_R: 0x1,
        D_W: 0x2,
        D_IE: 0x4,
        USR_R: 0x8,
        USR_W: 0x10,
        USR_IE: 0x20,
        V_R: 0x40,
        V_W: 0x80,
        V_IE: 0x100,
        TC_R: 0x200,
        TC_W: 0x400,
        TC_IE: 0x800,
        OT_R: 0x1000,
        OT_W: 0x2000,
        OT_IE: 0x4000,
        DM_R: 0x8000,
        CL_R: 0x10000,
        CL_W: 0x20000,
        CL_IE: 0x40000
    },
    TLM01: {
        CP_R: 0x1,
        TG_R: 0x2,
        TG_W: 0x4,
        LM_R: 0x8,
        FST_R: 0x10,
        EX_R: 0x20,
        EX_W: 0x40,
        EX_IE: 0x80,
        OP_R: 0x100,
        DSH_CS: 0x200,
        TRVS: 0x400,
        OPOI_R: 0x800,
        TCO: 0x1000
    },
    TLM02: {
        PLN_R: 0x1,
        ETA_R: 0x2,
        VP_R: 0x4,
        VR_W: 0x8,
        BRD_R: 0x10,
        ROW_R: 0x20,
        POI_R: 0x40,
        POI_W: 0x80,
        POI_IE: 0x100,
        SR_E: 0x200,
        TLC_R: 0x400,
        PLN_R_2: 0x800,
        PUESC: 0x1000
    },
    TLM03: {
        LT_R: 0x1,
        JA_R: 0x2,
        JA_W: 0x4,
        JAE_R: 0x8,
        BC_R: 0x10,
        BC_W: 0x20,
        BC_IE: 0x40,
        DWL: 0x80
    },
    TLM04: {
        RID_R: 0x1
    },
    TLM05: {
        LMC_R: 0x1,
        LTC_R: 0x2,
        JAEE_R: 0x4,
        FC_R: 0x8,
        FC_W: 0x10
    },
    TLM06: {
        AEI_R: 0x1,
        AER_R: 0x2,
        DRE_R: 0x4
    },
    TLM07: {
        TRD_R: 0x1,
        CRD_R: 0x2,
        CRD_W: 0x4
    },
    TLM08: {
        LMM_R: 0x1,
        LMT_R: 0x2
    },
    TLM09: {
        IA_R: 0x1,
        IA_I: 0x2,
        JAA: 0x4,
        GEOA: 0x8
    },
    TLM10: {
        CA_R: 0x1,
        CAC_R: 0x2,
        OAC_R: 0x4
    },
    TLM12: {
        DBH_R: 0x1,
        DBHD_R: 0x2,
        DBH_IC_JA: 0x4
    },
    TLM13: {
        MTN_R: 0x1,
        MTN_N: 0x2
    },
    TLM14: {
        DFF_R: 0x1
    },
    TLM19: {
        DIT_R: 0x1,
        DIT_W: 0x2
    },
    TLM21: {
        TID_R: 0x1
    },
    TLM23: {
        PM_R: 0x1
    },
    TLM25: {
        DM_RD: 0x1
    },
    TLM26: {
        CLD_R: 0x1,
        CLD_W: 0x2
    }
};

export function deserializePermissions(binaryRights: string): Role[] {
    const deserializedModules = {};
    const roles = [];
    const props = Object.getOwnPropertyNames;
    for (const moduleName of props(permsMask)) {
        deserializedModules[moduleName] = {};
        for (const permissionName of props(permsMask[moduleName])) {
            const regex = new RegExp(moduleName + ':(\\w{1,7})', 'i');
            const permMatch = binaryRights.match(regex);
            const hexperm = permMatch?.[1] ? permMatch[1] : '00';
            const hasPermission = !!(parseInt(hexperm, 16) & permsMask[moduleName][permissionName]);
            if (hasPermission && !!Role[permissionName]) {
                roles.push(Role[permissionName]);
            }
        }
    }
    return roles;
}

export function moduleResolver(roles: string[]): number[] | undefined {
    const modules = new Set<string>();
    roles.forEach(role => {
        const module = Object.keys(permsMask).find(key => role in permsMask[key]);
        if (module) {
            modules.add(module);
        }
    });
    if (modules.size > 0) {
        return [...modules.values()]
            .map(modul => modul.replace(/[^0-9]/g, ''))
            .map(Number)
            .sort();
    }
    return undefined;
}
