import React from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import { ColorResult, SketchPicker } from 'react-color';
import { Input } from 'common/components/Form';
import { WhitelabelSettings } from 'logic/partner/logic/partner-whitelabel';

interface Props extends WithTranslation {
    title: string;
    content: WhitelabelSettings;
    colorKey?: string;
    onValueChange?: (key: string, value: string) => void;
    onColorPickerClick?: (key: string) => void;
    onColorPickerChange?: (key: string) => void;
    group: string[];
}

function WhitelabelCard(props: Props) {
    function onColorClick(key: string) {
        props.onColorPickerClick?.(key);
    }

    function onColorPickerChange(color: ColorResult) {
        props.onColorPickerChange?.(color.hex);
    }

    function onValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        props.onValueChange?.(e.target.name, e.target.value);
    }

    return (
        <Card className="whitelabel-card" title={props.title} bordered={false}>
            {Object.keys(props.content)
                .filter(s => props.group.includes(s))
                .map(s => (
                    <Row align="middle" gutter={[10, 10]} key={s}>
                        <Col span={13}>{s}</Col>
                        <Col span={8}>
                            <Input type="text" name={s} value={props.content[s]} onChange={onValueChange} />
                        </Col>
                        {props.content[s][0] === '#' && (
                            <Col span={3}>
                                <div
                                    className={cn('whitelabel-color', {
                                        selected: props.colorKey && s === props.colorKey
                                    })}
                                    style={{ backgroundColor: props.content[s] }}
                                    onClick={() => onColorClick(s)}
                                />
                                {props.colorKey && s === props.colorKey && (
                                    <div
                                        className="sketch-picker-popover"
                                        style={{
                                            top: '50px',
                                            left: '-231px'
                                        }}
                                    >
                                        <SketchPicker
                                            color={props.content[s]}
                                            width="250px"
                                            onChangeComplete={onColorPickerChange}
                                        />
                                    </div>
                                )}
                            </Col>
                        )}
                    </Row>
                ))}
        </Card>
    );
}

export default withTranslation()(WhitelabelCard);
