import Menu from 'common/components/Menu';
import { useTranslation } from 'react-i18next';
import { DocsItem, DocsUserGuide } from 'modules/docs/DocsModule';

interface Props {
    docs: DocsItem[];
    module: DocsUserGuide;
    onUserGuideClick?: (module: DocsUserGuide) => void;
}

export default function DocsMenu(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="docs-menu">
            <h1>{t('common.helper')}</h1>

            <Menu mode="inline" openKeys={props.module ? [props.module as string] : []} inlineIndent={0}>
                {props.docs.map(d => (
                    <Menu.SubMenu
                        key={d.item}
                        onTitleClick={() => props.onUserGuideClick?.(d.item)}
                        title={t(`UserRights.modules.${d.item}.name`)}
                    >
                        {d.subitems.map(s => (
                            <Menu.Item key={s}>{t(`common.${s}`)}</Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ))}
            </Menu>
        </div>
    );
}
