/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringDeviceMine
 */
export interface MonitoringDeviceMine {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDeviceMine
     */
    readonly modules?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitoringDeviceMine
     */
    serialNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringDeviceMine
     */
    readonly limitedData?: boolean;
}

export function MonitoringDeviceMineFromJSON(json: any): MonitoringDeviceMine {
    return MonitoringDeviceMineFromJSONTyped(json, false);
}

export function MonitoringDeviceMineFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringDeviceMine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modules': !exists(json, 'modules') ? undefined : json['modules'],
        'serialNumber': json['serial_number'],
        'limitedData': !exists(json, 'limited_data') ? undefined : json['limited_data'],
    };
}

export function MonitoringDeviceMineToJSON(value?: MonitoringDeviceMine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serialNumber,
    };
}


