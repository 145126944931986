import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import { Table, Button } from 'common/components';
import Badge from 'common/components/Badge';
import { PaginationParams } from 'common/model/pagination';
import * as CommonIcons from 'resources/images/common';
import qa from 'qa-selectors';
import { Col, Row } from 'antd';
import { ManualRate } from 'generated/backend-api';
import Popover from 'common/components/Popover';
import { TABLE_SCROLL_Y_SMALL } from 'domain-constants';

type DiemsDetailTableDataItem = {
    id: string;
    originalDateFrom: string;
    originalDateTo: string;
    dateFrom: string;
    dateTo: string;
    domesticTravel: string;
    abroadTravel: string;
    powStay: string;
    country: string;
    routeFrom: string;
    routeTo: string;
    dailyRate: string;
    pocketMoney: string;
    totalRate: string;
    shortening: string;
    manualRate?: ManualRateTableData;
    appliedDiemsCountries?: string[];
};

export interface DiemsDetailTableData extends DiemsDetailTableDataItem {
    // isMain: the most time spend in current country. from this data is  sum counted
    children: ({ isMain: boolean; isChild: true } & Omit<
        DiemsDetailTableDataItem,
        'originalDateFrom' | 'originalDateTo'
    >)[];
}

export interface ManualRateTableData extends ManualRate {
    byUserName: string;
    byUserSurname: string;
}

interface Props {
    loading: boolean;
    data: DiemsDetailTableData[];
    isPolishDiems: boolean;
    isSlovakDiems: boolean;
    demoMode?: boolean;
    selectedId?: string;
    onPaginationChange: (pagination: PaginationParams) => void;
    onRowClick?: (id: string) => void;
    onEditDetailClick?: (id: string) => void;
    onRateEditClick?: (id: string) => void;
}
export default function AllowanceDetailTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="allowance-driver-detail-table">
            {' '}
            <Table<DiemsDetailTableData>
                columns={getColumns()}
                loading={props.loading}
                scroll={{ y: TABLE_SCROLL_Y_SMALL }}
                dataSource={props.data.map(d => ({
                    ...d,
                    key: `parent-${d.id}`,
                    children: d.children?.map((ch, i) => ({ ...ch, key: `child-${i}-${ch.id}` }))
                }))}
                onRow={record => {
                    return {
                        onClick: () => props.onRowClick?.(record.id),
                        className: cn({
                            'ant-table-row-opened': props.selectedId === record.id
                        })
                    };
                }}
            />
        </div>
    );

    function getColumns(): ColumnsType<DiemsDetailTableData> {
        const columns: ColumnsType<DiemsDetailTableData> = [
            {
                title: t('Allowance.detailTable.dateFrom'),
                key: 'dateFrom',
                dataIndex: 'dateFrom',
                width: 15,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldFrom}>{moment(data).format('L LT')}</span>
            },
            {
                title: t('Allowance.detailTable.dateTo'),
                key: 'dateTo',
                dataIndex: 'dateTo',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldTo}>{moment(data).format('L LT')}</span>
            },
            {
                title: t('Allowance.detailTable.stayLocalTime'),
                key: 'domesticTravel',
                dataIndex: 'domesticTravel',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldDomesticTravel}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.stayAbroadTime'),
                key: 'abroadTravel',
                dataIndex: 'abroadTravel',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldAbroadTravel}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.powStayTime'),
                key: 'powStay',
                dataIndex: 'powStay',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldPlaceOfWorkTime}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.country'),
                key: 'country',
                dataIndex: 'country',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldCountry}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.rate'),
                key: 'dailyRate',
                dataIndex: 'dailyRate',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldDailyRate}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.supply'),
                key: 'pocketMoney',
                dataIndex: 'pocketMoney',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldPocketMoney}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.shortening'),
                key: 'shortening',
                dataIndex: 'shortening',
                width: 10,
                align: 'center',
                render: data => <span data-qa={qa.allowances.detail.fieldMealShortage}>{data}</span>
            },
            {
                title: t('Allowance.detailTable.totalRate'),
                key: 'totalRate',
                dataIndex: 'totalRate',
                width: 15,
                align: 'center',
                render: (data, record) => {
                    const editButton = props.isSlovakDiems && (
                        <span className="manual-rate-edit-btn-wrapper">
                            <Button
                                type="text"
                                size="small"
                                disabled={props.demoMode}
                                className="manual-rate-edit-btn"
                                onClick={() => props.onRateEditClick?.(record.id)}
                                icon={
                                    <img
                                        className={cn({
                                            'selected-img': props.selectedId === record.id
                                        })}
                                        alt="edit"
                                        src={CommonIcons.editPencil}
                                    />
                                }
                            />
                        </span>
                    );
                    return record && (record.manualRate?.domestic || record.manualRate?.foreign) ? (
                        <Popover
                            overlayClassName="allowance-driver-detail-table-popover"
                            content={
                                <>
                                    <div>
                                        {t('common.editedBy')} {record.manualRate.byUserName}
                                        {record.manualRate.byUserSurname}
                                    </div>
                                    <div>{moment(record.manualRate.byUserAt).format('L LT')}</div>
                                </>
                            }
                        >
                            <Row justify="center">
                                <Col>{editButton}</Col>
                                <Col>
                                    <span>
                                        {editButton}
                                        <span data-qa={qa.allowances.detail.fieldDailyTotalRate}>{data}</span>
                                    </span>
                                </Col>
                                <Col>
                                    <Badge count=" " size="small" className="badge-allowance" />
                                </Col>
                            </Row>
                        </Popover>
                    ) : (
                        data && (
                            <span>
                                {editButton}
                                <span data-qa={qa.allowances.detail.fieldDailyTotalRate}>{data}</span>
                            </span>
                        )
                    );
                }
            },
            {
                title: '',
                key: 'action',
                width: 6,
                align: 'center',
                render: record => {
                    return (
                        <>
                            {!record.isChild && (
                                <Button
                                    className="edit-btn"
                                    type="link"
                                    disabled={props.demoMode}
                                    onClick={() => props.onEditDetailClick?.(record.id)}
                                    qa={qa.allowances.detail.btnEdit}
                                    icon={
                                        <img
                                            className={cn({
                                                'selected-img': props.selectedId === record.id
                                            })}
                                            alt="edit"
                                            src={CommonIcons.editPencilFill}
                                        />
                                    }
                                />
                            )}
                        </>
                    );
                }
            }
        ];

        if (props.isPolishDiems) {
            return columns.filter(c => c.key !== 'pocketMoney');
        }
        return columns;
    }
}
