import { useLogic } from 'App';
import { SettingsPersist } from 'logic/settings';
import * as React from 'react';

type Props = {
    [key in keyof SettingsPersist]?: boolean;
};
export function SettingsRerenderer(props: React.PropsWithChildren<Props>) {
    const [settings, setSettings] = React.useState<Partial<SettingsPersist>>({});
    const logic = useLogic();
    React.useEffect(() => {
        logic.settings().onChange(prop => {
            if (
                !Object.keys(props).some(key => {
                    return props[key];
                })
            ) {
                setSettings(prop);
            } else {
                const expectedChangeStateKeys = Object.keys(props).filter(k => props[k]);
                expectedChangeStateKeys.forEach(key => {
                    if (settings[key] !== prop[key]) {
                        setSettings(prop[key]);
                    }
                });
            }
        });
        // eslint-disable-next-line
    }, []);

    return <>{props.children}</>;
}
