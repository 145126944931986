import moment from 'moment';
import { users } from './users';
import { ReadOnlyUserToken, ReadOnlyUserTokenTokenTypeEnum } from 'generated/new-main';

const startTime = moment().startOf('year').toDate();

export const tachoCards = [
    {
        id: 71949,
        token: 'CZ 1003301431',
        holder: `${users[0].name} ${users[0].surname}`,
        user: { id: users[0].id, name: users[0].name, surname: users[0].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(496, 'days').toDate(),
        deleted: 0
    },
    {
        id: 72043,
        token: 'CZ 1003399253',
        holder: `${users[1].name} ${users[1].surname}`,
        user: { id: users[1].id, name: users[1].name, surname: users[1].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(563, 'days').toDate(),
        deleted: 0
    },
    {
        id: 72062,
        token: 'CZ 1006645035',
        holder: `${users[2].name} ${users[2].surname}`,
        user: { id: users[2].id, name: users[2].name, surname: users[2].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(741, 'days').toDate(),
        deleted: 0
    },
    {
        id: 72064,
        token: 'CZ 1008932440',
        holder: `${users[3].name} ${users[3].surname}`,
        user: { id: users[3].id, name: users[3].name, surname: users[3].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(674, 'days').toDate(),
        deleted: 0
    },
    {
        id: 72065,
        token: 'CZ 1008933448',
        holder: `${users[4].name} ${users[4].surname}`,
        user: { id: users[4].id, name: users[4].name, surname: users[4].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(160, 'days').toDate(),
        deleted: 0
    },
    {
        id: 72066,
        token: 'CZ 1002934449',
        holder: `${users[5].name} ${users[5].surname}`,
        user: { id: users[5].id, name: users[5].name, surname: users[5].surname },
        tokenType: ReadOnlyUserTokenTokenTypeEnum.DriverCard,
        startTime,
        endTime: moment().add(354, 'days').toDate(),
        deleted: 0
    }
] as ReadOnlyUserToken[];
