import React from 'react';
import moment, { Duration } from 'moment';
import cn from 'classnames';

interface Props {
    /**
     * In seconds
     */
    max: number;
    /**
     * In seconds
     */
    duration: number;
    overlapMax?: boolean;
    qa?: string;
}

function AlarmDuration(props: Props) {
    const durationHelper: number =
        props.duration >= props.max && !props.overlapMax ? props.max : props.duration >= 0 ? props.duration : 0;
    const moreAs80Percent: boolean = props.max * 0.8 < durationHelper;
    const moreAs60Percent: boolean = props.max * 0.6 < durationHelper;
    const momentDuration: Duration = moment.duration(durationHelper, 'seconds');
    const hours: number = Math.floor(momentDuration.asHours());
    const minutes: number = Math.floor(momentDuration.asMinutes() - hours * 60);
    const momentDurationString: string = `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}`;
    return (
        <span
            className={cn('alarm-remains t-bold', {
                't-text-warning': moreAs60Percent && !moreAs80Percent,
                't-text-danger': moreAs80Percent
            })}
            data-qa={props.qa}
        >
            {moreAs80Percent && <i className="fa fa-warning" />} {momentDurationString}
        </span>
    );
}

export default AlarmDuration;
