/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AvgPricePerKmOfFleetType,
    AvgPricePerKmOfFleetTypeFromJSON,
    AvgPricePerKmOfFleetTypeToJSON,
    Cost,
    CostFromJSON,
    CostToJSON,
    CostCalculationResult,
    CostCalculationResultFromJSON,
    CostCalculationResultToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PricePerKmResult,
    PricePerKmResultFromJSON,
    PricePerKmResultToJSON,
} from '../models';

export interface ComputeCostsV1CostsComputeCostsPostRequest {
    dateFrom?: Date;
    dateTo?: Date;
}

export interface PricePerKmV1CostsPricePerKmGetRequest {
    onlyPrices?: boolean;
    mobjectIds?: Array<number>;
}

export interface RecomputeV1CostsRecomputePostRequest {
    clientIds?: Array<number>;
    dateFrom?: Date;
    dateTo?: Date;
    dryRun?: boolean;
}

/**
 * no description
 */
export class CostsApi extends runtime.BaseAPI {

    /**
     * Compute price per km for client and his vehicles
     */
    async computeCostsV1CostsComputeCostsPostRaw(requestParameters: ComputeCostsV1CostsComputeCostsPostRequest): Promise<runtime.ApiResponse<CostCalculationResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/compute_costs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CostCalculationResultFromJSON(jsonValue));
    }

    /**
     * Compute price per km for client and his vehicles
     */
    async computeCostsV1CostsComputeCostsPost(requestParameters: ComputeCostsV1CostsComputeCostsPostRequest): Promise<CostCalculationResult> {
        const response = await this.computeCostsV1CostsComputeCostsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Compute system wide avg price per km in EUR + compute for every fleet type
     */
    async computeMarketAvgV1CostsMarketAvgComputePostRaw(): Promise<runtime.ApiResponse<Array<Cost>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/market_avg/compute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CostFromJSON));
    }

    /**
     * Compute system wide avg price per km in EUR + compute for every fleet type
     */
    async computeMarketAvgV1CostsMarketAvgComputePost(): Promise<Array<Cost>> {
        const response = await this.computeMarketAvgV1CostsMarketAvgComputePostRaw();
        return await response.value();
    }

    /**
     * Get latest system wide avg price per km in EUR of every known fleet type
     */
    async getMarketAvgOfFleetTypesV1CostsMarketAvgOfFleetTypesGetRaw(): Promise<runtime.ApiResponse<Array<Cost>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/market_avg/of_fleet_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CostFromJSON));
    }

    /**
     * Get latest system wide avg price per km in EUR of every known fleet type
     */
    async getMarketAvgOfFleetTypesV1CostsMarketAvgOfFleetTypesGet(): Promise<Array<Cost>> {
        const response = await this.getMarketAvgOfFleetTypesV1CostsMarketAvgOfFleetTypesGetRaw();
        return await response.value();
    }

    /**
     * Market average cost from default profiles
     */
    async marketAverageCostV1CostsMarketAverageCostGetRaw(): Promise<runtime.ApiResponse<Array<AvgPricePerKmOfFleetType>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/market_average_cost`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AvgPricePerKmOfFleetTypeFromJSON));
    }

    /**
     * Market average cost from default profiles
     */
    async marketAverageCostV1CostsMarketAverageCostGet(): Promise<Array<AvgPricePerKmOfFleetType>> {
        const response = await this.marketAverageCostV1CostsMarketAverageCostGetRaw();
        return await response.value();
    }

    /**
     * Price/km for client and requested vehicles.
     */
    async pricePerKmV1CostsPricePerKmGetRaw(requestParameters: PricePerKmV1CostsPricePerKmGetRequest): Promise<runtime.ApiResponse<PricePerKmResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onlyPrices !== undefined) {
            queryParameters['only_prices'] = requestParameters.onlyPrices;
        }

        if (requestParameters.mobjectIds) {
            queryParameters['mobject_ids'] = requestParameters.mobjectIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/price_per_km`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PricePerKmResultFromJSON(jsonValue));
    }

    /**
     * Price/km for client and requested vehicles.
     */
    async pricePerKmV1CostsPricePerKmGet(requestParameters: PricePerKmV1CostsPricePerKmGetRequest): Promise<PricePerKmResult> {
        const response = await this.pricePerKmV1CostsPricePerKmGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Recompute actual values for specific clients - only ROLE_OPRATOR + ROLE_ADMIN
     */
    async recomputeV1CostsRecomputePostRaw(requestParameters: RecomputeV1CostsRecomputePostRequest): Promise<runtime.ApiResponse<Array<CostCalculationResult>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientIds) {
            queryParameters['client_ids'] = requestParameters.clientIds;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.dryRun !== undefined) {
            queryParameters['dry_run'] = requestParameters.dryRun;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/recompute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CostCalculationResultFromJSON));
    }

    /**
     * Recompute actual values for specific clients - only ROLE_OPRATOR + ROLE_ADMIN
     */
    async recomputeV1CostsRecomputePost(requestParameters: RecomputeV1CostsRecomputePostRequest): Promise<Array<CostCalculationResult>> {
        const response = await this.recomputeV1CostsRecomputePostRaw(requestParameters);
        return await response.value();
    }

}
