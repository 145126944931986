/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressStructured
 */
export interface AddressStructured {
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    lang: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    town: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    route: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AddressStructured
     */
    postalCode: string;
}

export function AddressStructuredFromJSON(json: any): AddressStructured {
    return AddressStructuredFromJSONTyped(json, false);
}

export function AddressStructuredFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressStructured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lang': json['lang'],
        'address': json['address'],
        'countryCode': json['countryCode'],
        'country': json['country'],
        'town': json['town'],
        'route': json['route'],
        'streetAddress': json['street_address'],
        'postalCode': json['postalCode'],
    };
}

export function AddressStructuredToJSON(value?: AddressStructured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lang': value.lang,
        'address': value.address,
        'countryCode': value.countryCode,
        'country': value.country,
        'town': value.town,
        'route': value.route,
        'street_address': value.streetAddress,
        'postalCode': value.postalCode,
    };
}


