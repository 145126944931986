import React, { Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, StringValidator, NumberValidator } from 'validators';
import moment, { Moment } from 'moment';
import { CargoExpense } from 'logic/statistics/statistics-expenses';
import AutoComplete from 'common/components/AutoComplete';
import { L } from 'domain-constants';
import { ExpenseType } from 'generated/graphql';
import { DatePicker } from 'common/components';

const typeValidator = new StringValidator(
    {
        required: true
    },
    {
        required: 'required'
    }
);

const supplierValidator = new StringValidator(
    {
        required: true
    },
    {
        required: 'not_from_list'
    }
);

const quantityValidator = new NumberValidator(
    {
        required: true
    },
    {
        required: 'required'
    }
);

const priceTotalValidator = new NumberValidator(
    {
        required: true
    },
    {
        required: 'required'
    }
);

interface CargoFormValidator {
    type: string;
    supplier: string;
    quantity: string;
    priceTotal: string;
}

const formValidator = new FormValidator<CargoFormValidator>()
    .addValidator('type', typeValidator)
    .addValidator('supplier', supplierValidator)
    .addValidator('quantity', quantityValidator)
    .addValidator('priceTotal', priceTotalValidator);

interface Props extends WithTranslation {
    vehicle: {
        label: string;
        code: number;
    };
    suppliers: {
        label: string;
        code: string;
    }[];
    date: string;
    onSupplierTextChange?: (value: string) => void;
    onConfirm?: (cargo: CargoExpense) => void;
    onCancel?: () => void;
}

interface State {
    vehicleId: number;
    date: string;
    type: ExpenseType;
    supplier: string;
    quantity: string;
    priceTotal: string;
    dateErr: string;
    typeErr: string;
    supplierErr: string;
    quantityErr: string;
    priceTotalErr: string;
}

class TrackingCargo extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehicleId: this.props.vehicle.code,
            date: this.props.date,
            type: '' as ExpenseType,
            supplier: '',
            quantity: '',
            priceTotal: '',
            dateErr: '',
            typeErr: '',
            supplierErr: '',
            quantityErr: '',
            priceTotalErr: ''
        };
    }
    render() {
        const { t } = this.props;

        return (
            <div className="tracking-cargo t-small t-default t-margin t-border">
                <div className="t-padding">
                    <div className="t-row t-center t-uppercase t-bold">{t('ExpensesCargo.createCargo')}</div>
                    <p>
                        <label>{t('common.vehicle')}</label>
                        <input className="t-input" name="vehicle" value={this.props.vehicle.label} readOnly />
                    </p>
                    <p className="t-row">
                        <div className="expenses-cargo-datetime t-col">
                            <label>{t('ExpensesCargo.date')}</label>
                            <DatePicker.DateTimePicker
                                defaultValue={moment(this.state.date)}
                                onChange={date => this.onDateChange(date as Moment)}
                                trigger={
                                    <div className="t-input">{moment(this.state.date).format('L LT') as string}</div>
                                }
                            />
                        </div>
                        <div className="expenses-cargo-today t-rest">
                            {moment(this.state.date).format('L') === moment().format('L') ? (
                                '( ' + t('common.today') + ' )'
                            ) : (
                                <input type="checkbox" className="t-check t-right" checked={true} readOnly />
                            )}

                            <span className="t-text-danger">
                                {this.state.dateErr && t('validator.' + this.state.dateErr)}
                            </span>
                        </div>
                    </p>
                    <p>
                        <label>{t('ExpensesCargo.type')}</label>
                        <select className="t-select t-default" name="type" onChange={this.onTypeChange}>
                            <option />
                            {Object.keys(ExpenseType).map(e => {
                                return (
                                    <option key={e} value={ExpenseType[e]}>
                                        {t(`ExpensesFilter.${String(ExpenseType[e]).toLowerCase()}`)}
                                    </option>
                                );
                            })}
                        </select>
                        <span className="t-text-danger">
                            {this.state.typeErr && t('validator.' + this.state.typeErr)}
                        </span>
                    </p>
                    <p>
                        <label>{t('ExpensesCargo.supplier')}</label>
                        <AutoComplete
                            text=""
                            options={this.props.suppliers}
                            placeholder={t('ExpensesCargo.typeSupplier')}
                            onOptionSelect={this.onSupplierSelect}
                            onTextChange={this.onSupplierTextChange}
                        />
                        <span className="t-text-danger">
                            {this.state.supplierErr && t('validator.' + this.state.supplierErr)}
                        </span>
                    </p>
                    <p>
                        <label>{t('ExpensesCargo.quantity')}</label>
                        <input
                            className="t-input"
                            type="text"
                            name="quantity"
                            onChange={this.onQuantityChange}
                            style={{ width: '50%' }}
                        />
                        {(this.state.type === ExpenseType.Fuel || this.state.type === ExpenseType.Adblue) && (
                            <span>{L}</span>
                        )}
                        <span className="t-text-danger">
                            {this.state.quantityErr && t('validator.' + this.state.quantityErr)}
                        </span>
                    </p>
                    <p>
                        <label>{t('ExpensesCargo.priceTotal')}</label>
                        <input
                            className="t-input"
                            type="text"
                            name="priceTotal"
                            onChange={this.onPriceTotalChange}
                            style={{ width: '50%' }}
                        />
                        {this.state.type === ExpenseType.TollSticker ? (
                            <span>{t('ExpensesTable.withoutVAT')}</span>
                        ) : (
                            <span>{t('ExpensesTable.withVAT')}</span>
                        )}
                        <span className="t-text-danger">
                            {this.state.priceTotalErr && t('validator.' + this.state.priceTotalErr)}
                        </span>
                    </p>
                </div>
                <div className="t-row t-padding-small">
                    <div className="t-half t-padding-small">
                        <button
                            className="t-btn t-block t-primary-secondary t-ripple"
                            type="button"
                            onClick={this.onCancelClick}
                        >
                            {t('common.cancel')}
                        </button>
                    </div>
                    <div className="t-half t-padding-small">
                        <button
                            className="t-btn t-block t-primary t-ripple"
                            type="submit"
                            onClick={this.onConfirmClick}
                        >
                            {t('common.confirm')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    onDateChange = (value: Moment) => {
        this.setState({
            date: value.toISOString()
        });
    };

    onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        const validateRes = typeValidator.validate(value);
        this.setState({
            type: value as ExpenseType,
            typeErr: validateRes.err as string
        });
    };

    onSupplierTextChange = (value: string) => {
        this.setState({
            supplier: ''
        });
        this.props.onSupplierTextChange?.(value);
    };

    onSupplierSelect = (value: string | null) => {
        const validateRes = supplierValidator.validate(value ?? '');
        this.setState({
            supplier: value ?? '',
            supplierErr: validateRes.err as string
        });
    };

    onQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        const validateRes = quantityValidator.validate(value);
        this.setState({
            quantity: value,
            quantityErr: validateRes.err as string
        });
    };

    onPriceTotalChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        const validateRes = priceTotalValidator.validate(value);
        this.setState({
            priceTotal: value,
            priceTotalErr: validateRes.err as string
        });
    };

    private onConfirmClick = () => {
        const validationRes = formValidator.validate({
            type: this.state.type as string,
            supplier: this.state.supplier,
            quantity: this.state.quantity,
            priceTotal: this.state.priceTotal
        });

        if (validationRes.valid) {
            this.props.onConfirm?.({
                vehicleId: this.state.vehicleId,
                date: this.state.date,
                type: this.state.type,
                supplier: this.state.supplier,
                quantity: this.state.quantity,
                priceTotal: this.state.priceTotal
            });
        } else {
            this.setState({
                typeErr: validationRes.err!.type,
                supplierErr: validationRes.err!.supplier,
                quantityErr: validationRes.err!.quantity,
                priceTotalErr: validationRes.err!.priceTotal
            });
        }
    };

    private onCancelClick = () => {
        this.props.onCancel?.();
    };
}

export default withTranslation()(TrackingCargo);
