import { ReactNode } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';

interface Props<T extends SelectValue = SelectValue> extends SelectProps<T> {
    qa?: string;
    children?: ReactNode;
    innerref?: React.RefObject<RefSelectProps>;
}

function EnhancedSelect<T extends SelectValue = SelectValue>({ qa, ...props }: Props<T>) {
    return (
        <Select ref={props.innerref} data-qa={qa} {...props}>
            {props.children}
        </Select>
    );
}

EnhancedSelect.Option = Select.Option;

export default EnhancedSelect;
