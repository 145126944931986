/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationalCost,
    OperationalCostFromJSON,
    OperationalCostFromJSONTyped,
    OperationalCostToJSON,
} from './';

/**
 * Company Operational Costs
 * @export
 * @interface CompanyOperationalCostProperties
 */
export interface CompanyOperationalCostProperties {
    /**
     * 
     * @type {OperationalCost}
     * @memberof CompanyOperationalCostProperties
     */
    salaries: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof CompanyOperationalCostProperties
     */
    operations: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof CompanyOperationalCostProperties
     */
    crmInsurance: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof CompanyOperationalCostProperties
     */
    other: OperationalCost;
}

export function CompanyOperationalCostPropertiesFromJSON(json: any): CompanyOperationalCostProperties {
    return CompanyOperationalCostPropertiesFromJSONTyped(json, false);
}

export function CompanyOperationalCostPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOperationalCostProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salaries': OperationalCostFromJSON(json['salaries']),
        'operations': OperationalCostFromJSON(json['operations']),
        'crmInsurance': OperationalCostFromJSON(json['crm_insurance']),
        'other': OperationalCostFromJSON(json['other']),
    };
}

export function CompanyOperationalCostPropertiesToJSON(value?: CompanyOperationalCostProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salaries': OperationalCostToJSON(value.salaries),
        'operations': OperationalCostToJSON(value.operations),
        'crm_insurance': OperationalCostToJSON(value.crmInsurance),
        'other': OperationalCostToJSON(value.other),
    };
}


