/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Expenses,
    ExpensesFromJSON,
    ExpensesFromJSONTyped,
    ExpensesToJSON,
    NotificationReceivers,
    NotificationReceiversFromJSON,
    NotificationReceiversFromJSONTyped,
    NotificationReceiversToJSON,
    TaskType,
    TaskTypeFromJSON,
    TaskTypeFromJSONTyped,
    TaskTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface WriteOnlyMaintenanceTask
 */
export interface WriteOnlyMaintenanceTask {
    /**
     * 
     * @type {TaskType}
     * @memberof WriteOnlyMaintenanceTask
     */
    taskType?: TaskType;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMaintenanceTask
     */
    taskTypeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    clientId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    monitoredObject?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    user?: number | null;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyMaintenanceTask
     */
    place?: object;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMaintenanceTask
     */
    contact?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    dueMileageKilometers?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMaintenanceTask
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMaintenanceTask
     */
    repetitionDueDateType?: WriteOnlyMaintenanceTaskRepetitionDueDateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    repetitionDueDateValue?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyMaintenanceTask
     */
    repetitionBindToDueDate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    repetitionDueMileageKilometers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    notificationDueDateDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    notificationDueMileageKilometers?: number | null;
    /**
     * 
     * @type {Array<NotificationReceivers>}
     * @memberof WriteOnlyMaintenanceTask
     */
    notificationRecievers?: Array<NotificationReceivers> | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMaintenanceTask
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMaintenanceTask
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    createdById?: number;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMaintenanceTask
     */
    completionTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    completionMileageKilometers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    completedById?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMaintenanceTask
     */
    state?: WriteOnlyMaintenanceTaskStateEnum;
    /**
     * 
     * @type {Expenses}
     * @memberof WriteOnlyMaintenanceTask
     */
    expenses?: Expenses;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    lastEvaluationMileage?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyMaintenanceTask
     */
    lastEvaluationTimestamp?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMaintenanceTask
     */
    notes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMaintenanceTask
     */
    client?: number | null;
}

export function WriteOnlyMaintenanceTaskFromJSON(json: any): WriteOnlyMaintenanceTask {
    return WriteOnlyMaintenanceTaskFromJSONTyped(json, false);
}

export function WriteOnlyMaintenanceTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMaintenanceTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskType': !exists(json, 'task_type') ? undefined : TaskTypeFromJSON(json['task_type']),
        'taskTypeId': !exists(json, 'task_type_id') ? undefined : json['task_type_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'monitoredObject': !exists(json, 'monitored_object') ? undefined : json['monitored_object'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'dueMileageKilometers': !exists(json, 'due_mileage_kilometers') ? undefined : json['due_mileage_kilometers'],
        'dueDate': !exists(json, 'due_date') ? undefined : (json['due_date'] === null ? null : new Date(json['due_date'])),
        'repetitionDueDateType': !exists(json, 'repetition_due_date_type') ? undefined : json['repetition_due_date_type'],
        'repetitionDueDateValue': !exists(json, 'repetition_due_date_value') ? undefined : json['repetition_due_date_value'],
        'repetitionBindToDueDate': !exists(json, 'repetition_bind_to_due_date') ? undefined : json['repetition_bind_to_due_date'],
        'repetitionDueMileageKilometers': !exists(json, 'repetition_due_mileage_kilometers') ? undefined : json['repetition_due_mileage_kilometers'],
        'notificationDueDateDays': !exists(json, 'notification_due_date_days') ? undefined : json['notification_due_date_days'],
        'notificationDueMileageKilometers': !exists(json, 'notification_due_mileage_kilometers') ? undefined : json['notification_due_mileage_kilometers'],
        'notificationRecievers': !exists(json, 'notification_recievers') ? undefined : (json['notification_recievers'] === null ? null : (json['notification_recievers'] as Array<any>).map(NotificationReceiversFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'completionTime': !exists(json, 'completion_time') ? undefined : (json['completion_time'] === null ? null : new Date(json['completion_time'])),
        'completionMileageKilometers': !exists(json, 'completion_mileage_kilometers') ? undefined : json['completion_mileage_kilometers'],
        'completedById': !exists(json, 'completed_by_id') ? undefined : json['completed_by_id'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'expenses': !exists(json, 'expenses') ? undefined : ExpensesFromJSON(json['expenses']),
        'lastEvaluationMileage': !exists(json, 'last_evaluation_mileage') ? undefined : json['last_evaluation_mileage'],
        'lastEvaluationTimestamp': !exists(json, 'last_evaluation_timestamp') ? undefined : (json['last_evaluation_timestamp'] === null ? null : new Date(json['last_evaluation_timestamp'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function WriteOnlyMaintenanceTaskToJSON(value?: WriteOnlyMaintenanceTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_type': TaskTypeToJSON(value.taskType),
        'task_type_id': value.taskTypeId,
        'client_id': value.clientId,
        'monitored_object': value.monitoredObject,
        'user': value.user,
        'place': value.place,
        'contact': value.contact,
        'due_mileage_kilometers': value.dueMileageKilometers,
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate === null ? null : value.dueDate.toISOString()),
        'repetition_due_date_type': value.repetitionDueDateType,
        'repetition_due_date_value': value.repetitionDueDateValue,
        'repetition_bind_to_due_date': value.repetitionBindToDueDate,
        'repetition_due_mileage_kilometers': value.repetitionDueMileageKilometers,
        'notification_due_date_days': value.notificationDueDateDays,
        'notification_due_mileage_kilometers': value.notificationDueMileageKilometers,
        'notification_recievers': value.notificationRecievers === undefined ? undefined : (value.notificationRecievers === null ? null : (value.notificationRecievers as Array<any>).map(NotificationReceiversToJSON)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'completion_time': value.completionTime === undefined ? undefined : (value.completionTime === null ? null : value.completionTime.toISOString()),
        'completion_mileage_kilometers': value.completionMileageKilometers,
        'completed_by_id': value.completedById,
        'state': value.state,
        'expenses': ExpensesToJSON(value.expenses),
        'last_evaluation_mileage': value.lastEvaluationMileage,
        'last_evaluation_timestamp': value.lastEvaluationTimestamp === undefined ? undefined : (value.lastEvaluationTimestamp === null ? null : value.lastEvaluationTimestamp.toISOString()),
        'notes': value.notes,
        'client': value.client,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyMaintenanceTaskRepetitionDueDateTypeEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}
/**
* @export
* @enum {string}
*/
export enum WriteOnlyMaintenanceTaskStateEnum {
    Overdue = 'overdue',
    Duesoon = 'duesoon',
    Active = 'active',
    Done = 'done'
}


