import cn from 'classnames';
import Lottie from 'react-lottie-player';
import lottieJson from 'resources/json/processing-data.json';

enum Logo {
    light = '/loader-logo.svg',
    dark = '/loader-logo-dark.svg'
}

interface Props {
    type?: 'default' | 'data';
    dark?: boolean;
    fullSize?: boolean;
    maxHeight?: '100' | '200' | '300' | '400' | '500';
    width?: number;
    height?: number;
    customClassName?: string;
}

export function Loading(props: Props) {
    const theme = props.dark ? Logo.dark : Logo.light;

    return props.type === 'data' ? (
        <Lottie
            loop
            animationData={lottieJson}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            play
            style={{ width: props.width ?? '100%', height: props.height ?? '100%' }}
        />
    ) : (
        <div
            className={cn(`loading ${props.customClassName}`, {
                'loading-full-size': props.fullSize,
                'loading-dark': props.dark,
                [`loading-max-height-${props.maxHeight}`]: props.maxHeight
            })}
        >
            <img className="loading-logo" alt="Loading" src={theme} />
            <div className="loading-container">
                <div>
                    {Array(7)
                        .fill(null)
                        .map((_, i) => (
                            <div
                                key={i}
                                className={cn('loading-ball', {
                                    'loading-ball-dark': props.dark
                                })}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}
