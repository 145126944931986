import { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, message, Row, Tooltip } from 'antd';
import cn from 'classnames';
import { duration } from 'moment';
import 'moment-duration-format';
import qa from 'qa-selectors';
import { Loading, Popover } from 'common/components';
import { Checkbox, Input, Radio, Select } from 'common/components/Form';
import { AvailableCurrencies } from 'common/model/currency';
import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { FixedCost, OperationalCost } from 'logic/statistics/statistics-company-profile';
import CostPopover from '../CostPopover';
import { Role } from 'logic/auth';
import { searched } from 'common/utils/search';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import * as CommonIcons from 'resources/images/common';
import { PuescWarning } from '../PuescWarning/PuescWarning';
import { MonitoredObjectFleetType } from 'generated/backend-api/models';
import { TransportType } from '../../../planner-new/planner-logic';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PlannedTransport, RouteType } from 'generated/routing-api/models';
import { exchangePriceCurrency } from 'common/utils/best-price';
import numeral from 'numeral';
import { defaultVehicleProfileName } from 'modules/routing/planner-new/ui/RouteParamsConfigurator';
import { ExternalDeviceType } from 'generated/backend-api-live';
import { trailersVehicleStateObjectIds } from 'common/utils/vehicleState';
import { VehicleStateObject } from 'generated/graphql';
import * as icons from 'resources/images/common';

export interface RouteConfiguration {
    /**
     * Currently selected route balanced/fastest/planned
     */
    route: TransportType;
    clientId?: number;
    pricePerKM: {
        currency: AvailableCurrencies;
        cost: number;
    };
}

interface Props extends WithTranslation, WithLogic {
    companyCostPerKm?: PartnerCompanyModel['costPerKm'];
    costs?: {
        fixed?: FixedCost;
        operational?: {
            monitoredObjectId?: number;
            operationalCost?: OperationalCost;
        }[];
    };
    selectedVehicleId?: number;
    vehicleStates?: VehicleStateObject[];
    onCreateProfileClick?: () => void;
    onTransportTypeChange?: (type: TransportType) => void;
    onRoutePriceChange?: (rc: RouteConfiguration) => void;
    onCreateClientClick?: () => void;
    onClientSelect?: (id?: string) => void;
}

const selectLarge = 180 as const;
const selectSmall = 140 as const;
type selectTypeWidth = typeof selectLarge | typeof selectSmall;

interface State {
    transportNameChanged: boolean;
    selectValue: 'noCriteria' | 'newProfile' | string;
    selectedClient: string | 'newClient' | 'noCriteria';
    configuration: RouteConfiguration;
    vehicleSelectBoxWidth: selectTypeWidth;
    noMoreAvailable: boolean;
}

const INITIAL_STATE: State = {
    transportNameChanged: false,
    selectValue: 'noCriteria',
    selectedClient: 'noCriteria',
    vehicleSelectBoxWidth: selectSmall,
    noMoreAvailable: false,
    configuration: {
        route: TransportType.balanced,
        pricePerKM: {
            cost: 0,
            currency: AvailableCurrencies.EUR
        }
    }
};

class RouteConfigurator extends Component<Props, State> {
    roles: Role[];

    constructor(props: Props) {
        super(props);
        this.roles = this.props.logic.auth().roles();
        const transportCostPerKm = this.props.logic.plannerLogic().transport.costPerKm;
        this.state = {
            ...INITIAL_STATE,
            configuration: {
                ...INITIAL_STATE.configuration,
                pricePerKM: transportCostPerKm?.value
                    ? {
                          cost: transportCostPerKm.value ?? 0,
                          currency: (transportCostPerKm.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
                      }
                    : INITIAL_STATE.configuration.pricePerKM
            }
        };
    }

    componentDidUpdate() {
        if (this.state.selectValue.startsWith('vehicle-')) {
            const selectedVehicle = this.state.selectValue.replace('vehicle-', '');
            if (
                this.props.logic.plannerLogic().selectedVehicleId === undefined &&
                Number(selectedVehicle) !== this.props.logic.plannerLogic().defaultMonitoredObjectId &&
                !this.props.logic.plannerLogic().lockedMonitoredObjectId
            ) {
                message.warning(this.props.t('routing.configurator.vehicleAvailability.noMoreAvailable'));
                this.setState({
                    selectValue: 'noCriteria',
                    noMoreAvailable: true
                });
            }
        }
    }

    render() {
        const { t } = this.props;
        const { pricePerKM, route: activeRoute } = this.state.configuration;
        const collsGrid = this.props.logic.plannerLogic().tollCostEnable ? [6, 5, 5, 4] : [7, 6, 6, 5];

        const realTrailers = trailersVehicleStateObjectIds(
            [ExternalDeviceType.TrailerId, ExternalDeviceType.TrailerManual],
            this.props.vehicleStates?.find(
                vehicleState =>
                    vehicleState?.monitoredObjectId === String(this.props.logic.plannerLogic().selectedVehicleId)
            )
        );
        return (
            <div className="route-configurator">
                <div className="route-configurator-header">
                    <Row align="middle">
                        <Col span={24}>
                            <Row
                                className="route-configurator-header-title"
                                align="middle"
                                gutter={15}
                                justify="space-between"
                            >
                                <Col>
                                    <h5>{t('routing.configurator.title')}</h5>
                                </Col>
                                <Col>
                                    <Checkbox
                                        disabled={this.props.logic.plannerLogic().calculatingRouteLoading}
                                        className="route-configurator-omit-params"
                                        checked={this.props.logic.plannerLogic().transport.omitRouteParams}
                                        onChange={this._omitRouteParamsChange}
                                    >
                                        {t('routing.configurator.routeParams.omitParams')}
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row gutter={48} className="route-configurator-header-mo-selection" align="middle">
                                <Col span={2}>
                                    <div className="route-configurator-puesc-warning-wrapper">
                                        {t('common.vehicle')}
                                        {this.props.logic.plannerLogic().isPuescTransport &&
                                            (!this.props.logic.plannerLogic().selectedVehicleId ||
                                                !this.props.logic.plannerLogic().selectedVehicleIsInPuesc) && (
                                                <PuescWarning
                                                    type={
                                                        this.props.logic.plannerLogic().puescValidationErrors
                                                            ?.vehicle === false
                                                            ? 'error'
                                                            : 'warning'
                                                    }
                                                    tooltip={
                                                        this.props.logic.plannerLogic().puescValidationErrors
                                                            ?.vehicle === false
                                                            ? this.props.t('Planner.puesc.noVehicle')
                                                            : undefined
                                                    }
                                                    size="small"
                                                />
                                            )}
                                    </div>
                                </Col>
                                <Col span={5}>
                                    <Select<State['selectValue']>
                                        className={cn('route-configurator-vehicle-select', {
                                            'tm-warning': this.state.noMoreAvailable
                                        })}
                                        value={this._getSelectVehicleProfileDefaultValue()}
                                        onSelect={this._onVehicleOrProfileSelect}
                                        onClick={() => {
                                            this.setState({
                                                noMoreAvailable: false
                                            });
                                        }}
                                        showSearch
                                        filterOption={(input, option) => searched(input, String(option?.label))}
                                        disabled={
                                            !!this.props.logic.plannerLogic().lockedMonitoredObjectId ||
                                            this.props.logic.plannerLogic().availableVehiclesLoading
                                        }
                                        style={{ width: this.state.vehicleSelectBoxWidth }}
                                        loading={this.props.logic.plannerLogic().availableVehiclesLoading}
                                        qa={qa.planner.configurator.selectVehicle}
                                        onDropdownVisibleChange={(open: boolean) => {
                                            this.setState({
                                                vehicleSelectBoxWidth: open ? selectLarge : selectSmall
                                            });
                                        }}
                                    >
                                        <Select.Option
                                            value="noCriteria"
                                            data-qa={qa.schedulingPlanning.vehicleProfile.noCriteria}
                                            label={t('routing.configurator.select.noCriteria')}
                                        >
                                            {t('routing.configurator.select.noCriteria')}
                                        </Select.Option>
                                        {this.props.logic.plannerLogic().availableVehicles.length > 0 && (
                                            <Select.OptGroup label={t('routing.configurator.select.vehicles')}>
                                                {this.props.logic.plannerLogic().availableVehicles.map(vehicle => (
                                                    <Select.Option
                                                        data-qa={
                                                            vehicle.available
                                                                ? qa.schedulingPlanning.vehicleProfile.availableVehicle
                                                                      .withGPS
                                                                : qa.schedulingPlanning.vehicleProfile.disabledVehicle
                                                                      .withGPS
                                                        }
                                                        disabled={
                                                            (!vehicle.available &&
                                                                vehicle.data.monitoredObjectId !==
                                                                    this.props.logic.plannerLogic()
                                                                        .defaultMonitoredObjectId) ||
                                                            (this.props.logic.plannerLogic().isPuescTransport &&
                                                                !this.props.logic
                                                                    .plannerLogic()
                                                                    .externalSystemVehicleIds?.includes(
                                                                        Number(vehicle.data.monitoredObjectId)
                                                                    ))
                                                        }
                                                        title={
                                                            vehicle.available
                                                                ? t(
                                                                      'routing.configurator.vehicleAvailability.available'
                                                                  )
                                                                : t(
                                                                      'routing.configurator.vehicleAvailability.unavailable'
                                                                  )
                                                        }
                                                        key={`vehicle-${String(vehicle.data.monitoredObjectId)}`}
                                                        value={`vehicle-${String(vehicle.data.monitoredObjectId)}`}
                                                        label={vehicle.data.rn}
                                                    >
                                                        <div
                                                            className={cn('select-puesc-vehicle-wrapper', {
                                                                'select-puesc-flag':
                                                                    this.props.logic.plannerLogic().isPuescTransport &&
                                                                    this.props.logic
                                                                        .plannerLogic()
                                                                        .externalSystemVehicleIds?.includes(
                                                                            Number(vehicle.data.monitoredObjectId)
                                                                        )
                                                            })}
                                                        >
                                                            <Tooltip
                                                                placement="top"
                                                                mouseLeaveDelay={0}
                                                                title={vehicle.data.rn}
                                                            >
                                                                <span>{vehicle.data.rn}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        )}
                                        {[Role.VP_R].some(r => this.roles.includes(r)) &&
                                            this.props.logic.plannerLogic().availableVehicleProfiles.length > 0 && (
                                                <Select.OptGroup label={t('routing.configurator.select.profiles')}>
                                                    {this.props.logic
                                                        .plannerLogic()
                                                        .availableVehicleProfiles.map(profile => {
                                                            const name =
                                                                profile.name === defaultVehicleProfileName
                                                                    ? t('VehicleProfile.defaultProfileName')
                                                                    : profile.name;
                                                            return (
                                                                <Select.Option
                                                                    key={`profile-${String(
                                                                        profile.id ?? profile.name
                                                                    )}`}
                                                                    value={`profile-${String(profile.id)}`}
                                                                    data-qa={
                                                                        qa.schedulingPlanning.vehicleProfile.profile
                                                                    }
                                                                    label={name}
                                                                >
                                                                    {name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select.OptGroup>
                                            )}
                                        <Select.Option
                                            value="newProfile"
                                            key="newProfile"
                                            label={t('routing.configurator.select.newProfile')}
                                            data-qa={qa.schedulingPlanning.vehicleProfile.createProfile}
                                            disabled={
                                                ![Role.VR_W].some(r => this.roles.includes(r)) ||
                                                this.props.logic.demo().isActive
                                            }
                                        >
                                            {t('routing.configurator.select.newProfile')}
                                        </Select.Option>
                                    </Select>
                                </Col>
                                <Col span={3}>
                                    {this.state.vehicleSelectBoxWidth === selectSmall && (
                                        <Input.Number
                                            formatter={val => {
                                                if (val === '') return val;
                                                const res = `${val}`.match(/.*?(\d+\.?(\d+)?).*?/);
                                                if (!res) {
                                                    return '0';
                                                }
                                                return res[1];
                                            }}
                                            min={0}
                                            className={`planner-price-per-km ant-input-no-step ${cn({
                                                'ant-input-number-invalid': isNaN(pricePerKM.cost)
                                            })}`}
                                            disabled={
                                                this.props.logic.plannerLogic().availableVehiclesLoading ||
                                                this.props.logic.plannerLogic().vehiclesPricePerKmLoading
                                            }
                                            onChange={this._onPriceChange}
                                            value={numeral(pricePerKM.cost).format('0,0.00')}
                                            qa={qa.planner.configurator.inputCosts}
                                        />
                                    )}
                                </Col>
                                <Col span={2}>
                                    {this.state.vehicleSelectBoxWidth === selectSmall && (
                                        <>
                                            <span data-qa={qa.planner.configurator.fieldCurrency}>
                                                {pricePerKM.currency}
                                            </span>
                                            /{t('common.km')}
                                        </>
                                    )}
                                </Col>

                                <Col span={12}>
                                    <Row gutter={12} justify="end" align="middle">
                                        <Col>
                                            <div className="route-configurator-puesc-warning-wrapper">
                                                {t('common.trailer')}
                                                {this.props.logic.plannerLogic().isPuescTransport &&
                                                    !this.props.logic.plannerLogic().selectedTrailerId &&
                                                    !this.props.logic.plannerLogic().puescNoTrailer && (
                                                        <PuescWarning
                                                            type={
                                                                this.props.logic.plannerLogic().puescValidationErrors
                                                                    ?.trailer === false
                                                                    ? 'error'
                                                                    : 'warning'
                                                            }
                                                            tooltip={
                                                                this.props.logic.plannerLogic().puescValidationErrors
                                                                    ?.trailer === false
                                                                    ? this.props.t('Planner.puesc.noTrailer')
                                                                    : undefined
                                                            }
                                                            size="small"
                                                        />
                                                    )}
                                                {realTrailers.length > 0 &&
                                                    (!this.props.logic.plannerLogic().selectedTrailerId ||
                                                        String(this.props.logic.plannerLogic().selectedTrailerId) !==
                                                            realTrailers.toString()) && (
                                                        <Tooltip
                                                            data-qa={qa.routeOverview.table.fieldRealTrailer}
                                                            className={'planner-actual-trailer'}
                                                            title={`${t(
                                                                'RouteOverviewTable.actualTrailer'
                                                            )}: ${realTrailers
                                                                .map(
                                                                    realTrailer =>
                                                                        this.props.logic
                                                                            .plannerLogic()
                                                                            .availableTrailers?.find(
                                                                                trailer =>
                                                                                    String(trailer.data?.id) ===
                                                                                    realTrailer
                                                                            )?.data?.registrationNumber ?? realTrailer
                                                                )
                                                                .toString()}`}
                                                        >
                                                            <img
                                                                src={icons.warning}
                                                                alt="actual-trailer"
                                                                data-qa={qa.routeOverview.table.fieldRealTrailer}
                                                            />
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        </Col>
                                        <Col>
                                            <Select<number | 'noCriteria' | 'withoutTrailer'>
                                                value={
                                                    this.props.logic.plannerLogic().availableTrailersLoading
                                                        ? 'noCriteria'
                                                        : this._getSelectTrailerDefaultValue()
                                                }
                                                onSelect={this._onTrailerSelect}
                                                showSearch
                                                filterOption={(input, option) => searched(input, String(option?.label))}
                                                style={{ width: 140 }}
                                                loading={this.props.logic.plannerLogic().availableTrailersLoading}
                                                qa={qa.planner.configurator.selectVehicle}
                                            >
                                                <Select.Option
                                                    value="noCriteria"
                                                    label={t('routing.configurator.select.noCriteria')}
                                                >
                                                    {t('routing.configurator.select.noCriteria')}
                                                </Select.Option>
                                                <Select.Option
                                                    value="withoutTrailer"
                                                    label={t('routing.configurator.select.withoutTrailer')}
                                                >
                                                    {t('routing.configurator.select.withoutTrailer')}
                                                </Select.Option>
                                                <Select.OptGroup label={t('routing.configurator.select.trailers')}>
                                                    {this.props.logic
                                                        .plannerLogic()
                                                        .availableTrailers.map((trailer, index) => (
                                                            <Select.Option
                                                                disabled={!trailer.available}
                                                                data-qa={qa.schedulingPlanning.trailer.availableTrailer}
                                                                key={`trailer-${index}-${trailer.data?.id}`}
                                                                value={trailer.data?.id ?? 0}
                                                                label={trailer.data?.registrationNumber}
                                                            >
                                                                {trailer.data?.registrationNumber}
                                                            </Select.Option>
                                                        ))}
                                                </Select.OptGroup>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="route-configurator-selection">
                    <div className="route-configurator-selection-head">
                        <Row>
                            <Col xs={collsGrid[0]}>{t('routing.configurator.routeParams.preferredRoute')}</Col>
                            <Col xs={collsGrid[1]}>{t('routing.configurator.routeParams.distance')}</Col>
                            <Col xs={collsGrid[2]}>{t('routing.configurator.routeParams.duration')}</Col>
                            {this.props.logic.plannerLogic().tollCostEnable && (
                                <Col xs={4}>
                                    <div className="route-configurator-selection-head-toll">
                                        {t('routing.configurator.routeParams.tollCost')}
                                        {this.props.logic.plannerLogic().tollCostLoading && (
                                            <Loading height={12} width={48} type="data" />
                                        )}
                                    </div>
                                </Col>
                            )}
                            <Col xs={collsGrid[3]}>{t('routing.configurator.routeParams.costs')}</Col>
                        </Row>
                    </div>
                    {this.props.logic.plannerLogic().possibleTransports.map((route, index) => {
                        const tollCost = this._getTollCost(route.type);
                        return (
                            <div
                                key={'toll-costs' + index}
                                onClick={() => this._onSelectRoute(route.type)}
                                className={cn('route-configurator-selection-route', {
                                    'route-configurator-selection-route-selected':
                                        TransportType[route.type] ===
                                        this.props.logic.plannerLogic().selectedTransportType
                                })}
                                data-qa={
                                    (route.type as string) === (activeRoute as string)
                                        ? qa.planner.configurator.routeSelected
                                        : qa.planner.configurator.route
                                }
                            >
                                <Row align="middle" data-qa={qa.planner.configurator.transportType?.[route.type]}>
                                    <Col xs={collsGrid[0]}>
                                        <Row align="middle">
                                            <Col>
                                                <Radio
                                                    className=""
                                                    checked={
                                                        (route.type as string) ===
                                                        this.props.logic.plannerLogic().selectedTransportType
                                                    }
                                                />
                                            </Col>
                                            <Col>{this._getRouteTranslation(route.type)}</Col>
                                        </Row>
                                    </Col>
                                    <Col xs={collsGrid[1]} data-qa={qa.planner.configurator.fieldDistance}>
                                        {Math.round(Number(route.distance) / 1000) + ' ' + t('common.km')}
                                    </Col>
                                    <Col xs={collsGrid[2]} data-qa={qa.planner.configurator.fieldDuration}>
                                        {duration(route.duration ?? 0, 'seconds').format('h[h] m[m]')}
                                    </Col>
                                    {this.props.logic.plannerLogic().tollCostEnable && (
                                        <Col xs={4} data-qa={qa.planner.configurator.fieldTollCosts}>
                                            <Tooltip
                                                title={
                                                    tollCost === '-'
                                                        ? t('routing.configurator.routeParams.tollCostNotAvailable')
                                                        : undefined
                                                }
                                                placement="top"
                                                className={cn({ 'toll-cost-no-available': tollCost === '-' })}
                                            >
                                                {tollCost}
                                            </Tooltip>
                                        </Col>
                                    )}
                                    <Col xs={collsGrid[3]}>{this._getCost(route)}</Col>
                                </Row>
                            </div>
                        );
                    })}
                    {this.props.logic.plannerLogic().possibleTransports.length === 0 && (
                        <p className="route-configurator-selection-route">{t('routing.configurator.noRoutes')}</p>
                    )}
                </div>
                <div className="route-configurator-details">
                    <div className="route-configurator-details-item">
                        <div>{t('routing.configurator.select.title')}</div>
                        <Tooltip title={this.props.logic.plannerLogic().transport.name}>
                            <Input
                                size="large"
                                value={this.props.logic.plannerLogic().transport.name}
                                onChange={this._onTitleChange}
                                qa={qa.planner.configurator.inputName}
                            />
                        </Tooltip>
                    </div>
                    <div className="route-configurator-details-item">
                        <div>{t('routing.configurator.select.client')}</div>
                        <Select<State['selectedClient']>
                            value={this.props.logic.plannerLogic().transport.customerContactId ?? 'no-criteria'}
                            size="large"
                            onSelect={this._onClientSelect}
                            showSearch
                            filterOption={(input, option) => searched(input, String(option?.children))}
                            loading={this.props.logic.plannerLogic().clientContactListLoading}
                            qa={qa.planner.configurator.selectVehicle}
                        >
                            <Select.Option value="noCriteria" data-qa={qa.schedulingPlanning.vehicleProfile.noCriteria}>
                                {t('routing.configurator.select.noCriteria')}
                            </Select.Option>
                            <Select.Option value="newClient" key="newClient">
                                <div className="route-configurator-details-item-client">
                                    <img src={CommonIcons.addPlusCircle} alt="add new client" />
                                    {t('routing.configurator.select.newClient')}
                                </div>
                            </Select.Option>
                            <Select.OptGroup label={t('routing.configurator.select.clients')}>
                                {this.props.logic.plannerLogic().clientContactList.map((contact, index) => (
                                    <Select.Option
                                        data-qa={qa.schedulingPlanning.trailer.availableTrailer}
                                        key={`client-${index}-${contact.id}`}
                                        value={contact.id ?? 0}
                                    >
                                        {contact.name}
                                    </Select.Option>
                                ))}
                            </Select.OptGroup>
                        </Select>
                    </div>
                </div>
                {/* <div className="route-configurator-puesc-download">
                    <img alt="download" src={CommonIcons.download} />
                    PUESC documents
                </div> */}
            </div>
        );
    }

    private _omitRouteParamsChange = async (e: CheckboxChangeEvent) => {
        this.props.logic.plannerLogic().setOmitParamsFromRouting(e.target.checked);
        await this.props.logic.plannerLogic().planRoute(this.props.logic.plannerLogic().transport);
        this.props.logic.plannerLogic().drawRouteOnMap();
    };

    private _onTrailerSelect = (value: number | 'noCriteria' | 'withoutTrailer') => {
        this.props.logic.plannerLogic().setPuescNoTrailer(value === 'withoutTrailer');
        if (value === 'noCriteria' || value === 'withoutTrailer') {
            this.props.logic.plannerLogic().removeMonitoredObjectFromTransport(MonitoredObjectFleetType.Trailer);
        } else {
            this.props.logic.plannerLogic().setMonitoredObjectOnTransport(value, MonitoredObjectFleetType.Trailer);
        }
    };

    private _getRouteTranslation = (routeType: RouteType) => {
        return this.props.t(`VehicleProfile.route.${routeType}`);
    };

    private _getTollCost = (type: RouteType) => {
        // old transports with no toll cost data
        if (!this.props.logic.plannerLogic().tollCostByType?.[type]?.totalCost) {
            return '-';
        }
        const value = this.props.logic.plannerLogic().tollCostByType?.[type]?.totalCost;

        // Just show in client currency
        if (this.state.configuration.pricePerKM.currency === AvailableCurrencies.EUR) {
            return value
                ? numeral(value).format('0,0.0') + ' ' + AvailableCurrencies.EUR
                : 0.0 + ' ' + AvailableCurrencies.EUR;
        } else {
            const price = exchangePriceCurrency(
                AvailableCurrencies.EUR,
                this.state.configuration.pricePerKM.currency,
                value ?? 0,
                this.props.logic.poi().currencies ?? []
            );
            return numeral(price).format('0,0.0') + ' ' + this.state.configuration.pricePerKM.currency;
        }
    };

    private _getSelectVehicleProfileDefaultValue = () => {
        if (this.props.logic.plannerLogic().selectedProfileId) {
            return this.props.logic.plannerLogic().availableVehicleProfiles.length > 0 &&
                this.props.logic
                    .plannerLogic()
                    .availableVehicleProfiles.map(profile => profile.id)
                    .includes(this.props.logic.plannerLogic().selectedProfileId)
                ? `profile-${this.props.logic.plannerLogic().selectedProfileId}`
                : 'noCriteria';
        }
        if (this.props.logic.plannerLogic().selectedVehicleId) {
            return this.props.logic.plannerLogic().availableVehicles.length > 0 &&
                this.props.logic
                    .plannerLogic()
                    .availableVehicles.map(vehicle => vehicle.data.monitoredObjectId)
                    .includes(String(this.props.logic.plannerLogic().selectedVehicleId))
                ? `vehicle-${this.props.logic.plannerLogic().selectedVehicleId}`
                : 'noCriteria';
        }
        return 'noCriteria';
    };

    private _getSelectTrailerDefaultValue = () => {
        return (
            this.props.logic.plannerLogic().selectedTrailerId ??
            (this.props.logic.plannerLogic().puescNoTrailer ? 'withoutTrailer' : 'noCriteria')
        );
    };

    private _getCost = (route: PlannedTransport) => {
        const { pricePerKM } = this.state.configuration;
        const content = () => {
            const value = Math.round(pricePerKM.cost * ((route.distance ?? 0) / 1000) * 100) / 100;
            return numeral(value).format('0,0.0') + ' ' + pricePerKM.currency;
        };

        const operational = this.props.costs?.operational?.find(
            operational => operational.monitoredObjectId === this.props.selectedVehicleId
        );

        const requiredCosts = (costs?: {
            fixed?: FixedCost;
            operationalCost?: OperationalCost;
        }): costs is { fixed: FixedCost; operationalCost: OperationalCost } =>
            costs?.fixed !== undefined && operational?.operationalCost !== undefined;

        return requiredCosts(this.props.costs) &&
            this.props.selectedVehicleId &&
            operational &&
            operational.operationalCost ? (
            <Popover
                content={
                    <CostPopover
                        costs={{
                            fixed: this.props.costs.fixed,
                            operationalCost: operational.operationalCost
                        }}
                        pricePerKM={pricePerKM}
                        distance={route.distance ?? 0}
                    />
                }
            >
                <div className="route-configurator-route-cost">{content()}</div>
            </Popover>
        ) : (
            <div className="route-configurator-route-cost" data-qa={qa.planner.configurator.fieldCosts}>
                {content()}
            </div>
        );
    };

    private _onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.logic.plannerLogic().changeTransportName(e.target.value);
    };

    private _onPriceChange = (value: number | string | undefined) => {
        const valueValid = !isNaN(Number(value)) && Number(value) > 0;
        this.setState(
            state => ({
                configuration: {
                    ...state.configuration,
                    pricePerKM: {
                        ...state.configuration.pricePerKM,
                        cost: valueValid ? Number(value) : state.configuration.pricePerKM.cost
                    }
                }
            }),
            () => {
                this.props.onRoutePriceChange?.(this.state.configuration);
            }
        );
    };

    private _onSelectRoute = (type: RouteType) => {
        this.props.onTransportTypeChange?.(TransportType[type]);
    };

    private _onVehicleOrProfileSelect = (value: string) => {
        if (value.startsWith('profile-')) {
            const profile = this.props.logic
                .plannerLogic()
                .availableVehicleProfiles.find(p => p.id?.toString() === value.replace('profile-', ''));
            if (profile?.id) {
                this.props.logic.plannerLogic().updateTransportProfileAndReroute(profile.id);
            }
            let pricePerKmInClientCurrency = profile?.pricePerKm;
            if (this.props.companyCostPerKm?.currency !== AvailableCurrencies.EUR) {
                pricePerKmInClientCurrency = exchangePriceCurrency(
                    AvailableCurrencies.EUR,
                    this.state.configuration.pricePerKM.currency,
                    pricePerKmInClientCurrency ?? 0,
                    this.props.logic.poi().currencies ?? []
                );
            }
            this.setState(
                state => ({
                    selectValue: value,
                    configuration: {
                        ...state.configuration,
                        pricePerKM: {
                            cost: pricePerKmInClientCurrency
                                ? pricePerKmInClientCurrency
                                : this.props.companyCostPerKm?.cost ?? 0,
                            currency: this.props.companyCostPerKm?.currency ?? AvailableCurrencies.EUR
                        }
                    }
                }),
                () =>
                    this.props.logic
                        .plannerLogic()
                        .setTransportCostPerKm(
                            this.state.configuration.pricePerKM.cost,
                            this.state.configuration.pricePerKM.currency
                        )
            );
        } else if (value.startsWith('vehicle-')) {
            const vehicle = this.props.logic
                .plannerLogic()
                .availableVehicles.find(p => p.data.monitoredObjectId === value.replace('vehicle-', ''));
            if (vehicle?.data.monitoredObjectId) {
                this.props.logic
                    .plannerLogic()
                    .updateTransportMonitoredObjectAndReroute(
                        Number(vehicle.data.monitoredObjectId),
                        MonitoredObjectFleetType.Vehicle
                    );
            }
            this.setState(
                state => ({
                    selectValue: value,
                    configuration: {
                        ...state.configuration,
                        pricePerKM: vehicle?.profile?.costPerKm
                            ? {
                                  cost: vehicle?.profile?.costPerKm ?? 0,
                                  currency:
                                      vehicle?.profile?.currency ??
                                      this.props.companyCostPerKm?.currency ??
                                      AvailableCurrencies.EUR
                              }
                            : {
                                  cost: this.props.companyCostPerKm?.cost ?? 0,
                                  currency: this.props.companyCostPerKm?.currency ?? AvailableCurrencies.EUR
                              }
                    }
                }),
                () =>
                    this.props.logic
                        .plannerLogic()
                        .setTransportCostPerKm(
                            this.state.configuration.pricePerKM.cost,
                            this.state.configuration.pricePerKM.currency
                        )
            );
        } else if (value === 'newProfile') {
            this.props.onCreateProfileClick?.();
        } else {
            this.props.logic.plannerLogic().removeMonitoredObjectOrProfileAndReroute();
            this.setState({
                selectValue: value
            });
        }
    };

    private _onClientSelect = (value: State['selectedClient']) => {
        if (value === 'noCriteria') {
            this.props.onClientSelect?.(undefined);
        } else if (value === 'newClient') {
            this.props.onCreateClientClick?.();
        } else {
            this.props.onClientSelect?.(value);
        }
    };
}

export default withTranslation()(withLogicContext(observer(RouteConfigurator)));
