import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

export interface Props extends SwitchProps {
    qa?: string;
}

export default function EnhancedSwitch({ qa, ...props }: Props) {
    return <Switch data-qa={qa} {...props} />;
}
