import { Component, ChangeEvent, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormValidator, StringValidator } from 'validators';
import AutoComplete from 'common/components/AutoComplete';
import { PoiType } from 'generated/graphql';
import numeral from 'numeral';
import cn from 'classnames';
import { LatLng } from 'common/model/geo';
import { Checkbox, Select } from 'common/components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Role } from 'logic/auth';
import { PlaceOfWorkUser } from 'common/model/place-of-work-user';
import { GPS_FORMAT } from 'domain-constants';
import qa from 'qa-selectors';
import FormActions from './FormActions';
import { PoiModel } from 'common/model/poi';
import i18next from 'i18next';
import { Col, Row } from 'antd';

const nameValidator = new StringValidator(
    {
        required: true,
        regexp: /^[\wÀ-ž]+( +[\wÀ-ž]+)*$/
    },
    { required: i18next.t('common.required'), invalid_format: i18next.t('common.invalidFormat') }
);

const typeValidator = new StringValidator(
    {
        required: true
    },
    {
        required: i18next.t('common.required')
    }
);

interface PoiFormValidator {
    name: string;
    type: string;
}

const formValidator = new FormValidator<PoiFormValidator>()
    .addValidator('name', nameValidator)
    .addValidator('type', typeValidator);

const defaultValidationErrors = {
    nameErr: '',
    typeErr: ''
};

interface Props extends WithTranslation {
    demoMode?: boolean;
    model: PoiModel;
    modelEdited?: PoiModel;
    loading?: boolean;
    search: boolean;
    searchResults?: {
        label: string;
        code: string;
    }[];
    placeOfWorkUsers?: PlaceOfWorkUser[];
    roles: Role[];
    removePoiLoading: boolean;
    onSubmit?: (poi: PoiModel) => void;
    onCancel?: () => void;
    onSearchTextChange?: (value: string) => void;
    onSearchSelect?: (value: string) => void;
}

interface State {
    id: string;
    name: string;
    nameErr: string;
    type: PoiType;
    typeErr: string;
    forbidden: boolean;
    address: string;
    notes: string;
    center?: {
        lat: number;
        lng: number;
    };
    polygon?: LatLng[];
    countryCode?: string;
}

class PoiForm extends Component<Props, State> {
    model: PoiModel;

    constructor(props: Props) {
        super(props);

        const formatResult = formValidator.format(props.model);

        this.model = { ...props.model };
        this.state = {
            ...formatResult.str!,
            ...defaultValidationErrors,
            id: this.props.model.id ?? '',
            type: this.props.model.type ?? PoiType.Firm,
            forbidden: this.props.model.forbidden ?? false,
            notes: this.props.model.notes ?? '',
            address: this.props.model.address ?? '',
            countryCode: this.props.model.countryCode ?? '',
            center: this.props.model.center && {
                lat: this.props.model.center.lat,
                lng: this.props.model.center.lng
            },
            polygon: this.props.model.polygon
        };
    }

    componentDidUpdate(prevProps: Props) {
        this._onProps(prevProps);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="poi-form">
                <form className="editable t-padding" onSubmit={this._onSubmit}>
                    <div className="t-bar-block">
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.name')}>
                                {t('PoiForm.name')}
                            </Col>
                            <Col span={12}>
                                <input
                                    className={cn('t-input t-padding-small editable', {
                                        error: this.state?.nameErr,
                                        success: !this.state.nameErr && !!this.state.name
                                    })}
                                    type="text"
                                    name="name"
                                    onChange={this._onNameChange}
                                    value={this.state.name}
                                    data-qa={qa.poi.detail.inputName}
                                />

                                {this.state.nameErr && (
                                    <span className="t-bold t-text-danger" data-qa={qa.poi.detail.inputNameErr}>
                                        {t('validator.' + this.state.nameErr)}
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.type')} data-qa={qa.poi.detail.fieldType}>
                                {t('PoiForm.type')}
                            </Col>
                            <Col span={12}>
                                <Select
                                    className="select-no-title poi-type-select"
                                    showSearch
                                    onSelect={this._onTypeChange}
                                    value={this.state.type}
                                    data-qa={qa.poi.detail.selectType}
                                >
                                    {Object.keys(PoiType).map(type => {
                                        return (
                                            <Select.Option key={type} value={PoiType[type]}>
                                                {t(`PoiForm.${String(PoiType[type])}`)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                                <span className="t-text-danger" data-qa={qa.poi.detail.selectTypeErr}>
                                    {this.state.typeErr && t('validator.' + this.state.typeErr)}
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.forbidden')}>
                                {t('PoiForm.forbidden')}
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    onChange={this._onForbiddenChange}
                                    checked={this.state.forbidden}
                                    data-qa={qa.poi.detail.inputForbidden}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.searchForPlace')}>
                                {t('PoiForm.searchForPlace')}
                            </Col>
                            <Col span={12}>
                                <AutoComplete
                                    text=""
                                    options={this.props.searchResults ?? []}
                                    placeholder={t('PoiForm.typePlace')}
                                    onOptionSelect={this._onSearchSelect}
                                    onTextChange={this._onSearchTextChange}
                                    qa={qa.poi.detail.inputPlaceSearch}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.address')}>
                                {t('PoiForm.address')}
                            </Col>
                            <Col span={12}>
                                <input
                                    className="t-input t-half t-padding-small editable"
                                    disabled
                                    readOnly
                                    type="text"
                                    name="name"
                                    value={this.state.address}
                                    data-qa={qa.poi.detail.inputAddress}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.lat')}>
                                {t('PoiForm.lat')}
                            </Col>
                            <Col span={12}>
                                <input
                                    className="t-input t-half t-padding-small editable"
                                    disabled
                                    readOnly
                                    type="text"
                                    value={this.state.center ? numeral(this.state.center.lat).format(GPS_FORMAT) : ''}
                                    data-qa={qa.poi.detail.inputLat}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={12} title={t('PoiForm.lng')}>
                                {t('PoiForm.lng')}
                            </Col>
                            <Col span={12}>
                                <input
                                    className="t-input t-half t-padding-small editable"
                                    disabled
                                    readOnly
                                    type="text"
                                    value={this.state.center ? numeral(this.state.center.lng).format(GPS_FORMAT) : ''}
                                    data-qa={qa.poi.detail.inputLng}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} align="middle">
                            <Col className="notes" span={12} title={t('PoiForm.notes')}>
                                {t('PoiForm.notes')}
                            </Col>
                            <Col span={12}>
                                <textarea
                                    className="t-input t-half editable"
                                    name="notes"
                                    onChange={this._onNotesChange}
                                    value={this.state.notes}
                                />
                            </Col>
                        </Row>
                        <FormActions
                            cancelQa={qa.poi.detail.btnCancel}
                            submitQa={qa.poi.detail.btnSubmit}
                            submitLoading={this.props.loading}
                            submitDisabled={this.props.demoMode || !this.state.center || this.props.loading}
                            onCancelClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }

    private _onProps = (prevProps: Props): void => {
        if (this.props !== prevProps) {
            const formatResult = formValidator.format(this.props.model);
            if (this.props.model !== prevProps.model) {
                this.setState({
                    ...formatResult.obj!,
                    id: this.props.model.id ?? '',
                    forbidden: this.props.model.forbidden ?? false,
                    type: this.props.model.type ?? PoiType.Firm,
                    notes: this.props.model.notes ?? '',
                    address: this.props.modelEdited
                        ? this.props.modelEdited.address ?? ''
                        : this.props.model.address ?? '',
                    countryCode: this.props.modelEdited
                        ? this.props.modelEdited.countryCode ?? ''
                        : this.props.model.countryCode ?? '',
                    center: this.props.modelEdited
                        ? this.props.modelEdited.center && {
                              lat: this.props.modelEdited.center.lat,
                              lng: this.props.modelEdited.center.lng
                          }
                        : this.props.model.center && {
                              lat: this.props.model.center.lat,
                              lng: this.props.model.center.lng
                          },
                    polygon: this.props.modelEdited ? this.props.modelEdited.polygon : this.props.model.polygon
                });
            } else if (this.props.modelEdited !== prevProps.modelEdited) {
                this.setState(state => ({
                    ...state,
                    address: this.props.modelEdited
                        ? this.props.modelEdited.address ?? ''
                        : this.props.model.address ?? '',
                    countryCode: this.props.modelEdited
                        ? this.props.modelEdited.countryCode ?? ''
                        : this.props.model.countryCode ?? '',
                    center: this.props.modelEdited
                        ? this.props.modelEdited.center && {
                              lat: this.props.modelEdited.center.lat,
                              lng: this.props.modelEdited.center.lng
                          }
                        : this.props.model.center && {
                              lat: this.props.model.center.lat,
                              lng: this.props.model.center.lng
                          },
                    polygon: this.props.modelEdited ? this.props.modelEdited.polygon : this.props.model.polygon
                }));
            }
        }
    };

    private _onSubmit = (e: FormEvent): void => {
        e.preventDefault();
        const validationRes = formValidator.validate({
            name: this.state.name,
            type: this.state.type as string
        });

        if (validationRes.valid) {
            this.props.onSubmit?.({
                id: this.state.id,
                name: this.state.name,
                forbidden: this.state.forbidden,
                address: this.state.address,
                notes: this.state.notes,
                type: this.state.type,
                center: this.state.center,
                polygon: this.state.polygon,
                countryCode: this.state.countryCode
            });
        } else {
            this.setState({
                nameErr: validationRes.err!.name,
                typeErr: validationRes.err!.type
            });
        }
    };

    private _onForbiddenChange = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;
        this.model.forbidden = checked;
        this.setState({
            forbidden: checked
        });
    };

    private _onNameChange = (e: ChangeEvent): void => {
        const value = (e.target as HTMLInputElement).value;
        const validateRes = nameValidator.validate(value);
        this.model.name = value;
        this.setState({
            name: value,
            nameErr: validateRes.err as string
        });
    };

    private _onTypeChange = (value: string): void => {
        const validateRes = typeValidator.validate(value);

        this.model.type = value as PoiType;

        this.setState({
            type: value as PoiType,
            typeErr: validateRes.err as string
        });
    };

    private _onNotesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        this.model.notes = value;
        this.setState({
            notes: value
        });
    };

    private _onSearchSelect = (value: string | null) => {
        this.props.onSearchSelect?.(value ?? '');
    };

    private _onSearchTextChange = (value: string) => {
        this.props.onSearchTextChange?.(value);
    };
}

export default withTranslation()(PoiForm);
