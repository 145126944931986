/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriversToCustomPlaces
 */
export interface DriversToCustomPlaces {
    /**
     * 
     * @type {Array<string>}
     * @memberof DriversToCustomPlaces
     */
    listOfCustomPlaceIds: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DriversToCustomPlaces
     */
    listOfUserIds: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof DriversToCustomPlaces
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof DriversToCustomPlaces
     */
    end?: Date;
}

export function DriversToCustomPlacesFromJSON(json: any): DriversToCustomPlaces {
    return DriversToCustomPlacesFromJSONTyped(json, false);
}

export function DriversToCustomPlacesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriversToCustomPlaces {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listOfCustomPlaceIds': json['list_of_custom_place_ids'],
        'listOfUserIds': json['list_of_user_ids'],
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
    };
}

export function DriversToCustomPlacesToJSON(value?: DriversToCustomPlaces | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list_of_custom_place_ids': value.listOfCustomPlaceIds,
        'list_of_user_ids': value.listOfUserIds,
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
    };
}


