import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { Logic } from 'logic/logic';

export class VehicleGroups {
    constructor(private _logic: Logic) {}

    async getMonitoredObjectGroupNestedById(id: number) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupNestedSingle({
                id
            });
        } catch (err) {
            console.error('VehicleGroups getMonitoredObjectGroupById err:', err);
            throw err;
        }
    }

    async getAllMonitoredObjectGroups() {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupList({
                limit: INFINITY_PAGE_LIMIT,
                offset: DEFAULT_PAGE_OFFSET
            });
        } catch (err) {
            console.error('Monitored object group GET err:', err);
            throw err;
        }
    }

    async getMonitoredObjectGroupListByName(name?: string) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupList({
                limit: INFINITY_PAGE_LIMIT,
                offset: DEFAULT_PAGE_OFFSET,
                nameIexact: name
            });
        } catch (err) {
            console.error('Monitored object group GET by name err:', err);
            throw err;
        }
    }

    async editMonitoredObjectGroupName(id: number, name: string) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupUpdate({
                id,
                data: {
                    name
                }
            });
        } catch (err) {
            console.error('Monitored object group PATCH err:', err);
            throw err;
        }
    }

    async removeMonitoredObjectsFromMonitoredObjectGroup(groupId: number, monitoredObjectIds: number[]) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupRemoveAsset({
                id: groupId,
                data: {
                    monitoredObjects: monitoredObjectIds
                }
            });
        } catch (err) {
            console.error('Monitored object group remove err:', err);
            throw err;
        }
    }

    async addMonitoredObjectsToMonitoredObjectGroup(groupId: number, monitoredObjectIds: number[]) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupAddAsset({
                id: groupId,
                data: {
                    monitoredObjects: monitoredObjectIds
                }
            });
        } catch (err) {
            console.error('Monitored object group insert err:', err);
            throw err;
        }
    }

    async updateMonitoredObjectGroupList(groupId: number, monitoredObjectIds: number[], userIds: number[]) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupReplaceAssetUser({
                id: groupId,
                data: {
                    monitoredObjects: monitoredObjectIds,
                    users: userIds
                }
            });
        } catch (err) {
            console.error('Monitored object group PATCH err:', err);
            throw err;
        }
    }

    async deleteMonitoredObjectGroup(id: number) {
        try {
            return this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupDelete({
                id
            });
        } catch (err) {
            console.error('Monitored object group DELETE err:', err);
            throw err;
        }
    }

    async createMonitoredObjectGroup(name: string) {
        try {
            return await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupCreate({
                data: {
                    name
                }
            });
        } catch (err) {
            console.error('Monitored object group POST err:', err);
            throw err;
        }
    }
}
