import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Tooltip } from 'common/components';
import * as ccrIcons from 'resources/images/ccr';

import qa from 'qa-selectors';

interface Props extends WithTranslation {
    serialNumberList: string[];
    state: string;
}

class DriverCardsRemoteMemoryLabel extends Component<Props> {
    render() {
        return (
            <Tooltip
                title={this.props.serialNumberList.map(serialNumber => (
                    <div key={serialNumber}>{serialNumber}</div>
                ))}
            >
                <div
                    className={`driver-cards-remote-memory-label-container driver-cards-remote-memory-label-state-${this.props.state}`}
                >
                    <Row>
                        <Col>
                            <img
                                src={ccrIcons[this.props.state]}
                                alt={this.props.state}
                                data-qa={qa.remoteDownload.table.ccr[this.props.state]}
                            />
                        </Col>
                        <Col>{this.props.t(`DriverCardsRemoteMemoryCcrTable.${this.props.state}`)}</Col>
                    </Row>
                </div>
            </Tooltip>
        );
    }
}

export default withTranslation()(DriverCardsRemoteMemoryLabel);
