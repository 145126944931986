/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleDriverState,
    VehicleDriverStateFromJSON,
    VehicleDriverStateFromJSONTyped,
    VehicleDriverStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleDriver
 */
export interface VehicleDriver {
    /**
     * 
     * @type {string}
     * @memberof VehicleDriver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriver
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriver
     */
    source?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriver
     */
    time: Date;
    /**
     * 
     * @type {VehicleDriverState}
     * @memberof VehicleDriver
     */
    state: VehicleDriverState;
}

export function VehicleDriverFromJSON(json: any): VehicleDriver {
    return VehicleDriverFromJSONTyped(json, false);
}

export function VehicleDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'time': (new Date(json['time'])),
        'state': VehicleDriverStateFromJSON(json['state']),
    };
}

export function VehicleDriverToJSON(value?: VehicleDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'source': value.source,
        'time': (value.time.toISOString()),
        'state': VehicleDriverStateToJSON(value.state),
    };
}


