import { useState } from 'react';
import { Button } from 'common/components';
import { ButtonProps } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import cn from 'classnames';

interface Props extends ButtonProps, React.RefAttributes<HTMLElement> {
    size?: 'large' | 'middle' | 'small';
    qa?: string;
    innerref?: React.Ref<HTMLElement> | undefined;
    defaultIsDown?: boolean;
    isDown?: boolean;
}

const OutlineButton = ({ isDown, ...props }: Props) => {
    const [isDownButton, setIsDownButton] = useState(isDown ?? props.defaultIsDown ?? false);

    const handleButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
        setIsDownButton(!isDown);
        props.onClick?.(e);
    };

    if (isDown !== undefined && isDown !== isDownButton) {
        setIsDownButton(isDown);
    }

    return (
        <Button
            {...props}
            className={cn(`outline-button ${props.className}`, {
                'outline-button-down': isDown,
                'outline-button-up': !isDown
            })}
            onClick={handleButtonClicked}
        >
            {isDown ? <UpOutlined /> : <DownOutlined />}
        </Button>
    );
};

export default OutlineButton;
