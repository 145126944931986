import { useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import cn from 'classnames';
import { Button } from 'common/components';
interface Props {
    label: string;
    icon?: string;
    defaultSelected?: boolean;
    disabled?: boolean;
    extended?: boolean;
    showExtendedTooltip?: boolean;
    className?: string;
    count?: number;
    onButtonClick?: (selected: boolean) => void;
}

const FilterButton = (props: Props) => {
    const [selected, setSelected] = useState<boolean>(props.defaultSelected ?? false);
    const icon = props.icon ?? '/icons/icn-filter-button.svg';
    const extended = props.extended ?? true;
    const showExtendedTooltip = props.showExtendedTooltip ?? true;

    const handleButtonClick = () => {
        props.onButtonClick?.(!selected);
        setSelected(selected => !selected);
    };

    return (
        <Tooltip title={!extended && showExtendedTooltip ? props.label : ''} placement="top">
            <Button
                type="default"
                className={cn(`button-filter ${props.className} ${selected ?? 'selected'}`, {
                    selected: selected
                })}
                disabled={props.disabled}
                onClick={handleButtonClick}
            >
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row align="middle" gutter={8}>
                            {props.count !== undefined && (
                                <Col>
                                    <span className="count">{props.count}</span>
                                </Col>
                            )}
                            {extended && (
                                <Col>
                                    <span className="label">{props.label}</span>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col>
                        <span className="icon">
                            <img className="fa" src={icon} alt="" />
                        </span>
                    </Col>
                </Row>
            </Button>
        </Tooltip>
    );
};

export default FilterButton;
