/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TotalRanking
 */
export interface TotalRanking {
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TDrvBrRet20TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TDrvBrRet20TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TDrvBrRet20TDrv?: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    zt0TStayIdleTREng: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    pt0TStayIdle: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0TStayIdle: number;
    /**
     * 
     * @type {number}
     * @memberof TotalRanking
     */
    ft0Ranking: number;
}

export function TotalRankingFromJSON(json: any): TotalRanking {
    return TotalRankingFromJSONTyped(json, false);
}

export function TotalRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ft0TDrvWoCTEcorollTDrv': json['ft0_t_drv_wo_c_t_ecoroll_t_drv'],
        'pt0TDrvWoCTEcorollTDrv': json['pt0_t_drv_wo_c_t_ecoroll_t_drv'],
        'ft0TDrvAccConstTREng': json['ft0_t_drv_acc_const_t_r_eng'],
        'pt0TDrvAccConstTREng': json['pt0_t_drv_acc_const_t_r_eng'],
        'ft0TDrvLoad86TREng': json['ft0_t_drv_load_86_t_r_eng'],
        'pt0TDrvLoad86TREng': json['pt0_t_drv_load_86_t_r_eng'],
        'ft0TRDrvLoadOverOptTREng': json['ft0_t_r_drv_load_over_opt_t_r_eng'],
        'pt0TRDrvLoadOverOptTREng': json['pt0_t_r_drv_load_over_opt_t_r_eng'],
        'ft0TAvgAccBr': json['ft0_t_avg_acc_br'],
        'pt0TAvgAccBr': json['pt0_t_avg_acc_br'],
        'ft0TDrvBrRet20TDrv': json['ft0_t_drv_br_ret_20_t_drv'],
        'pt0TDrvBrRet20TDrv': json['pt0_t_drv_br_ret_20_t_drv'],
        'ft0CntBrDDrv': json['ft0_cnt_br_d_drv'],
        'pt0CntBrDDrv': json['pt0_cnt_br_d_drv'],
        'ft0DDrvBrDDrv': json['ft0_d_drv_br_d_drv'],
        'pt0DDrvBrDDrv': json['pt0_d_drv_br_d_drv'],
        'zt0TDrvWoCTEcorollTDrv': json['zt0_t_drv_wo_c_t_ecoroll_t_drv'],
        'zt0TDrvAccConstTREng': json['zt0_t_drv_acc_const_t_r_eng'],
        'zt0TDrvLoad86TREng': json['zt0_t_drv_load_86_t_r_eng'],
        'zt0TRDrvLoadOverOptTREng': json['zt0_t_r_drv_load_over_opt_t_r_eng'],
        'zt0TAvgAccBr': json['zt0_t_avg_acc_br'],
        'zt0TDrvBrRet20TDrv': !exists(json, 'zt0_t_drv_br_ret_20_t_drv') ? undefined : json['zt0_t_drv_br_ret_20_t_drv'],
        'zt0CntBrDDrv': json['zt0_cnt_br_d_drv'],
        'zt0DDrvBrDDrv': json['zt0_d_drv_br_d_drv'],
        'zt0TStayIdleTREng': json['zt0_t_stay_idle_t_r_eng'],
        'pt0TStayIdle': json['pt0_t_stay_idle'],
        'ft0TStayIdle': json['ft0_t_stay_idle'],
        'ft0Ranking': json['ft0_ranking'],
    };
}

export function TotalRankingToJSON(value?: TotalRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ft0_t_drv_wo_c_t_ecoroll_t_drv': value.ft0TDrvWoCTEcorollTDrv,
        'pt0_t_drv_wo_c_t_ecoroll_t_drv': value.pt0TDrvWoCTEcorollTDrv,
        'ft0_t_drv_acc_const_t_r_eng': value.ft0TDrvAccConstTREng,
        'pt0_t_drv_acc_const_t_r_eng': value.pt0TDrvAccConstTREng,
        'ft0_t_drv_load_86_t_r_eng': value.ft0TDrvLoad86TREng,
        'pt0_t_drv_load_86_t_r_eng': value.pt0TDrvLoad86TREng,
        'ft0_t_r_drv_load_over_opt_t_r_eng': value.ft0TRDrvLoadOverOptTREng,
        'pt0_t_r_drv_load_over_opt_t_r_eng': value.pt0TRDrvLoadOverOptTREng,
        'ft0_t_avg_acc_br': value.ft0TAvgAccBr,
        'pt0_t_avg_acc_br': value.pt0TAvgAccBr,
        'ft0_t_drv_br_ret_20_t_drv': value.ft0TDrvBrRet20TDrv,
        'pt0_t_drv_br_ret_20_t_drv': value.pt0TDrvBrRet20TDrv,
        'ft0_cnt_br_d_drv': value.ft0CntBrDDrv,
        'pt0_cnt_br_d_drv': value.pt0CntBrDDrv,
        'ft0_d_drv_br_d_drv': value.ft0DDrvBrDDrv,
        'pt0_d_drv_br_d_drv': value.pt0DDrvBrDDrv,
        'zt0_t_drv_wo_c_t_ecoroll_t_drv': value.zt0TDrvWoCTEcorollTDrv,
        'zt0_t_drv_acc_const_t_r_eng': value.zt0TDrvAccConstTREng,
        'zt0_t_drv_load_86_t_r_eng': value.zt0TDrvLoad86TREng,
        'zt0_t_r_drv_load_over_opt_t_r_eng': value.zt0TRDrvLoadOverOptTREng,
        'zt0_t_avg_acc_br': value.zt0TAvgAccBr,
        'zt0_t_drv_br_ret_20_t_drv': value.zt0TDrvBrRet20TDrv,
        'zt0_cnt_br_d_drv': value.zt0CntBrDDrv,
        'zt0_d_drv_br_d_drv': value.zt0DDrvBrDDrv,
        'zt0_t_stay_idle_t_r_eng': value.zt0TStayIdleTREng,
        'pt0_t_stay_idle': value.pt0TStayIdle,
        'ft0_t_stay_idle': value.ft0TStayIdle,
        'ft0_ranking': value.ft0Ranking,
    };
}


