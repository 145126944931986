/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MonitoredObjectToMonitoreObjectPartialUpdate,
    MonitoredObjectToMonitoreObjectPartialUpdateFromJSON,
    MonitoredObjectToMonitoreObjectPartialUpdateToJSON,
    MonitoredObjectToMonitoredObject,
    MonitoredObjectToMonitoredObjectFromJSON,
    MonitoredObjectToMonitoredObjectToJSON,
    MonitoredObjectWithPairedTrailers,
    MonitoredObjectWithPairedTrailersFromJSON,
    MonitoredObjectWithPairedTrailersToJSON,
    ReadOnlyMonitoredObjectToMonitoredObject,
    ReadOnlyMonitoredObjectToMonitoredObjectFromJSON,
    ReadOnlyMonitoredObjectToMonitoredObjectToJSON,
    WriteOnlyMonitoredObjectToMonitoredObject,
    WriteOnlyMonitoredObjectToMonitoredObjectFromJSON,
    WriteOnlyMonitoredObjectToMonitoredObjectToJSON,
} from '../models';

export interface MonitoredObjectToMonitoredObjectCreateRequest {
    data: WriteOnlyMonitoredObjectToMonitoredObject;
}

export interface MonitoredObjectToMonitoredObjectDeleteRequest {
    primaryMonitoredObjectId: string;
}

export interface MonitoredObjectToMonitoredObjectGetCurrentRequest {
    client?: string;
    atTime?: string;
}

export interface MonitoredObjectToMonitoredObjectGetPairedTrailersRequest {
    primaryMonitoredObject?: string;
    secondaryMonitoredObject?: string;
    primaryMonitoredObjectIn?: string;
    validFromLte?: string;
    validToGte?: string;
    client?: string;
}

export interface MonitoredObjectToMonitoredObjectListRequest {
    primaryMonitoredObject?: string;
    secondaryMonitoredObject?: string;
    primaryMonitoredObjectIn?: string;
    validFromLte?: string;
    validToGte?: string;
    client?: string;
}

export interface MonitoredObjectToMonitoredObjectPartialUpdateRequest {
    primaryMonitoredObjectId: string;
    data: WriteOnlyMonitoredObjectToMonitoredObject;
}

export interface MonitoredObjectToMonitoredObjectReadRequest {
    primaryMonitoredObjectId: string;
}

export interface MonitoredObjectToMonitoredObjectUpdateRequest {
    primaryMonitoredObjectId: string;
    data: WriteOnlyMonitoredObjectToMonitoredObject;
}

export interface MonitoredObjectToMonitoredObjectUpdateM2mRequest {
    data: MonitoredObjectToMonitoreObjectPartialUpdate;
}

/**
 * no description
 */
export class MonitoredObjectToMonitoredObjectApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectToMonitoredObjectCreateRaw(requestParameters: MonitoredObjectToMonitoredObjectCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectToMonitoredObject>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectToMonitoredObjectCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectToMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectCreate(requestParameters: MonitoredObjectToMonitoredObjectCreateRequest): Promise<ReadOnlyMonitoredObjectToMonitoredObject> {
        const response = await this.monitoredObjectToMonitoredObjectCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectDeleteRaw(requestParameters: MonitoredObjectToMonitoredObjectDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.primaryMonitoredObjectId === null || requestParameters.primaryMonitoredObjectId === undefined) {
            throw new runtime.RequiredError('primaryMonitoredObjectId','Required parameter requestParameters.primaryMonitoredObjectId was null or undefined when calling monitoredObjectToMonitoredObjectDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/{primary_monitored_object_id}/`.replace(`{${"primary_monitored_object_id"}}`, encodeURIComponent(String(requestParameters.primaryMonitoredObjectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async monitoredObjectToMonitoredObjectDelete(requestParameters: MonitoredObjectToMonitoredObjectDeleteRequest): Promise<void> {
        await this.monitoredObjectToMonitoredObjectDeleteRaw(requestParameters);
    }

    /**
     */
    async monitoredObjectToMonitoredObjectGetCurrentRaw(requestParameters: MonitoredObjectToMonitoredObjectGetCurrentRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectToMonitoredObject>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.atTime !== undefined) {
            queryParameters['at_time'] = requestParameters.atTime;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/get_current/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectToMonitoredObjectFromJSON));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectGetCurrent(requestParameters: MonitoredObjectToMonitoredObjectGetCurrentRequest): Promise<Array<MonitoredObjectToMonitoredObject>> {
        const response = await this.monitoredObjectToMonitoredObjectGetCurrentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectGetPairedTrailersRaw(requestParameters: MonitoredObjectToMonitoredObjectGetPairedTrailersRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectWithPairedTrailers>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.primaryMonitoredObject !== undefined) {
            queryParameters['primary_monitored_object'] = requestParameters.primaryMonitoredObject;
        }

        if (requestParameters.secondaryMonitoredObject !== undefined) {
            queryParameters['secondary_monitored_object'] = requestParameters.secondaryMonitoredObject;
        }

        if (requestParameters.primaryMonitoredObjectIn !== undefined) {
            queryParameters['primary_monitored_object__in'] = requestParameters.primaryMonitoredObjectIn;
        }

        if (requestParameters.validFromLte !== undefined) {
            queryParameters['valid_from__lte'] = requestParameters.validFromLte;
        }

        if (requestParameters.validToGte !== undefined) {
            queryParameters['valid_to__gte'] = requestParameters.validToGte;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/paired_trailers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectWithPairedTrailersFromJSON));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectGetPairedTrailers(requestParameters: MonitoredObjectToMonitoredObjectGetPairedTrailersRequest): Promise<Array<MonitoredObjectWithPairedTrailers>> {
        const response = await this.monitoredObjectToMonitoredObjectGetPairedTrailersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectListRaw(requestParameters: MonitoredObjectToMonitoredObjectListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMonitoredObjectToMonitoredObject>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.primaryMonitoredObject !== undefined) {
            queryParameters['primary_monitored_object'] = requestParameters.primaryMonitoredObject;
        }

        if (requestParameters.secondaryMonitoredObject !== undefined) {
            queryParameters['secondary_monitored_object'] = requestParameters.secondaryMonitoredObject;
        }

        if (requestParameters.primaryMonitoredObjectIn !== undefined) {
            queryParameters['primary_monitored_object__in'] = requestParameters.primaryMonitoredObjectIn;
        }

        if (requestParameters.validFromLte !== undefined) {
            queryParameters['valid_from__lte'] = requestParameters.validFromLte;
        }

        if (requestParameters.validToGte !== undefined) {
            queryParameters['valid_to__gte'] = requestParameters.validToGte;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMonitoredObjectToMonitoredObjectFromJSON));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectList(requestParameters: MonitoredObjectToMonitoredObjectListRequest): Promise<Array<ReadOnlyMonitoredObjectToMonitoredObject>> {
        const response = await this.monitoredObjectToMonitoredObjectListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectPartialUpdateRaw(requestParameters: MonitoredObjectToMonitoredObjectPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectToMonitoredObject>> {
        if (requestParameters.primaryMonitoredObjectId === null || requestParameters.primaryMonitoredObjectId === undefined) {
            throw new runtime.RequiredError('primaryMonitoredObjectId','Required parameter requestParameters.primaryMonitoredObjectId was null or undefined when calling monitoredObjectToMonitoredObjectPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectToMonitoredObjectPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/{primary_monitored_object_id}/`.replace(`{${"primary_monitored_object_id"}}`, encodeURIComponent(String(requestParameters.primaryMonitoredObjectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectToMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectPartialUpdate(requestParameters: MonitoredObjectToMonitoredObjectPartialUpdateRequest): Promise<ReadOnlyMonitoredObjectToMonitoredObject> {
        const response = await this.monitoredObjectToMonitoredObjectPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectReadRaw(requestParameters: MonitoredObjectToMonitoredObjectReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectToMonitoredObject>> {
        if (requestParameters.primaryMonitoredObjectId === null || requestParameters.primaryMonitoredObjectId === undefined) {
            throw new runtime.RequiredError('primaryMonitoredObjectId','Required parameter requestParameters.primaryMonitoredObjectId was null or undefined when calling monitoredObjectToMonitoredObjectRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/{primary_monitored_object_id}/`.replace(`{${"primary_monitored_object_id"}}`, encodeURIComponent(String(requestParameters.primaryMonitoredObjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectToMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectRead(requestParameters: MonitoredObjectToMonitoredObjectReadRequest): Promise<ReadOnlyMonitoredObjectToMonitoredObject> {
        const response = await this.monitoredObjectToMonitoredObjectReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectUpdateRaw(requestParameters: MonitoredObjectToMonitoredObjectUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectToMonitoredObject>> {
        if (requestParameters.primaryMonitoredObjectId === null || requestParameters.primaryMonitoredObjectId === undefined) {
            throw new runtime.RequiredError('primaryMonitoredObjectId','Required parameter requestParameters.primaryMonitoredObjectId was null or undefined when calling monitoredObjectToMonitoredObjectUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectToMonitoredObjectUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/{primary_monitored_object_id}/`.replace(`{${"primary_monitored_object_id"}}`, encodeURIComponent(String(requestParameters.primaryMonitoredObjectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectToMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectUpdate(requestParameters: MonitoredObjectToMonitoredObjectUpdateRequest): Promise<ReadOnlyMonitoredObjectToMonitoredObject> {
        const response = await this.monitoredObjectToMonitoredObjectUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectToMonitoredObjectUpdateM2mRaw(requestParameters: MonitoredObjectToMonitoredObjectUpdateM2mRequest): Promise<runtime.ApiResponse<MonitoredObjectToMonitoredObject>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectToMonitoredObjectUpdateM2m.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-to-monitored-object/update_m2m/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectToMonitoreObjectPartialUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectToMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectToMonitoredObjectUpdateM2m(requestParameters: MonitoredObjectToMonitoredObjectUpdateM2mRequest): Promise<MonitoredObjectToMonitoredObject> {
        const response = await this.monitoredObjectToMonitoredObjectUpdateM2mRaw(requestParameters);
        return await response.value();
    }

}
