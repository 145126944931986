/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    VehicleActivity,
    VehicleActivityFromJSON,
    VehicleActivityToJSON,
    VehicleDistance,
    VehicleDistanceFromJSON,
    VehicleDistanceToJSON,
} from '../models';

export interface DailyActivityV1VehiclestatisticsDailyActivityGetRequest {
    dateFrom: Date;
    dateTo: Date;
}

export interface DailyOdometerV1VehiclestatisticsDailyOdometerGetRequest {
    dateFrom: Date;
    dateTo: Date;
}

/**
 * no description
 */
export class VehicleStatisticsApi extends runtime.BaseAPI {

    /**
     * Daily Activity
     */
    async dailyActivityV1VehiclestatisticsDailyActivityGetRaw(requestParameters: DailyActivityV1VehiclestatisticsDailyActivityGetRequest): Promise<runtime.ApiResponse<Array<VehicleActivity>>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling dailyActivityV1VehiclestatisticsDailyActivityGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling dailyActivityV1VehiclestatisticsDailyActivityGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/vehiclestatistics/daily_activity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleActivityFromJSON));
    }

    /**
     * Daily Activity
     */
    async dailyActivityV1VehiclestatisticsDailyActivityGet(requestParameters: DailyActivityV1VehiclestatisticsDailyActivityGetRequest): Promise<Array<VehicleActivity>> {
        const response = await this.dailyActivityV1VehiclestatisticsDailyActivityGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Daily Odometer
     */
    async dailyOdometerV1VehiclestatisticsDailyOdometerGetRaw(requestParameters: DailyOdometerV1VehiclestatisticsDailyOdometerGetRequest): Promise<runtime.ApiResponse<Array<VehicleDistance>>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling dailyOdometerV1VehiclestatisticsDailyOdometerGet.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling dailyOdometerV1VehiclestatisticsDailyOdometerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/vehiclestatistics/daily_odometer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleDistanceFromJSON));
    }

    /**
     * Daily Odometer
     */
    async dailyOdometerV1VehiclestatisticsDailyOdometerGet(requestParameters: DailyOdometerV1VehiclestatisticsDailyOdometerGetRequest): Promise<Array<VehicleDistance>> {
        const response = await this.dailyOdometerV1VehiclestatisticsDailyOdometerGetRaw(requestParameters);
        return await response.value();
    }

}
