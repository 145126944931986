import { Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    FixedCostModel,
    OperationalCostModel,
    FixedCost,
    OperationalCost,
    Cost,
    CostStructure
} from 'logic/statistics/statistics-company-profile';
import { CostUnit } from 'generated/main-data-api';
import { StringValidator, FormValidator } from 'validators';
import { ColumnType } from 'antd/lib/table';
import { Row, Col } from 'antd';
import Table from 'common/components/Table';
import { Input } from 'common/components/Form';
import { AvailableCurrencies } from 'common/model/currency';
import { EFuelType } from 'generated/graphql';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { MonitoredObjectFleetType } from 'generated/backend-api';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { fixedStringNumber } from 'common/utils/textUtils';
import FormActions from 'common/components/FormActions';

interface OperationalCostsFormValidator {
    salaries: string;
    operations: string;
    crmInsurance: string;
    other: string;
    fuel: string;
    additives: string;
    oil: string;
    tires: string;
    maintenance: string;
    liabilityInsurance: string;
    collisionInsurance: string;
    personalInjuryInsurance: string;
    parking: string;
    cabinHeating: string;
    telecomunicationsAndMonitoring: string;
    washing: string;
    lngCng: string;
    electro: string;
}

interface Props extends WithTranslation {
    loading: boolean;
    current?: Cost;
    fixed?: {
        data: FixedCostModel;
    };
    operational?: {
        data: OperationalCostModel;
    };
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    tableData: CostStructure[];
    edit?: 'fixed' | 'operational' | undefined;
    currency?: AvailableCurrencies;
    demoMode?: boolean;
    onClose?: () => void;
    onSubmit?: (fixedCost: FixedCost, operationalCost: OperationalCost) => void;
}

export interface CostModel {
    name: string;
    defaultValue?: number;
    defaultValueFleetType?: {
        value: number;
        type: MonitoredObjectFleetType;
        unit: CostUnit;
    }[];
    defaultUnit?: CostUnit;
    value?: string;
    currency?: string;
    unit?: CostUnit;
    err?: string;
}

export interface Costs {
    operational: CostModel[];
    fixed: CostModel[];
}

export enum CostTranslations {
    fuel = 'priceFuel',
    lngCng = 'lngCng',
    electro = 'electro',
    cabinHeating = 'cabinHeating',
    collisionInsurance = 'collisionInsurance',
    liabilityInsurance = 'liabilityInsurance',
    maintenance = 'maintenance',
    parking = 'parking',
    personalInjuryInsurance = 'personalInjuryInsurance',
    additives = 'priceAdditives',
    oil = 'priceOil',
    tires = 'priceTires',
    telecomunicationsAndMonitoring = 'telecomunicationsAndMonitoring',
    washing = 'washing',
    salaries = 'wage',
    operations = 'officeRent',
    crmInsurance = 'insuranceCRM',
    other = 'other'
}

interface State {
    costs?: Costs;
    isValid: boolean;
}

class CompanyProfileCosts extends Component<Props, State> {
    fixedFields: string[] = ['salaries', 'crmInsurance', 'other', 'operations'];
    fixedCostsModel?: FixedCostModel;
    operationalCostsModel?: OperationalCostModel;
    formValidator: FormValidator<OperationalCostsFormValidator>;
    costValidator: StringValidator;

    constructor(props: Props) {
        super(props);
        const { fixed, operational, currency } = this.props;

        this.state = {
            costs: {
                fixed: [
                    {
                        name: 'salaries',
                        defaultValue: fixed?.data?.defaults?.salaries?.value,
                        defaultUnit: (fixed?.data?.defaults?.salaries?.unit as CostUnit) ?? CostUnit.PerKilometer,
                        value: this.renderValue(fixed?.data?.costs?.salaries?.value),
                        currency: this.props.currency ?? AvailableCurrencies.EUR,
                        unit: (fixed?.data?.costs?.salaries?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'operations',
                        defaultValue: fixed?.data?.defaults?.operations?.value,
                        defaultUnit: (fixed?.data?.defaults?.operations?.unit as CostUnit) ?? CostUnit.PerKilometer,
                        value: this.renderValue(fixed?.data?.costs?.operations?.value),
                        currency: this.props.currency ?? AvailableCurrencies.EUR,
                        unit: (this.props.fixed?.data?.costs?.operations?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'crmInsurance',
                        defaultValue: fixed?.data?.defaults?.crmInsurance?.value,
                        defaultUnit: (fixed?.data?.defaults?.crmInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer,
                        value: this.renderValue(fixed?.data?.costs?.crmInsurance?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (fixed?.data?.costs?.crmInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'other',
                        defaultValue: fixed?.data?.defaults?.other?.value,
                        defaultUnit: (fixed?.data?.defaults?.other?.unit as CostUnit) ?? CostUnit.PerKilometer,
                        value: this.renderValue(fixed?.data?.costs?.other?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (fixed?.data?.costs?.other?.unit as CostUnit) ?? CostUnit.PerKilometer
                    }
                ],
                operational: [
                    {
                        name: 'fuel',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.fuel.value,
                            unit: d.fuel.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.fuel?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.fuel?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'lngCng',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.lngCng.value,
                            unit: d.lngCng.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.lngCng?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.lngCng?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'electro',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.electro.value,
                            unit: d.electro.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.electro?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.electro?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'additives',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.additives.value,
                            unit: d.additives.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.additives?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.additives?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'oil',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.oil.value,
                            unit: d.oil.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.oil?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.oil?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'tires',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.tires.value,
                            unit: d.tires.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.tires?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.tires?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'maintenance',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.maintenance.value,
                            unit: d.maintenance.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.maintenance?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.maintenance?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'liabilityInsurance',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.liabilityInsurance.value,
                            unit: d.liabilityInsurance.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.liabilityInsurance?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.liabilityInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'collisionInsurance',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.collisionInsurance.value,
                            unit: d.collisionInsurance.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.collisionInsurance?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.collisionInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'personalInjuryInsurance',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.personalInjuryInsurance.value,
                            unit: d.personalInjuryInsurance.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.personalInjuryInsurance?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit:
                            (operational?.data?.costs?.personalInjuryInsurance?.unit as CostUnit) ??
                            CostUnit.PerKilometer
                    },
                    {
                        name: 'parking',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.parking.value,
                            unit: d.parking.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.parking?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.parking?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'cabinHeating',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.cabinHeating.value,
                            unit: d.cabinHeating.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.cabinHeating?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.cabinHeating?.unit as CostUnit) ?? CostUnit.PerKilometer
                    },
                    {
                        name: 'telecomunicationsAndMonitoring',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.telecomunicationsAndMonitoring.value,
                            unit: d.telecomunicationsAndMonitoring.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.telecomunicationsAndMonitoring?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit:
                            (operational?.data?.costs?.telecomunicationsAndMonitoring?.unit as CostUnit) ??
                            CostUnit.PerKilometer
                    },
                    {
                        name: 'washing',
                        defaultUnit: CostUnit.PerKilometer,
                        defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                            type: d.fleetType,
                            value: d.washing.value,
                            unit: d.washing.unit
                        })),
                        value: this.renderValue(operational?.data?.costs?.washing?.value),
                        currency: currency ?? AvailableCurrencies.EUR,
                        unit: (operational?.data?.costs?.washing?.unit as CostUnit) ?? CostUnit.PerKilometer
                    }
                ]
            },
            isValid: true
        };

        this.costValidator = new StringValidator(
            {
                regexp: /^[0-9]+(\.[0-9]+)?$|^$/,
                min: 0
            },
            { invalid_format: this.props.t('validator.invalid_format') }
        );

        this.formValidator = new FormValidator<OperationalCostsFormValidator>()
            .addValidator('salaries', this.costValidator)
            .addValidator('operations', this.costValidator)
            .addValidator('crmInsurance', this.costValidator)
            .addValidator('other', this.costValidator)
            .addValidator('fuel', this.costValidator)
            .addValidator('lngCng', this.costValidator)
            .addValidator('electro', this.costValidator)
            .addValidator('additives', this.costValidator)
            .addValidator('oil', this.costValidator)
            .addValidator('tires', this.costValidator)
            .addValidator('maintenance', this.costValidator)
            .addValidator('liabilityInsurance', this.costValidator)
            .addValidator('collisionInsurance', this.costValidator)
            .addValidator('personalInjuryInsurance', this.costValidator)
            .addValidator('parking', this.costValidator)
            .addValidator('cabinHeating', this.costValidator)
            .addValidator('telecomunicationsAndMonitoring', this.costValidator)
            .addValidator('washing', this.costValidator);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            JSON.stringify(prevProps.fixed) !== JSON.stringify(this.props.fixed) ||
            JSON.stringify(prevProps.operational) !== JSON.stringify(this.props.operational)
        ) {
            const { fixed, operational, currency } = this.props;
            this.setState(state => ({
                costs: {
                    ...state.costs,
                    fixed: [
                        {
                            name: 'salaries',
                            defaultValue: fixed?.data?.defaults?.salaries?.value,
                            defaultUnit: (fixed?.data?.defaults?.salaries?.unit as CostUnit) ?? CostUnit.PerKilometer,
                            value: this.renderValue(fixed?.data?.costs?.salaries?.value),
                            currency: this.props.currency ?? AvailableCurrencies.EUR,
                            unit: (fixed?.data?.costs?.salaries?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'operations',
                            defaultValue: fixed?.data?.defaults?.operations?.value,
                            defaultUnit: (fixed?.data?.defaults?.operations?.unit as CostUnit) ?? CostUnit.PerKilometer,
                            value: this.renderValue(fixed?.data?.costs?.operations?.value),
                            currency: this.props.currency ?? AvailableCurrencies.EUR,
                            unit: (this.props.fixed?.data?.costs?.operations?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'crmInsurance',
                            defaultValue: fixed?.data?.defaults?.crmInsurance?.value,
                            defaultUnit:
                                (fixed?.data?.defaults?.crmInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer,
                            value: this.renderValue(fixed?.data?.costs?.crmInsurance?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (fixed?.data?.costs?.crmInsurance?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'other',
                            defaultValue: fixed?.data?.defaults?.other?.value,
                            defaultUnit: (fixed?.data?.defaults?.other?.unit as CostUnit) ?? CostUnit.PerKilometer,
                            value: this.renderValue(fixed?.data?.costs?.other?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (fixed?.data?.costs?.other?.unit as CostUnit) ?? CostUnit.PerKilometer
                        }
                    ],
                    operational: [
                        {
                            name: 'fuel',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.fuel.value,
                                unit: d.fuel.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.fuel?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.fuel?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'lngCng',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.lngCng.value,
                                unit: d.lngCng.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.lngCng?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.lngCng?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'electro',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.electro.value,
                                unit: d.electro.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.electro?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.electro?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'additives',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.additives.value,
                                unit: d.additives.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.additives?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.additives?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'oil',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.oil.value,
                                unit: d.oil.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.oil?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.oil?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'tires',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.tires.value,
                                unit: d.tires.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.tires?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.tires?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'maintenance',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.maintenance.value,
                                unit: d.maintenance.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.maintenance?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.maintenance?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'liabilityInsurance',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.liabilityInsurance.value,
                                unit: d.liabilityInsurance.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.liabilityInsurance?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit:
                                (operational?.data?.costs?.liabilityInsurance?.unit as CostUnit) ??
                                CostUnit.PerKilometer
                        },
                        {
                            name: 'collisionInsurance',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.collisionInsurance.value,
                                unit: d.collisionInsurance.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.collisionInsurance?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit:
                                (operational?.data?.costs?.collisionInsurance?.unit as CostUnit) ??
                                CostUnit.PerKilometer
                        },
                        {
                            name: 'personalInjuryInsurance',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.personalInjuryInsurance.value,
                                unit: d.personalInjuryInsurance.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.personalInjuryInsurance?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit:
                                (operational?.data?.costs?.personalInjuryInsurance?.unit as CostUnit) ??
                                CostUnit.PerKilometer
                        },
                        {
                            name: 'parking',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.parking.value,
                                unit: d.parking.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.parking?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.parking?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'cabinHeating',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.cabinHeating.value,
                                unit: d.cabinHeating.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.cabinHeating?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.cabinHeating?.unit as CostUnit) ?? CostUnit.PerKilometer
                        },
                        {
                            name: 'telecomunicationsAndMonitoring',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.telecomunicationsAndMonitoring.value,
                                unit: d.telecomunicationsAndMonitoring.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.telecomunicationsAndMonitoring?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit:
                                (operational?.data?.costs?.telecomunicationsAndMonitoring?.unit as CostUnit) ??
                                CostUnit.PerKilometer
                        },
                        {
                            name: 'washing',
                            defaultUnit: CostUnit.PerKilometer,
                            defaultValueFleetType: operational?.data.defaultsFleet?.map(d => ({
                                type: d.fleetType,
                                value: d.washing.value,
                                unit: d.washing.unit
                            })),
                            value: this.renderValue(operational?.data?.costs?.washing?.value),
                            currency: currency ?? AvailableCurrencies.EUR,
                            unit: (operational?.data?.costs?.washing?.unit as CostUnit) ?? CostUnit.PerKilometer
                        }
                    ]
                }
            }));
        }
    }

    renderValue(value: number | undefined): string {
        return (value ?? 0) !== 0 ? String(value) : '';
    }

    render() {
        const { t } = this.props;
        return (
            <div className="company-profile-costs">
                <Row gutter={10} className="modal-form-content" align="middle">
                    {this.props.edit === 'fixed' && (
                        <Col offset={2} xs={20} md={20}>
                            <Table<CostModel>
                                bordered={false}
                                showHeader={true}
                                dataSource={this.state.costs?.fixed}
                                size="middle"
                                tableLayout="auto"
                                rowKey={record => record.name}
                                columns={this._createColumns('Fixed').filter(c => c.key !== 'defaultValue')}
                                scroll={{ y: 'calc(100vh - 400px' }}
                            />
                        </Col>
                    )}
                    {this.props.edit === 'operational' && (
                        <>
                            <Col xs={24} md={this.props.tableData.filter(d => d.selected).length > 1 ? 18 : 24}>
                                <Table<CostModel>
                                    showHeader={true}
                                    dataSource={this.state.costs?.operational.filter(
                                        o => !this._getNotUsedCosts().includes(o.name)
                                    )}
                                    size="middle"
                                    tableLayout="auto"
                                    rowKey={record => record.name}
                                    columns={this._createColumns('Operational').filter(
                                        c =>
                                            c.key !== 'defaultValue' ||
                                            (c.dataIndex &&
                                                this._getUsedMonitoredObjectFleetTypes().includes(
                                                    MonitoredObjectFleetType[c.dataIndex?.toString()]
                                                ))
                                    )}
                                    scroll={{ y: 'calc(100vh - 400px' }}
                                />
                            </Col>
                            {this.props.tableData.filter(d => d.selected).length > 1 && (
                                <Col xs={24} md={6}>
                                    <div className="company-profile-costs-alert">
                                        <div className="company-profile-costs-alert-content">
                                            {this.props.tableData.filter(
                                                d => d.selected && d.operationalCostProfile !== undefined
                                            ).length > 0 ? (
                                                <>
                                                    {t('CompanyProfile.overrideOperationalCostForManyVehicles')}{' '}
                                                    {this.props.tableData
                                                        .filter(d => d.selected && d.operationalCostProfile?.fuel)
                                                        .map(
                                                            d =>
                                                                this.props.vehicles?.find(
                                                                    v => v.id === d.monitoredObjectId
                                                                )?.registrationNumber
                                                        )
                                                        .toString()}
                                                </>
                                            ) : (
                                                t('CompanyProfile.operationalCostForManyVehicles')
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
                <FormActions
                    className="buttons-group"
                    onCancelClick={this._onClose}
                    submitText={t('common.submit')}
                    submitLoading={this.props.loading}
                    submitDisabled={this.props.loading || this.props.demoMode || !this.state.isValid}
                    onSubmitClick={this._onSubmit}
                />
            </div>
        );
    }

    private _fleetTypeToMonitoredObjectFleetType(type: FleetType): MonitoredObjectFleetType {
        switch (type) {
            case FleetType.LIGHT_VEHICLE:
                return MonitoredObjectFleetType.LightVehicle;
            default:
            case FleetType.VEHICLE:
                return MonitoredObjectFleetType.Vehicle;
        }
    }

    private _getUsedMonitoredObjectFleetTypes(): MonitoredObjectFleetType[] {
        const usedFleetTypes = this.props.vehicles
            .filter(v =>
                this.props.tableData
                    .filter(t => t.selected)
                    .map(t => t.monitoredObjectId)
                    .includes(v.id ?? 0)
            )
            .map(v => this._fleetTypeToMonitoredObjectFleetType(v.fleetType as FleetType));
        const usedFleetTypesUnique = usedFleetTypes.filter((a, b) => usedFleetTypes.indexOf(a) === b);
        return usedFleetTypesUnique;
    }

    private _getNotUsedCosts(): string[] {
        const usedFuelTypes: EFuelType[] = this.props.vehicles
            .filter(v =>
                this.props.tableData
                    .filter(t => t.selected)
                    .map(t => t.monitoredObjectId)
                    .includes(v.id ?? 0)
            )
            .map(v => (v?.fuelType as EFuelType) ?? EFuelType.Diesel);
        const usedFuelTypesUnique = usedFuelTypes.filter((a, b) => usedFuelTypes.indexOf(a) === b);
        let costs: string[] = [];
        usedFuelTypesUnique.forEach(fuelType => {
            switch (fuelType) {
                case EFuelType.Diesel:
                case EFuelType.Gasoline:
                    costs.push('fuel');
                    break;
                case EFuelType.LngCng:
                    costs.push('lngCng');
                    break;
                case EFuelType.Electro:
                    costs.push('electro');
                    break;
                case EFuelType.Hybrid:
                case EFuelType.Hydrogen:
                    costs.push('fuel');
                    costs.push('electro');
                    break;
            }
        });
        return ['fuel', 'lngCng', 'electro'].filter(f => !costs.includes(f));
    }

    private _createColumns = (costsType: string) => {
        const isOperational = costsType === 'Operational';

        return [
            {
                title: '',
                key: 'name',
                dataIndex: 'name',
                width: 150,
                render: (_: any, record: CostModel) => (
                    <div>{this.props.t(`CompanyProfile${costsType}Costs.${CostTranslations[record.name]}`)}</div>
                )
            },
            Object.keys(MonitoredObjectFleetType).map((d, i) => ({
                title: (
                    <>
                        {MonitoredObjectFleetType[d] === MonitoredObjectFleetType.Vehicle && (
                            <img
                                title={this.props.t('CompanyProfile.marketValue')}
                                className="company-profile-actions-current-costs-fleet-icon"
                                src="/img-map/panel-vehicle-selected.svg"
                                alt={this.props.t('common.vehicle')}
                            />
                        )}
                        {MonitoredObjectFleetType[d] === MonitoredObjectFleetType.LightVehicle && (
                            <img
                                title={this.props.t('CompanyProfile.marketValue')}
                                className="company-profile-actions-current-costs-fleet-icon"
                                src="/img-map/panel-light-vehicle-selected.svg"
                                alt={this.props.t('common.vehicle')}
                            />
                        )}
                    </>
                ),
                key: 'defaultValue',
                dataIndex: d.valueOf(),
                align: 'center',
                width: 120,
                render: (_: any, record: CostModel) => (
                    <>
                        {record.defaultValueFleetType?.[i].value}
                        {' / '}
                        {this.props.t(`CompanyProfile.unit.${record.defaultValueFleetType?.[i].unit}`)}
                    </>
                )
            })),
            {
                title: isOperational ? this.props.t('CompanyProfile.yourValue') ?? '' : '',
                key: 'value',
                dataIndex: 'value',
                width: 60,
                align: 'center',
                render: (_: any, record: any) => (
                    <div>
                        <Input
                            type="text"
                            value={
                                record.value !== undefined
                                    ? fixedStringNumber(record.value, ['electro'].includes(record.name) ? 4 : 2)
                                    : ''
                            }
                            maxLength={8}
                            onChange={this._onValueChange.bind(undefined, record.name, false)}
                            onBlur={this._onValueChange.bind(undefined, record.name, true)}
                        />
                        <span className="t-bold t-text-danger">{record.err}</span>
                    </div>
                )
            },
            {
                title: '',
                key: 'unit',
                dataIndex: 'unit',
                width: 110,
                align: 'center',
                render: (_: any, record: any) => (
                    <div>
                        <select
                            className="company-profile-costs-select-unit"
                            value={record.unit}
                            onChange={this._onUnitChange.bind(undefined, record.name)}
                        >
                            {Object.keys(CostUnit).map(v => {
                                return (
                                    <option key={v} value={CostUnit[v]}>
                                        {` / ${this.props.t(`CompanyProfile.unit.${String(CostUnit[v])}`)}`}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )
            }
        ].flat() as ColumnType<CostModel>[];
    };

    private _onValueChange = (key: string, onBlur: boolean, e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '.'); // Switch 0. to 0,

        const validateRes = this.costValidator.validate(onBlur ? value : value.replace(/\.$/, '')); // Dont validate 0. 0, only onBLUR

        if (this.fixedFields.includes(key)) {
            this.setState(
                state => ({
                    ...state,
                    costs: {
                        ...state.costs!,
                        fixed: state.costs!.fixed!.map(fixed => ({
                            ...fixed,
                            value: fixed.name === key ? value : fixed.value,
                            err: fixed.name === key ? validateRes.err : fixed.err
                        }))
                    }
                }),
                () => {
                    if (onBlur) {
                        this.formValidator.validate(this._getFormValues());
                        this.setState({
                            isValid: this.formValidator.valid ?? true
                        });
                    }
                }
            );
        } else {
            this.setState(
                state => ({
                    ...state,
                    costs: {
                        ...state.costs!,
                        operational: state.costs!.operational!.map(operational => ({
                            ...operational,
                            value: operational.name === key ? value : operational.value,
                            err: operational.name === key ? validateRes.err : operational.err
                        }))
                    }
                }),
                () => {
                    if (onBlur) {
                        this.formValidator.validate(this._getFormValues());
                        this.setState({
                            isValid: this.formValidator.valid ?? true
                        });
                    }
                }
            );
        }
    };

    private _onUnitChange = (key: string, e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;

        if (value) {
            if (this.fixedFields.includes(key)) {
                this.setState(state => ({
                    ...state,
                    costs: {
                        ...state.costs!,
                        fixed: state.costs!.fixed!.map(c => ({
                            ...c,
                            unit: c.name === key ? (value as CostUnit) : c.unit
                        }))
                    }
                }));
            } else {
                this.setState(state => ({
                    ...state,
                    costs: {
                        ...state.costs!,
                        operational: state.costs!.operational!.map(c => ({
                            ...c,
                            unit: c.name === key ? (value as CostUnit) : c.unit
                        }))
                    }
                }));
            }
        }
    };

    private _getFormValues(): OperationalCostsFormValidator {
        const { costs } = this.state;
        return {
            salaries: costs?.fixed.find(c => c.name === 'salaries')?.value!,
            operations: costs?.fixed.find(c => c.name === 'operations')?.value!,
            crmInsurance: costs?.fixed.find(c => c.name === 'crmInsurance')?.value!,
            other: costs?.fixed.find(c => c.name === 'other')?.value!,
            fuel: costs?.operational.find(c => c.name === 'fuel')?.value!,
            lngCng: costs?.operational.find(c => c.name === 'lngCng')?.value!,
            electro: costs?.operational.find(c => c.name === 'electro')?.value!,
            additives: costs?.operational.find(c => c.name === 'additives')?.value!,
            oil: costs?.operational.find(c => c.name === 'oil')?.value!,
            tires: costs?.operational.find(c => c.name === 'tires')?.value!,
            maintenance: costs?.operational.find(c => c.name === 'maintenance')?.value!,
            liabilityInsurance: costs?.operational.find(c => c.name === 'liabilityInsurance')?.value!,
            collisionInsurance: costs?.operational.find(c => c.name === 'collisionInsurance')?.value!,
            personalInjuryInsurance: costs?.operational.find(c => c.name === 'personalInjuryInsurance')?.value!,
            parking: costs?.operational.find(c => c.name === 'parking')?.value!,
            cabinHeating: costs?.operational.find(c => c.name === 'cabinHeating')?.value!,
            telecomunicationsAndMonitoring: costs?.operational.find(c => c.name === 'telecomunicationsAndMonitoring')
                ?.value!,
            washing: costs?.operational.find(c => c.name === 'washing')?.value!
        };
    }

    private _onClose = () => {
        this.props.onClose?.();
    };

    private _onSubmit = () => {
        const { costs } = this.state;
        const validationRes = this.formValidator.validate(this._getFormValues());

        if (validationRes.valid) {
            const fixedCost: FixedCost = {
                salaries: {
                    value: Number(costs?.fixed.find(c => c.name === 'salaries')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.fixed.find(c => c.name === 'salaries')?.unit as CostUnit
                },
                crmInsurance: {
                    value: Number(costs?.fixed.find(c => c.name === 'crmInsurance')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.fixed.find(c => c.name === 'crmInsurance')?.unit as CostUnit
                },
                operations: {
                    value: Number(costs?.fixed.find(c => c.name === 'operations')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.fixed.find(c => c.name === 'operations')?.unit as CostUnit
                },
                other: {
                    value: Number(costs?.fixed.find(c => c.name === 'other')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.fixed.find(c => c.name === 'other')?.unit as CostUnit
                }
            };

            const operationalCost: OperationalCost = {
                additives: {
                    value: Number(costs?.operational.find(c => c.name === 'additives')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'additives')?.unit as CostUnit
                },
                cabinHeating: {
                    value: Number(costs?.operational.find(c => c.name === 'cabinHeating')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'cabinHeating')?.unit as CostUnit
                },
                collisionInsurance: {
                    value: Number(costs?.operational.find(c => c.name === 'collisionInsurance')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'collisionInsurance')?.unit as CostUnit
                },
                fuel: {
                    value: Number(costs?.operational.find(c => c.name === 'fuel')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'fuel')?.unit as CostUnit
                },
                lngCng: {
                    value: Number(costs?.operational.find(c => c.name === 'lngCng')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'lngCng')?.unit as CostUnit
                },
                electro: {
                    value: Number(costs?.operational.find(c => c.name === 'electro')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'electro')?.unit as CostUnit
                },
                liabilityInsurance: {
                    value: Number(costs?.operational.find(c => c.name === 'liabilityInsurance')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'liabilityInsurance')?.unit as CostUnit
                },
                maintenance: {
                    value: Number(costs?.operational.find(c => c.name === 'maintenance')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'maintenance')?.unit as CostUnit
                },
                personalInjuryInsurance: {
                    value: Number(costs?.operational.find(c => c.name === 'personalInjuryInsurance')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'personalInjuryInsurance')?.unit as CostUnit
                },
                oil: {
                    value: Number(costs?.operational.find(c => c.name === 'oil')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'oil')?.unit as CostUnit
                },
                parking: {
                    value: Number(costs?.operational.find(c => c.name === 'parking')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'parking')?.unit as CostUnit
                },
                telecomunicationsAndMonitoring: {
                    value: Number(costs?.operational.find(c => c.name === 'telecomunicationsAndMonitoring')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'telecomunicationsAndMonitoring')?.unit as CostUnit
                },
                tires: {
                    value: Number(costs?.operational.find(c => c.name === 'tires')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'tires')?.unit as CostUnit
                },
                washing: {
                    value: Number(costs?.operational.find(c => c.name === 'washing')?.value),
                    currency: this.props.currency ?? AvailableCurrencies.EUR,
                    unit: costs?.operational.find(c => c.name === 'washing')?.unit as CostUnit
                }
            };
            this.props.onSubmit?.(fixedCost, operationalCost);
        }
    };
}

export default withTranslation()(CompanyProfileCosts);
