/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyDiemsRatesCurrency
 */
export interface ReadOnlyDiemsRatesCurrency {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRatesCurrency
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRatesCurrency
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRatesCurrency
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRatesCurrency
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRatesCurrency
     */
    minorUnit?: number | null;
}

export function ReadOnlyDiemsRatesCurrencyFromJSON(json: any): ReadOnlyDiemsRatesCurrency {
    return ReadOnlyDiemsRatesCurrencyFromJSONTyped(json, false);
}

export function ReadOnlyDiemsRatesCurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyDiemsRatesCurrency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'code': json['code'],
        'number': json['number'],
        'minorUnit': !exists(json, 'minor_unit') ? undefined : json['minor_unit'],
    };
}

export function ReadOnlyDiemsRatesCurrencyToJSON(value?: ReadOnlyDiemsRatesCurrency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'code': value.code,
        'number': value.number,
        'minor_unit': value.minorUnit,
    };
}


