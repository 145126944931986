import moment from 'moment';
import { google } from 'google-maps';
import { FuelStatisticsEntryMonitoredObject, TripInDatabase } from 'generated/backend-api';
import { DATE_TIME_FORMAT, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { ExpenseSource, ExpenseType } from 'generated/graphql';

import { Role } from '../auth';
import { Logic } from '../logic';
import { FraudDetectionState } from './statistics-expenses';
import { AddressIdentification, VehicleIdentification } from '../../common/components/Settings';
import {
    ActivityModel,
    JourneysModel,
    JourneysSumModel,
    JourneysWithSumModel
} from 'modules/statistics/modules/journeys/JourneysModule';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { ExpensesModel } from 'modules/statistics/modules/expenses/ui/ExpensesTable';
import { Alarm } from 'common/model/alarm';
import { MonitoredObjectWithPairedTrailers, ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { LatLng } from 'common/model/geo';
import { debounce } from '../../debounce';
import { toAddress } from 'common/utils/address';
import { DateRange } from 'common/model/date-time';

export interface Filter {
    driversChecked: string[];
    vehiclesChecked: string[];
}

interface ExpenseDetail {
    fraudDetection: FraudDetectionState;
    type?: string;
    source?: ExpenseSource;
    priceTotalWithVAT: number;
    currency: string;
    quantityTotal: number;
}

interface ExpenseRefueled {
    price: number;
    units: number;
    currency: string;
    date: string;
    counted: boolean;
}

type JourneysFilter = {
    driver?: string;
    trailer?: string;
    vehicle?: string;
    dateRange: {
        start: string;
        end: string;
    };
};

// TODO: Refactor this mess after Boris
export class StatisticsJourneysLogic {
    private _rawData: TripInDatabase[];
    private _vehicles: ReadOnlyMonitoredObjectFeSb[];
    private _addressIdentification?: AddressIdentification;
    private _google: google;
    private _onJourneysChange?: (data: JourneysWithSumModel) => void;
    private _onVehicleIdentificationChange?: (identification: VehicleIdentification) => void;
    private _pointInfoboxTimeout?: NodeJS.Timeout;

    constructor(private _logic: Logic) {
        this._vehicles = [];
        this._rawData = [];
        this._addressIdentification = _logic.settings().getProp('addressIdentification');
        this._google = (window as any).google;
    }

    onJourneysChange(cb: (data: JourneysWithSumModel) => void) {
        this._onJourneysChange = cb;
    }

    onVehicleIdentificationChange(cb: (identification: VehicleIdentification) => void) {
        this._onVehicleIdentificationChange = cb;
    }

    settings(): {
        filter: {
            driver: string;
            vehicle: string;
            trailer: string;
        };
        expanded: boolean;
    } {
        const settings = this._logic.settings().getProp('statistics').journeysActivity;
        const filter = {
            driver: settings.filter.driver,
            vehicle: settings.filter.vehicle,
            trailer: settings.filter.trailer
        };
        return { filter, expanded: settings.expanded };
    }

    setSettings(settings: { driver?: string; vehicle?: string; trailer?: string; expanded?: boolean }) {
        const originalSettings = this._logic.settings().getProp('statistics').journeysActivity;
        const modifiedSettings = {
            filter: {
                driver: settings.driver ?? originalSettings.filter.driver,
                vehicle: settings.vehicle ?? originalSettings.filter.vehicle,
                trailer: settings.trailer ?? originalSettings.filter.trailer
            },
            expanded: settings.expanded ?? originalSettings.expanded,
            disabledCoachPromo: originalSettings.disabledCoachPromo
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProps().statistics,
            journeysActivity: modifiedSettings
        });
        // this._logic.notification().send(
        //     {
        //         type: 'settings',
        //         data: this._logic.settings().getProps()
        //     },
        //     this._logic.auth().user().id
        // );
    }

    init(padding?: google.maps.Padding) {
        this._logic
            .map()
            .routing()
            .init(padding ?? this._logic.map().getPadding());
        this._addressIdentification = this._logic.settings().getProp('addressIdentification');
        this._logic.map().routing().renderJourneyControls();
        this._logic.settings().onChange(prop => {
            if (prop.vehicleIdentification) {
                this._onVehicleIdentificationChange?.(prop.vehicleIdentification);
            }
        });

        this._logic
            .map()
            .routing()
            .onPolylineMouseMove((polyline, _route, latLng) => {
                this._pointInfoboxTimeout = this.renderPointInfobox(polyline, latLng);
            });

        this._logic
            .map()
            .routing()
            .onPolylineMouseOut(() => {
                this.destroyPointInfo();
            });
    }

    private async _journeysDemo(filter: JourneysFilter): Promise<JourneysWithSumModel> {
        this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.JA_R]);

        this._rawData = this._logic
            .demo()
            .data.trips.filter(t => t.drivers && t.drivers.length > 0)
            .filter(
                t =>
                    moment(t.startTime).isAfter(moment(filter.dateRange.start, 'DD.MM.YYYY HH:mm')) &&
                    moment(t.endTime).isBefore(moment(filter.dateRange.end, 'DD.MM.YYYY HH:mm'))
            )
            .filter(e => {
                let result = true;

                if (filter.vehicle) {
                    result = String(e.monitoredObjectId) === String(filter.vehicle);
                }

                if (filter.driver && result) {
                    result = e?.drivers?.some(d => d.id === Number(filter.driver)) ?? false;
                    if (!result) {
                        result = e?.passengers?.some(d => d.id === Number(filter.driver)) ?? false;
                    }
                }

                if (filter.trailer !== undefined && filter.trailer !== '' && result) {
                    result = String(e?.trailer?.monitoredObjectId) === filter.trailer;
                }

                return result;
            });

        const groupedJourneys = this._toGroupedJourneysModel(this._rawData).reverse() ?? [];
        // TODO : TRIPS sumary...
        const journeysSum: JourneysSumModel = {
            duration: 0,
            afcL100Km: 0,
            consumption: 0,
            distance: 0,
            odometerEnd: 0,
            stopped: 0,
            co2Emission: 0
        };

        return { journeys: groupedJourneys, sum: journeysSum, anyDwlTripsFilteredOut: false };
    }

    async journeysWithSum(filter: JourneysFilter) {
        if (this._logic.demo().isActive) {
            return this._journeysDemo(filter);
        }
        return this._journeysWithSum(filter);
    }

    private async _journeysWithSum(filter: JourneysFilter): Promise<JourneysWithSumModel> {
        try {
            const requestFilter = { ...this.settings().filter, ...filter };
            const variables = {
                filter: {
                    startTime: moment(requestFilter.dateRange.start, DATE_TIME_FORMAT).toDate(),
                    endTime: moment(requestFilter.dateRange.end, DATE_TIME_FORMAT).toDate()
                },
                userId: filter.driver ? Number(filter.driver) : undefined,
                monitoredObjectId: filter.vehicle ? filter.vehicle : undefined,
                trailerId: filter.trailer ? Number(filter.trailer) : undefined
            };

            this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.JA_R]);
            const res = await this._logic.api().tripApi.getTripsApiV2TripsGet({
                dateFrom: variables.filter.startTime,
                dateTo: variables.filter.endTime,
                monitoredObjectId: variables.monitoredObjectId,
                driverId: variables.userId,
                trailerId: variables.trailerId,
                wrapArray: true,
                sort: 1,
                limit: INFINITY_PAGE_LIMIT
            });

            this._rawData =
                res.trips
                    .filter(r => r)
                    .map(t =>
                        t.isPrivate &&
                        this._vehicles.find(v => v.id === Number(t.monitoredObjectId))?.fleetType ===
                            FleetType.VEHICLE.toString()
                            ? { ...t, isPrivate: false }
                            : t
                    ) ?? []; // map all yourneys for VEHICLE "TRUCK" isnt private
            const groupedJourneys = this._toGroupedJourneysModel(this._rawData).reverse() ?? [];

            const journeysSum: JourneysSumModel = {
                duration: res.summary.durationSeconds ?? 0,
                afcL100Km: res.summary.consumptionLiters100km ?? 0,
                consumption: res.summary.consumptionLiters ?? 0,
                distance: res.summary.distanceMeters ?? 0,
                odometerEnd: res.summary.odometerEndMeters ?? 0,
                stopped: res.summary.stoppedTime ?? 0,
                co2Emission: res.summary.co2Emission ?? 0
            };

            return {
                journeys: groupedJourneys,
                sum: journeysSum,
                anyDwlTripsFilteredOut: res.anyDwlTripsFilteredOut ?? false
            };
        } catch (err) {
            console.error('Get trips err', err);
            throw err;
        }
    }

    async getPairedTrailers(
        monitoredObjectIds: string[],
        start: string,
        end: string
    ): Promise<Array<MonitoredObjectWithPairedTrailers>> {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.pairedTrailers;
        }

        const res = await this._logic
            .api()
            .monitoredObjectToMonitoredObjectApi.monitoredObjectToMonitoredObjectGetPairedTrailers({
                primaryMonitoredObjectIn: monitoredObjectIds.length > 0 ? monitoredObjectIds.join(',') : undefined,
                validFromLte: start,
                validToGte: end
            });
        return res;
    }

    async editJourneys(tripIds: string[], isPrivate: boolean): Promise<Boolean> {
        try {
            if (tripIds.length > 0) {
                const res = await this._logic.api().tripApi.editTripsV2TripsEditPost({
                    bodyEditTripsV2TripsEditPost: {
                        tripIds,
                        edit: {
                            isPrivate: isPrivate
                        }
                    }
                });

                return res ? true : false;
            }
            return false;
        } catch (err) {
            console.error('Edit trips err', err);
            throw err;
        }
    }

    selectPolyline(tripId: string): void {
        const polyline = this._rawData.find(d => d.id === tripId)?.metadata?.polyline;
        if (polyline) {
            this._logic.map().routing().selectPolylineReal(polyline);
        } else {
            this._logic.map().routing().unselectPolylinesReal();
        }
    }

    getTripId(polyline: string): string | undefined {
        const route = this._rawData.find(d => {
            if (d.metadata?.polyline) {
                return d.metadata.polyline === polyline;
            }
            return false;
        });
        if (route?.id) {
            return route.id;
        }
        return undefined;
    }

    warmPointInfo(activityIntervalIds: string[]) {
        if (this._logic.demo().isActive) {
            return;
        }
        if (activityIntervalIds.length > 0 && activityIntervalIds.some(ai => !!ai)) {
            this._logic.api().pointInfoApi.warmV1GraphApiWarmGet({
                activityIntervalIds
            });
        }
    }

    async getJourneyGraphData(monitoredObjectId: string, activityIntervalIds: string[]) {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.journeyGraphData;
        }

        const res = await this._logic
            .api()
            .journeyGraphApi.getGraphInfoV1GraphApiLegDataGet({ monitoredObjectId, activityIntervalIds });

        return res;
    }

    async getTemperatureData(dateRange: DateRange, serialNumbers: string[]) {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.temperatureSensorData.filter(d => serialNumbers.includes(d.serialNumber));
        }

        try {
            const res = await this._logic
                .api()
                .journeyGraphApi.getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet({
                    timestampFrom: moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                    timestampTo: moment(dateRange.end, DATE_TIME_FORMAT).toDate(),
                    serialNumbers
                });
            return res;
        } catch (err) {
            console.error(`Failed to fetch temperature sensors data (temperatures), err: ${err}`);
            throw err;
        }
    }

    renderPointInfobox = debounce(async (polyline: google.maps.Polyline, latLng: LatLng) => {
        const trip = this._rawData.find(trip => {
            return trip.metadata?.polyline === this._google.maps.geometry.encoding.encodePath(polyline.getPath());
        });
        if (trip?.monitoredObjectId && trip.activityIntervalId) {
            try {
                if (this._logic.demo().isActive) {
                    return;
                }

                const pointInfoData = await this._logic.api().pointInfoApi.getPointInfoV1GraphApiPointInfoGet({
                    lat: latLng.lat,
                    lon: latLng.lng,
                    activityIntervalId: trip.activityIntervalId ?? '',
                    monitoredObjectId: trip.monitoredObjectId
                });

                // BE can return null but api did not generate it
                if (pointInfoData) {
                    this.destroyPointInfo();
                    this._logic
                        .map()
                        .routing()
                        .renderPointInfobox(latLng, {
                            ...pointInfoData,
                            addressString: toAddress(
                                this._logic.auth().user().lang,
                                this._logic.auth().client()!,
                                pointInfoData.address,
                                AddressIdentification.Address,
                                'N/A'
                            )
                        });
                }
            } catch (err) {
                this.destroyPointInfo();
                console.error(`could not load point info, err:${err}`);
            }
        }
    }, 200);

    destroyPointInfo() {
        if (this._pointInfoboxTimeout) {
            clearTimeout(this._pointInfoboxTimeout);
        }
        this._logic.map().routing().destroyPointInfobox();
    }

    renderRoutesOnMap(ids: string[], alarms?: Alarm[]): void {
        const trips = this._rawData.filter(
            d =>
                ids.includes(d.id!) &&
                !d.isPrivate &&
                d.placeStart?.lat &&
                d.placeStart?.lon &&
                d.placeEnd?.lat &&
                d.placeEnd?.lon &&
                d.metadata?.polyline
        );

        if (trips) {
            if (trips.length > 0) {
                this._logic
                    .map()
                    .routing()
                    .renderJourneysActivityRoutes(
                        trips.map(trip => [
                            {
                                lat: trip.placeStart!.lat!,
                                lng: trip.placeStart!.lon!
                            },
                            {
                                lat: trip.placeEnd!.lat!,
                                lng: trip.placeEnd!.lon!
                            }
                        ]),
                        trips.map(trip => trip.metadata!.polyline!),
                        trips[trips?.length - 1].closed!,
                        alarms?.filter(a => a.lastGpsPointStartObj?.lat && a.lastGpsPointEndObj?.lng)
                    );
            }
        }
    }

    renderJourneysControls(): void {
        this._logic.map().addControls(this._google.maps.ControlPosition.TOP_CENTER, []);
    }

    destroyRouteOnMap(): void {
        this._logic.map().routing().destroy();
    }

    destroy(): void {
        this.destroyRouteOnMap();
        this._logic.map().sideBarControlsOffResetState();
        this._logic.map().routing().removeJourneyControls();
        this._logic.map().routing().removeAlarmMarkers();
    }

    private _toActivityModel = (journeysActivity: TripInDatabase): ActivityModel => {
        const vehicle = this._vehicles.find(v => String(v.id) === journeysActivity?.monitoredObjectId);

        return {
            activityIntervalId: journeysActivity.activityIntervalId ?? '',
            id: journeysActivity.id ?? '',
            monitoredObjectId: journeysActivity.monitoredObjectId ?? '',
            idNew: journeysActivity.idNew ?? '',
            vehicle: vehicle?.registrationNumber ?? '',
            monitoredObjectType: (vehicle?.fleetType as FleetType) ?? FleetType.VEHICLE,
            monitoredObjectRoles: (vehicle?.roles as Role[]) ?? [],
            startTime: journeysActivity.startTime
                ? moment(moment.utc(moment(journeysActivity.startTime).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : '',
            endTime: journeysActivity.endTime
                ? moment(moment.utc(moment(journeysActivity.endTime).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : '',
            isPrivate: journeysActivity.isPrivate ?? false,
            placeStart: {
                lat: '',
                lon: '',
                name: journeysActivity.placeStart?.name ?? '',
                addressStructured: journeysActivity.placeStart?.addressStructured
            },
            placeEnd: {
                lat: '',
                lon: '',
                name: journeysActivity.placeEnd?.name ?? '',
                addressStructured: journeysActivity.placeEnd?.addressStructured
            },
            closed: journeysActivity.closed ?? true,
            driver: journeysActivity.drivers
                ? journeysActivity.drivers.map(({ name, surname }) => `${name} ${surname}`).join(', ')
                : '',
            driver2: journeysActivity.passengers
                ? journeysActivity.passengers.map(({ name, surname }) => `${name} ${surname}`).join(', ')
                : '',
            type: 'B', // TODO: fix to real data
            duration: journeysActivity.duration ?? 0,
            distance: journeysActivity.distance ?? 0,
            odometerEnd: journeysActivity.odometerEnd ?? 0,
            odometerStart: journeysActivity.odometerStart ?? 0,
            afcL100Km: journeysActivity.consumptionLiters100km ?? 0,
            consumption: journeysActivity.totalConsumption ?? 0,
            co2Emission: journeysActivity.co2Emission ?? 0,
            units: 0,
            priceFormattedString: '',
            stopped: 0,
            expensesCount: 0,
            fraudsDetected: 0,
            fraudsQuestioned: 0,
            trailerRn: journeysActivity.trailer?.registrationNumber ?? '',
            temperatureSensors: journeysActivity.temperatureSensors,
            temperatureSensorsInstalled: journeysActivity.temperatureSensorsInstalled,
            odometerSnapshotEndTimestamp: journeysActivity.odometerSnapshotEndTimestamp
                ? moment(
                      moment
                          .utc(moment(journeysActivity.odometerSnapshotEndTimestamp).format('YYYY-MM-DD HH:mm:ss'))
                          .toDate()
                  )
                      .local()
                      .toISOString()
                : ''
        };
    };

    private _toGroupedJourneysModel = (journeysActivities: TripInDatabase[]): JourneysModel[] => {
        const activities: ActivityModel[] = journeysActivities.map(this._toActivityModel);

        const activitiesWithStopped: ActivityModel[] = activities.map((a, i) => {
            return {
                ...a,
                stopped: activities[i + 1]
                    ? Number(moment.utc(activities[i + 1].startTime).format('X')) -
                      Number(moment.utc(activities[i].endTime).format('X'))
                    : 0
            };
        });

        const groupedData: {
            [key: string]: ActivityModel[];
        } = activitiesWithStopped.reduce((a, v) => {
            const key = moment(v.startTime).local().startOf('day').toString();

            if (!a[key]) {
                a[key] = [];
            }
            a[key].push(v);

            return a;
        }, {});

        function sum<T extends JourneysSumModel, K extends keyof T>(values: T[], key: K): number {
            return values
                .map(v => v[key])
                ?.reduce((sum, num: any) => {
                    return sum + (num ?? 0);
                }, 0);
        }

        function avg<T extends JourneysSumModel, K extends keyof T>(values: T[], key: K): number {
            return sum(values, key) / values.length;
        }
        function sumAvgWeight<T extends JourneysSumModel, K extends keyof T>(values: T[], key: K, weightK: K): number {
            return values.reduce((sum, num: any) => {
                return sum + (num[key] ?? 0) * (num[weightK] ?? 0);
            }, 0);
        }

        function max<T extends JourneysSumModel, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((a, b: any) => Math.max(a, b), 0);
        }

        const transformedData: JourneysModel[] = Object.keys(groupedData).map((key): JourneysModel => {
            const activitiesNotPrivate: ActivityModel[] = groupedData[key].filter(a => a.isPrivate === false);
            const activitiesIsPrivate: ActivityModel[] = groupedData[key].filter(a => a.isPrivate === true);
            const privateActivity: ActivityModel | undefined =
                activitiesIsPrivate.length > 0
                    ? {
                          activityIntervalId: activitiesIsPrivate.map(a => a.activityIntervalId).toString() ?? '',
                          id: activitiesIsPrivate.map(a => a.id).toString() ?? '',
                          idNew: activitiesIsPrivate.map(a => a.idNew).toString() ?? '',
                          vehicle: activitiesIsPrivate[0]?.vehicle ?? '',
                          monitoredObjectId: activitiesIsPrivate[0]?.monitoredObjectId,
                          monitoredObjectType: activitiesIsPrivate[0]?.monitoredObjectType,
                          monitoredObjectRoles: activitiesIsPrivate[0]?.monitoredObjectRoles,
                          startTime: activitiesIsPrivate[0]?.startTime,
                          endTime: activitiesIsPrivate[0]?.endTime,
                          odometerSnapshotEndTimestamp: activitiesIsPrivate[0]?.odometerSnapshotEndTimestamp,
                          isPrivate: activitiesIsPrivate[0]?.isPrivate,
                          placeStart: {
                              lat: '',
                              lon: '',
                              name: activitiesIsPrivate[0]?.placeStart.name,
                              addressStructured: activitiesIsPrivate[0]?.placeStart.addressStructured
                          },
                          placeEnd: {
                              lat: '',
                              lon: '',
                              name: activitiesIsPrivate[activitiesIsPrivate.length - 1]?.placeEnd.name,
                              addressStructured: activitiesIsPrivate[0]?.placeEnd.addressStructured
                          },
                          closed: activitiesIsPrivate[0]?.closed,
                          driver: activitiesIsPrivate[0]?.driver,
                          driver2: activitiesIsPrivate[0]?.driver2,
                          type: activitiesIsPrivate[0]?.type,
                          duration: sum(activitiesIsPrivate, 'duration'),
                          distance: sum(activitiesIsPrivate, 'distance'),
                          odometerEnd: activitiesIsPrivate[activitiesIsPrivate.length - 1]?.odometerEnd,
                          odometerStart: activitiesIsPrivate[0]?.odometerStart,
                          afcL100Km: avg(activitiesIsPrivate, 'afcL100Km'),
                          consumption: sum(activitiesIsPrivate, 'consumption'),
                          co2Emission: sum(activitiesIsPrivate, 'co2Emission'),
                          units: 0,
                          priceFormattedString: '',
                          stopped: 0,
                          expensesCount: 0,
                          fraudsDetected: 0,
                          fraudsQuestioned: 0,
                          trailerRn: activitiesIsPrivate[0].trailerRn ?? ''
                      }
                    : undefined;
            const activities = privateActivity ? [...activitiesNotPrivate, privateActivity] : activitiesNotPrivate;
            return {
                id: `${groupedData[key][0].vehicle}-${key}`,
                date: key,
                startTime: groupedData[key][0].startTime,
                endTime: groupedData[key][groupedData[key].length - 1].endTime,
                monitoredObjectId: groupedData[key][0].monitoredObjectId,
                alertsCount: 0,
                expensesCount: 0,
                activitiesCount: activities.length,
                fraudsDetected: 0,
                fraudsQuestioned: 0,
                expanded: false,
                activities: activities,
                temperatureSensors: groupedData[key][0].temperatureSensors,
                sum: {
                    duration: sum(activities, 'duration'),
                    distance: sum(activities, 'distance'),
                    odometerEnd: max(activities, 'odometerEnd'),
                    afcL100Km: sumAvgWeight(activities, 'distance', 'afcL100Km') / sum(activities, 'distance'),
                    consumption: avg(activities, 'consumption'),
                    price: 0,
                    stopped: sum(activities, 'stopped'),
                    co2Emission: sum(activities, 'co2Emission')
                },
                privateSum:
                    activitiesIsPrivate.length > 0
                        ? {
                              duration: sum(activitiesIsPrivate, 'duration'),
                              distance: sum(activitiesIsPrivate, 'distance'),
                              odometerEnd: max(activitiesIsPrivate, 'odometerEnd'),
                              afcL100Km:
                                  sumAvgWeight(activitiesIsPrivate, 'distance', 'afcL100Km') /
                                  sum(activities, 'distance'),
                              consumption: avg(activitiesIsPrivate, 'consumption'),
                              price: 0,
                              stopped: sum(activitiesIsPrivate, 'stopped'),
                              co2Emission: sum(activitiesIsPrivate, 'co2Emission')
                          }
                        : undefined
            };
        });

        return transformedData;
    };

    private _toSumJourneysModel = (
        groupedJourneys: JourneysModel[],
        fuelConsumption: { afcL100Km: number; consumption: number }
    ): JourneysSumModel => {
        function sum<T extends JourneysSumModel, K extends keyof T>(values: T[], key: K): number {
            return values
                .map(v => v[key])
                ?.reduce((sum, num: any) => {
                    return sum + (num ?? 0);
                }, 0);
        }

        function max<T extends JourneysSumModel, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((a, b: any) => Math.max(a, b), 0);
        }

        const reducedSum = groupedJourneys.map(j => j.sum!);
        const reducedPrivateSum = groupedJourneys.map(j => j.privateSum!).filter(j => j);
        const transformedData: JourneysSumModel = {
            duration: sum(reducedSum, 'duration'),
            distance: sum(reducedSum, 'distance'),
            units: sum(reducedSum, 'units'),
            price: sum(reducedSum, 'price'),
            odometerEnd: max(reducedSum, 'odometerEnd'),
            afcL100Km: fuelConsumption.afcL100Km,
            consumption: fuelConsumption.consumption,
            stopped: sum(reducedSum, 'stopped'),
            co2Emission: sum(reducedSum, 'co2Emission'),
            privateDistance: reducedPrivateSum.length > 0 ? sum(reducedPrivateSum, 'distance') : 0,
            businessDistance:
                reducedPrivateSum.length > 0
                    ? sum(reducedSum, 'distance') - sum(reducedPrivateSum, 'distance')
                    : sum(reducedSum, 'distance')
        };
        return transformedData;
    };

    private _activitiesFrauds = (journeys: JourneysModel[], expenses: ExpensesModel[]): JourneysModel[] => {
        function sum<T extends ExpenseRefueled, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((sum, num: any) => sum + num, 0);
        }

        journeys.forEach(j => {
            const expense = expenses.filter(expense => moment(j.date).isSame(expense.main.date, 'day'))[0];
            let expenseSum: ExpenseRefueled[] =
                expense?.details
                    ?.filter(d => d.type === ExpenseType.Fuel)
                    .map(a => ({
                        price: a.priceTotalWithVAT,
                        units: a.quantityTotal,
                        currency: a.cargoCurrency,
                        date: a.date,
                        counted: false
                    })) ?? [];

            j.activities.forEach((a, i) => {
                a.expensesCount =
                    expenseSum.filter(
                        e => !e.counted && (moment(e.date).isBefore(a.endTime) || i === j.activities.length - 1)
                    ).length ?? 0;

                a.fraudsQuestioned =
                    expense?.details?.filter(
                        d =>
                            d.fraudDetection !== FraudDetectionState.FRAUD_DETECTED &&
                            d.fraudDetection !== FraudDetectionState.NO_FRAUD_DETECTED &&
                            (d.type === ExpenseType.Fuel || d.type === ExpenseType.Adblue) &&
                            d.source !== ExpenseSource.Manual &&
                            moment(d.date).isAfter(a.startTime) &&
                            moment(d.date).isBefore(a.endTime)
                    ).length ?? 0;

                a.fraudsDetected =
                    expense?.details?.filter(
                        d =>
                            d.fraudDetection === FraudDetectionState.FRAUD_DETECTED &&
                            (d.type === ExpenseType.Fuel || d.type === ExpenseType.Adblue) &&
                            d.source !== ExpenseSource.Manual &&
                            moment(d.date).isAfter(a.startTime) &&
                            moment(d.date).isBefore(a.endTime)
                    ).length ?? 0;
                a.units = sum(
                    expenseSum.filter(
                        e => !e.counted && (moment(e.date).isBefore(a.endTime) || i === j.activities.length - 1)
                    ) ?? [],
                    'units'
                );
                a.priceFormattedString = expenseSum
                    .filter(e => !e.counted && (moment(e.date).isBefore(a.endTime) || i === j.activities.length - 1))
                    .map(expense => `${expense.price} ${expense.currency}`)
                    .join(', ');

                expenseSum = expenseSum.map(e => ({
                    ...e,
                    counted: moment(e.date).isBefore(a.endTime) ? true : false
                }));
            });
            j.sum.price = expense?.main?.priceTotalWithVAT;
            j.sum.units = expense?.main?.quantityTotal;
        });
        return journeys;
    };

    expenseSumData(
        journeys: JourneysModel[],
        journeysSum: JourneysSumModel,
        expenses: ExpensesModel[]
    ): { journeys: JourneysModel[]; journeysSum: JourneysSumModel } {
        journeys = this._activitiesFrauds(journeys, expenses);

        const journeysTransformedData: JourneysModel[] = journeys.map(journey => {
            const expense = expenses.filter(expense => moment(journey.date).isSame(expense.main.date, 'day'))[0];
            const expenseDetail: ExpenseDetail[] =
                expense?.details?.map(i => ({
                    fraudDetection: i.fraudDetection,
                    currency: i.cargoCurrency,
                    type: i.type,
                    source: i.source,
                    priceTotalWithVAT: i.priceTotalWithVAT,
                    quantityTotal: i.quantityTotal
                })) ?? [];
            return {
                ...journey,
                expensesCount: expenseDetail.filter(f => f.type === ExpenseType.Fuel).length,
                fraudsDetected: expenseDetail.filter(
                    f =>
                        f.fraudDetection === FraudDetectionState.FRAUD_DETECTED &&
                        (f.type === ExpenseType.Fuel || f.type === ExpenseType.Adblue) &&
                        f.source !== ExpenseSource.Manual
                ).length,
                fraudsQuestioned: expenseDetail.filter(
                    f =>
                        f.fraudDetection !== FraudDetectionState.FRAUD_DETECTED &&
                        f.fraudDetection !== FraudDetectionState.NO_FRAUD_DETECTED &&
                        (f.type === ExpenseType.Fuel || f.type === ExpenseType.Adblue) &&
                        f.source !== ExpenseSource.Manual
                ).length
            };
        });

        const result = {
            journeys: journeysTransformedData,
            journeysSum: {
                ...journeysSum,
                price: this.sumJourneysSum(expenses, 'priceTotalWithVAT'),
                units: this.sumJourneysSum(expenses, 'quantityTotal')
            }
        };

        return result;
    }

    sumConsumption = <T extends FuelStatisticsEntryMonitoredObject, K extends keyof T>(
        values: T[],
        key: T[K] extends number ? K : never
    ): number => {
        return values.map(v => v[key]).reduce((sum, num) => sum + Number(num), 0);
    };

    sumActivities<T extends ActivityModel, K extends keyof T>(values: T[], key: K): number {
        return values
            .map(v => v[key])
            ?.reduce((sum, num: any) => {
                return sum + (num ?? 0);
            }, 0);
    }

    sumJourneys<T extends JourneysModel, K extends keyof T>(values: T[], key: K): number {
        return values
            .map(v => v[key])
            ?.reduce((sum, num: any) => {
                return sum + (num ?? 0);
            }, 0);
    }

    sumJourneysSum<T extends ExpensesModel, K extends keyof ExpensesModel['main']>(values: T[], key: K): number {
        return values
            .map(v => v.main[key])
            ?.reduce((sum, num: any) => {
                return sum + (num ?? 0);
            }, 0);
    }
}
