import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import qa from 'qa-selectors';
import { TrackingModel, MonitoredObjectTypeName } from 'common/model/tracking';
import { Row, Col } from 'antd';
import { Button } from 'common/components';
import { Role } from 'logic/auth';

export enum ControlPanel {
    VEHICLE = 'vehicle',
    FUEL = 'fuel',
    PARKING = 'parking',
    LAYER = 'layer',
    WASH = 'wash',
    POI = 'poi',
    ADD_POI = 'addPoi'
}

export enum ControlPanelIcon {
    VEHICLE = '/img-map/panel-vehicle.svg',
    VEHICLE_SELECTED = '/img-map/panel-vehicle-selected.svg',
    LIGHT_VEHICLE = '/img-map/panel-light-vehicle.svg',
    LIGHT_VEHICLE_SELECTED = '/img-map/panel-light-vehicle-selected.svg',
    FUEL = '/img-map/panel-fuel.svg',
    FUEL_SELECTED = '/img-map/panel-fuel-selected.svg',
    PARKING = '/img-map/panel-parking.svg',
    PARKING_SELECTED = '/img-map/panel-parking-selected.svg',
    LAYER = '/img-map/panel-layer.svg',
    LAYER_SELECTED = '/img-map/panel-layer-selected.svg',
    WASH = '/img-map/panel-wash.svg',
    WASH_SELECTED = '/img-map/panel-wash-selected.svg',
    POI = '/img-map/panel-poi.svg',
    POI_SELECTED = '/img-map/panel-poi-selected.svg',
    ADD_POI = 'addPoi',
    CENTER = '/img-map/center.svg'
}

interface Props extends WithTranslation {
    selected?: ControlPanel;
    vehicle?: TrackingModel;
    fuelLoading: boolean;
    parkingLoading: boolean;
    washLoading: boolean;
    poiLoading: boolean;
    roles: Role[];
    onCancel?: () => void;
    onBarClick?: (panel: ControlPanel) => void;
}

function MapControlsBar(props: Props) {
    function onVehicle(): void {
        if (props.vehicle) {
            props.onBarClick?.(ControlPanel.VEHICLE);
        }
    }

    function onFuel(): void {
        if (!props.fuelLoading) {
            props.onBarClick?.(ControlPanel.FUEL);
        }
    }

    function onParking(): void {
        if (!props.parkingLoading) {
            props.onBarClick?.(ControlPanel.PARKING);
        }
    }

    function onLayer(): void {
        props.onBarClick?.(ControlPanel.LAYER);
    }

    function onPoi(): void {
        props.onBarClick?.(ControlPanel.POI);
    }

    function onWash(): void {
        props.onBarClick?.(ControlPanel.WASH);
    }

    function getPanelBtnCount(): number {
        let count = 3;
        if (props.vehicle) {
            count++;
        }
        if (props.roles.includes(Role.TRVS)) {
            count++;
        }
        if (props.roles.includes(Role.OPOI_R)) {
            count++;
        }
        return count;
    }

    return (
        <div className="map-controls-bar">
            <div className="map-controls-close" data-qa={qa.mapControls.closeButton}>
                <i
                    title={props.t('common.close')}
                    onClick={props.onCancel}
                    className="fas fa-times t-btn t-right t-ripple"
                />
            </div>

            <Row className="map-controls-bar-nav" gutter={0} justify="space-between">
                {props.vehicle && (
                    <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                        <Button
                            type="link"
                            className={cn([
                                'map-bar-vehicle-card',
                                'map-controls-bar-btn',
                                {
                                    selected: props.selected === ControlPanel.VEHICLE
                                }
                            ])}
                            onClick={onVehicle}
                            icon={
                                props.vehicle?.monitoredObjectType?.name === MonitoredObjectTypeName.VEHICLE ? (
                                    <img
                                        src={
                                            props.selected === ControlPanel.VEHICLE
                                                ? ControlPanelIcon.VEHICLE_SELECTED
                                                : ControlPanelIcon.VEHICLE
                                        }
                                        alt={props.t('common.vehicle')}
                                    />
                                ) : (
                                    <img
                                        src={
                                            props.selected === ControlPanel.VEHICLE
                                                ? ControlPanelIcon.LIGHT_VEHICLE_SELECTED
                                                : ControlPanelIcon.LIGHT_VEHICLE
                                        }
                                        alt={props.t('common.vehicle')}
                                    />
                                )
                            }
                        />
                    </Col>
                )}
                <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                    <Button
                        type="link"
                        className={cn([
                            'map-bar-fuel',
                            'map-controls-bar-btn',
                            {
                                selected: props.selected === ControlPanel.FUEL
                            }
                        ])}
                        onClick={onFuel}
                        title={props.t('FuelStationControls.fuelStations')}
                        disabled={props.fuelLoading || !props.roles.includes(Role.FST_R)}
                        icon={
                            <img
                                src={
                                    props.selected === ControlPanel.FUEL
                                        ? ControlPanelIcon.FUEL_SELECTED
                                        : ControlPanelIcon.FUEL
                                }
                                alt={props.t('FuelStationControls.fuelStations')}
                            />
                        }
                    />
                </Col>
                <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                    <Button
                        type="link"
                        className={cn([
                            'map-bar-parking',
                            'map-controls-bar-btn',
                            {
                                selected: props.selected === ControlPanel.PARKING
                            }
                        ])}
                        disabled={props.parkingLoading || !props.roles.includes(Role.FST_R)}
                        onClick={onParking}
                        title={props.t('ParkingControls.parkings')}
                        icon={
                            <img
                                src={
                                    props.selected === ControlPanel.PARKING
                                        ? ControlPanelIcon.PARKING_SELECTED
                                        : ControlPanelIcon.PARKING
                                }
                                alt={props.t('ParkingControls.parkings')}
                            />
                        }
                    />
                </Col>
                {props.roles.includes(Role.TRVS) && (
                    <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                        <Button
                            type="link"
                            className={cn(['map-controls-bar-btn', { selected: props.selected === ControlPanel.WASH }])}
                            onClick={onWash}
                            title={props.t('PoiControls.carWash')}
                            disabled={props.washLoading}
                            icon={
                                <img
                                    src={
                                        props.selected === ControlPanel.WASH
                                            ? ControlPanelIcon.WASH_SELECTED
                                            : ControlPanelIcon.WASH
                                    }
                                    alt={props.t('PoiControls.carWash')}
                                />
                            }
                        />
                    </Col>
                )}
                {props.roles.includes(Role.OPOI_R) && (
                    <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                        <Button
                            type="link"
                            className={cn(['map-controls-bar-btn', { selected: props.selected === ControlPanel.POI }])}
                            onClick={onPoi}
                            title={props.t('PoiControls.customPlaces')}
                            disabled={props.poiLoading}
                            icon={
                                <img
                                    src={
                                        props.selected === ControlPanel.POI
                                            ? ControlPanelIcon.POI_SELECTED
                                            : ControlPanelIcon.POI
                                    }
                                    alt={props.t('PoiControls.customPlaces')}
                                />
                            }
                        />
                    </Col>
                )}
                <Col style={{ width: `calc(100% / ${getPanelBtnCount()})` }}>
                    <Button
                        type="link"
                        className={cn([
                            'map-bar-layer',
                            'map-controls-bar-btn',
                            { selected: props.selected === ControlPanel.LAYER }
                        ])}
                        onClick={onLayer}
                        title={props.t('LayerControls.mapBackgrounds')}
                        icon={
                            <img
                                src={
                                    props.selected === ControlPanel.LAYER
                                        ? ControlPanelIcon.LAYER_SELECTED
                                        : ControlPanelIcon.LAYER
                                }
                                alt={props.t('LayerControls.mapBackgrounds')}
                            />
                        }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default withTranslation()(MapControlsBar);
