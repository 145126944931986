/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AETRActivity,
    AETRActivityFromJSON,
    AETRActivityFromJSONTyped,
    AETRActivityToJSON,
} from './';

/**
 * 
 * @export
 * @interface AETRInterval
 */
export interface AETRInterval {
    /**
     * 
     * @type {AETRActivity}
     * @memberof AETRInterval
     */
    activity: AETRActivity;
    /**
     * 
     * @type {string}
     * @memberof AETRInterval
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    end?: Date;
    /**
     * 
     * @type {string}
     * @memberof AETRInterval
     */
    tachographCard: string;
    /**
     * 
     * @type {string}
     * @memberof AETRInterval
     */
    tachographCardS16?: string;
    /**
     * 
     * @type {AETRActivity}
     * @memberof AETRInterval
     */
    lastActivityRaw: AETRActivity;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    lastActivityRawTimestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof AETRInterval
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    crewStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    crewEnd?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    activityStop?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AETRInterval
     */
    reliable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AETRInterval
     */
    lastCardIn?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AETRInterval
     */
    lastCardInTimestamp?: Date;
}

export function AETRIntervalFromJSON(json: any): AETRInterval {
    return AETRIntervalFromJSONTyped(json, false);
}

export function AETRIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): AETRInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity': AETRActivityFromJSON(json['activity']),
        'monitoredObjectId': json['monitored_object_id'],
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'tachographCard': json['tachograph_card'],
        'tachographCardS16': !exists(json, 'tachograph_card_s16') ? undefined : json['tachograph_card_s16'],
        'lastActivityRaw': AETRActivityFromJSON(json['last_activity_raw']),
        'lastActivityRawTimestamp': (new Date(json['last_activity_raw_timestamp'])),
        'clientId': json['client_id'],
        'crewStart': !exists(json, 'crew_start') ? undefined : (new Date(json['crew_start'])),
        'crewEnd': !exists(json, 'crew_end') ? undefined : (new Date(json['crew_end'])),
        'activityStop': !exists(json, 'activity_stop') ? undefined : (new Date(json['activity_stop'])),
        'reliable': !exists(json, 'reliable') ? undefined : json['reliable'],
        'lastCardIn': !exists(json, 'last_card_in') ? undefined : json['last_card_in'],
        'lastCardInTimestamp': !exists(json, 'last_card_in_timestamp') ? undefined : (new Date(json['last_card_in_timestamp'])),
    };
}

export function AETRIntervalToJSON(value?: AETRInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity': AETRActivityToJSON(value.activity),
        'monitored_object_id': value.monitoredObjectId,
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'tachograph_card': value.tachographCard,
        'tachograph_card_s16': value.tachographCardS16,
        'last_activity_raw': AETRActivityToJSON(value.lastActivityRaw),
        'last_activity_raw_timestamp': (value.lastActivityRawTimestamp.toISOString()),
        'client_id': value.clientId,
        'crew_start': value.crewStart === undefined ? undefined : (value.crewStart.toISOString()),
        'crew_end': value.crewEnd === undefined ? undefined : (value.crewEnd.toISOString()),
        'activity_stop': value.activityStop === undefined ? undefined : (value.activityStop.toISOString()),
        'reliable': value.reliable,
        'last_card_in': value.lastCardIn,
        'last_card_in_timestamp': value.lastCardInTimestamp === undefined ? undefined : (value.lastCardInTimestamp.toISOString()),
    };
}


