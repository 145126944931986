import moment from 'moment';
import { useState } from 'react';

import cn from 'classnames';
import { WithLogic, withLogicContext } from 'App';
import { Button, Table } from 'common/components';
import DiemsBulkForm, { DiemsBulkFormModel } from 'common/forms/DiemsBulkForm/DiemsBulkForm';
import { calculationType, pocketMoneyMapper, replacementMeals } from 'common/model/allowance';
import { DATE_FORMAT } from 'domain-constants';
import { ReadOnlyDiemsRules } from 'generated/new-main/models';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as CommonIcons from 'resources/images/common';
export interface AllowanceRuleTableData {
    key: string;
    dateFrom: {
        editable: boolean;
        value: string;
    };
    dateTo: {
        editable: boolean;
        value: string;
    };
    rule: string;
    audience: string;
    editable: boolean;
    originalData: ReadOnlyDiemsRules;
}

interface Props extends WithLogic, WithTranslation {
    loading: boolean;
    lang: string;
    data: AllowanceRuleTableData[];
    onSubmit: (values: DiemsBulkFormModel, userId?: number | null) => void;
}

function AllowanceRuleTable(props: Props) {
    const [selected, setSelected] = useState<string>('');
    return (
        <Table<AllowanceRuleTableData>
            className="diems-rules-table"
            size="large"
            columns={[
                {
                    title: props.t('common.validFrom'),
                    key: 'dateFrom',
                    width: 120,
                    dataIndex: ['dateFrom', 'value'],
                    align: 'center',
                    render: data => moment(data, DATE_FORMAT).format('L')
                },
                {
                    title: props.t('common.validTo'),
                    key: 'dateTo',
                    width: 120,
                    dataIndex: ['dateTo', 'value'],
                    align: 'center',
                    render: data => moment(data, DATE_FORMAT).format('L')
                },
                {
                    title: props.t('Allowance.ruleTable.rule'),
                    key: 'rule',
                    dataIndex: 'rule',
                    align: 'left'
                },
                {
                    title: props.t('Allowance.ruleTable.audience'),
                    key: 'audience',
                    dataIndex: 'audience',
                    align: 'center'
                }
            ]}
            loading={props.loading}
            dataSource={props.data}
            onRow={record => {
                return {
                    className: cn({
                        'ant-table-row-selected': selected === record.key,
                        'ant-table-row-start-past': !record.dateFrom.editable,
                        'ant-table-row-end-past': !record.dateTo.editable
                    })
                };
            }}
            expandable={{
                expandedRowRender: record =>
                    record.key === selected && (
                        <DiemsBulkForm
                            wide
                            initialValues={{
                                start: moment.utc(record.originalData.start, DATE_FORMAT).format(DATE_FORMAT),
                                end: moment
                                    .utc(record.originalData.end, DATE_FORMAT)
                                    .subtract(1, 'days')
                                    .format(DATE_FORMAT),
                                pocketMoney:
                                    parseInt(
                                        Object.keys(pocketMoneyMapper).find(
                                            key => pocketMoneyMapper[key] === record.originalData.pocketMoney
                                        ) ?? pocketMoneyMapper[0].toString()
                                    ) ?? pocketMoneyMapper[0],
                                replacedMeals: record.originalData.replacedMeals ?? [],
                                calculationType: props.logic
                                    .diems()
                                    .rules()
                                    .getRulesCalculationType(record.originalData),
                                fixedRate: record.originalData.plFixedRates
                                    ? {
                                          domesticRate: record.originalData.plFixedRates.domesticRates[2].rate,
                                          foreignRate: record.originalData.plFixedRates.foreignRates[2].rate
                                      }
                                    : undefined
                            }}
                            demoMode={props.logic.demo().isActive}
                            isPolishDiems={props.logic.auth().newEWClient()?.country?.iso2.toUpperCase() === 'PL'}
                            lang={props.lang}
                            replacementMeals={replacementMeals()}
                            calculationType={calculationType()}
                            closeButtonText={props.t('common.closeAndDiscardChanges')}
                            submitButtonText={props.t('common.saveChanges')}
                            applyRowPadding
                            onCancel={() => setSelected('')}
                            onSubmit={values => props.onSubmit?.(values, record.originalData.user)}
                        />
                    ),
                rowExpandable: record => record.editable,
                expandIcon: props =>
                    props.record.editable && (
                        <Button
                            className={cn({
                                'selected-button': selected === props.record.key
                            })}
                            onClick={e => {
                                setSelected(props.record.key);
                                props.onExpand(props.record, e);
                            }}
                            size="small"
                            type="primary"
                            icon={<img alt="edit" src={CommonIcons.editPencil} />}
                        />
                    ),
                expandedRowKeys: [selected],
                expandIconColumnIndex: 4
            }}
        />
    );
}

export default withTranslation()(withLogicContext(AllowanceRuleTable));
