/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeenMessagesResponse
 */
export interface SeenMessagesResponse {
    /**
     * 
     * @type {number}
     * @memberof SeenMessagesResponse
     */
    seenNo?: number | null;
}

export function SeenMessagesResponseFromJSON(json: any): SeenMessagesResponse {
    return SeenMessagesResponseFromJSONTyped(json, false);
}

export function SeenMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeenMessagesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seenNo': !exists(json, 'seenNo') ? undefined : json['seenNo'],
    };
}

export function SeenMessagesResponseToJSON(value?: SeenMessagesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seenNo': value.seenNo,
    };
}


