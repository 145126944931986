import { TemperatureSensorData, TemperatureSensorDataFromJSON } from 'generated/backend-api-live';
import { GRAPH_FRACTION_SECONDS, temperatureSensors, temperatureStack } from './coldchain';
import { trips } from './trips';
import moment from 'moment';

const temperatureSensorDataRaw = temperatureSensors.map((sensor, index) => {
    const filtered = trips.filter(t => t.monitoredObjectId === String(sensor?.monitoredObject - 100));

    return {
        serial_number: sensor.serialNumber,
        timestamps: new Array(49).fill(null).map((_, index) =>
            moment()
                .subtract((49 - index) * GRAPH_FRACTION_SECONDS, 'seconds')
                .format('X')
        ),
        timestampsTicks: new Array(49).fill(null).map((_, index) =>
            moment()
                .subtract((49 - index) * GRAPH_FRACTION_SECONDS, 'seconds')
                .format('X')
        ),
        temperatures: temperatureStack[[0, 4, 8].includes(index) ? 0 : (index % (temperatureStack.length - 1)) + 1],
        polyline: filtered[filtered.length - 1]?.metadata?.polyline ?? ''
    };
});

export const temperatureSensorData: TemperatureSensorData[] = temperatureSensorDataRaw.map(e =>
    TemperatureSensorDataFromJSON(e)
);
