import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';
import { InputField, SelectField, TextareaField } from 'common/fields';
import { DatePickerField } from 'common/fields';
import { ReadOnlyFuelCompanySerializer } from 'generated/new-main';

export interface FuelCardFormModel {
    number: string;
    holder: string;
    fuelCompany: string;
    expirationDate: string;
    note: string;
}

interface Props extends WithTranslation {
    mode: 'EDIT' | 'CREATE';
    initialValues?: Partial<FuelCardFormModel>;
    fuelCompanies: ReadOnlyFuelCompanySerializer[];
    lang: string;
    demoMode?: boolean;
    onSubmit?: (values: FuelCardFormModel) => Promise<boolean>;
    onCancel?: () => void;
}

function FuelCardsForm(props: Props) {
    const schema = Yup.object().shape({
        number: Yup.string().required(props.t('common.required')),
        holder: Yup.string().required(props.t('common.required')),
        fuelCompany: Yup.string().required(props.t('common.required')),
        expirationDate: Yup.string().required(props.t('common.required')),
        note: Yup.string().max(1024, props.t('validator.maxLength', { max: 1024 }))
    });

    const create = props.mode === 'CREATE';

    function handleOnSubmit(values: FuelCardFormModel) {
        props.onSubmit?.(values);
    }

    return (
        <Formik<FuelCardFormModel>
            initialValues={{
                number: props.initialValues?.number ?? '',
                holder: props.initialValues?.holder ?? '',
                fuelCompany: props.initialValues?.fuelCompany ?? '',
                expirationDate: props.initialValues?.expirationDate ?? '',
                note: props.initialValues?.note ?? ''
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<FuelCardFormModel>) => {
                return (
                    <Form className="fuel-cards-form">
                        <Row className="form-row" gutter={[0, 8]}>
                            <Col span={24}>
                                <InputField
                                    qa={create ? qa.fuel.new.inputNumber : qa.fuel.detail.inputNumber}
                                    name="number"
                                    label={props.t('ManagementFuelCards.number')}
                                    size="large"
                                />
                            </Col>
                            <Col span={24}>
                                <InputField
                                    qa={create ? qa.fuel.new.inputHolder : qa.fuel.detail.inputHolder}
                                    name="holder"
                                    label={props.t('ManagementFuelCards.holderName')}
                                    size="large"
                                />
                            </Col>
                            <Col span={24}>
                                <SelectField
                                    defaultValue={props.initialValues?.fuelCompany}
                                    qa={create ? qa.fuel.new.selectCompany : qa.fuel.detail.selectCompany}
                                    name="fuelCompany"
                                    options={props.fuelCompanies.map(f => ({
                                        label: f.label,
                                        value: String(f.id)
                                    }))}
                                    label={props.t('ManagementFuelCards.fuelCompany')}
                                    size="large"
                                />
                            </Col>
                            <Col span={24}>
                                <DatePickerField
                                    lang={props.lang}
                                    label={props.t('ManagementFuelCards.expirationDate')}
                                    name="expirationDate"
                                    qa={create ? qa.fuel.new.inputExpDate : qa.fuel.detail.inputExpDate}
                                    size="large"
                                    format="L"
                                    disabledDate={d => d.isBefore(moment.utc().subtract(1, 'days'))}
                                />
                            </Col>
                            <Col span={24}>
                                <TextareaField
                                    defaultValue={props.initialValues?.note}
                                    name="note"
                                    qa={create ? qa.fuel.new.inputNote : qa.fuel.detail.inputNote}
                                    label={props.t('UsersTable.note')}
                                />
                            </Col>
                        </Row>

                        <FormActions
                            cancelQa={create ? qa.fuel.new.btnFormCancel : qa.fuel.detail.btnFormCancel}
                            submitQa={create ? qa.fuel.new.btnFormSubmit : qa.fuel.detail.btnFormSubmit}
                            submitLoading={formik.isSubmitting}
                            submitDisabled={props.demoMode}
                            onCancelClick={props.onCancel}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(FuelCardsForm);
