/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeFromJSONTyped,
    MonitoredObjectFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportMonitoredObject
 */
export interface TransportMonitoredObject {
    /**
     * 
     * @type {Date}
     * @memberof TransportMonitoredObject
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportMonitoredObject
     */
    endTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TransportMonitoredObject
     */
    primary?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransportMonitoredObject
     */
    monitoredObjectId?: number;
    /**
     * 
     * @type {MonitoredObjectFleetType}
     * @memberof TransportMonitoredObject
     */
    type?: MonitoredObjectFleetType;
}

export function TransportMonitoredObjectFromJSON(json: any): TransportMonitoredObject {
    return TransportMonitoredObjectFromJSONTyped(json, false);
}

export function TransportMonitoredObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportMonitoredObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'monitoredObjectId': !exists(json, 'monitoredObjectId') ? undefined : json['monitoredObjectId'],
        'type': !exists(json, 'type') ? undefined : MonitoredObjectFleetTypeFromJSON(json['type']),
    };
}

export function TransportMonitoredObjectToJSON(value?: TransportMonitoredObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'primary': value.primary,
        'monitoredObjectId': value.monitoredObjectId,
        'type': MonitoredObjectFleetTypeToJSON(value.type),
    };
}


