/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Number
 */
export interface Number {
    /**
     * 
     * @type {number}
     * @memberof Number
     */
    i: number | null;
}

export function NumberFromJSON(json: any): Number {
    return NumberFromJSONTyped(json, false);
}

export function NumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Number {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'i': json['i'],
    };
}

export function NumberToJSON(value?: Number | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'i': value.i,
    };
}


