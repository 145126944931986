/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    ServiceActualVehicleStateModelsVehicleFuelTank,
    ServiceActualVehicleStateModelsVehicleFuelTankFromJSON,
    ServiceActualVehicleStateModelsVehicleFuelTankFromJSONTyped,
    ServiceActualVehicleStateModelsVehicleFuelTankToJSON,
    ServiceActualVehicleStateModelsVehicleGPSData,
    ServiceActualVehicleStateModelsVehicleGPSDataFromJSON,
    ServiceActualVehicleStateModelsVehicleGPSDataFromJSONTyped,
    ServiceActualVehicleStateModelsVehicleGPSDataToJSON,
    ServiceActualVehicleStateModelsVehicleStateData,
    ServiceActualVehicleStateModelsVehicleStateDataFromJSON,
    ServiceActualVehicleStateModelsVehicleStateDataFromJSONTyped,
    ServiceActualVehicleStateModelsVehicleStateDataToJSON,
    ServiceActualVehicleStateModelsVehicleTransport,
    ServiceActualVehicleStateModelsVehicleTransportFromJSON,
    ServiceActualVehicleStateModelsVehicleTransportFromJSONTyped,
    ServiceActualVehicleStateModelsVehicleTransportToJSON,
    VehicleDriver,
    VehicleDriverFromJSON,
    VehicleDriverFromJSONTyped,
    VehicleDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleStateWithRN
 */
export interface VehicleStateWithRN {
    /**
     * 
     * @type {string}
     * @memberof VehicleStateWithRN
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateWithRN
     */
    address?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleStateWithRN
     */
    lastChangeState?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleStateWithRN
     */
    odometer?: number;
    /**
     * 
     * @type {ServiceActualVehicleStateModelsVehicleGPSData}
     * @memberof VehicleStateWithRN
     */
    gpsData?: ServiceActualVehicleStateModelsVehicleGPSData;
    /**
     * 
     * @type {ServiceActualVehicleStateModelsVehicleStateData}
     * @memberof VehicleStateWithRN
     */
    stateData?: ServiceActualVehicleStateModelsVehicleStateData;
    /**
     * 
     * @type {Array<ServiceActualVehicleStateModelsVehicleFuelTank>}
     * @memberof VehicleStateWithRN
     */
    fuelTanks?: Array<ServiceActualVehicleStateModelsVehicleFuelTank>;
    /**
     * 
     * @type {VehicleDriver}
     * @memberof VehicleStateWithRN
     */
    driver?: VehicleDriver;
    /**
     * 
     * @type {Array<ServiceActualVehicleStateModelsVehicleTransport>}
     * @memberof VehicleStateWithRN
     */
    activeTransports?: Array<ServiceActualVehicleStateModelsVehicleTransport>;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof VehicleStateWithRN
     */
    addressStructured?: Array<AddressStructured>;
    /**
     * 
     * @type {Date}
     * @memberof VehicleStateWithRN
     */
    odometerSnapshotTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateWithRN
     */
    rn?: string;
}

export function VehicleStateWithRNFromJSON(json: any): VehicleStateWithRN {
    return VehicleStateWithRNFromJSONTyped(json, false);
}

export function VehicleStateWithRNFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleStateWithRN {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': !exists(json, 'monitoredObjectId') ? undefined : json['monitoredObjectId'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'lastChangeState': !exists(json, 'last_change_state') ? undefined : (new Date(json['last_change_state'])),
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
        'gpsData': !exists(json, 'gpsData') ? undefined : ServiceActualVehicleStateModelsVehicleGPSDataFromJSON(json['gpsData']),
        'stateData': !exists(json, 'stateData') ? undefined : ServiceActualVehicleStateModelsVehicleStateDataFromJSON(json['stateData']),
        'fuelTanks': !exists(json, 'fuelTanks') ? undefined : ((json['fuelTanks'] as Array<any>).map(ServiceActualVehicleStateModelsVehicleFuelTankFromJSON)),
        'driver': !exists(json, 'driver') ? undefined : VehicleDriverFromJSON(json['driver']),
        'activeTransports': !exists(json, 'activeTransports') ? undefined : ((json['activeTransports'] as Array<any>).map(ServiceActualVehicleStateModelsVehicleTransportFromJSON)),
        'addressStructured': !exists(json, 'address_structured') ? undefined : ((json['address_structured'] as Array<any>).map(AddressStructuredFromJSON)),
        'odometerSnapshotTime': !exists(json, 'odometer_snapshot_time') ? undefined : (new Date(json['odometer_snapshot_time'])),
        'rn': !exists(json, 'rn') ? undefined : json['rn'],
    };
}

export function VehicleStateWithRNToJSON(value?: VehicleStateWithRN | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoredObjectId': value.monitoredObjectId,
        'address': value.address,
        'last_change_state': value.lastChangeState === undefined ? undefined : (value.lastChangeState.toISOString()),
        'odometer': value.odometer,
        'gpsData': ServiceActualVehicleStateModelsVehicleGPSDataToJSON(value.gpsData),
        'stateData': ServiceActualVehicleStateModelsVehicleStateDataToJSON(value.stateData),
        'fuelTanks': value.fuelTanks === undefined ? undefined : ((value.fuelTanks as Array<any>).map(ServiceActualVehicleStateModelsVehicleFuelTankToJSON)),
        'driver': VehicleDriverToJSON(value.driver),
        'activeTransports': value.activeTransports === undefined ? undefined : ((value.activeTransports as Array<any>).map(ServiceActualVehicleStateModelsVehicleTransportToJSON)),
        'address_structured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
        'odometer_snapshot_time': value.odometerSnapshotTime === undefined ? undefined : (value.odometerSnapshotTime.toISOString()),
        'rn': value.rn,
    };
}


