import moment from 'moment';
import { Rate } from 'common/components';
import { roundToStep } from 'common/utils/averages';

export type DriverStatValueFormat =
    | 'litres'
    | 'litresPerKm'
    | 'kmPerHour'
    | 'km'
    | 'duration'
    | 'durationTime'
    | 'percentage'
    | 'percentage100'
    | 'count'
    | 'countPerKm'
    | 'distance'
    | 'stars'
    | 'rpm'
    | 'ratio'
    | 'm'
    | 'mPerKm'
    | 'mPer100Km'
    | 'tonnes'
    | 'tonsPerKm';

export enum DriverStatValueColor {
    BLUE = 'blue',
    LIGHTBLUE = 'lightblue',
    PURPLE = 'purple',
    GREEN = 'green',
    YELLOW = 'yellow'
}

interface Props {
    value?: number;
    unit?: DriverStatValueFormat;
    rateColor?: DriverStatValueColor;
    fixed?: number;
    unitName?: string;
}

export default function DriverStatValue({ value = 0, unit = 'count', rateColor, fixed = 2, unitName }: Props) {
    const step = fixed && fixed > 0 ? Math.pow(10, fixed * -1) : 1;
    const rounded = roundToStep(Number(value), step);
    const duration = moment.duration(value, 's').format('HH:mm:ss', { trim: false });
    const getUnitName = (unitValue?: string) => {
        return unitName ? <span>{unitName}</span> : unitValue ? <span>{unitValue}</span> : '';
    };

    switch (unit) {
        case 'count':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName()}
                </div>
            );
        case 'tonsPerKm':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('t/100km')}
                </div>
            );
        case 'litres':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('l')}
                </div>
            );
        case 'litresPerKm':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('l/100km')}
                </div>
            );
        case 'kmPerHour':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('km/h')}
                </div>
            );
        case 'duration':
            return (
                <div className="driver-behavior-value">
                    <span>{duration.split(':')[0]}</span>
                    <span>h</span>
                    <span>{duration.split(':')[1]}</span>
                    <span>m</span>
                    <span>{duration.split(':')[2]}</span>
                    <span>s</span>
                </div>
            );
        case 'durationTime':
            return (
                <div className="driver-behavior-value">
                    <span>{duration.split(':')[0]}</span>
                    <span>h</span>
                    <span>{duration.split(':')[1]}</span>
                    <span>m</span>
                </div>
            );
        case 'distance':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('km')}
                </div>
            );
        case 'percentage':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('%')}
                </div>
            );
        case 'percentage100':
            return (
                <div className="driver-behavior-value">
                    <span>{(rounded * 100).toFixed(fixed)}</span>
                    {getUnitName('%')}
                </div>
            );
        case 'stars':
            return (
                <div className="driver-behavior-value">
                    <span>{roundToStep(rounded, 0.1)}</span>
                    <Rate size="xsmall" color={rateColor} value={roundToStep(rounded, 0.5)} disabled allowHalf />
                    {getUnitName()}
                </div>
            );
        case 'mPer100Km':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName('m/100km')}
                </div>
            );
        default:
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(fixed)}</span>
                    {getUnitName()}
                </div>
            );
    }
}
