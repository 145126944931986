/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BackendApiApiV1DiemsAddress,
    BackendApiApiV1DiemsAddressFromJSON,
    BackendApiApiV1DiemsAddressFromJSONTyped,
    BackendApiApiV1DiemsAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    date?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Point
     */
    point?: Array<object>;
    /**
     * 
     * @type {Array<BackendApiApiV1DiemsAddress>}
     * @memberof Point
     */
    address?: Array<BackendApiApiV1DiemsAddress>;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'point': !exists(json, 'point') ? undefined : json['point'],
        'address': !exists(json, 'address') ? undefined : ((json['address'] as Array<any>).map(BackendApiApiV1DiemsAddressFromJSON)),
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'date': value.date,
        'point': value.point,
        'address': value.address === undefined ? undefined : ((value.address as Array<any>).map(BackendApiApiV1DiemsAddressToJSON)),
    };
}


