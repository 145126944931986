/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoringDeviceType
 */
export interface WriteOnlyMonitoringDeviceType {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoringDeviceType
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoringDeviceType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoringDeviceType
     */
    externalId: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoringDeviceType
     */
    externalSystem: number;
}

export function WriteOnlyMonitoringDeviceTypeFromJSON(json: any): WriteOnlyMonitoringDeviceType {
    return WriteOnlyMonitoringDeviceTypeFromJSONTyped(json, false);
}

export function WriteOnlyMonitoringDeviceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoringDeviceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'externalId': json['external_id'],
        'externalSystem': json['external_system'],
    };
}

export function WriteOnlyMonitoringDeviceTypeToJSON(value?: WriteOnlyMonitoringDeviceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'name': value.name,
        'external_id': value.externalId,
        'external_system': value.externalSystem,
    };
}


