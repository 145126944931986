import { ChartTheme } from 'common/components/ChartTimeline';

export const LINE_COLORS = {
    primaryLineColor: '#00E1FF',
    secondaryLineColor: '#CE5F00'
};

export const AXIS_COLORS = {
    primary: '#9D9A9A'
};

export const DOT_COLORS = {
    primary: '#ffffff'
};

export const LINE_COLORS_COMPLEX = [
    '#ffb400',
    '#5cdbd3',
    '#8570db',
    '#29abeb',
    '#bae637',
    '#ff85c0',
    '#9831cc',
    '#ebf110',
    '#13683c'
];

export const CHART_THEME_DARK: ChartTheme = {
    style: {
        background: '#252323'
    },
    anotations: {
        background: 'rgba(235, 87, 87, 0.58)'
    },
    label: {
        fill: '#fff',
        fontWeight: 600
    },
    point: {
        infoblock: {
            opacity: 1,
            stroke: '#353637'
        },
        backgroundColor: '#fff'
    },
    tooltip: {
        backgroundColor: '#1a1b1c',
        border: '1px solid #525252',
        borderColor: '#525252',
        color: '#fff',
        padding: '10px',
        boxShadow:
            '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)'
    },
    yAxis: {
        gridLine: {
            stroke: '#353637',
            lineWidth: 1
        },
        line: {
            stroke: '#dadada'
        },
        label: {
            fontFamily: 'ProximaNova',
            fontSize: 12,
            fill: '#dadada'
        }
    },
    xAxis: {
        tickLine: {
            lineWidth: 0
        },
        line: {
            stroke: '#dadada',
            lineWidth: 0
        },
        label: {
            fontFamily: 'ProximaNova',
            fontSize: 12,
            fill: '#dadada'
        }
    }
};

export const CHART_THEME_LIGHT: ChartTheme = {
    style: {
        background: '#fff'
    },
    anotations: {
        background: 'rgba(255,255,255,0.5)'
    },
    point: {
        infoblock: {
            opacity: 1,
            stroke: '#000'
        },
        backgroundColor: '#fff'
    },
    label: {
        fill: '#000',
        fontWeight: 600
    },
    tooltip: {
        backgroundColor: '#fff',
        border: '1px solid #999',
        borderColor: '#999',
        color: '#000',
        padding: '10px',
        boxShadow:
            '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)'
    },
    yAxis: {
        gridLine: {
            stroke: '#999'
        },
        line: {
            stroke: '#212162'
        },
        label: {
            fontFamily: 'ProximaNova',
            fontSize: 12,
            fill: '#212162'
        }
    },
    xAxis: {
        tickLine: {
            lineWidth: 0
        },
        line: {
            stroke: '#212162'
        },
        label: {
            fontFamily: 'ProximaNova',
            fontSize: 12,
            fill: '#212162'
        }
    }
};
