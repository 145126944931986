/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ContentType {
    ApplicationPdf = 'application/pdf'
}

export function ContentTypeFromJSON(json: any): ContentType {
    return ContentTypeFromJSONTyped(json, false);
}

export function ContentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentType {
    return json as ContentType;
}

export function ContentTypeToJSON(value?: ContentType | null): any {
    return value as any;
}

