import { RouteNames } from 'App';
import { debounce } from 'debounce';
import { KM, L, DATE_TIME_FORMAT, EUR, KG, DWL_LOCK, DRIVER_BEHAVIOR_UPGRADE_LINK } from 'domain-constants';
import { Logic } from 'logic/logic';
import { Aggregator, CargoExpense } from 'logic/statistics/statistics-expenses';
import moment, { duration } from 'moment';
import numeral from 'numeral';
import qs from 'qs';
import { Component } from 'react';
import i18n from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Journeys from './ui/Journeys';
import { DriverModel } from 'logic/user/users';
import { Role } from 'logic/auth';
import { fromBlankAsync, Workbook, Style, Cell } from 'xlsx-populate';
import { confDefault } from 'conf';
import { exponea } from 'logic/exponea';
import { message } from 'antd';
import {
    ReadOnlyClientFleetTypeEnum,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyOdometerAdjustment
} from 'generated/new-main';
import { StringValidator } from 'validators';
import { SelectOpt } from 'common/components/AutoComplete';
import { ExpenseType } from 'generated/graphql';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { DateRange } from 'common/model/date-time';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { toAddress, toAddressString } from 'common/utils/address';
import { EWClient } from 'generated/main-data-api';
import { AddressStructured } from 'common/model/address';
import { GraphInfo } from 'generated/backend-api-live';
import { changeToNoSpaceString, search } from 'common/utils/search';
import { TripTemperatureSensor } from 'generated/backend-api';
import { TemperatureSensorData } from 'generated/backend-api-live';
import { downloadFile } from 'common/utils/fileUtils';
import { GraphKey } from './ui/JourneyGraph';
import { AddressIdentification } from 'common/components/Settings';

export interface ExpensesStats {
    date: string;
    fraudDetected: number;
    fraudQuestioned: number;
    fuelCount: number;
}

export interface JourneysWithSumModel {
    journeys: JourneysModel[];
    sum: JourneysSumModel;
    anyDwlTripsFilteredOut: boolean;
}

export interface JourneysFilterModel {
    vehicleId?: number | null;
    trailerId?: number | null;
    driver?: string;
    dateRange: DateRange;
}

export interface GraphTemperatureInfo extends GraphInfo {
    temperatures: { [key: string]: number[] };
}

export interface ActivityModel {
    activityIntervalId: string;
    id: string;
    idNew: string;
    vehicle: string;
    monitoredObjectId: string;
    monitoredObjectType: FleetType;
    monitoredObjectRoles: Role[];
    startTime: string;
    endTime: string;
    isPrivate: boolean;
    placeStart: {
        lat: string;
        lon: string;
        name: string;
        addressStructured?: AddressStructured[];
    };
    placeEnd: {
        lat: string;
        lon: string;
        name: string;
        addressStructured?: AddressStructured[];
    };
    closed: boolean;
    driver: string;
    driver2: string;
    type: string;
    duration: number;
    distance: number;
    odometerEnd: number;
    odometerStart: number;
    afcL100Km: number;
    consumption: number;
    co2Emission: number;
    units: number;
    priceFormattedString: string;
    stopped: number;
    expensesCount: number;
    fraudsDetected: number;
    fraudsQuestioned: number;
    trailerRn: string;
    odometerSnapshotEndTimestamp?: string;
    temperatureSensors?: TripTemperatureSensor[];
    temperatureSensorsInstalled?: TripTemperatureSensor[];
}

export interface JourneysSumModel {
    duration: number;
    distance: number;
    odometerEnd: number;
    afcL100Km: number;
    consumption: number;
    units?: number;
    price?: number;
    co2Emission: number;
    stopped: number;
    privateDistance?: number;
    businessDistance?: number;
}
export interface JourneysModel {
    id: string;
    startTime: string;
    endTime: string;
    monitoredObjectId: string;
    date: string;
    alertsCount: number;
    expensesCount: number;
    activitiesCount: number;
    fraudsDetected: number;
    fraudsQuestioned: number;
    expanded: boolean;
    activities: ActivityModel[];
    sum: JourneysSumModel;
    privateSum?: JourneysSumModel;
    temperatureSensors?: TripTemperatureSensor[];
    temperatureSensorsInstalled?: TripTemperatureSensor[];
}

export interface JourneyGraphDataModel {
    lats: number[] | null;
    lons: number[] | null;
    fuelLevels: number[] | null;
    speeds: number[] | null;
    timestamps: number[] | null;
    temperatures?: { [key: string | number]: number[] };
    timestampsTemperatures?: number[] | null;
}

export type RouteParams = {
    vehicleId?: string;
    trailerId?: string;
    driverId?: string;
    startDate?: string;
    endDate?: string;
    selectedDate?: string;
    selectedJourney?: string;
    tableExpanded?: string;
    search?: string;
};

export interface OdometerAdjustmentData {
    date?: Date;
    state?: string;
    stateErr?: string;
    activityId?: string;
}
export interface IsPrivateData {
    state?: boolean;
    activityIdNew?: string;
}

const adjustmentValidator = new StringValidator({
    required: false,
    regexp: /^[0-9]+$/,
    max: 7
});

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

export interface State {
    drivers: DriverModel[];
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    trailers: ReadOnlyMonitoredObjectFeSb[];
    bar: {
        expanded: boolean;
    };
    filter: {
        fullDWL?: boolean;
        driver?: string;
        driversOpts: SelectOpt[];
        vehicle?: string;
        vehiclesOpts: SelectOpt<number>[];
        vehicleSearch?: string;
        trailer?: string;
        trailersOpts: SelectOpt<number>[];
        dateRange: DateRange;
        dateChanged?: boolean;
        requests: number;
    };
    helper?: {
        content: string;
    };
    table: {
        data: {
            rows?: JourneysModel[];
            sum?: JourneysSumModel;
            odoAdjustements?: ReadOnlyOdometerAdjustment[];
            journeyGraphData?: JourneyGraphDataModel;
        };
        sensors?: TripTemperatureSensor[];
        loading: boolean;
        selectedJourney?: string;
        showConsumption?: boolean;
    };
    cargo?: {
        vehicleId?: number;
        vehicles: SelectOpt<number>[];
        suppliers: SelectOpt[];
        date: string;
        type?: ExpenseType;
    };
    exportLoading?: boolean;
    adjustment?: {
        data?: OdometerAdjustmentData;
        confirm?: { loading: boolean };
    };
    isPrivate?: {
        data?: IsPrivateData;
        confirm?: { loading: boolean };
    };
    roles: Role[];
    journeyGraphVisible: boolean;
    journeyGraphSection?: GraphKey;
    showPremiumInfo: boolean;
    showCoachInfo: boolean;
}

class JourneysModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const settings = this._logic.statisticsJourneys().settings();
        const defaults = confDefault.settings.statistics.journeysActivity.filter;
        const roles = this._logic.auth().roles();
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this.state = {
            vehicles: [],
            trailers: [],
            drivers: [],
            bar: { expanded: params.tableExpanded === 'true' ? true : settings.expanded },
            table: { data: {}, loading: true, showConsumption: true },
            filter: {
                vehiclesOpts: [],
                trailersOpts: [],
                driversOpts: [],
                driver: params.driverId,
                vehicle: params.vehicleId,
                trailer: params.trailerId,
                dateRange: {
                    start: params.startDate ? params.startDate : defaults.dateRange.start,
                    end: params.endDate ? params.endDate : defaults.dateRange.end
                },
                requests: 0
            },
            roles,
            journeyGraphVisible: this._logic.map().journeyGraphVisible,
            showPremiumInfo: false,
            showCoachInfo: this._logic.driverBehaviorCoach().trucks().showJourneyActivityPromo()
        };
        // this._logic.driverBehaviorCoach().trucks().saveJourneyActivityPromoSettings(false);
    }

    componentDidMount() {
        (window as any).app.JourneyModule = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        this._logic
            .map()
            .routing()
            .setHasRoleJAA([Role.JAA].some(r => this._logic.auth().roles().includes(r)));

        this._logic
            .map()
            .routing()
            .setHasRoleDBH_IC_JA(
                [Role.DBH_IC_JA].some(r => this._logic.auth().roles().includes(r)) &&
                    this._logic.auth().newClient()?.fleetType !== ReadOnlyClientFleetTypeEnum.Trucks
            ); // logic for check fleetType T034-2173

        this._logic.statisticsJourneys().init({
            ...this._logic.map().initialPadding,
            left: !this.state.bar.expanded
                ? document.getElementsByClassName('journeys')?.[0]?.clientWidth + 120 ??
                  this._logic.map().initialPaddingWithLeftComponent.left
                : this._logic.map().initialPaddingWithLeftComponent.left
        });

        this._logic.map().journeyGraphToggle(value => {
            this.setState(prev => ({
                ...prev,
                journeyGraphVisible: value
            }));
            this._logic.map().setPadding({
                ...this._logic.map().getPadding(),
                bottom:
                    value && this.state.table.selectedJourney
                        ? this._logic.map().initialPaddingWithBottomComponent.bottom
                        : this._logic.map().initialPadding.bottom
            });
            if (this.state.table.selectedJourney) {
                this._logic.statisticsJourneys().selectPolyline(this.state.table.selectedJourney);
            } else {
                this._logic.map().routing().unselectPolylinesReal();
            }
        });

        this._filterData()
            .then(() => {
                this._logic.statisticsJourneys().onJourneysChange(({ sum, journeys }) => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data: {
                                ...state.table.data,
                                rows: journeys,
                                sum: sum
                            },
                            loading: false
                        }
                    }));
                });

                this._logic.statisticsJourneys().onVehicleIdentificationChange(identification => {
                    this._logic.vehicles().changeVehicleIdentification(identification);
                    this._onFilterVehicleTextChange(this.state.filter.vehicleSearch ?? '');
                });

                this._logic.map().setPadding({
                    ...this._logic.map().initialPadding,
                    left: !this.state.bar.expanded
                        ? document.getElementsByClassName('journeys')?.[0]?.clientWidth + 120 ??
                          this._logic.map().initialPaddingWithLeftComponent.left
                        : this._logic.map().initialPaddingWithLeftComponent.left
                });

                if (this.state.filter.vehicle || this.state.filter.driver || this.state.filter.trailer) {
                    this.setState(state => ({
                        filter: {
                            ...state.filter,
                            vehicleId: this.state.filter.vehiclesOpts
                                .map(v => v.code)
                                .includes(Number(this.state.filter.vehicle))
                                ? this.state.filter.vehicle
                                : undefined,
                            trailerId: this.state.filter.trailersOpts
                                .map(v => v.code)
                                .includes(Number(this.state.filter.trailer))
                                ? this.state.filter.trailer
                                : undefined,
                            driver: this.state.filter.driversOpts
                                .map(v => v.code)
                                .includes(this.state.filter.driver ?? '')
                                ? this.state.filter.driver
                                : undefined
                        }
                    }));
                    this.props.history.push({
                        search: qs.stringify({
                            ...params,
                            vehicleId: this.state.filter.vehicle,
                            trailerId: this.state.filter.trailer,
                            driver: this.state.filter.driver
                        } as RouteParams)
                    });

                    this._logic
                        .statisticsJourneys()
                        .journeysWithSum({
                            driver: this.state.filter.driver,
                            vehicle: this.state.filter.vehicle,
                            trailer: this.state.filter.trailer,
                            dateRange: {
                                start: this.state.filter.dateRange.start,
                                end: this.state.filter.dateRange.end
                            }
                        })
                        .then(journeysWithSum => {
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    data: {
                                        ...state.table.data,
                                        rows: journeysWithSum.journeys,
                                        sum: journeysWithSum.sum
                                    },
                                    loading: false
                                },
                                showPremiumInfo: this._getPremiumVisible(journeysWithSum.anyDwlTripsFilteredOut)
                            }));

                            this._logic
                                .statisticsExpenses()
                                .tableData({
                                    vehicles:
                                        this.state.filter.vehicle && this.state.filter.vehicle.length > 0
                                            ? [this.state.filter.vehicle]
                                            : undefined,
                                    trailerId: this.state.filter.trailer
                                        ? Number(this.state.filter.trailer)
                                        : undefined,
                                    driverId: this.state.filter.driver ? Number(this.state.filter.driver) : undefined,
                                    type: [ExpenseType.Fuel],
                                    dateRange: {
                                        start: this.state.filter.dateRange.start,
                                        end: this.state.filter.dateRange.end
                                    },
                                    aggregator: Aggregator.DATE
                                })
                                .then(expenses => {
                                    const data = this._logic
                                        .statisticsJourneys()
                                        .expenseSumData(journeysWithSum.journeys, journeysWithSum.sum, expenses.data);

                                    const rows = data.journeys.map<JourneysModel>(journey => {
                                        if (
                                            moment(moment(params.selectedDate, DATE_TIME_FORMAT)).isSame(
                                                journey.date,
                                                'day'
                                            )
                                        ) {
                                            return {
                                                ...journey,
                                                expanded: true
                                            };
                                        }
                                        return journey;
                                    });

                                    this.setState(
                                        state => ({
                                            table: {
                                                ...state.table,
                                                data: {
                                                    ...state.table.data,
                                                    rows,
                                                    sum: {
                                                        ...journeysWithSum.sum,
                                                        price: data.journeysSum.price,
                                                        units: data.journeysSum.units
                                                    }
                                                },
                                                loading: false,
                                                selectedJourney: params.selectedJourney
                                            }
                                        }),
                                        () => {
                                            const expanded = rows.find(r => r.expanded);
                                            const sensors = this.state.table.data.rows
                                                ?.find(row => row.expanded)
                                                ?.activities.find(
                                                    a => a.id === this.state.table.selectedJourney
                                                )?.temperatureSensorsInstalled;

                                            this.setState(
                                                state => ({
                                                    table: {
                                                        ...state.table,
                                                        sensors: sensors?.filter(
                                                            (v, i, a) =>
                                                                a.findIndex(t => t.sensorId === v.sensorId) === i
                                                        )
                                                    }
                                                }),
                                                () => {
                                                    this._setJourneyGraphData();
                                                }
                                            );

                                            if (expanded && !this._logic.demo().isActive) {
                                                this._logic
                                                    .api()
                                                    .odometerAdjustmentApi.odometerAdjustmentOdometerAdjustmentBetweenDates(
                                                        {
                                                            dateFrom: moment(expanded?.date).startOf('day').toDate(),
                                                            dateTo: moment(
                                                                expanded?.activities[expanded?.activities.length - 1]
                                                                    .odometerSnapshotEndTimestamp
                                                            )
                                                                .add(1, 'day')
                                                                .startOf('day')
                                                                .toDate(),
                                                            monitoredObject: Number(this.state.filter.vehicle)
                                                        }
                                                    )
                                                    .then(data => {
                                                        this.setState(state => ({
                                                            table: {
                                                                ...state.table,
                                                                data: {
                                                                    ...state.table.data,
                                                                    odoAdjustements: data
                                                                }
                                                            }
                                                        }));
                                                    });
                                            }
                                        }
                                    );

                                    const row = this.state.table.data.rows?.find(row => row.expanded);
                                    if (row) {
                                        this._routeMapShowOrDestroy(row.id);
                                    }
                                });
                        })
                        .catch(err => {
                            message.error(this.props.t('common.error.loadDataError'));
                            console.error(`Load data error, err: ${err}`);
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    loading: false
                                }
                            }));
                        });

                    this._logic.map().sideBarControlsOffResetState();
                } else {
                    this.setState(state => ({
                        table: { ...state.table, data: {}, loading: false }
                    }));
                }
            })
            .catch(err => {
                message.error(this.props.t('common.error.loadDataError'));
                console.error(`Load data error, err: ${err}`);
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
            });

        this._logic
            .map()
            .routing()
            .onRouteClick(routeId => {
                const tripId = routeId ? this._logic.statisticsJourneys().getTripId(routeId) : undefined;
                if (tripId) {
                    this._onJourneySelect(tripId);
                } else {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            selectedJourney: undefined
                        }
                    }));
                }
            });

        this._logic
            .map()
            .routing()
            .onAlarmAcknowledgedClick(id => {
                this._logic.alarms().markAlarmsAsSeen([id]);
            });
    }

    componentWillUnmount() {
        (window as any).app.JourneyModule = undefined;
        this._logic.statisticsJourneys().destroy();
    }

    render() {
        return (
            <Journeys
                bar={this.state.bar}
                filter={this.state.filter}
                helper={this.state.helper}
                table={this.state.table}
                cargo={this.state.cargo}
                exportLoading={this.state.exportLoading}
                adjustment={this.state.adjustment}
                isPrivate={this.state.isPrivate}
                roles={this.state.roles}
                showPremiumInfo={this.state.showPremiumInfo}
                showCoachInfo={this.state.showCoachInfo}
                onIsPrivateEditIconClick={this._onIsPrivateEditIconClick}
                onIsPrivateSelectChange={this._onIsPrivateSelectChange}
                onIsPrivateModalCancel={this._onIsPrivateModalCancel}
                onIsPrivateModalConfirm={this._onIsPrivateModalConfirm}
                onAdjustmentEditIconClick={this._onOdometerAdjustmentEditIconClick}
                onAdjustmentInputChange={this._onOdometerAdjustmentTextChange}
                onAdjustmentInputKeyDown={this._onOdometerAdjustmentInputKeyDown}
                onAdjustmentModalCancel={this._onOdometerAdjustmentModalCancel}
                onAdjustmentModalConfirm={this._onOdometerAdjustmentModalConfirm}
                onBarExpand={this._onBarExpand}
                onBarHelperClick={this._onBarHelperClick}
                onCargoCancel={this._onCargoCancel}
                onCargoConfirm={this._onCargoConfirm}
                onCargoSupplierChange={this._onCargoSupplierChange}
                onExportClick={this._onExportClick}
                onFilterChange={this._onFilterChange}
                onFilterDriverTextChange={this._onFilterDriverTextChange}
                onFilterVehicleTextChange={this._onFilterVehicleTextChange}
                onFilterTrailerTextChange={this._onFilterTrailerTextChange}
                onHelperClose={this._onHelperClose}
                onSumShowExpensesClick={this._onSumShowExpensesClick}
                onSumShowFuelClick={this._onSumShowFuelClick}
                onTableCreateCargoClick={this._onTableCreateCargoClick}
                onTableJourneySelect={this._onTableJourneySelect}
                onTableRowExpand={this._onTableRowExpand}
                journeyGraphVisible={this.state.journeyGraphVisible}
                onCloseJourneyGraph={() => this._logic.map().onJourneyGraphToggle(false)}
                setGraphSection={this._setGraphSection}
                logic={this._logic}
                onActiveDotMount={this._handleMountGraphDot}
                onActiveDotDestroy={this._handleUnmountGraphDot}
                resetPolylineOnUnclickedOnMap={this._setJourneyGraphData}
                onClosePremiumInfoClick={this._handleClosePremiumInfoClick}
                onCloseCoachInfoClick={this._handleCloseCoachPromoInfoClick}
                onCoachInfoLinkClick={this._handleCoachPromoLinkClick}
            />
        );
    }

    private _handleMountGraphDot = (p: { key: string | number; value: string | number; lat: number; lon: number }) => {
        this._logic.map().routing().renderJourneyGraphMarker(p.lat, p.lon, this.state.journeyGraphSection);
    };

    private _handleUnmountGraphDot = () => {
        this._logic.map().routing().removeJourneyGraphMarker();
    };

    private _setGraphSection = (section: GraphKey) => {
        this.setState({
            journeyGraphSection: section
        });
    };

    // TODO:
    // - request
    // - loading response
    private _onOdometerAdjustmentInputKeyDown = (text: string) => {
        if (text === 'Escape') {
            this.setState(state => ({
                adjustment: {
                    ...state.adjustment,
                    data: undefined
                }
            }));
        }

        if (text === 'Enter' && !this.state.adjustment?.data?.stateErr) {
            this.setState(state => ({
                adjustment: {
                    ...state.adjustment,
                    confirm: { loading: false }
                }
            }));
        }
    };
    private _onOdometerAdjustmentTextChange = (text: string) => {
        const validateRes = adjustmentValidator.validate(text);
        this.setState(state => ({
            adjustment: {
                ...state.adjustment,
                data: {
                    ...state.adjustment?.data,
                    state: text,
                    stateErr: validateRes.err
                }
            }
        }));
    };

    private _onOdometerAdjustmentModalCancel = (): void => {
        this.setState({ adjustment: undefined });
    };

    private _onOdometerAdjustmentModalConfirm = (): void => {
        this.setState(
            state => ({
                adjustment: {
                    ...state.adjustment,
                    confirm: { loading: true }
                }
            }),
            () => {
                if (!this.state.adjustment?.data) {
                    this.setState({ adjustment: undefined });
                    return;
                }
                this._logic
                    .api()
                    .odometerAdjustmentApi.odometerAdjustmentCreate({
                        data: {
                            clientId: this._logic.auth().client()?.id!,
                            state: Number(this.state.adjustment!.data?.state) * 1000, // Meters
                            monitoredObject: Number(this.state.filter.vehicle),
                            date: new Date(this.state.adjustment!.data?.date!) // Date of actual adjustment
                        }
                    })
                    .then(adjustement => {
                        this.setState(
                            state => ({
                                adjustment: undefined,
                                table: {
                                    ...state.table,
                                    data: {
                                        ...state.table.data,
                                        odoAdjustements: [
                                            ...(state.table.data.odoAdjustements ?? []).filter(
                                                odo => !moment(odo.date).isSame(moment(adjustement.date))
                                            ),
                                            adjustement
                                        ]
                                    }
                                }
                            }),
                            () => {
                                message.success(this.props.t('JourneyActivityAdjustment.success'));
                            }
                        );
                    })
                    .catch(_err => {
                        this.setState({ adjustment: undefined });
                        message.error(this.props.t('JourneyActivityAdjustment.error'));
                    });
            }
        );
    };

    private _onOdometerAdjustmentEditIconClick = (data: OdometerAdjustmentData): void => {
        this.setState(state => ({
            adjustment: {
                ...state.adjustment,
                data: {
                    ...data,
                    state: numeral(Number(data.state) / 1000).format('0') // Kilometers
                }
            }
        }));
    };

    private _onIsPrivateSelectChange = (value: string) => {
        const isPrivateState = value === 'true' ? true : false;
        if (isPrivateState !== this.state.isPrivate?.data?.state) {
            this.setState(state => ({
                isPrivate: {
                    ...state.isPrivate,
                    data: {
                        ...state.isPrivate?.data,
                        state: isPrivateState
                    },
                    confirm: { loading: false }
                }
            }));
        } else {
            this.setState({ isPrivate: undefined });
        }
    };

    private _onIsPrivateModalCancel = (): void => {
        this.setState({ isPrivate: undefined });
    };

    private _onIsPrivateModalConfirm = (): void => {
        this.setState(
            state => ({
                isPrivate: {
                    ...state.isPrivate,
                    confirm: { loading: true }
                }
            }),
            () => {
                if (!this.state.isPrivate?.data) {
                    return;
                } else {
                    this._logic
                        .statisticsJourneys()
                        .editJourneys(
                            this.state.isPrivate.data.activityIdNew?.split(',').filter(a => a) ?? [],
                            this.state.isPrivate.data.state!
                        )
                        .then(_res => {
                            this._logic
                                .statisticsJourneys()
                                .journeysWithSum({
                                    dateRange: {
                                        start: this.state.filter.dateRange.start,
                                        end: this.state.filter.dateRange.end
                                    },
                                    driver: this.state.filter.driver,
                                    vehicle: this.state.filter.vehicle
                                })
                                .then(journeysWithSum => {
                                    this.setState(state => ({
                                        table: {
                                            ...state.table.data,
                                            loading: false,
                                            data: {
                                                rows: journeysWithSum.journeys.map(journeyWithSum => {
                                                    if (
                                                        state.table.data.rows?.find(r => r.id === journeyWithSum.id)
                                                            ?.expanded
                                                    ) {
                                                        return {
                                                            ...journeyWithSum,
                                                            expanded: true
                                                        };
                                                    } else {
                                                        return journeyWithSum;
                                                    }
                                                }),
                                                sum: journeysWithSum.sum
                                            }
                                        },
                                        isPrivate: undefined,
                                        showPremiumInfo: this._getPremiumVisible(journeysWithSum.anyDwlTripsFilteredOut)
                                    }));

                                    const rowsExpanded: JourneysModel | undefined = this.state.table.data.rows?.find(
                                        r => r.expanded
                                    );
                                    const tripsIdsToRender: string[] = rowsExpanded
                                        ? rowsExpanded.activities.map(a => a.id)
                                        : [];
                                    if (tripsIdsToRender?.length > 0) {
                                        if (rowsExpanded) {
                                            this._logic
                                                .alarms()
                                                .getAlarms({
                                                    active: false,
                                                    dateFrom: new Date(rowsExpanded.startTime),
                                                    dateTo: new Date(rowsExpanded.endTime),
                                                    monitoredObjectId: Number(rowsExpanded.monitoredObjectId),
                                                    limit: 999
                                                })
                                                .then(data => {
                                                    tripsIdsToRender &&
                                                        this._logic
                                                            .statisticsJourneys()
                                                            .renderRoutesOnMap(tripsIdsToRender, data);
                                                });
                                        } else {
                                            this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender);
                                        }
                                    }

                                    this._logic
                                        .statisticsExpenses()
                                        .tableData({
                                            vehicles:
                                                this.state.filter.vehicle && this.state.filter.vehicle.length > 0
                                                    ? [this.state.filter.vehicle]
                                                    : undefined,
                                            driverId: this.state.filter.driver
                                                ? Number(this.state.filter.driver)
                                                : undefined,
                                            type: [ExpenseType.Fuel],
                                            dateRange: {
                                                start: this.state.filter.dateRange.start,
                                                end: this.state.filter.dateRange.end
                                            },
                                            aggregator: Aggregator.DATE
                                        })
                                        .then(expenses => {
                                            const data = this._logic
                                                .statisticsJourneys()
                                                .expenseSumData(
                                                    journeysWithSum.journeys,
                                                    journeysWithSum.sum,
                                                    expenses.data
                                                );

                                            this.setState(state => ({
                                                table: {
                                                    ...state.table,
                                                    data: {
                                                        ...state.table.data,
                                                        rows: data.journeys.map(journey =>
                                                            state.table.data.rows?.find(r => r.id === journey.id)
                                                                ?.expanded
                                                                ? {
                                                                      ...journey,
                                                                      expanded: true
                                                                  }
                                                                : journey
                                                        ),
                                                        sum: {
                                                            ...journeysWithSum.sum,
                                                            price: data.journeysSum.price,
                                                            units: data.journeysSum.units
                                                        }
                                                    },
                                                    loading: false
                                                }
                                            }));
                                        });
                                })
                                .catch(err => {
                                    this.setState(state => ({
                                        table: {
                                            ...state.table,
                                            loading: false
                                        }
                                    }));
                                    console.error(`Load data error, err: ${err}`);
                                    message.error(this.props.t('common.error.loadDataError'));
                                });
                        })
                        .catch(_err => {
                            this.setState({ isPrivate: undefined });
                            message.error(this.props.t('JourneyActivityIsPrivate.error'));
                        });
                }
            }
        );
    };

    private _onIsPrivateEditIconClick = (data: IsPrivateData): void => {
        this.setState(state => ({
            isPrivate: {
                ...state.isPrivate,
                data
            }
        }));
    };

    private _onBarExpand = (): void => {
        if (!this.state.bar.expanded) {
            this._logic.exponea().trackEvent(exponea.module.statisticsJourneysActivity, {
                status: exponea.status.actionTaken,
                action: exponea.action.expand
            });
        }

        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });

        this.setState(
            state => ({
                bar: { expanded: !state.bar.expanded }
            }),
            () => {
                this._logic.statisticsJourneys().setSettings({ expanded: this.state.bar.expanded });
                this.props.history.push({
                    search: qs.stringify({
                        ...params,
                        tableExpanded: String(this.state.bar.expanded)
                    } as RouteParams)
                });

                if (!this.state.bar.expanded && this.state.table.data?.rows?.find(r => r.expanded)) {
                    this._logic.map().setPadding({
                        ...this._logic.map().initialPadding,
                        left:
                            document.getElementsByClassName('journeys')?.[0]?.clientWidth + 120 ??
                            this._logic.map().initialPaddingWithLeftComponent.left
                    });

                    const rowExpanded = this.state.table.data.rows.find(r => r.expanded);
                    const tripsIdsToRender = this.state.table.data.rows
                        .find(r => r.expanded)!
                        .activities.map(a => a.id);

                    if (rowExpanded) {
                        this._logic
                            .alarms()
                            .getAlarms({
                                active: false,
                                dateFrom: new Date(rowExpanded.startTime),
                                dateTo: new Date(rowExpanded.endTime),
                                monitoredObjectId: Number(rowExpanded.monitoredObjectId),
                                limit: 999
                            })
                            .then(data => {
                                tripsIdsToRender &&
                                    this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender, data);
                                if (this.state.table.selectedJourney) {
                                    this._logic.statisticsJourneys().selectPolyline(this.state.table.selectedJourney);
                                }
                            });
                    } else {
                        this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender);
                    }

                    if (this.state.table.selectedJourney) {
                        this._onJourneySelect(this.state.table.selectedJourney);
                    }
                } else {
                    this._logic.statisticsJourneys().destroyRouteOnMap();
                }
            }
        );
    };

    private _onFilterChange = (journeysFilter: JourneysFilterModel): void => {
        const { driver, vehicleId, trailerId, dateRange } = journeysFilter;
        const defaults = confDefault.settings.statistics.journeysActivity.filter;
        this.setState({ exportLoading: undefined });

        this.props.history.push({
            search: qs.stringify({
                startDate: dateRange.start,
                endDate: dateRange.end,
                driverId: driver ? driver : undefined,
                vehicleId: vehicleId ? vehicleId?.toString() : undefined,
                trailerId: trailerId ? trailerId?.toString() : undefined
            } as RouteParams)
        });

        this._logic.statisticsJourneys().setSettings(journeysFilter);
        this._logic.statisticsJourneys().destroyRouteOnMap();

        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    driver: journeysFilter.driver ? journeysFilter.driver : undefined,
                    vehicle: vehicleId ? String(journeysFilter.vehicleId) : undefined,
                    trailer: trailerId ? String(journeysFilter.trailerId) : undefined,
                    dateRange: {
                        start: journeysFilter.dateRange.start,
                        end: journeysFilter.dateRange.end
                    },
                    dateChanged:
                        journeysFilter.dateRange.start !== defaults.dateRange.start ||
                        journeysFilter.dateRange.end !== defaults.dateRange.end,
                    requests: state.filter.requests + 1
                },
                table: {
                    ...state.table,
                    loading: vehicleId || trailerId || driver ? true : false,
                    data:
                        vehicleId || trailerId || driver
                            ? {
                                  ...state.table.data,
                                  journeyGraphData: undefined
                              }
                            : {
                                  rows: undefined,
                                  sum: undefined,
                                  journeyGraphData: undefined
                              },
                    sensors: undefined
                },
                cargo: undefined
            }),
            () => {
                if (vehicleId || trailerId || driver) {
                    this._logic
                        .statisticsJourneys()
                        .journeysWithSum({
                            dateRange: {
                                start: this.state.filter.dateRange.start,
                                end: this.state.filter.dateRange.end
                            },
                            driver: driver ? driver : undefined,
                            trailer: trailerId ? trailerId?.toString() : undefined,
                            vehicle: vehicleId ? vehicleId?.toString() : undefined
                        })
                        .then(journeysWithSum => {
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    data: {
                                        ...state.table.data,
                                        sum: journeysWithSum.sum,
                                        rows:
                                            this.state.filter.requests - 1 === 0
                                                ? journeysWithSum.journeys.map(journeyWithSum => {
                                                      if (
                                                          state.table.data.rows?.find(r => r.id === journeyWithSum.id)
                                                              ?.expanded
                                                      ) {
                                                          return {
                                                              ...journeyWithSum,
                                                              expanded: true
                                                          };
                                                      } else {
                                                          return journeyWithSum;
                                                      }
                                                  })
                                                : []
                                    }
                                },
                                showPremiumInfo: this._getPremiumVisible(journeysWithSum.anyDwlTripsFilteredOut)
                            }));

                            const rowsExpanded: JourneysModel | undefined = this.state.table.data.rows?.find(
                                r => r.expanded
                            );
                            const tripsIdsToRender: string[] = rowsExpanded
                                ? rowsExpanded.activities.map(a => a.id)
                                : [];
                            if (tripsIdsToRender?.length > 0) {
                                if (rowsExpanded) {
                                    this._logic
                                        .alarms()
                                        .getAlarms({
                                            active: false,
                                            dateFrom: new Date(rowsExpanded.startTime),
                                            dateTo: new Date(rowsExpanded.endTime),
                                            monitoredObjectId: Number(rowsExpanded.monitoredObjectId),
                                            limit: 999
                                        })
                                        .then(data => {
                                            tripsIdsToRender &&
                                                this._logic
                                                    .statisticsJourneys()
                                                    .renderRoutesOnMap(tripsIdsToRender, data);
                                        });
                                } else {
                                    this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender);
                                }
                            }

                            this._logic
                                .statisticsExpenses()
                                .tableData({
                                    vehicles:
                                        this.state.filter.vehicle && this.state.filter.vehicle.length > 0
                                            ? [this.state.filter.vehicle]
                                            : undefined,
                                    driverId: this.state.filter.driver ? Number(this.state.filter.driver) : undefined,
                                    trailerId: this.state.filter.trailer
                                        ? Number(this.state.filter.trailer)
                                        : undefined,
                                    type: [ExpenseType.Fuel],
                                    dateRange: {
                                        start: this.state.filter.dateRange.start,
                                        end: this.state.filter.dateRange.end
                                    },
                                    aggregator: Aggregator.DATE
                                })
                                .then(expenses => {
                                    const data = this._logic
                                        .statisticsJourneys()
                                        .expenseSumData(journeysWithSum.journeys, journeysWithSum.sum, expenses.data);

                                    this.setState(state => ({
                                        filter: {
                                            ...state.filter,
                                            requests: state.filter.requests - 1
                                        },
                                        table: {
                                            ...state.table,
                                            loading: this.state.filter.requests - 1 === 0 ? false : true,
                                            data: {
                                                ...state.table.data,
                                                rows: data.journeys.map(journey =>
                                                    state.table.data.rows?.find(r => r.id === journey.id)?.expanded
                                                        ? {
                                                              ...journey,
                                                              expanded: true
                                                          }
                                                        : journey
                                                ),
                                                sum: {
                                                    ...journeysWithSum.sum,
                                                    price: data.journeysSum.price,
                                                    units: data.journeysSum.units
                                                }
                                            }
                                        }
                                    }));
                                });
                        })
                        .catch(err => {
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    loading: false
                                }
                            }));
                            console.error(`Load data error, err: ${err}`);
                            message.error(this.props.t('common.error.loadDataError'));
                        });
                } else {
                    this.setState(state => ({
                        filter: {
                            ...state.filter,
                            requests: state.filter.requests - 1
                        },
                        showPremiumInfo: false
                    }));
                }
            }
        );
    };

    private _filterData = async () => {
        const drivers = await this._logic.users().drivers();
        const driversOpts: SelectOpt[] = drivers.map(driver => ({
            code: driver.id.toString(),
            label: `${driver.name} ${driver.surname}`
        }));

        const mobjects = await this._logic.vehicles().getMonitoredObjectFilters(false, true, [Role.JA_R]);
        const mobjectsForTrailers = await this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, true, [], false, false);

        const vehicles = mobjects.filter(mo => {
            return mo.fleetType !== FleetType.TRAILER;
        });
        const vehiclesOpts: SelectOpt<number>[] = vehicles.map(vehicle => ({
            code: vehicle.id ?? 0,
            label: vehicle.disabledAt ? `*${vehicle.registrationNumber}` : vehicle.registrationNumber
        }));

        const trailers = mobjectsForTrailers.filter(mo => {
            return mo.fleetType === FleetType.TRAILER;
        });
        const trailersOpts: SelectOpt<number>[] = trailers.map(trailer => ({
            code: trailer.id ?? 0,
            label: trailer.disabledAt ? `*${trailer.registrationNumber}` : trailer.registrationNumber
        }));

        this.setState(state => ({
            vehicles,
            trailers,
            drivers,
            filter: {
                ...state.filter,
                fullDWL: this._logic.vehicles().fullDWL,
                driversOpts,
                vehiclesOpts,
                trailersOpts
            }
        }));
    };

    private _onFilterVehicleTextChange = debounce((value: string): void => {
        this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, true, [Role.JA_R])
            .then(vehicles => {
                const vehiclesOpts: SelectOpt<number>[] = vehicles.map(vehicle => ({
                    code: vehicle.id ?? 0,
                    label: vehicle.disabledAt ? `*${vehicle.registrationNumber}` : vehicle.registrationNumber
                }));
                this.setState(state => ({
                    filter: {
                        ...state.filter,
                        vehiclesOpts: value === '' ? vehiclesOpts : search(value, ['label'], vehiclesOpts)
                    }
                }));
            });
    }, 400);

    private _onFilterTrailerTextChange = debounce((value: string): void => {
        this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, true, [], false, false)
            .then(monitoredObjects => {
                const monitoredObjectsOpts: SelectOpt<number>[] = monitoredObjects
                    .filter(mo => mo.fleetType === FleetType.TRAILER)
                    .map(monitoredObject => ({
                        code: monitoredObject.id ?? 0,
                        label: monitoredObject.disabledAt
                            ? `*${monitoredObject.registrationNumber}`
                            : monitoredObject.registrationNumber
                    }));
                this.setState(state => ({
                    filter: {
                        ...state.filter,
                        trailersOpts:
                            value === '' ? monitoredObjectsOpts : search(value, ['label'], monitoredObjectsOpts)
                    }
                }));
            });
    }, 400);

    private _onFilterDriverTextChange = debounce((value: string): void => {
        if (value === '') {
            this._logic
                .users()
                .drivers()
                .then(drivers => {
                    const driversOpts: SelectOpt[] = drivers.map(driver => ({
                        code: driver.id.toString(),
                        label: `${driver.name} ${driver.surname}`
                    }));
                    this.setState(state => ({
                        filter: { ...state.filter, driversOpts }
                    }));
                });
        } else {
            // TODO: use filter in query instead of filtering results
            this._logic
                .users()
                .drivers()
                .then(drivers => {
                    const driversOpts: SelectOpt[] = drivers
                        .map(driver => ({
                            code: driver.id.toString(),
                            label: `${driver.name} ${driver.surname}`
                        }))
                        .filter(v =>
                            v.label.replace(/ /g, '').toLowerCase().includes(value.replace(/ /g, '').toLowerCase())
                        );

                    this.setState(state => ({
                        filter: { ...state.filter, driversOpts }
                    }));
                });
        }
    }, 400);

    private _onSumShowExpensesClick = (): void => {
        const expandedRow = this.state.table.data.rows?.find(r => r.expanded === true);
        this.props.history.push({
            pathname: RouteNames.STATISTICS_EXPENSES,
            search: qs.stringify({
                vehicleId: this.state.filter.vehicle ? this.state.filter.vehicle : undefined,
                trailerId: this.state.filter.trailer ? this.state.filter.trailer : undefined,
                driverId: this.state.filter.driver ? this.state.filter.driver : undefined,
                startDate: this.state.filter.dateRange.start,
                endDate: this.state.filter.dateRange.end,
                selectedDate: expandedRow ? moment(expandedRow.date).format(DATE_TIME_FORMAT) : undefined,
                selectedJourney: this.state.table.selectedJourney
            }),
            state: { from: this.props.location }
        });
    };

    private _onSumShowFuelClick = (): void => {
        const expandedRow = this.state.table.data.rows?.find(r => r.expanded === true);
        this.props.history.push({
            pathname: RouteNames.STATISTICS_FUEL_CONSUMPTION,
            search: qs.stringify({
                vehicleId: this.state.filter.vehicle ? this.state.filter.vehicle : undefined,
                trailerId: this.state.filter.trailer ? this.state.filter.trailer : undefined,
                driverId: this.state.filter.driver ? this.state.filter.driver : undefined,
                startDate: this.state.filter.dateRange.start,
                endDate: this.state.filter.dateRange.end,
                selectedDate: expandedRow ? moment(expandedRow.date).format(DATE_TIME_FORMAT) : undefined,
                selectedJourney: this.state.table.selectedJourney
            }),
            state: { from: this.props.location }
        });
    };

    private _setJourneyGraphData = async (activityId?: string) => {
        const selectedActivity = this.state.table.data.rows
            ?.flatMap(row => row.activities)
            .find(activity => activity.id === activityId);
        const expandedRow = this.state.table.data.rows?.find(row => row.expanded);

        const getActivityIds = () => {
            if (selectedActivity) {
                return [selectedActivity.activityIntervalId];
            } else if (expandedRow) {
                return expandedRow.activities.map(a => a.activityIntervalId);
            } else {
                return [];
            }
        };

        const getSerialNumbers = (): string[] => {
            if (selectedActivity) {
                return selectedActivity.temperatureSensorsInstalled?.map(sensor => sensor.serialNumber) ?? [];
            } else if (expandedRow) {
                let sn: string[] = [];
                expandedRow.activities.forEach(a => {
                    sn = sn.concat(...(a.temperatureSensorsInstalled?.map(sensor => sensor.serialNumber) ?? []));
                });
                return sn.filter((v, i, a) => a.indexOf(v) === i).flat();
            } else {
                return [];
            }
        };

        const setExternalDevicesData = (data: TemperatureSensorData[]) => {
            this.setState(state => ({
                ...state,
                table: {
                    ...state.table,
                    data: {
                        ...state.table.data,
                        journeyGraphData: {
                            ...state.table.data.journeyGraphData,
                            lats: state.table.data.journeyGraphData?.lats ?? null,
                            lons: state.table.data.journeyGraphData?.lons ?? null,
                            fuelLevels: state.table.data.journeyGraphData?.fuelLevels ?? null,
                            speeds: state.table.data.journeyGraphData?.speeds ?? null,
                            timestamps: state.table.data.journeyGraphData?.timestamps ?? null,
                            temperatures: data
                                .map(d => ({
                                    [d.serialNumber]: d.temperatures
                                }))
                                .reduce((key, value) => ({ ...key, ...value }), {}),
                            timestampsTemperatures: data?.[0]?.timestamps ?? null
                        }
                    }
                }
            }));
        };

        const setLegData = (data: GraphInfo[]) => {
            this.setState(state => ({
                ...state,
                table: {
                    ...state.table,
                    data: {
                        ...state.table.data,
                        journeyGraphData: {
                            ...state.table.data.journeyGraphData,
                            lats: data?.flatMap(v => v.lats),
                            lons: data?.flatMap(v => v.lons),
                            fuelLevels: data.flatMap(v => v.fuelLevels).some(v => v !== null)
                                ? data.flatMap(v => v.fuelLevels)
                                : null,
                            speeds: data.flatMap(v => v.speeds),
                            timestamps: data.flatMap(v => v.timestamps)
                        }
                    }
                }
            }));
        };

        const serialNumbers = getSerialNumbers();

        if (selectedActivity || expandedRow) {
            if (selectedActivity) {
                if (this._logic.auth().roles().includes(Role.CLD_R) && serialNumbers && serialNumbers.length > 0) {
                    this._logic
                        .statisticsJourneys()
                        .getTemperatureData(
                            {
                                start: moment(selectedActivity.startTime).format(DATE_TIME_FORMAT),
                                end: moment(selectedActivity.endTime).format(DATE_TIME_FORMAT)
                            },
                            serialNumbers
                        )
                        .then(setExternalDevicesData);
                }
                return this._logic
                    .statisticsJourneys()
                    .getJourneyGraphData(selectedActivity.monitoredObjectId, getActivityIds())
                    .then(setLegData);
            } else if (expandedRow) {
                if (this._logic.auth().roles().includes(Role.CLD_R) && serialNumbers && serialNumbers.length > 0) {
                    this._logic
                        .statisticsJourneys()
                        .getTemperatureData(
                            {
                                start: moment(expandedRow.startTime).format(DATE_TIME_FORMAT),
                                end: moment(expandedRow.endTime).format(DATE_TIME_FORMAT)
                            },
                            serialNumbers
                        )
                        .then(setExternalDevicesData);
                }
                return this._logic
                    .statisticsJourneys()
                    .getJourneyGraphData(expandedRow.monitoredObjectId, getActivityIds())
                    .then(setLegData);
            }
        } else {
            return this.setState(prev => ({
                ...prev,
                table: {
                    ...prev.table,
                    data: {
                        ...prev.table.data,
                        journeyGraphData: undefined
                    }
                }
            }));
        }
    };

    private _onTableRowExpand = (id: string): void => {
        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        const journey = this.state.table.data.rows?.find(row => row.id === id);

        this.setState(state => ({
            table: {
                ...state.table,
                data: {
                    ...state.table.data,
                    odoAdjustements: undefined
                },
                sensors: undefined
            }
        }));

        if (!this._logic.demo().isActive) {
            if (!journey?.expanded && journey?.activities) {
                this._logic
                    .statisticsJourneys()
                    .warmPointInfo(journey.activities.map(activity => activity.activityIntervalId));
            }

            this._logic
                .api()
                .odometerAdjustmentApi.odometerAdjustmentOdometerAdjustmentBetweenDates({
                    dateFrom: moment(journey?.date).startOf('day').toDate(),
                    dateTo: moment(journey?.activities[journey.activities.length - 1].odometerSnapshotEndTimestamp)
                        .add(1, 'day')
                        .startOf('day')
                        .toDate(),
                    monitoredObject: Number(journey?.monitoredObjectId)
                })
                .then(data => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data: {
                                ...state.table.data,
                                odoAdjustements: data
                            }
                        }
                    }));
                });
        }

        this.setState(
            state => {
                state.table.data.rows?.forEach(row => {
                    if (row.id === id) {
                        row.expanded = !row.expanded;
                        return;
                    }
                    row.expanded = false;
                });
                return {
                    cargo: undefined,
                    table: {
                        ...state.table,
                        selectedJourney: undefined
                    }
                };
            },
            () => {
                if (!this.state.bar.expanded) {
                    this._logic.map().setPadding({
                        ...this._logic.map().initialPadding,
                        left: !this.state.bar.expanded
                            ? document.getElementsByClassName('journeys')?.[0]?.clientWidth + 120 ??
                              this._logic.map().initialPaddingWithLeftComponent.left
                            : this._logic.map().initialPaddingWithLeftComponent.left
                    });
                }
                this._routeMapShowOrDestroy(id);

                const expandedRow = this.state.table.data.rows?.find(row => row.expanded);

                let sensors: TripTemperatureSensor[] = [];
                this.state.table.data.rows
                    ?.find(row => row.expanded)
                    ?.activities?.forEach(activity => {
                        sensors = sensors?.concat(activity.temperatureSensorsInstalled ?? []);
                    });

                this.setState(
                    state => ({
                        table: {
                            ...state.table,
                            sensors: sensors.filter((v, i, a) => a.findIndex(t => t.sensorId === v.sensorId) === i)
                        }
                    }),
                    () => {
                        this._setJourneyGraphData();
                    }
                );

                this.props.history.push({
                    search: qs.stringify({
                        ...params,
                        selectedDate: expandedRow ? moment(expandedRow.date).format(DATE_TIME_FORMAT) : undefined
                    } as RouteParams)
                });
            }
        );
    };

    private _routeMapShowOrDestroy = (id: string): void => {
        if (!this.state.bar.expanded && this.state.table.data?.rows?.find(r => r.expanded)) {
            const rowData = this.state.table.data.rows.find(row => row.id === id);
            const activities = this.state.table.data.rows.find(row => row.id === id)?.activities;
            const tripsIdsToRender = activities?.map(a => a.id);
            if (rowData) {
                this._logic
                    .alarms()
                    .getAlarms({
                        active: false,
                        dateFrom: new Date(rowData.startTime),
                        dateTo: new Date(rowData.endTime),
                        monitoredObjectId: Number(rowData.monitoredObjectId),
                        limit: 999
                    })
                    .then(data => {
                        tripsIdsToRender && this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender, data);
                        if (this.state.table.selectedJourney) {
                            this._logic.statisticsJourneys().selectPolyline(this.state.table.selectedJourney);
                        }
                    });
            } else {
                tripsIdsToRender && this._logic.statisticsJourneys().renderRoutesOnMap(tripsIdsToRender);
            }
        } else {
            this._logic.statisticsJourneys().destroyRouteOnMap();
        }
    };

    private _onTableJourneySelect = (id: string): void => {
        this._onJourneySelect(id === this.state.table.selectedJourney ? undefined : id);
    };

    private _onJourneySelect = (id?: string): void => {
        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                selectedJourney: id
            } as RouteParams)
        });

        this._setJourneyGraphData(id);
        if (id) {
            const sensors = this.state.table.data.rows
                ?.find(row => row.expanded)
                ?.activities?.find(d => d.id === id)?.temperatureSensorsInstalled;

            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        selectedJourney: id,
                        sensors: sensors?.filter((v, i, a) => a.indexOf(v) === i) ?? []
                    }
                }),
                () => {
                    if (!this.state.bar.expanded) {
                        this._logic.statisticsJourneys().selectPolyline(id);
                    }
                }
            );
        } else {
            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        selectedJourney: undefined
                    }
                }),
                () => {
                    this._logic.map().routing().unselectPolylinesReal();
                }
            );
        }
    };

    private _onTableCreateCargoClick = (date: string): void => {
        if (this.state.cargo) {
            this.setState(state => ({
                ...state,
                cargo: undefined
            }));
        } else {
            if (this.state.filter.vehicle) {
                this.setState(state => ({
                    cargo: {
                        vehicleId: Number(this.state.filter.vehicle),
                        suppliers: [],
                        date,
                        vehicles: state.filter.vehiclesOpts
                    }
                }));
            } else {
                this._logic
                    .vehicles()
                    .getMonitoredObjectFilters(false, false, [Role.JA_R])
                    .then(vehicles => {
                        const vehiclesOpts: SelectOpt<number>[] = vehicles.map(v => ({
                            code: v.id ?? 0,
                            label: v.registrationNumber
                        }));

                        this.setState(_state => ({
                            cargo: {
                                suppliers: [],
                                date,
                                vehicles: vehiclesOpts
                            }
                        }));
                    });
            }
        }
    };

    private _onCargoSupplierChange = debounce((value: string): void => {
        this._logic
            .routing()
            .suggestion()
            .getCustomPlaceSuggestions(value)
            .then(fuelStations => {
                const cargoFuelStations = fuelStations
                    .filter(f => f.source === 'FUEL_STATIONS')
                    .map<{
                        label: string;
                        code: string;
                    }>(f => ({
                        label: f.label || '',
                        code: f.id || ''
                    }));
                this.setState(state => ({
                    cargo: {
                        ...state.cargo!,
                        suppliers: cargoFuelStations
                    }
                }));
            });
    }, 300);

    private _onCargoConfirm = async (cargo: CargoExpense): Promise<boolean> => {
        this.setState(state => ({
            cargo: undefined,
            table: {
                ...state.table,
                loading: true
            }
        }));

        try {
            await this._logic.statisticsExpenses().createOrUpdateExpense(cargo);
            this._logic
                .statisticsExpenses()
                .tableData({
                    vehicles:
                        this.state.filter.vehicle && this.state.filter.vehicle.length > 0
                            ? [this.state.filter.vehicle]
                            : undefined,
                    driverId: this.state.filter.driver ? Number(this.state.filter.driver) : undefined,
                    type: [ExpenseType.Fuel],
                    dateRange: {
                        start: this.state.filter.dateRange.start,
                        end: this.state.filter.dateRange.end
                    },
                    aggregator: Aggregator.DATE
                })
                .then(expenses => {
                    const data = this._logic
                        .statisticsJourneys()
                        .expenseSumData(this.state.table.data.rows!, this.state.table.data.sum!, expenses.data);

                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data: {
                                ...state.table.data,
                                rows: data.journeys,
                                sum: state.table.data.sum
                                    ? {
                                          ...state.table.data.sum,
                                          price: data.journeysSum.price,
                                          units: data.journeysSum.units
                                      }
                                    : state.table.data.sum
                            },
                            loading: false
                        }
                    }));
                });
            return true;
        } catch (err) {
            console.error(`create/update expense failed, err: ${err}`);
            if (cargo.expenseId) {
                message.error(this.props.t('ExpensesCargo.error.createExpenseError'));
            } else {
                message.error(this.props.t('ExpensesCargo.error.editExpenseError'));
            }
            return false;
        }
    };

    private _onCargoCancel = (): void => {
        this.setState({ cargo: undefined });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'track&trace';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onExportClick = async (): Promise<void> => {
        const { t } = this.props;
        const distance = (distance: number) => Math.round(distance / 100) / 10;

        if (this.state.table.data.rows && this.state.table.data.rows.length > 0) {
            // loading state
            this.setState({ exportLoading: true });

            const lang = this._logic.settings().getProp('lang');
            const addressIdentification = this._logic.settings().getProp('addressIdentification');
            const client = this._logic.auth().client();

            const workbook: Workbook = await fromBlankAsync();
            const vehicle = this.state.vehicles.find(v => String(v.id) === this.state.filter.vehicle);
            const trailer = this.state.trailers.find(v => String(v.id) === this.state.filter.trailer);
            const driver = this.state.drivers.find(v => String(v.id) === this.state.filter.driver);
            const clientNew = this._logic.auth().newEWClient();
            let filter: string[][] = [];

            if (vehicle) {
                filter.push([
                    t('common.vehicle'),
                    '',
                    vehicle
                        ? getRegistrationNumber(!!vehicle.disabledAt, vehicle.registrationNumber)
                        : t('common.unknown')
                ]);
            }
            if (trailer) {
                filter.push([
                    t('common.trailer'),
                    '',
                    trailer
                        ? trailer.disabledAt
                            ? `*${trailer.registrationNumber}`
                            : trailer.registrationNumber
                        : t('common.unknown')
                ]);
            }
            if (driver) {
                filter.push([
                    t('common.driver'),
                    '',
                    driver ? `${driver.surname} ${driver.name}` : t('common.unknown')
                ]);
            }

            let head: string[][] = [
                [
                    t('JourneyExport.title'),
                    '',
                    moment(this.state.filter.dateRange.start, DATE_TIME_FORMAT).local().format('L LT'),
                    '',
                    '',
                    moment(this.state.filter.dateRange.end, DATE_TIME_FORMAT).local().format('L LT')
                ],
                [],
                [t('JourneyExport.company'), '', client?.name ?? t('common.unknown')],
                [
                    t('JourneyExport.address'),
                    '',
                    toAddress(
                        this._logic.auth().user().lang,
                        client as EWClient,
                        [
                            {
                                town: (clientNew?.address as any)?.city,
                                country: (clientNew?.address as any)?.country,
                                streetAddress: (clientNew?.address as any)?.street,
                                postalCode: (clientNew?.address as any)?.postcode,
                                countryCode: clientNew?.country?.iso2
                            }
                        ] as AddressStructured[],
                        this._logic.settings().getProp('addressIdentification'),
                        t('common.unknown')
                    )
                ]
            ];
            head = head.concat(filter);

            const headTableTitle: string[] = [
                t('common.day'),
                t('JourneyExport.startLocation'),
                t('common.from'),
                t('JourneyExport.stopLocation'),
                t('common.to'),
                `${t('JourneyExport.odometerEnd')} (${KM})`,
                `${t('common.distance')} (${KM})`,
                `${t('common.duration')} (hh:mm)`,
                t('common.type'),
                t('common.vehicle'),
                t('common.trailer'),
                t('common.driver'),
                t('JourneysTable.driver2'),
                `${t('common.consumption')} ${L}/100${KM}`,
                `${t('JourneysTable.co2')} (${KG})`
            ];
            const columnsCount = headTableTitle.length;

            const body = this.state.table.data.rows?.reduce<(string | number)[][]>((acc, row, i) => {
                // column names
                if (i === 0) {
                    acc.push(headTableTitle);
                }
                // data
                row.activities
                    .sort((a, b) => (moment.utc(a.startTime).isAfter(moment.utc(b.startTime)) ? -1 : 1)) // T033-15986 sort LINES are not same as we see on UI
                    .forEach(subRow => {
                        acc.push([
                            moment.utc(row.date).local().format('L'),
                            toAddressString(
                                lang,
                                client!,
                                subRow.placeStart.addressStructured,
                                addressIdentification ?? AddressIdentification.Address,
                                subRow.placeStart.name ?? ''
                            ) ?? '',
                            moment.utc(subRow.startTime).local().format('LT'),
                            toAddressString(
                                lang,
                                client!,
                                subRow.placeEnd.addressStructured,
                                addressIdentification ?? AddressIdentification.Address,
                                subRow.placeEnd.name ?? ''
                            ) ?? '',
                            moment.utc(subRow.endTime).local().format('LT'),
                            distance(subRow.odometerEnd),
                            distance(subRow.distance),
                            duration(subRow.duration, 'seconds').format('hh:mm', { trim: false }),
                            subRow.type,
                            subRow.vehicle,
                            subRow.trailerRn,
                            subRow.driver,
                            subRow.driver2,
                            Math.round(subRow.afcL100Km * 10) / 10,
                            Math.round((subRow.co2Emission / 1000) * 10) / 10
                        ]);
                    });

                return acc;
            }, []);

            const footer = [
                [
                    t('JourneyExport.odometerEnd'),
                    '',
                    `${numeral(distance(this.state.table?.data?.sum?.odometerEnd ?? 0)).format('0,0.00')} ${KM}`
                ],
                [
                    t('JourneyExport.distanceBusiness'),
                    '',
                    `${numeral(
                        distance(
                            this.state.table.data.rows.reduce((ac, curr) => {
                                const rowBusinessKm = curr.activities.filter(a => a.type === 'B');
                                const rowKmSum = rowBusinessKm.reduce((ac, km) => ac + km.distance, 0);
                                return ac + rowKmSum;
                            }, 0)
                        )
                    ).format('0,0.00')} ${KM}`
                ],
                [
                    t('JourneyExport.distancePrivate'),
                    '',
                    `${numeral(
                        distance(
                            this.state.table.data.rows.reduce((ac, curr) => {
                                const rowPrivateKm = curr.activities.filter(a => a.type === 'P');
                                const rowKmSum = rowPrivateKm.reduce((ac, km) => ac + km.distance, 0);
                                return ac + rowKmSum;
                            }, 0)
                        )
                    ).format('0,0.00')} ${KM}`
                ],
                [
                    t('JourneyExport.distanceTotal'),
                    '',
                    `${numeral(
                        distance(
                            this.state.table.data.rows.reduce((ac, curr) => {
                                const rowKmSum = curr.activities.reduce((ac, km) => ac + km.distance, 0);
                                return ac + rowKmSum;
                            }, 0)
                        )
                    ).format('0,0.00')} ${KM}`
                ],
                [
                    t('common.duration'),
                    '',
                    `${duration(this.state.table?.data?.sum?.duration, 'seconds').format('hh:mm', { trim: false })}  h`
                ],
                [
                    t('JourneyExport.numberOfRoutesBusiness'),
                    '',
                    String(
                        this.state.table.data.rows.reduce((ac, curr) => {
                            const tripTipe = curr.activities.filter(a => a.type === 'B');
                            return ac + tripTipe.length;
                        }, 0)
                    )
                ],
                [
                    t('JourneyExport.numberOfRoutesPrivate'),
                    '',
                    String(
                        this.state.table.data.rows.reduce((ac, curr) => {
                            const tripTipe = curr.activities.filter(a => a.type === 'P');
                            return ac + tripTipe.length;
                        }, 0)
                    )
                ],
                [
                    t('JourneyExport.refueledLitersQ'),
                    '',
                    `${numeral(this.state.table?.data?.sum?.units).format('0,0.00')} ${L}`
                ],
                [
                    t('JourneyExport.refueledLitersEur'),
                    '',
                    `${numeral(this.state.table?.data?.sum?.price).format('0,0.00')} ${EUR}`
                ],
                [
                    t('JourneyExport.AFCL100km'),
                    '',
                    `${numeral(this.state.table?.data?.sum?.afcL100Km).format('0,0.00')} ${L} / 100 ${KM}`
                ],
                [
                    t('common.consumption'),
                    '',
                    `${numeral(this.state.table?.data?.sum?.consumption).format('0,0.0')} ${L}`
                ],
                [
                    t('JourneysTable.co2'),
                    '',
                    `${numeral((this.state.table?.data?.sum?.co2Emission ?? 0) / 1000).format('0,0.0')} ${KG}`
                ]
            ];

            // data push + styles
            // ------------------
            let lastRow = 0;

            lastRow = workbook
                .sheet(0)
                .range(1, 1, head.length, columnsCount)
                .value(head)
                .style({ fill: { color: 'FFFFFF', type: 'solid' } } as Style)
                .forEach((cell: Cell, ri: number, ci: number) => {
                    if (ri === 0) cell.style({ bold: true, fontSize: 14 } as Style);
                    if (ri > 1) {
                        cell.style('fill', 'EFEFEF');
                        if (ri === 2) cell.style({ topBorderStyle: 'medium' } as Style);
                        if (ri === 3 + filter.length) cell.style({ bottomBorderStyle: 'medium' } as Style);
                        if (ci === 0) cell.style({ leftBorderStyle: 'medium' } as Style);
                        if (ci === columnsCount - 1) cell.style({ rightBorderStyle: 'medium' } as Style);
                    }
                })
                .endCell()
                .rowNumber();

            lastRow = workbook
                .sheet(0)
                .range(lastRow + 2, 1, lastRow + 1 + body.length, columnsCount)
                .value(body)
                .style('fill', 'FFFFFF')
                .forEach((cell: Cell, ri: number, ci: number) => {
                    if (ri === 0) {
                        cell.style({
                            bold: true,
                            fill: { color: 'D0D0D0' },
                            topBorderStyle: 'medium',
                            bottomBorderStyle: 'medium'
                        } as Style);
                        if (ci === 0) cell.style({ leftBorderStyle: 'medium' } as Style);
                        if (ci === columnsCount - 1) cell.style({ rightBorderStyle: 'medium' } as Style);
                    }
                    if (ri > 0) {
                        cell.style({ border: 'medium', fill: { color: 'EFEFEF', type: 'solid' } } as Style);
                        // remove borders
                        if ([2, 4].some(col => col === ci)) cell.style({ leftBorderColor: 'EFEFEF' } as Style);
                        if (ri !== body.length - 1) cell.style({ bottomBorderColor: 'EFEFEF' } as Style);
                    }
                })
                .endCell()
                .rowNumber();

            workbook
                .sheet(0)
                .range(lastRow + 2, 1, lastRow + footer.length + 1, columnsCount)
                .value(footer)
                .style({ border: 'medium', fill: { color: 'D4DFEE', type: 'solid' } } as Style)
                .forEach((cell: Cell, ri: number, ci: number) => {
                    // remove borders
                    if (ci > 0) cell.style({ leftBorderColor: 'D4DFEE' } as Style);
                    if (ri > 0) cell.style({ topBorderColor: 'D4DFEE' } as Style);
                    const row = ri + lastRow + 2;
                    workbook.sheet(0).range(`C${row}:D${row}`).merged(true);
                })
                .endCell()
                .rowNumber();

            // column width
            // https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
            [15, 60, 8, 60, 8, 20, 20, 16, 6, 20, 20, 20, 22, 10].forEach((width, i) => {
                // 65 => 'A'...'L'
                const column = String.fromCharCode(65 + i);
                workbook.sheet(0).column(column).width(width);
            });

            const blob = (await workbook.outputAsync()) as Blob;
            const filterName = this.state.filter?.vehicle
                ? changeToNoSpaceString(` ${t('common.vehicle')} ${filter[0][2]}`, '_')
                : this.state.filter?.driver
                ? changeToNoSpaceString(` ${t('common.driver')} ${filter[0][2]}`, '_')
                : this.state.filter?.trailer
                ? changeToNoSpaceString(` ${t('common.trailer')} ${filter[0][2]}`, '_')
                : '';
            downloadFile(
                blob,
                changeToNoSpaceString(
                    `${i18n.t('JourneyExport.exportedFilename')}${filterName} ${i18n.t(
                        'JourneyExport.exportedPeriod'
                    )} ${moment(this.state.filter.dateRange.start, DATE_TIME_FORMAT)
                        .local()
                        .format('L')
                        .replace('.', '_')}-${moment(this.state.filter.dateRange.end, DATE_TIME_FORMAT)
                        .local()
                        .format('L')
                        .replace('.', '_')}.xlsx`,
                    '_'
                )
            );
            this.setState({ exportLoading: false });
        }
    };

    private _getPremiumVisible = (anyDwlTripsFilteredOut?: boolean): boolean => {
        const vehicleDWL =
            this._logic
                .vehicles()
                .monitoredObjectsSimple.find(mobject => String(mobject.id) === this.state.filter.vehicle)
                ?.roles?.includes(Role.DWL) ?? false;
        const trailerDWL =
            this._logic
                .vehicles()
                .monitoredObjectsSimple.find(mobject => String(mobject.id) === this.state.filter.trailer)
                ?.roles?.includes(Role.DWL) ?? false;

        return (
            moment(this.state.filter.dateRange.start).isBefore(
                moment()
                    .subtract(DWL_LOCK - 1, 'days')
                    .endOf('day')
            ) &&
            (vehicleDWL || trailerDWL || (this.state.filter.fullDWL ?? false) || (anyDwlTripsFilteredOut ?? false))
        );
    };

    private _handleClosePremiumInfoClick = () => {
        this.setState({ showPremiumInfo: false });
    };

    private _handleCloseCoachPromoInfoClick = () => {
        this._logic.driverBehaviorCoach().trucks().saveJourneyActivityPromoSettings(true);
        this.setState({ showCoachInfo: this._logic.driverBehaviorCoach().trucks().showJourneyActivityPromo() });
    };

    private _handleCoachPromoLinkClick = () => {
        this._handleCloseCoachPromoInfoClick();
        window.open(DRIVER_BEHAVIOR_UPGRADE_LINK, '_blank');
    };
}

export default withTranslation()(withRouter(JourneysModule));
