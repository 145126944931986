import { AddressIdentification } from 'common/components/Settings';
import i18n from 'i18next';
import { DATE_FORMAT, KM } from 'domain-constants';
import { CreateManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRequest } from 'generated/backend-api/apis/BorderCrossingApi';
import { ManualCountryIntervalInDb } from 'generated/backend-api/models';
import { durationToFormated } from 'common/utils/actual';
import { toAddress } from 'common/utils/address';
import { DriverModel } from 'logic/user/users';
import {
    BorderCrossingCountryModel,
    BorderCrossingModel
} from 'modules/statistics/modules/border-crossing/StatisticsBorderCrossingModule';
import moment from 'moment';
import { Subject } from 'rxjs';
import { Logic } from '../logic';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import numeral from 'numeral';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';

export class BorderCrossing {
    manualCrossingLoading = new Subject<boolean>();
    manualCrossingData = new Subject<ManualCountryIntervalInDb[]>();
    manualCrossingError = new Subject<any>();

    constructor(private _logic: Logic) {}

    async createManualCrossing(data: CreateManualCountryIntervalV1BordercrossingsManualCountryIntervalPostRequest) {
        try {
            await this._logic
                .api()
                .borderCrossingApi.createManualCountryIntervalV1BordercrossingsManualCountryIntervalPost(data);
        } catch (err) {
            console.log('Cannot create manual crossing');
            throw err;
        }
    }

    async getManualCrossings(userId: number, dateFrom: Date, dateTo: Date) {
        this.manualCrossingLoading.next(true);
        try {
            const data = await this._logic
                .api()
                .borderCrossingApi.getManualCountryIntervalV1BordercrossingsManualCountryIntervalGet({
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    driverId: userId
                });
            this.manualCrossingData.next(data);
        } catch (err) {
            this.manualCrossingError.next(err);
        } finally {
            this.manualCrossingLoading.next(false);
        }
    }

    async deleteManualCrossing(id: string) {
        await this._logic
            .api()
            .borderCrossingApi.deleteManualCountryIntervalV1BordercrossingsManualCountryIntervalDelete({
                countryIntervalId: id
            });
    }

    async downloadBorderCrossingExport(
        borderCrossingSummaryData: BorderCrossingCountryModel[],
        borderCrossingData: BorderCrossingModel[],
        from: string,
        to: string,
        drivers?: DriverModel[],
        vehicles?: ReadOnlyMonitoredObjectFeSb[]
    ) {
        const workbook: Workbook = await fromBlankAsync();
        workbook.sheet(0).name(normalizeExportSheetName(i18n.t('BorderCrossing.title')));

        const fromStrFormat = moment(from, DATE_FORMAT).format('L');
        const toStrFormat = moment(to, DATE_FORMAT).format('L');
        const headDescription = [
            [
                i18n.t('BorderCrossing.title'),
                '',
                moment(from, DATE_FORMAT).format('L'),
                '',
                moment(to, DATE_FORMAT).format('L')
            ],
            []
        ];

        const headSummaryTitle = [
            [
                i18n.t('BorderCrossingTable.country').toUpperCase(),
                i18n.t('BorderCrossingSummary.entries').toUpperCase(),
                i18n.t('common.duration').toUpperCase(),
                i18n.t('common.distance').toUpperCase()
            ]
        ];

        const headTableTitle = [
            [
                i18n.t('BorderCrossingTable.country').toUpperCase(),
                i18n.t('common.driver').toUpperCase(),
                i18n.t('common.vehicle').toUpperCase(),
                i18n.t('BorderCrossingTable.entryDate').toUpperCase(),
                i18n.t('BorderCrossingTable.entryLocation').toUpperCase(),
                i18n.t('BorderCrossingTable.exitDate').toUpperCase(),
                i18n.t('BorderCrossingTable.exitLocation').toUpperCase(),
                i18n.t('common.distance').toUpperCase(),
                i18n.t('common.duration').toUpperCase()
            ]
        ];

        const summaryBody = borderCrossingSummaryData.map(sd => {
            return [
                sd.country,
                `${sd.entries} x`,
                durationToFormated(moment.duration(sd.duration, 'seconds')).trim(),
                `${numeral(sd.distance).format('0,0')} ${KM}`
            ];
        });

        const tableBody = borderCrossingData.map(bc => {
            const driver = drivers?.find(d => d.id === bc.driver);
            const vehicle = vehicles?.find(v => v.id === Number(bc.vehicle));
            const entryLocation = toAddress(
                this._logic.auth().user().lang,
                this._logic.auth().client()!,
                bc.addressStructuredEntry,
                AddressIdentification.Address,
                bc.entryLocation
            );
            const exitLocation = toAddress(
                this._logic.auth().user().lang,
                this._logic.auth().client()!,
                bc.addressStructuredExit,
                AddressIdentification.Address,
                bc.exitLocation
            );

            return [
                bc.country,
                `${driver?.name ?? ''} ${driver?.surname ?? ''}`,
                vehicle ? getRegistrationNumber(!!vehicle.disabledAt, vehicle.registrationNumber) : '',
                moment.utc(bc.entryDate).local().format('L LT'),
                entryLocation,
                moment.utc(bc.exitDate).local().format('L LT'),
                exitLocation,
                `${numeral(bc.distance).format('0,0')} ${KM}`,
                durationToFormated(moment.duration(bc.duration, 'seconds')).trim()
            ];
        });

        workbook.sheet(0).range(1, 1, 1, 2).merged(true);
        workbook.sheet(0).range(1, 3, 1, 4).merged(true);
        workbook.sheet(0).range(1, 5, 1, 6).merged(true);

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;
        const summaryTitleStartRow = 3;
        const summaryTitleEndRow = 3;
        const summaryDataStartRow = 4;
        const summaryDataEndRow = 4 + summaryBody.length - 1;
        const tableTitleStartRow = summaryDataEndRow + 2; // +2. there is empty row
        const tableTitleEndRow = summaryDataEndRow + 2;
        const tableDataStartRow = tableTitleStartRow + 1;
        const tableDataEndRow = tableDataStartRow + tableBody.length - 1;

        const workbookDescriptionRange = workbook.sheet(0).range(descriptionStartRow, 1, descriptionEndRow, 9);
        const workbookSummaryTitleRange = workbook.sheet(0).range(summaryTitleStartRow, 1, summaryTitleEndRow, 9);
        const workbookSummaryDataRange = workbook.sheet(0).range(summaryDataStartRow, 1, summaryDataEndRow, 9);
        const workbookTableTitleRange = workbook.sheet(0).range(tableTitleStartRow, 1, tableTitleEndRow, 9);
        const workbookTableBodyRange = workbook.sheet(0).range(tableDataStartRow, 1, tableDataEndRow, 9);

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true
        };
        const dataStyle = {
            fill: {
                color: 'EDEDED',
                type: 'solid'
            }
        };

        workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);
        workbookSummaryTitleRange.style({ ...titleDescriptionStyle, bottomBorder: true }).value(headSummaryTitle);
        workbookTableTitleRange.style({ ...titleDescriptionStyle, bottomBorder: true }).value(headTableTitle);
        workbookSummaryDataRange.style(dataStyle).value([...summaryBody, []]);
        workbookTableBodyRange.style(dataStyle).value(tableBody);

        [10, 20, 20, 30, 40, 30, 40, 15, 15].forEach((width, i) => {
            workbook
                .sheet(0)
                .column(i + 1)
                .width(width);
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('BorderCrossing.title')} ${fromStrFormat} - ${toStrFormat}.xlsx`);
    }
}
