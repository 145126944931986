/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationReceivers
 */
export interface NotificationReceivers {
    /**
     * 
     * @type {string}
     * @memberof NotificationReceivers
     */
    entity: NotificationReceiversEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationReceivers
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationReceivers
     */
    channel: NotificationReceiversChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationReceivers
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationReceivers
     */
    email?: string | null;
}

export function NotificationReceiversFromJSON(json: any): NotificationReceivers {
    return NotificationReceiversFromJSONTyped(json, false);
}

export function NotificationReceiversFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationReceivers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': json['entity'],
        'entityId': json['entity_id'],
        'channel': json['channel'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function NotificationReceiversToJSON(value?: NotificationReceivers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': value.entity,
        'entity_id': value.entityId,
        'channel': value.channel,
        'name': value.name,
        'email': value.email,
    };
}

/**
* @export
* @enum {string}
*/
export enum NotificationReceiversEntityEnum {
    User = 'user',
    Group = 'group',
    Role = 'role',
    External = 'external'
}
/**
* @export
* @enum {string}
*/
export enum NotificationReceiversChannelEnum {
    Email = 'email',
    App = 'app'
}


