import {
    IncreaseMoDataRateDocument,
    IncreaseMoDataRateQuery,
    IncreaseMoDataRateQueryVariables,
    VehiclesSubscribeDocument,
    VehiclesSubscribeQuery,
    VehiclesSubscribeQueryVariables,
    VehicleStateObject
} from '../generated/graphql';
import { Logic } from './logic';

export class VehiclesStateLogic {
    private _logic: Logic;
    data?: VehicleStateObject[];

    constructor(logic: Logic) {
        this._logic = logic;
    }

    async getData(device: string, reload: boolean = true): Promise<VehicleStateObject[]> {
        try {
            if (this._logic.demo().isActive) {
                this.data = this._logic.demo().data.vehicleStates;
                return this.data;
            } else {
                if (reload || !this.data) {
                    const res = await this._logic
                        .apollo()
                        .query<VehiclesSubscribeQuery, VehiclesSubscribeQueryVariables>({
                            query: VehiclesSubscribeDocument,
                            variables: {
                                device
                            },
                            fetchPolicy: 'no-cache'
                        });

                    if (res.data?.vehiclesSubscribe) {
                        this.data = res.data.vehiclesSubscribe;
                        return this.data;
                    } else {
                        return [];
                    }
                } else {
                    return this.data;
                }
            }
        } catch (err) {
            console.log('Increase vehicle updates err', err);
            throw err;
        }
    }

    async vehicle(id: string, reload?: boolean): Promise<VehicleStateObject | undefined> {
        try {
            let vehicle = this.data?.find(v => v.monitoredObjectId === id);

            if (!this.data || !vehicle) {
                try {
                    await this.getData(this._logic.notification().device!, reload);
                    vehicle = this.data?.find(v => v.monitoredObjectId === id);
                } catch (err) {
                    console.log('Increase vehicle updates no data err', err);
                    throw err;
                }
            }
            return vehicle;
        } catch (err) {
            console.log('Increase vehicle updates err', err);
            throw err;
        }
    }

    async increaseVehicleUpdates(vehicleId: string): Promise<boolean> {
        try {
            const res = await this._logic.apollo().query<IncreaseMoDataRateQuery, IncreaseMoDataRateQueryVariables>({
                query: IncreaseMoDataRateDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    monitoredObjectId: Number(vehicleId),
                    duration: 60,
                    interval: 1
                }
            });
            return !!res.data?.event_IncreaseMonitoredObjectDataRate;
        } catch (err) {
            console.log('Increase vehicle updates err', err);
            throw err;
        }
    }
}
