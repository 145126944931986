import { Role } from 'logic/auth';
import { WithTranslation, withTranslation } from 'react-i18next';

import { AetrFilterModel, SearchData } from '../../AetrModule';
import TableBar from 'common/components/TableBar';
import AetrFilter from '../AetrFilter';
import { confDefault } from 'conf';
import { arrayEquals } from 'common/utils/textUtils';

interface Props extends WithTranslation {
    search?: SearchData;
    filter: AetrFilterModel;
    filterOpen?: boolean;
    helper?: boolean;
    roles: Role[];
    onFilterClick?: () => void;
    onResetClick?: () => void;
    onHelperClick?: () => void;
    onSearch?: () => void;
    onSearchChange?: (text: string) => void;
    onSearchEsc?: () => void;
    onFilterConfirm?: (driversChecked: string[], vehiclesChecked: string[]) => void;
    onFilterCancel?: () => void;
}

function AetrBar(props: Props) {
    const { t } = props;
    const defaults = confDefault.settings.statistics.aetr.filter;

    function onFilterClick() {
        if (props.roles.includes(Role.AER_R)) {
            props.onFilterClick?.();
        }
    }

    return (
        <>
            <TableBar
                heading={t('AetrTableBar.name')}
                search={props.search}
                onSearchChange={props.roles.includes(Role.AER_R) ? props.onSearchChange : undefined}
                onHelperClick={props.onHelperClick}
                filter={{
                    activeCount:
                        (arrayEquals(props.filter.vehiclesChecked, defaults.vehicles) ||
                        props.filter.vehiclesChecked.length === props.filter.vehiclesOpts.length
                            ? 0
                            : 1) +
                        (arrayEquals(props.filter.driversChecked, defaults.drivers) ||
                        props.filter.driversChecked.length === props.filter.driversOpts.length
                            ? 0
                            : 1),
                    onClick: props.roles.includes(Role.AER_R) ? onFilterClick : undefined,
                    resetButton: { onClick: props.onResetClick }
                }}
            />
            {props.filterOpen && (
                <AetrFilter
                    driversOpts={props.filter.driversOpts}
                    vehiclesOpts={props.filter.vehiclesOpts}
                    driversChecked={props.filter.driversChecked}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    onConfirm={props.onFilterConfirm}
                    onCancel={props.onFilterCancel}
                />
            )}
        </>
    );
}

export default withTranslation()(AetrBar);
