/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOConstants,
    TCOConstantsFromJSON,
    TCOConstantsFromJSONTyped,
    TCOConstantsToJSON,
    TCOSettingArrayDiesel,
    TCOSettingArrayDieselFromJSON,
    TCOSettingArrayDieselFromJSONTyped,
    TCOSettingArrayDieselToJSON,
    TCOSettingArrayLNG,
    TCOSettingArrayLNGFromJSON,
    TCOSettingArrayLNGFromJSONTyped,
    TCOSettingArrayLNGToJSON,
    TCOSettingChar,
    TCOSettingCharFromJSON,
    TCOSettingCharFromJSONTyped,
    TCOSettingCharToJSON,
    TCOSettingFloat,
    TCOSettingFloatFromJSON,
    TCOSettingFloatFromJSONTyped,
    TCOSettingFloatToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOSettingsOutput
 */
export interface TCOSettingsOutput {
    /**
     * 
     * @type {TCOSettingChar}
     * @memberof TCOSettingsOutput
     */
    operationalLifetime: TCOSettingChar;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof TCOSettingsOutput
     */
    annualMileage: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingArrayLNG}
     * @memberof TCOSettingsOutput
     */
    lngVehicleDetails: TCOSettingArrayLNG;
    /**
     * 
     * @type {TCOSettingArrayDiesel}
     * @memberof TCOSettingsOutput
     */
    dieselVehicleDetails: TCOSettingArrayDiesel;
    /**
     * 
     * @type {TCOConstants}
     * @memberof TCOSettingsOutput
     */
    constants: TCOConstants;
}

export function TCOSettingsOutputFromJSON(json: any): TCOSettingsOutput {
    return TCOSettingsOutputFromJSONTyped(json, false);
}

export function TCOSettingsOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOSettingsOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operationalLifetime': TCOSettingCharFromJSON(json['operational_lifetime']),
        'annualMileage': TCOSettingFloatFromJSON(json['annual_mileage']),
        'lngVehicleDetails': TCOSettingArrayLNGFromJSON(json['lng_vehicle_details']),
        'dieselVehicleDetails': TCOSettingArrayDieselFromJSON(json['diesel_vehicle_details']),
        'constants': TCOConstantsFromJSON(json['constants']),
    };
}

export function TCOSettingsOutputToJSON(value?: TCOSettingsOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operational_lifetime': TCOSettingCharToJSON(value.operationalLifetime),
        'annual_mileage': TCOSettingFloatToJSON(value.annualMileage),
        'lng_vehicle_details': TCOSettingArrayLNGToJSON(value.lngVehicleDetails),
        'diesel_vehicle_details': TCOSettingArrayDieselToJSON(value.dieselVehicleDetails),
        'constants': TCOConstantsToJSON(value.constants),
    };
}


