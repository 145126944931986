import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { TachoCardModel } from '../TachoCardsModule';
import qa from 'qa-selectors';

interface Props {
    data: TachoCardModel;
}

export default function TachoCardDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ManagementTachoCards.id')}>
                            <span data-qa={qa.tacho.detail.fieldToken}>{props.data.token}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.surname')}>
                            <span data-qa={qa.tacho.detail.fieldSurname}>{props.data.surname}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>
                            <span data-qa={qa.tacho.detail.fieldName}>{props.data.name}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
