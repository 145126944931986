import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { MAP_MARKERS_INDEX } from 'domain-constants';
import { google } from 'google-maps';
import { MapConf, MapLogic } from '../map';
import { PoiModelMap } from './fuelStations';
import { PoiMarker } from './poi-marker';
import * as mapMarkers from 'resources/images/map';

export class ParkingsMapController {
    private _conf: MapConf;
    private _ctrl: MapLogic;
    private _map?: google.maps.Map;
    private _markers: PoiMarker[];
    private _cluster?: MarkerClusterer;
    private _google: google;

    private _infoWindow?: google.maps.InfoWindow;

    private _onParkingClick?: (parking: PoiModelMap) => void;
    private _onParkingClusterClick?: () => void;

    private readonly _clusterOptions: MarkerClustererOptions = {
        maxZoom: 20,
        gridSize: 150,
        zoomOnClick: false,
        enableRetinaIcons: false,
        clusterClass: 'p-cluster',
        styles: [
            {
                url: mapMarkers.parking,
                height: 49,
                width: 64,
                textSize: 18,
                textColor: '#000000'
            }
        ],
        zIndex: MAP_MARKERS_INDEX.PARKING
    };

    constructor(conf: MapConf, google: google, ctrl: MapLogic, map?: google.maps.Map) {
        this._map = map;
        this._conf = conf;
        this._google = google;
        this._markers = [];
        this._ctrl = ctrl;
    }

    show(): void {
        this._renderCluster();
    }

    hide(): void {
        if (this._cluster) {
            this._cluster.clearMarkers();
            this._cluster.setMap(null);
        }
    }

    onClick(cb?: (parking: PoiModelMap) => void): void {
        this._onParkingClick = cb;
    }

    onParkingClusterClick(cb?: () => void): void {
        this._onParkingClusterClick = cb;
    }
    setData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster!.clearMarkers();
        }
        this._markers = data.map(parking => this._createParkingMarker(parking));
    }

    updateData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster!.clearMarkers();
        }
        this._markers = data.map(parking => this._createParkingMarker(parking));
        this._renderCluster();
    }

    updateDetailData(data: PoiModelMap[]): void {
        this._markers = data.map(parking => this._createParkingMarker(parking));
        this._renderCluster();
    }

    private _onClusterClick = (cluster: MarkerClusterer) => {
        const bounds = new this._google.maps.LatLngBounds();

        cluster.getMarkers().forEach(marker => bounds.extend(marker.getPosition()!));

        this._onParkingClusterClick?.();
        this._map?.fitBounds(bounds, this._ctrl.getPadding());
    };

    private _renderCluster(): void {
        if (this._map) {
            if (this._cluster) {
                this._cluster.clearMarkers();
            }
            this._cluster = new MarkerClusterer(this._map, this._markers as any, this._clusterOptions);
            this._cluster!.addListener('clusterclick', this._onClusterClick);
        }
    }

    private _createParkingMarker(parking: PoiModelMap): PoiMarker {
        const marker = new PoiMarker(
            this._google,
            new this._google.maps.LatLng(parking.position.lat, parking.position.lng),
            {
                type: 'parking',
                data: { ...parking }
            }
        );

        marker.onPoiClick(poiData => {
            if (poiData.type === 'parking') {
                this._onParkingClick?.(poiData.data);
            }
        });

        return marker;
    }
}
