/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TruckBody {
    Tent = 'tent',
    Isotherm = 'isotherm',
    BoxTruck = 'box-truck',
    CarTransporter = 'car-transporter',
    DoubleTrailer = 'double-trailer',
    Van = 'van',
    Mega = 'mega',
    LowSuspension = 'low-suspension',
    Flatbed = 'flatbed',
    ChemicalTanker = 'chemical-tanker',
    FoodTanker = 'food-tanker',
    PetroleumTanker = 'petroleum-tanker',
    GasTanker = 'gas-tanker',
    LogTrailer = 'log-trailer',
    OversizedCargo = 'oversized-cargo',
    Container2040 = 'container-20-40',
    DumpTruck = 'dump-truck',
    Koffer = 'koffer',
    Jumbo = 'jumbo',
    Cooler = 'cooler',
    Curtainsider = 'curtainsider',
    Tanker = 'tanker',
    Silos = 'silos',
    RemovalTruck = 'removal-truck',
    Other = 'other'
}

export function TruckBodyFromJSON(json: any): TruckBody {
    return TruckBodyFromJSONTyped(json, false);
}

export function TruckBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TruckBody {
    return json as TruckBody;
}

export function TruckBodyToJSON(value?: TruckBody | null): any {
    return value as any;
}

