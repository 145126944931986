import { ReactNode } from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';

interface Props extends TagProps {
    children: ReactNode;
    qa?: string;
}

export default function EnhancedTag({ qa, ...props }: Props) {
    return <Tag data-qa={qa} {...props} />;
}
