/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CustomPlaceCrossingType {
    Entry = 'entry',
    Exit = 'exit'
}

export function CustomPlaceCrossingTypeFromJSON(json: any): CustomPlaceCrossingType {
    return CustomPlaceCrossingTypeFromJSONTyped(json, false);
}

export function CustomPlaceCrossingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomPlaceCrossingType {
    return json as CustomPlaceCrossingType;
}

export function CustomPlaceCrossingTypeToJSON(value?: CustomPlaceCrossingType | null): any {
    return value as any;
}

