import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

function SuperAdminBar(props: Props) {
    return (
        <div className="superadmin-bar">
            <h1>{props.t('SuperAdminBar.title')}</h1>
        </div>
    );
}

export default withTranslation()(SuperAdminBar);
