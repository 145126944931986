import { Row, Col } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import qa from 'qa-selectors';
import { BoardGridConfig } from '../../dispatcher-board-logic';

interface Props {
    config: BoardGridConfig;
    selectedDay: string;
}

export default function BoardNavigation(props: Props) {
    function getDayFromIndex(index: number) {
        return moment(props.selectedDay)
            .subtract(props.config.activeDayIndex - 1, 'days')
            .startOf('day')
            .add(index, 'days');
    }

    function getDays(): number[] {
        return [...Array(props.config.visibleDays)].map((_, i) => i);
    }

    function getHours() {
        return (
            <Row justify="space-around" align="middle">
                <Col>02</Col>
                <Col>06</Col>
                <Col>10</Col>
                <Col>14</Col>
                <Col>18</Col>
                <Col>22</Col>
            </Row>
        );
    }

    return (
        <div className="board-navigation">
            {getDays().map(i => (
                <div
                    key={i}
                    className={cn('board-navigation-day', {
                        'board-navigation-day-selected': moment(getDayFromIndex(i)).isSame(props.selectedDay, 'day')
                    })}
                    style={{ width: `${Math.round(100 / props.config.visibleDays)}%` }}
                    data-qa={
                        moment(getDayFromIndex(i)).isSame(props.selectedDay, 'day')
                            ? qa.dispatcherBoard.navigation.sectionDaySelected
                            : undefined
                    }
                >
                    <div className="board-navigation-day-title">
                        <strong>{getDayFromIndex(i).format('dddd')}</strong>
                        <span data-qa={qa.dispatcherBoard.navigation.fieldDate}>{getDayFromIndex(i).format('L')}</span>
                    </div>
                    <div className="board-navigation-day-hours">{getHours()}</div>
                </div>
            ))}
        </div>
    );
}
