import { Col, Row } from 'antd';
import i18next from 'i18next';
import { trendNone, trendNegative, trendPositive, trendNeutralPlus, trendNeutralMinus } from 'resources/images/common';
import { Theme } from '../Settings';

interface Props {
    value?: number;
    direction: TrendDirection;
    trendChangeForIncrease: TrendChange;
    hidePercent?: boolean;
    small?: boolean;
    theme?: Theme;
}

export enum TrendChange {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral'
}

export enum TrendDirection {
    UP = 'up',
    DOWN = 'down',
    NONE = 'none'
}

function Trend(props: Props) {
    const zeroValue =
        (props.trendChangeForIncrease === TrendChange.POSITIVE ||
            props.trendChangeForIncrease === TrendChange.NEGATIVE) &&
        props.value === 0;

    return (
        <Row
            gutter={[4, 0]}
            align="middle"
            className={`trend trend-${
                props.direction === TrendDirection.NONE
                    ? 'none'
                    : props.trendChangeForIncrease === TrendChange.NEUTRAL || zeroValue
                    ? 'neutral'
                    : (props.trendChangeForIncrease === TrendChange.POSITIVE &&
                          props.direction === TrendDirection.UP) ||
                      (props.trendChangeForIncrease === TrendChange.NEGATIVE && props.direction === TrendDirection.DOWN)
                    ? 'positive'
                    : 'negative'
            } trend-${props.direction} trend-theme-${props.theme}`}
        >
            {props.value !== undefined ? (
                <>
                    <Col>
                        <img
                            src={
                                props.direction === TrendDirection.NONE
                                    ? trendNone
                                    : props.trendChangeForIncrease === TrendChange.NEUTRAL || zeroValue
                                    ? Math.abs(props.value) > 0
                                        ? trendNeutralPlus
                                        : trendNeutralMinus
                                    : (props.trendChangeForIncrease === TrendChange.POSITIVE &&
                                          props.direction === TrendDirection.UP) ||
                                      (props.trendChangeForIncrease === TrendChange.NEGATIVE &&
                                          props.direction === TrendDirection.DOWN)
                                    ? trendPositive
                                    : trendNegative
                            }
                            alt="trend"
                        />
                    </Col>
                    {props.direction !== TrendDirection.NONE && (
                        <Col>
                            {props.direction === TrendDirection.UP && '+'}
                            {props.direction === TrendDirection.DOWN && '−'}
                            {Math.abs(props.value)}
                            {!props.hidePercent && '%'}
                        </Col>
                    )}
                </>
            ) : (
                <div className="no-data-trend">
                    {props.small ? i18next.t('common.noData').toLowerCase() : i18next.t('DriverBehavior.noDataTrend')}
                </div>
            )}
        </Row>
    );
}

export default Trend;
