import numeral from 'numeral';
import moment from 'moment';
import { Col, Row } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { JourneyStatisticsResponseStats } from 'generated/backend-api';
import { H, KG, KM, L, M } from 'domain-constants';
import { useTranslation } from 'react-i18next';
import { Loading } from 'common/components';
import qa from 'qa-selectors';
import { drive, fuel, time, co2e } from 'resources/images/statistic-report';

interface Props extends WithTranslation {
    loading?: boolean;
    data: JourneyStatisticsResponseStats[];
}

function StatisticReportSummary(props: Props) {
    const { t } = useTranslation();

    function generateTimeLabel(seconds: number, qa?: string) {
        const days = Math.floor(moment.duration(seconds, 'seconds').asDays());
        const daysLabel = days ? `${days} ${t('commonPlurals.days', { count: days })}` : '';
        const hoursMinutesLabel = moment.duration(seconds, 'seconds').subtract(days, 'days').format('hh:mm', {
            trim: false
        });
        return (
            <>
                <span data-qa={qa}>
                    {daysLabel} {hoursMinutesLabel}
                </span>{' '}
                [{H}:{M}]
            </>
        );
    }

    const processedData = props.data.map(record => {
        return [
            {
                label: props.t('StatisticReport.drive'),
                type: 'drive',
                data: [
                    {
                        label: props.t('common.distance'),
                        value: (
                            <>
                                <span data-qa={qa.statisticReport.summary.fieldDistance}>
                                    {numeral(record.distanceKilometers).format('0,0')}
                                </span>{' '}
                                {KM}
                            </>
                        )
                    },
                    {
                        label: props.t('StatisticReport.countOfJourneys'),
                        value: (
                            <span data-qa={qa.statisticReport.summary.fieldDistance}>
                                {numeral(record.ntrips).format('0,0')}
                            </span>
                        )
                    }
                ]
            },
            {
                label: props.t('common.time'),
                type: 'time',
                data: [
                    {
                        label: props.t('common.duration'),
                        value: generateTimeLabel(record.activityTimeSeconds)
                    },
                    {
                        label: props.t('JourneysTable.idling'),
                        value: generateTimeLabel(record.idleTimeSeconds)
                    },
                    {
                        label: props.t('JourneysTable.stopped'),
                        value: generateTimeLabel(record.stoppedTimeSeconds)
                    }
                ]
            },
            {
                label: props.t('StatisticReport.fuelConsumption'),
                type: 'fuel',
                data: [
                    {
                        label: props.t('StatisticReport.consumption'),
                        value: `${numeral(record.consumptionLiters).format('0,0')} ${L}`
                    },
                    {
                        label: props.t('common.averageConsumption'),
                        value: `${numeral(record.consumptionLitersPer100km).format('0,0.0')} ${L}/100 ${KM}`
                    }
                ]
            },
            {
                label: props.t('common.co2Emission'),
                type: 'co2e',
                data: [
                    {
                        label: props.t('StatisticReport.co2eTitle'),
                        value: `${record.co2Emission ? numeral(record.co2Emission / 1000).format('0,0') : '-'} ${KG}`
                    }
                ]
            }
        ];
    });

    function getImage(type: string) {
        switch (type) {
            case 'drive':
                return drive;
            case 'fuel':
                return fuel;
            case 'co2e':
                return co2e;
            default:
            case 'time':
                return time;
        }
    }

    return (
        <div className="statistic-report-summary">
            {props.loading ? (
                <Loading />
            ) : (
                processedData.map((pd, index) => (
                    <Row key={index} gutter={20} align="stretch">
                        {pd.map(section => (
                            <Col key={section.label} span={Math.round(24 / pd.length)}>
                                <div className="statistic-report-summary-section" data-qa={qa.statisticReport.summary}>
                                    <Row
                                        justify="space-between"
                                        align="middle"
                                        className="statistic-report-summary-section-heading"
                                    >
                                        <Col>
                                            <label>{section.label}</label>
                                        </Col>
                                        <Col>
                                            <img src={getImage(section.type)} alt={section.type} />
                                        </Col>
                                    </Row>

                                    {section.data.map(item => (
                                        <Row
                                            key={item.label}
                                            className="statistic-report-summary-section-item"
                                            justify="space-between"
                                        >
                                            <Col className="statistic-report-summary-section-item-label">
                                                {item.label}
                                            </Col>
                                            <Col className="statistic-report-summary-section-item-value">
                                                {item.value}
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                ))
            )}
        </div>
    );
}

export default withTranslation()(StatisticReportSummary);
