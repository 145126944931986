/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PerfectDriveScoreDriver,
    PerfectDriveScoreDriverFromJSON,
    PerfectDriveScoreDriverFromJSONTyped,
    PerfectDriveScoreDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface PerfectDriveScorePeriod
 */
export interface PerfectDriveScorePeriod {
    /**
     * 
     * @type {string}
     * @memberof PerfectDriveScorePeriod
     */
    month: string;
    /**
     * 
     * @type {Array<PerfectDriveScoreDriver>}
     * @memberof PerfectDriveScorePeriod
     */
    score?: Array<PerfectDriveScoreDriver>;
}

export function PerfectDriveScorePeriodFromJSON(json: any): PerfectDriveScorePeriod {
    return PerfectDriveScorePeriodFromJSONTyped(json, false);
}

export function PerfectDriveScorePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDriveScorePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'score': !exists(json, 'score') ? undefined : ((json['score'] as Array<any>).map(PerfectDriveScoreDriverFromJSON)),
    };
}

export function PerfectDriveScorePeriodToJSON(value?: PerfectDriveScorePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'score': value.score === undefined ? undefined : ((value.score as Array<any>).map(PerfectDriveScoreDriverToJSON)),
    };
}


