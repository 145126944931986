/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DTCODownloadedFileApiResult,
    DTCODownloadedFileApiResultFromJSON,
    DTCODownloadedFileApiResultToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface CreateDtcoDownloadDemandsV1DtcoDownloadDemandPostRequest {
    monitoredObjectId?: number;
    userId?: number;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create file demand object in collection
     */
    async createDtcoDownloadDemandsV1DtcoDownloadDemandPostRaw(requestParameters: CreateDtcoDownloadDemandsV1DtcoDownloadDemandPostRequest): Promise<runtime.ApiResponse<DTCODownloadedFileApiResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/download-demand`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DTCODownloadedFileApiResultFromJSON(jsonValue));
    }

    /**
     * Create file demand object in collection
     */
    async createDtcoDownloadDemandsV1DtcoDownloadDemandPost(requestParameters: CreateDtcoDownloadDemandsV1DtcoDownloadDemandPostRequest): Promise<DTCODownloadedFileApiResult> {
        const response = await this.createDtcoDownloadDemandsV1DtcoDownloadDemandPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Health
     */
    async healthHealthGetRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Health
     */
    async healthHealthGet(): Promise<object> {
        const response = await this.healthHealthGetRaw();
        return await response.value();
    }

}
