import eio from 'engine.io-client';
import { Subject } from 'rxjs';

export interface NotificationConf {
    sendUri: string;
    engineio?: {
        uri: string;
        opts: eio.SocketOptions;
    };
}

export type Callback = (message: NotificationMessage) => void;

export interface NotificationMessage<T = any> {
    type: string;
    data?: T;
}

export type PublishMany = {
    message: NotificationMessage;
    user: string;
    device?: string;
}[];

export type PublishToMany = {
    message: NotificationMessage;
    target: {
        user: string;
        device?: string;
    }[];
};

type Auth = () => Promise<string | undefined>;

export class Notification {
    readonly conf: NotificationConf;
    readonly device?: string;

    private _auth?: Auth;

    private _cb: Array<Callback> = [];
    private _cbs: { [type: string]: Array<Callback> } = {};

    private _onDisconnect?: () => void;

    private _eio?: eio.Socket;

    private _reconnect: boolean = false;
    private _connectSubject = new Subject<void>();
    guestUser?: string;

    constructor(conf: NotificationConf, auth?: Auth) {
        this.conf = conf;
        this.device = `${Math.random().toString(36).substr(2)}-${Date.now().toString(36)}`;
        auth && (this._auth = auth);
    }

    onConnect() {
        return this._connectSubject;
    }

    onDisconnect(cb?: () => void) {
        this._onDisconnect = cb;
    }

    connect(auth?: Auth, reconnect = 1e3): Promise<void> {
        this._reconnect = true;
        auth && (this._auth = auth);
        return new Promise((resolve, reject) => {
            auth?.()
                .then(token => {
                    this._disconnect();
                    const opts = {
                        ...this.conf.engineio?.opts
                    };

                    (opts as any).query = {
                        token,
                        device: this.device
                    };
                    if (this.guestUser) {
                        (opts as any).query = {
                            ...(opts as any).query,
                            guestUser: this.guestUser
                        };
                    }

                    this._eio = eio(this.conf.engineio?.uri, opts);
                    this._eio.on('open', () => {
                        this._connectSubject.next();
                        this._eio?.on('message', (message: any) => {
                            this._emit(JSON.parse(message));
                        });
                        resolve();
                    });
                    this._eio.on('error', (err: Error) => {
                        reject(err);
                    });
                    this._eio.on('close', () => {
                        this._onDisconnect?.();
                        setTimeout(() => {
                            this._reconnect && this.connect(auth);
                        }, reconnect);
                    });
                })
                .catch(err => {
                    console.log('Cannot authorize or error on eio connect:', err);
                    return err;
                });
        });
    }

    disconnect() {
        this._reconnect = false;
        this._disconnect();
    }

    async send(message: NotificationMessage, user: string, device?: string): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }
            const res = await fetch(`${this.conf.sendUri}/push/${user}/${device || ''}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(message)
            });
            return res.json();
        } catch (err) {
            console.log('Send message err', err);
            throw err;
        }
    }

    async sendMany(publish: PublishMany): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }
            const res = await fetch(`${this.conf.sendUri}/push/many`, {
                method: 'POST',
                headers,
                body: JSON.stringify(publish)
            });
            return res.json();
        } catch (err) {
            console.log('Send many message err', err);
            throw err;
        }
    }

    async sendToMany(publish: PublishToMany): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }

            const res = await fetch(`${this.conf.sendUri}/push/to-many`, {
                method: 'POST',
                headers,
                body: JSON.stringify(publish)
            });
            return res.json();
        } catch (err) {
            console.log('Send to many message err', err);
            throw err;
        }
    }

    on(type: string | string[], cb: Callback): this {
        if (type.constructor === String) {
            const t = type as string;
            if (!(t in this._cbs)) {
                this._cbs[t] = [];
            }
            if (this._cbs[t].indexOf(cb) === -1) {
                this._cbs[t].push(cb);
            }
        } else {
            (type as string[]).forEach(e => this.on(e, cb));
        }
        return this;
    }

    any(cb: Callback): this {
        this._cb.push(cb);
        return this;
    }

    once(type: string | string[], cb: Callback): this {
        if (type.constructor === String) {
            const t = type as string;
            const wrap = (message: NotificationMessage) => {
                this.off(t, wrap);
                cb(message);
            };
            this.on(type, wrap);
        } else {
            (type as string[]).forEach(e => this.once(e, cb));
        }
        return this;
    }

    off(type?: string, cb?: Callback): this {
        if (type === undefined) {
            if (cb) {
                this._cb = this._cb.filter(c => c !== cb);
            } else {
                this._cb.length = 0;
            }
        }
        if (type && type in this._cbs) {
            if (cb) {
                this._cbs[type].splice(this._cbs[type].indexOf(cb), 1);
            } else {
                this._cbs[type].length = 0;
                delete this._cbs[type];
            }
        }
        return this;
    }

    many(...cbs: { [type: string]: Callback }[]): this {
        cbs.forEach(cb => Object.keys(cb).forEach(t => this.on(t, cb[t])));
        return this;
    }

    private _emit(message: NotificationMessage): this {
        if (message.type in this._cbs) {
            for (let i = 0, l = this._cbs[message.type].length; i < l; i++) {
                this._cbs[message.type][i](message);
            }
        }
        for (let i = 0, l = this._cb.length; i < l; i++) {
            this._cb[i](message);
        }
        return this;
    }

    private _disconnect() {
        if (this._eio) {
            this._eio.close();
            delete this._eio;
        }
    }
}
