import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ColdchainTemperatureSensorModel } from '../../coldchain-logic';
import { Button } from 'common/components';
import cn from 'classnames';

interface Props extends WithTranslation {
    sensors?: ColdchainTemperatureSensorModel[];
    selectedSerialNumbers?: string[];
    dangerPoints?: boolean;
    onSensorToggle?: (serialNumber: string) => void;
    onDangerPointsToggle?: () => void;
}

function ColdchainChartLegend(props: Props) {
    const { t } = props;

    return (
        <Row justify="center" gutter={6}>
            <Col>
                <div className="chart-legend-title">{t('ManagementFleet.unitsAndSensors')}</div>
            </Col>
            {props.sensors?.map((sensor, index) => (
                <Col key={index}>
                    <Button
                        size="small"
                        className={cn('chart-legend-button', `chart-legend-button-${index + 1}`, {
                            selected: sensor.serialNumber && props.selectedSerialNumbers?.includes(sensor.serialNumber)
                        })}
                        onClick={() => sensor.serialNumber && props.onSensorToggle?.(sensor.serialNumber)}
                    >
                        {sensor.sensorName}
                    </Button>
                </Col>
            ))}
            <Col>
                <Button
                    size="small"
                    className={cn('chart-legend-button chart-legend-button-incident', {
                        selected: props.dangerPoints
                    })}
                    onClick={props.onDangerPointsToggle}
                >
                    {t('common.incident')}
                </Button>
            </Col>
        </Row>
    );
}

export default withTranslation()(ColdchainChartLegend);
