import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'resources/images/common';

interface Props {
    url: string;
    icon?: string;
    image?: string;
    label?: string;
    qa?: string;
}

const DownloadFile = (props: Props) => {
    const { t } = useTranslation();

    return (
        <a className="download-file-link" target="_blank" href={props.url} rel="noopener noreferrer">
            {!props.image && (
                <Row className="download-file" key={props.url} data-qa={props.qa} align="middle">
                    <Row className="download-file-icon" align="middle">
                        <Row>
                            <img src={props.icon} alt="download-file-icon" />
                        </Row>
                        <Row>{props.label && <span>{props.label}</span>}</Row>
                    </Row>
                    <Row className="download-file-download-icon" align="middle">
                        <Row>
                            <Col>
                                <img src={downloadFile} alt="download-file-download-icon" />
                            </Col>
                            <Col>{t('common.downloadFile')}</Col>
                        </Row>
                    </Row>
                </Row>
            )}
            {props.image && (
                <Row className="download-file" data-qa={props.qa} align="middle">
                    <img className="download-file-download-image" src={props.image} alt={props.image} />
                </Row>
            )}
        </a>
    );
};

export default DownloadFile;
