import { WithTranslation, withTranslation } from 'react-i18next';
import ColdchainChartMap from '../coldchain-chart-map';
import { WithLogic, withLogicContext } from 'App';
import {
    ColdchainDetailModel,
    ColdchainTemperatureSensorModel,
    TemperatureSensorDataGraphs
} from '../../coldchain-logic';
import { ColdchainAlertFilterParams, ColdchainChartFilterParams } from '../../ColdchainModule';
import { Button, Typography } from 'common/components';
import { AlarmInDatabaseWithGPSInfo, ColdChainProfileActiveEventRule } from 'generated/backend-api';
import { SearchData } from 'common/components/Search';
import { Col, Row } from 'antd';
import { DateRange } from 'common/model/date-time';
import qa from 'qa-selectors';
import * as icons from 'resources/images/common';
import cn from 'classnames';

interface Props extends WithLogic, WithTranslation {
    className?: string;
    temperatureSensors?: ColdchainTemperatureSensorModel[];
    temperatureSensorsData?: TemperatureSensorDataGraphs[];
    temperatureSensorsAlarmsData?: AlarmInDatabaseWithGPSInfo[];
    temperatureProfiles?: ColdChainProfileActiveEventRule[];
    temperatureSensorsDataLoading: boolean;
    chartFilter: ColdchainChartFilterParams;
    alertFilter: ColdchainAlertFilterParams;
    temperatureLogLoading: boolean;
    zones: number[];
    map: {
        open: boolean;
    };
    search?: SearchData;
    detailData?: ColdchainDetailModel;
    onChartFilterChange: (coldchainGraphFilter: ColdchainChartFilterParams) => void;
    onChartMapSwitchChange?: (checked: boolean) => void;
    onChartLegendDangerPointsToggle?: () => void;
    onAlertTableRowClick?: () => void;
    onSearchChange?: (value: string) => void;
    onAlertFilterChange?: (alertFilter: ColdchainAlertFilterParams) => void;
    onExportClick?: (monitoredObjectId?: number, dateRange?: DateRange) => void;
}

function ColdchainDetail(props: Props) {
    return (
        <div className={cn('coldchain-detail', props.className)}>
            <Row>
                <Col span={22}>
                    <Typography.Title level={3}>{props.t('Coldchain.timelineReport')}</Typography.Title>
                </Col>
                <Col span={2}>
                    <Row justify="end">
                        <Col>
                            <Button
                                type="primary"
                                size="large"
                                disabled={props.temperatureSensorsDataLoading}
                                loading={props.temperatureLogLoading}
                                onClick={() =>
                                    props.onExportClick?.(
                                        props.detailData?.monitoredObjectId,
                                        props.detailData?.dateRange
                                    )
                                }
                                qa={qa.coldChain.btnExport}
                                icon={<img src={icons.export} alt={props.t('common.export')} />}
                            >
                                {props.t('common.export')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ColdchainChartMap
                temperatureSensors={props.temperatureSensors}
                temperatureSensorsData={props.temperatureSensorsData}
                temperatureSensorsDataLoading={props.temperatureSensorsDataLoading}
                temperatureSensorsAlarmsData={props.temperatureSensorsAlarmsData}
                temperatureProfiles={props.temperatureProfiles}
                chartFilter={props.chartFilter}
                alertFilter={props.alertFilter}
                zones={props.zones}
                detailData={props.detailData}
                search={props.search}
                map={props.map}
                demoMode={props.logic.demo().isActive}
                theme={props.logic.settings().getProp('theme')}
                onChartFilterChange={props.onChartFilterChange}
                onChartLegendDangerPointsToggle={props.onChartLegendDangerPointsToggle}
                onChartMapSwitchChange={props.onChartMapSwitchChange}
                onAlertTableRowClick={props.onAlertTableRowClick}
                onSearchChange={props.onSearchChange}
                onAlertFilterChange={props.onAlertFilterChange}
            />
        </div>
    );
}

export default withTranslation()(withLogicContext(ColdchainDetail));
