import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const mapLocalCodesToLibCodes = {
    bg: 'bg',
    cs: 'cz',
    da: 'dk',
    de: 'de',
    en: 'gb',
    es: 'es',
    et: 'ee',
    fi: 'fi',
    fr: 'fr',
    ga: 'ie',
    el: 'gr',
    hr: 'hr',
    hu: 'hu',
    it: 'it',
    lt: 'lt',
    lv: 'lv',
    mt: 'mt',
    nl: 'nl',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sk: 'sk',
    sl: 'si',
    sv: 'se',
    tr: 'tr',
    uk: 'gb'
};

const preferredCountries = ['cz', 'sk'];

interface Props extends InputProps {
    lang: string;
    antd?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
}

export default class EnhancedInputPhone extends React.Component<Props> {
    private inputRef = React.createRef<HTMLInputElement>();
    private intl?: intlTelInput.Plugin = undefined;

    componentDidMount() {
        if (this.inputRef.current) {
            const input = this.inputRef.current;
            // @ts-ignore
            import('intl-tel-input/build/js/utils')
                .then(utilsScript => {
                    this.intl = intlTelInput(input, {
                        utilsScript,
                        dropdownContainer: document.body,
                        preferredCountries,
                        onlyCountries: Object.values(mapLocalCodesToLibCodes),
                        initialCountry: 'auto',
                        geoIpLookup: cb => {
                            cb(mapLocalCodesToLibCodes[this.props.lang] ?? '');
                        }
                    });
                    input.addEventListener('countrychange', () => {
                        this._onCountryChange();
                    });
                })
                .catch(err => {
                    console.error('Can not load utils script from intl-tel-input', err);
                });
        }
    }

    render() {
        const { className, value, ...props } = this.props;
        return (
            <div>
                {this.props.antd ? (
                    <Input {...props} className={className} onChange={this._onChange} />
                ) : (
                    <input value={value} className={className} onChange={this._onChange} ref={this.inputRef} />
                )}
            </div>
        );
    }

    private _onCountryChange = () => {
        if (this.intl && this.inputRef.current) {
            this.inputRef.current.value = this.intl.getNumber();
        }
    };

    private _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.intl) {
            const value = this.intl.getNumber();
            this.props.onChange?.(event, value);
        } else {
            this.props.onChange?.(event);
        }
    };
}
