/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum FuelStatsGroupByEntity {
    Vehicle = 'vehicle',
    Driver = 'driver'
}

export function FuelStatsGroupByEntityFromJSON(json: any): FuelStatsGroupByEntity {
    return FuelStatsGroupByEntityFromJSONTyped(json, false);
}

export function FuelStatsGroupByEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelStatsGroupByEntity {
    return json as FuelStatsGroupByEntity;
}

export function FuelStatsGroupByEntityToJSON(value?: FuelStatsGroupByEntity | null): any {
    return value as any;
}

