// FIXME: Remove whole component folder after meeting with transeu and update imports to new component
import cn from 'classnames';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-cool-dimensions';

import { H, KM } from 'domain-constants';
import { Button, Popover, Rate } from 'common/components';
import { PlacesModel, TransportModel } from 'common/model/transports';
import { useInterval } from 'common/utils/hooks';

import * as icons from 'resources/images/dispatcher-board';
import { DISAPPEARING_ANIMATION_LENGTH, TransportCalculatedProperties } from '../../dispatcher-board-logic';
import AddressDetail from '../AddressDetail/AddressDetail';
import { selectAddressStructuredByLanguage } from 'common/utils/address';
import { EWClient } from 'generated/main-data-api/models';
import numeral from 'numeral';

interface Props {
    transport: TransportModel;
    properties: TransportCalculatedProperties;
    demoMode?: boolean;
    lang: string;
    client?: EWClient;
    onConvertToTransport: (id: string) => void;
    onViewOnTransEu: (id: string) => void;
    onReject: (id: string) => void;
}

export default function DFFTransportBlock(props: Props) {
    const { observe, width } = useDimensions();
    const { t } = useTranslation();
    const [showDisappearAnimation, setShowDisappearAnimation] = useState(false);

    useInterval(() => {
        if (
            props.transport.metadata?.expiration &&
            moment().isAfter(
                moment(props.transport.metadata.expiration).subtract(DISAPPEARING_ANIMATION_LENGTH, 'seconds')
            )
        ) {
            setShowDisappearAnimation(true);
        } else if (
            moment().isAfter(
                moment(props.transport.firstPlaceRta)
                    .subtract(props.transport.metadata?.pickupDuration, 'seconds')
                    .subtract(DISAPPEARING_ANIMATION_LENGTH, 'seconds')
            )
        ) {
            setShowDisappearAnimation(true);
        }
    }, 1e3);

    function isNew() {
        if (props.transport.metadata?.timestamp) {
            return moment(moment().subtract(20, 'seconds')).isSameOrBefore(moment(props.transport.metadata?.timestamp));
        }
        return false;
    }

    function getCountry(place?: PlacesModel) {
        const [placeAddress] = place?.addressStructured ?? [];
        const countryCode = placeAddress?.countryCode?.toLowerCase();

        return countryCode;
    }

    function renderCountry(countryCode: string) {
        return (
            <div className="dff-transport-block-backup-country">
                <span className={cn('flag-icon flag-icon-squared', `flag-icon-${countryCode}`)} />
            </div>
        );
    }

    function renderTitle() {
        const lastPlace = props.transport.places[props.transport.places.length - 1];
        const [lastPlaceAddress] = lastPlace?.addressStructured ?? [];

        const distance = props.transport.metadata?.transportDistance
            ? `${Math.round(props.transport.metadata.transportDistance / 1000)} ${KM}, `
            : '';

        const address = lastPlaceAddress
            ? `${lastPlaceAddress?.country}, ${lastPlaceAddress?.postalCode}, ${lastPlaceAddress?.address}`
            : '';

        return width > 10 ? `${distance} ${address}` : '';
    }

    function renderDataBlock(label: ReactNode, value: ReactNode, highlight = false) {
        return (
            <div
                className={cn('dff-transport-datablock-backup', {
                    'dff-transport-datablock-backup-highlight': highlight
                })}
            >
                <div className="dff-transport-datablock-backup-value">{value}</div>
                <div className="dff-transport-datablock-backup-label">{label}</div>
            </div>
        );
    }

    function handleReject() {
        props.transport.id && props.onReject(props.transport.id);
    }

    function handleCovertToTransport() {
        props.transport.id && props.onConvertToTransport(props.transport.id);
    }

    function handleViewOnTransEu() {
        props.transport.id && props.onViewOnTransEu(props.transport.id);
    }

    function renderPopoverDetail() {
        const firstAddressStructured = selectAddressStructuredByLanguage(
            props.transport.places?.[0].addressStructured,
            props.lang,
            props.client
        );
        const lastAddressStructured = selectAddressStructuredByLanguage(
            props.transport.places?.[props.transport.places.length - 1].addressStructured,
            props.lang,
            props.client
        );

        return (
            <div className="dff-transport-popover-backup">
                <div className="dff-transport-popover-backup-content">
                    <AddressDetail
                        address={firstAddressStructured}
                        iconUrl={icons.load}
                        rta={props.transport.firstPlaceRta}
                    />
                    <AddressDetail
                        address={lastAddressStructured}
                        iconUrl={icons.unload}
                        rta={props.transport.lastPlaceRta}
                    />
                    <Row className="dff-transport-popover-backup-heading dff-transport-popover-backup-first-row">
                        {t('DFFTransportBlock.distance')}
                    </Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.distToPickUp'),
                                props.transport.metadata?.distanceToTransport
                                    ? `${Math.round(props.transport.metadata?.distanceToTransport / 1000)} ${KM}`
                                    : '-'
                            )}
                        </Col>
                        <Col span={8}>
                            {renderDataBlock(
                                t('common.duration'),
                                `${props.transport.metadata?.totalDuration ?? '-'} ${H}`
                            )}
                        </Col>
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.totalDistance'),
                                props.transport.metadata?.transportDistance
                                    ? `${Math.round(props.transport.metadata?.transportDistance / 1000)} ${KM}`
                                    : '-'
                            )}
                        </Col>
                    </Row>
                    <Row className="dff-transport-popover-backup-heading">{t('DFFTransportBlock.general')}</Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            {renderDataBlock(
                                t('common.weight'),
                                `${props.transport.metadata?.loadWeightValue} ${props.transport.metadata?.loadWeightUnit}`
                            )}
                        </Col>
                        <Col span={16}>
                            {renderDataBlock(t('common.trailer'), props.transport.metadata?.trailer ?? '-')}
                        </Col>
                    </Row>
                    <Row className="dff-transport-popover-backup-heading">{t('DFFTransportBlock.costs')}</Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.pickupCosts'),
                                props.transport.metadata?.pickupCosts
                                    ? `${numeral(props.transport.metadata?.pickupCosts).format('0,0')} €`
                                    : '-'
                            )}
                        </Col>
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.transportCosts'),
                                props.transport.metadata?.transportCosts
                                    ? `${numeral(props.transport.metadata?.transportCosts).format('0,0')} €`
                                    : '-'
                            )}
                        </Col>
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.costKm'),
                                props.transport.costPerKm?.cost
                                    ? `${numeral(props.transport.costPerKm?.cost).format('0,0.00')} €`
                                    : '-'
                            )}
                        </Col>
                    </Row>
                    <Row className="dff-transport-popover-backup-heading">{t('DFFTransportBlock.price')}</Row>
                    <Row gutter={8}>
                        {/* TODO: Add data when will be available */}
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.profit'),
                                props.transport.metadata?.profit
                                    ? `${numeral(props.transport.metadata?.profit).format('0,0')} €`
                                    : '-'
                            )}
                        </Col>
                        {/* TODO: Add data when will be available */}
                        <Col span={8}>
                            {renderDataBlock(
                                t('DispatcherBoardDetailTransportDff.finalPrice'),
                                props.transport.metadata?.finalPrice
                                    ? `${numeral(props.transport.metadata?.finalPrice).format('0,0')} €`
                                    : '-',
                                true
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="dff-transport-popover-backup-actions">
                    <Row gutter={8} className="dff-transport-popover-backup-actions-row" justify="end">
                        <Col>
                            <Button block type="text" onClick={handleReject} disabled={props.demoMode}>
                                {t('DFFTransportBlock.reject')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                block
                                type="primary"
                                onClick={handleCovertToTransport}
                                disabled={props.demoMode}
                            >
                                {t('DFFTransportBlock.convertToTransport')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                block
                                type="primary"
                                onClick={handleViewOnTransEu}
                                disabled={props.demoMode}
                            >
                                {t('DFFTransportBlock.viewOnTransEU')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        <Popover
            placement="left"
            overlayClassName="dff-transport-popover-backup-overlay"
            content={renderPopoverDetail()}
        >
            <div
                ref={observe}
                className={cn('dff-transport-block-backup', {
                    'dff-transport-block-backup-highlight': props.properties.highlight,
                    'dff-transport-block-backup-disappear': showDisappearAnimation
                })}
            >
                {isNew() && width > 40 && (
                    <div className="dff-transport-block-backup-prefix">
                        <img src={icons.newProposal} alt="New proposal" />
                    </div>
                )}
                <div className="dff-transport-block-backup-data">
                    {width > 152 && <Rate size="xsmall" value={props.transport.metadata?.score.stars} disabled />}
                    <Typography.Text ellipsis>{renderTitle()}</Typography.Text>
                </div>
                {!isNew() &&
                    width > 40 &&
                    renderCountry(getCountry(props.transport.places[props.transport.places.length - 1]))}
                {isNew() &&
                    width > 88 &&
                    renderCountry(getCountry(props.transport.places[props.transport.places.length - 1]))}
            </div>
        </Popover>
    );
}
