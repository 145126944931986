import React, { ReactNode } from 'react';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';

interface Props extends DropDownProps {
    children: ReactNode;
}

EnhancedDropdown.Button = Dropdown.Button;

export default function EnhancedDropdown(props: Props) {
    return <Dropdown {...props} />;
}
