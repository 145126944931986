/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coordinate,
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './';

/**
 * 
 * @export
 * @interface PositionList
 */
export interface PositionList {
    /**
     * 
     * @type {number}
     * @memberof PositionList
     */
    deviceId: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof PositionList
     */
    coordinate: Coordinate;
    /**
     * 
     * @type {string}
     * @memberof PositionList
     */
    ignitionState: string;
    /**
     * 
     * @type {Date}
     * @memberof PositionList
     */
    dateTime: Date;
    /**
     * 
     * @type {number}
     * @memberof PositionList
     */
    heading?: number;
    /**
     * 
     * @type {number}
     * @memberof PositionList
     */
    speed?: number;
}

export function PositionListFromJSON(json: any): PositionList {
    return PositionListFromJSONTyped(json, false);
}

export function PositionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'coordinate': CoordinateFromJSON(json['coordinate']),
        'ignitionState': json['ignitionState'],
        'dateTime': json['dateTime'],
        'heading': !exists(json, 'heading') ? undefined : json['heading'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
    };
}

export function PositionListToJSON(value?: PositionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'coordinate': CoordinateToJSON(value.coordinate),
        'ignitionState': value.ignitionState,
        'dateTime': value.dateTime,
        'heading': value.heading,
        'speed': value.speed,
    };
}


