/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverBehaviourContextualPeriod,
    DriverBehaviourContextualPeriodFromJSON,
    DriverBehaviourContextualPeriodFromJSONTyped,
    DriverBehaviourContextualPeriodToJSON,
    DriverBehaviourPeriod,
    DriverBehaviourPeriodFromJSON,
    DriverBehaviourPeriodFromJSONTyped,
    DriverBehaviourPeriodToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverBehaviourTrendsResponse
 */
export interface DriverBehaviourTrendsResponse {
    /**
     * 
     * @type {Array<DriverBehaviourPeriod>}
     * @memberof DriverBehaviourTrendsResponse
     */
    data?: Array<DriverBehaviourPeriod>;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourTrendsResponse
     */
    numberOfDrivers?: number;
    /**
     * 
     * @type {Array<DriverBehaviourContextualPeriod>}
     * @memberof DriverBehaviourTrendsResponse
     */
    contextualData?: Array<DriverBehaviourContextualPeriod>;
}

export function DriverBehaviourTrendsResponseFromJSON(json: any): DriverBehaviourTrendsResponse {
    return DriverBehaviourTrendsResponseFromJSONTyped(json, false);
}

export function DriverBehaviourTrendsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourTrendsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(DriverBehaviourPeriodFromJSON)),
        'numberOfDrivers': !exists(json, 'number_of_drivers') ? undefined : json['number_of_drivers'],
        'contextualData': !exists(json, 'contextual_data') ? undefined : ((json['contextual_data'] as Array<any>).map(DriverBehaviourContextualPeriodFromJSON)),
    };
}

export function DriverBehaviourTrendsResponseToJSON(value?: DriverBehaviourTrendsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(DriverBehaviourPeriodToJSON)),
        'number_of_drivers': value.numberOfDrivers,
        'contextual_data': value.contextualData === undefined ? undefined : ((value.contextualData as Array<any>).map(DriverBehaviourContextualPeriodToJSON)),
    };
}


