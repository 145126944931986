import { Button, Confirm } from 'common/components';
import * as React from 'react';
import moment, { Moment } from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import PlannerPlacesTask from '../PlannerPlacesTask';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TransportEventRule, TransportPlaceTask, TransportPlaceTaskType } from 'generated/backend-api';
import qa from 'qa-selectors';
import { createRef } from 'react';
import { FormikProps } from 'formik';
import { MessageType } from 'common/components/Confirm';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import { ContactList } from 'common/model/client-contact';

interface State {
    alarmConfig: TransportEventRule[];
    task?: TransportPlaceTask;
    clientContact?: ContactList;
    removeTaskConfirmVisible: boolean;
}

interface Props extends WithTranslation, WithLogic {
    onClose: () => void;
    onRemoveTask?: () => void;
    onManualAtaDateChange?: (dateTime: string) => void;
    onPlacesTaskSave?: (model: TransportPlaceTask, clientContact?: ContactList, config?: TransportEventRule[]) => void;
}

class PlaceSettings extends React.Component<Props, State> {
    addressFormikRef: React.RefObject<FormikProps<ContactList>>;
    constructor(props: Props) {
        super(props);
        this.state = {
            alarmConfig: this.props.logic.plannerLogic().selectedPlace?.eventRules ?? [],
            task: this.props.logic.plannerLogic().selectedTask,
            removeTaskConfirmVisible: false
        };

        this.addressFormikRef = createRef<FormikProps<ContactList>>();
    }

    render() {
        const { t } = this.props;
        const taskId = this.props.logic.plannerLogic().selectedTask?.id;

        return (
            <div className="place-settings-bar">
                {taskId && this.state.removeTaskConfirmVisible && (
                    <Confirm
                        type={MessageType.WARNING}
                        message={this.props.t('PlannerPlacesTask.removeActivityConfirm')}
                        onCancel={() => {
                            this.setState({
                                removeTaskConfirmVisible: false
                            });
                        }}
                        onConfirm={this._onRemoveTaskConfirm}
                    />
                )}
                <PlannerPlacesTask
                    addressFormikRef={this.addressFormikRef}
                    onPlaceTaskChange={this.props.onPlacesTaskSave !== undefined ? this._onPlaceTaskChange : undefined}
                    onAlarmConfigChange={this._onAlarmConfigChange}
                    onPlaceTaskClientContactChange={this._onPlaceTaskClientContactChange}
                    onManualAtaDateChange={this.props.onManualAtaDateChange}
                />
                {this.props.onPlacesTaskSave !== undefined && (
                    <div className="place-settings-action-buttons">
                        {taskId && !this.props.logic.plannerLogic().selectedPlaceDisabled ? (
                            <Button disabled={this.props.logic.demo().isActive} onClick={this._onRemoveTask}>
                                {t('common.remove')}
                            </Button>
                        ) : (
                            <div />
                        )}

                        <div className="save-cancel">
                            <Button
                                onClick={this.props.onClose}
                                disabled={this.props.logic.plannerLogic().selectedPlaceDisabled}
                                qa={qa.planner.placeSettings.btnClose}
                            >
                                {t('common.cancel')}
                            </Button>
                            <Button
                                onClick={this._onSave}
                                type="primary"
                                disabled={
                                    this.props.logic.plannerLogic().selectedPlaceDisabled ||
                                    this.props.logic.demo().isActive
                                }
                                qa={qa.planner.placeSettings.btnConfirm}
                            >
                                {t('common.confirm')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private _onPlaceTaskClientContactChange = (clientContact: ContactList) => {
        this.setState({
            clientContact
        });
    };

    private _onAlarmConfigChange = (config: TransportEventRule[]) => {
        this.setState({
            alarmConfig: config
        });
    };

    private _onPlaceTaskChange = (task?: TransportPlaceTask) => {
        this.setState({
            task
        });
    };

    private _onRemoveTask = () => {
        this.setState({ removeTaskConfirmVisible: true });
    };

    private _onRemoveTaskConfirm = () => {
        this.props.onRemoveTask?.();
        this.setState({ removeTaskConfirmVisible: false });
    };

    private _onSave = async () => {
        const task = this.state.task;
        if (!task) throw new Error('No task to save');
        if (
            this.state.task?.type === TransportPlaceTaskType.Loading ||
            this.state.task?.type === TransportPlaceTaskType.Unloading
        ) {
            const values = this.addressFormikRef.current?.values;
            let hasToBeValidated: boolean = true;
            if (values) {
                hasToBeValidated = !Object.values(values).every(formValue => {
                    return formValue === '' || formValue === null || formValue === undefined;
                });
            }

            const valid = hasToBeValidated ? await this.addressFormikRef.current?.submitForm() : true;

            if (valid) {
                const clientContact = this.state.clientContact;
                if (!clientContact) console.warn('Sender of gods is empty, no client contact to save');
                this.props.onClose?.();
                this.props.onPlacesTaskSave?.(task, clientContact, this.state.alarmConfig);
            }
        } else {
            this.props.onClose?.();
            this.props.onPlacesTaskSave?.(task, undefined, this.state.alarmConfig);
        }
    };

    private _manualAtaChange = (e: CheckboxChangeEvent) => {
        if (!e.target.checked) {
            this.props.onManualAtaDateChange?.('');
        } else {
            this.props.onManualAtaDateChange?.(moment().toISOString());
        }
    };
    private _manualAtaDateChange = (value: Moment | null) => {
        if (value) {
            this.props.onManualAtaDateChange?.(value.toISOString());
        }
    };
}

export default withTranslation()(withLogicContext(observer(PlaceSettings)));
