/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetType,
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
    CompanyOperationalCost,
    CompanyOperationalCostFromJSON,
    CompanyOperationalCostFromJSONTyped,
    CompanyOperationalCostToJSON,
    MonitoredObjectOperationalCost,
    MonitoredObjectOperationalCostFromJSON,
    MonitoredObjectOperationalCostFromJSONTyped,
    MonitoredObjectOperationalCostToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    TagAssignment,
    TagAssignmentFromJSON,
    TagAssignmentFromJSONTyped,
    TagAssignmentToJSON,
    TagCategory,
    TagCategoryFromJSON,
    TagCategoryFromJSONTyped,
    TagCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModificationOperationStats
 */
export interface ModificationOperationStats {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ModificationOperationStats
     */
    tag?: Array<Tag> | null;
    /**
     * 
     * @type {Array<TagAssignment>}
     * @memberof ModificationOperationStats
     */
    tagAssigment?: Array<TagAssignment> | null;
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof ModificationOperationStats
     */
    tagCategory?: Array<TagCategory> | null;
    /**
     * 
     * @type {Array<AssetType>}
     * @memberof ModificationOperationStats
     */
    assetType?: Array<AssetType> | null;
    /**
     * 
     * @type {Array<CompanyOperationalCost>}
     * @memberof ModificationOperationStats
     */
    companyOperationalCost?: Array<CompanyOperationalCost> | null;
    /**
     * 
     * @type {Array<MonitoredObjectOperationalCost>}
     * @memberof ModificationOperationStats
     */
    monitoredObjectOperationalCost?: Array<MonitoredObjectOperationalCost> | null;
}

export function ModificationOperationStatsFromJSON(json: any): ModificationOperationStats {
    return ModificationOperationStatsFromJSONTyped(json, false);
}

export function ModificationOperationStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationOperationStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': !exists(json, 'tag') ? undefined : (json['tag'] === null ? null : (json['tag'] as Array<any>).map(TagFromJSON)),
        'tagAssigment': !exists(json, 'tagAssigment') ? undefined : (json['tagAssigment'] === null ? null : (json['tagAssigment'] as Array<any>).map(TagAssignmentFromJSON)),
        'tagCategory': !exists(json, 'tagCategory') ? undefined : (json['tagCategory'] === null ? null : (json['tagCategory'] as Array<any>).map(TagCategoryFromJSON)),
        'assetType': !exists(json, 'assetType') ? undefined : (json['assetType'] === null ? null : (json['assetType'] as Array<any>).map(AssetTypeFromJSON)),
        'companyOperationalCost': !exists(json, 'companyOperationalCost') ? undefined : (json['companyOperationalCost'] === null ? null : (json['companyOperationalCost'] as Array<any>).map(CompanyOperationalCostFromJSON)),
        'monitoredObjectOperationalCost': !exists(json, 'monitoredObjectOperationalCost') ? undefined : (json['monitoredObjectOperationalCost'] === null ? null : (json['monitoredObjectOperationalCost'] as Array<any>).map(MonitoredObjectOperationalCostFromJSON)),
    };
}

export function ModificationOperationStatsToJSON(value?: ModificationOperationStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': value.tag === undefined ? undefined : (value.tag === null ? null : (value.tag as Array<any>).map(TagToJSON)),
        'tagAssigment': value.tagAssigment === undefined ? undefined : (value.tagAssigment === null ? null : (value.tagAssigment as Array<any>).map(TagAssignmentToJSON)),
        'tagCategory': value.tagCategory === undefined ? undefined : (value.tagCategory === null ? null : (value.tagCategory as Array<any>).map(TagCategoryToJSON)),
        'assetType': value.assetType === undefined ? undefined : (value.assetType === null ? null : (value.assetType as Array<any>).map(AssetTypeToJSON)),
        'companyOperationalCost': value.companyOperationalCost === undefined ? undefined : (value.companyOperationalCost === null ? null : (value.companyOperationalCost as Array<any>).map(CompanyOperationalCostToJSON)),
        'monitoredObjectOperationalCost': value.monitoredObjectOperationalCost === undefined ? undefined : (value.monitoredObjectOperationalCost === null ? null : (value.monitoredObjectOperationalCost as Array<any>).map(MonitoredObjectOperationalCostToJSON)),
    };
}


