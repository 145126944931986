/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TransportPlaceTaskType {
    Loading = 'loading',
    Parking = 'parking',
    Refueling = 'refueling',
    Unloading = 'unloading',
    Washing = 'washing',
    None = 'None'
}

export function TransportPlaceTaskTypeFromJSON(json: any): TransportPlaceTaskType {
    return TransportPlaceTaskTypeFromJSONTyped(json, false);
}

export function TransportPlaceTaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportPlaceTaskType {
    return json as TransportPlaceTaskType;
}

export function TransportPlaceTaskTypeToJSON(value?: TransportPlaceTaskType | null): any {
    return value as any;
}

