/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Availability
 */
export interface Availability {
    /**
     * 
     * @type {Date}
     * @memberof Availability
     */
    availableFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof Availability
     */
    availableUntil: Date;
}

export function AvailabilityFromJSON(json: any): Availability {
    return AvailabilityFromJSONTyped(json, false);
}

export function AvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Availability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableFrom': (new Date(json['availableFrom'])),
        'availableUntil': (new Date(json['availableUntil'])),
    };
}

export function AvailabilityToJSON(value?: Availability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableFrom': (value.availableFrom.toISOString()),
        'availableUntil': (value.availableUntil.toISOString()),
    };
}


