import qs from 'qs';
import { Component, ReactNode, RefObject } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { SortEnd, SortEvent } from 'react-sortable-hoc';
import { Col, message, Row } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Subscription } from 'rxjs';
import { RouteNames } from 'App';
import { Button, Checkbox, Confirm, Loading, Modal } from 'common/components';
import VehicleProfileForm from 'common/forms/VehicleProfileForm';
import { AvailableCurrencies } from 'common/model/currency';
import { Logic } from 'logic/logic';
import RouteConfigurator, { RouteConfiguration } from './ui/RouteConfigurator';
import { exponea } from 'logic/exponea';
import { TransportAlarmType, VehicleProfileModel } from 'common/model/transports';
import { Transport, TransportPlace } from 'generated/backend-api';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import { observer } from 'mobx-react';
import { ExternalSystemSecretMetadata, WriteOnlyVehicleProfileSerializerEuroClassEnum } from 'generated/new-main';
import { RouteSelectData } from '../places-autocomplete/PlacesAutocompleteModule';
import ClientForm from 'common/forms/ClientForm';
import PlannerBar from './ui/PlannerBar';
import PlacesConfigurator from './ui/PlacesConfigurator';
import PlannerAutocomplete from './ui/PlannerAutocomplete';
import qa from 'qa-selectors';
import { ContactList } from 'common/model/client-contact';
import RouteParamsConfigurator from './ui/RouteParamsConfigurator';
import { MessageType } from 'common/components/Confirm';
import PlaceRouteOptionsCard, { PlaceRouteOptionsResult } from './ui/PlaceRouteOptionsCard';
import RouteOptionsCard, { RouteOptions } from './ui/RouteOptionsCard';
import ActivityCard, { ActivityResult } from './ui/ActivityCard';
import { cloneDeep } from '@apollo/client/utilities';
import { RefSelectProps } from 'antd/lib/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ModalForm from 'common/components/ModalForm';
import cn from 'classnames';
import { PlanRouteErrorName, TransportType, VehicleProfile } from './planner-logic';
import { defaultVehicleProfileName } from '../planner-new/ui/RouteParamsConfigurator';
import * as modalIcons from 'resources/images/modal';
import { VehicleStateObject } from 'generated/graphql';
import { InaccessiblePointError, InaccessiblePointTypeEnum } from 'generated/routing-api';

export interface PuescModalModel {
    open: boolean;
    visited: boolean;
    dataLoading: boolean;
    data?: PuescConnection;
}

export interface PuescConnection {
    connected?: boolean;
    vehicles?: number[];
    metadata?: ExternalSystemSecretMetadata;
}

type RouteParams = {
    editId?: string;
    vehicleId?: string;
    startDate?: string;
    endDate?: string;
    dispatcherBoard?: string;
};

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

interface State {
    createClientProfileOpen: boolean;
    addVehicleProfileOpen: boolean;
    deleteVehicleConfirmVisible: boolean;
    PuescGoodsVerificationOpen: boolean;
    loading: boolean;
    backUrl?: string;
    manualAta?: {
        dateTime: string;
        placeId: string;
    };
    helper?: {
        content: string;
    };
    retriedWaypoints: string[];
    puescModal: PuescModalModel;

    pricePerKm?: number;
    currency?: AvailableCurrencies;
    costPerKm?: { cost: number; currency: AvailableCurrencies };
    route: {
        editOptionsOpen: boolean;
        editActivityOpen: boolean;
        selected: TransportPlace | undefined;
    };
    routeDetail: {
        openDetailOptions: boolean;
        transport: Transport | undefined;
    };
    templateSaveConfirmOpen: boolean;
    routingAlert: boolean;
    vehicleStates?: VehicleStateObject[];
}

export enum Calculation {
    VEHICLE_PROFILE = 'vehicleProfile',
    VEHICLE = 'vehicle',
    WITHOUT_RESTRICTIONS = 'withoutRestrictions'
}

class RoutePlanningScheduling extends Component<Props, State> {
    private _logic: Logic;
    private _externalSystemDataSubscription?: Subscription;
    private _planRouteErrorSubscription?: Subscription;
    private _vehicleProfileType: 'default' | 'edit';
    private _currentVehicleProfile?: VehicleProfile;
    private _lastPlaceRouteOptionsResult?: PlaceRouteOptionsResult;
    private _lastRouteOptionsResult?: RouteOptions;
    private _lastActivityTransportPlace?: TransportPlace;
    private _lastActivityResult?: ActivityResult;
    private _selectedTransportPlaceIndex?: number;
    private _oldTransportPlaceId?: string;
    private _oldTransportPlaceRouteId?: string;
    private _selectedTransportPlaceRouteId?: string;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this._vehicleProfileType = 'default';

        this.state = {
            loading: false,
            addVehicleProfileOpen: false,
            createClientProfileOpen: false,
            deleteVehicleConfirmVisible: false,
            retriedWaypoints: [],
            puescModal: {
                open: false,
                visited: false,
                dataLoading: false
            },
            PuescGoodsVerificationOpen: false,
            route: {
                editOptionsOpen: false,
                editActivityOpen: false,
                selected: undefined
            },
            routeDetail: {
                openDetailOptions: false,
                transport: undefined
            },
            templateSaveConfirmOpen: false,
            routingAlert: false
        };
    }

    componentDidMount() {
        this._oldTransportPlaceId = undefined;
        this._oldTransportPlaceRouteId = undefined;
        this._selectedTransportPlaceRouteId = undefined;
        const { t } = this.props;
        (window as any).app.PlannerModule = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this._logic
            .vehiclesState()
            .getData(this._logic.notification().device!)
            .then(res => {
                this.setState({ vehicleStates: res });
            });

        this._logic
            .plannerLogic()
            .init(true, 'edit', params.editId, undefined, params.vehicleId ? Number(params.vehicleId) : undefined, 2);

        this._planRouteErrorSubscription = this._logic.plannerLogic().onPlanRouteError.subscribe(async err => {
            if (err.name === PlanRouteErrorName.InaccessiblePointError) {
                this._logic.plannerLogic().clearTransport(true);

                // Show modal only if error is for current latest transport and modal is not already shown
                if (!this.state.routingAlert) {
                    Modal.warning({
                        centered: true,
                        title: t('routing.error.noRouteTitle'),
                        className: 'planner-error-modal',
                        icon: (
                            <div className="planner-error-modal-icon">
                                <img src={modalIcons.warning} alt="warning" />
                            </div>
                        ),
                        content: this._getErrorText(err),
                        okText: t('common.ok'),
                        onOk: () => {
                            this.setState({ routingAlert: false });
                        }
                    });

                    this.setState({ routingAlert: true });
                }
            } else {
                this._logic.plannerLogic().clearTransport(true);
                if (!this.state.routingAlert) {
                    Modal.warning({
                        centered: true,
                        className: 'planner-error-modal',
                        icon: (
                            <div className="planner-error-modal-icon">
                                <img src={modalIcons.error} alt="error" />
                            </div>
                        ),
                        title: t('common.error.somethingWentWrong'),
                        content: (
                            <>
                                <div>{t('common.error.somethingWentWrongDescription')}</div>
                                <div>{t('common.error.temporaryIssueDescription')}</div>
                            </>
                        ),
                        okText: t('common.ok'),
                        onOk: () => {
                            this.setState({ routingAlert: false });
                        }
                    });
                }
                this.setState({ routingAlert: true });
            }
        });

        this._logic.map().filterSubject.subscribe(([fuels, services]) => {
            this._logic.plannerLogic().setFuelDataFilter(fuels);
            this._logic.plannerLogic().setServiceDataFilter(services);
        });

        this._logic
            .vehicles()
            .getMonitoredObjectFilters()
            .then(vehicles => {
                const checkedVehicles = (vehicles ?? []).map(v => Number(v.id));
                this._logic
                    .statisticsCompanyProfile()
                    .pricePerKmCostStructure(checkedVehicles)
                    .then(resp => {
                        this.setState(() => ({
                            costPerKm: {
                                currency: resp?.currency as AvailableCurrencies,
                                cost: resp?.avgPricePerKm
                            }
                        }));
                    });
            });

        if (params.dispatcherBoard) {
            this.setState({
                backUrl: params.editId
                    ? `${RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL}?editId=${params.editId}`
                    : RouteNames.SCHEDULING_DISPATCHER_BOARD
            });
        } else if (params.editId) {
            this.setState({
                backUrl: `${RouteNames.SCHEDULING_ORDERS}?selected=${params.editId}&startDate=${params.startDate}&endDate=${params.endDate}`
            });
        } else if (params.vehicleId) {
            this.setState({
                backUrl: `${RouteNames.TRACKING}?vehicleId=${params.vehicleId}`
            });
        }

        this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
            status: exponea.status.screenShown,
            src: params.dispatcherBoard
                ? exponea.module.schedulingDispatcherBoard
                : params.editId
                ? exponea.module.schedulingRouteOverview
                : params.vehicleId
                ? exponea.module.trackingTableView
                : undefined
        });
    }

    componentWillUnmount() {
        (window as any).app.PlannerModule = undefined;
        this._logic.plannerLogic().reset(false, true);
        this._externalSystemDataSubscription?.unsubscribe();
        this._planRouteErrorSubscription?.unsubscribe();
    }

    render() {
        const transport = this._logic.plannerLogic().transport;
        const transportRouteDetail = this.state.routeDetail.transport;
        const readonly =
            transport.places?.[(this._selectedTransportPlaceIndex ?? 0) + 1]?.ata !== undefined &&
            transport.id !== undefined;

        return (
            <>
                <Modal
                    visible={this.state.createClientProfileOpen}
                    title={this.props.t('ClientContactListForm.modalNewContact')}
                    closable={true}
                    width={900}
                    footer={null}
                    destroyOnClose
                    onCancel={this._onCreateClientClose}
                >
                    <ClientForm
                        countries={this._logic.plannerLogic().countryList}
                        onSubmit={this._onCreateClientSubmit}
                        onCancel={this._onCreateClientClose}
                        demoMode={this.props.logic.demo().isActive}
                        isClient
                    />
                </Modal>
                {this.state.route.editActivityOpen && (
                    <ActivityCard
                        logic={this._logic}
                        transport={this._logic.plannerLogic().transport}
                        transportPlace={this._lastActivityTransportPlace!}
                        transportPlaceIndex={this._selectedTransportPlaceIndex!}
                        onSubmit={this._onSubmitActivity}
                        onEscape={this._onSubmitActivity}
                    />
                )}
                <ModalForm
                    visible={this.state.addVehicleProfileOpen}
                    title={
                        this._vehicleProfileType === 'default'
                            ? this.props.t('VehicleProfile.createVehicleProfile')
                            : this.props.t('VehicleProfile.editVehicleProfile')
                    }
                    closable={true}
                    className={'vehicle-profile-modal'}
                    footer={null}
                    destroyOnClose
                    onCancel={this._onCreateProfileClose}
                    size="super-wide"
                >
                    <VehicleProfileForm
                        type={this._vehicleProfileType}
                        demoMode={this.props.logic.demo().isActive}
                        initialValues={
                            this._currentVehicleProfile && {
                                id: String(this._currentVehicleProfile.id),
                                isDefault: this._currentVehicleProfile.isDefault,
                                name: String(this._currentVehicleProfile.name),
                                length: Number(this._currentVehicleProfile.length),
                                height: Number(this._currentVehicleProfile.height),
                                width: Number(this._currentVehicleProfile.width),
                                weight: Number(this._currentVehicleProfile.weight),
                                tunnel: String(this._currentVehicleProfile.tunnel),
                                euroClass: String(
                                    this._currentVehicleProfile.euroClass
                                ) as WriteOnlyVehicleProfileSerializerEuroClassEnum,
                                trailersCount: Number(this._currentVehicleProfile.trailersCount),
                                numberOfAxles: Number(this._currentVehicleProfile.numberOfAxles),
                                pricePerKm: Number(this._currentVehicleProfile.pricePerKm)
                            }
                        }
                        onSubmit={this._onVehicleProfileSubmit}
                        onCancel={this._onCreateProfileClose}
                    />
                </ModalForm>
                {this.state.route.editOptionsOpen && (
                    <PlaceRouteOptionsCard
                        logic={this._logic}
                        transportPlace={this._lastActivityTransportPlace}
                        showToll={this.props.logic.plannerLogic().tollCostEnable}
                        currency={
                            (this.props.logic.plannerLogic().transport.costPerKm?.currency ??
                                AvailableCurrencies.EUR) as AvailableCurrencies
                        }
                        readonly={readonly}
                        onSubmit={this._onPlaceRouteOptionsSubmit}
                        onEscape={this._onPlaceRouteOptionsSubmit}
                    />
                )}
                {this.state.routeDetail.openDetailOptions && this.props.logic.plannerLogic().tollCostEnable && (
                    <RouteOptionsCard
                        logic={this._logic}
                        transportPlaces={transportRouteDetail?.places}
                        routeOptionsSygic={cloneDeep(this.props.logic.plannerLogic().transport.routeOptionsSygic)}
                        avoidOptionsCountries={
                            this.props.logic.plannerLogic()?.transport.routeOptionsSygic?.avoidOptionsSygic
                        }
                        currency={
                            (transportRouteDetail?.costPerKm?.currency ??
                                AvailableCurrencies.EUR) as AvailableCurrencies
                        }
                        onSubmit={this._onRouteOptionsSubmit}
                        onEscape={this._onRouteOptionsSubmit}
                    />
                )}
                <div className="planner-wrapper module">
                    <div
                        className={cn('planner', {
                            'planner-empty': (this._logic.plannerLogic().transport?.places?.length ?? 0) === 0
                        })}
                    >
                        {this._logic.plannerLogic().initTransportLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <PlannerBar onHelperClick={this._onBarHelperClick} />
                                <RouteParamsConfigurator
                                    companyCostPerKm={{
                                        cost: this._logic.plannerLogic().transport.costPerKm?.value ?? 0,
                                        currency: this._logic.plannerLogic().transport.costPerKm
                                            ?.currency as AvailableCurrencies
                                    }}
                                    vehicleStates={this.state.vehicleStates}
                                    onRoutePriceChange={this._onRoutePriceChange}
                                    onCreateProfileClick={this._onCreateProfileClick}
                                    onEditProfileClick={this._onEditProfileClick}
                                    onDeleteProfileClick={this._onDeleteProfileClick}
                                />
                                <PlacesConfigurator
                                    routeLoading={
                                        this._logic.plannerLogic().createRouteLoading ||
                                        this._logic.plannerLogic().calculatingRouteLoading
                                    }
                                    placeSelectedIndex={this._selectedTransportPlaceIndex}
                                    routeSelectedId={this._selectedTransportPlaceRouteId}
                                    demoMode={this._logic.demo().isActive}
                                    isPuescTransportPossible={this._logic.plannerLogic().isPuescTransportPossible}
                                    puescActive={this._logic.plannerLogic().transport?.puesc}
                                    currency={
                                        (this.props.logic.plannerLogic().transport.costPerKm?.currency ??
                                            AvailableCurrencies.EUR) as AvailableCurrencies
                                    }
                                    showToll={this.props.logic.plannerLogic().tollCostEnable}
                                    openPlacesDisabled={
                                        this._logic.plannerLogic().calculatingRouteLoading ||
                                        this._logic.plannerLogic().externalSystemLoading ||
                                        this._logic.demo().isActive
                                    }
                                    onAddNewTask={this._onEditActivity}
                                    onSortEnd={this._onPlacesDragAndDrop}
                                    onDeleteClick={this._onPlacesDeleteClick}
                                    onPuescChange={this._onPuescChange}
                                    onRouteClick={this._onRouteClick}
                                    onPlaceLocationChange={this._onPlaceChange}
                                    onPlaceEdited={this._onPlaceEdited}
                                    onAlertCloseClick={this._onAlertCloseClick}
                                />
                                <PlannerAutocomplete
                                    loading={
                                        this._logic.plannerLogic().createRouteLoading ||
                                        this._logic.plannerLogic().calculatingRouteLoading
                                    }
                                    onAddRoute={this._onAddRoute}
                                />
                                {(this._logic.plannerLogic().transport.places?.length ?? 0) > 1 && (
                                    <RouteConfigurator
                                        showToll={this.props.logic.plannerLogic().tollCostEnable}
                                        onTransportTypeChange={this._onTransportTypeChange}
                                    />
                                )}
                                <Row gutter={12} justify="end" className="planner-save-new">
                                    <>
                                        {transport.places && transport.places.length > 1 && (
                                            <Col className="planner-save-new-template">
                                                <Checkbox
                                                    checked={this._logic.plannerLogic().saveTransportTemplate}
                                                    disabled={this._getDisableSave()}
                                                    onChange={this._onTransportTemplateSaveChange}
                                                >
                                                    <span>
                                                        {this._logic.plannerLogic().transport.transportTemplateId
                                                            ? this.props.t(
                                                                  'routing.configurator.template.updateRouteTemplate'
                                                              )
                                                            : this.props.t(
                                                                  'routing.configurator.template.saveRouteTemplate'
                                                              )}
                                                    </span>
                                                </Checkbox>
                                            </Col>
                                        )}
                                        {transport.places && transport.places.length > 0 && (
                                            <Col>
                                                <Button
                                                    onClick={this._onPlannerCancel}
                                                    type="default"
                                                    disabled={this._logic.plannerLogic().calculatingRouteLoading}
                                                    data-qa={qa.schedulingPlanning.cancelWithoutSave}
                                                >
                                                    {this.props.t('Planner.discardChanges')}
                                                </Button>
                                            </Col>
                                        )}
                                    </>
                                    {transport?.places &&
                                        transport?.places.length > 1 &&
                                        transport?.externalId &&
                                        !transport?.places[transport?.places.length - 1].route && (
                                            <Col>
                                                <Button
                                                    type="dashed"
                                                    loading={
                                                        this._logic.plannerLogic().generateRouteLoading ||
                                                        this._logic.plannerLogic().calculatingRouteLoading
                                                    }
                                                    onClick={this._onPlannerGenerateRoute}
                                                    qa={qa.schedulingPlanning.btnGenerateRoute}
                                                >
                                                    {this.props.t('Planner.generateRoute')}
                                                </Button>
                                            </Col>
                                        )}
                                    {transport?.places && transport?.places.length > 1 && (
                                        <Col>
                                            <Button
                                                onClick={this._onPlannerSave}
                                                type="primary"
                                                loading={this.state.loading}
                                                disabled={this._getDisableSave()}
                                                qa={qa.schedulingPlanning.saveRoute}
                                            >
                                                {this._logic.plannerLogic().transport.id
                                                    ? this.props.t('Planner.updateRoute')
                                                    : this.props.t('Planner.createRoute')}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )}
                    </div>
                </div>
                <HelperModal
                    name="planner"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
                {this.state.deleteVehicleConfirmVisible && (
                    <Confirm
                        danger={true}
                        type={MessageType.WARNING}
                        header={this.props.t('Planner.deleteVehicleProfileTitle')}
                        confirmLabel={this.props.t('common.delete')}
                        messageAsHtmlString={this.props.t('Planner.deleteVehicleProfileConfirm', {
                            profile: `<strong>${this._currentVehicleProfile?.name}</strong>`,
                            interpolation: {
                                escapeValue: false
                            }
                        })}
                        onConfirm={this._onDeleteProfileConfirm}
                        onCancel={() => {
                            this.setState({ deleteVehicleConfirmVisible: false });
                        }}
                    />
                )}
                {this.state.templateSaveConfirmOpen && (
                    <Confirm
                        type={MessageType.INFO}
                        header={this.props.t('routing.configurator.template.confirmSaveTitle')}
                        message={this.props.t('routing.configurator.template.confirmSaveText', {
                            name: '"' + this._logic.plannerLogic().transport.name + '"'
                        })}
                        confirmLabel={this.props.t('common.close')}
                        onConfirm={this._onConfirmTemplateSave}
                        onCancel={this._onConfirmTemplateSaveCancel}
                    />
                )}
            </>
        );
    }

    private _onCreateClientClose = () => {
        this.setState({ createClientProfileOpen: false });
    };

    private _onCreateClientSubmit = async (client: ContactList) => {
        try {
            await this._logic.plannerLogic().createClientContact(client);
            message.success('Client created successfully');
            this.setState({
                createClientProfileOpen: false
            });

            return true;
        } catch (err) {
            message.error('Could not create client, try again later');
            return false;
        }
    };

    private _getDisableSave(): boolean {
        return (
            (this._logic.plannerLogic().transport.places?.filter(d => d.route === undefined).length ?? 0) > 1 ||
            this.state.loading ||
            this.state.route.editOptionsOpen ||
            this.state.route.editActivityOpen ||
            this._logic.plannerLogic().calculatingRouteLoading ||
            this._logic.plannerLogic().externalSystemLoading ||
            this._logic.demo().isActive
        );
    }

    // #region vehicleProfile
    async _createVehicleProfile(model: VehicleProfileModel) {
        try {
            await this._logic.plannerLogic().createProfile(model);
            message.success(this.props.t('VehicleProfile.message.createSuccess'));
        } catch (err) {
            console.error(`Could not created vehicle profile, err: ${err}`);
            message.error(this.props.t('VehicleProfile.message.createError'));
        }
    }

    async _updateVehicleProfile(model: VehicleProfileModel) {
        try {
            await this._logic.plannerLogic().updateProfile(model);
            message.success(this.props.t('VehicleProfile.message.updateSuccess'));
        } catch (err) {
            console.error(`Could not updated vehicle profile, err: ${err}`);
            message.error(this.props.t('VehicleProfile.message.updateError'));
        }
    }

    private _onCreateProfileClose = () => {
        this._vehicleProfileType = 'default';
        this._currentVehicleProfile = undefined;
        this.setState({ addVehicleProfileOpen: false });
    };

    private _onVehicleProfileSubmit = async (model: VehicleProfileModel) => {
        if (this._vehicleProfileType === 'default') {
            await this._createVehicleProfile(model);
        } else {
            await this._updateVehicleProfile(model);
        }
        this.setState({ addVehicleProfileOpen: false });
    };

    private _onCreateProfileClick = () => {
        this.setState({ addVehicleProfileOpen: true });
    };

    private _onEditProfileClick = (profile: VehicleProfile) => {
        this._vehicleProfileType = 'edit';
        this._currentVehicleProfile = profile;
        this.setState({ addVehicleProfileOpen: true });
    };

    private _onDeleteProfileClick = (profile: VehicleProfile) => {
        this._currentVehicleProfile = profile;
        this.setState({
            deleteVehicleConfirmVisible: true
        });
    };

    private _onDeleteProfileConfirm = () => {
        try {
            this._logic.plannerLogic().deleteProfile(this._currentVehicleProfile?.id!);
            this._currentVehicleProfile = undefined;
            message.success(this.props.t('VehicleProfile.message.deleteSuccess'));
        } catch (err) {
            console.error(`Could not delete vehicle profile, err: ${err}`);
            message.error(this.props.t('VehicleProfile.message.deleteError'));
        }
        this.setState({
            deleteVehicleConfirmVisible: false
        });
    };

    private _closeAllCards = () => {
        this.setState(state => ({
            route: {
                ...state.route,
                editOptionsOpen: false,
                editActivityOpen: false
            },
            routeDetail: {
                ...state.routeDetail,
                openDetailOptions: false,
                transport: undefined
            }
        }));
        this._selectedTransportPlaceRouteId = undefined;
        this._selectedTransportPlaceIndex = undefined;
    };

    private _onConfirmTemplateSave = () => {
        this._saveTransport();
    };

    private _onConfirmTemplateSaveCancel = () => {
        this.setState({
            templateSaveConfirmOpen: false
        });
    };

    private _verifyTemplateDuplicity = async (): Promise<boolean> => {
        return await this._logic
            .plannerLogic()
            .transportTemplateNameExists(
                this._logic.plannerLogic().transport.transportTemplateId ?? '',
                this._logic.plannerLogic().transport.name ?? ''
            );
    };

    private _routeOptionsChange = async () => {
        if (this._lastPlaceRouteOptionsResult) {
            let selected = this._logic
                .plannerLogic()
                .transport.places?.find(place => place.id === this.state.route.selected?.id);

            let nextPlace: TransportPlace | undefined = undefined;
            this._logic.plannerLogic().transport.places?.forEach((place, index, places) => {
                if (place.id === this.state.route.selected?.id) {
                    nextPlace = places[index + 1];
                    return;
                }
            });

            if (selected) {
                //set next place rta
                nextPlace!.rta?.setSeconds(
                    nextPlace!.rta!.getSeconds() +
                        ((this._lastPlaceRouteOptionsResult?.durationBuffer ?? 0) - (selected.durationBuffer ?? 0))
                );
                selected.durationBuffer = this._lastPlaceRouteOptionsResult?.durationBuffer;
                selected.eventRules = cloneDeep(this._lastPlaceRouteOptionsResult?.eventRules);
            }
        } else if (this._lastRouteOptionsResult) {
            this._logic.plannerLogic().transport.routeOptionsSygic = this._lastRouteOptionsResult.routeOptionsSygic;
        }

        try {
            this._oldTransportPlaceRouteId = undefined;
            this._oldTransportPlaceId = undefined;
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
        } catch (err) {
            console.error(`Could not recompute route to transport, err: ${err}`);
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    // #endregion
    private _onPlaceRouteOptionsSubmit = (options: PlaceRouteOptionsResult) => {
        this._lastPlaceRouteOptionsResult = options;
        this._lastRouteOptionsResult = undefined;
        if (options.clearPreviousId) {
            this._oldTransportPlaceRouteId = undefined;
        }

        let selected = this._logic
            .plannerLogic()
            .transport.places?.find(place => place.id === this.state.route.selected?.id);

        if (selected) {
            selected.eventRules = options.eventRules;

            const durationBuffer = selected.durationBuffer ? selected.durationBuffer : 0;
            if (durationBuffer !== options.durationBuffer) {
                this._routeOptionsChange();
            }

            if (options.offNotification) {
                this._logic.plannerLogic().addPlaceEventRule(selected, TransportAlarmType.CorridorLeave, [
                    {
                        name: 'distance',
                        value: options.corridor
                    }
                ]);
            } else {
                this._logic.plannerLogic().removePlaceEventRule(selected, TransportAlarmType.CorridorLeave);
            }

            this._logic.plannerLogic().updateTransportInLocalStorage();
        }
        this._closeAllCards();
    };

    private _onRouteOptionsSubmit = (options: RouteOptions) => {
        let transport = this._logic.plannerLogic().transport;
        this._lastRouteOptionsResult = options;
        this._lastPlaceRouteOptionsResult = undefined;

        if (
            JSON.stringify(transport.routeOptionsSygic?.avoidOptionsSygic ?? []) !==
            JSON.stringify(options.routeOptionsSygic.avoidOptionsSygic ?? [])
        ) {
            this._routeOptionsChange();
        }

        this._closeAllCards();
    };

    private _onRoutePriceChange = (configuration: RouteConfiguration) => {
        this.setState({
            pricePerKm: configuration.pricePerKM.cost
        });
        this._logic
            .plannerLogic()
            .setTransportCostPerKm(configuration.pricePerKM.cost, configuration.pricePerKM.currency);
    };

    private _onTransportTypeChange = (type: TransportType) => {
        if (type !== this._logic.plannerLogic().selectedTransportType) {
            this._logic.plannerLogic().selectTransportType(type);
            this._logic.plannerLogic().drawRouteOnMap();
        }
        this._closeAllCards();
        if (!this.state.routeDetail.openDetailOptions) {
            this.setState(() => ({
                routeDetail: {
                    openDetailOptions: true,
                    transport: this._logic.plannerLogic().transport
                }
            }));
        }
    };

    private _onTransportTemplateSaveChange = async (e: CheckboxChangeEvent) => {
        this._logic.plannerLogic().setTransportTemplateSave(e.target.checked);
    };

    private _onPlannerCancel = () => {
        this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
            status: exponea.status.actionTaken,
            action: exponea.action.cancelRoute
        });

        const { backUrl } = this.state;
        if (backUrl) {
            this.props.history.push(backUrl);
        }

        this._logic.plannerLogic().reset();
    };

    private _onPlannerGenerateRoute = async () => {
        await this._logic.plannerLogic().generateRoute();
        await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
        this._logic.plannerLogic().drawRouteOnMap();
    };

    private _onAddRoute = async (data: RouteSelectData, element: RefObject<RefSelectProps>) => {
        this._closeAllCards();
        const transportPlaces = this._logic.plannerLogic().transport.places;
        if (transportPlaces && transportPlaces.length > 0) {
            this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
                status: exponea.status.actionTaken,
                action: exponea.action.addDestinationPointToRoute,
                places_count: transportPlaces.length
            });
        }

        try {
            await this._logic.plannerLogic().createRouteForTransport(data);
            this._logic.plannerLogic().updateTransportNameByPlaces();
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
            if (element) {
                element.current?.focus();
            }
        } catch (err) {
            console.error(`Could not add route to transport, err: ${err}`);
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlacesDragAndDrop = async (sort: SortEnd, _event: SortEvent) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this._logic.plannerLogic().updatePlaceOrder(sort.oldIndex, sort.newIndex);
            this._logic.plannerLogic().clearCrossingPoints(sort);
            this._logic.map().routing().resetPlaces();
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlacesDeleteClick = async (id: string) => {
        if (!(this._logic.plannerLogic().createRouteLoading || this._logic.plannerLogic().calculatingRouteLoading)) {
            this._logic.plannerLogic().calculateTollCost(this._logic.plannerLogic().transport);
            this._logic.map().polygons().removePolygon(id);
            this._logic.plannerLogic().removePlaceFromTransport(id);
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            await this._logic.plannerLogic().drawRouteOnMap();
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlannerSave = async () => {
        if (this._logic.plannerLogic().saveTransportTemplate) {
            if (await this._verifyTemplateDuplicity()) {
                this.setState({
                    templateSaveConfirmOpen: true
                });
                return;
            }
        }

        this._saveTransport();
    };

    private _saveTransport = async () => {
        this.setState(
            {
                loading: true
            },
            () => {
                if (
                    this._logic.plannerLogic().transport.puesc &&
                    this._logic.plannerLogic().puescSecret?.metadata?.puescActive &&
                    this._logic.plannerLogic().selectedVehicleId &&
                    !this.state.puescModal.visited
                ) {
                    this.setState(state => ({
                        puescModal: {
                            ...state.puescModal,
                            open: true,
                            visited: true
                        },
                        loading: false
                    }));
                } else {
                    this.setState(
                        state => ({
                            puescModal: {
                                ...state.puescModal,
                                visited: false
                            }
                        }),
                        async () => {
                            const params: RouteParams = qs.parse(this.props.history.location.search, {
                                ignoreQueryPrefix: true
                            });
                            this._logic.exponea().trackEvent(exponea.module.schedulingPlanner, {
                                status: exponea.status.actionTaken,
                                action: exponea.action.saveRoute,
                                src: params.dispatcherBoard
                                    ? exponea.module.schedulingDispatcherBoard
                                    : params.editId
                                    ? exponea.module.schedulingRouteOverview
                                    : params.vehicleId
                                    ? exponea.module.trackingTableView
                                    : undefined
                            });
                            try {
                                await this._logic.plannerLogic().saveTransport();
                            } catch (err) {
                                console.error(`could not save transport, err: ${err}`);
                                this.setState({
                                    loading: false
                                });
                            }

                            this._logic.plannerLogic().reset(true);

                            if (this.state.backUrl) {
                                this.props.history.push(this.state.backUrl);
                            } else {
                                this.props.history.push({
                                    pathname: RouteNames.SCHEDULING_ORDERS
                                });
                            }
                        }
                    );
                }
            }
        );
    };

    private _onEditActivity = (placeId: string | undefined) => {
        this._closeAllCards();

        if (placeId !== this._oldTransportPlaceId && !this.state.route.editActivityOpen) {
            this._lastActivityTransportPlace = cloneDeep(
                this._logic.plannerLogic().transport.places?.find(place => place.id === placeId)
            );
            this._selectedTransportPlaceIndex = this._logic
                .plannerLogic()
                .transport.places?.findIndex(place => place.id === placeId);
            this._oldTransportPlaceId = placeId;
            this.setState(state => ({
                route: {
                    ...state.route,
                    editActivityOpen: true,
                    selected: this._logic.plannerLogic().transport.places?.find(place => place.id === placeId)
                }
            }));
        } else {
            this.setState(state => ({
                route: {
                    ...state.route,
                    editActivityOpen: false,
                    selected: undefined
                }
            }));
            this._oldTransportPlaceId = undefined;
            this._selectedTransportPlaceIndex = undefined;
        }
        this._oldTransportPlaceRouteId = undefined;
    };

    private _activityChange = async () => {
        this._selectedTransportPlaceIndex = undefined;
        if (this._lastActivityResult && this.state.route.selected) {
            const change =
                this._logic.plannerLogic().getTransportPlaceTaskTime(this.state.route.selected?.tasks) !==
                this._logic.plannerLogic().getTransportPlaceTaskTime(this._lastActivityResult?.tasks);

            this.state.route.selected.tasks = this._lastActivityResult.tasks;
            this.state.route.selected.eventRules = this._lastActivityResult.eventRules;
            if (this._lastActivityResult.manualAta.use) {
                this.state.route.selected.ata = this._lastActivityResult.manualAta.date;
            }

            this._lastActivityResult = undefined;

            if (change) {
                try {
                    this._oldTransportPlaceId = undefined;
                    await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
                    this._logic.plannerLogic().drawRouteOnMap();
                } catch (err) {
                    console.error(`Could not recompute route to transport, err: ${err}`);
                    this._logic.plannerLogic().updateTransportNameByPlaces();
                }
            }
        }
    };

    private _onSubmitActivity = (result: ActivityResult) => {
        if (result.clearPreviousId) {
            this._oldTransportPlaceId = undefined;
        }
        if (
            result.manualAta.use &&
            result.manualAta.date &&
            this._lastActivityTransportPlace?.id &&
            this._logic.plannerLogic().transport?.id
        ) {
            this._logic
                .transportLogic()
                .setManualAtaForPlace(
                    result.manualAta.date,
                    this._lastActivityTransportPlace.id!,
                    this._logic.plannerLogic().transport.id!
                );
        }
        this._lastActivityResult = result;
        this._activityChange();
        this._closeAllCards();
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'routeplanning';
        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onPuescChange = (active: boolean) => {
        this._logic.plannerLogic().onPuescSwitch(active);
    };

    private _onRouteClick = (place: TransportPlace) => {
        this._closeAllCards();

        if (place.id !== this._oldTransportPlaceRouteId && !this.state.route.editOptionsOpen) {
            this._selectedTransportPlaceRouteId = place.id!;
            this._oldTransportPlaceRouteId = place.id!;
            this._lastActivityTransportPlace = cloneDeep(
                this._logic.plannerLogic().transport.places?.find(p => p.id === place.id)
            );

            this.setState(state => ({
                route: {
                    ...state.route,
                    editOptionsOpen: true,
                    selected: place
                }
            }));
        } else {
            this.setState(state => ({
                route: {
                    ...state.route,
                    editOptionsOpen: false,
                    selected: undefined
                }
            }));
            this._oldTransportPlaceRouteId = undefined;
        }
        this._oldTransportPlaceId = undefined;
    };

    private _onPlaceChange = async (data: RouteSelectData, placeId: string | undefined, index: number) => {
        try {
            await this._logic.plannerLogic().updateRouteForTransport(data, placeId, index);
            this._logic.plannerLogic().updateTransportNameByPlaces();
            await this._logic.plannerLogic().planRoute(this._logic.plannerLogic().transport);
            this._logic.plannerLogic().drawRouteOnMap();
        } catch (err) {
            console.error(`Could not add route to transport, err: ${err}`);
            this._logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _onPlaceEdited = () => {
        this._closeAllCards();
    };

    private _onAlertCloseClick = () => {
        const planner = this._logic.settings().getProp('planner');
        this._logic.settings().setProp('planner', {
            ...planner,
            hideAetrBreaksAlert: true
        });
    };

    private _getSelectedVehicleName = (): string => {
        let name = '';
        if (this._logic.plannerLogic().selectedVehicleId) {
            name =
                this._logic
                    .plannerLogic()
                    .availableVehicles.find(
                        vehicle =>
                            vehicle.data.monitoredObjectId === String(this._logic.plannerLogic().selectedVehicleId)
                    )?.data.rn ?? '';
        } else {
            const profileName =
                this.props.logic
                    .plannerLogic()
                    .availableVehicleProfiles.find(
                        profile => profile.id === this._logic.plannerLogic().selectedProfileId
                    )?.name ?? '';
            name =
                profileName === defaultVehicleProfileName
                    ? this.props.t('VehicleProfile.defaultProfileName')
                    : profileName;
        }
        return name;
    };

    _getErrorText(error: InaccessiblePointError): ReactNode {
        const formatValue = (text: string) => {
            return `<span class="message-value">${text}</span>`;
        };

        if (error.points && error.points?.length > 0) {
            const point = error.points[0];
            const logic = this.props.logic.plannerLogic();
            switch (point.type) {
                case InaccessiblePointTypeEnum.Place:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRoutePlaceDescription', {
                                    place: formatValue(point.name ?? ''),
                                    vehicle: formatValue(this._getSelectedVehicleName()),
                                    interpolation: {
                                        escapeValue: false
                                    }
                                })
                            }}
                        />
                    );
                case InaccessiblePointTypeEnum.Crossing:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRouteCrossingDescription', {
                                    number: formatValue(`${(point.crossingIndex ?? 0) + 1}.`),
                                    placeFrom: formatValue(
                                        logic.transport?.places?.[point.placeIndex ?? 0]?.name ?? ''
                                    ),
                                    placeTo: formatValue(
                                        logic.transport?.places?.[(point.placeIndex ?? 0) + 1]?.name ?? ''
                                    ),
                                    vehicle: formatValue(this._getSelectedVehicleName()),
                                    interpolation: {
                                        escapeValue: false
                                    }
                                })
                            }}
                        />
                    );
                default:
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('routing.error.noRouteDescription', {
                                    vehicle: formatValue(this._getSelectedVehicleName()),
                                    interpolation: {
                                        escapeValue: false
                                    }
                                })
                            }}
                        />
                    );
            }
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.t('routing.error.noRouteDescription', {
                            vehicle: formatValue(this._getSelectedVehicleName()),
                            interpolation: {
                                escapeValue: false
                            }
                        })
                    }}
                />
            );
        }
    }
}

export default withTranslation()(withRouter(observer(RoutePlanningScheduling)));
