import { Logic } from './logic';
import { TrackingModel } from 'common/model/tracking';
import { VehicleStateWithRN } from 'generated/backend-api';
import { VehicleStateObject } from 'generated/graphql';
import { NotificationMessage } from './notification-eio';
import { ControlPanel } from 'modules/map/components/MapControlsBar';

export class SimpleTrackingLogic {
    private _vehicles: TrackingModel[];
    private _selectedVehicle?: TrackingModel;

    constructor(private logic: Logic) {
        this._vehicles = [];
    }

    async init() {
        this.logic.map().setPadding(this.logic.map().initialPadding);
        this.logic.map().sideBarControlsOff();
        this.logic.map().vehicleCardData();
        const data = await this._loadInitialData();
        this._vehicles = data.map(v => this.logic.tracking().toTrackingModel(v as any, []));
        const mapData = this._vehicles.map(v => this.logic.tracking().toMapModel(v));
        this.logic.map().vehicles().setData(mapData);
        this.logic.map().vehicles().show();
        this.logic.map().vehicles().fitVehicles();
        this._watchUpdates();
        this.logic.notification().on('actual-vehicle-state', message => this._handleActualVehicleStateUpdate(message));
    }

    selectVehicle(id: string) {
        if (this._isVehicleGPSInValid(id)) {
            return;
        }
        if (this._selectedVehicle?.id === id) {
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                selected: false,
                checked: false,
                centered: false,
                route: false
            }));
            this._selectedVehicle = undefined;
            this.logic.map().vehicleCardData();
            this.logic.map().sideBarControlsOff();
            this.logic.map().vehicles().fitVehicles();
        } else {
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                selected: v.id === id,
                checked: v.id === id,
                centered: v.id === id,
                route: false
            }));

            this._selectedVehicle = this._vehicles.find(v => v.selected);
            this.logic.map().vehicleCardData(this._loadVehicle(id));
            this.logic.map().vehicleOn();
            if (this._selectedVehicle) {
                this.logic.map().vehicles().fitVehicle(this._selectedVehicle?.id);
            }
        }
    }

    unSelectVehicle() {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            selected: false,
            centered: false,
            route: false
        }));
        this.logic.map().sideBarControlsOff(true, ControlPanel.VEHICLE);
        this.logic.map().vehicleCardData();
        this._selectedVehicle = undefined;
        this._updateMap();
    }

    centerOffVehicles() {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            centered: false
        }));
        this._updateMap();

        if (this._selectedVehicle) {
            this.logic.map().setControlCenterVisible(true);
        }
    }

    private _watchUpdates() {
        this.logic
            .notification()
            .onConnect()
            .subscribe(async () => {
                const vd = await this._loadInitialData();

                this._vehicles = this._vehicles.map(v => {
                    const update: VehicleStateWithRN | undefined = vd.find(u => u.monitoredObjectId === v.id);
                    if (update) {
                        const vehicle: TrackingModel = {
                            ...this.logic.tracking().toTrackingModel(update as VehicleStateObject, [], v),
                            selected: v.selected,
                            checked: v.checked,
                            route: v.route,
                            centered: v.centered,
                            monitoredObjectType: v.monitoredObjectType,
                            tags: v.tags,
                            driverBehaviorTrends: v.driverBehaviorTrends,
                            driverBehaviorLightVehicle: v.driverBehaviorLightVehicle,
                            trailersName: v.trailers?.map(t => t.registrationNumber) ?? [],
                            RN: v.RN
                        };
                        return vehicle;
                    } else {
                        return v;
                    }
                });

                this._updateMap();
            });
    }

    private _handleActualVehicleStateUpdate(message: NotificationMessage<{ key: VehicleStateObject }>) {
        const updates = message.data ? Object.values(message.data) : undefined;
        this._vehicles = this._vehicles.map(v => {
            const update: VehicleStateObject | undefined = updates?.find(u => u.monitoredObjectId === v.id);
            if (update) {
                const vehicle: TrackingModel = {
                    ...this.logic.tracking().toTrackingModel(update, [], v),
                    selected: v.selected,
                    checked: v.checked,
                    route: v.route,
                    centered: v.centered,
                    monitoredObjectType: v.monitoredObjectType,
                    trailersName: v.trailers?.map(t => t.registrationNumber) ?? [],
                    tags: v.tags,
                    driverBehaviorTrends: v.driverBehaviorTrends,
                    driverBehaviorLightVehicle: v.driverBehaviorLightVehicle,
                    RN: v.RN
                };
                return vehicle;
            } else {
                return v;
            }
        });

        this._updateMap();
    }

    private _updateMap() {
        const selectedVehicle = this._vehicles.find(v => v.id === this._selectedVehicle?.id);
        if (this._vehicles.length) {
            let data = this._vehicles.map(v => this.logic.tracking().toMapModel(v));
            if (selectedVehicle) {
                data = [this.logic.tracking().toMapModel(selectedVehicle), ...data];
            }
            this.logic.map().vehicles().update(data);
        } else if (selectedVehicle) {
            const data = [this.logic.tracking().toMapModel(selectedVehicle)];
            this.logic.map().vehicles().update(data);
        }
    }

    private _loadVehicle(id: string) {
        return this._vehicles.find(v => v.id === id);
    }

    private _isVehicleGPSInValid(id: string) {
        const vehicle = this._vehicles.find(v => v.id === id);
        return !vehicle?.GPS?.lat || !vehicle?.GPS?.lng;
    }

    private _loadInitialData() {
        return this.logic.api().vehicleStateApi.getActualStatesV1ActualvehiclestateGet({
            device: this.logic.notification().device,
            user: this.logic.notification().guestUser
        });
    }
}
