import { Logic } from './logic';
import { CCRState } from 'modules/management/modules/driver-cards-remote-memory/DriversCardsRemoteMemoryModule';
import {
    Get_CcRsQuery,
    Get_CcRsQueryVariables,
    Get_CcRsDocument,
    Assign_CcrMutation,
    Assign_CcrMutationVariables,
    Assign_CcrDocument,
    Unassign_CcrMutation,
    Unassign_CcrMutationVariables,
    Unassign_CcrDocument,
    Subscribe_CcrDocument,
    Subscribe_CcrQueryVariables,
    Subscribe_CcrQuery
} from 'generated/graphql';
import moment, { Moment } from 'moment';

export interface CCRModel {
    id: string;
    serialNumber: string;
    state: CCRState;
}

export interface MessageCCRModel {
    serialNumber: string;
    state: CCRState;
}

export class CCRLogic {
    private _data: CCRModel[];

    private _cardReaderStateMessageDate: Moment;

    private _onData?: (data: CCRModel[]) => void;

    constructor(private _logic: Logic) {
        this._data = [];
        this._cardReaderStateMessageDate = moment();
    }

    onData(cb: (data: CCRModel[]) => void): void {
        this._onData = cb;
    }

    async init(): Promise<void> {
        await this._subscribeCCR(
            this._data.map(ccr => ccr.serialNumber),
            this._logic.notification().device!
        );

        this._logic
            .notification()
            .onConnect()
            .subscribe(async () => {
                try {
                    await this._subscribeCCR(
                        this._data.map(ccr => ccr.serialNumber),
                        this._logic.notification().device!
                    );
                } catch (err) {
                    console.log('subscribe onConnect error ', err);
                    throw err;
                }
            });

        this._logic.notification().on('cardreader-state', message => {
            try {
                const messageCcr: MessageCCRModel | undefined = this._messageToCCR(message.data);

                this._cardReaderStateMessageDate = moment();
                if (messageCcr) {
                    this._data = this._data.map(ccr => {
                        if (messageCcr.serialNumber === ccr.serialNumber) {
                            return { ...ccr, state: messageCcr.state };
                        } else {
                            return ccr;
                        }
                    });
                    this._onData?.(this._data);
                }
            } catch (err) {
                console.log('cardreader-state notfication error ', err);
                throw err;
            }
        });

        setInterval(() => {
            this._resubscribeCCR();
        }, 60000);
    }

    async getCCRs(): Promise<CCRModel[]> {
        try {
            if (this._logic.demo().isActive) {
                return this._logic.demo().data.ccrCards;
            } else {
                const res = await this._logic.apollo().query<Get_CcRsQuery, Get_CcRsQueryVariables>({
                    query: Get_CcRsDocument,
                    fetchPolicy: 'no-cache',
                    variables: {}
                });
                this._data = (res.data.get_CCRs || []).map(ccr =>
                    this._toCCR({ ...ccr, state: CCRState.ConnectedCompany })
                );
                return this._data;
            }
        } catch (err) {
            console.log('getCCRs error ', err);
            throw err;
        }
    }

    async assignCCR(card_reader_sn: string): Promise<CCRModel> {
        try {
            const res = await this._logic.apollo().query<Assign_CcrMutation, Assign_CcrMutationVariables>({
                query: Assign_CcrDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    ccrInput: {
                        card_reader_sn: card_reader_sn
                    }
                }
            });
            const newCCR = this._toCCR({ id: res.data.assign_CCR?.id, card_reader_sn } ?? {});
            this._data = [...this._data, newCCR];
            this._subscribeCCR(
                this._data.map(ccr => ccr.serialNumber),
                this._logic.notification().device!
            );
            return newCCR;
        } catch (err) {
            console.log('getCCRs error ', err);
            throw err;
        }
    }

    async unassignCCR(ccrId: string): Promise<CCRModel> {
        try {
            const res = await this._logic.apollo().query<Unassign_CcrMutation, Unassign_CcrMutationVariables>({
                query: Unassign_CcrDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    ccrUpdate: {
                        id: Number(ccrId)
                    }
                }
            });
            const oldCCR = this._toCCR({ id: res.data.unassign_CCR?.id } ?? {});
            this._data = this._data.filter(ccr => ccr.id !== oldCCR.id);
            return oldCCR;
        } catch (err) {
            console.log('unassignCCR error ', err);
            throw err;
        }
    }

    async _subscribeCCR(serialNumbers: string[], device: string): Promise<CCRModel[]> {
        try {
            const res = await this._logic.apollo().query<Subscribe_CcrQuery, Subscribe_CcrQueryVariables>({
                query: Subscribe_CcrDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    serialNumbers,
                    device
                }
            });
            const ccrs: MessageCCRModel[] | undefined = res.data.subscribe_CCR?.map(ccr => this._toCCR(ccr));
            this._cardReaderStateMessageDate = moment();

            if (ccrs) {
                this._data = this._data.map(ccr => {
                    const update: MessageCCRModel | undefined = ccrs.find(i => i.serialNumber === ccr.serialNumber);
                    if (update) {
                        return { ...ccr, state: update.state };
                    } else {
                        return ccr;
                    }
                });
                this._onData?.(this._data);
            }
            return this._data;
        } catch (err) {
            this._data = this._data.map(ccr => ({ ...ccr, state: CCRState.Unknown }));
            this._onData?.(this._data);
            console.log('subscribe CCR error ', err);
            throw err;
        }
    }

    async _resubscribeCCR(): Promise<void> {
        if (moment(this._cardReaderStateMessageDate).add(1, 'minutes').isBefore(moment())) {
            this._cardReaderStateMessageDate = moment();
            await this._subscribeCCR(
                this._data.map(ccr => ccr.serialNumber),
                this._logic.notification().device!
            );
        }
    }

    private _toCCR(data: any): CCRModel {
        return {
            id: String(data.id) ?? '',
            serialNumber: data.card_reader_sn ?? '',
            state: (data.state as CCRState) ?? CCRState.Unknown
        };
    }

    private _messageToCCR(data: any): MessageCCRModel {
        const state = this._messageStateCcr(data);
        return {
            serialNumber: data.state.sn ?? '',
            state: (state as CCRState) ?? CCRState.Unknown
        };
    }

    private _messageStateCcr(data: any): CCRState {
        if (data.state) {
            if (data.state.connected === false) {
                return CCRState.Disconnected;
            } else if (data.state.connected) {
                if (data.state?.driver_info?.card_number) {
                    return CCRState.ConnectedDriver;
                } else if (data.state?.stat_result?.atr && data.state?.stat_result?.sn) {
                    return CCRState.ConnectedCompany;
                } else {
                    return CCRState.Connected;
                }
            } else {
                return CCRState.Unknown;
            }
        } else {
            return CCRState.Unknown;
        }
    }

    destroy() {
        this._onData = undefined;
    }
}
