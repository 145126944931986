import { Row } from 'antd';
import { useLogic } from 'App';
import { Pagination, Table } from 'common/components';
import { ReadOnlyContactList, ReadOnlyContactListTypeEnum } from 'generated/new-main';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PaginationParams } from 'common/model/pagination';
import qa from 'qa-selectors';
import { ReactNode } from 'react';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props {
    lastCreated?: ReadOnlyContactList;
    onPaginationParamsChange: (pagination: PaginationParams) => void;
    onRowSelect: (contact?: ReadOnlyContactList) => void;
}

export default observer(function ContactListTable(props: Props) {
    const { t } = useTranslation();
    const logic = useLogic();
    const selectedContact = logic.contactListLogic().selectedContact;

    const getDisplayedContactTypes = (types: ReadOnlyContactListTypeEnum[]) => {
        let typesList: ReactNode[] = [];

        if (types.length === 0) {
            return typesList;
        }

        if (types.includes(ReadOnlyContactListTypeEnum.Client)) {
            typesList.push(
                <span data-qa={qa.contacts.table.fieldTypeClient}>{t('ContactList.tableClientTypeValues.client')}</span>
            );
        }

        if (types.includes(ReadOnlyContactListTypeEnum.Supplier)) {
            if (typesList.length > 0) {
                typesList.push(', ');
            }
            typesList.push(
                <span data-qa={qa.contacts.table.fieldTypeSupplier}>
                    {t('ContactList.tableClientTypeValues.supplier')}
                </span>
            );
        }

        if (types.includes(ReadOnlyContactListTypeEnum.Consignee)) {
            if (typesList.length > 0) {
                typesList.push(', ');
            }
            typesList.push(
                <span data-qa={qa.contacts.table.fieldTypeConsignee}>
                    {t('ContactList.tableClientTypeValues.consignee')}
                </span>
            );
        }

        return typesList;
    };

    return (
        <div>
            <Table
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                loading={logic.contactListLogic().contactListLoading}
                dataSource={logic.contactListLogic().contactList.data.map(d => ({ ...d, key: d.id }))}
                onRow={record => ({
                    className: cn({
                        'ant-table-row-last-created': props.lastCreated?.id === record.id,
                        'ant-table-row-selected': selectedContact?.id === record.id
                    }),
                    onClick: () => {
                        props.onRowSelect(record);
                    }
                })}
                columns={[
                    {
                        title: t('ContactList.table.name'),
                        width: 80,
                        align: 'left',
                        key: 'name',
                        dataIndex: 'name',
                        render: value => <span data-qa={qa.contacts.table.fieldName}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.businessId'),
                        width: 50,
                        align: 'left',
                        key: 'businessId',
                        dataIndex: 'businessId',
                        render: value => <span data-qa={qa.contacts.table.fieldBussinessId}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.vat'),
                        width: 50,
                        align: 'left',
                        key: 'vat',
                        dataIndex: 'vat',
                        render: value => <span data-qa={qa.contacts.table.fieldVat}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.taxId'),
                        width: 50,
                        align: 'left',
                        key: 'taxId',
                        dataIndex: 'taxId',
                        render: value => <span data-qa={qa.contacts.table.fieldTaxId}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.otherIdentificationNumber'),
                        width: 100,
                        align: 'left',
                        key: 'otherIdentificationNumber',
                        dataIndex: 'otherIdentificationNumber',
                        render: value => <span data-qa={qa.contacts.table.fieldOtherNotificationNm}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.type'),
                        width: 50,
                        align: 'left',
                        key: 'type',
                        dataIndex: 'type',
                        render: value => getDisplayedContactTypes(value).map(ct => ct)
                    },
                    {
                        title: t('ContactList.table.country'),
                        width: 50,
                        align: 'left',
                        key: 'countryId',
                        dataIndex: ['country', 'name'],
                        render: value => <span data-qa={qa.contacts.table.fieldCountry}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.city'),
                        width: 50,
                        align: 'left',
                        key: 'city',
                        dataIndex: 'city',
                        render: value => <span data-qa={qa.contacts.table.fieldCity}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.postalCode'),
                        width: 50,
                        align: 'left',
                        key: 'postalCode',
                        dataIndex: 'postalCode',
                        render: value => <span data-qa={qa.contacts.table.fieldPostalCode}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.street'),
                        width: 50,
                        align: 'left',
                        key: 'street',
                        dataIndex: 'street',
                        render: value => <span data-qa={qa.contacts.table.fieldStreet}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.houseNumber'),
                        width: 50,
                        align: 'left',
                        key: 'houseNumber',
                        dataIndex: 'houseNumber',
                        render: value => <span data-qa={qa.contacts.table.fieldHouseNumber}>{value}</span>
                    },
                    {
                        title: t('ContactList.table.premisesNumber'),
                        width: 50,
                        align: 'left',
                        key: 'premisesNumber',
                        dataIndex: 'premisesNumber',
                        render: value => <span data-qa={qa.contacts.table.fieldPremisesNumber}>{value}</span>
                    }
                ]}
            />
            {!logic.contactListLogic().contactListLoading &&
            logic.contactListLogic().contactList.total > 0 &&
            logic.contactListLogic().contactList.total > logic.contactListLogic().contactList.limit &&
            logic.contactListLogic().contactList.offset !== undefined &&
            logic.contactListLogic().contactList.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={logic.contactListLogic().contactList.total}
                        pageSize={logic.contactListLogic().contactList.limit}
                        current={getTableCurrentPage(
                            logic.contactListLogic().contactList.offset,
                            logic.contactListLogic().contactList.limit
                        )}
                        onChange={props.onPaginationParamsChange}
                    />
                </Row>
            ) : null}
        </div>
    );
});
