import { TIME_FORMAT_SHORT } from 'domain-constants';
import moment from 'moment';
import { GraphKey } from '../../JourneyGraph';

export const formatGraphData = (
    section: GraphKey,
    data?: {
        lats: number[] | null;
        lons: number[] | null;
        fuelLevels: number[] | null;
        speeds: number[] | null;
        timestamps: number[] | null;
    }
): Record<string, string | number>[] => {
    if (data?.[section] && data.timestamps && data.lats && data.lons) {
        return data.timestamps.map((stamp, index) => ({
            key: moment.unix(stamp).format(TIME_FORMAT_SHORT),
            value: data?.[section]?.[index] ?? 0,
            lat: data.lats?.[index] ?? 0,
            lon: data.lons?.[index] ?? 0
        }));
    }

    return [];
};
