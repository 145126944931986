import { Component } from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { message, Typography, Row, Col } from 'antd';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { RouteNames, rbac } from 'App';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';

import DriverBehaviorList from './List';
import DriverBehaviorDetail from './Detail';
import DriversLeaderboardTable from '../../components/DriversLeaderboardTable';
import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';
import Button from 'common/components/Button';
import { Loading } from 'common/components';
import { LayoutContent } from 'common/components/Layout/Content';
import qa from 'qa-selectors';
import { Theme } from 'common/components/Settings';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
    theme: Theme;
}

interface State {
    data: DriverBehaviorLightVehicleModel[];
    loading: boolean;
    sidePanelTab: 'top5improvers' | 'worst5drivers';
}

class DriverBehaviorVehiclesModule extends Component<Props, State> {
    subscriptionData?: Subscription;
    subscriptionLoading?: Subscription;
    subscriptionError?: Subscription;
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {
            data: [],
            loading: true,
            sidePanelTab: 'top5improvers'
        };
    }

    componentDidMount() {
        this.subscriptionData = this.props.logic
            .driverBehaviorCoach()
            .vehicles()
            .data.subscribe(data => {
                this.setState({ data, loading: false });
            });

        this.subscriptionLoading = this.props.logic
            .driverBehaviorCoach()
            .vehicles()
            .loading.subscribe(loading => {
                this.setState({ loading });
            });

        this.subscriptionError = this.props.logic
            .driverBehaviorCoach()
            .vehicles()
            .error.subscribe(error => {
                console.error(`Load data error, err: ${error}`);
                message.error(this.props.t('common.error.loadDataError'));
            });

        this.props.logic.driverBehaviorCoach().vehicles().loadData(moment.utc().startOf('month').toISOString());
    }

    componentWillUnmount() {
        this.subscriptionData?.unsubscribe();
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionError?.unsubscribe();
    }

    render() {
        const roles = this._logic.auth().roles();
        const { t } = this.props;
        const { loading } = this.state;
        return (
            <LayoutContent
                className="driver-behavior"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <Switch>
                        <Route
                            exact
                            path={RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_LIST}
                            render={rbac(roles, [Role.DBH_R], <DriverBehaviorList logic={this._logic} />)}
                        />
                        <Route
                            exact
                            path={`${RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL}/:id`}
                            render={rbac(
                                roles,
                                [Role.DBH_R],
                                <DriverBehaviorDetail logic={this._logic} theme={this.props.theme} />
                            )}
                        />
                        <Redirect to={RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_LIST} />
                    </Switch>
                }
                extra={[
                    !loading ? (
                        <>
                            <Typography.Title level={4}>{t('DriverBehavior.topDrivers')}</Typography.Title>
                            <DriversLeaderboardTable
                                data={this._logic.driverBehaviorCoach().vehicles().selectTopDrivers()}
                                loading={loading}
                                showHeader={false}
                                onRowClick={this._onTableRowClick}
                                className="drivers-leaderboard-table-top-drivers"
                                qa={qa.driverBehaviour.side.topDrivers}
                            />

                            {this._logic.driverBehaviorCoach().trucks().selectTopImproversDrivers().length > 0 && (
                                <>
                                    <Row gutter={4} justify="center">
                                        <Col>
                                            <Button
                                                type={
                                                    this.state.sidePanelTab === 'top5improvers' ? 'primary' : 'default'
                                                }
                                                onClick={() => this.setState({ sidePanelTab: 'top5improvers' })}
                                                qa={qa.driverBehaviour.side.btnTopImprovers}
                                            >
                                                {t('DriverBehavior.topImprovers')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type={
                                                    this.state.sidePanelTab === 'worst5drivers' ? 'primary' : 'default'
                                                }
                                                onClick={() => this.setState({ sidePanelTab: 'worst5drivers' })}
                                                qa={qa.driverBehaviour.side.btnWorstDrivers}
                                            >
                                                {t('DriverBehavior.worstDrivers')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {this._logic.driverBehaviorCoach().vehicles().selectTopImproversDrivers().length > 0 &&
                                this.state.sidePanelTab === 'top5improvers' && (
                                    <>
                                        <DriversLeaderboardTable
                                            data={this._logic
                                                .driverBehaviorCoach()
                                                .vehicles()
                                                .selectTopImproversDrivers()}
                                            loading={loading}
                                            showHeader={false}
                                            onRowClick={this._onTableRowClick}
                                            qa={qa.driverBehaviour.side.topImprovers}
                                        />
                                    </>
                                )}

                            {this.state.sidePanelTab === 'worst5drivers' && (
                                <DriversLeaderboardTable
                                    data={this._logic.driverBehaviorCoach().vehicles().selectWorstDrivers()}
                                    loading={loading}
                                    showHeader={false}
                                    onRowClick={this._onTableRowClick}
                                    qa={qa.driverBehaviour.side.worstDrivers}
                                />
                            )}
                        </>
                    ) : (
                        <Loading />
                    )
                ]}
            />
        );
    }

    private _onTableRowClick = (id: string) => {
        this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL + '/' + id);
    };
}

export default withRouter(withTranslation()(DriverBehaviorVehiclesModule));
