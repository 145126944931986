import { Subject } from 'rxjs';
import { data, DemoData } from 'common/demo';
import { Logic } from './logic';
import { Role } from './auth';

export class DemoLogic {
    isActive: boolean = false;
    active: Subject<boolean>;
    data: DemoData;
    settings: { active: boolean; roles: Role[] };
    logic: Logic;

    constructor(logic: Logic) {
        this.logic = logic;
        this.settings = this.logic.settings().getProp('demoMode');
        const roles = this.logic.auth().roles(true);
        if (!this.logic.conf.testMode) {
            if (roles.includes(Role.DM_RD)) {
                this.isActive = true;
            } else {
                if (roles.includes(Role.DM_R)) {
                    this.isActive = this.settings?.active;
                } else {
                    this.isActive = false;
                }
            }
        } else {
            this.isActive = true;
        }
        this.data = data;
        this.active = new Subject<boolean>();
    }

    enable() {
        this.isActive = true;
        this.logic.settings().setProp('demoMode', { ...this.settings, active: this.isActive });
        this.active.next(this.isActive);
    }

    disable() {
        this.isActive = false;
        this.logic.settings().setProp('demoMode', { ...this.settings, active: this.isActive });
        this.active.next(this.isActive);
    }
}
