/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MuDataProcessingModelsActivityInterval,
    MuDataProcessingModelsActivityIntervalFromJSON,
    MuDataProcessingModelsActivityIntervalFromJSONTyped,
    MuDataProcessingModelsActivityIntervalToJSON,
    MuDataProcessingModelsDriverInfoDriver,
    MuDataProcessingModelsDriverInfoDriverFromJSON,
    MuDataProcessingModelsDriverInfoDriverFromJSONTyped,
    MuDataProcessingModelsDriverInfoDriverToJSON,
    RouteOptionsSygic,
    RouteOptionsSygicFromJSON,
    RouteOptionsSygicFromJSONTyped,
    RouteOptionsSygicToJSON,
    TransportCostPerKm,
    TransportCostPerKmFromJSON,
    TransportCostPerKmFromJSONTyped,
    TransportCostPerKmToJSON,
    TransportDriver,
    TransportDriverFromJSON,
    TransportDriverFromJSONTyped,
    TransportDriverToJSON,
    TransportMonitoredObject,
    TransportMonitoredObjectFromJSON,
    TransportMonitoredObjectFromJSONTyped,
    TransportMonitoredObjectToJSON,
    TransportPlace,
    TransportPlaceFromJSON,
    TransportPlaceFromJSONTyped,
    TransportPlaceToJSON,
    TransportState,
    TransportStateFromJSON,
    TransportStateFromJSONTyped,
    TransportStateToJSON,
    TransportTollCost,
    TransportTollCostFromJSON,
    TransportTollCostFromJSONTyped,
    TransportTollCostToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transport
 */
export interface Transport {
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Transport
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    externalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Transport
     */
    firstRta?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Transport
     */
    lastRta?: Date;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    routeType?: string;
    /**
     * 
     * @type {Date}
     * @memberof Transport
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    lastUpdatedBy?: string;
    /**
     * 
     * @type {TransportState}
     * @memberof Transport
     */
    state?: TransportState;
    /**
     * 
     * @type {Array<TransportMonitoredObject>}
     * @memberof Transport
     */
    monitoredObjects?: Array<TransportMonitoredObject>;
    /**
     * 
     * @type {number}
     * @memberof Transport
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    client?: string;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    customerContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Transport
     */
    fixedName?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    number?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Transport
     */
    fuelTypes?: Array<string>;
    /**
     * 
     * @type {Array<TransportPlace>}
     * @memberof Transport
     */
    places?: Array<TransportPlace>;
    /**
     * Deprecated in favor of metadata[\'telematics_vehicle_profile_id\']
     * @type {number}
     * @memberof Transport
     */
    desiredVehicleProfile?: number;
    /**
     * 
     * @type {TransportCostPerKm}
     * @memberof Transport
     */
    costPerKm?: TransportCostPerKm;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    eventEvaluationMethod?: string;
    /**
     * 
     * @type {object}
     * @memberof Transport
     */
    metadata?: object;
    /**
     * 
     * @type {TransportTollCost}
     * @memberof Transport
     */
    tollCost?: TransportTollCost;
    /**
     * 
     * @type {boolean}
     * @memberof Transport
     */
    omitRouteParams?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transport
     */
    puesc?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Transport
     */
    plannedPUESCBorderCrossingsEntry?: number;
    /**
     * 
     * @type {number}
     * @memberof Transport
     */
    plannedPUESCBorderCrossingsExit?: number;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    pUESCTypeOfNotification?: string;
    /**
     * 
     * @type {{ [key: string]: MuDataProcessingModelsActivityInterval; }}
     * @memberof Transport
     */
    lastActivityInterval?: { [key: string]: MuDataProcessingModelsActivityInterval; };
    /**
     * 
     * @type {Array<number>}
     * @memberof Transport
     */
    driverIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Transport
     */
    trailerIds?: Array<number>;
    /**
     * 
     * @type {TransportMonitoredObject}
     * @memberof Transport
     */
    currentPrimaryAssignedMonitoredObject?: TransportMonitoredObject;
    /**
     * 
     * @type {TransportMonitoredObject}
     * @memberof Transport
     */
    currentNonprimaryAssignedMonitoredObject?: TransportMonitoredObject;
    /**
     * 
     * @type {MuDataProcessingModelsDriverInfoDriver}
     * @memberof Transport
     */
    lastDriver?: MuDataProcessingModelsDriverInfoDriver;
    /**
     * 
     * @type {TransportMonitoredObject}
     * @memberof Transport
     */
    lastTrailer?: TransportMonitoredObject;
    /**
     * 
     * @type {Array<TransportDriver>}
     * @memberof Transport
     */
    users?: Array<TransportDriver>;
    /**
     * 
     * @type {string}
     * @memberof Transport
     */
    transportTemplateId?: string;
    /**
     * 
     * @type {RouteOptionsSygic}
     * @memberof Transport
     */
    routeOptionsSygic?: RouteOptionsSygic;
    /**
     * 
     * @type {Date}
     * @memberof Transport
     */
    finishedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Transport
     */
    entriesLastUpdated?: Date;
}

export function TransportFromJSON(json: any): Transport {
    return TransportFromJSONTyped(json, false);
}

export function TransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'firstRta': !exists(json, 'firstRta') ? undefined : (new Date(json['firstRta'])),
        'lastRta': !exists(json, 'lastRta') ? undefined : (new Date(json['lastRta'])),
        'routeType': !exists(json, 'routeType') ? undefined : json['routeType'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
        'lastUpdatedBy': !exists(json, 'lastUpdatedBy') ? undefined : json['lastUpdatedBy'],
        'state': !exists(json, 'state') ? undefined : TransportStateFromJSON(json['state']),
        'monitoredObjects': !exists(json, 'monitoredObjects') ? undefined : ((json['monitoredObjects'] as Array<any>).map(TransportMonitoredObjectFromJSON)),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'customerContactId': !exists(json, 'customer_contact_id') ? undefined : json['customer_contact_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fixedName': !exists(json, 'fixed_name') ? undefined : json['fixed_name'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'fuelTypes': !exists(json, 'fuelTypes') ? undefined : json['fuelTypes'],
        'places': !exists(json, 'places') ? undefined : ((json['places'] as Array<any>).map(TransportPlaceFromJSON)),
        'desiredVehicleProfile': !exists(json, 'desiredVehicleProfile') ? undefined : json['desiredVehicleProfile'],
        'costPerKm': !exists(json, 'costPerKm') ? undefined : TransportCostPerKmFromJSON(json['costPerKm']),
        'eventEvaluationMethod': !exists(json, 'eventEvaluationMethod') ? undefined : json['eventEvaluationMethod'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'tollCost': !exists(json, 'tollCost') ? undefined : TransportTollCostFromJSON(json['tollCost']),
        'omitRouteParams': !exists(json, 'omitRouteParams') ? undefined : json['omitRouteParams'],
        'puesc': !exists(json, 'puesc') ? undefined : json['puesc'],
        'plannedPUESCBorderCrossingsEntry': !exists(json, 'plannedPUESCBorderCrossingsEntry') ? undefined : json['plannedPUESCBorderCrossingsEntry'],
        'plannedPUESCBorderCrossingsExit': !exists(json, 'plannedPUESCBorderCrossingsExit') ? undefined : json['plannedPUESCBorderCrossingsExit'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'pUESCTypeOfNotification': !exists(json, 'PUESCTypeOfNotification') ? undefined : json['PUESCTypeOfNotification'],
        'lastActivityInterval': !exists(json, 'last_activity_interval') ? undefined : (mapValues(json['last_activity_interval'], MuDataProcessingModelsActivityIntervalFromJSON)),
        'driverIds': !exists(json, 'driver_ids') ? undefined : json['driver_ids'],
        'trailerIds': !exists(json, 'trailer_ids') ? undefined : json['trailer_ids'],
        'currentPrimaryAssignedMonitoredObject': !exists(json, 'current_primary_assigned_monitored_object') ? undefined : TransportMonitoredObjectFromJSON(json['current_primary_assigned_monitored_object']),
        'currentNonprimaryAssignedMonitoredObject': !exists(json, 'current_nonprimary_assigned_monitored_object') ? undefined : TransportMonitoredObjectFromJSON(json['current_nonprimary_assigned_monitored_object']),
        'lastDriver': !exists(json, 'last_driver') ? undefined : MuDataProcessingModelsDriverInfoDriverFromJSON(json['last_driver']),
        'lastTrailer': !exists(json, 'last_trailer') ? undefined : TransportMonitoredObjectFromJSON(json['last_trailer']),
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(TransportDriverFromJSON)),
        'transportTemplateId': !exists(json, 'transport_template_id') ? undefined : json['transport_template_id'],
        'routeOptionsSygic': !exists(json, 'route_options_sygic') ? undefined : RouteOptionsSygicFromJSON(json['route_options_sygic']),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (new Date(json['finished_at'])),
        'entriesLastUpdated': !exists(json, 'entries_last_updated') ? undefined : (new Date(json['entries_last_updated'])),
    };
}

export function TransportToJSON(value?: Transport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'external_id': value.externalId,
        'firstRta': value.firstRta === undefined ? undefined : (value.firstRta.toISOString()),
        'lastRta': value.lastRta === undefined ? undefined : (value.lastRta.toISOString()),
        'routeType': value.routeType,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'lastUpdatedBy': value.lastUpdatedBy,
        'state': TransportStateToJSON(value.state),
        'monitoredObjects': value.monitoredObjects === undefined ? undefined : ((value.monitoredObjects as Array<any>).map(TransportMonitoredObjectToJSON)),
        'clientId': value.clientId,
        'client': value.client,
        'customer_contact_id': value.customerContactId,
        'name': value.name,
        'fixed_name': value.fixedName,
        'number': value.number,
        'fuelTypes': value.fuelTypes,
        'places': value.places === undefined ? undefined : ((value.places as Array<any>).map(TransportPlaceToJSON)),
        'desiredVehicleProfile': value.desiredVehicleProfile,
        'costPerKm': TransportCostPerKmToJSON(value.costPerKm),
        'eventEvaluationMethod': value.eventEvaluationMethod,
        'metadata': value.metadata,
        'tollCost': TransportTollCostToJSON(value.tollCost),
        'omitRouteParams': value.omitRouteParams,
        'puesc': value.puesc,
        'plannedPUESCBorderCrossingsEntry': value.plannedPUESCBorderCrossingsEntry,
        'plannedPUESCBorderCrossingsExit': value.plannedPUESCBorderCrossingsExit,
        'createdBy': value.createdBy,
        'PUESCTypeOfNotification': value.pUESCTypeOfNotification,
        'last_activity_interval': value.lastActivityInterval === undefined ? undefined : (mapValues(value.lastActivityInterval, MuDataProcessingModelsActivityIntervalToJSON)),
        'driver_ids': value.driverIds,
        'trailer_ids': value.trailerIds,
        'current_primary_assigned_monitored_object': TransportMonitoredObjectToJSON(value.currentPrimaryAssignedMonitoredObject),
        'current_nonprimary_assigned_monitored_object': TransportMonitoredObjectToJSON(value.currentNonprimaryAssignedMonitoredObject),
        'last_driver': MuDataProcessingModelsDriverInfoDriverToJSON(value.lastDriver),
        'last_trailer': TransportMonitoredObjectToJSON(value.lastTrailer),
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(TransportDriverToJSON)),
        'transport_template_id': value.transportTemplateId,
        'route_options_sygic': RouteOptionsSygicToJSON(value.routeOptionsSygic),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'entries_last_updated': value.entriesLastUpdated === undefined ? undefined : (value.entriesLastUpdated.toISOString()),
    };
}


