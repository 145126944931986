import qs from 'qs';
import i18n from 'i18next';
import moment from 'moment';
import { message } from 'antd';
import { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { DragDropContext, Draggable, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import { WithTranslation, withTranslation } from 'react-i18next';

import { confDefault } from 'conf';
import { DATE_FORMAT } from 'domain-constants';
import { TransportState } from 'generated/graphql';
import { HelperModal, Modal } from 'common/components';
import { TransportModel } from 'common/model/transports';
import { RouteNames, WithLogic, withLogicContext } from 'App';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { exponea } from 'logic/exponea';

import { DispatcherBoardRow, TransportWithUIProperties } from './dispatcher-board-logic';
import BoardHeader from './components/BoardHeader/BoardHeader';
import BoardOptions from './components/BoardOptions';
import BoardLayout from './components/BoardLayout';
import BoardNavigation from './components/BoardNavigation';
import BoardDateSelect from './components/BoardDateSelect';
import DFFTransportBlockBackup from './components/DFFTransportBlockBackup';
import DFFTransportBlock from './components/DFFTransportBlock';
import TelematicsTransportBlock from './components/TelematicsTransportBlock';
import DFFProposalDetail from './components/DFFProposalDetail/DFFProposalDetail';

interface RouteParams {
    startDate?: string;
}

interface Props extends WithLogic, WithTranslation, RouteComponentProps {}

interface State {
    etaDetail: boolean;
    helper: string | null;
    showNewTransports: boolean;
    initialScrollDone: boolean;
    selectedDetailTransport: string | null;
}

const INITIAL_STATE: State = {
    etaDetail: false,
    helper: null,
    showNewTransports: false,
    initialScrollDone: false,
    selectedDetailTransport: null
};

class DispatcherBoardModule extends Component<Props, State> {
    private readonly vehicleDroppablePrefix = 'vehicle-';
    private readonly freeTransportsDroppableID = 'free_transports';

    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        (window as any).app.DispatcherBoardModule = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        // TODO: Add settings
        const initialDate = params.startDate ? moment(params.startDate, DATE_FORMAT).toISOString() : undefined;
        this.props.logic.dispatcherBoardLogic().init(initialDate);
    }

    componentDidUpdate() {
        if (
            !this.state.initialScrollDone &&
            !this.props.logic.dispatcherBoardLogic().dffInitialShowCaseDone &&
            !this.props.logic.dispatcherBoardLogic().loading &&
            this.props.logic.dispatcherBoardLogic().data.length > 0
        ) {
            const firstRowWithProposal = this.props.logic
                .dispatcherBoardLogic()
                .data.find(v => v.transports.some(row => row.some(t => t.type === 'dff')));

            if (firstRowWithProposal) {
                document.getElementById(this._getRowId(firstRowWithProposal))?.scrollIntoView({ behavior: 'smooth' });
                this.setState({ initialScrollDone: true });
            }
        }
    }

    componentWillUnmount() {
        this.props.logic.dispatcherBoardLogic().destroy();
        this.props.logic.dffProposals().destroy();
    }

    render() {
        const selectedTransport = this._selectDetailTransports().find(t => t.id === this.state.selectedDetailTransport);

        return (
            <div className="module">
                <div className="dispatcher-board-module page">
                    {this.state.selectedDetailTransport && selectedTransport && (
                        <DFFProposalDetail
                            visible={!!this.state.selectedDetailTransport}
                            onClose={this._handleDetailClose}
                            proposal={selectedTransport}
                            onAccept={this._handleAcceptProposal}
                            onDecline={this._handleDeclineProposal}
                            onRemove={this._handleRejectProposal}
                            onSendToShipperClick={this._handleProposePrice}
                        />
                    )}

                    <BoardHeader
                        right={
                            <BoardOptions
                                roles={this.props.logic.auth().roles(true) ?? []}
                                etaDetail={this.state.etaDetail}
                                dffRatings={this.props.logic.dispatcherBoardLogic().dffRatings}
                                dffManualRefresh={this.props.logic.dispatcherBoardLogic().manualRefresh}
                                showDFFProposals={this.props.logic.dispatcherBoardLogic().showDffProposals}
                                showFreeTransports={this.state.showNewTransports}
                                onEtaDetailToggle={this._handleEtaDetailToggle}
                                onDFFRatingsChange={this._handleDFFRatingsChange}
                                onManualRefreshToggle={this._handleManualRefreshToggle}
                                onShowDFFProposalsToggle={this._handleShowDFFProposalsToggle}
                                onHelpToggle={this._handleToggleHelp}
                                onShowFreeTransportsToggle={this._handleFreeTransportsToggle}
                            />
                        }
                        newProposals={this.props.logic.dispatcherBoardLogic().newDFFProposals}
                        onRefreshBoard={this._handleRefreshBoard}
                    />
                    <DragDropContext onDragEnd={this._handleDragEnd} onDragStart={this._handleDragStart}>
                        <BoardLayout
                            rows={this.props.logic.dispatcherBoardLogic().data}
                            config={this.props.logic.dispatcherBoardLogic().config}
                            loading={this.props.logic.dispatcherBoardLogic().loading}
                            selectedDay={this.props.logic.dispatcherBoardLogic().selectedDate}
                            showFreeTransports={this.state.showNewTransports}
                            freeTransports={this.props.logic.dispatcherBoardLogic().freeTransports}
                            header={
                                <BoardNavigation
                                    config={this.props.logic.dispatcherBoardLogic().config}
                                    selectedDay={this.props.logic.dispatcherBoardLogic().selectedDate}
                                />
                            }
                            left={
                                <BoardDateSelect
                                    value={this.props.logic.dispatcherBoardLogic().selectedDate}
                                    config={this.props.logic.dispatcherBoardLogic().config}
                                    onDateChange={this._handleDayChange}
                                />
                            }
                            rowRender={(children, row) => (
                                <Droppable key={this._getRowId(row)} droppableId={this._getRowId(row)}>
                                    {provided =>
                                        children(provided, {
                                            ...provided.droppableProps,
                                            id: this._getRowId(row)
                                        })
                                    }
                                </Droppable>
                            )}
                            freeTransportsRender={children => (
                                <Droppable droppableId={this.freeTransportsDroppableID}>
                                    {provided => children(provided, provided.droppableProps)}
                                </Droppable>
                            )}
                            transportRender={(transport, index) =>
                                transport.type === 'dff' ? (
                                    this.props.logic.dispatcherBoardLogic().legacyStyle ? (
                                        <DFFTransportBlockBackup
                                            lang={this.props.logic.auth().user().lang}
                                            client={this.props.logic.auth().client()}
                                            transport={transport.data}
                                            properties={transport.properties}
                                            demoMode={this.props.logic.demo().isActive}
                                            onConvertToTransport={this._handleConvertProposalToTransport}
                                            onViewOnTransEu={this._handleViewProposalOnTransportEU}
                                            onReject={this._handleRejectProposal}
                                        />
                                    ) : (
                                        <DFFTransportBlock
                                            lang={this.props.logic.auth().user().lang}
                                            client={this.props.logic.auth().client()}
                                            transport={transport.data}
                                            properties={transport.properties}
                                            demoMode={this.props.logic.demo().isActive}
                                            onDetailClick={this._handleDetailClick}
                                        />
                                    )
                                ) : (
                                    <Draggable
                                        key={transport.data.id}
                                        isDragDisabled={!this._getTransportDraggable(transport)}
                                        draggableId={String(transport.data.id)}
                                        index={index}
                                    >
                                        {provided => (
                                            <TelematicsTransportBlock
                                                lang={this.props.logic.auth().user().lang}
                                                roles={this.props.logic.auth().roles(true) ?? []}
                                                client={this.props.logic.auth().client()}
                                                properties={transport.properties}
                                                transport={transport.data}
                                                routes={this._getTransportMapRoute(transport.data.id)}
                                                expanded={this.state.etaDetail}
                                                vehicleState={this._getVehicleStateObject(transport)}
                                                onTransportMapAfterInit={this._handleTransportMapAfterInit}
                                                onClick={this._handleTransportClick}
                                                innerRef={provided.innerRef}
                                                draggableProps={provided.draggableProps}
                                                dragHandleProps={provided.dragHandleProps}
                                            />
                                        )}
                                    </Draggable>
                                )
                            }
                            onCreateTransport={this._handleCreateTransport}
                        />
                    </DragDropContext>
                    <HelperModal
                        name="dispatcher-board"
                        content={this.state.helper ?? ''}
                        visible={!!this.state.helper}
                        onClose={this._handleHelperClose}
                    />
                </div>
            </div>
        );
    }

    private _selectDetailTransports = () => {
        if (!this.state.selectedDetailTransport) {
            return [];
        }
        const row = this.props.logic
            .dispatcherBoardLogic()
            .data.find(v => v.transports.some(r => r.some(t => t.data.id === this.state.selectedDetailTransport)));

        const data =
            row?.transports
                .flat()
                .filter(t => t.type === 'dff')
                .map(v => v.data) ?? [];

        return data;
    };

    private _handleDetailClick = (transportId: string) => {
        this.setState({ selectedDetailTransport: transportId });
    };

    private _handleDetailClose = () => {
        this.setState({ selectedDetailTransport: null });
    };

    private _getTransportMapRoute = (transportId?: string) => {
        return transportId ? this.props.logic.dispatcherBoardLogic().transportPopoverRoute[transportId] : undefined;
    };

    private _handleTransportMapAfterInit = (transportId: string) => {
        this.props.logic.dispatcherBoardLogic().getRouteDebounce(transportId);
    };

    private _getVehicleStateObject = (transport: TransportWithUIProperties) => {
        return this.props.logic
            .dispatcherBoardLogic()
            .data.find(d => d.vehicle.id?.toString() === transport.data.vehicle)?.state;
    };

    private _getTransportDraggable = (transport: TransportWithUIProperties) => {
        if (transport.type === 'dff') return false;
        return [TransportState.New, TransportState.Accepted, TransportState.Planned].includes(transport.data.state);
    };

    private _getRowId = (row: DispatcherBoardRow) => {
        return `${this.vehicleDroppablePrefix}${row.vehicle.id}`;
    };

    private _handleCreateTransport = (row: DispatcherBoardRow) => {
        this.props.history.push({
            pathname: RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                vehicleId: row.vehicle.id,
                startDate: this.props.logic.dispatcherBoardLogic().selectedDate,
                dispatcherBoard: true
            })
        });
    };

    private _handleDragStart = (drag: DragStart) => {
        // We need to be sure that live transport update won't update dragged transport
        this.props.logic.dispatcherBoardLogic().markTransportDragged(drag.draggableId);
    };

    private _handleDragEnd = (result: DropResult) => {
        // Remove vehicle from transport
        if (
            result.destination?.droppableId === this.freeTransportsDroppableID &&
            result.source.droppableId.startsWith(this.vehicleDroppablePrefix)
        ) {
            const transportId = result.draggableId;
            this.props.logic
                .dispatcherBoardLogic()
                .removeVehicleFromTransport(transportId)
                .catch(e => {
                    console.error(e);
                    message.error(this.props.t('DispatcherBoard.dragError'));
                });
        }

        // Add transport from one to another vehicle
        if (
            result.destination?.droppableId.startsWith(this.vehicleDroppablePrefix) &&
            result.source.droppableId.startsWith(this.vehicleDroppablePrefix)
        ) {
            const transportId = result.draggableId;
            const destinationVehicleId = result.destination.droppableId.replace(this.vehicleDroppablePrefix, '');
            this.props.logic
                .dispatcherBoardLogic()
                .moveTransportToOtherVehicle(transportId, destinationVehicleId)
                .catch(e => {
                    console.error(e);
                    message.error(this.props.t('DispatcherBoard.dragError'));
                });
        }

        // Add transport from free to another vehicle
        if (
            result.source.droppableId === this.freeTransportsDroppableID &&
            result.destination?.droppableId.startsWith(this.vehicleDroppablePrefix)
        ) {
            const transportId = result.draggableId;
            const destinationVehicleId = result.destination.droppableId.replace(this.vehicleDroppablePrefix, '');
            this.props.logic
                .dispatcherBoardLogic()
                .moveTransportToOtherVehicle(transportId, destinationVehicleId)
                .catch(e => {
                    console.error(e);
                    message.error(this.props.t('DispatcherBoard.dragError'));
                });
        }

        // D&D will rerender itself after drop item.
        // Changing transports async will cause dropped item to get back on same spot.
        // In order to let transport on new spot, we have to change current state
        this.props.logic
            .dispatcherBoardLogic()
            .reorderTransports(
                result.draggableId,
                result.destination
                    ? Number(result.destination.droppableId.replace(this.vehicleDroppablePrefix, ''))
                    : undefined
            );

        this.props.logic.dispatcherBoardLogic().clearDraggedTransport();
    };

    private _handleDayChange = (date: string) => {
        this.setState({ showNewTransports: false }, () => {
            this.props.logic.dispatcherBoardLogic().changeDate(date);
        });
    };

    private _handleEtaDetailToggle = () => {
        this.setState(state => ({ etaDetail: !state.etaDetail }));
    };

    private _handleDFFRatingsChange = (filter: number[]) => {
        this.props.logic.dispatcherBoardLogic().changeDffFilter(filter);
    };

    private _handleManualRefreshToggle = () => {
        this.props.logic.dispatcherBoardLogic().toggleManualRefresh();
    };

    private _handleRefreshBoard = () => {
        this.props.logic.dispatcherBoardLogic().refreshBoard();
    };

    private _handleShowDFFProposalsToggle = () => {
        this.props.logic.dispatcherBoardLogic().toggleShowProposals();
    };

    private _handleConvertProposalToTransport = (id: string) => {
        Modal.confirm({
            title: this.props.t('DispatcherBoard.convertToTransportConfirm'),
            okText: this.props.t('DFFTransportBlock.convertToTransport'),
            onOk: () => {
                this.setState({ selectedDetailTransport: null }, () => {
                    this.props.logic.dispatcherBoardLogic().convertProposalToTransport(id);
                });
            }
        });
    };

    private _handleAcceptProposal = (id: string, useJitpay: boolean) => {
        this.setState({ selectedDetailTransport: null }, () => {
            this.props.logic.dispatcherBoardLogic().acceptProposal(id, useJitpay);
        });
    };

    private _handleDeclineProposal = (id: string) => {
        this.setState({ selectedDetailTransport: null }, () => {
            this.props.logic.dispatcherBoardLogic().declineProposal(id);
        });
    };

    private _handleRejectProposal = (id: string) => {
        this.setState({ selectedDetailTransport: null }, () => {
            this.props.logic.dispatcherBoardLogic().rejectProposal(id);
        });
    };

    private _handleProposePrice = (id: string, proposedPrice: number, useJitpay: boolean) => {
        this.props.logic.dispatcherBoardLogic().proposePrice(id, proposedPrice, useJitpay);
    };

    private _handleTransportClick = (transport: TransportModel) => {
        this.props.logic.exponea().trackEvent(exponea.module.schedulingDispatcherBoard, {
            status: exponea.status.actionTaken,
            action: exponea.action.goToTransportDetail
        });

        this.props.history.push({
            pathname: RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL,
            search: qs.stringify({
                editId: transport.id,
                vehicleId: transport.vehicle ? transport.vehicle : undefined,
                startDate: moment(this.props.logic.dispatcherBoardLogic().selectedDate)
                    .subtract(this.props.logic.dispatcherBoardLogic().config.activeDayIndex - 1, 'day')
                    .format(DATE_FORMAT)
            })
        });
    };

    private _handleHelperClose = () => {
        this.setState({
            helper: null
        });
    };

    private _handleFreeTransportsToggle = () => {
        this.setState(state => ({
            showNewTransports: !state.showNewTransports
        }));
    };

    private getTransEuLink = (id: string) => {
        return `https://platform.trans.eu/exchange/offers?e1=offer.details.drawer&e1offerId=${id}&e1offerTabId=%22route%22`;
    };

    private _handleViewProposalOnTransportEU = (id: string) => {
        window.open(this.getTransEuLink(id), '_blank');
    };

    private _handleToggleHelp = () => {
        const module: DocsUserGuide = 'routeplanning';
        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic?.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: content
                });
            });
        });
    };
}

export default withRouter(withTranslation()(withLogicContext(observer(DispatcherBoardModule))));
