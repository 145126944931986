import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { exclamation } from 'resources/images/driver-behavior';

export default function DriverBehaviorIconExclamation() {
    const { t } = useTranslation();

    return (
        <Tooltip title={t('DriverBehaviorDetail.exclamation')}>
            <img className="icon-exclamation" src={exclamation} alt="icn-exclamation" />
        </Tooltip>
    );
}
