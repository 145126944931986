import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal } from '..';
import qa from 'qa-selectors';
import { Col, Row } from 'antd';
import cn from 'classnames';

export enum MessageType {
    INFO = 'info.svg',
    ERROR = 'error.svg',
    WARNING = 'warning.svg'
}

interface Props extends WithTranslation {
    message?: ReactNode;
    messageAsHtmlString?: string;
    header?: string;
    type?: MessageType;
    confirmDisabled?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    cancelButton?: boolean;
    loading?: boolean;
    danger?: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;
}

function Confirm(props: Props) {
    return (
        <Modal
            footer={null}
            visible={true}
            closable={false}
            className={`confirm-modal ${cn({ 'confirm-modal-no-header': props.header === undefined })}`}
            centered={true}
        >
            <Row>
                <table>
                    <tr>
                        {props.type && (
                            <td className="icon">
                                <img
                                    className="confirm-modal-icon"
                                    src={`/img-svg/confirm-dialog/${props.type}`}
                                    alt="confirm-icon"
                                />
                            </td>
                        )}
                        <td>
                            {props.header && (
                                <Row>
                                    <div className="confirm-modal-title">{props.header}</div>
                                </Row>
                            )}
                            {props.message && <div className="confirm-modal-message">{props.message}</div>}
                            {props.messageAsHtmlString && (
                                <div
                                    className="confirm-modal-message"
                                    dangerouslySetInnerHTML={{ __html: props.messageAsHtmlString }}
                                />
                            )}
                        </td>
                    </tr>
                </table>
            </Row>
            <Row gutter={[20, 10]} justify="end" className="confirm-modal-form-buttons">
                {(props.cancelButton ?? true) && (
                    <Col>
                        <Button onClick={props.onCancel} qa={qa.common.modal.btnCancel}>
                            {props.cancelLabel ?? props.t('common.cancel')}
                        </Button>
                    </Col>
                )}
                <Col>
                    <Button
                        type="primary"
                        danger={props.danger}
                        loading={props.loading}
                        htmlType="submit"
                        disabled={props.confirmDisabled}
                        onClick={props.onConfirm}
                        qa={qa.common.modal.btnConfirm}
                    >
                        {props.confirmLabel ?? props.t('common.confirm')}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default withTranslation()(Confirm);
