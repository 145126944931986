/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InlineResponse20044,
    InlineResponse20044FromJSON,
    InlineResponse20044ToJSON,
    ReadOnlyUserRole,
    ReadOnlyUserRoleFromJSON,
    ReadOnlyUserRoleToJSON,
    WriteOnlyUserRole,
    WriteOnlyUserRoleFromJSON,
    WriteOnlyUserRoleToJSON,
} from '../models';

export interface UserRoleCreateRequest {
    data: WriteOnlyUserRole;
}

export interface UserRoleDeleteRequest {
    id: string;
}

export interface UserRoleListRequest {
    limit?: number;
    offset?: number;
}

export interface UserRolePartialUpdateRequest {
    id: string;
    data: WriteOnlyUserRole;
}

export interface UserRoleReadRequest {
    id: string;
}

export interface UserRoleUpdateRequest {
    id: string;
    data: WriteOnlyUserRole;
}

/**
 * no description
 */
export class UserRoleApi extends runtime.BaseAPI {

    /**
     */
    async userRoleCreateRaw(requestParameters: UserRoleCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRole>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRoleCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRoleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRoleFromJSON(jsonValue));
    }

    /**
     */
    async userRoleCreate(requestParameters: UserRoleCreateRequest): Promise<ReadOnlyUserRole> {
        const response = await this.userRoleCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRoleDeleteRaw(requestParameters: UserRoleDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRoleDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userRoleDelete(requestParameters: UserRoleDeleteRequest): Promise<void> {
        await this.userRoleDeleteRaw(requestParameters);
    }

    /**
     */
    async userRoleListRaw(requestParameters: UserRoleListRequest): Promise<runtime.ApiResponse<InlineResponse20044>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20044FromJSON(jsonValue));
    }

    /**
     */
    async userRoleList(requestParameters: UserRoleListRequest): Promise<InlineResponse20044> {
        const response = await this.userRoleListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRolePartialUpdateRaw(requestParameters: UserRolePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRolePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRolePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRoleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRoleFromJSON(jsonValue));
    }

    /**
     */
    async userRolePartialUpdate(requestParameters: UserRolePartialUpdateRequest): Promise<ReadOnlyUserRole> {
        const response = await this.userRolePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRoleReadRaw(requestParameters: UserRoleReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRoleRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRoleFromJSON(jsonValue));
    }

    /**
     */
    async userRoleRead(requestParameters: UserRoleReadRequest): Promise<ReadOnlyUserRole> {
        const response = await this.userRoleReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRoleUpdateRaw(requestParameters: UserRoleUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRoleUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRoleUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-role/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserRoleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserRoleFromJSON(jsonValue));
    }

    /**
     */
    async userRoleUpdate(requestParameters: UserRoleUpdateRequest): Promise<ReadOnlyUserRole> {
        const response = await this.userRoleUpdateRaw(requestParameters);
        return await response.value();
    }

}
