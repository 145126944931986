import { PerfectDriveDriverDetailModel } from 'common/model/statistics';
import { useTranslation } from 'react-i18next';
import DriverBehaviorCoachTable, { DriverBehaviorCoachTableColors, DriverBehaviorCoachTableModel } from '.';
import { DriverBehaviorCoachDataMap } from '../DriverBehaviorCoach';
import {
    formatDriverBehaviorName,
    getPerfectDriveExclamation,
    getPerfectDriveValue
} from 'logic/driver-behavior-coach/driver-behavior-trucks';

interface Props {
    data?: PerfectDriveDriverDetailModel;
    comparedData?: PerfectDriveDriverDetailModel;
    dataMap: DriverBehaviorCoachDataMap[];
    selectedKey?: string;
    qa?: string;
}

export default function DriverBehaviorCoachDataTable(props: Props) {
    const { t } = useTranslation();

    const getDriverData = (data: PerfectDriveDriverDetailModel): DriverBehaviorCoachTableModel => {
        const detail = data.scores[0].detail;
        return {
            name: formatDriverBehaviorName(data.name, data.surname, data.tachocard),
            values: {
                highway: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.highway)
                        .map(dm => [
                            dm.key,
                            getPerfectDriveValue(dm.values?.highway, detail, 0, dm.diminisher, dm.maxValue)
                        ])
                ),
                urban: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.urban)
                        .map(dm => [
                            dm.key,
                            getPerfectDriveValue(dm.values?.urban, detail, 0, dm.diminisher, dm.maxValue)
                        ])
                ),
                country: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.country)
                        .map(dm => [
                            dm.key,
                            getPerfectDriveValue(dm.values?.country, detail, 0, dm.diminisher, dm.maxValue)
                        ])
                ),
                other: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.other)
                        .map(dm => [
                            dm.key,
                            getPerfectDriveValue(dm.values?.other, detail, 0, dm.diminisher, dm.maxValue)
                        ])
                ),
                summary: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.summary)
                        .map(dm => [
                            dm.key,
                            getPerfectDriveValue(dm.values?.summary, detail, 0, dm.diminisher, dm.maxValue)
                        ])
                ),
                exclamation: Object.fromEntries(
                    props.dataMap
                        .filter(dm => dm.values?.exclamation)
                        .map(dm => [dm.key, getPerfectDriveExclamation(dm.values?.exclamation, detail)])
                )
            }
        } as DriverBehaviorCoachTableModel;
    };

    const getData = (): any[] => {
        let driverData = [];

        if (props.data) {
            driverData.push(getDriverData(props.data));
        }

        if (props.comparedData) {
            driverData.push(getDriverData(props.comparedData));
        }

        return driverData;
    };

    return (
        <DriverBehaviorCoachTable
            title={t('DriverBehaviorDetail.driveData.title')}
            dataMap={props.dataMap}
            selectedKey={props.selectedKey}
            color={DriverBehaviorCoachTableColors.drive}
            collapsedRows={5}
            driversList={getData()}
        />
    );
}
