import { TrendChange } from 'common/components/Trend';
import { Theme } from 'common/components/Settings';

export const ArrowDot = (props: any) => {
    const {
        height,
        fontSize,
        cx,
        cy,
        value,
        payload,
        fill,
        stroke,
        showPoint,
        pointRadius,
        showLinePoint,
        trendChangeForIncrease,
        theme
    } = props;

    const point =
        value !== undefined &&
        showPoint &&
        ((!showLinePoint && !payload.prevValue && !payload.nextValue) || showLinePoint) ? (
            <circle
                r={pointRadius ?? 1}
                fontSize={fontSize}
                strokeWidth={(pointRadius ?? 1) * 2}
                stroke={stroke}
                fill={fill}
                width="510"
                height="230"
                cx={cx}
                cy={cy}
                className="recharts-dot recharts-line-dot"
            />
        ) : (
            <></>
        );

    const neutralColor = theme === Theme.Dark ? '#FFFFFF' : '#000000';

    if (payload.trendVal === 'up') {
        let color = '#1BDA7E';
        switch (trendChangeForIncrease) {
            case TrendChange.NEGATIVE:
                color = '#FF4E59';
                break;
            case TrendChange.NEUTRAL:
                color = neutralColor;
                break;
        }

        return (
            <>
                {point}
                <svg
                    x={cx - 9}
                    y={cy + (height ? (cy + 32 > height ? -52 : 22) : 22)}
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.952906 9.64853C0.727941 9.4235 0.601563 9.11833 0.601562 8.80013C0.601562 8.48194 0.727941 8.17677 0.952906 7.95173L8.15291 0.751734C8.37794 0.526769 8.68311 0.400391 9.00131 0.400391C9.3195 0.400391 9.62467 0.526769 9.84971 0.751734L17.0497 7.95173C17.2683 8.17806 17.3892 8.48118 17.3865 8.79581C17.3838 9.11045 17.2576 9.41143 17.0351 9.63392C16.8126 9.85641 16.5116 9.98261 16.197 9.98534C15.8823 9.98808 15.5792 9.86712 15.3529 9.64853L10.2013 4.49693V18.4001C10.2013 18.7184 10.0749 19.0236 9.84983 19.2487C9.62479 19.4737 9.31957 19.6001 9.00131 19.6001C8.68305 19.6001 8.37782 19.4737 8.15278 19.2487C7.92773 19.0236 7.80131 18.7184 7.80131 18.4001V4.49693L2.64971 9.64853C2.42467 9.8735 2.1195 9.99988 1.80131 9.99988C1.48311 9.99988 1.17794 9.8735 0.952906 9.64853Z"
                        fill={color}
                    />
                </svg>
            </>
        );
    } else if (payload.trendVal === 'down') {
        let color = '#FF4E59';
        switch (trendChangeForIncrease) {
            case TrendChange.NEGATIVE:
                color = '#1BDA7E';
                break;
            case TrendChange.NEUTRAL:
                color = neutralColor;
                break;
        }

        return (
            <>
                {point}
                <svg
                    x={cx - 10}
                    y={cy + (height ? (cy + 32 > height ? -52 : 22) : 22)}
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0491 10.352C17.274 10.577 17.4004 10.8822 17.4004 11.2004C17.4004 11.5186 17.274 11.8238 17.0491 12.0488L9.84908 19.2488C9.62405 19.4738 9.31888 19.6001 9.00068 19.6001C8.68249 19.6001 8.37732 19.4738 8.15228 19.2488L0.952283 12.0488C0.837671 11.9381 0.746253 11.8057 0.683362 11.6593C0.620471 11.5129 0.587367 11.3554 0.585983 11.1961C0.584598 11.0367 0.61496 10.8787 0.675297 10.7312C0.735634 10.5838 0.824738 10.4498 0.937409 10.3371C1.05008 10.2244 1.18406 10.1353 1.33154 10.075C1.47901 10.0147 1.63703 9.9843 1.79637 9.98569C1.9557 9.98707 2.11316 10.0202 2.25957 10.0831C2.40597 10.146 2.53839 10.2374 2.64908 10.352L7.80068 15.5036V1.60039C7.80068 1.28213 7.92711 0.976906 8.15216 0.751862C8.3772 0.526819 8.68242 0.400391 9.00068 0.400391C9.31894 0.400391 9.62417 0.526819 9.84921 0.751862C10.0743 0.976906 10.2007 1.28213 10.2007 1.60039V15.5036L15.3523 10.352C15.5773 10.127 15.8825 10.0006 16.2007 10.0006C16.5189 10.0006 16.8241 10.127 17.0491 10.352Z"
                        fill={color}
                    />
                </svg>
            </>
        );
    } else {
        return point;
    }
};
