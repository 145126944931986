import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import moment from 'moment';

import { DATE_TIME_FORMAT } from 'domain-constants';
import { DatePicker, Switch } from 'common/components';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import qa from 'qa-selectors';
import { DateRange } from 'common/model/date-time';
import { Select } from 'common/components/Form';
import { ColdchainDetailModel } from '../../coldchain-logic';
import { ColdchainChartFilterParams } from '../../ColdchainModule';

interface Props extends WithTranslation {
    demoMode: boolean;
    detailData?: ColdchainDetailModel;
    zones: number[];
    filterParams: ColdchainChartFilterParams;
    map: { open: boolean };
    onFilterChange: (coldchainChartFilter: ColdchainChartFilterParams) => void;
    onMapSwitchChange?: (checked: boolean) => void;
    onExportClick?: () => void;
}

function ColdchainChartFilter(props: Props) {
    const { t } = props;

    function onDateRangeChange(dateRange: DateRange) {
        props.onFilterChange?.({
            dateRange: {
                start: moment.utc(dateRange.start, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT),
                end: moment.utc(dateRange.end, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT)
            },
            sensorZone: props.filterParams.sensorZone,
            serialNumbers: props.filterParams.serialNumbers,
            dangerPoints: props.filterParams.dangerPoints
        });
    }

    function onZoneChange(sensorZone: number) {
        props.onFilterChange?.({
            dateRange: props.filterParams.dateRange,
            dangerPoints: props.filterParams.dangerPoints,
            sensorZone
        });
    }

    return (
        <Row gutter={16}>
            <Col span={18}>
                <Row gutter={16}>
                    <Col>
                        {props.demoMode ? (
                            <ButtonDateRangeTimeTrigger
                                withTime
                                dateRange={props.filterParams.dateRange}
                                format={'l LT'}
                                size="large"
                                qa={qa.coldChain.detail.graph.btnDateRange}
                            />
                        ) : (
                            <DatePicker.DateTimeRangePicker
                                defaultValue={[
                                    moment.utc(props.filterParams.dateRange.start, DATE_TIME_FORMAT),
                                    moment.utc(props.filterParams.dateRange.end, DATE_TIME_FORMAT)
                                ]}
                                showTime
                                onShortcutConfirm
                                onChange={dateRange =>
                                    onDateRangeChange({
                                        start: dateRange?.[0]?.format(DATE_TIME_FORMAT) ?? '',
                                        end: dateRange?.[1]?.format(DATE_TIME_FORMAT) ?? ''
                                    })
                                }
                                disabled={props.demoMode}
                                trigger={
                                    <ButtonDateRangeTimeTrigger
                                        withTime
                                        dateRange={props.filterParams.dateRange}
                                        format={'l LT'}
                                        size="large"
                                        qa={qa.coldChain.detail.graph.btnDateRange}
                                    />
                                }
                            />
                        )}
                    </Col>
                    {props.zones.length > 1 && (
                        <Col flex="150px">
                            <Select
                                showSearch={false}
                                placeholder={t('Coldchain.selectZone')}
                                defaultValue={props.detailData?.sensorZone}
                                size="large"
                                onChange={onZoneChange}
                            >
                                {props.zones.map(zone => (
                                    <Select.Option key={zone} value={zone}>
                                        {t('Coldchain.zone')} {zone}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                </Row>
            </Col>
            <Col span={6}>
                <Row justify="end" align="middle" gutter={12}>
                    <Col>
                        <Switch size="small" checked={props.map.open} onChange={props.onMapSwitchChange} />
                    </Col>
                    <Col className="coldchain-chart-filter-switch-label">{t('common.showOnMap')}</Col>
                </Row>
            </Col>
        </Row>
    );
}

export default withTranslation()(ColdchainChartFilter);
