/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserBLEConfig
 */
export interface UserBLEConfig {
    /**
     * 
     * @type {string}
     * @memberof UserBLEConfig
     */
    deviceMID: string;
    /**
     * 
     * @type {string}
     * @memberof UserBLEConfig
     */
    groupKEY: string;
    /**
     * 
     * @type {string}
     * @memberof UserBLEConfig
     */
    deviceMComKEY: string;
    /**
     * 
     * @type {string}
     * @memberof UserBLEConfig
     */
    fingerprint?: string;
}

export function UserBLEConfigFromJSON(json: any): UserBLEConfig {
    return UserBLEConfigFromJSONTyped(json, false);
}

export function UserBLEConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBLEConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceMID': json['deviceMID'],
        'groupKEY': json['groupKEY'],
        'deviceMComKEY': json['deviceMComKEY'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
    };
}

export function UserBLEConfigToJSON(value?: UserBLEConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceMID': value.deviceMID,
        'groupKEY': value.groupKEY,
        'deviceMComKEY': value.deviceMComKEY,
        'fingerprint': value.fingerprint,
    };
}


