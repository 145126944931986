import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ColdchainCheck, ColdchainTrash, ColdchainWarning, PlusCircle } from 'resources/images/planner';
import cn from 'classnames';
import { deg } from 'domain-constants';
import { ColdchainProfileData } from 'common/forms/ColdchainProfileForm';
import { Button } from 'common/components';

interface Props extends WithTranslation {
    index: number;
    data?: ColdchainProfileData;
    selected?: boolean;
    showDelete?: boolean;
    onSelect?: (state: boolean, index: number) => void;
    onDelete?: (index: number) => void;
}

const ColdchainZone = (props: Props) => {
    const { t } = props;
    const selected = props.selected ?? false;

    const handleSelectClick = () => {
        props.onSelect?.(!selected, props.index);
    };

    const handleDeleteClick = (event: React.MouseEvent) => {
        props.onDelete?.(props.index);
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <Row
            align="middle"
            justify="center"
            className={cn('coldchain-zone', {
                'coldchain-zone-selected': selected
            })}
            onClick={handleSelectClick}
        >
            <span className="coldchain-zone-index">{props.index + 1}</span>
            {props.showDelete && (
                <Button
                    type="text"
                    size="small"
                    icon={<img src={ColdchainTrash} alt="delete" />}
                    className="coldchain-zone-delete"
                    onClick={handleDeleteClick}
                />
            )}
            {!props.data?.name && (
                <Row justify="center" className="coldchain-zone-add-profile">
                    {!selected && (
                        <Col span={24}>
                            <img src={PlusCircle} alt="add-profile" />
                            {t('PlannerColdchain.addProfile')}
                        </Col>
                    )}
                    {selected && <Col span={24}>{t('PlannerColdchain.noProfile')}</Col>}
                </Row>
            )}

            {props.data?.name && (
                <Row align="bottom" justify="center" className="coldchain-zone-name">
                    {props.data?.name}
                </Row>
            )}
            {(props.data?.aboveTemperatureThresholdUse || props.data?.belowTemperatureThresholdUse) && (
                <Row
                    align="middle"
                    className={cn('coldchain-zone-content', {
                        'coldchain-zone-content-two':
                            props.data?.aboveTemperatureThresholdUse && props.data?.belowTemperatureThresholdUse
                    })}
                >
                    {props.data?.belowTemperatureThresholdUse && (
                        <span
                            className={cn('coldchain-zone-content-above', {
                                'coldchain-zone-content-center': !props.data?.aboveTemperatureThresholdUse,
                                'coldchain-zone-content-left':
                                    props.data?.belowTemperatureThresholdUse && props.data?.aboveTemperatureThresholdUse
                            })}
                        >
                            <span>{`${props.data?.belowTemperatureThreshold ?? '-'}${deg}`}</span>
                        </span>
                    )}
                    {props.data?.aboveTemperatureThresholdUse && (
                        <span
                            className={cn('coldchain-zone-content-below', {
                                'coldchain-zone-content-center': !props.data?.belowTemperatureThresholdUse,
                                'coldchain-zone-content-right':
                                    props.data?.belowTemperatureThresholdUse && props.data?.aboveTemperatureThresholdUse
                            })}
                        >
                            <span>{`${props.data?.aboveTemperatureThreshold ?? '-'}${deg}`}</span>
                        </span>
                    )}
                    {props.data?.aboveTemperatureThresholdUse && !props.data?.belowTemperatureThresholdUse && (
                        <>
                            <Col span={12}>
                                <span className="coldchain-zone-content-check-left">
                                    <img src={ColdchainCheck} alt="alert-check" />
                                </span>
                            </Col>
                            <Col span={12}>
                                <span className="coldchain-zone-content-warning-right">
                                    <img src={ColdchainWarning} alt="alert-warning" />
                                </span>
                            </Col>
                        </>
                    )}
                    {props.data?.belowTemperatureThresholdUse && !props.data?.aboveTemperatureThresholdUse && (
                        <>
                            <Col span={12}>
                                <span className="coldchain-zone-content-warning">
                                    <img src={ColdchainWarning} alt="alert-warning" />
                                </span>
                            </Col>
                            <Col span={12}>
                                <span className="coldchain-zone-content-check-right">
                                    <img src={ColdchainCheck} alt="alert-check" />
                                </span>
                            </Col>
                        </>
                    )}
                    {props.data?.belowTemperatureThresholdUse && props.data?.aboveTemperatureThresholdUse && (
                        <>
                            <Col span={8}>
                                <span className="coldchain-zone-content-warning">
                                    <img src={ColdchainWarning} alt="alert-warning" />
                                </span>
                            </Col>
                            <Col span={8}>
                                <span className="coldchain-zone-content-check-center">
                                    <img src={ColdchainCheck} alt="alert-check" />
                                </span>
                            </Col>
                            <Col span={8}>
                                <span className="coldchain-zone-content-warning-right">
                                    <img src={ColdchainWarning} alt="alert-check" />
                                </span>
                            </Col>
                        </>
                    )}
                </Row>
            )}
        </Row>
    );
};

export default withTranslation()(ColdchainZone);
