import { users } from './users';
import { vehicles } from './vehicles';
import moment from 'moment';
import { DTCODownloadedFileApiResult } from 'generated/backend-api';
import { CCRState } from 'modules/management/modules/driver-cards-remote-memory/DriversCardsRemoteMemoryModule';

export const dtcosCards = [
    ...users.map((u, i) =>
        i % 2
            ? ({
                  clientId: 100,
                  id: i.toString(),
                  driver: {
                      id: users[i]?.id ?? '',
                      name: String(users[i]?.name) + ' ' + String(users[i]?.surname) ?? ''
                  },
                  downloadStarted: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate(),
                  demand: true,
                  date: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate()
              } as DTCODownloadedFileApiResult)
            : ({
                  clientId: 100,
                  id: i.toString(),
                  driver: {
                      id: users[i]?.id ?? '',
                      name: String(users[i]?.name) + ' ' + String(users[i]?.surname) ?? ''
                  },
                  downloadStarted: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate(),
                  downloadedFile: 'file.xls',
                  demand: false,
                  date: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate()
              } as DTCODownloadedFileApiResult)
    ),
    ...users.map(
        (u, i) =>
            ({
                clientId: 100,
                id: i.toString(),
                driver: {
                    id: users[i]?.id ?? '',
                    name: String(users[i]?.name) + ' ' + String(users[i]?.surname) ?? ''
                },
                downloadStarted: moment()
                    .subtract(Math.round(Math.random() * 5) + 7, 'days')
                    .toDate(),
                downloadedFile: 'file.xls',
                demand: false,
                date: moment()
                    .subtract(Math.round(Math.random() * 5) + 7, 'days')
                    .toDate()
            } as DTCODownloadedFileApiResult)
    ),
    ...users.map(
        (u, i) =>
            ({
                clientId: 100,
                id: i.toString(),
                driver: {
                    id: users[i]?.id ?? '',
                    name: String(users[i]?.name) + ' ' + String(users[i]?.surname) ?? ''
                },
                downloadStarted: moment()
                    .subtract(Math.round(Math.random() * 5) + 14, 'days')
                    .toDate(),
                downloadedFile: 'file.xls',
                demand: false,
                date: moment()
                    .subtract(Math.round(Math.random() * 5) + 14, 'days')
                    .toDate()
            } as DTCODownloadedFileApiResult)
    ),
    ...users.map(
        (u, i) =>
            ({
                clientId: 100,
                id: i.toString(),
                driver: {
                    id: users[i]?.id ?? '',
                    name: String(users[i]?.name) + ' ' + String(users[i]?.surname) ?? ''
                },
                downloadStarted: moment()
                    .subtract(Math.round(Math.random() * 5) + 21, 'days')
                    .toDate(),
                downloadedFile: 'file.xls',
                demand: false,
                date: moment()
                    .subtract(Math.round(Math.random() * 5) + 21, 'days')
                    .toDate()
            } as DTCODownloadedFileApiResult)
    )
];

export const dtcosVehicles = [
    ...vehicles.map((u, i) =>
        i % 2
            ? ({
                  id: i.toString(),
                  clientId: 100,
                  monitoredObject: {
                      id: vehicles[i].id ?? '',
                      registrationNumber: String(vehicles[i].registrationNumber) ?? ''
                  },
                  dataActivityEnd: '',
                  dataActivityStart: '',
                  downloadStarted: moment()
                      .subtract(Math.round(Math.random() * 14), 'days')
                      .toDate(),
                  demand: true,
                  date: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate()
              } as DTCODownloadedFileApiResult)
            : ({
                  id: i.toString(),
                  clientId: 100,
                  monitoredObject: {
                      id: vehicles[i].id ?? '',
                      registrationNumber: String(vehicles[i].registrationNumber) ?? ''
                  },
                  dataActivityEnd: '',
                  dataActivityStart: '',
                  downloadStarted: moment()
                      .subtract(Math.round(Math.random() * 14), 'days')
                      .toDate(),
                  demand: false,
                  date: moment()
                      .subtract(Math.round(Math.random() * 1), 'days')
                      .toDate(),
                  downloadedFile: 'file.xls'
              } as DTCODownloadedFileApiResult)
    ),
    ...vehicles.map(
        (u, i) =>
            ({
                id: i.toString(),
                clientId: 100,
                monitoredObject: {
                    id: vehicles[i].id ?? '',
                    registrationNumber: String(vehicles[i].registrationNumber) ?? ''
                },
                dataActivityEnd: '',
                dataActivityStart: '',
                downloadStarted: moment()
                    .subtract(Math.round(Math.random() * 14) + 28, 'days')
                    .toDate(),
                demand: false,
                date: moment()
                    .subtract(Math.round(Math.random() * 5) + 7, 'days')
                    .toDate(),
                downloadedFile: 'file.xls'
            } as DTCODownloadedFileApiResult)
    ),
    ...vehicles.map(
        (u, i) =>
            ({
                id: i.toString(),
                clientId: 100,
                monitoredObject: {
                    id: vehicles[i].id ?? '',
                    registrationNumber: String(vehicles[i].registrationNumber) ?? ''
                },
                dataActivityEnd: '',
                dataActivityStart: '',
                downloadStarted: moment()
                    .subtract(Math.round(Math.random() * 14) + 56, 'days')
                    .toDate(),
                demand: false,
                date: moment()
                    .subtract(Math.round(Math.random() * 5) + 14, 'days')
                    .toDate(),
                downloadedFile: 'file.xls'
            } as DTCODownloadedFileApiResult)
    )
];
export const ccrCards = [
    {
        id: '1',
        serialNumber: 'SN0000000000',
        state: CCRState.Disconnected
    },
    {
        id: '2',
        serialNumber: 'SN5421065897',
        state: CCRState.ConnectedCompany
    }
];
