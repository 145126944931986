import React from 'react';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

interface Props extends TabsProps {}

function EnhancedTabs(props: Props) {
    return <Tabs {...props} />;
}

EnhancedTabs.TabPane = Tabs.TabPane;

export default EnhancedTabs;
