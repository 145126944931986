import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import cn from 'classnames';
import moment, { duration } from 'moment';
import 'moment-duration-format';
import qa from 'qa-selectors';
import { Button, Popover, Tooltip } from 'common/components';
import { AvailableCurrencies } from 'common/model/currency';
import CostPopover from '../CostPopover';
import { Role } from 'logic/auth';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import { TransportType } from '../../planner-logic';
import { PlannedTransport, RouteType } from 'generated/routing-api/models';
import { ItemInfo, TollField } from 'resources/images/planner';
import { TollCostByCountry, TransportPlaceTaskType } from 'generated/backend-api';
import SelectButton from 'common/components/SelectButon';
import { roundToDecimals } from 'common/utils/averages';
import numeral from 'numeral';

export interface RouteConfiguration {
    /**
     * Currently selected route balanced/fastest/planned
     */
    route: TransportType;
    clientId?: number;
    pricePerKM: {
        currency: AvailableCurrencies;
        cost: number;
    };
}

interface Props extends WithTranslation, WithLogic {
    showToll?: boolean;
    onTransportTypeChange?: (type: TransportType) => void;
}

const selectLarge = 180 as const;
const selectSmall = 140 as const;
type selectTypeWidth = typeof selectLarge | typeof selectSmall;

interface State {
    configuration: RouteConfiguration;
    vehicleSelectBoxWidth: selectTypeWidth;
    noMoreAvailable: boolean;
}

const INITIAL_STATE: State = {
    vehicleSelectBoxWidth: selectSmall,
    noMoreAvailable: false,
    configuration: {
        route: TransportType.balanced,
        pricePerKM: {
            cost: 0,
            currency: AvailableCurrencies.EUR
        }
    }
};

class RouteConfigurator extends Component<Props, State> {
    roles: Role[];

    constructor(props: Props) {
        super(props);
        this.roles = this.props.logic.auth().roles();
        const transportCostPerKm = this.props.logic.plannerLogic().transport.costPerKm;
        this.state = {
            ...INITIAL_STATE,
            configuration: {
                ...INITIAL_STATE.configuration,
                pricePerKM: transportCostPerKm?.value
                    ? {
                          cost: transportCostPerKm.value ?? 0,
                          currency: (transportCostPerKm.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
                      }
                    : INITIAL_STATE.configuration.pricePerKM
            }
        };
    }

    render() {
        const { t } = this.props;
        const { route: activeRoute } = this.state.configuration;

        return (
            <div className="route-configurator">
                <Row className="route-configurator-header" align="middle" justify="start">
                    <Col>
                        <h5>{t('routing.configurator.header')}</h5>
                    </Col>
                    <Col>
                        <Tooltip title={t('Planner.routeOptionsCostInfo')}>
                            <Button
                                type="link"
                                className="route-configurator-header-info"
                                icon={<img src={ItemInfo} alt="info-route-options" />}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <div className="route-configurator-selection-new">
                    {this.props.logic.plannerLogic().possibleTransports.map((route, index) => (
                        <div
                            key={index}
                            onClick={() => this._onSelectRoute(route.type)}
                            className={cn('route-configurator-selection-new-route', {
                                'route-configurator-selection-new-route-selected':
                                    TransportType[route.type] === this.props.logic.plannerLogic().selectedTransportType
                            })}
                            data-qa={
                                (route.type as string) === (activeRoute as string)
                                    ? qa.planner.configurator.routeSelected
                                    : qa.planner.configurator.route
                            }
                        >
                            <Row
                                gutter={[14, 12]}
                                align="middle"
                                data-qa={qa.planner.configurator.transportType?.[route.type]}
                            >
                                <Col xs={6}>
                                    <Row align="middle" className="route-type">
                                        <Col>{this._getRouteTranslation()}</Col>
                                    </Row>
                                </Col>
                                <Col xs={18}>
                                    <Row align="middle" justify="end" gutter={[14, 12]}>
                                        <Col data-qa={qa.planner.configurator.fieldDistance}>
                                            {Math.round(Number(route.distance) / 1000) + ' ' + t('common.km')}
                                        </Col>
                                        <Col data-qa={qa.planner.configurator.fieldDuration}>
                                            {this._getRouteDuration(route)}
                                        </Col>
                                        {this.props.showToll && (
                                            <Col>
                                                <SelectButton
                                                    loading={this.props.logic.plannerLogic().tollCostLoading}
                                                    label={this._getTollCost(route.type)}
                                                    icon={TollField}
                                                    selected={false}
                                                    qa={qa.planner.configurator.fieldTollCosts}
                                                    className="toll-field"
                                                    tooltip={{
                                                        overlayClassName: 'route-configurator-toll-title',
                                                        title: this._getTollCountries(route.type)
                                                    }}
                                                />
                                            </Col>
                                        )}
                                        <Col>{this._getCost(route)} </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {this.props.logic.plannerLogic().possibleTransports.length === 0 && (
                        <p className="route-configurator-selection-route">{t('routing.configurator.noRoutes')}</p>
                    )}
                </div>
            </div>
        );
    }

    private _getRouteTranslation = (): string => {
        if (!this.props.logic.plannerLogic().transport.id) {
            return this.props.t('VehicleProfile.route.recommended');
        } else {
            if (this.props.logic.plannerLogic().routeModified) {
                return this.props.t('VehicleProfile.route.modified');
            } else {
                return this.props.t('VehicleProfile.route.saved');
            }
        }
    };

    private _getTotalTollCost = (type: RouteType) => {
        // old transports with no toll cost data
        let tollCosts = {
            price: 0,
            currency: (this.props.logic.plannerLogic().transport.costPerKm?.currency ??
                AvailableCurrencies.EUR) as AvailableCurrencies
        };

        const value = this.props.logic.plannerLogic().tollCostByType?.[type]?.totalCost;
        // Just show in client currency
        tollCosts.price = this.props.logic.plannerLogic().getPriceByCurrency(value ?? 0.0, tollCosts.currency);
        return tollCosts;
    };

    private _getTollCost = (type: RouteType) => {
        // old transports with no toll cost data
        const tollCost = this._getTotalTollCost(type);
        if (tollCost.price > 0) {
            return numeral(tollCost.price).format('0,0.00') + ' ' + tollCost.currency;
        } else {
            return '-';
        }
    };

    private _getTollCountries = (type: RouteType) => {
        const countriesList: TollCostByCountry[] | undefined =
            this.props.logic.plannerLogic().tollCostByType?.[type]?.tollByCountry;

        if (countriesList?.length) {
            const countries = countriesList.map((country: TollCostByCountry) => {
                const currency =
                    (this.props.logic.plannerLogic().transport.costPerKm?.currency as AvailableCurrencies) ??
                    AvailableCurrencies.EUR;
                const price = this.props.logic
                    .plannerLogic()
                    .getPriceByCurrency(country.amountInTargetCurrency, currency);

                return (
                    <Row key={country.country} align="middle">
                        <Col span={15}>{this.props.t(`common.countries.${country.country}`)}</Col>
                        <Col span={9}>
                            {numeral(price).format('0,0.00')} {currency}
                        </Col>
                    </Row>
                );
            });
            return <div className="route-configurator-toll-title">{countries}</div>;
        }
        return null;
    };

    private _getRouteDuration = (route: PlannedTransport) => {
        const bufferTotal =
            this.props.logic
                .plannerLogic()
                .transport.places?.reduce((placeTotal, place) => placeTotal + (place.durationBuffer ?? 0), 0) || 0;

        const loadingUnloadingTotal =
            this.props.logic.plannerLogic().transport.places?.reduce((placeTotal, place) => {
                const placeTasksTotal = (place.tasks || [])
                    .filter(
                        task =>
                            task.type &&
                            [TransportPlaceTaskType.Loading, TransportPlaceTaskType.Unloading].includes(task.type)
                    )
                    .reduce((taskTotal, task) => {
                        const taskDuration =
                            task.plannedEnd && task.plannedStart
                                ? moment(task.plannedEnd).diff(moment(task.plannedStart)) / 1000
                                : 0;
                        return taskTotal + taskDuration;
                    }, 0);

                return placeTotal + placeTasksTotal;
            }, 0) || 0;

        const refuelParkWashTotal =
            this.props.logic.plannerLogic().transport.places?.reduce((placeTotal, place) => {
                const placeTasksTotal = (place.tasks || [])
                    .filter(
                        task =>
                            task.type &&
                            [
                                TransportPlaceTaskType.Refueling,
                                TransportPlaceTaskType.Washing,
                                TransportPlaceTaskType.Parking
                            ].includes(task.type)
                    )
                    .reduce((taskTotal, task) => {
                        const taskDuration =
                            task.plannedEnd && task.plannedStart
                                ? moment(task.plannedEnd).diff(moment(task.plannedStart)) / 1000
                                : 0;
                        return taskTotal + taskDuration;
                    }, 0);

                return placeTotal + placeTasksTotal;
            }, 0) ?? 0;

        const breaksTotal =
            this.props.logic.plannerLogic().transport.places?.reduce(
                (placeTotal, place) =>
                    placeTotal +
                    (place.listOfWtmBreakRest && place.listOfWtmBreakRest?.length > 0
                        ? place.listOfWtmBreakRest?.reduce((breakTotal, breakInfo) => {
                              const duration = breakInfo['activityEnd'] - breakInfo['activityStart'];
                              return breakTotal + duration;
                          }, 0)
                        : 0),
                0
            ) ?? 0;

        return breaksTotal + bufferTotal + loadingUnloadingTotal + refuelParkWashTotal > 0 ? (
            <Popover
                overlayClassName="route-duration-popover"
                placement="top"
                content={
                    <>
                        <Row justify="space-between">
                            <Col className="popover-label">{this.props.t('AetrTable.driveTime')}</Col>
                            <Col>{duration(route.duration ?? 0, 'seconds').format('h[h] m[m]')}</Col>
                        </Row>
                        {breaksTotal > 0 && (
                            <Row justify="space-between">
                                <Col className="popover-label">
                                    {this.props.t('Planner.RouteOptionsCard.duration.breakAndRests')}
                                </Col>
                                <Col>{duration(breaksTotal, 'seconds').format('h[h] m[m]')}</Col>
                            </Row>
                        )}
                        {bufferTotal > 0 && (
                            <Row justify="space-between">
                                <Col className="popover-label">{this.props.t('Planner.RouteOptionsCard.buffer')}</Col>
                                <Col>{duration(bufferTotal, 'seconds').format('h[h] m[m]')}</Col>
                            </Row>
                        )}
                        {loadingUnloadingTotal > 0 && (
                            <Row justify="space-between">
                                <Col className="popover-label">
                                    {this.props.t('Planner.RouteOptionsCard.duration.loadingAndUnloading')}
                                </Col>
                                <Col>{duration(loadingUnloadingTotal, 'seconds').format('h[h] m[m]')}</Col>
                            </Row>
                        )}
                        {refuelParkWashTotal > 0 && (
                            <Row justify="space-between">
                                <Col className="popover-label">
                                    {this.props.t('Planner.RouteOptionsCard.duration.refuelingAndCarWash')}
                                </Col>
                                <Col>{duration(refuelParkWashTotal, 'seconds').format('h[h] m[m]')}</Col>
                            </Row>
                        )}
                    </>
                }
            >
                {duration(
                    (route.duration ?? 0) + breaksTotal + bufferTotal + loadingUnloadingTotal + refuelParkWashTotal,
                    'seconds'
                ).format('h[h] m[m]')}
            </Popover>
        ) : (
            duration(route.duration ?? 0, 'seconds').format('h[h] m[m]')
        );
    };

    private _getCost = (route: PlannedTransport) => {
        const pricePerKM = {
            cost: this.props.logic.plannerLogic().transport.costPerKm?.value ?? 0,
            currency: (this.props.logic.plannerLogic().transport.costPerKm?.currency ??
                AvailableCurrencies.EUR) as AvailableCurrencies
        };
        const content = () => {
            const value = roundToDecimals(pricePerKM?.cost * ((route.distance ?? 0) / 1000));
            return numeral(value).format('0,0.00') + ' ' + pricePerKM?.currency;
        };

        return this.props.showToll ? (
            <Popover
                title={this.props.t('Planner.priceBreakdown')}
                overlayClassName="costs-popover-inner"
                content={
                    <CostPopover
                        tollPrice={this._getTotalTollCost(route.type)}
                        pricePerKM={pricePerKM}
                        distance={route.distance ?? 0}
                    />
                }
            >
                <div className="route-configurator-route-cost">{content()}</div>
            </Popover>
        ) : (
            <div className="route-configurator-route-cost" data-qa={qa.planner.configurator.fieldCosts}>
                {content()}
            </div>
        );
    };

    private _onSelectRoute = (type: RouteType) => {
        this.props.onTransportTypeChange?.(TransportType[type]);
    };
}

export default withTranslation()(withLogicContext(observer(RouteConfigurator)));
