/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectToUser
 */
export interface MonitoredObjectToUser {
    /**
     * 
     * @type {object}
     * @memberof MonitoredObjectToUser
     */
    monitoredObjects: object;
}

export function MonitoredObjectToUserFromJSON(json: any): MonitoredObjectToUser {
    return MonitoredObjectToUserFromJSONTyped(json, false);
}

export function MonitoredObjectToUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectToUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjects': json['monitored_objects'],
    };
}

export function MonitoredObjectToUserToJSON(value?: MonitoredObjectToUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_objects': value.monitoredObjects,
    };
}


