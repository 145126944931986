/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatetimePeriod
 */
export interface DatetimePeriod {
    /**
     * 
     * @type {Date}
     * @memberof DatetimePeriod
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof DatetimePeriod
     */
    end: Date;
}

export function DatetimePeriodFromJSON(json: any): DatetimePeriod {
    return DatetimePeriodFromJSONTyped(json, false);
}

export function DatetimePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatetimePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function DatetimePeriodToJSON(value?: DatetimePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
    };
}


