/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PuescStatus {
    Healthy = 'healthy',
    Unhealthy = 'unhealthy',
    Unknown = 'unknown',
    NoTransport = 'no_transport'
}

export function PuescStatusFromJSON(json: any): PuescStatus {
    return PuescStatusFromJSONTyped(json, false);
}

export function PuescStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PuescStatus {
    return json as PuescStatus;
}

export function PuescStatusToJSON(value?: PuescStatus | null): any {
    return value as any;
}

