import { Component } from 'react';
import { VehicleStateObject } from 'generated/graphql';
import { WithLogic, withLogicContext } from 'App';
import { google } from 'google-maps';
import { mapStyles } from 'logic/map/style';
import { LatLng } from 'common/model/geo';
import { getCenterBoundsWithDistance } from 'common/utils/geo';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as icons from 'resources/images/common';

interface Props extends WithLogic, WithTranslation {
    mapId: string;
    vehicleState: VehicleStateObject;
    routes?: string[];
    onAfterMapInit?: () => void;
}

class Map extends Component<Props, {}> {
    private _map?: google.maps.Map;
    private _google: google;
    private _vehicleMarker?: google.maps.Marker;
    private _polyline: google.maps.Polyline[] | undefined;

    constructor(props: Props) {
        super(props);
        this._google = (window as any).google;
    }

    componentDidMount() {
        const mapElement = document.getElementById(this.props.mapId);
        if (mapElement && this.props.vehicleState.gpsData?.lat && this.props.vehicleState.gpsData?.lon) {
            this._initMap(mapElement, {
                lat: this.props.vehicleState.gpsData.lat,
                lng: this.props.vehicleState.gpsData.lon
            });

            this.props.onAfterMapInit?.();

            this._setVehicle();
            this._setRoute();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.routes !== this.props.routes) {
            this._setRoute();
        }
    }

    render() {
        if (!this._isMapAvailable) {
            return (
                <div className="dispatcher-board-map-not-available">
                    <img src={icons.warning} alt="warning" />
                    <span>{this.props.t('DispatcherBoard.transportMap.mapNotAvailable')}</span>
                </div>
            );
        }

        return <div className="dispatcher-board-map" id={this.props.mapId} />;
    }

    private get _isMapAvailable(): boolean {
        return (
            !!this.props.vehicleState.gpsData &&
            !!this.props.vehicleState.gpsData.lat &&
            !!this.props.vehicleState.gpsData.lon
        );
    }

    private _initMap(element: HTMLElement, center: LatLng) {
        this._map = new this._google.maps.Map(element, {
            center,
            zoom: 8,
            draggable: false,
            styles: mapStyles.default,
            gestureHandling: 'greedy',
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            minZoom: 4
        });
    }

    private _setVehicle() {
        this._removeVehicle();
        this._vehicleMarker = this.props.logic
            .map()
            .vehicles()
            .createVehicleMarker(this.props.logic.map().vehicles().toVehicleMap(this.props.vehicleState));

        if (this._map) {
            this._vehicleMarker?.setMap(this._map);
            this._fitVehicle();
        }
    }

    private _setRoute() {
        this._removeRoute();
        this._polyline = this.props.routes?.map(route => {
            const polyline = new this._google.maps.Polyline({
                path: this._google.maps.geometry.encoding.decodePath(route),
                geodesic: true,
                strokeColor: '#07ADFA',
                strokeOpacity: 1.0,
                strokeWeight: 6
            });
            return polyline;
        });

        if (this._map) {
            this._polyline?.forEach(p => {
                if (this._map) {
                    p.setMap(this._map);
                }
            });
        }
    }

    private _removeVehicle() {
        this._vehicleMarker?.setMap(null);
        this._vehicleMarker = undefined;
    }

    private _removeRoute() {
        this._polyline?.forEach(p => {
            if (this._map) {
                p.setMap(null);
            }
        });
        this._polyline = [];
    }

    private _fitVehicle() {
        const position = this._vehicleMarker?.getPosition();
        if (this._vehicleMarker && position) {
            const bounds = getCenterBoundsWithDistance(this._google, position, 40000);
            this._map?.fitBounds(bounds, 0);
        }
    }
}

export default withTranslation()(withLogicContext(Map));
