/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    RouteCostsDetailsCountry,
    RouteCostsDetailsCountryFromJSON,
    RouteCostsDetailsCountryFromJSONTyped,
    RouteCostsDetailsCountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface RouteCostsDetails
 */
export interface RouteCostsDetails {
    /**
     * 
     * @type {Array<RouteCostsDetailsCountry>}
     * @memberof RouteCostsDetails
     */
    countries?: Array<RouteCostsDetailsCountry>;
    /**
     * 
     * @type {Price}
     * @memberof RouteCostsDetails
     */
    price: Price;
    /**
     * 
     * @type {number}
     * @memberof RouteCostsDetails
     */
    tollDistance: number;
}

export function RouteCostsDetailsFromJSON(json: any): RouteCostsDetails {
    return RouteCostsDetailsFromJSONTyped(json, false);
}

export function RouteCostsDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteCostsDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': !exists(json, 'countries') ? undefined : ((json['countries'] as Array<any>).map(RouteCostsDetailsCountryFromJSON)),
        'price': PriceFromJSON(json['price']),
        'tollDistance': json['toll_distance'],
    };
}

export function RouteCostsDetailsToJSON(value?: RouteCostsDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': value.countries === undefined ? undefined : ((value.countries as Array<any>).map(RouteCostsDetailsCountryToJSON)),
        'price': PriceToJSON(value.price),
        'toll_distance': value.tollDistance,
    };
}


