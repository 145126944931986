/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
    MonitoredObjectMetadata,
    MonitoredObjectMetadataFromJSON,
    MonitoredObjectMetadataFromJSONTyped,
    MonitoredObjectMetadataToJSON,
    MonitoredObjectType,
    MonitoredObjectTypeFromJSON,
    MonitoredObjectTypeFromJSONTyped,
    MonitoredObjectTypeToJSON,
    TemperatureSensor,
    TemperatureSensorFromJSON,
    TemperatureSensorFromJSONTyped,
    TemperatureSensorToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObject
 */
export interface MonitoredObject {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    clientId: number;
    /**
     * 
     * @type {Client}
     * @memberof MonitoredObject
     */
    client?: Client;
    /**
     * 
     * @type {MonitoredObjectType}
     * @memberof MonitoredObject
     */
    monitoredObjectType?: MonitoredObjectType;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    monitoredObjectTypeId?: number;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObject
     */
    costPerKm?: object;
    /**
     * 
     * @type {MonitoredObjectMetadata}
     * @memberof MonitoredObject
     */
    metadata?: MonitoredObjectMetadata;
    /**
     * 
     * @type {Array<TemperatureSensor>}
     * @memberof MonitoredObject
     */
    temperatureSensors?: Array<TemperatureSensor>;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    systemDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    manufacturerName?: string | null;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObject
     */
    _configuration?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    tankSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    customLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    vehicleTrainMaxWeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    vehicleTractorMaxWeight?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObject
     */
    cng?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObject
     */
    disabledAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObject
     */
    forwardedToDff?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObject
     */
    lastSyncWithDff?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    dffSyncTraceback?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    monitoringDevice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    driverApplication?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    externalSystem?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    countryOfRegistration?: number | null;
}

export function MonitoredObjectFromJSON(json: any): MonitoredObject {
    return MonitoredObjectFromJSONTyped(json, false);
}

export function MonitoredObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'monitoredObjectType': !exists(json, 'monitored_object_type') ? undefined : MonitoredObjectTypeFromJSON(json['monitored_object_type']),
        'monitoredObjectTypeId': !exists(json, 'monitored_object_type_id') ? undefined : json['monitored_object_type_id'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'metadata': !exists(json, 'metadata') ? undefined : MonitoredObjectMetadataFromJSON(json['metadata']),
        'temperatureSensors': !exists(json, 'temperature_sensors') ? undefined : ((json['temperature_sensors'] as Array<any>).map(TemperatureSensorFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'registrationNumber': json['registration_number'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'systemDescription': !exists(json, 'system_description') ? undefined : json['system_description'],
        'manufacturerName': !exists(json, 'manufacturer_name') ? undefined : json['manufacturer_name'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tankSize': !exists(json, 'tank_size') ? undefined : json['tank_size'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'vehicleTrainMaxWeight': !exists(json, 'vehicle_train_max_weight') ? undefined : json['vehicle_train_max_weight'],
        'vehicleTractorMaxWeight': !exists(json, 'vehicle_tractor_max_weight') ? undefined : json['vehicle_tractor_max_weight'],
        'cng': !exists(json, 'cng') ? undefined : json['cng'],
        'disabledAt': !exists(json, 'disabled_at') ? undefined : (json['disabled_at'] === null ? null : new Date(json['disabled_at'])),
        'forwardedToDff': !exists(json, 'forwarded_to_dff') ? undefined : json['forwarded_to_dff'],
        'lastSyncWithDff': !exists(json, 'last_sync_with_dff') ? undefined : (json['last_sync_with_dff'] === null ? null : new Date(json['last_sync_with_dff'])),
        'dffSyncTraceback': !exists(json, 'dff_sync_traceback') ? undefined : json['dff_sync_traceback'],
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : json['monitoring_device'],
        'driverApplication': !exists(json, 'driver_application') ? undefined : json['driver_application'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
        'countryOfRegistration': !exists(json, 'country_of_registration') ? undefined : json['country_of_registration'],
    };
}

export function MonitoredObjectToJSON(value?: MonitoredObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'monitored_object_type': MonitoredObjectTypeToJSON(value.monitoredObjectType),
        'monitored_object_type_id': value.monitoredObjectTypeId,
        'cost_per_km': value.costPerKm,
        'metadata': MonitoredObjectMetadataToJSON(value.metadata),
        'temperature_sensors': value.temperatureSensors === undefined ? undefined : ((value.temperatureSensors as Array<any>).map(TemperatureSensorToJSON)),
        'external_id': value.externalId,
        'registration_number': value.registrationNumber,
        'description': value.description,
        'system_description': value.systemDescription,
        'manufacturer_name': value.manufacturerName,
        'configuration': value._configuration,
        'note': value.note,
        'status': value.status,
        'tank_size': value.tankSize,
        'custom_label': value.customLabel,
        'vehicle_train_max_weight': value.vehicleTrainMaxWeight,
        'vehicle_tractor_max_weight': value.vehicleTractorMaxWeight,
        'cng': value.cng,
        'disabled_at': value.disabledAt === undefined ? undefined : (value.disabledAt === null ? null : value.disabledAt.toISOString()),
        'forwarded_to_dff': value.forwardedToDff,
        'last_sync_with_dff': value.lastSyncWithDff === undefined ? undefined : (value.lastSyncWithDff === null ? null : value.lastSyncWithDff.toISOString()),
        'dff_sync_traceback': value.dffSyncTraceback,
        'monitoring_device': value.monitoringDevice,
        'driver_application': value.driverApplication,
        'external_system': value.externalSystem,
        'country_of_registration': value.countryOfRegistration,
    };
}


