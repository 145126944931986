export const problematicCharsRegex = /(?![x00-z7F]|[xC0-xDF][x80-xBF]|[xE0-xEF][x80-xBF]{2}|[xF0-xF7][x80-xBF]{3})./g;
export const problematicNonAsciCharsRegex = /[\u{0080}-\u{FFFF}]/gu;

export const search = <T>(value: string | undefined, keys: string[], data: T[]): T[] => {
    return value
        ? data?.length > 0
            ? data.filter(item => {
                  return (
                      keys.filter(k => {
                          const keyArray = k.split('.');
                          if (keyArray.length > 1) {
                              // when we have tree object key
                              return (
                                  changeToValidASCINoSpaceString(
                                      String(keyArray.reduce((o, k) => (o ?? ({} as T))[k], item))
                                  )
                                      // lowercase
                                      .toLowerCase()
                                      .includes(
                                          changeToValidASCINoSpaceString(value)
                                              // lowercase
                                              .toLowerCase()
                                      )
                              );
                          } else {
                              // when we have simple key
                              if (Array.isArray(item[k])) {
                                  return item[k].find((itemValue: string) =>
                                      changeToValidASCINoSpaceString(itemValue)
                                          // lowercase
                                          .toLowerCase()
                                          .includes(
                                              changeToValidASCINoSpaceString(value)
                                                  // lowercase
                                                  .toLowerCase()
                                          )
                                  );
                              } else {
                                  return (
                                      changeToValidASCINoSpaceString(String(item[k]))
                                          // lowercase
                                          .toLowerCase()
                                          .includes(
                                              changeToValidASCINoSpaceString(value)
                                                  // lowercase
                                                  .toLowerCase()
                                          )
                                  );
                              }
                          }
                      }).length > 0
                  );
              })
            : []
        : data;
};

export const searched = (text: string | undefined, string: string): boolean => {
    return text
        ? changeToValidASCINoSpaceString(string)
              // lowercase
              .toLowerCase()
              .includes(
                  changeToValidASCINoSpaceString(text)
                      // lowercase
                      .toLowerCase()
              )
        : true;
};

export const changeToValidASCINoSpaceString = (string: string) => {
    return changeToNoSpaceString(string).replace(problematicNonAsciCharsRegex, '').replace(problematicCharsRegex, '');
};

export const changeToNoSpaceString = (string: string, whitespaceReplacer: string = '') => {
    return (
        string
            // remove whitespaces
            .replaceAll(' ', whitespaceReplacer)
            // remove accents/diacritics
            .normalize('NFD')
    );
};
