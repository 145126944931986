/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyOperationalCostDefaults,
    CompanyOperationalCostDefaultsFromJSON,
    CompanyOperationalCostDefaultsToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    ReadOnlyCompanyOperationalCost,
    ReadOnlyCompanyOperationalCostFromJSON,
    ReadOnlyCompanyOperationalCostToJSON,
    WriteOnlyCompanyOperationalCost,
    WriteOnlyCompanyOperationalCostFromJSON,
    WriteOnlyCompanyOperationalCostToJSON,
} from '../models';

export interface CompanyOperationalCostCreateRequest {
    data: WriteOnlyCompanyOperationalCost;
}

export interface CompanyOperationalCostDefaultMarketAverageRequest {
    client?: string;
    active?: boolean;
    limit?: number;
    offset?: number;
}

export interface CompanyOperationalCostDeleteRequest {
    id: number;
}

export interface CompanyOperationalCostListRequest {
    client?: string;
    active?: boolean;
    limit?: number;
    offset?: number;
}

export interface CompanyOperationalCostPartialUpdateRequest {
    id: number;
    data: WriteOnlyCompanyOperationalCost;
}

export interface CompanyOperationalCostReadRequest {
    id: number;
}

export interface CompanyOperationalCostUpdateRequest {
    id: number;
    data: WriteOnlyCompanyOperationalCost;
}

/**
 * no description
 */
export class CompanyOperationalCostApi extends runtime.BaseAPI {

    /**
     */
    async companyOperationalCostCreateRaw(requestParameters: CompanyOperationalCostCreateRequest): Promise<runtime.ApiResponse<ReadOnlyCompanyOperationalCost>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling companyOperationalCostCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCompanyOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCompanyOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostCreate(requestParameters: CompanyOperationalCostCreateRequest): Promise<ReadOnlyCompanyOperationalCost> {
        const response = await this.companyOperationalCostCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostDefaultMarketAverageRaw(requestParameters: CompanyOperationalCostDefaultMarketAverageRequest): Promise<runtime.ApiResponse<CompanyOperationalCostDefaults>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/default-market-averages/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyOperationalCostDefaultsFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostDefaultMarketAverage(requestParameters: CompanyOperationalCostDefaultMarketAverageRequest): Promise<CompanyOperationalCostDefaults> {
        const response = await this.companyOperationalCostDefaultMarketAverageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostDeleteRaw(requestParameters: CompanyOperationalCostDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyCompanyOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyOperationalCostDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCompanyOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostDelete(requestParameters: CompanyOperationalCostDeleteRequest): Promise<ReadOnlyCompanyOperationalCost> {
        const response = await this.companyOperationalCostDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostListRaw(requestParameters: CompanyOperationalCostListRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostList(requestParameters: CompanyOperationalCostListRequest): Promise<InlineResponse2002> {
        const response = await this.companyOperationalCostListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostPartialUpdateRaw(requestParameters: CompanyOperationalCostPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCompanyOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyOperationalCostPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling companyOperationalCostPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCompanyOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCompanyOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostPartialUpdate(requestParameters: CompanyOperationalCostPartialUpdateRequest): Promise<ReadOnlyCompanyOperationalCost> {
        const response = await this.companyOperationalCostPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostReadRaw(requestParameters: CompanyOperationalCostReadRequest): Promise<runtime.ApiResponse<ReadOnlyCompanyOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyOperationalCostRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCompanyOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostRead(requestParameters: CompanyOperationalCostReadRequest): Promise<ReadOnlyCompanyOperationalCost> {
        const response = await this.companyOperationalCostReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async companyOperationalCostUpdateRaw(requestParameters: CompanyOperationalCostUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCompanyOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyOperationalCostUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling companyOperationalCostUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/company-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCompanyOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCompanyOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async companyOperationalCostUpdate(requestParameters: CompanyOperationalCostUpdateRequest): Promise<ReadOnlyCompanyOperationalCost> {
        const response = await this.companyOperationalCostUpdateRaw(requestParameters);
        return await response.value();
    }

}
