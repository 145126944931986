import { google } from 'google-maps';
import i18next from 'i18next';
import { renderToString } from 'react-dom/server';
import * as turf from '@turf/turf';
import { toGeoJSON, decode, encode } from '@mapbox/polyline';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import booleanIntersects from '@turf/boolean-intersects';

import * as AlarmIcons from 'resources/images/alarms';
import { ReadOnlyCurrency } from 'generated/new-main';
import { LatLng } from 'common/model/geo';
import { AlarmType, TransportCrossingPoint } from 'generated/backend-api';

import { PoiModelMap } from './fuelStations';
import { MapConf, MapLogic } from '../map';
import { PoiMarkerData, PoiMarker } from './poi-marker';
import { VehicleMarkerIcon, VehicleModelMap } from './vehicles';
import AlarmBlock from 'modules/alarms/ui/AlarmBlock';
import { Alarm } from 'common/model/alarm';
import { EFuelType } from 'generated/graphql';
import cn from 'classnames';
import { bestPriceClusterCalculator, fsBestPriceInPois } from 'common/utils/best-price';
import poland from '../poland.json';
import * as plannerIcons from 'resources/images/planner';
import { MAP_MARKERS_INDEX, MAX_ZOOM } from 'domain-constants';
import PointInfobox from 'modules/map/components/PointInfobox/PointInfobox';
import { PointInfo } from 'generated/backend-api-live/models';
import { GraphKey } from 'modules/statistics/modules/journeys/ui/JourneyGraph';
// FIXME: This will be used only until marker with label will be in use, because normal cluster doesn't support these markers
const MarkerWithLabel = require('@google/markerwithlabel');

const polylineColor = '#25A6E4';

type AlarmClustersType = {
    -readonly [key in keyof typeof AlarmType]: MarkerClusterer | undefined;
};

type AlarmsType = {
    -readonly [key in keyof typeof AlarmType]?: PoiModelMap[];
};

enum PlacesIcons {
    Start = '/img-svg/placeStart.svg',
    Middle = '/img-svg/place.svg',
    End = '/img-svg/placeEnd.svg',
    Unclosed = '/markers-svg/vehicle-moving.svg'
}

enum FuelStationsIcons {
    Icon = '/img-map/map-fs-icon.svg'
}

enum ParkingIcons {
    Icon = '/img-map/map-p-icon.svg'
}

enum WashIcons {
    Icon = '/img-map/map-wash-icon.svg'
}

enum PoiIcons {
    Icon = '/img-map/map-poi-icon.svg'
}

interface PoiModel {
    poi: PoiModelMap;
    routeIndex: number;
    meta: {
        distance: number;
        coords: number[];
    };
}

export const driverBehaviorAlarmTypes: (keyof AlarmsType)[] = [
    'AggresiveTakeoff',
    'DangerousTurnCountry',
    'DangerousTurnHighway',
    'DangerousBreakingCity',
    'DangerousBreakingCountry',
    'DangerousBreakingHighway',
    'Overspeed'
];

export const geoAlarmTypes: (keyof AlarmsType)[] = ['CorridorLeave', 'PoiArrival', 'PoiClose', 'PoiDeparture'];
export const instantAlarmTypes: (keyof AlarmsType)[] = ['UnavailableGps', 'ConnectionLoss', 'BatteryLow'];

export class RoutingMapController {
    vehicle?: VehicleModelMap;

    readonly _google: google;

    private _conf: MapConf;
    private _map?: google.maps.Map;

    private _pointInfobox?: google.maps.InfoWindow;
    private _journeysControls: Array<HTMLElement | null>;
    private _journeysGraphSection?: GraphKey;
    private _vehicleMarker?: google.maps.Marker;
    private _placeMarkers: google.maps.Marker[];
    private _crossingPlaceMarkers: google.maps.Marker[];
    private _alarmMarkers: {
        [key in keyof typeof AlarmType]?: PoiMarker[];
    };
    private _alarmClusters: AlarmClustersType;
    private _alarms: AlarmsType;
    private _alarmsInfoBoxes: {
        [key: string]: {
            alarmType?: AlarmType;
            infoWindow: google.maps.InfoWindow;
        };
    };
    private _alarmsControlsActive: {
        driverBehaviorAlarmTypes: boolean;
        geoAlarmTypes: boolean;
        instantAlarmTypes: boolean;
    };
    private _markerBeforeMove?: LatLng;
    private _tmpWayPointMarker?: google.maps.Marker;
    private _graphPointMarker?: google.maps.Marker;
    private _polylinesPlanned: google.maps.Polyline[];
    private _polylinesReal: google.maps.Polyline[];
    private _selectedRouteId?: string;

    private _fuelStationsMarkers: PoiMarker[];
    private _fuelStationsCluster?: MarkerClusterer;
    private _fuelStations: PoiModelMap[][];
    private _fuelStationsRender: boolean;
    private _redrawFuelStationsAfterFitBounds?: boolean;
    private _onFuelStationClick?: (fuelStation: PoiModelMap) => void;

    private _parkingsMarkers: PoiMarker[];
    private _parkingsCluster?: MarkerClusterer;
    private _parkings: PoiModelMap[][];
    private _parkingsRender: boolean;
    private _redrawParkingsAfterFitBounds?: boolean;
    private _onParkingClick?: (parking: PoiModelMap) => void;

    private _poisMarkers: PoiMarker[];
    private _poisCluster?: MarkerClusterer;
    private _pois: PoiModelMap[][];
    private _poisRender: boolean;
    private _redrawPoisAfterFitBounds?: boolean;
    private _onPoiClick?: (poi: PoiModelMap) => void;

    private _washersMarkers: PoiMarker[];
    private _washersCluster?: MarkerClusterer;
    private _washers: PoiModelMap[][];
    private _washersRender: boolean;
    private _redrawWashersAfterFitBounds?: boolean;
    private _onWashClick?: (poi: PoiModelMap) => void;

    private _ctrl: MapLogic;
    private _currencies?: ReadOnlyCurrency[];
    private _onAlarmClick?: (alarm: PoiModelMap) => void;
    private _onAlarmAcknowledgedClick?: (id: string) => void;
    private _onRouteClick?: (routeId?: string) => void;
    private _onBestPriceChange?: (poi?: PoiModelMap[]) => void;
    private _onMarkerDragEnd?: (markerId: string, latLng: LatLng, type: 'waypoint' | 'crossingPoint') => void;
    idlingEventForClustererFuelStations: google.maps.MapsEventListener | undefined;
    idlingEventForClustererParkings: google.maps.MapsEventListener | undefined;
    idlingEventForClustererPois: google.maps.MapsEventListener | undefined;
    idlingEventForClustererWashers: google.maps.MapsEventListener | undefined;
    private _onMarkerDrag?: (
        marker: google.maps.Marker,
        latLng: LatLng,
        type: 'waypoint' | 'crossingPoint',
        route?: string,
        crossingPointBeforeMove?: LatLng
    ) => void;
    private _onCrossingPlaceMarkerDelete?: (crossingPlaceMarkerId: string, latLng: LatLng) => void;
    private _onMarkerMouseover?: (markerId: string, latLng: LatLng) => void;
    private _onMarkerMouseout?: (markerId: string, latLng: LatLng) => void;
    private _onPolylineClick?: (route: string, latLng: LatLng) => void;
    private _onPolylineMouseOver?: (route: string, latLng: LatLng) => void;
    private _onPolylineMouseMove?: (polyline: google.maps.Polyline, route: string, latLng: LatLng) => void;
    private _onPolylineMouseOut?: (polyline: google.maps.Polyline, route: string, latLng: LatLng) => void;
    private _hasRoleJAA: boolean;
    private _hasRoleDBH_IC_JA: boolean;
    polandPolygon: { lng: number; lat: number }[];

    constructor(conf: MapConf, google: google, ctrl: MapLogic, map?: google.maps.Map) {
        this._conf = conf;
        this._ctrl = ctrl;
        this._map = map;
        this._google = google;
        this._placeMarkers = [];
        this._crossingPlaceMarkers = [];
        this._polylinesPlanned = [];
        this._polylinesReal = [];
        this._selectedRouteId = undefined;

        this._hasRoleJAA = false;
        this._hasRoleDBH_IC_JA = false;

        this._fuelStations = [];
        this._fuelStationsMarkers = [];
        this._fuelStationsRender = true;

        this._parkings = [];
        this._parkingsMarkers = [];
        this._parkingsRender = true;

        this._washers = [];
        this._washersMarkers = [];
        this._washersRender = true;

        this._pois = [];
        this._poisMarkers = [];
        this._poisRender = true;

        this._alarmMarkers = {};
        this._alarms = {};
        this._alarmClusters = {
            BatteryLow: undefined,
            ConnectionLoss: undefined,
            CorridorLeave: undefined,
            MonitoringDeviceSilent: undefined,
            PoiArrival: undefined,
            PoiClose: undefined,
            PoiDeparture: undefined,
            UnavailableGps: undefined,

            AggresiveTakeoff: undefined,
            DangerousBreakingCity: undefined,
            DangerousBreakingCountry: undefined,
            DangerousBreakingHighway: undefined,
            DangerousTurnCountry: undefined,
            DangerousTurnHighway: undefined,
            Overspeed: undefined,

            PoiEntry: undefined,
            PoiExit: undefined,

            PuescSentGeoStale: undefined,
            PuescSentGeoError: undefined,
            DeadMansSwitch: undefined,
            TransportColdChainProfileTemperatureHigh: undefined,
            TransportColdChainProfileTemperatureLow: undefined
        };
        this._alarmsInfoBoxes = {};
        this._alarmsControlsActive = {
            driverBehaviorAlarmTypes: false,
            geoAlarmTypes: false,
            instantAlarmTypes: false
        };
        this._journeysControls = [];
        this.polandPolygon = poland;
    }

    init(initialPadding?: number | google.maps.Padding): void {
        if (initialPadding) {
            this._ctrl?.setPadding(initialPadding);
        }
        const defaultBounds = new this._google.maps.LatLngBounds(
            new this._google.maps.LatLng(this._conf.initBounds[0].lat, this._conf.initBounds[0].lng)
        );
        this._conf.initBounds.forEach(place => {
            defaultBounds.extend(new this._google.maps.LatLng(place.lat, place.lng));
        });
        this._map?.fitBounds(defaultBounds, this._ctrl.initialPaddingWithLeftComponent);
    }

    setCurrencies(currencies: ReadOnlyCurrency[]) {
        this._currencies = currencies;
    }

    onBestPriceChange(cb: (poi?: PoiModelMap[]) => void) {
        this._onBestPriceChange = cb;
    }

    onRouteClick(cb: (routeId?: string) => void): void {
        this._onRouteClick = cb;
    }

    onFuelStationClick(cb?: (fuelStation: PoiModelMap) => void): void {
        this._onFuelStationClick = cb;
    }

    onParkingClick(cb?: (parking: PoiModelMap) => void): void {
        this._onParkingClick = cb;
    }

    onWashClick(cb?: (wash: PoiModelMap) => void): void {
        this._onWashClick = cb;
    }

    onPoiClick(cb?: (poi: PoiModelMap) => void): void {
        this._onPoiClick = cb;
    }

    onMarkerDragEnd(cb?: (markerId: string, latLng: LatLng, type: 'waypoint' | 'crossingPoint') => void): void {
        this._onMarkerDragEnd = cb;
    }

    onMarkerDrag(
        cb?: (marker: google.maps.Marker, latLng: LatLng, type: 'waypoint' | 'crossingPoint', route?: string) => void
    ): void {
        this._onMarkerDrag = cb;
    }

    onCrossingPlaceMarkerDelete(cb?: (markerId: string, latLng: LatLng) => void): void {
        this._onCrossingPlaceMarkerDelete = cb;
    }

    onMarkerMouseover(cb?: (markerId: string, latLng: LatLng) => void): void {
        this._onMarkerMouseover = cb;
    }

    onMarkerMouseOut(cb?: (markerId: string, latLng: LatLng) => void): void {
        this._onMarkerMouseout = cb;
    }

    onPolylineClick(cb?: (route: string, latLng: LatLng) => void): void {
        this._onPolylineClick = cb;
    }

    onPolylineMouseOver(cb?: (route: string, latLng: LatLng) => void): void {
        this._onPolylineMouseOver = cb;
    }

    onPolylineMouseMove(cb?: (polyline: google.maps.Polyline, route: string, latLng: LatLng) => void): void {
        this._onPolylineMouseMove = cb;
    }

    onPolylineMouseOut(cb?: (polyline: google.maps.Polyline, route: string, latLng: LatLng) => void): void {
        this._onPolylineMouseOut = cb;
    }

    onAlarmAcknowledgedClick(cb?: (id: string) => void): void {
        this._onAlarmAcknowledgedClick = cb;
    }

    destroy(): void {
        this._onFuelStationClick = undefined;
        this._onParkingClick = undefined;
        this._onWashClick = undefined;
        this._onPoiClick = undefined;
        this._onAlarmAcknowledgedClick = undefined;
        this._removePlannedPolylines();
        this._removePolylinesReal();
        this._removeVehicleMarker();
        this._removeAlarmInfoboxes();
        this._removeAlarms();
        this.destroyPointInfobox();
        this.removeTmpWayPointMarker();
        this.removeAlarmMarkers();
        this.unselectPolylinesReal();
        this.renderAlarms();
        this.renderRoute([], [], []);
    }

    resetPlaces() {
        this._removeFuelStations();
        this._removeParkings();
        this._removeWashers();
        this._removePois();
    }

    setFuelStations(data: PoiModelMap[][]): void {
        this._fuelStations = data;
        if (this._fuelStations && this._fuelStationsRender) {
            this._renderFuelStations();
        }
    }

    setAlarms(data: { [key in keyof typeof AlarmType]?: PoiModelMap[] }) {
        this._alarms = data;
        if (this._alarms) {
            this._renderAlarms();
        }
    }

    updatePartialFuelStationsData(
        data: PoiModelMap[],
        resetBestPriceDuringCycle = false,
        redraw = false,
        selected = false
    ): void {
        const markers = this._fuelStationsCluster?.getMarkers();

        if (!markers) {
            return;
        }

        const markersMap: { [key: string]: PoiMarker } = {};

        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i] as unknown as PoiMarker;
            markersMap[marker.data().id] = marker;

            if (selected && data[0].selected) {
                marker.setData({
                    ...marker.data(),
                    selected: marker.data().id === data[0].id ? true : false
                });
            }

            if (resetBestPriceDuringCycle && marker.data().bestPrice) {
                marker.setData({ ...marker.data(), bestPrice: false });
            }
        }

        for (let i = 0; i < data.length; i++) {
            const update = data[i];
            const marker = markersMap[update.id];
            if (marker) {
                marker.setData({ ...marker.data(), ...update, selected: marker.data().selected });
            }
        }

        if (redraw) {
            this._fuelStationsCluster?.repaint();
        }
    }

    setParkings(data: PoiModelMap[][]): void {
        this._parkings = data;
        if (this._parkings && this._parkingsRender) {
            this._renderParkings();
        }
    }

    setFuelStationsRender(value: boolean): void {
        this._fuelStationsRender = value;
    }

    setParkingRender(value: boolean): void {
        this._parkingsRender = value;
    }

    updateFuelStation(data: PoiModelMap[][], fuelStation: PoiModelMap) {
        this._fuelStations = data;
        this._fuelStationsMarkers.find(marker => marker.id() === fuelStation.id)?.setData(fuelStation);
        this._renderFuelStations();
    }

    fuelStations(): PoiModelMap[][] {
        return this._fuelStations;
    }

    parkings(): PoiModelMap[][] {
        return this._parkings;
    }

    updateParking(data: PoiModelMap[][], parking: PoiModelMap) {
        this._parkings = data;
        this._parkingsMarkers.find(marker => marker.id() === parking.id)?.setData(parking);
        this._renderParkings();
    }

    hideParkings() {
        if (this._parkingsCluster) {
            this._parkings = this._parkings.map(p => p.map(pl => ({ ...pl, selected: false })));
            this._parkingsCluster.clearMarkers();
        }
    }

    showParkings() {
        if (this._map) {
            this._renderParkings();
        }
    }

    hideFuelStations() {
        if (this._fuelStationsCluster) {
            this._fuelStations = this._fuelStations.map(f => f.map(fs => ({ ...fs, selected: false })));
            this._fuelStationsCluster.clearMarkers();
        }
    }

    showFuelStations() {
        if (this._map) {
            this._renderFuelStations();
        }
    }
    haveRoutes(): boolean {
        return this._polylinesPlanned.length > 0 || this._polylinesReal.length > 0 || this._placeMarkers.length > 0;
    }

    /**
     * Fits route route on with included bounds of routes and markers
     */
    fitRoute(vehicle?: VehicleModelMap, maxZoom?: number) {
        const bounds = new this._google.maps.LatLngBounds();

        if (vehicle?.position?.lat && vehicle?.position?.lng) {
            const position = new this._google.maps.LatLng(vehicle.position.lat, vehicle.position.lng);
            if (position) {
                bounds.extend(position);
            }
        }

        if (this._vehicleMarker) {
            const position = this._vehicleMarker.getPosition();
            if (position) {
                bounds.extend(position);
            }
        }

        // All Markers (before route render)
        if (this._placeMarkers.length > 0) {
            this._placeMarkers.forEach(m => {
                if (m.getPosition()) {
                    bounds.extend(m.getPosition()!);
                }
            });
        }

        // All Routes
        // planed
        if (this._polylinesPlanned.length > 0) {
            this._polylinesPlanned.forEach(p => p.getPath().forEach(point => bounds.extend(point)));
        }
        // real
        if (this._polylinesReal.length > 0) {
            this._polylinesReal.forEach(p => p.getPath().forEach(point => bounds.extend(point)));
        }

        if (this._placeMarkers.length > 1) {
            this._map?.fitBounds(bounds, this._ctrl.getPadding());
        } else if (this._placeMarkers[0]) {
            const boundsWithDistance = this._ctrl.getCenterBoundsWithDistance(
                this._google,
                this._placeMarkers[0].getPosition()!
            );
            this._map?.fitBounds(boundsWithDistance, this._ctrl.getPadding());
        } else {
            this._conf.initBounds.forEach(place => {
                bounds.extend(place);
            });
            this._map?.fitBounds(bounds, this._ctrl.getPadding());
        }

        if (this._map && maxZoom) {
            this._google.maps.event.addListenerOnce(this._map, 'bounds_changed', () => {
                const zoom = this._map?.getZoom();
                if (zoom && zoom > maxZoom) {
                    this._map?.setZoom(maxZoom);
                    const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                    const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                    this._map?.panBy(x, y);
                }
            });
        }

        this._redrawFuelStationsAfterFitBounds = true;
        this._redrawParkingsAfterFitBounds = true;
        this._redrawPoisAfterFitBounds = true;
        this._redrawWashersAfterFitBounds = true;
    }

    renderTmpDraggingPointMarker(latLng: LatLng, route: string, markerBeforeMove?: boolean) {
        const decodedPolyline = this._google.maps.geometry.encoding.decodePath(route);
        if (markerBeforeMove) {
            const nearestPoint = this.nearestPoint(
                latLng,
                decodedPolyline?.map(p => [p.lng(), p.lat()])
            );
            this._markerBeforeMove = {
                lng: nearestPoint.geometry.coordinates[0],
                lat: nearestPoint.geometry.coordinates[1]
            };
        }
        if (this._map) {
            if (this._tmpWayPointMarker) {
                this._tmpWayPointMarker.setPosition(new this._google.maps.LatLng(latLng.lat, latLng.lng));
            } else {
                const crossingPlaceIcon = this._getPlaceIconSettings(0, 0, true);
                const marker = new MarkerWithLabel({
                    position: latLng,
                    zIndex: 1,
                    anchorPoint: new this._google.maps.Point(crossingPlaceIcon.anchorx, crossingPlaceIcon.anchorx),
                    icon: {
                        url: crossingPlaceIcon.url,
                        size: new this._google.maps.Size(crossingPlaceIcon.size[0], crossingPlaceIcon.size[1]),
                        anchor: new this._google.maps.Point(crossingPlaceIcon.anchorx, crossingPlaceIcon.anchorx)
                    },
                    draggable: true,
                    place_id: ''
                });

                const self = this;
                this._google.maps.event.addListener(marker, 'drag', function (e) {
                    self._onMarkerDrag?.(
                        marker,
                        { lat: e.latLng.lat(), lng: e.latLng.lng() },
                        'crossingPoint',
                        route,
                        self._markerBeforeMove
                    );
                });
                this._google.maps.event.addListener(marker, 'dragend', function (e) {
                    self._onMarkerDragEnd?.(
                        marker.get('place_id'),
                        {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng()
                        },
                        'crossingPoint'
                    );
                });

                this._tmpWayPointMarker = marker;
            }
            this._tmpWayPointMarker?.setMap(this._map);
        }
    }
    removeTmpWayPointMarker() {
        if (this._tmpWayPointMarker) {
            this._tmpWayPointMarker.setMap(null);
        }
        this._tmpWayPointMarker = undefined;
    }

    renderTmpDraggingRoute(encodedRoutes: string[]) {
        this._removePlannedPolylines();

        this._polylinesPlanned = encodedRoutes.map(route => {
            const polyline = new this._google.maps.Polyline({
                path: this._google.maps.geometry.encoding.decodePath(route),
                geodesic: true,
                strokeColor: polylineColor,
                strokeOpacity: 1.0,
                strokeWeight: 4
            });
            const self = this;
            this._google.maps.event.addListener(polyline, 'click', function (e) {
                self._onPolylineClick?.(route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            this._google.maps.event.addListener(polyline, 'onmouseover', function (e) {
                self._onPolylineMouseOver?.(route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            this._google.maps.event.addListener(polyline, 'mousemove', function (e) {
                self._onPolylineMouseMove?.(polyline, route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            this._google.maps.event.addListener(polyline, 'onmouseout', function (e) {
                self._onPolylineMouseOut?.(polyline, route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            return polyline;
        });

        this._renderPlannedPolylines();
    }

    removeMapObjects(objectsToRemove: {
        fuelStations?: boolean;
        parkings?: boolean;
        pois?: boolean;
        washers?: boolean;
        markers?: boolean;
        plannedPolylines?: boolean;
        removePolylinesReal?: boolean;
        vehicleMarker?: boolean;
    }) {
        objectsToRemove.fuelStations && this._removeFuelStations();
        objectsToRemove.parkings && this._removeParkings();
        objectsToRemove.pois && this._removePois();
        objectsToRemove.washers && this._removeWashers();
        objectsToRemove.markers && this._removePlaceMarkers();
        objectsToRemove.plannedPolylines && this._removePlannedPolylines();
        objectsToRemove.removePolylinesReal && this._removePolylinesReal();
        objectsToRemove.vehicleMarker && this._removeVehicleMarker();
    }

    renderRoute(
        places: {
            id: string;
            lat: number;
            lng: number;
            disabledDragging?: boolean;
            crossingPoints?: TransportCrossingPoint[];
        }[],
        encodedRoutes: { route: string; disabledDragging?: boolean }[],
        realRoutes: string[] = [],
        fitRoute: boolean = false,
        onlyPreview: boolean = false,
        vehicle?: VehicleModelMap,
        maxZoom?: number
    ): void {
        this._removeFuelStations();
        this._removeParkings();
        this._removeWashers();
        this._removePois();
        this._removePlaceMarkers();
        this._removePlannedPolylines();
        this._removeAlarms();
        this._removeAlarmInfoboxes();
        this._removePolylinesReal();

        // polyline to work with, it's wider, better access with mouse
        this._polylinesPlanned = encodedRoutes.map(route => {
            const polyline = new this._google.maps.Polyline({
                path: this._google.maps.geometry.encoding.decodePath(route.route),
                geodesic: true,
                strokeColor: '#07ADFA',
                strokeOpacity: 1.0,
                strokeWeight: 4
            });
            const self = this;
            if (!(onlyPreview || route.disabledDragging)) {
                this._google.maps.event.addListener(polyline, 'click', function (e) {
                    self._onPolylineClick?.(route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });

                this._google.maps.event.addListener(polyline, 'mouseover', function (e) {
                    self._onPolylineMouseOver?.(route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });

                this._google.maps.event.addListener(polyline, 'mousemove', function (e) {
                    self._onPolylineMouseMove?.(polyline, route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });

                this._google.maps.event.addListener(polyline, 'mouseout', function (e) {
                    self._onPolylineMouseOut?.(polyline, route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });
            }

            return polyline;
        });
        // polyline to show on map
        this._polylinesPlanned = [
            ...encodedRoutes.map(route => {
                const polyline = new this._google.maps.Polyline({
                    path: this._google.maps.geometry.encoding.decodePath(route.route),
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 4
                });

                const self = this;
                if (!(onlyPreview || route.disabledDragging)) {
                    this._google.maps.event.addListener(polyline, 'click', function (e) {
                        self._onPolylineClick?.(route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                    });

                    this._google.maps.event.addListener(polyline, 'mouseover', function (e) {
                        self._onPolylineMouseOver?.(route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                    });

                    this._google.maps.event.addListener(polyline, 'mousemove', function (e) {
                        self._onPolylineMouseMove?.(polyline, route.route, {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng()
                        });
                    });

                    this._google.maps.event.addListener(polyline, 'mouseout', function (e) {
                        self._onPolylineMouseOut?.(polyline, route.route, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                    });
                }

                return polyline;
            }),
            ...this._polylinesPlanned
        ];

        this._polylinesReal = realRoutes.map(route => {
            return new this._google.maps.Polyline({
                path: this._google.maps.geometry.encoding.decodePath(route),
                geodesic: true,
                strokeColor: '#222',
                strokeOpacity: 1.0,
                strokeWeight: 4
            });
        });
        this._crossingPlaceMarkers = [];

        this._placeMarkers = places.map((place, index) => {
            const self = this;
            const placeIcon = this._getPlaceIconSettings(index, places.length);
            const route = encodedRoutes[index];

            const marker = new MarkerWithLabel({
                position: place,
                anchorPoint: new this._google.maps.Point(placeIcon.anchorx, placeIcon.anchorx),
                icon: {
                    url: placeIcon.url,
                    size: new this._google.maps.Size(placeIcon.size[0], placeIcon.size[1]),
                    anchor: new this._google.maps.Point(placeIcon.anchorx, placeIcon.anchorx)
                },
                place_id: place.id,
                draggable: !(onlyPreview || place.disabledDragging)
            });

            marker.addListener('contextmenu', () => localStorage.setItem('map-context-disabled', '1'));

            if (place.crossingPoints) {
                const crossingPlaceMarkers: google.maps.Marker[] = place.crossingPoints?.map((crossingPoint, index) => {
                    const self = this;
                    const crossingPlaceIcon = this._getPlaceIconSettings(0, 0, true);
                    const crossingPlaceId: string = place.id + '__' + index;
                    const crossingPlaceMarker = new MarkerWithLabel({
                        position: { lat: crossingPoint.lat, lng: crossingPoint.lon },
                        anchorPoint: new this._google.maps.Point(crossingPlaceIcon.anchorx, crossingPlaceIcon.anchorx),
                        icon: {
                            url: crossingPlaceIcon.url,
                            size: new this._google.maps.Size(crossingPlaceIcon.size[0], crossingPlaceIcon.size[1]),
                            anchor: new this._google.maps.Point(crossingPlaceIcon.anchorx, crossingPlaceIcon.anchorx)
                        },
                        place_id: place.id,
                        crossingpoint_id: crossingPlaceId,
                        draggable: !(onlyPreview || route?.disabledDragging)
                    });

                    if (!(onlyPreview || route?.disabledDragging)) {
                        this._google.maps.event.addListener(crossingPlaceMarker, 'dragend', function (e) {
                            self._onMarkerDragEnd?.(
                                crossingPlaceId,
                                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                                'crossingPoint'
                            );
                        });
                        this._google.maps.event.addListener(crossingPlaceMarker, 'drag', function (e) {
                            self._onMarkerDrag?.(
                                crossingPlaceMarker,
                                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                                'crossingPoint'
                            );
                        });
                        this._google.maps.event.addListener(crossingPlaceMarker, 'dblclick', function (e) {
                            self._onCrossingPlaceMarkerDelete?.(crossingPlaceId, {
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng()
                            });
                        });
                        this._google.maps.event.addListener(crossingPlaceMarker, 'mouseover', function (e) {
                            self._onMarkerMouseover?.(crossingPlaceId, {
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng()
                            });
                        });
                        this._google.maps.event.addListener(crossingPlaceMarker, 'mouseout', function (e) {
                            self._onMarkerMouseout?.(crossingPlaceId, {
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng()
                            });
                        });
                    }

                    return crossingPlaceMarker;
                });
                this._crossingPlaceMarkers = [...this._crossingPlaceMarkers, ...crossingPlaceMarkers];
            }

            if (!(onlyPreview || place.disabledDragging)) {
                this._google.maps.event.addListener(marker, 'dragend', function (e) {
                    self._onMarkerDragEnd?.(place.id, { lat: e.latLng.lat(), lng: e.latLng.lng() }, 'waypoint');
                });
                this._google.maps.event.addListener(marker, 'drag', function (e) {
                    self._onMarkerDrag?.(marker, { lat: e.latLng.lat(), lng: e.latLng.lng() }, 'waypoint');
                });
                this._google.maps.event.addListener(marker, 'mouseover', function (e) {
                    self._onMarkerMouseover?.(place.id, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });
                this._google.maps.event.addListener(marker, 'mouseout', function (e) {
                    self._onMarkerMouseout?.(place.id, { lat: e.latLng.lat(), lng: e.latLng.lng() });
                });
            }

            return marker;
        });

        this._renderMarkers();
        this._renderPlannedPolylines();
        this._renderPolylinesReal();

        fitRoute && this.fitRoute(vehicle, maxZoom);
    }

    renderJourneysActivityRoutes(
        places: { lat: number; lng: number }[][],
        routes: string[],
        closed: boolean,
        alarms?: Alarm[]
    ): void {
        this._removePlaceMarkers();
        this.removeAlarmMarkers();
        this._removeAlarmInfoboxes();
        this._removePolylinesReal();

        this._polylinesReal = this.renderJourneyRoute(routes);
        this.setThisAlarms(alarms);

        places.forEach((place, index) => {
            place.forEach((place, placeIndex) => {
                if (index + placeIndex === places.length && !closed) {
                    const angle = this._google.maps.geometry.spherical.computeHeading(
                        new this._google.maps.LatLng(
                            places[places.length > 1 ? places.length - 2 : 0]?.[0]?.lat,
                            places[places.length > 1 ? places.length - 2 : 0]?.[0]?.lng
                        ),
                        new this._google.maps.LatLng(
                            places?.[places.length - 1]?.[1]?.lat,
                            places?.[places.length - 1]?.[1]?.lng
                        )
                    );
                    const content = document.createElement('img');
                    content.src = PlacesIcons.Unclosed;

                    this._placeMarkers.push(
                        new MarkerWithLabel({
                            position: place,
                            icon: {
                                url: PlacesIcons.Unclosed,
                                size: new this._google.maps.Size(66, 66),
                                anchor: new this._google.maps.Point(33, 33)
                            },
                            anchorPoint: new this._google.maps.Point(33, 33),
                            labelAnchor: new this._google.maps.Point(33, 33),
                            labelClass: 'map-vehicle',
                            labelContent: content,
                            labelStyle: {
                                transform: `rotate(${angle}deg)`
                            },
                            zIndex: MAP_MARKERS_INDEX.VEHICLE
                        })
                    );
                } else {
                    const placeIcon = this._getPlaceIconSettings(index + placeIndex, places.length + 1);
                    this._placeMarkers.push(
                        new this._google.maps.Marker({
                            position: place,
                            anchorPoint: new this._google.maps.Point(placeIcon.anchorx, placeIcon.anchorx),
                            icon: {
                                url: placeIcon.url,
                                size: new this._google.maps.Size(placeIcon.size[0], placeIcon.size[1]),
                                anchor: new this._google.maps.Point(placeIcon.anchorx, placeIcon.anchorx)
                            }
                        })
                    );
                }
            });
        });
        const driverAlarmsToRender = this._alarmsControlsActive.driverBehaviorAlarmTypes
            ? driverBehaviorAlarmTypes
            : [];
        const instantAlarmsToRender = this._alarmsControlsActive.instantAlarmTypes ? instantAlarmTypes : [];
        const geoAlarmsToRender = this._alarmsControlsActive.geoAlarmTypes ? geoAlarmTypes : [];

        this._renderMarkers();
        this._renderAlarms([...driverAlarmsToRender, ...instantAlarmsToRender, ...geoAlarmsToRender]);
        this._renderPolylinesReal();
        this._fitPolylines(MAX_ZOOM);
    }

    renderJourneyGraphMarker(lat: number, lng: number, section?: GraphKey) {
        if (this._map) {
            if (this._graphPointMarker && section === this._journeysGraphSection) {
                this._graphPointMarker.setPosition(new this._google.maps.LatLng(lat, lng));
            } else {
                this.removeJourneyGraphMarker();
                const graphPointMarker = this._getPlaceIconSettings(0, 0, true, section);
                const marker = new MarkerWithLabel({
                    position: {
                        lat,
                        lng
                    },
                    zIndex: 1,
                    anchorPoint: new this._google.maps.Point(graphPointMarker.anchorx, graphPointMarker.anchorx),
                    icon: {
                        url: graphPointMarker.url,
                        size: new this._google.maps.Size(graphPointMarker.size[0], graphPointMarker.size[1]),
                        anchor: new this._google.maps.Point(graphPointMarker.anchorx, graphPointMarker.anchorx)
                    },
                    draggable: false
                });

                this._graphPointMarker = marker;
            }
            this._journeysGraphSection = section;
            this._graphPointMarker?.setMap(this._map);
        }
    }
    removeJourneyGraphMarker() {
        if (this._graphPointMarker) {
            this._graphPointMarker.setMap(null);
        }
        this._graphPointMarker = undefined;
    }

    setThisAlarms(alarms?: Alarm[]) {
        this._alarms = {};
        if (alarms) {
            alarms.forEach((alarm, index) => {
                const key = Object.keys(AlarmType)[
                    Object.values(AlarmType).indexOf(alarm.alarmType)
                ] as keyof AlarmsType;
                let alarmsPoiData = this._alarms[key as keyof AlarmsType];
                if (alarmsPoiData) {
                    alarmsPoiData.push({
                        id: `${key}-${index}`,
                        name: alarm.alarmType,
                        date: alarm.startDateTime,
                        detailAddress: alarm.localizedAddress,
                        position: {
                            lat: alarm.lastGpsPointStartObj?.lat ?? 0,
                            lng: alarm.lastGpsPointStartObj?.lng ?? 0
                        },
                        alarmType: alarm.alarmType,
                        acknowledged: alarm.acknowledged,
                        selected: false
                    });
                } else {
                    alarmsPoiData = [
                        {
                            id: `${key}-${index}`,
                            name: alarm.alarmType,
                            date: alarm.startDateTime,
                            detailAddress: alarm.localizedAddress,
                            position: {
                                lat: alarm.lastGpsPointStartObj?.lat ?? 0,
                                lng: alarm.lastGpsPointStartObj?.lng ?? 0
                            },
                            alarmType: alarm.alarmType,
                            acknowledged: alarm.acknowledged,
                            selected: false
                        }
                    ];
                }
                this._alarms[key] = alarmsPoiData;
            });
        }
    }

    renderPointInfobox(location: LatLng, data: PointInfo & { addressString: string }) {
        const infoWindow = new this._google.maps.InfoWindow({
            content: renderToString(<PointInfobox data={data} />),
            pixelOffset: new this._google.maps.Size(125, 0),
            position: location,
            disableAutoPan: true
        });
        infoWindow.set('closed', false);
        infoWindow.set('id', `${data.lat}${data.lon}`);
        this._pointInfobox = infoWindow;
        this._pointInfobox.open({
            map: this._map,
            shouldFocus: false
        } as google.maps.InfoWindowOpenOptions);
        return this._pointInfobox;
    }

    destroyPointInfobox() {
        this._pointInfobox?.close();
        this._pointInfobox = undefined;
    }

    renderJourneyRoute(routes: string[]) {
        return routes.map(line => {
            const polyline = new this._google.maps.Polyline({
                path: this._google.maps.geometry.encoding.decodePath(line),
                geodesic: true,
                strokeColor: '#07ADFA',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                zIndex: 100
            });

            const clickHandler = (polyline: string): void => {
                if (this._selectedRouteId !== polyline) {
                    this._onRouteClick?.(line);

                    this.selectPolylineReal(line);
                } else {
                    this._onRouteClick?.(undefined);

                    this.unselectPolylinesReal();
                }
            };

            polyline.addListener('click', () => clickHandler(polyline.get('id')));
            polyline.set('id', line);

            this._google.maps.event.addListener(polyline, 'mouseover', e => {
                this._onPolylineMouseOver?.(line, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            this._google.maps.event.addListener(polyline, 'mousemove', e => {
                this._onPolylineMouseMove?.(polyline, line, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            this._google.maps.event.addListener(polyline, 'mouseout', e => {
                this._onPolylineMouseOut?.(polyline, line, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            });

            return polyline;
        });
    }

    renderJourneyControls() {
        this._journeysControls = [
            this._createDriverBehaviorAlarmControl(),
            this._createGeoAlarmControl(),
            this._createInstantAlarmControl(),
            this._createJourneyGraphControl()
        ];
        this._journeysControls.forEach(control => {
            control && this._map?.controls[this._google.maps.ControlPosition.TOP_CENTER].push(control);
        });
    }

    removeJourneyControls() {
        this._journeysControls.forEach(control => {
            const index = this._map!.controls[this._google.maps.ControlPosition.TOP_CENTER].getArray().indexOf(control);
            control && this._map?.controls[this._google.maps.ControlPosition.TOP_CENTER].removeAt(index);
        });

        this._journeysControls = [];
    }

    renderAlarms(alarms?: Alarm[], types?: (keyof AlarmClustersType)[]) {
        this.setThisAlarms(alarms);
        if (alarms) {
            if (types?.some(type => geoAlarmTypes.includes(type))) {
                this._alarmsControlsActive.geoAlarmTypes = true;
            }
            if (types?.some(type => instantAlarmTypes.includes(type))) {
                this._alarmsControlsActive.instantAlarmTypes = true;
            }
            if (types?.some(type => driverBehaviorAlarmTypes.includes(type))) {
                this._alarmsControlsActive.driverBehaviorAlarmTypes = true;
            }

            this._renderAlarms(types);
        }
    }

    removeAlarmMarkers() {
        Object.entries(this._alarmClusters).forEach(([_clusterKey, cluster]) => {
            cluster?.clearMarkers();
            cluster?.repaint();

            if (this._map) {
                cluster?.setMap(null);
            }
        });

        this._alarmClusters = {
            BatteryLow: undefined,
            ConnectionLoss: undefined,
            CorridorLeave: undefined,
            MonitoringDeviceSilent: undefined,
            PoiArrival: undefined,
            PoiClose: undefined,
            PoiDeparture: undefined,
            UnavailableGps: undefined,

            AggresiveTakeoff: undefined,
            DangerousBreakingCity: undefined,
            DangerousBreakingCountry: undefined,
            DangerousBreakingHighway: undefined,
            DangerousTurnCountry: undefined,
            DangerousTurnHighway: undefined,
            Overspeed: undefined,

            PoiEntry: undefined,
            PoiExit: undefined,

            PuescSentGeoStale: undefined,
            PuescSentGeoError: undefined,
            DeadMansSwitch: undefined,
            TransportColdChainProfileTemperatureHigh: undefined,
            TransportColdChainProfileTemperatureLow: undefined
        };
        this._alarmMarkers = {};
        this._alarms = {};
    }

    selectPolylineReal(id: string) {
        this._unselectPolylines();
        this._selectedRouteId = id;
        this._ctrl.setPadding({
            ...this._ctrl.getPadding(),
            bottom: this._ctrl.journeyGraphVisible
                ? this._ctrl.initialPaddingWithBottomComponent.bottom
                : this._ctrl.initialPadding.bottom
        });
        const polyline = this._polylinesReal.find(p => p.get('id') === id);
        if (polyline) {
            this._fitPolyline(polyline, MAX_ZOOM);
            polyline?.setOptions({
                strokeColor: '#222',
                strokeOpacity: 1.0,
                strokeWeight: 8,
                zIndex: 200
            });
        } else {
            this._selectedRouteId = undefined;
            this._fitPolylines(MAX_ZOOM);
        }
    }

    unselectPolylinesReal() {
        this._unselectPolylines();
        this._ctrl.setPadding({ ...this._ctrl.getPadding(), bottom: this._ctrl.initialPadding.bottom });
        this._fitPolylines(MAX_ZOOM);
    }

    setVehicle(vehicle?: VehicleModelMap) {
        this._removeVehicleMarker();
        this.vehicle = vehicle;
        if (this.vehicle?.position?.lat && this.vehicle?.position?.lng) {
            this._vehicleMarker = this._createVehicleMarker(this.vehicle);
            if (this._map) {
                this._vehicleMarker.setMap(this._map);
            }
        }
    }

    updateVehicle(vehicle: VehicleModelMap) {
        if (vehicle?.position?.lat && vehicle?.position?.lng) {
            this.vehicle = vehicle;
            if (this._vehicleMarker) {
                this._vehicleMarker.setPosition({
                    lat: vehicle.position.lat,
                    lng: vehicle.position.lng
                });
            }
        }
    }
    setHasRoleJAA(hasRole: boolean) {
        this._hasRoleJAA = hasRole;
    }
    setHasRoleDBH_IC_JA(hasRole: boolean) {
        this._hasRoleDBH_IC_JA = hasRole;
    }

    setWashers(data: PoiModelMap[][]): void {
        this._washers = data;
        if (this._washers && this._washersRender) {
            this._renderWashers();
        }
    }

    showWashers() {
        if (this._map) {
            this._renderWashers();
        }
    }

    hideWashers() {
        if (this._washersCluster) {
            this._washers = this._washers.map(p => p.map(pl => ({ ...pl, selected: false })));
            this._washersCluster.clearMarkers();
        }
    }

    setWashRender(value: boolean): void {
        this._washersRender = value;
    }

    washers(): PoiModelMap[][] {
        return this._washers;
    }

    updateWash(data: PoiModelMap[][], washer: PoiModelMap) {
        this._washers = data;
        this._washersMarkers.find(marker => marker.id() === washer.id)?.setData(washer);
        this._renderWashers();
    }

    private _removeWashers() {
        this._washersCluster?.clearMarkers();
        this._washersCluster?.repaint();
        if (this._map) {
            this._washersCluster?.setMap(null);
        }
        this._washersMarkers = [];
        this._washers = [];
    }

    private _renderWashers() {
        this._washersCluster?.clearMarkers();
        this._washersCluster?.setMap(null);
        const self = this;
        const data =
            this._washers && this._polylinesPlanned && this._placeMarkers
                ? this._poisNearestToPolylineData(this._washers, this._polylinesPlanned, this._placeMarkers)
                : [];
        function clusterClickHandler(cluster: MarkerClusterer) {
            const bounds = new self._google.maps.LatLngBounds();

            cluster.getMarkers().forEach(marker => {
                bounds.extend(marker.getPosition()!);
            });
            self._map?.fitBounds(bounds, self._ctrl.getPadding());
        }

        this._washersMarkers = data.map(e => {
            const washData: PoiMarkerData = {
                type: 'wash',
                data: {
                    ...e.poi,
                    routeIndex: e.routeIndex
                },
                distance: e.meta.distance
            };

            const marker = new PoiMarker(
                this._google,
                new this._google.maps.LatLng(e!.meta.coords[0], e!.meta.coords[1]),
                washData
            );

            marker.onPoiClick(poiData => {
                if (poiData.type === 'wash') {
                    this._onWashClick?.(poiData.data);
                }
            });

            return marker;
        });

        this._washersCluster = new MarkerClusterer(this._map!, this._washersMarkers as any, {
            gridSize: 90,
            averageCenter: true,
            zoomOnClick: false,
            clusterClass: 'wash-cluster',
            styles: [
                {
                    url: WashIcons.Icon,
                    height: 49,
                    width: 64
                }
            ]
        });

        // fitBounds not working properly with clusterer.
        // events in google map can finish before updating clusterer
        this.idlingEventForClustererWashers = this._map?.addListener('idle', () => {
            if (this._redrawWashersAfterFitBounds) {
                this._washersCluster?.repaint();
                this._redrawWashersAfterFitBounds = false;
            }
        });
        this._washersCluster!.addListener('clusterclick', clusterClickHandler);
    }

    setPois(data: PoiModelMap[][]): void {
        this._pois = data;
        if (this._pois && this._poisRender) {
            this._renderPois();
        }
    }

    showPois() {
        if (this._map) {
            this._renderPois();
        }
    }

    hidePois() {
        if (this._poisCluster) {
            this._pois = this._pois.map(p => p.map(pl => ({ ...pl, selected: false })));
            this._poisCluster.clearMarkers();
        }
    }

    setPoiRender(value: boolean): void {
        this._poisRender = value;
    }

    pois(): PoiModelMap[][] {
        return this._pois;
    }

    updatePoi(data: PoiModelMap[][], poi: PoiModelMap) {
        this._pois = data;
        this._poisMarkers.find(marker => marker.id() === poi.id)?.setData(poi);
        this._renderPois();
    }

    private _removePois() {
        this._poisCluster?.clearMarkers();
        this._poisCluster?.repaint();
        if (this._map) {
            this._poisCluster?.setMap(null);
        }
        this._poisMarkers = [];
        this._pois = [];
    }

    private _renderPois() {
        this._poisCluster?.clearMarkers();
        this._poisCluster?.setMap(null);
        const self = this;
        const data =
            this._pois && this._polylinesPlanned && this._placeMarkers
                ? this._poisNearestToPolylineData(this._pois, this._polylinesPlanned, this._placeMarkers)
                : [];
        function clusterClickHandler(cluster: MarkerClusterer) {
            const bounds = new self._google.maps.LatLngBounds();
            cluster.getMarkers().forEach(marker => {
                bounds.extend(marker.getPosition()!);
            });
            self._map?.fitBounds(bounds, self._ctrl.getPadding());
        }

        this._poisMarkers = data.map(e => {
            const poiData: PoiMarkerData = {
                type: 'poi',
                data: {
                    ...e.poi,
                    routeIndex: e.routeIndex
                },
                distance: e.meta.distance
            };

            const marker = new PoiMarker(
                this._google,
                new this._google.maps.LatLng(e!.meta.coords[0], e!.meta.coords[1]),
                poiData
            );

            marker.onPoiClick(poiData => {
                if (poiData.type === 'poi') {
                    this._onPoiClick?.(poiData.data);
                }
            });

            return marker;
        });

        this._poisCluster = new MarkerClusterer(this._map!, this._poisMarkers as any, {
            gridSize: 90,
            averageCenter: true,
            zoomOnClick: false,
            clusterClass: 'poi-cluster',
            styles: [
                {
                    url: PoiIcons.Icon,
                    height: 46,
                    width: 64
                }
            ]
        });

        // fitBounds not working properly with clusterer.
        // events in google map can finish before updating clusterer
        this.idlingEventForClustererPois = this._map?.addListener('idle', () => {
            if (this._redrawPoisAfterFitBounds) {
                this._poisCluster?.repaint();
                this._redrawPoisAfterFitBounds = false;
            }
        });
        this._poisCluster!.addListener('clusterclick', clusterClickHandler);
    }

    /**
     * Method returns points that fall within (Multi)Polygon(s) created from polyline based on turf's pointsWithinPolygon
     * @external https://turfjs.org/docs/#pointsWithinPolygon
     */

    polylineInPoland(polyline: string): boolean {
        const line = decode(polyline).map(e => [e[1], e[0]]);
        const polygon = turf.polygon([this.polandPolygon.map(point => [point.lng, point.lat])]);
        return booleanIntersects(turf.lineString(line), polygon);
    }

    pointInPoland(latLng: LatLng) {
        const polygon = turf.polygon([this.polandPolygon.map(point => [point.lng, point.lat])]);
        const ptsWithin = turf.pointsWithinPolygon(turf.points([[latLng.lng ?? -1, latLng.lat ?? -1]]), polygon);
        return ptsWithin.features.length > 0;
    }

    getPointsPolandCrossing(polyline: string) {
        const line = decode(polyline).map(e => [e[1], e[0]]);
        const polygon = turf.polygon([this.polandPolygon.map(point => [point.lng, point.lat])]);
        return turf.lineIntersect(turf.lineString(line), polygon);
    }

    nearestPoint(latLng: LatLng, collection: [number, number][]) {
        const targetPoint = turf.point([latLng.lng, latLng.lat]);
        const points = turf.featureCollection(collection.map(coordinate => turf.point(coordinate)));
        return turf.nearestPoint(targetPoint, points);
    }

    pointsAlongPolyline(polyline: string, pois: PoiModelMap[]): PoiModelMap[] {
        const points = turf.points(pois.map(e => [e.position.lng, e.position.lat]));
        const decoded = decode(polyline);

        // Making polyline simpler
        const polylineFiltrationRate = Math.max(1, Math.floor(decoded.length / 100));
        const filteredPolyline = decoded.filter((data, index) => {
            return index % polylineFiltrationRate === 0;
        });

        // Turf conversion & Calculating points within polygon
        const reEncodedPolyline = encode(filteredPolyline);
        const geo = turf.buffer(toGeoJSON(reEncodedPolyline), 20000, { units: 'meters' });
        const within = turf.pointsWithinPolygon(points, geo);

        const filteredPoints = pois.filter(p =>
            within.features.some(
                geoPoint =>
                    geoPoint.geometry?.coordinates[0] === p.position.lng &&
                    geoPoint.geometry?.coordinates[1] === p.position.lat
            )
        );

        return filteredPoints;
    }

    /**
     * Method returns calculated buffered polygon (turf feature) around polyline
     * @external http://turfjs.org/docs/#buffer
     */
    polygonAlongPolyline(polyline: string): turf.helpers.Feature {
        const decoded = decode(polyline);

        // Making polyline simpler
        const polylineFiltrationRate = Math.max(1, Math.floor(decoded.length / 400));
        const filteredPolyline = decoded.filter((data, index) => {
            return index % polylineFiltrationRate === 0;
        });

        // Turf conversion & Calculating points within polygon
        const reEncodedPolyline = encode(filteredPolyline);
        return turf.buffer(toGeoJSON(reEncodedPolyline), 20000, { units: 'meters' });
    }

    /**
     * Method fits markers & polylines currently set in routing
     */
    private _fitPolylines(maxZoom?: number): void {
        const bounds = new this._google.maps.LatLngBounds();

        // All Markers (before route render)
        if (this._placeMarkers.length > 0) {
            this._placeMarkers.forEach(m => {
                if (m.getPosition()) {
                    bounds.extend(m.getPosition()!);
                }
            });
        }
        // All Routes
        if (this._polylinesReal.length > 0) {
            this._polylinesReal.forEach(p => p.getPath().forEach(point => bounds.extend(point)));
        }

        if (this._map) {
            this._map?.setOptions({ maxZoom });
            if (this._placeMarkers.length > 0 || this._polylinesReal.length > 0) {
                this._map?.fitBounds(bounds, this._ctrl.getPadding());
            }
            const zoomAfterFit = this._map?.getZoom();
            if (maxZoom && zoomAfterFit && zoomAfterFit >= maxZoom) {
                // Refit bounds for zoomed "short polylines"
                if (this._placeMarkers.length > 0 || this._polylinesReal.length > 0) {
                    this._map?.fitBounds(bounds, this._ctrl.initialPadding);
                }
                const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                this._map?.panBy(x, y);
            }
            this._map?.setOptions({
                maxZoom: this._ctrl.getDefaultZoom().max
            });
        }
    }

    private _fitPolyline(polyline: google.maps.Polyline, maxZoom?: number) {
        const bounds = new this._google.maps.LatLngBounds();
        polyline.getPath().forEach(point => bounds.extend(point));

        if (this._map) {
            this._map?.setOptions({ maxZoom });
            this._map?.fitBounds(bounds, this._ctrl.getPadding());
            const zoomAfterFit = this._map?.getZoom();
            if (maxZoom && zoomAfterFit && zoomAfterFit >= maxZoom) {
                // Refit bounds for zoomed "short polylines"
                this._map?.fitBounds(bounds, this._ctrl.initialPadding);

                const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                this._map?.panBy(x, y);
            }
            this._map?.setOptions({
                maxZoom: this._ctrl.getDefaultZoom().max
            });
        }
    }

    private _unselectPolylines(): void {
        this._selectedRouteId = undefined;
        this._polylinesReal.forEach(polyline =>
            polyline.setOptions({
                strokeColor: '#07ADFA',
                strokeOpacity: 1.0,
                strokeWeight: 6,
                zIndex: 100
            })
        );
    }

    private _createVehicleMarker(vehicle: VehicleModelMap): google.maps.Marker {
        const content = document.createElement('img');
        content.src = this._getVehicleIcon(vehicle);
        // we need to set transform only for running vehicle, not idling or stationary
        content.style.transform =
            vehicle.alarms.length === 0 && vehicle.status === 1 ? `rotate(${vehicle.angle}deg)` : '';

        const marker = new MarkerWithLabel({
            position: vehicle.position,
            icon: {
                url: this._getVehicleIcon(vehicle),
                size: new this._google.maps.Size(66, 66),
                anchor: new this._google.maps.Point(33, 33)
            },
            clickable: true,
            anchorPoint: new this._google.maps.Point(33, 33),
            labelContent: content,
            labelAnchor: new this._google.maps.Point(33, 33),
            labelClass: cn('map-vehicle', {
                'map-vehicle-ev': vehicle.fuelType === EFuelType.Electro,
                'map-vehicle-ev-selected': vehicle.selected
            }),
            labelStyle: {
                opacity: 1
            }
        });

        marker.set('vehicle', vehicle);

        return marker;
    }

    private _removeVehicleMarker() {
        if (this._map) {
            this._vehicleMarker?.setMap(null);
        }
        this._vehicleMarker = undefined;
    }

    private _getVehicleIcon(vehicle: VehicleModelMap): VehicleMarkerIcon {
        switch (vehicle.status) {
            case 0:
                return vehicle.selected ? VehicleMarkerIcon.StationarySelected : VehicleMarkerIcon.Stationary;
            case 1:
                return vehicle.selected ? VehicleMarkerIcon.MovingSelected : VehicleMarkerIcon.Moving;
            case 2:
                return vehicle.selected ? VehicleMarkerIcon.IdlingSelected : VehicleMarkerIcon.Idling;
            // Vehicle status not found
            default:
                return VehicleMarkerIcon.Stationary;
        }
    }

    private _getAlarmIcon(alarmType: AlarmType) {
        switch (alarmType) {
            case AlarmType.UnavailableGps:
                return AlarmIcons.noGps;
            case AlarmType.ConnectionLoss:
                return AlarmIcons.noConnection;
            case AlarmType.BatteryLow:
                return AlarmIcons.lowBattery;
            case AlarmType.CorridorLeave:
                return AlarmIcons.outsideCorridor;
            case AlarmType.PoiClose:
                return AlarmIcons.poiClose;
            case AlarmType.PoiArrival:
                return AlarmIcons.poiArrival;
            case AlarmType.PoiDeparture:
                return AlarmIcons.poiDeparture;
            case AlarmType.AggresiveTakeoff:
                return AlarmIcons.acceleration;
            case AlarmType.DangerousTurnCountry:
                return AlarmIcons.turn1;
            case AlarmType.DangerousTurnHighway:
                return AlarmIcons.turn2;
            case AlarmType.DangerousBreakingCity:
                return AlarmIcons.breaking1;
            case AlarmType.DangerousBreakingCountry:
                return AlarmIcons.breaking2;
            case AlarmType.DangerousBreakingHighway:
                return AlarmIcons.breaking3;
            case AlarmType.Overspeed:
                return AlarmIcons.speed;
            default:
                return '';
        }
    }
    private _getPlaceIconSettings(index: number, total: number, crossingPoint: boolean = false, section?: GraphKey) {
        if (section === GraphKey.speeds) {
            return {
                url: plannerIcons.SpeedPlace,
                size: [20, 20],
                anchorx: 10,
                anchory: 10
            };
        } else if (section === GraphKey.fuelLevels) {
            return {
                url: plannerIcons.FuelLevelPlace,
                size: [20, 20],
                anchorx: 10,
                anchory: 10
            };
        } else if (crossingPoint) {
            return {
                url: plannerIcons.CrossingPlace,
                size: [20, 20],
                anchorx: 10,
                anchory: 10
            };
        } else if (index === 0) {
            return {
                url: plannerIcons.StartPoint,
                size: [24, 24],
                anchorx: 12,
                anchory: 12
            };
        } else if (index === total - 1) {
            return {
                url: plannerIcons.FinalPoint,
                size: [29, 25],
                anchorx: 12.5,
                anchory: 58
            };
        } else {
            return {
                url: plannerIcons.MiddlePoint,
                size: [20, 20],
                anchorx: 10,
                anchory: 10
            };
        }
    }

    private _renderPlannedPolylines() {
        this._polylinesPlanned.forEach(p => {
            if (this._map) {
                p.setMap(this._map);
            }
        });
    }

    private _renderPolylinesReal() {
        this._polylinesReal.forEach(p => {
            if (this._map) {
                p.setMap(this._map);
            }
        });
    }

    private _renderMarkers() {
        this._placeMarkers.forEach(m => {
            if (this._map) {
                m.setMap(this._map);
            }
        });
        this._crossingPlaceMarkers.forEach(cpm => {
            if (this._map) {
                cpm.setMap(this._map);
            }
        });
    }

    private _removePlannedPolylines() {
        this._polylinesPlanned.forEach(p => {
            if (this._map) {
                p.setMap(null);
            }
        });
        this._polylinesPlanned = [];
    }

    private _removePolylinesReal() {
        this._selectedRouteId = undefined;
        this._polylinesReal.forEach(p => {
            if (this._map) {
                p.setMap(null);
            }
        });
        this._polylinesReal = [];
    }

    private _removePlaceMarkers() {
        this._placeMarkers.forEach(m => {
            if (this._map) {
                m.setMap(null);
            }
        });
        this._crossingPlaceMarkers.forEach(m => {
            if (this._map) {
                m.setMap(null);
            }
        });
        this._placeMarkers = [];
        this._crossingPlaceMarkers = [];
    }

    private _removeAlarms() {
        Object.keys(this._alarmClusters).forEach(alarmType => {
            this._alarmClusters[alarmType]?.clearMarkers();
            this._alarmClusters[alarmType]?.repaint();
            if (this._map) {
                this._alarmClusters[alarmType]?.setMap(null);
            }

            this._alarmClusters[alarmType] = undefined;
        });
    }

    private _removeAlarmInfoboxesByType(types: (keyof AlarmClustersType)[]) {
        Object.keys(this._alarmsInfoBoxes).forEach(key => {
            const alarmsToBeRemoved = Object.keys(AlarmType)
                .filter(k => types.includes(k as keyof AlarmClustersType))
                .map(k => AlarmType[k]);
            if (alarmsToBeRemoved.includes(this._alarmsInfoBoxes[key].alarmType)) {
                this._alarmsInfoBoxes[key].infoWindow.close();
                delete this._alarmsInfoBoxes[key];
            }
        });
    }

    private _hideAlarmMarkersByType(types: (keyof AlarmClustersType)[]) {
        types.forEach(alarmType => {
            this._alarmClusters[alarmType]?.clearMarkers();
            this._alarmClusters[alarmType]?.repaint();
            if (this._map) {
                this._alarmClusters[alarmType]?.setMap(null);
            }

            this._alarmClusters[alarmType] = undefined;
        });
    }

    private _poisNearestToPolylineData = (
        pois: PoiModelMap[][],
        polylines: google.maps.Polyline[],
        markers: google.maps.Marker[]
    ): PoiModel[] => {
        const data: PoiModel[][] = polylines.map((_polyline, routeIndex) => {
            return (pois[routeIndex] || []).map<PoiModel>(poi => {
                return {
                    poi,
                    routeIndex,
                    meta: {
                        distance: 0,
                        coords: [poi.position.lat, poi.position.lng]
                    }
                };
            });
        });

        const reducedData: PoiModel[] = data.flat().reduce<PoiModel[]>((a, c) => {
            if (
                // unique pois
                !a.some(e => e.poi?.id === c.poi?.id) &&
                // pois not in transport
                !markers.some(
                    m =>
                        m.getPosition()?.lat() === c.poi?.position?.lat &&
                        m.getPosition()?.lng() === c.poi?.position?.lng
                )
            ) {
                a.push(c);
            }
            return a;
        }, []);

        return reducedData;
    };

    private _renderFuelStations(): void {
        this._fuelStationsCluster?.clearMarkers();
        if (this._map) {
            this._fuelStationsCluster?.setMap(null);
        }
        const self = this;
        const data =
            this._fuelStations && this._polylinesPlanned && this._placeMarkers
                ? this._poisNearestToPolylineData(this._fuelStations, this._polylinesPlanned, this._placeMarkers)
                : [];

        this._fuelStationsMarkers = data.map<PoiMarker>(e => {
            const poiData: PoiMarkerData = {
                type: 'fuelStation',
                data: {
                    ...e.poi,
                    routeIndex: e.routeIndex
                },
                distance: e.meta.distance
            };

            // TODO: We dont't need this poimarker anymore
            const marker = new PoiMarker(
                this._google,
                new this._google.maps.LatLng(e.meta.coords[0], e.meta.coords[1]),
                poiData
            );

            marker.onPoiClick(poiData => {
                if (poiData.type === 'fuelStation') {
                    this._onFuelStationClick?.(poiData.data);
                }
            });

            return marker;
        });

        const clusteringEndHandler = () => {
            const bounds = this._ctrl.reduceBounds(this._map!.getBounds()!, 5);
            const inView = this._fuelStationsCluster?.getClusters().filter(c => bounds.contains(c.getCenter()));

            if (!inView) {
                return;
            }

            const data = inView
                ?.map(cluster => {
                    const markers = cluster.getMarkers().map(m => (m as unknown as PoiMarker).data());
                    return fsBestPriceInPois(markers, this._currencies)!;
                })
                .filter(e => Boolean(e));

            const bestPrices = fsBestPriceInPois(data.flat(), this._currencies);

            if (bestPrices && bestPrices.length > 0) {
                bestPrices.forEach(bp => {
                    bp.bestPrice = true;
                });
                this.updatePartialFuelStationsData(bestPrices, true);
            }

            this._onBestPriceChange?.(bestPrices);
        };

        function clusterClickHandler(cluster: MarkerClusterer) {
            const bounds = new self._google.maps.LatLngBounds();
            const markers = cluster.getMarkers();
            markers.forEach(marker => {
                bounds.extend(marker.getPosition()!);
            });
            self._map?.fitBounds(bounds, self._ctrl.getPadding());
        }
        this._fuelStationsCluster = new MarkerClusterer(
            this._map!,
            this._fuelStationsMarkers as unknown as google.maps.Marker[],
            {
                gridSize: 100,
                averageCenter: true,
                zoomOnClick: false,
                clusterClass: 'fs-cluster',
                styles: [
                    {
                        url: FuelStationsIcons.Icon,
                        height: 67,
                        width: 64,
                        textSize: 18,
                        textColor: '#000000'
                    }
                ]
            }
        ) as MarkerClusterer;
        this._fuelStationsCluster.addListener('clusterclick', clusterClickHandler);
        this._fuelStationsCluster.addListener('clusteringend', clusteringEndHandler);
        // fitBounds not working properly with clusterer.
        // events in google map can finish before updating clusterer
        this.idlingEventForClustererFuelStations = this._map?.addListener('idle', () => {
            if (this._redrawFuelStationsAfterFitBounds) {
                this._fuelStationsCluster?.repaint();
                this._redrawFuelStationsAfterFitBounds = false;
            }
        });
        this._fuelStationsCluster.setCalculator(markers => {
            return bestPriceClusterCalculator(markers, this._currencies!);
        });
    }

    private _removeFuelStations() {
        this._fuelStationsCluster?.clearMarkers();
        this._fuelStationsCluster?.repaint();
        if (this._map) {
            this._fuelStationsCluster?.setMap(null);
        }
        this._fuelStationsMarkers = [];
        this._fuelStations = [];
    }

    private _renderParkings() {
        this._parkingsCluster?.clearMarkers();
        this._parkingsCluster?.setMap(null);
        const self = this;
        const data =
            this._parkings && this._polylinesPlanned && this._placeMarkers
                ? this._poisNearestToPolylineData(this._parkings, this._polylinesPlanned, this._placeMarkers)
                : [];
        function clusterClickHandler(cluster: MarkerClusterer) {
            const bounds = new self._google.maps.LatLngBounds();
            cluster.getMarkers().forEach(marker => {
                bounds.extend(marker.getPosition()!);
            });
            self._map?.fitBounds(bounds, self._ctrl.getPadding());
        }
        this._parkingsMarkers = data.map(e => {
            const poiData: PoiMarkerData = {
                type: 'parking',
                data: {
                    ...e.poi,
                    routeIndex: e.routeIndex
                },
                distance: e.meta.distance
            };

            const marker = new PoiMarker(
                this._google,
                new this._google.maps.LatLng(e!.meta.coords[0], e!.meta.coords[1]),
                poiData
            );

            marker.onPoiClick(poiData => {
                if (poiData.type === 'parking') {
                    this._onParkingClick?.(poiData.data);
                }
            });

            return marker;
        });

        this._parkingsCluster = new MarkerClusterer(this._map!, this._parkingsMarkers as any, {
            gridSize: 90,
            averageCenter: true,
            zoomOnClick: false,
            clusterClass: 'p-cluster',
            styles: [
                {
                    url: ParkingIcons.Icon,
                    height: 49,
                    width: 64
                }
            ]
        });

        // fitBounds not working properly with clusterer.
        // events in google map can finish before updating clusterer
        this.idlingEventForClustererParkings = this._map?.addListener('idle', () => {
            if (this._redrawParkingsAfterFitBounds) {
                this._parkingsCluster?.repaint();
                this._redrawParkingsAfterFitBounds = false;
            }
        });
        this._parkingsCluster!.addListener('clusterclick', clusterClickHandler);
    }

    private _renderAlarms(types?: (keyof AlarmClustersType)[]) {
        Object.entries(this._alarmClusters).forEach(([clusterKey, cluster]) => {
            if (types && !types.includes(clusterKey as keyof AlarmsType)) {
                return;
            }
            cluster?.clearMarkers();
            cluster?.setMap(null);
            const self = this;
            const data = this._alarms[clusterKey as keyof AlarmsType];

            function clusterClickHandler(cluster: MarkerClusterer) {
                const bounds = new self._google.maps.LatLngBounds();
                cluster.getMarkers().forEach(marker => {
                    bounds.extend(marker.getPosition()!);
                });
                self._map?.fitBounds(bounds, self._ctrl.getPadding());
            }

            const clusteringEndHandler = (_cluster: MarkerClusterer) => {
                const bounds = this._ctrl.reduceBounds(this._map!.getBounds()!, 5);
                Object.keys(this._alarmClusters).forEach(key => {
                    const clusterKey = key as keyof AlarmClustersType;
                    const inView = this._alarmClusters[clusterKey]
                        ?.getClusters()
                        .filter(c => bounds.contains(c.getCenter()));
                    if (!inView) {
                        return;
                    }
                    inView.forEach(cluster => {
                        const markers = cluster.getMarkers();
                        if (markers.length > 1) {
                            this._removeAlarmInfoboxes((markers as unknown as PoiMarker[]).map(m => m.data().id));
                        }
                        (markers as unknown as PoiMarker[]).forEach(marker => {
                            if (this._alarmsInfoBoxes[marker.data().id]) {
                                marker.setMap(null);
                            }
                        });
                    });
                });
            };

            this._alarmMarkers[clusterKey] = data?.map(poiData => {
                const marker = new PoiMarker(
                    this._google,
                    new this._google.maps.LatLng(poiData.position.lat, poiData.position.lng),
                    {
                        data: poiData,
                        type: 'alarm'
                    }
                );

                marker.onPoiClick(poiData => {
                    const prevInfoWindow = this._createAlarmInfoWindow(marker, poiData);
                    i18next.on('languageChanged', () => {
                        prevInfoWindow.close();
                        delete this._alarmsInfoBoxes[marker.data().id];
                        this._createAlarmInfoWindow(marker, poiData);
                    });

                    marker.setMap(null);
                    if (poiData.type === 'alarm') {
                        this._onAlarmClick?.(poiData.data);
                    }
                });

                return marker;
            });

            this._alarmClusters[clusterKey] = new MarkerClusterer(this._map!, this._alarmMarkers[clusterKey] as any, {
                gridSize: 90,
                averageCenter: true,
                zoomOnClick: false,
                clusterClass: 'al-cluster',
                styles: [
                    {
                        url: this._getAlarmIcon(AlarmType[clusterKey]),
                        height: 24,
                        width: 24
                    }
                ]
            });

            this._alarmClusters[clusterKey].addListener('clusterclick', clusterClickHandler);
            this._alarmClusters[clusterKey].addListener('clusteringend', clusteringEndHandler);
        });
    }

    private _createAlarmInfoWindow(marker: PoiMarker, poiData: PoiMarkerData) {
        const infoWindow = new this._google.maps.InfoWindow({
            content: renderToString(
                <AlarmBlock
                    key={`${poiData.data.position.lat}${poiData.data.position.lng}`}
                    id={`${poiData.data.position.lat}${poiData.data.position.lng}`}
                    type={poiData.data.name as AlarmType}
                    acknowledged={poiData.data.acknowledged}
                    startDateTime={poiData.data.date}
                    address={poiData.data.detailAddress}
                    onMarkAsSeen={this._onAlarmAcknowledgedClick}
                    closeButtonId={`${poiData.data.position.lat}${poiData.data.position.lng}-closeBtn`}
                />
            ),
            position: {
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng()
            },
            zIndex: 9999
        });
        this._google.maps.event.addListener(infoWindow, 'domready', () => {
            const closeButton = document.getElementById(
                `${poiData.data.position.lat}${poiData.data.position.lng}-closeBtn`
            );
            closeButton?.addEventListener('click', () => {
                infoWindow.close();
                delete this._alarmsInfoBoxes[marker.data().id];
                if (this._map) {
                    marker.setMap(this._map);
                }
            });
        });

        infoWindow.open(this._map);
        this._alarmsInfoBoxes[marker.data().id] = {
            alarmType: marker.data().alarmType,
            infoWindow: infoWindow
        };

        return infoWindow;
    }

    private _removeAlarmInfoboxes(ids?: string[]) {
        Object.keys(this._alarmsInfoBoxes).forEach(key => {
            if (ids) {
                if (ids.includes(key)) {
                    this._alarmsInfoBoxes[key]?.infoWindow.close();
                    delete this._alarmsInfoBoxes[key];
                }
            } else {
                this._alarmsInfoBoxes[key]?.infoWindow.close();
            }
        });

        if (!ids) {
            this._alarmsInfoBoxes = {};
        }
    }

    private _removeParkings() {
        this._parkingsCluster?.clearMarkers();
        this._parkingsCluster?.repaint();
        if (this._map) {
            this._parkingsCluster?.setMap(null);
        }
        this._parkingsMarkers = [];
        this._parkings = [];
    }

    private _createDriverBehaviorAlarmControl() {
        if (this._hasRoleDBH_IC_JA) {
            const driverControl = document.createElement('input');

            driverControl.type = 'image';
            driverControl.src = '/img-svg/icon-20-icn-driver.svg';
            driverControl.className = `map-control journey-control left1 ${
                this._alarmsControlsActive.driverBehaviorAlarmTypes ? 'active' : ''
            }`;
            driverControl.title = `${i18next.t('common.driverBehaviorAlarms')}`;
            driverControl.setAttribute('data-qa', 'map--fuel-stations-button');
            driverControl.id = 'journey-driver-control-toggler';

            driverControl.addEventListener('click', () => {
                if (driverControl.classList.contains('active')) {
                    driverControl.classList.remove('active');
                    this._alarmsControlsActive.driverBehaviorAlarmTypes = false;
                    this._hideAlarmMarkersByType(driverBehaviorAlarmTypes);
                    this._removeAlarmInfoboxesByType(driverBehaviorAlarmTypes);
                } else {
                    driverControl.classList.add('active');
                    this._alarmsControlsActive.driverBehaviorAlarmTypes = true;
                    this._renderAlarms(driverBehaviorAlarmTypes);
                }
            });

            return driverControl;
        } else {
            return null;
        }
    }

    private _createGeoAlarmControl() {
        const placeControl = document.createElement('input');
        const disabled = !this._hasRoleJAA;

        placeControl.disabled = disabled;
        placeControl.type = 'image';
        placeControl.src = '/img-svg/icon-20-icn-geofence.svg';
        placeControl.className = `map-control journey-control left2 ${disabled ? 'disabled' : ''} ${
            this._alarmsControlsActive.geoAlarmTypes ? 'active' : ''
        }`;
        placeControl.title = `${i18next.t('common.geoAlarms')}`;
        placeControl.setAttribute('data-qa', 'map--fuel-stations-button');
        placeControl.id = 'journey-place-control-toggler';

        placeControl.addEventListener('click', () => {
            if (placeControl.classList.contains('active')) {
                placeControl.classList.remove('active');
                this._alarmsControlsActive.geoAlarmTypes = false;
                this._hideAlarmMarkersByType(geoAlarmTypes);
                this._removeAlarmInfoboxesByType(geoAlarmTypes);
            } else {
                this._alarmsControlsActive.geoAlarmTypes = true;
                placeControl.classList.add('active');
                this._renderAlarms(geoAlarmTypes);
            }
        });

        return placeControl;
    }

    private _createInstantAlarmControl() {
        const alarmControl = document.createElement('input');
        const disabled = !this._hasRoleJAA;

        alarmControl.disabled = disabled;
        alarmControl.type = 'image';
        alarmControl.src = '/img-svg/icon-20-icn-alarm.svg';
        alarmControl.className = `map-control journey-control left3 ${disabled ? 'disabled' : ''} ${
            this._alarmsControlsActive.instantAlarmTypes ? 'active' : ''
        }`;
        alarmControl.title = `${i18next.t('common.instantAlarms')}`;
        alarmControl.setAttribute('data-qa', 'map--fuel-stations-button');
        alarmControl.id = 'journey-alarm-control-toggler';

        alarmControl.addEventListener('click', () => {
            if (alarmControl.classList.contains('active')) {
                alarmControl.classList.remove('active');
                this._alarmsControlsActive.instantAlarmTypes = false;
                this._hideAlarmMarkersByType(instantAlarmTypes);
                this._removeAlarmInfoboxesByType(instantAlarmTypes);
            } else {
                alarmControl.classList.add('active');
                this._alarmsControlsActive.instantAlarmTypes = true;
                this._renderAlarms(instantAlarmTypes);
            }
        });

        return alarmControl;
    }

    private _createJourneyGraphControl() {
        const graphControl = document.createElement('input');

        graphControl.type = 'image';
        graphControl.src = '/img-svg/icon-20-graph.svg';
        graphControl.className = `map-control journey-control left4 ${this._ctrl.journeyGraphVisible ? 'active' : ''}`;
        graphControl.title = `${i18next.t('common.routeGraph')}`;
        graphControl.setAttribute('data-qa', 'map--journey-graph-button');
        graphControl.id = 'journey-graph-control-toggler';

        graphControl.addEventListener('click', () => {
            this._ctrl.onJourneyGraphToggle(!this._ctrl.journeyGraphVisible);
        });

        return graphControl;
    }
}
