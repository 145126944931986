/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MsgResponse
 */
export interface MsgResponse {
    /**
     * 
     * @type {Message}
     * @memberof MsgResponse
     */
    message: Message;
}

export function MsgResponseFromJSON(json: any): MsgResponse {
    return MsgResponseFromJSONTyped(json, false);
}

export function MsgResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MsgResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function MsgResponseToJSON(value?: MsgResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': MessageToJSON(value.message),
    };
}


