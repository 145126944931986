/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum FileFormat {
    Pdf = 'pdf',
    Xlsx = 'xlsx'
}

export function FileFormatFromJSON(json: any): FileFormat {
    return FileFormatFromJSONTyped(json, false);
}

export function FileFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileFormat {
    return json as FileFormat;
}

export function FileFormatToJSON(value?: FileFormat | null): any {
    return value as any;
}

