import React, { ChangeEvent, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Tooltip } from 'common/components';
import { Input } from 'common/components/Form';
import cn from 'classnames';
import qaSelectors from 'qa-selectors';

export type SearchData = {
    text?: string;
};

interface Props extends WithTranslation {
    size?: 'small' | 'middle' | 'large';
    searchActive?: boolean;
    search?: SearchData;
    disabled?: boolean;
    placeholder?: string;
    onSearch?: (text: string) => void;
    onSearchClick?: () => void;
    qa?: string;
}

interface State {
    search?: string;
}

function Search({ t, size, searchActive, search, qa, disabled, placeholder, onSearchClick, onSearch }: Props) {
    function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
        // FIXME: Remove on react update to 17.x
        // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#no-event-pooling
        e.persist();
        onSearch?.(e.target.value);
        setState({
            search: e.target.value
        });
    }
    const [state, setState] = useState<State>({ search: search?.text });
    useEffect(() => {
        setState({
            search: search?.text
        });
    }, [search]);

    return (
        <div className={cn('tm-bar-search', { 'tm-bar-search-active': searchActive })}>
            {searchActive && (
                <Input
                    disabled={disabled}
                    size={size ?? 'large'}
                    qa={qa ?? qaSelectors.common.inputSearch}
                    placeholder={placeholder ?? t('common.searchPlaceholder')}
                    value={state.search}
                    defaultValue={search?.text ?? ''}
                    onChange={onSearchChange}
                />
            )}
            {onSearchClick ? (
                <Tooltip title={t('common.search')} placement="bottomLeft">
                    <img onClick={onSearchClick} className="pointer" src="/icons/icn-search.svg" alt="search" />
                </Tooltip>
            ) : (
                <img src="/icons/icn-search.svg" alt="search" />
            )}
        </div>
    );
}

export default withTranslation()(Search);
