/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetStazkaExportV1StazkaExportGetRequest {
    transportId: string;
    lang: string;
    exportType?: string;
}

/**
 * no description
 */
export class StazkaApi extends runtime.BaseAPI {

    /**
     * Stazka export
     */
    async getStazkaExportV1StazkaExportGetRaw(requestParameters: GetStazkaExportV1StazkaExportGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling getStazkaExportV1StazkaExportGet.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getStazkaExportV1StazkaExportGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.transportId !== undefined) {
            queryParameters['transport_id'] = requestParameters.transportId;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stazka/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stazka export
     */
    async getStazkaExportV1StazkaExportGet(requestParameters: GetStazkaExportV1StazkaExportGetRequest): Promise<void> {
        await this.getStazkaExportV1StazkaExportGetRaw(requestParameters);
    }

}
