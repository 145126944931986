/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyOdometerAdjustment
 */
export interface WriteOnlyOdometerAdjustment {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOdometerAdjustment
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOdometerAdjustment
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOdometerAdjustment
     */
    state: number;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyOdometerAdjustment
     */
    date: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyOdometerAdjustment
     */
    processed?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOdometerAdjustment
     */
    monitoredObject: number;
}

export function WriteOnlyOdometerAdjustmentFromJSON(json: any): WriteOnlyOdometerAdjustment {
    return WriteOnlyOdometerAdjustmentFromJSONTyped(json, false);
}

export function WriteOnlyOdometerAdjustmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyOdometerAdjustment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'state': json['state'],
        'date': (new Date(json['date'])),
        'processed': !exists(json, 'processed') ? undefined : (json['processed'] === null ? null : new Date(json['processed'])),
        'monitoredObject': json['monitored_object'],
    };
}

export function WriteOnlyOdometerAdjustmentToJSON(value?: WriteOnlyOdometerAdjustment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'state': value.state,
        'date': (value.date.toISOString()),
        'processed': value.processed === undefined ? undefined : (value.processed === null ? null : value.processed.toISOString()),
        'monitored_object': value.monitoredObject,
    };
}


