import moment from 'moment';
import { Rate } from 'common/components';
import { roundToStep } from 'common/utils/averages';

export type DriverStatValueFormat = 'liters' | 'kmPerHour' | 'duration' | 'percentage' | 'count' | 'distance' | 'stars';

interface Props {
    value?: number;
    unit: DriverStatValueFormat;
    rateColor?: 'yellow' | 'blue' | 'green' | 'lightblue' | 'purple';
}

export default function DriverStatValue({ value = 0, unit = 'count', rateColor }: Props) {
    const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
    const duration = moment.duration(value, 's').format('HH:mm:ss', { trim: false });

    switch (unit) {
        case 'count':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded}</span>
                </div>
            );
        case 'liters':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded}</span>
                    <span>l</span>
                </div>
            );
        case 'kmPerHour':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(2)}</span>
                    <span>km/h</span>
                </div>
            );
        case 'duration':
            return (
                <div className="driver-behavior-value">
                    <span>{duration.split(':')[0]}</span>
                    <span>h</span>
                    <span>{duration.split(':')[1]}</span>
                    <span>m</span>
                    <span>{duration.split(':')[2]}</span>
                    <span>s</span>
                </div>
            );
        case 'distance':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(2)}</span>
                    <span>km</span>
                </div>
            );
        case 'percentage':
            return (
                <div className="driver-behavior-value">
                    <span>{rounded.toFixed(2)}</span>
                    <span>%</span>
                </div>
            );
        case 'stars':
            return (
                <div className="driver-behavior-value">
                    <span>{roundToStep(rounded, 0.1)}</span>
                    <Rate size="xsmall" color={rateColor} value={roundToStep(rounded, 0.5)} disabled allowHalf />
                </div>
            );
        default:
            return <>{rounded}</>;
    }
}
