import { CustomPlace } from 'generated/backend-api';

export const places = [
    {
        id: '601ece896075b300186bb949',
        address: 'Žateckých 1212, 140 00 Praha 4-Nusle, Czechia',
        type: 'firm',
        polygonType: 'custom',
        name: 'W.A.G. payment solutions, Inc.',
        forbidden: false,
        notes: '',
        center: { coordinates: [14.435474823270845, 50.06159277507501], __typename: 'GeoJSONPointType' },
        polygon: {
            coordinates: [
                [
                    [14.435084306221006, 50.06208924357485],
                    [14.435713460235213, 50.06212370749191],
                    [14.435865340320682, 50.06106632336097],
                    [14.435371637861726, 50.06106184265813],
                    [14.435365426891268, 50.06117824671972],
                    [14.435156214588106, 50.06117258460599],
                    [14.435084306221006, 50.06208924357485]
                ]
            ],
            __typename: 'GeoJSONPolygonType'
        }
    },
    {
        id: '601ecf616075b300186bb94a',
        address: 'Svatá Kateřina 117, 348 06 Rozvadov, Czechia',
        type: 'firm',
        polygonType: 'custom',
        name: 'EW Rozvadov',
        forbidden: false,
        notes: '',
        center: { coordinates: [12.601310394309234, 49.675114383968065], __typename: 'GeoJSONPointType' },
        polygon: {
            coordinates: [
                [
                    [12.600068531535337, 49.67535555877515],
                    [12.600573369128707, 49.67560761404457],
                    [12.603121467692855, 49.67543828533946],
                    [12.60300286819763, 49.674621153891565],
                    [12.599499320925611, 49.674858557367266],
                    [12.5995154141797, 49.675097316712986],
                    [12.600068531535337, 49.67535555877515]
                ]
            ],
            __typename: 'GeoJSONPolygonType'
        }
    },
    {
        id: '601ecffa6075b300186bb94b',
        address: 'Zděbradská 124, 251 01 Říčany, Czechia',
        type: 'firm',
        polygonType: 'custom',
        name: 'EW Modletice',
        forbidden: false,
        notes: '',
        center: { coordinates: [14.612183156066894, 49.97162194035509], __typename: 'GeoJSONPointType' },
        polygon: {
            coordinates: [
                [
                    [14.612183156066916, 49.97252024469092],
                    [14.61339272449789, 49.972071092523066],
                    [14.61339272449789, 49.971172788187246],
                    [14.612183156066916, 49.97072363601927],
                    [14.6109735876359, 49.971172788187246],
                    [14.6109735876359, 49.972071092523066],
                    [14.612183156066916, 49.97252024469092]
                ]
            ],
            __typename: 'GeoJSONPolygonType'
        }
    }
] as CustomPlace[];
