/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AlarmInDatabaseWithGPSInfo,
    AlarmInDatabaseWithGPSInfoFromJSON,
    AlarmInDatabaseWithGPSInfoToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PuescEwMissionStatusResponse,
    PuescEwMissionStatusResponseFromJSON,
    PuescEwMissionStatusResponseToJSON,
} from '../models';

export interface AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest {
    alarmId: string;
}

export interface ActiveV1AlarmsActiveGetRequest {
    limit?: number;
    offset?: number;
    subscribeDevice?: string;
    subscribeUser?: string;
    monitoredObjectId?: number;
}

export interface GetAlarmsV1AlarmsMyGetRequest {
    active?: boolean;
    limit?: number;
    offset?: number;
    subscribeDevice?: string;
    subscribeUser?: string;
    dateFrom?: Date;
    dateTo?: Date;
    canceled?: boolean;
    acknowledged?: boolean;
    monitoredObjectId?: number;
}

export interface HistoryV1AlarmsHistoryGetRequest {
    limit?: number;
    offset?: number;
    dateFrom?: Date;
    dateTo?: Date;
    monitoredObjectId?: number;
}

export interface PuescEwMissionStatusV1AlarmsPuescEwMissionStatusGetRequest {
    moids: Array<string>;
    deadMansSwitchThresholdSeconds?: number;
    transportDataMaxAge?: number;
}

export interface PuescUserStatusV1AlarmsPuescUserStatusGetRequest {
    deadMansSwitchThresholdSeconds?: number;
    transportDataMaxAge?: number;
    moids?: Array<string>;
}

/**
 * no description
 */
export class AlarmsApi extends runtime.BaseAPI {

    /**
     * Acknowledge Alarm
     */
    async acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRaw(requestParameters: AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.alarmId === null || requestParameters.alarmId === undefined) {
            throw new runtime.RequiredError('alarmId','Required parameter requestParameters.alarmId was null or undefined when calling acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/alarm/{alarm_id}/acknowledge`.replace(`{${"alarm_id"}}`, encodeURIComponent(String(requestParameters.alarmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Acknowledge Alarm
     */
    async acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePost(requestParameters: AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest): Promise<object> {
        const response = await this.acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Active
     */
    async activeV1AlarmsActiveGetRaw(requestParameters: ActiveV1AlarmsActiveGetRequest): Promise<runtime.ApiResponse<Array<AlarmInDatabaseWithGPSInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlarmInDatabaseWithGPSInfoFromJSON));
    }

    /**
     * Active
     */
    async activeV1AlarmsActiveGet(requestParameters: ActiveV1AlarmsActiveGetRequest): Promise<Array<AlarmInDatabaseWithGPSInfo>> {
        const response = await this.activeV1AlarmsActiveGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * DEPRECATED - use endpoints /active/ or /history/ 
     */
    async getAlarmsV1AlarmsMyGetRaw(requestParameters: GetAlarmsV1AlarmsMyGetRequest): Promise<runtime.ApiResponse<Array<AlarmInDatabaseWithGPSInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.canceled !== undefined) {
            queryParameters['canceled'] = requestParameters.canceled;
        }

        if (requestParameters.acknowledged !== undefined) {
            queryParameters['acknowledged'] = requestParameters.acknowledged;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlarmInDatabaseWithGPSInfoFromJSON));
    }

    /**
     * DEPRECATED - use endpoints /active/ or /history/ 
     */
    async getAlarmsV1AlarmsMyGet(requestParameters: GetAlarmsV1AlarmsMyGetRequest): Promise<Array<AlarmInDatabaseWithGPSInfo>> {
        const response = await this.getAlarmsV1AlarmsMyGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Use in reports like journies and transports
     * History
     */
    async historyV1AlarmsHistoryGetRaw(requestParameters: HistoryV1AlarmsHistoryGetRequest): Promise<runtime.ApiResponse<Array<AlarmInDatabaseWithGPSInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlarmInDatabaseWithGPSInfoFromJSON));
    }

    /**
     * Use in reports like journies and transports
     * History
     */
    async historyV1AlarmsHistoryGet(requestParameters: HistoryV1AlarmsHistoryGetRequest): Promise<Array<AlarmInDatabaseWithGPSInfo>> {
        const response = await this.historyV1AlarmsHistoryGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Puesc Ew Mission Status
     */
    async puescEwMissionStatusV1AlarmsPuescEwMissionStatusGetRaw(requestParameters: PuescEwMissionStatusV1AlarmsPuescEwMissionStatusGetRequest): Promise<runtime.ApiResponse<Array<PuescEwMissionStatusResponse>>> {
        if (requestParameters.moids === null || requestParameters.moids === undefined) {
            throw new runtime.RequiredError('moids','Required parameter requestParameters.moids was null or undefined when calling puescEwMissionStatusV1AlarmsPuescEwMissionStatusGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deadMansSwitchThresholdSeconds !== undefined) {
            queryParameters['dead_mans_switch_threshold_seconds'] = requestParameters.deadMansSwitchThresholdSeconds;
        }

        if (requestParameters.transportDataMaxAge !== undefined) {
            queryParameters['transport_data_max_age'] = requestParameters.transportDataMaxAge;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/puesc-ew-mission-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PuescEwMissionStatusResponseFromJSON));
    }

    /**
     * Puesc Ew Mission Status
     */
    async puescEwMissionStatusV1AlarmsPuescEwMissionStatusGet(requestParameters: PuescEwMissionStatusV1AlarmsPuescEwMissionStatusGetRequest): Promise<Array<PuescEwMissionStatusResponse>> {
        const response = await this.puescEwMissionStatusV1AlarmsPuescEwMissionStatusGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Puesc User Status
     */
    async puescUserStatusV1AlarmsPuescUserStatusGetRaw(requestParameters: PuescUserStatusV1AlarmsPuescUserStatusGetRequest): Promise<runtime.ApiResponse<Array<PuescEwMissionStatusResponse>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deadMansSwitchThresholdSeconds !== undefined) {
            queryParameters['dead_mans_switch_threshold_seconds'] = requestParameters.deadMansSwitchThresholdSeconds;
        }

        if (requestParameters.transportDataMaxAge !== undefined) {
            queryParameters['transport_data_max_age'] = requestParameters.transportDataMaxAge;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/puesc-user-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PuescEwMissionStatusResponseFromJSON));
    }

    /**
     * Puesc User Status
     */
    async puescUserStatusV1AlarmsPuescUserStatusGet(requestParameters: PuescUserStatusV1AlarmsPuescUserStatusGetRequest): Promise<Array<PuescEwMissionStatusResponse>> {
        const response = await this.puescUserStatusV1AlarmsPuescUserStatusGetRaw(requestParameters);
        return await response.value();
    }

}
