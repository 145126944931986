import { WithTranslation, withTranslation } from 'react-i18next';
import Widget from 'common/components/Widget';
import { DriverBehaviorTrendsModel, DriverLeaderboardTrendsTableModel } from 'common/model/statistics';
import DriverLeaderboardTrendsTable from 'modules/statistics/modules/driver-behavior-new/components/DriverLeaderboardTrendsTable';

interface Props extends WithTranslation {
    data: DriverBehaviorTrendsModel[];
    loading: boolean;
    onClick?: () => void;
}

function WorstDriversWidget(props: Props) {
    return (
        <Widget
            loading={props.loading}
            title={props.t('CompanyProfileDashboard.worstDrivers')}
            styleName="sad"
            content={
                <DriverLeaderboardTrendsTable
                    data={
                        props.data.map(
                            data =>
                                ({
                                    id: data.id,
                                    name: data.name,
                                    score: data.scores[0].score ?? 0,
                                    rank: data.rank
                                } as DriverLeaderboardTrendsTableModel)
                        ) ?? []
                    }
                    loading={false}
                    showHeader={false}
                />
            }
            onClick={props.onClick}
        />
    );
}

export default withTranslation()(WorstDriversWidget);
