/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientBLEConfig,
    ClientBLEConfigFromJSON,
    ClientBLEConfigToJSON,
    ClientCostStat,
    ClientCostStatFromJSON,
    ClientCostStatToJSON,
    ClientOwnerUserRights,
    ClientOwnerUserRightsFromJSON,
    ClientOwnerUserRightsToJSON,
    ClientSSOIdentities,
    ClientSSOIdentitiesFromJSON,
    ClientSSOIdentitiesToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    ListOfChars,
    ListOfCharsFromJSON,
    ListOfCharsToJSON,
    Number,
    NumberFromJSON,
    NumberToJSON,
    ReadOnlyClient,
    ReadOnlyClientFromJSON,
    ReadOnlyClientToJSON,
    ReadOnlyClientBackendInfo,
    ReadOnlyClientBackendInfoFromJSON,
    ReadOnlyClientBackendInfoToJSON,
    ReadOnlyClientFlat,
    ReadOnlyClientFlatFromJSON,
    ReadOnlyClientFlatToJSON,
    WriteOnlyClient,
    WriteOnlyClientFromJSON,
    WriteOnlyClientToJSON,
} from '../models';

export interface ClientClientForBackendRequest {
    id: number;
}

export interface ClientClientGetRequest {
    name?: string;
    nameContains?: string;
    nameIcontains?: string;
    isPartner?: boolean;
    externalId?: string;
    referrer?: string;
    idIn?: string;
    clientOrReferrer?: string;
    catalogueExternalId?: string;
}

export interface ClientCreateRequest {
    data: WriteOnlyClient;
    onConflict?: string;
}

export interface ClientCreateCrmRequest {
    id: number;
    data: Client;
}

export interface ClientDeleteRequest {
    id: number;
}

export interface ClientGetActiveClientRequest {
    name?: string;
    nameContains?: string;
    nameIcontains?: string;
    isPartner?: boolean;
    externalId?: string;
    referrer?: string;
    idIn?: string;
    clientOrReferrer?: string;
    catalogueExternalId?: string;
}

export interface ClientGetBleConfigRequest {
    id: number;
}

export interface ClientGetClientNotForwardedRequest {
    name?: string;
    nameContains?: string;
    nameIcontains?: string;
    isPartner?: boolean;
    externalId?: string;
    referrer?: string;
    idIn?: string;
    clientOrReferrer?: string;
    catalogueExternalId?: string;
}

export interface ClientListRequest {
    name?: string;
    nameContains?: string;
    nameIcontains?: string;
    isPartner?: boolean;
    externalId?: string;
    referrer?: string;
    idIn?: string;
    clientOrReferrer?: string;
    catalogueExternalId?: string;
    limit?: number;
    offset?: number;
}

export interface ClientPartialUpdateRequest {
    id: number;
    data: WriteOnlyClient;
}

export interface ClientReadRequest {
    id: number;
}

export interface ClientRefererRequest {
    id: number;
    data: Number;
}

export interface ClientSetSsoAdminUserRightsRequest {
    id: number;
    data: ClientOwnerUserRights;
}

export interface ClientSsoIdentitiesRequest {
    id: number;
}

export interface ClientUpdateRequest {
    id: number;
    data: WriteOnlyClient;
}

/**
 * no description
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     */
    async clientClientForBackendRaw(requestParameters: ClientClientForBackendRequest): Promise<runtime.ApiResponse<ReadOnlyClientBackendInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientClientForBackend.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/backend-info/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientBackendInfoFromJSON(jsonValue));
    }

    /**
     */
    async clientClientForBackend(requestParameters: ClientClientForBackendRequest): Promise<ReadOnlyClientBackendInfo> {
        const response = await this.clientClientForBackendRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientClientGetRaw(requestParameters: ClientClientGetRequest): Promise<runtime.ApiResponse<Array<ReadOnlyClientFlat>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name__contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.isPartner !== undefined) {
            queryParameters['is_partner'] = requestParameters.isPartner;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.clientOrReferrer !== undefined) {
            queryParameters['client_or_referrer'] = requestParameters.clientOrReferrer;
        }

        if (requestParameters.catalogueExternalId !== undefined) {
            queryParameters['catalogue_external_id'] = requestParameters.catalogueExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/GDPR-get/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyClientFlatFromJSON));
    }

    /**
     */
    async clientClientGet(requestParameters: ClientClientGetRequest): Promise<Array<ReadOnlyClientFlat>> {
        const response = await this.clientClientGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientClientsCostRaw(): Promise<runtime.ApiResponse<ClientCostStat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cost-stat/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCostStatFromJSON(jsonValue));
    }

    /**
     */
    async clientClientsCost(): Promise<ClientCostStat> {
        const response = await this.clientClientsCostRaw();
        return await response.value();
    }

    /**
     */
    async clientCreateRaw(requestParameters: ClientCreateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onConflict !== undefined) {
            queryParameters['on_conflict'] = requestParameters.onConflict;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientCreate(requestParameters: ClientCreateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientCreateCrmRaw(requestParameters: ClientCreateCrmRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCreateCrm.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCreateCrm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/create_crm/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientCreateCrm(requestParameters: ClientCreateCrmRequest): Promise<void> {
        await this.clientCreateCrmRaw(requestParameters);
    }

    /**
     */
    async clientDeleteRaw(requestParameters: ClientDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientDelete(requestParameters: ClientDeleteRequest): Promise<ReadOnlyClient> {
        const response = await this.clientDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientGetActiveClientRaw(requestParameters: ClientGetActiveClientRequest): Promise<runtime.ApiResponse<ListOfChars>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name__contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.isPartner !== undefined) {
            queryParameters['is_partner'] = requestParameters.isPartner;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.clientOrReferrer !== undefined) {
            queryParameters['client_or_referrer'] = requestParameters.clientOrReferrer;
        }

        if (requestParameters.catalogueExternalId !== undefined) {
            queryParameters['catalogue_external_id'] = requestParameters.catalogueExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/active/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfCharsFromJSON(jsonValue));
    }

    /**
     */
    async clientGetActiveClient(requestParameters: ClientGetActiveClientRequest): Promise<ListOfChars> {
        const response = await this.clientGetActiveClientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientGetBleConfigRaw(requestParameters: ClientGetBleConfigRequest): Promise<runtime.ApiResponse<ClientBLEConfig>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientGetBleConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/bleConfig/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientBLEConfigFromJSON(jsonValue));
    }

    /**
     */
    async clientGetBleConfig(requestParameters: ClientGetBleConfigRequest): Promise<ClientBLEConfig> {
        const response = await this.clientGetBleConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientGetClientNotForwardedRaw(requestParameters: ClientGetClientNotForwardedRequest): Promise<runtime.ApiResponse<ListOfChars>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name__contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.isPartner !== undefined) {
            queryParameters['is_partner'] = requestParameters.isPartner;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.clientOrReferrer !== undefined) {
            queryParameters['client_or_referrer'] = requestParameters.clientOrReferrer;
        }

        if (requestParameters.catalogueExternalId !== undefined) {
            queryParameters['catalogue_external_id'] = requestParameters.catalogueExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/dff-not-forwarded/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfCharsFromJSON(jsonValue));
    }

    /**
     */
    async clientGetClientNotForwarded(requestParameters: ClientGetClientNotForwardedRequest): Promise<ListOfChars> {
        const response = await this.clientGetClientNotForwardedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientListRaw(requestParameters: ClientListRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name__contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.isPartner !== undefined) {
            queryParameters['is_partner'] = requestParameters.isPartner;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.clientOrReferrer !== undefined) {
            queryParameters['client_or_referrer'] = requestParameters.clientOrReferrer;
        }

        if (requestParameters.catalogueExternalId !== undefined) {
            queryParameters['catalogue_external_id'] = requestParameters.catalogueExternalId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     */
    async clientList(requestParameters: ClientListRequest): Promise<InlineResponse2001> {
        const response = await this.clientListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientPartialUpdateRaw(requestParameters: ClientPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientPartialUpdate(requestParameters: ClientPartialUpdateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientReadRaw(requestParameters: ClientReadRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientRead(requestParameters: ClientReadRequest): Promise<ReadOnlyClient> {
        const response = await this.clientReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientRefererRaw(requestParameters: ClientRefererRequest): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientReferer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientReferer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/referer_id/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NumberToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     */
    async clientReferer(requestParameters: ClientRefererRequest): Promise<Client> {
        const response = await this.clientRefererRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientSetSsoAdminUserRightsRaw(requestParameters: ClientSetSsoAdminUserRightsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSetSsoAdminUserRights.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSetSsoAdminUserRights.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/owner-rights/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientOwnerUserRightsToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientSetSsoAdminUserRights(requestParameters: ClientSetSsoAdminUserRightsRequest): Promise<void> {
        await this.clientSetSsoAdminUserRightsRaw(requestParameters);
    }

    /**
     */
    async clientSsoIdentitiesRaw(requestParameters: ClientSsoIdentitiesRequest): Promise<runtime.ApiResponse<Array<ClientSSOIdentities>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSsoIdentities.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/sso_identities/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientSSOIdentitiesFromJSON));
    }

    /**
     */
    async clientSsoIdentities(requestParameters: ClientSsoIdentitiesRequest): Promise<Array<ClientSSOIdentities>> {
        const response = await this.clientSsoIdentitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientUpdateRaw(requestParameters: ClientUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientUpdate(requestParameters: ClientUpdateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientUpdateRaw(requestParameters);
        return await response.value();
    }

}
