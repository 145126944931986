/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyUserMini
 */
export interface ReadOnlyUserMini {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserMini
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserMini
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserMini
     */
    ssoId?: string | null;
}

export function ReadOnlyUserMiniFromJSON(json: any): ReadOnlyUserMini {
    return ReadOnlyUserMiniFromJSONTyped(json, false);
}

export function ReadOnlyUserMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserMini {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'surname': json['surname'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
    };
}

export function ReadOnlyUserMiniToJSON(value?: ReadOnlyUserMini | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'sso_id': value.ssoId,
    };
}


