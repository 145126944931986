/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TripInDatabase,
    TripInDatabaseFromJSON,
    TripInDatabaseFromJSONTyped,
    TripInDatabaseToJSON,
    TripSummary,
    TripSummaryFromJSON,
    TripSummaryFromJSONTyped,
    TripSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface TripResponse
 */
export interface TripResponse {
    /**
     * 
     * @type {TripSummary}
     * @memberof TripResponse
     */
    summary: TripSummary;
    /**
     * 
     * @type {Array<TripInDatabase>}
     * @memberof TripResponse
     */
    trips: Array<TripInDatabase>;
    /**
     * 
     * @type {boolean}
     * @memberof TripResponse
     */
    anyDwlTripsFilteredOut?: boolean;
}

export function TripResponseFromJSON(json: any): TripResponse {
    return TripResponseFromJSONTyped(json, false);
}

export function TripResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': TripSummaryFromJSON(json['summary']),
        'trips': ((json['trips'] as Array<any>).map(TripInDatabaseFromJSON)),
        'anyDwlTripsFilteredOut': !exists(json, 'any_dwl_trips_filtered_out') ? undefined : json['any_dwl_trips_filtered_out'],
    };
}

export function TripResponseToJSON(value?: TripResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': TripSummaryToJSON(value.summary),
        'trips': ((value.trips as Array<any>).map(TripInDatabaseToJSON)),
        'any_dwl_trips_filtered_out': value.anyDwlTripsFilteredOut,
    };
}


