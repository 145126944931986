/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MainDataResUser,
    MainDataResUserFromJSON,
    MainDataResUserToJSON,
    MainDataResvoid,
    MainDataResvoidFromJSON,
    MainDataResvoidToJSON,
    UserUpdateBody,
    UserUpdateBodyFromJSON,
    UserUpdateBodyToJSON,
} from '../models';

export interface GetByIdRequest {
    id: number;
}

export interface GetBySSOIdRequest {
    id: string;
}

export interface UpdateUserRequest {
    userUpdateBody?: UserUpdateBody;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Gets user by id
     */
    async getByIdRaw(requestParameters: GetByIdRequest): Promise<runtime.ApiResponse<MainDataResUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResUserFromJSON(jsonValue));
    }

    /**
     * Gets user by id
     */
    async getById(requestParameters: GetByIdRequest): Promise<MainDataResUser> {
        const response = await this.getByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets logged user
     */
    async getBySSOIdRaw(requestParameters: GetBySSOIdRequest): Promise<runtime.ApiResponse<MainDataResUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBySSOId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/users/sso_id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResUserFromJSON(jsonValue));
    }

    /**
     * Gets logged user
     */
    async getBySSOId(requestParameters: GetBySSOIdRequest): Promise<MainDataResUser> {
        const response = await this.getBySSOIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets logged user
     */
    async getCurrentRaw(): Promise<runtime.ApiResponse<MainDataResUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResUserFromJSON(jsonValue));
    }

    /**
     * Gets logged user
     */
    async getCurrent(): Promise<MainDataResUser> {
        const response = await this.getCurrentRaw();
        return await response.value();
    }

    /**
     * Updates the authenticated user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<MainDataResUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/my`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateBodyToJSON(requestParameters.userUpdateBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResUserFromJSON(jsonValue));
    }

    /**
     * Updates the authenticated user
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<MainDataResUser> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async versionRaw(): Promise<runtime.ApiResponse<MainDataResvoid>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResvoidFromJSON(jsonValue));
    }

    /**
     */
    async version(): Promise<MainDataResvoid> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
