import { Button } from 'common/components';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLogic } from 'App';
import { observer } from 'mobx-react';
import qa from 'qa-selectors';

interface Props {
    onEdit: () => void;
    onRemove: () => void;
}

export default observer(function ContactListActions(props: Props) {
    const { t } = useTranslation();
    const logic = useLogic();

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={logic.demo().isActive}
                        qa={qa.contacts.form.btnSubmit}
                        onClick={props.onEdit}
                    >
                        {t('common.edit')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={logic.demo().isActive}
                        qa={qa.contacts.form.btnCancel}
                        onClick={props.onRemove}
                    >
                        {t('common.delete')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
});
