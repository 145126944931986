/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MainDataVersion,
    MainDataVersionFromJSON,
    MainDataVersionFromJSONTyped,
    MainDataVersionToJSON,
    TagAssignment,
    TagAssignmentFromJSON,
    TagAssignmentFromJSONTyped,
    TagAssignmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface MainDataResTagAssignmentArray
 */
export interface MainDataResTagAssignmentArray {
    /**
     * 
     * @type {MainDataVersion}
     * @memberof MainDataResTagAssignmentArray
     */
    version: MainDataVersion;
    /**
     * 
     * @type {Array<TagAssignment>}
     * @memberof MainDataResTagAssignmentArray
     */
    data?: Array<TagAssignment> | null;
    /**
     * 
     * @type {string}
     * @memberof MainDataResTagAssignmentArray
     */
    error?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResTagAssignmentArray
     */
    offset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResTagAssignmentArray
     */
    total?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResTagAssignmentArray
     */
    limit?: number | null;
}

export function MainDataResTagAssignmentArrayFromJSON(json: any): MainDataResTagAssignmentArray {
    return MainDataResTagAssignmentArrayFromJSONTyped(json, false);
}

export function MainDataResTagAssignmentArrayFromJSONTyped(json: any, ignoreDiscriminator: boolean): MainDataResTagAssignmentArray {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': MainDataVersionFromJSON(json['version']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(TagAssignmentFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function MainDataResTagAssignmentArrayToJSON(value?: MainDataResTagAssignmentArray | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': MainDataVersionToJSON(value.version),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(TagAssignmentToJSON)),
        'error': value.error,
        'offset': value.offset,
        'total': value.total,
        'limit': value.limit,
    };
}


