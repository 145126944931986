import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { ReadOnlyContactList, ReadOnlyContactListTypeEnum, ReadOnlyCountry } from 'generated/new-main';
import { ClientContactForm, ContactList } from 'common/model/client-contact';
import { InputField, SelectField } from 'common/fields';
import { Button, Checkbox } from 'common/components';
import { objectCompareSame } from 'common/utils/textUtils';
import cn from 'classnames';

interface Props extends WithTranslation {
    type?: 'default' | 'edit';
    size?: 'default' | 'small';
    designType?: 'default' | 'compact';
    initialClientContact?: Partial<ReadOnlyContactList>;
    countries: ReadOnlyCountry[];
    demoMode?: boolean;
    readonlyTypes?: ReadOnlyContactListTypeEnum[];
    onSubmit: (values: ContactList, type: ReadOnlyContactListTypeEnum[]) => Promise<boolean>;
    onCancel?: () => void;
}

function CreateContactForm({
    onSubmit,
    initialClientContact,
    t,
    onCancel,
    type,
    countries,
    demoMode,
    designType = 'default',
    readonlyTypes
}: Props) {
    const [clientType, setClientType] = useState<ReadOnlyContactListTypeEnum[]>(
        initialClientContact?.type ?? [ReadOnlyContactListTypeEnum.Client]
    );
    const [locked, setLocked] = useState(type === 'edit' ? true : false);

    useEffect(() => {
        form.setValues({
            name: initialClientContact?.name ?? '',
            businessId: initialClientContact?.businessId ?? '',
            vat: initialClientContact?.vat ?? '',
            taxId: initialClientContact?.taxId ?? '',
            otherIdentificationNumber: initialClientContact?.otherIdentificationNumber ?? '',

            countryId: initialClientContact?.country?.id,
            city: initialClientContact?.city ?? '',
            postalCode: initialClientContact?.postalCode ?? '',
            street: initialClientContact?.street ?? '',
            houseNumber: initialClientContact?.houseNumber ?? '',
            premisesNumber: initialClientContact?.premisesNumber ?? ''
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, initialClientContact]);

    let form: FormikProps<ClientContactForm>;

    const evaluateSubmitButton = () => {
        if (initialClientContact) {
            setTimeout(() => setLocked(objectCompareSame(initialClientContact, form.values)), 1);
        } else {
            return;
        }
    };

    const countriesOptions = countries.map(country => {
        return {
            value: country.id,
            label: country.name
        };
    });

    const supplierSchema = Yup.object().shape(
        {
            name: Yup.string().required(t('common.required')),

            businessId: Yup.string(),
            vat: Yup.string(),
            taxId: Yup.string(),
            otherIdentificationNumber: Yup.string(),
            countryId: Yup.number(),
            city: Yup.string(),
            postalCode: Yup.string(),
            street: Yup.string(),
            houseNumber: Yup.string(),
            premisesNumber: Yup.string()
        },
        [
            ['otherIdentificationNumber', 'taxId'],
            ['otherIdentificationNumber', 'vat'],
            ['otherIdentificationNumber', 'businessId'],
            ['taxId', 'vat'],
            ['taxId', 'businessId'],
            ['vat', 'businessId']
        ]
    );

    const clientSchema = Yup.object().shape({
        name: Yup.string().required(t('common.required'))
    });

    const onClientContactFormSubmit = (clientContact: ClientContactForm) => {
        return onSubmit?.(
            {
                ...clientContact,
                country: countries.find(c => c.id === clientContact.countryId)
            },
            clientType
        );
    };

    const handleChangeClientType = (type: ReadOnlyContactListTypeEnum) => {
        evaluateSubmitButton();
        setClientType(prev => {
            if (prev.includes(type)) {
                if (prev.length !== 1) {
                    return prev.filter(prevType => prevType !== type);
                } else {
                    return prev;
                }
            }

            return [...prev, type];
        });
    };

    return (
        <Formik<ClientContactForm>
            initialValues={{
                name: initialClientContact?.name ?? '',
                businessId: initialClientContact?.businessId ?? '',
                vat: initialClientContact?.vat ?? '',
                taxId: initialClientContact?.taxId ?? '',
                otherIdentificationNumber: initialClientContact?.otherIdentificationNumber ?? '',

                countryId: initialClientContact?.country?.id,
                city: initialClientContact?.city ?? '',
                postalCode: initialClientContact?.postalCode ?? '',
                street: initialClientContact?.street ?? '',
                houseNumber: initialClientContact?.houseNumber ?? '',
                premisesNumber: initialClientContact?.premisesNumber ?? ''
            }}
            onSubmit={onClientContactFormSubmit}
            validationSchema={clientType.includes(ReadOnlyContactListTypeEnum.Client) ? clientSchema : supplierSchema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<ClientContactForm>) => {
                form = formik;
                return (
                    <div
                        className={cn({
                            createContactForm: designType === 'default',
                            createContactFormCompact: designType === 'compact'
                        })}
                    >
                        <Form>
                            <div className="modal-form-content">
                                {designType === 'compact' && (
                                    <>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={4}>{t('ClientContactListForm.type').toUpperCase()}</Col>
                                            <Col span={6}>
                                                <Checkbox
                                                    name="type"
                                                    disabled={readonlyTypes?.includes(
                                                        ReadOnlyContactListTypeEnum.Client
                                                    )}
                                                    className="checkItem"
                                                    checked={clientType.includes(ReadOnlyContactListTypeEnum.Client)}
                                                    qa={qa.contacts.form.inputTypeClient}
                                                    onChange={() =>
                                                        handleChangeClientType(ReadOnlyContactListTypeEnum.Client)
                                                    }
                                                >
                                                    {t('ContactList.tableClientTypeValues.client')}
                                                </Checkbox>
                                            </Col>
                                            <Col span={6}>
                                                <Checkbox
                                                    name="type"
                                                    className="checkItem"
                                                    disabled={readonlyTypes?.includes(
                                                        ReadOnlyContactListTypeEnum.Supplier
                                                    )}
                                                    checked={clientType.includes(ReadOnlyContactListTypeEnum.Supplier)}
                                                    qa={qa.contacts.form.inputTypeSupplier}
                                                    onChange={() =>
                                                        handleChangeClientType(ReadOnlyContactListTypeEnum.Supplier)
                                                    }
                                                >
                                                    {t('ContactList.tableClientTypeValues.supplier')}
                                                </Checkbox>
                                            </Col>
                                            <Col span={6}>
                                                <Checkbox
                                                    name="type"
                                                    className="checkItem"
                                                    disabled={readonlyTypes?.includes(
                                                        ReadOnlyContactListTypeEnum.Consignee
                                                    )}
                                                    checked={clientType.includes(ReadOnlyContactListTypeEnum.Consignee)}
                                                    qa={qa.contacts.form.inputTypeSupplier}
                                                    onChange={() =>
                                                        handleChangeClientType(ReadOnlyContactListTypeEnum.Consignee)
                                                    }
                                                >
                                                    {t('ContactList.tableClientTypeValues.consignee')}
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={12}>
                                                <InputField
                                                    name="name"
                                                    label={t('ClientContactListForm.name')}
                                                    placeholder={t('ClientContactListForm.companyName')}
                                                    qa={qa.contacts.form.inputName}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InputField
                                                    name="businessId"
                                                    label={t('ClientContactListForm.businessId')}
                                                    placeholder={t('ClientContactListForm.businessId')}
                                                    qa={qa.contacts.form.inputBussinessId}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={8}>
                                                <InputField
                                                    name="vat"
                                                    label={t('ClientContactListForm.vat')}
                                                    placeholder={t('ClientContactListForm.vat')}
                                                    qa={qa.contacts.form.inputVat}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    name="taxId"
                                                    label={t('ClientContactListForm.taxId')}
                                                    placeholder={t('ClientContactListForm.taxId')}
                                                    qa={qa.contacts.form.inputTaxId}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    name="otherIdentificationNumber"
                                                    label={t('ClientContactListForm.otherIdentificationNumber')}
                                                    placeholder={t('ClientContactListForm.otherIdentificationNumber')}
                                                    qa={qa.contacts.form.inputOtherNotificationNm}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="section-title">
                                            {t('ClientContactListForm.addressOfResidenceTitle')}
                                        </Row>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={12}>
                                                <SelectField
                                                    autoComplete="nope"
                                                    label={t('ClientContactListForm.country')}
                                                    // @ts-ignore
                                                    options={countriesOptions}
                                                    name="countryId"
                                                    placeholder={t('ClientContactListForm.country')}
                                                    showSearch
                                                    qa={qa.contacts.form.inputCountry}
                                                    onChange={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InputField
                                                    name="city"
                                                    label={t('ClientContactListForm.city')}
                                                    placeholder={t('ClientContactListForm.city')}
                                                    qa={qa.contacts.form.inputCity}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={12}>
                                                <InputField
                                                    name="postalCode"
                                                    label={t('ClientContactListForm.postalCode')}
                                                    placeholder={t('ClientContactListForm.postalCode')}
                                                    qa={qa.contacts.form.inputPostalCode}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InputField
                                                    name="street"
                                                    placeholder={t('ClientContactListForm.street')}
                                                    label={t('ClientContactListForm.street')}
                                                    qa={qa.contacts.form.inputStreet}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={12}>
                                                <InputField
                                                    name="houseNumber"
                                                    label={t('ClientContactListForm.houseNumber')}
                                                    placeholder={t('ClientContactListForm.houseNumber')}
                                                    qa={qa.contacts.form.inputHouseNumber}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InputField
                                                    name="premisesNumber"
                                                    label={t('ClientContactListForm.premisesNumber')}
                                                    placeholder={t('ClientContactListForm.premisesNumber')}
                                                    qa={qa.contacts.form.inputPremisesNumber}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {designType === 'default' && (
                                    <>
                                        <div className="formSection">
                                            <div className="formSectionTitle">
                                                <span>{t('ClientContactListForm.basicInformationTitle')}</span>
                                            </div>
                                            <div className="inputBlock withCheckboxes">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.type')}
                                                </span>
                                                <div className="checkboxes">
                                                    <Checkbox
                                                        name="type"
                                                        className="checkItem"
                                                        checked={clientType.includes(
                                                            ReadOnlyContactListTypeEnum.Client
                                                        )}
                                                        qa={qa.contacts.form.inputTypeClient}
                                                        onChange={() =>
                                                            handleChangeClientType(ReadOnlyContactListTypeEnum.Client)
                                                        }
                                                    >
                                                        {t('ContactList.tableClientTypeValues.client')}
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="type"
                                                        className="checkItem"
                                                        checked={clientType.includes(
                                                            ReadOnlyContactListTypeEnum.Supplier
                                                        )}
                                                        qa={qa.contacts.form.inputTypeSupplier}
                                                        onChange={() =>
                                                            handleChangeClientType(ReadOnlyContactListTypeEnum.Supplier)
                                                        }
                                                    >
                                                        {t('ContactList.tableClientTypeValues.supplier')}
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="type"
                                                        className="checkItem"
                                                        checked={clientType.includes(
                                                            ReadOnlyContactListTypeEnum.Consignee
                                                        )}
                                                        qa={qa.contacts.form.inputTypeSupplier}
                                                        onChange={() =>
                                                            handleChangeClientType(
                                                                ReadOnlyContactListTypeEnum.Consignee
                                                            )
                                                        }
                                                    >
                                                        {t('ContactList.tableClientTypeValues.consignee')}
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.name')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="name"
                                                    placeholder={t('ClientContactListForm.companyName')}
                                                    qa={qa.contacts.form.inputName}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.businessId')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="businessId"
                                                    placeholder={t('ClientContactListForm.businessId')}
                                                    qa={qa.contacts.form.inputBussinessId}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">{t('ClientContactListForm.vat')}</span>
                                                <InputField
                                                    size="large"
                                                    name="vat"
                                                    placeholder={t('ClientContactListForm.vat')}
                                                    qa={qa.contacts.form.inputVat}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.taxId')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="taxId"
                                                    placeholder={t('ClientContactListForm.taxId')}
                                                    qa={qa.contacts.form.inputTaxId}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.otherIdentificationNumber')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="otherIdentificationNumber"
                                                    placeholder={t('ClientContactListForm.otherIdentificationNumber')}
                                                    qa={qa.contacts.form.inputOtherNotificationNm}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                        </div>

                                        <div className="formSection">
                                            <div className="formSectionTitle">
                                                {t('ClientContactListForm.addressOfResidenceTitle')}
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.country')}
                                                </span>
                                                <SelectField
                                                    autoComplete="nope"
                                                    size="large"
                                                    // @ts-ignore
                                                    options={countriesOptions}
                                                    name="countryId"
                                                    placeholder={t('ClientContactListForm.country')}
                                                    showSearch
                                                    qa={qa.contacts.form.inputCountry}
                                                    onChange={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.city')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="city"
                                                    placeholder={t('ClientContactListForm.city')}
                                                    qa={qa.contacts.form.inputCity}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.postalCode')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="postalCode"
                                                    placeholder={t('ClientContactListForm.postalCode')}
                                                    qa={qa.contacts.form.inputPostalCode}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.street')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="street"
                                                    placeholder={t('ClientContactListForm.street')}
                                                    qa={qa.contacts.form.inputStreet}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.houseNumber')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="houseNumber"
                                                    placeholder={t('ClientContactListForm.houseNumber')}
                                                    qa={qa.contacts.form.inputHouseNumber}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                            <div className="inputBlock">
                                                <span className="inputBlockName">
                                                    {t('ClientContactListForm.premisesNumber')}
                                                </span>
                                                <InputField
                                                    size="large"
                                                    name="premisesNumber"
                                                    placeholder={t('ClientContactListForm.premisesNumber')}
                                                    qa={qa.contacts.form.inputPremisesNumber}
                                                    onChangeCapture={() => evaluateSubmitButton()}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <Row justify="end" gutter={12} className="buttons-group">
                                <Col>
                                    <Button
                                        size="middle"
                                        onClick={onCancel}
                                        name="cancel"
                                        qa={qa.planner.createVehicleProfile.btnCancel}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        loading={formik.isSubmitting}
                                        htmlType="submit"
                                        qa={qa.planner.createVehicleProfile.btnSubmit}
                                        disabled={demoMode || locked}
                                        size="middle"
                                        type="primary"
                                    >
                                        {type === 'edit' ? t('common.edit') : t('common.confirm')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(CreateContactForm);
