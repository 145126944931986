/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientUpdateBody,
    ClientUpdateBodyFromJSON,
    ClientUpdateBodyToJSON,
    MainDataResEWClient,
    MainDataResEWClientFromJSON,
    MainDataResEWClientToJSON,
    MainDataResvoid,
    MainDataResvoidFromJSON,
    MainDataResvoidToJSON,
} from '../models';

export interface GetByExternalIdRequest {
    externalId: string;
}

export interface GetByIdRequest {
    clientId: number;
}

export interface UpdateClientRequest {
    clientUpdateBody?: ClientUpdateBody;
}

/**
 * no description
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     * - returns infos about the client by id
     */
    async getByExternalIdRaw(requestParameters: GetByExternalIdRequest): Promise<runtime.ApiResponse<MainDataResEWClient>> {
        if (requestParameters.externalId === null || requestParameters.externalId === undefined) {
            throw new runtime.RequiredError('externalId','Required parameter requestParameters.externalId was null or undefined when calling getByExternalId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/clients/external/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters.externalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResEWClientFromJSON(jsonValue));
    }

    /**
     * - returns infos about the client by id
     */
    async getByExternalId(requestParameters: GetByExternalIdRequest): Promise<MainDataResEWClient> {
        const response = await this.getByExternalIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * - returns infos about the client by id
     */
    async getByIdRaw(requestParameters: GetByIdRequest): Promise<runtime.ApiResponse<MainDataResEWClient>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResEWClientFromJSON(jsonValue));
    }

    /**
     * - returns infos about the client by id
     */
    async getById(requestParameters: GetByIdRequest): Promise<MainDataResEWClient> {
        const response = await this.getByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * - returns infos about the client of the authorized user
     */
    async getByMeRaw(): Promise<runtime.ApiResponse<MainDataResEWClient>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/clients/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResEWClientFromJSON(jsonValue));
    }

    /**
     * - returns infos about the client of the authorized user
     */
    async getByMe(): Promise<MainDataResEWClient> {
        const response = await this.getByMeRaw();
        return await response.value();
    }

    /**
     * - updates the client of the authorized user
     */
    async updateClientRaw(requestParameters: UpdateClientRequest): Promise<runtime.ApiResponse<MainDataResEWClient>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/clients/my`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientUpdateBodyToJSON(requestParameters.clientUpdateBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResEWClientFromJSON(jsonValue));
    }

    /**
     * - updates the client of the authorized user
     */
    async updateClient(requestParameters: UpdateClientRequest): Promise<MainDataResEWClient> {
        const response = await this.updateClientRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async versionRaw(): Promise<runtime.ApiResponse<MainDataResvoid>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/clients/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResvoidFromJSON(jsonValue));
    }

    /**
     */
    async version(): Promise<MainDataResvoid> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
