import { Subject } from 'rxjs';
import { Logic } from 'logic/logic';
import { FuelCardFormModel } from 'common/forms/FuelCardsForm';
import moment from 'moment';
import { FuelCardFilter } from 'modules/management/modules/fuel-cards/FuelCardsModule';
import { PaginatedResponse } from 'common/model/pagination';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';

export class ManagementFuelCardLogic {
    onFuelCardChanged = new Subject<void>();

    constructor(private _logic: Logic) {}

    async getFuelCompanies(): Promise<ReadOnlyFuelCompanySerializer[]> {
        try {
            const res = await this._logic.api().fuelCompanyApi.fuelCompanyList({});
            return res;
        } catch (err) {
            console.error('Get fuel company list err', err);
            throw err;
        }
    }

    async getFuelCard(id: number): Promise<ReadOnlyFuelCardSerializer> {
        try {
            const res = await this._logic.api().fuelCardApi.fuelCardRead({ id });
            return res;
        } catch (err) {
            console.error('Get fuel card list err', err);
            throw err;
        }
    }

    async getFuelCards(filter: FuelCardFilter): Promise<PaginatedResponse<ReadOnlyFuelCardSerializer[]>> {
        try {
            const res = await this._logic.api().fuelCardApi.fuelCardList(filter);
            return {
                data: res.results,
                limit: filter.limit,
                offset: filter.offset,
                total: res.count
            };
        } catch (err) {
            console.error('Get fuel card list err', err);
            throw err;
        }
    }

    async createFuelCard(model: FuelCardFormModel): Promise<boolean> {
        try {
            await this._logic.api().fuelCardApi.fuelCardCreate({
                data: {
                    number: model.number,
                    fuelCompany: Number(model.fuelCompany),
                    holder: model.holder,
                    expirationDate: moment(model.expirationDate).toDate(),
                    note: model.note
                }
            });
            this.onFuelCardChanged.next();
            return true;
        } catch (err) {
            console.error('Create fuel card err', err);
            throw err;
        }
    }

    async updateFuelCard(id: number, model: ReadOnlyFuelCardSerializer): Promise<boolean> {
        try {
            await this._logic.api().fuelCardApi.fuelCardPartialUpdate({
                id,
                data: {
                    number: model.number,
                    fuelCompany: model.fuelCompany,
                    holder: model.holder,
                    expirationDate: model.expirationDate,
                    monitoredObject: model.monitoredObject,
                    user: model.user,
                    note: model.note
                }
            });
            this.onFuelCardChanged.next();
            return true;
        } catch (err) {
            console.error('Update fuel card err', err);
            throw err;
        }
    }

    async deleteFuelCard(id?: number): Promise<boolean> {
        try {
            if (id) {
                await this._logic.api().fuelCardApi.fuelCardDelete({
                    id
                });
                this.onFuelCardChanged.next();
                return true;
            }

            return false;
        } catch (err) {
            console.error('Delete fuel card task err', err);
            throw err;
        }
    }

    async unpairMonitoredObject(model: ReadOnlyFuelCardSerializer) {
        try {
            await this._logic.api().fuelCardApi.fuelCardUpdate({
                id: model.id!,
                data: {
                    number: model.number,
                    fuelCompany: model.fuelCompany,
                    holder: model.holder,
                    expirationDate: model.expirationDate,
                    monitoredObject: null,
                    user: model.user,
                    note: model.note
                }
            });
            this.onFuelCardChanged.next();
            return true;
        } catch (err) {
            console.error('Update fuel card - unpair monitored obj err', err);
            throw err;
        }
    }

    async unpairUser(model: ReadOnlyFuelCardSerializer) {
        try {
            await this._logic.api().fuelCardApi.fuelCardUpdate({
                id: model.id!,
                data: {
                    number: model.number,
                    fuelCompany: model.fuelCompany,
                    holder: model.holder,
                    expirationDate: model.expirationDate,
                    monitoredObject: model.monitoredObject,
                    user: null,
                    note: model.note
                }
            });
            this.onFuelCardChanged.next();
            return true;
        } catch (err) {
            console.error('Update fuel card - unpair monitored obj err', err);
            throw err;
        }
    }

    destroy() {}
}
