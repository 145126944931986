import { action, makeObservable, observable } from 'mobx';
import { Logic } from 'logic/logic';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';
import { FuelCardFilter } from './FuelCardsModule';
import { DriverModel } from 'logic/user/users';
import { FleetModel, FleetType } from 'modules/management/modules/fleet/FleetModule';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { FuelCardFormModel } from 'common/forms/FuelCardsForm';

export interface FuelCompany {
    id: number;
    name: string;
    label: string;
}

export class FuelCardLogic {
    logic: Logic;

    @observable fuelCardFilter: FuelCardFilter;
    @observable fuelCardDetail?: ReadOnlyFuelCardSerializer;
    @observable fuelCards: PaginatedResponse<ReadOnlyFuelCardSerializer[]>;
    @observable fuelCompanies: ReadOnlyFuelCompanySerializer[];
    @observable monitoredObjects: FleetModel[];
    @observable drivers: DriverModel[];
    @observable fuelCardLoading?: boolean;
    @observable loadingCreateFuelCard?: boolean;

    constructor(logic: Logic) {
        this.logic = logic;

        this.fuelCardFilter = {
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET
        };
        this.fuelCards = {
            data: [],
            limit: DEFAULT_PAGE_LIMIT,
            offset: DEFAULT_PAGE_OFFSET,
            total: 0
        };
        this.drivers = [];
        this.monitoredObjects = [];
        this.fuelCompanies = [];
        this.fuelCardLoading = false;

        makeObservable(this);
    }

    @action
    async init() {
        try {
            this.fuelCardLoading = true;

            const fleet = await this.logic.vehicles().getFleetVehicles();
            this.monitoredObjects = fleet.filter(v => v.type !== FleetType.TRAILER);
            this.drivers = await this.logic.users().drivers();
            this.drivers = this.drivers.sort(
                (a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
            );

            await this._fetchFuelCompanies();
            await this._fetchFuelCards({
                limit: this.fuelCards.limit,
                offset: this.fuelCards.offset
            });
        } catch (err) {
            console.error('Loading fuel card init error', err);
            throw err;
        } finally {
            this.fuelCardLoading = false;
        }
    }

    @action
    async setFuelCardDetail(fuelCardId?: number) {
        this.fuelCardDetail = fuelCardId ? this.fuelCards.data.find(d => d.id === fuelCardId) : undefined;
    }

    @action
    async reloadFuelCards() {
        await this._fetchFuelCards(this.fuelCardFilter);
        if (this.fuelCardDetail?.id) {
            this.fuelCardDetail = this.fuelCards.data.find(d => d.id === this.fuelCardDetail?.id);
        }
    }

    @action
    async setFuelCardFilter(filter: FuelCardFilter) {
        this.fuelCardFilter = filter;
    }

    @action
    async createFuelCard(model: FuelCardFormModel) {
        this.loadingCreateFuelCard = true;
        try {
            await this.logic.managementFuelCard().createFuelCard(model);
            return true;
        } catch (err) {
            console.error('Create fuel card err', err);
            throw err;
        } finally {
            this.loadingCreateFuelCard = false;
        }
    }

    @action
    async _fetchFuelCompanies() {
        if (this.logic.demo().isActive) {
            this.fuelCompanies = this.logic.demo().data.fuelCompanies;
            return;
        }

        try {
            const res = await this.logic.managementFuelCard().getFuelCompanies();
            this.fuelCompanies = res;
        } catch (err) {
            console.error(`Failed to get fuel companies, err: ${err}`);
            throw err;
        }
    }

    @action
    private async _fetchFuelCards(filter: FuelCardFilter) {
        if (this.logic.demo().isActive) {
            const data = this.logic.demo().data.fuelCards;
            this.fuelCards = {
                ...this.fuelCards,
                data,
                total: data.length
            };
            return;
        }
        try {
            this.fuelCardLoading = true;
            const res = await this.logic.managementFuelCard().getFuelCards(filter);
            this.fuelCards = res;
        } catch (err) {
            console.error(`Failed to fetch fuel card, err: ${err}`);
            throw err;
        } finally {
            this.fuelCardLoading = false;
        }
    }
}
