import { useTranslation } from 'react-i18next';
import { Select } from 'common/components';
import { ExternalDevicesConfig } from '../utils/useExternalDevicesConfig';

interface Props {
    externalDevicesConfig: ExternalDevicesConfig;
    onChangeZone(zone: number): void;
    onToggleSensor(sensorSerial: string[]): void;
}

const ExternalDevicesOptions = ({ externalDevicesConfig, onChangeZone, onToggleSensor }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="external-devices-options">
            <Select
                onChange={onChangeZone}
                value={externalDevicesConfig.selectedZone}
                disabled={externalDevicesConfig.zones?.length === 0}
                className="zone-select"
            >
                {externalDevicesConfig.zones.sort().map(zone => (
                    <Select.Option value={zone} key={`external-devices-zone-${zone}`}>
                        {t('Coldchain.zone')} {zone}
                    </Select.Option>
                ))}
            </Select>
            <Select
                showSearch
                mode="multiple"
                allowClear
                maxTagCount="responsive"
                className="sensor-select"
                onChange={onToggleSensor}
                value={externalDevicesConfig.selectedSensors}
            >
                {externalDevicesConfig.sensors.map(sensor => (
                    <Select.Option key={`sensors-${sensor.serialNumber}`} value={sensor.serialNumber}>
                        {sensor.sensorName}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ExternalDevicesOptions;
