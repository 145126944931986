import { ReactNode, MouseEvent, Component } from 'react';
import moment, { Moment } from 'moment';
import cn from 'classnames';
import i18next from 'i18next';

import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { Button } from 'common/components';
import qa from 'qa-selectors';

export interface DateTimePickerProps extends PickerDateProps<Moment> {
    trigger?: ReactNode;
    lang?: string;
    qa?: string;
}

interface State {
    dateTime?: Moment | null | undefined;
    open?: boolean;
}

class EnhancedDateTimePicker extends Component<PickerDateProps<Moment>, State> {
    constructor(props: DateTimePickerProps) {
        super(props);
        this.state = {
            dateTime: props.value ?? props.defaultValue,
            open: props.open ?? false
        };
    }

    componentDidUpdate(prevProps: DateTimePickerProps, prevState: State) {
        // Check for reset value after submit formular
        if (this.props.value !== prevProps.value) {
            this.setState({ dateTime: this.props.value ? this.props.value : this.props.defaultValue });
        }
        // check for not setting value when close DateTimePicker withouth OK
        if (
            this.props.showTime &&
            prevState.open === true &&
            this.state.open === false &&
            !this.props.value &&
            this.state.dateTime
        ) {
            this.setState({ dateTime: this.props.value ? this.props.value : this.props.defaultValue });
        }
    }

    render() {
        return (
            <DatePicker
                {...this.props}
                onChange={(value, _dateString) => this._onChange(value)}
                value={this.state.dateTime}
                showToday={
                    this.props.showToday !== false && this.props.showTime === false
                        ? this.props.disabledDate
                            ? !this.props.disabledDate(moment())
                            : this.props.showToday ?? true
                        : false
                }
                open={this.state.open}
                className={cn({ empty: !this.state.dateTime })}
                onOpenChange={this._onOpenChange}
                onSelect={this._onChange}
                onOk={this._onOk}
                format={this.props.format ?? 'l LT'}
                showNow={false}
                allowClear
                monthCellRender={currntDate => (
                    <div
                        className={cn('ant-picker-cell-inner', {
                            'ant-picker-cell-now': currntDate.month() === moment().month()
                        })}
                    >
                        {currntDate.format('MMM')}
                    </div>
                )}
                renderExtraFooter={_mode => {
                    if (this.props.showNow !== false && !this.props.disabledDate?.(moment())) {
                        return (
                            <Button type="text" qa={qa.common.dateTimePicker.btnNow} onClick={this._onNow}>
                                {i18next.t('common.now')}
                            </Button>
                        );
                    } else {
                        return <></>;
                    }
                }}
            />
        );
    }

    private _onChange = (value: Moment | null) => {
        this.setState({ dateTime: value });
        !this.props.showTime && this.props.onChange?.(value, value?.toString() ?? '');
    };

    private _onOk = (value: Moment | null) => {
        this.props.onChange?.(value, value?.toString() ?? '');
    };

    private _onNow = () => {
        this.setState({ dateTime: moment() }, () => {
            this._onOk(this.state.dateTime ?? null);
            this._onOpenChange(false);
        });
    };

    private _onOpenChange = (open: boolean) => {
        this.props.onOpenChange?.(open);
        this.setState({ open });
    };
}

class EnhancedDateTimePickerWithTrigger extends Component<
    DateTimePickerProps,
    {
        open?: boolean;
        show: boolean;
    }
> {
    constructor(props: DateTimePickerProps) {
        super(props);
        this.state = {
            open: props.open ?? false,
            show: true
        };
    }

    componentDidUpdate(prevProps: DateTimePickerProps) {
        // Check for rerender in new language after change
        if (this.props.lang !== prevProps.lang) {
            this.setState({ show: false });
            this.setState({ show: true });
        }
    }

    render() {
        if (this.state.show) {
            if (this.props.trigger) {
                return (
                    <div
                        className={cn('ant-picker-wrapper', { 'ant-picker-wrapper-disabled': this.props.disabled })}
                        onClick={this._onPickerOpen}
                        data-qa={this.props.qa}
                    >
                        {this.props.trigger}
                        {this.state.open && (
                            <EnhancedDateTimePicker
                                {...this.props}
                                open={this.state.open}
                                onOpenChange={this._onOpenChange}
                            />
                        )}
                    </div>
                );
            }

            return <EnhancedDateTimePicker {...this.props} />;
        } else {
            return <></>;
        }
    }
    private _onPickerOpen = (_e: MouseEvent<HTMLDivElement>) => {
        !this.state.open && this._onOpenChange(true);
    };

    private _onOpenChange = (value: boolean) => {
        this.setState({ open: value });
    };
}

export default EnhancedDateTimePickerWithTrigger;
