/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiemsRateForInterval
 */
export interface DiemsRateForInterval {
    /**
     * 
     * @type {number}
     * @memberof DiemsRateForInterval
     */
    f: number;
    /**
     * 
     * @type {number}
     * @memberof DiemsRateForInterval
     */
    t: number;
    /**
     * 
     * @type {number}
     * @memberof DiemsRateForInterval
     */
    rate: number;
}

export function DiemsRateForIntervalFromJSON(json: any): DiemsRateForInterval {
    return DiemsRateForIntervalFromJSONTyped(json, false);
}

export function DiemsRateForIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiemsRateForInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'f': json['f'],
        't': json['t'],
        'rate': json['rate'],
    };
}

export function DiemsRateForIntervalToJSON(value?: DiemsRateForInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'f': value.f,
        't': value.t,
        'rate': value.rate,
    };
}


