import { Logic } from 'logic/logic';
import { ReadOnlyTLMBundleSerializer } from 'generated/new-main';

export class PartnerBundleLogic {
    constructor(private _logic: Logic) {}

    async getBundlesList(): Promise<ReadOnlyTLMBundleSerializer[]> {
        try {
            const response = await this._logic.api().tlmBundleApi.tlmBundleList();
            return response;
        } catch (err) {
            console.log('TLM bundle list GET err:', err);
            throw err;
        }
    }
}
