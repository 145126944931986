/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectCostProfile,
    MonitoredObjectCostProfileFromJSON,
    MonitoredObjectCostProfileFromJSONTyped,
    MonitoredObjectCostProfileToJSON,
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeFromJSONTyped,
    MonitoredObjectFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MoPricePerKmWithOperationalProfile
 */
export interface MoPricePerKmWithOperationalProfile {
    /**
     * 
     * @type {string}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    avgPricePerKm: number;
    /**
     * 
     * @type {number}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    monitoredObjectId: number;
    /**
     * 
     * @type {number}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    distance: number;
    /**
     * 
     * @type {MonitoredObjectFleetType}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    fleetType: MonitoredObjectFleetType;
    /**
     * 
     * @type {MonitoredObjectCostProfile}
     * @memberof MoPricePerKmWithOperationalProfile
     */
    operationalCostProfile?: MonitoredObjectCostProfile;
}

export function MoPricePerKmWithOperationalProfileFromJSON(json: any): MoPricePerKmWithOperationalProfile {
    return MoPricePerKmWithOperationalProfileFromJSONTyped(json, false);
}

export function MoPricePerKmWithOperationalProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoPricePerKmWithOperationalProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'avgPricePerKm': json['avg_price_per_km'],
        'monitoredObjectId': json['monitored_object_id'],
        'distance': json['distance'],
        'fleetType': MonitoredObjectFleetTypeFromJSON(json['fleet_type']),
        'operationalCostProfile': !exists(json, 'operational_cost_profile') ? undefined : MonitoredObjectCostProfileFromJSON(json['operational_cost_profile']),
    };
}

export function MoPricePerKmWithOperationalProfileToJSON(value?: MoPricePerKmWithOperationalProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'avg_price_per_km': value.avgPricePerKm,
        'monitored_object_id': value.monitoredObjectId,
        'distance': value.distance,
        'fleet_type': MonitoredObjectFleetTypeToJSON(value.fleetType),
        'operational_cost_profile': MonitoredObjectCostProfileToJSON(value.operationalCostProfile),
    };
}


