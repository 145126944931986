/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCOOutputFloat
 */
export interface TCOOutputFloat {
    /**
     * 
     * @type {number}
     * @memberof TCOOutputFloat
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof TCOOutputFloat
     */
    units: string;
}

export function TCOOutputFloatFromJSON(json: any): TCOOutputFloat {
    return TCOOutputFloatFromJSONTyped(json, false);
}

export function TCOOutputFloatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOOutputFloat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'units': json['units'],
    };
}

export function TCOOutputFloatToJSON(value?: TCOOutputFloat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'units': value.units,
    };
}


