/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransportDriver
 */
export interface TransportDriver {
    /**
     * 
     * @type {string}
     * @memberof TransportDriver
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportDriver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TransportDriver
     */
    surname?: string;
    /**
     * 
     * @type {Date}
     * @memberof TransportDriver
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportDriver
     */
    endTime?: Date;
}

export function TransportDriverFromJSON(json: any): TransportDriver {
    return TransportDriverFromJSONTyped(json, false);
}

export function TransportDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
    };
}

export function TransportDriverToJSON(value?: TransportDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
    };
}


