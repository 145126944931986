import { Component } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { Aggregator, ExpenseState, ExpenseModel, CargoExpense } from 'logic/statistics/statistics-expenses';
import { ExpensesModel } from './ui/ExpensesTable';
import { CheckboxGroupOpts, CheckboxGroupOpt } from 'common/components/CheckboxGroup';
import { Logic } from 'logic/logic';
import Expenses from './ui/Expenses';
import { ExpenseMode } from './ui/ExpensesCargo';
import { debounce } from 'debounce';
import { withRouter, RouteComponentProps, StaticContext } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import qs from 'qs';
import { RouteParams } from 'modules/statistics/modules/journeys/JourneysModule';
import { DATE_FORMAT } from 'domain-constants';
import { confDefault } from 'conf';
import { exponea } from 'logic/exponea';
import { message } from 'antd';
import { Role } from 'logic/auth';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import { DateRange } from 'common/model/date-time';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import * as H from 'history';
import { RouteNames } from 'App';
import { getCountryIso2FromIso3 } from 'common/model/countries';

const defaultValuesBar = {
    cargo: false,
    dateOpen: false,
    filterOpen: false,
    fuelOnly: false
};

export interface ExpensesFilterModel {
    fullDWL?: boolean;
    vehiclesOpts: CheckboxGroupOpts;
    vehiclesChecked: string[];
    type?: ExpenseType;
    driverId?: number;
    payment?: ExpenseState;
    source?: ExpenseSource;
}

interface Props extends RouteComponentProps<RouteParams, StaticContext, { from?: H.Location }>, WithTranslation {
    logic: Logic;
}

interface State {
    bar: {
        cargo?: boolean;
        dateOpen?: boolean;
        dateChanged?: boolean;
        filterOpen?: boolean;
        backUrl?: boolean;
        fuelOnly?: boolean;
    };
    cargo: {
        vehicles: {
            label: string;
            code: number;
        }[];
        suppliers: {
            label: string;
            code: string;
        }[];
        date: string;
        type: ExpenseType;
        mode: ExpenseMode;
        expense?: CargoExpense;
        loading: boolean;
    };
    dateRange: DateRange;
    filter: ExpensesFilterModel;
    previousFilterType?: ExpenseType;
    helper?: {
        content: string;
    };
    table: {
        data: ExpensesModel[];
        aggregator: Aggregator;
        expandedRow: string;
        loading: boolean;
        selectedExpense?: string;
    };
    deleteConfirmModal: {
        isOpen: boolean;
        id: string;
    };
    backUrlProps?: string;
    roles: Role[];
    lang: string;
    export: {
        enabled: boolean;
        loading: boolean;
    };
}

class ExpensesModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const settings = this._logic.statisticsExpenses().settings();
        const defaults = confDefault.settings.statistics.expenses.filter;
        const roles = this._logic.auth().roles();
        const lang = this._logic.auth().user().lang;

        this.state = {
            bar: {
                cargo: false,
                dateOpen: false,
                filterOpen: false,
                fuelOnly: false
            },
            cargo: {
                vehicles: [],
                suppliers: [],
                date: moment().toISOString(),
                type: ExpenseType.Fuel,
                mode: 'CREATE',
                loading: false
            },
            dateRange: {
                start: defaults.dateRange.start,
                end: defaults.dateRange.end
            },
            filter: {
                vehiclesOpts: [],
                vehiclesChecked: settings.vehicles,
                type: settings.type,
                payment: settings.payment,
                source: settings.source
            },
            table: {
                data: [],
                aggregator: settings.aggregator,
                expandedRow: '',
                loading: true
            },
            deleteConfirmModal: {
                isOpen: false,
                id: ''
            },
            roles,
            lang,
            export: {
                enabled: false,
                loading: true
            }
        };
    }

    componentDidMount() {
        (window as any).app.ExpensesModule = this;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        const filterParams = params.vehicleId !== undefined ? true : false;
        const defaults = confDefault.settings.statistics.expenses.filter;
        this.setState(state => ({
            ...state,
            filter: {
                ...state.filter,
                source: filterParams ? defaults.source : state.filter.source,
                payment: filterParams ? defaults.payment : state.filter.payment,
                type: filterParams ? defaults.type : state.filter.type,
                vehiclesChecked: params.vehicleId ? [params.vehicleId] : state.filter.vehiclesChecked,
                driverId: params.driverId ? parseInt(params.driverId) : undefined
            },
            previousFilterType: filterParams ? defaults.type : state.filter.type,
            dateRange: {
                start: params.startDate ? params.startDate : defaults.dateRange.start,
                end: params.endDate ? params.endDate : defaults.dateRange.end
            },
            bar: {
                ...state.bar,
                dateChanged:
                    (params.startDate && params.startDate !== defaults.dateRange.start) ||
                    (params.endDate && params.endDate !== defaults.dateRange.end)
                        ? true
                        : false,
                backUrl: !!this.props.location.state?.from,
                fuelOnly: (filterParams ? defaults.type : state.filter.type) === ExpenseType.Fuel
            },
            backUrlProps: params.startDate && params.endDate ? qs.stringify(params) : undefined
        }));

        this._filterData().then(() => {
            this._logic.settings().onChange(prop => {
                if (prop.vehicleIdentification) {
                    this._logic.vehicles().changeVehicleIdentification(prop.vehicleIdentification);
                    this._logic
                        .statisticsExpenses()
                        .vehicles()
                        .then(res => {
                            const vehiclesOpts = res.map<CheckboxGroupOpt>(v => ({
                                code: String(v.id),
                                label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
                                checked: false
                            }));
                            this.setState(state => ({
                                filter: {
                                    ...state.filter,
                                    vehiclesOpts
                                }
                            }));
                        });
                }
            });

            this._logic
                .statisticsExpenses()
                .tableData({
                    vehicles: this.state.filter.vehiclesChecked,
                    dateRange: {
                        start: this.state.dateRange.start,
                        end: this.state.dateRange.end
                    },
                    type: this.state.filter.type ? [this.state.filter.type] : undefined,
                    source: this.state.filter.source,
                    payment: this.state.filter.payment,
                    driverId: this.state.filter.driverId,
                    aggregator: this.state.table.aggregator
                })
                .then(({ data }) => {
                    data.map((r, i) => {
                        r.main.rowId = String(i);
                        return r;
                    });
                    const expandedRow = params.selectedDate
                        ? data.find(r => moment(r.main.date).format(DATE_FORMAT) === params.selectedDate)?.main.rowId!
                        : '';
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            data,
                            loading: false,
                            expandedRow
                        },
                        export: {
                            enabled: data.length > 0,
                            loading: false
                        }
                    }));
                })
                .catch(err => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                    message.error(this.props.t('common.error.loadDataError'));
                    console.error(`Load data error, err: ${err}`);
                });
        });
    }

    render() {
        return (
            <Expenses
                lang={this.state.lang}
                bar={this.state.bar}
                cargo={this.state.cargo}
                dateRange={this.state.dateRange}
                filter={this.state.filter}
                helper={this.state.helper}
                table={this.state.table}
                deleteConfirmOpen={this.state.deleteConfirmModal.isOpen}
                roles={this.state.roles}
                demoMode={this.props.logic.demo().isActive}
                export={this.state.export}
                onBarCargoClick={this._onBarCargoClick}
                onBarFilterClick={this._onBarFilterClick}
                onBarBackUrlClick={this._onBarBackUrlClick}
                onBarHelperClick={this._onBarHelperClick}
                onBarFuelOnlyChange={this._onBarFuelOnlyChange}
                onBarResetClick={this._onBarResetClick}
                onCargoSupplierChange={this._onCargoSupplierChange}
                onCargoConfirm={this._onCargoConfirm}
                onCargoCancel={this._onCargoCancel}
                onDateRangeChange={this._onDateRangeChange}
                onFilterConfirm={this._onFilterConfirm}
                onFilterCancel={this._onFilterCancel}
                onBarExportClick={this._onExportClick}
                onHelperClose={this._onHelperClose}
                onTableDetailDeleteClick={this._onTableDetailDeleteClick}
                onTableDetailEditClick={this._onTableDetailEditClick}
                onTableDetailDeleteCancelClick={this._onTableDetailDeleteCancelClick}
                onTableDetailDeleteConfirmClick={this._onTableDetailDeleteConfirmClick}
                onTableRowClick={this._onTableRowClick}
                onTableAggregtorChange={this._onTableAggregatorChange}
                onImportSuccess={this._onImportSuccess}
                onFuelonlyChange={this._onFuelonlyChange}
            />
        );
    }

    private _onImportSuccess = () => {
        this._logic
            .statisticsExpenses()
            .tableData({
                dateRange: {
                    start: this.state.dateRange.start,
                    end: this.state.dateRange.end
                },
                aggregator: this.state.table.aggregator,
                payment: this.state.filter.payment,
                source: this.state.filter.source,
                type: this.state.filter.type ? [this.state.filter.type] : undefined,
                vehicles: this.state.filter.vehiclesChecked
            })
            .then(res => {
                this.setState(state => ({
                    table: { ...state.table, data: res.data, loading: false }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                message.error(this.props.t('common.error.loadDataError'));
                console.error(`Load data error, err: ${err}`);
            });
    };

    private _onBarBackUrlClick = () => {
        const { backUrlProps } = this.state;
        if (backUrlProps) {
            this.props.history.push({
                pathname: RouteNames.STATISTICS_JOURNEYS_ACTIVITY,
                search: backUrlProps
            });
        }
    };

    private _onBarResetClick = (): void => {
        const defaults = confDefault.settings.statistics.expenses.filter;
        this._onFilterConfirm(defaults.vehicles, defaults.type, defaults.payment, defaults.source);
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'can';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onBarFuelOnlyChange = (checked: boolean) => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    fuelOnly: checked
                },
                filter: {
                    ...state.filter,
                    type: checked ? ExpenseType.Fuel : ('' as ExpenseType)
                }
            }),
            () => {
                this._reloadExpenses();
            }
        );
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onCargoSupplierChange = debounce((value: string) => {
        this._logic
            .routing()
            .suggestion()
            .getCustomPlaceSuggestions(value)
            .then(fuelStations => {
                const cargoFuelStations = fuelStations
                    .filter(f => f.source === 'FUEL_STATIONS')
                    .map<{
                        code: string;
                        label: string;
                    }>(f => ({
                        code: f.id || '',
                        label: f.label || ''
                    }));
                this.setState(state => ({
                    cargo: {
                        ...state.cargo,
                        suppliers: cargoFuelStations
                    }
                }));
            });
    }, 300);

    private _onBarCargoClick = () => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    cargo: !state.bar.cargo,
                    dateOpen: false,
                    filterOpen: false
                },
                table: {
                    ...state.table,
                    selectedExpense: undefined
                },
                cargo: {
                    vehicles: [],
                    suppliers: [],
                    date: moment().toISOString(),
                    type: ExpenseType.Fuel,
                    mode: 'CREATE',
                    loading: false
                }
            }),
            () => {
                if (this.state.cargo) {
                    this._logic
                        .statisticsExpenses()
                        .vehicles()
                        .then(res => {
                            const cargoVehiclesOpts = res.map<{
                                code: number;
                                label: string;
                            }>(v => ({
                                code: v.id ?? 0,
                                label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber)
                            }));

                            this.setState(state => ({
                                cargo: {
                                    ...state.cargo,
                                    vehicles: cargoVehiclesOpts
                                }
                            }));
                        })
                        .catch(e => console.error(e));
                }
            }
        );
    };

    private _onFuelonlyChange = (checked: boolean): void => {
        const type = checked
            ? ExpenseType.Fuel
            : this.state.previousFilterType !== ExpenseType.Fuel
            ? this.state.previousFilterType
            : undefined;

        this._logic.statisticsExpenses().setSettings({
            vehicles:
                this.state.filter.vehiclesChecked.length >= this.state.filter.vehiclesOpts.length
                    ? []
                    : this.state.filter.vehiclesChecked,
            type,
            payment: this.state.filter.payment,
            source: this.state.filter.source
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    fuelOnly: checked
                },
                filter: {
                    ...state.filter,
                    type: type
                },
                previousFilterType: state.filter.type,
                table: {
                    ...state.table,
                    loading: true
                },
                export: {
                    enabled: false,
                    loading: true
                }
            }),
            () => this._updateData()
        );
    };

    private _filterIsChanged(filter: ExpensesFilterModel): boolean {
        const defaults = confDefault.settings.statistics.expenses.filter;

        return (filter.type && filter.type !== defaults.type) ||
            (filter.payment && filter.payment !== defaults.payment) ||
            (filter.source && filter.source !== defaults.source) ||
            (filter.vehiclesChecked.length > 0 && filter.vehiclesChecked.length < this.state.filter.vehiclesOpts.length)
            ? true
            : false;
    }

    private _updateData() {
        this._logic
            .statisticsExpenses()
            .tableData({
                dateRange: {
                    start: this.state.dateRange.start,
                    end: this.state.dateRange.end
                },
                payment: this.state.filter.payment,
                source: this.state.filter.source,
                type: this.state.filter.type ? [this.state.filter.type] : undefined,
                vehicles: this.state.filter.vehiclesChecked,
                aggregator: this.state.table.aggregator
            })
            .then(res => {
                console.log(res.data);
                this.setState(state => ({
                    table: { ...state.table, data: res.data, loading: false },
                    export: {
                        enabled: res.data.length > 0,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    },
                    export: {
                        enabled: false,
                        loading: false
                    }
                }));
                message.error(this.props.t('common.error.loadDataError'));
                console.error(`Load data error, err: ${err}`);
            });
    }

    private _filterData = async () => {
        const vehicles = await this._logic.statisticsExpenses().vehicles();
        const vehiclesOpts = vehicles.map<CheckboxGroupOpt>(v => ({
            code: String(v.id),
            label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
            checked: false
        }));

        this.setState(state => ({
            filter: {
                ...state.filter,
                fullDWL: this._logic.vehicles().fullDWL,
                vehiclesChecked:
                    state.filter.vehiclesChecked.length > 0 && state.filter.vehiclesChecked.length < vehiclesOpts.length
                        ? state.filter.vehiclesChecked
                        : vehiclesOpts.map(v => v.code),
                vehiclesOpts
            }
        }));
    };

    private _onBarFilterClick = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                cargo: false,
                dateOpen: false,
                filterOpen: !state.bar.filterOpen
            }
        }));
    };

    private _onCargoConfirm = async (cargo: CargoExpense) => {
        this._logic.exponea().trackEvent(exponea.module.statisticsExpenses, {
            status: exponea.status.actionTaken,
            action: exponea.action.addManualExpense
        });

        try {
            await this._logic
                .statisticsExpenses()
                .createOrUpdateExpense(cargo)
                .then(res => {
                    if (res) {
                        this.state.cargo.mode === 'EDIT'
                            ? message.success(this.props.t('ExpensesCargo.editExpenseSuccess'))
                            : message.success(this.props.t('ExpensesCargo.createExpenseSuccess'));
                    } else {
                        this.state.cargo.mode === 'EDIT'
                            ? message.error(this.props.t('ExpensesCargo.error.editExpenseError'))
                            : message.error(this.props.t('ExpensesCargo.error.createExpenseError'));
                    }
                });
            this.setState(state => ({
                cargo: {
                    ...state.cargo,
                    loading: true
                },
                table: {
                    ...state.table,
                    selectedExpense: undefined
                }
            }));
        } catch (err) {
            if (cargo.expenseId) {
                this.state.cargo.mode === 'EDIT'
                    ? message.error(this.props.t('ExpensesCargo.error.editExpenseError'))
                    : message.error(this.props.t('ExpensesCargo.error.createExpenseError'));
            } else {
                message.error(this.props.t('ExpensesCargo.error.editExpenseError'));
            }
            return false;
        }

        this.setState(
            state => ({
                table: {
                    ...this.state.table,
                    loading: true
                },
                bar: {
                    ...state.bar,
                    cargo: false
                },
                cargo: {
                    vehicles: [],
                    suppliers: [],
                    date: moment().toISOString(),
                    type: ExpenseType.Fuel,
                    loading: false,
                    mode: 'CREATE'
                },
                export: {
                    enabled: false,
                    loading: true
                }
            }),
            () => this._updateData()
        );

        return true;
    };

    private _onCargoCancel = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                cargo: false,
                dateOpen: false,
                filterOpen: false,
                backUrl: state.bar.backUrl
            },
            cargo: {
                vehicles: [],
                suppliers: [],
                date: moment().toISOString(),
                type: ExpenseType.Fuel,
                mode: 'CREATE',
                loading: false
            },
            table: {
                ...state.table,
                selectedExpense: undefined
            }
        }));
    };

    private _onDateRangeChange = (dateRange: DateRange) => {
        this._logic.exponea().trackEvent(exponea.module.statisticsExpenses, {
            status: exponea.status.actionTaken,
            action: exponea.action.calendar
        });

        const params: RouteParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                startDate: dateRange.start,
                endDate: dateRange.end
            } as RouteParams)
        });
        const defaults = confDefault.settings.statistics.expenses.filter;

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    dateOpen: false,
                    dateChanged:
                        dateRange.start !== defaults.dateRange.start || dateRange.end !== defaults.dateRange.end
                },
                table: {
                    ...state.table,
                    loading: true
                },
                dateRange: dateRange ? dateRange : state.dateRange,
                export: {
                    enabled: false,
                    loading: true
                }
            }),
            () => this._updateData()
        );
    };

    private _onFilterConfirm = (
        vehiclesChecked: string[],
        type?: ExpenseType,
        payment?: ExpenseState,
        source?: ExpenseSource
    ) => {
        this._logic.exponea().trackEvent(exponea.module.statisticsExpenses, {
            status: exponea.status.actionTaken,
            action: exponea.action.filter
        });

        this._logic.statisticsExpenses().setSettings({
            vehicles: vehiclesChecked.length >= this.state.filter.vehiclesOpts.length ? [] : vehiclesChecked,
            type,
            payment,
            source
        });

        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    vehiclesChecked,
                    type,
                    payment,
                    source
                },
                bar: {
                    ...state.bar,
                    ...defaultValuesBar,
                    filterOpen: false,
                    fuelOnly: type === ExpenseType.Fuel,
                    backUrl: state.bar.backUrl
                },
                table: { ...state.table, loading: true },
                export: {
                    enabled: false,
                    loading: true
                }
            }),
            () => this._updateData()
        );
    };

    private _onFilterCancel = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                filterOpen: false
            }
        }));
    };

    private _onExportClick = () => {
        const data = this.state.table.data;
        if (data && data.length > 0) {
            this.setState(
                {
                    export: {
                        enabled: false,
                        loading: true
                    }
                },
                () => {
                    this._logic
                        .statisticsExpenses()
                        .downloadExpensesExport(data, this.state.dateRange.start, this.state.dateRange.end)
                        .finally(() => {
                            this.setState({
                                export: {
                                    enabled: true,
                                    loading: false
                                }
                            });
                        });
                }
            );
        }
    };

    private _onTableDetailDeleteClick = (id: string) => {
        this.setState({
            deleteConfirmModal: {
                isOpen: true,
                id
            }
        });
    };

    private _onTableDetailDeleteCancelClick = () => {
        this.setState({
            deleteConfirmModal: {
                isOpen: false,
                id: ''
            }
        });
    };

    private _onTableDetailDeleteConfirmClick = () => {
        this._logic
            .statisticsExpenses()
            .deleteExpense(this.state.deleteConfirmModal.id)
            .then(success => {
                if (success) {
                    this.setState(
                        {
                            bar: {
                                ...this.state.bar,
                                cargo:
                                    this.state.table.selectedExpense === this.state.deleteConfirmModal.id
                                        ? false
                                        : this.state.bar.cargo
                            },
                            table: {
                                ...this.state.table,
                                loading: true,
                                selectedExpense:
                                    this.state.table.selectedExpense === this.state.deleteConfirmModal.id
                                        ? undefined
                                        : this.state.table.selectedExpense
                            },
                            deleteConfirmModal: {
                                id: '',
                                isOpen: false
                            },
                            export: {
                                enabled: false,
                                loading: true
                            }
                        },
                        () => this._updateData()
                    );
                } else {
                    message.error(this.props.t('ExpensesCargo.error.deleteExpenseError'));
                }
            })
            .catch(_err => {
                message.error(this.props.t('ExpensesCargo.error.deleteExpenseError'));
            });
    };
    private _onTableDetailEditClick = (data: ExpenseModel) => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    cargo: true,
                    dateOpen: false,
                    filterOpen: false
                },
                cargo: {
                    vehicles: [],
                    suppliers: [],
                    date: moment().toISOString(),
                    type: ExpenseType.Fuel,
                    mode: 'EDIT',
                    loading: true
                },
                table: {
                    ...state.table,
                    selectedExpense: data.id
                }
            }),
            () => {
                this._logic
                    .statisticsExpenses()
                    .vehicles()
                    .then(res => {
                        const cargoVehiclesOpts = res.map<{
                            code: number;
                            label: string;
                        }>(v => ({
                            code: v.id ?? 0,
                            label: v.registrationNumber
                        }));

                        this.setState(state => ({
                            bar: {
                                cargo: true
                            },
                            cargo: {
                                ...state.cargo,
                                vehicles: cargoVehiclesOpts,
                                suppliers: [
                                    {
                                        code: data.fuelStation.id,
                                        label: data.fuelStation.name
                                    }
                                ],
                                mode: 'EDIT',
                                expense: {
                                    expenseId: data.id,
                                    date: data.date,
                                    priceTotal: data.priceTotalWithVAT.toString(),
                                    quantity: data.quantityTotal.toString(),
                                    supplier: data.fuelStation.id,
                                    fuelStation: {
                                        id: data.fuelStation.id,
                                        name: data.fuelStation.name
                                    },
                                    country: getCountryIso2FromIso3(data.cargoCountry),
                                    type: data.type as ExpenseType,
                                    vehicleId: data.monitoredObjectId
                                },
                                loading: false
                            }
                        }));
                    })
                    .catch(e => console.error(e));
            }
        );
    };
    private _onTableRowClick = (rowId: string) => {
        this.setState(state => ({
            table: {
                ...state.table,
                expandedRow: rowId === state.table.expandedRow ? '' : rowId
            }
        }));
    };

    private _reloadExpenses() {
        this.setState({
            table: { ...this.state.table, loading: true }
        });
        this._logic
            .statisticsExpenses()
            .tableData({
                dateRange: {
                    start: this.state.dateRange.start,
                    end: this.state.dateRange.end
                },
                aggregator: this.state.table.aggregator,
                payment: this.state.filter.payment,
                source: this.state.filter.source,
                type: this.state.filter.type ? [this.state.filter.type] : undefined,
                vehicles: this.state.filter.vehiclesChecked
            })
            .then(({ data }) => {
                this.setState({
                    table: { ...this.state.table, data, loading: false }
                });
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                message.error(this.props.t('common.error.loadDataError'));
                console.error(`Load data error, err: ${err}`);
            });
    }

    private _onTableAggregatorChange = (aggregator: Aggregator) => {
        this._logic.statisticsExpenses().setSettings({ aggregator: aggregator });
        this.setState(
            state => ({
                table: { ...state.table, aggregator, loading: true },
                export: {
                    enabled: false,
                    loading: true
                }
            }),
            () => this._updateData()
        );
    };
}

export default withTranslation()(withRouter(ExpensesModule));
