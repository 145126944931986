/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackendApiApiV1DriverbehaviourDriverBehaviourResponse,
    BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSON,
    BackendApiApiV1DriverbehaviourDriverBehaviourResponseToJSON,
    DriverBehaviourTrendsResponse,
    DriverBehaviourTrendsResponseFromJSON,
    DriverBehaviourTrendsResponseToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGetRequest {
    name?: string;
    driverId?: number;
    fromT?: Date;
}

export interface GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsGetRequest {
    fromT: string;
    toT: string;
    name?: string;
    driverId?: number;
    withContextualScoring?: boolean;
}

export interface GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGetRequest {
    fromT: string;
    toT: string;
    name?: string;
    driverId?: number;
    withContextualScoring?: boolean;
}

export interface GetDriverBehaviourV1DriverbehaviourMyGetRequest {
    name?: string;
    driverId?: number;
    fromT?: Date;
}

/**
 * no description
 */
export class DriverBehaviourApi extends runtime.BaseAPI {

    /**
     * Get Driver Behaviour Mobile
     */
    async getDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGetRaw(requestParameters: GetDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGetRequest): Promise<runtime.ApiResponse<BackendApiApiV1DriverbehaviourDriverBehaviourResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = (requestParameters.fromT as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/my/mobile-apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSON(jsonValue));
    }

    /**
     * Get Driver Behaviour Mobile
     */
    async getDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGet(requestParameters: GetDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGetRequest): Promise<BackendApiApiV1DriverbehaviourDriverBehaviourResponse> {
        const response = await this.getDriverBehaviourMobileV1DriverbehaviourMyMobileAppsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * e.g.: for sept + oct+ nov -> from_t: 2021-09-01 to_t: 2021-12-01
     * Get Driver Behaviour Trends
     */
    async getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGetRaw(requestParameters: GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsGetRequest): Promise<runtime.ApiResponse<DriverBehaviourTrendsResponse>> {
        if (requestParameters.fromT === null || requestParameters.fromT === undefined) {
            throw new runtime.RequiredError('fromT','Required parameter requestParameters.fromT was null or undefined when calling getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGet.');
        }

        if (requestParameters.toT === null || requestParameters.toT === undefined) {
            throw new runtime.RequiredError('toT','Required parameter requestParameters.toT was null or undefined when calling getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = requestParameters.fromT;
        }

        if (requestParameters.toT !== undefined) {
            queryParameters['to_t'] = requestParameters.toT;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.withContextualScoring !== undefined) {
            queryParameters['with_contextual_scoring'] = requestParameters.withContextualScoring;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/my/trends`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverBehaviourTrendsResponseFromJSON(jsonValue));
    }

    /**
     * e.g.: for sept + oct+ nov -> from_t: 2021-09-01 to_t: 2021-12-01
     * Get Driver Behaviour Trends
     */
    async getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGet(requestParameters: GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsGetRequest): Promise<DriverBehaviourTrendsResponse> {
        const response = await this.getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Driver Behaviour Trends
     */
    async getDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGetRaw(requestParameters: GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGetRequest): Promise<runtime.ApiResponse<DriverBehaviourTrendsResponse>> {
        if (requestParameters.fromT === null || requestParameters.fromT === undefined) {
            throw new runtime.RequiredError('fromT','Required parameter requestParameters.fromT was null or undefined when calling getDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGet.');
        }

        if (requestParameters.toT === null || requestParameters.toT === undefined) {
            throw new runtime.RequiredError('toT','Required parameter requestParameters.toT was null or undefined when calling getDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = requestParameters.fromT;
        }

        if (requestParameters.toT !== undefined) {
            queryParameters['to_t'] = requestParameters.toT;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.withContextualScoring !== undefined) {
            queryParameters['with_contextual_scoring'] = requestParameters.withContextualScoring;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/my/trends-mobile-apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverBehaviourTrendsResponseFromJSON(jsonValue));
    }

    /**
     * Get Driver Behaviour Trends
     */
    async getDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGet(requestParameters: GetDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGetRequest): Promise<DriverBehaviourTrendsResponse> {
        const response = await this.getDriverBehaviourTrendsV1DriverbehaviourMyTrendsMobileAppsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Driver Behaviour
     */
    async getDriverBehaviourV1DriverbehaviourMyGetRaw(requestParameters: GetDriverBehaviourV1DriverbehaviourMyGetRequest): Promise<runtime.ApiResponse<BackendApiApiV1DriverbehaviourDriverBehaviourResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = (requestParameters.fromT as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSON(jsonValue));
    }

    /**
     * Get Driver Behaviour
     */
    async getDriverBehaviourV1DriverbehaviourMyGet(requestParameters: GetDriverBehaviourV1DriverbehaviourMyGetRequest): Promise<BackendApiApiV1DriverbehaviourDriverBehaviourResponse> {
        const response = await this.getDriverBehaviourV1DriverbehaviourMyGetRaw(requestParameters);
        return await response.value();
    }

}
