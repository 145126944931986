import { Descriptions, DescriptionsProps } from 'antd';

interface Props extends DescriptionsProps {
    qa?: string;
    colon?: boolean;
    children?: JSX.Element | JSX.Element[];
}

export default function EnhancedDescriptions({ qa, colon, ...props }: Props) {
    return <Descriptions data-qa={qa} colon={colon ?? false} {...props} />;
}

EnhancedDescriptions.Item = Descriptions.Item;
