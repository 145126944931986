import Confirm, { MessageType } from 'common/components/Confirm';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithLogic, withLogicContext } from 'App';

import { FuelCardsLinks, Pairing, PairingDataSelected, Unpairing, UnpairType } from '../FuelCardsModule';
import FuelCardsDetail from './FuelCardsDetail';
import FuelCardsPairing from './FuelCardsPairing';
import FuelCardsTable from './FuelCardsTable';
import { LayoutContent } from 'common/components/Layout/Content';
import FuelCardsLinkedItems from './FuelCardsLinkedItems';
import { Role } from 'logic/auth';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import { Button } from 'common/components';
import qa from 'qa-selectors';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import Collapse from 'common/components/Collapse';
import FuelCardsActions from './FuelCardsActions';
import { fuelCards } from 'resources/images/sidebar';
import FuelCardsForm, { FuelCardFormModel } from 'common/forms/FuelCardsForm';
import Modal from 'common/components/Modal';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { ReadOnlyFuelCardSerializer, ReadOnlyFuelCompanySerializer } from 'generated/new-main';
import { FleetModel } from '../../fleet/FleetModule';
import { DriverModel } from 'logic/user/users';

interface Props extends WithTranslation, WithLogic {
    create: boolean;
    edit: boolean;
    loading?: boolean;
    roles: Role[];
    fuelCompanies: ReadOnlyFuelCompanySerializer[];
    drivers: DriverModel[];
    monitoredObjects: FleetModel[];
    fuelCards: PaginatedResponse<ReadOnlyFuelCardSerializer[]>;
    pairing?: Pairing;
    search?: { text: string };
    selected?: ReadOnlyFuelCardSerializer;
    delete: {
        isOpen: boolean;
        isLoading: boolean;
    };
    unpairing?: Unpairing;
    helper?: {
        content: string;
    };
    lang: string;
    demoMode?: boolean;
    removeFuelCardLoading: boolean;
    pairFuelCardLoading: boolean;
    unpairFuelCardLoading: boolean;
    onBarSearchButtonClick?: () => void;
    onBarSearchChange?: (text: string) => void;
    onBarHelperClick?: () => void;
    onBarCreateClick?: () => void;
    onCreateSubmitClick?: (values: FuelCardFormModel) => Promise<boolean>;
    onCreateCancelClick?: () => void;
    onDetailFormCancelClick?: () => void;
    onDetailFormSubmitClick?: (values: FuelCardFormModel) => Promise<boolean>;
    onConfirmDeleteCancelClick?: () => void;
    onConfirmDeteleSubmitClick?: () => void;
    onDetailUnpairClick?: (model: UnpairType, type: keyof FuelCardsLinks) => void;
    onFuelCardPair?: () => void;
    onPairingCancelClick?: () => void;
    onPairingConfirmClick?: () => void;
    onPairingItemSelect?: (selected: PairingDataSelected) => void;
    onPairingSearchChange?: (text: string) => void;
    onConfirmPairingSubmitClick?: () => void;
    onPairingTypeChange?: (linkType: keyof FuelCardsLinks) => void;
    onTableRowSelect?: (id: number) => void;
    onTablePaginationChange?: (pagination: PaginationParams) => void;
    onConfirmUnpairCancelClick?: () => void;
    onConfirmUnpairSubmitClick?: () => void;
    onHelperClose?: () => void;
    onActionsUpdateClick?: () => void;
    onActionsPairingClick?: () => void;
    onActionsDeleteClick?: () => void;
}

function FuelCards(props: Props) {
    const { t, demoMode } = props;

    const pairingConfirmMessagePrevious =
        props.pairing?.confirm?.type === 'vehicle' && props.selected?.monitoredObject
            ? t('ManagementPairing.fleetPaired')
            : props.pairing?.confirm?.type === 'user' && props.selected?.user
            ? t('ManagementPairing.userPaired')
            : '';
    const pairingConfirmMessageType =
        props.pairing?.confirm?.type === 'vehicle' ? t('ManagementPairing.vehicle') : t('ManagementPairing.user');

    return (
        <>
            <Modal
                width={350}
                footer={null}
                onCancel={props.onCreateCancelClick}
                closable={false}
                maskClosable={false}
                visible={props.create}
                title={t('ManagementFuelCards.createNewFuelCard')}
                destroyOnClose={true}
                className="fuel-cards-form-modal"
            >
                <FuelCardsForm
                    mode={'CREATE'}
                    demoMode={props.demoMode}
                    fuelCompanies={props.fuelCompanies}
                    lang={props.lang}
                    onCancel={props.onCreateCancelClick}
                    onSubmit={props.onCreateSubmitClick}
                />
            </Modal>

            {props.delete.isOpen && (
                <Confirm
                    danger
                    header={t('ManagementFuelCards.deleteHeader')}
                    message={`${t('ManagementFuelCards.deleteConfirm')} ${props.selected?.number}`}
                    type={MessageType.WARNING}
                    loading={props.delete.isLoading}
                    confirmLabel={props.t('common.delete')}
                    onConfirm={props.onConfirmDeteleSubmitClick}
                    onCancel={props.onConfirmDeleteCancelClick}
                />
            )}

            {props.pairing && (
                <FuelCardsPairing
                    data={props.pairing.data}
                    loading={props.pairing.loading}
                    model={props.selected}
                    search={props.pairing.search}
                    selected={props.pairing.selected}
                    type={props.pairing.type}
                    onSearchInputChange={props.onPairingSearchChange}
                    onPairingTypeChange={props.onPairingTypeChange}
                    onPairingItemSelect={props.onPairingItemSelect}
                    onConfirmButtonClick={props.onPairingConfirmClick}
                    onCancelButtonClick={props.onPairingCancelClick}
                />
            )}

            {/*
                TODO:
                - in furure for multiple type of pairing
                - switch messages based on model type
            */}
            {props.pairing?.confirm && (
                <Confirm
                    message={`${pairingConfirmMessagePrevious} ${pairingConfirmMessageType}`}
                    type={MessageType.INFO}
                    loading={props.pairFuelCardLoading}
                    onConfirm={props.onConfirmPairingSubmitClick}
                    onCancel={props.onPairingCancelClick}
                />
            )}

            {/*
                TODO:
                - in furure for multiple type of unpairing
                - switch messages based on model type
            */}
            {props.unpairing && (
                <Confirm
                    message={t('ManagementUnpairing.fuel')}
                    type={MessageType.INFO}
                    loading={props.unpairFuelCardLoading}
                    onConfirm={props.onConfirmUnpairSubmitClick}
                    onCancel={props.onConfirmUnpairCancelClick}
                />
            )}

            <HelperModal
                name="fuel-cards"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />

            <LayoutContent
                className="management-fuel-cards no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.fuelCards')}
                            search={props.search}
                            onSearchChange={props.onBarSearchChange}
                            onHelperClick={props.onBarHelperClick}
                            actions={[
                                <Button
                                    type="dashed"
                                    size="large"
                                    onClick={props.onBarCreateClick}
                                    disabled={demoMode || !props.roles.includes(Role.EX_W)}
                                    qa={qa.fuel.btnNew}
                                >
                                    {t('ManagementFuelCards.createNewFuelCard')}
                                </Button>
                            ]}
                        />
                        <FuelCardsTable
                            loading={props.loading}
                            fuelCards={props.fuelCards}
                            fuelCompanies={props.fuelCompanies}
                            selected={props.selected}
                            onRowSelect={props.onTableRowSelect}
                            onPaginationChange={props.onTablePaginationChange}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            header={props.logic.fuelCard().fuelCardDetail?.number}
                            headerIcon={fuelCards}
                            body={
                                <>
                                    <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                        <Collapse.Panel header={props.t('ManagementFuelCards.fuelCardsDetail')} key="1">
                                            <FuelCardsDetail
                                                edit={props.edit}
                                                roles={props.roles}
                                                model={props.selected}
                                                demoMode={props.demoMode}
                                                lang={props.lang}
                                                fuelCompanies={props.fuelCompanies}
                                                onFormCancelClick={props.onDetailFormCancelClick}
                                                onFormSubmitClick={props.onDetailFormSubmitClick}
                                            />
                                        </Collapse.Panel>
                                        {!props.edit ? (
                                            <Collapse.Panel header={props.t('ManagementFleet.linkedItems')} key="2">
                                                <FuelCardsLinkedItems
                                                    roles={props.roles}
                                                    monitoredObjects={props.monitoredObjects}
                                                    drivers={props.drivers}
                                                    model={props.selected}
                                                    demoMode={props.demoMode}
                                                    onUnpair={props.onDetailUnpairClick}
                                                />
                                            </Collapse.Panel>
                                        ) : (
                                            <></>
                                        )}
                                    </Collapse>
                                </>
                            }
                            footer={
                                !props.edit && (
                                    <FuelCardsActions
                                        roles={props.roles}
                                        demoMode={props.demoMode}
                                        onUpdateClick={props.onActionsUpdateClick}
                                        onPairingClick={props.onActionsPairingClick}
                                        onDeleteClick={props.onActionsDeleteClick}
                                    />
                                )
                            }
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(withLogicContext(FuelCards));
