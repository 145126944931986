import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'common/components';
interface Props {
    name: string;
    content: string;
    visible: boolean;
    onClose?: () => void;
}

export default function HelperModal({ ...props }: Props) {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('common.helper')}
            className={`modal-docs modal-docs-${props.name}`}
            width="900px"
            visible={props.visible ?? true}
            closable={true}
            footer={null}
            destroyOnClose
            onCancel={props.onClose}
        >
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </Modal>
    );
}
