import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import cn from 'classnames';
import Search from 'common/components/Search';
import { InputField, SelectField } from 'common/fields';
import { Button, Typography } from 'common/components';
import { Radio } from 'common/components/Form';
import { Loading } from 'common/components/Loading';
import { searched } from 'common/utils/search';
import { ReadOnlyCountry } from 'generated/new-main/models';
import { TachoCardsLinks } from 'modules/management/modules/tachograph-cards/TachoCardsModule';
import { UserModel } from 'modules/management/modules/management-users/UsersModule';
import { NoData } from 'common/components/NoData';
import qa from 'qa-selectors';
import 'flag-icon-css/css/flag-icon.css';

export type TachoCardPairingTypes = keyof TachoCardsLinks;

export interface TachoCardCreatePairFormModel {
    countryId?: number;
    token: string;
    username?: string;
    name?: string;
    surname?: string;
    pin?: string;
    userId?: string;
}

enum userPairType {
    existed,
    new
}

interface Props extends WithTranslation {
    users?: UserModel[];
    usersLoading?: boolean;
    initialValues?: Partial<TachoCardCreatePairFormModel>;
    countries: ReadOnlyCountry[];
    search?: string;
    demoMode?: boolean;
    onSubmit?: (values: TachoCardCreatePairFormModel) => Promise<boolean>;
    onCancel?: () => void;
    onSearchInputChange?: (text: string) => void;
}

function TachoCardCreatePairForm(props: Props) {
    let form: FormikProps<TachoCardCreatePairFormModel>;
    const [selectedUserPairType, setSelectedUserPairType] = useState<userPairType>(userPairType.existed);
    const [selectedUser, setSelectedUser] = useState<UserModel>();
    const [searchUser, setSearchUser] = useState<string>('');
    const [validateUser, setValidateUser] = useState<boolean>(false);

    const countriesOptions = props.countries
        .filter(c => c.tachoAlpha)
        .map(country => {
            return {
                value: country.id ?? 0,
                label: (
                    <div>
                        <span className={`flag-icon-tacho flag-icon flag-icon-${country.iso2.toLocaleLowerCase()}`} />
                        {country.name} {` (${country.iso2.toUpperCase()})`}
                    </div>
                ) as React.ReactNode
            };
        });

    const schema = Yup.object().shape({
        countryId: Yup.number().required(props.t('common.required')),
        userId: Yup.string().when([], {
            is: () => selectedUserPairType === userPairType.existed,
            then: Yup.string().required(props.t('common.required')),
            otherwise: Yup.string().notRequired()
        }),
        token: Yup.string()
            .required(props.t('common.required'))
            .min(16, `${props.t('validator.min')} 16 ${props.t('validator.characters_more_as_4')}`)
            .max(16, `${props.t('validator.max')} 16 ${props.t('validator.characters_more_as_4')}`),
        name: Yup.string().when([], {
            is: () => selectedUserPairType === userPairType.new,
            then: Yup.string()
                .required(props.t('common.required'))
                .matches(/^[a-zA-ZÀ-ž ]*[a-zA-ZÀ-ž]+[a-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
            otherwise: Yup.string().notRequired()
        }),
        surname: Yup.string().when([], {
            is: () => selectedUserPairType === userPairType.new,
            then: Yup.string()
                .required(props.t('common.required'))
                .matches(/^[a-zA-ZÀ-ž ]*[a-zA-ZÀ-ž]+[a-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
            otherwise: Yup.string().notRequired()
        }),
        pin: Yup.string().when([], {
            is: () => selectedUserPairType === userPairType.new,
            then: Yup.string()
                .required(props.t('common.required'))
                .min(0)
                .max(8, props.t('validator.not_in_range'))
                .matches(/^[0-9]+$/, props.t('validator.invalid_format')),
            otherwise: Yup.string().notRequired()
        }),
        username: Yup.string().when([], {
            is: () => selectedUserPairType === userPairType.new,
            then: Yup.string()
                .required(props.t('common.required'))
                .matches(/^[^\s].+[^\s]$/, props.t('validator.invalid_format')),
            otherwise: Yup.string().notRequired()
        })
    });

    const onSearch = debounce((text: string) => {
        setSearchUser(text);
    }, 300);

    function onUserPairTypeChange(pairType: userPairType) {
        setSelectedUserPairType(pairType);
    }

    function handleSubmit(values: TachoCardCreatePairFormModel) {
        if (selectedUserPairType === userPairType.new) {
            values.userId = undefined;
        } else {
            values.userId = selectedUser?.id;
        }
        return props.onSubmit?.(values);
    }

    function searchFilter(data: UserModel): boolean {
        if (searchUser) {
            return searched(searchUser, data.name) || searched(searchUser, data.surname);
        }
        return true;
    }

    function onItemSelect(selected: UserModel): void {
        setSelectedUser(selected);
        form.setFieldValue('userId', selected.id, true);
    }

    return (
        <Formik<TachoCardCreatePairFormModel>
            initialValues={{
                token: props.initialValues?.token ?? '',
                countryId: props.initialValues?.countryId,
                username: props.initialValues?.username ?? '',
                name: props.initialValues?.name ?? '',
                surname: props.initialValues?.surname ?? '',
                pin: props.initialValues?.pin ?? ''
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnChange={true}
        >
            {(formik: FormikProps<TachoCardCreatePairFormModel>) => {
                form = formik;
                return (
                    <Form className="tacho-card-form">
                        <div className="tacho-card-form-inputs">
                            <Row>
                                <Col span={24}>
                                    <Typography.Title level={4}>
                                        {props.t('ManagementTachoCards.newTachographCard')}
                                    </Typography.Title>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <SelectField
                                        aria-autocomplete="none"
                                        size="large"
                                        options={countriesOptions}
                                        filterOption={(input, option) => {
                                            const country = props.countries.find(
                                                country => country.id === option?.value
                                            );
                                            if (country) {
                                                return searched(input, country.name) || searched(input, country.iso2);
                                            }
                                            return false;
                                        }}
                                        name="countryId"
                                        className="tacho-card-form-country"
                                        label={props.t('ClientContactListForm.country')}
                                        placeholder={props.t('ClientContactListForm.country')}
                                        showSearch
                                        qa={qa.tacho.new.inputCountry}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputField
                                        name="token"
                                        size="large"
                                        label={props.t('ManagementTachoCards.id')}
                                        placeholder={props.t('ManagementTachoCards.id')}
                                        qa={qa.users.form.inputName}
                                    />
                                </Col>
                            </Row>
                            <Row className="tacho-card-form-pair" gutter={16} align="middle" justify="start">
                                <Col>
                                    <label>{props.t('ManagementTachoCards.pairWith')}:</label>
                                </Col>
                                <Col>
                                    <Radio.Group
                                        onChange={e => onUserPairTypeChange(e.target.value)}
                                        defaultValue={0}
                                        horizontal
                                    >
                                        <Radio value={0} qa={qa.tacho.pairing.inputUserFromList}>
                                            {props.t('ManagementTachoCards.userFromList')}
                                        </Radio>
                                        <Radio value={1} qa={qa.tacho.pairing.inputNewUser}>
                                            {props.t('ManagementTachoCards.newUser')}
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            {selectedUserPairType === userPairType.new ? (
                                <>
                                    <Row className="tacho-card-form-new-user" gutter={[16, 16]}>
                                        <Col span="24">
                                            <Typography.Title level={4}>
                                                {props.t('ManagementUsers.createUser')}{' '}
                                                <span className="tacho-card-form-users-type">
                                                    ({props.t('common.driver')})
                                                </span>
                                            </Typography.Title>
                                        </Col>
                                        <Col span={12}>
                                            <InputField
                                                size="large"
                                                label={props.t('common.username')}
                                                name="username"
                                                placeholder={props.t('common.username')}
                                                qa={qa.users.form.inputUsername}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InputField
                                                size="large"
                                                label={props.t('common.name')}
                                                name="name"
                                                placeholder={props.t('common.name')}
                                                qa={qa.users.form.inputName}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InputField
                                                size="large"
                                                label={props.t('common.surname')}
                                                name="surname"
                                                placeholder={props.t('common.surname')}
                                                qa={qa.users.form.inputSurname}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <InputField
                                                size="large"
                                                label={props.t('common.pin')}
                                                name="pin"
                                                placeholder={props.t('common.pin')}
                                                qa={qa.users.form.inputPin}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Search
                                        searchActive={true}
                                        onSearch={onSearch}
                                        qa={qa.common.pairing.inputSearch}
                                    />
                                    <div className="tacho-card-form-users">
                                        {!props.users ? (
                                            <Loading />
                                        ) : !props.users?.length ? (
                                            <NoData />
                                        ) : (
                                            props.users?.filter(searchFilter).map(u => (
                                                <label
                                                    key={u.id}
                                                    className={cn('tacho-card-form-users-user', {
                                                        selected: selectedUser?.id === u.id
                                                    })}
                                                    onClick={() => onItemSelect(u)}
                                                    data-qa={qa.tacho.pairing.btnItem}
                                                >
                                                    {`${u.name} ${u.surname}`}
                                                </label>
                                            ))
                                        )}
                                    </div>
                                    {validateUser && (
                                        <div className="control-error">{formik.errors.userId?.valueOf()}</div>
                                    )}
                                </>
                            )}
                        </div>
                        <Row gutter={16} justify="end" className="buttons-group">
                            <Col>
                                <Button onClick={props.onCancel} qa={qa.tacho.new.btnFormCancel}>
                                    {props.t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={formik.isSubmitting}
                                    disabled={props.demoMode}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => setValidateUser(true)}
                                    qa={qa.tacho.new.btnFormSubmit}
                                >
                                    {props.t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(TachoCardCreatePairForm);
