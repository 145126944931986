/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MuDataProcessingModelsActivityIntervalExternalDevice,
    MuDataProcessingModelsActivityIntervalExternalDeviceFromJSON,
    MuDataProcessingModelsActivityIntervalExternalDeviceFromJSONTyped,
    MuDataProcessingModelsActivityIntervalExternalDeviceToJSON,
    MuDataProcessingModelsDriverInfoDriver,
    MuDataProcessingModelsDriverInfoDriverFromJSON,
    MuDataProcessingModelsDriverInfoDriverFromJSONTyped,
    MuDataProcessingModelsDriverInfoDriverToJSON,
    MuDataProcessingModelsFuelTankLevel,
    MuDataProcessingModelsFuelTankLevelFromJSON,
    MuDataProcessingModelsFuelTankLevelFromJSONTyped,
    MuDataProcessingModelsFuelTankLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface MuDataProcessingModelsActivityIntervalLive
 */
export interface MuDataProcessingModelsActivityIntervalLive {
    /**
     * 
     * @type {MuDataProcessingModelsDriverInfoDriver}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    driver?: MuDataProcessingModelsDriverInfoDriver;
    /**
     * 
     * @type {Array<MuDataProcessingModelsDriverInfoDriver>}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    passengers?: Array<MuDataProcessingModelsDriverInfoDriver>;
    /**
     * 
     * @type {MuDataProcessingModelsDriverInfoDriver}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    defaultDriver?: MuDataProcessingModelsDriverInfoDriver;
    /**
     * 
     * @type {Array<MuDataProcessingModelsActivityIntervalExternalDevice>}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    externalDevices?: Array<MuDataProcessingModelsActivityIntervalExternalDevice>;
    /**
     * 
     * @type {Date}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    lastUpdate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    externalDevicesLastTs?: Date;
    /**
     * 
     * @type {number}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    lastCarBatteryVolts?: number;
    /**
     * 
     * @type {Date}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    lastCarBatteryTimestamp?: Date;
    /**
     * 
     * @type {{ [key: string]: MuDataProcessingModelsFuelTankLevel; }}
     * @memberof MuDataProcessingModelsActivityIntervalLive
     */
    lastFuelLevelByTank?: { [key: string]: MuDataProcessingModelsFuelTankLevel; };
}

export function MuDataProcessingModelsActivityIntervalLiveFromJSON(json: any): MuDataProcessingModelsActivityIntervalLive {
    return MuDataProcessingModelsActivityIntervalLiveFromJSONTyped(json, false);
}

export function MuDataProcessingModelsActivityIntervalLiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): MuDataProcessingModelsActivityIntervalLive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': !exists(json, 'driver') ? undefined : MuDataProcessingModelsDriverInfoDriverFromJSON(json['driver']),
        'passengers': !exists(json, 'passengers') ? undefined : ((json['passengers'] as Array<any>).map(MuDataProcessingModelsDriverInfoDriverFromJSON)),
        'defaultDriver': !exists(json, 'default_driver') ? undefined : MuDataProcessingModelsDriverInfoDriverFromJSON(json['default_driver']),
        'externalDevices': !exists(json, 'external_devices') ? undefined : ((json['external_devices'] as Array<any>).map(MuDataProcessingModelsActivityIntervalExternalDeviceFromJSON)),
        'lastUpdate': !exists(json, 'last_update') ? undefined : (new Date(json['last_update'])),
        'externalDevicesLastTs': !exists(json, 'external_devices_last_ts') ? undefined : (new Date(json['external_devices_last_ts'])),
        'lastCarBatteryVolts': !exists(json, 'last_car_battery_volts') ? undefined : json['last_car_battery_volts'],
        'lastCarBatteryTimestamp': !exists(json, 'last_car_battery_timestamp') ? undefined : (new Date(json['last_car_battery_timestamp'])),
        'lastFuelLevelByTank': !exists(json, 'last_fuel_level_by_tank') ? undefined : (mapValues(json['last_fuel_level_by_tank'], MuDataProcessingModelsFuelTankLevelFromJSON)),
    };
}

export function MuDataProcessingModelsActivityIntervalLiveToJSON(value?: MuDataProcessingModelsActivityIntervalLive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': MuDataProcessingModelsDriverInfoDriverToJSON(value.driver),
        'passengers': value.passengers === undefined ? undefined : ((value.passengers as Array<any>).map(MuDataProcessingModelsDriverInfoDriverToJSON)),
        'default_driver': MuDataProcessingModelsDriverInfoDriverToJSON(value.defaultDriver),
        'external_devices': value.externalDevices === undefined ? undefined : ((value.externalDevices as Array<any>).map(MuDataProcessingModelsActivityIntervalExternalDeviceToJSON)),
        'last_update': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString()),
        'external_devices_last_ts': value.externalDevicesLastTs === undefined ? undefined : (value.externalDevicesLastTs.toISOString()),
        'last_car_battery_volts': value.lastCarBatteryVolts,
        'last_car_battery_timestamp': value.lastCarBatteryTimestamp === undefined ? undefined : (value.lastCarBatteryTimestamp.toISOString()),
        'last_fuel_level_by_tank': value.lastFuelLevelByTank === undefined ? undefined : (mapValues(value.lastFuelLevelByTank, MuDataProcessingModelsFuelTankLevelToJSON)),
    };
}


