import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';
import cn from 'classnames';
import qa from 'qa-selectors';
import { Button } from 'common/components';
import { Role } from 'logic/auth';
import { SmartBarFilter } from 'modules/tracking/TrackingModule';

interface Props {
    rolled: boolean;
    active?: SmartBarFilter;
    onTime: number;
    delayed: number;
    fuelLow: number;
    alarms: number;
    aetrLow: number;
    roles: Role[];
    onRolledClick?: () => void;
    onOnTimeClick?: () => void;
    onDelayedClick?: () => void;
    onFuelLowClick?: () => void;
    onAlarmsClick?: () => void;
    onAetrLowClick?: () => void;
}

function TrackingSmartBar({
    rolled,
    active,
    onTime,
    delayed,
    fuelLow,
    alarms,
    aetrLow,
    roles,
    onRolledClick,
    onOnTimeClick,
    onDelayedClick,
    onFuelLowClick,
    onAlarmsClick,
    onAetrLowClick
}: Props) {
    const { t } = useTranslation();

    return (
        <div className={cn('tracking-smart-bar', { 'tracking-smart-bar-rolled': rolled })}>
            <div className="tracking-smart-bar-item">
                <Button
                    className={cn('tracking-smart-bar-item')}
                    type="link"
                    onClick={onRolledClick}
                    data-qa={qa.trackingSmartBar.btnRolled}
                    title={t('NavBar.tableView')}
                >
                    <img src="/img-svg/icon-24-icn-table.svg" alt={t('NavBar.tracking')} />
                </Button>
            </div>
            {roles.includes(Role.PLN_R) && (active === 'onTime' || onTime > 0) && (
                <div className="tracking-smart-bar-item on-time">
                    <Button
                        className={cn({ active: active === 'onTime' })}
                        type="link"
                        onClick={onOnTimeClick}
                        data-qa={qa.trackingSmartBar.btnOnTime}
                        title={t('TrackingSmartBar.onTime')}
                    >
                        <img src="/img-svg/icn-on-time.svg" alt={t('TrackingSmartBar.onTime')} />
                        <Badge count={onTime} overflowCount={999} />
                    </Button>
                </div>
            )}
            {roles.includes(Role.PLN_R) && (active === 'delayed' || delayed > 0) && (
                <div className="tracking-smart-bar-item">
                    <Button
                        className={cn({ active: active === 'delayed' })}
                        type="link"
                        onClick={onDelayedClick}
                        data-qa={qa.trackingSmartBar.btnDelayed}
                        title={t('TrackingSmartBar.delayedTransports')}
                    >
                        <img src="/img-svg/icn-delayed.svg" alt={t('TrackingSmartBar.delayedTransports')} />
                        <Badge count={delayed} overflowCount={999} />
                    </Button>
                </div>
            )}
            {roles.includes(Role.IA_R) && (active === 'alarms' || alarms > 0) && (
                <div className="tracking-smart-bar-item">
                    <Button
                        className={cn({ active: active === 'alarms' })}
                        type="link"
                        onClick={onAlarmsClick}
                        data-qa={qa.trackingSmartBar.btnAlarms}
                        title={t('common.alarms')}
                    >
                        <img src="/img-svg/icn-notification.svg" alt={t('common.alarms')} />
                        <Badge count={alarms} overflowCount={999} />
                    </Button>
                </div>
            )}
            {(active === 'fuelLow' || fuelLow > 0) && (
                <div className="tracking-smart-bar-item">
                    <Button
                        className={cn({ active: active === 'fuelLow' })}
                        type="link"
                        onClick={onFuelLowClick}
                        data-qa={qa.trackingSmartBar.btnFuelLow}
                        title={t('TrackingSmartBar.lowFuel')}
                    >
                        <img src="/img-svg/icn-fuel-low.svg" alt={t('TrackingSmartBar.lowFuel')} />
                        <Badge count={fuelLow} overflowCount={999} />
                    </Button>
                </div>
            )}
            {roles.includes(Role.AEI_R) && (active === 'aetrLow' || aetrLow > 0) && (
                <div className="tracking-smart-bar-item">
                    <Button
                        className={cn({ active: active === 'aetrLow' })}
                        type="link"
                        onClick={onAetrLowClick}
                        data-qa={qa.trackingSmartBar.btnAetrLow}
                        title={t('TrackingSmartBar.aetrLow')}
                    >
                        <img src="/img-svg/icn-aetr.svg" alt={t('TrackingSmartBar.aetrLow')} />
                        <Badge count={aetrLow} overflowCount={999} />
                    </Button>
                </div>
            )}
        </div>
    );
}

export default TrackingSmartBar;
