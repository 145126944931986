import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Widget from 'common/components/Widget';
import cn from 'classnames';

interface Props extends WithTranslation {
    trend?: number;
    value: number;
    currency: string;
    loading: boolean;
    onClick?: () => void;
}

function SavedWidget(props: Props) {
    return (
        <Widget
            loading={props.loading}
            title={props.t('CompanyProfileDashboard.saved')}
            styleName="fuel"
            content={
                <>
                    {props.value && (
                        <h1>
                            {props.value} {props.currency}
                        </h1>
                    )}

                    {props.trend && (
                        <div
                            className={cn('widget-trend', {
                                'widget-trend-decreasing': props.trend < 0,
                                'widget-trend-increasing': props.trend > 0
                            })}
                        >
                            <i className="fa fa-line-chart" />
                            {props.trend}%
                        </div>
                    )}
                </>
            }
            // onClick={props.onClick}
        />
    );
}

export default withTranslation()(SavedWidget);
