import { createRef, useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { WithLogic } from 'App';
import { Button, Confirm } from 'common/components';
import {
    TransportEventRule,
    TransportPlace,
    TransportPlaceTask,
    TransportPlaceTaskType,
    Goods,
    Transport
} from 'generated/backend-api';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    ActivityLoading,
    ActivityParking,
    ActivityRefueling,
    ActivityUnloading,
    ActivityWashing
} from 'resources/images/planner';
import ActivityAlarms, { ManualAtaResult } from '../ActivityAlarms';
import PlannerCard from '../PlannerCard';
import ActivityTaskLoading from '../ActivityTaskLoading';
import cn from 'classnames';
import { MessageType } from 'common/components/Confirm';
import ActivityTaskUnloading from '../ActivityTaskUnloading';
import { T, TASK_PLACE_TIME_DEFAULT, TASK_PLACE_TIME_REFUELING } from 'domain-constants';
import ActivityTaskOnetime from '../ActivityTaskOnetime';
import CreateContactForm from 'modules/management/modules/contact-list/components/createContactForm/CreateContactForm';
import { ContactList } from 'common/model/client-contact';
import ModalForm from 'common/components/ModalForm';
import { ReadOnlyContactListTypeEnum, WriteOnlyContactList, WriteOnlyContactListTypeEnum } from 'generated/new-main';
import { observer } from 'mobx-react';

export const NEW_CLIENT_ITEM_VALUE = 'newClient';
export interface ActivityResult {
    tasks: TransportPlaceTask[] | undefined;
    eventRules?: Array<TransportEventRule>;
    manualAta: ManualAtaResult;
    clearPreviousId: boolean;
}

export type ActivityGood = Goods & {
    disabled?: boolean;
    key?: string;
};
interface Props extends WithTranslation, WithLogic {
    transport: Transport;
    transportPlace: TransportPlace;
    transportPlaceIndex: number;
    onSubmit?: (result: ActivityResult, index: number) => void;
    onEscape?: (result: ActivityResult, index: number) => void;
}

const ActivityCard = (props: Props) => {
    const _refTasks = createRef<HTMLDivElement>();
    const [redraw, setRedraw] = useState(0);
    const [openedTaskIndex, setOpenedTaskIndex] = useState(-1);
    const [incorectGoodsWarningOpen, setIncorectGoodsWarningOpen] = useState(false);
    const [deleteClientConfirmOpen, setDeleteClientConfirmOpen] = useState(false);
    const [newClientOpen, setNewClientOpen] = useState(false);
    const [clientTask, setClientTask] = useState<TransportPlaceTask | undefined>(undefined);
    const [refreshClient, setRefreshClient] = useState(0);
    const [editClientOpen, setEditClientOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState('');
    const empty: number[] = [];
    const [expanded, setExpanded] = useState(empty);
    const [expandWidth, setExpandWidth] = useState(false);
    const [manualAta, setManualAta] = useState<ManualAtaResult>({
        use: false,
        date: props.transportPlace.ata
    });

    useEffect(() => {
        if (_refTasks.current && openedTaskIndex > -1) {
            _refTasks.current.scrollTop = _refTasks.current.scrollHeight;
            setOpenedTaskIndex(-1);
        }
    }, [_refTasks, openedTaskIndex]);

    const { t } = props;

    const _createTask = (taskType: TransportPlaceTaskType): TransportPlaceTask => {
        return {
            id: props.logic.plannerLogic().generateId(),
            type: taskType,
            description: undefined,
            action: undefined,
            isResolved: undefined,
            activityStartTime: undefined,
            activityEndTime: undefined,
            result: undefined,
            resultUnit: undefined,
            driverNote: undefined,
            payload: undefined,
            contactId: undefined,
            loadingGoods: undefined,
            unloadingGoods: undefined,
            puescComments: undefined,
            additionalInformation: undefined,
            plannedStart: undefined,
            plannedEnd: undefined,
            shipmentPrice: undefined
        };
    };

    const _getPlannedStart = (): Date => {
        if (props.transportPlace.tasks && props.transportPlace.tasks.length > 0) {
            const plannedDate = Math.max(...props.transportPlace.tasks.map(task => moment(task.plannedEnd).valueOf()));
            return moment(plannedDate).toDate();
        } else {
            return props.transportPlace?.rta!;
        }
    };

    const _getUnloadingGoods = (): string[] => {
        let unloadingGoodsList: string[] = [];

        props.transport.places?.forEach((place, placeIndex) => {
            if (placeIndex !== props.transportPlaceIndex) {
                place.tasks
                    ?.filter(t => t.type === TransportPlaceTaskType.Unloading)
                    .forEach(t => {
                        t.unloadingGoods?.forEach(ug => {
                            unloadingGoodsList.push(ug);
                        });
                    });
            }
        });

        props.transportPlace.tasks
            ?.filter(t => t.type === TransportPlaceTaskType.Unloading)
            .forEach(t => {
                t.unloadingGoods?.forEach(ug => {
                    unloadingGoodsList.push(ug);
                });
            });

        return unloadingGoodsList;
    };

    const _getGoods = (): ActivityGood[] => {
        let goods: ActivityGood[] = [];

        const unloadingGoodsList = _getUnloadingGoods();

        props.transport.places?.forEach((place, placeIndex) => {
            if (placeIndex !== props.transportPlaceIndex) {
                place.tasks
                    ?.filter(t => t.type === TransportPlaceTaskType.Loading)
                    .forEach(task => {
                        if (task.loadingGoods) {
                            task.loadingGoods.forEach(g => {
                                goods.push({
                                    ...g,
                                    disabled: unloadingGoodsList.indexOf(g.id ?? '') > -1
                                });
                            });
                        }
                    });
            }
        });

        props.transportPlace.tasks
            ?.filter(t => t.type === TransportPlaceTaskType.Loading)
            .forEach(task => {
                if (task.loadingGoods) {
                    task.loadingGoods.forEach(g => {
                        goods.push({
                            ...g,
                            disabled: unloadingGoodsList.indexOf(g.id ?? '') > -1
                        });
                    });
                }
            });
        return goods;
    };

    const _validateUnloading = (): boolean => {
        let loadingWeigthT = 0;
        let unloadigWeigthT = 0;

        props.transport.places?.forEach((place, placeIndex) => {
            if (placeIndex !== props.transportPlaceIndex) {
                place.tasks
                    ?.filter(t => t.type === TransportPlaceTaskType.Loading)
                    .forEach(task => {
                        loadingWeigthT += props.logic.plannerLogic().getTaskWeight(task, T);
                    });
            }
        });

        props.transportPlace.tasks
            ?.filter(t => t.type === TransportPlaceTaskType.Loading)
            .forEach(task => {
                loadingWeigthT += props.logic.plannerLogic().getTaskWeight(task, T);
            });

        props.transport.places?.forEach((place, placeIndex) => {
            if (placeIndex !== props.transportPlaceIndex) {
                place.tasks
                    ?.filter(t => t.type === TransportPlaceTaskType.Unloading)
                    .forEach(task => {
                        unloadigWeigthT += props.logic.plannerLogic().getTaskWeight(task, T);
                    });
            }
        });

        props.transportPlace.tasks
            ?.filter(t => t.type === TransportPlaceTaskType.Unloading)
            .forEach(task => {
                unloadigWeigthT += props.logic.plannerLogic().getTaskWeight(task, T);
            });

        return loadingWeigthT < unloadigWeigthT;
    };

    const _redraw = () => {
        setRedraw(redraw + 1);
    };

    const handleSubmitClick = () => {
        setIncorectGoodsWarningOpen(false);
        props.onSubmit?.(
            {
                tasks: props.transportPlace?.tasks,
                eventRules: props.transportPlace?.eventRules,
                clearPreviousId: true,
                manualAta
            },
            props.transportPlaceIndex
        );
    };

    const handleEscape = (clear: boolean) => {
        setIncorectGoodsWarningOpen(false);
        props.onEscape?.(
            {
                tasks: props.transportPlace?.tasks,
                eventRules: props.transportPlace?.eventRules,
                clearPreviousId: clear,
                manualAta
            },
            props.transportPlaceIndex
        );
    };

    const handleNewClientClick = (task: TransportPlaceTask) => {
        setClientTask(task);
        setNewClientOpen(true);
    };

    const handleEditClientClick = (id: string, task: TransportPlaceTask) => {
        setClientTask(task);
        setSelectedClientId(id);
        setEditClientOpen(true);
    };

    const handleDeleteClientClick = (id: string, task: TransportPlaceTask) => {
        setClientTask(task);
        setSelectedClientId(id);
        setDeleteClientConfirmOpen(true);
    };

    const handleConfirmDeleteClientClick = async () => {
        try {
            props.transportPlace?.tasks
                ?.filter(t => t.contactId === selectedClientId)
                .forEach(t => {
                    t.contactId = undefined;
                });
            props.logic.plannerLogic().transport.places?.forEach(place => {
                place.tasks
                    ?.filter(t => t.contactId === selectedClientId)
                    .forEach(t => {
                        t.contactId = undefined;
                    });
            });

            await props.logic.clientContactLogic().removeClientContact(selectedClientId);
            props.logic
                .plannerLogic()
                .fetchClientContactList()
                .then(() => {
                    setRefreshClient(refreshClient + 1);
                });
            message.success(t('ContactList.message.removeSuccess'));
        } catch (err) {
            message.error(t('ContactList.message.removeError'));
            throw err;
        }
        setDeleteClientConfirmOpen(false);
    };

    const handlerActivityButtonClick = (taskType: TransportPlaceTaskType) => {
        let task: TransportPlaceTask = _createTask(taskType);
        task.plannedStart = _getPlannedStart();
        task.plannedEnd = moment(task.plannedStart)
            .add(
                taskType === TransportPlaceTaskType.Refueling ? TASK_PLACE_TIME_REFUELING : TASK_PLACE_TIME_DEFAULT,
                'seconds'
            )
            .toDate();
        if (props.transportPlace.tasks) {
            props.transportPlace.tasks.push(task);
            setOpenedTaskIndex(props.transportPlace.tasks.length - 1);
        } else {
            props.transportPlace.tasks = [task];
            setOpenedTaskIndex(0);
        }
    };

    const handleTaskDelete = (index: number) => {
        props.transportPlace.tasks?.splice(index, 1);
        _updateDateTasks();
        _redraw();
        setIncorectGoodsWarningOpen(_validateUnloading());
    };

    const handleChangeUnloading = () => {
        setIncorectGoodsWarningOpen(_validateUnloading());
        _redraw();
    };

    const handleTaskDateChange = () => {
        _updateDateTasks();
        _redraw();
    };

    const handleContentExpanded = (index: number, value: boolean) => {
        const ex = expanded;
        if (value) {
            ex.push(index);
            setExpanded(ex);
        } else {
            if (expanded.indexOf(index) > -1) {
                ex.splice(expanded.indexOf(index), 1);
                setExpanded(ex);
            }
        }
        setExpandWidth(expanded.length > 0);
    };

    const handleManualAtaChange = (manualAta: ManualAtaResult) => {
        setManualAta(manualAta);
    };

    const handleSaveClient = async (value: ContactList, type: ReadOnlyContactListTypeEnum[]) => {
        const edited = editClientOpen;

        if (!edited) {
            try {
                const client = await props.logic.contactListLogic().createContactList({
                    ...value,
                    countryId: value.country?.id,
                    type: type as unknown as WriteOnlyContactListTypeEnum[]
                } as WriteOnlyContactList);
                message.success(t('ContactList.message.createSuccess'));
                props.logic
                    .plannerLogic()
                    .fetchClientContactList()
                    .then(() => {
                        clientTask!.contactId = client.id;
                        setRefreshClient(refreshClient + 1);
                    });
            } catch (err) {
                message.success(t('ContactList.message.createError'));
            }
        } else {
            try {
                await props.logic.clientContactLogic().updateClientContact(selectedClientId, {
                    ...value,
                    countryId: value.country?.id,
                    type: type as unknown as WriteOnlyContactListTypeEnum[]
                } as WriteOnlyContactList);
                message.success(t('ContactList.message.editSuccess'));
                props.logic
                    .plannerLogic()
                    .fetchClientContactList()
                    .then(() => {
                        setRefreshClient(refreshClient + 1);
                    });
            } catch (err) {
                message.success(t('ContactList.message.editError'));
            }
        }

        setNewClientOpen(false);
        setEditClientOpen(false);

        return false;
    };

    const _updateDateTasks = () => {
        if ((props.transportPlace.tasks?.length ?? 0) > 0) {
            props.transportPlace.tasks?.forEach((task, index) => {
                if (index > 0) {
                    const prevTask = props.transportPlace.tasks![index - 1];
                    const time = moment(task.plannedEnd).diff(moment(task.plannedStart)) / 1000;
                    task.plannedStart = prevTask.plannedEnd;
                    task.plannedEnd = moment(task.plannedStart).add(time, 'seconds').toDate();
                }
            });
        }
    };

    const placeDisabled = props.logic.plannerLogic().getPlaceDisabled(props.transportPlace);
    const manualAtaPossible = props.logic
        .plannerLogic()
        .isManualAtaPossible(props.transportPlaceIndex, props.transportPlace);

    const placesTasks = props.transportPlace.tasks?.map((task, index) => {
        switch (task.type) {
            case TransportPlaceTaskType.Parking:
            case TransportPlaceTaskType.Refueling:
            case TransportPlaceTaskType.Washing:
                return (
                    <ActivityTaskOnetime
                        key={index}
                        placeDisabled={placeDisabled}
                        index={index}
                        transportPlace={props.transportPlace!}
                        task={task}
                        expanded={openedTaskIndex === index}
                        onDelete={handleTaskDelete}
                        onDateChange={handleTaskDateChange}
                    />
                );
            case TransportPlaceTaskType.Loading:
                return (
                    <ActivityTaskLoading
                        key={index}
                        placeDisabled={placeDisabled}
                        logic={props.logic}
                        refreshClient={refreshClient}
                        index={index}
                        transportPlace={props.transportPlace!}
                        task={task}
                        expanded={openedTaskIndex === index}
                        onDelete={handleTaskDelete}
                        onNewClientClick={handleNewClientClick}
                        onEditClientClick={handleEditClientClick}
                        onDeleteClientClick={handleDeleteClientClick}
                        onDateChange={handleTaskDateChange}
                        onExpadedWidth={handleContentExpanded}
                    />
                );
            case TransportPlaceTaskType.Unloading:
                return (
                    <ActivityTaskUnloading
                        key={index}
                        logic={props.logic}
                        placeDisabled={placeDisabled}
                        refreshClient={refreshClient}
                        goods={_getGoods()}
                        index={index}
                        transportPlace={props.transportPlace!}
                        task={task}
                        expanded={openedTaskIndex === index}
                        onDelete={handleTaskDelete}
                        onNewClientClick={handleNewClientClick}
                        onEditClientClick={handleEditClientClick}
                        onDeleteClientClick={handleDeleteClientClick}
                        onDateChange={handleTaskDateChange}
                        onExpadedWidth={handleContentExpanded}
                        onChangeUnloading={handleChangeUnloading}
                    />
                );
            default:
                return '';
        }
    });

    const _logic = props.logic;
    const disabledButton = props.logic.plannerLogic().getPlaceDisabled(props.transportPlace);

    return (
        <>
            <PlannerCard
                className={cn('place-activity-card', {
                    'place-activity-card-expanded': expandWidth
                })}
                afterTitle={
                    <Row className="activity-buttons" align="middle" gutter={6} justify="space-between">
                        <Col flex={1}>
                            <Button
                                icon={<img src={ActivityLoading} alt="icon-activity-loading" />}
                                block
                                disabled={disabledButton}
                                onClick={() => handlerActivityButtonClick(TransportPlaceTaskType.Loading)}
                            />
                        </Col>
                        <Col flex={1}>
                            <Button
                                icon={<img src={ActivityUnloading} alt="icon-activity-loading" />}
                                block
                                disabled={disabledButton}
                                onClick={() => handlerActivityButtonClick(TransportPlaceTaskType.Unloading)}
                            />
                        </Col>
                        <Col flex={1}>
                            <Button
                                icon={<img src={ActivityParking} alt="icon-activity-parking" />}
                                block
                                disabled={disabledButton}
                                onClick={() => handlerActivityButtonClick(TransportPlaceTaskType.Parking)}
                            />
                        </Col>
                        <Col flex={1}>
                            <Button
                                icon={<img src={ActivityRefueling} alt="icon-activity-refueling" />}
                                block
                                disabled={disabledButton}
                                onClick={() => handlerActivityButtonClick(TransportPlaceTaskType.Refueling)}
                            />
                        </Col>
                        <Col flex={1}>
                            <Button
                                icon={<img src={ActivityWashing} alt="icon-activity-washing" />}
                                block
                                disabled={disabledButton}
                                onClick={() => handlerActivityButtonClick(TransportPlaceTaskType.Washing)}
                            />
                        </Col>
                    </Row>
                }
                onClose={handleSubmitClick}
                onEscape={handleEscape}
            >
                <Row ref={_refTasks} className="place-activity-card-tasks">
                    {placesTasks}
                </Row>
                <Row
                    className={cn('place-activity-card-alarms', {
                        'place-activity-card-alarms-no-empty': (placesTasks && placesTasks.length > 0) ?? false
                    })}
                >
                    <ActivityAlarms
                        logic={_logic}
                        transportPlace={props.transportPlace}
                        placeDisabled={placeDisabled}
                        manualAtaPossible={manualAtaPossible}
                        expanded={false}
                        onManualAtaChange={handleManualAtaChange}
                    />
                </Row>
            </PlannerCard>
            {incorectGoodsWarningOpen && (
                <Confirm
                    type={MessageType.WARNING}
                    header={t('PlannerPlacesTask.incorectGoodsTitle')}
                    message={t('PlannerPlacesTask.incorectGoodsMessage')}
                    cancelButton={false}
                    confirmLabel={t('common.close')}
                    onConfirm={() => setIncorectGoodsWarningOpen(false)}
                />
            )}
            {deleteClientConfirmOpen && (
                <Confirm
                    type={MessageType.WARNING}
                    header={t('ContactList.message.deletionHeader')}
                    message={t('ContactList.message.confirmRemoval')}
                    onConfirm={handleConfirmDeleteClientClick}
                    onCancel={() => setDeleteClientConfirmOpen(false)}
                />
            )}
            <ModalForm
                visible={newClientOpen || editClientOpen}
                title={newClientOpen ? t('ClientContactListForm.modalNewContact') : t('ClientContactListForm.details')}
                width={600}
                onCancel={() => {
                    setNewClientOpen(false);
                    setEditClientOpen(false);
                }}
            >
                <CreateContactForm
                    size="small"
                    designType="compact"
                    countries={props.logic.plannerLogic().countryList}
                    onSubmit={handleSaveClient}
                    readonlyTypes={
                        clientTask?.type === TransportPlaceTaskType.Loading
                            ? [ReadOnlyContactListTypeEnum.Client]
                            : [ReadOnlyContactListTypeEnum.Consignee]
                    }
                    initialClientContact={
                        editClientOpen
                            ? props.logic.plannerLogic().clientContactList.find(c => c.id === selectedClientId)
                            : clientTask?.type === TransportPlaceTaskType.Loading
                            ? {
                                  type: [ReadOnlyContactListTypeEnum.Client]
                              }
                            : {
                                  type: [ReadOnlyContactListTypeEnum.Consignee]
                              }
                    }
                    onCancel={() => {
                        setNewClientOpen(false);
                        setEditClientOpen(false);
                    }}
                    demoMode={props.logic.demo().isActive}
                />
            </ModalForm>
        </>
    );
};

export default withTranslation()(observer(ActivityCard));
