/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FuelStationPriceList
 */
export interface FuelStationPriceList {
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    customerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    currency?: string;
    /**
     * 
     * @type {Date}
     * @memberof FuelStationPriceList
     */
    date: Date;
    /**
     * 
     * @type {Array<object>}
     * @memberof FuelStationPriceList
     */
    extraFees: Array<object>;
    /**
     * 
     * @type {Date}
     * @memberof FuelStationPriceList
     */
    lastTimeUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    sumExtraFees?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    billingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStationPriceList
     */
    serviceCurrency?: string;
    /**
     * 
     * @type {number}
     * @memberof FuelStationPriceList
     */
    salesPriceInServiceCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof FuelStationPriceList
     */
    salesPriceInBillingCurrency?: number;
}

export function FuelStationPriceListFromJSON(json: any): FuelStationPriceList {
    return FuelStationPriceListFromJSONTyped(json, false);
}

export function FuelStationPriceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelStationPriceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'customerNumber': !exists(json, 'customerNumber') ? undefined : json['customerNumber'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'date': (new Date(json['date'])),
        'extraFees': json['extraFees'],
        'lastTimeUpdated': !exists(json, 'last_time_updated') ? undefined : (new Date(json['last_time_updated'])),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'sumExtraFees': !exists(json, 'sumExtraFees') ? undefined : json['sumExtraFees'],
        'billingCurrency': !exists(json, 'billingCurrency') ? undefined : json['billingCurrency'],
        'serviceCurrency': !exists(json, 'serviceCurrency') ? undefined : json['serviceCurrency'],
        'salesPriceInServiceCurrency': !exists(json, 'salesPriceInServiceCurrency') ? undefined : json['salesPriceInServiceCurrency'],
        'salesPriceInBillingCurrency': !exists(json, 'salesPriceInBillingCurrency') ? undefined : json['salesPriceInBillingCurrency'],
    };
}

export function FuelStationPriceListToJSON(value?: FuelStationPriceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'customerNumber': value.customerNumber,
        'locationCode': value.locationCode,
        'productId': value.productId,
        'countryCode': value.countryCode,
        'currency': value.currency,
        'date': (value.date.toISOString()),
        'extraFees': value.extraFees,
        'last_time_updated': value.lastTimeUpdated === undefined ? undefined : (value.lastTimeUpdated.toISOString()),
        'price': value.price,
        'source': value.source,
        'sumExtraFees': value.sumExtraFees,
        'billingCurrency': value.billingCurrency,
        'serviceCurrency': value.serviceCurrency,
        'salesPriceInServiceCurrency': value.salesPriceInServiceCurrency,
        'salesPriceInBillingCurrency': value.salesPriceInBillingCurrency,
    };
}


