/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyAccessCode
 */
export interface WriteOnlyAccessCode {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAccessCode
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyAccessCode
     */
    transportId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyAccessCode
     */
    start?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyAccessCode
     */
    end?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyAccessCode
     */
    client: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WriteOnlyAccessCode
     */
    monitoredObjects?: Array<number>;
}

export function WriteOnlyAccessCodeFromJSON(json: any): WriteOnlyAccessCode {
    return WriteOnlyAccessCodeFromJSONTyped(json, false);
}

export function WriteOnlyAccessCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyAccessCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'transportId': !exists(json, 'transport_id') ? undefined : json['transport_id'],
        'start': !exists(json, 'start') ? undefined : (json['start'] === null ? null : new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (json['end'] === null ? null : new Date(json['end'])),
        'client': json['client'],
        'monitoredObjects': !exists(json, 'monitored_objects') ? undefined : json['monitored_objects'],
    };
}

export function WriteOnlyAccessCodeToJSON(value?: WriteOnlyAccessCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'transport_id': value.transportId,
        'start': value.start === undefined ? undefined : (value.start === null ? null : value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end === null ? null : value.end.toISOString()),
        'client': value.client,
        'monitored_objects': value.monitoredObjects,
    };
}


