import { Col, Row } from 'antd';
import { Button, Tooltip } from 'common/components';
import { AvailableCurrencies } from 'common/model/currency';
import { VehicleWithAvailability } from 'common/model/transports';
import { ItemInfo } from 'resources/images/planner';
import { VehicleProfile } from '../../planner-logic';
import VehicleProfileDetail from '../VehicleProfileDetail';

interface Props {
    vehicle: VehicleWithAvailability;
    className?: string;
    qaEdit?: string;
    qaInfo?: string;
    currency?: AvailableCurrencies;
}

const VehicleItem = (props: Props) => {
    const getProfile = (vehicle: VehicleWithAvailability): VehicleProfile => {
        return {
            id: vehicle.profile?.id,
            name: vehicle.profile?.name,
            euroClass: vehicle.fleetModel?.emissionClass,
            numberOfAxles: props.vehicle.profile?.numberOfAxles?.v,
            weight: vehicle.profile?.weight?.v,
            pricePerKm: vehicle.profile?.costPerKm,
            height: vehicle.profile?.height?.v,
            trailersCount: vehicle.profile?.trailersCount,
            width: vehicle.profile?.width?.v,
            length: vehicle.profile?.length?.v,
            tunnel: vehicle.profile?.tunnel?.v,
            client: 0
        };
    };

    const profile: VehicleProfile = getProfile(props.vehicle);

    return (
        <Row className={`vehicle-item ${props.className}`} align="middle">
            <Col span={24}>
                <Row align="middle" justify="start">
                    <Col className="vehicle-item-rn">
                        <Tooltip placement="top" mouseLeaveDelay={0} title={props.vehicle.data.rn}>
                            <span>{props.vehicle.data.rn}</span>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip
                            title={<VehicleProfileDetail profile={profile} currency={props.currency} />}
                            placement="bottomRight"
                            overlayClassName="vehicle-profile-tooltip"
                        >
                            <Button
                                type="link"
                                className="vehicle-profile-action info-profile"
                                icon={<img src={ItemInfo} alt="info-vehicle-profile" />}
                                qa={props.qaInfo}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default VehicleItem;
