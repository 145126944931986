import React, { ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { Loading } from '../Loading';

interface Props extends WithTranslation {
    title: string;
    content: ReactNode;
    topRight?: ReactNode;
    styleName?: string;
    loading?: boolean;
    onClick?: () => void;
}

function Widget(props: Props) {
    return (
        <>
            <div
                className={cn('widget', props.styleName, { pointer: props.onClick })}
                onClick={() => props.onClick?.()}
            >
                {props.loading && <Loading />}
                <Row justify="space-between">
                    <Col>
                        <Typography.Title level={3}>{props.title}</Typography.Title>
                    </Col>
                    {props.topRight && <Col>{props.topRight}</Col>}
                </Row>
                <Row className="widget-content" align="middle" justify="center">
                    <Col>{props.content}</Col>
                </Row>
            </div>
        </>
    );
}

export default withTranslation()(Widget);
