/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    ServiceActualVehicleStateModelsVehicleTransportNextWayPoint,
    ServiceActualVehicleStateModelsVehicleTransportNextWayPointFromJSON,
    ServiceActualVehicleStateModelsVehicleTransportNextWayPointFromJSONTyped,
    ServiceActualVehicleStateModelsVehicleTransportNextWayPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceActualVehicleStateModelsVehicleTransport
 */
export interface ServiceActualVehicleStateModelsVehicleTransport {
    /**
     * 
     * @type {string}
     * @memberof ServiceActualVehicleStateModelsVehicleTransport
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActualVehicleStateModelsVehicleTransport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActualVehicleStateModelsVehicleTransport
     */
    firstRta: string;
    /**
     * 
     * @type {ServiceActualVehicleStateModelsVehicleTransportNextWayPoint}
     * @memberof ServiceActualVehicleStateModelsVehicleTransport
     */
    nextWaypoint: ServiceActualVehicleStateModelsVehicleTransportNextWayPoint;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof ServiceActualVehicleStateModelsVehicleTransport
     */
    addressStructured?: Array<AddressStructured>;
}

export function ServiceActualVehicleStateModelsVehicleTransportFromJSON(json: any): ServiceActualVehicleStateModelsVehicleTransport {
    return ServiceActualVehicleStateModelsVehicleTransportFromJSONTyped(json, false);
}

export function ServiceActualVehicleStateModelsVehicleTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceActualVehicleStateModelsVehicleTransport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'firstRta': json['firstRta'],
        'nextWaypoint': ServiceActualVehicleStateModelsVehicleTransportNextWayPointFromJSON(json['nextWaypoint']),
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON)),
    };
}

export function ServiceActualVehicleStateModelsVehicleTransportToJSON(value?: ServiceActualVehicleStateModelsVehicleTransport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'firstRta': value.firstRta,
        'nextWaypoint': ServiceActualVehicleStateModelsVehicleTransportNextWayPointToJSON(value.nextWaypoint),
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
    };
}


