/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorderCrossModel
 */
export interface BorderCrossModel {
    /**
     * 
     * @type {number}
     * @memberof BorderCrossModel
     */
    borderCrossId: number;
    /**
     * 
     * @type {Array}
     * @memberof BorderCrossModel
     */
    centroid: [number, number];
    /**
     * 
     * @type {number}
     * @memberof BorderCrossModel
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossModel
     */
    median6h?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossModel
     */
    delay?: number;
    /**
     * 
     * @type {Date}
     * @memberof BorderCrossModel
     */
    lastUpdate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BorderCrossModel
     */
    lastData?: Date;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossModel
     */
    crossingCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorderCrossModel
     */
    countries: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorderCrossModel
     */
    names: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorderCrossModel
     */
    puescNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BorderCrossModel
     */
    countryExit: string;
}

export function BorderCrossModelFromJSON(json: any): BorderCrossModel {
    return BorderCrossModelFromJSONTyped(json, false);
}

export function BorderCrossModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorderCrossModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borderCrossId': json['border_cross_id'],
        'centroid': json['centroid'],
        'minimum': !exists(json, 'minimum') ? undefined : json['minimum'],
        'median6h': !exists(json, 'median_6h') ? undefined : json['median_6h'],
        'delay': !exists(json, 'delay') ? undefined : json['delay'],
        'lastUpdate': (new Date(json['last_update'])),
        'lastData': !exists(json, 'last_data') ? undefined : (new Date(json['last_data'])),
        'crossingCount': !exists(json, 'crossing_count') ? undefined : json['crossing_count'],
        'countries': json['countries'],
        'names': json['names'],
        'puescNames': json['puesc_names'],
        'countryExit': json['country_exit'],
    };
}

export function BorderCrossModelToJSON(value?: BorderCrossModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'border_cross_id': value.borderCrossId,
        'centroid': value.centroid,
        'minimum': value.minimum,
        'median_6h': value.median6h,
        'delay': value.delay,
        'last_update': (value.lastUpdate.toISOString()),
        'last_data': value.lastData === undefined ? undefined : (value.lastData.toISOString()),
        'crossing_count': value.crossingCount,
        'countries': value.countries,
        'names': value.names,
        'puesc_names': value.puescNames,
        'country_exit': value.countryExit,
    };
}


