import React, { ReactNode } from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';

interface Props extends UploadProps {
    children?: ReactNode;
}

export default function EnhancedUpload({ customRequest = () => {}, ...props }: Props) {
    return <Upload customRequest={customRequest} {...props} />;
}
