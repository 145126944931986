/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Measurement,
    MeasurementFromJSON,
    MeasurementFromJSONTyped,
    MeasurementToJSON,
} from './';

/**
 * 
 * @export
 * @interface Goods
 */
export interface Goods {
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    cnItems?: string;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    wasteCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Goods
     */
    grossWeightVolume: number;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    measurementUnit: string;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    vatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof Goods
     */
    cmr?: string;
    /**
     * 
     * @type {Array<Measurement>}
     * @memberof Goods
     */
    measurements?: Array<Measurement>;
}

export function GoodsFromJSON(json: any): Goods {
    return GoodsFromJSONTyped(json, false);
}

export function GoodsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Goods {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cnItems': !exists(json, 'cn_items') ? undefined : json['cn_items'],
        'name': json['name'],
        'wasteCode': !exists(json, 'waste_code') ? undefined : json['waste_code'],
        'grossWeightVolume': json['gross_weight_volume'],
        'measurementUnit': json['measurement_unit'],
        'vatRate': !exists(json, 'vat_rate') ? undefined : json['vat_rate'],
        'cmr': !exists(json, 'cmr') ? undefined : json['cmr'],
        'measurements': !exists(json, 'measurements') ? undefined : ((json['measurements'] as Array<any>).map(MeasurementFromJSON)),
    };
}

export function GoodsToJSON(value?: Goods | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cn_items': value.cnItems,
        'name': value.name,
        'waste_code': value.wasteCode,
        'gross_weight_volume': value.grossWeightVolume,
        'measurement_unit': value.measurementUnit,
        'vat_rate': value.vatRate,
        'cmr': value.cmr,
        'measurements': value.measurements === undefined ? undefined : ((value.measurements as Array<any>).map(MeasurementToJSON)),
    };
}


