/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BorderCrossTwoDirections,
    BorderCrossTwoDirectionsFromJSON,
    BorderCrossTwoDirectionsToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetBorderCrossesGeojsonV1BorderCrossesGeoGetRequest {
    puesc?: boolean;
}

export interface GetBorderCrossesV1BorderCrossesGetRequest {
    puesc?: boolean;
}

/**
 * no description
 */
export class BorderCrossesApi extends runtime.BaseAPI {

    /**
     * Get Border Crosses Geojson
     */
    async getBorderCrossesGeojsonV1BorderCrossesGeoGetRaw(requestParameters: GetBorderCrossesGeojsonV1BorderCrossesGeoGetRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.puesc !== undefined) {
            queryParameters['puesc'] = requestParameters.puesc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/border_crosses/geo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Border Crosses Geojson
     */
    async getBorderCrossesGeojsonV1BorderCrossesGeoGet(requestParameters: GetBorderCrossesGeojsonV1BorderCrossesGeoGetRequest): Promise<object> {
        const response = await this.getBorderCrossesGeojsonV1BorderCrossesGeoGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Border Crosses
     */
    async getBorderCrossesV1BorderCrossesGetRaw(requestParameters: GetBorderCrossesV1BorderCrossesGetRequest): Promise<runtime.ApiResponse<Array<BorderCrossTwoDirections>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.puesc !== undefined) {
            queryParameters['puesc'] = requestParameters.puesc;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/border_crosses/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorderCrossTwoDirectionsFromJSON));
    }

    /**
     * Get Border Crosses
     */
    async getBorderCrossesV1BorderCrossesGet(requestParameters: GetBorderCrossesV1BorderCrossesGetRequest): Promise<Array<BorderCrossTwoDirections>> {
        const response = await this.getBorderCrossesV1BorderCrossesGetRaw(requestParameters);
        return await response.value();
    }

}
