import { useEffect, useState } from 'react';
import cn from 'classnames';
import TrackingFilter, { FilterOpts } from './TrackingFilter';
import TrackingCargo from './TrackingCargo';
import {
    MonitoredObjectTypeName,
    TrackingModel,
    VehicleDelayedFilterCode,
    VehicleStatusFilterCode
} from 'common/model/tracking';
import { TrackingSettings } from './TrackingSettings';
import TrackingTable from './TrackingTable';
import TrackingBar from './TrackingBar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { Modal, Button } from 'common/components';
import { CargoExpense } from 'logic/statistics/statistics-expenses';
import { Role } from 'logic/auth';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';
import HelperModal from 'common/components/HelperModal';
import { Col, Row } from 'antd';
import TrackingSmartBar from './TrackingSmartBar';
import { SmartBarFilter } from '../TrackingModule';
import { pxWidthText } from 'common/utils/textUtils';

interface Props extends WithTranslation {
    bar: {
        checkedAll?: boolean;
        expanded: boolean;
        filtered: boolean;
        filter?: boolean;
        settings?: boolean;
        isFilterLoading: boolean;
    };
    smartBar: {
        rolled: boolean;
        active?: SmartBarFilter;
        onTime: number;
        delayed: number;
        fuelLow: number;
        alarms: number;
        aetrLow: number;
    };
    filter: {
        delay: VehicleDelayedFilterCode;
        status: VehicleStatusFilterCode;
        monitoredObjectGroupsChecked: string[];
        monitoredObjectGroupsOpts: CheckboxGroupOpts;
    };
    search?: { text: string };
    table?: {
        data: TrackingModel[];
        loading?: boolean;
    };
    card?: {
        card?: TrackingModel;
    };
    helper?: {
        content: string;
    };
    cargo?: {
        vehicle: {
            code: number;
            label: string;
        };
        suppliers: {
            code: string;
            label: string;
        }[];
        date: string;
    };
    locationAlert?: {
        display: boolean;
        vehicleId?: string;
    };
    roles: Role[];
    fleetType: ReadOnlyClientFleetTypeEnum;
    onAlarmClickDismiss?: (ids: string[]) => void;
    onBarCheckAllChecked?: () => void;
    onBarExpandClick?: () => void;
    onBarFilterToggle?: () => void;
    onBarHelperClick?: () => void;
    onCargoCancel: () => void;
    onCargoConfirm: (cargo: CargoExpense) => void;
    onCargoSupplierChange: (value: string) => void;
    onFilterConfirm?: (filter: FilterOpts) => void;
    onSearchInputChange?: (text: string) => void;
    onSearchInputSubmit?: (text: string) => void;
    onHelperClose?: () => void;
    onSettingsApply?: () => void;
    onSettingsCancel?: () => void;
    onSmartBarRolledClick?: () => void;
    onSmartBarOnTimeClick?: () => void;
    onSmartBarDelayedClick?: () => void;
    onSmartBarFuelLowClick?: () => void;
    onSmartBarAlarmsClick?: () => void;
    onSmartBarAetrLowClick?: () => void;
    onTableColumnActualClick?: (id: string) => void;
    onTableColumnClick?: (column: string) => void;
    onTableAddDestination?: (id: string) => void;
    onTableRowCheck?: (id: string, checkValue: boolean) => void;
    onTableRowClick?: (id: string) => void;
    onTableRowBarHover?: (row: TrackingModel, position: { x: number; y: number }) => void;
    onTableRowBarLeave?: () => void;
    onLocationAlertClose?: () => void;
    onLocationAlertBack?: () => void;
}

function Tracking(props: Props) {
    const [state, setState] = useState({
        hasDestination: false,
        allLightVehicles: false
    });

    const data = props.table?.data;

    useEffect(() => {
        setState({
            hasDestination: data?.some(t => t.destination) ?? false,
            allLightVehicles:
                (data &&
                    data?.length > 0 &&
                    data?.every(d => d.monitoredObjectType?.name === MonitoredObjectTypeName.LIGHT_VEHICLE)) ??
                false
        });
    }, [data]);

    const calculateWidth = () => {
        let baseWidth = 500;
        baseWidth += state.hasDestination
            ? 220
            : state.allLightVehicles
            ? 0
            : pxWidthText(props.t('TrackingTable.addDestination'));
        return baseWidth;
    };

    const filter: number = document?.getElementById?.('tracking-filter-wrapper')?.getBoundingClientRect()?.width ?? 0;

    return (
        <div className="module">
            {props.roles.includes(Role.LT_R) && (
                <div
                    style={{ width: props.bar.expanded ? undefined : calculateWidth() + 43 }}
                    className={cn('tracking page', {
                        rolled: props.smartBar.rolled,
                        'rolled-expanded': props.smartBar.rolled && props.bar.expanded,
                        'tracking-loading': props.table?.loading
                    })}
                >
                    <Row>
                        <Col flex="40px">
                            <TrackingSmartBar
                                rolled={props.smartBar.rolled}
                                active={props.smartBar.active}
                                onTime={props.smartBar.onTime}
                                delayed={props.smartBar.delayed}
                                fuelLow={props.smartBar.fuelLow}
                                alarms={props.smartBar.alarms}
                                aetrLow={props.smartBar.aetrLow}
                                roles={props.roles}
                                onRolledClick={props.onSmartBarRolledClick}
                                onOnTimeClick={props.onSmartBarOnTimeClick}
                                onDelayedClick={props.onSmartBarDelayedClick}
                                onFuelLowClick={props.onSmartBarFuelLowClick}
                                onAlarmsClick={props.onSmartBarAlarmsClick}
                                onAetrLowClick={props.onSmartBarAetrLowClick}
                            />
                        </Col>
                        {props.smartBar.rolled && (
                            <Col flex={calculateWidth()} style={{ display: 'contents' }}>
                                <div
                                    className="tracking-bar-filter-search-settings-table"
                                    style={!props.bar.expanded ? { width: calculateWidth() } : {}}
                                >
                                    <TrackingBar
                                        checkboxAll={props.bar.checkedAll}
                                        expanded={props.bar.expanded}
                                        filtered={props.bar.filtered}
                                        filterActive={props.bar.filter}
                                        settingsActive={props.bar.settings}
                                        search={props.search}
                                        smartBar={props.smartBar.active}
                                        width={calculateWidth()}
                                        onCheckboxAllClick={props.onBarCheckAllChecked}
                                        onExpandClick={props.onBarExpandClick}
                                        onFilterClick={props.onBarFilterToggle}
                                        onHelperClick={props.onBarHelperClick}
                                        onSettingsClick={props.onSettingsCancel}
                                        onSearch={props.onSearchInputChange}
                                    />

                                    {props.bar.filter && (
                                        <div
                                            id="tracking-filter-wrapper"
                                            className={cn('tracking-filter-wrapper', {
                                                collapsed: !props.bar.expanded,
                                                expanded: props.bar.expanded,
                                                small: !state.hasDestination,
                                                hide: !filter
                                            })}
                                            style={{
                                                left:
                                                    props.bar.expanded || !filter
                                                        ? undefined
                                                        : calculateWidth() - filter + 60 // 20 margin, 40 tracking smart bar
                                            }}
                                        >
                                            <TrackingFilter
                                                statusFilter={props.filter.status}
                                                delayFilter={props.filter.delay}
                                                monitoredObjectGroupsChecked={props.filter.monitoredObjectGroupsChecked}
                                                monitoredObjectGroupsOpts={props.filter.monitoredObjectGroupsOpts}
                                                expanded={props.bar.expanded}
                                                isFilterLoading={props.bar.isFilterLoading}
                                                onCancel={props.onBarFilterToggle}
                                                onConfirm={props.onFilterConfirm}
                                            />
                                        </div>
                                    )}
                                    {props.table && props.bar.settings && (
                                        <TrackingSettings
                                            expanded={props.bar.expanded}
                                            onApply={props.onSettingsApply}
                                            onCancel={props.onSettingsCancel}
                                        />
                                    )}
                                    {props.table && data && (
                                        <TrackingTable
                                            data={props.table.data}
                                            loading={props.table.loading}
                                            roles={props.roles}
                                            fleetType={props.fleetType}
                                            expanded={props.bar.expanded}
                                            hasDestination={state.hasDestination}
                                            onAddDestination={props.onTableAddDestination}
                                            onAlarmDismiss={props.onAlarmClickDismiss}
                                            onColumnActualClick={props.onTableColumnActualClick}
                                            onColumnClick={props.onTableColumnClick}
                                            onRowCheck={props.onTableRowCheck}
                                            onRowClick={props.onTableRowClick}
                                            onRowBarHover={props.onTableRowBarHover}
                                            onRowBarLeave={props.onTableRowBarLeave}
                                        />
                                    )}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            )}
            {props.card && props.cargo && !props.bar.expanded && (
                <TrackingCargo
                    date={props.cargo.date}
                    suppliers={props.cargo.suppliers}
                    vehicle={props.cargo.vehicle}
                    onCancel={props.onCargoCancel}
                    onConfirm={props.onCargoConfirm}
                    onSupplierTextChange={props.onCargoSupplierChange}
                />
            )}
            {!props.table?.loading && props.locationAlert?.display && (
                <Modal
                    visible={props.locationAlert.display}
                    onCancel={props.onLocationAlertClose}
                    footer={[
                        <Button key="back" type="primary" onClick={props.onLocationAlertBack}>
                            {props.t('TrackingLocationAlert.backToFleet')}
                        </Button>
                    ]}
                >
                    {props.t('TrackingLocationAlert.noLocation')}
                </Modal>
            )}
            <HelperModal
                name="tracking"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}

export default withTranslation()(Tracking);
