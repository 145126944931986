import { TransportFromJSON } from 'generated/backend-api';
import { users } from './users';
// import { costs } from './costs';
import { transportsNew } from './transports-new';
import moment from 'moment';
import { pois } from './pois';
import { transportTasks } from './alarms';
import { transportsCompleted } from './transports-completed';
import { polylines } from './polylines';

export interface TransportPlaceDemo {
    name: string;
    addressStructured: {
        lang: string;
        address: string;
        countryCode: string;
        country: string;
        town: string;
        route: string;
        street_address: string;
        postalCode: string;
    }[];
    center: { type: string; coordinates: number[] };
    polygon?: { type: string; coordinates: number[][][] };
}

/**
 * Generate times to match current day
 */
export function getCurrentDates(offsetDay: number = 0, offsetMinutes: number = 0) {
    const placesBases = [
        {
            id: '1',
            times: ['2020-12-15T02:00:00+00:00', '2020-12-16T23:00:10+00:00', '2020-12-16T17:47:02+00:00'],
            nextDayIndex: 1,
            secondDayAdd: 3,
            firstDayOffset: -2 + offsetDay,
            firstDayOffsetMinutes: offsetMinutes
        },
        {
            id: '2',
            times: ['2020-12-15T08:00:00+00:00', '2020-12-15T10:00:00+00:00', '2020-12-15T16:15:00+00:00'],
            nextDayIndex: 3,
            firstDayOffset: 1 + offsetDay
        },
        {
            id: '3',
            times: ['2020-12-15T08:15:00+00:00', '2020-12-16T17:15:00+00:00', '2020-12-16T17:30:00+00:00'],
            nextDayIndex: 1,
            firstDayOffset: 1 + offsetDay
        },
        {
            id: '4',
            times: ['2021-02-26T05:30:16+00:00', '2021-02-26T09:00:20+00:00', '2021-02-27T16:00:13+00:00'],
            nextDayIndex: 2,
            firstDayOffset: 0 + offsetDay
        },
        {
            id: '5',
            times: ['2021-02-26T05:30:16+00:00', '2021-02-26T09:00:20+00:00', '2021-02-27T16:00:13+00:00'],
            nextDayIndex: 2,
            firstDayOffset: 0 + offsetDay
        },
        {
            id: '6',
            times: ['2021-02-26T05:00:16+00:00', '2021-02-26T16:00:20+00:00', '2021-02-27T11:00:13+00:00'],
            nextDayIndex: 2,
            firstDayOffset: 0 + offsetDay
        },
        {
            id: '7',
            times: ['2021-02-26T05:00:16+00:00', '2021-02-27T16:00:20+00:00'],
            nextDayIndex: 2,
            firstDayOffset: 0 + offsetDay
        },
        {
            id: '8',
            times: ['2021-02-26T15:00:16+00:00', '2021-02-27T16:00:20+00:00'],
            nextDayIndex: 1,
            firstDayOffset: 3 + offsetDay
        },
        {
            id: '9',
            times: ['2021-02-25T18:00:16+00:00', '2021-02-27T16:00:20+00:00'],
            nextDayIndex: 1,
            firstDayOffset: 0 + offsetDay
        },
        {
            id: '10',
            times: ['2021-02-25T20:00:10+00:00', '2021-02-26T17:00:20+00:00'],
            nextDayIndex: 1,
            firstDayOffset: 0 + offsetDay
        }
    ];

    const today = moment();
    const data = placesBases.map(base => {
        const times = base.times.map((t, i) => {
            if (i < base.nextDayIndex) {
                return moment(t)
                    .set('DDD', today.get('DDD'))
                    .set('month', today.get('month'))
                    .set('year', today.get('year'))
                    .add(base.firstDayOffset, 'days')
                    .add(base.firstDayOffsetMinutes, 'minutes')
                    .toISOString();
            } else {
                return moment(t)
                    .set('DDD', today.get('DDD'))
                    .set('month', today.get('month'))
                    .set('year', today.get('year'))
                    .add(1 + (base.secondDayAdd ?? 0) + base.firstDayOffset, 'days')
                    .add(base.firstDayOffsetMinutes, 'minutes')
                    .toISOString();
            }
        });
        return {
            ...base,
            times
        };
    });

    return data;
}

export const dates = getCurrentDates();

const data = [
    // TRANSPORT 1
    {
        id: '1',
        version: 1,
        firstRta: dates[0].times[0],
        lastRta: dates[0].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[0].times[0],
        state: 'delayed',
        current_primary_assigned_monitored_object: {
            startTime: dates[0].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 1,
            type: 'vehicle'
        },
        driver_ids: [users[7].id],
        last_driver: {
            id: users[7].id,
            name: users[7].name,
            surname: users[7].surname
        },
        monitoredObjects: [
            {
                startTime: dates[0].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 1,
                type: 'vehicle'
            },
            {
                startTime: dates[0].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 101,
                type: 'trailer'
            }
        ],
        users: [
            {
                id: users[0].id,
                name: users[0].name,
                surname: users[0].surname
            }
        ],
        clientId: 100,
        client: null,
        name: 'Slovnaftská 102, 82107 Bratislava, 821 07 Bratislava-Ružinov, Slovakia - ES, Paterna, Carrer dels Bombers 1',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_ggsxusl8u',
                type: 'waypoint',
                name: 'Slovnaftská 102, 82107 Bratislava, 821 07 Bratislava-Ružinov, Slovakia',
                rta: dates[0].times[0],
                rtd: dates[0].times[0],
                ata: null,
                atd: null,
                eta: dates[0].times[0],
                etd: null,
                distance: 1873468,
                duration: 83707,
                actualDistance: null,
                note: null,
                route: polylines[0][0],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(SK) 821 07, Bratislava, Slovnaftská',
                        countryCode: 'SK',
                        country: 'Slovensko',
                        town: 'Bratislava',
                        route: 'Slovnaftská',
                        street_address: 'Slovnaftská 81',
                        postalCode: '821 07'
                    },
                    {
                        lang: 'en',
                        address: '(SK) 821 07, Bratislava, Slovnaftská',
                        countryCode: 'SK',
                        country: 'Slovakia',
                        town: 'Bratislava',
                        route: 'Slovnaftská',
                        street_address: 'Slovnaftská 81',
                        postalCode: '821 07'
                    },
                    {
                        lang: 'sk',
                        address: '(SK) 821 07, Bratislava, Slovnaftská',
                        countryCode: 'SK',
                        country: 'Slovensko',
                        town: 'Bratislava',
                        route: 'Slovnaftská',
                        street_address: 'Slovnaftská 81',
                        postalCode: '821 07'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[0]],
                center: { type: 'Point', coordinates: [17.179500774652098, 48.12933428709012] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [17.179500774652098, 48.13113091765835],
                            [17.181831922408335, 48.130232602374235],
                            [17.181831922408335, 48.128435971806],
                            [17.179500774652098, 48.12753765652188],
                            [17.17716962689586, 48.128435971806],
                            [17.17716962689586, 48.130232602374235],
                            [17.179500774652098, 48.13113091765835]
                        ]
                    ]
                }
            },
            {
                id: '_o6x5t7unv',
                type: 'waypoint',
                name: 'ES, Santa Coloma de Gramenet, Carretera de La Roca 5',
                rta: dates[0].times[1],
                rtd: dates[0].times[1],
                ata: null,
                atd: null,
                eta: dates[0].times[1],
                etd: null,
                distance: 367853,
                duration: 16143,
                actualDistance: null,
                note: null,
                route: polylines[0][1],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                        countryCode: 'ES',
                        country: 'Španělsko',
                        town: 'Santa Coloma de Gramenet',
                        route: 'Carretera de La Roca',
                        street_address: 'Carretera de La Roca 5',
                        postalCode: '08924'
                    },
                    {
                        lang: 'en',
                        address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                        countryCode: 'ES',
                        country: 'Spain',
                        town: 'Santa Coloma de Gramenet',
                        route: 'Carretera de La Roca',
                        street_address: 'Carretera de La Roca 5',
                        postalCode: '08924'
                    },
                    {
                        lang: 'sk',
                        address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                        countryCode: 'ES',
                        country: 'Španielsko',
                        town: 'Santa Coloma de Gramenet',
                        route: 'Carretera de La Roca',
                        street_address: 'Carretera de La Roca 5',
                        postalCode: '08924'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[1]],
                center: { type: 'Point', coordinates: [2.18961, 41.467237] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [2.18961, 41.469033630568234],
                            [2.191686412872474, 41.468135315284115],
                            [2.191686412872474, 41.46633868471588],
                            [2.18961, 41.46544036943176],
                            [2.1875335871275263, 41.46633868471588],
                            [2.1875335871275263, 41.468135315284115],
                            [2.18961, 41.469033630568234]
                        ]
                    ]
                }
            },
            {
                id: '_lmiqd7c2l',
                type: 'waypoint',
                name: 'ES, Paterna, Carrer dels Bombers 1',
                rta: dates[0].times[2],
                rtd: dates[0].times[2],
                ata: null,
                atd: null,
                eta: dates[0].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(ES) 46988, Paterna, Carrer dels Bombers',
                        countryCode: 'ES',
                        country: 'Španělsko',
                        town: 'Paterna',
                        route: 'Carrer dels Bombers',
                        street_address: 'Carrer dels Bombers 1',
                        postalCode: '46988'
                    },
                    {
                        lang: 'en',
                        address: '(ES) 46988, Paterna, Carrer dels Bombers',
                        countryCode: 'ES',
                        country: 'Spain',
                        town: 'Paterna',
                        route: 'Carrer dels Bombers',
                        street_address: 'Carrer dels Bombers 1',
                        postalCode: '46988'
                    },
                    {
                        lang: 'sk',
                        address: '(ES) 46988, Paterna, Carrer dels Bombers',
                        countryCode: 'ES',
                        country: 'Španielsko',
                        town: 'Paterna',
                        route: 'Carrer dels Bombers',
                        street_address: 'Carrer dels Bombers 1',
                        postalCode: '46988'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[1]],
                center: { type: 'Point', coordinates: [-0.4546965000000001, 39.51483579999999] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [-0.4546965000000001, 39.51663243056823],
                            [-0.452679638355007, 39.51573411528411],
                            [-0.452679638355007, 39.513937484715875],
                            [-0.4546965000000001, 39.51303916943176],
                            [-0.4567133616449932, 39.513937484715875],
                            [-0.4567133616449932, 39.51573411528411],
                            [-0.4546965000000001, 39.51663243056823]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        }
    },
    // TRANSPORT 2
    {
        id: '2',
        version: '1',
        firstRta: dates[1].times[0],
        lastRta: dates[1].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: '2020-12-15T14:00:46+00:00',
        state: 'active',
        current_primary_assigned_monitored_object: {
            startTime: dates[1].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 2,
            type: 'vehicle'
        },

        driver_ids: [users[4].id],
        last_driver: {
            id: users[4].id,
            name: users[4].name,
            surname: users[4].surname
        },
        monitoredObjects: [
            {
                startTime: dates[1].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 2,
                type: 'vehicle'
            },
            {
                startTime: dates[1].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 103,
                type: 'trailer'
            }
        ],
        users: [
            {
                id: users[1].id,
                name: users[1].name,
                surname: users[1].surname
            },
            {
                id: users[0].id,
                name: users[0].name,
                surname: users[0].surname
            }
        ],
        clientId: 100,
        client: null,
        name: 'CZ, Plzeň 3, Unnamed Road -  DE, Ludwigshafen am Rhein, L523',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_besozoww7',
                type: 'waypoint',
                name: 'Unnamed Road, 318 00 Plzeň 3, Czechia',
                rta: dates[1].times[0],
                rtd: dates[1].times[0],
                ata: null,
                atd: null,
                eta: dates[1].times[0],
                etd: null,
                distance: 64684,
                duration: 3712,
                actualDistance: null,
                note: null,
                route: polylines[1][0],
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(CZ) 301 00, Plzeň, Nemocniční',
                        countryCode: 'CZ',
                        country: 'Czechia',
                        town: 'Plzeň',
                        route: 'Nemocniční',
                        street_address: 'Nemocniční 1',
                        postalCode: '301 00'
                    },
                    {
                        lang: 'sk',
                        address: '(CZ) 301 00, Plzeň, Nemocniční',
                        countryCode: 'CZ',
                        country: 'Česko',
                        town: 'Plzeň',
                        route: 'Nemocniční',
                        street_address: 'Nemocniční 1',
                        postalCode: '301 00'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [13.357157607812495, 49.73956853175707] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [13.357157607812495, 49.74136516232531],
                            [13.359565184924659, 49.74046684704119],
                            [13.359565184924659, 49.738670216472954],
                            [13.357157607812495, 49.737771901188836],
                            [13.35475003070033, 49.738670216472954],
                            [13.35475003070033, 49.74046684704119],
                            [13.357157607812495, 49.74136516232531]
                        ]
                    ]
                }
            },
            {
                id: '_r1qwprclw',
                type: 'waypoint',
                name: 'CZ, Rozvadov, Silnice II/605 117',
                rta: dates[1].times[1],
                rtd: dates[1].times[1],
                ata: null,
                atd: null,
                eta: dates[1].times[1],
                etd: null,
                distance: 401558,
                duration: 19586,
                actualDistance: null,
                note: null,
                route: polylines[1][1],
                addressStructured: [
                    {
                        lang: 'custom',
                        address: pois[0].name,
                        countryCode: '',
                        country: '',
                        town: '',
                        route: '',
                        street_address: 'Silnice II/605 117',
                        postalCode: ''
                    },
                    {
                        lang: 'sk',
                        address: '(CZ) 348 06, Rozvadov, 605',
                        countryCode: 'CZ',
                        country: 'Česko',
                        town: 'Rozvadov',
                        route: '605',
                        street_address: 'Silnice II/605 117',
                        postalCode: '348 06'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [12.6006072, 49.6754141] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [12.6006072, 49.677210730568234],
                            [12.603011599613897, 49.676312415284116],
                            [12.603011599613897, 49.67451578471588],
                            [12.6006072, 49.67361746943176],
                            [12.598202800386105, 49.67451578471588],
                            [12.598202800386105, 49.676312415284116],
                            [12.6006072, 49.677210730568234]
                        ]
                    ]
                }
            },
            {
                id: '_uhy7w73ej',
                type: 'waypoint',
                name: 'DE, Ludwigshafen am Rhein, L523 L953',
                rta: dates[1].times[2],
                rtd: dates[1].times[2],
                ata: null,
                atd: null,
                eta: dates[1].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(DE) 67063, Ludwigshafen am Rhein, L523',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Ludwigshafen am Rhein',
                        route: 'L523',
                        street_address: 'L523 L953',
                        postalCode: '67063'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 67063, Ludwigshafen am Rhein, L523',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Ludwigshafen am Rhein',
                        route: 'L523',
                        street_address: 'L523 L953',
                        postalCode: '67063'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [8.413979099999999, 49.5051135] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [8.413979099999999, 49.50691013056824],
                            [8.41637511988464, 49.50601181528412],
                            [8.41637511988464, 49.50421518471588],
                            [8.413979099999999, 49.503316869431764],
                            [8.411583080115358, 49.50421518471588],
                            [8.411583080115358, 49.50601181528412],
                            [8.413979099999999, 49.50691013056824]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        }
    },
    // TRANSPORT 3
    {
        id: '3',
        version: '1',
        firstRta: dates[2].times[0],
        lastRta: dates[2].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[2].times[0],
        state: 'active',
        current_primary_assigned_monitored_object: {
            startTime: dates[2].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 3,
            type: 'vehicle'
        },
        driver_ids: [users[3].id],
        last_driver: {
            id: users[3].id,
            name: users[3].name,
            surname: users[3].surname
        },
        monitoredObjects: [
            {
                startTime: dates[2].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 3,
                type: 'vehicle'
            },
            {
                startTime: dates[2].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 103,
                type: 'trailer'
            }
        ],
        users: [
            {
                id: users[2].id,
                name: users[2].name,
                surname: users[2].surname
            },
            {
                id: users[0].id,
                name: users[0].name,
                surname: users[0].surname
            }
        ],
        clientId: 100,
        client: null,
        name: 'PL, Gdańsk, Mieczysława Boguckiego 80 - IT, Venezia, Via Alessandro Volta 10',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_pb6of8g54',
                type: 'waypoint',
                name: 'PL, Gdańsk, Mieczysława Boguckiego 80',
                rta: dates[2].times[0],
                rtd: dates[2].times[0],
                ata: null,
                atd: null,
                eta: dates[2].times[0],
                etd: null,
                distance: 917086,
                duration: 42308,
                actualDistance: null,
                note: null,
                route: polylines[2][0],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(PL) 80-690, Gdańsk, Mieczysława Boguckiego',
                        countryCode: 'PL',
                        country: 'Polsko',
                        town: 'Gdańsk',
                        route: 'Mieczysława Boguckiego',
                        street_address: 'Mieczysława Boguckiego 80',
                        postalCode: '80-690'
                    },
                    {
                        lang: 'en',
                        address: '(PL) 80-690, Gdańsk, Mieczysława Boguckiego',
                        countryCode: 'PL',
                        country: 'Poland',
                        town: 'Gdańsk',
                        route: 'Mieczysława Boguckiego',
                        street_address: 'Mieczysława Boguckiego 80',
                        postalCode: '80-690'
                    },
                    {
                        lang: 'sk',
                        address: '(PL) 80-690, Gdańsk, Mieczysława Boguckiego',
                        countryCode: 'PL',
                        country: 'Poľsko',
                        town: 'Gdańsk',
                        route: 'Mieczysława Boguckiego',
                        street_address: 'Mieczysława Boguckiego 80',
                        postalCode: '80-690'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [18.9285651, 54.33553759999999] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [18.9285651, 54.337334230568224],
                            [18.93123375869675, 54.336435915284106],
                            [18.93123375869675, 54.33463928471587],
                            [18.9285651, 54.33374096943175],
                            [18.92589644130325, 54.33463928471587],
                            [18.92589644130325, 54.336435915284106],
                            [18.9285651, 54.337334230568224]
                        ]
                    ]
                }
            },
            {
                id: '_w738wif2h',
                type: 'waypoint',
                name: 'AT, Wien, Ailecgasse 82',
                rta: dates[2].times[1],
                rtd: dates[2].times[1],
                ata: null,
                atd: null,
                eta: dates[2].times[1],
                etd: null,
                distance: 577414,
                duration: 28195,
                actualDistance: null,
                note: null,
                route: polylines[2][1],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakousko',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'en',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Austria',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'sk',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakúsko',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [16.4533589, 48.1408986] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [16.4533589, 48.142695230568236],
                            [16.455690572852177, 48.14179691528412],
                            [16.455690572852177, 48.14000028471588],
                            [16.4533589, 48.13910196943176],
                            [16.451027227147826, 48.14000028471588],
                            [16.451027227147826, 48.14179691528412],
                            [16.4533589, 48.142695230568236]
                        ]
                    ]
                }
            },
            {
                id: '_fn7kwxxek',
                type: 'waypoint',
                name: 'IT, Venezia, Via Alessandro Volta 10',
                rta: dates[2].times[2],
                rtd: dates[2].times[2],
                ata: null,
                atd: null,
                eta: dates[2].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Itálie',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 10',
                        postalCode: '30175'
                    },
                    {
                        lang: 'en',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Italy',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 10',
                        postalCode: '30175'
                    },
                    {
                        lang: 'sk',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Taliansko',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 10',
                        postalCode: '30175'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [12.2400678, 45.469429] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [12.2400678, 45.471225630568235],
                            [12.242286466191398, 45.470327315284116],
                            [12.242286466191398, 45.46853068471588],
                            [12.2400678, 45.46763236943176],
                            [12.237849133808602, 45.46853068471588],
                            [12.237849133808602, 45.470327315284116],
                            [12.2400678, 45.471225630568235]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        }
    },
    // TRANSPORT 4
    {
        id: '4',
        version: '1',
        firstRta: dates[3].times[0],
        lastRta: dates[3].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[3].times[0],
        state: 'new',
        current_primary_assigned_monitored_object: {
            startTime: dates[3].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: undefined,
            type: 'vehicle'
        },
        monitoredObjects: [
            {
                startTime: dates[3].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: undefined,
                type: 'vehicle'
            }
        ],
        users: [],
        clientId: 100,
        client: null,
        name: 'HU, Budapest, Bojtár utca 74 - IT, Venezia, Via Alessandro Volta 9',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_845tp18za',
                type: 'waypoint',
                name: 'HU, Budapest, Bojtár utca 74',
                rta: dates[3].times[0],
                rtd: dates[3].times[0],
                ata: null,
                atd: null,
                eta: dates[3].times[0],
                etd: null,
                distance: 243373,
                duration: 13054,
                actualDistance: 100000,
                note: null,
                route: polylines[4][0],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(HU) 1037, Budapest, Bojtár u.',
                        countryCode: 'HU',
                        country: 'Maďarsko',
                        town: 'Budapest',
                        route: 'Bojtár u.',
                        street_address: 'Bojtár utca 74',
                        postalCode: '1037'
                    },
                    {
                        lang: 'en',
                        address: '(HU) 1037, Budapest, Bojtár u.',
                        countryCode: 'HU',
                        country: 'Hungary',
                        town: 'Budapest',
                        route: 'Bojtár u.',
                        street_address: 'Bojtár utca 74',
                        postalCode: '1037'
                    },
                    {
                        lang: 'sk',
                        address: '(HU) 1037, Budapest, Bojtár u.',
                        countryCode: 'HU',
                        country: 'Maďarsko',
                        town: 'Budapest',
                        route: 'Bojtár u.',
                        street_address: 'Bojtár utca 74',
                        postalCode: '1037'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [19.0379086, 47.55991580000001] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [19.0379086, 47.56171243056825],
                            [19.040214297044773, 47.56081411528413],
                            [19.040214297044773, 47.559017484715895],
                            [19.0379086, 47.55811916943178],
                            [19.03560290295523, 47.559017484715895],
                            [19.03560290295523, 47.56081411528413],
                            [19.0379086, 47.56171243056825]
                        ]
                    ]
                }
            },
            {
                id: '_v5pgtzudj',
                type: 'waypoint',
                name: '(AT) 1110, Wien, Ailecgasse',
                rta: dates[3].times[1],
                rtd: dates[3].times[1],
                ata: null,
                atd: null,
                eta: dates[3].times[1],
                etd: null,
                distance: 576387,
                duration: 27920,
                actualDistance: null,
                note: null,
                route: polylines[4][1],
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(AT) 1110, Vídeň, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakousko',
                        town: 'Vídeň',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'en',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Austria',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'sk',
                        address: '(AT) 1110, Viedeň, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakúsko',
                        town: 'Viedeň',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [16.4533589, 48.1408986] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [16.4533589, 48.142695230568236],
                            [16.455690572852177, 48.14179691528412],
                            [16.455690572852177, 48.14000028471588],
                            [16.4533589, 48.13910196943176],
                            [16.451027227147826, 48.14000028471588],
                            [16.451027227147826, 48.14179691528412],
                            [16.4533589, 48.142695230568236]
                        ]
                    ]
                }
            },
            {
                id: '_zdf1xh2mu',
                type: 'waypoint',
                name: 'IT, Venezia, Via Alessandro Volta 9',
                rta: dates[3].times[2],
                rtd: dates[3].times[2],
                ata: null,
                atd: null,
                eta: dates[3].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'cs',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Itálie',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 9',
                        postalCode: '30175'
                    },
                    {
                        lang: 'en',
                        address: '(IT) 30175, Venice, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Italy',
                        town: 'Venice',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 9',
                        postalCode: '30175'
                    },
                    {
                        lang: 'sk',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Taliansko',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 9',
                        postalCode: '30175'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [12.2331501, 45.472462] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [12.2331501, 45.47425863056824],
                            [12.235368885588342, 45.47336031528412],
                            [12.235368885588342, 45.47156368471588],
                            [12.2331501, 45.470665369431764],
                            [12.230931314411658, 45.47156368471588],
                            [12.230931314411658, 45.47336031528412],
                            [12.2331501, 45.47425863056824]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    // TRANSPORT 5
    {
        id: '5',
        version: '1',
        firstRta: dates[4].times[0],
        lastRta: dates[4].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[4].times[0],
        state: 'active',
        current_primary_assigned_monitored_object: {
            startTime: dates[4].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 7,
            type: 'vehicle'
        },
        driver_ids: [users[2].id],
        last_driver: {
            id: users[2].id,
            name: users[2].name,
            surname: users[2].surname
        },
        monitoredObjects: [
            {
                startTime: dates[4].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 7,
                type: 'vehicle'
            },
            {
                startTime: dates[4].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 105,
                type: 'trailer'
            }
        ],
        users: [
            {
                id: users[3].id,
                name: users[3].name,
                surname: users[3].surname
            }
        ],
        clientId: 100,
        client: null,
        name: 'BE, Zaventem, Hoge Wei 37 - ES, Santa Coloma de Gramenet, Carretera de La Roca 5',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_cl5zhldw3',
                type: 'waypoint',
                name: 'BE, Zaventem, Hoge Wei 37',
                rta: dates[4].times[0],
                rtd: dates[4].times[0],
                ata: null,
                atd: null,
                eta: dates[4].times[0],
                etd: null,
                distance: 1104337,
                duration: 49168,
                actualDistance: null,
                note: null,
                route: polylines[5][0],
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(BE) 1930, Zaventem, Hoge Wei',
                        countryCode: 'BE',
                        country: 'Belgium',
                        town: 'Zaventem',
                        route: 'Hoge Wei',
                        street_address: 'Hoge Wei 37',
                        postalCode: '1930'
                    },
                    {
                        lang: 'sk',
                        address: '(BE) 1930, Zaventem, Hoge Wei',
                        countryCode: 'BE',
                        country: 'Belgicko',
                        town: 'Zaventem',
                        route: 'Hoge Wei',
                        street_address: 'Hoge Wei 37',
                        postalCode: '1930'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [4.4995572, 50.8752355] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [4.4995572, 50.87703213056824],
                            [4.502022969068774, 50.87613381528412],
                            [4.502022969068774, 50.874337184715884],
                            [4.4995572, 50.873438869431766],
                            [4.497091430931226, 50.874337184715884],
                            [4.497091430931226, 50.87613381528412],
                            [4.4995572, 50.87703213056824]
                        ]
                    ]
                }
            },
            {
                id: '_soxebaav2',
                type: 'waypoint',
                name: 'AT, Wien, Ailecgasse 82',
                rta: dates[4].times[1],
                rtd: dates[4].times[1],
                ata: null,
                atd: null,
                eta: dates[4].times[1],
                etd: null,
                distance: 1830038,
                duration: 80553,
                actualDistance: null,
                note: null,
                route: polylines[5][1],
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Austria',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'sk',
                        address: '(AT) 1110, Viedeň, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakúsko',
                        town: 'Viedeň',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [16.4533589, 48.1408986] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [16.4533589, 48.142695230568236],
                            [16.455690572852177, 48.14179691528412],
                            [16.455690572852177, 48.14000028471588],
                            [16.4533589, 48.13910196943176],
                            [16.451027227147826, 48.14000028471588],
                            [16.451027227147826, 48.14179691528412],
                            [16.4533589, 48.142695230568236]
                        ]
                    ]
                }
            },
            {
                id: '_v4hxi9o6s',
                type: 'waypoint',
                name: 'ES, Santa Coloma de Gramenet, Carretera de La Roca 5',
                rta: dates[4].times[2],
                rtd: dates[4].times[2],
                ata: null,
                atd: null,
                eta: dates[4].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                        countryCode: 'ES',
                        country: 'Spain',
                        town: 'Santa Coloma de Gramenet',
                        route: 'Carretera de La Roca',
                        street_address: 'Carretera de La Roca 5',
                        postalCode: '08924'
                    },
                    {
                        lang: 'sk',
                        address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                        countryCode: 'ES',
                        country: 'Španielsko',
                        town: 'Santa Coloma de Gramenet',
                        route: 'Carretera de La Roca',
                        street_address: 'Carretera de La Roca 5',
                        postalCode: '08924'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [2.18961, 41.467237] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [2.18961, 41.469033630568234],
                            [2.191686412872474, 41.468135315284115],
                            [2.191686412872474, 41.46633868471588],
                            [2.18961, 41.46544036943176],
                            [2.1875335871275263, 41.46633868471588],
                            [2.1875335871275263, 41.468135315284115],
                            [2.18961, 41.469033630568234]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    // TRANSPORT 6
    {
        id: '6',
        version: '1',
        firstRta: dates[5].times[0],
        lastRta: dates[5].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: '2021-01-28T20:21:42+00:00',
        state: 'delayed',
        current_primary_assigned_monitored_object: {
            startTime: dates[5].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 6,
            type: 'vehicle'
        },
        driver_ids: [users[6].id],
        last_driver: {
            id: users[6].id,
            name: users[6].name,
            surname: users[6].surname
        },
        monitoredObjects: [
            {
                startTime: dates[5].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 6,
                type: 'vehicle'
            },
            {
                startTime: dates[0].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 107,
                type: 'trailer'
            }
        ],
        users: [
            {
                id: users[5].id,
                name: users[5].name,
                surname: users[5].surname
            }
        ],
        clientId: 100,
        client: null,
        name: 'IT, Venice, Via Alessandro Volta 9 - HU, Budapest, Kunigunda útja 45',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_13a84lada',
                type: 'waypoint',
                name: 'IT, Venice, Via Alessandro Volta 9',
                rta: dates[5].times[0],
                rtd: dates[5].times[0],
                ata: null,
                atd: null,
                eta: dates[5].times[0],
                etd: null,
                distance: 575829,
                duration: 29321,
                actualDistance: null,
                note: null,
                route: polylines[6][0],
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(IT) 30175, Venice, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Italy',
                        town: 'Venice',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 9',
                        postalCode: '30175'
                    },
                    {
                        lang: 'sk',
                        address: '(IT) 30175, Venezia, Via Alessandro Volta',
                        countryCode: 'IT',
                        country: 'Taliansko',
                        town: 'Venezia',
                        route: 'Via Alessandro Volta',
                        street_address: 'Via Alessandro Volta 9',
                        postalCode: '30175'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [12.2329539, 45.4723258] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [12.2329539, 45.47412243056824],
                            [12.23517268022629, 45.47322411528412],
                            [12.23517268022629, 45.47142748471588],
                            [12.2329539, 45.470529169431764],
                            [12.23073511977371, 45.47142748471588],
                            [12.23073511977371, 45.47322411528412],
                            [12.2329539, 45.47412243056824]
                        ]
                    ]
                }
            },
            {
                id: '_o61ayyw7p',
                type: 'waypoint',
                name: 'AT, Wien, Ailecgasse 82',
                rta: dates[5].times[1],
                rtd: dates[5].times[1],
                ata: null,
                atd: null,
                eta: dates[5].times[1],
                etd: null,
                distance: 242837,
                duration: 13273,
                actualDistance: null,
                note: null,
                route: polylines[6][1],
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(AT) 1110, Wien, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Austria',
                        town: 'Wien',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    },
                    {
                        lang: 'sk',
                        address: '(AT) 1110, Viedeň, Ailecgasse',
                        countryCode: 'AT',
                        country: 'Rakúsko',
                        town: 'Viedeň',
                        route: 'Ailecgasse',
                        street_address: 'Ailecgasse 82',
                        postalCode: '1110'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [16.4533589, 48.1408986] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [16.4533589, 48.142695230568236],
                            [16.455690572852177, 48.14179691528412],
                            [16.455690572852177, 48.14000028471588],
                            [16.4533589, 48.13910196943176],
                            [16.451027227147826, 48.14000028471588],
                            [16.451027227147826, 48.14179691528412],
                            [16.4533589, 48.142695230568236]
                        ]
                    ]
                }
            },
            {
                id: '_bk765xak4',
                type: 'waypoint',
                name: 'HU, Budapest, Kunigunda útja 45',
                rta: dates[5].times[2],
                rtd: dates[5].times[2],
                ata: null,
                atd: null,
                eta: dates[5].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'en',
                        address: '(HU) 1037, Budapest, Kunigunda útja',
                        countryCode: 'HU',
                        country: 'Hungary',
                        town: 'Budapest',
                        route: 'Kunigunda útja',
                        street_address: 'Kunigunda útja 45',
                        postalCode: '1037'
                    },
                    {
                        lang: 'sk',
                        address: '(HU) 1037, Budapest, Kunigunda útja',
                        countryCode: 'HU',
                        country: 'Maďarsko',
                        town: 'Budapest',
                        route: 'Kunigunda útja',
                        street_address: 'Kunigunda útja 45',
                        postalCode: '1037'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [19.0355739, 47.5607631] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [19.0355739, 47.56255973056824],
                            [19.037879634334242, 47.56166141528412],
                            [19.037879634334242, 47.559864784715884],
                            [19.0355739, 47.558966469431766],
                            [19.033268165665756, 47.559864784715884],
                            [19.033268165665756, 47.56166141528412],
                            [19.0355739, 47.56255973056824]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    // TRANSPORT 7
    {
        id: '7',
        version: '1',
        firstRta: dates[6].times[0],
        lastRta: dates[6].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[6].times[0],
        state: 'new',
        current_primary_assigned_monitored_object: {
            startTime: dates[6].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: undefined,
            type: 'vehicle'
        },
        monitoredObjects: [
            {
                startTime: dates[6].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: undefined,
                type: 'vehicle'
            }
        ],
        users: [],
        clientId: 100,
        client: null,
        name: 'DE, Kiel, Eckernförder Straße 30 - DE, Mníchov, Tal 50',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_2pxzgx2yh',
                type: 'waypoint',
                name: 'DE, Kiel, Eckernförder Straße 30',
                rta: dates[6].times[0],
                rtd: dates[6].times[0],
                ata: null,
                atd: null,
                eta: dates[6].times[0],
                etd: null,
                distance: 870480,
                duration: 39532,
                actualDistance: null,
                note: null,
                route: polylines[7][0],
                addressStructured: [
                    {
                        lang: 'bg',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Германия',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'en',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [10.1227652, 54.3232927] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [10.1227652, 54.32508933056824],
                            [10.125433064257328, 54.32419101528412],
                            [10.125433064257328, 54.322394384715885],
                            [10.1227652, 54.32149606943177],
                            [10.120097335742672, 54.322394384715885],
                            [10.120097335742672, 54.32419101528412],
                            [10.1227652, 54.32508933056824]
                        ]
                    ]
                }
            },
            {
                id: '_fxb1zi0bl',
                type: 'waypoint',
                name: 'DE, Mníchov, Tal 50',
                rta: dates[6].times[1],
                rtd: dates[6].times[1],
                ata: null,
                atd: null,
                eta: dates[6].times[1],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'bg',
                        address: '(DE) 80539, München, Thomas-Wimmer-Ring',
                        countryCode: 'DE',
                        country: 'Германия',
                        town: 'München',
                        route: 'Thomas-Wimmer-Ring',
                        street_address: 'Thomas-Wimmer-Ring 1A',
                        postalCode: '80539'
                    },
                    {
                        lang: 'en',
                        address: '(DE) 80331, Munich, Tal',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Munich',
                        route: 'Tal',
                        street_address: 'Tal 50',
                        postalCode: '80331'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 80331, Mníchov, Tal',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Mníchov',
                        route: 'Tal',
                        street_address: 'Tal 50',
                        postalCode: '80331'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [11.5819806, 48.1351253] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [11.5819806, 48.136921930568235],
                            [11.584312010664908, 48.13602361528412],
                            [11.584312010664908, 48.13422698471588],
                            [11.5819806, 48.13332866943176],
                            [11.579649189335091, 48.13422698471588],
                            [11.579649189335091, 48.13602361528412],
                            [11.5819806, 48.136921930568235]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    // TRANSPORT 8
    {
        id: '8',
        version: '1',
        firstRta: dates[7].times[0],
        lastRta: dates[7].times[dates[0].times.length - 1],
        routeType: null,
        lastUpdated: dates[7].times[0],
        state: 'active',
        current_primary_assigned_monitored_object: {
            startTime: dates[7].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 5,
            type: 'vehicle'
        },
        monitoredObjects: [
            {
                startTime: dates[7].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 5,
                type: 'vehicle'
            },
            {
                startTime: dates[7].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 106,
                type: 'trailer'
            }
        ],
        driver_ids: [users[1].id],
        last_driver: {
            id: users[1].id,
            name: users[1].name,
            surname: users[1].surname
        },
        users: [],
        clientId: 100,
        client: null,
        name: 'DE, Kiel, Eckernförder Straße 30 - DE, Mníchov, Tal 50',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_2pxzgx2yh',
                type: 'waypoint',
                name: 'DE, Kiel, Eckernförder Straße 30',
                rta: dates[7].times[0],
                rtd: dates[7].times[0],
                ata: null,
                atd: null,
                eta: dates[7].times[0],
                etd: null,
                distance: 397757,
                duration: 20370,
                actualDistance: null,
                note: null,
                route: polylines[8][0],
                addressStructured: [
                    {
                        lang: 'bg',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Германия',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'en',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [9.9936818, 53.5510846] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [9.9936818, 53.55288123056824],
                            [9.996300740509529, 53.55198291528412],
                            [9.996300740509529, 53.550186284715885],
                            [9.9936818, 53.54928796943177],
                            [9.99106285949047, 53.550186284715885],
                            [9.99106285949047, 53.55198291528412],
                            [9.9936818, 53.55288123056824]
                        ]
                    ]
                }
            },
            {
                id: '_fxb1zi0bl',
                type: 'waypoint',
                name: 'PL, Szczecin, plac Żołnierza Polskiego',
                rta: dates[7].times[1],
                rtd: dates[7].times[1],
                ata: null,
                atd: null,
                eta: dates[7].times[1],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'de',
                        address: '(PL) 70-553, Szczecin, plac Żołnierza Polskiego',
                        countryCode: 'PL',
                        country: 'Polen',
                        town: 'Szczecin',
                        route: 'plac Żołnierza Polskiego',
                        street_address: 'plac Żołnierza Polskiego ',
                        postalCode: '70-553'
                    },
                    {
                        lang: 'en',
                        address: '(PL) 70-553, Szczecin, plac Żołnierza Polskiego',
                        countryCode: 'PL',
                        country: 'Poland',
                        town: 'Szczecin',
                        route: 'plac Żołnierza Polskiego',
                        street_address: 'plac Żołnierza Polskiego ',
                        postalCode: '70-553'
                    },
                    {
                        lang: 'sk',
                        address: '(PL) 70-553, Szczecin, plac Żołnierza Polskiego',
                        countryCode: 'PL',
                        country: 'Poľsko',
                        town: 'Szczecin',
                        route: 'plac Żołnierza Polskiego',
                        street_address: 'plac Żołnierza Polskiego ',
                        postalCode: '70-553'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [14.5528116, 53.4285438] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [14.5528116, 53.430340430568236],
                            [14.555422984601947, 53.42944211528412],
                            [14.555422984601947, 53.42764548471588],
                            [14.5528116, 53.42674716943176],
                            [14.550200215398053, 53.42764548471588],
                            [14.550200215398053, 53.42944211528412],
                            [14.5528116, 53.430340430568236]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    // TRANSPORT 9
    {
        id: '9',
        version: '1',
        firstRta: dates[8].times[0],
        lastRta: dates[8].times[dates[8].times.length - 1],
        routeType: null,
        lastUpdated: dates[8].times[0],
        state: 'active',
        current_primary_assigned_monitored_object: {
            startTime: dates[8].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 4,
            type: 'vehicle'
        },
        driver_ids: [users[1].id, users[0].id],
        last_driver: {
            id: users[0].id,
            name: users[0].name,
            surname: users[0].surname
        },
        monitoredObjects: [
            {
                startTime: dates[8].times[0],
                endTime: undefined,
                primary: true,
                monitoredObjectId: 7,
                type: 'vehicle'
            },
            {
                startTime: dates[8].times[0],
                endTime: undefined,
                primary: false,
                monitoredObjectId: 104,
                type: 'trailer'
            }
        ],
        users: [],
        clientId: 100,
        client: null,
        name: 'DE, Kiel, Eckernförder Straße 30 - DE, Mníchov, Tal 50',
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_2pxzgx2yh',
                type: 'waypoint',
                name: 'DE, Kiel, Eckernförder Straße 30',
                rta: dates[8].times[0],
                rtd: dates[8].times[0],
                ata: null,
                atd: null,
                eta: dates[8].times[0],
                etd: null,
                distance: 870480,
                duration: 39532,
                actualDistance: null,
                note: null,
                route: polylines[7][0],
                addressStructured: [
                    {
                        lang: 'bg',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Германия',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'en',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 24103, Kiel, Eckernförder Str.',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Kiel',
                        route: 'Eckernförder Str.',
                        street_address: 'Eckernförder Straße 30',
                        postalCode: '24103'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [10.1227652, 54.3232927] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [10.1227652, 54.32508933056824],
                            [10.125433064257328, 54.32419101528412],
                            [10.125433064257328, 54.322394384715885],
                            [10.1227652, 54.32149606943177],
                            [10.120097335742672, 54.322394384715885],
                            [10.120097335742672, 54.32419101528412],
                            [10.1227652, 54.32508933056824]
                        ]
                    ]
                }
            },
            {
                id: '_fxb1zi0bl',
                type: 'waypoint',
                name: 'DE, Mníchov, Tal 50',
                rta: dates[8].times[1],
                rtd: dates[8].times[1],
                ata: null,
                atd: null,
                eta: dates[8].times[1],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: [
                    {
                        lang: 'bg',
                        address: '(DE) 80539, München, Thomas-Wimmer-Ring',
                        countryCode: 'DE',
                        country: 'Германия',
                        town: 'München',
                        route: 'Thomas-Wimmer-Ring',
                        street_address: 'Thomas-Wimmer-Ring 1A',
                        postalCode: '80539'
                    },
                    {
                        lang: 'en',
                        address: '(DE) 80331, Munich, Tal',
                        countryCode: 'DE',
                        country: 'Germany',
                        town: 'Munich',
                        route: 'Tal',
                        street_address: 'Tal 50',
                        postalCode: '80331'
                    },
                    {
                        lang: 'sk',
                        address: '(DE) 80331, Mníchov, Tal',
                        countryCode: 'DE',
                        country: 'Nemecko',
                        town: 'Mníchov',
                        route: 'Tal',
                        street_address: 'Tal 50',
                        postalCode: '80331'
                    }
                ],
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [],
                center: { type: 'Point', coordinates: [11.5819806, 48.1351253] },
                polygon: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [11.5819806, 48.136921930568235],
                            [11.584312010664908, 48.13602361528412],
                            [11.584312010664908, 48.13422698471588],
                            [11.5819806, 48.13332866943176],
                            [11.579649189335091, 48.13422698471588],
                            [11.579649189335091, 48.13602361528412],
                            [11.5819806, 48.136921930568235]
                        ]
                    ]
                }
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        },
        eventEvaluationMethod: 'strictOrder'
    },
    ...transportsNew,
    ...transportsCompleted
];

export const transports = data.map(e => TransportFromJSON(e));
