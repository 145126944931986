import { WithTranslation, withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import qa from 'qa-selectors';
import { DATE_FORMAT } from 'domain-constants';
import { searched } from 'common/utils/search';
import { DateRange } from 'common/model/date-time';
import { DatePicker } from 'common/components';
import { Select } from 'common/components/Form';
import TableBar from 'common/components/TableBar';
import { AutocompleteOpt } from 'modules/statistics/modules/border-crossing/StatisticsBorderCrossingModule';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import { ReactNode } from 'react';
import { Logic } from 'logic/logic';
import VehicleSelect, { VehicleSelectTypes } from 'modules/common/VehicleSelect';

interface Props extends WithTranslation {
    demoMode?: boolean;
    logic: Logic;
    filter?: ReactNode;
    loading: boolean;
    dateRange: DateRange;
    vehicles?: string[];
    drivers?: string[];
    driverOpts: AutocompleteOpt[];
    onFilterChange?: (filter: StatisticReportFilterModel) => void;
}

export interface StatisticReportFilterModel {
    vehicles?: string[];
    drivers?: string[];
    dateRange: DateRange;
}

function StatisticReportBar(props: Props) {
    function onDriversClear(): void {
        props.onFilterChange?.({
            vehicles: props.vehicles,
            drivers: [],
            dateRange: props.dateRange
        });
    }

    function onDriverOptionSelect(value: string): void {
        props.onFilterChange?.({
            vehicles: props.vehicles,
            drivers: props.drivers ? [...props.drivers, value] : [value],
            dateRange: props.dateRange
        });
    }

    function onDriverOptionDeselect(value: string): void {
        props.onFilterChange?.({
            vehicles: props.vehicles,
            drivers: props.drivers?.filter(c => c !== value),
            dateRange: props.dateRange
        });
    }

    function onVehicleClear(): void {
        props.onFilterChange?.({
            vehicles: [],
            drivers: props.drivers,
            dateRange: props.dateRange
        });
    }

    async function onVehicleOptionSelect(value: string) {
        if (value.indexOf('group_') > -1) {
            const groupId = Number(value.replace('group_', ''));
            await props.logic.statisticReportLogic().loadVehicleGroupVehicles(groupId);
            let list = props.logic.statisticReportLogic().getVehicleGroupVehicles(groupId);
            props.onFilterChange?.({
                vehicles: props.vehicles ? props.vehicles.concat(list) : list,
                drivers: props.drivers,
                dateRange: props.dateRange
            });
        } else {
            props.onFilterChange?.({
                vehicles: props.vehicles ? [...props.vehicles, value] : [value],
                drivers: props.drivers,
                dateRange: props.dateRange
            });
        }
    }

    async function onVehicleOptionDeselect(value: string) {
        if (value.indexOf('group_') > -1) {
            const groupId = Number(value.replace('group_', ''));
            await props.logic.statisticReportLogic().loadVehicleGroupVehicles(groupId);
            const list = props.logic.statisticReportLogic().getVehicleGroupVehicles(groupId);
            props.onFilterChange?.({
                vehicles: props.vehicles ? props.vehicles.filter(o => list.indexOf(o) === -1) : [],
                drivers: props.drivers,
                dateRange: props.dateRange
            });
        } else {
            props.onFilterChange?.({
                vehicles: props.vehicles ? props.vehicles.filter(o => o !== value) : [],
                drivers: props.drivers,
                dateRange: props.dateRange
            });
        }
    }

    function onDateRangeChange(values: [Moment | null, Moment | null] | null): void {
        if (values) {
            const [start, end] = values;

            props.onFilterChange?.({
                vehicles: props.vehicles,
                drivers: props.drivers,
                dateRange: {
                    start: start ? start.format(DATE_FORMAT) : props.dateRange.start,
                    end: end ? end.format(DATE_FORMAT) : props.dateRange.end
                }
            });
        }
    }

    return (
        <TableBar
            heading={props.t('StatisticReport.title')}
            filters={[
                <>{props.filter}</>,
                <>
                    <label>{props.t('common.period')}</label>
                    <DatePicker.DateTimeRangePicker
                        disabled={props.loading || props.demoMode}
                        className="extra-space-right"
                        clearIcon={false}
                        defaultValue={[
                            moment.utc(props.dateRange.start, DATE_FORMAT),
                            moment.utc(props.dateRange.end, DATE_FORMAT)
                        ]}
                        onChange={onDateRangeChange}
                        format={'L'}
                        loading={props.loading}
                        onShortcutConfirm
                        maxMonthRangePick={48}
                        shortcuts={{
                            [props.t('common.today')]: {
                                start: moment().startOf('day'),
                                end: moment().endOf('day')
                            },
                            [props.t('common.thisWeek')]: {
                                start: moment().startOf('week'),
                                end: moment().endOf('week')
                            },
                            [props.t('common.thisMonth')]: {
                                start: moment().startOf('month'),
                                end: moment().endOf('month')
                            },
                            [props.t('common.thisYear')]: {
                                start: moment().startOf('year'),
                                end: moment().endOf('year')
                            },
                            [props.t('common.yesterday')]: {
                                start: moment().subtract(1, 'days').startOf('day'),
                                end: moment().subtract(1, 'days').endOf('day')
                            },
                            [props.t('common.lastWeek')]: {
                                start: moment().subtract(1, 'week').startOf('week'),
                                end: moment().subtract(1, 'week').endOf('week')
                            },
                            [props.t('common.lastMonth')]: {
                                start: moment().subtract(1, 'month').startOf('month'),
                                end: moment().subtract(1, 'month').endOf('month')
                            },
                            [props.t('common.lastYear')]: {
                                start: moment().subtract(1, 'year').startOf('year'),
                                end: moment().subtract(1, 'year').endOf('year')
                            }
                        }}
                        trigger={
                            <ButtonDateRangeTimeTrigger
                                dateRange={props.dateRange}
                                qa={qa.common.dateTimeRangePicker.btn}
                            />
                        }
                    />
                </>,
                <>
                    <label>{`${props.t('common.vehicles')} / ${props.t('common.vehicleGroups')}`}</label>
                    <VehicleSelect
                        disabled={props.loading || props.demoMode}
                        types={[VehicleSelectTypes.vehicles, VehicleSelectTypes.vehicleGroups]}
                        showSearch
                        qa={qa.statisticReport.filter.inputVehicle}
                        size="large"
                        mode="multiple"
                        onSelect={onVehicleOptionSelect}
                        onDeselect={onVehicleOptionDeselect}
                        onClear={onVehicleClear}
                        filterOption={(input, option) => searched(input, String(option?.children))}
                        placeholder={props.t('common.typeRN')}
                        maxTagCount={'responsive'}
                    />
                </>,
                <>
                    <label>{props.t('common.drivers')}</label>
                    <Select
                        disabled={props.loading || props.demoMode}
                        showSearch
                        value={props.drivers}
                        qa={qa.statisticReport.filter.inputDriver}
                        size="large"
                        mode="multiple"
                        allowClear
                        onSelect={onDriverOptionSelect}
                        onDeselect={onDriverOptionDeselect}
                        onClear={onDriversClear}
                        filterOption={(input, option) => searched(input, String(option?.children))}
                        placeholder={props.t('common.typeDriversName')}
                        maxTagCount="responsive"
                    >
                        {props.driverOpts.map(driver => (
                            <Select.Option key={driver.code} value={driver.code}>
                                {driver.label}
                            </Select.Option>
                        ))}
                    </Select>
                </>
            ]}
        />
    );
}

export default withTranslation()(StatisticReportBar);
