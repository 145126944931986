import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { DriverBehaviorCoachModel } from 'common/model/statistics';
import numeral from 'numeral';
import { L } from 'domain-constants';
import { driver2, logoSumar } from 'resources/images/driver-behavior';
import { driver } from 'resources/images/driver-behavior';
import DriverBehaviorIconExclamation from '../DriverBehaviorCoachTable/DriverBehaviorIconExclamation';

interface Props {
    driversList?: DriverBehaviorCoachModel[];
    qa?: string;
}

export default function DriverBehaviorSumarTable(props: Props) {
    const { t } = useTranslation();
    const driverBehaviorTimesList = [
        'distance',
        'averageConsumption',
        'averageAscent',
        'averageWeight',
        'fuelConsumption',
        'departures'
    ];

    const units = {
        distance: t('common.km'),
        averageConsumption: `${L}/100 ${t('common.km')}`,
        averageAscent: `m/100 ${t('common.km')}`,
        averageWeight: t('common.metrics.tonnes'),
        fuelConsumption: t('FuelConsumptionTable.liters'),
        departures: `${t('common.count')}/100 ${t('common.km')}`
    };

    const summaryTableFormat = {
        distance: '0,0',
        averageConsumption: '0,0.0',
        averageAscent: '0,0',
        averageWeight: '0,0.0',
        fuelConsumption: '0,0.0',
        departures: '0,0'
    };

    return (
        <div
            className={cn('driver-behavior-times-table', {
                'driver-behavior-times-table-multi': (props.driversList?.length ?? 0) > 1
            })}
            data-qa={props.qa}
        >
            <table className="driver-behavior-times-table-table">
                {(props.driversList?.length ?? 0) > 1 && (
                    <>
                        <tr>
                            <td rowSpan={2}>
                                <img className="img-logo" src={logoSumar} alt="logo-sumar" />
                            </td>
                            <td>
                                <img className="img-driver" src={driver} alt="behavior-driver" />
                            </td>
                            <td>
                                <img className="img-driver" src={driver2} alt="behavior-driver" />
                            </td>
                        </tr>
                        <tr>
                            {props.driversList?.map((d, index) => (
                                <td
                                    key={`name_${index}`}
                                    className="driver-behavior-times-table-table-name"
                                    title={d.name}
                                >
                                    {d.name}
                                </td>
                            ))}
                        </tr>
                    </>
                )}
                {driverBehaviorTimesList.map((tl, index) => (
                    <tr key={index} data-field-name={tl}>
                        <td>
                            {`${t(`DriverBehavior.timesTable.${tl}`)} `}
                            <span className="driver-behavior-times-graph-unit">{`[${units[tl]}]`}</span>
                        </td>
                        {props.driversList?.map((d, index) => {
                            return (
                                <td key={`value_${index}`} className={'driver-behavior-times-graph-table-value'}>
                                    {d.timesTable[tl].value !== undefined ? (
                                        `${numeral(d.timesTable[tl].value).format(summaryTableFormat[tl])} `
                                    ) : (
                                        <span className="no-data-text">-</span>
                                    )}
                                    {d.timesTable?.[tl].exclamation && <DriverBehaviorIconExclamation />}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </table>
        </div>
    );
}
