import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Obu from './components/Obu';
import { ObuModel } from 'logic/partner/logic/partner-obu';
import { PartnerCompanySelectModel, PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { defaultInputs } from './components/ObuDetail';
import { PairingItemType, ObuTypeSelectModel } from 'logic/partner/partner';
import { ReadOnlyMonitoringDeviceType } from '../../../../generated/new-main';
import { PaginatedResponse, PaginationParams } from '../../../../common/model/pagination';
import { debounce } from 'debounce';
import { searched } from 'common/utils/search';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { message } from 'antd';
import { PairingItem, PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import { Role } from 'logic/auth';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    edit: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
    };
    table: {
        loading: boolean;
        data?: PaginatedResponse<ObuModel[]>;
        create?: ObuModel;
        selected?: ObuModel;
        delete?: ObuModel;
        clients?: PartnerCompanySelectModel[];
        obuTypeCounter?: ObuTypeSelectModel[];
    };
    createLoading: boolean;
    roles: Role[];
    userClient?: PartnerCompanyModel;
    pairing?: PartnerPairingModel;
    deviceTypes?: ReadOnlyMonitoringDeviceType[];
}

class PartnerObuModule extends Component<Props, State> {
    private _logic: Logic;
    items?: PairingItem[];
    private _externalSystem?: number;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const roles = this._logic.auth().roles();
        const userClient = this._logic.auth().newClient();
        this.state = {
            edit: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            },
            createLoading: false,
            roles,
            userClient
        };
    }

    componentDidMount() {
        const promiseCompanies = this.state.bar.companies
            ? this._logic.partner().companies().getClientList()
            : undefined;
        promiseCompanies?.then(resp => {
            const companies = resp.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));
            this.setState(state => ({ bar: { ...state.bar, companies } }));
        });

        Promise.all([
            this._logic.partner().obu().getObuList(this.state.bar.selectedCompany?.id),
            this._logic.partner().getObuTypeList(),
            this._logic.partner().companies().getClientList(this.state.bar.selectedCompany?.id),
            this._logic.partner().obu().getObuListTypes(),
            this._logic.partner().vehicles().getExternalSystems(),
            this._logic.auth().loadNewClient()
        ])
            .then(res => {
                const [data, obuTypeCounter, clients, deviceTypes, externalSystems] = res;

                this._externalSystem = externalSystems?.find(s => s.name === 'indirect_partner')?.id;
                const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));

                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: data,
                        obuTypeCounter,
                        clients: cl,
                        loading: false
                    },
                    deviceTypes
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    }

    render() {
        return (
            <Obu
                edit={this.state.edit}
                loading={this.state.table.loading}
                data={this.state.table.data}
                create={this.state.table.create}
                selected={this.state.table.selected}
                delete={this.state.table.delete}
                obuTypeCounter={this.state.table.obuTypeCounter}
                pairing={this.state.pairing}
                companies={this.state.bar.companies}
                selectedCompany={this.state.bar.selectedCompany}
                clients={this.state.table.clients}
                deviceTypes={this.state.deviceTypes}
                roles={this.state.roles}
                userClient={this.state.userClient}
                createLoading={this.state.createLoading}
                onTableRowSelect={this._onTableRowSelect}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onBarActionsCreate={this._onBarActionsCreate}
                onDetailFormCancel={this._onDetailFormCancel}
                onDetailFormSubmit={this._onDetailFormSubmit}
                onDetailActionsUnpair={this._onDetailActionsUnpair}
                onDeleteConfirm={this._onDeleteConfirm}
                onDeleteCancel={this._onDeleteCancel}
                onCreateSubmit={this._onCreateSubmit}
                onCreateCancel={this._onCreateCancel}
                onPairingKeySelect={this._onPairingKeySelect}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearch={this._onPairingSearch}
                onPairingConfirm={this._onPairingConfirm}
                onPairingCancel={this._onPairingCancel}
                onPaginationChange={this._onPaginationChange}
                onActionsPair={this._onActionsPair}
                onActionsUpdate={this._onActionsUpdate}
                onClientsFilterSearch={this._onClientsFilterSearch}
            />
        );
    }

    private _onClientsFilterSearch = async (text: string) => {
        const clients = await this._logic
            .partner()
            .companies()
            .getClientList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text);

        const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner })) ?? [];

        this.setState(state => ({
            table: {
                ...state.table,
                clients: cl
            }
        }));
    };

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onPaginationChange = async (pagination: PaginationParams) => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        try {
            const resp = await this._logic
                .partner()
                .obu()
                .getObuList(this.state.bar.selectedCompany?.id, pagination.limit, pagination.offset);
            this.setState(state => ({
                table: {
                    ...state.table,
                    data: resp
                }
            }));
        } catch (err) {
            console.error(`get obu list failed, err: ${err}`);
        }
        this.setState(state => ({
            table: {
                ...state.table,
                loading: false
            }
        }));
    };

    private _onPairingCancel = (): void => {
        this.items = undefined;
        this.setState({ pairing: undefined });
    };

    private _onPairingConfirm = (): void => {
        const selected = this.state.table?.selected;
        const key = this.state.pairing?.keySelected;
        const linked = this.state.pairing?.items!.find(c => c.id === this.state.pairing!.itemIdSelected);
        const permissions: string | undefined = linked?.permissions;

        if (selected && key && linked) {
            this.setState(state => ({ table: { ...state.table, loading: true } }));
            const source: PairingItemType = {
                id: key === PartnerPairingKey.tlmBundles ? selected.sn ?? '' : selected.id,
                key: PartnerPairingKey.obu
            };
            const target: PairingItemType = {
                id: linked?.id,
                key: key
            };

            this._logic
                .partner()
                .pair(source, target, permissions)
                .then(res => {
                    if (res === true) {
                        message.success(this.props.t('PartnerObu.message.pairSuccess'));
                        const links = (this.state.table!.selected!.links ?? []).concat([
                            {
                                id: target.id,
                                key: target.key,
                                rows: [
                                    {
                                        name: target.key === PartnerPairingKey.vehicles ? 'licensePlate' : 'pricing',
                                        value: linked.value
                                    }
                                ]
                            }
                        ]);
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data:
                                    target.key !== PartnerPairingKey.tlmBundles
                                        ? {
                                              ...state.table!.data!,
                                              data: state.table!.data!.data.map(d => ({
                                                  ...d,
                                                  links: d.id === source.id ? links : d.links
                                              }))
                                          }
                                        : state.table.data,
                                selected:
                                    target.key !== PartnerPairingKey.tlmBundles
                                        ? {
                                              ...state.table!.selected!,
                                              links
                                          }
                                        : state.table!.selected,
                                loading: false
                            },
                            pairing: undefined
                        }));
                        this._getObu(this.state.table.data?.limit, this.state.table.data?.offset);
                    } else {
                        message.error(this.props.t('PartnerObu.message.pairError'));
                    }
                })
                .catch(err => {
                    message.error(this.props.t('PartnerObu.message.pairError'));
                    console.error(`Pair error, err: ${err}`);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                });
        }
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                loading: true
            }
        }));
        switch (this.state.pairing?.keySelected) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(partners => {
                        this.items = partners.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.vehicles: {
                this._logic
                    .partner()
                    .vehicles()
                    .getVehicleList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, text ? text : undefined)
                    .then(vehicleList => {
                        this.items = vehicleList.data.map(p => ({
                            id: p.id,
                            value: p.licensePlate
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.tlmBundles: {
                this._logic
                    .partner()
                    .tlmBundles()
                    .getBundlesList()
                    .then(tlmBundleList => {
                        this.items = tlmBundleList.map(p => ({
                            id: p.id ?? '',
                            value: p.name,
                            permissions: p.binaryRights
                        }));
                        const itemIdSelected = this.items?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                search: text ? text : undefined,
                                itemIdSelected,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                const filtered = this.items?.filter(c => searched(text, c.value));
                const itemIdSelected = filtered?.find(c => c.id === this.state.pairing?.itemIdSelected)?.id;
                this.setState(state => ({
                    pairing: {
                        ...state.pairing!,
                        search: text ? text : undefined,
                        itemIdSelected,
                        items: filtered ?? undefined,
                        loading: false
                    }
                }));
            }
        }
    }, 500);

    private _onPairingItemSelect = (itemId: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                itemIdSelected: itemId
            }
        }));
    };

    private _onPairingKeySelect = (key: PartnerPairingKey): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing!,
                keySelected: key,
                items: undefined,
                loading: true
            }
        }));
        switch (key) {
            case PartnerPairingKey.companies: {
                this._logic
                    .partner()
                    .companies()
                    .getCompanyList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(companies => {
                        this.items = companies.data.map(p => ({
                            id: p.id,
                            value: p.name
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.tlmBundles: {
                this._logic
                    .partner()
                    .tlmBundles()
                    .getBundlesList()
                    .then(tlmBundleList => {
                        this.items = tlmBundleList.map(p => ({
                            id: p.id ?? '',
                            value: p.name,
                            permissions: p.binaryRights
                        }));
                        const filtered = this.state.pairing?.search
                            ? this.items!.filter(c => searched(this.state.pairing?.search, c.value))
                            : this.items;
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: filtered,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            case PartnerPairingKey.vehicles: {
                this._logic
                    .partner()
                    .vehicles()
                    .getVehicleList(undefined, DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, this.state.pairing?.search)
                    .then(vehicleList => {
                        this.items = vehicleList.data.map(p => ({
                            id: p.id,
                            value: p.licensePlate
                        }));
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                itemIdSelected: undefined,
                                items: this.items,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.error.loadDataError'));
                        console.error(`Load data error, err: ${err}`);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing!,
                                loading: false
                            }
                        }));
                    });
                break;
            }
            default: {
                break;
            }
        }
    };

    private _onBarActionsCreate = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                create: defaultInputs
            }
        }));
    };

    private _onActionsUpdate = (): void => {
        this.setState({
            edit: true
        });
    };

    private _onActionsPair = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                keys: [PartnerPairingKey.vehicles, PartnerPairingKey.tlmBundles]
            }
        }));
    };

    private _onDetailActionsUnpair = (linked: PairingItemType): void => {
        const selected = this.state.table?.selected;
        if (selected) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            const source: PairingItemType = {
                id: selected.id,
                key: PartnerPairingKey.obu
            };
            this._logic
                .partner()
                .unpair(source, linked)
                .then(res => {
                    if (res) {
                        message.success(this.props.t('PartnerObu.message.unpairSuccess'));
                        this.setState(
                            state => ({
                                table: {
                                    ...state.table,
                                    selected: state.table.selected
                                        ? {
                                              ...state.table.selected,
                                              links:
                                                  state.table.selected?.links && state.table.selected?.links.length > 1
                                                      ? state.table.selected?.links?.filter(l => l.id !== linked.id)
                                                      : undefined
                                          }
                                        : undefined
                                }
                            }),
                            () => this._getObu()
                        );
                    } else {
                        message.error(this.props.t('PartnerObu.message.unpairError'));
                    }
                })
                .catch(err => {
                    message.error(this.props.t('PartnerObu.message.unpairError'));
                    console.error(`Unpair error, err: ${err}`);
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                });
        }
    };

    private _onDetailFormCancel = (): void => {
        this.setState({
            edit: false
        });
    };

    private _onDetailFormSubmit = (model: ObuModel): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this._logic
            .partner()
            .obu()
            .editObu(model, this._externalSystem!)
            .then(_res => {
                message.success(this.props.t('PartnerObu.message.updateSuccess'));
                this.setState(state => ({
                    table: {
                        ...state.table,
                        selected: undefined
                    }
                }));
                this._getObu();
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Edit error, err: ${err}`);
                if (err.status === 409) {
                    message.error(this.props.t('PartnerObu.message.updateErrorMasterdata'));
                } else {
                    message.error(this.props.t('PartnerObu.message.updateError'));
                }
            });
    };

    private _getObu = (limit?: number, offset?: number): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));
        this._logic
            .partner()
            .obu()
            .getObuList(
                this.state.bar.selectedCompany?.id,
                limit ?? this.state.table.data?.limit,
                offset ?? this.state.table.data?.offset
            )
            .then(resp => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: resp,
                        loading: false
                    }
                }));
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _onDeleteConfirm = (): void => {
        if (this.state.table?.delete) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            this.props.logic
                .partner()
                .obu()
                .deleteObu(this.state.table?.delete)
                .then(deleted => {
                    if (deleted) {
                        message.success(this.props.t('PartnerObu.message.removeSuccess'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                delete: undefined
                            }
                        }));
                        this._getObu();
                    } else {
                        message.error(this.props.t('PartnerObu.message.removeError'));
                    }
                })
                .catch(err => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                    console.error(`Delete error, err: ${err}`);
                    message.error(this.props.t('PartnerObu.message.removeError'));
                });
        }
    };

    private _onDeleteCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, delete: undefined }
        }));
    };

    private _onCreateSubmit = (model: ObuModel): void => {
        this.setState({ createLoading: true });
        this._logic
            .partner()
            .obu()
            .addObu(model, this._externalSystem!)
            .then(_res => {
                message.success(this.props.t('PartnerObu.message.createSuccess'));
                this.setState(state => ({
                    table: { ...state.table, loading: true, create: undefined },
                    createLoading: false
                }));
                this._getObu();
            })
            .catch(err => {
                this.setState({ createLoading: false });
                console.error(`Create error, err: ${err}`);
                message.error(this.props.t('PartnerObu.message.createError'));
            });
    };

    private _onCreateCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, create: undefined }
        }));
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                },
                table: {
                    loading: true,
                    clients: state.table?.clients,
                    obuTypeCounter: state.table?.obuTypeCounter,
                    data: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getObu();
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    loading: true,
                    clients: state.table?.clients,
                    obuTypeCounter: state.table?.obuTypeCounter,
                    data: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
                this._getObu();
                if (this.state.bar.selectedCompany?.label) {
                    this._onClientsFilterSearch(this.state.bar.selectedCompany?.label);
                }
            }
        );
    };

    private _onTableRowSelect = (id: string): void => {
        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                selected: state.table?.selected?.id === id ? undefined : state.table?.data?.data.find(d => d.id === id)
            }
        }));
    };
}

export default withTranslation()(withRouter(PartnerObuModule));
