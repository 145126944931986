import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, Col, Row } from 'antd';
import { InputField, SelectField } from 'common/fields';

import { Tooltip } from 'common/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EmissionClass, FleetProfileMd, FleetType, TunnelType } from 'modules/management/modules/fleet/FleetModule';
import numeral from 'numeral';
import { M, T } from 'domain-constants';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';
import {
    MonitoredObjectFeFleetTemperatureSensor,
    MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum
} from 'generated/new-main/models';
import { EFuelType } from 'generated/graphql';
import TemperatureZonesEditor from 'modules/management/modules/fleet/components/TemperatureZonesEditor';
import { Role } from 'logic/auth';
import { useState } from 'react';

export const OPTIMAL_RPM_MIN_VALUE = 500;
export const OPTIMAL_RPM_MAX_VALUE = 1600;

export interface VehicleFormModel {
    id?: number;
    name?: string;
    vin?: string;
    tunnelType?: TunnelType;
    emissionClass?: EmissionClass;
    numberOfAxles?: number;

    fuelType?: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum;
    tankVolume?: number;
    optimalRpm?: number;

    height?: number;
    length?: number;
    width?: number;
    weight?: number;
    weightFull?: number;
    temperatureSensors?: MonitoredObjectFeFleetTemperatureSensor[];
}

interface Props extends WithTranslation {
    vehicleType: FleetType;
    initialValues?: Partial<VehicleFormModel>;
    masterData?: FleetProfileMd;
    mode: 'EDIT' | 'CREATE';
    roles: Role[];
    onSubmit?: (values: VehicleFormModel) => void;
    onCancel?: () => void;
}

function VehicleForm(props: Props) {
    let form: FormikProps<VehicleFormModel>;
    const [zones, setZones] = useState<number[]>(
        Array.from(
            {
                length: Math.max(
                    ...(props.initialValues?.temperatureSensors?.map(sensor => sensor.sensorZone ?? 0) ?? [])
                )
            },
            (_: number, i: number) => i + 1
        )
    );

    const schema = Yup.object().shape({
        name: Yup.string().trim().strict(true),
        vin: props.masterData?.vin
            ? Yup.string().max(30, props.t('validator.maxLength', { max: 30 }))
            : Yup.string()
                  .trim()
                  .strict(true)
                  .max(30, props.t('validator.maxLength', { max: 30 })),
        numberOfAxles: props.masterData?.numberOfAxles
            ? Yup.number()
            : Yup.number()
                  .nullable()
                  .required(props.t('common.required'))
                  .max(10, props.t('validator.maxValue', { max: 10 }))
                  .min(1, props.t('validator.minValue', { min: 1 })),
        emissionClass: props.masterData?.emissionClass
            ? Yup.mixed<EmissionClass>()
            : props.vehicleType === FleetType.VEHICLE
            ? Yup.mixed<EmissionClass>().oneOf(Object.values(EmissionClass)).required(props.t('common.required'))
            : Yup.mixed<EmissionClass>(),
        tunnelType: props.masterData?.tunnel
            ? Yup.number().nullable()
            : Yup.mixed<TunnelType>().oneOf(Object.values(TunnelType)),
        fuelType: props.masterData?.fuelType
            ? Yup.mixed<MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum>()
            : Yup.mixed<MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum>()
                  .oneOf(Object.values(MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum))
                  .required(props.t('common.required')),
        tankVolume: Yup.number()
            .nullable()
            .when('fuelType', {
                is: (fuelType: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum) => {
                    return fuelType === MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.ELECTRO;
                },
                then: Yup.number().nullable(),
                otherwise: Yup.number()
                    .nullable()
                    .max(2000, props.t('validator.maxValue', { max: 2000 }))
                    .min(1, props.t('validator.minValue', { min: 1 }))
            }),
        height: props.masterData?.height
            ? Yup.number().nullable()
            : Yup.number()
                  .nullable()
                  .required(props.t('common.required'))
                  .max(50, props.t('validator.maxValue', { max: 50 }))
                  .min(1, props.t('validator.minValue', { min: 1 })),
        length: props.masterData?.length
            ? Yup.number().nullable()
            : Yup.number()
                  .nullable()
                  .required(props.t('common.required'))
                  .max(20, props.t('validator.maxValue', { max: 20 }))
                  .min(1, props.t('validator.minValue', { min: 1 })),
        weight: props.masterData?.weight
            ? Yup.number().nullable()
            : Yup.number()
                  .nullable()
                  .max(44, props.t('validator.maxValue', { max: 44 }))
                  .min(1, props.t('validator.minValue', { min: 1 })),
        width: props.masterData?.width
            ? Yup.number().nullable()
            : Yup.number()
                  .nullable()
                  .required(props.t('common.required'))
                  .max(5, props.t('validator.maxValue', { max: 5 }))
                  .min(1, props.t('validator.minValue', { min: 1 })),
        optimalRpm: Yup.number().nullable().required(props.t('common.required'))
    });

    function onSensorDragEnd(sensorId: string, targetZone: number) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.id === sensorId
                ? {
                      ...sensor,
                      sensorZone: targetZone
                  }
                : sensor
        );
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function onZoneDeleteClick(zone: number) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.sensorZone && sensor.sensorZone >= zone
                ? {
                      ...sensor,
                      sensorZone: sensor.sensorZone - 1
                  }
                : sensor
        );
        setZones(zones.slice(0, zones.length - 1));
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function onZoneAddClick() {
        setZones(zones.concat(zones.length + 1));
    }

    function onSensorNameChange(sensorId: string, sensorName: string) {
        const sensorsNew = form.values.temperatureSensors?.map(sensor =>
            sensor.id === sensorId
                ? {
                      ...sensor,
                      sensorName
                  }
                : sensor
        );
        form.setFieldValue('temperatureSensors', sensorsNew);
    }

    function handleSubmit(values: VehicleFormModel) {
        props.onSubmit?.(values);
    }

    return (
        <Formik<VehicleFormModel>
            initialValues={{
                id: props.initialValues?.id,
                name: props.initialValues?.name,
                vin: props.initialValues?.vin,
                tunnelType: props.initialValues?.tunnelType,
                emissionClass: props.initialValues?.emissionClass,
                fuelType: props.initialValues?.fuelType,
                numberOfAxles: props.initialValues?.numberOfAxles,
                tankVolume: props.initialValues?.tankVolume,
                height: props.initialValues?.height,
                length: props.initialValues?.length,
                weight: props.initialValues?.weight,
                weightFull: props.initialValues?.weightFull,
                width: props.initialValues?.width,
                optimalRpm: props.initialValues?.optimalRpm,
                temperatureSensors: props.initialValues?.temperatureSensors
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<VehicleFormModel>) => {
                form = formik;

                return (
                    <Form className="vehicle-form">
                        <Row gutter={[20, 10]}>
                            <Col span={props.mode === 'EDIT' ? 24 : 12}>
                                <h2>{props.t('ManagementFleet.registration')}</h2>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('common.ownName')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        <InputField
                                            data-qa={qa.fleet.detail.inputName}
                                            name="name"
                                            placeholder={props.t('common.ownName')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('ManagementFleet.vin')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.vin ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldVin}>
                                                    {props.initialValues?.vin}
                                                </span>
                                            </>
                                        ) : (
                                            <InputField
                                                name="vin"
                                                placeholder={props.t('ManagementFleet.vin')}
                                                maxLength={30}
                                                qa={qa.fleet.detail.inputVin}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('ManagementFleet.tunnel')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.tunnel ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldTunnel}>
                                                    {props.initialValues?.tunnelType}
                                                </span>
                                            </>
                                        ) : (
                                            <SelectField
                                                name="tunnelType"
                                                options={Object.values(TunnelType).map(tunnelType => {
                                                    return {
                                                        value: tunnelType,
                                                        label: props.t(
                                                            `VehicleProfileForm.tunnelOptions.${tunnelType.toLowerCase()}`
                                                        )
                                                    };
                                                })}
                                                qa={qa.fleet.detail.selectTunnelType}
                                            />
                                        )}
                                    </Col>
                                    {formik.values.tunnelType && (
                                        <Col span={24}>
                                            <Alert
                                                message={props.t(
                                                    `VehicleProfileForm.tunnelOptions.${String(
                                                        formik.values.tunnelType
                                                    ).toLowerCase()}`
                                                )}
                                                description={props.t(
                                                    `VehicleProfileForm.tunnelOptionsDesc.${String(
                                                        formik.values.tunnelType
                                                    ).toLowerCase()}`
                                                )}
                                                type="info"
                                            />
                                        </Col>
                                    )}
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('common.ems')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.emissionClass ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldEmissionClass}>
                                                    {props.initialValues?.emissionClass}
                                                </span>
                                            </>
                                        ) : (
                                            <SelectField
                                                name="emissionClass"
                                                options={Object.values(EmissionClass).map(emissionClass => {
                                                    return {
                                                        value: emissionClass,
                                                        label: String(EmissionClass[emissionClass])
                                                    };
                                                })}
                                                qa={qa.fleet.detail.selectEmisionClass}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{`${props.t('ManagementFleet.axlesCount')}`}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.numberOfAxles ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldNumberOfAxles}>
                                                    {numeral(props.initialValues?.numberOfAxles).format('0,0')}
                                                </span>
                                            </>
                                        ) : (
                                            <InputField.Number
                                                name="numberOfAxles"
                                                qa={qa.fleet.detail.inputAxlesCount}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('ManagementFleet.fuelType')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.fuelType ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldFuelType}>
                                                    {props.initialValues?.fuelType
                                                        ? props.t(
                                                              `VehicleProfileForm.fuelTypeOptions.${props.initialValues?.fuelType.toLowerCase()}`
                                                          )
                                                        : ''}
                                                </span>
                                            </>
                                        ) : (
                                            <SelectField
                                                name="fuelType"
                                                options={Object.values(EFuelType)
                                                    .filter(f =>
                                                        [
                                                            EFuelType.Diesel,
                                                            EFuelType.Gasoline,
                                                            EFuelType.Electro,
                                                            EFuelType.Hybrid,
                                                            EFuelType.LngCng
                                                        ].includes(f)
                                                    )
                                                    .map(fuelType => {
                                                        return {
                                                            value: fuelType,
                                                            label: props.t(
                                                                `VehicleProfileForm.fuelTypeOptions.${fuelType.toLowerCase()}`
                                                            )
                                                        };
                                                    })}
                                                qa={qa.fleet.detail.selectFuelType}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                {formik.values.fuelType !==
                                    MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum.ELECTRO && (
                                    <Row className="form-row" gutter={[20, 10]}>
                                        <Col xl={24} xxl={10}>
                                            <label>{props.t('ManagementFleet.tankVolume')}</label>
                                        </Col>
                                        <Col xl={24} xxl={14}>
                                            <InputField.Number
                                                name="tankVolume"
                                                placeholder={props.t('ManagementFleet.tankVolume')}
                                                qa={qa.fleet.detail.inputTank}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('ManagementFleet.optimalRpm')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        <InputField.Number
                                            precision={0}
                                            min={OPTIMAL_RPM_MIN_VALUE}
                                            max={OPTIMAL_RPM_MAX_VALUE}
                                            name="optimalRpm"
                                            placeholder={props.t('ManagementFleet.optimalRpm')}
                                            qa={qa.fleet.detail.inputOptimalRpm}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={props.mode === 'EDIT' ? 24 : 12}>
                                <h2>{props.t('ManagementFleet.dimensions')}</h2>

                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{`${props.t('common.height')} [${props.t('common.metrics.m')}]`}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.height ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldHeight}>
                                                    {numeral(props.initialValues?.height).format('0,0.00')}
                                                </span>{' '}
                                                {M}
                                            </>
                                        ) : (
                                            <InputField.Number name="height" qa={qa.fleet.detail.inputHeight} />
                                        )}
                                    </Col>
                                </Row>

                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{`${props.t('common.length')} [${props.t('common.metrics.m')}]`}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.length ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldLength}>
                                                    {numeral(props.initialValues?.length).format('0,0.00')}
                                                </span>{' '}
                                                {M}
                                            </>
                                        ) : (
                                            <InputField.Number name="length" qa={qa.fleet.detail.inputLength} />
                                        )}
                                    </Col>
                                </Row>

                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>{`${props.t('common.width')} [${props.t('common.metrics.m')}]`}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.width ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldWidth}>
                                                    {numeral(props.initialValues?.width).format('0,0.00')}
                                                </span>{' '}
                                                {M}
                                            </>
                                        ) : (
                                            <InputField.Number name="width" qa={qa.fleet.detail.inputWidth} />
                                        )}
                                    </Col>
                                </Row>

                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>
                                            {' '}
                                            {`${props.t('common.weight')} [${props.t('common.metrics.tons')}]`}
                                        </label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        {props.masterData?.weight ? (
                                            <>
                                                <Tooltip
                                                    placement="top"
                                                    title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                                >
                                                    <i className="fas fa-info-circle" />{' '}
                                                </Tooltip>
                                                <span data-qa={qa.fleet.detail.fieldWeight}>
                                                    {numeral(props.initialValues?.weight).format('0,0.00')}
                                                </span>{' '}
                                                {T}
                                            </>
                                        ) : (
                                            <InputField.Number name="weight" qa={qa.fleet.detail.inputWeight} />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col xl={24} xxl={10}>
                                        <label>
                                            {' '}
                                            {`${props.t('ManagementFleet.fullLoadWeight')} [${props.t(
                                                'common.metrics.tons'
                                            )}]`}
                                        </label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        <>
                                            <Tooltip
                                                placement="top"
                                                title={props.t('ManagementFleet.dataSetInEWClientPortal')}
                                            >
                                                <i className="fas fa-info-circle" />{' '}
                                            </Tooltip>
                                            <span data-qa={qa.fleet.detail.fieldFullWeight}>
                                                {numeral(props.initialValues?.weightFull).format('0,0.00')}
                                            </span>{' '}
                                            {T}
                                        </>
                                    </Col>
                                </Row>
                            </Col>
                            {props.roles.includes(Role.CLD_W) &&
                                props.mode === 'EDIT' &&
                                !!form.values.temperatureSensors?.length && (
                                    <Col span={props.mode === 'EDIT' ? 24 : 12}>
                                        <TemperatureZonesEditor
                                            sensors={form.values.temperatureSensors}
                                            zones={zones}
                                            onZoneDeleteClick={onZoneDeleteClick}
                                            onZoneAddClick={onZoneAddClick}
                                            onSensorDragEnd={onSensorDragEnd}
                                            onSensorNameChange={onSensorNameChange}
                                        />
                                    </Col>
                                )}
                        </Row>
                        <FormActions
                            cancelQa={qa.fleet.form.btnCancel}
                            submitQa={qa.fleet.form.btnSubmit}
                            submitLoading={formik.isSubmitting}
                            onCancelClick={props.onCancel}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(VehicleForm);
