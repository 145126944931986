import { Subject } from 'rxjs';
import { Logic } from 'logic/logic';
import { ReadOnlyDiemsRates } from 'generated/new-main/models';

export class DiemsRates {
    private _logic: Logic;
    diemsImportLoading = new Subject<boolean>();
    diemsImportSuccess = new Subject<void>();
    diemsImportErrors = new Subject<any>();

    diemsListLoading = new Subject<boolean>();
    diemsListData = new Subject<ReadOnlyDiemsRates[]>();
    diemsListError = new Subject<any>();

    constructor(logic: Logic) {
        this._logic = logic;
    }

    async importDiemsRates(file: Blob) {
        this.diemsImportLoading.next(true);
        try {
            await this._logic.api().diemsRateApi.diemsRatesImportExcel({
                fileUploaded: file
            });

            this.diemsImportSuccess.next();
        } catch (err: any) {
            if (err.body) {
                const data = await err.json();
                this.diemsImportErrors.next(data);
            } else {
                this.diemsImportErrors.next(err);
            }
        } finally {
            this.diemsImportLoading.next(false);
        }
    }

    async getDiemsRates(startDate: string, endDate: string) {
        this.diemsListLoading.next(true);
        try {
            const data = await this._logic.api().diemsRateApi.diemsRatesList({
                startLte: startDate,
                endGte: endDate
            });

            this.diemsListData.next(data);
        } catch (err) {
            this.diemsListError.next(err);
        } finally {
            this.diemsListLoading.next(false);
        }
    }

    async editDiemsRates() {
        // try {
        //     await this._logic.api().diemsRateApi.diemsRatesUpdate({
        //         data: {
        //         }
        //     })
        // } catch(err) {
        // }
    }
}
