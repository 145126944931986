/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiemsRulesPLFixedRatesReq,
    DiemsRulesPLFixedRatesReqFromJSON,
    DiemsRulesPLFixedRatesReqFromJSONTyped,
    DiemsRulesPLFixedRatesReqToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiemsRulesCreateMany
 */
export interface DiemsRulesCreateMany {
    /**
     * 
     * @type {Date}
     * @memberof DiemsRulesCreateMany
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiemsRulesCreateMany
     */
    end?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiemsRulesCreateMany
     */
    replacedMeals?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof DiemsRulesCreateMany
     */
    pocketMoney?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiemsRulesCreateMany
     */
    users: Array<number>;
    /**
     * 
     * @type {DiemsRulesPLFixedRatesReq}
     * @memberof DiemsRulesCreateMany
     */
    plFixedRates?: DiemsRulesPLFixedRatesReq | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiemsRulesCreateMany
     */
    concatForeignDiems?: boolean;
}

export function DiemsRulesCreateManyFromJSON(json: any): DiemsRulesCreateMany {
    return DiemsRulesCreateManyFromJSONTyped(json, false);
}

export function DiemsRulesCreateManyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiemsRulesCreateMany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (json['end'] === null ? null : new Date(json['end'])),
        'replacedMeals': !exists(json, 'replaced_meals') ? undefined : json['replaced_meals'],
        'pocketMoney': !exists(json, 'pocket_money') ? undefined : json['pocket_money'],
        'users': json['users'],
        'plFixedRates': !exists(json, 'pl_fixed_rates') ? undefined : DiemsRulesPLFixedRatesReqFromJSON(json['pl_fixed_rates']),
        'concatForeignDiems': !exists(json, 'concat_foreign_diems') ? undefined : json['concat_foreign_diems'],
    };
}

export function DiemsRulesCreateManyToJSON(value?: DiemsRulesCreateMany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end === null ? null : value.end.toISOString()),
        'replaced_meals': value.replacedMeals,
        'pocket_money': value.pocketMoney,
        'users': value.users,
        'pl_fixed_rates': DiemsRulesPLFixedRatesReqToJSON(value.plFixedRates),
        'concat_foreign_diems': value.concatForeignDiems,
    };
}


