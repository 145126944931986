import { ChangeEvent, Component, FormEvent } from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';
import { StringValidator, FormValidator, NumeralValidator, NumberValidator } from 'validators';
import { ReadOnlyMonitoredObjectType } from 'generated/new-main';
import { EFuelType } from 'generated/graphql';
import FormActions from 'common/components/FormActions';

export interface VehiclesForm {
    id: string;
    licensePlate: string;
    licensePlateErr: string;
    odometer: string;
    odometerErr: string;
    brand?: string;
    typeId?: number;
    fuelTank?: number;
    fuelTankErr: string;
    cngTank?: string;
    cngTankErr?: string;
    company?: string;
    companyErr?: string;
    disabledAt?: Date;
}

export const defaultValidationErrors = {
    companyErr: '',
    licensePlateErr: '',
    odometerErr: '',
    fuelTankErr: '',
    cngTankErr: ''
};

export const defaultInputs: PartnerVehicleModel = {
    id: '',
    licensePlate: '',
    odometer: 0,
    brand: '',
    modules: []
};

export interface VehiclesFormValidator {
    company: string;
    licensePlate: string;
    fuelTank: string;
}

export const companyValidator = new StringValidator({
    required: true
});

export const licensePlateValidator = new StringValidator({
    required: true
});

export const odometerValidator = new NumeralValidator({
    format: '0',
    required: false,
    min: 0
});

export const fuelTankValidator = new NumberValidator({
    min: 1,
    max: 9999
});

export const formValidator = new FormValidator<VehiclesFormValidator>()
    .addValidator('company', companyValidator)
    .addValidator('licensePlate', licensePlateValidator)
    .addValidator('fuelTank', fuelTankValidator);

interface Props extends WithTranslation {
    editLoading?: boolean;
    model: PartnerVehicleModel;
    vehicleTypes?: ReadOnlyMonitoredObjectType[];
    onFormCancel?: () => void;
    onFormSubmit?: (model: PartnerVehicleModel) => void;
}

interface State {
    form?: VehiclesForm;
}

class VehiclesDetail extends Component<Props, State> {
    VehicleModel?: PartnerVehicleModel;

    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: this.props.model.id,
                licensePlate: this.props.model.licensePlate,
                odometer: String(this.props.model.odometer),
                brand: this.props.model.brand,
                typeId: this.props.model.typeId,
                fuelTank: this.props.model.fuelTank,
                cngTank: String(this.props.model.cngTank ?? '')
            }
        };
        if (this.props.model?.fuelType === EFuelType.Electro) {
            formValidator.updateValidator(
                'fuelTank',
                new NumberValidator({
                    min: 1,
                    max: 9999,
                    required: false
                })
            );
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.model !== this.props.model) {
            this.VehicleModel = undefined;
            this.setState({ form: undefined });
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="partner-sidepanel">
                <div className="partner-detail">
                    <form className="t-small editable" onSubmit={this._onFormSubmit}>
                        <div className="t-bar-block">
                            <div className="t-row">
                                <div className="t-half t-bold" title={t('PartnerVehicles.licensePlate')}>
                                    {t('PartnerVehicles.licensePlate')}
                                </div>
                                <div className="t-half">
                                    <input
                                        className={cn('t-input t-padding-small editable', {
                                            error: this.state.form?.licensePlateErr,
                                            success:
                                                !this.state.form?.licensePlateErr && !!this.state.form?.licensePlateErr
                                        })}
                                        value={
                                            this.state.form
                                                ? this.state.form?.licensePlate
                                                : this.props.model?.licensePlate
                                        }
                                        onChange={this._onLicensePlateChange}
                                    />
                                    {this.state.form?.licensePlateErr && (
                                        <span className="t-bold t-text-danger">
                                            {t('validator.' + this.state.form?.licensePlateErr)}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="t-row">
                                <div className="t-half t-bold" title={t('Partner.brand')}>
                                    {t('Partner.brand')}
                                </div>
                                <div className="t-half">
                                    <input
                                        className="t-input t-padding-small editable"
                                        value={this.state.form ? this.state.form?.brand : this.props.model?.brand}
                                        onChange={this._onBrandChange}
                                    />
                                </div>
                            </div>

                            <div className="t-row">
                                <div className="t-half t-bold" title={t('common.type')}>
                                    {t('common.type')}
                                </div>
                                <div className="t-half">
                                    <select
                                        className="t-input t-default editable"
                                        value={this.state.form?.typeId}
                                        onChange={this._onTypeChange}
                                    >
                                        <option />
                                        {(this.props.vehicleTypes ?? []).map(e => (
                                            <option className="t-inverse" key={e.id} value={e.id}>
                                                {t(`Partner.${String(e.name)}`)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {this.props.model?.fuelType !== EFuelType.Electro && (
                                <div className="t-row">
                                    <div className="t-half t-bold" title={t('PartnerVehicles.fuelTank')}>
                                        {t('PartnerVehicles.fuelTank')}
                                    </div>
                                    <div className="t-half">
                                        <input
                                            className={cn('t-input t-padding-small editable', {
                                                error: this.state.form?.fuelTankErr,
                                                success: !this.state.form?.fuelTankErr && !!this.state.form?.fuelTankErr
                                            })}
                                            value={
                                                this.state.form
                                                    ? this.state.form?.fuelTank
                                                    : this.props.model?.fuelTank ?? ''
                                            }
                                            onChange={this._onFuelTankChange}
                                        />
                                        {this.state.form?.fuelTankErr && (
                                            <span className="t-bold t-text-danger">
                                                {t('validator.' + this.state.form?.fuelTankErr)}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <FormActions submitLoading={this.props.editLoading} onCancelClick={this.props.onFormCancel} />
                    </form>
                </div>
            </div>
        );
    }

    private _onLicensePlateChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = licensePlateValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form!,
                licensePlate: value,
                licensePlateErr: validateRes.err as string
            }
        }));
    };

    private _onBrandChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        this.setState(state => ({
            form: {
                ...state.form!,
                brand: value
            }
        }));
    };

    private _onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        this.setState(state => ({
            form: {
                ...state.form!,
                typeId: Number(value)
            }
        }));
    };

    private _onFuelTankChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = fuelTankValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form!,
                fuelTank: Number(value),
                fuelTankErr: validateRes.err as string
            }
        }));
    };

    private _onFormSubmit = (e: FormEvent): void => {
        e.preventDefault();

        if (this.state.form) {
            const validationRes = formValidator.validate({
                company: this.state.form.company ?? '123', // RANDOM number dont wana validate it :D
                licensePlate: this.state.form.licensePlate,
                fuelTank: String(this.state.form.fuelTank)
            });
            if (validationRes.valid) {
                this.props.onFormSubmit?.({
                    ...this.props.model,
                    licensePlate: this.state.form.licensePlate,
                    odometer: Number(this.state.form.odometer),
                    fuelTank: Number(this.state.form.fuelTank),
                    brand: this.state.form.brand,
                    typeId: this.state.form.typeId
                });
            } else {
                this.setState(state => ({
                    form: {
                        ...state.form!,
                        licensePlateErr: validationRes.err?.licensePlate ?? '',
                        fuelTankErr: validationRes.err?.fuelTank ?? ''
                    }
                }));
            }
        }
    };
}

export default withTranslation()(VehiclesDetail);
