import React from 'react';
import { RouteNames } from 'App';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import { NavLink } from 'react-router-dom';
import qa from 'qa-selectors';
import * as sidebarIcons from 'resources/images/sidebar';
interface Props extends WithTranslation {
    roles: Role[];
}

function ManagementSideBar(props: Props) {
    const { t, roles } = props;

    return (
        <div className="management-sidebar sidebar">
            {[Role.V_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_FLEET}
                    activeClassName="sidebar-item-active"
                    title={t('common.fleet')}
                    data-qa={qa.sidebar.settings.btnFleet}
                >
                    <img src={sidebarIcons.fleet} alt="fleet" />
                </NavLink>
            )}
            {[Role.USR_W].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_ROLES}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.roles')}
                    data-qa={qa.sidebar.settings.btnRles}
                >
                    <img src={sidebarIcons.roles} alt="roles" />
                </NavLink>
            )}
            {[Role.USR_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_USERS}
                    activeClassName="sidebar-item-active"
                    title={t('common.user')}
                    data-qa={qa.sidebar.settings.btnUsers}
                >
                    <img src={sidebarIcons.users} alt="users" />
                </NavLink>
            )}
            {[Role.EX_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_FUEL_CARDS}
                    activeClassName="sidebar-item-active"
                    title={t('common.fuelCards')}
                    data-qa={qa.sidebar.settings.btnFuelCards}
                >
                    <img src={sidebarIcons.fuelCards} alt="fuel-cards" />
                </NavLink>
            )}
            {[Role.TC_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_TACHO_CARDS}
                    activeClassName="sidebar-item-active"
                    title={t('common.tachoCards')}
                    data-qa={qa.sidebar.settings.btnTachographCards}
                >
                    <img src={sidebarIcons.tachographCards} alt="tachograph-cards" />
                </NavLink>
            )}
            {[Role.CRD_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.driverCardsRemoteMemory')}
                    data-qa={qa.sidebar.settings.btnDriverCardsRemoteMemory}
                >
                    <img src={sidebarIcons.cardsMemory} alt="cards-memory" />
                </NavLink>
            )}
            {[Role.TRD_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.vehiclesRemoteMemory')}
                    data-qa={qa.sidebar.settings.btnVehiclesRemoteMemory}
                >
                    <img src={sidebarIcons.vehicleMemory} alt="vehicles-memory" />
                </NavLink>
            )}
            {[Role.POI_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_CUSTOM_PLACES}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.poi')}
                    data-qa={qa.sidebar.settings.btnCustomPlaces}
                >
                    <img src={sidebarIcons.pois} alt="poi" />
                </NavLink>
            )}
            {[Role.CA_R, Role.CAC_R, Role.OAC_R, Role.PUESC].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_SYSTEM_CONNECTIONS}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.systemConnections')}
                    data-qa={qa.sidebar.settings.btnSystemConnections}
                >
                    <img src={sidebarIcons.systemConnections} alt="system-connections" />
                </NavLink>
            )}
            {[Role.CL_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.SETTINGS_CONTACT_LIST}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.contacts')}
                    data-qa={qa.sidebar.settings.btnContactList}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <img
                        style={{
                            maxHeight: 36,
                            maxWidth: 36,
                            height: '100%',
                            width: '100%'
                        }}
                        src={sidebarIcons.contactList}
                        alt="contact-list"
                    />
                </NavLink>
            )}
        </div>
    );
}

export default withTranslation()(ManagementSideBar);
