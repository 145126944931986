import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Button } from 'common/components';
import * as MaintenanceIcons from 'resources/images/maintenance';

interface Props extends WithTranslation {
    onCreateNewClick?: () => void;
}

function MaintenanceIntro(props: Props) {
    return (
        <Row justify="center">
            <Col flex="725px">
                <div className="maintenance-intro">
                    <div>
                        <img src={MaintenanceIcons.introFleet} alt="fleet" />
                    </div>
                    <Row justify="center">
                        <Col>
                            <div className="maintenance-intro-maintenance-icon">
                                <img src={MaintenanceIcons.maintenanceIcon} alt="maintenance" />
                            </div>
                        </Col>
                    </Row>
                    <div className="maintenance-intro-heading">{props.t('Maintenance.task.introTitle')}</div>
                    <div className="maintenance-intro-description">{props.t('Maintenance.task.introDescription')}</div>
                    <Row className="maintenance-intro-actions" justify="center">
                        <Col>
                            <Button type="primary" onClick={props.onCreateNewClick} block={false}>
                                {props.t('Maintenance.task.createNewMaintenanceTask')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default withTranslation()(MaintenanceIntro);
