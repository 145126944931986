import {
    Configuration as NotificationAPIConfiguration,
    RequestContext,
    FetchParams,
    ResponseContext,
    NotificationsApi,
    MessagingApi
} from 'generated/frontend-event-api';

import { Configuration as GeocodingApiConfiguration, GeocodingApi } from 'generated/geocoding-api';
import {
    Configuration as BackendApiConfiguration,
    AetrApi,
    AlarmsApi as BackendBaseAPI,
    MaintenanceApi,
    FuelStationsApi,
    TripApi,
    DriverBehaviourApi,
    CostsApi,
    BorderCrossingApi,
    DriverBehaviourLightVehiclesApi,
    TransportsApi,
    PlaceOfWorkApi,
    CustomPlaceApi,
    CustomPlaceCrossingApi,
    TravisApi,
    DiemsApi,
    StazkaApi,
    DefaultDriverApi,
    BorderCrossesApi,
    DefaultApi,
    DtcoApi,
    JourneyStatisticsApi,
    VehicleStateApi,
    TransportTemplateApi,
    DocumentApi,
    PerfectDriveApi,
    PuescApi
} from 'generated/backend-api';

import {
    Configuration as BackendApiLiveConfiguration,
    PointInfoApi,
    GraphApiApi,
    FleetDynamicDataApi,
    ExternalDeviceApi
} from 'generated/backend-api-live';

import {
    Configuration as MainAPIConfiguration,
    UserApi,
    ClientApi,
    TagApi,
    TagCategoryApi,
    ApplicationsApi
} from 'generated/main-data-api';

import {
    Configuration as NewMainAPIConfiguration,
    ClientApi as NewClientApi,
    CurrencyApi,
    MonitoredObjectApi,
    ObuApi,
    AddressApi,
    ContactPersonApi,
    CountryApi,
    ObuTypeApi,
    MonitoredObjectGroupApi,
    MonitoredObjectGroupTypeApi,
    UserApi as NewUserApi,
    MonitoringDeviceApi,
    MonitoringDeviceTypeApi,
    MonitoredObjectToMonitoredObjectApi,
    MonitoredObjectTypeApi,
    ExternalSystemApi,
    MonitoredObjectOperationalCostApi as NewMonitoredObjectOperationalCostApi,
    CompanyOperationalCostApi as NewCompanyOperationalCostApi,
    OdometerAdjustmentApi,
    DriverApplicationApi,
    WhiteLabelApi,
    WhiteLabelTemplateApi,
    UserGroupApi,
    UserRightApi,
    UserRoleApi,
    UserTokenApi,
    MaintenanceTaskApi,
    MaintenanceTaskTypeApi,
    ExternalSystemSecretApi,
    ExternalSystemAccessApi,
    CustomerAccessApi,
    DiemsRatesApi,
    DiemsRulesApi,
    ModuleApi,
    BinaryRightsApi,
    ContactListApi,
    PlExciseDutyGoodsTypeApi,
    FuelCardApi,
    FuelCompanyApi,
    VehicleProfileApi,
    ColdChainApi,
    DiemsRules2Api,
    TlmBundleApi
} from 'generated/new-main';

import { RoutingApi, Configuration as RoutingApiConfiguration } from 'generated/routing-api';

import {
    Configuration as DFFAPIConfiguration,
    DffProposalsApi,
    DffNegotiationApi,
    DffShipmentApi
} from 'generated/dff-api';

import {
    DefaultApi as CgiPartnerModuleApi,
    Configuration as CgiPartnerModuleApiConfiguration
} from 'generated/cgi-partner-module-api';

import { Conf } from '../conf';

type Auth = () => Promise<string | undefined>;

export class Api {
    readonly conf: Conf['api'];

    readonly addressApi: AddressApi;
    readonly applicationsApi: ApplicationsApi;
    readonly backendApi: BackendBaseAPI;
    readonly clientApi: ClientApi;
    readonly contactPersonApi: ContactPersonApi;
    readonly countryApi: CountryApi;
    readonly currencyApi: CurrencyApi;
    readonly externalSystemTypeApi: ExternalSystemApi;
    readonly externalSystemAccessApi: ExternalSystemAccessApi;
    readonly externalSystemSecretApi: ExternalSystemSecretApi;
    readonly geocodingApi: GeocodingApi;
    readonly maintenanceApi: MaintenanceApi;
    readonly messaging: MessagingApi;
    readonly monitoredObjectApi: MonitoredObjectApi;
    readonly monitoredObjectGroupApi: MonitoredObjectGroupApi;
    readonly monitoredObjectGroupTypeApi: MonitoredObjectGroupTypeApi;
    readonly monitoredObjectToMonitoredObjectApi: MonitoredObjectToMonitoredObjectApi;
    readonly monitoredObjectTypeApi: MonitoredObjectTypeApi;
    readonly monitoringDeviceApi: MonitoringDeviceApi;
    readonly monitoringDeviceTypeApi: MonitoringDeviceTypeApi;
    readonly newClientApi: NewClientApi;
    readonly newUserApi: NewUserApi;
    readonly notifications: NotificationsApi;
    readonly obuApi: ObuApi;
    readonly obuTypeApi: ObuTypeApi;
    readonly odometerAdjustmentApi: OdometerAdjustmentApi;
    readonly maintenanceTaskApi: MaintenanceTaskApi;
    readonly coldchainApi: ColdChainApi;
    readonly maintenanceTaskTypeApi: MaintenanceTaskTypeApi;
    readonly journeysStatisticsApi: JourneyStatisticsApi;
    readonly journeyGraphApi: GraphApiApi;
    readonly externalDeviceApi: ExternalDeviceApi;
    readonly tagApi: TagApi;
    readonly tagGroupApi: TagCategoryApi;
    readonly userApi: UserApi;
    readonly fuelStationsApi: FuelStationsApi;
    readonly newMonitoredObjectOperationalCostApi: NewMonitoredObjectOperationalCostApi;
    readonly newCompanyOperationalCost: NewCompanyOperationalCostApi;
    readonly driverApplicationApi: DriverApplicationApi;
    readonly tripApi: TripApi;
    readonly driverBehaviourApi: DriverBehaviourApi;
    readonly driverBehaviourLightVehiclesApi: DriverBehaviourLightVehiclesApi;
    readonly costsApi: CostsApi;
    readonly userGroupApi: UserGroupApi;
    readonly userRightApi: UserRightApi;
    readonly userRoleApi: UserRoleApi;
    readonly fuelCardApi: FuelCardApi;
    readonly fuelCompanyApi: FuelCompanyApi;
    readonly userTokenApi: UserTokenApi;
    readonly borderCrossingApi: BorderCrossingApi;
    readonly whitelabelApi: WhiteLabelApi;
    readonly whitelabelTemplateApi: WhiteLabelTemplateApi;
    readonly placeOfWorkApi: PlaceOfWorkApi;
    readonly customPlaceApi: CustomPlaceApi;
    readonly customPlaceCrossingApi: CustomPlaceCrossingApi;
    readonly travisApi: TravisApi;
    readonly stazkaApi: StazkaApi;
    readonly aetrApi: AetrApi;
    readonly customerAccessApi: CustomerAccessApi;
    readonly vehicleStateApi: VehicleStateApi;
    readonly borderCrossesApi: BorderCrossesApi;
    readonly ContactListApi: ContactListApi;
    readonly PlExciseDutyGoodsTypeApi: PlExciseDutyGoodsTypeApi;
    readonly dtcoApi: DtcoApi;
    readonly defaultApi: DefaultApi;
    readonly vehicleProfileApi: VehicleProfileApi;
    readonly documentApi: DocumentApi;
    readonly tlmBundleApi: TlmBundleApi;
    readonly perfectDriveApi: PerfectDriveApi;
    readonly puescApi: PuescApi;

    readonly transportApi: TransportsApi;
    readonly transportTemplateApi: TransportTemplateApi;
    readonly diemsRateApi: DiemsRatesApi;
    readonly diemsApi: DiemsApi;
    readonly diemsRuleApi: DiemsRulesApi;
    readonly diemsRules2Api: DiemsRules2Api;
    readonly defaultDriverApi: DefaultDriverApi;
    readonly moduleApi: ModuleApi;
    readonly binaryRightsApi: BinaryRightsApi;
    readonly dffProposalApi: DffProposalsApi;
    readonly routingApi: RoutingApi;
    readonly pointInfoApi: PointInfoApi;
    readonly fleetDynamicDataApi: FleetDynamicDataApi;
    readonly dffNegotiationApi: DffNegotiationApi;
    readonly dffShipmentApi: DffShipmentApi;

    readonly cgiPartnerModuleApi: CgiPartnerModuleApi;

    private _auth?: Auth;
    constructor(conf: Conf['api']) {
        this.conf = conf;

        const apiNotificationConf = new NotificationAPIConfiguration(
            this._createConfiguration(this.conf.notification.basePath)
        );
        const apiDFFConf = new DFFAPIConfiguration(this._createConfiguration(this.conf.backend.basePath));
        // const apiDFFConf = new DFFAPIConfiguration(this._createConfiguration('http://localhost:8091'));

        const apiBackendConf = new BackendApiConfiguration(this._createConfiguration(this.conf.backend.basePath));
        const apiVehicleStateConf = new BackendApiConfiguration(this._createConfiguration(this.conf.backend.basePath)); // manualy added

        const apiBackendLiveConf = new BackendApiLiveConfiguration(
            this._createConfiguration(this.conf.backend.basePath)
        );
        const apiGeocodingConf = new GeocodingApiConfiguration(this._createConfiguration(this.conf.geocoding.basePath));
        const apiMainConf = new MainAPIConfiguration(this._createConfiguration(this.conf.main.basePath));
        const apiNewMainConf = new NewMainAPIConfiguration(this._createConfiguration(this.conf.newMain.basePath));
        const apiTransportsConf = new BackendApiConfiguration(this._createConfiguration(this.conf.transport.basePath));
        const apiRoutingConf = new RoutingApiConfiguration(this._createConfiguration(this.conf.routing.basePath));

        const cgiPartnerModuleConf = new CgiPartnerModuleApiConfiguration(
            this._createConfiguration(this.conf.cgiPartnerModule.basePath)
        );

        this.addressApi = new AddressApi(apiNewMainConf);
        this.applicationsApi = new ApplicationsApi(apiMainConf);
        this.backendApi = new BackendBaseAPI(apiBackendConf);
        this.clientApi = new ClientApi(apiMainConf);
        this.contactPersonApi = new ContactPersonApi(apiNewMainConf);
        this.countryApi = new CountryApi(apiNewMainConf);
        this.currencyApi = new CurrencyApi(apiNewMainConf);
        this.externalSystemTypeApi = new ExternalSystemApi(apiNewMainConf);
        this.externalSystemAccessApi = new ExternalSystemAccessApi(apiNewMainConf);
        this.externalSystemSecretApi = new ExternalSystemSecretApi(apiNewMainConf);
        this.geocodingApi = new GeocodingApi(apiGeocodingConf);
        this.maintenanceApi = new MaintenanceApi(apiBackendConf);
        this.coldchainApi = new ColdChainApi(apiNewMainConf);
        this.journeysStatisticsApi = new JourneyStatisticsApi(apiBackendConf);
        this.journeyGraphApi = new GraphApiApi(apiBackendLiveConf);
        this.externalDeviceApi = new ExternalDeviceApi(apiBackendLiveConf);
        this.messaging = new MessagingApi(apiNotificationConf);
        this.monitoredObjectApi = new MonitoredObjectApi(apiNewMainConf);
        this.monitoredObjectGroupApi = new MonitoredObjectGroupApi(apiNewMainConf);
        this.monitoredObjectGroupTypeApi = new MonitoredObjectGroupTypeApi(apiNewMainConf);
        this.monitoredObjectToMonitoredObjectApi = new MonitoredObjectToMonitoredObjectApi(apiNewMainConf);
        this.monitoredObjectTypeApi = new MonitoredObjectTypeApi(apiNewMainConf);
        this.monitoringDeviceApi = new MonitoringDeviceApi(apiNewMainConf);
        this.monitoringDeviceTypeApi = new MonitoringDeviceTypeApi(apiNewMainConf);
        this.newClientApi = new NewClientApi(apiNewMainConf);
        this.newUserApi = new NewUserApi(apiNewMainConf);
        this.notifications = new NotificationsApi(apiNotificationConf);
        this.obuApi = new ObuApi(apiNewMainConf);
        this.obuTypeApi = new ObuTypeApi(apiNewMainConf);
        this.odometerAdjustmentApi = new OdometerAdjustmentApi(apiNewMainConf);
        this.maintenanceTaskApi = new MaintenanceTaskApi(apiNewMainConf);
        this.maintenanceTaskTypeApi = new MaintenanceTaskTypeApi(apiNewMainConf);
        this.tagApi = new TagApi(apiMainConf);
        this.tagGroupApi = new TagCategoryApi(apiMainConf);
        this.userApi = new UserApi(apiMainConf);
        this.fuelStationsApi = new FuelStationsApi(apiBackendConf);
        this.dffProposalApi = new DffProposalsApi(apiDFFConf);
        this.newCompanyOperationalCost = new NewCompanyOperationalCostApi(apiNewMainConf);
        this.newMonitoredObjectOperationalCostApi = new NewMonitoredObjectOperationalCostApi(apiNewMainConf);
        this.driverApplicationApi = new DriverApplicationApi(apiNewMainConf);
        this.tripApi = new TripApi(apiBackendConf);
        this.driverBehaviourApi = new DriverBehaviourApi(apiBackendConf);
        this.driverBehaviourLightVehiclesApi = new DriverBehaviourLightVehiclesApi(apiBackendConf);
        this.costsApi = new CostsApi(apiBackendConf);
        this.borderCrossingApi = new BorderCrossingApi(apiBackendConf);
        this.whitelabelApi = new WhiteLabelApi(apiNewMainConf);
        this.whitelabelTemplateApi = new WhiteLabelTemplateApi(apiNewMainConf);
        this.userGroupApi = new UserGroupApi(apiNewMainConf);
        this.userRightApi = new UserRightApi(apiNewMainConf);
        this.userRoleApi = new UserRoleApi(apiNewMainConf);
        this.fuelCardApi = new FuelCardApi(apiNewMainConf);
        this.fuelCompanyApi = new FuelCompanyApi(apiNewMainConf);
        this.userTokenApi = new UserTokenApi(apiNewMainConf);
        this.transportApi = new TransportsApi(apiTransportsConf);
        this.diemsRateApi = new DiemsRatesApi(apiNewMainConf);
        this.diemsRuleApi = new DiemsRulesApi(apiNewMainConf);
        this.diemsRules2Api = new DiemsRules2Api(apiNewMainConf);
        this.moduleApi = new ModuleApi(apiNewMainConf);
        this.diemsApi = new DiemsApi(apiBackendConf);
        this.placeOfWorkApi = new PlaceOfWorkApi(apiBackendConf);
        this.customPlaceApi = new CustomPlaceApi(apiBackendConf);
        this.customPlaceCrossingApi = new CustomPlaceCrossingApi(apiBackendConf);
        this.travisApi = new TravisApi(apiBackendConf);
        this.stazkaApi = new StazkaApi(apiBackendConf);
        this.aetrApi = new AetrApi(apiBackendConf);
        this.customerAccessApi = new CustomerAccessApi(apiNewMainConf);
        this.vehicleStateApi = new VehicleStateApi(apiVehicleStateConf);
        this.defaultDriverApi = new DefaultDriverApi(apiBackendConf);
        this.binaryRightsApi = new BinaryRightsApi(apiNewMainConf);
        this.borderCrossesApi = new BorderCrossesApi(apiBackendConf);
        this.ContactListApi = new ContactListApi(apiNewMainConf);
        this.PlExciseDutyGoodsTypeApi = new PlExciseDutyGoodsTypeApi(apiNewMainConf);
        this.routingApi = new RoutingApi(apiRoutingConf);
        this.dtcoApi = new DtcoApi(apiBackendConf);
        this.defaultApi = new DefaultApi(apiBackendConf);
        this.pointInfoApi = new PointInfoApi(apiBackendLiveConf);
        this.fleetDynamicDataApi = new FleetDynamicDataApi(apiBackendLiveConf);
        this.vehicleProfileApi = new VehicleProfileApi(apiNewMainConf);
        this.transportTemplateApi = new TransportTemplateApi(apiTransportsConf);
        this.dffNegotiationApi = new DffNegotiationApi(apiDFFConf);
        this.dffShipmentApi = new DffShipmentApi(apiDFFConf);
        this.documentApi = new DocumentApi(apiBackendConf);
        this.tlmBundleApi = new TlmBundleApi(apiNewMainConf);
        this.perfectDriveApi = new PerfectDriveApi(apiBackendConf);
        this.puescApi = new PuescApi(apiBackendConf);

        this.cgiPartnerModuleApi = new CgiPartnerModuleApi(cgiPartnerModuleConf);
    }

    customerAccessApiRaw(): CustomerAccessApi {
        return new CustomerAccessApi(
            new NewMainAPIConfiguration({
                basePath: this.conf.newMain.basePath
            })
        );
    }

    init(auth?: Auth) {
        auth && (this._auth = auth);
    }

    private _createConfiguration(basePath: string) {
        return {
            basePath,
            middleware: [
                {
                    pre: this._preMiddleware,
                    post: this._postMiddleware
                }
            ]
        };
    }

    private _preMiddleware = async (context: RequestContext): Promise<FetchParams | void> => {
        const token = this._auth && (await this._auth());
        context.init = {
            ...context.init,
            headers: {
                ...context.init.headers,
                Authorization: 'Bearer ' + token
            }
        };
        (context.init as any).t0 = performance.now();
        return Promise.resolve(context);
    };

    private _postMiddleware = async (context: ResponseContext): Promise<Response | void> => {
        const t1 = performance.now();
        const t = t1 - (context.init as any).t0;
        const limit = 1e1;
        if (t > limit) {
            console.warn(`API ${context.url || ''} ${t} ms (request exceeded ${limit} ms)\n`);
        }
        return;
    };
}
