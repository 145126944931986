import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Subscription } from 'rxjs';
import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';
import * as DriverBehaviorIcons from 'resources/images/driver-behavior';
import { Logic } from 'logic/logic';

import { RouteNames } from 'App';

import DriverBehaviorCollapse from '../../../components/DriverBehaviorCollapse';
import DriverBehaviorStat from '../../../components/DriverBehaviorStat';
import DriverScoreLightStats from '../../../components/DriverScoreLightStats';
import DriverDetailNav from '../../../components/DriverDetailNav';
import DriverStatValue, { DriverStatValueFormat } from '../../../components/DriverStatValue';
import Trend, { TrendChange } from 'common/components/Trend';
import qa from 'qa-selectors';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { Themes } from 'common/components/ChartTimeline';

function DriverBehaviorIcon({ icon }: { icon: string }) {
    return <img src={icon} alt={icon} />;
}

interface DriverBehaviorDataMap {
    [key: string]: {
        key: keyof DriverBehaviorLightVehicleModel['detail'] | keyof DriverBehaviorLightVehicleModel;
        unit: DriverStatValueFormat;
        icon?: string;
        trendForIncrease?: TrendChange;
    }[];
}

interface Props extends WithTranslation, RouteComponentProps<{ id: string }> {
    logic: Logic;
    theme: Themes;
}

interface State {
    data?: DriverBehaviorLightVehicleModel;
    dataMap: DriverBehaviorDataMap;
    date?: string;
    export: {
        processing: boolean;
        enable: boolean;
    };
}

/**
 * LIGHT VEHICLE DETAIL
 * HARSH events
 * - Agresívny rozjazd - city_aggresive_takeoff_penalty
 * - Nebezpečné brzdenie 10-60 km/h	- city_dangerous_breaking_penalty
 * - Nebezpečné brzdenie 60-110 km/h - country_dangerous_breaking_penalty
 * - Nebezpečné prejazdy zákrutou 10-65 km/h - city_dangerous_turn_penalty
 * - Nebezpečné prejazdy zákrutou 65-110 km/h - country_dangerous_turn_penalty
 * - Nebezpečné prejazdy zákrutou viac ako 110 km/h	 - highway_dangerous_turn_penalty
 * - Neplynulá jazda 60-110 km/h - country_uneven_drive_penalty
 * - Neplynulá jazda viac ako 110 km/h - highway_uneven_drive_penalty
 * ECOLOGY
 * - Spotrebované palivo pri stáni na voľnobeh - nemame
 * - Priemerná spotreba na 100km - nemame
 * - CO2 - nemame
 * SAFETY
 * - Nebezpečná rýchlosť  nad 110km/h highway_dangerous_speed_penalty
 * DRIVE style
 * - sum_city_duration, sum_country_duration, sum_highway_duration
 */
class VehicleDetailModule extends Component<Props, State> {
    subscriptionExport?: Subscription;

    constructor(props: Props) {
        super(props);
        this.state = {
            export: {
                processing: false,
                enable: true
            },
            dataMap: {
                harshEvents: [
                    {
                        key: 'dangerousBrakingPenalty',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.brakejawsVehicle,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'cityAggresiveTakeoffPenalty',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.accelerationVehicle,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'countryDangerousTurnPenalty',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.curve1,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'highwayDangerousTurnPenalty',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.curve2,
                        trendForIncrease: TrendChange.NEGATIVE
                    }
                ],
                ecology: [
                    {
                        key: 'ecologyScore',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.fuelPistolVehicle,
                        trendForIncrease: TrendChange.NEGATIVE
                    }
                ],
                safety: [
                    {
                        key: 'highwayDangerousSpeedPenalty',
                        unit: 'stars',
                        icon: DriverBehaviorIcons.speedVehicle,
                        trendForIncrease: TrendChange.NEGATIVE
                    }
                ]
            }
        };
    }

    componentDidMount() {
        const detail = this.props.logic.driverBehavior().vehicles().getById(this.props.match.params.id);
        const date = this.props.logic.driverBehavior().vehicles().date;

        this.subscriptionExport = this.props.logic
            .driverBehavior()
            .vehicles()
            .exportDetailProcessing.subscribe(processing => {
                this.setState(state => ({
                    export: {
                        ...state.export,
                        processing: processing
                    }
                }));
            });

        if (detail) {
            this.setState({
                data: detail,
                date
            });
        } else {
            this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const detail = this.props.logic.driverBehavior().vehicles().getById(this.props.match.params.id);
            const date = this.props.logic.driverBehavior().vehicles().date;

            if (detail) {
                this.setState({
                    data: detail,
                    date
                });
            } else {
                this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES);
            }
        }
    }

    componentWillUnmount() {
        this.subscriptionExport?.unsubscribe();
    }

    render() {
        const { t } = this.props;
        const { dataMap, data, date } = this.state;

        return (
            <div className="driver-behavior">
                <DriverDetailNav
                    name={data?.name}
                    tachocard={data?.tachocard}
                    date={date}
                    export={this.state.export}
                    onExportClick={() => {
                        this.props.logic
                            .driverBehavior()
                            .vehicles()
                            .downloadDriverBehaviorDetailExport(String(data?.driverId));
                    }}
                    onBackButtonClick={this._onBackButtonClick}
                />
                <DriverScoreLightStats data={data} theme={this.props.theme} />
                <div className="driver-behavior-data">
                    <DriverBehaviorCollapse
                        title={t('DriverBehaviorLightTable.cols.economyScore')}
                        stats={dataMap.harshEvents.map(e => (
                            <DriverBehaviorStat
                                key={e.key}
                                color="blue"
                                value={<DriverStatValue value={data?.detail?.[e.key]} unit={e.unit} rateColor="blue" />}
                                description={t(`DriverBehaviorVehicleDetail.options.economy.${e.key}`)}
                                note={t(`DriverBehaviorVehicleDetail.options.economy.${e.key}Note`)}
                                development={
                                    <Trend
                                        direction={getIncreaseDirection(
                                            data?.detail?.[e.key],
                                            data?.prevDetail?.[e.key]
                                        )}
                                        trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                        value={getIncreasePercent(data?.detail?.[e.key], data?.prevDetail?.[e.key])}
                                    />
                                }
                                icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                qa={qa.driverBehaviour.light.detail.economy[e.key]}
                            />
                        ))}
                    />
                    <Row gutter={15}>
                        <Col span={12}>
                            <DriverBehaviorCollapse
                                title={t('DriverBehaviorVehicleDetail.ecology.title')}
                                stats={dataMap.ecology.map(e => (
                                    <DriverBehaviorStat
                                        key={e.key}
                                        color="green"
                                        value={
                                            <DriverStatValue value={data?.[e.key]} unit={e.unit} rateColor="green" />
                                        }
                                        description={t(`DriverBehaviorVehicleDetail.options.ecology.${e.key}`)}
                                        note={t(`DriverBehaviorVehicleDetail.options.ecology.${e.key}Note`)}
                                        development={
                                            <Trend
                                                direction={getIncreaseDirection(
                                                    data?.detail?.[e.key],
                                                    data?.prevDetail?.[e.key]
                                                )}
                                                trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                                value={getIncreasePercent(
                                                    data?.detail?.[e.key],
                                                    data?.prevDetail?.[e.key]
                                                )}
                                            />
                                        }
                                        icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                        qa={qa.driverBehaviour.light.detail.ecology}
                                    />
                                ))}
                            />
                        </Col>
                        <Col span={12}>
                            <DriverBehaviorCollapse
                                title={t('DriverBehaviorVehicleDetail.safety.title')}
                                stats={dataMap.safety.map(e => (
                                    <DriverBehaviorStat
                                        key={e.key}
                                        color="yellow"
                                        value={
                                            <DriverStatValue
                                                value={data?.detail?.[e.key]}
                                                unit={e.unit}
                                                rateColor="yellow"
                                            />
                                        }
                                        description={t(`DriverBehaviorVehicleDetail.options.safety.${e.key}`)}
                                        note={t(`DriverBehaviorVehicleDetail.options.safety.${e.key}Note`)}
                                        development={
                                            <Trend
                                                direction={getIncreaseDirection(
                                                    data?.detail?.[e.key],
                                                    data?.prevDetail?.[e.key]
                                                )}
                                                trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                                value={getIncreasePercent(
                                                    data?.detail?.[e.key],
                                                    data?.prevDetail?.[e.key]
                                                )}
                                            />
                                        }
                                        icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                        qa={qa.driverBehaviour.light.detail.safety}
                                    />
                                ))}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    private _onBackButtonClick = () => {
        this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES);
    };
}

export default withRouter(withTranslation()(VehicleDetailModule));
