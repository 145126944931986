import { Subject } from 'rxjs';
import i18n from 'i18next';
import {
    DriverBehaviorModel,
    DriverBehaviorModelDetail,
    DriverBehaviorTrendsModel,
    DriverLeaderboardTrendsTableModel,
    PerfectDriveDriverDetailModel
} from 'common/model/statistics';
import { Logic } from '../logic';
import {
    DriverBehaviourContextualPeriod,
    DriverBehaviourScore,
    PerfectDriveGroupedByDriver,
    PerfectDrivePeriod,
    PerfectDriveScoreContextualPeriod,
    PerfectDriveScorePeriod
} from 'generated/backend-api';
import { fromBlankAsync, Sheet, Workbook } from 'xlsx-populate';
import moment from 'moment';
import { roundToStep } from 'common/utils/averages';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import numeral from 'numeral';
import { DRIVER_BEHAVIOR_DATE_FORMAT, L, MONTH_YEAR_FORMAT } from 'domain-constants';
import { DriverLeaderboardTrendsTableFilter } from 'modules/statistics/modules/driver-behavior-coach/components/DriverLeaderboardTrendsTable';
import { DriverModel } from 'logic/user/users';
import { formatName, snakeCaseToCamelCase, translateFormatedText } from 'common/utils/textUtils';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { DriverBehaviorCoachDataMap } from 'modules/statistics/modules/driver-behavior-coach/components/DriverBehaviorCoach';
import * as DriverBehaviorIcons from 'resources/images/driver-behavior';
import * as DriverBehaviorCoachIcons from 'resources/images/driver-behavior/coach-icon';
import { TrendChange } from 'common/components/Trend';
import {
    DriverBehaviorDataMap,
    DriverBehaviorDataMapModel
} from 'modules/statistics/modules/driver-behavior-coach/modules/Trucks/Detail';
import { formatDuration, formatSecondsToString } from 'common/utils/dateTimeUtils';
import { DriverStatValueFormat } from 'modules/statistics/modules/driver-behavior-coach/components/DriverStatValue';
import { Role } from 'logic/auth';
import {
    getCoachTableLabel,
    getUnit
} from 'modules/statistics/modules/driver-behavior-coach/components/DriverBehaviorCoachTable/DriverBehaviorCoachTableLabel';
import { driverBehaviorCoachTableFormatValue } from 'modules/statistics/modules/driver-behavior-coach/components/DriverBehaviorCoachTable';
import { cloneDeep } from '@apollo/client/utilities';

export type PerfectDriveValueKey = string | Function;

export function getPerfectDriveValue(
    valueKey?: PerfectDriveValueKey,
    data?: PerfectDriveGroupedByDriver,
    defaultValue?: number,
    diminisher?: number,
    maxValue?: number
): number | undefined {
    if (!data) {
        return undefined;
    }
    let value = undefined;
    if (valueKey instanceof Function) {
        value = valueKey(data);
    } else {
        if (valueKey) {
            const keys = valueKey.split('.');
            if (data && keys[0] in data) {
                value = data?.[keys[0]];
            } else {
                console.log(`Key ${keys[0]} in value ${valueKey} not exists.`);
                return value;
            }
            if (value) {
                if (keys.length > 1) {
                    for (let i = 1; i < keys.length; i++) {
                        if (value) {
                            if (keys[i] in value) {
                                value = value[keys[i]];
                            } else {
                                console.log(`Key ${keys[i]} in value ${valueKey} not exists.`);
                                value = undefined;
                                break;
                            }
                        } else {
                            console.log(`Key ${keys[0]} in value ${valueKey} not exists.`);
                            break;
                        }
                    }
                }
            }
        }
    }

    if (value === undefined && defaultValue) {
        value = defaultValue;
    }

    if (diminisher !== undefined) {
        value = value * diminisher;
    }
    if (maxValue !== undefined) {
        value = Math.min(value, maxValue);
    }
    return value;
}

export function getPerfectDriveExclamation(
    valueKey?: PerfectDriveValueKey,
    data?: PerfectDriveGroupedByDriver
): boolean {
    if (data && valueKey && data.pgnCounters) {
        const keys = String(valueKey).split('|');
        for (let key in data.pgnCounters) {
            for (let i = 0; i < keys.length; i++) {
                if (data.pgnCounters[key][keys[i]]) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function formatDriverBehaviorName(
    name: string | undefined,
    surname: string | undefined,
    tachocard: string | undefined
): string {
    return (name || surname ? formatName(name, surname) : tachocard) ?? '';
}
export class DriverBehaviorTrucks {
    private _data?: DriverBehaviorModel[];
    private _dataTrends?: DriverBehaviorTrendsModel[];
    private _dataPerfectDrive?: PerfectDriveDriverDetailModel[];
    private _currentDataTrends?: DriverBehaviorTrendsModel[];
    private _dataContextual?: DriverBehaviourContextualPeriod[];
    private _detailData?: PerfectDriveDriverDetailModel;
    private _detailComparedData?: PerfectDriveDriverDetailModel;
    private _recommends?: DriverBehaviorCoachDataMap[];

    private _logic: Logic;
    private _drivers?: DriverModel[];
    private _vehicles?: ReadOnlyMonitoredObjectFeSb[];

    data = new Subject<DriverBehaviorModel[]>();
    dataTrends = new Subject<DriverBehaviorTrendsModel[]>();
    dataContextual = new Subject<DriverBehaviourContextualPeriod[]>();
    detailData = new Subject<PerfectDriveDriverDetailModel | undefined>();
    detailComparedData = new Subject<PerfectDriveDriverDetailModel | undefined>();

    dataPerfectDrive = new Subject<PerfectDriveDriverDetailModel[]>();
    detailReportData = new Subject<PerfectDriveDriverDetailModel | undefined>();
    detailReportComparedData = new Subject<PerfectDriveDriverDetailModel | undefined>();

    loading = new Subject<boolean>();
    error = new Subject<any>();
    exportListProcessing = new Subject<boolean>();
    exportDetailProcessing = new Subject<boolean>();
    detailDataLoading = new Subject<boolean>();
    date?: string;
    comparedDate?: string;
    selectedDriverId?: string;
    selectedDriverName?: string;
    comparedDriverId?: string;
    perfectDriveScoreData?: PerfectDriveScorePeriod[];
    perfectDriveReportData?: PerfectDrivePeriod[];

    constructor(logic: Logic) {
        this._logic = logic;
    }

    selectWorstDrivers = () => {
        if (!this._data) return [];
        return [...this._data].sort((a, b) => a.score - b.score).map((d, index) => ({ ...d, rank: index + 1 }));
    };

    selectWorstDriversTrends = (leaderFilter: DriverLeaderboardTrendsTableFilter) => {
        if (!this._currentDataTrends) return [];

        const fieldFilter = this.getLeaderSortField(leaderFilter);
        return this.getLeaderData(leaderFilter, 'asc').map(
            (d, i) =>
                ({
                    id: d.driverId ?? '',
                    name: d.name ?? '',
                    score: d.scores[0][fieldFilter] ?? 0,
                    trend: [d.scores[0][fieldFilter], d.scores?.[1]?.[fieldFilter]],
                    value: d.scores[0][fieldFilter] ?? 0,
                    rank: i + 1
                } as DriverLeaderboardTrendsTableModel)
        );
    };

    getLeaderData = (leaderFilter: DriverLeaderboardTrendsTableFilter, direction?: 'asc' | 'desc' | 'improver') => {
        if (!this._currentDataTrends) return [];

        const sortFieldName = this.getLeaderSortField(leaderFilter);
        return this._currentDataTrends.sort((a, b) => {
            if (direction === 'desc') {
                return (a.scores?.[0]?.[sortFieldName] ?? 0) > (b.scores?.[0]?.[sortFieldName] ?? 0) ? -1 : 1;
            } else if (direction === 'asc') {
                return (a.scores?.[0]?.[sortFieldName] ?? 0) > (b.scores?.[0]?.[sortFieldName] ?? 0) ? 1 : -1;
            } else if (direction === 'improver') {
                return (a.scores?.[1]?.[sortFieldName] ?? 0) - (a.scores?.[0]?.[sortFieldName] ?? 0) >
                    (b.scores?.[1]?.[sortFieldName] ?? 0) - (b.scores?.[0]?.[sortFieldName] ?? 0)
                    ? 1
                    : -1;
            } else {
                return a.name > b.name ? 1 : -1;
            }
        });
    };

    getLeaderSortField = (leaderFilter: DriverLeaderboardTrendsTableFilter): string => {
        return leaderFilter === DriverLeaderboardTrendsTableFilter.WearTear
            ? 'wearTearScore'
            : leaderFilter === DriverLeaderboardTrendsTableFilter.Eco
            ? 'ecoScore'
            : leaderFilter === DriverLeaderboardTrendsTableFilter.Driving
            ? 'driveScore'
            : 'score';
    };

    getRecommends = () => {
        return this._recommends;
    };

    selectTopDrivers = () => {
        if (!this._data) return [];
        return [...this._data].sort((a, b) => b.score - a.score).slice(0, 5);
    };

    selectTopDriversTrends = (leaderFilter: DriverLeaderboardTrendsTableFilter) => {
        if (!this._currentDataTrends) return [];

        const fieldFilter = this.getLeaderSortField(leaderFilter);
        return this.getLeaderData(leaderFilter, 'desc').map(
            (d, i) =>
                ({
                    id: d.driverId ?? '',
                    name: d.name ?? '',
                    score: d.scores[0][fieldFilter] ?? 0,
                    trend: [d.scores[0][fieldFilter], d.scores?.[1]?.[fieldFilter]],
                    value: d.scores[0][fieldFilter] ?? 0,
                    rank: i + 1
                } as DriverLeaderboardTrendsTableModel)
        );
    };

    selectTopImproversDrivers = () => {
        if (!this._data) return [];
        const improvement = (newValue: number, oldValue: number) =>
            ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100;

        return this._data
            .filter(e => e.oldScore)
            .sort((a, b) => improvement(a.score, a.oldScore!) - improvement(b.score, b.oldScore!))
            .map((d, index) => ({ ...d, rank: index + 1 }));
    };

    selectTopImproversDriversTrends = (leaderFilter: DriverLeaderboardTrendsTableFilter) => {
        if (!this._currentDataTrends) return [];
        const improvement = (newValue: number, oldValue: number) =>
            ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100;

        const fieldFilter = this.getLeaderSortField(leaderFilter);
        let rank = 1;
        return this.getLeaderData(leaderFilter, 'improver')
            ?.map(
                d =>
                    ({
                        id: d.driverId ?? '',
                        name: d.name ?? '',
                        score: d.scores[0][fieldFilter] ?? 0,
                        trend: [d.scores[0][fieldFilter], d.scores?.[1]?.[fieldFilter]],
                        value:
                            d.scores?.[1][fieldFilter] && d.scores?.[0][fieldFilter]
                                ? improvement(d.scores[0][fieldFilter], d.scores?.[1][fieldFilter])
                                : undefined,
                        rank: d.scores?.[1][fieldFilter] && d.scores?.[0][fieldFilter] ? rank++ : undefined
                    } as DriverLeaderboardTrendsTableModel)
            )
            .sort((a, b) => (!b.rank ? -1 : 1));
    };

    getByDriverId(id: string) {
        return (this._data ?? []).find(e => e.driverId === Number(id));
    }

    getByDriverTachocard(tachocard?: string) {
        return (this._data ?? []).find(e => e.tachocard === tachocard);
    }

    getDataTrendsByDriverId(id: string) {
        return (this._dataTrends ?? []).find(e => e.driverId === id);
    }

    getDataReportByDriverId(id: string) {
        return (this._dataPerfectDrive ?? []).find(e => e.id === id);
    }

    getDataTrendsDrivers() {
        return (this._dataTrends ?? []).filter(e => e.driverId).map(e => ({ value: e.driverId ?? '', label: e.name }));
    }

    getContextualData() {
        return this._dataContextual ?? [];
    }

    getCurrentScoreData() {
        return this._currentDataTrends ?? [];
    }

    getDetailData() {
        return this._detailData;
    }

    getDetailComparedData() {
        return this._detailComparedData;
    }

    getDate() {
        return this.date;
    }

    getComparedDate() {
        return this.comparedDate;
    }

    getSelectedDriverId() {
        return this.selectedDriverId;
    }

    getSelectedDriverName() {
        return this.selectedDriverName;
    }

    getComparedDriverId() {
        return this.comparedDriverId;
    }

    setDate(date?: string) {
        this.date = date;
    }

    setComparedDate(comparedDate?: string) {
        this.comparedDate = comparedDate;
    }

    setSelectedDriverId(id?: string) {
        this.selectedDriverId = id;
    }

    setSelectedDriverName(name?: string) {
        this.selectedDriverName = name;
    }

    setComparedDriverId(id?: string) {
        this.comparedDriverId = id;
    }

    setDetailData(data?: PerfectDriveDriverDetailModel) {
        this._detailData = data;
        this.detailData.next(this._detailData);
    }

    setDetailComparedData(data?: PerfectDriveDriverDetailModel) {
        this._detailComparedData = data;
        this.detailComparedData.next(this._detailComparedData);
    }

    async loadData(date: string) {
        this.date = date;
        this.comparedDate = date;
        this.loading.next(true);

        if (this._logic.demo().isActive) {
            this._data = this._logic.demo().data.driverBehaviorTrucks;
        } else {
            try {
                const resp = await this._logic.api().driverBehaviourApi.getDriverBehaviourV1DriverbehaviourMyGet({
                    fromT: new Date(date)
                });
                this._data = resp.act.map(score => {
                    const prevScore = resp.prev.find(ps => ps.generalData.driver1.id === score.generalData.driver1.id);
                    return this._toTruckModel(score, resp.numberOfDrivers, prevScore);
                });
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this.data.next(this._data ?? []);
        this.loading.next(false);
    }

    formatDataMapValue(
        unit: DriverStatValueFormat | undefined,
        value: number | undefined = 0,
        fixed?: number,
        unitName?: string
    ): string {
        const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
        const duration = formatSecondsToString(Math.round(value ?? 0));
        const getUnitName = (unitValue?: string) => {
            return unitName ? translateFormatedText(unitName) : unitValue ? unitValue : '';
        };

        switch (unit) {
            case 'count':
                return `${rounded.toFixed(fixed)} ${getUnitName()}`;
            case 'tonsPerKm':
                return `${rounded.toFixed(fixed)} ${getUnitName('t/100km')}`;
            case 'litres':
                return `${rounded.toFixed(fixed)} ${getUnitName('l')}`;
            case 'litresPerKm':
                return `${rounded.toFixed(fixed)} ${getUnitName('l/100km')}`;
            case 'kmPerHour':
                return `${rounded.toFixed(fixed)} ${getUnitName('km/h')}`;
            case 'duration':
                return `${duration.split(':')[0]}h${duration.split(':')[1]}m${duration.split(':')[2]}s`;
            case 'durationTime':
                return `${duration.split(':')[0]}h${duration.split(':')[1]}m`;
            case 'distance':
                return `${rounded.toFixed(fixed)} ${getUnitName('km')}`;
            case 'percentage':
                return `${rounded.toFixed(fixed)} ${getUnitName('%')}`;
            case 'percentage100':
                return `${(rounded * 100).toFixed(fixed)} ${getUnitName('%')}`;
            case 'stars':
                return `${roundToStep(rounded, 0.1)} ${getUnitName()}`;
            case 'mPer100Km':
                return `${rounded.toFixed(fixed)} ${getUnitName('m/100km')}`;
            default:
                return `${rounded.toFixed(fixed)} ${getUnitName()}`;
        }
    }

    async downloadDriverBehaviorDetailExport() {
        if (!this.selectedDriverId) {
            throw new Error('No id provided');
        }

        this.exportDetailProcessing.next(true);

        const workbook: Workbook = await fromBlankAsync();
        const drivers = [this.getDetailData(), this.getDetailComparedData()];

        const dataMap = this.getGeneralDataMap();

        const improvement = (newValue: number, oldValue: number) =>
            Math.round(((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100 * 10) / 10;

        const writeTable = (
            sheet: Sheet,
            title: string,
            dataMap: DriverBehaviorDataMapModel[],
            currentRow: number,
            data: PerfectDriveDriverDetailModel
        ) => {
            sheet
                .range(currentRow, 1, currentRow, 1)
                .style(centerStyle)
                .style(boldStyle)
                .value([[title]]);
            currentRow += 1;

            dataMap.forEach((d, index) => {
                const value = getPerfectDriveValue(d.value, data.scores[0].detail);
                const prevValue = getPerfectDriveValue(d.value, data.scores[1].detail);
                const trend =
                    value === 0 && prevValue === 0
                        ? 0
                        : prevValue !== undefined
                        ? improvement(
                              getPerfectDriveValue(d.value, data.scores[0].detail) ?? 0,
                              getPerfectDriveValue(d.value, data.scores[1].detail) ?? 0
                          )
                        : undefined;

                sheet
                    .range(currentRow, index + 2, currentRow + 2, index + 2)
                    .style(centerStyle)
                    .style(wrapStyle)
                    .value([
                        [translateFormatedText(d?.title)],
                        [
                            d.unit === 'durationTime'
                                ? formatDuration(
                                      (getPerfectDriveValue(d.value, data.scores[0].detail) ?? 0) * 1000,
                                      true,
                                      false
                                  )
                                : `${driverBehaviorCoachTableFormatValue(
                                      getPerfectDriveValue(d.value, data.scores[0].detail),
                                      d.unit,
                                      d.fixed
                                  )} ${
                                      d.unit !== 'percentage' ? getUnit(d.unit).replace('[', '').replace(']', '') : ''
                                  }`
                        ],
                        [trend === undefined ? i18n.t('DriverBehavior.noDataTrend') : trend === 0 ? '-' : `${trend}`]
                    ]);
                sheet.range(currentRow, index + 2, currentRow, index + 2).style(boldStyle);

                const cell = sheet.cell(`${String.fromCharCode(index + 66)}${currentRow + 2}`);
                if (trend !== undefined && trend !== 0 && d.trendForIncrease !== TrendChange.NEUTRAL) {
                    cell.style({
                        fontColor:
                            (d.trendForIncrease !== TrendChange.POSITIVE && trend < 0) ||
                            (d.trendForIncrease !== TrendChange.NEGATIVE && trend > 0)
                                ? '4caf50'
                                : 'ff3823'
                    });
                }
            });

            sheet.range(currentRow, 1, currentRow + 2, 1).merged(true);
            sheet.range(currentRow, 1, currentRow, 1).style(shadowStyle);
            sheet.range(currentRow + 1, 1, currentRow + 2, 9).style(shadowStyle);

            return (currentRow += 3);
        };

        const centerStyle = {
            verticalAlignment: 'center',
            horizontalAlignment: 'center'
        };

        const boldStyle = {
            bold: true
        };

        const normalStyle = {
            bold: false
        };

        const shadowStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            }
        };

        const wrapStyle = {
            wrapText: true
        };

        drivers
            .filter(driver => driver !== undefined)
            .forEach((data, index) => {
                const driverName = formatDriverBehaviorName(data?.name, data?.surname, data?.tachocard);
                const dataCurrent = data?.scores[0];

                if (!dataCurrent) {
                    throw new Error(`No driver with id: ${data?.id}`);
                }

                const sheet =
                    index === 0
                        ? workbook
                              .sheet(0)
                              .name(normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${driverName}`))
                        : workbook.addSheet(
                              normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${driverName}`)
                          );

                const dateStr = moment(index === 0 ? this.date : this.comparedDate).format(MONTH_YEAR_FORMAT);

                const headDescription = [[i18n.t('DriverDetailModule.title'), '', driverName, '', dateStr], []];
                let currentRow = 1;

                // head descripton
                sheet.range(currentRow, 1, currentRow, 7).style(centerStyle).style(boldStyle).value(headDescription);
                sheet.range(currentRow, 1, currentRow, 2).merged(true);
                sheet.range(currentRow, 3, currentRow, 4).merged(true);
                currentRow += 2;

                // Score
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverScoreStats.title')]]);
                currentRow += 1;

                sheet
                    .range(currentRow, 1, currentRow + 1, 9)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([
                        [
                            roundToStep(dataCurrent.overalScore ?? 0, 1),
                            i18n.t('DriverBehaviorTable.filters.driving'),
                            i18n.t('DriverBehaviorTable.filters.eco'),
                            i18n.t('DriverBehaviorTable.filters.wearTear'),
                            i18n.t('DriveRank.title')
                        ],
                        [
                            '',
                            roundToStep(dataCurrent.driveScore ?? 0, 1),
                            roundToStep(dataCurrent.ecoScore ?? 0, 1),
                            roundToStep(dataCurrent.wearTearScore ?? 0, 1),
                            dataCurrent.rank ?? 1
                        ]
                    ]);
                sheet
                    .range(currentRow, 1, currentRow + 1, 1)
                    .style(shadowStyle)
                    .merged(true);
                sheet
                    .range(currentRow + 1, 2, currentRow + 1, 9)
                    .style(shadowStyle)
                    .style(normalStyle);
                currentRow += 3;

                // Driving data
                currentRow =
                    writeTable(
                        sheet,
                        i18n.t('DriverBehaviorDetail.driveData.title'),
                        dataMap.driveData,
                        currentRow,
                        data
                    ) + 1;

                // Eco data
                currentRow =
                    writeTable(sheet, i18n.t('DriverBehaviorDetail.ecoData.title'), dataMap.ecoData, currentRow, data) +
                    1;

                // Wear tear data
                currentRow = writeTable(
                    sheet,
                    i18n.t('DriverBehaviorDetail.wearTearData.title'),
                    dataMap.wearTearData,
                    currentRow,
                    data
                );

                [25, 25, 25, 25, 25, 25, 25, 25, 25].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;

        const data = this.getDetailData();
        downloadFile(
            blob,
            `${i18n.t('DriverDetailModule.title')} - ${formatDriverBehaviorName(
                data?.name,
                data?.surname,
                data?.tachocard
            )} ${moment(this.date).format(MONTH_YEAR_FORMAT)}.xlsx`
        );

        this.exportListProcessing.next(false);
        this.exportDetailProcessing.next(false);
    }

    async downloadDriverBehaviorDetailCoachExport() {
        if (!this.selectedDriverId) {
            throw new Error('No id provided');
        }

        this.exportDetailProcessing.next(true);

        const workbook: Workbook = await fromBlankAsync();
        const drivers = [this.getDetailData(), this.getDetailComparedData()];

        const dataCoachMap = this.getCoachDataMap();

        const writeTable = (
            sheet: Sheet,
            title: string,
            dataMap: DriverBehaviorCoachDataMap[],
            currentRow: number,
            data: PerfectDriveDriverDetailModel,
            boldRows: number
        ) => {
            sheet
                .range(currentRow, 1, currentRow, 1)
                .style(centerStyle)
                .style(boldStyle)
                .value([[title]]);
            currentRow += 1;

            sheet
                .range(currentRow, 1, currentRow, 9)
                .style(centerStyle)
                .style(boldStyle)
                .value([
                    [
                        '',
                        '',
                        '',
                        '',
                        i18n.t('DriverBehaviorDetail.export.tableTitle.highway'),
                        i18n.t('DriverBehaviorDetail.export.tableTitle.country'),
                        i18n.t('DriverBehaviorDetail.export.tableTitle.city'),
                        i18n.t('DriverBehaviorDetail.export.tableTitle.other'),
                        i18n.t('DriverBehaviorDetail.export.tableTitle.summary')
                    ]
                ]);
            currentRow += 1;

            dataMap.forEach((d, index) => {
                sheet
                    .range(currentRow + index, 1, currentRow + index, 9)
                    .style(centerStyle)
                    .style(shadowStyle)
                    .value([
                        [
                            '',
                            getCoachTableLabel(d),
                            '',
                            '',
                            driverBehaviorCoachTableFormatValue(
                                getPerfectDriveValue(d.values?.highway, data.scores[0].detail),
                                d.unit
                            ),
                            driverBehaviorCoachTableFormatValue(
                                getPerfectDriveValue(d.values?.country, data.scores[0].detail),
                                d.unit
                            ),
                            driverBehaviorCoachTableFormatValue(
                                getPerfectDriveValue(d.values?.urban, data.scores[0].detail),
                                d.unit
                            ),
                            driverBehaviorCoachTableFormatValue(
                                getPerfectDriveValue(d.values?.other, data.scores[0].detail),
                                d.unit
                            ),
                            driverBehaviorCoachTableFormatValue(
                                getPerfectDriveValue(d.values?.summary, data.scores[0].detail),
                                d.unit
                            )
                        ]
                    ]);

                sheet
                    .range(currentRow + index, 2, currentRow + index, 4)
                    .style(leftStyle)
                    .style(wrapStyle)
                    .merged(true);
            });

            sheet.range(currentRow, 2, currentRow + boldRows, 2).style(boldStyle);
            sheet
                .range(currentRow - 1, 1, currentRow + dataMap.length - 1, 1)
                .style(shadowStyle)
                .merged(true);
            currentRow += dataMap.length;

            return currentRow;
        };

        const centerStyle = {
            verticalAlignment: 'center',
            horizontalAlignment: 'center'
        };

        const leftStyle = {
            horizontalAlignment: 'left'
        };

        const bottomStyle = {
            verticalAlignment: 'bottom'
        };

        const boldStyle = {
            bold: true
        };

        const normalStyle = {
            bold: false
        };

        const shadowStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            }
        };

        const wrapStyle = {
            wrapText: true
        };

        drivers
            .filter(driver => driver !== undefined)
            .forEach((data, index) => {
                const driverName = formatDriverBehaviorName(data?.name, data?.surname, data?.tachocard);
                const dataCurrent = data?.scores[0];

                if (!dataCurrent) {
                    throw new Error(`No driver with id: ${data?.id}`);
                }

                const sheet =
                    index === 0
                        ? workbook
                              .sheet(0)
                              .name(normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${driverName}`))
                        : workbook.addSheet(
                              normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${driverName}`)
                          );

                const dateStr = moment(index === 0 ? this.date : this.comparedDate).format(MONTH_YEAR_FORMAT);

                const headDescription = [[i18n.t('DriverDetailModule.titleCoach'), '', driverName, '', dateStr], []];
                let currentRow = 1;

                // head descripton
                sheet.range(currentRow, 1, currentRow, 7).style(centerStyle).style(boldStyle).value(headDescription);
                sheet.range(currentRow, 1, currentRow, 2).merged(true);
                sheet.range(currentRow, 3, currentRow, 4).merged(true);
                currentRow += 2;

                // Score
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverScoreStats.title')]]);
                currentRow += 1;

                sheet
                    .range(currentRow, 1, currentRow + 1, 9)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([
                        [
                            roundToStep(dataCurrent.overalScore ?? 0, 0.1),
                            i18n.t('DriverBehaviorTable.filters.driving'),
                            i18n.t('DriverBehaviorTable.filters.eco'),
                            i18n.t('DriverBehaviorTable.filters.wearTear'),
                            i18n.t('DriverBehaviorTable.filters.overal')
                        ],
                        [
                            '',
                            roundToStep(dataCurrent.driveScore ?? 0, 0.1),
                            roundToStep(dataCurrent.ecoScore ?? 0, 0.1),
                            roundToStep(dataCurrent.wearTearScore ?? 0, 0.1),
                            dataCurrent.rank ?? 1
                        ]
                    ]);
                sheet
                    .range(currentRow, 1, currentRow + 1, 1)
                    .style(shadowStyle)
                    .merged(true);
                sheet
                    .range(currentRow + 1, 2, currentRow + 1, 9)
                    .style(shadowStyle)
                    .style(normalStyle);
                currentRow += 4;

                // Recommends
                const recomments = this.getRecommendsData(data)?.slice(0, 3) ?? [];
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverBehavior.recomends.title')]]);
                currentRow += 1;

                recomments.forEach((r, index) => {
                    sheet
                        .range(currentRow + index, 1, currentRow + index, 4)
                        .style(centerStyle)
                        .style(boldStyle)
                        .style(shadowStyle)
                        .value([['', translateFormatedText(r.title), '', translateFormatedText(r.recommend)]]);
                    sheet
                        .range(currentRow + index, 2, currentRow + index, 3)
                        .style(wrapStyle)
                        .merged(true);
                    sheet
                        .range(currentRow + index, 4, currentRow + index, 9)
                        .style(wrapStyle)
                        .style(normalStyle)
                        .merged(true);
                });
                sheet.range(currentRow, 1, currentRow + recomments.length - 1, 1).merged(true);
                currentRow += recomments.length + 1;

                // Driven vehicles
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverBehaviorDetail.export.drivenVehicles')]]);
                currentRow += 1;

                data.vehicles?.forEach((v, index) => {
                    sheet
                        .range(currentRow, 2 + index, currentRow + 1, 2 + index)
                        .style(centerStyle)
                        .style(boldStyle)
                        .value([[`${i18n.t('DriverBehaviorDetail.export.vehicle')} ${index + 1}`], [v]]);
                    sheet.range(currentRow + 1, 2 + index, currentRow + 1, 2 + index).style(normalStyle);
                });
                sheet.range(currentRow + 1, 1, currentRow + 1, 9).style(shadowStyle);
                sheet
                    .range(currentRow, 1, currentRow + 1, 1)
                    .style(shadowStyle)
                    .merged(true);
                currentRow += 3;

                // Driving time
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverBehaviorDetail.export.drivingTime')]]);
                currentRow += 1;

                sheet
                    .range(currentRow, 1, currentRow + 1, 9)
                    .style(boldStyle)
                    .style(centerStyle)
                    .style(wrapStyle)
                    .value([
                        [
                            '[hh:mm:ss]',
                            i18n.t('DriverBehavior.timesGraph.drivingTime'),
                            i18n.t('DriverBehavior.timesGraph.idlingTime')
                        ],
                        [
                            '',
                            formatSecondsToString(getPerfectDriveValue('vGlobal.fg0TDrv', data.scores[0].detail) ?? 0),
                            formatSecondsToString(
                                getPerfectDriveValue('vGlobal.fg0TStayIncTStayPto', data.scores[0].detail) ?? 0
                            )
                        ]
                    ]);

                sheet
                    .range(currentRow + 1, 1, currentRow + 1, 9)
                    .style(shadowStyle)
                    .style(normalStyle);
                sheet
                    .range(currentRow, 1, currentRow + 1, 1)
                    .style(shadowStyle)
                    .style(normalStyle)
                    .style(bottomStyle)
                    .merged(true);
                currentRow += 3;

                // General data
                sheet
                    .range(currentRow, 1, currentRow, 1)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverBehaviorDetail.export.generalData')]]);
                currentRow += 1;

                sheet
                    .range(currentRow, 1, currentRow + 1, 9)
                    .style(boldStyle)
                    .style(centerStyle)
                    .style(wrapStyle)
                    .value([
                        [
                            '',
                            `${i18n.t('DriverBehavior.timesTable.distance')} [${i18n.t('common.km')}]`,
                            `${i18n.t('DriverBehavior.timesTable.fuelConsumption')} [${i18n.t(
                                'FuelConsumptionTable.liters'
                            )}]`,
                            `${i18n.t('DriverBehavior.timesTable.departures')} [-/100 ${i18n.t('common.km')}]`,
                            `${i18n.t('DriverBehavior.timesTable.averageAscent')} [m/100 ${i18n.t('common.km')}]`,
                            `${i18n.t('DriverBehavior.timesTable.averageConsumption')} [${L}/100 ${i18n.t(
                                'common.km'
                            )}]`,
                            `${i18n.t('DriverBehavior.timesTable.averageWeight')} [${i18n.t('common.metrics.tons')}]`
                        ],
                        [
                            '',
                            numeral(getPerfectDriveValue('vGlobal.fg0DDrv', data.scores[0].detail)).format('0,0.0'),
                            numeral(getPerfectDriveValue('vGlobal.fg0C', data.scores[0].detail, 0)).format('0,0.0'),
                            numeral(getPerfectDriveValue('vGlobal.fg0CntDpDDrv', data.scores[0].detail, 0)).format(
                                '0,0.0'
                            ),
                            numeral(getPerfectDriveValue('vGlobal.fg0AltIncrDDrv', data.scores[0].detail, 0)).format(
                                '0,0.0'
                            ),
                            numeral(getPerfectDriveValue('vGlobal.fg0CDrvDDrv', data.scores[0].detail, 0)).format(
                                '0,0.0'
                            ),
                            numeral(getPerfectDriveValue('vGlobal.fg0MAvg', data.scores[0].detail, 0)).format('0,0.0')
                        ]
                    ]);

                sheet
                    .range(currentRow + 1, 1, currentRow + 1, 9)
                    .style(shadowStyle)
                    .style(normalStyle);
                sheet
                    .range(currentRow, 1, currentRow + 1, 1)
                    .style(shadowStyle)
                    .style(normalStyle)
                    .merged(true);
                currentRow += 3;

                // Driving data
                currentRow =
                    writeTable(
                        sheet,
                        i18n.t('DriverBehaviorDetail.driveData.title'),
                        dataCoachMap.drive,
                        currentRow,
                        data,
                        5
                    ) + 1;

                // Eco data
                currentRow =
                    writeTable(
                        sheet,
                        i18n.t('DriverBehaviorDetail.ecoData.title'),
                        dataCoachMap.eco,
                        currentRow,
                        data,
                        5
                    ) + 1;

                // Wear tear data
                currentRow = writeTable(
                    sheet,
                    i18n.t('DriverBehaviorDetail.wearTearData.title'),
                    dataCoachMap.wearTear,
                    currentRow,
                    data,
                    3
                );

                [25, 25, 25, 25, 25, 25, 25, 25, 25].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;

        const data = this.getDetailData();
        downloadFile(
            blob,
            `${i18n.t('DriverDetailModule.title')} - ${formatDriverBehaviorName(
                data?.name,
                data?.surname,
                data?.tachocard
            )} ${moment(this.date).format(MONTH_YEAR_FORMAT)}.xlsx`
        );

        this.exportListProcessing.next(false);
        this.exportDetailProcessing.next(false);
    }

    async downloadDriverBehaviorListExport() {
        this.exportListProcessing.next(true);

        const improvement = (newValue: number, oldValue: number) => {
            if (oldValue === 0) {
                return 0;
            }
            return Math.round(((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100 * 10) / 10;
        };

        const workbook: Workbook = await fromBlankAsync();
        const sheetLeadBoard = workbook.sheet(0).name(normalizeExportSheetName(i18n.t('DriverListModule.title')));
        const sheetTopDrivers = workbook.addSheet(
            normalizeExportSheetName(i18n.t('DriverListModule.export.currentStats')),
            1
        );

        const allSheets = [sheetLeadBoard, sheetTopDrivers];

        const centerStyle = {
            verticalAlignment: 'center',
            horizontalAlignment: 'center'
        };

        const leftStyle = {
            horizontalAlignment: 'left'
        };

        const boldStyle = {
            bold: true
        };

        const borderTopStyle = {
            topBorder: true
        };

        const shadowStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            }
        };

        const wrapStyle = {
            wrapText: true
        };

        const dateStr = moment(this.date).format(MONTH_YEAR_FORMAT);
        allSheets.forEach((sheet, index) => {
            let currentRow = 1;
            if (index === 0) {
                const leaderBoardBody: any[][] = [];
                this._dataTrends?.forEach(driverBehavior => {
                    leaderBoardBody.push([
                        driverBehavior.rank,
                        driverBehavior.name,
                        roundToStep(driverBehavior.scores[0].score ?? 0, 1),
                        roundToStep(driverBehavior.scores[0].driveScore ?? 0, 1),
                        roundToStep(driverBehavior.scores[0].ecoScore ?? 0, 1),
                        roundToStep(driverBehavior.scores[0].wearTearScore ?? 0, 1)
                    ]);
                });

                sheet
                    .range(currentRow, 1, currentRow, 3)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverListModule.title'), '', dateStr]]);
                currentRow += 2;

                sheet
                    .range(currentRow, 1, currentRow, 6)
                    .value([
                        [
                            i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.name').toUpperCase(),
                            i18n.t('DriverBehaviorTable.filters.overal').toUpperCase(),
                            i18n.t('DriverBehaviorTable.filters.driving').toUpperCase(),
                            i18n.t('DriverBehaviorTable.filters.eco').toUpperCase(),
                            i18n.t('DriverBehaviorTable.filters.wearTear').toUpperCase()
                        ]
                    ])
                    .autoFilter()
                    .style(boldStyle)
                    .style(centerStyle)
                    .style(wrapStyle);

                sheet
                    .range(currentRow + 1, 1, currentRow + leaderBoardBody.length, 6)
                    .value(leaderBoardBody)
                    .style(shadowStyle)
                    .style(centerStyle);
                sheet.range(currentRow + 1, 2, currentRow + leaderBoardBody.length, 2).style(leftStyle);
                sheet.range(currentRow + 1, 1, currentRow + 1, 6).style(borderTopStyle);

                [15, 25, 25, 25, 25, 25].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            } else {
                const currentDate = moment().format(MONTH_YEAR_FORMAT);
                const driversBody: any[][] = [];
                this.selectTopDriversTrends(DriverLeaderboardTrendsTableFilter.Overal).forEach(driver => {
                    driversBody.push([
                        driver.rank,
                        driver.name,
                        roundToStep(driver.score, 0.1),
                        improvement(driver?.trend?.[0] ?? 0, driver?.trend?.[1] ?? 0)
                    ]);
                });
                this.selectTopDriversTrends(DriverLeaderboardTrendsTableFilter.Driving).forEach(driver => {
                    let data = driversBody.find(d => d[1] === driver.name);
                    data?.push(roundToStep(driver.score, 0.1));
                    data?.push(improvement(driver?.trend?.[0] ?? 0, driver?.trend?.[1] ?? 0));
                });
                this.selectTopDriversTrends(DriverLeaderboardTrendsTableFilter.Eco).forEach(driver => {
                    let data = driversBody.find(d => d[1] === driver.name);
                    data?.push(roundToStep(driver.score, 0.1));
                    data?.push(improvement(driver?.trend?.[0] ?? 0, driver?.trend?.[1] ?? 0));
                });
                this.selectTopDriversTrends(DriverLeaderboardTrendsTableFilter.WearTear).forEach(driver => {
                    let data = driversBody.find(d => d[1] === driver.name);
                    data?.push(roundToStep(driver.score, 0.1));
                    data?.push(improvement(driver?.trend?.[0] ?? 0, driver?.trend?.[1] ?? 0));
                });

                sheet
                    .range(currentRow, 1, currentRow, 3)
                    .style(centerStyle)
                    .style(boldStyle)
                    .value([[i18n.t('DriverListModule.export.currentStats'), '', currentDate]]);
                currentRow += 2;

                sheet
                    .range(currentRow, 1, currentRow, 4)
                    .style(centerStyle)
                    .style(boldStyle)
                    .style(wrapStyle)
                    .value([
                        [
                            '',
                            i18n.t('DriverListModule.export.myFleet').toUpperCase(),
                            i18n.t('DriverListModule.export.ewAverage').toUpperCase(),
                            i18n.t('DriverListModule.export.ewBestDriver').toUpperCase()
                        ]
                    ])
                    .autoFilter();

                const date = moment().format(DRIVER_BEHAVIOR_DATE_FORMAT);
                const current = this._dataContextual?.find(d => d.start === date);
                const previous = this._dataContextual?.find(
                    d => d.start === moment(date).subtract(1, 'month').format(DRIVER_BEHAVIOR_DATE_FORMAT)
                );

                sheet
                    .range(currentRow + 1, 1, currentRow + 2, 4)
                    .style(boldStyle)
                    .style(centerStyle)
                    .value([
                        [
                            i18n.t('DriverListModule.export.score').toUpperCase(),
                            roundToStep(current?.clientAvgDriverOveralScore ?? 0),
                            roundToStep(current?.crossSystemAvgDriverOveralScore ?? 0),
                            roundToStep(current?.crossSystemBestDriverOveralScore ?? 0)
                        ],
                        [
                            i18n.t('DriverListModule.export.improvement').toUpperCase(),
                            improvement(
                                current?.clientAvgDriverOveralScore ?? 0,
                                previous?.clientAvgDriverOveralScore ?? 0
                            ),
                            improvement(
                                current?.crossSystemAvgDriverOveralScore ?? 0,
                                previous?.crossSystemAvgDriverOveralScore ?? 0
                            ),
                            improvement(
                                current?.crossSystemBestDriverOveralScore ?? 0,
                                previous?.crossSystemBestDriverOveralScore ?? 0
                            )
                        ]
                    ]);
                sheet.range(currentRow + 1, 1, currentRow + 2, 1).style(leftStyle);
                sheet.range(currentRow + 1, 2, currentRow + 2, 4).style(shadowStyle);
                sheet.range(currentRow + 1, 2, currentRow + 1, 4).style(borderTopStyle);
                sheet.range(currentRow + 2, 2, currentRow + 2, 4).forEach((row: any) => {
                    row._row._cells.forEach((cell: any) => {
                        const val = cell.value();
                        if (!isNaN(parseFloat(val)) && parseFloat(val) !== 0) {
                            cell.style({ fontColor: parseFloat(val) > 0 ? '4caf50' : 'ff3823' });
                        }
                    });
                });
                currentRow += 4;

                sheet
                    .range(currentRow, 1, currentRow, 10)
                    .style(centerStyle)
                    .style(boldStyle)
                    .style(wrapStyle)
                    .value([
                        [
                            i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.name').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.score').toUpperCase(),
                            i18n.t('DriverListModule.export.scoreImprovement.overall').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.driveScore').toUpperCase(),
                            i18n.t('DriverListModule.export.scoreImprovement.driving').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.ecoScore').toUpperCase(),
                            i18n.t('DriverListModule.export.scoreImprovement.eco').toUpperCase(),
                            i18n.t('DriverBehaviorTable.cols.wearTearScore').toUpperCase(),
                            i18n.t('DriverListModule.export.scoreImprovement.wearTear').toUpperCase()
                        ]
                    ])
                    .autoFilter();

                sheet
                    .range(currentRow + 1, 1, currentRow + driversBody.length, 10)
                    .style(centerStyle)
                    .style(shadowStyle)
                    .value(driversBody)
                    .forEach((row: any) => {
                        row._row._cells.forEach((cell: any, index: number) => {
                            const val = cell.value();
                            if (index > 3 && index % 2 === 0 && !isNaN(parseFloat(val)) && parseFloat(val) !== 0) {
                                cell.style({ fontColor: parseFloat(val) > 0 ? '4caf50' : 'ff3823' });
                            }
                        });
                    });
                sheet.range(currentRow + 1, 2, currentRow + driversBody.length, 2).style(leftStyle);
                sheet.range(currentRow + 1, 2, currentRow + 1, 10).style(borderTopStyle);

                [15, 25, 25, 25, 25, 25, 25, 25, 25, 25].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            }
            sheet.range(1, 1, 1, 2).style(boldStyle).merged(true);
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('DriverListModule.title')} ${dateStr}.xlsx`);
        this.exportListProcessing.next(false);
    }

    private _toTruckModel(
        score: DriverBehaviourScore,
        totalDrivers: number,
        prevScore?: DriverBehaviourScore
    ): DriverBehaviorModel {
        return {
            id: score.id,
            rank: score.rank ?? 0,
            name: formatName(score.generalData.driver1.name, score.generalData.driver1.surname),
            driverId: score.generalData.driver1.id,
            score: score.overalScore / 20,
            oldScore: prevScore?.overalScore ? prevScore?.overalScore / 20 : undefined,
            driveScore: score.driveScore / 20,
            ecoScore: score.ecoScore / 20,
            wearTearScore: score.wearTearScore / 20,
            detail: this._toTruckDetail(score),
            prevDetail: prevScore && this._toTruckDetail(prevScore),
            totalDrivers,
            distance: {
                highway: score.driveData.highway.distance,
                city: score.driveData.city.distance,
                noCity: score.driveData.nocity.distance,
                other: score.driveData.other.distance,
                summary: score.driveData.summary.distance
            }
        };
    }

    private _toTruckDetail(score: DriverBehaviourScore): DriverBehaviorModelDetail {
        return {
            fullDistance: score.generalData.distance,
            driverTime: score.generalData.driveTime,
            avgSpeed: score.driveData.summary.avgSpeed,
            avgWeight: 7569,
            avgElevation: 13900,
            brakingDistance: 567,
            driverPredictability: 7990,
            distanceEcoroll: 230,
            consumptionRPM: 34.3,
            rpmOptimum: 1200,
            consumption: score.generalData.consumption,
            engineOnTime: score.generalData.engineOnTime,
            idlingTime: score.generalData.idlingTime,
            cruiseControlTime: score.driveData.summary.cruiseControlTime,
            cntTakeoff: score.driveData.summary.cntTakeoff,
            idleConsumption: score.savingData.idleConsumption,
            rpmOverTime: score.savingData.rpmOverTime,
            constantAccelerationTime: score.savingData.constantAccelerationTime,
            driveTime85: score.savingData.driveTime85,
            driveTimeWithoutConsumptionWithEcoroll: score.savingData.driveTimeWithoutConsumptionWithEcoroll,
            accelerationTimeWithCruiseControl: score.savingData.accelerationTimeWithCruiseControl,
            kickdownTime: score.savingData.kickdownTime,
            maxRPN: score.savingData.maxRpm,
            avgParkingBreakCount: Math.round(score.wearData.avgParkingBreakCount),
            avgRetarderCount: Math.round(score.wearData.avgRetarderCount),
            avgServiceBrakeCount: Math.round(score.wearData.avgServiceBrakeCount),
            serviceBrakeVsRetarderPercentage: score.wearData.serviceBrakeVsRetarderPercentage,
            retarderTime: score.wearData.retarderTime,
            distanceWithRetarder: score.wearData.distanceWithRetarder,
            distanceWithServiceBrake: score.wearData.distanceWithServiceBrake,
            maxSpeedWithParkingBrake: score.wearData.maxSpeedWithParkingBrake,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        };
    }

    private _toDetailReportModel(
        driverId: number,
        detailData: PerfectDrivePeriod,
        item?: PerfectDriveDriverDetailModel
    ) {
        const driver = detailData.driverData?.find(d => d.driver.id === driverId);
        if (item) {
            item.history?.push({
                month: detailData.month,
                numberOfDrivers: detailData.numberOfDrivers ?? 1,
                overalScore: driver?.overalScore,
                driveScore: driver?.driveScore,
                ecoScore: driver?.ecoScore,
                wearTearScore: driver?.wearTearScore,
                rank: driver?.rank ?? 1,
                detail: {
                    ...driver?.pdGroupByDriver,
                    pgnCounters: this._transformCounters(driver?.pdGroupByDriver.pgnCounters)
                } as PerfectDriveGroupedByDriver
            });
            return item;
        } else {
            return {
                id: String(driver!.driver.id),
                name: driver!.driver.name,
                surname: driver!.driver.surname,
                tachocard: driver!.driver.tachoCard,
                vehicles:
                    driver!.pdGroupByDriver.idcar && this._vehicles
                        ? this._vehicles
                              .filter(v => driver!.pdGroupByDriver.idcar.includes(Number(v.id)))
                              .map(d => d.registrationNumber)
                        : [],
                history: [
                    {
                        month: detailData.month,
                        numberOfDrivers: detailData.numberOfDrivers ?? 1,
                        overalScore: driver?.overalScore,
                        driveScore: driver?.driveScore,
                        ecoScore: driver?.ecoScore,
                        wearTearScore: driver?.wearTearScore,
                        rank: driver?.rank ?? 1,
                        detail: {
                            ...driver?.pdGroupByDriver,
                            pgnCounters: this._transformCounters(driver?.pdGroupByDriver.pgnCounters)
                        }
                    }
                ],
                scores: [],
                stars: {
                    overalScore: '',
                    driveScore: '',
                    ecoScore: '',
                    wearTearScore: ''
                }
            } as PerfectDriveDriverDetailModel;
        }
    }

    private _transformCounters(structure?: object) {
        function isStruct(value: any): value is any {
            return typeof value === 'object' && Object.keys(value).some((key: string) => typeof key !== 'number');
        }

        let result = {};
        for (let key in structure) {
            const name = snakeCaseToCamelCase(key);
            if (isStruct(structure[key])) {
                result[name] = this._transformCounters(structure[key]);
            } else {
                result[name] = structure[key];
            }
        }

        return result;
    }

    async setDriverData(comparedData: boolean = false) {
        const date = this.date;
        const driverId = comparedData ? this.comparedDriverId : this.selectedDriverId;

        await this.setDetailReportDataByType(moment(date).startOf('month').toISOString(), driverId, 11, comparedData);
    }

    async setDetailReportDataByType(dateFrom: string, driverId?: string, history?: number, comparedData?: boolean) {
        this.detailDataLoading.next(true);
        try {
            if (comparedData !== true) {
                await this.loadPerfectDriverData(dateFrom, history ?? 11);
            }
            const driver = this._dataPerfectDrive?.find(d => d.id === driverId);
            const dates = [
                moment(comparedData ? this.comparedDate : this.date).format(DRIVER_BEHAVIOR_DATE_FORMAT),
                moment(comparedData ? this.comparedDate : this.date)
                    .add(-1, 'months')
                    .format(DRIVER_BEHAVIOR_DATE_FORMAT)
            ];

            const data = driver
                ? {
                      ...driver,
                      scores: driver.history.filter(h => dates.includes(h.month))
                  }
                : undefined;

            const comparedDriver = this._drivers?.find(driver => driver.id === driverId);
            const comparedDriverEmptyData = {
                id: driverId ?? '',
                name: comparedDriver?.name,
                surname: comparedDriver?.surname,
                tachocard: '',
                vehicles: [],
                history: [],
                scores: [],
                stars: {
                    overalScore: '',
                    driveScore: '',
                    ecoScore: '',
                    wearTearScore: ''
                }
            } as PerfectDriveDriverDetailModel;

            if (comparedData) {
                this.setDetailComparedData(data ?? comparedDriverEmptyData);
            } else {
                this.setDetailData(data);
                this.loadRecommends();
            }
        } catch (err) {
            this.error.next(err);
            this.loading.next(false);
        }

        this.detailDataLoading.next(false);
    }

    setDrivers = (drivers: DriverModel[]) => {
        this._drivers = drivers ?? [];
    };

    setVehicles = (vehicles?: ReadOnlyMonitoredObjectFeSb[]) => {
        this._vehicles = vehicles;
    };

    formatCoachData(
        timeKey?: string,
        percentageKey?: string,
        ratingKey?: string,
        data?: PerfectDriveGroupedByDriver,
        noTime: boolean = false
    ) {
        let time = '';
        if (noTime) {
            time = timeKey ? numeral(getPerfectDriveValue(timeKey, data, 0) ?? 0).format('0,0') : '';
        } else {
            time = timeKey ? formatSecondsToString(Math.round(getPerfectDriveValue(timeKey, data, 0) ?? 0)) : '';
        }

        const percentage = percentageKey
            ? ` / ${numeral(getPerfectDriveValue(percentageKey, data, 0)).format('0,0')}%`
            : '';
        const rating = ratingKey ? ` / ${numeral(getPerfectDriveValue(ratingKey, data, 0)).format('0,0')}` : '';

        return `${time}${percentage}${rating}`;
    }

    getCoachDataMap = (): { [key: string]: DriverBehaviorCoachDataMap[] } => {
        return {
            drive: [
                {
                    key: 'mileage',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1DDrv',
                        country: 'drivingMode.drivingModeReportType2.fd1DDrv',
                        urban: 'drivingMode.drivingModeReportType3.fd1DDrv',
                        other: 'drivingMode.drivingModeReportType4.fd1DDrv',
                        summary: 'drivingMode.drivingModeReportSummary.fd1DDrv',
                        exclamation: 'fd1DDrv'
                    },
                    title: '{{DriverBehaviorDetail.options.general.fullDistance}}',
                    icon: DriverBehaviorCoachIcons.milleage,
                    unit: 'km'
                },
                {
                    key: 'drivingTime',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1TDrv',
                        country: 'drivingMode.drivingModeReportType2.fd1TDrv',
                        urban: 'drivingMode.drivingModeReportType3.fd1TDrv',
                        other: 'drivingMode.drivingModeReportType4.fd1TDrv',
                        summary: 'drivingMode.drivingModeReportSummary.fd1TDrv',
                        exclamation: 'fd1TDrv'
                    },
                    title: '{{DriverBehaviorDetail.options.general.driverTime}}',
                    icon: DriverBehaviorCoachIcons.drivingTime,
                    unit: 'duration'
                },
                {
                    key: 'averageElevation',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1AltIncrDDrv',
                        country: 'drivingMode.drivingModeReportType2.fd1AltIncrDDrv',
                        urban: 'drivingMode.drivingModeReportType3.fd1AltIncrDDrv',
                        other: 'drivingMode.drivingModeReportType4.fd1AltIncrDDrv',
                        summary: 'drivingMode.drivingModeReportSummary.fd1AltIncrDDrv',
                        exclamation: 'fd1AltIncrDDrv'
                    },
                    title: '{{DriverBehaviorDetail.options.general.avgElevation}}',
                    icon: DriverBehaviorCoachIcons.elevation,
                    unit: 'mPerKm'
                },
                // {
                //     key: 'idlingTimeLongerThan2Minutes',
                //     title: '{{DriverBehaviorDetail.options.general.idlingTimeLonger}}',
                //     info: '{{DriverBehaviorDetail.driveData.infoText.idlingTimeLonger}}',
                //     postfix: ', % {{DriverBehaviorDetail.options.general.idlingTimeLongerPostfix}}',
                //     recomend: '{{DriverBehaviorDetail.driveData.recomends.idlingTimeLonger}}',
                //     icon: DriverBehaviorCoachIcons.idling,
                //     unit: 'duration'
                // },
                {
                    key: 'idlingTime',
                    values: {
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'vGlobal.fg0TStayIdle',
                                'vGlobal.fg0TStayIdleTREng',
                                undefined,
                                data
                            );
                        },
                        exclamation: 'fg0TStayIdle|fg0TStayIdleTREng'
                    },
                    title: '{{DriverBehaviorDetail.options.general.idlingTime}}',
                    info: '{{DriverBehaviorDetail.driveData.infoText.idlingTime}}',
                    unit: 'duration',
                    recommend: '{{DriverBehaviorDetail.driveData.recomends.idlingTimeLonger}}',
                    recommendKey: 'pt0TStayIdleTREng',
                    icon: DriverBehaviorCoachIcons.idling,
                    postfix: ', % {{DriverBehaviorDetail.options.general.idlingTimePostfix}}',
                    starWeight: 1
                },
                {
                    key: 'drivingInMotionWithCruiseControlRatio',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1TDrvCcTREng',
                        country: 'drivingMode.drivingModeReportType2.fd1TDrvCcTREng',
                        urban: 'drivingMode.drivingModeReportType3.fd1TDrvCcTREng',
                        other: 'drivingMode.drivingModeReportType4.fd1TDrvCcTREng',
                        summary: 'drivingMode.drivingModeReportSummary.fd1TDrvCcTREng',
                        exclamation: 'fd1TDrvCcTREng'
                    },
                    title: '{{DriverBehaviorDetail.options.general.drivingMotion}}',
                    unit: 'percentage',
                    maxValue: 100
                },
                {
                    key: 'drivingInMotionWithCruiseControl',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1TDrvCc',
                        country: 'drivingMode.drivingModeReportType2.fd1TDrvCc',
                        urban: 'drivingMode.drivingModeReportType3.fd1TDrvCc',
                        other: 'drivingMode.drivingModeReportType4.fd1TDrvCc',
                        summary: 'drivingMode.drivingModeReportSummary.fd1TDrvCc',
                        exclamation: 'fd1TDrvCc'
                    },
                    title: '{{DriverBehaviorDetail.options.general.drivingMotion}}',
                    unit: 'duration'
                },
                {
                    key: 'ratioOfTimeSpentInDrivingMode',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1SpendInDrivingMode',
                        country: 'drivingMode.drivingModeReportType2.fd1SpendInDrivingMode',
                        urban: 'drivingMode.drivingModeReportType3.fd1SpendInDrivingMode',
                        other: 'drivingMode.drivingModeReportType4.fd1SpendInDrivingMode',
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return (
                                (getPerfectDriveValue(
                                    'drivingMode.drivingModeReportType1.fd1SpendInDrivingMode',
                                    data
                                ) ?? 0) +
                                (getPerfectDriveValue(
                                    'drivingMode.drivingModeReportType2.fd1SpendInDrivingMode',
                                    data
                                ) ?? 0) +
                                (getPerfectDriveValue(
                                    'drivingMode.drivingModeReportType3.fd1SpendInDrivingMode',
                                    data
                                ) ?? 0) +
                                (getPerfectDriveValue(
                                    'drivingMode.drivingModeReportType4.fd1SpendInDrivingMode',
                                    data
                                ) ?? 0)
                            );
                        },
                        exclamation: 'fd1SpendInDrivingMode'
                    },
                    title: '{{DriverBehaviorDetail.options.general.ratioTimeMode}}',
                    unit: 'percentage',
                    diminisher: 100
                },
                {
                    key: 'timeOfEngineBeingSwitchedOn',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1TREng',
                        country: 'drivingMode.drivingModeReportType2.fd1TREng',
                        urban: 'drivingMode.drivingModeReportType3.fd1TREng',
                        other: 'drivingMode.drivingModeReportType4.fd1TREng',
                        summary: 'drivingMode.drivingModeReportSummary.fd1TREng',
                        exclamation: 'fd1TREng'
                    },
                    title: '{{DriverBehaviorDetail.options.general.engineOnTime}}',
                    unit: 'duration'
                },
                // {
                //     key: 'idlingTime30',
                //     title: '{{DriverBehaviorDetail.options.general.idlingTime30}}',
                //     unit: 'count'
                // },
                // {
                //     key: 'idlingTime60',
                //     title: '{{DriverBehaviorDetail.options.general.idlingTime60}}',
                //     unit: 'count'
                // },
                {
                    key: 'takeOffs',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1CntDp',
                        country: 'drivingMode.drivingModeReportType2.fd1CntDp',
                        urban: 'drivingMode.drivingModeReportType3.fd1CntDp',
                        other: 'drivingMode.drivingModeReportType4.fd1CntDp',
                        summary: 'drivingMode.drivingModeReportSummary.fd1CntDp',
                        exclamation: 'fd1CntDp'
                    },
                    title: '{{DriverBehaviorDetail.options.general.cntTakeoff}}',
                    info: '{{DriverBehaviorDetail.driveData.infoText.cntTakeoff}}',
                    icon: DriverBehaviorCoachIcons.takeoff,
                    unit: 'count'
                },
                {
                    key: 'averageSpeed',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1DDrvTDrv',
                        country: 'drivingMode.drivingModeReportType2.fd1DDrvTDrv',
                        urban: 'drivingMode.drivingModeReportType3.fd1DDrvTDrv',
                        other: 'drivingMode.drivingModeReportType4.fd1DDrvTDrv',
                        summary: 'drivingMode.drivingModeReportSummary.fd1DDrvTDrv',
                        exclamation: 'fd1DDrvTDrv'
                    },
                    title: '{{DriverBehaviorDetail.options.general.avgSpeed}}',
                    icon: DriverBehaviorCoachIcons.averageSpeed,
                    unit: 'kmPerHour'
                },
                {
                    key: 'brakingDistance',
                    values: {
                        highway: 'drivingMode.drivingModeReportType1.fd1DDrvRet20DDrvBrDDrvParkBr',
                        country: 'drivingMode.drivingModeReportType2.fd1DDrvRet20DDrvBrDDrvParkBr',
                        urban: 'drivingMode.drivingModeReportType3.fd1DDrvRet20DDrvBrDDrvParkBr',
                        other: 'drivingMode.drivingModeReportType4.fd1DDrvRet20DDrvBrDDrvParkBr',
                        summary: 'drivingMode.drivingModeReportSummary.fd1DDrvRet20DDrvBrDDrvParkBr',
                        exclamation: 'fd1DDrvRet20DDrvBrDDrvParkBr'
                    },
                    title: '{{DriverBehaviorDetail.options.general.brakingDistance}}',
                    unit: 'distance'
                }
            ],
            eco: [
                {
                    key: 'accelerationTimeWithCruiseControl',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvAccConst',
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvAccConstTREng',
                                'fuelConsumption.fuelConsumptionReportType1.rc1TDrvAccConstTREng',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvAccConst',
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvAccConstTREng',
                                'fuelConsumption.fuelConsumptionReportType2.rc1TDrvAccConstTREng',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvAccConst',
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvAccConstTREng',
                                'fuelConsumption.fuelConsumptionReportType3.rc1TDrvAccConstTREng',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvAccConst',
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvAccConstTREng',
                                'fuelConsumption.fuelConsumptionReportType4.rc1TDrvAccConstTREng',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvAccConst',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvAccConstTREng',
                                'totalRanking.pt0TDrvAccConstTREng',
                                data
                            );
                        },
                        exclamation: 'fc1TDrvAccConst|fc1TDrvAccConstTREng|rc1TDrvAccConstTREng'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.timeAcceleration}}',
                    postfix: ', % {{DriverBehaviorDetail.ecoData.timeAccelerationPostfix}}',
                    recommendKey: 'pt0TDrvAccConstTREng',
                    recommend: '{{DriverBehaviorDetail.ecoData.recomends.timeAcceleration}}',
                    icon: DriverBehaviorCoachIcons.acceleration,
                    unit: 'duration',
                    starWeight: 3
                },
                {
                    key: 'driveTimeWithoutConsumptionWithScoroll',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvWoCTEcoroll',
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvWoCTEcorollTDrv',
                                'fuelConsumption.fuelConsumptionReportType1.rc1TDrvWoCTEcorollTDrv',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvWoCTEcoroll',
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvWoCTEcorollTDrv',
                                'fuelConsumption.fuelConsumptionReportType2.rc1TDrvWoCTEcorollTDrv',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvWoCTEcoroll',
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvWoCTEcorollTDrv',
                                'fuelConsumption.fuelConsumptionReportType3.rc1TDrvWoCTEcorollTDrv',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvWoCTEcoroll',
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvWoCTEcorollTDrv',
                                'fuelConsumption.fuelConsumptionReportType4.rc1TDrvWoCTEcorollTDrv',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvWoCTEcoroll',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvWoCTEcorollTDrv',
                                'totalRanking.pt0TDrvWoCTEcorollTDrv',
                                data
                            );
                        },
                        exclamation: 'fc1TDrvWoCTEcoroll|fc1TDrvWoCTEcorollTDrv|rc1TDrvWoCTEcorollTDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.drivingTimeConsumption}}',
                    info: '{{DriverBehaviorDetail.ecoData.infoText.driveTimeWithoutConsumptionWithScoroll}}',
                    postfix: ', % {{DriverBehaviorDetail.ecoData.drivingTimeConsumptionPostfix}}, {{common.rating}}',
                    recommendKey: 'pt0TDrvWoCTEcorollTDrv',
                    recommend: '{{DriverBehaviorDetail.ecoData.recomends.drivingTimeConsumption}}',
                    icon: DriverBehaviorCoachIcons.dtEcoroll,
                    unit: 'duration',
                    starWeight: 2
                },
                {
                    key: 'driveTime85',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvLoad86',
                                'fuelConsumption.fuelConsumptionReportType1.fc1TDrvLoad86TREng',
                                'fuelConsumption.fuelConsumptionReportType1.rc1TDrvLoad86TREng',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvLoad86',
                                'fuelConsumption.fuelConsumptionReportType2.fc1TDrvLoad86TREng',
                                'fuelConsumption.fuelConsumptionReportType2.rc1TDrvLoad86TREng',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvLoad86',
                                'fuelConsumption.fuelConsumptionReportType3.fc1TDrvLoad86TREng',
                                'fuelConsumption.fuelConsumptionReportType3.rc1TDrvLoad86TREng',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvLoad86',
                                'fuelConsumption.fuelConsumptionReportType4.fc1TDrvLoad86TREng',
                                'fuelConsumption.fuelConsumptionReportType4.rc1TDrvLoad86TREng',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvLoad86',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvLoad86TREng',
                                'totalRanking.pt0TDrvLoad86TREng',
                                data
                            );
                        },
                        exclamation: 'fc1TDrvLoad86|fc1TDrvLoad86TREng|rc1TDrvLoad86TREng'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.drivingTimeSpeed}}',
                    info: '{{DriverBehaviorDetail.ecoData.infoText.drivingTimeSpeed}}',
                    postfix: ', % {{DriverBehaviorDetail.ecoData.drivingTimeSpeedPostfix}}, {{common.rating}}',
                    recommendKey: 'pt0TDrvLoad86TREng',
                    recommend: '{{DriverBehaviorDetail.ecoData.recomends.drivingTimeSpeed}}',
                    icon: DriverBehaviorCoachIcons.dtOver85,
                    unit: 'duration',
                    starWeight: 2
                },
                {
                    key: 'predictabilityTime',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TAvgAccBr',
                                undefined,
                                'fuelConsumption.fuelConsumptionReportType1.rc1TAvgAccBr',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TAvgAccBr',
                                undefined,
                                'fuelConsumption.fuelConsumptionReportType2.rc1TAvgAccBr',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TAvgAccBr',
                                undefined,
                                'fuelConsumption.fuelConsumptionReportType3.rc1TAvgAccBr',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TAvgAccBr',
                                undefined,
                                'fuelConsumption.fuelConsumptionReportType4.rc1TAvgAccBr',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TAvgAccBr',
                                undefined,
                                'totalRanking.pt0TAvgAccBr',
                                data
                            );
                        },
                        exclamation: 'fc1TAvgAccBr|rc1TAvgAccBr'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.driverPredictability}}',
                    info: '{{DriverBehaviorDetail.ecoData.infoText.driverPredictability}}',
                    postfix: ', {{common.rating}}',
                    recommendKey: 'pt0TAvgAccBr',
                    recommend: '{{DriverBehaviorDetail.ecoData.recomends.driverPredictability}}',
                    icon: DriverBehaviorCoachIcons.predict,
                    unit: 'duration',
                    starWeight: 2
                },
                {
                    key: 'rpmOverTime',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TRDrvLoadOverOpt',
                                'fuelConsumption.fuelConsumptionReportType1.fc1TRDrvLoadOverOptTREng',
                                'fuelConsumption.fuelConsumptionReportType1.rc1TRDrvLoadOverOptTREng',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TRDrvLoadOverOpt',
                                'fuelConsumption.fuelConsumptionReportType2.fc1TRDrvLoadOverOptTREng',
                                'fuelConsumption.fuelConsumptionReportType2.rc1TRDrvLoadOverOptTREng',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TRDrvLoadOverOpt',
                                'fuelConsumption.fuelConsumptionReportType3.fc1TRDrvLoadOverOptTREng',
                                'fuelConsumption.fuelConsumptionReportType3.rc1TRDrvLoadOverOptTREng',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TRDrvLoadOverOpt',
                                'fuelConsumption.fuelConsumptionReportType4.fc1TRDrvLoadOverOptTREng',
                                'fuelConsumption.fuelConsumptionReportType4.rc1TRDrvLoadOverOptTREng',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TRDrvLoadOverOpt',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TRDrvLoadOverOptTREng',
                                'totalRanking.pt0TRDrvLoadOverOptTREng',
                                data
                            );
                        },
                        exclamation: 'fc1TRDrvLoadOverOpt|fc1TRDrvLoadOverOptTREng|rc1TRDrvLoadOverOptTREng'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.rpmOptimum}}',
                    postfix: ', {{DriverBehaviorDetail.ecoData.rpmOptimumPostfix}}',
                    recommendKey: 'pt0TRDrvLoadOverOptTREng',
                    recommend: '{{DriverBehaviorDetail.ecoData.recomends.rpmOptimum}}',
                    icon: DriverBehaviorCoachIcons.speeding,
                    unit: 'duration',
                    starWeight: 1
                },
                {
                    key: 'ecoAvgConsumption',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1CDrvDDrv',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1CDrvDDrv',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1CDrvDDrv',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1CDrvDDrv',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1CDrvDDrv',
                        exclamation: 'fc1CDrvDDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.avgConsumption}}',
                    unit: 'litresPerKm'
                },
                {
                    key: 'avgConsumptionWithoutPto',
                    values: {
                        summary: 'fuelConsumption.fuelConsumptionGlobal.fc0CCStayIncCStayPtoDDrv',
                        exclamation: 'fc0CCStayIncCStayPtoDDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.avgConsumptionPTO}}',
                    unit: 'litresPerKm',
                    decimals: 1
                },
                {
                    key: 'driveConsumption',
                    values: {
                        summary: 'fuelConsumption.fuelConsumptionGlobal.fc0CDrv',
                        exclamation: 'fc0CDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.fuelConsumedDriving}}',
                    unit: 'litres',
                    decimals: 1
                },
                {
                    key: 'idleConsumption',
                    values: {
                        summary: 'fuelConsumption.fuelConsumptionGlobal.fc0CStayIdle',
                        exclamation: 'fc0CStayIdle'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.fuelConsumedIdling}}',
                    unit: 'litres',
                    decimals: 1
                },
                {
                    key: 'increasedRpmConsumptionIdle',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1CStayIncCStayPto',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1CStayIncCStayPto',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1CStayIncCStayPto',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1CStayIncCStayPto',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1CStayIncCStayPto',
                        exclamation: 'fc1CStayIncCStayPto'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.consumptionRPM}}',
                    unit: 'litres',
                    decimals: 1
                },
                {
                    key: 'averageIdlingTimeBeforeTakeOff',
                    values: {
                        summary: 'fuelConsumption.fuelConsumptionGlobal.fc0TStayIdleCntDp',
                        exclamation: 'fc0TStayIdleCntDp'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.abgIdleTime}}',
                    unit: 'duration'
                },
                {
                    key: 'acceleratorPedal',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1TAccCc',
                                'fuelConsumption.fuelConsumptionReportType1.fc1TAccCcTDrv',
                                undefined,
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1TAccCc',
                                'fuelConsumption.fuelConsumptionReportType2.fc1TAccCcTDrv',
                                undefined,
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1TAccCc',
                                'fuelConsumption.fuelConsumptionReportType3.fc1TAccCcTDrv',
                                undefined,
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1TAccCc',
                                'fuelConsumption.fuelConsumptionReportType4.fc1TAccCcTDrv',
                                undefined,
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TAccCc',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1TAccCcTDrv',
                                undefined,
                                data
                            );
                        },
                        exclamation: 'fc1TAccCc|fc1TAccCcTDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.acceleratorPedal}}',
                    postfix: ', % {{DriverBehaviorDetail.ecoData.timeAccelerationPostfix}}',
                    unit: 'duration'
                },
                {
                    key: 'avgConsumptionWithCruiseControl',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1CDrvCcDDrvCc',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1CDrvCcDDrvCc',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1CDrvCcDDrvCc',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1CDrvCcDDrvCc',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1CDrvCcDDrvCc',
                        exclamation: 'fc1CDrvCcDDrvCc'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.avgConsumptionActive}}',
                    unit: 'litresPerKm',
                    decimals: 1
                },
                {
                    key: 'avgSpeedWithCruiseControl',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1DDrvTDrvCc',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1DDrvTDrvCc',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1DDrvTDrvCc',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1DDrvTDrvCc',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1DDrvTDrvCc',
                        exclamation: 'fc1DDrvTDrvCc'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.avgSpeedCruise}}',
                    unit: 'kmPerHour'
                },
                {
                    key: 'distanceEcoroll',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType1.fc1DEcoroll',
                                'fuelConsumption.fuelConsumptionReportType1.fc1DEcorollDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType2.fc1DEcoroll',
                                'fuelConsumption.fuelConsumptionReportType2.fc1DEcorollDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType3.fc1DEcoroll',
                                'fuelConsumption.fuelConsumptionReportType3.fc1DEcorollDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportType4.fc1DEcoroll',
                                'fuelConsumption.fuelConsumptionReportType4.fc1DEcorollDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'fuelConsumption.fuelConsumptionReportSummary.fc1DEcoroll',
                                'fuelConsumption.fuelConsumptionReportSummary.fc1DEcorollDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        exclamation: 'fc1DEcoroll|fc1DEcorollDDrv'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.distanceEcoroll}}',
                    unit: 'km',
                    postfix: ', % {{DriverBehaviorDetail.ecoData.distanceEcorollPostfix}}'
                },
                {
                    key: 'kickdownTime',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1TDrvKd',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1TDrvKd',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1TDrvKd',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1TDrvKd',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvKd',
                        exclamation: 'fc1TDrvKd'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.drivingTime}}',
                    info: '{{DriverBehaviorDetail.ecoData.infoText.drivingTime}}',
                    unit: 'duration'
                },
                {
                    key: 'ecoAvgRPM',
                    values: {
                        highway: 'fuelConsumption.fuelConsumptionReportType1.fc1RAvgLoad',
                        country: 'fuelConsumption.fuelConsumptionReportType2.fc1RAvgLoad',
                        urban: 'fuelConsumption.fuelConsumptionReportType3.fc1RAvgLoad',
                        other: 'fuelConsumption.fuelConsumptionReportType4.fc1RAvgLoad',
                        summary: 'fuelConsumption.fuelConsumptionReportSummary.fc1RAvgLoad',
                        exclamation: 'fc1RAvgLoad'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.avgRPM}}',
                    unit: 'rpm'
                },
                {
                    key: 'maxRpm',
                    values: {
                        highway: 'wear.wearReportType1.fw1RMaxDrvLoad',
                        country: 'wear.wearReportType2.fw1RMaxDrvLoad',
                        urban: 'wear.wearReportType3.fw1RMaxDrvLoad',
                        other: 'wear.wearReportType4.fw1RMaxDrvLoad',
                        summary: 'wear.wearReportSummary.fw1RMaxDrvLoad',
                        exclamation: 'fw1RMaxDrvLoad'
                    },
                    title: '{{DriverBehaviorDetail.ecoData.maxRPM}}',
                    unit: 'rpm'
                }
            ],
            wearTear: [
                {
                    key: 'distanceWithRetarderPercentage',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType1.fw1TDrvBrRet20',
                                'wear.wearReportType1.fw1TDrvBrRet20TDrv',
                                'wear.wearReportType1.rw1TDrvBrRet20TDrv',
                                data
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType2.fw1TDrvBrRet20',
                                'wear.wearReportType2.fw1TDrvBrRet20TDrv',
                                'wear.wearReportType2.rw1TDrvBrRet20TDrv',
                                data
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType3.fw1TDrvBrRet20',
                                'wear.wearReportType3.fw1TDrvBrRet20TDrv',
                                'wear.wearReportType3.rw1TDrvBrRet20TDrv',
                                data
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType4.fw1TDrvBrRet20',
                                'wear.wearReportType4.fw1TDrvBrRet20TDrv',
                                'wear.wearReportType4.rw1TDrvBrRet20TDrv',
                                data
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportSummary.fw1TDrvBrRet20',
                                'wear.wearReportSummary.fw1TDrvBrRet20TDrv',
                                'totalRanking.pt0TDrvBrRet20TDrv',
                                data
                            );
                        },
                        exclamation: 'fw1TDrvBrRet20|fw1TDrvBrRet20TDrv|rw1TDrvBrRet20TDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.brakingDistance}}',
                    postfix: ', % {{DriverBehaviorDetail.wearTearData.brakingDistancePostfix}}, {{common.rating}}',
                    recommendKey: 'pt0TDrvBrRet20TDrv',
                    recommend: '{{DriverBehaviorDetail.wearTearData.recomends.brakingDistance}}',
                    icon: DriverBehaviorCoachIcons.engineBreak,
                    unit: 'duration',
                    starWeight: 3
                },
                {
                    key: 'brakingUsingServiceBrakeFromWholeBrakingDistance',
                    values: {
                        highway: 'wear.wearReportType1.rw1DDrvBrDDrv',
                        country: 'wear.wearReportType2.rw1DDrvBrDDrv',
                        urban: 'wear.wearReportType3.rw1DDrvBrDDrv',
                        other: 'wear.wearReportType4.rw1DDrvBrDDrv',
                        summary: 'totalRanking.pt0DDrvBrDDrv',
                        exclamation: 'rw1DDrvBrDDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.brakingService}}',
                    postfix: ' [{{common.rating}}]',
                    recommendKey: 'pt0DDrvBrDDrv',
                    recommend: '{{DriverBehaviorDetail.wearTearData.recomends.brakingService}}',
                    icon: DriverBehaviorCoachIcons.breaking,
                    starWeight: 2
                },
                {
                    key: 'avgServiceBrakeCount',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType1.fw1CntBrDDrv',
                                undefined,
                                'wear.wearReportType1.rw1CntBrDDrv',
                                data,
                                true
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType2.fw1CntBrDDrv',
                                undefined,
                                'wear.wearReportType2.rw1CntBrDDrv',
                                data,
                                true
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType3.fw1CntBrDDrv',
                                undefined,
                                'wear.wearReportType3.rw1CntBrDDrv',
                                data,
                                true
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType4.fw1CntBrDDrv',
                                undefined,
                                'wear.wearReportType4.rw1CntBrDDrv',
                                data,
                                true
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportSummary.fw1CntBrDDrv',
                                undefined,
                                'totalRanking.pt0CntBrDDrv',
                                data,
                                true
                            );
                        },
                        exclamation: 'fw1CntBrDDrv|rw1CntBrDDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.avgService}}',
                    postfix: ', {{common.rating}}',
                    recommendKey: 'pt0CntBrDDrv',
                    recommend: '{{DriverBehaviorDetail.wearTearData.recomends.avgService}}',
                    icon: DriverBehaviorCoachIcons.breakPer100,
                    unit: 'countPerKm',
                    starWeight: 1
                },
                {
                    key: 'serviceBrakeCount',
                    values: {
                        highway: 'wear.wearReportType1.fw1CntRetExhCntRetLnCntRetEngDDrv',
                        country: 'wear.wearReportType2.fw1CntRetExhCntRetLnCntRetEngDDrv',
                        urban: 'wear.wearReportType3.fw1CntRetExhCntRetLnCntRetEngDDrv',
                        other: 'wear.wearReportType4.fw1CntRetExhCntRetLnCntRetEngDDrv',
                        summary: 'wear.wearReportSummary.fw1CntRetExhCntRetLnCntRetEngDDrv',
                        exclamation: 'fw1CntRetExhCntRetLnCntRetEngDDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.engineBreak}}',
                    unit: 'countPerKm'
                },
                {
                    key: 'wearDistanceTravelled',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType1.fw1DDrvRet20',
                                'wear.wearReportType1.fw1DDrvRet20DDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType2.fw1DDrvRet20',
                                'wear.wearReportType2.fw1DDrvRet20DDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType3.fw1DDrvRet20',
                                'wear.wearReportType3.fw1DDrvRet20DDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType4.fw1DDrvRet20',
                                'wear.wearReportType4.fw1DDrvRet20DDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportSummary.fw1DDrvRet20',
                                'wear.wearReportSummary.fw1DDrvRet20DDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        exclamation: 'fw1DDrvRet20|fw1DDrvRet20DDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.distanceTravelled}}',
                    unit: 'km',
                    postfix: ', % {{DriverBehaviorDetail.wearTearData.distanceTravelledPostfix}}'
                },
                {
                    key: 'maxRPM',
                    values: {
                        highway: 'wear.wearReportType1.fw1RMaxRet',
                        country: 'wear.wearReportType2.fw1RMaxRet',
                        urban: 'wear.wearReportType3.fw1RMaxRet',
                        other: 'wear.wearReportType4.fw1RMaxRet',
                        summary: 'wear.wearReportSummary.fw1RMaxRet',
                        exclamation: 'fw1RMaxRet'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.maxRPM}}',
                    unit: 'rpm'
                },
                {
                    key: 'distanceWithServiceBrakePercentage',
                    values: {
                        highway: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType1.fw1DDrvBr',
                                'wear.wearReportType1.fw1DDrvBrDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        country: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType2.fw1DDrvBr',
                                'wear.wearReportType2.fw1DDrvBrDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        urban: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType3.fw1DDrvBr',
                                'wear.wearReportType3.fw1DDrvBrDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        other: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportType4.fw1DDrvBr',
                                'wear.wearReportType4.fw1DDrvBrDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        summary: (data?: PerfectDriveGroupedByDriver) => {
                            return this.formatCoachData(
                                'wear.wearReportSummary.fw1DDrvBr',
                                'wear.wearReportSummary.fw1DDrvBrDDrv',
                                undefined,
                                data,
                                true
                            );
                        },
                        exclamation: 'fw1DDrvBr|fw1DDrvBrDDrv'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.distanceTravelledService}}',
                    unit: 'km',
                    postfix: ', % {{DriverBehaviorDetail.wearTearData.distanceTravelledServicePostfix}}'
                },
                {
                    key: 'wearRatioService',
                    values: {
                        highway: 'wear.wearReportType1.fw1DDrvBrDDrvRet20',
                        country: 'wear.wearReportType2.fw1DDrvBrDDrvRet20',
                        urban: 'wear.wearReportType3.fw1DDrvBrDDrvRet20',
                        other: 'wear.wearReportType4.fw1DDrvBrDDrvRet20',
                        summary: 'wear.wearReportSummary.fw1DDrvBrDDrvRet20',
                        exclamation: 'fw1DDrvBrDDrvRet20'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.ratioService}}',
                    info: '{{DriverBehaviorDetail.wearTearData.infoText.ratioService}}',
                    unit: 'ratio'
                },
                {
                    key: 'parkingBrakeCount',
                    values: {
                        summary: 'wear.wearGlobal.fw0CntDrvParkBr',
                        exclamation: 'fw0CntDrvParkBr'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.parkingBreak}}',
                    info: '{{DriverBehaviorDetail.wearTearData.infoText.parkingBreak}}',
                    unit: 'count'
                },
                {
                    key: 'maxSpeedWithParkingBrake',
                    values: {
                        summary: 'wear.wearGlobal.fw0VMaxDrvParkBr',
                        exclamation: 'fw0VMaxDrvParkBr'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.maxSpeed}}',
                    info: '{{DriverBehaviorDetail.wearTearData.infoText.maxSpeed}}',
                    unit: 'kmPerHour'
                },
                {
                    key: 'wearDistanceTravelledParking',
                    values: {
                        highway: 'wear.wearReportType1.fw1DDrvParkBr',
                        country: 'wear.wearReportType2.fw1DDrvParkBr',
                        urban: 'wear.wearReportType3.fw1DDrvParkBr',
                        other: 'wear.wearReportType4.fw1DDrvParkBr',
                        summary: 'wear.wearReportSummary.fw1DDrvParkBr',
                        exclamation: 'fw1DDrvParkBr'
                    },
                    title: '{{DriverBehaviorDetail.wearTearData.distanceTravelledParking}}',
                    unit: 'm'
                }
            ]
        };
    };

    getGeneralDataMap = (): DriverBehaviorDataMap => {
        return {
            driveData: [
                {
                    key: 'distance',
                    value: 'vGlobal.fg0DDrv',
                    title: '{{DriverBehaviorDetail.options.general.fullDistance}}',
                    unit: 'distance',
                    icon: DriverBehaviorIcons.distance,
                    trendForIncrease: TrendChange.NEUTRAL,
                    fixed: 1
                },
                {
                    key: 'driveTime',
                    value: 'vGlobal.fg0TDrv',
                    title: '{{DriverBehaviorDetail.options.general.driverTime}}',
                    unit: 'durationTime',
                    diminisher: 3600,
                    icon: DriverBehaviorIcons.timing,
                    trendForIncrease: TrendChange.NEUTRAL,
                    fixed: 0
                },
                {
                    key: 'drivingInMotionWithCruiseControl',
                    value: 'drivingMode.drivingModeReportSummary.fd1TDrvCcTREng',
                    title: '{{DriverBehaviorDetail.options.general.cruiseControlTime}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.cruiseControlTine,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'brakingDistance',
                    value: 'drivingMode.drivingModeReportSummary.fd1DDrvRet20DDrvBrDDrvParkBr',
                    title: '{{DriverBehaviorDetail.options.general.brakingDistance}}',
                    unit: 'distance',
                    icon: DriverBehaviorIcons.brakingDistance,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 1
                },
                {
                    key: 'averageElevation',
                    value: 'vGlobal.fg0AltIncrDDrv',
                    title: '{{DriverBehaviorDetail.options.general.avgElevation}}',
                    unit: 'mPer100Km',
                    icon: DriverBehaviorIcons.avgElevation,
                    trendForIncrease: TrendChange.NEUTRAL,
                    fixed: 1
                },
                {
                    key: 'averageWeight',
                    value: 'vGlobal.fg0MAvg',
                    title: '{{DriverBehaviorDetail.options.general.avgWeight}}',
                    unit: 'tonnes',
                    unitName: '{{common.metrics.tonnes}}',
                    icon: DriverBehaviorIcons.avgWeight,
                    trendForIncrease: TrendChange.NEUTRAL,
                    fixed: 1
                },
                {
                    key: 'averageSpeed',
                    value: 'drivingMode.drivingModeReportSummary.fd1DDrvTDrv',
                    title: '{{DriverBehaviorDetail.options.general.avgSpeed}}',
                    unit: 'kmPerHour',
                    icon: DriverBehaviorIcons.speed,
                    trendForIncrease: TrendChange.NEUTRAL,
                    fixed: 1
                },
                {
                    key: 'numberOfTakeOffs',
                    value: 'drivingMode.drivingModeReportSummary.fd1CntDp',
                    title: '{{DriverBehaviorDetail.options.general.cntTakeoff}}',
                    icon: DriverBehaviorIcons.cntTakeOff,
                    unitName: '{{common.count}}',
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                }
            ],
            ecoData: [
                {
                    key: 'avgConsumption',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1CDrvDDrv',
                    title: '{{DriverBehaviorDetail.ecoData.avgConsumption}}',
                    unit: 'litresPerKm',
                    icon: DriverBehaviorIcons.fuelPistolAlt,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 1
                },
                {
                    key: 'timeOfConstantAccelerationPedalWithActiveCruiseControl',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1TAccCcTDrv',
                    title: '{{DriverBehaviorDetail.options.general.constantAccelerationTime}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.acceleration,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                },
                {
                    key: 'driveTimeWithoutFuelConsumptionOrEcorollMode',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvWoCTEcorollTDrv',
                    title: '{{DriverBehaviorDetail.options.general.driveTimeWithoutConsumptionWithEcoroll}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.driveTimeWithoutConsumptionWithEcoroll,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'drivingTimeInTractionAtSpeedHigherThan85',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1TDrvLoad86TREng',
                    title: '{{DriverBehaviorDetail.options.general.driveTime85}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.truckingTime,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                },
                {
                    key: 'driversPredictability',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1TAvgAccBr',
                    title: '{{DriverBehaviorDetail.ecoData.driverPredictability}}',
                    unit: 'durationTime',
                    diminisher: 3600,
                    icon: DriverBehaviorIcons.driverPredictability,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'rpmOverOptimum',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1TRDrvLoadOverOptTREng', //
                    title: '{{DriverBehaviorDetail.ecoData.rpmOptimum}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.rpmOptimum,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                },
                {
                    key: 'distanceInEcorollMode',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1DEcorollDDrv',
                    title: '{{DriverBehaviorDetail.ecoData.distanceEcoroll}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.distanceEcoroll,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'consumptionAtStandStillWithIncreasedRPM',
                    value: 'fuelConsumption.fuelConsumptionReportSummary.fc1CStayIncCStayPto',
                    title: '{{DriverBehaviorDetail.ecoData.consumptionRPM}}',
                    unit: 'litres',
                    unitName: '{{common.metrics.liters}}',
                    icon: DriverBehaviorIcons.consumptionRPM,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 1
                }
            ],
            wearTearData: [
                {
                    key: 'brakingDistanceUsingEngineBrakeFromWholeBrakingDistance',
                    value: 'wear.wearReportSummary.fw1TDrvBrRet20TDrv',
                    title: '{{DriverBehaviorDetail.wearTearData.brakingDistance}}',
                    unit: 'percentage',
                    fixed: 0,
                    icon: DriverBehaviorIcons.wtdBrakingDistance,
                    trendForIncrease: TrendChange.POSITIVE
                },
                {
                    key: 'brakingUsingServiceBrakeFromWholeBrakingDistance',
                    value: 'totalRanking.pt0DDrvBrDDrv',
                    title: '{{DriverBehaviorDetail.wearTearData.brakingService}}',
                    unit: 'ratio',
                    unitName: '{{common.rating}}',
                    icon: DriverBehaviorIcons.wtdBrakingService,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'averageServiceBrakeActivationsPer100km',
                    value: 'wear.wearReportSummary.fw1CntBrDDrv',
                    title: '{{DriverBehaviorDetail.wearTearData.avgService}} {{common.metrics.per100km}}',
                    unit: 'count',
                    unitName: '{{common.count}}/100km',
                    icon: DriverBehaviorIcons.wtdAvgService,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 1
                },
                {
                    key: 'numberOfParkingBrakeActivationsWhileDriving',
                    value: 'wear.wearGlobal.fw0CntDrvParkBr',
                    title: '{{DriverBehaviorDetail.wearTearData.parkingBreak}}',
                    unit: 'count',
                    unitName: '{{common.count}}',
                    icon: DriverBehaviorIcons.wtdParkingBreak,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                },
                {
                    key: 'maximumSpeedWhenUsingParkingBrake',
                    value: 'wear.wearGlobal.fw0VMaxDrvParkBr',
                    title: '{{DriverBehaviorDetail.wearTearData.maxSpeed}}',
                    unit: 'kmPerHour',
                    icon: DriverBehaviorIcons.wtdMaxSpeed,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 1
                },
                {
                    key: 'numberOfEngineBrakeApplicationsPer100km',
                    value: 'wear.wearReportSummary.fw1CntRetExhCntRetLnCntRetEngDDrv',
                    title: '{{DriverBehaviorDetail.wearTearData.engineBreak}} {{common.metrics.per100km}}',
                    unit: 'count',
                    unitName: '{{common.count}}/100km',
                    icon: DriverBehaviorIcons.wtdEngineBrake,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 1
                },
                {
                    key: 'distanceTravelledWhenUsingEngineBrake',
                    value: 'wear.wearReportSummary.fw1DDrvRet20DDrv',
                    title: '{{DriverBehaviorDetail.wearTearData.distanceTravelled}}',
                    unit: 'percentage',
                    icon: DriverBehaviorIcons.wtdDistanceTravelled,
                    trendForIncrease: TrendChange.POSITIVE,
                    fixed: 0
                },
                {
                    key: 'maximumRpmWhenUsingEngineBrake',
                    value: 'wear.wearReportSummary.fw1RMaxRet',
                    title: '{{DriverBehaviorDetail.wearTearData.maxRPM}}',
                    unit: 'rpm',
                    unitName: '{{common.metrics.rpm}}',
                    icon: DriverBehaviorIcons.wtdMaxRPM,
                    trendForIncrease: TrendChange.NEGATIVE,
                    fixed: 0
                }
            ]
        };
    };

    getRecommendsData(detailData?: PerfectDriveDriverDetailModel) {
        if (detailData) {
            let recommends: any[] = [];
            const map = this.getCoachDataMap();
            for (let key in map) {
                map[key].forEach(rec => {
                    if (rec.recommendKey) {
                        recommends.push({
                            value:
                                getPerfectDriveValue(
                                    `totalRanking.${rec.recommendKey}`,
                                    detailData?.scores?.[0].detail
                                ) ?? undefined,
                            weight:
                                getPerfectDriveValue(
                                    `totalRanking.${rec.recommendKey.replace('pt0', 'zt0')}`,
                                    detailData?.scores?.[0].detail
                                ) ?? -1,
                            map: rec
                        });
                    }
                });
            }

            return recommends
                .sort((a, b) => ((5 - a.value) * a.weight > (5 - b.value) * b.weight ? -1 : 1))
                .map(rec => rec.map);
        } else {
            return undefined;
        }
    }

    loadRecommends() {
        this._recommends = this.getRecommendsData(this._detailData);
    }

    async loadPerfectDriverData(date: string, history?: number) {
        this.date = date;
        this.loading.next(true);

        const month = this.getDate()
            ? moment(this.getDate()).format(DRIVER_BEHAVIOR_DATE_FORMAT)
            : moment().format(DRIVER_BEHAVIOR_DATE_FORMAT);
        let dates = [month];
        if (history) {
            for (let i = 0; i < history; i++) {
                dates.push(
                    moment(month)
                        .add((i + 1) * -1, 'months')
                        .format(DRIVER_BEHAVIOR_DATE_FORMAT)
                );
            }
        }

        let filtered: PerfectDrivePeriod[] = [];
        if (this._logic.demo().isActive) {
            const data = this._getDemoPerfectDriveReportData();
            filtered = data
                .sort((a, b) => (moment(a.month).isBefore(b.month) ? 1 : -1))
                .filter(pdp => dates.includes(pdp.month));
        } else {
            try {
                filtered = await this._logic
                    .api()
                    .perfectDriveApi.perfectDriveReportV1DriverbehaviourPerfectDriveReportGet({
                        month,
                        history: history ?? 0
                    });
                filtered.sort((a, b) => (moment(a.month).isBefore(b.month) ? 1 : -1));
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        let dataDrivers: PerfectDriveDriverDetailModel[] = [];
        if (filtered) {
            for (let i = 0; i < filtered.length; i++) {
                filtered[i].driverData?.forEach(d => {
                    if (d.driver.id) {
                        const item = dataDrivers.find(dd => dd.id === String(d.driver.id));
                        if (item) {
                            this._toDetailReportModel(d.driver.id, filtered[i], item);
                        } else {
                            dataDrivers.push(this._toDetailReportModel(d.driver.id, filtered[i]));
                        }
                    }
                });
            }
        }

        this._dataPerfectDrive = dataDrivers;

        if (this._dataPerfectDrive) {
            let scoresStar = {
                overalScore: {
                    value: 0,
                    id: ''
                },
                driveScore: {
                    value: 0,
                    id: ''
                },
                ecoScore: {
                    value: 0,
                    id: ''
                },
                wearTearScore: {
                    value: 0,
                    id: ''
                }
            };
            const fields = ['overalScore', 'driveScore', 'ecoScore', 'wearTearScore'];
            this._dataPerfectDrive?.forEach(dpd => {
                dates.forEach(d => {
                    const score = dpd.history?.find(s => s.month === d);
                    if (score === undefined) {
                        dpd.history.push({
                            month: d,
                            numberOfDrivers: 0,
                            overalScore: undefined,
                            driveScore: undefined,
                            ecoScore: undefined,
                            wearTearScore: undefined,
                            rank: undefined,
                            detail: undefined
                        });
                        if (d === dates[0]) {
                            dpd.vehicles = undefined;
                        }
                    } else if (d === month) {
                        for (let i = 0; i < fields.length; i++) {
                            if (scoresStar[fields[i]].value < (score?.[fields[i]] ?? 0)) {
                                scoresStar[fields[i]] = {
                                    value: score?.[fields[i]] ?? 0,
                                    id: dpd.id
                                };
                            }
                        }
                    }
                });
                dpd.history?.sort((a, b) => (moment(a.month).isBefore(b.month) ? 1 : -1));
            });

            this._dataPerfectDrive?.forEach(pd => {
                for (let i = 0; i < fields.length; i++) {
                    pd.stars[fields[i]] = scoresStar[fields[i]].id;
                }
            });
        }

        this.dataPerfectDrive.next(this._dataPerfectDrive ?? []);
        this.loading.next(false);
        return this._dataPerfectDrive;
    }

    async loadPerfectDriveScoreData(date?: string, tachocard?: string) {
        this.date = date;
        this.loading.next(true);

        const month = moment(this.getDate()).format(DRIVER_BEHAVIOR_DATE_FORMAT);
        if (this._logic.demo().isActive) {
            const data = this._getDemoPerfectDriveScoreData(tachocard);
            this._dataTrends = data
                ?.find(scores => scores.month === month)
                ?.score?.map((score, i) => ({
                    id: i.toString(),
                    name: formatDriverBehaviorName(score.driver.name, score.driver.surname, score.driver.tachoCard),
                    driverId: score.driver.id?.toString(),
                    rank: score.rank ?? 0,
                    tachocard: score.driver.tachoCard,
                    totalDrivers: 0,
                    scores: [
                        {
                            start: month,
                            score: score.overalScore,
                            driveScore: score.driveScore,
                            ecoScore: score.ecoScore,
                            wearTearScore: score.wearTearScore
                        }
                    ]
                }))
                .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));
        } else {
            try {
                const data = await this.getPerfectDriveScore(month, undefined, tachocard);
                this._dataTrends = data
                    ?.find(scores => scores.month === month)
                    ?.score?.map((score, i) => ({
                        id: i.toString(),
                        name: formatDriverBehaviorName(score.driver.name, score.driver.surname, score.driver.tachoCard),
                        driverId: score.driver.id?.toString(),
                        rank: score.rank,
                        tachocard: score.driver.tachoCard,
                        totalDrivers: 0,
                        scores: [
                            {
                                start: month,
                                score: score.overalScore,
                                driveScore: score.driveScore,
                                ecoScore: score.ecoScore,
                                wearTearScore: score.wearTearScore
                            }
                        ]
                    }))
                    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this.dataTrends.next(this._dataTrends ?? []);
        this.loading.next(false);
        return this._dataTrends;
    }

    async loadCurrentScoreData() {
        this.loading.next(true);

        const month = moment().format(DRIVER_BEHAVIOR_DATE_FORMAT);
        const monthPrev = moment().add(-1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);
        if (this._logic.demo().isActive) {
            const data = this._getDemoPerfectDriveScoreData();
            this._currentDataTrends = data
                ?.find(scores => scores.month === month)
                ?.score?.map((score, i) => ({
                    id: i.toString(),
                    name: formatDriverBehaviorName(score.driver?.name, score.driver?.surname, score.driver.tachoCard),
                    driverId: score.driver.id?.toString(),
                    rank: score.rank ?? 0,
                    tachocard: score.driver.tachoCard,
                    totalDrivers: 0,
                    scores: [
                        {
                            start: month,
                            score: score.overalScore,
                            driveScore: score.driveScore,
                            ecoScore: score.ecoScore,
                            wearTearScore: score.wearTearScore
                        }
                    ]
                }));
            data?.find(scores => scores.month === monthPrev)?.score?.forEach(score => {
                this._currentDataTrends
                    ?.find(trend => trend.driverId === score.driver.id)
                    ?.scores.push({
                        start: monthPrev,
                        score: score.overalScore,
                        driveScore: score.driveScore,
                        ecoScore: score.ecoScore,
                        wearTearScore: score.wearTearScore
                    });
            });
            await this.loadContextualScoreData();
        } else {
            try {
                const data = await this.getPerfectDriveScore(month, 1);
                this._currentDataTrends = data
                    ?.find(scores => scores.month === month)
                    ?.score?.map((score, i) => ({
                        id: i.toString(),
                        name: formatDriverBehaviorName(score.driver.name, score.driver.surname, score.driver.tachoCard),
                        driverId: score.driver.id?.toString(),
                        rank: score.rank,
                        tachocard: score.driver.tachoCard,
                        totalDrivers: 0,
                        scores: [
                            {
                                start: month,
                                score: score.overalScore,
                                driveScore: score.driveScore,
                                ecoScore: score.ecoScore,
                                wearTearScore: score.wearTearScore
                            }
                        ]
                    }));
                data?.find(scores => scores.month === monthPrev)?.score?.forEach(score => {
                    this._currentDataTrends
                        ?.find(trend => trend.driverId === score.driver.id)
                        ?.scores.push({
                            start: monthPrev,
                            score: score.overalScore,
                            driveScore: score.driveScore,
                            ecoScore: score.ecoScore,
                            wearTearScore: score.wearTearScore
                        });
                });
                await this.loadContextualScoreData();
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this.loading.next(false);
    }

    async loadContextualScoreData() {
        const month = moment().format(DRIVER_BEHAVIOR_DATE_FORMAT);
        const monthPrev = moment().add(-1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);

        let data: PerfectDriveScorePeriod[] = [];
        if (this._logic.demo().isActive) {
            data = this._getDemoPerfectDriveScoreData();
            this._dataContextual = this._logic.demo().data.perfectDriveContextualData.map(contextualData => {
                return {
                    clientAvgDriverOveralScore: contextualData.clientAvgDriverOveralScore,
                    crossSystemAvgDriverOveralScore: contextualData.crossSystemAvgDriverOveralScore,
                    crossSystemBestDriverOveralScore: contextualData.crossSystemBestDriverOveralScore,
                    start: contextualData.month
                } as DriverBehaviourContextualPeriod;
            });
        } else {
            try {
                data = await this.getPerfectDriveScore(month, 1);
                const dataContextual = await this.getContextualScore(month);
                this._dataContextual = dataContextual?.map((contextualData: PerfectDriveScoreContextualPeriod) => {
                    return {
                        clientAvgDriverOveralScore: contextualData.clientAvgDriverOveralScore,
                        crossSystemAvgDriverOveralScore: contextualData.crossSystemAvgDriverOveralScore,
                        crossSystemBestDriverOveralScore: contextualData.crossSystemBestDriverOveralScore,
                        start: contextualData.month
                    } as DriverBehaviourContextualPeriod;
                });
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this._currentDataTrends = data
            ?.find(scores => scores.month === month)
            ?.score?.map((score, i) => {
                const prev = data
                    ?.find(trend => trend.month === monthPrev)
                    ?.score?.find(s => s.driver.id === score.driver.id);
                return {
                    id: i.toString(),
                    name: formatDriverBehaviorName(score.driver.name, score.driver.surname, score.driver.tachoCard),
                    driverId: score.driver.id?.toString(),
                    rank: score.rank ?? 0,
                    tachocard: score.driver.tachoCard,
                    totalDrivers: 0,
                    scores:
                        prev === undefined
                            ? [
                                  {
                                      start: month,
                                      score: score.overalScore,
                                      driveScore: score.driveScore,
                                      ecoScore: score.ecoScore,
                                      wearTearScore: score.wearTearScore
                                  },
                                  {
                                      start: monthPrev,
                                      score: undefined,
                                      driveScore: undefined,
                                      ecoScore: undefined,
                                      wearTearScore: undefined
                                  }
                              ]
                            : [
                                  {
                                      start: month,
                                      score: score.overalScore,
                                      driveScore: score.driveScore,
                                      ecoScore: score.ecoScore,
                                      wearTearScore: score.wearTearScore
                                  },
                                  {
                                      start: monthPrev,
                                      score: prev.overalScore,
                                      driveScore: prev.driveScore,
                                      ecoScore: prev.ecoScore,
                                      wearTearScore: prev.wearTearScore
                                  }
                              ]
                };
            });
    }

    showPromo() {
        return !this._logic.auth().roles().includes(Role.DBH_R);
    }

    showJourneyActivityPromo() {
        return false;
        // return (
        //     !this._logic.auth().roles().includes(Role.DBH_R) &&
        //     !this._logic.settings().getProp('statistics').journeysActivity.disabledCoachPromo
        // );
    }

    _getDemoPerfectDriveScoreData(tachocard?: string) {
        if (!this.perfectDriveScoreData) {
            const prevMonth = moment().add(-1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);
            this.perfectDriveScoreData = this._logic.demo().data.perfectDriveScoreData.slice();
            this.perfectDriveScoreData.find(d => d.month === prevMonth)?.score?.splice(1, 1);
        }
        if (tachocard) {
            const perfectDriveScoreData = cloneDeep(this.perfectDriveScoreData);
            perfectDriveScoreData.forEach(scoreData => {
                scoreData.score = scoreData.score?.filter(d => d.driver.tachoCard === tachocard);
            });
            return perfectDriveScoreData;
        }
        return this.perfectDriveScoreData;
    }

    _getDemoPerfectDriveReportData() {
        if (!this.perfectDriveReportData) {
            this.perfectDriveReportData = this._logic.demo().data.perfectDriveReportData.slice();
            this.perfectDriveReportData?.[1].driverData?.splice(1, 1);
        }
        return this.perfectDriveReportData ?? [];
    }

    saveJourneyActivityPromoSettings(disabledCoachPromo: boolean) {
        const oldSettings = this._logic.settings().getProp('statistics');
        this._logic.settings().setProp('statistics', {
            ...oldSettings,
            journeysActivity: {
                ...oldSettings.journeysActivity,
                disabledCoachPromo: disabledCoachPromo
            }
        });
    }

    saveDateToSettings(date?: string) {
        this._logic.settings().setProp('driverBehavior', {
            date: date
        });
    }

    async getContextualScore(month: string) {
        return await this._logic
            .api()
            .perfectDriveApi.getPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGet({
                month
            });
    }

    async getPerfectDriveScore(month: string, history?: number, tachocard?: string) {
        const old = !this._logic.conf.settings.driverBehaviorCoachAvailable;
        return await this._logic.api().perfectDriveApi.perfectDriveScoreV1DriverbehaviourPerfectDriveScoreGet({
            month,
            history,
            tachocard,
            old
        });
    }
}
