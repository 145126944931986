import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { ControlPanel } from './MapControlsBar';
import { Displayed, DataSourceTypes } from '../MapModule';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { Button } from 'common/components';
import PoiCard from 'common/components/PoiCard';
import qa from 'qa-selectors';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';

interface Props extends WithTranslation {
    data: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        washers: PoiModelMap[];
        loading: boolean;
    };
    controlOnRoute?: 'edit' | 'preview';
    onWashCardClick?: (wash: PoiModelMap) => void;
    onAddToTransportClick?: (data: PoiMarkerData) => void;
    onConfirmClick?: () => void;
    onBarLabel?: (panel: ControlPanel) => void;
}

interface State {
    subOptionsData?: DataSourceTypes;
}

class ControlsWash extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            subOptionsData: DataSourceTypes.EUROWAG
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="map-controls t-card">
                <div className="wash-controls t-inverse t-padding-small">
                    <div className="map-controls-bubble main">
                        <div className="setting-card">
                            <Row justify="space-between" align="middle">
                                <Col>
                                    <label>{t('PoiControls.carWash')}</label>
                                </Col>
                                <Col className="heading">
                                    <Button
                                        qa={
                                            this.props.data.optionsData.doNotDisplay
                                                ? qa.mapControls.showWashersButton
                                                : qa.mapControls.hideWashersButton
                                        }
                                        type="primary"
                                        size="middle"
                                        onClick={this._onConfirmClick}
                                    >
                                        {this.props.data.optionsData.doNotDisplay
                                            ? t('common.showOnMap')
                                            : t('common.hide')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="wash-controls-stations t-medium">
                        {this.props.data.washers?.map((p, index) => (
                            <PoiCard
                                type="wash"
                                data={p}
                                key={p.id}
                                index={index}
                                controlOnRoute={this.props.controlOnRoute}
                                onAddToRouteClick={this.props.onAddToTransportClick}
                                onClick={this.props.onWashCardClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    private _onConfirmClick = (): void => {
        this.props.onConfirmClick?.();
    };
}

export default withTranslation()(ControlsWash);
