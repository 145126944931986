/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelInfo,
    ChannelInfoFromJSON,
    ChannelInfoFromJSONTyped,
    ChannelInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChannelInfoResponse
 */
export interface ChannelInfoResponse {
    /**
     * 
     * @type {ChannelInfo}
     * @memberof ChannelInfoResponse
     */
    channelInfo: ChannelInfo;
}

export function ChannelInfoResponseFromJSON(json: any): ChannelInfoResponse {
    return ChannelInfoResponseFromJSONTyped(json, false);
}

export function ChannelInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelInfo': ChannelInfoFromJSON(json['channelInfo']),
    };
}

export function ChannelInfoResponseToJSON(value?: ChannelInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelInfo': ChannelInfoToJSON(value.channelInfo),
    };
}


