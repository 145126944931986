import { PaginatedResponse } from 'common/model/pagination';
import { ReadOnlyWhiteLabel, ReadOnlyWhiteLabelTemplate } from 'generated/new-main';
import { Logic } from 'logic/logic';

export interface WhitelabelTemplateModel {
    id: string;
    name: string;
    client?: number;
    content: WhitelabelSettings;
    logo?: string;
}

export interface WhitelabelModel {
    id: string;
    template: string;
    client?: number;
    content?: WhitelabelSettings;
}

export interface WhitelabelSettings {
    'tm-primary'?: string;
    'tm-primary-contrast'?: string;
    'tm-secondary'?: string;
    'tm-secondary-contrast'?: string;
    invert?: string;
    text?: string;
    'text-hightlighted'?: string;
    'text-hover'?: string;
    bg?: string;
    'bg-block'?: string;
    border?: string;
    title?: string;
    'panel-bg'?: string;
    'navbar-bg'?: string;
    'navbar-submenu-bg'?: string;
    'navbar-text'?: string;
    'navbar-text-hover'?: string;
    'navbar-sub-bg'?: string;
    'navbar-clock-text'?: string;
    'navbar-distancer'?: string;
    'sidebar-text'?: string;
    'sidebar-bg'?: string;
    'sidebar-hover'?: string;
    scroll?: string;
    'scroll-thumb'?: string;
    'scroll-thumb-hover'?: string;
    'table-text'?: string;
    'table-selected-text'?: string;
    'table-bg-even'?: string;
    'table-bg-odd'?: string;
    'table-hover-bg'?: string;
    'table-selected-bg'?: string;
    'table-selected-detail-bg'?: string;
    'table-border'?: string;
    'table-border-horizontal'?: string;
    'table-selected-border'?: string;
    'table-head-text'?: string;
    'table-head-highlight-text'?: string;
    'table-head-bg'?: string;
    'table-head-border'?: string;
    'table-head-sub-bg'?: string;
    'table-head-sub-border'?: string;
    'table-button-hover-bg'?: string;
    'table-tag-text'?: string;
    'table-tag-bg'?: string;
    'bar-input-text'?: string;
    'bar-popup-bg'?: string;
    'bar-popup-text'?: string;
    'bar-popup-border'?: string;
    'bar-btn-text'?: string;
    'bar-btn-bg'?: string;
    'bar-btn-border'?: string;
    'bar-btn-hover-bg'?: string;
    'bar-btn-hover-border'?: string;
    'button-text'?: string;
    'button-bg'?: string;
    'button-text-hover'?: string;
    'button-bg-hover'?: string;
    'filter-text'?: string;
    'sidepanel-text'?: string;
    'dispatcher-board-text'?: string;
    'dispatcher-board-container-bg'?: string;
    'dispatcher-board-bg'?: string;
    'dispatcher-board-completed-opacity'?: string;
    'dispatcher-board-head-bg'?: string;
    'dispatcher-board-head-sub-bg'?: string;
    'dispatcher-board-head-active-text'?: string;
    'dispatcher-board-transport-active-bg'?: string;
    'dispatcher-board-transport-inactive-bg'?: string;
    'dispatcher-board-transport-border'?: string;
    'dispatcher-board-border'?: string;
    'route-overview-new-text'?: string;
    'route-overview-delayed-text'?: string;
    'route-overview-active-text'?: string;
    'route-overview-completed-text'?: string;
    'tracking-table-head-bg'?: string;
    'statistics-costs-label-bg'?: string;
    'aetr-highlighted-text'?: string;
    'aetr-highlighted-bg-1'?: string;
    'aetr-highlighted-bg-2'?: string;
    'journeys-input-bg'?: string;
    'planner-profile-values-text'?: string;
    'planner-profile-values-bg'?: string;
    'poi-card-text'?: string;
    'driver-behavior-bg'?: string;
    'journeys-selected-text'?: string;
    'route-infobox-bg'?: string;
    'sidebar-img-invert'?: string;
    'planner-body-bg'?: string;
    'planner-th-heading'?: string;
    'planner-bg'?: string;
    'planner-group-bg'?: string;
    'planner-route-text'?: string;
    'planner-route-bg'?: string;
    summary?: string;
    'form-bg'?: string;
    'form-block-bg'?: string;
    'form-input-bg'?: string;
    'tag-text'?: string;
    'tag-bg'?: string;
    'loader-loading-wrap-bg'?: string;
    'loader-processing-wrap-bg'?: string;
    'progress-bg'?: string;
    'progress-success'?: string;
    'progress-warning'?: string;
    'progress-danger'?: string;
}

export class PartnerWhitelabelLogic {
    constructor(private _logic: Logic) {}

    async getWhitelabelTemplateList(
        clientId?: number,
        limit = 1e3,
        offset = 0
    ): Promise<PaginatedResponse<WhitelabelTemplateModel[]>> {
        try {
            const response = await this._logic.api().whitelabelTemplateApi.whiteLabelTemplateList({
                // clientId: clientId ?? this._logic.auth().client()?.id,
                limit,
                offset
            });
            return {
                data: response.results.map(res => this._toWhitelabelTemplate(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Whitelabel list GET err:', err);
            throw err;
        }
    }

    async getWhitelabelList(clientId?: number, limit = 1e3, offset = 0): Promise<PaginatedResponse<WhitelabelModel[]>> {
        try {
            const response = await this._logic.api().whitelabelApi.whiteLabelList({
                // clientId: clientId ?? this._logic.auth().client()?.id,
                limit,
                offset
            });
            return {
                data: response.results.map(res => this._toWhitelabel(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Whitelabel list GET err:', err);
            throw err;
        }
    }

    async getWhitelabel(clientId?: number, limit = 1e3, offset = 0): Promise<WhitelabelModel[]> {
        try {
            const templates = await this._logic.api().whitelabelTemplateApi.whiteLabelTemplateList({
                limit,
                offset
            });
            const whitelabelList = await this._logic.api().whitelabelApi.whiteLabelList({
                limit,
                offset
            });
            const models = whitelabelList.results.map(w =>
                this._toWhitelabel(w, templates.results.find(t => t.id === w.template)?.content)
            );
            return models;
        } catch (err) {
            console.log('Whitelabel GET err:', err);
            throw err;
        }
    }

    async addWhitelabelTemplate(whitelabel: WhitelabelTemplateModel): Promise<WhitelabelTemplateModel> {
        try {
            const clientId = this._logic.auth().client()?.id;
            const response = await this._logic.api().whitelabelTemplateApi.whiteLabelTemplateCreate({
                data: {
                    name: whitelabel.name,
                    content: whitelabel.content,
                    client: clientId
                }
            });
            return this._toWhitelabelTemplate(response);
        } catch (err) {
            console.log('WhitelabelTemplate POST err:', err);
            throw err;
        }
    }

    async editWhitelabelTemplate(whitelabel: WhitelabelTemplateModel): Promise<WhitelabelTemplateModel> {
        try {
            const response = await this._logic.api().whitelabelTemplateApi.whiteLabelTemplateUpdate({
                id: whitelabel.id,
                data: {
                    id: whitelabel.id,
                    name: whitelabel.name,
                    content: whitelabel.content,
                    client: whitelabel.client
                }
            });
            return this._toWhitelabelTemplate(response);
        } catch (err) {
            console.log('WhitelabelTemplate Update err:', err);
            throw err;
        }
    }

    async deleteWhitelabelTemplate(whitelabel: WhitelabelTemplateModel): Promise<Boolean> {
        try {
            await this._logic.api().whitelabelTemplateApi.whiteLabelTemplateDelete({ id: whitelabel.id });
            return true;
        } catch (err) {
            console.log('Whitelabel DELETE err:', err);
            throw err;
        }
    }

    async createWhitelabel(templateId: string, clientId: number): Promise<WhitelabelModel> {
        try {
            const response = await this._logic.api().whitelabelApi.whiteLabelCreate({
                data: {
                    client: clientId,
                    template: templateId
                }
            });
            return this._toWhitelabel(response);
        } catch (err) {
            console.log('Whitelabel Create err:', err);
            throw err;
        }
    }

    async updateWhitelabel(whitelabelId: string, templateId: string, clientId: number): Promise<WhitelabelModel> {
        try {
            const response = await this._logic.api().whitelabelApi.whiteLabelUpdate({
                id: whitelabelId,
                data: {
                    client: clientId,
                    template: templateId
                }
            });
            return this._toWhitelabel(response);
        } catch (err) {
            console.log('Whitelabel Create err:', err);
            throw err;
        }
    }

    async deleteWhitelabel(id: string) {
        try {
            await this._logic.api().whitelabelApi.whiteLabelDelete({
                id
            });
            return true;
        } catch (err) {
            console.log('Whitelabel Delete err:', err);
            throw err;
        }
    }

    private _toWhitelabel(data: ReadOnlyWhiteLabel, content?: WhitelabelSettings): WhitelabelModel {
        return {
            id: data.id ?? '',
            template: data.template ?? undefined,
            client: data.client ?? undefined,
            content
        };
    }

    private _toWhitelabelTemplate(data: ReadOnlyWhiteLabelTemplate): WhitelabelTemplateModel {
        return {
            id: data.id ?? '',
            name: data.name ?? '',
            content: data.content,
            client: data.client ?? undefined
        };
    }
}

const jsonDark = {
    'tm-primary': '#29abe2',
    'tm-primary-contrast': '#ffffff',
    'tm-secondary': '#ffd22e',
    'tm-secondary-contrast': '#000000',
    invert: '0%',
    text: '#ffffff',
    'text-hightlighted': '#dcdcdc',
    'text-hover': '#a9a9a9',
    bg: '#222222',
    'bg-block': '#4f4f4f',
    border: '#bcbcbb',
    title: '#ffffff',
    'panel-bg': '#1c1c1c',
    'navbar-bg': '#cc0000',
    'navbar-submenu-bg': '#aa0011',
    'navbar-text': '#a9a9a9',
    'navbar-text-hover': '#ffffff',
    'navbar-sub-bg': '#222222',
    'navbar-clock-text': '#ffffff',
    'navbar-distancer': '#555555',
    'sidebar-text': '#dfdfdf',
    'sidebar-bg': '#222222',
    'sidebar-hover': '#dfdfdf',
    scroll: '#333333',
    'scroll-thumb': '#cccccc',
    'scroll-thumb-hover': '#ffffff',
    'table-text': '#ffffff',
    'table-selected-text': '#ffffff',
    'table-bg-even': '#222222',
    'table-bg-odd': '#393939',
    'table-hover-bg': '#2f3a44',
    'table-selected-bg': '#222e38',
    'table-selected-detail-bg': '#384d5f',
    'table-border': '#4f4f4f',
    'table-border-horizontal': '#222222',
    'table-selected-border': '#ffffff',
    'table-head-text': '#fff',
    'table-head-highlight-text': '#ffd22e',
    'table-head-bg': '#22476a',
    'table-head-border': '#808080',
    'table-head-sub-bg': '#656565',
    'table-head-sub-border': '#808080',
    'table-button-hover-bg': '#333333',
    'table-tag-text': '#000000',
    'table-tag-bg': '#222222',
    'bar-input-text': '#6c6c6c',
    'bar-popup-bg': '#6f6f6f',
    'bar-popup-text': '#dfdfdf',
    'bar-popup-border': '#cccccc',
    'bar-btn-text': '#444444',
    'bar-btn-bg': '#bcbcbc',
    'bar-btn-border': '#bcbcbc',
    'bar-btn-hover-bg': '#dddddd',
    'bar-btn-hover-border': '#dddddd',
    'button-text': '#444444',
    'button-bg': '#bcbcbc',
    'button-text-hover': '#444444',
    'button-bg-hover': '#dddddd',
    'filter-text': '#dfdfdf',
    'sidepanel-text': '#222222',
    'dispatcher-board-text': '#ffffff',
    'dispatcher-board-container-bg': '#393939',
    'dispatcher-board-bg': '#2f2f2f',
    'dispatcher-board-completed-opacity': '0.3',
    'dispatcher-board-head-bg': '#4f4f4f',
    'dispatcher-board-head-sub-bg': '#393939',
    'dispatcher-board-head-active-text': '#ffe37d',
    'dispatcher-board-transport-active-bg': '#c8eaf9',
    'dispatcher-board-transport-inactive-bg': '#bcbcbc',
    'dispatcher-board-transport-border': '#bcbcbc',
    'dispatcher-board-border': '#4f4f4f',
    'route-overview-new-text': '#75a9f9',
    'route-overview-delayed-text': '#fddf74',
    'route-overview-active-text': '#77a559',
    'route-overview-completed-text': '#aaaaaa',
    'tracking-table-head-bg': '#bcbcbc',
    'statistics-costs-label-bg': '#393939',
    'aetr-highlighted-text': '#ffffff',
    'aetr-highlighted-bg-1': '#222e38',
    'aetr-highlighted-bg-2': '#384d5f',
    'journeys-input-bg': '#222222',
    'planner-profile-values-text': '#ffffff',
    'planner-profile-values-bg': '#6b5e2d',
    'poi-card-text': '#000000',
    'driver-behavior-bg': '#4f4f4f',
    'journeys-selected-text': '#fddf74',
    'route-infobox-bg': '#222222',
    'sidebar-img-invert': '0%',
    'planner-body-bg': '#222222',
    'planner-th-heading': '#4f4f4f',
    'planner-bg': '#393939',
    'planner-group-bg': '#4f4f4f',
    'planner-route-text': '#000',
    'planner-route-bg': '#bcbcbc',
    summary: '#29abe2',
    'form-bg': '#1b3243',
    'form-block-bg': '#384d5f',
    'form-input-bg': '#4d6071',
    'tag-text': '#000000',
    'tag-bg': '#ffffff',
    'loader-loading-wrap-bg': '#232323',
    'loader-processing-wrap-bg': '#555555',
    'progress-bg': '#808080',
    'progress-success': '#77a559',
    'progress-warning': '#ff9800',
    'progress-danger': '#ff3823'
};

export const sampleWhitelabel: WhitelabelTemplateModel[] = [
    {
        id: '123123123',
        name: 'Dark 1',
        content: jsonDark,
        client: undefined
    },
    {
        id: '789789789',
        name: 'Light 2',
        content: jsonDark,
        client: 100
    }
];
