import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PartnerTableBar from 'modules/partner/components/PartnerTableBar';
import Confirm, { MessageType } from 'common/components/Confirm';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';
import UsersTable from '../UsersTable';
import UsersDetail from '../UsersDetail';
import UsersCreate from '../UsersCreate';
import UsersActions from '../UsersActions';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { LayoutContent } from 'common/components/Layout/Content';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import UsersDetailCard from '../UsersDetailCard';

interface Props extends WithTranslation {
    edit: boolean;
    loading: boolean;
    data?: PaginatedResponse<PartnerUserModel[]>;
    selected?: PartnerUserModel;
    create?: PartnerUserModel;
    delete?: PartnerUserModel;
    search?: { text: string };
    companies?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    clients?: PartnerCompanySelectModel[];
    onBarCompanyFilterChange?: (id: string) => void;
    onBarCompanyFilterCancel?: () => void;
    onBarCompanyFilterSearch?: (value: string) => void;
    onTableRowSelect?: (id: string) => void;
    onDetailActionsCreate?: () => void;
    onDetailFormCancel?: () => void;
    onDetailFormSubmit?: (model: PartnerUserModel) => void;
    onDeleteConfirm?: () => void;
    onDeleteCancel?: () => void;
    onCreateSubmit?: (model: PartnerUserModel) => void;
    onCreateCancel?: () => void;
    onUsersPaginationChange?: (pagination: PaginationParams) => void;
    onActionsDelete?: () => void;
    onActionsImpersonate?: () => void;
    onClientsFilterSearch?: (text: string) => void;
}

function Users(props: Props) {
    const { t } = props;

    return (
        <>
            {props.create && (
                <UsersCreate
                    model={props.create}
                    clients={props.clients}
                    onClientSearch={props.onClientsFilterSearch}
                    onSubmit={props.onCreateSubmit}
                    onCancel={props.onCreateCancel}
                />
            )}

            {props.delete && (
                <Confirm
                    danger
                    header={t('PartnerUsers.deleteHeader')}
                    message={`${t('PartnerUsers.deleteConfirm')} ${props.delete?.name} ${props.delete?.surname}`}
                    type={MessageType.WARNING}
                    confirmLabel={props.t('common.delete')}
                    onConfirm={props.onDeleteConfirm}
                    onCancel={props.onDeleteCancel}
                />
            )}

            <LayoutContent
                className="partner-users no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.users')}
                            filters={[
                                <PartnerTableBar
                                    companies={props.companies}
                                    selectedCompany={props.selectedCompany}
                                    onCompanyFilterChange={props.onBarCompanyFilterChange}
                                    onCompanyFilterCancel={props.onBarCompanyFilterCancel}
                                    onCompanyFilterSearch={props.onBarCompanyFilterSearch}
                                />
                            ]}
                        />
                        <UsersTable
                            loading={props.loading}
                            data={props.data}
                            selected={props.selected}
                            clients={props.companies}
                            onTableRowSelect={props.onTableRowSelect}
                            onPaginationChange={props.onUsersPaginationChange}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            header={
                                <h2>
                                    {props.selected.name} {props.selected.surname}
                                </h2>
                            }
                            body={
                                <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                    <Collapse.Panel header={props.t('common.detail')} key="1">
                                        {props.edit ? (
                                            <UsersDetail
                                                model={props.selected}
                                                clients={props.clients}
                                                onClientSearch={props.onClientsFilterSearch}
                                            />
                                        ) : (
                                            <UsersDetailCard data={props.selected} />
                                        )}
                                    </Collapse.Panel>
                                </Collapse>
                            }
                            footer={!props.edit && <UsersActions data={props.selected} />}
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(Users);
