import cn from 'classnames';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CcrModel } from 'logic/partner/logic/partner-ccr';
import { CcrForm, defaultValidationErrors, snValidator, identValidator, formValidator } from '../CcrDetail';
import moment, { Moment } from 'moment';
import { Row, Col } from 'antd';
import { Button, DatePicker } from 'common/components';

interface Props extends WithTranslation {
    model: CcrModel;
    onCancel?: () => void;
    onSubmit?: (model: CcrModel) => void;
}

interface State {
    form: CcrForm;
}

class CcrCreate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: props.model.id,
                ident: props.model.ident,
                sn: props.model.sn,
                createdOn: props.model.createdOn
            }
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="t-modal">
                <form className="partner-modal-form t-modal-content" onSubmit={this._onSubmit}>
                    <h1>{t('PartnerCcr.addNewCcr')}</h1>
                    <div className="t-bar-block">
                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.ident')}>
                                {t('Partner.ident')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.identErr,
                                        success: !this.state.form.identErr && !!this.state.form.ident
                                    })}
                                    onChange={this._onIdentChange}
                                    value={this.state.form.ident}
                                />
                                {this.state.form.identErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.identErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.sn')}>
                                {t('Partner.sn')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.snErr,
                                        success: !this.state.form.snErr && !!this.state.form.sn
                                    })}
                                    onChange={this._onSnChange}
                                    value={this.state.form.sn}
                                />
                                {this.state.form.snErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.snErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.createdOn')}>
                                {t('Partner.createdOn')}
                            </div>
                            <div className="t-half">
                                <DatePicker.DateTimePicker
                                    defaultValue={this.state.form.createdOn}
                                    onChange={value => this._onCreatedOnChange(value as Moment)}
                                    showTime={false}
                                    trigger={
                                        <div className="t-input t-padding-small">
                                            {moment(this.state.form.createdOn).format('L')}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <footer>
                        <Row justify="center" gutter={10}>
                            <Col>
                                <Button type="default" onClick={this._onCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                    </footer>
                </form>
            </div>
        );
    }

    private _onIdentChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = identValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                ident: value,
                identErr: validateRes.err as string
            }
        }));
    };

    private _onSnChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = snValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                sn: value,
                snErr: validateRes.err as string
            }
        }));
    };

    private _onCreatedOnChange = (value: Moment): void => {
        this.setState(state => ({
            form: {
                ...state.form,
                createdOn: value
            }
        }));
    };

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const validationRes = formValidator.validate({
            ident: this.state.form.ident,
            sn: this.state.form.sn
        });
        if (validationRes.valid) {
            const modelCreate: CcrModel = {
                id: '',
                ident: this.state.form.ident,
                sn: this.state.form.sn,
                createdOn: this.state.form.createdOn
            };
            this.props.onSubmit?.(modelCreate);
        } else {
            this.setState(state => ({
                form: {
                    ...state.form!,
                    identErr: validationRes.err?.ident ?? '',
                    snErr: validationRes.err?.sn ?? ''
                }
            }));
        }
    };
}

export default withTranslation()(CcrCreate);
