import moment from 'moment';
import { ReadOnlyFuelCardSerializerFromJSON, ReadOnlyFuelCompanySerializerFromJSON } from 'generated/new-main';
import { vehicles } from './vehicles';
import { users } from './users';

const fuelCompaniesRaw = [
    { id: 1, name: 'aral', label: 'ARAL' },
    { id: 2, name: 'as24', label: 'AS24' },
    { id: 3, name: 'avia', label: 'AVIA' },
    { id: 4, name: 'bp', label: 'BP' },
    { id: 5, name: 'ccs', label: 'CCS' },
    { id: 6, name: 'circle_k', label: 'Circle K' },
    { id: 7, name: 'dkv', label: 'DKV' },
    { id: 8, name: 'eni', label: 'ENI' },
    { id: 9, name: 'eurowag', label: 'EUROWAG' },
    { id: 10, name: 'gas', label: 'GAS' },
    { id: 11, name: 'ids', label: 'IDS' },
    { id: 12, name: 'jet', label: 'JET' },
    { id: 13, name: 'omv', label: 'OMV' },
    { id: 14, name: 'shell', label: 'SHELL' },
    { id: 15, name: 'slovnaft', label: 'SLOVNAFT' },
    { id: 16, name: 'uta', label: 'UTA' }
];
export const fuelCompanies = fuelCompaniesRaw.map(e => ReadOnlyFuelCompanySerializerFromJSON(e));

export const fuelCardsRaw = [
    {
        id: 1,
        number: '3457923',
        expiration_date: moment().add(542, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        holder: `${users[0].name} ${users[0].surname}`,
        monitored_object: vehicles[0].id,
        fuel_company: 9,
        client: 8279,
        user: users[0].id
    },
    {
        id: 2,
        number: '8993440',
        expiration_date: moment().add(317, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        holder: `${users[1].name} ${users[1].surname}`,
        monitored_object: vehicles[1].id,
        fuel_company: 9,
        client: 8279,
        user: users[1].id
    },
    {
        id: 3,
        number: '8995623',
        expiration_date: moment().add(368, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        holder: `${users[2].name} ${users[2].surname}`,
        monitored_object: vehicles[2].id,
        fuel_company: 9,
        client: 8279,
        user: users[2].id
    },
    {
        id: 4,
        number: '3437323',
        expiration_date: moment().add(400, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        holder: `${users[3].name} ${users[3].surname}`,
        monitored_object: vehicles[3].id,
        fuel_company: 9,
        client: 8279,
        user: users[3].id
    },
    {
        id: 5,
        number: '8933440',
        expiration_date: moment().add(341, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        note: 'Contacted by dispatcher',
        holder: `${users[4].name} ${users[4].surname}`,
        monitored_object: vehicles[4].id,
        fuel_company: 9,
        client: 8279,
        user: users[4].id
    },
    {
        id: 6,
        number: '2934443',
        expiration_date: moment().add(231, 'days').format('YYYY-MM-DD'),
        start_time: null,
        end_time: null,
        limit_amount: null,
        limit_duration: null,
        deleted: 1,
        status: null,
        holder: `${users[5].name} ${users[5].surname}`,
        monitored_object: vehicles[5].id,
        fuel_company: 9,
        client: 8279,
        user: users[5].id
    }
];
export const fuelCards = fuelCardsRaw.map(e => ReadOnlyFuelCardSerializerFromJSON(e));
