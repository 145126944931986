/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectToMonitoreObjectPartialUpdate
 */
export interface MonitoredObjectToMonitoreObjectPartialUpdate {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectToMonitoreObjectPartialUpdate
     */
    primaryMonitoredObjectId: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectToMonitoreObjectPartialUpdate
     */
    secondaryMonitoredObjectId: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectToMonitoreObjectPartialUpdate
     */
    validFrom: Date;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectToMonitoreObjectPartialUpdate
     */
    source: MonitoredObjectToMonitoreObjectPartialUpdateSourceEnum;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectToMonitoreObjectPartialUpdate
     */
    validTo?: Date;
}

export function MonitoredObjectToMonitoreObjectPartialUpdateFromJSON(json: any): MonitoredObjectToMonitoreObjectPartialUpdate {
    return MonitoredObjectToMonitoreObjectPartialUpdateFromJSONTyped(json, false);
}

export function MonitoredObjectToMonitoreObjectPartialUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectToMonitoreObjectPartialUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primaryMonitoredObjectId': json['primary_monitored_object_id'],
        'secondaryMonitoredObjectId': json['secondary_monitored_object_id'],
        'validFrom': (new Date(json['valid_from'])),
        'source': json['source'],
        'validTo': !exists(json, 'valid_to') ? undefined : (new Date(json['valid_to'])),
    };
}

export function MonitoredObjectToMonitoreObjectPartialUpdateToJSON(value?: MonitoredObjectToMonitoreObjectPartialUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_monitored_object_id': value.primaryMonitoredObjectId,
        'secondary_monitored_object_id': value.secondaryMonitoredObjectId,
        'valid_from': (value.validFrom.toISOString()),
        'source': value.source,
        'valid_to': value.validTo === undefined ? undefined : (value.validTo.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum MonitoredObjectToMonitoreObjectPartialUpdateSourceEnum {
    Man = 'man',
    TId = 't_id'
}


