/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCostStatOfFleetTypes
 */
export interface ClientCostStatOfFleetTypes {
    /**
     * 
     * @type {number}
     * @memberof ClientCostStatOfFleetTypes
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCostStatOfFleetTypes
     */
    costPerKm: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCostStatOfFleetTypes
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCostStatOfFleetTypes
     */
    fleetType: ClientCostStatOfFleetTypesFleetTypeEnum;
}

export function ClientCostStatOfFleetTypesFromJSON(json: any): ClientCostStatOfFleetTypes {
    return ClientCostStatOfFleetTypesFromJSONTyped(json, false);
}

export function ClientCostStatOfFleetTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCostStatOfFleetTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'costPerKm': json['cost_per_km'],
        'currency': json['currency'],
        'fleetType': json['fleet_type'],
    };
}

export function ClientCostStatOfFleetTypesToJSON(value?: ClientCostStatOfFleetTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'cost_per_km': value.costPerKm,
        'currency': value.currency,
        'fleet_type': value.fleetType,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClientCostStatOfFleetTypesFleetTypeEnum {
    LightVehicle = 'light_vehicle',
    Vehicle = 'vehicle',
    Trailer = 'trailer'
}


