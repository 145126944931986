import cn from 'classnames';
import { Progress, Rate } from 'common/components';
import { MonitoredObjectTypeName } from 'common/model/tracking';
import { roundToStep } from 'common/utils/averages';

interface Props {
    monitoredObjectType?: MonitoredObjectTypeName;
    value: number;
    color: 'yellow' | 'lightblue' | 'green' | 'blue' | 'purple';
    size: number;
    withRate?: boolean;
    qa?: string;
}

export enum Colors {
    Lightblue = '#00e1ff',
    Blue = '#338fff',
    Green = '#1bda7e',
    Yellow = '#ffd22e',
    Purple = '#6037d2'
}

export default function DriverScoreProgress({
    monitoredObjectType = MonitoredObjectTypeName.VEHICLE,
    value,
    color = 'green',
    size,
    withRate = true,
    qa
}: Props) {
    return (
        <Progress
            className={cn('driver-score-progress', {
                'driver-score-progress-blue': color === 'blue',
                'driver-score-progress-yellow': color === 'yellow',
                'driver-score-progress-lightblue': color === 'lightblue',
                'driver-score-progress-green': color === 'green',
                'driver-score-progress-purple': color === 'purple'
            })}
            percent={monitoredObjectType === MonitoredObjectTypeName.VEHICLE ? (value / 5) * 100 : value}
            type="dashboard"
            gapDegree={84}
            trailColor="#6c6c6c"
            strokeWidth={8}
            strokeColor={
                color === 'lightblue'
                    ? Colors.Lightblue
                    : color === 'blue'
                    ? Colors.Blue
                    : color === 'green'
                    ? Colors.Green
                    : color === 'purple'
                    ? Colors.Purple
                    : Colors.Yellow
            }
            format={() => (
                <div className="driver-score-progress-content">
                    <span data-qa={qa}>{roundToStep(value, 0.1)}</span>
                    {withRate && (
                        <Rate
                            size={size > 120 ? 'small' : 'xsmall'}
                            value={roundToStep(value, 0.5)}
                            color={color}
                            disabled
                            allowHalf
                        />
                    )}
                </div>
            )}
            width={size}
        />
    );
}
