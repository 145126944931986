/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOOutputFloat,
    TCOOutputFloatFromJSON,
    TCOOutputFloatFromJSONTyped,
    TCOOutputFloatToJSON,
    TCOOutputInteger,
    TCOOutputIntegerFromJSON,
    TCOOutputIntegerFromJSONTyped,
    TCOOutputIntegerToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOOutputDetails
 */
export interface TCOOutputDetails {
    /**
     * 
     * @type {TCOOutputInteger}
     * @memberof TCOOutputDetails
     */
    year: TCOOutputInteger;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    mileage: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    purchaseCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    insuranceCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    serviceAndMaintenanceCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    fuelCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    fuelPrice: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    fuelAverageConsumption: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    totalConsumptionOfFuel: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    tollCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    otherCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    totalCost: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    gCo2PerUnitProduction: TCOOutputFloat;
    /**
     * 
     * @type {TCOOutputFloat}
     * @memberof TCOOutputDetails
     */
    residualValue: TCOOutputFloat;
}

export function TCOOutputDetailsFromJSON(json: any): TCOOutputDetails {
    return TCOOutputDetailsFromJSONTyped(json, false);
}

export function TCOOutputDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOOutputDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': TCOOutputIntegerFromJSON(json['year']),
        'mileage': TCOOutputFloatFromJSON(json['mileage']),
        'purchaseCost': TCOOutputFloatFromJSON(json['purchase_cost']),
        'insuranceCost': TCOOutputFloatFromJSON(json['insurance_cost']),
        'serviceAndMaintenanceCost': TCOOutputFloatFromJSON(json['service_and_maintenance_cost']),
        'fuelCost': TCOOutputFloatFromJSON(json['fuel_cost']),
        'fuelPrice': TCOOutputFloatFromJSON(json['fuel_price']),
        'fuelAverageConsumption': TCOOutputFloatFromJSON(json['fuel_average_consumption']),
        'totalConsumptionOfFuel': TCOOutputFloatFromJSON(json['total_consumption_of_fuel']),
        'tollCost': TCOOutputFloatFromJSON(json['toll_cost']),
        'otherCost': TCOOutputFloatFromJSON(json['other_cost']),
        'totalCost': TCOOutputFloatFromJSON(json['total_cost']),
        'gCo2PerUnitProduction': TCOOutputFloatFromJSON(json['g_co2_per_unit_production']),
        'residualValue': TCOOutputFloatFromJSON(json['residual_value']),
    };
}

export function TCOOutputDetailsToJSON(value?: TCOOutputDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': TCOOutputIntegerToJSON(value.year),
        'mileage': TCOOutputFloatToJSON(value.mileage),
        'purchase_cost': TCOOutputFloatToJSON(value.purchaseCost),
        'insurance_cost': TCOOutputFloatToJSON(value.insuranceCost),
        'service_and_maintenance_cost': TCOOutputFloatToJSON(value.serviceAndMaintenanceCost),
        'fuel_cost': TCOOutputFloatToJSON(value.fuelCost),
        'fuel_price': TCOOutputFloatToJSON(value.fuelPrice),
        'fuel_average_consumption': TCOOutputFloatToJSON(value.fuelAverageConsumption),
        'total_consumption_of_fuel': TCOOutputFloatToJSON(value.totalConsumptionOfFuel),
        'toll_cost': TCOOutputFloatToJSON(value.tollCost),
        'other_cost': TCOOutputFloatToJSON(value.otherCost),
        'total_cost': TCOOutputFloatToJSON(value.totalCost),
        'g_co2_per_unit_production': TCOOutputFloatToJSON(value.gCo2PerUnitProduction),
        'residual_value': TCOOutputFloatToJSON(value.residualValue),
    };
}


