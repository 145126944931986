import { Logic } from 'logic/logic';
import { ReadOnlyUser } from 'generated/new-main';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';

export interface PartnerUserModel {
    id: string;
    ssoId?: string;
    name: string;
    surname: string;
    contact?: {
        emails?: string[];
        phone_numbers?: string[];
    };
    note?: string;
    monitoredObjectGroup?: number;
    clientId?: number;
    clientName?: string;
}

export class PartnerUserLogic {
    constructor(private _logic: Logic) {}

    async getUserList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET
    ): Promise<PaginatedResponse<PartnerUserModel[]>> {
        try {
            const response = await this._logic.api().newUserApi.userList({ client: clientId, limit, offset });
            return {
                data: response.results.map(res => this._toUser(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('User list GET err:', err);
            throw err;
        }
    }

    async getUser(ssoId: string): Promise<PartnerUserModel> {
        try {
            const response = await this._logic.api().newUserApi.userRead({ ssoId: ssoId });
            return this._toUser(response);
        } catch (err) {
            console.log('User GET err:', err);
            throw err;
        }
    }
    async deleteUser(user: PartnerUserModel): Promise<Boolean> {
        try {
            const response = await this._logic.api().newUserApi.userDelete({ ssoId: user.ssoId! });
            return Boolean(response);
        } catch (err) {
            console.log('User DELETE err:', err);
            throw err;
        }
    }
    async addUser(user: PartnerUserModel): Promise<PartnerUserModel> {
        // TODO: REMOVE EVERYTHING WHAT IS NO IN USE FROM PARTNER MODULE. EG CREATE NEW USER
        try {
            const response = await this._logic.api().newUserApi.userCreate({
                data: {
                    name: user.name,
                    surname: user.surname,
                    active: 0, // TODO toto je spravne ?
                    deleted: 0,
                    averageSpeed: 0,
                    clientId: user.clientId ?? 0,
                    userGroupIds: [],
                    userRolesIds: [],
                    contact: user.contact,
                    note: user.note
                }
            });
            return this._toUser(response);
        } catch (err) {
            console.log('User POST err:', err);
            throw err;
        }
    }

    private _toUser(data: ReadOnlyUser): PartnerUserModel {
        return {
            id: String(data.id) ?? '',
            ssoId: data.ssoId ?? '',
            name: data.name ?? '',
            surname: data.surname ?? '',
            clientId: data.client?.id ?? 0,
            clientName: data.client?.name ?? '',
            contact: {
                emails: [],
                phone_numbers: [],
                ...data.contact
            },
            note: data.note ?? ''
        };
    }
}

export const sampleUsers: PartnerUserModel[] = [
    {
        id: '1',
        ssoId: 'NZ 557 KL',
        name: 'Jozko',
        surname: 'Mrkvicka',
        note: 'Note',
        monitoredObjectGroup: 5,
        contact: {
            emails: ['aaa@zafasdf.sk'],
            phone_numbers: ['+421 999 000 000']
        },
        clientId: 113,
        clientName: '113'
    },
    {
        id: '2',
        ssoId: 'NZ 558 KL',
        name: 'Jozko',
        surname: 'Mrkvicka',
        note: 'Note',
        monitoredObjectGroup: 0,
        clientId: 113,
        clientName: '113'
    }
];
