/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringDevicePermissions
 */
export interface MonitoringDevicePermissions {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDevicePermissions
     */
    roles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDevicePermissions
     */
    modules: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDevicePermissions
     */
    promo?: Array<string>;
}

export function MonitoringDevicePermissionsFromJSON(json: any): MonitoringDevicePermissions {
    return MonitoringDevicePermissionsFromJSONTyped(json, false);
}

export function MonitoringDevicePermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringDevicePermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': json['roles'],
        'modules': json['modules'],
        'promo': !exists(json, 'promo') ? undefined : json['promo'],
    };
}

export function MonitoringDevicePermissionsToJSON(value?: MonitoringDevicePermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles,
        'modules': value.modules,
        'promo': value.promo,
    };
}


