/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCOSettingFloat
 */
export interface TCOSettingFloat {
    /**
     * 
     * @type {string}
     * @memberof TCOSettingFloat
     */
    type: TCOSettingFloatTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TCOSettingFloat
     */
    defaultValue: number;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingFloat
     */
    hint: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingFloat
     */
    units: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingFloat
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingFloat
     */
    visible: boolean;
}

export function TCOSettingFloatFromJSON(json: any): TCOSettingFloat {
    return TCOSettingFloatFromJSONTyped(json, false);
}

export function TCOSettingFloatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOSettingFloat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'defaultValue': json['default_value'],
        'hint': json['hint'],
        'units': json['units'],
        'required': json['required'],
        'visible': json['visible'],
    };
}

export function TCOSettingFloatToJSON(value?: TCOSettingFloat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'default_value': value.defaultValue,
        'hint': value.hint,
        'units': value.units,
        'required': value.required,
        'visible': value.visible,
    };
}

/**
* @export
* @enum {string}
*/
export enum TCOSettingFloatTypeEnum {
    Integer = 'integer',
    Float = 'float',
    Array = 'array'
}


