/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DefaultDriverInfo
 */
export interface DefaultDriverInfo {
    /**
     * 
     * @type {number}
     * @memberof DefaultDriverInfo
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultDriverInfo
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultDriverInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultDriverInfo
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultDriverInfo
     */
    registrationNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof DefaultDriverInfo
     */
    monitoredObjectDisabledAt?: Date;
}

export function DefaultDriverInfoFromJSON(json: any): DefaultDriverInfo {
    return DefaultDriverInfoFromJSONTyped(json, false);
}

export function DefaultDriverInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultDriverInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'monitoredObjectId': json['monitored_object_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'registrationNumber': json['registration_number'],
        'monitoredObjectDisabledAt': !exists(json, 'monitored_object_disabled_at') ? undefined : (new Date(json['monitored_object_disabled_at'])),
    };
}

export function DefaultDriverInfoToJSON(value?: DefaultDriverInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver_id': value.driverId,
        'monitored_object_id': value.monitoredObjectId,
        'name': value.name,
        'surname': value.surname,
        'registration_number': value.registrationNumber,
        'monitored_object_disabled_at': value.monitoredObjectDisabledAt === undefined ? undefined : (value.monitoredObjectDisabledAt.toISOString()),
    };
}


