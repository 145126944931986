/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessagePayload,
    MessagePayloadFromJSON,
    MessagePayloadFromJSONTyped,
    MessagePayloadToJSON,
} from './';

/**
 * 
 * @export
 * @interface MessageInput
 */
export interface MessageInput {
    /**
     * 
     * @type {string}
     * @memberof MessageInput
     */
    text: string;
    /**
     * 
     * @type {Array<MessagePayload>}
     * @memberof MessageInput
     */
    payload?: Array<MessagePayload> | null;
}

export function MessageInputFromJSON(json: any): MessageInput {
    return MessageInputFromJSONTyped(json, false);
}

export function MessageInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'payload': !exists(json, 'payload') ? undefined : (json['payload'] === null ? null : (json['payload'] as Array<any>).map(MessagePayloadFromJSON)),
    };
}

export function MessageInputToJSON(value?: MessageInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'payload': value.payload === undefined ? undefined : (value.payload === null ? null : (value.payload as Array<any>).map(MessagePayloadToJSON)),
    };
}


