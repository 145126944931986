import { GetStazkaExportV1StazkaExportGetRequest } from 'generated/backend-api';
import i18n from 'i18next';
import { Logic } from './logic';
import { downloadFile } from 'common/utils/fileUtils';

export class StazkaLogic {
    private _logic: Logic;
    constructor(logic: Logic) {
        this._logic = logic;
    }

    async getStazkaExport(filters: GetStazkaExportV1StazkaExportGetRequest): Promise<void> {
        try {
            const stazkaRes = await this._logic.api().stazkaApi.getStazkaExportV1StazkaExportGetRaw(filters);

            const stazkaBlob = await stazkaRes.raw.blob();
            downloadFile(stazkaBlob, `${i18n.t('StazkaExportModal.fileName')}.xlsx`);
        } catch (err) {
            console.error('Can not get stazka export, err: ', err);
            throw err;
        }
    }

    async getTransportStazkaExport(transportId: string): Promise<void> {
        try {
            const stazkaRes = await this._logic.api().stazkaApi.getStazkaExportV1StazkaExportGetRaw({
                transportId,
                lang: i18n.language
            });
            const stazkaBlob = await stazkaRes.raw.blob();
            downloadFile(stazkaBlob, `${i18n.t('StazkaExportModal.fileName')}.xlsx`);
        } catch (err) {
            console.error('Can not get stazka export, err: ', err);
            throw err;
        }
    }
}
