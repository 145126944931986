import moment from 'moment';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Logic } from 'logic/logic';
import { ColdchainFilter } from './ColdchainModule';
import i18n from 'i18next';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import {
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyProfileSerializer,
    ReadOnlyTemperatureSensorSerializer,
    WriteOnlyProfileSerializer
} from 'generated/new-main';
import { ExternalDeviceState, ExternalDeviceType, MonitoredObjectState } from 'generated/backend-api-live';
import { DateRange } from 'common/model/date-time';
import { COLD_CHAIN_PROFILE_ID, DATE_TIME_FORMAT } from 'domain-constants';
import { ColdchainProfileData } from 'common/forms/ColdchainProfileForm';
import { AlarmInDatabaseWithGPSInfo, AlarmType, ColdChainProfileActiveEventRule } from 'generated/backend-api';
import { ZONE_RULE_NAME } from 'domain-constants';
import { findFirstBiggerItemIndex } from 'common/utils/math';
import { getXAxisTicks } from 'common/utils/dateTimeUtils';
import { message } from 'antd';
import { downloadFile } from 'common/utils/fileUtils';
import { search } from 'common/utils/search';

export interface TemperatureSensorDataGraphs {
    serialNumber: string;
    timestamps: Array<number>;
    temperatures: Array<number | null>;
    timestampsTicks?: Array<number>;
    polyline: string;
    timestampsSource?: Array<number>;
    temperaturesSource?: Array<number | null>;
    interval?: number;
}

export interface ColdchainTemperatureSensorModel extends ReadOnlyTemperatureSensorSerializer {
    temperature?: number;
    monitoredObjectState?: MonitoredObjectState;
    monitoredObjectRN?: string;
    profileNames?: string[];
}

export interface ColdchainDetailModel {
    monitoredObjectId: number;
    registrationNumber?: string;
    sensorZone?: number;
    sensorId?: string;
    sensorSerialNumber?: string;
    dateRange: DateRange;
}

export class ColdchainLogic {
    logic: Logic;

    @observable coldchainFilter: ColdchainFilter;
    @observable coldchainDetail?: ColdchainDetailModel;
    @observable temperatureSensors: ColdchainTemperatureSensorModel[];
    @observable temperatureSensorsState: ExternalDeviceState[];
    @observable profiles: ReadOnlyProfileSerializer[];
    @observable temperatureSensorsData?: TemperatureSensorDataGraphs[];
    @observable temperatureSensorsAlarmsData?: AlarmInDatabaseWithGPSInfo[];
    @observable temperatureProfiles?: ColdChainProfileActiveEventRule[];
    @observable detailedTemperatureSensors?: ColdchainTemperatureSensorModel[];

    @observable vehicles: ReadOnlyMonitoredObjectFeSb[];
    @observable trailers: ReadOnlyMonitoredObjectFeSb[];

    @observable coldchainLoading: boolean;
    @observable coldchainDetailLoading: boolean;
    @observable temperatureLogLoading: boolean;
    mobjectsTemperatureSensors: ColdchainTemperatureSensorModel[];

    constructor(logic: Logic) {
        this.logic = logic;

        this.temperatureSensors = [];
        this.mobjectsTemperatureSensors = [];
        this.temperatureSensorsState = [];
        this.profiles = [];
        this.vehicles = [];
        this.trailers = [];
        this.coldchainFilter = {
            showDeleted: false
        };

        this.coldchainLoading = false;
        this.coldchainDetailLoading = false;
        this.temperatureLogLoading = false;

        makeObservable(this);
    }

    @action
    async init() {
        try {
            this.coldchainLoading = true;
            this.coldchainDetailLoading = true;

            const temperatureSensors = await this._fetchTemperatureSensors();
            const externalDeviceState = await this.fetchExternalDeviceState(temperatureSensors.map(d => d.id ?? ''));
            const fleet = await this.logic.vehicles().getMonitoredObjectFilters(false, false, undefined, true, false);
            const coldChainProfiles = await this._fetchColdchainProfiles();

            runInAction(() => {
                this.temperatureSensors = temperatureSensors;
                this.mobjectsTemperatureSensors = temperatureSensors.slice();
                this.temperatureSensorsState = externalDeviceState;
                this.profiles = coldChainProfiles.sort((a, b) =>
                    (a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '') ? 1 : -1
                );
                this.vehicles = fleet.filter(v => (v.fleetType as FleetType) !== FleetType.TRAILER);
                this.trailers = fleet.filter(v => (v.fleetType as FleetType) === FleetType.TRAILER);
                if (!this.coldchainFilter.showDeleted) {
                    this.temperatureSensors = this.temperatureSensors.filter(
                        sensor =>
                            this.trailers.find(t => t.id === sensor.monitoredObject) ||
                            this.vehicles.find(t => t.id === sensor.monitoredObject)
                    );
                }
                this.coldchainLoading = false;
            });
            this.mergeTemperatureSensorsState();

            if (this.coldchainDetail) {
                this.coldchainDetail.registrationNumber = fleet.find(
                    d => d.id === this.coldchainDetail?.monitoredObjectId
                )?.registrationNumber;

                const sensorSerialNumbers = this.detailedTemperatureSensors
                    ?.filter(
                        sensor =>
                            sensor.monitoredObject === this.coldchainDetail?.monitoredObjectId &&
                            sensor.sensorZone === this.coldchainDetail.sensorZone
                    )
                    .map(sensor => sensor.serialNumber);
                if (sensorSerialNumbers) {
                    const temperatureSensorsData = await this._fetchTemperatureSensorsData(
                        this.coldchainDetail?.dateRange,
                        sensorSerialNumbers
                    );

                    const temperatureProfiles = await this._fetchTemperatureProfiles(
                        this.coldchainDetail?.dateRange,
                        String(this.coldchainDetail.monitoredObjectId)
                    );

                    const temperatureSensorsAlarmsData = await this._fetchTemperatureSensorsAlarmsData(
                        this.coldchainDetail?.dateRange,
                        this.coldchainDetail.monitoredObjectId
                    );

                    runInAction(() => {
                        this.temperatureSensorsData = temperatureSensorsData;
                        this.temperatureProfiles = temperatureProfiles;
                        this.temperatureSensorsAlarmsData = temperatureSensorsAlarmsData.filter(alarm =>
                            [
                                AlarmType.TransportColdChainProfileTemperatureHigh,
                                AlarmType.TransportColdChainProfileTemperatureLow
                            ].includes(alarm.alarmType)
                        );
                    });
                }
                this.coldchainDetailLoading = false;
            }
        } catch (err) {
            this.coldchainLoading = false;
            this.coldchainDetailLoading = false;
            console.error('Loading cold chain init error');
            throw err;
        }
    }

    @action
    async setColdchainDetail(dateRange: DateRange, monitoredObjectId?: number, sensorZone?: number, sensorId?: string) {
        try {
            this.coldchainDetailLoading = true;
            if (monitoredObjectId) {
                const sensorSerialNumber = this.temperatureSensors.find(d => d.id === sensorId)?.serialNumber;
                const detailedTemperatureSensors = this.temperatureSensors.filter(
                    coldchain => coldchain.monitoredObject === monitoredObjectId
                );

                if (
                    dateRange.start !== this.coldchainDetail?.dateRange.start ||
                    dateRange.end !== this.coldchainDetail?.dateRange.end ||
                    sensorZone !== this.coldchainDetail?.sensorZone
                ) {
                    runInAction(() => {
                        this.detailedTemperatureSensors = detailedTemperatureSensors;
                        this.coldchainDetail = {
                            monitoredObjectId,
                            registrationNumber: this.vehicles
                                .concat(this.trailers)
                                .find(d => d.id === monitoredObjectId)?.registrationNumber,
                            sensorZone,
                            sensorId,
                            sensorSerialNumber,
                            dateRange
                        };
                    });

                    const sensorSerialNumbers = this.detailedTemperatureSensors
                        ?.filter(
                            sensor =>
                                sensor.monitoredObject === monitoredObjectId && sensor.sensorZone === (sensorZone ?? 1)
                        )
                        .map(sensor => sensor.serialNumber);
                    if (sensorSerialNumbers && this.coldchainDetail?.monitoredObjectId) {
                        const temperatureSensorsData = await this._fetchTemperatureSensorsData(
                            dateRange,
                            sensorSerialNumbers
                        );
                        const temperatureSensorsAlarmsData = await this._fetchTemperatureSensorsAlarmsData(
                            dateRange,
                            this.coldchainDetail.monitoredObjectId
                        );
                        const temperatureProfiles = await this._fetchTemperatureProfiles(
                            dateRange,
                            String(this.coldchainDetail?.monitoredObjectId)
                        );

                        runInAction(() => {
                            this.temperatureSensorsData = temperatureSensorsData;
                            this.temperatureProfiles = temperatureProfiles;
                            this.temperatureSensorsAlarmsData = temperatureSensorsAlarmsData;
                        });
                    }
                }
            } else {
                this.detailedTemperatureSensors = undefined;
                this.coldchainDetail = undefined;
            }
            this.coldchainDetailLoading = false;
        } catch (err) {
            this.coldchainDetailLoading = false;
            console.error('Loading detail error');
            throw err;
        }
    }

    @action
    cancelDetail() {
        this.detailedTemperatureSensors = undefined;
        this.coldchainDetail = undefined;
    }

    @action
    async reloadColdchain() {
        this.coldchainLoading = true;
        const temperatureSensors = await this._fetchTemperatureSensors();
        const externalDeviceState = await this.fetchExternalDeviceState(temperatureSensors.map(d => d.id ?? ''));
        const externalDeviceFiltered: string[] = [];

        externalDeviceState.forEach(externalDevice => {
            if (externalDevice.monitoredObjectState?.activeTransports.length > 0) {
                externalDevice.monitoredObjectState.activeTransports.forEach(transport => {
                    if (transport.activeEventRules) {
                        transport.activeEventRules.forEach(activeEventRule => {
                            if (externalDevice.monitoredObjectState?.activeTransports.length > 0) {
                                const profileId = activeEventRule.config?.find(
                                    c => c.name === COLD_CHAIN_PROFILE_ID
                                )?.value;
                                if (
                                    activeEventRule.name?.replace(ZONE_RULE_NAME, '') ===
                                        String(
                                            temperatureSensors.find(ts => ts.id === externalDevice.deviceId)?.sensorZone
                                        ) &&
                                    this.coldchainFilter.profilesIds?.includes(String(profileId))
                                ) {
                                    externalDeviceFiltered.push(externalDevice.deviceId);
                                    return;
                                }
                            }
                        });
                    }
                });
            }
        });
        runInAction(() => {
            this.temperatureSensors =
                this.coldchainFilter.profilesIds?.length &&
                this.coldchainFilter.profilesIds?.length !== this.profiles.length
                    ? temperatureSensors.filter(sensor => externalDeviceFiltered.includes(sensor.id ?? ''))
                    : temperatureSensors;
            if (!this.coldchainFilter.showDeleted) {
                this.temperatureSensors = this.temperatureSensors.filter(
                    sensor =>
                        this.trailers.find(t => t.id === sensor.monitoredObject) ||
                        this.vehicles.find(t => t.id === sensor.monitoredObject)
                );
            }
            this.temperatureSensorsState = externalDeviceState;
        });
        this.mergeTemperatureSensorsState();
        this.coldchainLoading = false;
    }

    @action
    mergeTemperatureSensorsState() {
        this.temperatureSensors = search(
            this.coldchainFilter.textSearch,
            ['sensorName', 'monitoredObjectRN', 'profileNames'],
            this.temperatureSensors.map(sensor => {
                const sensorState = this.temperatureSensorsState.find(
                    sensorState => sensorState.deviceId === sensor.id
                );

                const rules = sensorState?.monitoredObjectState?.activeTransports
                    ?.map(activeTransport =>
                        activeTransport.activeEventRules?.find(
                            rule => rule?.name === `transport_cold_chain_zone_${sensor.sensorZone ?? 1}`
                        )
                    )
                    .filter(rule => rule);

                const profileNames = rules
                    ?.map(rule => {
                        const profile = this.profiles.find(profile =>
                            rule?.config
                                ?.map(item => (item.name === COLD_CHAIN_PROFILE_ID ? item.value : undefined))
                                .includes(profile.id)
                        );
                        return profile?.name ?? '';
                    })
                    .filter(profileName => profileName);

                return {
                    ...sensor,
                    temperature:
                        sensorState?.data?.['temperature'] !== null ? sensorState?.data?.['temperature'] : undefined,
                    monitoredObjectState: sensorState?.monitoredObjectState,
                    monitoredObjectRN:
                        [...this.vehicles, ...this.trailers]?.find(
                            monitoredObject => monitoredObject?.id === sensor.monitoredObject
                        )?.registrationNumber ?? '',
                    profileNames
                };
            })
        );
    }

    @action
    setColdchainFilter(filter: ColdchainFilter) {
        this.coldchainFilter = filter;
    }

    private async _fetchTemperatureSensors(): Promise<ColdchainTemperatureSensorModel[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.temperatureSensors;
        }

        try {
            const res = await this.logic.api().coldchainApi.coldChainTemperatureSensorList({
                monitoredObjectIn: this.coldchainFilter.monitoredObjectIds
                    ? this.coldchainFilter.monitoredObjectIds?.toString()
                    : undefined
            });
            return res;
        } catch (err) {
            console.error(`Failed to fetch coldchain - temperature sensor, err: ${err}`);
            throw err;
        }
    }

    private async _fetchColdchainProfiles(): Promise<ReadOnlyProfileSerializer[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.coldChainProfileList;
        }
        try {
            const response = await this.logic.api().coldchainApi.coldChainProfileList({});
            return response;
        } catch (err) {
            console.error(`Could not fetch cold chain profiles, err: ${err}`);
            throw err;
        }
    }

    async fetchExternalDeviceState(deviceIds: string[]): Promise<ExternalDeviceState[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.externalDeviceState;
        }

        try {
            const res = await this.logic
                .api()
                .externalDeviceApi.getExternalDeviceStateV2ActualVehicleStateExternalDeviceStateGet({
                    deviceType: ExternalDeviceType.TemperatureSensor,
                    deviceId: deviceIds
                });
            return res;
        } catch (err) {
            console.error(`Failed to fetch coldchain - external device state, err: ${err}`);
            throw err;
        }
    }

    async _fetchTemperatureProfiles(
        dateRange: DateRange,
        monitoredObjectId: string
    ): Promise<ColdChainProfileActiveEventRule[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.temperatureProfiles;
        }

        try {
            const res = await this.logic
                .api()
                .transportApi.transportMonitoringEntriesEventRulesColdChainProfileV1TransportsTransportMonitoringEntryEventRulesColdChainProfileGet(
                    {
                        tsfrom: moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                        tsto: moment(dateRange.end, DATE_TIME_FORMAT).toDate(),
                        monitoredObjectId: monitoredObjectId
                    }
                );
            return res;
        } catch (err) {
            console.error(`Failed to fetch temperature sensors data (temperatures), err: ${err}`);
            throw err;
        }
    }

    async _fetchTemperatureSensorsData(
        dateRange: DateRange,
        serialNumbers: string[]
    ): Promise<TemperatureSensorDataGraphs[]> {
        if (this.logic.demo().isActive) {
            return this.logic.demo().data.temperatureSensorData.filter(d => serialNumbers.includes(d.serialNumber));
        }

        try {
            const res = await this.logic
                .api()
                .journeyGraphApi.getTemperatureSensorsDataV1GraphApiTemperatureSensorDataGet({
                    timestampFrom: moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                    timestampTo: moment(dateRange.end, DATE_TIME_FORMAT).toDate(),
                    serialNumbers
                });

            const ticks = getXAxisTicks(
                moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                moment(dateRange.end, DATE_TIME_FORMAT).toDate()
            );

            let resScaled: TemperatureSensorDataGraphs[] = [];
            serialNumbers.forEach(serialNumber => {
                const sensor = res?.find(d => d.serialNumber === serialNumber);

                const timestampsScaled = ticks.samples.map(sample => {
                    const timestamp = sample / 1000;
                    const closestIndex = sensor ? findFirstBiggerItemIndex(timestamp, sensor?.timestamps) : 0;

                    return {
                        timestamp,
                        temperature:
                            closestIndex === 0 || (sensor && sensor.temperatures[closestIndex] > 3999)
                                ? null
                                : sensor
                                ? sensor.temperatures[closestIndex]
                                : null
                    };
                });

                resScaled.push({
                    polyline: sensor?.polyline ?? '',
                    serialNumber,
                    timestamps: timestampsScaled.map(d => d.timestamp),
                    timestampsTicks: ticks.ticks.map(d => d / 1000),
                    temperatures: timestampsScaled.map(d => d.temperature),
                    timestampsSource: sensor?.timestamps,
                    temperaturesSource: sensor?.temperatures,
                    interval: ticks.tickDuration / ticks.sampleDuration
                });
            });

            return resScaled;
        } catch (err) {
            console.error(`Failed to fetch temperature sensors data (temperatures), err: ${err}`);
            throw err;
        }
    }

    async _fetchTemperatureSensorsAlarmsData(
        dateRange: DateRange,
        monitoredObjectId: number
    ): Promise<AlarmInDatabaseWithGPSInfo[]> {
        if (this.logic.demo().isActive) {
            return this.logic
                .demo()
                .data.temperatureSensorAlarmsData.filter(
                    alarm => String(alarm.monitoredObjectId) === String(this.coldchainDetail?.monitoredObjectId)
                );
        }

        try {
            const res = await this.logic.api().backendApi.historyV1AlarmsHistoryGet({
                dateFrom: moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                dateTo: moment(dateRange.end, DATE_TIME_FORMAT).toDate(),
                monitoredObjectId
            });

            return res.filter(
                alarm =>
                    alarm.alarmType === AlarmType.TransportColdChainProfileTemperatureHigh ||
                    alarm.alarmType === AlarmType.TransportColdChainProfileTemperatureLow
            );
        } catch (err) {
            console.error(`Failed to fetch temperature sensors data (alarms), err: ${err}`);
            throw err;
        }
    }

    async createColdchainProfile(
        coldchainProfile: ColdchainProfileData
    ): Promise<ReadOnlyProfileSerializer | undefined> {
        const coldchainProfileData: WriteOnlyProfileSerializer = {
            id: coldchainProfile.id,
            name: coldchainProfile.name,
            aboveTemperatureThreshold: coldchainProfile.aboveTemperatureThresholdUse
                ? coldchainProfile.aboveTemperatureThreshold
                : null,
            belowTemperatureThreshold: coldchainProfile.belowTemperatureThresholdUse
                ? coldchainProfile.belowTemperatureThreshold
                : null,
            alarmTimerSeconds: coldchainProfile.alarmTimerSecondsUse
                ? (coldchainProfile.alarmTimerSeconds ?? 0) * 60
                : null,
            client: this.logic.auth().client()?.id!
        };
        try {
            const result = await this.logic.api().coldchainApi.coldChainProfileCreate({
                data: coldchainProfileData
            });
            return result;
        } catch (err) {
            console.error(`Could not create cold chain profiles, err: ${err}`);
        }
        return undefined;
    }

    async updateColdchainProfile(
        coldchainProfile: ColdchainProfileData
    ): Promise<ReadOnlyProfileSerializer | undefined> {
        const coldchainProfileData: WriteOnlyProfileSerializer = {
            id: coldchainProfile.id,
            name: coldchainProfile.name,
            aboveTemperatureThreshold: coldchainProfile.aboveTemperatureThresholdUse
                ? coldchainProfile.aboveTemperatureThreshold
                : null,
            belowTemperatureThreshold: coldchainProfile.belowTemperatureThresholdUse
                ? coldchainProfile.belowTemperatureThreshold
                : null,
            alarmTimerSeconds: coldchainProfile.alarmTimerSecondsUse
                ? (coldchainProfile.alarmTimerSeconds ?? 0) * 60
                : null,
            client: this.logic.auth().client()?.id!
        };
        try {
            const result = await this.logic.api().coldchainApi.coldChainProfilePartialUpdate({
                id: coldchainProfileData?.id!,
                data: coldchainProfileData
            });
            return result;
        } catch (err) {
            console.error(`Could not update cold chain profiles, err: ${err}`);
        }
        return undefined;
    }

    async deleteColdchainProfile(id: string): Promise<boolean> {
        try {
            await this.logic.api().coldchainApi.coldChainProfileDelete({
                id: id
            });
            return true;
        } catch (err) {
            console.error(`Could not delete cold chain profiles, err: ${err}`);
        }
        return false;
    }

    async getColdchainProfile(id: string): Promise<ReadOnlyProfileSerializer | undefined> {
        try {
            const profile = await this.logic.api().coldchainApi.coldChainProfileRead({
                id: id
            });
            return profile;
        } catch (err) {
            console.error(`Could not get cold chain profiles, err: ${err}`);
        }
        return undefined;
    }

    async getTemperatureLog(monitoredObjectId: number, dateRange: DateRange): Promise<void> {
        this.temperatureLogLoading = true;
        try {
            const timeLogRes = await this.logic.api().documentApi.getTemperatureLogV1DocumentTemperatureLogGetRaw({
                timestampFrom: moment(dateRange.start, DATE_TIME_FORMAT).toDate(),
                timestampTo: moment(dateRange.end, DATE_TIME_FORMAT).toDate(),
                monitoredObjectId: String(monitoredObjectId)
            });

            const timeLogBlob = await (timeLogRes as any)?.raw?.blob();
            downloadFile(timeLogBlob, `${i18n.t('Coldchain.temperatureLog.fileName')}.xlsx`);
            this.temperatureLogLoading = false;
        } catch (err) {
            this.temperatureLogLoading = false;
            message.error(i18n.t('Coldchain.temperatureLog.errorMessage'));
            console.error('Can not get temperature log export, err: ', err);
            throw err;
        }
    }

    @action
    reset() {
        this.coldchainFilter = {
            monitoredObjectIds: [],
            textSearch: '',
            showDeleted: false
        };
        this.temperatureSensors = [];
        this.mobjectsTemperatureSensors = [];
        this.temperatureSensorsState = [];
        this.temperatureProfiles = undefined;
        this.temperatureSensorsData = undefined;
        this.coldchainDetail = undefined;
        this.temperatureSensorsAlarmsData = undefined;
        this.detailedTemperatureSensors = undefined;

        this.coldchainLoading = false;
        this.coldchainDetailLoading = false;
    }
}
