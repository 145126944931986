/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AETRActivity {
    Drive = 'drive',
    Rest = 'rest',
    Work = 'work',
    Available = 'available'
}

export function AETRActivityFromJSON(json: any): AETRActivity {
    return AETRActivityFromJSONTyped(json, false);
}

export function AETRActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AETRActivity {
    return json as AETRActivity;
}

export function AETRActivityToJSON(value?: AETRActivity | null): any {
    return value as any;
}

