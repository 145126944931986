/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectProfileMerged,
    MonitoredObjectProfileMergedFromJSON,
    MonitoredObjectProfileMergedFromJSONTyped,
    MonitoredObjectProfileMergedToJSON,
    MonitoredObjectTrailerProfile,
    MonitoredObjectTrailerProfileFromJSON,
    MonitoredObjectTrailerProfileFromJSONTyped,
    MonitoredObjectTrailerProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectMetadata
 */
export interface MonitoredObjectMetadata {
    /**
     * 
     * @type {MonitoredObjectProfileMerged}
     * @memberof MonitoredObjectMetadata
     */
    profile?: MonitoredObjectProfileMerged;
    /**
     * 
     * @type {MonitoredObjectTrailerProfile}
     * @memberof MonitoredObjectMetadata
     */
    trailerProfile?: MonitoredObjectTrailerProfile;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectMetadata
     */
    trailerIdSerialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectMetadata
     */
    customLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectMetadata
     */
    licensePlateCountry?: string;
}

export function MonitoredObjectMetadataFromJSON(json: any): MonitoredObjectMetadata {
    return MonitoredObjectMetadataFromJSONTyped(json, false);
}

export function MonitoredObjectMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': !exists(json, 'profile') ? undefined : MonitoredObjectProfileMergedFromJSON(json['profile']),
        'trailerProfile': !exists(json, 'trailerProfile') ? undefined : MonitoredObjectTrailerProfileFromJSON(json['trailerProfile']),
        'trailerIdSerialNumber': !exists(json, 'trailerIdSerialNumber') ? undefined : json['trailerIdSerialNumber'],
        'customLabel': !exists(json, 'customLabel') ? undefined : json['customLabel'],
        'licensePlateCountry': !exists(json, 'licensePlateCountry') ? undefined : json['licensePlateCountry'],
    };
}

export function MonitoredObjectMetadataToJSON(value?: MonitoredObjectMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': MonitoredObjectProfileMergedToJSON(value.profile),
        'trailerProfile': MonitoredObjectTrailerProfileToJSON(value.trailerProfile),
        'trailerIdSerialNumber': value.trailerIdSerialNumber,
        'customLabel': value.customLabel,
        'licensePlateCountry': value.licensePlateCountry,
    };
}


