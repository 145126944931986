import {
    GeoJsonPointType,
    GeoJsonPointTypeInput,
    GeoJsonPolygonType,
    GeoJsonPolygonTypeInput
} from 'generated/graphql';
import { LatLng } from 'common/model/geo';

export function toGeoJsonPointTypeInput(position: LatLng): GeoJsonPointTypeInput {
    return {
        type: 'Point',
        coordinates: [position.lng, position.lat]
    };
}

export function toGeoJsonPolygonTypeInput(positions: LatLng[][]): GeoJsonPolygonTypeInput {
    // geojson needs to have same first and last place in polygons;
    // https://tools.ietf.org/html/rfc7946#section-3.1.6
    positions.forEach(polygon => {
        if (polygon.length > 0) {
            const firstCoords = polygon[0];
            const lastCoords = polygon[polygon.length - 1];

            if (firstCoords.lat !== lastCoords.lat || firstCoords.lng !== lastCoords.lng) {
                polygon.push({
                    lat: firstCoords.lat,
                    lng: firstCoords.lng
                });
            }
        }
    });

    return {
        type: 'Polygon',
        coordinates: positions.map(pol => pol.map(p => [p.lng, p.lat]))
    };
}

export function latLngFromGeoJsonPointType(geoJson: GeoJsonPointType): LatLng {
    return {
        lat: geoJson.coordinates ? geoJson.coordinates[1] : -1,
        lng: geoJson.coordinates ? geoJson.coordinates[0] : -1
    };
}

export function latLngFromGeoJsonPolygonType(geoJson: GeoJsonPolygonType): LatLng[][] {
    // latLng format use in maps should not have same last and first place in polygon.
    // developers.google.com/maps/documentation/javascript/reference/polygon#PolygonOptions.paths
    if (geoJson.coordinates && geoJson.coordinates[0].length > 0) {
        geoJson.coordinates.forEach(pol => {
            const firstCoords = pol?.[0];
            const lastCoords = pol?.[pol.length - 1];

            if (firstCoords?.[0] === lastCoords?.[0] && firstCoords?.[1] === lastCoords?.[1]) {
                pol.pop();
            }
        });
    }

    return geoJson.coordinates
        ? geoJson.coordinates.map<LatLng[]>(pol =>
              pol.map(p => ({
                  lat: p?.[1],
                  lng: p?.[0]
              }))
          )
        : [];
}

export function centerToLatLngObject(center: [number, number]): LatLng {
    return {
        lat: center[1],
        lng: center[0]
    };
}

export function centerToLatLngTuple(center: LatLng): [number, number] {
    return [center.lng, center.lat];
}
