import { DriverBehaviorModel, DriverBehaviorLightVehicleModel } from '../model/statistics';
import { users } from './users';
import { DriverBehaviourTrendsResponseFromJSON } from 'generated/backend-api';
import moment from 'moment';
import { UserTokenTokenTypeEnum } from 'generated/new-main';

const driveTime = [
    47 * 3600 + 41 * 60 + 53,
    66 * 3600 + 11 * 60 + 13,
    135 * 3600 + 51 * 60 + 46,
    130 * 3600 + 51 * 60 + 20,
    124 * 3600 + 34 * 60 + 20,
    145 * 3600 + 14 * 60 + 20
];
const engineOn = [
    57 * 3600 + 22 * 60 + 13,
    86 * 3600 + 19 * 60 + 22,
    165 * 3600 + 28 * 60 + 6,
    138 * 3600 + 0 * 60 + 42,
    126 * 3600 + 24 * 60 + 58,
    148 * 3600 + 33 * 60 + 43
];
const driveTimePrev = [
    (47 * 3600 + 41 * 60 + 53) * 0.97,
    (66 * 3600 + 11 * 60 + 13) * 0.96,
    (135 * 3600 + 51 * 60 + 46) * 0.94,
    (130 * 3600 + 51 * 60 + 20) * 0.95,
    (124 * 3600 + 34 * 60 + 20) * 0.95,
    (145 * 3600 + 14 * 60 + 20) * 0.95
];
const engineOnPrev = [
    (57 * 3600 + 22 * 60 + 13) * 0.74,
    (86 * 3600 + 19 * 60 + 22) * 0.9,
    (165 * 3600 + 28 * 60 + 6) * 0.95,
    (138 * 3600 + 0 * 60 + 42) * 0.97,
    (126 * 3600 + 24 * 60 + 58) * 1.09,
    (148 * 3600 + 33 * 60 + 43) * 1.11
];
const maxRpm = [2136, 1798, 1921, 2014, 1688, 1992];
const maxRpmPrev = [2136 * 0.89, 1798 * 0.99, 1921 * 1.08, 2014 * 0.91, 1688 * 1.15, 1992 * 1.1];
const kickDown = [
    4 * 60 + 48,
    18 * 60 + 21,
    3 * 3600 + 33 * 60 + 45,
    1 * 3600 + 8 * 60 + 6,
    40 * 60 + 51,
    15 * 60 + 40
];
const kickDownPrev = [
    (4 * 60 + 48) * 0.98,
    (18 * 60 + 21) * 0.95,
    (3 * 3600 + 33 * 60 + 45) * 0.91,
    (1 * 3600 + 8 * 60 + 6) * 1.16,
    (40 * 60 + 51) * 1.03,
    (15 * 60 + 40) * 0.95
];
const accelerationTimeWithCruiseControl = [
    2 * 3600 + 19 * 60 + 36,
    1 * 3600 + 21 * 60 + 21,
    3 * 3600 + 20 * 60 + 8,
    1 * 3600 + 41 * 60 + 54,
    8 * 60 + 57,
    16 * 60 + 25
];
const accelerationTimeWithCruiseControlPrev = [
    (2 * 3600 + 19 * 60 + 36) * 0.95,
    (1 * 3600 + 21 * 60 + 21) * 0.98,
    (3 * 3600 + 20 * 60 + 8) * 0.99,
    (1 * 3600 + 41 * 60 + 54) * 0.92,
    (8 * 60 + 57) * 1.12,
    (16 * 60 + 25) * 1.06
];
const driveTimeWithoutConsumptionWithEcoroll = [
    (3 * 3600 + 26 * 60 + 36) * 0.95,
    (2 * 3600 + 21 * 60 + 40) * 0.98,
    (0 * 3600 + 20 * 60 + 8) * 0.99,
    (1 * 3600 + 54 * 60 + 54) * 0.92,
    (54 * 60 + 13) * 1.12,
    (16 * 60 + 25) * 1.06
];
const driveTimeWithoutConsumptionWithEcorollPrev = [
    (3 * 3600 + 26 * 60 + 36) * 0.91,
    (2 * 3600 + 21 * 60 + 40) * 1.05,
    (0 * 3600 + 20 * 60 + 8) * 0.94,
    (1 * 3600 + 54 * 60 + 54) * 0.933,
    (54 * 60 + 13) * 1.22,
    (16 * 60 + 25) * 0.863
];
const retarderTime = [
    1 * 3600 + 16 * 60 + 59,
    2 * 3600 + 26 * 60 + 55,
    1 * 3600 + 43 * 60 + 54,
    1 * 3600 + 36 * 60 + 11,
    2 * 3600 + 12 * 60 + 20,
    0 * 3600 + 21 * 60 + 61
];
const retarderTimePrev = [
    (1 * 3600 + 16 * 60 + 59) * 1.06,
    (2 * 3600 + 26 * 60 + 55) * 0.99,
    (1 * 3600 + 43 * 60 + 54) * 0.91,
    (1 * 3600 + 36 * 60 + 11) * 0.93,
    (2 * 3600 + 12 * 60 + 20) * 0.97,
    (0 * 3600 + 21 * 60 + 61) * 0.98
];
const fullDistance = [2546, 3520, 8867, 8836, 7893, 5804];
const fullDistancePrev = [2546 * 0.95, 3520 * 0.89, 8867 * 0.78, 8836 * 0.79, 7893 * 0.05, 5804 * 0.93];
const maxSpeedWithParkingBrake = [0, 15, 0, 0, 10, 0];

const driveDataBatch = [
    {
        highway: {
            drive_time: 97068.0,
            engine_on_time: 97068.0,
            avg_speed: 78.780504388676,
            cnt_takeoff: 14.0,
            brake_distance: 11.519999999999998,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 2124.1850000000004,
            spend_in_driving_mode_percentage: 36.017677114370635
        },
        nocity: {
            drive_time: 39658.0,
            engine_on_time: 39675.0,
            avg_speed: 60.28397801200262,
            cnt_takeoff: 10.0,
            brake_distance: 44.13500000000001,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 664.095,
            spend_in_driving_mode_percentage: 14.715344284436792
        },
        city: {
            drive_time: 106022.0,
            engine_on_time: 106681.0,
            avg_speed: 39.90535926505819,
            cnt_takeoff: 188.0,
            brake_distance: 104.01499999999999,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1175.2349999999997,
            spend_in_driving_mode_percentage: 39.34011376581163
        },
        other: {
            drive_time: 26753.0,
            engine_on_time: 33849.0,
            avg_speed: 10.041864463798452,
            cnt_takeoff: 359.0,
            brake_distance: 4.9324999999999966,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 74.625,
            spend_in_driving_mode_percentage: 9.926864835380945
        },
        summary: {
            drive_time: 269501.0,
            engine_on_time: 277273.0,
            avg_speed: 53.94155865840943,
            cnt_takeoff: 571.0,
            brake_distance: 164.60250000000002,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 4038.1399999999994
        }
    },
    {
        highway: {
            drive_time: 52368.0,
            engine_on_time: 52368.0,
            avg_speed: 76.30545371219065,
            cnt_takeoff: 1.0,
            brake_distance: 0.0,
            cruise_control_time: 8014.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1109.99,
            spend_in_driving_mode_percentage: 19.43211672331646
        },
        nocity: {
            drive_time: 66898.0,
            engine_on_time: 66900.0,
            avg_speed: 62.230739334509245,
            cnt_takeoff: 1.0,
            brake_distance: 0.0,
            cruise_control_time: 145.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1156.4199999999998,
            spend_in_driving_mode_percentage: 24.823742448755436
        },
        city: {
            drive_time: 133038.0,
            engine_on_time: 135383.0,
            avg_speed: 40.05046678392638,
            cnt_takeoff: 213.0,
            brake_distance: 0.0,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1480.0649999999994,
            spend_in_driving_mode_percentage: 49.366214952577444
        },
        other: {
            drive_time: 17188.0,
            engine_on_time: 37220.0,
            avg_speed: 12.324994181987437,
            cnt_takeoff: 513.0,
            brake_distance: 0.0,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 58.84500000000002,
            spend_in_driving_mode_percentage: 6.37792587535066
        },
        summary: {
            drive_time: 269492.0,
            engine_on_time: 291871.0,
            avg_speed: 50.833241803096186,
            cnt_takeoff: 728.0,
            brake_distance: 0.0,
            cruise_control_time: 8159.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 3805.3199999999993
        }
    },
    {
        highway: {
            drive_time: 71587.0,
            engine_on_time: 71588.0,
            avg_speed: 82.06422953888276,
            cnt_takeoff: 12.0,
            brake_distance: 9.57,
            cruise_control_time: 64948.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1631.87,
            spend_in_driving_mode_percentage: 32.55893281908773
        },
        nocity: {
            drive_time: 11810.0,
            engine_on_time: 11814.0,
            avg_speed: 59.803895004233674,
            cnt_takeoff: 0.0,
            brake_distance: 14.979999999999997,
            cruise_control_time: 1962.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 196.1899999999999,
            spend_in_driving_mode_percentage: 5.3713802309557055
        },
        city: {
            drive_time: 102162.0,
            engine_on_time: 106118.0,
            avg_speed: 33.1222763845657,
            cnt_takeoff: 347.0,
            brake_distance: 82.95500000000004,
            cruise_control_time: 2612.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 939.9550000000004,
            spend_in_driving_mode_percentage: 46.46494048729016
        },
        other: {
            drive_time: 34310.0,
            engine_on_time: 52598.0,
            avg_speed: 13.685980763625768,
            cnt_takeoff: 735.0,
            brake_distance: 6.132499999999995,
            cruise_control_time: 6.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 130.43500000000003,
            spend_in_driving_mode_percentage: 15.604746462666405
        },
        summary: {
            drive_time: 219869.0,
            engine_on_time: 242118.0,
            avg_speed: 47.457440566883015,
            cnt_takeoff: 1094.0,
            brake_distance: 113.63750000000003,
            cruise_control_time: 69528.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 2898.4500000000003
        }
    },
    {
        highway: {
            drive_time: 52368.0,
            engine_on_time: 52368.0,
            avg_speed: 76.30545371219065,
            cnt_takeoff: 1.0,
            brake_distance: 0.0,
            cruise_control_time: 8014.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1109.99,
            spend_in_driving_mode_percentage: 19.43211672331646
        },
        nocity: {
            drive_time: 66898.0,
            engine_on_time: 66900.0,
            avg_speed: 62.230739334509245,
            cnt_takeoff: 1.0,
            brake_distance: 0.0,
            cruise_control_time: 145.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1156.4199999999998,
            spend_in_driving_mode_percentage: 24.823742448755436
        },
        city: {
            drive_time: 133038.0,
            engine_on_time: 135383.0,
            avg_speed: 40.05046678392638,
            cnt_takeoff: 213.0,
            brake_distance: 0.0,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1480.0649999999994,
            spend_in_driving_mode_percentage: 49.366214952577444
        },
        other: {
            drive_time: 17188.0,
            engine_on_time: 37220.0,
            avg_speed: 12.324994181987437,
            cnt_takeoff: 513.0,
            brake_distance: 0.0,
            cruise_control_time: 0.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 58.84500000000002,
            spend_in_driving_mode_percentage: 6.37792587535066
        },
        summary: {
            drive_time: 269492.0,
            engine_on_time: 291871.0,
            avg_speed: 50.833241803096186,
            cnt_takeoff: 728.0,
            brake_distance: 0.0,
            cruise_control_time: 8159.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 3805.3199999999993
        }
    },
    {
        highway: {
            drive_time: 71587.0,
            engine_on_time: 71588.0,
            avg_speed: 82.06422953888276,
            cnt_takeoff: 12.0,
            brake_distance: 9.57,
            cruise_control_time: 64948.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 1631.87,
            spend_in_driving_mode_percentage: 32.55893281908773
        },
        nocity: {
            drive_time: 11810.0,
            engine_on_time: 11814.0,
            avg_speed: 59.803895004233674,
            cnt_takeoff: 0.0,
            brake_distance: 14.979999999999997,
            cruise_control_time: 1962.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 196.1899999999999,
            spend_in_driving_mode_percentage: 5.3713802309557055
        },
        city: {
            drive_time: 102162.0,
            engine_on_time: 106118.0,
            avg_speed: 33.1222763845657,
            cnt_takeoff: 347.0,
            brake_distance: 82.95500000000004,
            cruise_control_time: 2612.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 939.9550000000004,
            spend_in_driving_mode_percentage: 46.46494048729016
        },
        other: {
            drive_time: 34310.0,
            engine_on_time: 52598.0,
            avg_speed: 13.685980763625768,
            cnt_takeoff: 735.0,
            brake_distance: 6.132499999999995,
            cruise_control_time: 6.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 130.43500000000003,
            spend_in_driving_mode_percentage: 15.604746462666405
        },
        summary: {
            drive_time: 219869.0,
            engine_on_time: 242118.0,
            avg_speed: 47.457440566883015,
            cnt_takeoff: 1094.0,
            brake_distance: 113.63750000000003,
            cruise_control_time: 69528.0,
            cruise_control_percentage: -1.0,
            avg_raising: -1.0,
            distance: 2898.4500000000003
        }
    }
];

const savingDataBatch = [
    {
        drive_consumption: 650,
        idle_consumption: 0,
        avg_consumption_without_pto: -1,
        increased_rpm_consumption_idle: 6,
        avg_consumption_with_cruise_control: -1,
        avg_consumption_with_cruise_control_to_avg_consumption: -1,
        avg_speed_with_cruise_control: -1,
        stay_idle_cnt_dp: -1,
        max_rpm: 2074,
        drive_time_without_consumption_with_ecoroll: 35278,
        drive_time_without_consumption_with_ecoroll_percentage: -1,
        constant_acceleration_time: 106588,
        constant_acceleration_time_percentage: -1,
        acceleration_time_with_cruise_control: 759,
        acceleration_time_with_cruise_control_percentage: -1,
        kickdown_time: 0,
        drive_time_85: 7,
        drive_time_85_percentage: -1,
        rpm_over_time: 405,
        rpm_over_time_percentage: -1,
        predictability_time: 46736,
        distance_ecoroll: 5,
        distance_ecoroll_percentage: -1
    },
    {
        drive_consumption: 564,
        idle_consumption: 0,
        avg_consumption_without_pto: -1,
        increased_rpm_consumption_idle: 0,
        avg_consumption_with_cruise_control: -1,
        avg_consumption_with_cruise_control_to_avg_consumption: -1,
        avg_speed_with_cruise_control: -1,
        stay_idle_cnt_dp: -1,
        max_rpm: 2822,
        drive_time_without_consumption_with_ecoroll: 269492,
        drive_time_without_consumption_with_ecoroll_percentage: -1,
        constant_acceleration_time: 120336,
        constant_acceleration_time_percentage: -1,
        acceleration_time_with_cruise_control: 8129,
        acceleration_time_with_cruise_control_percentage: -1,
        kickdown_time: 0,
        drive_time_85: 48,
        drive_time_85_percentage: -1,
        rpm_over_time: 10706,
        rpm_over_time_percentage: -1,
        predictability_time: 85535,
        distance_ecoroll: 0,
        distance_ecoroll_percentage: -1
    },
    {
        drive_consumption: 668,
        idle_consumption: 0,
        avg_consumption_without_pto: -1,
        increased_rpm_consumption_idle: 0,
        avg_consumption_with_cruise_control: -1,
        avg_consumption_with_cruise_control_to_avg_consumption: -1,
        avg_speed_with_cruise_control: -1,
        stay_idle_cnt_dp: -1,
        max_rpm: 1772,
        drive_time_without_consumption_with_ecoroll: 51846,
        drive_time_without_consumption_with_ecoroll_percentage: -1,
        constant_acceleration_time: 152963,
        constant_acceleration_time_percentage: -1,
        acceleration_time_with_cruise_control: 0,
        acceleration_time_with_cruise_control_percentage: -1,
        kickdown_time: 0,
        drive_time_85: 55,
        drive_time_85_percentage: -1,
        rpm_over_time: 63618,
        rpm_over_time_percentage: -1,
        predictability_time: 102795,
        distance_ecoroll: 25,
        distance_ecoroll_percentage: -1
    },
    {
        drive_consumption: 732,
        idle_consumption: 0,
        avg_consumption_without_pto: -1,
        increased_rpm_consumption_idle: 0,
        avg_consumption_with_cruise_control: -1,
        avg_consumption_with_cruise_control_to_avg_consumption: -1,
        avg_speed_with_cruise_control: -1,
        stay_idle_cnt_dp: -1,
        max_rpm: 1932,
        drive_time_without_consumption_with_ecoroll: 51846,
        drive_time_without_consumption_with_ecoroll_percentage: -1,
        constant_acceleration_time: 152963,
        constant_acceleration_time_percentage: -1,
        acceleration_time_with_cruise_control: 0,
        acceleration_time_with_cruise_control_percentage: -1,
        kickdown_time: 0,
        drive_time_85: 55,
        drive_time_85_percentage: -1,
        rpm_over_time: 63618,
        rpm_over_time_percentage: -1,
        predictability_time: 102795,
        distance_ecoroll: 25,
        distance_ecoroll_percentage: -1
    }
];

const wearTearDataBatch = [
    {
        parking_brake_count: 0,
        avg_parking_break_count: 0.0,
        max_speed_with_parking_brake: 0,
        max_rpm: 2022,
        max_rpm_city: 2022,
        max_rpm_out_of_city: 1932,
        max_rpm_highway: 1600,
        retarder_count: 0,
        avg_retarder_count: 0.0,
        retarder_time: 0,
        retarder_time_percents: -1,
        distance_with_retarder: 0.0,
        distance_with_retarder_percentage: -1,
        max_rpm_with_retarder: 0,
        service_brake_count: 0,
        avg_service_brake_count: 0.0,
        distance_with_service_brake: 0.0,
        distance_with_service_brake_percentage: -1,
        service_brake_vs_retarder_percentage: 100.0
    },
    {
        parking_brake_count: 0,
        avg_parking_break_count: 0.0,
        max_speed_with_parking_brake: 12,
        max_rpm: 2074,
        max_rpm_city: 2074,
        max_rpm_out_of_city: 1655,
        max_rpm_highway: 1604,
        retarder_count: 2420,
        avg_retarder_count: 83.4929013783229,
        retarder_time: 9902,
        retarder_time_percents: -1,
        distance_with_retarder: 97.85500000000003,
        distance_with_retarder_percentage: -1,
        max_rpm_with_retarder: 2604,
        service_brake_count: 1672,
        avg_service_brake_count: 57.68600458865944,
        distance_with_service_brake: 21.914999999999996,
        distance_with_service_brake_percentage: -1,
        service_brake_vs_retarder_percentage: 22.39538092075008
    },
    {
        parking_brake_count: 0,
        avg_parking_break_count: 0.0,
        max_speed_with_parking_brake: 65,
        max_rpm: 1772,
        max_rpm_city: 1682,
        max_rpm_out_of_city: 1745,
        max_rpm_highway: 1772,
        retarder_count: 1487,
        avg_retarder_count: 36.823884263547086,
        retarder_time: 12029,
        retarder_time_percents: -1,
        distance_with_retarder: 138.07999999999998,
        distance_with_retarder_percentage: -1,
        max_rpm_with_retarder: 2976,
        service_brake_count: 1637,
        avg_service_brake_count: 40.53846572927141,
        distance_with_service_brake: 31.444999999999986,
        distance_with_service_brake_percentage: -1,
        service_brake_vs_retarder_percentage: 22.773030127462334
    },
    {
        parking_brake_count: 0,
        avg_parking_break_count: 0.0,
        max_speed_with_parking_brake: 0,
        max_rpm: 2655,
        max_rpm_city: 2655,
        max_rpm_out_of_city: 2314,
        max_rpm_highway: 1963,
        retarder_count: 0,
        avg_retarder_count: 0.0,
        retarder_time: 0,
        retarder_time_percents: -1,
        distance_with_retarder: 0.0,
        distance_with_retarder_percentage: -1,
        max_rpm_with_retarder: 0,
        service_brake_count: 0,
        avg_service_brake_count: 0.0,
        distance_with_service_brake: 0.0,
        distance_with_service_brake_percentage: -1,
        service_brake_vs_retarder_percentage: 100.0
    },
    {
        parking_brake_count: 0,
        avg_parking_break_count: 0.0,
        max_speed_with_parking_brake: 43,
        max_rpm: 1815,
        max_rpm_city: 1815,
        max_rpm_out_of_city: 1645,
        max_rpm_highway: 1710,
        retarder_count: 1487,
        avg_retarder_count: 21.823884263547086,
        retarder_time: 8930,
        retarder_time_percents: -1,
        distance_with_retarder: 140.07999999999998,
        distance_with_retarder_percentage: -1,
        max_rpm_with_retarder: 2206,
        service_brake_count: 1300,
        avg_service_brake_count: 32.53846572927141,
        distance_with_service_brake: 25.444999999999986,
        distance_with_service_brake_percentage: -1,
        service_brake_vs_retarder_percentage: 20.773030127462334
    }
];

const driverBehaviorTrendsTrucksRaw = {
    data: [
        {
            start: moment().startOf('month').format('YYYY-MM-DD'),
            score: [
                {
                    id: '',
                    rank: 4,
                    overal_score: 70.70087423745417,
                    drive_score: 18.56619120419158,
                    eco_score: 95.53643150817092,
                    wear_tear_score: 21.9,
                    general_data: {
                        driver1: {
                            id: users[0].id,
                            name: users[0].name,
                            surname: users[0].surname,
                            tachoCard: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[0].id,
                            name: users[0].name,
                            surname: users[0].surname,
                            tachoCard: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['1'],
                        engine_on_time: 291871,
                        drive_time: 269492,
                        idling_time: 2704,
                        idling_time_percentage: -1,
                        idling_time_pto: 988,
                        idling_time_pto_percentage: -1,
                        distance: 3805,
                        consumption: 0
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 6,
                    overal_score: 44.09576169539467,
                    drive_score: 65.68963640470766,
                    eco_score: 48.353742455735,
                    wear_tear_score: 83.24390622574134,
                    general_data: {
                        driver1: {
                            id: users[1].id,
                            name: users[1].name,
                            surname: users[1].surname,
                            tachoCard: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[1].id,
                            name: users[1].name,
                            surname: users[1].surname,
                            tachoCard: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['2'],
                        engine_on_time: 242118,
                        drive_time: 219869,
                        idling_time: 151,
                        idling_time_percentage: -1,
                        idling_time_pto: 7721,
                        idling_time_pto_percentage: -1,
                        distance: 2898,
                        consumption: 667
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 2,
                    overal_score: 80.15921096867389,
                    drive_score: 60.50548606498678,
                    eco_score: 65.7508469356329,
                    wear_tear_score: 99.22129990540199,
                    general_data: {
                        driver1: {
                            id: users[2].id,
                            name: users[2].name,
                            surname: users[2].surname,
                            tachoCard: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[2].id,
                            name: users[2].name,
                            surname: users[2].surname,
                            tachoCard: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4247', '24710'],
                        engine_on_time: 277273,
                        drive_time: 269501,
                        idling_time: 107,
                        idling_time_percentage: -1,
                        idling_time_pto: 138,
                        idling_time_pto_percentage: -1,
                        distance: 4038,
                        consumption: 673
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 1,
                    overal_score: 82.70087423745417,
                    drive_score: 19.56619120419158,
                    eco_score: 98.53643150817092,
                    wear_tear_score: 29.3,
                    general_data: {
                        driver1: {
                            id: users[3].id,
                            name: users[3].name,
                            surname: users[3].surname,
                            tachoCard: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[3].id,
                            name: users[3].name,
                            surname: users[3].surname,
                            tachoCard: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4'],
                        engine_on_time: 291871,
                        drive_time: 269492,
                        idling_time: 2704,
                        idling_time_percentage: -1,
                        idling_time_pto: 988,
                        idling_time_pto_percentage: -1,
                        distance: 3805,
                        consumption: 0
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 5,
                    overal_score: 67.09576169539467,
                    drive_score: 67.68963640470766,
                    eco_score: 47.353742455735,
                    wear_tear_score: 95.24390622574134,
                    general_data: {
                        driver1: {
                            id: users[4].id,
                            name: users[4].name,
                            surname: users[4].surname,
                            tachoCard: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[4].id,
                            name: users[4].name,
                            surname: users[4].surname,
                            tachoCard: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['5'],
                        engine_on_time: 242118,
                        drive_time: 219869,
                        idling_time: 151,
                        idling_time_percentage: -1,
                        idling_time_pto: 7721,
                        idling_time_pto_percentage: -1,
                        distance: 2898,
                        consumption: 667
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 3,
                    overal_score: 72.15921096867389,
                    drive_score: 60.50548606498678,
                    eco_score: 80.7508469356329,
                    wear_tear_score: 97.22129990540199,
                    general_data: {
                        driver1: {
                            id: users[5].id,
                            name: users[5].name,
                            surname: users[5].surname,
                            tachoCard: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[5].id,
                            name: users[5].name,
                            surname: users[5].surname,
                            tachoCard: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4247', '24710'],
                        engine_on_time: 277273,
                        drive_time: 269501,
                        idling_time: 107,
                        idling_time_percentage: -1,
                        idling_time_pto: 138,
                        idling_time_pto_percentage: -1,
                        distance: 4038,
                        consumption: 673
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                }
            ]
        },
        {
            start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            score: [
                {
                    id: '',
                    rank: 4,
                    overal_score: 68.70087423745417,
                    drive_score: 65.56619120419158,
                    eco_score: 81.53643150817092,
                    wear_tear_score: 31.7,
                    general_data: {
                        driver1: {
                            id: users[0].id,
                            name: users[0].name,
                            surname: users[0].surname,
                            tachoCard: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[0].id,
                            name: users[0].name,
                            surname: users[0].surname,
                            tachoCard: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['1'],
                        engine_on_time: 291871,
                        drive_time: 269492,
                        idling_time: 2704,
                        idling_time_percentage: -1,
                        idling_time_pto: 988,
                        idling_time_pto_percentage: -1,
                        distance: 3805,
                        consumption: 0
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 6,
                    overal_score: 45.09576169539467,
                    drive_score: 63.68963640470766,
                    eco_score: 29.353742455735,
                    wear_tear_score: 98.24390622574134,
                    general_data: {
                        driver1: {
                            id: users[1].id,
                            name: users[1].name,
                            surname: users[1].surname,
                            tachoCard: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[1].id,
                            name: users[1].name,
                            surname: users[1].surname,
                            tachoCard: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['2'],
                        engine_on_time: 242118,
                        drive_time: 219869,
                        idling_time: 151,
                        idling_time_percentage: -1,
                        idling_time_pto: 7721,
                        idling_time_pto_percentage: -1,
                        distance: 2898,
                        consumption: 667
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 2,
                    overal_score: 79.15921096867389,
                    drive_score: 71.50548606498678,
                    eco_score: 66.7508469356329,
                    wear_tear_score: 99.22129990540199,
                    general_data: {
                        driver1: {
                            id: users[2].id,
                            name: users[2].name,
                            surname: users[2].surname,
                            tachoCard: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[2].id,
                            name: users[2].name,
                            surname: users[2].surname,
                            tachoCard: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4247', '24710'],
                        engine_on_time: 277273,
                        drive_time: 269501,
                        idling_time: 107,
                        idling_time_percentage: -1,
                        idling_time_pto: 138,
                        idling_time_pto_percentage: -1,
                        distance: 4038,
                        consumption: 673
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 1,
                    overal_score: 83.70087423745417,
                    drive_score: 13.56619120419158,
                    eco_score: 93.53643150817092,
                    wear_tear_score: 41.4,
                    general_data: {
                        driver1: {
                            id: users[3].id,
                            name: users[3].name,
                            surname: users[3].surname,
                            tachoCard: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[3].id,
                            name: users[3].name,
                            surname: users[3].surname,
                            tachoCard: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4'],
                        engine_on_time: 291871,
                        drive_time: 269492,
                        idling_time: 2704,
                        idling_time_percentage: -1,
                        idling_time_pto: 988,
                        idling_time_pto_percentage: -1,
                        distance: 3805,
                        consumption: 0
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 5,
                    overal_score: 70.09576169539467,
                    drive_score: 73.68963640470766,
                    eco_score: 32.353742455735,
                    wear_tear_score: 99.24390622574134,
                    general_data: {
                        driver1: {
                            id: users[4].id,
                            name: users[4].name,
                            surname: users[4].surname,
                            tachoCard: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[4].id,
                            name: users[4].name,
                            surname: users[4].surname,
                            tachoCard: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['5'],
                        engine_on_time: 242118,
                        drive_time: 219869,
                        idling_time: 151,
                        idling_time_percentage: -1,
                        idling_time_pto: 7721,
                        idling_time_pto_percentage: -1,
                        distance: 2898,
                        consumption: 667
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                },
                {
                    id: '',
                    rank: 3,
                    overal_score: 75.15921096867389,
                    drive_score: 71.50548606498678,
                    eco_score: 66.7508469356329,
                    wear_tear_score: 99.22129990540199,
                    general_data: {
                        driver1: {
                            id: users[5].id,
                            name: users[5].name,
                            surname: users[5].surname,
                            tachoCard: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        driver2: {
                            id: users[5].id,
                            name: users[5].name,
                            surname: users[5].surname,
                            tachoCard: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                                ?.token
                        },
                        monitored_object_id: ['4247', '24710'],
                        engine_on_time: 277273,
                        drive_time: 269501,
                        idling_time: 107,
                        idling_time_percentage: -1,
                        idling_time_pto: 138,
                        idling_time_pto_percentage: -1,
                        distance: 4038,
                        consumption: 673
                    },
                    drive_data: driveDataBatch[Math.floor(Math.random() * driveDataBatch.length)],
                    saving_data: savingDataBatch[Math.floor(Math.random() * savingDataBatch.length)],
                    wear_data: wearTearDataBatch[Math.floor(Math.random() * savingDataBatch.length)]
                }
            ]
        }
    ],
    number_of_drivers: 6,
    contextual_data: [
        {
            start: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
            cross_system_best_driver_overal_score: 87.58216334499328,
            cross_system_avg_driver_overal_score: 82.0551809128116,
            client_avg_driver_overal_score: 82.0551809128116
        },
        {
            start: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
            cross_system_best_driver_overal_score: 99.58216334499328,
            cross_system_avg_driver_overal_score: 82.0551809128116,
            client_avg_driver_overal_score: 82.0551809128116
        },
        {
            start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            cross_system_best_driver_overal_score: 80.38579148543562,
            cross_system_avg_driver_overal_score: 86.01857468753143,
            client_avg_driver_overal_score: 86.01857468753143
        },
        {
            start: moment().startOf('month').format('YYYY-MM-DD'),
            cross_system_best_driver_overal_score: 75.15604695361276,
            cross_system_avg_driver_overal_score: 81.72604859046902,
            client_avg_driver_overal_score: 81.72604859046902
        }
    ]
};

export const driverBehaviorTrendsTrucks = DriverBehaviourTrendsResponseFromJSON(driverBehaviorTrendsTrucksRaw);

export const driverBehaviorTrucks: DriverBehaviorModel[] = [
    {
        id: '1',
        rank: 1,
        name: `${users[0].name} ${users[0].surname}`,
        tachocard: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[0].id,
        score: 4.2,
        driveScore: 3.9,
        ecoScore: 3.7,
        wearTearScore: 4.9,
        detail: {
            fullDistance: fullDistance[0],
            driverTime: driveTime[0],
            consumption: 314,
            avgElevation: 13900,
            avgSpeed: 53.9,
            avgWeight: 7569,
            brakingDistance: 567,
            driverPredictability: 3600,
            distanceEcoroll: 300,
            consumptionRPM: 34,
            rpmOptimum: 1200,
            idleConsumption: 4,
            rpmOverTime: 13 * 3600 + 19 * 60 + 47,
            constantAccelerationTime: 20 * 3600 + 19 * 60 + 34,
            driveTime85: 1 * 3600 + 45 * 60 + 13,
            avgParkingBreakCount: 1,
            avgRetarderCount: 50,
            avgServiceBrakeCount: 46,
            serviceBrakeVsRetarderPercentage: 35.32,
            engineOnTime: engineOn[0],
            idlingTime: engineOn[0] - driveTime[0],
            cruiseControlTime: driveTime[0] * 0.35,
            cntTakeoff: 318,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[0],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[0],
            kickdownTime: kickDown[0],
            maxRPN: maxRpm[0],
            retarderTime: retarderTime[0],
            distanceWithRetarder: fullDistance[0] * 0.02459684,
            distanceWithServiceBrake: fullDistance[0] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[0],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[0],
            driverTime: driveTimePrev[0],
            consumption: 314 * 0.98,
            avgElevation: 13900 * 0.99,
            avgSpeed: 53.9 * 0.97,
            avgWeight: 7569 * 0.97,
            brakingDistance: 567 * 0.99,
            driverPredictability: 3600,
            distanceEcoroll: 310,
            consumptionRPM: 35.1,
            rpmOptimum: 1500,
            idleConsumption: 4 * 1.05,
            rpmOverTime: (13 * 3600 + 19 * 60 + 47) * 0.97,
            constantAccelerationTime: (20 * 3600 + 19 * 60 + 34) * 0.96,
            driveTime85: (1 * 3600 + 45 * 60 + 13) * 0.96,
            avgParkingBreakCount: 0,
            avgRetarderCount: 46,
            avgServiceBrakeCount: 44,
            serviceBrakeVsRetarderPercentage: 100,
            engineOnTime: engineOnPrev[0],
            idlingTime: engineOnPrev[0] - driveTimePrev[0],
            cruiseControlTime: driveTimePrev[0] * 0.389,
            cntTakeoff: 256,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[0],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[0],
            kickdownTime: kickDownPrev[0],
            maxRPN: maxRpmPrev[0],
            retarderTime: retarderTimePrev[0],
            distanceWithRetarder: fullDistancePrev[0] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[1] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: { highway: 1400, city: 346, noCity: 400, other: 400, summary: 2546 }
    },
    {
        id: '2',
        rank: 2,
        name: `${users[1].name} ${users[1].surname}`,
        tachocard: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[1].id,
        score: 4.0004564654,
        oldScore: 4.1620890144918725,
        driveScore: 3.7,
        ecoScore: 3.312123333,
        wearTearScore: 4.9023561465,
        detail: {
            fullDistance: fullDistance[1],
            driverTime: driveTime[1],
            consumption: 774,
            avgElevation: 13000,
            avgSpeed: 53.2,
            avgWeight: 3569,
            brakingDistance: 888,
            driverPredictability: 3600,
            distanceEcoroll: 310,
            consumptionRPM: 34,
            rpmOptimum: 2200,
            idleConsumption: 3,
            rpmOverTime: 0 * 3600 + 18 * 60 + 7,
            constantAccelerationTime: 40 * 3600 + 50 * 60 + 18,
            driveTime85: 0 * 3600 + 18 * 60 + 13,
            avgParkingBreakCount: 0,
            avgRetarderCount: 74,
            avgServiceBrakeCount: 18,
            serviceBrakeVsRetarderPercentage: 43.11,
            engineOnTime: engineOn[1],
            idlingTime: engineOn[1] - driveTime[1],
            cruiseControlTime: driveTime[0] * 0.21,
            cntTakeoff: 95,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[1],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[1],
            kickdownTime: kickDown[1],
            maxRPN: maxRpm[1],
            retarderTime: retarderTime[1],
            distanceWithRetarder: fullDistance[1] * 0.02459684,
            distanceWithServiceBrake: fullDistance[1] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[0],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[1],
            driverTime: driveTimePrev[1],
            consumption: 774 * 1.02,
            avgElevation: 13000 * 0.98,
            avgSpeed: 53.2 * 0.96,
            avgWeight: 3569 * 0.96,
            brakingDistance: 888 * 0.98,
            driverPredictability: 4600,
            distanceEcoroll: 330,
            consumptionRPM: 34,
            rpmOptimum: 1200,
            idleConsumption: 3 * 1.04,
            rpmOverTime: (0 * 3600 + 18 * 60 + 7) * 1.05,
            constantAccelerationTime: (40 * 3600 + 50 * 60 + 18) * 0.85,
            driveTime85: (0 * 3600 + 18 * 60 + 13) * 1.11,
            avgParkingBreakCount: 0,
            avgRetarderCount: 71,
            avgServiceBrakeCount: 17,
            serviceBrakeVsRetarderPercentage: 43.11 * 0.96,
            engineOnTime: engineOnPrev[1],
            idlingTime: engineOnPrev[1] - driveTimePrev[1],
            cruiseControlTime: driveTimePrev[0] * 0.245,
            cntTakeoff: 70,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[1],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[1],
            kickdownTime: kickDownPrev[1],
            maxRPN: maxRpmPrev[1],
            retarderTime: retarderTimePrev[1],
            distanceWithRetarder: fullDistancePrev[1] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[1] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: {
            highway: 441.7349999999999,
            city: 492.09499999999997,
            noCity: 399.07499999999993,
            other: 11.984999999999998,
            summary: 1344.8899999999996
        }
    },
    {
        id: '3',
        rank: 3,
        name: `${users[2].name} ${users[2].surname}`,
        tachocard: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[2].id,
        score: 3.9,
        oldScore: 3.971460683584632,
        driveScore: 3.4,
        ecoScore: 3.3,
        wearTearScore: 4.922598784338641,
        detail: {
            fullDistance: fullDistance[2],
            driverTime: driveTime[2],
            consumption: 2490,
            avgElevation: 12900,
            avgSpeed: 65.3,
            avgWeight: 7069,
            brakingDistance: 448,
            driverPredictability: 8600,
            distanceEcoroll: 230,
            consumptionRPM: 32.7,
            rpmOptimum: 3200,
            idleConsumption: 24,
            rpmOverTime: 0 * 3600 + 6 * 60 + 52,
            constantAccelerationTime: 67 * 3600 + 28 * 60 + 58,
            driveTime85: 5 * 3600 + 16 * 60 + 28,
            avgParkingBreakCount: 0,
            avgRetarderCount: 127,
            avgServiceBrakeCount: 13,
            serviceBrakeVsRetarderPercentage: 9.7,
            engineOnTime: engineOn[2],
            idlingTime: engineOn[2] - driveTime[2],
            cruiseControlTime: driveTime[0] * 0.36,
            cntTakeoff: 150,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[2],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[2],
            kickdownTime: kickDown[2],
            maxRPN: maxRpm[2],
            retarderTime: retarderTime[2],
            distanceWithRetarder: fullDistance[2] * 0.02459684,
            distanceWithServiceBrake: fullDistance[2] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[2],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[2],
            driverTime: driveTimePrev[2],
            consumption: 2490 * 0.92,
            avgElevation: 12900 * 0.99,
            avgSpeed: 65.3 * 0.93,
            avgWeight: 7069 * 0.93,
            brakingDistance: 448 * 0.99,
            driverPredictability: 7600,
            distanceEcoroll: 230,
            consumptionRPM: 32.6,
            rpmOptimum: 4200,
            idleConsumption: 24 * 0.94,
            rpmOverTime: (0 * 3600 + 6 * 60 + 52) * 0.95,
            constantAccelerationTime: (67 * 3600 + 28 * 60 + 58) * 0.82,
            driveTime85: (5 * 3600 + 16 * 60 + 28) * 1.01,
            avgParkingBreakCount: 0,
            avgRetarderCount: 117,
            avgServiceBrakeCount: 14,
            serviceBrakeVsRetarderPercentage: 9.3,
            engineOnTime: engineOnPrev[2],
            idlingTime: engineOnPrev[2] - driveTimePrev[2],
            cruiseControlTime: driveTimePrev[0] * 0.31,
            cntTakeoff: 186,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[2],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[2],
            kickdownTime: kickDownPrev[2],
            maxRPN: maxRpmPrev[2],
            retarderTime: retarderTimePrev[2],
            distanceWithRetarder: fullDistancePrev[2] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[2] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: {
            highway: 653.5250000000001,
            city: 299.07500000000005,
            noCity: 66.69000000000001,
            other: 35.28,
            summary: 1054.5700000000002
        }
    },
    {
        id: '4',
        rank: 4,
        name: `${users[3].name} ${users[3].surname}`,
        tachocard: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[3].id,
        score: 3.7,
        driveScore: 3.2,
        ecoScore: 3.3,
        wearTearScore: 5,
        detail: {
            fullDistance: fullDistance[3],
            driverTime: driveTime[3],
            consumption: 2323,
            avgElevation: 10000,
            avgSpeed: 67.3,
            avgWeight: 7570,
            brakingDistance: 655,
            driverPredictability: 3300,
            distanceEcoroll: 158,
            consumptionRPM: 33,
            rpmOptimum: 1200,
            idleConsumption: 16,
            rpmOverTime: 0 * 3600 + 9 * 60 + 52,
            constantAccelerationTime: 55 * 3600 + 28 * 60 + 58,
            driveTime85: 4 * 3600 + 16 * 60 + 28,
            avgParkingBreakCount: 0,
            avgRetarderCount: 112,
            avgServiceBrakeCount: 11,
            serviceBrakeVsRetarderPercentage: 9.7,
            engineOnTime: engineOn[3],
            idlingTime: engineOn[3] - driveTime[3],
            cruiseControlTime: driveTime[0] * 0.18,
            cntTakeoff: 90,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[3],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[3],
            kickdownTime: kickDown[3],
            maxRPN: maxRpm[3],
            retarderTime: retarderTime[3],
            distanceWithRetarder: fullDistance[3] * 0.02459684,
            distanceWithServiceBrake: fullDistance[3] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[3],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[3],
            driverTime: driveTimePrev[3],
            consumption: 2323 * 0.92,
            avgElevation: 10000 * 0.98,
            avgSpeed: 67.3 * 0.93,
            avgWeight: 7570 * 0.93,
            brakingDistance: 655 * 0.98,
            driverPredictability: 3600,
            distanceEcoroll: 150,
            consumptionRPM: 33,
            rpmOptimum: 1260,
            idleConsumption: 16 * 0.94,
            rpmOverTime: (0 * 3600 + 9 * 60 + 32) * 1.05,
            constantAccelerationTime: (55 * 3600 + 28 * 60 + 58) * 0.82,
            driveTime85: (4 * 3600 + 16 * 60 + 28) * 0.99,
            avgParkingBreakCount: 0,
            avgRetarderCount: 112 * 0.92,
            avgServiceBrakeCount: 11 * 1.02,
            serviceBrakeVsRetarderPercentage: 9.7 * 0.96,
            engineOnTime: engineOnPrev[3],
            idlingTime: engineOnPrev[3] - driveTimePrev[3],
            cruiseControlTime: driveTimePrev[0] * 0.21,
            cntTakeoff: 98,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[3],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[3],
            kickdownTime: kickDownPrev[3],
            maxRPN: maxRpmPrev[3],
            retarderTime: retarderTimePrev[3],
            distanceWithRetarder: fullDistancePrev[3] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[3] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: { highway: 1400, city: 346, noCity: 400, other: 400, summary: 2546 }
    },
    {
        id: '5',
        rank: 5,
        name: `${users[4].name} ${users[4].surname}`,
        tachocard: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[4].id,
        score: 3.1,
        driveScore: 3.2,
        ecoScore: 3.1,
        wearTearScore: 4.6,
        detail: {
            fullDistance: fullDistance[4],
            driverTime: driveTime[4],
            consumption: 2123,
            avgElevation: 11588,
            avgSpeed: 66.3,
            avgWeight: 2569,
            brakingDistance: 589,
            driverPredictability: 5600,
            distanceEcoroll: 240,
            consumptionRPM: 34.6,
            rpmOptimum: 1200,
            idleConsumption: 23,
            rpmOverTime: 0 * 3600 + 23 * 60 + 32,
            constantAccelerationTime: 34 * 3600 + 18 * 60 + 58,
            driveTime85: 2 * 3600 + 12 * 60 + 28,
            avgParkingBreakCount: 0,
            avgRetarderCount: 137,
            avgServiceBrakeCount: 14,
            serviceBrakeVsRetarderPercentage: 9.7,
            engineOnTime: engineOn[4],
            idlingTime: engineOn[4] - driveTime[4],
            cruiseControlTime: driveTime[0] * 0.26,
            cntTakeoff: 168,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[4],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[4],
            kickdownTime: kickDown[4],
            maxRPN: maxRpm[4],
            retarderTime: retarderTime[4],
            distanceWithRetarder: fullDistance[4] * 0.02459684,
            distanceWithServiceBrake: fullDistance[4] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[4],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[4],
            driverTime: driveTimePrev[4],
            consumption: 2123 * 0.98,
            avgElevation: 11588 * 0.99,
            avgSpeed: 66.3 * 0.93,
            avgWeight: 2569 * 0.93,
            brakingDistance: 589 * 0.99,
            driverPredictability: 5600,
            distanceEcoroll: 233,
            consumptionRPM: 34.2,
            rpmOptimum: 1100,
            idleConsumption: 23 * 0.97,
            rpmOverTime: (0 * 3600 + 23 * 60 + 32) * 1.03,
            constantAccelerationTime: (34 * 3600 + 18 * 60 + 58) * 0.92,
            driveTime85: (2 * 3600 + 12 * 60 + 28) * 0.99,
            avgParkingBreakCount: 0,
            avgRetarderCount: 137 * 0.97,
            avgServiceBrakeCount: 14 * 1.01,
            serviceBrakeVsRetarderPercentage: 9.7 * 0.96,
            engineOnTime: engineOnPrev[4],
            idlingTime: engineOnPrev[4] - driveTimePrev[4],
            cruiseControlTime: driveTimePrev[0] * 0.23,
            cntTakeoff: 145,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[4],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[4],
            kickdownTime: kickDownPrev[4],
            maxRPN: maxRpmPrev[4],
            retarderTime: retarderTimePrev[4],
            distanceWithRetarder: fullDistancePrev[4] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[4] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: { highway: 1400, city: 346, noCity: 400, other: 400, summary: 2546 }
    },
    {
        id: '6',
        rank: 6,
        name: `${users[5].name} ${users[5].surname}`,
        tachocard: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
        driverId: users[5].id,
        score: 2.1,
        driveScore: 3.2,
        ecoScore: 3.1,
        wearTearScore: 2.6,
        detail: {
            fullDistance: fullDistance[5],
            driverTime: driveTime[5],
            consumption: 3123,
            avgElevation: 14500,
            avgSpeed: 69.3,
            avgWeight: 3369,
            brakingDistance: 566,
            driverPredictability: 7990,
            distanceEcoroll: 230,
            consumptionRPM: 33.9,
            rpmOptimum: 3300,
            idleConsumption: 45,
            rpmOverTime: 2 * 3600 + 23 * 60 + 32,
            constantAccelerationTime: 78 * 3600 + 18 * 60 + 58,
            driveTime85: 1 * 3600 + 12 * 60 + 28,
            avgParkingBreakCount: 0,
            avgRetarderCount: 127,
            avgServiceBrakeCount: 23,
            serviceBrakeVsRetarderPercentage: 3.7,
            engineOnTime: engineOn[5],
            idlingTime: engineOn[5] - driveTime[5],
            cruiseControlTime: driveTime[0] * 0.26,
            cntTakeoff: 201,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcoroll[5],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControl[5],
            kickdownTime: kickDown[5],
            maxRPN: maxRpm[5],
            retarderTime: retarderTime[5],
            distanceWithRetarder: fullDistance[5] * 0.02459684,
            distanceWithServiceBrake: fullDistance[5] * 0.019159684,
            maxSpeedWithParkingBrake: maxSpeedWithParkingBrake[5],
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        prevDetail: {
            fullDistance: fullDistancePrev[5],
            driverTime: driveTimePrev[5],
            consumption: 3123 * 0.97,
            avgElevation: 14500 * 0.98,
            avgSpeed: 69.3 * 0.93,
            avgWeight: 3369 * 0.93,
            brakingDistance: 567 * 0.98,
            driverPredictability: 8000,
            distanceEcoroll: 230,
            consumptionRPM: 33.8,
            rpmOptimum: 3300,
            idleConsumption: 45 * 0.87,
            rpmOverTime: (2 * 3600 + 23 * 60 + 32) * 1.03,
            constantAccelerationTime: (78 * 3600 + 18 * 60 + 58) * 0.92,
            driveTime85: (1 * 3600 + 12 * 60 + 28) * 0.99,
            avgParkingBreakCount: 0,
            avgRetarderCount: 127 * 0.97,
            avgServiceBrakeCount: 23 * 1.01,
            serviceBrakeVsRetarderPercentage: 3.7 * 0.96,
            engineOnTime: engineOnPrev[5],
            idlingTime: engineOnPrev[5] - driveTimePrev[5],
            cruiseControlTime: driveTimePrev[0] * 0.27,
            cntTakeoff: 166,
            driveTimeWithoutConsumptionWithEcoroll: driveTimeWithoutConsumptionWithEcorollPrev[5],
            accelerationTimeWithCruiseControl: accelerationTimeWithCruiseControlPrev[5],
            kickdownTime: kickDownPrev[5],
            maxRPN: maxRpmPrev[5],
            retarderTime: retarderTimePrev[5],
            distanceWithRetarder: fullDistancePrev[5] * 0.02459684,
            distanceWithServiceBrake: fullDistancePrev[5] * 0.019159684,
            maxSpeedWithParkingBrake: 0,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        },
        totalDrivers: 5,
        distance: { highway: 1400, city: 346, noCity: 400, other: 400, summary: 2546 }
    }
];

export const driverBehaviorVehicles: DriverBehaviorLightVehicleModel[] = [
    {
        id: '101',
        rank: 1,
        score: 3.3001674107142857,
        oldScore: 4.7752976190476195,
        harshEventsScore: 3.801116071428571,
        ecologyScore: 4.1,
        safetyScore: 2.100390625,
        totalDrivers: 46,
        name: `${users[0].name} ${users[0].surname}`,
        driverId: users[0].id,
        detail: {
            // HarhEvents-Economy - 1
            cityAggresiveTakeoffPenalty: 4.8275,
            cityDangerousBreakingPenalty: 3.484,
            cityDangerousTurnPenalty: 4.896,
            countryDangerousBreakingPenalty: 5,
            // HarhEvents-Economy - 2
            countryDangerousTurnPenalty: 4.25,
            // HarhEvents-Economy - 4
            countryUnevenDrivePenalty: 5,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        prevDetail: {
            cityAggresiveTakeoffPenalty: 4.5234375,
            cityDangerousBreakingPenalty: 0.71484375,
            cityDangerousTurnPenalty: 5,
            countryDangerousBreakingPenalty: 5.00390625,
            countryDangerousTurnPenalty: 5.00390625,
            countryUnevenDrivePenalty: 5.00390625,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        distance: { city: 3540, highway: 0, noCity: 832, other: 0, summary: 0 }
    },
    {
        id: '102',
        rank: 2,
        score: 4.80020461309523805,
        oldScore: 4.89549579625052,
        harshEventsScore: 4.7002232142857143,
        ecologyScore: 5,
        safetyScore: 4.90390625,
        totalDrivers: 46,
        name: `${users[1].name} ${users[1].surname}`,
        driverId: users[1].id,
        detail: {
            cityAggresiveTakeoffPenalty: 5,
            cityDangerousBreakingPenalty: 5,
            cityDangerousTurnPenalty: 5,
            countryDangerousBreakingPenalty: 5.00390625,
            countryDangerousTurnPenalty: 5.00390625,
            countryUnevenDrivePenalty: 5.00390625,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        prevDetail: {
            cityAggresiveTakeoffPenalty: 3.5114322136976046,
            cityDangerousBreakingPenalty: 4.483170377994012,
            cityDangerousTurnPenalty: 5,
            countryDangerousBreakingPenalty: 4.775652879569309,
            countryDangerousTurnPenalty: 5,
            countryUnevenDrivePenalty: 4.891353092200506,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        distance: { city: 788, highway: 0, noCity: 0, other: 0, summary: 0 }
    },
    {
        id: '103',
        rank: 3,
        score: 5.001674107142857,
        oldScore: 4.6312872023809526,
        harshEventsScore: 4.901116071428571,
        ecologyScore: 5,
        safetyScore: 5.00390625,
        totalDrivers: 46,
        name: `${users[2].name} ${users[2].surname}`,
        driverId: users[2].id,
        detail: {
            cityAggresiveTakeoffPenalty: 5,
            cityDangerousBreakingPenalty: 5,
            cityDangerousTurnPenalty: 5,
            countryDangerousBreakingPenalty: 5,
            countryDangerousTurnPenalty: 5,
            countryUnevenDrivePenalty: 5,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        prevDetail: {
            cityAggresiveTakeoffPenalty: 3.171875,
            cityDangerousBreakingPenalty: 3.34765625,
            cityDangerousTurnPenalty: 5,
            countryDangerousBreakingPenalty: 4.90234375,
            countryDangerousTurnPenalty: 5,
            countryUnevenDrivePenalty: 5,
            highwayDangerousBreakingPenalty: 5.00390625,
            highwayDangerousTurnPenalty: 5.00390625,
            highwayUnevenDrivePenalty: 5.00390625,
            highwayDangerousSpeedPenalty: 5.00390625,
            dangerousBrakingPenalty: (5 + 5 + 5.00390625) / 3
        },
        distance: { city: 1602, highway: 0, noCity: 365, other: 0, summary: 0 }
    }
];
