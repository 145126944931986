import { Conf } from 'conf';
import { google } from 'google-maps';
import { TransportAddressStructuredInput } from '../generated/graphql';
import { GeocodingGrid } from './geocoding-grid';
import { Logic } from './logic';
import { ReverseLanguageBatchLangsEnum } from '../generated/geocoding-api';
import { AddressWithCountry, LatLng } from 'common/model/geo';
export type GeocodingConf = Conf['geocoding'];

export type QueryAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
export type GeocoderResult = google.maps.GeocoderResult;
export type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
export type PlaceResult = google.maps.places.PlaceResult;

export class Geocoding {
    _conf: GeocodingConf;
    _grid: GeocodingGrid;
    _autocomplete: google.maps.places.AutocompleteService;
    _places: google.maps.places.PlacesService;
    _google: google;
    _geocodingService: google.maps.Geocoder;

    constructor(conf: GeocodingConf, private _logic: Logic) {
        this._conf = conf;
        this._google = (window as any).google;
        this._grid = new GeocodingGrid();
        this._autocomplete = new this._google.maps.places.AutocompleteService();
        this._places = new this._google.maps.places.PlacesService(document.createElement('div'));
        this._geocodingService = new this._google.maps.Geocoder();
    }

    autocompleteSessionToken(): AutocompleteSessionToken {
        return new this._google.maps.places.AutocompleteSessionToken();
    }

    async reverseGeocoding(latLng: LatLng): Promise<GeocoderResult[]> {
        return new Promise((resolve, reject) => {
            this._geocodingService.geocode(
                {
                    location: latLng
                },
                (geocoderResult, status) => {
                    if (status === this._google.maps.GeocoderStatus.OK) {
                        resolve(geocoderResult ?? []);
                    } else {
                        reject(status);
                    }
                }
            );
        });
    }

    placesAutocomplete(text: string, sessionToken?: AutocompleteSessionToken): Promise<QueryAutocompletePrediction[]> {
        return new Promise<QueryAutocompletePrediction[]>((resolve, reject) => {
            this._autocomplete.getPlacePredictions(
                {
                    input: text,
                    sessionToken
                },
                (predictions, status) => {
                    const placesStatusOK = this._google.maps.places.PlacesServiceStatus.OK;

                    if (status === placesStatusOK) {
                        resolve(predictions ?? []);
                    } else {
                        reject(status);
                    }
                }
            );
        });
    }

    placesDetail(placeId: string, sessionToken?: AutocompleteSessionToken): Promise<PlaceResult> {
        return new Promise<PlaceResult>((resolve, reject) => {
            this._places.getDetails(
                {
                    placeId,
                    sessionToken,
                    fields: ['name', 'formatted_address', 'place_id', 'geometry']
                },
                (place, status) => {
                    const placesStatusOK = this._google.maps.places.PlacesServiceStatus.OK;

                    if (status === placesStatusOK && place) {
                        resolve(place);
                    } else {
                        reject(status);
                    }
                }
            );
        });
    }

    geocodeLatLng(location: { lat: number; lng: number }): Promise<AddressWithCountry> {
        return new Promise<AddressWithCountry>((resolve, _reject) => {
            const geocoder = new this._google.maps.Geocoder();
            geocoder.geocode({ location }, (results, status) => {
                if (status === this._google.maps.GeocoderStatus.OK) {
                    if (results?.[0]) {
                        const countryCode = results[0].address_components.find(ac =>
                            ['country', 'political'].every(type => ac.types.includes(type))
                        );
                        resolve({
                            formattedAddress: results[0].formatted_address,
                            countryCode: countryCode?.short_name ?? ''
                        });
                    } else {
                        resolve({
                            formattedAddress: '',
                            countryCode: ''
                        });
                        // reject(status);
                    }
                } else {
                    resolve({
                        formattedAddress: '',
                        countryCode: ''
                    });
                    // reject(status);
                }
            });
        });
    }

    geocodeBatch(lat: number, lng: number) {
        const [x, y] = this._grid.toXY(lat, lng);
        const client = this._logic.auth().client()!;
        return this._logic.api().geocodingApi.reverseLanguageBatch({
            x,
            y,
            distance: 2,
            langs: client.geocodingLanguages as string[] as ReverseLanguageBatchLangsEnum[]
        });
    }

    geocodeLatLngDetail(location: { lat: number; lng: number }): Promise<TransportAddressStructuredInput | undefined> {
        return new Promise((resolve, _reject) => {
            const geocoder = new this._google.maps.Geocoder();
            geocoder.geocode({ location }, (results, status) => {
                if (status === this._google.maps.GeocoderStatus.OK) {
                    const [nearest] = results ?? [];
                    if (nearest) {
                        resolve(this._geocodeResultToAddress(nearest));
                    } else {
                        resolve(undefined);
                    }
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    private _geocodeResultToAddress(result: google.maps.GeocoderResult): TransportAddressStructuredInput {
        const country = result?.address_components.find(c => c.types.includes('country'))?.long_name || '';
        const countryCode = result?.address_components.find(c => c.types.includes('country'))?.short_name || '';
        const town = result?.address_components.find(c => c.types.includes('locality'))?.long_name || '';
        const route = result?.address_components.find(c => c.types.includes('route'))?.long_name || '';
        const street_address = result?.address_components.find(c => c.types.includes('street_number'))?.long_name || '';
        const postalCode = result?.address_components.find(c => c.types.includes('postal_code'))?.long_name || '';

        const address: TransportAddressStructuredInput = {
            lang: 'en', // TODO:  Implement i18n
            country,
            countryCode,
            town,
            route,
            address: `(${countryCode}) ${postalCode}, ${town}, ${route}${
                street_address ? `, ${route} ${street_address}` : ''
            }`,
            street_address: `${route} ${street_address}`,
            postalCode: postalCode
        };

        return address;
    }
}

// function loadAutoCompleteAPI(params) {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.src = urlBuilder({
//         base: 'https://maps.googleapis.com/maps/api/js',
//         libraries: params.libraries || [],
//         callback: 'googleMapsAutoCompleteAPILoad',
//         apiKey: params.apiKey,
//         client: params.client,
//         language: params.language,
//         version: params.version
//     });
//     document.querySelector('head').appendChild(script);
// }

// const google = (window as any).google;
