import { ReadOnlyUserRole, UserRole } from 'generated/new-main/models';
import i18n from 'i18next';

const rolesWithTranslation = ['driver', 'dispatcher', 'owner'];
export function getRoleName(role: ReadOnlyUserRole | UserRole) {
    return rolesWithTranslation.includes(role.label?.toLowerCase()) ? i18n.t(`common.${role.label}`) : role.label;
}

export function getRoleQaKey(role: string) {
    return rolesWithTranslation.includes(role.toLowerCase()) ? role.toLowerCase() : undefined;
}
