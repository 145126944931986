import { Row, Col } from 'antd';
import { withTranslation, WithTranslation, useTranslation } from 'react-i18next';

import { TrackingModel, MonitoredObjectTypeName } from 'common/model/tracking';
import PlacesConfigurator from 'modules/routing/planner/ui/PlacesConfigurator';
import { Button, Modal } from 'common/components';

import TransportInfo from './TransportInfo';
import DispatcherBoardDetailBar from './DispatcherBoardDetailBar';
import PlaceSettings from 'modules/routing/planner/ui/PlaceSettings';
import { Role } from 'logic/auth';
import { Transport, TransportPlace, TransportState } from 'generated/backend-api';
import { WithLogic, withLogicContext } from 'App';
import { observer } from 'mobx-react';
import { useState } from 'react';

interface Props extends WithTranslation, WithLogic {
    data: {
        transport?: Transport;
        vehicle?: TrackingModel;
        plannedCost: Transport['costPerKm'];
        selectedPlace?: TransportPlace;
        loading: boolean;
    };
    hasAlarmRight?: boolean;
    roles: Role[];
    hasProposal: boolean;
    onAlarmClickOrTaskClick: (id: string) => void;
    onAlarmClickOrTaskCloseClick: () => void;
    onBarCloseClick: () => void;
    onBarEditClick: () => void;
    onProposalDetailClick: () => void;
}

function DispatcherBoardDetail(props: Props) {
    const { t } = useTranslation();
    const [taskVisible, setTaskVisible] = useState<boolean>(false);
    const onEditTask = (placeId: string, taskId: string) => {
        props.logic.plannerLogic().setSelectedPlaceId(placeId);
        props.logic.plannerLogic().setSelectedTaskId(taskId);
        setTaskVisible(true);
    };

    return (
        <div className="dispatcher-board-deta">
            <div className="disp-board">
                <div className="disp-board-places">
                    <DispatcherBoardDetailBar roles={props.roles} onBackClick={props.onBarCloseClick} />
                    <PlacesConfigurator routeLoading={false} onEditTask={onEditTask} disabled />
                </div>

                {props.data.transport && (
                    <>
                        {props.hasProposal && (
                            <div className="disp-board-proposal">
                                {t('DispatcherBoardDetail.proposalDetail')}
                                <Button type="link" onClick={props.onProposalDetailClick}>
                                    {t('DispatcherBoardDetail.proposalButton')}
                                </Button>
                            </div>
                        )}

                        <TransportInfo
                            aetr={{
                                restingDuration: props.data.vehicle?.restingDuration,
                                drivingDuration: props.data.vehicle?.drivingDuration,
                                workingDuration: props.data.vehicle?.workingDuration,
                                activityStartTime: props.data.vehicle?.activityStartTime,
                                dailyDriveMaxHours: props.data.vehicle?.dailyDriveMaxHours,
                                dailyUtilizationLeft: props.data.vehicle?.dailyUtilizationLeft,
                                dailyUtilizationMaxHours: props.data.vehicle?.dailyUtilizationMaxHours
                            }}
                            costs={{
                                planned: props.data.plannedCost
                            }}
                            client={props.data.transport?.client}
                            distance={Math.round(
                                props.data.transport?.places?.reduce((a, c) => (a = a + (c.distance ?? 0) / 1e3), 0) ??
                                    0
                            )}
                            driver={props.data.vehicle?.driverName ?? undefined}
                            name={props.data.transport?.name ?? undefined}
                            truck={props.data.vehicle?.monitoredObjectType?.name === MonitoredObjectTypeName.VEHICLE}
                        />
                    </>
                )}
                {props.roles.includes(Role.PLN_R) &&
                    props.data.transport?.state &&
                    ![TransportState.Canceled, TransportState.Finished, TransportState.Rejected].includes(
                        props.data.transport?.state
                    ) && (
                        <Row justify="end" gutter={10}>
                            <Col>
                                <Button type="primary" onClick={props.onBarEditClick}>
                                    {t('common.edit')}
                                </Button>
                            </Col>
                        </Row>
                    )}
            </div>
            <Modal
                visible={taskVisible}
                title={t('PlannerPlacesTask.activity')}
                closable={true}
                width={1200}
                footer={null}
                destroyOnClose
                onCancel={() => setTaskVisible(false)}
            >
                {props.logic.plannerLogic().selectedPlaceId && props.data.transport?.places && (
                    <PlaceSettings onClose={() => setTaskVisible(false)} />
                )}
            </Modal>
        </div>
    );
}

export default withTranslation()(withLogicContext(observer(DispatcherBoardDetail)));
