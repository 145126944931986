import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Button } from 'common/components';

interface Props {
    editLoading?: boolean;
    onUpdate?: () => void;
    onPair?: () => void;
    onDelete?: () => void;
}

export default function VehiclesActions(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="partner-actions">
            <Row gutter={[8, 8]}>
                {props.onUpdate && (
                    <Col span={12}>
                        <Button
                            loading={props.editLoading}
                            block
                            type="primary"
                            onClick={props.onUpdate}
                            icon={<i className="fas fa-fw fa-pencil-alt" />}
                        >
                            {t('common.update')}
                        </Button>
                    </Col>
                )}
                {props.onPair && (
                    <Col span={12}>
                        <Button block type="primary" onClick={props.onPair} icon={<i className="fas fa-fw fa-link" />}>
                            {t('Partner.addAnotherItem')}
                        </Button>
                    </Col>
                )}
                {props.onDelete && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            onClick={props.onDelete}
                            icon={<i className="fas fa-fw fa-trash-alt" />}
                        >
                            {t('common.delete')}
                        </Button>
                    </Col>
                )}
            </Row>
        </div>
    );
}
