/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomPlaceCrossingTstr,
    CustomPlaceCrossingTstrFromJSON,
    CustomPlaceCrossingTstrFromJSONTyped,
    CustomPlaceCrossingTstrToJSON,
    LibModelsActivityInterval,
    LibModelsActivityIntervalFromJSON,
    LibModelsActivityIntervalFromJSONTyped,
    LibModelsActivityIntervalToJSON,
    MuDataProcessingJoinersCustomPlaceCrossingManual,
    MuDataProcessingJoinersCustomPlaceCrossingManualFromJSON,
    MuDataProcessingJoinersCustomPlaceCrossingManualFromJSONTyped,
    MuDataProcessingJoinersCustomPlaceCrossingManualToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomPlaceCrossingTstrWithCustomPlaceData
 */
export interface CustomPlaceCrossingTstrWithCustomPlaceData {
    /**
     * 
     * @type {LibModelsActivityInterval}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    activityInterval?: LibModelsActivityInterval;
    /**
     * 
     * @type {number}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    driverId?: number;
    /**
     * 
     * @type {CustomPlaceCrossingTstr}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    customPlaceCrossing: CustomPlaceCrossingTstr;
    /**
     * 
     * @type {MuDataProcessingJoinersCustomPlaceCrossingManual}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    manual?: MuDataProcessingJoinersCustomPlaceCrossingManual;
    /**
     * 
     * @type {string}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    customPlaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomPlaceCrossingTstrWithCustomPlaceData
     */
    customPlaceAddress?: string;
}

export function CustomPlaceCrossingTstrWithCustomPlaceDataFromJSON(json: any): CustomPlaceCrossingTstrWithCustomPlaceData {
    return CustomPlaceCrossingTstrWithCustomPlaceDataFromJSONTyped(json, false);
}

export function CustomPlaceCrossingTstrWithCustomPlaceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomPlaceCrossingTstrWithCustomPlaceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityInterval': !exists(json, 'activity_interval') ? undefined : LibModelsActivityIntervalFromJSON(json['activity_interval']),
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'customPlaceCrossing': CustomPlaceCrossingTstrFromJSON(json['custom_place_crossing']),
        'manual': !exists(json, 'manual') ? undefined : MuDataProcessingJoinersCustomPlaceCrossingManualFromJSON(json['manual']),
        'id': json['id'],
        'customPlaceName': !exists(json, 'custom_place_name') ? undefined : json['custom_place_name'],
        'customPlaceAddress': !exists(json, 'custom_place_address') ? undefined : json['custom_place_address'],
    };
}

export function CustomPlaceCrossingTstrWithCustomPlaceDataToJSON(value?: CustomPlaceCrossingTstrWithCustomPlaceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_interval': LibModelsActivityIntervalToJSON(value.activityInterval),
        'driver_id': value.driverId,
        'custom_place_crossing': CustomPlaceCrossingTstrToJSON(value.customPlaceCrossing),
        'manual': MuDataProcessingJoinersCustomPlaceCrossingManualToJSON(value.manual),
        'id': value.id,
        'custom_place_name': value.customPlaceName,
        'custom_place_address': value.customPlaceAddress,
    };
}


