export const countriesCodes = [
    { name: 'Belgium', iso2: 'BE', iso3: 'BEL' },
    { name: 'Bulgaria', iso2: 'BG', iso3: 'BGR' },
    { name: 'Czech Republic', iso2: 'CZ', iso3: 'CZE' },
    { name: 'Denmark', iso2: 'DK', iso3: 'DNK' },
    { name: 'Germany', iso2: 'DE', iso3: 'DEU' },
    { name: 'Estonia', iso2: 'EE', iso3: 'EST' },
    { name: 'Ireland', iso2: 'IE', iso3: 'IRL' },
    { name: 'Greece', iso2: 'GR', iso3: 'GRC' },
    { name: 'Spain', iso2: 'ES', iso3: 'ESP' },
    { name: 'France', iso2: 'FR', iso3: 'FRA' },
    { name: 'Croatia', iso2: 'HR', iso3: 'HRV' },
    { name: 'Italy', iso2: 'IT', iso3: 'ITA' },
    { name: 'Cyprus', iso2: 'CY', iso3: 'CYP' },
    { name: 'Latvia', iso2: 'LV', iso3: 'LVA' },
    { name: 'Lithuania', iso2: 'LT', iso3: 'LTU' },
    { name: 'Luxembourg', iso2: 'LU', iso3: 'LUX' },
    { name: 'Hungary', iso2: 'HU', iso3: 'HUN' },
    { name: 'Malta', iso2: 'MT', iso3: 'MLT' },
    { name: 'Netherlands', iso2: 'NL', iso3: 'NLD' },
    { name: 'Austria', iso2: 'AT', iso3: 'AUT' },
    { name: 'Poland', iso2: 'PL', iso3: 'POL' },
    { name: 'Portugal', iso2: 'PT', iso3: 'PRT' },
    { name: 'Slovenia', iso2: 'SI', iso3: 'SVN' },
    { name: 'Slovakia', iso2: 'SK', iso3: 'SVK' },
    { name: 'Finland', iso2: 'FI', iso3: 'FIN' },
    { name: 'Sweden', iso2: 'SE', iso3: 'SWE' },
    { name: 'United Kingdom of Great Britain and Northern Ireland', iso2: 'GB', iso3: 'GBR' },
    { name: 'San Marino', iso2: 'SM', iso3: 'SMR' },
    { name: 'Albania', iso2: 'AL', iso3: 'ALB' },
    { name: 'Andorra', iso2: 'AD', iso3: 'AND' },
    { name: 'Belarus', iso2: 'BY', iso3: 'BLR' },
    { name: 'Bosnia and Herzegovina', iso2: 'BA', iso3: 'BIH' },
    { name: 'Isle of Man', iso2: 'IM', iso3: 'IMN' },
    { name: 'Macedonia', iso2: 'MK', iso3: 'MKD' },
    { name: 'Moldova (the Republic of)', iso2: 'MD', iso3: 'MDA' },
    { name: 'Monaco', iso2: 'MC', iso3: 'MCO' },
    { name: 'Montenegro', iso2: 'ME', iso3: 'MNE' },
    { name: 'Norway', iso2: 'NO', iso3: 'NOR' },
    { name: 'Serbia', iso2: 'RS', iso3: 'SRB' },
    { name: 'Switzerland', iso2: 'CH', iso3: 'CHE' },
    { name: 'Liechtenstein', iso2: 'LI', iso3: 'LIE' },
    { name: 'Turkey', iso2: 'TR', iso3: 'TUR' },
    { name: 'Ukraine', iso2: 'UA', iso3: 'UKR' },
    { name: 'Romania', iso2: 'RO', iso3: 'ROU' },
    { name: 'Russia', iso2: 'RU', iso3: 'RUS' }
];

export const getCountryName = (countryCode: string = ''): string => {
    const country = countriesCodes.find(country => country.iso2 === countryCode.toUpperCase());
    if (country) {
        return country.name;
    } else {
        return countryCode;
    }
};

export const getCountryIso2FromIso3 = (iso3: string = ''): string => {
    const country = countriesCodes.find(country => country.iso3 === iso3.toUpperCase());
    if (country) {
        return country.iso2;
    } else {
        return iso3;
    }
};

export const getCountryIso3 = (contryCode: string = ''): string => {
    const country = countriesCodes.find(country => country.iso2 === contryCode.toUpperCase());
    if (country) {
        return country.iso3;
    } else {
        return contryCode;
    }
};
