/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MuDataProcessingModelsDriverInfoDriver,
    MuDataProcessingModelsDriverInfoDriverFromJSON,
    MuDataProcessingModelsDriverInfoDriverFromJSONTyped,
    MuDataProcessingModelsDriverInfoDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportMonitoringEntryDriver
 */
export interface TransportMonitoringEntryDriver {
    /**
     * 
     * @type {MuDataProcessingModelsDriverInfoDriver}
     * @memberof TransportMonitoringEntryDriver
     */
    driver: MuDataProcessingModelsDriverInfoDriver;
    /**
     * 
     * @type {Date}
     * @memberof TransportMonitoringEntryDriver
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransportMonitoringEntryDriver
     */
    stop: Date;
}

export function TransportMonitoringEntryDriverFromJSON(json: any): TransportMonitoringEntryDriver {
    return TransportMonitoringEntryDriverFromJSONTyped(json, false);
}

export function TransportMonitoringEntryDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportMonitoringEntryDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': MuDataProcessingModelsDriverInfoDriverFromJSON(json['driver']),
        'start': (new Date(json['start'])),
        'stop': (new Date(json['stop'])),
    };
}

export function TransportMonitoringEntryDriverToJSON(value?: TransportMonitoringEntryDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': MuDataProcessingModelsDriverInfoDriverToJSON(value.driver),
        'start': (value.start.toISOString()),
        'stop': (value.stop.toISOString()),
    };
}


