/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TransportState {
    New = 'new',
    Active = 'active',
    Delayed = 'delayed',
    Planned = 'planned',
    Rejected = 'rejected',
    Finished = 'finished',
    Assigned = 'assigned',
    Canceled = 'canceled',
    Accepted = 'accepted'
}

export function TransportStateFromJSON(json: any): TransportState {
    return TransportStateFromJSONTyped(json, false);
}

export function TransportStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportState {
    return json as TransportState;
}

export function TransportStateToJSON(value?: TransportState | null): any {
    return value as any;
}

