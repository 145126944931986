import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import Button from 'common/components/Button';
import Typography from 'common/components/Typography';
import { iconClose } from 'resources/images/common';
import ButtonHelper from 'common/components/ButtonHelper';
import qa from 'qa-selectors';

export function LayoutSidePanel({
    headerIcon,
    header,
    body,
    footer,
    onCloseClick,
    onHelperClick
}: {
    headerIcon?: string;
    header?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;
    onCloseClick?: () => void;
    onHelperClick?: () => void;
}) {
    return (
        <div className="layout-side-panel">
            {(header || headerIcon) && (
                <div className="layout-side-panel-header">
                    <Row gutter={16}>
                        <Col flex="1 1 100px" style={{ overflow: 'hidden' }}>
                            <Typography.Title level={2} ellipsis={{ suffix: ' ' }} data-qa={qa.sidePanel.fieldTitle}>
                                {headerIcon && <img src={headerIcon} alt="detail" />}
                                {header}
                            </Typography.Title>
                        </Col>
                        {onCloseClick && (
                            <Col flex="40px">
                                <Button
                                    className="layout-side-panel-header-close"
                                    type="text"
                                    icon={<img src={iconClose} alt="close" />}
                                    onClick={onCloseClick}
                                />
                            </Col>
                        )}
                        {onHelperClick && <ButtonHelper onClick={onHelperClick} />}
                    </Row>
                </div>
            )}
            <div className="layout-side-panel-body">{body}</div>
            {footer && <div className="layout-side-panel-footer">{footer}</div>}
        </div>
    );
}
