import { debounce } from 'debounce';
import { Logic } from 'logic/logic';
import { Component, createRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18next';
import qs from 'qs';
import Collapse from 'common/components/Collapse';

import { LinksMetadata } from '../../ManagementModule';
import FleetCreateTrailer from './components/FleetTrailerCreate';
import { OperationalCostModel } from 'logic/statistics/statistics-company-profile';
import { exponea } from 'logic/exponea';
import { search } from 'common/utils/search';
import { AvailableCurrencies } from 'common/model/currency';
import { message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { Conf, confDefault } from 'conf';
import { DriverModel } from 'logic/user/users';
import { DefaultDriverInfo } from 'generated/backend-api/models';
import CompanyProfileOperationalCosts from 'modules/statistics/modules/company-profile/components/CompanyProfileOperationalCosts';
import { Confirm } from 'common/components';
import { MessageType } from 'common/components/Confirm';
import FleetPairing from './ui/FleetPairing';
import { LayoutContent } from 'common/components/Layout/Content';
import FleetTable from './ui/FleetTable';
import FleetLinkedItems from './ui/FleetLinkedItems';
import FleetTrailerDetail from './components/FleetTrailerDetail';
import HelperModal from 'common/components/HelperModal';
import FleetVehicleDetail from './components/FleetVehicleDetail';
import {
    MonitoredObjectFeFleetFuelCard,
    MonitoredObjectFeFleetTemperatureSensor,
    MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum,
    MonitoredObjectTrailerProfileTypeEnum,
    ReadOnlyFuelCardSerializer,
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyMonitoredObjectType
} from 'generated/new-main';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import FleetTrailerActions from './components/FleetTrailerActions';
import FleetVehicleActions from './components/FleetVehicleActions';
import { truck, trailer } from 'resources/images/common';
import VehicleGroupsModule from '../vehicle-groups/VehicleGroupsModule';
import GroupsAssignmentForm, {
    GroupsAssignmentFormModel
} from 'common/forms/GroupsAssignmentForm/GroupsAssignmentForm';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { ExternalDeviceType } from 'generated/backend-api-live';
import { ETrailerType } from 'generated/graphql';
import { RouteNames } from 'App';
import FleetBar from './components/FleetBar';

export interface FuelCardInputModel {
    number: string;
    fuelCompanyId: number | string;
}

export interface Sensors {
    brakeTemperature: boolean;
    exterierTemperature: boolean;
}

export enum FleetType {
    VEHICLE = 'vehicle',
    TRAILER = 'trailer',
    LIGHT_VEHICLE = 'light_vehicle'
}
export enum FleetActive {
    ACTIVE = 'active',
    DISCHARGED = 'discharged'
}
export enum TunnelType {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E'
}

export enum EmissionClass {
    EEV = 'EEV',
    EURO_I = 'EURO_I',
    EURO_II = 'EURO_II',
    EURO_III = 'EURO_III',
    EURO_IV = 'EURO_IV',
    EURO_V = 'EURO_V',
    EURO_VI = 'EURO_VI'
}

export enum ModuleToKey {
    'module0' = 'management',
    'module1' = 'livemap',
    'module2' = 'routeplanning',
    'module3' = 'track&trace',
    'module4' = 'running&idling',
    'module5' = 'can',
    'module6' = 'legaldrivertimes',
    'module7' = 'tachoremotedownload',
    'module8' = 'messaging',
    'module9' = 'alarms',
    'module10' = 'publicapi',
    'module11' = 'costcalculator',
    'module12' = 'driverbehaviour',
    'module13' = 'maintenance',
    'module14' = 'dff',
    'module15' = 'obddiagnostics',
    'module19' = 'diets(travelallowances)',
    'module20' = 'fuelguard',
    'module21' = 'trailerid',
    'module22' = 'business/privatetrip+driverid',
    'module23' = 'partner',
    'module26' = 'coldchain'
}

export interface LinkFleet {
    id: string;
    rn: string;
    name: string;
    type?: ETrailerType;
    trailerPairingType?: ExternalDeviceType;
    metadata?: LinksMetadata;
}

export interface LinkDefaultDriver {
    id: string;
    name: string;
}

export interface FleetLinks {
    fleet?: LinkFleet[];
    fuelCard?: MonitoredObjectFeFleetFuelCard[];
    vehicles?: {}[];
    trailers?: {}[];
    defaultDriver?: DefaultDriverInfo;
}

export interface FleetProfileMd {
    fuelType: boolean;
    width: boolean;
    height: boolean;
    length: boolean;
    tunnel: boolean;
    weight: boolean;
    weightFull: boolean;
    numberOfAxles: boolean;
    trailersCount: boolean;
    emissionClass: boolean;
    vin: boolean;
    optimalRpm: boolean;
}

export interface FleetModel {
    cargoType?: string;
    monitoredObjectGroups?: {
        id?: number;
        name: string;
    }[];
    currency?: AvailableCurrencies;
    countryOfRegistration?: string;
    defaultDriver?: {
        id?: number;
        name?: string;
    };
    disabledAt?: Date;
    emissionClass?: string;
    fuelCards?: MonitoredObjectFeFleetFuelCard[];
    fuelType?: MonitoredObjectMetadataProfileMergedItemFuelTypeVEnum;
    weightFull?: number;
    height?: number;
    id: number;
    length?: number;
    links?: FleetLinks;
    manufacturer: string;
    serialNumber?: string;
    costPerKm?: number;
    md?: FleetProfileMd;
    name: string;
    note: string;
    numberOfAxles?: number;
    registrationNumber: string;
    sensors?: Sensors;
    services?: string[];
    tags?: string[];
    tankSize?: number;
    toll: string[];
    trailers?: FleetModel[];
    trailersCount?: number;
    trailerType?: MonitoredObjectTrailerProfileTypeEnum;
    tunnel?: string;
    type: FleetType;
    monitoredObjectType?: ReadOnlyMonitoredObjectType;
    vin?: string;
    weight?: number;
    width?: number;
    optimalRpm?: number;
    trailerIdSerialNumber?: string;
    trailerPairingType?: ExternalDeviceType;
    temperatureSensors?: TemperatureSensorWithTemperature[];
}

export type PairingData = ReadOnlyMonitoredObjectFeSb | ReadOnlyFuelCardSerializer | DriverModel;
export type UnpairType = FleetModel;

export interface Pairing {
    data?: PairingData[];
    selected?: PairingData;
    confirm?: PairingData;
    type?: keyof FleetLinks;
    search?: string;
}

export interface ToBeUnpaired {
    id: string;
    idToUnpair: string;
}

export interface TemperatureSensorWithTemperature extends MonitoredObjectFeFleetTemperatureSensor {
    temperatureValue?: number;
}

export interface Unpairing {
    data: ToBeUnpaired;
    type: keyof FleetLinks;
}

export type RouteParams = {
    monitoredObjectId?: string;
};

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    search?: { text: string };
    pairing?: Pairing;
    pairingLoading?: boolean;
    unpairing?: Unpairing;
    unpairingLoading?: boolean;
    disableVehicleLoading?: boolean;
    enableVehicleLoading?: boolean;
    operationalCosts?: { data: OperationalCostModel; vehicles: string[] };
    trailerFormOpen: boolean;
    bar: {
        filterOpen?: boolean;
    };
    filter: {
        fleetActive?: FleetActive;
        fleetType?: FleetType;
    };
    table: {
        loading: boolean;
        selected?: FleetModel;
        data?: FleetModel[];
        remove?: FleetModel;
        removeLoading?: boolean;
    };
    roles: Role[];
    helper?: {
        content: string;
    };
    edit: boolean;
    disableConfirmIsOpen: boolean;
    activeConfirmIsOpen: boolean;
    monitoredObjectGroupsOpen: boolean;
    assignToGroup: {
        open: boolean;
        loading: boolean;
        monitoredObjectGroups: ReadOnlyMonitoredObjectGroupNested[];
    };
    params: RouteParams;
}

class ManagementFleetModule extends Component<Props, State> {
    private _logic: Logic;
    tableRowRef: React.RefObject<HTMLTableRowElement>;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const roles = this._logic.auth().roles();
        const settings = this.props.logic.settings().getProp('management').fleet;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        this.tableRowRef = createRef();

        this.state = {
            trailerFormOpen: false,
            table: {
                loading: true
            },
            roles,
            edit: false,
            bar: {
                filterOpen: false
            },
            filter: {
                fleetActive: settings?.filter?.fleetActive,
                fleetType: settings?.filter?.fleetType
            },
            disableConfirmIsOpen: false,
            activeConfirmIsOpen: false,
            monitoredObjectGroupsOpen: false,
            assignToGroup: {
                loading: false,
                open: false,
                monitoredObjectGroups: []
            },
            params
        };
    }

    componentDidMount() {
        (window as any).app.modul = this;
        this._updateTableData(!!this.state.params.monitoredObjectId);
    }

    render() {
        return (
            <>
                {this.state.operationalCosts && (
                    <CompanyProfileOperationalCosts
                        vehicles={this.state.operationalCosts.vehicles}
                        data={this.state.operationalCosts.data}
                        onSubmit={this._onOperationalCostsSubmit}
                        onClose={this._onOperationalCostsClose}
                    />
                )}

                {this.state.table.remove && (
                    <Confirm
                        danger
                        header={this.props.t('ManagementFleet.deleteHeader')}
                        message={`${this.props.t('ManagementFleet.deleteConfirm')} ${
                            this.state.table.selected?.registrationNumber
                        }`}
                        type={MessageType.WARNING}
                        loading={this.state.table.removeLoading}
                        confirmLabel={this.props.t('common.delete')}
                        onConfirm={this._onFleetRemoveConfirm}
                        onCancel={this._onFleetRemoveCancel}
                    />
                )}

                <FleetCreateTrailer
                    roles={this.state.roles}
                    isOpen={this.state.trailerFormOpen}
                    onCancelButtonClick={this._onCancelCreateTrailer}
                    onSubmitButtonClick={this._onTrailerFormSubmitClick}
                />

                {this.state.pairing && (
                    <FleetPairing
                        type={this.state.pairing.type}
                        model={this.state.table.selected}
                        data={this.state.pairing.data}
                        selected={this.state.pairing.selected}
                        search={this.state.pairing.search}
                        onSearchInputChange={this._onPairingSearch}
                        onPairingTypeChange={this._onPairingTypeChange}
                        onPairingItemSelect={this._onPairingItemSelect}
                        onConfirmButtonClick={this._onPairingConfirm}
                        onCancelButtonClick={this._onPairingCancel}
                    />
                )}

                {this.state.pairing?.confirm && (
                    <Confirm
                        message={this._getPairingConfirmMessage()}
                        type={MessageType.INFO}
                        loading={this.state.pairingLoading}
                        onConfirm={this._onPairingSubmit}
                        onCancel={this._onPairingCancel}
                    />
                )}

                {this.state.unpairing && (
                    <Confirm
                        message={this._getUnpairingConfirmMessage()}
                        type={MessageType.INFO}
                        loading={this.state.unpairingLoading}
                        onConfirm={this._onUnpairAssetConfirm}
                        onCancel={this._onUnpairAssetCancel}
                    />
                )}

                <LayoutContent
                    className="management-fleet no-padding"
                    mainSizes={{ xs: 24, sm: 24, md: 18 }}
                    extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                    main={
                        <>
                            <FleetBar
                                heading={this.props.t('common.fleet')}
                                demoMode={this._logic.demo().isActive}
                                filter={this.state.filter}
                                filterOpen={this.state.bar.filterOpen}
                                search={this.state.search}
                                roles={this.state.roles}
                                onFilterCancelClick={this._onFilterCancel}
                                onFilterConfirmClick={this._onFilterConfirm}
                                onBarSearchChange={this._onSearchChange}
                                backButton={
                                    this.state.params.monitoredObjectId
                                        ? {
                                              onClick: this._onBackButtonClick,
                                              title: this.props.t('common.backTo.coldChain')
                                          }
                                        : undefined
                                }
                                onBarFilterClick={this._onBarFilterClick}
                                onBarResetClick={this._onBarResetClick}
                                onHelperClick={this._onBarHelperClick}
                                onCreateTrailer={this._onCreateTrailer}
                                onVehicleGroupOpen={this._onVehicleGroupOpen}
                            />
                            <FleetTable
                                tableRowRef={this.tableRowRef}
                                loading={this.state.table.loading}
                                data={this.state.table.data}
                                selected={this.state.table.selected}
                                roles={this.state.roles}
                                groups={this.state.assignToGroup.monitoredObjectGroups}
                                demoMode={this._logic.demo().isActive}
                                onTableRowSelect={this._onTableRowSelect}
                                onUnpairTrailerFromVehicleIconClick={this._onUnpairClick}
                            />
                        </>
                    }
                    extra={[
                        this.state.monitoredObjectGroupsOpen ? (
                            <LayoutSidePanel
                                header={this.props.t('common.vehicleGroups')}
                                body={
                                    <VehicleGroupsModule
                                        onCancel={() => {
                                            this.setState({ monitoredObjectGroupsOpen: false });
                                        }}
                                    />
                                }
                            />
                        ) : this.state.assignToGroup.open ? (
                            <>
                                <LayoutSidePanel
                                    headerIcon={this.state.table.selected?.type === FleetType.TRAILER ? trailer : truck}
                                    header={this.state.table.selected?.registrationNumber}
                                    body={
                                        <>
                                            <GroupsAssignmentForm
                                                header={this.props.t('ManagementFleet.assignVehicleTitle')}
                                                initialValues={{
                                                    monitoredObjectGroupIds:
                                                        this.state.table.selected?.monitoredObjectGroups?.map(
                                                            group => group.id ?? 0
                                                        )
                                                }}
                                                loading={this.state.assignToGroup.loading}
                                                monitoredObjectGroupsOptions={this.state.assignToGroup.monitoredObjectGroups.map(
                                                    group => {
                                                        return {
                                                            value: group.id ?? 0,
                                                            label: group.name
                                                        };
                                                    }
                                                )}
                                                onSubmit={this._onGroupAssignmentSubmit}
                                                onCancel={this._onGroupAssignmentCloseClick}
                                            />
                                        </>
                                    }
                                />
                            </>
                        ) : !this.state.table.selected ? null : this.state.table.selected &&
                          this.state.table.selected.type === FleetType.TRAILER ? (
                            <LayoutSidePanel
                                headerIcon={trailer}
                                header={this.state.table.selected.registrationNumber}
                                body={
                                    <>
                                        <Collapse
                                            expandIconPosition="right"
                                            bordered={true}
                                            defaultActiveKey={['1', '2']}
                                        >
                                            <Collapse.Panel
                                                header={this.props.t('ManagementFleet.trailerDetail')}
                                                key="1"
                                            >
                                                <FleetTrailerDetail
                                                    edit={this.state.edit}
                                                    data={this.state.table.selected}
                                                    demoMode={this._logic.demo().isActive}
                                                    roles={this.state.roles}
                                                    onFormCancel={this._onFleetFormCancelClick}
                                                    onFormSubmitClick={this._onTrailerFormSubmitClick}
                                                />
                                            </Collapse.Panel>
                                            {!this.state.edit ? (
                                                <Collapse.Panel
                                                    header={this.props.t('ManagementFleet.linkedItems')}
                                                    key="2"
                                                >
                                                    <FleetLinkedItems
                                                        model={this.state.table.selected}
                                                        demoMode={this._logic.demo().isActive}
                                                        roles={this.state.roles}
                                                        onUnpairClick={this._onUnpairClick}
                                                    />
                                                </Collapse.Panel>
                                            ) : (
                                                <></>
                                            )}
                                        </Collapse>
                                    </>
                                }
                                footer={
                                    !this.state.edit && (
                                        <FleetTrailerActions
                                            data={this.state.table.selected}
                                            demoMode={this._logic.demo().isActive}
                                            roles={this.state.roles}
                                            onUpdateClick={this._onActionsUpdateClick}
                                            onPairingClick={this._onActionsPairingAssetClick}
                                            onDeleteClick={this._onActionsTrailerDeleteClick}
                                            onAssignToVehicleGroupClick={this._onActionsAssignToVehicleGroupClick}
                                        />
                                    )
                                }
                            />
                        ) : (
                            <LayoutSidePanel
                                headerIcon={truck}
                                header={this.state.table.selected.registrationNumber}
                                body={
                                    <>
                                        <Collapse
                                            expandIconPosition="right"
                                            bordered={true}
                                            defaultActiveKey={['1', '2']}
                                        >
                                            <Collapse.Panel
                                                header={this.props.t('ManagementFleet.vehicleDetail')}
                                                key="1"
                                            >
                                                <FleetVehicleDetail
                                                    logic={this.props.logic}
                                                    edit={this.state.edit}
                                                    data={this.state.table.selected}
                                                    demoMode={this._logic.demo().isActive}
                                                    roles={this.state.roles}
                                                    onFormSubmitClick={this._onVehicleFormSubmitClick}
                                                    onFormCancelClick={this._onFleetFormCancelClick}
                                                />
                                            </Collapse.Panel>
                                            {!this.state.edit ? (
                                                <Collapse.Panel
                                                    header={this.props.t('ManagementFleet.linkedItems')}
                                                    key="2"
                                                >
                                                    <FleetLinkedItems
                                                        model={this.state.table.selected}
                                                        demoMode={this._logic.demo().isActive}
                                                        roles={this.state.roles}
                                                        onUnpairClick={this._onUnpairClick}
                                                    />
                                                </Collapse.Panel>
                                            ) : (
                                                <></>
                                            )}
                                        </Collapse>
                                    </>
                                }
                                footer={
                                    !this.state.edit && (
                                        <FleetVehicleActions
                                            data={this.state.table.selected}
                                            demoMode={this._logic.demo().isActive}
                                            roles={this.state.roles}
                                            onUpdateClick={this._onActionsUpdateClick}
                                            onActiveClick={this._onVehicleActiveClick}
                                            onDisableClick={this._onVehicleDisableClick}
                                            onPairNewAssetButtonClick={this._onActionsPairingAssetClick}
                                            onAssignToVehicleGroupClick={this._onActionsAssignToVehicleGroupClick}
                                        />
                                    )
                                }
                            />
                        )
                    ]}
                />

                <HelperModal
                    name="fleet"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />

                {this.state.disableConfirmIsOpen && (
                    <Confirm
                        loading={this.state.disableVehicleLoading}
                        type={MessageType.WARNING}
                        header={this.props.t('ManagementFleet.disableVehicle')}
                        message={this.props.t('ManagementFleet.message.vehicleDisableConfirm')}
                        onCancel={this._onDisableMonitoredObjectCancel}
                        onConfirm={this._onDisableMonitoredObjectConfirm}
                    />
                )}
                {this.state.activeConfirmIsOpen && (
                    <Confirm
                        loading={this.state.enableVehicleLoading}
                        type={MessageType.WARNING}
                        header={this.props.t('ManagementFleet.activateVehicle')}
                        message={this.props.t('ManagementFleet.message.vehicleActiveConfirm')}
                        onCancel={this._onActivateMonitoredObjectCancel}
                        onConfirm={this._onActivateMonitoredObjectConfirm}
                    />
                )}
            </>
        );
    }

    private _onGroupAssignmentSubmit = async (values: GroupsAssignmentFormModel): Promise<boolean> => {
        const selectedMonitoredObject = this.state.table.selected;
        try {
            if (!selectedMonitoredObject) throw new Error('no selected monitored object');
            await this._logic
                .vehicles()
                .updateMonitoredObjectGroupForVehicle(selectedMonitoredObject.id, values.monitoredObjectGroupIds);

            this._updateTableData();
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    open: false
                }
            }));
            message.success('ManagementFleet.message.vehicleGroupsAssignedSuccess');
        } catch (err) {
            console.error(`Could not assign vehicle to vehicle groups, err: ${err}`);
            message.error('ManagementFleet.message.vehicleGroupsAssignedError');
            return false;
        }

        return true;
    };

    private _onGroupAssignmentCloseClick = () => {
        this.setState(state => ({
            assignToGroup: {
                ...state.assignToGroup,
                open: false
            }
        }));
    };

    private _onActionsAssignToVehicleGroupClick = async () => {
        this.setState(state => ({
            assignToGroup: {
                ...state.assignToGroup,
                open: true,
                loading: true
            }
        }));

        try {
            const result = await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupNested({
                offset: DEFAULT_PAGE_OFFSET,
                limit: INFINITY_PAGE_LIMIT
            });
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    loading: false,
                    monitoredObjectGroups: result.results
                }
            }));
        } catch (err) {
            message.error('ManagementFleet.message.vehicleGroupsLoadError');
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    loading: false
                }
            }));
        }
    };

    private _onVehicleGroupOpen = () => {
        this.setState({
            monitoredObjectGroupsOpen: true
        });
    };

    private _getUnpairingConfirmMessage = (): string => {
        switch (this.state.unpairing?.type) {
            case 'fuelCard':
                return this.props.t('ManagementUnpairing.fuel');
            case 'defaultDriver':
                return this.props.t('ManagementUnpairing.defaultDriver');
            case 'trailers':
                return this.props.t('ManagementUnpairing.trailer');
            case 'vehicles':
                return this.props.t('ManagementUnpairing.vehicle');
            default:
                return '';
        }
    };

    private _getPairingConfirmMessage = (): string => {
        switch (this.state.pairing?.type) {
            case 'fuelCard':
                return this.props.t('ManagementPairing.fuel');
            case 'defaultDriver':
                return this.props.t('ManagementPairing.defaultDriver');
            case 'trailers':
                return this.props.t('ManagementPairing.trailer');
            case 'vehicles':
                return this.props.t('ManagementPairing.vehicle');
            default:
                return '';
        }
    };

    private _onActivateMonitoredObjectConfirm = () => {
        const id = this.state.table.selected?.id;
        if (id) {
            this.setState({
                enableVehicleLoading: true
            });
            this._logic
                .vehicles()
                .setActiveMonitoredObject(id, true)
                .then(() => {
                    this.setState(
                        {
                            activeConfirmIsOpen: false
                        },
                        () => {
                            this._updateTableData();
                            message.success(this.props.t('ManagementFleet.message.vehicleActiveSuccess'));
                        }
                    );
                })
                .catch(err => {
                    message.error(this.props.t('ManagementFleet.message.vehicleActiveError'));
                    console.error(`Disable mo failed, err: ${JSON.stringify(err)}`);
                })
                .finally(() => {
                    this.setState({
                        enableVehicleLoading: false
                    });
                });
        }
    };

    private _onDisableMonitoredObjectConfirm = () => {
        const id = this.state.table.selected?.id;
        if (id) {
            this.setState({
                disableVehicleLoading: true
            });
            this._logic
                .vehicles()
                .setActiveMonitoredObject(id, false)
                .then(() => {
                    this.setState(
                        {
                            disableConfirmIsOpen: false
                        },
                        () => {
                            this._updateTableData();
                            message.success(this.props.t('ManagementFleet.message.vehicleDisableSuccess'));
                        }
                    );
                })
                .catch(err => {
                    message.error(this.props.t('ManagementFleet.message.vehicleDisabledError'));
                    console.error(`Disable mo failed, err: ${JSON.stringify(err)}`);
                })
                .finally(() => {
                    this.setState({
                        disableVehicleLoading: false
                    });
                });
        }
    };

    private _onFleetRemoveButton = (model: FleetModel): void => {
        this.setState(state => ({
            table: { ...state.table, remove: model }
        }));
    };

    private _onFleetRemoveCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, remove: undefined }
        }));
    };

    private _onFleetRemoveConfirm = (): void => {
        if (this.state.table?.remove?.id) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    removeLoading: true
                }
            }));
            this._logic
                .vehicles()
                .deleteTrailer(this.state.table?.remove?.id)
                .then(deleted => {
                    this.setState(
                        state => ({
                            table: {
                                ...state.table,
                                remove: undefined,
                                removeLoading: false
                            }
                        }),
                        () => {
                            if (deleted) {
                                message.success(this.props.t('ManagementFleet.message.trailerRemoveSuccess'));
                                this._updateTableData();
                            } else {
                                message.error(this.props.t('ManagementFleet.message.trailerRemoveError'));
                            }
                        }
                    );
                });
        }
    };

    private _onUnpairClick = (type: keyof FleetLinks, data: ToBeUnpaired): void => {
        this.setState({ unpairing: { data, type } });
    };

    private _onUnpairAssetCancel = (): void => {
        this.setState({ unpairing: undefined });
    };

    private _onUnpairAssetConfirm = async (): Promise<void> => {
        this.setState({ unpairingLoading: true });
        // unpairing vehicle from trailer or vice-versa
        if (this.state.unpairing && ['vehicles', 'trailers', 'fleet'].includes(this.state.unpairing.type)) {
            const dataToUnpair =
                this.state.unpairing?.type === 'vehicles' || this.state.unpairing?.type === 'fleet'
                    ? [this.state.unpairing.data.id, this.state.unpairing.data.idToUnpair]
                    : [this.state.unpairing.data.idToUnpair, this.state.unpairing.data.id];
            try {
                const unpaired = await this._logic
                    .vehicles()
                    .unpairTrailerVehicle(Number(dataToUnpair[0]), Number(dataToUnpair[1]));

                if (unpaired) {
                    message.success(this.props.t('ManagementFleet.message.unpairSuccess'));
                    this._updateTableData();
                } else {
                    message.error(this.props.t('ManagementFleet.message.unpairError'));
                }
            } catch (err) {
                console.error(`Could not unpair, err: ${err}`);
                message.error(this.props.t('ManagementFleet.message.unpairError'));
            }
        }
        if (this.state.unpairing?.type === 'defaultDriver') {
            try {
                await this._logic.vehicles().unpairDriverFromVehicle(this.state.unpairing.data.id);
                message.success(this.props.t('ManagementFleet.message.unpairSuccess'));
                this._updateTableData();
            } catch (err) {
                console.error('Can not unpair default driver, err: ', err);
                message.error(this.props.t('ManagementFleet.message.unpairError'));
            }
        }

        this.setState({ unpairingLoading: false, unpairing: undefined });
    };

    private _onPairingConfirm = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                confirm: state.pairing?.selected
            }
        }));
    };

    private _onPairingSubmit = async (): Promise<void> => {
        this.setState({
            pairingLoading: true
        });

        function isFuelCard(obj: PairingData): obj is ReadOnlyFuelCardSerializer {
            return 'fuelCompany' in obj;
        }
        function isFleet(obj: PairingData): obj is ReadOnlyMonitoredObjectFeSb {
            return 'registrationNumber' in obj;
        }
        function isDriver(obj: PairingData): obj is DriverModel {
            return 'pin' in obj;
        }

        if (this.state.pairing?.selected && this.state.table?.selected) {
            if (this.state.pairing?.type === 'trailers' && isFleet(this.state.pairing.selected)) {
                const trailerId = this.state.pairing.selected.id ?? 0;
                const vehicleId = this.state.table?.selected?.id;

                try {
                    const paired = await this._logic.vehicles().pairTrailerToVehicle(vehicleId, trailerId);
                    if (paired) {
                        message.success(this.props.t('ManagementFleet.message.pairSuccess'));
                        this._updateTableData();
                    } else {
                        message.error(this.props.t('ManagementFleet.message.pairError'));
                    }
                } catch (err) {
                    message.error(this.props.t('ManagementFleet.message.pairError'));
                }
            }

            if (this.state.pairing?.type === 'vehicles' && isFleet(this.state.pairing.selected)) {
                const trailerId = this.state.table?.selected?.id;
                const vehicleId = this.state.pairing.selected.id ?? 0;

                try {
                    const paired = await this._logic.vehicles().pairTrailerToVehicle(vehicleId, trailerId);
                    if (paired) {
                        message.success(this.props.t('ManagementFleet.message.pairSuccess'));
                        this._updateTableData();
                    } else {
                        message.error(this.props.t('ManagementFleet.message.pairError'));
                    }
                } catch (err) {
                    message.error(this.props.t('ManagementFleet.message.pairError'));
                }
            }

            if (
                this.state.pairing?.type === 'fuelCard' &&
                isFuelCard(this.state.pairing.selected) &&
                this.state.pairing.selected.id
            ) {
                try {
                    const updated = await this._logic
                        .managementFuelCard()
                        .updateFuelCard(this.state.pairing.selected.id, {
                            ...this.state.pairing.selected,
                            monitoredObject: this.state.table?.selected?.id ?? 0
                        });

                    if (updated) {
                        message.success(this.props.t('ManagementFleet.message.pairSuccess'));
                        this._updateTableData();
                    } else {
                        message.error(this.props.t('ManagementFleet.message.pairError'));
                    }
                } catch (err) {
                    message.error(this.props.t('ManagementFleet.message.pairError'));
                }
            }

            if (this.state.pairing.type === 'defaultDriver' && isDriver(this.state.pairing.selected)) {
                try {
                    await this._logic
                        .vehicles()
                        .pairDriverToMonitoredObject(
                            this.state.table?.selected.id.toString(),
                            Number(this.state.pairing.selected.id)
                        );
                    message.success(this.props.t('ManagementFleet.message.pairSuccess'));
                    this._updateTableData();
                } catch (err) {
                    console.error('can not pair driver to vehicle, err: ', err);
                    message.error(this.props.t('ManagementFleet.message.pairError'));
                }
            }
        }

        this.setState({
            pairingLoading: false,
            pairing: undefined
        });
    };

    private _onCreateTrailer = (): void => {
        this.setState({
            trailerFormOpen: true
        });
    };

    private _onCancelCreateTrailer = (): void => {
        this.setState({
            trailerFormOpen: false
        });
    };

    private _onOperationalCostsSubmit = async (model: OperationalCostModel, vehicles: string[]) => {
        const vehiclesNumbers: number[] = vehicles.map(v => Number(v));
        await this._logic
            .statisticsCompanyProfile()
            .monitoredObjectOperationalCostCreateMany([
                { monitoredObjectIds: vehiclesNumbers, operationalCostData: model.costs! }
            ]);
        this.setState({
            operationalCosts: undefined
        });
    };

    private _onOperationalCostsClose = (): void => {
        this.setState({
            operationalCosts: undefined
        });
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: { ...state.pairing, search: text }
        }));
    }, 500);

    private _onPairingItemSelect = (selected: PairingData): void => {
        this.setState(state => ({
            pairing: { ...state.pairing, selected }
        }));
    };

    private _onPairingCancel = (): void => {
        this.setState({ pairing: undefined });
    };

    private _onPairingTypeChange = (type: keyof FleetLinks): void => {
        // same type, nothing changed
        if (this.state.pairing?.type === type) return;

        this.setState({ pairing: { type } }, () => {
            if (type === 'vehicles') {
                this._logic
                    .vehicles()
                    .getUnpairedVehicles()
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data
                            }
                        }));
                    })
                    .catch(() => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: []
                            }
                        }));
                    });
            }
            if (type === 'trailers') {
                this._logic
                    .vehicles()
                    .getUnpairedTrailers()
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data
                            }
                        }));
                    })
                    .catch(() => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: []
                            }
                        }));
                    });
            }
            if (type === 'fuelCard') {
                this._logic
                    .managementFuelCard()
                    .getFuelCards({
                        limit: INFINITY_PAGE_LIMIT,
                        offset: DEFAULT_PAGE_OFFSET
                    })
                    .then(res => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: res.data.filter(
                                    d => !this.state.table?.selected?.links?.fuelCard?.map(f => f.id).includes(d.id)
                                )
                            }
                        }));
                    })
                    .catch(() => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: []
                            }
                        }));
                    });
            }

            if (type === 'defaultDriver') {
                this._logic
                    .users()
                    .drivers()
                    .then(drivers => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: drivers
                            }
                        }));
                    })
                    .catch(err => {
                        message.error('Can not get drivers, try again later');
                        console.error('Can not get drivers, err: ', err);

                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data: []
                            }
                        }));
                    });
            }
        });
    };

    private _onActionsPairingAssetClick = (): void => {
        this.setState({ pairing: { type: undefined } });
    };

    private _onTrailerFormSubmitClick = async (model: FleetModel): Promise<void> => {
        if (model.id === 0) {
            this._logic.exponea().trackEvent(exponea.module.settingsFleet, {
                status: exponea.status.actionTaken,
                action: exponea.action.createTrailer
            });

            // create new
            try {
                const created = await this._logic.vehicles().createTrailer(model);
                if (created) {
                    message.success(this.props.t('ManagementFleet.message.trailerCreateSuccess'));
                    this._updateTableData();
                } else {
                    message.error(this.props.t('ManagementFleet.message.trailerCreateError'));
                }
            } catch (err) {
                console.error(`Can not create trailer, err: ${JSON.stringify(err)}`);
                message.error(this.props.t('ManagementFleet.message.trailerCreateError'));
            } finally {
                this.setState({
                    edit: false
                });
            }
        } else {
            try {
                const updated = await this.props.logic.vehicles().updateMonitoredObject(model);
                if (updated) {
                    message.success(this.props.t('ManagementFleet.message.trailerUpdateSuccess'));
                    this._updateTableData();
                } else {
                    message.error(this.props.t('ManagementFleet.message.trailerUpdateError'));
                }
            } catch (err: any) {
                if (err.status === 409) {
                    message.error(this.props.t('ManagementFleet.message.trailerIdExists'));
                } else {
                    console.error(`Can not update trailer, err: ${JSON.stringify(err)}`);
                    message.error(
                        `${this.props.t('ManagementFleet.message.trailerUpdateError')} - ${err.trailerIdSerialNumber}`
                    );
                }
            } finally {
                this.setState({
                    edit: false
                });
            }
        }

        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                trailerForm: undefined
            },
            trailerFormOpen: false
        }));
    };

    private _onVehicleFormSubmitClick = async (model: FleetModel): Promise<void> => {
        if (model.id === 0) {
            // create new
            this.setState(
                state => ({
                    table: {
                        ...state.table,
                        vehicleForm: undefined
                    }
                }),
                () => {
                    //  TODO:
                    // - backend request
                }
            );
        } else {
            // update
            this.props.logic
                .vehicles()
                .updateMonitoredObject(model)
                .then(updated => {
                    if (updated) {
                        message.success(this.props.t('ManagementFleet.message.vehicleUpdateSuccess'));
                        this.setState(
                            {
                                search: undefined,
                                edit: false
                            },
                            () => {
                                this._updateTableData();
                            }
                        );
                    } else {
                        message.error(this.props.t('ManagementFleet.message.vehicleUpdateError'));
                    }
                });
        }
    };

    private _onTableRowSelect = (id: string): void => {
        this.setState(state => ({
            ...state,
            edit: false,
            monitoredObjectGroupsOpen: false,
            assignToGroup: {
                ...state.assignToGroup,
                open: false
            },
            table: {
                ...state.table,
                selected:
                    state.table?.selected?.id === Number(id)
                        ? undefined
                        : this.state.table?.data?.find(d => d.id === Number(id))
            }
        }));
    };

    private _onSearchChange = debounce((text: string): void => {
        this._logic.exponea().trackEvent(exponea.module.settingsFleet, {
            status: exponea.status.actionTaken,
            action: exponea.action.search
        });
        const filteredData = this._filterTableData(text, this._logic.vehicles().fleet);

        this.setState({
            search: {
                text
            },
            table: {
                loading: false,
                data: filteredData
            }
        });
    }, 500);

    async _updateTableData(withParams?: boolean): Promise<void> {
        const searchText = this.state.search?.text;
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            async () => {
                try {
                    const filteredData = this._filterTableData(
                        searchText,
                        await this._logic.vehicles().getFleetTableData()
                    );

                    this.setState(state => ({
                        pairing: undefined,
                        table: {
                            loading: false,
                            data: filteredData,
                            vehicleForm: undefined,
                            selected: filteredData?.find(d => d.id === state.table?.selected?.id)
                        }
                    }));

                    if (withParams) {
                        if (this.state.params.monitoredObjectId) {
                            this._onTableRowSelect(this.state.params.monitoredObjectId);

                            this.tableRowRef?.current?.scrollIntoView();
                        }
                    }
                } catch (err) {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                    console.error(`Load data error, err: ${err}`);
                    message.error(this.props.t('common.error.loadDataError'));
                }
            }
        );
    }

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onActionsUpdateClick = () => {
        this.setState({
            edit: true
        });
    };

    private _onFleetFormCancelClick = () => {
        this.setState({
            edit: false
        });
    };

    private _onActionsTrailerDeleteClick = () => {
        this.setState({
            table: {
                ...this.state.table,
                remove: this.state.table.selected
            }
        });
    };

    private _onVehicleActiveClick = () => {
        this.setState({
            activeConfirmIsOpen: true
        });
    };

    private _onVehicleDisableClick = () => {
        this.setState({
            disableConfirmIsOpen: true
        });
    };

    private _onActivateMonitoredObjectCancel = () => {
        this.setState({
            activeConfirmIsOpen: false
        });
    };

    private _onDisableMonitoredObjectCancel = () => {
        this.setState({
            disableConfirmIsOpen: false
        });
    };

    private _onBarFilterClick = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                filterOpen: !state.bar.filterOpen
            }
        }));
    };

    private _onBackButtonClick = () => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_COLD_CHAIN,
            search: qs.stringify({
                monitoredObjectId: this.state.params.monitoredObjectId
            })
        });
    };

    private _onFilterCancel = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                filterOpen: false
            }
        }));
    };

    private _onBarResetClick = () => {
        const defalts = confDefault.settings.management.fleet.filter;
        this._onFilterConfirm({ fleetActive: defalts.fleetActive, fleetType: defalts.fleetType });
    };

    private _onFilterConfirm = (filter: { fleetActive: FleetActive | undefined; fleetType: FleetType | undefined }) => {
        this._setSettings({ filter: filter });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    filterOpen: false
                },
                filter
            }),
            () => {
                let filteredData = this._logic.vehicles().fleet;
                if (this.state.filter.fleetActive) {
                    filteredData = filteredData?.filter(d =>
                        this.state.filter.fleetActive === FleetActive.ACTIVE ? !d.disabledAt : d.disabledAt
                    );
                }
                if (this.state.filter.fleetType) {
                    filteredData = filteredData?.filter(d => d.type === this.state.filter.fleetType);
                }
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data:
                            state.search?.text === ''
                                ? filteredData
                                : filteredData?.filter(vehicle =>
                                      [
                                          ...search(
                                              state.search?.text,
                                              ['registrationNumber', 'id', 'name'],
                                              filteredData
                                          ).map(v => v.id),
                                          ...this._logic
                                              .vehicles()
                                              .fleet?.filter(
                                                  vehicle =>
                                                      search(
                                                          state.search?.text,
                                                          ['rn', 'id', 'name'],
                                                          vehicle?.links?.fleet ?? []
                                                      )?.length > 0
                                              )
                                              .map(v => v.id)
                                      ].includes(vehicle.id)
                                  ) // Search for vehicles and trailers and linked item too vehicles (when trailer find), trailer when vehicle find
                    }
                }));
            }
        );
    };

    private _setSettings(settings: Partial<Conf['settings']['management']['fleet']>) {
        const modifiedSettings: Conf['settings']['management']['fleet'] = {
            filter: {
                fleetActive: settings?.filter?.fleetActive,
                fleetType: settings?.filter?.fleetType
            }
        };
        this._logic
            .settings()
            .setProp('management', { ...this._logic.settings().getProps().management, fleet: modifiedSettings });
    }

    private _filterTableData(searchText: string | undefined, data: FleetModel[]): FleetModel[] {
        let filteredData = data;
        if (this.state.filter.fleetActive) {
            filteredData = filteredData?.filter(d =>
                this.state.filter.fleetActive === FleetActive.ACTIVE ? !d.disabledAt : d.disabledAt
            );
        }
        if (this.state.filter.fleetType) {
            filteredData = filteredData?.filter(d => d.type === this.state.filter.fleetType);
        }
        if (searchText) {
            filteredData = filteredData?.filter(vehicle =>
                [
                    ...search(searchText, ['registrationNumber', 'name'], filteredData).map(v => v.id),
                    ...filteredData
                        .filter(vehicle => search(searchText, ['rn', 'name'], vehicle?.links?.fleet ?? [])?.length > 0)
                        .map(v => v.id)
                ].includes(vehicle.id)
            );
        }
        return filteredData;
    }
}

export default withTranslation()(withRouter(ManagementFleetModule));
