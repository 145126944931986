import { WithTranslation, withTranslation } from 'react-i18next';
import Widget from 'common/components/Widget';
import { DriverBehaviorTrendsModel, DriverLeaderboardTrendsTableModel } from 'common/model/statistics';
import DriverLeaderboardTrendsTable from 'modules/statistics/modules/driver-behavior-new/components/DriverLeaderboardTrendsTable';

interface Props extends WithTranslation {
    loading: boolean;
    data: DriverBehaviorTrendsModel[];
    onClick?: () => void;
}

function TopDriversTrendsWidget(props: Props) {
    return (
        <Widget
            loading={props.loading}
            title={props.t('CompanyProfileDashboard.topDrivers')}
            styleName="prize"
            content={
                props.data.length === 0 ? (
                    <div className="widget-empty">{props.t('utils.empty')}</div>
                ) : (
                    <DriverLeaderboardTrendsTable
                        data={
                            props.data.map(
                                data =>
                                    ({
                                        id: data.id,
                                        name: data.name,
                                        score: data.scores[0].score ?? 0,
                                        rank: data.rank
                                    } as DriverLeaderboardTrendsTableModel)
                            ) ?? []
                        }
                        loading={false}
                        showHeader={false}
                    />
                )
            }
            onClick={props.onClick}
        />
    );
}

export default withTranslation()(TopDriversTrendsWidget);
