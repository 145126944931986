import { Col, Row } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Role } from 'logic/auth';
import { Checkbox, Dropdown, Rate, Menu, Switch, Tooltip } from 'common/components';

import * as icons from 'resources/images/dispatcher-board';
import qa from 'qa-selectors';

interface Props {
    roles: Role[];
    etaDetail: boolean;
    dffRatings: number[];
    dffManualRefresh: boolean;
    showDFFProposals: boolean;
    showFreeTransports: boolean;
    onEtaDetailToggle: () => void;
    onDFFRatingsChange: (value: number[]) => void;
    onManualRefreshToggle: () => void;
    onShowDFFProposalsToggle: () => void;
    onHelpToggle: () => void;
    onShowFreeTransportsToggle: () => void;
}

export default function BoardOptions(props: Props) {
    const { t } = useTranslation();

    function renderIcon(key: string, active?: boolean) {
        return (
            <img
                className={cn('board-options-icon', { 'board-options-icon-active': active })}
                src={active ? icons[key + 'Selected'] : icons[key]}
                alt={key}
                data-qa={qa.dispatcherBoard.bar[key]}
            />
        );
    }

    return (
        <div className="board-options">
            <Row gutter={16} align="middle">
                <Col>
                    <Row gutter={8}>
                        <Col onClick={props.onShowFreeTransportsToggle}>
                            <Tooltip title={t('DispatcherBoard.newTransports')}>
                                {renderIcon('expand', props.showFreeTransports)}
                            </Tooltip>
                        </Col>
                        {props.roles.includes(Role.ETA_R) && (
                            <Col onClick={props.onEtaDetailToggle}>
                                <Tooltip title={t('DispatcherBoard.delay')}>
                                    {renderIcon('truckTime', props.etaDetail)}
                                </Tooltip>
                            </Col>
                        )}
                        <Col onClick={props.onHelpToggle}>
                            <Tooltip title={t('common.helper')}>{renderIcon('help')}</Tooltip>
                        </Col>
                    </Row>
                </Col>
                {props.roles.includes(Role.DFF_R) && (
                    <Col className="board-options-right">
                        <Row gutter={8}>
                            <Col onClick={props.onShowDFFProposalsToggle}>
                                {renderIcon('dffIcon', props.showDFFProposals)}
                            </Col>
                            <Col>
                                <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu className="dff-filter-menu">
                                            {[...new Array(5)].map((_, index) => (
                                                <Menu.Item
                                                    key={index}
                                                    icon={
                                                        <Checkbox
                                                            checked={(props.dffRatings ?? []).includes(index + 1)}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        props.onDFFRatingsChange?.(
                                                            props.dffRatings?.includes(index + 1)
                                                                ? props.dffRatings?.filter(i => i !== index + 1)
                                                                : [...props.dffRatings, index + 1]
                                                        )
                                                    }
                                                    data-qa={qa.dispatcherBoard.bar.dffRating[index + 1]}
                                                >
                                                    <Rate size="small" value={index + 1} disabled />
                                                </Menu.Item>
                                            ))}
                                            <Menu.Item
                                                key="showAll"
                                                onClick={() =>
                                                    props.onDFFRatingsChange?.(
                                                        [...new Array(5)].map((_, index) => index + 1)
                                                    )
                                                }
                                                data-qa={qa.dispatcherBoard.bar.dffRatingShowAll}
                                            >
                                                {t('DispatcherBoard.showAll')}
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    {renderIcon('star', props.dffRatings.length < 5)}
                                </Dropdown>
                            </Col>
                            <Col>
                                <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu className="dff-filter-menu">
                                            <Menu.Item key="dffManualRefresh">
                                                <div className="board-options-menu-item">
                                                    <Switch
                                                        checked={props.dffManualRefresh}
                                                        onChange={props.onManualRefreshToggle}
                                                        qa={qa.dispatcherBoard.bar.dffManualRefresh}
                                                    />
                                                    {t('DispatcherBoard.dffManualRefresh')}
                                                </div>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    {renderIcon('settings', props.dffManualRefresh)}
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
}
