/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof UserGroup
     */
    client: number;
}

export function UserGroupFromJSON(json: any): UserGroup {
    return UserGroupFromJSONTyped(json, false);
}

export function UserGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'label': json['label'],
        'client': json['client'],
    };
}

export function UserGroupToJSON(value?: UserGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'client': value.client,
    };
}


