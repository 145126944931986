/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyOBU
 */
export interface ReadOnlyOBU {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    id?: string;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyOBU
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyOBU
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    identificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    serialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOBU
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOBU
     */
    activatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    vehicleBrand: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOBU
     */
    imei: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyOBU
     */
    monitoredObject?: number | null;
}

export function ReadOnlyOBUFromJSON(json: any): ReadOnlyOBU {
    return ReadOnlyOBUFromJSONTyped(json, false);
}

export function ReadOnlyOBUFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyOBU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'type': json['type'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'identificationNumber': json['identification_number'],
        'serialNumber': json['serial_number'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'activatedAt': !exists(json, 'activated_at') ? undefined : (json['activated_at'] === null ? null : new Date(json['activated_at'])),
        'vehicleBrand': json['vehicle_brand'],
        'imei': json['imei'],
        'monitoredObject': !exists(json, 'monitored_object') ? undefined : json['monitored_object'],
    };
}

export function ReadOnlyOBUToJSON(value?: ReadOnlyOBU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': ClientToJSON(value.client),
        'type': value.type,
        'identification_number': value.identificationNumber,
        'serial_number': value.serialNumber,
        'activated_at': value.activatedAt === undefined ? undefined : (value.activatedAt === null ? null : value.activatedAt.toISOString()),
        'vehicle_brand': value.vehicleBrand,
        'imei': value.imei,
        'monitored_object': value.monitoredObject,
    };
}


