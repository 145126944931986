/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonObj,
    MonObjFromJSON,
    MonObjFromJSONTyped,
    MonObjToJSON,
    TrailerPair,
    TrailerPairFromJSON,
    TrailerPairFromJSONTyped,
    TrailerPairToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectWithPairedTrailers
 */
export interface MonitoredObjectWithPairedTrailers {
    /**
     * 
     * @type {MonObj}
     * @memberof MonitoredObjectWithPairedTrailers
     */
    primaryMonitoredObject: MonObj;
    /**
     * 
     * @type {Array<TrailerPair>}
     * @memberof MonitoredObjectWithPairedTrailers
     */
    secondaryMonitoredObjects: Array<TrailerPair>;
}

export function MonitoredObjectWithPairedTrailersFromJSON(json: any): MonitoredObjectWithPairedTrailers {
    return MonitoredObjectWithPairedTrailersFromJSONTyped(json, false);
}

export function MonitoredObjectWithPairedTrailersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectWithPairedTrailers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primaryMonitoredObject': MonObjFromJSON(json['primary_monitored_object']),
        'secondaryMonitoredObjects': ((json['secondary_monitored_objects'] as Array<any>).map(TrailerPairFromJSON)),
    };
}

export function MonitoredObjectWithPairedTrailersToJSON(value?: MonitoredObjectWithPairedTrailers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_monitored_object': MonObjToJSON(value.primaryMonitoredObject),
        'secondary_monitored_objects': ((value.secondaryMonitoredObjects as Array<any>).map(TrailerPairToJSON)),
    };
}


