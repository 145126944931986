import React, { ReactNode } from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxProps } from 'antd/lib/checkbox';
import { Control } from '../Control';
import { EnhancedCheckboxOptionType } from 'common/fields/CheckboxGroup';

interface Props extends CheckboxProps {
    qa?: string;
    children?: ReactNode;
}

function EnhancedCheckbox(props: Props) {
    return (
        <Checkbox data-qa={props.qa} {...props}>
            {props.children}
        </Checkbox>
    );
}

interface CheckboxGroupControlProps extends CheckboxGroupProps {
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
    qa?: string;
    options?: EnhancedCheckboxOptionType[];
}

EnhancedCheckbox.Group = ({ label, error, children, options, qa, ...props }: CheckboxGroupControlProps) => {
    return (
        <Control label={label} error={error} qa={qa}>
            <Checkbox.Group {...props}>
                {options
                    ? options.map((o, index) => (
                          <div className="ant-checkbox-group-item" key={index}>
                              <EnhancedCheckbox
                                  disabled={o.disabled}
                                  onChange={o.onChange}
                                  value={o.value}
                                  style={o.style}
                                  qa={o.qa}
                              >
                                  {o.label}
                              </EnhancedCheckbox>
                          </div>
                      ))
                    : children}
            </Checkbox.Group>
        </Control>
    );
};

export default EnhancedCheckbox;
