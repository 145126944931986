import { BorderCrossTwoDirections } from 'generated/backend-api';
import { Logic } from './logic';

export class BorderCrossesLogic {
    private _logic: Logic;

    constructor(logic: Logic) {
        this._logic = logic;
    }

    async borderCrosses(puesc?: boolean): Promise<BorderCrossTwoDirections[]> {
        if (this._logic.demo().isActive) {
            try {
                const _borderCrosses = await this._logic.api().borderCrossesApi.getBorderCrossesV1BorderCrossesGet({
                    puesc
                });
                return _borderCrosses;
            } catch (err) {
                console.error("Can't download border crosses err:", err);
                throw err;
            }
        } else {
            try {
                const _borderCrosses = await this._logic.api().borderCrossesApi.getBorderCrossesV1BorderCrossesGet({
                    puesc
                });
                return _borderCrosses;
            } catch (err) {
                console.error("Can't download border crosses err:", err);
                throw err;
            }
        }
    }
}

export const mockedBorderCrossess: BorderCrossTwoDirections[] = [
    {
        borderCrossId: 1,
        names: ['Svinesundsbron', 'Svinesundsbrua'],
        countries: ['SE', 'NO'],
        direction1: {
            borderCrossId: 1,
            centroid: [59.09433645360931, 11.2518654270951],
            minimum: 10,
            median6h: 10,
            delay: 0,
            lastUpdate: new Date('2021-11-30T16:00:24.108000'),
            lastData: new Date('2021-11-30T12:51:01'),
            crossingCount: 1,
            countries: ['SE', 'NO'],
            names: ['Svinesundsbron', 'Svinesundsbrua'],
            countryExit: 'SE',
            puescNames: ['puesc name 1']
        },
        direction2: {
            borderCrossId: 1,
            centroid: [59.09433645360931, 11.2518654270951],
            minimum: 10,
            median6h: 10,
            delay: 0,
            lastUpdate: new Date('2021-11-30T12:05:10.534000'),
            lastData: new Date('2021-11-30T06:06:52'),
            crossingCount: 1,
            countries: ['SE', 'NO'],
            names: [],
            countryExit: 'NO',
            puescNames: ['puesc name 2']
        }
    },
    {
        borderCrossId: 2,
        names: [],
        countries: ['EE', 'LV'],
        direction1: {
            borderCrossId: 2,
            centroid: [57.87445661565775, 24.381250277813496],
            minimum: 10,
            median6h: 10,
            delay: 0,
            lastUpdate: new Date('2021-11-23T23:35:05.122000'),
            lastData: new Date('2021-11-23T17:38:23'),
            crossingCount: 1,
            countries: ['EE', 'LV'],
            names: [],
            countryExit: 'LV',
            puescNames: ['puesc name 3']
        },
        direction2: {
            borderCrossId: 2,
            centroid: [57.87445661565775, 24.381250277813496],
            minimum: 10,
            median6h: 40,
            delay: 30,
            lastUpdate: new Date('2021-11-30T15:45:09.867000'),
            lastData: new Date('2021-11-30T09:48:49'),
            crossingCount: 1,
            countries: ['EE', 'LV'],
            names: ['Heinaste', 'Ainaži'],
            countryExit: 'EE',
            puescNames: []
        }
    },
    {
        borderCrossId: 1,
        names: ['Svinesundsbron', 'Svinesundsbrua'],
        countries: ['SE', 'NO'],
        direction1: {
            borderCrossId: 1,
            centroid: [53.09433645360931, 11.2518654270951],
            minimum: 10,
            median6h: 10,
            delay: 90,
            lastUpdate: new Date('2021-11-23T16:00:24.108000'),
            lastData: new Date('2021-11-23T12:51:01'),
            crossingCount: 1,
            countries: ['SE', 'NO'],
            names: ['Svinesundsbron', 'Svinesundsbrua'],
            countryExit: 'SE',
            puescNames: []
        },
        direction2: {
            borderCrossId: 1,
            centroid: [53.09433645360931, 11.2518654270951],
            minimum: 10,
            median6h: 10,
            delay: 30,
            lastUpdate: new Date('2021-11-23T12:05:10.534000'),
            lastData: new Date('2021-11-23T06:06:52'),
            crossingCount: 1,
            countries: ['SE', 'NO'],
            names: [],
            countryExit: 'NO',
            puescNames: []
        }
    },
    {
        borderCrossId: 1,
        names: ['Svinesundsbronss', 'Svinesundsbruasa'],
        countries: ['LT', 'LV'],
        direction1: {
            borderCrossId: 1,
            centroid: [55.744830097874285, 26.309297732753944],
            minimum: 10,
            median6h: 10,
            delay: 90,
            lastUpdate: new Date('2021-11-23T16:00:24.108000'),
            lastData: new Date('2021-11-23T12:51:01'),
            crossingCount: 1,
            countries: ['LT', 'LV'],
            names: ['Svinesundsbron', 'Svinesundsbrua'],
            countryExit: 'LT',
            puescNames: []
        },
        direction2: undefined
    }
];
