import { withTranslation } from 'react-i18next';
import PlannerContentContainer, { PlannerContentContainerProps } from '../PlannerContentContainer';
import { Col, InputNumber, Row, Slider } from 'antd';
import { useState } from 'react';
import { Checkbox, DatePicker } from 'common/components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TransportEventRule, TransportPlace } from 'generated/backend-api';
import { WithLogic } from 'App';
import { ActivityAlarms as IconAlarms } from 'resources/images/planner';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import moment from 'moment';

export interface ManualAtaResult {
    use: boolean;
    date?: Date;
}
interface Props extends PlannerContentContainerProps, WithLogic {
    transportPlace: TransportPlace;
    placeDisabled?: boolean;
    disabledAta?: boolean;
    manualAtaPossible?: boolean;
    onChange?: (options: TransportEventRule) => void;
    onManualAtaChange?: (manualAta: ManualAtaResult) => void;
}

enum RuleActivityName {
    Arrival = 'transport_arrival',
    Departure = 'transport_departure',
    Close = 'transport_poi_close'
}

const ActivityAlarms = (props: Props) => {
    const [ata, setAta] = useState(props.transportPlace.ata);
    const [manualAta, setManualAta] = useState(false);

    const [poiArrival, setPoiArrival] = useState(
        props?.transportPlace?.eventRules?.find(rule => rule.name === RuleActivityName.Arrival) ? true : false
    );
    const [poiDeparture, setPoiDeparture] = useState(
        props?.transportPlace?.eventRules?.find(rule => rule.name === RuleActivityName.Departure) ? true : false
    );
    const [poiClose, setPoiClose] = useState(
        props?.transportPlace?.eventRules?.find(rule => rule.name === RuleActivityName.Close) ? true : false
    );
    const config = props.logic
        .plannerLogic()
        .getPlaceEventRuleConfig(props.transportPlace!, RuleActivityName.Close, 'duration');
    const [duration, setDuration] = useState(config ? Number(config.value) / 60 : 30);

    const _setRule = (ruleName: RuleActivityName, value: boolean) => {
        if (value) {
            props.logic.plannerLogic().addPlaceEventRule(props.transportPlace!, ruleName, []);
        } else {
            props.logic.plannerLogic().removePlaceEventRule(props.transportPlace!, ruleName);
        }
    };

    const handlerPoiArrivalChange = (e: CheckboxChangeEvent) => {
        setPoiArrival(e.target.checked);
        _setRule(RuleActivityName.Arrival, e.target.checked);
    };

    const handlerPoiDepartureChange = (e: CheckboxChangeEvent) => {
        setPoiDeparture(e.target.checked);
        _setRule(RuleActivityName.Departure, e.target.checked);
    };

    const handlerPoiCloseChange = (e: CheckboxChangeEvent) => {
        setPoiClose(e.target.checked);
        _setRule(RuleActivityName.Close, e.target.checked);
    };

    const handlerManualAtaChange = (e: CheckboxChangeEvent) => {
        setManualAta(e.target.checked);
        props.onManualAtaChange?.({
            use: e.target.checked,
            date: ata
        });
    };

    const handlerDateChange = (value: moment.Moment | null) => {
        setAta(value!.toDate() ?? undefined);
        props.onManualAtaChange?.({
            use: manualAta,
            date: value!.toDate() ?? undefined
        });
    };

    const handlerDurationChange = (value: number) => {
        setDuration(value);
        props.logic.plannerLogic().addPlaceEventRule(props.transportPlace!, RuleActivityName.Close, [
            {
                name: 'duration',
                value: value * 60
            }
        ]);
    };

    const { t } = props;
    return (
        <PlannerContentContainer
            {...props}
            className="activity-alarms"
            beforeTitle={<img src={IconAlarms} alt="activity-alarms-icon" />}
            title={t('PlannerPlacesTask.notificationsAta')}
        >
            <Row>
                <Checkbox disabled={props.placeDisabled} defaultChecked={poiArrival} onChange={handlerPoiArrivalChange}>
                    <span>{t('PlannerAlarms.types.transport_arrival.name')}</span>
                </Checkbox>
            </Row>
            <Row>
                <Checkbox
                    disabled={props.placeDisabled}
                    defaultChecked={poiDeparture}
                    onChange={handlerPoiDepartureChange}
                >
                    <span>{t('PlannerAlarms.types.transport_departure.name')}</span>
                </Checkbox>
            </Row>
            <Row>
                <Checkbox disabled={props.placeDisabled} defaultChecked={poiClose} onChange={handlerPoiCloseChange}>
                    <span>{`${t('PlannerAlarms.types.transport_poi_close.name')} (${t(
                        'PlannerAlarms.types.transport_poi_close.note'
                    )})`}</span>
                </Checkbox>
                {poiClose && (
                    <Row>
                        <Col span={21}>
                            <Slider value={duration} min={1} max={100} onChange={handlerDurationChange} />
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                className="corridor-input"
                                name="corridor"
                                value={duration}
                                disabled={!poiClose}
                                onChange={handlerDurationChange}
                                min={1}
                                max={100}
                                step={1}
                            />
                        </Col>
                    </Row>
                )}
            </Row>
            <Row className="set-ata">
                <Col span={14}>
                    <Checkbox disabled={!props.manualAtaPossible} onChange={handlerManualAtaChange}>
                        <span>{t('PlannerTaskBar.setManualAta')}</span>
                    </Checkbox>
                </Col>
                <Col span={10}>
                    {manualAta && (
                        <DatePicker.DateTimePicker
                            disabled={!props.manualAtaPossible}
                            showTime={{ format: TIME_FORMAT_SHORT }}
                            defaultValue={ata ? moment(ata) : undefined}
                            onChange={handlerDateChange}
                        />
                    )}
                </Col>
            </Row>
        </PlannerContentContainer>
    );
};

export default withTranslation()(ActivityAlarms);
