/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeFromJSONTyped,
    MonitoredObjectFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cost
 */
export interface Cost {
    /**
     * 
     * @type {string}
     * @memberof Cost
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Cost
     */
    avgPricePerKm: number;
    /**
     * 
     * @type {string}
     * @memberof Cost
     */
    currency: string;
    /**
     * 
     * @type {Date}
     * @memberof Cost
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof Cost
     */
    numberOfCompanies: number;
    /**
     * 
     * @type {MonitoredObjectFleetType}
     * @memberof Cost
     */
    fleetType?: MonitoredObjectFleetType;
}

export function CostFromJSON(json: any): Cost {
    return CostFromJSONTyped(json, false);
}

export function CostFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'avgPricePerKm': json['avg_price_per_km'],
        'currency': json['currency'],
        'date': (new Date(json['date'])),
        'numberOfCompanies': json['number_of_companies'],
        'fleetType': !exists(json, 'fleet_type') ? undefined : MonitoredObjectFleetTypeFromJSON(json['fleet_type']),
    };
}

export function CostToJSON(value?: Cost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avg_price_per_km': value.avgPricePerKm,
        'currency': value.currency,
        'date': (value.date.toISOString()),
        'number_of_companies': value.numberOfCompanies,
        'fleet_type': MonitoredObjectFleetTypeToJSON(value.fleetType),
    };
}


