import cn from 'classnames';
import { Loading } from 'common/components/Loading';
import { WithTranslation, withTranslation } from 'react-i18next';

import Search from 'common/components/Search';
import { FuelCardsLinks, PairingData, PairingDataSelected } from '../FuelCardsModule';
import { NoData } from 'common/components/NoData';
import { searched } from 'common/utils/search';
import { UserModel } from 'logic/user/users';
import { Row, Col } from 'antd';
import { Typography } from 'common/components';
import qa from 'qa-selectors';
import { ReadOnlyFuelCardSerializer } from 'generated/new-main';
import FormActions from 'common/components/FormActions';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

type FleetPairingTypes = keyof FuelCardsLinks;

interface Props extends WithTranslation {
    data?: PairingData;
    loading?: boolean;
    model?: ReadOnlyFuelCardSerializer;
    search?: string;
    selected?: PairingDataSelected;
    type?: FleetPairingTypes;
    onCancelButtonClick?: () => void;
    onConfirmButtonClick?: () => void;
    onPairingItemSelect?: (selected: PairingDataSelected) => void;
    onPairingTypeChange?: (type: FleetPairingTypes) => void;
    onSearchInputChange?: (text: string) => void;
}

function FuelCardsPairing(props: Props) {
    function onLinkChange(type: FleetPairingTypes): void {
        props.onPairingTypeChange?.(type);
    }

    function onItemSelect(selected: PairingDataSelected): void {
        props.onPairingItemSelect?.(selected);
    }

    function onSearch(text: string): void {
        props.onSearchInputChange?.(text);
    }

    function searchFleetFilter(data: ReadOnlyMonitoredObjectFeSb): boolean {
        if (props.search) {
            return (
                searched(props.search, String(data.id)) ||
                searched(props.search, data.registrationNumber) ||
                searched(props.search, data.customLabel ?? '')
            );
        }
        return true;
    }

    function searchUserFilter(data: UserModel): boolean {
        if (props.search) {
            return (
                searched(props.search, String(data.id)) ||
                searched(props.search, data.name) ||
                searched(props.search, data.surname)
            );
        }
        return true;
    }

    const linksGroup = {
        fleet: ['vehicles', 'trailers'],
        user: 'user'
    };
    const { t, model } = props;

    const groupButton = (link: FleetPairingTypes, hasChildren: boolean) => (
        <button
            className={cn('t-button t-block', {
                't-primary': props.type && (linksGroup[link].includes(props.type) || props.type === link),
                't-bold': props.type && (linksGroup[link].includes(props.type) || props.type === link)
            })}
            onClick={onLinkChange.bind(undefined, link)}
            data-qa={qa.fuel.pairing[`btnType${link.charAt(0).toUpperCase()}${link.slice(1)}`]}
        >
            <span className="t-left">{t(`common.${link}`)}</span>
            {hasChildren ? (
                props.type ? (
                    <span className="t-right">
                        <i
                            className={cn(
                                'fas fa-fw',
                                linksGroup[link].includes(props.type) || props.type === link
                                    ? 'fa-angle-down'
                                    : 'fa-angle-right'
                            )}
                        />
                    </span>
                ) : (
                    <span className="t-right">
                        <i className="fas fa-fw fa-angle-right" />
                    </span>
                )
            ) : null}
        </button>
    );

    const linkButton = (link: FleetPairingTypes) => (
        <button
            disabled={link === 'trailers'}
            className={cn('t-button t-block', {
                't-bold': link === props.type
            })}
            onClick={onLinkChange.bind(undefined, link)}
            data-qa={qa.fuel.pairing[`btnType${link.charAt(0).toUpperCase()}${link.slice(1)}`]}
        >
            <span className="t-left t-margin-left">{t(`common.${link}`)}</span>
        </button>
    );

    const pairingContentData = () => {
        if (!props.type || props.type === 'fleet') {
            return <NoData text={t('Partner.selectItemToLink')} qa={qa.fuel.pairing.fieldNoData} />;
        }

        if (props.loading) {
            return <Loading />;
        }
        if (props.type === 'vehicles' && !props.data?.fleet?.length) {
            return <NoData />;
        }

        if (props.type === 'user' && !props.data?.user?.length) {
            return <NoData />;
        }

        if (props.type === 'vehicles') {
            return props.data?.fleet?.filter(searchFleetFilter).map(f => (
                <button
                    key={f.id}
                    className={cn('t-button t-block t-ripple btn-pairing-content', {
                        't-primary': props.selected?.data === f
                    })}
                    onClick={onItemSelect.bind(undefined, { type: 'vehicle', data: f })}
                    data-qa={qa.fuel.pairing.btnItemVehicles}
                    title={f.registrationNumber}
                >
                    <Row>
                        <Col span="18">
                            <Typography.Text
                                className="text-ellipsis t-transform-none"
                                ellipsis={{ tooltip: f.registrationNumber }}
                            >
                                {f.registrationNumber}
                            </Typography.Text>
                        </Col>
                        <Col span="6">
                            <Typography.Text className="text-ellipsis" ellipsis={{ tooltip: f.id }}>
                                {f.id}
                            </Typography.Text>
                        </Col>
                    </Row>
                </button>
            ));
        }

        if (props.type === 'user') {
            return props.data?.user?.filter(searchUserFilter).map(u => (
                <button
                    key={u.id}
                    className={cn('t-button t-block t-ripple', {
                        't-primary': props.selected?.data === u
                    })}
                    onClick={onItemSelect.bind(undefined, { type: 'user', data: u })}
                    data-qa={qa.fuel.pairing.btnItemUser}
                    title={`${u.name} ${u.surname}`}
                >
                    <div className="t-row">
                        <span className="t-left t-transform-none">{`${u.name} ${u.surname}`}</span>
                    </div>
                </button>
            ));
        }

        return null;
    };

    return (
        <div className="t-modal fuel-card-pairing">
            <div className="management-fuel-pairing t-modal-content">
                <div className="t-bar t-large t-center t-padding-large">
                    <span className="t-uppercase">{`${t('ManagementFuelCards.pairing')} - `}</span>
                    <span className="t-bold t-uppercase">{model?.number}</span>
                </div>
                <Row>
                    <Col className="management-fuel-pairing-type" span="12">
                        {Object.keys(linksGroup).map(key => {
                            if (Array.isArray(linksGroup[key])) {
                                return (
                                    <>
                                        {groupButton(key as keyof typeof linksGroup, true)}
                                        {(linksGroup[key].includes(props.type) || key === props.type) && (
                                            <>
                                                {linksGroup[key].map((link: keyof typeof linksGroup) =>
                                                    linkButton(link)
                                                )}
                                            </>
                                        )}
                                    </>
                                );
                            } else {
                                return groupButton(key as keyof typeof linksGroup, false);
                            }
                        })}
                    </Col>
                    <Col className="management-fuel-pairing-items-side" span="12">
                        <div className="t-row">
                            <Search searchActive={true} onSearch={onSearch} qa={qa.common.pairing.inputSearch} />
                        </div>
                        <div className="management-fuel-pairing-items">{pairingContentData()}</div>
                        <FormActions
                            className="buttons-group"
                            submitQa={qa.fuel.pairing.btnFormSubmit}
                            cancelQa={qa.fuel.pairing.btnFormCancel}
                            submitText={t('common.save')}
                            submitDisabled={!props.selected || !props.type}
                            onSubmitClick={props.onConfirmButtonClick}
                            onCancelClick={props.onCancelButtonClick}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default withTranslation()(FuelCardsPairing);
