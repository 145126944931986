import { WithTranslation, withTranslation } from 'react-i18next';
import { CcrModel, CCRState } from '../DriversCardsRemoteMemoryModule';

import { Row, Col } from 'antd';
import { Button } from 'common/components';
import DriverCardsRemoteMemoryLabel from '../components/DriverCardLabelState/DriverCardsRemoteMemoryLabel';

export interface Props extends WithTranslation {
    data: CcrModel[];
    editCcr: boolean;
    onCcrClick?: () => void;
}

export interface CCRGroupModel {
    id: string;
    serialNumberList: Array<string>;
    state: CCRState;
}

function DriverCardsRemoteMemoryCcr(props: Props) {
    function onCcrClick() {
        props.onCcrClick?.();
    }

    const { t, editCcr } = props;
    const readersTitle = t(
        `DriverCardsRemoteMemoryCcrTable.${props.data.length > 1 ? 'companyCardReaders' : 'companyCardReader'}`
    );
    // Dont show data with correct status
    const filterdData = props.data.filter(ccr => CCRState.ConnectedCompany !== ccr.state);
    const filterdUniqueDataStates = [...new Set(filterdData.map(ccr => ccr.state))];
    return (
        <Row align="middle" className="drivers-cards-remote-memory-ccr">
            {filterdUniqueDataStates.length > 0 && (
                <Col>
                    {filterdUniqueDataStates.map((state, index) => (
                        <DriverCardsRemoteMemoryLabel
                            key={index}
                            serialNumberList={filterdData
                                .map(ccr => (ccr.state === state ? ccr.serialNumber : ''))
                                .filter(serialNumber => serialNumber)}
                            state={state}
                        />
                    ))}
                </Col>
            )}
            <Col>{editCcr && <Button onClick={onCcrClick}>{readersTitle}</Button>}</Col>
        </Row>
    );
}

export default withTranslation()(DriverCardsRemoteMemoryCcr);
