import { AvailableCurrencies } from 'common/model/currency';
import {
    PlacesModel,
    TrailerWithAvailability,
    TransportAlarmType,
    TransportModel,
    VehicleWithAvailability
} from 'common/model/transports';
import { latLngFromGeoJsonPointType, latLngFromGeoJsonPolygonType } from 'common/utils/geo-utils';
import {
    AvailableMobjectTypes,
    AvailableMonitoredObjects,
    MonitoredObjectFleetType,
    PricePerKmResult,
    Transport,
    TransportState
} from 'generated/backend-api/models';
import { PlaceType } from 'generated/graphql';
import { PlannedTransport } from 'generated/routing-api/models';
import { Logic } from 'logic/logic';
import moment from 'moment';

export class TransportLogic {
    constructor(private _logic: Logic) {}

    public async fetchTransport(transportId: string) {
        if (this._logic.demo().isActive) {
            const t = this._logic.demo().data.transports.find(t => t.id === transportId);
            if (t) {
                return t;
            } else {
                throw new Error('Not found');
            }
        }
        try {
            return await this._logic.api().transportApi.findOneV1TransportsTransportIdGet({ transportId });
        } catch (err) {
            console.error(`CCould not load transport, err: ${err}`);
            throw err;
        }
    }

    async getAvailableMonitoredObjects(
        type: AvailableMobjectTypes,
        startTime: Date
    ): Promise<AvailableMonitoredObjects> {
        try {
            return await this._logic.api().transportApi.availableForTransportV1TransportsAvailableMonitoredObjectsGet({
                startTime,
                types: type
            });
        } catch (err) {
            console.error(`Could not load available MO, err: ${err}`);
            throw err;
        }
    }

    async availableTrailersFromTime(startTime: string): Promise<TrailerWithAvailability[]> {
        if (this._logic.demo().isActive) {
            const monitoredObjects = await this._logic.demo().data.trailers;
            return monitoredObjects.map(
                data =>
                    ({
                        available: true,
                        data
                    } as TrailerWithAvailability)
            );
        }

        const [availableMobs, monitoredObjects] = await Promise.all([
            this._logic
                .transportLogic()
                .getAvailableMonitoredObjects(AvailableMobjectTypes.Trailer, new Date(startTime)),
            await this._logic.vehicles().getMonitoredObjectFilters(true, true, undefined, false, false)
        ]);
        return (
            availableMobs.trailers?.map(trailerWithAvailability => ({
                available: trailerWithAvailability.av,
                data: monitoredObjects.find(monitoredObject => monitoredObject.id === trailerWithAvailability.id)
            })) ?? []
        );
    }

    async availableVehiclesFromTime(startTime: string): Promise<VehicleWithAvailability[]> {
        if (this._logic.demo().isActive) {
            const monitoredObjects = await this._logic.demo().data.vehicleStates;
            const pricePerKm = this._logic.demo().data.costs;
            const fleet = await this._logic.vehicles().getFleetVehicles();
            return monitoredObjects.map(
                data =>
                    ({
                        available: true,
                        data,
                        profile: {
                            id: Number(data.monitoredObjectId),
                            costPerKm:
                                pricePerKm.mobjects.find(d => data.monitoredObjectId === String(d.monitoredObjectId))
                                    ?.avgPricePerKm ?? 0,
                            currency: pricePerKm.currency
                        },
                        fleetModel: fleet.find(f => String(f.id) === data.monitoredObjectId)
                    } as VehicleWithAvailability)
            );
        }

        try {
            const availableMobs = await this._logic
                .transportLogic()
                .getAvailableMonitoredObjects(AvailableMobjectTypes.Vehicle, new Date(startTime));
            if (availableMobs.vehicles) {
                const r = availableMobs.vehicles;
                if (!this._logic.vehiclesState().data) {
                    try {
                        await this._logic.vehiclesState().getData(this._logic.notification().device!);
                    } catch (err) {
                        console.error('Available vehicles from time no data err', err);
                        throw err;
                    }
                }
                const monitoredObjects = await this._logic.vehicles().vehiclesData([]);
                const fleet = await this._logic.vehicles().getFleetVehicles();

                const data = r
                    .map(v => ({
                        data: this._logic.vehiclesState().data?.find(d => Number(d.monitoredObjectId) === v.id),
                        available: v.av,
                        profile: monitoredObjects.find(monitoredObject => String(monitoredObject.id) === String(v.id)),
                        fleetModel: fleet.find(f => f.id === v.id)
                    }))
                    .filter(v => v.data) as VehicleWithAvailability[];
                return data;
            } else {
                return [];
            }
        } catch (err) {
            console.error('Available vehicles from time err', err);
            throw err;
        }
    }

    async pricePerKm(vehicles: VehicleWithAvailability[]): Promise<VehicleWithAvailability[]> {
        let pricePerKm: PricePerKmResult | undefined = undefined;
        let vehiclesWithPrices: VehicleWithAvailability[] = [];

        if (this._logic.demo().isActive) {
            const monitoredObjects = await this._logic.demo().data.vehicleStates;
            const fleet = await this._logic.vehicles().getFleetVehicles();
            vehicles = monitoredObjects.map(
                data =>
                    ({
                        available: true,
                        data,
                        profile: {
                            id: Number(data.monitoredObjectId)
                        },
                        fleetModel: fleet.find(f => String(f.id) === data.monitoredObjectId)
                    } as VehicleWithAvailability)
            );
        }

        try {
            pricePerKm = await this._logic.statisticsCompanyProfile().pricePerKmCostStructure(
                vehicles.map(vehicle => Number(vehicle.data.monitoredObjectId)),
                true
            );
        } catch (err) {
            console.error('PricePerKmCostStructure err', err);
        }

        vehiclesWithPrices = vehicles.map(v => {
            const pricePerKmOfVehicle = this._logic
                .statisticsCompanyProfile()
                .pricePerKmByVehicleOrClientOrMarketAvg(
                    pricePerKm,
                    v.data.monitoredObjectType?.name as MonitoredObjectFleetType,
                    Number(v.data?.monitoredObjectId)
                );

            return {
                ...v,
                profile: v.profile
                    ? {
                          ...v.profile,
                          ...pricePerKmOfVehicle
                      }
                    : undefined,
                fleetModel: v.fleetModel
                    ? {
                          ...v.fleetModel,
                          ...pricePerKmOfVehicle
                      }
                    : undefined
            };
        });

        return vehiclesWithPrices;
    }

    async setManualAtaForPlace(dateTime: Date, placeId: string, transportId: string): Promise<Transport> {
        try {
            return await this._logic.api().transportApi.setAtaAtdV1TransportsTransportIdPlacePlaceIdSetAtaPatch({
                ata: dateTime,
                placeId,
                transportId
            });
        } catch (err) {
            console.error('Can not update ata', err);
            throw err;
        }
    }

    getActiveMonitoredObject(transport: Transport) {
        return transport?.monitoredObjects?.find(mo => !mo.endTime && mo.type !== MonitoredObjectFleetType.Trailer);
    }

    transportInPoland(transport: PlannedTransport | TransportModel | Transport): boolean {
        if ((transport as Transport)?.routeOptionsSygic?.possibleAvoidsSygic !== undefined) {
            const avoids = (transport as Transport)?.routeOptionsSygic?.possibleAvoidsSygic ?? [];
            return avoids['pol'] ?? false;
        } else if ((transport as PlannedTransport)?.possibleAvoidsSygic !== undefined) {
            const avoids = (transport as PlannedTransport)?.possibleAvoidsSygic ?? [];
            return avoids['pol'] ?? false;
        } else {
            return !!transport.places?.some(place => {
                return place.route ? this._logic.map().routing().polylineInPoland(place.route) : false;
            });
        }
    }

    getTransportState(selectedVehicle: boolean, firstPlaceRta?: string) {
        if (!selectedVehicle) {
            return TransportState.New;
        }
        return moment().subtract(30, 'minutes').isAfter(firstPlaceRta)
            ? TransportState.Delayed
            : moment().isAfter(firstPlaceRta)
            ? TransportState.Active
            : TransportState.Accepted;
    }

    toTransport(t: Transport): TransportModel {
        try {
            return {
                id: t.id || undefined,
                externalId: t.externalId,
                vehicle: t?.currentPrimaryAssignedMonitoredObject?.monitoredObjectId
                    ? String(t.currentPrimaryAssignedMonitoredObject.monitoredObjectId)
                    : undefined,
                name: t.name || '',
                firstPlaceRta: t.places?.[0]?.rta
                    ? moment(moment.utc(moment(t.places?.[0]?.rta).format('YYYY-MM-DD HH:mm:ss')).toDate())
                          .local()
                          .toISOString()
                    : '',
                lastPlaceRta: t.places?.[t.places?.length - 1]?.rta
                    ? moment(
                          moment
                              .utc(moment(t.places?.[t.places?.length - 1]?.rta).format('YYYY-MM-DD HH:mm:ss'))
                              .toDate()
                      )
                          .local()
                          .toISOString()
                    : '',
                profile: String(t.desiredVehicleProfile ?? ''),
                client: t.client ?? '',
                clientId: t.clientId,
                customerContactId: t.customerContactId,
                monitoredObjects: t.monitoredObjects,
                costPerKm: t.costPerKm
                    ? {
                          cost: t.costPerKm.value ?? 0,
                          currency: t.costPerKm.currency as AvailableCurrencies
                      }
                    : undefined,
                users:
                    t.users && t.users.length > 0
                        ? t.users.map(u => ({
                              name: u.name || '',
                              surname: u.surname || '',
                              id: u.id + '' || ''
                          }))
                        : [],
                state: t.state ?? TransportState.Planned,
                tollCost: t.tollCost,
                omitParamsFromRouting: t.omitRouteParams,
                pUESCTypeOfNotification: t.pUESCTypeOfNotification,
                version: t.version,
                lastDriver: t.lastDriver,
                lastTrailer: t.lastTrailer,
                puesc: t.puesc,
                places: (t.places || []).map((p, index) => {
                    const prevPlace = (t.places || [])[index - 1];
                    return {
                        id: p.id || this._generateId(),
                        center: latLngFromGeoJsonPointType(p.center ?? {}),
                        polygon: p.polygon ? latLngFromGeoJsonPolygonType(p.polygon) : undefined,
                        name: p.name || '',
                        note: p.note || '',
                        route: prevPlace?.route ? prevPlace.route : '',
                        eventStates: p.eventStates || [],
                        tasks: p.tasks,
                        rta: p.rta
                            ? moment(moment.utc(moment(p.rta).format('YYYY-MM-DD HH:mm:ss')).toDate())
                                  .local()
                                  .toISOString()
                            : '',
                        rtd: p.rtd
                            ? moment(moment.utc(moment(p.rtd).format('YYYY-MM-DD HH:mm:ss')).toDate())
                                  .local()
                                  .toISOString()
                            : '',
                        eta: p.eta
                            ? moment(moment.utc(moment(p.eta).format('YYYY-MM-DD HH:mm:ss')).toDate())
                                  .local()
                                  .toISOString()
                            : '',
                        ata: p.ata
                            ? moment(moment.utc(moment(p.ata).format('YYYY-MM-DD HH:mm:ss')).toDate())
                                  .local()
                                  .toISOString()
                            : '',
                        atd: p.atd
                            ? moment(moment.utc(moment(p.atd).format('YYYY-MM-DD HH:mm:ss')).toDate())
                                  .local()
                                  .toISOString()
                            : '',
                        distance: (t.version === 2 ? p?.distance : prevPlace?.distance) ?? 0,
                        duration: (t.version === 2 ? p?.duration : prevPlace?.duration) ?? 0,
                        addressStructured: p.addressStructured,
                        type: p.type
                            ? p.type === PlaceType.Start || p.type === PlaceType.End
                                ? PlaceType.Waypoint
                                : p.type
                            : PlaceType.Waypoint,
                        alarms: (p.eventRules || []).map(er => ({
                            name: er.name as unknown as TransportAlarmType,
                            config:
                                (er.config?.length ?? 0) > 0 && er.config?.[0]
                                    ? {
                                          name: er.config[0].name,
                                          value: er.config[0].value
                                      }
                                    : undefined
                        }))
                    } as PlacesModel;
                })
            };
        } catch (e) {
            console.error('unable to parse transport', t);
            throw e;
        }
    }

    private _generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}
