/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientCostStatOfFleetTypes,
    ClientCostStatOfFleetTypesFromJSON,
    ClientCostStatOfFleetTypesFromJSONTyped,
    ClientCostStatOfFleetTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientCostStat
 */
export interface ClientCostStat {
    /**
     * 
     * @type {number}
     * @memberof ClientCostStat
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCostStat
     */
    costPerKm: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCostStat
     */
    currency: string;
    /**
     * 
     * @type {Array<ClientCostStatOfFleetTypes>}
     * @memberof ClientCostStat
     */
    ofFleetTypes: Array<ClientCostStatOfFleetTypes>;
}

export function ClientCostStatFromJSON(json: any): ClientCostStat {
    return ClientCostStatFromJSONTyped(json, false);
}

export function ClientCostStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCostStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'costPerKm': json['cost_per_km'],
        'currency': json['currency'],
        'ofFleetTypes': ((json['of_fleet_types'] as Array<any>).map(ClientCostStatOfFleetTypesFromJSON)),
    };
}

export function ClientCostStatToJSON(value?: ClientCostStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'cost_per_km': value.costPerKm,
        'currency': value.currency,
        'of_fleet_types': ((value.ofFleetTypes as Array<any>).map(ClientCostStatOfFleetTypesToJSON)),
    };
}


