/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WriteOnlyJSONWebTokenSerializerTelematics,
    WriteOnlyJSONWebTokenSerializerTelematicsFromJSON,
    WriteOnlyJSONWebTokenSerializerTelematicsToJSON,
} from '../models';

export interface ApiTokenAuthCreateRequest {
    data: WriteOnlyJSONWebTokenSerializerTelematics;
}

/**
 * no description
 */
export class ApiTokenAuthApi extends runtime.BaseAPI {

    /**
     * Returns a JSON Web Token that can be used for authenticated requests.
     * API View that receives a POST with a user\'s username and password.
     */
    async apiTokenAuthCreateRaw(requestParameters: ApiTokenAuthCreateRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling apiTokenAuthCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api-token-auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyJSONWebTokenSerializerTelematicsToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a JSON Web Token that can be used for authenticated requests.
     * API View that receives a POST with a user\'s username and password.
     */
    async apiTokenAuthCreate(requestParameters: ApiTokenAuthCreateRequest): Promise<object> {
        const response = await this.apiTokenAuthCreateRaw(requestParameters);
        return await response.value();
    }

}
