import { Row, Col } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as TransportIcons from 'resources/images/transport';
import { useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import { Role } from 'logic/auth';
import PlannerAlarms from '../PlannerAlarms';
import { TransportEventRule, TransportPlaceTask } from 'generated/backend-api/models';

interface Props extends WithTranslation, WithLogic {
    task: TransportPlaceTask;
    placeDisabled: boolean;
    placeIndex: number;
    onAlarmConfigChange: (config: TransportEventRule[]) => void;
    onNoteForDriverChange: (val: string) => void;
    onManualAtaDateChange?: (dateTime: string) => void;
}

function PlaceTypeParking(props: Props) {
    const [collapseContent, setCollapseContent] = useState({
        notifications: true
    });

    const collapse = (
        title: string,
        type: keyof typeof collapseContent,
        collapsable: boolean = true,
        smallTitle: boolean = false
    ) => {
        return (
            <div className="place-type-collapse">
                <span
                    className={cn('place-type-collapse-title', {
                        small: smallTitle
                    })}
                >
                    {title}
                </span>
                {collapsable && (
                    <div className="place-type-collapse-action">
                        <span
                            onClick={() =>
                                setCollapseContent({
                                    ...collapseContent,
                                    [type]: !collapseContent[type]
                                })
                            }
                        >
                            {collapseContent[type] ? props.t('common.hide') : props.t('common.show')}{' '}
                        </span>

                        <img
                            src={collapseContent[type] ? TransportIcons.arrowDown : TransportIcons.arrowRight}
                            alt="arrow"
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="place-type-parking">
            <div className="place-type-parking-item">
                <Row gutter={[20, 10]} className="form-row form-row-title">
                    <Col span={24}>{collapse(props.t('PlannerPlacesTask.notifications'), 'notifications')}</Col>
                </Row>
                <div
                    className={cn('place-type-collapse-content', {
                        hidden: !collapseContent.notifications
                    })}
                >
                    {props.logic.auth().roles().includes(Role.IA_R) && (
                        <PlannerAlarms
                            task={props.task}
                            alarmValue={props.logic.plannerLogic().alarmsBySelectedPlace}
                            driverNoteValue={props.logic.plannerLogic().driverNoteBySelectedTask}
                            disabled={props.placeDisabled}
                            placeIndex={props.placeIndex}
                            onAlarmConfigChange={props.onAlarmConfigChange}
                            onNoteForDriverChange={props.onNoteForDriverChange}
                            onManualAtaDateChange={props.onManualAtaDateChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(withLogicContext(observer(PlaceTypeParking)));
