// @ts-nocheck
import { TCOOutput, TCOSettingsOutput } from 'generated/new-main';
export const settings: Omit<TCOSettingsOutput, 'constants'> = {
    operationalLifetime: {
        type: 'integer',
        defaultValue: 5,
        hint: false,
        units: 'years',
        visible: true,
        required: true
    },
    annualMileage: {
        type: 'float',
        defaultValue: 130000,
        units: 'km',
        required: true,
        visible: true,
        hint: false
    },
    dieselVehicleDetails: {
        type: 'array',
        values: {
            purchaseCost: {
                type: 'float',
                defaultValue: 90000,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualTollCost: {
                type: 'float',
                defaultValue: 24500,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualInsuranceCost: {
                type: 'float',
                defaultValue: 1800,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualOtherCost: {
                type: 'float',
                defaultValue: 0,
                units: '&euro;',
                required: true,
                visible: true,
                hint: false
            },
            annualServiceAndMaintenanceCost: {
                type: 'float',
                defaultValue: 3500,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            fuelPrice: {
                type: 'float',
                defaultValue: 1.1,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            fuelAverageConsumption: {
                type: 'float',
                defaultValue: 28,
                units: 'l/100 km',
                required: true,
                visible: true,
                hint: true
            },
            gCo2PerUnitProduction: {
                type: 'float',
                defaultValue: 2640,
                units: 'gCO2/l',
                required: true,
                visible: false,
                hint: false
            },
            residualValue: {
                type: 'float',
                defaultValue: 0,
                units: '&euro;',
                required: true,
                visible: true,
                hint: false
            },
            adbluePrice: {
                type: 'float',
                defaultValue: 0.4,
                units: '&euro;',
                required: false,
                visible: false,
                hint: false
            },
            adblueAverageConsumption: {
                type: 'float',
                defaultValue: 2.15,
                units: 'l/100 km',
                required: false,
                visible: false,
                hint: false
            }
        }
    },
    lngVehicleDetails: {
        type: 'array',
        values: {
            purchaseCost: {
                type: 'float',
                defaultValue: 120000,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualTollCost: {
                type: 'float',
                defaultValue: 17400,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualInsuranceCost: {
                type: 'float',
                defaultValue: 2400,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            annualOtherCost: {
                type: 'float',
                defaultValue: 0,
                units: '&euro;',
                required: true,
                visible: true,
                hint: false
            },
            annualServiceAndMaintenanceCost: {
                type: 'float',
                defaultValue: 4025,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            fuelPrice: {
                type: 'float',
                defaultValue: 1.5,
                units: '&euro;',
                required: true,
                visible: true,
                hint: true
            },
            fuelAverageConsumption: {
                type: 'float',
                defaultValue: 25.2,
                units: 'kg/100 km',
                required: true,
                visible: true,
                hint: true
            },
            gCo2PerUnitProduction: {
                type: 'float',
                defaultValue: 2459,
                units: 'gCO2/kg',
                required: true,
                visible: false,
                hint: false
            },
            residualValue: {
                type: 'float',
                defaultValue: 0,
                units: '&euro;',
                required: true,
                visible: true,
                hint: false
            }
        }
    }
};

export const constants: TCOSettingsOutput['constants'] = {
    tel: '+421901111111',
    email: 'info@eurowag.com'
};

export const calculated: TCOOutput = {
    lngVehicleDetails: [
        {
            year: {
                value: 1,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 120000,
                units: '&euro;'
            },
            insuranceCost: {
                value: 2400,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 4025,
                units: '&euro;'
            },
            fuelCost: {
                value: 49139.99999999999,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 32760,
                units: 'kg'
            },
            tollCost: {
                value: 17400,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 192965,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        },
        {
            year: {
                value: 2,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 2400,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 4025,
                units: '&euro;'
            },
            fuelCost: {
                value: 49139.99999999999,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 32760,
                units: 'kg'
            },
            tollCost: {
                value: 17400,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 72965,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        },
        {
            year: {
                value: 3,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 2400,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 4025,
                units: '&euro;'
            },
            fuelCost: {
                value: 49139.99999999999,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 32760,
                units: 'kg'
            },
            tollCost: {
                value: 17400,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 72965,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        },
        {
            year: {
                value: 4,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 2400,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 4025,
                units: '&euro;'
            },
            fuelCost: {
                value: 49139.99999999999,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 32760,
                units: 'kg'
            },
            tollCost: {
                value: 17400,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 72965,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        },
        {
            year: {
                value: 5,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 2400,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 4025,
                units: '&euro;'
            },
            fuelCost: {
                value: 49139.99999999999,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 32760,
                units: 'kg'
            },
            tollCost: {
                value: 17400,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 72965,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        }
    ],
    dieselVehicleDetails: [
        {
            year: {
                value: 1,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 90000,
                units: '&euro;'
            },
            insuranceCost: {
                value: 1800,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 3500,
                units: '&euro;'
            },
            fuelCost: {
                value: 40040.00000000001,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 36400,
                units: 'l'
            },
            tollCost: {
                value: 24500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 160958,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 1118,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 2795,
                units: 'l'
            }
        },
        {
            year: {
                value: 2,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 1800,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 3500,
                units: '&euro;'
            },
            fuelCost: {
                value: 40040.00000000001,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 36400,
                units: 'l'
            },
            tollCost: {
                value: 24500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 70958,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 1118,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 2795,
                units: 'l'
            }
        },
        {
            year: {
                value: 3,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 1800,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 3500,
                units: '&euro;'
            },
            fuelCost: {
                value: 40040.00000000001,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 36400,
                units: 'l'
            },
            tollCost: {
                value: 24500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 70958,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 1118,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 2795,
                units: 'l'
            }
        },
        {
            year: {
                value: 4,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 1800,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 3500,
                units: '&euro;'
            },
            fuelCost: {
                value: 40040.00000000001,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 36400,
                units: 'l'
            },
            tollCost: {
                value: 24500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 70958,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 1118,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 2795,
                units: 'l'
            }
        },
        {
            year: {
                value: 5,
                units: 'year'
            },
            mileage: {
                value: 130000,
                units: 'km'
            },
            purchaseCost: {
                value: 0,
                units: '&euro;'
            },
            insuranceCost: {
                value: 1800,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 3500,
                units: '&euro;'
            },
            fuelCost: {
                value: 40040.00000000001,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 36400,
                units: 'l'
            },
            tollCost: {
                value: 24500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 70958,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 0,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 1118,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 2795,
                units: 'l'
            }
        }
    ],
    total: {
        lngVehicleDetails: {
            year: {
                value: 5,
                units: 'year'
            },
            mileage: {
                value: 650000,
                units: 'km'
            },
            purchaseCost: {
                value: 120000,
                units: '&euro;'
            },
            insuranceCost: {
                value: 12000,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 20125,
                units: '&euro;'
            },
            fuelCost: {
                value: 245699.99999999997,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.5,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 25.2,
                units: 'kg/100 km'
            },
            totalConsumptionOfFuel: {
                value: 163800,
                units: 'kg'
            },
            tollCost: {
                value: 87000,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 484825,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 2459,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            }
        },
        dieselVehicleDetails: {
            year: {
                value: 5,
                units: 'year'
            },
            mileage: {
                value: 650000,
                units: 'km'
            },
            purchaseCost: {
                value: 90000,
                units: '&euro;'
            },
            insuranceCost: {
                value: 0,
                units: '&euro;'
            },
            serviceAndMaintenanceCost: {
                value: 17500,
                units: '&euro;'
            },
            fuelCost: {
                value: 200200.00000000003,
                units: '&euro;'
            },
            fuelPrice: {
                value: 1.1,
                units: '&euro;'
            },
            fuelAverageConsumption: {
                value: 28,
                units: 'l/100 km'
            },
            totalConsumptionOfFuel: {
                value: 182000,
                units: 'l'
            },
            tollCost: {
                value: 122500,
                units: '&euro;'
            },
            otherCost: {
                value: 0,
                units: '&euro;'
            },
            totalCost: {
                value: 444790,
                units: '&euro;'
            },
            gCo2PerUnitProduction: {
                value: 2640,
                units: 'gCO2/kg'
            },
            residualValue: {
                value: 0,
                units: '&euro;'
            },
            adblueCosts: {
                value: 5590,
                units: '&euro;'
            },
            adbluePrice: {
                value: 0.4,
                units: '&euro;'
            },
            adblueAverageConsumption: {
                value: 2.15,
                units: 'l/100 km'
            },
            totalConsumptionOfAdblue: {
                value: 13975,
                units: 'l'
            }
        }
    }
};
