/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPRequest,
    WAGTPRequestFromJSON,
    WAGTPRequestFromJSONTyped,
    WAGTPRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface BodyObePersonalizationObePersonalizationPost
 */
export interface BodyObePersonalizationObePersonalizationPost {
    /**
     * 
     * @type {WAGTPRequest}
     * @memberof BodyObePersonalizationObePersonalizationPost
     */
    request: WAGTPRequest;
}

export function BodyObePersonalizationObePersonalizationPostFromJSON(json: any): BodyObePersonalizationObePersonalizationPost {
    return BodyObePersonalizationObePersonalizationPostFromJSONTyped(json, false);
}

export function BodyObePersonalizationObePersonalizationPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyObePersonalizationObePersonalizationPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': WAGTPRequestFromJSON(json['request']),
    };
}

export function BodyObePersonalizationObePersonalizationPostToJSON(value?: BodyObePersonalizationObePersonalizationPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': WAGTPRequestToJSON(value.request),
    };
}


