import { Logic } from 'logic/logic';
import i18n from 'i18next';
import { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

import { RouteNames } from 'App';

import DriverBehaviorCollapse from '../../../components/DriverBehaviorCollapse';
import DriverBehaviorStat, { DriverBehaviorStatColor } from '../../../components/DriverBehaviorStat';
import DriverTrendsScoreStats from '../../../components/DriverTrendsScoreStats';
import DriverDetailNav, { DriverBehaviorNavType, DriverBehaviorNavTabs } from '../../../components/DriverDetailNav';
import DriverStatValue, { DriverStatValueFormat } from '../../../components/DriverStatValue';
import qa from 'qa-selectors';
import Trend, { TrendChange } from 'common/components/Trend';
import moment from 'moment';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { HelperModal } from 'common/components';
import DriverBehaviorCoach, { DriverBehaviorCoachDataMap } from '../../../components/DriverBehaviorCoach';
import DriverBehaviorNoData from '../../../components/DriverBehaviorNoData';
import { DriverBehaviorLoading } from '../../../components/DriverBehaviorLoading/Index';
import { Subscription } from 'rxjs';
import { formatName, translateFormatedText } from 'common/utils/textUtils';
import InfoBanner from 'common/components/InfoBanner';
import {
    formatDriverBehaviorName,
    getPerfectDriveValue,
    PerfectDriveValueKey
} from 'logic/driver-behavior-coach/driver-behavior-trucks';
import { PerfectDriveDriverDetailModel } from 'common/model/statistics';
import { DATE_FORMAT, DRIVER_BEHAVIOR_DATE_FORMAT } from 'domain-constants';
import ChartLineTrends from 'common/components/ChartLineTrends';
import ChartCompare, { ChartCompareModel } from 'common/components/ChartCompare';
import { PerfectDriveGroupedByDriver } from 'generated/backend-api';
import { Themes } from 'common/components/ChartTimeline';
import qs from 'qs';
import DriverBehaviorChartBar from '../../../components/DriverBehaviorChartBar';
import { message } from 'antd';
import { Theme } from 'common/components/Settings';

function DriverBehaviorIcon({ icon }: { icon: string }) {
    return <img src={icon} alt={icon} />;
}

function getRomanNumeralMonth(month: number) {
    const romanNumerals = {
        1: 'I',
        2: 'II',
        3: 'III',
        4: 'IV',
        5: 'V',
        6: 'VI',
        7: 'VII',
        8: 'VIII',
        9: 'IX',
        10: 'X',
        11: 'XI',
        12: 'XII'
    };
    return romanNumerals[month];
}

export type DriverBehaviourRouteParams = {
    date?: string;
    comparedDate?: string;
    comparedDriverId?: string;
};
export interface DriverBehaviorDataMapModel {
    key: string;
    value?: PerfectDriveValueKey;
    title?: string;
    unit?: DriverStatValueFormat;
    unitName?: string;
    icon?: string;
    trendForIncrease?: TrendChange;
    diminisher?: number;
    postfix?: ReactNode | string;
    fixed?: number;
}

export interface DriverBehaviorDataMap {
    [key: string]: DriverBehaviorDataMapModel[];
}

interface Props extends WithTranslation, RouteComponentProps<{ id: string }> {
    logic: Logic;
    selectedTab: DriverBehaviorNavTabs;
    theme: Theme;
    onDetailTabChange?: (tab: DriverBehaviorNavTabs) => void;
    onBackButtonClick?: () => void;
}

interface State {
    dataMap: DriverBehaviorDataMap;
    coachDataMap: { [key: string]: DriverBehaviorCoachDataMap[] };
    drivers: {
        value: string;
        label: string;
    }[];
    date?: string;
    comparedDate?: string;
    comparedDriverId?: string;
    export: {
        processing: boolean;
        enable: boolean;
    };
    bar?: {
        name: string;
        rank?: number;
        tachocard?: string;
    };
    chart?: {
        comparedDriverId?: string;
    };
    helper?: {
        content: string;
    };
    loading: boolean;
    detailDataLoading: boolean;
}

/**
 * Driver detail module takes care of displaying detail of current selected driver in url
 */
class TruckDetailModule extends Component<Props, State> {
    subscriptionLoading?: Subscription;
    subscriptionDetailDataLoading?: Subscription;

    constructor(props: Props) {
        super(props);
        const params: DriverBehaviourRouteParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        this.state = {
            dataMap: this.props.logic.driverBehaviorCoach().trucks().getGeneralDataMap(),
            coachDataMap: this.props.logic.driverBehaviorCoach().trucks().getCoachDataMap(),
            export: {
                enable: true,
                processing: false
            },
            date: params?.date ?? moment().startOf('months').toISOString(),
            comparedDate: params?.comparedDate,
            comparedDriverId: params?.comparedDriverId,
            chart: {
                comparedDriverId: params.comparedDriverId
            },
            drivers: [],
            loading: true,
            detailDataLoading: false
        };

        this.props.logic.driverBehaviorCoach().trucks().setDate(moment(this.state.date, DATE_FORMAT).toISOString());
        this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .setComparedDate(moment(this.state.comparedDate, DATE_FORMAT).toISOString());
        this.props.logic.driverBehaviorCoach().trucks().setComparedDriverId(this.state.comparedDriverId);
    }

    async componentDidMount() {
        this.props.logic
            .users()
            .drivers()
            .then(driversData => {
                const drivers = driversData.filter(e => e.id);
                this.props.logic.driverBehaviorCoach().trucks().setDrivers(drivers);
                this.setState({
                    drivers:
                        drivers
                            .map(e => ({ value: e.id ?? '', label: formatName(e.name, e.surname) }))
                            .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
                });
            });
        await this.props.logic
            .vehicles()
            .getMonitoredObjectFilters(true, true)
            .then(vehicles => {
                this.props.logic.driverBehaviorCoach().trucks().setVehicles(vehicles);
            });
        this.subscriptionLoading = this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .loading.subscribe(loading => {
                this.setState({ loading });
            });
        this.subscriptionDetailDataLoading = this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .detailDataLoading.subscribe(detailDataLoading => {
                this.setState({ detailDataLoading });
            });

        if (
            this.props.match.params.id &&
            this.props.match.params.id !== this.props.logic.driverBehaviorCoach().trucks().getSelectedDriverId()
        ) {
            this.props.logic.driverBehaviorCoach().trucks().setSelectedDriverId(this.props.match.params.id);
            this._setUrlParams();
        }

        await this.props.logic.driverBehaviorCoach().trucks().setDriverData();

        const detail = this.props.logic.driverBehaviorCoach().trucks().getDetailData();
        const date = this.props.logic.driverBehaviorCoach().trucks().getDate();
        const comparedDate = this.props.logic.driverBehaviorCoach().trucks().getComparedDate();

        if (detail && !this.props.logic.driverBehaviorCoach().trucks().showPromo()) {
            if (this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
                await this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
            } else {
                this.props.logic.driverBehaviorCoach().trucks().setDetailComparedData(undefined);
            }
            this.setState({
                bar: {
                    name: formatDriverBehaviorName(detail.name, detail.surname, detail.tachocard),
                    rank: detail.scores?.[0].rank ?? 1,
                    tachocard: detail.tachocard
                },
                date,
                comparedDate
            });
        } else {
            this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const params: DriverBehaviourRouteParams = qs.parse(this.props.history.location.search, {
                ignoreQueryPrefix: true
            });

            this.props.logic.driverBehaviorCoach().trucks().setSelectedDriverId(this.props.match.params.id);
            this.props.logic
                .driverBehaviorCoach()
                .trucks()
                .setDate(params.date ? moment(params.date, DATE_FORMAT).toISOString() : undefined);
            await this.props.logic.driverBehaviorCoach().trucks().setDriverData();

            const detail = this.props.logic
                .driverBehaviorCoach()
                .trucks()
                .getDataReportByDriverId(this.props.match.params.id);

            if (detail) {
                this.props.logic
                    .driverBehaviorCoach()
                    .trucks()
                    .setComparedDate(
                        params.comparedDate ? moment(params.comparedDate, DATE_FORMAT).toISOString() : undefined
                    );

                this.props.logic.driverBehaviorCoach().trucks().setComparedDriverId(params.comparedDriverId);
                if (!this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
                    this.props.logic.driverBehaviorCoach().trucks().setDetailComparedData();
                }

                const date = this.props.logic.driverBehaviorCoach().trucks().getDate();
                const comparedDate = this.props.logic.driverBehaviorCoach().trucks().getComparedDate();

                this.setState({
                    bar: {
                        name: formatDriverBehaviorName(detail.name, detail.surname, detail.tachocard),
                        rank: detail.scores?.[0]?.rank ?? 1,
                        tachocard: detail.tachocard
                    },
                    date,
                    comparedDriverId: this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId(),
                    comparedDate
                });
            } else {
                this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
            }
        }

        this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .exportDetailProcessing.subscribe(processing => {
                this.setState(state => ({
                    export: {
                        ...state.export,
                        processing
                    }
                }));
            });
    }

    async componentWillUnmount() {
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionDetailDataLoading?.unsubscribe();
    }

    getDataMapByKey(key: string): DriverBehaviorCoachDataMap | undefined {
        for (let dataKey in this.state.coachDataMap) {
            const data = this.state.coachDataMap[dataKey].find(d => d.key === key);
            if (data) {
                return data;
            }
        }
        return undefined;
    }

    getPerfectDriveValueToGraph(
        valueKey?: PerfectDriveValueKey,
        data?: PerfectDriveGroupedByDriver,
        unit?: string,
        fixed?: number
    ): number | undefined {
        const value = getPerfectDriveValue(valueKey, data);
        return ['durationTime', 'duration'].includes(unit ?? '')
            ? value
                ? Math.round(value ?? 0) / 3600
                : undefined
            : value
            ? Number(value.toFixed(fixed ?? 1))
            : value;
    }

    getBasicPopupGraph(
        e: DriverBehaviorDataMapModel,
        selectedData: PerfectDriveDriverDetailModel | undefined,
        comparedData: PerfectDriveDriverDetailModel | undefined,
        theme: Themes | undefined,
        drivers: { value: string; label: string }[],
        color: string,
        trendChangeForIncrease: TrendChange | undefined
    ) {
        return selectedData && comparedData === undefined ? (
            <ChartLineTrends
                trendChangeForIncrease={trendChangeForIncrease}
                color={color}
                size="large"
                theme={theme}
                showPoints={true}
                pointRadius={1}
                data={selectedData.history
                    .map((score, i) => {
                        const selectedScore = this.getPerfectDriveValueToGraph(e.value, score?.detail, e.unit, e.fixed);
                        const prevScore = this.getPerfectDriveValueToGraph(
                            e.value,
                            selectedData.history[i + 1]?.detail,
                            e.unit,
                            e.fixed
                        );
                        return {
                            xIndex: i,
                            yVal: selectedScore,
                            xVal: i < 12 ? getRomanNumeralMonth(Number(moment(score.month).format('M'))) : undefined,
                            trendVal:
                                selectedScore && prevScore
                                    ? selectedScore > prevScore
                                        ? 'up'
                                        : selectedScore < prevScore
                                        ? 'down'
                                        : undefined
                                    : undefined
                        };
                    })
                    .reverse()}
                minY={0}
                height={270}
                width={600}
                lineWidth={2}
            />
        ) : selectedData && comparedData ? (
            <>
                <DriverBehaviorChartBar
                    color={color}
                    drivers={drivers}
                    mainId={selectedData.id}
                    comparedDriverId={comparedData.id}
                    useScore={false}
                />
                <ChartCompare
                    color={color}
                    data={[
                        ...selectedData.history
                            .map(
                                (score, i) =>
                                    ({
                                        xIndex: i,
                                        xVal:
                                            i < 12
                                                ? getRomanNumeralMonth(Number(moment(score.month).format('M')))
                                                : undefined,
                                        yVal: this.getPerfectDriveValueToGraph(e.value, score?.detail, e.unit, e.fixed),
                                        yLine: 'main'
                                    } as ChartCompareModel)
                            )
                            .reverse(),
                        ...comparedData.history
                            .map(
                                (score, i) =>
                                    ({
                                        xIndex: i,
                                        xVal:
                                            i < 12
                                                ? getRomanNumeralMonth(Number(moment(score.month).format('M')))
                                                : undefined,
                                        yVal: this.getPerfectDriveValueToGraph(e.value, score?.detail, e.unit, e.fixed),
                                        yLine: 'compared'
                                    } as ChartCompareModel)
                            )
                            .reverse()
                    ]}
                    theme={theme}
                    height={270}
                    width={600}
                    lineWidth={2}
                />
            </>
        ) : undefined;
    }

    render() {
        const { t } = this.props;
        const { dataMap, date, comparedDate, comparedDriverId, loading } = this.state;
        const theme = this.props.logic.settings().getProp('theme');
        const selectedData = this.props.logic.driverBehaviorCoach().trucks().getDetailData();
        const comparedData = this.props.logic.driverBehaviorCoach().trucks().getDetailComparedData();
        const recommend = this.props.logic.driverBehaviorCoach().trucks().getRecommends()?.[0];

        return (
            <div className="driver-behavior">
                <DriverDetailNav
                    loading={this.state.loading || this.state.detailDataLoading}
                    type={DriverBehaviorNavType.Truck}
                    selectedTab={this.props.selectedTab}
                    name={this.props.logic.driverBehaviorCoach().trucks().getSelectedDriverName()}
                    tachocard={this.state.bar?.tachocard}
                    drivers={this.state.drivers ?? []}
                    driverId={selectedData?.id}
                    comparedDriverId={comparedDriverId}
                    disabledComparedDriver={!selectedData?.scores?.[0]?.detail}
                    date={date}
                    comparedDate={comparedDate}
                    export={this.state.export}
                    onExportClick={() => {
                        if (this.props.selectedTab === DriverBehaviorNavTabs.coach) {
                            this.props.logic.driverBehaviorCoach().trucks().downloadDriverBehaviorDetailCoachExport();
                        } else {
                            this.props.logic.driverBehaviorCoach().trucks().downloadDriverBehaviorDetailExport();
                        }
                    }}
                    onTabChange={this._onTabChange}
                    onDateRangeChange={this._onDateRangeChange}
                    onBarDateNextClick={this._onBarDateNextClick}
                    onBarDatePreviousClick={this._onBarDatePreviousClick}
                    onComparedDateRangeChange={this._onComparedDateRangeChange}
                    onBarComparedDateNextClick={this._onBarComparedDateNextClick}
                    onBarComparedDatePreviousClick={this._onBarComparedDatePreviousClick}
                    onBackButtonClick={this._onNavBackClick}
                    onCompareDriverSelect={this._onCompareDriverSelect}
                    onHelperClick={this._onBarHelperClick}
                    demoMode={this.props.logic.demo().isActive}
                />

                {loading ? (
                    <DriverBehaviorLoading />
                ) : selectedData?.scores?.[0].detail ? (
                    <>
                        {this.props.selectedTab === DriverBehaviorNavTabs.basic && (
                            <>
                                {selectedData && !comparedData && recommend && (
                                    <InfoBanner
                                        className="basic-recommend-info"
                                        icon="bust"
                                        iconOrientation="left"
                                        decoration={false}
                                        title={translateFormatedText(recommend?.title)}
                                        text={translateFormatedText(recommend?.recommend)}
                                    />
                                )}
                                <DriverTrendsScoreStats
                                    selectedTab={this.props.selectedTab}
                                    data={selectedData}
                                    drivers={this.state.drivers ?? []}
                                    comparedData={comparedData}
                                    theme={this.props.theme}
                                />
                                <div className="driver-behavior-data">
                                    <DriverBehaviorCollapse
                                        title={i18n.t('DriverBehaviorDetail.driveData.title')}
                                        stats={dataMap.driveData.map(e => (
                                            <DriverBehaviorStat
                                                key={e.key}
                                                color={DriverBehaviorStatColor.BLUE}
                                                value={
                                                    <DriverStatValue
                                                        value={getPerfectDriveValue(
                                                            e.value,
                                                            selectedData?.scores[0]?.detail
                                                        )}
                                                        unit={e.unit}
                                                        unitName={translateFormatedText(e.unitName)}
                                                        fixed={e.fixed}
                                                    />
                                                }
                                                description={translateFormatedText(e.title)}
                                                development={
                                                    <Trend
                                                        direction={getIncreaseDirection(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail
                                                            )
                                                        )}
                                                        trendChangeForIncrease={
                                                            e.trendForIncrease ?? TrendChange.NEUTRAL
                                                        }
                                                        value={getIncreasePercent(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail
                                                            )
                                                        )}
                                                        theme={this.props.theme}
                                                    />
                                                }
                                                icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                                qa={`driver-behaviour--trucks-detail--${e.key}`}
                                                popover={this.getBasicPopupGraph(
                                                    e,
                                                    selectedData,
                                                    comparedData,
                                                    theme,
                                                    this.state.drivers,
                                                    '#338fff',
                                                    e.trendForIncrease
                                                )}
                                            />
                                        ))}
                                        qa={qa.driverBehaviour.trucks.detail.drive.collapse}
                                    />

                                    <DriverBehaviorCollapse
                                        title={t('DriverBehaviorDetail.ecoData.title')}
                                        stats={dataMap.ecoData.map(e => (
                                            <DriverBehaviorStat
                                                key={e.key}
                                                color={DriverBehaviorStatColor.BLUE}
                                                value={
                                                    <DriverStatValue
                                                        value={getPerfectDriveValue(
                                                            e.value,
                                                            selectedData?.scores[0]?.detail
                                                        )}
                                                        unit={e.unit}
                                                        unitName={translateFormatedText(e.unitName)}
                                                        fixed={e.fixed}
                                                    />
                                                }
                                                description={translateFormatedText(e.title)}
                                                development={
                                                    <Trend
                                                        direction={getIncreaseDirection(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail
                                                            )
                                                        )}
                                                        trendChangeForIncrease={
                                                            e.trendForIncrease ?? TrendChange.NEUTRAL
                                                        }
                                                        value={getIncreasePercent(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail,
                                                                0
                                                            )
                                                        )}
                                                        theme={this.props.theme}
                                                    />
                                                }
                                                icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                                qa={`driver-behaviour--trucks-detail--${e.key}`}
                                                popover={this.getBasicPopupGraph(
                                                    e,
                                                    selectedData,
                                                    comparedData,
                                                    theme,
                                                    this.state.drivers,
                                                    'green',
                                                    e.trendForIncrease
                                                )}
                                            />
                                        ))}
                                        qa={qa.driverBehaviour.trucks.detail.wearTear.collapse}
                                    />
                                    <DriverBehaviorCollapse
                                        title={t('DriverBehaviorDetail.wearTearData.title')}
                                        stats={dataMap.wearTearData.map(e => (
                                            <DriverBehaviorStat
                                                key={e.key}
                                                color={DriverBehaviorStatColor.YELLOW}
                                                value={
                                                    <DriverStatValue
                                                        value={getPerfectDriveValue(
                                                            e.value,
                                                            selectedData?.scores[0]?.detail
                                                        )}
                                                        unit={e.unit}
                                                        unitName={translateFormatedText(e.unitName)}
                                                        fixed={e.fixed}
                                                    />
                                                }
                                                description={translateFormatedText(e.title)}
                                                development={
                                                    <Trend
                                                        direction={getIncreaseDirection(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail
                                                            )
                                                        )}
                                                        trendChangeForIncrease={
                                                            e.trendForIncrease ?? TrendChange.NEUTRAL
                                                        }
                                                        value={getIncreasePercent(
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[0]?.detail
                                                            ),
                                                            getPerfectDriveValue(
                                                                e.value,
                                                                selectedData?.scores[1]?.detail
                                                            )
                                                        )}
                                                        theme={this.props.theme}
                                                    />
                                                }
                                                icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                                qa={`driver-behaviour--trucks-detail--${e.key}`}
                                                popover={this.getBasicPopupGraph(
                                                    e,
                                                    selectedData,
                                                    comparedData,
                                                    theme,
                                                    this.state.drivers,
                                                    'yellow',
                                                    e.trendForIncrease
                                                )}
                                            />
                                        ))}
                                        qa={qa.driverBehaviour.trucks.detail.eco.collapse}
                                    />
                                </div>
                            </>
                        )}
                        {this.props.selectedTab === DriverBehaviorNavTabs.coach && (
                            <div className="driver-behavior-data">
                                <DriverBehaviorCoach
                                    dataMap={this.state.coachDataMap}
                                    logic={this.props.logic}
                                    selectedTab={this.props.selectedTab}
                                    data={selectedData}
                                    comparedData={comparedData}
                                    driverList={this.state.drivers}
                                    theme={this.props.theme}
                                />
                            </div>
                        )}
                    </>
                ) : !loading ? (
                    <div className="card-no-data">
                        <DriverBehaviorNoData />
                    </div>
                ) : undefined}

                <HelperModal
                    name="driver-behavior"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </div>
        );
    }

    private _onNavBackClick = () => {
        this.props.onBackButtonClick?.();
        this.props.history.push({
            pathname: RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS,
            search: qs.stringify({
                date: moment(this.props.logic.settings().getProp('driverBehavior')?.date).format(DATE_FORMAT)
            })
        });
    };

    private _onCompareDriverSelect = (id: string | undefined) => {
        if (!this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
            this.props.logic
                .driverBehaviorCoach()
                .trucks()
                .setComparedDate(this.props.logic.driverBehaviorCoach().trucks().getDate());
            this._setUrlParams();
        }

        this.props.logic.driverBehaviorCoach().trucks().setComparedDriverId(id);

        if (id !== '') {
            this.props.logic
                .driverBehaviorCoach()
                .trucks()
                .setDriverData(true)
                .then(() => {
                    this.setState({
                        comparedDate: this.props.logic.driverBehaviorCoach().trucks().getComparedDate(),
                        comparedDriverId: id,
                        chart: {
                            comparedDriverId: id
                        }
                    });
                });
        } else {
            this.props.logic.driverBehaviorCoach().trucks().setComparedDate(undefined);
            this.props.logic.driverBehaviorCoach().trucks().setDetailComparedData(undefined);

            this.setState({
                comparedDate: undefined,
                comparedDriverId: id,
                chart: {
                    comparedDriverId: id
                }
            });
        }
        this._setUrlParams();
    };

    private _setComparedDate() {
        const compareDate = this.props.logic.driverBehaviorCoach().trucks().getComparedDate();
        if (this.props.logic.driverBehaviorCoach().trucks().comparedDriverId) {
            const date = this.props.logic.driverBehaviorCoach().trucks().getDate();

            this.props.logic.driverBehaviorCoach().trucks().setComparedDate(date);
            return date;
        }
        return compareDate;
    }

    private _onDateRangeChange = async (value: string) => {
        const date = moment(value).toISOString();
        this.props.logic.driverBehaviorCoach().trucks().setDate(date);
        const comparedDate = this._setComparedDate();

        await this.props.logic.driverBehaviorCoach().trucks().setDriverData();
        if (this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
            this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
        }

        this.setState(
            {
                date,
                comparedDate
            },
            () => {
                this._setUrlParams();
            }
        );
    };

    private _onBarDatePreviousClick = async () => {
        if (this.state.loading || this.state.detailDataLoading) {
            return;
        }
        const date = moment(this.state.date).subtract(1, 'month').toISOString();
        this.props.logic.driverBehaviorCoach().trucks().setDate(date);
        const comparedDate = this._setComparedDate();

        await this.props.logic.driverBehaviorCoach().trucks().setDriverData();
        if (this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
            this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
        }

        this.setState(
            {
                date,
                comparedDate
            },
            () => {
                this._setUrlParams();
            }
        );
    };

    private _onBarDateNextClick = async () => {
        if (this.state.loading || this.state.detailDataLoading) {
            return;
        }
        const date = moment(this.state.date).add(1, 'month').toISOString();
        this.props.logic.driverBehaviorCoach().trucks().setDate(date);
        const comparedDate = this._setComparedDate();

        await this.props.logic.driverBehaviorCoach().trucks().setDriverData();
        if (this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
            this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
        }

        this.setState(
            {
                date,
                comparedDate
            },
            () => {
                this._setUrlParams();
            }
        );
    };

    private _onComparedDateRangeChange = (value: string) => {
        const comparedDate = moment(value).toISOString();
        const date = moment(this.state.date).add(1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);
        const minDate = moment(date).add(-13, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);

        if (moment(comparedDate).isAfter(minDate) && moment(comparedDate).isBefore(date)) {
            this.props.logic.driverBehaviorCoach().trucks().setComparedDate(comparedDate);
            this.setState(
                {
                    comparedDate
                },
                () => {
                    this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
                    this._setUrlParams();
                }
            );
        } else {
            message.warning(this.props.i18n.t('DriverBehaviorDetail.dateComparedWarning'));
        }
    };

    private _onBarComparedDatePreviousClick = () => {
        const comparedDate = moment(this.state.comparedDate).subtract(1, 'month').toISOString();
        const date = moment(this.state.date).add(1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);
        const minDate = moment(date).add(-13, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);

        if (moment(comparedDate).isAfter(minDate) && moment(comparedDate).isBefore(date)) {
            this.props.logic.driverBehaviorCoach().trucks().setComparedDate(comparedDate);
            this.setState(
                {
                    comparedDate
                },
                () => {
                    this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
                    this._setUrlParams();
                }
            );
        } else {
            message.warning(this.props.i18n.t('DriverBehaviorDetail.dateComparedWarning'));
        }
    };

    private _onBarComparedDateNextClick = () => {
        const comparedDate = moment(this.state.comparedDate).add(1, 'month').toISOString();
        const date = moment(this.state.date).add(1, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);
        const minDate = moment(date).add(-13, 'months').format(DRIVER_BEHAVIOR_DATE_FORMAT);

        if (moment(comparedDate).isAfter(minDate) && moment(comparedDate).isBefore(date)) {
            this.props.logic.driverBehaviorCoach().trucks().setComparedDate(comparedDate);
            this.setState(
                {
                    comparedDate
                },
                () => {
                    this.props.logic.driverBehaviorCoach().trucks().setDriverData(true);
                    this._setUrlParams();
                }
            );
        } else {
            message.warning(this.props.i18n.t('DriverBehaviorDetail.dateComparedWarning'));
        }
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'driverbehaviourcoach';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onTabChange = (tab: DriverBehaviorNavTabs) => {
        this.props.onDetailTabChange?.(tab);
    };

    private _setUrlParams = () => {
        this.props.history.push({
            search: qs.stringify({
                date: moment(this.props.logic.driverBehaviorCoach().trucks().getDate()).format(DATE_FORMAT),
                comparedDate: this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()
                    ? moment(this.props.logic.driverBehaviorCoach().trucks().getComparedDate()).format(DATE_FORMAT)
                    : undefined,
                comparedDriverId: this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()
            } as DriverBehaviourRouteParams)
        });
    };
}

export default withRouter(withTranslation()(TruckDetailModule));
