/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleDriverState
 */
export interface VehicleDriverState {
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverState
     */
    activityStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverState
     */
    drivingOrWorkingStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverState
     */
    activity?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverState
     */
    nextBreakStartTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverState
     */
    nextBreakStartTime2?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    drivingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    restingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    workingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    biweeklyDrivingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    weeklyDrivingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDriverState
     */
    choppedDrivingTimeCapacity?: number;
}

export function VehicleDriverStateFromJSON(json: any): VehicleDriverState {
    return VehicleDriverStateFromJSONTyped(json, false);
}

export function VehicleDriverStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDriverState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityStartTime': !exists(json, 'activityStartTime') ? undefined : json['activityStartTime'],
        'drivingOrWorkingStartTime': !exists(json, 'drivingOrWorkingStartTime') ? undefined : json['drivingOrWorkingStartTime'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'nextBreakStartTime': !exists(json, 'nextBreakStartTime') ? undefined : json['nextBreakStartTime'],
        'nextBreakStartTime2': !exists(json, 'nextBreakStartTime2') ? undefined : json['nextBreakStartTime2'],
        'drivingDuration': !exists(json, 'drivingDuration') ? undefined : json['drivingDuration'],
        'restingDuration': !exists(json, 'restingDuration') ? undefined : json['restingDuration'],
        'workingDuration': !exists(json, 'workingDuration') ? undefined : json['workingDuration'],
        'biweeklyDrivingDuration': !exists(json, 'biweeklyDrivingDuration') ? undefined : json['biweeklyDrivingDuration'],
        'weeklyDrivingDuration': !exists(json, 'weeklyDrivingDuration') ? undefined : json['weeklyDrivingDuration'],
        'choppedDrivingTimeCapacity': !exists(json, 'choppedDrivingTimeCapacity') ? undefined : json['choppedDrivingTimeCapacity'],
    };
}

export function VehicleDriverStateToJSON(value?: VehicleDriverState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityStartTime': value.activityStartTime,
        'drivingOrWorkingStartTime': value.drivingOrWorkingStartTime,
        'activity': value.activity,
        'nextBreakStartTime': value.nextBreakStartTime,
        'nextBreakStartTime2': value.nextBreakStartTime2,
        'drivingDuration': value.drivingDuration,
        'restingDuration': value.restingDuration,
        'workingDuration': value.workingDuration,
        'biweeklyDrivingDuration': value.biweeklyDrivingDuration,
        'weeklyDrivingDuration': value.weeklyDrivingDuration,
        'choppedDrivingTimeCapacity': value.choppedDrivingTimeCapacity,
    };
}


