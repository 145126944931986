import { Col, Row } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox } from 'common/components';
import { ParentModule } from '../user-modules';
import qa from 'qa-selectors';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    parentModule?: ParentModule;
    mode: 'READ' | 'EDIT';
    roleId?: string;
    onRightChange?: (hasRight: boolean, rightId?: string) => void;
    onRightChangeAll?: (hasRight: boolean, moduleId?: number) => void;
}

function UserRights(props: Props) {
    const { t, parentModule, mode, roleId } = props;

    const rightsCheckedCount: number = parentModule?.subModules.filter(right => right.hasRight).length ?? 0;

    const _onRightChangeAll = (e: CheckboxChangeEvent) => {
        props.onRightChangeAll?.(e.target.checked, parentModule?.id);
    };

    return (
        <div className="user-rights-modules" data-qa={qa.roles.detail.sectionRights}>
            {parentModule && (
                <>
                    {confDefault.visibleModulesIds.includes(parentModule.id) ? ( //  PartnerModule hidden, and other modules in development
                        <>
                            <h3 className="module-name">
                                {t(`UserRights.modules.${parentModule.name.toLowerCase().replace(/ /g, '')}.name`)}
                            </h3>
                            <img
                                src={`/img/screen-module-${parentModule.id}.png`}
                                alt={parentModule.name.replace(/ /g, '')}
                            />
                            <p className="module-description">
                                {t(
                                    `UserRights.modules.${parentModule.name
                                        .toLowerCase()
                                        .replace(/ /g, '')}.description`
                                )}
                            </p>
                        </>
                    ) : (
                        <>
                            <h3 className="module-name">{t(`UserRights.modules.inDevelopment.name`)}</h3>
                            <img src={`/img/screen-no-module.png`} alt={t(`UserRights.modules.inDevelopment.name`)} />
                            <p className="module-description">{t(`UserRights.modules.inDevelopment.description`)}</p>
                        </>
                    )}
                    {parentModule.subModules.length > 0 && mode === 'EDIT' && parentModule.access && (
                        <Row gutter={[0, 10]}>
                            <Col span={22}>{t(`common.all`)}</Col>
                            <Col span={2}>
                                <Checkbox
                                    qa={`roles--detail--right-all`}
                                    key={`${module.id}-all`}
                                    onChange={_onRightChangeAll}
                                    indeterminate={
                                        parentModule?.subModules.length !== rightsCheckedCount && rightsCheckedCount > 0
                                    }
                                    checked={parentModule?.subModules.length === rightsCheckedCount}
                                    defaultChecked={parentModule?.subModules.length === rightsCheckedCount}
                                />
                            </Col>
                        </Row>
                    )}
                    {parentModule.subModules.map((module, index) => (
                        <div className="module-item" key={`module-${index}`}>
                            <Row gutter={[0, 10]}>
                                <Col span={22}>
                                    {t(`UserRights.rights.${module.name.toLowerCase().replace(/\s/g, '')}`)}
                                </Col>
                                <Col span={2}>
                                    {mode === 'EDIT' && parentModule.access ? (
                                        <Checkbox
                                            qa={`roles--detail--right-${index}`}
                                            key={`${module.id}-${roleId}`}
                                            onChange={(e: CheckboxChangeEvent) => {
                                                props.onRightChange?.(e.target.checked, module.id);
                                            }}
                                            checked={module.hasRight}
                                            defaultChecked={module.hasRight}
                                        />
                                    ) : (
                                        <Checkbox disabled checked={module.hasRight} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default withTranslation()(UserRights);
