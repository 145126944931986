import { Conf } from '../conf';
import { Auth } from './auth';
import { AuthMock } from './auth-mock';
import { Notification } from './notification-eio';
import { Messaging } from './messaging';
import { UserLogic } from './user/users';

import { TrackingLogic } from './tracking';
import { VehiclesLogic } from './vehicle/vehicles';
import { Geocoding } from './geocoding';
import { DispatcherKitVehiclesRemoteMemoryLogic } from './management/management-vehicles-remote-memory';
import { DispatcherKitDriverCardsRemoteMemoryLogic } from './management/management-driver-cards-remote-memory';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import Apollo from 'common/gql-config/apollo';
import { MapLogic } from './map/map';
import { PoiLogic } from './poi';
import { SchedulingRouteOverviewLogic } from './scheduling-route-overview';
import { GoogleAnalyticsLogic } from './google-analytics';
import { StatisticsCompanyProfileLogic } from './statistics/statistics-company-profile';
import { StatisticsAetrReadingLogic } from './statistics/statistics-aetr-reading';
import { StatisticsColdchainLogic } from './statistics/statistics-coldchain';
import { StatisticsJourneysLogic } from './statistics/statistics-journeys';
import { StatisticsFuelConsumptionLogic } from './statistics/statistics-fuel-consumption';
import { StatisticsExpensesLogic } from './statistics/statistics-expenses';
import { StatisticsMaintenanceTaskLogic } from './statistics/statistics-maintenance';
import { SuperadminLogic } from './superadmin';
import { PartnerLogic } from './partner/partner';
import { VehiclesStateLogic } from './vehicles-state';
import { Api } from './api';
import { Settings } from './settings';
import { DispatcherBoardDetailLogic } from './dispatcher-board-detail';
import { DispatcherKitPoiLogic } from './management/management-poi';
import { ManagementCardsLogic } from './management/management-cards';
import { CCRLogic } from './ccr';
import { ExponeaLogic } from './exponea';
import { CustomerApiLogic } from './customer-api';
import { SettingsPersist } from './settings';
import { DriverBehavior } from './driver-behavior/driver-behavior';
import { DriverBehavior as DriverBehaviorCoach } from './driver-behavior-coach/driver-behavior';
import { AlarmsLogic } from './alarms';
import { MaintenanceNotificationsLogic } from './maintenanceNotifications';
import { StatisticsBorderCrossingLogic } from './statistics/statistics-border-crossing';
import { DemoLogic } from './demo';
import { DffProposalsLogic } from './dff-proposals';
import { ExternalSystemLogic } from './management/management-external-systems';
import { Routing } from './routing/routing';
import { DiemsLogic } from './diems/diems';
import { BorderCrossing } from './border-crossing/border-crossing';
import { StazkaLogic } from './stazka';
import { CustomerAccessLogic } from './customer-access';
import { SimpleTrackingLogic } from './simple-tracking';
import { DispatcherBoardLogic } from 'modules/dispatcher-board/dispatcher-board-logic';
import { TotalCostOfOwnerShipLogic } from 'modules/statistics/modules/company-profile/modules/TotlaCostOfOwnership/total-cost-of-ownership-logic';
import { CustomPlacesLogic } from 'modules/management/modules/custom-places/custom-places-logic';
import { MaintenanceLogic } from 'modules/statistics/modules/maintenance/maintenance-logic';
import { BorderCrossesLogic } from './border-crosses';
import { StatisticReportLogic } from 'modules/statistics/modules/statistic-report/statistic-report-logic';
import { TransportLogic } from './transport/transport';
import { PlannerLogic } from 'modules/routing/planner-new/planner-logic';
import { ContactListLogic } from 'modules/management/modules/contact-list/contact-list-logic';
import { ClientContactLogic } from './contact-list/contact-list';
import { VehicleGroupsModuleLogic } from 'modules/management/modules/vehicle-groups/vehicle-groups-module-logic';
import { ColdchainLogic } from 'modules/statistics/modules/coldchain/coldchain-logic';

import { ManagementFuelCardLogic } from './management/management-fuel-card';
import { FuelCardLogic } from 'modules/management/modules/fuel-cards/fuel-card-logic';

export class Logic {
    readonly conf: Conf;

    private _auth?: Auth;
    private _settings?: Settings<SettingsPersist>;
    private _map?: MapLogic;
    private _geocoding?: Geocoding;
    private _alarms?: AlarmsLogic;
    private _maintenanceNotifications?: MaintenanceNotificationsLogic;
    private _notification?: Notification;
    private _messaging?: Messaging;
    private _tracking?: TrackingLogic;
    private _schedulingRouteOverview?: SchedulingRouteOverviewLogic;

    private _apollo?: ApolloClient<NormalizedCacheObject>;
    private _vehicles?: VehiclesLogic;
    private _vehicleGroups?: VehicleGroupsModuleLogic;
    private _users?: UserLogic;
    private _statisticsCompanyProfile?: StatisticsCompanyProfileLogic;
    private _statisticsAetrReading?: StatisticsAetrReadingLogic;
    private _statisticsColdchain?: StatisticsColdchainLogic;
    private _statisticsExpenses?: StatisticsExpensesLogic;
    private _statisticsMaintenance?: StatisticsMaintenanceTaskLogic;
    private _statisticsFuelConsumption?: StatisticsFuelConsumptionLogic;
    private _statisticsJourneys?: StatisticsJourneysLogic;
    private _statisticsBorderCrossing?: StatisticsBorderCrossingLogic;
    private _dispatcherKitDriverCardsRemoteMemory?: DispatcherKitDriverCardsRemoteMemoryLogic;
    private _dispatcherKitVehiclesRemoteMemory?: DispatcherKitVehiclesRemoteMemoryLogic;
    private _dispatcherKitPoi?: DispatcherKitPoiLogic;
    private _poi?: PoiLogic;
    private _cards?: ManagementCardsLogic;

    private _fuelCardLogic?: FuelCardLogic;
    private _managementFuelCard?: ManagementFuelCardLogic;

    private _routing?: Routing;
    private _ga?: GoogleAnalyticsLogic;
    private _superadmin?: SuperadminLogic;
    private _partner?: PartnerLogic;
    private _vehiclesState?: VehiclesStateLogic;
    private _api?: Api;
    private _dispatcherBoardLogic?: DispatcherBoardLogic;
    private _dffProposals?: DffProposalsLogic;
    private _dispatcherBoardDetail?: DispatcherBoardDetailLogic;
    private _ccr?: CCRLogic;
    private _exponea?: ExponeaLogic;
    private _customerAPI?: CustomerApiLogic;
    private _driverBehavior?: DriverBehavior;
    private _driverBehaviorCoach?: DriverBehaviorCoach;
    private _externalSystem?: ExternalSystemLogic;
    private _diemsLogic?: DiemsLogic;
    private _borderCrossingLogic?: BorderCrossing;
    private _stazka?: StazkaLogic;
    private _demoLogic?: DemoLogic;
    private _customerAccess?: CustomerAccessLogic;
    private _simpleTracking?: SimpleTrackingLogic;
    private _tcoLogic?: TotalCostOfOwnerShipLogic;
    private _customPlacesLogic?: CustomPlacesLogic;
    private _maintenanceLogic?: MaintenanceLogic;
    private _borderCrossesLogic?: BorderCrossesLogic;
    private _statisticReportLogic?: StatisticReportLogic;
    private _coldchainLogic?: ColdchainLogic;
    private _notificationDFF?: Notification;
    private _transportLogic?: TransportLogic;
    private _plannerLogic?: PlannerLogic;
    private _clientContactLogic?: ClientContactLogic;
    private _contactListLogic?: ContactListLogic;
    private _vehicleGroupsModuleLogic?: VehicleGroupsModuleLogic;

    constructor(conf: Conf) {
        this.conf = conf;
    }

    borderCrossing() {
        if (!this._borderCrossingLogic) {
            this._borderCrossingLogic = new BorderCrossing(this);
        }
        return this._borderCrossingLogic;
    }

    diems() {
        if (!this._diemsLogic) {
            this._diemsLogic = new DiemsLogic(this);
        }
        return this._diemsLogic;
    }

    auth(): Auth {
        if (this.conf.auth.mockedSSO?.use) {
            if (!this._auth) {
                this._auth = new AuthMock(this.conf.auth) as any as Auth;
            }
            return this._auth;
        }

        if (!this._auth) {
            this._auth = new Auth(this.conf.auth, this);
        }

        return this._auth;
    }

    settings() {
        if (!this._settings) {
            this._settings = new Settings<SettingsPersist>({
                demoMode: this.conf.settings.demoMode,
                lang: this.conf.settings.lang,
                addressIdentification: this.conf.settings.addressIdentification,
                vehicleIdentification: this.conf.settings.vehicleIdentification,
                theme: this.conf.settings.theme,
                tracking: this.conf.settings.tracking,
                map: this.conf.settings.map,
                statistics: {
                    journeysActivity: {
                        expanded: this.conf.settings.statistics.journeysActivity.expanded,
                        filter: {
                            driver: this.conf.settings.statistics.journeysActivity.filter.driver,
                            vehicle: this.conf.settings.statistics.journeysActivity.filter.vehicle,
                            trailer: this.conf.settings.statistics.journeysActivity.filter.trailer
                        },
                        disabledCoachPromo: this.conf.settings.statistics.journeysActivity.disabledCoachPromo
                    },
                    fuelConsumption: {
                        filter: {
                            drivers: this.conf.settings.statistics.fuelConsumption.filter.drivers,
                            vehicles: this.conf.settings.statistics.fuelConsumption.filter.vehicles,
                            groupByTimespan: this.conf.settings.statistics.fuelConsumption.filter.groupByTimespan,
                            groupByEntity: this.conf.settings.statistics.fuelConsumption.filter.groupByEntity,
                            shortActivity: this.conf.settings.statistics.fuelConsumption.filter.shortActivity,
                            fuelSuspiciousActivity:
                                this.conf.settings.statistics.fuelConsumption.filter.fuelSuspiciousActivity
                        }
                    },
                    aetr: this.conf.settings.statistics.aetr,
                    expenses: {
                        filter: {
                            vehicles: this.conf.settings.statistics.expenses.filter.vehicles,
                            type: this.conf.settings.statistics.expenses.filter.type,
                            payment: this.conf.settings.statistics.expenses.filter.payment,
                            source: this.conf.settings.statistics.expenses.filter.source,
                            aggregator: this.conf.settings.statistics.expenses.filter.aggregator
                        }
                    },
                    coldChain: {
                        filter: {
                            vehicles: this.conf.settings.statistics.coldChain.filter.vehicles,
                            trailers: this.conf.settings.statistics.coldChain.filter.trailers,
                            profiles: this.conf.settings.statistics.coldChain.filter.profiles
                        }
                    },
                    maintenance: {
                        filter: {
                            vehicles: this.conf.settings.statistics.maintenance.filter.vehicles,
                            trailers: this.conf.settings.statistics.maintenance.filter.trailers,
                            drivers: this.conf.settings.statistics.maintenance.filter.drivers,
                            taskTypes: this.conf.settings.statistics.maintenance.filter.taskTypes,
                            intervals: this.conf.settings.statistics.maintenance.filter.intervals,
                            status: this.conf.settings.statistics.maintenance.filter.status,
                            dueDateGte: this.conf.settings.statistics.maintenance.filter.dueDateGte,
                            dueDateLte: this.conf.settings.statistics.maintenance.filter.dueDateLte
                        }
                    }
                },
                management: {
                    users: this.conf.settings.management.users,
                    fleet: {
                        filter: {
                            fleetActive: this.conf.settings.management.fleet.filter.fleetActive,
                            fleetType: this.conf.settings.management.fleet.filter.fleetType
                        }
                    }
                },
                scheduling: {
                    routeOverview: {
                        filter: {
                            vehicles: this.conf.settings.scheduling.routeOverview.filter.vehicles,
                            drivers: this.conf.settings.scheduling.routeOverview.filter.drivers,
                            transportState: this.conf.settings.scheduling.routeOverview.filter.transportState
                        }
                    }
                },
                dispatcherBoard: this.conf.settings.dispatcherBoard,
                planner: {
                    transport: this.conf.settings.planner.transport,
                    possibleTransports: this.conf.settings.planner.possibleTransports,
                    tollCostByType: this.conf.settings.planner.tollCostByType,
                    defaultPuescActive: this.conf.settings.planner.defaultPuescActive,
                    hideAetrBreaksAlert: this.conf.settings.planner.hideAetrBreaksAlert
                },
                driverBehavior: {
                    date: this.conf.settings.driverBehavior.date
                }
            });
        }
        return this._settings;
    }

    routing() {
        if (!this._routing) {
            this._routing = new Routing(this);
        }
        return this._routing;
    }

    ga() {
        if (!this._ga) {
            this._ga = new GoogleAnalyticsLogic(this.conf.googleAnalytics);
        }
        return this._ga;
    }

    map() {
        if (!this._map) {
            const vehicleClusteringEnabled = this.settings().getProp('map').vehicleClusteringEnabled;
            this._map = new MapLogic(
                {
                    ...this.conf.map,
                    vehicles: {
                        ...this.conf.map.vehicles,
                        clusteringEnabled: vehicleClusteringEnabled
                    }
                },
                this.auth().roles()
            );
        }
        return this._map;
    }

    geocoding() {
        if (!this._geocoding) {
            this._geocoding = new Geocoding(this.conf.geocoding, this);
        }
        return this._geocoding;
    }

    alarms(): AlarmsLogic {
        if (!this._alarms) {
            this._alarms = new AlarmsLogic(this);
        }
        return this._alarms;
    }

    maintenanceNotifications(): MaintenanceNotificationsLogic {
        if (!this._maintenanceNotifications) {
            this._maintenanceNotifications = new MaintenanceNotificationsLogic(this);
        }
        return this._maintenanceNotifications;
    }

    notification() {
        if (!this._notification) {
            this._notification = new Notification(this.conf.notification);
        }
        return this._notification;
    }

    notificationDFF() {
        if (!this._notificationDFF) {
            this._notificationDFF = new Notification(this.conf.notificationDFF);
        }
        return this._notificationDFF;
    }

    messaging() {
        if (!this._messaging) {
            this._messaging = new Messaging(this.conf.messaging.uri, this.conf, this._notification);
        }
        return this._messaging;
    }

    tracking() {
        if (!this._tracking) {
            this._tracking = new TrackingLogic(this);
        }
        return this._tracking;
    }

    simpleTracking() {
        if (!this._simpleTracking) {
            this._simpleTracking = new SimpleTrackingLogic(this);
        }
        return this._simpleTracking;
    }

    schedulingRouteOverview() {
        if (!this._schedulingRouteOverview) {
            this._schedulingRouteOverview = new SchedulingRouteOverviewLogic(this);
        }
        return this._schedulingRouteOverview;
    }

    users() {
        if (!this._users) {
            this._users = new UserLogic(this.apollo(), this);
        }
        return this._users;
    }

    apollo() {
        if (!this._apollo) {
            const apollo = new Apollo(this.conf.graphQL, this);
            this._apollo = apollo.initialize(this.auth());
        }
        return this._apollo;
    }

    vehicles() {
        if (!this._vehicles) {
            this._vehicles = new VehiclesLogic(this.apollo(), this);
        }
        return this._vehicles;
    }

    vehicleGroups() {
        if (!this._vehicleGroups) {
            this._vehicleGroups = new VehicleGroupsModuleLogic(this);
        }

        return this._vehicleGroups;
    }

    statisticsCompanyProfile() {
        if (!this._statisticsCompanyProfile) {
            this._statisticsCompanyProfile = new StatisticsCompanyProfileLogic(this);
        }
        return this._statisticsCompanyProfile;
    }

    statisticsAetrReading() {
        if (!this._statisticsAetrReading) {
            this._statisticsAetrReading = new StatisticsAetrReadingLogic(this);
        }
        return this._statisticsAetrReading;
    }

    statisticsJourneys() {
        if (!this._statisticsJourneys) {
            this._statisticsJourneys = new StatisticsJourneysLogic(this);
        }
        return this._statisticsJourneys;
    }

    statisticsExpenses() {
        if (!this._statisticsExpenses) {
            this._statisticsExpenses = new StatisticsExpensesLogic(this);
        }
        return this._statisticsExpenses;
    }

    statisticsMaintenance() {
        if (!this._statisticsMaintenance) {
            this._statisticsMaintenance = new StatisticsMaintenanceTaskLogic(this);
        }
        return this._statisticsMaintenance;
    }

    managementFuelCard() {
        if (!this._managementFuelCard) {
            this._managementFuelCard = new ManagementFuelCardLogic(this);
        }
        return this._managementFuelCard;
    }

    fuelCard() {
        if (!this._fuelCardLogic) {
            this._fuelCardLogic = new FuelCardLogic(this);
        }
        return this._fuelCardLogic;
    }

    statisticsFuelConsumption() {
        if (!this._statisticsFuelConsumption) {
            this._statisticsFuelConsumption = new StatisticsFuelConsumptionLogic(this);
        }
        return this._statisticsFuelConsumption;
    }

    statisticsBorderCrossing() {
        if (!this._statisticsBorderCrossing) {
            this._statisticsBorderCrossing = new StatisticsBorderCrossingLogic(this);
        }
        return this._statisticsBorderCrossing;
    }

    statisticsColdchain() {
        if (!this._statisticsColdchain) {
            this._statisticsColdchain = new StatisticsColdchainLogic(this);
        }
        return this._statisticsColdchain;
    }

    poi() {
        if (!this._poi) {
            this._poi = new PoiLogic(this.conf.places, this.auth(), this);
        }
        return this._poi;
    }

    dispatcherKitDriverCardsRemoteMemory() {
        if (!this._dispatcherKitDriverCardsRemoteMemory) {
            this._dispatcherKitDriverCardsRemoteMemory = new DispatcherKitDriverCardsRemoteMemoryLogic(this);
        }
        return this._dispatcherKitDriverCardsRemoteMemory;
    }

    dispatcherKitVehiclesRemoteMemory() {
        if (!this._dispatcherKitVehiclesRemoteMemory) {
            this._dispatcherKitVehiclesRemoteMemory = new DispatcherKitVehiclesRemoteMemoryLogic(this);
        }
        return this._dispatcherKitVehiclesRemoteMemory;
    }

    dispatcherKitPoi() {
        if (!this._dispatcherKitPoi) {
            this._dispatcherKitPoi = new DispatcherKitPoiLogic(this.geocoding(), this);
        }
        return this._dispatcherKitPoi;
    }

    cards() {
        if (!this._cards) {
            this._cards = new ManagementCardsLogic(this);
        }
        return this._cards;
    }

    superadminLogic() {
        if (!this._superadmin) {
            this._superadmin = new SuperadminLogic(this);
        }
        return this._superadmin;
    }

    partner() {
        if (!this._partner) {
            this._partner = new PartnerLogic(this);
        }
        return this._partner;
    }

    vehiclesState() {
        if (!this._vehiclesState) {
            this._vehiclesState = new VehiclesStateLogic(this);
        }
        return this._vehiclesState;
    }

    api() {
        if (!this._api) {
            this._api = new Api(this.conf.api);
        }
        return this._api;
    }

    dffProposals() {
        if (!this._dffProposals) {
            this._dffProposals = new DffProposalsLogic(this);
        }
        return this._dffProposals;
    }

    dispatcherBoardDetail() {
        if (!this._dispatcherBoardDetail) {
            this._dispatcherBoardDetail = new DispatcherBoardDetailLogic(this);
        }
        return this._dispatcherBoardDetail;
    }

    ccr() {
        if (!this._ccr) {
            this._ccr = new CCRLogic(this);
        }
        return this._ccr;
    }

    exponea() {
        if (!this._exponea) {
            this._exponea = new ExponeaLogic();
        }
        return this._exponea;
    }

    customerAPI() {
        if (!this._customerAPI) {
            this._customerAPI = new CustomerApiLogic(this);
        }
        return this._customerAPI;
    }

    driverBehavior() {
        if (!this._driverBehavior) {
            this._driverBehavior = new DriverBehavior(this);
        }
        return this._driverBehavior;
    }

    driverBehaviorCoach() {
        if (!this._driverBehaviorCoach) {
            this._driverBehaviorCoach = new DriverBehaviorCoach(this);
        }
        return this._driverBehaviorCoach;
    }

    externalSystem(): ExternalSystemLogic {
        if (!this._externalSystem) {
            this._externalSystem = new ExternalSystemLogic(this);
        }
        return this._externalSystem;
    }

    stazka(): StazkaLogic {
        if (!this._stazka) {
            this._stazka = new StazkaLogic(this);
        }
        return this._stazka;
    }

    customerAccessLogic(): CustomerAccessLogic {
        if (!this._customerAccess) {
            this._customerAccess = new CustomerAccessLogic(this);
        }
        return this._customerAccess;
    }

    demo(): DemoLogic {
        if (!this._demoLogic) {
            this._demoLogic = new DemoLogic(this);
        }
        return this._demoLogic;
    }

    dispatcherBoardLogic(): DispatcherBoardLogic {
        if (!this._dispatcherBoardLogic) {
            this._dispatcherBoardLogic = new DispatcherBoardLogic(this);
        }
        return this._dispatcherBoardLogic;
    }

    tcoLogic(): TotalCostOfOwnerShipLogic {
        if (!this._tcoLogic) {
            this._tcoLogic = new TotalCostOfOwnerShipLogic(this.conf, this._demoLogic?.isActive);
        }
        return this._tcoLogic;
    }

    customPlacesLogic() {
        if (!this._customPlacesLogic) {
            this._customPlacesLogic = new CustomPlacesLogic(this);
        }
        return this._customPlacesLogic;
    }

    maintenanceLogic() {
        if (!this._maintenanceLogic) {
            this._maintenanceLogic = new MaintenanceLogic(this);
        }
        return this._maintenanceLogic;
    }

    borderCrosses() {
        if (!this._borderCrossesLogic) {
            this._borderCrossesLogic = new BorderCrossesLogic(this);
        }
        return this._borderCrossesLogic;
    }

    statisticReportLogic() {
        if (!this._statisticReportLogic) {
            this._statisticReportLogic = new StatisticReportLogic(this);
        }
        return this._statisticReportLogic;
    }

    coldchainLogic() {
        if (!this._coldchainLogic) {
            this._coldchainLogic = new ColdchainLogic(this);
        }
        return this._coldchainLogic;
    }

    transportLogic() {
        if (!this._transportLogic) {
            this._transportLogic = new TransportLogic(this);
        }

        return this._transportLogic;
    }

    plannerLogic() {
        if (!this._plannerLogic) {
            this._plannerLogic = new PlannerLogic(this);
        }

        return this._plannerLogic;
    }

    contactListLogic() {
        if (!this._contactListLogic) {
            this._contactListLogic = new ContactListLogic(this);
        }

        return this._contactListLogic;
    }

    clientContactLogic() {
        if (!this._clientContactLogic) {
            this._clientContactLogic = new ClientContactLogic(this);
        }

        return this._clientContactLogic;
    }

    vehicleGroupsModuleLogic() {
        if (!this._vehicleGroupsModuleLogic) {
            this._vehicleGroupsModuleLogic = new VehicleGroupsModuleLogic(this);
        }

        return this._vehicleGroupsModuleLogic;
    }
}
