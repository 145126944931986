import { ImpersonateUserQuery, ImpersonateUserQueryVariables, ImpersonateUserDocument } from 'generated/graphql';
import { Logic } from './logic';
import { SuperAdminCompanyModel, SuperAdminUserModel } from 'modules/superadmin/SuperAdminModule';
import { ClientSSOIdentities, ReadOnlyClient, ReadOnlyUser } from 'generated/new-main';
import { message } from 'antd';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';

export interface SuperAdminImpersionationToken {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    refresh_expires_in: number;
    token_type: 'bearer' | string;
    user_id: string;
}

const toImpersonationToken: (
    authData: NonNullable<ImpersonateUserQuery['impersonate_User']>
) => SuperAdminImpersionationToken = authData => ({
    access_token: authData.access_token || '',
    refresh_token: authData.refresh_token || '',
    expires_in: authData.expires_in || 0,
    refresh_expires_in: authData.refresh_expires_in || 0,
    token_type: authData.token_type || '',
    user_id: authData.user_id || ''
});

export const toCompanyModelNew: (company: ReadOnlyClient) => SuperAdminCompanyModel = company => ({
    id: String(company.id),
    companyName: company.name || '',
    address:
        `${company.addressNew?.[0]?.city ?? ''} ${company.addressNew?.[0]?.street ?? ''} ${
            company.addressNew?.[0]?.houseNumber ?? ''
        }` || '',
    country: company.addressNew?.[0]?.country,
    users: []
});

export const toSuperadminUserModelNew: (user: ReadOnlyUser) => SuperAdminUserModel = user => {
    const loginCredentials: { username: string } = user.loginCredentials as { username: string };
    return {
        id: String(user.id) || '',
        username: loginCredentials?.username ?? '',
        name: user.name || '',
        surname: user.surname || '',
        ssoId: user.ssoId || ''
    };
};
export const toSuperadminSSOUserModelNew: (user: ClientSSOIdentities) => SuperAdminUserModel = user => {
    return {
        id: String(user.identityId) || '',
        username: user.username ?? '',
        name: user.firstName || '',
        surname: user.lastName || '',
        ssoId: user.identityId || ''
    };
};

export class SuperadminLogic {
    constructor(private _logic: Logic) {}

    destroy() {}

    async getCompaniesNew(searchText: string): Promise<SuperAdminCompanyModel[]> {
        try {
            const response = await this._logic.api().newClientApi.clientList({
                nameIcontains: searchText
            });
            return response.results.map(toCompanyModelNew);
        } catch (err) {
            console.log('Client list GET err:', err);
            throw err;
        }
    }

    async getCompanyUser(
        companyId: string,
        searchString: string = '',
        userGroups: string[] = [],
        limit: number = INFINITY_PAGE_LIMIT,
        offset: number = DEFAULT_PAGE_OFFSET
    ): Promise<SuperAdminUserModel[]> {
        try {
            const groups = userGroups.length > 0 ? userGroups.toString() : undefined;
            const response = await this._logic.api().newUserApi.userList({
                client: companyId,
                nameOrSurnameOrUsername: searchString,
                userGroupName: groups,
                limit,
                offset
            });
            return response.results ? response.results.map(toSuperadminUserModelNew) : [];
        } catch (err) {
            console.log('Client user list GET err:', err);
            throw err;
        }
    }

    async getCompanySSOUser(companyId: string): Promise<SuperAdminUserModel[]> {
        try {
            const response = await this._logic.api().newClientApi.clientSsoIdentities({
                id: Number(companyId)
            });
            return response ? response.map(toSuperadminSSOUserModelNew) : [];
        } catch (err) {
            console.log('Client user list from SSOnp GET err:', err);
            throw err;
        }
    }

    async impersonate(user: SuperAdminUserModel): Promise<void> {
        try {
            const companiesList = await this._logic
                .apollo()
                .query<ImpersonateUserQuery, ImpersonateUserQueryVariables>({
                    query: ImpersonateUserDocument,
                    fetchPolicy: 'no-cache',
                    variables: { ssoId: user.ssoId! }
                });

            const token = toImpersonationToken(companiesList.data.impersonate_User || {});
            console.debug(token);
            if (token.access_token.length > 0 && token.refresh_token.length > 0) {
                this._logic.auth().impersonate(token);
            } else {
                message.error('Unable to load impersonate token');
            }
        } catch (err) {
            console.log('Impersonate err:', err);
            throw err;
        }
    }
}
