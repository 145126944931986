import { AddressIdentification } from 'common/components/Settings';
import { Output } from 'generated/backend-api';
import { toAddress } from 'common/utils/address';
import { DispatcherModel, DriverModel } from 'logic/user/users';
import { DiemsDetailTableData } from 'modules/statistics/modules/allowance/components/AllowanceDetailTable/AllowanceDetailTable';
import { DiemsSummary } from 'modules/statistics/modules/allowance/components/AllowancesSummary/AllowanceSummary';
import { DiemsTableData } from 'modules/statistics/modules/allowance/components/AllowanceTable/AllowanceTable';
import moment from 'moment';
import { Subject } from 'rxjs';
import { Logic } from '../logic';
import { DiemsRates } from './logic/diems-rates';
import { DiemsRules } from './logic/diems-rules';
import numeral from 'numeral';
import { formatDuration } from 'common/utils/dateTimeUtils';
import i18n from 'i18next';

export class DiemsLogic {
    private _diems?: {
        start: Date;
        end: Date;
        data: Output;
    };
    drivers?: DriverModel[];
    dispatchers?: DispatcherModel[];

    diemsLoading = new Subject<boolean>();
    diemsData = new Subject<Output>();
    diemsError = new Subject<any>();

    private _rates?: DiemsRates;
    private _rules?: DiemsRules;

    constructor(private _logic: Logic) {}

    rates() {
        if (!this._rates) {
            this._rates = new DiemsRates(this._logic);
        }

        return this._rates;
    }

    rules() {
        if (!this._rules) {
            this._rules = new DiemsRules(this._logic);
        }

        return this._rules;
    }

    async getDiems(start: Date, end: Date, userId?: number, hardReload?: boolean) {
        this.diemsLoading.next(true);
        try {
            if (
                !hardReload &&
                this._diems?.start.getTime() === start.getTime() &&
                this._diems.end.getTime() === end.getTime() &&
                userId &&
                this._diems?.data.diems?.[userId?.toString()]
            ) {
                this.diemsData.next(this._diems.data);
            } else {
                await this.fetchDrivers();
                await this.fetchDispatchers();
                if (this._logic.demo().isActive) {
                    const demoDiems = this._logic.demo().data.diems;
                    this._diems = {
                        data: demoDiems,
                        start,
                        end
                    };
                } else {
                    this._diems = {
                        data: await this._logic.api().diemsApi.diemsDebugV1DiemsDiemsGet({
                            start,
                            end,
                            driverId: userId
                        }),
                        start,
                        end
                    };
                }

                this.diemsData.next(this._diems.data);
            }
        } catch (err) {
            console.error(`Can not get diems, err: ${err}`);
            this.diemsError.next(err);
        } finally {
            this.diemsLoading.next(false);
        }
    }

    async fetchDrivers() {
        this.drivers = await this._logic.users().drivers();
    }

    async fetchDispatchers() {
        this.dispatchers = await this._logic.users().dispatchers();
    }

    getDiemsUser(userId: number) {
        return this.drivers?.find(u => u.id === userId.toString());
    }

    getDiemsGlobalSummary(): DiemsSummary {
        const diems = this._diems?.data;
        let foreignTravel = 0;
        let domesticTravel = 0;
        if (diems?.diems) {
            foreignTravel = Object.keys(diems.diems).reduce((prev, currUserId) => {
                const countryStays = diems.diems?.[currUserId].countryStays;
                if (!countryStays) {
                    return prev;
                }
                return (
                    Object.keys(countryStays).reduce((prev, curr) => {
                        const foreign = countryStays[curr].borderCrossings?.reduce((prev, cur) => {
                            return prev + (cur.stayForeign ?? 0);
                        }, 0);
                        return prev + (foreign ? foreign : 0);
                    }, 0) + prev
                );
            }, 0);

            domesticTravel = Object.keys(diems.diems).reduce((prev, currUserId) => {
                const countryStays = diems.diems?.[currUserId].countryStays;
                if (!countryStays) {
                    return prev;
                }
                return (
                    Object.keys(countryStays).reduce((prev, curr) => {
                        const domestic = countryStays[curr].borderCrossings?.reduce((prev, cur) => {
                            return prev + (cur.stayDomestic ?? 0);
                        }, 0);
                        return prev + (domestic ? domestic : 0);
                    }, 0) + prev
                );
            }, 0);
        }

        const countries = diems?.globalFinancialReport?.visitedCountries?.sort().join(', ');

        const pocketMoneyObj = diems?.globalFinancialReport?.appliedDiemsRule?.pocketMoney;
        const pocketMoney: DiemsSummary['pocketMoney'] = pocketMoneyObj
            ? Object.keys(pocketMoneyObj).map(key => {
                  return {
                      currency: key,
                      value: Math.round(pocketMoneyObj[key] * 100) / 100
                  };
              })
            : [];

        const dailyRateObj = diems?.globalFinancialReport?.dailyTotalRate;
        const dailyRate: DiemsSummary['dailyRates'] = dailyRateObj
            ? Object.keys(dailyRateObj)
                  .filter(k => {
                      return k !== 'sum_in_country_origin_currency';
                  })
                  .map(key => {
                      return {
                          currency: key,
                          value: Math.round(dailyRateObj[key] * 100) / 100
                      };
                  })
            : [];

        const dailyTotalRateObj = diems?.globalFinancialReport?.dailyTotalRate;
        const countryOriginCurrency = diems?.globalFinancialReport?.countryOriginCurrency;
        const dailyTotalRate: DiemsSummary['allowanceSummary'] = dailyTotalRateObj
            ? {
                  currency: countryOriginCurrency ?? '',
                  value: Math.round(dailyTotalRateObj['sum_in_country_origin_currency'] * 100) / 100
              }
            : undefined;

        return {
            abroadStay: foreignTravel,
            localStay: domesticTravel,
            countries: countries ?? '',
            pocketMoney: pocketMoney,
            dailyRates: dailyRate,
            allowanceSummary: dailyTotalRate
        } as DiemsSummary;
    }

    getDiemsGlobalTableData(): DiemsTableData[] {
        const diems = this._diems?.data?.diems;
        if (!diems) {
            return [];
        }

        return Object.keys(diems).map(userId => {
            const userDiems = diems[userId];
            const countryStays = diems[userId].countryStays;
            const foreignTravel = countryStays
                ? Object.keys(countryStays).reduce((prev, currDate) => {
                      return (
                          prev +
                          (countryStays[currDate].borderCrossings?.reduce((prev, cur) => {
                              return prev + (cur.stayForeign ?? 0);
                          }, 0) ?? 0)
                      );
                  }, 0)
                : 0;

            const domesticTravel = countryStays
                ? Object.keys(countryStays).reduce((prev, currDate) => {
                      return (
                          prev +
                          (countryStays[currDate].borderCrossings?.reduce((prev, cur) => {
                              return prev + (cur.stayDomestic ?? 0);
                          }, 0) ?? 0)
                      );
                  }, 0)
                : 0;

            const countries = (userDiems.userFinancialReport?.visitedCountries ?? []).sort();

            const shorteningObj = userDiems.userFinancialReport?.appliedDiemsRule?.shortenings;
            const shortening = shorteningObj
                ? Object.keys(shorteningObj)
                      .map(keyCurrency => {
                          const value = Object.keys(shorteningObj[keyCurrency]).reduce((prev, shortingKey) => {
                              return prev + shorteningObj[keyCurrency][shortingKey];
                          }, 0);
                          return `${numeral(value).format('0,0.00')} ${keyCurrency}`; // spocitam pre vsetky meny dokopy, zobrazovat kazdu menu
                      })
                      .join(', ')
                : '';

            const pocketMoneyObj = userDiems.userFinancialReport?.appliedDiemsRule?.pocketMoney;
            const pocketMoney = pocketMoneyObj
                ? Object.keys(pocketMoneyObj)
                      .map(key => {
                          return `${numeral(pocketMoneyObj[key]).format('0,0.00')} ${key}`;
                      })
                      .join(', ')
                : '';

            const dailyRateObj = userDiems.userFinancialReport?.dailyRate;
            const dailyRate = dailyRateObj
                ? Object.keys(dailyRateObj)
                      .map(key => {
                          return `${numeral(dailyRateObj[key]).format('0,0.00')} ${key}`;
                      })
                      .join(', ')
                : '';

            const dailyTotalRateObj = userDiems.userFinancialReport?.dailyTotalRate;
            const countryOriginCurrency = userDiems.userFinancialReport?.countryOriginCurrency;
            const dailyTotalRate = dailyTotalRateObj
                ? `${numeral(dailyTotalRateObj['sum_in_country_origin_currency']).format(
                      '0,0.00'
                  )} ${countryOriginCurrency}`
                : '';

            return {
                stayAbroadTime: foreignTravel,
                stayLocalTime: domesticTravel,
                id: userId,
                countries: countries.join(', '),
                name: this.drivers?.find(u => u.id === userId)?.name ?? '',
                surname: this.drivers?.find(u => u.id === userId)?.surname ?? '',
                shortening: shortening,
                rate: dailyRate,
                pocketMoney: pocketMoney,
                vouchers: dailyTotalRate
            } as DiemsTableData;
        });
    }

    getDiemsUserTableData(userId: number): DiemsDetailTableData[] {
        const userDiems = this._diems?.data.diems?.[userId.toString()];
        const countryStays = userDiems?.countryStays;
        if (!countryStays) {
            return [];
        }
        // keys are single date. eg (12.03.1993)
        return Object.keys(countryStays).map(diemsDateKey => {
            // user was in xy countries. we took date and place from first and last one in main row of table
            // in child rows are specific countries
            const allCrossings = countryStays[diemsDateKey].borderCrossings;
            let originalDateFrom = '';
            let originalDateTo = '';
            let mainDateFrom = '';
            let mainDateTo = '';
            let mainRouteFrom = '';
            let mainRouteTo = '';
            if (allCrossings && allCrossings.length > 0) {
                const dateFrom = allCrossings[0].entry?.date;
                originalDateFrom = dateFrom ?? '';
                mainDateFrom = dateFrom ? moment(moment.utc(dateFrom).toDate()).toISOString() : '';
                const dateTo = allCrossings[allCrossings.length - 1].exit?.date;
                originalDateTo = dateTo ?? '';
                mainDateTo = dateTo ? moment(moment.utc(dateTo).toDate()).toISOString() : '';

                const routeFromAddressStructured = allCrossings[0].entry?.address ?? [];
                mainRouteFrom =
                    routeFromAddressStructured.length > 0
                        ? toAddress(
                              this._logic.auth().user().lang,
                              this._logic.auth().client()!,
                              routeFromAddressStructured,
                              AddressIdentification.Address,
                              `${routeFromAddressStructured[0].address}`
                          )
                        : '';
                const routeToAddressStructured = allCrossings[allCrossings.length - 1].exit?.address ?? [];
                mainRouteTo =
                    routeToAddressStructured.length > 0
                        ? toAddress(
                              this._logic.auth().user().lang,
                              this._logic.auth().client()!,
                              routeToAddressStructured,
                              AddressIdentification.Address,
                              `${routeToAddressStructured[0].address}`
                          )
                        : '';
            }
            // country in main row are all countries visited by this date
            const countries = countryStays[diemsDateKey].financialReport?.visitedCountries?.sort();

            // stay domestic / abroad. sum of all stays from each border crossings in current date
            const foreignTravel = countryStays[diemsDateKey].borderCrossings?.reduce((prev, cur) => {
                return prev + (cur.stayForeign ?? 0);
            }, 0);
            const domesticTravel = countryStays[diemsDateKey].borderCrossings?.reduce((prev, cur) => {
                return prev + (cur.stayDomestic ?? 0);
            }, 0);

            const powStay = countryStays[diemsDateKey].borderCrossings?.reduce((prev, cur) => {
                return prev + (cur.powStay ?? 0);
            }, 0);

            // show all shortenings in all currencies. key in shortenings is currency
            const shorteningObj = countryStays[diemsDateKey].financialReport?.appliedDiemsRule?.shortenings;
            const shortening = shorteningObj
                ? Object.keys(shorteningObj)
                      .map(keyCurrency => {
                          const value = Object.keys(shorteningObj[keyCurrency]).reduce((prev, shortingKey) => {
                              return prev + shorteningObj[keyCurrency][shortingKey];
                          }, 0);
                          return `${numeral(value).format('0,0.00')} ${keyCurrency}`; // spocitam pre vsetky meny dokopy, zobrazovat kazdu menu
                      })
                      .join(', ')
                : '';

            // show all shortenings/pocketMoney in all currencies. key is currency
            const pocketMoneyObj = countryStays[diemsDateKey].financialReport?.appliedDiemsRule?.pocketMoney;
            const pocketMoney = pocketMoneyObj
                ? Object.keys(pocketMoneyObj)
                      .map(key => {
                          return `${numeral(pocketMoneyObj[key]).format('0,0.00')} ${key}`;
                      })
                      .join(', ')
                : '';

            const dailyRateObj = countryStays[diemsDateKey].financialReport?.dailyRate;
            const dailyRate = dailyRateObj
                ? Object.keys(dailyRateObj)
                      .map(key => {
                          return `${numeral(dailyRateObj[key]).format('0,0.00')} ${key}`;
                      })
                      .join(', ')
                : '';

            const dailyTotalRateObj = countryStays[diemsDateKey].financialReport?.dailyTotalRate;
            const countryOriginCurrency = countryStays[diemsDateKey].financialReport?.countryOriginCurrency;
            const dailyTotalRate = dailyTotalRateObj
                ? `${numeral(dailyTotalRateObj['sum_in_country_origin_currency']).format(
                      '0,0.00'
                  )} ${countryOriginCurrency}`
                : '';
            const dispatcher = this.dispatchers?.find(
                d =>
                    d.id ===
                    countryStays[diemsDateKey].financialReport?.appliedDiemsRule?.manualRate?.byUser?.toString()
            );

            return {
                id: diemsDateKey,
                originalDateFrom: originalDateFrom,
                originalDateTo: originalDateTo,
                dateFrom: mainDateFrom,
                dateTo: mainDateTo,
                routeFrom: mainRouteFrom,
                routeTo: mainRouteTo,
                country: countries ? countries.join(', ') : '',
                abroadTravel: foreignTravel
                    ? formatDuration(foreignTravel * 1000)
                    : `0${i18n.t('common.hours')[0].toLowerCase()} 0${i18n.t('common.minutes')[0].toLowerCase()}`,
                domesticTravel: domesticTravel
                    ? formatDuration(domesticTravel * 1000)
                    : `0${i18n.t('common.hours')[0].toLowerCase()} 0${i18n.t('common.minutes')[0].toLowerCase()}`,
                powStay: powStay
                    ? formatDuration(powStay * 1000)
                    : `0${i18n.t('common.hours')[0].toLowerCase()} 0${i18n.t('common.minutes')[0].toLowerCase()}`,
                shortening: shortening,
                pocketMoney: pocketMoney,
                dailyRate: dailyRate,
                totalRate: dailyTotalRate,
                manualRate: countryStays[diemsDateKey].financialReport?.appliedDiemsRule?.manualRate
                    ? {
                          ...countryStays[diemsDateKey].financialReport?.appliedDiemsRule?.manualRate,
                          byUserName: dispatcher?.name ?? '',
                          byUserSurname: dispatcher?.surname ?? ''
                      }
                    : undefined,
                appliedDiemsCountries: countryStays[diemsDateKey].appliedDiemsCountries,
                children:
                    allCrossings && allCrossings.length > 1
                        ? allCrossings.map(c => {
                              return {
                                  dateFrom: c.entry?.date
                                      ? moment(moment.utc(c.entry.date).toDate()).toISOString()
                                      : '',
                                  dateTo: c.exit?.date ? moment(moment.utc(c.exit.date).toDate()).toISOString() : '',
                                  routeFrom:
                                      c.entry?.address && c.entry.address.length > 0
                                          ? toAddress(
                                                this._logic.auth().user().lang,
                                                this._logic.auth().client()!,
                                                c.entry.address,
                                                AddressIdentification.Address,
                                                `${c.entry.address[0].address}`
                                            )
                                          : '',
                                  routeTo:
                                      c.exit?.address && c.exit.address.length > 0
                                          ? toAddress(
                                                this._logic.auth().user().lang,
                                                this._logic.auth().client()!,
                                                c.exit.address,
                                                AddressIdentification.Address,
                                                `${c.exit.address[0].address}`
                                            )
                                          : '',
                                  country: c.entry?.country,
                                  domesticTravel: c.stayDomestic ? formatDuration(c.stayDomestic * 1000) : '',
                                  abroadTravel: c.stayForeign ? formatDuration(c.stayForeign * 1000) : '',
                                  powStay: c.powStay ? formatDuration(c.powStay * 1000) : '',
                                  isMain: countryStays[diemsDateKey].appliedDiemsCountries?.includes(
                                      c.entry?.country ?? ''
                                  ),
                                  isChild: true
                              };
                          })
                        : undefined
            } as DiemsDetailTableData;
            // return {};
        }) as DiemsDetailTableData[];
    }

    getDiemsUserSummary(userId: number): DiemsSummary {
        const userDiems = this._diems?.data.diems?.[userId.toString()];
        const countryStays = userDiems?.countryStays;
        let foreignTravel = 0;
        let domesticTravel = 0;
        if (countryStays) {
            foreignTravel = Object.keys(countryStays).reduce((prev, curr) => {
                const foreign = countryStays[curr].borderCrossings?.reduce((prev, cur) => {
                    return prev + (cur.stayForeign ?? 0);
                }, 0);
                return prev + (foreign ? foreign : 0);
            }, 0);

            domesticTravel = Object.keys(countryStays).reduce((prev, curr) => {
                const domestic = countryStays[curr].borderCrossings?.reduce((prev, cur) => {
                    return prev + (cur.stayDomestic ?? 0);
                }, 0);
                return prev + (domestic ? domestic : 0);
            }, 0);
        }

        const countries = userDiems?.userFinancialReport?.visitedCountries?.sort().join(', ');

        const pocketMoneyObj = userDiems?.userFinancialReport?.appliedDiemsRule?.pocketMoney;
        const pocketMoney: DiemsSummary['pocketMoney'] = pocketMoneyObj
            ? Object.keys(pocketMoneyObj).map(key => {
                  return {
                      currency: key,
                      value: Math.round(pocketMoneyObj[key] * 100) / 100
                  };
              })
            : [];

        const dailyRateObj = userDiems?.userFinancialReport?.dailyTotalRate;
        const dailyRate: DiemsSummary['dailyRates'] = dailyRateObj
            ? Object.keys(dailyRateObj)
                  .filter(k => {
                      return k !== 'sum_in_country_origin_currency';
                  })
                  .map(key => {
                      return {
                          currency: key,
                          value: Math.round(dailyRateObj[key] * 100) / 100
                      };
                  })
            : [];

        const dailyTotalRateObj = userDiems?.userFinancialReport?.dailyTotalRate;
        const countryOriginCurrency = userDiems?.userFinancialReport?.countryOriginCurrency;
        const dailyTotalRate: DiemsSummary['allowanceSummary'] = dailyTotalRateObj
            ? {
                  currency: countryOriginCurrency ?? '',
                  value: Math.round(dailyTotalRateObj['sum_in_country_origin_currency'] * 100) / 100
              }
            : undefined;

        return {
            abroadStay: foreignTravel,
            localStay: domesticTravel,
            countries: countries ?? '',
            pocketMoney: pocketMoney,
            dailyRates: dailyRate,
            allowanceSummary: dailyTotalRate
        } as DiemsSummary;
    }
}
