/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityType,
    ActivityTypeFromJSON,
    ActivityTypeFromJSONTyped,
    ActivityTypeToJSON,
    TeamType,
    TeamTypeFromJSON,
    TeamTypeFromJSONTyped,
    TeamTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface WTMInterval
 */
export interface WTMInterval {
    /**
     * epoch timestamp in seconds
     * @type {number}
     * @memberof WTMInterval
     */
    activityStart: number;
    /**
     * epoch timestamp in seconds
     * @type {number}
     * @memberof WTMInterval
     */
    activityEnd: number;
    /**
     * 
     * @type {TeamType}
     * @memberof WTMInterval
     */
    teamType: TeamType;
    /**
     * 
     * @type {ActivityType}
     * @memberof WTMInterval
     */
    activityType: ActivityType;
}

export function WTMIntervalFromJSON(json: any): WTMInterval {
    return WTMIntervalFromJSONTyped(json, false);
}

export function WTMIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): WTMInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityStart': json['activityStart'],
        'activityEnd': json['activityEnd'],
        'teamType': TeamTypeFromJSON(json['teamType']),
        'activityType': ActivityTypeFromJSON(json['activityType']),
    };
}

export function WTMIntervalToJSON(value?: WTMInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityStart': value.activityStart,
        'activityEnd': value.activityEnd,
        'teamType': TeamTypeToJSON(value.teamType),
        'activityType': ActivityTypeToJSON(value.activityType),
    };
}


