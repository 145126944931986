/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpenseItemType,
    ExpenseItemTypeFromJSON,
    ExpenseItemTypeFromJSONTyped,
    ExpenseItemTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExpenseItem
 */
export interface ExpenseItem {
    /**
     * 
     * @type {number}
     * @memberof ExpenseItem
     */
    value: number;
    /**
     *      An enumeration. ISO4217 Code
     * @type {string}
     * @memberof ExpenseItem
     */
    currencyCode: string;
    /**
     * 
     * @type {ExpenseItemType}
     * @memberof ExpenseItem
     */
    item: ExpenseItemType;
}

export function ExpenseItemFromJSON(json: any): ExpenseItem {
    return ExpenseItemFromJSONTyped(json, false);
}

export function ExpenseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'currencyCode': json['currencyCode'],
        'item': ExpenseItemTypeFromJSON(json['item']),
    };
}

export function ExpenseItemToJSON(value?: ExpenseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'currencyCode': value.currencyCode,
        'item': ExpenseItemTypeToJSON(value.item),
    };
}


