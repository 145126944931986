import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { KM } from 'domain-constants';
import numeral from 'numeral';

import { AETRDataDetail } from 'common/components';
import { Transport } from 'generated/backend-api';

interface Props {
    /**
     * Aetr data
     */
    aetr: {
        activityStartTime?: string;
        drivingDuration?: number;
        workingDuration?: number;
        restingDuration?: number;
        dailyDriveMaxHours?: number;
        dailyUtilizationLeft?: number;
        dailyUtilizationMaxHours?: number;
    };
    /**
     * Driver name
     */
    driver?: string;
    /**
     * Name of transport
     */
    name?: string;
    /**
     * Total distance of transport in meters
     */
    distance?: number;
    /**
     * Specified transport client
     */
    client?: string;
    /**
     * Is truck
     */
    truck?: boolean;
    costs: {
        planned: Transport['costPerKm'];
    };
}

export default function TransportInfo(props: Props) {
    const { t } = useTranslation();
    const dailyUtilizationMaxHours = props.aetr.dailyUtilizationMaxHours ?? 0;

    const dailyDriveMaxHours = props.aetr.dailyDriveMaxHours ?? 0;
    const dailyDriveMaxSeconds = dailyDriveMaxHours * 3600;
    const dailyUtilizationLeft: number = props.aetr.dailyUtilizationLeft ?? 0;

    const driving: number = Math.max(props.aetr.drivingDuration ?? 0, 0);
    const drivingLeft: number = dailyDriveMaxSeconds >= driving ? dailyDriveMaxSeconds - driving : 0;

    return (
        <div className="transport-info">
            <Row gutter={20}>
                <Col xs={24} md={11}>
                    <Typography.Title level={3}>
                        <i className="fa fa-user-o" />
                        {props.driver && props.driver.length > 0 ? props.driver : t('common.notSpecified')}
                    </Typography.Title>
                    {props.truck && props.aetr.activityStartTime && (
                        <AETRDataDetail
                            drivingDuration={props.aetr.drivingDuration}
                            restingDuration={props.aetr.restingDuration}
                            workingDuration={props.aetr.workingDuration}
                            drivingLeft={drivingLeft}
                            dailyUtilizationLeft={dailyUtilizationLeft}
                            dailyUtilizationMaxHours={dailyUtilizationMaxHours}
                        />
                    )}
                </Col>
                <Col xs={24} md={8} className="transport-info-route-info">
                    <Typography.Title level={3}>
                        <i className="fa fa-info" />
                        {t('TransportInfo.routeInfo')}
                    </Typography.Title>
                    <Row>
                        <Col xs={12}>{t('TransportInfo.transportName')}</Col>
                        <Col>
                            <strong>{props.name ?? t('common.notSpecified')}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>{t('TransportInfo.distance')}</Col>
                        <Col>
                            <strong>
                                {props.distance
                                    ? `${numeral(props.distance).format('0.0')} ${KM}`
                                    : t('common.notSpecified')}
                            </strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>{t('TransportInfo.client')}</Col>
                        <Col>
                            <strong>{props.client ?? t('common.notSpecified')}</strong>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={5} className="transport-info-costs">
                    <div className="transport-info-cost">
                        <span>{t('TransportInfo.plannedCosts')}</span>
                        <strong>{`${numeral(props.costs.planned?.value).format('0.0')} ${
                            props.costs.planned?.currency
                        }`}</strong>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
