import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

import { NotificationReceivers, TaskTypeCategoryEnum } from 'generated/new-main';
import { LayoutDetail } from 'common/components/Layout/Detail';
import { Checkbox } from 'common/components';
import Descriptions from 'common/components/Descriptions';
import {
    MaintenanceRepetitionIntervalEnum,
    MaintenanceTaskTableModel
} from 'modules/statistics/modules/maintenance/MaintenanceModule';
import { DATE_FORMAT, KM } from 'domain-constants';
import qa from 'qa-selectors';
import { Col, Row } from 'antd';
import { checkCircleGreen, closeCircleDark, computer, mail } from 'resources/images/common';

interface Props {
    data: MaintenanceTaskTableModel;
    notificationReceivers?: NotificationReceivers[];
}

export default function MaintenanceDetailCard(props: Props) {
    const { t } = useTranslation();

    const notificationReceivers = props.notificationReceivers?.reduce((acc, receiver) => {
        const index = acc.findIndex(r => r.entityId === String(receiver.entityId));
        if (index === -1) {
            acc.push({
                entityId: String(receiver.entityId),
                name: receiver.name ?? undefined,
                email: receiver.email ?? undefined,
                entity: receiver.entity,
                channelEmail: receiver.channel === 'email',
                channelApp: receiver.channel === 'app'
            });
        } else {
            if (receiver.channel === 'email') {
                acc[index].channelEmail = true;
            } else {
                acc[index].channelApp = true;
            }
        }
        return acc;
    }, [] as { entityId?: string; name?: string; email?: string; entity?: string; channelEmail?: boolean; channelApp?: boolean }[]);

    return (
        <div className="maintenance-detail-card">
            <LayoutDetail
                firstLeft={
                    <>
                        <Descriptions layout="vertical" column={1}>
                            <Descriptions.Item label={t('Maintenance.task.taskFor')}>
                                <span data-qa={qa.maintenance.detail?.[`fieldState_${props.data.category}`]}>
                                    {t(`common.${props.data.category}`)}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                        {props.data.createdBy?.name && props.data.createdBy?.surname && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.createdBy')}>
                                    <span data-qa={qa.maintenance.detail.fieldCreatedBy}>
                                        {props.data.createdBy?.name} {props.data.createdBy?.surname}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                        {props.data.completionTime && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('Maintenance.task.completedOnDate')}>
                                    <span data-qa={qa.maintenance.detail.fieldCompletedOnDate}>
                                        {moment(props.data.completionTime).format(DATE_FORMAT)}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </>
                }
                firstRight={
                    <>
                        <Descriptions layout="vertical" column={1}>
                            {props.data.category === TaskTypeCategoryEnum.Driver ? (
                                <Descriptions.Item label={t('Maintenance.task.driverName')}>
                                    <span data-qa={qa.maintenance.detail.fieldDriver}>{props.data.driver}</span>
                                </Descriptions.Item>
                            ) : props.data.category === TaskTypeCategoryEnum.Vehicle ? (
                                <Descriptions.Item label={t('common.registrationNumber')}>
                                    <span data-qa={qa.maintenance.detail.fieldVehicle}>{props.data.vehicle}</span>
                                </Descriptions.Item>
                            ) : props.data.category === TaskTypeCategoryEnum.Trailer ? (
                                <Descriptions.Item label={t('common.registrationNumber')}>
                                    <span data-qa={qa.maintenance.detail.fieldTrailer}>{props.data.trailer}</span>
                                </Descriptions.Item>
                            ) : props.data.category === TaskTypeCategoryEnum.Company ? (
                                <Descriptions.Item label={t('common.company')}>
                                    <span data-qa={qa.maintenance.detail.fieldCompany}>{props.data.company}</span>
                                </Descriptions.Item>
                            ) : (
                                <></>
                            )}
                        </Descriptions>
                        {props.data.completedBy?.name && props.data.completedBy?.surname && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('common.completedBy')}>
                                    <span data-qa={qa.maintenance.detail.fieldCompletedBy}>
                                        {props.data.completedBy?.name} {props.data.completedBy?.surname}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                        {props.data.completionMileageKilometers !== undefined && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('Maintenance.task.completedOnKm')}>
                                    <span data-qa={qa.maintenance.detail.fieldCompletionMileage}>
                                        {numeral(props.data.completionMileageKilometers).format('0,0')} {KM}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </>
                }
                secondTitle={t('Maintenance.task.dueInterval')}
                secondLeft={
                    <Descriptions layout="vertical" column={1}>
                        {props.data.dueDate ? (
                            <Descriptions.Item label={t('Maintenance.task.dueOnDate')}>
                                <span data-qa={qa.maintenance.detail.fieldDueDate}>
                                    {moment(props.data.dueDate).format('L')}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                        {props.data.repetitionInterval === MaintenanceRepetitionIntervalEnum.Custom ? (
                            <Descriptions.Item label={t('Maintenance.task.repeatEvery')}>
                                <span data-qa={qa.maintenance.detail.fieldDueDateValue}>
                                    {props.data.taskType?.repetitionDueDateValue}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                        {props.data.dueMileageKilometers ? (
                            <Descriptions.Item label={t(`${t('Maintenance.task.startMileage')} (${t('common.km')})`)}>
                                <span data-qa={qa.maintenance.detail.fieldDueMileageKm}>
                                    {numeral(props.data.dueMileageKilometers).format('0,0')}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                        {props.data.repetitionBindToDueDate ? (
                            <Descriptions.Item>
                                <Checkbox checked={!!props.data.repetitionBindToDueDate} disabled />
                                {t('Maintenance.task.linkRepetition')}
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                    </Descriptions>
                }
                secondRight={
                    <Descriptions layout="vertical" column={1}>
                        {props.data.repetitionInterval ? (
                            <Descriptions.Item label={t('Maintenance.task.repeat')}>
                                <span
                                    data-qa={qa.maintenance.detail?.[`fieldInterval_${props.data.repetitionInterval}`]}
                                >
                                    {t(`Maintenance.task.repeatTypes.${props.data.repetitionInterval}`)}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                        {props.data.repetitionInterval === MaintenanceRepetitionIntervalEnum.Custom ? (
                            <Descriptions.Item label={t('Maintenance.task.repeatInterval')}>
                                <span
                                    data-qa={
                                        qa.maintenance.detail?.[`fieldIntervalType_${props.data.repetitionDueDateType}`]
                                    }
                                >
                                    {t(`common.${props.data.repetitionDueDateType}`)}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                        {props.data.repetitionDueMileageKilometers ? (
                            <Descriptions.Item label={t(`${t('Maintenance.task.repeatAfter')} (${t('common.km')})`)}>
                                <span data-qa={qa.maintenance.detail.fieldRepetitionDueMileageKm}>
                                    {numeral(props.data.repetitionDueMileageKilometers).format('0,0')}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                    </Descriptions>
                }
                thirdTitle={t('Maintenance.task.notification')}
                thirdLeft={
                    <>
                        {props.data.notificationDueDateDays && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('Maintenance.task.daysBefore')}>
                                    <span data-qa={qa.maintenance.detail.fieldNotificationDueDateDays}>
                                        {props.data.notificationDueDateDays}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                        {props.data.notificationDueMileageKilometers && (
                            <Descriptions layout="vertical" column={1}>
                                <Descriptions.Item label={t('Maintenance.task.kmBefore')}>
                                    <span data-qa={qa.maintenance.detail.fieldNotificationDueMileageKm}>
                                        {numeral(props.data.notificationDueMileageKilometers).format('0,0')}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                        {!!notificationReceivers?.length && (
                            <Descriptions layout="vertical" column={2}>
                                <Descriptions.Item
                                    className="maintenance-receivers"
                                    label={
                                        <Row justify="space-between">
                                            <Col>{t('Maintenance.task.recipients')}</Col>
                                            <Col>
                                                <span className="maintenance-receivers-heading-image">
                                                    <img src={mail} alt="email" />
                                                </span>
                                                <span className="maintenance-receivers-heading-image">
                                                    <img src={computer} alt="computer" />
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    {notificationReceivers.map((r, i) => (
                                        <Row key={i} justify="space-between">
                                            <Col className="maintenance-receivers-title">
                                                <span className="maintenance-receivers-title-name">{r.name}</span>
                                                {r.email && <> ({r.email})</>}
                                            </Col>
                                            <Col>
                                                {r.channelEmail ? (
                                                    <img src={checkCircleGreen} alt="checked" />
                                                ) : (
                                                    <img src={closeCircleDark} alt="unchecked" />
                                                )}

                                                {r.channelApp ? (
                                                    <img src={checkCircleGreen} alt="checked" />
                                                ) : (
                                                    <img src={closeCircleDark} alt="unchecked" />
                                                )}
                                            </Col>
                                        </Row>
                                    ))}
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </>
                }
                forthTitle={props.data.notes ? t('common.notes') : undefined}
                forthLeft={
                    props.data.notes ? (
                        <Descriptions layout="vertical" column={1}>
                            <Descriptions.Item>
                                <span>{props.data.notes}</span>
                            </Descriptions.Item>
                        </Descriptions>
                    ) : undefined
                }
                fifthTitle={props.data.expenses ? t('Maintenance.task.expenses') : undefined}
                fifthLeft={
                    props.data.expenses?.priceTotalWoVat && props.data.expenses?.vat ? (
                        <Descriptions layout="vertical" column={1}>
                            <Descriptions.Item label={`${t('common.priceTotal')} (${t('common.includingVAT')})`}>
                                <span>
                                    {numeral(
                                        props.data.expenses.priceTotalWoVat * (1 + props.data.expenses.vat / 100)
                                    ).format('0,0.00')}{' '}
                                    {props.data.expenses.currency}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    ) : undefined
                }
            />
        </div>
    );
}
