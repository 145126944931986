import { useTranslation } from 'react-i18next';
import TableBar from 'common/components/TableBar';

interface Props {
    onHelperClick?: () => void;
}

function PlannerBar(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="planner-bar">
            <TableBar heading={t('PlannerBar.title')} onHelperClick={props.onHelperClick} />
        </div>
    );
}

export default PlannerBar;
