/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObject,
    ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectFromJSON,
    ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectFromJSONTyped,
    ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyMonitoredObjectToMonitoredObject
 */
export interface ReadOnlyMonitoredObjectToMonitoredObject {
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    validFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    validTo?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    pairInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    unpairInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    source: ReadOnlyMonitoredObjectToMonitoredObjectSourceEnum;
    /**
     * 
     * @type {ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObject}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    primaryMonitoredObject?: ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObject;
    /**
     * 
     * @type {ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObject}
     * @memberof ReadOnlyMonitoredObjectToMonitoredObject
     */
    secondaryMonitoredObject?: ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObject;
}

export function ReadOnlyMonitoredObjectToMonitoredObjectFromJSON(json: any): ReadOnlyMonitoredObjectToMonitoredObject {
    return ReadOnlyMonitoredObjectToMonitoredObjectFromJSONTyped(json, false);
}

export function ReadOnlyMonitoredObjectToMonitoredObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMonitoredObjectToMonitoredObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validFrom': (new Date(json['valid_from'])),
        'validTo': !exists(json, 'valid_to') ? undefined : (json['valid_to'] === null ? null : new Date(json['valid_to'])),
        'pairInfo': !exists(json, 'pair_info') ? undefined : json['pair_info'],
        'unpairInfo': !exists(json, 'unpair_info') ? undefined : json['unpair_info'],
        'source': json['source'],
        'primaryMonitoredObject': !exists(json, 'primary_monitored_object') ? undefined : ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectFromJSON(json['primary_monitored_object']),
        'secondaryMonitoredObject': !exists(json, 'secondary_monitored_object') ? undefined : ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectFromJSON(json['secondary_monitored_object']),
    };
}

export function ReadOnlyMonitoredObjectToMonitoredObjectToJSON(value?: ReadOnlyMonitoredObjectToMonitoredObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid_from': (value.validFrom.toISOString()),
        'valid_to': value.validTo === undefined ? undefined : (value.validTo === null ? null : value.validTo.toISOString()),
        'pair_info': value.pairInfo,
        'unpair_info': value.unpairInfo,
        'source': value.source,
        'primary_monitored_object': ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectToJSON(value.primaryMonitoredObject),
        'secondary_monitored_object': ReadOnlyMonitoredObjectToMonitoredObjectPrimaryMonitoredObjectToJSON(value.secondaryMonitoredObject),
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyMonitoredObjectToMonitoredObjectSourceEnum {
    TId = 't_id',
    Man = 'man'
}


