import * as Yup from 'yup';
import { ChangeEvent, useState } from 'react';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button } from '../../components';
import qa from 'qa-selectors';
import * as uuid from 'uuid';
import { InputField } from 'common/fields';
import CheckboxField from 'common/fields/Checkbox';
import { degC } from 'domain-constants';
import { formikValuesIsChanged } from 'common/components/ModalForm';

export interface ColdchainProfileData {
    id?: string;
    name?: string;
    aboveTemperatureThreshold?: number;
    aboveTemperatureThresholdUse?: boolean;
    belowTemperatureThreshold?: number;
    belowTemperatureThresholdUse?: boolean;
    alarmTimerSeconds?: number;
    alarmTimerSecondsUse?: boolean;
    deleted?: boolean;
}

export type ColdchainFormEditType = 'new' | 'update' | 'saveAs' | undefined;

interface Props extends WithTranslation {
    type?: ColdchainFormEditType;
    initialValues?: ColdchainProfileData;
    onSubmit?: (values: ColdchainProfileData, type: ColdchainFormEditType) => void;
    onCancel?: () => void;
}

function ColdchainProfileForm({ onSubmit, initialValues, t, onCancel, type }: Props) {
    let form: FormikProps<ColdchainProfileData>;
    const [_repaint, _setRepaint] = useState(0);
    const [aboveDisabled, setAboveDisabled] = useState(!initialValues?.aboveTemperatureThresholdUse ?? true);
    const [belowDisabled, setBelowDisabled] = useState(!initialValues?.belowTemperatureThresholdUse ?? true);
    const [alarmTimerDisabled, setAlarmTimerDisabled] = useState(!initialValues?.alarmTimerSecondsUse ?? true);
    const [_name, _setName] = useState(initialValues?.name);
    const [_changeName, _setChangeName] = useState(initialValues?.name);
    const [_change, _setChange] = useState(false);

    const schema = Yup.object().shape({
        name: Yup.string().when([], {
            is: () =>
                form.values.aboveTemperatureThresholdUse ||
                form.values.belowTemperatureThresholdUse ||
                form.values.alarmTimerSecondsUse,
            then: Yup.string().test({
                name: 'nameErr',
                message: () =>
                    (_changeName ?? '') === '' ? t('validator.custom.profile_name') : t('validator.trimValue'),
                test: value => {
                    const name = value ?? '';
                    return name !== '' && name.charAt(0) !== ' ' && name.charAt(name.length - 1) !== ' ';
                }
            })
        }),
        belowTemperatureThresholdUse: Yup.boolean(),
        belowTemperatureThreshold: Yup.number()
            .nullable()
            .when('belowTemperatureThresholdUse', (belowTemperatureThresholdUse, schema) =>
                belowTemperatureThresholdUse
                    ? Yup.number()
                          .nullable()
                          .min(-50, t('validator.minValue', { min: -50 }))
                          .max(50, t('validator.maxValue', { max: 50 }))
                          .required(t('validator.required'))
                    : schema
            ),
        aboveTemperatureThresholdUse: Yup.boolean(),
        aboveTemperatureThreshold: Yup.number()
            .nullable()
            .when([], {
                is: () => form.values.aboveTemperatureThresholdUse && form.values.belowTemperatureThresholdUse,
                then: Yup.number().when([], {
                    is: () => form.values.aboveTemperatureThreshold === form.values.belowTemperatureThreshold,
                    then: Yup.number()
                        .nullable()
                        .required(t('validator.required'))
                        .min(50, t('validator.custom.identical_values_temperature')),
                    otherwise: Yup.number()
                        .nullable()
                        .required(t('validator.required'))
                        .min(-50, t('validator.minValue', { min: -50 }))
                        .max(50, t('validator.maxValue', { max: 50 }))
                        .moreThan(
                            Yup.ref('belowTemperatureThreshold'),
                            t('validator.custom.different_values_temperature')
                        )
                }),
                otherwise: Yup.number().when([], {
                    is: () => form.values.aboveTemperatureThresholdUse,
                    then: Yup.number()
                        .nullable()
                        .min(-50, t('validator.minValue', { min: -50 }))
                        .max(50, t('validator.maxValue', { max: 50 }))
                        .required(t('validator.required')),
                    otherwise: Yup.number().nullable().notRequired()
                })
            }),
        alarmTimerSecondsUse: Yup.boolean(),
        alarmTimerSeconds: Yup.number()
            .nullable()
            .when('alarmTimerSecondsUse', (alarmTimerSecondsUse, schema) =>
                alarmTimerSecondsUse
                    ? Yup.number()
                          .nullable()
                          .required(t('validator.required'))
                          .min(1, t('validator.minValue', { min: 1 }))
                    : schema
            )
    });

    function handleOnSubmit(values: ColdchainProfileData) {
        let typeSubmit = type;
        if (type === 'update' && _name !== _changeName) {
            typeSubmit = 'saveAs';
        }
        onSubmit?.(values, typeSubmit);
    }

    const handleOnCheckboxClick = (name: string, value: boolean): void => {
        switch (name) {
            case 'aboveTemperatureThresholdUse':
                setAboveDisabled(!aboveDisabled);
                break;
            case 'belowTemperatureThresholdUse':
                setBelowDisabled(!belowDisabled);
                break;
            case 'alarmTimerSecondsUse':
                setAlarmTimerDisabled(!alarmTimerDisabled);
                break;
        }
        handleValuesChange(name, value);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        _setChangeName(event.target.value);
        handleValuesChange('name', event.target.value);
    };

    const handleValuesChange = (name: string, value: any) => {
        const change = formikValuesIsChanged(form, name, value);
        let values = { ...form.values };
        values[name] = value;

        const nameValue = values['name'] ?? '';
        const thresholds =
            nameValue !== '' &&
            nameValue.charAt(0) !== ' ' &&
            nameValue.charAt(nameValue.length - 1) !== ' ' &&
            (values['aboveTemperatureThresholdUse'] === true ||
                values['alarmTimerSecondsUse'] === true ||
                values['belowTemperatureThresholdUse'] === true);

        setTimeout(() => {
            form.validateForm().then(() => {
                _setChange(change && thresholds);
            });
        }, 200);
    };

    const handleNumberValuesChange = (name: string, value: any) => {
        if (value !== false) {
            handleValuesChange(name, value);
        }
    };

    return (
        <Formik<ColdchainProfileData>
            initialValues={{
                id: initialValues?.id ?? uuid.v4(),
                name: initialValues?.name,
                aboveTemperatureThreshold: initialValues?.aboveTemperatureThreshold ?? 0,
                aboveTemperatureThresholdUse: initialValues?.aboveTemperatureThresholdUse,
                belowTemperatureThreshold: initialValues?.belowTemperatureThreshold ?? 0,
                belowTemperatureThresholdUse: initialValues?.belowTemperatureThresholdUse,
                alarmTimerSecondsUse: initialValues?.alarmTimerSecondsUse,
                alarmTimerSeconds: initialValues?.alarmTimerSeconds ?? 0
            }}
            initialTouched={{
                aboveTemperatureThreshold: true
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<ColdchainProfileData>) => {
                form = formik;
                return (
                    <div className="coldchain-profile-form">
                        <Form
                            onChange={event => {
                                const element = event.target as HTMLFormElement;
                                const type = element.attributes.getNamedItem('type')?.value;
                                handleValuesChange(
                                    element.name,
                                    type === 'checkbox'
                                        ? element.checked
                                        : type === 'number'
                                        ? parseFloat(element.value)
                                        : element.value
                                );
                            }}
                        >
                            <Row gutter={6} className="name-field">
                                <Col flex="auto">
                                    <InputField
                                        name="name"
                                        placeholder={
                                            type === 'new'
                                                ? t('PlannerColdchain.newProfile')
                                                : t('VehicleProfileForm.name')
                                        }
                                        value={_changeName}
                                        autoFocus={true}
                                        qa={qa.coldChain.profileForm.name}
                                        onInput={handleNameChange}
                                    />
                                    <div className="control-error">{form.errors.name}</div>
                                </Col>
                                <Col>
                                    <Button
                                        size="middle"
                                        onClick={onCancel}
                                        qa={qa.planner.createVehicleProfile.btnCancel}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                                {_change && (
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="middle"
                                            qa={qa.planner.createVehicleProfile.btnSubmit}
                                            loading={formik.isSubmitting}
                                            disabled={!formik.isValid}
                                        >
                                            {type === 'new'
                                                ? t('PlannerColdchain.form.save')
                                                : _name !== _changeName
                                                ? t('PlannerColdchain.form.saveAsNew')
                                                : t('PlannerColdchain.form.update')}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Row className="temperature-field">
                                <Col span={19}>
                                    <CheckboxField
                                        name="belowTemperatureThresholdUse"
                                        defaultChecked={initialValues?.belowTemperatureThresholdUse}
                                        qa={qa.coldChain.profileForm.belowTemperatureThresholdCheckbox}
                                        onClick={element =>
                                            handleOnCheckboxClick(
                                                'belowTemperatureThresholdUse',
                                                (element.target as HTMLInputElement).checked
                                            )
                                        }
                                    >
                                        {t('PlannerColdchain.form.belowTemperatureThreshold')}
                                    </CheckboxField>
                                </Col>
                                <Col span={5}>
                                    <InputField.Number
                                        name="belowTemperatureThreshold"
                                        postfix={`[${degC}]`}
                                        disabled={belowDisabled}
                                        defaultValue={initialValues?.belowTemperatureThreshold ?? 0}
                                        onChange={value => handleNumberValuesChange('belowTemperatureThreshold', value)}
                                        qa={qa.coldChain.profileForm.belowTemperatureThreshold}
                                    />
                                </Col>
                            </Row>
                            <Row className="temperature-field">
                                <Col span={19}>
                                    <CheckboxField
                                        name="aboveTemperatureThresholdUse"
                                        defaultChecked={initialValues?.aboveTemperatureThresholdUse}
                                        qa={qa.coldChain.profileForm.aboveTemperatureThresholdCheckbox}
                                        onClick={element =>
                                            handleOnCheckboxClick(
                                                'aboveTemperatureThresholdUse',
                                                (element.target as HTMLInputElement).checked
                                            )
                                        }
                                    >
                                        {t('PlannerColdchain.form.aboveTemperatureThreshold')}
                                    </CheckboxField>
                                </Col>
                                <Col span={5}>
                                    <InputField.Number
                                        name="aboveTemperatureThreshold"
                                        disabled={aboveDisabled}
                                        postfix={`[${degC}]`}
                                        defaultValue={initialValues?.aboveTemperatureThreshold ?? 0}
                                        onChange={value => handleNumberValuesChange('aboveTemperatureThreshold', value)}
                                        qa={qa.coldChain.profileForm.aboveTemperatureThreshold}
                                    />
                                </Col>
                            </Row>
                            <Row className="temperature-field">
                                <Col span={19}>
                                    <CheckboxField
                                        name="alarmTimerSecondsUse"
                                        defaultChecked={initialValues?.alarmTimerSecondsUse}
                                        qa={qa.coldChain.profileForm.alarmTimerSecondsCheckbox}
                                        onClick={element =>
                                            handleOnCheckboxClick(
                                                'alarmTimerSecondsUse',
                                                (element.target as HTMLInputElement).checked
                                            )
                                        }
                                    >
                                        {t('PlannerColdchain.form.alarmTimerSeconds')}
                                    </CheckboxField>
                                </Col>
                                <Col span={5}>
                                    <InputField.Number
                                        name="alarmTimerSeconds"
                                        postfix={`[min]`}
                                        min={0}
                                        disabled={alarmTimerDisabled}
                                        defaultValue={(initialValues?.alarmTimerSeconds ?? 0) / 60}
                                        onChange={value => handleNumberValuesChange('alarmTimerSeconds', value)}
                                        qa={qa.coldChain.profileForm.alarmTimerSeconds}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(ColdchainProfileForm);
