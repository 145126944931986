import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { Role } from 'logic/auth';
import Button from 'common/components/Button';
import qa from 'qa-selectors';
import { PoiModel } from 'common/model/poi';

interface Props {
    model: PoiModel;
    roles: Role[];
    onEditClick?: () => void;
    onAddPlaceOfWorkClick?: () => void;
    onDeleteClick?: () => void;
}

function PoiActions(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Button
                        block
                        type="primary"
                        disabled={!props.roles.includes(Role.POI_W)}
                        qa={qa.poi.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-edit" />}
                        onClick={props.onEditClick}
                    >
                        {t('PoiForm.editCustomPlace')}
                    </Button>
                </Col>
                <Col span={24}>
                    <Button
                        block
                        type="primary"
                        disabled={!props.roles.includes(Role.POI_W)}
                        icon={<i className="fas fa-fw fa-trash-alt" />}
                        qa={qa.poi.detail.btnDelete}
                        onClick={props.onDeleteClick}
                    >
                        {t('PoiForm.deleteCustomPlace')}
                    </Button>
                </Col>
                <Col span={24}>
                    <Button
                        block
                        type="primary"
                        disabled={!props.roles.includes(Role.POI_W)}
                        icon={<i className="fas fa-users" />}
                        qa={qa.poi.detail.btnAddPlaceOfWork}
                        onClick={props.onAddPlaceOfWorkClick}
                    >
                        {t('PoiForm.addPlaceOfWorkUsers')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default PoiActions;
