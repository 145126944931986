import LinkedItem, { LinkedItemType } from 'common/components/LinkedItem';
import { PairingLink, PartnerPairingKey } from 'common/model/partner-pairing';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    links: PairingLink[];
    unpairLoading?: boolean;
    unpairHide?: boolean;
    onUnpair?: (id: string, key: PartnerPairingKey) => void;
}

function PartnerPairs(props: Props) {
    const { t } = props;

    function onUnpair(id: string, key: PartnerPairingKey): void {
        props.onUnpair?.(id, key);
    }

    function getType(type: PartnerPairingKey) {
        switch (type) {
            case PartnerPairingKey.users:
                return LinkedItemType.USER;
            case PartnerPairingKey.vehicles:
                return LinkedItemType.VEHICLE;
            case PartnerPairingKey.partners:
                return LinkedItemType.PARTNER;
            default:
                return undefined;
        }
    }

    return (
        <div className="partner-pairs">
            {props.links.map((d, i) => (
                <LinkedItem
                    key={i.toString()}
                    parentId={d.id}
                    type={getType(d.key)}
                    typeName={t(`common.${d.key}`)}
                    items={d.rows?.map(r => ({
                        label: ['sn', 'licensePlate', 'partner'].includes(r.name)
                            ? t(`Partner.${r.name}`)
                            : t(`common.${r.name}`),
                        value: r.value,
                        customName: r.customName
                    }))}
                    unpairLoading={props.unpairLoading}
                    unpairHide={props.unpairHide}
                    onUnpairClick={onUnpair.bind(undefined, d.id, d.key)}
                />
            ))}
        </div>
    );
}

export default withTranslation()(PartnerPairs);
