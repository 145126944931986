/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UnitCodeLength {
    Meter = 'meter'
}

export function UnitCodeLengthFromJSON(json: any): UnitCodeLength {
    return UnitCodeLengthFromJSONTyped(json, false);
}

export function UnitCodeLengthFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitCodeLength {
    return json as UnitCodeLength;
}

export function UnitCodeLengthToJSON(value?: UnitCodeLength | null): any {
    return value as any;
}

