import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Button, Confirm, Table, Tooltip } from 'common/components';
import { MessageType } from 'common/components/Confirm';
import TransportGoods from 'common/forms/TransportGoods';
import { GoodsWithAddress } from 'common/model/transports';
import { Goods } from 'generated/backend-api/models';
import qa from 'qa-selectors';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as CommonIcons from 'resources/images/common';

export interface GoodsTable extends Goods {
    loadingAt?: string;
    unloadingAt?: string;
}

interface Props extends WithTranslation {
    currentAddress: string;
    initialGoods?: GoodsWithAddress[];
    isPuesc?: boolean;
    placeDisabled?: boolean;
    placeIndex: number;
    onLoadingGoodsChange: (goods: Goods[]) => void;
}

interface State {
    formOpen: boolean;
    goods: GoodsWithAddress[];
    editGoods?: Goods;
    removeGoodsConfirm?: {
        visible: boolean;
        id: string;
    };
}

class PlaceTaskGoods extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            formOpen: false,
            goods: props.initialGoods ?? []
        };
    }

    render() {
        return (
            <div className="place-task-goods">
                {this.state.formOpen && (
                    <TransportGoods
                        initialValues={this.state.editGoods}
                        isPuesc={this.props.isPuesc}
                        onCancel={() =>
                            this.setState({
                                formOpen: false,
                                editGoods: undefined
                            })
                        }
                        onSubmit={this._onAddNewGoods}
                    />
                )}
                {!this.state.formOpen && (
                    <div className="place-task-goods-content">
                        <div className="place-task-goods-content table">
                            <Table<GoodsTable>
                                dataSource={this.state.goods.map((e, index) => ({ ...e, key: e.id, index: index }))}
                                columns={this._createColumns()}
                                className="goods-table"
                                bordered={false}
                            />
                        </div>
                        <div className="place-task-goods-content add-new">
                            <Button
                                onClick={() => this.setState({ formOpen: true })}
                                size="large"
                                type="dashed"
                                qa={qa.planner.activity.btnAddNewGood}
                            >
                                {this.props.t('PlannerPlacesTask.addGoodsToList')}
                            </Button>
                        </div>
                    </div>
                )}
                {this.state.removeGoodsConfirm?.visible && (
                    <Confirm
                        header={this.props.t('PlannerPlacesTask.removeGoodsHeader')}
                        type={MessageType.WARNING}
                        message={this.props.t('PlannerPlacesTask.removeGoodsConfirm')}
                        onConfirm={this._onRemoveGoodsConfirm}
                        onCancel={() => {
                            this.setState({ removeGoodsConfirm: undefined });
                        }}
                    />
                )}
            </div>
        );
    }

    private _createColumns = (): ColumnsType<Goods> => {
        return [
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.no'),
                dataIndex: 'index',
                key: 'index',
                width: 56,
                render: index => <span data-qa={qa.planner.activity.goods.fieldIndex}>{index}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.cnItems'),
                dataIndex: 'cnItems',
                key: 'cnItems',
                width: 112,
                render: cnItems => <span data-qa={qa.planner.activity.goods.fieldCnItems}>{cnItems}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.name'),
                dataIndex: 'name',
                key: 'name',
                width: 160,
                render: name => <span data-qa={qa.planner.activity.goods.fieldName}>{name}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.CMRNumber'),
                dataIndex: 'cmr',
                key: 'cmr',
                width: 112,
                render: cmr => <span data-qa={qa.planner.activity.goods.fieldCmrNumber}>{cmr}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.weight'),
                dataIndex: 'grossWeightVolume',
                key: 'grossWeightVolume',
                width: 80,
                render: grossWeightVolume => (
                    <span data-qa={qa.planner.activity.goods.fieldGrossWeightVolume}>{grossWeightVolume}</span>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.unit'),
                dataIndex: 'measurementUnit',
                key: 'measurementUnit',
                width: 80,
                render: measurmentUnit => (
                    <span data-qa={qa.planner.activity.goods.fieldMeasurmentUnit}>{measurmentUnit}</span>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.wasteCode'),
                dataIndex: 'wasteCode',
                key: 'wasteCode',
                width: 110,
                render: wasteCode => <span data-qa={qa.planner.activity.goods.fieldWasteCode}>{wasteCode}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.loadingAt'),
                dataIndex: ['loading', 'address'],
                key: 'loadingAddress',
                className: 'goods-table-location',
                width: 132,
                render: location => (
                    <Tooltip placement="bottomLeft" mouseLeaveDelay={0} title={location}>
                        <span data-qa={qa.planner.activity.goods.fieldLoading}>{location}</span>
                    </Tooltip>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.unloadingAt'),
                dataIndex: ['unloading', 'address'],
                key: 'unloadingAddress',
                className: 'goods-table-location',
                width: 132,
                render: location => (
                    <Tooltip placement="bottomLeft" mouseLeaveDelay={0} title={location}>
                        <span data-qa={qa.planner.activity.goods.fieldUnloading}>{location}</span>
                    </Tooltip>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.actions'),
                key: 'actions',
                width: 96,
                align: 'center',
                render: (record: GoodsWithAddress) => {
                    return (
                        <Row justify="center" gutter={[10, 20]}>
                            <Col>
                                {!this.props.placeDisabled && (
                                    <img
                                        className="pointer"
                                        src={CommonIcons.editPencilFill}
                                        alt="edit-goods"
                                        onClick={() => {
                                            this._onEditGoods(record);
                                        }}
                                        data-qa={qa.planner.activity.goods.btnEdit}
                                    />
                                )}
                            </Col>
                            <Col>
                                {!this.props.placeDisabled && (
                                    <img
                                        className="pointer"
                                        src={CommonIcons.trash}
                                        alt="remove-goods"
                                        onClick={() => {
                                            this._onRemoveGoods(record.id);
                                        }}
                                        data-qa={qa.planner.activity.goods.btnDelete}
                                    />
                                )}
                            </Col>
                        </Row>
                    );
                }
            }
        ];
    };

    private _onRemoveGoodsConfirm = () => {
        this.setState(
            {
                goods: [...this.state.goods.filter(goods => goods.id !== this.state.removeGoodsConfirm?.id)],
                removeGoodsConfirm: undefined
            },
            () => {
                this.props.onLoadingGoodsChange(this.state.goods);
            }
        );
    };

    private _onRemoveGoods = (goodsId: string) => {
        this.setState({
            removeGoodsConfirm: {
                visible: true,
                id: goodsId
            }
        });
    };

    private _onEditGoods = (goods: Goods) => {
        this.setState({
            editGoods: goods,
            formOpen: true
        });
    };

    private _onAddNewGoods = async (goods: Goods) => {
        let newGoods: GoodsWithAddress[] = [];
        if (this.state.editGoods) {
            newGoods = this.state.goods.map(stateGoods => {
                if (stateGoods.id === goods.id) {
                    return {
                        ...stateGoods,
                        ...goods
                    };
                }
                return stateGoods;
            });
        } else {
            newGoods = [
                ...this.state.goods,
                {
                    ...goods,
                    loading: {
                        placeIndex: this.props.placeIndex,
                        address: this.props.currentAddress,
                        placeId: ''
                    },
                    unloading: undefined
                }
            ];
        }
        this.setState(
            {
                goods: newGoods,
                formOpen: false,
                editGoods: undefined
            },
            () => {
                this.props.onLoadingGoodsChange(this.state.goods);
            }
        );

        return true;
    };
}

export default withTranslation()(PlaceTaskGoods);
