/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleInfo
 */
export interface VehicleInfo {
    /**
     * 
     * @type {string}
     * @memberof VehicleInfo
     */
    emissionClassCode: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInfo
     */
    axleCount: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleInfo
     */
    fuelConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleInfo
     */
    vehicleLoaded: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleInfo
     */
    loadFuelConsumptionRatio: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleInfo
     */
    maxWeight: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleInfo
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleInfo
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInfo
     */
    vehicleId: string;
}

export function VehicleInfoFromJSON(json: any): VehicleInfo {
    return VehicleInfoFromJSONTyped(json, false);
}

export function VehicleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emissionClassCode': json['emission_class_code'],
        'axleCount': json['axle_count'],
        'fuelConsumption': json['fuel_consumption'],
        'vehicleLoaded': json['vehicle_loaded'],
        'loadFuelConsumptionRatio': json['load_fuel_consumption_ratio'],
        'maxWeight': json['max_weight'],
        'weight': json['weight'],
        'companyId': json['company_id'],
        'vehicleId': json['vehicle_id'],
    };
}

export function VehicleInfoToJSON(value?: VehicleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emission_class_code': value.emissionClassCode,
        'axle_count': value.axleCount,
        'fuel_consumption': value.fuelConsumption,
        'vehicle_loaded': value.vehicleLoaded,
        'load_fuel_consumption_ratio': value.loadFuelConsumptionRatio,
        'max_weight': value.maxWeight,
        'weight': value.weight,
        'company_id': value.companyId,
        'vehicle_id': value.vehicleId,
    };
}


