import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { FixedCost, OperationalCost, Cost, CostUnit } from 'logic/statistics/statistics-company-profile';
import { CostTranslations } from 'modules/statistics/modules/company-profile/components/CompanyProfileCosts';
import { RouteConfiguration } from '../RouteConfigurator';

interface Props {
    /**
     * Fixed and operational costs for company vehicles (average for now)
     */
    costs: { fixed: FixedCost; operationalCost: OperationalCost };
    /**
     * Current price per km
     */
    pricePerKM: RouteConfiguration['pricePerKM'];
    /**
     * In meters
     */
    distance: number;
}

export default function CostPopover({ costs, pricePerKM, distance }: Props) {
    const { t } = useTranslation();

    const withType = (type: 'FixedCosts' | 'OperationalCosts', cost: FixedCost | OperationalCost) =>
        Object.fromEntries(Object.entries(cost).map(([key, value]) => [key, { ...value, type }]));

    const routeCostTotal = () => {
        const value = pricePerKM.cost * (distance / 1e3);
        return value;
    };

    const totalCosts = Object.entries({
        ...withType('FixedCosts', costs.fixed),
        ...withType('OperationalCosts', costs.operationalCost)
    }).filter(([_, cost]) => cost && (cost?.value ?? 0) > 0) as [
        keyof OperationalCost | FixedCost,
        Cost & { type: 'fixed' | 'operational' }
    ][];

    const sortedTotalCosts = totalCosts.sort((a, b) => a[1]?.value - b[1]?.value).reverse();
    const costsPerKM = sortedTotalCosts.filter(c => c[1].unit === CostUnit.PerKilometer);
    const costsPerKMTotal = costsPerKM.reduce((a, c) => (a = a + c[1].value * (distance / 1e3)), 0);

    return (
        <div className="costs-pupover">
            {costsPerKM.slice(0, 5).map((cost, i) => (
                <Row key={i} justify="space-between" gutter={20}>
                    <Col>{t(`CompanyProfile${cost[1].type}.${CostTranslations[cost[0].toString()]}`)}</Col>
                    <Col>{Math.round(cost[1].value * (distance / 1e3)) + ' ' + cost[1].currency}</Col>
                </Row>
            ))}
            {sortedTotalCosts.length !== costsPerKM.length && (
                <Row justify="space-between" gutter={20}>
                    <Col>{t(`CompanyProfileFixedCosts.other`)}</Col>
                    <Col>{Math.round(routeCostTotal() - costsPerKMTotal) + ' ' + pricePerKM.currency}</Col>
                </Row>
            )}
            {costsPerKM.length > 5 && (
                <Row justify="center" gutter={20}>
                    ...
                </Row>
            )}
            <Row justify="space-between" gutter={20}>
                <Col>{t(`CompanyProfile.totalCosts`)}</Col>
                <Col>{Math.round(routeCostTotal()) + ' ' + pricePerKM.currency}</Col>
            </Row>
        </div>
    );
}
