import { debounce } from 'debounce';
import { UserTokenType } from 'generated/graphql';
import { Logic } from 'logic/logic';
import React, { Component } from 'react';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import { UserModel as OldUserModel } from 'logic/user/users';
import { UserLinks, UserModel, UserType } from '../management-users/UsersModule';
import TachoCards from './ui/TachoCards';
import { TachoCardPairingTypes } from './ui/TachoCardPairing/TachoCardsPairing';
import { exponea } from 'logic/exponea';
import { search } from 'common/utils/search';
import { message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import { confDefault } from 'conf';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import {
    ReadOnlyCountry,
    ReadOnlyUser,
    UserTokenCreateTokenTypeEnum,
    WriteOnlyUserTokenTokenTypeEnum
} from 'generated/new-main';
import { DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { TachoCardCreatePairFormModel } from 'common/forms/TachoCardCreatePairForm';
import { UserDriverFormModel } from 'common/forms/UserDriverForm/UserDriverForm';

export interface TachoCardModel {
    expirationDate: string;
    holder: string;
    id: number;
    name: string;
    surname: string;
    type?: WriteOnlyUserTokenTokenTypeEnum;
    links?: TachoCardsLinks;
    startDate: string;
    token: string;
    userId?: number;
}

export interface TachoCardsLinks {
    user?: {
        id: string;
        name: string;
        relation: string;
    };
}

export type PairingData = UserModel;
export type UnpairType = TachoCardModel;

export interface Pairing {
    confirm?: PairingData;
    data?: PairingData[];
    search?: string;
    selected?: PairingData;
    type?: TachoCardPairingTypes;
    pairNewCreated?: boolean;
    newUser?: UserDriverFormModel;
}

export interface Unpairing {
    model: UnpairType;
    type: keyof TachoCardsLinks;
}

export interface TachoCardForm {
    expirationDate: string;
    expirationDateErr: string;
    name: string;
    surname: string;
    holder: string;
    token: string;
    tokenErr: string;
}

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    edit: boolean;
    pairing?: Pairing;
    search?: { text: string };
    countries: ReadOnlyCountry[];
    table?: {
        loading?: boolean;
        data?: TachoCardModel[];
        form?: TachoCardForm;
        remove?: TachoCardModel;
        selected?: TachoCardModel;
    };
    form?: {
        open?: boolean;
        users?: UserModel[];
    };
    roles: Role[];
    unpairing?: Unpairing;
    helper?: {
        content: string;
    };
    updateTachoCardLoading: boolean;
    removeTachoCardLoading: boolean;
    pairTachoCardLoading: boolean;
    unpairTachoCardLoading: boolean;
}

class TachoCardsModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const roles = this._logic.auth().roles();
        this.state = {
            edit: false,
            countries: [],
            table: {
                loading: true
            },
            roles,
            updateTachoCardLoading: false,
            removeTachoCardLoading: false,
            pairTachoCardLoading: false,
            unpairTachoCardLoading: false
        };
    }

    componentDidMount() {
        (window as any).app.TachoCardModule = this;
        this._logic
            .cards()
            .getTachoCards(true)
            .then(data => {
                this.setState({ table: { data: data.sort((a, b) => (a.id > b.id ? 1 : -1)), loading: false } });
            })
            .catch(err => {
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
            });

        this._logic
            .api()
            .countryApi.countryList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET })
            .then(res => {
                this.setState({
                    countries: res.results
                });
            });
    }

    componentWillUnmount() {
        (window as any).app.TachoCardModule = undefined;
    }

    render() {
        return (
            <TachoCards
                edit={this.state.edit}
                loading={this.state.table?.loading}
                data={this.state.table?.data}
                countries={this.state.countries}
                createFormVisible={!!this.state.table?.form}
                pairing={this.state.pairing}
                remove={this.state.table?.remove}
                search={this.state.search}
                selected={this.state.table?.selected}
                unpairing={this.state.unpairing}
                helper={this.state.helper}
                demoMode={this._logic.demo().isActive}
                roles={this.state.roles}
                form={this.state.form}
                updateTachoCardLoading={this.state.updateTachoCardLoading}
                removeTachoCardLoading={this.state.removeTachoCardLoading}
                pairTachoCardLoading={this.state.pairTachoCardLoading}
                unpairTachoCardLoading={this.state.unpairTachoCardLoading}
                onBarSearchInputChange={this._onSearchChange}
                onBarHelperClick={this._onBarHelperClick}
                onBarCreatePairClick={this._onTachoCardCreatePairClick}
                onTachoCardCreatePairCancelClick={this._onTachoCardCreatePairCancelClick}
                onTachoCardCreatePairSubmitClick={this._onTachoCardCreatePairSubmitClick}
                onTachoCardFormCheckTokenExist={this.onTachoCardFormCheckTokenExist}
                onTachoCardFormCancel={this._onTachoCardFormCancel}
                onTachoCardFormSubmit={this._onTachoCardFormSubmit}
                onTachoCardRemoveCancel={this._onTachoCardRemoveCancel}
                onTachoCardRemoveConfirm={this._onTachoCardRemoveConfirm}
                onTachoCardUnpair={this._onTachoCardUnpair}
                onPairingCancelButtonClick={this._onPairingCancel}
                onPairingConfirmCancel={this._onPairingConfirmCancel}
                onPairingConfirmButtonClick={this._onPairingConfirm}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearchInputChange={this._onPairingSearch}
                onPairingSubmitButtonClick={this._onPairingSubmit}
                onPairingTypeChange={this._onPairingTypeChange}
                onTableRowSelect={this._onTableRowSelect}
                onActionsUpdateClick={this._onActionsUpdateClick}
                onActionsPairingClick={this._onTachoCardPair}
                onActionsDeleteClick={this._onTachoCardRemoveClick}
                onUnpairCancel={this._onUnpairCancel}
                onUnpairConfirm={this._onUnpairConfirm}
                onHelperClose={this._onHelperClose}
            />
        );
    }

    private _onSearchChange = debounce((text: string): void => {
        this._logic.exponea().trackEvent(exponea.module.settingsTachographCards, {
            status: exponea.status.actionTaken,
            action: exponea.action.search
        });

        this.setState(
            {
                table: {
                    loading: true
                },
                search: { text }
            },
            () => {
                this._logic
                    .cards()
                    .getTachoCards()
                    .then(res => {
                        const data = search(text, ['token', 'name', 'surname'], res);
                        this.setState({ table: { data, loading: false } });
                    })
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            }
        );
    }, 500);

    private _onTachoCardUnpair = (model: UnpairType, type: keyof TachoCardsLinks): void => {
        this.setState({ unpairing: { model, type } });
    };

    private _onUnpairCancel = (): void => {
        this.setState({ unpairing: undefined });
    };

    private _onUnpairConfirm = async (): Promise<void> => {
        this.setState({ unpairTachoCardLoading: true });
        if (this.state.unpairing?.type === 'user') {
            try {
                const updated = await this._logic
                    .cards()
                    .unpairUserFromTachoCard(this.state.unpairing.model.id, String(this.state.unpairing.model.userId));
                if (updated) {
                    message.success(this.props.t('ManagementTachoCards.message.unpairSuccess'));
                    this._reloadData();
                } else {
                    message.error(this.props.t('ManagementTachoCards.message.unpairError'));
                }
            } catch (err) {
                console.error(`unpair error, err: ${err}`);
                message.error(this.props.t('ManagementTachoCards.message.unpairError'));
            }
        }
        this.setState({ unpairing: undefined, unpairTachoCardLoading: false });
    };

    private _onTachoCardRemoveConfirm = async (): Promise<void> => {
        this.setState({
            removeTachoCardLoading: true
        });
        const searchText = this.state.search?.text;
        if (this.state.table?.remove) {
            try {
                const data = await this.props.logic.cards().deleteTachoCard(this.state.table?.remove);
                message.success(this.props.t('ManagementTachoCards.message.removeSuccess'));
                this.setState({
                    table: {
                        data: searchText ? search(searchText, ['token', 'name', 'surname'], data) : data
                    }
                });
            } catch (err) {
                console.error(`Remove tacho error, err: ${err}`);
                message.error(this.props.t('ManagementTachoCards.message.removeError'));
            }
        }
        this.setState({
            removeTachoCardLoading: false
        });
    };

    private _onTachoCardRemoveCancel = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                remove: undefined
            }
        }));
    };

    private _onTachoCardRemoveClick = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                remove: this.state.table?.selected
            }
        }));
    };

    private _reloadData = (): void => {
        const searchText = this.state.search?.text;
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this._logic
                    .cards()
                    .getTachoCards(true)
                    .then(data => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false,
                                data: searchText
                                    ? search(searchText, ['token', 'name', 'surname'], data).sort((a, b) =>
                                          a.id > b.id ? 1 : -1
                                      )
                                    : data.sort((a, b) => (a.id > b.id ? 1 : -1)),
                                selected: data.find(d => d.id === state.table?.selected?.id)
                            }
                        }));
                    })
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            }
        );
    };

    private _onTachoCardFormSubmit = async (model: TachoCardModel): Promise<boolean> => {
        this.setState({
            updateTachoCardLoading: true
        });

        try {
            const searchText = this.state.search?.text;
            const tachoCards = await this.props.logic.cards().updateTachoCard(model);
            message.success(this.props.t('ManagementTachoCards.message.updateSuccess'));
            const selected = tachoCards.find(v => v.id === model.id);
            if (selected) {
                this.setState({
                    table: {
                        data: searchText ? search(searchText, ['token', 'name', 'surname'], tachoCards) : tachoCards,
                        form: undefined,
                        selected
                    }
                });
            }
            return true;
        } catch (err) {
            console.error(`Tacho update error, err: ${err}`);
            message.error(this.props.t('ManagementTachoCards.message.updateError'));
            return false;
        } finally {
            this.setState({
                edit: false,
                updateTachoCardLoading: false
            });
        }
    };

    private _onTachoCardFormCancel = (): void => {
        this.setState({
            edit: false
        });
    };

    private _onPairingConfirm = (newUser?: UserDriverFormModel): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                confirm: state.pairing?.selected,
                newUser
            }
        }));
    };

    private _onPairingSubmit = async (): Promise<void> => {
        this.setState({
            pairTachoCardLoading: true
        });
        function isUser(obj: PairingData): obj is UserModel {
            return 'name' in obj && 'surname' in obj;
        }

        if (
            (this.state.pairing?.type === 'user' &&
                this.state.pairing?.selected &&
                isUser(this.state.pairing.selected)) ||
            this.state.pairing?.newUser
        ) {
            let newCreatedUser: ReadOnlyUser | undefined;

            if (this.state.pairing.newUser) {
                try {
                    const roles = await this._logic.users().roles().getUserRoles();
                    const driverRole = roles.find(r => r.label.toLocaleLowerCase() === 'driver');
                    if (!driverRole?.id) throw new Error('could not find driver role id');
                    newCreatedUser = await this.props.logic.users().createUser({
                        active: 1,
                        averageSpeed: 0,
                        deleted: 0,
                        name: this.state.pairing.newUser.name ?? '',
                        surname: this.state.pairing.newUser.surname ?? '',
                        userRolesIds: [driverRole?.id],
                        userGroupIds: [],
                        languageIso6391: this.props.logic.auth().user().lang,
                        loginCredentials: {
                            pin: this.state.pairing.newUser.pin,
                            username: this.state.pairing.newUser.username
                        },
                        contact: {
                            phone_numbers: [],
                            emails: []
                        }
                    });
                } catch (err: any) {
                    if (err && typeof err?.json === 'function') {
                        err?.json()
                            ?.then(() => {
                                if (err?.status === 409) {
                                    message.error(this.props.t('ManagementUsers.userNameError'));
                                } else {
                                    message.error(this.props.t('ManagementUsers.error'));
                                }
                            })
                            .catch((err: any) => {
                                console.error('err', err);
                                message.error(this.props.t('ManagementUsers.error'));
                            });
                    } else {
                        message.error(this.props.t('ManagementUsers.error'));
                    }
                }
            }

            const tachoCardToBePaired = this.state.table?.selected;
            const selectedUser = newCreatedUser ?? this.state.pairing.selected;
            if (!selectedUser) {
                this.setState(state => ({
                    pairTachoCardLoading: false,
                    pairing: {
                        ...state.pairing,
                        confirm: undefined,
                        newUser: undefined
                    }
                }));
                throw new Error('no user to pair');
            }
            try {
                const updateTokenSuccess = await this._logic
                    .cards()
                    .pairUserToTachoCard(Number(tachoCardToBePaired?.id), String(selectedUser.id));
                if (updateTokenSuccess) {
                    message.success(this.props.t('ManagementTachoCards.message.pairSuccess'));
                    this.setState(
                        {
                            pairing: undefined,
                            pairTachoCardLoading: false
                        },
                        () => {
                            this._reloadData();
                        }
                    );
                    return;
                } else {
                    message.error(this.props.t('ManagementTachoCards.message.pairError'));
                }
            } catch (err: any) {
                console.error(`Tacho pair error, err: ${err}`);
                if (err.body) {
                    const data = await err.json();
                    if (data[0] === 'UserToken already paired to a user') {
                        message.error(this.props.t('ManagementTachoCards.message.alreadyPaired'));
                    } else {
                        message.error(this.props.t('ManagementTachoCards.message.pairError'));
                    }
                } else {
                    message.error(this.props.t('ManagementTachoCards.message.pairError'));
                }
            }
        }

        this.setState(state => ({
            pairTachoCardLoading: false,
            pairing: {
                ...state.pairing,
                confirm: undefined,
                newUser: undefined
            }
        }));
    };

    private _onPairingConfirmCancel = (): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                confirm: undefined,
                newUser: undefined
            }
        }));
    };

    private _onPairingCancel = (): void => {
        this.setState({ pairing: undefined });
    };

    private _onPairingTypeChange = (type: TachoCardPairingTypes): void => {
        if (this.state.pairing?.type === type) {
            return;
        }

        this.setState({ pairing: { type } }, () => {
            if (type === 'user') {
                this._logic
                    .users()
                    .drivers()
                    .then(res => {
                        const data = this._toUserModel(res, ['driver']);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data
                            }
                        }));
                    });
            }
        });
    };

    private _onPairingItemSelect = (selected: PairingData): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                selected
            }
        }));
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                search: text
            }
        }));
    }, 500);

    private _onTachoCardPair = (pairNewCreated?: boolean): void => {
        this.setState(
            {
                pairing: { type: 'user', pairNewCreated }
            },
            () => {
                this._logic
                    .users()
                    .drivers()
                    .then(drivers => {
                        const data = this._toUserModel(drivers, ['driver']);
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                data
                            }
                        }));
                    });
            }
        );
    };

    private _onTachoCardCreatePairClick = (): void => {
        this.setState(
            {
                form: {
                    open: true
                }
            },
            () => {
                this._logic
                    .users()
                    .drivers()
                    .then(res => {
                        const data = this._toUserModel(res, ['driver']);
                        this.setState(state => ({
                            form: {
                                ...state.form,
                                users: data
                            }
                        }));
                    });
            }
        );
    };

    private _onTachoCardCreatePairCancelClick = (): void => {
        this.setState({
            form: {
                open: false
            }
        });
    };

    private _onTachoCardCreatePairSubmitClick = async (model: TachoCardCreatePairFormModel): Promise<boolean> => {
        this._logic.exponea().trackEvent(exponea.module.settingsTachographCards, {
            status: exponea.status.actionTaken,
            action: exponea.action.createTachographCard
        });

        try {
            const country = this.state.countries.find(c => c.id === model.countryId);
            if (!country?.tachoAlpha) {
                throw new Error(`Country ${country?.name} has no tachoAlpha`);
            }
            const token = country?.tachoAlpha.toUpperCase() + ' '.repeat(3 - country?.tachoAlpha.length) + model.token;

            const roles = await this._logic.users().roles().getUserRoles();
            const driverRole = roles.find(r => r.label.toLocaleLowerCase() === 'driver');
            if (!driverRole?.id) {
                throw new Error('could not find driver role id');
            }

            this._logic
                .api()
                .userTokenApi.userTokenCreateAndPairUser({
                    data: {
                        userToken: {
                            token,
                            tokenType: UserTokenCreateTokenTypeEnum.DriverCard
                        },
                        user:
                            model.name && model.surname && model.username && model.pin
                                ? {
                                      name: model.name ?? '',
                                      surname: model.surname ?? '',
                                      loginCredentials: {
                                          pin: model.pin,
                                          username: model.username
                                      },
                                      active: 1,
                                      averageSpeed: 0,
                                      deleted: 0,
                                      languageIso6391: this.props.logic.auth().user().lang,
                                      userRolesIds: [driverRole?.id],
                                      userGroupIds: [],
                                      contact: {
                                          phone_numbers: [],
                                          emails: []
                                      }
                                  }
                                : undefined,
                        userId: model.userId ? Number(model.userId) : undefined
                    }
                })
                .then(() => {
                    const pairedUser = this.state.form?.users?.find(u => u.id === model.userId);
                    const pairedUserName = pairedUser
                        ? `${pairedUser.name} ${pairedUser.surname}`
                        : `${model.name} ${model.surname}`;
                    message.success(
                        this.props.t('ManagementTachoCards.message.createPairSuccess', {
                            token,
                            user: pairedUserName
                        })
                    );

                    this._reloadData();
                    this.setState({
                        form: undefined
                    });
                })
                .catch(err => {
                    if (err && typeof err?.json === 'function') {
                        err?.json()
                            ?.then((res: any) => {
                                if (err?.status === 409 && res?.['Type'] === 'user_token') {
                                    message.error(this.props.t('ManagementTachoCards.message.tokenAlreadyExits'));
                                } else if (err?.status === 409 && res?.['Type'] === 'user') {
                                    message.error(this.props.t('ManagementTachoCards.message.userAlreadyExits'));
                                }
                            })
                            .catch((err: any) => {
                                console.error(`Tacho create error, err: ${err}`);
                                message.error(this.props.t('ManagementUsers.error'));
                            });
                    } else {
                        message.error(this.props.t('ManagementUsers.error'));
                    }
                });
        } catch (err) {
            console.error(`Tacho create error, err: ${err}`);
            message.error(this.props.t('ManagementTachoCards.message.createError'));
        }

        return true;
    };

    private _onTableRowSelect = (id: number): void => {
        this.setState(state => ({
            edit: false,
            table: {
                ...state.table,
                selected: state.table?.selected?.id === id ? undefined : this.state.table?.data?.find(d => d.id === id)
            }
        }));
    };

    // TODO:
    // - move to logic
    // - after managementV1 deletion
    private _toUserModel = (users: OldUserModel[], types: UserType[]): UserModel[] =>
        users.map<UserModel>(u => {
            const tachoCard = u.tokens?.filter(t => t.token_type === 'driver_card')?.[0];
            return {
                id: u.id,
                email: u.email,
                mobile: u.mobile,
                name: u.name,
                note: u.note,
                password: u.password ?? '',
                ssoId: u.ssoId,
                surname: u.surname,
                username: u.username,
                tachographId: u.tachographId,
                contacts: u.contacts ?? { emails: [], phone_numbers: [] },
                tokens: u.tokens,
                pin: u.pin ?? '',
                address: '',
                workLocation: [],
                links: { tachoCard: this._tachoCardsToLinks(tachoCard) },
                userGroups: types
            };
        });
    // TODO:
    // - move to logic
    // - after managementV1 deletion
    private _tachoCardsToLinks = (token?: UserTokenType): UserLinks['tachoCard'] => ({
        id: String(token?.id ?? 0),
        holder: token?.holder ?? '',
        token: token?.token ?? '',
        type: token?.token_type ?? '',
        relation: '-',
        expirationDate: token?.endTime ?? ''
    });

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _onActionsUpdateClick = () => {
        this.setState({
            edit: true
        });
    };

    private onTachoCardFormCheckTokenExist = async (token: string): Promise<boolean> => {
        return await this._logic
            .api()
            .userTokenApi.userTokenList({ token: token })
            .then(res => {
                if (res.results.length === 0) {
                    return false;
                } else {
                    return true;
                }
            });
    };
}

export default withTranslation()(withRouter(TachoCardsModule));
