/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CostProfileItem,
    CostProfileItemFromJSON,
    CostProfileItemFromJSONTyped,
    CostProfileItemToJSON,
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeFromJSONTyped,
    MonitoredObjectFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectCostProfileOfFleetType
 */
export interface MonitoredObjectCostProfileOfFleetType {
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    fuel: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    additives: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    oil: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    tires: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    maintenance: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    liabilityInsurance: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    collisionInsurance: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    personalInjuryInsurance: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    parking: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    cabinHeating: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    telecomunicationsAndMonitoring: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    washing: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    lngCng: CostProfileItem;
    /**
     * 
     * @type {CostProfileItem}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    electro: CostProfileItem;
    /**
     * 
     * @type {MonitoredObjectFleetType}
     * @memberof MonitoredObjectCostProfileOfFleetType
     */
    fleetType: MonitoredObjectFleetType;
}

export function MonitoredObjectCostProfileOfFleetTypeFromJSON(json: any): MonitoredObjectCostProfileOfFleetType {
    return MonitoredObjectCostProfileOfFleetTypeFromJSONTyped(json, false);
}

export function MonitoredObjectCostProfileOfFleetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectCostProfileOfFleetType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fuel': CostProfileItemFromJSON(json['fuel']),
        'additives': CostProfileItemFromJSON(json['additives']),
        'oil': CostProfileItemFromJSON(json['oil']),
        'tires': CostProfileItemFromJSON(json['tires']),
        'maintenance': CostProfileItemFromJSON(json['maintenance']),
        'liabilityInsurance': CostProfileItemFromJSON(json['liability_insurance']),
        'collisionInsurance': CostProfileItemFromJSON(json['collision_insurance']),
        'personalInjuryInsurance': CostProfileItemFromJSON(json['personal_injury_insurance']),
        'parking': CostProfileItemFromJSON(json['parking']),
        'cabinHeating': CostProfileItemFromJSON(json['cabin_heating']),
        'telecomunicationsAndMonitoring': CostProfileItemFromJSON(json['telecomunications_and_monitoring']),
        'washing': CostProfileItemFromJSON(json['washing']),
        'lngCng': CostProfileItemFromJSON(json['lng_cng']),
        'electro': CostProfileItemFromJSON(json['electro']),
        'fleetType': MonitoredObjectFleetTypeFromJSON(json['fleet_type']),
    };
}

export function MonitoredObjectCostProfileOfFleetTypeToJSON(value?: MonitoredObjectCostProfileOfFleetType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fuel': CostProfileItemToJSON(value.fuel),
        'additives': CostProfileItemToJSON(value.additives),
        'oil': CostProfileItemToJSON(value.oil),
        'tires': CostProfileItemToJSON(value.tires),
        'maintenance': CostProfileItemToJSON(value.maintenance),
        'liability_insurance': CostProfileItemToJSON(value.liabilityInsurance),
        'collision_insurance': CostProfileItemToJSON(value.collisionInsurance),
        'personal_injury_insurance': CostProfileItemToJSON(value.personalInjuryInsurance),
        'parking': CostProfileItemToJSON(value.parking),
        'cabin_heating': CostProfileItemToJSON(value.cabinHeating),
        'telecomunications_and_monitoring': CostProfileItemToJSON(value.telecomunicationsAndMonitoring),
        'washing': CostProfileItemToJSON(value.washing),
        'lng_cng': CostProfileItemToJSON(value.lngCng),
        'electro': CostProfileItemToJSON(value.electro),
        'fleet_type': MonitoredObjectFleetTypeToJSON(value.fleetType),
    };
}


