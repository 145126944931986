/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    LoadingInformation,
    LoadingInformationFromJSON,
    LoadingInformationFromJSONTyped,
    LoadingInformationToJSON,
    NegotiableAttributes,
    NegotiableAttributesFromJSON,
    NegotiableAttributesFromJSONTyped,
    NegotiableAttributesToJSON,
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    Requirements,
    RequirementsFromJSON,
    RequirementsFromJSONTyped,
    RequirementsToJSON,
    ShipmentState,
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
    Shipper,
    ShipperFromJSON,
    ShipperFromJSONTyped,
    ShipperToJSON,
} from './';

/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentId: string;
    /**
     * 
     * @type {ShipmentState}
     * @memberof Shipment
     */
    state: ShipmentState;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    ownerId: string;
    /**
     * 
     * @type {Shipper}
     * @memberof Shipment
     */
    shipper: Shipper;
    /**
     * 
     * @type {Requirements}
     * @memberof Shipment
     */
    requirements: Requirements;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    isFtlOnly: boolean;
    /**
     * 
     * @type {LoadingInformation}
     * @memberof Shipment
     */
    loadingDetails: LoadingInformation;
    /**
     * 
     * @type {Array<LoadingInformation>}
     * @memberof Shipment
     */
    unloadingDetails: Array<LoadingInformation>;
    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    receiveProposalsUntil: Date;
    /**
     * 
     * @type {Array<NegotiableAttributes>}
     * @memberof Shipment
     */
    negotiableAttributes: Array<NegotiableAttributes>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Shipment
     */
    attachments: Array<Attachment>;
    /**
     * 
     * @type {Price}
     * @memberof Shipment
     */
    maxPrice?: Price;
    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    readonly updatedAt?: Date;
}

export function ShipmentFromJSON(json: any): Shipment {
    return ShipmentFromJSONTyped(json, false);
}

export function ShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'state': ShipmentStateFromJSON(json['state']),
        'ownerId': json['ownerId'],
        'shipper': ShipperFromJSON(json['shipper']),
        'requirements': RequirementsFromJSON(json['requirements']),
        'isFtlOnly': json['isFtlOnly'],
        'loadingDetails': LoadingInformationFromJSON(json['loadingDetails']),
        'unloadingDetails': ((json['unloadingDetails'] as Array<any>).map(LoadingInformationFromJSON)),
        'receiveProposalsUntil': (new Date(json['receiveProposalsUntil'])),
        'negotiableAttributes': ((json['negotiableAttributes'] as Array<any>).map(NegotiableAttributesFromJSON)),
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'maxPrice': !exists(json, 'maxPrice') ? undefined : PriceFromJSON(json['maxPrice']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function ShipmentToJSON(value?: Shipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentId': value.shipmentId,
        'state': ShipmentStateToJSON(value.state),
        'ownerId': value.ownerId,
        'shipper': ShipperToJSON(value.shipper),
        'requirements': RequirementsToJSON(value.requirements),
        'isFtlOnly': value.isFtlOnly,
        'loadingDetails': LoadingInformationToJSON(value.loadingDetails),
        'unloadingDetails': ((value.unloadingDetails as Array<any>).map(LoadingInformationToJSON)),
        'receiveProposalsUntil': (value.receiveProposalsUntil.toISOString()),
        'negotiableAttributes': ((value.negotiableAttributes as Array<any>).map(NegotiableAttributesToJSON)),
        'attachments': ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'maxPrice': PriceToJSON(value.maxPrice),
    };
}


