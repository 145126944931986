/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyContactPersonNested
 */
export interface WriteOnlyContactPersonNested {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyContactPersonNested
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    cellPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    ssoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactPersonNested
     */
    username?: string | null;
}

export function WriteOnlyContactPersonNestedFromJSON(json: any): WriteOnlyContactPersonNested {
    return WriteOnlyContactPersonNestedFromJSONTyped(json, false);
}

export function WriteOnlyContactPersonNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyContactPersonNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'address': json['address'],
        'clientId': json['client_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function WriteOnlyContactPersonNestedToJSON(value?: WriteOnlyContactPersonNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'address': value.address,
        'client_id': value.clientId,
        'name': value.name,
        'surname': value.surname,
        'email': value.email,
        'cell_phone': value.cellPhone,
        'position': value.position,
        'sso_id': value.ssoId,
        'username': value.username,
    };
}


