/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cost,
    CostFromJSON,
    CostFromJSONTyped,
    CostToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyCosts
 */
export interface CompanyCosts {
    /**
     * 
     * @type {Cost}
     * @memberof CompanyCosts
     */
    salaries: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof CompanyCosts
     */
    operations: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof CompanyCosts
     */
    crmInsurance: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof CompanyCosts
     */
    other: Cost;
}

export function CompanyCostsFromJSON(json: any): CompanyCosts {
    return CompanyCostsFromJSONTyped(json, false);
}

export function CompanyCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salaries': CostFromJSON(json['salaries']),
        'operations': CostFromJSON(json['operations']),
        'crmInsurance': CostFromJSON(json['crm_insurance']),
        'other': CostFromJSON(json['other']),
    };
}

export function CompanyCostsToJSON(value?: CompanyCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salaries': CostToJSON(value.salaries),
        'operations': CostToJSON(value.operations),
        'crm_insurance': CostToJSON(value.crmInsurance),
        'other': CostToJSON(value.other),
    };
}


