export function ignitionToStatus(ignition?: boolean | number | null): 0 | 1 | 2 {
    switch (ignition) {
        case 2:
            return 2;
        case true:
        case 1:
            return 1;
        case false:
        case null:
        case 0:
            return 0;
        default:
            return 0;
    }
}

export function alarmTypeToProfileConfigName(alarmType: string): string {
    switch (alarmType) {
        case 'transport_cold_chain_profile_temperature_low':
            return 'below_temperature_threshold';
        default:
        case 'transport_cold_chain_profile_temperature_high':
            return 'above_temperature_threshold';
    }
}
