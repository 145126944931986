/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleSettingsLNG,
    VehicleSettingsLNGFromJSON,
    VehicleSettingsLNGFromJSONTyped,
    VehicleSettingsLNGToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOSettingArrayLNG
 */
export interface TCOSettingArrayLNG {
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayLNG
     */
    type: TCOSettingArrayLNGTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayLNG
     */
    defaultValue: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayLNG
     */
    hint: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayLNG
     */
    units: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayLNG
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayLNG
     */
    visible: boolean;
    /**
     * 
     * @type {VehicleSettingsLNG}
     * @memberof TCOSettingArrayLNG
     */
    values: VehicleSettingsLNG;
}

export function TCOSettingArrayLNGFromJSON(json: any): TCOSettingArrayLNG {
    return TCOSettingArrayLNGFromJSONTyped(json, false);
}

export function TCOSettingArrayLNGFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOSettingArrayLNG {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'defaultValue': json['default_value'],
        'hint': json['hint'],
        'units': json['units'],
        'required': json['required'],
        'visible': json['visible'],
        'values': VehicleSettingsLNGFromJSON(json['values']),
    };
}

export function TCOSettingArrayLNGToJSON(value?: TCOSettingArrayLNG | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'default_value': value.defaultValue,
        'hint': value.hint,
        'units': value.units,
        'required': value.required,
        'visible': value.visible,
        'values': VehicleSettingsLNGToJSON(value.values),
    };
}

/**
* @export
* @enum {string}
*/
export enum TCOSettingArrayLNGTypeEnum {
    Integer = 'integer',
    Float = 'float',
    Array = 'array'
}


