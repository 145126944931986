/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppliedDiemsRules,
    AppliedDiemsRulesFromJSON,
    AppliedDiemsRulesFromJSONTyped,
    AppliedDiemsRulesToJSON,
} from './';

/**
 * 
 * @export
 * @interface FinancialReport
 */
export interface FinancialReport {
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancialReport
     */
    visitedCountries?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FinancialReport
     */
    countryStaySums?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FinancialReport
     */
    dailyRate?: { [key: string]: number; };
    /**
     * 
     * @type {AppliedDiemsRules}
     * @memberof FinancialReport
     */
    appliedDiemsRule?: AppliedDiemsRules;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FinancialReport
     */
    dailyTotalRate?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof FinancialReport
     */
    countryOriginCurrency?: string;
}

export function FinancialReportFromJSON(json: any): FinancialReport {
    return FinancialReportFromJSONTyped(json, false);
}

export function FinancialReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visitedCountries': !exists(json, 'visited_countries') ? undefined : json['visited_countries'],
        'countryStaySums': !exists(json, 'country_stay_sums') ? undefined : json['country_stay_sums'],
        'dailyRate': !exists(json, 'daily_rate') ? undefined : json['daily_rate'],
        'appliedDiemsRule': !exists(json, 'applied_diems_rule') ? undefined : AppliedDiemsRulesFromJSON(json['applied_diems_rule']),
        'dailyTotalRate': !exists(json, 'daily_total_rate') ? undefined : json['daily_total_rate'],
        'countryOriginCurrency': !exists(json, 'country_origin_currency') ? undefined : json['country_origin_currency'],
    };
}

export function FinancialReportToJSON(value?: FinancialReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visited_countries': value.visitedCountries,
        'country_stay_sums': value.countryStaySums,
        'daily_rate': value.dailyRate,
        'applied_diems_rule': AppliedDiemsRulesToJSON(value.appliedDiemsRule),
        'daily_total_rate': value.dailyTotalRate,
        'country_origin_currency': value.countryOriginCurrency,
    };
}


