/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DIHPriceTagResponse,
    DIHPriceTagResponseFromJSON,
    DIHPriceTagResponseFromJSONTyped,
    DIHPriceTagResponseToJSON,
    FuelStationPriceList,
    FuelStationPriceListFromJSON,
    FuelStationPriceListFromJSONTyped,
    FuelStationPriceListToJSON,
} from './';

/**
 * 
 * @export
 * @interface SinglePriceResponseModel
 */
export interface SinglePriceResponseModel {
    /**
     * 
     * @type {DIHPriceTagResponse}
     * @memberof SinglePriceResponseModel
     */
    response: DIHPriceTagResponse;
    /**
     * 
     * @type {Array<FuelStationPriceList>}
     * @memberof SinglePriceResponseModel
     */
    data: Array<FuelStationPriceList>;
}

export function SinglePriceResponseModelFromJSON(json: any): SinglePriceResponseModel {
    return SinglePriceResponseModelFromJSONTyped(json, false);
}

export function SinglePriceResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SinglePriceResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': DIHPriceTagResponseFromJSON(json['response']),
        'data': ((json['data'] as Array<any>).map(FuelStationPriceListFromJSON)),
    };
}

export function SinglePriceResponseModelToJSON(value?: SinglePriceResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': DIHPriceTagResponseToJSON(value.response),
        'data': ((value.data as Array<any>).map(FuelStationPriceListToJSON)),
    };
}


