/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobjectGroupsMobject,
    MobjectGroupsMobjectFromJSON,
    MobjectGroupsMobjectFromJSONTyped,
    MobjectGroupsMobjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface MobjectGroups
 */
export interface MobjectGroups {
    /**
     * 
     * @type {number}
     * @memberof MobjectGroups
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof MobjectGroups
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MobjectGroups
     */
    readonly clientId?: string;
    /**
     * 
     * @type {Array<MobjectGroupsMobject>}
     * @memberof MobjectGroups
     */
    monitoredObjects: Array<MobjectGroupsMobject>;
}

export function MobjectGroupsFromJSON(json: any): MobjectGroups {
    return MobjectGroupsFromJSONTyped(json, false);
}

export function MobjectGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobjectGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'monitoredObjects': ((json['monitored_objects'] as Array<any>).map(MobjectGroupsMobjectFromJSON)),
    };
}

export function MobjectGroupsToJSON(value?: MobjectGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'monitored_objects': ((value.monitoredObjects as Array<any>).map(MobjectGroupsMobjectToJSON)),
    };
}


