import React, { FormEvent, Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox, Col, Row } from 'antd';

import { Displayed, Services, DataSourceTypes, ParkingControlsForm } from '../MapModule';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import cn from 'classnames';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';
import PoiCard from 'common/components/PoiCard';
import { Button } from 'common/components';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    data: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        parkings: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    controlOnRoute?: 'edit' | 'preview';
    onFilterClick?: () => void;
    onPoiCardClick?: (poi: PoiModelMap) => void;
    onCancelClick?: () => void;
    onConfirmClick?: (form: ParkingControlsForm) => void;
    onAddToTransportClick?: (data: PoiMarkerData) => void;
}

interface State {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
    services: boolean;
}

class ControlsParking extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            subOptionsData: DataSourceTypes.EUROWAG,
            serviceData: this.props.data.serviceData,
            services: false
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="map-controls t-card">
                <div className="parking-controls t-inverse t-padding-small">
                    <form
                        className={cn({
                            'parking-controls-form-open': this.props.data.filterOpen,
                            'parking-controls-services-open': this.state.services
                        })}
                    >
                        <div>
                            <div className="map-controls-bubble main">
                                <div className="setting-card">
                                    <Row justify="space-between" align="middle">
                                        <Col>
                                            <label>{t('ParkingControls.parkings')}</label>
                                        </Col>
                                        <Col>
                                            <Button
                                                qa={
                                                    this.props.data.optionsData.doNotDisplay
                                                        ? qa.mapControls.showParkingLotsButton
                                                        : qa.mapControls.hideParkingLotsButton
                                                }
                                                type="primary"
                                                size="middle"
                                                onClick={this._onConfirmClick}
                                            >
                                                {this.props.data.optionsData.doNotDisplay
                                                    ? t('common.showOnMap')
                                                    : t('common.hide')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="content t-row t-hide">
                                    <div className="t-col t-half">
                                        <div className="t-row">
                                            <label>
                                                <Checkbox disabled checked>
                                                    {t('FuelStationControls.eurowag')}
                                                </Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="t-col t-half t-hide">
                                        <div className="t-row">
                                            <label>
                                                <input
                                                    disabled
                                                    type="checkbox"
                                                    checked={
                                                        this.props.data.subOptionsData === DataSourceTypes.IMPORTED
                                                    }
                                                    className="t-check t-inverse"
                                                    onChange={this._onSourceImportedChange}
                                                />
                                                {t('FuelStationControls.imported')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.data.filterOpen && (
                                <div className="map-controls-bubble">
                                    <div className="setting-card">
                                        <div className="heading t-row t-bar" onClick={this._onServicesDropdown}>
                                            <label className="t-bar-item">{t('FuelStationControls.services')}</label>
                                            <div className="setting-card-toggle t-right">
                                                <i
                                                    className={cn('fas', 'fa-fw', {
                                                        'fa-chevron-down': this.state.services,
                                                        'fa-chevron-right': !this.state.services
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        data-qa={qa.mapControls.parkingLotServicesContainer}
                                        className={cn('t-row content', {
                                            't-hide': !this.state.services
                                        })}
                                    >
                                        <div className="t-col t-half t-disabled">
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        disabled
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        checked={this.props.data.serviceData.wc}
                                                    />
                                                    {t('FuelStationControls.wc')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        disabled
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        checked={this.props.data.serviceData.shower}
                                                    />
                                                    {t('FuelStationControls.shower')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        disabled
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        checked={this.props.data.serviceData.food}
                                                    />
                                                    {t('FuelStationControls.food')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="t-col t-half t-disabled">
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        disabled
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        checked={this.props.data.serviceData.carWash}
                                                    />
                                                    {t('FuelStationControls.carwash')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        disabled
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        checked={this.props.data.serviceData.special}
                                                    />
                                                    {t('FuelStationControls.special')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                    <div className="parking-controls-stations t-medium">
                        {this.props.data.parkings?.map((p, index) => (
                            <PoiCard
                                type="parking"
                                data={p}
                                key={p.id}
                                index={index}
                                controlOnRoute={this.props.controlOnRoute}
                                onAddToRouteClick={this.props.onAddToTransportClick}
                                onClick={this.props.onPoiCardClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    private _onServicesDropdown = (): void => {
        this.setState(state => ({
            ...state,
            services: !state.services
        }));
    };

    private _onSourceImportedChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const checked = e.target.checked;
        this.setState({
            subOptionsData: checked ? DataSourceTypes.IMPORTED : undefined
        });
    };

    private _onConfirmClick = (event: FormEvent): void => {
        event.preventDefault();
        this.setState(
            {
                subOptionsData:
                    !this.props.data.optionsData.doNotDisplay &&
                    this.props.data.subOptionsData === DataSourceTypes.EUROWAG
                        ? undefined
                        : DataSourceTypes.EUROWAG
            },
            () => {
                this.props.onConfirmClick?.(this.state);
            }
        );
    };
}

export default withTranslation()(ControlsParking);
