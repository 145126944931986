import { ChangeEvent, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Slider } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Checkbox, Textarea } from 'common/components/Form';
import { TransportAlarmType } from 'common/model/transports';
import qa from 'qa-selectors';
import { TransportEventRule, TransportPlaceTask } from 'generated/backend-api/models';
import moment, { Moment } from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DownloadFile from '../DownloadFile/Index';
import { fileTypeCustom, fileTypeDoc, fileTypePdf, fileTypeXls } from 'resources/images/common';
import { formatDuration } from 'common/utils/dateTimeUtils';
import numeral from 'numeral';

interface Props extends WithTranslation {
    task: TransportPlaceTask;
    placeIndex: number;
    alarmValue?: TransportEventRule[];
    driverNoteValue?: string;
    disabled?: boolean;
    manualAta?: {
        dateTime: string;
        placeId: string;
    };
    onAlarmConfigChange?: (config: TransportEventRule[]) => void;
    onNoteForDriverChange?: (value: string) => void;
    onManualAtaDateChange?: (dateTime: string) => void;
}

interface State {
    alarmValue: TransportEventRule[];
    manualAta?: {
        dateTime: string;
        placeId: string;
    };
    driverNoteValue: string;
}

class PlannerAlarms extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            alarmValue: [],
            driverNoteValue: '',
            manualAta: this.props.manualAta
        };
    }

    options = [
        {
            label: this.props.t('PlannerAlarms.types.transport_arrival.name'),
            value: TransportAlarmType.TransportArrival
        },
        {
            label: this.props.t('PlannerAlarms.types.transport_departure.name'),
            value: TransportAlarmType.TransportDeparture
        },
        {
            label: this.props.t('PlannerAlarms.types.transport_poi_close.name'),
            value: TransportAlarmType.CloseToPoi,
            slider: {
                min: 1 * 60,
                max: 100 * 60,
                unit: 'min',
                note: this.props.t('PlannerAlarms.types.transport_poi_close.note')
            }
        },
        {
            label: this.props.t('PlannerAlarms.types.transport_corridor_leave.name'),
            value: TransportAlarmType.CorridorLeave,
            slider: {
                min: 1 * 1e3,
                max: 100 * 1e3,
                unit: 'km',
                note: this.props.t('PlannerAlarms.types.transport_corridor_leave.note')
            }
        }
    ];

    componentDidMount() {
        this.setState({ alarmValue: this.props.alarmValue ?? [], driverNoteValue: this.props.driverNoteValue ?? '' });
    }

    render() {
        const { alarmValue: value } = this.state;
        return (
            <div className="planner-alarms">
                <Checkbox.Group value={value.map(v => v.name ?? '')} onChange={this.onAlarmChange}>
                    {this.options.map(o => (
                        <div className="ant-checkbox-group-item" key={o.value}>
                            <Checkbox
                                disabled={
                                    this.props.disabled ||
                                    (this.props.placeIndex === 0 && o.value === TransportAlarmType.CorridorLeave) // MFR 18.2.2021 Cant set CorridorLeave on first place
                                }
                                value={o.value}
                                qa={qa.planner.alarms[`input_${o.value}`]}
                            >
                                {o.label}
                            </Checkbox>
                            {value.some(v => v.name === o.value) && o.slider && (
                                <div className="planner-alarms-slider">
                                    <Slider
                                        disabled={
                                            this.props.disabled ||
                                            (this.props.placeIndex === 0 &&
                                                o.value === TransportAlarmType.CorridorLeave) // MFR 18.2.2021 Cant set CorridorLeave on first place
                                        }
                                        defaultValue={Number(value.find(v => v.name === o.value)?.config?.[0].value)}
                                        min={o.slider.min}
                                        max={o.slider.max}
                                        onChange={this.onSliderChange(o.value)}
                                        tipFormatter={value => (
                                            <>
                                                {value
                                                    ? o.slider.unit === 'km'
                                                        ? `${numeral(value / 1e3).format('0,0')} ${o.slider.unit}`
                                                        : `${numeral(value / 60).format('0,0')} ${o.slider.unit}`
                                                    : ''}
                                            </>
                                        )}
                                    />
                                    <p>{o.slider.note}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </Checkbox.Group>

                <Textarea
                    disabled={this.props.disabled}
                    placeholder={this.props.t('PlannerPlacesTask.instructionsForDriver')}
                    value={this.state.driverNoteValue}
                    onChange={this._onNoteForDriverChange}
                    qa={qa.planner.placesTask.inputInstructions}
                />

                <div className="planner-alarms-driver">
                    <div className="planner-alarms-driver-time">
                        <div className="planner-alarms-driver-time-raw-data">
                            {this.props.task.activityStartTime && (
                                <div className="planner-alarms-driver-time-raw-data-item">
                                    <div>{this.props.t('PlannerPlacesTask.startTime')}</div>
                                    <div>{moment(this.props.task.activityStartTime).format('L LT')}</div>
                                </div>
                            )}
                            {this.props.task.activityEndTime && (
                                <div className="planner-alarms-driver-time-raw-data-item">
                                    <div>{this.props.t('PlannerPlacesTask.endTime')}</div>
                                    <div>{moment(this.props.task.activityEndTime).format('L LT')}</div>
                                </div>
                            )}
                            {this.props.task.activityStartTime && this.props.task.activityEndTime && (
                                <div className="planner-alarms-driver-time-raw-data-item">
                                    <div>{this.props.t('PlannerPlacesTask.duration')}</div>
                                    <div>
                                        {formatDuration(
                                            moment(this.props.task.activityEndTime).diff(
                                                moment(this.props.task.activityStartTime)
                                            ),
                                            true
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div>
                            {!this.props.disabled && (
                                // [TransportState.Active, TransportState.Delayed].includes(
                                //     this.props.transportState ?? TransportState.New
                                // ) &&
                                <div className="place-settings-bar-manual-ata">
                                    <Checkbox
                                        checked={!!this.props.manualAta}
                                        onChange={this._manualAtaChange}
                                        data-qa={qa.planner.placeSettings.inputManualAta}
                                    >
                                        {this.props.t('PlannerTaskBar.setManualAta')}
                                    </Checkbox>
                                    {this.state.manualAta && (
                                        <DatePicker.DateTimePicker
                                            defaultValue={moment(this.props.manualAta?.dateTime)}
                                            onChange={this._manualAtaDateChange}
                                            showTime={{ format: TIME_FORMAT_SHORT }}
                                            trigger={
                                                <div className="t-input">
                                                    {moment(this.props.manualAta?.dateTime).format('L LT')}
                                                </div>
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div> */}
                    </div>

                    {this.props.task.driverNote && (
                        <>
                            <div className="planner-alarms-driver-title">
                                {this.props.t('PlannerPlacesTask.notesFromDriver')}
                            </div>
                            <div className="planner-alarms-driver-note">{this.props.task.driverNote}</div>
                        </>
                    )}
                    {this.props.task?.payload && (
                        <div className="planner-alarms-driver-media">
                            {this.props.task?.payload
                                .filter(m => m.thumb)
                                .map(m => (
                                    <DownloadFile url={m.url} image={m.thumb} />
                                ))}
                            {this.props.task?.payload
                                .filter(m => !m.thumb)
                                .map(m => {
                                    const ext = m.url.substring(m.url.lastIndexOf('.') + 1).toLowerCase();
                                    if (ext === 'pdf') {
                                        return <DownloadFile url={m.url} icon={fileTypePdf} />;
                                    } else if (['doc', 'docx'].indexOf(ext) > -1) {
                                        return <DownloadFile url={m.url} icon={fileTypeDoc} />;
                                    } else if (['xls', 'xlsx'].indexOf(ext) > -1) {
                                        return <DownloadFile url={m.url} icon={fileTypeXls} />;
                                    } else {
                                        return <DownloadFile url={m.url} icon={fileTypeCustom} />;
                                    }
                                })}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private onAlarmChange = (value: CheckboxValueType[]) => {
        this.setState(
            state => ({
                alarmValue: [
                    ...value
                        .filter(v => !state.alarmValue.some(sv => String(sv.name) === String(v)))
                        .map(v => {
                            const config = {
                                name: v === TransportAlarmType.CorridorLeave ? 'distance' : 'duration',
                                value: v === TransportAlarmType.CorridorLeave ? 1 * 1e3 : 30 * 60
                            };
                            return {
                                name: v as TransportAlarmType,
                                config: [TransportAlarmType.CorridorLeave, TransportAlarmType.CloseToPoi].includes(
                                    v as TransportAlarmType
                                )
                                    ? [config]
                                    : []
                            };
                        }),
                    ...state.alarmValue.filter(e => value.includes(String(e.name)))
                ]
            }),
            () => {
                this.props.onAlarmConfigChange?.(this.state.alarmValue);
            }
        );
    };

    private _manualAtaChange = (e: CheckboxChangeEvent) => {
        if (!e.target.checked) {
            this.props.onManualAtaDateChange?.('');
        } else {
            this.props.onManualAtaDateChange?.(moment().toISOString());
        }
    };

    private _manualAtaDateChange = (value: Moment | null) => {
        if (value) {
            this.props.onManualAtaDateChange?.(value.toISOString());
        }
    };

    private onSliderChange = (name: string) => {
        return (sValue: number) => {
            const index = this.state.alarmValue.findIndex(e => e.name === name);
            if (index !== -1) {
                const config = {
                    name: name === TransportAlarmType.CorridorLeave ? 'distance' : 'duration',
                    value:
                        name === TransportAlarmType.CorridorLeave
                            ? Number(numeral(sValue / 1e3).format('0,0')) * 1e3
                            : Number(numeral(sValue / 60).format('0,0')) * 60
                };
                this.setState(
                    state => ({
                        alarmValue: [
                            ...state.alarmValue.filter(e => e.name !== (name as TransportAlarmType)),
                            {
                                name: name as TransportAlarmType,
                                config: [config]
                            }
                        ]
                    }),
                    () => {
                        this.props.onAlarmConfigChange?.(this.state.alarmValue);
                    }
                );
            }
        };
    };

    private _onNoteForDriverChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState(
            {
                driverNoteValue: event.target.value
            },
            () => {
                this.props.onNoteForDriverChange?.(this.state.driverNoteValue);
            }
        );
    };
}

export default withTranslation()(PlannerAlarms);
