/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    MainDataResOperationResultSummary,
    MainDataResOperationResultSummaryFromJSON,
    MainDataResOperationResultSummaryToJSON,
    MainDataResTagAssignmentArray,
    MainDataResTagAssignmentArrayFromJSON,
    MainDataResTagAssignmentArrayToJSON,
    MainDataResvoid,
    MainDataResvoidFromJSON,
    MainDataResvoidToJSON,
    TagAssignModel,
    TagAssignModelFromJSON,
    TagAssignModelToJSON,
    TagCreateModel,
    TagCreateModelFromJSON,
    TagCreateModelToJSON,
    TagUpdateModel,
    TagUpdateModelFromJSON,
    TagUpdateModelToJSON,
} from '../models';

export interface AssignTagsRequest {
    performModifications?: boolean;
    tagAssignModel?: Array<TagAssignModel>;
}

export interface CreateTagsRequest {
    performModifications?: boolean;
    tagCreateModel?: Array<TagCreateModel>;
}

export interface DeleteTagsRequest {
    performModifications?: boolean;
    requestBody?: Array<number>;
}

export interface GetAssignedRequest {
    tagId?: number;
    assetTypeId?: number;
    tagCategoryId?: number;
}

export interface GetTagsRequest {
    assetTypeId?: number;
    tagCategoryId?: number;
}

export interface UnassignTagsRequest {
    performModifications?: boolean;
    tagAssignModel?: Array<TagAssignModel>;
}

export interface UpdateTagsRequest {
    performModifications?: boolean;
    tagUpdateModel?: Array<TagUpdateModel>;
}

/**
 * no description
 */
export class TagApi extends runtime.BaseAPI {

    /**
     */
    async assignTagsRaw(requestParameters: AssignTagsRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag/assign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tagAssignModel?.map(TagAssignModelToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async assignTags(requestParameters: AssignTagsRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.assignTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createTagsRaw(requestParameters: CreateTagsRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tagCreateModel?.map(TagCreateModelToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async createTags(requestParameters: CreateTagsRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.createTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteTagsRaw(requestParameters: DeleteTagsRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async deleteTags(requestParameters: DeleteTagsRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.deleteTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAssignedRaw(requestParameters: GetAssignedRequest): Promise<runtime.ApiResponse<MainDataResTagAssignmentArray>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tagId !== undefined) {
            queryParameters['tagId'] = requestParameters.tagId;
        }

        if (requestParameters.assetTypeId !== undefined) {
            queryParameters['assetTypeId'] = requestParameters.assetTypeId;
        }

        if (requestParameters.tagCategoryId !== undefined) {
            queryParameters['tagCategoryId'] = requestParameters.tagCategoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag/assigned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResTagAssignmentArrayFromJSON(jsonValue));
    }

    /**
     */
    async getAssigned(requestParameters: GetAssignedRequest): Promise<MainDataResTagAssignmentArray> {
        const response = await this.getAssignedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTagsRaw(requestParameters: GetTagsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.assetTypeId !== undefined) {
            queryParameters['assetTypeId'] = requestParameters.assetTypeId;
        }

        if (requestParameters.tagCategoryId !== undefined) {
            queryParameters['tagCategoryId'] = requestParameters.tagCategoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async getTags(requestParameters: GetTagsRequest): Promise<InlineResponse200> {
        const response = await this.getTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async unassignTagsRaw(requestParameters: UnassignTagsRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag/unassign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tagAssignModel?.map(TagAssignModelToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async unassignTags(requestParameters: UnassignTagsRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.unassignTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateTagsRaw(requestParameters: UpdateTagsRequest): Promise<runtime.ApiResponse<MainDataResOperationResultSummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.performModifications !== undefined) {
            queryParameters['performModifications'] = requestParameters.performModifications;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tagUpdateModel?.map(TagUpdateModelToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResOperationResultSummaryFromJSON(jsonValue));
    }

    /**
     */
    async updateTags(requestParameters: UpdateTagsRequest): Promise<MainDataResOperationResultSummary> {
        const response = await this.updateTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     * To get the api version
     * get api version
     */
    async versionRaw(): Promise<runtime.ApiResponse<MainDataResvoid>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tag/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResvoidFromJSON(jsonValue));
    }

    /**
     * To get the api version
     * get api version
     */
    async version(): Promise<MainDataResvoid> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
