import i18n from 'i18next';
import moment, { duration } from 'moment';

import { Logic } from '../logic';
import { DriverModel } from '../user/users';
import { Role } from '../auth';
import { DATE_FORMAT, DATE_FORMAT_SHORT_BE, KM } from 'domain-constants';
import { DateRange } from 'common/model/date-time';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import numeral from 'numeral';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { FuelStatsGroupByTimespan, FuelStatsGroupByEntity, FuelStatisticsGroup } from 'generated/backend-api';

export interface Summary {
    journeyLiters: number;
    journeyAFC: number;
    drivingLiters: number;
    drivingDistance: number;
    drivingAFC: number;
    idlingLiters: number;
    idlingTaken: number;
    idlingAFC: number;
    ptoLiters: number;
    ptoTaken: number;
    ptoAFC: number;
}

export class StatisticsFuelConsumptionLogic {
    private _data: FuelStatisticsGroup[];
    private _vehicles: ReadOnlyMonitoredObjectFeSb[];
    private _users: DriverModel[];

    constructor(private _logic: Logic) {
        this._data = [];
        this._vehicles = [];
        this._users = [];
    }

    settings(): {
        drivers: string[];
        vehicles: string[];
        groupByTimespan: FuelStatsGroupByTimespan;
        groupByEntity: FuelStatsGroupByEntity;
        shortActivity: boolean;
        fuelSuspiciousActivity: boolean;
    } {
        const settings = this._logic.settings().getProp('statistics').fuelConsumption.filter;
        const filter = {
            drivers: settings.drivers,
            vehicles: settings.vehicles,
            groupByTimespan: settings.groupByTimespan,
            groupByEntity: settings.groupByEntity,
            shortActivity: settings.shortActivity,
            fuelSuspiciousActivity: settings.fuelSuspiciousActivity
        };
        return filter;
    }

    setSettings(settings: {
        drivers?: string[];
        vehicles?: string[];
        groupByTimespan?: FuelStatsGroupByTimespan;
        groupByEntity?: FuelStatsGroupByEntity;
        shortActivity?: boolean;
        fuelSuspiciousActivity?: boolean;
    }) {
        const originalSettings = this._logic.settings().getProp('statistics').fuelConsumption.filter;
        const modifiedSettings = {
            drivers: settings.drivers ?? originalSettings.drivers,
            vehicles: settings.vehicles ?? originalSettings.vehicles,
            groupByTimespan: settings.groupByTimespan ?? originalSettings.groupByTimespan,
            groupByEntity: settings.groupByEntity ?? originalSettings.groupByEntity,
            shortActivity: settings.shortActivity ?? originalSettings.shortActivity,
            fuelSuspiciousActivity: settings.fuelSuspiciousActivity ?? originalSettings.fuelSuspiciousActivity
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProps().statistics,
            fuelConsumption: { filter: modifiedSettings }
        });
        // this._logic.notification().send(
        //     {
        //         type: 'settings',
        //         data: this._logic.settings().getProps()
        //     },
        //     this._logic.auth().user().id
        // );
    }

    async table(filter: {
        drivers?: string[];
        vehicles?: string[];
        dateRange: DateRange;
        groupByTimespan?: FuelStatsGroupByTimespan;
        groupByEntity?: FuelStatsGroupByEntity;
    }): Promise<FuelStatisticsGroup[]> {
        this._data = await this.getFuelConsumption(filter);
        return this._data;
    }

    geUsers() {
        return this._users;
    }

    geVehicles() {
        return this._vehicles;
    }

    async getUsers(): Promise<DriverModel[]> {
        this._users = await this._logic.users().drivers();
        return this._users;
    }

    async getVehicles(): Promise<ReadOnlyMonitoredObjectFeSb[]> {
        this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, false, [Role.FC_R]);
        return this._vehicles;
    }

    async loadData(filter: {
        drivers?: string[];
        vehicles?: string[];
        dateRange: DateRange;
        groupByTimespan?: FuelStatsGroupByTimespan;
        groupByEntity?: FuelStatsGroupByEntity;
    }): Promise<FuelStatisticsGroup[]> {
        await this.getUsers();
        await this.getVehicles();
        this._data = await this.getFuelConsumption(filter);
        return this._data;
    }

    async getFuelConsumption(filter: {
        drivers?: string[];
        vehicles?: string[];
        dateRange: DateRange;
        groupByTimespan?: FuelStatsGroupByTimespan;
        groupByEntity?: FuelStatsGroupByEntity;
    }): Promise<FuelStatisticsGroup[]> {
        if (this._logic.demo().isActive) {
            return this._logic
                .demo()
                .data.fuelConsumptions.filter(entry =>
                    !filter?.drivers || filter?.drivers?.length === 0 || filter?.drivers?.length === this._users.length
                        ? true
                        : filter?.drivers?.some(driver =>
                              entry.entries?.some(entryDriver => String(entryDriver.driverId) === driver)
                          )
                )
                .filter(entry =>
                    !filter?.vehicles ||
                    filter?.vehicles?.length === 0 ||
                    filter?.vehicles?.length === this._vehicles.length
                        ? true
                        : filter?.vehicles?.some(vehicle => vehicle === entry.monitoredObjectId)
                );
        }

        try {
            const requestFilter = { ...this.settings(), ...filter };
            const fuelConsumption = await this._logic
                .api()
                .journeysStatisticsApi.getFuelStatisticsV1JourneyStatisticsFuelStatisticsGet({
                    moids:
                        !filter?.vehicles ||
                        filter?.vehicles?.length === 0 ||
                        filter?.vehicles?.length === this._vehicles.length
                            ? undefined
                            : filter?.vehicles?.map(v => String(v)),
                    driverIds:
                        !filter?.drivers ||
                        filter?.drivers?.length === 0 ||
                        filter?.drivers?.length === this._users.length
                            ? undefined
                            : filter?.drivers?.map(v => Number(v)),
                    groupByTimespan: requestFilter.groupByTimespan,
                    groupByEntity: requestFilter.groupByEntity,
                    dateFrom: moment
                        .utc(requestFilter.dateRange.start, DATE_FORMAT)
                        .startOf('day')
                        .format('YYYY-MM-DD'),
                    dateTo: moment.utc(requestFilter.dateRange.end, DATE_FORMAT).add(1, 'day').format('YYYY-MM-DD')
                })
                .then(data => data);

            return fuelConsumption;
        } catch (err) {
            console.error('Get consumption err', err);
            throw err;
        }
    }

    async downloadFuelConsumptionExport(
        data: FuelStatisticsGroup[],
        from: string,
        to: string,
        groupByEntity: FuelStatsGroupByEntity
    ) {
        const workbook: Workbook = await fromBlankAsync();
        workbook.sheet(0).name(normalizeExportSheetName(i18n.t('FuelConsumptionBar.fuelConsumption')));

        const fromStrFormat = moment(from, DATE_FORMAT).format('L');
        const toStrFormat = moment(to, DATE_FORMAT).format('L');
        const headDescription = [
            [
                i18n.t('FuelConsumptionBar.fuelConsumption'),
                '',
                moment(from, DATE_FORMAT).format('L'),
                '',
                moment(to, DATE_FORMAT).format('L')
            ],
            []
        ];

        const headTableTitleGroup = [
            [
                i18n.t('common.date').toUpperCase(),
                i18n.t('FuelConsumptionTable.vehicleDriver').toUpperCase(),
                i18n.t('FuelConsumptionTable.journeyCombined').toUpperCase(),
                '',
                i18n.t('FuelConsumptionTable.driving').toUpperCase(),
                '',
                '',
                i18n.t('FuelConsumptionTable.idling').toUpperCase(),
                '',
                ''
            ]
        ];
        const headTableTitle = [
            [
                '',
                '',
                i18n.t('FuelConsumptionTable.liters').toUpperCase(),
                i18n.t('FuelConsumptionTable.AFCL100km').toUpperCase(),
                i18n.t('FuelConsumptionTable.liters').toUpperCase(),
                i18n.t('FuelConsumptionTable.distance').toUpperCase(),
                i18n.t('FuelConsumptionTable.AFCL100km').toUpperCase(),
                i18n.t('FuelConsumptionTable.liters').toUpperCase(),
                i18n.t('FuelConsumptionTable.taken').toUpperCase(),
                i18n.t('FuelConsumptionTable.AFCLh').toUpperCase()
            ]
        ];

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true
        };
        const dataStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid',
                horizontalAlignment: 'center'
            }
        };

        const tableBody: any[][] = [];
        data.forEach(fc => {
            const vehicle = this._vehicles.find(vehicle => vehicle.id === Number(fc.monitoredObjectId));
            const driver = this._users.find(user => user.id === String(fc.driverId));

            tableBody.push([
                moment(fc.dateFrom, DATE_FORMAT_SHORT_BE).format('L'),
                groupByEntity === FuelStatsGroupByEntity.Vehicle
                    ? getRegistrationNumber(!!vehicle?.disabledAt, vehicle?.registrationNumber ?? '')
                    : (driver?.name ?? '') + ' ' + (driver?.surname ?? ' '),
                fc.stats.consumptionLiters ? numeral(fc.stats.consumptionLiters).format('0,0.00') : '-',
                fc.stats.consumptionLitersPer100km ? numeral(fc.stats.consumptionLitersPer100km).format('0,0.00') : '-',
                fc.stats.consumptionActivityLiters ? numeral(fc.stats.consumptionActivityLiters).format('0,0.00') : '-',
                fc.stats.distanceKilometers ? `${numeral(fc.stats.distanceKilometers).format('0,0.00')} ${KM}` : '-',
                fc.stats.consumptionActivityLitersPer100km
                    ? numeral(fc.stats.consumptionActivityLitersPer100km).format('0,0.00')
                    : '-',
                fc.stats.consumptionIdleLiters ? numeral(fc.stats.consumptionIdleLiters).format('0,0.00') : '-',
                fc.stats.idleTimeSeconds ? duration(fc.stats.idleTimeSeconds, 's').format() : '-',
                fc.stats.consumptionIdleLitersPer1h
                    ? numeral(fc.stats.consumptionIdleLitersPer1h).format('0,0.00')
                    : '-'
            ]);

            fc.entries?.forEach(entry => {
                const entryVehicle = this._vehicles.find(vehicle => vehicle.id === Number(entry.monitoredObjectId));
                const entryDriver = this._users.find(user => user.id === String(entry.driverId));
                tableBody.push([
                    moment.utc(fc.dateFrom, DATE_FORMAT_SHORT_BE).format('L'),
                    groupByEntity === FuelStatsGroupByEntity.Vehicle
                        ? (entryDriver?.name ?? '') + ' ' + (entryDriver?.surname ?? ' ')
                        : getRegistrationNumber(!!entryVehicle?.disabledAt, entryVehicle?.registrationNumber ?? ''),
                    entry.stats.consumptionLiters ? numeral(entry.stats.consumptionLiters).format('0,0.00') : '-',
                    entry.stats.consumptionLitersPer100km
                        ? numeral(entry.stats.consumptionLitersPer100km).format('0,0.00')
                        : '-',
                    entry.stats.consumptionActivityLiters
                        ? numeral(entry.stats.consumptionActivityLiters).format('0,0.00')
                        : '-',
                    entry.stats.distanceKilometers
                        ? `${numeral(entry.stats.distanceKilometers).format('0,0.00')} ${KM}`
                        : '-',
                    entry.stats.consumptionActivityLitersPer100km
                        ? numeral(entry.stats.consumptionActivityLitersPer100km).format('0,0.00')
                        : '-',
                    entry.stats.consumptionIdleLiters
                        ? numeral(entry.stats.consumptionIdleLiters).format('0,0.00')
                        : '-',
                    entry.stats.idleTimeSeconds ? duration(entry.stats.idleTimeSeconds, 's').format() : '-',
                    entry.stats.consumptionIdleLitersPer1h
                        ? numeral(entry.stats.consumptionIdleLitersPer1h).format('0,0.00')
                        : '-'
                ]);
            });
        });

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;

        const tableTitleGroupStartRow = descriptionEndRow + 1;
        const tableTitleGroupEndRow = tableTitleGroupStartRow;
        const tableTitleStartRow = tableTitleGroupEndRow + 1;
        const tableTitleEndRow = tableTitleStartRow;
        const tableDataStartRow = tableTitleEndRow + 1;
        const tableDataEndRow = tableDataStartRow + tableBody.length - 1;

        const workbookDescriptionRange = workbook.sheet(0).range(descriptionStartRow, 1, tableTitleEndRow, 12);
        const workbookTableTitleGroupRange = workbook
            .sheet(0)
            .range(tableTitleGroupStartRow, 1, tableTitleGroupEndRow, 12);
        const workbookTableTitleRange = workbook.sheet(0).range(tableTitleStartRow, 1, tableTitleEndRow, 12);
        const workbookTableBodyRange = workbook.sheet(0).range(tableDataStartRow, 1, tableDataEndRow, 12);

        workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);
        workbookTableTitleGroupRange.style(titleDescriptionStyle).value(headTableTitleGroup);
        workbookTableTitleRange.style({ ...titleDescriptionStyle, bottomBorder: true }).value(headTableTitle);
        workbookTableBodyRange.style(dataStyle).value(tableBody);

        let totalRows = 4;
        data.forEach((fc, index) => {
            if (fc.entries && fc.entries.length > 0) {
                const startRow = totalRows + index + 2;
                const endRow = startRow + fc.entries.length - 1;
                totalRows += fc.entries.length;
                workbook
                    .sheet(0)
                    .range(startRow, 2, endRow, 12)
                    .style({
                        fill: {
                            color: 'EDEDED',
                            type: 'solid',
                            horizontalAlignment: 'center'
                        }
                    });
            }
        });

        workbook.sheet(0).range(1, 1, 1, 2).merged(true);
        workbook.sheet(0).range(1, 3, 1, 4).merged(true);
        workbook.sheet(0).range(1, 5, 1, 6).merged(true);

        workbook.sheet(0).range(tableTitleGroupStartRow, 3, tableTitleGroupEndRow, 4).merged(true);
        workbook.sheet(0).range(tableTitleGroupStartRow, 5, tableTitleGroupEndRow, 7).merged(true);
        workbook.sheet(0).range(tableTitleGroupStartRow, 8, tableTitleGroupEndRow, 9).merged(true);
        workbook.sheet(0).range(tableTitleGroupStartRow, 10, tableTitleGroupEndRow, 12).merged(true);

        [15, 20, 10, 20, 10, 15, 20, 10, 10, 10, 10, 20].forEach((width, i) => {
            workbook
                .sheet(0)
                .column(i + 1)
                .width(width);
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('FuelConsumptionBar.fuelConsumption')} ${fromStrFormat} - ${toStrFormat}.xlsx`);
    }
}
