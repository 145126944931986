/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TripDriver,
    TripDriverFromJSON,
    TripDriverFromJSONTyped,
    TripDriverToJSON,
    TripMetadata,
    TripMetadataFromJSON,
    TripMetadataFromJSONTyped,
    TripMetadataToJSON,
    TripPlace,
    TripPlaceFromJSON,
    TripPlaceFromJSONTyped,
    TripPlaceToJSON,
    TripTemperatureSensor,
    TripTemperatureSensorFromJSON,
    TripTemperatureSensorFromJSONTyped,
    TripTemperatureSensorToJSON,
    TripTrailer,
    TripTrailerFromJSON,
    TripTrailerFromJSONTyped,
    TripTrailerToJSON,
} from './';

/**
 * 
 * @export
 * @interface TripInDatabase
 */
export interface TripInDatabase {
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    monitoringDeviceId?: string;
    /**
     * 
     * @type {Date}
     * @memberof TripInDatabase
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TripInDatabase
     */
    endTime?: Date;
    /**
     * 
     * @type {TripPlace}
     * @memberof TripInDatabase
     */
    placeStart?: TripPlace;
    /**
     * 
     * @type {TripPlace}
     * @memberof TripInDatabase
     */
    placeEnd?: TripPlace;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    odometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canOdometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsOdometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    odometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canOdometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsOdometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    totalConsumption?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TripInDatabase
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {Array<TripDriver>}
     * @memberof TripInDatabase
     */
    drivers?: Array<TripDriver>;
    /**
     * 
     * @type {Array<TripDriver>}
     * @memberof TripInDatabase
     */
    passengers?: Array<TripDriver>;
    /**
     * 
     * @type {boolean}
     * @memberof TripInDatabase
     */
    closed?: boolean;
    /**
     * 
     * @type {TripMetadata}
     * @memberof TripInDatabase
     */
    metadata?: TripMetadata;
    /**
     * 
     * @type {TripTrailer}
     * @memberof TripInDatabase
     */
    trailer?: TripTrailer;
    /**
     * 
     * @type {Array<TripTemperatureSensor>}
     * @memberof TripInDatabase
     */
    temperatureSensors?: Array<TripTemperatureSensor>;
    /**
     * 
     * @type {Array<TripTemperatureSensor>}
     * @memberof TripInDatabase
     */
    temperatureSensorsInstalled?: Array<TripTemperatureSensor>;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    fuelType?: string;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    consumptionLiters100km?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    co2Emission?: number;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    activityIntervalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof TripInDatabase
     */
    odometerSnapshotEndTimestamp?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof TripInDatabase
     */
    trailerActivityIntervalIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    idNew?: string;
}

export function TripInDatabaseFromJSON(json: any): TripInDatabase {
    return TripInDatabaseFromJSONTyped(json, false);
}

export function TripInDatabaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripInDatabase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': !exists(json, 'monitoredObjectId') ? undefined : json['monitoredObjectId'],
        'monitoringDeviceId': !exists(json, 'monitoringDeviceId') ? undefined : json['monitoringDeviceId'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'placeStart': !exists(json, 'placeStart') ? undefined : TripPlaceFromJSON(json['placeStart']),
        'placeEnd': !exists(json, 'placeEnd') ? undefined : TripPlaceFromJSON(json['placeEnd']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'canDistance': !exists(json, 'canDistance') ? undefined : json['canDistance'],
        'gpsDistance': !exists(json, 'gpsDistance') ? undefined : json['gpsDistance'],
        'odometerStart': !exists(json, 'odometerStart') ? undefined : json['odometerStart'],
        'canOdometerStart': !exists(json, 'canOdometerStart') ? undefined : json['canOdometerStart'],
        'gpsOdometerStart': !exists(json, 'gpsOdometerStart') ? undefined : json['gpsOdometerStart'],
        'odometerEnd': !exists(json, 'odometerEnd') ? undefined : json['odometerEnd'],
        'canOdometerEnd': !exists(json, 'canOdometerEnd') ? undefined : json['canOdometerEnd'],
        'gpsOdometerEnd': !exists(json, 'gpsOdometerEnd') ? undefined : json['gpsOdometerEnd'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'totalConsumption': !exists(json, 'totalConsumption') ? undefined : json['totalConsumption'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'drivers': !exists(json, 'drivers') ? undefined : ((json['drivers'] as Array<any>).map(TripDriverFromJSON)),
        'passengers': !exists(json, 'passengers') ? undefined : ((json['passengers'] as Array<any>).map(TripDriverFromJSON)),
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'metadata': !exists(json, 'metadata') ? undefined : TripMetadataFromJSON(json['metadata']),
        'trailer': !exists(json, 'trailer') ? undefined : TripTrailerFromJSON(json['trailer']),
        'temperatureSensors': !exists(json, 'temperatureSensors') ? undefined : ((json['temperatureSensors'] as Array<any>).map(TripTemperatureSensorFromJSON)),
        'temperatureSensorsInstalled': !exists(json, 'temperatureSensorsInstalled') ? undefined : ((json['temperatureSensorsInstalled'] as Array<any>).map(TripTemperatureSensorFromJSON)),
        'fuelType': !exists(json, 'fuel_type') ? undefined : json['fuel_type'],
        'consumptionLiters100km': !exists(json, 'consumption_liters_100km') ? undefined : json['consumption_liters_100km'],
        'co2Emission': !exists(json, 'co2_emission') ? undefined : json['co2_emission'],
        'activityIntervalId': !exists(json, 'activity_interval_id') ? undefined : json['activity_interval_id'],
        'odometerSnapshotEndTimestamp': !exists(json, 'odometerSnapshotEndTimestamp') ? undefined : (new Date(json['odometerSnapshotEndTimestamp'])),
        'trailerActivityIntervalIds': !exists(json, 'trailer_activity_interval_ids') ? undefined : json['trailer_activity_interval_ids'],
        'id': json['id'],
        'idNew': !exists(json, 'id_new') ? undefined : json['id_new'],
    };
}

export function TripInDatabaseToJSON(value?: TripInDatabase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoredObjectId': value.monitoredObjectId,
        'monitoringDeviceId': value.monitoringDeviceId,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'placeStart': TripPlaceToJSON(value.placeStart),
        'placeEnd': TripPlaceToJSON(value.placeEnd),
        'distance': value.distance,
        'canDistance': value.canDistance,
        'gpsDistance': value.gpsDistance,
        'odometerStart': value.odometerStart,
        'canOdometerStart': value.canOdometerStart,
        'gpsOdometerStart': value.gpsOdometerStart,
        'odometerEnd': value.odometerEnd,
        'canOdometerEnd': value.canOdometerEnd,
        'gpsOdometerEnd': value.gpsOdometerEnd,
        'duration': value.duration,
        'totalConsumption': value.totalConsumption,
        'isPrivate': value.isPrivate,
        'drivers': value.drivers === undefined ? undefined : ((value.drivers as Array<any>).map(TripDriverToJSON)),
        'passengers': value.passengers === undefined ? undefined : ((value.passengers as Array<any>).map(TripDriverToJSON)),
        'closed': value.closed,
        'metadata': TripMetadataToJSON(value.metadata),
        'trailer': TripTrailerToJSON(value.trailer),
        'temperatureSensors': value.temperatureSensors === undefined ? undefined : ((value.temperatureSensors as Array<any>).map(TripTemperatureSensorToJSON)),
        'temperatureSensorsInstalled': value.temperatureSensorsInstalled === undefined ? undefined : ((value.temperatureSensorsInstalled as Array<any>).map(TripTemperatureSensorToJSON)),
        'fuel_type': value.fuelType,
        'consumption_liters_100km': value.consumptionLiters100km,
        'co2_emission': value.co2Emission,
        'activity_interval_id': value.activityIntervalId,
        'odometerSnapshotEndTimestamp': value.odometerSnapshotEndTimestamp === undefined ? undefined : (value.odometerSnapshotEndTimestamp.toISOString()),
        'trailer_activity_interval_ids': value.trailerActivityIntervalIds,
        'id': value.id,
        'id_new': value.idNew,
    };
}


