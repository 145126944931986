/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyCurrencyConversionSerializer,
    ReadOnlyCurrencyConversionSerializerFromJSON,
    ReadOnlyCurrencyConversionSerializerToJSON,
    WriteOnlyCurrencyConversionSerializer,
    WriteOnlyCurrencyConversionSerializerFromJSON,
    WriteOnlyCurrencyConversionSerializerToJSON,
} from '../models';

export interface CurrencyConversionCreateRequest {
    data: WriteOnlyCurrencyConversionSerializer;
}

export interface CurrencyConversionDeleteRequest {
    id: string;
}

export interface CurrencyConversionListRequest {
    currencyOrigin?: string;
    currencyForeign?: string;
    date?: string;
    dateBefore?: string;
    dateAfter?: string;
}

export interface CurrencyConversionPartialUpdateRequest {
    id: string;
    data: WriteOnlyCurrencyConversionSerializer;
}

export interface CurrencyConversionReadRequest {
    id: string;
}

export interface CurrencyConversionUpdateRequest {
    id: string;
    data: WriteOnlyCurrencyConversionSerializer;
}

/**
 * no description
 */
export class CurrencyConversionApi extends runtime.BaseAPI {

    /**
     */
    async currencyConversionCreateRaw(requestParameters: CurrencyConversionCreateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrencyConversionSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyConversionCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyConversionSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyConversionSerializerFromJSON(jsonValue));
    }

    /**
     */
    async currencyConversionCreate(requestParameters: CurrencyConversionCreateRequest): Promise<ReadOnlyCurrencyConversionSerializer> {
        const response = await this.currencyConversionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyConversionDeleteRaw(requestParameters: CurrencyConversionDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyConversionDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async currencyConversionDelete(requestParameters: CurrencyConversionDeleteRequest): Promise<void> {
        await this.currencyConversionDeleteRaw(requestParameters);
    }

    /**
     */
    async currencyConversionListRaw(requestParameters: CurrencyConversionListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyCurrencyConversionSerializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.currencyOrigin !== undefined) {
            queryParameters['currency_origin'] = requestParameters.currencyOrigin;
        }

        if (requestParameters.currencyForeign !== undefined) {
            queryParameters['currency_foreign'] = requestParameters.currencyForeign;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyCurrencyConversionSerializerFromJSON));
    }

    /**
     */
    async currencyConversionList(requestParameters: CurrencyConversionListRequest): Promise<Array<ReadOnlyCurrencyConversionSerializer>> {
        const response = await this.currencyConversionListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyConversionLoadDailyCurrencyConversionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/load-daily-currency-conversions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async currencyConversionLoadDailyCurrencyConversions(): Promise<void> {
        await this.currencyConversionLoadDailyCurrencyConversionsRaw();
    }

    /**
     */
    async currencyConversionPartialUpdateRaw(requestParameters: CurrencyConversionPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrencyConversionSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyConversionPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyConversionPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyConversionSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyConversionSerializerFromJSON(jsonValue));
    }

    /**
     */
    async currencyConversionPartialUpdate(requestParameters: CurrencyConversionPartialUpdateRequest): Promise<ReadOnlyCurrencyConversionSerializer> {
        const response = await this.currencyConversionPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyConversionReadRaw(requestParameters: CurrencyConversionReadRequest): Promise<runtime.ApiResponse<ReadOnlyCurrencyConversionSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyConversionRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyConversionSerializerFromJSON(jsonValue));
    }

    /**
     */
    async currencyConversionRead(requestParameters: CurrencyConversionReadRequest): Promise<ReadOnlyCurrencyConversionSerializer> {
        const response = await this.currencyConversionReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyConversionUpdateRaw(requestParameters: CurrencyConversionUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrencyConversionSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyConversionUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyConversionUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency-conversion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyConversionSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyConversionSerializerFromJSON(jsonValue));
    }

    /**
     */
    async currencyConversionUpdate(requestParameters: CurrencyConversionUpdateRequest): Promise<ReadOnlyCurrencyConversionSerializer> {
        const response = await this.currencyConversionUpdateRaw(requestParameters);
        return await response.value();
    }

}
