/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20021,
    InlineResponse20021FromJSON,
    InlineResponse20021ToJSON,
    ReadOnlyMonitoredObjectGroupSharing,
    ReadOnlyMonitoredObjectGroupSharingFromJSON,
    ReadOnlyMonitoredObjectGroupSharingToJSON,
    WriteOnlyMonitoredObjectGroupSharing,
    WriteOnlyMonitoredObjectGroupSharingFromJSON,
    WriteOnlyMonitoredObjectGroupSharingToJSON,
} from '../models';

export interface MonitoredObjectGroupSharingCreateRequest {
    data: WriteOnlyMonitoredObjectGroupSharing;
}

export interface MonitoredObjectGroupSharingDeleteRequest {
    id: string;
}

export interface MonitoredObjectGroupSharingListRequest {
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupSharingPartialUpdateRequest {
    id: string;
    data: WriteOnlyMonitoredObjectGroupSharing;
}

export interface MonitoredObjectGroupSharingReadRequest {
    id: string;
}

export interface MonitoredObjectGroupSharingUpdateRequest {
    id: string;
    data: WriteOnlyMonitoredObjectGroupSharing;
}

/**
 * no description
 */
export class MonitoredObjectGroupSharingApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectGroupSharingCreateRaw(requestParameters: MonitoredObjectGroupSharingCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupSharing>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupSharingCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupSharingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupSharingFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupSharingCreate(requestParameters: MonitoredObjectGroupSharingCreateRequest): Promise<ReadOnlyMonitoredObjectGroupSharing> {
        const response = await this.monitoredObjectGroupSharingCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupSharingDeleteRaw(requestParameters: MonitoredObjectGroupSharingDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupSharingDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async monitoredObjectGroupSharingDelete(requestParameters: MonitoredObjectGroupSharingDeleteRequest): Promise<void> {
        await this.monitoredObjectGroupSharingDeleteRaw(requestParameters);
    }

    /**
     */
    async monitoredObjectGroupSharingListRaw(requestParameters: MonitoredObjectGroupSharingListRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupSharingList(requestParameters: MonitoredObjectGroupSharingListRequest): Promise<InlineResponse20021> {
        const response = await this.monitoredObjectGroupSharingListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupSharingPartialUpdateRaw(requestParameters: MonitoredObjectGroupSharingPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupSharing>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupSharingPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupSharingPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupSharingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupSharingFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupSharingPartialUpdate(requestParameters: MonitoredObjectGroupSharingPartialUpdateRequest): Promise<ReadOnlyMonitoredObjectGroupSharing> {
        const response = await this.monitoredObjectGroupSharingPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupSharingReadRaw(requestParameters: MonitoredObjectGroupSharingReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupSharing>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupSharingRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupSharingFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupSharingRead(requestParameters: MonitoredObjectGroupSharingReadRequest): Promise<ReadOnlyMonitoredObjectGroupSharing> {
        const response = await this.monitoredObjectGroupSharingReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupSharingUpdateRaw(requestParameters: MonitoredObjectGroupSharingUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupSharing>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupSharingUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupSharingUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-sharing/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupSharingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupSharingFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupSharingUpdate(requestParameters: MonitoredObjectGroupSharingUpdateRequest): Promise<ReadOnlyMonitoredObjectGroupSharing> {
        const response = await this.monitoredObjectGroupSharingUpdateRaw(requestParameters);
        return await response.value();
    }

}
