import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { Row, Col } from 'antd';

interface Props {
    className?: string;
    cancelQa?: string;
    cancelDisabled?: boolean;
    submitQa?: string;
    submitText?: string;
    submitLoading?: boolean;
    submitDisabled?: boolean;
    onCancelClick?: () => void;
    onSubmitClick?: () => void;
}

export default function FormActions({ ...props }: Props) {
    const { t } = useTranslation();

    return (
        <Row gutter={[12, 12]} className={`form-buttons ${props.className}`} justify="end">
            <Col>
                <Button disabled={props.cancelDisabled} qa={props.cancelQa} onClick={props.onCancelClick}>
                    {t('common.cancel')}
                </Button>
            </Col>
            <Col>
                <Button
                    loading={props.submitLoading}
                    disabled={props.submitDisabled}
                    type="primary"
                    htmlType="submit"
                    qa={props.submitQa}
                    onClick={props.onSubmitClick}
                >
                    {props.submitText ?? t('common.confirm')}
                </Button>
            </Col>
        </Row>
    );
}
