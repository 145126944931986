/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DIHPromotionPrice,
    DIHPromotionPriceFromJSON,
    DIHPromotionPriceFromJSONTyped,
    DIHPromotionPriceToJSON,
    DIHTotemPrice,
    DIHTotemPriceFromJSON,
    DIHTotemPriceFromJSONTyped,
    DIHTotemPriceToJSON,
} from './';

/**
 * 
 * @export
 * @interface DIHPriceListResult
 */
export interface DIHPriceListResult {
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    articleCode: string;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    locationCode: string;
    /**
     * 
     * @type {DIHPromotionPrice}
     * @memberof DIHPriceListResult
     */
    promotionPrice?: DIHPromotionPrice;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    salesCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof DIHPriceListResult
     */
    salesPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    billingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    serviceCurrency?: string;
    /**
     * 
     * @type {number}
     * @memberof DIHPriceListResult
     */
    salesPriceInServiceCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof DIHPriceListResult
     */
    salesPriceInBillingCurrency?: number;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    salesSubModelType?: string;
    /**
     * 
     * @type {DIHTotemPrice}
     * @memberof DIHPriceListResult
     */
    totemPrice?: DIHTotemPrice;
    /**
     * 
     * @type {string}
     * @memberof DIHPriceListResult
     */
    unit?: string;
}

export function DIHPriceListResultFromJSON(json: any): DIHPriceListResult {
    return DIHPriceListResultFromJSONTyped(json, false);
}

export function DIHPriceListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DIHPriceListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleCode': json['articleCode'],
        'countryCode': json['countryCode'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'locationCode': json['locationCode'],
        'promotionPrice': !exists(json, 'promotionPrice') ? undefined : DIHPromotionPriceFromJSON(json['promotionPrice']),
        'salesCurrency': json['salesCurrency'],
        'salesPrice': !exists(json, 'salesPrice') ? undefined : json['salesPrice'],
        'billingCurrency': !exists(json, 'billingCurrency') ? undefined : json['billingCurrency'],
        'serviceCurrency': !exists(json, 'serviceCurrency') ? undefined : json['serviceCurrency'],
        'salesPriceInServiceCurrency': !exists(json, 'salesPriceInServiceCurrency') ? undefined : json['salesPriceInServiceCurrency'],
        'salesPriceInBillingCurrency': !exists(json, 'salesPriceInBillingCurrency') ? undefined : json['salesPriceInBillingCurrency'],
        'salesSubModelType': !exists(json, 'salesSubModelType') ? undefined : json['salesSubModelType'],
        'totemPrice': !exists(json, 'totemPrice') ? undefined : DIHTotemPriceFromJSON(json['totemPrice']),
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function DIHPriceListResultToJSON(value?: DIHPriceListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleCode': value.articleCode,
        'countryCode': value.countryCode,
        'label': value.label,
        'locationCode': value.locationCode,
        'promotionPrice': DIHPromotionPriceToJSON(value.promotionPrice),
        'salesCurrency': value.salesCurrency,
        'salesPrice': value.salesPrice,
        'billingCurrency': value.billingCurrency,
        'serviceCurrency': value.serviceCurrency,
        'salesPriceInServiceCurrency': value.salesPriceInServiceCurrency,
        'salesPriceInBillingCurrency': value.salesPriceInBillingCurrency,
        'salesSubModelType': value.salesSubModelType,
        'totemPrice': DIHTotemPriceToJSON(value.totemPrice),
        'unit': value.unit,
    };
}


