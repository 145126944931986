/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PTVTollCost,
    PTVTollCostFromJSON,
    PTVTollCostFromJSONTyped,
    PTVTollCostToJSON,
    PlannedTransportPlaceCrossingPoints,
    PlannedTransportPlaceCrossingPointsFromJSON,
    PlannedTransportPlaceCrossingPointsFromJSONTyped,
    PlannedTransportPlaceCrossingPointsToJSON,
    PlannedTransportPlacePosition,
    PlannedTransportPlacePositionFromJSON,
    PlannedTransportPlacePositionFromJSONTyped,
    PlannedTransportPlacePositionToJSON,
    RouteCostsDetails,
    RouteCostsDetailsFromJSON,
    RouteCostsDetailsFromJSONTyped,
    RouteCostsDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PlannedTransportPlace
 */
export interface PlannedTransportPlace {
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlace
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlace
     */
    ptvRouteId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlace
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlace
     */
    departure: string;
    /**
     * The travel time for the route [s].
     * @type {number}
     * @memberof PlannedTransportPlace
     */
    duration?: number;
    /**
     * The distance of the route [m].
     * @type {number}
     * @memberof PlannedTransportPlace
     */
    distance?: number;
    /**
     * 
     * @type {PlannedTransportPlacePosition}
     * @memberof PlannedTransportPlace
     */
    position: PlannedTransportPlacePosition;
    /**
     * 
     * @type {string}
     * @memberof PlannedTransportPlace
     */
    route?: string;
    /**
     * 
     * @type {PTVTollCost}
     * @memberof PlannedTransportPlace
     */
    tollCost?: PTVTollCost;
    /**
     * 
     * @type {RouteCostsDetails}
     * @memberof PlannedTransportPlace
     */
    routeCostsDetails?: RouteCostsDetails;
    /**
     * 
     * @type {Array<PlannedTransportPlaceCrossingPoints>}
     * @memberof PlannedTransportPlace
     */
    crossingPoints?: Array<PlannedTransportPlaceCrossingPoints>;
}

export function PlannedTransportPlaceFromJSON(json: any): PlannedTransportPlace {
    return PlannedTransportPlaceFromJSONTyped(json, false);
}

export function PlannedTransportPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlannedTransportPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ptvRouteId': !exists(json, 'ptvRouteId') ? undefined : json['ptvRouteId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'departure': json['departure'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'position': PlannedTransportPlacePositionFromJSON(json['position']),
        'route': !exists(json, 'route') ? undefined : json['route'],
        'tollCost': !exists(json, 'tollCost') ? undefined : PTVTollCostFromJSON(json['tollCost']),
        'routeCostsDetails': !exists(json, 'routeCostsDetails') ? undefined : RouteCostsDetailsFromJSON(json['routeCostsDetails']),
        'crossingPoints': !exists(json, 'crossingPoints') ? undefined : ((json['crossingPoints'] as Array<any>).map(PlannedTransportPlaceCrossingPointsFromJSON)),
    };
}

export function PlannedTransportPlaceToJSON(value?: PlannedTransportPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ptvRouteId': value.ptvRouteId,
        'name': value.name,
        'departure': value.departure,
        'duration': value.duration,
        'distance': value.distance,
        'position': PlannedTransportPlacePositionToJSON(value.position),
        'route': value.route,
        'tollCost': PTVTollCostToJSON(value.tollCost),
        'routeCostsDetails': RouteCostsDetailsToJSON(value.routeCostsDetails),
        'crossingPoints': value.crossingPoints === undefined ? undefined : ((value.crossingPoints as Array<any>).map(PlannedTransportPlaceCrossingPointsToJSON)),
    };
}


