import { Subject, Observable, Subscription, interval } from 'rxjs';
import { buffer } from 'rxjs/operators';
import {
    CustomPlace,
    CustomPlaceCrossingTstrWithCustomPlaceData,
    CustomPlaceCrossingType,
    FuelStationPriceList,
    PlaceOfWorkInDB
} from 'generated/backend-api';
import { ReadOnlyCurrency } from 'generated/new-main';
import enhanceFetchWithRetry from 'fetch-retry';

import { Auth } from './auth';
import { Logic } from './logic';
import {
    PoiModelMap,
    FuelTypes,
    FuelStationServicesTranslations,
    fuelToFuelTypeMap,
    serviceToTypeMap
} from './map/logic/fuelStations';
import { Fuels, Services } from '../modules/map/MapModule';
import { centerToLatLngObject } from '../common/utils/geo-utils';
import * as faker from 'faker';
import moment from 'moment';
import {
    PointSuggestionsDocument,
    PointSuggestionsQuery,
    PointSuggestionsQueryVariables,
    PlaceSuggestion,
    CustomPlaceUpdateInput,
    Update_CustomPlaceMutation,
    Update_CustomPlaceMutationVariables,
    Update_CustomPlaceDocument,
    Add_CustomPlaceMutation,
    Add_CustomPlaceMutationVariables,
    Add_CustomPlaceDocument,
    CustomPlaceAddInput
} from 'generated/graphql';
import { AvailableCurrencies } from 'common/model/currency';
import { getClientCurrencyNameOrEUR, getClientCurrencyOrEUR } from 'common/utils/best-price';

const fetchRetry = enhanceFetchWithRetry(fetch);

function randomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

interface PlacesConfig {
    url: string;
}

interface FuelStationModel {
    _id: string;
    center: {
        type: string;
        coordinates: [number, number];
    };
    externalId: string;
    name: string;
    services: string[];
    fuelTypes: string[];
}

interface ParkingLotModel {
    _id: string;
    center: {
        type: string;
        coordinates: [number, number];
    };
    name: string;
}

export class PoiLogic {
    private _fuelStations?: PoiModelMap[];
    private _parkings?: PoiModelMap[];
    private _pois?: PoiModelMap[];
    private _washers?: PoiModelMap[];
    private _conf: PlacesConfig;
    private _auth: Auth;
    private _priceList?: FuelStationPriceList[];
    private _fuelStationsWithPrices?: PoiModelMap[];
    private _logic: Logic;
    private _suggestionSearchText?: string;
    fuelStationsLoaded: Subject<boolean>;
    parkingsLoaded: Subject<boolean>;
    poisLoaded: Subject<boolean>;
    washersLoaded: Subject<boolean>;
    currencies?: ReadOnlyCurrency[];
    pricesUpdatesSubject: Subject<PoiModelMap[]>;
    poisSubscriptionData?: Subscription;

    manualPlaceOfWorkCrossingLoading: Subject<boolean>;
    manualPlaceOfWorkCrossingData: Subject<CustomPlaceCrossingTstrWithCustomPlaceData[]>;
    manualPlaceOfWorkCrossingError: Subject<any>;

    constructor(conf: PlacesConfig, auth: Auth, logic: Logic) {
        this._conf = conf;
        this._auth = auth;
        this._logic = logic;

        this.pricesUpdatesSubject = new Subject<PoiModelMap[]>();
        this.fuelStationsLoaded = new Subject<boolean>();
        this.parkingsLoaded = new Subject<boolean>();
        this.poisLoaded = new Subject<boolean>();
        this.washersLoaded = new Subject<boolean>();

        this.manualPlaceOfWorkCrossingLoading = new Subject<boolean>();
        this.manualPlaceOfWorkCrossingData = new Subject<CustomPlaceCrossingTstrWithCustomPlaceData[]>();
        this.manualPlaceOfWorkCrossingError = new Subject<any>();
    }

    init() {
        const params = {
            clientId: this._logic.auth().client()?.id!,
            subscribeDevice: this._logic.notification().device,
            subscribeUser: this._logic.auth().keycloak.tokenParsed?.['sub']
        };
        this._logic.api().fuelStationsApi.subscribeV1FuelstationsPricesSubscribeGet(params);

        this._logic
            .notification()
            .onConnect()
            .subscribe(async () => {
                const params = {
                    clientId: this._logic.auth().client()?.id!,
                    subscribeDevice: this._logic.notification().device,
                    subscribeUser: this._logic.auth().keycloak.tokenParsed?.['sub']
                };
                await this._logic.api().fuelStationsApi.subscribeV1FuelstationsPricesSubscribeGet(params);
            });

        this.watchPricesUpdates();
    }

    watchPricesUpdates() {
        const priceUpdate$ = new Observable<FuelStationPriceList[]>(subscriber => {
            this._logic.notification().on('prices-update', n => {
                if (n.data && Array.isArray(n.data)) {
                    const pricesUpdate = n.data as FuelStationPriceList[];
                    subscriber.next(pricesUpdate);
                }
            });
        });

        const buffered$ = priceUpdate$.pipe(buffer(interval(10e3)));

        buffered$.subscribe(data => {
            const flat = data.flat();
            const updates = flat.reduce<FuelStationPriceList[]>((list, current) => {
                if (!list.some(e => e.locationCode === current.locationCode && e.productId === current.productId)) {
                    list.push(current);
                }
                return list;
            }, []);

            console.debug('[FS prices] update', updates);

            if (updates.length > 0) {
                this.updatePricelist(updates);
                const pois = this.fuelStationsWithPrices();
                this.pricesUpdatesSubject.next(pois);
            }
        });
    }

    /**
     * Update price list with partial data & returns updated fuelstations
     */
    updatePricelist(updates: FuelStationPriceList[] = []): PoiModelMap[] {
        const updatedFuelStations: PoiModelMap[] = [];

        if (updates.length === 0) {
            return [];
        }

        if (!this._fuelStations) {
            return [];
        }

        if (!this._priceList) {
            this._priceList = [];
        }

        updates.forEach(u => {
            if (this._priceList) {
                const index = this._priceList.findIndex(item => item.id === u.id);
                if (index !== -1) {
                    this._priceList[index] = u;
                } else {
                    this._priceList?.push(u);
                }
            }
        });

        const pricelistMap: { [key: string]: FuelStationPriceList[] } = {};

        for (let i = 0; i < this._priceList.length; i++) {
            const pricelist = this._priceList[i];
            if (pricelist.locationCode) {
                const oldData = pricelistMap[pricelist.locationCode] ?? [];
                pricelistMap[pricelist.locationCode] = oldData.concat(pricelist);
            }
        }
        this._fuelStationsWithPrices = (this._fuelStations ?? []).map(fs => {
            const fuelTypes = fs.fuelTypes ?? [];
            const pricelist = pricelistMap[fs.externalId!] ?? [];
            pricelist.forEach(pl => {
                const foundFuelType = fuelTypes.findIndex(e => e.code === pl.productId);
                if (foundFuelType !== -1) {
                    fuelTypes[foundFuelType] = {
                        ...fuelTypes[foundFuelType],
                        price: {
                            price: getClientCurrencyOrEUR(pl, this._logic.auth().newClient()?.currency),
                            source: pl.source || '',
                            currency: getClientCurrencyNameOrEUR(
                                pl.currency as AvailableCurrencies,
                                this._logic.auth().newClient()?.currency
                            ),
                            type: pl.type || '',
                            productId: pl.productId || '',
                            sumExtraFees: pl.sumExtraFees || ''
                        }
                    };
                } else {
                    fuelTypes.push({
                        code: pl.productId ?? '',
                        name: pl.productId ? FuelTypes[pl.productId] : '',
                        price: {
                            price: getClientCurrencyOrEUR(pl, this._logic.auth().newClient()?.currency),
                            source: pl.source || '',
                            currency: getClientCurrencyNameOrEUR(
                                pl.currency as AvailableCurrencies,
                                this._logic.auth().newClient()?.currency
                            ),
                            type: pl.type || '',
                            productId: pl.productId || '',
                            sumExtraFees: pl.sumExtraFees || ''
                        }
                    });
                }
            });

            const updatedFs: PoiModelMap = {
                ...fs,
                fuelTypes
            };
            if (updates.some(u => u.locationCode === updatedFs.externalId)) {
                updatedFuelStations.push(updatedFs);
            }
            return updatedFs;
        });

        return updatedFuelStations;
    }

    fuelStationsPrices() {
        return new Promise(resolve => {
            if (this._logic.demo().isActive) {
                resolve([]);
            } else {
                if (this._priceList) {
                    resolve(this._priceList);
                } else {
                    this._logic
                        .api()
                        .fuelStationsApi.getAllPricesV1FuelstationsPricesAvailableGet()
                        .then(priceList => {
                            this._priceList = priceList;
                            resolve(this._priceList);
                        })
                        .catch(() => {
                            this._priceList = [];
                            resolve(this._priceList);
                        });
                }
            }
        });
    }

    unselectFuelStations(): void {
        this._fuelStationsWithPrices = this._fuelStationsWithPrices?.map(fuel => ({
            ...fuel,
            selected: false
        }));
    }

    fuelStationsWithPrices(): PoiModelMap[] {
        if (this._fuelStationsWithPrices && this._fuelStationsWithPrices.length !== 0) {
            return this._fuelStationsWithPrices;
        }
        this._fuelStationsWithPrices = (this._fuelStations ?? []).map(fs => {
            return {
                ...fs,
                fuelTypes: (fs.fuelTypes ?? []).map(type => {
                    const fuelTypePrice = this._priceList?.find(
                        p => p.locationCode === fs.externalId && type.code === p.productId
                    );
                    return {
                        ...type,
                        ...(fuelTypePrice
                            ? {
                                  price: {
                                      price: getClientCurrencyOrEUR(
                                          fuelTypePrice,
                                          this._logic.auth().newClient()?.currency
                                      ),
                                      source: fuelTypePrice.source || '',
                                      currency: getClientCurrencyNameOrEUR(
                                          fuelTypePrice.currency as AvailableCurrencies,
                                          this._logic.auth().newClient()?.currency
                                      ),
                                      type: fuelTypePrice.type || '',
                                      productId: fuelTypePrice.productId || '',
                                      sumExtraFees: fuelTypePrice.sumExtraFees || ''
                                  }
                              }
                            : {})
                    };
                })
            };
        });

        return this._fuelStationsWithPrices;
    }

    fuelStations(): Promise<PoiModelMap[]> {
        if (this._logic.demo().isActive) {
            return new Promise(resolve => {
                fetch('/fuel-stations.json')
                    .then(r => r.json())
                    .then((r: { data: { fuelStations: FuelStationModel[] } }) => {
                        if (this._fuelStations) {
                            resolve(this._fuelStations);
                        } else {
                            this._fuelStations = r.data.fuelStations.map(fs => this._toPoiModel(fs));
                            if (this._logic.demo().isActive) {
                                this._priceList = this._fuelStations?.map(fs => ({
                                    countryCode: 'CZ',
                                    currency: AvailableCurrencies.EUR,
                                    customerNumber: '71945',
                                    date: new Date(),
                                    extraFees: [],
                                    id: '5fd81354487cf5989668bcc1',
                                    last_time_updated: '2021-01-10T01:41:50.362000',
                                    locationCode: fs.externalId,
                                    price: String(randomNumber(1, 1.4)),
                                    productId: '010003',
                                    source: 'EW CZ TP',
                                    sumExtraFees: '0',
                                    type: 'COST+',
                                    name: ''
                                }));
                            }
                            this.fuelStationsLoaded.next(true);
                            resolve(this._fuelStations);
                        }
                    });
            });
        }

        const token = this._auth.token();
        return new Promise<PoiModelMap[]>(resolve => {
            if (this._fuelStations) {
                this._fuelStations = this._fuelStations.map(f => ({
                    ...f,
                    selected: false
                }));
                resolve(this._fuelStations);
            } else {
                const testOwnerHeader = this._ownerHeader();
                fetchRetry(`${this._conf.url}/places/get-fuel-stations`, {
                    method: 'GET',
                    headers: {
                        ...testOwnerHeader,
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    retryOn: (attempt, error, response) => {
                        if (
                            error !== null ||
                            (response && response.status >= 400) ||
                            (response as any).data?.fuelStations.length > 0
                        ) {
                            console.log(`FuelStations retrying, attempt number ${attempt + 1}`);
                            return true;
                        }

                        return false;
                    },
                    retryDelay: (attempt: number) => {
                        return Math.pow(2, attempt) * 2000;
                    },
                    retries: 10
                })
                    .then(r => r.json())
                    .then(r => {
                        const fuelstations = ((r.data.fuelStations as FuelStationModel[])! || []).map<PoiModelMap>(fs =>
                            this._toPoiModel(fs)
                        );
                        this._fuelStations = fuelstations;
                        this.fuelStationsLoaded.next(true);
                        resolve(fuelstations);
                    });
            }
        });
    }

    parkings(): Promise<PoiModelMap[]> {
        if (this._logic.demo().isActive) {
            return new Promise(resolve => {
                fetch('/parkings.json')
                    .then(r => r.json())
                    .then((r: { data: { parkingLots: ParkingLotModel[] } }) => {
                        if (this._parkings) {
                            resolve(this._parkings);
                        } else {
                            this._parkings = r.data.parkingLots.map(fs => ({
                                id: fs._id || '',
                                name: fs.name || '',
                                selected: false,
                                position: centerToLatLngObject(fs.center.coordinates)
                            }));
                            this.parkingsLoaded.next(true);
                            resolve(this._parkings);
                        }
                    });
            });
        }

        return new Promise(resolve => {
            const token = this._auth.token();
            if (this._parkings) {
                this._parkings = this._parkings.map(p => ({
                    ...p,
                    selected: false
                }));
                resolve(this._parkings);
            } else {
                const testOwnerHeader = this._ownerHeader();
                fetchRetry(`${this._conf.url}/places/get-parking-lots`, {
                    method: 'GET',
                    headers: {
                        ...testOwnerHeader,
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    retryOn: (attempt, error, response) => {
                        if (
                            error !== null ||
                            (response && response.status >= 400) ||
                            (response as any)?.data?.parkingLots.length > 0
                        ) {
                            console.log(`ParkingLots retrying, attempt number ${attempt + 1}`);
                            return true;
                        }

                        return false;
                    },
                    retryDelay: attempt => {
                        return Math.pow(2, attempt) * 2000;
                    },
                    retries: 10
                })
                    .then(r => r.json())
                    .then(r => {
                        const parkingLots = ((r.data.parkingLots as ParkingLotModel[])! || []).map<PoiModelMap>(fs => ({
                            id: fs._id || '',
                            name: fs.name || '',
                            selected: false,
                            position: centerToLatLngObject(fs.center.coordinates)
                        }));
                        this._parkings = parkingLots;
                        this.parkingsLoaded.next(true);
                        resolve(parkingLots);
                    });
            }
        });
    }

    parkingsDetail(ids: [string]): PoiModelMap[] {
        if (this._parkings) {
            const parkingsDetail = this._parkings.filter(p => ids.includes(p.id));

            parkingsDetail.forEach(fsd => {
                if (this._parkings) {
                    this._parkings = this._parkings.map(fs => {
                        return fs.id === fsd.id ? { ...fsd, selected: true } : { ...fs, selected: false };
                    });
                } else {
                    this._parkings = parkingsDetail;
                }
            });
            return this._parkings;
        }
        return [];
    }

    setWashersInDetail(ids: [string]): PoiModelMap[] {
        if (this._washers) {
            const washersDetail = this._washers.filter(w => ids.includes(w.id));

            washersDetail.forEach(ws => {
                if (this._washers) {
                    this._washers = this._washers.map(w => {
                        return w.id === ws.id ? { ...ws, selected: true } : { ...w, selected: false };
                    });
                } else {
                    this._washers = washersDetail;
                }
            });
            return this._washers;
        }
        return [];
    }

    poisDetail(ids: [string]): PoiModelMap[] {
        if (this._pois) {
            const poisDetail = this._pois.filter(p => ids.includes(p.id));

            poisDetail.forEach(ps => {
                if (this._pois) {
                    this._pois = this._pois.map(p => {
                        return p.id === ps.id ? { ...ps, selected: true } : { ...p, selected: false };
                    });
                } else {
                    this._pois = poisDetail;
                }
            });
            return this._pois;
        }
        return [];
    }

    async exchangeRate(): Promise<boolean> {
        try {
            if (this._logic.demo().isActive) {
                this.currencies = this._logic.demo().data.currencies;
                this._logic.map().routing().setCurrencies(this.currencies);
                this._logic.map().fuelStations().setCurrencies(this.currencies);
            } else {
                this.currencies = (await this._logic.api().currencyApi.currencyList({})).results;
                this._logic.map().routing().setCurrencies(this.currencies);
                this._logic.map().fuelStations().setCurrencies(this.currencies);
            }
            return true;
        } catch (err) {
            console.error('Cannot download currencyList err:', err);
            return false;
        }
    }

    async washers(): Promise<PoiModelMap[]> {
        try {
            if (this._washers) {
                return this._washers;
            } else {
                const responseWashers = await this._logic.api().travisApi.getV1TravisGet();
                const washers: PoiModelMap[] = Object.values(responseWashers).map((d: any) => ({
                    id: 'travis' + String(d.id),
                    name: d.name ?? '',
                    detailAddress: `${d.address?.country}, ${d.address?.city}, ${d.address?.address}`,
                    selected: false,
                    openingHours: {
                        monday: d.truckwash?.openinghours?.monday ?? '',
                        tuesday: d.truckwash?.openinghours?.tuesday ?? '',
                        wednesday: d.truckwash?.openinghours?.wednesday ?? '',
                        thursday: d.truckwash?.openinghours?.thursday ?? '',
                        friday: d.truckwash?.openinghours?.friday ?? '',
                        saturday: d.truckwash?.openinghours?.saturday ?? '',
                        sunday: d.truckwash?.openinghours?.sunday ?? ''
                    },
                    position: {
                        lat: Number(d.address?.latitude) || 0,
                        lng: Number(d.address?.longitude) || 0
                    }
                }));
                this._washers = washers;
                this.washersLoaded.next(true);
                return this._washers;
            }
        } catch (err) {
            console.error('Travis Car Washers err:', err);
            this._washers = [];
            throw err;
        }
    }

    pois(forceRefresh: boolean = false): Promise<PoiModelMap[]> {
        return new Promise(resolve => {
            if (this._pois && !forceRefresh) {
                resolve(this._pois);
            } else {
                this.poisSubscriptionData = this._logic.dispatcherKitPoi().data.subscribe(data => {
                    const pois = data.map<PoiModelMap>(poi => ({
                        id: poi.id || '',
                        name: poi.name || '',
                        detailAddress: poi.address,
                        selected: false,
                        position: {
                            lat: poi.center?.lat ?? 0,
                            lng: poi.center?.lng ?? 0
                        },
                        notes: poi.notes,
                        forbidden: poi.forbidden,
                        type: poi.type
                    }));
                    this._pois = pois;

                    if (this._pois) {
                        this.poisLoaded.next(true);
                        resolve(this._pois);
                    }
                });

                this._logic.dispatcherKitPoi().loadData();
            }
        });
    }

    filterFuelStations = (fuelStations: PoiModelMap[], fuels: Fuels, services: Services): PoiModelMap[] => {
        let data: PoiModelMap[] = fuelStations;

        const fuelFilter = Object.values(fuels).some(e => e);
        const serviceFilter = Object.values(services).some(e => e);

        // Filtering logic based on serviceToTypeMap & fuelToFuelTypeMap maps
        if (fuelFilter) {
            const fsKeys = Object.entries(fuels).reduce<string[][]>((acc, [key, value]) => {
                if (value) {
                    acc.push(fuelToFuelTypeMap[key]);
                }
                return acc;
            }, []);

            data = data.filter(fs => {
                return fsKeys.some(keySet => {
                    const included = keySet.some(key => (fs.fuelTypes || []).map(e => e.code).includes(key));
                    return included;
                });
            });
        }

        if (serviceFilter) {
            const svKeys = Object.entries(services).reduce<string[][]>((acc, [key, value]) => {
                if (value) {
                    acc.push(serviceToTypeMap[key]);
                }
                return acc;
            }, []);

            data = data.filter(fs => {
                return svKeys.every(keySet => {
                    const included = keySet.some(key => (fs.services || []).map(e => e.code).includes(key));
                    return included;
                });
            });
        }

        return data;
    };

    async getPoiByName(): Promise<PoiModelMap[]> {
        try {
            if (this._logic.demo().isActive) {
                return [].map(placeOfWork => this._toPoiModel(placeOfWork));
            } else {
                // const response = await this._logic.api()..placeOfWorkListV1PlacesPlaceOfWorkGet({
                //     userId: userId
                // });
                // return response.map(placeOfWork => this._toPoiModel(placeOfWork));
                return mockedPlacesOfWork;
            }
        } catch (err) {
            console.log('PlaceOfWork list for user err:', err);
            throw err;
        }
    }

    async getCustomPlaces(): Promise<CustomPlace[]> {
        try {
            if (this._logic.demo().isActive) {
                return [];
            } else {
                const response = await this._logic.api().customPlaceApi.customPlaceFindV1PlacesCustomPlaceGet({});
                return response;
            }
        } catch (err) {
            console.error('PlaceOfWork list for customPlaceA err:', err);
            throw err;
        }
    }

    async createCustomPlace(placeData: CustomPlaceAddInput): Promise<void> {
        try {
            await this._logic.apollo().mutate<Add_CustomPlaceMutation, Add_CustomPlaceMutationVariables>({
                mutation: Add_CustomPlaceDocument,
                variables: { placeData }
            });
        } catch (err) {
            console.error(`Custom place create failed, err: ${err}`);
            throw err;
        }
    }

    async updateCustomPlace(placeData: CustomPlaceUpdateInput): Promise<void> {
        try {
            await this._logic.apollo().mutate<Update_CustomPlaceMutation, Update_CustomPlaceMutationVariables>({
                mutation: Update_CustomPlaceDocument,
                variables: { placeData }
            });
        } catch (err) {
            console.error(`Custom place update failed, err: ${err}`);
            throw err;
        }
    }

    async getCustomPlaceDetail(poiId: string): Promise<CustomPlace[]> {
        try {
            if (this._logic.demo().isActive) {
                return [];
            } else {
                const response = await this._logic.api().customPlaceApi.customPlaceFindV1PlacesCustomPlaceGet({
                    id: poiId
                });
                return response;
            }
        } catch (err) {
            console.error('PlaceOfWork list for customPlace err:', err);
            throw err;
        }
    }

    async getUserCustomPlaces(userId: number): Promise<CustomPlace[]> {
        try {
            if (this._logic.demo().isActive) {
                return this._logic
                    .demo()
                    .data.diemsPlacesOfWork.filter(d => d.placeOfWork && d.placeOfWork?.[0].userId === userId);
            } else {
                const response = await this._logic.api().placeOfWorkApi.placeOfWorkListV1PlacesPlaceOfWorkGet({
                    userIds: [userId]
                });
                return response;
            }
        } catch (err) {
            console.log('PlaceOfWork list for user err:', err);
            throw err;
        }
    }

    async addUserPlacesOfWork(userId: number, placeIds: string[]): Promise<PlaceOfWorkInDB[]> {
        try {
            const response = await this._logic.api().placeOfWorkApi.placeOfWorkCreateV1PlacesPlaceOfWorkPost({
                driversToCustomPlaces: {
                    listOfCustomPlaceIds: placeIds,
                    listOfUserIds: [userId],
                    start: moment().startOf('day').toDate()
                }
            });
            return response;
        } catch (err) {
            console.log('Add placeOfWork list for user err:', err);
            throw err;
        }
    }

    async addPlaceOfWorkUsers(
        placeId: string,
        userIds: number[],
        start: string,
        end: string
    ): Promise<PlaceOfWorkInDB[]> {
        try {
            const response = await this._logic.api().placeOfWorkApi.placeOfWorkCreateV1PlacesPlaceOfWorkPost({
                driversToCustomPlaces: {
                    listOfCustomPlaceIds: [placeId],
                    listOfUserIds: userIds,
                    start: moment(start).startOf('day').toDate(),
                    end: moment(end).endOf('day').toDate()
                }
            });
            return response;
        } catch (err) {
            console.log('Add user list for place of work err:', err);
            throw err;
        }
    }

    async deletePlaceOfWorkUser(placeOfWorkId: string, customPlaceId: string): Promise<PlaceOfWorkInDB> {
        try {
            const response = await this._logic.api().placeOfWorkApi.placeOfWorkDeleteV1PlacesPlaceOfWorkDelete({
                placeOfWorkId: placeOfWorkId,
                customPlaceId: customPlaceId
            });
            return response;
        } catch (err) {
            console.log('Delete placeOfWork from user err:', err);
            throw err;
        }
    }

    async getPlaceOfWorkCrossing(userIds: number[], start: Date, end: Date) {
        this.manualPlaceOfWorkCrossingLoading.next(true);
        try {
            if (this._logic.demo().isActive) {
                const demoData = this._logic
                    .demo()
                    .data.diemsManualPlaceOfWorkCrossing.filter(p => p.driverId && userIds.includes(p.driverId));
                this.manualPlaceOfWorkCrossingData.next(demoData);
            } else {
                const data = await this._logic
                    .api()
                    .customPlaceCrossingApi.customPlaceCrossingListV1PlacesCustomPlaceCrossingGet({
                        start,
                        end,
                        userIds
                    });
                this.manualPlaceOfWorkCrossingData.next(data);
            }
        } catch (err) {
            console.log('Manual place of work err:', err);
            this.manualPlaceOfWorkCrossingError.next(err);
        } finally {
            this.manualPlaceOfWorkCrossingLoading.next(false);
        }
    }

    async addManualPlaceOfWorkCrossing(
        userId: number,
        customPlaceId: string,
        timestamp: Date,
        type: CustomPlaceCrossingType
    ) {
        try {
            return await this._logic
                .api()
                .customPlaceCrossingApi.createManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualPost({
                    customPlaceId,
                    timestamp,
                    type,
                    userId
                });
        } catch (err) {
            console.log('Manual place of work err:', err);
            throw err;
        }
    }

    async deleteManualPlaceOfWorkCrossing(id: string) {
        try {
            const response = await this._logic
                .api()
                .customPlaceCrossingApi.deleteManualCustomPlaceCrossingV1PlacesCustomPlaceCrossingManualDelete({
                    customPlaceCrossingId: id
                });
            return response;
        } catch (err) {
            console.log('Delete placeOfWorkCrossing from user err:', err);
            throw err;
        }
    }

    private _toPoiModel(fs: FuelStationModel): PoiModelMap {
        return {
            id: fs._id || '',
            externalId: String(fs.externalId),
            name: fs.name || '',
            selected: false,
            fuelTypes: (fs.fuelTypes || []).map(code => ({
                code,
                name: FuelTypes[code]
            })),
            services: (fs.services || []).map(code => ({
                code,
                name: FuelStationServicesTranslations[code]
            })),
            position: centerToLatLngObject(fs.center.coordinates)
        };
    }

    async getCustomPlaceSuggestions(text: string): Promise<PlaceSuggestion[]> {
        this._suggestionSearchText = text;
        const { data } = await this._logic.apollo().query<PointSuggestionsQuery, PointSuggestionsQueryVariables>({
            query: PointSuggestionsDocument,
            fetchPolicy: 'no-cache',
            variables: {
                searchToken: text
            }
        });

        if (data?.search?.pointSuggestions) {
            if (this._suggestionSearchText === text) {
                return data.search.pointSuggestions;
            } else {
                throw new Error('Interrupted call with new data...');
            }
        } else {
            throw new Error('No search point suggestions');
        }
    }

    private _ownerHeader():
        | {
              'X-Test-Owner': string;
          }
        | undefined {
        const testOwner = document.querySelector('meta[name="X-Test-Owner"]') as HTMLMetaElement;
        const testOwnerHeader = testOwner?.content
            ? {
                  'X-Test-Owner': testOwner.content as string
              }
            : undefined;
        return testOwnerHeader;
    }
}

const mockedPlacesOfWork: PoiModelMap[] = new Array(5).fill(null).map(() => ({
    id: faker.datatype.number({ min: 19999999, max: 99999999 }).toString(),
    name: faker.random.word(),
    selected: false,
    position: {
        lat: faker.datatype.number({ min: 45, max: 50, precision: 0.00001 }),
        lng: faker.datatype.number({ min: 2, max: 6, precision: 0.00001 })
    },
    detailAddress: faker.random.word()
}));
