import Input, { TextAreaProps } from 'antd/lib/input';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Control } from '../Control';

interface Props extends TextAreaProps {
    label?: ReactNode;
    error?: ReactNode;
    qa?: string;
}

export default function EnhancedTextArea({ label, error, qa, ...bag }: Props) {
    return (
        <Control label={label} error={error}>
            <Input.TextArea
                className={cn({
                    'ant-input-warning': error
                })}
                {...bag}
                data-qa={qa}
            />
        </Control>
    );
}
