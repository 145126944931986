import { ReadOnlyCurrencyFromJSON } from 'generated/new-main';

const data = [
    {
        id: 25,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 1.9558, currency: 25 }],
        name: 'Bulgarian Lev',
        code: 'BGN',
        number: 975,
        minor_unit: 2
    },
    {
        id: 42,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 26.163, currency: 42 }],
        name: 'Czech Koruna',
        code: 'CZK',
        number: 203,
        minor_unit: 2
    },
    {
        id: 44,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 7.4369, currency: 44 }],
        name: 'Danish Krone',
        code: 'DKK',
        number: 208,
        minor_unit: 2
    },
    { id: 54, latest_exchange_rate: [], name: 'Euro', code: 'EUR', number: 978, minor_unit: 2 },
    {
        id: 57,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 359.62, currency: 57 }],
        name: 'Forint',
        code: 'HUF',
        number: 348,
        minor_unit: 2
    },
    { id: 66, latest_exchange_rate: [], name: 'Hryvnia', code: 'UAH', number: 980, minor_unit: 2 },
    {
        id: 109,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 10.2863, currency: 109 }],
        name: 'Norwegian Krone',
        code: 'NOK',
        number: 578,
        minor_unit: 2
    },
    {
        id: 129,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 90.8, currency: 129 }],
        name: 'Russian Ruble',
        code: 'RUB',
        number: 643,
        minor_unit: 2
    },
    {
        id: 148,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 10.051, currency: 148 }],
        name: 'Swedish Krona',
        code: 'SEK',
        number: 752,
        minor_unit: 2
    },
    {
        id: 149,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 1.0827, currency: 149 }],
        name: 'Swiss Franc',
        code: 'CHF',
        number: 756,
        minor_unit: 2
    },
    {
        id: 179,
        latest_exchange_rate: [{ date: '2021-01-08T00:00:00Z', rate: 4.5113, currency: 179 }],
        name: 'Zloty',
        code: 'PLN',
        number: 985,
        minor_unit: 2
    }
];

export const currencies = data.map(ReadOnlyCurrencyFromJSON);
