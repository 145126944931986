import { Col, Divider, message, Row } from 'antd';
import { ReadOnlyUserRight, ReadOnlyUserRole, ReadOnlyModule } from 'generated/new-main/models';
import { Logic } from 'logic/logic';
import UserModules, { module, ParentModule } from '../management-users/ui/user-modules';
import UserRights from '../management-users/ui/user-rights';
import UserRoles from './ui/user-roles';
import * as React from 'react';
import i18n from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Loading } from 'common/components/Loading';
import { UserRoleFormModel } from 'common/forms/UserRoleForm';
import { Confirm, HelperModal } from 'common/components';
import { MessageType } from 'common/components/Confirm';
import { confDefault } from 'conf';
import TableBar from 'common/components/TableBar';
import { DocsUserGuide } from 'modules/docs/DocsModule';

interface Props extends WithTranslation {
    logic: Logic;
}
interface State {
    loading: boolean;
    roleEditable: boolean;
    rightsEditable: boolean;
    userRights: ReadOnlyUserRight[];
    userRoles: ReadOnlyUserRole[];
    selectedRole?: ReadOnlyUserRole;
    moduleRights: ParentModule[];
    userModules: ReadOnlyModule[];
    selectedModule?: ParentModule;
    roleDelete: {
        processing: boolean;
        confirmVisible: boolean;
        id?: string;
    };
    helper?: {
        content: string;
    };
}
class RolesModule extends React.Component<Props, State> {
    private _logic: Logic;
    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            loading: true,
            roleEditable: false,
            rightsEditable: false,
            userRights: [],
            userRoles: [],
            userModules: [],
            moduleRights: [],
            roleDelete: {
                confirmVisible: false,
                processing: false
            }
        };
    }

    componentDidMount() {
        Promise.all([
            this._logic.users().rights().getUserRights(),
            this._logic.users().roles().getUserRoles(),
            this._logic.users().modules().getUserModules()
        ])
            .then(res => {
                const [userRights, userRoles, userModules] = res;
                this.setState(
                    () => ({
                        loading: false,
                        userRights,
                        userRoles,
                        userModules
                    }),
                    () => {
                        this._onRoleChange();
                    }
                );
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                message.error(this.props.t('common.error.loadDataError'));
                console.error('fail to fetch user groups, rights or contracts', err);
            });
    }

    render() {
        return (
            <div className="user-roles-rights page">
                <>
                    {this.state.loading && <Loading />}
                    <TableBar
                        heading={this.props.t('ManagementRoles.rolesManagement')}
                        onHelperClick={this._onBarHelperClick}
                    />
                    <Row>
                        <Col span={5}>
                            {this.state.selectedRole ? (
                                <>
                                    <h3>{this._getSelectedRoleName()}</h3>
                                    <div className="role-description">{this._getSelectedRoleDescription()}</div>
                                </>
                            ) : (
                                <h3>{this.props.t('ManagementRoles.createNew')}</h3>
                            )}

                            <UserRoles
                                userRoles={this.state.userRoles}
                                roleEditable={this.state.roleEditable}
                                userRoleFormInitValue={{ ...this.state.selectedRole }}
                                selectedRoleId={this.state.selectedRole?.id ?? ''}
                                demoMode={this._logic.demo().isActive}
                                onSubmitRoleForm={this._onSubmitRoleForm}
                                onRoleChange={this._onRoleChange}
                                onDeleteRole={this._onRoleDeleteClick}
                                onEditRoleFormVisibleChange={this._onEditRoleFormVisibleChange}
                            />
                        </Col>
                        <Col className="divider-wrapper" span={1}>
                            <Divider dashed type="vertical" />
                        </Col>
                        <Col span={12}>
                            <UserModules
                                mode={'EDIT'}
                                roleId={this.state.selectedRole?.id}
                                selectedModuleId={this.state.selectedModule?.id}
                                demoMode={this._logic.demo().isActive}
                                onModuleSelect={this._onUserRightsModuleSelect}
                                onRightChange={this._onRightChange}
                                modulesGroups={this._getUserRightsModules()}
                            />
                        </Col>
                        <Col className="divider-wrapper" span={1}>
                            <Divider dashed type="vertical" />
                        </Col>
                        <Col span={5}>
                            <UserRights
                                mode={this.state.rightsEditable ? 'EDIT' : 'READ'}
                                roleId={this.state.selectedRole?.id}
                                parentModule={this.state.moduleRights.find(
                                    module => module.id === this.state.selectedModule?.id
                                )}
                                onRightChange={this._onRightChange}
                                onRightChangeAll={this._onRightChangeAll}
                            />
                        </Col>
                    </Row>
                    {this.state.roleDelete.confirmVisible && (
                        <Confirm
                            danger
                            type={MessageType.WARNING}
                            loading={this.state.roleDelete.processing}
                            message={this.props.t('ManagementRoles.roleDeletedConfirm')}
                            confirmLabel={this.props.t('common.delete')}
                            onCancel={this._onRoleDeleteCancel}
                            onConfirm={this._onRoleDeleteConfirm}
                        />
                    )}
                </>

                <HelperModal
                    name="roles"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </div>
        );
    }

    private _getSelectedRoleName = () => {
        switch (this.state.selectedRole?.name?.toLocaleLowerCase()) {
            case 'owner':
                return this.props.t('ManagementRoles.constRoles.owner.label');
            case 'dispatcher':
                return this.props.t('ManagementRoles.constRoles.dispatcher.label');
            case 'driver':
                return this.props.t('ManagementRoles.constRoles.driver.label');
            default:
                return this.state.selectedRole?.label;
        }
    };

    private _getSelectedRoleDescription = () => {
        switch (this.state.selectedRole?.name?.toLocaleLowerCase()) {
            case 'owner':
                return this.props.t('ManagementRoles.constRoles.owner.description');
            case 'dispatcher':
                return this.props.t('ManagementRoles.constRoles.dispatcher.description');
            case 'driver':
                return this.props.t('ManagementRoles.constRoles.driver.description');
            default:
                return this.state.selectedRole?.description;
        }
    };

    private _onRightChange = (hasRight: boolean, rightId?: string) => {
        const moduleRights = [...this.state.moduleRights];
        moduleRights.forEach(module => {
            const foundModule = module.subModules.find(sm => sm.id === rightId);
            if (foundModule) {
                foundModule.hasRight = hasRight;
            }
        });

        this.setState({
            moduleRights
        });
    };

    private _onRightChangeAll = (hasRight: boolean, moduleId?: number) => {
        const moduleRights = [...this.state.moduleRights].map(module => {
            return module.id === moduleId
                ? {
                      ...module,
                      subModules: module.subModules.map(subModule => ({
                          ...subModule,
                          hasRight: hasRight
                      }))
                  }
                : module;
        });
        this.setState({
            moduleRights
        });
    };

    private _onEditRoleFormVisibleChange = (visible: boolean) => {
        this.setState({
            rightsEditable: visible,
            moduleRights: this._getUserRightsModules()
        });
    };

    private _onRoleDeleteClick = (id: string) => {
        this.setState({
            roleDelete: {
                confirmVisible: true,
                processing: false,
                id: id
            }
        });
    };

    private _onRoleDeleteConfirm = () => {
        this.setState(
            state => ({
                roleDelete: {
                    ...state.roleDelete,
                    processing: true
                }
            }),
            () => {
                if (this.state.roleDelete.id)
                    this.props.logic
                        .users()
                        .roles()
                        .deleteUserRole(this.state.roleDelete.id)
                        .then(() => {
                            message.success(this.props.t('ManagementRoles.roleDeleted'));
                        })
                        .catch(err => {
                            if (err.body) {
                                err.json().then((dataErr: any) => {
                                    if (dataErr && dataErr.code === 4001) {
                                        message.error(
                                            this.props.t('ManagementRoles.error.roleAssigned', {
                                                users: dataErr.user_names_list
                                            })
                                        );
                                    } else {
                                        message.error(this.props.t('ManagementRoles.error.unknownDelete'));
                                    }
                                });
                            } else {
                                message.error(this.props.t('ManagementRoles.error.unknownDelete'));
                            }
                        })
                        .then(() => {})
                        .finally(() => {
                            this.setState({
                                roleDelete: {
                                    processing: false,
                                    confirmVisible: false,
                                    id: undefined
                                }
                            });
                            this._logic
                                .users()
                                .roles()
                                .getUserRoles()
                                .then(userRoles => {
                                    this.setState({
                                        userRoles: userRoles,
                                        loading: false,
                                        selectedRole: undefined,
                                        selectedModule: undefined
                                    });
                                });
                        });
            }
        );
    };

    private _onRoleDeleteCancel = () => {
        this.setState({
            roleDelete: {
                processing: false,
                confirmVisible: false,
                id: undefined
            }
        });
    };

    private _onSubmitRoleForm = async (roleData: UserRoleFormModel) => {
        this.setState({
            loading: true
        });

        const roleRightsIds = this.state.moduleRights.reduce((prev: string[], curr) => {
            if (curr.access) {
                return prev.concat(
                    curr.subModules.filter(sm => sm.hasRight && typeof sm.id !== undefined).map(sm => sm.id) as string[]
                );
            }
            return prev;
        }, [] as string[]);

        let roleId = this.state.selectedRole?.id;

        try {
            if (this.state.selectedRole && roleId) {
                await this.props.logic.users().roles().updateUserRole(roleId, roleData, roleRightsIds);
                message.success(this.props.t('ManagementRoles.roleEdited', { roleName: roleData.label }));
            } else {
                roleId = (await this.props.logic.users().roles().createUserRole(roleData, roleRightsIds)).id;
                message.success(this.props.t('ManagementRoles.roleCreated', { roleName: roleData.label }));
            }
            this._logic
                .users()
                .roles()
                .getUserRoles()
                .then(userRoles => {
                    this.setState({
                        userRoles: userRoles,
                        selectedRole: userRoles.find(role => role.id === roleId),
                        roleEditable: true,
                        loading: false,
                        rightsEditable: false
                    });
                });
            return true;
        } catch (err: any) {
            const unknownError = () => {
                if (this.state.selectedRole && roleId) {
                    message.error(this.props.t('ManagementRoles.error.unknownEdit'));
                } else {
                    message.error(this.props.t('ManagementRoles.error.unknownCreate'));
                }
            };
            if (err?.json) {
                (err as Response)
                    .json()
                    .then(data => {
                        if (data.user_rights && data.user_rights[0] === 'This list may not be empty.') {
                            message.error(this.props.t('ManagementRoles.error.noRightsSelected'));
                        } else {
                            unknownError();
                        }
                    })
                    .catch(unknownError);
            } else {
                unknownError();
            }
            this.setState({
                loading: false,
                rightsEditable: true
            });

            return false;
        }
    };

    private _onRoleChange = (id?: string) => {
        this.setState(
            {
                selectedRole: this.state.userRoles.find(r => r.id === id)
            },
            () => {
                const moduleRights: ParentModule[] = this._getUserRightsModules();

                this.setState(state => ({
                    moduleRights,
                    roleEditable: !!state.selectedRole
                }));
            }
        );
    };

    private _getUserRightsModules = (): ParentModule[] => {
        let moduleRights =
            this.state.userModules.length > 0
                ? this.state.userModules.map(
                      module =>
                          ({
                              access:
                                  this.state.userRights?.filter(right => right.module.name === module.name).length > 0
                                      ? true
                                      : false,
                              id: module.id ?? 0,
                              name: module.label ?? '',
                              code: module.name ?? '',
                              subModules:
                                  this.state.userRights.length > 0
                                      ? this.state.userRights
                                            .filter(right => right.module.name === module.name)
                                            .map(right => {
                                                return {
                                                    id: String(right.id),
                                                    name: right.label ?? '',
                                                    hasRight:
                                                        right.id &&
                                                        this.state.moduleRights.filter(module =>
                                                            module.subModules.find(
                                                                subModule =>
                                                                    subModule.id === right.id && subModule.hasRight
                                                            )
                                                        )
                                                            ? true
                                                            : false
                                                } as module;
                                            })
                                      : []
                          } as ParentModule)
                  )
                : [];

        // When you wana create newRole set all rigths to false
        if (!this.state.selectedRole) {
            moduleRights = moduleRights.map(module => ({
                ...module,
                subModules: module.subModules.map(subModule => ({ ...subModule, hasRight: false }))
            }));
        } else {
            const userActiveRightsIds = this.state.userRoles
                .filter(r => r.id === this.state.selectedRole?.id)
                .map(r => r.userRights)
                .flat();
            moduleRights = moduleRights.map(module => ({
                ...module,
                subModules: module.subModules.map(subModule => ({
                    ...subModule,
                    hasRight: userActiveRightsIds.includes(subModule.id ?? '')
                }))
            }));
        }

        return moduleRights;
    };

    private _onUserRightsModuleSelect = (moduleId: number): void => {
        this.setState({
            selectedModule: this._getUserRightsModules().find(m => m.id === moduleId)
        });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(RolesModule);
