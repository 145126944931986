/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiemsRulesPLFixedRatesRes,
    DiemsRulesPLFixedRatesResFromJSON,
    DiemsRulesPLFixedRatesResFromJSONTyped,
    DiemsRulesPLFixedRatesResToJSON,
} from './';

/**
 * 
 * @export
 * @interface WriteOnlyDiemsRules
 */
export interface WriteOnlyDiemsRules {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDiemsRules
     */
    id?: string;
    /**
     * 
     * @type {DiemsRulesPLFixedRatesRes}
     * @memberof WriteOnlyDiemsRules
     */
    plFixedRates?: DiemsRulesPLFixedRatesRes | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyDiemsRules
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyDiemsRules
     */
    end?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WriteOnlyDiemsRules
     */
    replacedMeals?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules
     */
    pocketMoney?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyDiemsRules
     */
    concatForeignDiems?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules
     */
    client: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules
     */
    user?: number | null;
}

export function WriteOnlyDiemsRulesFromJSON(json: any): WriteOnlyDiemsRules {
    return WriteOnlyDiemsRulesFromJSONTyped(json, false);
}

export function WriteOnlyDiemsRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyDiemsRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'plFixedRates': !exists(json, 'pl_fixed_rates') ? undefined : DiemsRulesPLFixedRatesResFromJSON(json['pl_fixed_rates']),
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (json['end'] === null ? null : new Date(json['end'])),
        'replacedMeals': !exists(json, 'replaced_meals') ? undefined : json['replaced_meals'],
        'pocketMoney': !exists(json, 'pocket_money') ? undefined : json['pocket_money'],
        'concatForeignDiems': !exists(json, 'concat_foreign_diems') ? undefined : json['concat_foreign_diems'],
        'client': json['client'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function WriteOnlyDiemsRulesToJSON(value?: WriteOnlyDiemsRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pl_fixed_rates': DiemsRulesPLFixedRatesResToJSON(value.plFixedRates),
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end === null ? null : value.end.toISOString()),
        'replaced_meals': value.replacedMeals,
        'pocket_money': value.pocketMoney,
        'concat_foreign_diems': value.concatForeignDiems,
        'client': value.client,
        'user': value.user,
    };
}


