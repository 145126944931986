import moment, { Moment } from 'moment';
import i18n from 'i18next';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Logic } from 'logic/logic';
import { VehiclesRemoteMemory } from './ui/VehiclesRemoteMemory';
import { VehiclesRemoteMemoryModel } from 'logic/management/management-vehicles-remote-memory';
import { message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { CcrModel } from '../driver-cards-remote-memory/DriversCardsRemoteMemoryModule';
import { debounce } from 'debounce';
import { downloadFile } from 'common/utils/fileUtils';

export interface MemoryCard {
    id: string;
    monitoredObject?: {
        id: string;
        registrationNumber: string;
    };
    driver?: {
        name: string;
        id: string;
    };
    date: string;
    demand: boolean;
    downloadedFile?: string;
    downloadStarted?: string;
    downloadRequested?: string;
    dataActivityStart: string;
    dataActivityEnd: string;
}

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    search: { text: string };
    startDate: string;
    table: {
        data: VehiclesRemoteMemoryModel[];
        loadingDemandIds: string[];
        loading: boolean;
    };
    ccr: {
        open: boolean;
        edit: boolean;
        data: CcrModel[];
    };
    helper?: {
        content: string;
    };
}

class VehiclesRemoteMemoryModule extends Component<Props, State> {
    private logic: Logic;

    constructor(props: Props) {
        super(props);
        this.state = {
            search: { text: '' },
            startDate: moment.utc().startOf('month').toISOString(),
            ccr: {
                open: false,
                edit: this.props.logic.auth().impersonator(),
                data: []
            },
            table: {
                data: [],
                loadingDemandIds: [],
                loading: true
            }
        };
        this.logic = this.props.logic;
    }

    componentDidMount() {
        (window as any).app.modul = this;

        this.logic
            .dispatcherKitVehiclesRemoteMemory()
            .getTableData(
                {
                    timestampFrom: moment.utc(this.state.startDate).toDate(),
                    timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                },
                this.state.search.text
            )
            .then(data =>
                this.setState({
                    table: {
                        data,
                        loadingDemandIds: [],
                        loading: false
                    }
                })
            )
            .catch(err => {
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
            });

        this.logic
            .ccr()
            .getCCRs()
            .then(data => {
                this.setState(state => ({
                    ccr: {
                        ...state.ccr,
                        data
                    }
                }));
                if (data.length > 0 && !this.logic.demo().isActive) {
                    this.logic.ccr().init();
                }
            })
            .catch(e => console.error(e));

        this.logic.ccr().onData(data => {
            this.setState(state => ({
                ccr: {
                    ...state.ccr,
                    data
                }
            }));
        });
    }

    componentWillUnmount() {
        this.logic.dispatcherKitVehiclesRemoteMemory().destroy();
    }

    private _onBarCCRClick = () => {
        this.setState(state => ({
            ccr: {
                ...state.ccr,
                open: !state.ccr.open
            }
        }));
    };

    render() {
        return (
            <VehiclesRemoteMemory
                search={this.state.search}
                table={{
                    ...this.state.table,
                    onDayDownloadClick: this._onDayDownloadClick,
                    onRemoteDownloadClick: this._onRemoteDownloadClick,
                    onMonthlyDownloadClick: this._onMonthlyDownloadClick
                }}
                ccr={this.state.ccr}
                helper={this.state.helper}
                startDate={this.state.startDate}
                demoMode={this.logic.demo().isActive}
                onSearchChange={this._onSearchChange}
                onBarHelperClick={this._onBarHelperClick}
                onBarNextChange={this._onNextChange}
                onBarPreviousChange={this._onPreviousChange}
                onBarDatePickerChange={this._onDatePickerChange}
                onBarDownloadAllClick={this._onBarDownloadAllClick}
                onBarCcrClick={this._onBarCCRClick}
                onHelperClose={this._onHelperClose}
            />
        );
    }

    private _onBarDownloadAllClick = () => {
        let documentIds: string[] = [];
        this.state.table.data
            .filter(entry => entry.cards.length > 0)
            .forEach(entry => {
                entry.cards.forEach(data => {
                    if (!data.demand && data.downloadedFile && data.monitoredObject?.id) {
                        documentIds.push(data.id);
                    }
                });
            });
        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .downloadFiles(documentIds)
            .then(result => {
                if (result.url) {
                    const filename = result.filename;
                    const headers = new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        Authorization: `Bearer ${this.logic.auth().token()}`
                    });
                    fetch(this.logic.conf.api.backend.basePath + result.url, {
                        headers
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            downloadFile(blob, filename);
                            message.success(this.props.t('common.downloadSuccessMsg'));
                        })
                        .catch(err => {
                            message.error(this.props.t('common.downloadErrorMsg'));
                            console.error('Fetch archive file err', err);
                        });
                }
            })
            .catch(err => {
                message.error(this.props.t('common.downloadErrorMsg'));
                console.error('Download files err', err);
            });
    };

    private _onMonthlyDownloadClick = (id: string) => {
        let documentIds: string[] = [];
        this.state.table.data
            .filter(entry => entry.id === id)
            .forEach(entry => {
                entry.cards.forEach(data => {
                    if (!data.demand && data.downloadedFile) {
                        documentIds.push(data.id);
                    }
                });
            });

        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .downloadFiles(documentIds)
            .then(result => {
                if (result.url) {
                    const filename = result.filename;
                    const headers = new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        Authorization: `Bearer ${this.logic.auth().token()}`
                    });
                    fetch(this.logic.conf.api.backend.basePath + result.url, {
                        headers
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            downloadFile(blob, filename);
                            message.success(this.props.t('common.downloadSuccessMsg'));
                        })
                        .catch(err => {
                            message.error(this.props.t('common.downloadErrorMsg'));
                            console.error('Fetch archive file err', err);
                        });
                }
            })
            .catch(err => {
                message.error(this.props.t('common.downloadErrorMsg'));
                console.error('Download files err', err);
            });
    };

    private _onDayDownloadClick = async (documentId: string) => {
        try {
            const result = await this.logic.dispatcherKitDriverCardsRemoteMemory().getDtcoLink(documentId);
            if (result.url) {
                const filename = result.filename;
                const headers = new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Content-Type'
                });
                fetch(result.url, {
                    headers
                })
                    .then(response => response.blob())
                    .then(blob => {
                        downloadFile(blob, filename);
                        message.success(this.props.t('common.downloadSuccessMsg'));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.downloadErrorMsg'));
                        console.error('Fetch archive file err', err);
                    });
            }
        } catch (err) {
            message.error(this.props.t('common.downloadErrorMsg'));
            console.error('Download file err', err);
        }
    };

    private _onRemoteDownloadClick = (monitoredObjectId: string) => {
        this.setState(
            state => ({
                ...state,
                table: {
                    ...state.table,
                    loadingDemandIds: state.table.loadingDemandIds.concat(monitoredObjectId)
                }
            }),
            () => {
                this.logic
                    .dispatcherKitVehiclesRemoteMemory()
                    .createDownloadDemand(monitoredObjectId)
                    .then(cardsDemanded => {
                        const data = this.state.table.data.map(tableCell => ({
                            ...tableCell,
                            cardsDemanded:
                                tableCell.id === monitoredObjectId ? [cardsDemanded] : tableCell.cardsDemanded
                        }));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loadingDemandIds: state.table.loadingDemandIds.filter(id => id !== monitoredObjectId),
                                data
                            }
                        }));
                    });
            }
        );
    };

    private _onSearchChange = debounce((text: string) => {
        this.setState(() => ({
            search: { text }
        }));
        this.logic
            .dispatcherKitVehiclesRemoteMemory()
            .search(text)
            .then(data =>
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data,
                        loading: false
                    }
                }))
            );
    }, 500);

    private _onNextChange = () => {
        const startDate = moment.utc(this.state.startDate).add(1, 'month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitVehiclesRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data =>
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }))
                    )
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    })
        );
    };

    private _onPreviousChange = () => {
        const startDate = moment.utc(this.state.startDate).subtract(1, 'month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitVehiclesRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }));
                    })
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    })
        );
    };

    private _onDatePickerChange = (date: Moment) => {
        const startDate = moment.utc(date.toDate()).startOf('month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitVehiclesRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data =>
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }))
                    )
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    })
        );
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'tachoremotedownload';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(VehiclesRemoteMemoryModule));
