import { transportTasks } from './alarms';
import { TransportPlaceDemo, getCurrentDates } from './transports';
import { users } from './users';
import moment from 'moment';

const historyDates = [getCurrentDates(-5, 151)[0], getCurrentDates(-6.1, 32)[0], getCurrentDates(-4.3, 48)[0]];

const transportPlacesArray: TransportPlaceDemo[] = [
    {
        name: 'SK, Bratislava, Slovnaftská',
        addressStructured: [
            {
                lang: 'cs',
                address: '(SK) 821 07, Bratislava, Slovnaftská',
                countryCode: 'SK',
                country: 'Slovensko',
                town: 'Bratislava',
                route: 'Slovnaftská',
                street_address: 'Slovnaftská 81',
                postalCode: '821 07'
            },
            {
                lang: 'en',
                address: '(SK) 821 07, Bratislava, Slovnaftská',
                countryCode: 'SK',
                country: 'Slovakia',
                town: 'Bratislava',
                route: 'Slovnaftská',
                street_address: 'Slovnaftská 81',
                postalCode: '821 07'
            },
            {
                lang: 'sk',
                address: '(SK) 821 07, Bratislava, Slovnaftská',
                countryCode: 'SK',
                country: 'Slovensko',
                town: 'Bratislava',
                route: 'Slovnaftská',
                street_address: 'Slovnaftská 81',
                postalCode: '821 07'
            }
        ],
        center: { type: 'Point', coordinates: [17.179500774652098, 48.12933428709012] }
    },
    {
        name: 'ES, Paterna, Carrer dels Bombers 1',
        addressStructured: [
            {
                lang: 'cs',
                address: '(ES) 46988, Paterna, Carrer dels Bombers',
                countryCode: 'ES',
                country: 'Španělsko',
                town: 'Paterna',
                route: 'Carrer dels Bombers',
                street_address: 'Carrer dels Bombers 1',
                postalCode: '46988'
            },
            {
                lang: 'en',
                address: '(ES) 46988, Paterna, Carrer dels Bombers',
                countryCode: 'ES',
                country: 'Spain',
                town: 'Paterna',
                route: 'Carrer dels Bombers',
                street_address: 'Carrer dels Bombers 1',
                postalCode: '46988'
            },
            {
                lang: 'sk',
                address: '(ES) 46988, Paterna, Carrer dels Bombers',
                countryCode: 'ES',
                country: 'Španielsko',
                town: 'Paterna',
                route: 'Carrer dels Bombers',
                street_address: 'Carrer dels Bombers 1',
                postalCode: '46988'
            }
        ],
        polygon: {
            type: 'Polygon',
            coordinates: [
                [
                    [-0.4546965000000001, 39.51663243056823],
                    [-0.452679638355007, 39.51573411528411],
                    [-0.452679638355007, 39.513937484715875],
                    [-0.4546965000000001, 39.51303916943176],
                    [-0.4567133616449932, 39.513937484715875],
                    [-0.4567133616449932, 39.51573411528411],
                    [-0.4546965000000001, 39.51663243056823]
                ]
            ]
        },
        center: { type: 'Point', coordinates: [-0.4546965000000001, 39.51483579999999] }
    },
    {
        name: 'ES, Santa Coloma de Gramenet, Carretera de La Roca 5',
        addressStructured: [
            {
                lang: 'cs',
                address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                countryCode: 'ES',
                country: 'Španělsko',
                town: 'Santa Coloma de Gramenet',
                route: 'Carretera de La Roca',
                street_address: 'Carretera de La Roca 5',
                postalCode: '08924'
            },
            {
                lang: 'en',
                address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                countryCode: 'ES',
                country: 'Spain',
                town: 'Santa Coloma de Gramenet',
                route: 'Carretera de La Roca',
                street_address: 'Carretera de La Roca 5',
                postalCode: '08924'
            },
            {
                lang: 'sk',
                address: '(ES) 08924, Santa Coloma de Gramenet, Carretera de La Roca',
                countryCode: 'ES',
                country: 'Španielsko',
                town: 'Santa Coloma de Gramenet',
                route: 'Carretera de La Roca',
                street_address: 'Carretera de La Roca 5',
                postalCode: '08924'
            }
        ],
        polygon: {
            type: 'Polygon',
            coordinates: [
                [
                    [2.18961, 41.469033630568234],
                    [2.191686412872474, 41.468135315284115],
                    [2.191686412872474, 41.46633868471588],
                    [2.18961, 41.46544036943176],
                    [2.1875335871275263, 41.46633868471588],
                    [2.1875335871275263, 41.468135315284115],
                    [2.18961, 41.469033630568234]
                ]
            ]
        },
        center: { type: 'Point', coordinates: [2.18961, 41.467237] }
    },
    {
        name: 'DE, Berlin, Am Lustgardten 1',
        addressStructured: [
            {
                lang: 'cs',
                address: '(DE) 10178, Berlin, Am Lustgarten',
                countryCode: 'DE',
                country: 'Německo',
                town: 'Berlin',
                route: 'Am Lustgarten',
                street_address: 'Am Lustgarten 1',
                postalCode: '10178'
            },
            {
                lang: 'en',
                address: '(DE) 10178, Berlin, Am Lustgarten',
                countryCode: 'DE',
                country: 'Germany',
                town: 'Berlin',
                route: 'Am Lustgarten',
                street_address: 'Am Lustgarten 1',
                postalCode: '10178'
            },
            {
                lang: 'sk',
                address: '(DE) 10178, Berlin, Am Lustgarten',
                countryCode: 'DE',
                country: 'Nemecko',
                town: 'Berlin',
                route: 'Am Lustgarten',
                street_address: 'Am Lustgarten 1',
                postalCode: '10178'
            }
        ],
        center: {
            type: 'Point',
            coordinates: [13.40017, 52.51912]
        },
        polygon: {
            type: 'Polygon',
            coordinates: [
                [
                    [13.4001665, 52.520917930568224],
                    [13.402723503214183, 52.520019615284106],
                    [13.402723503214183, 52.51822298471587],
                    [13.4001665, 52.51732466943175],
                    [13.397609496785815, 52.51822298471587],
                    [13.397609496785815, 52.520019615284106]
                ]
            ]
        }
    },
    {
        name: 'HU, Budapest, Soroksari út 60',
        addressStructured: [
            {
                lang: 'cs',
                address: '(HU) 1095, Budapešť, Soroksári út',
                countryCode: 'HU',
                country: 'Maďarsko',
                town: 'Budapešť',
                route: 'Soroksári út',
                street_address: 'Soroksári út 60',
                postalCode: '1095'
            },
            {
                lang: 'en',
                address: '(HU) 1095, Budapest, Soroksári út',
                countryCode: 'HU',
                country: 'Hungary',
                town: 'Budapest',
                route: 'Soroksári út',
                street_address: 'Soroksári út 60',
                postalCode: '1095'
            },
            {
                lang: 'sk',
                address: '(HU) 1095, Budapešť, Soroksári út',
                countryCode: 'HU',
                country: 'Maďarsko',
                town: 'Budapešť',
                route: 'Soroksári út',
                street_address: 'Soroksári út 60',
                postalCode: '1095'
            }
        ],
        center: {
            type: 'Point',
            coordinates: [19.07759, 47.46674]
        },
        polygon: {
            type: 'Polygon',
            coordinates: [
                [
                    [19.0775922, 47.468536030568224],
                    [19.079893806787222, 47.467637715284106],
                    [19.079893806787222, 47.46584108471587],
                    [19.0775922, 47.46494276943175],
                    [19.07529059321278, 47.46584108471587],
                    [19.07529059321278, 47.467637715284106]
                ]
            ]
        }
    },
    {
        name: 'HR, Zagreb, Zagrepčanka',
        addressStructured: [
            {
                lang: 'cs',
                address: '(HR) 10000, Záhřeb, Savska cesta',
                countryCode: 'HR',
                country: 'Chorvatsko',
                town: 'Záhřeb',
                route: 'Savska cesta',
                street_address: 'Savska cesta 41/1',
                postalCode: '10000'
            },
            {
                lang: 'en',
                address: '(HR) 10000, Zagreb, Savska cesta',
                countryCode: 'HR',
                country: 'Croatia',
                town: 'Zagreb',
                route: 'Savska cesta',
                street_address: 'Savska cesta 41/1',
                postalCode: '10000'
            },
            {
                lang: 'sk',
                address: '(HR) 10000, Záhreb, Savska cesta',
                countryCode: 'HR',
                country: 'Chorvátsko',
                town: 'Záhreb',
                route: 'Savska cesta',
                street_address: 'Savska cesta 41/1',
                postalCode: '10000'
            }
        ],
        center: {
            type: 'Point',
            coordinates: [15.96223, 45.79833]
        },
        polygon: {
            type: 'Polygon',
            coordinates: [
                [
                    [15.9622255, 45.80012873056824],
                    [15.96445722564728, 45.79923041528412],
                    [15.96445722564728, 45.797433784715885],
                    [15.9622255, 45.79653546943177],
                    [15.959993774352721, 45.797433784715885],
                    [15.959993774352721, 45.79923041528412]
                ]
            ]
        }
    }
];
const [bratislava, paterna, coloma, berlin, budapest, zagreb] = transportPlacesArray;

export const transportsCompleted = [
    // 201 - FINISHED BRATISLAVA - COLOMA - PATERNA
    {
        id: '201',
        version: 1,
        firstRta: historyDates[0].times[0],
        lastRta: historyDates[0].times[historyDates[0].times.length - 1],
        routeType: null,
        lastUpdated: historyDates[0].times[0],
        state: 'finished',
        current_primary_assigned_monitored_object: {
            startTime: historyDates[0].times[0],
            endTime: undefined,
            primary: true,
            monitoredObjectId: 1,
            type: 'vehicle'
        },
        users: [
            {
                id: users[0].id,
                name: users[0].name,
                surname: users[0].surname
            }
        ],
        clientId: 100,
        client: null,
        name: `${bratislava?.name} - ${paterna?.name}`,
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_ggsxusl8u',
                type: 'waypoint',
                name: bratislava?.name,
                rta: historyDates[0].times[0],
                rtd: historyDates[0].times[0],
                ata: null,
                atd: null,
                eta: historyDates[0].times[0],
                etd: null,
                distance: 1873468,
                duration: 83707,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: bratislava?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[0]],
                center: bratislava?.center,
                polygon: bratislava?.polygon
            },
            {
                id: '_o6x5t7unv',
                type: 'waypoint',
                name: coloma?.name,
                rta: historyDates[0].times[1],
                rtd: historyDates[0].times[1],
                ata: null,
                atd: null,
                eta: historyDates[0].times[1],
                etd: null,
                distance: 367853,
                duration: 16143,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: coloma?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[1]],
                center: coloma?.center,
                polygon: coloma?.polygon
            },
            {
                id: '_lmiqd7c2l',
                type: 'waypoint',
                name: paterna?.name,
                rta: historyDates[0].times[2],
                rtd: historyDates[0].times[2],
                ata: null,
                atd: null,
                eta: historyDates[0].times[2],
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: paterna?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[2]],
                center: paterna?.center,
                polygon: paterna?.polygon
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        }
    },

    // 202 - FINISHED BERLIN - BUDAPEST - ZAGREB
    {
        id: '202',
        version: 1,
        firstRta: moment().set({ year: 2023, month: 4, date: 14, hour: 11, minute: 3 }).toDate(),
        lastRta: moment().set({ year: 2023, month: 4, date: 15, hour: 8, minute: 1 }).toDate(),
        routeType: null,
        lastUpdated: '',
        state: 'finished',
        current_primary_assigned_monitored_object: {
            startTime: '',
            endTime: undefined,
            primary: true,
            monitoredObjectId: 2,
            type: 'vehicle'
        },
        users: [
            {
                id: users[0].id,
                name: users[0].name,
                surname: users[0].surname
            }
        ],
        clientId: 100,
        client: null,
        name: berlin?.name + ' - ' + zagreb?.name,
        number: null,
        fuelTypes: [],
        places: [
            {
                id: '_ggsxusl8u',
                type: 'waypoint',
                name: berlin?.name,
                rta: '',
                rtd: '',
                ata: null,
                atd: null,
                eta: '',
                etd: null,
                distance: 1873468,
                duration: 83707,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: berlin?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[0]],
                center: berlin?.center,
                polygon: berlin?.polygon
            },
            {
                id: '_o6x5t7unv',
                type: 'waypoint',
                name: budapest?.name,
                rta: historyDates[1].times[1],
                rtd: historyDates[1].times[1],
                ata: null,
                atd: null,
                eta: historyDates[1].times[1],
                etd: null,
                distance: 367853,
                duration: 16143,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: budapest?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[1]],
                center: budapest?.center,
                polygon: budapest?.polygon
            },
            {
                id: '_lmiqd7c2l',
                type: 'waypoint',
                name: zagreb?.name,
                rta: moment().set({ year: 2023, month: 4, date: 15, hour: 8, minute: 2 }).toDate(),
                rtd: moment().set({ year: 2023, month: 4, date: 15, hour: 8, minute: 3 }).toDate(),
                ata: null,
                atd: null,
                eta: moment().set({ year: 2023, month: 4, date: 15, hour: 8, minute: 1 }).toDate(),
                etd: null,
                distance: null,
                duration: null,
                actualDistance: null,
                note: null,
                route: null,
                addressStructured: zagreb?.addressStructured,
                eventRules: [],
                eventStates: [],
                legs: [],
                tasks: [transportTasks[2]],
                center: zagreb?.center,
                polygon: zagreb?.polygon
            }
        ],
        desiredVehicleProfile: null,
        costPerKm: {
            value: 1.36,
            currency: 'EUR'
        }
    }
];
