import Confirm, { MessageType } from 'common/components/Confirm';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Pairing, PairingData, TachoCardModel, TachoCardsLinks, Unpairing, UnpairType } from '../TachoCardsModule';
import { LayoutContent } from 'common/components/Layout/Content';
import { Role } from 'logic/auth';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import TachoCardsLinkedItems from 'modules/management/modules/tachograph-cards/ui/TachoCardsLinkedItems';
import TachoCardsDetail from 'modules/management/modules/tachograph-cards/ui/TachoCardsDetail';
import TachoCardsPairing, { TachoCardPairingTypes } from './TachoCardPairing/TachoCardsPairing';
import TachoCardsTable from 'modules/management/modules/tachograph-cards/ui/TachoCardsTable';
import TachoCardsActions from 'modules/management/modules/tachograph-cards/ui/TachoCardsActions';
import { Button, Modal } from 'common/components';
import qa from 'qa-selectors';
import { tachographCards } from 'resources/images/sidebar';
import { ReadOnlyCountry } from 'generated/new-main/models';
import { UserDriverFormModel } from 'common/forms/UserDriverForm/UserDriverForm';
import TachoCardCreatePairForm, { TachoCardCreatePairFormModel } from 'common/forms/TachoCardCreatePairForm';
import { UserModel } from '../../management-users/UsersModule';

interface Props extends WithTranslation {
    edit: boolean;
    loading?: boolean;
    data?: TachoCardModel[];
    form?: {
        open?: boolean;
        users?: UserModel[];
    };
    countries: ReadOnlyCountry[];
    createFormVisible?: boolean;
    pairing?: Pairing;
    remove?: TachoCardModel;
    search?: { text: string };
    selected?: TachoCardModel;
    unpairing?: Unpairing;
    helper?: {
        content: string;
    };
    demoMode?: boolean;
    roles: Role[];
    updateTachoCardLoading: boolean;
    removeTachoCardLoading: boolean;
    pairTachoCardLoading: boolean;
    unpairTachoCardLoading: boolean;
    onBarSearchInputChange?: (text: string) => void;
    onBarHelperClick?: () => void;
    onBarCreatePairClick?: () => void;
    onTachoCardCreatePairSubmitClick?: (model: TachoCardCreatePairFormModel) => Promise<boolean>;
    onTachoCardCreatePairCancelClick?: () => void;
    onTachoCardFormCheckTokenExist?: (token: string) => Promise<boolean>;
    onTachoCardFormCancel?: () => void;
    onTachoCardFormSubmit?: (model: TachoCardModel) => Promise<boolean>;
    onPairingCancelButtonClick?: () => void;
    onPairingConfirmCancel?: () => void;
    onPairingConfirmButtonClick?: (newUser?: UserDriverFormModel) => void;
    onPairingItemSelect?: (selected: PairingData) => void;
    onPairingSearchInputChange?: (text: string) => void;
    onPairingSubmitButtonClick?: () => void;
    onPairingTypeChange?: (linkType: TachoCardPairingTypes) => void;
    onTableRowSelect?: (id: number) => void;
    onTachoCardRemoveCancel?: () => void;
    onTachoCardRemoveConfirm?: () => void;
    onUnpairCancel?: () => void;
    onTachoCardUnpair?: (id: UnpairType, type: keyof TachoCardsLinks) => void;
    onUnpairConfirm?: () => void;
    onHelperClose?: () => void;
    onActionsUpdateClick?: () => void;
    onActionsPairingClick?: () => void;
    onActionsDeleteClick?: () => void;
}

function TachoCards(props: Props) {
    const { t } = props;

    return (
        <>
            {props.pairing && (
                <Modal
                    visible={!!props.pairing}
                    title={props.t('ManagementTachoCards.pairing')}
                    closable={true}
                    size="super-wide"
                    footer={null}
                    destroyOnClose
                    onCancel={props.onPairingCancelButtonClick}
                    withoutPadding={true}
                >
                    <TachoCardsPairing
                        data={props.pairing.data}
                        search={props.pairing.search}
                        selected={props.pairing.selected}
                        type={props.pairing.type}
                        model={props.selected}
                        pairNewCreated={props.pairing.pairNewCreated}
                        onCancelButtonClick={props.onPairingCancelButtonClick}
                        onConfirmButtonClick={props.onPairingConfirmButtonClick}
                        onPairingItemSelect={props.onPairingItemSelect}
                        onPairingTypeChange={props.onPairingTypeChange}
                        onSearchInputChange={props.onPairingSearchInputChange}
                    />
                </Modal>
            )}
            <Modal
                visible={props.form?.open}
                title={props.t('ManagementTachoCards.createAndPairNewTachoCard')}
                closable={true}
                width={600}
                footer={null}
                destroyOnClose
                onCancel={props.onTachoCardCreatePairCancelClick}
                withoutPadding={true}
            >
                <TachoCardCreatePairForm
                    users={props.form?.users}
                    countries={props.countries}
                    demoMode={props.demoMode}
                    onCancel={props.onTachoCardCreatePairCancelClick}
                    onSubmit={props.onTachoCardCreatePairSubmitClick}
                />
            </Modal>

            {props.remove && (
                <Confirm
                    danger
                    header={t('ManagementTachoCards.deleteHeader')}
                    message={t('ManagementTachoCards.deleteConfirm')}
                    type={MessageType.WARNING}
                    loading={props.removeTachoCardLoading}
                    confirmLabel={props.t('common.delete')}
                    onCancel={props.onTachoCardRemoveCancel}
                    onConfirm={props.onTachoCardRemoveConfirm}
                />
            )}

            {/*
                TODO:
                - in furure for multiple type of pairing
                - switch messages based on model type
            */}
            {(props.pairing?.confirm || props.pairing?.newUser) && (
                <Confirm
                    message={t('ManagementPairing.user')}
                    type={MessageType.INFO}
                    loading={props.pairTachoCardLoading}
                    confirmLabel={props.t('common.confirm')}
                    onCancel={props.onPairingConfirmCancel}
                    onConfirm={props.onPairingSubmitButtonClick}
                />
            )}

            {/*
                TODO:
                - in furure for multiple type of unpairing
                - switch messages based on model type
            */}
            {props.unpairing && (
                <Confirm
                    message={`${t('ManagementUnpairing.user')} ${props.unpairing?.model.holder}`}
                    type={MessageType.INFO}
                    loading={props.unpairTachoCardLoading}
                    onCancel={props.onUnpairCancel}
                    onConfirm={props.onUnpairConfirm}
                />
            )}

            <HelperModal
                name="tacho-cards"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />

            <LayoutContent
                className="management-tacho-cards no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.tachoCards')}
                            search={props.search}
                            onSearchChange={props.onBarSearchInputChange}
                            onHelperClick={props.onBarHelperClick}
                            actions={[
                                <Button
                                    type="dashed"
                                    size="large"
                                    onClick={props.onBarCreatePairClick}
                                    disabled={props.demoMode || !props.roles.includes(Role.TC_W)}
                                    qa={qa.tacho.btnNew}
                                >
                                    {t('ManagementTachoCards.createNewTachoCard')}
                                </Button>
                            ]}
                        />
                        <TachoCardsTable
                            loading={props.loading}
                            data={props.data}
                            selected={props.selected}
                            onRowSelect={props.onTableRowSelect}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            header={props.selected.token}
                            headerIcon={tachographCards}
                            body={
                                <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                    <Collapse.Panel header={props.t('ManagementTachoCards.tachoCardsDetail')} key="1">
                                        <TachoCardsDetail
                                            edit={props.edit}
                                            model={props.selected}
                                            demoMode={props.demoMode}
                                            roles={props.roles}
                                            updateTachoCardLoading={props.updateTachoCardLoading}
                                            onCheckTokenExist={props.onTachoCardFormCheckTokenExist}
                                            onFormCancel={props.onTachoCardFormCancel}
                                            onFormSubmit={props.onTachoCardFormSubmit}
                                        />
                                    </Collapse.Panel>
                                    {!props.edit ? (
                                        <Collapse.Panel header={props.t('ManagementFleet.linkedItems')} key="2">
                                            <TachoCardsLinkedItems
                                                model={props.selected}
                                                demoMode={props.demoMode}
                                                roles={props.roles}
                                                onUnpair={props.onTachoCardUnpair}
                                            />
                                        </Collapse.Panel>
                                    ) : (
                                        <></>
                                    )}
                                </Collapse>
                            }
                            footer={
                                !props.edit && (
                                    <TachoCardsActions
                                        model={props.selected}
                                        roles={props.roles}
                                        demoMode={props.demoMode}
                                        onUpdateClick={props.onActionsUpdateClick}
                                        onPairingClick={props.onActionsPairingClick}
                                        onDeleteClick={props.onActionsDeleteClick}
                                    />
                                )
                            }
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(TachoCards);
