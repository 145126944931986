import moment from 'moment';
import qs from 'qs';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18next';

import { RouteNames } from 'App';
import { CheckboxGroupOpt, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import RouteOverview from './ui/RouteOverview';
import { confDefault } from 'conf';
import { Logic } from 'logic/logic';
import { exponea } from 'logic/exponea';
import { TransportModel, VehicleWithAvailability } from 'common/model/transports';
import { Role } from 'logic/auth';
import { TransportState } from 'generated/backend-api';
import { message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { StazkaExportModalModel, StazkaExportModalStateModel } from 'common/model/stazka-export-modal';
import { DATE_FORMAT } from 'domain-constants';
import { Subscription } from 'rxjs';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { DateRange } from 'common/model/date-time';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { VehicleStateObject } from 'generated/graphql';
import cn from 'classnames';
import { downloadFile } from 'common/utils/fileUtils';

export type SearchData = { text?: string; type?: number };

export type RouteOverviewTableData = {
    routesNew?: TransportModel[];
    routesDelayed?: TransportModel[];
    routesActive?: TransportModel[];
    routesFinished?: TransportModel[];
};

interface RouteParams {
    startDate?: string;
    endDate?: string;
    selected?: string;
    dispatcherBoard?: string;
}

export interface RouteOverviewFilterModel {
    fullDWL?: boolean;
    driversOpts: CheckboxGroupOpts;
    vehiclesOpts: CheckboxGroupOpts;
    transportStateOpts: CheckboxGroupOpts;
    driversChecked: string[];
    vehiclesChecked: string[];
    transportStateChecked: string[];
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
    logic: Logic;
    embedded: boolean;
}

interface State {
    bar: {
        dateOpen?: boolean;
        dateChanged?: boolean;
        filterOpen?: boolean;
        backUrl?: boolean;
    };
    filter: RouteOverviewFilterModel;
    helper?: {
        content: string;
    };
    vehiclesSelect?: {
        vehicles: VehicleWithAvailability[];
        loading: boolean;
        vehicleId?: string;
        selectedTransport?: string;
        selectedTransportData?: TransportModel;
        puescMonitoredObjects: number[];
    };
    search?: SearchData;
    dateRange: DateRange;
    remove?: string;
    removeVehicle?: {
        transportId: string;
        vehicleId: string;
    };
    table: {
        data?: RouteOverviewTableData;
        monitoredObjects?: ReadOnlyMonitoredObjectFeSb[];
        vehicleStates?: VehicleStateObject[];
        selectedId?: string;
        loading: boolean;
        processing: boolean;
    };
    backUrl?: string;
    roles: Role[];
    stazkaExportModal: StazkaExportModalModel;
    removeTransportLoading: boolean;
    removeVehicleLoading: boolean;
    addVehicleLoading: boolean;
}

const defaultBar = {
    dateOpen: false,
    filterOpen: false
};

class RouteOverviewModule extends Component<Props, State> {
    private _logic: Logic;
    private _routeOverviewDataSubscription?: Subscription;
    private _routeOverviewLoadingSubscription?: Subscription;
    private _routeOverviewErrorSubscription?: Subscription;
    private _externalSystemDataSubscription?: Subscription;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const settings = this._logic.schedulingRouteOverview().settings();
        const defaults = confDefault.settings.scheduling.routeOverview.filter;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        this.state = {
            bar: {
                ...defaultBar,
                dateChanged:
                    (params.startDate && params.startDate !== defaults.dateRange.start) ||
                    (params.endDate && params.endDate !== defaults.dateRange.end)
                        ? true
                        : false
            },
            dateRange: {
                start: params.startDate ? params.startDate : defaults.dateRange.start,
                end: params.endDate ? params.endDate : defaults.dateRange.end
            },
            filter: {
                driversOpts: [],
                vehiclesOpts: [],
                transportStateOpts: [],
                driversChecked: settings.drivers,
                vehiclesChecked: settings.vehicles,
                transportStateChecked: settings.transportState
            },
            table: {
                data: {},
                loading: true,
                processing: false
            },
            roles: this._logic.auth().roles(),
            stazkaExportModal: {
                visible: false,
                monitredObjects: [],
                startDateTime: params.startDate
                    ? moment(params.startDate, DATE_FORMAT).toISOString()
                    : moment(defaults.dateRange.start, DATE_FORMAT).toISOString(),
                endDateTime: params.endDate
                    ? moment(params.endDate, DATE_FORMAT).toISOString()
                    : moment(defaults.dateRange.end, DATE_FORMAT).toISOString(),
                loading: true
            },
            removeTransportLoading: false,
            removeVehicleLoading: false,
            addVehicleLoading: false
        };
    }

    componentDidMount() {
        (window as any).app.routeOverview = this;

        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        this._filterData().then(async () => {
            await this._logic.schedulingRouteOverview().init();
            this._routeOverviewDataSubscription = this._logic.schedulingRouteOverview().onData.subscribe(transports => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: this._filterTable(transports),
                        monitoredObjects: this._logic.schedulingRouteOverview().monitoredObjects,
                        vehicleStates: this._logic.schedulingRouteOverview().vehicleStates,
                        loading: false,
                        processing: false
                    }
                }));
            });
            this._routeOverviewLoadingSubscription = this._logic
                .schedulingRouteOverview()
                .onLoading.subscribe(loading => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            loading: loading,
                            processing: loading
                        }
                    }));
                });
            this._routeOverviewErrorSubscription = this._logic.schedulingRouteOverview().onError.subscribe(_err => {
                message.error(this.props.t('common.error.loadDataError'));
            });

            if (params.selected) {
                this.setState(
                    state => ({
                        bar: {
                            ...state.bar,
                            backUrl: params.startDate && params.endDate && params.dispatcherBoard ? true : false
                        },
                        table: {
                            ...state.table,
                            selectedId: params.selected
                        },
                        backUrl:
                            params.startDate && params.endDate && params.dispatcherBoard
                                ? `${RouteNames.SCHEDULING_DISPATCHER_BOARD_DETAIL}?editId=${params.selected}`
                                : undefined
                    }),
                    async () => {
                        await this._logic.schedulingRouteOverview().getData({
                            dateRange: this.state.dateRange,
                            transportState: this.state.filter.transportStateChecked as TransportState[]
                        });
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                vehicleStates: this._logic.schedulingRouteOverview().vehicleStates
                            }
                        }));
                    }
                );
            } else {
                await this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
                this.setState(state => ({
                    table: {
                        ...state.table,
                        vehicleStates: this._logic.schedulingRouteOverview().vehicleStates
                    }
                }));
            }
        });
    }

    componentWillUnmount() {
        this._routeOverviewDataSubscription?.unsubscribe();
        this._routeOverviewLoadingSubscription?.unsubscribe();
        this._routeOverviewErrorSubscription?.unsubscribe();
        this._externalSystemDataSubscription?.unsubscribe();
    }

    render() {
        return (
            <div className={cn('scheduling module', { embedded: this.props.embedded })}>
                <RouteOverview
                    bar={this.state.bar}
                    dateRange={this.state.dateRange}
                    filter={this.state.filter}
                    helper={this.state.helper}
                    search={this.state.search}
                    table={this.state.table}
                    remove={this.state.remove}
                    vehicleRemove={!!this.state.removeVehicle}
                    vehiclesSelect={this.state.vehiclesSelect}
                    demoMode={this._logic.demo().isActive}
                    roles={this.state.roles}
                    stazkaExportModal={this.state.stazkaExportModal}
                    removeTransportLoading={this.state.removeTransportLoading}
                    removeVehicleLoading={this.state.removeVehicleLoading}
                    addVehicleLoading={this.state.addVehicleLoading}
                    onBarBackUrlClick={this._onBarBackUrl}
                    onBarResetClick={this._onBarResetClick}
                    onBarFilterClick={this._onBarFilterClick}
                    onBarHelperClick={this._onBarHelperClick}
                    onBarSearchClick={this._onBarSearchClick}
                    onBarStazkaExportClick={this._onBarStazkaExportClick}
                    onDateRangeChange={this._onDateRangeChange}
                    onFilterCancel={this._onFilterCancel}
                    onFilterConfirm={this._onFilterConfirm}
                    onSearchSubmit={this._onTableSearchSubmit}
                    onHelperClose={this._onHelperClose}
                    onRemoveCancel={this._onRemoveCancel}
                    onRemoveConfirm={this._onRemoveConfirm}
                    onTableAddVehicleClick={this._onTableAddVehicleClick}
                    onTableRemoveVehicleClick={this._onTableRemoveVehicleClick}
                    onRemoveVehicleCancel={this._onVehicleRemoveCancel}
                    onRemoveVehicleConfirm={this._onVehicleRemoveConfirm}
                    onTableRemoveTransportClick={this._onTableRemoveTransportClick}
                    onTableStazkaExportClick={this._onTableStazkaExportClick}
                    onTableRowClick={this._onTableRowClick}
                    onTableRowExpand={this._onTableRowClickExpand}
                    onTableSeeInJourneysClick={this._onTableSeeInJourneysClick}
                    onTableSetActiveClick={this._onTableSetActiveClick}
                    onTableSetCompleteClick={this._onTableSetCompleteClick}
                    onTableTrackOnMapClick={this._onTableTrackOnMapClick}
                    onVehicleSelectCancel={this._onVehicleSelectCancel}
                    onVehicleSelectConfirm={this._onVehicleSelectConfirm}
                    onStazkaExportModalCancel={this._onStazkaExportModalCancel}
                    onStazkaExportModalConfirm={this._onStazkaExportModalConfirm}
                />
            </div>
        );
    }

    private _onBarStazkaExportClick = (): void => {
        this.setState(state => ({
            stazkaExportModal: {
                ...state.stazkaExportModal,
                visible: true
            }
        }));
    };

    private _onStazkaExportModalCancel = (): void => {
        this.setState(state => ({
            stazkaExportModal: {
                ...state.stazkaExportModal,
                visible: false
            }
        }));
    };

    private _onStazkaExportModalConfirm = async (data: StazkaExportModalStateModel) => {
        this.setState(state => ({
            stazkaExportModal: {
                ...state.stazkaExportModal,
                loading: true
            }
        }));
        console.log('stazkax', data);
        if (this._logic.demo().isActive) {
            const response = await fetch(`/demo/transport-log-report-${data.transportId}.xlsx`);
            const blob = await response.blob();
            downloadFile(blob, `/demo/transport-log-report-${data.transportId}.xlsx`);
            this.setState(state => ({
                stazkaExportModal: {
                    ...state.stazkaExportModal,
                    loading: false,
                    visible: false
                }
            }));
        } else {
            if (this.state.stazkaExportModal?.transport?.id) {
                this._logic
                    .stazka()
                    .getTransportStazkaExport(this.state.stazkaExportModal.transport.id)
                    .then(() => {
                        this.setState(state => ({
                            stazkaExportModal: {
                                ...state.stazkaExportModal,
                                visible: false
                            }
                        }));
                    })
                    .catch(err => {
                        console.error(`Export data error, err: ${err}`);
                        message.error(this.props.t('StazkaExportModal.error'));
                    })
                    .finally(() => {
                        this.setState(state => ({
                            stazkaExportModal: {
                                ...state.stazkaExportModal,
                                loading: false
                            }
                        }));
                    });
            }
        }
    };

    private _onTableStazkaExportClick = (transport: TransportModel): void => {
        this.setState(state => ({
            stazkaExportModal: {
                ...state.stazkaExportModal,
                transport,
                visible: true
            }
        }));
    };

    private _filterNewAccepted = (r: TransportModel): boolean => {
        if (
            (this.state.filter.transportStateChecked.length === 0 ||
                this.state.filter.transportStateChecked.length >=
                    Object.values(TransportState).filter(v => v !== TransportState.Rejected).length) &&
            [TransportState.Accepted, TransportState.New].includes(r.state)
        ) {
            return true;
        } else if (
            this.state.filter.transportStateChecked.includes(TransportState.Accepted as string) &&
            r.state === TransportState.Accepted
        ) {
            return true;
        } else if (
            this.state.filter.transportStateChecked.includes(TransportState.New as string) &&
            r.state === TransportState.New
        ) {
            return true;
        } else {
            return false;
        }
    };

    private _filterDelayed = (r: TransportModel): boolean => {
        if (!this.state.filter.transportStateChecked.length && r.state === TransportState.Delayed) {
            return true;
        }
        return (
            this.state.filter.transportStateChecked.includes(TransportState.Delayed) &&
            r.state === TransportState.Delayed
        );
    };

    private _filterActive = (r: TransportModel): boolean => {
        if (!this.state.filter.transportStateChecked.length && r.state === TransportState.Active) {
            return true;
        }
        return (
            this.state.filter.transportStateChecked.includes(TransportState.Active) && r.state === TransportState.Active
        );
    };

    private _filterFinished = (r: TransportModel): boolean => {
        if (!this.state.filter.transportStateChecked.length && r.state === TransportState.Finished) {
            return true;
        }
        return (
            this.state.filter.transportStateChecked.includes(TransportState.Finished) &&
            r.state === TransportState.Finished
        );
    };

    private _filterVehicles = (r: TransportModel): boolean => {
        if (
            !this.state.filter.vehiclesChecked.length ||
            this.state.filter.vehiclesChecked.length >= this.state.filter.vehiclesOpts.length
        )
            return true;
        const vehicle = this.state.filter.vehiclesOpts.find(v => v.code === r.vehicle);
        if (vehicle) {
            return this.state.filter.vehiclesChecked.includes(vehicle.code);
        }
        return false;
    };

    private _filterDrivers = (r: TransportModel): boolean => {
        if (
            !this.state.filter.driversChecked.length ||
            this.state.filter.driversChecked.length >= this.state.filter.driversOpts.length
        )
            return true;
        const driver = this.state.filter.driversOpts.find(d => d.code === String(r.lastDriver?.driverId));
        if (driver) {
            return this.state.filter.driversChecked.includes(driver.code);
        }
        return false;
    };

    private _filterTable(data: TransportModel[]): RouteOverviewTableData {
        return {
            routesNew: data.filter(this._filterNewAccepted).filter(this._filterVehicles).filter(this._filterDrivers),
            routesDelayed: data.filter(this._filterDelayed).filter(this._filterVehicles).filter(this._filterDrivers),
            routesActive: data.filter(this._filterActive).filter(this._filterVehicles).filter(this._filterDrivers),
            routesFinished: data.filter(this._filterFinished).filter(this._filterVehicles).filter(this._filterDrivers)
        };
    }

    private _onFilterCancel = () => {
        this.setState(state => ({
            bar: {
                ...state.bar,
                ...defaultBar,
                filterOpen: !this.state.bar.filterOpen
            }
        }));
    };

    private _onFilterConfirm = (
        driversChecked: string[],
        vehiclesChecked: string[],
        transportStateChecked: string[]
    ) => {
        this._logic.exponea().trackEvent(exponea.module.schedulingRouteOverview, {
            status: exponea.status.actionTaken,
            action: exponea.action.filter
        });

        this._logic.schedulingRouteOverview().setSettings({
            drivers: driversChecked,
            vehicles: vehiclesChecked,
            transportState: transportStateChecked
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    ...defaultBar
                },
                filter: {
                    ...state.filter,
                    driversChecked,
                    vehiclesChecked,
                    transportStateChecked
                },
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this._logic.schedulingRouteOverview().setSettings({
                    drivers: driversChecked.length >= this.state.filter.driversOpts.length ? [] : driversChecked,
                    vehicles: vehiclesChecked.length >= this.state.filter.vehiclesOpts.length ? [] : vehiclesChecked,
                    transportState:
                        transportStateChecked.length >= this.state.filter.transportStateOpts.length
                            ? []
                            : transportStateChecked
                });
                this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
            }
        );
    };

    private _filterData = async () => {
        const drivers = await this._logic.users().drivers();
        const driversOpts = drivers.map<CheckboxGroupOpt>(u => ({
            code: u.id,
            label: `${u.name} ${u.surname}`,
            checked: false
        }));

        const vehicles = await this._logic.vehicles().getMonitoredObjectFilters(false, true);
        const vehiclesOpts = vehicles.map<CheckboxGroupOpt>(v => ({
            code: String(v.id) ?? '',
            label: getRegistrationNumber(!!v.disabledAt, v.registrationNumber),
            checked: false
        }));

        const transportStateOpts = Object.values(TransportState)
            .filter(v => v !== TransportState.Rejected)
            .map<CheckboxGroupOpt>(v => ({
                code: v,
                label: v,
                checked: false
            }));

        this.setState(state => ({
            filter: {
                ...state.filter,
                fullDWL: this._logic.vehicles().fullDWL,
                driversChecked:
                    state.filter.driversChecked.length > 0 && state.filter.driversChecked.length < driversOpts.length
                        ? state.filter.driversChecked
                        : driversOpts.map(v => v.code),
                driversOpts,
                vehiclesChecked:
                    state.filter.vehiclesChecked.length > 0 && state.filter.vehiclesChecked.length < vehiclesOpts.length
                        ? state.filter.vehiclesChecked
                        : vehiclesOpts.map(v => v.code),
                vehiclesOpts,
                transportStateChecked:
                    state.filter.transportStateChecked.length > 0 &&
                    state.filter.transportStateChecked.length < transportStateOpts.length
                        ? state.filter.transportStateChecked
                        : transportStateOpts.map(v => v.code),
                transportStateOpts: transportStateOpts
            },
            stazkaExportModal: {
                ...state.stazkaExportModal,
                monitredObjects: vehicles,
                loading: false
            }
        }));
    };

    private _onBarFilterClick = () => {
        this.setState(state => ({
            bar: { ...state.bar, ...defaultBar, filterOpen: !state.bar.filterOpen }
        }));
    };

    private _onBarBackUrl = () => {
        const { backUrl } = this.state;
        if (backUrl) {
            this.props.history.push(backUrl);
        }
    };

    private _onBarResetClick = () => {
        const defaults = confDefault.settings.scheduling.routeOverview.filter;
        this._onFilterConfirm(
            defaults.drivers,
            defaults.vehicles,
            this.state.filter.transportStateOpts.map(v => v.code)
        );
    };

    private _onTableSetActiveClick = (transportId: string) => {
        this.setState(state => ({
            table: {
                ...state.table,
                processing: true
            }
        }));
        this._logic
            .schedulingRouteOverview()
            .setTransportActive(transportId)
            .then(() => {
                this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
            });
    };

    private _onTableSetCompleteClick = (transportId: string) => {
        this.setState(state => ({
            table: {
                ...state.table,
                processing: true
            }
        }));
        this._logic
            .schedulingRouteOverview()
            .setTransportComplete(transportId)
            .then(() => {
                this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
            });
    };

    private _onRemoveConfirm = () => {
        this.setState({
            removeTransportLoading: true
        });
        this.state.remove &&
            this._logic
                .schedulingRouteOverview()
                .removeTransport(this.state.remove)
                .then(() => {
                    this._logic.schedulingRouteOverview().getData({
                        dateRange: this.state.dateRange,
                        transportState: this.state.filter.transportStateChecked as TransportState[]
                    });
                })
                .catch(err => {
                    console.error(`Could not remove selected transport, err: ${err}`);
                })
                .finally(() => {
                    this.setState({
                        removeTransportLoading: false,
                        remove: undefined,
                        removeVehicleLoading: false
                    });
                });
    };

    private _onRemoveCancel = () => {
        this.setState({
            remove: undefined
        });
    };

    private _onTableRemoveTransportClick = (transportId: string) => {
        this.setState({
            remove: transportId
        });
    };

    private _onTableTrackOnMapClick = (id: string) => {
        if (id) {
            this.props.history.push({
                pathname: RouteNames.TRACKING,
                search: qs.stringify({
                    vehicleId: id
                })
            });
        }
    };

    private _onTableSeeInJourneysClick = (_transportId: string) => {
        // Implementation
    };

    private _onTableRemoveVehicleClick = (transportId: string, vehicleId?: string) => {
        if (!vehicleId) {
            return;
        }

        this.setState({
            removeVehicle: {
                transportId,
                vehicleId
            }
        });
    };

    private _onVehicleRemoveConfirm = async () => {
        this.setState({
            removeVehicleLoading: true
        });
        const { removeVehicle } = this.state;

        if (removeVehicle?.transportId) {
            await this._logic
                .schedulingRouteOverview()
                .removeVehicleFromTransport(removeVehicle.transportId)
                .catch(_err => {
                    message.error('Cannot remove vehicle from transport');
                });
            await this._logic.schedulingRouteOverview().getData({
                dateRange: this.state.dateRange,
                transportState: this.state.filter.transportStateChecked as TransportState[]
            });
            this.setState(() => ({ removeVehicle: undefined, removeVehicleLoading: false }));
        }
    };

    private _onVehicleRemoveCancel = () => {
        this.setState(() => ({ removeVehicle: undefined }));
    };

    private _onTableAddVehicleClick = (transportId: string) => {
        this.setState(
            () => ({
                vehiclesSelect: {
                    vehicles: [],
                    loading: true,
                    selectedTransport: transportId,
                    puescMonitoredObjects: []
                }
            }),
            () => {
                Promise.all([
                    this._logic.transportLogic().fetchTransport(transportId),
                    this._logic.schedulingRouteOverview().loadAvailableVehiclesForTransport(transportId),
                    this._logic.externalSystem().loadData()
                ]).then(res => {
                    this.props.logic.externalSystem().loadData();
                    const [transport, vehicles, externalSystem] = res;
                    this.setState({
                        vehiclesSelect: {
                            ...this.state.vehiclesSelect,
                            vehicles: vehicles,
                            loading: false,
                            selectedTransportData: this._logic.transportLogic().toTransport(transport),
                            puescMonitoredObjects:
                                externalSystem.secrets.find(s => s.externalSystemAccess?.externalSystemName === 'PUESC')
                                    ?.monitoredObjectGroup?.monitoredObjects ?? []
                        }
                    });
                });
            }
        );
    };

    private _onVehicleSelectCancel = () => {
        this.setState({
            vehiclesSelect: undefined
        });
    };

    private _onVehicleSelectConfirm = async (vehicleId: string) => {
        this.setState({
            addVehicleLoading: true
        });
        const { vehiclesSelect } = this.state;
        const transport = this.state.table.data?.routesNew?.filter(
            transport => transport.id === vehiclesSelect?.selectedTransport
        )[0];
        const transportState: TransportState = this._logic
            .transportLogic()
            .getTransportState(true, transport?.places?.[0]?.rta);
        if (vehiclesSelect?.selectedTransport) {
            try {
                await this._logic
                    .schedulingRouteOverview()
                    .addVehicleToTransport(vehicleId, vehiclesSelect.selectedTransport, transportState)
                    .catch(() => {
                        message.error(this.props.t('RouteOverviewVehicleSelect.messages.addVehicleError'));
                    });

                await this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
                this.setState({
                    addVehicleLoading: false,
                    vehiclesSelect: undefined
                });
                message.success(this.props.t('RouteOverviewVehicleSelect.messages.addVehicleSuccess'));
            } catch (e) {
                console.error(`Add vehicle to transport failed, err: ${e}`);
                message.error(this.props.t('RouteOverviewVehicleSelect.messages.addVehicleError'));
            }
        }
    };

    private _onBarSearchClick = () => {
        if (this.state.search) {
            this.setState({ search: undefined });
        } else {
            this.setState({ search: { text: '' } });
        }
    };

    private _onDateRangeChange = (dateRange: DateRange) => {
        this._logic.exponea().trackEvent(exponea.module.schedulingRouteOverview, {
            status: exponea.status.actionTaken,
            action: exponea.action.calendar
        });

        const defaults = confDefault.settings.statistics.expenses.filter;
        this.setState(
            state => ({
                dateRange: dateRange,
                bar: {
                    ...state.bar,
                    dateOpen: !state.bar.dateOpen,
                    dateChanged:
                        dateRange.start !== defaults.dateRange.start || dateRange.end !== defaults.dateRange.end
                },
                table: {
                    ...state.table,
                    loading: true
                },
                stazkaExportModal: {
                    ...state.stazkaExportModal,
                    startDateTime: moment(dateRange.start, DATE_FORMAT).toISOString(),
                    endDateTime: moment(dateRange.end, DATE_FORMAT).toISOString()
                }
            }),
            () => {
                this.props.history.push({
                    search: qs.stringify({
                        startDate: dateRange.start,
                        endDate: dateRange.end
                    } as RouteParams)
                });
                this._logic.schedulingRouteOverview().getData({
                    dateRange: this.state.dateRange,
                    transportState: this.state.filter.transportStateChecked as TransportState[]
                });
            }
        );
    };

    private _onTableRowClick = (id: string, vehicleId: string) => {
        const transport = [
            ...(this.state.table.data?.routesActive ?? []),
            ...(this.state.table.data?.routesDelayed ?? []),
            ...(this.state.table.data?.routesFinished ?? []),
            ...(this.state.table.data?.routesNew ?? [])
        ].find(transport => transport.id === id);
        this.props.history.push({
            pathname:
                this._logic.conf.settings.plannerOldAvailable && transport?.version === 2
                    ? RouteNames.SCHEDULING_PLANNER_NEW
                    : RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                editId: id,
                vehicleId: vehicleId ? vehicleId : undefined,
                startDate: this.state.dateRange.start,
                endDate: this.state.dateRange.end
            })
        });
    };

    private _onTableRowClickExpand = (type: keyof RouteOverviewTableData) => {
        if (this.state.table?.data) {
            if (this.state.table.data[type]) {
                this.setState({
                    table: {
                        ...this.state.table,
                        data: { ...this.state.table.data, [type]: undefined },
                        loading: false
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...this.state.table,
                        data: {
                            ...this.state.table.data,
                            [type]: this._filterTable(this._logic.schedulingRouteOverview().data)[type]
                        },
                        loading: false
                    }
                });
            }
        }
    };

    private _onTableSearchSubmit = ({ text, type }: SearchData) => {
        this.setState(state => ({
            search: { ...state.search, text, type }
        }));
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'routeplanning';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(RouteOverviewModule));
