import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import moment, { Moment } from 'moment';
import TableBar from 'common/components/TableBar';
import { Tabs } from 'common/components';
import qa from 'qa-selectors';
import * as icons from 'resources/images/common';
import { truck, lightVehicle } from 'resources/images/driver-behavior';
import Search from 'common/components/Search';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';
import DtcoCalendar from 'common/components/DtcoCalendar';

export enum DriverBehaviorType {
    Light = 'light_vehicles',
    Trucks = 'trucks'
}

interface Props {
    date?: string;
    filter: DriverBehaviorType;
    exportEnable: boolean;
    searchEnabled?: boolean;
    exportProcessing: boolean;
    clientFleetType: ReadOnlyClientFleetTypeEnum;
    onSearch?: (text: string) => void;
    onFilterChange?: (key: DriverBehaviorType) => void;
    onDateRangeChange?: (value: string) => void;
    onExportClick?: () => void;
    onHelperClick?: () => void;
    onBarDateNextClick?: () => void;
    onBarDatePreviousClick?: () => void;
}

export default function DriverBehaviorBar(props: Props) {
    const { t } = useTranslation();

    function onDateRangeChange(from: Moment | null) {
        if (from) {
            props.onDateRangeChange?.(from?.toISOString());
        }
    }

    function onFilterLightVehicles() {
        props.onFilterChange?.(DriverBehaviorType.Trucks);
    }

    function onFilterTrucks() {
        props.onFilterChange?.(DriverBehaviorType.Light);
    }

    return (
        <TableBar
            heading={t('DriverListModule.title')}
            actions={[
                <Button
                    type="primary"
                    size="large"
                    disabled={!props.exportEnable}
                    loading={props.exportProcessing}
                    onClick={props.onExportClick}
                    qa={qa.driverBehaviour.btnExport}
                    icon={<img src={icons.export} alt={t('common.export')} />}
                >
                    {t('common.export')}
                </Button>
            ]}
            filters={[
                [ReadOnlyClientFleetTypeEnum.Mixed].includes(props.clientFleetType) && (
                    <div className="driver-behavior-filter-switch">
                        <Tabs
                            size="large"
                            onTabClick={
                                props.filter === DriverBehaviorType.Trucks ? onFilterTrucks : onFilterLightVehicles
                            }
                            activeKey={props.filter === DriverBehaviorType.Trucks ? '1' : '2'}
                        >
                            <Tabs.TabPane
                                tab={
                                    <img
                                        data-qa={qa.driverBehaviour.filter.btnTrucks}
                                        src={truck}
                                        alt={t('common.vehicle')}
                                    />
                                }
                                key="1"
                            />
                            <Tabs.TabPane
                                tab={
                                    <img
                                        data-qa={qa.driverBehaviour.filter.btnLight}
                                        src={lightVehicle}
                                        alt={t('common.vehicle')}
                                    />
                                }
                                key="2"
                            />
                        </Tabs>
                    </div>
                ),
                <DtcoCalendar
                    startDate={props.date ?? moment().toISOString()}
                    onNextChange={props.onBarDateNextClick}
                    onPreviousChange={props.onBarDatePreviousClick}
                    onDatePickerChange={onDateRangeChange}
                />,
                <Search
                    searchActive={true}
                    qa={qa.driverBehaviour.filter.inputDriver}
                    placeholder={t('DriverBehaviorFilter.search')}
                    onSearch={props.onSearch}
                />
            ]}
            onHelperClick={props.onHelperClick}
        />
    );
}
