import { Logic } from '../../logic';

export class UserGroups {
    constructor(private _logic: Logic) {}

    public async getUserGroups() {
        const userGroups = await this._logic.api().userGroupApi.userGroupList({});
        return userGroups.results;
    }
}
