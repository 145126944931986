import { useLogic } from 'App';
import Descriptions from 'common/components/Descriptions';
import { LayoutDetail } from 'common/components/Layout/Detail';
import { ReadOnlyContactListTypeEnum } from 'generated/new-main';
import { observer } from 'mobx-react';
import qa from 'qa-selectors';
import { useTranslation } from 'react-i18next';

export default observer(function ContactListDetail() {
    const { t } = useTranslation();
    const logic = useLogic();

    const getDisplayedContactTypes = (types: ReadOnlyContactListTypeEnum[]) => {
        if (types.length === 0) {
            return '';
        }

        let typesList = [];

        if (types.includes(ReadOnlyContactListTypeEnum.Client)) {
            typesList.push(
                <span data-qa={qa.contacts.detail.fieldTypeClient}>
                    {t('ContactList.tableClientTypeValues.client')}
                </span>
            );
        }

        if (types.includes(ReadOnlyContactListTypeEnum.Supplier)) {
            if (typesList.length > 0) {
                typesList.push(', ');
            }
            typesList.push(
                <span data-qa={qa.contacts.detail.fieldTypeSupplier}>
                    {t('ContactList.tableClientTypeValues.supplier')}
                </span>
            );
        }

        if (types.includes(ReadOnlyContactListTypeEnum.Consignee)) {
            if (typesList.length > 0) {
                typesList.push(', ');
            }
            typesList.push(
                <span data-qa={qa.contacts.detail.fieldTypeConsignee}>
                    {t('ContactList.tableClientTypeValues.consignee')}
                </span>
            );
        }

        return typesList;
    };

    return (
        <div className="vehicle-detail-card">
            <LayoutDetail
                firstTitle={t('ClientContactListForm.basicInformationTitle')}
                firstLeft={
                    <>
                        <Descriptions layout="vertical" column={1}>
                            <Descriptions.Item label={t('ClientContactListForm.name')}>
                                <span data-qa={qa.contacts.detail.fieldName}>
                                    {logic.contactListLogic().selectedContact?.name}
                                </span>
                            </Descriptions.Item>

                            <Descriptions.Item label={t('ClientContactListForm.businessId')}>
                                <span data-qa={qa.contacts.detail.fieldBussinessId}>
                                    {logic.contactListLogic().selectedContact?.businessId}
                                </span>
                            </Descriptions.Item>

                            <Descriptions.Item label={t('ClientContactListForm.taxId')}>
                                <span data-qa={qa.contacts.detail.fieldTaxId}>
                                    {logic.contactListLogic().selectedContact?.taxId}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ClientContactListForm.type')}>
                            <span>
                                {getDisplayedContactTypes(logic.contactListLogic().selectedContact?.type ?? [])}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.vat')}>
                            <span data-qa={qa.contacts.detail.fieldVat}>
                                {logic.contactListLogic().selectedContact?.vat}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.otherIdentificationNumber')}>
                            <span data-qa={qa.contacts.detail.fieldOtherNotificationNm}>
                                {logic.contactListLogic().selectedContact?.otherIdentificationNumber}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondTitle={t('ClientContactListForm.addressOfResidenceTitle')}
                secondLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ClientContactListForm.country')}>
                            <span data-qa={qa.contacts.detail.fieldCountry}>
                                {logic.contactListLogic().selectedContact?.country?.name}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.postalCode')}>
                            <span data-qa={qa.contacts.detail.fieldPostalCode}>
                                {logic.contactListLogic().selectedContact?.postalCode}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.houseNumber')}>
                            <span data-qa={qa.contacts.detail.fieldHouseNumber}>
                                {logic.contactListLogic().selectedContact?.houseNumber}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('ClientContactListForm.city')}>
                            <span data-qa={qa.contacts.detail.fieldCity}>
                                {logic.contactListLogic().selectedContact?.city}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.street')}>
                            <span data-qa={qa.contacts.detail.fieldStreet}>
                                {logic.contactListLogic().selectedContact?.street}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ClientContactListForm.premisesNumber')}>
                            <span data-qa={qa.contacts.detail.fieldPremisesNumber}>
                                {logic.contactListLogic().selectedContact?.premisesNumber}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
});
