import * as Yup from 'yup';
import { useState } from 'react';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button } from '../../components';
import { InputField, SelectField } from '../../fields';
import { EmissionClass, TunnelType } from 'modules/management/modules/fleet/FleetModule';
import { VehicleProfileModel } from '../../model/transports';
import qa from 'qa-selectors';
import { M, T } from 'domain-constants';
import { AvailableCurrencies } from 'common/model/currency';
import { VehicleProfileVehicle } from 'resources/images/planner';
import { formikValuesIsChanged } from 'common/components/ModalForm';

interface Props extends WithTranslation {
    type?: 'default' | 'edit';
    initialValues?: Partial<VehicleProfileModel>;
    demoMode?: boolean;
    onSubmit: (values: VehicleProfileModel) => void;
    onCancel?: () => void;
}

function VehicleProfileForm({ onSubmit, initialValues, t, onCancel, type, demoMode }: Props) {
    let form: FormikProps<VehicleProfileModel>;

    const [_change, _setChange] = useState(false);

    const handleValuesChange = (name: string, value: any) => {
        _setChange(formikValuesIsChanged(form, name, value));
    };

    const handleNumberValuesChange = (name: string, value: any) => {
        if (value !== false) {
            _setChange(formikValuesIsChanged(form, name, value));
        }
    };

    const schema = Yup.object().shape({
        height: Yup.number()
            .nullable()
            .min(1, t('validator.minValue', { min: 1 }))
            .max(5, t('validator.maxValue', { max: 5 }))
            .required(t('common.required')),
        length: Yup.number()
            .nullable()
            .min(1, t('validator.minValue', { min: 1 }))
            .max(20, t('validator.maxValue', { max: 20 }))
            .required(t('common.required')),
        name: Yup.string().required(t('common.required')),
        numberOfAxles: Yup.number()
            .min(0, t('validator.minValue', { min: 0 }))
            .max(10, t('validator.maxValue', { max: 10 }))
            .required(t('common.required')),
        pricePerKm: Yup.number()
            .nullable()
            .min(0, t('validator.minValue', { min: 0 }))
            .required(t('common.required')),
        trailersCount: Yup.number()
            .nullable()
            .min(0, t('validator.minValue', { min: 0 }))
            .max(3, t('validator.maxValue', { max: 3 }))
            .required(t('common.required')),
        euroClass: Yup.string().required(t('common.required')),
        tunnel: Yup.string().required(t('common.required')),
        weight: Yup.number()
            .nullable()
            .min(0, t('validator.minValue', { min: 0 }))
            .max(44, t('validator.maxValue', { max: 44 }))
            .required(t('common.required')),
        width: Yup.number()
            .nullable()
            .min(1, t('validator.minValue', { min: 1 }))
            .max(5, t('validator.maxValue', { max: 5 }))
            .required(t('common.required'))
    });

    function handleOnSubmit(values: VehicleProfileModel) {
        onSubmit?.(values);
    }

    return (
        <Formik<VehicleProfileModel>
            initialValues={{
                id: initialValues?.id,
                isDefault: initialValues?.isDefault,
                name: initialValues?.name,
                length: initialValues?.length,
                height: initialValues?.height,
                width: initialValues?.width,
                weight: initialValues?.weight,
                tunnel: initialValues?.tunnel,
                euroClass: initialValues?.euroClass,
                trailersCount: initialValues?.trailersCount,
                numberOfAxles: initialValues?.numberOfAxles,
                pricePerKm: initialValues?.pricePerKm
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<VehicleProfileModel>) => {
                form = formik;
                return (
                    <div className="vehicle-profile-form">
                        <Form
                            onChange={event => {
                                const element = event.target as HTMLFormElement;
                                const type = element.attributes.getNamedItem('type')?.value;
                                handleValuesChange(
                                    element.name,
                                    type === 'checkbox'
                                        ? element.checked
                                        : type === 'number'
                                        ? parseFloat(element.value)
                                        : element.value
                                );
                            }}
                        >
                            <div className=" modal-form-content">
                                <Row gutter={32} align="middle">
                                    <Col span={13}>
                                        <div className="vehicle-profile-content">
                                            {!initialValues?.isDefault && (
                                                <>
                                                    <Row>
                                                        <Col span={24}>
                                                            <InputField
                                                                name="name"
                                                                label={t('VehicleProfileForm.name')}
                                                                placeholder={t('VehicleProfileForm.name')}
                                                                defaultValue={initialValues?.name}
                                                                qa={qa.planner.createVehicleProfile.inputName}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <h3>{t('VehicleProfileForm.detailTitle')}</h3>
                                                </>
                                            )}
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <SelectField
                                                        options={Object.keys(TunnelType).map(d => ({
                                                            value: d,
                                                            label: t(
                                                                `VehicleProfileForm.tunnelOptions.${d.toLowerCase()}`
                                                            )
                                                        }))}
                                                        placeholder={t('common.select')}
                                                        name="tunnel"
                                                        label={t('VehicleProfileForm.tunnel')}
                                                        defaultValue={initialValues?.tunnel}
                                                        qa={qa.planner.createVehicleProfile.selectTunnelType}
                                                        onChange={value => handleNumberValuesChange('tunnel', value)}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <SelectField
                                                        options={[
                                                            {
                                                                label: t('common.none'),
                                                                value: '0'
                                                            },
                                                            {
                                                                label: '1',
                                                                value: '1'
                                                            },
                                                            {
                                                                label: '2',
                                                                value: '2'
                                                            },
                                                            {
                                                                label: '3',
                                                                value: '3'
                                                            }
                                                        ]}
                                                        name="trailersCount"
                                                        placeholder={t('common.select')}
                                                        label={t('VehicleProfileForm.trailerCount')}
                                                        defaultValue={initialValues?.trailersCount}
                                                        qa={qa.planner.createVehicleProfile.selectTrailerCount}
                                                        onChange={value =>
                                                            handleNumberValuesChange('trailersCount', value)
                                                        }
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="numberOfAxles"
                                                        label={t('VehicleProfileForm.numberOfAxles')}
                                                        placeholder={'0'}
                                                        defaultValue={initialValues?.numberOfAxles}
                                                        qa={qa.planner.createVehicleProfile.inputAxlesCount}
                                                        onChange={value =>
                                                            handleNumberValuesChange('numberOfAxles', value)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="length"
                                                        postfix={`[${M}]`}
                                                        label={t('VehicleProfileForm.length')}
                                                        placeholder={'0'}
                                                        min={1}
                                                        max={20}
                                                        defaultValue={initialValues?.length}
                                                        qa={qa.planner.createVehicleProfile.inputLength}
                                                        onChange={value => {
                                                            handleNumberValuesChange('length', value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="height"
                                                        postfix={`[${M}]`}
                                                        label={t('VehicleProfileForm.height')}
                                                        placeholder={'0'}
                                                        min={1}
                                                        max={5}
                                                        defaultValue={initialValues?.height}
                                                        qa={qa.planner.createVehicleProfile.inputHeight}
                                                        onChange={value => handleNumberValuesChange('height', value)}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="width"
                                                        postfix={`[${M}]`}
                                                        label={t('VehicleProfileForm.width')}
                                                        placeholder={'0'}
                                                        min={1}
                                                        max={5}
                                                        defaultValue={initialValues?.width}
                                                        qa={qa.planner.createVehicleProfile.inputWidth}
                                                        onChange={value => {
                                                            handleNumberValuesChange('width', value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="weight"
                                                        postfix={`[${T}]`}
                                                        label={t('PlannerPlacesTask.totalWeight')}
                                                        placeholder={'0'}
                                                        min={0}
                                                        max={50}
                                                        defaultValue={initialValues?.weight}
                                                        qa={qa.planner.createVehicleProfile.inputWeight}
                                                        onChange={value => handleNumberValuesChange('weight', value)}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <InputField.Number
                                                        name="pricePerKm"
                                                        label={t('VehicleProfileForm.pricePerKm')}
                                                        precision={2}
                                                        postfix={`[${AvailableCurrencies.EUR}]`}
                                                        min={0}
                                                        defaultValue={initialValues?.pricePerKm}
                                                        qa={qa.planner.createVehicleProfile.inputPricePerKm}
                                                        onChange={value =>
                                                            handleNumberValuesChange('pricePerKm', value)
                                                        }
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <SelectField
                                                        label={t('common.ems')}
                                                        name="euroClass"
                                                        options={Object.values(EmissionClass).map(emissionClass => {
                                                            return {
                                                                value: emissionClass,
                                                                label: String(EmissionClass[emissionClass])
                                                            };
                                                        })}
                                                        placeholder={t('common.select')}
                                                        defaultValue={initialValues?.euroClass}
                                                        qa={qa.planner.createVehicleProfile.selectEmisionClass}
                                                        onChange={value => handleNumberValuesChange('euroClass', value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <img
                                            className="vehicle-image"
                                            src={VehicleProfileVehicle}
                                            alt="vehicle-profile-vehicle"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Row gutter={12} justify="end" className="buttons-group">
                                <Col>
                                    <Button
                                        name="cancel"
                                        onClick={onCancel}
                                        qa={qa.planner.createVehicleProfile.btnCancel}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        disabled={form.isSubmitting || !_change || demoMode}
                                        type="primary"
                                        htmlType="submit"
                                        qa={qa.planner.createVehicleProfile.btnSubmit}
                                    >
                                        {type === 'edit' ? t('common.edit') : t('common.confirm')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(VehicleProfileForm);
