/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManualCountryInterval,
    ManualCountryIntervalFromJSON,
    ManualCountryIntervalFromJSONTyped,
    ManualCountryIntervalToJSON,
    MuDataProcessingJoinersCountryIntervalManual,
    MuDataProcessingJoinersCountryIntervalManualFromJSON,
    MuDataProcessingJoinersCountryIntervalManualFromJSONTyped,
    MuDataProcessingJoinersCountryIntervalManualToJSON,
} from './';

/**
 * 
 * @export
 * @interface ManualCountryIntervalInDb
 */
export interface ManualCountryIntervalInDb {
    /**
     * 
     * @type {ManualCountryInterval}
     * @memberof ManualCountryIntervalInDb
     */
    countryInterval: ManualCountryInterval;
    /**
     * 
     * @type {MuDataProcessingJoinersCountryIntervalManual}
     * @memberof ManualCountryIntervalInDb
     */
    manual?: MuDataProcessingJoinersCountryIntervalManual;
    /**
     * 
     * @type {string}
     * @memberof ManualCountryIntervalInDb
     */
    id: string;
}

export function ManualCountryIntervalInDbFromJSON(json: any): ManualCountryIntervalInDb {
    return ManualCountryIntervalInDbFromJSONTyped(json, false);
}

export function ManualCountryIntervalInDbFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualCountryIntervalInDb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryInterval': ManualCountryIntervalFromJSON(json['country_interval']),
        'manual': !exists(json, 'manual') ? undefined : MuDataProcessingJoinersCountryIntervalManualFromJSON(json['manual']),
        'id': json['id'],
    };
}

export function ManualCountryIntervalInDbToJSON(value?: ManualCountryIntervalInDb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_interval': ManualCountryIntervalToJSON(value.countryInterval),
        'manual': MuDataProcessingJoinersCountryIntervalManualToJSON(value.manual),
        'id': value.id,
    };
}


