import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import PartnerPairs from 'modules/partner/components/PartnerPairs';
import { Row, Col } from 'antd';
import { PartnerPairingKey } from 'common/model/partner-pairing';
import { PairingItemType } from 'logic/partner/partner';

interface Props {
    model: PartnerCompanyModel;
    unpairHide?: boolean;
    onUnpair?: (linked: PairingItemType) => void;
}

export default function PartnersLinkedItems(props: Props) {
    function onUnpair(id: string, key: PartnerPairingKey) {
        const linked: PairingItemType = {
            id,
            key
        };
        props.onUnpair?.(linked);
    }

    return props.model ? (
        <Row>
            <Col span={24}>
                <div className="partner-pairing-list">
                    {props.model?.links && (
                        <PartnerPairs links={props.model?.links} onUnpair={onUnpair} unpairHide={props.unpairHide} />
                    )}
                </div>
            </Col>
        </Row>
    ) : (
        <></>
    );
}
