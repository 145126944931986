import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { Row, Col, Steps, Divider } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useLogic } from 'App';
import { H, KM } from 'domain-constants';
import { TransportModel } from 'common/model/transports';
import { Button, Modal, Typography, Tabs, Checkbox } from 'common/components';
import { iconInformation } from 'resources/images/common';
import { jitPayLogo } from 'resources/images/dispatcher-board';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { NegotiableAttributes, NegotiationState } from 'generated/dff-api';
import DFFDataBlock from '../DFFDataBlock';
import DFFJitPayExplanationTooltip from '../DFFJitPayExplanationTooltip';
import DFFProposalDetailPlaces from './ui/DFFProposalDetailPlaces/DFFProposalDetailPlaces';
import Alert from 'common/components/Alert';
import DFFProposePrice from './ui/DFFProposePrice';
import DFFProposalDetailLoads from './ui/DFFProposalDetailLoads/DFFProposalDetailLoads';
import DffProposalDetailData from './ui/DFFProposalDetailData/DFFProposalDetailData';
import DFFProposalDetailRoute from './ui/DFFProposalDetailRoute/DFFProposalDetailRoute';
import { exponea } from 'logic/exponea';

enum ProposalDetailTabs {
    OVERVIEW = 'overview-tab',
    VEHICLE_REQUIREMENTS = 'vehicle-requirements-tab',
    SHIPMENT_DETAILS = 'shipment-details-tab',
    LOADING_UNLOADING = 'loading-unloading-tab',
    TRANSPORT_ORDER = 'transport-order-tab'
}

enum ProposalDetailSteps {
    PROPOSAL = 0,
    ACCEPTANCE,
    TRANSPORT_ORDER
}

interface Props {
    visible: boolean;
    proposal: TransportModel;
    onClose: () => void;
    onAccept?: (proposalId: string, useJitpay: boolean) => void;
    onDecline?: (proposalId: string) => void;
    onRemove?: (proposalId: string) => void;
    onSendToShipperClick?: (proposalId: string, proposedPrice: number, useJitpay: boolean) => void;
}

export default function DFFProposalDetail({ proposal, ...props }: Props) {
    const [activeTab, setActiveTab] = useState<string>(ProposalDetailTabs.OVERVIEW);
    const [activeStep, setActiveStep] = useState<number>(ProposalDetailSteps.PROPOSAL);
    const [expiresIn, setExpiresIn] = useState(0);
    const [transportOrderPdf, setTransportOrderPdf] = useState<string | null>('');
    const [proposedPrice, setProposedPrice] = useState(proposal.metadata?.finalPrice ?? 0);
    const [useJitpay, setUseJitpay] = useState(!!proposal.metadata?.proposal?.useJitpay);
    const [profit, setProfit] = useState<number | null>(null);
    const [isProposePriceEdit, setIsProposePriceEdit] = useState(false);
    const { t } = useTranslation();
    const logic = useLogic();
    const isNegotiatedByPrice = proposal.metadata?.proposal?.negotiableAttributes?.includes(NegotiableAttributes.Price);

    useEffect(() => {
        if (proposal.metadata?.proposal?.attachments.length) {
            logic
                .dffProposals()
                .getTransportOrderPdf(
                    proposal.metadata?.proposal?.shipmentId,
                    proposal.metadata?.proposal?.attachments[0].id //proposal has only one attachment
                )
                .then(pdf => setTransportOrderPdf(window.URL.createObjectURL(pdf)))
                .catch(error => {
                    console.error(error);
                });
        }

        setExpiresIn(moment(proposal.metadata?.expiration).diff(new Date()));
        const interval = setInterval(() => {
            setExpiresIn(moment(proposal.metadata?.expiration).diff(new Date()));
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activeStep === ProposalDetailSteps.TRANSPORT_ORDER) {
            setActiveTab(ProposalDetailTabs.TRANSPORT_ORDER);
        }

        const proposalState = proposal.metadata?.proposal?.state;
        if (
            proposalState === NegotiationState.Declined ||
            proposalState === NegotiationState.ShipperOffer ||
            proposalState === NegotiationState.CarrierOffer
        ) {
            setActiveStep(ProposalDetailSteps.ACCEPTANCE);
        }

        if (proposal.metadata?.proposal?.state === NegotiationState.Completed) {
            setActiveStep(ProposalDetailSteps.TRANSPORT_ORDER);
        }
    }, [activeStep, proposal.metadata?.proposal?.state]);

    function handleTabChange(tabKey: string) {
        setActiveTab(tabKey);

        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            view: tabKey
        });
    }

    function handleProposedPriceChange(proposedPrice: any) {
        const offeredPrice = proposal.metadata?.finalPrice;
        const profit =
            offeredPrice !== null && offeredPrice !== undefined
                ? ((proposedPrice - offeredPrice) / offeredPrice) * 100
                : null;

        setProposedPrice(proposedPrice);
        setProfit(profit);

        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            activity: exponea.action.propose_price
        });
    }

    const getCargoSpaceLabel = () => {
        const cargoSpace = proposal.metadata?.proposal?.vehicle.cargoSpace;
        return cargoSpace ? `${cargoSpace.width}m x ${cargoSpace.height}m x ${cargoSpace.length}m` : '-';
    };

    const handleUseJitpayChange = (event: CheckboxChangeEvent) => {
        setUseJitpay(event.target.checked);
    };

    const onAccept = () => {
        proposal.id && props.onAccept?.(proposal.id, useJitpay);

        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            activity: exponea.action.accept,
            jitpay: useJitpay
        });
    };

    const trackEvent = (config: object) => {
        logic.exponea().trackEvent(exponea.module.schedulingDispatcherBoard, config);
    };

    const onDecline = () => {
        proposal.id && props.onDecline?.(proposal.id);

        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            activity: exponea.action.decline,
            jitpay: useJitpay
        });
    };

    const onSendToShipperClick = () => {
        props.onSendToShipperClick?.(proposal.id!, proposedPrice, useJitpay);

        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            activity: exponea.action.send_proposed_price
        });
    };

    const onDownloadPdf = () => {
        trackEvent({
            status: ProposalDetailSteps[activeStep].toLowerCase(),
            activity: exponea.action.download_order
        });
    };

    const DistanceSection = () => {
        return (
            <section>
                <Row>
                    <Col>
                        <Typography.Title level={4}>{t('DispatcherBoardProposalDetailDff.distance')}</Typography.Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('DispatcherBoardProposalDetailDff.distToPickUp')}
                            value={
                                proposal.metadata?.distanceToTransport
                                    ? `${Math.round(proposal.metadata?.distanceToTransport / 1000)} ${KM}`
                                    : '-'
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('common.duration')}
                            value={`${
                                moment.duration(proposal.metadata?.totalDuration, 'seconds').format('h:mm') ?? '-'
                            } ${H}`}
                        />
                    </Col>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('DispatcherBoardProposalDetailDff.totalDistance')}
                            value={
                                proposal.metadata?.transportDistance
                                    ? `${Math.round(proposal.metadata?.transportDistance / 1000)} ${KM}`
                                    : '-'
                            }
                        />
                    </Col>
                </Row>
            </section>
        );
    };

    const CostsSection = () => {
        return (
            <section>
                <Row>
                    <Col>
                        <Typography.Title level={4}>{t('DispatcherBoardProposalDetailDff.costs')}</Typography.Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('DispatcherBoardProposalDetailDff.pickupFuelCosts')}
                            value={
                                proposal.metadata?.pickupCosts
                                    ? `${numeral(proposal.metadata?.pickupCosts).format('0,0')} €`
                                    : '-'
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('DispatcherBoardProposalDetailDff.transportFuelCosts')}
                            value={
                                proposal.metadata?.transportCosts
                                    ? `${numeral(proposal.metadata?.transportCosts).format('0,0')} €`
                                    : '-'
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <DFFDataBlock
                            label={t('DispatcherBoardProposalDetailDff.fuelCostsKm')}
                            value={
                                proposal.costPerKm?.cost
                                    ? `${numeral(proposal.costPerKm?.cost).format('0,0.00')} €`
                                    : '-'
                            }
                        />
                    </Col>
                </Row>
            </section>
        );
    };

    const ShipmentLoadType = () => {
        return (
            <DFFDataBlock
                label={t('DispatcherBoardProposalDetailDff.loadType')}
                value={proposal.metadata?.proposal?.places[0].loads // loadingDetail is on index 0
                    ?.map(load => `${load.count ?? 0} x ${load.loadType}`)
                    .join(',')}
            />
        );
    };

    return (
        <Modal
            className="dff-proposal-detail"
            width={940}
            visible={props.visible}
            footer={null}
            title={null}
            closable={false}
            closeIcon={<></>}
        >
            <div className="dff-proposal-detail-header">
                <div className="dff-proposal-detail-title">
                    <Typography.Title level={3}>
                        {t('DispatcherBoardProposalDetailDff.proposalDetail')}
                    </Typography.Title>
                </div>
                <span className="dff-proposal-detail-id">{`ID: ${proposal?.id}`}</span>
                <div className="dff-proposal-detail-close">
                    <Button size="middle" onClick={props.onClose}>
                        {t('common.close')}
                    </Button>
                </div>
            </div>

            <div className="dff-proposal-detail-content">
                <div className="dff-proposal-detail-steps">
                    <Row>
                        <Col span={24}>
                            <Steps current={activeStep}>
                                <Steps.Step title={t('DispatcherBoardProposalDetailDff.proposal')} />
                                <Steps.Step title={t('DispatcherBoardProposalDetailDff.acceptance')} />
                                <Steps.Step title={t('DispatcherBoardProposalDetailDff.transportOrder')} />
                            </Steps>
                        </Col>
                    </Row>
                </div>
                {proposal.metadata?.proposal?.state === NegotiationState.Completed && (
                    <Alert
                        className="dff-proposal-detail-alert"
                        message={t('DispatcherBoardProposalDetailDff.confirm.title')}
                        description={t('DispatcherBoardProposalDetailDff.confirm.text')}
                        type="success"
                        showIcon
                    />
                )}
                {proposal.metadata?.proposal?.state === NegotiationState.CarrierOffer && (
                    <Alert
                        className="dff-proposal-detail-alert"
                        message=""
                        description={t('DispatcherBoardProposalDetailDff.proposalReviewText')}
                        type="info"
                        showIcon
                    />
                )}
                {proposal.metadata?.proposal?.state === NegotiationState.Declined && (
                    <Alert
                        className="dff-proposal-detail-alert"
                        message=""
                        description={t('DispatcherBoardProposalDetailDff.proposalRejectedText')}
                        type="warning"
                        showIcon
                    />
                )}
                <div className="dff-proposal-detail-tabs">
                    <Tabs activeKey={activeTab} onChange={handleTabChange}>
                        {/* Overview tab */}
                        <Tabs.TabPane
                            tab={t('DispatcherBoardProposalDetailDff.overview')}
                            key={ProposalDetailTabs.OVERVIEW}
                        >
                            <div className="dff-proposal-detail-tab">
                                <div className="dff-proposal-detail-tab-content">
                                    <DFFProposalDetailRoute places={proposal.metadata?.proposal?.places!} />

                                    <Row gutter={48}>
                                        <Col xs={24} md={proposal.metadata?.companyInfo ? 12 : 24}>
                                            <DistanceSection />

                                            {/* Shipment */}
                                            <section>
                                                <Row>
                                                    <Col>
                                                        <Typography.Title level={4}>
                                                            {t('DispatcherBoardProposalDetailDff.shipment')}
                                                        </Typography.Title>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8}>
                                                    <Col span={24}>
                                                        <ShipmentLoadType />
                                                    </Col>
                                                </Row>
                                            </section>

                                            <CostsSection />
                                        </Col>

                                        {/* Company info */}
                                        {proposal.metadata?.companyInfo && (
                                            <Col xs={24} md={12}>
                                                <Typography.Title level={4}>
                                                    {t('DFFOffersDetail.companyInfo')}
                                                </Typography.Title>

                                                <div className="dff-proposal-detail-content-info-block">
                                                    <section>
                                                        <label>{proposal.metadata.companyInfo.name}</label>
                                                        <span>{proposal.metadata.companyInfo.address}</span>
                                                        <span>Tax ID: {proposal.metadata.companyInfo.taxNumber}</span>
                                                        <span>VAT ID: {proposal.metadata.companyInfo.vatNumber}</span>
                                                    </section>

                                                    <section>
                                                        <label>
                                                            {t('DispatcherBoardProposalDetailDff.contactPerson')}
                                                        </label>
                                                        <span>{proposal.metadata.companyInfo.contactPerson}</span>
                                                        <span>{proposal.metadata.companyInfo.contactNumber}</span>
                                                        <span>{proposal.metadata.companyInfo.email}</span>
                                                    </section>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>

                                    <Divider />

                                    <section>
                                        <Row gutter={[8, 8]} align="middle">
                                            <Col span={4} className="dff-datablock-price">
                                                <DFFDataBlock
                                                    label={t(
                                                        `DispatcherBoardProposalDetailDff.${
                                                            isNegotiatedByPrice ? 'offeredPrice' : 'offeredFixPrice'
                                                        }`
                                                    )}
                                                    value={
                                                        proposal.metadata?.finalPrice
                                                            ? `${numeral(proposal.metadata?.finalPrice).format(
                                                                  '0,0'
                                                              )} €`
                                                            : '-'
                                                    }
                                                />
                                            </Col>
                                            {/* Temporarily commented, we will display transport profit information in the future */}
                                            {/* <Col span={4} className="dff-datablock-profit">
                                                <DFFDataBlock
                                                    label={t('DispatcherBoardProposalDetailDff.profit')}
                                                    value={
                                                        proposal.metadata?.profit
                                                            ? `${numeral(proposal.metadata?.profit).format('0,0')} €`
                                                            : '-'
                                                    }
                                                />
                                            </Col> */}
                                            {isNegotiatedByPrice && (
                                                <Col span={20}>
                                                    <DFFProposePrice
                                                        readonly={
                                                            proposal.metadata?.proposal?.state !==
                                                                NegotiationState.ShipperOffer &&
                                                            proposal.metadata?.proposal?.state !==
                                                                NegotiationState.Generated
                                                        }
                                                        proposedPrice={proposedPrice}
                                                        profit={profit}
                                                        onProposedPriceChange={handleProposedPriceChange}
                                                        onSendToShipperClick={onSendToShipperClick}
                                                        onEditClick={setIsProposePriceEdit}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </section>

                                    <Row gutter={[8, 8]}>
                                        <Col>
                                            <Checkbox checked={useJitpay} onChange={handleUseJitpayChange}>
                                                <Trans i18nKey="DispatcherBoardProposalDetailDff.jitPayText">
                                                    Payment within 24 hours with
                                                    <DFFJitPayExplanationTooltip>
                                                        <Typography.Text underline>
                                                            JITpay™ factoring service
                                                        </Typography.Text>
                                                    </DFFJitPayExplanationTooltip>
                                                </Trans>
                                            </Checkbox>
                                        </Col>
                                        <Col>
                                            <img src={jitPayLogo} height={38} alt="JitPay" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Tabs.TabPane>

                        {/* Vehicle requirements tab */}
                        <Tabs.TabPane
                            tab={t('DispatcherBoardProposalDetailDff.vehicleRequirements')}
                            key={ProposalDetailTabs.VEHICLE_REQUIREMENTS}
                        >
                            <div className="dff-proposal-detail-tab">
                                <div className="dff-proposal-detail-tab-content">
                                    <Row>
                                        <Col span={12}>
                                            <Row gutter={[0, 8]}>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.vehicleType')}
                                                        value={proposal.metadata?.proposal?.vehicle.type}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.truckBody')}
                                                        value={proposal.metadata?.proposal?.vehicle.truckBody}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.waysOfLoading')}
                                                        value={proposal.metadata?.proposal?.vehicle.waysOfLoading?.join(
                                                            ', '
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={12}>
                                            <Row gutter={[0, 8]}>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.adrClasses')}
                                                        value={proposal.metadata?.proposal?.vehicle.adrClasses?.join(
                                                            ', '
                                                        )}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.accessories')}
                                                        value={proposal.metadata?.proposal?.vehicle.accessories?.join(
                                                            ', '
                                                        )}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <DffProposalDetailData
                                                        label={t('DispatcherBoardProposalDetailDff.cargoSpace')}
                                                        value={getCargoSpaceLabel()}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Tabs.TabPane>

                        {/* Shipment details tab */}
                        <Tabs.TabPane
                            tab={t('DispatcherBoardProposalDetailDff.shipmentDetails')}
                            key={ProposalDetailTabs.SHIPMENT_DETAILS}
                        >
                            <div className="dff-proposal-detail-tab">
                                <div className="dff-proposal-detail-tab-content">
                                    {/* places[0] contains loadingDetails  */}
                                    <DFFProposalDetailLoads loads={proposal.metadata?.proposal?.places[0].loads!} />
                                </div>
                            </div>
                        </Tabs.TabPane>

                        {/* Loading & Unloading tab */}
                        <Tabs.TabPane
                            tab={t('DispatcherBoardProposalDetailDff.loadingUnloading')}
                            key={ProposalDetailTabs.LOADING_UNLOADING}
                        >
                            <div className="dff-proposal-detail-tab">
                                <div className="dff-proposal-detail-tab-content">
                                    <DFFProposalDetailPlaces places={proposal.metadata?.proposal?.places!} />
                                </div>
                            </div>
                        </Tabs.TabPane>

                        {/* Transport order tab */}
                        {activeStep === ProposalDetailSteps.TRANSPORT_ORDER && (
                            <Tabs.TabPane
                                tab={t('DispatcherBoardProposalDetailDff.transportOrder')}
                                key={ProposalDetailTabs.TRANSPORT_ORDER}
                            >
                                <div className="dff-proposal-detail-transport-order">
                                    {transportOrderPdf && (
                                        <object
                                            data={`${transportOrderPdf}#toolbar=0`}
                                            width="100%"
                                            height="100%"
                                            type="application/pdf"
                                            aria-label="transport-order"
                                        >
                                            <p>{t('DispatcherBoardProposalDetailDff.wrongPdf')}</p>
                                        </object>
                                    )}
                                </div>
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </div>

                {/* Footer */}
                <Row justify="space-between" align="middle" className="dff-proposal-detail-footer">
                    {activeTab !== ProposalDetailTabs.TRANSPORT_ORDER ? (
                        <>
                            <Col>
                                {proposal.metadata?.companyInfo && (
                                    <Row align="middle">
                                        <img src={iconInformation} alt="info" />
                                        <span>
                                            {t('DispatcherBoardProposalDetailDff.expireText')}{' '}
                                            {moment.duration(expiresIn).format('d [days] h [h] m [min]')}
                                        </span>
                                    </Row>
                                )}
                            </Col>
                            {proposal.metadata?.proposal?.state !== NegotiationState.Declined ? (
                                <Col>
                                    <Row gutter={16}>
                                        <Col>
                                            <Button
                                                className="dff-proposal-detail-footer-decline-button"
                                                danger
                                                disabled={
                                                    proposal.metadata?.proposal?.state === NegotiationState.Completed ||
                                                    isProposePriceEdit
                                                }
                                                onClick={onDecline}
                                            >
                                                {t('DispatcherBoardProposalDetailDff.decline')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="primary"
                                                onClick={onAccept}
                                                disabled={
                                                    (proposal.metadata?.proposal?.state !==
                                                        NegotiationState.ShipperOffer &&
                                                        proposal.metadata?.proposal?.state !==
                                                            NegotiationState.Generated) ||
                                                    isProposePriceEdit
                                                }
                                            >
                                                {t('DispatcherBoardProposalDetailDff.accept')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <Col>
                                    <Button
                                        className="dff-proposal-detail-footer-remove-button"
                                        danger
                                        type="primary"
                                        onClick={() => proposal.id && props.onRemove?.(proposal.id)}
                                    >
                                        {t('common.remove')}
                                    </Button>
                                </Col>
                            )}
                        </>
                    ) : (
                        <Row gutter={16}>
                            <Col>
                                {transportOrderPdf && (
                                    <a href={transportOrderPdf} target="_blank" rel="noopener noreferrer" download>
                                        <Button onClick={onDownloadPdf}>
                                            <i className="fa fa-file-pdf" />
                                            {t('DispatcherBoardProposalDetailDff.downloadPdf')}
                                        </Button>
                                    </a>
                                )}
                            </Col>
                        </Row>
                    )}
                </Row>
            </div>
        </Modal>
    );
}
