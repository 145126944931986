import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { PlaceOfWorkUser } from 'common/model/place-of-work-user';
import { Checkbox } from 'common/components/Form';
import { Button } from 'common/components';
import { GPS_FORMAT } from 'domain-constants';
import { Col, Collapse, Row } from 'antd';
import { PoiModel } from 'common/model/poi';
import moment from 'moment';
import * as CommonIcons from 'resources/images/common';

interface Props {
    data: PoiModel;
    placeOfWorkUsers?: PlaceOfWorkUser[];
    onPlaceOfWorkUserDeleteButtonClick?: (userId: string) => void;
    demoMode?: boolean;
}

export default function PoiDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="poi-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={2}>
                        <Descriptions.Item label={t('PoiForm.name')}>
                            <span data-qa={qa.poi.detail.fieldName}>{props.data.name}</span>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.lat')}>
                            <span data-qa={qa.poi.detail.fieldLat}>
                                {props.data.center ? numeral(props.data.center.lat).format(GPS_FORMAT) : ''}
                            </span>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.type')}>
                            <span data-qa={qa.poi.detail.fieldType}>{t(`PoiForm.${String(props.data.type)}`)}</span>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.lng')}>
                            <span data-qa={qa.poi.detail.fieldLng}>
                                {props.data.center ? numeral(props.data.center.lng).format(GPS_FORMAT) : ''}
                            </span>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.notes')}>{props.data.notes}</Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.address')}>
                            <span data-qa={qa.poi.detail.inputAddress}>{props.data.address}</span>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('PoiForm.forbidden')}>
                            <Checkbox
                                disabled={true}
                                checked={props.data.forbidden}
                                data-qa={qa.poi.detail.inputForbidden}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondLeft={
                    props.placeOfWorkUsers && props.placeOfWorkUsers?.length > 0 ? (
                        <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1']}>
                            <Collapse.Panel header={t('PoiForm.placeOfWorkUsers')} key="1">
                                <div className="place-of-work-users">
                                    {props.placeOfWorkUsers.map(user => (
                                        <div className="user-place-card" key={user.id}>
                                            <Row justify="space-between" data-qa={qa.poi.detail.sectionPlaceOfWork}>
                                                <Col
                                                    className="user-place-card-name"
                                                    data-qa={qa.poi.detail.placeOfWork.fieldName}
                                                >
                                                    {`${user.userName?.slice(0, 1)}.  ${user.userSurname}`}
                                                </Col>
                                                <Col className="place-of-work-users-delete">
                                                    <Button
                                                        type="link"
                                                        disabled={props.demoMode}
                                                        size="middle"
                                                        onClick={() =>
                                                            props.onPlaceOfWorkUserDeleteButtonClick?.(String(user.id))
                                                        }
                                                        qa={qa.poi.detail.placeOfWork.btnDelete}
                                                        icon={<img src={CommonIcons.trash} alt="remove-user" />}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col>
                                                    {`${t('common.from')}: `}
                                                    <span className="user-place-card-date">
                                                        {moment(user.start).format('L')}
                                                    </span>
                                                </Col>
                                                <Col>
                                                    {`${t('common.to')}: `}
                                                    <span className="user-place-card-date">
                                                        {moment(user.end).format('L')}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    ) : undefined
                }
            />
        </div>
    );
}
