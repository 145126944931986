/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPServiceContractProvisioningWrap,
    WAGTPServiceContractProvisioningWrapFromJSON,
    WAGTPServiceContractProvisioningWrapFromJSONTyped,
    WAGTPServiceContractProvisioningWrapToJSON,
} from './';

/**
 * 
 * @export
 * @interface WAGTPRequestBodyServiceContractProvisioning
 */
export interface WAGTPRequestBodyServiceContractProvisioning {
    /**
     * 
     * @type {Array<WAGTPServiceContractProvisioningWrap>}
     * @memberof WAGTPRequestBodyServiceContractProvisioning
     */
    listOfServiceContractProvisioning: Array<WAGTPServiceContractProvisioningWrap>;
}

export function WAGTPRequestBodyServiceContractProvisioningFromJSON(json: any): WAGTPRequestBodyServiceContractProvisioning {
    return WAGTPRequestBodyServiceContractProvisioningFromJSONTyped(json, false);
}

export function WAGTPRequestBodyServiceContractProvisioningFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPRequestBodyServiceContractProvisioning {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listOfServiceContractProvisioning': ((json['listOfServiceContractProvisioning'] as Array<any>).map(WAGTPServiceContractProvisioningWrapFromJSON)),
    };
}

export function WAGTPRequestBodyServiceContractProvisioningToJSON(value?: WAGTPRequestBodyServiceContractProvisioning | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'listOfServiceContractProvisioning': ((value.listOfServiceContractProvisioning as Array<any>).map(WAGTPServiceContractProvisioningWrapToJSON)),
    };
}


