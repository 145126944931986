import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import qa from 'qa-selectors';
import { ReadOnlyMonitoredObjectGroupNested, ReadOnlyUserNested } from 'generated/new-main';
import { getRoleName } from 'common/utils/components/RoleName';
import { Button, Tag } from 'common/components';
import { isDriver } from '../UsersModule';
import { Col, Row } from 'antd';
import { CustomPlace } from 'generated/backend-api';
import * as CommonIcons from 'resources/images/common';
import Collapse from 'common/components/Collapse';

interface Props {
    demoMode?: boolean;
    data: ReadOnlyUserNested;
    groups: ReadOnlyMonitoredObjectGroupNested[];
    userCustomPlaces?: CustomPlace[];
    onPlaceOfWorkUsersDeleteButtonClick?: (customPlaceId: string, placeOfWorkId: string) => void;
}

export default function UsersDetailCard(props: Props) {
    const { t } = useTranslation();

    const contactEmail = (props.data?.contact as any)?.emails?.[0];
    const displayEmail = contactEmail ?? '';

    const contactPhone = (props.data?.contact as any)?.phone_numbers?.[0];
    const displayPhone = contactPhone ?? '';

    return (
        <div className="user-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>
                            <span data-qa={qa.users.detail.fieldName}>{props.data.name}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementUsers.userType')}>
                            {props.data.userRoles?.map((ur, i) => (
                                <Tag key={i} qa={qa.users.detail.fieldRoles}>
                                    {getRoleName(ur)}
                                </Tag>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.vehicleGroups')}>
                            {props.data.monitoredObjectGroups?.map((groupId, i) => (
                                <Tag key={i} qa={qa.users.detail.fieldVehicleGroups}>
                                    {props.groups.find(group => group.id === groupId)?.name}
                                </Tag>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.phone')}>
                            <span data-qa={qa.users.detail.fieldPhone}>{displayPhone}</span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.surname')}>
                            <span data-qa={qa.users.detail.fieldSurname}>{props.data.surname}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('ManagementUsers.emailLogin')}>
                            <span data-qa={qa.users.detail.fieldEmail}>{displayEmail}</span>
                        </Descriptions.Item>
                        {isDriver(props.data) ? (
                            <Descriptions.Item label={t('ManagementUsers.pin')}>
                                <span data-qa={qa.users.detail.fieldPin}>
                                    {(props.data?.loginCredentials as any)?.pin}
                                </span>
                            </Descriptions.Item>
                        ) : (
                            <></>
                        )}
                    </Descriptions>
                }
                secondLeft={
                    props.userCustomPlaces && props.userCustomPlaces?.length > 0 ? (
                        <div className="place-of-work-users">
                            <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1']}>
                                <Collapse.Panel header={t('ManagementUsers.placesOfWork')} key="1">
                                    {props.userCustomPlaces?.map(customPlace =>
                                        customPlace?.placeOfWork?.map(placeOfWork => (
                                            <Row
                                                key={placeOfWork.id}
                                                justify="space-between"
                                                data-qa={qa.users.detail.sectionPlaceOfWork}
                                            >
                                                <Col data-qa={qa.users.detail.placeOfWork.fieldName}>
                                                    {customPlace.name}
                                                </Col>
                                                <Col className="place-of-work-users-delete">
                                                    <Button
                                                        type="link"
                                                        disabled={props.demoMode}
                                                        qa={qa.users.detail.placeOfWork.btnDelete}
                                                        icon={<img alt="remove" src={CommonIcons.trash} />}
                                                        onClick={() =>
                                                            props.onPlaceOfWorkUsersDeleteButtonClick?.(
                                                                customPlace.id,
                                                                placeOfWork.id
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        ))
                                    )}
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    ) : undefined
                }
            />
        </div>
    );
}
