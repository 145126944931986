/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCOVehicleDetails
 */
export interface TCOVehicleDetails {
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    purchaseCost: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    annualTollCost: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    annualInsuranceCost: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    annualOtherCost: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    annualServiceAndMaintenanceCost: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    fuelPrice: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    fuelAverageConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    gCo2PerUnitProduction: number;
    /**
     * 
     * @type {number}
     * @memberof TCOVehicleDetails
     */
    residualValue: number;
}

export function TCOVehicleDetailsFromJSON(json: any): TCOVehicleDetails {
    return TCOVehicleDetailsFromJSONTyped(json, false);
}

export function TCOVehicleDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOVehicleDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseCost': json['purchase_cost'],
        'annualTollCost': json['annual_toll_cost'],
        'annualInsuranceCost': json['annual_insurance_cost'],
        'annualOtherCost': json['annual_other_cost'],
        'annualServiceAndMaintenanceCost': json['annual_service_and_maintenance_cost'],
        'fuelPrice': json['fuel_price'],
        'fuelAverageConsumption': json['fuel_average_consumption'],
        'gCo2PerUnitProduction': json['g_co2_per_unit_production'],
        'residualValue': json['residual_value'],
    };
}

export function TCOVehicleDetailsToJSON(value?: TCOVehicleDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchase_cost': value.purchaseCost,
        'annual_toll_cost': value.annualTollCost,
        'annual_insurance_cost': value.annualInsuranceCost,
        'annual_other_cost': value.annualOtherCost,
        'annual_service_and_maintenance_cost': value.annualServiceAndMaintenanceCost,
        'fuel_price': value.fuelPrice,
        'fuel_average_consumption': value.fuelAverageConsumption,
        'g_co2_per_unit_production': value.gCo2PerUnitProduction,
        'residual_value': value.residualValue,
    };
}


