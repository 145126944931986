/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyUserGroup
 */
export interface WriteOnlyUserGroup {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserGroup
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserGroup
     */
    client: number;
}

export function WriteOnlyUserGroupFromJSON(json: any): WriteOnlyUserGroup {
    return WriteOnlyUserGroupFromJSONTyped(json, false);
}

export function WriteOnlyUserGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyUserGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'label': json['label'],
        'client': json['client'],
    };
}

export function WriteOnlyUserGroupToJSON(value?: WriteOnlyUserGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'client': value.client,
    };
}


