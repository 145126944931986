export interface PartnerPairingModel {
    keys: PartnerPairingKey[];
    keySelected?: PartnerPairingKey;
    items?: PairingItem[];
    itemIdSelected?: string;
    search?: string;
    loading?: boolean;
}

export enum PartnerPairingKey {
    partners = 'partners',
    companies = 'companies',
    tlmBundles = 'tlmBundles',
    vehicles = 'vehicles',
    obu = 'obu',
    ccr = 'ccr',
    users = 'users'
}

export interface PairingItem {
    id: string;
    value: string;
    permissions?: string;
}

export interface PairingLink {
    id: string;
    key: PartnerPairingKey;
    rows?: {
        name: string;
        value: string;
        customName?: string;
    }[];
}
