/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlannedTransportPlacePosition
 */
export interface PlannedTransportPlacePosition {
    /**
     * 
     * @type {number}
     * @memberof PlannedTransportPlacePosition
     */
    lng: number;
    /**
     * 
     * @type {number}
     * @memberof PlannedTransportPlacePosition
     */
    lat: number;
}

export function PlannedTransportPlacePositionFromJSON(json: any): PlannedTransportPlacePosition {
    return PlannedTransportPlacePositionFromJSONTyped(json, false);
}

export function PlannedTransportPlacePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlannedTransportPlacePosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lng': json['lng'],
        'lat': json['lat'],
    };
}

export function PlannedTransportPlacePositionToJSON(value?: PlannedTransportPlacePosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lng': value.lng,
        'lat': value.lat,
    };
}


