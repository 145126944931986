import { Col, Row } from 'antd';
import Trend, { TrendChange, TrendDirection } from 'common/components/Trend';
import { useTranslation } from 'react-i18next';
import { fleetVehicles, ewVehicles, ewDriver } from 'resources/images/driver-behavior';

interface Props {
    value: number;
    type: 'fleetVehicles' | 'ewVehicles' | 'ewDriver';
    trend?: {
        value?: number;
        direction: TrendDirection;
        trendChangeForIncrease: TrendChange;
    };
}

export default function DriversLeaderboardCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="drivers-leaderboard-card">
            <img
                src={props.type === 'fleetVehicles' ? fleetVehicles : props.type === 'ewDriver' ? ewDriver : ewVehicles}
                alt={props.type}
            />
            <h3 className="label">{t(`DriverBehavior.${props.type}`)}</h3>
            <div className="value">{props.value}</div>
            <Row justify="center">
                <Col>
                    {props.trend && (
                        <Trend
                            direction={props.trend.direction}
                            trendChangeForIncrease={props.trend.trendChangeForIncrease}
                            value={props.trend.value}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}
