/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlarmType,
    AlarmTypeFromJSON,
    AlarmTypeFromJSONTyped,
    AlarmTypeToJSON,
    EventsModelsAddress,
    EventsModelsAddressFromJSON,
    EventsModelsAddressFromJSONTyped,
    EventsModelsAddressToJSON,
    TimestampedLocation,
    TimestampedLocationFromJSON,
    TimestampedLocationFromJSONTyped,
    TimestampedLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface AlarmInDatabaseWithGPSInfo
 */
export interface AlarmInDatabaseWithGPSInfo {
    /**
     * 
     * @type {AlarmType}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    alarmType: AlarmType;
    /**
     * 
     * @type {Date}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    start: Date;
    /**
     * 
     * @type {string}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    clientId: number;
    /**
     * 
     * @type {Date}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    end?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    acknowledged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    canceled?: boolean;
    /**
     * 
     * @type {object}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    uniqueData?: object;
    /**
     * 
     * @type {TimestampedLocation}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    lastGpsPointStartObj?: TimestampedLocation;
    /**
     * 
     * @type {TimestampedLocation}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    lastGpsPointEndObj?: TimestampedLocation;
    /**
     * 
     * @type {Array<EventsModelsAddress>}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    addressStart?: Array<EventsModelsAddress>;
    /**
     * 
     * @type {Array<EventsModelsAddress>}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    addressEnd?: Array<EventsModelsAddress>;
    /**
     * 
     * @type {string}
     * @memberof AlarmInDatabaseWithGPSInfo
     */
    alarmId: string;
}

export function AlarmInDatabaseWithGPSInfoFromJSON(json: any): AlarmInDatabaseWithGPSInfo {
    return AlarmInDatabaseWithGPSInfoFromJSONTyped(json, false);
}

export function AlarmInDatabaseWithGPSInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmInDatabaseWithGPSInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alarmType': AlarmTypeFromJSON(json['alarmType']),
        'start': (new Date(json['start'])),
        'monitoredObjectId': json['monitoredObjectId'],
        'clientId': json['clientId'],
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'acknowledged': !exists(json, 'acknowledged') ? undefined : json['acknowledged'],
        'canceled': !exists(json, 'canceled') ? undefined : json['canceled'],
        'uniqueData': !exists(json, 'unique_data') ? undefined : json['unique_data'],
        'lastGpsPointStartObj': !exists(json, 'last_gps_point_start_obj') ? undefined : TimestampedLocationFromJSON(json['last_gps_point_start_obj']),
        'lastGpsPointEndObj': !exists(json, 'last_gps_point_end_obj') ? undefined : TimestampedLocationFromJSON(json['last_gps_point_end_obj']),
        'addressStart': !exists(json, 'address_start') ? undefined : ((json['address_start'] as Array<any>).map(EventsModelsAddressFromJSON)),
        'addressEnd': !exists(json, 'address_end') ? undefined : ((json['address_end'] as Array<any>).map(EventsModelsAddressFromJSON)),
        'alarmId': json['alarm_id'],
    };
}

export function AlarmInDatabaseWithGPSInfoToJSON(value?: AlarmInDatabaseWithGPSInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alarmType': AlarmTypeToJSON(value.alarmType),
        'start': (value.start.toISOString()),
        'monitoredObjectId': value.monitoredObjectId,
        'clientId': value.clientId,
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'acknowledged': value.acknowledged,
        'canceled': value.canceled,
        'unique_data': value.uniqueData,
        'last_gps_point_start_obj': TimestampedLocationToJSON(value.lastGpsPointStartObj),
        'last_gps_point_end_obj': TimestampedLocationToJSON(value.lastGpsPointEndObj),
        'address_start': value.addressStart === undefined ? undefined : ((value.addressStart as Array<any>).map(EventsModelsAddressToJSON)),
        'address_end': value.addressEnd === undefined ? undefined : ((value.addressEnd as Array<any>).map(EventsModelsAddressToJSON)),
        'alarm_id': value.alarmId,
    };
}


