/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JourneyStatisticsResponseStats,
    JourneyStatisticsResponseStatsFromJSON,
    JourneyStatisticsResponseStatsFromJSONTyped,
    JourneyStatisticsResponseStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface JourneyStatisticsResponse
 */
export interface JourneyStatisticsResponse {
    /**
     * 
     * @type {Array<JourneyStatisticsResponseStats>}
     * @memberof JourneyStatisticsResponse
     */
    stats: Array<JourneyStatisticsResponseStats>;
}

export function JourneyStatisticsResponseFromJSON(json: any): JourneyStatisticsResponse {
    return JourneyStatisticsResponseFromJSONTyped(json, false);
}

export function JourneyStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JourneyStatisticsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stats': ((json['stats'] as Array<any>).map(JourneyStatisticsResponseStatsFromJSON)),
    };
}

export function JourneyStatisticsResponseToJSON(value?: JourneyStatisticsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stats': ((value.stats as Array<any>).map(JourneyStatisticsResponseStatsToJSON)),
    };
}


