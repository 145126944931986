import * as Yup from 'yup';
import FormActions from 'common/components/FormActions';
import { Form, Formik, FormikProps } from 'formik';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InputField, SelectField, CheckBoxField, TextareaField } from 'common/fields';
import { Col, Row } from 'antd';
import { PoiType } from 'generated/backend-api';
import { PoiModel, poiTypes } from 'common/model/poi';
import { AutoComplete } from 'common/components';
import { LatLng } from 'common/model/geo';
import { useEffect } from 'react';

export interface PolygonExternalValues {
    address?: string;
    center?: LatLng;
    polygon?: LatLng[];
    countryCode?: string;
}

interface Props extends WithTranslation {
    initialValues?: Partial<PoiModel>;
    polygonExternalValues?: PolygonExternalValues;
    demoMode: boolean;
    searchResults: {
        label: string;
        code: string;
    }[];
    onSearchPlaceTextChange: (value: string) => void;
    onSearchPlaceSelect: (value: string) => void;
    onSubmit: (values: PoiModel) => Promise<boolean>;
    onCancel?: () => void;
}

function CustomPlacesForm(props: Props) {
    let setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void = () => {};
    useEffect(() => {
        setFieldValue('address', props.polygonExternalValues?.address ?? '');
        setFieldValue('center.lat', props.polygonExternalValues?.center?.lat ?? '');
        setFieldValue('center.lng', props.polygonExternalValues?.center?.lng ?? '');
        setFieldValue('polygon', props.polygonExternalValues?.polygon ?? []);
    }, [props.polygonExternalValues]);

    function handleOnSubmit(values: PoiModel) {
        props.onSubmit?.(values);
    }

    function onSearchSelect(value: string | null) {
        props.onSearchPlaceSelect?.(value ?? '');
    }

    return (
        <Formik<PoiModel>
            initialValues={{
                name: props.initialValues?.name ?? '',
                address: props.initialValues?.address ?? '',
                center: props.initialValues?.center ?? undefined,
                countryCode: props.initialValues?.countryCode ?? '',
                forbidden: props.initialValues?.forbidden ?? false,
                notes: props.initialValues?.notes ?? '',
                polygon: props.initialValues?.polygon ?? [],
                type: props.initialValues?.type ?? undefined
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .transform(v => v.trim())
                    .required(props.t('common.required')),
                type: Yup.mixed().oneOf(Object.values(PoiType)).required(props.t('common.required'))
            })}
            onSubmit={handleOnSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {(formik: FormikProps<PoiModel>) => {
                setFieldValue = formik.setFieldValue;
                return (
                    <Form className="custom-place-form">
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <InputField qa={qa.poi.detail.inputName} name="name" label={props.t('PoiForm.name')} />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <SelectField
                                    qa={qa.poi.detail.fieldType}
                                    name="type"
                                    options={poiTypes()}
                                    label={props.t('PoiForm.type')}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <CheckBoxField data-qa={qa.poi.detail.inputForbidden} name="forbidden" label={''}>
                                    {props.t('PoiForm.forbidden')}
                                </CheckBoxField>
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <AutoComplete
                                    text=""
                                    options={props.searchResults}
                                    placeholder={props.t('PoiForm.typePlace')}
                                    onOptionSelect={onSearchSelect}
                                    onTextChange={props.onSearchPlaceTextChange}
                                    qa={qa.poi.detail.inputPlaceSearch}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <InputField
                                    readOnly
                                    name="address"
                                    label={props.t('PoiForm.address')}
                                    qa={qa.poi.detail.inputAddress}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={12}>
                                <InputField
                                    readOnly
                                    name="center.lat"
                                    label={props.t('PoiForm.lat')}
                                    qa={qa.poi.detail.inputLat}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField
                                    readOnly
                                    name="center.lng"
                                    label={props.t('PoiForm.lng')}
                                    qa={qa.poi.detail.inputLng}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[16, 24]}>
                            <Col span={24}>
                                <TextareaField
                                    name="notes"
                                    label={props.t('PoiForm.notes')}
                                    qa={qa.poi.detail.inputLng}
                                />
                            </Col>
                        </Row>

                        <FormActions
                            cancelQa={qa.poi.detail.btnCancel}
                            submitQa={qa.poi.detail.btnSubmit}
                            submitLoading={formik.isSubmitting}
                            submitDisabled={props.demoMode || !formik.values.address}
                            onCancelClick={props.onCancel}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(CustomPlacesForm);
