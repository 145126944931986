import { Logic } from 'logic/logic';
import i18n from 'i18next';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DriverBehaviorModelDetail } from 'common/model/statistics';
import * as DriverBehaviorIcons from 'resources/images/driver-behavior';

import { RouteNames } from 'App';

import DriverBehaviorCollapse from '../../../components/DriverBehaviorCollapse';
import DriverBehaviorStat from '../../../components/DriverBehaviorStat';
import DriverTrendsScoreStats from '../../../components/DriverTrendsScoreStats';
import DriverDetailNav from '../../../components/DriverDetailNav';
import DriverStatValue, { DriverStatValueFormat } from '../../../components/DriverStatValue';
import qa from 'qa-selectors';
import Trend, { TrendChange } from 'common/components/Trend';
import ChartTrends from 'common/components/ChartTrends';
import moment from 'moment';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { NoData } from 'common/components/NoData';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { HelperModal } from 'common/components';

function DriverBehaviorIcon({ icon }: { icon: string }) {
    return <img src={icon} alt={icon} />;
}

interface DriverBehaviorDataMap {
    [key: string]: {
        key: keyof DriverBehaviorModelDetail;
        unit: DriverStatValueFormat;
        icon?: string;
        trendForIncrease?: TrendChange;
        diminisher?: number;
    }[];
}

interface Props extends WithTranslation, RouteComponentProps<{ id: string }> {
    logic: Logic;
}

interface State {
    dataMap: DriverBehaviorDataMap;
    drivers?: {
        value: string;
        label: string;
    }[];
    date?: string;
    export: {
        processing: boolean;
        enable: boolean;
    };
    bar?: {
        name: string;
        rank?: number;
        tachocard?: string;
    };
    chart?: {
        compareDriverId?: string;
    };
    helper?: {
        content: string;
    };
}

/**
 * Driver detail module takes care of displaying detail of current selected driver in url
 */
class TruckDetailModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            export: {
                enable: true,
                processing: false
            },
            dataMap: {
                driveData: [
                    {
                        key: 'fullDistance',
                        unit: 'distance',
                        icon: DriverBehaviorIcons.distance,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'driverTime',
                        unit: 'duration',
                        diminisher: 3600,
                        icon: DriverBehaviorIcons.timing,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'consumption',
                        unit: 'liters',
                        icon: DriverBehaviorIcons.fuelPistol,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'avgSpeed',
                        unit: 'kmPerHour',
                        icon: DriverBehaviorIcons.speed,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'engineOnTime',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'idlingTime',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'cruiseControlTime',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'cntTakeoff',
                        unit: 'count',
                        trendForIncrease: TrendChange.NEUTRAL
                    }
                ],
                ecoData: [
                    {
                        key: 'idleConsumption',
                        unit: 'liters',
                        icon: DriverBehaviorIcons.fuelPistolAlt,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'rpmOverTime',
                        unit: 'duration',
                        diminisher: 3600,
                        icon: DriverBehaviorIcons.rpm,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'constantAccelerationTime',
                        unit: 'duration',
                        diminisher: 3600,
                        icon: DriverBehaviorIcons.acceleration,
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'driveTime85',
                        unit: 'duration',
                        diminisher: 3600,
                        icon: DriverBehaviorIcons.truckingTime,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'driveTimeWithoutConsumptionWithEcoroll',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'accelerationTimeWithCruiseControl',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'kickdownTime',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'maxRPN',
                        unit: 'count',
                        trendForIncrease: TrendChange.NEGATIVE
                    }
                ],
                wearTearData: [
                    {
                        key: 'avgParkingBreakCount',
                        unit: 'count',
                        icon: DriverBehaviorIcons.handbrake,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'avgRetarderCount',
                        unit: 'count',
                        icon: DriverBehaviorIcons.retarder,
                        trendForIncrease: TrendChange.NEUTRAL
                    },
                    {
                        key: 'avgServiceBrakeCount',
                        unit: 'count',
                        icon: DriverBehaviorIcons.brakejaws,
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'serviceBrakeVsRetarderPercentage',
                        unit: 'percentage',
                        icon: DriverBehaviorIcons.brakesign,
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'retarderTime',
                        unit: 'duration',
                        diminisher: 3600,
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'distanceWithRetarder',
                        unit: 'distance',
                        trendForIncrease: TrendChange.POSITIVE
                    },
                    {
                        key: 'distanceWithServiceBrake',
                        unit: 'distance',
                        trendForIncrease: TrendChange.NEGATIVE
                    },
                    {
                        key: 'maxSpeedWithParkingBrake',
                        unit: 'kmPerHour',
                        trendForIncrease: TrendChange.NEGATIVE
                    }
                ]
            }
        };
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            this.props.logic.driverBehavior().trucks().setSelectedDriverId(this.props.match.params.id);
        }

        const detail = this.props.logic.driverBehavior().trucks().getDataTrendsByDriverId(this.props.match.params.id);
        const drivers = this.props.logic.driverBehavior().trucks().getDataTrendsDrivers();
        const date = this.props.logic.driverBehavior().trucks().date;

        if (detail) {
            this.setState({
                bar: {
                    name: detail.name,
                    rank: detail.rank,
                    tachocard: detail.tachocard
                },
                drivers,
                date
            });
        } else {
            this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const detail = this.props.logic
                .driverBehavior()
                .trucks()
                .getDataTrendsByDriverId(this.props.match.params.id);
            this.props.logic.driverBehavior().trucks().setSelectedDriverId(this.props.match.params.id);

            const date = this.props.logic.driverBehavior().trucks().date;

            if (detail) {
                this.setState({
                    date
                });
            } else {
                this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
            }
        }

        this.props.logic
            .driverBehavior()
            .trucks()
            .exportDetailProcessing.subscribe(processing => {
                this.setState(state => ({
                    export: {
                        ...state.export,
                        processing
                    }
                }));
            });
    }

    componentWillUnmount(): void {
        this.props.logic.driverBehavior().trucks().setComparedDriverId(undefined);
    }

    render() {
        const { t } = this.props;
        const { dataMap, date } = this.state;

        const selectedData = this.props.logic.driverBehavior().trucks().selectedDriverId
            ? this.props.logic
                  .driverBehavior()
                  .trucks()
                  .getDataTrendsByDriverId(this.props.logic.driverBehavior().trucks().selectedDriverId!)
            : undefined;

        return (
            <div className="driver-behavior">
                <DriverDetailNav
                    name={selectedData?.name}
                    tachocard={this.state.bar?.tachocard}
                    date={date}
                    rank={
                        selectedData?.rank && selectedData?.totalDrivers
                            ? { value: selectedData?.rank, total: selectedData?.totalDrivers }
                            : undefined
                    }
                    export={this.state.export}
                    onExportClick={() => {
                        this.props.logic.driverBehavior().trucks().downloadDriverBehaviorDetailExport();
                    }}
                    onDateRangeChange={this._onDateRangeChange}
                    onBarDateNextClick={this._onBarDateNextClick}
                    onBarDatePreviousClick={this._onBarDatePreviousClick}
                    onBackButtonClick={this._onNavBackClick}
                    onHelperClick={this._onBarHelperClick}
                    demoMode={this.props.logic.demo().isActive}
                />

                {selectedData ? (
                    <>
                        <DriverTrendsScoreStats
                            data={selectedData}
                            theme={this.props.logic.settings().getProp('theme')}
                            comparedData={
                                this.props.logic.driverBehavior().trucks().comparedDriverId !== undefined
                                    ? this.props.logic
                                          .driverBehavior()
                                          .trucks()
                                          .getDataTrendsByDriverId(
                                              this.props.logic.driverBehavior().trucks().comparedDriverId!
                                          )
                                    : undefined
                            }
                            drivers={this.state.drivers ?? []}
                            onCompareDriverSelect={this._onCompareDriverSelect}
                            onCompareDriverDeselect={this._onCompareDriverDeselect}
                        />
                        <div className="driver-behavior-data">
                            <DriverBehaviorCollapse
                                title={t('DriverBehaviorDetail.driveData.title')}
                                stats={dataMap.driveData.map(e => (
                                    <DriverBehaviorStat
                                        key={e.key}
                                        color="blue"
                                        value={
                                            <DriverStatValue
                                                value={selectedData?.scores[0].detail?.[e.key]}
                                                unit={e.unit}
                                            />
                                        }
                                        description={t(`DriverBehaviorDetail.options.general.${e.key}`)}
                                        development={
                                            <Trend
                                                direction={getIncreaseDirection(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                                trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                                value={getIncreasePercent(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                            />
                                        }
                                        icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                        qa={qa.driverBehaviour.trucks.detail.drive[e.key]}
                                        popover={
                                            selectedData && (
                                                <ChartTrends
                                                    theme={this.props.logic.settings().getProp('theme')}
                                                    data={selectedData?.scores
                                                        .map((score, i) => {
                                                            return {
                                                                xIndex: i,
                                                                yVal:
                                                                    score.detail?.[e.key] !== undefined
                                                                        ? score.detail?.[e.key] / (e.diminisher ?? 1)
                                                                        : undefined,
                                                                xVal:
                                                                    i < 12
                                                                        ? moment(score.start, 'YYYY-MM-DD').format(
                                                                              'MMM'
                                                                          )
                                                                        : i.toString()
                                                            };
                                                        })
                                                        .reverse()}
                                                    width={580}
                                                    height={250}
                                                    color={'#25a6e4'}
                                                    lineWidth={2}
                                                />
                                            )
                                        }
                                    />
                                ))}
                                qa={qa.driverBehaviour.trucks.detail.drive.collapse}
                            />

                            <DriverBehaviorCollapse
                                title={t('DriverBehaviorDetail.ecoData.title')}
                                stats={dataMap.ecoData.map(e => (
                                    <DriverBehaviorStat
                                        key={e.key}
                                        color="green"
                                        value={
                                            <DriverStatValue
                                                value={selectedData?.scores[0].detail?.[e.key]}
                                                unit={e.unit}
                                            />
                                        }
                                        description={t(`DriverBehaviorDetail.options.general.${e.key}`)}
                                        development={
                                            <Trend
                                                direction={getIncreaseDirection(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                                trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                                value={getIncreasePercent(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                            />
                                        }
                                        icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                        qa={qa.driverBehaviour.trucks.detail.eco[e.key]}
                                        popover={
                                            selectedData && (
                                                <ChartTrends
                                                    theme={this.props.logic.settings().getProp('theme')}
                                                    data={selectedData?.scores
                                                        .map((score, i) => {
                                                            return {
                                                                xIndex: i,
                                                                yVal:
                                                                    score.detail?.[e.key] !== undefined
                                                                        ? score.detail?.[e.key] / (e.diminisher ?? 1)
                                                                        : undefined,
                                                                xVal:
                                                                    i < 12
                                                                        ? moment(score.start, 'YYYY-MM-DD').format(
                                                                              'MMM'
                                                                          )
                                                                        : i.toString()
                                                            };
                                                        })
                                                        .reverse()}
                                                    width={580}
                                                    height={250}
                                                    color={'#1BDA7E'}
                                                    lineWidth={2}
                                                />
                                            )
                                        }
                                    />
                                ))}
                                qa={qa.driverBehaviour.trucks.detail.wearTear.collapse}
                            />
                            <DriverBehaviorCollapse
                                title={t('DriverBehaviorDetail.wearTearData.title')}
                                stats={dataMap.wearTearData.map(e => (
                                    <DriverBehaviorStat
                                        key={e.key}
                                        color="yellow"
                                        value={
                                            <DriverStatValue
                                                value={selectedData?.scores[0].detail?.[e.key]}
                                                unit={e.unit}
                                            />
                                        }
                                        description={t(`DriverBehaviorDetail.options.general.${e.key}`)}
                                        development={
                                            <Trend
                                                direction={getIncreaseDirection(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                                trendChangeForIncrease={e.trendForIncrease ?? TrendChange.NEUTRAL}
                                                value={getIncreasePercent(
                                                    selectedData?.scores[0].detail?.[e.key],
                                                    selectedData?.scores[1].detail?.[e.key]
                                                )}
                                            />
                                        }
                                        icon={e.icon && <DriverBehaviorIcon icon={e.icon} />}
                                        qa={qa.driverBehaviour.trucks.detail.wearTear[e.key]}
                                        popover={
                                            selectedData && (
                                                <ChartTrends
                                                    theme={this.props.logic.settings().getProp('theme')}
                                                    data={selectedData?.scores
                                                        .map((score, i) => {
                                                            return {
                                                                xIndex: i,
                                                                yVal:
                                                                    score.detail?.[e.key] !== undefined
                                                                        ? score.detail?.[e.key] / (e.diminisher ?? 1)
                                                                        : undefined,
                                                                xVal:
                                                                    i < 12
                                                                        ? moment(score.start, 'YYYY-MM-DD').format(
                                                                              'MMM'
                                                                          )
                                                                        : i.toString()
                                                            };
                                                        })
                                                        .reverse()}
                                                    width={580}
                                                    height={250}
                                                    color={'#FFD22E'}
                                                    lineWidth={2}
                                                />
                                            )
                                        }
                                    />
                                ))}
                                qa={qa.driverBehaviour.trucks.detail.eco.collapse}
                            />
                        </div>
                    </>
                ) : (
                    <div className="card-no-data">
                        <NoData />
                    </div>
                )}

                <HelperModal
                    name="driver-behavior"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </div>
        );
    }

    private _onNavBackClick = () => {
        this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
    };

    private _onCompareDriverSelect = (id: string) => {
        this.setState({
            chart: {
                compareDriverId: id
            }
        });
        this.props.logic.driverBehavior().trucks().setComparedDriverId(id);
    };

    private _onCompareDriverDeselect = () => {
        this.props.logic.driverBehavior().trucks().setComparedDriverId(undefined);
        this.setState({
            chart: undefined
        });
    };

    private _onDateRangeChange = (date: string) => {
        this.setState(
            {
                date
            },
            () => {
                this.props.logic.driverBehavior().trucks().loadTrendsData(date);
            }
        );
    };

    private _onBarDatePreviousClick = () => {
        const date = moment(this.state.date).subtract(1, 'month').toISOString();
        this.setState(
            {
                date
            },
            () => {
                this.props.logic.driverBehavior().trucks().loadTrendsData(date);
            }
        );
    };

    private _onBarDateNextClick = () => {
        const date = moment(this.state.date).add(1, 'month').toISOString();
        this.setState(
            {
                date
            },
            () => {
                this.props.logic.driverBehavior().trucks().loadTrendsData(date);
            }
        );
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'driverbehaviour';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(TruckDetailModule));
