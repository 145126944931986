/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AttachmentType {
    TRANSPORTORDER = 'TRANSPORT_ORDER'
}

export function AttachmentTypeFromJSON(json: any): AttachmentType {
    return AttachmentTypeFromJSONTyped(json, false);
}

export function AttachmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentType {
    return json as AttachmentType;
}

export function AttachmentTypeToJSON(value?: AttachmentType | null): any {
    return value as any;
}

