/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Format is \'iso:avoid\' like \'svk:country|cze:tolls\' for details see our routing-api service
 * @export
 * @interface TransportTemplateRouteOptionsSygic
 */
export interface TransportTemplateRouteOptionsSygic {
    /**
     * all avoids set by dispatcher format is \'iso:avoid\' like \'svk:country|cze:tolls
     * @type {Array<string>}
     * @memberof TransportTemplateRouteOptionsSygic
     */
    avoidOptionsSygic: Array<string>;
    /**
     * all possible avoids from sygic routing api
     * @type {{ [key: string]: Array<string>; }}
     * @memberof TransportTemplateRouteOptionsSygic
     */
    possibleAvoidsSygic?: { [key: string]: Array<string>; };
}

export function TransportTemplateRouteOptionsSygicFromJSON(json: any): TransportTemplateRouteOptionsSygic {
    return TransportTemplateRouteOptionsSygicFromJSONTyped(json, false);
}

export function TransportTemplateRouteOptionsSygicFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportTemplateRouteOptionsSygic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avoidOptionsSygic': json['avoid_options_sygic'],
        'possibleAvoidsSygic': !exists(json, 'possible_avoids_sygic') ? undefined : json['possible_avoids_sygic'],
    };
}

export function TransportTemplateRouteOptionsSygicToJSON(value?: TransportTemplateRouteOptionsSygic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avoid_options_sygic': value.avoidOptionsSygic,
        'possible_avoids_sygic': value.possibleAvoidsSygic,
    };
}


