/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RawDataPointStateGpsModel
 */
export interface RawDataPointStateGpsModel {
    /**
     * 
     * @type {string}
     * @memberof RawDataPointStateGpsModel
     */
    type: RawDataPointStateGpsModelTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof RawDataPointStateGpsModel
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof RawDataPointStateGpsModel
     */
    subtype: RawDataPointStateGpsModelSubtypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RawDataPointStateGpsModel
     */
    on: boolean;
}

export function RawDataPointStateGpsModelFromJSON(json: any): RawDataPointStateGpsModel {
    return RawDataPointStateGpsModelFromJSONTyped(json, false);
}

export function RawDataPointStateGpsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawDataPointStateGpsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'time': (new Date(json['time'])),
        'subtype': json['subtype'],
        'on': json['on'],
    };
}

export function RawDataPointStateGpsModelToJSON(value?: RawDataPointStateGpsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'time': (value.time.toISOString()),
        'subtype': value.subtype,
        'on': value.on,
    };
}

/**
* @export
* @enum {string}
*/
export enum RawDataPointStateGpsModelTypeEnum {
    State = 'state'
}
/**
* @export
* @enum {string}
*/
export enum RawDataPointStateGpsModelSubtypeEnum {
    Gps = 'gps'
}


