/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Code,
    CodeFromJSON,
    CodeToJSON,
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
    ReadOnlyAccessCode,
    ReadOnlyAccessCodeFromJSON,
    ReadOnlyAccessCodeToJSON,
    RefreshToken,
    RefreshTokenFromJSON,
    RefreshTokenToJSON,
    WriteOnlyAccessCode,
    WriteOnlyAccessCodeFromJSON,
    WriteOnlyAccessCodeToJSON,
} from '../models';

export interface CustomerAccessAccessCodeCreateRequest {
    data: WriteOnlyAccessCode;
}

export interface CustomerAccessAccessCodeDeleteRequest {
    id: string;
}

export interface CustomerAccessAccessCodeListRequest {
    limit?: number;
    offset?: number;
}

export interface CustomerAccessAccessCodePartialUpdateRequest {
    id: string;
    data: WriteOnlyAccessCode;
}

export interface CustomerAccessAccessCodeReadRequest {
    id: string;
}

export interface CustomerAccessAccessCodeUpdateRequest {
    id: string;
    data: WriteOnlyAccessCode;
}

export interface CustomerAccessTokenAuthCreateRequest {
    data: Code;
}

export interface CustomerAccessTokenRefreshCreateRequest {
    data: RefreshToken;
}

/**
 * no description
 */
export class CustomerAccessApi extends runtime.BaseAPI {

    /**
     */
    async customerAccessAccessCodeCreateRaw(requestParameters: CustomerAccessAccessCodeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyAccessCode>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling customerAccessAccessCodeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAccessCodeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAccessCodeFromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodeCreate(requestParameters: CustomerAccessAccessCodeCreateRequest): Promise<ReadOnlyAccessCode> {
        const response = await this.customerAccessAccessCodeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async customerAccessAccessCodeDeleteRaw(requestParameters: CustomerAccessAccessCodeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyAccessCode>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerAccessAccessCodeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAccessCodeFromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodeDelete(requestParameters: CustomerAccessAccessCodeDeleteRequest): Promise<ReadOnlyAccessCode> {
        const response = await this.customerAccessAccessCodeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async customerAccessAccessCodeListRaw(requestParameters: CustomerAccessAccessCodeListRequest): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodeList(requestParameters: CustomerAccessAccessCodeListRequest): Promise<InlineResponse2007> {
        const response = await this.customerAccessAccessCodeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async customerAccessAccessCodePartialUpdateRaw(requestParameters: CustomerAccessAccessCodePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyAccessCode>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerAccessAccessCodePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling customerAccessAccessCodePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAccessCodeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAccessCodeFromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodePartialUpdate(requestParameters: CustomerAccessAccessCodePartialUpdateRequest): Promise<ReadOnlyAccessCode> {
        const response = await this.customerAccessAccessCodePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async customerAccessAccessCodeReadRaw(requestParameters: CustomerAccessAccessCodeReadRequest): Promise<runtime.ApiResponse<ReadOnlyAccessCode>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerAccessAccessCodeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAccessCodeFromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodeRead(requestParameters: CustomerAccessAccessCodeReadRequest): Promise<ReadOnlyAccessCode> {
        const response = await this.customerAccessAccessCodeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async customerAccessAccessCodeUpdateRaw(requestParameters: CustomerAccessAccessCodeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyAccessCode>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerAccessAccessCodeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling customerAccessAccessCodeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/access-code/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAccessCodeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAccessCodeFromJSON(jsonValue));
    }

    /**
     */
    async customerAccessAccessCodeUpdate(requestParameters: CustomerAccessAccessCodeUpdateRequest): Promise<ReadOnlyAccessCode> {
        const response = await this.customerAccessAccessCodeUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a JSON Web Token that can be used for authenticated requests.
     * API View that receives a POST with a user\'s username and password.
     */
    async customerAccessTokenAuthCreateRaw(requestParameters: CustomerAccessTokenAuthCreateRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling customerAccessTokenAuthCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/token-auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a JSON Web Token that can be used for authenticated requests.
     * API View that receives a POST with a user\'s username and password.
     */
    async customerAccessTokenAuthCreate(requestParameters: CustomerAccessTokenAuthCreateRequest): Promise<object> {
        const response = await this.customerAccessTokenAuthCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * If \'orig_iat\' field (original issued-at-time) is found, will first check if it\'s within expiration window, then copy it to the new token
     * API View that returns a refreshed token (with new expiration) based on existing token
     */
    async customerAccessTokenRefreshCreateRaw(requestParameters: CustomerAccessTokenRefreshCreateRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling customerAccessTokenRefreshCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/customer-access/token-refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * If \'orig_iat\' field (original issued-at-time) is found, will first check if it\'s within expiration window, then copy it to the new token
     * API View that returns a refreshed token (with new expiration) based on existing token
     */
    async customerAccessTokenRefreshCreate(requestParameters: CustomerAccessTokenRefreshCreateRequest): Promise<object> {
        const response = await this.customerAccessTokenRefreshCreateRaw(requestParameters);
        return await response.value();
    }

}
