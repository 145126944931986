/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyCurrencyConversionSerializer
 */
export interface WriteOnlyCurrencyConversionSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyCurrencyConversionSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyCurrencyConversionSerializer
     */
    currencyOrigin: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyCurrencyConversionSerializer
     */
    currencyForeign: string;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyCurrencyConversionSerializer
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyCurrencyConversionSerializer
     */
    rate: number;
}

export function WriteOnlyCurrencyConversionSerializerFromJSON(json: any): WriteOnlyCurrencyConversionSerializer {
    return WriteOnlyCurrencyConversionSerializerFromJSONTyped(json, false);
}

export function WriteOnlyCurrencyConversionSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyCurrencyConversionSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currencyOrigin': json['currency_origin'],
        'currencyForeign': json['currency_foreign'],
        'date': (new Date(json['date'])),
        'rate': json['rate'],
    };
}

export function WriteOnlyCurrencyConversionSerializerToJSON(value?: WriteOnlyCurrencyConversionSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'currency_origin': value.currencyOrigin,
        'currency_foreign': value.currencyForeign,
        'date': (value.date.toISOString()),
        'rate': value.rate,
    };
}


