import i18n from 'i18next';
import moment from 'moment';
import { findFirstBiggerItem } from './math';

export function timeNamesList(useShortNames: boolean = false) {
    let daysName = i18n.t('common.days')[0].toLowerCase();
    let hoursName = i18n.t('common.hours')[0].toLowerCase();
    let minutesName = i18n.t('common.minutes')[0].toLowerCase();
    let secondsName = i18n.t('common.seconds')[0].toLowerCase();

    if (useShortNames) {
        daysName = daysName[0];
        hoursName = hoursName[0];
        minutesName = minutesName[0];
        secondsName = secondsName[0];
    }

    return {
        days: daysName,
        hours: hoursName,
        minutes: minutesName,
        seconds: secondsName
    };
}

/**
 *
 * @param duration ms
 */
export function formatDuration(duration: number, useShortNames: boolean = false, useDays: boolean = true) {
    const d = Math.floor(duration / (24 * 60 * 60 * 1000));
    const h = Math.floor(duration / (60 * 60 * 1000));
    const m = Math.floor(duration / (60 * 1000));

    const days = parseInt(d.toString());
    const hours = parseInt(h.toString()) - 24 * days;
    const minutes = parseInt(m.toString()) - parseInt(h.toString()) * 60;

    const timeNames = timeNamesList(useShortNames);

    if (useDays && days > 0) {
        return `${days}${timeNames.days} ${hours}${timeNames.hours} ${minutes}${timeNames.minutes}`;
    }

    return `${h}${timeNames.hours} ${minutes}${timeNames.minutes}`;
}

/**
 *
 * @param duration ms
 */
export function formatDurationWithSeconds(duration: number, useShortNames: boolean = false) {
    const d = Math.floor(duration / (24 * 60 * 60 * 1000));
    const h = Math.floor(duration / (60 * 60 * 1000));
    const m = Math.floor(duration / (60 * 1000));
    const s = Math.floor(duration / 1000);

    const days = parseInt(d.toString());
    const hours = parseInt(h.toString()) - 24 * days;
    const minutes = parseInt(m.toString()) - parseInt(h.toString()) * 60;
    const seconds = parseInt(s.toString()) - parseInt(m.toString()) * 60;

    const timeNames = timeNamesList(useShortNames);

    if (days > 0) {
        return `${days}${timeNames.days} ${hours}${timeNames.hours} ${minutes}${timeNames.minutes} ${seconds}${timeNames.seconds}`;
    }

    return `${hours}${timeNames.hours} ${minutes}${timeNames.minutes} ${seconds}${timeNames.seconds}`;
}

export function formatSecondsToString(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
        seconds < 10 ? '0' + seconds : seconds
    }`;
}

interface TimeInterval {
    tickDuration: number;
    sampleDuration: number;
}

function getTimelineIntervals(): TimeInterval[] {
    return [
        { tickDuration: 864000000, sampleDuration: 14400000 }, // 10 days
        { tickDuration: 432000000, sampleDuration: 7200000 }, // 5 days
        { tickDuration: 345600000, sampleDuration: 5760000 }, // 4 days
        { tickDuration: 172800000, sampleDuration: 2880000 }, // 2 days
        { tickDuration: 86400000, sampleDuration: 1440000 }, // 1 day
        { tickDuration: 43200000, sampleDuration: 720000 }, // 12 hours
        { tickDuration: 28800000, sampleDuration: 480000 }, // 8 hours
        { tickDuration: 21600000, sampleDuration: 360000 }, // 6 hours
        { tickDuration: 14400000, sampleDuration: 240000 }, // 4 hours
        { tickDuration: 10800000, sampleDuration: 180000 }, // 3 hours
        { tickDuration: 7200000, sampleDuration: 120000 }, // 2 hours
        { tickDuration: 3600000, sampleDuration: 60000 }, // 1 hour
        { tickDuration: 1800000, sampleDuration: 30000 }, // 30 minutes
        { tickDuration: 1200000, sampleDuration: 20000 }, // 20 minutes
        { tickDuration: 600000, sampleDuration: 10000 }, // 10 minutes
        { tickDuration: 300000, sampleDuration: 5000 }, // 5 minutes
        { tickDuration: 120000, sampleDuration: 2000 }, // 2 minutes
        { tickDuration: 60000, sampleDuration: 1000 }, // 1 minute
        { tickDuration: 30000, sampleDuration: 1000 }, // 30 seconds
        { tickDuration: 15000, sampleDuration: 1000 }, // 15 seconds
        { tickDuration: 10000, sampleDuration: 1000 }, // 10 seconds
        { tickDuration: 5000, sampleDuration: 1000 } // 5 seconds
    ];
}

export function getXAxisTicks(
    startDate: Date,
    endDate: Date
): { ticks: number[]; samples: number[]; tickDuration: number; sampleDuration: number } {
    const intervals: number[] = getTimelineIntervals().map(d => d.tickDuration);
    const diffInMs = endDate.getTime() - startDate.getTime();

    const intervalDurationGraph = findFirstBiggerItem(Math.ceil(diffInMs / 13), intervals);
    const sampleDurationGraph =
        getTimelineIntervals().find(d => d.tickDuration === intervalDurationGraph)?.sampleDuration ?? 5000;

    const firstTickProposed = Math.floor(startDate.getTime() / intervalDurationGraph) * intervalDurationGraph;
    const firstTick =
        moment(startDate).toDate().getDate() !== moment(firstTickProposed).toDate().getDate()
            ? Number(moment(startDate).startOf('day').format('X')) * 1000
            : firstTickProposed;

    const ticksCount = diffInMs / intervalDurationGraph;
    const samplesCount = (endDate.getTime() - moment(firstTick).toDate().getTime()) / sampleDurationGraph;

    return {
        ticks: new Array(Math.ceil(ticksCount) + 1)
            .fill(0)
            .map((_, i) => firstTick + i * intervalDurationGraph)
            .filter(tick => tick <= endDate.getTime()),
        samples: new Array(Math.ceil(samplesCount) + 1)
            .fill(0)
            .map((_, i) => firstTick + i * sampleDurationGraph)
            .filter(tick => tick <= endDate.getTime()),
        tickDuration: intervalDurationGraph,
        sampleDuration: sampleDurationGraph
    };
}
