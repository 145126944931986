/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOVehicleDetails,
    TCOVehicleDetailsFromJSON,
    TCOVehicleDetailsFromJSONTyped,
    TCOVehicleDetailsToJSON,
    TCOVehicleDetailsDiesel,
    TCOVehicleDetailsDieselFromJSON,
    TCOVehicleDetailsDieselFromJSONTyped,
    TCOVehicleDetailsDieselToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOInput
 */
export interface TCOInput {
    /**
     * 
     * @type {number}
     * @memberof TCOInput
     */
    operationalLifetime: number;
    /**
     * 
     * @type {number}
     * @memberof TCOInput
     */
    annualMileage: number;
    /**
     * 
     * @type {TCOVehicleDetails}
     * @memberof TCOInput
     */
    lngVehicleDetails: TCOVehicleDetails;
    /**
     * 
     * @type {TCOVehicleDetailsDiesel}
     * @memberof TCOInput
     */
    dieselVehicleDetails: TCOVehicleDetailsDiesel;
}

export function TCOInputFromJSON(json: any): TCOInput {
    return TCOInputFromJSONTyped(json, false);
}

export function TCOInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operationalLifetime': json['operational_lifetime'],
        'annualMileage': json['annual_mileage'],
        'lngVehicleDetails': TCOVehicleDetailsFromJSON(json['lng_vehicle_details']),
        'dieselVehicleDetails': TCOVehicleDetailsDieselFromJSON(json['diesel_vehicle_details']),
    };
}

export function TCOInputToJSON(value?: TCOInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operational_lifetime': value.operationalLifetime,
        'annual_mileage': value.annualMileage,
        'lng_vehicle_details': TCOVehicleDetailsToJSON(value.lngVehicleDetails),
        'diesel_vehicle_details': TCOVehicleDetailsDieselToJSON(value.dieselVehicleDetails),
    };
}


