/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyUserToken
 */
export interface WriteOnlyUserToken {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserToken
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    tokenType: WriteOnlyUserTokenTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    holder?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    userSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserToken
     */
    userDeleted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserToken
     */
    userActive?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserToken
     */
    userSsoId?: string | null;
}

export function WriteOnlyUserTokenFromJSON(json: any): WriteOnlyUserToken {
    return WriteOnlyUserTokenFromJSONTyped(json, false);
}

export function WriteOnlyUserTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyUserToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'token': json['token'],
        'tokenType': json['token_type'],
        'holder': !exists(json, 'holder') ? undefined : json['holder'],
        'userSurname': !exists(json, 'user_surname') ? undefined : json['user_surname'],
        'userName': !exists(json, 'user_name') ? undefined : json['user_name'],
        'userDeleted': !exists(json, 'user_deleted') ? undefined : json['user_deleted'],
        'userActive': !exists(json, 'user_active') ? undefined : json['user_active'],
        'userSsoId': !exists(json, 'user_sso_id') ? undefined : json['user_sso_id'],
    };
}

export function WriteOnlyUserTokenToJSON(value?: WriteOnlyUserToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'token': value.token,
        'token_type': value.tokenType,
        'holder': value.holder,
        'user_surname': value.userSurname,
        'user_name': value.userName,
        'user_deleted': value.userDeleted,
        'user_active': value.userActive,
        'user_sso_id': value.userSsoId,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyUserTokenTokenTypeEnum {
    Ibutton = 'ibutton ',
    Rfid = 'rfid',
    DriverCard = 'driver_card',
    Pin = 'pin',
    App = 'app',
    DefaultDriver = 'default_driver'
}


