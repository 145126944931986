import { ReactNode } from 'react';
import cn from 'classnames';
import { Row, Col } from 'antd';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import qa from 'qa-selectors';

interface Props {
    heading: string;
    body: ReactNode;
    className?: string;
    qa?: string;
    submitButton?: {
        onClick?: () => void;
        qa?: string;
        loading?: boolean;
        disabled?: boolean;
    };
    cancelButton?: {
        onClick?: () => void;
        qa?: string;
        disabled?: boolean;
    };
    resetButton?: {
        onClick?: () => void;
        qa?: string;
    };
}

export default function TableFilter(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={cn('table-filter', props.className)} data-qa={props.qa}>
            <div className="table-filter-heading">
                <div className="title">{props.heading}</div>
            </div>
            <div className="table-filter-body">{props.body}</div>
            <div className="table-filter-footer">
                <Row justify="space-between" gutter={16}>
                    <Col>
                        {props.resetButton && (
                            <Button
                                type="link"
                                qa={props.resetButton?.qa ?? qa.common.filter.btnReset}
                                onClick={props.resetButton?.onClick}
                            >
                                {t('common.resetFilter')}
                            </Button>
                        )}
                    </Col>
                    <Col>
                        <Row gutter={12}>
                            <Col>
                                <Button qa={props.cancelButton?.qa} onClick={props.cancelButton?.onClick}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={props.submitButton?.loading}
                                    disabled={props.submitButton?.disabled}
                                    type="primary"
                                    htmlType="submit"
                                    qa={props.submitButton?.qa}
                                    onClick={props.submitButton?.onClick}
                                >
                                    {t('common.applyFilter')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
