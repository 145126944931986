/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RawDataPointFuelLevelModel
 */
export interface RawDataPointFuelLevelModel {
    /**
     * 
     * @type {string}
     * @memberof RawDataPointFuelLevelModel
     */
    type: RawDataPointFuelLevelModelTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof RawDataPointFuelLevelModel
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointFuelLevelModel
     */
    raw: number;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointFuelLevelModel
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointFuelLevelModel
     */
    tankId: number;
}

export function RawDataPointFuelLevelModelFromJSON(json: any): RawDataPointFuelLevelModel {
    return RawDataPointFuelLevelModelFromJSONTyped(json, false);
}

export function RawDataPointFuelLevelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawDataPointFuelLevelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'time': (new Date(json['time'])),
        'raw': json['raw'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'tankId': json['tank_id'],
    };
}

export function RawDataPointFuelLevelModelToJSON(value?: RawDataPointFuelLevelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'time': (value.time.toISOString()),
        'raw': value.raw,
        'level': value.level,
        'tank_id': value.tankId,
    };
}

/**
* @export
* @enum {string}
*/
export enum RawDataPointFuelLevelModelTypeEnum {
    FuelLevel = 'fuel_level'
}


