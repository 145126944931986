import { WithTranslation, withTranslation } from 'react-i18next';
import PartnerTableBar from 'modules/partner/components/PartnerTableBar';
import CcrTable from '../CcrTable';
import { CcrModel } from 'logic/partner/logic/partner-ccr';
import CcrCreate from '../CcrCreate';
import Confirm, { MessageType } from 'common/components/Confirm';
import CcrDetail from '../CcrDetail';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import PartnerPairing from 'modules/partner/components/PartnerPairing';
import { PairingItemType } from 'logic/partner/partner';
import { LayoutContent } from 'common/components/Layout/Content';
import { PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import CcrDetailCard from '../CcrDetailCard';
import CcrLinkedItems from '../CcrLinkedItems';

interface Props extends WithTranslation {
    edit: boolean;
    loading: boolean;
    data?: CcrModel[];
    selected?: CcrModel;
    create?: CcrModel;
    delete?: CcrModel;
    search?: { text: string };
    pairing?: PartnerPairingModel;
    companies?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    onBarCompanyFilterChange?: (id: string) => void;
    onBarCompanyFilterCancel?: () => void;
    onBarCompanyFilterSearch?: (value: string) => void;
    onTableRowSelect?: (id: string) => void;
    onDetailFormCancel?: () => void;
    onDetailFormSubmit?: (model: CcrModel) => void;
    onDetailActionsDelete?: (model: CcrModel) => void;
    onDetailActionsPair?: () => void;
    onDetailActionsUnpair?: (linked: PairingItemType) => void;
    onDetailActionsCreate?: () => void;
    onDeleteConfirm?: () => void;
    onDeleteCancel?: () => void;
    onCreateSubmit?: () => void;
    onCreateCancel?: () => void;
    onPairingKeySelect?: (key: PartnerPairingKey) => void;
    onPairingItemSelect?: (itemId: string) => void;
    onPairingSearch?: (text: string) => void;
    onPairingConfirm?: () => void;
    onPairingCancel?: () => void;
}

function Obu(props: Props) {
    const { t } = props;

    return (
        <>
            {props.pairing && (
                <PartnerPairing
                    keys={props.pairing.keys}
                    keySelected={props.pairing.keySelected}
                    items={props.pairing.items}
                    itemIdSelected={props.pairing.itemIdSelected}
                    search={props.pairing.search}
                    loading={props.pairing.loading}
                    keySource={PartnerPairingKey.ccr}
                    onKeySelect={props.onPairingKeySelect}
                    onItemSelect={props.onPairingItemSelect}
                    onSearch={props.onPairingSearch}
                    onConfirm={props.onPairingConfirm}
                    onCancel={props.onPairingCancel}
                />
            )}

            {props.create && (
                <CcrCreate model={props.create} onSubmit={props.onCreateSubmit} onCancel={props.onCreateCancel} />
            )}

            {props.delete && (
                <Confirm
                    danger
                    header={t('PartnerCcr.deleteHeader')}
                    message={`${t('PartnerCcr.deleteConfirm')} ${props.delete?.ident}`}
                    type={MessageType.WARNING}
                    confirmLabel={props.t('common.delete')}
                    onConfirm={props.onDeleteConfirm}
                    onCancel={props.onDeleteCancel}
                />
            )}
            <LayoutContent
                className="partner-ccr no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.companyCardReader')}
                            filters={[
                                <PartnerTableBar
                                    companies={props.companies}
                                    selectedCompany={props.selectedCompany}
                                    onCompanyFilterChange={props.onBarCompanyFilterChange}
                                    onCompanyFilterCancel={props.onBarCompanyFilterCancel}
                                    onCompanyFilterSearch={props.onBarCompanyFilterSearch}
                                />
                            ]}
                        />
                        <CcrTable
                            loading={props.loading}
                            data={props.data}
                            selected={props.selected}
                            onTableRowSelect={props.onTableRowSelect}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            header={props.selected.sn}
                            body={
                                <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                    <Collapse.Panel header={props.t('common.detail')} key="1">
                                        {props.edit ? (
                                            <CcrDetail
                                                model={props.selected}
                                                onFormCancel={props.onDetailFormCancel}
                                                onFormSubmit={props.onDetailFormSubmit}
                                            />
                                        ) : (
                                            <CcrDetailCard data={props.selected} />
                                        )}
                                    </Collapse.Panel>

                                    {!props.edit ? (
                                        <Collapse.Panel header={props.t('Partner.linkedItems')} key="2">
                                            <CcrLinkedItems
                                                model={props.selected}
                                                onUnpair={props.onDetailActionsUnpair}
                                            />
                                        </Collapse.Panel>
                                    ) : (
                                        <></>
                                    )}
                                </Collapse>
                            }
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(Obu);
