export function findClosestIndex(target: number, arr: number[]): number {
    let min: number;
    let index: number = 0;
    arr.forEach((_item, i) => {
        min = Math.abs(arr[index] - target);
        if (Math.abs(arr[i] - target) < min) {
            index = i;
        }
    });
    return index;
}

export function findFirstBiggerItem(target: number, arr: number[]): number {
    let index: number = 0;
    arr.sort((a, b) => b - a);
    arr.forEach((_item, i) => {
        if (arr[i] >= target) {
            index = i;
        }
    });
    return arr[index];
}

export function findFirstBiggerItemIndex(target: number, arr: number[]): number {
    let index: number = 0;
    arr.forEach((_item, i) => {
        if (_item < target) {
            index = i;
        }
    });
    return index;
}
