/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectFleetType,
    MonitoredObjectFleetTypeFromJSON,
    MonitoredObjectFleetTypeFromJSONTyped,
    MonitoredObjectFleetTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComputedMarketAvgPricePerKmOfFleetType
 */
export interface ComputedMarketAvgPricePerKmOfFleetType {
    /**
     * 
     * @type {string}
     * @memberof ComputedMarketAvgPricePerKmOfFleetType
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedMarketAvgPricePerKmOfFleetType
     */
    avgPricePerKm: number;
    /**
     * 
     * @type {Date}
     * @memberof ComputedMarketAvgPricePerKmOfFleetType
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof ComputedMarketAvgPricePerKmOfFleetType
     */
    numberOfCompanies: number;
    /**
     * 
     * @type {MonitoredObjectFleetType}
     * @memberof ComputedMarketAvgPricePerKmOfFleetType
     */
    fleetType: MonitoredObjectFleetType;
}

export function ComputedMarketAvgPricePerKmOfFleetTypeFromJSON(json: any): ComputedMarketAvgPricePerKmOfFleetType {
    return ComputedMarketAvgPricePerKmOfFleetTypeFromJSONTyped(json, false);
}

export function ComputedMarketAvgPricePerKmOfFleetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputedMarketAvgPricePerKmOfFleetType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'avgPricePerKm': json['avg_price_per_km'],
        'date': (new Date(json['date'])),
        'numberOfCompanies': json['number_of_companies'],
        'fleetType': MonitoredObjectFleetTypeFromJSON(json['fleet_type']),
    };
}

export function ComputedMarketAvgPricePerKmOfFleetTypeToJSON(value?: ComputedMarketAvgPricePerKmOfFleetType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'avg_price_per_km': value.avgPricePerKm,
        'date': (value.date.toISOString()),
        'number_of_companies': value.numberOfCompanies,
        'fleet_type': MonitoredObjectFleetTypeToJSON(value.fleetType),
    };
}


