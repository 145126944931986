/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectMetadataProfileMergedItemOptimalRpm
 */
export interface MonitoredObjectMetadataProfileMergedItemOptimalRpm {
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObjectMetadataProfileMergedItemOptimalRpm
     */
    md: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectMetadataProfileMergedItemOptimalRpm
     */
    v?: number;
}

export function MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSON(json: any): MonitoredObjectMetadataProfileMergedItemOptimalRpm {
    return MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSONTyped(json, false);
}

export function MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectMetadataProfileMergedItemOptimalRpm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'md': json['md'],
        'v': !exists(json, 'v') ? undefined : json['v'],
    };
}

export function MonitoredObjectMetadataProfileMergedItemOptimalRpmToJSON(value?: MonitoredObjectMetadataProfileMergedItemOptimalRpm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'md': value.md,
        'v': value.v,
    };
}


