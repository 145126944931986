/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FuelConsumptionGlobal
 */
export interface FuelConsumptionGlobal {
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionGlobal
     */
    fc0CDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionGlobal
     */
    fc0CStayIdle: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionGlobal
     */
    fc0TStayIdleCntDp: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionGlobal
     */
    fc0CCStayIncCStayPtoDDrv: number;
}

export function FuelConsumptionGlobalFromJSON(json: any): FuelConsumptionGlobal {
    return FuelConsumptionGlobalFromJSONTyped(json, false);
}

export function FuelConsumptionGlobalFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionGlobal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fc0CDrv': json['fc0_c_drv'],
        'fc0CStayIdle': json['fc0_c_stay_idle'],
        'fc0TStayIdleCntDp': json['fc0_t_stay_idle_cnt_dp'],
        'fc0CCStayIncCStayPtoDDrv': json['fc0_c_c_stay_inc_c_stay_pto_d_drv'],
    };
}

export function FuelConsumptionGlobalToJSON(value?: FuelConsumptionGlobal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fc0_c_drv': value.fc0CDrv,
        'fc0_c_stay_idle': value.fc0CStayIdle,
        'fc0_t_stay_idle_cnt_dp': value.fc0TStayIdleCntDp,
        'fc0_c_c_stay_inc_c_stay_pto_d_drv': value.fc0CCStayIncCStayPtoDDrv,
    };
}


