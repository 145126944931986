/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyProfileSerializer
 */
export interface ReadOnlyProfileSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProfileSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProfileSerializer
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyProfileSerializer
     */
    aboveTemperatureThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyProfileSerializer
     */
    belowTemperatureThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyProfileSerializer
     */
    alarmTimerSeconds?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyProfileSerializer
     */
    client: number;
}

export function ReadOnlyProfileSerializerFromJSON(json: any): ReadOnlyProfileSerializer {
    return ReadOnlyProfileSerializerFromJSONTyped(json, false);
}

export function ReadOnlyProfileSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyProfileSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'aboveTemperatureThreshold': !exists(json, 'above_temperature_threshold') ? undefined : json['above_temperature_threshold'],
        'belowTemperatureThreshold': !exists(json, 'below_temperature_threshold') ? undefined : json['below_temperature_threshold'],
        'alarmTimerSeconds': !exists(json, 'alarm_timer_seconds') ? undefined : json['alarm_timer_seconds'],
        'client': json['client'],
    };
}

export function ReadOnlyProfileSerializerToJSON(value?: ReadOnlyProfileSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'above_temperature_threshold': value.aboveTemperatureThreshold,
        'below_temperature_threshold': value.belowTemperatureThreshold,
        'alarm_timer_seconds': value.alarmTimerSeconds,
        'client': value.client,
    };
}


