import React, { Component } from 'react';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';

import { FleetLinks, FleetModel, ToBeUnpaired, FleetType } from '../FleetModule';
import { VehicleFormModel } from './FleetCreateVehicle';
import LinkedItem, { LinkedItemType } from 'common/components/LinkedItem';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import { ExternalDeviceType } from 'generated/backend-api-live';

export interface Notifications {
    gps?: number;
    comunication?: number;
    battery?: number;
    corridor?: number;
    driver?: boolean;
    restricted?: number;
}

interface VehicleDetailMenu {
    detail?: boolean;
    actions?: boolean;
    alarms?: boolean;
}

interface Props extends WithTranslation {
    model?: FleetModel;
    tunnelTypeOpts?: [];
    demoMode?: boolean;
    roles: Role[];
    onCreateTrailerButtonClick?: () => void;
    onCreateVehicleButtonClick?: () => void;
    onDetailButtonCloseClick?: () => void;
    onFleetRemoveButtonClick?: (model: FleetModel) => void;
    onLocateOnMapButtonClick?: (id: number) => void;
    onPairNewAssetButtonClick?: () => void;
    onTagsButtonClick?: () => void;
    onUnpairClick?: (type: keyof FleetLinks, data: ToBeUnpaired) => void;
    onVehicleFormSubmitClick?: (model: FleetModel) => void;
    onOperationalCostsClick?: () => void;
}

interface State {
    menu?: VehicleDetailMenu;
    vehicleForm?: VehicleFormModel;
    notifications?: Notifications;
}

class FleetLinkedItems extends Component<Props, State> {
    fleetModel?: FleetModel;
    constructor(props: Props) {
        super(props);
        this.state = {
            menu: { detail: true, actions: true }
        };
    }

    componentDidMount() {
        (window as any).app.vehicleDetail = this;
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.model !== this.props.model) {
            this.fleetModel = undefined;
            this.setState({ vehicleForm: undefined });
        }
    }

    render() {
        const { t, model, demoMode } = this.props;

        return (
            <div className="management-fleet-pairing-list">
                {!model ? (
                    t('common.selectItemInTableFirst')
                ) : (
                    <>
                        {model.links?.fleet?.map((data, i) => (
                            <div key={i}>
                                {model.type === FleetType.VEHICLE || model.type === FleetType.LIGHT_VEHICLE ? (
                                    <LinkedItem
                                        parentId={String(model.id)}
                                        typeName={t('common.trailer')}
                                        type={
                                            data.trailerPairingType === ExternalDeviceType.TrailerId
                                                ? LinkedItemType.TRAILER_DYNAMIC
                                                : LinkedItemType.TRAILER
                                        }
                                        heading={<span data-qa={qa.fleet.linkedItems.trailer.rn}>{data.rn}</span>}
                                        disabled={demoMode}
                                        items={[
                                            {
                                                label: t('common.registrationNumber'),
                                                value: data.rn,
                                                qa: qa.fleet.linkedItems.fieldRn
                                            },
                                            {
                                                label: t('ManagementFleet.trailerType'),
                                                value: t(`TrailerTypes.${data.type}`),
                                                qa: qa.fleet.linkedItems.fieldType
                                            },
                                            {
                                                label: t(`ManagementLinksMetadata.dateOfPairing`),
                                                value: moment(data.metadata?.dateOfPairing).format('L') ?? '',
                                                qa: qa.fleet.linkedItems.fieldDateOfPairing
                                            },
                                            {
                                                label: t(`ManagementLinksMetadata.locationWhenPaired`),
                                                value: data.metadata?.locationWhenPaired ?? '',
                                                qa: qa.fleet.linkedItems.fieldLocationWhenPaired
                                            }
                                        ]}
                                        qa={qa.fleet.linkedItems.sectionTrailer}
                                        onUnpairClick={() =>
                                            this._onUnpairClick('trailers', {
                                                id: data.id,
                                                idToUnpair: String(model.id)
                                            })
                                        }
                                    />
                                ) : (
                                    <LinkedItem
                                        parentId={String(model.id)}
                                        disabled={demoMode || !this.props.roles.includes(Role.V_IE)}
                                        type={LinkedItemType.VEHICLE}
                                        typeName={t('common.vehicle')}
                                        heading={<span data-qa={qa.fleet.linkedItems.vehicle.rn}>{data.rn}</span>}
                                        items={[
                                            {
                                                label: t('common.registrationNumber'),
                                                value: data.rn,
                                                qa: qa.fleet.linkedItems.fieldRn
                                            }
                                        ]}
                                        qa={qa.fleet.linkedItems.sectionVehicle}
                                        onUnpairClick={() =>
                                            this._onUnpairClick('vehicles', {
                                                id: data.id,
                                                idToUnpair: String(model.id)
                                            })
                                        }
                                    />
                                )}
                            </div>
                        ))}
                        {model.links?.fuelCard?.map((data, i) => (
                            <div key={i}>
                                <LinkedItem
                                    parentId={String(model.id)}
                                    unpairHide={true}
                                    type={LinkedItemType.FUEL_CARD}
                                    disabled={demoMode || !!model.disabledAt}
                                    typeName={t('common.fuelCard')}
                                    heading={<span data-qa={qa.fleet.linkedItems.fuelCard.name}>{data.number}</span>}
                                    items={[
                                        { label: t('common.name'), value: data.number },
                                        { label: t('UsersTable.note'), value: data.note ?? '', ellipses: true }
                                    ]}
                                    qa={qa.fleet.linkedItems.sectionFuelCard}
                                    onUnpairClick={() =>
                                        this._onUnpairClick('fuelCard', {
                                            id: model.id?.toString(),
                                            idToUnpair: data.id?.toString() ?? ''
                                        })
                                    }
                                />
                            </div>
                        ))}
                        {model.links?.defaultDriver && (
                            <div>
                                <LinkedItem
                                    parentId={String(model.id)}
                                    disabled={demoMode}
                                    typeName={t('common.defaultDriver')}
                                    heading={
                                        <span data-qa={qa.fleet.linkedItems.defaultDriver.name}>
                                            {model.links.defaultDriver.name ?? ''}{' '}
                                            {model.links.defaultDriver.surname ?? ''}
                                        </span>
                                    }
                                    type={LinkedItemType.DEFAULT_DRIVER}
                                    items={[
                                        {
                                            label: t('common.name'),
                                            value: `${model.links.defaultDriver.name ?? ''} ${
                                                model.links.defaultDriver.surname ?? ''
                                            }`
                                        }
                                    ]}
                                    qa={qa.fleet.linkedItems.sectionDefaultDriver}
                                    onUnpairClick={() =>
                                        this._onUnpairClick('defaultDriver', {
                                            id: String(model.id),
                                            idToUnpair: ''
                                        })
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }

    private _onUnpairClick = (type: keyof FleetLinks, data: ToBeUnpaired): void => {
        this.props.onUnpairClick?.(type, data);
    };
}

export default withTranslation()(FleetLinkedItems);
