import { google } from 'google-maps';
import { MapConf, MapLogic } from '../map';
import ReactDOMServer from 'react-dom/server';
import { VehicleClusterInfobox } from 'modules/map/components/VehicleClusterInfobox/VehicleClusterInfobox';
import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { Alarm } from 'common/model/alarm';
import { MAP_MARKERS_INDEX, MAX_ZOOM } from 'domain-constants';
import { ChargingObject, EFuelType, VehicleStateObject } from 'generated/graphql';
import { getCenterBoundsWithDistance } from 'common/utils/geo';
import { ignitionToStatus } from 'common/utils/mappers';
import { Cluster } from '@googlemaps/markerclustererplus/dist/cluster';
import { NoGPSStatus } from 'common/model/tracking';

declare global {
    interface Window {
        renderVehiclesInView?: boolean;
        allowInfoWindowVisibleOutsideBounds?: boolean;
    }
}

const MarkerWithLabel = require('@google/markerwithlabel');

const markerClustererOptions: MarkerClustererOptions = {
    gridSize: 160,
    zoomOnClick: false,
    enableRetinaIcons: true,
    styles: [
        {
            url: '/markers/cluster.png',
            height: 76,
            width: 76,
            textColor: '#ffffff',
            textSize: 12,
            anchorIcon: [36, 36],
            anchorText: [31, 0],
            fontWeight: '700',
            className: 'vehicle-cluster'
        },
        {
            url: '/markers/cluster-alert.png',
            height: 76,
            width: 76,
            textColor: '#ffffff',
            textSize: 12,
            anchorIcon: [36, 36],
            anchorText: [31, 0],
            fontWeight: '700',
            className: 'vehicle-cluster'
        }
    ],
    zIndex: MAP_MARKERS_INDEX.VEHICLE
};

// PNG has better performance for map. do not change for svg.
export enum VehicleMarkerIcon {
    NoGps = '/markers/vehicle-no-gps.png',

    Moving = '/markers/vehicle-moving.png',
    MovingSelected = '/markers/vehicle-moving-selected.png',

    Idling = '/markers/vehicle-idling.png',
    IdlingSelected = '/markers/vehicle-idling-selected.png',

    Stationary = '/markers/vehicle-stationary.png',
    StationarySelected = '/markers/vehicle-stationary-selected.png',

    Alarm = '/markers/vehicle-alarm.png',
    AlarmSelected = '/markers/vehicle-alarm-selected.png',

    AlarmWarning = '/markers/vehicle-alarm-warning.png',
    AlarmSelectedWarning = '/markers/vehicle-alarm-warning-selected.png'
}

export interface VehicleModelMap {
    id: string;
    name: string;
    trailers: string[];
    selected: boolean;
    centered: boolean;
    route: boolean;
    moving: boolean;
    status: number;
    angle?: number;
    position: { lat?: number | null; lng?: number | null };
    alarms: Alarm[];
    fuelType?: EFuelType;
    charging?: ChargingObject;
    noGpsStatus?: NoGPSStatus;
}

export class VehiclesMapController {
    private _conf: MapConf;
    private _ctrl: MapLogic;
    private _map?: google.maps.Map;
    private _markers: google.maps.Marker[];
    private _cluster?: MarkerClusterer;
    private _google: google;
    private _clusteringEnable: boolean;
    private _markerIdleListener?: google.maps.MapsEventListener;
    private _clusteringBeginListener?: google.maps.MapsEventListener;
    private _clusteringEndListener?: google.maps.MapsEventListener;
    private _clusterClickListener?: google.maps.MapsEventListener;
    private _clusterInfoBoxes: google.maps.InfoWindow[];
    private _markerInfoBoxes: {
        [key: string]: google.maps.InfoWindow;
    };

    private _redrawVehiclesAfterFitBounds?: boolean;

    private _onVehicleClick?: (vehicle: VehicleModelMap) => void;
    private _vehicleData?: VehicleModelMap[];

    constructor(conf: MapConf, google: google, ctrl: MapLogic, map?: google.maps.Map) {
        this._map = map;
        this._conf = conf;
        this._ctrl = ctrl;
        this._google = google;
        this._markers = [];
        this._clusterInfoBoxes = [];
        this._markerInfoBoxes = {};
        this._clusteringEnable = conf.vehicles.clusteringEnabled ?? true;
    }

    setClusteringEnable(enable: boolean): void {
        this._clusteringEnable = enable;
        if (this._clusteringEnable) {
            this.clusterVehicles();
        } else {
            this.unclusterVehicles();
        }
    }

    clusterVehicles() {
        this._markerIdleListener?.remove();
        this._removeMarkerInfoBoxes();
        this._renderCluster();
    }

    unclusterVehicles() {
        this._removeClusterInfoBoxes();
        this._cluster?.clearMarkers();
        this._cluster = undefined;
        this._clusteringBeginListener?.remove();
        this._clusteringEndListener?.remove();
        this._clusterClickListener?.remove();

        if (window.renderVehiclesInView) {
            this._renderMarkersInView();
            this._markerIdleListener = this._map?.addListener('idle', () => {
                this._renderMarkersInView();
            });
        } else {
            this._renderMarkers();
        }
    }

    mapZoomListener = () => {
        const vehicle = this._markers.find(
            m => (m.get('vehicle') as VehicleModelMap)?.selected && (m.get('vehicle') as VehicleModelMap)?.centered
        );
        if (vehicle) {
            const p = vehicle.getPosition();
            if (p) {
                const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                this._map?.setCenter(p);
                this._map?.panBy(x, y);
            }
        }
    };

    show(): void {
        if (this._clusteringEnable) {
            this._renderCluster();
        } else {
            if (window.renderVehiclesInView) {
                this._renderMarkersInView();
                this._markerIdleListener = this._map?.addListener('idle', () => {
                    this._renderMarkersInView();
                });
            } else {
                this._renderMarkers();
            }
        }
        this._map?.addListener('zoom_changed', this.mapZoomListener);
    }

    hide(): void {
        this._cluster?.clearMarkers();
        this._cluster?.setMap(null);
        this._markers.forEach(m => m.setMap(null));
        this._removeClusterInfoBoxes();
        this._removeMarkerInfoBoxes();
        this._markers = [];
        this._markerInfoBoxes = {};
        this._clusteringBeginListener?.remove();
        this._clusteringEndListener?.remove();
        this._clusterClickListener?.remove();
        this._markerIdleListener?.remove();
    }

    onClick(cb: (vehicle: VehicleModelMap) => void): void {
        this._onVehicleClick = cb;
    }

    fitVehicles(): void {
        this._fitVehicles();
        this._redrawVehiclesAfterFitBounds = true;
    }

    fitVehicle(id: string): void {
        this._fitVehicle(id);
    }

    setData(data: VehicleModelMap[]) {
        if (this._cluster) {
            this._removeClusterInfoBoxes();
            this._cluster?.clearMarkers();
        }

        if (this._markers.length > 0) {
            this._removeMarkerInfoBoxes();
            this._markers.forEach(m => m.setMap(null));
            this._markers = [];
        }

        for (const marker of this._markers) {
            marker.setMap(null);
        }

        this._vehicleData = data;
        this._markers = data.map(vehicle => this.createVehicleMarker(vehicle));
        return this._markers;
    }

    update(data: VehicleModelMap[]): void {
        const vehiclesIdThatWasUpdated: number[] = [];
        data.forEach(newVehicle => {
            const oldMarker = this._markers.find(marker => marker.get('vehicle').id === newVehicle.id);
            const vehicleDataIndex = this._vehicleData?.findIndex(v => v.id === newVehicle.id);
            if (vehicleDataIndex) {
                this._vehicleData?.splice(vehicleDataIndex, 1, newVehicle);
            } else {
                this._vehicleData?.push(newVehicle);
            }

            if (oldMarker) {
                const oldVehicle: VehicleModelMap = oldMarker.get('vehicle');
                if (
                    newVehicle?.position?.lat &&
                    newVehicle?.position?.lng &&
                    (!oldVehicle.position ||
                        oldVehicle?.position?.lat !== newVehicle?.position?.lat ||
                        oldVehicle?.position?.lng !== newVehicle?.position?.lng ||
                        oldVehicle?.selected !== newVehicle?.selected ||
                        oldVehicle?.status !== newVehicle?.status ||
                        ((!oldVehicle?.alarms || oldVehicle?.alarms.length === 0) && newVehicle.alarms.length > 0) ||
                        ((!newVehicle?.alarms || newVehicle?.alarms.length === 0) && oldVehicle.alarms.length > 0) ||
                        oldVehicle?.alarms.some(
                            a =>
                                a.acknowledged !==
                                newVehicle.alarms.find(newA => newA.alarmId === a.alarmId)?.acknowledged
                        ))
                ) {
                    const marker = this._markers.find(marker => marker.get('vehicle').id === newVehicle.id);

                    if (marker) {
                        // those are markers/infoWindow that we want to update on map
                        vehiclesIdThatWasUpdated.push(Number(newVehicle.id));
                        // different icon size of alarms
                        const iconSize = newVehicle.alarms.length > 0 ? 42 : 66;

                        const labelStyle =
                            newVehicle.alarms.length === 0 && newVehicle.status === 1 ? { opacity: 1 } : undefined;
                        const imgStyle =
                            newVehicle.alarms.length === 0 && newVehicle.status === 1
                                ? `transform: rotate(${newVehicle.angle}deg)`
                                : '';

                        marker.set('labelStyle', labelStyle);
                        marker.set(
                            'labelContent',
                            `<img style="${imgStyle}" src="${this._getVehicleIcon(newVehicle)}"/>`
                        );

                        (marker as any).label.setContent();

                        marker.set('vehicle', newVehicle);
                        marker.setPosition({
                            lat: newVehicle!.position!.lat!,
                            lng: newVehicle!.position!.lng!
                        });
                        marker.setIcon({
                            url: this._getVehicleIcon(newVehicle),
                            size: new this._google.maps.Size(iconSize, iconSize),
                            anchor: new this._google.maps.Point(iconSize / 2, iconSize / 2)
                        });
                        marker.setOptions({
                            anchorPoint: new this._google.maps.Point(iconSize / 2, iconSize / 2)
                        });
                        marker.set('labelAnchor', new this._google.maps.Point(iconSize / 2, iconSize / 2));
                        if (!this._clusteringEnable && this._map) {
                            marker.setMap(this._map);
                        }
                    }
                }
            } else {
                const marker = this.createVehicleMarker(newVehicle);
                this._markers.push(marker);
                this._cluster?.addMarker(marker);
            }
        });

        if (this._clusteringEnable) {
            this._cluster?.repaint();
            this._fitSelectedVehicle();
        } else {
            if (window.renderVehiclesInView) {
                this._renderMarkersInView(vehiclesIdThatWasUpdated);
            } else {
                this._renderMarkers(vehiclesIdThatWasUpdated);
            }

            this._fitSelectedVehicle();
        }
    }

    createVehicleMarker(vehicle: VehicleModelMap): google.maps.Marker {
        const self = this;

        function clickHandler(this: google.maps.Marker) {
            self._onVehicleClick?.(this.get('vehicle'));
        }

        //  different icon size of alarms
        const iconSize = vehicle.alarms.length > 0 ? 42 : 66;

        // Rotate vehicle only when we're moving and don't have any alar,
        const labelStyle =
            vehicle.alarms.length === 0 && vehicle.status === 1
                ? {
                      opacity: 1
                  }
                : undefined;
        const iconStyle =
            vehicle.alarms.length === 0 && vehicle.status === 1 ? `transform: rotate(${vehicle.angle}deg)` : '';

        const marker = new MarkerWithLabel({
            position: vehicle.position,
            icon: {
                url: this._getVehicleIcon(vehicle),
                size: new this._google.maps.Size(iconSize, iconSize),
                anchor: new this._google.maps.Point(iconSize / 2, iconSize / 2)
            },
            clickable: true,

            anchorPoint: new this._google.maps.Point(iconSize / 2, iconSize / 2),
            labelContent: `<img style="${iconStyle}" src="${this._getVehicleIcon(vehicle)}"/>`,
            labelAnchor: new this._google.maps.Point(iconSize / 2, iconSize / 2),
            labelClass: `map-vehicle ${vehicle.fuelType === EFuelType.Electro ? 'map-vehicle-ev' : ''} ${
                vehicle.selected ? 'map-vehicle-ev-selected' : ''
            }`,
            labelStyle,
            zIndex: MAP_MARKERS_INDEX.VEHICLE
        });

        marker.set('vehicle', vehicle);
        marker.addListener('click', clickHandler);

        return marker;
    }

    toVehicleMap(vehicle: VehicleStateObject): VehicleModelMap {
        return {
            id: vehicle.monitoredObjectId!,
            trailers: vehicle.trailers?.map(t => t.registrationNumber!) ?? [],
            moving: !!vehicle?.stateData?.ignition,
            angle: vehicle.gpsData!.angle!,
            position: {
                lat: vehicle.gpsData!.lat!,
                lng: vehicle.gpsData!.lon!
            },
            name: '-',
            selected: false,
            centered: false,
            route: false,
            status: ignitionToStatus(vehicle.stateData?.ignition),
            alarms: [], // TODO: GQL
            fuelType: vehicle.monitoredObjectFuelType ?? undefined,
            charging: vehicle.charging ?? undefined
        };
    }

    private _fitSelectedVehicle() {
        const marker = this._markers.find(
            m => m.get('vehicle').selected && m.get('vehicle').centered && !m.get('vehicle').route
        );

        if (marker) {
            this._centerVehicle(marker.get('vehicle').id);
        }
    }

    private _centerVehicle(id: string) {
        const vehicleMarker = this._markers.find(m => m.get('vehicle').id === id);
        if (vehicleMarker) {
            if (this._map) {
                const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                this._map.setCenter(vehicleMarker.getPosition()!);
                this._map.panBy(x, y);
            }
        }
    }

    private _fitVehicle(id: string) {
        const vehicleMarker = this._markers.find(m => m.get('vehicle').id === id);

        if (vehicleMarker) {
            const bounds = getCenterBoundsWithDistance(
                this._google,
                vehicleMarker.getPosition()!,
                this._conf.vehicles.vehicleCenterDistance
            );
            this._map?.fitBounds(bounds, this._ctrl.getPadding());
        }
    }

    private _createClusterInfoBoxes(cluster: Cluster) {
        const vehicles: VehicleModelMap[] = cluster
            .getMarkers()
            .map(marker => marker.get('vehicle')) as VehicleModelMap[];

        const infoWindow = new this._google.maps.InfoWindow({
            disableAutoPan: true,
            pixelOffset: new this._google.maps.Size(80, cluster.getMarkers().length * 10 + 29)
        });
        infoWindow.setPosition({
            lat: cluster.getCenter().lat(),
            lng: cluster.getCenter().lng()
        });

        infoWindow.setContent(this._renderInfoBoxContent(vehicles));
        this._clusterInfoBoxes.push(infoWindow);
    }

    private _createMarkerInfoBoxes(marker: google.maps.Marker) {
        const vehicle = marker.get('vehicle') as VehicleModelMap;
        const infoWindow =
            this._markerInfoBoxes[vehicle.id] ??
            new this._google.maps.InfoWindow({
                disableAutoPan: true,
                pixelOffset: new this._google.maps.Size(80, 11 + 25)
            });
        const position = marker.getPosition();
        if (position) {
            infoWindow.setPosition(position);
            infoWindow.setContent(this._renderInfoBoxContent([vehicle]));
            this._markerInfoBoxes[vehicle.id] = infoWindow;
        }
    }

    private _renderMarkersInView(vehiclesIdThatWasUpdated?: number[]) {
        const bounds = this._map?.getBounds();
        for (const marker of this._markers) {
            const position = marker.getPosition();
            if (position && bounds?.contains(position)) {
                if (!marker.getMap() || vehiclesIdThatWasUpdated?.includes(Number(marker.get('vehicle').id))) {
                    marker.setOptions({ map: this._map!, visible: true });
                    if (!marker.get('vehicle').selected) {
                        this._createMarkerInfoBoxes(marker);
                    }
                }
            } else {
                marker.setOptions({ map: null, visible: false });
            }
        }

        this._renderMarkerInfoBoxesInView(vehiclesIdThatWasUpdated);
    }

    private _renderMarkers(vehiclesIdThatWasUpdated?: number[]) {
        for (const marker of this._markers) {
            if (!marker.getMap() || vehiclesIdThatWasUpdated?.includes(Number(marker.get('vehicle').id))) {
                marker.setOptions({ map: this._map!, visible: true });
                if (!marker.get('vehicle').selected) {
                    this._createMarkerInfoBoxes(marker);
                }
            }
        }

        this._renderMarkerInfoBoxes(vehiclesIdThatWasUpdated);
    }

    private _renderCluster(): void {
        const clusteringBeginHandler = (_cluster: MarkerClusterer) => {
            this._removeClusterInfoBoxes();
        };

        const clusteringEndHandler = () => {
            this._cluster?.getClusters().forEach(cluster => {
                const vehicles: VehicleModelMap[] = cluster
                    .getMarkers()
                    .map(marker => marker.get('vehicle')) as VehicleModelMap[];

                if (vehicles[0].selected && vehicles.length === 1) {
                    // don't render RN infobox for selected vehicle
                } else {
                    this._createClusterInfoBoxes(cluster);
                }
            });
            this._renderClusterInfoBoxes();
        };

        const clusterClickHandler = (cluster: MarkerClusterer) => {
            // TODO: Check  why this doesn't work
            // self._cluster?.fitMapToMarkers(self._ctrl.getPadding());
            const bounds = new this._google.maps.LatLngBounds();

            cluster.getMarkers().forEach(marker => {
                const position = marker.getPosition();
                position && bounds.extend(position);
            });
            if (cluster.getMarkers() && cluster.getMarkers().length > 0) {
                this._map?.fitBounds(bounds, this._ctrl.getPadding());
            }
        };

        if (this._cluster) {
            this._cluster.clearMarkers();
            this._clusteringBeginListener?.remove();
            this._clusteringEndListener?.remove();
            this._clusterClickListener?.remove();
        }

        this._cluster = new MarkerClusterer(this._map!, this._markers, markerClustererOptions);

        // Rendering style of cluster
        this._cluster?.setCalculator((markers, _numStyles) => {
            // 1 - default, 2 - alert
            const alertCluster = markers.some(marker => marker.get('vehicle').alarms.length > 0);

            return {
                text: String(markers.length),
                index: alertCluster ? 2 : 1,
                title: ''
            };
        });

        this._map?.addListener('idle', () => {
            if (this._clusteringEnable && this._redrawVehiclesAfterFitBounds) {
                this._cluster?.repaint();
            }
            this._redrawVehiclesAfterFitBounds = false;
        });
        this._clusteringBeginListener = this._cluster?.addListener('clusteringbegin', clusteringBeginHandler);
        this._clusteringEndListener = this._cluster?.addListener('clusteringend', clusteringEndHandler);
        this._clusterClickListener = this._cluster?.addListener('clusterclick', clusterClickHandler);
    }

    private _getVehicleIcon(vehicle: VehicleModelMap): VehicleMarkerIcon | string {
        if (vehicle.noGpsStatus === NoGPSStatus.SwitzerlandUnavailable) {
            return VehicleMarkerIcon.NoGps;
        }

        if (vehicle.alarms.length) {
            if (vehicle.alarms.some(vehicle => vehicle.acknowledged === undefined || vehicle.acknowledged === false)) {
                return vehicle.selected ? VehicleMarkerIcon.AlarmSelected : VehicleMarkerIcon.Alarm;
            } else {
                return vehicle.selected ? VehicleMarkerIcon.AlarmSelectedWarning : VehicleMarkerIcon.AlarmWarning;
            }
        }

        switch (vehicle.status) {
            case 0:
                return vehicle.selected ? VehicleMarkerIcon.StationarySelected : VehicleMarkerIcon.Stationary;
            case 1:
                return vehicle.selected ? VehicleMarkerIcon.MovingSelected : VehicleMarkerIcon.Moving;
            case 2:
                return vehicle.selected ? VehicleMarkerIcon.IdlingSelected : VehicleMarkerIcon.Idling;
            // Vehicle status not found
            default:
                return VehicleMarkerIcon.Stationary;
        }
    }

    private _removeClusterInfoBoxes(): void {
        this._clusterInfoBoxes.forEach(infoBox => {
            infoBox.close();
        });
        this._clusterInfoBoxes = [];
    }

    private _renderClusterInfoBoxes(): void {
        this._clusterInfoBoxes.forEach(infoBox => {
            infoBox.open({
                shouldFocus: false,
                anchor: new this._google.maps.Marker({
                    map: this._map
                })
            });
        });
    }

    private _removeMarkerInfoBoxes(): void {
        Object.values(this._markerInfoBoxes).forEach(infoBox => {
            infoBox.close();
        });
        this._markerInfoBoxes = {};
    }

    private _renderMarkerInfoBoxesInView(vehiclesIdThatWasUpdated?: number[]): void {
        const bounds = this._map?.getBounds();
        Object.entries(this._markerInfoBoxes).forEach(entry => {
            const [vehicleId, infoBox] = entry;
            const position = infoBox.getPosition();
            if (position && bounds?.contains(position)) {
                // @ts-ignore
                if (!infoBox.getMap() || vehiclesIdThatWasUpdated?.includes(vehicleId)) {
                    infoBox.open({
                        shouldFocus: false,
                        anchor: new this._google.maps.Marker({
                            map: this._map
                        })
                    });
                }
            } else if (!window.allowInfoWindowVisibleOutsideBounds) {
                infoBox.close();
            }
        });
    }

    private _renderMarkerInfoBoxes(vehiclesIdThatWasUpdated?: number[]): void {
        Object.entries(this._markerInfoBoxes).forEach(entry => {
            const [vehicleId, infoBox] = entry;
            // @ts-ignore
            if (!infoBox.getMap() || vehiclesIdThatWasUpdated?.includes(vehicleId)) {
                infoBox.close();
                infoBox.open({
                    shouldFocus: false,
                    anchor: new this._google.maps.Marker({
                        map: this._map
                    })
                });
            }
        });
    }

    private _renderInfoBoxContent(vehicles: VehicleModelMap[]): string {
        return ReactDOMServer.renderToString(VehicleClusterInfobox({ vehicles, showIcon: vehicles.length > 1 }));
    }

    private _fitVehicles(maxZoom: number = MAX_ZOOM): void {
        if (this._map && this._markers && this._markers.length > 0) {
            let bounds = new this._google.maps.LatLngBounds();

            this._markers.forEach(marker => bounds.extend(marker.get('vehicle').position));

            if (this._map) {
                this._map?.setOptions({ maxZoom });
                this._map?.fitBounds(bounds, this._ctrl.getPadding());
                const zoomAfterFit = this._map?.getZoom();
                if (maxZoom && zoomAfterFit && zoomAfterFit >= maxZoom) {
                    // Refit bounds for zoomed "short polylines"
                    this._map?.fitBounds(bounds, this._ctrl.initialPadding);

                    const x = ((this._ctrl?.getPadding().right ?? 0) - (this._ctrl?.getPadding().left ?? 0)) / 2;
                    const y = ((this._ctrl?.getPadding().bottom ?? 0) - (this._ctrl?.getPadding().top ?? 0)) / 2;
                    this._map?.panBy(x, y);
                }
                this._map?.setOptions({
                    maxZoom: this._ctrl.getDefaultZoom().max
                });
            }
        }
    }
}
