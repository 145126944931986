import React from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components';
import { countriesCodes } from 'common/model/countries';
import { SelectOption } from 'common/components/Form/Select';
import { SelectField, DatePickerField } from 'common/fields';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import qa from 'qa-selectors';

export interface BorderCrossingFormModel {
    date?: string;
    countryEnter?: string;
    countryExit?: string;
}

interface Props {
    type?: 'default' | 'edit';
    initialValues?: Partial<BorderCrossingFormModel>;
    lang: string;
    onSubmit: (values: BorderCrossingFormModel) => void;
    onCancel?: () => void;
}

const countriesOpts: SelectOption[] = countriesCodes.map(c => ({
    label: c.name,
    value: c.iso2
}));

function BorderCrossingForm({ onSubmit, initialValues, onCancel, type, lang }: Props) {
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        date: Yup.string().required(t('common.required')),
        countryEnter: Yup.string().required(t('common.required')),
        countryExit: Yup.string().required(t('common.required'))
    });

    function handleOnSubmit(values: BorderCrossingFormModel) {
        onSubmit?.(values);
    }

    return (
        <Formik<BorderCrossingFormModel>
            initialValues={{
                date: initialValues?.date,
                countryEnter: initialValues?.countryEnter,
                countryExit: initialValues?.countryExit
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<BorderCrossingFormModel>) => (
                <div className="border-crossing-form">
                    <Form>
                        <Row gutter={[20, 10]}>
                            <Col span={8}>{t('Allowance.borderCrossingForm.crossingDate')}</Col>
                            <Col span={16}>
                                <DatePickerField lang={lang} showTime={{ format: TIME_FORMAT_SHORT }} name="date" />
                            </Col>
                        </Row>
                        <Row gutter={[20, 10]}>
                            <Col span={8}>{t('Allowance.borderCrossingForm.countryFrom')}</Col>
                            <Col span={16}>
                                <SelectField
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    name="countryEnter"
                                    options={countriesOpts}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 50]}>
                            <Col span={8}>{t('Allowance.borderCrossingForm.countryTo')}</Col>
                            <Col span={16}>
                                <SelectField
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    name="countryExit"
                                    options={countriesOpts}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-buttons">
                            <Col span={12}>
                                <Button onClick={onCancel}>
                                    {t('common.cancel')}
                                    qa={qa.borderCrossing.form.btnCancel}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    disabled={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                    qa={qa.borderCrossing.form.btnSubmit}
                                >
                                    {type === 'edit' ? t('common.edit') : t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default BorderCrossingForm;
