import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import numeral from 'numeral';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AlarmInDatabaseWithGPSInfo, AlarmType, ColdChainProfileActiveEventRule } from 'generated/backend-api';
import { Table } from 'common/components';
import { DATE_TIME_FORMAT, degC, GPS_FORMAT, H, TABLE_SCROLL_Y_SUPER_SMALL } from 'domain-constants';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { ColdchainTemperatureSensorModel } from '../../coldchain-logic';
import { LINE_COLORS_COMPLEX } from 'common/components/SimpleLineGraph/constants/colors';
import i18next from 'i18next';
import { ColdchainAlarmProfile } from '../../ColdchainModule';

interface Props extends WithTranslation {
    temperatureSensors?: ColdchainTemperatureSensorModel[];
    temperatureSensorsAlarmsData?: AlarmInDatabaseWithGPSInfo[];
    temperatureProfiles?: ColdChainProfileActiveEventRule[];
    loading?: boolean;
    onRowClick?: () => void;
}

export function coldchainAlarmName(data: AlarmInDatabaseWithGPSInfo): string {
    const profile: ColdchainAlarmProfile | undefined = data?.uniqueData?.['cold_chain_profile'];

    const value =
        data.alarmType === AlarmType.TransportColdChainProfileTemperatureLow
            ? profile?.below_temperature_threshold
            : data.alarmType === AlarmType.TransportColdChainProfileTemperatureHigh
            ? profile?.above_temperature_threshold
            : undefined;
    const typeValue = `${i18next.t(`Coldchain.types.${data.alarmType}`)} ${value ?? ' '} ${degC}`;
    const timeValue =
        profile?.alarm_timer_seconds !== null
            ? `, ${i18next.t('Coldchain.thresholdTimer', {
                  timer: numeral(Number(profile?.alarm_timer_seconds) / 60).format('0,0')
              })}`
            : '';

    return `${typeValue}${timeValue}`;
}

function ColdchainAlertTable(props: Props) {
    return (
        <div className="coldchain-alert-table">
            <Table<AlarmInDatabaseWithGPSInfo>
                loading={props.loading}
                dataSource={props.temperatureSensorsAlarmsData?.map(item => ({ key: item.alarmId, ...item }))}
                scroll={{ y: TABLE_SCROLL_Y_SUPER_SMALL }}
                columns={createColumns()}
            />
        </div>
    );

    function createColumns() {
        return [
            {
                title: props.t('Coldchain.alert'),
                dataIndex: 'alarmType',
                key: 'alarmType',
                align: 'left',
                width: 200,
                render: (_, record) => {
                    return coldchainAlarmName(record);
                }
            },
            {
                title: props.t('common.location'),
                align: 'left',
                width: 150,
                render: (_, record) => (
                    <AddressFormatter
                        defaultAddress={`${numeral(record.lastGpsPointStartObj?.lat).format(GPS_FORMAT)}, ${numeral(
                            record.lastGpsPointStartObj?.lng
                        ).format(GPS_FORMAT)}`}
                        addressStructured={record.addressStart}
                    />
                )
            },
            {
                title: props.t('common.sensor'),
                key: 'sensor',
                align: 'left',
                width: 100,
                render: (_, record) => {
                    return (
                        <span
                            style={{
                                color: LINE_COLORS_COMPLEX[
                                    props.temperatureSensors?.findIndex(
                                        sensor =>
                                            sensor.serialNumber ===
                                            record.uniqueData?.['temperature_sensor_serial_number']
                                    ) ?? 0
                                ]
                            }}
                        >
                            {
                                props.temperatureSensors?.find(
                                    sensor =>
                                        sensor.serialNumber === record.uniqueData?.['temperature_sensor_serial_number']
                                )?.sensorName
                            }
                        </span>
                    );
                }
            },
            {
                title: props.t('common.status'),
                key: 'status',
                align: 'left',
                width: 100,
                render: (_, record) =>
                    moment(record.end).isAfter(moment()) ? props.t('common.alerting') : props.t('common.resolved')
            },
            {
                title: props.t('common.from'),
                dataIndex: 'start',
                key: 'start',
                align: 'left',
                width: 100,
                render: data =>
                    moment(moment.utc(moment(data).format('YYYY-MM-DD HH:mm:ss')).toDate())
                        .local()
                        .format(DATE_TIME_FORMAT)
            },
            {
                title: props.t('common.to'),
                dataIndex: 'end',
                key: 'end',
                align: 'left',
                width: 100,
                render: data =>
                    moment.utc(data).isBefore(moment.utc())
                        ? moment(moment.utc(moment(data).format('YYYY-MM-DD HH:mm:ss')).toDate())
                              .local()
                              .format(DATE_TIME_FORMAT)
                        : '-'
            },
            {
                title: props.t('common.duration'),
                dataIndex: 'duration',
                key: 'duration',
                align: 'center',
                width: 100,
                render: (_, record) =>
                    `${moment
                        .duration(
                            moment(
                                moment
                                    .utc(
                                        moment(
                                            moment.utc(record.end).isAfter(moment.utc()) ? moment.utc() : record.end
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                    )
                                    .toDate()
                            )
                                .local()
                                .diff(
                                    moment(
                                        moment.utc(moment(record.start).format('YYYY-MM-DD HH:mm:ss')).toDate()
                                    ).local(),
                                    'seconds'
                                ),
                            'seconds'
                        )
                        .format('HH:mm', { trim: false })} ${H}`
            }
            // TODO: enable when JourneyActivity page can handle trailers monitoredObjectId
            // {
            //     title: props.t('common.actions'),
            //     dataIndex: 'actions',
            //     key: 'actions',
            //     width: 50,
            //     render: () => (
            //         <Button type="link" onClick={props.onRowClick}>
            //             {props.t('NavBar.journeysActivity')}
            //         </Button>
            //     )
            // }
        ] as ColumnType<AlarmInDatabaseWithGPSInfo>[];
    }
}

export default withTranslation()(ColdchainAlertTable);
