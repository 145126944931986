/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleDriverStateNew,
    VehicleDriverStateNewFromJSON,
    VehicleDriverStateNewFromJSONTyped,
    VehicleDriverStateNewToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleDriverNew
 */
export interface VehicleDriverNew {
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverNew
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverNew
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverNew
     */
    source?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDriverNew
     */
    time?: Date;
    /**
     * 
     * @type {VehicleDriverStateNew}
     * @memberof VehicleDriverNew
     */
    stateNew?: VehicleDriverStateNew;
    /**
     * 
     * @type {string}
     * @memberof VehicleDriverNew
     */
    driverToken?: string;
}

export function VehicleDriverNewFromJSON(json: any): VehicleDriverNew {
    return VehicleDriverNewFromJSONTyped(json, false);
}

export function VehicleDriverNewFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDriverNew {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'stateNew': !exists(json, 'state_new') ? undefined : VehicleDriverStateNewFromJSON(json['state_new']),
        'driverToken': !exists(json, 'driver_token') ? undefined : json['driver_token'],
    };
}

export function VehicleDriverNewToJSON(value?: VehicleDriverNew | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'source': value.source,
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'state_new': VehicleDriverStateNewToJSON(value.stateNew),
        'driver_token': value.driverToken,
    };
}


