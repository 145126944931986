/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PositionListP44,
    PositionListP44FromJSON,
    PositionListP44FromJSONTyped,
    PositionListP44ToJSON,
} from './';

/**
 * 
 * @export
 * @interface DevicesPositionResponseP44
 */
export interface DevicesPositionResponseP44 {
    /**
     * 
     * @type {Array<PositionListP44>}
     * @memberof DevicesPositionResponseP44
     */
    positionList: Array<PositionListP44>;
}

export function DevicesPositionResponseP44FromJSON(json: any): DevicesPositionResponseP44 {
    return DevicesPositionResponseP44FromJSONTyped(json, false);
}

export function DevicesPositionResponseP44FromJSONTyped(json: any, ignoreDiscriminator: boolean): DevicesPositionResponseP44 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionList': ((json['positionList'] as Array<any>).map(PositionListP44FromJSON)),
    };
}

export function DevicesPositionResponseP44ToJSON(value?: DevicesPositionResponseP44 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positionList': ((value.positionList as Array<any>).map(PositionListP44ToJSON)),
    };
}


