/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Tag assign input
 * @export
 * @interface TagAssignModel
 */
export interface TagAssignModel {
    /**
     * 
     * @type {number}
     * @memberof TagAssignModel
     */
    assetTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignModel
     */
    assetId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignModel
     */
    tagId: number;
}

export function TagAssignModelFromJSON(json: any): TagAssignModel {
    return TagAssignModelFromJSONTyped(json, false);
}

export function TagAssignModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAssignModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetTypeId': json['asset_type_id'],
        'assetId': json['asset_id'],
        'tagId': json['tag_id'],
    };
}

export function TagAssignModelToJSON(value?: TagAssignModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset_type_id': value.assetTypeId,
        'asset_id': value.assetId,
        'tag_id': value.tagId,
    };
}


