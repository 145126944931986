import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { DriverCardsRemoteMemoryModel } from 'logic/management/management-driver-cards-remote-memory';
import DriverCardsRemoteMemoryCcr from './DriverCardsRemoteMemoryBar';
import DriverCardsRemoteMemoryCcrTable from './DriverCardsRemoteMemoryCcrTable';
import { CcrModel, CcrModelInput } from '../DriversCardsRemoteMemoryModule';
import DateGridTable, { DateGridTableModel } from 'common/components/DateGridTable';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { LayoutContent } from 'common/components/Layout/Content';
import Button from 'common/components/Button';
import DtcoCalendar from 'common/components/DtcoCalendar';
import * as CommonIcons from 'resources/images/common';

interface Props {
    search: { text: string };
    ccr: {
        open: boolean;
        edit: boolean;
        loading: boolean;
        data: CcrModel[];
    };
    table?: {
        data: DriverCardsRemoteMemoryModel[];
        loadingDemandIds: string[];
        loading: boolean;
        onDayDownloadClick?: (id: string) => void;
        onRemoteDownloadClick?: (id: string) => void;
        onMonthlyDownloadClick?: (id: string) => void;
    };
    helper?: {
        content: string;
    };
    startDate: string;
    demoMode?: boolean;
    onSearchChange?: (text: string) => void;
    onBarSearch?: () => void;
    onBarCcrClick?: () => void;
    onBarHelperClick?: () => void;
    onBarPreviousChange?: () => void;
    onBarNextChange?: () => void;
    onBarDatePickerChange?: (date: Moment) => void;
    onBarDownloadAllClick?: () => void;
    onCcrTableAdd?: (input: CcrModelInput) => void;
    onCcrTableRemove?: (id: string) => void;
    onHelperClose?: () => void;
}

export function DriverCardsRemoteMemory(props: Props) {
    const data: DateGridTableModel[] = props.table
        ? props.table.data.map(row => ({
              id: row.id,
              firstColValue: row.name + ' ' + row.surname,
              secondColValue: row.fuelCardId,
              downloads: row.cards
                  .filter(card => !(card.demand && card.downloadedFile))
                  .map(card => ({
                      id: card.id,
                      fileName: card.downloadedFile,
                      date: moment(card.date).startOf('day').toString(),
                      processing:
                          card.demand && !!card.downloadRequested && !card.downloadedFile && !card.downloadStarted
                  }))
                  .concat(
                      row.cardsDemanded.map(card => ({
                          id: '',
                          fileName: card.downloadedFile,
                          date: moment(card.downloadStarted).startOf('day').toString(),
                          processing: true
                      }))
                  )
          }))
        : [];

    const { t } = useTranslation();

    return (
        <>
            <LayoutContent
                className="drivers-remote-memorys page no-padding"
                mainSizes={{ xs: 24, sm: 24, md: props.ccr.open ? 18 : 24 }}
                extraSizes={[{ xs: 24, sm: 24, md: props.ccr.open ? 6 : 0 }]}
                main={
                    <>
                        <TableBar
                            heading={t('DriverCardsRemoteMemoryBar.cardsMemory')}
                            search={props.search}
                            datePicker={
                                <DtcoCalendar
                                    startDate={props.startDate}
                                    onNextChange={props.onBarNextChange}
                                    onPreviousChange={props.onBarPreviousChange}
                                    onDatePickerChange={props.onBarDatePickerChange}
                                />
                            }
                            actions={[
                                <DriverCardsRemoteMemoryCcr
                                    editCcr={props.ccr.edit}
                                    data={props.ccr.data}
                                    onCcrClick={props.onBarCcrClick}
                                />,
                                <Button
                                    type="dashed"
                                    icon={
                                        <img
                                            src={CommonIcons.downloadFolder}
                                            alt={t('RemoteDownload.dowloadAllDrivers')}
                                        />
                                    }
                                    onClick={props.onBarDownloadAllClick}
                                    disabled={
                                        props.demoMode || !props.table?.data.filter(row => row.downloadAvailable).length
                                    }
                                >
                                    {t('RemoteDownload.dowloadAllDrivers')}
                                </Button>
                            ]}
                            onSearchChange={props.onSearchChange}
                            onHelperClick={props.onBarHelperClick}
                        />
                        {props.table && (
                            <div className="tm-drivers-remote-memory-table">
                                <DateGridTable
                                    data={data}
                                    startDate={props.startDate}
                                    loading={props.table.loading}
                                    loadingDemandIds={props.table.loadingDemandIds}
                                    demoMode={props.demoMode}
                                    widthFirstCol={190}
                                    onDayDownloadClick={props.table?.onDayDownloadClick}
                                    onRemoteDownloadClick={props.table?.onRemoteDownloadClick}
                                    onMonthlyDownloadClick={props.table?.onMonthlyDownloadClick}
                                />
                            </div>
                        )}
                    </>
                }
                extra={[
                    props.ccr.open ? (
                        <LayoutSidePanel
                            header={t('DriverCardsRemoteMemoryCcrTable.companyCardReader')}
                            body={
                                <DriverCardsRemoteMemoryCcrTable
                                    data={props.ccr.data}
                                    onAdd={props.onCcrTableAdd}
                                    onRemove={props.onCcrTableRemove}
                                />
                            }
                        />
                    ) : null
                ]}
            />

            <HelperModal
                name="remote-memory"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </>
    );
}
