import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { Logic } from 'logic/logic';
import { useState } from 'react';

export const useOptions = () => {
    const [options, setOptions] = useState<{ key: number; value: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getOptions = (logic: Logic, search?: string) => {
        setLoading(true);
        logic
            .users()
            .getUsers(DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, search)
            .then(res => {
                setOptions(
                    res.data
                        .filter(
                            user =>
                                !logic
                                    .customPlacesLogic()
                                    .selectedPlacesOfWorkUserExtended.map(u => u.userId)
                                    .includes(user.id ?? -1)
                        )
                        .map(user => ({
                            key: user.id ?? -1,
                            value: `${user.name} ${user.surname}`
                        }))
                );
                setLoading(false);
            });
    };

    return { loading, getOptions, options };
};
