/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyClientTachographCardReader
 */
export interface WriteOnlyClientTachographCardReader {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClientTachographCardReader
     */
    cardReaderSn: string;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyClientTachographCardReader
     */
    activeTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyClientTachographCardReader
     */
    client?: number | null;
}

export function WriteOnlyClientTachographCardReaderFromJSON(json: any): WriteOnlyClientTachographCardReader {
    return WriteOnlyClientTachographCardReaderFromJSONTyped(json, false);
}

export function WriteOnlyClientTachographCardReaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyClientTachographCardReader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardReaderSn': json['card_reader_sn'],
        'activeTo': !exists(json, 'active_to') ? undefined : (json['active_to'] === null ? null : new Date(json['active_to'])),
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function WriteOnlyClientTachographCardReaderToJSON(value?: WriteOnlyClientTachographCardReader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_reader_sn': value.cardReaderSn,
        'active_to': value.activeTo === undefined ? undefined : (value.activeTo === null ? null : value.activeTo.toISOString()),
        'client': value.client,
    };
}


