/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaceOfWork
 */
export interface PlaceOfWork {
    /**
     * 
     * @type {number}
     * @memberof PlaceOfWork
     */
    userId: number;
    /**
     * 
     * @type {Date}
     * @memberof PlaceOfWork
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlaceOfWork
     */
    end?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlaceOfWork
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlaceOfWork
     */
    createdAt?: Date;
}

export function PlaceOfWorkFromJSON(json: any): PlaceOfWork {
    return PlaceOfWorkFromJSONTyped(json, false);
}

export function PlaceOfWorkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceOfWork {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function PlaceOfWorkToJSON(value?: PlaceOfWork | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


