import { Row } from 'antd';
import cn from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

import { PaginationParams, PaginatedResponse } from 'common/model/pagination';
import { ReadOnlyMonitoredObjectType } from 'generated/new-main';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';
import { Pagination, Table, Tag } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { Tooltip } from 'common/components';
import * as iconModule from 'resources/images/modules';
import { ModuleToKey } from 'modules/management/modules/fleet/FleetModule';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    loading: boolean;
    data?: PaginatedResponse<PartnerVehicleModel[]>;
    selected?: PartnerVehicleModel;
    vehicleTypes?: ReadOnlyMonitoredObjectType[];
    onTableRowSelect?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function VehiclesTable(props: Props) {
    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    const { t, data, loading, selected } = props;

    return (
        <div className="partner-vehicles-table partner-main-table">
            <Table<PartnerVehicleModel>
                loading={loading}
                dataSource={data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id.toString()),
                    className: cn({
                        'ant-table-row-selected': selected?.id === record.id
                    })
                })}
            />

            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );

    function moduleTag(module: string) {
        // cut TLM from module
        const moduleNumber: number = Number(module.replace('TLM', ''));
        return (
            <Tooltip
                key={moduleNumber}
                title={`${t('common.modul')} ${moduleNumber} - ${t(
                    `UserRights.modules.${ModuleToKey[`module${moduleNumber}`]}.name`
                )}`}
                placement="top"
                mouseEnterDelay={0}
                overlayClassName="management-fleet-module-tooltip"
            >
                <Tag>
                    <img
                        className="icon-module"
                        src={iconModule[`icon_module_${moduleNumber}`]}
                        alt={`${t('common.modul')} ${moduleNumber}`}
                    />
                </Tag>
            </Tooltip>
        );
    }

    function createColumns() {
        return [
            {
                title: t('PartnerVehicles.licensePlate'),
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                width: 60,
                render: (licensePlate, record) => getRegistrationNumber(!!record.disabledAt, licensePlate)
            },
            {
                title: t('Partner.brand'),
                dataIndex: 'brand',
                key: 'brand',
                width: 60,
                render: brand => brand
            },
            {
                title: t('common.type'),
                dataIndex: 'type',
                key: 'type',
                width: 60,
                render: type => t(`Partner.${props.vehicleTypes?.find(e => e.id === type.id)?.name}`)
            },
            {
                title: t('Partner.company'),
                dataIndex: 'companyName',
                key: 'companyName',
                width: 60,
                render: companyName => companyName
            },
            {
                title: t('Partner.modules'),
                dataIndex: 'modules',
                key: 'modules',
                width: 200,
                render: modules => (
                    <span className="management-fleet-table-services-tags management-fleet-module">
                        {modules.sort()?.map((module: string) => moduleTag(module))}
                    </span>
                )
            }
        ] as ColumnType<PartnerVehicleModel>[];
    }
}

export default withTranslation()(VehiclesTable);
