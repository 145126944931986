/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageType,
    MessageTypeFromJSON,
    MessageTypeFromJSONTyped,
    MessageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface WAGTPRequestHeader
 */
export interface WAGTPRequestHeader {
    /**
     * 
     * @type {string}
     * @memberof WAGTPRequestHeader
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof WAGTPRequestHeader
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof WAGTPRequestHeader
     */
    correlationId?: string;
    /**
     * 
     * @type {MessageType}
     * @memberof WAGTPRequestHeader
     */
    itemName: MessageType;
    /**
     * 
     * @type {string}
     * @memberof WAGTPRequestHeader
     */
    component?: string;
}

export function WAGTPRequestHeaderFromJSON(json: any): WAGTPRequestHeader {
    return WAGTPRequestHeaderFromJSONTyped(json, false);
}

export function WAGTPRequestHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPRequestHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': json['messageId'],
        'timestamp': json['timestamp'],
        'correlationId': !exists(json, 'correlationId') ? undefined : json['correlationId'],
        'itemName': MessageTypeFromJSON(json['itemName']),
        'component': !exists(json, 'component') ? undefined : json['component'],
    };
}

export function WAGTPRequestHeaderToJSON(value?: WAGTPRequestHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageId': value.messageId,
        'timestamp': value.timestamp,
        'correlationId': value.correlationId,
        'itemName': MessageTypeToJSON(value.itemName),
        'component': value.component,
    };
}


