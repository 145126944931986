/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyProfileSerializer,
    ReadOnlyProfileSerializerFromJSON,
    ReadOnlyProfileSerializerToJSON,
    ReadOnlyTemperatureSensorSerializer,
    ReadOnlyTemperatureSensorSerializerFromJSON,
    ReadOnlyTemperatureSensorSerializerToJSON,
    WriteOnlyProfileSerializer,
    WriteOnlyProfileSerializerFromJSON,
    WriteOnlyProfileSerializerToJSON,
    WriteOnlyTemperatureSensorSerializer,
    WriteOnlyTemperatureSensorSerializerFromJSON,
    WriteOnlyTemperatureSensorSerializerToJSON,
} from '../models';

export interface ColdChainProfileCreateRequest {
    data: WriteOnlyProfileSerializer;
}

export interface ColdChainProfileDeleteRequest {
    id: string;
}

export interface ColdChainProfileListRequest {
    idIn?: string;
    client?: string;
}

export interface ColdChainProfilePartialUpdateRequest {
    id: string;
    data: WriteOnlyProfileSerializer;
}

export interface ColdChainProfileReadRequest {
    id: string;
}

export interface ColdChainProfileUpdateRequest {
    id: string;
    data: WriteOnlyProfileSerializer;
}

export interface ColdChainTemperatureSensorCreateRequest {
    data: WriteOnlyTemperatureSensorSerializer;
}

export interface ColdChainTemperatureSensorDeleteRequest {
    id: string;
}

export interface ColdChainTemperatureSensorListRequest {
    monitoredObjectIn?: string;
    textSearch?: string;
}

export interface ColdChainTemperatureSensorPartialUpdateRequest {
    id: string;
    data: WriteOnlyTemperatureSensorSerializer;
}

export interface ColdChainTemperatureSensorReadRequest {
    id: string;
}

export interface ColdChainTemperatureSensorUpdateRequest {
    id: string;
    data: WriteOnlyTemperatureSensorSerializer;
}

/**
 * no description
 */
export class ColdChainApi extends runtime.BaseAPI {

    /**
     */
    async coldChainProfileCreateRaw(requestParameters: ColdChainProfileCreateRequest): Promise<runtime.ApiResponse<ReadOnlyProfileSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainProfileCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainProfileCreate(requestParameters: ColdChainProfileCreateRequest): Promise<ReadOnlyProfileSerializer> {
        const response = await this.coldChainProfileCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainProfileDeleteRaw(requestParameters: ColdChainProfileDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainProfileDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async coldChainProfileDelete(requestParameters: ColdChainProfileDeleteRequest): Promise<void> {
        await this.coldChainProfileDeleteRaw(requestParameters);
    }

    /**
     */
    async coldChainProfileListRaw(requestParameters: ColdChainProfileListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyProfileSerializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.idIn !== undefined) {
            queryParameters['id__in'] = requestParameters.idIn;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyProfileSerializerFromJSON));
    }

    /**
     */
    async coldChainProfileList(requestParameters: ColdChainProfileListRequest): Promise<Array<ReadOnlyProfileSerializer>> {
        const response = await this.coldChainProfileListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainProfilePartialUpdateRaw(requestParameters: ColdChainProfilePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainProfilePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainProfilePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainProfilePartialUpdate(requestParameters: ColdChainProfilePartialUpdateRequest): Promise<ReadOnlyProfileSerializer> {
        const response = await this.coldChainProfilePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainProfileReadRaw(requestParameters: ColdChainProfileReadRequest): Promise<runtime.ApiResponse<ReadOnlyProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainProfileRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainProfileRead(requestParameters: ColdChainProfileReadRequest): Promise<ReadOnlyProfileSerializer> {
        const response = await this.coldChainProfileReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainProfileUpdateRaw(requestParameters: ColdChainProfileUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainProfileUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainProfileUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainProfileUpdate(requestParameters: ColdChainProfileUpdateRequest): Promise<ReadOnlyProfileSerializer> {
        const response = await this.coldChainProfileUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorCreateRaw(requestParameters: ColdChainTemperatureSensorCreateRequest): Promise<runtime.ApiResponse<ReadOnlyTemperatureSensorSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainTemperatureSensorCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTemperatureSensorSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTemperatureSensorSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainTemperatureSensorCreate(requestParameters: ColdChainTemperatureSensorCreateRequest): Promise<ReadOnlyTemperatureSensorSerializer> {
        const response = await this.coldChainTemperatureSensorCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorDeleteRaw(requestParameters: ColdChainTemperatureSensorDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyTemperatureSensorSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainTemperatureSensorDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTemperatureSensorSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainTemperatureSensorDelete(requestParameters: ColdChainTemperatureSensorDeleteRequest): Promise<ReadOnlyTemperatureSensorSerializer> {
        const response = await this.coldChainTemperatureSensorDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorListRaw(requestParameters: ColdChainTemperatureSensorListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyTemperatureSensorSerializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectIn !== undefined) {
            queryParameters['monitored_object__in'] = requestParameters.monitoredObjectIn;
        }

        if (requestParameters.textSearch !== undefined) {
            queryParameters['text_search'] = requestParameters.textSearch;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyTemperatureSensorSerializerFromJSON));
    }

    /**
     */
    async coldChainTemperatureSensorList(requestParameters: ColdChainTemperatureSensorListRequest): Promise<Array<ReadOnlyTemperatureSensorSerializer>> {
        const response = await this.coldChainTemperatureSensorListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorPartialUpdateRaw(requestParameters: ColdChainTemperatureSensorPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyTemperatureSensorSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainTemperatureSensorPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainTemperatureSensorPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTemperatureSensorSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTemperatureSensorSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainTemperatureSensorPartialUpdate(requestParameters: ColdChainTemperatureSensorPartialUpdateRequest): Promise<ReadOnlyTemperatureSensorSerializer> {
        const response = await this.coldChainTemperatureSensorPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorReadRaw(requestParameters: ColdChainTemperatureSensorReadRequest): Promise<runtime.ApiResponse<ReadOnlyTemperatureSensorSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainTemperatureSensorRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTemperatureSensorSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainTemperatureSensorRead(requestParameters: ColdChainTemperatureSensorReadRequest): Promise<ReadOnlyTemperatureSensorSerializer> {
        const response = await this.coldChainTemperatureSensorReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async coldChainTemperatureSensorUpdateRaw(requestParameters: ColdChainTemperatureSensorUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyTemperatureSensorSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling coldChainTemperatureSensorUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling coldChainTemperatureSensorUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/cold-chain/temperature-sensor/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyTemperatureSensorSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyTemperatureSensorSerializerFromJSON(jsonValue));
    }

    /**
     */
    async coldChainTemperatureSensorUpdate(requestParameters: ColdChainTemperatureSensorUpdateRequest): Promise<ReadOnlyTemperatureSensorSerializer> {
        const response = await this.coldChainTemperatureSensorUpdateRaw(requestParameters);
        return await response.value();
    }

}
