import PartnerPairs from 'modules/partner/components/PartnerPairs';
import { PartnerPairingKey } from 'common/model/partner-pairing';
import { PairingItemType } from 'logic/partner/partner';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';

interface Props {
    model: PartnerVehicleModel;
    unpairLoading?: boolean;
    onUnpair?: (linked: PairingItemType) => void;
}

export default function VehiclesLinkedItems(props: Props) {
    function onUnpair(id: string, key: PartnerPairingKey) {
        const linked: PairingItemType = {
            id,
            key
        };
        props.onUnpair?.(linked);
    }

    return (
        <div className="partner-pairing-list">
            {props.model?.links && (
                <PartnerPairs unpairLoading={props.unpairLoading} links={props.model?.links} onUnpair={onUnpair} />
            )}
        </div>
    );
}
