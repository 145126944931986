import { useState } from 'react';
import { CheckboxGroup, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import TableFilter from 'common/components/TableFilter';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    vehicleOpts: CheckboxGroupOpts;
    vehiclesChecked: string[];
    trailerOpts: CheckboxGroupOpts;
    trailersChecked: string[];
    profileOpts: CheckboxGroupOpts;
    profilesChecked: string[];
    showDeleted: boolean;
    onConfirm?: (
        vehiclesChecked: string[],
        trailersChecked: string[],
        profilesChecked: string[],
        showDeleted: boolean
    ) => void;
    onCancel?: () => void;
}

function ColdchainFilter(props: Props) {
    const { t } = props;

    const [vehiclesChecked, setVehiclesChecked] = useState<string[]>(props.vehiclesChecked);
    function onCheckboxVehiclesChange(vehiclesChecked: string[]) {
        setVehiclesChecked(vehiclesChecked);
    }

    const [trailersChecked, setTrailersChecked] = useState<string[]>(props.trailersChecked);
    function onCheckboxTrailersChange(trailersChecked: string[]) {
        setTrailersChecked(trailersChecked);
    }

    const [profilesChecked, setProfilesChecked] = useState<string[]>(props.profilesChecked);
    function onCheckboxProfilesChange(profilesChecked: string[]) {
        setProfilesChecked(profilesChecked);
    }

    const [showDeleted, _setShowDeleted] = useState<boolean>(props.showDeleted);
    function _onShowDeletedChange(checked: boolean) {
        _setShowDeleted(checked);
    }

    function onConfirmClick(): void {
        props.onConfirm?.(vehiclesChecked, trailersChecked, profilesChecked, showDeleted);
    }

    function onResetClick(): void {
        const defaults = confDefault.settings.statistics.coldChain.filter;
        setVehiclesChecked(defaults.vehicles);
        setTrailersChecked(defaults.trailers);
        setProfilesChecked(defaults.profiles);
        _setShowDeleted(false);
    }

    return (
        <TableFilter
            className="coldchain-filter"
            qa={qa.coldChain.filter.section}
            heading={t('Coldchain.filterName')}
            body={
                <Row gutter={[24, 24]}>
                    {props.vehicleOpts.length > 0 && (
                        <Col>
                            <CheckboxGroup
                                heading={t('common.vehicles')}
                                scrolled={true}
                                labelCheckAll={t('common.allVehicles')}
                                qa={qa.coldChain.filter.sectionVehicles}
                                options={props.vehicleOpts.map(v => ({
                                    code: v.code,
                                    label: v.label,
                                    checked: vehiclesChecked.includes(v.code),
                                    qa: qa.coldChain.filter.checkboxTrailer
                                }))}
                                onChange={onCheckboxVehiclesChange}
                            />
                        </Col>
                    )}
                    {props.trailerOpts.length > 0 && (
                        <Col>
                            <CheckboxGroup
                                heading={t('common.trailers')}
                                scrolled={true}
                                labelCheckAll={t('common.allTrailers')}
                                qa={qa.coldChain.filter.sectionTrailers}
                                options={props.trailerOpts.map(v => ({
                                    code: v.code,
                                    label: v.label,
                                    checked: trailersChecked.includes(v.code),
                                    qa: qa.coldChain.filter.checkboxTrailer
                                }))}
                                onChange={onCheckboxTrailersChange}
                            />
                        </Col>
                    )}
                    {props.profileOpts.length > 0 && (
                        <Col>
                            <CheckboxGroup
                                heading={t('PlannerColdchain.title')}
                                scrolled={true}
                                labelCheckAll={t('common.all')}
                                qa={qa.coldChain.filter.sectionProfiles}
                                options={props.profileOpts.map(v => ({
                                    code: v.code,
                                    label: v.label,
                                    checked: profilesChecked.includes(v.code),
                                    qa: qa.coldChain.filter.checkboxProfile
                                }))}
                                onChange={onCheckboxProfilesChange}
                            />
                        </Col>
                    )}
                    {/* <Col>
                        <Row className="coldchain-filter-deleted" justify="end" align="middle" gutter={12}>
                            <Col>
                                <Switch size="small" onChange={onShowDeletedChange} checked={showDeleted} />
                            </Col>
                            <Col className="coldchain-filter-switch-label">{t('Coldchain.filterDeleted')}</Col>
                        </Row>
                    </Col> */}
                </Row>
            }
            cancelButton={{
                onClick: props.onCancel,
                qa: qa.coldChain.filter.btnCancel
            }}
            submitButton={{
                onClick: onConfirmClick,
                qa: qa.coldChain.filter.btnSubmit,
                disabled: props.demoMode
            }}
            resetButton={{
                onClick: onResetClick
            }}
        />
    );
}

export default withTranslation()(ColdchainFilter);
