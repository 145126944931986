import { ReadOnlyCountryFromJSON } from 'generated/new-main';

export const countries = [
    { id: 13, name: 'Aruba', iso2: 'AW', iso3: 'ABW', time_zone: 'America/Aruba', currency: 'AWG', vat_rate: 0.0 },
    { id: 1, name: 'Afghanistan', iso2: 'AF', iso3: 'AFG', time_zone: 'Asia/Kabul', currency: '', vat_rate: 0.0 },
    { id: 7, name: 'Angola', iso2: 'AO', iso3: 'AGO', time_zone: 'Africa/Luanda', currency: 'AOA', vat_rate: 0.0 },
    {
        id: 8,
        name: 'Anguilla',
        iso2: 'AI',
        iso3: 'AIA',
        time_zone: 'America/Anguilla',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 2,
        name: 'Åland Islands',
        iso2: 'AX',
        iso3: 'ALA',
        time_zone: 'Europe/Mariehamn',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 3,
        name: 'Albania',
        iso2: 'AL',
        iso3: 'ALB',
        time_zone: 'Europe/Tirane',
        currency: 'ALL',
        vat_rate: 20.0
    },
    {
        id: 6,
        name: 'Andorra',
        iso2: 'AD',
        iso3: 'AND',
        time_zone: 'Europe/Andorra',
        currency: 'EUR',
        vat_rate: 4.5
    },
    {
        id: 234,
        name: 'United Arab Emirates (the)',
        iso2: 'AE',
        iso3: 'ARE',
        time_zone: 'Asia/Dubai',
        currency: 'AED',
        vat_rate: 0.0
    },
    {
        id: 11,
        name: 'Argentina',
        iso2: 'AR',
        iso3: 'ARG',
        time_zone: 'America/Argentina/Buenos_Aires',
        currency: 'ARS',
        vat_rate: 0.0
    },
    { id: 12, name: 'Armenia', iso2: 'AM', iso3: 'ARM', time_zone: 'Asia/Yerevan', currency: 'AMD', vat_rate: 0.0 },
    {
        id: 5,
        name: 'American Samoa',
        iso2: 'AS',
        iso3: 'ASM',
        time_zone: 'Pacific/Pago_Pago',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 9,
        name: 'Antarctica',
        iso2: 'AQ',
        iso3: 'ATA',
        time_zone: 'Antarctica/Casey',
        currency: '',
        vat_rate: 0.0
    },
    {
        id: 75,
        name: 'French Southern Territories (the)',
        iso2: 'TF',
        iso3: 'ATF',
        time_zone: 'Indian/Kerguelen',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 10,
        name: 'Antigua and Barbuda',
        iso2: 'AG',
        iso3: 'ATG',
        time_zone: 'America/Antigua',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 14,
        name: 'Australia',
        iso2: 'AU',
        iso3: 'AUS',
        time_zone: 'Antarctica/Macquarie',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 15,
        name: 'Austria',
        iso2: 'AT',
        iso3: 'AUT',
        time_zone: 'Europe/Vienna',
        currency: 'EUR',
        vat_rate: 20.0
    },
    { id: 16, name: 'Azerbaijan', iso2: 'AZ', iso3: 'AZE', time_zone: 'Asia/Baku', currency: 'AZN', vat_rate: 0.0 },
    {
        id: 37,
        name: 'Burundi',
        iso2: 'BI',
        iso3: 'BDI',
        time_zone: 'Africa/Bujumbura',
        currency: 'BIF',
        vat_rate: 0.0
    },
    {
        id: 22,
        name: 'Belgium',
        iso2: 'BE',
        iso3: 'BEL',
        time_zone: 'Europe/Brussels',
        currency: 'EUR',
        vat_rate: 21.0
    },
    {
        id: 24,
        name: 'Benin',
        iso2: 'BJ',
        iso3: 'BEN',
        time_zone: 'Africa/Porto-Novo',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 28,
        name: 'Bonaire, Sint Eustatius and Saba',
        iso2: 'BQ',
        iso3: 'BES',
        time_zone: 'America/Kralendijk',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 36,
        name: 'Burkina Faso',
        iso2: 'BF',
        iso3: 'BFA',
        time_zone: 'Africa/Ouagadougou',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 19,
        name: 'Bangladesh',
        iso2: 'BD',
        iso3: 'BGD',
        time_zone: 'Asia/Dhaka',
        currency: 'BDT',
        vat_rate: 0.0
    },
    {
        id: 35,
        name: 'Bulgaria',
        iso2: 'BG',
        iso3: 'BGR',
        time_zone: 'Europe/Sofia',
        currency: 'BGN',
        vat_rate: 20.0
    },
    { id: 18, name: 'Bahrain', iso2: 'BH', iso3: 'BHR', time_zone: 'Asia/Bahrain', currency: 'BHD', vat_rate: 0.0 },
    {
        id: 17,
        name: 'Bahamas (the)',
        iso2: 'BS',
        iso3: 'BHS',
        time_zone: 'America/Nassau',
        currency: 'BSD',
        vat_rate: 0.0
    },
    {
        id: 29,
        name: 'Bosnia and Herzegovina',
        iso2: 'BA',
        iso3: 'BIH',
        time_zone: 'Europe/Sarajevo',
        currency: 'BAM',
        vat_rate: 17.0
    },
    {
        id: 185,
        name: 'Saint Barthélemy',
        iso2: 'BL',
        iso3: 'BLM',
        time_zone: 'America/St_Barthelemy',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 21,
        name: 'Belarus',
        iso2: 'BY',
        iso3: 'BLR',
        time_zone: 'Europe/Minsk',
        currency: 'BYN',
        vat_rate: 20.0
    },
    {
        id: 23,
        name: 'Belize',
        iso2: 'BZ',
        iso3: 'BLZ',
        time_zone: 'America/Belize',
        currency: 'BZD',
        vat_rate: 0.0
    },
    {
        id: 25,
        name: 'Bermuda',
        iso2: 'BM',
        iso3: 'BMU',
        time_zone: 'Atlantic/Bermuda',
        currency: 'BMD',
        vat_rate: 0.0
    },
    {
        id: 27,
        name: 'Bolivia (Plurinational State of)',
        iso2: 'BO',
        iso3: 'BOL',
        time_zone: 'America/La_Paz',
        currency: 'BOB',
        vat_rate: 0.0
    },
    {
        id: 32,
        name: 'Brazil',
        iso2: 'BR',
        iso3: 'BRA',
        time_zone: 'America/Araguaina',
        currency: 'BRL',
        vat_rate: 0.0
    },
    {
        id: 20,
        name: 'Barbados',
        iso2: 'BB',
        iso3: 'BRB',
        time_zone: 'America/Barbados',
        currency: 'BBD',
        vat_rate: 0.0
    },
    {
        id: 34,
        name: 'Brunei Darussalam',
        iso2: 'BN',
        iso3: 'BRN',
        time_zone: 'Asia/Brunei',
        currency: 'BND',
        vat_rate: 0.0
    },
    { id: 26, name: 'Bhutan', iso2: 'BT', iso3: 'BTN', time_zone: 'Asia/Thimphu', currency: 'BTN', vat_rate: 0.0 },
    { id: 31, name: 'Bouvet Island', iso2: 'BV', iso3: 'BVT', time_zone: '', currency: 'NOK', vat_rate: 0.0 },
    {
        id: 30,
        name: 'Botswana',
        iso2: 'BW',
        iso3: 'BWA',
        time_zone: 'Africa/Gaborone',
        currency: 'BWP',
        vat_rate: 0.0
    },
    {
        id: 43,
        name: 'Central African Republic (the)',
        iso2: 'CF',
        iso3: 'CAF',
        time_zone: 'Africa/Bangui',
        currency: 'XAF',
        vat_rate: 0.0
    },
    {
        id: 41,
        name: 'Canada',
        iso2: 'CA',
        iso3: 'CAN',
        time_zone: 'America/Atikokan',
        currency: 'CAD',
        vat_rate: 0.0
    },
    {
        id: 44,
        name: 'Cocos (Keeling) Islands (the)',
        iso2: 'CC',
        iso3: 'CCK',
        time_zone: 'Indian/Cocos',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 216,
        name: 'Switzerland',
        iso2: 'CH',
        iso3: 'CHE',
        time_zone: 'Europe/Zurich',
        currency: 'CHF',
        vat_rate: 7.7
    },
    {
        id: 99,
        name: 'Chile',
        iso2: 'CL',
        iso3: 'CHL',
        time_zone: 'America/Punta_Arenas',
        currency: 'CLP',
        vat_rate: 0.0
    },
    { id: 100, name: 'China', iso2: 'CN', iso3: 'CHN', time_zone: 'Asia/Shanghai', currency: 'CNY', vat_rate: 0.0 },
    {
        id: 51,
        name: "Côte d'Ivoire",
        iso2: 'CI',
        iso3: 'CIV',
        time_zone: 'Africa/Abidjan',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 40,
        name: 'Cameroon',
        iso2: 'CM',
        iso3: 'CMR',
        time_zone: 'Africa/Douala',
        currency: 'XAF',
        vat_rate: 0.0
    },
    {
        id: 47,
        name: 'Congo (the Democratic Republic of the)',
        iso2: 'CD',
        iso3: 'COD',
        time_zone: 'Africa/Kinshasa',
        currency: 'CDF',
        vat_rate: 0.0
    },
    {
        id: 48,
        name: 'Congo (the)',
        iso2: 'CG',
        iso3: 'COG',
        time_zone: 'Africa/Brazzaville',
        currency: 'XAF',
        vat_rate: 0.0
    },
    {
        id: 49,
        name: 'Cook Islands (the)',
        iso2: 'CK',
        iso3: 'COK',
        time_zone: 'Pacific/Rarotonga',
        currency: 'NZD',
        vat_rate: 0.0
    },
    {
        id: 45,
        name: 'Colombia',
        iso2: 'CO',
        iso3: 'COL',
        time_zone: 'America/Bogota',
        currency: 'COP',
        vat_rate: 0.0
    },
    {
        id: 46,
        name: 'Comoros (the)',
        iso2: 'KM',
        iso3: 'COM',
        time_zone: 'Indian/Comoro',
        currency: 'KMF',
        vat_rate: 0.0
    },
    {
        id: 38,
        name: 'Cabo Verde',
        iso2: 'CV',
        iso3: 'CPV',
        time_zone: 'Atlantic/Cape_Verde',
        currency: 'CVE',
        vat_rate: 0.0
    },
    {
        id: 50,
        name: 'Costa Rica',
        iso2: 'CR',
        iso3: 'CRI',
        time_zone: 'America/Costa_Rica',
        currency: 'CRC',
        vat_rate: 0.0
    },
    { id: 53, name: 'Cuba', iso2: 'CU', iso3: 'CUB', time_zone: 'America/Havana', currency: 'CUC', vat_rate: 0.0 },
    {
        id: 54,
        name: 'Curaçao',
        iso2: 'CW',
        iso3: 'CUW',
        time_zone: 'America/Curacao',
        currency: 'ANG',
        vat_rate: 0.0
    },
    {
        id: 101,
        name: 'Christmas Island',
        iso2: 'CX',
        iso3: 'CXR',
        time_zone: 'Indian/Christmas',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 42,
        name: 'Cayman Islands (the)',
        iso2: 'KY',
        iso3: 'CYM',
        time_zone: 'America/Cayman',
        currency: 'KYD',
        vat_rate: 0.0
    },
    {
        id: 55,
        name: 'Cyprus',
        iso2: 'CY',
        iso3: 'CYP',
        time_zone: 'Asia/Famagusta',
        currency: 'EUR',
        vat_rate: 19.0
    },
    {
        id: 56,
        name: 'Czech Republic (the)',
        iso2: 'CZ',
        iso3: 'CZE',
        time_zone: 'Europe/Prague',
        currency: 'CZK',
        vat_rate: 21.0
    },
    {
        id: 79,
        name: 'Germany',
        iso2: 'DE',
        iso3: 'DEU',
        time_zone: 'Europe/Berlin',
        currency: 'EUR',
        vat_rate: 19.0
    },
    {
        id: 58,
        name: 'Djibouti',
        iso2: 'DJ',
        iso3: 'DJI',
        time_zone: 'Africa/Djibouti',
        currency: 'DJF',
        vat_rate: 0.0
    },
    {
        id: 59,
        name: 'Dominica',
        iso2: 'DM',
        iso3: 'DMA',
        time_zone: 'America/Dominica',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 57,
        name: 'Denmark',
        iso2: 'DK',
        iso3: 'DNK',
        time_zone: 'Europe/Copenhagen',
        currency: 'DKK',
        vat_rate: 25.0
    },
    {
        id: 60,
        name: 'Dominican Republic (the)',
        iso2: 'DO',
        iso3: 'DOM',
        time_zone: 'America/Santo_Domingo',
        currency: 'DOP',
        vat_rate: 0.0
    },
    {
        id: 4,
        name: 'Algeria',
        iso2: 'DZ',
        iso3: 'DZA',
        time_zone: 'Africa/Algiers',
        currency: 'DZD',
        vat_rate: 0.0
    },
    {
        id: 61,
        name: 'Ecuador',
        iso2: 'EC',
        iso3: 'ECU',
        time_zone: 'America/Guayaquil',
        currency: 'USD',
        vat_rate: 0.0
    },
    { id: 62, name: 'Egypt', iso2: 'EG', iso3: 'EGY', time_zone: 'Africa/Cairo', currency: 'EGP', vat_rate: 0.0 },
    {
        id: 65,
        name: 'Eritrea',
        iso2: 'ER',
        iso3: 'ERI',
        time_zone: 'Africa/Asmara',
        currency: 'ERN',
        vat_rate: 0.0
    },
    {
        id: 246,
        name: 'Western Sahara*',
        iso2: 'EH',
        iso3: 'ESH',
        time_zone: 'Africa/El_Aaiun',
        currency: 'MAD',
        vat_rate: 0.0
    },
    { id: 209, name: 'Spain', iso2: 'ES', iso3: 'ESP', time_zone: 'Africa/Ceuta', currency: 'EUR', vat_rate: 21.0 },
    {
        id: 66,
        name: 'Estonia',
        iso2: 'EE',
        iso3: 'EST',
        time_zone: 'Europe/Tallinn',
        currency: 'EUR',
        vat_rate: 20.0
    },
    {
        id: 67,
        name: 'Ethiopia',
        iso2: 'ET',
        iso3: 'ETH',
        time_zone: 'Africa/Addis_Ababa',
        currency: 'ETB',
        vat_rate: 0.0
    },
    {
        id: 71,
        name: 'Finland',
        iso2: 'FI',
        iso3: 'FIN',
        time_zone: 'Europe/Helsinki',
        currency: 'EUR',
        vat_rate: 24.0
    },
    { id: 70, name: 'Fiji', iso2: 'FJ', iso3: 'FJI', time_zone: 'Pacific/Fiji', currency: 'FJD', vat_rate: 0.0 },
    {
        id: 68,
        name: 'Falkland Islands (the) [Malvinas]',
        iso2: 'FK',
        iso3: 'FLK',
        time_zone: 'Atlantic/Stanley',
        currency: '',
        vat_rate: 0.0
    },
    { id: 72, name: 'France', iso2: 'FR', iso3: 'FRA', time_zone: 'Europe/Paris', currency: 'EUR', vat_rate: 20.0 },
    {
        id: 69,
        name: 'Faroe Islands (the)',
        iso2: 'FO',
        iso3: 'FRO',
        time_zone: 'Atlantic/Faroe',
        currency: 'DKK',
        vat_rate: 0.0
    },
    {
        id: 145,
        name: 'Micronesia (Federated States of)',
        iso2: 'FM',
        iso3: 'FSM',
        time_zone: 'Pacific/Chuuk',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 76,
        name: 'Gabon',
        iso2: 'GA',
        iso3: 'GAB',
        time_zone: 'Africa/Libreville',
        currency: 'XAF',
        vat_rate: 0.0
    },
    {
        id: 235,
        name: 'United Kingdom of Great Britain and Northern Ireland (the)',
        iso2: 'GB',
        iso3: 'GBR',
        time_zone: 'Europe/London',
        currency: 'GBP',
        vat_rate: 20.0
    },
    { id: 78, name: 'Georgia', iso2: 'GE', iso3: 'GEO', time_zone: 'Asia/Tbilisi', currency: 'GEL', vat_rate: 0.0 },
    {
        id: 88,
        name: 'Guernsey',
        iso2: 'GG',
        iso3: 'GGY',
        time_zone: 'Europe/Guernsey',
        currency: 'GBP',
        vat_rate: 0.0
    },
    { id: 80, name: 'Ghana', iso2: 'GH', iso3: 'GHA', time_zone: 'Africa/Accra', currency: 'GHS', vat_rate: 0.0 },
    {
        id: 81,
        name: 'Gibraltar',
        iso2: 'GI',
        iso3: 'GIB',
        time_zone: 'Europe/Gibraltar',
        currency: 'GIP',
        vat_rate: 0.0
    },
    {
        id: 89,
        name: 'Guinea',
        iso2: 'GN',
        iso3: 'GIN',
        time_zone: 'Africa/Conakry',
        currency: 'GNF',
        vat_rate: 0.0
    },
    {
        id: 85,
        name: 'Guadeloupe',
        iso2: 'GP',
        iso3: 'GLP',
        time_zone: 'America/Guadeloupe',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 77,
        name: 'Gambia (the)',
        iso2: 'GM',
        iso3: 'GMB',
        time_zone: 'Africa/Banjul',
        currency: 'GMD',
        vat_rate: 0.0
    },
    {
        id: 90,
        name: 'Guinea-Bissau',
        iso2: 'GW',
        iso3: 'GNB',
        time_zone: 'Africa/Bissau',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 64,
        name: 'Equatorial Guinea',
        iso2: 'GQ',
        iso3: 'GNQ',
        time_zone: 'Africa/Malabo',
        currency: 'XAF',
        vat_rate: 0.0
    },
    {
        id: 82,
        name: 'Greece',
        iso2: 'GR',
        iso3: 'GRC',
        time_zone: 'Europe/Athens',
        currency: 'EUR',
        vat_rate: 24.0
    },
    {
        id: 84,
        name: 'Grenada',
        iso2: 'GD',
        iso3: 'GRD',
        time_zone: 'America/Grenada',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 83,
        name: 'Greenland',
        iso2: 'GL',
        iso3: 'GRL',
        time_zone: 'America/Danmarkshavn',
        currency: 'DKK',
        vat_rate: 0.0
    },
    {
        id: 87,
        name: 'Guatemala',
        iso2: 'GT',
        iso3: 'GTM',
        time_zone: 'America/Guatemala',
        currency: 'GTQ',
        vat_rate: 0.0
    },
    {
        id: 73,
        name: 'French Guiana',
        iso2: 'GF',
        iso3: 'GUF',
        time_zone: 'America/Cayenne',
        currency: 'EUR',
        vat_rate: 0.0
    },
    { id: 86, name: 'Guam', iso2: 'GU', iso3: 'GUM', time_zone: 'Pacific/Guam', currency: 'USD', vat_rate: 0.0 },
    {
        id: 91,
        name: 'Guyana',
        iso2: 'GY',
        iso3: 'GUY',
        time_zone: 'America/Guyana',
        currency: 'GYD',
        vat_rate: 0.0
    },
    {
        id: 96,
        name: 'Hong Kong',
        iso2: 'HK',
        iso3: 'HKG',
        time_zone: 'Asia/Hong_Kong',
        currency: 'HKD',
        vat_rate: 0.0
    },
    {
        id: 93,
        name: 'Heard Island and McDonald Islands',
        iso2: 'HM',
        iso3: 'HMD',
        time_zone: '',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 95,
        name: 'Honduras',
        iso2: 'HN',
        iso3: 'HND',
        time_zone: 'America/Tegucigalpa',
        currency: 'HNL',
        vat_rate: 0.0
    },
    {
        id: 52,
        name: 'Croatia',
        iso2: 'HR',
        iso3: 'HRV',
        time_zone: 'Europe/Zagreb',
        currency: 'HRK',
        vat_rate: 25.0
    },
    {
        id: 92,
        name: 'Haiti',
        iso2: 'HT',
        iso3: 'HTI',
        time_zone: 'America/Port-au-Prince',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 97,
        name: 'Hungary',
        iso2: 'HU',
        iso3: 'HUN',
        time_zone: 'Europe/Budapest',
        currency: 'HUF',
        vat_rate: 27.0
    },
    {
        id: 104,
        name: 'Indonesia',
        iso2: 'ID',
        iso3: 'IDN',
        time_zone: 'Asia/Jakarta',
        currency: 'IDR',
        vat_rate: 0.0
    },
    {
        id: 108,
        name: 'Isle of Man',
        iso2: 'IM',
        iso3: 'IMN',
        time_zone: 'Europe/Isle_of_Man',
        currency: 'GBP',
        vat_rate: 20.0
    },
    { id: 103, name: 'India', iso2: 'IN', iso3: 'IND', time_zone: 'Asia/Kolkata', currency: 'INR', vat_rate: 0.0 },
    {
        id: 33,
        name: 'British Indian Ocean Territory (the)',
        iso2: 'IO',
        iso3: 'IOT',
        time_zone: 'Indian/Chagos',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 107,
        name: 'Ireland',
        iso2: 'IE',
        iso3: 'IRL',
        time_zone: 'Europe/Dublin',
        currency: 'EUR',
        vat_rate: 23.0
    },
    {
        id: 105,
        name: 'Iran (Islamic Republic of)',
        iso2: 'IR',
        iso3: 'IRN',
        time_zone: 'Asia/Tehran',
        currency: 'IRR',
        vat_rate: 0.0
    },
    { id: 106, name: 'Iraq', iso2: 'IQ', iso3: 'IRQ', time_zone: 'Asia/Baghdad', currency: 'IQD', vat_rate: 0.0 },
    {
        id: 102,
        name: 'Iceland',
        iso2: 'IS',
        iso3: 'ISL',
        time_zone: 'Atlantic/Reykjavik',
        currency: 'ISK',
        vat_rate: 0.0
    },
    {
        id: 109,
        name: 'Israel',
        iso2: 'IL',
        iso3: 'ISR',
        time_zone: 'Asia/Jerusalem',
        currency: 'ILS',
        vat_rate: 0.0
    },
    { id: 110, name: 'Italy', iso2: 'IT', iso3: 'ITA', time_zone: 'Europe/Rome', currency: 'EUR', vat_rate: 22.0 },
    {
        id: 111,
        name: 'Jamaica',
        iso2: 'JM',
        iso3: 'JAM',
        time_zone: 'America/Jamaica',
        currency: 'JMD',
        vat_rate: 0.0
    },
    {
        id: 113,
        name: 'Jersey',
        iso2: 'JE',
        iso3: 'JEY',
        time_zone: 'Europe/Jersey',
        currency: 'GBP',
        vat_rate: 0.0
    },
    { id: 114, name: 'Jordan', iso2: 'JO', iso3: 'JOR', time_zone: 'Asia/Amman', currency: 'JOD', vat_rate: 0.0 },
    { id: 112, name: 'Japan', iso2: 'JP', iso3: 'JPN', time_zone: 'Asia/Tokyo', currency: 'JPY', vat_rate: 0.0 },
    {
        id: 115,
        name: 'Kazakhstan',
        iso2: 'KZ',
        iso3: 'KAZ',
        time_zone: 'Asia/Almaty',
        currency: 'KZT',
        vat_rate: 0.0
    },
    {
        id: 116,
        name: 'Kenya',
        iso2: 'KE',
        iso3: 'KEN',
        time_zone: 'Africa/Nairobi',
        currency: 'KES',
        vat_rate: 0.0
    },
    {
        id: 121,
        name: 'Kyrgyzstan',
        iso2: 'KG',
        iso3: 'KGZ',
        time_zone: 'Asia/Bishkek',
        currency: 'KGS',
        vat_rate: 0.0
    },
    {
        id: 39,
        name: 'Cambodia',
        iso2: 'KH',
        iso3: 'KHM',
        time_zone: 'Asia/Phnom_Penh',
        currency: 'KHR',
        vat_rate: 0.0
    },
    {
        id: 117,
        name: 'Kiribati',
        iso2: 'KI',
        iso3: 'KIR',
        time_zone: 'Pacific/Enderbury',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 187,
        name: 'Saint Kitts and Nevis',
        iso2: 'KN',
        iso3: 'KNA',
        time_zone: 'America/St_Kitts',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 119,
        name: 'Korea (the Republic of)',
        iso2: 'KR',
        iso3: 'KOR',
        time_zone: 'Asia/Seoul',
        currency: 'KRW',
        vat_rate: 0.0
    },
    { id: 120, name: 'Kuwait', iso2: 'KW', iso3: 'KWT', time_zone: 'Asia/Kuwait', currency: 'KWD', vat_rate: 0.0 },
    {
        id: 122,
        name: "Lao People's Democratic Republic (the)",
        iso2: 'LA',
        iso3: 'LAO',
        time_zone: 'Asia/Vientiane',
        currency: 'LAK',
        vat_rate: 0.0
    },
    { id: 124, name: 'Lebanon', iso2: 'LB', iso3: 'LBN', time_zone: 'Asia/Beirut', currency: 'LBP', vat_rate: 0.0 },
    {
        id: 126,
        name: 'Liberia',
        iso2: 'LR',
        iso3: 'LBR',
        time_zone: 'Africa/Monrovia',
        currency: 'LRD',
        vat_rate: 0.0
    },
    {
        id: 127,
        name: 'Libya',
        iso2: 'LY',
        iso3: 'LBY',
        time_zone: 'Africa/Tripoli',
        currency: 'LYD',
        vat_rate: 0.0
    },
    {
        id: 188,
        name: 'Saint Lucia',
        iso2: 'LC',
        iso3: 'LCA',
        time_zone: 'America/St_Lucia',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 128,
        name: 'Liechtenstein',
        iso2: 'LI',
        iso3: 'LIE',
        time_zone: 'Europe/Vaduz',
        currency: 'CHF',
        vat_rate: 7.7
    },
    {
        id: 210,
        name: 'Sri Lanka',
        iso2: 'LK',
        iso3: 'LKA',
        time_zone: 'Asia/Colombo',
        currency: 'LKR',
        vat_rate: 0.0
    },
    {
        id: 125,
        name: 'Lesotho',
        iso2: 'LS',
        iso3: 'LSO',
        time_zone: 'Africa/Maseru',
        currency: 'ZAR',
        vat_rate: 0.0
    },
    {
        id: 129,
        name: 'Lithuania',
        iso2: 'LT',
        iso3: 'LTU',
        time_zone: 'Europe/Vilnius',
        currency: 'EUR',
        vat_rate: 21.0
    },
    {
        id: 130,
        name: 'Luxembourg',
        iso2: 'LU',
        iso3: 'LUX',
        time_zone: 'Europe/Luxembourg',
        currency: 'EUR',
        vat_rate: 17.0
    },
    { id: 123, name: 'Latvia', iso2: 'LV', iso3: 'LVA', time_zone: 'Europe/Riga', currency: 'EUR', vat_rate: 21.0 },
    { id: 131, name: 'Macao', iso2: 'MO', iso3: 'MAC', time_zone: 'Asia/Macau', currency: 'MOP', vat_rate: 0.0 },
    {
        id: 189,
        name: 'Saint Martin (French part)',
        iso2: 'MF',
        iso3: 'MAF',
        time_zone: 'America/Marigot',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 151,
        name: 'Morocco',
        iso2: 'MA',
        iso3: 'MAR',
        time_zone: 'Africa/Casablanca',
        currency: 'MAD',
        vat_rate: 0.0
    },
    {
        id: 147,
        name: 'Monaco',
        iso2: 'MC',
        iso3: 'MCO',
        time_zone: 'Europe/Monaco',
        currency: 'EUR',
        vat_rate: 20.0
    },
    {
        id: 146,
        name: 'Moldova (the Republic of)',
        iso2: 'MD',
        iso3: 'MDA',
        time_zone: 'Europe/Chisinau',
        currency: 'MDL',
        vat_rate: 20.0
    },
    {
        id: 133,
        name: 'Madagascar',
        iso2: 'MG',
        iso3: 'MDG',
        time_zone: 'Indian/Antananarivo',
        currency: 'MGA',
        vat_rate: 0.0
    },
    {
        id: 136,
        name: 'Maldives',
        iso2: 'MV',
        iso3: 'MDV',
        time_zone: 'Indian/Maldives',
        currency: 'MVR',
        vat_rate: 0.0
    },
    {
        id: 144,
        name: 'Mexico',
        iso2: 'MX',
        iso3: 'MEX',
        time_zone: 'America/Bahia_Banderas',
        currency: 'MXN',
        vat_rate: 0.0
    },
    {
        id: 139,
        name: 'Marshall Islands (the)',
        iso2: 'MH',
        iso3: 'MHL',
        time_zone: 'Pacific/Kwajalein',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 132,
        name: 'Macedonia (the former Yugoslav Republic of)',
        iso2: 'MK',
        iso3: 'MKD',
        time_zone: 'Europe/Skopje',
        currency: 'MKD',
        vat_rate: 18.0
    },
    { id: 137, name: 'Mali', iso2: 'ML', iso3: 'MLI', time_zone: 'Africa/Bamako', currency: 'XOF', vat_rate: 0.0 },
    { id: 138, name: 'Malta', iso2: 'MT', iso3: 'MLT', time_zone: 'Europe/Malta', currency: 'EUR', vat_rate: 18.0 },
    { id: 153, name: 'Myanmar', iso2: 'MM', iso3: 'MMR', time_zone: 'Asia/Yangon', currency: 'MMK', vat_rate: 0.0 },
    {
        id: 149,
        name: 'Montenegro',
        iso2: 'ME',
        iso3: 'MNE',
        time_zone: 'Europe/Podgorica',
        currency: 'EUR',
        vat_rate: 21.0
    },
    {
        id: 148,
        name: 'Mongolia',
        iso2: 'MN',
        iso3: 'MNG',
        time_zone: 'Asia/Choibalsan',
        currency: 'MNT',
        vat_rate: 0.0
    },
    {
        id: 165,
        name: 'Northern Mariana Islands (the)',
        iso2: 'MP',
        iso3: 'MNP',
        time_zone: 'Pacific/Saipan',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 152,
        name: 'Mozambique',
        iso2: 'MZ',
        iso3: 'MOZ',
        time_zone: 'Africa/Maputo',
        currency: 'MZN',
        vat_rate: 0.0
    },
    {
        id: 141,
        name: 'Mauritania',
        iso2: 'MR',
        iso3: 'MRT',
        time_zone: 'Africa/Nouakchott',
        currency: 'MRO',
        vat_rate: 0.0
    },
    {
        id: 150,
        name: 'Montserrat',
        iso2: 'MS',
        iso3: 'MSR',
        time_zone: 'America/Montserrat',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 140,
        name: 'Martinique',
        iso2: 'MQ',
        iso3: 'MTQ',
        time_zone: 'America/Martinique',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 142,
        name: 'Mauritius',
        iso2: 'MU',
        iso3: 'MUS',
        time_zone: 'Indian/Mauritius',
        currency: 'MUR',
        vat_rate: 0.0
    },
    {
        id: 134,
        name: 'Malawi',
        iso2: 'MW',
        iso3: 'MWI',
        time_zone: 'Africa/Blantyre',
        currency: 'MWK',
        vat_rate: 0.0
    },
    {
        id: 135,
        name: 'Malaysia',
        iso2: 'MY',
        iso3: 'MYS',
        time_zone: 'Asia/Kuala_Lumpur',
        currency: 'MYR',
        vat_rate: 0.0
    },
    {
        id: 143,
        name: 'Mayotte',
        iso2: 'YT',
        iso3: 'MYT',
        time_zone: 'Indian/Mayotte',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 154,
        name: 'Namibia',
        iso2: 'NA',
        iso3: 'NAM',
        time_zone: 'Africa/Windhoek',
        currency: 'NAD',
        vat_rate: 0.0
    },
    {
        id: 158,
        name: 'New Caledonia',
        iso2: 'NC',
        iso3: 'NCL',
        time_zone: 'Pacific/Noumea',
        currency: 'XPF',
        vat_rate: 0.0
    },
    {
        id: 161,
        name: 'Niger (the)',
        iso2: 'NE',
        iso3: 'NER',
        time_zone: 'Africa/Niamey',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 164,
        name: 'Norfolk Island',
        iso2: 'NF',
        iso3: 'NFK',
        time_zone: 'Pacific/Norfolk',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 162,
        name: 'Nigeria',
        iso2: 'NG',
        iso3: 'NGA',
        time_zone: 'Africa/Lagos',
        currency: 'NGN',
        vat_rate: 0.0
    },
    {
        id: 160,
        name: 'Nicaragua',
        iso2: 'NI',
        iso3: 'NIC',
        time_zone: 'America/Managua',
        currency: 'NIO',
        vat_rate: 0.0
    },
    { id: 163, name: 'Niue', iso2: 'NU', iso3: 'NIU', time_zone: 'Pacific/Niue', currency: 'NZD', vat_rate: 0.0 },
    {
        id: 157,
        name: 'Netherlands (the)',
        iso2: 'NL',
        iso3: 'NLD',
        time_zone: 'Europe/Amsterdam',
        currency: 'EUR',
        vat_rate: 21.0
    },
    { id: 166, name: 'Norway', iso2: 'NO', iso3: 'NOR', time_zone: 'Europe/Oslo', currency: 'NOK', vat_rate: 25.0 },
    {
        id: 156,
        name: 'Nepal',
        iso2: 'NP',
        iso3: 'NPL',
        time_zone: 'Asia/Kathmandu',
        currency: 'NPR',
        vat_rate: 0.0
    },
    { id: 155, name: 'Nauru', iso2: 'NR', iso3: 'NRU', time_zone: 'Pacific/Nauru', currency: 'AUD', vat_rate: 0.0 },
    {
        id: 159,
        name: 'New Zealand',
        iso2: 'NZ',
        iso3: 'NZL',
        time_zone: 'Pacific/Auckland',
        currency: 'NZD',
        vat_rate: 0.0
    },
    { id: 167, name: 'Oman', iso2: 'OM', iso3: 'OMN', time_zone: 'Asia/Muscat', currency: 'OMR', vat_rate: 0.0 },
    {
        id: 168,
        name: 'Pakistan',
        iso2: 'PK',
        iso3: 'PAK',
        time_zone: 'Asia/Karachi',
        currency: 'PKR',
        vat_rate: 0.0
    },
    {
        id: 171,
        name: 'Panama',
        iso2: 'PA',
        iso3: 'PAN',
        time_zone: 'America/Panama',
        currency: 'PAB',
        vat_rate: 0.0
    },
    {
        id: 176,
        name: 'Pitcairn',
        iso2: 'PN',
        iso3: 'PCN',
        time_zone: 'Pacific/Pitcairn',
        currency: 'NZD',
        vat_rate: 0.0
    },
    { id: 174, name: 'Peru', iso2: 'PE', iso3: 'PER', time_zone: 'America/Lima', currency: 'PEN', vat_rate: 0.0 },
    {
        id: 175,
        name: 'Philippines (the)',
        iso2: 'PH',
        iso3: 'PHL',
        time_zone: 'Asia/Manila',
        currency: 'PHP',
        vat_rate: 0.0
    },
    { id: 169, name: 'Palau', iso2: 'PW', iso3: 'PLW', time_zone: 'Pacific/Palau', currency: 'USD', vat_rate: 0.0 },
    {
        id: 172,
        name: 'Papua New Guinea',
        iso2: 'PG',
        iso3: 'PNG',
        time_zone: 'Pacific/Bougainville',
        currency: 'PGK',
        vat_rate: 0.0
    },
    {
        id: 177,
        name: 'Poland',
        iso2: 'PL',
        iso3: 'POL',
        time_zone: 'Europe/Warsaw',
        currency: 'PLN',
        vat_rate: 23.0
    },
    {
        id: 179,
        name: 'Puerto Rico',
        iso2: 'PR',
        iso3: 'PRI',
        time_zone: 'America/Puerto_Rico',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 118,
        name: "Korea (the Democratic People's Republic of)",
        iso2: 'KP',
        iso3: 'PRK',
        time_zone: 'Asia/Pyongyang',
        currency: 'KPW',
        vat_rate: 0.0
    },
    {
        id: 178,
        name: 'Portugal',
        iso2: 'PT',
        iso3: 'PRT',
        time_zone: 'Atlantic/Azores',
        currency: 'EUR',
        vat_rate: 23.0
    },
    {
        id: 173,
        name: 'Paraguay',
        iso2: 'PY',
        iso3: 'PRY',
        time_zone: 'America/Asuncion',
        currency: 'PYG',
        vat_rate: 0.0
    },
    {
        id: 170,
        name: 'Palestine, State of',
        iso2: 'PS',
        iso3: 'PSE',
        time_zone: 'Asia/Gaza',
        currency: '',
        vat_rate: 0.0
    },
    {
        id: 74,
        name: 'French Polynesia',
        iso2: 'PF',
        iso3: 'PYF',
        time_zone: 'Pacific/Gambier',
        currency: 'XPF',
        vat_rate: 0.0
    },
    { id: 180, name: 'Qatar', iso2: 'QA', iso3: 'QAT', time_zone: 'Asia/Qatar', currency: 'QAR', vat_rate: 0.0 },
    {
        id: 181,
        name: 'Réunion',
        iso2: 'RE',
        iso3: 'REU',
        time_zone: 'Indian/Reunion',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 182,
        name: 'Romania',
        iso2: 'RO',
        iso3: 'ROM',
        time_zone: 'Europe/Bucharest',
        currency: 'RON',
        vat_rate: 19.0
    },
    {
        id: 183,
        name: 'Russian Federation (the)',
        iso2: 'RU',
        iso3: 'RUS',
        time_zone: 'Asia/Anadyr',
        currency: 'RUB',
        vat_rate: 0.0
    },
    {
        id: 184,
        name: 'Rwanda',
        iso2: 'RW',
        iso3: 'RWA',
        time_zone: 'Africa/Kigali',
        currency: 'RWF',
        vat_rate: 0.0
    },
    {
        id: 195,
        name: 'Saudi Arabia',
        iso2: 'SA',
        iso3: 'SAU',
        time_zone: 'Asia/Riyadh',
        currency: 'SAR',
        vat_rate: 0.0
    },
    {
        id: 211,
        name: 'Sudan (the)',
        iso2: 'SD',
        iso3: 'SDN',
        time_zone: 'Africa/Khartoum',
        currency: 'SDG',
        vat_rate: 0.0
    },
    {
        id: 196,
        name: 'Senegal',
        iso2: 'SN',
        iso3: 'SEN',
        time_zone: 'Africa/Dakar',
        currency: 'XOF',
        vat_rate: 0.0
    },
    {
        id: 200,
        name: 'Singapore',
        iso2: 'SG',
        iso3: 'SGP',
        time_zone: 'Asia/Singapore',
        currency: 'SGD',
        vat_rate: 0.0
    },
    {
        id: 207,
        name: 'South Georgia and the South Sandwich Islands',
        iso2: 'GS',
        iso3: 'SGS',
        time_zone: 'Atlantic/South_Georgia',
        currency: '',
        vat_rate: 0.0
    },
    {
        id: 186,
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        iso2: 'SH',
        iso3: 'SHN',
        time_zone: 'Atlantic/St_Helena',
        currency: 'SHP',
        vat_rate: 0.0
    },
    {
        id: 213,
        name: 'Svalbard and Jan Mayen',
        iso2: 'SJ',
        iso3: 'SJM',
        time_zone: 'Arctic/Longyearbyen',
        currency: 'NOK',
        vat_rate: 0.0
    },
    {
        id: 204,
        name: 'Solomon Islands',
        iso2: 'SB',
        iso3: 'SLB',
        time_zone: 'Pacific/Guadalcanal',
        currency: 'SBD',
        vat_rate: 0.0
    },
    {
        id: 199,
        name: 'Sierra Leone',
        iso2: 'SL',
        iso3: 'SLE',
        time_zone: 'Africa/Freetown',
        currency: 'SLL',
        vat_rate: 0.0
    },
    {
        id: 63,
        name: 'El Salvador',
        iso2: 'SV',
        iso3: 'SLV',
        time_zone: 'America/El_Salvador',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 193,
        name: 'San Marino',
        iso2: 'SM',
        iso3: 'SMR',
        time_zone: 'Europe/San_Marino',
        currency: 'EUR',
        vat_rate: 22.0
    },
    {
        id: 205,
        name: 'Somalia',
        iso2: 'SO',
        iso3: 'SOM',
        time_zone: 'Africa/Mogadishu',
        currency: 'SOS',
        vat_rate: 0.0
    },
    {
        id: 190,
        name: 'Saint Pierre and Miquelon',
        iso2: 'PM',
        iso3: 'SPM',
        time_zone: 'America/Miquelon',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 197,
        name: 'Serbia',
        iso2: 'RS',
        iso3: 'SRB',
        time_zone: 'Europe/Belgrade',
        currency: 'RSD',
        vat_rate: 20.0
    },
    {
        id: 208,
        name: 'South Sudan',
        iso2: 'SS',
        iso3: 'SSD',
        time_zone: 'Africa/Juba',
        currency: 'SSP',
        vat_rate: 0.0
    },
    {
        id: 194,
        name: 'Sao Tome and Principe',
        iso2: 'ST',
        iso3: 'STP',
        time_zone: 'Africa/Sao_Tome',
        currency: 'STD',
        vat_rate: 0.0
    },
    {
        id: 212,
        name: 'Suriname',
        iso2: 'SR',
        iso3: 'SUR',
        time_zone: 'America/Paramaribo',
        currency: 'SRD',
        vat_rate: 0.0
    },
    {
        id: 202,
        name: 'Slovakia',
        iso2: 'SK',
        iso3: 'SVK',
        time_zone: 'Europe/Bratislava',
        currency: 'EUR',
        vat_rate: 20.0
    },
    {
        id: 203,
        name: 'Slovenia',
        iso2: 'SI',
        iso3: 'SVN',
        time_zone: 'Europe/Ljubljana',
        currency: 'EUR',
        vat_rate: 22.0
    },
    {
        id: 215,
        name: 'Sweden',
        iso2: 'SE',
        iso3: 'SWE',
        time_zone: 'Europe/Stockholm',
        currency: 'SEK',
        vat_rate: 25.0
    },
    {
        id: 214,
        name: 'Swaziland',
        iso2: 'SZ',
        iso3: 'SWZ',
        time_zone: 'Africa/Mbabane',
        currency: 'SZL',
        vat_rate: 0.0
    },
    {
        id: 201,
        name: 'Sint Maarten (Dutch part)',
        iso2: 'SX',
        iso3: 'SXM',
        time_zone: 'America/Lower_Princes',
        currency: 'ANG',
        vat_rate: 0.0
    },
    {
        id: 198,
        name: 'Seychelles',
        iso2: 'SC',
        iso3: 'SYC',
        time_zone: 'Indian/Mahe',
        currency: 'SCR',
        vat_rate: 0.0
    },
    {
        id: 217,
        name: 'Syrian Arab Republic',
        iso2: 'SY',
        iso3: 'SYR',
        time_zone: 'Asia/Damascus',
        currency: 'SYP',
        vat_rate: 0.0
    },
    {
        id: 230,
        name: 'Turks and Caicos Islands (the)',
        iso2: 'TC',
        iso3: 'TCA',
        time_zone: 'America/Grand_Turk',
        currency: 'USD',
        vat_rate: 0.0
    },
    { id: 98, name: 'Chad', iso2: 'TD', iso3: 'TCD', time_zone: 'Africa/Ndjamena', currency: 'XAF', vat_rate: 0.0 },
    { id: 223, name: 'Togo', iso2: 'TG', iso3: 'TGO', time_zone: 'Africa/Lome', currency: 'XOF', vat_rate: 0.0 },
    {
        id: 221,
        name: 'Thailand',
        iso2: 'TH',
        iso3: 'THA',
        time_zone: 'Asia/Bangkok',
        currency: 'THB',
        vat_rate: 0.0
    },
    {
        id: 219,
        name: 'Tajikistan',
        iso2: 'TJ',
        iso3: 'TJK',
        time_zone: 'Asia/Dushanbe',
        currency: 'TJS',
        vat_rate: 0.0
    },
    {
        id: 224,
        name: 'Tokelau',
        iso2: 'TK',
        iso3: 'TKL',
        time_zone: 'Pacific/Fakaofo',
        currency: 'NZD',
        vat_rate: 0.0
    },
    {
        id: 229,
        name: 'Turkmenistan',
        iso2: 'TM',
        iso3: 'TKM',
        time_zone: 'Asia/Ashgabat',
        currency: 'TMT',
        vat_rate: 0.0
    },
    {
        id: 222,
        name: 'Timor-Leste',
        iso2: 'TL',
        iso3: 'TLS',
        time_zone: 'Asia/Dili',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 225,
        name: 'Tonga',
        iso2: 'TO',
        iso3: 'TON',
        time_zone: 'Pacific/Tongatapu',
        currency: 'TOP',
        vat_rate: 0.0
    },
    {
        id: 226,
        name: 'Trinidad and Tobago',
        iso2: 'TT',
        iso3: 'TTO',
        time_zone: 'America/Port_of_Spain',
        currency: 'TTD',
        vat_rate: 0.0
    },
    {
        id: 227,
        name: 'Tunisia',
        iso2: 'TN',
        iso3: 'TUN',
        time_zone: 'Africa/Tunis',
        currency: 'TND',
        vat_rate: 0.0
    },
    {
        id: 228,
        name: 'Turkey',
        iso2: 'TR',
        iso3: 'TUR',
        time_zone: 'Europe/Istanbul',
        currency: 'TRY',
        vat_rate: 18.0
    },
    {
        id: 231,
        name: 'Tuvalu',
        iso2: 'TV',
        iso3: 'TUV',
        time_zone: 'Pacific/Funafuti',
        currency: 'AUD',
        vat_rate: 0.0
    },
    {
        id: 218,
        name: 'Taiwan (Province of China)',
        iso2: 'TW',
        iso3: 'TWN',
        time_zone: 'Asia/Taipei',
        currency: '',
        vat_rate: 0.0
    },
    {
        id: 220,
        name: 'Tanzania, United Republic of',
        iso2: 'TZ',
        iso3: 'TZA',
        time_zone: 'Africa/Dar_es_Salaam',
        currency: 'TZS',
        vat_rate: 0.0
    },
    {
        id: 232,
        name: 'Uganda',
        iso2: 'UG',
        iso3: 'UGA',
        time_zone: 'Africa/Kampala',
        currency: 'UGX',
        vat_rate: 0.0
    },
    {
        id: 233,
        name: 'Ukraine',
        iso2: 'UA',
        iso3: 'UKR',
        time_zone: 'Europe/Kiev',
        currency: 'UAH',
        vat_rate: 20.0
    },
    {
        id: 236,
        name: 'United States Minor Outlying Islands (the)',
        iso2: 'UM',
        iso3: 'UMI',
        time_zone: 'Pacific/Midway',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 238,
        name: 'Uruguay',
        iso2: 'UY',
        iso3: 'URY',
        time_zone: 'America/Montevideo',
        currency: 'UYU',
        vat_rate: 0.0
    },
    {
        id: 237,
        name: 'United States of America (the)',
        iso2: 'US',
        iso3: 'USA',
        time_zone: 'America/Adak',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 239,
        name: 'Uzbekistan',
        iso2: 'UZ',
        iso3: 'UZB',
        time_zone: 'Asia/Samarkand',
        currency: 'UZS',
        vat_rate: 0.0
    },
    {
        id: 94,
        name: 'Holy See (the)',
        iso2: 'VA',
        iso3: 'VAT',
        time_zone: 'Europe/Vatican',
        currency: 'EUR',
        vat_rate: 0.0
    },
    {
        id: 191,
        name: 'Saint Vincent and the Grenadines',
        iso2: 'VC',
        iso3: 'VCT',
        time_zone: 'America/St_Vincent',
        currency: 'XCD',
        vat_rate: 0.0
    },
    {
        id: 241,
        name: 'Venezuela (Bolivarian Republic of)',
        iso2: 'VE',
        iso3: 'VEN',
        time_zone: 'America/Caracas',
        currency: 'VEF',
        vat_rate: 0.0
    },
    {
        id: 243,
        name: 'Virgin Islands (British)',
        iso2: 'VG',
        iso3: 'VGB',
        time_zone: 'America/Tortola',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 244,
        name: 'Virgin Islands (U.S.)',
        iso2: 'VI',
        iso3: 'VIR',
        time_zone: 'America/St_Thomas',
        currency: 'USD',
        vat_rate: 0.0
    },
    {
        id: 242,
        name: 'Viet Nam',
        iso2: 'VN',
        iso3: 'VNM',
        time_zone: 'Asia/Ho_Chi_Minh',
        currency: 'VND',
        vat_rate: 0.0
    },
    {
        id: 240,
        name: 'Vanuatu',
        iso2: 'VU',
        iso3: 'VUT',
        time_zone: 'Pacific/Efate',
        currency: 'VUV',
        vat_rate: 0.0
    },
    {
        id: 245,
        name: 'Wallis and Futuna',
        iso2: 'WF',
        iso3: 'WLF',
        time_zone: 'Pacific/Wallis',
        currency: 'XPF',
        vat_rate: 0.0
    },
    { id: 192, name: 'Samoa', iso2: 'WS', iso3: 'WSM', time_zone: 'Pacific/Apia', currency: 'WST', vat_rate: 0.0 },
    { id: 247, name: 'Yemen', iso2: 'YE', iso3: 'YEM', time_zone: 'Asia/Aden', currency: 'YER', vat_rate: 0.0 },
    {
        id: 206,
        name: 'South Africa',
        iso2: 'ZA',
        iso3: 'ZAF',
        time_zone: 'Africa/Johannesburg',
        currency: 'ZAR',
        vat_rate: 0.0
    },
    {
        id: 248,
        name: 'Zambia',
        iso2: 'ZM',
        iso3: 'ZMB',
        time_zone: 'Africa/Lusaka',
        currency: 'ZMW',
        vat_rate: 0.0
    },
    {
        id: 249,
        name: 'Zimbabwe',
        iso2: 'ZW',
        iso3: 'ZWE',
        time_zone: 'Africa/Harare',
        currency: 'ZWL',
        vat_rate: 0.0
    }
].map(c => ReadOnlyCountryFromJSON(c));
