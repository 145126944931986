/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPoint,
    BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPointFromJSON,
    BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPointFromJSONTyped,
    BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPointToJSON,
    TransportEventRule,
    TransportEventRuleFromJSON,
    TransportEventRuleFromJSONTyped,
    TransportEventRuleToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackendApiApiV2ActualVehicleStateVehicleTransport
 */
export interface BackendApiApiV2ActualVehicleStateVehicleTransport {
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    firstRta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    puesc?: boolean;
    /**
     * 
     * @type {BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPoint}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    nextWaypoint: BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPoint;
    /**
     * 
     * @type {Array<TransportEventRule>}
     * @memberof BackendApiApiV2ActualVehicleStateVehicleTransport
     */
    activeEventRules?: Array<TransportEventRule>;
}

export function BackendApiApiV2ActualVehicleStateVehicleTransportFromJSON(json: any): BackendApiApiV2ActualVehicleStateVehicleTransport {
    return BackendApiApiV2ActualVehicleStateVehicleTransportFromJSONTyped(json, false);
}

export function BackendApiApiV2ActualVehicleStateVehicleTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV2ActualVehicleStateVehicleTransport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstRta': !exists(json, 'firstRta') ? undefined : json['firstRta'],
        'puesc': !exists(json, 'puesc') ? undefined : json['puesc'],
        'nextWaypoint': BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPointFromJSON(json['nextWaypoint']),
        'activeEventRules': !exists(json, 'activeEventRules') ? undefined : ((json['activeEventRules'] as Array<any>).map(TransportEventRuleFromJSON)),
    };
}

export function BackendApiApiV2ActualVehicleStateVehicleTransportToJSON(value?: BackendApiApiV2ActualVehicleStateVehicleTransport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'firstRta': value.firstRta,
        'puesc': value.puesc,
        'nextWaypoint': BackendApiApiV2ActualVehicleStateVehicleTransportNextWayPointToJSON(value.nextWaypoint),
        'activeEventRules': value.activeEventRules === undefined ? undefined : ((value.activeEventRules as Array<any>).map(TransportEventRuleToJSON)),
    };
}


