import { CalculationTypeOptions, replacementMeals } from 'common/model/allowance';
import { DATE_FORMAT } from 'domain-constants';
import {
    DiemsRulesAffected,
    DiemsRulesCreateMany,
    ReadOnlyDiemsRules,
    WriteOnlyDiemsRules2RuleClassEnum,
    WriteOnlyDiemsRules2RuleNameEnum
} from 'generated/new-main';
import { Logic } from 'logic/logic';
import { action, makeObservable, observable } from 'mobx';
import { AllowanceRuleTableData } from 'modules/statistics/modules/allowance/components/AllowanceRuleTable/AllowanceRuleTable';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import i18n from 'i18next';

export class DiemsRules {
    private _logic: Logic;

    @observable diemsListLoading: boolean = false;
    @observable diemsRuleListData: ReadOnlyDiemsRules[] = [];
    diemsOverlappingLoading = new Subject<boolean>();
    diemsOverlappingData = new Subject<DiemsRulesAffected>();
    diemsOverlappingError = new Subject<any>();

    constructor(logic: Logic) {
        this._logic = logic;
        makeObservable(this);
    }

    @action
    async getDiemsRules(userId?: number) {
        this.diemsListLoading = true;
        try {
            const diemsRuleListData = (
                this._logic.demo().isActive
                    ? this._logic.demo().data.diemsRules
                    : await this._logic.api().diemsRuleApi.diemsRulesList({})
            ).sort((a, b) => {
                return Number(b.end) - Number(a.start);
            });

            if (userId) {
                this.diemsRuleListData = diemsRuleListData.filter(
                    rule => rule.user === userId || rule.user === undefined
                );
            } else {
                this.diemsRuleListData = diemsRuleListData;
            }
        } catch (err) {
            console.error('Can not get diems rule, err: ', err);
            throw err;
        } finally {
            this.diemsListLoading = false;
        }
    }

    async getAppliedDiemsRule(userId: number, start: string, end: string) {
        await this.getDiemsRules(userId);
        const dateFilterDiemsRule = this.diemsRuleListData.filter(rule => {
            return (
                moment(rule.end).diff(moment(end), 'days') >= 0 && moment(rule.start).diff(moment(start), 'days') <= 0
            );
        });

        const appliedUserRule = dateFilterDiemsRule.find(rule => rule.user);

        if (appliedUserRule) {
            return appliedUserRule;
        }
        return dateFilterDiemsRule[0];
    }

    async getOverlappingDiemsRules(start: Moment, end: Moment, userIds?: number[]) {
        this.diemsOverlappingLoading.next(true);
        try {
            const data = await this._logic.api().diemsRuleApi.diemsRulesOverlapping({
                end: end.toISOString(),
                start: start.toISOString(),
                users: userIds ? userIds.join(',') : undefined
            });
            this.diemsOverlappingData.next(data);
        } catch (err) {
            this.diemsOverlappingError.next(err);
        } finally {
            this.diemsOverlappingLoading.next(false);
        }
    }

    async setManualRate(dayFrom: Date, dayTo: Date, user: number, ruleValue: any, manualRateId?: string) {
        const clientId = this._logic.auth().client()?.id;

        try {
            if (!clientId) {
                throw new Error('No client id');
            }

            if (!ruleValue.domestic && !ruleValue.foreign && manualRateId) {
                await this._logic.api().diemsRules2Api.diemsRules2Delete({
                    id: manualRateId
                });
            } else if (manualRateId === undefined) {
                await this._logic.api().diemsRules2Api.diemsRules2Create({
                    data: {
                        client: clientId,
                        dayFrom,
                        dayTo,
                        user,
                        ruleClass: WriteOnlyDiemsRules2RuleClassEnum.User,
                        ruleName: WriteOnlyDiemsRules2RuleNameEnum.DiemsRate,
                        ruleValue
                    }
                });
            } else {
                await this._logic.api().diemsRules2Api.diemsRules2Update({
                    id: manualRateId,
                    data: {
                        client: clientId,
                        dayFrom,
                        dayTo,
                        user,
                        ruleClass: WriteOnlyDiemsRules2RuleClassEnum.User,
                        ruleName: WriteOnlyDiemsRules2RuleNameEnum.DiemsRate,
                        ruleValue
                    }
                });
            }
        } catch (err) {
            console.error('Can not set manual rate, err: ', err);
            throw err;
        }
    }

    async createDiemsRules(
        start: Date,
        end: Date,
        userIds: number[],
        replacedMeals: number[],
        pocketMoney: number,
        concatForeignDiems: boolean,
        domesticRate?: number,
        foreignRate?: number
    ) {
        const clientId = this._logic.auth().client()?.id;
        try {
            if (!clientId) {
                throw new Error('No client id');
            }

            const requestData: DiemsRulesCreateMany = {
                start,
                end,
                users: userIds,
                replacedMeals: replacedMeals.length > 0 ? replacedMeals : null,
                pocketMoney,
                concatForeignDiems: concatForeignDiems
            };

            if (domesticRate && foreignRate) {
                requestData.plFixedRates = {
                    domesticRate: domesticRate,
                    foreignRate: foreignRate
                };
            }

            await this._logic.api().diemsRuleApi.diemsRulesCreateMany({
                data: requestData
            });
        } catch (err) {
            console.error('Can not create diems rule, err: ', err);
            throw err;
        }
    }

    get diemsRuleTableData(): AllowanceRuleTableData[] {
        const getPocketMoney = (dr: ReadOnlyDiemsRules) => {
            return dr.pocketMoney ? `${i18n.t('Allowance.bulk.supply')}: ${dr.pocketMoney}` : '';
        };
        const getReplacementMeals = (dr: ReadOnlyDiemsRules) => {
            return dr.replacedMeals
                ? `${i18n.t('Allowance.bulk.shortening')}: ${dr.replacedMeals
                      .map(rm => replacementMeals().find(m => m.value === rm)?.label)
                      .filter(rm => rm !== undefined)
                      .join(', ')}`
                : '';
        };

        const getCalculationType = (dr: ReadOnlyDiemsRules) => {
            return `${i18n.t('Allowance.bulk.calculationType')}: ${this.getRulesCalculationType(dr)}`;
        };

        const getPlFixes = (dr: ReadOnlyDiemsRules) => {
            return dr.plFixedRates
                ? `${i18n.t('Allowance.bulk.domesticRatePerDay')}: ${dr.plFixedRates.domesticRates[2].rate}, ${i18n.t(
                      'Allowance.bulk.foreignRatePerDay'
                  )}: ${dr.plFixedRates.foreignRates[2].rate}`
                : '';
        };

        const getAudience = (dr: ReadOnlyDiemsRules) => {
            const user = this._logic.diems().drivers?.find(d => d.id === dr.user?.toString());
            if (dr.user) {
                return user ? `${user.name} ${user.surname}` : '';
            }
            return this._logic.auth().client()?.name ?? '';
        };

        return this.diemsRuleListData.map<AllowanceRuleTableData>((dr, index) => {
            const rulesString = [getPocketMoney(dr), getReplacementMeals(dr), getCalculationType(dr), getPlFixes(dr)];
            return {
                originalData: dr,
                key: dr.id ?? index.toString(),
                dateFrom: {
                    editable: moment().diff(moment(dr.start), 'days') <= 0,
                    value: moment(dr.start).format(DATE_FORMAT)
                },
                dateTo: {
                    editable: moment().diff(moment(dr.end), 'days') <= 0,
                    value: moment.utc(dr.end).subtract(1, 'days').format(DATE_FORMAT)
                },
                audience: getAudience(dr),
                editable: moment().diff(moment(dr.end), 'days') <= 0 || moment().diff(moment(dr.start), 'days') <= 0,
                rule: rulesString.filter(r => r !== '').join(' | ')
            };
        });
    }

    getRulesCalculationType(rule: ReadOnlyDiemsRules) {
        return rule.concatForeignDiems
            ? CalculationTypeOptions.lastForeign
            : rule.plFixedRates
            ? CalculationTypeOptions.fixedRates
            : CalculationTypeOptions.default;
    }
}
