import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { WithLogic } from 'App';
import { Button } from 'common/components';
import { Input } from 'common/components/Form';
import { EUR, M3, T } from 'domain-constants';
import { ReadOnlyContactList } from 'generated/new-main';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { withTranslation } from 'react-i18next';
import { ellipse } from 'resources/images/common';
import { ActivityGood, NEW_CLIENT_ITEM_VALUE } from '../ActivityCard';
import ActivityTaskBase, { ActivityBaseProps } from '../ActivityTaskBase';
import EditItem from '../EditItem';
import PlannerContentContainer from '../PlannerContentContainer';
import ActivityGoodsTable from '../ActivityGoodsTable';
import ActivityMoreDetails from '../ActivityMoreDetails';
import { ItemAdd } from 'resources/images/planner';
import ActivityClient from '../ActivityClient';
import { OptionData } from 'rc-select/lib/interface';
import { TransportPlaceTask } from 'generated/backend-api';
import { observer } from 'mobx-react';

interface Props extends ActivityBaseProps, WithLogic {
    placeDisabled: boolean;
    refreshClient?: number;
    onDeleteClientClick?: (id: string, task: TransportPlaceTask) => void;
    onEditClientClick?: (id: string, task: TransportPlaceTask) => void;
    onNewClientClick?: (task: TransportPlaceTask) => void;
}

const ActivityTaskLoading = (props: Props) => {
    const _getClient = (): string | undefined => {
        return props.task.contactId
            ? props.logic.plannerLogic().clientList.find(c => c.id === props.task.contactId)?.name ??
                  props.task.contactId
            : '';
    };

    const [selectedClient, setSelectedClient] = useState(_getClient());
    const [change, setChange] = useState(0);
    const [showMoreWeight, setShowMoreWeight] = useState(false);
    const [goodsCount, setGoodsCount] = useState(props.task.loadingGoods?.length ?? 0);
    const [tWeight, setTWeight] = useState(props.logic.plannerLogic().getTaskWeight(props.task, T));
    const [mWeight, setMWeight] = useState(props.logic.plannerLogic().getTaskWeight(props.task, M3));

    useEffect(() => {
        setSelectedClient(_getClient());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshClient, props.task.contactId]);

    const { t } = props;

    const handleClientSelect = (value: string) => {
        if (value === NEW_CLIENT_ITEM_VALUE) {
            props.onNewClientClick?.(props.task);
        } else {
            const client = props.logic.plannerLogic().clientList.find(c => c.name === value);
            if (client) {
                props.task.contactId = client.id;
            }
            setSelectedClient(value);
        }
    };

    const handleClientChange = (value: string) => {
        if (value !== NEW_CLIENT_ITEM_VALUE) {
            const client = props.logic.plannerLogic().clientList.find(c => c.name === value);
            if (!client) {
                props.task.contactId = value;
                setSelectedClient(value);
            }
        }
    };

    const handleValueChange = (name: string, value: any) => {
        switch (name) {
            case 'shippmentPrice':
                if (props.task.shipmentPrice) {
                    props.task.shipmentPrice.price = value;
                } else {
                    props.task.shipmentPrice = {
                        price: value,
                        currency: EUR
                    };
                }
                break;
            case 'totalWeight':
                props.logic.plannerLogic().addOrRemoveMeasurement(props.task, value, T);
                props.logic.plannerLogic().addOrRemoveMeasurement(props.task, 0, M3);
                props.task.loadingGoods = [];

                setTWeight(value);
                setMWeight(0);
                break;
            case 'volume':
                props.logic.plannerLogic().addOrRemoveMeasurement(props.task, value, M3);
                setMWeight(value);
                break;
        }
    };

    const handleShowMoreClick = () => {
        setShowMoreWeight(!showMoreWeight);
        props.onExpadedWidth?.(props.index, !showMoreWeight);
    };

    const handleOnExpandClicked = (state: boolean) => {
        setChange(change + 1);
        if (!state) {
            setShowMoreWeight(false);
            props.onExpadedWidth?.(props.index, false);
        }
    };

    const handleAddGoodsClick = () => {
        const newData: ActivityGood = {
            id: props.logic.plannerLogic().generateId(),
            name: '',
            cnItems: undefined,
            cmr: undefined,
            grossWeightVolume: 0,
            measurementUnit: T,
            measurements: []
        };
        if (props.task.loadingGoods) {
            props.task.loadingGoods.push(newData);
        } else {
            props.task.loadingGoods = [newData];
        }
        setGoodsCount(props.task.loadingGoods.length ?? 0);
    };

    const handleGoodsChange = (record: ActivityGood, dataIndex: string) => {
        if (['grossWeightVolume', 'measurements'].indexOf(dataIndex) > -1) {
            _computeWeight();
        }
    };

    const handleGoodsDelete = () => {
        _computeWeight();
        setGoodsCount(props.task?.loadingGoods?.length ?? 0);
    };

    const _computeWeight = () => {
        let sum = 0;

        props.task?.loadingGoods?.forEach(good => {
            sum += good.measurements?.find(m => m.measurementUnit === M3)?.grossWeightVolume ?? 0;
        });
        props.logic.plannerLogic().addOrRemoveMeasurement(props.task, sum, M3);
        setMWeight(sum);

        sum = 0;
        props.task?.loadingGoods?.forEach(good => {
            sum +=
                good.measurements?.find(m => m.measurementUnit === T)?.grossWeightVolume ?? good.grossWeightVolume ?? 0;
        });

        props.logic.plannerLogic().addOrRemoveMeasurement(props.task, sum, T);
        setTWeight(sum);
    };

    const _getClientName = (client: ReadOnlyContactList | undefined): React.ReactNode | undefined => {
        if (client) {
            return (
                <EditItem
                    showDelete={false}
                    className="edit-active-client"
                    onButtonEditClick={() => {
                        props.onEditClientClick?.(String(client.id), props.task);
                    }}
                >
                    <Row className="title">{client.name}</Row>
                    {client.country?.iso2}, {client?.city}, {client?.street} {client?.houseNumber},{client?.postalCode}
                    <img src={ellipse} alt="ellipse-icon" />
                    {t('ClientContactListForm.businessId').toUpperCase()} {client.businessId}
                    <img src={ellipse} alt="ellipse-icon" />
                    {t('ClientContactListForm.vat').toUpperCase()} {client.vat}
                    <img src={ellipse} alt="ellipse-icon" />
                    {t('ClientContactListForm.taxId').toUpperCase()} {client.taxId}
                    <img src={ellipse} alt="ellipse-icon" />
                    {t('ClientContactListForm.otherIdentificationNumber').toUpperCase()}{' '}
                    {client.otherIdentificationNumber}
                </EditItem>
            );
        } else {
            return undefined;
        }
    };

    const newClient = {
        key: NEW_CLIENT_ITEM_VALUE,
        value: NEW_CLIENT_ITEM_VALUE,
        label: (
            <Row
                align="middle"
                className="add-new-activity-client"
                onClick={e => {
                    props.onNewClientClick?.(props.task);
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <img src={ItemAdd} alt="add-new-client" />
                {t('ContactList.createNew')}
            </Row>
        )
    };

    let clientOptions = props.logic.plannerLogic().clientList.map(
        (client, index) =>
            (({
                key: client.id,
                value: client.name,
                label: (
                    <EditItem
                        key={index}
                        onButtonDeleteClick={() => {
                            props.onDeleteClientClick?.(client.id!, props.task);
                        }}
                        onButtonEditClick={() => {
                            props.onEditClientClick?.(client.id!, props.task);
                        }}
                    >
                        {client.name}
                    </EditItem>
                )
            } as OptionData) ?? [])
    );

    clientOptions.unshift(newClient);

    const client = props.logic.plannerLogic().clientContactList.find(client => client.id === props.task.contactId);

    const measurements = props.task.measurements
        ?.sort((a, b) => {
            return a.measurementUnit === T || a.measurementUnit > b.measurementUnit ? -1 : 1;
        })
        .map(measurement => (
            <>
                <img src={ellipse} alt="ellipse-icon" />
                {numeral(measurement.grossWeightVolume ?? 0).format('0, 0.00')} {measurement.measurementUnit}
            </>
        ));

    const cmr = props.task.loadingGoods?.filter(g => g.cmr)?.map(g => g.cmr);

    return (
        <ActivityTaskBase
            {...props}
            className="activity-task-loading"
            headerInfo={
                <Row className="activity-task-loading-close-detail" align="middle">
                    {client ? client.name : selectedClient ?? ''} {measurements}{' '}
                    {cmr && (
                        <>
                            <img src={ellipse} alt="ellipse-icon" />
                            CMR {cmr.join(', ')}
                        </>
                    )}
                </Row>
            }
            onExpandedClick={handleOnExpandClicked}
        >
            <Row className="container">
                <Row>
                    <Col className="client">
                        <Row>{t('routing.configurator.select.client')}</Row>
                        <ActivityClient
                            autoFocus={false}
                            disabled={props.placeDisabled}
                            refresh={props.refreshClient}
                            value={selectedClient}
                            qa={qa.planner.activity.inputCompanyName}
                            onChange={handleClientChange}
                            onSelect={handleClientSelect}
                            options={clientOptions}
                        />
                    </Col>
                    <Col className="shippment-price">
                        <Row>{t('PlannerPlacesTask.shippmentPrice')}</Row>
                        <Input.Number
                            defaultValue={Number(props.task.shipmentPrice ? props.task.shipmentPrice.price : 0)}
                            disabled={props.placeDisabled}
                            disableStepIcon={true}
                            precision={2}
                            min={0}
                            postfix={`[${props.task.shipmentPrice ? props.task.shipmentPrice.currency : EUR}]`}
                            onChange={value => {
                                handleValueChange('shippmentPrice', value);
                            }}
                        />
                    </Col>
                </Row>
                {client && <Row className="activity-task-loading-client">{_getClientName(client)}</Row>}
            </Row>
            {!showMoreWeight && (
                <>
                    <Row className="total-weight" align="middle">
                        <Col flex="auto">{t('PlannerPlacesTask.loadedGoods')}</Col>
                        <Col className="total-weight-input">
                            <Row>{t('PlannerPlacesTask.totalWeight')}</Row>
                            <Input.Number
                                disabled={props.placeDisabled || goodsCount > 0}
                                data-qa={qa.planner.activity.goods.fieldGrossWeightVolume}
                                value={tWeight}
                                disableStepIcon={true}
                                precision={1}
                                min={0}
                                postfix={`[${T}]`}
                                onChange={value => {
                                    handleValueChange('totalWeight', value);
                                }}
                            />
                        </Col>
                        <Col className="total-volume-input">
                            <Row>{t('PlannerPlacesTask.volume')}</Row>
                            <Input.Number
                                disabled={props.placeDisabled || goodsCount > 0}
                                data-qa={qa.planner.activity.goods.fieldVolume}
                                disableStepIcon={true}
                                value={mWeight}
                                precision={1}
                                min={0}
                                postfix={`[${M3}]`}
                                onChange={value => {
                                    handleValueChange('volume', value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="weight-show-more" justify="end">
                        <Col onClick={handleShowMoreClick}>{t('PlannerPlacesTask.moreDetails')}</Col>
                    </Row>
                </>
            )}
            {showMoreWeight && (
                <PlannerContentContainer
                    className="add-goods"
                    title={t('PlannerPlacesTask.loadedGoods')}
                    expanded={true}
                    expandable={false}
                >
                    <Row>
                        <Row gutter={12} align="middle" className="total">
                            <Col span={5}>
                                <Row>{t('PlannerPlacesTask.totalWeight')}</Row>
                                <Input.Number
                                    disabled={props.placeDisabled || goodsCount > 0}
                                    data-qa={qa.planner.activity.goods.fieldGrossWeightVolume}
                                    value={tWeight}
                                    precision={1}
                                    min={0}
                                    postfix={`[${T}]`}
                                    onChange={value => {
                                        handleValueChange('totalWeight', value);
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <Row>{t('PlannerPlacesTask.volume')}</Row>
                                <Input.Number
                                    disabled={props.placeDisabled || goodsCount > 0}
                                    data-qa={qa.planner.activity.goods.fieldVolume}
                                    value={mWeight}
                                    precision={1}
                                    min={0}
                                    postfix={`[${M3}]`}
                                    onChange={value => {
                                        handleValueChange('volume', value);
                                    }}
                                />
                            </Col>
                            <Col span={14}>
                                {!props.placeDisabled && (
                                    <Button onClick={handleAddGoodsClick} className="edit-items" size="large">
                                        {t('PlannerPlacesTask.addNewLoadedGood')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row className="goods-table">
                            <ActivityGoodsTable
                                logic={props.logic}
                                goods={props.task.loadingGoods}
                                placeDisabled={props.placeDisabled}
                                editable={!props.placeDisabled}
                                onChange={handleGoodsChange}
                                onDelete={handleGoodsDelete}
                            />
                        </Row>
                    </Row>
                    <Row className="weight-show-more" justify="end">
                        <Col onClick={handleShowMoreClick}>{t('PlannerPlacesTask.lessDetails')}</Col>
                    </Row>
                </PlannerContentContainer>
            )}
            <ActivityMoreDetails task={props.task} />
        </ActivityTaskBase>
    );
};

export default withTranslation()(observer(ActivityTaskLoading));
