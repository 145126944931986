/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPActionCodeContractOperation,
    WAGTPActionCodeContractOperationFromJSON,
    WAGTPActionCodeContractOperationFromJSONTyped,
    WAGTPActionCodeContractOperationToJSON,
} from './';

/**
 * 
 * @export
 * @interface WAGTPServiceContractProvisioning
 */
export interface WAGTPServiceContractProvisioning {
    /**
     * 
     * @type {string}
     * @memberof WAGTPServiceContractProvisioning
     */
    actionId: string;
    /**
     * 
     * @type {WAGTPActionCodeContractOperation}
     * @memberof WAGTPServiceContractProvisioning
     */
    actionCode: WAGTPActionCodeContractOperation;
    /**
     * 
     * @type {string}
     * @memberof WAGTPServiceContractProvisioning
     */
    reasonCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WAGTPServiceContractProvisioning
     */
    tollContractNbr: string;
    /**
     * 
     * @type {string}
     * @memberof WAGTPServiceContractProvisioning
     */
    serviceDomain: string;
    /**
     * 
     * @type {string}
     * @memberof WAGTPServiceContractProvisioning
     */
    serviceArea?: string;
}

export function WAGTPServiceContractProvisioningFromJSON(json: any): WAGTPServiceContractProvisioning {
    return WAGTPServiceContractProvisioningFromJSONTyped(json, false);
}

export function WAGTPServiceContractProvisioningFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPServiceContractProvisioning {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': json['actionId'],
        'actionCode': WAGTPActionCodeContractOperationFromJSON(json['actionCode']),
        'reasonCode': !exists(json, 'reasonCode') ? undefined : json['reasonCode'],
        'tollContractNbr': json['tollContractNbr'],
        'serviceDomain': json['serviceDomain'],
        'serviceArea': !exists(json, 'serviceArea') ? undefined : json['serviceArea'],
    };
}

export function WAGTPServiceContractProvisioningToJSON(value?: WAGTPServiceContractProvisioning | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'actionCode': WAGTPActionCodeContractOperationToJSON(value.actionCode),
        'reasonCode': value.reasonCode,
        'tollContractNbr': value.tollContractNbr,
        'serviceDomain': value.serviceDomain,
        'serviceArea': value.serviceArea,
    };
}


