import { WithTranslation, withTranslation } from 'react-i18next';
import { LayerTypes } from '../../MapModule';
import { ControlPanel } from '../MapControlsBar';
import qa from 'qa-selectors';
import { Checkbox } from 'common/components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Card, Row, Col } from 'antd';
import { Switch, Tag } from 'common/components';
import cn from 'classnames';
import { WithLogic, withLogicContext } from 'App';

interface Props extends WithTranslation, WithLogic {
    data: { display: LayerTypes; traffic: boolean; borderCrosses: boolean };
    onLayerClick?: (type: LayerTypes) => void;
    onTrafficChange?: (show: boolean) => void;
    onBorderCrossesChange?: (show: boolean) => void;
    onBarLabel?: (panel: ControlPanel) => void;
    onVehicleClusteringSwitchClick?: (checked: boolean) => void;
}

function ControlsLayer(props: Props) {
    const { t } = props;
    const defaultVehicleClusteringEnabled = props.logic.settings().getProp('map').vehicleClusteringEnabled;

    function onLayerClick(type: LayerTypes) {
        props.onLayerClick?.(type);
    }

    function onLayerRoadmapClick() {
        onLayerClick(LayerTypes.ROADMAP);
    }

    function onLayerHybridClick() {
        onLayerClick(LayerTypes.HYBRID);
    }

    function onLayerTerrainClick() {
        onLayerClick(LayerTypes.TERRAIN);
    }

    function onTrafficChange(e: CheckboxChangeEvent) {
        const checked = e.target.checked;
        props.onTrafficChange?.(checked);
    }

    function onBorderCrossesChange(e: CheckboxChangeEvent) {
        const checked = e.target.checked;
        props.onBorderCrossesChange?.(checked);
    }

    return (
        <div className="map-controls layer-controls">
            <Card type="inner" title={t('LayerControls.mapBackgrounds')}>
                <Row align="middle">
                    <Col xs={8}>
                        <img
                            data-qa={qa.mapControls.simpleLayerButton}
                            alt="roadmap"
                            height="64"
                            width="64"
                            src="/img/roadmap.png"
                            onClick={onLayerRoadmapClick}
                            className={cn({ selected: props.data.display === LayerTypes.ROADMAP })}
                        />
                    </Col>
                    <Col xs={8}>
                        <img
                            data-qa={qa.mapControls.colourLayerButton}
                            alt="satelite"
                            height="64"
                            width="64"
                            src="https://www.google.cz/maps/vt/pb=!1m5!1m4!1i12!2i4422!3i2775!4i128!2m2!1e1!3i802!3m9!2scs!3scz!5e1105!12m1!1e4!12m1!1e47!12m1!1e3!4e0!5m1!1e0"
                            onClick={onLayerHybridClick}
                            className={cn({ selected: props.data.display === LayerTypes.HYBRID })}
                        />
                    </Col>
                    <Col xs={8}>
                        <img
                            data-qa={qa.mapControls.heightLayerButton}
                            alt="terrain"
                            height="64"
                            width="64"
                            src="https://www.google.cz/maps/vt/pb=!1m5!1m4!1i12!2i4422!3i2775!4i128!2m2!1e0!3i427130520!3m7!2scs!3scz!5e1105!12m1!1e47!12m1!1e3!4e0!5m1!1e0"
                            onClick={onLayerTerrainClick}
                            className={cn({ selected: props.data.display === LayerTypes.TERRAIN })}
                        />
                    </Col>
                </Row>
            </Card>
            <Card type="inner" title={t('LayerControls.markerClustering')}>
                <div className="layer-controls-clustering">
                    <Switch
                        defaultChecked={defaultVehicleClusteringEnabled}
                        size="small"
                        onChange={props.onVehicleClusteringSwitchClick}
                    />
                    <span>{t('common.vehicles')}</span>
                </div>
            </Card>
            <Card type="inner" title={t('LayerControls.traffic')}>
                <Row className="layer-controls-main-checkbox">
                    <Checkbox
                        data-qa={qa.mapControls.displayTrafficButton}
                        onChange={onTrafficChange}
                        checked={props.data.traffic}
                    >
                        {t('LayerControls.mapTrafficDisplay')}
                    </Checkbox>
                </Row>
                <Row>
                    <Col>
                        <span className="layer-controls-traffic-box green-box" />
                    </Col>
                    <Col>{t('LayerControls.trafficLegendColorsGreen')}</Col>
                </Row>
                <Row>
                    <Col>
                        <span className="layer-controls-traffic-box orange-box" />
                    </Col>
                    <Col>{t('LayerControls.trafficLegendColorsOrange')}</Col>
                </Row>
                <Row>
                    <Col>
                        <span className="layer-controls-traffic-box red-box" />
                    </Col>
                    <Col>{t('LayerControls.trafficLegendColorsRed')}</Col>
                </Row>
            </Card>
            <Card type="inner" title={t('LayerControls.borderCrosses')}>
                <Row className="layer-controls-main-checkbox">
                    <Checkbox
                        data-qa={qa.mapControls.displayBorderCrossesButton}
                        onChange={onBorderCrossesChange}
                        checked={props.data.borderCrosses}
                    >
                        {t('LayerControls.borderCrossesDisplay')}
                    </Checkbox>
                </Row>
                <Row>
                    <Col>
                        <Tag color="success">20m</Tag>
                    </Col>
                    <Col>{t('LayerControls.borderCrosses30m')}</Col>
                </Row>
                <Row>
                    <Col>
                        <Tag color="warning">1:10h</Tag>
                    </Col>
                    <Col>{t('LayerControls.borderCrosses90m')}</Col>
                </Row>
                <Row>
                    <Col>
                        <Tag color="error">3:30h</Tag>
                    </Col>
                    <Col>{t('LayerControls.borderCrossesOver90m')}</Col>
                </Row>
            </Card>
        </div>
    );
}

export default withLogicContext(withTranslation()(ControlsLayer));
