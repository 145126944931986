import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { CcrModel } from 'logic/partner/logic/partner-ccr';

interface Props {
    data: CcrModel;
}

export default function CcrDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="pricing-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.id')}>{props.data.ident}</Descriptions.Item>
                        <Descriptions.Item label={t('Partner.sn')}>{props.data.sn}</Descriptions.Item>
                        <Descriptions.Item label={t('Partner.createdOn')}>
                            {props.data.createdOn.format('L')}
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
