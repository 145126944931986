/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMobileDeviceOrdered
 */
export interface WriteOnlyMobileDeviceOrdered {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    type?: WriteOnlyMobileDeviceOrderedTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    imei: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    simIccid: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    externalId: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMobileDeviceOrdered
     */
    client?: number;
}

export function WriteOnlyMobileDeviceOrderedFromJSON(json: any): WriteOnlyMobileDeviceOrdered {
    return WriteOnlyMobileDeviceOrderedFromJSONTyped(json, false);
}

export function WriteOnlyMobileDeviceOrderedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMobileDeviceOrdered {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'imei': json['imei'],
        'simIccid': json['simIccid'],
        'externalId': json['external_id'],
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function WriteOnlyMobileDeviceOrderedToJSON(value?: WriteOnlyMobileDeviceOrdered | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'imei': value.imei,
        'simIccid': value.simIccid,
        'external_id': value.externalId,
        'client': value.client,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyMobileDeviceOrderedTypeEnum {
    _1 = '1'
}


