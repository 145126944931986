import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';

interface Props {
    data: PartnerUserModel;
    onImpersonate?: () => void;
    onDelete?: () => void;
}

export default function UsersActions(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="partner-actions">
            <Row gutter={[12, 12]}>
                {props.onImpersonate && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            onClick={props.onImpersonate}
                            disabled={!props.data.ssoId}
                            icon={<i className="fas fa-fw fa-user" />}
                        >
                            {t('Partner.impersonate')}
                        </Button>
                    </Col>
                )}
                {props.onDelete && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            onClick={props.onDelete}
                            icon={<i className="fas fa-fw fa-trash-alt" />}
                        >
                            {t('common.delete')}
                        </Button>
                    </Col>
                )}
            </Row>
        </div>
    );
}
