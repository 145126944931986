/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20010,
    InlineResponse20010FromJSON,
    InlineResponse20010ToJSON,
    InlineResponse20011,
    InlineResponse20011FromJSON,
    InlineResponse20011ToJSON,
    ReadOnlyUserSerializer,
    ReadOnlyUserSerializerFromJSON,
    ReadOnlyUserSerializerToJSON,
} from '../models';

export interface EwTruckDriverDriverListRequest {
    limit?: number;
    offset?: number;
}

export interface EwTruckDriverDriverRetrieveLoginTokenRequest {
    id: number;
}

export interface EwTruckDriverMonitoredObjectListRequest {
    limit?: number;
    offset?: number;
}

/**
 * no description
 */
export class EwTruckDriverApi extends runtime.BaseAPI {

    /**
     */
    async ewTruckDriverDriverListRaw(requestParameters: EwTruckDriverDriverListRequest): Promise<runtime.ApiResponse<InlineResponse20010>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ew-truck-driver/driver/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20010FromJSON(jsonValue));
    }

    /**
     */
    async ewTruckDriverDriverList(requestParameters: EwTruckDriverDriverListRequest): Promise<InlineResponse20010> {
        const response = await this.ewTruckDriverDriverListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ewTruckDriverDriverRetrieveLoginTokenRaw(requestParameters: EwTruckDriverDriverRetrieveLoginTokenRequest): Promise<runtime.ApiResponse<ReadOnlyUserSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ewTruckDriverDriverRetrieveLoginToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ew-truck-driver/driver/{id}/login-token/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserSerializerFromJSON(jsonValue));
    }

    /**
     */
    async ewTruckDriverDriverRetrieveLoginToken(requestParameters: EwTruckDriverDriverRetrieveLoginTokenRequest): Promise<ReadOnlyUserSerializer> {
        const response = await this.ewTruckDriverDriverRetrieveLoginTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ewTruckDriverMonitoredObjectListRaw(requestParameters: EwTruckDriverMonitoredObjectListRequest): Promise<runtime.ApiResponse<InlineResponse20011>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ew-truck-driver/monitored-object/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20011FromJSON(jsonValue));
    }

    /**
     */
    async ewTruckDriverMonitoredObjectList(requestParameters: EwTruckDriverMonitoredObjectListRequest): Promise<InlineResponse20011> {
        const response = await this.ewTruckDriverMonitoredObjectListRaw(requestParameters);
        return await response.value();
    }

}
