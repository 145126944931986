/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeFromJSONTyped,
    ExternalDeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivityIntervalExternalDevice
 */
export interface ActivityIntervalExternalDevice {
    /**
     * 
     * @type {string}
     * @memberof ActivityIntervalExternalDevice
     */
    muDataOriginalJstr?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityIntervalExternalDevice
     */
    serialNumber: string;
    /**
     * 
     * @type {ExternalDeviceType}
     * @memberof ActivityIntervalExternalDevice
     */
    deviceType: ExternalDeviceType;
    /**
     * 
     * @type {string}
     * @memberof ActivityIntervalExternalDevice
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityIntervalExternalDevice
     */
    source: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityIntervalExternalDevice
     */
    tfrom: Date;
    /**
     * 
     * @type {object}
     * @memberof ActivityIntervalExternalDevice
     */
    metadata: object;
    /**
     * 
     * @type {object}
     * @memberof ActivityIntervalExternalDevice
     */
    data?: object;
}

export function ActivityIntervalExternalDeviceFromJSON(json: any): ActivityIntervalExternalDevice {
    return ActivityIntervalExternalDeviceFromJSONTyped(json, false);
}

export function ActivityIntervalExternalDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityIntervalExternalDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'muDataOriginalJstr': !exists(json, 'mu_data_original_jstr') ? undefined : json['mu_data_original_jstr'],
        'serialNumber': json['serial_number'],
        'deviceType': ExternalDeviceTypeFromJSON(json['device_type']),
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'source': json['source'],
        'tfrom': (new Date(json['tfrom'])),
        'metadata': json['metadata'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function ActivityIntervalExternalDeviceToJSON(value?: ActivityIntervalExternalDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mu_data_original_jstr': value.muDataOriginalJstr,
        'serial_number': value.serialNumber,
        'device_type': ExternalDeviceTypeToJSON(value.deviceType),
        'device_id': value.deviceId,
        'source': value.source,
        'tfrom': (value.tfrom.toISOString()),
        'metadata': value.metadata,
        'data': value.data,
    };
}


