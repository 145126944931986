import { RouteOverviewFilterModel, RouteOverviewTableData, SearchData } from '../RouteOverviewModule';
import RouteOverviewBar from './RouteOverviewBar';
import RouteOverviewSearch from './RouteOverviewSearch';
import RouteOverviewTable from './RouteOverviewTable';
import RouteOverviewVehicleSelect from './RouteOverviewVehicleSelect';
import Confirm, { MessageType } from 'common/components/Confirm';
import { useTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import { StazkaExportModalModel, StazkaExportModalStateModel } from 'common/model/stazka-export-modal';
import StazkaExportModal from 'common/components/StazkaExportModal';
import { TransportModel, VehicleWithAvailability } from 'common/model/transports';
import HelperModal from 'common/components/HelperModal';
import { DateRange } from 'common/model/date-time';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { VehicleStateObject } from 'generated/graphql';

interface Props {
    bar: {
        backUrl?: boolean;
        dateChanged?: boolean;
        filterOpen?: boolean;
    };
    filter: RouteOverviewFilterModel;
    helper?: {
        content: string;
    };
    search?: SearchData;
    dateRange: DateRange;
    remove?: string;
    vehicleRemove?: boolean;
    table: {
        data?: RouteOverviewTableData;
        monitoredObjects?: ReadOnlyMonitoredObjectFeSb[];
        vehicleStates?: VehicleStateObject[];
        loading: boolean;
        processing: boolean;
        selectedId?: string;
    };
    vehiclesSelect?: {
        vehicles: VehicleWithAvailability[];
        loading: boolean;
        vehicleId?: string;
        selectedTransport?: string;
        selectedTransportData?: TransportModel;
        puescMonitoredObjects: number[];
    };
    demoMode?: boolean;
    roles: Role[];
    stazkaExportModal: StazkaExportModalModel;
    removeTransportLoading: boolean;
    removeVehicleLoading: boolean;
    addVehicleLoading: boolean;
    onBarBackUrlClick?: () => void;
    onBarFilterClick?: () => void;
    onBarResetClick?: () => void;
    onBarHelperClick?: () => void;
    onBarSearchClick?: () => void;
    onBarStazkaExportClick?: () => void;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onFilterCancel: () => void;
    onFilterConfirm: (driversChecked: string[], vehiclesChecked: string[], transitStatesChecked: string[]) => void;
    onHelperClose?: () => void;
    onSearchSubmit?: (data: SearchData) => void;
    onTableAddVehicleClick?: (transportId: string) => void;
    onTableRemoveVehicleClick?: (transportId: string, vehicleId?: string) => void;
    onTableRemoveTransportClick?: (transportId: string) => void;
    onTableRowClick?: (id: string, vehicleId: string) => void;
    onTableRowExpand?: (type: keyof RouteOverviewTableData) => void;
    onTableSeeInJourneysClick?: (transportId: string) => void;
    onTableSetActiveClick?: (transportId: string) => void;
    onTableSetCompleteClick?: (transportId: string) => void;
    onTableStazkaExportClick?: (transport: TransportModel) => void;
    onTableTrackOnMapClick?: (vehicleRN: string) => void;
    onVehicleSelectCancel: () => void;
    onVehicleSelectConfirm: (id: string) => void;
    onRemoveConfirm?: () => void;
    onRemoveVehicleConfirm?: () => void;
    onRemoveVehicleCancel?: () => void;
    onRemoveCancel?: () => void;
    onStazkaExportModalCancel?: () => void;
    onStazkaExportModalConfirm?: (data: StazkaExportModalStateModel) => void;
}

function RouteOverview(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="route-overview page">
            <RouteOverviewBar
                backUrl={props.bar.backUrl}
                dateChanged={props.bar.dateChanged}
                dateRange={props.dateRange}
                filter={props.filter}
                filterOpen={props.bar.filterOpen}
                roles={props.roles}
                demoMode={props.demoMode}
                onDateRangeChange={props.onDateRangeChange}
                onBackUrlClick={props.onBarBackUrlClick}
                onResetClick={props.onBarResetClick}
                onFilterClick={props.onBarFilterClick}
                onHelperClick={props.onBarHelperClick}
                onStazkaExportClick={props.onBarStazkaExportClick}
                onFilterCancel={props.onFilterCancel}
                onFilterConfirm={props.onFilterConfirm}
            />

            {props.search && <RouteOverviewSearch search={props.search} onSubmit={props.onSearchSubmit} />}

            {props.vehiclesSelect?.selectedTransport && (
                <RouteOverviewVehicleSelect
                    addVehicleLoading={props.addVehicleLoading}
                    vehiclesLoading={props.vehiclesSelect.loading}
                    puescTransport={props.vehiclesSelect.selectedTransportData?.puesc}
                    puescMonitoredObjects={props.vehiclesSelect.puescMonitoredObjects}
                    vehicles={props.vehiclesSelect.vehicles}
                    onCancel={props.onVehicleSelectCancel}
                    onConfirm={props.onVehicleSelectConfirm}
                />
            )}

            {props.remove && (
                <Confirm
                    danger
                    header={t('RouteOverviewTable.deleteHeader')}
                    message={t('RouteOverviewTable.deleteConfirm')}
                    type={MessageType.WARNING}
                    loading={props.removeTransportLoading}
                    confirmLabel={t('common.delete')}
                    onCancel={props.onRemoveCancel}
                    onConfirm={props.onRemoveConfirm}
                />
            )}

            {props.vehicleRemove && (
                <Confirm
                    header={t('RouteOverviewTable.deleteHeaderVehicle')}
                    message={t('RouteOverviewTable.deleteConfirmVehicle')}
                    type={MessageType.WARNING}
                    loading={props.removeVehicleLoading}
                    onCancel={props.onRemoveVehicleCancel}
                    onConfirm={props.onRemoveVehicleConfirm}
                />
            )}

            <RouteOverviewTable
                data={props.table.data}
                loading={props.table.loading}
                processing={props.table.processing}
                selectedId={props.table.selectedId}
                monitoredObjects={props.table.monitoredObjects}
                vehicleStates={props.table.vehicleStates}
                demoMode={props.demoMode}
                roles={props.roles}
                vehiclesSelectedTransportId={props.vehiclesSelect?.selectedTransport}
                onAddVehicleClick={props.onTableAddVehicleClick}
                onRemoveVehicleClick={props.onTableRemoveVehicleClick}
                onClickRowExpand={props.onTableRowExpand}
                onRemoveTransportClick={props.onTableRemoveTransportClick}
                onStazkaExportClick={props.onTableStazkaExportClick}
                onRowClick={props.onTableRowClick}
                onSeeInJourneys={props.onTableSeeInJourneysClick}
                onSetActiveClick={props.onTableSetActiveClick}
                onSetCompleteClick={props.onTableSetCompleteClick}
                onTrackOnMapClick={props.onTableTrackOnMapClick}
            />

            <StazkaExportModal
                {...props.stazkaExportModal}
                onCancel={props.onStazkaExportModalCancel}
                onConfirm={props.onStazkaExportModalConfirm}
            />

            <HelperModal
                name="route-overview"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}

export default RouteOverview;
