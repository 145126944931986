import { MaintenanceNotificationInDb, NotificationTypeEnum } from 'generated/backend-api';
import moment from 'moment';
import { maintenanceTasks } from './maintenance';

export const maintenanceNotifications: MaintenanceNotificationInDb[] = [
    {
        id: '1',
        clientId: 100,
        notificationType: NotificationTypeEnum.App,
        notified: false,
        createdAt: moment()
            .set({
                hour: 6,
                minute: 0,
                second: 0
            })
            .toDate(),
        taskId: maintenanceTasks[0].id ?? '',
        acknowledged: false,
        event: {
            id: '1',
            eventType: 'vehicle',
            maintenanceTaskWas: {
                state: maintenanceTasks[0].state ?? 'duesoon',
                taskTypeName: maintenanceTasks[0].taskType?.name ?? '',
                dueDateLeftSeconds: 0,
                monitoredObjectId: maintenanceTasks[0].monitoredObject?.toString(),
                dueMileageLeftKilometers: maintenanceTasks[0].dueMileageLeftKilometers
            },
            maintenanceTaskNew: {
                state: maintenanceTasks[0].state ?? 'overdue',
                taskTypeName: maintenanceTasks[0].taskType?.name ?? '',
                dueDateLeftSeconds: maintenanceTasks[0].dueDateLeftSeconds ?? 0,
                monitoredObjectId: maintenanceTasks[0].monitoredObject?.toString(),
                dueMileageLeftKilometers: maintenanceTasks[0].dueMileageLeftKilometers
            }
        }
    },
    {
        id: '2',
        clientId: 100,
        notificationType: NotificationTypeEnum.App,
        notified: true,
        taskId: maintenanceTasks[1].id ?? '',
        createdAt: moment()
            .set({
                hour: 6,
                minute: 0,
                second: 0
            })
            .toDate(),
        acknowledged: false,
        event: {
            id: '1',
            eventType: 'vehicle',
            maintenanceTaskWas: {
                state: maintenanceTasks[1].state ?? 'active',
                taskTypeName: maintenanceTasks[1].taskType?.name ?? '',
                dueDateLeftSeconds: 0,
                monitoredObjectId: maintenanceTasks[1].monitoredObject?.toString()
            },
            maintenanceTaskNew: {
                state: maintenanceTasks[1].state ?? 'overdue',
                taskTypeName: maintenanceTasks[1].taskType?.name ?? '',
                dueDateLeftSeconds: maintenanceTasks[1].dueDateLeftSeconds ?? 0,
                monitoredObjectId: maintenanceTasks[1].monitoredObject?.toString()
            }
        }
    }
];
