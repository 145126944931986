import { RouteCostsDetailsCountrySegment, RouteOptionsSygic, TransportPlace } from 'generated/backend-api';
import { WithTranslation, withTranslation } from 'react-i18next';
import PlannerContentContainer from '../PlannerContentContainer';
import PlannerCard from '../PlannerCard';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { Button, Checkbox } from 'common/components';
import { KM } from 'domain-constants';
import { AvailableCurrencies } from 'common/model/currency';
import { WithLogic } from 'App';
import numeral from 'numeral';
import { TollField } from 'resources/images/planner';
import { getCountryIso2FromIso3, getCountryIso3 } from 'common/model/countries';
import { AvoidOptionsSygicType } from 'modules/routing/planner-new/planner-logic';
import { cloneDeep } from '@apollo/client/utilities';
import cn from 'classnames';
import { PuescWarning } from 'modules/routing/planner/ui/PuescWarning/PuescWarning';
import qa from 'qa-selectors';

export interface RouteOptions {
    routeOptionsSygic: RouteOptionsSygic;
}

interface Props extends WithTranslation, WithLogic {
    transportPlaces?: TransportPlace[] | undefined;
    currency?: AvailableCurrencies;
    routeOptionsSygic?: RouteOptionsSygic;
    avoidOptionsCountries?: string[];
    onSubmit?: (options: RouteOptions) => void;
    onEscape?: (options: RouteOptions) => void;
}

interface TollCountry {
    countryCode: string;
    segments: RouteCostsDetailsCountrySegment[];
    possibleAvoidsSygic: Array<string>;
    possible: boolean;
}

const RouteOptionsCard = (props: Props) => {
    const [rerender, setRerender] = useState(0);
    const [avoidOptionsSygic, setAvoidOptionsSygic] = useState(props.routeOptionsSygic?.avoidOptionsSygic ?? []);
    const [_avoidOptionsSygic] = useState(
        props.routeOptionsSygic?.avoidOptionsSygic ? cloneDeep(props.routeOptionsSygic?.avoidOptionsSygic) : []
    );
    const [collapsed, setCollapsed] = useState(props.logic.plannerLogic().routeOptionsCollapsedCountries);
    const [collapsedWidth, setCollapsedWidth] = useState(false);
    let tollCountries: TollCountry[] = [];

    const handlerSubmitClick = () => {
        const options: RouteOptions = {
            routeOptionsSygic: {
                avoidOptionsSygic: avoidOptionsSygic
            }
        };
        props.onSubmit?.(options);
    };

    const handleToggleAvoid = (value: boolean, country: string, avoidType: AvoidOptionsSygicType) => {
        if (value) {
            _addAvoid(country, avoidType);
        } else {
            _removeAvoid(country, avoidType);
        }
        handleContentWidthCollapsed(country, !value);
        setRerender(rerender + 1);
    };

    const _addAvoid = (country: string, avoidType: AvoidOptionsSygicType) => {
        if (!_avoidExists(country, avoidType)) {
            avoidOptionsSygic.push(country + ':' + avoidType);
            setAvoidOptionsSygic(avoidOptionsSygic);
        }
    };

    const _removeAvoid = (country: string, avoidType: AvoidOptionsSygicType) => {
        const index = avoidOptionsSygic.indexOf(country + ':' + avoidType);
        if (index > -1) {
            avoidOptionsSygic.splice(index, 1);
            setAvoidOptionsSygic(avoidOptionsSygic);
        }
    };

    const _avoidExists = (
        country: string,
        avoidType: AvoidOptionsSygicType,
        options: string[] | undefined = undefined
    ): boolean => {
        if (options) {
            return options.indexOf(country + ':' + avoidType) > -1;
        } else {
            return avoidOptionsSygic.indexOf(country + ':' + avoidType) > -1;
        }
    };

    const _transformAvoid = (avoid: string): { countryCode: string; type: AvoidOptionsSygicType } => {
        const avoidData = cloneDeep(avoid).split(':');
        return {
            countryCode: avoidData[0],
            type: avoidData[1] as AvoidOptionsSygicType
        };
    };

    const handleContentWidthCollapsed = (country: string, value: boolean) => {
        const ex = collapsed;
        if (!value) {
            ex.push(country);
            setCollapsed(ex);
        } else {
            if (collapsed.indexOf(country) > -1) {
                ex.splice(collapsed.indexOf(country), 1);
                setCollapsed(ex);
            }
        }
        props.logic.plannerLogic().routeOptionsCollapsedCountries = ex.slice();
        setCollapsedWidth(ex.length === tollCountries.length);
    };

    const handleEscape = () => {
        const options: RouteOptions = {
            routeOptionsSygic: {
                avoidOptionsSygic: avoidOptionsSygic
            }
        };
        props.onEscape?.(options);
    };

    const { t } = props;
    let sumToll = 0;
    const tollCurrency = props.currency ?? AvailableCurrencies.EUR;

    props.transportPlaces?.forEach(place => {
        if (place.routeCosts?.countries) {
            place.routeCosts?.countries?.forEach(country => {
                if (country.segments) {
                    const tollCountry = tollCountries.find(c => c.countryCode === country.countryCode);
                    if (!tollCountry) {
                        tollCountries.push({
                            countryCode: country.countryCode,
                            segments: country.segments,
                            possibleAvoidsSygic: [],
                            possible: false
                        });
                    } else {
                        tollCountry.segments = tollCountry.segments.concat(country.segments);
                    }
                }
                sumToll = sumToll + country.price.price;
            });
        }
    });

    props?.avoidOptionsCountries?.forEach((avoid: string) => {
        const country = _transformAvoid(avoid);
        const countryCode = getCountryIso2FromIso3(country.countryCode);

        if (!tollCountries.find(country => country.countryCode === countryCode)) {
            tollCountries.push({
                countryCode: countryCode,
                segments: [],
                possibleAvoidsSygic: [],
                possible: false
            });
        }
    });

    if (props?.routeOptionsSygic?.possibleAvoidsSygic) {
        for (const key in props?.routeOptionsSygic?.possibleAvoidsSygic) {
            const countryCode = getCountryIso2FromIso3(key);
            const country = tollCountries.find(country => country.countryCode === countryCode);
            if (country) {
                country.possibleAvoidsSygic = props?.routeOptionsSygic?.possibleAvoidsSygic[key];
            } else {
                tollCountries.push({
                    countryCode: countryCode,
                    segments: [],
                    possibleAvoidsSygic: props?.routeOptionsSygic?.possibleAvoidsSygic[key],
                    possible: true
                });
            }
        }
    }

    // sumToll = props.logic.plannerLogic().getPriceByCurrency(sumToll ?? 0, props.currency ?? AvailableCurrencies.EUR);
    const tolls = tollCountries.map(country => {
        let totalDistance = 0;
        let totalPrice = 0;

        const details = country.segments
            .map((segment, index) => {
                const price = props.logic
                    .plannerLogic()
                    .getPriceByCurrency(segment.price?.price ?? 0, tollCurrency ?? AvailableCurrencies.EUR);
                totalPrice = totalPrice + price;
                totalDistance = totalDistance + (segment.tollDistance ?? 0);

                //segment.name?.replace(/(<([^>]+)>|\d*_)/gi, '')
                return (
                    <Row key={`country-${country.countryCode}-segment-${index}`}>
                        <Col span={14}>{segment.name}</Col>
                        <Col span={5}>
                            {numeral(price).format('0,0.00')} {tollCurrency}
                        </Col>
                        <Col className={cn({ 'empty-value': segment.tollDistance === 0 })} span={5}>
                            {segment.tollDistance
                                ? `${numeral(segment.tollDistance / 1000).format('0,0.0')} ${KM}`
                                : '-'}
                        </Col>
                    </Row>
                );
            })
            .filter(a => a);

        const useAvoidCountry =
            props.transportPlaces?.findIndex(place => {
                const code: string =
                    place.addressStructured && place.addressStructured.length > 0
                        ? place.addressStructured[0].countryCode
                        : '';

                return code === country.countryCode;
            }) === -1;

        const isoCountry = getCountryIso3(country.countryCode).toLowerCase();
        const isAvoidCountry =
            _avoidExists(isoCountry, AvoidOptionsSygicType.country) ||
            (_avoidExists(isoCountry, AvoidOptionsSygicType.country, props.avoidOptionsCountries) &&
                _avoidExists(isoCountry, AvoidOptionsSygicType.country));

        const isHighways =
            _avoidExists(isoCountry, AvoidOptionsSygicType.highways, _avoidOptionsSygic) ||
            (_avoidExists(isoCountry, AvoidOptionsSygicType.highways, _avoidOptionsSygic) &&
                _avoidExists(isoCountry, AvoidOptionsSygicType.highways, _avoidOptionsSygic)) ||
            country.possibleAvoidsSygic.includes('highways');

        const isFerries =
            _avoidExists(isoCountry, AvoidOptionsSygicType.ferries, _avoidOptionsSygic) ||
            (_avoidExists(isoCountry, AvoidOptionsSygicType.ferries, _avoidOptionsSygic) &&
                _avoidExists(isoCountry, AvoidOptionsSygicType.ferries, _avoidOptionsSygic)) ||
            country.possibleAvoidsSygic.includes('ferries');

        const loadCostData = !(isFerries && country.segments.length === 0);

        return (
            <PlannerContentContainer
                key={country.countryCode}
                title={
                    <Row align="middle">
                        <Col span={1}>
                            <span
                                className={`flag-icon flag-icon-squared flag-icon-${country.countryCode.toLowerCase()}`}
                            />
                        </Col>
                        <Col className="country-title-container" span={!loadCostData ? 11 : 13}>
                            <span className="country-title">{t(`common.countries.${country.countryCode}`)}</span>
                            {useAvoidCountry && (
                                <Button
                                    type="text"
                                    className="avoid-country"
                                    onClick={e => {
                                        handleToggleAvoid(
                                            !_avoidExists(isoCountry, AvoidOptionsSygicType.country),
                                            isoCountry,
                                            AvoidOptionsSygicType.country
                                        );
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    qa={
                                        _avoidExists(isoCountry, AvoidOptionsSygicType.country)
                                            ? qa.planner.routeOptions.includeCountry
                                            : qa.planner.routeOptions.avoidCountry
                                    }
                                >
                                    {_avoidExists(isoCountry, AvoidOptionsSygicType.country)
                                        ? t('Planner.RouteOptionsCard.includeCountry')
                                        : t('Planner.RouteOptionsCard.avoidCountry')}
                                </Button>
                            )}
                        </Col>
                        <Col span={!loadCostData ? 12 : 10}>
                            <Row className="toll-price" align="middle">
                                {!isAvoidCountry && country.segments.length > 0 && (
                                    <Col className="toll-after-row">
                                        <img className="toll-icon" src={TollField} alt="toll-icon" />
                                        {`${numeral(totalDistance / 1000).format('0,0.0')} ${KM} / ${numeral(
                                            totalPrice
                                        ).format('0,0.00')} ${props.currency}`}
                                    </Col>
                                )}
                                {!loadCostData && (
                                    <Col>
                                        <Row className="toll-warning-after-row" justify="end" align="middle">
                                            <Col>
                                                <PuescWarning type="warning" size="small" />
                                                {t('Planner.RouteOptionsCard.tollWarning')}
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                }
                className={cn('', {
                    'avoid-country-container': isAvoidCountry
                })}
                showMore={true}
                showLess={country.segments.length > 2}
                moreHeight={130}
                expandable={!isAvoidCountry}
                expanded={
                    collapsed.indexOf(isoCountry) > -1 && !isAvoidCountry ? true : isAvoidCountry ? false : undefined
                }
                collapseHeight={isAvoidCountry ? 0 : 30}
                collapsed={isAvoidCountry ? true : undefined}
                onExpandedClick={(state: boolean) => {
                    if (!isAvoidCountry) {
                        handleContentWidthCollapsed(isoCountry, state);
                    }
                }}
            >
                {!isAvoidCountry && (isHighways || isFerries) && (
                    <Row className="avoid-row" align="middle">
                        <Col className="avoid-row-bold">{t('Planner.RouteOptionsCard.avoid')}:</Col>
                        {isHighways && (
                            <Col>
                                <Checkbox
                                    defaultChecked={_avoidExists(isoCountry, AvoidOptionsSygicType.highways)}
                                    onChange={e => {
                                        handleToggleAvoid(e.target.checked, isoCountry, AvoidOptionsSygicType.highways);
                                    }}
                                >
                                    <span>{t('Planner.RouteOptionsCard.highways')}</span>
                                </Checkbox>
                            </Col>
                        )}
                        {isFerries && (
                            <Col>
                                <Checkbox
                                    defaultChecked={_avoidExists(isoCountry, AvoidOptionsSygicType.ferries)}
                                    onChange={e => {
                                        handleToggleAvoid(e.target.checked, isoCountry, AvoidOptionsSygicType.ferries);
                                    }}
                                >
                                    <span>{t('Planner.RouteOptionsCard.ferries')}</span>
                                </Checkbox>
                            </Col>
                        )}
                    </Row>
                )}
                {details.length > 0 && (
                    <Row className="route-country-detail">
                        <Row className="route-country-detail-header">
                            <Col span={14}>{t('Planner.RouteOptionsCard.segment').toUpperCase()}</Col>
                            <Col span={5}>{t('common.toll').toUpperCase()}</Col>
                            <Col span={5}>{t('common.distance').toUpperCase()}</Col>
                        </Row>
                        {details}
                    </Row>
                )}
            </PlannerContentContainer>
        );
    });

    sumToll = props.logic.plannerLogic().getPriceByCurrency(sumToll, tollCurrency);

    if (!collapsedWidth && collapsed.length > 0) {
        if (collapsed.length === tollCountries.length) {
            setCollapsedWidth(true);
        }
    }

    return (
        <PlannerCard
            className={cn('route-options-card', {
                'route-options-card-collapsed-width': collapsedWidth
            })}
            title={t('Planner.RouteOptionsCard.title')}
            fullContent={true}
            footer={
                tolls.length > 0 && (
                    <Row className="total-cost">
                        <Col span={16}>{t('Planner.RouteOptionsCard.tollSumar')}</Col>
                        <Col span={8}>
                            {numeral(sumToll).format('0,0.00')} {tollCurrency}
                        </Col>
                    </Row>
                )
            }
            onClose={handlerSubmitClick}
            onEscape={handleEscape}
        >
            <Row className="toll-row">{tolls}</Row>
        </PlannerCard>
    );
};

export default withTranslation()(RouteOptionsCard);
