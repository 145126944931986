import { google } from 'google-maps';
import moment from 'moment';
import { renderToString } from 'react-dom/server';

import { BorderCrossTwoDirections } from 'generated/backend-api';
import BorderCrossesInfowindow from 'modules/map/components/BorderCrossesInfowindow';
import * as borderCrossesIcon from 'resources/images/border-crosses';
import { BorderCrossingMarker } from './border-crossing-marker';

const zooms = [4, 6, 8, 10]; // ExtraSmall, Small, Medium, Big

export interface BorderCrossesMarkerProps {
    zIndex: number;
    size: number;
    anchorX: number;
    anchorY: number;
    pixelOffset: [number, number];
    url: string;
}

export class BorderCrossesMapController {
    private _map?: google.maps.Map;
    private _markers: BorderCrossingMarker[];
    private _infoWindow?: google.maps.InfoWindow;
    private _google: google;
    private _mapClickListener?: google.maps.MapsEventListener;
    private _mapZoomListener?: google.maps.MapsEventListener;

    constructor(google: google, map?: google.maps.Map) {
        this._map = map;
        this._google = google;
        this._markers = [];
    }

    destroy(): void {
        this._removeMarkers();
        this._removeInfowindow();
        this._mapClickListener && this._google.maps.event.removeListener(this._mapClickListener);
        this._mapZoomListener && this._google.maps.event.removeListener(this._mapZoomListener);
    }

    setData(data: BorderCrossTwoDirections[]): void {
        this._removeMarkers();
        this._markers = data.map(borderCrosses => this._createBorderCrossesMarker(borderCrosses));
        this._mapZoomListener && this._google.maps.event.removeListener(this._mapZoomListener);
        this._mapZoomListener = this._map?.addListener('zoom_changed', () => this._onZoomChange(data));
        this._drawMarkers();
    }

    private _drawMarkers(): void {
        this._markers?.forEach(marker => marker.setMap(this._map ?? null));
    }

    private _removeMarkers(): void {
        this._markers?.forEach(marker => marker.setMap(null));
        this._markers = [];
    }

    private _createBorderCrossesMarker(borderCrosses: BorderCrossTwoDirections): BorderCrossingMarker {
        const position: google.maps.LatLng = new this._google.maps.LatLng({
            lat: borderCrosses.direction1!.centroid[0], // Direction 1 all the time here BE implementation
            lng: borderCrosses.direction1!.centroid[1] // Direction 1 all the time here BE implementation
        });
        const borderCrossesProps: BorderCrossesMarkerProps = this._getBorderCrossesProps(
            borderCrosses?.direction1?.delay,
            borderCrosses?.direction2?.delay,
            borderCrosses?.direction1?.lastData,
            borderCrosses?.direction2?.lastData
        );

        const marker = new BorderCrossingMarker(this._google, position, borderCrossesProps);

        marker.onBorderCrossingClick = () => {
            this._createInfowindow(marker, borderCrosses, borderCrossesProps);
        };

        return marker;
    }

    private _createInfowindow(
        marker: BorderCrossingMarker,
        borderCrosses: BorderCrossTwoDirections,
        borderCrossesProps: BorderCrossesMarkerProps,
        shouldFocus: boolean = true
    ) {
        const map = this._map;
        this._removeInfowindow();

        this._infoWindow = new this._google.maps.InfoWindow({
            content: renderToString(<BorderCrossesInfowindow data={borderCrosses} />),
            pixelOffset: new this._google.maps.Size(
                borderCrossesProps.pixelOffset[0],
                borderCrossesProps.pixelOffset[1]
            ),
            zIndex: 99999,
            disableAutoPan: !shouldFocus
        });

        this._infoWindow.open({
            anchor: marker,
            map,
            shouldFocus
        } as google.maps.InfoWindowOpenOptions);

        this._mapClickListener = this._map?.addListener('click', () => {
            this._removeInfowindow();
        });
    }

    private _removeInfowindow() {
        this._infoWindow?.close();
        this._infoWindow = undefined;
    }

    private _onZoomChange(data: BorderCrossTwoDirections[]) {
        // redraw markers on specific zoom change
        if (zooms.includes(this._map?.getZoom() ?? 0)) {
            this._removeMarkers();
            this._markers = data.map(borderCrosses => this._createBorderCrossesMarker(borderCrosses));
            this._drawMarkers();
        }
    }

    private _getBorderCrossesProps(
        delay1: number = 0,
        delay2: number = 0,
        lastData1: Date | undefined,
        lastData2: Date | undefined
    ): BorderCrossesMarkerProps {
        const valid1 = lastData1 && moment(lastData1).isAfter(moment().subtract(360, 'minutes'));
        const valid2 = lastData2 && moment(lastData2).isAfter(moment().subtract(360, 'minutes'));
        const zoom: number = this._map?.getZoom() ?? zooms[0];
        const markerSize: 'ExtraSmall' | 'Small' | 'Medium' | 'Big' =
            zoom <= zooms[0] ? 'ExtraSmall' : zoom <= zooms[1] ? 'Small' : zoom <= zooms[2] ? 'Medium' : 'Big';
        if (valid1 || valid2) {
            if ((valid1 && delay1 > 90) || (valid2 && delay2 > 90)) {
                switch (markerSize) {
                    case 'ExtraSmall':
                        return {
                            zIndex: 3,
                            size: 16,
                            anchorX: 8,
                            anchorY: 8,
                            pixelOffset: [0, 36],
                            url: borderCrossesIcon[`red${markerSize}`]
                        };
                    case 'Small':
                        return {
                            zIndex: 3,
                            size: 22,
                            anchorX: 11,
                            anchorY: 11,
                            pixelOffset: [0, 38],
                            url: borderCrossesIcon[`red${markerSize}`]
                        };
                    case 'Medium':
                        return {
                            zIndex: 3,
                            size: 24,
                            anchorX: 12,
                            anchorY: 12,
                            pixelOffset: [0, 40],
                            url: borderCrossesIcon[`red${markerSize}`]
                        };
                    default:
                        return {
                            zIndex: 3,
                            size: 28,
                            anchorX: 14,
                            anchorY: 14,
                            pixelOffset: [0, 42],
                            url: borderCrossesIcon[`red${markerSize}`]
                        };
                }
            } else if ((valid1 && delay1 <= 90 && delay1 >= 30) || (valid2 && delay2 <= 90 && delay2 >= 30)) {
                switch (markerSize) {
                    case 'ExtraSmall':
                        return {
                            zIndex: 3,
                            size: 14,
                            anchorX: 7,
                            anchorY: 7,
                            pixelOffset: [0, 35],
                            url: borderCrossesIcon[`orange${markerSize}`]
                        };
                    case 'Small':
                        return {
                            zIndex: 3,
                            size: 18,
                            anchorX: 9,
                            anchorY: 9,
                            pixelOffset: [0, 37],
                            url: borderCrossesIcon[`orange${markerSize}`]
                        };
                    case 'Medium':
                        return {
                            zIndex: 3,
                            size: 20,
                            anchorX: 10,
                            anchorY: 10,
                            pixelOffset: [0, 38],
                            url: borderCrossesIcon[`orange${markerSize}`]
                        };
                    default:
                        return {
                            zIndex: 3,
                            size: 28,
                            anchorX: 14,
                            anchorY: 14,
                            pixelOffset: [0, 42],
                            url: borderCrossesIcon[`orange${markerSize}`]
                        };
                }
            } else {
                switch (markerSize) {
                    case 'ExtraSmall':
                        return {
                            zIndex: 1,
                            size: 12,
                            anchorX: 5,
                            anchorY: 5,
                            pixelOffset: [0, 33],
                            url: borderCrossesIcon[`green${markerSize}`]
                        };
                    case 'Small':
                        return {
                            zIndex: 1,
                            size: 14,
                            anchorX: 7,
                            anchorY: 7,
                            pixelOffset: [0, 35],
                            url: borderCrossesIcon[`green${markerSize}`]
                        };
                    case 'Medium':
                        return {
                            zIndex: 1,
                            size: 16,
                            anchorX: 8,
                            anchorY: 8,
                            pixelOffset: [0, 36],
                            url: borderCrossesIcon[`green${markerSize}`]
                        };
                    default:
                        return {
                            zIndex: 1,
                            size: 28,
                            anchorX: 14,
                            anchorY: 14,
                            pixelOffset: [0, 42],
                            url: borderCrossesIcon[`green${markerSize}`]
                        };
                }
            }
        } else {
            switch (markerSize) {
                case 'ExtraSmall':
                    return {
                        zIndex: 0,
                        size: 12,
                        anchorX: 5,
                        anchorY: 5,
                        pixelOffset: [0, 33],
                        url: borderCrossesIcon[`gray${markerSize}`]
                    };
                case 'Small':
                    return {
                        zIndex: 0,
                        size: 14,
                        anchorX: 7,
                        anchorY: 7,
                        pixelOffset: [0, 35],
                        url: borderCrossesIcon[`gray${markerSize}`]
                    };
                case 'Medium':
                    return {
                        zIndex: 0,
                        size: 16,
                        anchorX: 8,
                        anchorY: 8,
                        pixelOffset: [0, 36],
                        url: borderCrossesIcon[`gray${markerSize}`]
                    };
                default:
                    return {
                        zIndex: 0,
                        size: 28,
                        anchorX: 14,
                        anchorY: 14,
                        pixelOffset: [0, 42],
                        url: borderCrossesIcon[`gray${markerSize}`]
                    };
            }
        }
    }
}
