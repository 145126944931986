import cn from 'classnames';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Component } from 'react';

import { TrackingModel, MonitoredObjectTypeName, NoGPSStatus } from 'common/model/tracking';
import { StatusToIcon } from 'modules/tracking/components/TrackingTable';
import { Col, message, Row, Tag, Typography } from 'antd';
import { KM, L, KG, H, MAX_BIWEEKLY_RIDE, T, degC, V } from 'domain-constants';
import numeral from 'numeral';
import AlarmRemainsDuration from 'common/components/AlarmRemainsDuration';
import { ControlPanel } from '../MapControlsBar';
import { AlarmType, ExternalDeviceType } from 'generated/backend-api';
import { AlarmsInfoIcon, Button, Tooltip } from 'common/components';
import DriverScoreProgress from 'modules/statistics/modules/driver-behavior-new/components/DriverScoreProgress';
import qa from 'qa-selectors';
import { PlacesModel, TransportModel } from 'common/model/transports';
import { SettingsRerenderer } from 'common/utils/components/SettingsRerenderer';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { WithLogic, withLogicContext } from 'App';
import { AddressStructured, EFuelType } from 'generated/graphql';
import { toAddress } from 'common/utils/address';
import { Role } from 'logic/auth';
import * as vehicleDetailIcon from 'resources/images/vehicle-detail';
import { thermostat } from 'resources/images/common';
import { phone } from 'resources/images/common';
import { noGps } from 'resources/images/common';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as icons from 'resources/images/navbar';
import { trailersTrackingModelRN } from 'common/utils/vehicleState';
import { formatGps } from 'common/utils/textUtils';

const m2km = (meters: number) => Math.round(meters / 100) / 10;

enum PlaceTypeToImage {
    loading = '/img-svg/icn-load.svg',
    unloading = '/img-svg/icn-unload.svg',
    refueling = '/img-svg/refueling.svg',
    parking = '/img-svg/parking.svg',
    washing = '/img-svg/washing.svg'
}

interface Props extends WithTranslation, WithLogic {
    data: TrackingModel;
    panelType?: ControlPanel;
    transport?: TransportModel;
    simpleVehicleView?: boolean;
    roles: Role[];
    onCargo?: () => void;
    onMessage?: () => void;
    onNavigateToJourney?: () => void;
    onNavigateToDriverBehavior?: () => void;
    onNavigateToDriverBehaviorLightVehicles?: () => void;
    onNavigateToDispatcherBoard?: () => void;
    onNavigateToAetrReport?: (driverId?: string) => void;
    onTemperatureSensorClick?: (monitoredObjectId: string, sensorId: string) => void;
    onShowRealVsPlannedRouteOnMap?: () => void;
    onAddDestination?: () => void;
}

interface State {
    userIndex: number;
}

function formattedDuration(duration: moment.Duration | undefined): string {
    if (duration === undefined) return '';

    let format = '';
    if (Math.abs(duration.get('years')) > 0) format += `${Math.abs(duration.get('years'))}Y `;
    if (Math.abs(duration.get('months')) > 0) format += `${Math.abs(duration.get('months'))}M `;
    if (Math.abs(duration.get('days')) > 0) format += `${Math.abs(duration.get('days'))}D `;
    if (Math.abs(duration.get('hours')) > 0) format += `${Math.abs(duration.get('hours'))}h `;
    if (Math.abs(duration.get('minutes')) > 0) format += `${Math.abs(duration.get('minutes'))}m`;

    return format;
}
class ControlsVehicleCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userIndex: 0
        };
    }

    onUserIndexChange(userIndex: number) {
        this.setState({ userIndex });
    }

    handleCopyCoordinates = (_text: string, result: boolean) => {
        if (result) {
            message.info(this.props.t('VehicleCard.coordinatesCopy'));
        }
    };

    render() {
        const { t } = this.props;
        const coldchainAlarms = this.props.logic
            .alarms()
            .getAllAlarms()
            .filter(a =>
                [
                    AlarmType.TransportColdChainProfileTemperatureHigh,
                    AlarmType.TransportColdChainProfileTemperatureLow
                ].includes(a.alarmType)
            );
        const { tankSizeLimit, vehicleLimit, lightVehicleLimit } =
            this.props.logic?.conf.settings.tracking.smartFilter.fuelLow;

        const dailyUtilizationMaxHours =
            this.props?.data?.aetrData?.[this.state.userIndex]?.dailyUtilizationMaxHours ?? 0;
        const dailyUtilizationMaxSeconds = dailyUtilizationMaxHours * 3600;
        const dailyUtilizationLeft: number =
            this.props?.data?.aetrData?.[this.state.userIndex]?.dailyUtilizationLeft ?? 0;

        const dailyDriveMaxHours = this.props?.data?.aetrData?.[this.state.userIndex]?.dailyDriveMaxHours ?? 0;
        const dailyDriveMaxSeconds = dailyDriveMaxHours * 3600;
        const dailyDriveLeft: number = this.props?.data?.aetrData?.[this.state.userIndex]?.dailyDriveLeft ?? 0;

        const biweeklyDrivingLeft: number =
            this.props?.data?.aetrData?.[this.state.userIndex]?.biweeklyDrivingLeft ?? 0;

        const phoneData = this.props.data.contactsData?.find(
            d => d.driverId === this.props?.data?.aetrData?.[this.state.userIndex]?.driverId
        )?.driverPhone;

        const hasAetrData = (userIndex: number) => {
            return (
                this.props.roles.includes(Role.DRE_R) &&
                this.props?.data?.aetrData?.[userIndex]?.driverName &&
                this.props?.data.monitoredObjectType?.name === MonitoredObjectTypeName.VEHICLE &&
                this.props?.data?.aetrData?.[userIndex]?.activityStartTime
            );
        };

        const hasAnyAetrData = this.props.data?.aetrData?.find((data, index) => hasAetrData(index)) ? true : false;
        const hasAnyPhoneData =
            this.props.data?.contactsData && this.props.data?.contactsData.filter(d => d.driverPhone).length;

        const trailersManual = trailersTrackingModelRN([ExternalDeviceType.TrailerManual], this.props.data);
        const trailersDynamic = trailersTrackingModelRN([ExternalDeviceType.TrailerId], this.props.data);

        const temperatureSensors = this.props.data?.externalDevices?.filter(
            externalDevice =>
                externalDevice?.device_type === ExternalDeviceType.TemperatureSensor &&
                externalDevice?.data?.temperature !== undefined
        );

        return (
            <div className={cn('vehicle-card t-inverse')}>
                <div className="vehicle-card-bar t-bar t-medium t-border-bottom-inverse">
                    <div className="t-bar">
                        <div className="t-bar-item">
                            <Tooltip
                                placement="bottom"
                                title={`${this.props.data.RN}\n${trailersManual?.join(',')}\n${trailersDynamic?.join(
                                    ','
                                )}`}
                            >
                                <div className="vehicle-card-bar-identification t-bold">
                                    <span className="vehicle-card-rn" data-qa={qa.vehicleCard.rn}>
                                        {' '}
                                        {this.props.data.RN}
                                    </span>
                                    <span className="vehicle-card-bar-trailers">
                                        {trailersDynamic && trailersDynamic.length > 0 && (
                                            <>
                                                <img
                                                    src={vehicleDetailIcon.trailerDynamic}
                                                    alt={this.props.t('common.trailer')}
                                                />
                                                {trailersDynamic?.join(',')}
                                            </>
                                        )}
                                        {trailersManual && trailersManual.length > 0 && (
                                            <>
                                                <img
                                                    src={vehicleDetailIcon.trailer}
                                                    alt={this.props.t('common.trailer')}
                                                />
                                                {trailersManual?.join(',')}
                                            </>
                                        )}
                                    </span>
                                </div>
                            </Tooltip>

                            {this.props.data.destination && (
                                <Row className="vehicle-card-bar-destination">
                                    <Col flex="20px">
                                        <img
                                            src={vehicleDetailIcon.target}
                                            alt={t(`TrackingTable.destination`)}
                                            title={t(`TrackingTable.destination`)}
                                        />
                                    </Col>
                                    <Col
                                        flex="auto"
                                        className="vehicle-card-bar-destination-address"
                                        title={this._getLocationTitle(
                                            this.props.data.destination,
                                            this.props.data.destinationAddressStructured ?? []
                                        )}
                                    >
                                        <AddressFormatter
                                            defaultAddress={this.props.data.destination}
                                            addressStructured={this.props.data.destinationAddressStructured}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className="t-bar-item t-right">
                            {this.props.data.noGPSStatus === NoGPSStatus.SwitzerlandUnavailable ? (
                                <Tooltip
                                    placement="left"
                                    mouseLeaveDelay={0}
                                    title={this.props.t('TrackingTable.noGpsSwitzerland')}
                                >
                                    <img width="24" height="24" src={noGps} alt="no-gps" />
                                </Tooltip>
                            ) : (
                                <i
                                    className={cn(
                                        'vehicle-card-bar-status',
                                        'fas',
                                        `fa-${StatusToIcon[this.props.data.status]}-circle`,
                                        {
                                            't-text-secondary': this.props.data.status === StatusToIcon.play
                                        }
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {!this.props.simpleVehicleView && (
                    <div className="vehicle-card-data">
                        <div
                            className={cn('vehicle-detail', {
                                'with-last-update': this._showSinceLastUpdate(this._lastUpdateDuration())
                            })}
                        >
                            {this.props.data.monitoredObjectFuelType === EFuelType.Electro && (
                                <div className="vehicle-charge-status">
                                    <div className="vehicle-charge-status-left">
                                        <img src="/img-svg/icn-charge.svg" alt="charge" />
                                        {this.props.data.tank ?? 'N/A'}%
                                    </div>
                                    <div className="vehicle-charge-status-right">
                                        <img src="/img-svg/icn-plug.svg" alt="charge" />
                                        {this.props.data.charging?.on
                                            ? t('VehicleCard.charging')
                                            : t('VehicleCard.noCharging')}
                                    </div>
                                </div>
                            )}
                            <div className="vehicle-actual-data">
                                <Tag className="status" title={t('VehicleCard.status')}>
                                    {!this.props.data.invalid && (
                                        <>
                                            <i
                                                className={cn(
                                                    'status fa',
                                                    `fa-${StatusToIcon[this.props.data.status]}`
                                                )}
                                                aria-hidden="true"
                                            />{' '}
                                        </>
                                    )}
                                    <span title={moment(this.props.data.lastChangeState).format('L LT')}>
                                        {formattedDuration(this._lastChangeStateDuration())}
                                    </span>
                                </Tag>
                                <SettingsRerenderer lang addressIdentification>
                                    {this.props.data.noGPSStatus === NoGPSStatus.SwitzerlandUnavailable ? (
                                        <Tag
                                            title={`CH, ${t('TrackingTable.noGpsSwitzerlandLocation')}`}
                                            className="no-gps"
                                        >
                                            <img src={vehicleDetailIcon.place} alt="location" />
                                            CH, {t('TrackingTable.noGpsSwitzerlandLocation')}
                                        </Tag>
                                    ) : (
                                        <Tag
                                            className={cn('position location', {
                                                't-line-through': this.props.data.invalid
                                            })}
                                            title={this._getLocationTitle(
                                                this.props.data.location,
                                                this.props.data.addressStructured
                                            )}
                                        >
                                            <img src={vehicleDetailIcon.place} alt="location" />
                                            <AddressFormatter
                                                defaultAddress={this.props.data.location}
                                                addressStructured={this.props.data.addressStructured}
                                            />
                                        </Tag>
                                    )}
                                </SettingsRerenderer>
                                {this.props.data.ODO && (
                                    <Tag className="odo" title={t('VehicleCard.odometer')}>
                                        <span
                                            className={cn('location', {
                                                't-line-through': this.props.data.invalid
                                            })}
                                        >
                                            <img src={vehicleDetailIcon.trackChanges} alt="odo" />
                                            {numeral(m2km(Number(this.props.data.ODO))).format('0,0')} {KM}
                                        </span>
                                    </Tag>
                                )}

                                {this.props.data.tank &&
                                this.props.data.tank !== 0 &&
                                this.props.data.monitoredObjectFuelType !== EFuelType.Electro ? (
                                    <Tag className="tank" title={t('VehicleCard.fuelLeft')}>
                                        <span
                                            className={cn('location', {
                                                'vehicle-actual-data-alarm':
                                                    (this.props.data.tankSize &&
                                                        this.props.data.tank <
                                                            this.props.data.tankSize * tankSizeLimit) ||
                                                    (!this.props.data.tankSize &&
                                                        this.props.data.tank <
                                                            (this.props.data.monitoredObjectType?.name ===
                                                            MonitoredObjectTypeName.VEHICLE
                                                                ? vehicleLimit
                                                                : lightVehicleLimit))
                                            })}
                                        >
                                            <i className="fas fa-gas-pump" />
                                            {numeral(this.props.data.tank).format('0,0')}{' '}
                                            {this.props.data.monitoredObjectFuelType === EFuelType.LngCng ? KG : L}
                                        </span>
                                    </Tag>
                                ) : (
                                    ''
                                )}
                                {this.props.data.GPS && (
                                    <Tag
                                        className={cn('GPS', {
                                            'vehicle-actual-data-alarm': this.props.data.alarms.some(
                                                a =>
                                                    a.alarmType === AlarmType.UnavailableGps ||
                                                    a.alarmType === AlarmType.ConnectionLoss
                                            )
                                        })}
                                        title={t('VehicleCard.actualPosition')}
                                    >
                                        <img src={vehicleDetailIcon.compass} alt="GPS" />
                                        {`${formatGps(this.props.data.GPS.lat, 3)} ${formatGps(
                                            this.props.data.GPS.lng,
                                            3
                                        )}`}
                                        <CopyToClipboard
                                            text={`${formatGps(this.props.data.GPS.lat)}, ${formatGps(
                                                this.props.data.GPS.lng
                                            )}`}
                                            onCopy={this.handleCopyCoordinates}
                                        >
                                            <Tooltip
                                                className="tooltip"
                                                title={this.props.t('settings.copyToClipboard')}
                                            >
                                                <Button
                                                    className="vehicle-actual-data-copy-coordinates"
                                                    type="text"
                                                    size="small"
                                                    icon={
                                                        <img
                                                            src={icons.copyToClipboard}
                                                            style={{ width: 14 }}
                                                            alt="copyCoordinates"
                                                        />
                                                    }
                                                    qa={qa.vehicleCard.btnCopyCoordinatesToClipboard}
                                                />
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </Tag>
                                )}
                                {typeof this.props.data.carBattery?.volts === 'number' &&
                                this.props.data.carBattery?.volts >= 0 ? (
                                    <Tag
                                        className={cn('car-battery', {
                                            'vehicle-actual-data-alarm':
                                                this.props.data.carBattery.volts <
                                                (this.props.data.monitoredObjectType?.name ===
                                                MonitoredObjectTypeName.VEHICLE
                                                    ? this.props.logic?.conf.settings.tracking.batteryVoltageLowLimit
                                                          .vehicle
                                                    : this.props.logic?.conf.settings.tracking.batteryVoltageLowLimit
                                                          .lightVehicle)
                                        })}
                                        title={t('VehicleCard.batteryVoltage')}
                                    >
                                        <img src={vehicleDetailIcon.battery} alt="battery" />
                                        {numeral(this.props.data.carBattery.volts).format('0,0.0')} {V}
                                    </Tag>
                                ) : null}
                                {this.props.data?.GPS?.speed && !this.props.data.invalid && (
                                    <Tag className="speed" title={t('VehicleCard.speed')}>
                                        <i className="fas fa-shipping-fast" />
                                        {numeral(this.props.data.GPS.speed).format('0,0')}
                                        &nbsp;
                                        {KM}/{H}
                                    </Tag>
                                )}
                                {this.props.data.weight?.weight && (
                                    <Tag
                                        title={`${t('VehicleCard.trailerWeight')} ${numeral(
                                            this.props.data.weight.weight
                                        ).format('0,0')} ${KG}`}
                                    >
                                        <img src={vehicleDetailIcon.weight} alt="weight" />
                                        {this.props.data.weight.weight > 1000
                                            ? `${numeral(this.props.data.weight.weight / 1000).format('0.0')} ${T}`
                                            : `${numeral(this.props.data.weight.weight).format('0,0')} ${KG}`}
                                    </Tag>
                                )}
                                {this.props.data.adblue?.level && (
                                    <Tag
                                        title={`${t('VehicleCard.adblue')} ${numeral(
                                            this.props.data.adblue.level
                                        ).format('0,0')} %`}
                                    >
                                        <img src={vehicleDetailIcon.adblue} alt="adblue" />
                                        {`${numeral(this.props.data.adblue.level).format('0,0')} %`}
                                    </Tag>
                                )}
                                {this.props.data.alarms.length > 0 && (
                                    <Tag className="speed">
                                        <AlarmsInfoIcon alarms={this.props.data.alarms} />
                                    </Tag>
                                )}
                                {this.props.logic.auth().roles().includes(Role.CLD_R) &&
                                    temperatureSensors?.map(temperatureSensor => {
                                        if (temperatureSensor.data?.temperature !== undefined) {
                                            const rule = this.props.data.activeTransports?.[0]?.activeEventRules?.find(
                                                rule =>
                                                    rule?.name ===
                                                    `transport_cold_chain_zone_${
                                                        temperatureSensor.metadata?.sensor_zone ?? 1
                                                    }`
                                            );

                                            const thresholdAbove = rule?.config?.find(
                                                item => item.name === 'above_temperature_threshold'
                                            );
                                            const thresholdBelow = rule?.config?.find(
                                                item => item.name === 'below_temperature_threshold'
                                            );

                                            const ruleAlarm = coldchainAlarms.find(
                                                a =>
                                                    a.monitoredObjectId === this.props.data.id &&
                                                    a.uniqueData?.['temperature_sensor_serial_number'] ===
                                                        temperatureSensor?.metadata?.sensor_name
                                            );

                                            // !a.canceled &&
                                            //         !a.acknowledged
                                            return (
                                                <Tooltip
                                                    key={temperatureSensor.device_id}
                                                    title={
                                                        <>
                                                            <Row justify="space-between" align="middle" gutter={48}>
                                                                <Col className="temperature-sensor-name">
                                                                    {temperatureSensor?.metadata?.sensor_name}
                                                                </Col>
                                                                <Col
                                                                    className={cn('temperature-sensor-data', {
                                                                        'temperature-sensor-data-alarm':
                                                                            ruleAlarm &&
                                                                            !ruleAlarm.canceled &&
                                                                            !ruleAlarm.acknowledged,
                                                                        'temperature-sensor-data-alarm-warning':
                                                                            ruleAlarm &&
                                                                            !ruleAlarm.canceled &&
                                                                            ruleAlarm.acknowledged
                                                                    })}
                                                                >
                                                                    {`${numeral(
                                                                        temperatureSensor.data?.temperature
                                                                    ).format('0.0')} ${degC}`}
                                                                </Col>
                                                            </Row>
                                                            {rule && (
                                                                <Row justify="space-between" align="middle" gutter={48}>
                                                                    <Col className="temperature-sensor-name">
                                                                        {t('common.threshold')}
                                                                    </Col>
                                                                    <Col className="temperature-sensor-tooltip-threshold">
                                                                        <Row justify="end" gutter={4}>
                                                                            {thresholdBelow && !thresholdAbove && (
                                                                                <Col className="temperature-sensor-tooltip-threshold-text">
                                                                                    {t('common.below')}
                                                                                </Col>
                                                                            )}
                                                                            {thresholdBelow && (
                                                                                <Col>
                                                                                    {`${numeral(
                                                                                        thresholdBelow.value
                                                                                    ).format('0')} ${degC}`}
                                                                                </Col>
                                                                            )}
                                                                            {thresholdBelow && thresholdAbove && (
                                                                                <Col className="temperature-sensor-tooltip-threshold-text">
                                                                                    {t('common.to')}
                                                                                </Col>
                                                                            )}
                                                                            {!thresholdBelow && thresholdAbove && (
                                                                                <Col className="temperature-sensor-tooltip-threshold-text">
                                                                                    {t('common.above')}
                                                                                </Col>
                                                                            )}
                                                                            {thresholdAbove && (
                                                                                <Col>
                                                                                    {`${numeral(
                                                                                        thresholdAbove.value
                                                                                    ).format('0')} ${degC}`}
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            <Row justify="center">
                                                                <Col>
                                                                    <Button
                                                                        onClick={() =>
                                                                            this.props.onTemperatureSensorClick?.(
                                                                                this.props.data.id,
                                                                                temperatureSensor?.device_id ?? ''
                                                                            )
                                                                        }
                                                                        type="link"
                                                                    >
                                                                        {t('common.seeDetails')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    placement="rightTop"
                                                    overlayClassName="temperature-sensor-tooltip"
                                                >
                                                    <Tag
                                                        className={cn('temperature-sensor', {
                                                            'vehicle-actual-data-alarm':
                                                                ruleAlarm &&
                                                                !ruleAlarm.canceled &&
                                                                !ruleAlarm.acknowledged,
                                                            'vehicle-actual-data-alarm-warning':
                                                                ruleAlarm &&
                                                                !ruleAlarm.canceled &&
                                                                ruleAlarm.acknowledged
                                                        })}
                                                    >
                                                        <img
                                                            src={thermostat}
                                                            alt="temperature-sensor"
                                                            height="20px"
                                                            width="20px"
                                                        />
                                                        {`${numeral(temperatureSensor.data?.temperature).format(
                                                            '0.0'
                                                        )} ${degC}`}
                                                    </Tag>
                                                </Tooltip>
                                            );
                                        }
                                        return undefined;
                                    })}
                            </div>
                            {this._showSinceLastUpdate(this._lastUpdateDuration()) && (
                                <div
                                    className={cn('vehicle-since-last-update t-medium', {
                                        'no-journeys-link': !this.props.roles.includes(Role.JA_R)
                                    })}
                                    title={moment(this.props.data.gpsDataTime).format('L LT')}
                                >
                                    <div className="t-left">{t('VehicleCard.sinceLastUpdate')}</div>
                                    <div className="t-right" data-qa={qa.vehicleCard.data.fieldLastUpdate}>
                                        <i className="fa fa-refresh" /> {formattedDuration(this._lastUpdateDuration())}
                                    </div>
                                </div>
                            )}
                            {this.props.roles.includes(Role.JA_R) && (
                                <div className="vehicle-card-link">
                                    {this.props.onNavigateToJourney && (
                                        <Button
                                            type="link"
                                            onClick={this.props.onNavigateToJourney}
                                            qa={qa.vehicleCard.data.btnLinkToJourneys}
                                        >
                                            {t('VehicleCard.journeysActivity')}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>

                        {this.props.data?.driverBehaviorTrends && (
                            <div className="vehicle-card-driver-behavior">
                                <Row justify="space-between" align="top">
                                    <Col flex={7}>
                                        <div className="vehicle-card-driver-behavior-progress">
                                            <DriverScoreProgress
                                                value={this.props.data.driverBehaviorTrends.scores[0].score ?? 0}
                                                color="lightblue"
                                                size={70}
                                                withRate={false}
                                            />
                                            <Typography.Title level={4}>{t('DriverScoreStats.title')}</Typography.Title>
                                        </div>
                                    </Col>
                                    <Col flex={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <DriverScoreProgress
                                                value={this.props.data?.driverBehaviorTrends.scores[0].driveScore ?? 0}
                                                color="blue"
                                                size={53}
                                                withRate={false}
                                            />
                                            <Typography.Title level={4}>{t('DriverScoreStats.drive')}</Typography.Title>
                                        </div>
                                    </Col>
                                    <Col flex={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <DriverScoreProgress
                                                value={this.props.data?.driverBehaviorTrends.scores[0].ecoScore ?? 0}
                                                color="green"
                                                size={53}
                                                withRate={false}
                                            />
                                            <Typography.Title level={4}>{t('DriverScoreStats.eco')}</Typography.Title>
                                        </div>
                                    </Col>
                                    <Col flex={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <DriverScoreProgress
                                                value={
                                                    this.props.data?.driverBehaviorTrends.scores[0].wearTearScore ?? 0
                                                }
                                                color="yellow"
                                                size={53}
                                                withRate={false}
                                            />
                                            <Typography.Title level={4}>
                                                {t('DriverScoreStats.wearTear')}
                                            </Typography.Title>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="vehicle-card-link">
                                    {this.props.onNavigateToDriverBehavior && (
                                        <Button type="link" onClick={this.props.onNavigateToDriverBehavior}>
                                            {t('NavBar.driverBehavior')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {this.props.data?.driverBehaviorLightVehicle && (
                            <div className="vehicle-card-driver-behavior">
                                <Row justify="space-between" align="bottom">
                                    <Col span={7}>
                                        <Typography.Title level={4}>{t('DriverScoreStats.title')}</Typography.Title>
                                        <DriverScoreProgress
                                            value={this.props.data?.driverBehaviorLightVehicle.score ?? 0}
                                            color="lightblue"
                                            size={70}
                                        />
                                    </Col>
                                    <Col span={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <Typography.Title level={4}>
                                                {t('DriverBehaviorLightTable.cols.economyScore')}
                                            </Typography.Title>
                                            <DriverScoreProgress
                                                value={
                                                    this.props.data?.driverBehaviorLightVehicle.harshEventsScore ?? 0
                                                }
                                                color="blue"
                                                size={53}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <Typography.Title level={4}>
                                                {t('DriverScoreStats.ecologyScore')}
                                            </Typography.Title>
                                            <DriverScoreProgress
                                                value={this.props.data?.driverBehaviorLightVehicle.ecologyScore ?? 0}
                                                color="green"
                                                size={53}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="driver-score-stats-specific-col">
                                            <Typography.Title level={4}>
                                                {t('DriverScoreStats.safetyScore')}
                                            </Typography.Title>
                                            <DriverScoreProgress
                                                value={this.props.data?.driverBehaviorLightVehicle.safetyScore ?? 0}
                                                color="yellow"
                                                size={53}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="vehicle-card-link">
                                    {this.props.onNavigateToDriverBehaviorLightVehicles && (
                                        <Button
                                            type="link"
                                            onClick={this.props.onNavigateToDriverBehaviorLightVehicles}
                                        >
                                            {t('NavBar.driverBehavior')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className={cn({
                                'vehicle-card-driving-data':
                                    this.props.data.driverName || phoneData || hasAetrData(this.state.userIndex)
                            })}
                        >
                            {this.props.data.driverName && (
                                <Row
                                    align="middle"
                                    className={cn('vehicle-card-crew', {
                                        'without-aetr': !hasAetrData(this.state.userIndex)
                                    })}
                                    gutter={10}
                                >
                                    {this.props.data?.aetrData?.map(
                                        (driver, index) =>
                                            index < 2 && (
                                                <Col
                                                    xs={this.props.data?.aetrData?.length > 1 ? 12 : 24}
                                                    key={index}
                                                    className={cn('vehicle-card-crew-driver', {
                                                        'vehicle-card-crew-driver-simple':
                                                            !hasAnyPhoneData &&
                                                            (!hasAnyAetrData || this.props.data?.aetrData?.length < 2),
                                                        'vehicle-card-crew-driver-simple-single':
                                                            this.props.data?.aetrData?.length < 2
                                                    })}
                                                >
                                                    {hasAnyPhoneData ||
                                                    (hasAnyAetrData && this.props.data?.aetrData?.length > 1) ? (
                                                        <Button
                                                            className={cn('vehicle-card-crew-driver', {
                                                                active: this.state.userIndex === index
                                                            })}
                                                            type="primary"
                                                            block
                                                            qa={
                                                                this.state.userIndex === index
                                                                    ? qa.vehicleCard.aetr.fieldDriverActive
                                                                    : qa.vehicleCard.aetr.fieldDriver
                                                            }
                                                            onClick={() => this.onUserIndexChange(index)}
                                                        >
                                                            <Typography.Text
                                                                className="text-ellipsis"
                                                                ellipsis={{
                                                                    tooltip: driver?.driverName
                                                                }}
                                                            >
                                                                <img src={vehicleDetailIcon.driver} alt="driver" />
                                                                {driver?.driverName}
                                                            </Typography.Text>
                                                        </Button>
                                                    ) : (
                                                        <Typography.Text
                                                            className="text-ellipsis"
                                                            ellipsis={{
                                                                tooltip: driver?.driverName
                                                            }}
                                                        >
                                                            <img src={vehicleDetailIcon.driver} alt="driver" />
                                                            {driver?.driverName}
                                                        </Typography.Text>
                                                    )}
                                                </Col>
                                            )
                                    )}
                                </Row>
                            )}
                            {phoneData && (
                                <div className="phone-data">
                                    <Row justify="center" align="middle" gutter={4}>
                                        <Col>
                                            <img src={phone} alt="phone" />
                                        </Col>
                                        <Col>{phoneData}</Col>
                                    </Row>
                                </div>
                            )}
                            {hasAetrData(this.state.userIndex) && (
                                <>
                                    <div className="vehicle-crew">
                                        <div className="aetr-data t-medium">
                                            <Row>
                                                <Col span={8}>
                                                    <div className="prefix">
                                                        {t('VehicleCard.remainingDailyCapacity')}
                                                    </div>
                                                    <div className="value">
                                                        <AlarmRemainsDuration
                                                            remainingDuration={dailyUtilizationLeft}
                                                            max={dailyUtilizationMaxSeconds}
                                                            qa={qa.vehicleCard.aetr.fieldDailyWorkLeft}
                                                        />
                                                    </div>
                                                    <div className="suffix">
                                                        {t('common.outOf')}{' '}
                                                        <span data-qa={qa.vehicleCard.aetr.fieldDailyWorkMax}>
                                                            {dailyUtilizationMaxHours}
                                                        </span>{' '}
                                                        {H}
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className="prefix">
                                                        {t('VehicleCard.remainingDailyDriveTime')}
                                                    </div>
                                                    <div className="value">
                                                        <AlarmRemainsDuration
                                                            remainingDuration={dailyDriveLeft}
                                                            max={dailyDriveMaxSeconds}
                                                            qa={qa.vehicleCard.aetr.fieldDailyDriveLeft}
                                                        />
                                                    </div>
                                                    <div className="suffix">
                                                        {t('common.outOf')}{' '}
                                                        <span data-qa={qa.vehicleCard.aetr.fieldDailyWorkMax}>
                                                            {dailyDriveMaxHours}
                                                        </span>{' '}
                                                        {H}
                                                    </div>

                                                    {this.props.data?.aetrData?.[this.state.userIndex]
                                                        ?.weeklyExtendedShifts &&
                                                        (this.props.data.aetrData[this.state.userIndex]
                                                            .weeklyExtendedShifts ?? 0) > 0 && (
                                                            <div className="suffix">
                                                                {t('common.extended')}:{' '}
                                                                {
                                                                    this.props.data?.aetrData?.[this.state.userIndex]
                                                                        ?.weeklyExtendedShifts
                                                                }
                                                            </div>
                                                        )}
                                                </Col>
                                                <Col span={8}>
                                                    <div className="prefix">{t('VehicleCard.remaining14DaysRide')}</div>
                                                    <div className="value">
                                                        <AlarmRemainsDuration
                                                            remainingDuration={biweeklyDrivingLeft}
                                                            max={MAX_BIWEEKLY_RIDE}
                                                            qa={qa.vehicleCard.aetr.field14daysDriveLeft}
                                                        />
                                                    </div>
                                                    <div className="suffix">
                                                        {t('common.outOf')}{' '}
                                                        <span data-qa={qa.vehicleCard.aetr.field14daysDriveMax}>
                                                            {Math.round(MAX_BIWEEKLY_RIDE / 3600)}
                                                        </span>{' '}
                                                        {H}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="vehicle-card-link">
                                            {this.props.onNavigateToAetrReport && (
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        this.props.onNavigateToAetrReport?.(
                                                            this.props.data?.aetrData?.[this.state.userIndex]?.driverId
                                                        )
                                                    }
                                                    qa={qa.vehicleCard.aetr.btnLinkToAetr}
                                                >
                                                    {t('VehicleCard.aetrReport')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {this.props.roles.includes(Role.ROW_R) &&
                            this.props.data.destination &&
                            this.props.transport?.places &&
                            this.props.transport?.places?.length > 0 && (
                                <div className="transport-detail t-medium">
                                    <div
                                        className="transport-detail-places"
                                        data-qa={qa.vehicleCard.transport.sectionPlace}
                                    >
                                        {this.props.transport.places.map((place: PlacesModel, index: number) => {
                                            return (
                                                <Row
                                                    key={place.id}
                                                    align="middle"
                                                    gutter={[5, 30]}
                                                    className={cn('places-li', {
                                                        'with-ata': place.ata,
                                                        'next-place': place.id === this.props.data.id
                                                    })}
                                                >
                                                    <Col flex="35px">
                                                        <div className="place-task">
                                                            {place?.tasks && place?.tasks[0] && place?.tasks[0].type ? (
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={t(
                                                                        `PlannerPlacesTask.${place.tasks[0].type}`
                                                                    )}
                                                                >
                                                                    <img
                                                                        src={PlaceTypeToImage[place.tasks[0].type]}
                                                                        alt="unload"
                                                                        data-qa={
                                                                            qa.vehicleCard.transport.place.type[
                                                                                place.tasks[0].type
                                                                            ]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : index === 0 ? (
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={t(`PlannerPlacesTask.loading`)}
                                                                >
                                                                    <div
                                                                        className="place-task-img"
                                                                        data-qa={
                                                                            qa.vehicleCard.transport.place.type.loading
                                                                        }
                                                                    >
                                                                        <img
                                                                            src="/img-svg/icn-load.svg"
                                                                            alt="loading"
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                            ) : index ===
                                                              (this.props?.transport?.places?.length ?? 0) - 1 ? (
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={t(`PlannerPlacesTask.unloading`)}
                                                                >
                                                                    <div
                                                                        className="place-task-img"
                                                                        data-qa={
                                                                            qa.vehicleCard.transport.place.type
                                                                                .unloading
                                                                        }
                                                                    >
                                                                        <img
                                                                            src="/img-svg/icn-unload.svg"
                                                                            alt="unloading"
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </Col>

                                                    <Col flex="auto">
                                                        <Row justify="space-between">
                                                            <Col
                                                                className="place-location"
                                                                title={this._getLocationTitle(
                                                                    place.name ?? '',
                                                                    place.addressStructured
                                                                )}
                                                            >
                                                                <SettingsRerenderer>
                                                                    <div
                                                                        className="place-location-date"
                                                                        data-qa={
                                                                            qa.vehicleCard.transport.place.fieldDate
                                                                        }
                                                                        title={`${t(
                                                                            'DispatcherBoardDetail.rtaTooltip'
                                                                        )}: ${moment.utc(place.rta).format('L LT')}`}
                                                                    >
                                                                        {moment.utc(place.rta).format('ddd')},{' '}
                                                                        {moment.utc(place.rta).format('l')}
                                                                    </div>

                                                                    <div className="t-nowrap-with-elipses">
                                                                        <div className="t-nowrap-with-elipses">
                                                                            <AddressFormatter
                                                                                defaultAddress={place.name ?? ''}
                                                                                qa={
                                                                                    qa.vehicleCard.transport.place
                                                                                        .fiedAddress
                                                                                }
                                                                                addressStructured={
                                                                                    place.addressStructured
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </SettingsRerenderer>
                                                            </Col>

                                                            {place.duration && place.distance ? (
                                                                <Col>
                                                                    <div className="place-duration-to t-nowrap-with-elipses">
                                                                        <img
                                                                            src="/img-svg/timer.svg"
                                                                            alt={this.props.t('common.trailer')}
                                                                        />
                                                                        <span
                                                                            data-qa={
                                                                                qa.vehicleCard.transport.place
                                                                                    .fieldDuration
                                                                            }
                                                                        >
                                                                            {formattedDuration(
                                                                                moment.duration(
                                                                                    place.duration,
                                                                                    'seconds'
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="place-distance-to">
                                                                        <img
                                                                            src="/img-svg/distance-to.svg"
                                                                            alt={this.props.t('common.trailer')}
                                                                        />
                                                                        <span
                                                                            data-qa={
                                                                                qa.vehicleCard.transport.place
                                                                                    .fieldDistanceTo
                                                                            }
                                                                        >
                                                                            {numeral(place.distance / 1000).format(
                                                                                '0,0'
                                                                            )}
                                                                        </span>{' '}
                                                                        {KM}
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {place.ata && (
                                                                <Col>
                                                                    <div className="place-check">
                                                                        <i className="fa fa-check" />
                                                                    </div>
                                                                </Col>
                                                            )}
                                                            <Col>
                                                                <div className="place-time">
                                                                    {this.props.roles.includes(Role.ETA_R) && (
                                                                        <>
                                                                            <div
                                                                                className="rta"
                                                                                title={`${t(
                                                                                    'DispatcherBoardDetail.rtaTooltip'
                                                                                )}: ${moment
                                                                                    .utc(place.rta)
                                                                                    .format('L LT')}`}
                                                                            >
                                                                                <strong>
                                                                                    {moment.utc(place.rta).format('LT')}
                                                                                </strong>
                                                                            </div>
                                                                            {place.ata ? (
                                                                                <div
                                                                                    className={cn(
                                                                                        'place-time-ata t-nowrap'
                                                                                    )}
                                                                                    title={`${t(
                                                                                        'DispatcherBoardDetail.ataTooltip'
                                                                                    )}: ${moment
                                                                                        .utc(place.ata)
                                                                                        .format('L LT')}`}
                                                                                >
                                                                                    {moment.utc(place.ata).format('LT')}
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className={cn(
                                                                                        'place-time-eta t-nowrap',
                                                                                        {
                                                                                            't-text-warning':
                                                                                                moment
                                                                                                    .duration(
                                                                                                        moment(
                                                                                                            place.eta
                                                                                                        ).diff(
                                                                                                            moment(
                                                                                                                place.rta
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                    .asMinutes() <=
                                                                                                    30 &&
                                                                                                moment
                                                                                                    .duration(
                                                                                                        moment(
                                                                                                            place.eta
                                                                                                        ).diff(
                                                                                                            moment(
                                                                                                                place.rta
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                    .asMinutes() > 0,
                                                                                            't-text-danger':
                                                                                                moment
                                                                                                    .duration(
                                                                                                        moment(
                                                                                                            place.eta
                                                                                                        ).diff(
                                                                                                            moment(
                                                                                                                place.rta
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                    .asMinutes() > 30,
                                                                                            't-text-success':
                                                                                                moment
                                                                                                    .duration(
                                                                                                        moment(
                                                                                                            place.eta
                                                                                                        ).diff(
                                                                                                            moment(
                                                                                                                place.rta
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                    .asMinutes() <= 0
                                                                                        }
                                                                                    )}
                                                                                    title={`${t(
                                                                                        'DispatcherBoardDetail.etaTooltip'
                                                                                    )}:  ${moment
                                                                                        .utc(place.eta)
                                                                                        .format('L LT')}`}
                                                                                >
                                                                                    {place.eta
                                                                                        ? moment
                                                                                              .utc(place.eta)
                                                                                              .format('LT')
                                                                                        : '-'}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </div>
                                    <div className="vehicle-card-link">
                                        {this.props.onNavigateToDispatcherBoard && (
                                            <Button type="link" onClick={this.props.onNavigateToDispatcherBoard}>
                                                {t('VehicleCard.transportDetail')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                    </div>
                )}
            </div>
        );
    }

    private _getLocationTitle = (name: string, addressStructured: AddressStructured[]) => {
        const client = this.props.logic?.auth().client();
        const lang = this.props.logic?.settings().getProp('lang');
        const addressIdentification = this.props.logic?.settings().getProp('addressIdentification');

        if (!client || !lang || !addressIdentification) {
            console.error(
                'can not get "client" || "lang" || "addressIdentification" from logic, render default address name'
            );
            return name;
        }
        return toAddress(lang, client, addressStructured, addressIdentification, name);
    };

    private _showSinceLastUpdate(duration: moment.Duration | undefined): boolean {
        return duration && duration.asMinutes() > 1 ? true : false;
    }

    private _lastUpdateDuration = (): moment.Duration | undefined => {
        return this.props.data.gpsDataTime === '-'
            ? undefined
            : moment.duration(moment().diff(moment(this.props.data.gpsDataTime).toDate()));
    };

    private _lastChangeStateDuration = (): moment.Duration | undefined => {
        return this.props.data.lastChangeState
            ? moment.duration(moment().diff(moment(this.props.data.lastChangeState).toDate()))
            : undefined;
    };
}

export default withLogicContext(withTranslation()(ControlsVehicleCard));
