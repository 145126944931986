interface Props {
    className?: string;
    title?: string;
    text: string;
}

export function PromoTextBox(props: Props) {
    return (
        <div className={`driver-behavior-promo-textbox${props.className ? ` ${props.className}` : ''}`}>
            {props.title && <div className="driver-behavior-promo-textbox-title">{props.title}</div>}
            <div className="driver-behavior-promo-textbox-text">{props.text}</div>
        </div>
    );
}
