import { useTranslation } from 'react-i18next';
import { FleetModel, FleetType } from '../../FleetModule';
import { Button } from 'common/components';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import { Row, Col } from 'antd';

interface Props {
    data: FleetModel;
    demoMode?: boolean;
    roles: Role[];
    onUpdateClick?: () => void;
    onPairingClick?: () => void;
    onDeleteClick?: (data: FleetModel) => void;
    onAssignToVehicleGroupClick?: () => void;
}

export default function FleetTrailerActions(props: Props) {
    const { data } = props;
    const { t } = useTranslation();

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onUpdateClick}
                        type="primary"
                        disabled={props.demoMode || !props.roles.includes(Role.V_W) || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-pencil-alt" />}
                    >
                        {t('ManagementFleet.updateData')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onAssignToVehicleGroupClick}
                        type="primary"
                        disabled={props.demoMode || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnTags}
                        icon={<i className="fa fa-share-alt" />}
                    >
                        {t('ManagementFleet.assignToVehicleGroup')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        onClick={props.onPairingClick}
                        type="primary"
                        disabled={props.demoMode || !props.roles.includes(Role.V_IE) || !!props.data.disabledAt}
                        qa={qa.fleet.detail.btnAddLinkedItem}
                        icon={<i className="fas fa-fw fa-link" />}
                    >
                        {t('ManagementFleet.addAnotherItem')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        disabled={data.type === FleetType.VEHICLE || props.demoMode || !props.roles.includes(Role.V_W)}
                        block
                        onClick={props.onDeleteClick?.bind(undefined, data)}
                        type="primary"
                        qa={qa.fleet.detail.btnDelete}
                        icon={<i className="fas fa-fw fa-trash-alt" />}
                    >
                        {t('common.delete')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
