/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemperatureSensorData
 */
export interface TemperatureSensorData {
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensorData
     */
    serialNumber: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TemperatureSensorData
     */
    timestamps: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TemperatureSensorData
     */
    temperatures: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensorData
     */
    polyline: string;
}

export function TemperatureSensorDataFromJSON(json: any): TemperatureSensorData {
    return TemperatureSensorDataFromJSONTyped(json, false);
}

export function TemperatureSensorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemperatureSensorData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serial_number'],
        'timestamps': json['timestamps'],
        'temperatures': json['temperatures'],
        'polyline': json['polyline'],
    };
}

export function TemperatureSensorDataToJSON(value?: TemperatureSensorData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serialNumber,
        'timestamps': value.timestamps,
        'temperatures': value.temperatures,
        'polyline': value.polyline,
    };
}


