/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShipperBillingInfo
 */
export interface ShipperBillingInfo {
    /**
     * 
     * @type {string}
     * @memberof ShipperBillingInfo
     */
    billingCode: string;
    /**
     * 
     * @type {string}
     * @memberof ShipperBillingInfo
     */
    vatNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipperBillingInfo
     */
    taxNumber: string;
    /**
     * 
     * @type {Address}
     * @memberof ShipperBillingInfo
     */
    companyAddress: Address;
}

export function ShipperBillingInfoFromJSON(json: any): ShipperBillingInfo {
    return ShipperBillingInfoFromJSONTyped(json, false);
}

export function ShipperBillingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipperBillingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingCode': json['billingCode'],
        'vatNumber': json['vatNumber'],
        'taxNumber': json['taxNumber'],
        'companyAddress': AddressFromJSON(json['companyAddress']),
    };
}

export function ShipperBillingInfoToJSON(value?: ShipperBillingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingCode': value.billingCode,
        'vatNumber': value.vatNumber,
        'taxNumber': value.taxNumber,
        'companyAddress': AddressToJSON(value.companyAddress),
    };
}


