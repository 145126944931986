import { TrendDirection } from 'common/components/Trend';
import { roundToStep } from '../averages';

export function getIncreaseDirection(newValue?: number, oldValue?: number, step: number = 1.0) {
    const value =
        newValue && oldValue
            ? ((roundToStep(Number(newValue), step) - roundToStep(Number(oldValue))) / Number(oldValue)) * 100
            : 0;
    if (value === 0) {
        return TrendDirection.NONE;
    } else if (value < 0) {
        return TrendDirection.DOWN;
    } else {
        return TrendDirection.UP;
    }
}

export function getIncreasePercent(newValue?: number, oldValue?: number, step: number = 1.0) {
    if (oldValue === undefined || newValue === undefined) return undefined;

    const value =
        oldValue === 0
            ? 0
            : ((roundToStep(Number(newValue), step) - roundToStep(Number(oldValue))) / Number(oldValue)) * 100;
    return Math.round(value + Number.EPSILON);
}
