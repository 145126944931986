import { ClusterIconInfo } from '@googlemaps/markerclustererplus';
import { AvailableCurrencies } from 'common/model/currency';
import { FuelStationPriceList } from 'generated/backend-api';
import { ReadOnlyCurrency } from 'generated/new-main';
import { PoiModelMapFuelType, PoiModelMap, fuelToFuelTypeMap } from 'logic/map/logic/fuelStations';
import { PoiMarker } from 'logic/map/logic/poi-marker';
import numeral from 'numeral';

/**
 * Returns closest rate for best price calculation
 */
function getEURRate(value?: PoiModelMapFuelType['price'], currencies?: ReadOnlyCurrency[]): number {
    if (value === undefined) {
        return 0;
    }
    if (value?.currency === AvailableCurrencies.EUR) {
        return Number.parseFloat(Number(value.price).toFixed(3));
    } else {
        if (!currencies) {
            return Number.parseFloat(Number(value.price).toFixed(3));
        } else {
            const exchangeRate = currencies.find(c => c.code === value.currency);
            if (exchangeRate) {
                const [coefficient] = exchangeRate?.latestExchangeRate ?? [];
                return Number.parseFloat(Number(value.price / (coefficient?.rate ?? 1)).toFixed(3));
            } else {
                return 0;
            }
        }
    }
}

/**
 * Returns closest rate for best price calculation
 */
export function getClientCurrencyOrEUR(value?: FuelStationPriceList, clientCurrency?: AvailableCurrencies): number {
    if (value === undefined) {
        return 0;
    }
    if (value?.currency === AvailableCurrencies.EUR) {
        return Number.parseFloat(Number(value.salesPriceInServiceCurrency).toFixed(3));
    } else {
        if (clientCurrency === value.currency) {
            return Number.parseFloat(Number(value.price).toFixed(3));
        } else {
            return Number.parseFloat(Number(value.salesPriceInServiceCurrency).toFixed(3));
        }
    }
}

export function exchangePriceCurrency(
    oldCurrency: AvailableCurrencies,
    newCurrency: AvailableCurrencies,
    price: number,
    currencies: ReadOnlyCurrency[]
): number {
    const exchangeRate = (currencies ?? []).find(e => e.code === newCurrency);

    const oldExchangeRate = (currencies ?? []).find(e => e.code === oldCurrency);

    if (exchangeRate && oldCurrency !== newCurrency) {
        const [exchange] = exchangeRate?.latestExchangeRate ?? [];
        const [oldExchange] = oldExchangeRate?.latestExchangeRate ?? [];

        const newPrice =
            oldCurrency === AvailableCurrencies.EUR
                ? (price * (exchange?.rate ?? 1) * 100) / 100
                : ((price / (oldExchange?.rate ?? 1)) * (exchange?.rate ?? 1) * 100) / 10;

        return newPrice;
    }

    return price;
}

/**
 * Returns closest rate for best price calculation
 */
export function getClientCurrencyNameOrEUR(
    currency?: AvailableCurrencies,
    clientCurrency?: AvailableCurrencies
): string {
    if (currency && currency === clientCurrency) {
        return currency;
    } else {
        return AvailableCurrencies.EUR;
    }
}

/**
 * Returns best price for allowed fuel types
 */
export function fsBestPriceInFuelTypes(
    fTypes: PoiModelMapFuelType[],
    currencies?: ReadOnlyCurrency[]
): PoiModelMapFuelType | undefined {
    const [bestPrice] = fTypes
        .filter(p =>
            fuelToFuelTypeMap.diesel.some(code => code === p.code && p.price?.price && Number(p.price.price) >= 0.005)
        )
        .sort((a, b) => getEURRate(a.price, currencies) - getEURRate(b.price, currencies));
    return bestPrice;
}

export function fsBestPriceInPois(data: PoiModelMap[], currencies?: ReadOnlyCurrency[]): PoiModelMap[] | undefined {
    const bestPrices = data
        .filter(p =>
            p.fuelTypes?.some(
                t => t.price?.price && Number(t.price.price) >= 0.005 && fuelToFuelTypeMap.diesel.includes(t.code)
            )
        )
        .sort(
            (a, b) =>
                Number(
                    a.fuelTypes ? getEURRate(fsBestPriceInFuelTypes(a.fuelTypes, currencies)?.price, currencies) : 0
                ) -
                Number(b.fuelTypes ? getEURRate(fsBestPriceInFuelTypes(b.fuelTypes, currencies)?.price, currencies) : 0)
        )
        .reduce((acc, curr, index, arr) => {
            const prevPoi = acc[acc.length - 1];
            const lastPrice = prevPoi?.fuelTypes
                ? getEURRate(fsBestPriceInFuelTypes(prevPoi.fuelTypes, currencies)?.price, currencies)
                : 0;
            const currentPrice = curr.fuelTypes
                ? getEURRate(fsBestPriceInFuelTypes(curr.fuelTypes, currencies)?.price, currencies)
                : 0;

            if (currentPrice > 0 && (lastPrice === currentPrice || !prevPoi)) {
                return [...acc, curr];
            }
            arr.splice(acc.length - 1);
            return acc;
        }, [] as PoiModelMap[]);
    return bestPrices;
}

export const bestPriceClusterCalculator = (
    markers: google.maps.Marker[],
    currencies: ReadOnlyCurrency[]
): ClusterIconInfo => {
    const clusterFuelStations = markers.map(m => (m as unknown as PoiMarker).data());
    const hasBestPrice = clusterFuelStations.find(marker => marker.bestPrice);

    const bestPrices = fsBestPriceInPois(clusterFuelStations, currencies);
    const bestPrice = bestPrices && bestPrices.length > 0 ? bestPrices[0] : undefined;
    const bestPriceInFuelTypes = bestPrice?.fuelTypes && fsBestPriceInFuelTypes(bestPrice.fuelTypes, currencies);
    const result = `${hasBestPrice ? ' ' : ''}${numeral(bestPriceInFuelTypes?.price?.price || 0).format('0,0.00')} ${
        bestPriceInFuelTypes?.price?.currency
    }`;

    // FIXME: Find better way to distinguish between best price than current space on start of string
    const title = bestPriceInFuelTypes ? result : ``;

    return {
        text: String(markers.length),
        index: 1,
        title
    };
};
