import { changeToNoSpaceString } from './search';

export function getDataUrlFromFile(file: File): Promise<string> {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _e => {
            if (reader.result) {
                res(String(reader.result));
            } else {
                rej('No result data from reader');
            }
        };
        reader.onerror = e => {
            rej(e);
        };
    });
}

export function getByteArrayFromFile(file: File): Promise<number[]> {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = _e => {
            if (reader.result) {
                const fileByteArray = [];
                const arrayBuffer = reader.result,
                    array = new Uint8Array(arrayBuffer as ArrayBuffer);

                for (let i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }

                res(fileByteArray);
            } else {
                rej('No result data from reader');
            }
        };
        reader.onerror = e => {
            rej(e);
        };
    });
}

export function getBlobFromByteArray(byteArray: number[]) {
    const arr = new Uint8Array(byteArray);
    const blob = new Blob([arr], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    return blob;
}

export function normalizeExportSheetName(name: string) {
    return changeToNoSpaceString(name).substring(0, 30); // max length of sheetName === 31
}

export function downloadFile(url: Blob, fileName: string) {
    const urlLink = URL.createObjectURL(url);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.download = fileName;
    link.href = urlLink;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(urlLink);
    document.body.removeChild(link);
}
