/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Position,
    PositionFromJSON,
    PositionFromJSONTyped,
    PositionToJSON,
    Stream,
    StreamFromJSON,
    StreamFromJSONTyped,
    StreamToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatteryLevelLowEventInterval
 */
export interface BatteryLevelLowEventInterval {
    /**
     * 
     * @type {string}
     * @memberof BatteryLevelLowEventInterval
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof BatteryLevelLowEventInterval
     */
    clientId: number;
    /**
     * 
     * @type {Position}
     * @memberof BatteryLevelLowEventInterval
     */
    positionStart?: Position;
    /**
     * 
     * @type {Position}
     * @memberof BatteryLevelLowEventInterval
     */
    positionEnd?: Position;
    /**
     * 
     * @type {Date}
     * @memberof BatteryLevelLowEventInterval
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatteryLevelLowEventInterval
     */
    end?: Date;
    /**
     * 
     * @type {string}
     * @memberof BatteryLevelLowEventInterval
     */
    eventId?: string;
    /**
     * 
     * @type {Stream}
     * @memberof BatteryLevelLowEventInterval
     */
    stream?: Stream;
    /**
     * 
     * @type {string}
     * @memberof BatteryLevelLowEventInterval
     */
    type?: BatteryLevelLowEventIntervalTypeEnum;
}

export function BatteryLevelLowEventIntervalFromJSON(json: any): BatteryLevelLowEventInterval {
    return BatteryLevelLowEventIntervalFromJSONTyped(json, false);
}

export function BatteryLevelLowEventIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatteryLevelLowEventInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'clientId': json['client_id'],
        'positionStart': !exists(json, 'position_start') ? undefined : PositionFromJSON(json['position_start']),
        'positionEnd': !exists(json, 'position_end') ? undefined : PositionFromJSON(json['position_end']),
        'start': (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'eventId': !exists(json, 'event_id') ? undefined : json['event_id'],
        'stream': !exists(json, 'stream') ? undefined : StreamFromJSON(json['stream']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function BatteryLevelLowEventIntervalToJSON(value?: BatteryLevelLowEventInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'client_id': value.clientId,
        'position_start': PositionToJSON(value.positionStart),
        'position_end': PositionToJSON(value.positionEnd),
        'start': (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'event_id': value.eventId,
        'stream': StreamToJSON(value.stream),
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum BatteryLevelLowEventIntervalTypeEnum {
    BatteryLevelLow = 'battery_level_low'
}


