import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, Loading, Tag, Typography } from 'common/components';
import qa from 'qa-selectors';
import { VehicleWithAvailability } from 'common/model/transports';
import { search } from 'common/utils/search';
import { Col, Row } from 'antd';
import Search from 'common/components/Search';
import { NoData } from 'common/components/NoData';

interface Props extends WithTranslation {
    vehicles: VehicleWithAvailability[];
    vehiclesLoading: boolean;
    addVehicleLoading: boolean;
    puescTransport?: boolean;
    puescMonitoredObjects: number[];
    onConfirm: (id: string) => void;
    onCancel: () => void;
}

interface State {
    selected?: string;
    search: string;
}

class RouteOverviewVehicleSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            search: ''
        };
    }

    render() {
        const { onCancel, t, puescTransport } = this.props;
        return (
            <div className="route-overview-vehicle-select t-border t-default">
                <Row>
                    <Search
                        search={{ text: this.state.search }}
                        qa={qa.routeOverview.addVehicle.inputSearch}
                        placeholder={t('RouteOverviewVehicleSelect.searchPlaceholder')}
                        searchActive
                        onSearch={this._onSearchChange}
                    />
                </Row>
                <div className="t-row">
                    <ul className="t-ul">
                        {this.props.vehiclesLoading ? (
                            <Loading />
                        ) : this._filteredResults().length > 0 ? (
                            this._filteredResults().map(v => {
                                const available =
                                    v.available &&
                                    (!puescTransport ||
                                        (puescTransport &&
                                            this.props.puescMonitoredObjects.includes(
                                                Number(v.data.monitoredObjectId)
                                            )));
                                return (
                                    <li
                                        key={v.data.monitoredObjectId!}
                                        className={cn('t-row', {
                                            'route-overview-vehicle-select-selected':
                                                this.state.selected === v.data.monitoredObjectId!,
                                            'route-overview-vehicle-select-available': available,
                                            'route-overview-vehicle-select-disabled': !available
                                        })}
                                        onClick={this._onVehicleSelect(v.data.monitoredObjectId!)}
                                        data-qa={
                                            available
                                                ? qa.routeOverview.addVehicle.vehicleAvailable
                                                : qa.routeOverview.addVehicle.vehicleInTransport
                                        }
                                    >
                                        <div className="t-third" data-qa={qa.routeOverview.addVehicle.fieldRn}>
                                            <Typography.Text
                                                className="text-ellipsis"
                                                disabled={!available}
                                                ellipsis={{ tooltip: v.data.rn }}
                                            >
                                                {v.data.rn}
                                            </Typography.Text>
                                        </div>
                                        <div className="t-third">
                                            {puescTransport && available ? <Tag>PUESC</Tag> : <br />}
                                        </div>
                                        <div className="t-third">
                                            <Typography.Text
                                                className="text-ellipsis"
                                                disabled={!available}
                                                ellipsis={{
                                                    tooltip: available
                                                        ? t('RouteOverviewVehicleSelect.available')
                                                        : t('RouteOverviewVehicleSelect.inTransport')
                                                }}
                                            >
                                                {v.available
                                                    ? t('RouteOverviewVehicleSelect.available')
                                                    : t('RouteOverviewVehicleSelect.inTransport')}
                                            </Typography.Text>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <NoData />
                        )}
                    </ul>
                </div>
                <Row gutter={10} justify="end">
                    <Col>
                        <Button type="default" onClick={onCancel} qa={qa.routeOverview.addVehicle.btnCancel}>
                            {t('RouteOverviewVehicleSelect.cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            onClick={this._onConfirm}
                            loading={this.props.addVehicleLoading}
                            disabled={this.props.vehiclesLoading || !this.props.vehicles}
                            qa={qa.routeOverview.addVehicle.btnSubmit}
                        >
                            {t('RouteOverviewVehicleSelect.confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private _filteredResults = () => {
        const searchText = this.state.search;
        const { vehicles } = this.props;

        return searchText.length !== 0 ? search(searchText, ['data.rn', 'data.monitoredObjectId'], vehicles) : vehicles;
    };

    private _onSearchChange = (text: string) => {
        this.setState({ search: text });
    };
    private _onVehicleSelect = (id: string) => () => {
        const vehicle = this.props.vehicles.find(v => v.data.monitoredObjectId === id);
        if (vehicle?.available) {
            this.setState({ selected: id });
        }
    };

    private _onConfirm = () => {
        const { selected } = this.state;
        if (selected) {
            this.props.onConfirm(selected);
        }
    };
}

export default withTranslation()(RouteOverviewVehicleSelect);
