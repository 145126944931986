/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DIHPromotionPrice
 */
export interface DIHPromotionPrice {
    /**
     * 
     * @type {string}
     * @memberof DIHPromotionPrice
     */
    marginType?: string;
    /**
     * 
     * @type {number}
     * @memberof DIHPromotionPrice
     */
    marginValue?: number;
}

export function DIHPromotionPriceFromJSON(json: any): DIHPromotionPrice {
    return DIHPromotionPriceFromJSONTyped(json, false);
}

export function DIHPromotionPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DIHPromotionPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marginType': !exists(json, 'marginType') ? undefined : json['marginType'],
        'marginValue': !exists(json, 'marginValue') ? undefined : json['marginValue'],
    };
}

export function DIHPromotionPriceToJSON(value?: DIHPromotionPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marginType': value.marginType,
        'marginValue': value.marginValue,
    };
}


