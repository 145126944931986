import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Goods, TransportEventRule, TransportPlaceTask, TransportPlaceTaskType } from 'generated/backend-api';
import cn from 'classnames';
import PlaceTypeLoading from '../PlaceTypeLoading/PlaceTypeLoading';
import { FormikProps } from 'formik';
import PlaceTypeUnloading from '../PlaceTypeUnloading/PlaceTypeUnloading';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import PlaceTypeParking from '../PlaceTypeParking/PlaceTypeParking';
import PlaceTypeRefueling from '../PlaceTypeRefueling/PlaceTypeRefueling';
import qa from 'qa-selectors';
import { ContactList } from 'common/model/client-contact';
export interface Props extends WithTranslation, WithLogic {
    addressFormikRef?: React.RefObject<FormikProps<ContactList>>;
    onPlaceTaskChange?: (task: TransportPlaceTask) => void;
    onAlarmConfigChange?: (config: TransportEventRule[]) => void;
    onPlaceTaskClientContactChange?: (clientContact: ContactList) => void;
    onManualAtaDateChange?: (dateTime: string) => void;
}

interface State {
    task: TransportPlaceTask;
    clientContact?: ContactList;
}

class PlannerPlacesTask extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            task: this.props.logic.plannerLogic().selectedTask ?? {
                id: this._generateId(),
                action: '',
                type: TransportPlaceTaskType.Loading
            }
        };
    }

    componentDidMount() {
        this.props.onPlaceTaskChange?.(this.state.task);
    }

    render() {
        let tasks: TransportPlaceTaskType[] = [];
        if (this.props.logic.plannerLogic().selectedTask) {
            const taskType = Object.values(TransportPlaceTaskType).find(
                taskType => this.props.logic.plannerLogic().selectedTask?.type === taskType
            );
            if (taskType) tasks = [taskType];
        } else {
            // Not using Object.values since it needs to be in this order
            tasks = [
                TransportPlaceTaskType.Loading,
                TransportPlaceTaskType.Unloading,
                TransportPlaceTaskType.Parking,
                TransportPlaceTaskType.Refueling
            ].filter(
                task =>
                    !this.props.logic
                        .plannerLogic()
                        .selectedPlace?.tasks?.map(t => t.type)
                        .includes(task)
            );
        }

        return (
            <div className="planner-tasks">
                <div className="planner-tasks-select">
                    <Row gutter={[10, 20]}>
                        {tasks.map(taskType => {
                            return (
                                <Col key={`task-item-${taskType}`} span={6}>
                                    <div
                                        className={cn('planner-tasks-select-item', {
                                            active: this.state.task.type === taskType
                                        })}
                                        onClick={() => {
                                            this._onTaskTypeChange(taskType);
                                        }}
                                        data-qa={qa.planner.activity.btnType[taskType]}
                                    >
                                        <img
                                            src={this.props.logic.plannerLogic().getTaskTypeSelectionIcon(taskType)}
                                            alt={taskType}
                                        />
                                        <div>{this.props.t(`PlannerPlacesTask.${taskType}`)}</div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                <div className="planner-tasks-content">{this._getTaskTypeContent()}</div>
            </div>
        );
    }

    private _onTaskTypeChange = (taskType: TransportPlaceTaskType) => {
        this.setState(
            state => ({
                task: {
                    ...state.task,
                    type: taskType
                }
            }),
            () => {
                this.props.onPlaceTaskChange?.(this.state.task);
            }
        );
    };

    private _getTaskTypeContent = () => {
        const placeIndex =
            this.props.logic
                .plannerLogic()
                .transport.places?.findIndex(p => p.id === this.props.logic.plannerLogic().selectedPlaceId) ?? 0;
        switch (this.state.task.type) {
            case TransportPlaceTaskType.Loading:
                return (
                    <PlaceTypeLoading
                        task={this.state.task}
                        initialClientContact={this.props.logic.plannerLogic().clientContactBySelectedTask}
                        addressFormikRef={this.props.addressFormikRef}
                        placeDisabled={this.props.logic.plannerLogic().selectedPlaceDisabled}
                        placeIndex={placeIndex}
                        goodsWithAddress={this.props.logic.plannerLogic().goodsWithAddressBySelectedPlace}
                        onClientContactFormikSubmit={this._onClientContactFormikSubmit}
                        onLoadingGoodsChange={this._onLoadingGoodsChange}
                        onPuescCommentChange={this._onPuescCommentChange}
                        onAlarmConfigChange={this._onAlarmConfigChange}
                        onNoteForDriverChange={this._onNoteForDriverChange}
                        onManualAtaDateChange={this.props.onManualAtaDateChange}
                    />
                );
            case TransportPlaceTaskType.Unloading:
                return (
                    <PlaceTypeUnloading
                        task={this.state.task}
                        initialClientContact={this.props.logic.plannerLogic().clientContactBySelectedTask}
                        addressFormikRef={this.props.addressFormikRef}
                        placeDisabled={this.props.logic.plannerLogic().selectedPlaceDisabled}
                        placeIndex={placeIndex}
                        goodsWithAddress={this.props.logic.plannerLogic().goodsWithAddress}
                        onClientContactFormikSubmit={this._onClientContactFormikSubmit}
                        onUnloadingGoodsChange={this._onUnloadingGoodsChange}
                        onPuescCommentChange={this._onPuescCommentChange}
                        onAlarmConfigChange={this._onAlarmConfigChange}
                        onNoteForDriverChange={this._onNoteForDriverChange}
                        onManualAtaDateChange={this.props.onManualAtaDateChange}
                    />
                );
            case TransportPlaceTaskType.Parking:
                return (
                    <PlaceTypeParking
                        task={this.state.task}
                        placeDisabled={this.props.logic.plannerLogic().selectedPlaceDisabled}
                        placeIndex={placeIndex}
                        onAlarmConfigChange={this._onAlarmConfigChange}
                        onNoteForDriverChange={this._onNoteForDriverChange}
                        onManualAtaDateChange={this.props.onManualAtaDateChange}
                    />
                );
            case TransportPlaceTaskType.Refueling:
                return (
                    <PlaceTypeRefueling
                        task={this.state.task}
                        placeDisabled={this.props.logic.plannerLogic().selectedPlaceDisabled}
                        placeIndex={placeIndex}
                        onAlarmConfigChange={this._onAlarmConfigChange}
                        onNoteForDriverChange={this._onNoteForDriverChange}
                        onManualAtaDateChange={this.props.onManualAtaDateChange}
                    />
                );
            default:
                return 'not yet';
        }
    };

    private _onAlarmConfigChange = (config: TransportEventRule[]) => {
        this.setState(
            state => ({
                task: {
                    ...state.task
                }
            }),
            () => {
                this.props.onAlarmConfigChange?.(config);
            }
        );
    };

    private _onPuescCommentChange = (comment?: string) => {
        this.setState(
            state => ({
                task: {
                    ...state.task,
                    puescComments: comment
                }
            }),
            () => {
                this.props.onPlaceTaskChange?.(this.state.task);
            }
        );
    };

    private _onLoadingGoodsChange = (goods: Goods[]) => {
        this.setState(
            state => ({
                task: {
                    ...state.task,
                    loadingGoods: goods
                }
            }),
            () => {
                this.props.onPlaceTaskChange?.(this.state.task);
            }
        );
    };

    private _onUnloadingGoodsChange = (goodsIds: string[]) => {
        this.setState(
            state => ({
                task: {
                    ...state.task,
                    unloadingGoods: goodsIds
                }
            }),
            () => {
                this.props.onPlaceTaskChange?.(this.state.task);
            }
        );
    };

    private _onClientContactFormikSubmit = async (value: ContactList): Promise<boolean> => {
        return new Promise((res, rej) => {
            this.setState(
                {
                    clientContact: value
                },
                () => {
                    if (this.state.clientContact) {
                        this.props.onPlaceTaskClientContactChange?.(this.state.clientContact);
                        res(true);
                    } else {
                        rej(false);
                    }
                }
            );
        });
    };

    private _onNoteForDriverChange = (value: string) => {
        if (!this.props.logic.plannerLogic().selectedPlaceDisabled) {
            this.setState(
                state => ({ task: { ...state.task, action: value } }),
                () => {
                    this.props.onPlaceTaskChange?.(this.state.task);
                }
            );
        }
    };

    private _generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}

export default withTranslation()(withLogicContext(observer(PlannerPlacesTask)));
