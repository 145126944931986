/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyContactList
 */
export interface WriteOnlyContactList {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyContactList
     */
    client?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyContactList
     */
    countryId?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WriteOnlyContactList
     */
    type: Array<WriteOnlyContactListTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    premisesNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyContactList
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyContactList
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyContactList
     */
    otherIdentificationNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyContactList
     */
    isNaturalPerson?: boolean | null;
}

export function WriteOnlyContactListFromJSON(json: any): WriteOnlyContactList {
    return WriteOnlyContactListFromJSONTyped(json, false);
}

export function WriteOnlyContactListFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyContactList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'type': json['type'],
        'name': json['name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'house_number') ? undefined : json['house_number'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'premisesNumber': !exists(json, 'premises_number') ? undefined : json['premises_number'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'taxId': !exists(json, 'tax_id') ? undefined : json['tax_id'],
        'businessId': !exists(json, 'business_id') ? undefined : json['business_id'],
        'otherIdentificationNumber': !exists(json, 'other_identification_number') ? undefined : json['other_identification_number'],
        'isNaturalPerson': !exists(json, 'is_natural_person') ? undefined : json['is_natural_person'],
    };
}

export function WriteOnlyContactListToJSON(value?: WriteOnlyContactList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': value.client,
        'country_id': value.countryId,
        'type': value.type,
        'name': value.name,
        'city': value.city,
        'street': value.street,
        'house_number': value.houseNumber,
        'postal_code': value.postalCode,
        'premises_number': value.premisesNumber,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'vat': value.vat,
        'tax_id': value.taxId,
        'business_id': value.businessId,
        'other_identification_number': value.otherIdentificationNumber,
        'is_natural_person': value.isNaturalPerson,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyContactListTypeEnum {
    Client = 'client',
    Supplier = 'supplier',
    Consignee = 'consignee'
}


