import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    id: string;
    name?: string;
    vehicles?: ReactNode;
    apiKey?: string;
    demoMode?: boolean;
    onUpdate?: () => void;
    onDelete?: () => void;
}

function CustomerApiKeyCard({ name, apiKey, vehicles, demoMode, onDelete, onUpdate: onRename, t }: Props) {
    return (
        <div className="api-key-card" data-qa={qa.systemConnections.apiKeys.sectionApiKey}>
            <div className="api-key-card-content">
                <span>{t('CustomerApiKeyCard.user')}</span>
                <strong data-qa={qa.systemConnections.apiKeys.fieldUser}>{name}</strong>
            </div>
            <div className="api-key-card-content">
                <span>{t('CustomerApiKeyCard.vehicles')}</span>
                <span data-qa={qa.systemConnections.apiKeys.fieldVehicles}>{vehicles}</span>
            </div>
            <div className="api-key-card-content">
                <span>{t('CustomerApiKeyCard.apiKey')}</span>
                <strong data-qa={qa.systemConnections.apiKeys.fieldApiKey}>{apiKey}</strong>
            </div>
            <div className="api-key-card-actions">
                {demoMode ? (
                    <u>{t('CustomerApiKeyCard.actions.delete')}</u>
                ) : (
                    <u onClick={onDelete} data-qa={qa.systemConnections.apiKeys.btnDelete}>
                        {t('CustomerApiKeyCard.actions.delete')}
                    </u>
                )}
                <u onClick={onRename} data-qa={qa.systemConnections.apiKeys.btnEdit}>
                    {t('CustomerApiKeyCard.actions.rename')}
                </u>
            </div>
        </div>
    );
}

export default withTranslation()(CustomerApiKeyCard);
