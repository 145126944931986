/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectProfile
 */
export interface MonitoredObjectProfile {
    /**
     * Euroclass code
     * @type {string}
     * @memberof MonitoredObjectProfile
     */
    euroClass?: string;
    /**
     * no. of vehicle\'s axles
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    numberOfAxles?: number;
    /**
     * Max Weight of vehicle in tons
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    weight?: number;
    /**
     * Max Weight of vehicle in tons
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    weightFull?: number;
    /**
     * Vehicle height in centimeters
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    height?: number;
    /**
     * Vehicle width in centimeters
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    width?: number;
    /**
     * Vehicle length in centimeters
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    trailersCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectProfile
     */
    tunnel?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectProfile
     */
    vin?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectProfile
     */
    fuelType?: MonitoredObjectProfileFuelTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectProfile
     */
    optimalRpm?: number;
}

export function MonitoredObjectProfileFromJSON(json: any): MonitoredObjectProfile {
    return MonitoredObjectProfileFromJSONTyped(json, false);
}

export function MonitoredObjectProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'euroClass': !exists(json, 'euroClass') ? undefined : json['euroClass'],
        'numberOfAxles': !exists(json, 'numberOfAxles') ? undefined : json['numberOfAxles'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'weightFull': !exists(json, 'weightFull') ? undefined : json['weightFull'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'trailersCount': !exists(json, 'trailersCount') ? undefined : json['trailersCount'],
        'tunnel': !exists(json, 'tunnel') ? undefined : json['tunnel'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'optimalRpm': !exists(json, 'optimalRpm') ? undefined : json['optimalRpm'],
    };
}

export function MonitoredObjectProfileToJSON(value?: MonitoredObjectProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'euroClass': value.euroClass,
        'numberOfAxles': value.numberOfAxles,
        'weight': value.weight,
        'weightFull': value.weightFull,
        'height': value.height,
        'width': value.width,
        'length': value.length,
        'trailersCount': value.trailersCount,
        'tunnel': value.tunnel,
        'vin': value.vin,
        'fuelType': value.fuelType,
        'optimalRpm': value.optimalRpm,
    };
}

/**
* @export
* @enum {string}
*/
export enum MonitoredObjectProfileFuelTypeEnum {
    DIESEL = 'DIESEL',
    GASOLINE = 'GASOLINE',
    LNGCNG = 'LNG_CNG',
    ELECTRO = 'ELECTRO',
    HYBRID = 'HYBRID',
    HYDROGEN = 'HYDROGEN',
    Empty = ''
}


