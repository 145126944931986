import { Component } from 'react';
import cn from 'classnames';
import i18next from 'i18next';
import moment, { Moment } from 'moment';

import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { Col, Row, DatePicker as DatePickerAnt } from 'antd';
import { Button, Select, Tooltip } from 'common/components';
import { CalendarProps, DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// @ts-ignore types
import * as locales from 'react-date-range/dist/locale';
import qa from 'qa-selectors';

interface Props extends RangePickerDateProps<Moment> {
    loading?: boolean;
    shortcuts?: {};
    confirmButtonText?: string;
    cancelButtonText?: string;
    defaultValue: [Moment, Moment];
    className?: string;
    maxDate?: Date;
    minDate?: Date;
    maxMonthRangePick?: number;
    onShortcutConfirm?: boolean;
    onDateChange?: (date: [Moment, Moment]) => void;
    onOpenChange?: (open: boolean) => void;
}

interface State {
    dateTimeRange: { start: Moment; end: Moment };
    maxMonthRangePick: number;
    maxDate: Date;
    minDate: Date;
    focusedRange: [number, 0 | 1];
}
const momentLocaleToFns = (lang: string) => {
    switch (lang) {
        case 'ga':
            return locales['enGB'];
        case 'mt':
            return locales['enGB'];
        case 'en-gb':
            return locales['enGB'];
        default:
            return locales[lang];
    }
};

class DateTimeRangePickerNew extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dateTimeRange: {
                start: props.defaultValue?.[0] ?? moment(),
                end: props.defaultValue?.[1] ?? moment()
            },
            maxMonthRangePick: props.maxMonthRangePick ?? 2,
            maxDate: props.maxDate ?? moment().toDate(),
            minDate: props.minDate ?? moment().subtract(10, 'years').toDate(),
            focusedRange: [0, 0]
        };
    }

    handleDateChange = (dateTimeRange: { start: Moment; end: Moment }) => {
        const start = this.state.dateTimeRange.start;
        const end = this.state.dateTimeRange.end;
        if (dateTimeRange.start.isSame(dateTimeRange.end, 'date') && this.state.focusedRange[1] === 1) {
            this.setState(() => ({
                dateTimeRange: {
                    start: dateTimeRange.start.set({
                        hours: start.startOf('day').hours(),
                        minutes: start.startOf('day').minutes()
                    }),
                    end: dateTimeRange.end.set({
                        hours: end.endOf('day').hours(),
                        minutes: end.endOf('day').minutes()
                    })
                }
            }));
        } else {
            this.setState(() => ({
                dateTimeRange: {
                    start: dateTimeRange.start.set({
                        hours: start.hours(),
                        minutes: start.minutes()
                    }),
                    end: dateTimeRange.end.set({
                        hours: end.hours(),
                        minutes: end.minutes()
                    })
                }
            }));
        }
    };

    handleFocusChange = (focusedRange: [number, 0 | 1]) => {
        this.setState({ focusedRange });
    };

    onShortcutClick = (dateTimeRange: { start: Moment; end: Moment }) => {
        const minDate = this.getMinDate();
        const maxDate = this.getMaxDate();
        const dateTimeRangeValidated = {
            start: dateTimeRange.start.isBefore(minDate) ? moment(minDate).startOf('day') : dateTimeRange.start,
            end: dateTimeRange.end.isAfter(maxDate) ? moment(maxDate).endOf('day') : dateTimeRange.end
        };
        this.handleChange(dateTimeRangeValidated);

        this.props.onShortcutConfirm &&
            this.props.onDateChange?.([dateTimeRangeValidated.start, dateTimeRangeValidated.end]);
    };

    handleChange = (dateTimeRange: { start: Moment; end: Moment }) => {
        const start = dateTimeRange.start.isBefore(dateTimeRange.end) ? dateTimeRange.start : dateTimeRange.end;
        const end = dateTimeRange.end.isAfter(dateTimeRange.start) ? dateTimeRange.end : dateTimeRange.start;
        this.setState({
            dateTimeRange: {
                start,
                end
            }
        });
    };

    getDisabledHours = (start: boolean): number[] => {
        let hours = [];
        if (this.state.dateTimeRange.start.date() === this.state.dateTimeRange.end.date()) {
            if (start) {
                for (let i = this.state.dateTimeRange.end.hour(); i < 24; i++) {
                    hours.push(i);
                }
            } else {
                for (let i = 0; i < this.state.dateTimeRange.start.hour(); i++) {
                    hours.push(i);
                }
            }
        }
        return hours;
    };

    getDisabledMinutes = (start: boolean): number[] => {
        let minutes = [];
        if (this.state.dateTimeRange.start.date() === this.state.dateTimeRange.end.date()) {
            if (start) {
                if (this.state.dateTimeRange.end.hour() === this.state.dateTimeRange.start.hour()) {
                    for (let i = this.state.dateTimeRange.end.minute(); i < 60; i++) {
                        minutes.push(i);
                    }
                }
            } else {
                if (this.state.dateTimeRange.start.hour() === this.state.dateTimeRange.end.hour()) {
                    for (let i = 0; i < this.state.dateTimeRange.start.minute(); i++) {
                        minutes.push(i);
                    }
                }
            }
        }
        return minutes;
    };

    onConfirm = () => {
        this.props.onDateChange?.([this.state.dateTimeRange.start, this.state.dateTimeRange.end]);
    };

    onCancel = () => {
        this.props.onOpenChange?.(false);
    };

    getMaxDate = (): Date => {
        let maxDate: Date = this.state.maxDate;
        if (
            this.state.dateTimeRange.start.date() === this.state.dateTimeRange.end.date() &&
            this.state.focusedRange[1] === 1
        ) {
            const maxRangeDate = moment(this.state.dateTimeRange.start)
                .add(this.state.maxMonthRangePick, 'months')
                .toDate();
            maxDate = moment(maxDate).isBefore(maxRangeDate) ? maxDate : maxRangeDate;
        }
        return maxDate;
    };

    getMinDate = (): Date => {
        let minDate: Date = this.state.minDate;
        if (
            this.state.dateTimeRange.start.date() === this.state.dateTimeRange.end.date() &&
            this.state.focusedRange[1] === 1
        ) {
            const minRangeDate = moment(this.state.dateTimeRange.start)
                .subtract(this.state.maxMonthRangePick, 'months')
                .toDate();
            minDate = moment(minDate).isAfter(minRangeDate) ? minDate : minRangeDate;
        }
        return minDate;
    };

    getShownDate = (): Date => {
        let shownDate: Date = moment(this.state.dateTimeRange.start).subtract(1, 'months').toDate();
        const rangeDays = moment.duration(
            moment(this.state.dateTimeRange.end).diff(this.state.dateTimeRange.start, 'days'),
            'days'
        );
        if (
            moment(this.state.dateTimeRange.start).isAfter(
                moment(this.state.dateTimeRange.start).endOf('month').subtract(rangeDays, 'days')
            )
        ) {
            shownDate = moment(this.state.dateTimeRange.start).toDate();
        }
        return shownDate;
    };

    dayRenderer = (date: Date) => {
        const monthLocale = this.state.maxMonthRangePick > 1 ? i18next.t('common.months') : i18next.t('common.month');
        const cannotSelectDateTitle =
            this.state.focusedRange[1] === 1
                ? `${i18next.t('common.cannotSelectDateNotInRangeMax')} ${this.state.maxMonthRangePick} ${monthLocale}`
                : i18next.t('common.cannotSelectDate');
        const qaCurrentDate = moment().isSame(date, 'date') ? qa.common.dateTimeRangePicker.currentDate : '';
        const qaStartDate = moment(this.state.dateTimeRange.start).isSame(date, 'date')
            ? qa.common.dateTimeRangePicker.startDate
            : '';
        const qaEndDate = moment(this.state.dateTimeRange.end).isSame(date, 'date')
            ? qa.common.dateTimeRangePicker.endDate
            : '';
        const qaDate = `${qaCurrentDate}${qaCurrentDate ? ` ${qaStartDate}` : qaStartDate}${
            qaCurrentDate || qaStartDate ? ` ${qaEndDate}` : qaEndDate
        }`;

        if (moment(date).isBefore(moment(this.getMinDate()).startOf('day'))) {
            return (
                <Tooltip qa={qaDate} title={cannotSelectDateTitle}>
                    {date.getDate()}
                </Tooltip>
            );
        }
        if (moment(date).isAfter(moment(this.getMaxDate()).endOf('day'))) {
            return (
                <Tooltip qa={qaDate} title={cannotSelectDateTitle}>
                    {date.getDate()}
                </Tooltip>
            );
        } else {
            return <span data-qa={qaDate}>{date.getDate()}</span>;
        }
    };

    navigatorRenderer = (
        focusedDate: Date,
        changeShownDate: (value: Date | number | string, mode?: 'set' | 'setYear' | 'setMonth' | 'monthOffset') => void,
        _props: CalendarProps
    ): JSX.Element => {
        const upperYearLimit = this.getMaxDate().getFullYear();
        const lowerYearLimit = this.getMinDate().getFullYear();
        const upperMonthLimit = focusedDate.getFullYear() === upperYearLimit ? this.getMaxDate().getMonth() : 11;
        const lowerMonthLimit = focusedDate.getFullYear() === lowerYearLimit ? this.getMinDate().getMonth() : 0;
        const upperMonthNextLimit =
            focusedDate.getMonth() === 11
                ? focusedDate.getFullYear() + 1 === upperYearLimit
                    ? this.getMaxDate().getMonth() + 1
                    : 11
                : upperMonthLimit + 1;
        const lowerMonthNextLimit =
            focusedDate.getMonth() === 11
                ? focusedDate.getFullYear() + 1 === lowerYearLimit
                    ? this.getMinDate().getMonth()
                    : 0
                : lowerMonthLimit;

        return (
            <Row align="middle" className="rdrMonthAndYearWrapper">
                <Col xs={12} className="rdrMonthAndYearPickersCol">
                    <Row className="rdrMonthAndYearPickersRow">
                        <span>
                            <Button
                                size="small"
                                type="primary"
                                className="rdrNextPrevButton rdrPprevButton"
                                disabled={moment(focusedDate)
                                    .subtract(1, 'months')
                                    .endOf('month')
                                    .isBefore(this.getMinDate())}
                                onClick={() => changeShownDate(-1, 'monthOffset')}
                                qa={qa.common.dateTimeRangePicker.prevMonth}
                            >
                                <i />
                            </Button>
                        </span>
                        <span className="rdrMonthAndYearPickers rdrMonthAndYearPickersFrom">
                            <span className="rdrMonthPicker">
                                <Select
                                    value={focusedDate.getMonth()}
                                    qa={qa.common.dateTimeRangePicker.monthsFrom}
                                    onChange={value => changeShownDate(value, 'setMonth')}
                                    bordered={false}
                                    showArrow={false}
                                >
                                    {moment.months().map(
                                        (month: any, i: number) =>
                                            i >= lowerMonthLimit &&
                                            i <= upperMonthLimit && (
                                                <Select.Option key={i} value={i}>
                                                    {month}
                                                </Select.Option>
                                            )
                                    )}
                                </Select>
                            </span>
                            <span className="rdrYearPicker">
                                <Select
                                    value={focusedDate.getFullYear()}
                                    onChange={value => changeShownDate(value, 'setYear')}
                                    bordered={false}
                                    showArrow={false}
                                    qa={qa.common.dateTimeRangePicker.yearsFrom}
                                >
                                    {new Array(upperYearLimit - lowerYearLimit + 1)
                                        .fill(upperYearLimit)
                                        .map((val, i) => {
                                            const year = val - i;
                                            return (
                                                <Select.Option key={year} value={year}>
                                                    {year}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </span>
                        </span>
                    </Row>
                </Col>
                <Col xs={12} className="rdrMonthAndYearPickersCol">
                    <Row className="rdrMonthAndYearPickersRow">
                        <span className="rdrMonthAndYearPickers rdrMonthAndYearPickersTo">
                            <span className="rdrMonthPicker">
                                <Select
                                    value={(focusedDate.getMonth() + 1) % 12}
                                    bordered={false}
                                    showArrow={false}
                                    qa={qa.common.dateTimeRangePicker.monthsTo}
                                    onChange={value =>
                                        focusedDate.getMonth() === 11 && value > 0
                                            ? changeShownDate(
                                                  moment(focusedDate)
                                                      .add(1, 'years')
                                                      .month(value - 1)
                                                      .toDate(),
                                                  'set'
                                              )
                                            : focusedDate.getMonth() < 11 && value > 0
                                            ? changeShownDate(Number(value) - 1, 'setMonth')
                                            : changeShownDate(
                                                  moment(focusedDate).subtract(1, 'years').endOf('year').toDate(),
                                                  'set'
                                              )
                                    }
                                >
                                    {moment.months().map(
                                        (month: any, i: number) =>
                                            i >= lowerMonthNextLimit &&
                                            i <= upperMonthNextLimit && (
                                                <Select.Option key={i} value={i}>
                                                    {month}
                                                </Select.Option>
                                            )
                                    )}
                                </Select>
                            </span>
                            <span className="rdrYearPicker">
                                <Select
                                    value={moment(focusedDate).add(1, 'months').toDate().getFullYear()}
                                    qa={qa.common.dateTimeRangePicker.yearsTo}
                                    bordered={false}
                                    showArrow={false}
                                    onChange={value =>
                                        focusedDate.getMonth() === 11
                                            ? changeShownDate(value - 1, 'setYear')
                                            : changeShownDate(value, 'setYear')
                                    }
                                >
                                    {new Array(upperYearLimit - lowerYearLimit + 1)
                                        .fill(upperYearLimit)
                                        .map((val, i) => {
                                            const year = val - i;
                                            return (
                                                <Select.Option key={year} value={year}>
                                                    {year}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </span>
                        </span>
                        <span>
                            <Button
                                size="small"
                                type="primary"
                                className="rdrNextPrevButton rdrNextButton"
                                qa={qa.common.dateTimeRangePicker.nextMonth}
                                disabled={moment(focusedDate)
                                    .add(1, 'months')
                                    .startOf('month')
                                    .isAfter(this.getMaxDate())}
                                onClick={() => changeShownDate(+1, 'monthOffset')}
                            >
                                <i />
                            </Button>
                        </span>
                    </Row>
                </Col>
            </Row>
        );
    };

    render() {
        const {
            format,
            showTime = false,
            open = true,
            shortcuts,
            confirmButtonText = 'Confirm',
            cancelButtonText = 'Cancel'
        } = this.props;
        const formatStyle = (format as string) ?? (showTime ? 'L LT' : 'L');
        return (
            <div
                className={cn('react-date-range-component', this.props.className, {
                    showTime: this.props.showTime
                })}
                style={{ display: open ? 'block' : 'none' }}
            >
                <Row className="react-date-range-component-panel">
                    {showTime && (
                        <Col className="datetime-text from">
                            <div className="date">
                                {this.state.dateTimeRange.start?.format(formatStyle.split(' ')[0])}
                            </div>
                            <div className="time">
                                {this.state.dateTimeRange.start?.format(formatStyle.split(' ')[1])}
                            </div>
                            <DatePickerAnt.TimePicker
                                className="react-date-range-component-time-from"
                                dropdownClassName="react-date-range-component-time-from"
                                value={this.state.dateTimeRange.start}
                                disabledDate={date => date.isAfter(this.state.dateTimeRange.end)}
                                disabledHours={() => this.getDisabledHours(true)}
                                disabledMinutes={() => this.getDisabledMinutes(true)}
                                defaultOpen={true}
                                open={true}
                                format="HH:mm"
                                onSelect={time =>
                                    this.handleChange({
                                        start: time ?? this.state.dateTimeRange.start,
                                        end: this.state.dateTimeRange.end
                                    })
                                }
                                dropdownAlign={{
                                    points: ['tr', 'br']
                                }}
                            />
                        </Col>
                    )}
                    <Col>
                        <DateRangePicker
                            onChange={({ selection: { startDate, endDate } }) => {
                                this.handleDateChange({
                                    start: moment(startDate as Date),
                                    end: moment(endDate as Date)
                                });
                            }}
                            preventSnapRefocus={true}
                            maxDate={this.getMaxDate()}
                            minDate={this.getMinDate()}
                            months={2}
                            ranges={[
                                {
                                    startDate: this.state.dateTimeRange.start.toDate(),
                                    endDate: this.state.dateTimeRange.end.toDate(),
                                    key: 'selection'
                                }
                            ]}
                            locale={momentLocaleToFns(moment().locale())}
                            shownDate={this.getShownDate()}
                            weekdayDisplayFormat={'EEEEEE'}
                            monthDisplayFormat={'LLLL yyyy'}
                            direction="horizontal"
                            showMonthAndYearPickers={false}
                            showDateDisplay={false}
                            navigatorRenderer={this.navigatorRenderer}
                            fixedHeight
                            onRangeFocusChange={this.handleFocusChange}
                            focusedRange={this.state.focusedRange}
                            dayContentRenderer={this.dayRenderer}
                        />
                    </Col>
                    {showTime && (
                        <Col className="datetime-text to">
                            <div className="date">
                                {this.state.dateTimeRange.end?.format(formatStyle.split(' ')[0])}
                            </div>
                            <div className="time">
                                {this.state.dateTimeRange.end?.format(formatStyle.split(' ')[1])}
                            </div>
                            <DatePickerAnt.TimePicker
                                className="react-date-range-component-time-to"
                                dropdownClassName="react-date-range-component-time-to"
                                value={this.state.dateTimeRange.end}
                                disabledDate={date => date.isBefore(this.state.dateTimeRange.start)}
                                disabledHours={() => this.getDisabledHours(false)}
                                disabledMinutes={() => this.getDisabledMinutes(false)}
                                open={true}
                                defaultOpen={true}
                                format="HH:mm"
                                onSelect={time =>
                                    this.handleChange({
                                        start: this.state.dateTimeRange.start,
                                        end: time ?? this.state.dateTimeRange.end
                                    })
                                }
                                dropdownAlign={{
                                    points: ['tr', 'br']
                                }}
                            />
                        </Col>
                    )}
                </Row>

                <div className="react-date-range-component-tools-bar">
                    {shortcuts && (
                        <Row className={cn('shortcuts-bar', { showTime: showTime })} gutter={12}>
                            {Object.keys(shortcuts).map(key => (
                                <Col xs={Object.keys(shortcuts).length % 3 === 0 ? 8 : 6} key={key}>
                                    <Button
                                        className="btn"
                                        size="middle"
                                        qa={qa.common.dateTimeRangePicker.shortcut}
                                        onClick={() => this.onShortcutClick(shortcuts[key])}
                                    >
                                        {key}
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
                <Row className="react-date-range-component-submit-buttons" justify="end">
                    <Col>
                        <Button
                            size="middle"
                            type="default"
                            onClick={this.onCancel}
                            qa={qa.common.dateTimeRangePicker.cancel}
                        >
                            {cancelButtonText}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            size="middle"
                            onClick={this.onConfirm}
                            qa={qa.common.dateTimeRangePicker.confirm}
                        >
                            {confirmButtonText}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DateTimeRangePickerNew;
