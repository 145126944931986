/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WAGTPActionCodeObeOperation {
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_17 = 17,
    NUMBER_18 = 18
}

export function WAGTPActionCodeObeOperationFromJSON(json: any): WAGTPActionCodeObeOperation {
    return WAGTPActionCodeObeOperationFromJSONTyped(json, false);
}

export function WAGTPActionCodeObeOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WAGTPActionCodeObeOperation {
    return json as WAGTPActionCodeObeOperation;
}

export function WAGTPActionCodeObeOperationToJSON(value?: WAGTPActionCodeObeOperation | null): any {
    return value as any;
}

