import React, { ChangeEvent, Component, FormEvent } from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import {
    defaultValidationErrors,
    UserForm,
    surnameValidator,
    formValidator,
    emailValidator,
    phoneNumberValidator,
    nameValidator
} from '../UsersDetail';
import { Select } from 'common/components/Form';
import { debounce } from 'debounce';
import { SelectValue } from 'antd/lib/select';

interface Props extends WithTranslation {
    model: PartnerUserModel;
    clients?: PartnerCompanySelectModel[];
    onClientSearch?: (text: string) => void;
    onCancel?: () => void;
    onSubmit?: (model: PartnerUserModel) => void;
}

interface State {
    form: UserForm;
}

class UsersCreate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: props.model.id,
                name: props.model.name,
                surname: props.model.surname,
                ssoId: props.model.ssoId ?? '',
                email: props.model.contact?.emails?.[0] ?? '',
                phoneNumber: props.model.contact?.phone_numbers?.[0] ?? '',
                note: props.model.note ?? ''
            }
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="t-modal">
                <form
                    className="partner-pricing-modal-form partner-modal-form t-modal-content"
                    onSubmit={this._onSubmit}
                >
                    <div className="t-bar t-large t-center t-padding-16">
                        <span className="t-uppercase">{t('PartnerUsers.createNewUser')}</span>
                    </div>
                    <div className="t-bar-block">
                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.name')}>
                                {t('common.name')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.nameErr,
                                        success: !this.state.form.nameErr && !!this.state.form.name
                                    })}
                                    onChange={this._onNameChange}
                                    value={this.state.form.name}
                                />
                                {this.state.form.nameErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.nameErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.surname')}>
                                {t('common.surname')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.surnameErr,
                                        success: !this.state.form.surnameErr && !!this.state.form.surname
                                    })}
                                    onChange={this._onSurnameChange}
                                    value={this.state.form.surname}
                                />
                                {this.state.form.surnameErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.surnameErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.company')}>
                                {t('common.company')}
                            </div>
                            <div className="t-half">
                                <Select
                                    showSearch
                                    placeholder={t('common.select')}
                                    value={this.state.form?.clientId ? this.state.form?.clientId : undefined}
                                    filterOption={false}
                                    size="small"
                                    options={this.props.clients?.map(v => ({ value: v.id, label: v.label }))}
                                    onChange={this._onClientChange}
                                    onSearch={this._onClientSearch}
                                />
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.email')}>
                                {t('common.email')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.emailErr,
                                        success: !this.state.form.emailErr && !!this.state.form.email
                                    })}
                                    onChange={this._onEmailChange}
                                    value={this.state.form.email}
                                />
                                <span className="t-bold t-text-danger">{this.state.form.emailErr}</span>
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.phone')}>
                                {t('common.phone')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.phoneNumberErr,
                                        success: !this.state.form.phoneNumberErr && !!this.state.form.phoneNumber
                                    })}
                                    onChange={this._onPhoneNumberChange}
                                    value={this.state.form.phoneNumber}
                                />
                                <span className="t-bold t-text-danger">{this.state.form.emailErr}</span>
                            </div>
                        </div>
                    </div>
                    <footer className="t-row t-margin">
                        <div className="t-half t-padding-tiny">
                            <button
                                className="t-right t-btn t-primary-secondary t-ripple"
                                type="button"
                                onClick={this._onCancel}
                            >
                                {t('common.cancel')}
                            </button>
                        </div>
                        <div className="t-half t-padding-tiny">
                            <button className="t-left t-primary t-btn t-ripple" type="submit">
                                {t('common.save')}
                            </button>
                        </div>
                    </footer>
                </form>
            </div>
        );
    }

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const validationRes = formValidator.validate({
            name: this.state.form.name,
            surname: this.state.form.surname,
            email: this.state.form.email,
            phoneNumber: this.state.form.phoneNumber
        });

        if (validationRes.valid) {
            const modelCreate: PartnerUserModel = {
                id: '',
                name: this.state.form.name,
                surname: this.state.form.surname,
                contact: {
                    emails: [this.state.form.email],
                    phone_numbers: [this.state.form.phoneNumber]
                },
                clientId: Number(this.state.form.clientId)
            };
            this.props.onSubmit?.(modelCreate);
        } else {
            this.setState(state => ({
                form: {
                    ...state.form!,
                    nameErr: validationRes.err?.name ?? '',
                    surnameErr: validationRes.err?.surname ?? '',
                    emailErr: validationRes.err?.email ?? '',
                    phoneNumberErr: validationRes.err?.phoneNumber ?? ''
                }
            }));
        }
    };

    private _onNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = nameValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                name: value,
                nameErr: validateRes.err as string
            }
        }));
    };

    private _onSurnameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = surnameValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                surname: value,
                surnameErr: validateRes.err as string
            }
        }));
    };

    private _onEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = emailValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                email: value,
                emailErr: validateRes.err as string
            }
        }));
    };

    private _onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = phoneNumberValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                phoneNumber: value,
                phoneNumberErr: validateRes.err as string
            }
        }));
    };

    private _onClientChange = (value: SelectValue) => {
        this.setState(state => ({
            form: {
                ...state.form!,
                client: String(value)
            }
        }));
    };

    private _onClientSearch = debounce((text: string) => {
        this.props.onClientSearch?.(text);
    }, 4e2);
}

export default withTranslation()(UsersCreate);
