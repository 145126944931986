/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2009,
    InlineResponse2009FromJSON,
    InlineResponse2009ToJSON,
    ReadOnlyDriver,
    ReadOnlyDriverFromJSON,
    ReadOnlyDriverToJSON,
    WriteOnlyDriver,
    WriteOnlyDriverFromJSON,
    WriteOnlyDriverToJSON,
} from '../models';

export interface DriverCreateRequest {
    data: WriteOnlyDriver;
}

export interface DriverDeleteRequest {
    id: number;
}

export interface DriverListRequest {
    clientIn?: string;
    id?: number;
    nameSurname?: string;
    limit?: number;
    offset?: number;
}

export interface DriverPartialUpdateRequest {
    id: number;
    data: WriteOnlyDriver;
}

export interface DriverReadRequest {
    id: number;
}

export interface DriverUpdateRequest {
    id: number;
    data: WriteOnlyDriver;
}

/**
 * no description
 */
export class DriverApi extends runtime.BaseAPI {

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverCreateRaw(requestParameters: DriverCreateRequest): Promise<runtime.ApiResponse<ReadOnlyDriver>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverFromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverCreate(requestParameters: DriverCreateRequest): Promise<ReadOnlyDriver> {
        const response = await this.driverCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverDeleteRaw(requestParameters: DriverDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyDriver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverFromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverDelete(requestParameters: DriverDeleteRequest): Promise<ReadOnlyDriver> {
        const response = await this.driverDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverListRaw(requestParameters: DriverListRequest): Promise<runtime.ApiResponse<InlineResponse2009>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientIn !== undefined) {
            queryParameters['client__in'] = requestParameters.clientIn;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.nameSurname !== undefined) {
            queryParameters['name_surname'] = requestParameters.nameSurname;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2009FromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverList(requestParameters: DriverListRequest): Promise<InlineResponse2009> {
        const response = await this.driverListRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverPartialUpdateRaw(requestParameters: DriverPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDriver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverFromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverPartialUpdate(requestParameters: DriverPartialUpdateRequest): Promise<ReadOnlyDriver> {
        const response = await this.driverPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverReadRaw(requestParameters: DriverReadRequest): Promise<runtime.ApiResponse<ReadOnlyDriver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverFromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverRead(requestParameters: DriverReadRequest): Promise<ReadOnlyDriver> {
        const response = await this.driverReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverUpdateRaw(requestParameters: DriverUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDriver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverFromJSON(jsonValue));
    }

    /**
     * retrieves just a minimal set of fields for drivers
     */
    async driverUpdate(requestParameters: DriverUpdateRequest): Promise<ReadOnlyDriver> {
        const response = await this.driverUpdateRaw(requestParameters);
        return await response.value();
    }

}
