import { ClientApplication } from 'generated/main-data-api';
import { vehicles } from './vehicles';

export const customerApi: ClientApplication[] = [
    {
        id: 1,
        name: 'Tester One API key',
        apiKey: 'c8d5-6621-c8e3a10984214',
        filter: vehicles.map(v => String(v.id)),
        clientId: '100'
    }
];
