/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WearReportSummary
 */
export interface WearReportSummary {
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1RMaxRet: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1TDrvBrRet20: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvBr: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvRet20: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvParkBr: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvRet20DDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1TDrvBrRet20TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1DDrvBrDDrvRet20: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntRetExhCntRetLnCntRetEngDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1RMaxDrvLoad: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    rw1TDrvBrRet20TDrv?: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    rw1CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    rw1DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    sw1TDrvBrRet20TDrv?: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    sw1CntBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    sw1DDrvBrDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntRetExh: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntRetLn: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntRetEng: number;
    /**
     * 
     * @type {number}
     * @memberof WearReportSummary
     */
    fw1CntBr: number;
}

export function WearReportSummaryFromJSON(json: any): WearReportSummary {
    return WearReportSummaryFromJSONTyped(json, false);
}

export function WearReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WearReportSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fw1RMaxRet': json['fw1_r_max_ret'],
        'fw1TDrvBrRet20': json['fw1_t_drv_br_ret_20'],
        'fw1DDrvBr': json['fw1_d_drv_br'],
        'fw1DDrvRet20': json['fw1_d_drv_ret_20'],
        'fw1DDrvParkBr': json['fw1_d_drv_park_br'],
        'fw1CntBrDDrv': json['fw1_cnt_br_d_drv'],
        'fw1DDrvBrDDrv': json['fw1_d_drv_br_d_drv'],
        'fw1DDrvRet20DDrv': json['fw1_d_drv_ret_20_d_drv'],
        'fw1TDrvBrRet20TDrv': json['fw1_t_drv_br_ret_20_t_drv'],
        'fw1DDrvBrDDrvRet20': json['fw1_d_drv_br_d_drv_ret_20'],
        'fw1CntRetExhCntRetLnCntRetEngDDrv': json['fw1_cnt_ret_exh_cnt_ret_ln_cnt_ret_eng_d_drv'],
        'fw1RMaxDrvLoad': json['fw1_r_max_drv_load'],
        'rw1TDrvBrRet20TDrv': !exists(json, 'rw1_t_drv_br_ret_20_t_drv') ? undefined : json['rw1_t_drv_br_ret_20_t_drv'],
        'rw1CntBrDDrv': json['rw1_cnt_br_d_drv'],
        'rw1DDrvBrDDrv': json['rw1_d_drv_br_d_drv'],
        'sw1TDrvBrRet20TDrv': !exists(json, 'sw1_t_drv_br_ret_20_t_drv') ? undefined : json['sw1_t_drv_br_ret_20_t_drv'],
        'sw1CntBrDDrv': json['sw1_cnt_br_d_drv'],
        'sw1DDrvBrDDrv': json['sw1_d_drv_br_d_drv'],
        'fw1CntRetExh': json['fw1_cnt_ret_exh'],
        'fw1CntRetLn': json['fw1_cnt_ret_ln'],
        'fw1CntRetEng': json['fw1_cnt_ret_eng'],
        'fw1CntBr': json['fw1_cnt_br'],
    };
}

export function WearReportSummaryToJSON(value?: WearReportSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fw1_r_max_ret': value.fw1RMaxRet,
        'fw1_t_drv_br_ret_20': value.fw1TDrvBrRet20,
        'fw1_d_drv_br': value.fw1DDrvBr,
        'fw1_d_drv_ret_20': value.fw1DDrvRet20,
        'fw1_d_drv_park_br': value.fw1DDrvParkBr,
        'fw1_cnt_br_d_drv': value.fw1CntBrDDrv,
        'fw1_d_drv_br_d_drv': value.fw1DDrvBrDDrv,
        'fw1_d_drv_ret_20_d_drv': value.fw1DDrvRet20DDrv,
        'fw1_t_drv_br_ret_20_t_drv': value.fw1TDrvBrRet20TDrv,
        'fw1_d_drv_br_d_drv_ret_20': value.fw1DDrvBrDDrvRet20,
        'fw1_cnt_ret_exh_cnt_ret_ln_cnt_ret_eng_d_drv': value.fw1CntRetExhCntRetLnCntRetEngDDrv,
        'fw1_r_max_drv_load': value.fw1RMaxDrvLoad,
        'rw1_t_drv_br_ret_20_t_drv': value.rw1TDrvBrRet20TDrv,
        'rw1_cnt_br_d_drv': value.rw1CntBrDDrv,
        'rw1_d_drv_br_d_drv': value.rw1DDrvBrDDrv,
        'sw1_t_drv_br_ret_20_t_drv': value.sw1TDrvBrRet20TDrv,
        'sw1_cnt_br_d_drv': value.sw1CntBrDDrv,
        'sw1_d_drv_br_d_drv': value.sw1DDrvBrDDrv,
        'fw1_cnt_ret_exh': value.fw1CntRetExh,
        'fw1_cnt_ret_ln': value.fw1CntRetLn,
        'fw1_cnt_ret_eng': value.fw1CntRetEng,
        'fw1_cnt_br': value.fw1CntBr,
    };
}


