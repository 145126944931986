/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDeviceMonitoredObjectState,
    ExternalDeviceMonitoredObjectStateFromJSON,
    ExternalDeviceMonitoredObjectStateFromJSONTyped,
    ExternalDeviceMonitoredObjectStateToJSON,
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeFromJSONTyped,
    ExternalDeviceTypeToJSON
} from './';

/**
 *
 * @export
 * @interface ExternalDeviceState
 */
export interface ExternalDeviceState {
    /**
     *
     * @type {string}
     * @memberof ExternalDeviceState
     */
    deviceId: string;
    /**
     *
     * @type {ExternalDeviceType}
     * @memberof ExternalDeviceState
     */
    deviceType: ExternalDeviceType;
    /**
     *
     * @type {object}
     * @memberof ExternalDeviceState
     */
    data?: object;
    /**
     *
     * @type {ExternalDeviceMonitoredObjectState}
     * @memberof ExternalDeviceState
     */
    monitoredObjectState: ExternalDeviceMonitoredObjectState;
}

export function ExternalDeviceStateFromJSON(json: any): ExternalDeviceState {
    return ExternalDeviceStateFromJSONTyped(json, false);
}

export function ExternalDeviceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDeviceState {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        deviceId: json['device_id'],
        deviceType: ExternalDeviceTypeFromJSON(json['device_type']),
        data: !exists(json, 'data') ? undefined : json['data'],
        monitoredObjectState: ExternalDeviceMonitoredObjectStateFromJSON(json['monitored_object_state'])
    };
}

export function ExternalDeviceStateToJSON(value?: ExternalDeviceState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        device_id: value.deviceId,
        device_type: ExternalDeviceTypeToJSON(value.deviceType),
        data: value.data,
        monitored_object_state: ExternalDeviceMonitoredObjectStateToJSON(value.monitoredObjectState)
    };
}
