import { Tooltip } from 'common/components';
import cn from 'classnames';

export interface DriverBehaviorProgressBarValue {
    label?: string;
    title?: string;
    value: number;
    color: string;
}

interface Props {
    values?: DriverBehaviorProgressBarValue[];
    tooltipClassName?: string;
    qa?: string;
}

export default function DriverBehaviorProgressBar(props: Props) {
    const values = props.values ?? [];
    const maxValue = values?.length > 0 ? values.map(v => v.value).reduce((sum, value) => sum + value) ?? 0 : 0;

    return (
        <div className="driver-behavior-progress-bar" data-qa={props.qa}>
            {values?.map((v, index) => {
                const value = v.value > 0 && maxValue ? v.value / (maxValue / 100) : 0;
                const style = {
                    width: `${value}%`,
                    backgroundColor: v.color
                };
                return (
                    <Tooltip
                        key={index}
                        overlayClassName={cn(
                            `driver-behavior-progress-bar-tooltip${
                                props.tooltipClassName ? ` ${props.tooltipClassName}` : ''
                            }`
                        )}
                        title={v.label ? `${v.label} ${v.title}` : v.title}
                    >
                        <div className="driver-behavior-progress-bar-value" style={style} />
                    </Tooltip>
                );
            })}
        </div>
    );
}
