export enum AvailableCurrencies {
    BGN = 'BGN',
    CZK = 'CZK',
    DKK = 'DKK',
    EUR = 'EUR',
    HUF = 'HUF',
    CHF = 'CHF',
    PLN = 'PLN',
    NOK = 'NOK',
    RUB = 'RUB',
    SEK = 'SEK'
}
