import { TIME_FORMAT_SHORT } from 'domain-constants';
import moment from 'moment';
import { useState } from 'react';
import { getColorByIndex } from './externalDevicesHelpers';
import { ExternalDevicesConfig } from './useExternalDevicesConfig';
import { JourneyGraphDataModel } from '../../../JourneysModule';

export const useComplexGraphData = (journeyGraphData?: JourneyGraphDataModel) => {
    const [coloredLineKey, setColoredLineKey] = useState<Record<string, string>>({});

    const resetLineColors = (data: Record<string, string | number>) => {
        const raw = { ...coloredLineKey };

        Object.keys(data)
            .filter(key => key !== 'timestamp')
            .map((key, index) => {
                if (raw[key]) {
                    return raw;
                } else {
                    return (raw[key] = getColorByIndex(index));
                }
            });

        return setColoredLineKey(raw);
    };

    const getFormattedExternalDevicesData = (config: ExternalDevicesConfig) => {
        return [journeyGraphData].flatMap(item => {
            return (
                item?.timestampsTemperatures?.map((stamp, index) => {
                    const dataObject = {
                        timestamp: moment.unix(stamp).format(TIME_FORMAT_SHORT)
                    };
                    config.selectedSensors.forEach(sensorSerial => {
                        const value = item?.temperatures?.[sensorSerial]?.[index];

                        if (value && sensorSerial) {
                            dataObject[sensorSerial] = value;
                        }
                    });

                    return dataObject;
                }) ?? {}
            );
        });
    };

    return { coloredLineKey, resetLineColors, getFormattedExternalDevicesData };
};
