/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CostProfileItem
 */
export interface CostProfileItem {
    /**
     * 
     * @type {string}
     * @memberof CostProfileItem
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof CostProfileItem
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof CostProfileItem
     */
    unit: string;
}

export function CostProfileItemFromJSON(json: any): CostProfileItem {
    return CostProfileItemFromJSONTyped(json, false);
}

export function CostProfileItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostProfileItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function CostProfileItemToJSON(value?: CostProfileItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'value': value.value,
        'unit': value.unit,
    };
}


