/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlarmInDatabaseWithGPSInfo,
    AlarmInDatabaseWithGPSInfoFromJSON,
    AlarmInDatabaseWithGPSInfoFromJSONTyped,
    AlarmInDatabaseWithGPSInfoToJSON,
    PuescEwMissionStatus,
    PuescEwMissionStatusFromJSON,
    PuescEwMissionStatusFromJSONTyped,
    PuescEwMissionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface PuescEwMissionStatusResponse
 */
export interface PuescEwMissionStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof PuescEwMissionStatusResponse
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof PuescEwMissionStatusResponse
     */
    transportId?: string;
    /**
     * 
     * @type {Array<AlarmInDatabaseWithGPSInfo>}
     * @memberof PuescEwMissionStatusResponse
     */
    alarms: Array<AlarmInDatabaseWithGPSInfo>;
    /**
     * 
     * @type {boolean}
     * @memberof PuescEwMissionStatusResponse
     */
    monitoringHealthy: boolean;
    /**
     * 
     * @type {PuescEwMissionStatus}
     * @memberof PuescEwMissionStatusResponse
     */
    status: PuescEwMissionStatus;
}

export function PuescEwMissionStatusResponseFromJSON(json: any): PuescEwMissionStatusResponse {
    return PuescEwMissionStatusResponseFromJSONTyped(json, false);
}

export function PuescEwMissionStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PuescEwMissionStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'transportId': !exists(json, 'transport_id') ? undefined : json['transport_id'],
        'alarms': ((json['alarms'] as Array<any>).map(AlarmInDatabaseWithGPSInfoFromJSON)),
        'monitoringHealthy': json['monitoring_healthy'],
        'status': PuescEwMissionStatusFromJSON(json['status']),
    };
}

export function PuescEwMissionStatusResponseToJSON(value?: PuescEwMissionStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'transport_id': value.transportId,
        'alarms': ((value.alarms as Array<any>).map(AlarmInDatabaseWithGPSInfoToJSON)),
        'monitoring_healthy': value.monitoringHealthy,
        'status': PuescEwMissionStatusToJSON(value.status),
    };
}


