/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Output,
    OutputFromJSON,
    OutputToJSON,
} from '../models';

export interface DiemsDebugV1DiemsDiemsGetRequest {
    start: Date;
    end: Date;
    driverId?: number;
}

/**
 * no description
 */
export class DiemsApi extends runtime.BaseAPI {

    /**
     * Diems Debug
     */
    async diemsDebugV1DiemsDiemsGetRaw(requestParameters: DiemsDebugV1DiemsDiemsGetRequest): Promise<runtime.ApiResponse<Output>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling diemsDebugV1DiemsDiemsGet.');
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError('end','Required parameter requestParameters.end was null or undefined when calling diemsDebugV1DiemsDiemsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/diems/diems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputFromJSON(jsonValue));
    }

    /**
     * Diems Debug
     */
    async diemsDebugV1DiemsDiemsGet(requestParameters: DiemsDebugV1DiemsDiemsGetRequest): Promise<Output> {
        const response = await this.diemsDebugV1DiemsDiemsGetRaw(requestParameters);
        return await response.value();
    }

}
