/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CardReaderStateStr,
    CardReaderStateStrFromJSON,
    CardReaderStateStrToJSON,
    DTCODownloadedFileApiResult,
    DTCODownloadedFileApiResultFromJSON,
    DTCODownloadedFileApiResultToJSON,
    DtcoDownloadLink,
    DtcoDownloadLinkFromJSON,
    DtcoDownloadLinkToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface CardreaderStateV1DtcoCardreaderStateGetRequest {
    cardReaderSn: Array<string>;
    device?: string;
    user?: string;
}

export interface GenerateDtcoArchiveV1DtcoFilesArchiveGetRequest {
    documentId: Array<string>;
}

export interface GetDtcoArchiveV1DtcoFilesArchiveArchiveIdGetRequest {
    archiveId: string;
}

export interface GetDtcoDriverV1DtcoFilesDriverGetRequest {
    timestampFrom: Date;
    timestampTo: Date;
}

export interface GetDtcoLinkV1DtcoFilesDocumentIdLinkGetRequest {
    documentId: string;
}

export interface GetDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGetRequest {
    timestampFrom: Date;
    timestampTo: Date;
}

/**
 * no description
 */
export class DtcoApi extends runtime.BaseAPI {

    /**
     * Cardreader State
     */
    // async cardreaderStateV1DtcoCardreaderStateGetRaw(requestParameters: CardreaderStateV1DtcoCardreaderStateGetRequest): Promise<runtime.ApiResponse<{ [key: string]: CardReaderStateStr; }>> {
    //     if (requestParameters.cardReaderSn === null || requestParameters.cardReaderSn === undefined) {
    //         throw new runtime.RequiredError('cardReaderSn','Required parameter requestParameters.cardReaderSn was null or undefined when calling cardreaderStateV1DtcoCardreaderStateGet.');
    //     }

    //     const queryParameters: runtime.HTTPQuery = {};

    //     if (requestParameters.cardReaderSn) {
    //         queryParameters['card_reader_sn'] = requestParameters.cardReaderSn;
    //     }

    //     if (requestParameters.device !== undefined) {
    //         queryParameters['device'] = requestParameters.device;
    //     }

    //     if (requestParameters.user !== undefined) {
    //         queryParameters['user'] = requestParameters.user;
    //     }

    //     const headerParameters: runtime.HTTPHeaders = {};

    //     if (this.configuration && this.configuration.accessToken) {
    //         const token = this.configuration.accessToken;
    //         const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

    //         if (tokenString) {
    //             headerParameters["Authorization"] = `Bearer ${tokenString}`;
    //         }
    //     }
    //     const response = await this.request({
    //         path: `/v1/dtco/cardreader/state`,
    //         method: 'GET',
    //         headers: headerParameters,
    //         query: queryParameters,
    //     });

    //     return new runtime.JSONApiResponse(response, (jsonValue) => CardReaderStateStrFromJSON(jsonValue));
    // }

    // /**
    //  * Cardreader State
    //  */
    // async cardreaderStateV1DtcoCardreaderStateGet(requestParameters: CardreaderStateV1DtcoCardreaderStateGetRequest): Promise<{ [key: string]: CardReaderStateStr; }> {
    //     const response = await this.cardreaderStateV1DtcoCardreaderStateGetRaw(requestParameters);
    //     return await response.value();
    // }

    /**
     * Get Dtco files archive link
     */
    async generateDtcoArchiveV1DtcoFilesArchiveGetRaw(requestParameters: GenerateDtcoArchiveV1DtcoFilesArchiveGetRequest): Promise<runtime.ApiResponse<DtcoDownloadLink>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling generateDtcoArchiveV1DtcoFilesArchiveGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.documentId) {
            queryParameters['document_id'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/files/archive/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DtcoDownloadLinkFromJSON(jsonValue));
    }

    /**
     * Get Dtco files archive link
     */
    async generateDtcoArchiveV1DtcoFilesArchiveGet(requestParameters: GenerateDtcoArchiveV1DtcoFilesArchiveGetRequest): Promise<DtcoDownloadLink> {
        const response = await this.generateDtcoArchiveV1DtcoFilesArchiveGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Dtco files archive
     */
    async getDtcoArchiveV1DtcoFilesArchiveArchiveIdGetRaw(requestParameters: GetDtcoArchiveV1DtcoFilesArchiveArchiveIdGetRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.archiveId === null || requestParameters.archiveId === undefined) {
            throw new runtime.RequiredError('archiveId','Required parameter requestParameters.archiveId was null or undefined when calling getDtcoArchiveV1DtcoFilesArchiveArchiveIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/files/archive/{archive_id}`.replace(`{${"archive_id"}}`, encodeURIComponent(String(requestParameters.archiveId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Dtco files archive
     */
    async getDtcoArchiveV1DtcoFilesArchiveArchiveIdGet(requestParameters: GetDtcoArchiveV1DtcoFilesArchiveArchiveIdGetRequest): Promise<object> {
        const response = await this.getDtcoArchiveV1DtcoFilesArchiveArchiveIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get DTCO driver
     */
    async getDtcoDriverV1DtcoFilesDriverGetRaw(requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest): Promise<runtime.ApiResponse<Array<DTCODownloadedFileApiResult>>> {
        if (requestParameters.timestampFrom === null || requestParameters.timestampFrom === undefined) {
            throw new runtime.RequiredError('timestampFrom','Required parameter requestParameters.timestampFrom was null or undefined when calling getDtcoDriverV1DtcoFilesDriverGet.');
        }

        if (requestParameters.timestampTo === null || requestParameters.timestampTo === undefined) {
            throw new runtime.RequiredError('timestampTo','Required parameter requestParameters.timestampTo was null or undefined when calling getDtcoDriverV1DtcoFilesDriverGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestampFrom !== undefined) {
            queryParameters['timestamp_from'] = (requestParameters.timestampFrom as any).toISOString();
        }

        if (requestParameters.timestampTo !== undefined) {
            queryParameters['timestamp_to'] = (requestParameters.timestampTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/files/driver/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DTCODownloadedFileApiResultFromJSON));
    }

    /**
     * Get DTCO driver
     */
    async getDtcoDriverV1DtcoFilesDriverGet(requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest): Promise<Array<DTCODownloadedFileApiResult>> {
        const response = await this.getDtcoDriverV1DtcoFilesDriverGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Dtco file download link
     */
    async getDtcoLinkV1DtcoFilesDocumentIdLinkGetRaw(requestParameters: GetDtcoLinkV1DtcoFilesDocumentIdLinkGetRequest): Promise<runtime.ApiResponse<DtcoDownloadLink>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDtcoLinkV1DtcoFilesDocumentIdLinkGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/files/{document_id}/link/`.replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DtcoDownloadLinkFromJSON(jsonValue));
    }

    /**
     * Get Dtco file download link
     */
    async getDtcoLinkV1DtcoFilesDocumentIdLinkGet(requestParameters: GetDtcoLinkV1DtcoFilesDocumentIdLinkGetRequest): Promise<DtcoDownloadLink> {
        const response = await this.getDtcoLinkV1DtcoFilesDocumentIdLinkGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get DTCO monitored objects
     */
    async getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGetRaw(requestParameters: GetDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGetRequest): Promise<runtime.ApiResponse<Array<DTCODownloadedFileApiResult>>> {
        if (requestParameters.timestampFrom === null || requestParameters.timestampFrom === undefined) {
            throw new runtime.RequiredError('timestampFrom','Required parameter requestParameters.timestampFrom was null or undefined when calling getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGet.');
        }

        if (requestParameters.timestampTo === null || requestParameters.timestampTo === undefined) {
            throw new runtime.RequiredError('timestampTo','Required parameter requestParameters.timestampTo was null or undefined when calling getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.timestampFrom !== undefined) {
            queryParameters['timestamp_from'] = (requestParameters.timestampFrom as any).toISOString();
        }

        if (requestParameters.timestampTo !== undefined) {
            queryParameters['timestamp_to'] = (requestParameters.timestampTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/files/monitored-object/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DTCODownloadedFileApiResultFromJSON));
    }

    /**
     * Get DTCO monitored objects
     */
    async getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGet(requestParameters: GetDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGetRequest): Promise<Array<DTCODownloadedFileApiResult>> {
        const response = await this.getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Health
     */
    async healthV1DtcoHealthGetRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/dtco/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Health
     */
    async healthV1DtcoHealthGet(): Promise<object> {
        const response = await this.healthV1DtcoHealthGetRaw();
        return await response.value();
    }

}
