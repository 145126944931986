import { Logic } from 'logic/logic';
import { PairingLink, PartnerPairingKey } from 'common/model/partner-pairing';
import {
    MonitoredObject,
    ReadOnlyMonitoredObjectType,
    ReadOnlyExternalSystem,
    ReadOnlyMonitoredObjectNested,
    MonitoredObjectType
} from 'generated/new-main';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { EFuelType } from 'generated/graphql';

export interface PartnerVehicleModel {
    id: string;
    licensePlate: string;
    // commissionedDate: Moment;
    odometer?: number;
    brand?: string;
    type?: MonitoredObjectType;
    typeId?: number;
    fuelTank?: number;
    externalId?: string;
    externalSystem?: number;
    cngTank?: number;
    modules?: string[];
    company?: string;
    companyName?: string;
    links?: PairingLink[];
    fuelType?: EFuelType;
    disabledAt?: Date;
}

export class PartnerVehiclesLogic {
    constructor(private _logic: Logic) {}

    async getVehicleList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        registrationNumberString?: string
    ): Promise<PaginatedResponse<PartnerVehicleModel[]>> {
        try {
            const response = await this._logic.api().monitoredObjectApi.monitoredObjectNested({
                client: clientId,
                limit,
                offset,
                registrationNumberIcontains: registrationNumberString
            });
            return {
                data: response.results.map(res => this._toVehicleNested(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Monitored object list GET err:', err);
            throw err;
        }
    }

    async editVehicle(vehicle: PartnerVehicleModel, externalSystem: number): Promise<PartnerVehicleModel> {
        try {
            const response = await this._logic.api().monitoredObjectApi.monitoredObjectUpdate({
                id: Number(vehicle.id),
                data: {
                    externalSystem,
                    clientId: Number(vehicle.company),
                    tankSize: vehicle.fuelTank,
                    externalId: `${vehicle.company}_${vehicle.licensePlate}`,
                    manufacturerName: vehicle.brand,
                    registrationNumber: vehicle.licensePlate,
                    monitoredObjectTypeId: vehicle.typeId
                }
            });
            return this._toVehicle(response);
        } catch (err) {
            console.log('Monitored object POST err:', err);
            throw err;
        }
    }

    async deleteVehicle(vehicle: PartnerVehicleModel): Promise<Boolean> {
        try {
            await this._logic.api().monitoredObjectApi.monitoredObjectDelete({ id: Number(vehicle.id) });
            return true;
        } catch (err) {
            console.log('Monitored object DELETE err:', err);
            throw err;
        }
    }

    async getMonitoredObjectsTypes(): Promise<ReadOnlyMonitoredObjectType[]> {
        try {
            const resp = await this._logic.vehicles().vehicleTypes().getMonitoredObjectsTypes();
            return resp;
        } catch (err) {
            console.log('Monitored object GET err:', err);
            throw err;
        }
    }

    async getExternalSystems(): Promise<ReadOnlyExternalSystem[]> {
        try {
            const resp = await this._logic.api().externalSystemTypeApi.externalSystemList({});
            return resp.results;
        } catch (err) {
            console.log('External systems GET err:', err);
            throw err;
        }
    }

    async createVehicle(vehicle: PartnerVehicleModel, externalSystem: number): Promise<PartnerVehicleModel> {
        try {
            const response = await this._logic.api().monitoredObjectApi.monitoredObjectCreate({
                data: {
                    clientId: Number(vehicle.company),
                    tankSize: vehicle.fuelTank,
                    externalId: `${vehicle.company}_${vehicle.licensePlate}`,
                    externalSystem,
                    manufacturerName: vehicle.brand,
                    registrationNumber: vehicle.licensePlate,
                    monitoredObjectTypeId: vehicle.typeId!
                    // TODO: CNG boolean
                }
            });
            return this._toVehicle(response);
        } catch (err) {
            console.log('Monitored object POST err:', err);
            throw err;
        }
    }

    private _toVehicle(data: MonitoredObject): PartnerVehicleModel {
        return {
            id: String(data.id) ?? '',
            licensePlate: data.registrationNumber,
            // commissionedDate: moment(),
            odometer: 0, // TODO hardcoded
            brand: data.manufacturerName ?? '',
            type: data.monitoredObjectType,
            typeId: data.monitoredObjectTypeId,
            externalId: data.externalId ?? undefined,
            fuelTank: data.tankSize ? data.tankSize : undefined,
            cngTank: 600, // TODO hardcoded
            company: String(data.client?.id) ?? '',
            companyName: data.client?.name ?? '',
            externalSystem: data.externalSystem ?? 0,
            modules: [] // TODO hardcoded modules
        };
    }

    private _toVehicleNested(data: ReadOnlyMonitoredObjectNested): PartnerVehicleModel {
        return {
            id: String(data.id) ?? '',
            licensePlate: data.registrationNumber,
            // commissionedDate: moment(),
            odometer: 0, // TODO hardcoded
            brand: data.manufacturerName ?? '',
            type: data.monitoredObjectType,
            typeId: data.monitoredObjectTypeId,
            externalId: data.externalId ?? undefined,
            fuelTank: data.tankSize ? data.tankSize : undefined,
            cngTank: 600, // TODO hardcoded
            company: String(data.client?.id) ?? '',
            companyName: data.client?.name ?? '',
            externalSystem: data.externalSystem ?? 0,
            modules: data.monitoringDevice?.modules ?? [],
            fuelType: (data.metadata as any)?.profile?.fuelType.v,
            disabledAt: data.disabledAt ?? undefined,
            links: data.monitoringDevice
                ? [
                      {
                          key: PartnerPairingKey.obu,
                          id: String(data.monitoringDevice.id),
                          rows: [
                              {
                                  name: 'sn',
                                  value: data.monitoringDevice.serialNumber
                              }
                          ]
                      }
                  ]
                : undefined
        };
    }
}

export const sampleVehicles: PartnerVehicleModel[] = [
    {
        id: 'vehi-a',
        licensePlate: 'NZ 557 KL',
        // commissionedDate: moment(),
        odometer: 298154,
        brand: 'Volvo',
        typeId: 0,
        fuelTank: 500,
        cngTank: 600,
        company: '',
        modules: ['MODULE 0', 'MODULE 1'],
        links: [
            {
                key: PartnerPairingKey.companies,
                id: 'comp-a',
                rows: [
                    {
                        name: 'name',
                        value: 'Comp A'
                    }
                ]
            },
            {
                key: PartnerPairingKey.obu,
                id: 'obu-a',
                rows: [
                    {
                        name: 'sn',
                        value: '121354654'
                    }
                ]
            }
        ]
    },
    {
        id: 'vehi-b',
        licensePlate: 'NZ 558 KL',
        // commissionedDate: moment(),
        odometer: 298154,
        brand: 'Volvo',
        typeId: 0,
        fuelTank: 500,
        cngTank: 600,
        company: '',
        modules: ['MODULE 0', 'MODULE 1']
    }
];
