/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyMonitoredObjectV2Serializer,
    ReadOnlyMonitoredObjectV2SerializerFromJSON,
    ReadOnlyMonitoredObjectV2SerializerToJSON,
} from '../models';

export interface MonitoredObjectV2ListRequest {
    monitoredObjectTypeId?: string;
    externalId?: string;
    externalSystem?: string;
}

export interface MonitoredObjectV2ReadRequest {
    id: string;
}

/**
 * no description
 */
export class MonitoredObjectV2Api extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectV2ListRaw(requestParameters: MonitoredObjectV2ListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyMonitoredObjectV2Serializer>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectTypeId !== undefined) {
            queryParameters['monitored_object_type_id'] = requestParameters.monitoredObjectTypeId;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-v2/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyMonitoredObjectV2SerializerFromJSON));
    }

    /**
     */
    async monitoredObjectV2List(requestParameters: MonitoredObjectV2ListRequest): Promise<Array<ReadOnlyMonitoredObjectV2Serializer>> {
        const response = await this.monitoredObjectV2ListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectV2ReadRaw(requestParameters: MonitoredObjectV2ReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectV2Serializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectV2Read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-v2/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectV2SerializerFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectV2Read(requestParameters: MonitoredObjectV2ReadRequest): Promise<ReadOnlyMonitoredObjectV2Serializer> {
        const response = await this.monitoredObjectV2ReadRaw(requestParameters);
        return await response.value();
    }

}
