/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyMonitoringDevice
 */
export interface WriteOnlyMonitoringDevice {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoringDevice
     */
    clientId: number;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyMonitoringDevice
     */
    permissions?: object;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyMonitoringDevice
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoringDevice
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyMonitoringDevice
     */
    externalVehicleId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyMonitoringDevice
     */
    isEva?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoringDevice
     */
    monitoringDeviceType: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoringDevice
     */
    monitoringDeviceStatus?: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyMonitoringDevice
     */
    externalSystem?: number | null;
}

export function WriteOnlyMonitoringDeviceFromJSON(json: any): WriteOnlyMonitoringDevice {
    return WriteOnlyMonitoringDeviceFromJSONTyped(json, false);
}

export function WriteOnlyMonitoringDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyMonitoringDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['client_id'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'serialNumber': json['serial_number'],
        'externalVehicleId': !exists(json, 'external_vehicle_id') ? undefined : json['external_vehicle_id'],
        'isEva': !exists(json, 'is_eva') ? undefined : json['is_eva'],
        'monitoringDeviceType': json['monitoring_device_type'],
        'monitoringDeviceStatus': !exists(json, 'monitoring_device_status') ? undefined : json['monitoring_device_status'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
    };
}

export function WriteOnlyMonitoringDeviceToJSON(value?: WriteOnlyMonitoringDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'permissions': value.permissions,
        'metadata': value.metadata,
        'serial_number': value.serialNumber,
        'external_vehicle_id': value.externalVehicleId,
        'is_eva': value.isEva,
        'monitoring_device_type': value.monitoringDeviceType,
        'monitoring_device_status': value.monitoringDeviceStatus,
        'external_system': value.externalSystem,
    };
}


