/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
    ReadOnlyMobileDeviceOrdered,
    ReadOnlyMobileDeviceOrderedFromJSON,
    ReadOnlyMobileDeviceOrderedToJSON,
    WriteOnlyMobileDeviceOrdered,
    WriteOnlyMobileDeviceOrderedFromJSON,
    WriteOnlyMobileDeviceOrderedToJSON,
} from '../models';

export interface MobileDeviceOrderedCreateRequest {
    data: WriteOnlyMobileDeviceOrdered;
}

export interface MobileDeviceOrderedDeleteRequest {
    id: string;
}

export interface MobileDeviceOrderedListRequest {
    id?: string;
    type?: string;
    imei?: string;
    simIccid?: string;
    client?: string;
    externalId?: string;
    limit?: number;
    offset?: number;
}

export interface MobileDeviceOrderedPartialUpdateRequest {
    id: string;
    data: WriteOnlyMobileDeviceOrdered;
}

export interface MobileDeviceOrderedReadRequest {
    id: string;
}

export interface MobileDeviceOrderedUpdateRequest {
    id: string;
    data: WriteOnlyMobileDeviceOrdered;
}

/**
 * no description
 */
export class MobileDeviceOrderedApi extends runtime.BaseAPI {

    /**
     */
    async mobileDeviceOrderedCreateRaw(requestParameters: MobileDeviceOrderedCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceOrdered>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceOrderedCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceOrderedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceOrderedFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedCreate(requestParameters: MobileDeviceOrderedCreateRequest): Promise<ReadOnlyMobileDeviceOrdered> {
        const response = await this.mobileDeviceOrderedCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceOrderedDeleteRaw(requestParameters: MobileDeviceOrderedDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceOrdered>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceOrderedDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceOrderedFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedDelete(requestParameters: MobileDeviceOrderedDeleteRequest): Promise<ReadOnlyMobileDeviceOrdered> {
        const response = await this.mobileDeviceOrderedDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceOrderedListRaw(requestParameters: MobileDeviceOrderedListRequest): Promise<runtime.ApiResponse<InlineResponse20018>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.imei !== undefined) {
            queryParameters['imei'] = requestParameters.imei;
        }

        if (requestParameters.simIccid !== undefined) {
            queryParameters['simIccid'] = requestParameters.simIccid;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedList(requestParameters: MobileDeviceOrderedListRequest): Promise<InlineResponse20018> {
        const response = await this.mobileDeviceOrderedListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceOrderedPartialUpdateRaw(requestParameters: MobileDeviceOrderedPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceOrdered>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceOrderedPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceOrderedPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceOrderedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceOrderedFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedPartialUpdate(requestParameters: MobileDeviceOrderedPartialUpdateRequest): Promise<ReadOnlyMobileDeviceOrdered> {
        const response = await this.mobileDeviceOrderedPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceOrderedReadRaw(requestParameters: MobileDeviceOrderedReadRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceOrdered>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceOrderedRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceOrderedFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedRead(requestParameters: MobileDeviceOrderedReadRequest): Promise<ReadOnlyMobileDeviceOrdered> {
        const response = await this.mobileDeviceOrderedReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async mobileDeviceOrderedUpdateRaw(requestParameters: MobileDeviceOrderedUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMobileDeviceOrdered>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mobileDeviceOrderedUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mobileDeviceOrderedUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/mobile-device-ordered/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMobileDeviceOrderedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMobileDeviceOrderedFromJSON(jsonValue));
    }

    /**
     */
    async mobileDeviceOrderedUpdate(requestParameters: MobileDeviceOrderedUpdateRequest): Promise<ReadOnlyMobileDeviceOrdered> {
        const response = await this.mobileDeviceOrderedUpdateRaw(requestParameters);
        return await response.value();
    }

}
