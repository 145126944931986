import cn from 'classnames';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Radio } from 'common/components/Form';
import qa from 'qa-selectors';

export interface RadioGroupOpt {
    code: string;
    label: string;
    qa?: string;
}

export type RadioGroupOpts = RadioGroupOpt[];

interface Props {
    name: string;
    heading?: string;
    scrolled?: boolean;
    options?: RadioGroupOpts;
    type: 'radio' | 'button';
    checked: string;
    qa?: string;
    onChange?: (code: string) => void;
}

export function RadioGroup(props: Props) {
    function onChangeNew(e: RadioChangeEvent) {
        const code = e.target.value;
        props.onChange?.(code);
    }

    return (
        <>
            {props.heading && <label className="group-title">{props.heading}</label>}
            <div
                className={cn('checkbox-group', {
                    scroll: props.scrolled
                })}
                data-qa={props.qa}
            >
                <Radio.Group onChange={onChangeNew} value={props.checked}>
                    {props.options?.map(o =>
                        props.type === 'radio' ? (
                            <Radio
                                value={o.code}
                                key={o.code}
                                name={props.name}
                                checked={props.checked === o.code}
                                qa={o.qa ?? qa.common.radio}
                            >
                                {o.label}
                            </Radio>
                        ) : (
                            <Radio.Button
                                value={o.code}
                                key={o.code}
                                name={props.name}
                                checked={props.checked === o.code}
                                qa={o.qa ?? qa.common.radioButton}
                            >
                                {o.label}
                            </Radio.Button>
                        )
                    )}
                </Radio.Group>
            </div>
        </>
    );
}
