/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Aggregated,
    AggregatedFromJSON,
    AggregatedFromJSONTyped,
    AggregatedToJSON,
    LibJoinersDriverBehaviourLightVehiclesDriver,
    LibJoinersDriverBehaviourLightVehiclesDriverFromJSON,
    LibJoinersDriverBehaviourLightVehiclesDriverFromJSONTyped,
    LibJoinersDriverBehaviourLightVehiclesDriverToJSON,
    Ratings,
    RatingsFromJSON,
    RatingsFromJSONTyped,
    RatingsToJSON,
    Score,
    ScoreFromJSON,
    ScoreFromJSONTyped,
    ScoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverBehaviourLightVehiclesDriverInDb
 */
export interface DriverBehaviourLightVehiclesDriverInDb {
    /**
     * 
     * @type {string}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    id: string;
    /**
     * 
     * @type {LibJoinersDriverBehaviourLightVehiclesDriver}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    driver?: LibJoinersDriverBehaviourLightVehiclesDriver;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    idleTime: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    activityTime: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCityDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCountryDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumHighwayDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCityAggresiveTakeoffPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCityDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCityDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCountryDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCountryDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumCountryUnevenDrivePenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumHighwayDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumHighwayDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumHighwayUnevenDrivePenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    sumHighwayDangerousSpeedPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    count: number;
    /**
     * 
     * @type {Aggregated}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    aggregated: Aggregated;
    /**
     * 
     * @type {Ratings}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    ratings: Ratings;
    /**
     * 
     * @type {Score}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    score: Score;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourLightVehiclesDriverInDb
     */
    rank: number;
}

export function DriverBehaviourLightVehiclesDriverInDbFromJSON(json: any): DriverBehaviourLightVehiclesDriverInDb {
    return DriverBehaviourLightVehiclesDriverInDbFromJSONTyped(json, false);
}

export function DriverBehaviourLightVehiclesDriverInDbFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourLightVehiclesDriverInDb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'driver': !exists(json, 'driver') ? undefined : LibJoinersDriverBehaviourLightVehiclesDriverFromJSON(json['driver']),
        'idleTime': json['idle_time'],
        'activityTime': json['activity_time'],
        'sumCityDuration': json['sum_city_duration'],
        'sumCountryDuration': json['sum_country_duration'],
        'sumHighwayDuration': json['sum_highway_duration'],
        'sumCityAggresiveTakeoffPenalty': json['sum_city_aggresive_takeoff_penalty'],
        'sumCityDangerousBreakingPenalty': json['sum_city_dangerous_breaking_penalty'],
        'sumCityDangerousTurnPenalty': json['sum_city_dangerous_turn_penalty'],
        'sumCountryDangerousBreakingPenalty': json['sum_country_dangerous_breaking_penalty'],
        'sumCountryDangerousTurnPenalty': json['sum_country_dangerous_turn_penalty'],
        'sumCountryUnevenDrivePenalty': json['sum_country_uneven_drive_penalty'],
        'sumHighwayDangerousBreakingPenalty': json['sum_highway_dangerous_breaking_penalty'],
        'sumHighwayDangerousTurnPenalty': json['sum_highway_dangerous_turn_penalty'],
        'sumHighwayUnevenDrivePenalty': json['sum_highway_uneven_drive_penalty'],
        'sumHighwayDangerousSpeedPenalty': json['sum_highway_dangerous_speed_penalty'],
        'count': json['count'],
        'aggregated': AggregatedFromJSON(json['aggregated']),
        'ratings': RatingsFromJSON(json['ratings']),
        'score': ScoreFromJSON(json['score']),
        'rank': json['rank'],
    };
}

export function DriverBehaviourLightVehiclesDriverInDbToJSON(value?: DriverBehaviourLightVehiclesDriverInDb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'driver': LibJoinersDriverBehaviourLightVehiclesDriverToJSON(value.driver),
        'idle_time': value.idleTime,
        'activity_time': value.activityTime,
        'sum_city_duration': value.sumCityDuration,
        'sum_country_duration': value.sumCountryDuration,
        'sum_highway_duration': value.sumHighwayDuration,
        'sum_city_aggresive_takeoff_penalty': value.sumCityAggresiveTakeoffPenalty,
        'sum_city_dangerous_breaking_penalty': value.sumCityDangerousBreakingPenalty,
        'sum_city_dangerous_turn_penalty': value.sumCityDangerousTurnPenalty,
        'sum_country_dangerous_breaking_penalty': value.sumCountryDangerousBreakingPenalty,
        'sum_country_dangerous_turn_penalty': value.sumCountryDangerousTurnPenalty,
        'sum_country_uneven_drive_penalty': value.sumCountryUnevenDrivePenalty,
        'sum_highway_dangerous_breaking_penalty': value.sumHighwayDangerousBreakingPenalty,
        'sum_highway_dangerous_turn_penalty': value.sumHighwayDangerousTurnPenalty,
        'sum_highway_uneven_drive_penalty': value.sumHighwayUnevenDrivePenalty,
        'sum_highway_dangerous_speed_penalty': value.sumHighwayDangerousSpeedPenalty,
        'count': value.count,
        'aggregated': AggregatedToJSON(value.aggregated),
        'ratings': RatingsToJSON(value.ratings),
        'score': ScoreToJSON(value.score),
        'rank': value.rank,
    };
}


