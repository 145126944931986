import { ReadOnlyContactListFromJSON } from 'generated/new-main';

const country = {
    id: 1,
    name: 'Poland',
    iso2: 'PL',
    iso3: 'POL',
    time_zone: 'Europe/Warszawa',
    currency: 'PLZ',
    vat_rate: 0,
    tacho_alpha: 'PLA'
};

const contactListData = [
    {
        id: '1',
        country,
        name: 'Transport Szmyd',
        city: 'Warszawa',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['client']
    },
    {
        id: '2',
        country,
        name: 'Expo Poland',
        city: 'Warszawa',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['client']
    },
    {
        id: '3',
        country,
        name: 'Trucks Transport',
        city: 'Warszawa',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['supplier']
    },
    {
        id: '4',
        country,
        name: 'TransGroup',
        city: 'Warszawa',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['supplier']
    },
    {
        id: '5',
        country,
        name: 'Transport Krystof',
        city: 'Warszawa',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['supplier']
    },
    {
        id: '6',
        country,
        name: 'Trailers Transport',
        city: 'Gdansk',
        street: '1',
        house_number: '18',
        postal_code: '01025',
        premises_number: '010',
        created_at: '2022-03-10T13:02:30.592259Z',
        deleted_at: null,
        vat: '1',
        tax_id: '1',
        business_id: '1',
        other_identification_number: '1',
        is_natural_person: null,
        type: ['supplier', 'client']
    }
];

export const contactList = contactListData.map(e => ReadOnlyContactListFromJSON(e));
