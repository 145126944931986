import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import TableBar from 'common/components/TableBar';
import { WithLogic, withLogicContext } from 'App';
import { SearchData } from 'common/components/Search';
import qa from 'qa-selectors';
import { FleetActive, FleetType } from '../../FleetModule';
import { Button } from 'common/components';
import { Role } from 'logic/auth';
import FleetFilter from '../FleetFilter';
import { confDefault } from 'conf';

interface Props extends WithTranslation, WithLogic {
    demoMode?: boolean;
    search?: SearchData;
    heading?: string;
    filterOpen?: boolean;
    filter: {
        fleetActive?: FleetActive;
        fleetType?: FleetType;
    };
    backButton?: {
        onClick?: () => void;
        qa?: string;
        title?: string;
    };
    className?: string;
    roles: Role[];
    onBarSearchChange?: (text: string) => void;
    onBarFilterClick?: () => void;
    onBarResetClick?: () => void;
    onFilterConfirmClick?: (filter: { fleetActive: FleetActive | undefined; fleetType: FleetType | undefined }) => void;
    onFilterCancelClick?: () => void;
    onVehicleGroupOpen?: () => void;
    onCreateTrailer?: () => void;
    onHelperClick?: () => void;
}

function FleetBar(props: Props) {
    const defaults = confDefault.settings.management.fleet.filter;

    return (
        <>
            <TableBar
                searchInput={{ disabled: props.demoMode }}
                heading={props.heading ?? ''}
                search={props.search}
                backButton={props.backButton}
                className={props.className}
                filter={{
                    activeCount:
                        (props.filter.fleetType === defaults.fleetType ? 0 : 1) +
                        (props.filter.fleetActive === defaults.fleetActive ? 0 : 1),
                    qa: qa.fleet.bar.btnFilter,
                    onClick: props.onBarFilterClick,
                    resetButton: { onClick: props.onBarResetClick }
                }}
                actions={[
                    <Button
                        type="default"
                        size="large"
                        onClick={props.onVehicleGroupOpen}
                        disabled={props.demoMode}
                        qa={qa.fleet.bar.btnVehicleGroups}
                    >
                        {props.t('common.vehicleGroups')}
                    </Button>,
                    <Button
                        type="dashed"
                        size="large"
                        onClick={props.onCreateTrailer}
                        disabled={props.demoMode || !props.roles.includes(Role.V_W)}
                        qa={qa.fleet.detail.btnNewTrailer}
                    >
                        {props.t('ManagementFleet.newTrailer')}
                    </Button>
                ]}
                onSearchChange={props.onBarSearchChange}
                onHelperClick={props.onHelperClick}
            />
            {props.filterOpen && (
                <FleetFilter
                    fleetActive={props.filter.fleetActive}
                    fleetType={props.filter.fleetType}
                    demoMode={props.demoMode}
                    onConfirm={props.onFilterConfirmClick}
                    onCancel={props.onFilterCancelClick}
                />
            )}
        </>
    );
}

export default withTranslation()(withLogicContext(observer(FleetBar)));
