import moment from 'moment';
import { MemoryCard } from 'modules/management/modules/vehicles-remote-memory/VehiclesRemoteMemoryModule';
import { Logic } from '../logic';
import { searched } from '../../common/utils/search';
import { DTCODownloadedFileApiResult, GetDtcoDriverV1DtcoFilesDriverGetRequest } from 'generated/backend-api';

export interface VehiclesRemoteMemoryModel {
    id: string;
    licensePlate: string;
    cards: MemoryCard[];
    disabledAt?: Date;
    cardsDemanded: MemoryCard[];
    downloadAvailable: boolean;
}

export class DispatcherKitVehiclesRemoteMemoryLogic {
    private data: VehiclesRemoteMemoryModel[];

    constructor(private _logic: Logic) {
        this.data = [];
    }

    async getTableData(
        requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest,
        search: string
    ): Promise<VehiclesRemoteMemoryModel[]> {
        const vehicles = await this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, false, undefined, false, true, true);

        const dtco: DTCODownloadedFileApiResult[] = await this._getDTCOMonitoredObjectFiles(requestParameters);

        this.data = vehicles.map(vehicle => ({
            id: String(vehicle.id),
            licensePlate: vehicle.registrationNumber,
            cards: dtco.filter(d => Number(d.monitoredObject?.id) === Number(vehicle.id)).map(this._toCard),
            disabledAt: vehicle.disabledAt ?? undefined,
            cardsDemanded: [],
            downloadAvailable:
                dtco.filter(
                    d => Number(d.monitoredObject?.id) === Number(vehicle.id) && d.demand === false && d.downloadedFile
                ).length > 0 ?? false
        }));

        if (search.length !== 0) {
            return this.search(search);
        }

        return this.data;
    }

    async _getDTCOMonitoredObjectFiles(requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest) {
        try {
            if (this._logic.demo().isActive) {
                return this._logic.demo().data.dtcosVehicles;
            }

            const result: DTCODownloadedFileApiResult[] = await this._logic
                .api()
                .dtcoApi.getDtcoMonitoredObjectV1DtcoFilesMonitoredObjectGet(requestParameters);
            return result;
        } catch (err) {
            console.error('Get DTCO monitored object error');
            throw err;
        }
    }

    async createDownloadDemand(id: string): Promise<MemoryCard> {
        try {
            const dtco = await this._logic
                .api()
                .defaultApi.createDtcoDownloadDemandsV1DtcoDownloadDemandPost({ monitoredObjectId: Number(id) });
            return this._toCard(dtco);
        } catch (err) {
            console.error('Create download demand err', err);
            throw err;
        }
    }

    search(value: string): Promise<VehiclesRemoteMemoryModel[]> {
        return new Promise(resolve => {
            resolve(value ? this.data.filter(i => searched(value, i.licensePlate)) : this.data);
        });
    }

    destroy() {}

    private _toCard(data: DTCODownloadedFileApiResult): MemoryCard {
        return {
            id: data.id,
            monitoredObject: {
                id: String(data.monitoredObject?.id) ?? '',
                registrationNumber: String(data.monitoredObject?.registrationNumber) ?? ''
            },
            date: moment(data.date).toISOString() ?? '',
            demand: data.demand ?? false,
            downloadedFile: data.downloadedFile,
            downloadStarted: data.downloadStarted ? moment(data.downloadStarted).toISOString() : undefined,
            downloadRequested: data.downloadRequested ? moment(data.downloadRequested).toISOString() : undefined,
            dataActivityEnd: data.dataActivityEnd ?? '',
            dataActivityStart: data.dataActivityStart ?? ''
        };
    }
}
