import cn from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
    label: ReactNode;
    value: ReactNode;
    highlight?: boolean;
}

export default function DFFDataBlock({ label, value, highlight = false }: Props) {
    return (
        <div className={cn('dff-datablock', { 'dff-datablock-highlight': highlight })}>
            <div className="dff-datablock-value">{value}</div>
            <div className="dff-datablock-label">{label}</div>
        </div>
    );
}
