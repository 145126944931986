/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChannelInfo
 */
export interface ChannelInfo {
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelInfo
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    creator: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelInfo
     */
    users: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ChannelInfo
     */
    newMsgCount: number;
    /**
     * 
     * @type {Date}
     * @memberof ChannelInfo
     */
    newMsgFrom: Date;
}

export function ChannelInfoFromJSON(json: any): ChannelInfo {
    return ChannelInfoFromJSONTyped(json, false);
}

export function ChannelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'created': (new Date(json['created'])),
        'creator': json['creator'],
        'users': json['users'],
        'newMsgCount': json['newMsgCount'],
        'newMsgFrom': (new Date(json['newMsgFrom'])),
    };
}

export function ChannelInfoToJSON(value?: ChannelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'created': (value.created.toISOString()),
        'creator': value.creator,
        'users': value.users,
        'newMsgCount': value.newMsgCount,
        'newMsgFrom': (value.newMsgFrom.toISOString()),
    };
}


