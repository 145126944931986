import { InputNumberProps } from 'antd/lib/input-number';
import { InputProps } from 'antd/lib/input';
import { Field, FieldProps, getIn } from 'formik';
import { ReactNode } from 'react';

import { Input } from '../../components/Form';

interface Props extends InputProps {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    maxLength?: number;
    type?: 'password' | 'text';
    qa?: string;
    disabled?: boolean;
}

interface NumberProps extends InputNumberProps {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    error?: ReactNode;
    qa?: string;
    disabled?: boolean;
    postfix?: string;
}

function InputField({ name, type, qa, disabled, maxLength, ...props }: Props) {
    return (
        <Field name={name}>
            {({ field, form: { errors, touched, setFieldValue } }: FieldProps<any>) => (
                <Input
                    qa={qa}
                    type={type}
                    error={getIn(touched, name) && getIn(errors, name)}
                    disabled={disabled}
                    maxLength={maxLength}
                    autoComplete="off"
                    {...field}
                    onBlur={e => {
                        const val = (e.target.value || '').replace(/\s+/gi, ' ');
                        setFieldValue(field.name, val.trim());
                    }}
                    {...props}
                />
            )}
        </Field>
    );
}

InputField.Number = function ({ name, type = 'number', qa, disabled, onChange, ...props }: NumberProps) {
    const originalOnChange = onChange;
    return (
        <Field name={name} autocomplete="off">
            {({ field: { ...restField }, form: { errors, touched, setFieldValue } }: FieldProps<any>) => {
                const handleChange = (value: number | string) => {
                    setFieldValue(name, value);
                    originalOnChange?.(value);
                };
                return (
                    <Input.Number
                        qa={qa}
                        type={type}
                        error={getIn(touched, name) && getIn(errors, name)}
                        disabled={disabled}
                        autoComplete="off"
                        {...restField}
                        {...props}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
};

export default InputField;
