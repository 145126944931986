import { Logic } from 'logic/logic';
import { SuggestionLogic } from './logic/suggestion';

export class Routing {
    private _suggestion?: SuggestionLogic;
    constructor(private _logic: Logic) {}

    suggestion() {
        if (!this._suggestion) {
            this._suggestion = new SuggestionLogic(this._logic);
        }

        return this._suggestion;
    }
}
