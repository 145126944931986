import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useState, Fragment, MouseEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Col, Row } from 'antd';
import { WithLogic, withLogicContext } from 'App';

import { Button, Tag, Tooltip } from 'common/components';
import Collapse from 'common/components/Collapse';
import { Input } from 'common/components/Form';
import { SelectOption } from 'common/components/Form/Select';
import { DatePickerField, InputField, SelectField, CheckBoxField, TextareaField } from 'common/fields';
import { Select } from 'common/components/Form';
import * as CommonIcons from 'resources/images/common';
import MaintenanceExpenseForm from 'common/forms/MaintenanceExpenseForm';
import MaintenanceRecipientForm from '../MaintenanceRecipientForm';
import MultiSelectAutocompleteField from 'common/fields/AutoCompleteMultiSelect';
import {
    ExpensesItemsTypeEnum,
    MaintenanceTaskListRepetitionDueDateTypeEnum,
    MaintenanceTaskRepetitionDueDateTypeEnum,
    ReadOnlyContactList,
    ReadOnlyTaskType,
    TaskTypeCategoryEnum
} from 'generated/new-main';
import cn from 'classnames';
import { CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { MaintenanceRepetitionIntervalEnum } from 'modules/statistics/modules/maintenance/MaintenanceModule';
import { roundToDecimals } from 'common/utils/averages';
import { searched } from 'common/utils/search';
import qa from 'qa-selectors';
import { objectCompareSame } from 'common/utils/textUtils';

export interface RecipientModel {
    id?: number;
    userId?: number;
    userRoleId?: string;
    name?: string;
    email?: boolean;
    emailNotification?: boolean;
    systemNotification?: boolean;
}

export interface RecipientsSearchResult {
    name: string;
    userId?: number;
    userRoleId?: string;
    email?: boolean;
}

export interface TaskTypeSearchResult {
    id: string;
    name: string;
    custom: boolean;
}

export interface SearchResult {
    key: number;
    value: string;
}

export interface MaintenanceExpenseModel {
    name?: string;
    type?: ExpensesItemsTypeEnum;
    priceWoVat?: number;
}

export interface MaintenanceCompletitionModel {
    date?: string;
    odometer?: number;
}

export type MaintenanceFormModel = {
    category?: TaskTypeCategoryEnum;
    taskType?: string;
    taskTypeNameNew?: string;
    driverIds?: number[];
    vehicleIds?: number[];
    trailerIds?: number[];
    repetition: {
        date?: boolean;
        odometer?: boolean;
        dueDate?: string;
        interval?: MaintenanceRepetitionIntervalEnum;
        dueDateValue?: number;
        dueDateType?: MaintenanceTaskRepetitionDueDateTypeEnum;
        dueMileageKilometers?: number;
        dueMileageKilometersInterval?: number;
        bindToDueDate?: boolean;
    };
    notification: {
        dueDateDays?: number;
        dueMileageKilometers?: number;
        recipients?: RecipientModel[];
    };
    expenses: {
        priceTotal?: number;
        vat?: number;
        currency?: string;
        priceTotalWithVat?: number;
        supplier?: string;
        items?: MaintenanceExpenseModel[];
    };
    notes?: string;
};

interface Props extends WithTranslation, WithLogic {
    initialValues?: Partial<MaintenanceFormModel>;
    mode: 'EDIT' | 'CREATE';
    currencyOpts: SelectOption[];
    recipientsSearchResultsOpts?: RecipientsSearchResult[];
    driverIdsSearchResults?: SearchResult[];
    vehicleIdsSearchResults?: SearchResult[];
    trailerIdsSearchResults?: SearchResult[];
    taskTypeSearchResults?: TaskTypeSearchResult[];
    companyName?: string;
    lang: string;
    taskTypeOpts: ReadOnlyTaskType[];
    vehicleOpts: CheckboxGroupOpts;
    trailerOpts: CheckboxGroupOpts;
    driverOpts: CheckboxGroupOpts;
    clientOpts: ReadOnlyContactList[];
    clientOptsLoading?: boolean;
    supplierSelected?: string;
    demoMode?: boolean;
    onSubmit?: (values: MaintenanceFormModel) => void;
    onSearchTextChange?: (value: string, category: TaskTypeCategoryEnum) => void;
    onSearchTaskTypeTextChange?: (value: string, category?: TaskTypeCategoryEnum) => void;
    onSearchRecipientTextChange?: (value: string) => void;
    onCustomTypeDeleteClick?: (value: string) => void;
    onCustomTypeEditClick?: (value: string) => void;
    onCustomTypeEditCancelClick?: () => void;
    onCustomTypeEditSaveClick?: (id: string, name: string) => void;
    onCategoryChange?: (category: TaskTypeCategoryEnum) => void;
    onSupplierCreateClick?: () => void;
    onSupplierSelectClick?: (id: string) => void;
    onCancel?: () => void;
}

function MaintenanceForm({
    initialValues,
    mode,
    recipientsSearchResultsOpts,
    currencyOpts,
    driverIdsSearchResults,
    vehicleIdsSearchResults,
    trailerIdsSearchResults,
    taskTypeSearchResults,
    companyName,
    lang,
    t,
    vehicleOpts,
    trailerOpts,
    driverOpts,
    taskTypeOpts,
    clientOpts,
    clientOptsLoading,
    supplierSelected,
    demoMode,
    onSearchTextChange,
    onSearchRecipientTextChange,
    onSearchTaskTypeTextChange,
    onCustomTypeDeleteClick,
    onCustomTypeEditSaveClick,
    onCategoryChange,
    onSupplierCreateClick,
    onSupplierSelectClick,
    onSubmit,
    onCancel
}: Props) {
    let form: FormikProps<MaintenanceFormModel>;

    const [openExpenses, setOpenExpenses] = useState<boolean>(false);
    const [openNotes, setOpenNotes] = useState<boolean>(false);
    const [openDetailedPrice, setOpenDetailedPrice] = useState<boolean>(false);

    const [selectedVehicles, setSelectedVehicles] = useState<{ value: string; key?: number | string }[]>(
        vehicleOpts
            .filter(v => initialValues && v.code && initialValues.vehicleIds?.includes(Number(v.code)))
            .map(v => ({
                key: v.code,
                value: v.label
            }))
    );
    const [selectedTrailers, setSelectedTrailers] = useState<{ value: string; key?: number | string }[]>(
        trailerOpts
            .filter(v => initialValues && v.code && initialValues.trailerIds?.includes(Number(v.code)))
            .map(v => ({
                key: v.code,
                value: v.label
            }))
    );
    const [selectedDrivers, setSelectedDrivers] = useState<{ value: string; key?: number | string }[]>(
        driverOpts
            .filter(v => initialValues && v.code && initialValues.driverIds?.includes(Number(v.code)))
            .map(v => ({
                key: v.code,
                value: v.label
            }))
    );

    const [searchDriverValue, setSearchDriverValue] = useState('');
    const [searchVehicleValue, setSearchVehicleValue] = useState('');
    const [searchTrailerValue, setSearchTrailerValue] = useState('');
    const [searchTaskTypeValue, setSearchTaskTypeValue] = useState(
        initialValues?.taskType ? taskTypeOpts.find(tt => tt.id === initialValues?.taskType)?.name : ''
    );

    const [editId, setEditId] = useState('');
    const [editName, setEditName] = useState('');

    const [locked, setLocked] = useState(mode === 'EDIT' ? true : false);

    const schema = Yup.object().shape({
        category: Yup.mixed<TaskTypeCategoryEnum>()
            .oneOf(Object.values(TaskTypeCategoryEnum))
            .required(t('Maintenance.task.errorMsg.categoryTaskError')),
        driverIds: Yup.array().when([], {
            is: () => form.values.category === TaskTypeCategoryEnum.Driver,
            then: Yup.array().min(1, t('common.minOneItem')),
            otherwise: Yup.array().notRequired()
        }),
        vehicleIds: Yup.array().when([], {
            is: () => form.values.category === TaskTypeCategoryEnum.Vehicle,
            then: Yup.array().min(1, t('common.minOneItem')),
            otherwise: Yup.array().notRequired()
        }),
        trailerIds: Yup.array().when([], {
            is: () => form.values.category === TaskTypeCategoryEnum.Trailer,
            then: Yup.array().min(1, t('common.minOneItem')),
            otherwise: Yup.array().notRequired()
        }),
        taskType: Yup.string().when([], {
            is: () =>
                form.values.taskTypeNameNew === undefined ||
                (form.values.taskTypeNameNew && form.values.taskTypeNameNew.length < 3),
            then: Yup.string().required(t('common.required')),
            otherwise: Yup.string().notRequired()
        }),
        repetition: Yup.object().shape({
            date: Yup.boolean(),
            dueDate: Yup.string().when([], {
                is: () =>
                    (form.values.category &&
                        [
                            TaskTypeCategoryEnum.Driver,
                            TaskTypeCategoryEnum.Company,
                            TaskTypeCategoryEnum.Trailer
                        ].includes(form.values.category)) ||
                    (form.values.category === TaskTypeCategoryEnum.Vehicle && form.values.repetition.date),
                then: Yup.string().nullable().required(t('common.required')),
                otherwise: Yup.string().nullable().notRequired()
            }),
            dueMileageKilometers: Yup.number()
                .nullable()
                .when([], {
                    is: () => form.values.category === TaskTypeCategoryEnum.Vehicle && form.values.repetition.odometer,
                    then: Yup.number().nullable().min(1, t('validator.invalid_value')).required(t('common.required')),
                    otherwise: Yup.number().nullable().notRequired()
                }),
            interval: Yup.mixed().oneOf(Object.values(MaintenanceRepetitionIntervalEnum)),
            dueDateValue: Yup.number().when([], {
                is: () =>
                    form.values.repetition.interval !== undefined &&
                    form.values.repetition.interval !== MaintenanceRepetitionIntervalEnum.None,
                then: Yup.number().nullable().required(t('common.required')),
                otherwise: Yup.number().notRequired()
            }),
            dueDateType: Yup.mixed<MaintenanceTaskListRepetitionDueDateTypeEnum>().when([], {
                is: () =>
                    form.values.repetition.interval !== undefined &&
                    form.values.repetition.interval !== MaintenanceRepetitionIntervalEnum.None,
                then: Yup.mixed<MaintenanceTaskRepetitionDueDateTypeEnum>()
                    .oneOf(Object.values(MaintenanceTaskRepetitionDueDateTypeEnum))
                    .required(t('common.required')),
                otherwise: Yup.mixed<MaintenanceTaskRepetitionDueDateTypeEnum>()
                    .oneOf(Object.values(MaintenanceTaskRepetitionDueDateTypeEnum))
                    .notRequired()
            }),
            odometer: Yup.boolean(),
            dueMileageKilometersInterval: Yup.number().nullable().min(1, t('validator.invalid_value')).notRequired()
        }),
        notification: Yup.object().shape({
            dueDateDays: Yup.number().when([], {
                is: () => form.values.repetition.date,
                then: Yup.number().nullable().required(t('common.required')),
                otherwise: Yup.number().nullable().notRequired()
            }),
            dueMileageKilometers: Yup.number().when([], {
                is: () => form.values.repetition.odometer,
                then: Yup.number().nullable().required(t('common.required')),
                otherwise: Yup.number().nullable().notRequired()
            }),
            recipients: Yup.array(
                Yup.object().shape({
                    emailNotification: Yup.boolean(),
                    systemNotification: Yup.boolean()
                })
            )
        }),
        expenses: Yup.object().shape({
            priceTotal: Yup.number()
                .nullable()
                .min(0.01, t('validator.invalid_value'))
                .when([], {
                    is: () =>
                        (form.values.expenses?.vat && form.values.expenses?.vat > 0) ||
                        (form.values.expenses.currency && form.values.expenses.currency !== ''),
                    then: Yup.number().nullable().required(t('Maintenance.task.errorMsg.requiredWhenVatOrCurrency')),
                    otherwise: Yup.number().notRequired().nullable()
                }),
            currency: Yup.string().when([], {
                is: () =>
                    (form.values.expenses?.priceTotal && form.values.expenses?.priceTotal > 0) ||
                    (form.values.expenses?.vat && form.values.expenses?.vat > 0),
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            vat: Yup.number()
                .nullable()
                .min(0.01, t('validator.invalid_value'))
                .when([], {
                    is: () =>
                        (form.values.expenses?.priceTotal && form.values.expenses?.priceTotal > 0) ||
                        (form.values.expenses.currency && form.values.expenses.currency !== ''),
                    then: Yup.number()
                        .nullable()
                        .required(t('Maintenance.task.errorMsg.requiredWhenPriceTotalOrCurrency')),
                    otherwise: Yup.number().notRequired().nullable()
                }),
            priceTotalWithVat: Yup.number().nullable().min(0.01, t('validator.invalid_value')).notRequired(),
            items: Yup.array(
                Yup.object().shape({
                    name: Yup.string().required(t('common.required')),
                    type: Yup.mixed<ExpensesItemsTypeEnum>().oneOf(Object.values(ExpensesItemsTypeEnum)),
                    priceWoVat: Yup.number().min(0.01, t('validator.invalid_value')).required(t('common.required'))
                })
            )
        }),
        notes: Yup.string()
    });

    const clientOptions: SelectOption[] = clientOpts.map(client => ({
        label: client.name ?? '',
        value: client.id ?? ''
    }));

    const categoryOptions: SelectOption[] = Object.values(TaskTypeCategoryEnum)
        .sort()
        .reverse()
        .map(val => ({
            label: (
                <>
                    <img src={getCategoryIcon(val)} alt={val} />
                    {t(`common.${val}`)}
                </>
            ),
            value: val
        }));

    const expensesTypeOptions: SelectOption[] = Object.values(ExpensesItemsTypeEnum).map(value => ({
        label: t(`common.${value}`),
        value: value
    }));

    const repeatOptions: SelectOption[] = Object.values(MaintenanceRepetitionIntervalEnum).map(value => ({
        label: t(`Maintenance.task.repeatTypes.${value}`),
        value: value
    }));

    const customRepeatIntervalOptions: SelectOption[] = Object.values(MaintenanceTaskRepetitionDueDateTypeEnum).map(
        value => ({
            label: t(`common.${value}`),
            value: value
        })
    );

    const toIntervalEnum = (value: number, type: string): MaintenanceRepetitionIntervalEnum => {
        const interval = `${type}_${value}`;
        switch (interval) {
            case 'day_1':
                return MaintenanceRepetitionIntervalEnum.Day1;
            case 'week_1':
                return MaintenanceRepetitionIntervalEnum.Week1;
            case 'week_2':
                return MaintenanceRepetitionIntervalEnum.Week2;
            case 'month_1':
                return MaintenanceRepetitionIntervalEnum.Month1;
            case 'month_2':
                return MaintenanceRepetitionIntervalEnum.Month2;
            case 'month_3':
                return MaintenanceRepetitionIntervalEnum.Month3;
            case 'month_6':
                return MaintenanceRepetitionIntervalEnum.Month6;
            case 'year_1':
                return MaintenanceRepetitionIntervalEnum.Year1;
            case 'year_2':
                return MaintenanceRepetitionIntervalEnum.Year2;
            default:
                return MaintenanceRepetitionIntervalEnum.Custom;
        }
    };

    function collapseChange(key: string | string[]) {
        if (key instanceof Array) {
            setOpenExpenses(key.includes('4'));
            setOpenNotes(key.includes('5'));
        } else {
            setOpenExpenses(key === '4');
            setOpenNotes(key === '5');
        }
    }

    function collapsePriceChange(key: string | string[]) {
        setOpenDetailedPrice(key.includes('price'));
    }

    function getCategoryIcon(value: string) {
        switch (value) {
            case 'driver':
                return CommonIcons.person;
            case 'trailer':
                return CommonIcons.trailerTwoAxles;
            case 'vehicle':
                return CommonIcons.truckCabin;
            case 'company':
            default:
                return CommonIcons.homeWork;
        }
    }

    function handleSubmit(values: MaintenanceFormModel) {
        values.expenses.supplier = supplierSelected;
        onSubmit?.(values);
    }

    function evaluateSubmitButton() {
        setTimeout(() => setLocked(objectCompareSame(initialValues, form.values)), 1);
    }

    function handleRemoveRecipient(index: number) {
        const recipientsNew = form.values.notification.recipients?.sort().filter((f, i) => i !== index);
        form.setFieldValue('notification.recipients', recipientsNew);
        evaluateSubmitButton();
    }

    function handleRemoveExpensesItem(index: number) {
        const itemsNew = form.values.expenses.items?.sort().filter((f, i) => i !== index);
        form.setFieldValue('expenses.items', itemsNew);

        const priceTotal = itemsNew?.length
            ? itemsNew?.map(data => Number(data.priceWoVat) ?? 0).reduce((sum, value) => sum + value)
            : 0;
        form.setFieldValue('expenses.priceTotal', priceTotal);

        if (priceTotal && form.values.expenses.vat) {
            const newPriceTotalWithVat = Number(priceTotal) * (1 + form.values.expenses.vat / 100);
            form.setFieldValue(
                'expenses.priceTotalWithVat',
                roundToDecimals(numeral(newPriceTotalWithVat).value() ?? 0, 2)
            );
        }
        evaluateSubmitButton();
    }

    function onSupplierSelect(value: 'noCriteria' | 'newSupplier' | string) {
        if (value === 'newSupplier') {
            onSupplierCreateClick?.();
            setLocked(false);
        } else {
            if (value !== 'noCriteria') {
                if (value !== supplierSelected) {
                    onSupplierSelectClick?.(value);
                    form.setFieldValue('expenses.supplier', value !== 'noCriteria' ? value : undefined);
                    setLocked(value === initialValues?.expenses?.supplier);
                }
            }
        }
    }

    function onExpensesPriceTotalChange(valueType: string | number) {
        const value = Number(valueType);
        if (form.values.expenses.vat && value) {
            const newPriceTotalWithVat = Number(value) * (1 + form.values.expenses.vat / 100);
            form.setFieldValue(
                'expenses.priceTotalWithVat',
                roundToDecimals(numeral(newPriceTotalWithVat).value() ?? 0, 2)
            );
        }
        evaluateSubmitButton();
    }

    function onExpensesVatChange(value: string | number) {
        const vat = Number(value);
        if (form.values.expenses.priceTotal && vat) {
            const newPriceTotalWithVat = form.values.expenses.priceTotal * (1 + vat / 100);
            form.setFieldValue(
                'expenses.priceTotalWithVat',
                roundToDecimals(numeral(newPriceTotalWithVat).value() ?? 0, 2)
            );
        }
        evaluateSubmitButton();
    }

    function handleInterval(interval: string) {
        if (interval && !['custom', 'none'].includes(interval)) {
            const [type, value] = interval.toString().split('_');
            form.setFieldValue('repetition.dueDateValue', value, true);
            form.setFieldValue('repetition.dueDateType', type, true);
        } else {
            form.setFieldValue('repetition.dueDateValue', undefined, true);
            form.setFieldValue('repetition.dueDateType', undefined, true);
        }
    }

    function onExpensesItemChange(value: string | number, index: number) {
        const priceTotal = form.values.expenses.items
            ?.map((data, i) => (i === index ? Number(value) : data.priceWoVat ?? 0))
            .reduce((a, b) => a + b);
        form.setFieldValue('expenses.priceTotal', priceTotal);
        if (priceTotal && form.values.expenses.vat) {
            const newPriceTotalWithVat = Number(priceTotal) * (1 + form.values.expenses.vat / 100);
            form.setFieldValue(
                'expenses.priceTotalWithVat',
                roundToDecimals(numeral(newPriceTotalWithVat).value() ?? 0, 2)
            );
        }
    }

    async function onMaintenanceRecipientFormSubmit(model: RecipientModel): Promise<boolean> {
        let recipientsNew = form.values.notification.recipients;
        if (recipientsNew) {
            recipientsNew.push({
                ...model,
                emailNotification: true,
                systemNotification: model.email ? false : true
            });
        }
        form.setFieldValue('notification.recipients', recipientsNew);
        evaluateSubmitButton();
        return true;
    }

    async function onMaintenanceExpenseFormSubmit(model: MaintenanceExpenseModel): Promise<boolean> {
        let expensesNew = form.values.expenses.items;
        if (expensesNew) {
            expensesNew.push({
                name: model.name,
                type: model.type,
                priceWoVat: model.priceWoVat
            });
        }
        form.setFieldValue('expenses.items', expensesNew);
        const priceTotal = form.values.expenses.items
            ?.map(data => Number(data.priceWoVat) ?? 0)
            .reduce((a, b) => a + b);
        form.setFieldValue('expenses.priceTotal', priceTotal);
        if (priceTotal && form.values.expenses.vat) {
            const newPriceTotalWithVat = Number(priceTotal) * (1 + form.values.expenses.vat / 100);
            form.setFieldValue(
                'expenses.priceTotalWithVat',
                roundToDecimals(numeral(newPriceTotalWithVat).value() ?? 0, 2)
            );
        }
        evaluateSubmitButton();

        return true;
    }

    const renderCategoryButton = (categoryVal: TaskTypeCategoryEnum, formik: FormikProps<MaintenanceFormModel>) => (
        <Button
            type="default"
            block
            className={cn('button-task-for', {
                'ant-btn-dangerous': form.errors.category
            })}
            onClick={() => {
                formik.setFieldValue('repetition.date', true, true);
                formik.setFieldValue('repetition.odometer', false, true);
                formik.setFieldValue('category', categoryVal, true);
                onCategoryChange?.(categoryVal);
            }}
            icon={<img src={getCategoryIcon(categoryVal)} alt={categoryVal} />}
            qa={qa.maintenance.form.categoryBtns?.[categoryVal]}
        >
            {t(`common.${categoryVal}`)}
        </Button>
    );

    const renderTaskTypeOption = (item: TaskTypeSearchResult, index: number) => ({
        key: index,
        value: item.name,
        label: (
            <>
                {item.id && editId.includes(item.id) ? (
                    <Row key={index} align="middle">
                        <Col span={16}>
                            <Input
                                autoFocus
                                defaultValue={item.name}
                                onClick={e => e.stopPropagation()}
                                onChange={val => setEditName(val.target.value)}
                            />
                        </Col>
                        <Col span={8}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        type="text"
                                        size="middle"
                                        className="action-image"
                                        onClick={handleCustomTypeEditCancel.bind(undefined, item.id)}
                                        icon={<img src={CommonIcons.closeCircle} alt="cancel" />}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        type="text"
                                        size="middle"
                                        className="action-image"
                                        onClick={handleCustomTypeEditSave.bind(undefined, item.id, editName)}
                                        icon={<img src={CommonIcons.checkCircle} alt="submit" />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Row key={index} align="middle">
                        <Col span={16}>
                            <span className="text">{item.name}</span>
                        </Col>
                        <Col span={8}>
                            {item.custom && (
                                <Row justify="end">
                                    <Col>
                                        <Button
                                            type="text"
                                            size="middle"
                                            className="action-image"
                                            onClick={handleCustomTypeEditClick.bind(undefined, item.id)}
                                            icon={<img src={CommonIcons.edit} alt="edit" />}
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            type="text"
                                            size="middle"
                                            className="action-image"
                                            onClick={handleCustomTypeDeleteClick.bind(undefined, item.id)}
                                            icon={<img src={CommonIcons.closeCircle} alt="delete" />}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                )}
            </>
        )
    });

    function handleCustomTypeDeleteClick(id: string, e: MouseEvent<HTMLElement>) {
        e.stopPropagation();
        onCustomTypeDeleteClick?.(id);
    }

    function handleCustomTypeEditClick(id: string, e: MouseEvent<HTMLElement>) {
        e.stopPropagation();
        setEditId(id);
    }

    function handleCustomTypeEditCancel(id: string, e: MouseEvent<HTMLElement>) {
        e.stopPropagation();
        setEditId('');
    }

    function handleCustomTypeEditSave(id: string, name: string, e: MouseEvent<HTMLElement>) {
        e.stopPropagation();
        if (name.length > 0) {
            onCustomTypeEditSaveClick?.(id, name);
        }
    }

    return (
        <Formik<MaintenanceFormModel>
            initialValues={{
                category: initialValues?.category,
                taskType: initialValues?.taskType,
                driverIds: initialValues?.driverIds ?? [],
                trailerIds: initialValues?.trailerIds ?? [],
                vehicleIds: initialValues?.vehicleIds ?? [],
                repetition: {
                    date: initialValues?.repetition?.date,
                    odometer: initialValues?.repetition?.odometer,
                    dueDate: initialValues?.repetition?.dueDate,
                    interval: initialValues?.repetition?.interval,
                    bindToDueDate: initialValues?.repetition?.bindToDueDate,
                    dueDateValue: initialValues?.repetition?.dueDateValue,
                    dueDateType: initialValues?.repetition?.dueDateType,
                    dueMileageKilometers: initialValues?.repetition?.dueMileageKilometers,
                    dueMileageKilometersInterval: initialValues?.repetition?.dueMileageKilometersInterval
                },
                notification: {
                    dueDateDays: initialValues?.notification?.dueDateDays,
                    dueMileageKilometers: initialValues?.notification?.dueMileageKilometers,
                    recipients: initialValues?.notification?.recipients
                        ? JSON.parse(JSON.stringify(initialValues?.notification?.recipients))
                        : []
                },
                expenses: {
                    currency: initialValues?.expenses?.currency,
                    priceTotal: initialValues?.expenses?.priceTotal,
                    priceTotalWithVat: initialValues?.expenses?.priceTotalWithVat,
                    supplier: initialValues?.expenses?.supplier,
                    vat: initialValues?.expenses?.vat,
                    items: initialValues?.expenses?.items
                        ? JSON.parse(JSON.stringify(initialValues?.expenses?.items))
                        : []
                },
                notes: initialValues?.notes
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<MaintenanceFormModel>) => {
                form = formik;
                return (
                    <div className="maintenance-task-form">
                        <Form className="maintenance-task-form-inputs">
                            <Collapse
                                expandIconPosition="right"
                                bordered={true}
                                activeKey={[1, 2, 3]}
                                className="maintenance-task-form-inputs-core"
                            >
                                <Collapse.Panel
                                    showArrow={false}
                                    header={
                                        mode === 'CREATE'
                                            ? t('Maintenance.task.newTaskFor')
                                            : t('Maintenance.task.taskFor')
                                    }
                                    key={1}
                                >
                                    {formik.values.category ? (
                                        <Row gutter={[24, 0]}>
                                            <Col span={5}>
                                                <SelectField
                                                    name="category"
                                                    options={categoryOptions}
                                                    dropdownClassName="options-task-for"
                                                    className="empty-label select-task-for"
                                                    size="large"
                                                    disabled={mode === 'EDIT'}
                                                    onChange={() => {
                                                        form.setFieldValue('taskType', undefined, true);
                                                        setSelectedDrivers([]);
                                                        setSelectedVehicles([]);
                                                        setSelectedTrailers([]);
                                                        form.setFieldValue('driverIds', []);
                                                        form.setFieldValue('vehicleIds', []);
                                                        form.setFieldValue('trailerIds', []);
                                                        setSearchTaskTypeValue('');
                                                    }}
                                                    onSelect={(_val, option) => {
                                                        onCategoryChange?.(option.value);
                                                    }}
                                                    showArrow={false}
                                                />
                                            </Col>
                                            <Col span={19}>
                                                <Row gutter={[12, 0]}>
                                                    <Col span={12}>
                                                        <div
                                                            style={{
                                                                display:
                                                                    formik.values.category ===
                                                                    TaskTypeCategoryEnum.Driver
                                                                        ? 'block'
                                                                        : 'none'
                                                            }}
                                                        >
                                                            <MultiSelectAutocompleteField
                                                                label={t('common.driver')}
                                                                onSelect={(_val, option) => {
                                                                    setSelectedDrivers(
                                                                        selectedDrivers.concat([
                                                                            {
                                                                                key: option.key,
                                                                                value: option.value
                                                                            }
                                                                        ])
                                                                    );
                                                                    setSearchDriverValue('');
                                                                }}
                                                                name="driverIds"
                                                                search={false}
                                                                placeholderText={t('Maintenance.task.chooseDriver')}
                                                                autoFocus
                                                                disabled={mode === 'EDIT'}
                                                                options={driverIdsSearchResults?.filter(
                                                                    option =>
                                                                        !formik.values.driverIds?.includes(option.key)
                                                                )}
                                                                value={searchDriverValue}
                                                                onChange={val => {
                                                                    setSearchDriverValue(val);
                                                                }}
                                                                onSearch={val =>
                                                                    onSearchTextChange?.(
                                                                        val,
                                                                        TaskTypeCategoryEnum.Driver
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display:
                                                                    formik.values.category ===
                                                                    TaskTypeCategoryEnum.Vehicle
                                                                        ? 'block'
                                                                        : 'none'
                                                            }}
                                                        >
                                                            <MultiSelectAutocompleteField
                                                                label={t('common.vehicle')}
                                                                onSelect={(_val, option) => {
                                                                    setSelectedVehicles(
                                                                        selectedVehicles.concat([
                                                                            {
                                                                                key: option.key,
                                                                                value: option.value
                                                                            }
                                                                        ])
                                                                    );
                                                                    setSearchVehicleValue('');
                                                                }}
                                                                name="vehicleIds"
                                                                placeholderText={t('Maintenance.task.chooseVehicle')}
                                                                autoFocus
                                                                disabled={mode === 'EDIT'}
                                                                search={false}
                                                                options={vehicleIdsSearchResults?.filter(
                                                                    option =>
                                                                        !formik.values.vehicleIds?.includes(option.key)
                                                                )}
                                                                value={searchVehicleValue}
                                                                onChange={setSearchVehicleValue}
                                                                onSearch={val =>
                                                                    onSearchTextChange?.(
                                                                        val,
                                                                        TaskTypeCategoryEnum.Vehicle
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            style={{
                                                                display:
                                                                    formik.values.category ===
                                                                    TaskTypeCategoryEnum.Trailer
                                                                        ? 'block'
                                                                        : 'none'
                                                            }}
                                                        >
                                                            <MultiSelectAutocompleteField
                                                                label={t('common.trailer')}
                                                                onSelect={(_val, option) => {
                                                                    setSelectedTrailers(
                                                                        selectedTrailers.concat([
                                                                            {
                                                                                key: option.key,
                                                                                value: option.value
                                                                            }
                                                                        ])
                                                                    );
                                                                    setSearchTrailerValue('');
                                                                }}
                                                                name="trailerIds"
                                                                placeholderText={t('Maintenance.task.chooseTrailer')}
                                                                autoFocus
                                                                disabled={mode === 'EDIT'}
                                                                search={false}
                                                                options={trailerIdsSearchResults?.filter(
                                                                    option =>
                                                                        !formik.values.trailerIds?.includes(option.key)
                                                                )}
                                                                value={searchTrailerValue}
                                                                onChange={setSearchTrailerValue}
                                                                onSearch={val =>
                                                                    onSearchTextChange?.(
                                                                        val,
                                                                        TaskTypeCategoryEnum.Trailer
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            style={{
                                                                display:
                                                                    formik.values.category ===
                                                                    TaskTypeCategoryEnum.Company
                                                                        ? 'block'
                                                                        : 'none'
                                                            }}
                                                        >
                                                            <InputField
                                                                name="company"
                                                                size="large"
                                                                value={companyName}
                                                                readOnly
                                                                label={t('Maintenance.task.companyName')}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <MultiSelectAutocompleteField
                                                            search={false}
                                                            autoFocus
                                                            allowClear={formik.values.taskType ? true : false}
                                                            name="taskType"
                                                            disabled={mode === 'EDIT'}
                                                            onSelect={(_val, option) => {
                                                                const taskTypeSelected = taskTypeSearchResults?.find(
                                                                    (r, i) => i === option.key
                                                                );
                                                                const taskTypeOpt = taskTypeOpts?.find(
                                                                    tt => tt.id === taskTypeSelected?.id
                                                                );

                                                                if (taskTypeSelected) {
                                                                    formik.setFieldValue(
                                                                        'taskType',
                                                                        taskTypeSelected?.id,
                                                                        true
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'repetition.interval',
                                                                        taskTypeOpt?.repetitionDueDateValue &&
                                                                            taskTypeOpt?.repetitionDueDateType
                                                                            ? toIntervalEnum(
                                                                                  taskTypeOpt?.repetitionDueDateValue,
                                                                                  taskTypeOpt?.repetitionDueDateType
                                                                              )
                                                                            : undefined,
                                                                        true
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'repetition.dueDateValue',
                                                                        taskTypeOpt?.repetitionDueDateValue,
                                                                        true
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'repetition.dueDateType',
                                                                        taskTypeOpt?.repetitionDueDateType,
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                            label={t('Maintenance.task.taskType')}
                                                            placeholderText={t('Maintenance.task.chooseTaskType')}
                                                            options={taskTypeSearchResults?.map((r, index) =>
                                                                renderTaskTypeOption(r, index)
                                                            )}
                                                            value={searchTaskTypeValue}
                                                            onChange={val => {
                                                                setSearchTaskTypeValue(val);
                                                                formik.setFieldValue('taskType', undefined, true);
                                                                formik.setFieldValue('taskTypeNameNew', val, true);
                                                                setTimeout(() => {
                                                                    formik.validateForm();
                                                                }, 1);
                                                            }}
                                                            onClear={() => {
                                                                setSearchTaskTypeValue('');
                                                                formik.setFieldValue(
                                                                    'taskTypeNameNew',
                                                                    undefined,
                                                                    true
                                                                );
                                                                formik.setFieldValue('taskType', undefined, true);
                                                            }}
                                                            onSearch={val =>
                                                                onSearchTaskTypeTextChange?.(
                                                                    val,
                                                                    formik.values.category
                                                                )
                                                            }
                                                            onDropdownVisibleChange={() => setEditId('')}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={[24, 0]}>
                                                    <Col>
                                                        {selectedDrivers &&
                                                            formik.values.category === TaskTypeCategoryEnum.Driver && (
                                                                <div className="selected">
                                                                    {selectedDrivers
                                                                        .filter(option => option.value)
                                                                        .map(option => (
                                                                            <Tag
                                                                                key={`selected-item-${option.key}`}
                                                                                className="selected-item"
                                                                            >
                                                                                {option.value}
                                                                                {mode === 'CREATE' && (
                                                                                    <img
                                                                                        alt="remove"
                                                                                        src={CommonIcons.iconClose}
                                                                                        onClick={() => {
                                                                                            formik.setFieldValue(
                                                                                                'driverIds',
                                                                                                formik.values.driverIds?.filter(
                                                                                                    id =>
                                                                                                        id !==
                                                                                                        Number(
                                                                                                            option.key
                                                                                                        )
                                                                                                )
                                                                                            );
                                                                                            setSelectedDrivers(
                                                                                                selectedDrivers.filter(
                                                                                                    u =>
                                                                                                        Number(
                                                                                                            u.key
                                                                                                        ) !==
                                                                                                        Number(
                                                                                                            option.key
                                                                                                        )
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Tag>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        {selectedTrailers &&
                                                            formik.values.category === TaskTypeCategoryEnum.Trailer && (
                                                                <div className="selected">
                                                                    {selectedTrailers
                                                                        .filter(option => option.value)
                                                                        .map(option => (
                                                                            <Tag
                                                                                key={`selected-item-${option.key}`}
                                                                                className="selected-item"
                                                                            >
                                                                                {option.value}
                                                                                {mode === 'CREATE' && (
                                                                                    <img
                                                                                        alt="remove"
                                                                                        src={CommonIcons.iconClose}
                                                                                        onClick={() => {
                                                                                            formik.setFieldValue(
                                                                                                'trailerIds',
                                                                                                formik.values.trailerIds?.filter(
                                                                                                    id =>
                                                                                                        id !==
                                                                                                        option.key
                                                                                                )
                                                                                            );
                                                                                            setSelectedTrailers(
                                                                                                selectedTrailers.filter(
                                                                                                    u =>
                                                                                                        u.key !==
                                                                                                        option.key
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Tag>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        {selectedVehicles &&
                                                            formik.values.category === TaskTypeCategoryEnum.Vehicle && (
                                                                <div className="selected">
                                                                    {selectedVehicles
                                                                        .filter(option => option.value)
                                                                        .map(option => (
                                                                            <Tag
                                                                                key={`selected-item-${option.key}`}
                                                                                className="selected-item"
                                                                            >
                                                                                {option.value}
                                                                                {mode === 'CREATE' && (
                                                                                    <img
                                                                                        alt="remove"
                                                                                        src={CommonIcons.iconClose}
                                                                                        onClick={() => {
                                                                                            formik.setFieldValue(
                                                                                                'vehicleIds',
                                                                                                formik.values.vehicleIds?.filter(
                                                                                                    id =>
                                                                                                        id !==
                                                                                                        option.key
                                                                                                )
                                                                                            );
                                                                                            setSelectedVehicles(
                                                                                                selectedVehicles.filter(
                                                                                                    u =>
                                                                                                        u.key !==
                                                                                                        option.key
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Tag>
                                                                        ))}
                                                                </div>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>
                                            <Row gutter={[8, 0]}>
                                                <Col span={5}>
                                                    {renderCategoryButton(TaskTypeCategoryEnum.Vehicle, formik)}
                                                </Col>
                                                <Col span={5}>
                                                    {renderCategoryButton(TaskTypeCategoryEnum.Trailer, formik)}
                                                </Col>
                                                <Col span={5}>
                                                    {renderCategoryButton(TaskTypeCategoryEnum.Driver, formik)}
                                                </Col>
                                                <Col span={5}>
                                                    {renderCategoryButton(TaskTypeCategoryEnum.Company, formik)}
                                                </Col>
                                            </Row>
                                            <div className="control-error">{formik.errors.category?.valueOf()}</div>
                                        </>
                                    )}
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} header={t('Maintenance.task.dueInterval')} key={2}>
                                    {formik.values.category === 'vehicle' && (
                                        <Row gutter={[24, 12]} className="due-interval-checkboxes">
                                            <Col span={12}>
                                                <CheckBoxField
                                                    name="repetition.date"
                                                    qa={qa.maintenance.form.inputDate}
                                                    onChange={() => evaluateSubmitButton()}
                                                >
                                                    {t('Maintenance.form.date')}
                                                </CheckBoxField>
                                            </Col>
                                            <Col span={12}>
                                                <CheckBoxField
                                                    name="repetition.odometer"
                                                    qa={qa.maintenance.form.inputOdometer}
                                                    onChange={() => evaluateSubmitButton()}
                                                >
                                                    {t('Maintenance.form.odometer')}
                                                </CheckBoxField>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={[24, 12]}>
                                        <Col span={12}>
                                            <Row gutter={[12, 0]}>
                                                <Col span={12}>
                                                    <DatePickerField
                                                        size="large"
                                                        lang={lang}
                                                        format="L"
                                                        label={t('Maintenance.task.dueOn')}
                                                        name="repetition.dueDate"
                                                        showTime={false}
                                                        showNow={false}
                                                        disabled={formik.values.repetition.date === false}
                                                        qa={qa.maintenance.form.inputDueDate}
                                                        onChange={() => evaluateSubmitButton()}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <SelectField
                                                        name="repetition.interval"
                                                        options={repeatOptions}
                                                        label={`${t('Maintenance.task.repeat')}`}
                                                        size="large"
                                                        onSelect={(_val, option) => handleInterval(option.value)}
                                                        disabled={
                                                            formik.values.taskType !== undefined ||
                                                            formik.values.repetition.date === false
                                                        }
                                                        qa={qa.maintenance.form.inputDateInterval}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {formik.values.category === TaskTypeCategoryEnum.Vehicle && (
                                            <Col span={12}>
                                                <Row gutter={[12, 0]}>
                                                    <Col span={12}>
                                                        <InputField.Number
                                                            size="large"
                                                            min={1}
                                                            label={`${t('Maintenance.task.startMileage')} (${t(
                                                                'common.km'
                                                            )})`}
                                                            name="repetition.dueMileageKilometers"
                                                            disabled={formik.values.repetition.odometer === false}
                                                            qa={qa.maintenance.form.inputDueMileage}
                                                            onChange={() => evaluateSubmitButton()}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <InputField.Number
                                                            min={1}
                                                            name="repetition.dueMileageKilometersInterval"
                                                            label={`${t('Maintenance.task.repeatAfter')} (${t(
                                                                'common.km'
                                                            )}) (${t('common.optional')})`}
                                                            size="large"
                                                            disabled={formik.values.repetition.odometer === false}
                                                            qa={qa.maintenance.form.inputDueMileageInterval}
                                                            onChange={() => evaluateSubmitButton()}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                        {formik.values.repetition.interval ===
                                            MaintenanceRepetitionIntervalEnum.Custom && (
                                            <Col span={12}>
                                                <Row gutter={[12, 0]}>
                                                    <Col span={12}>
                                                        <InputField.Number
                                                            name="repetition.dueDateValue"
                                                            min={1}
                                                            label={t('Maintenance.task.repeatEvery')}
                                                            size="large"
                                                            disabled={
                                                                formik.values.taskType !== undefined ||
                                                                formik.values.repetition.date === false
                                                            }
                                                            qa={qa.maintenance.form.inputDateValue}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <SelectField
                                                            name="repetition.dueDateType"
                                                            options={customRepeatIntervalOptions}
                                                            label={t('Maintenance.task.repeatInterval')}
                                                            size="large"
                                                            disabled={
                                                                formik.values.taskType !== undefined ||
                                                                formik.values.repetition.date === false
                                                            }
                                                            qa={qa.maintenance.form.inputDateType}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}

                                        <Col span={24}>
                                            <Row gutter={[12, 0]} align="middle">
                                                <Col>
                                                    <CheckBoxField
                                                        name="repetition.bindToDueDate"
                                                        disabled={formik.values.repetition.date === false}
                                                        qa={qa.maintenance.form.inputDateBind}
                                                        onChange={() => evaluateSubmitButton()}
                                                    >
                                                        {t('Maintenance.task.linkRepetition')}
                                                    </CheckBoxField>
                                                </Col>
                                                <Col>
                                                    <Tooltip
                                                        title={t('Maintenance.task.linkRepetitionTooltip')}
                                                        placement="rightTop"
                                                    >
                                                        <img src={CommonIcons.tooltipInfo} alt="info" />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} header={t('Maintenance.task.notification')} key={3}>
                                    <Row gutter={[24, 12]}>
                                        <Col span={6}>
                                            <Row gutter={[24, 12]}>
                                                <Col>
                                                    <InputField.Number
                                                        name="notification.dueDateDays"
                                                        min={1}
                                                        label={t('Maintenance.task.daysBefore')}
                                                        size="large"
                                                        disabled={formik.values.repetition.date === false}
                                                        onChange={() => evaluateSubmitButton()}
                                                    />
                                                </Col>
                                                {formik.values.category === TaskTypeCategoryEnum.Vehicle && (
                                                    <Col>
                                                        <InputField.Number
                                                            name="notification.dueMileageKilometers"
                                                            min={1}
                                                            label={t('Maintenance.task.kmBefore')}
                                                            size="large"
                                                            disabled={formik.values.repetition.odometer === false}
                                                            onChange={() => evaluateSubmitButton()}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={18}>
                                            <MaintenanceRecipientForm
                                                initialValues={{
                                                    name: '',
                                                    email: true,
                                                    userId: undefined,
                                                    userRoleId: undefined
                                                }}
                                                recipientsSearchResultsOpts={recipientsSearchResultsOpts?.filter(
                                                    r =>
                                                        !formik.values.notification.recipients
                                                            ?.map(rec => rec.userId)
                                                            .includes(r.userId) ||
                                                        !formik.values.notification.recipients
                                                            ?.map(rec => rec.userRoleId)
                                                            .includes(r.userRoleId)
                                                )}
                                                onSearchRecipientTextChange={onSearchRecipientTextChange}
                                                onSubmit={onMaintenanceRecipientFormSubmit}
                                            />

                                            {formik.values.notification.recipients?.map((d, index) => (
                                                <Row
                                                    gutter={[24, 6]}
                                                    className="recipient-row"
                                                    align="middle"
                                                    key={index}
                                                >
                                                    <Col span={8} className="recipient-col-name">
                                                        {d.name}
                                                    </Col>
                                                    <Col span={5}>
                                                        <CheckBoxField
                                                            name={`notification.recipients.${index}.emailNotification`}
                                                            value={d.emailNotification}
                                                            onChange={() => evaluateSubmitButton()}
                                                        >
                                                            {t('common.email')}
                                                        </CheckBoxField>
                                                    </Col>
                                                    <Col span={9}>
                                                        <CheckBoxField
                                                            disabled={d.email ? true : false}
                                                            name={`notification.recipients.${index}.systemNotification`}
                                                            value={d.systemNotification}
                                                            onChange={() => evaluateSubmitButton()}
                                                        >
                                                            {t('Maintenance.task.systemNotification')}
                                                        </CheckBoxField>
                                                    </Col>
                                                    <Col span={2} className="recipient-col-remove">
                                                        <Button
                                                            type="link"
                                                            icon={<img src={CommonIcons.trashBin} alt="remove" />}
                                                            size="middle"
                                                            onClick={() => handleRemoveRecipient(index)}
                                                        />
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>

                            <Collapse
                                expandIconPosition="right"
                                bordered={true}
                                onChange={collapseChange}
                                className="maintenance-task-form-inputs-additional"
                            >
                                <Collapse.Panel
                                    header={
                                        <Row justify="space-between">
                                            <Col>
                                                {t('Maintenance.task.expenses')}{' '}
                                                <span className="optional">({t('common.optional')})</span>
                                            </Col>
                                            <Col className="expand-title">
                                                {openExpenses ? t('common.hide') : t('common.show')}
                                            </Col>
                                        </Row>
                                    }
                                    key={4}
                                >
                                    <Row gutter={[12, 12]}>
                                        <Col span={7}>
                                            <InputField.Number
                                                name="expenses.priceTotal"
                                                min={0}
                                                size="large"
                                                label={`${t('common.priceTotal')} (${t('common.excludingVAT')})`}
                                                disabled={
                                                    formik.values.expenses?.items &&
                                                    formik.values.expenses?.items?.length > 0
                                                }
                                                onChange={val => onExpensesPriceTotalChange(val)}
                                            />
                                        </Col>
                                        <Col span={7}>
                                            <InputField.Number
                                                name="expenses.vat"
                                                min={0}
                                                size="large"
                                                label={`${t('common.vat')} (%)`}
                                                onChange={val => onExpensesVatChange(val)}
                                            />
                                        </Col>
                                        <Col span={7}>
                                            <InputField.Number
                                                name="expenses.priceTotalWithVat"
                                                min={0}
                                                size="large"
                                                label={`${t('common.priceTotal')} (${t('common.includingVAT')})`}
                                                disabled={
                                                    formik.values.expenses?.items &&
                                                    formik.values.expenses?.items?.length > 0
                                                }
                                                onChange={() => evaluateSubmitButton()}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <SelectField
                                                name="expenses.currency"
                                                options={currencyOpts}
                                                label={t('common.currency')}
                                                size="large"
                                                allowClear
                                                onChange={() => evaluateSubmitButton()}
                                            />
                                        </Col>
                                        <Col span={14}>
                                            <Select<'noSupplier' | 'newSupplier' | string>
                                                value={supplierSelected}
                                                size="large"
                                                label={t('common.supplier')}
                                                onSelect={onSupplierSelect}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    searched(input, String(option?.children))
                                                }
                                                loading={clientOptsLoading}
                                            >
                                                <Select.Option value="noSupplier">
                                                    {t('Maintenance.task.noSupplier')}
                                                </Select.Option>
                                                <Select.Option value="newSupplier" key="newSupplier">
                                                    <div className="maintenance-task-form-supplier-item-new">
                                                        <img src={CommonIcons.addPlusCircle} alt="add new client" />
                                                        {t('common.createNewSupplier')}
                                                    </div>
                                                </Select.Option>
                                                <Select.OptGroup label={t('routing.configurator.select.clients')}>
                                                    {clientOptions.map((option, index) => (
                                                        <Select.Option
                                                            data-qa={option.qa}
                                                            key={`supplier-${index}-${option.value}`}
                                                            value={option.value ?? 0}
                                                        >
                                                            {option.label}
                                                        </Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Collapse
                                        className="expenses-items"
                                        expandIconPosition="right"
                                        bordered={true}
                                        onChange={collapsePriceChange}
                                    >
                                        <Collapse.Panel
                                            header={
                                                <Row justify="end">
                                                    <Col className="expand-title">
                                                        {openDetailedPrice
                                                            ? t('Maintenance.task.hideDetailedPriceBreakdown')
                                                            : t('Maintenance.task.showDetailedPriceBreakdown')}
                                                    </Col>
                                                </Row>
                                            }
                                            key="price"
                                        >
                                            <Row gutter={[12, 12]} align="middle">
                                                {formik.values.expenses.items?.map((d, index) => (
                                                    <Fragment key={index}>
                                                        <Col span={10}>
                                                            <InputField
                                                                name={`expenses.items.${index}.name`}
                                                                size="large"
                                                                label={`${t('common.item')} ${index + 1}`}
                                                            />
                                                        </Col>
                                                        <Col span={5}>
                                                            <SelectField
                                                                options={expensesTypeOptions}
                                                                name={`expenses.items.${index}.type`}
                                                                size="large"
                                                                label={`${t('common.type')} (${t('common.optional')})`}
                                                            />
                                                        </Col>
                                                        <Col span={6}>
                                                            <InputField.Number
                                                                name={`expenses.items.${index}.priceWoVat`}
                                                                size="large"
                                                                min={0.01}
                                                                label={`${t('Maintenance.task.itemPrice')} (${t(
                                                                    'common.excludingVAT'
                                                                )})`}
                                                                onChange={val => onExpensesItemChange(val, index)}
                                                            />
                                                        </Col>
                                                        <Col span={3}>
                                                            <Button
                                                                block
                                                                type="link"
                                                                className="empty-label"
                                                                size="middle"
                                                                disabled={demoMode}
                                                                icon={
                                                                    <img src={CommonIcons.trashBin} alt="remove-item" />
                                                                }
                                                                onClick={() => handleRemoveExpensesItem(index)}
                                                            />
                                                        </Col>
                                                    </Fragment>
                                                ))}
                                            </Row>

                                            <MaintenanceExpenseForm
                                                typeOpts={expensesTypeOptions}
                                                onSubmit={onMaintenanceExpenseFormSubmit}
                                                initialValues={{
                                                    name: ''
                                                }}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={
                                        <Row justify="space-between">
                                            <Col>
                                                {t('common.notes')}{' '}
                                                <span className="optional">({t('common.optional')})</span>
                                            </Col>
                                            <Col className="expand-title">
                                                {openNotes ? t('common.hide') : t('common.show')}
                                            </Col>
                                        </Row>
                                    }
                                    key={5}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <TextareaField
                                                name="notes"
                                                placeholder={t('Maintenance.task.notesPlaceholder')}
                                                onChangeCapture={() => evaluateSubmitButton()}
                                            />
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Form>
                        <Row gutter={12} justify="end" className="buttons-group">
                            <Col>
                                <Button type="default" onClick={onCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    disabled={formik.isSubmitting || locked || demoMode}
                                    type="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        formik.submitForm();
                                    }}
                                    htmlType="submit"
                                >
                                    {mode === 'CREATE' ? t('common.create') : t('common.saveChanges')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(withLogicContext(MaintenanceForm));
