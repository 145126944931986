import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Col, Row } from 'antd';

import { Button } from 'common/components';
import { DatePicker } from 'common/components';
import qa from 'qa-selectors';
import { chevronLeft, chevronRight } from 'resources/images/common';
import ButtonDateRangeTimeTrigger from '../ButtonDateRangeTimeTrigger';

interface Props {
    startDate: string;
    disabled?: boolean;
    onPreviousChange?: () => void;
    onNextChange?: () => void;
    onDatePickerChange?: (date: Moment) => void;
}

export default function DtcoCalendar(props: Props) {
    const { t } = useTranslation();

    return (
        <Row align="middle" gutter={8} className="dtco-calendar" justify="start">
            {props.onPreviousChange && (
                <Col>
                    <Button
                        type="default"
                        size="middle"
                        onClick={props.onPreviousChange}
                        qa={qa.remoteDownload.table.btnCalendarPrevious}
                        title={t('common.previous')}
                        icon={<img src={chevronLeft} alt="previous" />}
                    />
                </Col>
            )}
            <Col>
                <DatePicker.MonthPicker
                    onChange={value => props.onDatePickerChange?.(value as Moment)}
                    defaultValue={moment(props.startDate)}
                    showTime={false}
                    size="small"
                    disabled={props.disabled}
                    trigger={
                        <ButtonDateRangeTimeTrigger
                            size="small"
                            customLabel={`${moment(props.startDate).startOf('month').format('MMMM')} ${moment(
                                props.startDate
                            )
                                .startOf('month')
                                .format('YYYY')} `}
                            qa={qa.common.dateTimePicker.btn}
                        />
                    }
                />
            </Col>
            {props.onNextChange && (
                <Col>
                    <Button
                        type="default"
                        size="middle"
                        onClick={props.onNextChange}
                        qa={qa.remoteDownload.table.btnCalendarNext}
                        title={t('common.next')}
                        icon={<img src={chevronRight} alt="next" />}
                    />
                </Col>
            )}
        </Row>
    );
}
