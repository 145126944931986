/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructuredType,
    AddressStructuredTypeFromJSON,
    AddressStructuredTypeFromJSONTyped,
    AddressStructuredTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReverseGeocodeResponseFormatted
 */
export interface ReverseGeocodeResponseFormatted {
    /**
     * 
     * @type {Array<AddressStructuredType>}
     * @memberof ReverseGeocodeResponseFormatted
     */
    data: Array<AddressStructuredType>;
    /**
     * 
     * @type {boolean}
     * @memberof ReverseGeocodeResponseFormatted
     */
    cache: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReverseGeocodeResponseFormatted
     */
    x?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReverseGeocodeResponseFormatted
     */
    y?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReverseGeocodeResponseFormatted
     */
    distance?: number | null;
}

export function ReverseGeocodeResponseFormattedFromJSON(json: any): ReverseGeocodeResponseFormatted {
    return ReverseGeocodeResponseFormattedFromJSONTyped(json, false);
}

export function ReverseGeocodeResponseFormattedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReverseGeocodeResponseFormatted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(AddressStructuredTypeFromJSON)),
        'cache': json['cache'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
    };
}

export function ReverseGeocodeResponseFormattedToJSON(value?: ReverseGeocodeResponseFormatted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(AddressStructuredTypeToJSON)),
        'cache': value.cache,
        'x': value.x,
        'y': value.y,
        'distance': value.distance,
    };
}


