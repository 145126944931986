import { Row } from 'antd';
import cn from 'classnames';
import { DriverBehaviorCoachModel } from 'common/model/statistics';
import { useTranslation } from 'react-i18next';
import { vehiclesList } from 'resources/images/driver-behavior';

interface Props {
    driversList?: DriverBehaviorCoachModel[];
    qa?: string;
}

export default function DriverVehicleList(props: Props) {
    const { t } = useTranslation();

    return (
        <div
            className={cn('driver-vehicle-list', {
                'driver-vehicle-list-multi': (props.driversList?.length ?? 0) > 1
            })}
            data-qa={props.qa}
        >
            <Row className="driver-vehicle-list-vehicle-img">
                <img src={vehiclesList} alt="img-vehicles" />
            </Row>
            <Row className="driver-vehicle-list-container">
                {props.driversList?.map((d, index) => (
                    <Row key={index} className="driver-vehicle-list-vehicles">
                        <>
                            {(props.driversList?.length ?? 0) > 1 && (
                                <div
                                    className={cn('driver-vehicle-list-vehicles-name', {
                                        'no-data-text': !d.vehicles
                                    })}
                                >
                                    {d.name}
                                </div>
                            )}
                            <div className="driver-vehicle-list-vehicles-list">
                                {d.vehicles ? (
                                    d.vehicles.map(v => <div className="driver-vehicle-list-vehicles-vehicle">{v}</div>)
                                ) : (
                                    <div className="no-data-text">{t('common.noData').toLowerCase()}</div>
                                )}
                            </div>
                        </>
                    </Row>
                ))}
            </Row>
        </div>
    );
}
