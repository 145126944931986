/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputVehicleProfile,
    InputVehicleProfileFromJSON,
    InputVehicleProfileFromJSONTyped,
    InputVehicleProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface TollCostRequestBody
 */
export interface TollCostRequestBody {
    /**
     * 
     * @type {Date}
     * @memberof TollCostRequestBody
     */
    departureTime: Date;
    /**
     * 
     * @type {string}
     * @memberof TollCostRequestBody
     */
    polyline: string;
    /**
     * 
     * @type {string}
     * @memberof TollCostRequestBody
     */
    currency: string;
    /**
     * 
     * @type {InputVehicleProfile}
     * @memberof TollCostRequestBody
     */
    profile?: InputVehicleProfile;
}

export function TollCostRequestBodyFromJSON(json: any): TollCostRequestBody {
    return TollCostRequestBodyFromJSONTyped(json, false);
}

export function TollCostRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TollCostRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departureTime': (new Date(json['departureTime'])),
        'polyline': json['polyline'],
        'currency': json['currency'],
        'profile': !exists(json, 'profile') ? undefined : InputVehicleProfileFromJSON(json['profile']),
    };
}

export function TollCostRequestBodyToJSON(value?: TollCostRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departureTime': (value.departureTime.toISOString()),
        'polyline': value.polyline,
        'currency': value.currency,
        'profile': InputVehicleProfileToJSON(value.profile),
    };
}


