import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { arrow, arrows } from 'resources/images/driver-behavior';
import { Button } from 'common/components';
import { placeHolder } from 'resources/images/driver-behavior/coach-icon';
import { DriverBehaviorCoachDataMap } from '../DriverBehaviorCoach';
import { translateFormatedText } from 'common/utils/textUtils';
import { bannerBust } from 'resources/images/common';

export interface DriverBehaviorRecomendsData {
    key: string;
    text: string;
}

interface Props {
    className?: string;
    dataList?: DriverBehaviorCoachDataMap[];
    icon?: string;
    iconColor?: string;
    qa?: string;
    onChange?: (key: string) => void;
}

export default function DriverBehaviorRecomends(props: Props) {
    const [selected, setSelected] = useState<number>(0);
    const { t } = useTranslation();

    const handlerPreviousClick = () => {
        if (selected > 0) {
            setSelected(selected - 1);
            props.onChange?.(props.dataList?.[selected - 1].key ?? '');
        }
    };

    const handlerNextClick = () => {
        if (selected < (props.dataList?.length ?? 0) - 1) {
            setSelected(selected + 1);
            props.onChange?.(props.dataList?.[selected + 1]?.key ?? '');
        }
    };

    const background = {
        backgroundImage: `url("${arrows}")`,
        backgroundRepeat: 'repeat-x'
    };

    return (
        <div className={`driver-behavior-recomends${props.className ? ` ${props.className}` : ''}`} data-qa={props.qa}>
            <div className="driver-behavior-recomends-container" data-qa={props.qa}>
                <Row className="driver-behavior-recomends-header">
                    <Col flex={1} className="driver-behavior-recomends-header-left">
                        <Row className="title" justify="space-between">
                            <Col>{t('DriverBehavior.recomends.title')}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="icon">
                                    <img src={props.icon ?? placeHolder} alt="recomends-icon" />
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="image">
                        <img src={bannerBust} alt="driver-recomends" />
                    </Col>
                </Row>
                {(props.dataList?.length ?? 0) > 0 && (
                    <div className="text-container">
                        <div className="text-content">
                            <p>{translateFormatedText(props.dataList?.[selected]?.recommend)}</p>
                        </div>
                        <div className="buttons">
                            <Button disabled={selected === 0} onClick={handlerPreviousClick}>
                                <img src={arrow} alt="arrow-right" /> {t('common.previous')}
                            </Button>
                            <Button
                                disabled={selected === (props.dataList?.length ?? 0) - 1}
                                onClick={handlerNextClick}
                            >
                                {t('DriverBehaviorDetail.nextTip')} <img src={arrow} alt="arrow-left" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div className="arrows" style={background} />
        </div>
    );
}
