/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse200TollByCountry,
    InlineResponse200TollByCountryFromJSON,
    InlineResponse200TollByCountryFromJSONTyped,
    InlineResponse200TollByCountryToJSON,
    PTVTollCostTotalCost,
    PTVTollCostTotalCostFromJSON,
    PTVTollCostTotalCostFromJSONTyped,
    PTVTollCostTotalCostToJSON,
} from './';

/**
 * 
 * @export
 * @interface PTVTollCost
 */
export interface PTVTollCost {
    /**
     * 
     * @type {Array<InlineResponse200TollByCountry>}
     * @memberof PTVTollCost
     */
    costsByCountry: Array<InlineResponse200TollByCountry>;
    /**
     * 
     * @type {PTVTollCostTotalCost}
     * @memberof PTVTollCost
     */
    totalCost: PTVTollCostTotalCost;
}

export function PTVTollCostFromJSON(json: any): PTVTollCost {
    return PTVTollCostFromJSONTyped(json, false);
}

export function PTVTollCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): PTVTollCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costsByCountry': ((json['costsByCountry'] as Array<any>).map(InlineResponse200TollByCountryFromJSON)),
        'totalCost': PTVTollCostTotalCostFromJSON(json['totalCost']),
    };
}

export function PTVTollCostToJSON(value?: PTVTollCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costsByCountry': ((value.costsByCountry as Array<any>).map(InlineResponse200TollByCountryToJSON)),
        'totalCost': PTVTollCostTotalCostToJSON(value.totalCost),
    };
}


