import { Configuration, TCOInput, TCOOutput, TCOSettingsOutput } from 'generated/new-main';
import { TcoApi } from 'generated/new-main/apis/TcoApi';
import { action, makeObservable, observable } from 'mobx';
import numeral from 'numeral';
import { Conf } from 'tco/conf';
import { calculated, settings, constants } from './mocked/mocked';

interface TcoCommonValue {
    type: string;
    default_value: number | string;
    hint: string;
    units: string;
}

export interface TcoCalculatedTableData {
    name: string;
    yearCount: number;
    units: string;
    [key: string]: string | number;
}

export interface TcoSumTableDataDetail {
    purchaseCost?: {
        value?: number;
        units?: string;
    };
    insuranceCost?: {
        value?: number;
        units?: string;
    };
    serviceAndMaintenanceCost?: {
        value?: number;
        units?: string;
    };
    fuelCost?: {
        value?: number;
        units?: string;
    };
    tollCost?: {
        value?: number;
        units?: string;
    };
    otherCost?: {
        value?: number;
        units?: string;
    };
    adblueCosts?: {
        value?: number;
        units?: string;
    }; //(iba v pripade Diesel vozidla)
    totalCost?: {
        value?: number;
        units?: string;
    };
    gCo2PerUnitProduction?: {
        value?: number;
        units?: string;
    };
}

export interface GCo2Constant {
    diesel?: number;
    lng?: number;
}

export interface TcoSumTableData {
    lng: Omit<TcoSumTableDataDetail, 'adblueCosts'>;
    diesel: TcoSumTableDataDetail;
    saving?: {
        percentage: number;
        absolute: number;
        units?: string;
    };
    gCo2Constant: GCo2Constant;
}

export class TotalCostOfOwnerShipLogic {
    private _tcoApi?: TcoApi;
    private _conf: Conf;
    @observable isDemoMode: boolean = false;

    @observable settingsLoading: boolean = false;
    @observable settings?: Omit<TCOSettingsOutput, 'constants'>;
    @observable settingsConstants?: TCOSettingsOutput['constants'];

    @observable calculateLoading: boolean = false;
    @observable calculatedData?: TCOOutput;

    constructor(conf: Conf, isDemoMode: boolean = false) {
        this._conf = conf;
        this.isDemoMode = isDemoMode;
        makeObservable(this);
    }

    get tcoApi(): TcoApi {
        if (this._tcoApi) {
            return this._tcoApi;
        }
        return new TcoApi(new Configuration({ basePath: this._conf.api.newMain.basePath }));
    }

    get gCo2Constant(): GCo2Constant {
        return {
            diesel: this.settings?.dieselVehicleDetails.values.gCo2PerUnitProduction.defaultValue,
            lng: this.settings?.lngVehicleDetails.values.gCo2PerUnitProduction.defaultValue
        };
    }

    get formInitValues(): TCOInput {
        const settings = this.settings ? { ...this.settings } : undefined;
        let result: TCOInput | {} = {};
        if (settings) {
            Object.keys(settings).forEach((key: string) => {
                const keyTyped: keyof TCOSettingsOutput = key as keyof TCOSettingsOutput;
                if (['integer', 'float'].includes(settings[keyTyped].type as string)) {
                    result[keyTyped] = settings[keyTyped].defaultValue;
                } else if (['array'].includes(settings[keyTyped].type as string)) {
                    // @ts-ignore
                    const values: { [key: string]: TcoCommonValue } = settings[keyTyped].values;
                    result[keyTyped] = {};
                    Object.keys(values).forEach((keyValues: string) => {
                        if (values[keyValues]) {
                            // @ts-ignore
                            result[keyTyped][keyValues] = values[keyValues].defaultValue;
                        } else {
                            result[keyTyped][keyValues] = 0;
                        }
                    });
                }
            });
        }
        return result as TCOInput;
    }

    get tableCalculatedTableData(): TcoCalculatedTableData[] {
        const resultData: TcoCalculatedTableData[] = [];
        const calculatedData = this.calculatedData;
        if (calculatedData) {
            Object.keys(calculatedData).forEach(key => {
                const calculatedDataKey = key as keyof Omit<TCOOutput, 'total'>;
                if (key !== 'total') {
                    calculatedData[calculatedDataKey].forEach(data => {
                        Object.keys(data).forEach(rowKey => {
                            if (rowKey !== 'year') {
                                const typedRowKey = rowKey as keyof typeof data;
                                const foundRowIndex = resultData.findIndex(d => d.name === typedRowKey);
                                const row: TcoCalculatedTableData = {
                                    name: typedRowKey,
                                    yearCount: 1,
                                    [`${calculatedDataKey}.${data.year.value}.unit`]: data[typedRowKey].units,
                                    [`${calculatedDataKey}.${data.year.value}.value`]: numeral(
                                        data[typedRowKey].value
                                    ).format(data[typedRowKey].units === '&euro;' ? '0,0.00' : '0,0.0'),
                                    units: data[typedRowKey].units
                                };

                                if (foundRowIndex !== -1) {
                                    resultData[foundRowIndex] = {
                                        ...resultData[foundRowIndex],
                                        ...row,
                                        yearCount: resultData[foundRowIndex].yearCount + 1
                                    };
                                } else {
                                    resultData.push(row);
                                }
                            }
                        });
                    });
                }
            });

            Object.keys(calculatedData['total']).forEach(totalKey => {
                Object.keys(calculatedData['total'][totalKey]).forEach(rowKey => {
                    const foundIndex = resultData.findIndex(rd => rd.name === rowKey);
                    if (foundIndex !== -1) {
                        const row = resultData[foundIndex];
                        resultData[foundIndex] = {
                            ...row,
                            [`total.${totalKey}`]: numeral(calculatedData['total'][totalKey][rowKey].value).format(
                                '0,0.00'
                            )
                        };
                    }
                });
            });
        }

        // has to add total cost at the end.
        const totalCost = resultData.find(d => d.name === 'totalCost');
        if (totalCost) {
            resultData.splice(resultData.indexOf(totalCost), 1);
            resultData.push(totalCost);
        }

        return resultData;
    }

    get tableSumTableData(): TcoSumTableData {
        const result: TcoSumTableData = {
            diesel: {
                fuelCost: this.calculatedData?.total.dieselVehicleDetails.fuelCost,
                insuranceCost: this.calculatedData?.total.dieselVehicleDetails.insuranceCost,
                otherCost: this.calculatedData?.total.dieselVehicleDetails.otherCost,
                purchaseCost: this.calculatedData?.total.dieselVehicleDetails.purchaseCost,
                serviceAndMaintenanceCost: this.calculatedData?.total.dieselVehicleDetails.serviceAndMaintenanceCost,
                tollCost: this.calculatedData?.total.dieselVehicleDetails.tollCost,
                adblueCosts: this.calculatedData?.total.dieselVehicleDetails.adblueCosts,
                totalCost: this.calculatedData?.total.dieselVehicleDetails.totalCost,
                gCo2PerUnitProduction: this.calculatedData?.total.dieselVehicleDetails.gCo2PerUnitProduction
            },
            lng: {
                fuelCost: this.calculatedData?.total.lngVehicleDetails.fuelCost,
                insuranceCost: this.calculatedData?.total.lngVehicleDetails.insuranceCost,
                otherCost: this.calculatedData?.total.lngVehicleDetails.otherCost,
                purchaseCost: this.calculatedData?.total.lngVehicleDetails.purchaseCost,
                serviceAndMaintenanceCost: this.calculatedData?.total.lngVehicleDetails.serviceAndMaintenanceCost,
                tollCost: this.calculatedData?.total.lngVehicleDetails.tollCost,
                totalCost: this.calculatedData?.total.lngVehicleDetails.totalCost,
                gCo2PerUnitProduction: this.calculatedData?.total.lngVehicleDetails.gCo2PerUnitProduction
            },
            gCo2Constant: this.gCo2Constant
        };

        if (this.calculatedData) {
            const saving =
                this.calculatedData.total.dieselVehicleDetails.totalCost.value -
                this.calculatedData.total.lngVehicleDetails.totalCost.value;

            if (saving > 0) {
                result.saving = {
                    units: this.calculatedData.total.dieselVehicleDetails.totalCost.units,
                    absolute: saving,
                    percentage:
                        Math.round(
                            (100 -
                                this.calculatedData.total.lngVehicleDetails.totalCost.value /
                                    (this.calculatedData.total.dieselVehicleDetails.totalCost.value / 100) +
                                Number.EPSILON) *
                                100
                        ) / 100
                };
            }
        }

        return result;
    }

    @action
    async init() {
        if (this.isDemoMode) {
            this.settings = settings;
            this.settingsConstants = constants;
        } else {
            this.settingsLoading = true;
            try {
                const settings = await this.tcoApi.tcoGetSettings();
                this.settings = {
                    annualMileage: settings.annualMileage,
                    dieselVehicleDetails: settings.dieselVehicleDetails,
                    lngVehicleDetails: settings.lngVehicleDetails,
                    operationalLifetime: settings.operationalLifetime
                };
                this.settingsConstants = settings.constants;
            } catch (err) {
                console.error(`Fail to load settings, err: `, err);
            }
            this.settingsLoading = false;
        }
    }

    @action
    async tcoCalculate(data: TCOInput) {
        if (this.isDemoMode) {
            this.calculatedData = calculated;
        } else {
            this.calculateLoading = true;
            try {
                this.calculatedData = await this.tcoApi.tcoCalculate({
                    data
                });
            } catch (err) {
                console.error(`Fail to calculate TCO, err: `, err);
            }
            this.calculateLoading = false;
        }
    }
}
