/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceList
 */
export interface DeviceList {
    /**
     * 
     * @type {number}
     * @memberof DeviceList
     */
    deviceId: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceList
     */
    deviceName: string;
}

export function DeviceListFromJSON(json: any): DeviceList {
    return DeviceListFromJSONTyped(json, false);
}

export function DeviceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'deviceName': json['deviceName'],
    };
}

export function DeviceListToJSON(value?: DeviceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'deviceName': value.deviceName,
    };
}


