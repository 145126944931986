/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ExpenseItemType {
    Fuel = 'fuel',
    Oil = 'oil',
    Toll = 'toll',
    Adblue = 'adblue',
    Insurance = 'insurance',
    Driver = 'driver',
    Fixed = 'fixed',
    Labor = 'labor',
    Tires = 'tires',
    Vignette = 'vignette'
}

export function ExpenseItemTypeFromJSON(json: any): ExpenseItemType {
    return ExpenseItemTypeFromJSONTyped(json, false);
}

export function ExpenseItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseItemType {
    return json as ExpenseItemType;
}

export function ExpenseItemTypeToJSON(value?: ExpenseItemType | null): any {
    return value as any;
}

