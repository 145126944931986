import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { VehiclesRemoteMemoryModel } from 'logic/management/management-vehicles-remote-memory';
import DateGridTable, { DateGridTableModel } from 'common/components/DateGridTable';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import Button from 'common/components/Button';
import DtcoCalendar from 'common/components/DtcoCalendar';
import * as CommonIcons from 'resources/images/common';
import { CcrModel } from '../../driver-cards-remote-memory/DriversCardsRemoteMemoryModule';
import DriverCardsRemoteMemoryCcr from '../../driver-cards-remote-memory/ui/DriverCardsRemoteMemoryBar';

interface Props {
    search: { text: string };
    table?: {
        data: VehiclesRemoteMemoryModel[];
        loadingDemandIds: string[];
        loading: boolean;
        onDayDownloadClick?: (id: string) => void;
        onRemoteDownloadClick?: (id: string) => void;
        onMonthlyDownloadClick?: (id: string) => void;
    };
    startDate: string;
    ccr: {
        open: boolean;
        edit: boolean;
        data: CcrModel[];
    };
    helper?: {
        content: string;
    };
    demoMode?: boolean;
    onSearchChange?: (text: string) => void;
    onBarHelperClick?: () => void;
    onBarPreviousChange?: () => void;
    onBarNextChange?: () => void;
    onBarDatePickerChange?: (date: Moment) => void;
    onBarDownloadAllClick?: () => void;
    onBarCcrClick?: () => void;
    onHelperClose?: () => void;
}

export function VehiclesRemoteMemory(props: Props) {
    const data: DateGridTableModel[] = props.table
        ? props.table.data.map(row => ({
              id: row.id,
              firstColValue: row.disabledAt ? `*${row.licensePlate}` : row.licensePlate,
              secondColValue: row.id,
              downloads: row.cards
                  .filter(card => !(card.demand && card.downloadedFile))
                  .map(card => ({
                      id: card.id,
                      fileName: card.downloadedFile,
                      date: moment(card.date).startOf('day').toString(),
                      processing:
                          card.demand && !!card.downloadRequested && !card.downloadedFile && !card.downloadStarted
                  }))
                  .concat(
                      row.cardsDemanded.map(card => ({
                          id: '',
                          fileName: card.downloadedFile,
                          date: moment(card.date).startOf('day').toString(),
                          processing: true
                      }))
                  )
          }))
        : [];

    const { t } = useTranslation();
    return (
        <div className="vehicles-remote-memory page">
            <TableBar
                heading={t('VehiclesRemoteMemoryBar.vehiclesMemory')}
                datePicker={
                    <DtcoCalendar
                        startDate={props.startDate}
                        onNextChange={props.onBarNextChange}
                        onPreviousChange={props.onBarPreviousChange}
                        onDatePickerChange={props.onBarDatePickerChange}
                    />
                }
                actions={[
                    <DriverCardsRemoteMemoryCcr
                        editCcr={false}
                        data={props.ccr.data}
                        onCcrClick={props.onBarCcrClick}
                    />,
                    <Button
                        type="dashed"
                        icon={<img src={CommonIcons.downloadFolder} alt={t('RemoteDownload.dowloadAllVehicles')} />}
                        onClick={props.onBarDownloadAllClick}
                        disabled={props.demoMode || !props.table?.data.filter(row => row.downloadAvailable).length}
                    >
                        {t('RemoteDownload.dowloadAllVehicles')}
                    </Button>
                ]}
                search={props.search}
                onSearchChange={props.onSearchChange}
                onHelperClick={props.onBarHelperClick}
            />

            {props.table && (
                <div className="tm-vehicles-remote-memory-table">
                    <DateGridTable
                        data={data}
                        loadingDemandIds={props.table.loadingDemandIds}
                        demoMode={props.demoMode}
                        onDayDownloadClick={props.table.onDayDownloadClick}
                        startDate={props.startDate}
                        loading={props.table.loading}
                        widthFirstCol={120}
                        onRemoteDownloadClick={props.table?.onRemoteDownloadClick}
                        onMonthlyDownloadClick={props.table?.onMonthlyDownloadClick}
                    />
                </div>
            )}

            <HelperModal
                name="remote-memory"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}
