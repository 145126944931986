import cn from 'classnames';
import { Button, Col, Progress, Row } from 'antd';
import moment, { duration } from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MAX_BIWEEKLY_RIDE, MAX_WEEKLY_RIDE, MIN_WEEKLY_RIDE, TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { ColumnType } from 'antd/lib/table';

import Table from 'common/components/Table';
import { AetrModel, DailyPerformance } from 'modules/statistics/modules/aetr/AetrModule';
import AlarmDuration from 'common/components/AlarmDuration';
import PerformanceBar from 'common/components/PerformanceBar';
import Tooltip from 'common/components/Tooltip';
import qa from 'qa-selectors';
import { NoData } from 'common/components/NoData';

interface OwnProps {
    data?: AetrModel[];
    previous?: AetrModel[];
    openedWeeks: string[];
    openedDays: string[];
    loading: boolean;
    selected?: string[];
    onWeeksExpand?: (keyWeek: string, tachocard: string) => void;
    onDaysExpand?: (keyDay: string, tachocard: string, week: string) => void;
}

type Props = OwnProps & WithTranslation;

function AetrTable(props: Props) {
    const { t, data, loading, openedWeeks, openedDays, onDaysExpand, onWeeksExpand } = props;
    return (
        <div
            className={cn('aetr-table', {
                'aetr-table-loading': loading
            })}
        >
            <Table<AetrModel>
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                showHeader={true}
                loading={loading}
                onRow={(row, i) => ({
                    className: cn({
                        'row-main': row.week === '0' && i !== 0,
                        'opened-days': openedDays.includes(row.driverName + row.current.vehicleNumberPlate + row.week),
                        'opened-week': openedWeeks.includes(row.driverName + row.current.vehicleNumberPlate)
                    })
                })}
                dataSource={data
                    ?.map((e, i) => ({ ...e, i, key: i }))
                    .filter(d => d.week === '0' || openedWeeks.includes(d.driverName + d.current.vehicleNumberPlate))}
                columns={createColumns()}
                expandable={{
                    columnWidth: 80,
                    expandedRowRender: record =>
                        record.dailyPerformances && record.dailyPerformances?.length > 0 ? (
                            <div className="performance-table">
                                <Table<DailyPerformance>
                                    showHeader={true}
                                    dataSource={record.dailyPerformances
                                        ?.map((f, j) => ({ ...f, j, key: j }))
                                        .reverse()}
                                    columns={createColumnsDays()}
                                />
                            </div>
                        ) : (
                            !loading && <NoData />
                        ),
                    expandIcon: ({ expanded, onExpand, record }) => (
                        <Row justify="space-around">
                            <Col span={12}>
                                {record.week === '0' ? (
                                    <Button
                                        type="text"
                                        title={t('AetrTable.weeklyStats')}
                                        className="weekly-stats-btn"
                                        block
                                        disabled={
                                            data &&
                                            data.filter(
                                                d =>
                                                    d.driverName === record.driverName &&
                                                    d.current.vehicleNumberPlate === record.current.vehicleNumberPlate
                                            ).length === 1
                                        }
                                        onClick={() =>
                                            onWeeksExpand?.(
                                                record.driverName + record.current.vehicleNumberPlate,
                                                record.tachographCard
                                            )
                                        }
                                    >
                                        <i
                                            className={cn({
                                                'fa fa-chevron-down': openedWeeks.includes(
                                                    record.driverName + record.current.vehicleNumberPlate
                                                ),
                                                'fa fa-chevron-right': !openedWeeks.includes(
                                                    record.driverName + record.current.vehicleNumberPlate
                                                )
                                            })}
                                        />
                                    </Button>
                                ) : (
                                    <span className="weekly-stats">{Number(record.week) * -7}</span>
                                )}
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="text"
                                    title={t('AetrTable.dailyStats')}
                                    block
                                    onClick={e => {
                                        onExpand(record, e);
                                        onDaysExpand?.(
                                            record.driverName + record.current.vehicleNumberPlate + record.week,
                                            record.tachographCard,
                                            record.week ?? '0'
                                        );
                                    }}
                                >
                                    <i className={cn('fa', { 'fa-minus': expanded, 'fa-plus': !expanded })} />
                                </Button>
                            </Col>
                        </Row>
                    )
                }}
            />
        </div>
    );

    function createColumnsDays() {
        const { t } = props;
        return [
            {
                title: ' ',
                align: 'center',
                dataIndex: 'driver',
                key: 'reliable',
                className: 'reliable',
                width: '6%',
                render: (_, row) => (
                    <div className="reliable-item">{!row.reliable ? <i className="fa fa-warning" /> : ' '}</div>
                )
            },
            {
                title: 'Day',
                align: 'left',
                dataIndex: 'day',
                className: 'day',
                key: 'day',
                width: '6%',
                render: (_, row) => moment(row.date).add(59, 'minutes').format('MMM Do')
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.performances'),
                align: 'center',
                dataIndex: 'performances',
                className: 'performances',
                key: 'performances',
                width: '48%',
                render: (_, row) => <PerformanceBar performances={row.intervals} tooltip={true} table={true} />
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.drivingDuration'),
                align: 'center',
                dataIndex: 'drivingDuration',
                key: 'drivingDuration',
                width: '8%',
                render: (_, row) =>
                    duration(row.drivingDuration, 'seconds').format('hh:mm', {
                        trim: false
                    })
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.workingDuration'),
                align: 'center',
                dataIndex: 'workingDuration',
                key: 'workingDuration',
                width: '8%',
                render: (_, row) =>
                    duration(row.workingDuration, 'seconds').format('hh:mm', {
                        trim: false
                    })
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.restingDuration'),
                align: 'center',
                dataIndex: 'restingDuration',
                key: 'restingDuration',
                width: '8%',
                render: (_, row) =>
                    duration(row.restingDuration, 'seconds').format('hh:mm', {
                        trim: false
                    })
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.extendedDrive10h'),
                align: 'center',
                dataIndex: 'extendedDrive',
                key: 'extendedDrive',
                width: '8%',
                render: (_, row) => row.extendedDrive && <i className="fa fa-check" />
            } as ColumnType<DailyPerformance>,
            {
                title: t('AetrTable.reducedDailyRest'),
                align: 'center',
                dataIndex: 'reducedDailyRest',
                key: 'reducedDailyRest',
                width: '8%',
                render: (_, row) => row.reducedDailyRest && <i className="fa fa-check" />
            } as ColumnType<DailyPerformance>
        ] as ColumnType<DailyPerformance>[];
    }

    function createColumns() {
        const { t } = props;
        return [
            emptyColumn(),
            {
                title: ' ',
                align: 'center',
                dataIndex: 'driver',
                key: 'reliable',
                className: 'reliable',
                width: '2%',
                render: (_, row) => (
                    <div className="reliable-item">
                        {!row.current.reliable ? (
                            <>
                                <i className="fa fa-warning" />
                                <Tooltip
                                    qa={qa.aetr.table.reliable}
                                    overlay={t('AetrTable.reliableInfo')}
                                    children={<i className="reliable-info fas fa-info-circle" />}
                                />
                            </>
                        ) : (
                            ' '
                        )}
                    </div>
                )
            },
            {
                title: t(`AetrTable.driver`),
                align: 'left',
                dataIndex: 'driver',
                key: 'driver',
                className: 'driver',
                width: '10%',
                render: (_, row) => (
                    <div
                        className={cn({
                            'opened-week':
                                row.week === '0' &&
                                openedWeeks.includes(row.driverName + row.current.vehicleNumberPlate)
                        })}
                    >
                        {row.week === '0' ? (
                            <>
                                <span data-qa={qa.aetr.table.fieldDriver}>{row.driverName}</span>
                                {row.isCrew && (
                                    <Tooltip
                                        overlay={t('AetrTable.driverInDoubleCrew')}
                                        children={
                                            <i
                                                className="aetr-table-double-crew fas fa-user-plus"
                                                data-qa={qa.aetr.table.fieldDoubleCrew}
                                            />
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                )
            },
            {
                title: t(`AetrTable.licensePlate`),
                width: '8%',
                align: 'center',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                render: (_, row) => (
                    <div className={cn('main-row')}>
                        {row.week === '0' ? (
                            <span data-qa={qa.aetr.table.fieldRn}>{row.current.vehicleNumberPlate}</span>
                        ) : (
                            ''
                        )}
                    </div>
                )
            },
            {
                title: t(`AetrTable.currentDay`),
                width: '16%',
                align: 'center',
                dataIndex: 'currentDayDriveLeft',
                key: 'currentDayDriveLeft',
                children: [currentDayDriveLeft()] as ColumnType<AetrModel>[]
            },
            {
                title: t(`AetrTable.weeklyDriveStats`),
                width: '16%',
                align: 'center',
                dataIndex: 'currentWeekDriveLeft',
                key: 'currentWeekDriveLeft',
                children: [currentWeekDriveLeft()] as ColumnType<AetrModel>[]
            },
            {
                title: t(`AetrTable.14daysDrive`),
                width: '8%',
                align: 'center',
                dataIndex: 'column14daysDrive',
                key: 'column14daysDrive',
                className: 'column14daysDrive',
                render: (_, row) => {
                    return <>{column14daysDriveRow(row, 0, false)}</>;
                }
            },
            {
                title: t(`AetrTable.previousWeekRest`),
                width: '8%',
                align: 'center',
                dataIndex: 'previousWeekRestLength',
                key: 'previousWeekRestLength',
                children: [previousWeekRestLength()] as ColumnType<AetrModel>[]
            },
            {
                title: t(`AetrTable.currentWeekStats`),
                width: '24%',
                align: 'center',
                dataIndex: 'currentWeek',
                key: 'currentWeek',
                children: [
                    currentWeekDriven(),
                    currentWeekExtendedDrive10h(),
                    currentWeekReducedDailyRest()
                ] as ColumnType<AetrModel>[]
            },
            {
                title: t(`AetrTable.nextWeekRest`),
                width: '8%',
                align: 'center',
                dataIndex: 'nextWeekRestLength',
                key: 'nextWeekRestLength',
                children: [nextWeekRestLength()] as ColumnType<AetrModel>[]
            }
        ] as ColumnType<AetrModel>[];
    }
    function emptyColumn() {
        return {
            title: '',
            dataIndex: 'epmty',
            key: 'empty',
            width: 1,
            children: [
                {
                    title: '',
                    dataIndex: 'epmty-col',
                    key: 'empty-col',
                    width: 1,
                    render: () => <></>
                }
            ] as ColumnType<AetrModel>[]
        };
    }

    function currentDayDriveLeft() {
        return {
            title: (
                <div className="aetr-progress-head">
                    <span>{t(`AetrTable.driveTime`)}</span>
                    <span>{t(`AetrTable.timeLeft`)}</span>
                </div>
            ),
            dataIndex: 'currentDay',
            key: 'currentDay',
            align: 'center',
            width: '16%',
            className: 'current-day-drive-left',
            render: (_, row) => {
                return (
                    <>
                        <div className="present">
                            <div className="main-row">{currentDayDriveLeftRow(row, 0, false)}</div>
                        </div>
                    </>
                );
            }
        } as ColumnType<AetrModel>;
    }

    function currentWeekDriveLeft() {
        return {
            title: (
                <div className="aetr-progress-head">
                    <span>{t(`AetrTable.driveTime`)}</span>
                    <span>{t(`AetrTable.timeLeft`)}</span>
                </div>
            ),
            dataIndex: 'currentWeek',
            key: 'currentWeek',
            align: 'center',
            width: '16%',
            className: 'with-progress',
            render: (_, row) => {
                return (
                    <div
                        className={cn({
                            'current-week-rowX': row.week === '0'
                        })}
                    >
                        {currentWeekDriveLeftRow(row, 0, false)}
                    </div>
                );
            }
        } as ColumnType<AetrModel>;
    }

    function previousWeekRestLength() {
        return {
            title: t(`AetrTable.lengthOfRestPeriod`),
            dataIndex: 'previousWeekRestLength',
            key: 'previousWeekRestLength',
            align: 'center',
            className: 'previousWeekRestLength',
            width: '8%',
            render: (_, row) => <>{previousWeekRestLengthRow(row, 0, false)}</>
        } as ColumnType<AetrModel>;
    }

    function currentWeekDriven() {
        return {
            title: t(`AetrTable.daysDriven`),
            dataIndex: 'currentWeekStats_2',
            key: 'currentWeekStats_2',
            align: 'center',
            width: '8%',
            className: 'currentWeekStats_2',
            render: (_, row) => <>{currentWeekDrivenRow(row, 0, false)}</>
        } as ColumnType<AetrModel>;
    }

    function currentWeekExtendedDrive10h() {
        return {
            title: t(`AetrTable.extendedDrive10h`),
            dataIndex: 'currentWeekStats_3',
            key: 'currentWeekStats_3',
            align: 'center',
            width: '8%',
            className: 'currentWeekStats_3',
            render: (_, row) => <>{currentWeekExtendedDrive10hRow(row, 0, false)}</>
        } as ColumnType<AetrModel>;
    }

    function currentWeekReducedDailyRest() {
        return {
            title: t(`AetrTable.reducedDailyRest`),
            dataIndex: 'currentWeekStats_4',
            key: 'currentWeekStats_4',
            align: 'center',
            className: 'currentWeekStats_4',
            width: '8%',
            render: (_, row) => <>{currentWeekReducedDailyRestRow(row, 0, false)}</>
        } as ColumnType<AetrModel>;
    }

    function nextWeekRestLength() {
        return {
            title: t(`AetrTable.lengthOfRestPeriod`),
            dataIndex: 'nextWeekRest_2',
            key: 'nextWeekRest_2',
            align: 'center',
            width: '8%',
            className: 'nextWeekRest_2',
            render: (_, row) => <>{row.week === '0' ? nextWeekRestLengthRow(row, 0, false) : ''}</>
        } as ColumnType<AetrModel>;
    }

    function currentDayDriveLeftRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                {row.week === '0' && (
                    <>
                        <div className="aetr-progress-graph">
                            <Progress
                                percent={row.current.drivingDurationRemainingPercentage}
                                showInfo={false}
                                strokeWidth={11}
                                status={
                                    row.current.drivingDurationRemainingPercentage <= 10
                                        ? 'exception'
                                        : row.current.drivingDurationRemainingPercentage <= 40
                                        ? 'normal'
                                        : 'success'
                                }
                            />
                        </div>
                        <div className="aetr-progress-body">
                            <span data-qa={qa.aetr.table.fieldCurrentDayDriveTime}>
                                {duration(row.current.drivingDuration, 'seconds').format('hh:mm', {
                                    trim: false
                                })}
                            </span>
                            <span
                                data-qa={qa.aetr.table.fieldCurrentDayTimeLeft}
                                className={cn(
                                    {
                                        't-text-success': row.current.drivingDuration < row.current.maxDailyRide * 0.6
                                    },
                                    {
                                        't-text-warning':
                                            row.current.drivingDuration >= row.current.maxDailyRide * 0.6 &&
                                            row.current.drivingDuration < row.current.maxDailyRide * 0.9
                                    },
                                    {
                                        't-text-danger': row.current.drivingDuration >= row.current.maxDailyRide * 0.9
                                    }
                                )}
                            >
                                {duration(row.current.drivingDurationRemaining, 'seconds').format('hh:mm', {
                                    trim: false
                                })}
                            </span>
                        </div>
                    </>
                )}
            </div>
        );
    }

    function currentWeekDriveLeftRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <div className="aetr-progress-graph">
                    <Progress
                        percent={row.current.weeklyDrivingDurationPercentage}
                        showInfo={false}
                        strokeWidth={11}
                        status={
                            row.current.weeklyDrivingDurationPercentage <= 10
                                ? 'exception'
                                : row.current.weeklyDrivingDurationPercentage <= 40
                                ? 'normal'
                                : 'success'
                        }
                    />
                </div>

                <div className="aetr-progress-body">
                    <span data-qa={qa.aetr.table.fieldWeekDriveTime}>
                        {duration(row.current.weeklyDrivingDuration, 'seconds').format('hh:mm', {
                            trim: false
                        })}
                    </span>
                    <span
                        data-qa={qa.aetr.table.fieldWeekTimeLeft}
                        className={cn(
                            'pointer',
                            {
                                't-text-success':
                                    row.current.weeklyDrivingDurationRemaining > row.current.maxWeeklyRide * 0.4
                            },
                            {
                                't-text-warning':
                                    row.current.weeklyDrivingDurationRemaining >= row.current.maxWeeklyRide * 0.1 &&
                                    row.current.weeklyDrivingDurationRemaining <= row.current.maxWeeklyRide * 0.4
                            },
                            {
                                't-text-danger':
                                    row.current.weeklyDrivingDurationRemaining < row.current.maxWeeklyRide * 0.1
                            }
                        )}
                    >
                        {duration(row.current.weeklyDrivingDurationRemaining, 'seconds').format('hh:mm', {
                            trim: false
                        })}
                    </span>
                </div>
            </div>
        );
    }

    function column14daysDriveRow(row: AetrModel, index: number, history: boolean = true) {
        const biweeklyMaxRide =
            Math.max(row.current.lastWeekDrivingDuration, 0) <= MIN_WEEKLY_RIDE
                ? MAX_WEEKLY_RIDE + Math.max(row.current.lastWeekDrivingDuration, 0)
                : MAX_BIWEEKLY_RIDE;
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <AlarmDuration
                    duration={row.current.biweeklyDrivingDuration}
                    overlapMax
                    max={biweeklyMaxRide}
                    qa={qa.aetr.table.field14daysDrive}
                />
            </div>
        );
    }

    function previousWeekRestLengthRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
                data-qa={qa.aetr.table.fieldPreviousWeekRest}
            >
                {duration(row.evaluation.lastDailyRestDuration, 'seconds').format('hh:mm', {
                    trim: false
                })}
            </div>
        );
    }

    function currentWeekDrivenRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <div className="main-row">
                    <div
                        data-qa={qa.aetr.table.fieldWeekDaysDriven}
                        className={cn(
                            {
                                't-text-success': row.evaluation.shifts <= 2
                            },
                            {
                                't-text-warning': row.evaluation.shifts >= 3 && row.evaluation.shifts <= 4
                            },
                            {
                                't-text-danger': row.evaluation.shifts > 4
                            }
                        )}
                    >
                        {`${row.evaluation.shifts} `}
                    </div>
                    {` (${t('AetrTable.max')} 6)`}
                </div>
            </div>
        );
    }

    function currentWeekExtendedDrive10hRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <div className="main-row">
                    <div
                        data-qa={qa.aetr.table.fieldWeekExtendedDrive}
                        className={cn(
                            {
                                't-text-success': row.evaluation.extendedDailyShifts === 0
                            },
                            {
                                't-text-warning': row.evaluation.extendedDailyShifts === 1
                            },
                            {
                                't-text-danger': row.evaluation.extendedDailyShifts >= 2
                            }
                        )}
                    >
                        {`${row.evaluation.extendedDailyShifts} `}
                    </div>
                    {` (${t('AetrTable.max')} 2)`}
                </div>
            </div>
        );
    }

    function currentWeekReducedDailyRestRow(row: AetrModel, index: number, history: boolean = true) {
        return (
            <div
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <div className="main-row">
                    <div
                        data-qa={qa.aetr.table.fieldWeekReducedDailyRest}
                        className={cn(
                            {
                                't-text-success': row.evaluation.reducedDailyRests <= 1
                            },
                            {
                                't-text-warning': row.evaluation.reducedDailyRests === 2
                            },
                            {
                                't-text-danger': row.evaluation.reducedDailyRests >= 3
                            }
                        )}
                        aria-hidden="true"
                    >
                        {`${row.evaluation.reducedDailyRests} `}
                    </div>
                    {` (${t('AetrTable.max')} 3)`}
                </div>
            </div>
        );
    }

    function nextWeekRestLengthRow(row: AetrModel, index: number, history: boolean = true) {
        const nextWeeklyRestDurationWithoutDebt = Math.max(
            row.evaluation.nextWeeklyRestDuration - row.evaluation.nextWeeklyRestDurationDebt,
            0
        );
        return (
            <div
                data-qa={qa.aetr.table.fieldNextWeekRest}
                key={index}
                className={cn({
                    present: !history,
                    history: history
                })}
            >
                <Tooltip
                    qa={qa.aetr.table.nextWeeklyRestDuration}
                    overlay={`${duration(nextWeeklyRestDurationWithoutDebt, 'seconds').format('hh:mm', {
                        trim: false
                    })} + ${duration(row.evaluation.nextWeeklyRestDurationDebt, 'seconds').format('hh:mm', {
                        trim: false
                    })} ( ${t('AetrTable.rest')} + ${t('AetrTable.debt')} )`}
                    children={duration(row.evaluation.nextWeeklyRestDuration, 'seconds').format('hh:mm', {
                        trim: false
                    })}
                />
            </div>
        );
    }
}

export default withTranslation()(AetrTable);
