import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import numeral from 'numeral';
import { InputField, SelectField } from 'common/fields';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { ReadOnlyDiemsRates } from 'generated/new-main';
import { formatIntervalsToHours } from 'common/utils/textUtils';
import FormActions from 'common/components/FormActions';
import { DATE_FORMAT } from 'domain-constants';
import moment from 'moment';
import { Rate } from 'generated/backend-api';

export interface ManualRatesFormModel {
    id?: string;
    date: string;
    manualRateDomesticId?: string;
    manualRateForeignId?: string;
    user: number;
    dayFrom: string;
    dayTo: string;
}

interface Props extends WithTranslation {
    initialValues?: Partial<ManualRatesFormModel>;
    current?: {
        domestic?: Rate;
        foreign?: Rate;
    };
    diemsRates?: ReadOnlyDiemsRates[];
    diemsRatesLoading?: boolean;
    demoMode: boolean;
    onSubmit?: (values: ManualRatesFormModel) => void;
    onCancel?: () => void;
}

function ManualRatesForm(props: Props) {
    const schema = Yup.object().shape({
        manualRateDomesticId: Yup.string(),
        manualRateForeignId: Yup.string()
    });

    function handleSubmit(values: ManualRatesFormModel) {
        props.onSubmit?.(values);
    }

    const domesticOptions = props.diemsRates?.filter(
        diemsRate => diemsRate.country?.iso2 === 'SK' && diemsRate.foreignCountry?.iso2 === 'SK'
    );
    const foreignOptions = props.diemsRates?.filter(
        diemsRate => diemsRate.country?.iso2 === 'SK' && diemsRate.foreignCountry?.iso2 !== 'SK'
    );

    const domesticOptionSelected = domesticOptions?.find(
        diemsRate =>
            diemsRate.rate === props.current?.domestic?.rate &&
            diemsRate.currency?.code === props.current?.domestic?.currency
    )?.id;
    const foreignOptionSelected = foreignOptions?.find(
        diemsRate =>
            diemsRate.rate === props.current?.foreign?.rate &&
            diemsRate.currency?.code === props.current?.foreign?.currency
    )?.id;

    return (
        <Formik<ManualRatesFormModel>
            initialValues={{
                id: props.initialValues?.id,
                date: props.initialValues?.date ?? '',
                manualRateDomesticId: domesticOptionSelected ?? '',
                manualRateForeignId: foreignOptionSelected ?? '',
                user: props.initialValues?.user ?? 0,
                dayFrom: props.initialValues?.dayFrom ?? '',
                dayTo: props.initialValues?.dayTo ?? ''
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<ManualRatesFormModel>) => {
                return (
                    <div className="manual-rates-form">
                        <Row className="form-row">
                            <Col span="24">
                                <InputField
                                    name="dayFrom"
                                    readOnly={true}
                                    size="large"
                                    label={props.t('common.date')}
                                    value={moment(formik.values.dayFrom).format(DATE_FORMAT)}
                                />
                            </Col>
                        </Row>
                        {domesticOptions && domesticOptions?.length > 0 && (
                            <Row className="form-row">
                                <Col span="24">
                                    <SelectField
                                        name="manualRateDomesticId"
                                        size="large"
                                        label={props.t('Allowance.manualRateForm.domesticRate')}
                                        defaultValue={domesticOptionSelected ?? 'default'}
                                        onChange={val => {
                                            console.log('onChange', val);
                                        }}
                                        options={[
                                            {
                                                value: 'default',
                                                label: props.t('Allowance.manualRateForm.defaultRate')
                                            },
                                            ...(domesticOptions?.map(diemsRate => ({
                                                value: diemsRate.id ?? '',
                                                label: `${numeral(diemsRate.rate).format('0,0.00')} ${
                                                    diemsRate.currency?.code
                                                } (${formatIntervalsToHours(
                                                    diemsRate.intervalFrom,
                                                    diemsRate.intervalTo,
                                                    '–'
                                                )})`
                                            })) ?? [])
                                        ]}
                                    />
                                </Col>
                            </Row>
                        )}

                        {foreignOptions && foreignOptions?.length > 0 && (
                            <Row className="form-row">
                                <Col span="24">
                                    <SelectField
                                        name="manualRateForeignId"
                                        size="large"
                                        label={props.t('Allowance.manualRateForm.abroadRate')}
                                        defaultValue={foreignOptionSelected ?? 'default'}
                                        options={[
                                            {
                                                value: 'default',
                                                label: props.t('Allowance.manualRateForm.defaultRate')
                                            },
                                            ...(foreignOptions?.map(diemsRate => ({
                                                value: diemsRate.id ?? '',
                                                label: `${numeral(diemsRate.rate).format('0,0.00')} ${
                                                    diemsRate.currency?.code
                                                } (${formatIntervalsToHours(
                                                    diemsRate.intervalFrom,
                                                    diemsRate.intervalTo,
                                                    '–'
                                                )})`
                                            })) ?? [])
                                        ]}
                                    />
                                </Col>
                            </Row>
                        )}
                        <FormActions
                            submitDisabled={props.demoMode || props.diemsRatesLoading}
                            submitLoading={formik.isSubmitting}
                            submitText={props.t('common.confirm')}
                            cancelQa={qa.roles.detail.btnFormCancel}
                            submitQa={qa.roles.detail.btnFormSubmit}
                            onCancelClick={props.onCancel}
                            onSubmitClick={formik.submitForm}
                        />
                    </div>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(ManualRatesForm);
