import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';

import qa from 'qa-selectors';
import ScoreCard from 'common/components/ScoreCard';

interface Props {
    data?: DriverBehaviorLightVehicleModel;
}

export default function DriverScoreLightStats({ data }: Props) {
    const { t } = useTranslation();
    const max =
        data &&
        Object.keys(data.distance)
            .map(key => ({
                label: key,
                value: data.distance[key]
            }))
            .find(d => d.value === Math.max(data.distance.city, data.distance.highway, data.distance.noCity));
    const sum = data && data.distance.city + data.distance.highway + data.distance.noCity;

    return (
        <div className="driver-score-stats">
            <Row justify="space-between" gutter={16}>
                <Col flex={1}>
                    <ScoreCard
                        color="lightblue"
                        value={data?.score ?? 0}
                        label={t('DriverScoreStats.title')}
                        size={160}
                        important={true}
                        qa={qa.driverBehaviour.light.detail.fieldScore}
                    />
                </Col>
                <Col flex={1}>
                    <ScoreCard
                        label={t('DriverBehaviorLightTable.cols.economyScore')}
                        value={data?.harshEventsScore ?? 0}
                        size={160}
                        color="blue"
                        qa={qa.driverBehaviour.light.detail.fieldEconomy}
                    />
                </Col>
                <Col flex={1}>
                    <ScoreCard
                        value={data?.ecologyScore ?? 0}
                        label={t('DriverScoreStats.ecologyScore')}
                        size={160}
                        color="green"
                        qa={qa.driverBehaviour.light.detail.fieldEcology}
                    />
                </Col>
                <Col flex={1}>
                    <ScoreCard
                        color="yellow"
                        value={data?.safetyScore ?? 0}
                        label={t('DriverScoreStats.safetyScore')}
                        size={160}
                        qa={qa.driverBehaviour.light.detail.fieldSafety}
                    />
                </Col>
                {max && max.value !== 0 && sum && (
                    <Col flex={1}>
                        <ScoreCard
                            value={(max.value / sum) * 100}
                            label={t('DriverBehaviorDistanceChart.title')}
                            size={160}
                            color="purple"
                            percent={true}
                            qa={qa.driverBehaviour.light.detail.distance}
                            description={
                                <span className="score-card-distance">
                                    {t(`DriverBehaviorDistanceChart.${max.label}`)}
                                </span>
                            }
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
}
