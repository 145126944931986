/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Length,
    LengthFromJSON,
    LengthFromJSONTyped,
    LengthToJSON,
    LoadType,
    LoadTypeFromJSON,
    LoadTypeFromJSONTyped,
    LoadTypeToJSON,
    Volume,
    VolumeFromJSON,
    VolumeFromJSONTyped,
    VolumeToJSON,
    Weight,
    WeightFromJSON,
    WeightFromJSONTyped,
    WeightToJSON,
} from './';

/**
 * 
 * @export
 * @interface Load
 */
export interface Load {
    /**
     * 
     * @type {LoadType}
     * @memberof Load
     */
    loadType: LoadType;
    /**
     * 
     * @type {Weight}
     * @memberof Load
     */
    weight: Weight;
    /**
     * 
     * @type {number}
     * @memberof Load
     */
    count?: number;
    /**
     * 
     * @type {Length}
     * @memberof Load
     */
    length?: Length;
    /**
     * 
     * @type {Length}
     * @memberof Load
     */
    height?: Length;
    /**
     * 
     * @type {Length}
     * @memberof Load
     */
    width?: Length;
    /**
     * 
     * @type {Volume}
     * @memberof Load
     */
    volumeFluids?: Volume;
    /**
     * 
     * @type {string}
     * @memberof Load
     */
    description?: string;
}

export function LoadFromJSON(json: any): Load {
    return LoadFromJSONTyped(json, false);
}

export function LoadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Load {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loadType': LoadTypeFromJSON(json['loadType']),
        'weight': WeightFromJSON(json['weight']),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'length': !exists(json, 'length') ? undefined : LengthFromJSON(json['length']),
        'height': !exists(json, 'height') ? undefined : LengthFromJSON(json['height']),
        'width': !exists(json, 'width') ? undefined : LengthFromJSON(json['width']),
        'volumeFluids': !exists(json, 'volumeFluids') ? undefined : VolumeFromJSON(json['volumeFluids']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function LoadToJSON(value?: Load | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loadType': LoadTypeToJSON(value.loadType),
        'weight': WeightToJSON(value.weight),
        'count': value.count,
        'length': LengthToJSON(value.length),
        'height': LengthToJSON(value.height),
        'width': LengthToJSON(value.width),
        'volumeFluids': VolumeToJSON(value.volumeFluids),
        'description': value.description,
    };
}


