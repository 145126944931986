import { Line, G2 } from '@ant-design/charts';
import { LineConfig } from '@ant-design/plots';
import numeral from 'numeral';
import { ThemeChartType, Themes, getChartTheme } from '../ChartTimeline';

export interface ChartCompareModel {
    xIndex: number;
    xVal: string;
    yVal?: number;
    yLine: 'main' | 'compared';
}

interface Props {
    data: ChartCompareModel[];
    theme?: Themes;
    color?: string;
    yAxisStep?: number;
    height: number;
    width?: number;
    padding?: number;
    lineWidth: number;
}

export default function ChartCompare({ color = '#00E1FF', theme, yAxisStep = 10, ...props }: Props) {
    const maxVal = Math.round(Math.max(...props.data.map(d => d.yVal ?? 0)) * 1.1);
    const minVal = Math.round(Math.min(...props.data.map(d => d.yVal ?? 100)) * 0.8);

    const chartTheme = getChartTheme(theme, ThemeChartType.Trend);

    G2.registerShape('point', 'infoblock', {
        draw(cfg, container) {
            const current = cfg.data;
            const cx: number = typeof cfg.x === 'number' ? cfg.x : 0;
            const cy: number = typeof cfg.y === 'number' ? cfg.y : 0;
            const yVal = current?.['yVal'];
            const shapeRect: any = container.addGroup();

            shapeRect.addShape('rect', {
                zIndex: 2,
                attrs: {
                    width: 36,
                    height: 17,
                    x: cx - 18,
                    y: cy - 14,
                    fill: chartTheme.point?.backgroundColor,
                    radius: 4,
                    opacity: 0
                }
            });
            shapeRect.addShape('text', {
                zIndex: 3,
                attrs: {
                    x: cx,
                    y: cy + 2,
                    fill: chartTheme.point?.infoblock?.stroke,
                    text: numeral(yVal).format('0,0.0'),
                    fontSize: 13,
                    textAlign: 'center',
                    opacity: 0
                }
            });
            return shapeRect;
        }
    });

    const config: LineConfig = {
        data: props.data,
        xField: 'xVal',
        yField: 'yVal',
        seriesField: 'yLine',
        legend: false,
        width: props.width,
        height: props.height,
        animation: false,
        lineStyle: ({ yLine }) =>
            yLine === 'compared'
                ? {
                      lineDash: [2, 4],
                      lineWidth: 2
                  }
                : {
                      lineWidth: 2
                  },
        color,
        interactions: [
            {
                type: 'marker-active'
            }
        ],
        point: {
            shape: 'infoblock',
            state: {
                active: {
                    style: {
                        opacity: 1,
                        stroke: chartTheme.point?.backgroundColor
                    }
                }
            }
        },
        yAxis: {
            max: maxVal,
            min: minVal,
            grid: {
                line: {
                    style: {
                        lineWidth: 0
                    }
                }
            },
            line: {
                style: {
                    stroke: chartTheme.yAxis?.line?.stroke,
                    lineWidth: 1
                }
            },
            label: {
                style: {
                    fill: chartTheme.yAxis?.label?.fill
                }
            },
            tickCount: yAxisStep
        },
        xAxis: {
            line: {
                style: {
                    stroke: chartTheme.xAxis?.line?.stroke
                }
            },
            label: {
                style: {
                    fill: chartTheme.xAxis?.label?.fill
                }
            }
        },
        limitInPlot: true,
        smooth: true,
        tooltip: {
            showContent: false,
            showMarkers: false,
            showCrosshairs: true,
            crosshairs: {
                line: {
                    style: {
                        lineDash: [2, 4]
                    }
                }
            }
        }
    };

    return (
        <div className="chart-compare" style={{ width: props.width }}>
            <Line {...config} />
        </div>
    );
}
