/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaintenanceTask,
    MaintenanceTaskFromJSON,
    MaintenanceTaskFromJSONTyped,
    MaintenanceTaskToJSON,
} from './';

/**
 * 
 * @export
 * @interface MaintenanceNotificationEvent
 */
export interface MaintenanceNotificationEvent {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceNotificationEvent
     */
    eventType: string;
    /**
     * 
     * @type {MaintenanceTask}
     * @memberof MaintenanceNotificationEvent
     */
    maintenanceTaskWas: MaintenanceTask;
    /**
     * 
     * @type {MaintenanceTask}
     * @memberof MaintenanceNotificationEvent
     */
    maintenanceTaskNew: MaintenanceTask;
}

export function MaintenanceNotificationEventFromJSON(json: any): MaintenanceNotificationEvent {
    return MaintenanceNotificationEventFromJSONTyped(json, false);
}

export function MaintenanceNotificationEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceNotificationEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventType': json['event_type'],
        'maintenanceTaskWas': MaintenanceTaskFromJSON(json['maintenance_task_was']),
        'maintenanceTaskNew': MaintenanceTaskFromJSON(json['maintenance_task_new']),
    };
}

export function MaintenanceNotificationEventToJSON(value?: MaintenanceNotificationEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event_type': value.eventType,
        'maintenance_task_was': MaintenanceTaskToJSON(value.maintenanceTaskWas),
        'maintenance_task_new': MaintenanceTaskToJSON(value.maintenanceTaskNew),
    };
}


