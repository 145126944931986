import React, { MouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteOverviewTableData } from '../RouteOverviewModule';
import cn from 'classnames';
import moment from 'moment';
import { Menu } from 'antd';
import { Loading } from 'common/components/Loading';
import { Button, Dropdown, Tooltip } from 'common/components';
import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { Role } from 'logic/auth';
import { MonitoredObjectFleetType, TransportState } from 'generated/backend-api';
import qa from 'qa-selectors';
import { NoData } from 'common/components/NoData';
import TableHeadLabel from 'common/components/TableHeadLabel';
import { TransportModel } from 'common/model/transports';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { VehicleStateObject } from 'generated/graphql';
import { trailersVehicleStateObjectIds } from 'common/utils/vehicleState';
import * as icons from 'resources/images/common';
import { ExternalDeviceType } from 'generated/backend-api-live';

interface Props extends WithTranslation {
    data?: RouteOverviewTableData;
    selectedId?: string;
    loading: boolean;
    processing: boolean;
    demoMode?: boolean;
    roles: Role[];
    vehiclesSelectedTransportId?: string;
    monitoredObjects?: ReadOnlyMonitoredObjectFeSb[];
    vehicleStates?: VehicleStateObject[];
    onRowClick?: (id: string, vehicleId: string) => void;
    onClickRowExpand?: (key: keyof RouteOverviewTableData) => void;
    onAddVehicleClick?: (transportId: string) => void;
    onRemoveVehicleClick?: (transportId: string, vehicleId?: string) => void;
    onSetActiveClick?: (transportId: string) => void;
    onSetCompleteClick?: (transportId: string) => void;
    onRemoveTransportClick?: (transportId: string) => void;
    onStazkaExportClick?: (transport: TransportModel) => void;
    onSeeInJourneys?: (transportId: string) => void;
    onTrackOnMapClick?: (rn: string) => void;
}

type TableHead = [[string, number][], string[]];

const orderSection: Array<keyof RouteOverviewTableData> = [
    'routesNew',
    'routesDelayed',
    'routesActive',
    'routesFinished'
];

const textColors = {
    routesNew: 't-text-light-blue',
    routesDelayed: 't-text-primary',
    routesActive: 't-text-success',
    routesFinished: 't-text-light-secondary'
};

function RouteOverviewTable({ t, data, loading, processing, selectedId, ...props }: Props) {
    const head: TableHead = [
        [
            // [Name, Colspan]
            [t('common.route'), 1],
            [t('common.start'), 2],
            [t('common.finish'), 2],
            [t('common.status'), 1],
            [t('RouteOverviewTable.vehicleRN'), 1],
            [t('RouteOverviewTable.trailerRN'), 1],
            [t('common.driver'), 1],
            [t('shorthand.RTA'), 1],
            [t('shorthand.ETA'), 1],
            ['', 1]
        ],
        [
            t('common.name'),
            t('RouteOverviewTable.dateAndTime'),
            t('RouteOverviewTable.firstPointOnTheRoute'),
            t('RouteOverviewTable.dateAndTime'),
            t('RouteOverviewTable.lastPointOnTheRoute'),
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        ]
    ];
    const borders = head[0].reduce(
        (a, v) => {
            a.v += v[1];
            a.o.push(a.v);
            return a;
        },
        { v: 0, o: [] as number[] }
    ).o;

    const rowColSpan = head[0].map(col => col[1]).reduce((total, actual) => total + actual);

    function onClickRowExpand(key: keyof RouteOverviewTableData): void {
        props.onClickRowExpand?.(key);
    }

    function onClickRow(id: string, vehicleId: string, status: TransportState): void {
        if (status !== TransportState.Finished) {
            props.onRowClick?.(id, vehicleId);
        }
    }

    function onAddVehicle(id?: string) {
        return (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            id && props.onAddVehicleClick?.(id);
        };
    }

    function onRemoveVehicle(transportId: string, vehicleId?: string) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onRemoveVehicleClick?.(transportId, vehicleId);
        };
    }

    function onSetActiveClick(id?: string) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            id && props.onSetActiveClick?.(id);
        };
    }

    function onSetCompleteClick(id?: string) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            id && props.onSetCompleteClick?.(id);
        };
    }

    function onRemoveTransportClick(id?: string) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            id && props.onRemoveTransportClick?.(id);
        };
    }
    function onStazkaExportClick(row: TransportModel) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onStazkaExportClick?.(row);
        };
    }

    function onTrackOnMapClick(vehicleId: string) {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onTrackOnMapClick?.(vehicleId);
        };
    }

    return (
        <div className="route-overview-table">
            {(loading || processing) && (
                <div
                    className={cn([
                        { 'route-overview-table-loading': loading, 'route-overview-table-processing': processing }
                    ])}
                >
                    <Loading />
                </div>
            )}
            <table className="t-table t-table-all t-small t-hoverable t-bordered">
                <thead>
                    <tr>
                        {head[0].map((col, i) => (
                            <th
                                key={i}
                                className={cn(['t-border-primary', { 't-border-right': head[0].length - 1 > i }])}
                                colSpan={col[1]}
                            >
                                <TableHeadLabel title={col[0]} />
                            </th>
                        ))}
                        <th />
                    </tr>
                    <tr>
                        {head[1].map((col, i) => (
                            <th
                                key={i}
                                className={cn({
                                    't-border-primary': borders.includes(i + 1),
                                    't-border-secondary': !borders.includes(i + 1),
                                    't-border-right': head[1].length - 1 > i
                                })}
                            >
                                <TableHeadLabel level="normal" title={col} />
                            </th>
                        ))}
                        <th />
                    </tr>
                </thead>
                {data ? (
                    orderSection.map(key => {
                        const routeOverview = data[key];
                        return (
                            <tbody key={key} data-qa={qa.routeOverview.table[key]}>
                                <tr
                                    key="colapsable"
                                    className="t-dark-secondary t-hover-dark-secondary"
                                    onClick={onClickRowExpand.bind(undefined, key)}
                                >
                                    <td className="t-left-align t-uppercase" colSpan={rowColSpan}>
                                        <i
                                            className={cn(
                                                'fa',
                                                'fa-fw',
                                                routeOverview ? 'fa-chevron-down' : 'fa-chevron-right'
                                            )}
                                            aria-hidden="true"
                                            data-qa={qa.routeOverview.table.btn[key]}
                                        />{' '}
                                        {t(`RouteOverviewTable.${key}`)}
                                    </td>
                                    <td />
                                </tr>
                                {routeOverview && (
                                    <>
                                        {routeOverview.length > 0 ? (
                                            routeOverview.map(row => {
                                                const plannedTrailers = row?.monitoredObjects?.filter(
                                                    monitoredObject =>
                                                        !monitoredObject.endTime &&
                                                        monitoredObject.type === MonitoredObjectFleetType.Trailer
                                                );

                                                const realTrailers: string[] =
                                                    trailersVehicleStateObjectIds(
                                                        [
                                                            ExternalDeviceType.TrailerId,
                                                            ExternalDeviceType.TrailerManual
                                                        ],
                                                        props.vehicleStates?.find(
                                                            vehicleState =>
                                                                vehicleState.monitoredObjectId === row.vehicle
                                                        )
                                                    ) ?? [];

                                                return (
                                                    <tr
                                                        key={row.id}
                                                        className={cn(textColors[key], `${row.state}-route-row`, {
                                                            't-primary': selectedId === row.id,
                                                            pointer: row.state !== TransportState.Finished
                                                        })}
                                                    >
                                                        <td
                                                            className="t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldName}
                                                        >
                                                            <div
                                                                className="route-overview-name-col t-nowrap-with-elipses"
                                                                title={row.name}
                                                            >
                                                                {row.name}
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="t-border-secondary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldStartDate}
                                                        >
                                                            {moment.utc(row.places?.[0]?.rta ?? '').format('L LT')}
                                                        </td>
                                                        <td
                                                            className="t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                        >
                                                            <AddressFormatter
                                                                className="route-overview-place-col t-nowrap-with-elipses"
                                                                renderAddressTitle
                                                                defaultAddress={row.places?.[0]?.name ?? ''}
                                                                addressStructured={row.places?.[0]?.addressStructured}
                                                                qa={qa.routeOverview.table.fieldStartLocation}
                                                            />
                                                        </td>
                                                        <td
                                                            className="t-border-secondary t-border-right"
                                                            data-qa={qa.routeOverview.table.fieldEndDate}
                                                        >
                                                            {moment
                                                                .utc(row.places?.[row.places?.length - 1]?.rta ?? '')
                                                                .format('L LT')}
                                                        </td>
                                                        <td
                                                            className="t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                        >
                                                            <div
                                                                className="route-overview-place-col t-nowrap-with-elipses"
                                                                title={row.places?.[row.places.length - 1]?.name}
                                                            >
                                                                <AddressFormatter
                                                                    className="route-overview-place-col t-nowrap-with-elipses"
                                                                    renderAddressTitle
                                                                    defaultAddress={
                                                                        row.places?.[row.places.length - 1].name ?? ''
                                                                    }
                                                                    addressStructured={
                                                                        row.places?.[row.places.length - 1]
                                                                            .addressStructured
                                                                    }
                                                                    qa={qa.routeOverview.table.fieldEndLocation}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td
                                                            className={cn([
                                                                't-center t-border-primary t-border-right',
                                                                `status-${row.state}`
                                                            ])}
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldStatus}
                                                        >
                                                            {t(`RouteOverviewTable.${row.state}`)}
                                                        </td>
                                                        <td
                                                            className="t-center t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                        >
                                                            {row.vehicle ? (
                                                                <span data-qa={qa.routeOverview.table.fieldVehicle}>
                                                                    {props.monitoredObjects?.find(
                                                                        mobject => String(mobject.id) === row.vehicle
                                                                    )?.registrationNumber ?? row.vehicle}
                                                                </span>
                                                            ) : key === 'routesNew' ? (
                                                                <Button
                                                                    size="small"
                                                                    type="ghost"
                                                                    disabled={
                                                                        props.demoMode ||
                                                                        props.vehiclesSelectedTransportId !== undefined
                                                                    }
                                                                    onClick={onAddVehicle(row.id)}
                                                                    qa={qa.routeOverview.table.btnAddVehicle}
                                                                >
                                                                    {t('RouteOverviewTable.addVehicle')}
                                                                </Button>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </td>
                                                        <td
                                                            className="t-center t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                        >
                                                            <span className="route-overview-trailer">
                                                                {key === 'routesFinished' && row.lastTrailer ? (
                                                                    <span data-qa={qa.routeOverview.table.fieldTrailer}>
                                                                        {props.monitoredObjects?.find(
                                                                            mobject =>
                                                                                mobject?.id ===
                                                                                row.lastTrailer?.monitoredObjectId
                                                                        )?.registrationNumber ??
                                                                            row.lastTrailer?.monitoredObjectId}
                                                                    </span>
                                                                ) : plannedTrailers && plannedTrailers?.length > 0 ? (
                                                                    <span data-qa={qa.routeOverview.table.fieldTrailer}>
                                                                        {plannedTrailers
                                                                            .map(
                                                                                plannedTrailer =>
                                                                                    props.monitoredObjects?.find(
                                                                                        mobject =>
                                                                                            mobject?.id ===
                                                                                            plannedTrailer.monitoredObjectId
                                                                                    )?.registrationNumber ??
                                                                                    plannedTrailer.monitoredObjectId
                                                                            )
                                                                            .toString()}
                                                                    </span>
                                                                ) : key === 'routesNew' ? (
                                                                    <Button
                                                                        size="small"
                                                                        type="ghost"
                                                                        disabled={
                                                                            props.demoMode ||
                                                                            props.vehiclesSelectedTransportId !==
                                                                                undefined
                                                                        }
                                                                        onClick={onClickRow.bind(
                                                                            undefined,
                                                                            row.id ?? '',
                                                                            row.vehicle ?? '',
                                                                            row.state
                                                                        )}
                                                                        qa={qa.routeOverview.table.btnAddTrailer}
                                                                    >
                                                                        {t('RouteOverviewTable.addTrailer')}
                                                                    </Button>
                                                                ) : (
                                                                    '-'
                                                                )}{' '}
                                                                {[
                                                                    'routesNew',
                                                                    'routesDelayed',
                                                                    'routesActive'
                                                                ].includes(key) &&
                                                                realTrailers &&
                                                                plannedTrailers &&
                                                                plannedTrailers?.length > 0 &&
                                                                realTrailers.toString() !==
                                                                    plannedTrailers
                                                                        ?.map(
                                                                            plannedTrailer =>
                                                                                plannedTrailer.monitoredObjectId
                                                                        )
                                                                        .toString() ? (
                                                                    <Tooltip
                                                                        data-qa={
                                                                            qa.routeOverview.table.fieldRealTrailer
                                                                        }
                                                                        className={'route-overview-actual-trailer'}
                                                                        title={`${t(
                                                                            'RouteOverviewTable.actualTrailer'
                                                                        )}: ${
                                                                            realTrailers
                                                                                .map(
                                                                                    realTrailer =>
                                                                                        props.monitoredObjects?.find(
                                                                                            mobject =>
                                                                                                String(mobject?.id) ===
                                                                                                String(realTrailer)
                                                                                        )?.registrationNumber
                                                                                )
                                                                                .toString()
                                                                                .replaceAll(',', '') !== ''
                                                                                ? realTrailers
                                                                                      .map(
                                                                                          realTrailer =>
                                                                                              props.monitoredObjects?.find(
                                                                                                  mobject =>
                                                                                                      String(
                                                                                                          mobject?.id
                                                                                                      ) ===
                                                                                                      String(
                                                                                                          realTrailer
                                                                                                      )
                                                                                              )?.registrationNumber
                                                                                      )
                                                                                      .toString()
                                                                                : t('RouteOverviewTable.notPaired')
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            src={icons.warning}
                                                                            alt="actual-trailer"
                                                                            data-qa={
                                                                                qa.routeOverview.table.fieldRealTrailer
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td
                                                            className="t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldDriver}
                                                        >
                                                            <div
                                                                className="route-overview-driver-col t-nowrap-with-elipses"
                                                                title={
                                                                    row.lastDriver
                                                                        ? (row.lastDriver?.name ?? '') +
                                                                          ' ' +
                                                                          (row.lastDriver?.surname ?? '')
                                                                        : ''
                                                                }
                                                            >
                                                                {row.lastDriver
                                                                    ? (row.lastDriver?.name ?? '') +
                                                                      ' ' +
                                                                      (row.lastDriver?.surname ?? '')
                                                                    : ''}
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="t-border-primary t-border-right"
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldRta}
                                                        >
                                                            {moment
                                                                .utc(row.places?.[row.places?.length - 1]?.rta)
                                                                .format('L LT')}
                                                        </td>
                                                        <td
                                                            onClick={onClickRow.bind(
                                                                undefined,
                                                                row.id ?? '',
                                                                row.vehicle ?? '',
                                                                row.state
                                                            )}
                                                            data-qa={qa.routeOverview.table.fieldEta}
                                                        >
                                                            {moment
                                                                .utc(row.places?.[row.places?.length - 1]?.eta)
                                                                .format('L LT')}
                                                        </td>
                                                        <td
                                                            style={{
                                                                overflow: 'initial'
                                                            }}
                                                        >
                                                            <Dropdown
                                                                overlay={
                                                                    <Menu>
                                                                        {[
                                                                            TransportState.New,
                                                                            TransportState.Accepted,
                                                                            TransportState.Planned
                                                                        ].includes(row.state) &&
                                                                            row.vehicle && (
                                                                                <Menu.Item key="track">
                                                                                    <div
                                                                                        className="actions-track-on-map uppercase"
                                                                                        onClick={onRemoveVehicle(
                                                                                            row.id ?? '',
                                                                                            row.vehicle
                                                                                        )}
                                                                                        data-qa={
                                                                                            qa.routeOverview.table
                                                                                                .btnRemoveVehicle
                                                                                        }
                                                                                    >
                                                                                        {t(
                                                                                            'RouteOverviewTable.removeVehicle'
                                                                                        )}
                                                                                    </div>
                                                                                </Menu.Item>
                                                                            )}
                                                                        {['routesActive'].includes(key) && row.vehicle && (
                                                                            <Menu.Item key="track">
                                                                                <div
                                                                                    className="actions-track-on-map uppercase"
                                                                                    onClick={onTrackOnMapClick(
                                                                                        row.vehicle ?? ''
                                                                                    )}
                                                                                    data-qa={
                                                                                        qa.routeOverview.table
                                                                                            .btnTrackVehicleOnMap
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        'RouteOverviewTable.trackVehicleOnMap'
                                                                                    )}
                                                                                </div>
                                                                            </Menu.Item>
                                                                        )}
                                                                        {['routesFinished'].includes(key) && (
                                                                            <Menu.Item
                                                                                key="setActive"
                                                                                disabled={props.demoMode}
                                                                            >
                                                                                <div
                                                                                    className="actions-set-as-active uppercase"
                                                                                    onClick={onSetActiveClick(row.id)}
                                                                                    data-qa={
                                                                                        qa.routeOverview.table
                                                                                            .btnSetAsActive
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        'RouteOverviewTable.setAsActive'
                                                                                    )}
                                                                                </div>
                                                                            </Menu.Item>
                                                                        )}
                                                                        {[
                                                                            'routesNew',
                                                                            'routesDelayed',
                                                                            'routesActive'
                                                                        ].includes(key) && (
                                                                            <Menu.Item
                                                                                key="complete"
                                                                                disabled={props.demoMode}
                                                                            >
                                                                                <div
                                                                                    className="actions-set-as-complete uppercase"
                                                                                    onClick={onSetCompleteClick(row.id)}
                                                                                    data-qa={
                                                                                        qa.routeOverview.table
                                                                                            .btnSetAsComplete
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        'RouteOverviewTable.setAsComplete'
                                                                                    )}
                                                                                </div>
                                                                            </Menu.Item>
                                                                        )}
                                                                        <Menu.Item
                                                                            key="remove"
                                                                            disabled={props.demoMode}
                                                                        >
                                                                            <div
                                                                                className="actions-remove uppercase"
                                                                                onClick={onRemoveTransportClick(row.id)}
                                                                                data-qa={
                                                                                    qa.routeOverview.table.btnRemove
                                                                                }
                                                                            >
                                                                                {t('RouteOverviewTable.remove')}
                                                                            </div>
                                                                        </Menu.Item>
                                                                        {props.roles.includes(Role.SR_E) &&
                                                                            row.state === TransportState.Finished && (
                                                                                <Menu.Item key="stazka-export">
                                                                                    <div
                                                                                        className="actions-remove uppercase"
                                                                                        onClick={onStazkaExportClick(
                                                                                            row
                                                                                        )}
                                                                                        data-qa={
                                                                                            qa.routeOverview.table
                                                                                                .btnStazkaExport
                                                                                        }
                                                                                    >
                                                                                        {t('common.stazkaExport')}
                                                                                    </div>
                                                                                </Menu.Item>
                                                                            )}
                                                                    </Menu>
                                                                }
                                                                placement="bottomRight"
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    type="primary"
                                                                    qa={qa.routeOverview.table.btnActions}
                                                                >
                                                                    {t('RouteOverviewTable.actions')}
                                                                </Button>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={head[1].length}>
                                                    <NoData />
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        );
                    })
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan={head[1].length}>
                                <NoData />
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default withTranslation()(RouteOverviewTable);
