/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectMetadataProfileMergedItemCharField,
    MonitoredObjectMetadataProfileMergedItemCharFieldFromJSON,
    MonitoredObjectMetadataProfileMergedItemCharFieldFromJSONTyped,
    MonitoredObjectMetadataProfileMergedItemCharFieldToJSON,
    MonitoredObjectMetadataProfileMergedItemFuelType,
    MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSON,
    MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSONTyped,
    MonitoredObjectMetadataProfileMergedItemFuelTypeToJSON,
    MonitoredObjectMetadataProfileMergedItemInteger,
    MonitoredObjectMetadataProfileMergedItemIntegerFromJSON,
    MonitoredObjectMetadataProfileMergedItemIntegerFromJSONTyped,
    MonitoredObjectMetadataProfileMergedItemIntegerToJSON,
    MonitoredObjectMetadataProfileMergedItemOptimalRpm,
    MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSON,
    MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSONTyped,
    MonitoredObjectMetadataProfileMergedItemOptimalRpmToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectProfileMerged
 */
export interface MonitoredObjectProfileMerged {
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemCharField}
     * @memberof MonitoredObjectProfileMerged
     */
    emissionClass: MonitoredObjectMetadataProfileMergedItemCharField;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    numberOfAxles: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    weight: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    weightFull: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    height: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    width: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    length: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemInteger}
     * @memberof MonitoredObjectProfileMerged
     */
    trailersCount: MonitoredObjectMetadataProfileMergedItemInteger;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemCharField}
     * @memberof MonitoredObjectProfileMerged
     */
    tunnel: MonitoredObjectMetadataProfileMergedItemCharField;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemCharField}
     * @memberof MonitoredObjectProfileMerged
     */
    vin: MonitoredObjectMetadataProfileMergedItemCharField;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemFuelType}
     * @memberof MonitoredObjectProfileMerged
     */
    fuelType: MonitoredObjectMetadataProfileMergedItemFuelType;
    /**
     * 
     * @type {MonitoredObjectMetadataProfileMergedItemOptimalRpm}
     * @memberof MonitoredObjectProfileMerged
     */
    optimalRpm: MonitoredObjectMetadataProfileMergedItemOptimalRpm;
}

export function MonitoredObjectProfileMergedFromJSON(json: any): MonitoredObjectProfileMerged {
    return MonitoredObjectProfileMergedFromJSONTyped(json, false);
}

export function MonitoredObjectProfileMergedFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectProfileMerged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emissionClass': MonitoredObjectMetadataProfileMergedItemCharFieldFromJSON(json['emissionClass']),
        'numberOfAxles': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['numberOfAxles']),
        'weight': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['weight']),
        'weightFull': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['weightFull']),
        'height': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['height']),
        'width': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['width']),
        'length': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['length']),
        'trailersCount': MonitoredObjectMetadataProfileMergedItemIntegerFromJSON(json['trailersCount']),
        'tunnel': MonitoredObjectMetadataProfileMergedItemCharFieldFromJSON(json['tunnel']),
        'vin': MonitoredObjectMetadataProfileMergedItemCharFieldFromJSON(json['vin']),
        'fuelType': MonitoredObjectMetadataProfileMergedItemFuelTypeFromJSON(json['fuelType']),
        'optimalRpm': MonitoredObjectMetadataProfileMergedItemOptimalRpmFromJSON(json['optimalRpm']),
    };
}

export function MonitoredObjectProfileMergedToJSON(value?: MonitoredObjectProfileMerged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emissionClass': MonitoredObjectMetadataProfileMergedItemCharFieldToJSON(value.emissionClass),
        'numberOfAxles': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.numberOfAxles),
        'weight': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.weight),
        'weightFull': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.weightFull),
        'height': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.height),
        'width': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.width),
        'length': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.length),
        'trailersCount': MonitoredObjectMetadataProfileMergedItemIntegerToJSON(value.trailersCount),
        'tunnel': MonitoredObjectMetadataProfileMergedItemCharFieldToJSON(value.tunnel),
        'vin': MonitoredObjectMetadataProfileMergedItemCharFieldToJSON(value.vin),
        'fuelType': MonitoredObjectMetadataProfileMergedItemFuelTypeToJSON(value.fuelType),
        'optimalRpm': MonitoredObjectMetadataProfileMergedItemOptimalRpmToJSON(value.optimalRpm),
    };
}


