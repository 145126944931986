/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PositionList,
    PositionListFromJSON,
    PositionListFromJSONTyped,
    PositionListToJSON,
} from './';

/**
 * 
 * @export
 * @interface DevicesPositionResponse
 */
export interface DevicesPositionResponse {
    /**
     * 
     * @type {Array<PositionList>}
     * @memberof DevicesPositionResponse
     */
    positionList: Array<PositionList>;
}

export function DevicesPositionResponseFromJSON(json: any): DevicesPositionResponse {
    return DevicesPositionResponseFromJSONTyped(json, false);
}

export function DevicesPositionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevicesPositionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionList': ((json['positionList'] as Array<any>).map(PositionListFromJSON)),
    };
}

export function DevicesPositionResponseToJSON(value?: DevicesPositionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positionList': ((value.positionList as Array<any>).map(PositionListToJSON)),
    };
}


