/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonObj
 */
export interface MonObj {
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    systemDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    manufacturerName?: string | null;
    /**
     * 
     * @type {object}
     * @memberof MonObj
     */
    _configuration?: object | null;
    /**
     * 
     * @type {object}
     * @memberof MonObj
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    status?: number | null;
    /**
     * 
     * @type {object}
     * @memberof MonObj
     */
    costPerKm?: object;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    tankSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    customLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    vehicleTrainMaxWeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    vehicleTractorMaxWeight?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MonObj
     */
    cng?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MonObj
     */
    disabledAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MonObj
     */
    forwardedToDff?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MonObj
     */
    lastSyncWithDff?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MonObj
     */
    dffSyncTraceback?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    monitoringDevice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    client: number;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    monitoredObjectType: number;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    driverApplication?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    externalSystem?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonObj
     */
    countryOfRegistration?: number | null;
}

export function MonObjFromJSON(json: any): MonObj {
    return MonObjFromJSONTyped(json, false);
}

export function MonObjFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonObj {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'registrationNumber': json['registration_number'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'systemDescription': !exists(json, 'system_description') ? undefined : json['system_description'],
        'manufacturerName': !exists(json, 'manufacturer_name') ? undefined : json['manufacturer_name'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'tankSize': !exists(json, 'tank_size') ? undefined : json['tank_size'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'vehicleTrainMaxWeight': !exists(json, 'vehicle_train_max_weight') ? undefined : json['vehicle_train_max_weight'],
        'vehicleTractorMaxWeight': !exists(json, 'vehicle_tractor_max_weight') ? undefined : json['vehicle_tractor_max_weight'],
        'cng': !exists(json, 'cng') ? undefined : json['cng'],
        'disabledAt': !exists(json, 'disabled_at') ? undefined : (json['disabled_at'] === null ? null : new Date(json['disabled_at'])),
        'forwardedToDff': !exists(json, 'forwarded_to_dff') ? undefined : json['forwarded_to_dff'],
        'lastSyncWithDff': !exists(json, 'last_sync_with_dff') ? undefined : (json['last_sync_with_dff'] === null ? null : new Date(json['last_sync_with_dff'])),
        'dffSyncTraceback': !exists(json, 'dff_sync_traceback') ? undefined : json['dff_sync_traceback'],
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : json['monitoring_device'],
        'client': json['client'],
        'monitoredObjectType': json['monitored_object_type'],
        'driverApplication': !exists(json, 'driver_application') ? undefined : json['driver_application'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
        'countryOfRegistration': !exists(json, 'country_of_registration') ? undefined : json['country_of_registration'],
    };
}

export function MonObjToJSON(value?: MonObj | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'external_id': value.externalId,
        'registration_number': value.registrationNumber,
        'description': value.description,
        'system_description': value.systemDescription,
        'manufacturer_name': value.manufacturerName,
        'configuration': value._configuration,
        'metadata': value.metadata,
        'note': value.note,
        'status': value.status,
        'cost_per_km': value.costPerKm,
        'tank_size': value.tankSize,
        'custom_label': value.customLabel,
        'vehicle_train_max_weight': value.vehicleTrainMaxWeight,
        'vehicle_tractor_max_weight': value.vehicleTractorMaxWeight,
        'cng': value.cng,
        'disabled_at': value.disabledAt === undefined ? undefined : (value.disabledAt === null ? null : value.disabledAt.toISOString()),
        'forwarded_to_dff': value.forwardedToDff,
        'last_sync_with_dff': value.lastSyncWithDff === undefined ? undefined : (value.lastSyncWithDff === null ? null : value.lastSyncWithDff.toISOString()),
        'dff_sync_traceback': value.dffSyncTraceback,
        'monitoring_device': value.monitoringDevice,
        'client': value.client,
        'monitored_object_type': value.monitoredObjectType,
        'driver_application': value.driverApplication,
        'external_system': value.externalSystem,
        'country_of_registration': value.countryOfRegistration,
    };
}


