/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackendApiApiV1DiemsAddress
 */
export interface BackendApiApiV1DiemsAddress {
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    streetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DiemsAddress
     */
    lang?: string;
}

export function BackendApiApiV1DiemsAddressFromJSON(json: any): BackendApiApiV1DiemsAddress {
    return BackendApiApiV1DiemsAddressFromJSONTyped(json, false);
}

export function BackendApiApiV1DiemsAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV1DiemsAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'streetAddress': !exists(json, 'street_address') ? undefined : json['street_address'],
        'lang': !exists(json, 'lang') ? undefined : json['lang'],
    };
}

export function BackendApiApiV1DiemsAddressToJSON(value?: BackendApiApiV1DiemsAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'postalCode': value.postalCode,
        'countryCode': value.countryCode,
        'country': value.country,
        'town': value.town,
        'route': value.route,
        'street_address': value.streetAddress,
        'lang': value.lang,
    };
}


