/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyTLMBundleSerializer
 */
export interface ReadOnlyTLMBundleSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyTLMBundleSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyTLMBundleSerializer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyTLMBundleSerializer
     */
    binaryRights: string;
}

export function ReadOnlyTLMBundleSerializerFromJSON(json: any): ReadOnlyTLMBundleSerializer {
    return ReadOnlyTLMBundleSerializerFromJSONTyped(json, false);
}

export function ReadOnlyTLMBundleSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyTLMBundleSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'binaryRights': json['binary_rights'],
    };
}

export function ReadOnlyTLMBundleSerializerToJSON(value?: ReadOnlyTLMBundleSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'binary_rights': value.binaryRights,
    };
}


