import React, { ReactNode } from 'react';
import { Tabs } from 'common/components';
import { Role } from 'logic/auth';

interface Props {
    active: string;
    tabs: { title: ReactNode; value: string; roles?: Role[] }[];
    onChange?: (value: string) => void;
}

export default function SystemConnectionsTabs(props: Props) {
    return (
        <div className="sys-connections-tabs">
            <Tabs defaultActiveKey={props.active} onChange={props.onChange}>
                {props.tabs.map(t => (
                    <Tabs.TabPane key={t.value} tab={t.title} />
                ))}
            </Tabs>
        </div>
    );
}
