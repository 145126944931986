import {
    monitoredObjectTypes,
    vehicles,
    vehiclesSimple,
    trailers,
    monitoredObject,
    pairedTrailers,
    monitoredObjectToMonitoredObject,
    fleetDynamicData
} from './vehicles';
import {
    temperatureSensors,
    externalDeviceState,
    temperatureSensorAlarmsData,
    coldChainProfileList,
    temperatureProfiles
} from './coldchain';
import { costs } from './costs';
import { vehicleStates, puescStatus } from './vehicles-states';
import { vehicleProfiles } from './vehicle-profiles';
import { transportTemplates } from './transport-templates';
import { fuelConsumptions } from './fuel-consumption';
import { journeysStatistics } from './journeys-statistics';
import { transports } from './transports';
import { trips } from './trips';
import { places } from './places';
import { expenses } from './expenses';
import { users, dispatchers, usersSimpleInfo } from './users';
import { aetr } from './aetr';
import { client } from './client';
import { contactList } from './contactlist';
import { customerApi } from './customerApi';
import { driverBehaviorTrucks, driverBehaviorTrendsTrucks, driverBehaviorVehicles } from './driver-behavior';
import { fuelCards, fuelCompanies } from './fuel-cards';
import { tachoCards } from './tacho-cards';
import { dtcosVehicles, dtcosCards, ccrCards } from './dtcos';
import { currencies } from './currencies';
import { borderCrossing } from './border-crossing';
import {
    maintenanceTasks,
    maintenanceTaskTypes,
    maintenanceTasksSummary,
    maintenanceTaskReceivers
} from './maintenance';
import { proposals, rawProposals, demoProposals, transEUMockInfo } from './dff-proposals';
import { maintenanceNotifications } from './maintenanceNotifications';
import { countries } from './countries';
import { diems, diemsRules, diemsPlacesOfWork, diemsManualPlaceOfWorkCrossing } from './diems';
import { defaultDrivers } from './default-drivers';
import { alarms } from './alarms';
import { pois } from './pois';
import { channels, messages } from './messaging';
import { journeyGraphData } from './journeyGraphData';
import { temperatureSensorData } from './coldchain-state';
import { perfectDriveContextualData, perfectDriveScoreData, perfectDriveReportData } from './perfect-drive';

export const data = {
    users,
    dispatchers,
    usersSimpleInfo,
    transports,
    trips,
    vehicles,
    vehiclesSimple,
    trailers,
    monitoredObject,
    monitoredObjectToMonitoredObject,
    pairedTrailers,
    costs,
    fuelConsumptions,
    journeysStatistics,
    driverBehaviorTrucks,
    driverBehaviorTrendsTrucks,
    driverBehaviorVehicles,
    vehicleStates,
    puescStatus,
    vehicleProfiles,
    places,
    expenses,
    aetr,
    client,
    customerApi,
    fuelCards,
    fuelCompanies,
    tachoCards,
    dtcosVehicles,
    dtcosCards,
    currencies,
    borderCrossing,
    contactList,
    maintenanceTasks,
    maintenanceTaskTypes,
    maintenanceTasksSummary,
    maintenanceTaskReceivers,
    proposals,
    rawProposals,
    demoProposals,
    maintenanceNotifications,
    countries,
    diems,
    diemsRules,
    diemsPlacesOfWork,
    diemsManualPlaceOfWorkCrossing,
    defaultDrivers,
    monitoredObjectTypes,
    transEUMockInfo,
    alarms,
    ccrCards,
    pois,
    messaging: {
        messages,
        channels
    },
    journeyGraphData,
    fleetDynamicData,
    temperatureSensors,
    externalDeviceState,
    temperatureSensorData,
    temperatureSensorAlarmsData,
    temperatureProfiles,
    coldChainProfileList,
    transportTemplates,
    perfectDriveScoreData,
    perfectDriveContextualData,
    perfectDriveReportData
};

export type DemoData = typeof data;
