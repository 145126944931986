import { WithTranslation, withTranslation } from 'react-i18next';
import PartnersTable from '../PartnerTable';
import PartnerTableBar from 'modules/partner/components/PartnerTableBar';
import { PartnerCompanyModel, PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import PartnerPairing from 'modules/partner/components/PartnerPairing';
import { PairingItemType, CurrencySelectModel, CountrySelectModel } from 'logic/partner/partner';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { LayoutContent } from 'common/components/Layout/Content';
import { PartnerPairingKey, PartnerPairingModel } from 'common/model/partner-pairing';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import PartnersActions from '../PartnersActions';
import PartnersDetailCard from '../PartnersDetailCard';
import PartnersLinkedItems from '../PartnersLinkedItems';

interface Props extends WithTranslation {
    edit: boolean;
    loading: boolean;
    data?: PaginatedResponse<PartnerCompanyModel[]>;
    selected?: PartnerCompanyModel;
    pairing?: PartnerPairingModel;
    search?: { text: string };
    createLoading?: boolean;
    createError?: string;
    companies?: PartnerCompanySelectModel[];
    currencyCounter?: CurrencySelectModel[];
    countryCounter?: CountrySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    onBarCompanyFilterChange?: (id: string) => void;
    onBarCompanyFilterCancel?: () => void;
    onBarCompanyFilterSearch?: (value: string) => void;
    onTableRowSelect?: (id: string) => void;
    onDetailActionsUnpair?: (linked: PairingItemType) => void;
    onCheckUserEmailExist?: (email: string) => Promise<boolean>;
    onPairingKeySelect?: (key: PartnerPairingKey) => void;
    onPairingItemSelect?: (itemId: string) => void;
    onPairingSearch?: (text: string) => void;
    onPairingConfirm?: () => void;
    onPairingCancel?: () => void;
    onActionsImpersonate?: () => Promise<void>;
    onActionsPair?: () => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function Partners(props: Props) {
    const { t } = props;

    return (
        <>
            {props.pairing && (
                <PartnerPairing
                    keys={props.pairing.keys}
                    keySelected={props.pairing.keySelected}
                    items={props.pairing.items}
                    itemIdSelected={props.pairing.itemIdSelected}
                    search={props.pairing.search}
                    loading={props.pairing.loading}
                    keySource={PartnerPairingKey.partners}
                    onKeySelect={props.onPairingKeySelect}
                    onItemSelect={props.onPairingItemSelect}
                    onSearch={props.onPairingSearch}
                    onConfirm={props.onPairingConfirm}
                    onCancel={props.onPairingCancel}
                />
            )}

            <LayoutContent
                className="partner-partners no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            heading={t('common.partners')}
                            filters={[
                                <PartnerTableBar
                                    companies={props.companies}
                                    selectedCompany={props.selectedCompany}
                                    onCompanyFilterChange={props.onBarCompanyFilterChange}
                                    onCompanyFilterCancel={props.onBarCompanyFilterCancel}
                                    onCompanyFilterSearch={props.onBarCompanyFilterSearch}
                                />
                            ]}
                        />
                        <PartnersTable
                            data={props.data}
                            selected={props.selected}
                            loading={props.loading}
                            onTableRowSelect={props.onTableRowSelect}
                            onPaginationChange={props.onPaginationChange}
                        />
                    </>
                }
                extra={[
                    props.selected ? (
                        <LayoutSidePanel
                            header={props.selected.name}
                            body={
                                <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                    <Collapse.Panel header={props.t('common.detail')} key="1">
                                        <PartnersDetailCard
                                            data={props.selected}
                                            currencyCounter={props.currencyCounter}
                                            countryCounter={props.countryCounter}
                                        />
                                    </Collapse.Panel>
                                    {!props.edit ? (
                                        <Collapse.Panel header={props.t('Partner.linkedItems')} key="2">
                                            <PartnersLinkedItems
                                                model={props.selected}
                                                onUnpair={props.onDetailActionsUnpair}
                                            />
                                        </Collapse.Panel>
                                    ) : (
                                        <></>
                                    )}
                                </Collapse>
                            }
                            footer={
                                <PartnersActions
                                    onImpersonate={
                                        props.selected?.contactPerson?.[0]?.ssoId
                                            ? props.onActionsImpersonate
                                            : undefined
                                    }
                                    onPair={props.onActionsPair}
                                />
                            }
                        />
                    ) : null
                ]}
            />
        </>
    );
}

export default withTranslation()(Partners);
