import { Col, Row, Tooltip } from 'antd';
import { TooltipPlacement, TooltipProps } from 'antd/lib/tooltip';
import { Button } from 'common/components';
import cn from 'classnames';

interface Props {
    label?: string;
    icon?: string;
    tooltip?: TooltipProps;
    placement?: TooltipPlacement;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
    qa?: string;
    loading?: boolean;
    onButtonClick?: (selected: boolean) => void;
}

const SelectButton = (props: Props) => {
    const title = props.tooltip?.title && '';
    const selected = props.selected && false;

    const handleButtonClick = () => {
        props.onButtonClick?.(selected as boolean);
    };

    return (
        <Tooltip title={title} {...props.tooltip}>
            <Button
                type="default"
                className={cn(`select-button ${props.className}`, {
                    selected: props.selected === true,
                    disabled: props.disabled === true
                })}
                disabled={props.disabled}
                onClick={handleButtonClick}
                data-qa={props.qa}
                loading={props.loading}
            >
                <Row justify="space-between" align="middle">
                    {props.icon && !props.loading && (
                        <Col>
                            <span className="icon">
                                <img className="fa" src={props.icon} alt="" />
                            </span>
                        </Col>
                    )}
                    <Col>{props.label}</Col>
                </Row>
            </Button>
        </Tooltip>
    );
};

export default SelectButton;
