import React, { createRef, useEffect } from 'react';
import { CardProps, Col, Row } from 'antd';
import { Button, Card } from 'common/components';
import cn from 'classnames';
import { ArrowLeft } from 'resources/images/planner';
import { getParents } from 'common/utils';

interface Props extends CardProps {
    icon?: string;
    beforeTitle?: React.ReactNode;
    afterTitle?: React.ReactNode;
    fullContent?: boolean;
    footer?: React.ReactNode;
    escapeDelay?: number;
    onClose?: () => void;
    onEscape?: (clear: boolean) => void;
}

const PlannerCard = (props: React.PropsWithChildren<Props>) => {
    const escapeDelay = props.escapeDelay ?? 200;
    const wrapperRef = createRef<HTMLDivElement>();

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                const containers = ['.ant-picker-dropdown', '.ant-select-dropdown', '.ant-modal-root', '.ant-tooltip'];
                let isContainer = false;
                for (let i = 0; i < containers.length; i++) {
                    if (getParents(event.target, containers[i])) {
                        isContainer = true;
                        break;
                    }
                }
                if (!isContainer) {
                    props.onEscape?.(!getParents(event.target, '.places-configurator-new-place-wrapper'));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        if (props.onEscape) {
            document.addEventListener('keydown', handleKeypress);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (props.onEscape) {
                document.removeEventListener('keydown', handleKeypress);
            }
        };
    });

    const handlerCloseClick = (event: React.MouseEvent) => {
        props.onClose?.();
        event.stopPropagation();
        event.preventDefault();
    };

    const handleKeypress = (event: KeyboardEvent) => {
        if (event && event.key === 'Escape') {
            if (props.onEscape) {
                setTimeout(() => {
                    props.onEscape?.(true);
                }, escapeDelay);
            }
        }
    };

    return (
        <Card
            title={''}
            className={cn(`planner-card ${props.className}`, {
                'planner-card-full-content': props.fullContent
            })}
        >
            <Row ref={wrapperRef}>
                <Row align="middle" className="planner-card-header">
                    <Row align="middle" className="planner-card-header-content" gutter={6}>
                        <Col>
                            <Button
                                icon={<img src={ArrowLeft} alt="icon-arrow-left" />}
                                size="small"
                                className="planner-card-submit"
                                onClick={handlerCloseClick}
                            />
                        </Col>
                        {props.beforeTitle && <Col>{props.beforeTitle}</Col>}
                        {props.title && <Col>{props.title}</Col>}
                        {props.afterTitle && <Col flex="auto">{props.afterTitle}</Col>}
                    </Row>
                </Row>
                <Row className="planner-card-content planner-content-scroll-new">{props.children}</Row>
                {props.footer && <Row className="planner-card-footer">{props.footer}</Row>}
            </Row>
        </Card>
    );
};

export default PlannerCard;
