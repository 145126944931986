import React from 'react';
import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';

export function LayoutContent({
    main,
    extra,
    mainSizes,
    extraSizes,
    className
}: {
    main: ReactNode;
    extra?: ReactNode[];
    mainSizes?: { xs: number; sm: number; md: number };
    extraSizes?: { xs: number; sm: number; md: number }[];
    className?: string;
}) {
    const extraLayout = extra?.filter(e => e !== null);
    const useExtraLayout = extraLayout?.length === extraSizes?.length;
    return (
        <div className={cn('layout-content page', className)}>
            <Row gutter={6}>
                <Col
                    xs={mainSizes?.xs ?? 24}
                    sm={mainSizes?.sm ?? 24}
                    md={useExtraLayout ? mainSizes?.md ?? 18 : 24}
                    className="layout-content-main"
                >
                    <div className="layout-content-main-content">{main}</div>
                </Col>
                {useExtraLayout &&
                    extraLayout?.map((c, i) => (
                        <Col
                            key={i}
                            xs={extraSizes?.[i].xs ?? 24}
                            sm={extraSizes?.[i].sm ?? 24}
                            md={extraSizes?.[i].md ?? 6}
                            className="layout-content-extra"
                        >
                            <div className="layout-content-extra-content">{c}</div>
                        </Col>
                    ))}
            </Row>
        </div>
    );
}
