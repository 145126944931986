import { NoData } from 'common/components/NoData';
import { getGraphConfig } from 'common/components/SimpleLineGraph/getGraphConfig';
import { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import GraphTooltip from 'common/components/SimpleLineGraph/components/GraphTooltip';
import ActiveDot from 'common/components/SimpleLineGraph/components/ActiveDot';
import IdleDot from 'common/components/SimpleLineGraph/components/IdleDot';
import { ExternalDevicesConfig } from '../../utils/useExternalDevicesConfig';
import { useComplexGraphData } from '../../utils/useComplexGraphData';
import { degC } from 'domain-constants';

interface Props extends WithTranslation {
    hideDots?: boolean;
    onActiveDotMount?(p: { key: string | number; value: string | number; lat: number; lon: number }, i: number): void;
    onActiveDotDestroy?(): void;
    activeGraphDotIndex?: number;
    externalDevicesConfig: ExternalDevicesConfig;
    data: Record<string, string | number>[];
    seriesLabels?: {
        [key: string]: string;
    };
}

function ComplexGraph({
    onActiveDotMount,
    onActiveDotDestroy,
    activeGraphDotIndex,
    externalDevicesConfig,
    data,
    seriesLabels
}: Props) {
    const graphConfig = getGraphConfig();
    const { coloredLineKey, resetLineColors } = useComplexGraphData();

    useEffect(() => {
        resetLineColors(data[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data[0]]);

    useEffect(() => {
        return () => {
            onActiveDotDestroy?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (data.length === 0 || externalDevicesConfig.selectedSensors.length === 0) {
        return <NoData />;
    }

    return (
        <ResponsiveContainer>
            <LineChart data={data} margin={graphConfig.marging}>
                <XAxis stroke={graphConfig.axisStroke} dataKey="timestamp" style={graphConfig.fontSize} />
                <YAxis stroke={graphConfig.axisStroke} style={graphConfig.fontSize} />
                <Tooltip
                    content={
                        <GraphTooltip
                            seriesLabels={seriesLabels}
                            valueUnit={degC}
                            color={graphConfig.lineStroke}
                            complex
                            colors={coloredLineKey}
                        />
                    }
                />
                {Object.keys(data[0])
                    .filter(key => key !== 'timestamp')
                    .map(key => {
                        return (
                            <Line
                                key={key}
                                type="monotone"
                                dataKey={key}
                                stroke={coloredLineKey[key]}
                                dot={<IdleDot activeIndex={activeGraphDotIndex} strokeColor={coloredLineKey[key]} />}
                                activeDot={
                                    <ActiveDot
                                        onActiveDotMount={onActiveDotMount}
                                        strokeColor={coloredLineKey[key]}
                                        onActiveDotDestroy={onActiveDotDestroy}
                                    />
                                }
                            />
                        );
                    })}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default withTranslation()(ComplexGraph);
