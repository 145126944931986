import { action, makeObservable, observable, runInAction } from 'mobx';
import { Logic } from 'logic/logic';
import {
    ReadOnlyMonitoredObject,
    ReadOnlyMonitoredObjectGroup,
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyUser
} from 'generated/new-main/models';
import { VehicleGroupsFormModel } from 'common/forms/VehicleGroupsForm/VehicleGroupsForm';

export class VehicleGroupsModuleLogic {
    logic: Logic;

    @observable loadingMonitoredObjectGroupDetail?: boolean;
    @observable loadingMonitoredObjectGroups?: boolean;
    @observable loadingMonitoredObjects?: boolean;
    @observable loadingUsers?: boolean;
    @observable loadingInit?: boolean;

    @observable monitoredObjectGroups: ReadOnlyMonitoredObjectGroup[] = [];
    @observable monitoredObjects: ReadOnlyMonitoredObject[] = [];
    @observable users: ReadOnlyUser[] = [];

    @observable selectedMonitoredObjectGroupId?: number;
    @observable selectedMonitoredObjectGroupNested?: ReadOnlyMonitoredObjectGroupNested;

    constructor(logic: Logic) {
        this.logic = logic;

        makeObservable(this);
    }

    get selectedMonitoredObjectGroup(): ReadOnlyMonitoredObjectGroup | undefined {
        return this.monitoredObjectGroups.find(group => group.id === this.selectedMonitoredObjectGroupId);
    }

    @action
    async init() {
        if (this.logic.demo().isActive) {
            // TODO:
        } else {
            this.loadingInit = true;
            this.selectedMonitoredObjectGroupId = undefined;
            try {
                await Promise.all([
                    this._fetchMonitoredObjectGroups(),
                    this._fetchMonitoredObjects(),
                    this._fetchUsers()
                ]);
            } catch (err) {
                console.error('VehicleGroupsModuleLogic init err:', err);
                throw err;
            } finally {
                runInAction(() => {
                    this.loadingInit = false;
                });
            }
        }
    }

    @action
    async setSelectedMonitoredObjectGroupId(id?: number) {
        this.selectedMonitoredObjectGroupId = id;
        this.selectedMonitoredObjectGroupNested = id ? await this._fetchMonitoredObjectGroupDetail(id) : undefined;
    }

    @action updateMonitoredObjectGroupList(
        monitoredObjectGroup: ReadOnlyMonitoredObjectGroup,
        type: 'push' | 'pop' = 'push'
    ) {
        if (type === 'push') {
            const index = this.monitoredObjectGroups.findIndex(group => group.id === monitoredObjectGroup.id);
            if (index !== -1) {
                this.monitoredObjectGroups[index] = monitoredObjectGroup;
            } else {
                this.monitoredObjectGroups.push(monitoredObjectGroup);
            }
        } else {
            this.monitoredObjectGroups = this.monitoredObjectGroups.filter(
                group => group.id !== monitoredObjectGroup.id
            );
        }
    }

    async createMonitoredObjectGroup(name: string) {
        try {
            return await this.logic.vehicles().vehicleGroups().createMonitoredObjectGroup(name);
        } catch (err) {
            console.error('VehicleGroupsModuleLogic createNewGroup err:', err);
            throw err;
        }
    }

    async editMonitoredObjectGroupName(id: number, name: string) {
        try {
            return await this.logic.vehicles().vehicleGroups().editMonitoredObjectGroupName(id, name);
        } catch (err) {
            console.error('VehicleGroupsModuleLogic editMonitoredObjectGroupName err:', err);
            throw err;
        }
    }

    async updateMonitoredObjectGroup(values: VehicleGroupsFormModel) {
        try {
            return await this.logic
                .vehicles()
                .vehicleGroups()
                .updateMonitoredObjectGroupList(values.vehicleGroupId, values.vehicleIds, values.userIds);
        } catch (err) {
            console.error('VehicleGroupsModuleLogic updateMonitoredObjectGroup err:', err);
            throw err;
        }
    }

    async deleteMonitoredObjectGroup(id: number) {
        try {
            return await this.logic.vehicles().vehicleGroups().deleteMonitoredObjectGroup(id);
        } catch (err) {
            console.error('VehicleGroupsModuleLogic deleteMonitoredObjectGroup err:', err);
            throw err;
        }
    }

    @action
    private async _fetchMonitoredObjectGroupDetail(id: number) {
        this.loadingMonitoredObjectGroupDetail = true;
        try {
            return await this.logic.vehicles().vehicleGroups().getMonitoredObjectGroupNestedById(id);
        } catch (err) {
            console.error('VehicleGroupsModuleLogic _fetchMonitoredObjectGroupDetail err:', err);
            throw err;
        } finally {
            runInAction(() => {
                this.loadingMonitoredObjectGroupDetail = false;
            });
        }
    }

    @action
    private async _fetchMonitoredObjectGroups() {
        this.loadingMonitoredObjectGroups = true;
        try {
            const response = await this.logic.vehicles().vehicleGroups().getAllMonitoredObjectGroups();
            runInAction(() => {
                this.monitoredObjectGroups = response.results;
            });
        } catch (err) {
            console.error('Monitored object group GET err:', err);
            throw err;
        } finally {
            runInAction(() => {
                this.loadingMonitoredObjectGroups = false;
            });
        }
    }

    @action
    private async _fetchMonitoredObjects() {
        this.loadingMonitoredObjects = true;
        try {
            const response = await this.logic.vehicles().getClientMonitoredObjects();
            runInAction(() => {
                this.monitoredObjects = response.results;
            });
        } catch (err) {
            console.error('Monitored object GET err:', err);
            throw err;
        } finally {
            runInAction(() => {
                this.loadingMonitoredObjects = false;
            });
        }
    }

    @action
    private async _fetchUsers() {
        this.loadingUsers = true;
        try {
            const response = await this.logic.users().dispatchersNew();
            runInAction(() => {
                this.users = response.data;
            });
        } catch (err) {
            console.error('User GET err:', err);
            throw err;
        } finally {
            runInAction(() => {
                this.loadingUsers = false;
            });
        }
    }
}
