/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Code
 */
export interface Code {
    /**
     * 
     * @type {string}
     * @memberof Code
     */
    code: string;
}

export function CodeFromJSON(json: any): Code {
    return CodeFromJSONTyped(json, false);
}

export function CodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Code {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function CodeToJSON(value?: Code | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
    };
}


