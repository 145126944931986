import { ChangeEvent, Component, createRef, KeyboardEvent } from 'react';
import cn from 'classnames';
import { Input } from 'common/components/Form';

export type SelectOptCodeType = string | number;
export interface SelectOpt<T extends SelectOptCodeType = string> {
    code: T;
    label: string;
}

export interface Props<T extends SelectOptCodeType = string> {
    text?: string;
    type?: string;
    options: SelectOpt<T>[];
    placeholder?: string;
    autofocus?: boolean;
    disabled?: boolean;
    width?: number;
    onOptionSelect?: (value: T | null) => void;
    onTextChange?: (value: string) => void;
    qa?: string;
}

interface State<T extends SelectOptCodeType = string> {
    open: boolean;
    text: string;
    selected?: boolean;
    options: SelectOpt<T>[];
    highlight?: SelectOptCodeType;
}

export default class AutoComplete<T extends SelectOptCodeType = string> extends Component<Props<T>, State<T>> {
    private _ref = createRef<HTMLDivElement>();

    constructor(props: Props<T>) {
        super(props);
        this.state = {
            open: false,
            text: this.props.text ?? '',
            options: this.props.options ?? [],
            selected: this.props.options.filter(o => o.label === this.props.text).length ? true : false
        };
    }

    componentDidUpdate(prevProps: Props<T>) {
        this._ref.current?.scrollIntoView({
            block: 'nearest'
        });
        if (this.props.text !== prevProps.text) {
            this.setState({
                text: this.props.text ?? ''
            });
        }
        if (this.props.options !== prevProps.options) {
            this.setState({
                options: this.props.options,
                selected: this.props.options.filter(o => o.label === this.props.text).length ? true : false
            });
        }
    }

    render() {
        const { open, text, selected, options, highlight } = this.state;

        return (
            <div
                className={cn('autocomplete', {
                    disabled: this.props.disabled
                })}
            >
                <div className="t-dropdown-click" onMouseLeave={this._onLeaveInput} onMouseEnter={this._onEnterInput}>
                    <Input
                        type="text"
                        value={text}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autofocus}
                        placeholder={this.props.placeholder}
                        title={selected ? undefined : this.props.placeholder}
                        width={this.props.width}
                        onChange={this._onChange}
                        onKeyDown={this._onKeyDown}
                        onClick={this._onInputClick}
                        data-qa={this.props.qa}
                    />
                    {options.length > 0 && open && (
                        <div className={cn('t-dropdown-content', 't-bar-block')}>
                            {options.map((o, i) => (
                                <div
                                    key={o.code}
                                    ref={highlight === o.code ? this._ref : null}
                                    className={cn('t-bar-item', 't-hover-primary', {
                                        't-primary': (highlight === undefined && i === 0) || highlight === o.code
                                    })}
                                    onClick={this._onOptionClick.bind(undefined, o.code, o.label)}
                                >
                                    {o.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {selected && (
                    <img
                        className="autocomplete-close"
                        src="/img-svg/icn-close.svg"
                        onClick={this._onRemove}
                        alt=""
                        data-qa={`${this.props.qa}-cancel`}
                    />
                )}
            </div>
        );
    }

    private _onInputClick = (): void => {
        if (!this.props.disabled) {
            this.setState({ open: true });
        }
    };

    private _onLeaveInput = (): void => {
        if (!this.props.disabled) {
            this.setState({ open: false });
        }
    };

    private _onEnterInput = (): void => {
        if (!this.props.disabled) {
            this.setState({ open: true });
        }
    };

    private _onRemove = (): void => {
        if (!this.props.disabled) {
            this.setState({ text: '', highlight: undefined, selected: false });
            this.props.onOptionSelect?.(null);
            this.props.onTextChange?.('');
        }
    };

    private _onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (!this.props.disabled) {
            const text = e.currentTarget.value;
            this.setState({ text, open: true, highlight: undefined });
            this.props.onTextChange?.(text);
        }
    };

    private _onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (!this.props.disabled) {
            if (this.state.options.length > 0 && this.state.open) {
                switch (e.key) {
                    case 'ArrowDown': {
                        const index = this.state.options.findIndex(o => o.code === this.state.highlight);
                        const highlight = this.state.options[(index + 1) % this.state.options.length].code;
                        this.setState({ highlight });
                        break;
                    }
                    case 'ArrowUp': {
                        const current = this.state.options.findIndex(o => o.code === this.state.highlight);
                        const highlight =
                            this.state.options[current === 0 ? this.state.options.length - 1 : current - 1].code;
                        this.setState({ highlight });
                        break;
                    }
                    case 'Enter': {
                        const current = this.state.options.find(o => o.code === this.state.highlight);
                        if (current) {
                            this.setState({
                                open: false,
                                text: current.label,
                                selected: true,
                                highlight: current.code
                            });
                            this.props.onOptionSelect?.(current.code);
                        }
                        break;
                    }
                    case 'Escape': {
                        this.setState({ open: !this.state.open });
                        break;
                    }
                    default:
                        break;
                }
            }
        }
    };

    private _onOptionClick = (code: T, label: string): void => {
        if (!this.props.disabled) {
            this.setState({
                text: label,
                selected: true,
                highlight: code,
                open: false
            });
            this.props.onOptionSelect?.(code);
        }
    };
}
