import { PlaceSuggestion, PlaceSuggestionSource, UserContactsType } from 'generated/graphql';
import { Logic } from 'logic/logic';
import i18n from 'i18next';
import { Component } from 'react';
import { message } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import { TachoCardModel } from '../tachograph-cards/TachoCardsModule';
import { LinksMetadata } from '../../ManagementModule';
import Users from './ui/Users';
import { debounce } from 'debounce';
import { exponea } from 'logic/exponea';
import { Role } from 'logic/auth';
import {
    ReadOnlyMonitoredObjectFeSb,
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyUser,
    ReadOnlyUserNested,
    ReadOnlyUserRight,
    ReadOnlyUserRole
} from 'generated/new-main/models';
import { UserProfileFormModel } from 'common/forms/UserProfileForm';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, INFINITY_PAGE_LIMIT } from 'domain-constants';
import { CustomPlace, DefaultDriverInfo } from 'generated/backend-api';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { UserNestedModelModel } from 'common/model/user';
import { GroupsAssignmentFormModel } from 'common/forms/GroupsAssignmentForm/GroupsAssignmentForm';

export interface UserLinks {
    tachoCard?: {
        id: string;
        token: string;
        type: string;
        holder: string;
        relation: string;
        expirationDate: string;
        metadata?: LinksMetadata;
    };
    monitoredObject?: DefaultDriverInfo[];
    app?: {
        id: string;
        token: string;
        type: string;
        holder: string;
        relation: string;
        metadata?: LinksMetadata;
    };
}

export interface UserToken {
    id: number;
    token: string;
    user_id: number;
    token_type: string;
    startTime: string;
    endTime: string;
    deleted: number;
}

export type UserType = 'driver' | 'dispatcher';

export interface UserModel {
    id: string;
    ssoId: string;
    name: string;
    surname: string;
    mobile: string;
    email: string;
    tachographId: string;
    note: string;
    userGroups: UserType[];
    password?: string;
    pin?: string;
    username?: string;
    contacts: UserContactsType;
    // tokens?: UserTokenType[];
    address?: string;
    workLocation?: string[];
    links?: UserLinks;
}

export interface DriverModel extends UserModel {
    pin: string;
}

export interface DispatcherModel extends UserModel {
    username: string;
}

export function isDriver(model: ReadOnlyUser): boolean {
    return !!model.userRoles && model.userRoles.some(ur => ur.label.toLocaleLowerCase() === 'driver');
}

export interface Pairing {
    data?: PairingData[];
    selected?: PairingData;
    confirm?: PairingData;
    type?: keyof UserLinks;
    search?: string;
    foundPair?: boolean;
}

export interface Unpairing {
    model: ReadOnlyUserNested;
    type: keyof UserLinks;
    unpairId?: string;
}

export interface MonitoredObjectPairModel {
    id: string;
    rn: string;
}

export type PairingData = TachoCardModel | ReadOnlyMonitoredObjectFeSb;
export enum UserFilter {
    Users = 'dispatcher',
    Drivers = 'driver',
    All = ''
}

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    search?: { text: string };
    pairing?: Pairing;
    unpairing?: Unpairing;
    userPasswordReset?: ReadOnlyUser;
    invitedToEWFleetDrivers: string[];
    invite: boolean;
    table?: {
        loading?: boolean;
        remove?: UserNestedModelModel;
        selected?: UserNestedModelModel;
        userCustomPlaces?: CustomPlace[];
        data?: PaginatedResponse<UserNestedModelModel[]>;
    };
    userProfileForm: {
        loading?: boolean;
        isOpen: boolean;
        mode: 'EDIT' | 'CREATE';
    };
    placeOfWorkDelete?: {
        placeOfWork?: CustomPlace;
        loading?: boolean;
    };
    placesOfWorksAdd?: {
        visible?: boolean;
        placesForAdd?: PlaceSuggestion[];
        placesForAddSelected?: PlaceSuggestion[];
        loading?: boolean;
    };
    roles: Role[];
    userRoles?: ReadOnlyUserRole[];
    userRights?: ReadOnlyUserRight[];
    helper?: {
        content: string;
    };
    lastCreatedUser?: ReadOnlyUser;
    userPasswordLoading: boolean;
    userRemoveLoading: boolean;
    pairLoading: boolean;
    unpairLoading: boolean;
    assignToGroup: {
        open: boolean;
        loading: boolean;
        monitoredObjectGroups: ReadOnlyMonitoredObjectGroupNested[];
    };
    usersFilter: UserFilter;
}

class ManagementUserModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        const roles = this._logic.auth().roles();
        // const settings = this._logic.settings().getProp('management').users;
        this.state = {
            invitedToEWFleetDrivers: [],
            invite: this._logic.auth().roles().includes(Role.USR_W),
            userProfileForm: {
                isOpen: false,
                mode: 'CREATE'
            },
            roles,
            userPasswordLoading: false,
            userRemoveLoading: false,
            pairLoading: false,
            unpairLoading: false,
            assignToGroup: {
                loading: false,
                open: false,
                monitoredObjectGroups: []
            },
            usersFilter: UserFilter.Users //settings.filter,
        };
    }

    componentDidMount() {
        (window as any).app.UserModule = this;

        this._reloadData();
        this._logic
            .api()
            .monitoredObjectGroupApi.monitoredObjectGroupNested({
                offset: DEFAULT_PAGE_OFFSET,
                limit: INFINITY_PAGE_LIMIT
            })
            .then(result => {
                this.setState(state => ({
                    assignToGroup: {
                        ...state.assignToGroup,
                        loading: false,
                        monitoredObjectGroups: result.results
                    }
                }));
            });
    }

    componentWillUnmount() {
        (window as any).app.UserModule = undefined;
    }

    render() {
        return (
            <Users
                lang={this._logic.auth().user().lang}
                data={this.state.table?.data}
                loading={this.state.table?.loading}
                pairing={this.state.pairing}
                userPasswordReset={this.state.userPasswordReset}
                remove={this.state.table?.remove}
                selected={this.state.table?.selected}
                userCustomPlaces={this.state.table?.userCustomPlaces}
                unpairing={this.state.unpairing}
                search={this.state.search}
                invitedToEWFleetDrivers={this.state.invitedToEWFleetDrivers}
                invite={this.state.invite}
                userRights={this.state.userRights}
                userRoles={this.state.userRoles}
                userProfileForm={this.state.userProfileForm}
                demoMode={this._logic.demo().isActive}
                roles={this.state.roles}
                helper={this.state.helper}
                placeOfWorkDelete={this.state.placeOfWorkDelete}
                placesOfWorkAdd={this.state.placesOfWorksAdd}
                lastCreatedUser={this.state.lastCreatedUser}
                userPasswordLoading={this.state.userPasswordLoading}
                userRemoveLoading={this.state.userRemoveLoading}
                pairLoading={this.state.pairLoading}
                unpairLoading={this.state.unpairLoading}
                assignToGroup={this.state.assignToGroup}
                usersFilter={this.state.usersFilter}
                onBackButtonClick={this._onBackButtonClick}
                onCancelNewUserButtonClick={this._onCancelNewUser}
                onBarCreateNewUserButtonClick={this._onCreateNewUser}
                onDetailCloseClick={this._onDetailCloseClick}
                onPairingCancelButtonClick={this._onPairingCancel}
                onPairingConfirmButtonClick={this._onPairingConfirm}
                onPairingItemSelect={this._onPairingItemSelect}
                onPairingSearchInputChange={this._onPairingSearch}
                onPairingSubmitButtonClick={this._onPairingSubmit}
                onPairingTypeChange={this._onPairingTypeChange}
                onSearchButtonClick={this._onBarSearchToggle}
                onSearchInputChange={this._onSearchChange}
                onTableRowSelect={this._onTableRowSelect}
                onUnpairAssetButtonCancelClick={this._onUnpairAssetCancel}
                onUnpairAssetButtonClick={this._onUnpairAsseButton}
                onUnpairAssetButtonConfirmClick={this._onUnpairAssetConfirm}
                onUserFormSubmit={this._onUserFormSubmit}
                onUserRemoveButtonCancel={this._onUserRemoveCancel}
                onUserRemoveButtonConfirm={this._onUserRemoveConfirm}
                onUserPlaceOfWorkRemoveButtonClick={this._onUserPlaceOfWorkDelete}
                onUserPlaceOfWorkRemoveButtonCancel={this._onUserPlaceOfWorkDeleteCancel}
                onUserPlaceOfWorkRemoveButtonConfirm={this._onUserPlaceOfWorkDeleteConfirm}
                onUserPlacesOfWorkAddButtonCancel={this._onUserPlacesOfWorkAddCancel}
                onUserPlacesOfWorkAddButtonConfirm={this._onUserPlacesOfWorkAddConfirm}
                onUserPlacesOfWorkAddDeleteButtonClick={this._onUserPlacesOfWorkAddDelete}
                onUserPlacesOfWorkAddSearch={this._onUserPlacesOfWorkAddSearch}
                onUserPlacesOfWorkAddSelect={this._onUserPlacesOfWorkAddSelect}
                onPaginationChange={this._onPaginationChange}
                onUserPasswordResetCancel={this._onUserPasswordResetCancel}
                onUserPasswordResetConfirm={this._onUserPasswordResetConfirm}
                onHelperClick={this._onHelperClick}
                onHelperClose={this._onHelperClose}
                onActionsEditNewUserButtonClick={this._onEditNewUserButtonClick}
                onActionsInviteToEWFleetDriver={this._onInviteToEWFleet}
                onActionsLinkNewAssetButtonClick={this._onPairingAssetClick}
                onActionsPasswordResetClick={this._onUserPasswordResetClick}
                onActionsPlaceOfWorkUsersAddButtonClick={this._onUserPlacesOfWorkAdd}
                onActionsUserRemoveButtonClick={this._onUserRemoveClick}
                onGroupAssignmentSubmit={this._onGroupAssignmentSubmit}
                onGroupAssignmentCloseClick={this._onGroupAssignmentCloseClick}
                onActionsAssignToVehicleGroupClick={this._onActionsAssignToVehicleGroupClick}
                onUsersFilterClick={this._onUserFilterClick}
            />
        );
    }

    private _onUserFilterClick = (usersFilter: UserFilter) => {
        this.setState(state => ({
            ...state,
            usersFilter
        }));
        // this._logic.users().setSettings()
        this._reloadData();
    };

    private _onGroupAssignmentSubmit = async (values: GroupsAssignmentFormModel): Promise<boolean> => {
        const selectedUserId = this.state.table?.selected?.id;
        const selectedUserSsoId = this.state.table?.selected?.ssoId;
        if (!selectedUserId || !selectedUserSsoId) {
            console.error(`Could not assign vehicle to vehicle groups, no selected user`);
            return false;
        }
        try {
            const success = await this._logic
                .users()
                .updateMonitoredObjectGroupForUser(selectedUserSsoId, values.monitoredObjectGroupIds);
            this._reloadData();
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    open: false
                }
            }));
            return success;
        } catch (err) {
            console.error(`Could not assign vehicle to vehicle groups, err: ${err}`);
            return false;
        }
    };

    private _onGroupAssignmentCloseClick = () => {
        this.setState(state => ({
            assignToGroup: {
                ...state.assignToGroup,
                open: false
            }
        }));
    };

    private _onActionsAssignToVehicleGroupClick = async () => {
        this.setState(state => ({
            assignToGroup: {
                ...state.assignToGroup,
                open: true,
                loading: true
            }
        }));

        try {
            const result = await this._logic.api().monitoredObjectGroupApi.monitoredObjectGroupNested({
                offset: DEFAULT_PAGE_OFFSET,
                limit: INFINITY_PAGE_LIMIT
            });
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    loading: false,
                    monitoredObjectGroups: result.results
                }
            }));
        } catch (err) {
            message.error('err');
            this.setState(state => ({
                assignToGroup: {
                    ...state.assignToGroup,
                    loading: false
                }
            }));
        }
    };

    private _onPaginationChange = (pagination: PaginationParams) => {
        this._reloadData(pagination.limit, pagination.offset);
    };

    private _onBarSearchToggle = (): void => {
        this.setState(state => ({
            search: state.search ? undefined : { text: '' }
        }));
    };

    private _onSearchChange = debounce((text: string): void => {
        this._logic.exponea().trackEvent(exponea.module.settingsUsers, {
            status: exponea.status.actionTaken,
            action: exponea.action.search
        });
        this.setState({ table: undefined, search: { text } }, () => {
            this._reloadData();
        });
    }, 500);

    private _onPairingSubmit = async (): Promise<void> => {
        this.setState({
            pairLoading: true
        });
        function isTachoCard(obj: PairingData): obj is TachoCardModel {
            return 'token' in obj;
        }

        function isMonitoredObject(obj: PairingData): obj is ReadOnlyMonitoredObjectFeSb {
            return 'registrationNumber' in obj;
        }

        if (this.state.pairing?.selected) {
            if (this.state.pairing?.type === 'tachoCard' && isTachoCard(this.state.pairing.selected)) {
                const tachoCardToPair = this.state.pairing.selected;
                const selectedUser = this.state.table?.selected!;
                const modelTachoCard: TachoCardModel = {
                    ...tachoCardToPair,
                    userId: Number(selectedUser.id),
                    holder: `${this.state.pairing.selected.name} ${this.state.pairing.selected.surname}`,
                    name: this.state.pairing.selected.name,
                    surname: this.state.pairing.selected.surname
                };

                try {
                    const updated = await this._logic
                        .cards()
                        .pairUserToTachoCard(modelTachoCard.id, String(modelTachoCard.userId));

                    if (updated) {
                        message.success(this.props.t('ManagementUsers.message.pairSuccess'));
                        this.setState(
                            {
                                pairing: undefined
                            },
                            () =>
                                this._reloadData(
                                    this.state.table?.data?.limit,
                                    this.state.table?.data?.offset,
                                    this.state.lastCreatedUser ? true : false
                                )
                        );
                    } else {
                        message.error(this.props.t('ManagementTachoCards.message.pairError'));
                    }
                } catch (err) {
                    message.error(this.props.t('ManagementTachoCards.message.pairError'));
                    console.error(`Can not pair ${this.state.pairing?.type}, err: ${err}`);
                }
            }

            if (this.state.pairing?.type === 'monitoredObject' && isMonitoredObject(this.state.pairing.selected)) {
                const mo = this.state.pairing.selected;
                const selectedUser = this.state.table?.selected!;
                try {
                    await this._logic
                        .vehicles()
                        .pairDriverToMonitoredObject((mo.id ?? '').toString(), Number(selectedUser.id));
                    message.success(this.props.t('ManagementTachoCards.message.pairSuccess'));
                    this.setState(
                        {
                            pairing: undefined
                        },
                        () =>
                            this._reloadData(
                                this.state.table?.data?.limit,
                                this.state.table?.data?.offset,
                                this.state.lastCreatedUser ? true : false
                            )
                    );
                } catch (err) {
                    message.error(this.props.t('ManagementTachoCards.message.pairError'));
                    console.error(`Can not pair ${this.state.pairing?.type}, err: ${err}`);
                }
            }
        }

        this.setState({
            pairLoading: false
        });
    };

    private _onUnpairAsseButton = (model: ReadOnlyUser, type: keyof UserLinks, unpairId?: string): void => {
        this.setState({ unpairing: { model, type, unpairId } });
    };

    private _onUnpairAssetCancel = (): void => {
        this.setState({ unpairing: undefined });
    };

    private _onUnpairAssetConfirm = async (): Promise<void> => {
        this.setState({ unpairLoading: true });
        const tokens = this.state.table?.selected?.userTokens;
        if (this.state.unpairing?.type === 'tachoCard') {
            const tachoCard = tokens?.find(t => t.tokenType === 'driver_card');
            if (!tachoCard) {
                this.setState({ unpairing: undefined, unpairLoading: false });
                return;
            }
            try {
                const unpaired = await this._logic
                    .cards()
                    .unpairTachoCardFromUser(this.state.unpairing.model, tachoCard);

                if (unpaired) {
                    this._reloadData(
                        this.state.table?.data?.limit,
                        this.state.table?.data?.offset,
                        this.state.lastCreatedUser ? true : false
                    );
                    message.success(this.props.t('ManagementUsers.message.unpairSuccess'));
                } else {
                    message.error(this.props.t('ManagementUsers.message.unpairError'));
                    console.error('Unpair failed');
                }
            } catch (err) {
                console.error(`Unpair failed, err: ${err}`);
                message.error(this.props.t('ManagementUsers.message.unpairError'));
            }
        } else if (this.state.unpairing?.type === 'app') {
            const appCard = tokens?.find(t => t.tokenType === 'app');
            if (!appCard) {
                this.setState({ unpairing: undefined, unpairLoading: false });
                return;
            }
            try {
                const unpaired = await this._logic.cards().unpairAppFromUser(this.state.unpairing.model, appCard);

                if (unpaired) {
                    this._reloadData(
                        this.state.table?.data?.limit,
                        this.state.table?.data?.offset,
                        this.state.lastCreatedUser ? true : false
                    );
                    message.success(this.props.t('ManagementUsers.message.unpairSuccess'));
                } else {
                    message.error(this.props.t('ManagementUsers.message.unpairError'));
                    console.error('Unpair failed');
                }
            } catch (err) {
                console.error(`Unpair failed, err: ${err}`);
                message.error(this.props.t('ManagementUsers.message.unpairError'));
            }
        } else if (this.state.unpairing?.type === 'monitoredObject' && this.state.unpairing.unpairId) {
            try {
                await this._logic.vehicles().unpairDriverFromVehicle(this.state.unpairing.unpairId);
                this._reloadData(
                    this.state.table?.data?.limit,
                    this.state.table?.data?.offset,
                    this.state.lastCreatedUser ? true : false
                );
                message.success(this.props.t('ManagementUsers.message.unpairSuccess'));
            } catch (err) {
                console.error(`Unpair failed, err: ${err}`);
                message.error(this.props.t('ManagementUsers.message.unpairError'));
            }
        }

        this.setState({
            unpairLoading: false,
            unpairing: undefined
        });
    };

    private _onPairingCancel = (): void => {
        this.setState({ pairing: undefined });
    };

    private _onPairingTypeChange = (type: keyof UserLinks): void => {
        if (this.state.pairing?.type === type) {
            return;
        }

        this.setState({ pairing: { type } }, () => {
            if (type === 'tachoCard') {
                this._logic
                    .cards()
                    .getTachoCards(true, INFINITY_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, true)
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data
                            }
                        }));
                    });
            }
            if (type === 'monitoredObject') {
                this._logic
                    .vehicles()
                    .getMonitoredObjectFilters()
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                selected: undefined,
                                data
                            }
                        }));
                    });
            }
        });
    };

    private _onPairingItemSelect = (selected: PairingData): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                selected
            }
        }));
    };

    private _onPairingConfirm = (): void => {
        function isMonitoredObject(obj: PairingData): obj is ReadOnlyMonitoredObjectFeSb {
            return 'registrationNumber' in obj;
        }
        let foundPair: boolean = false;
        if (
            this.state.pairing?.selected &&
            this.state.pairing?.type === 'monitoredObject' &&
            isMonitoredObject(this.state.pairing.selected)
        ) {
            const mo = this.state.pairing.selected;
            foundPair = !!this._logic
                .users()
                .defaultDriversInfo.find(defaultDriver => defaultDriver.monitoredObjectId === (mo.id ?? '').toString());
        }
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                foundPair: foundPair,
                confirm: state.pairing?.selected
            }
        }));
    };

    private _onPairingSearch = debounce((text: string): void => {
        this.setState(state => ({
            pairing: {
                ...state.pairing,
                search: text
            }
        }));
    }, 500);

    private _onPairingAssetClick = (): void => {
        this.setState(
            {
                pairing: { type: 'tachoCard' }
            },
            () =>
                this._logic
                    .cards()
                    .getTachoCards(true, INFINITY_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, true)
                    .then(data => {
                        this.setState(state => ({
                            pairing: {
                                ...state.pairing,
                                data
                            }
                        }));
                    })
        );
    };

    private _onEditNewUserButtonClick = () => {
        this.setState({
            userProfileForm: {
                loading: true,
                isOpen: true,
                mode: 'EDIT'
            }
        });
        Promise.all([this._logic.users().rights().getUserRights(), this._logic.users().roles().getUserRoles()])
            .then(res => {
                const [userRights, userRoles] = res;
                this.setState(state => ({
                    table: {
                        ...state.table
                    },
                    userProfileForm: {
                        ...state.userProfileForm,
                        loading: false
                    },
                    userRights,
                    userRoles
                }));
            })
            .catch(err => {
                console.error('fail to fetch user groups, rights or contracts', err);
            });
    };

    private _onCreateNewUser = (): void => {
        this.setState({
            userProfileForm: {
                loading: true,
                isOpen: true,
                mode: 'CREATE'
            }
        });
        Promise.all([this._logic.users().rights().getUserRights(), this._logic.users().roles().getUserRoles()])
            .then(res => {
                const [userRights, userRoles] = res;
                this.setState(state => ({
                    table: {
                        ...state.table
                    },
                    userProfileForm: {
                        ...state.userProfileForm,
                        loading: false
                    },
                    userRights,
                    userRoles
                }));
            })
            .catch(err => {
                console.error('fail to fetch user groups, rights or contracts', err);
            });
    };

    private _onBackButtonClick = () => {
        this.setState(state => ({
            userProfileForm: {
                ...state.userProfileForm,
                isOpen: false
            }
        }));
    };

    private _onCancelNewUser = (): void => {
        this.setState(state => ({
            userProfileForm: {
                ...state.userProfileForm,
                isOpen: false
            }
        }));
    };

    private _onUserRemoveClick = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                remove: state.table?.selected
            }
        }));
    };

    private _onUserRemoveConfirm = async (): Promise<void> => {
        this.setState({
            userRemoveLoading: true
        });

        if (this.state.table?.remove) {
            try {
                await this._logic.users().deleteUser({
                    ...this.state.table?.remove
                });
                message.success(this.props.t('ManagementUsers.message.userRemoveSuccess'));
            } catch (err) {
                console.error(`User remove failed, err: ${err}`);
                message.error(this.props.t('ManagementUsers.message.userRemoveError'));
            }
        }

        this.setState(
            state => ({
                userRemoveLoading: false,
                table: {
                    ...state.table,
                    remove: undefined
                }
            }),
            () => this._reloadData()
        );
    };

    private _onInviteToEWFleet = (): void => {
        const model = this.state.table?.selected;
        if (model) {
            this._logic.users().inviteToEwFleetDriver(model);
            const userId = model.id;
            if (!userId) {
                throw new Error('nor user id');
            }
            this.setState(state => ({
                invitedToEWFleetDrivers: state.invitedToEWFleetDrivers.concat(userId.toString())
            }));
        }
    };

    private _onUserRemoveCancel = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                remove: undefined
            }
        }));
    };

    private _onDetailCloseClick = (): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                selected: undefined,
                userCustomPlaces: undefined
            }
        }));
    };

    private _onTableRowSelect = (id?: number): void => {
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    selected:
                        state.table?.selected?.id === id ? undefined : state.table?.data?.data.find(d => d.id === id)
                },
                userProfileForm: {
                    ...state.userProfileForm,
                    loading: false,
                    isOpen: false
                }
            }),
            () => {
                if (this.state.table?.selected?.id) {
                    this._logic
                        .poi()
                        .getUserCustomPlaces(this.state.table?.selected?.id)
                        .then(placesOfWork => {
                            this.setState(state => ({
                                table: {
                                    ...state.table,
                                    userCustomPlaces: placesOfWork
                                }
                            }));
                        });
                }
            }
        );
    };

    private _onUserPlaceOfWorkDelete = (placeOfWorkId: string): void => {
        const placeOfWork = this.state.table?.userCustomPlaces?.find(placeOfWork => placeOfWork.id === placeOfWorkId);
        if (placeOfWork) {
            this.setState(state => ({
                placeOfWorkDelete: {
                    ...state.placeOfWorkDelete,
                    placeOfWork: placeOfWork
                }
            }));
        }
    };
    private _onUserPlaceOfWorkDeleteCancel = (): void => {
        this.setState({ placeOfWorkDelete: undefined });
    };

    private _onUserPlaceOfWorkDeleteConfirm = (): void => {
        this.setState(state => ({
            placeOfWorkDelete: {
                ...state.placeOfWorkDelete,
                loading: true
            }
        }));
        const placeId = this.state.placeOfWorkDelete?.placeOfWork?.id;
        const placeOfWorkId = this.state.placeOfWorkDelete?.placeOfWork?.placeOfWork?.[0]?.id;
        if (placeId && placeOfWorkId) {
            this._logic
                .poi()
                .deletePlaceOfWorkUser(placeOfWorkId, placeId)
                .then(_res => {
                    this.setState({
                        placeOfWorkDelete: undefined
                    });
                    if (this.state.table?.selected?.id) {
                        this._logic
                            .poi()
                            .getUserCustomPlaces(this.state.table?.selected?.id)
                            .then(placesOfWork => {
                                this.setState(state => ({
                                    table: {
                                        ...state.table,
                                        userCustomPlaces: placesOfWork
                                    }
                                }));
                            })
                            .catch(_err => {
                                this.setState(state => ({
                                    table: {
                                        ...state.table,
                                        userCustomPlaces: []
                                    }
                                }));
                            });
                    }
                });
        }
    };

    private _onUserPlacesOfWorkAdd = (): void => {
        this.setState({
            placesOfWorksAdd: {
                visible: true
            }
        });
    };

    private _onUserPlacesOfWorkAddCancel = (): void => {
        this.setState({ placesOfWorksAdd: undefined });
    };

    private _onUserPlacesOfWorkAddSearch = debounce((search: string) => {
        this._logic
            .poi()
            .getCustomPlaceSuggestions(search)
            .then(res =>
                this.setState(state => ({
                    placesOfWorksAdd: {
                        ...state.placesOfWorksAdd,
                        placesForAdd: res.filter(place => place.source === PlaceSuggestionSource.CustomPlaces)
                    }
                }))
            );
    }, 500);

    private _onUserPlacesOfWorkAddDelete = (placeOfWorkId: string): void => {
        const placeOfWork = this.state.placesOfWorksAdd?.placesForAddSelected?.find(
            placeOfWork => placeOfWork.id === placeOfWorkId
        );

        if (placeOfWork) {
            this.setState(state => ({
                placesOfWorksAdd: {
                    ...state.placesOfWorksAdd,
                    placesForAddSelected: state.placesOfWorksAdd?.placesForAddSelected?.filter(
                        place => place.id !== placeOfWorkId
                    )
                }
            }));
        }
    };

    private _onUserPlacesOfWorkAddSelect = (placeOfWorkId: string): void => {
        const placeOfWork = this.state.placesOfWorksAdd?.placesForAdd?.find(
            placeOfWork => placeOfWork.id === placeOfWorkId
        );

        if (placeOfWork) {
            this.setState(state => ({
                placesOfWorksAdd: {
                    ...state.placesOfWorksAdd,
                    placesForAddSelected: state.placesOfWorksAdd?.placesForAddSelected
                        ? state.placesOfWorksAdd?.placesForAddSelected?.concat(placeOfWork)
                        : [placeOfWork]
                }
            }));
        }
    };

    private _onUserPlacesOfWorkAddConfirm = (): void => {
        this.setState(state => ({
            placesOfWorksAdd: {
                ...state.placesOfWorksAdd,
                loading: true
            }
        }));

        const userId = this.state.table?.selected?.id;
        const placeOfWorkIds = this.state.placesOfWorksAdd?.placesForAddSelected?.map(
            placeOfWork => placeOfWork.id ?? ''
        );
        if (userId && placeOfWorkIds && placeOfWorkIds?.length > 0) {
            this._logic
                .poi()
                .addUserPlacesOfWork(userId, placeOfWorkIds)
                .then(_res => {
                    this.setState({ placesOfWorksAdd: undefined });
                    message.success(this.props.t('ManagementUsers.message.placeOwWorkAddSuccess'));
                    if (this.state.table?.selected?.id) {
                        this._logic
                            .poi()
                            .getUserCustomPlaces(this.state.table?.selected?.id)
                            .then(placesOfWork => {
                                this.setState(state => ({
                                    table: {
                                        ...state.table,
                                        userCustomPlaces: placesOfWork
                                    }
                                }));
                            });
                    }
                })
                .catch(err => {
                    this.setState(state => ({
                        placesOfWorksAdd: {
                            ...state.placesOfWorksAdd,
                            loading: false
                        }
                    }));

                    console.error(`Could not add place of work, err: ${err}`);
                    message.error(this.props.t('ManagementUsers.message.placeOwWorkAddError'));
                });
        }
    };

    private _onUserFormSubmit = (model: UserProfileFormModel): Promise<boolean> => {
        this._logic.exponea().trackEvent(exponea.module.settingsUsers, {
            status: exponea.status.actionTaken,
            action: exponea.action.createUser
        });

        this.setState(state => ({
            userProfileForm: {
                ...state.userProfileForm,
                loading: true
            }
        }));

        const updateDataWithoutReload = (user: ReadOnlyUser) => {
            const newUsers = this.state.table?.data?.data.map(u => {
                if (u.id === user.id) {
                    return {
                        ...u,
                        ...user
                    };
                }
                return u;
            });

            this.setState(state => {
                if (newUsers && state.table && state.table.data) {
                    return {
                        table: {
                            ...state.table,
                            data: {
                                ...state.table.data,
                                selected: { ...state.table.selected, ...user },
                                data: newUsers
                            }
                        }
                    };
                }
                return {};
            });
        };

        const userApiThenCallback = (user: ReadOnlyUser) => {
            if (this.state.userProfileForm.mode === 'EDIT') {
                updateDataWithoutReload(user);
            }
            this.setState(
                state => ({
                    search: undefined,
                    table: { ...state.table, selected: user },
                    userProfileForm: {
                        ...state.userProfileForm,
                        loading: false,
                        isOpen: false
                    },
                    lastCreatedUser: this.state.userProfileForm.mode === 'CREATE' ? user : state.lastCreatedUser
                }),
                () => {
                    if (this.state.userProfileForm.mode === 'CREATE') {
                        this._reloadData(DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, true);
                    }
                }
            );
            message.success(
                this.state.userProfileForm.mode === 'CREATE'
                    ? this.props.t('ManagementUsers.success')
                    : this.props.t('ManagementUsers.successUpdate')
            );
            return true;
        };

        const userApiCatchCallback = (err: any) => {
            console.error(err);
            this.setState(
                state => ({
                    search: undefined,
                    table: { ...state.table, form: undefined },
                    userProfileForm: {
                        ...state.userProfileForm,
                        loading: false
                    }
                }),
                () => this._reloadData()
            );
            if (err && typeof err?.json === 'function') {
                err?.json()
                    ?.then((res: any) => {
                        if (err?.status === 409) {
                            message.error(this.props.t('ManagementUsers.userNameError'));
                        } else if (res.startsWith('User with username')) {
                            message.error(this.props.t('ManagementUsers.userNameError'));
                        } else {
                            message.error(
                                this.state.userProfileForm.mode === 'CREATE'
                                    ? this.props.t('ManagementUsers.error')
                                    : this.props.t('ManagementUsers.updateError')
                            );
                        }
                    })
                    .catch((err: any) => {
                        console.error('err', err);
                        message.error(
                            this.state.userProfileForm.mode === 'CREATE'
                                ? this.props.t('ManagementUsers.error')
                                : this.props.t('ManagementUsers.updateError')
                        );
                    });
            } else {
                message.error(
                    this.state.userProfileForm.mode === 'CREATE'
                        ? this.props.t('ManagementUsers.error')
                        : this.props.t('ManagementUsers.updateError')
                );
            }
            return false;
        };

        if (this.state.userProfileForm.mode === 'CREATE') {
            return this.props.logic
                .users()
                .createUser({
                    active: 1,
                    averageSpeed: 0,
                    deleted: 0,
                    name: model.name ?? '',
                    surname: model.surname ?? '',
                    userRolesIds: model.roles ?? [],
                    userGroupIds: [],
                    languageIso6391: this.props.logic.auth().user().lang,
                    loginCredentials: {
                        pin: model.pin,
                        username: model.username
                    },
                    contact: {
                        phone_numbers: [model.phone],
                        emails: [model.email]
                    }
                })
                .then(userApiThenCallback)
                .catch(userApiCatchCallback);
        } else {
            return this._logic
                .users()
                .updateUser({
                    ssoId: this.state.table?.selected?.ssoId,
                    active: this.state.table?.selected?.active ?? 1,
                    averageSpeed: this.state.table?.selected?.averageSpeed ?? 0,
                    deleted: 0,
                    name: model.name ?? '',
                    surname: model.surname ?? '',
                    userRolesIds: model.roles ?? [],
                    userGroupIds: [],
                    languageIso6391: this.props.logic.auth().user().lang,
                    loginCredentials: model.pin
                        ? {
                              pin: model.pin,
                              username: model.username
                          }
                        : {
                              username: model.username
                          },
                    contact: {
                        phone_numbers: [model.phone],
                        emails: [model.email]
                    }
                })
                .then(userApiThenCallback)
                .catch(userApiCatchCallback);
        }
    };

    private _reloadData = async (
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        fromCreate: boolean = false
    ): Promise<void> => {
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this._logic
                    .users()
                    .getUsersNested(limit, offset, this.state.search?.text, true, this.state.usersFilter)
                    .then(async res => {
                        if (fromCreate && this.state.lastCreatedUser) {
                            await this._logic
                                .users()
                                .getUsersNested(
                                    limit,
                                    offset,
                                    ((this.state.lastCreatedUser?.loginCredentials as any).username ?? '') as string,
                                    true
                                )
                                .then(res => {
                                    this.setState(state => ({
                                        lastCreatedUser: res.data.find(user => user.id === state.lastCreatedUser?.id)
                                    }));
                                });
                            res.data = res.data.filter(user => user.id !== this.state.lastCreatedUser?.id);
                            res.data.unshift(this.state.lastCreatedUser);
                        }

                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data: {
                                    ...res
                                },
                                selected: res.data.find(d => d.id === state.table?.selected?.id)
                            }
                        }));
                    })
                    .catch(err => {
                        console.error(`Load data error, err: ${err}`);
                        message.error(this.props.t('common.error.loadDataError'));
                    })
                    .finally(() => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            },
                            lastCreatedUser: !fromCreate ? undefined : state.lastCreatedUser
                        }));
                    });
            }
        );
    };

    private _onUserPasswordResetConfirm = async () => {
        const ssoId = this.state.userPasswordReset?.ssoId;
        this.setState({ userPasswordLoading: true });
        if (!ssoId) {
            message.error(this.props.t('ManagementUsers.passwordResetError'));
        } else {
            try {
                await this._logic.users().resetPassword(ssoId);
                message.success(this.props.t('ManagementUsers.passwordResetSuccess'));
            } catch (err) {
                message.error(this.props.t('ManagementUsers.passwordResetError'));
            }
        }
        this.setState({ userPasswordLoading: true, userPasswordReset: undefined });
    };

    private _onUserPasswordResetCancel = () => {
        this.setState({
            userPasswordReset: undefined
        });
    };

    private _onUserPasswordResetClick = () => {
        this.setState({
            userPasswordReset: this.state.table?.selected
        });
    };

    private _onHelperClick = () => {
        const module: DocsUserGuide = 'management';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this._logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(ManagementUserModule));
