/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverBehaviourLightVehiclesDriverInDb,
    DriverBehaviourLightVehiclesDriverInDbFromJSON,
    DriverBehaviourLightVehiclesDriverInDbFromJSONTyped,
    DriverBehaviourLightVehiclesDriverInDbToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse
 */
export interface BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse {
    /**
     * 
     * @type {Array<DriverBehaviourLightVehiclesDriverInDb>}
     * @memberof BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse
     */
    prev: Array<DriverBehaviourLightVehiclesDriverInDb>;
    /**
     * 
     * @type {Array<DriverBehaviourLightVehiclesDriverInDb>}
     * @memberof BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse
     */
    act: Array<DriverBehaviourLightVehiclesDriverInDb>;
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse
     */
    numberOfDrivers: number;
}

export function BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSON(json: any): BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse {
    return BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSONTyped(json, false);
}

export function BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prev': ((json['prev'] as Array<any>).map(DriverBehaviourLightVehiclesDriverInDbFromJSON)),
        'act': ((json['act'] as Array<any>).map(DriverBehaviourLightVehiclesDriverInDbFromJSON)),
        'numberOfDrivers': json['number_of_drivers'],
    };
}

export function BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseToJSON(value?: BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prev': ((value.prev as Array<any>).map(DriverBehaviourLightVehiclesDriverInDbToJSON)),
        'act': ((value.act as Array<any>).map(DriverBehaviourLightVehiclesDriverInDbToJSON)),
        'number_of_drivers': value.numberOfDrivers,
    };
}


