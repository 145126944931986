import { WithLogic, withLogicContext } from 'App';
import { Confirm } from 'common/components';
import { LayoutContent } from 'common/components/Layout/Content';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ContactListTable from './components/contactListTable/ContactListTable';
import * as ContactListIcons from 'resources/images/contact-list';
import { observer } from 'mobx-react';
import ContactListDetail from './components/contactListDetail/ContactListDetail';
import ContactListActions from './components/contactListActions/ContactListActions';
import { MessageType } from 'common/components/Confirm';
import { message } from 'antd';
import { debounce } from 'debounce';
import {
    ReadOnlyContactList,
    ReadOnlyContactListTypeEnum,
    WriteOnlyContactList,
    WriteOnlyContactListTypeEnum
} from 'generated/new-main';
import { PaginationParams } from 'common/model/pagination';
import { ContactList } from 'common/model/client-contact';
import CreateContactForm from './components/createContactForm/CreateContactForm';
import { ContactListFiltersType } from './components/contactListFilters/ContactListFilters';
import { Role } from 'logic/auth';
import ContactListBar from './components/contactListBar/ContactListBar';
import { confDefault } from 'conf';

interface Props extends WithTranslation, WithLogic {}

interface State {
    lastCreated?: ReadOnlyContactList;
    editActive: boolean;
    createActive: boolean;
    removeModalOpen: boolean;
    bar: {
        filterOpen: boolean;
    };
}

const INITIAL_STATE: State = {
    editActive: false,
    createActive: false,
    removeModalOpen: false,
    bar: {
        filterOpen: false
    }
};

class ContactListModule extends Component<Props, State> {
    private _logic: Logic;
    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
        this._logic = this.props.logic;
    }

    componentDidMount() {
        this._logic.contactListLogic().init();
    }

    render() {
        return (
            <>
                <LayoutContent
                    className="management-fleet no-padding"
                    mainSizes={
                        this._logic.contactListLogic().selectedContact || this.state.createActive
                            ? { xs: 24, sm: 24, md: 18 }
                            : { xs: 24, sm: 24, md: 24 }
                    }
                    extraSizes={
                        this._logic.contactListLogic().selectedContact || this.state.createActive
                            ? [{ xs: 24, sm: 24, md: 6 }]
                            : [{ xs: 0, sm: 0, md: 0 }]
                    }
                    main={
                        <>
                            <ContactListBar
                                heading={this.props.t('ContactList.title')}
                                search={{
                                    text: this._logic.contactListLogic().textSearch
                                }}
                                onBarSearchChange={this._onBarSearchChange}
                                onBarResetClick={this._onBarResetClick}
                                onCreateNewContact={this._onCreateNewContact}
                                filterOpen={this.state.bar.filterOpen}
                                onBarFilterClick={() => {
                                    this.setState({
                                        bar: {
                                            filterOpen: !this.state.bar.filterOpen
                                        }
                                    });
                                }}
                                onFilterCancelClick={this._onFilterCancelClick}
                                onFilterConfirmClick={this._onFilterConfirmClick}
                            />
                            <ContactListTable
                                lastCreated={this.state.lastCreated}
                                onPaginationParamsChange={this._onPaginationParamsChange}
                                onRowSelect={(contact?: ReadOnlyContactList) => {
                                    if (this._logic.contactListLogic().selectedContact?.id === contact?.id) {
                                        this._logic.contactListLogic().setSelectedContact?.(undefined);
                                    } else {
                                        this._logic.contactListLogic().setSelectedContact?.(contact);
                                    }
                                    this.setState({
                                        createActive: false,
                                        editActive: false
                                    });
                                }}
                            />
                        </>
                    }
                    extra={[
                        (this._logic.contactListLogic().selectedContact || this.state.createActive) && (
                            <LayoutSidePanel
                                header={
                                    this.state.createActive
                                        ? this.props.t('ContactList.createNew')
                                        : this._logic.contactListLogic().selectedContact?.name
                                }
                                headerIcon={ContactListIcons.contactCard}
                                body={
                                    !this.state.createActive && !this.state.editActive ? (
                                        <ContactListDetail />
                                    ) : (
                                        <CreateContactForm
                                            size="small"
                                            type={this.state.editActive ? 'edit' : 'default'}
                                            initialClientContact={
                                                this.state.editActive
                                                    ? this._logic.contactListLogic().selectedContact
                                                    : undefined
                                            }
                                            countries={this._logic.contactListLogic().countryList}
                                            onSubmit={this._onSubmitContactForm}
                                            onCancel={this._onCancelContactForm}
                                        />
                                    )
                                }
                                footer={
                                    this._logic.auth().roles().includes(Role.CL_W) &&
                                    !this.state.createActive &&
                                    !this.state.editActive && (
                                        <ContactListActions
                                            onEdit={this._onEditContactListClick}
                                            onRemove={this._onRemoveContactListClick}
                                        />
                                    )
                                }
                            />
                        )
                    ]}
                />
                {this.state.removeModalOpen && (
                    <Confirm
                        loading={this._logic.contactListLogic().contactListRemoveLoading}
                        header={this.props.t('ContactList.message.deletionHeader')}
                        type={MessageType.WARNING}
                        danger
                        confirmLabel={this.props.t('common.delete')}
                        message={`${this.props.t('ContactList.message.confirmRemoval')} ${
                            this._logic.contactListLogic().selectedContact?.name
                        }`}
                        onConfirm={this._onRemoveConfirmClick}
                        onCancel={this._onRemoveCancelClick}
                    />
                )}
            </>
        );
    }

    private _onPaginationParamsChange = (pagination: PaginationParams) => {
        this.setState({
            lastCreated: undefined
        });

        this._logic.contactListLogic().onPaginationParamsChange(pagination);
    };

    private _onRemoveConfirmClick = async () => {
        try {
            await this._logic.contactListLogic().removeSelectedContactList();
            message.success(this.props.t('ContactList.message.removeSuccess'));
            this.setState({
                lastCreated: undefined
            });
            this._logic.contactListLogic().reload(true);
        } catch (err) {
            message.error(this.props.t('ContactList.message.removeError'));
            console.error(`Could not remove contact, err: ${err}`);
        } finally {
            this.setState({
                removeModalOpen: false
            });
        }
    };

    private _onRemoveCancelClick = () => {
        this.setState({
            removeModalOpen: false
        });
    };

    private _onRemoveContactListClick = () => {
        this.setState({
            removeModalOpen: true
        });
    };

    private _onEditContactListClick = () => {
        this.setState({
            createActive: false,
            editActive: true
        });
    };

    private _onFilterCancelClick = () => {
        this.setState({
            bar: {
                filterOpen: false
            }
        });
    };

    private _onBarResetClick = () => {
        const defaults = confDefault.settings.management.contactList.filter;
        this._onFilterConfirmClick({ types: defaults.types, countries: defaults.countries });
    };

    private _onFilterConfirmClick = (filters: ContactListFiltersType) => {
        this.setState({
            bar: {
                filterOpen: false
            }
        });
        this._logic.contactListLogic().onFiltersChange(filters);
    };

    private _onBarSearchChange = debounce((textSearch: string) => {
        this.setState({
            lastCreated: undefined
        });
        this._logic.contactListLogic().onTextSearchChange(textSearch);
    }, 500);

    private _onCreateNewContact = () => {
        this.setState({
            createActive: true,
            editActive: false
        });
    };

    private _onSubmitContactForm = async (value: ContactList, type: ReadOnlyContactListTypeEnum[]) => {
        const clientId = this._logic.auth().client()?.id;
        if (!clientId) {
            throw new Error('No client id in auth');
        }

        if (this.state.createActive) {
            try {
                const contact = await this._logic.contactListLogic().createContactList({
                    ...value,
                    client: clientId,
                    countryId: value.country?.id,
                    type: type as unknown as WriteOnlyContactListTypeEnum[]
                } as WriteOnlyContactList);
                this._logic.contactListLogic().setSelectedContact(contact);
                this._logic.contactListLogic().addLastCreatedContact(contact);
                this.setState({
                    lastCreated: contact
                });
                message.success(this.props.t('ContactList.message.createSuccess'));
            } catch (err) {
                message.error(this.props.t('ContactList.message.createError'));
                console.error(`Could not create contact, err: ${err}`);
            }
        }
        if (this.state.editActive) {
            try {
                const contact = await this._logic.contactListLogic().updateSelectedContactList({
                    ...value,
                    client: clientId,
                    countryId: value.country?.id,
                    type: type as unknown as WriteOnlyContactListTypeEnum[]
                } as WriteOnlyContactList);
                this.setState({
                    lastCreated: undefined
                });
                this._logic.contactListLogic().setSelectedContact(contact);
                this._logic.contactListLogic().reload();
                message.success(this.props.t('ContactList.message.editSuccess'));
            } catch (err) {
                message.error(this.props.t('ContactList.message.editError'));
                console.error(`Could not update contact, err: ${err}`);
            }
        }

        this.setState({
            createActive: false,
            editActive: false
        });

        return false;
    };

    private _onCancelContactForm = () => {
        this.setState({
            createActive: false,
            editActive: false
        });
    };
}

export default withTranslation()(withLogicContext(observer(ContactListModule)));
