import { Component, createRef } from 'react';
import { debounce } from 'debounce';
import { observer } from 'mobx-react';
import qs from 'qs';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import { LayoutContent } from 'common/components/Layout/Content';
import ColdchainTable from './components/coldchain-table';
import ColdchainBar from './components/coldchain-bar';
import ColdchainDetail from './components/coldchain-detail';
import { SearchData } from 'common/components/Search';
import { Loading } from 'common/components';
import { DateRange } from 'common/model/date-time';
import { message } from 'antd';
import { RouteNames } from 'App';
import { DATE_FORMAT, DATE_TIME_FORMAT, ZONE_RULE_NAME } from 'domain-constants';
import moment from 'moment';
import { AlarmInDatabaseWithGPSInfo } from 'generated/backend-api';
import cn from 'classnames';
import { confDefault } from 'conf';

export type RouteParams = {
    sensorId?: string;
    monitoredObjectId?: string;
};

export interface ColdchainAlertModel {
    id: string;
    alert: string;
    location: string;
    status: string;
    from: string;
    to: string;
    duration: string;
}

export interface ColdchainFilter {
    textSearch?: string;
    monitoredObjectIds?: number[];
    profilesIds?: string[];
    detailTextSearch?: string;
    showDeleted: boolean;
}

export interface ColdchainChartFilterParams {
    dateRange: DateRange;
    sensorZone?: number;
    serialNumbers?: string[];
    dangerPoints: boolean;
}

export interface ColdchainAlertFilterParams {
    alerting: boolean;
    resolved: boolean;
}

export interface ColdchainAlarmProfile {
    above_temperature_threshold?: number;
    alarm_timer_seconds?: number;
    below_temperature_threshold: number;
    profile_id?: string;
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
    logic: Logic;
}

interface State {
    bar: {
        search?: boolean;
        filter?: boolean;
        active?: boolean;
    };
    search?: SearchData;
    detailFilter: {
        search?: SearchData;
    };
    graphFilter: ColdchainChartFilterParams;
    map: {
        open: boolean;
    };
    filter: {
        open: boolean;
        vehiclesChecked: string[];
        trailersChecked: string[];
        profilesChecked: string[];
        showDeleted: boolean;
    };
    alertFilter: ColdchainAlertFilterParams;
    helper?: {
        content: string;
    };
    params: RouteParams;
}

const defaultBar = {
    filter: false
};

class ColdchainModule extends Component<Props, State> {
    private _logic: Logic;
    tableRowRef: React.RefObject<HTMLTableRowElement>;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const params: RouteParams = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });
        const settings = this._logic.statisticsColdchain().settings();
        this.tableRowRef = createRef();

        this.state = {
            bar: { ...defaultBar },
            filter: {
                open: false,
                vehiclesChecked: [],
                trailersChecked: settings?.trailers,
                profilesChecked: settings?.profiles,
                showDeleted: false
            },
            alertFilter: {
                alerting: true,
                resolved: true
            },
            detailFilter: {},
            graphFilter: {
                dateRange: {
                    start: moment().subtract(1, 'day').format(DATE_TIME_FORMAT),
                    end: moment().format(DATE_TIME_FORMAT)
                },
                dangerPoints: true
            },
            map: {
                open: false
            },
            params
        };
    }

    async componentDidMount() {
        try {
            await this._logic.coldchainLogic().init();
        } catch (err) {
            message.error(this.props.t('Coldchain.error.loadingColdchainError'));
        }

        if (this.state.params.sensorId) {
            const sensor = this._logic
                .coldchainLogic()
                .temperatureSensors.find(sensor => sensor.id === this.state.params.sensorId);

            if (sensor) {
                this._logic
                    .coldchainLogic()
                    .setColdchainDetail(
                        this.state.graphFilter.dateRange,
                        sensor.monitoredObject,
                        sensor.sensorZone,
                        this.state.params.sensorId
                    );
            }

            this.setState(state => ({
                graphFilter: {
                    ...state.graphFilter,
                    sensorZone: sensor?.sensorZone,
                    serialNumbers: this._logic
                        .coldchainLogic()
                        .temperatureSensors?.filter(
                            d => d.monitoredObject === sensor?.monitoredObject && d.sensorZone === sensor?.sensorZone
                        )
                        .map(d => d.serialNumber)
                }
            }));
        }

        if (this.state.params.monitoredObjectId && this.tableRowRef?.current) {
            this.tableRowRef?.current?.scrollIntoView({ block: 'start' });
        }
    }

    componentWillUnmount() {
        this._logic.coldchainLogic().reset();
    }

    render() {
        const zones = [
            ...new Set(
                this._logic
                    .coldchainLogic()
                    .detailedTemperatureSensors?.map(d => d.sensorZone)
                    .filter(Number)
            )
        ] as number[];

        return (
            <LayoutContent
                className="statistics-coldchain"
                main={
                    <>
                        {this._logic.coldchainLogic().coldchainDetail ? (
                            this._logic.coldchainLogic().coldchainLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    <ColdchainBar
                                        heading={this._logic.coldchainLogic().coldchainDetail?.registrationNumber ?? ''}
                                        demoMode={this._logic.demo().isActive}
                                        filter={this.state.filter}
                                        className="table-bar-coldchain-detail"
                                        backButton={{
                                            onClick: this._onBarBackUrlClick,
                                            title: this.state.params.sensorId
                                                ? this.props.t('common.backTo.mapView')
                                                : this.props.t('common.backTo.coldChain')
                                        }}
                                    />
                                    <ColdchainDetail
                                        className={cn({
                                            'coldchain-detail-map-opened': this.state.map.open
                                        })}
                                        search={this.state.detailFilter.search}
                                        detailData={this._logic.coldchainLogic().coldchainDetail}
                                        temperatureSensorsData={this._logic.coldchainLogic().temperatureSensorsData}
                                        temperatureSensorsDataLoading={
                                            this._logic.coldchainLogic().coldchainDetailLoading
                                        }
                                        temperatureSensors={this._logic.coldchainLogic().detailedTemperatureSensors}
                                        temperatureSensorsAlarmsData={this._detailFiltered(
                                            this._logic.coldchainLogic().temperatureSensorsAlarmsData ?? []
                                        )}
                                        temperatureProfiles={this._logic
                                            .coldchainLogic()
                                            .temperatureProfiles?.filter(
                                                d =>
                                                    d.eventRule.name ===
                                                    ZONE_RULE_NAME +
                                                        this._logic.coldchainLogic().coldchainDetail?.sensorZone
                                            )}
                                        chartFilter={this.state.graphFilter}
                                        alertFilter={this.state.alertFilter}
                                        map={this.state.map}
                                        zones={zones}
                                        temperatureLogLoading={this._logic.coldchainLogic().temperatureLogLoading}
                                        onChartFilterChange={this._onChartFilterChange}
                                        onAlertFilterChange={this._onAlertFilterChange}
                                        onChartMapSwitchChange={this._onChartMapSwitchChange}
                                        onChartLegendDangerPointsToggle={this._onChartLegendDangerPointsToggle}
                                        onAlertTableRowClick={this._onAlertTableRowClick}
                                        onSearchChange={this._onDetailSearchChange}
                                        onExportClick={this._onExportClick}
                                    />
                                </>
                            )
                        ) : (
                            <>
                                <ColdchainBar
                                    search={this.state.search}
                                    heading={this.props.t('NavBar.coldchain')}
                                    demoMode={this._logic.demo().isActive}
                                    filter={this.state.filter}
                                    onBarFilterClick={this._onBarFilterClick}
                                    onBarSearchChange={this._onBarSearchChange}
                                    onBarResetClick={this._onBarResetClick}
                                    onFilterConfirmClick={this._onFilterConfirmClick}
                                    onFilterCancelClick={this._onFilterCancelClick}
                                />
                                <ColdchainTable
                                    tableRowRef={this.tableRowRef}
                                    scrolledToId={this.state.params.monitoredObjectId}
                                    loading={this._logic.coldchainLogic().coldchainLoading}
                                    data={this._logic.coldchainLogic().temperatureSensors}
                                    vehicles={this._logic.coldchainLogic().vehicles}
                                    trailers={this._logic.coldchainLogic().trailers}
                                    profiles={this._logic.coldchainLogic().profiles}
                                    onRowFleetClick={this._onRowFleetClick}
                                    onRowDetailClick={this._onRowDetailClick}
                                />
                            </>
                        )}
                    </>
                }
                mainSizes={{ md: 24, sm: 24, xs: 24 }}
            />
        );
    }

    private _detailFiltered = (data: AlarmInDatabaseWithGPSInfo[]): AlarmInDatabaseWithGPSInfo[] => {
        let filteredData = data;

        if (!this.state.alertFilter.alerting) {
            filteredData = filteredData.filter(d => !moment(d.end).isAfter(moment()));
        }
        if (!this.state.alertFilter.resolved) {
            filteredData = filteredData.filter(d => moment(d.end).isAfter(moment()) || !d.end);
        }

        return filteredData;
    };

    private _onRowFleetClick = (monitoredObjectId: number): void => {
        this.props.history.push({
            pathname: RouteNames.SETTINGS_FLEET,
            search: qs.stringify({
                monitoredObjectId
            })
        });
    };

    private _onRowDetailClick = (
        monitoredObjectId: number,
        sensorZone?: number,
        sensorSerialNumbers?: string[]
    ): void => {
        try {
            this._logic.coldchainLogic().setColdchainDetail(
                {
                    start: moment().subtract(1, 'day').format(DATE_TIME_FORMAT),
                    end: moment().format(DATE_TIME_FORMAT)
                },
                monitoredObjectId,
                sensorZone,
                sensorSerialNumbers?.length ? sensorSerialNumbers?.[0] : undefined
            );
        } catch (err) {
            message.error(this.props.t('Coldchain.error.loadingSensorsDataError'));
        }
        this.setState(state => ({
            graphFilter: {
                ...state.graphFilter,
                sensorZone: sensorZone,
                serialNumbers: sensorSerialNumbers,
                dateRange: {
                    start: moment().subtract(1, 'day').format(DATE_TIME_FORMAT),
                    end: moment().format(DATE_TIME_FORMAT)
                }
            },
            filter: {
                ...state.filter,
                open: false
            }
        }));
        this.props.history.push({
            search: qs.stringify({
                monitoredObjectId
            })
        });
    };

    private _onChartMapSwitchChange = (open: boolean): void => {
        this.setState({
            map: {
                open
            }
        });
    };

    private _onChartLegendDangerPointsToggle = (): void => {
        this.setState(state => ({
            graphFilter: {
                ...state.graphFilter,
                dangerPoints: !state.graphFilter.dangerPoints
            }
        }));
    };

    private _onChartFilterChange = (graphFilter: ColdchainChartFilterParams): void => {
        const selectedSensors =
            graphFilter.serialNumbers ??
            this._logic
                .coldchainLogic()
                .detailedTemperatureSensors?.filter(d => d.sensorZone === graphFilter.sensorZone)
                .map(d => d.serialNumber);
        try {
            this._logic.coldchainLogic().setColdchainDetail(
                {
                    start: moment(graphFilter.dateRange.start, DATE_TIME_FORMAT).isBefore(moment())
                        ? graphFilter.dateRange.start
                        : moment().format(DATE_TIME_FORMAT),
                    end: moment(graphFilter.dateRange.end, DATE_TIME_FORMAT).isBefore(moment())
                        ? graphFilter.dateRange.end
                        : moment().format(DATE_TIME_FORMAT)
                },
                this._logic.coldchainLogic().coldchainDetail?.monitoredObjectId,
                graphFilter.sensorZone,
                undefined
            );
        } catch (err) {
            message.error(this.props.t('Coldchain.error.loadingSensorsDataError'));
        }
        this.props.history.push({
            search: ''
        });
        this.setState({
            graphFilter: {
                ...graphFilter,
                serialNumbers: selectedSensors
            }
        });
    };

    private _onAlertFilterChange = (alertFilter: ColdchainAlertFilterParams): void => {
        this.setState({
            alertFilter
        });
    };

    private _onFilterConfirmClick = (
        vehiclesChecked: string[],
        trailersChecked: string[],
        profilesChecked: string[],
        showDeleted: boolean
    ): void => {
        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    open: false,
                    vehiclesChecked,
                    trailersChecked,
                    profilesChecked,
                    showDeleted
                }
            }),
            () => this._filterChange()
        );
    };

    private _onFilterCancelClick = (): void => {
        this.setState(state => ({
            filter: {
                ...state.filter,
                open: false
            }
        }));
    };

    private _onBarResetClick = (): void => {
        const defaults = confDefault.settings.statistics.coldChain.filter;
        this._onFilterConfirmClick(defaults.vehicles, defaults.trailers, defaults.profiles, false);
    };

    private _onBarFilterClick = () => {
        this.setState(state => ({
            filter: {
                ...state.filter,
                open: !state.filter.open
            }
        }));
    };

    private _onAlertTableRowClick = (): void => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_JOURNEYS_ACTIVITY,
            search: qs.stringify({
                vehicleId: this._logic.coldchainLogic().coldchainDetail?.monitoredObjectId,
                startDate: moment(this._logic.coldchainLogic().coldchainDetail?.dateRange.start).format(DATE_FORMAT),
                startEnd: moment(this._logic.coldchainLogic().coldchainDetail?.dateRange.end).format(DATE_FORMAT)
            })
        });
    };

    private _filterChange = () => {
        const filter = this._filters();

        this._logic.statisticsColdchain().setSettings({
            vehicles: this.state.filter.trailersChecked,
            trailers: this.state.filter.trailersChecked,
            profiles: this.state.filter.profilesChecked
        });

        this._logic.coldchainLogic().setColdchainFilter({
            ...filter,
            monitoredObjectIds: filter.monitoredObjects,
            profilesIds: filter.profiles,
            textSearch: this.state.search?.text,
            detailTextSearch: this.state.detailFilter.search?.text
        });
        try {
            this._logic.coldchainLogic().reloadColdchain();
        } catch (err) {
            message.error(this.props.t('Coldchain.error.loadingSensorsDataError'));
        }
    };

    private _filters = () => {
        return {
            monitoredObjects:
                this.state.filter.vehiclesChecked.length > 0 || this.state.filter.trailersChecked.length > 0
                    ? this.state.filter.vehiclesChecked.concat(this.state.filter.trailersChecked).map(d => Number(d))
                    : undefined,
            profiles: this.state.filter.profilesChecked.length > 0 ? this.state.filter.profilesChecked : undefined,
            showDeleted: this.state.filter.showDeleted
        };
    };

    private _onBarSearchChange = debounce((searchText: string) => {
        this.setState(
            {
                search: {
                    text: searchText
                }
            },
            () => {
                this._filterChange();
            }
        );
    }, 500);

    private _onBarBackUrlClick = () => {
        if (this.state.params.sensorId) {
            this.props.history.push({
                pathname: RouteNames.TRACKING,
                search: qs.stringify({
                    vehicleId: this.state.params.monitoredObjectId
                })
            });
        } else {
            this.props.history.push({
                search: ''
            });
            this._logic.coldchainLogic().cancelDetail();
        }
    };

    private _onDetailSearchChange = debounce((searchText: string) => {
        this.setState({
            detailFilter: {
                search: {
                    text: searchText
                }
            }
        });
    }, 500);

    private _onExportClick = (monitoredObjectId?: number, dateRange?: DateRange) => {
        if (monitoredObjectId && dateRange?.start && dateRange.end) {
            this._logic.coldchainLogic().getTemperatureLog(monitoredObjectId, dateRange);
        }
    };

    setColdChainSettings = (settings: { vehicles: string[]; trailers: string[]; profiles: string[] }) => {
        const originalSettings = this._logic.settings().getProp('statistics').coldChain.filter;
        const modifiedSettings = {
            ...originalSettings,
            trailers: settings.trailers ?? originalSettings.trailers,
            profiles: settings.profiles ?? originalSettings.profiles
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProps().statistics,
            coldChain: {
                filter: modifiedSettings
            }
        });
    };
}

export default withTranslation()(withRouter(observer(ColdchainModule)));
