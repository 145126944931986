import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PlannerContentContainer, { PlannerContentContainerProps } from '../PlannerContentContainer';
import { Col, message, Row } from 'antd';
import { TransportEventRule, TransportPlace } from 'generated/backend-api';
import { ColdchainEditProfile, ColdchainIcon, ItemAdd, ItemInfo, PlusCircle } from 'resources/images/planner';
import ColdchainZone from './ColdchainZone';
import qa from 'qa-selectors';
import { Button, Checkbox, Confirm, Select, Tooltip } from 'common/components';
import { chassis } from 'resources/images/coldchain';
import cn from 'classnames';
import { searched } from 'common/utils/search';
import { WithLogic } from 'App';
import ColdchainProfileForm, { ColdchainFormEditType, ColdchainProfileData } from 'common/forms/ColdchainProfileForm';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MessageType } from 'common/components/Confirm';
import { observer } from 'mobx-react';
import EditItem from '../EditItem';
import ColdchainProfileInfo from './ColdchainProfileInfo';
import { COLD_CHAIN_PROFILE_ID, ZONE_RULE_NAME } from 'domain-constants';
import { ColdchainTemperatureSensorModel } from 'modules/statistics/modules/coldchain/coldchain-logic';

interface Props extends PlannerContentContainerProps, WithLogic {
    transportPlace?: TransportPlace;
    readonly?: boolean;
    sensors?: ColdchainTemperatureSensorModel[];
}

const ColdchainContainer = (props: Props) => {
    const _loadZone = (): ColdchainProfileData[] => {
        let zonesData: ColdchainProfileData[] = [];

        let lastZone = 1;
        props.transportPlace?.eventRules
            ?.filter(r => r.name?.indexOf(ZONE_RULE_NAME) === 0)
            .forEach((r: TransportEventRule) => {
                const planner = props.logic.plannerLogic();
                const zone = parseInt(r.name!.replace(ZONE_RULE_NAME, ''));
                for (let i = lastZone; i < zone; i++) {
                    zonesData.push({ id: undefined });
                }
                lastZone = zone + 1;
                const rule = {
                    id: planner.getPlaceEventRuleConfigValue(r, COLD_CHAIN_PROFILE_ID),
                    name: planner.availableColdchainProfiles?.find(
                        p => p.id === planner.getPlaceEventRuleConfigValue(r, COLD_CHAIN_PROFILE_ID)
                    )?.name,
                    aboveTemperatureThreshold: planner.getPlaceEventRuleConfigValue(r, 'above_temperature_threshold'),
                    belowTemperatureThreshold: planner.getPlaceEventRuleConfigValue(r, 'below_temperature_threshold'),
                    alarmTimerSeconds: planner.getPlaceEventRuleConfigValue(r, 'alarm_timer_seconds'),
                    aboveTemperatureThresholdUse:
                        planner.getPlaceEventRuleConfigValue(r, 'above_temperature_threshold') !== undefined,
                    belowTemperatureThresholdUse:
                        planner.getPlaceEventRuleConfigValue(r, 'below_temperature_threshold') !== undefined,
                    alarmTimerSecondsUse: planner.getPlaceEventRuleConfigValue(r, 'alarm_timer_seconds') !== undefined
                };
                zonesData.push(rule as ColdchainProfileData);
            });

        const monitoredObjectZonesLength =
            props.logic
                .plannerLogic()
                .availableTrailers.find(t => t.data?.id === props.logic.plannerLogic().selectedTrailerId)
                ?.sensors?.map(s => s.sensorZone)
                ?.reduce((a, b) => Math.max(a ?? 1, b ?? 1)) ?? 1;

        for (let i = zonesData.length; i < monitoredObjectZonesLength; i++) {
            zonesData.push({
                id: undefined
            });
        }

        return zonesData;
    };

    const [rerender, setRerender] = useState(0);
    const [zones, setZones] = useState(_loadZone());
    const [selected, setSelected] = useState(-1);
    const [edited, setEdited] = useState(false);
    const [editType, setEditType] = useState<ColdchainFormEditType>('new');
    const [profileValues, setProfileValues] = useState<ColdchainProfileData | undefined>(undefined);
    const [profilesList, setProfilesList] = useState<ColdchainProfileData[] | undefined>(undefined);
    const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleteKey, setDeleteKey] = useState('');
    const { t } = props;

    const handleSelectZoneClick = (state: boolean, index: number) => {
        if (!props.readonly) {
            setEdited(false);
            _loadProfilesList();
            setSelected(state ? index : -1);
        }
    };

    const handleAddZoneClick = () => {
        setSelected(-1);
        if (zones.length < 3) {
            let data = zones.slice();
            data.push({
                name: undefined
            });
            setZones(data);
        }
    };

    const handleDeleteZoneClick = (index: number) => {
        setEdited(false);
        let data = zones.slice();
        if (data.length > 1) {
            data.splice(index, 1);
            setSelected(-1);
        } else {
            if (data.length > 0) {
                data[0] = {
                    id: undefined
                };
            }
        }
        setZones(data);
        _saveColdchain(data);
    };

    const handleProfileFormCancelClick = () => {
        setEdited(false);
    };

    const handleProfileFormSubmitClick = async (values: ColdchainProfileData, type: ColdchainFormEditType) => {
        setEdited(false);
        switch (type) {
            case 'new':
                values.id = undefined;
                await _createColdchainProfile(values);
                break;
            case 'update':
                if (props.logic.plannerLogic().getColdchainProfiles(values.id, props.transportPlace).length > 1) {
                    if (!(props.logic.plannerLogic().showColdchainUpdateConfirm ?? true)) {
                        await _updateColdchainProfile(values, true);
                        props.logic.plannerLogic().updateColdchainProfiles(values);
                        setRerender(rerender + 1);
                    } else {
                        setProfileValues(values);
                        setConfirmUpdateOpen(true);
                    }
                } else {
                    await _updateColdchainProfile(values, true);
                    setRerender(rerender + 1);
                }

                break;
            case 'saveAs':
                values.id = undefined;
                await _createColdchainProfile(values, true);
                _saveColdchain();
                setRerender(rerender + 1);
                break;
        }
    };

    const _loadProfilesList = async (update: boolean = false) => {
        if (profilesList === undefined || update) {
            props.logic.plannerLogic().availableColdchainProfilesLoading = true;
            let list = props.logic.plannerLogic().availableColdchainProfiles.slice();
            for (let i = 0; i < zones.length; i++) {
                const zone = zones[i];
                if (zone.id) {
                    const exists = list.find(p => p.id === zone.id);
                    if (!exists) {
                        const profile = await props.logic.plannerLogic().getColdchainProfile(zone.id);
                        if (profile) {
                            list.push({
                                ...props.logic.plannerLogic().profileToColdchainProfile(profile),
                                deleted: true
                            });
                        }
                    }
                }
            }
            setProfilesList(
                list.sort((a, b) => ((a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '') ? 1 : -1))
            );
            props.logic.plannerLogic().availableColdchainProfilesLoading = false;
        }
    };

    const _onProfileSelect = (value: string) => {
        if (value === '') {
            zones[selected] = {
                id: undefined
            };
            _saveColdchain();
            setRerender(rerender + 1);
        } else if (value !== 'newProfile') {
            const profile = profilesList?.find(profile => profile.id === value);
            if (profile) {
                zones[selected] = profile;
            }
            _saveColdchain();
            setRerender(rerender + 1);
        } else {
            setEditType('new');
            setEdited(true);
        }
    };

    const _onEditProfileClick = () => {
        setEditType('update');
        setEdited(true);
    };

    const _onUpdateCheckboxClick = (event: CheckboxChangeEvent) => {
        props.logic.plannerLogic().showColdchainUpdateConfirm = !event.target.checked;
    };

    const _onUpdateConfirmCancel = () => {
        setConfirmUpdateOpen(false);
    };

    const _onUpdateConfirmConfirm = async () => {
        setConfirmUpdateOpen(false);
        if (profileValues) {
            await _updateColdchainProfile(profileValues, true);
            props.logic.plannerLogic().updateColdchainProfiles(profileValues);
        }
    };

    const _onDeleteConfirmCancel = () => {
        setConfirmDeleteOpen(false);
    };

    const _onDeleteConfirmConfirm = async () => {
        setConfirmDeleteOpen(false);
        _deleteColdchainProfile(deleteKey);
    };

    const _createColdchainProfile = async (model: ColdchainProfileData, updateSelected: boolean = false) => {
        try {
            const result = await props.logic.plannerLogic().createColdchainProfile(model);
            message.success(props.t('PlannerColdchain.message.createSuccess'));
            _loadProfilesList(true);
            if (result && updateSelected && selected > -1) {
                const profile = props.logic.plannerLogic().profileToColdchainProfile(result);
                zones[selected] = profile;
            }
        } catch (err) {
            console.error(`Could not created cold chain profile, err: ${err}`);
            message.error(props.t('PlannerColdchain.message.createError'));
        }
    };

    const _updateColdchainProfile = async (
        model: ColdchainProfileData,
        updateSelected: boolean = false
    ): Promise<ColdchainProfileData | undefined> => {
        try {
            const result = await props.logic.plannerLogic().updateColdchainProfile(model);
            message.success(props.t('PlannerColdchain.message.updateSuccess'));
            _loadProfilesList(true);
            if (result) {
                const profile = props.logic.plannerLogic().profileToColdchainProfile(result);
                if (updateSelected && selected > -1) {
                    zones[selected] = profile;
                }
                const newZones = zones.map(zone => (zone.id === profile.id ? profile : zone));
                setZones(newZones);
                _saveColdchain(newZones);
                return profile;
            } else {
                return undefined;
            }
        } catch (err) {
            console.error(`Could not update cold chain profile, err: ${err}`);
            message.error(props.t('PlannerColdchain.message.updateError'));
        }
        return undefined;
    };

    const _deleteColdchainProfile = async (id: string) => {
        try {
            const result = await props.logic.plannerLogic().deleteColdchainProfile(id);
            if (result) {
                message.success(props.t('PlannerColdchain.message.deleteSuccess'));
                _loadProfilesList(true);
            } else {
                message.error(props.t('PlannerColdchain.message.deleteError'));
            }
        } catch (err) {
            console.error(`Could not delete cold chain profile, err: ${err}`);
            message.error(props.t('PlannerColdchain.message.deleteError'));
        }
    };

    const _onProfileNameChange = (value: string, key: number | string) => {
        const profile = props.logic.plannerLogic().availableColdchainProfiles.find(p => p.id === String(key));
        if (profile) {
            profile.name = value;
            _updateColdchainProfile(profile);
            if (selected > -1) {
                if (zones[selected].id === key) {
                    zones[selected].name = value;
                }
            }
        }
    };

    const _onDeleteProfileClick = (key: string | number | undefined) => {
        setDeleteKey(String(key));
        setConfirmDeleteOpen(true);
    };

    const _saveColdchain = (data: ColdchainProfileData[] = zones) => {
        const planner = props.logic.plannerLogic();
        // delete zones
        for (let i = 1; i < 4; i++) {
            planner.removePlaceEventRule(props.transportPlace!, ZONE_RULE_NAME + String(i));
        }

        // add zones
        data.forEach((profile: ColdchainProfileData, index: number) => {
            if (profile.id) {
                planner.addPlaceEventRule(props.transportPlace!, ZONE_RULE_NAME + String(index + 1), [
                    { name: COLD_CHAIN_PROFILE_ID, value: profile.id },
                    { name: 'above_temperature_threshold', value: profile.aboveTemperatureThreshold },
                    { name: 'below_temperature_threshold', value: profile.belowTemperatureThreshold },
                    { name: 'alarm_timer_seconds', value: profile.alarmTimerSeconds }
                ]);
            }
        });
    };

    const selectSpan = selected > -1 && zones.length > 1 ? 2 : 0;
    const zoneContainers = zones.map((zone: ColdchainProfileData, index: number) => {
        let span = 24;
        switch (zones.length) {
            case 2:
                span = 12 - selectSpan;
                break;
            case 3:
                span = 8 - selectSpan;
                break;
        }
        return (
            <Col key={`zonecontainer_${index}`} span={index === selected ? span + selectSpan * zones.length : span}>
                <ColdchainZone
                    key={`zone_${index}`}
                    data={zone}
                    index={index}
                    selected={index === selected}
                    showDelete={
                        !props.readonly &&
                        (index > 0 || zones.length > 1 || (zones.length === 1 && zone.id !== undefined))
                    }
                    onSelect={handleSelectZoneClick}
                    onDelete={handleDeleteZoneClick}
                />
            </Col>
        );
    });

    return (
        <PlannerContentContainer
            {...props}
            className="coldchain-container"
            beforeTitle={<img className="coldchain-icon" src={ColdchainIcon} alt="coldchain-icon" />}
            title={t('PlannerColdchain.title')}
            expandable={false}
        >
            <div className="coldchain-container-zones-bg" />
            <Row className="coldchain-container-zones">
                {props.readonly !== true && zones.length < 3 && (
                    <Col span={3}>
                        <Button
                            type="text"
                            className="coldchain-container-add-zone"
                            onClick={handleAddZoneClick}
                            qa={qa.planner.coldchain.btnAddZone}
                        >
                            <p>
                                <img src={PlusCircle} alt="add-zone" />
                                <br />
                                {t('PlannerColdchain.addZone')}
                            </p>
                        </Button>
                    </Col>
                )}
                <Col span={zones.length < 3 && !props.readonly ? 21 : 24}>
                    <Row className="coldchain-container-zones-zones" gutter={2}>
                        {zoneContainers}
                    </Row>
                </Col>
            </Row>
            <Row className="coldchain-container-profile">
                <Row className="coldchain-container-chassis" justify="end">
                    <img src={chassis} alt="coldchain-chassis" />
                </Row>
                {selected > -1 && (
                    <>
                        <Row
                            className={cn(
                                `coldchain-container-selected coldchain-container-selected-${selected}-${zones.length}`,
                                {
                                    'coldchain-container-selected-last': selected === zones.length - 1,
                                    'coldchain-container-selected-first': selected === 0 && zones.length === 3,
                                    'coldchain-container-selected-center': selected === 1 && zones.length === 3
                                }
                            )}
                        >
                            {(zones.length < 3 || (zones.length === 3 && selected > 0)) && (
                                <Row className="left-cover" />
                            )}
                            {selected !== zones.length - 1 && <Row className="right-cover" />}
                            {zones.length < 3 && (
                                <Col data-type={selected === 0 ? 'prev' : 'button'} span={3}>
                                    {selected === 0 && <div />}
                                </Col>
                            )}
                            <Col span={zones.length < 3 ? 21 : 24}>
                                <Row className="coldchain-container-selected-zones" gutter={2}>
                                    {zones.map((zone, index) => {
                                        let span = 24;
                                        switch (zones.length) {
                                            case 2:
                                                span = 12 - selectSpan;
                                                break;
                                            case 3:
                                                span = 8 - selectSpan;
                                                break;
                                        }

                                        let type = '';
                                        if (index === selected - 1) {
                                            type = 'prev';
                                        } else if (index === selected + 1) {
                                            type = 'next';
                                        } else if (index === selected) {
                                            type = 'selected';
                                            if (index === zones.length - 1) {
                                                type += '-last';
                                            } else if (index === 0 && (zones.length === 1 || zones.length === 3)) {
                                                type += '-first';
                                            }
                                        }

                                        return (
                                            <Col
                                                data-type={type}
                                                span={index === selected ? span + selectSpan * zones.length : span}
                                            >
                                                {type === 'prev' && <div className={`prev-${index}-${zones.length}`} />}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            className={cn(
                                `coldchain-container-selected-container coldchain-container-selected-container-${selected}-${zones.length}`,
                                {
                                    'coldchain-container-selected-container-last': selected === zones.length - 1,
                                    'coldchain-container-selected-container-first': selected === 0 && zones.length === 3
                                }
                            )}
                        >
                            {!edited && (
                                <Row>
                                    <Col flex="auto">
                                        <Select
                                            className={cn('coldchain-profile-select')}
                                            size="middle"
                                            onSelect={_onProfileSelect}
                                            value={zones?.[selected].id ?? ''}
                                            showSearch
                                            filterOption={(input, option) => searched(input, String(option?.label))}
                                            disabled={props.logic.plannerLogic().availableColdchainProfilesLoading}
                                            loading={props.logic.plannerLogic().availableColdchainProfilesLoading}
                                            qa={qa.planner.coldchain.selectProfile}
                                            showArrow={false}
                                        >
                                            <Select.Option
                                                value="newProfile"
                                                key="newProfile"
                                                className="add-new-profile"
                                                data-qa={qa.planner.coldchain.newProfie}
                                                disabled={props.logic.demo().isActive}
                                                label={t('PlannerColdchain.newProfile')}
                                            >
                                                <img src={ItemAdd} alt="add-new-profile" />
                                                {t('PlannerColdchain.newProfile')}
                                            </Select.Option>
                                            <Select.Option
                                                value=""
                                                key="noProfile"
                                                className="no-profile"
                                                data-qa={qa.planner.coldchain.newProfie}
                                                label={t('PlannerColdchain.noProfile')}
                                            >
                                                {t('PlannerColdchain.noProfile')}
                                            </Select.Option>
                                            {profilesList?.map(profile => {
                                                return (
                                                    <Select.Option
                                                        key={profile.id}
                                                        value={profile.id ?? ''}
                                                        data-qa={qa.coldChain.profileForm.listItem}
                                                        label={profile.name}
                                                    >
                                                        <EditItem
                                                            editable={!profile.deleted}
                                                            autoSelectEdited={true}
                                                            showDelete={!profile.deleted}
                                                            showEdit={!profile.deleted}
                                                            label={profile.name}
                                                            labelKey={profile.id}
                                                            qaEdit={qa.coldChain.profileForm.editButton}
                                                            qaDelete={qa.coldChain.profileForm.deleteButton}
                                                            onButtonSaveClick={_onProfileNameChange}
                                                            onButtonDeleteClick={_onDeleteProfileClick}
                                                        >
                                                            <Row align="middle" className="coldchain-profile-item">
                                                                <Col className="coldchain-profile-item-rn">
                                                                    {profile.name}
                                                                </Col>
                                                                <Col className="edit-item-button">
                                                                    <Tooltip
                                                                        title={<ColdchainProfileInfo data={profile} />}
                                                                        placement="right"
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            className="edit-item-action coldchain-profile-info"
                                                                            icon={
                                                                                <img
                                                                                    src={ItemInfo}
                                                                                    alt="coldchain-profile-info"
                                                                                />
                                                                            }
                                                                            data-qa={
                                                                                qa.coldChain.profileForm.infoButton
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>
                                                        </EditItem>
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Col>
                                    {zones[selected].id && !zones[selected].deleted && (
                                        <Col>
                                            <Button
                                                className="edit-profile-button"
                                                disabled={props.logic.plannerLogic().availableColdchainProfilesLoading}
                                                icon={<img src={ColdchainEditProfile} alt="coldchain-edit-profile" />}
                                                onClick={_onEditProfileClick}
                                                qa={qa.planner.coldchain.btnEditProfile}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )}
                            {edited && (
                                <ColdchainProfileForm
                                    type={editType}
                                    initialValues={editType === 'new' || selected === -1 ? undefined : zones[selected]}
                                    onCancel={handleProfileFormCancelClick}
                                    onSubmit={handleProfileFormSubmitClick}
                                />
                            )}
                        </Row>
                    </>
                )}
            </Row>
            {confirmUpdateOpen && (
                <Confirm
                    type={MessageType.WARNING}
                    confirmLabel={t('PlannerColdchain.confirmUpdateSubmit')}
                    header={t('PlannerColdchain.confirmUpdateTitle')}
                    message={
                        <>
                            {t('PlannerColdchain.confirmUpdateMessage')}
                            <br />
                            <Checkbox onChange={_onUpdateCheckboxClick}>
                                {t('PlannerColdchain.confirmUpdateCheckbox')}
                            </Checkbox>
                        </>
                    }
                    onCancel={_onUpdateConfirmCancel}
                    onConfirm={_onUpdateConfirmConfirm}
                />
            )}
            {confirmDeleteOpen && (
                <Confirm
                    type={MessageType.WARNING}
                    header={t('PlannerColdchain.confirmDeleteTitle')}
                    message={t('PlannerColdchain.confirmDeleteMessage', {
                        name:
                            props.logic.plannerLogic().availableColdchainProfiles.find(p => p.id === deleteKey)?.name ??
                            ''
                    })}
                    onCancel={_onDeleteConfirmCancel}
                    onConfirm={_onDeleteConfirmConfirm}
                />
            )}
        </PlannerContentContainer>
    );
};

export default withTranslation()(observer(ColdchainContainer));
