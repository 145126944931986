/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MainDataVersion,
    MainDataVersionFromJSON,
    MainDataVersionFromJSONTyped,
    MainDataVersionToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {MainDataVersion}
     * @memberof InlineResponse200
     */
    version: MainDataVersion;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof InlineResponse200
     */
    data: Array<Tag>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': MainDataVersionFromJSON(json['version']),
        'data': ((json['data'] as Array<any>).map(TagFromJSON)),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': MainDataVersionToJSON(value.version),
        'data': ((value.data as Array<any>).map(TagToJSON)),
    };
}


