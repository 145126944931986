/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrRemoveUserToUserGroupBody,
    AddOrRemoveUserToUserGroupBodyFromJSON,
    AddOrRemoveUserToUserGroupBodyToJSON,
    AddOrRemoveUserToUserRoleBody,
    AddOrRemoveUserToUserRoleBodyFromJSON,
    AddOrRemoveUserToUserRoleBodyToJSON,
    InlineResponse20046,
    InlineResponse20046FromJSON,
    InlineResponse20046ToJSON,
    InlineResponse20047,
    InlineResponse20047FromJSON,
    InlineResponse20047ToJSON,
    LoginUserRequest,
    LoginUserRequestFromJSON,
    LoginUserRequestToJSON,
    LoginUserResponse,
    LoginUserResponseFromJSON,
    LoginUserResponseToJSON,
    NumberList,
    NumberListFromJSON,
    NumberListToJSON,
    ReadOnlyUser,
    ReadOnlyUserFromJSON,
    ReadOnlyUserToJSON,
    ReadOnlyUserBackendInfo,
    ReadOnlyUserBackendInfoFromJSON,
    ReadOnlyUserBackendInfoToJSON,
    ReadOnlyUserMini,
    ReadOnlyUserMiniFromJSON,
    ReadOnlyUserMiniToJSON,
    ReadOnlyUserNested,
    ReadOnlyUserNestedFromJSON,
    ReadOnlyUserNestedToJSON,
    RefreshTokenRequest,
    RefreshTokenRequestFromJSON,
    RefreshTokenRequestToJSON,
    UserBLEConfig,
    UserBLEConfigFromJSON,
    UserBLEConfigToJSON,
    UsersSimpleInfo,
    UsersSimpleInfoFromJSON,
    UsersSimpleInfoToJSON,
    WriteOnlyUser,
    WriteOnlyUserFromJSON,
    WriteOnlyUserToJSON,
} from '../models';

export interface UserAddUserToUserGroupRequest {
    ssoId: string;
    data: AddOrRemoveUserToUserGroupBody;
}

export interface UserAddUserToUserRoleRequest {
    ssoId: string;
    data: AddOrRemoveUserToUserRoleBody;
}

export interface UserCreateRequest {
    data: WriteOnlyUser;
    onConflict?: string;
    skipSso?: boolean;
}

export interface UserDeleteRequest {
    ssoId: string;
}

export interface UserEwDriverRegistrationLinkCreateRequest {
    ssoId: string;
    sendSms?: boolean;
}

export interface UserGetBleConfigRequest {
    ssoId: string;
}

export interface UserGetUsersSimpleInfoRequest {
    name?: string;
    client?: string;
    userGroupName?: string;
    id?: number;
    ssoId?: string;
    ssoIdIn?: string;
    nameOrSurnameOrUsername?: string;
    idIn?: string;
    limit?: number;
    offset?: number;
}

export interface UserListRequest {
    name?: string;
    client?: string;
    userGroupName?: string;
    id?: number;
    ssoId?: string;
    ssoIdIn?: string;
    nameOrSurnameOrUsername?: string;
    idIn?: string;
    limit?: number;
    offset?: number;
}

export interface UserLoginRequest {
    ssoId: string;
    data: LoginUserRequest;
}

export interface UserMiniListRequest {
    name?: string;
    client?: string;
    userGroupName?: string;
    id?: number;
    ssoId?: string;
    ssoIdIn?: string;
    nameOrSurnameOrUsername?: string;
    idIn?: string;
}

export interface UserNestedRequest {
    name?: string;
    client?: string;
    userGroupName?: string;
    id?: number;
    ssoId?: string;
    ssoIdIn?: string;
    nameOrSurnameOrUsername?: string;
    idIn?: string;
    limit?: number;
    offset?: number;
}

export interface UserNestedSingleRequest {
    ssoId: string;
}

export interface UserPartialUpdateRequest {
    ssoId: string;
    data: WriteOnlyUser;
}

export interface UserPutNewRequest {
    ssoId: string;
    data: WriteOnlyUser;
}

export interface UserReadRequest {
    ssoId: string;
}

export interface UserRefreshTokenRequest {
    ssoId: string;
    data: RefreshTokenRequest;
}

export interface UserReplaceMonitoredObjectGroupsRequest {
    ssoId: string;
    data: NumberList;
}

export interface UserResetPasswordRequest {
    ssoId: string;
}

export interface UserUpdateRequest {
    ssoId: string;
    data: WriteOnlyUser;
}

export interface UserUserForBackendRequest {
    ssoId: string;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async userAddUserToUserGroupRaw(requestParameters: UserAddUserToUserGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userAddUserToUserGroup.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userAddUserToUserGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/user-group/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemoveUserToUserGroupBodyToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userAddUserToUserGroup(requestParameters: UserAddUserToUserGroupRequest): Promise<void> {
        await this.userAddUserToUserGroupRaw(requestParameters);
    }

    /**
     */
    async userAddUserToUserRoleRaw(requestParameters: UserAddUserToUserRoleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userAddUserToUserRole.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userAddUserToUserRole.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/user-roles/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemoveUserToUserRoleBodyToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userAddUserToUserRole(requestParameters: UserAddUserToUserRoleRequest): Promise<void> {
        await this.userAddUserToUserRoleRaw(requestParameters);
    }

    /**
     */
    async userCreateRaw(requestParameters: UserCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onConflict !== undefined) {
            queryParameters['on_conflict'] = requestParameters.onConflict;
        }

        if (requestParameters.skipSso !== undefined) {
            queryParameters['skip_sso'] = requestParameters.skipSso;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userCreate(requestParameters: UserCreateRequest): Promise<ReadOnlyUser> {
        const response = await this.userCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userDeleteRaw(requestParameters: UserDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userDelete(requestParameters: UserDeleteRequest): Promise<ReadOnlyUser> {
        const response = await this.userDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userEwDriverRegistrationLinkCreateRaw(requestParameters: UserEwDriverRegistrationLinkCreateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userEwDriverRegistrationLinkCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sendSms !== undefined) {
            queryParameters['send_sms'] = requestParameters.sendSms;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/ewDriverRegistration/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userEwDriverRegistrationLinkCreate(requestParameters: UserEwDriverRegistrationLinkCreateRequest): Promise<void> {
        await this.userEwDriverRegistrationLinkCreateRaw(requestParameters);
    }

    /**
     */
    async userGetBleConfigRaw(requestParameters: UserGetBleConfigRequest): Promise<runtime.ApiResponse<UserBLEConfig>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userGetBleConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/bleConfig/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBLEConfigFromJSON(jsonValue));
    }

    /**
     */
    async userGetBleConfig(requestParameters: UserGetBleConfigRequest): Promise<UserBLEConfig> {
        const response = await this.userGetBleConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGetUsersSimpleInfoRaw(requestParameters: UserGetUsersSimpleInfoRequest): Promise<runtime.ApiResponse<Array<UsersSimpleInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userGroupName !== undefined) {
            queryParameters['user_group__name'] = requestParameters.userGroupName;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.ssoId !== undefined) {
            queryParameters['sso_id'] = requestParameters.ssoId;
        }

        if (requestParameters.ssoIdIn !== undefined) {
            queryParameters['sso_id__in'] = requestParameters.ssoIdIn;
        }

        if (requestParameters.nameOrSurnameOrUsername !== undefined) {
            queryParameters['name_or_surname_or_username'] = requestParameters.nameOrSurnameOrUsername;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id_in'] = requestParameters.idIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/get-users-simple/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsersSimpleInfoFromJSON));
    }

    /**
     */
    async userGetUsersSimpleInfo(requestParameters: UserGetUsersSimpleInfoRequest): Promise<Array<UsersSimpleInfo>> {
        const response = await this.userGetUsersSimpleInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userListRaw(requestParameters: UserListRequest): Promise<runtime.ApiResponse<InlineResponse20046>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userGroupName !== undefined) {
            queryParameters['user_group__name'] = requestParameters.userGroupName;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.ssoId !== undefined) {
            queryParameters['sso_id'] = requestParameters.ssoId;
        }

        if (requestParameters.ssoIdIn !== undefined) {
            queryParameters['sso_id__in'] = requestParameters.ssoIdIn;
        }

        if (requestParameters.nameOrSurnameOrUsername !== undefined) {
            queryParameters['name_or_surname_or_username'] = requestParameters.nameOrSurnameOrUsername;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id_in'] = requestParameters.idIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20046FromJSON(jsonValue));
    }

    /**
     */
    async userList(requestParameters: UserListRequest): Promise<InlineResponse20046> {
        const response = await this.userListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userLoginRaw(requestParameters: UserLoginRequest): Promise<runtime.ApiResponse<LoginUserResponse>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userLogin.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/login/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginUserRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async userLogin(requestParameters: UserLoginRequest): Promise<LoginUserResponse> {
        const response = await this.userLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userMiniListRaw(requestParameters: UserMiniListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyUserMini>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userGroupName !== undefined) {
            queryParameters['user_group__name'] = requestParameters.userGroupName;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.ssoId !== undefined) {
            queryParameters['sso_id'] = requestParameters.ssoId;
        }

        if (requestParameters.ssoIdIn !== undefined) {
            queryParameters['sso_id__in'] = requestParameters.ssoIdIn;
        }

        if (requestParameters.nameOrSurnameOrUsername !== undefined) {
            queryParameters['name_or_surname_or_username'] = requestParameters.nameOrSurnameOrUsername;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id_in'] = requestParameters.idIn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/mini/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyUserMiniFromJSON));
    }

    /**
     */
    async userMiniList(requestParameters: UserMiniListRequest): Promise<Array<ReadOnlyUserMini>> {
        const response = await this.userMiniListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userNestedRaw(requestParameters: UserNestedRequest): Promise<runtime.ApiResponse<InlineResponse20047>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.userGroupName !== undefined) {
            queryParameters['user_group__name'] = requestParameters.userGroupName;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.ssoId !== undefined) {
            queryParameters['sso_id'] = requestParameters.ssoId;
        }

        if (requestParameters.ssoIdIn !== undefined) {
            queryParameters['sso_id__in'] = requestParameters.ssoIdIn;
        }

        if (requestParameters.nameOrSurnameOrUsername !== undefined) {
            queryParameters['name_or_surname_or_username'] = requestParameters.nameOrSurnameOrUsername;
        }

        if (requestParameters.idIn !== undefined) {
            queryParameters['id_in'] = requestParameters.idIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20047FromJSON(jsonValue));
    }

    /**
     */
    async userNested(requestParameters: UserNestedRequest): Promise<InlineResponse20047> {
        const response = await this.userNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userNestedSingleRaw(requestParameters: UserNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyUserNested>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/nested/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserNestedFromJSON(jsonValue));
    }

    /**
     */
    async userNestedSingle(requestParameters: UserNestedSingleRequest): Promise<ReadOnlyUserNested> {
        const response = await this.userNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userPartialUpdateRaw(requestParameters: UserPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userPartialUpdate(requestParameters: UserPartialUpdateRequest): Promise<ReadOnlyUser> {
        const response = await this.userPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userPutNewRaw(requestParameters: UserPutNewRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userPutNew.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userPutNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/ensure/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userPutNew(requestParameters: UserPutNewRequest): Promise<ReadOnlyUser> {
        const response = await this.userPutNewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userReadRaw(requestParameters: UserReadRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userRead(requestParameters: UserReadRequest): Promise<ReadOnlyUser> {
        const response = await this.userReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userRefreshTokenRaw(requestParameters: UserRefreshTokenRequest): Promise<runtime.ApiResponse<LoginUserResponse>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userRefreshToken.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userRefreshToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/refreshToken/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async userRefreshToken(requestParameters: UserRefreshTokenRequest): Promise<LoginUserResponse> {
        const response = await this.userRefreshTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userReplaceMonitoredObjectGroupsRaw(requestParameters: UserReplaceMonitoredObjectGroupsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userReplaceMonitoredObjectGroups.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userReplaceMonitoredObjectGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/replace-monitored-object-groups/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NumberListToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userReplaceMonitoredObjectGroups(requestParameters: UserReplaceMonitoredObjectGroupsRequest): Promise<void> {
        await this.userReplaceMonitoredObjectGroupsRaw(requestParameters);
    }

    /**
     */
    async userResetPasswordRaw(requestParameters: UserResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userResetPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/reset-password/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userResetPassword(requestParameters: UserResetPasswordRequest): Promise<void> {
        await this.userResetPasswordRaw(requestParameters);
    }

    /**
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUser>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserFromJSON(jsonValue));
    }

    /**
     */
    async userUpdate(requestParameters: UserUpdateRequest): Promise<ReadOnlyUser> {
        const response = await this.userUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userUserForBackendRaw(requestParameters: UserUserForBackendRequest): Promise<runtime.ApiResponse<ReadOnlyUserBackendInfo>> {
        if (requestParameters.ssoId === null || requestParameters.ssoId === undefined) {
            throw new runtime.RequiredError('ssoId','Required parameter requestParameters.ssoId was null or undefined when calling userUserForBackend.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user/{sso_id}/backend-info/`.replace(`{${"sso_id"}}`, encodeURIComponent(String(requestParameters.ssoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserBackendInfoFromJSON(jsonValue));
    }

    /**
     */
    async userUserForBackend(requestParameters: UserUserForBackendRequest): Promise<ReadOnlyUserBackendInfo> {
        const response = await this.userUserForBackendRaw(requestParameters);
        return await response.value();
    }

}
