/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceList,
    DeviceListFromJSON,
    DeviceListFromJSONTyped,
    DeviceListToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscoverDevicesResponse
 */
export interface DiscoverDevicesResponse {
    /**
     * 
     * @type {Array<DeviceList>}
     * @memberof DiscoverDevicesResponse
     */
    deviceList: Array<DeviceList>;
}

export function DiscoverDevicesResponseFromJSON(json: any): DiscoverDevicesResponse {
    return DiscoverDevicesResponseFromJSONTyped(json, false);
}

export function DiscoverDevicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscoverDevicesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceList': ((json['deviceList'] as Array<any>).map(DeviceListFromJSON)),
    };
}

export function DiscoverDevicesResponseToJSON(value?: DiscoverDevicesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceList': ((value.deviceList as Array<any>).map(DeviceListToJSON)),
    };
}


