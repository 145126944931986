import { message } from 'antd';
import { WithLogic, withLogicContext } from 'App';
import { Loading } from 'common/components';
import VehicleGroupsForm, { VehicleGroupsFormModel } from 'common/forms/VehicleGroupsForm/VehicleGroupsForm';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithLogic, WithTranslation {
    onCancel: () => void;
}

class VehicleGroupsModule extends Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.logic.vehicleGroupsModuleLogic().init();
    }

    render() {
        return (
            <div>
                {this.props.logic.vehicleGroupsModuleLogic().loadingInit ? (
                    <Loading />
                ) : (
                    <VehicleGroupsForm
                        userIdsOptions={this.props.logic.vehicleGroupsModuleLogic().users.map(user => {
                            return {
                                label: `${user.name} ${user.surname}`,
                                value: user.id ?? 0
                            };
                        })}
                        vehicleIdsOptions={this.props.logic.vehicleGroupsModuleLogic().monitoredObjects.map(vehicle => {
                            return {
                                value: vehicle.id ?? 0,
                                label: vehicle.registrationNumber
                            };
                        })}
                        initialValues={{
                            vehicleGroupId:
                                this.props.logic.vehicleGroupsModuleLogic().selectedMonitoredObjectGroup?.id,
                            userIds:
                                this.props.logic.vehicleGroupsModuleLogic().selectedMonitoredObjectGroupNested?.users,
                            vehicleIds:
                                this.props.logic.vehicleGroupsModuleLogic().selectedMonitoredObjectGroupNested
                                    ?.monitoredObjects
                        }}
                        selectedMonitoredObjectGroupLoading={
                            this.props.logic.vehicleGroupsModuleLogic().loadingMonitoredObjectGroupDetail
                        }
                        monitoredObjectGroups={this.props.logic.vehicleGroupsModuleLogic().monitoredObjectGroups}
                        onSelectMonitoredObjectGroup={this._onSelectMonitoredObjectGroup}
                        onCancel={this.props.onCancel}
                        onCreateNewGroupSubmit={this._onCreateMonitoredObjectGroup}
                        onEditGroupNameSubmit={this._onEditGroupNameSubmit}
                        onDeleteGroupSubmit={this._onDeleteGroupSubmit}
                        onReplaceAssetSubmit={this._onVehicleGroupFormSubmit}
                    />
                )}
            </div>
        );
    }

    private _onVehicleGroupFormSubmit = async (values: VehicleGroupsFormModel) => {
        try {
            const monitoredObjectGroup = await this.props.logic
                .vehicleGroupsModuleLogic()
                .updateMonitoredObjectGroup(values);
            this.props.logic.vehicleGroupsModuleLogic().updateMonitoredObjectGroupList(monitoredObjectGroup);
            message.success(this.props.t('VehicleGroupModule.message.updateSuccess'));
            this.props.onCancel();
            return true;
        } catch (error) {
            message.error(this.props.t('VehicleGroupModule.message.updateError'));
            return false;
        }
    };

    private _onEditGroupNameSubmit = async (id: number, name: string) => {
        try {
            const monitoredObjectGroup = await this.props.logic
                .vehicleGroupsModuleLogic()
                .editMonitoredObjectGroupName(id, name);
            this.props.logic.vehicleGroupsModuleLogic().updateMonitoredObjectGroupList(monitoredObjectGroup);
            message.success(this.props.t('VehicleGroupModule.message.updateNameSuccess'));
            return true;
        } catch (err: any) {
            // non_field_errors: ["The fields client, name must make a unique set."]
            // 0: "The fields client, name must make a unique set."
            if (err.json) {
                (err.json() as Promise<any>).then(data => {
                    if (
                        data?.non_field_errors?.length &&
                        data.non_field_errors[0] === 'The fields client, name must make a unique set.'
                    ) {
                        message.error(this.props.t('VehicleGroupModule.message.updateNameError_duplicate'));
                    } else {
                        message.error(this.props.t('VehicleGroupModule.message.updateNameError'));
                    }
                });
            } else {
                message.error(this.props.t('VehicleGroupModule.message.updateNameError'));
            }
            return false;
        }
    };

    private _onDeleteGroupSubmit = async (id: number) => {
        try {
            const monitoredObjectGroup = await this.props.logic
                .vehicleGroupsModuleLogic()
                .deleteMonitoredObjectGroup(id);
            this.props.logic.vehicleGroupsModuleLogic().updateMonitoredObjectGroupList(monitoredObjectGroup, 'pop');
            if (this.props.logic.vehicleGroupsModuleLogic().selectedMonitoredObjectGroupId === id) {
                this.props.logic.vehicleGroupsModuleLogic().setSelectedMonitoredObjectGroupId(undefined);
            }
            message.success(this.props.t('VehicleGroupModule.message.deleteSuccess'));
            return true;
        } catch (err) {
            message.error(this.props.t('VehicleGroupModule.message.deleteError'));
            return false;
        }
    };

    private _onCreateMonitoredObjectGroup = async (name: string) => {
        try {
            const monitoredObjectGroup = await this.props.logic
                .vehicleGroupsModuleLogic()
                .createMonitoredObjectGroup(name);

            if (monitoredObjectGroup.id) {
                this.props.logic.vehicleGroupsModuleLogic().updateMonitoredObjectGroupList(monitoredObjectGroup);
                this.props.logic.vehicleGroupsModuleLogic().setSelectedMonitoredObjectGroupId(monitoredObjectGroup.id);
            }
            message.success(this.props.t('VehicleGroupModule.message.createSuccess'));
            return monitoredObjectGroup;
        } catch (err: any) {
            if (err.json) {
                (err.json() as Promise<any>).then(data => {
                    if (
                        data?.non_field_errors?.length &&
                        data.non_field_errors[0] === 'The fields client, name must make a unique set.'
                    ) {
                        message.error(this.props.t('VehicleGroupModule.message.updateNameError_duplicate'));
                    } else {
                        message.error(this.props.t('VehicleGroupModule.message.createError'));
                    }
                });
            } else {
                message.error(this.props.t('VehicleGroupModule.message.createError'));
            }
            throw err;
        }
    };

    private _onSelectMonitoredObjectGroup = (id: number) => {
        this.props.logic.vehicleGroupsModuleLogic().setSelectedMonitoredObjectGroupId(id);
    };
}

export default withTranslation()(withLogicContext(observer(VehicleGroupsModule)));
