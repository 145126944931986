/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ProposalForClient,
    ProposalForClientFromJSON,
    ProposalForClientToJSON,
} from '../models';

export interface GetProposalsV3ProposalsMyGetRequest {
    monitoredObjectIds?: Array<string>;
    subscribeUser?: string;
    subscribeDevice?: string;
    monitoredObjectId?: number;
}

export interface GetProposalsV3ProposalsMyGetByTransportIdRequest {
    transportId: string;
}

/**
 * no description
 */
export class DffProposalsApi extends runtime.BaseAPI {

    /**
     * Get Proposals
     */
    async getProposalsV3ProposalsMyGetRaw(requestParameters: GetProposalsV3ProposalsMyGetRequest): Promise<runtime.ApiResponse<Array<ProposalForClient>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectIds) {
            queryParameters['monitored_object_ids'] = requestParameters.monitoredObjectIds;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalForClientFromJSON));
    }

    /**
     * Get Proposals
     */
    async getProposalsV3ProposalsMyGet(requestParameters: GetProposalsV3ProposalsMyGetRequest): Promise<Array<ProposalForClient>> {
        const response = await this.getProposalsV3ProposalsMyGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Proposal By Transport ID
     */
    async getProposalsV3ProposalsMyGetByTransportIdRaw(requestParameters: GetProposalsV3ProposalsMyGetByTransportIdRequest): Promise<runtime.ApiResponse<ProposalForClient>> {
        if (requestParameters.transportId === null || requestParameters.transportId === undefined) {
            throw new runtime.RequiredError('transportId','Required parameter requestParameters.transportId was null or undefined when calling getProposalsV3ProposalsMyGetByTransportId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/my/transport/{transport_id}`.replace(`{${"transport_id"}}`, encodeURIComponent(String(requestParameters.transportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalForClientFromJSON(jsonValue));
    }

    /**
     * Get Proposal By Transport ID
     */
    async getProposalsV3ProposalsMyGetByTransportId(requestParameters: GetProposalsV3ProposalsMyGetByTransportIdRequest): Promise<ProposalForClient> {
        const response = await this.getProposalsV3ProposalsMyGetByTransportIdRaw(requestParameters);
        return await response.value();
    }

}
