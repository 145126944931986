import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { Role } from 'logic/auth';
import Button from 'common/components/Button';
import { ReadOnlyUser } from 'generated/new-main/models';
import qa from 'qa-selectors';

interface Props {
    invite: boolean;
    invitedToEWFleetDrivers?: string[];
    demoMode?: boolean;
    model?: ReadOnlyUser;
    roles: Role[];
    onInviteToEWFleetDriver?: () => void;
    onEditNewUserButtonClick?: () => void;
    onLinkNewAssetButtonClick?: () => void;
    onUserRemoveButtonClick?: () => void;
    onPlaceOfWorkUsersAddButtonClick?: () => void;
    onPasswordResetClick?: () => void;
    onAssignToVehicleGroupClick?: () => void;
}

function UsersActions(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                {props.invite && props.model?.userRoles?.some(ur => ur.label.toLocaleLowerCase() === 'driver') && (
                    <Col span={12}>
                        <Button
                            block
                            type="primary"
                            disabled={
                                props.invitedToEWFleetDrivers?.includes(props.model?.id?.toString() ?? '') ||
                                props.demoMode
                            }
                            onClick={props.onInviteToEWFleetDriver}
                            qa={qa.users.detail.btnInviteEWFleet}
                            icon={<i className="far fa-paper-plane" />}
                        >
                            {t('ManagementUsers.inviteToEWFleetDriver')}
                        </Button>
                    </Col>
                )}
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        onClick={props.onEditNewUserButtonClick}
                        disabled={props.demoMode || !props.roles.includes(Role.USR_W)}
                        qa={qa.users.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-pencil-alt" />}
                    >
                        {t('ManagementUsers.update')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={
                            !props.model?.userRoles?.some(
                                userRole => userRole.label.toLocaleLowerCase() === 'driver'
                            ) ||
                            props.demoMode ||
                            !props.roles.includes(Role.USR_IE)
                        }
                        onClick={props.onLinkNewAssetButtonClick}
                        qa={qa.users.detail.btnAddLinkedItem}
                        icon={<i className="fas fa-fw fa-link" />}
                    >
                        {t('ManagementUsers.link')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        onClick={props.onUserRemoveButtonClick}
                        disabled={props.demoMode || !props.roles.includes(Role.USR_W)}
                        qa={qa.users.detail.btnDelete}
                        icon={<i className="fas fa-fw fa-trash-alt" />}
                    >
                        {t('ManagementUsers.delete')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        type="primary"
                        block
                        onClick={props.onPlaceOfWorkUsersAddButtonClick}
                        disabled={props.demoMode || !props.roles.includes(Role.POI_W)}
                        qa={qa.users.detail.btnPlaceOfWorkAdd}
                        icon={<i className="fas fa-map-pin" />}
                    >
                        {t('ManagementUsers.placeOfWorkAdd')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        type="primary"
                        block
                        onClick={props.onPasswordResetClick}
                        disabled={props.demoMode || !props.roles.includes(Role.USR_W)}
                        qa={qa.users.detail.btnPlaceOfWorkAdd}
                        icon={<i className="fas fa-key" />}
                    >
                        {t('ManagementUsers.passwordReset')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        type="primary"
                        block
                        onClick={props.onAssignToVehicleGroupClick}
                        disabled={props.demoMode}
                        qa={qa.users.detail.btnVehicleGroupAdd}
                    >
                        {t('ManagementUsers.assignToVehicleGroup')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default UsersActions;
