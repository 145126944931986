import moment, { Duration } from 'moment';
import * as m from 'moment';
import { observable, runInAction } from 'mobx';
import { interval, Observable, Subscription } from 'rxjs';
import { action, makeObservable } from 'mobx';
import { extendMoment } from 'moment-range';
import { buffer } from 'rxjs/operators';

import { TransportState, VehicleStateObject, VehicleTransportObject } from 'generated/graphql';
import { MonitoredObjectFleetType, Transport, TransportFromJSON } from 'generated/backend-api';

import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';
import { VehicleDelayedFilterCode } from 'common/model/tracking';
import { TransportModel } from 'common/model/transports';

import { debounce } from 'debounce';
import { NotificationMessage, Notification } from 'logic/notification-eio';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { TransEUCompanyInfo } from '../../logic/dff-proposals';
import { NegotiationState, ProposalForClient } from 'generated/dff-api';

const momentRange = extendMoment(m);

(window as any).TransportFromJSON = TransportFromJSON;

export interface BoardGridConfig {
    visibleDays: number;
    activeDayIndex: number;
}

export interface TransportCalculatedProperties {
    actual: string;
    offset: number;
    width: number;
    renderEta: boolean;
    etaWidth: number | undefined;
    timeToFirstRta: number;
    duration?: Duration;
    highlight?: boolean;
}

export interface TransportWithUIProperties {
    type: 'dff' | 'tlm';
    data: TransportModel;
    properties: TransportCalculatedProperties;
}

export interface DispatcherBoardRow {
    vehicle: ReadOnlyMonitoredObjectFeSb;
    state?: VehicleStateObject;
    transports: TransportWithUIProperties[][];
}

export const DISAPPEARING_ANIMATION_LENGTH = 5;

export class DispatcherBoardLogic {
    private readonly _rejectedProposalsKey = 'rejectedProposals';
    private readonly _dffInitialShowCaseKey = 'dffInitialShowCaseKey';

    logic: Logic;

    @observable selectedDate: string;
    @observable data: DispatcherBoardRow[];
    @observable dffRatings: number[];
    @observable showDffProposals: boolean;
    @observable manualRefresh: boolean;
    @observable newDFFProposals: number;
    @observable config: BoardGridConfig;
    @observable loading = false;
    @observable draggedTransport?: string;
    @observable transportPopoverRoute: { [transportId: string]: string[] | undefined } = {};
    @observable transports: Transport[] = [];
    @observable legacyStyle = false;

    private _transportsWithRoute: Transport[] = [];
    private _dffProposals: ProposalForClient[] = [];
    private _vehicles: ReadOnlyMonitoredObjectFeSb[] = [];
    private _vehiclesStates: VehicleStateObject[] = [];
    private _selectedDate: string;

    private _dataUpdatesInterval = 10e3;
    private _transportsUpdatesSub?: Subscription;
    private _vehicleStateUpdatesSub?: Subscription;
    private _transportsUpdateCb?: (n: NotificationMessage<any>) => void;
    private _vehiclesStateUpdateCb?: (n: NotificationMessage<any>) => void;

    transportUpdateNotification(): Notification {
        if (this.logic.conf.useDFFTransports) {
            return this.logic.notificationDFF();
        } else {
            return this.logic.notification();
        }
    }

    get dffInitialShowCaseDone() {
        const value = JSON.parse(localStorage.getItem(this._dffInitialShowCaseKey) ?? 'false');
        return value;
    }

    set dffInitialShowCaseDone(value: boolean) {
        localStorage.setItem(this._dffInitialShowCaseKey, JSON.stringify(value));
    }

    get rejectedProposals() {
        return JSON.parse(localStorage.getItem(this._rejectedProposalsKey) ?? '[]');
    }

    set rejectedProposals(value: string[]) {
        localStorage.setItem(this._rejectedProposalsKey, JSON.stringify(value));
    }

    get viewStartDay() {
        return moment
            .utc(this._selectedDate)
            .startOf('day')
            .subtract(this.config.activeDayIndex - 1, 'days')
            .toISOString();
    }

    get viewEndDay() {
        return moment(this.viewStartDay).add(5, 'days').toISOString();
    }

    get freeTransports() {
        const data = this.transports
            .filter(t => t.state === TransportState.New)
            .map(t => this.logic.transportLogic().toTransport(t));

        const transportsData = this.calculateTransportsDimensions(data, this.viewStartDay);
        return transportsData;
    }

    constructor(logic: Logic) {
        this.logic = logic;

        this._selectedDate = moment().toISOString();
        this.selectedDate = this._selectedDate;
        this.data = [];
        this.dffRatings = [1, 2, 3, 4, 5];
        this.manualRefresh = false;
        this.newDFFProposals = 0;
        this.showDffProposals = true;
        this.config = { activeDayIndex: 2, visibleDays: 5 };

        makeObservable(this);
    }

    @action
    init(initialDate?: string) {
        if (initialDate) {
            this._selectedDate = initialDate;
            this.selectedDate = this._selectedDate;
        }

        if (this.logic.demo().isActive) {
            this._initDemoMode();
        } else {
            this._init();
        }
    }

    @action
    async getRouteDebounce(transportId: string) {
        debounce(async () => {
            runInAction(async () => {
                const transportWithRoute = await this.getTransportWithRoute(transportId);
                this.transportPopoverRoute[transportId] = transportWithRoute.places
                    ?.filter(p => p.route)
                    .map(p => p.route!);
            });
        }, 300)();
    }

    async getTransportWithRoute(transportId: string) {
        const index = this._transportsWithRoute.findIndex(t => t.id === transportId);
        if (index !== -1) {
            return this._transportsWithRoute[index];
        }

        const transport = await this._fetchTransportWithRoute(transportId);
        this._transportsWithRoute.push(transport);

        return transport;
    }

    destroy() {
        this._transportsUpdatesSub?.unsubscribe();
        this.transportUpdateNotification().off('transports-update', this._transportsUpdateCb);
        this.logic.notification().off('actual-vehicle-state', this._vehiclesStateUpdateCb);
        this._vehicleStateUpdatesSub?.unsubscribe();
        this.dffInitialShowCaseDone = true;
    }

    @action
    async changeDate(date: string) {
        this.loading = true;
        this._selectedDate = date;
        this.transports = await this._fetchTransports(this.viewStartDay);

        runInAction(() => {
            this._calculateData();
            this.loading = false;
            this.selectedDate = this._selectedDate;
        });
    }

    @action
    refreshBoard() {
        this.newDFFProposals = 0;
        this._dffProposals = this.logic.dffProposals().proposals;
        this._calculateData();
    }

    @action
    changeDffFilter(values: number[]) {
        this.dffRatings = values;
        this._calculateData();
    }

    @action
    toggleManualRefresh() {
        this.manualRefresh = !this.manualRefresh;
    }

    @action
    rejectProposal(id: string) {
        this.rejectedProposals = [...this.rejectedProposals, id];
        this._calculateData();
    }

    @action
    toggleShowProposals() {
        this.showDffProposals = !this.showDffProposals;
        this._calculateData();
    }

    @action
    async convertProposalToTransport(id: string) {
        this.loading = true;
        const transport = await this._acceptProposal(id);
        try {
            this.logic.api().dffNegotiationApi.acceptV3ProposalsProposalIdAcceptPatch({
                proposalId: id
            });
        } catch (e) {
            console.error('Unable to accept proposal');
            console.error(e);
        }
        runInAction(() => {
            this.transports.push(transport);
            this.rejectedProposals = [...this.rejectedProposals, id];
            this._calculateData();
            this.loading = false;
        });
    }

    @action
    async acceptProposal(id: string, useJitpay?: boolean) {
        this.loading = true;

        try {
            if (!this.logic.demo().isActive) {
                await this.logic.api().dffNegotiationApi.acceptV3ProposalsProposalIdAcceptPatch({
                    proposalId: id,
                    useJitpay
                });
            }

            runInAction(() => {
                const acceptedProposal = this._dffProposals.find(proposal => proposal.proposalId === id);
                if (acceptedProposal) {
                    acceptedProposal.state = NegotiationState.CarrierOffer;
                    acceptedProposal.useJitpay = !!useJitpay;
                }
            });
        } catch (e) {
            console.error('Unable to accept proposal', e);
        }

        runInAction(() => {
            this._calculateData();
            this.loading = false;
        });
    }

    @action
    async declineProposal(id: string) {
        this.loading = true;

        try {
            if (!this.logic.demo().isActive) {
                await this.logic.api().dffNegotiationApi.declineV3ProposalsProposalIdDeclinePatch({
                    proposalId: id
                });
            }

            runInAction(() => {
                this.rejectedProposals = [...this.rejectedProposals, id];
                const rejectedProposal = this._dffProposals.find(proposal => proposal.proposalId === id);
                if (rejectedProposal) {
                    rejectedProposal.state = NegotiationState.Declined;
                }
            });
        } catch (e) {
            console.error('Unable to decline proposal', e);
        }

        runInAction(() => {
            this._calculateData();
            this.loading = false;
        });
    }

    @action
    async proposePrice(id: string, proposedPrice: number, useJitpay?: boolean) {
        this.loading = true;

        try {
            if (!this.logic.demo().isActive) {
                await this.logic.api().dffNegotiationApi.offerV3ProposalsProposalIdOfferPatch({
                    proposalId: id,
                    negotiationOffer: {
                        price: { value: proposedPrice, currencyCode: 'EUR' },
                        useJitpay: !!useJitpay
                    }
                });
            }

            runInAction(() => {
                const proposal = this._dffProposals.find(proposal => proposal.proposalId === id);
                if (proposal) {
                    proposal.negotiatedPrice = { value: proposedPrice, currencyCode: 'EUR' };
                    proposal.state = NegotiationState.CarrierOffer;
                    proposal.useJitpay = !!useJitpay;
                }
            });
        } catch (e) {
            console.error('Unable to offer price', e);
        }

        runInAction(() => {
            this._calculateData();
            this.loading = false;
        });
    }

    @action
    reorderTransports(transportId: string, newVehicleId?: number) {
        const transportIndex = this.transports.findIndex(t => t.id === transportId);
        if (transportIndex !== -1) {
            const transport = this.transports[transportIndex];
            const transportModel = this.logic.transportLogic().toTransport(transport);
            const transportState: TransportState = this.logic
                .transportLogic()
                .getTransportState(newVehicleId ? true : false, transportModel.places?.[0]?.rta);

            const transportMonitoredObjects = transport.monitoredObjects ?? [];
            if (transportMonitoredObjects.length > 0) {
                transportMonitoredObjects[transportMonitoredObjects.length - 1].endTime = new Date();
                transportMonitoredObjects[transportMonitoredObjects.length - 1].primary = false;
            }

            if (newVehicleId) {
                transportMonitoredObjects.push({
                    endTime: undefined,
                    startTime: new Date(),
                    monitoredObjectId: newVehicleId,
                    primary: true
                });
            }

            this.transports[transportIndex] = {
                ...transport,
                state: transportState,
                monitoredObjects: transportMonitoredObjects
            };
            this._calculateData();
        }
    }

    @action
    async removeVehicleFromTransport(transportId: string) {
        const transport = this.transports.find(t => t.id === transportId);
        if (
            transport?.state &&
            ![TransportState.Accepted, TransportState.New, TransportState.Planned].includes(transport.state)
        ) {
            throw new Error(`Can not remove vehicle from transport with state: ${transport.state}`);
        }

        if (this.logic.demo().isActive) {
            const index = this.transports.findIndex(t => t.id === transportId);
            if (index !== -1) {
                this.transports[index] = { ...transport, monitoredObjects: [], state: TransportState.New };
                this._calculateData();
            }
        } else {
            await this.logic.api().transportApi.updateV1TransportsTransportIdPatch({
                transport: {
                    ...transport,
                    state: TransportState.New,
                    monitoredObjects: transport?.monitoredObjects?.filter(
                        mo => mo.type === MonitoredObjectFleetType.Trailer
                    )
                },
                transportId: transportId
            });
            const transports = await this._fetchTransports(this.viewStartDay);
            runInAction(() => {
                this.transports = transports;
                this._calculateData();
            });
        }
    }

    @action
    async moveTransportToOtherVehicle(transportId: string, destinationVehicleId: string) {
        const transport = this.transports.find(t => t.id === transportId);
        if (!transport) {
            throw new Error(`Unable to find transport with id ${transportId}`);
        }

        const transportModel = this.logic.transportLogic().toTransport(transport);
        const transportState: TransportState = this.logic
            .transportLogic()
            .getTransportState(true, transportModel.places?.[0]?.rta);

        if (
            transport?.state &&
            ![TransportState.Accepted, TransportState.New, TransportState.Planned].includes(transport.state)
        ) {
            throw new Error(`Can not remove vehicle from transport with state: ${transport.state}`);
        }

        if (this.logic.demo().isActive) {
            const index = this.transports.findIndex(t => t.id === transportId);
            if (index !== -1) {
                this.transports[index] = {
                    ...transport,
                    monitoredObjects: [
                        {
                            monitoredObjectId: Number(destinationVehicleId),
                            startTime: transport.places?.[0].rta,
                            primary: true
                        }
                    ],
                    state: transportState
                };
                this._calculateData();
            }
        } else {
            await this._attachVehicleToTransport(destinationVehicleId, transportId, transportState);
            const transports = await this._fetchTransports(this.viewStartDay);
            runInAction(() => {
                this.transports = transports;
                this._calculateData();
            });
        }
    }

    @action
    clearDraggedTransport() {
        this.draggedTransport = undefined;
    }

    @action
    markTransportDragged(transportId: string) {
        if (this.transports.some(t => t.id === transportId)) {
            this.draggedTransport = transportId;
        }
    }

    private async _init() {
        this.loading = true;
        this._watchTransportsUpdates();
        this._watchVehicleStatesUpdates();
        try {
            this.transports = await this._fetchTransports(this.viewStartDay);
        } catch (err) {
            console.error(`Unable to load transports, err: ${err}`);
        }
        try {
            this._vehicles = await this.logic.vehicles().getMonitoredObjectFilters(false, true);
        } catch (err) {
            console.error(`Unable to load vehicles, err: ${err}`);
        }
        try {
            this._vehiclesStates = await this._fetchVehicleStates(this.logic.notification().device!);
        } catch (err) {
            console.error(`Unable to load vehicle states, err: ${err}`);
        }

        if (this.logic.auth().roles().includes(Role.DFF_R)) {
            this.logic.dffProposals().init(this._vehicles?.map(v => v.id ?? 0));
            this._dffProposals = this.logic.dffProposals().proposals;
            this.logic.dffProposals().proposalsUpdates.subscribe(data => {
                if (this.manualRefresh) {
                    runInAction(() => {
                        this.newDFFProposals += data.length;
                    });
                } else {
                    this._dffProposals = this.logic.dffProposals().proposals;
                    this._calculateData();
                }
            });
        }

        this._calculateData();
        this.loading = false;
    }

    private _initDemoMode() {
        this.transports = this.logic?.demo().data.transports;
        this._vehicles = this.logic?.demo().data.vehiclesSimple;
        this._vehiclesStates = this.logic?.demo().data.vehicleStates;

        if (this.logic.auth().roles(true).includes(Role.DFF_R)) {
            this.logic.dffProposals().init(this._vehicles?.map(v => v.id ?? 0));
            this._dffProposals = this.logic.dffProposals().proposals;
            this.logic.dffProposals().proposalsUpdates.subscribe(data => {
                if (this.manualRefresh) {
                    runInAction(() => {
                        this.newDFFProposals += data.length;
                    });
                } else {
                    this._dffProposals = this.logic.dffProposals().proposals;
                    this._calculateData();
                }
            });
        }

        this._calculateData();
        this._watchTransportsUpdates();
        this._watchVehicleStatesUpdates();
    }

    @action
    private _calculateData() {
        const transports = this.transports.map(t => this.logic.transportLogic().toTransport(t)) ?? [];
        let proposalsToShow: TransportModel[] = [];

        if (this.showDffProposals) {
            proposalsToShow = this._dffProposals
                .map((p, i) => {
                    let companyInfo: TransEUCompanyInfo | undefined = undefined;
                    if (this.logic.demo().isActive) {
                        companyInfo = this.logic.demo().data.transEUMockInfo[i];
                    } else {
                        companyInfo = {
                            name: p.shipment.shipper.companyContact.companyName,
                            address: p.shipment.shipper.billingInfo.companyAddress.street,
                            contactNumber: p.shipment?.shipper?.companyContact?.phoneNumber,
                            contactPerson: p.shipment?.shipper?.companyContact?.contactPerson,
                            paymentStatus: 'good',
                            vatNumber: p.shipment?.shipper?.billingInfo.vatNumber,
                            email: p.shipment?.shipper?.companyContact?.email
                        };
                    }
                    return this.logic.dffProposals().dffProposalToTransportModel(p, companyInfo);
                })
                .filter(p =>
                    momentRange
                        .range(moment(p.firstPlaceRta), moment(p.lastPlaceRta))
                        .overlaps(momentRange.range(moment(this.viewStartDay), moment(this.viewEndDay)))
                )
                .filter(p =>
                    moment().isBefore(
                        moment(p.metadata?.proposal?.places[0].loadingWindow.availableUntil)
                            .subtract(p.metadata?.pickupDuration, 'seconds')
                            .add(DISAPPEARING_ANIMATION_LENGTH, 'seconds')
                    )
                )
                .filter(p =>
                    p.metadata?.expiration
                        ? moment().isBefore(moment(p.metadata.expiration).add(DISAPPEARING_ANIMATION_LENGTH, 'seconds'))
                        : true
                )
                .filter(p => !this.rejectedProposals.includes(String(p.id)))
                .filter(
                    p =>
                        ![NegotiationState.Declined, NegotiationState.Expired].some(
                            state => state === p.metadata?.proposal?.state
                        )
                );
            // .filter(p => this.dffRatings.includes(p.metadata?.score.stars ?? 0)) ?? []; // Temporarily we will not filter by score
        }

        this.data = this._vehicles.map(vehicle => {
            const tlmTransports = transports
                .filter(t => String(t.vehicle) === String(vehicle.id))
                .filter(
                    t =>
                        ![TransportState.Rejected, TransportState.Canceled].includes(t.state ?? TransportState.Rejected)
                )
                .filter(p =>
                    momentRange
                        .range(moment(p.firstPlaceRta), moment(p.lastPlaceRta))
                        .overlaps(momentRange.range(moment(this.viewStartDay), moment(this.viewEndDay)))
                );

            let dffTransports = proposalsToShow.filter(t => String(t.vehicle) === String(vehicle.id));
            // Display only proposals which doesn't overlap transports
            dffTransports = dffTransports.filter(proposal => {
                const proposalRange = momentRange.range(moment(proposal.firstPlaceRta), moment(proposal.lastPlaceRta));
                const overlaps = tlmTransports.some(t =>
                    momentRange.range(moment(t.firstPlaceRta), moment(t.lastPlaceRta)).overlaps(proposalRange)
                );
                return !overlaps;
            });

            dffTransports = this._sortVehicleProposalTransports(dffTransports);

            const state = this._vehiclesStates?.find(v => String(v.monitoredObjectId) === String(vehicle.id))!;
            const transportsData = this.calculateTransportsDimensions(
                [...tlmTransports, ...dffTransports],
                this.viewStartDay,
                state
            );

            return {
                vehicle,
                state,
                transports: transportsData
            };
        });
    }

    calculateTransportsDimensions(
        transports: TransportModel[],
        startDate: string,
        state?: VehicleStateObject
    ): DispatcherBoardRow['transports'] {
        const data = this._createIntersectionColumnsOfTransports(transports);
        const processed: DispatcherBoardRow['transports'] = [];

        for (const row of data) {
            const calculated = row.map(c => {
                const properties = this._calculateTransportSize(c, startDate, state);
                const type = (c.name?.startsWith('proposal') ? 'dff' : 'tlm') as 'dff' | 'tlm';
                const highlight = type === 'dff' && !this.dffInitialShowCaseDone;
                return {
                    type,
                    data: c,
                    properties: {
                        ...properties,
                        highlight
                    }
                };
            });

            processed.push(calculated);
        }

        return processed;
    }

    private async _acceptProposal(id: string): Promise<Transport> {
        const proposals = this.logic
            .dffProposals()
            .proposals.map(p => this.logic.dffProposals().dffProposalToTransportModel(p));
        const transport = proposals.find(t => t.id === id);

        if (!transport) {
            throw new Error('Proposal not found');
        } else {
            if (this.logic.demo().isActive) {
                const data = this.logic.dffProposals().proposalTransportToTransport(transport, '', 1);
                const t = {
                    ...data,
                    id: Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1)
                };
                return t;
            } else {
                const { distance, route } = await this.logic.dffProposals().getRouteFormFirstToLastPlace(transport);
                const data = this.logic.dffProposals().proposalTransportToTransport(transport, route, distance);
                const resp = await this.logic.api().transportApi.createV1TransportsPost({ transport: data });
                return resp;
            }
        }
    }

    @action
    private _watchTransportsUpdates() {
        type BufferedUpdate<T> = {
            data: T;
            timestamp: string;
        };

        const transportUpdate$ = new Observable<BufferedUpdate<Transport>>(subscriber => {
            this._transportsUpdateCb = (n: NotificationMessage<any>) => {
                if (n.data && Array.isArray(n.data)) {
                    const transportUpdates = n.data.map(t => TransportFromJSON(t));
                    const timestamp = new Date().toISOString();
                    for (const update of transportUpdates) {
                        subscriber.next({ data: update, timestamp });
                    }
                }
            };

            this.transportUpdateNotification().on('transports-update', this._transportsUpdateCb);
        });

        const buffered$ = transportUpdate$.pipe(buffer(interval(this._dataUpdatesInterval)));
        this._transportsUpdatesSub = buffered$.subscribe(data => {
            const groupedUpdates = data.reduce<{ [id: string]: BufferedUpdate<Transport>[] }>((a, c) => {
                if (c.data.id) {
                    if (a[c.data.id]) {
                        a[c.data.id].push(c);
                    } else {
                        a[c.data.id] = [c];
                    }
                }
                return a;
            }, {});

            const latestUpdates = Object.values(groupedUpdates).reduce<Transport[]>((a, c) => {
                const [mostRecentUpdate] = c.sort((a, b) => (moment(a.timestamp).isBefore(b.timestamp) ? 0 : -1));
                if (mostRecentUpdate) {
                    a.push(mostRecentUpdate.data);
                }
                return a;
            }, []);

            const newTransports = [...this.transports];

            latestUpdates.forEach(update => {
                if (!(this.draggedTransport && update.id === this.draggedTransport)) {
                    const index = newTransports.findIndex(a => String(a.id) === String(update.id));
                    if (index !== -1) {
                        newTransports[index] = update;
                    } else {
                        newTransports.push(update);
                    }
                }
            });

            runInAction(() => {
                this.transports = newTransports;
            });

            this._calculateData();
        });
    }

    private _watchVehicleStatesUpdates() {
        type BufferedUpdate<T> = {
            data: T;
            timestamp: string;
        };

        const vehicleStateUpdate$ = new Observable<BufferedUpdate<VehicleStateObject>>(subscriber => {
            this._vehiclesStateUpdateCb = (message: NotificationMessage<any>) => {
                if (message.data && Array.isArray(Object.values(message.data))) {
                    const updates: VehicleStateObject[] = Object.values(message.data);
                    const timestamp = new Date().toISOString();
                    for (const update of updates) {
                        subscriber.next({ data: update, timestamp });
                    }
                }
            };

            this.logic.notification().on('actual-vehicle-state', this._vehiclesStateUpdateCb);
        });

        const buffered$ = vehicleStateUpdate$.pipe(buffer(interval(this._dataUpdatesInterval)));

        this._vehicleStateUpdatesSub = buffered$.subscribe(data => {
            const groupedUpdates = data.reduce<{ [id: string]: BufferedUpdate<VehicleStateObject>[] }>((a, c) => {
                if (c.data.monitoredObjectId) {
                    if (a[c.data.monitoredObjectId]) {
                        a[c.data.monitoredObjectId].push(c);
                    } else {
                        a[c.data.monitoredObjectId] = [c];
                    }
                }
                return a;
            }, {});

            const latestUpdates = Object.values(groupedUpdates).reduce<VehicleStateObject[]>((a, c) => {
                const [mostRecentUpdate] = c.sort((a, b) => (moment(a.timestamp).isBefore(b.timestamp) ? 0 : -1));
                if (mostRecentUpdate) {
                    a.push(mostRecentUpdate.data);
                }
                return a;
            }, []);

            const newVehicleStates = [...this._vehiclesStates];

            latestUpdates.forEach(update => {
                const index = newVehicleStates.findIndex(
                    a => String(a.monitoredObjectId) === String(update.monitoredObjectId)
                );
                if (index !== -1) {
                    newVehicleStates[index] = update;
                } else {
                    newVehicleStates.push(update);
                }
            });

            runInAction(() => {
                this._vehiclesStates = newVehicleStates;
            });

            this._calculateData();
        });
    }

    private _transpose<T>(matrix: T[][]) {
        const res: T[][] = [];
        matrix.forEach(col => {
            col.forEach((row, rowIndex) => {
                if (res[rowIndex]) {
                    res[rowIndex].push(row);
                } else {
                    res.push([row]);
                }
            });
        });
        return res;
    }

    private _getDuration(transport?: VehicleTransportObject): moment.Duration | undefined {
        if (transport?.nextWaypoint?.rta && transport.nextWaypoint.eta) {
            const momentEta = moment.utc(transport.nextWaypoint.eta);
            const momentRta = moment.utc(transport.nextWaypoint.rta);
            const duration = moment.duration(momentEta.diff(momentRta));
            return duration;
        }
        return undefined;
    }

    private _getActual(duration?: moment.Duration) {
        if (duration) {
            return `${
                duration.asSeconds() <= 0 ? VehicleDelayedFilterCode.ON_TIME : duration.asSeconds() < 0 ? '- ' : '+ '
            }${
                duration.asSeconds() > 0
                    ? Math.abs(duration.get('days')) > 0
                        ? `${Math.abs(duration.get('days'))}d ${Math.abs(duration.get('hours'))}h ${Math.abs(
                              duration.get('minutes')
                          )}m`
                        : `${Math.abs(duration.get('hours'))}h ${Math.abs(duration.get('minutes'))}m`
                    : ''
            }`;
        }
        return '';
    }

    private _calculateTransportSize(
        transport: TransportModel,
        startDate: string,
        vehicleState?: VehicleStateObject
    ): TransportCalculatedProperties {
        // TODO: Calculate values bellow automatically
        const dayPercentageWidth = 20;
        const dayToMinutes = 1440;
        const defaultMargin = 0;

        const firstPlaceRta = transport.firstPlaceRta;
        const lastPlaceRta = transport.lastPlaceRta;
        const firstRtaDiffStartDate = moment.utc(firstPlaceRta).diff(moment.utc(startDate).toDate(), 'minutes');

        const activeTransport = vehicleState?.activeTransports?.find(t => t.id === transport.id);

        const duration = this._getDuration(activeTransport);
        const actual = this._getActual(duration);

        const offset =
            firstRtaDiffStartDate > 0
                ? defaultMargin + dayPercentageWidth * (firstRtaDiffStartDate / dayToMinutes)
                : defaultMargin;

        const width =
            firstRtaDiffStartDate > 0
                ? dayPercentageWidth *
                  (Math.abs(moment.utc(lastPlaceRta).diff(moment.utc(firstPlaceRta).toDate(), 'minutes')) /
                      dayToMinutes)
                : dayPercentageWidth *
                  (Math.abs(moment.utc(startDate).diff(moment.utc(lastPlaceRta).toDate(), 'minutes')) / dayToMinutes);

        const etaVisible =
            activeTransport?.nextWaypoint?.eta &&
            moment.utc(activeTransport.nextWaypoint.eta).isAfter(moment.utc(startDate))
                ? true
                : false;

        const etaWidth =
            activeTransport && duration
                ? dayPercentageWidth * (Math.abs(duration.asMinutes()) / dayToMinutes) +
                  (duration.asMinutes() < 0 ? 0 : width)
                : undefined;

        const renderEta = Boolean(
            etaWidth &&
                etaVisible &&
                duration &&
                transport.state !== TransportState.Finished &&
                transport.state !== TransportState.Rejected
        );

        const timeToFirstRta = moment.utc(firstPlaceRta).diff(moment.utc().toDate(), 'hours');

        return { actual, offset, renderEta, width, etaWidth, timeToFirstRta, duration };
    }

    /**
     * This method makes intersection of transports which shares time interval from firstPlaceRta & lastPlaceRta and tides them to columns like this
     * Desired state is bellow where is xxx is transport in dispatcher board
     *      xxxxxx            xxxxxx      xxxxxx
     *          xxxxxx        xxxxxx   xxxxx
     *              xxxxxx    xxxxxx      xxxxxxx
     */
    private _createIntersectionColumnsOfTransports(
        transports: TransportModel[],
        sortByRating = false
    ): TransportModel[][] {
        const sortedTransports = transports.filter(t => t.firstPlaceRta && t.lastPlaceRta);
        const processed: TransportModel[][] = [];
        let workingIndex = 0;

        for (const [index, transport] of sortedTransports.entries()) {
            if (!Array.isArray(processed[workingIndex])) {
                processed[workingIndex] = [];
            }

            if (index === 0) {
                processed[workingIndex].push(transport);
            } else {
                const currentTransportRange = momentRange.range(
                    moment(transport.firstPlaceRta),
                    moment(transport.lastPlaceRta)
                );

                const overlapsSomeTransport = processed[workingIndex].some(t => {
                    const tRange = momentRange.range(moment(t.firstPlaceRta), moment(t.lastPlaceRta));
                    const overlaps = tRange.overlaps(currentTransportRange);
                    return overlaps;
                });

                if (overlapsSomeTransport) {
                    processed[workingIndex].push(transport);
                } else {
                    workingIndex += 1;
                    processed[workingIndex] = [];
                    processed[workingIndex].push(transport);
                }
            }
        }

        if (sortByRating) {
            const sorted = processed.map(group =>
                group.sort((a, b) => Number(b.metadata?.score.value) - Number(a.metadata?.score.value))
            );
            return this._transpose(sorted);
        }

        // We need to transpose because we want to show transports in columns
        const transposed = this._transpose(processed);
        return transposed;
    }

    private async _fetchTransportWithRoute(transportId: string): Promise<Transport> {
        if (this.logic.demo().isActive) {
            const transport = this.logic.demo().data.transports.find(t => t.id === transportId);
            if (!transport) throw new Error('transport not found');
            return transport;
        } else {
            try {
                const transport = await this.logic
                    .api()
                    .transportApi.findOneV1TransportsTransportIdGet({ transportId });
                return transport;
            } catch (err) {
                console.error('Get transports err', err);
                throw err;
            }
        }
    }

    private async _fetchTransports(date: string): Promise<Transport[]> {
        const filterStates = [
            TransportState.Accepted,
            TransportState.Active,
            TransportState.New,
            TransportState.Planned,
            TransportState.Finished,
            TransportState.Delayed
        ];
        if (this.logic.demo().isActive) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.logic.demo().data.transports);
                }, 2e3);
            });
        } else {
            try {
                const transports = await this.logic.api().transportApi.findV1TransportsGet({
                    states: filterStates,
                    fromDate: moment(date).startOf('day').toDate(),
                    toDate: moment(date).add(5, 'days').endOf('day').toDate(),
                    subscribeDevice: this.logic.notification().device,
                    subscribeUser: this.logic.auth().keycloak.tokenParsed?.['sub'],
                    excludeFields: ['route'],
                    onlyCurrentVehicles: true
                });
                return transports;
            } catch (err) {
                console.error('Get transports err', err);
                throw err;
            }
        }
    }

    private async _fetchVehicleStates(deviceId: string): Promise<VehicleStateObject[]> {
        if (this.logic.demo().isActive) {
            return new Promise(resolve => {
                resolve(this.logic.demo().data.vehicleStates);
            });
        } else {
            return this.logic.vehiclesState().getData(deviceId);
        }
    }

    private async _attachVehicleToTransport(
        vehicleId: string,
        transportId: string,
        state: TransportState
    ): Promise<void> {
        await this.logic.api().transportApi.attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost({
            transportId: transportId,
            monitoredObjectType: MonitoredObjectFleetType.Vehicle,
            bodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost: {
                monitoredObject: Number(vehicleId),
                start: new Date(),
                state
            }
        });
    }

    private _sortVehicleProposalTransports(proposalTransports: TransportModel[]) {
        const dffTransportsProposalGenerated: TransportModel[] = [];
        const dffTransportsProposalWithoutGenerated: TransportModel[] = [];

        proposalTransports.forEach(p => {
            if (p.metadata?.proposal?.state === NegotiationState.Generated) {
                dffTransportsProposalGenerated.push(p);
            } else {
                dffTransportsProposalWithoutGenerated.push(p);
            }
        });

        const sortByScoreFunc = (a: TransportModel, b: TransportModel) =>
            Number(b.metadata?.score.value) - Number(a.metadata?.score.value);

        return [
            ...dffTransportsProposalWithoutGenerated.sort(sortByScoreFunc),
            ...dffTransportsProposalGenerated.sort(sortByScoreFunc).slice(0, 3)
        ];
    }
}
