import { Conf } from '../conf';
import ReactGA from 'react-ga';

type GoogleAnalyticsConf = Conf['googleAnalytics'];

export class GoogleAnalyticsLogic {
    readonly conf: GoogleAnalyticsConf;

    private readonly _active: boolean;

    constructor(conf: GoogleAnalyticsConf) {
        this.conf = conf;
        this._active = !!conf.trackingCode;
    }

    initialize(user: string) {
        if (this._active) {
            ReactGA.initialize(this.conf.trackingCode, {
                ...this.conf.options,
                gaOptions: {
                    ...this.conf.options.gaOptions,
                    userId: user
                }
            });
        }
    }

    pageview(page: string) {
        if (this._active) {
            ReactGA.pageview(page);
        }
    }

    pushEvent(category: string, action: string, label?: string) {
        if (this._active) {
            ReactGA.event({
                category,
                action,
                label
            });
        }
    }
}
