/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PerfectDriveScoreContextualPeriod
 */
export interface PerfectDriveScoreContextualPeriod {
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreContextualPeriod
     */
    crossSystemBestDriverOveralScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreContextualPeriod
     */
    crossSystemAvgDriverOveralScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreContextualPeriod
     */
    clientAvgDriverOveralScore: number;
    /**
     * 
     * @type {string}
     * @memberof PerfectDriveScoreContextualPeriod
     */
    month: string;
}

export function PerfectDriveScoreContextualPeriodFromJSON(json: any): PerfectDriveScoreContextualPeriod {
    return PerfectDriveScoreContextualPeriodFromJSONTyped(json, false);
}

export function PerfectDriveScoreContextualPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDriveScoreContextualPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'crossSystemBestDriverOveralScore': json['cross_system_best_driver_overal_score'],
        'crossSystemAvgDriverOveralScore': json['cross_system_avg_driver_overal_score'],
        'clientAvgDriverOveralScore': json['client_avg_driver_overal_score'],
        'month': json['month'],
    };
}

export function PerfectDriveScoreContextualPeriodToJSON(value?: PerfectDriveScoreContextualPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cross_system_best_driver_overal_score': value.crossSystemBestDriverOveralScore,
        'cross_system_avg_driver_overal_score': value.crossSystemAvgDriverOveralScore,
        'client_avg_driver_overal_score': value.clientAvgDriverOveralScore,
        'month': value.month,
    };
}


