import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Control } from 'common/components/Form/Control';
import { Field, FieldProps } from 'formik';
import { CheckboxProps } from 'antd/lib/checkbox';
import { ReactNode } from 'react';

import { Checkbox } from '../../components/Form';

interface Props extends CheckboxProps {
    name: string;
    label?: ReactNode;
    error?: ReactNode;
    children: ReactNode;
    qa?: string;
    onChange?: () => void;
}

const CheckboxField = ({ label, error, name, qa, onChange, ...bag }: Props) => (
    <Field name={name}>
        {({ field: { value, ...fieldBag }, form }: FieldProps<any>) => {
            const handleChange = (e: CheckboxChangeEvent) => {
                form.setFieldValue(name, e.target.checked);
                onChange?.();
            };
            return (
                <Control label={label} error={error}>
                    <Checkbox
                        checked={value}
                        value={Boolean(value)}
                        qa={qa}
                        {...fieldBag}
                        {...bag}
                        onChange={handleChange}
                    />
                </Control>
            );
        }}
    </Field>
);

export default CheckboxField;
