/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectOdometersList
 */
export interface MonitoredObjectOdometersList {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOdometersList
     */
    monitoredObjectId: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectOdometersList
     */
    odometerState: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoredObjectOdometersList
     */
    timestamp: Date;
}

export function MonitoredObjectOdometersListFromJSON(json: any): MonitoredObjectOdometersList {
    return MonitoredObjectOdometersListFromJSONTyped(json, false);
}

export function MonitoredObjectOdometersListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOdometersList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'odometerState': json['odometer_state'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function MonitoredObjectOdometersListToJSON(value?: MonitoredObjectOdometersList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'odometer_state': value.odometerState,
        'timestamp': (value.timestamp.toISOString()),
    };
}


