/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WearGlobal,
    WearGlobalFromJSON,
    WearGlobalFromJSONTyped,
    WearGlobalToJSON,
    WearReportSummary,
    WearReportSummaryFromJSON,
    WearReportSummaryFromJSONTyped,
    WearReportSummaryToJSON,
    WearReportType,
    WearReportTypeFromJSON,
    WearReportTypeFromJSONTyped,
    WearReportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Wear
 */
export interface Wear {
    /**
     * 
     * @type {WearGlobal}
     * @memberof Wear
     */
    wearGlobal: WearGlobal;
    /**
     * 
     * @type {WearReportSummary}
     * @memberof Wear
     */
    wearReportSummary: WearReportSummary;
    /**
     * 
     * @type {WearReportType}
     * @memberof Wear
     */
    wearReportType1: WearReportType;
    /**
     * 
     * @type {WearReportType}
     * @memberof Wear
     */
    wearReportType2: WearReportType;
    /**
     * 
     * @type {WearReportType}
     * @memberof Wear
     */
    wearReportType3: WearReportType;
    /**
     * 
     * @type {WearReportType}
     * @memberof Wear
     */
    wearReportType4: WearReportType;
}

export function WearFromJSON(json: any): Wear {
    return WearFromJSONTyped(json, false);
}

export function WearFromJSONTyped(json: any, ignoreDiscriminator: boolean): Wear {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wearGlobal': WearGlobalFromJSON(json['wear_global']),
        'wearReportSummary': WearReportSummaryFromJSON(json['wear_report_summary']),
        'wearReportType1': WearReportTypeFromJSON(json['wear_report_type_1']),
        'wearReportType2': WearReportTypeFromJSON(json['wear_report_type_2']),
        'wearReportType3': WearReportTypeFromJSON(json['wear_report_type_3']),
        'wearReportType4': WearReportTypeFromJSON(json['wear_report_type_4']),
    };
}

export function WearToJSON(value?: Wear | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wear_global': WearGlobalToJSON(value.wearGlobal),
        'wear_report_summary': WearReportSummaryToJSON(value.wearReportSummary),
        'wear_report_type_1': WearReportTypeToJSON(value.wearReportType1),
        'wear_report_type_2': WearReportTypeToJSON(value.wearReportType2),
        'wear_report_type_3': WearReportTypeToJSON(value.wearReportType3),
        'wear_report_type_4': WearReportTypeToJSON(value.wearReportType4),
    };
}


