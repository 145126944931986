import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import { Pagination, Table } from 'common/components';
import Tag from 'common/components/Tag';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import {
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyUser,
    ReadOnlyUserNested,
    UserRole
} from 'generated/new-main/models';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { DefaultDriverInfo } from 'generated/backend-api';
import { getRoleName } from 'common/utils/components/RoleName';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    data?: PaginatedResponse<ReadOnlyUserNested[]>;
    loading?: boolean;
    selected?: ReadOnlyUserNested;
    lastCreatedUser?: ReadOnlyUser;
    groups: ReadOnlyMonitoredObjectGroupNested[];
    onPaginationChange?: (pagination: PaginationParams) => void;
    onTableRowSelect?: (id?: number) => void;
}

function UsersTable(props: Props) {
    function onClickRow(id?: number) {
        props.onTableRowSelect?.(id);
    }

    function createColumns(): ColumnsType<ReadOnlyUserNested> {
        return [
            {
                title: props.t('common.name'),
                dataIndex: 'name',
                key: 'name',
                width: 30,
                render: name => <span data-qa={qa.users.table.fieldName}>{name}</span>
            },
            {
                title: props.t('common.surname'),
                dataIndex: 'surname',
                key: 'surname',
                width: 60,
                render: surname => <span data-qa={qa.users.table.fieldSurname}>{surname}</span>
            },
            {
                title: props.t('ManagementUsers.emailLogin'),
                dataIndex: 'contact',
                key: 'emailLogin',
                width: 60,
                render: contact => <span data-qa={qa.users.table.fieldEmail}>{(contact as any)?.emails[0]}</span>
            },
            {
                title: props.t('common.phone'),
                dataIndex: 'contact',
                key: 'phone',
                align: 'center',
                width: 45,
                render: contact => <span data-qa={qa.users.table.fieldPhone}>{(contact as any)?.phone_numbers[0]}</span>
            },
            {
                title: props.t('common.defaultVehicle'),
                dataIndex: 'defaultDriver',
                key: 'defaultDriver',
                align: 'center',
                width: 45,
                render: defaultDriver => (
                    <span data-qa={qa.users.table.fieldDefaultVehicle}>
                        {(defaultDriver as DefaultDriverInfo[])
                            ?.map(di =>
                                di.monitoredObjectDisabledAt ? `*${di.registrationNumber}` : di.registrationNumber
                            )
                            .join(', ')}
                    </span>
                )
            },
            {
                title: props.t('common.groups'),
                width: 70,
                render: (_, record) => (
                    <span className="management-fleet-table-services-tags">
                        {record.monitoredObjectGroups?.map((groupId, i) => (
                            <Tag key={i} qa={qa.users.table.fieldVehicleGroups}>
                                {props.groups.find(group => group.id === groupId)?.name}
                            </Tag>
                        ))}
                    </span>
                )
            },
            {
                title: props.t('ManagementUsers.userType'),
                dataIndex: 'userRoles',
                width: 45,
                render: (userRoles: UserRole[], record) => {
                    return (
                        <>
                            {userRoles?.map((userRole, i) => (
                                <Tag
                                    className={cn({
                                        'ant-tag-last-created': props.lastCreatedUser?.id === record.id
                                    })}
                                    qa={qa.users.table.fieldRoles}
                                    key={i}
                                >
                                    {getRoleName(userRole)}
                                </Tag>
                            ))}
                        </>
                    );
                }
            }
        ];
    }

    return (
        <div className="management-users-table">
            <Table<ReadOnlyUserNested>
                loading={props.loading}
                dataSource={props.data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id),
                    className: cn({
                        'ant-table-row-selected': props.selected?.id === record.id,
                        'ant-table-row-last-created': props.lastCreatedUser?.id === record.id
                    })
                })}
            />

            {!props.loading &&
            props.data?.total &&
            props.data?.total > 0 &&
            props.data?.total > props.data?.limit &&
            props.data?.offset !== undefined &&
            props.data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={props.data?.total}
                        pageSize={props.data?.limit}
                        current={getTableCurrentPage(props.data.offset, props.data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );
}

export default withTranslation()(UsersTable);
