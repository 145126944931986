/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Availability,
    AvailabilityFromJSON,
    AvailabilityFromJSONTyped,
    AvailabilityToJSON,
    CompanyContact,
    CompanyContactFromJSON,
    CompanyContactFromJSONTyped,
    CompanyContactToJSON,
    Load,
    LoadFromJSON,
    LoadFromJSONTyped,
    LoadToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface LoadingInformation
 */
export interface LoadingInformation {
    /**
     * 
     * @type {Location}
     * @memberof LoadingInformation
     */
    location: Location;
    /**
     * 
     * @type {Availability}
     * @memberof LoadingInformation
     */
    loadingWindow: Availability;
    /**
     * 
     * @type {Array<Load>}
     * @memberof LoadingInformation
     */
    loads: Array<Load>;
    /**
     * 
     * @type {CompanyContact}
     * @memberof LoadingInformation
     */
    contact?: CompanyContact;
    /**
     * 
     * @type {string}
     * @memberof LoadingInformation
     */
    note?: string;
}

export function LoadingInformationFromJSON(json: any): LoadingInformation {
    return LoadingInformationFromJSONTyped(json, false);
}

export function LoadingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadingInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': LocationFromJSON(json['location']),
        'loadingWindow': AvailabilityFromJSON(json['loadingWindow']),
        'loads': ((json['loads'] as Array<any>).map(LoadFromJSON)),
        'contact': !exists(json, 'contact') ? undefined : CompanyContactFromJSON(json['contact']),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function LoadingInformationToJSON(value?: LoadingInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationToJSON(value.location),
        'loadingWindow': AvailabilityToJSON(value.loadingWindow),
        'loads': ((value.loads as Array<any>).map(LoadToJSON)),
        'contact': CompanyContactToJSON(value.contact),
        'note': value.note,
    };
}


