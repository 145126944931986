import moment from 'moment';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { JourneysFilterModel, JourneysModel } from '../../JourneysModule';
import { DATE_TIME_FORMAT, DWL_LOCK } from 'domain-constants';
import { SelectOpt } from 'common/components/AutoComplete';
import { DateRange } from 'common/model/date-time';
import { DatePicker, AutoComplete, Button } from 'common/components';
import { Col, Row } from 'antd';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import * as icons from 'resources/images/common';

interface Props extends WithTranslation {
    vehicleId?: string;
    vehicles: SelectOpt<number>[];
    trailerId?: string;
    trailers: SelectOpt<number>[];
    driver?: string;
    drivers: SelectOpt[];
    dateRange: DateRange;
    dateChanged?: boolean;
    exportLoading?: boolean;
    tableLoading?: boolean;
    expanded?: boolean;
    roles: Role[];
    rows?: JourneysModel[];
    fullDWL?: boolean;
    onFilterChange: (journeysFilter: JourneysFilterModel) => void;
    onExportClick: () => void;
    onVehicleTextChange?: (value: string) => void;
    onTrailerTextChange?: (value: string) => void;
    onDriverTextChange?: (value: string) => void;
}

interface State {
    vehicleId: number | null;
    trailerId: number | null;
    driver: string;
    dateRange: DateRange;
}

class JourneysFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehicleId: this.props.vehicleId ? Number(this.props.vehicleId) : null,
            trailerId: this.props.trailerId ? Number(this.props.trailerId) : null,
            driver: this.props.driver ?? '',
            dateRange: this.props.dateRange
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.vehicleId !== prevProps.vehicleId) {
            this.setState({ vehicleId: this.props.vehicleId ? Number(this.props.vehicleId) : null });
        }
        if (this.props.trailerId !== prevProps.trailerId) {
            this.setState({ trailerId: this.props.trailerId ? Number(this.props.trailerId) : null });
        }
        if (this.props.driver !== prevProps.driver) {
            this.setState({ driver: this.props.driver ?? '' });
        }
    }

    render() {
        const { t, vehicles, trailers, drivers } = this.props;
        const vehicleText = vehicles.find(o => o.code === this.state.vehicleId)?.label ?? '';
        const trailerText = trailers.find(o => o.code === this.state.trailerId)?.label ?? '';
        const driverText = drivers.find(o => o.code === this.state.driver)?.label ?? '';

        return (
            <div className="journeys-filter">
                <Row gutter={16} align="bottom">
                    <Col>
                        <label>{t('common.period')}</label>
                        <DatePicker.DateTimeRangePicker
                            defaultValue={[
                                moment(this.props.dateRange.start, DATE_TIME_FORMAT),
                                moment(this.props.dateRange.end, DATE_TIME_FORMAT)
                            ]}
                            minDate={
                                this.props.fullDWL
                                    ? moment()
                                          .subtract(DWL_LOCK - 1, 'days')
                                          .endOf('day')
                                          .toDate()
                                    : undefined
                            }
                            showTime
                            onShortcutConfirm
                            onChange={dateRange =>
                                this._onDateRangeChange({
                                    start: dateRange?.[0]?.format(DATE_TIME_FORMAT) ?? '',
                                    end: dateRange?.[1]?.format(DATE_TIME_FORMAT) ?? ''
                                })
                            }
                            trigger={
                                <ButtonDateRangeTimeTrigger
                                    withTime
                                    dateRange={this.props.dateRange}
                                    format={'l LT'}
                                    size="large"
                                    qa={qa.common.dateTimeRangePicker.btn}
                                />
                            }
                        />
                    </Col>
                    <Col>
                        <label>{t('common.vehicles')}</label>
                        <AutoComplete<number>
                            text={vehicleText}
                            options={vehicles}
                            autofocus
                            placeholder={this.props.t('JourneysFilter.selectVehicle')}
                            qa={qa.journeys.filter.inputVehicle}
                            onOptionSelect={this._onVehicleOptionSelect}
                            onTextChange={this._onVehicleTextChange}
                        />
                    </Col>
                    <Col>
                        <label>{t('common.trailers')}</label>
                        <AutoComplete<number>
                            text={trailerText}
                            options={trailers}
                            placeholder={this.props.t('JourneysFilter.selectTrailer')}
                            qa={qa.journeys.filter.inputTrailer}
                            onOptionSelect={this._onTrailerOptionSelect}
                            onTextChange={this._onTrailerTextChange}
                        />
                    </Col>
                    <Col>
                        <label>{t('common.drivers')}</label>
                        <AutoComplete
                            text={driverText}
                            options={drivers}
                            placeholder={this.props.t('JourneysFilter.selectDriver')}
                            qa={qa.journeys.filter.inputDriver}
                            onOptionSelect={this._onDriverOptionSelect}
                            onTextChange={this._onDriverTextChange}
                        />
                    </Col>
                    {this.props.expanded && this.props.rows && this.props.rows?.length > 0 && !this.props.tableLoading && (
                        <Col>
                            <Button
                                disabled={!this.props.roles.includes(Role.JAE_R)}
                                loading={this.props.exportLoading}
                                className="journeys-filter-export"
                                type="default"
                                onClick={this._onExportClick}
                                qa={qa.journeys.filter.btnExport}
                                icon={<img src={icons.export} alt={t('common.export')} />}
                            >
                                {this.props.exportLoading ? `${t('common.loading')}...` : t('JourneysFilter.export')}
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }

    private _onExportClick = (): void => {
        this.props.onExportClick?.();
    };

    private _onDateRangeChange = (dateRange: DateRange): void => {
        this.setState(
            {
                dateRange: {
                    start: moment(dateRange.start, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT),
                    end: moment(dateRange.end, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT)
                }
            },
            () => {
                this.props.onFilterChange(this.state);
            }
        );
    };

    private _onVehicleOptionSelect = (value: number | null): void => {
        this.setState({ vehicleId: value }, () => {
            this.props.onFilterChange(this.state);
        });
    };

    private _onVehicleTextChange = (value: string): void => {
        this.props.onVehicleTextChange?.(value);

        if (value === '' && this.state.vehicleId !== null) {
            this.setState({ vehicleId: null }, () => {
                this.props.onFilterChange(this.state);
            });
        }
    };

    private _onTrailerOptionSelect = (value: number | null): void => {
        this.setState({ trailerId: value }, () => {
            this.props.onFilterChange(this.state);
        });
    };

    private _onTrailerTextChange = (value: string): void => {
        this.props.onTrailerTextChange?.(value);

        if (value === '' && this.state.trailerId !== null) {
            this.setState({ trailerId: null }, () => {
                this.props.onFilterChange(this.state);
            });
        }
    };

    private _onDriverOptionSelect = (value: string | null): void => {
        this.setState({ driver: value ?? '' }, () => {
            this.props.onFilterChange(this.state);
        });
    };

    private _onDriverTextChange = (value: string): void => {
        this.props.onDriverTextChange?.(value);

        if (value === '' && this.state.driver !== '') {
            this.setState({ driver: value }, () => {
                this.props.onFilterChange(this.state);
            });
        }
    };
}

export default withTranslation()(JourneysFilter);
