import { Component, ReactElement } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Col, Popover, Row, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { AetrInterval } from 'modules/statistics/modules/aetr/AetrModule';
import Tooltip from 'common/components/Tooltip';
import * as aetrIcons from 'resources/images/aetr';

export enum ActivityToActivityName {
    D = 'driving',
    R = 'resting',
    W = 'working',
    A = 'available'
}

interface Props extends WithTranslation {
    key?: number;
    tooltip?: boolean;
    table?: boolean;
    maxWidth?: number;
    performances?: AetrInterval[];
}

interface State {
    tableShow: boolean;
}

class PerformanceBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tableShow: false
        };
    }

    render() {
        return (
            <>
                <div
                    key={this.props.key}
                    className={cn('performance-bar', 'progress-bar', { pointer: this.props.table })}
                    onClick={this._onProgressBarClick}
                >
                    <div className="bar">
                        {this.props.performances?.map((p, i) =>
                            this.props.tooltip ? (
                                <Popover
                                    key={i}
                                    placement="top"
                                    content={
                                        <div className="performance-bar-content">
                                            <img
                                                src={aetrIcons[ActivityToActivityName[p.activity]]}
                                                alt={this.props.t(`AetrTable.${ActivityToActivityName[p.activity]}`)}
                                            />{' '}
                                            {this.props.t(`AetrTable.${ActivityToActivityName[p.activity]}`)}
                                            <div>
                                                {`${moment.utc(p.startTime).format('LT')} - ${moment
                                                    .utc(p.endTime)
                                                    .format('LT')}`}
                                            </div>
                                            {p.startLocation && <div>{p.startLocation}</div>}
                                            {p.endLocation && <div>{p.endLocation}</div>}
                                        </div>
                                    }
                                    trigger="hover"
                                >
                                    {this._tachoPeriod(p, i)}
                                </Popover>
                            ) : (
                                this._tachoPeriod(p, i)
                            )
                        )}
                    </div>
                </div>
                {this.state.tableShow && (
                    <Table<AetrInterval>
                        showHeader={true}
                        dataSource={this.props.performances?.map((e, i) => ({ ...e, key: i }))}
                        columns={this._createColumns()}
                        className="performance-bar-table"
                        pagination={false}
                        onRow={row => ({ className: cn(`${ActivityToActivityName[row.activity]}`) })}
                    />
                )}
            </>
        );
    }

    private _onProgressBarClick = () => {
        if (this.props.table && this.props.performances?.length) {
            this.setState(state => ({
                tableShow: !state.tableShow
            }));
        }
    };

    private _tachoPeriod = (p: AetrInterval, index: number): ReactElement => {
        return (
            <div
                key={index}
                className={ActivityToActivityName[p.activity]}
                style={{
                    width: `${this._getWidth(p.startTime, p.endTime)}%`,
                    marginLeft: `${this._getWidth(
                        this.props.performances?.[index - 1]
                            ? this.props.performances?.[index - 1].endTime
                            : moment.utc(p.startTime).startOf('day').toISOString(),
                        p.startTime
                    )}%`
                }}
            />
        );
    };

    private _getWidth = (startTime: string, endTime: string): number => {
        return (moment.duration(moment.utc(endTime).diff(moment.utc(startTime))).asMinutes() / (60 * 24)) * 100;
    };

    private _createColumns = (): ColumnType<AetrInterval>[] => {
        const { t } = this.props;
        return [
            {
                title: this.props.t('AetrTable.activity'),
                dataIndex: 'activity',
                key: 'activity',
                width: 70,
                align: 'center',
                render: (_, record) => (
                    <Row justify="center">
                        <Col span={12}>
                            <img
                                src={aetrIcons[ActivityToActivityName[record.activity]]}
                                alt={t(`AetrTable.${ActivityToActivityName[record.activity]}`)}
                            />
                        </Col>
                        <Col span={12}>{this.props.t(`AetrTable.${ActivityToActivityName[record.activity]}`)}</Col>
                    </Row>
                )
            },
            {
                title: t('AetrTable.startTime'),
                dataIndex: 'start',
                key: 'start',
                width: 100,
                align: 'center',
                render: (_, record) => moment.utc(record.startTime).format('LT')
            },
            {
                title: t('AetrTable.endTime'),
                dataIndex: 'end',
                key: 'end',
                width: 100,
                align: 'center',
                render: (_, record) => moment.utc(record.endTime).format('LT')
            },
            {
                title: t('common.duration'),
                dataIndex: 'duration',
                key: 'duration',
                width: 100,
                align: 'center',
                render: (_, record, index) => {
                    return (
                        <div>
                            {moment.duration(moment(record.endTime).diff(moment(record.startTime))).format('hh:mm', {
                                trim: false
                            })}
                            {index === 0 && (
                                <Tooltip
                                    overlay={t('AetrTable.utcWarning')}
                                    children={<i className="utc-warning fas fa-info-circle" />}
                                />
                            )}
                        </div>
                    );
                }
            }
        ] as ColumnType<AetrInterval>[];
    };
}

export default withTranslation()(PerformanceBar);
