/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeFromJSONTyped,
    ExternalDeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MuStreamEditExternalDevice
 */
export interface MuStreamEditExternalDevice {
    /**
     * 
     * @type {string}
     * @memberof MuStreamEditExternalDevice
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MuStreamEditExternalDevice
     */
    deviceId?: string;
    /**
     * 
     * @type {ExternalDeviceType}
     * @memberof MuStreamEditExternalDevice
     */
    deviceType: ExternalDeviceType;
}

export function MuStreamEditExternalDeviceFromJSON(json: any): MuStreamEditExternalDevice {
    return MuStreamEditExternalDeviceFromJSONTyped(json, false);
}

export function MuStreamEditExternalDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MuStreamEditExternalDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'deviceType': ExternalDeviceTypeFromJSON(json['device_type']),
    };
}

export function MuStreamEditExternalDeviceToJSON(value?: MuStreamEditExternalDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serialNumber,
        'device_id': value.deviceId,
        'device_type': ExternalDeviceTypeToJSON(value.deviceType),
    };
}


