import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { Row, Col } from 'antd';

import { roundToStep } from 'common/utils/averages';
import { userCircle } from 'resources/images/common';
import { Table, Rate, Loading, Progress, Sorter } from 'common/components';
import { DriverBehaviorTrendsModel } from 'common/model/statistics';
import qa from 'qa-selectors';
import { ColumnType } from 'antd/lib/table';
import { TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';

interface Props {
    data: DriverBehaviorTrendsModel[];
    loading: boolean;
    onRowClick?: (id: string, name?: string) => void;
    qa?: string;
}

export default function DriverBehaviorTrendsTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="driver-behavior-table">
            {props.loading ? (
                <Loading />
            ) : (
                <Table<DriverBehaviorTrendsModel>
                    scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
                    columns={[
                        {
                            title: t('DriverBehaviorTable.cols.rank'),
                            key: 'rank',
                            dataIndex: 'rank',
                            width: 60,
                            align: 'center',
                            render: value => <div data-qa={qa.driverBehaviour.trucks.table.fieldRank}>{value}</div>
                        },
                        {
                            title: t('DriverBehaviorTable.cols.name'),
                            key: 'name',
                            dataIndex: 'name',
                            className: 'driver-behavior-table-name',
                            align: 'left',
                            width: 150,
                            render: value => (
                                <Row align="middle" gutter={16}>
                                    <Col>
                                        <Avatar icon={<img src={userCircle} alt="user" />} />
                                    </Col>
                                    <Col>
                                        <span data-qa={qa.driverBehaviour.trucks.table.fieldName}>{value}</span>
                                    </Col>
                                </Row>
                            )
                        },
                        {
                            key: 'score',
                            sort: true,
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'score');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.trucks.table.headingDrive}>
                                            {t('DriverBehaviorTable.filters.overal')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            qa: qa.driverBehaviour.trucks.table.headingScore,
                            dataIndex: 'score',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => (a.scores[0]?.score ?? 0) - (b.scores[0]?.score ?? 0),
                            render: (_, row) => (
                                <Row justify="space-between" align="middle">
                                    {row.scores[0]?.score && (
                                        <>
                                            <Col
                                                data-qa={qa.driverBehaviour.trucks.table.fieldScore}
                                                className="driver-behavior-table-score"
                                            >
                                                <Rate
                                                    size="default"
                                                    value={roundToStep(row.scores[0]?.score, 0.5) * 0.05}
                                                    disabled
                                                    allowHalf
                                                />
                                            </Col>
                                            <Col>
                                                <span>{roundToStep(row.scores[0]?.score, 1)}</span>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            )
                        } as ColumnType<DriverBehaviorTrendsModel>,
                        {
                            key: 'driveScore',
                            sort: true,
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'driveScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.trucks.table.headingDrive}>
                                            {t('DriverBehaviorTable.filters.driving')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            qa: qa.driverBehaviour.trucks.table.headingDrive,
                            dataIndex: 'driveScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => (a.scores[0]?.driveScore ?? 0) - (b.scores[0]?.driveScore ?? 0),
                            render: (_, row) => (
                                <Row align="middle" gutter={8}>
                                    {row.scores[0].driveScore && (
                                        <>
                                            <Col flex="auto">
                                                <Progress
                                                    percent={row.scores[0].driveScore}
                                                    showInfo={false}
                                                    strokeWidth={11}
                                                    status="normal"
                                                    color="blue"
                                                />
                                            </Col>
                                            <Col data-qa={qa.driverBehaviour.trucks.table.fieldDrive} flex="50px">
                                                {roundToStep(row.scores[0].driveScore, 1)}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            )
                        } as ColumnType<DriverBehaviorTrendsModel>,
                        {
                            key: 'ecoScore',
                            sort: true,
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'ecoScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.trucks.table.headingDrive}>
                                            {t('DriverBehaviorTable.filters.eco')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            qa: qa.driverBehaviour.trucks.table.headingEco,
                            dataIndex: 'ecoScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => (a.scores[0]?.ecoScore ?? 0) - (b.scores[0]?.ecoScore ?? 0),
                            render: (_, row) => (
                                <Row align="middle" gutter={8}>
                                    {row.scores[0].ecoScore && (
                                        <>
                                            <Col flex="auto">
                                                <Progress
                                                    percent={row.scores[0].ecoScore}
                                                    showInfo={false}
                                                    strokeWidth={11}
                                                    status="normal"
                                                    color="green"
                                                />
                                            </Col>
                                            <Col data-qa={qa.driverBehaviour.trucks.table.fieldEco} flex="50px">
                                                {roundToStep(row.scores[0].ecoScore, 1)}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            )
                        } as ColumnType<DriverBehaviorTrendsModel>,
                        {
                            key: 'wearTearScore',
                            sort: true,
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'wearTearScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.trucks.table.headingDrive}>
                                            {t('DriverBehaviorTable.filters.wearTear')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            qa: qa.driverBehaviour.trucks.table.headingWearTear,
                            dataIndex: 'wearTearScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => (a.scores[0]?.wearTearScore ?? 0) - (b.scores[0]?.wearTearScore ?? 0),
                            render: (_, row) => (
                                <Row align="middle" gutter={8}>
                                    {row.scores[0].wearTearScore && (
                                        <>
                                            <Col flex="auto">
                                                <Progress
                                                    percent={row.scores[0].wearTearScore}
                                                    showInfo={false}
                                                    strokeWidth={11}
                                                    status="normal"
                                                    color="yellow"
                                                />
                                            </Col>
                                            <Col data-qa={qa.driverBehaviour.trucks.table.fieldWearTear} flex="50px">
                                                {roundToStep(row.scores[0].wearTearScore, 1)}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            )
                        } as ColumnType<DriverBehaviorTrendsModel>
                    ]}
                    dataSource={props.data}
                    onRow={record => ({
                        onClick: () => props.onRowClick?.(String(record.driverId), record.name)
                    })}
                />
            )}
        </div>
    );
}
