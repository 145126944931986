import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Col, Row } from 'antd';
import { Select } from 'common/components/Form';
import { AutocompleteOpt, BorderCrossingFilterModel } from '../../StatisticsBorderCrossingModule';
import { CountrySelectModel } from 'logic/partner/partner';
import { DateRange } from 'common/model/date-time';
import { AutoComplete, Button, DatePicker } from 'common/components';
import { Role } from 'logic/auth';
import { DATE_FORMAT } from 'domain-constants';
import qa from 'qa-selectors';
import { searched } from 'common/utils/search';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import * as icons from 'resources/images/common';

interface Props extends WithTranslation {
    roles: Role[];
    vehicle?: string;
    vehicleOpts: AutocompleteOpt[];
    driver?: string;
    driverOpts: AutocompleteOpt[];
    countries?: string[];
    countryOpts: CountrySelectModel[];
    dateRange: DateRange;
    dateChanged?: boolean;
    loading: boolean;
    exportEnable: boolean;
    exportLoading: boolean;
    onFilterChange?: (filter: BorderCrossingFilterModel) => void;
    onVehicleTextChange?: (value: string) => void;
    onDriverTextChange?: (value: string) => void;
    onExport?: () => void;
}

interface State {
    vehicleText: string;
    driverText: string;
    dateRange: DateRange;
}

class BorderCrossingFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehicleText: this.props.vehicleOpts.find(e => e.code === this.props.vehicle)?.label ?? '',
            driverText: this.props.driverOpts.find(e => e.code === this.props.driver)?.label ?? '',
            dateRange: this.props.dateRange
        };
    }
    render() {
        const { t, vehicleOpts, driverOpts, countries, countryOpts, dateRange, loading } = this.props;
        return (
            <div className="border-crossing-filter">
                <Row align="bottom" justify="space-between" gutter={[16, 0]}>
                    <Col>
                        <Row gutter={16} align="bottom">
                            <Col className="border-crossing-filter-datepicker">
                                <label>{t('common.period')}</label>
                                <DatePicker.DateTimeRangePicker
                                    disabled={loading}
                                    clearIcon={false}
                                    defaultValue={[
                                        moment.utc(dateRange.start, DATE_FORMAT),
                                        moment.utc(dateRange.end, DATE_FORMAT)
                                    ]}
                                    onChange={this._onDateRangeChange}
                                    format={'L'}
                                    className="extra-space-right"
                                    trigger={
                                        <ButtonDateRangeTimeTrigger
                                            dateRange={dateRange}
                                            qa={qa.common.dateTimeRangePicker.btn}
                                        />
                                    }
                                />
                            </Col>
                            <Col className="border-crossing-filter-vehicles">
                                <label>{t('common.vehicles')}</label>
                                <AutoComplete
                                    text={this.state.vehicleText}
                                    disabled={loading || this.state.driverText ? true : false}
                                    options={vehicleOpts}
                                    qa={qa.borderCrossing.filter.inputVehicle}
                                    autofocus
                                    placeholder={t('common.typeRN')}
                                    width={200}
                                    onOptionSelect={this._onVehicleOptionSelect}
                                    onTextChange={this._onVehicleTextChange}
                                />
                            </Col>
                            <Col className="border-crossing-filter-drivers">
                                <label>{t('common.drivers')}</label>
                                <AutoComplete
                                    text={this.state.driverText}
                                    disabled={loading || this.state.vehicleText ? true : false}
                                    options={driverOpts}
                                    qa={qa.borderCrossing.filter.inputDriver}
                                    placeholder={t('common.typeDriversName')}
                                    width={200}
                                    onOptionSelect={this._onDriverOptionSelect}
                                    onTextChange={this._onDriverTextChange}
                                />
                            </Col>
                            <Col>
                                <label>{t('BorderCrossingFilter.countries')}</label>
                                <Select
                                    className="border-crossing-filter-countries"
                                    disabled={
                                        loading || (this.state.vehicleText === '' && this.state.driverText === '')
                                    }
                                    showSearch
                                    placeholder={t('common.typeCountry')}
                                    defaultValue={countries}
                                    qa={qa.borderCrossing.filter.inputCountry}
                                    size="large"
                                    mode="multiple"
                                    allowClear
                                    onSelect={this._onCountryOptionSelect}
                                    onDeselect={this._onCountryOptionDeselect}
                                    onClear={this._onCountriesClear}
                                    filterOption={(input, option) => searched(input, String(option?.children))}
                                    maxTagCount={'responsive'}
                                >
                                    {countryOpts.map(country => (
                                        <Select.Option key={country.code} value={country.code}>
                                            <div
                                                className={`flag-icon flag-icon-${String(
                                                    country.code
                                                ).toLocaleLowerCase()}`}
                                                data-qa={qa.borderCrossing.filter.fieldFlag}
                                                data-country-code={country.code}
                                            />
                                            {` ${t(`common.countries.${country.code}`)} (${country.code})`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>

                    {
                        // this.props.roles.includes(Role.BC_IE), @spanko Oki tak radšej takto nech to vidia,
                        // TODO: fix right after BE is done: https://jira.eurowag.com/browse/T033-9030
                        this.props.roles.includes(Role.BC_R) && (
                            <Col>
                                <Button
                                    size="large"
                                    loading={this.props.exportLoading}
                                    disabled={!this.props.exportEnable}
                                    className="border-crossing-filter-export-btn"
                                    type="primary"
                                    qa={qa.borderCrossing.filter.btnExport}
                                    icon={<img src={icons.export} alt={t('common.export')} />}
                                    onClick={this._onExport}
                                >
                                    {this.props.t('common.export')}
                                </Button>
                            </Col>
                        )
                    }
                </Row>
            </div>
        );
    }

    private _onExport = (): void => {
        this.props.onExport?.();
    };

    private _onDateRangeChange = (values: [Moment | null, Moment | null] | null): void => {
        if (values) {
            const [start, end] = values;
            if (start || end) {
                this.setState(
                    state => ({
                        dateRange: {
                            start: start ? start.format(DATE_FORMAT) : state.dateRange.start,
                            end: end ? end.format(DATE_FORMAT) : state.dateRange.end
                        }
                    }),
                    () => {
                        this.props.onFilterChange?.({
                            vehicle: this.props.vehicle,
                            driver: this.props.driver,
                            countries: this.props.countries,
                            dateRange: this.state.dateRange
                        });
                    }
                );
            }
        }
    };

    private _onVehicleOptionSelect = (value: string | null): void => {
        this.setState({ vehicleText: this.props.vehicleOpts.find(e => e.code === value)?.label || '' }, () => {
            this.props.onFilterChange?.({
                vehicle: value ?? '',
                driver: this.props.driver,
                countries: this.props.countries,
                dateRange: this.props.dateRange
            });
        });
    };

    private _onVehicleTextChange = (value: string): void => {
        this.props.onVehicleTextChange?.(value);

        if (value === '' && this.state.vehicleText !== '') {
            this.setState({ vehicleText: value }, () => {
                this.props.onFilterChange?.(this.state);
            });
        }
    };

    private _onDriverOptionSelect = (value: string | null): void => {
        this.setState({ driverText: this.props.driverOpts.find(e => e.code === value)?.label || '' }, () => {
            this.props.onFilterChange?.({
                vehicle: this.props.vehicle,
                driver: value ?? '',
                countries: this.props.countries,
                dateRange: this.props.dateRange
            });
        });
    };

    private _onDriverTextChange = (value: string): void => {
        this.props.onDriverTextChange?.(value);

        if (value === '' && this.state.driverText !== '') {
            this.setState({ driverText: value }, () => {
                this.props.onFilterChange?.(this.state);
            });
        }
    };

    private _onCountryOptionSelect = (value: string): void => {
        this.props.onFilterChange?.({
            vehicle: this.props.vehicle,
            driver: this.props.driver,
            countries: this.props.countries ? [...this.props.countries, value] : [value],
            dateRange: this.props.dateRange
        });
    };
    private _onCountryOptionDeselect = (value: string): void => {
        this.props.onFilterChange?.({
            vehicle: this.props.vehicle,
            driver: this.props.driver,
            countries: this.props.countries?.filter(c => c !== value),
            dateRange: this.props.dateRange
        });
    };

    private _onCountriesClear = (): void => {
        this.props.onFilterChange?.({
            vehicle: this.props.vehicle,
            driver: this.props.driver,
            countries: [],
            dateRange: this.props.dateRange
        });
    };
}

export default withTranslation()(BorderCrossingFilter);
