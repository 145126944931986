/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyObePersonalizationObePersonalizationPost,
    BodyObePersonalizationObePersonalizationPostFromJSON,
    BodyObePersonalizationObePersonalizationPostToJSON,
    BodyObeProvisioningObeProvisioningPost,
    BodyObeProvisioningObeProvisioningPostFromJSON,
    BodyObeProvisioningObeProvisioningPostToJSON,
    BodyServiceContractProvisioningServiceContractProvisioningPost,
    BodyServiceContractProvisioningServiceContractProvisioningPostFromJSON,
    BodyServiceContractProvisioningServiceContractProvisioningPostToJSON,
    BodyTpRequestTpRequestPost,
    BodyTpRequestTpRequestPostFromJSON,
    BodyTpRequestTpRequestPostToJSON,
    BundlesAdminPermissions,
    BundlesAdminPermissionsFromJSON,
    BundlesAdminPermissionsToJSON,
    BundlesObePermissions,
    BundlesObePermissionsFromJSON,
    BundlesObePermissionsToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    WAGTPResponseWrap,
    WAGTPResponseWrapFromJSON,
    WAGTPResponseWrapToJSON,
} from '../models';

export interface AdminPermissionsAdminPermissionsPostRequest {
    messageId: string;
    bundlesAdminPermissions: BundlesAdminPermissions;
}

export interface ObePermissionsObePermissionsPostRequest {
    messageId: string;
    bundlesObePermissions: BundlesObePermissions;
    clientId?: number;
}

export interface ObePersonalizationObePersonalizationPostRequest {
    bodyObePersonalizationObePersonalizationPost: BodyObePersonalizationObePersonalizationPost;
}

export interface ObeProvisioningObeProvisioningPostRequest {
    bodyObeProvisioningObeProvisioningPost: BodyObeProvisioningObeProvisioningPost;
}

export interface ServiceContractProvisioningServiceContractProvisioningPostRequest {
    bodyServiceContractProvisioningServiceContractProvisioningPost: BodyServiceContractProvisioningServiceContractProvisioningPost;
}

export interface TpRequestTpRequestPostRequest {
    bodyTpRequestTpRequestPost: BodyTpRequestTpRequestPost;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Admin Permissions
     */
    async adminPermissionsAdminPermissionsPostRaw(requestParameters: AdminPermissionsAdminPermissionsPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling adminPermissionsAdminPermissionsPost.');
        }

        if (requestParameters.bundlesAdminPermissions === null || requestParameters.bundlesAdminPermissions === undefined) {
            throw new runtime.RequiredError('bundlesAdminPermissions','Required parameter requestParameters.bundlesAdminPermissions was null or undefined when calling adminPermissionsAdminPermissionsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.messageId !== undefined && requestParameters.messageId !== null) {
            headerParameters['messageId'] = String(requestParameters.messageId);
        }

        const response = await this.request({
            path: `/admin-permissions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BundlesAdminPermissionsToJSON(requestParameters.bundlesAdminPermissions),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Admin Permissions
     */
    async adminPermissionsAdminPermissionsPost(requestParameters: AdminPermissionsAdminPermissionsPostRequest): Promise<object> {
        const response = await this.adminPermissionsAdminPermissionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obe Permissions
     */
    async obePermissionsObePermissionsPostRaw(requestParameters: ObePermissionsObePermissionsPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling obePermissionsObePermissionsPost.');
        }

        if (requestParameters.bundlesObePermissions === null || requestParameters.bundlesObePermissions === undefined) {
            throw new runtime.RequiredError('bundlesObePermissions','Required parameter requestParameters.bundlesObePermissions was null or undefined when calling obePermissionsObePermissionsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.messageId !== undefined && requestParameters.messageId !== null) {
            headerParameters['messageId'] = String(requestParameters.messageId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/obe-permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BundlesObePermissionsToJSON(requestParameters.bundlesObePermissions),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Obe Permissions
     */
    async obePermissionsObePermissionsPost(requestParameters: ObePermissionsObePermissionsPostRequest): Promise<object> {
        const response = await this.obePermissionsObePermissionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obe Personalization
     */
    async obePersonalizationObePersonalizationPostRaw(requestParameters: ObePersonalizationObePersonalizationPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.bodyObePersonalizationObePersonalizationPost === null || requestParameters.bodyObePersonalizationObePersonalizationPost === undefined) {
            throw new runtime.RequiredError('bodyObePersonalizationObePersonalizationPost','Required parameter requestParameters.bodyObePersonalizationObePersonalizationPost was null or undefined when calling obePersonalizationObePersonalizationPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/obe-personalization/?`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyObePersonalizationObePersonalizationPostToJSON(requestParameters.bodyObePersonalizationObePersonalizationPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Obe Personalization
     */
    async obePersonalizationObePersonalizationPost(requestParameters: ObePersonalizationObePersonalizationPostRequest): Promise<object> {
        const response = await this.obePersonalizationObePersonalizationPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obe Provisioning
     */
    async obeProvisioningObeProvisioningPostRaw(requestParameters: ObeProvisioningObeProvisioningPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.bodyObeProvisioningObeProvisioningPost === null || requestParameters.bodyObeProvisioningObeProvisioningPost === undefined) {
            throw new runtime.RequiredError('bodyObeProvisioningObeProvisioningPost','Required parameter requestParameters.bodyObeProvisioningObeProvisioningPost was null or undefined when calling obeProvisioningObeProvisioningPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/obe-provisioning/?`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyObeProvisioningObeProvisioningPostToJSON(requestParameters.bodyObeProvisioningObeProvisioningPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Obe Provisioning
     */
    async obeProvisioningObeProvisioningPost(requestParameters: ObeProvisioningObeProvisioningPostRequest): Promise<object> {
        const response = await this.obeProvisioningObeProvisioningPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Service Contract Provisioning
     */
    async serviceContractProvisioningServiceContractProvisioningPostRaw(requestParameters: ServiceContractProvisioningServiceContractProvisioningPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.bodyServiceContractProvisioningServiceContractProvisioningPost === null || requestParameters.bodyServiceContractProvisioningServiceContractProvisioningPost === undefined) {
            throw new runtime.RequiredError('bodyServiceContractProvisioningServiceContractProvisioningPost','Required parameter requestParameters.bodyServiceContractProvisioningServiceContractProvisioningPost was null or undefined when calling serviceContractProvisioningServiceContractProvisioningPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/service-contract-provisioning/?`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyServiceContractProvisioningServiceContractProvisioningPostToJSON(requestParameters.bodyServiceContractProvisioningServiceContractProvisioningPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Service Contract Provisioning
     */
    async serviceContractProvisioningServiceContractProvisioningPost(requestParameters: ServiceContractProvisioningServiceContractProvisioningPostRequest): Promise<object> {
        const response = await this.serviceContractProvisioningServiceContractProvisioningPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tp Request
     */
    async tpRequestTpRequestPostRaw(requestParameters: TpRequestTpRequestPostRequest): Promise<runtime.ApiResponse<WAGTPResponseWrap>> {
        if (requestParameters.bodyTpRequestTpRequestPost === null || requestParameters.bodyTpRequestTpRequestPost === undefined) {
            throw new runtime.RequiredError('bodyTpRequestTpRequestPost','Required parameter requestParameters.bodyTpRequestTpRequestPost was null or undefined when calling tpRequestTpRequestPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tp-request/?`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyTpRequestTpRequestPostToJSON(requestParameters.bodyTpRequestTpRequestPost),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WAGTPResponseWrapFromJSON(jsonValue));
    }

    /**
     * Tp Request
     */
    async tpRequestTpRequestPost(requestParameters: TpRequestTpRequestPostRequest): Promise<WAGTPResponseWrap> {
        const response = await this.tpRequestTpRequestPostRaw(requestParameters);
        return await response.value();
    }

}
