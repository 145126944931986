import { Logic } from 'logic/logic';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subscription } from 'rxjs';
import i18n from 'i18next';

import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';
import { RouteNames } from 'App';

import { DriverBehaviorType } from '../../../components/DriverBehaviorBar';
import DriverBehaviorLightTable from '../../../components/DriverBehaviorLightTable';
import DriverBehaviorBar from '../../../components/DriverBehaviorBar';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import { searched } from 'common/utils/search';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
}

interface State {
    data: DriverBehaviorLightVehicleModel[];
    search: string;
    date: string;
    filter: DriverBehaviorType;
    loading: boolean;
    export: {
        processing: boolean;
        enable: boolean;
    };
    helper?: {
        content: string;
    };
}

class DriverListModule extends Component<Props, State> {
    subscriptionData?: Subscription;
    subscriptionLoading?: Subscription;
    subscriptionExport?: Subscription;

    constructor(props: Props) {
        super(props);
        this.state = {
            search: '',
            data: [],
            filter: DriverBehaviorType.Light,
            loading: true,
            export: {
                enable: false,
                processing: false
            },
            date: moment(moment.utc(this.props.logic.settings().getProp('driverBehavior').date).toLocaleString())
                .startOf('month')
                .toISOString()
        };
    }

    componentDidMount() {
        this.subscriptionData = this.props.logic
            .driverBehavior()
            .vehicles()
            .data.subscribe(data => {
                this.setState(state => ({
                    data,
                    export: {
                        ...state.export,
                        enable: true
                    }
                }));
            });

        this.subscriptionLoading = this.props.logic
            .driverBehavior()
            .vehicles()
            .loading.subscribe(loading => {
                this.setState({ loading });
            });

        this.subscriptionExport = this.props.logic
            .driverBehavior()
            .vehicles()
            .exportListProcessing.subscribe(processing => {
                this.setState(state => ({
                    export: {
                        ...state.export,
                        processing: processing
                    }
                }));
            });

        this.props.logic.driverBehavior().vehicles().loadData(this.state.date);
    }

    componentWillUnmount() {
        this.subscriptionData?.unsubscribe();
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionExport?.unsubscribe();
    }

    private _onBarDatePreviousClick = () => {
        const date = moment(this.state.date).subtract(1, 'month').toISOString();
        this.setState(
            {
                date
            },
            () => {
                this.props.logic.driverBehavior().trucks().saveDateToSettings(date);
                this.props.logic.driverBehavior().vehicles().loadData(date);
            }
        );
    };

    private _onBarDateNextClick = () => {
        const date = moment(this.state.date).add(1, 'month').toISOString();
        this.setState(
            {
                date
            },
            () => {
                this.props.logic.driverBehavior().trucks().saveDateToSettings(date);
                this.props.logic.driverBehavior().vehicles().loadData(date);
            }
        );
    };

    render() {
        const { data, search, loading } = this.state;
        return (
            <>
                <DriverBehaviorBar
                    date={this.state.date}
                    filter={this.state.filter}
                    searchEnabled={this.state.data.length > 0}
                    exportEnable={this.state.export.enable}
                    exportProcessing={this.state.export.processing}
                    clientFleetType={
                        this.props.logic.auth().newEWClient()?.fleetType ?? ReadOnlyClientFleetTypeEnum.Mixed
                    }
                    onDateRangeChange={this._onDateRangeChange}
                    onSearch={this._onSearch}
                    onFilterChange={this._onFilterChange}
                    onExportClick={this._onExportClick}
                    onHelperClick={this._onBarHelperClick}
                    onBarDateNextClick={this._onBarDateNextClick}
                    onBarDatePreviousClick={this._onBarDatePreviousClick}
                />
                <DriverBehaviorLightTable
                    data={data.filter(d => searched(search, d.name)).map((data, key) => ({ ...data, key }))}
                    loading={loading}
                    onRowClick={this._onTableRowClick}
                />
                <HelperModal
                    name="driver-behavior"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _onExportClick = () => {
        this.props.logic.driverBehavior().vehicles().downloadDriverBehaviorListExport();
    };

    private _onTableRowClick = (id: string) => {
        this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL + '/' + id);
    };

    private _onSearch = (search: string) => {
        this.setState({ search });
    };

    private _onDateRangeChange = (date: string) => {
        this.setState({ date, search: '', data: [] }, () => {
            this.props.logic.driverBehavior().trucks().saveDateToSettings(date);
            this.props.logic.driverBehavior().vehicles().loadData(this.state.date);
        });
    };

    private _onFilterChange = (filter: DriverBehaviorType) => {
        if (filter === DriverBehaviorType.Trucks) {
            this.props.history.push(RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS);
        }
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'driverbehaviour';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(DriverListModule));
