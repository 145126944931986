import cn from 'classnames';
import { CargoExpense } from 'logic/statistics/statistics-expenses';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ReadOnlyOdometerAdjustment } from 'generated/new-main';
import ExpensesCargo from 'modules/statistics/modules/expenses/ui/ExpensesCargo';
import { Button, Modal } from 'common/components';
import { LayoutContent } from 'common/components/Layout/Content';
import {
    ExpensesStats,
    JourneysFilterModel,
    JourneysModel,
    JourneysSumModel,
    State,
    OdometerAdjustmentData,
    IsPrivateData,
    JourneyGraphDataModel
} from '../JourneysModule';
import JourneysFilter from './JourneysFilter/JourneysFilter';
import JourneysSum from './JourneysSum';
import JourneysTable from './JourneysTable';
import { SelectOpt } from 'common/components/AutoComplete';
import { Role } from 'logic/auth';
import { ExpenseType } from 'generated/graphql';
import HelperModal from 'common/components/HelperModal';
import { DateRange } from 'common/model/date-time';
import TableBar from 'common/components/TableBar';
import qa from 'qa-selectors';
import JourneyGraph, { GraphKey } from './JourneyGraph';
import { Logic } from 'logic/logic';
import { useEffect, useState } from 'react';
import { TripTemperatureSensor } from 'generated/backend-api';
import { ArrowLeftFilled, ArrowRightFilled } from 'resources/images/journeys-activity';
import InfoBanner from 'common/components/InfoBanner';
import { DWL_LOCK } from 'domain-constants';

interface Props extends WithTranslation {
    bar: {
        expanded: boolean;
    };
    filter: {
        fullDWL?: boolean;
        vehicle?: string;
        vehiclesOpts: SelectOpt<number>[];
        trailer?: string;
        trailersOpts: SelectOpt<number>[];
        driver?: string;
        driversOpts: SelectOpt[];
        dateRange: DateRange;
        dateChanged?: boolean;
    };
    helper?: {
        content: string;
    };
    table: {
        data: {
            rows?: JourneysModel[];
            sum?: JourneysSumModel;
            expensesStats?: ExpensesStats[];
            odoAdjustements?: ReadOnlyOdometerAdjustment[];
            journeyGraphData?: JourneyGraphDataModel;
        };
        sensors?: TripTemperatureSensor[];
        selectedJourney?: string;
        loading: boolean;
        showConsumption?: boolean;
    };
    cargo?: {
        vehicleId?: number;
        vehicles: SelectOpt<number>[];
        suppliers: SelectOpt[];
        date: string;
        type?: ExpenseType;
    };
    exportLoading?: boolean;
    adjustment?: State['adjustment'];
    isPrivate?: State['isPrivate'];
    roles: Role[];
    journeyGraphVisible: boolean;
    showPremiumInfo?: boolean;
    showCoachInfo?: boolean;

    onClosePremiumInfoClick?: () => void;
    onCloseCoachInfoClick?: () => void;
    onCoachInfoLinkClick?: () => void;
    onIsPrivateEditIconClick?: (data: IsPrivateData) => void;
    onIsPrivateSelectChange?: (text: string) => void;
    onIsPrivateModalCancel?: () => void;
    onIsPrivateModalConfirm?: () => void;
    onAdjustmentEditIconClick?: (data: OdometerAdjustmentData) => void;
    onAdjustmentInputChange?: (text: string) => void;
    onAdjustmentInputKeyDown?: (key: string) => void;
    onAdjustmentModalCancel?: () => void;
    onAdjustmentModalConfirm?: () => void;
    onBarExpand: () => void;
    onBarHelperClick?: () => void;
    onCargoCancel: () => void;
    onCargoConfirm: (cargo: CargoExpense) => Promise<boolean>;
    onCargoSupplierChange: (value: string) => void;
    onExportClick: () => void;
    onFilterChange: (journeysFilter: JourneysFilterModel) => void;
    onFilterDriverTextChange?: (value: string) => void;
    onFilterTrailerTextChange?: (value: string) => void;
    onFilterVehicleTextChange?: (value: string) => void;
    onHelperClose?: () => void;
    onSumShowExpensesClick: () => void;
    onSumShowFuelClick: () => void;
    onTableCreateCargoClick: (date: string) => void;
    onTableJourneySelect: (id: string) => void;
    onTableRowExpand: (id: string) => void;
    onCloseJourneyGraph(): void;
    setGraphSection?(section: GraphKey): void;
    logic: Logic;
    onActiveDotMount(p: { key: string | number; value: string | number }): void;
    onActiveDotDestroy?(): void;

    resetPolylineOnUnclickedOnMap(): void;
}

function Journeys(props: Props) {
    const { t } = props;
    const [activeGraphDotIndex, setActiveGraphDotIndex] = useState<number | undefined>();

    useEffect(() => {
        if (!props.table.selectedJourney) {
            props.resetPolylineOnUnclickedOnMap();
        }
        setActiveGraphDotIndex(undefined);
        props.onActiveDotDestroy?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.table.selectedJourney]);

    return (
        <>
            <LayoutContent
                className={cn('journeys', { expanded: props.bar.expanded, 'with-cargo': props.cargo })}
                mainSizes={
                    props.bar.expanded && props.cargo
                        ? { xs: 20, sm: 20, md: 20 }
                        : props.bar.expanded && !props.cargo
                        ? { xs: 24, sm: 24, md: 24 }
                        : !props.bar.expanded && props.cargo
                        ? { xs: 18, sm: 18, md: 18 }
                        : { xs: 24, sm: 24, md: 24 }
                }
                extraSizes={
                    props.cargo
                        ? props.bar.expanded
                            ? [{ xs: 4, sm: 4, md: 4 }]
                            : [{ xs: 6, sm: 6, md: 6 }]
                        : undefined
                }
                main={
                    <>
                        <TableBar
                            heading={props.t('NavBar.journeysActivity')}
                            actions={[
                                <Button
                                    className="expand-button"
                                    type={props.bar.expanded ? 'primary' : 'default'}
                                    size="small"
                                    qa={qa.journeys.bar.btnExpanded}
                                    onClick={props.onBarExpand}
                                    title={props.t('common.expand')}
                                    icon={
                                        <img
                                            src={props.bar.expanded ? ArrowLeftFilled : ArrowRightFilled}
                                            alt="arrow-left"
                                        />
                                    }
                                />
                            ]}
                            filters={[
                                <JourneysFilter
                                    roles={props.roles}
                                    vehicleId={props.filter.vehicle}
                                    trailerId={props.filter.trailer}
                                    vehicles={props.filter.vehiclesOpts}
                                    trailers={props.filter.trailersOpts}
                                    driver={props.filter.driver}
                                    drivers={props.filter.driversOpts}
                                    dateRange={props.filter.dateRange}
                                    dateChanged={props.filter.dateChanged}
                                    fullDWL={props.filter.fullDWL}
                                    exportLoading={props.exportLoading}
                                    tableLoading={props.table.loading}
                                    expanded={props.bar.expanded}
                                    rows={props.table.data.rows}
                                    onFilterChange={props.onFilterChange}
                                    onExportClick={props.onExportClick}
                                    onVehicleTextChange={props.onFilterVehicleTextChange}
                                    onTrailerTextChange={props.onFilterTrailerTextChange}
                                    onDriverTextChange={props.onFilterDriverTextChange}
                                />
                            ]}
                            onHelperClick={props.onBarHelperClick}
                        />
                        {props.showPremiumInfo && (
                            <InfoBanner
                                className="premium-info"
                                iconHeight={98}
                                title={t('JourneysActivityInfo.title', { days: DWL_LOCK })}
                                text={t('JourneysActivityInfo.text')}
                                iconOrientation="left"
                                onCloseButtonClick={props.onClosePremiumInfoClick}
                            />
                        )}
                        {props.showCoachInfo && (
                            <InfoBanner
                                className="coach-info"
                                icon="driverWheel"
                                iconHeight={120}
                                title={t('DriverBehavior.journeysActivity.title')}
                                text={t('DriverBehavior.journeysActivity.text')}
                                iconOrientation="left"
                                button={true}
                                buttonLabel={t('common.interested')}
                                buttonPositionBottom={!props.bar.expanded}
                                onLinkButtonClick={props.onCoachInfoLinkClick}
                                onCloseButtonClick={props.onCloseCoachInfoClick}
                            />
                        )}
                        <div className="journeys-filter-sum-table">
                            <div className="t-row">
                                {props.bar.expanded && props.table?.data?.sum && (
                                    <JourneysSum
                                        roles={props.roles}
                                        sum={props.table.data.sum}
                                        onShowExpensesClick={props.onSumShowExpensesClick}
                                        onShowFuelClick={props.onSumShowFuelClick}
                                    />
                                )}
                            </div>
                            <JourneysTable
                                adjustment={props.adjustment}
                                isPrivate={props.isPrivate}
                                expanded={props.bar.expanded}
                                vehicle={props.filter.vehicle}
                                trailer={props.filter.trailer}
                                driver={props.filter.driver}
                                data={props.table.data}
                                selectedJourney={props.table.selectedJourney}
                                loading={props.table.loading}
                                showConsumption={props.table.showConsumption}
                                roles={props.roles}
                                warningSwitzerland={props.logic.conf.settings.warningSwitzerland}
                                onIsPrivateEditIconClick={props.onIsPrivateEditIconClick}
                                onIsPrivateSelectChange={props.onIsPrivateSelectChange}
                                onAdjustmentEditIconClick={props.onAdjustmentEditIconClick}
                                onAdjustmentInputChange={props.onAdjustmentInputChange}
                                onAdjustmentInputKeyDown={props.onAdjustmentInputKeyDown}
                                onCreateCargoClick={props.onTableCreateCargoClick}
                                onJourneySelect={props.onTableJourneySelect}
                                onRowExpand={props.onTableRowExpand}
                            />
                        </div>
                    </>
                }
                extra={
                    props.cargo
                        ? [
                              <ExpensesCargo
                                  mode={'CREATE'}
                                  vehicleId={props.cargo.vehicleId}
                                  vehicles={props.cargo.vehicles}
                                  suppliers={props.cargo.suppliers}
                                  date={props.cargo.date}
                                  onSupplierTextChange={props.onCargoSupplierChange}
                                  onConfirm={props.onCargoConfirm}
                                  onCancel={props.onCargoCancel}
                              />
                          ]
                        : undefined
                }
            />

            {props.adjustment?.confirm && (
                <Modal
                    className="journeys-adjustment-confirm"
                    width={350}
                    visible={true}
                    centered={true}
                    closable={false}
                    okText={t('common.confirm')}
                    title={t('JourneyActivityAdjustment.confirm')}
                    okButtonProps={{ loading: props.adjustment.confirm.loading }}
                    onOk={props.onAdjustmentModalConfirm}
                    onCancel={props.onAdjustmentModalCancel}
                >
                    {t('JourneyActivityAdjustment.recalculate')}
                </Modal>
            )}

            {props.isPrivate?.confirm && (
                <Modal
                    className="journeys-is-private-confirm"
                    width={350}
                    visible={true}
                    centered={true}
                    closable={false}
                    okText={t('common.confirm')}
                    title={t('JourneyActivityIsPrivate.confirm')}
                    okButtonProps={{ loading: props.isPrivate.confirm.loading }}
                    onOk={props.onIsPrivateModalConfirm}
                    onCancel={props.onIsPrivateModalCancel}
                >
                    {t('JourneyActivityIsPrivate.body')}
                </Modal>
            )}

            <HelperModal
                name="journeys"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
            {!props.bar.expanded && props.journeyGraphVisible && props.table.data.journeyGraphData?.timestamps && (
                <JourneyGraph
                    roles={props.roles}
                    sensors={props.table.sensors}
                    onCloseJourneyGraph={props.onCloseJourneyGraph}
                    setGraphSection={props.setGraphSection}
                    data={props.table.data.journeyGraphData}
                    onActiveDotMount={(p, i) => {
                        props.onActiveDotMount?.(p);
                        setActiveGraphDotIndex(i);
                    }}
                    onActiveDotDestroy={props.onActiveDotDestroy}
                    activeGraphDotIndex={activeGraphDotIndex}
                />
            )}
        </>
    );
}

export default withTranslation()(Journeys);
