/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2008,
    InlineResponse2008FromJSON,
    InlineResponse2008ToJSON,
    ReadOnlyDriverApplication,
    ReadOnlyDriverApplicationFromJSON,
    ReadOnlyDriverApplicationToJSON,
    WriteOnlyDriverApplication,
    WriteOnlyDriverApplicationFromJSON,
    WriteOnlyDriverApplicationToJSON,
} from '../models';

export interface DriverApplicationCreateRequest {
    data: WriteOnlyDriverApplication;
}

export interface DriverApplicationDeleteRequest {
    id: number;
}

export interface DriverApplicationListRequest {
    limit?: number;
    offset?: number;
}

export interface DriverApplicationPartialUpdateRequest {
    id: number;
    data: WriteOnlyDriverApplication;
}

export interface DriverApplicationReadRequest {
    id: number;
}

export interface DriverApplicationUpdateRequest {
    id: number;
    data: WriteOnlyDriverApplication;
}

/**
 * no description
 */
export class DriverApplicationApi extends runtime.BaseAPI {

    /**
     */
    async driverApplicationCreateRaw(requestParameters: DriverApplicationCreateRequest): Promise<runtime.ApiResponse<ReadOnlyDriverApplication>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverApplicationCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverApplicationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverApplicationFromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationCreate(requestParameters: DriverApplicationCreateRequest): Promise<ReadOnlyDriverApplication> {
        const response = await this.driverApplicationCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverApplicationDeleteRaw(requestParameters: DriverApplicationDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyDriverApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverApplicationDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverApplicationFromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationDelete(requestParameters: DriverApplicationDeleteRequest): Promise<ReadOnlyDriverApplication> {
        const response = await this.driverApplicationDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverApplicationListRaw(requestParameters: DriverApplicationListRequest): Promise<runtime.ApiResponse<InlineResponse2008>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2008FromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationList(requestParameters: DriverApplicationListRequest): Promise<InlineResponse2008> {
        const response = await this.driverApplicationListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverApplicationPartialUpdateRaw(requestParameters: DriverApplicationPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDriverApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverApplicationPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverApplicationPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverApplicationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverApplicationFromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationPartialUpdate(requestParameters: DriverApplicationPartialUpdateRequest): Promise<ReadOnlyDriverApplication> {
        const response = await this.driverApplicationPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverApplicationReadRaw(requestParameters: DriverApplicationReadRequest): Promise<runtime.ApiResponse<ReadOnlyDriverApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverApplicationRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverApplicationFromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationRead(requestParameters: DriverApplicationReadRequest): Promise<ReadOnlyDriverApplication> {
        const response = await this.driverApplicationReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverApplicationUpdateRaw(requestParameters: DriverApplicationUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyDriverApplication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling driverApplicationUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling driverApplicationUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/driver-application/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyDriverApplicationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyDriverApplicationFromJSON(jsonValue));
    }

    /**
     */
    async driverApplicationUpdate(requestParameters: DriverApplicationUpdateRequest): Promise<ReadOnlyDriverApplication> {
        const response = await this.driverApplicationUpdateRaw(requestParameters);
        return await response.value();
    }

}
