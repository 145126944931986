import Collapse, { CollapseProps } from 'antd/lib/collapse';
interface Props extends CollapseProps {
    qa?: string;
    children?: JSX.Element | JSX.Element[];
}

export default function EnhancedCollapse({ qa, ...props }: Props) {
    return <Collapse data-qa={qa} {...props} />;
}

EnhancedCollapse.Panel = Collapse.Panel;
