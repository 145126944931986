/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyInfo,
    CompanyInfoFromJSON,
    CompanyInfoFromJSONTyped,
    CompanyInfoToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Location}
     * @memberof Order
     */
    l: Location;
    /**
     * 
     * @type {Location}
     * @memberof Order
     */
    source: Location;
    /**
     * 
     * @type {Location}
     * @memberof Order
     */
    target?: Location;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderId: number;
    /**
     * 
     * @type {CompanyInfo}
     * @memberof Order
     */
    companyInfo?: CompanyInfo;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    weight: number;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    finishDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    publicationDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    tenants?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    linksSelfHref?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    linksRequiredTruckBodyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    linksRequiredTruckBodyHref?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    linksRequiredTruckBodiesIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    linksRequiredTruckBodiesHref?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    loadHeightValue?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadHeightUnitCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    loadLengthValue?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadLengthUnitCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    loadWeightValue?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadWeightUnitCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    loadingDate?: Date;
    /**
     * 
     * @type {Location}
     * @memberof Order
     */
    loadingLoc?: Location;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadingPlaceAddressLocality?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadingPlaceAddressPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    loadingPlaceAddressCountry?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    unloadingDate?: Date;
    /**
     * 
     * @type {Location}
     * @memberof Order
     */
    unloadingLoc?: Location;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    unloadingPlaceAddressLocality?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    unloadingPlaceAddressPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    unloadingPlaceAddressCountry?: string;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'l': LocationFromJSON(json['L']),
        'source': LocationFromJSON(json['source']),
        'target': !exists(json, 'target') ? undefined : LocationFromJSON(json['target']),
        'timestamp': json['@timestamp'],
        'orderId': json['order_id'],
        'companyInfo': !exists(json, 'company_info') ? undefined : CompanyInfoFromJSON(json['company_info']),
        'weight': json['weight'],
        'creationDate': !exists(json, 'creation_date') ? undefined : (new Date(json['creation_date'])),
        'finishDate': !exists(json, 'finish_date') ? undefined : (new Date(json['finish_date'])),
        'publicationDate': !exists(json, 'publication_date') ? undefined : (new Date(json['publication_date'])),
        'tenants': !exists(json, 'tenants') ? undefined : json['tenants'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'linksSelfHref': !exists(json, 'links.self.href') ? undefined : json['links.self.href'],
        'linksRequiredTruckBodyId': !exists(json, 'links.required_truck_body.id') ? undefined : json['links.required_truck_body.id'],
        'linksRequiredTruckBodyHref': !exists(json, 'links.required_truck_body.href') ? undefined : json['links.required_truck_body.href'],
        'linksRequiredTruckBodiesIds': !exists(json, 'links.required_truck_bodies.ids') ? undefined : json['links.required_truck_bodies.ids'],
        'linksRequiredTruckBodiesHref': !exists(json, 'links.required_truck_bodies.href') ? undefined : json['links.required_truck_bodies.href'],
        'loadHeightValue': !exists(json, 'load_height.value') ? undefined : json['load_height.value'],
        'loadHeightUnitCode': !exists(json, 'load_height.unit_code') ? undefined : json['load_height.unit_code'],
        'loadLengthValue': !exists(json, 'load_length.value') ? undefined : json['load_length.value'],
        'loadLengthUnitCode': !exists(json, 'load_length.unit_code') ? undefined : json['load_length.unit_code'],
        'loadWeightValue': !exists(json, 'load_weight.value') ? undefined : json['load_weight.value'],
        'loadWeightUnitCode': !exists(json, 'load_weight.unit_code') ? undefined : json['load_weight.unit_code'],
        'loadingDate': !exists(json, 'loading_date') ? undefined : (new Date(json['loading_date'])),
        'loadingLoc': !exists(json, 'loading_loc') ? undefined : LocationFromJSON(json['loading_loc']),
        'loadingPlaceAddressLocality': !exists(json, 'loading_place.address.locality') ? undefined : json['loading_place.address.locality'],
        'loadingPlaceAddressPostalCode': !exists(json, 'loading_place.address.postal_code') ? undefined : json['loading_place.address.postal_code'],
        'loadingPlaceAddressCountry': !exists(json, 'loading_place.address.country') ? undefined : json['loading_place.address.country'],
        'unloadingDate': !exists(json, 'unloading_date') ? undefined : (new Date(json['unloading_date'])),
        'unloadingLoc': !exists(json, 'unloading_loc') ? undefined : LocationFromJSON(json['unloading_loc']),
        'unloadingPlaceAddressLocality': !exists(json, 'unloading_place.address.locality') ? undefined : json['unloading_place.address.locality'],
        'unloadingPlaceAddressPostalCode': !exists(json, 'unloading_place.address.postal_code') ? undefined : json['unloading_place.address.postal_code'],
        'unloadingPlaceAddressCountry': !exists(json, 'unloading_place.address.country') ? undefined : json['unloading_place.address.country'],
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'L': LocationToJSON(value.l),
        'source': LocationToJSON(value.source),
        'target': LocationToJSON(value.target),
        '@timestamp': value.timestamp,
        'order_id': value.orderId,
        'company_info': CompanyInfoToJSON(value.companyInfo),
        'weight': value.weight,
        'creation_date': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'finish_date': value.finishDate === undefined ? undefined : (value.finishDate.toISOString()),
        'publication_date': value.publicationDate === undefined ? undefined : (value.publicationDate.toISOString()),
        'tenants': value.tenants,
        'id': value.id,
        'links.self.href': value.linksSelfHref,
        'links.required_truck_body.id': value.linksRequiredTruckBodyId,
        'links.required_truck_body.href': value.linksRequiredTruckBodyHref,
        'links.required_truck_bodies.ids': value.linksRequiredTruckBodiesIds,
        'links.required_truck_bodies.href': value.linksRequiredTruckBodiesHref,
        'load_height.value': value.loadHeightValue,
        'load_height.unit_code': value.loadHeightUnitCode,
        'load_length.value': value.loadLengthValue,
        'load_length.unit_code': value.loadLengthUnitCode,
        'load_weight.value': value.loadWeightValue,
        'load_weight.unit_code': value.loadWeightUnitCode,
        'loading_date': value.loadingDate === undefined ? undefined : (value.loadingDate.toISOString()),
        'loading_loc': LocationToJSON(value.loadingLoc),
        'loading_place.address.locality': value.loadingPlaceAddressLocality,
        'loading_place.address.postal_code': value.loadingPlaceAddressPostalCode,
        'loading_place.address.country': value.loadingPlaceAddressCountry,
        'unloading_date': value.unloadingDate === undefined ? undefined : (value.unloadingDate.toISOString()),
        'unloading_loc': LocationToJSON(value.unloadingLoc),
        'unloading_place.address.locality': value.unloadingPlaceAddressLocality,
        'unloading_place.address.postal_code': value.unloadingPlaceAddressPostalCode,
        'unloading_place.address.country': value.unloadingPlaceAddressCountry,
    };
}


