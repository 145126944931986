/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * in meters
 * @export
 * @interface CargoSpace
 */
export interface CargoSpace {
    /**
     * 
     * @type {number}
     * @memberof CargoSpace
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpace
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSpace
     */
    length?: number;
}

export function CargoSpaceFromJSON(json: any): CargoSpace {
    return CargoSpaceFromJSONTyped(json, false);
}

export function CargoSpaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoSpace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'length': !exists(json, 'length') ? undefined : json['length'],
    };
}

export function CargoSpaceToJSON(value?: CargoSpace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'length': value.length,
    };
}


