import { ColumnsType } from 'antd/lib/table';
import { Table, Tooltip } from 'common/components';
import { Checkbox } from 'common/components/Form';
import { GoodsWithAddress } from 'common/model/transports';
import qa from 'qa-selectors';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    currentAddress: string;
    initialGoods?: GoodsWithAddress[];
    placeId: string;
    placeDisabled?: boolean;
    placeIndex: number;
    onUnloadingGoodsChange: (goodsIds: string[]) => void;
}

interface State {
    unloadingGoodsIds: Set<string>;
}

class PlaceTaskGoodsUnloading extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            unloadingGoodsIds: new Set(
                props.initialGoods
                    ?.filter(goods => {
                        return goods.unloading?.placeId === this.props.placeId;
                    })
                    .map(goods => goods.id) ?? []
            )
        };
    }

    render() {
        return (
            <div className="place-task-goods">
                <div className="place-task-goods-content">
                    <div className="place-task-goods-content table">
                        <Table<GoodsWithAddress>
                            dataSource={(this.props.initialGoods ?? [])
                                .filter(goods => goods.loading.placeIndex < this.props.placeIndex)
                                .map((e, index) => ({
                                    ...e,
                                    key: e.id,
                                    index: index
                                }))}
                            className="goods-table"
                            columns={this._createColumns()}
                            bordered={false}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private _createColumns = (): ColumnsType<GoodsWithAddress> => {
        return [
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.no'),
                dataIndex: 'index',
                key: 'index',
                width: 56,
                render: index => <span data-qa={qa.planner.activity.goods.fieldIndex}>{index}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.cnItems'),
                dataIndex: 'cnItems',
                key: 'cnItems',
                width: 112,
                render: cnItems => <span data-qa={qa.planner.activity.goods.fieldCnItems}>{cnItems}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.name'),
                dataIndex: 'name',
                key: 'name',
                width: 160,
                render: name => <span data-qa={qa.planner.activity.goods.fieldName}>{name}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.CMRNumber'),
                dataIndex: 'cmr',
                key: 'cmr',
                width: 112,
                render: cmr => <span data-qa={qa.planner.activity.goods.fieldCmrNumber}>{cmr}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.weight'),
                dataIndex: 'grossWeightVolume',
                key: 'grossWeightVolume',
                width: 80,
                render: grossWeightVolume => (
                    <span data-qa={qa.planner.activity.goods.fieldGrossWeightVolume}>{grossWeightVolume}</span>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.unit'),
                dataIndex: 'measurementUnit',
                key: 'measurementUnit',
                width: 80,
                render: measurmentUnit => (
                    <span data-qa={qa.planner.activity.goods.fieldMeasurmentUnit}>{measurmentUnit}</span>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.wasteCode'),
                dataIndex: 'wasteCode',
                key: 'wasteCode',
                width: 110,
                render: wasteCode => <span data-qa={qa.planner.activity.goods.fieldWasteCode}>{wasteCode}</span>
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.loadingAt'),
                dataIndex: ['loading', 'address'],
                key: 'loadingAddress',
                className: 'goods-table-location',
                width: 132,
                render: location => (
                    <Tooltip placement="bottomLeft" mouseLeaveDelay={0} title={location}>
                        <span data-qa={qa.planner.activity.goods.fieldLoading}>{location}</span>
                    </Tooltip>
                )
            },
            {
                title: this.props.t('PlannerPlacesTask.loadingUnloadingGoodsTable.unloadingAt'),
                dataIndex: ['unloading', 'address'],
                key: 'unloadingAddress',
                className: 'goods-table-location',
                width: 132,
                render: location => (
                    <Tooltip placement="bottomLeft" mouseLeaveDelay={0} title={location}>
                        <span data-qa={qa.planner.activity.goods.fieldUnloading}>{location}</span>
                    </Tooltip>
                )
            },
            {
                title: '',
                key: 'select',
                width: 80,
                align: 'center',
                render: (record: GoodsWithAddress) => {
                    return (
                        <Checkbox
                            disabled={
                                (record.unloading && this.props.placeId !== record.unloading.placeId) ||
                                this.props.placeDisabled
                            }
                            defaultChecked={record.unloading?.placeId === this.props.placeId}
                            onChange={e => {
                                this._onToggleGoods(e.target.checked, record.id);
                            }}
                            qa={qa.planner.activity.checkboxToogleGoods}
                        />
                    );
                }
            }
        ];
    };

    private _onToggleGoods = (checked: boolean, id: string) => {
        const goodsIds = this.state.unloadingGoodsIds;
        if (checked) {
            goodsIds.add(id);
        } else {
            goodsIds.delete(id);
        }
        this.setState(
            {
                unloadingGoodsIds: goodsIds
            },
            () => {
                this.props.onUnloadingGoodsChange(Array.from(this.state.unloadingGoodsIds));
            }
        );
    };
}

export default withTranslation()(PlaceTaskGoodsUnloading);
