/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Channel,
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChannelResponse
 */
export interface ChannelResponse {
    /**
     * 
     * @type {Channel}
     * @memberof ChannelResponse
     */
    channel: Channel;
}

export function ChannelResponseFromJSON(json: any): ChannelResponse {
    return ChannelResponseFromJSONTyped(json, false);
}

export function ChannelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': ChannelFromJSON(json['channel']),
    };
}

export function ChannelResponseToJSON(value?: ChannelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': ChannelToJSON(value.channel),
    };
}


