/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DTCODownloadedFileApiResult
 */
export interface DTCODownloadedFileApiResult {
    /**
     * 
     * @type {object}
     * @memberof DTCODownloadedFileApiResult
     */
    monitoredObject?: { [key: string]: string | number };
    /**
     *
     * @type {object}
     * @memberof DTCODownloadedFileApiResult
     */
    driver?: { [key: string]: string | number };
    /**
     * 
     * @type {string}
     * @memberof DTCODownloadedFileApiResult
     */
    downloadedFile?: string;
    /**
     * 
     * @type {number}
     * @memberof DTCODownloadedFileApiResult
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof DTCODownloadedFileApiResult
     */
    downloadStarted?: Date;
    /**
     * 
     * @type {string}
     * @memberof DTCODownloadedFileApiResult
     */
    dataActivityStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DTCODownloadedFileApiResult
     */
    dataActivityEnd?: string;
    /**
     * 
     * @type {Date}
     * @memberof DTCODownloadedFileApiResult
     */
    downloadRequested?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DTCODownloadedFileApiResult
     */
    demand?: boolean;
    /**
     * 
     * @type {object}
     * @memberof DTCODownloadedFileApiResult
     */
    state?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DTCODownloadedFileApiResult
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DTCODownloadedFileApiResult
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DTCODownloadedFileApiResult
     */
    date: Date;
}

export function DTCODownloadedFileApiResultFromJSON(json: any): DTCODownloadedFileApiResult {
    return DTCODownloadedFileApiResultFromJSONTyped(json, false);
}

export function DTCODownloadedFileApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DTCODownloadedFileApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObject': !exists(json, 'monitoredObject') ? undefined : json['monitoredObject'],
        'driver': !exists(json, 'driver') ? undefined : json['driver'],
        'downloadedFile': !exists(json, 'downloadedFile') ? undefined : json['downloadedFile'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'downloadStarted': !exists(json, 'downloadStarted') ? undefined : (new Date(json['downloadStarted'])),
        'dataActivityStart': !exists(json, 'dataActivityStart') ? undefined : json['dataActivityStart'],
        'dataActivityEnd': !exists(json, 'dataActivityEnd') ? undefined : json['dataActivityEnd'],
        'downloadRequested': !exists(json, 'downloadRequested') ? undefined : (new Date(json['downloadRequested'])),
        'demand': !exists(json, 'demand') ? undefined : json['demand'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
        'id': json['id'],
        'date': (new Date(json['date'])),
    };
}

export function DTCODownloadedFileApiResultToJSON(value?: DTCODownloadedFileApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoredObject': value.monitoredObject,
        'driver': value.driver,
        'downloadedFile': value.downloadedFile,
        'clientId': value.clientId,
        'downloadStarted': value.downloadStarted === undefined ? undefined : (value.downloadStarted.toISOString()),
        'dataActivityStart': value.dataActivityStart,
        'dataActivityEnd': value.dataActivityEnd,
        'downloadRequested': value.downloadRequested === undefined ? undefined : (value.downloadRequested.toISOString()),
        'demand': value.demand,
        'state': value.state,
        'cancelled': value.cancelled,
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
    };
}


