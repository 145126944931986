import moment from 'moment';
import { MemoryCard } from 'modules/management/modules/driver-cards-remote-memory/DriversCardsRemoteMemoryModule';

import { Logic } from '../logic';
import { searched } from '../../common/utils/search';
import {
    DTCODownloadedFileApiResult,
    DtcoDownloadLink,
    GetDtcoDriverV1DtcoFilesDriverGetRequest
} from 'generated/backend-api';

export interface DriverCardsRemoteMemoryModel {
    id: string;
    fuelCardId: string;
    name: string;
    surname: string;
    cards: MemoryCard[];
    cardsDemanded: MemoryCard[];
    downloadAvailable: boolean;
}

export class DispatcherKitDriverCardsRemoteMemoryLogic {
    private data: DriverCardsRemoteMemoryModel[];

    constructor(private _logic: Logic) {
        this.data = [];
    }

    async getTableData(
        requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest,
        search: string
    ): Promise<DriverCardsRemoteMemoryModel[]> {
        const drivers = await this._logic.users().drivers();

        const dtco: DTCODownloadedFileApiResult[] = await this._getDTCODriverFiles(requestParameters);

        this.data = drivers.map(driver => ({
            name: driver.name,
            surname: driver.surname,
            fuelCardId: driver.tachographId,
            id: driver.id.toString(),
            cards: dtco.filter(d => d.driver?.id === Number(driver.id)).map(this._toCard),
            cardsDemanded: [],
            downloadAvailable:
                dtco.filter(d => Number(d.driver?.id) === Number(driver.id) && d.demand === false && d.downloadedFile)
                    .length > 0 ?? false
        }));

        if (search.length !== 0) {
            return this.search(search);
        }

        return this.data;
    }

    async _getDTCODriverFiles(requestParameters: GetDtcoDriverV1DtcoFilesDriverGetRequest) {
        try {
            if (this._logic.demo().isActive) {
                return this._logic.demo().data.dtcosCards;
            }
            const result: DTCODownloadedFileApiResult[] = await this._logic
                .api()
                .dtcoApi.getDtcoDriverV1DtcoFilesDriverGet(requestParameters);
            return result;
        } catch (err) {
            console.error('Get DTCO driver error');
            throw err;
        }
    }

    async getDtcoLink(documentId: string): Promise<DtcoDownloadLink> {
        try {
            const downloadLink = await this._logic
                .api()
                .dtcoApi.getDtcoLinkV1DtcoFilesDocumentIdLinkGet({ documentId });
            return downloadLink;
        } catch (err) {
            console.error('Dtco link err', err);
            throw err;
        }
    }

    async downloadFiles(documentIds: string[]): Promise<DtcoDownloadLink> {
        try {
            const downloadLink = await this._logic
                .api()
                .dtcoApi.generateDtcoArchiveV1DtcoFilesArchiveGet({ documentId: documentIds });
            return downloadLink;
        } catch (err) {
            console.error('Download files archive err', err);
            throw err;
        }
    }

    async createDownloadDemand(userId: string): Promise<MemoryCard> {
        try {
            const dtco = await this._logic
                .api()
                .defaultApi.createDtcoDownloadDemandsV1DtcoDownloadDemandPost({ userId: Number(userId) });

            return this._toCard(dtco);
        } catch (err) {
            console.error('Create download demand err', err);
            throw err;
        }
    }

    private _toCard(data: DTCODownloadedFileApiResult): MemoryCard {
        return {
            id: data.id,
            driver: {
                id: String(data.driver?.id) ?? '',
                name: String(data.driver?.name) ?? ''
            },
            date: moment(data.date).toISOString() ?? '',
            demand: data.demand ?? true,
            downloadedFile: data.downloadedFile ?? '',
            downloadStarted: data.downloadStarted ? moment(data.downloadStarted).toISOString() : undefined,
            downloadRequested: data.downloadRequested ? moment(data.downloadRequested).toISOString() : undefined,
            dataActivityEnd: data.dataActivityEnd ?? '',
            dataActivityStart: data.dataActivityStart ?? ''
        };
    }

    search(value: string): Promise<DriverCardsRemoteMemoryModel[]> {
        return new Promise(resolve => {
            resolve(
                value
                    ? this.data.filter(item => {
                          return searched(value, item.name + ' ' + item.surname);
                      })
                    : this.data
            );
        });
    }
}
