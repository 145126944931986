import { ReactNode } from 'react';
import { TextAreaProps } from 'antd/lib/input';
import { Field, FieldProps, getIn } from 'formik';

import { Textarea } from '../../components/Form';

interface Props extends TextAreaProps {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    qa?: string;
}

export default function TextareaField({ name, qa, ...bag }: Props) {
    return (
        <Field name={name}>
            {({ field, form: { errors, touched } }: FieldProps<any>) => (
                <Textarea rows={4} error={getIn(touched, name) && getIn(errors, name)} qa={qa} {...field} {...bag} />
            )}
        </Field>
    );
}
