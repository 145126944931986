import qa from 'qa-selectors';
import React, { ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface VehicleData {
    name: string;
    id: string;
}

interface State {
    search: string;
    selection: string[];
}

interface Props extends WithTranslation {
    vehicles?: VehicleData[];
    selection: string[];
    onChange?: (selected: string[]) => void;
}

class CustomerApiVehiclesSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            search: '',
            selection: []
        };
    }

    componentDidMount() {
        if (this.props.selection) {
            this.setState({
                selection: this.props.selection.map(s => String(s))
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selection !== this.props.selection) {
            this.setState({
                selection: this.props.selection.map(s => String(s))
            });
        }
    }

    render() {
        const { t } = this.props;
        const { search, selection } = this.state;
        return (
            <div className="customer-api-vehicle-select">
                <h4>{t('CustomerApiVehiclesSelect.title')}</h4>
                <u onClick={this._onSelectAll} data-qa={qa.systemConnections.createApiKey.vehicle.btnSelectAll}>
                    {t('CustomerApiVehiclesSelect.selectAll')}
                </u>
                <div className="customer-api-vehicle-select-search">
                    <input
                        value={search}
                        type="text"
                        onChange={this._onSearchChange}
                        data-qa={qa.systemConnections.createApiKey.vehicle.inputSearch}
                    />
                    <i className="fa fa-search" />
                </div>
                <div className="customer-api-vehicle-select-vehicles">
                    {this._filteredVehicles().map(v => (
                        <div key={v.id} className="customer-api-vehicle-select-vehicles-vehicle">
                            <input
                                type="checkbox"
                                className="t-check t-inverse"
                                checked={selection.some(e => e === v.id)}
                                onChange={this._onVehicleSelect(v.id)}
                                data-qa={qa.systemConnections.createApiKey.vehicle.inputVehicle}
                            />
                            {v.name}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private _filteredVehicles = () => {
        return (this.props.vehicles || []).filter(
            v => v.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
        );
    };

    private _onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: e.target.value });
    };

    private _onSelectAll = () => {
        this.setState(
            state => ({
                selection:
                    state.selection.length === this.props.vehicles?.length
                        ? []
                        : this.props.vehicles?.map(e => e.id) || []
            }),
            () => {
                this.props.onChange?.(this.state.selection);
            }
        );
    };

    private _onVehicleSelect = (id: string) => {
        return () => {
            if (this.state.selection.some(e => e === id)) {
                this.setState(
                    state => ({ selection: state.selection.filter(v => v !== id) }),
                    () => {
                        this.props.onChange?.(this.state.selection);
                    }
                );
            } else {
                this.setState(
                    state => ({
                        selection: [...state.selection, this.props.vehicles?.map(e => e.id).find(e => e === id)!]
                    }),
                    () => {
                        this.props.onChange?.(this.state.selection);
                    }
                );
            }
        };
    };
}

export default withTranslation()(CustomerApiVehiclesSelect);
