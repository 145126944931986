import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Select } from 'common/components/Form';
import { SelectOption } from 'common/components/Form/Select';
import { star } from 'resources/images/common';

export interface ChartCompareModel {
    xIndex: number;
    xVal: string;
    yVal?: number;
    yLine: 'main' | 'compared';
}

export enum DriverBehaviorCompare {}
// ALLCLIENTSBEST = 'allClientsBest',
// ALLCLIENTSAVERAGE = 'allClientsAverage',
// COMPANYAVERAGE = 'companyAverage',
// COMPANYBEST = 'companyBest'

export enum ScoreTrend {
    LAST12MONTHS = 'last12Months'
}

interface Props {
    label?: string;
    drivers: SelectOption[];
    width?: number;
    mainId: string;
    comparedDriverId?: string;
    color?: string;
    onCompareDriverSelect?: (id: string) => void;
    onCompareDriverDeselect?: () => void;
    onCompareUsersChange?: () => void;
    onScoreTrendChange?: () => void;
}

export default function DriverBehaviorChartBar(props: Props) {
    const { t } = useTranslation();

    function compareWithOptions(): SelectOption[] {
        return Object.values(DriverBehaviorCompare).map(
            value =>
                ({
                    label: t(`DriverBehaviorCharts.${value}`),
                    value: String(value)
                } as SelectOption)
        );
    }

    function scoreTrendOptions(): SelectOption[] {
        return Object.values(ScoreTrend).map(value => {
            return {
                label: t(`DriverBehaviorCharts.${value}`),
                value: value
            };
        });
    }

    return (
        <div className="driver-behavior-chart-bar" style={{ width: props.width ? `${props.width}px` : undefined }}>
            {props.label && (
                <Row justify="center">
                    <Col className="driver-behavior-chart-bar-label">{props.label}</Col>
                </Row>
            )}
            <Row justify="space-between">
                <Col className="driver-behavior-chart-bar-dates">
                    <Row align="middle" gutter={16}>
                        <Col>
                            <label>{t('DriverBehaviorCharts.scoreTrend')}</label>
                        </Col>
                        <Col>
                            <Select
                                className="driver-behavior-chart-bar-dates-select"
                                options={scoreTrendOptions()}
                                size="large"
                                showArrow={true}
                                placeholder={t('DriverBehaviorCharts.selectOptions')}
                                value={ScoreTrend.LAST12MONTHS}
                            />
                        </Col>
                    </Row>
                </Col>
                {props.comparedDriverId && (
                    <Col className="driver-behavior-chart-bar-legend">
                        <Row justify="space-between" gutter={32}>
                            <Col>
                                <span
                                    className="driver-behavior-chart-bar-legend-line driver-behavior-chart-bar-legend-line-solid"
                                    style={{ borderColor: props.color }}
                                />
                                {props.drivers.find(d => d.value === props.mainId)?.label}
                            </Col>
                            <Col className="driver-behavior-chart-bar-legend-separator">vs</Col>
                            <Col>
                                <span
                                    className="driver-behavior-chart-bar-legend-line driver-behavior-chart-bar-legend-line-dashed"
                                    style={{ borderColor: props.color }}
                                />
                                {props.drivers.find(d => d.value === props.comparedDriverId)?.label}
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col className="driver-behavior-chart-bar-users">
                    <Row align="middle" gutter={16}>
                        <Col>
                            <label>{t('DriverBehaviorCharts.compareWith')}</label>
                        </Col>
                        <Col>
                            <Select
                                className="driver-behavior-chart-bar-users-select"
                                size="large"
                                onSelect={props.onCompareDriverSelect}
                                onDeselect={props.onCompareDriverDeselect}
                                onClear={props.onCompareDriverDeselect}
                                showArrow={true}
                                placeholder={t('DriverBehaviorCharts.selectOptions')}
                                allowClear
                                value={props.comparedDriverId ? [props.comparedDriverId] : []}
                                maxTagCount="responsive"
                            >
                                {compareWithOptions().map(d => (
                                    <Select.Option value={d.value}>
                                        <Row align="middle" gutter={4}>
                                            <Col>
                                                <img src={star} alt="star" />
                                            </Col>
                                            <Col>{d.label}</Col>
                                        </Row>
                                    </Select.Option>
                                ))}
                                {props.drivers.filter(d => d.value !== props.mainId).length > 0 && (
                                    <Select.OptGroup label={t('DriverBehaviorCharts.otherDriversInMyCompany')}>
                                        {props.drivers
                                            .filter(d => d.value !== props.mainId)
                                            .map(d => (
                                                <Select.Option key={d.value} value={d.value}>
                                                    {d.label}
                                                </Select.Option>
                                            ))}
                                    </Select.OptGroup>
                                )}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
