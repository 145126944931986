import React from 'react';
import cn from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row } from 'antd';

import { PartnerUserModel } from 'logic/partner/logic/partner-user';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { Pagination, Table } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';
import { getTableCurrentPage } from 'common/utils/tableUtils';

interface Props extends WithTranslation {
    loading: boolean;
    data?: PaginatedResponse<PartnerUserModel[]>;
    selected?: PartnerUserModel;
    clients?: PartnerCompanySelectModel[];
    onTableRowSelect?: (id: string) => void;
    onPaginationChange?: (pagination: PaginationParams) => void;
}

function UsersTable(props: Props) {
    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    const { t, data, loading, selected } = props;

    return (
        <div className="pricing-table partner-main-table">
            <Table<PartnerUserModel>
                loading={loading}
                dataSource={data?.data.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id.toString()),
                    className: cn({
                        'ant-table-row-selected': selected?.id === record.id
                    })
                })}
            />

            {!loading &&
            data?.total &&
            data?.total > 0 &&
            data?.total > data?.limit &&
            data?.offset !== undefined &&
            data?.limit !== undefined ? (
                <Row justify="center">
                    <Pagination
                        total={data?.total}
                        pageSize={data?.limit}
                        current={getTableCurrentPage(data.offset, data.limit)}
                        onChange={props.onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );

    function createColumns() {
        return [
            {
                title: t('common.name'),
                dataIndex: 'name',
                key: 'name',
                width: 60,
                render: name => name
            },
            {
                title: t('common.surname'),
                dataIndex: 'surname',
                key: 'surname',
                width: 60,
                render: surname => surname
            },
            {
                title: t('Partner.client'),
                dataIndex: 'clientName',
                key: 'clientName',
                width: 60,
                render: clientName => clientName
            }
        ] as ColumnType<PartnerUserModel>[];
    }
}

export default withTranslation()(UsersTable);
