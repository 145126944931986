import { useState } from 'react';
import { GraphKey } from '../../JourneyGraph';
import { getAvailableZones } from './externalDevicesHelpers';
import { JourneyGraphDataModel } from '../../../JourneysModule';
import { TripTemperatureSensor } from 'generated/backend-api';

export type ExternalDevicesConfig = {
    zones: number[];
    sensors: TripTemperatureSensor[];
    selectedZone: number;
    selectedSensors: string[];
};

export const useExternalDevicesConfig = (data?: JourneyGraphDataModel, sensors?: TripTemperatureSensor[]) => {
    const [externalDevicesConfig, setExternalDevicesConfig] = useState<ExternalDevicesConfig>({
        selectedSensors: [],
        selectedZone: 1,
        sensors: [],
        zones: []
    });

    const presetExternalDevicesConfig = (selectedSection: GraphKey) => {
        if (sensors) {
            if (selectedSection === GraphKey.externalDevices) {
                setExternalDevicesConfig({
                    zones: getAvailableZones(sensors),
                    sensors: sensors.filter(device => device.sensorZone === getAvailableZones(sensors)[0]),
                    selectedZone: getAvailableZones(sensors)[0],
                    selectedSensors: sensors
                        .filter(device => device.sensorZone === getAvailableZones(sensors)[0])
                        .map(s => s.serialNumber)
                });
            } else {
                setExternalDevicesConfig({
                    zones: [],
                    sensors: [],
                    selectedZone: 1,
                    selectedSensors: []
                });
            }
        }
    };

    const handleChangeZone = (zone: number) => {
        return setExternalDevicesConfig(prev => ({
            ...prev,
            sensors: sensors?.filter(device => device.sensorZone === zone) ?? [],
            selectedZone: zone,
            selectedSensors:
                sensors?.filter(device => device.sensorZone === zone)?.map(device => device.serialNumber) ?? []
        }));
    };

    const handleToggleSensor = (serialNumbers: string[]) => {
        return setExternalDevicesConfig(prev => ({
            ...prev,
            selectedSensors: serialNumbers
        }));
    };

    return {
        presetExternalDevicesConfig,
        externalDevicesConfig,
        onChangeZone: handleChangeZone,
        onToggleSensor: handleToggleSensor
    };
};
