import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'common/components';
import { RouteNames, permissionsRouteMap } from '../../../App';
import { Role } from '../../../logic/auth';
import * as forbiddenPageImages from 'resources/images/forbidden';
import qa from 'qa-selectors';

export interface ForbiddenPageProps {
    type: '403' | '404';
    availableRoles: Role[];
}

const ForbiddenPage = ({ type, availableRoles }: ForbiddenPageProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const typemap = {
        '403': {
            image: forbiddenPageImages._403,
            content: 'common.error.noPermissions'
        },
        '404': {
            image: forbiddenPageImages._404,
            content: 'common.error.notFound'
        }
    };

    const handleClick = () => {
        const keys = Object.keys(permissionsRouteMap);
        keys.every(route => {
            if (permissionsRouteMap[route as RouteNames].some(r => availableRoles.includes(r))) {
                history.push({ pathname: route });
                return false;
            }
            return true;
        });
    };

    return (
        <div className="forbidden-page" data-qa={type === '403' ? qa.forbidden._403 : qa.forbidden._404}>
            <img src={typemap[type].image} alt="forbidden" />
            <Typography.Title level={4}>{t('common.error.interjection')}</Typography.Title>
            <Typography.Text>{t(`${typemap[type].content}`)}</Typography.Text>
            {!!availableRoles.length && (
                <Button
                    type="primary"
                    size="middle"
                    onClick={handleClick}
                    qa={qa.common.btnReturn}
                    className="forbidden-page__btn-return"
                >
                    {t('common.return')}
                </Button>
            )}
        </div>
    );
};

export default ForbiddenPage;
