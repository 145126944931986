import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import moment, { duration } from 'moment';
import numeral from 'numeral';
import { GroupByFuelConsumption, FuelConsumptionHead } from '../FuelConsumptionModule';
import { Loading } from 'common/components/Loading';
import { confDefault } from 'conf';
import { Role } from 'logic/auth';
import { getRegistrationNumber } from 'common/utils/registrationName';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main/models';
import { NoData } from 'common/components/NoData';
import { FuelStatisticsGroup, FuelStatsGroupByTimespan, FuelStatsGroupByEntity } from 'generated/backend-api';
import { DriverModel } from 'logic/user/users';
import { DATE_FORMAT_SHORT_BE } from 'domain-constants';
import TableHeadLabel from 'common/components/TableHeadLabel';
import { Button, Col, Row } from 'antd';

export interface Summary {
    journeyLiters: number;
    journeyAFC: number;
    drivingLiters: number;
    drivingDistance: number;
    drivingAFC: number;
    idlingLiters: number;
    idlingTaken: number;
    idlingAFC: number;
}

interface Props extends WithTranslation {
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    drivers: DriverModel[];
    data: FuelStatisticsGroup[];
    loading: boolean;
    agregation: GroupByFuelConsumption;
    summary?: Summary;
    expanded?: string;
    roles: Role[];
    onItemSelect?: (id: string) => void;
    groupByTimespanChange?: (type: FuelStatsGroupByTimespan) => void;
    groupByEntityChange?: (type: FuelStatsGroupByEntity) => void;
}

function cycleGroupByTimespan(value: FuelStatsGroupByTimespan, step = 1): FuelStatsGroupByTimespan {
    const v = Object.values(FuelStatsGroupByTimespan);
    const i = v.indexOf(value) + step;
    const n = v.length;
    return v[((i % n) + n) % n] as FuelStatsGroupByTimespan;
}

function FuelConsumptionTable(props: Props) {
    const { t } = props;
    const head: FuelConsumptionHead = props.roles.includes(Role.RID_R)
        ? [
              [
                  [t('FuelConsumptionTable.timeRange'), 1],
                  [t('FuelConsumptionTable.vehicleDriver'), 1],
                  [t('FuelConsumptionTable.journeyCombined'), 2],
                  [t('FuelConsumptionTable.driving'), 3],
                  [t('FuelConsumptionTable.idling'), 3]
              ],
              [
                  '',
                  '',
                  t('FuelConsumptionTable.liters'),
                  t('FuelConsumptionTable.AFCL100km'),
                  t('FuelConsumptionTable.liters'),
                  t('FuelConsumptionTable.distance'),
                  t('FuelConsumptionTable.AFCL100km'),
                  t('FuelConsumptionTable.liters'),
                  `${t('FuelConsumptionTable.taken')} [h:m:s]`,
                  t('FuelConsumptionTable.AFCLh')
              ]
          ]
        : [
              [
                  [t('FuelConsumptionTable.timeRange'), 1],
                  [t('FuelConsumptionTable.vehicleDriver'), 1],
                  [t('FuelConsumptionTable.driving'), 3]
              ],
              [
                  '',
                  '',
                  t('FuelConsumptionTable.liters'),
                  t('FuelConsumptionTable.distance'),
                  t('FuelConsumptionTable.AFCL100km')
              ]
          ];

    const borders = head[0].reduce(
        (a, v) => {
            a.v += v[1];
            a.o.push(a.v);
            return a;
        },
        { v: 0, o: [] as number[] }
    ).o;
    let step = 0;

    function onClickRow(id: string) {
        props.onItemSelect?.(id);
    }

    function onGroupByTimespanNext() {
        props.groupByTimespanChange?.(cycleGroupByTimespan(props.agregation.date));
    }

    function onGroupByTimespanDatePrev() {
        props.groupByTimespanChange?.(cycleGroupByTimespan(props.agregation.date, -1));
    }

    function onVehicleDriverSwitch() {
        if (props.groupByEntityChange) {
            if (props.agregation.entity === FuelStatsGroupByEntity.Driver) {
                props.groupByEntityChange(FuelStatsGroupByEntity.Vehicle);
            } else {
                props.groupByEntityChange(FuelStatsGroupByEntity.Driver);
            }
        }
    }

    const summaryOrder = [
        '',
        '',
        'journeyLiters',
        'journeyAFC',
        'drivingLiters',
        'drivingDistance',
        'drivingAFC',
        'idlingLiters',
        'idlingTaken',
        'idlingAFC'
    ].filter(
        so =>
            ['journeyLiters', 'journeyAFC', 'idlingLiters, idlingTaken', 'idlingAFC'].includes(so) &&
            props.roles.includes(Role.RID_R)
    );

    return (
        <div className="fuel-consumption-table">
            {props.loading ? (
                <Loading />
            ) : (
                <table className="t-table t-table-all t-hoverable t-small">
                    <thead>
                        <tr>
                            {head[0].map((h, i) => {
                                if (i === 1) {
                                    const [vehicle, driver] = h[0].split(' ');
                                    return (
                                        <th key={i} className="t-uppercase t-center t-border-primary t-border-right">
                                            <span
                                                className={cn('t-uppercase', {
                                                    't-text-primary':
                                                        props.agregation.entity === FuelStatsGroupByEntity.Driver &&
                                                        driver
                                                })}
                                            >
                                                {driver}
                                            </span>{' '}
                                            <button className="t-button" onClick={onVehicleDriverSwitch}>
                                                <i className="fa fa-fw fa-exchange" aria-hidden="true" />
                                            </button>{' '}
                                            <span
                                                className={cn('t-uppercase', {
                                                    't-text-primary':
                                                        props.agregation.entity === FuelStatsGroupByEntity.Vehicle &&
                                                        vehicle
                                                })}
                                            >
                                                {vehicle}
                                            </span>
                                        </th>
                                    );
                                }

                                return (
                                    <th
                                        key={i}
                                        className={cn([
                                            't-border-primary',
                                            {
                                                't-border-right': head[0].length - 1 > i
                                            }
                                        ])}
                                        colSpan={h[1]}
                                    >
                                        <TableHeadLabel title={h[0]} />
                                    </th>
                                );
                            })}
                        </tr>
                        <tr>
                            {head[1].map((h, i) => {
                                if (i === 0) {
                                    return (
                                        <th key={i} className="t-center t-border-primary t-border-right">
                                            {' '}
                                            <button
                                                className="t-button t-padding-small t-ripple"
                                                onClick={onGroupByTimespanDatePrev}
                                            >
                                                <i className="fa fa-fw fa-arrow-left" aria-hidden="true" />{' '}
                                            </button>
                                            <span className="t-uppercase">{t(`common.${props.agregation.date}`)}</span>
                                            <button
                                                className="t-button t-padding-small t-ripple"
                                                onClick={onGroupByTimespanNext}
                                            >
                                                <i className="fa fa-fw fa-arrow-right" aria-hidden="true" />
                                            </button>
                                        </th>
                                    );
                                }
                                return (
                                    <th
                                        key={i}
                                        className={cn({
                                            't-border-primary': borders.includes(i + 1),
                                            't-border-secondary': !borders.includes(i + 1),
                                            't-border-right': head[1].length - 1 > i
                                        })}
                                    >
                                        <TableHeadLabel title={h} level="normal" />
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {props.summary && (
                        <tbody className="fuel-consumption-summary" key="summary">
                            <tr>
                                {summaryOrder.map((s, i) => (
                                    <th key={i} className="t-text-primary t-center">
                                        {(props.summary![s] &&
                                            numeral(props.summary![s]).format('0,0.00') +
                                                (s === 'drivingDistance' ? ' km' : '')) ||
                                            '-'}
                                    </th>
                                ))}
                            </tr>
                        </tbody>
                    )}
                    {props.data && props.data.length > 0 ? (
                        props.data.map((fuelConsumption, i) => {
                            const vehicle = props.vehicles.find(
                                v => v.id === Number(fuelConsumption.monitoredObjectId)
                            );
                            const driver = props.drivers?.find(
                                driver => String(fuelConsumption.driverId) === driver.id
                            );
                            return (
                                <tbody
                                    key={i}
                                    className={cn({
                                        selected: String(i) === props.expanded
                                    })}
                                >
                                    <tr
                                        onClick={onClickRow.bind(undefined, String(i))}
                                        className={cn(
                                            {
                                                selected: String(i) === props.expanded
                                            },
                                            {
                                                odd: i % 2 && String(i) !== props.expanded
                                            }
                                        )}
                                    >
                                        <td className="t-center t-border-primary t-border-right">
                                            <Row gutter={8}>
                                                <Col>
                                                    <Button
                                                        type={String(i) === props.expanded ? 'primary' : 'default'}
                                                        size="small"
                                                        disabled={
                                                            !fuelConsumption.entries ||
                                                            fuelConsumption.entries?.length === 0
                                                        }
                                                        icon={
                                                            <i
                                                                className={cn('fa', 'fa-fw', {
                                                                    'fa-chevron-up': String(i) !== props.expanded,
                                                                    'fa-chevron-down': String(i) === props.expanded
                                                                })}
                                                            />
                                                        }
                                                    />
                                                </Col>
                                                <Col>
                                                    {props.agregation.date === FuelStatsGroupByTimespan.Day &&
                                                        moment
                                                            .utc(fuelConsumption.dateFrom, DATE_FORMAT_SHORT_BE)
                                                            .format('L')}
                                                    {props.agregation.date === FuelStatsGroupByTimespan.Week &&
                                                        moment
                                                            .utc(fuelConsumption.dateFrom, DATE_FORMAT_SHORT_BE)
                                                            .format('L') +
                                                            ' - ' +
                                                            moment
                                                                .utc(fuelConsumption.dateTo, DATE_FORMAT_SHORT_BE)
                                                                .format('L')}
                                                    {props.agregation.date === FuelStatsGroupByTimespan.Month &&
                                                        moment
                                                            .utc(fuelConsumption.dateFrom, DATE_FORMAT_SHORT_BE)
                                                            .format('MMMM YYYY')}
                                                </Col>
                                            </Row>
                                        </td>
                                        <td className="t-center t-border-primary t-border-right">
                                            {props.agregation.entity === 'vehicle'
                                                ? getRegistrationNumber(
                                                      !!vehicle?.disabledAt,
                                                      vehicle?.registrationNumber ?? ''
                                                  )
                                                : (driver?.name ?? '') + ' ' + (driver?.surname ?? '')}
                                        </td>
                                        {props.roles.includes(Role.RID_R) && (
                                            <>
                                                <td className="t-center t-border-secondary t-border-right">
                                                    {(fuelConsumption.stats.consumptionLiters &&
                                                        numeral(fuelConsumption.stats.consumptionLiters).format(
                                                            '0,0.00'
                                                        )) ||
                                                        '-'}
                                                </td>
                                                <td className="t-center t-border-primary t-border-right">
                                                    {(fuelConsumption.stats.consumptionLitersPer100km &&
                                                        numeral(fuelConsumption.stats.consumptionLitersPer100km).format(
                                                            '0,0.00'
                                                        )) ||
                                                        '-'}
                                                </td>
                                            </>
                                        )}
                                        <td className="t-center t-border-secondary t-border-right">
                                            {(fuelConsumption.stats.consumptionActivityLiters &&
                                                numeral(fuelConsumption.stats.consumptionActivityLiters).format(
                                                    '0,0.00'
                                                )) ||
                                                '-'}
                                        </td>
                                        <td className="t-center t-border-secondary t-border-right">
                                            {(fuelConsumption.stats.distanceKilometers &&
                                                numeral(fuelConsumption.stats.distanceKilometers).format('0,0.00') +
                                                    ' km') ||
                                                '-'}
                                        </td>
                                        <td className="t-center t-border-primary t-border-right">
                                            {(fuelConsumption.stats.consumptionActivityLitersPer100km &&
                                                numeral(fuelConsumption.stats.consumptionActivityLitersPer100km).format(
                                                    '0,0.00'
                                                )) ||
                                                '-'}
                                        </td>
                                        {props.roles.includes(Role.RID_R) && (
                                            <>
                                                <td className="t-center t-border-secondary t-border-right">
                                                    {(fuelConsumption.stats.consumptionIdleLiters &&
                                                        numeral(fuelConsumption.stats.consumptionIdleLiters).format(
                                                            '0,0.00'
                                                        )) ||
                                                        '-'}
                                                </td>
                                                <td className="t-center t-border-primary t-border-right">
                                                    {(fuelConsumption.stats.idleTimeSeconds &&
                                                        duration(
                                                            fuelConsumption.stats.idleTimeSeconds,
                                                            'seconds'
                                                        ).format()) ||
                                                        '-'}
                                                </td>
                                                <td className="t-center t-border-primary t-border-right">
                                                    {(fuelConsumption.stats.consumptionIdleLitersPer1h &&
                                                        numeral(
                                                            fuelConsumption.stats.consumptionIdleLitersPer1h
                                                        ).format('0,0.00')) ||
                                                        '-'}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                    {fuelConsumption.entries &&
                                        String(i) === props.expanded &&
                                        fuelConsumption.entries?.map((entry, j) => {
                                            const driver = props.drivers?.find(
                                                driver => driver.id === String(entry.driverId)
                                            );
                                            const vehicle = props.vehicles?.find(
                                                vehicle => vehicle.id === Number(entry.monitoredObjectId)
                                            );
                                            return (
                                                <tr
                                                    key={j}
                                                    className={cn([
                                                        'detail',
                                                        't-small',
                                                        {
                                                            't-secondary': step % 2 ? false : true
                                                        }
                                                    ])}
                                                >
                                                    <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                        {moment.utc(entry.dateFrom, DATE_FORMAT_SHORT_BE).format('L')}
                                                    </td>
                                                    <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                        {props.agregation.entity === 'vehicle'
                                                            ? (driver?.name ?? '') + ' ' + (driver?.surname ?? '')
                                                            : getRegistrationNumber(
                                                                  !!vehicle?.disabledAt,
                                                                  vehicle?.registrationNumber ?? ''
                                                              )}
                                                    </td>
                                                    {props.roles.includes(Role.RID_R) && (
                                                        <>
                                                            <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                                {(entry.stats.consumptionLiters &&
                                                                    numeral(entry.stats.consumptionLiters).format(
                                                                        '0,0.00'
                                                                    )) ||
                                                                    '-'}
                                                            </td>
                                                            <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                                {(entry.stats.consumptionLitersPer100km &&
                                                                    numeral(
                                                                        entry.stats.consumptionLitersPer100km
                                                                    ).format('0,0.00')) ||
                                                                    '-'}
                                                            </td>
                                                        </>
                                                    )}
                                                    <td className="t-center t-padding-tiny t-border-secondary t-border-right">
                                                        {(entry.stats.consumptionActivityLiters &&
                                                            numeral(entry.stats.consumptionActivityLiters).format(
                                                                '0,0.00'
                                                            )) ||
                                                            '-'}
                                                    </td>
                                                    <td className="t-center t-padding-tiny t-border-secondary t-border-right">
                                                        {(entry.stats.distanceKilometers &&
                                                            numeral(entry.stats.distanceKilometers).format('0,0.00') +
                                                                ' km') ||
                                                            '-'}
                                                    </td>
                                                    <td
                                                        title={t('FuelConsumptionFilter.fuelSuspiciousActivity')}
                                                        className={cn('t-center t-border-primary t-border-right', {
                                                            'fuel-suspicious-activity':
                                                                Number(entry.stats.consumptionActivityLitersPer100km) >
                                                                confDefault.settings.statistics.fuelConsumption.filter
                                                                    .fuelSuspiciousActivityLimit
                                                        })}
                                                    >
                                                        {(entry.stats.consumptionActivityLitersPer100km &&
                                                            numeral(
                                                                entry.stats.consumptionActivityLitersPer100km
                                                            ).format('0,0.00')) ||
                                                            '-'}
                                                    </td>
                                                    {props.roles.includes(Role.RID_R) && (
                                                        <>
                                                            <td className="t-center t-padding-tiny t-border-secondary t-border-right">
                                                                {(entry.stats.consumptionIdleLiters &&
                                                                    numeral(entry.stats.consumptionIdleLiters).format(
                                                                        '0,0.00'
                                                                    )) ||
                                                                    '-'}
                                                            </td>
                                                            <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                                {(entry.stats.idleTimeSeconds &&
                                                                    duration(
                                                                        entry.stats.idleTimeSeconds,
                                                                        'seconds'
                                                                    ).format()) ||
                                                                    '-'}
                                                            </td>
                                                            <td className="t-center t-padding-tiny t-border-primary t-border-right">
                                                                {entry.stats.consumptionIdleLitersPer1h
                                                                    ? numeral(
                                                                          entry.stats.consumptionIdleLitersPer1h
                                                                      ).format('0,0.00')
                                                                    : '-'}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            );
                        })
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={head[1].length}>
                                    <NoData />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            )}
        </div>
    );
}

export default withTranslation()(FuelConsumptionTable);
