import { Button } from 'common/components';
import UiTabBar from 'common/components/UiTabBar/UiTabBar';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GraphKey, GraphOption } from '../JourneyGraph';
import { closeIconSquare } from 'resources/images/common';
import ExternalDevicesOptions from './graphHeader/ExternalDevicesOptions';
import { ExternalDevicesConfig } from './utils/useExternalDevicesConfig';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    onClose(): void;
    options: GraphOption[];
    selectedSection: GraphKey;
    onSectionSelect(key: GraphKey): void;
    externalDevicesSelected: Boolean;
    externalDevicesConfig: ExternalDevicesConfig;
    onChangeZone(zone: number): void;
    onToggleSensor(sensorSerial: string[]): void;
}

const GraphHeader = ({
    onClose,
    options,
    selectedSection,
    onSectionSelect,
    externalDevicesSelected,
    externalDevicesConfig,
    onChangeZone,
    onToggleSensor
}: Props) => {
    return (
        <div className="journey-graph--header">
            <UiTabBar options={options} selectedOption={selectedSection} onSelect={onSectionSelect} />
            {externalDevicesSelected && (
                <ExternalDevicesOptions
                    externalDevicesConfig={externalDevicesConfig}
                    onChangeZone={onChangeZone}
                    onToggleSensor={onToggleSensor}
                />
            )}
            <Button
                className="graph-header-button"
                size="middle"
                onClick={onClose}
                icon={<img src={closeIconSquare} alt="close" />}
                qa={qa.journeys.graph.btnClose}
            />
        </div>
    );
};

export default withTranslation()(GraphHeader);
