/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigestDriverBehaviourDriver,
    DigestDriverBehaviourDriverFromJSON,
    DigestDriverBehaviourDriverFromJSONTyped,
    DigestDriverBehaviourDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface PerfectDriveScoreDriver
 */
export interface PerfectDriveScoreDriver {
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreDriver
     */
    overalScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreDriver
     */
    driveScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreDriver
     */
    ecoScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreDriver
     */
    wearTearScore: number;
    /**
     * 
     * @type {number}
     * @memberof PerfectDriveScoreDriver
     */
    rank?: number;
    /**
     * 
     * @type {DigestDriverBehaviourDriver}
     * @memberof PerfectDriveScoreDriver
     */
    driver: DigestDriverBehaviourDriver;
}

export function PerfectDriveScoreDriverFromJSON(json: any): PerfectDriveScoreDriver {
    return PerfectDriveScoreDriverFromJSONTyped(json, false);
}

export function PerfectDriveScoreDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDriveScoreDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overalScore': json['overal_score'],
        'driveScore': json['drive_score'],
        'ecoScore': json['eco_score'],
        'wearTearScore': json['wear_tear_score'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'driver': DigestDriverBehaviourDriverFromJSON(json['driver']),
    };
}

export function PerfectDriveScoreDriverToJSON(value?: PerfectDriveScoreDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overal_score': value.overalScore,
        'drive_score': value.driveScore,
        'eco_score': value.ecoScore,
        'wear_tear_score': value.wearTearScore,
        'rank': value.rank,
        'driver': DigestDriverBehaviourDriverToJSON(value.driver),
    };
}


