/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MuStreamEdit,
    MuStreamEditFromJSON,
    MuStreamEditToJSON,
} from '../models';

export interface GetEditV1MustreamsEditLastGetRequest {
    monitoredObjectIds: Array<string>;
}

export interface PostEditV1MustreamsEditPostRequest {
    muStreamEdit: Array<MuStreamEdit>;
}

/**
 * no description
 */
export class MuStreamsApi extends runtime.BaseAPI {

    /**
     * Get Edit
     */
    async getEditV1MustreamsEditLastGetRaw(requestParameters: GetEditV1MustreamsEditLastGetRequest): Promise<runtime.ApiResponse<{ [key: string]: Array<MuStreamEdit>; }>> {
        if (requestParameters.monitoredObjectIds === null || requestParameters.monitoredObjectIds === undefined) {
            throw new runtime.RequiredError('monitoredObjectIds','Required parameter requestParameters.monitoredObjectIds was null or undefined when calling getEditV1MustreamsEditLastGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectIds) {
            queryParameters['monitored_object_ids'] = requestParameters.monitoredObjectIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/mustreams/edit/last`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Edit
     */
    async getEditV1MustreamsEditLastGet(requestParameters: GetEditV1MustreamsEditLastGetRequest): Promise<{ [key: string]: Array<MuStreamEdit>; }> {
        const response = await this.getEditV1MustreamsEditLastGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Edit
     */
    async postEditV1MustreamsEditPostRaw(requestParameters: PostEditV1MustreamsEditPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.muStreamEdit === null || requestParameters.muStreamEdit === undefined) {
            throw new runtime.RequiredError('muStreamEdit','Required parameter requestParameters.muStreamEdit was null or undefined when calling postEditV1MustreamsEditPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/mustreams/edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.muStreamEdit.map(MuStreamEditToJSON),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Post Edit
     */
    async postEditV1MustreamsEditPost(requestParameters: PostEditV1MustreamsEditPostRequest): Promise<object> {
        const response = await this.postEditV1MustreamsEditPostRaw(requestParameters);
        return await response.value();
    }

}
