import cn from 'classnames';
import { StatusToIcon } from 'modules/tracking/components/TrackingTable';
import { VehicleModelMap } from 'logic/map/logic/vehicles';
import React, { Fragment } from 'react';
import { NoGPSStatus } from 'common/model/tracking';
import { noGps } from 'resources/images/common';

interface Props {
    vehicles: VehicleModelMap[];
    showIcon: boolean;
}

export function VehicleClusterInfobox(props: Props) {
    return (
        <div className="infobox vehicle-cluster-infobox">
            {props.vehicles.map(vehicle => (
                <Fragment key={vehicle.id}>
                    <div
                        className={cn('t-nowrap-with-elipses vehicle', { selected: vehicle.selected })}
                        title={vehicle.name}
                    >
                        {vehicle.noGpsStatus === NoGPSStatus.SwitzerlandUnavailable ? (
                            <img className="infobox-no-gps" width="16" height="16" src={noGps} alt="no-gps" />
                        ) : (
                            props.showIcon &&
                            !vehicle.noGpsStatus && <i className={cn(`fa fa-${StatusToIcon[vehicle.status]}`)} />
                        )}
                        {vehicle.name}
                    </div>
                    {/* Add in updated design */}
                    {/* {!!vehicle.trailers.length && (
                        <div
                            className={cn('t-nowrap-with-elipses trailer', { selected: vehicle.selected })}
                            title={vehicle.trailers.join(', ')}
                        >
                            {vehicle.trailers.join(', ')}
                        </div>
                    )} */}
                </Fragment>
            ))}
        </div>
    );
}
