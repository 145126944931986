import { Switch, Route, Redirect } from 'react-router';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';
import { RouteNames, rbac } from 'App';
import CompanyProfileCostsModule from './modules/Costs/CompanyProfileCostsModule';
import CompanyProfileDashboardModule from './modules/Dashboard/CompanyProfileDashboardModule';
import TotalCostOfOwnershipLayout from './modules/TotlaCostOfOwnership/TotalCostOfOwnershipLayout';

interface Props {
    logic: Logic;
}

export default function CompanyProfileModule(props: Props) {
    const roles = props.logic.auth().roles();
    return (
        <Switch>
            <Route
                path={RouteNames.STATISTICS_COMPANY_PROFILE_DASHBOARD}
                render={rbac(roles, [Role.DBHD_R], <CompanyProfileDashboardModule logic={props.logic} />)}
            />
            <Route
                path={RouteNames.STATISTICS_COMPANY_PROFILE_COSTS}
                render={rbac(roles, [Role.CP_R], <CompanyProfileCostsModule logic={props.logic} />)}
            />
            <Route
                path={RouteNames.STATISTICS_COMPANY_PROFILE_TOTAL_COST_OF_OWNERSHIP}
                render={rbac(roles, [Role.TCO], <TotalCostOfOwnershipLayout logic={props.logic} />)}
            />
            <Redirect
                to={
                    roles.includes(Role.DBHD_R)
                        ? RouteNames.STATISTICS_COMPANY_PROFILE_DASHBOARD
                        : RouteNames.STATISTICS_COMPANY_PROFILE_COSTS
                }
            />
        </Switch>
    );
}
