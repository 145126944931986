/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    TransportEventRule,
    TransportEventRuleFromJSON,
    TransportEventRuleFromJSONTyped,
    TransportEventRuleToJSON,
    VehicleTransportNextWayPoint,
    VehicleTransportNextWayPointFromJSON,
    VehicleTransportNextWayPointFromJSONTyped,
    VehicleTransportNextWayPointToJSON
} from './';

/**
 *
 * @export
 * @interface VehicleTransport
 */
export interface VehicleTransport {
    /**
     *
     * @type {string}
     * @memberof VehicleTransport
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof VehicleTransport
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof VehicleTransport
     */
    firstRta?: string;
    /**
     *
     * @type {VehicleTransportNextWayPoint}
     * @memberof VehicleTransport
     */
    nextWaypoint: VehicleTransportNextWayPoint;
    /**
     *
     * @type {Array<AddressStructured>}
     * @memberof VehicleTransport
     */
    addressStructured?: Array<AddressStructured>;
    /**
     *
     * @type {Array<TransportEventRule>}
     * @memberof VehicleTransport
     */
    activeEventRules?: Array<TransportEventRule>;
}

export function VehicleTransportFromJSON(json: any): VehicleTransport {
    return VehicleTransportFromJSONTyped(json, false);
}

export function VehicleTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleTransport {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        firstRta: !exists(json, 'firstRta') ? undefined : json['firstRta'],
        nextWaypoint: VehicleTransportNextWayPointFromJSON(json['nextWaypoint']),
        addressStructured: !exists(json, 'addressStructured')
            ? undefined
            : (json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON),
        activeEventRules: !exists(json, 'activeEventRules')
            ? undefined
            : (json['activeEventRules'] as Array<any>).map(TransportEventRuleFromJSON)
    };
}

export function VehicleTransportToJSON(value?: VehicleTransport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        firstRta: value.firstRta,
        nextWaypoint: VehicleTransportNextWayPointToJSON(value.nextWaypoint),
        addressStructured:
            value.addressStructured === undefined
                ? undefined
                : (value.addressStructured as Array<any>).map(AddressStructuredToJSON),
        activeEventRules:
            value.activeEventRules === undefined
                ? undefined
                : (value.activeEventRules as Array<any>).map(TransportEventRuleToJSON)
    };
}
