import { ReactNode, MouseEvent, useState, Component } from 'react';
import moment, { Moment } from 'moment';
import i18next from 'i18next';
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import cn from 'classnames';
import { Dropdown } from 'antd';
import DateTimeRangePicker from './DateTimeRangePickerComponent';

interface State {
    dateTimeRange: [Moment, Moment];
}

interface Props extends RangePickerDateProps<Moment> {
    trigger?: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    qa?: string;
    onShortcutConfirm?: boolean;
    maxDate?: Date;
    minDate?: Date;
    maxMonthRangePick?: number;
    shortcuts?: {};
}

class EnhancedDateTimeRangePicker extends Component<Props, State> {
    private _update: boolean = true;

    constructor(props: Props) {
        super(props);
        this.state = {
            dateTimeRange: [props.defaultValue?.[0] ?? moment(), props.defaultValue?.[1] ?? moment()]
        };
    }

    componentDidMount() {
        this._update = false;
    }

    componentWillUnmount() {
        this._update = true;
    }

    shouldComponentUpdate(): boolean {
        return this._update;
    }

    onChangeWithClose(values: [Moment, Moment] | null) {
        if (values) {
            this.setState({ dateTimeRange: values });
            this.props.onChange?.(values, [values?.[0]?.toISOString() ?? '', values?.[1].toISOString() ?? '']);
            this.props.onOpenChange?.(false);
        }
    }

    onChange(values: [Moment, Moment] | null) {
        this._update = true;
        if (values) {
            this.setState({ dateTimeRange: values });
        }
        this._update = false;
    }

    render() {
        return (
            <div
                className={cn(this.props.className, 'date-range-picker', {
                    showTime: this.props.showTime
                })}
            >
                <DateTimeRangePicker
                    shortcuts={
                        this.props.shortcuts ?? {
                            [i18next.t('common.today')]: {
                                start: moment().startOf('day'),
                                end: moment().endOf('day')
                            },
                            [i18next.t('common.thisWeek')]: {
                                start: moment().startOf('week'),
                                end: moment().endOf('week')
                            },
                            [i18next.t('common.thisMonth')]: {
                                start: moment().startOf('month'),
                                end: moment().endOf('month')
                            },
                            [i18next.t('common.yesterday')]: {
                                start: moment().subtract(1, 'days').startOf('day'),
                                end: moment().subtract(1, 'days').endOf('day')
                            },
                            [i18next.t('common.lastWeek')]: {
                                start: moment().subtract(1, 'week').startOf('week'),
                                end: moment().subtract(1, 'week').endOf('week')
                            },
                            [i18next.t('common.lastMonth')]: {
                                start: moment().subtract(1, 'month').startOf('month'),
                                end: moment().subtract(1, 'month').endOf('month')
                            }
                        }
                    }
                    onShortcutConfirm={this.props.onShortcutConfirm}
                    maxDate={this.props.maxDate}
                    minDate={this.props.minDate}
                    maxMonthRangePick={this.props.maxMonthRangePick}
                    defaultValue={this.state.dateTimeRange}
                    showTime={this.props.showTime}
                    onOpenChange={this.props.onOpenChange}
                    confirmButtonText={i18next.t('common.confirm')}
                    cancelButtonText={i18next.t('common.cancel')}
                    onDateChange={(dateRange: [Moment, Moment]) => this.onChangeWithClose(dateRange)}
                />
            </div>
        );
    }
}

function EnhancedDateTimeRangePickerWithTrigger({ trigger, loading, qa, ...props }: Props) {
    const [open, setOpen] = useState(false);

    function onPickerOpen(e: MouseEvent<HTMLDivElement>) {
        if (!loading) {
            setOpen(!open);
            e.preventDefault();
        }
    }

    function onOpenChange(value: boolean) {
        setOpen(value);
    }

    if (trigger) {
        return (
            <Dropdown
                className={cn(
                    'old-picker-range-wrapper',
                    { 'old-picker-range-wrapper-disabled': props.disabled },
                    props.className
                )}
                disabled={loading}
                overlay={
                    open ? <EnhancedDateTimeRangePicker {...props} open={open} onOpenChange={onOpenChange} /> : <></>
                }
                visible={open}
            >
                <div onClick={onPickerOpen} data-qa={qa}>
                    {trigger}
                </div>
            </Dropdown>
        );
    }

    return <EnhancedDateTimeRangePicker {...props} />;
}

export default EnhancedDateTimeRangePickerWithTrigger;
