import qa from 'qa-selectors';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import GraphSwitch from './JourneyGraph/graph/GraphSwitch';
import GraphHeader from './JourneyGraph/GraphHeader';
import { useExternalDevicesConfig } from './JourneyGraph/utils/useExternalDevicesConfig';
import { JourneyGraphDataModel } from '../JourneysModule';
import { Role } from 'logic/auth';
import { TripTemperatureSensor } from 'generated/backend-api';

interface Props extends WithTranslation {
    onCloseJourneyGraph(): void;
    setGraphSection?(section: GraphKey): void;
    roles: Role[];
    sensors?: TripTemperatureSensor[];
    data?: JourneyGraphDataModel;
    onActiveDotMount(p: { key: string | number; value: string | number }, i: number): void;
    onActiveDotDestroy?(): void;
    activeGraphDotIndex?: number;
}

export enum GraphKey {
    speeds = 'speeds',
    fuelLevels = 'fuelLevels',
    externalDevices = 'externalDevices'
}

export interface GraphOption {
    key: GraphKey;
    label: string;
    qa?: string;
}

function JourneyGraph({
    setGraphSection,
    roles,
    onCloseJourneyGraph,
    data,
    sensors,
    onActiveDotMount,
    onActiveDotDestroy,
    activeGraphDotIndex,
    t
}: Props) {
    const { presetExternalDevicesConfig, externalDevicesConfig, onChangeZone, onToggleSensor } =
        useExternalDevicesConfig(data, sensors);
    const [selectedSection, setSelectedSection] = useState<GraphKey>(GraphKey.speeds);

    useEffect(() => {
        presetExternalDevicesConfig(selectedSection);
        setGraphSection?.(selectedSection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSection]);

    let OPTIONS: GraphOption[] = [
        {
            key: GraphKey.speeds,
            label: t('JourneysTable.graph.speed'),
            qa: qa.journeys.graph.btnSpeed
        },
        {
            key: GraphKey.fuelLevels,
            label: t('JourneysTable.graph.fuelLevel'),
            qa: qa.journeys.graph.btnFuelLevels
        }
    ];

    if (roles.includes(Role.CLD_R) && sensors?.length) {
        OPTIONS = [
            ...OPTIONS,
            {
                key: GraphKey.externalDevices,
                label: t('JourneysTable.graph.externalDevices'),
                qa: qa.journeys.graph.btnColdChain
            }
        ];
    }

    return (
        <div className="journey-graph">
            <GraphHeader
                onClose={onCloseJourneyGraph}
                options={OPTIONS}
                selectedSection={selectedSection}
                onSectionSelect={setSelectedSection}
                externalDevicesSelected={
                    selectedSection === GraphKey.externalDevices && roles.includes(Role.CLD_R) && sensors?.length
                        ? true
                        : false
                }
                externalDevicesConfig={externalDevicesConfig}
                onChangeZone={onChangeZone}
                onToggleSensor={onToggleSensor}
            />
            <div className="graph-wrapper">
                <GraphSwitch
                    data={data}
                    selectedSection={selectedSection}
                    activeGraphDotIndex={activeGraphDotIndex}
                    onActiveDotMount={onActiveDotMount}
                    onActiveDotDestroy={onActiveDotDestroy}
                    externalDevicesConfig={externalDevicesConfig}
                    seriesLabels={sensors
                        ?.map(sensor => ({ [sensor.serialNumber]: sensor.sensorName }))
                        .reduce((a, b) => ({ ...a, ...b }), {})}
                />
            </div>
        </div>
    );
}

export default withTranslation()(JourneyGraph);
