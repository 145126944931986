/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressClient,
    AddressClientFromJSON,
    AddressClientFromJSONTyped,
    AddressClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    clientId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    country: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    type?: AddressTypeEnum;
    /**
     * 
     * @type {AddressClient}
     * @memberof Address
     */
    client?: AddressClient;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'country': json['country'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'house_number') ? undefined : json['house_number'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'client': !exists(json, 'client') ? undefined : AddressClientFromJSON(json['client']),
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'country': value.country,
        'city': value.city,
        'street': value.street,
        'house_number': value.houseNumber,
        'zip': value.zip,
        'type': value.type,
        'client': AddressClientToJSON(value.client),
    };
}

/**
* @export
* @enum {string}
*/
export enum AddressTypeEnum {
    Correspondence = 'correspondence',
    Delivery = 'delivery'
}


