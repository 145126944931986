import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import { Card } from 'common/components';

interface Props {
    labels?: ReactNode[];
    className?: string;
    actions?: ReactNode[];
    content: ReactNode;
    size?: 'middle' | 'large';
}

export default function TableContentCard({ size = 'large', ...props }: Props) {
    return (
        <Card
            className={`table-content-card table-content-card-${size} ${props.className}`}
            extra={
                props.actions && (
                    <Row gutter={16} align="middle">
                        {props.actions?.map((d, i) => (
                            <Col key={i}>{d}</Col>
                        ))}
                    </Row>
                )
            }
            bordered={false}
        >
            {props.content}
        </Card>
    );
}
