/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileDeviceHasMobileAppLogCurrentVersion
 */
export interface MobileDeviceHasMobileAppLogCurrentVersion {
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceHasMobileAppLogCurrentVersion
     */
    mobileDeviceDeviceId: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceHasMobileAppLogCurrentVersion
     */
    mobileAppPackageName: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceHasMobileAppLogCurrentVersion
     */
    mobileAppAppName: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceHasMobileAppLogCurrentVersion
     */
    mobileAppVersionName: string;
    /**
     * 
     * @type {number}
     * @memberof MobileDeviceHasMobileAppLogCurrentVersion
     */
    mobileAppVersionCode: number;
}

export function MobileDeviceHasMobileAppLogCurrentVersionFromJSON(json: any): MobileDeviceHasMobileAppLogCurrentVersion {
    return MobileDeviceHasMobileAppLogCurrentVersionFromJSONTyped(json, false);
}

export function MobileDeviceHasMobileAppLogCurrentVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceHasMobileAppLogCurrentVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobileDeviceDeviceId': json['mobile_device_device_id'],
        'mobileAppPackageName': json['mobile_app_package_name'],
        'mobileAppAppName': json['mobile_app_app_name'],
        'mobileAppVersionName': json['mobile_app_version_name'],
        'mobileAppVersionCode': json['mobile_app_version_code'],
    };
}

export function MobileDeviceHasMobileAppLogCurrentVersionToJSON(value?: MobileDeviceHasMobileAppLogCurrentVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile_device_device_id': value.mobileDeviceDeviceId,
        'mobile_app_package_name': value.mobileAppPackageName,
        'mobile_app_app_name': value.mobileAppAppName,
        'mobile_app_version_name': value.mobileAppVersionName,
        'mobile_app_version_code': value.mobileAppVersionCode,
    };
}


