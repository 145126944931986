import React from 'react';
import cn from 'classnames';

interface Props {
    first?: number;
    second?: number;
    third?: number;
    fourth?: number;
    maxWidth: number;
    label?: {
        first?: string;
        firstTooltip?: string;
        second?: string;
        secondTooltip?: string;
        third?: string;
        thirdTooltip?: string;
        fourth?: string;
        fourthTooltip?: string;
    };
    legend?: {
        first?: string;
        second?: string;
        third?: string;
        fourth?: string;
    };
    hover?: boolean;
}

function ProgressBar(props: Props) {
    const firstWidth: number = ((props.first ?? 0) / props.maxWidth) * 100;
    const secondWidth: number = firstWidth < 100 ? ((props.second ?? 0) / props.maxWidth) * 100 : 0;
    const thirdWidth: number = firstWidth + secondWidth < 100 ? ((props.third ?? 0) / props.maxWidth) * 100 : 0;
    const fourthWidth: number =
        firstWidth + secondWidth + thirdWidth < 100 ? ((props.fourth ?? 0) / props.maxWidth) * 100 : 0;

    const fourthLabelWidth: number =
        (props.first ?? 0) + (props.second ?? 0) + (props.third ?? 0) <= props.maxWidth * 0.9 ? fourthWidth : 0;

    return (
        <div
            className={cn('progress-bar', {
                legend: props.legend
            })}
        >
            {props.label && (
                <div className="label">
                    <div
                        className="text-first t-center t-bold"
                        style={{ width: `${firstWidth}%` }}
                        title={props.label.firstTooltip}
                    >
                        {props.label.first}
                    </div>
                    <div
                        className="text-second t-center t-bold"
                        style={{ width: `${secondWidth}%` }}
                        title={props.label.secondTooltip}
                    >
                        {props.label.second}
                    </div>
                    <div
                        className="text-third t-center t-bold"
                        style={{ width: `${thirdWidth}%` }}
                        title={props.label.thirdTooltip}
                    >
                        {props.label.third}
                    </div>
                    <div
                        className={cn('text-fourth t-bold', { fixed: !fourthLabelWidth })}
                        style={{
                            width: fourthLabelWidth ? `${fourthLabelWidth}%` : '',
                            textAlign: fourthLabelWidth > 10 ? 'center' : 'right'
                        }}
                        title={props.label.fourthTooltip}
                    >
                        {props.label.fourth}
                    </div>
                </div>
            )}
            <div className="bar">
                <div className="first" style={{ width: `${firstWidth}%` }}>
                    {' '}
                </div>
                <div className="second" style={{ width: `${secondWidth}%` }}>
                    {' '}
                </div>
                <div className="third" style={{ width: `${thirdWidth}%` }}>
                    {' '}
                </div>
                <div className="fourth" style={{ width: `${fourthWidth}%` }}>
                    {' '}
                </div>
            </div>
            {props.legend && (
                <div className="legend t-tiny">
                    <div>
                        <span className="square first">&nbsp;</span>
                        {props.legend.first}
                    </div>
                    <div>
                        <span className="square second">&nbsp;</span>
                        {props.legend.second}
                    </div>
                    <div>
                        <span className="square third">&nbsp;</span>
                        {props.legend.third}
                    </div>
                    <div>
                        <span className="square fourth text t-no-wrap">&nbsp;</span>
                        {props.legend.fourth}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProgressBar;
