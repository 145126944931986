/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectFeFleetFuelCard,
    MonitoredObjectFeFleetFuelCardFromJSON,
    MonitoredObjectFeFleetFuelCardFromJSONTyped,
    MonitoredObjectFeFleetFuelCardToJSON,
    MonitoredObjectFeFleetMD,
    MonitoredObjectFeFleetMDFromJSON,
    MonitoredObjectFeFleetMDFromJSONTyped,
    MonitoredObjectFeFleetMDToJSON,
    MonitoredObjectFeFleetMOGs,
    MonitoredObjectFeFleetMOGsFromJSON,
    MonitoredObjectFeFleetMOGsFromJSONTyped,
    MonitoredObjectFeFleetMOGsToJSON,
    MonitoredObjectFeFleetMOType,
    MonitoredObjectFeFleetMOTypeFromJSON,
    MonitoredObjectFeFleetMOTypeFromJSONTyped,
    MonitoredObjectFeFleetMOTypeToJSON,
    MonitoredObjectFeFleetTemperatureSensor,
    MonitoredObjectFeFleetTemperatureSensorFromJSON,
    MonitoredObjectFeFleetTemperatureSensorFromJSONTyped,
    MonitoredObjectFeFleetTemperatureSensorToJSON,
    MonitoredObjectMetadata,
    MonitoredObjectMetadataFromJSON,
    MonitoredObjectMetadataFromJSONTyped,
    MonitoredObjectMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyMonitoredObjectFeFleet
 */
export interface ReadOnlyMonitoredObjectFeFleet {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    customLabel?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    disabledAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    manufacturerName?: string | null;
    /**
     * 
     * @type {MonitoredObjectMetadata}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    metadata?: MonitoredObjectMetadata;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    tankSize?: number | null;
    /**
     * 
     * @type {MonitoredObjectFeFleetMOType}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    monitoredObjectType: MonitoredObjectFeFleetMOType;
    /**
     * 
     * @type {Array<MonitoredObjectFeFleetFuelCard>}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    fuelCards: Array<MonitoredObjectFeFleetFuelCard>;
    /**
     * 
     * @type {Array<MonitoredObjectFeFleetMOGs>}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    monitoredObjectGroups: Array<MonitoredObjectFeFleetMOGs>;
    /**
     * 
     * @type {MonitoredObjectFeFleetMD}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    monitoringDevice: MonitoredObjectFeFleetMD;
    /**
     * 
     * @type {Array<MonitoredObjectFeFleetTemperatureSensor>}
     * @memberof ReadOnlyMonitoredObjectFeFleet
     */
    temperatureSensors: Array<MonitoredObjectFeFleetTemperatureSensor>;
}

export function ReadOnlyMonitoredObjectFeFleetFromJSON(json: any): ReadOnlyMonitoredObjectFeFleet {
    return ReadOnlyMonitoredObjectFeFleetFromJSONTyped(json, false);
}

export function ReadOnlyMonitoredObjectFeFleetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMonitoredObjectFeFleet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'registrationNumber': json['registration_number'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'disabledAt': !exists(json, 'disabled_at') ? undefined : (json['disabled_at'] === null ? null : new Date(json['disabled_at'])),
        'manufacturerName': !exists(json, 'manufacturer_name') ? undefined : json['manufacturer_name'],
        'metadata': !exists(json, 'metadata') ? undefined : MonitoredObjectMetadataFromJSON(json['metadata']),
        'tankSize': !exists(json, 'tank_size') ? undefined : json['tank_size'],
        'monitoredObjectType': MonitoredObjectFeFleetMOTypeFromJSON(json['monitored_object_type']),
        'fuelCards': ((json['fuel_cards'] as Array<any>).map(MonitoredObjectFeFleetFuelCardFromJSON)),
        'monitoredObjectGroups': ((json['monitored_object_groups'] as Array<any>).map(MonitoredObjectFeFleetMOGsFromJSON)),
        'monitoringDevice': MonitoredObjectFeFleetMDFromJSON(json['monitoring_device']),
        'temperatureSensors': ((json['temperature_sensors'] as Array<any>).map(MonitoredObjectFeFleetTemperatureSensorFromJSON)),
    };
}

export function ReadOnlyMonitoredObjectFeFleetToJSON(value?: ReadOnlyMonitoredObjectFeFleet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registration_number': value.registrationNumber,
        'custom_label': value.customLabel,
        'disabled_at': value.disabledAt === undefined ? undefined : (value.disabledAt === null ? null : value.disabledAt.toISOString()),
        'manufacturer_name': value.manufacturerName,
        'metadata': MonitoredObjectMetadataToJSON(value.metadata),
        'tank_size': value.tankSize,
        'monitored_object_type': MonitoredObjectFeFleetMOTypeToJSON(value.monitoredObjectType),
        'fuel_cards': ((value.fuelCards as Array<any>).map(MonitoredObjectFeFleetFuelCardToJSON)),
        'monitored_object_groups': ((value.monitoredObjectGroups as Array<any>).map(MonitoredObjectFeFleetMOGsToJSON)),
        'monitoring_device': MonitoredObjectFeFleetMDToJSON(value.monitoringDevice),
        'temperature_sensors': ((value.temperatureSensors as Array<any>).map(MonitoredObjectFeFleetTemperatureSensorToJSON)),
    };
}


