import { LINE_COLORS } from 'common/components/SimpleLineGraph/constants/colors';
import { SpecifycSettings } from 'common/components/SimpleLineGraph/getGraphConfig';
import SimpleLineGraph from 'common/components/SimpleLineGraph/SimpleLineGraph';
import { useMemo } from 'react';
import { GraphKey } from '../../JourneyGraph';
import { formatGraphData } from '../utils/formatGraphData';
import qa from 'qa-selectors';

interface Props {
    data?: {
        lats: number[] | null;
        lons: number[] | null;
        fuelLevels: number[] | null;
        speeds: number[] | null;
        timestamps: number[] | null;
    };
    onActiveDotMount(p: { key: string | number; value: string | number }, i: number): void;
    selectedSection: GraphKey;
    onActiveDotDestroy?(): void;
    activeGraphDotIndex?: number;
}

const SimpleGraph = ({ data, onActiveDotMount, onActiveDotDestroy, activeGraphDotIndex, selectedSection }: Props) => {
    const displayedGraphData = useMemo(() => formatGraphData(selectedSection, data), [selectedSection, data]);

    const getSpecifycSettings = (): SpecifycSettings => {
        switch (selectedSection) {
            case GraphKey.speeds:
                return {
                    lineStroke: LINE_COLORS.primaryLineColor,
                    tooltipValueUnit: 'km/h',
                    qa: qa.journeys.graph.sectionGraphSpeed
                };
            case GraphKey.fuelLevels: {
                return {
                    lineStroke: LINE_COLORS.secondaryLineColor,
                    tooltipValueUnit: 'l',
                    qa: qa.journeys.graph.sectionGraphFuelLevels
                };
            }

            default:
                return {
                    lineStroke: LINE_COLORS.primaryLineColor,
                    tooltipValueUnit: ''
                };
        }
    };

    return (
        <SimpleLineGraph
            data={displayedGraphData}
            specifycSettings={getSpecifycSettings()}
            hideDots
            onActiveDotMount={onActiveDotMount}
            onActiveDotDestroy={onActiveDotDestroy}
            activeGraphDotIndex={activeGraphDotIndex}
        />
    );
};

export default SimpleGraph;
