import { Logic } from '../../logic';

export class UserTokens {
    constructor(private _logic: Logic) {}

    public async getUserTokens() {
        const userTokensResponse = await this._logic.api().userTokenApi.userTokenList({});
        return userTokensResponse.results;
    }
}
