import { CheckboxGroupOpt } from 'common/components/CheckboxGroup';
import { Logic } from 'logic/logic';
import {
    Cost,
    CostStructure,
    CostUnit,
    FixedCost,
    FixedCostModel,
    OperationalCost,
    OperationalCostModel,
    CostFleet,
    OperationalCostFleet
} from 'logic/statistics/statistics-company-profile';
import moment from 'moment';
import { Component } from 'react';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReadOnlyCurrency, ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { AvailableCurrencies } from 'common/model/currency';
import { WithTranslation, withTranslation } from 'react-i18next';
import { LayoutContent } from 'common/components/Layout/Content';
import CompanyProfileBar from '../../components/CompanyProfileBar';
import CompanyProfileCosts from '../../components/CompanyProfileCosts';
import CompanyProfileActions, { VehiclesTarget } from '../../components/CompanyProfileActions';
import { RouteNames } from 'App';
import CompanyProfileCostsTable from '../../components/CompanyProfileCostsTable';
import { Confirm } from 'common/components';
import { DATE_FORMAT } from 'domain-constants';
import { debounce } from 'debounce';
import { PricePerKmResult, MonitoredObjectFleetType } from 'generated/backend-api';
import { search } from 'common/utils/search';
import { Role } from 'logic/auth';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import HelperModal from 'common/components/HelperModal';
import { EFuelType } from 'generated/graphql';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { MessageType } from 'common/components/Confirm';
import { message } from 'antd';
import ModalForm from 'common/components/ModalForm';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
}

interface State {
    actions: {
        costs: boolean;
        data?: {
            currentCostsFleet?: CostFleet[];
            marketAverageFleet?: CostFleet[];
            lastUpdate: string;
        };
        vehicles: {
            label: string;
            code: string;
            checked: boolean;
        }[];
        vehiclesTarget: VehiclesTarget;
    };
    costs: {
        loading: boolean;
        currentCosts?: Cost;
        fixedCost?: {
            data: FixedCostModel;
        };
        operationalCost?: {
            data: OperationalCostModel;
        };
    };
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    table: {
        data: CostStructure[];
        search?: { text: string };
        loading: boolean;
        originalData: PricePerKmResult;
    };
    edit?: 'operational' | 'fixed' | undefined;
    showWarning?: boolean;
    currency: AvailableCurrencies;
    exchangeRates: ReadOnlyCurrency[];
    roles: Role[];
    helper?: {
        content: string;
    };
}

class CompanyProfileCostsModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const roles = this._logic.auth().roles();
        this.state = {
            actions: {
                costs: false,
                vehicles: [],
                vehiclesTarget: VehiclesTarget.All
            },
            costs: {
                loading: true
            },
            currency: AvailableCurrencies.EUR,
            exchangeRates: [],
            vehicles: [],
            table: {
                data: [],
                originalData: {} as PricePerKmResult,
                loading: true
            },
            roles
        };
    }

    componentDidMount() {
        (window as any).app.CompanyProfile = this;

        // TODO: Roles
        this._loadData();
        this._logic.settings().onChange(prop => {
            if (prop.vehicleIdentification) {
                this._logic.vehicles().changeVehicleIdentification(prop.vehicleIdentification);
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: true,
                        search: {
                            text: ''
                        }
                    }
                }));
                this._loadData();
            }
        });
    }

    componentWillUnmount() {
        (window as any).app.CompanyProfile = undefined;
    }

    render() {
        const { t } = this.props;

        const selectedCount = this.state.table.data.filter(d => {
            return d.selected;
        }).length;
        const selectedNoFuelType = this.state.table.data.filter(d => {
            const hasFuelType = [EFuelType.Diesel, EFuelType.Gasoline, EFuelType.Hybrid, EFuelType.Electro].includes(
                this.state.vehicles?.find(v => v.id === d.monitoredObjectId)?.fuelType as EFuelType
            );
            return d.selected && !hasFuelType;
        }).length;

        return (
            <>
                <LayoutContent
                    className="company-profile-bar-panel"
                    mainSizes={{ xs: 24, sm: 24, md: 19 }}
                    extraSizes={[{ xs: 24, sm: 24, md: 5 }]}
                    main={
                        <div className="company-profile-bar-panel">
                            <CompanyProfileBar
                                heading={t('CompanyProfile.costsCalculator')}
                                currency={this.state.currency}
                                data={this.state.table.data}
                                search={this.state.table.search}
                                loading={this.state.table.loading}
                                demoMode={this._logic.demo().isActive}
                                roles={this.state.roles}
                                onHelperClick={this._onBarHelperClick}
                                onDashboard={this._onActionsDashboard}
                                onSearchChange={this._onSearchInputChange}
                                onCurrencyChange={this._onCurrencyChange}
                                onCostEditChange={this._onCostEditChange}
                            />
                            <CompanyProfileCostsTable
                                data={this.state.table.data}
                                vehicles={this.state.vehicles}
                                loading={this.state.table.loading}
                                onCheckboxAllChange={this._onCheckboxAllChange}
                                onCheckboxChange={this._onCheckboxChange}
                            />
                        </div>
                    }
                    extra={[
                        <CompanyProfileActions
                            data={this.state.actions.data}
                            costs={this.state.actions.costs}
                            vehiclesTarget={this.state.actions.vehiclesTarget}
                            loading={this.state.costs.loading || this.state.table.loading}
                            vehicles={this.state.actions.vehicles}
                            demoMode={this._logic.demo().isActive}
                            roles={this.state.roles}
                            onAddServices={this._onActionsAddServices}
                            onFixedCostsEditChange={this._onFixedCostEditChange}
                            onSetCosts={this._onActionsSetCosts}
                            onVehiclesTargetChange={this._onActionsVehiclesTargetChange}
                            onVehiclesChange={this._onActionsVehiclesChange}
                            onActionsTotalCostOfOwnership={this._onActionsTotalCostOfOwnership}
                        />
                    ]}
                />
                {this.state.edit && (
                    <ModalForm
                        className="company-profile-modal"
                        title={
                            this.state.edit === 'operational'
                                ? `${t('CompanyProfile.costsCalculatorChange')} ${
                                      this.state.table.data.filter(d => d.selected).length > 1
                                          ? `${t('CompanyProfile.multipleVehicles')} (${
                                                this.state.table.data.filter(
                                                    d => d.selected && d.operationalCostProfile?.fuel
                                                ).length
                                            })`
                                          : this.state.vehicles?.find(
                                                v =>
                                                    v.id ===
                                                    this.state.table.data.filter(d => d.selected)?.[0].monitoredObjectId
                                            )?.registrationNumber
                                  }`
                                : t('CompanyProfile.editFixedCosts')
                        }
                        width="60%"
                        onCancel={this._onCostsClose}
                        visible={this.state.edit !== undefined}
                    >
                        <CompanyProfileCosts
                            loading={this.state.costs.loading}
                            currency={this.state.currency}
                            current={this.state.costs.currentCosts}
                            fixed={this.state.costs.fixedCost}
                            edit={this.state.edit}
                            operational={
                                this.state.table.data.filter(d => d.selected).length > 1
                                    ? {
                                          data: {
                                              dateFrom: this.state.costs?.operationalCost?.data?.dateFrom ?? '',
                                              defaultsFleet: this.state.costs?.operationalCost?.data?.defaultsFleet
                                          }
                                      }
                                    : this.state.costs.operationalCost
                            }
                            vehicles={this.state.vehicles}
                            tableData={this.state.table.data}
                            demoMode={this._logic.demo().isActive}
                            onClose={this._onCostsClose}
                            onSubmit={this._onCostsSubmit}
                        />
                    </ModalForm>
                )}
                {this.state.showWarning &&
                    this.state.edit === 'operational' &&
                    ((selectedNoFuelType === 1 && selectedCount === 1) ||
                        (selectedNoFuelType > 0 && selectedCount > 1)) && (
                        <Confirm
                            type={MessageType.WARNING}
                            message={t('CompanyProfile.messageNoFuelType')}
                            cancelButton={false}
                            onConfirm={() => {
                                this.setState(state => ({
                                    edit:
                                        (selectedNoFuelType === 1 && selectedCount === 1) ||
                                        selectedNoFuelType === selectedCount
                                            ? undefined
                                            : state.edit,
                                    showWarning: undefined
                                }));
                            }}
                        />
                    )}
                <HelperModal
                    name="company-profile-costs"
                    content={this.state.helper?.content ?? ''}
                    onClose={this._onHelperClose}
                    visible={!!this.state.helper}
                />
            </>
        );
    }

    private _loadData = () => {
        this._logic
            .vehicles()
            .getMonitoredObjectFilters()
            .then(async vehiclesData => {
                const vehicles = vehiclesData
                    .map<CheckboxGroupOpt>(v => ({
                        code: String(v.id),
                        label: v.registrationNumber,
                        checked: true
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label));

                await this._logic.auth().loadNewClient();

                const clientCost =
                    this._logic.auth().newClient()?.costPerKm && this._logic.auth().newClient()?.costPerKm?.currency
                        ? this._logic.auth().newClient()?.costPerKm
                        : undefined;

                this.setState(
                    state => ({
                        ...state,
                        vehicles: vehiclesData,
                        actions: {
                            ...state.actions,
                            vehicles
                        },
                        exchangeRates: this._logic.poi().currencies ?? [],
                        costs: {
                            ...state.costs,
                            loading: true,
                            currentCosts: clientCost
                                ? {
                                      currency: clientCost?.currency,
                                      value: clientCost?.cost,
                                      unit: CostUnit.PerKilometer
                                  }
                                : undefined
                        }
                    }),
                    () => {
                        this._onActionsSetCosts();
                    }
                );
            })
            .catch(e => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(e);
            });
    };

    private _recalculatePriceCurrency = (costs: FixedCost | OperationalCost, currency: AvailableCurrencies) => {
        if (this.state.costs.currentCosts?.currency === currency) {
            return costs;
        }

        const exchangeRate = (this._logic.poi().currencies ?? []).find(e => e.code === currency);

        const oldExchangeRate = (this._logic.poi().currencies ?? []).find(
            e => e.code === this.state.costs.currentCosts?.currency
        );

        if (exchangeRate) {
            const [exchange] = exchangeRate?.latestExchangeRate ?? [];
            const [oldExchange] = oldExchangeRate?.latestExchangeRate ?? [];

            const entries = Object.entries(costs) as Array<[keyof FixedCost, Cost]>;
            const resp = entries.map(([k, v]) => [
                k,
                {
                    ...v,
                    currency,
                    value:
                        this.state.costs.currentCosts?.currency === AvailableCurrencies.EUR
                            ? (v.value * (exchange?.rate ?? 1) * 100) / 100
                            : currency === AvailableCurrencies.EUR
                            ? ((v.value / (oldExchange?.rate ?? 1)) * 100) / 100
                            : ((v.value / (oldExchange?.rate ?? 1)) * (exchange?.rate ?? 1) * 100) / 100
                } as Cost
            ]);
            return Object.fromEntries(resp);
        }

        return costs;
    };
    private _exchangePriceCurrency = (
        oldCurrency: AvailableCurrencies,
        newCurrency: AvailableCurrencies,
        price: number
    ): number => {
        const exchangeRate = (this._logic.poi().currencies ?? []).find(e => e.code === newCurrency);

        const oldExchangeRate = (this._logic.poi().currencies ?? []).find(e => e.code === oldCurrency);

        if (exchangeRate && oldCurrency !== newCurrency) {
            const [exchange] = exchangeRate?.latestExchangeRate ?? [];
            const [oldExchange] = oldExchangeRate?.latestExchangeRate ?? [];

            const newPrice =
                oldCurrency === AvailableCurrencies.EUR
                    ? (price * (exchange?.rate ?? 1) * 100) / 100
                    : ((price / (oldExchange?.rate ?? 1)) * (exchange?.rate ?? 1) * 100) / 10;

            return newPrice;
        }

        return price;
    };

    private _onCurrencyChange = async (currency: AvailableCurrencies) => {
        const oldCurrency = this.state.currency;
        this.setState(
            state => ({
                currency,
                costs: { ...state.costs, loading: true },
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            async () => {
                try {
                    await this._logic.statisticsCompanyProfile().companyOperationalCostCreate({
                        costs: this._recalculatePriceCurrency(
                            this.state.costs.fixedCost?.data.costs as FixedCost,
                            currency
                        ),
                        dateFrom: moment().format(DATE_FORMAT)
                    });

                    if (this.state.table.data.filter(d => d.operationalCostProfile).length > 0) {
                        await this._logic.statisticsCompanyProfile().monitoredObjectOperationalCostCreateMany(
                            this.state.table.data
                                .map(d => ({
                                    monitoredObjectIds: [d.monitoredObjectId],
                                    operationalCostData: d.operationalCostProfile
                                        ? this._recalculatePriceCurrency(
                                              d.operationalCostProfile as OperationalCost,
                                              currency
                                          )
                                        : undefined
                                }))
                                .filter(d => d.operationalCostData)
                        );
                    }
                    const costs = await this._logic.statisticsCompanyProfile().computeCosts();
                    this.setState(state => ({
                        currency: currency,
                        costs: {
                            ...state.costs,
                            loading: false,
                            currentCosts: {
                                currency: costs.currency,
                                unit: CostUnit.PerKilometer,
                                value: costs.value
                            }
                        }
                    }));
                    await this._onActionsSetCosts();
                } catch (e) {
                    message.error(this.props.t('common.error.loadDataError'));
                    this.setState(state => ({
                        currency: oldCurrency,
                        costs: {
                            ...state.costs,
                            loading: false
                        },
                        table: {
                            ...state.table,
                            loading: false
                        }
                    }));
                }
            }
        );
    };

    private _onActionsAddServices = (): void => undefined;

    private _defaultAvgMarketCost = (
        pricePerKm: PricePerKmResult,
        fleetType: MonitoredObjectFleetType
    ): { price: number; currency: AvailableCurrencies } => {
        const marketAvg = pricePerKm.marketAvgPricePerKmOfFleetTypes?.find(d => d.fleetType === fleetType);

        if (marketAvg && marketAvg.numberOfCompanies >= 5) {
            return { price: marketAvg.avgPricePerKm, currency: marketAvg.currency as AvailableCurrencies };
        } else {
            const defaultMarketAvg = pricePerKm.defaultMarketAvgPricePerKmOfFleetTypes?.find(
                d => d.fleetType === fleetType
            );
            return {
                price: defaultMarketAvg?.avgPricePerKm ?? 0,
                currency: defaultMarketAvg?.currency as AvailableCurrencies
            };
        }
    };

    private _onActionsSetCosts = async (): Promise<void> => {
        this.setState(state => ({
            actions: {
                ...state.actions,
                costs: true
            },
            costs: {
                ...state.costs,
                loading: true
            }
        }));
        const monitoredObjectIds = this.state.actions.vehicles.filter(v => v.checked).map(v => Number(v.code));
        const usedTypesAll = this.state.vehicles.map(v =>
            v.fleetType === FleetType.VEHICLE
                ? MonitoredObjectFleetType.Vehicle
                : v.fleetType === FleetType.LIGHT_VEHICLE
                ? MonitoredObjectFleetType.LightVehicle
                : undefined
        );
        const usedTypes = usedTypesAll.filter((a, b) => usedTypesAll.indexOf(a) === b);

        const pricePerKmCostStructure = await this._logic
            .statisticsCompanyProfile()
            .pricePerKmCostStructure(monitoredObjectIds);

        const currency = (pricePerKmCostStructure.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR;
        const defaultMarketOperationalCosts =
            pricePerKmCostStructure.defaultMarketAvgOperationalCostProfileOfFleetTypes;

        let marketAverageFleet: CostFleet[] = [];
        usedTypes.forEach(t => {
            const marketAvg = this._logic
                .statisticsCompanyProfile()
                .avgMarketCost(pricePerKmCostStructure, t as MonitoredObjectFleetType);

            marketAverageFleet.push({
                value: this._exchangePriceCurrency(
                    (marketAvg.currency ?? currency) as AvailableCurrencies,
                    currency,
                    marketAvg.costPerKm ?? 0
                ),
                currency,
                unit: CostUnit.PerKilometer,
                fleetType: t as MonitoredObjectFleetType
            });
        });

        this.setState(state => ({
            actions: {
                ...state.actions,
                data: {
                    currentCosts: {
                        value: pricePerKmCostStructure.avgPricePerKm,
                        currency,
                        unit: CostUnit.PerKilometer
                    },
                    currentCostsFleet: pricePerKmCostStructure.avgPricePerKmOfFleetTypes
                        ?.map(c => ({
                            value: c.avgPricePerKm,
                            currency,
                            unit: CostUnit.PerKilometer,
                            fleetType: c.fleetType
                        }))
                        .sort((a, b) => (a.fleetType > b.fleetType ? 1 : -1)),
                    marketAverageFleet: marketAverageFleet,
                    lastUpdate: moment().toISOString()
                }
            },
            currency,
            costs: {
                ...state.costs!,
                loading: false,
                fixedCost: {
                    data: {
                        costs: pricePerKmCostStructure.fixedCostProfile as FixedCost
                    }
                },
                operationalCost: {
                    data: {
                        dateFrom: pricePerKmCostStructure.fixedCostProfile?.[0]?.dateFrom ?? moment().toString(),
                        // costs: pricePerKmCostStructure.fixedCostProfile?.[0]?.costs ?? defaultOperationalCosts,
                        costs: pricePerKmCostStructure.fixedCostProfile?.[0]?.costs,
                        // defaults: defaultOperationalCosts as OperationalCost,
                        defaultsFleet: defaultMarketOperationalCosts as OperationalCostFleet[]
                    }
                }
            },
            table: {
                ...state.table,
                originalData: pricePerKmCostStructure,
                data: pricePerKmCostStructure.mobjects.map(mo => ({ ...mo, selected: false })),
                loading: false
            }
        }));
    };

    private _onSearchInputChange = (text: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                search: {
                    text
                }
            }
        }));
        this._onSearchInputChangeDebounce(text);
    };

    private _onSearchInputChangeDebounce = debounce((text: string): void => {
        const filteredVehicles = search(text, ['registrationNumber'], this.state.vehicles).map(v => v.id);
        this.setState(state => ({
            table: {
                ...state.table,
                data: this.state.table.originalData.mobjects
                    .filter(d => filteredVehicles.includes(d.monitoredObjectId))
                    .map(d => ({
                        ...d,
                        selected:
                            state.table.data.find(td => td.selected && td.monitoredObjectId === d.monitoredObjectId) !==
                                undefined ?? false
                    }))
            }
        }));
    }, 500);

    private _onCostsClose = (): void => {
        this.setState(state => ({
            ...state,
            edit: undefined,
            showWarning: undefined
        }));
    };
    private _onCostEditChange = (): void => {
        if (this.state.table.data.filter(d => d.selected).length > 0) {
            this.setState(state => ({
                edit: 'operational',
                showWarning: true,
                costs: {
                    ...state.costs,
                    operationalCost: {
                        data: {
                            ...state.costs!.operationalCost!.data!,
                            costs: state.table.data.filter(d => d.selected)?.[0]
                                .operationalCostProfile as OperationalCost
                        }
                    }
                }
            }));
        }
    };
    private _onFixedCostEditChange = (): void => {
        this.setState({
            edit: 'fixed',
            showWarning: true
        });
    };

    private _onCheckboxAllChange = (checked: boolean): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                data: state.table.data.map(d => {
                    const vehicle = this.state.vehicles.find(v => v.id === d.monitoredObjectId);
                    return { ...d, selected: checked && !vehicle?.disabledAt };
                })
            }
        }));
    };

    private _onCheckboxChange = (id: number): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                data: state.table.data.map(d => (d.monitoredObjectId === id ? { ...d, selected: !d.selected } : d))
            }
        }));
    };

    private _onCostsSubmit = async (fixedCostFormData: FixedCost, operationalCostFormData: OperationalCost) => {
        this.setState(state => ({
            ...state,
            costs: { ...state.costs, loading: true },
            table: { ...state.table, loading: true }
        }));
        try {
            const vehicles = this.state.table.data.filter(d => d.selected).map(v => v.monitoredObjectId);
            if (fixedCostFormData) {
                await this._logic.statisticsCompanyProfile().companyOperationalCostCreate({
                    costs: fixedCostFormData,
                    dateFrom: moment().format(DATE_FORMAT)
                });
            }

            if (operationalCostFormData && this.state.edit === 'operational') {
                await this._logic
                    .statisticsCompanyProfile()
                    .monitoredObjectOperationalCostCreateMany(
                        this._operationalCostsPerFuelType(vehicles, operationalCostFormData)
                    );
            }

            const costs = await this._logic.statisticsCompanyProfile().computeCosts();
            this.setState(state => ({
                costs: {
                    ...state.costs,
                    currentCosts: {
                        currency: costs.currency,
                        unit: CostUnit.PerKilometer,
                        value: costs.value
                    }
                },
                edit: undefined,
                showWarning: undefined
            }));
            this._onActionsSetCosts();
        } catch (e) {
            if (fixedCostFormData) {
                message.error(this.props.t('CompanyProfileOperationalCosts.errors.create'));
            } else if (operationalCostFormData && this.state.edit === 'operational') {
                message.error(this.props.t('CompanyProfileOperationalCosts.errors.createMonitoredObject'));
            } else {
                message.error(this.props.t('common.error.loadDataError'));
            }

            this.setState(state => ({
                ...state,
                costs: { ...state.costs, loading: false },
                table: { ...state.table, loading: false }
            }));
        }
    };

    private _onActionsVehiclesTargetChange = (vehiclesTarget: VehiclesTarget): void => {
        const vehicles = this.state.actions.vehicles.map((v, i) => ({
            ...v,
            checked: vehiclesTarget !== VehiclesTarget.Single || i === 0
        }));
        this.setState(
            state => ({
                actions: {
                    ...state.actions,
                    vehicles,
                    vehiclesTarget
                }
            }),
            () => {
                this._onActionsSetCosts();
            }
        );
    };

    private _onActionsVehiclesChange = (vehiclesChecked: string[]): void => {
        this.setState(
            state => ({
                actions: {
                    ...state.actions,
                    vehicles: state.actions.vehicles.map(v => ({
                        ...v,
                        checked: vehiclesChecked.includes(v.code)
                    }))
                }
            }),
            () => {
                this._onActionsSetCosts();
            }
        );
    };

    private _onActionsDashboard = (): void => {
        this.props.history.push(RouteNames.STATISTICS_COMPANY_PROFILE_DASHBOARD);
    };

    private _onActionsTotalCostOfOwnership = (): void => {
        this.props.history.push(RouteNames.STATISTICS_COMPANY_PROFILE_TOTAL_COST_OF_OWNERSHIP);
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'track&trace';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };

    private _operationalCostsPerFuelType(
        vehicles: number[],
        costs: OperationalCost
    ): { monitoredObjectIds: number[]; operationalCostData: OperationalCost }[] {
        let operationCosts = [];

        const fuelVehicles = this.state.vehicles
            .filter(v => [EFuelType.Gasoline, EFuelType.Diesel].includes((v.fuelType as EFuelType) ?? EFuelType.Diesel))
            .map(v => v.id ?? 0)
            .filter(v => vehicles.includes(v ?? 0));
        if (fuelVehicles.length > 0) {
            operationCosts.push({
                monitoredObjectIds: fuelVehicles,
                operationalCostData: {
                    ...costs,
                    lngCng: {
                        ...costs.lngCng,
                        value: 0
                    },
                    electro: {
                        ...costs.electro,
                        value: 0
                    }
                }
            });
        }

        const electroVehicles = this.state.vehicles
            .filter(v => [EFuelType.Electro].includes((v.fuelType as EFuelType) ?? EFuelType.Diesel))
            .map(v => v.id ?? 0)
            .filter(v => vehicles.includes(v ?? 0));
        if (electroVehicles.length > 0) {
            operationCosts.push({
                monitoredObjectIds: electroVehicles,
                operationalCostData: {
                    ...costs,
                    fuel: {
                        ...costs.fuel,
                        value: 0
                    },
                    lngCng: {
                        ...costs.electro,
                        value: 0
                    }
                }
            });
        }

        const lngCngVehicles = this.state.vehicles
            .filter(v => [EFuelType.LngCng].includes((v.fuelType as EFuelType) ?? EFuelType.Diesel))
            .map(v => v.id ?? 0)
            .filter(v => vehicles.includes(v ?? 0));
        if (lngCngVehicles.length > 0) {
            operationCosts.push({
                monitoredObjectIds: lngCngVehicles,
                operationalCostData: {
                    ...costs,
                    fuel: {
                        ...costs.fuel,
                        value: 0
                    },
                    electro: {
                        ...costs.electro,
                        value: 0
                    }
                }
            });
        }

        const hybridVehicles = this.state.vehicles
            .filter(v => [EFuelType.Hybrid].includes((v.fuelType as EFuelType) ?? EFuelType.Diesel))
            .map(v => v.id ?? 0)
            .filter(v => vehicles.includes(v ?? 0));
        if (hybridVehicles.length > 0) {
            operationCosts.push({
                monitoredObjectIds: hybridVehicles,
                operationalCostData: {
                    ...costs,
                    lngCng: {
                        ...costs.electro,
                        value: 0
                    }
                }
            });
        }

        return operationCosts;
    }
}

export default withRouter(withTranslation()(CompanyProfileCostsModule));
