/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyContactPersonNested
 */
export interface ReadOnlyContactPersonNested {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    id?: string;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyContactPersonNested
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyContactPersonNested
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    cellPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    ssoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyContactPersonNested
     */
    username?: string | null;
}

export function ReadOnlyContactPersonNestedFromJSON(json: any): ReadOnlyContactPersonNested {
    return ReadOnlyContactPersonNestedFromJSONTyped(json, false);
}

export function ReadOnlyContactPersonNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyContactPersonNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'address': json['address'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function ReadOnlyContactPersonNestedToJSON(value?: ReadOnlyContactPersonNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': ClientToJSON(value.client),
        'address': value.address,
        'name': value.name,
        'surname': value.surname,
        'email': value.email,
        'cell_phone': value.cellPhone,
        'position': value.position,
        'sso_id': value.ssoId,
        'username': value.username,
    };
}


