import { WithLogic, withLogicContext } from 'App';
import { Loading } from 'common/components';
import Modal from 'common/components/Modal';
import { BorderCrossingFormModel } from 'common/forms/BorderCrossingForm';
import PlaceOfWorkCrossingForm from 'common/forms/PlaceOfWorkCrossingForm';
import { CustomPlace } from 'generated/backend-api/models';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {}

interface Props extends WithTranslation, WithLogic {
    visible: boolean;
    processing: boolean;
    customPlaces: CustomPlace[];
    onSubmit: (data: BorderCrossingFormModel) => void;
    onCancel: () => void;
}

class AllowancePlaceOfWorkCrossingEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}
    render() {
        return (
            <Modal
                width={400}
                destroyOnClose
                className="place-of-work-modal"
                onCancel={this.props.onCancel}
                footer={null}
                closable={false}
                title={<span>{this.props.t('Allowance.placeOfWorkCrossingForm.title')}</span>}
                visible={this.props.visible}
            >
                {this.props.processing ? (
                    <Loading maxHeight="200" />
                ) : (
                    <PlaceOfWorkCrossingForm
                        lang={this.props.logic.auth().user().lang}
                        placeOfWorkOpts={this.props.customPlaces.map(cp => ({
                            label: cp.name,
                            value: cp.id,
                            title: cp.address
                        }))}
                        onCancel={this.props.onCancel}
                        onSubmit={this._onSubmit}
                    />
                )}
            </Modal>
        );
    }

    private _onSubmit = (data: BorderCrossingFormModel) => {
        this.props.onSubmit(data);
    };
}

export default withLogicContext(withTranslation()(AllowancePlaceOfWorkCrossingEdit));
