import React, { Component } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Logic } from 'logic/logic';
import { roundToStep } from 'common/utils/averages';
import { RouteNames } from 'App';
import { BaseDriverBehaviorModel, DriverBehaviorTrendsModel } from 'common/model/statistics';
import { DriverScoreDataModel } from 'common/reports-widgets/DriverScoresWidget';
import { DriverBehaviorType } from 'modules/statistics/modules/driver-behavior/components/DriverBehaviorBar';
import { LayoutContent } from 'common/components/Layout/Content';
import CompanyProfileDashboard from '../../components/CompanyProfileDashboard';
import { Role } from 'logic/auth';
import { confDefault } from 'conf';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import qa from 'qa-selectors';
import { Button } from 'common/components';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
}

interface State {
    filter: DriverBehaviorType;
    date?: string;
    trucks?: {
        loading: boolean;
        driverScores?: {
            trend?: number;
            value?: number;
            currency?: string;
            data: { stars: number; percentage: number }[];
        };
        topDrivers?: {
            dataTrends?: DriverBehaviorTrendsModel[];
        };
        worstDrivers?: {
            dataTrends?: DriverBehaviorTrendsModel[];
        };
    };
    vehicles?: {
        loading: boolean;
        driverScores?: {
            trend?: number;
            value?: number;
            currency?: string;
            data: { stars: number; percentage: number }[];
        };
        topDrivers?: {
            data: BaseDriverBehaviorModel[];
        };
        worstDrivers?: {
            data: BaseDriverBehaviorModel[];
        };
    };
    roles: Role[];
    helper?: {
        content: string;
    };
}

class CompanyProfileDashboardModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const roles = this._logic.auth().roles();
        this.state = {
            filter: DriverBehaviorType.Trucks,
            date: moment.utc().startOf('month').toISOString(),
            trucks: {
                loading: true
            },
            vehicles: {
                loading: true
            },
            roles
        };
    }

    componentDidMount() {
        (window as any).app.CompanyProfile = this;
        this._setDashboard();
    }

    componentWillUnmount() {
        (window as any).app.CompanyProfile = undefined;
    }

    render() {
        const { t } = this.props;
        return (
            <LayoutContent
                className="company-profile"
                mainSizes={{ xs: 24, sm: 24, md: 19 }}
                extraSizes={[{ xs: 24, sm: 24, md: 5 }]}
                main={
                    <CompanyProfileDashboard
                        filter={this.state.filter}
                        date={this.state.date}
                        trucks={this.state.trucks}
                        vehicles={this.state.vehicles}
                        helper={this.state.helper}
                        onBarHelperClick={this._onBarHelperClick}
                        onFilterChange={this._onDashboardFilterChange}
                        onDateRangeChange={this._onDashboardDateRangeChange}
                        onDateNextChange={this._onDashboardDateNextChange}
                        onDatePrevChange={this._onDashboardDatePrevChange}
                        onTrucksWidgetClick={this._onTrucksWidgetClick}
                        onVehiclesWidgetClick={this._onVehiclesWidgetClick}
                        onExpensesWidgetClick={this._onExpensesWidgetClick}
                        onHelperClose={this._onHelperClose}
                    />
                }
                extra={[
                    <div className="company-profile-actions">
                        <div className="company-profile-actions-list">
                            {this.state.roles.includes(Role.CP_R) && (
                                <Button
                                    onClick={() => this._onSetCosts?.()}
                                    type="primary"
                                    block
                                    qa={qa.companyProfile.dashboard.btnCostCalculator}
                                >
                                    {t('CompanyProfile.costsCalculator')}
                                </Button>
                            )}
                        </div>
                    </div>
                ]}
            />
        );
    }

    private _onSetCosts = (): void => {
        this.props.history.push(RouteNames.STATISTICS_COMPANY_PROFILE_COSTS);
    };

    private _setDashboard = (): void => {
        function sum<T extends BaseDriverBehaviorModel, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((sum, num: any) => sum + num, 0);
        }

        function sumScore<T extends { score: number }, K extends keyof T>(
            values: T[],
            key: T[K] extends number ? K : never
        ): number {
            return values.map(v => v[key]).reduce((sum, num: any) => sum + num, 0);
        }

        this.props.logic
            .driverBehavior()
            .vehicles()
            .data.subscribe(data => {
                let driverScoresData: DriverScoreDataModel[] = [];
                [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0.5, 0].forEach(i => {
                    if (data.filter(d => roundToStep(d.score, 0.5) === i).length) {
                        driverScoresData.push({
                            stars: i,
                            percentage: data.filter(d => roundToStep(d.score, 0.5) === i).length
                        });
                    }
                });

                this.setState(state => ({
                    ...state,
                    vehicles: {
                        loading: false,
                        driverScores: {
                            value: data?.length > 0 ? sum(data, 'score') / data.length : undefined,
                            data: driverScoresData
                        },
                        topDrivers: {
                            data: data
                                .sort((a, b) => a.score - b.score)
                                .reverse()
                                .slice(0, 3)
                        },
                        worstDrivers: {
                            data: data.sort((a, b) => a.score - b.score).slice(0, 3)
                        }
                    }
                }));
            });

        this.props.logic
            .driverBehavior()
            .trucks()
            .dataTrends.subscribe(data => {
                let driverScoresData: DriverScoreDataModel[] = [];
                [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0.5, 0].forEach(i => {
                    if (data.filter(d => roundToStep(((d.scores[0].score ?? 0) - 4.75) / 20, 0.5) === i).length) {
                        driverScoresData.push({
                            stars: i,
                            percentage: data.filter(d => roundToStep(((d.scores[0].score ?? 0) - 4.75) / 20, 0.5) === i)
                                .length
                        });
                    }
                });

                const driverScoresDataMap = data.map(d => ({
                    score: d.scores[0].score ?? 0
                }));
                this.setState(state => ({
                    ...state,
                    trucks: {
                        loading: false,
                        driverScores: {
                            value:
                                data?.length > 0
                                    ? sumScore(driverScoresDataMap, 'score') / driverScoresDataMap.length
                                    : undefined,
                            data: driverScoresData
                        },
                        topDrivers: {
                            dataTrends: data
                                .sort((a, b) => (a.scores[0].score ?? 0) - (b.scores[0].score ?? 0))
                                .reverse()
                                .slice(0, 3)
                        },
                        worstDrivers: {
                            dataTrends: data
                                .sort((a, b) => (a.scores[0].score ?? 0) - (b.scores[0].score ?? 0))
                                .slice(0, 3)
                        }
                    }
                }));
            });

        this.props.logic
            .driverBehavior()
            .vehicles()
            .loading.subscribe(loading => {
                this.setState(state => ({
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        loading: loading
                    }
                }));
            });

        this.props.logic
            .driverBehavior()
            .trucks()
            .loading.subscribe(loading => {
                this.setState(state => ({
                    ...state,
                    trucks: {
                        ...state.trucks,
                        loading: loading
                    }
                }));
            });

        if (this.state.date) {
            if (this.state.filter === DriverBehaviorType.Trucks) {
                this.props.logic.driverBehavior().trucks().loadTrendsData(this.state.date);
            } else {
                this.props.logic.driverBehavior().vehicles().loadData(this.state.date);
            }
        }
    };

    private _onDashboardDateRangeChange = (date: string) => {
        this.setState(
            state => ({
                ...state,
                date,
                vehicles: {
                    ...state.vehicles,
                    loading: true
                },
                trucks: {
                    ...state.trucks,
                    loading: true
                }
            }),
            () => {
                if (this.state.date) {
                    if (this.state.filter === DriverBehaviorType.Trucks) {
                        this.props.logic.driverBehavior().trucks().loadTrendsData(this.state.date);
                    } else {
                        this.props.logic.driverBehavior().vehicles().loadData(this.state.date);
                    }
                }
            }
        );
    };

    private _onDashboardDateNextChange = () => {
        const date = moment(this.state.date).add(1, 'months').toISOString();
        this._onDashboardDateRangeChange(date);
    };

    private _onDashboardDatePrevChange = () => {
        const date = moment(this.state.date).add(-1, 'months').toISOString();
        this._onDashboardDateRangeChange(date);
    };

    private _onDashboardFilterChange = (filter: DriverBehaviorType) => {
        this.setState(
            state => ({
                ...state,
                filter,
                vehicles: {
                    ...state.vehicles,
                    loading: true
                },
                trucks: {
                    ...state.trucks,
                    loading: true
                }
            }),
            () => {
                if (this.state.date) {
                    if (this.state.filter === DriverBehaviorType.Trucks) {
                        this.props.logic.driverBehavior().trucks().loadTrendsData(this.state.date);
                    } else {
                        this.props.logic.driverBehavior().vehicles().loadData(this.state.date);
                    }
                }
            }
        );
    };

    private _onTrucksWidgetClick = () => {
        this._logic.driverBehaviorCoach().trucks().saveDateToSettings(this.state.date);
        this.props.history.push({
            pathname: RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST
        });
    };

    private _onVehiclesWidgetClick = () => {
        this._logic.driverBehaviorCoach().trucks().saveDateToSettings(this.state.date);
        this.props.history.push({
            pathname: RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_LIST
        });
    };
    private _onExpensesWidgetClick = () => {
        this.props.history.push({
            pathname: RouteNames.STATISTICS_EXPENSES
        });
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'track&trace';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(CompanyProfileDashboardModule));
