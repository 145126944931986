import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { RouteNames, rbac } from 'App';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';

import CustomerAccessModule from './modules/customer-access';
import CustomerApiModule from './modules/customer-api';
import OtherSystemsModule from './modules/other-systems';

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
}

interface State {}

class SystemConnectionsModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const roles = this.props.logic.auth().roles();
        return (
            <Switch>
                <Route
                    exact
                    path={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API}
                    render={rbac(roles, [Role.CA_R], <CustomerApiModule />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS}
                    render={rbac(roles, [Role.CAC_R], <CustomerAccessModule />)}
                />
                <Route
                    exact
                    path={RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS}
                    render={rbac(roles, [Role.OAC_R, Role.PUESC], <OtherSystemsModule />)}
                />
                <Redirect
                    to={
                        roles.includes(Role.CA_R)
                            ? RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API
                            : roles.includes(Role.CAC_R)
                            ? RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS
                            : RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS
                    }
                />
            </Switch>
        );
    }
}

export default withRouter(withTranslation()(SystemConnectionsModule));
