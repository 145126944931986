import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import moment, { Moment } from 'moment';

import { CheckboxGroup, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import TableFilter from 'common/components/TableFilter';
import { Button, DatePicker } from 'common/components';
import qa from 'qa-selectors';
import { closeCircle } from 'resources/images/common';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    vehiclesOpts: CheckboxGroupOpts;
    trailersOpts: CheckboxGroupOpts;
    driversOpts: CheckboxGroupOpts;
    taskTypeOpts: CheckboxGroupOpts;
    intervalOpts: CheckboxGroupOpts;
    stateOpts: CheckboxGroupOpts;
    dueDateGte?: string;
    dueDateLte?: string;
    vehiclesChecked: string[];
    trailersChecked: string[];
    driversChecked: string[];
    taskTypeChecked: string[];
    intervalChecked: string[];
    stateChecked: string[];
    onConfirm?: (
        vehiclesChecked: string[],
        trailersChecked: string[],
        driversChecked: string[],
        taskTypeChecked: string[],
        intervalChecked: string[],
        stateChecked: string[],
        dueDateGte?: string,
        dueDateLte?: string
    ) => void;
    onCancel?: () => void;
}

interface State {
    vehiclesChecked: string[];
    trailersChecked: string[];
    driversChecked: string[];
    taskTypeChecked: string[];
    intervalChecked: string[];
    stateChecked: string[];
    dueDateGte?: string;
    dueDateLte?: string;
}

class MaintenanceFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            vehiclesChecked: props.vehiclesChecked,
            trailersChecked: props.trailersChecked,
            driversChecked: props.driversChecked,
            taskTypeChecked: props.taskTypeChecked,
            intervalChecked: props.intervalChecked,
            stateChecked: props.stateChecked,
            dueDateGte: props.dueDateGte,
            dueDateLte: props.dueDateLte
        };
    }

    render() {
        const { t } = this.props;

        return (
            <TableFilter
                className="maintenance-filter"
                qa={qa.maintenance.filter.section}
                heading={t('Maintenance.filterName')}
                body={
                    <Row gutter={[24, 24]}>
                        {this.props.vehiclesOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.vehicles')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allVehicles')}
                                    options={this.props.vehiclesOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.vehiclesChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxVehiclesChange}
                                />
                            </Col>
                        )}
                        {this.props.trailersOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.trailers')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allTrailers')}
                                    options={this.props.trailersOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.trailersChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxTrailersChange}
                                />
                            </Col>
                        )}
                        {this.props.driversOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.drivers')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allDrivers')}
                                    options={this.props.driversOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.driversChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxDriversChange}
                                />
                            </Col>
                        )}
                        {this.props.taskTypeOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('Maintenance.task.taskType')}
                                    scrolled={true}
                                    labelCheckAll={t('common.all')}
                                    options={this.props.taskTypeOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.taskTypeChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxTaskTypeChange}
                                />
                            </Col>
                        )}
                        {this.props.intervalOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.interval')}
                                    scrolled={true}
                                    small={true}
                                    labelCheckAll={t('common.all')}
                                    options={this.props.intervalOpts.map(v => ({
                                        code: v.code,
                                        label: t(`Maintenance.filter.${v.code}`),
                                        checked: this.state.intervalChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxIntervalChange}
                                />
                            </Col>
                        )}
                        {this.props.stateOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.status')}
                                    scrolled={true}
                                    small={true}
                                    labelCheckAll={t('common.all')}
                                    options={this.props.stateOpts.map(v => ({
                                        code: v.code,
                                        label: t(`Maintenance.dueFilter.${v.code}`),
                                        checked: this.state.stateChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxStateChange}
                                />
                            </Col>
                        )}
                        <Col>
                            <div className="date-filter">
                                <label className="group-title">{t('common.dateFrom')}</label>
                                <Row justify="space-between">
                                    <Col flex="auto">
                                        <DatePicker.DateTimePicker
                                            size="large"
                                            picker="date"
                                            defaultValue={
                                                this.props.dueDateGte ? moment(this.props.dueDateGte) : undefined
                                            }
                                            onChange={this.onDateFromChange}
                                            trigger={
                                                <div className="ant-input">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            {this.state.dueDateGte
                                                                ? (moment(this.state.dueDateGte).format('L') as string)
                                                                : ''}
                                                        </Col>
                                                        <Col>
                                                            <i className="fa fa-fw fa-calendar" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col flex="40px">
                                        {this.state.dueDateGte && (
                                            <Button
                                                type="text"
                                                className="maintenance-filter-datepicker-clear"
                                                icon={<img src={closeCircle} alt="clear" />}
                                                onClick={this._onDueDateGteClearClick}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <div className="date-filter">
                                <label className="group-title">{t('common.dateTo')}</label>
                                <Row justify="space-between">
                                    <Col flex="auto">
                                        <DatePicker.DateTimePicker
                                            size="large"
                                            picker="date"
                                            defaultValue={
                                                this.props.dueDateLte ? moment(this.props.dueDateLte) : undefined
                                            }
                                            onChange={this.onDateToChange}
                                            trigger={
                                                <div className="ant-input">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            {this.state.dueDateLte
                                                                ? (moment(this.state.dueDateLte).format('L') as string)
                                                                : ''}
                                                        </Col>
                                                        <Col>
                                                            <i className="fa fa-fw fa-calendar" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col flex="40px">
                                        {this.state.dueDateLte && (
                                            <Button
                                                type="text"
                                                className="maintenance-filter-datepicker-clear"
                                                icon={<img src={closeCircle} alt="clear" />}
                                                onClick={this._onDueDateLteClearClick}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                }
                cancelButton={{
                    onClick: this._onCancel,
                    qa: qa.maintenance.filter.btnCancel
                }}
                submitButton={{
                    onClick: this._onConfirmClick,
                    qa: qa.maintenance.filter.btnSubmit,
                    disabled: this.props.demoMode
                }}
                resetButton={{
                    onClick: this._onResetClick
                }}
            />
        );
    }

    onDateFromChange = (value: Moment | null) => {
        if (value) {
            this.setState({
                dueDateGte: value.startOf('day').toISOString()
            });
        }
    };

    onDateToChange = (value: Moment | null) => {
        if (value) {
            this.setState({
                dueDateLte: value.endOf('day').toISOString()
            });
        }
    };

    _onDueDateGteClearClick = () => {
        this.setState({
            dueDateGte: undefined
        });
    };

    _onDueDateLteClearClick = () => {
        this.setState({
            dueDateLte: undefined
        });
    };

    private _onResetClick = (): void => {
        const defaults = confDefault.settings.statistics.maintenance.filter;
        this.setState({
            vehiclesChecked: defaults.vehicles,
            trailersChecked: defaults.trailers,
            driversChecked: defaults.drivers,
            taskTypeChecked: defaults.taskTypes,
            intervalChecked: defaults.intervals,
            stateChecked: defaults.status,
            dueDateGte: defaults.dueDateGte,
            dueDateLte: defaults.dueDateLte
        });
    };

    private _onConfirmClick = (): void => {
        this.props.onConfirm?.(
            this.state.vehiclesChecked,
            this.state.trailersChecked,
            this.state.driversChecked,
            this.state.taskTypeChecked,
            this.state.intervalChecked,
            this.state.stateChecked,
            this.state.dueDateGte ?? undefined,
            this.state.dueDateLte ?? undefined
        );
    };

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onCheckboxVehiclesChange = (vehiclesChecked: string[]) => {
        this.setState({
            vehiclesChecked
        });
    };

    private _onCheckboxTrailersChange = (trailersChecked: string[]) => {
        this.setState({
            trailersChecked
        });
    };

    private _onCheckboxDriversChange = (driversChecked: string[]) => {
        this.setState({
            driversChecked
        });
    };

    private _onCheckboxTaskTypeChange = (taskTypeChecked: string[]) => {
        this.setState({
            taskTypeChecked
        });
    };

    private _onCheckboxIntervalChange = (intervalChecked: string[]) => {
        this.setState({
            intervalChecked
        });
    };

    private _onCheckboxStateChange = (statusChecked: string[]) => {
        this.setState({
            stateChecked: statusChecked
        });
    };
}

export default withTranslation()(MaintenanceFilter);
