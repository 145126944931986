import { Logic } from '../logic';
import { PaginatedResponse } from 'common/model/pagination';
import moment from 'moment';
import { BorderCrossingEntry } from 'generated/backend-api';
import { BorderCrossingModel } from 'modules/statistics/modules/border-crossing/StatisticsBorderCrossingModule';
import { DATE_FORMAT, DEFAULT_PAGE_LIMIT_SHORT, DEFAULT_PAGE_OFFSET } from 'domain-constants';

const initData: PaginatedResponse<BorderCrossingEntry[]> = {
    data: [],
    total: 0,
    limit: 0,
    offset: 0
};
export class StatisticsBorderCrossingLogic {
    private _data: PaginatedResponse<BorderCrossingEntry[]>;
    private _onDataChange?: (data: PaginatedResponse<BorderCrossingModel[]>) => void;

    constructor(private _logic: Logic) {
        this._data = initData;
    }

    onDataChange(cb: (data: PaginatedResponse<BorderCrossingModel[]>) => void) {
        this._onDataChange = cb;
    }

    destroy() {}

    async getBorderCrossingList(
        monitoredObjectId?: string,
        driverId?: string,
        dateFrom?: string,
        dateTo?: string,
        countries?: Array<string>,
        limit = DEFAULT_PAGE_LIMIT_SHORT,
        offset = DEFAULT_PAGE_OFFSET
    ): Promise<PaginatedResponse<BorderCrossingModel[]>> {
        try {
            if (this._logic.demo().isActive) {
                const borderCrossing: BorderCrossingEntry[] = this._logic
                    .demo()
                    .data.borderCrossing.filter(b =>
                        monitoredObjectId ? b?.monitoredObjectId === monitoredObjectId : b
                    )
                    .filter(b => (driverId ? String(b?.driverId) === driverId : b));
                this._data = { data: borderCrossing, total: borderCrossing.length, limit, offset };
            } else {
                const response = await this._logic.api().borderCrossingApi.getBorderCrossingsApiV1BordercrossingsGet({
                    // limit, // Todo Fix IT Oli
                    // offset, // Todo Fix IT Oli
                    monitoredObjectId: monitoredObjectId ? monitoredObjectId : undefined,
                    driverId: driverId ? Number(driverId) : undefined,
                    dateFrom: moment.utc(dateFrom, DATE_FORMAT).startOf('day').toDate(),
                    dateTo: moment.utc(dateTo, DATE_FORMAT).endOf('day').toDate(),
                    countries: countries && countries?.length > 0 ? countries : undefined
                });
                this._data = {
                    data: response.filter(res => res),
                    total: response.length,
                    limit,
                    offset
                };
            }
            return {
                data: this._data.data.map(res => this._toBorderCrossing(res)),
                total: this._data.total,
                limit: this._data.limit,
                offset: this._data.offset
            };
        } catch (err) {
            console.log('Border crossings client GET err:', err);
            throw err;
        }
    }
    private _toBorderCrossing(data: BorderCrossingEntry | undefined): BorderCrossingModel {
        return {
            id: data?.start?.toISOString() || '',
            country: data?.countryEntry,
            driver: String(data?.driverId),
            vehicle: data?.monitoredObjectId,
            entryDate: data?.start
                ? moment(moment.utc(moment(data.start).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : undefined,
            exitDate: data?.end
                ? moment(moment.utc(moment(data.end).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : undefined,
            distance: data?.distance ?? 0,
            duration: data?.duration ?? 0,
            entryLocation: data?.countryEntry,
            addressStructuredEntry: data?.addressEntry,
            addressStructuredExit: data?.addressExit,
            exitLocation: data?.countryExit
        };
    }
}
