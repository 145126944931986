/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
    RouteCostsDetails,
    RouteCostsDetailsFromJSON,
    RouteCostsDetailsFromJSONTyped,
    RouteCostsDetailsToJSON,
    TransportCrossingPoint,
    TransportCrossingPointFromJSON,
    TransportCrossingPointFromJSONTyped,
    TransportCrossingPointToJSON,
    TransportEventRule,
    TransportEventRuleFromJSON,
    TransportEventRuleFromJSONTyped,
    TransportEventRuleToJSON,
    TransportTemplateTask,
    TransportTemplateTaskFromJSON,
    TransportTemplateTaskFromJSONTyped,
    TransportTemplateTaskToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransportTemplateTransportPlace
 */
export interface TransportTemplateTransportPlace {
    /**
     * 
     * @type {string}
     * @memberof TransportTemplateTransportPlace
     */
    name: string;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof TransportTemplateTransportPlace
     */
    addressStructured?: Array<AddressStructured>;
    /**
     * 
     * @type {object}
     * @memberof TransportTemplateTransportPlace
     */
    center?: object;
    /**
     * 
     * @type {object}
     * @memberof TransportTemplateTransportPlace
     */
    polygon?: object;
    /**
     * 
     * @type {Array<TransportCrossingPoint>}
     * @memberof TransportTemplateTransportPlace
     */
    crossingPoints?: Array<TransportCrossingPoint>;
    /**
     * 
     * @type {number}
     * @memberof TransportTemplateTransportPlace
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof TransportTemplateTransportPlace
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TransportTemplateTransportPlace
     */
    durationBuffer?: number;
    /**
     * 
     * @type {string}
     * @memberof TransportTemplateTransportPlace
     */
    route?: string;
    /**
     * 
     * @type {RouteCostsDetails}
     * @memberof TransportTemplateTransportPlace
     */
    routeCosts?: RouteCostsDetails;
    /**
     * 
     * @type {Array<TransportEventRule>}
     * @memberof TransportTemplateTransportPlace
     */
    eventRules?: Array<TransportEventRule>;
    /**
     * 
     * @type {Array<TransportTemplateTask>}
     * @memberof TransportTemplateTransportPlace
     */
    tasks?: Array<TransportTemplateTask>;
}

export function TransportTemplateTransportPlaceFromJSON(json: any): TransportTemplateTransportPlace {
    return TransportTemplateTransportPlaceFromJSONTyped(json, false);
}

export function TransportTemplateTransportPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportTemplateTransportPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON)),
        'center': !exists(json, 'center') ? undefined : json['center'],
        'polygon': !exists(json, 'polygon') ? undefined : json['polygon'],
        'crossingPoints': !exists(json, 'crossingPoints') ? undefined : ((json['crossingPoints'] as Array<any>).map(TransportCrossingPointFromJSON)),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationBuffer': !exists(json, 'duration_buffer') ? undefined : json['duration_buffer'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'routeCosts': !exists(json, 'route_costs') ? undefined : RouteCostsDetailsFromJSON(json['route_costs']),
        'eventRules': !exists(json, 'eventRules') ? undefined : ((json['eventRules'] as Array<any>).map(TransportEventRuleFromJSON)),
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TransportTemplateTaskFromJSON)),
    };
}

export function TransportTemplateTransportPlaceToJSON(value?: TransportTemplateTransportPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
        'center': value.center,
        'polygon': value.polygon,
        'crossingPoints': value.crossingPoints === undefined ? undefined : ((value.crossingPoints as Array<any>).map(TransportCrossingPointToJSON)),
        'distance': value.distance,
        'duration': value.duration,
        'duration_buffer': value.durationBuffer,
        'route': value.route,
        'route_costs': RouteCostsDetailsToJSON(value.routeCosts),
        'eventRules': value.eventRules === undefined ? undefined : ((value.eventRules as Array<any>).map(TransportEventRuleToJSON)),
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TransportTemplateTaskToJSON)),
    };
}


