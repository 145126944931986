import { Col, Row } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import FilterButton from 'common/components/FilterButton';

interface Props extends WithTranslation {
    demoMode?: boolean;
    extended: boolean;
    selected: {
        overdue: boolean;
        dueSoon: boolean;
        active: boolean;
    };
    counts: {
        overdue?: number;
        dueSoon?: number;
        active?: number;
    };
    onOverdueClick?: (selected: boolean) => void;
    onDueSoonClick?: (selected: boolean) => void;
    onActiveClick?: (selected: boolean) => void;
}

function MaintenanceDueFilter(props: Props) {
    return (
        <Row
            gutter={12}
            className={cn('maintenance-due-filter', {
                extended: props.extended
            })}
            align="middle"
        >
            {props.extended &&
                props.counts.active !== undefined &&
                props.counts.dueSoon !== undefined &&
                props.counts.overdue !== undefined && <Col>{props.t('Maintenance.dueFilter.tasksByDue')}</Col>}

            {props.counts.overdue !== undefined ? (
                <Col>
                    <FilterButton
                        defaultSelected={props.selected.overdue}
                        label={props.t('Maintenance.dueFilter.overdue')}
                        className={'maintenance-due-filter-overdue'}
                        onButtonClick={props.onOverdueClick}
                        count={props.counts.overdue}
                        extended={props.extended}
                        disabled={props.demoMode}
                    />
                </Col>
            ) : (
                <></>
            )}

            {props.counts.dueSoon !== undefined ? (
                <Col>
                    <FilterButton
                        defaultSelected={props.selected.dueSoon}
                        label={props.t('Maintenance.dueFilter.duesoon')}
                        className={'maintenance-due-filter-due-soon'}
                        onButtonClick={props.onDueSoonClick}
                        count={props.counts.dueSoon}
                        extended={props.extended}
                        disabled={props.demoMode}
                    />
                </Col>
            ) : (
                <></>
            )}
            {props.counts.active !== undefined ? (
                <Col>
                    <FilterButton
                        defaultSelected={props.selected.active}
                        label={props.t('Maintenance.dueFilter.active')}
                        className={'maintenance-due-filter-active'}
                        onButtonClick={props.onActiveClick}
                        count={props.counts.active}
                        extended={props.extended}
                        disabled={props.demoMode}
                    />
                </Col>
            ) : (
                <></>
            )}
        </Row>
    );
}

export default withTranslation()(MaintenanceDueFilter);
