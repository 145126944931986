import { ReactNode } from 'react';
import { Progress } from 'antd';
import { ProgressProps } from 'antd/lib/progress';
import cn from 'classnames';

interface Props extends ProgressProps {
    children?: ReactNode;
    color?: 'yellow' | 'green' | 'blue';
}

export default function EnhancedProgress({ color, ...props }: Props) {
    return (
        <Progress
            className={cn({
                'ant-progress-blue': color === 'blue',
                'ant-progress-yellow': color === 'yellow',
                'ant-progress-green': color === 'green'
            })}
            {...props}
        />
    );
}
