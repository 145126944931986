/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserContact
 */
export interface UserContact {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserContact
     */
    emails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserContact
     */
    phoneNumbers?: Array<string>;
}

export function UserContactFromJSON(json: any): UserContact {
    return UserContactFromJSONTyped(json, false);
}

export function UserContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
        'phoneNumbers': !exists(json, 'phone_numbers') ? undefined : json['phone_numbers'],
    };
}

export function UserContactToJSON(value?: UserContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': value.emails,
        'phone_numbers': value.phoneNumbers,
    };
}


