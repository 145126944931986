import cn from 'classnames';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PartnerVehicleModel } from 'logic/partner/logic/partner-vehicles';
import {
    VehiclesForm,
    defaultValidationErrors,
    licensePlateValidator,
    fuelTankValidator,
    formValidator,
    companyValidator
} from '../VehiclesDetail';
import { ReadOnlyMonitoredObjectType } from 'generated/new-main';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { debounce } from 'debounce';
import { SelectValue } from 'antd/lib/select';
import { Select } from 'common/components/Form';
import { Row, Col } from 'antd';
import { Button } from 'common/components';
import { searched } from 'common/utils/search';

interface Props extends WithTranslation {
    createLoading?: boolean;
    model: PartnerVehicleModel;
    vehicleTypes?: ReadOnlyMonitoredObjectType[];
    clients?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    onClientSearch?: (text: string) => void;
    onCancel?: () => void;
    onSubmit?: (model: PartnerVehicleModel) => void;
}

interface State {
    form: VehiclesForm;
}

class VehiclesCreate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            form: {
                ...defaultValidationErrors,
                id: props.model.id,
                licensePlate: props.model.licensePlate,
                // commissionedDate: props.model.commissionedDate,
                odometer: String(props.model.odometer),
                brand: props.model.brand,
                typeId: props.model.typeId,
                fuelTank: props.model.fuelTank,
                cngTank: props.model.cngTank ? String(props.model.fuelTank) : '',
                company: props.selectedCompany?.id,
                disabledAt: props.model.disabledAt
            }
        };
    }

    componentDidUpdate() {
        if (!this.state.form.typeId && this.props.vehicleTypes?.[0]?.id) {
            this.setState(state => ({
                form: { ...state.form, typeId: this.props.vehicleTypes?.[0]?.id! }
            }));
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="t-modal">
                <form className="partner-modal-form t-modal-content" onSubmit={this._onSubmit}>
                    <h1>{t('PartnerVehicles.addNewVehicle')}</h1>
                    <div className="t-bar-block">
                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('PartnerVehicles.licensePlate')}>
                                {t('PartnerVehicles.licensePlate')}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.licensePlate,
                                        success: !this.state.form.licensePlateErr && !!this.state.form.licensePlate
                                    })}
                                    onChange={this._onLicensePlateChange}
                                    value={this.state.form.licensePlate}
                                />
                                {this.state.form.licensePlateErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.licensePlateErr)}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('Partner.brand')}>
                                {t('Partner.brand')}
                            </div>
                            <div className="t-half">
                                <input
                                    className="t-input t-padding-small"
                                    onChange={this._onBrandChange}
                                    value={this.state.form.brand}
                                />
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.type')}>
                                {t('common.type')}
                            </div>
                            <div className="t-half">
                                <Select
                                    showSearch
                                    placeholder={t('common.select')}
                                    value={this.state.form?.typeId ? String(this.state.form?.typeId) : undefined}
                                    filterOption={(input, option) => searched(input, String(option?.children))}
                                    size="middle"
                                    options={this.props.vehicleTypes?.map(v => ({
                                        value: String(v.id),
                                        label: v.label
                                    }))}
                                    onChange={this._onTypeChange}
                                />
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('common.type')}>
                                {t('common.company')}
                            </div>
                            <div className="t-half">
                                <Select
                                    showSearch
                                    placeholder={t('common.select')}
                                    value={this.props.selectedCompany?.id ?? undefined}
                                    filterOption={false}
                                    size="middle"
                                    options={this.props.clients?.map(v => ({ value: v.id, label: v.label }))}
                                    onChange={this._onClientChange}
                                    onSearch={this._onClientSearch}
                                />
                                {this.state.form.companyErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.companyErr)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="t-row t-row-padding">
                            <div className="t-half t-padding-small" title={t('PartnerVehicles.fuelTank')}>
                                {`${t('PartnerVehicles.fuelTank')} ( ${t('FuelConsumptionTable.liters')} )`}
                            </div>
                            <div className="t-half">
                                <input
                                    className={cn('t-input t-padding-small', {
                                        error: this.state.form.fuelTankErr,
                                        success: !this.state.form.fuelTankErr && !!this.state.form.fuelTank
                                    })}
                                    onChange={this._onFuelTankChange}
                                    value={this.state.form.fuelTank}
                                />
                                {this.state.form.fuelTankErr && (
                                    <span className="t-bold t-text-danger">
                                        {t('validator.' + this.state.form.fuelTankErr)}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <footer>
                        <Row justify="end" gutter={10}>
                            <Col>
                                <Button type="default" onClick={this._onCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button loading={this.props.createLoading} type="primary" htmlType="submit">
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                    </footer>
                </form>
            </div>
        );
    }

    private _onLicensePlateChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = licensePlateValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                licensePlate: value,
                licensePlateErr: validateRes.err as string
            }
        }));
    };

    private _onBrandChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        this.setState(state => ({
            form: {
                ...state.form,
                brand: value
            }
        }));
    };

    private _onTypeChange = (value: SelectValue) => {
        this.setState(state => ({
            form: {
                ...state.form!,
                typeId: Number(value)
            }
        }));
    };

    private _onClientChange = (value: SelectValue) => {
        const validateRes = companyValidator.validate(String(value));
        this.setState(state => ({
            form: {
                ...state.form!,
                company: String(value),
                companyErr: validateRes.err as string
            }
        }));
    };

    private _onClientSearch = debounce((text: string) => {
        this.props.onClientSearch?.(text);
    }, 4e2);

    private _onFuelTankChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const validateRes = fuelTankValidator.validate(value);
        this.setState(state => ({
            form: {
                ...state.form,
                fuelTank: Number(value),
                fuelTankErr: validateRes.err as string
            }
        }));
    };

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const validationRes = formValidator.validate({
            company: this.state.form.company ?? '',
            licensePlate: this.state.form.licensePlate,
            fuelTank: String(this.state.form.fuelTank)
        });

        if (validationRes.valid) {
            const modelCreate: PartnerVehicleModel = {
                id: '',
                licensePlate: this.state.form.licensePlate,
                odometer: Number(this.state.form.odometer),
                brand: this.state.form.brand,
                typeId: this.state.form.typeId,
                fuelTank: Number(this.state.form.fuelTank),
                company: this.state.form.company
            };
            this.props.onSubmit?.(modelCreate);
        } else {
            this.setState(state => ({
                form: {
                    ...state.form!,
                    companyErr: validationRes.err?.company ?? '',
                    licensePlateErr: validationRes.err?.licensePlate ?? '',
                    fuelTankErr: validationRes.err?.fuelTank ?? ''
                }
            }));
        }
    };
}

export default withTranslation()(VehiclesCreate);
