import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';
import { RouteNames, rbac } from 'App';

import DriverBehaviorTrucks from './modules/Trucks/DriverBehaviorTrucksModule';
import DriverBehaviorVehicles from './modules/Vehicles/DriverBehaviorVehiclesModule';
import { Loading } from 'common/components';
import { ReadOnlyClientFleetTypeEnum } from 'generated/new-main';

interface Props {
    logic: Logic;
}

interface State {
    selectedRoute?: RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS | RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES;
}

export enum TrendScoreValues {
    driveScore = 'driveScore',
    ecoScore = 'ecoScore',
    wearTearScore = 'wearTearScore'
}

export default class DriverBehaviorModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {};
    }

    async componentDidMount() {
        const fleetType = this.props.logic.auth().newEWClient()?.fleetType ?? ReadOnlyClientFleetTypeEnum.Mixed;
        this.setState({
            selectedRoute:
                fleetType === ReadOnlyClientFleetTypeEnum.LightVehicles
                    ? RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES
                    : RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS
        });
    }

    render() {
        const roles = this._logic.auth().roles();
        return (
            <Switch>
                <Route
                    path={RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS}
                    render={rbac(roles, [Role.DBH_R], <DriverBehaviorTrucks logic={this._logic} />)}
                />
                <Route
                    path={RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES}
                    render={rbac(roles, [Role.DBH_R], <DriverBehaviorVehicles logic={this._logic} />)}
                />
                {this.state.selectedRoute ? (
                    <Redirect to={this.state.selectedRoute} />
                ) : (
                    <div className="page driver-behavior-page">
                        <Loading />
                    </div>
                )}
            </Switch>
        );
    }
}
