import { useState } from 'react';
import cn from 'classnames';
import { Moment } from 'moment';
import moment from 'moment';
import { Row, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    SortableElement,
    SortableContainer,
    SortEvent,
    SortEventWithTag,
    SortEndHandler,
    SortEnd
} from 'react-sortable-hoc';
import { Button, DatePicker } from 'common/components';

import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import * as CommonIcons from 'resources/images/common';
import qa from 'qa-selectors';
import * as PlannerIcons from 'resources/images/planner';
import { WithLogic, withLogicContext } from 'App';
import { observer } from 'mobx-react';
import { PuescWarning } from '../PuescWarning/PuescWarning';
import { TransportPlace } from 'generated/backend-api/models';
import { latLngFromGeoJsonPointType } from 'common/utils/geo-utils';
import { Switch } from 'common/components';
import numeral from 'numeral';

interface Props extends WithLogic {
    sorting: boolean;
    disabled?: boolean;
    routeLoading?: boolean;
    onSortEnd?: SortEndHandler;
    onDeleteClick?: (id: string) => void;
    onAddNewTask?: (placeId?: string) => void;
    onEditTask?: (placeId: string, taskId: string) => void;
    demoMode?: boolean;
    isPuescTransportPossible?: boolean;
    onPuescChange?: (active: boolean) => void;
    puescActive?: boolean;
}

const PlacesConfigurator = SortableContainer(
    observer((props: Props) => {
        const places = props.logic.plannerLogic().transport.places ?? [];
        const { t } = useTranslation();
        const totalDistance = places.reduce((a, c) => a + (c.distance ?? 0), 0);

        function selectPlaceBeforeRta(index: number) {
            return (
                (places[index - 1] &&
                    places[index - 1].rta &&
                    moment(places[index - 1].rta).add(places[index - 1].duration || undefined)) ||
                undefined
            );
        }
        const showPuescWarningToIndex = places.findIndex(
            place =>
                !!(
                    place.route &&
                    props.logic.plannerLogic().isPuescTransport &&
                    props.logic.map().routing().polylineInPoland(place.route)
                )
        );
        return (
            <div
                className={cn('places-configurator', 'planner-content-scroll', {
                    'places-configurator-disabled': props.disabled
                })}
            >
                {props.isPuescTransportPossible && (
                    <div className="switch-puesc-container">
                        <div className="switch-puesc">
                            <Switch onChange={props.onPuescChange} checked={props.puescActive} />
                            <span className="puesc-description">{t('PlannerBar.puesc')}</span>
                        </div>
                    </div>
                )}
                {places.length > 0 && (
                    <div className="places-configurator-head">
                        <Row>
                            <Col xs={2}>{t('routing.places.heading.arrival')}</Col>
                            <Col xs={3}>{t('routing.places.heading.distance')}</Col>
                            <Col xs={15}>{t('routing.places.heading.destination')}</Col>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                {t('routing.places.heading.action')}
                            </Col>
                        </Row>
                    </div>
                )}
                {places.map((place, index) => {
                    return props.logic.plannerLogic().getPlaceDisabled(place) ? (
                        <Place
                            showPuescWarning={index === showPuescWarningToIndex}
                            disabled={props.logic.plannerLogic().getPlaceDisabled(place)}
                            isActive={props.logic.plannerLogic().selectedPlaceId === place.id}
                            hasRoute={(place.route ?? '') !== ''}
                            key={place.id}
                            order={index}
                            count={places.length}
                            placeBeforeRta={selectPlaceBeforeRta(index)}
                            plannerDisabled={
                                props.disabled ||
                                (typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number' &&
                                    index === 0) ||
                                props.routeLoading
                            } // First waypoint cant change when you go from addDestination MFR 28.5.2021
                            addNewTaskDisabled={props.disabled || props.routeLoading}
                            {...{ index, totalDistance, data: place, places, ...props }}
                        />
                    ) : (
                        <SortablePlace
                            showPuescWarning={index === showPuescWarningToIndex}
                            disabled={props.logic.plannerLogic().getPlaceDisabled(place)}
                            isActive={props.logic.plannerLogic().selectedPlaceId === place.id}
                            hasRoute={(place.route ?? '') !== ''}
                            key={place.id}
                            order={index}
                            count={places.length}
                            placeBeforeRta={selectPlaceBeforeRta(index)}
                            plannerDisabled={
                                props.disabled ||
                                (typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number' &&
                                    index === 0) ||
                                props.routeLoading
                            } // First waypoint cant change when you go from addDestination MFR 28.5.2021
                            addNewTaskDisabled={props.disabled || props.routeLoading}
                            {...{ index, totalDistance, data: place, places, ...props }}
                        />
                    );
                })}
            </div>
        );
    })
);

interface PlaceProps extends Props {
    showPuescWarning: boolean;
    order: number;
    count: number;
    data: TransportPlace;
    isActive: boolean;
    hasRoute: boolean;
    totalDistance: number;
    placeBeforeRta?: Moment;
    plannerDisabled?: boolean;
    placeId?: string;
    addNewTaskDisabled?: boolean;
    places: TransportPlace[];
}

const SortablePlace = SortableElement((props: PlaceProps) => <Place {...props} />);

const Place = observer(({ data, order, count, places = [], ...props }: PlaceProps) => {
    const momentRta = moment(data.rta).local();
    const { t } = useTranslation();

    const onRtaChange = (value: Moment | null) => {
        if (value && data.id) {
            props.logic.plannerLogic().changePlaceRta(data.id, value);
            props.logic.plannerLogic().planRoute(props.logic.plannerLogic().transport);
            props.logic.plannerLogic().drawRouteOnMap();
        }
    };

    function disabledDate(current: Moment): boolean {
        const placeBefore = places[order - 1];
        const now = moment().startOf('day');
        const isBefore = current.isBefore(now);
        const isBeforePlaceBeforeRTA = placeBefore ? current.isBefore(moment(placeBefore.rta)) : false;

        if (isBefore || isBeforePlaceBeforeRTA) {
            return true;
        }

        return false;
    }

    const puescValidationTaskError =
        props.logic.plannerLogic().puescValidationErrors?.loadingTask === false ||
        props.logic.plannerLogic().puescValidationErrors?.unloadingTask === false ||
        props.logic.plannerLogic().puescValidationErrors?.allGoodsUnloaded === false;
    const puescValidationTaskMessage = (
        <div>
            {props.logic.plannerLogic().puescValidationErrors?.loadingTask === false && (
                <>
                    {t('Planner.puesc.noLoadingTaskSelected')}
                    <br />
                </>
            )}
            {props.logic.plannerLogic().puescValidationErrors?.unloadingTask === false && (
                <>
                    {t('Planner.puesc.noUnloadingTaskSelected')}
                    <br />
                </>
            )}
            {props.logic.plannerLogic().puescValidationErrors?.allGoodsUnloaded === false && (
                <>
                    {t('Planner.puesc.noAllGodsUnloaded')}
                    <br />
                </>
            )}
        </div>
    );

    const disabled =
        props.logic.plannerLogic().getPlaceDisabled(places[order]) ||
        (order === 0 && typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number');

    const distance = places.slice(0, order + 1).reduce((a, c) => (a = a + (c.distance ?? 0)), 0);

    return (
        <div
            className={cn('places-configurator-place-wrapper', {
                'places-configurator-place-wrapper-inactive-sort': props.routeLoading
            })}
            data-qa={qa.planner.places.sectionPlace}
        >
            <div
                className={cn('places-configurator-place', {
                    'places-configurator-place-sorting': props.sorting,
                    'places-configurator-place-disabled': disabled,
                    'places-configurator-place-active': props.isActive,
                    'places-configurator-place-puesc': props.showPuescWarning
                })}
            >
                <Row align="middle">
                    <Col xs={2}>
                        <DatePicker.DateTimePicker
                            showTime={{ format: TIME_FORMAT_SHORT }}
                            disabled={disabled || props.plannerDisabled}
                            defaultValue={momentRta}
                            disabledDate={disabledDate}
                            onChange={onRtaChange}
                            showNow={order === 0}
                            qa={qa.planner.places.btnDatepicker}
                            trigger={
                                <div className="places-configurator-place-date">
                                    <div
                                        className="places-configurator-place-date-time"
                                        data-qa={qa.planner.places.fieldTime}
                                    >
                                        {momentRta.format('HH:mm')}
                                    </div>
                                    <div
                                        className="places-configurator-place-date-day"
                                        data-qa={qa.planner.places.fieldDate}
                                    >
                                        {momentRta.format('DD.MM.')}
                                    </div>
                                </div>
                            }
                        />
                    </Col>
                    <Col xs={3}>
                        <div className="places-configurator-place-distance">
                            <div
                                className={cn('places-configurator-place-distance-icon', {
                                    'places-configurator-place-distance-icon-last': order + 1 === count && count !== 1,
                                    'places-configurator-place-distance-line':
                                        props.hasRoute && !props.routeLoading && !props.sorting,
                                    'places-configurator-place-distance-line-puesc':
                                        props.showPuescWarning && !props.routeLoading && !props.sorting
                                })}
                            >
                                {(order + 1 === count || order === 0) && <i className="fa fa-map-marker" />}
                                {props.showPuescWarning && (
                                    <div
                                        className="places-configurator-place-puesc-warning"
                                        data-qa={qa.planner.places.fieldPuescWarning}
                                    >
                                        <PuescWarning size="medium" />
                                        <label>{t('common.puesc')}</label>
                                        <div className="places-configurator-place-puesc-warning-info">
                                            <Tooltip placement="right" title={t('Planner.puesc.puescRequirements')}>
                                                <img src={PlannerIcons.PuescInfo} alt="puesc-info" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <span data-qa={qa.planner.places.fieldDistance}>
                                {distance && numeral(Number((distance ?? 0) / 1000)).format('0,0.0')}
                            </span>{' '}
                            {t('common.km')}
                        </div>
                    </Col>
                    <Col xs={14}>
                        <div className="places-configurator-place-name">
                            {data.name ? (
                                <Tooltip
                                    title={
                                        <AddressFormatter
                                            defaultAddress={data.name ?? ''}
                                            addressStructured={data.addressStructured}
                                        />
                                    }
                                >
                                    {data.name}
                                </Tooltip>
                            ) : (
                                <AddressFormatter
                                    defaultAddress={data.name ?? ''}
                                    addressStructured={data.addressStructured}
                                    qa={qa.planner.places.fieldName}
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={5}>
                        <div className="places-configurator-place-actions">
                            {!props.addNewTaskDisabled && (
                                <img
                                    src={CommonIcons.addPlusCircle}
                                    onClick={() => props.onAddNewTask?.(data.id)}
                                    alt="add"
                                    data-qa={qa.planner.places.btnAddTask}
                                />
                            )}
                            {props.logic.plannerLogic().isPuescTransport &&
                                props.logic
                                    .map()
                                    .routing()
                                    .pointInPoland(latLngFromGeoJsonPointType(data.center ?? {})) && (
                                    <PuescWarning
                                        tooltip={puescValidationTaskError ? puescValidationTaskMessage : undefined}
                                        type={puescValidationTaskError ? 'error' : 'warning'}
                                        size="small"
                                    />
                                )}
                            {data.tasks &&
                                data.tasks.length > 0 &&
                                data.tasks.map(task => (
                                    <img
                                        src={props.logic.plannerLogic().getTaskTypeSelectionIcon(task.type)}
                                        className={cn('places-configurator-place-actions-task', {
                                            'places-configurator-place-actions-selected':
                                                props.logic.plannerLogic().selectedPlaceId === data.id
                                        })}
                                        key={task.id}
                                        onClick={() => props.onEditTask?.(data.id ?? '', task.id ?? '')}
                                        alt="tasks"
                                        data-qa={qa.planner.places.btnTasks}
                                    />
                                ))}
                            {!disabled && !props.plannerDisabled && (
                                <Button
                                    onClick={() => props.onDeleteClick?.(data.id ?? '')}
                                    data-qa={qa.planner.places.btnRemove}
                                    size="middle"
                                    type="link"
                                    icon={
                                        <img
                                            src={CommonIcons.trash}
                                            className="places-configurator-place-actions-remove"
                                            alt="delete"
                                        />
                                    }
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* There will be advanced place settings later */}
        </div>
    );
});

function shouldCancelStart(e: SortEvent | SortEventWithTag) {
    const tag = (e as SortEventWithTag).target.tagName.toLowerCase();
    const el = e.target as HTMLElement;
    return (
        tag === 'img' ||
        tag === 'input' ||
        String(el.className).startsWith('places-configurator-place-date') ||
        String(el.className).startsWith('ant')
    );
}

const SortedPlacesConfigurator = ({ onSortEnd, ...props }: Omit<Props, 'sorting'>) => {
    const [sorting, setIsSorting] = useState(false);

    function handleSortStart() {
        setIsSorting(true);
    }

    function handleSortEnd(sort: SortEnd, event: SortEvent) {
        if (
            (sort.newIndex === 0 && typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number') ||
            sort.oldIndex === sort.newIndex
        ) {
            // the first element is "unsortable"
            setIsSorting(false);
            return;
        }
        setIsSorting(false);
        onSortEnd?.(sort, event);
    }

    return (
        <PlacesConfigurator
            lockAxis="y"
            distance={1}
            shouldCancelStart={shouldCancelStart}
            onSortStart={handleSortStart}
            onSortEnd={handleSortEnd}
            sorting={sorting}
            {...props}
        />
    );
};

export default withLogicContext(observer(SortedPlacesConfigurator));
