/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyEditTripsV2TripsEditPost,
    BodyEditTripsV2TripsEditPostFromJSON,
    BodyEditTripsV2TripsEditPostToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    TripInDatabase,
    TripInDatabaseFromJSON,
    TripInDatabaseToJSON,
    TripResponse,
    TripResponseFromJSON,
    TripResponseToJSON,
} from '../models';

export interface EditTripsV2TripsEditPostRequest {
    bodyEditTripsV2TripsEditPost: BodyEditTripsV2TripsEditPost;
}

export interface GetTripsApiV2TripsGetRequest {
    driverId?: number;
    dateFrom?: Date;
    dateTo?: Date;
    limit?: number;
    offset?: number;
    minDistance?: number;
    stripMetadata?: boolean;
    wrapArray?: boolean;
    sort?: number;
    onlyNew?: boolean;
    trailerId?: number;
    monitoredObjectId?: string;
}

export interface GetTripsLiveApiV2TripsLiveGetRequest {
    monitoredObjectId?: string;
    driverId?: number;
    f?: Date;
    t?: Date;
}

/**
 * no description
 */
export class TripApi extends runtime.BaseAPI {

    /**
     * Edit Trips
     */
    async editTripsV2TripsEditPostRaw(requestParameters: EditTripsV2TripsEditPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.bodyEditTripsV2TripsEditPost === null || requestParameters.bodyEditTripsV2TripsEditPost === undefined) {
            throw new runtime.RequiredError('bodyEditTripsV2TripsEditPost','Required parameter requestParameters.bodyEditTripsV2TripsEditPost was null or undefined when calling editTripsV2TripsEditPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/trips/edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyEditTripsV2TripsEditPostToJSON(requestParameters.bodyEditTripsV2TripsEditPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Edit Trips
     */
    async editTripsV2TripsEditPost(requestParameters: EditTripsV2TripsEditPostRequest): Promise<object> {
        const response = await this.editTripsV2TripsEditPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Trips Api
     */
    async getTripsApiV2TripsGetRaw(requestParameters: GetTripsApiV2TripsGetRequest): Promise<runtime.ApiResponse<TripResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.minDistance !== undefined) {
            queryParameters['min_distance'] = requestParameters.minDistance;
        }

        if (requestParameters.stripMetadata !== undefined) {
            queryParameters['strip_metadata'] = requestParameters.stripMetadata;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.onlyNew !== undefined) {
            queryParameters['only_new'] = requestParameters.onlyNew;
        }

        if (requestParameters.trailerId !== undefined) {
            queryParameters['trailer_id'] = requestParameters.trailerId;
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/trips/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TripResponseFromJSON(jsonValue));
    }

    /**
     * Get Trips Api
     */
    async getTripsApiV2TripsGet(requestParameters: GetTripsApiV2TripsGetRequest): Promise<TripResponse> {
        const response = await this.getTripsApiV2TripsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Trips Live Api
     */
    async getTripsLiveApiV2TripsLiveGetRaw(requestParameters: GetTripsLiveApiV2TripsLiveGetRequest): Promise<runtime.ApiResponse<Array<TripInDatabase>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.f !== undefined) {
            queryParameters['f'] = (requestParameters.f as any).toISOString();
        }

        if (requestParameters.t !== undefined) {
            queryParameters['t'] = (requestParameters.t as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/trips/live/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripInDatabaseFromJSON));
    }

    /**
     * Get Trips Live Api
     */
    async getTripsLiveApiV2TripsLiveGet(requestParameters: GetTripsLiveApiV2TripsLiveGetRequest): Promise<Array<TripInDatabase>> {
        const response = await this.getTripsLiveApiV2TripsLiveGetRaw(requestParameters);
        return await response.value();
    }

}
