/* tslint:disable */
/* eslint-disable */
/**
 * WAG TP Request API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WAGTPRequest,
    WAGTPRequestFromJSON,
    WAGTPRequestFromJSONTyped,
    WAGTPRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface BodyServiceContractProvisioningServiceContractProvisioningPost
 */
export interface BodyServiceContractProvisioningServiceContractProvisioningPost {
    /**
     * 
     * @type {WAGTPRequest}
     * @memberof BodyServiceContractProvisioningServiceContractProvisioningPost
     */
    request: WAGTPRequest;
}

export function BodyServiceContractProvisioningServiceContractProvisioningPostFromJSON(json: any): BodyServiceContractProvisioningServiceContractProvisioningPost {
    return BodyServiceContractProvisioningServiceContractProvisioningPostFromJSONTyped(json, false);
}

export function BodyServiceContractProvisioningServiceContractProvisioningPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyServiceContractProvisioningServiceContractProvisioningPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': WAGTPRequestFromJSON(json['request']),
    };
}

export function BodyServiceContractProvisioningServiceContractProvisioningPostToJSON(value?: BodyServiceContractProvisioningServiceContractProvisioningPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': WAGTPRequestToJSON(value.request),
    };
}


