import { Col, Row } from 'antd';
import { CheckboxGroup, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import { Checkbox } from 'common/components/Form';
import TableFilter from 'common/components/TableFilter';
import qa from 'qa-selectors';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    driversOpts: CheckboxGroupOpts;
    vehiclesOpts: CheckboxGroupOpts;
    driversChecked: string[];
    vehiclesChecked: string[];
    shortActivity: boolean;
    fuelSuspiciousActivity: boolean;
    onConfirm?: (
        driversChecked: string[],
        vehiclesChecked: string[],
        shortActivity: boolean,
        fuelSusupiciousActivity: boolean
    ) => void;
    onCancel?: () => void;
}

interface State {
    driversChecked: string[];
    vehiclesChecked: string[];
    shortActivity: boolean;
    fuelSuspiciousActivity: boolean;
}

class FuelConsumptionFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            driversChecked: props.driversChecked,
            vehiclesChecked: props.vehiclesChecked,
            shortActivity: props.shortActivity,
            fuelSuspiciousActivity: props.fuelSuspiciousActivity
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.driversChecked.length !== this.props.driversChecked.length) {
            this.setState({ driversChecked: this.props.driversChecked });
        }
        if (prevProps.vehiclesChecked.length !== this.props.vehiclesChecked.length) {
            this.setState({ vehiclesChecked: this.props.vehiclesChecked });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <TableFilter
                className="fuel-consumption-filter"
                qa={qa.fuelConsumption.filter.section}
                heading={t('FuelConsumptionFilter.name')}
                body={
                    <Row gutter={[24, 24]}>
                        <Col>
                            <div>
                                <Checkbox
                                    checked={this.state.shortActivity}
                                    onChange={this._onShortActivityChange}
                                    qa={qa.fuelConsumption.filter.checkboxShortActivity}
                                >
                                    {t('FuelConsumptionFilter.shortActivity')}
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox
                                    checked={this.state.fuelSuspiciousActivity}
                                    onChange={this._onFuelSuspiciousActivityChange}
                                    qa={qa.fuelConsumption.filter.checkboxSuspiciousActivity}
                                >
                                    {t('FuelConsumptionFilter.fuelSuspiciousActivity')}
                                </Checkbox>
                            </div>
                        </Col>
                        {this.props.driversOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.drivers')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allDrivers')}
                                    options={this.props.driversOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.driversChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxDriversChange}
                                    qa={qa.fuelConsumption.filter.groupDrivers}
                                />
                            </Col>
                        )}
                        {this.props.vehiclesOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.vehicles')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allVehicles')}
                                    options={this.props.vehiclesOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.vehiclesChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxVehiclesChange}
                                    qa={qa.fuelConsumption.filter.groupVehicles}
                                />
                            </Col>
                        )}
                    </Row>
                }
                cancelButton={{
                    onClick: this._onCancel,
                    qa: qa.fuelConsumption.filter.btnCancel
                }}
                submitButton={{
                    onClick: this._onConfirmClick,
                    qa: qa.fuelConsumption.filter.btnSubmit,
                    disabled: this.props.demoMode
                }}
                resetButton={{
                    onClick: this._onResetClick
                }}
            />
        );
    }

    private _onResetClick = (): void => {
        const defaults = confDefault.settings.statistics.fuelConsumption.filter;
        this.setState({
            driversChecked: defaults.drivers,
            vehiclesChecked: defaults.vehicles,
            shortActivity: defaults.shortActivity,
            fuelSuspiciousActivity: defaults.fuelSuspiciousActivity
        });
    };

    private _onConfirmClick = (): void => {
        this.props.onConfirm?.(
            this.state.driversChecked,
            this.state.vehiclesChecked,
            this.state.shortActivity,
            this.state.fuelSuspiciousActivity
        );
    };

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onShortActivityChange = (): void => {
        this.setState(state => ({
            ...state,
            shortActivity: !state.shortActivity
        }));
    };

    private _onFuelSuspiciousActivityChange = (): void => {
        this.setState(state => ({
            ...state,
            fuelSuspiciousActivity: !state.fuelSuspiciousActivity
        }));
    };

    private _onCheckboxDriversChange = (driversChecked: string[]) => {
        this.setState({
            driversChecked
        });
    };

    private _onCheckboxVehiclesChange = (vehiclesChecked: string[]) => {
        this.setState({
            vehiclesChecked
        });
    };
}

export default withTranslation()(FuelConsumptionFilter);
