import { Logic } from './logic';
import { Subject } from 'rxjs';
import { ReadOnlyAccessCode, ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';
import { PaginatedResponse } from 'common/model/pagination';
import { GrantAccessFormValues } from 'modules/management/modules/system-connections/components/GrantAccessForm';
import { DEFAULT_PAGE_LIMIT_SHORT, DEFAULT_PAGE_OFFSET } from 'domain-constants';

export type CustomerAccessCode = {
    id: string;
    start: string;
    end: string;
    vehicles: string[];
    code: string;
};

export class CustomerAccessLogic {
    private _logic: Logic;

    accessCodes?: PaginatedResponse<CustomerAccessCode[]>;
    loading: boolean = true;
    private _vehicles?: ReadOnlyMonitoredObjectFeSb[];

    onData = new Subject<PaginatedResponse<CustomerAccessCode[]>>();
    onLoading = new Subject<boolean>();
    onError = new Subject<string>();

    constructor(logic: Logic) {
        this._logic = logic;
    }

    async loadData(limit: number = DEFAULT_PAGE_LIMIT_SHORT, offset: number = DEFAULT_PAGE_OFFSET) {
        this.onLoading.next(true);
        this._vehicles = await this._logic.vehicles().getMonitoredObjectFilters();
        this.accessCodes = await this._getAccesses(limit, offset);
        this.onData.next(this.accessCodes);
        this.onLoading.next(false);
    }

    async createAccess(data: GrantAccessFormValues) {
        const client = this._logic.auth().client()?.id;
        if (!client) {
            throw new Error('Missing client');
        }

        try {
            this.onLoading.next(true);
            await this._createAccess({
                client,
                end: new Date(data.end),
                start: new Date(data.start),
                monitoredObjects: data.vehicles.map(v => Number(v))
            });
            this.accessCodes = await this._getAccesses();
            this.onData.next(this.accessCodes);
            this.onLoading.next(false);
        } catch (e) {
            this.onLoading.next(false);
            throw e;
        }
    }

    async deleteAccess(id: string) {
        this.onLoading.next(true);
        await this._deleteAccess(id);
        this.accessCodes = await this._getAccesses();
        this.onData.next(this.accessCodes);
        this.onLoading.next(false);
    }

    async getTokenFromCode(code: string) {
        const token = await this._loadToken(code);
        return token;
    }

    private async _createAccess(data: ReadOnlyAccessCode): Promise<ReadOnlyAccessCode> {
        const resp = await this._logic.api().customerAccessApi.customerAccessAccessCodeCreate({
            data
        });
        return resp;
    }

    private async _loadToken(code: string): Promise<string> {
        const resp = await this._logic.api().customerAccessApiRaw().customerAccessTokenAuthCreate({
            data: { code }
        });
        return (resp as any).token;
    }

    private async _getAccesses(
        limit: number = DEFAULT_PAGE_LIMIT_SHORT,
        offset: number = DEFAULT_PAGE_OFFSET
    ): Promise<PaginatedResponse<CustomerAccessCode[]>> {
        const resp = await this._logic.api().customerAccessApi.customerAccessAccessCodeList({ limit, offset });
        return {
            data: resp.results.map(v => this._toAccessModel(v, this._vehicles)),
            total: resp.count,
            limit,
            offset
        };
    }

    private async _deleteAccess(id: string): Promise<ReadOnlyAccessCode> {
        const resp = await this._logic.api().customerAccessApi.customerAccessAccessCodeDelete({ id });
        return resp;
    }

    private _toAccessModel(v: ReadOnlyAccessCode, vehicles?: ReadOnlyMonitoredObjectFeSb[]): CustomerAccessCode {
        return {
            code: v.code ?? '',
            end: v.end?.toString() ?? '',
            start: v.start?.toString() ?? '',
            id: v.id ?? '',
            vehicles:
                v.monitoredObjects?.map(mo => vehicles?.find(v => v.id === mo)?.registrationNumber ?? String(mo)) ?? []
        };
    }
}
