/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressComponent,
    AddressComponentFromJSON,
    AddressComponentFromJSONTyped,
    AddressComponentToJSON,
    AddressGeometry,
    AddressGeometryFromJSON,
    AddressGeometryFromJSONTyped,
    AddressGeometryToJSON,
    PlusCode,
    PlusCodeFromJSON,
    PlusCodeFromJSONTyped,
    PlusCodeToJSON,
} from './';

/**
 * When the geocoder returns results, it places them within a (JSON) `results` array. Even if the geocoder returns no results (such as if the address doesn\'t exist) it still returns an empty `results` array. (XML responses consist of zero or more `<result>` elements.)
 * @export
 * @interface GeocodingResult
 */
export interface GeocodingResult {
    /**
     * array indicates the type of the returned result. This array contains a set of zero or more tags identifying the type of feature returned in the result. For example, a geocode of \"Chicago\" returns \"locality\" which indicates that \"Chicago\" is a city, and also returns \"political\" which indicates it is a political entity.
     * @type {Array<object>}
     * @memberof GeocodingResult
     */
    types: Array<object>;
    /**
     * is a string containing the human-readable address of this location.  Often this address is equivalent to the postal address. Note that some countries, such as the United Kingdom, do not allow distribution of true postal addresses due to licensing restrictions.  The formatted address is logically composed of one or more address components. For example, the address \"111 8th Avenue, New York, NY\" consists of the following components: \"111\" (the street number), \"8th Avenue\" (the route), \"New York\" (the city) and \"NY\" (the US state).  Do not parse the formatted address programmatically. Instead you should use the individual address components, which the API response includes in addition to the formatted address field.
     * @type {string}
     * @memberof GeocodingResult
     */
    formattedAddress: string;
    /**
     * is an array containing the separate components applicable to this address.  Note the following facts about the `address_components[]` array:   - The array of address components may contain more components than the `formatted_address`.   - The array does not necessarily include all the political entities that contain an address,     apart from those included in the `formatted_address`. To retrieve all the political entities that contain a specific address,     you should use reverse geocoding, passing the latitude/longitude of the address as a parameter to the request.   - The format of the response is not guaranteed to remain the same between requests.     In particular, the number of `address_components` varies based on the address requested and can change     over time for the same address. A component can change position in the array.     The type of the component can change. A particular component may be missing in a later response.
     * @type {Array<AddressComponent>}
     * @memberof GeocodingResult
     */
    addressComponents: Array<AddressComponent>;
    /**
     * is an array denoting all the localities contained in a postal code. This is only present when the result is a postal code that contains multiple localities.
     * @type {Array<string>}
     * @memberof GeocodingResult
     */
    postcodeLocalities: Array<string>;
    /**
     * 
     * @type {AddressGeometry}
     * @memberof GeocodingResult
     */
    geometry: AddressGeometry;
    /**
     * 
     * @type {PlusCode}
     * @memberof GeocodingResult
     */
    plusCode: PlusCode;
    /**
     * indicates that the geocoder did not return an exact match for the original request, though it was able to match part of the requested address. You may wish to examine the original request for misspellings and/or an incomplete address.  Partial matches most often occur for street addresses that do not exist within the locality you pass in the request. Partial matches may also be returned when a request matches two or more locations in the same locality. For example, \"21 Henr St, Bristol, UK\" will return a partial match for both Henry Street and Henrietta Street. Note that if a request includes a misspelled address component, the geocoding service may suggest an alternative address. Suggestions triggered in this way will also be marked as a partial match.
     * @type {boolean}
     * @memberof GeocodingResult
     */
    partialMatch: boolean;
    /**
     * is a unique identifier that can be used with other Google APIs.
     * @type {string}
     * @memberof GeocodingResult
     */
    placeId: string;
}

export function GeocodingResultFromJSON(json: any): GeocodingResult {
    return GeocodingResultFromJSONTyped(json, false);
}

export function GeocodingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeocodingResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': json['types'],
        'formattedAddress': json['formatted_address'],
        'addressComponents': ((json['address_components'] as Array<any>).map(AddressComponentFromJSON)),
        'postcodeLocalities': json['postcode_localities'],
        'geometry': AddressGeometryFromJSON(json['geometry']),
        'plusCode': PlusCodeFromJSON(json['plus_code']),
        'partialMatch': json['partial_match'],
        'placeId': json['place_id'],
    };
}

export function GeocodingResultToJSON(value?: GeocodingResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': value.types,
        'formatted_address': value.formattedAddress,
        'address_components': ((value.addressComponents as Array<any>).map(AddressComponentToJSON)),
        'postcode_localities': value.postcodeLocalities,
        'geometry': AddressGeometryToJSON(value.geometry),
        'plus_code': PlusCodeToJSON(value.plusCode),
        'partial_match': value.partialMatch,
        'place_id': value.placeId,
    };
}


