/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FuelConsumptionReportType
 */
export interface FuelConsumptionReportType {
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1CDrvDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvWoCTEcoroll: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvLoad86: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TRDrvLoadOverOpt: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1CStayIncCStayPto: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TAccCc: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvAccConst: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvKd: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1CDrvCcDDrvCc: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1DDrvTDrvCc: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1DEcoroll: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1DEcorollDDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1RAvgLoad: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    fc1TAccCcTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    sc1TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    sc1TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    sc1TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    sc1TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    sc1TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    rc1TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    rc1TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    rc1TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    rc1TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    rc1TAvgAccBr: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    roadType: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    pc1TDrvWoCTEcorollTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    pc1TDrvAccConstTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    pc1TDrvLoad86TREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    pc1TRDrvLoadOverOptTREng: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionReportType
     */
    pc1TAvgAccBr: number;
}

export function FuelConsumptionReportTypeFromJSON(json: any): FuelConsumptionReportType {
    return FuelConsumptionReportTypeFromJSONTyped(json, false);
}

export function FuelConsumptionReportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionReportType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fc1CDrvDDrv': json['fc1_c_drv_d_drv'],
        'fc1TDrvWoCTEcoroll': json['fc1_t_drv_wo_c_t_ecoroll'],
        'fc1TDrvLoad86': json['fc1_t_drv_load_86'],
        'fc1TRDrvLoadOverOpt': json['fc1_t_r_drv_load_over_opt'],
        'fc1CStayIncCStayPto': json['fc1_c_stay_inc_c_stay_pto'],
        'fc1TAccCc': json['fc1_t_acc_cc'],
        'fc1TDrvAccConst': json['fc1_t_drv_acc_const'],
        'fc1TDrvKd': json['fc1_t_drv_kd'],
        'fc1CDrvCcDDrvCc': json['fc1_c_drv_cc_d_drv_cc'],
        'fc1DDrvTDrvCc': json['fc1_d_drv_t_drv_cc'],
        'fc1DEcoroll': json['fc1_d_ecoroll'],
        'fc1DEcorollDDrv': json['fc1_d_ecoroll_d_drv'],
        'fc1RAvgLoad': json['fc1_r_avg_load'],
        'fc1TDrvWoCTEcorollTDrv': json['fc1_t_drv_wo_c_t_ecoroll_t_drv'],
        'fc1TDrvLoad86TREng': json['fc1_t_drv_load_86_t_r_eng'],
        'fc1TDrvAccConstTREng': json['fc1_t_drv_acc_const_t_r_eng'],
        'fc1TRDrvLoadOverOptTREng': json['fc1_t_r_drv_load_over_opt_t_r_eng'],
        'fc1TAvgAccBr': json['fc1_t_avg_acc_br'],
        'fc1TAccCcTDrv': json['fc1_t_acc_cc_t_drv'],
        'sc1TDrvWoCTEcorollTDrv': json['sc1_t_drv_wo_c_t_ecoroll_t_drv'],
        'sc1TDrvAccConstTREng': json['sc1_t_drv_acc_const_t_r_eng'],
        'sc1TDrvLoad86TREng': json['sc1_t_drv_load_86_t_r_eng'],
        'sc1TRDrvLoadOverOptTREng': json['sc1_t_r_drv_load_over_opt_t_r_eng'],
        'sc1TAvgAccBr': json['sc1_t_avg_acc_br'],
        'rc1TDrvWoCTEcorollTDrv': json['rc1_t_drv_wo_c_t_ecoroll_t_drv'],
        'rc1TDrvAccConstTREng': json['rc1_t_drv_acc_const_t_r_eng'],
        'rc1TDrvLoad86TREng': json['rc1_t_drv_load_86_t_r_eng'],
        'rc1TRDrvLoadOverOptTREng': json['rc1_t_r_drv_load_over_opt_t_r_eng'],
        'rc1TAvgAccBr': json['rc1_t_avg_acc_br'],
        'roadType': json['road_type'],
        'pc1TDrvWoCTEcorollTDrv': json['pc1_t_drv_wo_c_t_ecoroll_t_drv'],
        'pc1TDrvAccConstTREng': json['pc1_t_drv_acc_const_t_r_eng'],
        'pc1TDrvLoad86TREng': json['pc1_t_drv_load_86_t_r_eng'],
        'pc1TRDrvLoadOverOptTREng': json['pc1_t_r_drv_load_over_opt_t_r_eng'],
        'pc1TAvgAccBr': json['pc1_t_avg_acc_br'],
    };
}

export function FuelConsumptionReportTypeToJSON(value?: FuelConsumptionReportType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fc1_c_drv_d_drv': value.fc1CDrvDDrv,
        'fc1_t_drv_wo_c_t_ecoroll': value.fc1TDrvWoCTEcoroll,
        'fc1_t_drv_load_86': value.fc1TDrvLoad86,
        'fc1_t_r_drv_load_over_opt': value.fc1TRDrvLoadOverOpt,
        'fc1_c_stay_inc_c_stay_pto': value.fc1CStayIncCStayPto,
        'fc1_t_acc_cc': value.fc1TAccCc,
        'fc1_t_drv_acc_const': value.fc1TDrvAccConst,
        'fc1_t_drv_kd': value.fc1TDrvKd,
        'fc1_c_drv_cc_d_drv_cc': value.fc1CDrvCcDDrvCc,
        'fc1_d_drv_t_drv_cc': value.fc1DDrvTDrvCc,
        'fc1_d_ecoroll': value.fc1DEcoroll,
        'fc1_d_ecoroll_d_drv': value.fc1DEcorollDDrv,
        'fc1_r_avg_load': value.fc1RAvgLoad,
        'fc1_t_drv_wo_c_t_ecoroll_t_drv': value.fc1TDrvWoCTEcorollTDrv,
        'fc1_t_drv_load_86_t_r_eng': value.fc1TDrvLoad86TREng,
        'fc1_t_drv_acc_const_t_r_eng': value.fc1TDrvAccConstTREng,
        'fc1_t_r_drv_load_over_opt_t_r_eng': value.fc1TRDrvLoadOverOptTREng,
        'fc1_t_avg_acc_br': value.fc1TAvgAccBr,
        'fc1_t_acc_cc_t_drv': value.fc1TAccCcTDrv,
        'sc1_t_drv_wo_c_t_ecoroll_t_drv': value.sc1TDrvWoCTEcorollTDrv,
        'sc1_t_drv_acc_const_t_r_eng': value.sc1TDrvAccConstTREng,
        'sc1_t_drv_load_86_t_r_eng': value.sc1TDrvLoad86TREng,
        'sc1_t_r_drv_load_over_opt_t_r_eng': value.sc1TRDrvLoadOverOptTREng,
        'sc1_t_avg_acc_br': value.sc1TAvgAccBr,
        'rc1_t_drv_wo_c_t_ecoroll_t_drv': value.rc1TDrvWoCTEcorollTDrv,
        'rc1_t_drv_acc_const_t_r_eng': value.rc1TDrvAccConstTREng,
        'rc1_t_drv_load_86_t_r_eng': value.rc1TDrvLoad86TREng,
        'rc1_t_r_drv_load_over_opt_t_r_eng': value.rc1TRDrvLoadOverOptTREng,
        'rc1_t_avg_acc_br': value.rc1TAvgAccBr,
        'road_type': value.roadType,
        'pc1_t_drv_wo_c_t_ecoroll_t_drv': value.pc1TDrvWoCTEcorollTDrv,
        'pc1_t_drv_acc_const_t_r_eng': value.pc1TDrvAccConstTREng,
        'pc1_t_drv_load_86_t_r_eng': value.pc1TDrvLoad86TREng,
        'pc1_t_r_drv_load_over_opt_t_r_eng': value.pc1TRDrvLoadOverOptTREng,
        'pc1_t_avg_acc_br': value.pc1TAvgAccBr,
    };
}


