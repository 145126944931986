/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EWClient,
    EWClientFromJSON,
    EWClientFromJSONTyped,
    EWClientToJSON,
    TLangCodesISO6391,
    TLangCodesISO6391FromJSON,
    TLangCodesISO6391FromJSONTyped,
    TLangCodesISO6391ToJSON,
    UserLoginCredentials,
    UserLoginCredentialsFromJSON,
    UserLoginCredentialsFromJSONTyped,
    UserLoginCredentialsToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ssoId: string;
    /**
     * 
     * @type {EWClient}
     * @memberof User
     */
    client?: EWClient;
    /**
     * 
     * @type {UserLoginCredentials}
     * @memberof User
     */
    loginCredentials: UserLoginCredentials;
    /**
     * 
     * @type {TLangCodesISO6391}
     * @memberof User
     */
    language: TLangCodesISO6391;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ssoId': json['ssoId'],
        'client': !exists(json, 'client') ? undefined : EWClientFromJSON(json['client']),
        'loginCredentials': UserLoginCredentialsFromJSON(json['loginCredentials']),
        'language': TLangCodesISO6391FromJSON(json['language']),
        'name': json['name'],
        'surname': json['surname'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ssoId': value.ssoId,
        'client': EWClientToJSON(value.client),
        'loginCredentials': UserLoginCredentialsToJSON(value.loginCredentials),
        'language': TLangCodesISO6391ToJSON(value.language),
        'name': value.name,
        'surname': value.surname,
    };
}


