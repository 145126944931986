/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
} from './';

/**
 * 
 * @export
 * @interface PointInfo
 */
export interface PointInfo {
    /**
     * 
     * @type {number}
     * @memberof PointInfo
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof PointInfo
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof PointInfo
     */
    lon: number;
    /**
     * 
     * @type {number}
     * @memberof PointInfo
     */
    speed: number;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof PointInfo
     */
    address?: Array<AddressStructured>;
    /**
     * 
     * @type {number}
     * @memberof PointInfo
     */
    fuelLevel?: number;
}

export function PointInfoFromJSON(json: any): PointInfo {
    return PointInfoFromJSONTyped(json, false);
}

export function PointInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'lat': json['lat'],
        'lon': json['lon'],
        'speed': json['speed'],
        'address': !exists(json, 'address') ? undefined : ((json['address'] as Array<any>).map(AddressStructuredFromJSON)),
        'fuelLevel': !exists(json, 'fuel_level') ? undefined : json['fuel_level'],
    };
}

export function PointInfoToJSON(value?: PointInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'lat': value.lat,
        'lon': value.lon,
        'speed': value.speed,
        'address': value.address === undefined ? undefined : ((value.address as Array<any>).map(AddressStructuredToJSON)),
        'fuel_level': value.fuelLevel,
    };
}


