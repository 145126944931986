/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrivingModeReportSummary
 */
export interface DrivingModeReportSummary {
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1TDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1TREng: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1DDrvTDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1DDrv: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1CntDp: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1DDrvRet20DDrvBrDDrvParkBr: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1TDrvCc: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1TDrvCcTREng: number;
    /**
     * 
     * @type {number}
     * @memberof DrivingModeReportSummary
     */
    fd1AltIncrDDrv: number;
}

export function DrivingModeReportSummaryFromJSON(json: any): DrivingModeReportSummary {
    return DrivingModeReportSummaryFromJSONTyped(json, false);
}

export function DrivingModeReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingModeReportSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fd1TDrv': json['fd1_t_drv'],
        'fd1TREng': json['fd1_t_r_eng'],
        'fd1DDrvTDrv': json['fd1_d_drv_t_drv'],
        'fd1DDrv': json['fd1_d_drv'],
        'fd1CntDp': json['fd1_cnt_dp'],
        'fd1DDrvRet20DDrvBrDDrvParkBr': json['fd1_d_drv_ret_20_d_drv_br_d_drv_park_br'],
        'fd1TDrvCc': json['fd1_t_drv_cc'],
        'fd1TDrvCcTREng': json['fd1_t_drv_cc_t_r_eng'],
        'fd1AltIncrDDrv': json['fd1_alt_incr_d_drv'],
    };
}

export function DrivingModeReportSummaryToJSON(value?: DrivingModeReportSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fd1_t_drv': value.fd1TDrv,
        'fd1_t_r_eng': value.fd1TREng,
        'fd1_d_drv_t_drv': value.fd1DDrvTDrv,
        'fd1_d_drv': value.fd1DDrv,
        'fd1_cnt_dp': value.fd1CntDp,
        'fd1_d_drv_ret_20_d_drv_br_d_drv_park_br': value.fd1DDrvRet20DDrvBrDDrvParkBr,
        'fd1_t_drv_cc': value.fd1TDrvCc,
        'fd1_t_drv_cc_t_r_eng': value.fd1TDrvCcTREng,
        'fd1_alt_incr_d_drv': value.fd1AltIncrDDrv,
    };
}


