import { Row, Col } from 'antd';
import { InputField, AutoCompleteFieldAnt as AutoCompleteField, SelectField } from 'common/fields';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as TransportIcons from 'resources/images/transport';
import { useState } from 'react';
import cn from 'classnames';
import PlaceTaskGoods from '../PlaceTaskGoodsLoading/PlaceTaskGoods';
import { Textarea } from 'common/components/Form';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import { toAddress } from 'common/utils/address';
import { AddressIdentification } from 'common/components/Settings';
import { Role } from 'logic/auth';
import PlannerAlarms from '../PlannerAlarms';
import * as Yup from 'yup';
import { Goods, TransportEventRule, TransportPlaceTask } from 'generated/backend-api/models';
import { ReadOnlyContactList } from 'generated/new-main/models';
import { PuescWarning } from '../PuescWarning/PuescWarning';
import { GoodsWithAddress } from 'common/model/transports';
import { ClientContactForm, ContactList } from 'common/model/client-contact';
import qa from 'qa-selectors';

interface Props extends WithTranslation, WithLogic {
    task: TransportPlaceTask;
    initialClientContact?: Partial<ReadOnlyContactList>;
    placeDisabled: boolean;
    placeIndex: number;
    goodsWithAddress?: GoodsWithAddress[];
    addressFormikRef?: React.RefObject<FormikProps<ContactList>>;
    onClientContactFormikSubmit: (val: ContactList) => Promise<boolean>;
    onLoadingGoodsChange: (goods: Goods[]) => void;
    onPuescCommentChange: (comment: string) => void;
    onAlarmConfigChange: (config: TransportEventRule[]) => void;
    onNoteForDriverChange: (val: string) => void;
    onManualAtaDateChange?: (dateTime: string, placeId: string) => void;
}

function PlaceTypeLoading(props: Props) {
    const [collapseContent, setCollapseContent] = useState({
        address: false,
        goods: false,
        puescComments: false,
        notifications: false
    });
    const [initialContact, setInitialContact] = useState(props.initialClientContact);
    const { t } = props;
    const clientOptions = props.logic.plannerLogic().clientContactList.map(client => {
        return {
            value: client.name,
            id: client.id
        };
    });

    const countriesOptions = props.logic.plannerLogic().countryList.map(country => {
        return {
            value: country.id ?? '',
            label: country.name
        };
    });

    const collapse = (
        title: string | React.ReactNode,
        type: keyof typeof collapseContent,
        collapsable: boolean = true,
        smallTitle: boolean = false
    ) => {
        return (
            <div className="place-type-collapse">
                <span
                    className={cn('place-type-collapse-title', {
                        small: smallTitle
                    })}
                >
                    {title}
                </span>
                {collapsable && (
                    <div className="place-type-collapse-action">
                        <span
                            onClick={() =>
                                setCollapseContent({
                                    address: false,
                                    goods: false,
                                    notifications: false,
                                    puescComments: false,
                                    [type]: !collapseContent[type]
                                })
                            }
                            data-qa={
                                collapseContent[type] ? qa.planner.activity.hide[type] : qa.planner.activity.show[type]
                            }
                        >
                            {collapseContent[type] ? t('common.hide') : t('common.show')}{' '}
                        </span>

                        <img
                            src={collapseContent[type] ? TransportIcons.arrowDown : TransportIcons.arrowRight}
                            alt="arrow"
                        />
                    </div>
                )}
            </div>
        );
    };

    const schema = Yup.object().shape(
        {
            name: Yup.string().required(t('common.required')),

            businessId: Yup.string().when(['vat', 'taxId', 'otherIdentificationNumber'], {
                is: (vat: string, taxId: string, otherIdentificationNumber: string) => {
                    return !vat && !taxId && !otherIdentificationNumber;
                },
                then: Yup.string().required(t('ClientContactListForm.requiredId')),
                otherwise: Yup.string().notRequired()
            }),
            vat: Yup.string().when(['businessId', 'taxId', 'otherIdentificationNumber'], {
                is: (businessId: string, taxId: string, otherIdentificationNumber: string) => {
                    return !businessId && !taxId && !otherIdentificationNumber;
                },
                then: Yup.string().required(''),
                otherwise: Yup.string().notRequired()
            }),
            taxId: Yup.string().when(['vat', 'businessId', 'otherIdentificationNumber'], {
                is: (vat: string, businessId: string, otherIdentificationNumber: string) => {
                    return !vat && !businessId && !otherIdentificationNumber;
                },
                then: Yup.string().required(''),
                otherwise: Yup.string().notRequired()
            }),
            otherIdentificationNumber: Yup.string().when(['vat', 'businessId', 'taxId'], {
                is: (vat: string, businessId: string, taxId: string) => {
                    return !vat && !businessId && !taxId;
                },
                then: Yup.string().required(''),
                otherwise: Yup.string().notRequired()
            }),
            countryId: Yup.number().required(t('common.required')),
            city: Yup.string().required(t('common.required')),
            postalCode: Yup.string().required(t('common.required')),
            street: Yup.string(),
            houseNumber: Yup.string(),
            premisesNumber: Yup.string().required(t('common.required'))
        },
        [
            ['otherIdentificationNumber', 'taxId'],
            ['otherIdentificationNumber', 'vat'],
            ['otherIdentificationNumber', 'businessId'],
            ['taxId', 'vat'],
            ['taxId', 'businessId'],
            ['vat', 'businessId']
        ]
    );

    const onClientSelect = (id: string) => {
        setInitialContact(props.logic.plannerLogic().clientContactList.find(c => c.id === id));
    };

    const onClientContactFormSubmit = (clientContact: ClientContactForm) => {
        return props.onClientContactFormikSubmit({
            ...clientContact,
            country: props.logic.plannerLogic().countryList.find(c => c.id === clientContact.countryId)
        });
    };

    return (
        <div className="place-type-loading">
            <div className="place-type-loading-item">
                <Row gutter={[20, 10]} className="form-row form-row-title">
                    <Col span={24}>
                        {collapse(
                            <div className="title-puesc-warning">
                                <label>{props.t('PlannerPlacesTask.senderOfGoods')}</label>
                                {props.logic.plannerLogic().isPuescTransport && (
                                    <PuescWarning
                                        size="medium"
                                        tooltip={t('PlannerPlacesTask.clientContactRequirements')}
                                    />
                                )}
                            </div>,
                            'goods',
                            false
                        )}
                    </Col>
                </Row>

                <Formik<ClientContactForm>
                    innerRef={props.addressFormikRef}
                    initialValues={{
                        name: initialContact?.name ?? '',
                        businessId: initialContact?.businessId ?? '',
                        vat: initialContact?.vat ?? '',
                        taxId: initialContact?.taxId ?? '',
                        otherIdentificationNumber: initialContact?.otherIdentificationNumber ?? '',

                        countryId: initialContact?.country?.id,
                        city: initialContact?.city ?? '',
                        postalCode: initialContact?.postalCode ?? '',
                        street: initialContact?.street ?? '',
                        houseNumber: initialContact?.houseNumber ?? '',
                        premisesNumber: initialContact?.premisesNumber ?? ''
                    }}
                    onSubmit={onClientContactFormSubmit}
                    validationSchema={schema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    enableReinitialize
                >
                    {(formik: FormikProps<ClientContactForm>) => {
                        if (formik.isSubmitting && !collapseContent.address) {
                            setCollapseContent({
                                ...collapseContent,
                                address: true
                            });
                        }
                        return (
                            <div className="place-type-loading-client-form">
                                <Form>
                                    <Row gutter={[20, 10]} className="form-row form-row-title">
                                        <Col span={24}>
                                            <span className="base-title">
                                                {t('ClientContactListForm.basicInformationTitle')}
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={[20, 10]}
                                        className="form-row form-row-content form-row-content-basic-information"
                                    >
                                        <Col span={6}>
                                            <AutoCompleteField
                                                disabled={props.placeDisabled}
                                                size="large"
                                                options={clientOptions}
                                                defaultValue={initialContact?.name}
                                                name="name"
                                                label={t('ClientContactListForm.companyName')}
                                                placeholder={t('ClientContactListForm.companyName')}
                                                customValueEnable
                                                filterOption={(inputValue, option) =>
                                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onSelect={(_value, option) => {
                                                    onClientSelect(option.id);
                                                }}
                                                qa={qa.planner.activity.inputCompanyName}
                                            />
                                        </Col>
                                        <Col span={5} className="client-contact-bussines-id">
                                            <InputField
                                                disabled={props.placeDisabled}
                                                size="large"
                                                name="businessId"
                                                label={t('ClientContactListForm.businessId')}
                                                placeholder={t('ClientContactListForm.businessId')}
                                                qa={qa.planner.activity.inputBussinessId}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <InputField
                                                disabled={props.placeDisabled}
                                                size="large"
                                                name="vat"
                                                label={t('ClientContactListForm.vat')}
                                                placeholder={t('ClientContactListForm.vat')}
                                                qa={qa.planner.activity.inputVat}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <InputField
                                                disabled={props.placeDisabled}
                                                size="large"
                                                name="taxId"
                                                label={t('ClientContactListForm.taxId')}
                                                placeholder={t('ClientContactListForm.taxId')}
                                                qa={qa.planner.activity.inputTaxId}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <InputField
                                                disabled={props.placeDisabled}
                                                size="large"
                                                name="otherIdentificationNumber"
                                                label={t('ClientContactListForm.otherIdentificationNumber')}
                                                placeholder={t('ClientContactListForm.otherIdentificationNumber')}
                                                qa={qa.planner.activity.inputOther}
                                            />
                                        </Col>
                                    </Row>

                                    <Row gutter={[20, 10]} className="form-row form-row-title">
                                        <Col span={24}>
                                            {collapse(
                                                <div className="title-puesc-warning">
                                                    <label>{t('ClientContactListForm.addressOfResidenceTitle')}</label>
                                                    {props.logic.plannerLogic().isPuescTransport && (
                                                        <div className="title-puesc-warning-required">
                                                            <span>({t('Planner.puesc.requiredForPUESC')})</span>
                                                            <PuescWarning size="medium" />
                                                        </div>
                                                    )}
                                                </div>,
                                                'address',
                                                true,
                                                true
                                            )}
                                        </Col>
                                    </Row>

                                    <div
                                        className={cn('place-type-collapse-content', {
                                            hidden: !collapseContent.address
                                        })}
                                    >
                                        <Row gutter={[20, 10]} className="form-row">
                                            <Col span={8}>
                                                <SelectField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    // @ts-ignore
                                                    options={countriesOptions}
                                                    name="countryId"
                                                    label={t('ClientContactListForm.country')}
                                                    placeholder={t('ClientContactListForm.country')}
                                                    showSearch
                                                    qa={qa.planner.activity.inputCountry}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    name="city"
                                                    label={t('ClientContactListForm.city')}
                                                    placeholder={t('ClientContactListForm.city')}
                                                    qa={qa.planner.activity.inputCity}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    name="postalCode"
                                                    label={t('ClientContactListForm.postalCode')}
                                                    placeholder={t('ClientContactListForm.postalCode')}
                                                    qa={qa.planner.activity.inputPostalCode}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[20, 10]} className="form-row">
                                            <Col span={8}>
                                                <InputField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    name="street"
                                                    label={t('ClientContactListForm.street')}
                                                    placeholder={t('ClientContactListForm.street')}
                                                    qa={qa.planner.activity.inputStreet}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    name="houseNumber"
                                                    label={t('ClientContactListForm.houseNumber')}
                                                    placeholder={t('ClientContactListForm.houseNumber')}
                                                    qa={qa.planner.activity.inputHouseNumber}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <InputField
                                                    disabled={props.placeDisabled}
                                                    size="large"
                                                    name="premisesNumber"
                                                    label={t('ClientContactListForm.premisesNumber')}
                                                    placeholder={t('ClientContactListForm.premisesNumber')}
                                                    qa={qa.planner.activity.inputPremisesNumber}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <div className="place-type-loading-item">
                <Row gutter={[20, 10]} className="form-row form-row-title">
                    <Col span={24}>
                        {collapse(
                            <div className="title-puesc-warning">
                                <label>{props.t('PlannerPlacesTask.loadedGoods')}</label>
                                {props.logic.plannerLogic().isPuescTransport && <PuescWarning size="medium" />}
                            </div>,
                            'goods'
                        )}
                    </Col>
                </Row>
                <div
                    className={cn('place-type-collapse-content', {
                        hidden: !collapseContent.goods
                    })}
                >
                    <PlaceTaskGoods
                        placeIndex={props.placeIndex}
                        placeDisabled={props.placeDisabled}
                        currentAddress={toAddress(
                            props.logic.auth().user().lang,
                            props.logic.auth().client()!,
                            props.logic.plannerLogic().addressBySelectedPlace,
                            AddressIdentification.Address,
                            ''
                        )}
                        initialGoods={props.goodsWithAddress}
                        isPuesc={props.logic.plannerLogic().isPuescTransport}
                        onLoadingGoodsChange={props.onLoadingGoodsChange}
                    />
                </div>
            </div>
            {props.logic.plannerLogic().isPuescTransport && (
                <div className="place-type-loading-item">
                    <Row gutter={[20, 10]} className="form-row form-row-title">
                        <Col span={24}>{collapse(props.t('PlannerPlacesTask.PUESCComments'), 'puescComments')}</Col>
                    </Row>

                    <div
                        className={cn('place-type-collapse-content', {
                            hidden: !collapseContent.puescComments
                        })}
                    >
                        <Textarea
                            value={props.task.puescComments}
                            onChange={val => props.onPuescCommentChange(val.target.value)}
                            qa={qa.planner.activity.inputComments}
                        />
                    </div>
                </div>
            )}

            <div className="place-type-loading-item">
                <Row gutter={[20, 10]} className="form-row form-row-title">
                    <Col span={24}>{collapse(props.t('PlannerPlacesTask.notifications'), 'notifications')}</Col>
                </Row>
                <div
                    className={cn('place-type-collapse-content', {
                        hidden: !collapseContent.notifications
                    })}
                >
                    {props.logic.auth().roles().includes(Role.IA_R) && (
                        <PlannerAlarms
                            task={props.task}
                            alarmValue={props.logic.plannerLogic().alarmsBySelectedPlace}
                            driverNoteValue={props.logic.plannerLogic().driverNoteBySelectedTask}
                            disabled={props.placeDisabled}
                            placeIndex={props.placeIndex}
                            onAlarmConfigChange={props.onAlarmConfigChange}
                            onNoteForDriverChange={props.onNoteForDriverChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(withLogicContext(observer(PlaceTypeLoading)));
