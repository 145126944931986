import moment from 'moment';

import { TransportModel, VehicleWithAvailability } from 'common/model/transports';
import { AddressIdentification, VehicleIdentification } from 'common/components/Settings';
import { VehicleStateObject } from '../generated/graphql';
import { Logic } from './logic';
import { MonitoredObjectFleetType, Transport, TransportState } from 'generated/backend-api';
import { DATE_FORMAT } from 'domain-constants';
import { Subject } from 'rxjs';
import { DateRange } from 'common/model/date-time';
import { ReadOnlyMonitoredObjectFeSb } from 'generated/new-main';

export class SchedulingRouteOverviewLogic {
    data: TransportModel[];
    rawData: Transport[];
    vehicleStates: VehicleStateObject[];
    monitoredObjects: ReadOnlyMonitoredObjectFeSb[];

    private _addressIdentification?: AddressIdentification;
    private _vehicleIdentification?: VehicleIdentification;

    onData = new Subject<TransportModel[]>();
    onLoading = new Subject<boolean>();
    onError = new Subject<any>();

    constructor(private _logic: Logic) {
        this.vehicleStates = [];
        this.monitoredObjects = [];
        this.data = [];
        this.rawData = [];
        this._addressIdentification = _logic.settings().getProp('addressIdentification');
        this._vehicleIdentification = _logic.settings().getProp('vehicleIdentification');
    }

    settings(): {
        drivers: string[];
        vehicles: string[];
        transportState: string[];
    } {
        const settings = this._logic.settings().getProp('scheduling').routeOverview.filter;
        const filter = {
            drivers: settings.drivers,
            vehicles: settings.vehicles,
            transportState: settings.transportState
        };
        return filter;
    }

    setSettings(settings: { drivers?: string[]; vehicles?: string[]; transportState?: string[] }) {
        const originalSettings = this._logic.settings().getProp('scheduling').routeOverview.filter;
        const modifiedSettings = {
            drivers: settings.drivers ?? originalSettings.drivers,
            vehicles: settings.vehicles ?? originalSettings.vehicles,
            transportState: settings.transportState ?? originalSettings.transportState
        };

        this._logic.settings().setProp('scheduling', {
            routeOverview: {
                filter: modifiedSettings
            }
        });
    }

    async init() {
        if (this._logic.demo().isActive) {
            this._initDemoMode();
        } else {
            await this._init();
        }
    }

    private _initDemoMode() {
        this._addressIdentification = this._logic.settings().getProp('addressIdentification');
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');
        this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, false, undefined, false, false)
            .then(monitoredObjects => (this.monitoredObjects = monitoredObjects));
        this.vehicleStates = this._logic.demo().data.vehicleStates;
        this.data = this._logic.demo().data.transports.map(t => this._logic.transportLogic().toTransport(t));
        this.onData?.next(this.data);
    }

    private async _init() {
        this._addressIdentification = this._logic.settings().getProp('addressIdentification');
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');

        await this._logic
            .vehicles()
            .getMonitoredObjectFilters(false, false, undefined, false, false)
            .then(monitoredObjects => (this.monitoredObjects = monitoredObjects));

        this._logic
            .vehiclesState()
            .getData(this._logic.notification().device!)
            .then(async vehicleStates => {
                this.vehicleStates = vehicleStates;
            })
            .catch(err => {
                this.onLoading.next(false);
                this.onError.next(err);
            });

        this._logic.settings().onChange(prop => {
            if (prop.vehicleIdentification) {
                this._addressIdentification = prop.addressIdentification ?? this._addressIdentification;
                this._vehicleIdentification = prop.vehicleIdentification ?? this._vehicleIdentification;
                this.data = this.rawData.map(t => this._logic.transportLogic().toTransport(t));
                this.onData.next?.(this.data);
            }
        });
    }

    vehicleByRn(rn: string) {
        return this.vehicleStates.find(v => v.rn === rn);
    }

    getData(filter: { drivers?: string[]; vehicles?: string[]; transportState?: string[]; dateRange: DateRange }) {
        if (this._logic.demo().isActive) {
            this.data = this._logic.demo().data.transports.map(t => this._logic.transportLogic().toTransport(t));
            this.vehicleStates = this._logic.demo().data.vehicleStates;
            this.onData?.next(this.data);
        } else {
            this.onLoading.next(true);
            this._logic
                .vehiclesState()
                .getData(this._logic.notification().device!)
                .then(async vehicleStates => {
                    this.vehicleStates = vehicleStates;
                    this._fetchTransports(filter)
                        .then(data => {
                            this.data = data;
                            this.onData.next(data);
                            this.onLoading.next(false);
                        })
                        .catch(err => {
                            this.onLoading.next(false);
                            this.onError.next(err);
                        });
                })
                .catch(err => {
                    this.onLoading.next(false);
                    this.onError.next(err);
                });
        }
    }

    async loadAvailableVehiclesForTransport(id: string): Promise<VehicleWithAvailability[]> {
        try {
            const t = this.data.find(d => d.id === id);
            if (t) {
                const data = await this._logic.transportLogic().availableVehiclesFromTime(t.firstPlaceRta ?? '');
                return data ?? [];
            } else {
                return [];
            }
        } catch (err) {
            console.error('Available vehicles for transport err', err);
            throw err;
        }
    }

    removeVehicleFromTransport(transportId: string) {
        const transport = this.rawData.find(t => t.id === transportId);
        if (
            transport?.state &&
            ![TransportState.Accepted, TransportState.New, TransportState.Planned].includes(transport.state)
        ) {
            throw new Error(`Can not remove vehicle from transport with state: ${transport?.state}`);
        }
        try {
            return this._logic.api().transportApi.updateV1TransportsTransportIdPatch({
                transport: {
                    ...transport,
                    state: TransportState.New,
                    monitoredObjects: transport?.monitoredObjects?.filter(
                        mo => mo.type === MonitoredObjectFleetType.Trailer
                    )
                },
                transportId: transportId
            });
        } catch (err) {
            console.error('Unable to remove vehicle from transport, err:', err);
            throw err;
        }
    }

    addVehicleToTransport(vehicleId: string, selectedTransport: string, state: TransportState) {
        try {
            return this._logic
                .api()
                .transportApi.attachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost({
                    transportId: selectedTransport,
                    monitoredObjectType: MonitoredObjectFleetType.Vehicle,
                    bodyAttachMonitoredObjectV1TransportsTransportIdAttachMonitoredObjectPost: {
                        monitoredObject: Number(vehicleId),
                        start: new Date(),
                        state
                    }
                });
        } catch (err) {
            console.error('Cannot attach vehicle to transport', err);
            throw err;
        }
    }

    removeTransport(id: string): Promise<boolean> {
        return this._set_TransportState({
            id,
            state: TransportState.Rejected
        });
    }

    setTransportComplete(id: string) {
        return this._set_TransportState({
            id,
            state: TransportState.Finished
        });
    }

    setTransportActive(id: string) {
        return this._set_TransportState({
            id,
            state: TransportState.Active
        });
    }

    private async _fetchTransports(filter: {
        drivers?: string[];
        vehicles?: string[];
        transportState?: string[];
        dateRange: DateRange;
    }): Promise<TransportModel[]> {
        try {
            let transports: Transport[] = [];
            if (filter.transportState && filter.transportState?.length > 0) {
                transports = await this._logic.api().transportApi.findV1TransportsGet({
                    states: filter.transportState as TransportState[],
                    drivers: filter.drivers ? filter.drivers.map(d => Number(d)) : undefined,
                    vehicles: filter.drivers ? filter.drivers.map(d => Number(d)) : undefined,
                    fromDate: moment(filter.dateRange.start, DATE_FORMAT).toDate(),
                    toDate: moment(filter.dateRange.end, DATE_FORMAT).endOf('day').toDate(),
                    excludeFields: ['route'],
                    onlyCurrentVehicles: true
                });
            }

            this.rawData = transports;
            return this.rawData.map(t => this._logic.transportLogic().toTransport(t));
        } catch (err) {
            console.error('Get transports err', err);
            throw err;
        }
    }

    private async _set_TransportState({ id, state }: { id: string; state: TransportState }): Promise<boolean> {
        try {
            const transport = this.rawData.find(t => t.id === id);
            delete transport?.id;

            if (!transport) {
                throw new Error('no transport found');
            }

            await this._logic.api().transportApi.updateV1TransportsTransportIdPatch({
                transportId: id,
                transport: { ...transport, state }
            });
            return true;
        } catch (err) {
            console.error('Set transport state err', err);
            throw err;
        }
    }
}
