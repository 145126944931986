import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { Row, Col } from 'antd';

import { roundToStep } from 'common/utils/averages';
import { Table, Rate, Loading, Progress, Sorter } from 'common/components';
import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';
import qa from 'qa-selectors';
import { userCircle } from 'resources/images/common';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';

interface Props {
    data: DriverBehaviorLightVehicleModel[];
    loading: boolean;
    onRowClick?: (id: string) => void;
}

export default function DriverBehaviorLightTable(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="driver-behavior-table">
            {props.loading ? (
                <Loading />
            ) : (
                <Table<DriverBehaviorLightVehicleModel>
                    scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                    columns={[
                        {
                            title: t('DriverBehaviorLightTable.cols.rank'),
                            key: 'rank',
                            dataIndex: 'rank',
                            width: 60,
                            align: 'center',
                            render: value => <div data-qa={qa.driverBehaviour.light.table.fieldRank}>{value}</div>
                        },
                        {
                            title: t('DriverBehaviorLightTable.cols.name'),
                            key: 'name',
                            dataIndex: 'name',
                            align: 'left',
                            width: 150,
                            render: value => (
                                <Row align="middle" gutter={16}>
                                    <Col>
                                        <Avatar icon={<img src={userCircle} alt="user" />} />
                                    </Col>
                                    <Col>
                                        <span data-qa={qa.driverBehaviour.light.table.fieldName}>{value}</span>
                                    </Col>
                                </Row>
                            )
                        },
                        {
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'score');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.light.table.headingScore}>
                                            {t('DriverBehaviorTable.cols.score')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            key: 'score',
                            dataIndex: 'score',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => a.score - b.score,
                            render: value => (
                                <Row justify="space-between" align="middle">
                                    <Col
                                        data-qa={qa.driverBehaviour.light.table.fieldScore}
                                        className="driver-behavior-table-score"
                                    >
                                        <Rate size="default" value={roundToStep(value, 0.5)} disabled allowHalf />
                                    </Col>
                                    <Col>
                                        <span>{roundToStep(value, 0.1)}</span>
                                    </Col>
                                </Row>
                            )
                        },
                        {
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'driveScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.light.table.headingEconomy}>
                                            {t('DriverBehaviorLightTable.cols.economyScore')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            key: 'harshEventsScore',
                            dataIndex: 'harshEventsScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => a.harshEventsScore - b.harshEventsScore,
                            render: value => (
                                <Row align="middle" gutter={8}>
                                    <Col flex="auto">
                                        <Progress
                                            percent={value * 20}
                                            showInfo={false}
                                            strokeWidth={11}
                                            status="normal"
                                            color="blue"
                                        />
                                    </Col>
                                    <Col data-qa={qa.driverBehaviour.light.table.fieldEconomy} flex="50px">
                                        {roundToStep(value, 0.1)}
                                    </Col>
                                </Row>
                            )
                        },
                        {
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'ecologyScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.light.table.headingEcology}>
                                            {t('DriverBehaviorLightTable.cols.ecologyScore')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            key: 'ecologyScore',
                            dataIndex: 'ecologyScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => a.ecologyScore - b.ecologyScore,
                            render: value => (
                                <Row align="middle" gutter={8}>
                                    <Col flex="auto">
                                        <Progress
                                            percent={value * 20}
                                            showInfo={false}
                                            strokeWidth={11}
                                            status="normal"
                                            color="green"
                                        />
                                    </Col>
                                    <Col data-qa={qa.driverBehaviour.light.table.fieldEcology} flex="50px">
                                        {roundToStep(value, 0.1)}
                                    </Col>
                                </Row>
                            )
                        },
                        {
                            title: ({ sortColumns }) => {
                                const sortedColumn = sortColumns?.find(({ column }) => column.key === 'ecologyScore');
                                return (
                                    <Row justify="space-between" align="middle">
                                        <Col data-qa={qa.driverBehaviour.light.table.headingSafety}>
                                            {t('DriverBehaviorLightTable.cols.safetyScore')}
                                        </Col>
                                        <Col>
                                            <Sorter order={sortedColumn?.order ?? undefined} />
                                        </Col>
                                    </Row>
                                );
                            },
                            key: 'safetyScore',
                            dataIndex: 'safetyScore',
                            align: 'center',
                            width: 130,
                            sorter: (a, b) => a.safetyScore - b.safetyScore,
                            render: value => (
                                <Row align="middle" gutter={8}>
                                    <Col flex="auto">
                                        <Progress
                                            percent={value * 20}
                                            showInfo={false}
                                            strokeWidth={11}
                                            status="normal"
                                            color="yellow"
                                        />
                                    </Col>
                                    <Col data-qa={qa.driverBehaviour.light.table.fieldSafety} flex="50px">
                                        {roundToStep(value, 0.1)}
                                    </Col>
                                </Row>
                            )
                        }
                    ]}
                    dataSource={props.data}
                    onRow={record => ({
                        onClick: () => props.onRowClick?.(record.id.toString())
                    })}
                />
            )}
        </div>
    );
}
