/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyMobileApp
 */
export interface ReadOnlyMobileApp {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileApp
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMobileApp
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileApp
     */
    link: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyMobileApp
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileApp
     */
    packageName: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileApp
     */
    appName: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMobileApp
     */
    versionName: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMobileApp
     */
    versionCode: number;
}

export function ReadOnlyMobileAppFromJSON(json: any): ReadOnlyMobileApp {
    return ReadOnlyMobileAppFromJSONTyped(json, false);
}

export function ReadOnlyMobileAppFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMobileApp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'link': json['link'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'packageName': json['package_name'],
        'appName': json['app_name'],
        'versionName': json['version_name'],
        'versionCode': json['version_code'],
    };
}

export function ReadOnlyMobileAppToJSON(value?: ReadOnlyMobileApp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'link': value.link,
        'package_name': value.packageName,
        'app_name': value.appName,
        'version_name': value.versionName,
        'version_code': value.versionCode,
    };
}


