/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Count,
    CountFromJSON,
    CountToJSON,
    GDPRDryRun,
    GDPRDryRunFromJSON,
    GDPRDryRunToJSON,
    ReadOnlyClientTachographCardReader,
    ReadOnlyClientTachographCardReaderFromJSON,
    ReadOnlyClientTachographCardReaderToJSON,
    WriteOnlyClientTachographCardReader,
    WriteOnlyClientTachographCardReaderFromJSON,
    WriteOnlyClientTachographCardReaderToJSON,
} from '../models';

export interface ClientTachographCardReaderCreateRequest {
    data: WriteOnlyClientTachographCardReader;
}

export interface ClientTachographCardReaderDeleteRequest {
    id: number;
}

export interface ClientTachographCardReaderListRequest {
    client?: string;
    activeToIsnull?: boolean;
}

export interface ClientTachographCardReaderPartialUpdateRequest {
    id: number;
    data: WriteOnlyClientTachographCardReader;
}

export interface ClientTachographCardReaderReadRequest {
    id: number;
}

export interface ClientTachographCardReaderTachoCardReaderAnonymizeRequest {
    data: GDPRDryRun;
}

export interface ClientTachographCardReaderTachoCardReaderCountRequest {
    client?: string;
    activeToIsnull?: boolean;
}

export interface ClientTachographCardReaderTachoCardReaderGetRequest {
    client?: string;
    activeToIsnull?: boolean;
}

export interface ClientTachographCardReaderUpdateRequest {
    id: number;
    data: WriteOnlyClientTachographCardReader;
}

/**
 * no description
 */
export class ClientTachographCardReaderApi extends runtime.BaseAPI {

    /**
     */
    async clientTachographCardReaderCreateRaw(requestParameters: ClientTachographCardReaderCreateRequest): Promise<runtime.ApiResponse<ReadOnlyClientTachographCardReader>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientTachographCardReaderCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientTachographCardReaderToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientTachographCardReaderFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderCreate(requestParameters: ClientTachographCardReaderCreateRequest): Promise<ReadOnlyClientTachographCardReader> {
        const response = await this.clientTachographCardReaderCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderDeleteRaw(requestParameters: ClientTachographCardReaderDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientTachographCardReaderDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientTachographCardReaderDelete(requestParameters: ClientTachographCardReaderDeleteRequest): Promise<void> {
        await this.clientTachographCardReaderDeleteRaw(requestParameters);
    }

    /**
     */
    async clientTachographCardReaderListRaw(requestParameters: ClientTachographCardReaderListRequest): Promise<runtime.ApiResponse<Array<ReadOnlyClientTachographCardReader>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.activeToIsnull !== undefined) {
            queryParameters['active_to__isnull'] = requestParameters.activeToIsnull;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyClientTachographCardReaderFromJSON));
    }

    /**
     */
    async clientTachographCardReaderList(requestParameters: ClientTachographCardReaderListRequest): Promise<Array<ReadOnlyClientTachographCardReader>> {
        const response = await this.clientTachographCardReaderListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderPartialUpdateRaw(requestParameters: ClientTachographCardReaderPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClientTachographCardReader>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientTachographCardReaderPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientTachographCardReaderPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientTachographCardReaderToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientTachographCardReaderFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderPartialUpdate(requestParameters: ClientTachographCardReaderPartialUpdateRequest): Promise<ReadOnlyClientTachographCardReader> {
        const response = await this.clientTachographCardReaderPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderReadRaw(requestParameters: ClientTachographCardReaderReadRequest): Promise<runtime.ApiResponse<ReadOnlyClientTachographCardReader>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientTachographCardReaderRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientTachographCardReaderFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderRead(requestParameters: ClientTachographCardReaderReadRequest): Promise<ReadOnlyClientTachographCardReader> {
        const response = await this.clientTachographCardReaderReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderAnonymizeRaw(requestParameters: ClientTachographCardReaderTachoCardReaderAnonymizeRequest): Promise<runtime.ApiResponse<Count>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientTachographCardReaderTachoCardReaderAnonymize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/GDPR-anonymize/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GDPRDryRunToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderAnonymize(requestParameters: ClientTachographCardReaderTachoCardReaderAnonymizeRequest): Promise<Count> {
        const response = await this.clientTachographCardReaderTachoCardReaderAnonymizeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderCountRaw(requestParameters: ClientTachographCardReaderTachoCardReaderCountRequest): Promise<runtime.ApiResponse<Count>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.activeToIsnull !== undefined) {
            queryParameters['active_to__isnull'] = requestParameters.activeToIsnull;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/GDPR-count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderCount(requestParameters: ClientTachographCardReaderTachoCardReaderCountRequest): Promise<Count> {
        const response = await this.clientTachographCardReaderTachoCardReaderCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderGetRaw(requestParameters: ClientTachographCardReaderTachoCardReaderGetRequest): Promise<runtime.ApiResponse<Array<ReadOnlyClientTachographCardReader>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.activeToIsnull !== undefined) {
            queryParameters['active_to__isnull'] = requestParameters.activeToIsnull;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/GDPR-get/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyClientTachographCardReaderFromJSON));
    }

    /**
     */
    async clientTachographCardReaderTachoCardReaderGet(requestParameters: ClientTachographCardReaderTachoCardReaderGetRequest): Promise<Array<ReadOnlyClientTachographCardReader>> {
        const response = await this.clientTachographCardReaderTachoCardReaderGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientTachographCardReaderUpdateRaw(requestParameters: ClientTachographCardReaderUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClientTachographCardReader>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientTachographCardReaderUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientTachographCardReaderUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-tachograph-card-reader/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientTachographCardReaderToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientTachographCardReaderFromJSON(jsonValue));
    }

    /**
     */
    async clientTachographCardReaderUpdate(requestParameters: ClientTachographCardReaderUpdateRequest): Promise<ReadOnlyClientTachographCardReader> {
        const response = await this.clientTachographCardReaderUpdateRaw(requestParameters);
        return await response.value();
    }

}
