import { AddressIdentification } from 'common/components/Settings';
import * as TransportIcons from 'resources/images/transport';
import {
    GoodsWithAddress,
    PuescValidationErrors,
    TrailerWithAvailability,
    VehicleProfileModel,
    VehicleWithAvailability
} from 'common/model/transports';
import { toAddress } from 'common/utils/address';
import {
    BorderCrossTwoDirections,
    Goods,
    Measurement,
    MonitoredObjectFleetType,
    PricePerKmResult,
    RouteCostsDetails,
    TollCostByCountry,
    Transport,
    TransportCrossingPoint,
    TransportEventRule,
    TransportEventRuleConfig,
    TransportPlace,
    TransportPlaceTask,
    TransportPlaceTaskType,
    TransportTemplateInDb,
    TransportTollCost,
    TripInDatabase
} from 'generated/backend-api/models';
import {
    AddressStructured,
    PlaceSuggestionSource,
    PlaceType,
    TransportState,
    VehicleStateObject
} from 'generated/graphql';
import {
    ReadOnlyContactList,
    ReadOnlyContactListTypeEnum,
    ReadOnlyCountry,
    ReadOnlyExternalSystemSecret,
    ReadOnlyVehicleProfileSerializer,
    ReadOnlyProfileSerializer,
    WriteOnlyContactListTypeEnum
} from 'generated/new-main/models';
import { Logic } from 'logic/logic';
import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import {
    COLD_CHAIN_PROFILE_ID,
    DEFAULT_PAGE_OFFSET,
    INFINITY_PAGE_LIMIT,
    M3,
    MAX_ZOOM,
    T,
    TASK_PLACE_TIME_DEFAULT,
    TASK_PLACE_TIME_REFUELING,
    ZONE_RULE_NAME
} from 'domain-constants';
import { LatLng } from 'common/model/geo';
import { Role } from 'logic/auth';
import { Subject } from 'rxjs';
import { PlannerLogicDefinition } from './planner-logic-definition';
import { AvailableCurrencies } from 'common/model/currency';
import { PlaceResult } from 'logic/geocoding';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import {
    latLngFromGeoJsonPointType,
    latLngFromGeoJsonPolygonType,
    toGeoJsonPointTypeInput,
    toGeoJsonPolygonTypeInput
} from 'common/utils/geo-utils';
import { debounce } from 'debounce';
import { AddressStructuredType } from 'generated/geocoding-api/models';
import { defaultFuelServiceFilter, defaultFuelsFilter, Fuels, Services } from 'modules/map/MapModule';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';
import { RouteSelectData } from '../places-autocomplete/PlacesAutocompleteModule';
import {
    RoutingEmissionStandard,
    PlannedTransport,
    PlannedTransportPlacePosition,
    RouteType,
    InputVehicleProfile as VehicleProfileRoutingApi,
    TollCostRequestBody,
    InaccessiblePointError
} from 'generated/routing-api/models';
import { EmissionClass } from 'modules/management/modules/fleet/FleetModule';
import { alarmsByPlace } from 'common/demo/alarms';
import { ContactListListTypeOverlapEnum } from 'generated/new-main';
import { ContactList } from 'common/model/client-contact';
import { exchangePriceCurrency } from 'common/utils/best-price';
import { TransportTemplateFindV1TransportsTemplateGetProjectionEnum } from 'generated/backend-api';
import * as uuid from 'uuid';
import { ColdchainProfileData } from 'common/forms/ColdchainProfileForm';
import { roundToDecimals } from 'common/utils/averages';
import { trailersVehicleStateObjectIds } from 'common/utils/vehicleState';
import { ExternalDeviceType } from 'generated/backend-api-live';
import { ColdchainTemperatureSensorModel } from 'modules/statistics/modules/coldchain/coldchain-logic';

export interface VehicleProfile {
    id?: number;
    name?: string | null;
    isDefault?: boolean;
    euroClass?: string | null;
    numberOfAxles?: number | null;
    weight?: number | null;
    pricePerKm?: number;
    height?: number | null;
    trailersCount?: number | null;
    width?: number | null;
    length?: number | null;
    tunnel?: string | null;
    client?: number;
}

export enum PlanRouteErrorName {
    InaccessiblePointError = 'InaccessiblePointError',
    Unknown = 'Unknown'
}

export type PlanRoutError = InaccessiblePointError;

export enum TransportType {
    shortest = 'shortest',
    planned = 'planned',
    fastest = 'fastest',
    balanced = 'balanced'
}

export enum AvoidOptionsSygicType {
    country = 'country',
    highways = 'highways',
    ferries = 'ferries'
}
export interface TransportTemplateList {
    id: string;
    name: string;
}

const poiRadius = 200; // radius for polygon default create
const defaultTransportType: TransportType = TransportType.balanced;
const trailerOverlapVehicleLength = 3.6; // meters

export class PlannerLogic extends PlannerLogicDefinition {
    onPlanRouteError: Subject<PlanRoutError>;
    onVehicleSet: Subject<VehicleStateObject>;
    onRemovePlaceFromTransport: Subject<TransportPlace>;
    version: number;
    @observable lockedMonitoredObjectId?: number;
    @observable initTransportLoading: boolean;
    @observable generateRouteLoading?: boolean;
    @observable createRouteLoading?: boolean;
    @observable calculatingRouteLoading?: boolean;
    @observable tollCostLoading?: boolean;
    @observable tollCostByType?: {
        [key in RouteType]?: TransportTollCost;
    };
    @observable transport: Transport;

    @observable availableTrailers: TrailerWithAvailability[];
    @observable availableTrailersLoading?: boolean;
    @observable availableVehicles: VehicleWithAvailability[];
    @observable availableVehiclesLoading?: boolean;
    @observable vehiclesPricePerKmLoading?: boolean;
    @observable availableVehicleProfiles: VehicleProfile[];
    @observable availableVehicleProfilesLoading?: boolean;
    @observable availableColdchainProfiles: ColdchainProfileData[];
    @observable availableColdchainProfilesLoading?: boolean;
    @observable showColdchainUpdateConfirm?: boolean;
    @observable availableTransportTemplates: TransportTemplateList[];
    @observable availableTransportTemplatesLoading?: boolean;
    @observable saveTransportTemplate?: boolean;
    @observable clientContactList: ReadOnlyContactList[];
    @observable clientContactListLoading?: boolean;
    @observable clientList: ReadOnlyContactList[];
    @observable consigneeList: ReadOnlyContactList[];
    @observable countryList: ReadOnlyCountry[];
    @observable countryListLoading?: boolean;
    @observable routeModified: boolean;

    @observable puescSecret?: ReadOnlyExternalSystemSecret;
    @observable externalSystemVehicleIds?: number[];
    @observable externalSystemConnected: boolean;
    @observable externalSystemLoading?: boolean;

    @observable selectedPlaceId: string;
    @observable selectedTaskId: string;

    @observable transportInPoland: boolean;
    @observable defaultPuescActive?: boolean;

    @observable plCrosses?: BorderCrossTwoDirections[];
    @observable plCrossesLoading?: boolean;

    @observable puescValidationErrors?: PuescValidationErrors;
    @observable possibleTransports: PlannedTransport[];
    @observable selectedTransportType: TransportType;
    @observable puescNoTrailer?: boolean;
    @observable defaultMonitoredObjectId?: number;
    @observable transportPlacesHash?: string;

    protected _customPlacesRta: Set<string>;
    protected _trips: TripInDatabase[];

    protected _onMarkerDragEndInterval?: NodeJS.Timeout;
    protected _debounceUpdatePlaceActiveIds: string[] = [];
    protected _tmpWayPointPlaceId?: string;
    protected _newPointMarkerAllowed: boolean;

    protected _fuelStations: PoiModelMap[];
    protected _parkings: PoiModelMap[];
    protected _pois: PoiModelMap[];
    protected _washers: PoiModelMap[];
    protected _fuelStationsLoadedWhileRender: boolean;
    protected _parkingsLoadedWhileRender: boolean;
    protected _poisLoadedWhileRender: boolean;
    protected _washersLoadedWhileRender: boolean;
    protected _fuelDataFilter: Fuels = defaultFuelsFilter;
    protected _serviceDataFilter: Services = defaultFuelServiceFilter;
    protected _useLocaleStorage: boolean = true;
    protected _lang: string;
    public pricePerKm: PricePerKmResult | undefined = undefined;
    public routeOptionsCollapsedCountries: string[] = [];

    constructor(private _logic: Logic) {
        super();
        this.version = _logic.conf.settings.plannerOldAvailable ? 1 : 2;
        this.onPlanRouteError = new Subject<PlanRoutError>();
        this.onVehicleSet = new Subject<VehicleStateObject>();
        this.onRemovePlaceFromTransport = new Subject<TransportPlace>();
        this.initTransportLoading = false;
        this.availableTrailers = [];
        this.availableVehicles = [];
        this.availableVehicleProfiles = [];
        this.availableColdchainProfiles = [];
        this.availableTransportTemplates = [];
        this.clientContactList = [];
        this.clientList = [];
        this.consigneeList = [];
        this.countryList = [];
        this.transportInPoland = false;
        this.externalSystemConnected = false;
        this.selectedPlaceId = '';
        this.selectedTaskId = '';
        this.transport = {
            monitoredObjects: [],
            state: TransportState.New,
            places: [],
            users: []
        };
        this.possibleTransports = [];
        this.selectedTransportType = defaultTransportType;

        this._customPlacesRta = new Set();
        this._trips = [];

        this._fuelStations = [];
        this._parkings = [];
        this._pois = [];
        this._washers = [];
        this._newPointMarkerAllowed = true;
        this._fuelStationsLoadedWhileRender = true;
        this._parkingsLoadedWhileRender = true;
        this._poisLoadedWhileRender = true;
        this._washersLoadedWhileRender = true;
        this.routeModified = false;
        this._lang = this._logic.settings().getProp('lang') ?? '';

        makeObservable(this);
    }

    get useTrafficMode() {
        return this._logic.conf.api.routing.trafficMode;
    }

    get tollCostEnable() {
        return this._logic.auth().roles().includes(Role.TLC_R) && this._logic.conf.api.routing.service === 'sygic';
    }

    get isPuescTransport() {
        if (this.transportInPoland && this.externalSystemConnected && this.transport.puesc) {
            return true;
        }
        return false;
    }

    get isPuescTransportPossible() {
        if (this.transportInPoland && this.externalSystemConnected) {
            return true;
        }
        return false;
    }

    get selectedTrailerId() {
        return this.transport.monitoredObjects?.find(mo => mo.type === MonitoredObjectFleetType.Trailer && !mo.endTime)
            ?.monitoredObjectId;
    }

    get selectedVehicleIsInPuesc() {
        return this.selectedVehicleId && this.externalSystemVehicleIds?.includes(this.selectedVehicleId);
    }

    get selectedVehicleId() {
        return this.transport.monitoredObjects?.find(mo => mo.type !== MonitoredObjectFleetType.Trailer && !mo.endTime)
            ?.monitoredObjectId;
    }

    get selectedProfileId() {
        return this.transport.desiredVehicleProfile;
    }

    get selectedPlace() {
        return this.transport.places?.find(p => p.id === this.selectedPlaceId);
    }

    get selectedPlaceDisabled() {
        return this.getPlaceDisabled(this.selectedPlace);
    }

    get selectedTask() {
        return this.transport.places
            ?.find(p => p.id === this.selectedPlaceId)
            ?.tasks?.find(task => task.id === this.selectedTaskId);
    }

    get goodsBySelectedTask() {
        const task = this.selectedTask;
        if (!task) return [];
        const goods = this._getTransportGoods();

        const loadingGoods = task.loadingGoods ?? [];
        const unloadingIds = task.unloadingGoods ?? [];
        return loadingGoods.concat(
            unloadingIds.reduce<Goods[]>((currGoods, accId) => {
                const item = goods.find(g => g.id === accId);
                if (item) {
                    currGoods.push(item);
                }

                return currGoods;
            }, [] as Goods[])
        );
    }

    get goodsWithAddressBySelectedPlace(): GoodsWithAddress[] {
        const selectedPlace = this.selectedPlace;
        const unloadingAddress = {};
        const loadingGoods: GoodsWithAddress[] = [];
        this.transport.places?.forEach((place, placeIndex) => {
            if (place?.tasks && place.tasks.length > 0) {
                const placeAddress = toAddress(
                    this._logic.auth().user().lang,
                    this._logic.auth().client()!,
                    place.addressStructured,
                    AddressIdentification.Address,
                    'N/A'
                );

                place.tasks.forEach(task => {
                    if (selectedPlace?.id === place.id) {
                        task.loadingGoods?.forEach(goods => {
                            loadingGoods.push({
                                ...goods,
                                loading: {
                                    placeIndex,
                                    placeId: place.id ?? '',
                                    address: placeAddress
                                },
                                unloading: undefined
                            });
                        });
                    }

                    task.unloadingGoods?.forEach(unloadingId => {
                        unloadingAddress[unloadingId] = {
                            address: placeAddress,
                            placeId: place.id ?? ''
                        };
                    });
                });
            }
        });

        return loadingGoods.map(goods => {
            return {
                ...goods,
                unloading: unloadingAddress[goods.id]
            };
        });
    }

    get goodsWithAddress(): GoodsWithAddress[] {
        const unloadingAddress = {};
        const loadingGoods: GoodsWithAddress[] = [];
        this.transport.places?.forEach((place, placeIndex) => {
            if (place.tasks && place.tasks.length > 0) {
                const placeAddress = toAddress(
                    this._logic.auth().user().lang,
                    this._logic.auth().client()!,
                    place.addressStructured,
                    AddressIdentification.Address,
                    'N/A'
                );

                place.tasks.forEach(task => {
                    task.loadingGoods?.forEach(goods => {
                        loadingGoods.push({
                            ...goods,
                            loading: {
                                placeIndex,
                                placeId: place.id ?? '',
                                address: placeAddress
                            },
                            unloading: undefined
                        });
                    });

                    task.unloadingGoods?.forEach(unloadingId => {
                        unloadingAddress[unloadingId] = {
                            address: placeAddress,
                            placeId: place.id ?? ''
                        };
                    });
                });
            }
        });

        return loadingGoods.map(goods => {
            return {
                ...goods,
                unloading: unloadingAddress[goods.id]
            };
        });
    }

    get addressBySelectedPlace() {
        const place = this.selectedPlace;
        return place?.addressStructured;
    }

    get alarmsBySelectedPlace() {
        if (this._logic.demo().isActive) {
            return this.selectedTask?.type ? alarmsByPlace[this.selectedTask.type] : [];
        }
        const place = this.selectedPlace;
        return place?.eventRules;
    }

    get driverNoteBySelectedTask() {
        const task = this.selectedTask;
        return task?.action;
    }

    get clientContactBySelectedTask() {
        const task = this.selectedTask;
        return this.clientContactList.find(c => c.id === task?.contactId);
    }

    get hasAlarmRight() {
        return this._logic.auth().roles().includes(Role.IA_R);
    }

    @action
    setFuelDataFilter(fuel: Fuels) {
        this._fuelDataFilter = fuel;
    }

    @action
    setServiceDataFilter(service: Services) {
        this._serviceDataFilter = service;
    }

    @action
    setOmitParamsFromRouting(omit: boolean) {
        this.transport.omitRouteParams = omit;
        this._updateTransportInLocalStorage();
    }

    @action
    setInitTransportLoading(loading: boolean) {
        this.initTransportLoading = loading;
    }

    @action
    setFixedTransportName(value: boolean) {
        this.transport.fixedName = value;
    }

    @action
    async init(
        useLocaleStorage: boolean,
        planningMode: 'edit' | 'preview',
        transportId?: string,
        transport?: Transport,
        monitoredObjectId?: number,
        version?: number
    ) {
        this.puescValidationErrors = undefined;
        this.initTransportLoading = true;
        this.selectedTransportType = TransportType.balanced;
        this._useLocaleStorage = useLocaleStorage;
        this._newPointMarkerAllowed = true;
        this.version = version ?? 1;

        try {
            await this.initMap(planningMode);
            await this._fetchPricePerKm();
            const planner = this._logic.settings().getProp('planner');
            const transportRefresh =
                planner?.transport?.id === transportId && this._logic.settings().getProp('lang') !== this._lang;
            this._lang = this._logic.settings().getProp('lang');

            if (transportId && !transportRefresh) {
                const transport = await this._logic.transportLogic().fetchTransport(transportId);
                runInAction(() => {
                    this.transport = {
                        ...transport,
                        places: transport.places?.map(place => ({
                            ...place,
                            center: latLngFromGeoJsonPointType(place.center ?? {}),
                            polygon: place.polygon ? latLngFromGeoJsonPolygonType(place.polygon) : undefined
                        }))
                    };
                    this.transformTransport(this.transport, this.version);
                    this.calculateTollCost(this.transport);
                });
                this.selectedTransportType = TransportType.balanced;
                this.defaultMonitoredObjectId = this.selectedVehicleId;
                this._setPlannedPossibleTransport();
            } else if (transport) {
                this.transport = transport;
                this.selectedTransportType = TransportType.balanced;
                this.defaultMonitoredObjectId = this.selectedVehicleId;
                this._setPlannedPossibleTransport();
            } else if (monitoredObjectId && !transportRefresh) {
                this.lockedMonitoredObjectId = monitoredObjectId;

                const vehicle = await this._logic.vehiclesState().vehicle(monitoredObjectId.toString());
                this.addPlaceToTransport(
                    toAddress(
                        this._logic.auth().user().lang,
                        this._logic.auth().client()!,
                        vehicle?.address_structured,
                        AddressIdentification.CodeAndAddress,
                        vehicle?.address ?? ''
                    ),
                    {
                        lat: vehicle?.gpsData?.lat ?? 0,
                        lng: vehicle?.gpsData?.lon ?? 0
                    } as LatLng,
                    PlaceType.Waypoint,
                    0
                );
                if (vehicle?.monitoredObjectId) {
                    this.setMonitoredObjectOnTransport(
                        Number(vehicle.monitoredObjectId),
                        MonitoredObjectFleetType.Vehicle
                    );
                    const trailers = trailersVehicleStateObjectIds(
                        [ExternalDeviceType.TrailerId, ExternalDeviceType.TrailerManual],
                        vehicle
                    );

                    if (trailers.length > 0) {
                        trailers.forEach(trailer => {
                            if (
                                this.availableTrailers.find(
                                    availableTrailer => trailer === String(availableTrailer.data?.id)
                                )?.available
                            ) {
                                this.setMonitoredObjectOnTransport(Number(trailer), MonitoredObjectFleetType.Trailer);
                            }
                        });
                    }
                }
                this.transformTransport(this.transport, this.version);
            } else {
                this.defaultPuescActive = planner.defaultPuescActive;
                const plannedTransport = planner.possibleTransports?.some(
                    transport => (transport.type as string) === TransportType.balanced
                );
                if (planner.transport) {
                    this.transport = {
                        ...planner.transport,
                        id: planner.transport.id === transportId ? planner.transport.id : undefined,
                        places: planner.transport.places?.map(place => ({
                            ...place,
                            ata: place.ata ? new Date(place.ata) : undefined,
                            atd: place.atd ? new Date(place.atd) : undefined,
                            eta: place.eta ? new Date(place.eta) : undefined,
                            etaComputedAt: place.etaComputedAt ? new Date(place.etaComputedAt) : undefined,
                            etd: place.etd ? new Date(place.etd) : undefined,
                            resolvedByDriverAt: place.resolvedByDriverAt
                                ? new Date(place.resolvedByDriverAt)
                                : undefined,
                            rta: place.rta ? new Date(place.rta) : undefined,
                            rtd: place.rtd ? new Date(place.rtd) : undefined
                        })),
                        monitoredObjects: planner.transport.monitoredObjects?.map(mo => ({
                            ...mo,
                            endTime: mo.endTime ? new Date(mo.endTime) : undefined,
                            startTime: mo.startTime ? new Date(mo.startTime) : undefined
                        })),
                        firstRta: planner.transport.places?.[0].rta ? planner.transport.places?.[0].rta : undefined,
                        lastRta: planner.transport.places?.[planner.transport.places.length - 1].rta
                            ? planner.transport.places?.[planner.transport.places.length - 1].rta
                            : undefined,
                        lastUpdated: planner.transport.lastUpdated ? new Date(planner.transport.lastUpdated) : undefined
                    };
                    this.transformTransport(this.transport, this.version);
                    this.calculateTollCost(this.transport);
                }
                if (plannedTransport) {
                    this.selectedTransportType = TransportType.balanced;
                    this._setPlannedPossibleTransport();
                } else {
                    this.selectedTransportType = TransportType.balanced;
                    this.possibleTransports = planner.possibleTransports ?? [];
                    this.tollCostByType = planner.tollCostByType;
                }
            }
        } catch (err) {
            console.error(`Could not load transport, err: ${err}`);
        }

        this.setDefaultProfile(this.transport);
        if (this.transport.puesc) {
            this.setPuescNoTrailer(true);
        }
        this._fetchAvailableTrailers().then(() => {
            this.validateTrailer(this.transport);
        });
        this._fetchAvailableVehicles().then(() => {
            const vehicle = this.availableVehicles?.find(
                vehicle => Number(vehicle.data.monitoredObjectId) === this.selectedVehicleId
            );

            if (vehicle) {
                this.setMonitoredObjectOnTransport(
                    Number(vehicle.data.monitoredObjectId),
                    MonitoredObjectFleetType.Vehicle
                );
                this._logic.map().routing().setVehicle(this._logic.map().vehicles().toVehicleMap(vehicle.data));
                this._logic.map().routing().fitRoute();
            }
        });
        this._fetchVehicleProfiles();
        this.fetchClientContactList();
        this._fetchTransportTemplates();
        this._fetchCountryList();
        this._fetchColdchainProfiles();

        if (planningMode === 'edit') {
            this._fetchExternalSystemData().then(() => {
                this.onRouteChange();
            });

            this.drawRouteOnMap();
        }

        this._logic.poi().fuelStationsLoaded.subscribe(() => {
            if (!this._fuelStationsLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });
        this._logic.poi().parkingsLoaded.subscribe(() => {
            if (!this._parkingsLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });
        this._logic.poi().poisLoaded.subscribe(() => {
            if (!this._poisLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });
        this._logic.poi().washersLoaded.subscribe(() => {
            if (!this._washersLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });

        this.initTransportLoading = false;
    }

    async initMap(planningMode: 'edit' | 'preview' = 'edit') {
        this._logic.map().routing().init(this._logic.map().initialPaddingWithLeftComponent);
        this._logic.map().routePlanningMode(planningMode);

        this._logic.poi().pricesUpdatesSubject.subscribe(fss => {
            this._logic.map().routing().updatePartialFuelStationsData(fss, true, true);
        });

        this._logic
            .map()
            .routing()
            .onMarkerDrag(
                debounce(
                    async (
                        marker: google.maps.Marker,
                        latLng: LatLng,
                        type: 'waypoint' | 'crossingPoint',
                        route?: string,
                        crossingPointBeforeMove?: LatLng
                    ) => {
                        const markerPlaceId = crossingPointBeforeMove
                            ? this._tmpWayPointPlaceId
                            : type === 'crossingPoint'
                            ? marker.get('crossingpoint_id')
                            : marker.get('place_id');
                        this._logic.map().routing().removeMapObjects({
                            fuelStations: true,
                            parkings: true,
                            washers: true,
                            pois: true
                        });
                        const debounceId = this._generateId();
                        this._debounceUpdatePlaceActiveIds.push(debounceId);
                        if (!markerPlaceId || markerPlaceId === '') {
                            this._tmpWayPointPlaceId = this._generateId();
                            const routeIndex = this.transport.places?.findIndex(place => place.route === route) ?? 0;
                            const decodedPolyline = google.maps.geometry.encoding.decodePath(route ?? '');
                            const crossingPoints = this.transport.places?.[routeIndex]?.crossingPoints;
                            const nearestCrossingPointsOnPolyline = crossingPoints?.map(cp => {
                                const nearestPoint = this._logic
                                    .map()
                                    .routing()
                                    .nearestPoint(
                                        { lat: cp.lat, lng: cp.lon },
                                        decodedPolyline?.map(p => [p.lng(), p.lat()])
                                    );
                                return {
                                    lng: nearestPoint?.geometry.coordinates[0],
                                    lat: nearestPoint?.geometry.coordinates[1]
                                };
                            });
                            let crossingPointsIndex = 0;
                            const splittedPolyline: LatLng[][] = decodedPolyline.reduce(
                                (obj, cur) => {
                                    if (!obj?.[0]) {
                                        obj[0] = [];
                                    }
                                    obj[obj.length - 1].push({ lat: cur.lat(), lng: cur.lng() });
                                    if (
                                        cur.lat() === nearestCrossingPointsOnPolyline?.[crossingPointsIndex]?.lat &&
                                        cur.lng() === nearestCrossingPointsOnPolyline?.[crossingPointsIndex]?.lng
                                    ) {
                                        crossingPointsIndex++;
                                        obj[obj.length] = [];
                                    }
                                    return obj;
                                },
                                [[]] as LatLng[][]
                            );
                            crossingPointsIndex = 0;
                            const crossingPointIndex: number =
                                splittedPolyline.findIndex(sp =>
                                    sp.some(
                                        s =>
                                            s.lat === crossingPointBeforeMove?.lat &&
                                            s.lng === crossingPointBeforeMove?.lng
                                    )
                                ) ?? -1;

                            const crossingPointId =
                                this.transport.places?.[routeIndex ?? 0]?.id + '__' + crossingPointIndex;
                            this._tmpWayPointPlaceId = crossingPointId;
                            try {
                                await this.addPlaceCrossingPointToTransport(crossingPointId, latLng);
                            } finally {
                                this._debounceUpdatePlaceActiveIds = this._debounceUpdatePlaceActiveIds.filter(
                                    d => d !== debounceId
                                );
                            }
                        } else {
                            try {
                                if (type === 'waypoint') {
                                    await this.updatePlaceLatLong(markerPlaceId, latLng, true);
                                } else {
                                    await this.updateCrossingPointLatLong(markerPlaceId, latLng, true);
                                }
                            } finally {
                                this._debounceUpdatePlaceActiveIds = this._debounceUpdatePlaceActiveIds.filter(
                                    d => d !== debounceId
                                );
                            }
                        }
                    },
                    1000
                )
            );

        this._logic
            .map()
            .routing()
            .onMarkerDragEnd((markerId: string, latLng: LatLng, type: 'waypoint' | 'crossingPoint') => {
                // we need to wait for updates from onDrag function

                // if previous dragging is still in progress we need to cancel it
                if (this._onMarkerDragEndInterval) {
                    clearInterval(this._onMarkerDragEndInterval);
                    this._onMarkerDragEndInterval = undefined;
                }

                this._onMarkerDragEndInterval = setInterval(() => {
                    if (this._debounceUpdatePlaceActiveIds.length === 0) {
                        if (!markerId) {
                            markerId = this._tmpWayPointPlaceId ?? '';
                        }
                        if (markerId) {
                            this._logic.map().routing().removeTmpWayPointMarker();
                            if (type === 'waypoint') {
                                this.updatePlaceLatLong(markerId, latLng);
                            } else {
                                this.updateCrossingPointLatLong(markerId, latLng);
                            }
                            if (this._onMarkerDragEndInterval) {
                                clearInterval(this._onMarkerDragEndInterval);
                                this._onMarkerDragEndInterval = undefined;
                            }
                            this._tmpWayPointPlaceId = undefined;
                        }
                    }
                    // bigger timeout than debounce. to be sure all drag function already fired.
                }, 1000);
            });

        this._logic
            .map()
            .routing()
            .onCrossingPlaceMarkerDelete(async (markerId: string, _latLng: LatLng) => {
                const splittedId = markerId.split('__');
                const placeId = splittedId?.[0];
                const crossingPointIndex = splittedId?.[1];

                this.transport = {
                    ...this.transport,
                    places: this.transport.places?.map(p =>
                        p.id === placeId
                            ? {
                                  ...p,
                                  crossingPoints: p.crossingPoints?.filter(
                                      (_cp, index) => index !== Number(crossingPointIndex)
                                  )
                              }
                            : p
                    )
                };

                this._logic.map().routing().resetPlaces();
                await this.planRoute(this.transport);
                await this.drawRouteOnMap();
                this._checkVehicleAvailability();
            });

        const self = this;
        this._logic
            .map()
            .routing()
            .onMarkerMouseover(() => {
                self._logic.map().routing().removeTmpWayPointMarker();
            });

        this._logic
            .map()
            .routing()
            .onPolylineMouseOver((route, latLng) => {
                if (!self._logic.map().polygons().pointInPolygons(latLng) && self._newPointMarkerAllowed) {
                    self._logic.map().routing().renderTmpDraggingPointMarker(latLng, route, true);
                }
            });

        this._logic
            .map()
            .routing()
            .onPolylineMouseMove((_polyline, route, latLng) => {
                if (!self._logic.map().polygons().pointInPolygons(latLng) && self._newPointMarkerAllowed) {
                    self._logic.map().routing().renderTmpDraggingPointMarker(latLng, route);
                } else {
                    self._logic.map().routing().removeTmpWayPointMarker();
                }
            });

        this._logic
            .map()
            .routing()
            .onPolylineMouseOut((_polyline, _route, _latLng) => {
                self._logic.map().routing().removeTmpWayPointMarker();
            });
    }

    @action
    async routing(transport: Transport, transportPlaces?: TransportPlace[]): Promise<PlannedTransport[]> {
        // const transportPlacesHash = this._calculateTransportPlacesHash(transport);
        try {
            let api = undefined;
            switch (this._logic.conf.api?.routing?.service) {
                case 'ptv':
                    api = this._logic.api().routingApi.ptvDirections.bind(this._logic.api().routingApi);
                    break;
                case 'here':
                    api = this._logic.api().routingApi.hereDirections.bind(this._logic.api().routingApi);
                    break;
                default:
                    api = this._logic.api().routingApi.sygicCoreDirections.bind(this._logic.api().routingApi);
            }

            const places = transportPlaces ?? transport.places ?? [];
            return await api({
                routePlannerRequestBody: {
                    wayPoints:
                        places.map(p => {
                            const latLng = p.center ? (p.center as LatLng) : undefined;
                            return {
                                id: p.id ?? '',
                                departure: moment(p.rta).toISOString(),
                                useCustomDeparture: p.fixedRta,
                                lat: this.transformGeoPosition(latLng?.lat ?? -1),
                                lng: this.transformGeoPosition(latLng?.lng ?? -1),
                                name: p.name,
                                crossingPoints: p.crossingPoints?.map(
                                    crossingPoint =>
                                        ({
                                            lat: this.transformGeoPosition(crossingPoint.lat),
                                            lng: this.transformGeoPosition(crossingPoint.lon)
                                        } as PlannedTransportPlacePosition)
                                )
                            };
                        }) ?? [],
                    profile: transport.omitRouteParams ? undefined : this._getRoutingProfile(),
                    useDeparture: this.useTrafficMode,
                    useRealistic: this.useTrafficMode,
                    useToll: this.tollCostEnable,
                    useTollDetails: this.tollCostEnable,
                    avoidOptionsSygic: transport.routeOptionsSygic?.avoidOptionsSygic
                }
            });
        } catch (err: any) {
            console.error(err);
            const unknownError = {
                name: PlanRouteErrorName.Unknown,
                message: err.statusText,
                points: []
            };
            console.log(err);
            if (err.status === 404) {
                if (err.json) {
                    (err.json() as Promise<any>).then(data => {
                        const handleError = () => {
                            if ((data?.name ?? '') === PlanRouteErrorName.InaccessiblePointError) {
                                return data;
                            }

                            return unknownError;
                        };
                        this.onPlanRouteError.next(handleError());
                    });
                    return [];
                } else {
                    this.onPlanRouteError.next(unknownError);
                    return [];
                }
            } else {
                this.onPlanRouteError.next(unknownError);
                return [];
            }
        }
    }

    @action
    async planRoute(transport: Transport, loading: boolean = true): Promise<void> {
        if (loading) {
            this.calculatingRouteLoading = true;
        }
        let transports: PlannedTransport[] = [];
        runInAction(() => {
            this.transportPlacesHash = this._calculateTransportPlacesHash(transport);
        });
        transports = await this.routing(transport);
        this.calculatingRouteLoading = false;
        if ((transports as any).errors || (transports as any).error) {
            this.calculatingRouteLoading = false;
            return;
        }
        this.possibleTransports = transports;
        const currentRoute = (this.possibleTransports || []).find(
            t => (t.type as string) === this.selectedTransportType
        );

        if (currentRoute) {
            if (this.transport.routeOptionsSygic) {
                this.transport.routeOptionsSygic.possibleAvoidsSygic = currentRoute.possibleAvoidsSygic;
            } else {
                this.transport.routeOptionsSygic = {
                    avoidOptionsSygic: [],
                    possibleAvoidsSygic: currentRoute.possibleAvoidsSygic
                };
            }
            this._addRouteToTransport(this.transport, currentRoute);

            const routeWithAetr = await this._logic.api().transportApi.planV1TransportsPlanPost({
                transport: {
                    ...this.transport,
                    places: this.transport.places?.map(place => ({
                        ...place,
                        center: toGeoJsonPointTypeInput((place.center as LatLng) ?? {}),
                        polygon: toGeoJsonPolygonTypeInput((place.polygon as LatLng[][]) ?? {})
                    }))
                }
            });
            this._addAetrToTransport(this.transport, routeWithAetr);

            if (this.transport.routeOptionsSygic) {
                this.transport.routeOptionsSygic.possibleAvoidsSygic = currentRoute.possibleAvoidsSygic;
            } else {
                this.transport.routeOptionsSygic = {
                    avoidOptionsSygic: [],
                    possibleAvoidsSygic: currentRoute.possibleAvoidsSygic
                };
            }
        }

        if (this.tollCostEnable) {
            this._loadTollCost(this.transport);
        }

        this.calculatingRouteLoading = false;

        this._checkVehicleAvailability();
        this.routeModified = true;
    }

    @action
    removePlaceFromTransport(id: string) {
        const place = this.transport.places?.find(p => p.id === id);
        const placeIndex = this.transport.places?.findIndex(p => p.id === id) ?? 0;
        const transportPlaces = this.transport.places?.filter(p => p.id !== id);
        this.transport = {
            ...this.transport,
            places: transportPlaces?.map((p, index) =>
                index === placeIndex - 1 && transportPlaces.length < 2
                    ? { ...p, route: undefined, crossingPoints: [] }
                    : p
            )
        };
        if (transportPlaces?.length === 0) {
            this.reset();
        }
        place && this.onRemovePlaceFromTransport.next(place);
    }

    async addPoiToTransport(poi: PoiMarkerData) {
        const type =
            poi.type === 'parking'
                ? PlaceType.ParkingLot
                : poi.type === 'fuelStation'
                ? PlaceType.FuelStation
                : poi.type === 'wash'
                ? PlaceType.Wash
                : PlaceType.Waypoint;

        return await this.addPlaceToTransport(
            poi.data.name,
            poi.data.position,
            type,
            poi.data.routeIndex !== undefined ? poi.data.routeIndex + 1 : undefined
        );
    }

    async updateRouteForTransport(data: RouteSelectData, placeId: string | undefined, index: number) {
        this.createRouteLoading = true;
        try {
            if (data.type === 'LAT_LNG') {
                const coords = {
                    lat: data.value.lat ?? 0,
                    lng: data.value.lng ?? 0
                };

                await this.addPlaceToTransport(
                    `${data.value.lat}, ${data.value.lng}`,
                    coords,
                    PlaceType.Waypoint,
                    undefined,
                    undefined,
                    undefined,
                    index
                );
            }
            if (data.type === 'GOOGLE' || data.type === 'GOOGLE_GEOCODER') {
                const googleSuggestion = data.value;
                if (googleSuggestion?.place_id) {
                    const result = await this.placeDetail(googleSuggestion.place_id);
                    const coords = {
                        lat: result.geometry?.location?.lat() ?? 0,
                        lng: result.geometry?.location?.lng() ?? 0
                    };

                    if (result.name && result.geometry && result.geometry!.location) {
                        await this.addPlaceToTransport(
                            result.formatted_address ?? result.name ?? '',
                            coords,
                            PlaceType.Waypoint,
                            undefined,
                            undefined,
                            undefined,
                            index
                        );
                    }
                }
            } else if (data.type === 'EUROWAG') {
                const eurowagSuggestion = data.value;
                if (eurowagSuggestion?.position?.coordinates) {
                    if (eurowagSuggestion.position) {
                        await this.addPlaceToTransport(
                            eurowagSuggestion.label || '',
                            latLngFromGeoJsonPointType(eurowagSuggestion.position),
                            eurowagSuggestion.source === PlaceSuggestionSource.FuelStations
                                ? PlaceType.FuelStation
                                : eurowagSuggestion.source === PlaceSuggestionSource.ParkingLots
                                ? PlaceType.ParkingLot
                                : eurowagSuggestion.source === PlaceSuggestionSource.CustomPlaces
                                ? PlaceType.CustomPlace
                                : PlaceType.Waypoint,
                            undefined,
                            eurowagSuggestion.polygon
                                ? latLngFromGeoJsonPolygonType(eurowagSuggestion.polygon)
                                : undefined,
                            undefined,
                            index
                        );
                    }
                }
            }
        } catch (err) {
            console.error(`Could not update route to transport, err: ${err}`);
            throw err;
        }
        this.createRouteLoading = false;
    }

    async createRouteForTransport(data: RouteSelectData) {
        this.createRouteLoading = true;
        try {
            if (data.type === 'LAT_LNG') {
                const coords = {
                    lat: data.value.lat ?? 0,
                    lng: data.value.lng ?? 0
                };

                await this.addPlaceToTransport(`${data.value.lat}, ${data.value.lng}`, coords, PlaceType.Waypoint);
            }
            if (data.type === 'GOOGLE' || data.type === 'GOOGLE_GEOCODER') {
                const googleSuggestion = data.value;
                if (googleSuggestion?.place_id) {
                    const result = await this.placeDetail(googleSuggestion.place_id);
                    const coords = {
                        lat: result.geometry?.location?.lat() ?? 0,
                        lng: result.geometry?.location?.lng() ?? 0
                    };

                    if (result.name && result.geometry && result.geometry!.location) {
                        await this.addPlaceToTransport(
                            result.formatted_address ?? result.name ?? '',
                            coords,
                            PlaceType.Waypoint
                        );
                    }
                }
            } else if (data.type === 'EUROWAG') {
                const eurowagSuggestion = data.value;
                if (eurowagSuggestion?.position?.coordinates) {
                    if (eurowagSuggestion.position) {
                        await this.addPlaceToTransport(
                            eurowagSuggestion.label || '',
                            latLngFromGeoJsonPointType(eurowagSuggestion.position),
                            eurowagSuggestion.source === PlaceSuggestionSource.FuelStations
                                ? PlaceType.FuelStation
                                : eurowagSuggestion.source === PlaceSuggestionSource.ParkingLots
                                ? PlaceType.ParkingLot
                                : eurowagSuggestion.source === PlaceSuggestionSource.CustomPlaces
                                ? PlaceType.CustomPlace
                                : PlaceType.Waypoint,
                            undefined,
                            eurowagSuggestion.polygon
                                ? latLngFromGeoJsonPolygonType(eurowagSuggestion.polygon)
                                : undefined
                        );
                    }
                }
            }
        } catch (err) {
            console.error(`Could not add route to transport, err: ${err}`);
            throw err;
        }
        this.createRouteLoading = false;
    }

    @action
    async addPlaceToTransport(
        name: string,
        coordinates: LatLng,
        type: PlaceType,
        index?: number,
        polygon?: LatLng[][],
        id?: string,
        changeIndex?: number
    ): Promise<string> {
        const places = this.transport.places;

        const addressStructured = await this._logic.geocoding().geocodeBatch(coordinates.lat, coordinates.lng);

        const placeholder: TransportPlace = {
            id: id ?? this._generateId(),
            name: name,
            center: {
                lat: roundToDecimals(coordinates.lat, 5),
                lng: roundToDecimals(coordinates.lng, 5)
            },
            polygon: polygon ?? [
                this._logic.map().poi().getCoordinatesFromPoint(coordinates.lat, coordinates.lng, poiRadius)
            ],
            crossingPoints: [],
            type: type,
            addressStructured,
            tasks:
                type === PlaceType.FuelStation || type === PlaceType.ParkingLot || type === PlaceType.Wash
                    ? [
                          {
                              id: 'placeType',
                              action: '',
                              type:
                                  type === PlaceType.FuelStation
                                      ? TransportPlaceTaskType.Refueling
                                      : type === PlaceType.ParkingLot
                                      ? TransportPlaceTaskType.Parking
                                      : type === PlaceType.Wash
                                      ? TransportPlaceTaskType.Washing
                                      : undefined,
                              plannedStart: this.version === 2 ? moment().toDate() : undefined,
                              plannedEnd:
                                  this.version === 2
                                      ? moment()
                                            .add(
                                                type === PlaceType.FuelStation
                                                    ? TASK_PLACE_TIME_REFUELING
                                                    : TASK_PLACE_TIME_DEFAULT,
                                                'seconds'
                                            )
                                            .toDate()
                                      : undefined
                          }
                      ]
                    : [],
            ...(places?.length === 0 ? { rta: new Date() } : {})
        };

        runInAction(() => {
            if (changeIndex !== undefined) {
                if (this.transport?.places?.[changeIndex]) {
                    this.transport.places[changeIndex] = placeholder;
                }
            } else {
                const insert = (arr: TransportPlace[], index: number, ...newItems: TransportPlace[]) => [
                    ...arr.slice(0, index),
                    ...newItems,
                    ...arr.slice(index)
                ];

                this.transport = {
                    ...this.transport,
                    places:
                        places && places?.length > 0
                            ? insert(places, Number(index !== undefined ? index : places.length), placeholder)
                            : [placeholder]
                };
            }
        });

        return placeholder.id ?? '';
    }

    @action
    async addPlaceCrossingPointToTransport(markerId: string, coordinates: LatLng): Promise<void> {
        const splittedId = markerId.split('__');
        const placeId = splittedId?.[0];
        const crossingPointIndex = splittedId?.[1];

        const insert = (arr: TransportCrossingPoint[], index: number, ...newItems: TransportCrossingPoint[]) => [
            ...arr.slice(0, index),
            ...newItems,
            ...arr.slice(index)
        ];

        this.transport = {
            ...this.transport,
            places: this.transport.places?.map(p => {
                if (p.id === placeId) {
                    const crossingPoints = p.crossingPoints;
                    return {
                        ...p,
                        crossingPoints:
                            crossingPoints && crossingPoints?.length > 0
                                ? insert(crossingPoints, Number(crossingPointIndex), {
                                      lat: coordinates.lat,
                                      lon: coordinates.lng
                                  })
                                : [{ lat: coordinates.lat, lon: coordinates.lng }]
                    };
                } else {
                    return p;
                }
            })
        };

        this._logic.map().routing().resetPlaces();
        await this.planRoute(this.transport);
        await this.drawRouteOnMap(false, false);
        this._checkVehicleAvailability();
    }

    @action
    async updateCrossingPointLatLong(markerId: string, coordinates: LatLng, partialUpdate = false): Promise<void> {
        const splittedId = markerId.split('__');
        if (splittedId.length > 1) {
            const placeId = splittedId?.[0];
            const crossingPointIndex = splittedId?.[1];

            this.transport = {
                ...this.transport,
                places: this.transport.places?.map(p =>
                    p.id === placeId
                        ? {
                              ...p,
                              crossingPoints: p.crossingPoints?.map((cp, index) =>
                                  index === Number(crossingPointIndex)
                                      ? { lat: coordinates.lat, lon: coordinates.lng }
                                      : cp
                              )
                          }
                        : p
                )
            };

            if (!partialUpdate) {
                this._logic.map().routing().resetPlaces();
                await this.planRoute(this.transport);
                await this.drawRouteOnMap(false, false);
            } else {
                const changedPlaceIndex = this.transport.places?.findIndex(p => p.id === placeId);
                if (changedPlaceIndex !== undefined) {
                    const placesToCalculate =
                        changedPlaceIndex === 0
                            ? this.transport.places?.slice(0, 2)
                            : changedPlaceIndex === this.transport.places?.length
                            ? this.transport.places?.slice(
                                  this.transport.places.length - 2,
                                  this.transport.places.length
                              )
                            : this.transport.places?.slice(changedPlaceIndex - 1, changedPlaceIndex + 2);
                    if (placesToCalculate) await this._planPartialRoute(placesToCalculate);
                }
            }

            this._checkVehicleAvailability();
        } else {
            this._logic.map().routing().resetPlaces();
            await this.planRoute(this.transport);
            await this.drawRouteOnMap();
        }
    }

    @action
    async updatePlaceLatLong(id: string, coordinates: LatLng, partialUpdate = false): Promise<void> {
        let addressStructured: AddressStructuredType[] = [];
        if (!partialUpdate) {
            addressStructured = await this._logic.geocoding().geocodeBatch(coordinates.lat, coordinates.lng);
        }

        this.transport = {
            ...this.transport,
            places: this.transport.places?.map(p =>
                p.id === id
                    ? {
                          ...p,
                          name: '',
                          center: {
                              lat: coordinates.lat,
                              lng: coordinates.lng,
                              coordinates: { lat: coordinates.lat, lng: coordinates.lng }
                          },
                          addressStructured: partialUpdate ? p.addressStructured : addressStructured,
                          polygon: [
                              this._logic
                                  .map()
                                  .poi()
                                  .getCoordinatesFromPoint(coordinates.lat, coordinates.lng, poiRadius)
                          ],
                          type: PlaceType.Waypoint,
                          tasks: []
                      }
                    : p
            )
        };

        if (!partialUpdate) {
            this._logic.map().routing().resetPlaces();
            await this.planRoute(this.transport);
            await this.drawRouteOnMap(false, false);
        } else {
            const changedPlaceIndex = this.transport.places?.findIndex(p => p.id === id);
            if (changedPlaceIndex !== undefined) {
                const placesToCalculate =
                    changedPlaceIndex === 0
                        ? this.transport.places?.slice(0, 2)
                        : changedPlaceIndex === this.transport.places?.length
                        ? this.transport.places?.slice(this.transport.places.length - 2, this.transport.places.length)
                        : this.transport.places?.slice(changedPlaceIndex - 1, changedPlaceIndex + 2);
                if (placesToCalculate) await this._planPartialRoute(placesToCalculate);
            }
        }

        this._checkVehicleAvailability();
    }

    @action
    async saveSelectedTask(task: TransportPlaceTask, clientContact?: ContactList, config?: TransportEventRule[]) {
        if (clientContact) {
            const foundContact = this.clientContactList.find(c => c.name === clientContact.name);
            try {
                if (foundContact?.id) {
                    const contact = await this.updateClientContact(foundContact.id, clientContact);
                    runInAction(() => {
                        task.contactId = contact.id;
                    });
                } else {
                    const contact = await this.createClientContact(clientContact);
                    runInAction(() => {
                        task.contactId = contact.id;
                    });
                }
            } catch (err) {
                console.error(`could not create/update client contact list, err: ${err}`);
            }
        }

        const selectedPlace = this.selectedPlace;
        const selectedTask = this.selectedTask;

        this._removeNotExistingUnloadedGoods();

        if (!selectedPlace) throw new Error('can not find selected place');
        if (selectedTask && selectedPlace.tasks) {
            selectedPlace.tasks[selectedPlace.tasks?.findIndex(task => task.id === selectedTask.id)] = task;
        } else {
            if (selectedPlace.tasks === undefined) {
                selectedPlace.tasks = [task];
            } else {
                selectedPlace.tasks.push(task);
            }
        }
        if (config && this.hasAlarmRight) {
            selectedPlace.eventRules = config;
        }

        this._updateTransportInLocalStorage();
    }

    @action
    removeSelectedTask() {
        const selectedPlace = this.selectedPlace;
        const selectedTask = this.selectedTask;
        if (!selectedPlace) throw new Error('can not find selected place');
        if (!selectedTask) throw new Error('can not find selected task');
        selectedPlace.tasks = selectedPlace.tasks?.filter(task => task.id !== selectedTask.id);
        this._removeNotExistingUnloadedGoods();
    }

    @action
    setClientContactId(clientId?: string) {
        this.transport.customerContactId = clientId;
        this._updateTransportInLocalStorage();
    }

    @action
    async updateTransportMonitoredObjectAndReroute(monitoredObjectId: number, type: MonitoredObjectFleetType) {
        const prevProfile = this.availableVehicleProfiles.find(p => p.id === this.transport.desiredVehicleProfile);
        const prevVehicle = this.availableVehicles?.find(
            v => v.data.monitoredObjectId === this.selectedVehicleId?.toString()
        )?.fleetModel;
        const prevVehicleProfile: VehicleProfile = {
            euroClass: prevVehicle?.emissionClass,
            height: prevVehicle?.height,
            numberOfAxles: prevVehicle?.numberOfAxles,
            trailersCount: prevVehicle?.trailersCount,
            tunnel: prevVehicle?.tunnel,
            weight: prevVehicle?.weight,
            width: prevVehicle?.width
        };
        const currentVehicle = this.availableVehicles.find(
            vehicle => vehicle.data.monitoredObjectId === monitoredObjectId.toString()
        )?.fleetModel;
        const currentVehicleProfile = {
            euroClass: currentVehicle?.emissionClass,
            height: currentVehicle?.height,
            numberOfAxles: currentVehicle?.numberOfAxles,
            trailersCount: currentVehicle?.trailersCount,
            tunnel: currentVehicle?.tunnel,
            weight: currentVehicle?.weight,
            width: currentVehicle?.width
        };
        const shouldReroute = !this._compareProfiles(currentVehicleProfile, prevProfile ?? prevVehicleProfile);

        this.setMonitoredObjectOnTransport(monitoredObjectId, type);
        const trailers = trailersVehicleStateObjectIds(
            [ExternalDeviceType.TrailerId, ExternalDeviceType.TrailerManual],
            await this._logic.vehiclesState().vehicle(String(monitoredObjectId))
        );
        if (trailers.length > 0) {
            trailers?.forEach(trailer => {
                if (this.availableTrailers.find(t => t.data?.id === Number(trailer))?.available) {
                    this.setMonitoredObjectOnTransport(Number(trailer), MonitoredObjectFleetType.Trailer);
                }
            });
        }
        this.removeTransportDesiredProfileId();

        // we want to reroute only if profiles have diff values
        if (shouldReroute) {
            await this.planRoute(this.transport);
            await this.drawRouteOnMap();
        }
    }

    @action
    setMonitoredObjectOnTransport(monitoredObjectId: number, type: MonitoredObjectFleetType) {
        const newMonitoredObject = {
            startTime: moment.utc().toDate(),
            endTime: undefined,
            monitoredObjectId: monitoredObjectId,
            primary: false,
            type
        };

        if (!this.transport.monitoredObjects) {
            this.transport.monitoredObjects = [];
        }
        this.removeMonitoredObjectFromTransport(type);
        this.transport.monitoredObjects.push(newMonitoredObject);
        if ([MonitoredObjectFleetType.Vehicle, MonitoredObjectFleetType.LightVehicle].indexOf(type) > -1) {
            this.transport.desiredVehicleProfile = undefined;
        }
        this._updateTransportInLocalStorage();
        const vehicle = this.availableVehicles?.find(v => Number(v.data.monitoredObjectId) === this.selectedVehicleId);
        this._logic.map().routing().setVehicle();
        if (vehicle?.data.gpsData?.lat && vehicle?.data.gpsData?.lon) {
            this._logic.map().routing().setVehicle(this._logic.map().vehicles().toVehicleMap(vehicle.data));
            this._logic.map().routing().fitRoute();

            this.onVehicleSet.next(vehicle.data);
        }
    }

    @action
    async removeMonitoredObjectOrProfileAndReroute() {
        this.removeMonitoredObjectFromTransport(MonitoredObjectFleetType.Vehicle);
        this.removeTransportDesiredProfileId();
        this._logic.map().routing().setVehicle(undefined);

        await this.planRoute(this.transport);
        await this.drawRouteOnMap();
    }

    @action
    removeMonitoredObjectFromTransport(type: MonitoredObjectFleetType) {
        this.transport.monitoredObjects = this.transport.monitoredObjects?.filter(mo => mo.type !== type);
        this._updateTransportInLocalStorage();
    }

    @action
    changePlaceRta(placeId: string, value: moment.Moment) {
        this._customPlacesRta.add(placeId);
        const placeIndex = this.transport.places?.findIndex(p => p.id === placeId) ?? 0;

        let newDate = moment(value).toDate();
        for (let i = placeIndex; i < this.transport.places!.length; i++) {
            let newPlace = this.transport.places?.[i]!;
            newPlace.rta = moment(newDate).toDate();
            newPlace.rtd = moment(newPlace.rta).toDate();
            const lastTask =
                (newPlace.tasks ?? []).length > 0 ? newPlace.tasks?.[newPlace.tasks.length - 1].plannedEnd : undefined;

            if (lastTask) {
                newPlace.rtd = moment(lastTask).toDate();
            }
            newPlace.fixedRta = i === placeIndex;
            this.transport.places![i] = newPlace;
            newDate = moment(newPlace.rtd).toDate();
        }

        this._checkVehicleAvailability();
    }

    @action
    changeTransportName(name?: string) {
        this.transport.name = name;
    }

    updateTransportNameByPlaces() {
        const transport = this._logic.plannerLogic().transport;
        if (!transport?.places) {
            this.changeTransportName();
            return;
        }

        if (!transport?.id) {
            this.setRouteNameByPlaces(transport.places);
        }
    }

    setRouteNameByPlaces(places: TransportPlace[]) {
        if (this.transport.fixedName ?? false) {
            return;
        }

        if (places) {
            let transportStartName: string = '';
            let transportEndName: string = '';

            const startAddressStructured = (places?.[0]?.addressStructured ?? []).find(
                address => address.lang === this._logic.settings().getProp('lang')
            );

            if (places?.[0]?.type !== PlaceType.Waypoint || !startAddressStructured) {
                transportStartName = places?.[0]?.name ?? '';
            } else if (startAddressStructured) {
                transportStartName = this.formatRouteNameAddreass(startAddressStructured);
            }

            const lastPlaceIndex = places.length - 1;
            const endAddressStructured = (places?.[lastPlaceIndex]?.addressStructured ?? []).find(
                address => address.lang === this._logic.settings().getProp('lang')
            );
            if (places?.[lastPlaceIndex]?.type !== PlaceType.Waypoint || !endAddressStructured) {
                transportEndName = places?.[lastPlaceIndex]?.name ?? '';
            } else if (endAddressStructured) {
                transportEndName = this.formatRouteNameAddreass(endAddressStructured);
            }

            const transportName =
                places.length < 2 ? transportStartName : `${transportStartName} - ${transportEndName}`;
            this.changeTransportName(transportName);
        }
    }

    formatRouteNameAddreass(structuredAddress: AddressStructured | undefined) {
        if (structuredAddress) {
            const address = [structuredAddress.countryCode, structuredAddress.postalCode, structuredAddress.town];

            return address.join(', ');
        } else {
            return '';
        }
    }

    @action
    setTransportCostPerKm(price: number, currency?: string) {
        this.transport.costPerKm = {
            value: price,
            currency: currency ?? this.transport.costPerKm?.currency ?? AvailableCurrencies.EUR
        };
        this._updateTransportInLocalStorage();
    }

    @action
    async generateRoute() {
        this.generateRouteLoading = true;
        try {
            if (this.transport.places) {
                const placesAddressStructured = await Promise.all(
                    this.transport.places?.map(place => {
                        const latLng = place.center ? (place.center as LatLng) : undefined;
                        return latLng ? this._logic.geocoding().geocodeBatch(latLng?.lat, latLng?.lng) : [];
                    })
                );

                this.transport = {
                    ...this.transport,
                    places: this.transport.places.map((p, i) => ({
                        ...p,
                        addressStructured: placesAddressStructured[i]
                    }))
                };
            }
        } catch (err) {
            console.error(`Could not generate route, err:${err}`);
        }
        this.generateRouteLoading = false;
    }

    @action
    updatePlaceOrder(oldIndex: number, newIndex: number) {
        if (this.transport.places?.[newIndex].eventStates && this.transport.places?.[newIndex].eventStates?.length)
            // toto je co za podmienka ?
            return;

        const places = arrayMove(this.transport.places ?? [], oldIndex, newIndex).map(p => ({
            ...p,
            departureTime: undefined
        }));
        const firstRta = this.transport.places?.[0]?.rta;
        if (oldIndex === 0 || newIndex === 0) {
            places[0].rta = firstRta;
        }
        this.transport = {
            ...this.transport,
            places
        };

        this._checkVehicleAvailability();
    }

    @action
    async updateTransportProfileAndReroute(desiredVehicleProfile: number) {
        const prevProfile = this.availableVehicleProfiles.find(p => p.id === this.transport.desiredVehicleProfile);
        const prevVehicle = this.availableVehicles?.find(
            v => v.data.monitoredObjectId === this.selectedVehicleId?.toString()
        )?.fleetModel;
        const prevVehicleProfile: VehicleProfile = {
            euroClass: prevVehicle?.emissionClass,
            height: prevVehicle?.height,
            numberOfAxles: prevVehicle?.numberOfAxles,
            trailersCount: prevVehicle?.trailersCount,
            tunnel: prevVehicle?.tunnel,
            weight: prevVehicle?.weight,
            width: prevVehicle?.width
        };
        const currentProfile = this.availableVehicleProfiles.find(profile => profile.id === desiredVehicleProfile);
        const shouldReroute = !this._compareProfiles(currentProfile, prevProfile ?? prevVehicleProfile);

        this.setTransportDesiredProfileId(desiredVehicleProfile);
        this.removeMonitoredObjectFromTransport(MonitoredObjectFleetType.Vehicle);
        this._logic.map().routing().setVehicle(undefined);

        // we want to reroute only if profiles have diff values
        if (shouldReroute) {
            await this.planRoute(this.transport);
            await this.drawRouteOnMap();
        }
    }

    @action
    setTransportDesiredProfileId(desiredVehicleProfile?: number) {
        this.transport.desiredVehicleProfile = desiredVehicleProfile;
    }

    @action
    removeTransportDesiredProfileId() {
        this.transport.desiredVehicleProfile = undefined;
    }

    @action
    selectTransportType(type: TransportType) {
        this.selectedTransportType = type;
        const currentRoute = (this.possibleTransports || []).find(
            t => (t.type as string) === this.selectedTransportType
        );

        if (currentRoute) {
            this.transport = {
                ...this.transport,
                firstRta: currentRoute.firstRta ? moment(currentRoute.firstRta).toDate() : undefined,
                lastRta: currentRoute.lastRta ? moment(currentRoute.lastRta).toDate() : undefined,
                tollCost: this.tollCostByType?.[currentRoute.type],
                places: currentRoute.places.map(p => {
                    const currentPlace = this.transport.places?.find(t => t.id === p.id);
                    return {
                        ...currentPlace,
                        id: p.id!,
                        name: p.name,
                        distance: p.distance,
                        duration: p.duration,
                        route: p.route,
                        rta: p.departure ? moment(p.departure).toDate() : undefined,
                        center: { lat: p.position.lat, lng: p.position.lng },
                        polygon: [
                            this._logic.map().poi().getCoordinatesFromPoint(p.position.lat, p.position.lng, poiRadius)
                        ]
                    } as TransportPlace;
                })
            };
        }
    }

    @action
    setPuescNoTrailer(noTrailer: boolean) {
        // just for puesc validation.
        // when user select trailer "noCriteria" or "withoutTrailer" it is the same value,
        // we allow puesc transport without trailer. which has to be selected by user
        this.puescNoTrailer = noTrailer;
    }

    async drawRouteOnMap(fetchLatestTrips?: boolean, fitRoute: boolean = true) {
        if (
            this.selectedVehicleId &&
            this.transport.places?.[0]?.rta &&
            this.transport.places?.[this.transport.places.length - 1]?.rta &&
            fetchLatestTrips
        ) {
            const start = moment
                .min(
                    ...[this.transport.places?.[0]?.rta, this.transport.places?.[0]?.ata]
                        .filter(e => e && moment(e).isValid())
                        .map(e => moment(e))
                )
                .utc()
                .toISOString();

            const end = moment
                .max(
                    ...[
                        this.transport.places?.[this.transport.places.length - 1].rta,
                        this.transport.places?.[this.transport.places.length - 1].ata
                    ]
                        .filter(e => e && moment(e).isValid())
                        .map(e => moment(e))
                )
                .utc()
                .toISOString();

            if (fetchLatestTrips) await this._fetchTrips(this.selectedVehicleId, start, end);
        }

        const places =
            this.transport.places?.map((place, index) => {
                const latLng = place.center ? (place.center as LatLng) : undefined;
                return {
                    id: place.id ?? '',
                    lat: latLng?.lat ?? -1,
                    lng: latLng?.lng ?? -1,
                    disabledDragging: (index === 0 && !!this.lockedMonitoredObjectId) || !!place.ata,
                    crossingPoints: place.crossingPoints
                };
            }) ?? [];

        const routes =
            this.transport.places
                ?.filter(p => p.route)
                .map((p, index) => {
                    return {
                        route: p.route!,
                        disabledDragging: this.transport.places?.[index + 1]?.ata ? true : false
                    };
                }) ?? [];

        const trips = this._trips.reduce<string[]>((a, c) => {
            if (c.metadata?.polyline) {
                a.push(c.metadata.polyline);
            }
            return a;
        }, []);

        this._logic.map().routing().renderRoute(places, routes, trips, fitRoute, false, undefined, MAX_ZOOM);

        this._logic.map().polygons().destroy();
        this.transport.places?.forEach(place => {
            if (place.polygon?.[0]) {
                this._logic
                    .map()
                    .polygons()
                    .addPolygon(place.polygon[0], place.id ?? '');
            }
        });
        this._logic.map().polygons().renderPolygons();
        this.poisAlongRoute(routes.map(r => r.route));
    }

    @action
    onRouteChange() {
        this._transportInPoland();
        if (this.isPuescTransportPossible) {
            this.transport.puesc =
                this.transport.puesc === undefined && this.defaultPuescActive !== undefined
                    ? this.defaultPuescActive
                    : this.transport.puesc;
            this._lookPlCrosses();
        } else {
            this._removePuescData();
        }

        this._updateTransportInLocalStorage();
    }

    @action
    onPuescSwitch(active: boolean) {
        this.transport.puesc = active;
        this.defaultPuescActive = active;

        this._updateTransportInLocalStorage();
    }

    @action
    setSelectedPlaceId(placeId?: string) {
        this.selectedPlaceId = placeId ?? '';
    }

    @action
    setSelectedTaskId(taskId?: string) {
        this.selectedTaskId = taskId ?? '';
    }

    @action
    reset(removeLocaleStorage: boolean = true, resetPlanningMode: boolean = false) {
        this.puescValidationErrors = undefined;
        this.transport = {
            name: undefined,
            monitoredObjects: [],
            desiredVehicleProfile: undefined,
            state: TransportState.New,
            places: [],
            users: [],
            routeOptionsSygic: undefined
        };
        this.transportInPoland = false;
        this.routeModified = false;
        this.lockedMonitoredObjectId = undefined;
        this.selectedTransportType = TransportType.balanced;
        this.saveTransportTemplate = false;
        this.routeOptionsCollapsedCountries = [];
        if (removeLocaleStorage) {
            this.setDefaultProfile(this.transport);
            this.removeLocaleStorage();
        }
        this._fuelStations = [];
        this._parkings = [];
        this._pois = [];
        this._washers = [];
        this._logic.map().routing().destroy();
        this._logic.map().polygons().destroy();
        if (resetPlanningMode) {
            this._newPointMarkerAllowed = false;
            this._logic.map().routePlanningMode();
            this._logic.map().sideBarControlsOffResetState();
        } else {
            this.initMap();
        }
    }

    removeLocaleStorage() {
        const planner = this._logic.settings().getProp('planner');
        this._logic.settings().setProp('planner', {
            ...planner,
            transport: this._logic.conf.settings.planner.transport
        });
    }

    async updateClientContact(id: string, client: ContactList) {
        const clientId = this._logic.auth().client()?.id;
        if (!clientId) {
            throw new Error('No client id in auth');
        }

        const result = await this._logic.clientContactLogic().updateClientContact(id, {
            ...client,
            countryId: client.country?.id ?? undefined,
            client: clientId,
            type: [WriteOnlyContactListTypeEnum.Client]
        });
        this.fetchClientContactList();

        return result;
    }

    async createClientContact(client: ContactList) {
        const clientId = this._logic.auth().client()?.id;
        if (!clientId) {
            throw new Error('No client id in auth');
        }

        const result = await this._logic.clientContactLogic().createClientContact({
            ...client,
            countryId: client.country?.id ?? undefined,
            client: clientId,
            type: [WriteOnlyContactListTypeEnum.Client]
        });
        this.fetchClientContactList();
        return result;
    }

    async createProfile(model: VehicleProfileModel) {
        const result = await this._logic.vehicles().createVehicleProfile(model);
        this._fetchVehicleProfiles();
        return result;
    }

    async deleteProfile(id: number) {
        const result = await this._logic.vehicles().deleteVehicleProfile(id);
        this._fetchVehicleProfiles();
        return result;
    }

    async updateProfile(model: VehicleProfileModel) {
        const result = await this._logic.vehicles().updateVehicleProfile(model);
        this._fetchVehicleProfiles();
        return result;
    }

    async createColdchainProfile(model: ColdchainProfileData) {
        const result = await this._logic.coldchainLogic().createColdchainProfile(model);
        await this._fetchColdchainProfiles();
        return result;
    }

    async updateColdchainProfile(model: ColdchainProfileData) {
        const result = await this._logic.coldchainLogic().updateColdchainProfile(model);
        await this._fetchColdchainProfiles();
        return result;
    }

    async deleteColdchainProfile(id: string) {
        const result = await this._logic.coldchainLogic().deleteColdchainProfile(id);
        await this._fetchColdchainProfiles();
        return result;
    }

    async getColdchainProfile(id: string) {
        const result = await this._logic.coldchainLogic().getColdchainProfile(id);
        return await result;
    }

    getTaskTypeSelectionIcon(taskType?: TransportPlaceTaskType) {
        switch (taskType) {
            case TransportPlaceTaskType.Loading:
                return TransportIcons.placeTypeLoading;
            case TransportPlaceTaskType.Unloading:
                return TransportIcons.placeTypeUnloading;
            case TransportPlaceTaskType.Parking:
                return TransportIcons.placeTypeParking;
            case TransportPlaceTaskType.Refueling:
                return TransportIcons.placeTypeFueling;
            case TransportPlaceTaskType.Washing:
                return TransportIcons.placeTypeWashing;
            default:
                return '';
        }
    }

    placeDetail(id: string): Promise<PlaceResult> {
        return this._logic.geocoding().placesDetail(id, this._logic.geocoding().autocompleteSessionToken());
    }

    @action
    validPuescTransport() {
        const vehicleFilled = () => {
            return !!this.transport.monitoredObjects?.some(
                mo =>
                    mo.type === MonitoredObjectFleetType.Vehicle &&
                    !mo.endTime &&
                    this.externalSystemVehicleIds?.includes(Number(mo.monitoredObjectId))
            );
        };

        const trailerFilled = () => {
            return (
                !!this.transport.monitoredObjects?.some(
                    mo => mo.type === MonitoredObjectFleetType.Trailer && !mo.endTime
                ) || !!this.puescNoTrailer
            );
        };

        const hasLoadingTask = () => {
            return !!this.transport.places?.some(place =>
                place.tasks?.some(task => task.type === TransportPlaceTaskType.Loading)
            );
        };

        const hasUnloadingTask = () => {
            return !!this.transport.places?.some(place =>
                place.tasks?.some(task => task.type === TransportPlaceTaskType.Unloading)
            );
        };

        const allGoodsAreUnloaded = () => {
            return this.goodsWithAddress.every(good => good.unloading?.placeId);
        };

        const minimumGoods = () => {
            return this.goodsWithAddress.length > 0;
        };

        const validation = {
            vehicle: vehicleFilled(),
            trailer: trailerFilled(),
            loadingTask: hasLoadingTask(),
            unloadingTask: hasUnloadingTask(),
            allGoodsUnloaded: allGoodsAreUnloaded(),
            minimumGoods: minimumGoods()
        };

        const isValid = Object.keys(validation).every(key => validation[key]);
        this.puescValidationErrors = isValid ? undefined : validation;
    }

    async saveTransport(): Promise<Transport> {
        const firstDestinationAta =
            this.lockedMonitoredObjectId === this.selectedVehicleId
                ? this.availableVehicles.find(v => Number(v.data.monitoredObjectId) === this.lockedMonitoredObjectId)
                      ?.data.gpsData?.time ?? undefined
                : undefined;
        this.transport.version = this.version;

        this.transformTransport(this.transport, 1);

        if (this.transport.id) {
            return this._updateTransport(this.transport.id, {
                ...this.transport,
                state: this._logic
                    .transportLogic()
                    .getTransportState(
                        this.selectedVehicleId ? true : false,
                        this.transport.places?.[0]?.rta?.toISOString()
                    ),
                places: this.transport.places?.map(place => ({
                    ...place,
                    center: toGeoJsonPointTypeInput((place.center as LatLng) ?? {}),
                    polygon: toGeoJsonPolygonTypeInput((place.polygon as LatLng[][]) ?? {})
                }))
            });
        } else {
            return this._createTransport(
                {
                    ...this.transport,
                    state: this._logic
                        .transportLogic()
                        .getTransportState(
                            this.selectedVehicleId ? true : false,
                            this.transport.places?.[0].rta?.toISOString()
                        ),
                    places: this.transport.places?.map(place => ({
                        ...place,
                        center: toGeoJsonPointTypeInput((place.center as LatLng) ?? {}),
                        polygon: toGeoJsonPolygonTypeInput((place.polygon as LatLng[][]) ?? {})
                    }))
                },
                firstDestinationAta
            );
        }
    }

    setParkingsInDetail(ids: [string]): PoiModelMap[] {
        const parking: PoiModelMap = {
            ...this._parkings.filter(ps => ids.includes(ps.id))[0],
            selected: true
        };

        const parkings: PoiModelMap[][] = this._logic
            .map()
            .routing()
            .parkings()
            .map(ps => ps.map(p => (p.id !== parking.id ? { ...p, selected: false } : parking)));

        this._parkings = parkings.flat().reduce<PoiModelMap[]>((a, c) => {
            if (!a.some(e => e.id === c.id)) {
                a.push(c);
            }
            return a;
        }, []);

        this._logic.map().routing().updateParking(parkings, parking);
        return this._parkings;
    }

    setPoisInDetail(ids: [string]): PoiModelMap[] {
        const poi: PoiModelMap = {
            ...this._pois.filter(ps => ids.includes(ps.id))[0],
            selected: true
        };

        const pois: PoiModelMap[][] = this._logic
            .map()
            .routing()
            .pois()
            .map(ps => ps.map(p => (p.id !== poi.id ? { ...p, selected: false } : poi)));

        this._pois = pois.flat().reduce<PoiModelMap[]>((a, c) => {
            if (!a.some(e => e.id === c.id)) {
                a.push(c);
            }
            return a;
        }, []);

        this._logic.map().routing().updatePoi(pois, poi);
        return this._pois;
    }

    setWashersInDetail(ids: [string]): PoiModelMap[] {
        const poi: PoiModelMap = {
            ...this._washers.filter(w => ids.includes(w.id))[0],
            selected: true
        };

        const washers: PoiModelMap[][] = this._logic
            .map()
            .routing()
            .washers()
            .map(ps => ps.map(p => (p.id !== poi.id ? { ...p, selected: false } : poi)));

        this._washers = washers.flat().reduce<PoiModelMap[]>((a, c) => {
            if (!a.some(e => e.id === c.id)) {
                a.push(c);
            }
            return a;
        }, []);

        this._logic.map().routing().updateWash(washers, poi);
        return this._washers;
    }

    setFuelStationsDetail(id: string) {
        const fuelStation = this._fuelStations.find(f => f.id === id);
        if (!fuelStation) {
            return;
        }

        fuelStation.selected = true;

        const fuelStations: PoiModelMap[][] = this._logic
            .map()
            .routing()
            .fuelStations()
            .map(fs => fs.map(f => (f.id !== fuelStation.id ? { ...f, selected: false } : fuelStation)));

        this._fuelStations = fuelStations.flat().reduce<PoiModelMap[]>((a, c) => {
            if (!a.some(e => e.id === c.id)) {
                a.push(c);
            }
            return a;
        }, []);

        this._logic.map().routing().updateFuelStation(fuelStations, fuelStation);
    }

    _setDefaultProfile(transport: Transport, currency: string): number {
        let price = 0;
        const profile = this.getDefaultVehicleProfile();
        if (profile) {
            transport.desiredVehicleProfile = profile.id;
            if ((profile.pricePerKm ?? 0) > 0) {
                price = this.getPriceByCurrency(profile.pricePerKm ?? 0, currency as AvailableCurrencies);
            }
        }
        return price;
    }

    setDefaultProfile(transport: Transport) {
        if ((transport.monitoredObjects?.length ?? 0) === 0 && !this.selectedVehicleId) {
            let price = transport.costPerKm?.value ?? 0;
            const currency = transport.costPerKm?.currency ?? this.pricePerKm?.currency ?? AvailableCurrencies.EUR;

            if (!transport.desiredVehicleProfile) {
                if (this.version === 2) {
                    price = this._setDefaultProfile(transport, currency);
                }
            } else {
                if (this.availableVehicleProfiles) {
                    const vehicleProfile = this.availableVehicleProfiles.find(
                        p => p.id === transport.desiredVehicleProfile
                    );
                    if (!vehicleProfile) {
                        price = this._setDefaultProfile(transport, currency);
                    }
                    if (price === 0 && vehicleProfile) {
                        if ((vehicleProfile.pricePerKm ?? 0) > 0) {
                            price = this.getPriceByCurrency(
                                vehicleProfile.pricePerKm ?? 0,
                                currency as AvailableCurrencies
                            );
                        }
                    }
                }
            }

            price = this.getComputePricePerKm(price);

            this.setTransportCostPerKm(price, currency);
        }
    }

    getComputePricePerKm = (costPerKm: number): number => {
        if (costPerKm > 0) {
            return costPerKm;
        }
        if (costPerKm === 0) {
            costPerKm = this._logic
                .statisticsCompanyProfile()
                .avgMarketCost(this.pricePerKm, MonitoredObjectFleetType.Vehicle).costPerKm;
        }
        return costPerKm;
    };

    protected async _updateTransport(id: string, transport: Transport): Promise<Transport> {
        const updatedTransport = await this._logic.api().transportApi.updateV1TransportsTransportIdPatch({
            transportId: id,
            transport,
            upsertTemplate: this.saveTransportTemplate
        });
        this.saveTransportTemplate = false;
        this.reset();

        return updatedTransport;
    }

    protected async _createTransport(transport: Transport, firstDestAta?: string) {
        const firstDestinationAta = firstDestAta ? moment(firstDestAta).toDate() : undefined;
        const t = await this._logic.api().transportApi.createV1TransportsPost({
            transport: transport,
            firstDestinationAta,
            upsertTemplate: this.saveTransportTemplate
        });
        this.saveTransportTemplate = false;
        this.reset();
        return t;
    }

    @action
    protected async _fetchAvailableVehicles() {
        this.availableVehiclesLoading = true;
        try {
            const vehicles = await this._logic
                .transportLogic()
                .availableVehiclesFromTime(this.transport.places?.[0]?.rta?.toISOString() ?? moment().toISOString());
            runInAction(() => {
                this.availableVehicles = vehicles;
                this._fetchVehiclesPricePerKm().then(() => {
                    const vehicle = this.availableVehicles?.find(
                        vehicle => Number(vehicle.data.monitoredObjectId) === this.selectedVehicleId
                    );

                    if (vehicle) {
                        this.setTransportCostPerKm(
                            this.getComputePricePerKm(vehicle.profile?.costPerKm ?? 0),
                            vehicle.profile?.currency
                        );
                    }
                });
            });
        } catch (err) {
            console.error('Could not fetch available vehicles');
            throw err;
        } finally {
            runInAction(() => {
                this.availableVehiclesLoading = false;
            });
        }
    }

    @action
    protected async _fetchVehiclesPricePerKm() {
        this.vehiclesPricePerKmLoading = true;
        try {
            const vehicles = await this._logic.transportLogic().pricePerKm(this.availableVehicles);
            runInAction(() => {
                this.availableVehicles = vehicles.map(vehicle => ({
                    ...vehicle,
                    profile: vehicle.profile
                        ? {
                              ...vehicle.profile,
                              costPerKm: this.getComputePricePerKm(vehicle.profile.costPerKm ?? 0)
                          }
                        : undefined
                }));
            });
        } catch (err) {
            console.error('Could not fetch vehicles price per km');
            throw err;
        } finally {
            runInAction(() => {
                this.vehiclesPricePerKmLoading = false;
            });
        }
    }

    @action
    protected async _fetchPricePerKm() {
        try {
            const pricePerKm = await this._logic.statisticsCompanyProfile().pricePerKmCostStructure([]);
            runInAction(() => {
                this.pricePerKm = pricePerKm;
            });
        } catch (err) {
            console.error('Could not fetch price per km');
            throw err;
        }
    }

    @action
    protected async _fetchAvailableTrailers() {
        this.availableTrailersLoading = true;
        try {
            let trailers = await this._logic
                .transportLogic()
                .availableTrailersFromTime(this.transport.places?.[0]?.rta?.toISOString() ?? moment().toISOString());
            if (trailers) {
                const monitoredObjects = await this._logic.vehicles().vehiclesData([]);
                const sensors = await this._fetchTemperatureSensors();

                trailers = trailers?.map(t => ({
                    available: t.available,
                    data: t.data,
                    profile: monitoredObjects.find(
                        monitoredObject => String(monitoredObject.id) === String(t?.data?.id ?? -1)
                    )?.metadata?.['trailerProfile'],
                    sensors: sensors.filter(s => s.monitoredObject === t.data?.id)
                }));
            }
            runInAction(() => {
                this.availableTrailers = trailers;
            });
        } catch (err) {
            console.error('Could not fetch available trailers');
            throw err;
        } finally {
            runInAction(() => {
                this.availableTrailersLoading = false;
            });
        }
    }

    private async _fetchTemperatureSensors(): Promise<ColdchainTemperatureSensorModel[]> {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.temperatureSensors;
        }

        try {
            const res = await this._logic.api().coldchainApi.coldChainTemperatureSensorList({
                monitoredObjectIn: undefined,
                textSearch: ''
            });
            return res;
        } catch (err) {
            console.error(`Failed to fetch coldchain - temperature sensor, err: ${err}`);
            throw err;
        }
    }

    @action
    protected async _fetchColdchainProfiles(): Promise<void> {
        if (this._logic.demo().isActive) {
            this.availableColdchainProfiles = []; // this._logic.demo().data.vehicleProfiles;
        } else {
            this.availableColdchainProfilesLoading = true;
            try {
                const response = await this._logic.api().coldchainApi.coldChainProfileList({});
                runInAction(() => {
                    this.availableColdchainProfiles = response.map(cp => this.profileToColdchainProfile(cp));
                });
            } catch (err) {
                console.error(`Could not fetch cold chain profiles, err: ${err}`);
            } finally {
                runInAction(() => {
                    this.availableColdchainProfilesLoading = false;
                });
            }
        }
    }

    @action
    protected async _fetchVehicleProfiles(): Promise<void> {
        this.availableVehicleProfilesLoading = true;
        if (this._logic.demo().isActive) {
            this.availableVehicleProfiles = this._logic.demo().data.vehicleProfiles;
        } else {
            try {
                const response = await this._logic
                    .api()
                    .vehicleProfileApi.vehicleProfileList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
                runInAction(() => {
                    this.availableVehicleProfiles =
                        response.results
                            .map(vp => this._toVehicleProfile(vp))
                            .sort(
                                (a: VehicleProfile, b: VehicleProfile) =>
                                    (a?.name ?? '').localeCompare(b?.name ?? '') ||
                                    (a?.name ?? '').localeCompare(b?.name ?? '')
                            ) ?? [];

                    this.setDefaultProfile(this.transport);
                });
            } catch (err) {
                console.error(`Could not fetch vehicle profiles, err: ${err}`);
            }
        }
        runInAction(() => {
            this.availableVehicleProfilesLoading = false;
        });
    }

    protected _toVehicleProfile(res: ReadOnlyVehicleProfileSerializer): VehicleProfile {
        return {
            id: res.id,
            name: res.name,
            euroClass: res.euroClass,
            numberOfAxles: res.numberOfAxles,
            weight: res.weight,
            pricePerKm: res.pricePerKm,
            height: res.height,
            trailersCount: res.trailersCount,
            width: res.width,
            length: res.length,
            tunnel: res.tunnel,
            isDefault: res.isDefault
        };
    }

    @action
    public async fetchClientContactList() {
        if (this._logic.demo().isActive) {
            this.clientContactList = this._logic
                .demo()
                .data.contactList.filter(client => client.type?.includes(ReadOnlyContactListTypeEnum.Client));
            return;
        }

        this.clientContactListLoading = true;
        try {
            const contactList = await this._logic
                .clientContactLogic()
                .getAllClientContactListByType([
                    ContactListListTypeOverlapEnum.Client,
                    ContactListListTypeOverlapEnum.Consignee
                ]);
            runInAction(() => {
                this.clientContactList = contactList;

                this.clientList = contactList.filter((e: ReadOnlyContactList) =>
                    e.type.includes(ReadOnlyContactListTypeEnum.Client)
                );
                this.consigneeList = contactList.filter((e: ReadOnlyContactList) =>
                    e.type.includes(ReadOnlyContactListTypeEnum.Consignee)
                );
            });
        } catch (err) {
            console.error('Could not fetch client contact list');
            throw err;
        } finally {
            runInAction(() => {
                this.clientContactListLoading = false;
            });
        }
    }

    @action
    protected async _fetchCountryList() {
        this.countryListLoading = true;
        try {
            const response = await this._logic
                .api()
                .countryApi.countryList({ limit: INFINITY_PAGE_LIMIT, offset: DEFAULT_PAGE_OFFSET });
            runInAction(() => {
                this.countryList = response.results;
            });
        } catch (err) {
            console.error(`could not get country list, err :${err}`);
            throw err;
        } finally {
            runInAction(() => {
                this.countryListLoading = false;
            });
        }
    }

    @action
    protected async _fetchPlCrosses() {
        this.plCrossesLoading = true;
        try {
            this.plCrosses = await this._logic.borderCrosses().borderCrosses(true);
        } catch (err) {
            console.error(`Could not get pl crosses err: ${err}`);
        }
        this.plCrossesLoading = false;
    }

    @action
    protected async _fetchExternalSystemData() {
        this.externalSystemLoading = true;
        try {
            const data = await this._logic.externalSystem().loadData();
            const secret = data.secrets.find(s => s.externalSystemAccess?.externalSystemName === 'PUESC');
            this.externalSystemVehicleIds = secret?.monitoredObjectGroup?.monitoredObjects;
            this.externalSystemConnected = !!secret;
        } catch (err) {
            console.error(`Could not fetch external system, err: ${err}`);
        }
        this.externalSystemLoading = false;
    }

    @action
    protected async _fetchTrips(vehicleId: number, startTime: string, endTime: string) {
        if (this._logic.demo().isActive) {
            this._trips = await this._logic
                .demo()
                .data.trips.filter(v => v.monitoredObjectId && v.monitoredObjectId === vehicleId.toString());
        } else {
            try {
                const tripsResponse = await this._logic.api().tripApi.getTripsApiV2TripsGet({
                    dateFrom: moment(startTime).toDate(),
                    dateTo: moment(endTime).toDate(),
                    monitoredObjectId: vehicleId.toString(),
                    wrapArray: true,
                    sort: 1
                });
                this._trips = tripsResponse.trips
                    .filter(r => r)
                    .filter(v => v.monitoredObjectId && v.monitoredObjectId === vehicleId.toString());
            } catch (err) {
                console.error(`Could not fetch tripe, err: ${err}`);
            }
        }
    }

    @action
    protected async _fetchTransportTemplates(): Promise<void> {
        this.availableTransportTemplatesLoading = true;
        if (this._logic.demo().isActive) {
            this.availableTransportTemplates = this._logic.demo().data.transportTemplates.map(tt => {
                return {
                    id: tt.id,
                    name: tt.transport.name
                } as TransportTemplateList;
            });
        } else {
            try {
                const response = await this._logic
                    .api()
                    .transportTemplateApi.transportTemplateFindV1TransportsTemplateGet({
                        projection: [TransportTemplateFindV1TransportsTemplateGetProjectionEnum.Name]
                    });
                runInAction(() => {
                    this.availableTransportTemplates =
                        response.map(tt => {
                            return {
                                id: tt.id,
                                name: tt.transport.name
                            } as TransportTemplateList;
                        }) ?? [];
                });
            } catch (err) {
                console.error(`Could not fetch transport templates, err: ${err}`);
            }
        }
        runInAction(() => {
            this.availableTransportTemplatesLoading = false;
        });
    }

    @action
    async transportTemplatesDelete(id: string): Promise<void> {
        //delete template by id
        const templateIndex = this.availableTransportTemplates.findIndex(t => t?.id === id);
        if (templateIndex) {
            this.availableTransportTemplates.splice(templateIndex, 1);
            await this._logic.api().transportTemplateApi.transportTemplateDeactivateV1TransportsTemplateIdDelete({
                id: id
            });
        }
    }

    @action
    async transportTemplatesRename(id: string, name: string): Promise<void> {
        //update template name
        const template = this.availableTransportTemplates.find(t => t?.id === id);
        if (template) {
            const newTemplate = await this._logic
                .api()
                .transportTemplateApi.transportTemplateRenameV1TransportsTemplateIdPatch({
                    id: template.id,
                    name
                });
            runInAction(() => {
                template.name = name;
                template.id = newTemplate.id;
            });
        }
    }
    async transportTemplateByName(name: string): Promise<Array<TransportTemplateInDb>> {
        const templates = await this._logic.api().transportTemplateApi.transportTemplateFindV1TransportsTemplateGet({
            name: name
        });
        return templates;
    }

    async transportTemplateNameExists(id: string, name: string): Promise<boolean> {
        let result = false;
        const templates = await this.transportTemplateByName(name);
        templates.forEach(t => {
            if (t.id !== id) {
                result = true;
            }
        });
        return result;
    }

    async transportTemplateById(id: string): Promise<object> {
        const template = await this._logic
            .api()
            .transportTemplateApi.transportTemplateFindOneV1TransportsTemplateIdGet({
                id: id
            });
        return template;
    }

    async transportTemplateToTransport(templateId: string): Promise<void> {
        const template = await this.transportTemplateById(templateId);
        if (template) {
            const transport = {
                ...(template as TransportTemplateInDb).transport,
                version: 2,
                state: TransportState.New,
                users: [],
                transportTemplateId: templateId
            };
            this.transformTransport(transport, 2);
            let date = moment().toDate();
            transport.places = transport.places?.map(place => {
                const p = {
                    ...place,
                    id: this.generateId(),
                    center: latLngFromGeoJsonPointType(place.center ?? {}),
                    polygon: place.polygon ? latLngFromGeoJsonPolygonType(place.polygon) : undefined,
                    rta: date
                };
                date = moment(date)
                    .add(place.duration ?? 0, 'seconds')
                    .toDate();
                this.updateTransportPlaceTaskTimes(p);
                return p;
            });
            this.setDefaultProfile(transport);

            try {
                this.transport = transport;
                this.calculateTollCost(this.transport);
                this.saveTransportTemplate = false;
                this.selectedTransportType = TransportType.balanced;
                this.defaultMonitoredObjectId = this.selectedVehicleId;
                this.setDefaultProfile(this.transport);
                this._updateTransportInLocalStorage();
                this._transportInPoland();

                this._setPlannedPossibleTransport();
                this.routeModified = false;
            } catch (err) {
                console.error(`Could not recompute template transport, err: ${err}`);
                this.updateTransportNameByPlaces();
            }
        }
    }

    setTransportTemplateSave(save: boolean) {
        this.saveTransportTemplate = save;
    }

    @action
    protected _setPlannedPossibleTransport() {
        this.possibleTransports = [
            {
                firstRta: this.transport.firstRta?.toString() ?? '',
                lastRta: this.transport.lastRta?.toString() ?? '',
                type: TransportType.balanced as any,
                tollCost: this.transport.tollCost,
                distance: this.transport.places?.reduce((acc, cur) => acc + (cur.distance ?? 0), 0) ?? 0,
                places:
                    this.transport.places?.map(place => {
                        return {
                            ...place,
                            departure: place.rta?.toString() ?? '',
                            position: {
                                lat: (place.center as any)?.lat as number,
                                lng: (place.center as any)?.lng as number
                            },
                            crossingPoints: place.crossingPoints as PlannedTransportPlacePosition[] | undefined
                        };
                    }) ?? [],
                duration: this.transport.places?.reduce((acc, cur) => acc + (cur.duration ?? 0), 0) ?? 0
            }
        ];
    }

    private _addAetrToTransport(transport: Transport, route: Transport) {
        this.transport = {
            ...transport,
            places: transport.places?.map((place, index) => ({
                ...place,
                rta: route.places?.[index].rta,
                rtd: route.places?.[index].rtd,
                durationBuffer: route.places?.[index].durationBuffer,
                tasks: route.places?.[index].tasks,
                listOfWtmBreakRest: route.places?.[index].listOfWtmBreakRest
                    ? route.places?.[index].listOfWtmBreakRest
                    : []
            }))
        };
    }

    @action
    protected _addRouteToTransport(transport: Transport, route: PlannedTransport) {
        const newVersion = this.version === 2;

        this.transport = {
            ...transport,
            firstRta: route.firstRta ? moment(route.firstRta).toDate() : undefined,
            lastRta: route.lastRta ? moment(route.lastRta).toDate() : undefined,
            places: route.places.map((p, index, places) => {
                const currentPlace = this.transport.places?.find(t => t.id === p.id);

                const departure = p.departure ? moment(p.departure).toDate() : undefined;
                const currentRta = currentPlace ? currentPlace.rta : undefined;
                let rta = currentRta;
                if (departure && currentRta && currentPlace?.fixedRta) {
                    rta = moment(departure).isAfter(currentRta) ? departure : currentRta;
                } else {
                    rta = departure ?? currentRta;
                }

                return {
                    ...currentPlace,
                    id: p.id!,
                    name: p.name,
                    distance: newVersion ? p.distance : index === 0 ? 0 : places[index - 1].distance,
                    duration: newVersion ? p.duration : index === 0 ? 0 : places[index - 1].duration,
                    route: p.route,
                    rta: rta,
                    center: { lat: p.position.lat, lng: p.position.lng },
                    crossingPoints:
                        p.crossingPoints?.map(
                            point => ({ lat: point.lat, lon: point.lng } as TransportCrossingPoint)
                        ) ?? [] // TODO fix ROUTING LAT LNG
                } as TransportPlace;
            })
        };

        //set rta, rtd and add activity and buffer
        this.transport.places?.forEach((place, placeIndex, places) => {
            const nextPlace = placeIndex < places!.length - 1 ? places![placeIndex + 1] : undefined;

            if (nextPlace) {
                let rta = moment(place.rta).toDate();
                let rtd = moment(place.rta).toDate();

                if (newVersion) {
                    this.updateTransportPlaceTaskTimes(place);
                    const taskDate =
                        (place.tasks ?? []).length > 0 ? place.tasks?.[place.tasks.length - 1].plannedEnd : undefined;

                    if (taskDate && moment(rtd).isBefore(taskDate)) {
                        rtd = moment(taskDate).toDate();
                        rta = rtd;
                    }
                }

                rta = moment(rta)
                    .add((newVersion ? place.duration : nextPlace.duration) ?? 0, 'seconds')
                    .toDate();

                if ((place?.durationBuffer ?? 0) > 0) {
                    rta = moment(rta).add(place.durationBuffer!, 'seconds').toDate();
                }

                place.rtd = rtd;
                if (!nextPlace.fixedRta) {
                    nextPlace.rta = moment(rta).toDate();
                } else {
                    if (moment(nextPlace.rta).isBefore(moment(rta))) {
                        nextPlace.rta = moment(rta).toDate();
                        nextPlace.fixedRta = false;
                    }
                }
            } else {
                let rtd = moment(place.rta).toDate();

                if (newVersion) {
                    this.updateTransportPlaceTaskTimes(place);
                    const taskDate =
                        (place.tasks ?? []).length > 0 ? place.tasks?.[place.tasks.length - 1].plannedEnd : undefined;

                    if (taskDate && moment(rtd).isBefore(taskDate)) {
                        rtd = moment(taskDate).toDate();
                    }
                }

                place.rtd = rtd;
            }
        });

        this.onRouteChange();
    }

    @action
    protected _removePuescData() {
        this.transport.puesc = false;
        // we can save those data even if Polish excise duty and transport monitoring by PUESC/SENT-GEO """IS NOT""" applicable based on the type of transported goods
        // this.transport.pUESCTypeOfNotification, this has to be saved
        if (!this.transportInPoland) {
            this.transport.pUESCTypeOfNotification = undefined;
            this.transport.plannedPUESCBorderCrossingsEntry = undefined;
            this.transport.plannedPUESCBorderCrossingsExit = undefined;
        }
    }

    @action
    protected _removeNotExistingUnloadedGoods() {
        const allGoodsIds = this.goodsWithAddress.map(goods => goods.id);
        this.transport.places?.forEach(place => {
            const unloadedTask = place.tasks?.find(task => task.type === TransportPlaceTaskType.Unloading);
            if (unloadedTask) {
                unloadedTask.unloadingGoods = unloadedTask?.unloadingGoods?.filter(id => allGoodsIds.includes(id));
            }
        });
    }

    @action
    protected async _loadTollCost(transport: Transport): Promise<void> {
        this.tollCostLoading = true;
        transport = this.transport;
        try {
            const tolls = await this._logic.api().routingApi.roadLordTollCosts({
                tollCostRequestBody:
                    transport?.places
                        ?.filter(p => p.route)
                        .map(place => {
                            return {
                                departureTime: moment(place.rtd ?? place.atd).toDate(),
                                polyline: place.route,
                                currency: 'EUR',
                                profile: transport.omitRouteParams ? undefined : this._getRoutingProfile()
                            } as TollCostRequestBody;
                        }) ?? []
            });
            runInAction(() => {
                tolls.forEach((toll, index) => {
                    if (transport?.places?.[index]) {
                        transport.places[index].routeCosts = toll as RouteCostsDetails;
                    }
                });

                this.calculateTollCost(transport);
                this._updateTransportInLocalStorage();
            });
        } catch (err) {
            transport.tollCost = undefined;
            transport.places?.forEach((_, index) => {
                if (transport?.places?.[index]) {
                    transport.places[index].routeCosts = undefined;
                }
            });
            this.calculateTollCost(transport);
            this._updateTransportInLocalStorage();
            console.error(err);
        }
        runInAction(() => {
            this.tollCostLoading = false;
        });
    }

    getTransportVersion(): number {
        return this.version;
    }

    addPlaceEventRule(place: TransportPlace, ruleName: string, configs: TransportEventRuleConfig[]) {
        const rule = place.eventRules?.find(rule => rule.name === ruleName);
        if (rule) {
            configs.forEach(config => {
                const conf = rule.config?.find(conf => (conf.name = config.name));
                if (conf) {
                    conf.value = config.value;
                } else {
                    if (rule.config) {
                        rule.config.push(config);
                    } else {
                        rule.config = [config];
                    }
                }
            });
        } else {
            const rule = {
                name: ruleName,
                config: configs
            };
            if (!place.eventRules) {
                place.eventRules = [rule];
            } else {
                place.eventRules.push(rule);
            }
        }
    }

    removePlaceEventRule(place: TransportPlace, ruleName: string, configName: string | undefined = undefined) {
        if (place.eventRules) {
            if (!configName) {
                place.eventRules = place.eventRules.filter((rule: TransportEventRule) => rule.name !== ruleName);
            } else {
                const rule = place.eventRules?.find(rule => rule.name === ruleName);
                if (rule?.config) {
                    rule.config = rule.config.filter((config: TransportEventRuleConfig) => config.name !== ruleName);
                }
            }
        }
    }

    getPlaceEventRuleConfig(
        place: TransportPlace,
        ruleName: string,
        configName: string
    ): TransportEventRuleConfig | undefined {
        const rule = place.eventRules?.find(rule => rule.name === ruleName);
        if (rule) {
            if (rule.config) {
                const conf = rule.config?.find(conf => (conf.name = configName));
                if (conf) {
                    return conf;
                } else {
                    return undefined;
                }
            }
        }
        return undefined;
    }

    getPlaceEventRuleConfigValue(rule: TransportEventRule, configName: string): number | string | object | undefined {
        if (rule.config) {
            const conf = rule.config?.find(conf => conf.name === configName);
            if (conf) {
                return conf.value ?? undefined;
            } else {
                return undefined;
            }
        }
        return undefined;
    }

    calculateTollCost(transport: Transport) {
        if (this.tollCostEnable && transport.places && transport.places?.length > 0) {
            this.tollCostByType = {};
            let totalCost = 0;
            let tollByCountry: TollCostByCountry[] = [];
            let tollCosts: TransportTollCost = {
                totalCost: 0,
                tollByCountry: []
            };

            transport.places.forEach(place => {
                if (place.routeCosts) {
                    place.routeCosts?.countries?.forEach(country => {
                        totalCost = totalCost + country.price.price;

                        const find = tollByCountry.find(c => c.country === country.countryCode);
                        if (find) {
                            find.amountInTargetCurrency = find.amountInTargetCurrency + country.price.price;
                        } else {
                            tollByCountry.push({
                                country: country.countryCode,
                                amountInTargetCurrency: country.price.price!
                            });
                        }
                    });
                }
            });

            tollCosts = {
                totalCost: totalCost,
                tollByCountry: tollByCountry
            };
            this.tollCostByType[RouteType.Balanced] = tollCosts;
            transport.tollCost = tollCosts;
        }
    }

    protected _getRoutingProfile(): VehicleProfileRoutingApi | undefined {
        const toRoutingEmission = (localEmissionClass: string): RoutingEmissionStandard => {
            switch (localEmissionClass) {
                case EmissionClass.EEV:
                    return RoutingEmissionStandard.EUROEEV;
                case EmissionClass.EURO_I:
                    return RoutingEmissionStandard.EURO1;
                case EmissionClass.EURO_II:
                    return RoutingEmissionStandard.EURO2;
                case EmissionClass.EURO_III:
                    return RoutingEmissionStandard.EURO3;
                case EmissionClass.EURO_IV:
                    return RoutingEmissionStandard.EURO4;
                case EmissionClass.EURO_V:
                    return RoutingEmissionStandard.EURO5;
                case EmissionClass.EURO_VI:
                    return RoutingEmissionStandard.EURO6;
                default:
                    return RoutingEmissionStandard.NONE;
            }
        };

        const profile = this.availableVehicleProfiles.find(p => p.id === this.transport.desiredVehicleProfile);
        const vehicle = this.availableVehicles?.find(
            v => v.data.monitoredObjectId === this.selectedVehicleId?.toString()
        );

        const trailers = this.availableTrailers.filter(trailer =>
            this.transport.monitoredObjects
                ?.map(monitoredObject =>
                    !monitoredObject.endTime && monitoredObject.type === MonitoredObjectFleetType.Trailer
                        ? monitoredObject.monitoredObjectId
                        : undefined
                )
                .includes(trailer.data?.id)
        );

        let result: VehicleProfileRoutingApi | undefined = undefined;
        if (profile) {
            result = {
                height: profile.height ?? undefined,
                fullWeight: profile.weight ?? undefined,
                length: profile.length ?? undefined,
                trailersCount: profile.trailersCount ?? undefined,
                numberOfAxles: profile.numberOfAxles ?? undefined,
                tunnel: profile.tunnel ?? undefined,
                width: profile.width ?? undefined,
                euroClass: profile.euroClass ? toRoutingEmission(profile.euroClass) : undefined
            };
        } else if (vehicle?.fleetModel) {
            result = {
                height: vehicle.fleetModel.height
                    ? Math.max(
                          Number(vehicle.fleetModel.height ?? 0),
                          ...(trailers?.filter(t => t?.profile?.height).map(t => Number(t?.profile?.height)) ?? [])
                      )
                    : undefined,
                emptyWeight: vehicle.fleetModel.weight
                    ? Number(
                          Number(vehicle.fleetModel.weight ?? 0) +
                              (trailers.filter(t => t?.profile?.weightEmpty) ?? []).reduce((a, c) => {
                                  a = a + Number(c?.profile?.weightEmpty);
                                  return a;
                              }, 0)
                      )
                    : undefined,
                fullWeight: vehicle.fleetModel.weightFull ? Number(vehicle.fleetModel.weightFull ?? 0) : undefined,
                length: vehicle.fleetModel.length
                    ? Number(
                          Number(vehicle.fleetModel.length ?? 0) +
                              (trailers.length > 0
                                  ? Math.max(
                                        (trailers.filter(t => t?.profile?.length) ?? []).reduce((a, c) => {
                                            a = a + Number(c?.profile?.length);
                                            return a;
                                        }, 0) - trailerOverlapVehicleLength,
                                        0
                                    ) // first trailer is over vehicle
                                  : 0)
                      )
                    : undefined,
                trailersCount: trailers.length > 0 ? trailers.length : undefined,
                numberOfAxles: vehicle.fleetModel?.numberOfAxles
                    ? Number(
                          Number(vehicle.fleetModel.numberOfAxles ?? 0) +
                              (trailers.filter(t => t?.profile?.numberOfAxles) ?? []).reduce((a, c) => {
                                  a = a + Number(c?.profile?.numberOfAxles);
                                  return a;
                              }, 0)
                      )
                    : undefined,
                tunnel: vehicle.fleetModel.tunnel || undefined,
                width: vehicle.fleetModel.width
                    ? Math.max(
                          Number(vehicle.fleetModel.width ?? 0),
                          ...(trailers.filter(t => t?.profile?.width).map(t => Number(t?.profile?.width)) ?? [])
                      )
                    : undefined,
                euroClass: vehicle.fleetModel.emissionClass
                    ? toRoutingEmission(vehicle.fleetModel.emissionClass)
                    : undefined
            };
        } else {
            const defaultProfile = this.getDefaultVehicleProfile();
            if (defaultProfile) {
                result = {
                    height: defaultProfile.height ?? undefined,
                    fullWeight: defaultProfile.weight ?? undefined,
                    length: defaultProfile.length ?? undefined,
                    trailersCount: defaultProfile.trailersCount ?? undefined,
                    numberOfAxles: defaultProfile.numberOfAxles ?? undefined,
                    tunnel: defaultProfile.tunnel ?? undefined,
                    width: defaultProfile.width ?? undefined,
                    euroClass: defaultProfile.euroClass ? toRoutingEmission(defaultProfile.euroClass) : undefined
                };
            }
        }
        return result;
    }

    @action
    protected _transportInPoland() {
        this.transportInPoland = this._logic.transportLogic().transportInPoland(this.transport);
    }

    protected async _lookPlCrosses() {
        this.transport.plannedPUESCBorderCrossingsExit = undefined;
        this.transport.plannedPUESCBorderCrossingsEntry = undefined;

        if (!this.plCrosses) {
            await this._fetchPlCrosses();
        }
        let crossingEntryPoint: number[] | undefined;
        let crossingExitPoint: number[] | undefined;
        if (this.transport.places) {
            const latLng = this.transport.places[0]?.center as LatLng;
            const transportStartedInPoland = this._logic.map().routing().pointInPoland(latLng);
            this.transport.places.forEach(place => {
                if (place.route) {
                    if (this._logic.map().routing().polylineInPoland(place.route)) {
                        const features = this._logic.map().routing().getPointsPolandCrossing(place.route).features;
                        for (const feature of features) {
                            if (!crossingEntryPoint && !transportStartedInPoland) {
                                crossingEntryPoint = feature.geometry.coordinates;
                            } else if (!crossingExitPoint) {
                                crossingExitPoint = feature.geometry.coordinates;
                            }
                        }
                    }
                }
            });
        }

        if (this.plCrosses && this.plCrosses.length > 0 && (crossingEntryPoint || crossingExitPoint)) {
            const plCrossesByCoordinate = new Map<string, BorderCrossTwoDirections>();
            const coordinates: [number, number][] = [];
            for (const crosses of this.plCrosses) {
                if (crosses.direction1) {
                    coordinates.push(crosses.direction1.centroid);
                    plCrossesByCoordinate.set(
                        `${crosses.direction1.centroid[0]}${crosses.direction1.centroid[1]}`,
                        crosses
                    );
                } else if (crosses.direction2) {
                    coordinates.push(crosses.direction2.centroid);
                    plCrossesByCoordinate.set(
                        `${crosses.direction2.centroid[0]}${crosses.direction2.centroid[1]}`,
                        crosses
                    );
                }
            }
            if (crossingEntryPoint) {
                const nearestEntryBorderPoint = this._logic.map().routing().nearestPoint(
                    {
                        lat: crossingEntryPoint[0],
                        lng: crossingEntryPoint[1]
                    },
                    coordinates
                );

                const entryCrosses = plCrossesByCoordinate.get(
                    `${nearestEntryBorderPoint.geometry.coordinates[0]}${nearestEntryBorderPoint.geometry.coordinates[1]}`
                );
                if (entryCrosses) {
                    this.transport.plannedPUESCBorderCrossingsEntry = entryCrosses.borderCrossId;
                }
            }

            if (crossingExitPoint) {
                const nearestExitBorderPoint = this._logic.map().routing().nearestPoint(
                    {
                        lat: crossingExitPoint[0],
                        lng: crossingExitPoint[1]
                    },
                    coordinates
                );

                const entryCrosses = plCrossesByCoordinate.get(
                    `${nearestExitBorderPoint.geometry.coordinates[0]}${nearestExitBorderPoint.geometry.coordinates[1]}`
                );
                if (entryCrosses) {
                    this.transport.plannedPUESCBorderCrossingsExit = entryCrosses.borderCrossId;
                }
            }
        }

        this._updateTransportInLocalStorage();
    }

    protected _getTransportGoods(): Goods[] {
        return (
            this.transport.places?.reduce<Goods[]>((acc, curr) => {
                if (curr.tasks?.[0].loadingGoods) {
                    return acc.concat(curr.tasks?.[0].loadingGoods);
                }
                return acc;
            }, [] as Goods[]) ?? []
        );
    }

    protected _updateTransportInLocalStorage() {
        if (this._useLocaleStorage) {
            const plannerNew = this._logic.settings().getProp('planner');
            this._logic.settings().setProp('planner', {
                ...plannerNew,
                transport: this.transport,
                tollCostByType: this.tollCostByType,
                possibleTransports: this.possibleTransports,
                defaultPuescActive: this.defaultPuescActive
            });
        }
    }

    public updateTransportInLocalStorage() {
        this._updateTransportInLocalStorage();
    }

    protected _generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    protected _isMarkerDraggable(placeId?: string): boolean {
        const placeIndex = this.transport.places?.findIndex(place => place.id !== placeId);
        return placeIndex === undefined || !(placeIndex === 0 && this.lockedMonitoredObjectId !== undefined);
    }

    protected async _planPartialRoute(places: TransportPlace[]) {
        this.selectedTransportType =
            this.selectedTransportType === TransportType.planned ? TransportType.shortest : this.selectedTransportType;
        let transports: PlannedTransport[] = [];

        transports = await this.routing(this.transport, places);

        const currentUpdatedRoute = (transports || []).find(t => TransportType[t.type] === this.selectedTransportType);

        if (currentUpdatedRoute) {
            // set first route as it was before partial planning since api will return empty string for the first place.
            // that would override existing route.
            if (currentUpdatedRoute.places.length > 0) {
                const firstPlace = currentUpdatedRoute.places[0];
                const prevPlace = this.transport.places?.find(pl => pl.id === firstPlace.id);
                firstPlace.route = prevPlace?.route;
            }
            this.transport = {
                ...this.transport,
                places: this.transport.places?.map(p => {
                    const updatedPlace = currentUpdatedRoute.places.find(pl => p.id === pl.id);
                    if (updatedPlace) {
                        return {
                            ...p,
                            name: updatedPlace.name ?? '',
                            id: updatedPlace.id ?? undefined,
                            distance: updatedPlace.distance ?? undefined,
                            duration: updatedPlace.duration ?? undefined,
                            route: updatedPlace.route ?? undefined,
                            rta: updatedPlace.departure ? moment.utc(updatedPlace.departure).toDate() : undefined,
                            center: { lat: updatedPlace.position.lat, lng: updatedPlace.position.lng },
                            crossingPoints:
                                updatedPlace.crossingPoints?.map(
                                    point => ({ lat: point.lat, lon: point.lng } as TransportCrossingPoint)
                                ) ?? [] // TODO fix ROUTING LAT LNG
                        };
                    } else {
                        return {
                            ...p
                        };
                    }
                })
            };

            this.transformTransport(this.transport, this.version);
        } else {
            this._logic.map().routing().renderRoute([], [], []);
        }

        const routes = this.transport.places?.filter(p => p.route).map(p => p.route!);
        if (routes) this._logic.map().routing().renderTmpDraggingRoute(routes);
    }

    async poisAlongRoute(encodedRoutes: string[]): Promise<void> {
        const fss = this._logic
            .poi()
            .filterFuelStations(
                await this._logic.poi().fuelStationsWithPrices(),
                this._fuelDataFilter,
                this._serviceDataFilter
            );
        if (fss.length === 0) {
            this._fuelStationsLoadedWhileRender = false;
        }
        const pss = await this._logic.poi().parkings();
        if (pss.length === 0) {
            this._parkingsLoadedWhileRender = false;
        }
        const washers = await this._logic.poi().washers();
        if (washers.length === 0) {
            this._washersLoadedWhileRender = false;
        }
        const pois = await this._logic.poi().pois();
        if (pois.length === 0) {
            this._poisLoadedWhileRender = false;
        }

        if (fss.length > 0) {
            const fuelStations: PoiModelMap[][] = encodedRoutes
                .map(route => this._logic.map().routing().pointsAlongPolyline(route, fss))
                .map((section, index) => section.map(fs => ({ ...fs, routeIndex: index })));

            this._fuelStations = fuelStations.flat().reduce<PoiModelMap[]>((a, c) => {
                if (!a.some(e => e.id === c.id)) {
                    a.push(c);
                }
                return a;
            }, []);

            this._logic.map().routing().setFuelStations(fuelStations);
        }

        if (pss.length > 0) {
            const parkings: PoiModelMap[][] = encodedRoutes.map(route =>
                this._logic.map().routing().pointsAlongPolyline(route, pss)
            );

            this._parkings = parkings.flat().reduce<PoiModelMap[]>((a, c) => {
                if (!a.some(e => e.id === c.id)) {
                    a.push(c);
                }
                return a;
            }, []);

            this._logic.map().routing().setParkings(parkings);
        }

        if (washers.length > 0) {
            const routeWashers: PoiModelMap[][] = encodedRoutes.map(route =>
                this._logic.map().routing().pointsAlongPolyline(route, washers)
            );

            this._washers = routeWashers.flat().reduce<PoiModelMap[]>((a, c) => {
                if (!a.some(e => e.id === c.id)) {
                    a.push(c);
                }
                return a;
            }, []);

            this._logic.map().routing().setWashers(routeWashers);
        }

        if (pois.length > 0) {
            const routePois: PoiModelMap[][] = encodedRoutes.map(route =>
                this._logic.map().routing().pointsAlongPolyline(route, pois)
            );

            this._pois = routePois.flat().reduce<PoiModelMap[]>((a, c) => {
                if (!a.some(e => e.id === c.id)) {
                    a.push(c);
                }
                return a;
            }, []);

            this._logic.map().routing().setPois(routePois);
        }

        const fuelStationsWithoutPrice = this._fuelStations
            .filter(p => !p.fuelTypes?.some(t => t.price?.price) && p.externalId)
            .map(f => f.externalId) as string[];

        if (fuelStationsWithoutPrice.length > 0) {
            console.info('[FS prices] Going to load => ' + fuelStationsWithoutPrice.length + ' without price');
            console.info('[FS prices] FuelStations external ids', fuelStationsWithoutPrice);
            this._loadMissingFuelStations(fuelStationsWithoutPrice);
        }
    }

    protected _loadMissingFuelStations(fuelStations: string[], retryCount: number = 0) {
        if (this._logic.demo().isActive) {
            return;
        }
        return this._logic
            .api()
            .fuelStationsApi.computePricesV1FuelstationsPricesComputePost({ requestBody: fuelStations })
            .catch(err => {
                console.error('can not get missing fuelStations', err);
                console.error('retry count: ', retryCount);
                if (retryCount < 3) {
                    setTimeout(() => this._loadMissingFuelStations(fuelStations, retryCount + 1), Math.random() * 3001); // random value to 0 - 3s
                }
            });
    }

    protected async _checkVehicleAvailability() {
        await Promise.all([this._fetchAvailableTrailers(), this._fetchAvailableVehicles()]);

        const vehicleWithAvailability = this.availableVehicles.find(
            vehicle => Number(vehicle.data.monitoredObjectId) === this.selectedVehicleId
        );
        if (
            !vehicleWithAvailability?.available &&
            this.lockedMonitoredObjectId !== this.selectedVehicleId &&
            !this.defaultMonitoredObjectId
        ) {
            this.removeMonitoredObjectFromTransport(MonitoredObjectFleetType.Vehicle);
        }
    }

    protected _compareProfiles(profileA?: VehicleProfile, profileB?: VehicleProfile) {
        return (
            profileA?.euroClass === profileB?.euroClass &&
            profileA?.height === profileB?.height &&
            profileA?.length === profileB?.length &&
            profileA?.numberOfAxles === profileB?.numberOfAxles &&
            profileA?.trailersCount === profileB?.trailersCount &&
            profileA?.tunnel === profileB?.tunnel &&
            profileA?.weight === profileB?.weight &&
            profileA?.width === profileB?.width
        );
    }

    protected _calculateTransportPlacesHash(transport: Transport) {
        return transport.places?.map(p => p.center?.['lat'] + ':' + p.center?.['lng']).join('-');
    }

    getDefaultVehicleProfile() {
        if (this.availableVehicleProfiles.length > 0) {
            return this.availableVehicleProfiles.find(profile => profile.isDefault) ?? this.availableVehicleProfiles[0];
        }
        return undefined;
    }

    getSelectVehicleProfile = () => {
        const defaultProfile = this.getDefaultVehicleProfile();
        const defaultProfileId = defaultProfile ? `profile-${defaultProfile?.id}` : '';

        if (this.selectedProfileId) {
            return this.availableVehicleProfiles.length > 0 &&
                this.availableVehicleProfiles.map(profile => profile.id).includes(this.selectedProfileId)
                ? `profile-${this.selectedProfileId}`
                : defaultProfileId;
        }

        if (this.selectedVehicleId) {
            return this.availableVehicles.length > 0 &&
                this.availableVehicles
                    .map(vehicle => vehicle.data.monitoredObjectId)
                    .includes(String(this.selectedVehicleId))
                ? `vehicle-${this.selectedVehicleId}`
                : defaultProfileId;
        }

        return defaultProfileId;
    };

    getPriceByCurrency = (price: number, currency: AvailableCurrencies): number => {
        if (currency !== AvailableCurrencies.EUR) {
            price = exchangePriceCurrency(
                AvailableCurrencies.EUR,
                currency,
                price ?? 0,
                this._logic.poi().currencies ?? []
            );
        }
        return price;
    };

    transformTransport(transport: Transport, version: number = 1) {
        if ((transport.places ?? []).length > 0) {
            if (version === 1) {
                if (transport.places && transport.places[0].duration !== 0) {
                    for (let i = transport.places.length - 1; i > 0; i--) {
                        transport.places[i].duration = transport.places[i - 1].duration;
                        transport.places[i].distance = transport.places[i - 1].distance;
                    }
                    transport.places[0].duration = 0;
                    transport.places[0].distance = 0;
                }
            } else {
                if (transport.places && transport.places[0].duration === 0) {
                    for (let i = 0; i < transport.places.length - 1; i++) {
                        transport.places[i].duration = transport.places[i + 1].duration;
                        transport.places[i].distance = transport.places[i + 1].distance;
                    }
                    transport.places[transport.places.length - 1].duration = 0;
                    transport.places[transport.places.length - 1].distance = 0;
                }

                if (this._logic.conf.settings.plannerOldAvailable === false && transport.version === 1) {
                    transport.places?.forEach((place, index) => {
                        const leaveCorridorRule = place.eventRules?.find(
                            rule => rule.name === 'transport_corridor_leave'
                        );
                        if (leaveCorridorRule) {
                            const prevPlace = transport.places?.[index - 1];
                            if (prevPlace) {
                                if (prevPlace.eventRules) {
                                    prevPlace.eventRules.push(leaveCorridorRule);
                                } else {
                                    prevPlace.eventRules = [leaveCorridorRule];
                                }
                            }
                            place.eventRules = place.eventRules?.filter(
                                rule => rule.name !== 'transport_corridor_leave'
                            );
                        }
                    });
                }
            }
        }
    }

    validateTrailer(transport: Transport) {
        const trailers = transport.monitoredObjects?.filter(mo => mo.type === MonitoredObjectFleetType.Trailer) ?? [];
        if (trailers) {
            trailers.forEach(trailer => {
                const trailerAvailable = this.availableTrailers.find(t => t?.data?.id === trailer.monitoredObjectId);
                if (!trailerAvailable) {
                    transport.monitoredObjects = transport.monitoredObjects?.filter(
                        mo =>
                            !(
                                mo.type === MonitoredObjectFleetType.Trailer &&
                                mo.monitoredObjectId === trailer.monitoredObjectId
                            )
                    );
                }
            });
        }
    }

    generateId(): string {
        return uuid.v4();
    }

    updateTransportPlaceTaskTimes(place: TransportPlace) {
        let newStartDate = moment(place.rta).toDate();
        place.tasks?.forEach(task => {
            const diff = this.getTaskTime(task);
            task.plannedStart = newStartDate;
            task.plannedEnd = moment(task.plannedStart).add(diff, 'seconds').toDate();
            newStartDate = moment(task.plannedStart).add(diff, 'seconds').toDate();
        });
    }

    getTransportPlaceTaskTime(tasks?: TransportPlaceTask[]): number {
        let time = 0;

        tasks?.forEach(task => (time += this.getTaskTime(task)));
        return time;
    }

    getTaskTime(task: TransportPlaceTask): number {
        return moment(task.plannedEnd).diff(task.plannedStart) / 1000;
    }

    getGoodWeight(good: Goods, unit: string): number | undefined {
        switch (unit) {
            case T:
                return good.measurements?.find(m => m.measurementUnit === T)?.grossWeightVolume;
            case M3:
                return good.measurements?.find(m => m.measurementUnit === M3)?.grossWeightVolume;
            default:
                return undefined;
        }
    }

    getTaskWeight(task: TransportPlaceTask, unit: string): number {
        switch (unit) {
            case T:
                return task.measurements?.find(m => m.measurementUnit === T)?.grossWeightVolume ?? 0;
            case M3:
                return task.measurements?.find(m => m.measurementUnit === M3)?.grossWeightVolume ?? 0;
            default:
                return 0;
        }
    }

    getMeasurement(measurements: Measurement[], unit: string): Measurement | undefined {
        return measurements?.find(m => m.measurementUnit === unit);
    }

    addOrRemoveMeasurement = (task: TransportPlaceTask, value: number, unit: string) => {
        if (task.measurements) {
            if (value === 0) {
                const index = task.measurements.findIndex(m => m.measurementUnit === unit);
                if (index > -1) {
                    task.measurements.splice(index, 1);
                }
                return;
            }
            const measurement = task.measurements?.find(m => m.measurementUnit === unit);

            if (measurement) {
                measurement.grossWeightVolume = value;
            } else {
                task.measurements?.push({
                    grossWeightVolume: value,
                    measurementUnit: unit
                });
            }
        } else {
            task.measurements = [
                {
                    grossWeightVolume: value,
                    measurementUnit: unit
                }
            ];
        }
    };

    profileToColdchainProfile = (profile: ReadOnlyProfileSerializer): ColdchainProfileData => {
        return {
            ...profile,
            aboveTemperatureThresholdUse: profile.aboveTemperatureThreshold !== undefined,
            belowTemperatureThresholdUse: profile.belowTemperatureThreshold !== undefined,
            alarmTimerSecondsUse: profile.alarmTimerSeconds !== undefined
        } as ColdchainProfileData;
    };

    getPlaceDisabled(place?: TransportPlace): boolean {
        return !!(place?.eventStates?.some(e => e.eventRuleName === 'transport_skipping') || place?.ata);
    }

    isManualAtaPossible(placeIndex: number, place?: TransportPlace): boolean {
        return !(place?.eventStates?.some(e => e.eventRuleName === 'transport_skipping') || place?.ata) &&
            [TransportState.Active, TransportState.Delayed].includes(this.transport?.state as TransportState) &&
            (!this.transport?.places?.[placeIndex - 1] || this.transport?.places?.[placeIndex - 1].ata)
            ? true
            : false;
    }

    clearTransport(clearAvoid?: boolean) {
        this.transport = {
            ...this.transport,
            places: this.transport.places?.map(place => {
                return {
                    ...place,
                    duration: 0,
                    distance: 0,
                    route: undefined,
                    routeCosts: undefined
                };
            }),
            routeOptionsSygic: clearAvoid ? undefined : this.transport.routeOptionsSygic,
            tollCost: undefined
        };
        this.drawRouteOnMap();
        this.updateTransportNameByPlaces();
    }

    clearCrossingPoints(sort: SortEnd) {
        this.transport.places![sort.newIndex].crossingPoints = [];
        this.transport.places![sort.oldIndex].crossingPoints = [];
        if (sort.newIndex - 1 > -1) {
            this.transport.places![sort.newIndex - 1].crossingPoints = [];
        }
        if (sort.oldIndex - 1 > -1) {
            this.transport.places![sort.oldIndex - 1].crossingPoints = [];
        }
    }

    clearColdchain(id: number) {
        if ((this.availableTrailers?.find(t => t.data?.id === id)?.sensors?.length ?? 0) === 0) {
            this.transport.places?.forEach(place => {
                place.eventRules = place.eventRules?.filter(er => !er.name?.includes(ZONE_RULE_NAME));
            });
        }
    }

    getColdchainProfiles(id: string | undefined, actualPlace: TransportPlace | undefined): TransportEventRule[] {
        let profiles: TransportEventRule[] = [];
        this.transport.places
            ?.filter(p => p.id !== actualPlace?.id)
            ?.forEach(place => {
                place.eventRules
                    ?.filter(er => er.config?.find(c => c.name === COLD_CHAIN_PROFILE_ID)?.value === id)
                    ?.forEach(er => {
                        profiles.push(er);
                    });
            });

        actualPlace?.eventRules
            ?.filter(er => er.config?.find(c => c.name === COLD_CHAIN_PROFILE_ID)?.value === id)
            ?.forEach(er => {
                profiles.push(er);
            });

        return profiles;
    }

    updateColdchainProfiles(values: ColdchainProfileData) {
        this.transport.places?.forEach(place => {
            place.eventRules
                ?.filter(er => er.config?.find(c => c.name === COLD_CHAIN_PROFILE_ID)?.value === values.id)
                ?.forEach(er => {
                    er.config = [
                        { name: COLD_CHAIN_PROFILE_ID, value: values.id },
                        { name: 'above_temperature_threshold', value: values.aboveTemperatureThreshold },
                        { name: 'below_temperature_threshold', value: values.belowTemperatureThreshold },
                        { name: 'alarm_timer_seconds', value: values.alarmTimerSeconds }
                    ];
                });
        });
    }

    transformGeoPosition(position: number): number {
        return Math.round(position * 1e5) / 1e5;
    }
}
