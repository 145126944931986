import { Col, Row } from 'antd';
import { AvailableCurrencies } from 'common/model/currency';
import { M, T } from 'domain-constants';
import { MonitoredObjectTrailerProfile } from 'generated/new-main';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    profile?: MonitoredObjectTrailerProfile;
    currency?: AvailableCurrencies;
}

const TrailerProfileDetail = (props: Props) => {
    const { t } = props;

    return (
        <div className="trailer-profile-info">
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.type')}</Col>
                <Col span={8}>{t(`VehicleProfileForm.trailerTypes.${props.profile?.type}`)}</Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.numberOfAxles')}</Col>
                <Col span={8}>{props.profile?.numberOfAxles}</Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.length')}</Col>
                <Col span={8}>
                    {props.profile?.length} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.height')}</Col>
                <Col span={8}>
                    {props.profile?.height} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.width')}</Col>
                <Col span={8}>
                    {props.profile?.width} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.weightEmpty')}</Col>
                <Col span={8}>
                    {props.profile?.weightEmpty} {T}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={16}>{t('VehicleProfileForm.weightFull')}</Col>
                <Col span={8}>
                    {props.profile?.weightFull} {T}
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(TrailerProfileDetail);
