import React, { FormEvent, Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Displayed, Fuels, Services, DataSourceTypes, FuelControlsForm } from '../MapModule';
import cn from 'classnames';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { PoiMarkerData } from 'logic/map/logic/poi-marker';
import PoiCard from 'common/components/PoiCard';
import { Button } from 'common/components';
import qa from 'qa-selectors';
import { Checkbox } from 'common/components/Form';

interface Props extends WithTranslation {
    data: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        fuelData: Fuels;
        fuelStations: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    controlOnRoute?: 'edit' | 'preview';
    onPoiCardClick?: (poi: PoiModelMap) => void;
    onFilterClick?: () => void;
    onCancelClick?: () => void;
    onConfirmClick?: (form: FuelControlsForm) => void;
    onAddToTransportClick?: (data: PoiMarkerData) => void;
    onEditFuelFilter?: (filter: Fuels) => void;
    onEditServicesFilter?: (filter: Services) => void;
}

interface State {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
    fuelData: Fuels;
    services: boolean;
}

class ControlsFuel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            subOptionsData: DataSourceTypes.EUROWAG,
            serviceData: this.props.data.serviceData,
            fuelData: this.props.data.fuelData,
            services: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(this.props.data.fuelData) !== JSON.stringify(prevProps.data.fuelData)) {
            this.setState(
                state => ({
                    ...state,
                    fuelData: this.props.data.fuelData
                }),
                () => this.props.onEditFuelFilter?.(this.state.fuelData)
            );
        }
    }

    render() {
        const { t } = this.props;

        let selected: PoiModelMap[] = [];
        let bestFuelPrices: PoiModelMap[] = [];
        let recentlyViewed: PoiModelMap[] = [];

        this.props.data.fuelStations.forEach(f => {
            if (f.selected) {
                selected.push(f);
            } else if (f.bestPrice) {
                bestFuelPrices.push(f);
            } else {
                recentlyViewed.push(f);
            }
        });

        return (
            <div className="map-controls t-card">
                <div className="fuel-controls t-inverse t-padding-small">
                    <form
                        className={cn({
                            'fuel-controls-form-open': this.props.data.filterOpen,
                            'fuel-controls-fuels-services-open': this.state.services
                        })}
                    >
                        <div>
                            <div className="map-controls-bubble main">
                                <div className="setting-card">
                                    <Row justify="space-between" align="middle">
                                        <Col>
                                            <label>{t('FuelStationControls.fuelStations')}</label>
                                        </Col>
                                        <Col>
                                            <Button
                                                qa={
                                                    this.props.data.optionsData.doNotDisplay
                                                        ? qa.mapControls.showFuelStationsButton
                                                        : qa.mapControls.hideFuelStationsButton
                                                }
                                                type="primary"
                                                size="middle"
                                                onClick={this._onConfirmClick}
                                            >
                                                {this.props.data.optionsData.doNotDisplay
                                                    ? t('common.showOnMap')
                                                    : t('common.hide')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="t-row t-bar t-hide">
                                    <label className="t-bar-item t-medium">{t('FuelStationControls.source')}</label>
                                </div>
                                <div className="content t-row t-hide">
                                    <div className="t-col t-half">
                                        <div className="t-row">
                                            <label>
                                                <Checkbox disabled checked>
                                                    {t('FuelStationControls.eurowag')}
                                                </Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="t-col t-half">
                                        <div className="t-row">
                                            <label>
                                                <Checkbox
                                                    disabled
                                                    defaultChecked={
                                                        this.state.subOptionsData === DataSourceTypes.IMPORTED
                                                    }
                                                    onChange={this._onSourceImportedChange}
                                                >
                                                    {t('FuelStationControls.imported')}
                                                </Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.props.data.filterOpen && (
                                <div className="map-controls-bubble">
                                    <div className="setting-card">
                                        <div className="heading t-row t-bar" onClick={this._onServicesDropdown}>
                                            <label className="t-bar-item">
                                                {t('FuelStationControls.fuelsAndServices')}
                                            </label>
                                            <div className="setting-card-toggle t-right">
                                                <i
                                                    className={cn('fas', 'fa-fw', {
                                                        'fa-chevron-down': this.state.services,
                                                        'fa-chevron-right': !this.state.services
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        data-qa={qa.mapControls.fuelStationsServicesContainer}
                                        className={cn('content t-row', {
                                            't-hide': !this.state.services
                                        })}
                                    >
                                        {/* FuelTypes */}
                                        <div className="t-col t-half">
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="diesel"
                                                        className="t-check t-inverse"
                                                        checked={this.state.fuelData.diesel}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.diesel')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="biodiesel"
                                                        className="t-check t-inverse"
                                                        checked={this.state.fuelData.biodiesel}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.biodiesel')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="adblue"
                                                        className="t-check t-inverse"
                                                        checked={this.state.fuelData.adblue}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.adblue')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="natural95"
                                                        className="t-check t-inverse"
                                                        checked={this.state.fuelData.natural95}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.natural95')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="natural98"
                                                        className="t-check t-inverse"
                                                        checked={this.state.fuelData.natural98}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.natural98')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label className="t-disabled">
                                                    <input
                                                        type="checkbox"
                                                        name="cng"
                                                        className="t-check t-inverse"
                                                        disabled
                                                        checked={this.state.fuelData.cng}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.cng')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label className="t-disabled">
                                                    <input
                                                        type="checkbox"
                                                        name="electro"
                                                        className="t-check t-inverse"
                                                        disabled
                                                        checked={this.state.fuelData.electro}
                                                        onChange={this._onFuelFilterChange}
                                                    />
                                                    {t('FuelStationControls.electro')}
                                                </label>
                                            </div>
                                        </div>
                                        {/* Services */}
                                        <div className="t-col t-half">
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="wc"
                                                        className="t-check t-inverse"
                                                        checked={this.state.serviceData.wc}
                                                        onChange={this._onServiceFilterChange}
                                                    />
                                                    {t('FuelStationControls.wc')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="t-check t-inverse"
                                                        name="shower"
                                                        checked={this.state.serviceData.shower}
                                                        onChange={this._onServiceFilterChange}
                                                    />
                                                    {t('FuelStationControls.shower')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="food"
                                                        className="t-check t-inverse"
                                                        checked={this.state.serviceData.food}
                                                        onChange={this._onServiceFilterChange}
                                                    />
                                                    {t('FuelStationControls.food')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="carWash"
                                                        className="t-check t-inverse"
                                                        checked={this.state.serviceData.carWash}
                                                        onChange={this._onServiceFilterChange}
                                                    />
                                                    {t('FuelStationControls.carwash')}
                                                </label>
                                            </div>
                                            <div className="t-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="special"
                                                        className="t-check t-inverse"
                                                        checked={this.state.serviceData.special}
                                                        onChange={this._onServiceFilterChange}
                                                    />
                                                    {t('FuelStationControls.special')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                    <div className="fuel-controls-stations">
                        {selected.length > 0 && (
                            <>
                                {selected?.map((f, index) => (
                                    <PoiCard
                                        type="fuelStation"
                                        onClick={this.props.onPoiCardClick}
                                        data={f}
                                        key={f.id}
                                        index={index}
                                        controlOnRoute={this.props.controlOnRoute}
                                        onAddToRouteClick={this.props.onAddToTransportClick}
                                    />
                                ))}
                            </>
                        )}
                        {bestFuelPrices.length > 0 && (
                            <>
                                <div className="fuel-controls-subtitle">{t('FuelStationControls.bestPrices')}</div>
                                {bestFuelPrices?.map((f, index) => (
                                    <PoiCard
                                        type="fuelStation"
                                        onClick={this.props.onPoiCardClick}
                                        data={f}
                                        key={f.id}
                                        index={index}
                                        controlOnRoute={this.props.controlOnRoute}
                                        onAddToRouteClick={this.props.onAddToTransportClick}
                                    />
                                ))}
                            </>
                        )}
                        {recentlyViewed.length > 0 && (
                            <>
                                <div className="fuel-controls-subtitle">{t('FuelStationControls.recentlyViewed')}</div>
                                {recentlyViewed?.map((f, index) => (
                                    <PoiCard
                                        type="fuelStation"
                                        onClick={this.props.onPoiCardClick}
                                        data={f}
                                        key={f.id}
                                        index={index}
                                        controlOnRoute={this.props.controlOnRoute}
                                        onAddToRouteClick={this.props.onAddToTransportClick}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private _onServicesDropdown = (): void => {
        this.setState(state => ({
            ...state,
            services: !state.services
        }));
    };

    private _onSourceImportedChange = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;

        this.setState({
            subOptionsData: checked ? DataSourceTypes.IMPORTED : undefined
        });
    };

    private _onServiceFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
        // FIXME: Remove on react update to 17.x
        // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#no-event-pooling
        e.persist();
        this.setState(
            state => ({
                serviceData: {
                    ...state.serviceData,
                    [e.target.name]: e.target.checked
                }
            }),
            () => {
                this.props.onEditServicesFilter?.(this.state.serviceData);
            }
        );
    };

    private _onFuelFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
        e.persist();
        this.setState(
            state => ({
                fuelData: {
                    ...state.fuelData,
                    [e.target.name]: e.target.checked
                }
            }),
            () => {
                this.props.onEditFuelFilter?.(this.state.fuelData);
            }
        );
    };

    private _onConfirmClick = (event: FormEvent): void => {
        event.preventDefault();
        this.setState(
            {
                subOptionsData:
                    !this.props.data.optionsData.doNotDisplay &&
                    this.props.data.subOptionsData === DataSourceTypes.EUROWAG
                        ? undefined
                        : DataSourceTypes.EUROWAG
            },
            () => {
                this.props.onConfirmClick?.(this.state);
            }
        );
    };
}

export default withTranslation()(ControlsFuel);
