/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DrivingMode,
    DrivingModeFromJSON,
    DrivingModeFromJSONTyped,
    DrivingModeToJSON,
    FuelConsumption,
    FuelConsumptionFromJSON,
    FuelConsumptionFromJSONTyped,
    FuelConsumptionToJSON,
    TotalRanking,
    TotalRankingFromJSON,
    TotalRankingFromJSONTyped,
    TotalRankingToJSON,
    VGlobalData,
    VGlobalDataFromJSON,
    VGlobalDataFromJSONTyped,
    VGlobalDataToJSON,
    Wear,
    WearFromJSON,
    WearFromJSONTyped,
    WearToJSON,
} from './';

/**
 * 
 * @export
 * @interface PerfectDriveGroupedByDriver
 */
export interface PerfectDriveGroupedByDriver {
    /**
     * 
     * @type {Array<number>}
     * @memberof PerfectDriveGroupedByDriver
     */
    idcar: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PerfectDriveGroupedByDriver
     */
    status: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerfectDriveGroupedByDriver
     */
    info: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PerfectDriveGroupedByDriver
     */
    type: Array<number>;
    /**
     * 
     * @type {VGlobalData}
     * @memberof PerfectDriveGroupedByDriver
     */
    vGlobal: VGlobalData;
    /**
     * 
     * @type {DrivingMode}
     * @memberof PerfectDriveGroupedByDriver
     */
    drivingMode: DrivingMode;
    /**
     * 
     * @type {FuelConsumption}
     * @memberof PerfectDriveGroupedByDriver
     */
    fuelConsumption: FuelConsumption;
    /**
     * 
     * @type {Wear}
     * @memberof PerfectDriveGroupedByDriver
     */
    wear: Wear;
    /**
     * 
     * @type {TotalRanking}
     * @memberof PerfectDriveGroupedByDriver
     */
    totalRanking: TotalRanking;
    /**
     * 
     * @type {object}
     * @memberof PerfectDriveGroupedByDriver
     */
    pgnCounters?: object;
}

export function PerfectDriveGroupedByDriverFromJSON(json: any): PerfectDriveGroupedByDriver {
    return PerfectDriveGroupedByDriverFromJSONTyped(json, false);
}

export function PerfectDriveGroupedByDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDriveGroupedByDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcar': json['idcar'],
        'status': json['status'],
        'info': json['info'],
        'type': json['type'],
        'vGlobal': VGlobalDataFromJSON(json['v_global']),
        'drivingMode': DrivingModeFromJSON(json['driving_mode']),
        'fuelConsumption': FuelConsumptionFromJSON(json['fuel_consumption']),
        'wear': WearFromJSON(json['wear']),
        'totalRanking': TotalRankingFromJSON(json['total_ranking']),
        'pgnCounters': !exists(json, 'pgn_counters') ? undefined : json['pgn_counters'],
    };
}

export function PerfectDriveGroupedByDriverToJSON(value?: PerfectDriveGroupedByDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcar': value.idcar,
        'status': value.status,
        'info': value.info,
        'type': value.type,
        'v_global': VGlobalDataToJSON(value.vGlobal),
        'driving_mode': DrivingModeToJSON(value.drivingMode),
        'fuel_consumption': FuelConsumptionToJSON(value.fuelConsumption),
        'wear': WearToJSON(value.wear),
        'total_ranking': TotalRankingToJSON(value.totalRanking),
        'pgn_counters': value.pgnCounters,
    };
}


