/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyPUESCSentDocumentSerializer
 */
export interface ReadOnlyPUESCSentDocumentSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    clientId: number;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    originator: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    originatorId: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    documentRaw: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    documentName: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    documentCancel?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    documentCancelReplaceXpathRead?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    documentCancelReplaceXpathWrite?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    sentResponseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    responseRaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    responseDocument?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    sentRefNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    error?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    state?: ReadOnlyPUESCSentDocumentSerializerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    errorCode?: ReadOnlyPUESCSentDocumentSerializerErrorCodeEnum;
    /**
     * 
     * @type {object}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    errorDetails?: object | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyPUESCSentDocumentSerializer
     */
    deleted: number;
}

export function ReadOnlyPUESCSentDocumentSerializerFromJSON(json: any): ReadOnlyPUESCSentDocumentSerializer {
    return ReadOnlyPUESCSentDocumentSerializerFromJSONTyped(json, false);
}

export function ReadOnlyPUESCSentDocumentSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyPUESCSentDocumentSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'originator': json['originator'],
        'originatorId': json['originator_id'],
        'documentRaw': json['document_raw'],
        'documentName': json['document_name'],
        'documentCancel': !exists(json, 'document_cancel') ? undefined : json['document_cancel'],
        'documentCancelReplaceXpathRead': !exists(json, 'document_cancel_replace_xpath_read') ? undefined : json['document_cancel_replace_xpath_read'],
        'documentCancelReplaceXpathWrite': !exists(json, 'document_cancel_replace_xpath_write') ? undefined : json['document_cancel_replace_xpath_write'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'sentResponseRaw': !exists(json, 'sent_response_raw') ? undefined : json['sent_response_raw'],
        'responseRaw': !exists(json, 'response_raw') ? undefined : json['response_raw'],
        'responseDocument': !exists(json, 'response_document') ? undefined : json['response_document'],
        'sentRefNumber': !exists(json, 'sent_ref_number') ? undefined : json['sent_ref_number'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'errorCode': !exists(json, 'error_code') ? undefined : json['error_code'],
        'errorDetails': !exists(json, 'error_details') ? undefined : json['error_details'],
        'deleted': json['deleted'],
    };
}

export function ReadOnlyPUESCSentDocumentSerializerToJSON(value?: ReadOnlyPUESCSentDocumentSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'originator': value.originator,
        'originator_id': value.originatorId,
        'document_raw': value.documentRaw,
        'document_name': value.documentName,
        'document_cancel': value.documentCancel,
        'document_cancel_replace_xpath_read': value.documentCancelReplaceXpathRead,
        'document_cancel_replace_xpath_write': value.documentCancelReplaceXpathWrite,
        'sent_response_raw': value.sentResponseRaw,
        'response_raw': value.responseRaw,
        'response_document': value.responseDocument,
        'sent_ref_number': value.sentRefNumber,
        'error': value.error,
        'state': value.state,
        'error_code': value.errorCode,
        'error_details': value.errorDetails,
        'deleted': value.deleted,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyPUESCSentDocumentSerializerStateEnum {
    Draft = 'draft',
    New = 'new',
    Sent = 'sent',
    Done = 'done'
}
/**
* @export
* @enum {string}
*/
export enum ReadOnlyPUESCSentDocumentSerializerErrorCodeEnum {
    GENERICERROR = 'GENERIC_ERROR'
}


