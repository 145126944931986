import moment, { Moment } from 'moment';
import { Logic } from 'logic/logic';
import { ReadOnlyAddressNested, ReadOnlyClient, ReadOnlyClientFleetTypeEnum } from 'generated/new-main';
import { PaginatedResponse } from 'common/model/pagination';
import { PartnerAddressModel, sampleAddresses } from './partner-address';
import { PartnerContactPersonModel, sampleContactPersons } from './partner-contact-person';
import { AvailableCurrencies } from 'common/model/currency';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';
import { PairingLink, PartnerPairingKey } from 'common/model/partner-pairing';

export enum CompanyType {
    Logistic = 'logistic',
    Individual = 'individual',
    Spedition = 'spedition'
}

export enum BillingCycle {
    Monthly = 'monthly'
}
export interface Styles {
    color: string;
    font: string;
}

export interface PartnerCompanyModel {
    id: string;
    name: string;
    billingCode: string;
    identificationNumber: string;
    referrer?: number;
    vatNumber: string;
    ssoId?: string;
    creationDate: Moment;
    terminationDate: Moment;
    companyType: CompanyType;
    vehiclesNo: number;
    billingCycle: BillingCycle;
    currency: AvailableCurrencies;
    discount: number;
    isPartner?: boolean;
    language?: string;
    geocodingLanguages?: string[];
    address?: PartnerAddressModel[];
    contactPerson?: PartnerContactPersonModel[];
    links?: PairingLink[];
    logo?: string;
    styles?: Styles;
    fleetType?: ReadOnlyClientFleetTypeEnum;
    costPerKm?: { currency: AvailableCurrencies; cost: number };
    services?: {
        id: string;
        name: string;
    }[];
}

export interface PartnerCompanySelectModel {
    id: string;
    label: string;
    isPartner?: boolean;
}

export class PartnerPartnersLogic {
    constructor(private _logic: Logic) {}

    async getPartnerList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        nameIcontains?: string
    ): Promise<PaginatedResponse<PartnerCompanyModel[]>> {
        try {
            const response = await this._logic.api().newClientApi.clientList({
                isPartner: true,
                clientOrReferrer: clientId,
                nameIcontains,
                limit,
                offset
            });
            return {
                data: response.results.map(res => this._toPartner(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Partner client list GET err:', err);
            throw err;
        }
    }

    async assignReferer(clientId: string, refererId: string | null) {
        try {
            return await this._logic.api().newClientApi.clientReferer({
                id: Number(clientId),
                data: { i: refererId ? Number(refererId) : null }
            });
        } catch (err) {
            console.log('Partner assing  err:', err);
            throw err;
        }
    }

    async addPartner(partner: PartnerCompanyModel): Promise<PartnerCompanyModel> {
        try {
            let contactPersonAddress;
            const responseClient = await this._logic.api().newClientApi.clientCreate({
                data: {
                    externalSystem: 5, // TODO fix
                    language: 'ENG', // TODO fix
                    languageIso6391: 'en', // TODO fix
                    isPartner: true,
                    name: partner.name,
                    billingCode: partner.billingCode,
                    identificationNumber: partner.identificationNumber,
                    vatNumber: partner.vatNumber,
                    taxNumber: 'TAX NUasfMBER', // TODO: Fix VAT
                    fieldOfActivity: partner.companyType,
                    bankingConnection: 'SK00123asf1384545475', // TODO fix
                    // emailForInvocing: partner.personEmail,
                    currency: partner.currency as string,
                    // creationDate: moment(),
                    // terminationDate: moment(),
                    // companyType: CompanyType.Individual,
                    // vehiclesNo: 20,
                    // billingCycle: BillingCycle.Monthly,
                    // discount: 10,
                    // addressStreet: 'b',
                    // addressStreetNo: 'b',
                    // addressCity: 'b',
                    // addressCountry: 'b',
                    // addressZipCode: 'b',
                    // personName: 'b',
                    // personSurname: 'b',
                    // personEmail: 'b',
                    // personPhone: 'b',
                    // personPosition: 'b',
                    // personAddressStreet: 'a',
                    // personAddressStreetNo: 'a',
                    // personAddressCity: 'a',
                    // personAddressCountry: 'a',
                    // personAddressZipCode: 'a',
                    logo: partner.logo,
                    styles: partner.styles
                }
            });

            if (partner.address) {
                await this._logic.partner().address().addAddress(partner.address?.[0], responseClient.id); // TODO: now just one address from FE can add
                if (partner.address[1]?.city) {
                    await this._logic.partner().address().addAddress(partner.address?.[1], responseClient.id); // TODO: now just one address from FE can add
                }
            }

            if (partner.contactPerson) {
                if (partner.contactPerson?.[0].address) {
                    contactPersonAddress = await this._logic
                        .partner()
                        .address()
                        .addAddress(partner.contactPerson?.[0].address?.[0]);
                    // TODO: now just one address from FE can add
                }
                await this._logic
                    .partner()
                    .contactPerson()
                    .addContactPerson(partner.contactPerson?.[0], responseClient.id!, contactPersonAddress?.id!); // TODO: now just one address from FE can add
            }
            return this._toPartner(responseClient);
        } catch (err) {
            console.log('Partner client POST err:', err);
            throw err;
        }
    }

    async deletePartner(partner: PartnerCompanyModel): Promise<Boolean> {
        try {
            const response = await this._logic.api().newClientApi.clientDelete({ id: Number(partner.id) });
            return response ? true : false;
        } catch (err) {
            console.log('Partner client DELETE err:', err);
            throw err;
        }
    }

    private _toPartner(
        data: ReadOnlyClient,
        addresses?: PartnerAddressModel[],
        contactPersons?: PartnerContactPersonModel[]
    ): PartnerCompanyModel {
        return {
            id: String(data.id),
            name: data.name ?? '',
            referrer: data.referrer ? data.referrer : undefined,
            billingCode: data.billingCode ?? '',
            identificationNumber: data.identificationNumber ?? '',
            vatNumber: data.vatNumber ?? '',
            creationDate: moment(),
            terminationDate: moment().add(1, 'years'),
            language: data.language ?? 'en',
            geocodingLanguages: [data.language ?? 'en'],
            companyType:
                data.fieldOfActivity && data.fieldOfActivity !== 'N/A'
                    ? (data.fieldOfActivity as CompanyType)
                    : CompanyType.Individual,
            vehiclesNo: 20,
            ssoId: data.externalId,
            billingCycle: BillingCycle.Monthly,
            fleetType: data.fleetType,
            currency: (data.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR,
            discount: 10,
            logo: data.logo ?? '',
            styles: (data.styles ?? {}) as Styles,
            isPartner: data.isPartner,
            address: addresses
                ? addresses
                : (data.addressNew as ReadOnlyAddressNested[]).map(this._logic.partner().address().toAddress),
            contactPerson: contactPersons
                ? contactPersons
                : data.contactpersonSet?.map(cp => this._logic.partner().contactPerson().toContactPerson(cp)),
            links: data.referrer
                ? [
                      {
                          id: 'partner' + data.referrer,
                          key: PartnerPairingKey.partners,
                          rows: [
                              {
                                  name: 'partner',
                                  value: String(data.referrer)
                              }
                          ]
                      }
                  ]
                : undefined
        };
    }
}

export const samplePartners: PartnerCompanyModel[] = [
    {
        id: '1',
        name: 'a',
        billingCode: 'a',
        identificationNumber: 'a',
        vatNumber: 'a',
        creationDate: moment(),
        terminationDate: moment(),
        companyType: CompanyType.Individual,
        vehiclesNo: 20,
        billingCycle: BillingCycle.Monthly,
        currency: AvailableCurrencies.EUR,
        discount: 10,
        address: sampleAddresses,
        ssoId: 'sadasfasasda',
        contactPerson: sampleContactPersons,
        links: [
            {
                key: PartnerPairingKey.companies,
                id: 'comp-a',
                rows: [
                    {
                        name: 'name',
                        value: 'Comp A'
                    }
                ]
            }
        ]
    },
    {
        id: '2',
        name: 'b',
        billingCode: 'b',
        identificationNumber: 'b',
        vatNumber: 'b',
        creationDate: moment(),
        terminationDate: moment(),
        companyType: CompanyType.Individual,
        vehiclesNo: 20,
        billingCycle: BillingCycle.Monthly,
        currency: AvailableCurrencies.CZK,
        discount: 10,
        address: sampleAddresses,
        contactPerson: sampleContactPersons
    }
];
