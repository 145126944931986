import { PoiModel } from 'common/model/poi';
import { PoiType } from 'generated/graphql';

export const poisData = [
    {
        name: 'Eurowag Truck park',
        address: 'Svatá Kateřina 117, 348 06 Rozvadov, Czechia',
        center: { lat: 49.675718, lng: 12.6006297 },
        countryCode: 'CZ',
        forbidden: false,
        notes: '',
        polygon: [
            { lat: 49.67661631528412, lng: 12.6006297 },
            { lat: 49.67616715764206, lng: 12.601831907319427 },
            { lat: 49.675268842357944, lng: 12.601831907319427 },
            { lat: 49.674819684715885, lng: 12.6006297 },
            { lat: 49.675268842357944, lng: 12.599427492680574 },
            { lat: 49.67616715764206, lng: 12.599427492680574 }
        ],
        type: 'parking'
    },
    {
        name: 'Company 1',
        address: 'Rambla de Prim, 125, 08020 Barcelona, Spain',
        center: { lat: 41.419773, lng: 2.2073646 },
        countryCode: 'ES',
        forbidden: false,
        notes: '',
        polygon: [
            { lat: 41.42067131528412, lng: 2.2073646 },
            { lat: 41.42022215764206, lng: 2.2084020473132444 },
            { lat: 41.41932384235794, lng: 2.2084020473132444 },
            { lat: 41.41887468471588, lng: 2.2073646 },
            { lat: 41.41932384235794, lng: 2.2063271526867556 },
            { lat: 41.42022215764206, lng: 2.2063271526867556 }
        ],
        type: 'firm'
    },
    {
        name: 'Poi',
        address: 'A1, 97-360 Kamieńsk, Poland',
        center: { lat: 51.2058251, lng: 19.4813582 },
        countryCode: 'PL',
        forbidden: false,
        notes: '',
        polygon: [
            { lat: 51.20672341528412, lng: 19.4813582 },
            { lat: 51.20627425764206, lng: 19.482599913330382 },
            { lat: 51.20537594235794, lng: 19.482599913330382 },
            { lat: 51.20492678471588, lng: 19.4813582 },
            { lat: 51.20537594235794, lng: 19.480116486669615 },
            { lat: 51.20627425764206, lng: 19.480116486669615 }
        ],
        type: 'firm'
    },
    {
        name: 'Company 2',
        address: 'Am Ravelsberg 54, 52080 Aachen, Germany',
        center: { lat: 50.802596, lng: 6.123141299999999 },
        countryCode: 'DE',
        forbidden: false,
        notes: '',
        polygon: [
            { lat: 50.80349431528412, lng: 6.123141299999999 },
            { lat: 50.80304515764206, lng: 6.124372266879058 },
            { lat: 50.80214684235794, lng: 6.124372266879058 },
            { lat: 50.80169768471588, lng: 6.123141299999999 },
            { lat: 50.80214684235794, lng: 6.1219103331209395 },
            { lat: 50.80304515764206, lng: 6.1219103331209395 }
        ],
        type: 'firm'
    },
    {
        name: 'Company 3',
        address: 'A4 Torino - Trieste, 10038 Chivasso TO, Italy',
        center: { lat: 45.2323822, lng: 7.945062300000001 },
        countryCode: 'IT',
        forbidden: false,
        notes: '',
        polygon: [
            { lat: 45.23328051528412, lng: 7.945062300000001 },
            { lat: 45.23283135764206, lng: 7.946166996648018 },
            { lat: 45.231933042357944, lng: 7.946166996648018 },
            { lat: 45.231483884715885, lng: 7.945062300000001 },
            { lat: 45.231933042357944, lng: 7.943957603351984 },
            { lat: 45.23283135764206, lng: 7.943957603351984 }
        ],
        type: 'firm'
    }
];

export const pois: PoiModel[] = [
    {
        type: PoiType.Parking,
        id: '1',
        name: 'Svatá Kateřina 117, 348 06 Rozvadov, Czechia',
        forbidden: false,
        address: 'Svatá Kateřina 117, 348 06 Rozvadov, Czechia',
        notes: '',
        center: { lat: 49.675718, lng: 12.6006297 },
        polygon: [
            { lat: 49.67661631528412, lng: 12.6006297 },
            { lat: 49.67616715764206, lng: 12.601831907319427 },
            { lat: 49.675268842357944, lng: 12.601831907319427 },
            { lat: 49.674819684715885, lng: 12.6006297 },
            { lat: 49.675268842357944, lng: 12.599427492680574 },
            { lat: 49.67616715764206, lng: 12.599427492680574 }
        ]
    },
    {
        type: PoiType.Firm,
        id: '2',
        name: 'Wyzwolenia 21D83-115 Swarożyn, Poland',
        forbidden: false,
        address: 'A1, 97-360 Kamieńsk, Poland',
        notes: '',
        center: { lat: 51.2058251, lng: 19.4813582 },
        polygon: [
            { lat: 51.20672341528412, lng: 19.4813582 },
            { lat: 51.20627425764206, lng: 19.482599913330382 },
            { lat: 51.20537594235794, lng: 19.482599913330382 },
            { lat: 51.20492678471588, lng: 19.4813582 },
            { lat: 51.20537594235794, lng: 19.480116486669615 },
            { lat: 51.20627425764206, lng: 19.480116486669615 }
        ]
    },
    {
        type: PoiType.Firm,
        id: '3',
        name: 'Highway checkpoint Germany',
        forbidden: false,
        address: 'Am Ravelsberg 54, 52080 Aachen, Germany',
        notes: '',
        center: { lat: 50.802596, lng: 6.123141299999999 },
        polygon: [
            { lat: 50.80349431528412, lng: 6.123141299999999 },
            { lat: 50.80304515764206, lng: 6.124372266879058 },
            { lat: 50.80214684235794, lng: 6.124372266879058 },
            { lat: 50.80169768471588, lng: 6.123141299999999 },
            { lat: 50.80214684235794, lng: 6.1219103331209395 },
            { lat: 50.80304515764206, lng: 6.1219103331209395 }
        ]
    },
    {
        type: PoiType.Firm,
        id: '4',
        name: 'Highway checkpoint',
        forbidden: false,
        address: 'A4 Torino - Trieste, 10038 Chivasso TO, Italy',
        notes: '',
        center: { lat: 45.2323822, lng: 7.945062300000001 },
        polygon: [
            { lat: 45.23328051528412, lng: 7.945062300000001 },
            { lat: 45.23283135764206, lng: 7.946166996648018 },
            { lat: 45.231933042357944, lng: 7.946166996648018 },
            { lat: 45.231483884715885, lng: 7.945062300000001 },
            { lat: 45.231933042357944, lng: 7.943957603351984 },
            { lat: 45.23283135764206, lng: 7.943957603351984 }
        ]
    },
    {
        type: PoiType.Firm,
        id: '5',
        name: ' Rambla de Prim, 125, 08020 Barcelona, Spain',
        forbidden: false,
        address: 'Rambla de Prim, 125, 08020 Barcelona, Spain',
        notes: '',
        center: { lat: 41.41970329999999, lng: 2.2072396 },
        polygon: [
            { lat: 41.420601615284106, lng: 2.2072396 },
            { lat: 41.42015245764205, lng: 2.208277046199826 },
            { lat: 41.41925414235793, lng: 2.208277046199826 },
            { lat: 41.41880498471587, lng: 2.2072396 },
            { lat: 41.41925414235793, lng: 2.2062021538001737 },
            { lat: 41.42015245764205, lng: 2.2062021538001737 }
        ]
    }
];
