import { ColumnType } from 'antd/lib/table';
import Table from 'common/components/Table';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TcoCalculatedTableData } from '../../total-cost-of-ownership-logic';

interface Props extends WithTranslation {
    data: TcoCalculatedTableData[];
}

function TCOCalculatedTable(props: Props) {
    const units = {
        '&euro;': '€',
        years: props.t('years')
    };

    return (
        <Table<TcoCalculatedTableData>
            dataSource={props.data?.map((e, index) => ({ ...e, key: index }))}
            columns={createColumns()}
        />
    );

    function createColumns() {
        if (!props.data) {
            return [];
        }
        type columnType = ColumnType<TcoCalculatedTableData> & { children?: ColumnType<TcoCalculatedTableData>[] };
        const result: columnType[] = [
            {
                title: props.t('common.name'),
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                width: 50,
                render: (val: string) => {
                    return props.t(`TotalCostOfOwnership.table.${val}`);
                }
            },
            {
                title: props.t('common.unit'),
                dataIndex: 'units',
                key: 'units',
                align: 'left',
                width: 50,
                render: (val: string) => {
                    return units[val] ?? val;
                }
            }
        ];

        Object.keys(props.data[0])
            .sort()
            .forEach(key => {
                const splittedTableKey = key.split('.');
                if (splittedTableKey[2] && splittedTableKey[2].toLowerCase() === 'value') {
                    const foundIndex = result.findIndex(r => {
                        return r.dataIndex === `year.${splittedTableKey[1]}`;
                    });

                    if (foundIndex !== -1) {
                        result[foundIndex].children?.push({
                            title: props.t(`TotalCostOfOwnership.table.${splittedTableKey[0]}`),
                            dataIndex: key,
                            key: key,
                            align: 'center',
                            width: 25,
                            render: data => (
                                <span data-qa={qa.totalCostOfOwnership.table.calculated.fieldData}>{data}</span>
                            )
                        });
                    } else {
                        result.push({
                            title: `${splittedTableKey[1]}. ${props.t('common.year')}.`,
                            dataIndex: `year.${splittedTableKey[1]}`,
                            key: key,
                            align: 'center',
                            width: 50,
                            children: [
                                {
                                    title: props.t(`TotalCostOfOwnership.table.${splittedTableKey[0]}`),
                                    dataIndex: key,
                                    key: key,
                                    align: 'center',
                                    width: 25,
                                    render: data => (
                                        <span data-qa={qa.totalCostOfOwnership.table.calculated.fieldData}>{data}</span>
                                    )
                                }
                            ]
                        });
                    }
                } else if (splittedTableKey[0] === 'total') {
                    const foundIndex = result.findIndex(r => {
                        return r.dataIndex === 'total';
                    });

                    if (foundIndex !== -1) {
                        result[foundIndex].children?.push({
                            title: props.t(`TotalCostOfOwnership.table.${splittedTableKey[1]}`),
                            dataIndex: key,
                            key: key,
                            align: 'center',
                            width: 25,
                            render: data => (
                                <span data-qa={qa.totalCostOfOwnership.table.calculated.fieldData}>{data}</span>
                            )
                        });
                    } else {
                        result.push({
                            title: props.t('common.total'),
                            dataIndex: 'total',
                            key: key,
                            align: 'center',
                            width: 50,
                            children: [
                                {
                                    title: props.t(`TotalCostOfOwnership.table.${splittedTableKey[1]}`),
                                    dataIndex: key,
                                    key: key,
                                    align: 'center',
                                    width: 25,
                                    render: data => (
                                        <span data-qa={qa.totalCostOfOwnership.table.calculated.fieldData}>{data}</span>
                                    )
                                }
                            ]
                        });
                    }
                }
            });
        return result as ColumnType<TcoCalculatedTableData>[];
    }
}

export default withTranslation()(TCOCalculatedTable);
