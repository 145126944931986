/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChannelInfo,
    ChannelInfoFromJSON,
    ChannelInfoToJSON,
} from '../models';

/**
 * no description
 */
export class MessagingApi extends runtime.BaseAPI {

    /**
     * sync and get ew truck driver channel of driver
     */
    async syncV1MessagingChannelSyncGetRaw(): Promise<runtime.ApiResponse<ChannelInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/messaging/channel/sync/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChannelInfoFromJSON(jsonValue));
    }

    /**
     * sync and get ew truck driver channel of driver
     */
    async syncV1MessagingChannelSyncGet(): Promise<ChannelInfo> {
        const response = await this.syncV1MessagingChannelSyncGetRaw();
        return await response.value();
    }

}
