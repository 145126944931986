import { PairingLink, PartnerPairingKey } from 'common/model/partner-pairing';
import { Logic } from 'logic/logic';
import { MonitoringDevice, ReadOnlyMonitoringDeviceNested, ReadOnlyMonitoringDeviceType } from 'generated/new-main';
import { PaginatedResponse } from 'common/model/pagination';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET } from 'domain-constants';

export interface ObuModel {
    id: string;
    sn: string;
    type: string;
    activatedOn?: string;
    clientId?: string;
    clientName?: string;
    vehicleId?: string;
    vehicleRN?: string;
    links?: PairingLink[];
    disabledAt?: Date;
}

export class PartnerObuLogic {
    constructor(private _logic: Logic) {}
    async getObuList(
        clientId?: string,
        limit = DEFAULT_PAGE_LIMIT,
        offset = DEFAULT_PAGE_OFFSET,
        serialNumberIcontains?: string
    ): Promise<PaginatedResponse<ObuModel[]>> {
        try {
            const response = await this._logic.api().monitoringDeviceApi.monitoringDeviceNested({
                client: clientId,
                limit,
                offset,
                serialNumberIcontains
            });
            return {
                data: response.results.map(res => this._toNestedObu(res)),
                total: response.count,
                limit,
                offset
            };
        } catch (err) {
            console.log('Obu list GET err:', err);
            throw err;
        }
    }

    async getObuListTypes(): Promise<ReadOnlyMonitoringDeviceType[]> {
        try {
            const response = await this._logic.api().monitoringDeviceTypeApi.monitoringDeviceTypeList({});
            return response.results;
        } catch (err) {
            console.log('Obu list types GET err:', err);
            throw err;
        }
    }

    async editObu(obu: ObuModel, externalSystem: number): Promise<ObuModel> {
        try {
            const response = await this._logic.api().monitoringDeviceApi.monitoringDeviceUpdate({
                id: Number(obu.id),
                data: {
                    clientId: Number(obu.clientId),
                    monitoringDeviceType: Number(obu.type),
                    serialNumber: obu.sn,
                    externalSystem
                }
            });
            return this._toObu(response);
        } catch (err) {
            console.log('Obu POST err:', err);
            throw err;
        }
    }

    async addObu(obu: ObuModel, externalSystem: number): Promise<ObuModel> {
        try {
            const response = await this._logic.api().monitoringDeviceApi.monitoringDeviceCreate({
                data: {
                    clientId: Number(obu.clientId),
                    monitoringDeviceType: Number(obu.type),
                    serialNumber: obu.sn,
                    externalSystem
                }
            });
            return this._toObu(response);
        } catch (err) {
            console.log('Obu POST err:', err);
            throw err;
        }
    }

    async getObu(obuId: string): Promise<ObuModel> {
        try {
            const response = await this._logic.api().monitoringDeviceApi.monitoringDeviceNestedSingle({
                id: Number(obuId)
            });
            return this._toNestedObu(response);
        } catch (err) {
            console.log('Obu GET err:', err);
            throw err;
        }
    }

    async deleteObu(obu: ObuModel): Promise<Boolean> {
        try {
            const response = await this._logic.api().monitoringDeviceApi.monitoringDeviceDelete({ id: Number(obu.id) });
            return response ? true : false;
        } catch (err) {
            console.log('Obu DELETE err:', err);
            throw err;
        }
    }

    async assignObuPermissions(obeId: string, permissions?: string) {
        try {
            if (permissions) {
                const response = await this._logic.api().cgiPartnerModuleApi.obePermissionsObePermissionsPost({
                    messageId: '',
                    bundlesObePermissions: { permissions, obeId }
                    //clientId?: 0
                });
                return response ? true : false;
            } else {
                return false;
            }
        } catch (err) {
            console.log('Assign obu perrmissions err:', err);
            throw err;
        }
    }

    private _toObu(data: MonitoringDevice): ObuModel {
        // TODO: get monitored object
        const links = data.externalVehicleId
            ? [
                  {
                      id: String(data.externalVehicleId),
                      key: PartnerPairingKey.vehicles,
                      rows: [
                          {
                              name: 'id',
                              value: String(data.externalVehicleId)
                          }
                      ]
                  }
              ]
            : undefined;

        return {
            id: String(data.id),
            sn: data.serialNumber,
            type: String(data.monitoringDeviceType),
            activatedOn: String(data.monitoringDeviceStatus),
            clientId: String(data.client?.id),
            clientName: String(data.client?.name),
            vehicleId: data.externalVehicleId ?? '',
            links: links
        };
    }

    private _toNestedObu(data: ReadOnlyMonitoringDeviceNested): ObuModel {
        const monitoredObject: PairingLink | undefined = data.monitoredObject
            ? {
                  id: String(data.monitoredObject.id),
                  key: PartnerPairingKey.vehicles,
                  rows: [
                      {
                          name: 'licensePlate',
                          value: data.monitoredObject.registrationNumber
                      }
                  ]
              }
            : undefined;
        let links: PairingLink[] = [];

        if (monitoredObject) {
            links.push(monitoredObject);
        }

        return {
            id: String(data.id),
            sn: data.serialNumber,
            type: String(data.monitoringDeviceType),
            activatedOn: String(data.monitoringDeviceStatus),
            clientId: String(data.client?.id),
            clientName: String(data.client?.name),
            vehicleId: String(data.monitoredObject?.id),
            vehicleRN: data.monitoredObject?.registrationNumber ?? '',
            links: links,
            disabledAt: data.monitoredObject?.disabledAt ?? undefined
        };
    }
}

export const sampleObu: ObuModel[] = [
    {
        id: 'obu-a',
        type: '1',
        activatedOn: 'Paired',
        sn: 'fdlkjflk',
        links: [
            {
                key: PartnerPairingKey.companies,
                id: 'comp-a',
                rows: [
                    {
                        name: 'name',
                        value: 'Comp A'
                    }
                ]
            },
            {
                key: PartnerPairingKey.tlmBundles,
                id: 'tlm-a',
                rows: [
                    {
                        name: 'name',
                        value: 'TLM Bundle A'
                    }
                ]
            },
            {
                key: PartnerPairingKey.vehicles,
                id: 'veh-a',
                rows: [
                    {
                        name: 'vehicle',
                        value: 'NZ 512 AA'
                    }
                ]
            }
        ]
    },
    {
        id: 'obu-b',
        sn: '4545454545656565',
        type: '1',
        activatedOn: 'Paired'
    }
];
