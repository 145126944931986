import React from 'react';
import SuperAdminBar from './SuperAdminBar';
import SuperAdminSearch from './SuperAdminSearch';
import SuperAdminTable from './SuperAdminTable';
import SuperAdminUserWindow from './SuperAdminUserWindow';
import { SuperAdminUserModel, SuperAdminCompanyModel } from '../SuperAdminModule';
import { CountrySelectModel } from 'logic/partner/partner';
import { LayoutContent } from 'common/components/Layout/Content';

interface Props {
    search?: { text: string };
    users?: {
        search?: { text: string };
        ssoUsers?: boolean;
        table?: {
            data: SuperAdminUserModel[];
            loading: boolean;
        };
    };
    table?: {
        data: SuperAdminCompanyModel[];
        selected?: SuperAdminCompanyModel;
        loading: boolean;
        countryCounter?: CountrySelectModel[];
    };
    onBarSearchClick?: () => void;
    onSearchChange: (text: string) => void;
    onFormCancel: () => void;
    onTableRowClick?: (id: string) => void;
    onUsersSearchChange: (text: string) => void;
    onSsoUserChecboxChange: (value: boolean) => void;
    onUserSubmit?: (user: SuperAdminUserModel) => void;
}

export function SuperAdmin(props: Props) {
    return (
        <div className="superadmin module">
            <LayoutContent
                className="management-fleet"
                mainSizes={{ xs: 16, sm: 16, md: 16 }}
                extraSizes={[{ xs: 8, sm: 8, md: 8 }]}
                main={
                    <>
                        <SuperAdminBar />
                        {props.search && <SuperAdminSearch text={props.search.text} onChange={props.onSearchChange} />}
                        {props.table && (
                            <SuperAdminTable
                                data={props.table.data}
                                selected={props.table.selected}
                                countryCounter={props.table.countryCounter}
                                loading={props.table.loading}
                                onRowClick={props.onTableRowClick}
                            />
                        )}
                    </>
                }
                extra={
                    props.users?.table
                        ? [
                              <SuperAdminUserWindow
                                  data={props.users.table.data}
                                  ssoUsers={props.users.ssoUsers}
                                  search={props.users.search}
                                  loading={props.users.table.loading}
                                  onCancel={props.onFormCancel}
                                  onSearchChange={props.onUsersSearchChange}
                                  onSsoUserChecboxChange={props.onSsoUserChecboxChange}
                                  onUserSubmit={props.onUserSubmit}
                              />
                          ]
                        : undefined
                }
            />
        </div>
    );
}
