/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyDiemsRules2
 */
export interface WriteOnlyDiemsRules2 {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDiemsRules2
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyDiemsRules2
     */
    ruleValue: object;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyDiemsRules2
     */
    dayFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyDiemsRules2
     */
    dayTo: Date;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDiemsRules2
     */
    ruleName: WriteOnlyDiemsRules2RuleNameEnum;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDiemsRules2
     */
    ruleClass: WriteOnlyDiemsRules2RuleClassEnum;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules2
     */
    client: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules2
     */
    user: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDiemsRules2
     */
    lastModifiedBy?: number | null;
}

export function WriteOnlyDiemsRules2FromJSON(json: any): WriteOnlyDiemsRules2 {
    return WriteOnlyDiemsRules2FromJSONTyped(json, false);
}

export function WriteOnlyDiemsRules2FromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyDiemsRules2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleValue': json['rule_value'],
        'dayFrom': (new Date(json['day_from'])),
        'dayTo': (new Date(json['day_to'])),
        'ruleName': json['rule_name'],
        'ruleClass': json['rule_class'],
        'client': json['client'],
        'user': json['user'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
    };
}

export function WriteOnlyDiemsRules2ToJSON(value?: WriteOnlyDiemsRules2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rule_value': value.ruleValue,
        'day_from': (value.dayFrom.toISOString().substr(0,10)),
        'day_to': (value.dayTo.toISOString().substr(0,10)),
        'rule_name': value.ruleName,
        'rule_class': value.ruleClass,
        'client': value.client,
        'user': value.user,
        'last_modified_by': value.lastModifiedBy,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyDiemsRules2RuleNameEnum {
    DiemsRate = 'diems_rate'
}
/**
* @export
* @enum {string}
*/
export enum WriteOnlyDiemsRules2RuleClassEnum {
    User = 'user'
}


