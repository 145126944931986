import { Loading } from 'common/components';

interface Props {
    type?: 'default' | 'data';
    dark?: boolean;
    fullSize?: boolean;
    maxHeight?: '100' | '200' | '300' | '400' | '500';
    width?: number;
    height?: number;
}

export function DriverBehaviorLoading(props: Props) {
    return <Loading customClassName="driver-behavior-loading" {...props} />;
}
