import { Upload } from 'antd';
import { UploadProps, DraggerProps } from 'antd/lib/upload';
import * as React from 'react';

export default function EnhancedUpload({ children, ...props }: UploadProps & { children?: React.ReactNode }) {
    return <Upload {...props}>{children}</Upload>;
}

const { Dragger } = Upload;
export function EnhancedDragger({ children, ...props }: DraggerProps & { children?: React.ReactNode }) {
    return <Dragger {...props}>{children}</Dragger>;
}
