/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    TransportTemplate,
    TransportTemplateFromJSON,
    TransportTemplateToJSON,
    TransportTemplateInDb,
    TransportTemplateInDbFromJSON,
    TransportTemplateInDbToJSON,
} from '../models';

export interface TransportTemplateDeactivateV1TransportsTemplateIdDeleteRequest {
    id: string;
}

export interface TransportTemplateFindOneV1TransportsTemplateIdGetRequest {
    id: string;
}

export interface TransportTemplateFindV1TransportsTemplateGetRequest {
    name?: string;
    projection?: Array<TransportTemplateFindV1TransportsTemplateGetProjectionEnum>;
}

export interface TransportTemplateInsertV1TransportsTemplatePostRequest {
    transportTemplate: TransportTemplate;
}

export interface TransportTemplateRenameV1TransportsTemplateIdPatchRequest {
    id: string;
    name: string;
}

/**
 * no description
 */
export class TransportTemplateApi extends runtime.BaseAPI {

    /**
     * Transport Template Deactivate
     */
    async transportTemplateDeactivateV1TransportsTemplateIdDeleteRaw(requestParameters: TransportTemplateDeactivateV1TransportsTemplateIdDeleteRequest): Promise<runtime.ApiResponse<TransportTemplateInDb>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transportTemplateDeactivateV1TransportsTemplateIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportTemplateInDbFromJSON(jsonValue));
    }

    /**
     * Transport Template Deactivate
     */
    async transportTemplateDeactivateV1TransportsTemplateIdDelete(requestParameters: TransportTemplateDeactivateV1TransportsTemplateIdDeleteRequest): Promise<TransportTemplateInDb> {
        const response = await this.transportTemplateDeactivateV1TransportsTemplateIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a template by id
     * Transport Template Find One
     */
    async transportTemplateFindOneV1TransportsTemplateIdGetRaw(requestParameters: TransportTemplateFindOneV1TransportsTemplateIdGetRequest): Promise<runtime.ApiResponse<TransportTemplateInDb>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transportTemplateFindOneV1TransportsTemplateIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportTemplateInDbFromJSON(jsonValue));
    }

    /**
     * Get a template by id
     * Transport Template Find One
     */
    async transportTemplateFindOneV1TransportsTemplateIdGet(requestParameters: TransportTemplateFindOneV1TransportsTemplateIdGetRequest): Promise<TransportTemplateInDb> {
        const response = await this.transportTemplateFindOneV1TransportsTemplateIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search for template by name
     * Transport Template Find
     */
    async transportTemplateFindV1TransportsTemplateGetRaw(requestParameters: TransportTemplateFindV1TransportsTemplateGetRequest): Promise<runtime.ApiResponse<Array<TransportTemplateInDb>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.projection) {
            queryParameters['projection'] = requestParameters.projection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/template/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransportTemplateInDbFromJSON));
    }

    /**
     * Search for template by name
     * Transport Template Find
     */
    async transportTemplateFindV1TransportsTemplateGet(requestParameters: TransportTemplateFindV1TransportsTemplateGetRequest): Promise<Array<TransportTemplateInDb>> {
        const response = await this.transportTemplateFindV1TransportsTemplateGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Internal use only, fe should not use this endpoint
     */
    async transportTemplateInsertV1TransportsTemplatePostRaw(requestParameters: TransportTemplateInsertV1TransportsTemplatePostRequest): Promise<runtime.ApiResponse<TransportTemplateInDb>> {
        if (requestParameters.transportTemplate === null || requestParameters.transportTemplate === undefined) {
            throw new runtime.RequiredError('transportTemplate','Required parameter requestParameters.transportTemplate was null or undefined when calling transportTemplateInsertV1TransportsTemplatePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/template/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransportTemplateToJSON(requestParameters.transportTemplate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportTemplateInDbFromJSON(jsonValue));
    }

    /**
     * Internal use only, fe should not use this endpoint
     */
    async transportTemplateInsertV1TransportsTemplatePost(requestParameters: TransportTemplateInsertV1TransportsTemplatePostRequest): Promise<TransportTemplateInDb> {
        const response = await this.transportTemplateInsertV1TransportsTemplatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Transport Template Rename
     */
    async transportTemplateRenameV1TransportsTemplateIdPatchRaw(requestParameters: TransportTemplateRenameV1TransportsTemplateIdPatchRequest): Promise<runtime.ApiResponse<TransportTemplateInDb>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transportTemplateRenameV1TransportsTemplateIdPatch.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling transportTemplateRenameV1TransportsTemplateIdPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transports/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransportTemplateInDbFromJSON(jsonValue));
    }

    /**
     * Transport Template Rename
     */
    async transportTemplateRenameV1TransportsTemplateIdPatch(requestParameters: TransportTemplateRenameV1TransportsTemplateIdPatchRequest): Promise<TransportTemplateInDb> {
        const response = await this.transportTemplateRenameV1TransportsTemplateIdPatchRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum TransportTemplateFindV1TransportsTemplateGetProjectionEnum {
    Name = 'name',
    All = 'all'
}
