import { Dropdown, Menu } from 'common/components';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';
import { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    position?: {
        x: number;
        y: number;
    };
    controlOnRoute?: 'edit' | 'preview';
    createPoiPossible?: boolean;
    startPlacePossible?: boolean;
    roles: Role[];
    onCreatePoi?: () => void;
    onAddPlannerPlace?: (position: 'start' | 'waypoint' | 'end') => void;
    children: ReactNode;
    loading?: boolean;
}

function ContextMenu(props: Props) {
    const disabled = !!Number(localStorage.getItem('map-context-disabled')) || props.loading;

    if ([Role.POI_W, Role.PLN_R].some(role => props.roles.includes(role))) {
        return (
            <Dropdown
                overlay={
                    <Menu>
                        {props.roles.includes(Role.PLN_R) && props.controlOnRoute === 'edit' && (
                            <>
                                {props.startPlacePossible && (
                                    <Menu.Item key="startFromHere" disabled={disabled}>
                                        <div
                                            onClick={() => !disabled && props.onAddPlannerPlace?.('start')}
                                            data-qa={qa.mapContextMenu.btnStartFromHere}
                                        >
                                            {props.t('ContextMenu.startFromHere')}
                                        </div>
                                    </Menu.Item>
                                )}
                                <Menu.Item key="directionToHere" disabled={disabled}>
                                    <div
                                        onClick={() => !disabled && props.onAddPlannerPlace?.('end')}
                                        data-qa={qa.mapContextMenu.btnDirectionToHere}
                                    >
                                        {props.t('ContextMenu.directionToHere')}
                                    </div>
                                </Menu.Item>
                                {/* <Menu.Item key="addToRoute" disabled={disabled}>
                                    <div
                                        onClick={() => props.onAddPlannerPlace?.('waypoint')}
                                        data-qa={qa.mapContextMenu.btnAddToRoute}
                                    >
                                        {props.t('ContextMenu.addToRoute')}
                                    </div>
                                </Menu.Item> */}
                            </>
                        )}
                        {props.roles.includes(Role.POI_W) && props.createPoiPossible && (
                            <Menu.Item key="createPoi">
                                <div onClick={props.onCreatePoi} data-qa={qa.mapContextMenu.btnCreatePoi}>
                                    {props.t('ContextMenu.createNewCustomPlace')}
                                </div>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['contextMenu']}
            >
                {props.children}
            </Dropdown>
        );
    } else {
        return <>{props.children}</>;
    }
}

export default withTranslation()(ContextMenu);
