import { LayoutContent } from 'common/components/Layout/Content';
import { Logic } from 'logic/logic';
import TotalCostOfOwnershipModule from './TotalCostOfOwnershipModule';
interface Props {
    logic: Logic;
}

export default function TotalCOstOfOwnershipLayout(props: Props) {
    return (
        <LayoutContent
            mainSizes={{ xs: 24, sm: 24, md: 24 }}
            extraSizes={[{ xs: 24, sm: 24, md: 24 }]}
            main={
                <div className="web-app-tco">
                    <TotalCostOfOwnershipModule
                        isSuperAdmin={props.logic.auth().superadmin()}
                        logic={props.logic.tcoLogic()}
                    />
                </div>
            }
            extra={[]}
        />
    );
}
