/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimePeriod,
    DatetimePeriodFromJSON,
    DatetimePeriodFromJSONTyped,
    DatetimePeriodToJSON,
    Place,
    PlaceFromJSON,
    PlaceFromJSONTyped,
    PlaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleUnavailabilityForUpdate
 */
export interface VehicleUnavailabilityForUpdate {
    /**
     * 
     * @type {string}
     * @memberof VehicleUnavailabilityForUpdate
     */
    reasonMessage: string;
    /**
     * 
     * @type {Place}
     * @memberof VehicleUnavailabilityForUpdate
     */
    place?: Place;
    /**
     * 
     * @type {DatetimePeriod}
     * @memberof VehicleUnavailabilityForUpdate
     */
    datetimePeriod: DatetimePeriod;
}

export function VehicleUnavailabilityForUpdateFromJSON(json: any): VehicleUnavailabilityForUpdate {
    return VehicleUnavailabilityForUpdateFromJSONTyped(json, false);
}

export function VehicleUnavailabilityForUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleUnavailabilityForUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonMessage': json['reasonMessage'],
        'place': !exists(json, 'place') ? undefined : PlaceFromJSON(json['place']),
        'datetimePeriod': DatetimePeriodFromJSON(json['datetimePeriod']),
    };
}

export function VehicleUnavailabilityForUpdateToJSON(value?: VehicleUnavailabilityForUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonMessage': value.reasonMessage,
        'place': PlaceToJSON(value.place),
        'datetimePeriod': DatetimePeriodToJSON(value.datetimePeriod),
    };
}


