/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityIntervalExternalDevice,
    ActivityIntervalExternalDeviceFromJSON,
    ActivityIntervalExternalDeviceFromJSONTyped,
    ActivityIntervalExternalDeviceToJSON,
    DriverInfoDriver,
    DriverInfoDriverFromJSON,
    DriverInfoDriverFromJSONTyped,
    DriverInfoDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivityIntervalLive
 */
export interface ActivityIntervalLive {
    /**
     * 
     * @type {string}
     * @memberof ActivityIntervalLive
     */
    muDataOriginalJstr?: string;
    /**
     * 
     * @type {DriverInfoDriver}
     * @memberof ActivityIntervalLive
     */
    driver?: DriverInfoDriver;
    /**
     * 
     * @type {Array<DriverInfoDriver>}
     * @memberof ActivityIntervalLive
     */
    passengers?: Array<DriverInfoDriver>;
    /**
     * 
     * @type {DriverInfoDriver}
     * @memberof ActivityIntervalLive
     */
    defaultDriver?: DriverInfoDriver;
    /**
     * 
     * @type {Array<ActivityIntervalExternalDevice>}
     * @memberof ActivityIntervalLive
     */
    externalDevices?: Array<ActivityIntervalExternalDevice>;
    /**
     * 
     * @type {Date}
     * @memberof ActivityIntervalLive
     */
    externalDevicesLastTs?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityIntervalLive
     */
    lastUpdate?: Date;
}

export function ActivityIntervalLiveFromJSON(json: any): ActivityIntervalLive {
    return ActivityIntervalLiveFromJSONTyped(json, false);
}

export function ActivityIntervalLiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityIntervalLive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'muDataOriginalJstr': !exists(json, 'mu_data_original_jstr') ? undefined : json['mu_data_original_jstr'],
        'driver': !exists(json, 'driver') ? undefined : DriverInfoDriverFromJSON(json['driver']),
        'passengers': !exists(json, 'passengers') ? undefined : ((json['passengers'] as Array<any>).map(DriverInfoDriverFromJSON)),
        'defaultDriver': !exists(json, 'default_driver') ? undefined : DriverInfoDriverFromJSON(json['default_driver']),
        'externalDevices': !exists(json, 'external_devices') ? undefined : ((json['external_devices'] as Array<any>).map(ActivityIntervalExternalDeviceFromJSON)),
        'externalDevicesLastTs': !exists(json, 'external_devices_last_ts') ? undefined : (new Date(json['external_devices_last_ts'])),
        'lastUpdate': !exists(json, 'last_update') ? undefined : (new Date(json['last_update'])),
    };
}

export function ActivityIntervalLiveToJSON(value?: ActivityIntervalLive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mu_data_original_jstr': value.muDataOriginalJstr,
        'driver': DriverInfoDriverToJSON(value.driver),
        'passengers': value.passengers === undefined ? undefined : ((value.passengers as Array<any>).map(DriverInfoDriverToJSON)),
        'default_driver': DriverInfoDriverToJSON(value.defaultDriver),
        'external_devices': value.externalDevices === undefined ? undefined : ((value.externalDevices as Array<any>).map(ActivityIntervalExternalDeviceToJSON)),
        'external_devices_last_ts': value.externalDevicesLastTs === undefined ? undefined : (value.externalDevicesLastTs.toISOString()),
        'last_update': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString()),
    };
}


