import { google } from 'google-maps';
import { MapLogic } from '../map';
import { PoiMarker, PoiMarkerData } from './poi-marker';
import { ReadOnlyCurrency } from 'generated/new-main';
import { LatLng } from 'common/model/geo';
import { AlarmType } from 'generated/backend-api';
import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { PoiType } from 'generated/graphql';
import { MAP_MARKERS_INDEX } from 'domain-constants';
import { bestPriceClusterCalculator, fsBestPriceInPois } from 'common/utils/best-price';
import * as mapMarkers from 'resources/images/map';

export enum FuelStationServices {
    WF00001 = 'MYCKA',
    WF00002 = 'NONSTOP',
    WF00003 = 'OBCERSTVENI',
    WF00004 = 'PARKOVISTE',
    WF00005 = 'PARKOVISTE_hlid',
    WF00006 = 'PNEU',
    WF00007 = 'REST',
    WF00008 = 'RESTAURACE',
    WF00009 = 'SELFSERVICE',
    WF00010 = 'SHOP',
    WF00011 = 'SPRCHA',
    WF00012 = 'STK',
    WF00013 = 'TOLL',
    WF00014 = 'WiFi',
    WF00015 = 'TRUCK_WASH',
    WF00016 = 'KAMEROVYZAZNAM',
    WF00017 = 'DO3,5TUNY',
    WF00018 = 'NAD3,5TUNY'
}

export enum FuelStationServicesTranslations {
    WF00001 = 'carWash',
    WF00002 = 'nonstop',
    WF00003 = 'refreshment',
    WF00004 = 'parking',
    WF00005 = 'parkingSecure',
    WF00006 = 'pneu',
    WF00007 = 'rest',
    WF00008 = 'restaurant',
    WF00009 = 'selfService',
    WF00010 = 'shop',
    WF00011 = 'shower',
    WF00012 = 'carTechCheckPlace',
    WF00013 = 'toll',
    WF00014 = 'wifi',
    WF00015 = 'truckWash',
    WF00016 = 'cameraArea',
    WF00017 = 'under3_5t',
    WF00018 = 'over3_5t'
}

export enum FuelTypes {
    '010003' = 'Diesel',
    '010004' = 'BA95N',
    '010006' = 'AdBlue',
    '010007' = 'LTO-E',
    '010008' = 'ArticDiesel',
    '010014' = 'AdBlue-can20',
    '010015' = 'AdBlue-canwithoutnozzle',
    '010017' = 'BA98N',
    '010021' = 'EkoDiesel30/70',
    '010022' = 'Biodiesel50/50',
    '010023' = 'Biodiesel70/30',
    '010024' = 'Biodiesel100',
    '010025' = 'AdBlueEsso-can',
    '010026' = 'AdBlue-can18',
    '010029' = 'AdBlue-BG',
    '010030' = 'Diesel',
    '010031' = 'Diesel',
    '010032' = 'DieselEWPremium',
    '010035' = 'BA95NPremium',
    '010036' = 'DieselEconomy',
    '010037' = 'RedDieselES',
    '010038' = 'LNG',
    '010039' = 'CNG',
    '010041' = 'Gasoline99+',
    '010044' = 'LPG'
}

// Function for change special chars as -,/,+,, to _
export const fuelTypeToFuelIconName = (name?: string): string => {
    return name?.replace(/\/|\+|-+/g, '_') ?? '';
};

export const fuelToFuelTypeMap = {
    diesel: ['010003', '010008', '010021', '010030', '010031', '010032', '010036'],
    biodiesel: ['010022', '010023', '010024'],
    adblue: ['010006', '010014', '010015', '010025', '010026', '010029'],
    natural95: ['010004', '010035'],
    natural98: ['010017'],
    cng: ['010039', '010038'],
    electro: []
};

export const serviceToTypeMap = {
    wc: ['WF00009'],
    shower: ['WF00011'],
    food: ['WF00003', 'WF00008', 'WF00010'],
    carWash: ['WF00001', 'WF00015'],
    special: []
};

export interface PoiModelMapFuelType {
    name: string;
    code: string;
    price?: {
        price: number;
        source: string;
        currency: string;
        type: string;
        productId: string;
        sumExtraFees: string;
    };
}

export interface PoiOpeningHours {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}

export interface PoiModelMap {
    id: string;
    name: string;
    alarmType?: AlarmType;
    date?: string;
    detailAddress?: string;
    selected: boolean;
    inTransport?: boolean;
    position: LatLng;
    fuelTypes?: PoiModelMapFuelType[];
    bestPrice?: boolean;
    acknowledged?: boolean;
    services?: { name: string; code: string }[];
    routeIndex?: number;
    externalId?: string;
    type?: PoiType;
    forbidden?: boolean;
    notes?: string;
    openingHours?: PoiOpeningHours;
}

export class FuelStationsMapController {
    visible: boolean;

    private _map?: google.maps.Map;
    private _markers: google.maps.Marker[];
    private _cluster?: MarkerClusterer;
    private _google: google;
    private _currencies?: ReadOnlyCurrency[];
    private _ctrl: MapLogic;

    private _onFuelStationClick?: (fuelStation: PoiModelMap) => void;
    private _onFuelStationClusterClick?: () => void;
    private _onBestPriceChange?: (poi?: PoiModelMap[]) => void;

    private readonly _clusterOptions: MarkerClustererOptions = {
        maxZoom: 20,
        gridSize: 150,
        zoomOnClick: false,
        enableRetinaIcons: false,
        clusterClass: 'fs-cluster',
        styles: [
            {
                url: mapMarkers.fuelStation,
                height: 67,
                width: 64,
                textSize: 18,
                textColor: '#000000'
            }
        ],
        zIndex: MAP_MARKERS_INDEX.WASH
    };

    constructor(google: google, ctrl: MapLogic, map?: google.maps.Map) {
        this._ctrl = ctrl;
        this._map = map;
        this._google = google;
        this._markers = [];
        this.visible = false;
    }

    onBestPriceChange(cb: (poi?: PoiModelMap[]) => void) {
        this._onBestPriceChange = cb;
    }

    setCurrencies(currencies: ReadOnlyCurrency[]) {
        this._currencies = currencies;
    }

    show(): void {
        this._renderCluster();
        this.visible = true;
    }

    hide(): void {
        if (this._cluster) {
            this._cluster.setMap(null);
            this._cluster.clearMarkers();
        }

        this.visible = false;
    }

    onClick(cb?: (fuelStation: PoiModelMap) => void): void {
        this._onFuelStationClick = cb;
    }

    onFuelStationClusterClick(cb?: () => void): void {
        this._onFuelStationClusterClick = cb;
    }

    setData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster.clearMarkers();
        }
        this._markers = data.map(fuelStation => this._createFuelStationMarker(fuelStation));
    }

    updateData(data: PoiModelMap[]): void {
        if (this._cluster) {
            this._cluster.clearMarkers();
        }
        this._markers = data.map(fuelStation => this._createFuelStationMarker(fuelStation));

        if (this.visible) {
            this._renderCluster();
        }
    }

    updatePartialData(data: PoiModelMap[], resetBestPriceDuringCycle = false, redraw = false, selected = false): void {
        const markers = this._cluster?.getMarkers();

        if (!markers) {
            return;
        }

        // Since we have really big data set we're using for, maybe can be even better
        const markersMap: { [key: string]: PoiMarker } = {};

        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i] as unknown as PoiMarker;
            markersMap[marker.data().id] = marker;

            if (selected && data[0].selected) {
                marker.setData({
                    ...marker.data(),
                    selected: marker.data().id === data[0].id ? true : false
                });
            }

            if (resetBestPriceDuringCycle && marker.data().bestPrice) {
                marker.setData({ ...marker.data(), bestPrice: false });
            }
        }

        for (let i = 0; i < data.length; i++) {
            const update = data[i];
            const marker = markersMap[update.id];
            if (marker) {
                marker.setData({ ...marker.data(), ...update, selected: marker.data().selected });
            }
        }

        if (redraw) {
            this._cluster?.repaint();
        }
    }

    private _onClusterClick = (cluster: MarkerClusterer) => {
        const bounds = new this._google.maps.LatLngBounds();

        cluster.getMarkers().forEach(marker => bounds.extend(marker.getPosition()!));
        this._onFuelStationClusterClick?.();
        this._map?.fitBounds(bounds, this._ctrl.getPadding());
    };

    private _renderCluster(): void {
        const clusteringEndHandler = () => {
            const bounds = this._ctrl.reduceBounds(this._map!.getBounds()!, 5);
            const inView = this._cluster?.getClusters().filter(c => bounds.contains(c.getCenter()));

            if (!inView) {
                return;
            }

            const data = inView
                ?.map(cluster => {
                    const markers = cluster.getMarkers().map(m => (m as unknown as PoiMarker).data());
                    return fsBestPriceInPois(markers, this._currencies)!;
                })
                .filter(e => Boolean(e));

            const bestPrice = fsBestPriceInPois(data.flat(), this._currencies);

            if (bestPrice && bestPrice.length > 0) {
                bestPrice.forEach(bp => {
                    bp.bestPrice = true;
                });
                this.updatePartialData(bestPrice, true);
            }

            this._onBestPriceChange?.(bestPrice);
        };

        if (this._map) {
            if (this._cluster) {
                this._cluster.clearMarkers();
            }

            this._cluster = new MarkerClusterer(this._map, this._markers, this._clusterOptions);
            this._cluster.addListener('clusterclick', this._onClusterClick);
            this._cluster.addListener('clusteringend', clusteringEndHandler);
            this._cluster.setCalculator(markers => {
                return bestPriceClusterCalculator(markers, this._currencies!);
            });
        }
    }

    private _createFuelStationMarker(fuelStation: PoiModelMap): google.maps.Marker {
        const poiData: PoiMarkerData = {
            type: 'fuelStation',
            data: {
                ...fuelStation
            }
        };

        const marker = new PoiMarker(this._google, new this._google.maps.LatLng(fuelStation.position), poiData);

        marker.onPoiClick(poiData => {
            if (poiData.type === 'fuelStation') {
                this._onFuelStationClick?.(poiData.data);
            }
        });

        return marker as any;
    }
}
