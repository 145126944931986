import moment from 'moment';
import { action, makeObservable, observable } from 'mobx';
import { Logic } from 'logic/logic';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'domain-constants';
import { JourneyStatisticsResponseStats } from 'generated/backend-api';
import { DriverModel } from 'logic/user/users';
import { StatisticReportFilterModel } from './components/StatisticReportBar';
import { VehicleIdentification } from 'common/components/Settings';
import { ReadOnlyMonitoredObjectFeSb, ReadOnlyMonitoredObjectGroup } from 'generated/new-main';

export enum StatisticReportGroup {
    ByDays = 'byDays',
    ByMonths = 'byMonths',
    ByVehicles = 'byVehicles',
    ByDrivers = 'byDrivers',
    Summary = 'summary'
}

export class StatisticReportLogic {
    logic: Logic;

    @observable statisticReport: (JourneyStatisticsResponseStats & { name?: string })[];
    @observable statisticReportGroup: StatisticReportGroup;
    @observable statisticReportFilter: StatisticReportFilterModel;
    @observable loadingInit?: boolean;
    @observable loadingStatisticReport?: boolean;
    @observable drivers: DriverModel[];
    @observable vehicles: ReadOnlyMonitoredObjectFeSb[];
    @observable vehicleGroups: ReadOnlyMonitoredObjectGroup[];
    @observable vehicleGroupsVehicles: { id: number; vehicleIds: string[] }[];

    constructor(logic: Logic) {
        this.logic = logic;
        this.statisticReport = [];
        this.drivers = [];
        this.vehicles = [];
        this.vehicleGroups = [];
        this.vehicleGroupsVehicles = [];
        this.statisticReportGroup = StatisticReportGroup.ByDays;
        this.statisticReportFilter = {
            dateRange: {
                start: moment().startOf('month').format(DATE_FORMAT),
                end: moment().format(DATE_FORMAT)
            }
        };
        makeObservable(this);
    }

    @action
    async init() {
        try {
            this.loadingInit = true;
            this.setStatisticReportFilter({
                dateRange: {
                    start: moment().startOf('month').format(DATE_FORMAT),
                    end: moment().format(DATE_FORMAT)
                }
            });
            this.statisticReportGroup = StatisticReportGroup.ByDays;

            this.drivers = await this.logic.users().drivers();
            this.drivers = this.drivers.sort(
                (a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
            );
            this.vehicles = await this.logic.vehicles().getMonitoredObjectFilters(false, true);
            const vehicleGroups = await (
                await this.logic.vehicles().vehicleGroups().getAllMonitoredObjectGroups()
            ).results;
            this.vehicleGroups = vehicleGroups.sort(
                (a: ReadOnlyMonitoredObjectGroup, b: ReadOnlyMonitoredObjectGroup) =>
                    a.name.localeCompare(b.name) || a.name.localeCompare(b.name)
            );

            if (this.logic.demo().isActive) {
                this.statisticReport = this.statisticReportGroup
                    ? this.logic
                          .demo()
                          .data.journeysStatistics?.[this.statisticReportGroup].filter(
                              d =>
                                  (this.statisticReportFilter.vehicles?.length
                                      ? d.monitoredObjectId &&
                                        this.statisticReportFilter.vehicles?.includes(String(d.monitoredObjectId))
                                      : d) &&
                                  (this.statisticReportFilter.drivers?.length
                                      ? d.driverId && this.statisticReportFilter.drivers?.includes(String(d.driverId))
                                      : d)
                          )
                    : [];
            } else {
                const report = await this._fetchStatisticReport();
                this.statisticReport = report;
            }
        } catch (err) {
            console.error('Loading statistic report init error');
            throw err;
        } finally {
            this.loadingInit = false;
        }
    }

    @action
    private async _fetchStatisticReport() {
        if (this.logic.demo().isActive) {
            return this.statisticReportGroup
                ? this.logic
                      .demo()
                      .data.journeysStatistics?.[this.statisticReportGroup]?.filter(
                          (d: JourneyStatisticsResponseStats) =>
                              (this.statisticReportFilter.vehicles?.length &&
                              [
                                  StatisticReportGroup.ByDays,
                                  StatisticReportGroup.ByVehicles,
                                  StatisticReportGroup.ByDrivers
                              ].includes(this.statisticReportGroup)
                                  ? d.monitoredObjectId &&
                                    this.statisticReportFilter.vehicles?.includes(String(d.monitoredObjectId))
                                  : d) &&
                              (this.statisticReportFilter.drivers?.length &&
                              [
                                  StatisticReportGroup.ByDays,
                                  StatisticReportGroup.ByVehicles,
                                  StatisticReportGroup.ByDrivers
                              ].includes(this.statisticReportGroup)
                                  ? d.driverId && this.statisticReportFilter.drivers?.includes(String(d.driverId))
                                  : d)
                      )
                : [];
        }

        try {
            this.loadingStatisticReport = true;
            const requestParams = {
                dateFrom: moment(this.statisticReportFilter.dateRange.start, DATE_TIME_FORMAT).format('YYYY-MM-DD'),
                dateTo: moment(this.statisticReportFilter.dateRange.end, DATE_TIME_FORMAT)
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
                driverIds: this.statisticReportFilter.drivers?.length
                    ? this.statisticReportFilter.drivers?.map(driverId => Number(driverId))
                    : undefined,
                moids: this.statisticReportFilter.vehicles?.length
                    ? this.statisticReportFilter.vehicles?.map(moid => String(moid))
                    : undefined
            };

            if (this.statisticReportGroup === StatisticReportGroup.ByDays) {
                const res = await this.logic
                    .api()
                    .journeysStatisticsApi.getJourneyStatisticsByDaysV1JourneyStatisticsByDaysGet(requestParams);
                return res.stats.sort((a, b) => (moment(a.timestampFrom).isBefore(b.timestampFrom) ? 1 : -1));
            } else if (this.statisticReportGroup === StatisticReportGroup.ByMonths) {
                const res = await this.logic
                    .api()
                    .journeysStatisticsApi.getJourneyStatisticsByDaysV1JourneyStatisticsByMonthsGet(requestParams);
                return res.stats.sort((a, b) => (moment(a.timestampFrom).isBefore(b.timestampFrom) ? 1 : -1));
            } else if (this.statisticReportGroup === StatisticReportGroup.ByDrivers) {
                const res = await this.logic
                    .api()
                    .journeysStatisticsApi.getJourneyStatisticsByDriversV1JourneyStatisticsByDriversGet(requestParams);
                return res.stats
                    .map(stat => ({
                        ...stat,
                        name: this.drivers.find(driver => driver.id === String(stat.driverId))?.surname
                    }))
                    .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1));
            } else if (this.statisticReportGroup === StatisticReportGroup.ByVehicles) {
                const res = await this.logic
                    .api()
                    .journeysStatisticsApi.getJourneyStatisticsByVehiclesV1JourneyStatisticsByMonitoredObjectsGet(
                        requestParams
                    );
                return res.stats
                    .map(stat => {
                        const vehicle = this.vehicles.find(vehicle => vehicle.id === Number(stat.monitoredObjectId));
                        return {
                            ...stat,
                            name:
                                this.logic.settings().getProp('vehicleIdentification') ===
                                VehicleIdentification.RegistrationNumber
                                    ? vehicle?.registrationNumber
                                    : vehicle?.customLabel
                        };
                    })
                    .sort((a, b) => (a.name && b.name && a.name > b.name ? 1 : -1));
            } else {
                const res = await this.logic
                    .api()
                    .journeysStatisticsApi.getJourneyStatisticsSummaryV1JourneyStatisticsSummaryGet(requestParams);
                return res.stats;
            }
        } catch (err) {
            console.error(`Failed to fetch statistic report, err: ${err}`);
            throw err;
        } finally {
            this.loadingStatisticReport = false;
        }
    }

    @action
    async reloadStatisticReport() {
        this.statisticReport = await this._fetchStatisticReport();
    }

    @action
    setStatisticReportFilter(filter: StatisticReportFilterModel) {
        this.statisticReportFilter = filter;
    }

    @action
    setStatisticReportGroup(group: StatisticReportGroup) {
        this.statisticReportGroup = group;
    }

    @action
    async loadVehicleGroupVehicles(groupId: number) {
        if (!this.vehicleGroupsVehicles[groupId]) {
            const result = await this.logic.vehicles().vehicleGroups().getMonitoredObjectGroupNestedById(groupId);
            this.vehicleGroupsVehicles.push({
                id: groupId,
                vehicleIds: result.monitoredObjects?.map(o => String(o)) ?? []
            });
        }
    }

    getVehicleGroupVehicles(groupId: number) {
        return this.vehicleGroupsVehicles.find(group => group.id === groupId)?.vehicleIds ?? [];
    }
}
