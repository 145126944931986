/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressStructured,
    AddressStructuredFromJSON,
    AddressStructuredFromJSONTyped,
    AddressStructuredToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleTransportNextWayPoint
 */
export interface VehicleTransportNextWayPoint {
    /**
     * 
     * @type {number}
     * @memberof VehicleTransportNextWayPoint
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTransportNextWayPoint
     */
    lon?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTransportNextWayPoint
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTransportNextWayPoint
     */
    rta?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTransportNextWayPoint
     */
    eta?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleTransportNextWayPoint
     */
    estimatedDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTransportNextWayPoint
     */
    remainingDistancePercentage?: number;
    /**
     * 
     * @type {Array<AddressStructured>}
     * @memberof VehicleTransportNextWayPoint
     */
    addressStructured?: Array<AddressStructured>;
}

export function VehicleTransportNextWayPointFromJSON(json: any): VehicleTransportNextWayPoint {
    return VehicleTransportNextWayPointFromJSONTyped(json, false);
}

export function VehicleTransportNextWayPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleTransportNextWayPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rta': !exists(json, 'rta') ? undefined : json['rta'],
        'eta': !exists(json, 'eta') ? undefined : json['eta'],
        'estimatedDistance': !exists(json, 'estimatedDistance') ? undefined : json['estimatedDistance'],
        'remainingDistancePercentage': !exists(json, 'remainingDistancePercentage') ? undefined : json['remainingDistancePercentage'],
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(AddressStructuredFromJSON)),
    };
}

export function VehicleTransportNextWayPointToJSON(value?: VehicleTransportNextWayPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lon': value.lon,
        'name': value.name,
        'rta': value.rta,
        'eta': value.eta,
        'estimatedDistance': value.estimatedDistance,
        'remainingDistancePercentage': value.remainingDistancePercentage,
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(AddressStructuredToJSON)),
    };
}


