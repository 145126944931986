import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import moment from 'moment';

import { DriverBehaviorTrendsModel } from 'common/model/statistics';
import ScoreCard, { ScoreCardColors } from 'common/components/ScoreCard';
import qa from 'qa-selectors';
import { Button } from 'common/components';
import ChartLineTrends from 'common/components/ChartLineTrends';
import cn from 'classnames';
import DriverBehaviorChartBar from 'modules/statistics/modules/driver-behavior-new/components/DriverBehaviorChartBar';
import ChartCompare, { ChartCompareModel } from 'common/components/ChartCompare';
import Trend, { TrendChange } from 'common/components/Trend';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { TrendScoreValues } from '../../DriverBehaviorModule';
import { Themes } from 'common/components/ChartTimeline';

enum ScoreToColor {
    score = 'lightblue',
    driveScore = 'blue',
    ecoScore = 'green',
    wearTearScore = 'yellow'
}

interface Props {
    data?: DriverBehaviorTrendsModel;
    comparedData?: DriverBehaviorTrendsModel;
    theme?: Themes;
    drivers: {
        value: string;
        label: string;
    }[];
    onCompareDriverSelect?: (id: string) => void;
    onCompareDriverDeselect?: () => void;
}

export default function DriverTrendsScoreStats(props: Props) {
    const { t } = useTranslation();
    const [selectedScore, setSelectedScore] = useState<string>('');

    const max =
        props.data?.scores[0]?.distance &&
        Object.keys(props.data.scores[0].distance)
            .map(key => ({
                label: key,
                value: props.data?.scores[0]?.distance?.[key]
            }))
            .find(
                d =>
                    props.data &&
                    d.value ===
                        Math.max(
                            props.data.scores[0].distance?.city ?? 0,
                            props.data.scores[0].distance?.highway ?? 0,
                            props.data.scores[0].distance?.noCity ?? 0,
                            props.data.scores[0].distance?.other ?? 0
                        ) &&
                    d.label !== 'summary'
            );
    const bestScoreCard = Object.keys(TrendScoreValues)
        .map(key => ({ name: key, value: props.data?.scores[0]?.[key] ?? 0 }))
        .sort((a, b) => (a.value > b.value ? -1 : 1))[0].name;

    function toggleChart(score: string) {
        setSelectedScore(score && score !== selectedScore ? score : '');
    }

    const activeColor =
        ScoreToColor[selectedScore] === 'lightblue'
            ? ScoreCardColors.Lightblue
            : ScoreToColor[selectedScore] === 'blue'
            ? ScoreCardColors.Blue
            : ScoreToColor[selectedScore] === 'green'
            ? ScoreCardColors.Green
            : ScoreToColor[selectedScore] === 'purple'
            ? ScoreCardColors.Purple
            : ScoreCardColors.Yellow;

    return (
        <div className="driver-score-stats">
            <Row justify="space-between" gutter={16}>
                <Col flex={1}>
                    <Button
                        className={cn('driver-score-stats-button', {
                            'driver-score-stats-button-selected': selectedScore === 'score'
                        })}
                        block
                        type="link"
                        onClick={() => toggleChart('score')}
                    >
                        <ScoreCard
                            theme={props.theme}
                            color="lightblue"
                            value={props.data?.scores[0].score ?? 0}
                            label={t('DriverBehaviorTable.filters.overal')}
                            description={
                                <Trend
                                    direction={getIncreaseDirection(
                                        props.data?.scores[0].score,
                                        props.data?.scores[1].score
                                    )}
                                    trendChangeForIncrease={TrendChange.POSITIVE}
                                    value={getIncreasePercent(props.data?.scores[0].score, props.data?.scores[1].score)}
                                />
                            }
                            size={160}
                            important={true}
                            qa={qa.driverBehaviour.trucks.detail.fieldScore}
                        />
                    </Button>
                </Col>
                <Col flex={1}>
                    <Button
                        className={cn('driver-score-stats-button', {
                            'driver-score-stats-button-selected': selectedScore === TrendScoreValues.driveScore
                        })}
                        block
                        type="link"
                        onClick={() => toggleChart(TrendScoreValues.driveScore)}
                    >
                        <ScoreCard
                            theme={props.theme}
                            label={t('DriverBehaviorTable.filters.driving')}
                            value={props.data?.scores[0].driveScore ?? 0}
                            description={
                                <Trend
                                    direction={getIncreaseDirection(
                                        props.data?.scores[0].driveScore,
                                        props.data?.scores[1].driveScore
                                    )}
                                    trendChangeForIncrease={TrendChange.POSITIVE}
                                    value={getIncreasePercent(props.data?.scores[0].score, props.data?.scores[1].score)}
                                />
                            }
                            size={160}
                            color="blue"
                            starred={bestScoreCard === TrendScoreValues.driveScore}
                            qa={qa.driverBehaviour.trucks.detail.fieldDrive}
                        />
                    </Button>
                </Col>
                <Col flex={1}>
                    <Button
                        className={cn('driver-score-stats-button', {
                            'driver-score-stats-button-selected': selectedScore === TrendScoreValues.ecoScore
                        })}
                        block
                        type="link"
                        onClick={() => toggleChart(TrendScoreValues.ecoScore)}
                    >
                        <ScoreCard
                            theme={props.theme}
                            value={props.data?.scores[0].ecoScore ?? 0}
                            label={t('DriverBehaviorTable.filters.eco')}
                            description={
                                <Trend
                                    direction={getIncreaseDirection(
                                        props.data?.scores[0].ecoScore,
                                        props.data?.scores[1].ecoScore
                                    )}
                                    trendChangeForIncrease={TrendChange.POSITIVE}
                                    value={getIncreasePercent(
                                        props.data?.scores[0].ecoScore,
                                        props.data?.scores[1].ecoScore
                                    )}
                                />
                            }
                            size={160}
                            color="green"
                            starred={bestScoreCard === TrendScoreValues.ecoScore}
                            qa={qa.driverBehaviour.trucks.detail.fieldEco}
                        />
                    </Button>
                </Col>
                <Col flex={1}>
                    <Button
                        className={cn('driver-score-stats-button', {
                            'driver-score-stats-button-selected': selectedScore === TrendScoreValues.wearTearScore
                        })}
                        block
                        type="link"
                        onClick={() => toggleChart(TrendScoreValues.wearTearScore)}
                    >
                        <ScoreCard
                            theme={props.theme}
                            color="yellow"
                            value={props.data?.scores[0].wearTearScore ?? 0}
                            label={t('DriverBehaviorTable.filters.wearTear')}
                            size={160}
                            starred={bestScoreCard === TrendScoreValues.wearTearScore}
                            qa={qa.driverBehaviour.trucks.detail.fieldWearTear}
                            description={
                                <Trend
                                    direction={getIncreaseDirection(
                                        props.data?.scores[0].wearTearScore,
                                        props.data?.scores[1].wearTearScore
                                    )}
                                    trendChangeForIncrease={TrendChange.POSITIVE}
                                    value={getIncreasePercent(
                                        props.data?.scores[0].wearTearScore,
                                        props.data?.scores[1].wearTearScore
                                    )}
                                />
                            }
                        />
                    </Button>
                </Col>
                {props.data?.scores[0].distance && max?.value && (
                    <Col flex={1} style={{ paddingBottom: '16px' }}>
                        <ScoreCard
                            theme={props.theme}
                            value={(max.value / props.data.scores[0].distance.summary) * 100}
                            label={t('DriverBehaviorDistanceChart.title')}
                            size={160}
                            color="purple"
                            percent={true}
                            description={
                                <span className="score-card-distance">
                                    {t(`DriverBehaviorDistanceChart.${max.label}`)}
                                </span>
                            }
                            qa={qa.driverBehaviour.trucks.detail.distance}
                        />
                    </Col>
                )}
            </Row>
            {selectedScore && (
                <div className="driver-score-stats-chart">
                    {props.data?.driverId && (
                        <>
                            <DriverBehaviorChartBar
                                drivers={props.drivers}
                                mainId={props.data.driverId}
                                comparedDriverId={props.comparedData?.driverId}
                                onCompareDriverSelect={props.onCompareDriverSelect}
                                color={activeColor}
                                onCompareDriverDeselect={props.onCompareDriverDeselect}
                            />
                            {props.comparedData ? (
                                <ChartCompare
                                    color={activeColor}
                                    theme={props.theme}
                                    data={[
                                        ...props.data.scores
                                            .map(
                                                (d, i) =>
                                                    ({
                                                        xIndex: i,
                                                        xVal:
                                                            i < 12
                                                                ? moment(d.start, 'YYYY-MM-DD').format('MMM')
                                                                : i.toString(),
                                                        yVal: d[selectedScore],
                                                        yLine: 'main'
                                                    } as ChartCompareModel)
                                            )
                                            .reverse(),
                                        ...props.comparedData.scores
                                            .map(
                                                (d, i) =>
                                                    ({
                                                        xIndex: i,
                                                        xVal:
                                                            i < 12
                                                                ? moment(d.start, 'YYYY-MM-DD').format('MMM')
                                                                : i.toString(),
                                                        yVal: d[selectedScore],
                                                        yLine: 'compared'
                                                    } as ChartCompareModel)
                                            )
                                            .reverse()
                                    ]}
                                    height={270}
                                    lineWidth={2}
                                />
                            ) : (
                                <ChartLineTrends
                                    size="large"
                                    theme={props.theme}
                                    data={props.data.scores
                                        .map((score, i) => {
                                            return {
                                                xIndex: i,
                                                yVal: score[selectedScore],
                                                xVal: i < 12 ? moment(score.start, 'YYYY-MM-DD').format('MMM') : '',
                                                trendVal:
                                                    props.data?.scores[i]?.[selectedScore] &&
                                                    props.data?.scores[i + 1]?.[selectedScore]
                                                        ? props.data?.scores[i][selectedScore] >
                                                          props.data?.scores[i + 1][selectedScore]
                                                            ? 'up'
                                                            : props.data?.scores[i][selectedScore] <
                                                              props.data?.scores[i + 1][selectedScore]
                                                            ? 'down'
                                                            : undefined
                                                        : undefined
                                            };
                                        })
                                        .reverse()}
                                    height={270}
                                    maxY={100}
                                    color={
                                        ScoreToColor[selectedScore] === 'lightblue'
                                            ? ScoreCardColors.Lightblue
                                            : ScoreToColor[selectedScore] === 'blue'
                                            ? ScoreCardColors.Blue
                                            : ScoreToColor[selectedScore] === 'green'
                                            ? ScoreCardColors.Green
                                            : ScoreToColor[selectedScore] === 'purple'
                                            ? ScoreCardColors.Purple
                                            : ScoreCardColors.Yellow
                                    }
                                    lineWidth={2}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
