/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PUESCEventType {
    SendLocation = 'send_location',
    SentDocumentNew = 'sent_document_new',
    SentDocumentUpdated = 'sent_document_updated',
    SentLocationResult = 'sent_location_result'
}

export function PUESCEventTypeFromJSON(json: any): PUESCEventType {
    return PUESCEventTypeFromJSONTyped(json, false);
}

export function PUESCEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PUESCEventType {
    return json as PUESCEventType;
}

export function PUESCEventTypeToJSON(value?: PUESCEventType | null): any {
    return value as any;
}

