import { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TCOInput } from 'generated/new-main';

import { TotalCostOfOwnerShipLogic } from './total-cost-of-ownership-logic';
import Collapse from 'common/components/Collapse';
import { Loading } from 'common/components/Loading';
import TotalCostOfOwnershipForm from 'common/forms/TotalCostOfOwnershipForm';
import TCOCalculatedTable from './components/TCOCalculatedTable/TCOCalculatedTable';
import TCOSumTable from './components/TCOSumTable/TCOSumTable';
import { Col, Row } from 'antd';
import { TCOBarChartTotalCost } from './components/TCOBarChart/TCOBarChartTotalCost';
import { TCOBarChartEmissions } from './components/TCOBarChart/TCOBarChartEmmisions';

interface Props extends WithTranslation {
    logic: TotalCostOfOwnerShipLogic;
    isSuperAdmin?: boolean;
}

interface State {
    activePanel: string | string[];
}

class TCOModule extends Component<Props, State> {
    sumTableRef: React.RefObject<HTMLDivElement>;
    calculatedTableRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);
        this.state = {
            activePanel: '1'
        };
        this.sumTableRef = createRef();
        this.calculatedTableRef = createRef();
    }

    componentDidMount() {
        this.props.logic.init();
    }

    render() {
        if (this.props.logic.settingsLoading) {
            return <Loading />;
        }
        if (!this.props.logic.settings) {
            return <>{this.props.t('TotalCostOfOwnership.loadSettingsError')}</>;
        }
        return (
            <div className="module tco-module scroll">
                <h1>{this.props.t('TotalCostOfOwnership.tcoCalculator')}</h1>
                <br />
                <Collapse activeKey={this.state.activePanel} onChange={this._onColapseChange}>
                    <Collapse.Panel header={this.props.t('TotalCostOfOwnership.tcoCalculatorDescription')} key="1">
                        <TotalCostOfOwnershipForm
                            initialValues={this.props.logic.formInitValues}
                            tcoSettings={this.props.logic.settings}
                            lang={'en'}
                            loading={this.props.logic.calculateLoading}
                            onSubmit={(values: TCOInput) => {
                                if (this.props.isSuperAdmin) {
                                    this.props.logic.tcoCalculate(values).then(() => {
                                        this.calculatedTableRef.current?.scrollIntoView({
                                            behavior: 'smooth'
                                        });
                                    });
                                } else {
                                    this.props.logic.tcoCalculate(values).then(() => {
                                        this.sumTableRef.current?.scrollIntoView({
                                            behavior: 'smooth'
                                        });
                                    });
                                }
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <div>
                    {this.props.t('TotalCostOfOwnership.tcoCalculatorContactInfo')}:{' '}
                    {this.props.logic.settingsConstants?.email && this.props.logic.settingsConstants?.email !== ''
                        ? this.props.logic.settingsConstants?.email
                        : '-'}
                    {' / '}
                    {this.props.logic.settingsConstants?.tel && this.props.logic.settingsConstants?.tel !== ''
                        ? this.props.logic.settingsConstants?.tel
                        : '-'}
                </div>
                {this.props.logic.calculatedData && (
                    <>
                        {this.props.isSuperAdmin && (
                            <>
                                <h2 ref={this.calculatedTableRef}>
                                    {this.props.t('TotalCostOfOwnership.resultCalculatedData')}
                                </h2>
                                <TCOCalculatedTable data={this.props.logic.tableCalculatedTableData} />
                                <br />
                                <br />
                            </>
                        )}

                        <Row className="tco-module-sum-content">
                            <Col span={10} ref={this.sumTableRef}>
                                <TCOSumTable data={this.props.logic.tableSumTableData} />
                            </Col>
                            <Col span={1} />
                            <Col span={6}>
                                <TCOBarChartTotalCost
                                    dieselTotalCost={
                                        this.props.logic.calculatedData.total.dieselVehicleDetails.totalCost.value
                                    }
                                    lngTotalCost={
                                        this.props.logic.calculatedData.total.lngVehicleDetails.totalCost.value
                                    }
                                />
                            </Col>
                            <Col span={1} />
                            <Col span={6}>
                                <TCOBarChartEmissions
                                    dieselGCo2={
                                        this.props.logic.calculatedData.total.dieselVehicleDetails.gCo2PerUnitProduction
                                            .value
                                    }
                                    lngGCo2={
                                        this.props.logic.calculatedData.total.lngVehicleDetails.gCo2PerUnitProduction
                                            .value
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        );
    }

    private _onColapseChange = (key: string | string[]) => {
        this.setState({
            activePanel: key
        });
    };
}

export default withTranslation()(observer(TCOModule));
