import { RouteNames } from 'App';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Role } from 'logic/auth';
import { NavLink } from 'react-router-dom';
import qa from 'qa-selectors';
import * as sidebarIcons from 'resources/images/sidebar';

interface Props extends WithTranslation {
    roles: Role[];
}

function StatisticsSideBar(props: Props) {
    const { t, roles } = props;

    return (
        <div className="statistics-sidebar sidebar">
            {[Role.CP_R, Role.DBHD_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_COMPANY_PROFILE}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.companyProfile')}
                    data-qa={qa.sidebar.statistics.btnCompanyProfile}
                >
                    <img src={sidebarIcons.companyProfile} alt="dashboard" />
                </NavLink>
            )}
            {[Role.JA_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_JOURNEYS_ACTIVITY}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.journeysActivity')}
                    data-qa={qa.sidebar.statistics.btnJourneysActivity}
                >
                    <img src={sidebarIcons.journeys} alt="journeys" />
                </NavLink>
            )}
            {[Role.FC_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_FUEL_CONSUMPTION}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.fuelConsumptions')}
                    data-qa={qa.sidebar.statistics.btnFuelConsumption}
                >
                    <img src={sidebarIcons.fuelConsumption} alt="fuel-consumption" />
                </NavLink>
            )}
            {[Role.AEI_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_AETR}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.AETR')}
                    data-qa={qa.sidebar.statistics.btnAetr}
                >
                    <img src={sidebarIcons.aetr} alt="aetr" />
                </NavLink>
            )}
            {[Role.EX_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_EXPENSES}
                    activeClassName="sidebar-item-active"
                    data-qa={qa.sidebar.statistics.btnExpenses}
                >
                    <img src={sidebarIcons.expenses} alt="expenses" />
                </NavLink>
            )}
            {[Role.DBH_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_DRIVER_BEHAVIOR}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.driverBehavior')}
                    data-qa={qa.sidebar.statistics.btnDriverBehavior}
                >
                    <img src={sidebarIcons.driverBehavior} alt="driver-behaviour" />
                </NavLink>
            )}
            {[Role.BC_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_BORDER_CROSSING}
                    activeClassName="sidebar-item-active"
                    title={t('NavBar.borderCrossing')}
                    data-qa={qa.sidebar.statistics.btnBorderCrossing}
                >
                    <img src={sidebarIcons.borderCrossing} alt="border-crossing" />
                </NavLink>
            )}
            {[Role.MTN_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_MAINTENANCE}
                    activeClassName="sidebar-item-active"
                    data-qa={qa.sidebar.statistics.btnMaintenance}
                    title={t('NavBar.maintanance')}
                >
                    <img src={sidebarIcons.maintenance} alt="maintenance" />
                </NavLink>
            )}
            {[Role.DIT_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_ALLOWANCES}
                    activeClassName="sidebar-item-active"
                    data-qa={qa.sidebar.statistics.btnAllowances}
                    title={t('NavBar.allowances')}
                >
                    <img src={sidebarIcons.allowances} alt="allowances" />
                </NavLink>
            )}
            {[Role.FC_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_STATISTIC_REPORT}
                    activeClassName="sidebar-item-active"
                    data-qa={qa.sidebar.statistics.btnStatisticReport}
                    title={t('NavBar.statisticReport')}
                >
                    <img src={sidebarIcons.statisticReport} alt="statistic-report" />
                </NavLink>
            )}
            {[Role.CLD_R].some(r => roles.includes(r)) && (
                <NavLink
                    className="sidebar-item"
                    to={RouteNames.STATISTICS_COLD_CHAIN}
                    activeClassName="sidebar-item-active"
                    data-qa={qa.sidebar.statistics.btnColdchain}
                    title={t('NavBar.coldchain')}
                >
                    <img src={sidebarIcons.coldchain} alt="coldchain" />
                </NavLink>
            )}
        </div>
    );
}

export default withTranslation()(StatisticsSideBar);
