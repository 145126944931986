/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20026,
    InlineResponse20026FromJSON,
    InlineResponse20026ToJSON,
    MonitoredObjectOperationalCost,
    MonitoredObjectOperationalCostFromJSON,
    MonitoredObjectOperationalCostToJSON,
    MonitoredObjectOperationalCostCreateMany,
    MonitoredObjectOperationalCostCreateManyFromJSON,
    MonitoredObjectOperationalCostCreateManyToJSON,
    MonitoredObjectOperationalCostDefaults,
    MonitoredObjectOperationalCostDefaultsFromJSON,
    MonitoredObjectOperationalCostDefaultsToJSON,
    MonitoredObjectOperationalCostsSerializerOfFleetTypes,
    MonitoredObjectOperationalCostsSerializerOfFleetTypesFromJSON,
    MonitoredObjectOperationalCostsSerializerOfFleetTypesToJSON,
    ReadOnlyMonitoredObjectOperationalCost,
    ReadOnlyMonitoredObjectOperationalCostFromJSON,
    ReadOnlyMonitoredObjectOperationalCostToJSON,
    WriteOnlyMonitoredObjectOperationalCost,
    WriteOnlyMonitoredObjectOperationalCostFromJSON,
    WriteOnlyMonitoredObjectOperationalCostToJSON,
} from '../models';

export interface MonitoredObjectOperationalCostCreateRequest {
    data: WriteOnlyMonitoredObjectOperationalCost;
}

export interface MonitoredObjectOperationalCostCreateManyRequest {
    data: Array<MonitoredObjectOperationalCostCreateMany>;
}

export interface MonitoredObjectOperationalCostDefaultMarketAverageRequest {
    monitoredObject?: string;
    dateFrom?: string;
    dateFromLte?: string;
    dateFromGte?: string;
    dateTo?: string;
    dateToLte?: string;
    dateToGte?: string;
    monitoredObjectClient?: string;
    active?: boolean;
    monitoredObjectIn?: string;
    mobjectIn?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectOperationalCostDefaultMarketAverageOfFleetTypesRequest {
    monitoredObject?: string;
    dateFrom?: string;
    dateFromLte?: string;
    dateFromGte?: string;
    dateTo?: string;
    dateToLte?: string;
    dateToGte?: string;
    monitoredObjectClient?: string;
    active?: boolean;
    monitoredObjectIn?: string;
    mobjectIn?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectOperationalCostDeleteRequest {
    id: number;
}

export interface MonitoredObjectOperationalCostListRequest {
    monitoredObject?: string;
    dateFrom?: string;
    dateFromLte?: string;
    dateFromGte?: string;
    dateTo?: string;
    dateToLte?: string;
    dateToGte?: string;
    monitoredObjectClient?: string;
    active?: boolean;
    monitoredObjectIn?: string;
    mobjectIn?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectOperationalCostPartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectOperationalCost;
}

export interface MonitoredObjectOperationalCostReadRequest {
    id: number;
}

export interface MonitoredObjectOperationalCostUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectOperationalCost;
}

/**
 * no description
 */
export class MonitoredObjectOperationalCostApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectOperationalCostCreateRaw(requestParameters: MonitoredObjectOperationalCostCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectOperationalCost>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectOperationalCostCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostCreate(requestParameters: MonitoredObjectOperationalCostCreateRequest): Promise<ReadOnlyMonitoredObjectOperationalCost> {
        const response = await this.monitoredObjectOperationalCostCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostCreateManyRaw(requestParameters: MonitoredObjectOperationalCostCreateManyRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectOperationalCost>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectOperationalCostCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/create-many/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(MonitoredObjectOperationalCostCreateManyToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectOperationalCostFromJSON));
    }

    /**
     */
    async monitoredObjectOperationalCostCreateMany(requestParameters: MonitoredObjectOperationalCostCreateManyRequest): Promise<Array<MonitoredObjectOperationalCost>> {
        const response = await this.monitoredObjectOperationalCostCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostDefaultMarketAverageRaw(requestParameters: MonitoredObjectOperationalCostDefaultMarketAverageRequest): Promise<runtime.ApiResponse<MonitoredObjectOperationalCostDefaults>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateFromLte !== undefined) {
            queryParameters['date_from__lte'] = requestParameters.dateFromLte;
        }

        if (requestParameters.dateFromGte !== undefined) {
            queryParameters['date_from__gte'] = requestParameters.dateFromGte;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.dateToLte !== undefined) {
            queryParameters['date_to__lte'] = requestParameters.dateToLte;
        }

        if (requestParameters.dateToGte !== undefined) {
            queryParameters['date_to__gte'] = requestParameters.dateToGte;
        }

        if (requestParameters.monitoredObjectClient !== undefined) {
            queryParameters['monitored_object__client'] = requestParameters.monitoredObjectClient;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.monitoredObjectIn !== undefined) {
            queryParameters['monitored_object__in'] = requestParameters.monitoredObjectIn;
        }

        if (requestParameters.mobjectIn !== undefined) {
            queryParameters['mobject_in'] = requestParameters.mobjectIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/default-market-averages/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectOperationalCostDefaultsFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostDefaultMarketAverage(requestParameters: MonitoredObjectOperationalCostDefaultMarketAverageRequest): Promise<MonitoredObjectOperationalCostDefaults> {
        const response = await this.monitoredObjectOperationalCostDefaultMarketAverageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostDefaultMarketAverageOfFleetTypesRaw(requestParameters: MonitoredObjectOperationalCostDefaultMarketAverageOfFleetTypesRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectOperationalCostsSerializerOfFleetTypes>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateFromLte !== undefined) {
            queryParameters['date_from__lte'] = requestParameters.dateFromLte;
        }

        if (requestParameters.dateFromGte !== undefined) {
            queryParameters['date_from__gte'] = requestParameters.dateFromGte;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.dateToLte !== undefined) {
            queryParameters['date_to__lte'] = requestParameters.dateToLte;
        }

        if (requestParameters.dateToGte !== undefined) {
            queryParameters['date_to__gte'] = requestParameters.dateToGte;
        }

        if (requestParameters.monitoredObjectClient !== undefined) {
            queryParameters['monitored_object__client'] = requestParameters.monitoredObjectClient;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.monitoredObjectIn !== undefined) {
            queryParameters['monitored_object__in'] = requestParameters.monitoredObjectIn;
        }

        if (requestParameters.mobjectIn !== undefined) {
            queryParameters['mobject_in'] = requestParameters.mobjectIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/default-market-averages-of-fleet-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectOperationalCostsSerializerOfFleetTypesFromJSON));
    }

    /**
     */
    async monitoredObjectOperationalCostDefaultMarketAverageOfFleetTypes(requestParameters: MonitoredObjectOperationalCostDefaultMarketAverageOfFleetTypesRequest): Promise<Array<MonitoredObjectOperationalCostsSerializerOfFleetTypes>> {
        const response = await this.monitoredObjectOperationalCostDefaultMarketAverageOfFleetTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostDeleteRaw(requestParameters: MonitoredObjectOperationalCostDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectOperationalCostDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostDelete(requestParameters: MonitoredObjectOperationalCostDeleteRequest): Promise<ReadOnlyMonitoredObjectOperationalCost> {
        const response = await this.monitoredObjectOperationalCostDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostListRaw(requestParameters: MonitoredObjectOperationalCostListRequest): Promise<runtime.ApiResponse<InlineResponse20026>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateFromLte !== undefined) {
            queryParameters['date_from__lte'] = requestParameters.dateFromLte;
        }

        if (requestParameters.dateFromGte !== undefined) {
            queryParameters['date_from__gte'] = requestParameters.dateFromGte;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.dateToLte !== undefined) {
            queryParameters['date_to__lte'] = requestParameters.dateToLte;
        }

        if (requestParameters.dateToGte !== undefined) {
            queryParameters['date_to__gte'] = requestParameters.dateToGte;
        }

        if (requestParameters.monitoredObjectClient !== undefined) {
            queryParameters['monitored_object__client'] = requestParameters.monitoredObjectClient;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.monitoredObjectIn !== undefined) {
            queryParameters['monitored_object__in'] = requestParameters.monitoredObjectIn;
        }

        if (requestParameters.mobjectIn !== undefined) {
            queryParameters['mobject_in'] = requestParameters.mobjectIn;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20026FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostList(requestParameters: MonitoredObjectOperationalCostListRequest): Promise<InlineResponse20026> {
        const response = await this.monitoredObjectOperationalCostListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostPartialUpdateRaw(requestParameters: MonitoredObjectOperationalCostPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectOperationalCostPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectOperationalCostPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostPartialUpdate(requestParameters: MonitoredObjectOperationalCostPartialUpdateRequest): Promise<ReadOnlyMonitoredObjectOperationalCost> {
        const response = await this.monitoredObjectOperationalCostPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostReadRaw(requestParameters: MonitoredObjectOperationalCostReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectOperationalCostRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostRead(requestParameters: MonitoredObjectOperationalCostReadRequest): Promise<ReadOnlyMonitoredObjectOperationalCost> {
        const response = await this.monitoredObjectOperationalCostReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectOperationalCostUpdateRaw(requestParameters: MonitoredObjectOperationalCostUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectOperationalCost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectOperationalCostUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectOperationalCostUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-operational-cost/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectOperationalCostToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectOperationalCostFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectOperationalCostUpdate(requestParameters: MonitoredObjectOperationalCostUpdateRequest): Promise<ReadOnlyMonitoredObjectOperationalCost> {
        const response = await this.monitoredObjectOperationalCostUpdateRaw(requestParameters);
        return await response.value();
    }

}
