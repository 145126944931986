import { Col, Row } from 'antd';
import { ColdchainProfileData } from 'common/forms/ColdchainProfileForm';
import { degC } from 'domain-constants';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    data?: ColdchainProfileData;
}

const ColdchainProfileInfo = (props: Props) => {
    const { t } = props;
    return (
        <Row className="coldchain-profile-info-tooltip">
            {props.data?.aboveTemperatureThresholdUse && (
                <Row>
                    <Col flex="auto">{t('PlannerColdchain.info.alertAbove')}:</Col>
                    <Col className="coldchain-profile-info-tooltip-value">
                        {`${props.data?.aboveTemperatureThreshold} ${degC}`}
                    </Col>
                </Row>
            )}
            {props.data?.belowTemperatureThresholdUse && (
                <Row>
                    <Col flex="auto">{t('PlannerColdchain.info.alertBelow')}:</Col>
                    <Col className="coldchain-profile-info-tooltip-value">
                        {`${props.data?.belowTemperatureThreshold} ${degC}`}
                    </Col>
                </Row>
            )}
            {props.data?.alarmTimerSecondsUse && (
                <Row>
                    <Col flex="auto">{t('PlannerColdchain.info.alertAfter')}:</Col>
                    <Col className="coldchain-profile-info-tooltip-value">
                        {`${(props.data?.alarmTimerSeconds ?? 0) / 60} ${t('PlannerColdchain.info.mins')}`}
                    </Col>
                </Row>
            )}
        </Row>
    );
};

export default withTranslation()(ColdchainProfileInfo);
