import { Component, createRef, RefObject } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, message, Row, Tooltip } from 'antd';
import cn from 'classnames';
import 'moment-duration-format';
import qa from 'qa-selectors';

import { Input, Select } from 'common/components/Form';
import { AvailableCurrencies } from 'common/model/currency';
import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { Role } from 'logic/auth';
import { searched } from 'common/utils/search';
import { observer } from 'mobx-react';
import { WithLogic, withLogicContext } from 'App';
import { PuescWarning } from '../PuescWarning/PuescWarning';
import { MonitoredObjectFleetType } from 'generated/backend-api/models';
import { TransportType, VehicleProfile } from '../../planner-logic';
import VehicleProfileItem from '../VehicleProfileItem';
import { ItemAdd, Topic } from 'resources/images/planner';
import VehicleItem from '../VehicleItem';
import { VehicleWithAvailability } from 'common/model/transports';
import EditItem from '../EditItem';
import { Button, Confirm } from 'common/components';
import { MessageType } from 'common/components/Confirm';
import TrailerItem from '../TrailerItem';
import * as icons from 'resources/images/common';
import { VehicleStateObject } from 'generated/graphql';
import { trailersVehicleStateObjectIds } from 'common/utils/vehicleState';
import { ExternalDeviceType } from 'generated/backend-api-live';
export const defaultVehicleProfileName = 'default_vehicle_profile_truck_40t';

export interface RouteConfiguration {
    /**
     * Currently selected route balanced/fastest/planned
     */
    route: TransportType;
    clientId?: number;

    pricePerKM: {
        currency: AvailableCurrencies;
        cost: number;
    };
}

const TRANSPORT_NAME_KEY: string = 'transport-name';
interface Props extends WithTranslation, WithLogic {
    companyCostPerKm?: PartnerCompanyModel['costPerKm'];
    vehicleStates?: VehicleStateObject[];
    onCreateProfileClick?: () => void;
    onEditProfileClick?: (
        profile: VehicleProfile,
        isVehicle: boolean,
        vehicle: VehicleWithAvailability | undefined
    ) => void;
    onDeleteProfileClick?: (profile: VehicleProfile) => void;
    onRoutePriceChange?: (rc: RouteConfiguration) => void;
}

interface State {
    transportNameChanged: boolean;
    selectValue: 'noCriteria' | 'newProfile' | string;
    selectedRouteNameValue: 'transport-name' | string;
    routeName: string;
    selectedClient: string | 'newClient' | 'noCriteria';
    configuration: RouteConfiguration;
    noMoreAvailable: boolean;
    hideName: boolean;
    openTemplateDeleteConfirm: boolean;
    openTemplateRenameConfirm: boolean;
    templateResult: {
        id?: string;
        name?: string;
    };
    templateSelect?: boolean;
    transportNameEdited: boolean;
    editedTemplateName: boolean;
}

const INITIAL_STATE: State = {
    transportNameChanged: false,
    selectValue: 'noCriteria',
    selectedRouteNameValue: TRANSPORT_NAME_KEY,
    routeName: '',
    selectedClient: 'noCriteria',
    noMoreAvailable: false,
    hideName: false,
    openTemplateDeleteConfirm: false,
    openTemplateRenameConfirm: false,
    templateResult: {
        id: undefined,
        name: undefined
    },
    configuration: {
        route: TransportType.balanced,
        pricePerKM: {
            cost: 0,
            currency: AvailableCurrencies.EUR
        }
    },
    templateSelect: false,
    transportNameEdited: false,
    editedTemplateName: false
};

class RouteParamsConfigurator extends Component<Props, State> {
    roles: Role[];
    _selectRef: RefObject<HTMLSelectElement> | undefined;

    constructor(props: Props) {
        super(props);
        this.roles = this.props.logic.auth().roles();
        this._selectRef = createRef();

        const transportCostPerKm = this.props.logic.plannerLogic().transport.costPerKm;

        this.state = {
            ...INITIAL_STATE,
            configuration: {
                ...INITIAL_STATE.configuration,
                pricePerKM: transportCostPerKm?.value
                    ? {
                          cost: transportCostPerKm.value ?? 0,
                          currency: (transportCostPerKm.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
                      }
                    : INITIAL_STATE.configuration.pricePerKM
            }
        };
    }

    _onTemplateChangeName = async (name: string, id: any) => {
        const template = this.props.logic.plannerLogic().availableTransportTemplates.find(t => t?.id === id);
        if (template && this.props.logic.plannerLogic().availableTransportTemplates) {
            if (await this.props.logic.plannerLogic().transportTemplateNameExists(id, name)) {
                this.setState(state => ({
                    ...state,
                    templateResult: {
                        id: id,
                        name: name
                    }
                }));
                this.setState(state => ({
                    ...state,
                    openTemplateRenameConfirm: true
                }));
                return;
            } else {
                try {
                    this.props.logic.plannerLogic().transportTemplatesRename(id, name);
                    message.success(this.props.t('routing.configurator.template.successRename'));
                } catch (err) {
                    message.error(this.props.t('routing.configurator.template.successRename'));
                }
            }
        }

        this.setState(state => ({
            ...state,
            openTemplateRenameConfirm: false,
            editedTemplateName: false
        }));
    };

    _onConfirmTemplateRename = () => {
        if (this.state.templateResult.id && this.state.templateResult.name) {
            try {
                this.props.logic
                    .plannerLogic()
                    .transportTemplatesRename(this.state.templateResult.id, this.state.templateResult.name);
                message.success(this.props.t('routing.configurator.template.successRename'));
            } catch (err) {
                message.error(this.props.t('routing.configurator.template.successRename'));
            }
        }
        this.setState(state => ({
            ...state,
            templateResult: {
                ...state.templateResult,
                id: undefined
            },
            openTemplateRenameConfirm: false
        }));
    };

    _onConfirmTemplateRenameCancel = () => {
        this.setState(state => ({
            ...state,
            templateResult: {
                ...state.templateResult,
                id: undefined
            },
            openTemplateRenameConfirm: false
        }));
    };

    _onTemplateDelete = (key: any) => {
        this.setState(state => ({
            ...state,
            editedTemplateName: false,
            templateResult: {
                ...state.templateResult,
                id: key
            },
            openTemplateDeleteConfirm: true
        }));
    };

    _onTemplateEdit = (event: React.MouseEvent) => {
        this.setState(() => ({
            editedTemplateName: true
        }));
        event.stopPropagation();
        event.preventDefault();
    };

    _onTemplateEditCancel = () => {
        this.setState(() => ({
            editedTemplateName: false
        }));
    };

    _onConfirmTemplateDelete = () => {
        if (this.state.templateResult.id) {
            try {
                this.props.logic.plannerLogic().transportTemplatesDelete(this.state.templateResult.id);
                message.success(this.props.t('routing.configurator.template.successDelete'));
            } catch (err) {
                message.error(this.props.t('routing.configurator.template.errorDelete'));
            }
        }
        this.setState(state => ({
            ...state,
            openTemplateDeleteConfirm: false
        }));
    };

    _onConfirmTemplateDeleteCancel = () => {
        this.setState(state => ({
            ...state,
            templateResult: {
                ...state.templateResult,
                id: undefined
            },
            openTemplateDeleteConfirm: false
        }));
    };

    _onButtonEditTemplateNameClick = () => {
        this.setState(state => ({
            ...state,
            transportNameEdited: true
        }));
    };

    _onButtonCancelTemplateNameClick = () => {
        this.setState(state => ({
            ...state,
            transportNameEdited: false
        }));
    };

    _onButtonSaveTemplateNameClick = (value: string) => {
        this.props.logic.plannerLogic().changeTransportName(value);
        this.props.logic.plannerLogic().setFixedTransportName(true);
        this.setState(state => ({
            ...state,
            transportNameEdited: false
        }));
    };

    _onButtonShowTemplatesClick = (e: React.MouseEvent) => {
        this.setState(state => ({
            ...state,
            templateSelect: this.state.templateSelect ? false : true
        }));
        this._selectRef?.current?.focus();
        e.stopPropagation();
        e.preventDefault();
    };

    _getAvailabilityQA = (vehicle: VehicleWithAvailability): string => {
        if (vehicle.available) {
            if (vehicle.data.gpsData?.lat && vehicle.data.gpsData?.lon) {
                return qa.schedulingPlanning.vehicleProfile.availableVehicle.withGPS;
            } else {
                return qa.schedulingPlanning.vehicleProfile.availableVehicle.withoutGPS;
            }
        } else {
            if (vehicle.data.gpsData?.lat && vehicle.data.gpsData?.lon) {
                return qa.schedulingPlanning.vehicleProfile.disabledVehicle.withGPS;
            } else {
                return qa.schedulingPlanning.vehicleProfile.disabledVehicle.withoutGPS;
            }
        }
    };

    render() {
        const { t } = this.props;

        const layout =
            this.state.selectValue.startsWith('vehicle-') ||
            this._getSelectVehicleProfileDefaultValue().startsWith('vehicle-')
                ? [10, 5, 5, 4]
                : [12, 8, 0, 4];

        const trailersShow =
            this.state.selectValue.startsWith('vehicle-') ||
            this._getSelectVehicleProfileDefaultValue().startsWith('vehicle-');

        const realTrailers = trailersVehicleStateObjectIds(
            [ExternalDeviceType.TrailerId, ExternalDeviceType.TrailerManual],
            this.props.vehicleStates?.find(
                vehicleState =>
                    vehicleState?.monitoredObjectId === String(this.props.logic.plannerLogic().selectedVehicleId)
            )
        );
        return (
            <>
                <div className="route-params-configurator-new">
                    <div className="route-configurator-new-header">
                        <Row align="middle">
                            <Col span={layout[0]}>
                                <div>{t('routing.configurator.routeName')}</div>
                                <Select<State['selectValue']>
                                    innerRef={this._selectRef}
                                    className={cn('route-configurator-route-name-select', {
                                        'tm-warning': this.state.noMoreAvailable,
                                        'route-configurator-route-name-select-edited': this.state.transportNameEdited
                                    })}
                                    qa={qa.planner.configurator.inputName}
                                    value={TRANSPORT_NAME_KEY}
                                    size="large"
                                    showSearch={this.state.templateSelect}
                                    showArrow={false}
                                    open={this.state.templateSelect ?? false}
                                    filterOption={(input, option) => searched(input, String(option?.label))}
                                    disabled={
                                        this.props.logic.plannerLogic().availableTransportTemplatesLoading ||
                                        this.props.logic.plannerLogic().generateRouteLoading
                                    }
                                    loading={this.props.logic.plannerLogic().availableTransportTemplatesLoading}
                                    onSelect={this._onRouteNameSelect}
                                    onSearch={this._onRouteNameSearch}
                                    onBlur={() => {
                                        if (this.state.templateSelect && !this.state.editedTemplateName) {
                                            this.setState(() => ({
                                                templateSelect: false
                                            }));
                                        }
                                    }}
                                >
                                    {!this.state.templateSelect && (
                                        <Select.Option
                                            data-qa={qa.planner.configurator.inputName}
                                            key={TRANSPORT_NAME_KEY}
                                            value={TRANSPORT_NAME_KEY}
                                            label={this.props.logic.plannerLogic().transport.name ?? ''}
                                        >
                                            <Row align="middle" className="transport-name-item">
                                                <Col>
                                                    <EditItem
                                                        className="transport-name-item-editor"
                                                        showDelete={false}
                                                        showEdit={true}
                                                        editable={true}
                                                        autoSelectEdited={true}
                                                        label={this.props.logic.plannerLogic().transport.name ?? ''}
                                                        onButtonEditClick={this._onButtonEditTemplateNameClick}
                                                        onButtonCancelClick={this._onButtonCancelTemplateNameClick}
                                                        onButtonSaveClick={this._onButtonSaveTemplateNameClick}
                                                        qaInput={qa.planner.configurator.transportName.inputName}
                                                        qaEdit={qa.planner.configurator.transportName.btnEdit}
                                                        qaCancel={qa.planner.configurator.transportName.btnCancel}
                                                        qaSave={qa.planner.configurator.transportName.btnSave}
                                                    >
                                                        {this.props.logic.plannerLogic().transport.name ?? ''}
                                                    </EditItem>
                                                </Col>
                                                <Col className="transport-name-item-select">
                                                    <Button
                                                        disabled={
                                                            this.props.logic.plannerLogic()
                                                                .availableTransportTemplatesLoading ||
                                                            this.props.logic.plannerLogic().generateRouteLoading
                                                        }
                                                        icon={<img src={Topic} alt="transport-name-item-select" />}
                                                        onClick={this._onButtonShowTemplatesClick}
                                                        qa={qa.planner.configurator.transportName.btnShowRoutes}
                                                    />
                                                </Col>
                                            </Row>
                                        </Select.Option>
                                    )}
                                    {this.state.templateSelect &&
                                        this.props.logic.plannerLogic().availableTransportTemplates.length > 0 && (
                                            <Select.OptGroup label={t('routing.configurator.savedRoutes')}>
                                                {this.props.logic
                                                    .plannerLogic()
                                                    .availableTransportTemplates.map((template, index) => (
                                                        <Select.Option
                                                            data-qa={qa.schedulingPlanning.templates.savesRoutes}
                                                            key={`transport-template-${String(index)}`}
                                                            value={template.id}
                                                            label={template.name}
                                                        >
                                                            <Tooltip title={template.name}>
                                                                <EditItem
                                                                    editable={true}
                                                                    autoSelectEdited={true}
                                                                    label={template.name}
                                                                    labelKey={template.id}
                                                                    qaInput="asdfassssss"
                                                                    qaEdit={qa.schedulingPlanning.templates.edit}
                                                                    qaDelete={qa.schedulingPlanning.templates.delete}
                                                                    onButtonSaveClick={this._onTemplateChangeName}
                                                                    onButtonDeleteClick={this._onTemplateDelete}
                                                                    onButtonEditClick={this._onTemplateEdit}
                                                                    onButtonCancelClick={this._onTemplateEditCancel}
                                                                >
                                                                    {template.name}
                                                                </EditItem>
                                                            </Tooltip>
                                                        </Select.Option>
                                                    ))}
                                            </Select.OptGroup>
                                        )}
                                </Select>
                            </Col>
                            <Col span={layout[1]}>
                                <div>
                                    {t('common.vehicle')}{' '}
                                    {this.props.logic.plannerLogic().isPuescTransport &&
                                        (!this.props.logic.plannerLogic().selectedVehicleId ||
                                            !this.props.logic.plannerLogic().selectedVehicleIsInPuesc) && (
                                            <PuescWarning
                                                type={
                                                    this.props.logic.plannerLogic().puescValidationErrors?.vehicle ===
                                                    false
                                                        ? 'error'
                                                        : 'warning'
                                                }
                                                tooltip={
                                                    this.props.logic.plannerLogic().puescValidationErrors?.vehicle ===
                                                    false
                                                        ? this.props.t('Planner.puesc.noVehicle')
                                                        : undefined
                                                }
                                                size="small"
                                            />
                                        )}
                                </div>
                                <Select<State['selectValue']>
                                    className={cn('route-configurator-vehicle-select', {
                                        'tm-warning': this.state.noMoreAvailable
                                    })}
                                    size="large"
                                    value={this._getSelectVehicleProfileDefaultValue()}
                                    onSelect={this._onVehicleOrProfileSelect}
                                    onClick={() => {
                                        this.setState({
                                            noMoreAvailable: false
                                        });
                                    }}
                                    dropdownMatchSelectWidth={trailersShow ? 278 : 335}
                                    showSearch
                                    filterOption={(input, option) => searched(input, String(option?.label))}
                                    disabled={
                                        !!this.props.logic.plannerLogic().lockedMonitoredObjectId ||
                                        this.props.logic.plannerLogic().availableVehiclesLoading
                                    }
                                    loading={this.props.logic.plannerLogic().availableVehiclesLoading}
                                    qa={qa.planner.configurator.selectVehicle}
                                >
                                    <Select.Option
                                        value="newProfile"
                                        key="newProfile"
                                        className="add-new-profile"
                                        data-qa={qa.schedulingPlanning.vehicleProfile.createProfile}
                                        disabled={
                                            ![Role.VR_W].some(r => this.roles.includes(r)) ||
                                            this.props.logic.demo().isActive
                                        }
                                        label={t('routing.configurator.select.newProfile')}
                                    >
                                        <img src={ItemAdd} alt="add-new-profile" />
                                        {t('routing.configurator.select.newProfile')}
                                    </Select.Option>
                                    {this.props.logic.plannerLogic().availableVehicles.length > 0 && (
                                        <Select.OptGroup label={t('routing.configurator.select.vehicles')}>
                                            {this.props.logic.plannerLogic().availableVehicles.map(vehicle => (
                                                <Select.Option
                                                    data-qa={this._getAvailabilityQA(vehicle)}
                                                    disabled={
                                                        (!vehicle.available &&
                                                            vehicle.data.monitoredObjectId !==
                                                                this.props.logic.plannerLogic()
                                                                    .defaultMonitoredObjectId) ||
                                                        (this.props.logic.plannerLogic().isPuescTransport &&
                                                            !this.props.logic
                                                                .plannerLogic()
                                                                .externalSystemVehicleIds?.includes(
                                                                    Number(vehicle.data.monitoredObjectId)
                                                                ))
                                                    }
                                                    title={
                                                        vehicle.available
                                                            ? t('routing.configurator.vehicleAvailability.available')
                                                            : t('routing.configurator.vehicleAvailability.unavailable')
                                                    }
                                                    key={`vehicle-${String(vehicle.data.monitoredObjectId)}`}
                                                    value={`vehicle-${String(vehicle.data.monitoredObjectId)}`}
                                                    label={vehicle.data.rn}
                                                >
                                                    <VehicleItem
                                                        className={cn('select-puesc-vehicle-wrapper', {
                                                            'select-puesc-flag':
                                                                this.props.logic.plannerLogic().isPuescTransport &&
                                                                this.props.logic
                                                                    .plannerLogic()
                                                                    .externalSystemVehicleIds?.includes(
                                                                        Number(vehicle.data.monitoredObjectId)
                                                                    )
                                                        })}
                                                        vehicle={vehicle}
                                                        currency={
                                                            vehicle.profile?.currency
                                                                ? vehicle.profile?.currency
                                                                : this.state.configuration.pricePerKM.currency
                                                        }
                                                    />
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                    {[Role.VP_R].some(r => this.roles.includes(r)) &&
                                        this.props.logic.plannerLogic().availableVehicleProfiles.length > 0 && (
                                            <Select.OptGroup label={t('routing.configurator.select.profiles')}>
                                                {this.props.logic
                                                    .plannerLogic()
                                                    .availableVehicleProfiles.map((profile, index) => (
                                                        <Select.Option
                                                            key={`profile-${String(index)}`}
                                                            value={`profile-${profile.id}`}
                                                            data-qa={qa.schedulingPlanning.vehicleProfile.profile}
                                                            label={profile.name}
                                                        >
                                                            <VehicleProfileItem
                                                                profile={profile}
                                                                demoMode={this.props.logic.demo().isActive}
                                                                currency={this.state.configuration.pricePerKM.currency}
                                                                qaEdit={qa.planner.createVehicleProfile.btnEdit}
                                                                qaDelete={qa.planner.createVehicleProfile.btnEdit}
                                                                onButtonEditClick={this.props.onEditProfileClick}
                                                                onButtonDeleteClick={this.props.onDeleteProfileClick}
                                                            />
                                                        </Select.Option>
                                                    ))}
                                            </Select.OptGroup>
                                        )}
                                </Select>
                            </Col>
                            {trailersShow && (
                                <Col span={layout[2]}>
                                    <div>
                                        {t('common.trailer')}{' '}
                                        {this.props.logic.plannerLogic().isPuescTransport &&
                                            !this.props.logic.plannerLogic().selectedTrailerId &&
                                            !this.props.logic.plannerLogic().puescNoTrailer && (
                                                <PuescWarning
                                                    type={
                                                        this.props.logic.plannerLogic().puescValidationErrors
                                                            ?.trailer === false
                                                            ? 'error'
                                                            : 'warning'
                                                    }
                                                    tooltip={
                                                        this.props.logic.plannerLogic().puescValidationErrors
                                                            ?.trailer === false
                                                            ? this.props.t('Planner.puesc.noTrailer')
                                                            : undefined
                                                    }
                                                    size="small"
                                                />
                                            )}
                                        {realTrailers &&
                                            (!this.props.logic.plannerLogic().selectedTrailerId ||
                                                (String(this.props.logic.plannerLogic().selectedTrailerId) !==
                                                    realTrailers.toString() && (
                                                    <Tooltip
                                                        data-qa={qa.routeOverview.table.fieldRealTrailer}
                                                        className={'planner-actual-trailer'}
                                                        title={`${t('RouteOverviewTable.actualTrailer')}: ${
                                                            realTrailers
                                                                .map(
                                                                    realTrailer =>
                                                                        this.props.logic
                                                                            .plannerLogic()
                                                                            .availableTrailers?.find(
                                                                                trailer =>
                                                                                    String(trailer.data?.id) ===
                                                                                    String(realTrailer)
                                                                            )?.data?.registrationNumber ?? realTrailer
                                                                )
                                                                .toString() !== ''
                                                                ? realTrailers
                                                                      .map(
                                                                          realTrailer =>
                                                                              this.props.logic
                                                                                  .plannerLogic()
                                                                                  .availableTrailers?.find(
                                                                                      trailer =>
                                                                                          String(trailer.data?.id) ===
                                                                                          String(realTrailer)
                                                                                  )?.data?.registrationNumber ??
                                                                              realTrailer
                                                                      )
                                                                      .toString()
                                                                : t('RouteOverviewTable.notPaired')
                                                        }`}
                                                    >
                                                        <img
                                                            src={icons.warning}
                                                            alt="actual-trailer"
                                                            data-qa={qa.routeOverview.table.fieldRealTrailer}
                                                        />
                                                    </Tooltip>
                                                )))}
                                    </div>
                                    <Select<number | 'withoutTrailer'>
                                        value={
                                            this.props.logic.plannerLogic().availableTrailersLoading
                                                ? 'withoutTrailer'
                                                : this._getSelectTrailerDefaultValue()
                                        }
                                        onSelect={this._onTrailerSelect}
                                        showSearch
                                        dropdownMatchSelectWidth={250}
                                        size="large"
                                        filterOption={(input, option) => searched(input, String(option?.label))}
                                        loading={this.props.logic.plannerLogic().availableTrailersLoading}
                                        qa={qa.planner.configurator.selectVehicle}
                                    >
                                        <Select.Option value="withoutTrailer">
                                            {t('routing.configurator.select.withoutTrailer')}
                                        </Select.Option>
                                        <Select.OptGroup label={t('routing.configurator.select.trailers')}>
                                            {this.props.logic.plannerLogic().availableTrailers.map((trailer, index) => (
                                                <Select.Option
                                                    disabled={!trailer.available}
                                                    data-qa={qa.schedulingPlanning.trailer.availableTrailer}
                                                    key={`trailer-${index}-${trailer.data?.id}`}
                                                    value={trailer.data?.id ?? 0}
                                                    label={trailer.data?.registrationNumber}
                                                >
                                                    <TrailerItem
                                                        rn={trailer.data?.registrationNumber}
                                                        profile={trailer.profile}
                                                    />
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    </Select>
                                </Col>
                            )}
                            <Col span={layout[3]}>
                                <>
                                    <div>{t('routing.configurator.ratePerKm')}</div>
                                    <Input.Number
                                        size="large"
                                        precision={2}
                                        min={0}
                                        className={cn({
                                            'ant-input-number-invalid': !this.props.companyCostPerKm
                                                ? true
                                                : isNaN(this.props.companyCostPerKm.cost)
                                        })}
                                        disabled={
                                            this.props.logic.plannerLogic().availableVehiclesLoading ||
                                            this.props.logic.plannerLogic().vehiclesPricePerKmLoading
                                        }
                                        disableStepIcon={true}
                                        onChange={this._onPriceChange}
                                        value={this.props.companyCostPerKm?.cost}
                                        postfix={`${this.props.companyCostPerKm?.currency}`}
                                        qa={qa.planner.configurator.inputCosts}
                                    />
                                </>
                            </Col>
                        </Row>
                    </div>
                </div>
                {this.state.openTemplateDeleteConfirm && (
                    <Confirm
                        type={MessageType.WARNING}
                        header={t('routing.configurator.template.confirmDeleteTitle')}
                        message={t('routing.configurator.template.confirmDeleteText')}
                        confirmLabel={t('common.delete')}
                        onConfirm={this._onConfirmTemplateDelete}
                        onCancel={this._onConfirmTemplateDeleteCancel}
                    />
                )}
                {this.state.openTemplateRenameConfirm && (
                    <Confirm
                        type={MessageType.INFO}
                        header={t('routing.configurator.template.confirmRenameTitle')}
                        message={t('routing.configurator.template.confirmRenameText', {
                            name: '"' + this.state.templateResult.name + '"'
                        })}
                        confirmLabel={t('routing.configurator.template.confirmRenameLabel')}
                        onConfirm={this._onConfirmTemplateRename}
                        onCancel={this._onConfirmTemplateRenameCancel}
                    />
                )}
            </>
        );
    }

    private _onTrailerSelect = async (value: number | 'withoutTrailer') => {
        this.props.logic.plannerLogic().setPuescNoTrailer(value === 'withoutTrailer');

        if (value === 'withoutTrailer') {
            this.props.logic.plannerLogic().removeMonitoredObjectFromTransport(MonitoredObjectFleetType.Trailer);
        } else {
            this.props.logic.plannerLogic().setMonitoredObjectOnTransport(value, MonitoredObjectFleetType.Trailer);
            this.props.logic.plannerLogic().clearColdchain(value);
        }

        try {
            await this.props.logic.plannerLogic().planRoute(this.props.logic.plannerLogic().transport);
            this.props.logic.plannerLogic().drawRouteOnMap();
        } catch (err) {
            console.error(`Could not recompute route to transport, err: ${err}`);
            this.props.logic.plannerLogic().updateTransportNameByPlaces();
        }
    };

    private _getSelectVehicleProfileDefaultValue = () => {
        return this.props.logic.plannerLogic().getSelectVehicleProfile();
    };

    private _getSelectTrailerDefaultValue = () => {
        return this.props.logic.plannerLogic().selectedTrailerId ?? 'withoutTrailer';
    };

    private _onPriceChange = (value: number | string | undefined) => {
        const valueValid = !isNaN(Number(value)) && Number(value) > 0;

        if (valueValid) {
            this.props.logic.plannerLogic().transport.costPerKm = {
                value: Number(value),
                currency: this.state.configuration.pricePerKM.currency
            };
            this.setState(
                state => ({
                    configuration: {
                        ...state.configuration,
                        pricePerKM: {
                            ...state.configuration.pricePerKM,
                            cost: Number(value)
                        }
                    }
                }),
                () => {
                    this.props.onRoutePriceChange?.(this.state.configuration);
                }
            );
        }
    };

    private _onVehicleOrProfileSelect = (value: string) => {
        if (value.startsWith('profile-')) {
            const profile = this.props.logic
                .plannerLogic()
                .availableVehicleProfiles.find(p => p.id?.toString() === value.replace('profile-', ''));

            if (profile?.id) {
                this.props.logic.plannerLogic().updateTransportProfileAndReroute(profile.id);
            }

            const pricePerKmInClientCurrency = this.props.logic
                .plannerLogic()
                .getPriceByCurrency(
                    profile?.pricePerKm ?? 0,
                    this.props.companyCostPerKm?.currency ?? AvailableCurrencies.EUR
                );

            this.setState(
                state => ({
                    selectValue: value,
                    configuration: {
                        ...state.configuration,
                        pricePerKM: {
                            cost: pricePerKmInClientCurrency
                                ? pricePerKmInClientCurrency
                                : this.props.companyCostPerKm?.cost ?? 0,
                            currency: this.props.companyCostPerKm?.currency ?? AvailableCurrencies.EUR
                        }
                    }
                }),
                () =>
                    this.props.logic
                        .plannerLogic()
                        .setTransportCostPerKm(
                            this.state.configuration.pricePerKM.cost,
                            this.state.configuration.pricePerKM.currency
                        )
            );
        } else if (value.startsWith('vehicle-')) {
            const vehicle = this.props.logic
                .plannerLogic()
                .availableVehicles.find(p => p.data.monitoredObjectId === value.replace('vehicle-', ''));

            if (vehicle?.data.monitoredObjectId) {
                this.props.logic
                    .plannerLogic()
                    .updateTransportMonitoredObjectAndReroute(
                        Number(vehicle.data.monitoredObjectId),
                        MonitoredObjectFleetType.Vehicle
                    );
            }

            this.setState(
                state => ({
                    selectValue: value,
                    configuration: {
                        ...state.configuration,
                        pricePerKM: vehicle?.profile?.costPerKm
                            ? {
                                  cost: vehicle?.profile?.costPerKm ?? 0,
                                  currency:
                                      vehicle?.profile?.currency ??
                                      this.props.companyCostPerKm?.currency ??
                                      AvailableCurrencies.EUR
                              }
                            : {
                                  cost: this.props.companyCostPerKm?.cost ?? 0,
                                  currency: this.props.companyCostPerKm?.currency ?? AvailableCurrencies.EUR
                              }
                    }
                }),
                () =>
                    this.props.logic
                        .plannerLogic()
                        .setTransportCostPerKm(
                            this.state.configuration.pricePerKM.cost,
                            this.state.configuration.pricePerKM.currency
                        )
            );
        } else if (value === 'newProfile') {
            this.props.onCreateProfileClick?.();
        } else {
            this.props.logic.plannerLogic().removeMonitoredObjectOrProfileAndReroute();

            this.setState({
                selectValue: value
            });
        }
    };

    private _onRouteNameSelect = async (value: string) => {
        if (value !== TRANSPORT_NAME_KEY) {
            await this.props.logic.plannerLogic().transportTemplateToTransport(value);

            const transport = this.props.logic.plannerLogic().transport;
            this.setState(
                state => ({
                    ...state,
                    selectValue: `profile-${transport.desiredVehicleProfile}`,
                    configuration: {
                        ...state.configuration,
                        pricePerKM: {
                            cost: transport.costPerKm?.value ?? 0,
                            currency: (transport.costPerKm?.currency ?? AvailableCurrencies.EUR) as AvailableCurrencies
                        }
                    }
                }),
                () => {
                    this.props.onRoutePriceChange?.(this.state.configuration);
                }
            );
            await this.props.logic.plannerLogic().planRoute(this.props.logic.plannerLogic().transport);
            this.props.logic.plannerLogic().drawRouteOnMap();
            this.setState(() => ({
                templateSelect: undefined
            }));
        }
    };

    private _onRouteNameSearch = (value: string) => {
        this.setState(state => ({
            ...state,
            routeName: value
        }));
    };
}

export default withTranslation()(withLogicContext(observer(RouteParamsConfigurator)));
