import { Select } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Control } from '../Control';

export interface SelectOption {
    label: React.ReactNode;
    value: string | number;
    disabled?: boolean;
    qa?: string;
}

type Props = {
    className?: string;
    options?: SelectOption[];
    warning?: boolean;
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
    qa?: string;
    mode?: SelectProps<SelectOption>['mode'];
    width?: number;
    innerRef?: React.Ref<RefSelectProps> | undefined;
};

function EnhancedSelect<ValueType extends SelectValue = SelectValue>({
    className,
    options = [],
    warning,
    label,
    error,
    children,
    qa,
    innerRef,
    ...props
}: SelectProps<ValueType> & Props) {
    function renderOptions() {
        return options.map((option, index) => (
            <Select.Option
                key={index}
                value={option.value}
                title={option.title ?? option.title}
                disabled={option.disabled ? true : false}
                data-qa={option.qa}
                className="ant-select-option"
            >
                {option.label}
            </Select.Option>
        ));
    }

    function renderSelect() {
        return (
            <Select
                className={cn({
                    'ant-select-warning': warning,
                    'ant-select--with-label': label,
                    empty: !props.value && !props.defaultValue
                })}
                data-qa={qa}
                ref={innerRef}
                {...props}
            >
                {children || renderOptions()}
            </Select>
        );
    }
    return (
        <Control label={label} error={error} className={className}>
            {renderSelect()}
        </Control>
    );
}
EnhancedSelect.Option = Select.Option;
EnhancedSelect.OptGroup = Select.OptGroup;
export default EnhancedSelect;
