import moment from 'moment';
import { EFuelType, VehicleStateObject } from 'generated/graphql';

import { vehicles, trailers, monitoredObjectTypes } from './vehicles';
import { users } from './users';
import { transports } from './transports';
import { pois } from './pois';
import { externalDeviceStateData, temperatureSensorsData } from './coldchain';
import { PuescStatus, PuescStatusResponseFromJSON } from 'generated/backend-api';
import { UserTokenTokenTypeEnum } from 'generated/new-main';

const drivingDuration = [
    5 * 3600 + 34 * 60,
    2 * 3600 + 1 * 60,
    0 * 3600 + 30 * 60,
    2 * 3600 + 15 * 60,
    5 * 3600 + 3 * 60,
    2 * 3600 + 23 * 60
];

// Current week - Drive time, 14-days drive
export const weeklyDrivingDuration = [
    36 * 3600 + 34 * 60,
    42 * 3600 + 1 * 60,
    14 * 3600 + 30 * 60,
    16 * 3600 + 30 * 60,
    12 * 3600 + 23 * 60,
    32 * 3600 + 21 * 60
];

// Previous week rest - Length of rest period
export const lastDailyRestDuration = [
    13 * 3600 + 21 * 60,
    12 * 3600 + 41 * 60,
    16 * 3600 + 41 * 60,
    15 * 3600 + 41 * 60,
    22 * 3600 + 41 * 60,
    22 * 3600 + 11 * 60
];

const currentPlaces = [
    // CURRENT PLACE 1
    [
        {
            lang: 'custom',
            country: '',
            countryCode: '',
            town: '',
            route: '',
            address: pois[4].name,
            street_address: '',
            postalCode: '',
            source: 'google'
        },
        {
            lang: 'en',
            country: 'Spain',
            countryCode: 'ES',
            town: 'Barcelona',
            route: 'Rambla de Prim',
            address: '(ES) 08020, Barcelona, Rambla de Prim',
            street_address: 'Rambla de Prim 125',
            postalCode: '08020',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Španielsko',
            countryCode: 'ES',
            town: 'Barcelona',
            route: 'Rambla de Prim',
            address: '(ES) 08020, Barcelona, Rambla de Prim',
            street_address: 'Rambla de Prim 125',
            postalCode: '08020',
            source: 'google'
        }
    ],
    // CURRENT PLACE 2
    [
        {
            lang: 'custom',
            country: '',
            countryCode: '',
            town: '',
            route: '',
            address: pois[0].name,
            street_address: '',
            postalCode: '',
            source: 'google'
        },
        {
            lang: 'en',
            country: 'Czechia',
            countryCode: 'CZ',
            town: 'Rozvadov',
            route: '605',
            address: '(CZ) 348 06, Rozvadov, 605',
            street_address: 'Silnice II/605 117',
            postalCode: '348 06',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Česko',
            countryCode: 'CZ',
            town: 'Rozvadov',
            route: '605',
            address: '(CZ) 348 06, Rozvadov, 605',
            street_address: 'Silnice II/605 117',
            postalCode: '348 06',
            source: 'google'
        }
    ],
    // CURRENT PLACE 3
    [
        {
            lang: 'cs',
            country: 'Polsko',
            countryCode: 'PL',
            town: 'Zduny',
            route: 'A1',
            address: '(PL) 83-115, Zduny, A1',
            street_address: 'Autostrada Bursztynowa ',
            postalCode: '83-115',
            source: 'google'
        },
        {
            lang: 'en',
            country: 'Poland',
            countryCode: 'PL',
            town: 'Zduny',
            route: 'A1',
            address: '(PL) 83-115, Zduny, A1',
            street_address: 'Autostrada Bursztynowa ',
            postalCode: '83-115',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Poľsko',
            countryCode: 'PL',
            town: 'Zduny',
            route: 'A1',
            address: '(PL) 83-115, Zduny, A1',
            street_address: 'Autostrada Bursztynowa ',
            postalCode: '83-115',
            source: 'google'
        }
    ],
    // CURRENT PLACE 4
    [
        {
            lang: 'en',
            country: 'Austria',
            countryCode: 'AT',
            town: 'Wien',
            route: 'Ailecgasse',
            address: '(AT) 1110, Wien, Ailecgasse',
            street_address: 'Ailecgasse 51',
            postalCode: '1110',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Rakúsko',
            countryCode: 'AT',
            town: 'Wien',
            route: 'Ailecgasse',
            address: '(AT) 1110, Wien, Ailecgasse',
            street_address: 'Ailecgasse 51',
            postalCode: '1110',
            source: 'google'
        }
    ],
    // CURRENT PLACE 5
    [
        {
            lang: 'custom',
            country: '',
            countryCode: '',
            town: '',
            route: '',
            address: pois[2].name,
            street_address: '',
            postalCode: '',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Nemecko',
            countryCode: 'DE',
            town: 'Aachen',
            route: 'Am Ravelsberg',
            address: '(DE) 52080, Aachen, Am Ravelsberg',
            street_address: 'Am Ravelsberg 54',
            postalCode: '52080',
            source: 'google'
        }
    ],
    // CURRENT PLACE 6
    [
        {
            lang: 'en',
            country: 'Italy',
            countryCode: 'IT',
            town: 'Modeano',
            route: 'E70',
            address: '(IT) 33056, Modeano, E70',
            street_address: 'A4 Torino - Trieste 1',
            postalCode: '33056',
            source: 'google'
        },
        {
            lang: 'sk',
            country: 'Taliansko',
            countryCode: 'IT',
            town: 'Modeano',
            route: 'E70',
            address: '(IT) 33056, Modeano, E70',
            street_address: 'A4 Torino - Trieste 1',
            postalCode: '33056',
            source: 'google'
        }
    ]
];

export const defaultAddressStructured = [
    {
        address: '(ES) 17700, La Jonquera, Avinguda del Canigó',
        postalCode: '17700',
        countryCode: 'ES',
        country: 'Španělsko',
        town: 'La Jonquera',
        route: 'Avinguda del Canigó',
        street_address: 'Avinguda del Canigó 7P',
        lang: 'cs'
    },
    {
        address: '(ES) 17700, La Jonquera, Avinguda del Canigó',
        postalCode: '17700',
        countryCode: 'ES',
        country: 'Spain',
        town: 'La Jonquera',
        route: 'Avinguda del Canigó',
        street_address: 'Avinguda del Canigó 7P',
        lang: 'en'
    },
    {
        address: '(ES) 17700, La Jonquera, Avinguda del Canigó',
        postalCode: '17700',
        countryCode: 'ES',
        country: 'Španielsko',
        town: 'La Jonquera',
        route: 'Avinguda del Canigó',
        street_address: 'Avinguda del Canigó 7P',
        lang: 'sk'
    }
];

export const vehicleStates: VehicleStateObject[] = [
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[0].registrationNumber,
        monitoredObjectFuelType: EFuelType.Diesel,
        customLabel: null,
        monitoredObjectId: '1',
        address: '(FR) 33380, Mios, E5',
        odometer: 131561345,
        trailers: [
            {
                id: trailers[0].id,
                registrationNumber: trailers[0].registrationNumber,
                customLabel: trailers[0].customLabel
            }
        ],
        gpsData: {
            time: new Date().toISOString(),
            lat: 41.464502,
            lon: 2.189802,
            alt: 0,
            speed: 53,
            angle: 3
        },
        car_battery: { volts: 21.7878 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 1 },
        driver: {
            name: `${users[0].name} ${users[0].surname}`,
            driver_token: users[0].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[0].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[0] + 0 * 3600 + 45 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: '2020-02-08T10:51:00',
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[0],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                biweeklyDrivingDuration: 53 * 3600 + 26 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[0] + 0 * 3600 + 45 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[0],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                drivingDuration14days: 53 * 3600 + 26 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T19:10:57Z', raw_value: 637, level: 285, id: 0 },
            { time: '2020-12-14T19:10:57Z', raw_value: 613, level: 0, id: 1 }
        ],
        adblue_level: {
            level: 92
        },
        weight: {
            weight: 59000
        },
        address_structured: currentPlaces[0] ?? defaultAddressStructured,
        activeTransports: [
            {
                id: '1',
                name: '(SK) 934 05, Levice, E. Sachsa - Žilina, Slovakia',
                firstRta: '2020-08-03T10:17:13Z',
                nextWaypoint: {
                    lat: 41.44884,
                    lon: 2.19896,
                    name: '(SK) 934 05, Levice, E. Sachsa',
                    rta: '2020-10-24T02:00:00Z',
                    eta: '2020-10-24T02:15:00Z',
                    estimatedDistance: 367853,
                    remainingDistancePercentage: 20,
                    addressStructured: transports![0].places![1].addressStructured
                }
            }
        ],
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString(),
        monitoredObjectGroups: [
            {
                name: 'All vehicles',
                id: 1001,
                clientId: 100
            }
        ],
        external_devices: [
            {
                device_id: trailers[0].id,
                device_type: 'trailer_manual',
                serial_number: trailers[0].id,
                metadata: {
                    registration_number: trailers[0].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            },
            ...temperatureSensorsData
                .filter(tsd => String(tsd.monitored_object) === '10' + String(vehicles[0].id))
                .map(tsd => {
                    const ext = externalDeviceStateData.find(ed => ed.device_id === tsd.id);
                    return {
                        device_id: tsd.id,
                        data: ext?.data,
                        device_type: ext?.device_type,
                        serial_number: tsd.serial_number,
                        metadata: {
                            registration_number: '',
                            sensor_name: tsd.sensor_name,
                            sensor_type: 'temperature_sensor',
                            sensor_zone: String(tsd.sensor_zone)
                        }
                    };
                })
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[1].registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '2',
        address: '(CZ) Modletice, Czechia',
        odometer: 546132770,
        trailers: [
            {
                id: trailers[1].id,
                registrationNumber: trailers[1].registrationNumber,
                customLabel: trailers[1].customLabel
            }
        ],
        gpsData: {
            time: new Date().toISOString(),
            lat: 49.67562,
            lon: 12.600738,
            alt: 0,
            speed: 0,
            angle: 44
        },
        car_battery: { volts: 24.83 },
        stateData: { time: '2020-12-14T19:13:07Z', ignition: 0 },
        driver: {
            name: `${users[1].name} ${users[1].surname}`,
            driver_token: users[1].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[1].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: '2020-12-14T16:51:00',
                drivingOrWorkingStartTime: null,
                activity: 'resting',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[1],
                restingDuration: 0 * 3600 + 26 * 60,
                workingDuration: 0 * 3600 + 0 * 60,
                biweeklyDrivingDuration: 47 * 3600 + 59 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[1] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                activity: 'resting',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[1],
                restingDuration: 0 * 3600 + 26 * 60,
                workingDuration: 0 * 3600 + 0 * 60,
                drivingDuration14days: 47 * 3600 + 59 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T18:59:38Z', raw_value: 591, level: 130, id: 0 },
            { time: '2020-12-14T18:59:38Z', raw_value: 607, level: 0, id: 1 }
        ],
        adblue_level: {
            level: 39
        },
        weight: {
            weight: 20000
        },
        address_structured: currentPlaces[1] ?? defaultAddressStructured,
        activeTransports: [
            {
                id: '2',
                name: '(SK) 974 01, Banská Bystrica, Majerská cesta - 976 13 Slovenská Ľupča, Slovensko',
                firstRta: '2020-10-19T13:32:52Z',
                nextWaypoint: {
                    lat: 48.76554429999999,
                    lon: 19.2755917,
                    name: '976 13 Slovenská Ľupča, Slovensko',
                    rta: '2020-10-24T02:24:50Z',
                    eta: '2020-10-24T02:24:50Z',
                    estimatedDistance: 1283825,
                    remainingDistancePercentage: 0,
                    addressStructured: transports![1].places![1].addressStructured
                }
            }
        ],
        last_change_state: moment().add('-26', 'minutes').toISOString(),
        external_devices: [
            {
                device_id: trailers[1].id,
                device_type: 'trailer_manual',
                serial_number: trailers[1].id,
                metadata: {
                    registration_number: trailers[1].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            },
            ...temperatureSensorsData
                .filter(tsd => String(tsd.monitored_object) === '10' + String(vehicles[1].id))
                .map(tsd => {
                    const ext = externalDeviceStateData.find(ed => ed.device_id === tsd.id);
                    return {
                        device_id: tsd.id,
                        data: ext?.data,
                        device_type: ext?.device_type,
                        serial_number: tsd.serial_number,
                        metadata: {
                            registration_number: '',
                            sensor_name: tsd.sensor_name,
                            sensor_type: 'temperature_sensor',
                            sensor_zone: String(tsd.sensor_zone)
                        }
                    };
                })
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[2].registrationNumber,
        monitoredObjectFuelType: EFuelType.Diesel,
        customLabel: null,
        monitoredObjectId: '3',
        address: '(SK) 821 01, Bratislava, Nevädzová',
        odometer: 342065000.82,
        trailers: [
            {
                id: trailers[2].id,
                registrationNumber: trailers[2].registrationNumber,
                customLabel: trailers[2].customLabel
            }
        ],
        gpsData: {
            time: new Date().toISOString(),
            lat: 54.04543907395319,
            lon: 18.6712646484375,
            alt: 131,
            speed: 80,
            angle: 180
        },
        car_battery: { volts: 24.96 },
        stateData: { time: new Date().toISOString(), ignition: 1 },
        driver: {
            name: `${users[2].name} ${users[2].surname}`,
            driver_token: users[2].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[2].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[2] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: null,
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[2],
                restingDuration: 0 * 3600 + 0 * 60,
                workingDuration: 0 * 3600 + 0 * 60,
                biweeklyDrivingDuration: 75 * 3600 + 30 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[2] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[2],
                restingDuration: 0 * 3600 + 0 * 60,
                workingDuration: 0 * 3600 + 0 * 60,
                drivingDuration14days: 75 * 3600 + 30 * 600,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [{ time: '2020-12-11T10:32:27Z', raw_value: 823, level: 47.5, id: 0 }],
        address_structured: currentPlaces[2] ?? defaultAddressStructured,
        activeTransports: [
            {
                id: '3',
                name: 'Hamburg, Nemecko - Odesa, Odeska, Ukrajina, 65000',
                firstRta: '2020-12-12T01:58:49Z',
                nextWaypoint: {
                    lat: 53.5510846,
                    lon: 9.9936818,
                    name: 'Hamburg, Nemecko',
                    rta: '2020-12-12T01:58:49Z',
                    eta: '2020-12-12T01:58:49Z',
                    estimatedDistance: 0,
                    remainingDistancePercentage: 0,
                    addressStructured: transports![2].places![1].addressStructured
                }
            }
        ],
        last_change_state: moment().add('-15', 'minutes').toISOString(),
        external_devices: [
            {
                device_id: trailers[2].id,
                device_type: 'trailer_manual',
                serial_number: trailers[2].id,
                metadata: {
                    registration_number: trailers[2].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            },
            ...temperatureSensorsData
                .filter(tsd => String(tsd.monitored_object) === '10' + String(vehicles[2].id))
                .map(tsd => {
                    const ext = externalDeviceStateData.find(ed => ed.device_id === tsd.id);
                    return {
                        device_id: tsd.id,
                        data: ext?.data,
                        device_type: ext?.device_type,
                        serial_number: tsd.serial_number,
                        metadata: {
                            registration_number: '',
                            sensor_name: tsd.sensor_name,
                            sensor_type: 'temperature_sensor',
                            sensor_zone: String(tsd.sensor_zone)
                        }
                    };
                })
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[3].registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '4',
        address: '(FR) 33380, Mios, E5',
        odometer: 122043000,
        trailers: [
            {
                id: trailers[3].id,
                registrationNumber: trailers[3].registrationNumber,
                customLabel: trailers[3].customLabel
            }
        ],
        gpsData: {
            time: new Date().toISOString(),
            lat: 48.14082,
            lon: 16.454025,
            alt: 0,
            speed: 0,
            angle: 252
        },
        car_battery: { volts: 21.792 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 2 },
        driver: {
            name: `${users[4].name} ${users[4].surname}`,
            driver_token: users[4].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[4].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[3] + 2 * 3600 + 36 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: null,
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[3],
                restingDuration: 0 * 3600 + 5 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                biweeklyDrivingDuration: 75 * 3600 + 30 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[3] + 2 * 3600 + 36 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[3],
                restingDuration: 0 * 3600 + 5 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                drivingDuration14days: 74 * 3600 + 22 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T19:10:57Z', raw_value: 637, level: 285, id: 0 },
            { time: '2020-12-14T19:10:57Z', raw_value: 613, level: 0, id: 1 }
        ],
        adblue_level: {
            level: 80
        },
        weight: {
            weight: 40000
        },
        address_structured: currentPlaces[3] ?? defaultAddressStructured,
        activeTransports: [],
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString(),
        external_devices: [
            {
                device_id: trailers[3].id,
                device_type: 'trailer_manual',
                serial_number: trailers[3].id,
                metadata: {
                    registration_number: trailers[3].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            }
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[4].registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '5',
        address: '(FR) 33380, Mios, E5',
        odometer: 349023548,
        trailers: [
            {
                id: trailers[5].id,
                registrationNumber: trailers[5].registrationNumber,
                customLabel: trailers[5].customLabel
            }
        ],
        gpsData: {
            time: new Date().toISOString(),
            lat: 50.803043,
            lon: 6.123635,
            alt: 0,
            speed: 76,
            angle: 82
        },
        car_battery: { volts: 23.1 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 1 },
        driver: {
            name: `${users[3].name} ${users[3].surname}`,
            driver_token: users[3].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[3].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[4] + 0 * 3600 + 45 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: null,
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[4],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                biweeklyDrivingDuration: 77 * 3600 + 37 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[4] + 0 * 3600 + 45 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[4],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                drivingDuration14days: 77 * 3600 + 37 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T19:10:57Z', raw_value: 637, level: 285, id: 0 },
            { time: '2020-12-14T19:10:57Z', raw_value: 613, level: 0, id: 1 }
        ],
        adblue_level: {
            level: 42
        },
        weight: {
            weight: 23000
        },
        address_structured: currentPlaces[4] ?? defaultAddressStructured,
        activeTransports: [
            {
                id: '5',
                name: '(SK) 934 05, Levice, E. Sachsa - Žilina, Slovakia',
                firstRta: '2020-08-03T10:17:13Z',
                nextWaypoint: {
                    lat: 48.18908,
                    lon: 18.60916,
                    name: '(SK) 934 05, Levice, E. Sachsa',
                    rta: '2020-08-05T16:12:38Z',
                    eta: '2020-08-05T16:12:38Z',
                    estimatedDistance: 366808,
                    remainingDistancePercentage: 224.11985384869186,
                    addressStructured: transports![4].places![1].addressStructured
                }
            }
        ],
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString(),
        external_devices: [
            ...temperatureSensorsData
                .filter(tsd => String(tsd.monitored_object) === '10' + String(vehicles[0].id))
                .map(tsd => {
                    const ext = externalDeviceStateData.find(ed => ed.device_id === tsd.id);
                    return {
                        device_id: tsd.id,
                        data: ext?.data,
                        device_type: ext?.device_type,
                        serial_number: tsd.serial_number,
                        metadata: {
                            registration_number: '',
                            sensor_name: tsd.sensor_name,
                            sensor_type: 'temperature_sensor',
                            sensor_zone: String(tsd.sensor_zone)
                        }
                    };
                })
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[5].registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '6',
        address: '(FR) 33380, Mios, E5',
        odometer: 49132345,
        trailers: [],
        gpsData: {
            time: new Date().toISOString(),
            lat: 45.818573,
            lon: 13.029495,
            alt: 0,
            speed: 95,
            angle: 63
        },
        car_battery: { volts: 23.897 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 1 },
        driver: {
            name: `${users[5].name} ${users[5].surname}`,
            driver_token: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[5].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[5] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: null,
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[5],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                biweeklyDrivingDuration: 57 * 3600 + 39 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[5] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[5],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                drivingDuration14days: 57 * 3600 + 39 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T19:10:57Z', raw_value: 637, level: 285, id: 0 },
            { time: '2020-12-14T19:10:57Z', raw_value: 613, level: 0, id: 1 }
        ],
        adblue_level: {
            level: 70
        },
        weight: {
            weight: 31000
        },
        address_structured: currentPlaces[5] ?? defaultAddressStructured,
        activeTransports: [
            {
                id: '6',
                name: '(SK) 934 05, Levice, E. Sachsa - Žilina, Slovakia',
                firstRta: '2020-08-01T10:17:13Z',
                nextWaypoint: {
                    lat: 48.18908,
                    lon: 18.60916,
                    name: '(SK) 934 05, Levice, E. Sachsa',
                    rta: '2020-08-04T10:00:00Z',
                    eta: '2020-08-04T10:30:00Z',
                    estimatedDistance: 366808,
                    remainingDistancePercentage: 224.11985384869186,
                    addressStructured: transports![5].places![1].addressStructured
                }
            }
        ],
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString(),
        external_devices: [
            {
                device_id: trailers[7].id,
                device_type: 'trailer_manual',
                serial_number: trailers[7].id,
                metadata: {
                    registration_number: trailers[7].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            }
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 1),
        rn: vehicles[7].registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '7',
        address: '(FR) 33380, Mios, E5',
        odometer: 49132345,
        trailers: [],
        gpsData: {
            time: new Date().toISOString(),
            lat: 45.818573,
            lon: 13.029495,
            alt: 0,
            speed: 95,
            angle: 63
        },
        car_battery: { volts: 24.66 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 1 },
        driver: {
            name: `${users[5].name} ${users[5].surname}`,
            driver_token: users[5].userTokens?.find(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)?.token,
            id: String(users[5].id),
            source: 'driver_card',
            time: '2020-12-14T18:10:56Z',
            state: {
                activityStartTime: moment()
                    .subtract(drivingDuration[5] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                drivingOrWorkingStartTime: null,
                activity: 'driving',
                nextBreakStartTime: null,
                drivingDuration: drivingDuration[5],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                biweeklyDrivingDuration: 57 * 3600 + 39 * 60,
                choppedDrivingTimeCapacity: null,
                nextBreakStartTime2: '2020-12-14T18:29:00',
                weeklyDrivingDuration: 0
            },
            state_new: {
                activityStartTime: moment()
                    .subtract(drivingDuration[5] + 0 * 3600 + 0 * 60)
                    .toISOString(),
                activity: 'driving',
                nextBreakStartTime: moment.utc().add(5, 'days').toISOString(),
                timestamp: moment.utc().toISOString(),
                drivingDuration: drivingDuration[5],
                restingDuration: 0 * 3600 + 45 * 60,
                workingDuration: 2 * 3600 + 36 * 60,
                drivingDuration14days: 57 * 3600 + 39 * 60,
                dailyUtilizationMaxHours: 15,
                dailyDriveMaxHours: 10
            }
        },
        fuelTanks: [
            { time: '2020-12-14T19:10:57Z', raw_value: 637, level: 285, id: 0 },
            { time: '2020-12-14T19:10:57Z', raw_value: 613, level: 0, id: 1 }
        ],
        address_structured: currentPlaces[5] ?? defaultAddressStructured,
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString(),
        external_devices: [
            {
                device_id: trailers[5].id,
                device_type: 'trailer_manual',
                serial_number: trailers[5].id,
                metadata: {
                    registration_number: trailers[5].registrationNumber + '',
                    sensor_name: undefined,
                    sensor_type: undefined,
                    sensor_zone: undefined
                }
            }
        ]
    } as VehicleStateObject,
    {
        monitoredObjectType: monitoredObjectTypes.find(t => t.id === 8),
        rn: vehicles.find(v => v.id === 1001)?.registrationNumber,
        monitoredObjectFuelType: EFuelType.Gasoline,
        customLabel: null,
        monitoredObjectId: '1001',
        address: '(FR) 33380, Mios, E5',
        odometer: 50291185,
        trailers: [],
        gpsData: {
            time: new Date().toISOString(),
            lat: 49.678817,
            lon: 12.6011047,
            alt: 0,
            speed: 0,
            angle: 3
        },
        car_battery: { volts: 24.36 },
        stateData: { time: '2020-12-14T19:04:20Z', ignition: 0 },
        fuelTanks: [{ time: '2020-12-14T19:10:57Z', raw_value: 50, level: 32, id: 0 }],
        address_structured: currentPlaces[1] ?? defaultAddressStructured,
        activeTransports: [],
        last_change_state: moment().add('-2', 'hours').add('-31', 'minutes').toISOString()
    } as VehicleStateObject
];

export const chunkPlaces = [
    // Transport place 1
    [
        [
            {
                lang: 'cs',
                country: 'Slovensko',
                countryCode: 'SK',
                town: 'Bratislava',
                route: 'Vlčie hrdlo',
                address: '(SK) 821 07, Bratislava, Vlčie hrdlo',
                street_address: 'Vlčie hrdlo 69',
                postalCode: '821 07',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Slovakia',
                countryCode: 'SK',
                town: 'Bratislava',
                route: 'Vlčie hrdlo',
                address: '(SK) 821 07, Bratislava, Vlčie hrdlo',
                street_address: 'Vlčie hrdlo 69',
                postalCode: '821 07',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Slovensko',
                countryCode: 'SK',
                town: 'Bratislava',
                route: 'Vlčie hrdlo',
                address: '(SK) 821 07, Bratislava, Vlčie hrdlo',
                street_address: 'Vlčie hrdlo 69',
                postalCode: '821 07',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Rakousko',
                countryCode: 'AT',
                town: 'Völkermarkt',
                route: 'E66',
                address: '(AT) 9100, Völkermarkt, E66',
                street_address: 'Süd Autobahn 5',
                postalCode: '9100',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: 'Völkermarkt',
                route: 'E66',
                address: '(AT) 9100, Völkermarkt, E66',
                street_address: 'Süd Autobahn 5',
                postalCode: '9100',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: 'Völkermarkt',
                route: 'E66',
                address: '(AT) 9100, Völkermarkt, E66',
                street_address: 'Süd Autobahn 5',
                postalCode: '9100',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Rakousko',
                countryCode: 'AT',
                town: 'Pörtschach am Wörthersee',
                route: 'Sallacher Str.',
                address: '(AT) 9210, Pörtschach am Wörthersee, Sallacher Str.',
                street_address: 'Sallacher Straße 80',
                postalCode: '9210',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: 'Pörtschach am Wörthersee',
                route: 'Pritschitzer Weg',
                address: '(AT) 9210, Pörtschach am Wörthersee, Pritschitzer Weg',
                street_address: 'Pritschitzer Weg 64',
                postalCode: '9210',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: 'Pörtschach am Wörthersee',
                route: 'Sallacher Str.',
                address: '(AT) 9210, Pörtschach am Wörthersee, Sallacher Str.',
                street_address: 'Sallacher Straße 80',
                postalCode: '9210',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Itálie',
                countryCode: 'IT',
                town: '',
                route: 'E70',
                address: '(IT) 25010, , E70',
                street_address: 'A21 Torino - Piacenza - Brescia ',
                postalCode: '25010',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: '',
                route: 'E70',
                address: '(IT) 25010, , E70',
                street_address: 'A21 Torino - Piacenza - Brescia ',
                postalCode: '25010',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: '',
                route: 'E70',
                address: '(IT) 25010, , E70',
                street_address: 'A21 Torino - Piacenza - Brescia ',
                postalCode: '25010',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Itálie',
                countryCode: 'IT',
                town: 'Boissano',
                route: 'Via Fornaci',
                address: '(IT) 17054, Boissano, Via Fornaci',
                street_address: 'Via Fornaci 29',
                postalCode: '17054',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: 'Boissano',
                route: 'A10',
                address: '(IT) 17054, Boissano, A10',
                street_address: 'Autostrada dei Fiori 4',
                postalCode: '17054',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: 'Boissano',
                route: 'Via Fornaci',
                address: '(IT) 17054, Boissano, Via Fornaci',
                street_address: 'Via Fornaci 29',
                postalCode: '17054',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Francie',
                countryCode: 'FR',
                town: 'Les Arcs',
                route: 'A8',
                address: '(FR) 83460, Les Arcs, A8',
                street_address: 'La Provençale ',
                postalCode: '83460',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'France',
                countryCode: 'FR',
                town: 'Les Arcs',
                route: 'A8',
                address: '(FR) 83460, Les Arcs, A8',
                street_address: 'La Provençale ',
                postalCode: '83460',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Francúzsko',
                countryCode: 'FR',
                town: 'Les Arcs',
                route: 'A8',
                address: '(FR) 83460, Les Arcs, A8',
                street_address: 'La Provençale ',
                postalCode: '83460',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Francie',
                countryCode: 'FR',
                town: 'Bessan',
                route: 'A9',
                address: '(FR) 34550, Bessan, A9',
                street_address: 'La Languedocienne ',
                postalCode: '34550',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'France',
                countryCode: 'FR',
                town: 'Bessan',
                route: 'A9',
                address: '(FR) 34550, Bessan, A9',
                street_address: 'La Languedocienne ',
                postalCode: '34550',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Francúzsko',
                countryCode: 'FR',
                town: 'Bessan',
                route: 'A9',
                address: '(FR) 34550, Bessan, A9',
                street_address: 'La Languedocienne ',
                postalCode: '34550',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Španělsko',
                countryCode: 'ES',
                town: 'Barcelona',
                route: 'B-10',
                address: '(ES) 08033, Barcelona, B-10',
                street_address: 'Ronda Litoral 1983',
                postalCode: '08033',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Spain',
                countryCode: 'ES',
                town: 'Barcelona',
                route: 'B-10',
                address: '(ES) 08033, Barcelona, B-10',
                street_address: 'Ronda Litoral 1983',
                postalCode: '08033',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Španielsko',
                countryCode: 'ES',
                town: 'Barcelona',
                route: 'B-10',
                address: '(ES) 08033, Barcelona, B-10',
                street_address: 'Ronda Litoral 1983',
                postalCode: '08033',
                source: 'google'
            }
        ]
    ],
    // Transport place 3
    [
        [
            {
                lang: 'cs',
                country: 'Česko',
                countryCode: 'CZ',
                town: 'Plzeň',
                route: 'Unnamed Road',
                address: '(CZ) 318 00, Plzeň, Unnamed Road',
                street_address: 'Unnamed Road ',
                postalCode: '318 00',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Czechia',
                countryCode: 'CZ',
                town: 'Plzeň',
                route: 'Unnamed Road',
                address: '(CZ) 318 00, Plzeň, Unnamed Road',
                street_address: 'Unnamed Road ',
                postalCode: '318 00',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Česko',
                countryCode: 'CZ',
                town: 'Plzeň',
                route: 'Unnamed Road',
                address: '(CZ) 318 00, Plzeň, Unnamed Road',
                street_address: 'Unnamed Road ',
                postalCode: '318 00',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Česko',
                countryCode: 'CZ',
                town: 'Rozvadov',
                route: '605',
                address: '(CZ) 348 06, Rozvadov, 605',
                street_address: 'Silnice II/605 117',
                postalCode: '348 06',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Czechia',
                countryCode: 'CZ',
                town: 'Rozvadov',
                route: '605',
                address: '(CZ) 348 06, Rozvadov, 605',
                street_address: 'Silnice II/605 117',
                postalCode: '348 06',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Česko',
                countryCode: 'CZ',
                town: 'Rozvadov',
                route: '605',
                address: '(CZ) 348 06, Rozvadov, 605',
                street_address: 'Silnice II/605 117',
                postalCode: '348 06',
                source: 'google'
            }
        ]
    ],
    // Transport place 3
    [
        [
            {
                lang: 'cs',
                country: 'Polsko',
                countryCode: 'PL',
                town: 'Gdańsk',
                route: 'Rufowa',
                address: '(PL) 80-690, Gdańsk, Rufowa',
                street_address: 'Rufowa 6',
                postalCode: '80-690',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Poland',
                countryCode: 'PL',
                town: 'Gdańsk',
                route: 'Rufowa',
                address: '(PL) 80-690, Gdańsk, Rufowa',
                street_address: 'Rufowa 6',
                postalCode: '80-690',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Poľsko',
                countryCode: 'PL',
                town: 'Gdańsk',
                route: 'Rufowa',
                address: '(PL) 80-690, Gdańsk, Rufowa',
                street_address: 'Rufowa 6',
                postalCode: '80-690',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Polsko',
                countryCode: 'PL',
                town: 'Pruszcz Gdański',
                route: 'S6',
                address: '(PL) 83-000, Pruszcz Gdański, S6',
                street_address: 'Obwodnica Trójmiasta 117',
                postalCode: '83-000',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Poland',
                countryCode: 'PL',
                town: 'Pruszcz Gdański',
                route: 'S6',
                address: '(PL) 83-000, Pruszcz Gdański, S6',
                street_address: 'Obwodnica Trójmiasta 117',
                postalCode: '83-000',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Poľsko',
                countryCode: 'PL',
                town: 'Pruszcz Gdański',
                route: 'S6',
                address: '(PL) 83-000, Pruszcz Gdański, S6',
                street_address: 'Obwodnica Trójmiasta 117',
                postalCode: '83-000',
                source: 'google'
            }
        ],
        [
            {
                lang: 'cs',
                country: 'Polsko',
                countryCode: 'PL',
                town: 'Zduny',
                route: 'A1',
                address: '(PL) 83-115, Zduny, A1',
                street_address: 'Autostrada Bursztynowa ',
                postalCode: '83-115',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Poland',
                countryCode: 'PL',
                town: 'Zduny',
                route: 'A1',
                address: '(PL) 83-115, Zduny, A1',
                street_address: 'Autostrada Bursztynowa ',
                postalCode: '83-115',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Poľsko',
                countryCode: 'PL',
                town: 'Zduny',
                route: 'A1',
                address: '(PL) 83-115, Zduny, A1',
                street_address: 'Autostrada Bursztynowa ',
                postalCode: '83-115',
                source: 'google'
            }
        ]
    ],
    [
        [
            {
                lang: 'cs',
                country: 'Maďarsko',
                countryCode: 'HU',
                town: 'Budapest',
                route: 'Bojtár u.',
                address: '(HU) 1033, Budapest, Bojtár u.',
                street_address: 'Bojtár utca 51',
                postalCode: '1033',
                source: 'google'
            },
            {
                lang: 'en',
                country: 'Hungary',
                countryCode: 'HU',
                town: 'Budapest',
                route: 'Bojtár u.',
                address: '(HU) 1033, Budapest, Bojtár u.',
                street_address: 'Bojtár utca 51',
                postalCode: '1033',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Maďarsko',
                countryCode: 'HU',
                town: 'Budapest',
                route: 'Bojtár u.',
                address: '(HU) 1033, Budapest, Bojtár u.',
                street_address: 'Bojtár utca 51',
                postalCode: '1033',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Hungary',
                countryCode: 'HU',
                town: 'Károlyháza',
                route: 'M1',
                address: '(HU) 9182, Károlyháza, M1',
                street_address: 'M1 ',
                postalCode: '9182',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Maďarsko',
                countryCode: 'HU',
                town: 'Károlyháza',
                route: 'M1',
                address: '(HU) 9182, Károlyháza, M1',
                street_address: 'M1 ',
                postalCode: '9182',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Austria',
                countryCode: 'AT',
                town: 'Wien',
                route: 'Ailecgasse',
                address: '(AT) 1110, Wien, Ailecgasse',
                street_address: 'Ailecgasse 82',
                postalCode: '1110',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Rakúsko',
                countryCode: 'AT',
                town: 'Viedeň',
                route: 'Ailecgasse',
                address: '(AT) 1110, Viedeň, Ailecgasse',
                street_address: 'Ailecgasse 82',
                postalCode: '1110',
                source: 'google'
            }
        ]
    ],
    [
        [
            {
                lang: 'en',
                country: 'Belgium',
                countryCode: 'BE',
                town: 'Zaventem',
                route: 'Hoge Wei',
                address: '(BE) 1930, Zaventem, Hoge Wei',
                street_address: 'Hoge Wei 37',
                postalCode: '1930',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Belgicko',
                countryCode: 'BE',
                town: 'Zaventem',
                route: 'Hoge Wei',
                address: '(BE) 1930, Zaventem, Hoge Wei',
                street_address: 'Hoge Wei 37',
                postalCode: '1930',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Belgium',
                countryCode: 'BE',
                town: 'Maasmechelen',
                route: 'A2',
                address: '(BE) 3630, Maasmechelen, A2',
                street_address: 'A2 ',
                postalCode: '3630',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Belgicko',
                countryCode: 'BE',
                town: 'Maasmechelen',
                route: 'A2',
                address: '(BE) 3630, Maasmechelen, A2',
                street_address: 'A2 ',
                postalCode: '3630',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Germany',
                countryCode: 'DE',
                town: 'Aachen',
                route: 'A4',
                address: '(DE) 52080, Aachen, A4',
                street_address: 'A4 44',
                postalCode: '52080',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Nemecko',
                countryCode: 'DE',
                town: 'Aachen',
                route: 'E314',
                address: '(DE) 52080, Aachen, E314',
                street_address: 'E314 44',
                postalCode: '52080',
                source: 'google'
            }
        ]
    ],
    [
        [
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: 'Venice',
                route: 'Via Alessandro Volta',
                address: '(IT) 30175, Venice, Via Alessandro Volta',
                street_address: 'Via Alessandro Volta 9',
                postalCode: '30175',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: 'Venezia',
                route: 'Via Alessandro Volta',
                address: '(IT) 30175, Venezia, Via Alessandro Volta',
                street_address: 'Via Alessandro Volta 9',
                postalCode: '30175',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: 'Cessalto',
                route: 'Via Tomba',
                address: '(IT) 31040, Cessalto, Via Tomba',
                street_address: 'Via Tomba 36',
                postalCode: '31040',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: 'Cessalto',
                route: 'E70',
                address: '(IT) 31040, Cessalto, E70',
                street_address: 'A4 Torino - Trieste 36',
                postalCode: '31040',
                source: 'google'
            }
        ],
        [
            {
                lang: 'en',
                country: 'Italy',
                countryCode: 'IT',
                town: 'Modeano',
                route: 'E70',
                address: '(IT) 33056, Modeano, E70',
                street_address: 'A4 Torino - Trieste 1',
                postalCode: '33056',
                source: 'google'
            },
            {
                lang: 'sk',
                country: 'Taliansko',
                countryCode: 'IT',
                town: 'Modeano',
                route: 'E70',
                address: '(IT) 33056, Modeano, E70',
                street_address: 'A4 Torino - Trieste 1',
                postalCode: '33056',
                source: 'google'
            }
        ]
    ]
];

const puescStatusData = [
    {
        monitored_object_id: '7',
        puesc_sent_geo_stale: false,
        puesc_sent_geo_error: false,
        unavailable_gps: false,
        monitoring_device_stale: false,
        status: PuescStatus.NoTransport
    }
];

export const puescStatus = puescStatusData.map(e => PuescStatusResponseFromJSON(e));
