import { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import PartnerSideBar from './components/PartnerSideBar';
import { Logic } from 'logic/logic';
import { RouteNames } from 'App';
import PartnersModule from 'modules/partner/modules/partners/PartnersModule';
import CompaniesModule from 'modules/partner/modules/companies/CompaniesModule';
import ObuModule from 'modules/partner/modules/obu/ObuModule';
import CcrModule from 'modules/partner/modules/ccr/CcrModule';
import VehiclesModule from 'modules/partner/modules/vehicles/VehiclesModule';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import UsersModule from 'modules/partner/modules/users/UsersModule';
import WhitelabelModule from './modules/whitelabel/WhitelabelModule';

interface Props extends RouteComponentProps {
    logic: Logic;
}

interface State {
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
}

class PartnerModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = { companies: [] };
    }

    componentDidMount() {
        this._logic
            .partner()
            .companies()
            .getClientList()
            .then(resp => {
                const cl = resp.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner }));
                this.setState(_state => ({
                    companies: cl
                }));
            });
    }

    render() {
        return (
            <div className="partner module">
                <PartnerSideBar />
                <Switch>
                    <Route
                        exact
                        path={RouteNames.PARTNER_PARTNERS}
                        render={() => (
                            <PartnersModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_COMPANIES}
                        render={() => (
                            <CompaniesModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_WHITELABEL}
                        render={() => (
                            <WhitelabelModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_VEHICLES}
                        render={() => (
                            <VehiclesModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_OBU}
                        render={() => (
                            <ObuModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_CCR}
                        render={() => (
                            <CcrModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={RouteNames.PARTNER_USERS}
                        render={() => (
                            <UsersModule
                                logic={this.props.logic}
                                companies={this.state.companies}
                                selectedCompany={this.state.selectedCompany}
                                onCompanyChange={this._onCompanyChange}
                                onCompanyFilterSearch={this._onCompanyFilterSearch}
                            />
                        )}
                    />
                    <Redirect to={RouteNames.PARTNER_PARTNERS} />
                </Switch>
            </div>
        );
    }

    private _onCompanyChange = (company?: PartnerCompanySelectModel) => {
        this.setState(state => ({
            ...state,
            selectedCompany: company
        }));
    };
    private _onCompanyFilterSearch = async (text: string): Promise<PartnerCompanySelectModel[]> => {
        const clients = await this._logic.partner().companies().getClientList(undefined, 100, 0, text);

        const cl = clients.data.map(d => ({ id: d.id, label: d.name, isPartner: d.isPartner })) ?? [];

        this.setState(_state => ({
            companies: cl
        }));
        return cl;
    };
}

export default withRouter(PartnerModule);
