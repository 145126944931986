import { withTranslation } from 'react-i18next';
import ActivityMoreDetails from '../ActivityMoreDetails';
import ActivityTaskBase, { ActivityBaseProps } from '../ActivityTaskBase';

interface Props extends ActivityBaseProps {}

const ActivityTaskOnetime = (props: Props) => {
    return (
        <ActivityTaskBase {...props} className={`activity-task-onetime ${props.className}`}>
            <ActivityMoreDetails task={props.task} placeDisabled={props.placeDisabled} />
        </ActivityTaskBase>
    );
};

export default withTranslation()(ActivityTaskOnetime);
