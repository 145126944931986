import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { UserLinks, UserModel } from '../UsersModule';
import { ReadOnlyUser, UserToken } from 'generated/new-main/models';
import LinkedItem, { LinkedItemType } from 'common/components/LinkedItem';
import { UserNestedModelModel } from 'common/model/user';
import qa from 'qa-selectors';

interface DetailMenu {
    detail?: boolean;
    actions?: boolean;
}
interface Props extends WithTranslation {
    model?: UserNestedModelModel;
    userTokens?: UserToken[];
    demoMode?: boolean;
    onUnlinkAssetButtonClick?: (model: ReadOnlyUser, type: keyof UserLinks, unpairId?: string) => void;
}

interface State {
    menu?: DetailMenu;
}

class UsersLinkedItems extends Component<Props, State> {
    userModel?: UserModel;

    constructor(props: Props) {
        super(props);
        this.state = {
            menu: { detail: true, actions: true }
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.model !== this.props.model) {
            this.userModel = undefined;
        }
    }

    render() {
        const { t, model } = this.props;
        const userTokens = this.props.userTokens;
        const tachoToken = userTokens?.find(ut => ut.tokenType === 'driver_card');
        const appToken = userTokens?.find(ut => ut.tokenType === 'app');
        const links: UserLinks = {
            tachoCard: tachoToken
                ? {
                      expirationDate: tachoToken.endTime ? tachoToken.endTime.toLocaleString() : '',
                      holder: tachoToken.holder ?? '',
                      id: tachoToken.id ? tachoToken.id.toString() : '',
                      relation: '-',
                      token: tachoToken.token,
                      type: tachoToken.tokenType
                  }
                : undefined,
            app: appToken
                ? {
                      holder: appToken.holder ?? '',
                      id: appToken.id ? appToken.id.toString() : '',
                      relation: '-',
                      token: appToken.token,
                      type: appToken.tokenType
                  }
                : undefined
        };

        return (
            <div className="management-users-pairing-list">
                {model && links.app && (
                    <LinkedItem
                        parentId={String(model.id)}
                        typeName={t('common.app')}
                        items={undefined}
                        disabled={this.props.demoMode}
                        qa={qa.users.linkedItems.sectionApp}
                        onUnpairClick={this._onUnpairButtonClick.bind(undefined, model, 'app')}
                    />
                )}

                {model && links.tachoCard && (
                    <LinkedItem
                        parentId={String(model.id)}
                        type={LinkedItemType.TACHOGRAPH_CARD}
                        typeName={t('common.tachoCard')}
                        items={[
                            {
                                label: t('common.token'),
                                value: links.tachoCard.token,
                                qa: qa.users.linkedItems.fieldToken
                            }
                        ]}
                        disabled={this.props.demoMode}
                        qa={qa.users.linkedItems.sectionTachoCard}
                        onUnpairClick={this._onUnpairButtonClick.bind(undefined, model, 'tachoCard')}
                    />
                )}

                {model?.defaultDriver?.map((dd, index) => {
                    return (
                        <LinkedItem
                            key={`linked-item-${index}`}
                            parentId={String(model.id)}
                            typeName={t('common.defaultVehicle')}
                            type={LinkedItemType.DEFAULT_VEHICLE}
                            items={[
                                {
                                    label: t('common.rn'),
                                    value: dd.registrationNumber,
                                    qa: qa.users.linkedItems.fieldRn
                                }
                            ]}
                            disabled={this.props.demoMode}
                            qa={qa.users.linkedItems.sectionDefaultVehicle}
                            onUnpairClick={this._onUnpairButtonClick.bind(
                                undefined,
                                model,
                                'monitoredObject',
                                dd.monitoredObjectId
                            )}
                        />
                    );
                })}
            </div>
        );
    }

    private _onUnpairButtonClick = (model: ReadOnlyUser, type: keyof UserLinks, unpairId?: string): void => {
        this.props.onUnlinkAssetButtonClick?.(model, type, unpairId);
    };
}

export default withTranslation()(UsersLinkedItems);
