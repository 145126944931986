import { Logic } from 'logic/logic';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { PartnerCompanyModel, PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import Whitelabel from './components/Whitelabel';
import { WhitelabelModel, WhitelabelTemplateModel } from 'logic/partner/logic/partner-whitelabel';
import { PaginatedResponse } from 'common/model/pagination';
import { message } from 'antd';

interface Props extends RouteComponentProps, WithTranslation {
    logic: Logic;
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyChange?: (company?: PartnerCompanySelectModel) => void;
    onCompanyFilterSearch?: (text: string) => Promise<PartnerCompanySelectModel[]>;
}

interface State {
    removeLoading: boolean;
    bar: {
        search?: { text: string };
        companies?: PartnerCompanySelectModel[];
        selectedCompany?: PartnerCompanySelectModel;
    };
    table: {
        loading: boolean;
        templates?: PaginatedResponse<WhitelabelTemplateModel[]>;
        selectedTemplate?: WhitelabelTemplateModel;
        active?: WhitelabelModel;
        logo?: string;
        delete?: WhitelabelTemplateModel;
    };
    client?: PartnerCompanyModel;
}

class WhitelabelModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = props.logic;
        this.state = {
            removeLoading: false,
            bar: {
                search: { text: '' },
                selectedCompany: this.props.selectedCompany,
                companies: this.props.companies
            },
            table: {
                loading: true
            }
        };
    }

    componentDidMount() {
        const clientId = this._logic.auth().client()?.id;
        Promise.all([
            this._logic.partner().whitelabel().getWhitelabelTemplateList(),
            this._logic.partner().whitelabel().getWhitelabelList(),
            this._logic.partner().companies()?.getCompany(Number(clientId))
        ])
            .then(res => {
                const [templates, whitelabel, client] = res;
                this.setState({
                    client,
                    table: {
                        loading: false,
                        templates,
                        active: whitelabel.data[0] ?? undefined,
                        logo: client.logo === '' ? undefined : client.logo
                    }
                });
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    }

    render() {
        return (
            <Whitelabel
                templates={this.state.table.templates}
                loading={this.state.table.loading}
                selectedTemplate={this.state.table.selectedTemplate}
                delete={this.state.table.delete}
                active={this.state.table?.active}
                companies={this.state.bar?.companies}
                selectedCompany={this.state.bar?.selectedCompany}
                logo={this.state.table.logo}
                removeLoading={this.state.removeLoading}
                onBarCompanyFilterChange={this._onBarCompanyFilterChange}
                onBarCompanyFilterCancel={this._onBarCompanyFilterCancel}
                onBarCompanyFilterSearch={this._onBarCompanyFilterSearch}
                onTemplateSubmit={this._onTemplateSubmit}
                onTemplateClose={this._onTemplateClose}
                onTemplatesRowAction={this._onTemplatesRowAction}
                onTemplatesRowDelete={this._onTemplatesRowDelete}
                onTemplatesRowDeactivate={this._onTemplatesRowDeactivate}
                onTemplatesRowActivate={this._onTemplatesRowActivate}
                onLogoSubmit={this._onLogoSubmit}
                onDeleteConfirm={this._onDeleteConfirm}
                onDeleteCancel={this._onDeleteCancel}
            />
        );
    }

    private _onTemplatesRowActivate = (templateId: string): void => {
        const clientId = this._logic.auth().client()?.id;
        if (clientId) {
            this.setState(state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }));
            if (this.state.table.active) {
                this._logic
                    .partner()
                    .whitelabel()
                    .updateWhitelabel(this.state.table.active.id, templateId, clientId)
                    .then(_res => {
                        this._getWhitelabelTemplates(true);
                    })
                    .catch(_err => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            } else {
                this._logic
                    .partner()
                    .whitelabel()
                    .createWhitelabel(templateId, clientId)
                    .then(_res => {
                        this._getWhitelabelTemplates(true);
                    })
                    .catch(_err => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            }
        }
    };

    private _onTemplatesRowDeactivate = (id: string): void => {
        this.setState(
            state => ({
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this._logic
                    .partner()
                    .whitelabel()
                    .deleteWhitelabel(id)
                    .then(() => {
                        this._getWhitelabelTemplates(true);
                    })
                    .finally(() => {
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loading: false
                            }
                        }));
                    });
            }
        );
    };

    private _getWhitelabelTemplates = (setWhitelabel?: boolean): void => {
        Promise.all([
            this._logic.partner().whitelabel().getWhitelabelTemplateList(),
            this._logic.partner().whitelabel().getWhitelabelList()
        ])
            .then(res => {
                const [templates, whitelabel] = res;
                this.setState({
                    table: {
                        loading: false,
                        templates,
                        active: whitelabel.data[0] ?? undefined
                    }
                });

                if (setWhitelabel) {
                    if (whitelabel.data[0]) {
                        const activeTemplate = templates.data.filter(t => t.id === whitelabel.data[0].template)[0];
                        Object.keys(activeTemplate.content).forEach(t => {
                            document.body.style.setProperty(`--${t}`, activeTemplate.content[t] ?? '');
                        });
                    } else if (templates.data[0]) {
                        const defaultTemplate = templates.data[0];
                        Object.keys(defaultTemplate.content).forEach(t => {
                            document.body.style.setProperty(`--${t}`, defaultTemplate.content[t] ?? '');
                        });
                    }
                }
            })
            .catch(err => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
    };

    private _onTemplatesRowDelete = (id: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                delete: this.state.table.templates?.data.find(t => t.id === id)
            }
        }));
    };

    private _onDeleteConfirm = async (): Promise<void> => {
        this.setState({
            removeLoading: true
        });
        if (this.state.table.delete) {
            try {
                const res = await this.props.logic
                    .partner()
                    .whitelabel()
                    .deleteWhitelabelTemplate(this.state.table.delete);
                if (res) {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            selectedTemplate: undefined
                        }
                    }));
                    this._getWhitelabelTemplates();
                }
            } catch (err) {
                console.error(`Remove white label failed, err: ${err}`);
            }
        }
        this.setState({
            removeLoading: false
        });
    };

    private _onDeleteCancel = (): void => {
        this.setState(state => ({
            table: { ...state.table, delete: undefined }
        }));
    };

    private _onTemplatesRowAction = (id: string): void => {
        this.setState(state => ({
            table: {
                ...state.table,
                selectedTemplate: state.table.templates?.data.find(t => t.id === id)
            }
        }));
    };

    private _onLogoSubmit = (logo?: string) => {
        if (this.state.client) {
            this._logic
                .partner()
                .companies()
                .updateCompanyLogo({
                    ...this.state.client,
                    logo: logo ?? ''
                })
                .then(_res => {
                    this.setState(state => ({
                        table: {
                            ...state.table,
                            logo
                        }
                    }));
                });

            this._setLogo(logo);
        }
    };

    private _onTemplateSubmit = (model: WhitelabelTemplateModel) => {
        this.setState(state => ({
            table: {
                ...state.table,
                loading: true
            }
        }));

        if (model.client) {
            this.props.logic
                .partner()
                .whitelabel()
                .editWhitelabelTemplate(model)
                .then(_res => {
                    this._getWhitelabelTemplates(this.state.table.active?.template === model.id);
                });
        } else {
            this.props.logic
                .partner()
                .whitelabel()
                .addWhitelabelTemplate(model)
                .then(_res => {
                    this._getWhitelabelTemplates();
                });
        }

        this.setState(state => ({
            table: {
                ...state.table,
                selectedTemplate: undefined
            }
        }));
    };

    private _onTemplateClose = () => {
        this.setState(state => ({
            table: {
                ...state.table,
                selectedTemplate: undefined
            }
        }));
    };

    private _onBarCompanyFilterSearch = async (text: string) => {
        const cl = await this.props.onCompanyFilterSearch?.(text);
        this.setState(state => ({
            bar: {
                ...state.bar,
                companies: cl
            }
        }));
    };

    private _onBarCompanyFilterCancel = (): void => {
        this.setState(
            state => ({
                ...state,
                bar: {
                    ...state.bar,
                    selectedCompany: undefined
                },
                table: {
                    ...state.table,
                    data: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
            }
        );
    };

    private _onBarCompanyFilterChange = (id: string): void => {
        this.setState(
            state => ({
                ...state,
                bar: {
                    ...state.bar,
                    selectedCompany: state.bar.companies?.find(c => c.id === id)
                },
                table: {
                    ...state.table,
                    data: undefined
                }
            }),
            () => {
                this.props.onCompanyChange?.(this.state.bar.selectedCompany);
            }
        );
    };

    private _setLogo = (logo?: string) => {
        if (document.querySelector('.navbar-logo div.logo') !== null) {
            const navbarLogo = document.querySelector('.navbar-logo div.logo')! as HTMLDivElement;
            navbarLogo.style.backgroundImage = logo ? `url("${logo}")` : '';
        }
    };
}

export default withTranslation()(withRouter(WhitelabelModule));
