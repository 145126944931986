/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FuelStatistics,
    FuelStatisticsFromJSON,
    FuelStatisticsFromJSONTyped,
    FuelStatisticsToJSON,
} from './';

/**
 * 
 * @export
 * @interface FuelStatisticsEntry
 */
export interface FuelStatisticsEntry {
    /**
     * 
     * @type {string}
     * @memberof FuelStatisticsEntry
     */
    monitoredObjectId?: string;
    /**
     * 
     * @type {number}
     * @memberof FuelStatisticsEntry
     */
    driverId?: number;
    /**
     * 
     * @type {FuelStatistics}
     * @memberof FuelStatisticsEntry
     */
    stats: FuelStatistics;
    /**
     * 
     * @type {string}
     * @memberof FuelStatisticsEntry
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof FuelStatisticsEntry
     */
    dateTo: string;
}

export function FuelStatisticsEntryFromJSON(json: any): FuelStatisticsEntry {
    return FuelStatisticsEntryFromJSONTyped(json, false);
}

export function FuelStatisticsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelStatisticsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': !exists(json, 'monitored_object_id') ? undefined : json['monitored_object_id'],
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'stats': FuelStatisticsFromJSON(json['stats']),
        'dateFrom': json['date_from'],
        'dateTo': json['date_to'],
    };
}

export function FuelStatisticsEntryToJSON(value?: FuelStatisticsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'driver_id': value.driverId,
        'stats': FuelStatisticsToJSON(value.stats),
        'date_from': value.dateFrom,
        'date_to': value.dateTo,
    };
}


