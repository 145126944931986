/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse200TollByCountry
 */
export interface InlineResponse200TollByCountry {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200TollByCountry
     */
    amountInTargetCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200TollByCountry
     */
    country: string;
}

export function InlineResponse200TollByCountryFromJSON(json: any): InlineResponse200TollByCountry {
    return InlineResponse200TollByCountryFromJSONTyped(json, false);
}

export function InlineResponse200TollByCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200TollByCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountInTargetCurrency': json['amountInTargetCurrency'],
        'country': json['country'],
    };
}

export function InlineResponse200TollByCountryToJSON(value?: InlineResponse200TollByCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amountInTargetCurrency': value.amountInTargetCurrency,
        'country': value.country,
    };
}


