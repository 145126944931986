/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Costs, CostsFromJSON, CostsFromJSONTyped, CostsToJSON } from './';

/**
 *
 * @export
 * @interface LegDetails
 */
export interface LegDetails {
    /**
     *
     * @type {string}
     * @memberof LegDetails
     */
    result: string;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    geoDistance: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    travelTime: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    timeElapsed: number;
    /**
     *
     * @type {Costs}
     * @memberof LegDetails
     */
    costs?: Costs;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    distance: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    durationDriving: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    durationNonDriving: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    durationTotal: number;
    /**
     *
     * @type {number}
     * @memberof LegDetails
     */
    fsimTimeElapsed: number;
}

export function LegDetailsFromJSON(json: any): LegDetails {
    return LegDetailsFromJSONTyped(json, false);
}

export function LegDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegDetails {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        result: json['result'],
        geoDistance: json['geo_distance'],
        travelTime: json['travel_time'],
        timeElapsed: json['time_elapsed'],
        costs: !exists(json, 'costs') ? undefined : CostsFromJSON(json['costs']),
        distance: json['distance'],
        durationDriving: json['duration_driving'],
        durationNonDriving: json['duration_non_driving'],
        durationTotal: json['duration_total'],
        fsimTimeElapsed: json['fsim_time_elapsed']
    };
}

export function LegDetailsToJSON(value?: LegDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        result: value.result,
        geo_distance: value.geoDistance,
        travel_time: value.travelTime,
        time_elapsed: value.timeElapsed,
        costs: CostsToJSON(value.costs),
        distance: value.distance,
        duration_driving: value.durationDriving,
        duration_non_driving: value.durationNonDriving,
        duration_total: value.durationTotal,
        fsim_time_elapsed: value.fsimTimeElapsed
    };
}
