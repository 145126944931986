import { Field, FieldProps, getIn } from 'formik';
import { ReactNode } from 'react';
import moment, { Moment } from 'moment';
import { Control } from 'common/components/Form/Control';

import { DatePicker } from '../../components';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { DateTimePickerProps } from 'common/components/DatePicker/components/DateTimePicker/DateTimePicker';

interface Props extends DateTimePickerProps, PickerDateProps<Moment> {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    lang?: string;
    qa?: string;
    onChange?: () => void;
}

export default function DateTimePickerField({ name, label, onChange, ...props }: Props) {
    return (
        <Field name={name}>
            {({ field: { value, name, ...fieldProps }, form }: FieldProps<string>) => {
                function handleChange(_: Moment | null, stringDate: string) {
                    form.setFieldValue(name, stringDate);
                    onChange?.();
                }

                return (
                    <Control label={label} error={getIn(form.touched, name) && getIn(form.errors, name)}>
                        <DatePicker.DateTimePicker
                            defaultValue={value ? moment(value) : undefined}
                            style={{ width: '100%' }}
                            {...fieldProps}
                            {...props}
                            onChange={handleChange}
                        />
                    </Control>
                );
            }}
        </Field>
    );
}
