import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, DatePicker } from 'common/components';
import { DateRange } from 'common/model/date-time';
import { DATE_FORMAT, DWL_LOCK } from 'domain-constants';
import moment, { Moment } from 'moment';
import TableBar from 'common/components/TableBar';
import qa from 'qa-selectors';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import { FuelConsumptionFilterModel } from '../../FuelConsumptionModule';
import * as icons from 'resources/images/common';
import FuelConsumptionFilter from '../FuelConsumptionFilter';

interface FuelConsumptionBarProps {
    demoMode?: boolean;
    summary: boolean;
    filter: FuelConsumptionFilterModel;
    filterOpen: boolean;
    settings: boolean;
    dateOpen: boolean;
    dateChanged?: boolean;
    dateRange: DateRange;
    backUrl?: boolean;
    exportEnable: boolean;
    exportProcessing: boolean;
    onExport?: () => void;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onSummaryClick?: () => void;
    onFilterClick?: () => void;
    onSettingsClick?: () => void;
    onHelperClick?: () => void;
    onBackUrlClick?: () => void;
    onBarResetClick?: () => void;
    onFilterConfirm?: (
        driversChecked: string[],
        vehiclesChecked: string[],
        shortActivity: boolean,
        fuelSusupiciousActivity: boolean
    ) => void;
    onFilterCancel?: () => void;
}

type Props = FuelConsumptionBarProps & WithTranslation;

function FuelConsumptionBar(props: Props) {
    function onFilterClick() {
        props.onFilterClick?.();
    }

    function _onDateRangeChange(values: [Moment | null, Moment | null] | null): void {
        if (values) {
            const [start, end] = values;
            props.onDateRangeChange?.({
                start: start ? start.format(DATE_FORMAT) : props.dateRange.start,
                end: end ? end.format(DATE_FORMAT) : props.dateRange.end
            });
        }
    }

    function onBackUrlClick() {
        props.onBackUrlClick?.();
    }

    return (
        <>
            <TableBar
                heading={props.t('FuelConsumptionBar.fuelConsumption')}
                className="fuel-consumption-bar"
                datePicker={
                    <DatePicker.DateTimeRangePicker
                        defaultValue={[
                            moment(props.dateRange.start, DATE_FORMAT),
                            moment(props.dateRange.end, DATE_FORMAT)
                        ]}
                        minDate={
                            props.filter.fullDWL
                                ? moment()
                                      .subtract(DWL_LOCK - 1, 'days')
                                      .startOf('day')
                                      .toDate()
                                : undefined
                        }
                        onChange={_onDateRangeChange}
                        trigger={
                            <ButtonDateRangeTimeTrigger
                                dateRange={props.dateRange}
                                changed={props.dateChanged}
                                qa={qa.common.dateTimeRangePicker.btn}
                            />
                        }
                    />
                }
                filter={{
                    activeCount:
                        (props.filter.vehiclesChecked.length === 0 ||
                        props.filter.vehiclesChecked.length === props.filter.vehiclesOpts.length
                            ? 0
                            : 1) +
                        (props.filter.driversChecked.length === 0 ||
                        props.filter.driversChecked.length === props.filter.driversOpts.length
                            ? 0
                            : 1) +
                        (props.filter.shortActivity === false || props.filter.fuelSuspiciousActivity === false ? 1 : 0),
                    onClick: onFilterClick,
                    qa: qa.fuel.bar.btnFilter,
                    resetButton: {
                        onClick: props.onBarResetClick
                    }
                }}
                backButton={
                    props.backUrl
                        ? {
                              onClick: onBackUrlClick,
                              title: props.t('ExpensesBar.backToJourneys')
                          }
                        : undefined
                }
                actions={[
                    <Button
                        loading={props.exportProcessing}
                        disabled={!props.exportEnable}
                        type="primary"
                        className="button-export"
                        onClick={props.onExport}
                        qa={qa.fuel.bar.btnExport}
                        icon={<img src={icons.export} alt={props.t('common.export')} />}
                    >
                        {props.t('common.export')}
                    </Button>
                ]}
                onHelperClick={props.onHelperClick}
            />
            {props.filterOpen && (
                <FuelConsumptionFilter
                    demoMode={props.demoMode}
                    driversOpts={props.filter.driversOpts}
                    vehiclesOpts={props.filter.vehiclesOpts}
                    driversChecked={props.filter.driversChecked}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    fuelSuspiciousActivity={props.filter.fuelSuspiciousActivity}
                    shortActivity={props.filter.shortActivity}
                    onConfirm={props.onFilterConfirm}
                    onCancel={props.onFilterCancel}
                />
            )}
        </>
    );
}

export default withTranslation()(FuelConsumptionBar);
