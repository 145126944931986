/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AETRDailyData,
    AETRDailyDataFromJSON,
    AETRDailyDataFromJSONTyped,
    AETRDailyDataToJSON,
    AETRInterval,
    AETRIntervalFromJSON,
    AETRIntervalFromJSONTyped,
    AETRIntervalToJSON,
} from './';

/**
 * 
 * @export
 * @interface AETRWeeklyData
 */
export interface AETRWeeklyData {
    /**
     * 
     * @type {Date}
     * @memberof AETRWeeklyData
     */
    weekStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof AETRWeeklyData
     */
    utilizationStart?: Date;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    extendedShifts: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    reducedDailyRests: number;
    /**
     * 
     * @type {Array<AETRDailyData>}
     * @memberof AETRWeeklyData
     */
    dailyData?: Array<AETRDailyData>;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    daysDriven: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    drivingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    drivingDuration14Days?: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    driveMaxSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    weeklyRestDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    nextWeeklyRestDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof AETRWeeklyData
     */
    nextWeeklyRestDurationDebt?: number;
    /**
     * 
     * @type {AETRInterval}
     * @memberof AETRWeeklyData
     */
    weeklyRestPeriod?: AETRInterval;
    /**
     * 
     * @type {boolean}
     * @memberof AETRWeeklyData
     */
    reliable?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AETRWeeklyData
     */
    aetrIntervals?: { [key: string]: object; };
}

export function AETRWeeklyDataFromJSON(json: any): AETRWeeklyData {
    return AETRWeeklyDataFromJSONTyped(json, false);
}

export function AETRWeeklyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AETRWeeklyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weekStart': (new Date(json['week_start'])),
        'utilizationStart': !exists(json, 'utilization_start') ? undefined : (new Date(json['utilization_start'])),
        'extendedShifts': json['extended_shifts'],
        'reducedDailyRests': json['reduced_daily_rests'],
        'dailyData': !exists(json, 'daily_data') ? undefined : ((json['daily_data'] as Array<any>).map(AETRDailyDataFromJSON)),
        'daysDriven': json['days_driven'],
        'drivingDuration': json['driving_duration'],
        'drivingDuration14Days': !exists(json, 'driving_duration_14_days') ? undefined : json['driving_duration_14_days'],
        'driveMaxSeconds': !exists(json, 'drive_max_seconds') ? undefined : json['drive_max_seconds'],
        'weeklyRestDuration': !exists(json, 'weekly_rest_duration') ? undefined : json['weekly_rest_duration'],
        'nextWeeklyRestDuration': !exists(json, 'next_weekly_rest_duration') ? undefined : json['next_weekly_rest_duration'],
        'nextWeeklyRestDurationDebt': !exists(json, 'next_weekly_rest_duration_debt') ? undefined : json['next_weekly_rest_duration_debt'],
        'weeklyRestPeriod': !exists(json, 'weekly_rest_period') ? undefined : AETRIntervalFromJSON(json['weekly_rest_period']),
        'reliable': !exists(json, 'reliable') ? undefined : json['reliable'],
        'aetrIntervals': !exists(json, 'aetr_intervals') ? undefined : json['aetr_intervals'],
    };
}

export function AETRWeeklyDataToJSON(value?: AETRWeeklyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week_start': (value.weekStart.toISOString().substr(0,10)),
        'utilization_start': value.utilizationStart === undefined ? undefined : (value.utilizationStart.toISOString()),
        'extended_shifts': value.extendedShifts,
        'reduced_daily_rests': value.reducedDailyRests,
        'daily_data': value.dailyData === undefined ? undefined : ((value.dailyData as Array<any>).map(AETRDailyDataToJSON)),
        'days_driven': value.daysDriven,
        'driving_duration': value.drivingDuration,
        'driving_duration_14_days': value.drivingDuration14Days,
        'drive_max_seconds': value.driveMaxSeconds,
        'weekly_rest_duration': value.weeklyRestDuration,
        'next_weekly_rest_duration': value.nextWeeklyRestDuration,
        'next_weekly_rest_duration_debt': value.nextWeeklyRestDurationDebt,
        'weekly_rest_period': AETRIntervalToJSON(value.weeklyRestPeriod),
        'reliable': value.reliable,
        'aetr_intervals': value.aetrIntervals,
    };
}


