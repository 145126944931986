/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MobileApp,
    MobileAppFromJSON,
    MobileAppToJSON,
    MobileDevice,
    MobileDeviceFromJSON,
    MobileDeviceToJSON,
    MobileDeviceHasMobileApp,
    MobileDeviceHasMobileAppFromJSON,
    MobileDeviceHasMobileAppToJSON,
} from '../models';

export interface DeviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPostRequest {
    deviceId: string;
    packageName: string;
    appName: string;
    versionName: string;
    versionCode: number;
    jsonData: string;
}

export interface GetV1MobileAppGetRequest {
    packageName?: string;
}

export interface GetV1MobileAppLatestGetRequest {
    packageName?: string;
}

export interface GetV1MobileDeviceAppGetRequest {
    mobileDeviceId: string;
    packageName: string;
}

export interface GetV1MobileDeviceAppLatestGetRequest {
    mobileDeviceDeviceId: string;
    packageName?: string;
}

export interface GetV1MobileDeviceGetRequest {
    clientId?: number;
    deviceId?: string;
    limit?: number;
    offset?: number;
}

export interface UpgradeV1MobileDeviceAppUpgradePostRequest {
    mobileAppId: string;
    mobileDeviceIds?: Array<string>;
    mobileDeviceDeviceIds?: Array<string>;
    all?: boolean;
    clientId?: number;
}

export interface UploadV1MobileAppPostRequest {
    file: Blob;
}

/**
 * no description
 */
export class MobileApi extends runtime.BaseAPI {

    /**
     * Register actual state of device and installed apps - UPDATER APP
     */
    async deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPostRaw(requestParameters: DeviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPostRequest): Promise<runtime.ApiResponse<MobileApp>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        if (requestParameters.packageName === null || requestParameters.packageName === undefined) {
            throw new runtime.RequiredError('packageName','Required parameter requestParameters.packageName was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName','Required parameter requestParameters.appName was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        if (requestParameters.versionName === null || requestParameters.versionName === undefined) {
            throw new runtime.RequiredError('versionName','Required parameter requestParameters.versionName was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        if (requestParameters.versionCode === null || requestParameters.versionCode === undefined) {
            throw new runtime.RequiredError('versionCode','Required parameter requestParameters.versionCode was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        if (requestParameters.jsonData === null || requestParameters.jsonData === undefined) {
            throw new runtime.RequiredError('jsonData','Required parameter requestParameters.jsonData was null or undefined when calling deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        if (requestParameters.appName !== undefined) {
            queryParameters['app_name'] = requestParameters.appName;
        }

        if (requestParameters.versionName !== undefined) {
            queryParameters['version_name'] = requestParameters.versionName;
        }

        if (requestParameters.versionCode !== undefined) {
            queryParameters['version_code'] = requestParameters.versionCode;
        }

        if (requestParameters.jsonData !== undefined) {
            queryParameters['json_data'] = requestParameters.jsonData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/mobile/device/app/current_version`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAppFromJSON(jsonValue));
    }

    /**
     * Register actual state of device and installed apps - UPDATER APP
     */
    async deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPost(requestParameters: DeviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPostRequest): Promise<MobileApp> {
        const response = await this.deviceAppCurrentVersionV1MobileDeviceAppCurrentVersionPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * List available apks - SUPERADMIN UI
     */
    async getV1MobileAppGetRaw(requestParameters: GetV1MobileAppGetRequest): Promise<runtime.ApiResponse<Array<MobileApp>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/mobile/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileAppFromJSON));
    }

    /**
     * List available apks - SUPERADMIN UI
     */
    async getV1MobileAppGet(requestParameters: GetV1MobileAppGetRequest): Promise<Array<MobileApp>> {
        const response = await this.getV1MobileAppGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get latest apk of the package - UPDATER APP
     */
    async getV1MobileAppLatestGetRaw(requestParameters: GetV1MobileAppLatestGetRequest): Promise<runtime.ApiResponse<MobileApp>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/mobile/app/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAppFromJSON(jsonValue));
    }

    /**
     * Get latest apk of the package - UPDATER APP
     */
    async getV1MobileAppLatestGet(requestParameters: GetV1MobileAppLatestGetRequest): Promise<MobileApp> {
        const response = await this.getV1MobileAppLatestGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get history of app on device - SUPERADMIN UI
     */
    async getV1MobileDeviceAppGetRaw(requestParameters: GetV1MobileDeviceAppGetRequest): Promise<runtime.ApiResponse<Array<MobileDeviceHasMobileApp>>> {
        if (requestParameters.mobileDeviceId === null || requestParameters.mobileDeviceId === undefined) {
            throw new runtime.RequiredError('mobileDeviceId','Required parameter requestParameters.mobileDeviceId was null or undefined when calling getV1MobileDeviceAppGet.');
        }

        if (requestParameters.packageName === null || requestParameters.packageName === undefined) {
            throw new runtime.RequiredError('packageName','Required parameter requestParameters.packageName was null or undefined when calling getV1MobileDeviceAppGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mobileDeviceId !== undefined) {
            queryParameters['mobile_device_id'] = requestParameters.mobileDeviceId;
        }

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/mobile/device/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileDeviceHasMobileAppFromJSON));
    }

    /**
     * Get history of app on device - SUPERADMIN UI
     */
    async getV1MobileDeviceAppGet(requestParameters: GetV1MobileDeviceAppGetRequest): Promise<Array<MobileDeviceHasMobileApp>> {
        const response = await this.getV1MobileDeviceAppGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get latest app latest version of app for this device - UPDATER APP
     */
    async getV1MobileDeviceAppLatestGetRaw(requestParameters: GetV1MobileDeviceAppLatestGetRequest): Promise<runtime.ApiResponse<Array<MobileApp>>> {
        if (requestParameters.mobileDeviceDeviceId === null || requestParameters.mobileDeviceDeviceId === undefined) {
            throw new runtime.RequiredError('mobileDeviceDeviceId','Required parameter requestParameters.mobileDeviceDeviceId was null or undefined when calling getV1MobileDeviceAppLatestGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mobileDeviceDeviceId !== undefined) {
            queryParameters['mobile_device_device_id'] = requestParameters.mobileDeviceDeviceId;
        }

        if (requestParameters.packageName !== undefined) {
            queryParameters['package_name'] = requestParameters.packageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/mobile/device/app/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileAppFromJSON));
    }

    /**
     * Get latest app latest version of app for this device - UPDATER APP
     */
    async getV1MobileDeviceAppLatestGet(requestParameters: GetV1MobileDeviceAppLatestGetRequest): Promise<Array<MobileApp>> {
        const response = await this.getV1MobileDeviceAppLatestGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * List mobile devices - SUPERADMIN UI
     */
    async getV1MobileDeviceGetRaw(requestParameters: GetV1MobileDeviceGetRequest): Promise<runtime.ApiResponse<Array<MobileDevice>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/mobile/device`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileDeviceFromJSON));
    }

    /**
     * List mobile devices - SUPERADMIN UI
     */
    async getV1MobileDeviceGet(requestParameters: GetV1MobileDeviceGetRequest): Promise<Array<MobileDevice>> {
        const response = await this.getV1MobileDeviceGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set the desired app version which should be installed on the device ( ids == ids in DB, device_ids == identificator of device/imei/android id etc.) - SUPERADMIN UI
     */
    async upgradeV1MobileDeviceAppUpgradePostRaw(requestParameters: UpgradeV1MobileDeviceAppUpgradePostRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.mobileAppId === null || requestParameters.mobileAppId === undefined) {
            throw new runtime.RequiredError('mobileAppId','Required parameter requestParameters.mobileAppId was null or undefined when calling upgradeV1MobileDeviceAppUpgradePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mobileAppId !== undefined) {
            queryParameters['mobile_app_id'] = requestParameters.mobileAppId;
        }

        if (requestParameters.mobileDeviceIds) {
            queryParameters['mobile_device_ids'] = requestParameters.mobileDeviceIds;
        }

        if (requestParameters.mobileDeviceDeviceIds) {
            queryParameters['mobile_device_device_ids'] = requestParameters.mobileDeviceDeviceIds;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/mobile/device/app/upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Set the desired app version which should be installed on the device ( ids == ids in DB, device_ids == identificator of device/imei/android id etc.) - SUPERADMIN UI
     */
    async upgradeV1MobileDeviceAppUpgradePost(requestParameters: UpgradeV1MobileDeviceAppUpgradePostRequest): Promise<boolean> {
        const response = await this.upgradeV1MobileDeviceAppUpgradePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload new version of apk - SUPERADMIN UI
     */
    async uploadV1MobileAppPostRaw(requestParameters: UploadV1MobileAppPostRequest): Promise<runtime.ApiResponse<MobileApp>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadV1MobileAppPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/mobile/app`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAppFromJSON(jsonValue));
    }

    /**
     * Upload new version of apk - SUPERADMIN UI
     */
    async uploadV1MobileAppPost(requestParameters: UploadV1MobileAppPostRequest): Promise<MobileApp> {
        const response = await this.uploadV1MobileAppPostRaw(requestParameters);
        return await response.value();
    }

}
