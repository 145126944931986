import { LayoutContent } from 'common/components/Layout/Content';
import * as React from 'react';
import AllowanceBar from '../components/AllowanceBar/AllowanceBar';
import AllowanceBulkEdit from '../components/AllowanceBulkEdit/AllowanceBulkEdit';
import AllowanceTable, { DiemsTableData } from '../components/AllowanceTable/AllowanceTable';
import AllowanceSummary, { DiemsSummary } from '../components/AllowancesSummary/AllowanceSummary';
import { RouteNames, WithLogic, withLogicContext } from 'App';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { fromBlankAsync, Workbook, Style, Cell } from 'xlsx-populate';
import moment from 'moment';
import qs from 'qs';
import { DATE_FORMAT } from 'domain-constants';
import { Subscription } from 'rxjs';
import { message } from 'antd';
import i18n from 'i18next';
import { confDefault } from 'conf';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { DiemsBulkFormModel } from 'common/forms/DiemsBulkForm/DiemsBulkForm';
import Confirm, { MessageType } from 'common/components/Confirm';
import numeral from 'numeral';
import HelperModal from 'common/components/HelperModal';
import { CalculationTypeOptions, pocketMoneyMapper } from 'common/model/allowance';
import { formatDuration } from 'common/utils/dateTimeUtils';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import { toAddress } from 'common/utils/address';
import { EWClient } from 'generated/main-data-api';
import { AddressStructured } from 'common/model/address';
import { changeToNoSpaceString } from 'common/utils/search';

interface State {
    bulk: {
        bulkEditVisible: boolean;
        bulkConfirmVisible: boolean;
        overlappingCount: number;
        overlappingCountLoading?: boolean;
        loading: boolean;
        formData?: { users: string[] } & DiemsBulkFormModel;
    };
    detail: {
        isOpen: boolean;
    };
    diems: {
        loading: boolean;
        summaryData?: DiemsSummary;
        tableData?: DiemsTableData[];
        userSelected: string[];
    };
    exportLoading: boolean;
    date: {
        start: string;
        end: string;
    };
    helper?: {
        content: string;
    };
}

interface Props extends WithTranslation, WithLogic, RouteComponentProps {}
type Query = {
    start?: string;
    end?: string;
};

class AllowancesModule extends React.Component<Props, State> {
    private _defaultStartDate: string;
    private _defaultEndDate: string;
    private _diemsOverlappingSubscription?: Subscription;
    private _diemsOverlappingLoadingSubscription?: Subscription;
    private _diemsDataSubscription?: Subscription;
    private _diemsErrorSubscription?: Subscription;
    private _diemsLoadingSubscription?: Subscription;
    constructor(props: Props) {
        super(props);
        window.moment = moment;
        const params: Query = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        });

        if (params.start) {
            this._defaultStartDate = params.start;
        } else {
            this._defaultStartDate = moment().startOf('month').format(DATE_FORMAT);
        }
        if (params.end) {
            this._defaultEndDate = params.end;
        } else {
            this._defaultEndDate = moment().format(DATE_FORMAT);
        }

        this.state = {
            bulk: {
                bulkEditVisible: false,
                bulkConfirmVisible: false,
                overlappingCount: 0,
                loading: false
            },
            date: {
                start: this._defaultStartDate,
                end: this._defaultEndDate
            },
            diems: {
                loading: true,
                userSelected: []
            },

            detail: {
                isOpen: false
            },
            exportLoading: false
        };
    }

    async componentDidMount() {
        this.setState(
            {
                date: {
                    start: this._defaultStartDate,
                    end: this._defaultEndDate
                }
            },
            () => {
                this._diemsOverlappingLoadingSubscription = this.props.logic
                    .diems()
                    .rules()
                    .diemsOverlappingLoading.subscribe(loading => {
                        this.setState(state => ({
                            bulk: {
                                ...state.bulk,
                                overlappingCountLoading: loading
                            }
                        }));
                    });

                this._diemsOverlappingSubscription = this.props.logic
                    .diems()
                    .rules()
                    .diemsOverlappingData.subscribe(data => {
                        this.setState(state => ({
                            bulk: {
                                ...state.bulk,
                                overlappingCount: data.count
                            }
                        }));
                    });

                this._diemsLoadingSubscription = this.props.logic.diems().diemsLoading.subscribe(loading => {
                    this.setState(state => ({
                        diems: {
                            ...state.diems,
                            loading
                        }
                    }));
                });
                this._diemsErrorSubscription = this.props.logic.diems().diemsError.subscribe(_err => {
                    message.error(this.props.t('common.error.loadDataError'));
                });
                this._diemsDataSubscription = this.props.logic.diems().diemsData.subscribe(_data => {
                    const tableData = this.props.logic.diems().getDiemsGlobalTableData();
                    const summaryData = this.props.logic.diems().getDiemsGlobalSummary();
                    this.setState(state => ({
                        diems: {
                            ...state.diems,
                            summaryData: summaryData,
                            tableData: tableData
                        }
                    }));
                });

                this.props.logic
                    .diems()
                    .getDiems(
                        moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                        moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate()
                    );
            }
        );
    }

    componentWillUnmount() {
        this._diemsOverlappingLoadingSubscription?.unsubscribe();
        this._diemsOverlappingSubscription?.unsubscribe();
        this._diemsDataSubscription?.unsubscribe();
        this._diemsErrorSubscription?.unsubscribe();
        this._diemsLoadingSubscription?.unsubscribe();
    }

    render() {
        return (
            <LayoutContent
                className="allowance"
                mainSizes={this.state.detail.isOpen ? { xs: 24, sm: 24, md: 18 } : { xs: 24, sm: 24, md: 24 }}
                extraSizes={this.state.detail.isOpen ? [{ xs: 24, sm: 24, md: 6 }] : [{ xs: 0, sm: 0, md: 0 }]}
                main={
                    <>
                        <AllowanceBar
                            isSuperAdmin={this.props.logic.auth().superadmin()}
                            title={this.props.t('Allowance.bar.listTitle')}
                            dateRange={{
                                start: this.state.date.start,
                                end: this.state.date.end
                            }}
                            loading={this.state.diems.loading}
                            demoMode={this.props.logic.demo().isActive}
                            onCalendarChange={this._onCalendarChange}
                            exportLoading={this.state.exportLoading}
                            onBulkEdit={this._onBulkGlobalEdit}
                            onExportClick={this._onExportClick}
                            onHelperClick={this._onBarHelperClick}
                        />
                        <AllowanceSummary {...this.state.diems.summaryData} />
                        <AllowanceTable
                            loading={this.state.diems.loading}
                            isPolishDiems={this.props.logic.auth()?.newEWClient()?.country?.iso2.toUpperCase() === 'PL'}
                            data={this.state.diems.tableData ?? []}
                            onShowDetailClick={this._onShowDetailClick}
                            onSelectedRowChange={this._onTableUserSelectedChange}
                        />

                        {this.state.bulk.bulkEditVisible && (
                            <AllowanceBulkEdit
                                isPolishDiems={
                                    this.props.logic.auth()?.newEWClient()?.country?.iso2.toUpperCase() === 'PL'
                                }
                                defaultStart={this.state.date.start}
                                defaultEnd={this.state.date.end}
                                visible={this.state.bulk.bulkEditVisible}
                                onCancel={() => {
                                    this.setState(state => ({
                                        bulk: {
                                            ...state.bulk,
                                            bulkEditVisible: false
                                        }
                                    }));
                                }}
                                demoMode={this.props.logic.demo().isActive}
                                onSubmit={this._onBulkCreateClick}
                                onEdit={this._onBulkEditClick}
                            />
                        )}
                        {this.state.bulk.bulkConfirmVisible && (
                            <Confirm
                                type={MessageType.WARNING}
                                message={`${this.props.t('Allowance.bulk.countOfAffectedRules')}: ${
                                    this.state.bulk.overlappingCount
                                }`}
                                loading={this.state.bulk.overlappingCountLoading || this.state.bulk.loading}
                                onConfirm={this._onBulkCreateConfirm}
                                onCancel={this._onBulkCreateCancel}
                            />
                        )}
                        <HelperModal
                            name="allowances"
                            content={this.state.helper?.content ?? ''}
                            onClose={this._onHelperClose}
                            visible={!!this.state.helper}
                        />
                    </>
                }
                extra={this.state.detail.isOpen ? [<div />] : undefined}
            />
        );
    }

    private _onBulkGlobalEdit = () => {
        this.props.logic.diems().rules().getDiemsRules();
        this.setState(
            state => ({
                bulk: {
                    ...state.bulk,
                    formData: {
                        start: this.state.date.start,
                        end: this.state.date.end,
                        replacedMeals: [0],
                        pocketMoney: 0,
                        users: []
                    }
                }
            }),
            () => {
                this.setState(state => ({
                    bulk: {
                        ...state.bulk,
                        bulkEditVisible: true
                    }
                }));
            }
        );
    };

    private _onTableUserSelectedChange = (ids: string[]) => {
        this.setState(state => ({
            diems: {
                ...state.diems,
                userSelected: ids
            }
        }));
    };

    private _onBulkCreateCancel = () => {
        this.setState({
            bulk: {
                bulkConfirmVisible: false,
                bulkEditVisible: false,
                overlappingCount: 0,
                formData: undefined,
                loading: false
            }
        });
    };

    private _onBulkCreateConfirm = () => {
        this.setState(state => ({
            bulk: {
                ...state.bulk,
                loading: true
            }
        }));

        const formData = this.state.bulk.formData;
        const date = this.state.date;
        if (date && formData) {
            this.props.logic
                .diems()
                .rules()
                .createDiemsRules(
                    moment.utc(formData.start, DATE_FORMAT).toDate(),
                    moment.utc(formData.end, DATE_FORMAT).add(1, 'days').toDate(),
                    formData.users.map(userId => {
                        return parseInt(userId);
                    }),
                    formData.replacedMeals ?? [],
                    parseInt(pocketMoneyMapper[formData.pocketMoney ?? 0]),
                    formData.calculationType === CalculationTypeOptions.lastForeign,
                    formData.fixedRate?.domesticRate,
                    formData.fixedRate?.foreignRate
                )
                .then(() => {
                    message.success(this.props.t('Allowance.bulk.message.createSuccess'));
                })
                .catch(err => {
                    console.error(err);
                    message.error(this.props.t('Allowance.bulk.message.createError'));
                })
                .finally(() => {
                    this.props.logic
                        .diems()
                        .getDiems(
                            moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                            moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate()
                        );
                    this.setState({
                        bulk: {
                            bulkConfirmVisible: false,
                            bulkEditVisible: false,
                            overlappingCount: 0,
                            formData: undefined,
                            loading: false
                        }
                    });
                });
        } else {
            this.setState({
                bulk: {
                    bulkConfirmVisible: false,
                    bulkEditVisible: false,
                    overlappingCount: 0,
                    formData: undefined,
                    loading: false
                }
            });
        }
    };

    private _onBulkEditClick = (data: DiemsBulkFormModel, userId?: number | null) => {
        this.setState(state => ({
            bulk: {
                ...state.bulk,
                bulkEditVisible: false,
                bulkConfirmVisible: true,
                formData: {
                    ...data,
                    users: userId ? [userId.toString()] : []
                }
            }
        }));

        this.props.logic
            .diems()
            .rules()
            .getOverlappingDiemsRules(
                moment.utc(data.start, DATE_FORMAT),
                moment.utc(data.end, DATE_FORMAT).add(1, 'days')
            );
    };

    private _onBulkCreateClick = (data: DiemsBulkFormModel) => {
        this.setState(state => ({
            bulk: {
                ...state.bulk,
                bulkEditVisible: false,
                bulkConfirmVisible: true,
                formData: {
                    ...data,
                    users: this.state.diems.userSelected
                }
            }
        }));

        this.props.logic
            .diems()
            .rules()
            .getOverlappingDiemsRules(
                moment.utc(data.start, DATE_FORMAT),
                moment.utc(data.end, DATE_FORMAT).add(1, 'days')
            );
    };

    private _onCalendarChange = (values: [moment.Moment | null, moment.Moment | null] | null) => {
        if (values) {
            const [start, end] = values;
            if (!start || !end) {
                console.error('missing start or end date');
            } else {
                this.props.history.push({
                    search: qs.stringify({
                        start: start.format(DATE_FORMAT),
                        end: end.format(DATE_FORMAT)
                    } as Query)
                });
                this.setState(
                    state => ({
                        date: {
                            start: start.format(DATE_FORMAT),
                            end: end.format(DATE_FORMAT)
                        },
                        diems: {
                            ...state.diems,
                            loading: true
                        }
                    }),
                    () => {
                        this.props.logic
                            .diems()
                            .getDiems(
                                moment(this.state.date.start, DATE_FORMAT).startOf('day').toDate(),
                                moment(this.state.date.end, DATE_FORMAT).add(1, 'days').toDate()
                            );
                    }
                );
            }
        }
    };

    private _onShowDetailClick = (id: string) => {
        this.props.history.push({
            pathname: `${RouteNames.STATISTICS_ALLOWANCES_DRIVER_DETAIL}/${id}`,
            search: qs.stringify({
                start: this.state.date.start,
                end: this.state.date.end
            } as Query)
        });
    };

    private _onExportClick = async (): Promise<void> => {
        const { t } = this.props;
        const userIds = this.state.diems.tableData?.map(td => td.id);
        if (userIds && userIds.length > 0) {
            this.setState({ exportLoading: true });
            const workbook: Workbook = await fromBlankAsync();
            userIds.forEach((userId, index) => {
                const diems = this.props.logic.diems().getDiemsUserTableData(parseInt(userId));
                const userData = this.props.logic.diems().getDiemsUser(parseInt(userId));
                const driverName = userData
                    ? `${userData.name ? `${userData.name.slice(0, 1)}. ` : ''} ${userData.surname}`
                    : index + t('common.unknown');
                const client = this.props.logic.auth().client();
                const clientNew = this.props.logic.auth().newEWClient();
                const sheetName = normalizeExportSheetName(`${index + 1}.${driverName}`);
                // Rename first default sheet
                if (index === 0) {
                    workbook.sheet(0).name(sheetName);
                } else {
                    workbook.addSheet(sheetName);
                }
                const head: string[][] = [
                    [
                        t('AllowanceExport.title'),
                        '',
                        `${t('AllowanceExport.period')}: ${moment(this.state.date.start, DATE_FORMAT)
                            .local()
                            .format('L')} - ${moment(this.state.date.end, DATE_FORMAT).local().format('L')}`,
                        '',
                        '',
                        ''
                    ],
                    [],
                    [t('common.company'), '', client?.name ?? ''],
                    [
                        t('common.address'),
                        '',
                        toAddress(
                            this.props.logic.auth().user().lang,
                            client as EWClient,
                            [
                                {
                                    town: (clientNew?.address as any)?.city,
                                    country: (clientNew?.address as any)?.country,
                                    streetAddress: (clientNew?.address as any)?.street,
                                    postalCode: (clientNew?.address as any)?.postcode,
                                    countryCode: clientNew?.country?.iso2
                                }
                            ] as AddressStructured[],
                            this.props.logic.settings().getProp('addressIdentification'),
                            t('common.unknown')
                        )
                    ],
                    [t('common.driver'), '', changeToNoSpaceString(driverName)],
                    [],
                    [
                        `${t('Allowance.summary.stayLocalTime')} (${t('common.days')[0].toLowerCase()}:${t(
                            'common.hours'
                        )[0].toLowerCase()}:${t('common.minutes')[0].toLowerCase()})`,
                        t('Allowance.summary.countries'),
                        `${t('Allowance.summary.stayAbroadTime')} (${t('common.days')[0].toLowerCase()}:${t(
                            'common.hours'
                        )[0].toLowerCase()}:${t('common.minutes')[0].toLowerCase()})`,
                        t('Allowance.summary.pocketMoney'),
                        t('Allowance.summary.allowance'),
                        t('Allowance.summary.allowanceTotal')
                    ],
                    [
                        formatDuration((this.state.diems.summaryData?.localStay ?? 0) * 1000),
                        this.state.diems.summaryData?.countries ?? '',
                        formatDuration((this.state.diems.summaryData?.abroadStay ?? 0) * 1000),
                        this.state.diems.summaryData?.pocketMoney
                            .map(pocketMoney => `${pocketMoney.currency} ${pocketMoney.value}`)
                            .toString() ?? '',
                        this.state.diems.summaryData?.dailyRates
                            .map(dailyRate => `${dailyRate.currency} ${dailyRate.value}`)
                            .toString() ?? '',
                        this.state.diems.summaryData?.allowanceSummary
                            ? `${this.state.diems.summaryData?.allowanceSummary.currency} ${this.state.diems.summaryData?.allowanceSummary.value}`
                            : ''
                    ]
                ];

                let data: any[] = [];
                let manuallyChanges = false;

                diems.forEach(row => {
                    data.push([
                        moment(row.id).format('L'),
                        moment(row.dateFrom).format('L'),
                        moment(row.dateTo).format('L'),
                        row.domesticTravel,
                        row.abroadTravel,
                        row.country,
                        row.dailyRate,
                        row.pocketMoney,
                        row.shortening ? numeral(row.shortening).format('0,0.00') : '',
                        row.totalRate,
                        row.manualRate?.domestic || row.manualRate?.foreign
                            ? `${row.manualRate.byUserName} ${row.manualRate.byUserSurname}`
                            : '',
                        row.manualRate?.domestic || row.manualRate?.foreign
                            ? moment(row.manualRate.byUserAt).format('L LT')
                            : ''
                    ]);
                    if (row.manualRate?.domestic || row.manualRate?.foreign) {
                        manuallyChanges = true;
                    }

                    if (row.children) {
                        row.children.forEach(child => {
                            data.push([
                                '',
                                moment(child.dateFrom).format('L LT'),
                                moment(child.dateTo).format('L LT'),
                                child.domesticTravel,
                                child.abroadTravel,
                                child.country,
                                child.dailyRate,
                                child.pocketMoney,
                                child.shortening ? numeral(row.shortening).format('0,0.00') : '',
                                child.totalRate,
                                child.manualRate?.domestic || child.manualRate?.foreign
                                    ? `${child.manualRate.byUserName} ${child.manualRate.byUserSurname}`
                                    : '',
                                child.manualRate?.domestic || child.manualRate?.foreign
                                    ? moment(child.manualRate.byUserAt).format('L LT')
                                    : ''
                            ]);
                            if (child.manualRate?.domestic || child.manualRate?.foreign) {
                                manuallyChanges = true;
                            }
                        });
                    }
                });

                let body = [];
                if (manuallyChanges) {
                    body.push(['', '', '', '', '', '', '', '', '', t('Allowance.detailTable.totalRate')]);
                }

                body.push([
                    t('common.day'),
                    t('Allowance.detailTable.dateFrom'),
                    t('Allowance.detailTable.dateTo'),
                    t('Allowance.detailTable.stayLocalTime'),
                    t('Allowance.detailTable.stayAbroadTime'),
                    t('Allowance.detailTable.country'),
                    t('Allowance.detailTable.rate'),
                    t('Allowance.detailTable.supply'),
                    t('Allowance.detailTable.shortening'),
                    manuallyChanges ? t('Allowance.detailTable.rate') : t('Allowance.detailTable.totalRate'),
                    manuallyChanges ? t('Allowance.detailTable.manuallyChangedByUser') : '',
                    manuallyChanges ? t('Allowance.detailTable.manuallyChangedDate') : ''
                ]);

                body = [...body, ...data];

                // data push + styles
                // ------------------
                let lastRow = 0;

                workbook.sheet(sheetName).range(1, 3, 1, 6).merged(true);

                lastRow = workbook
                    .sheet(sheetName)
                    .range(1, 1, head.length, 6)
                    .value(head)
                    .style({ fill: { color: 'FFFFFF', type: 'solid' } } as Style)
                    .forEach((cell: Cell, ri: number) => {
                        if (ri === 0) cell.style({ bold: true, fontSize: 14 } as Style);
                        if ([2, 3, 4].includes(ri)) {
                            cell.style({ bold: true, fill: { color: 'EFEFEF' } });
                        }
                        if ([6, 7].includes(ri)) {
                            cell.style({
                                bold: true,
                                fill: { color: 'EFEFEF' },
                                topBorderStyle: 'medium',
                                bottomBorderStyle: 'medium',
                                rightBorderStyle: 'medium',
                                wrapText: true
                            } as Style);
                        }
                    })
                    .endCell()
                    .rowNumber();

                lastRow = workbook
                    .sheet(sheetName)
                    .range(lastRow + 2, 1, lastRow + 1 + body.length, manuallyChanges ? 12 : 10)
                    .value(body)
                    .style('fill', 'FFFFFF')
                    .forEach((cell: Cell, ri: number) => {
                        if (ri === 0 || (manuallyChanges && ri === 1)) {
                            cell.style({
                                bold: true,
                                fill: { color: 'D0D0D0' },
                                rightBorderStyle: 'medium',
                                topBorderStyle: 'medium',
                                bottomBorderStyle: 'medium',
                                wrapText: true
                            } as Style);
                        }

                        if (ri > 1 || (!manuallyChanges && ri === 1)) {
                            cell.style({ border: 'medium', fill: { color: 'EFEFEF', type: 'solid' } } as Style);
                        }
                    })
                    .endCell()
                    .rowNumber();

                if (manuallyChanges) {
                    workbook
                        .sheet(sheetName)
                        .range(head.length + 2, 1, head.length + 2, 9)
                        .merged(true);
                    workbook
                        .sheet(sheetName)
                        .range(head.length + 2, 10, head.length + 2, 12)
                        .merged(true)
                        .style('horizontalAlignment', 'center');
                }
                // column width
                // https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
                [20, 20, 20, 20, 20, 25, 15, 15, 15, 15, 20, 20].forEach((width, i) => {
                    // 65 => 'A'...'t'
                    const column = String.fromCharCode(65 + i);
                    workbook.sheet(sheetName).column(column).width(width);
                });

                // signature
                const startColumn = 7;
                workbook
                    .sheet(sheetName)
                    .range(lastRow + 2, startColumn + 1, lastRow + 5, startColumn + (manuallyChanges ? 5 : 3))
                    .merged(true)
                    .style({
                        bold: true,
                        topBorderStyle: 'medium',
                        rightBorderStyle: 'medium',
                        bottomBorderStyle: 'medium'
                    } as Style);
                workbook
                    .sheet(sheetName)
                    .range(lastRow + 2, startColumn, lastRow + 5, startColumn)
                    .forEach((cell: Cell, ri: number) => {
                        if (ri === 0) {
                            cell.style({
                                bold: true,
                                borderStyle: 'medium',
                                bottomBorderColor: 'FFFFFF',
                                rightBorderColor: 'FFFFFF'
                            } as Style);
                        } else if (ri === 3) {
                            cell.style({
                                bold: true,
                                borderStyle: 'medium',
                                topBorderColor: 'FFFFFF',
                                rightBorderColor: 'FFFFFF'
                            } as Style);
                        } else if (ri > 0) {
                            cell.style({
                                bold: true,
                                borderStyle: 'medium',
                                topBorderColor: 'FFFFFF',
                                bottomBorderColor: 'FFFFFF',
                                rightBorderColor: 'FFFFFF'
                            } as Style);
                        }
                    });

                workbook
                    .sheet(sheetName)
                    .range(lastRow + 4, startColumn, lastRow + 4, startColumn)
                    .style({ fill: { color: 'EFEFEF' } } as Style)
                    .value(t('AllowanceExport.signature'));
            });

            // create and download file
            const blob = (await workbook.outputAsync()) as Blob;
            downloadFile(
                blob,
                `Allowance list ${moment(this.state.date.start, DATE_FORMAT).local().format('L')}-${moment(
                    this.state.date.end,
                    DATE_FORMAT
                )
                    .local()
                    .format('L')}.xlsx`
            );
            this.setState({ exportLoading: false });
        }
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'diets(travelallowances)';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.props.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withRouter(withTranslation()(withLogicContext(AllowancesModule)));
