import { WithTranslation, withTranslation } from 'react-i18next';
import { KM } from 'domain-constants';
import cn from 'classnames';
import { Col, Row } from 'antd';
import 'flag-icon-css/css/flag-icon.css';
import { BorderCrossingCountryModel } from '../../StatisticsBorderCrossingModule';
import moment from 'moment';
import numeral from 'numeral';
import { durationToFormated } from 'common/utils/actual';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    data: BorderCrossingCountryModel[];
}

function BorderCrossingSummary(props: Props) {
    return (
        <div className="border-crossing-summary">
            {props.data?.map((e, i) => (
                <div
                    className="border-crossing-summary-country"
                    key={i}
                    data-qa={qa.borderCrossing.summary.sectionCountry}
                >
                    <div className="border-crossing-summary-location">
                        <div className={cn(`flag-icon flag-icon-${e.country.toLocaleLowerCase()}`)} />
                        <div data-qa={qa.borderCrossing.summary.fieldCountry}>{e.country}</div>
                    </div>
                    <div className="border-crossing-summary-stats">
                        <Col>
                            <Row>{props.t('BorderCrossingSummary.entries')}:</Row>
                            <Row>{props.t('common.duration')}:</Row>
                            <Row>{props.t('common.distance')}:</Row>
                        </Col>
                        <Col>
                            <Row>
                                <span data-qa={qa.borderCrossing.summary.fieldEntries}>{`${e.entries} x`}</span>
                            </Row>
                            <Row>
                                <span data-qa={qa.borderCrossing.summary.fieldDuration}>
                                    {durationToFormated(moment.duration(e.duration, 'seconds'))}
                                </span>
                            </Row>
                            <Row>
                                <span data-qa={qa.borderCrossing.summary.fieldDistance}>{`${numeral(e.distance).format(
                                    '0,0'
                                )} ${KM}`}</span>
                            </Row>
                        </Col>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default withTranslation()(BorderCrossingSummary);
