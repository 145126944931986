import { LatLng } from 'common/model/geo';
import {
    GoodsWithAddress,
    PuescValidationErrors,
    TrailerWithAvailability,
    VehicleWithAvailability
} from 'common/model/transports';
import {
    AddressStructured,
    BorderCrossTwoDirections,
    Goods,
    MonitoredObjectFleetType,
    Transport,
    TransportEventRule,
    TransportPlace,
    TransportPlaceTask,
    TransportPlaceTaskType,
    TransportTollCost,
    TripInDatabase
} from 'generated/backend-api';
import { PlaceType, VehicleProfile } from 'generated/graphql';
import {
    ReadOnlyContactList,
    ReadOnlyCountry,
    ReadOnlyExternalSystemSecret,
    WriteOnlyContactList
} from 'generated/new-main/models';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { Fuels, Services } from 'modules/map/MapModule';
import { Subject } from 'rxjs';
import { PlanRoutError, TransportType } from './planner-logic';
import {
    PlannedTransport,
    RouteType,
    InputVehicleProfile as VehicleProfileRoutingApi
} from 'generated/routing-api/models';
import { ColdchainProfileData } from 'common/forms/ColdchainProfileForm';

export abstract class PlannerLogicDefinition {
    abstract onPlanRouteError: Subject<PlanRoutError>;
    /**
     * Filed is true if you have monitored object in parameter, assumption redirecting from TNT
     */
    abstract lockedMonitoredObjectId?: number;
    abstract initTransportLoading: boolean;
    abstract generateRouteLoading?: boolean;
    abstract createRouteLoading?: boolean;
    abstract calculatingRouteLoading?: boolean;
    abstract tollCostLoading?: boolean;
    abstract routeModified: boolean;
    abstract tollCostByType?: {
        [key in RouteType]?: TransportTollCost;
    };
    abstract transport: Transport;
    abstract availableTrailers: TrailerWithAvailability[];
    abstract availableTrailersLoading?: boolean;
    abstract availableVehicles: VehicleWithAvailability[];
    abstract availableVehiclesLoading?: boolean;
    abstract availableVehicleProfiles: VehicleProfile[];
    abstract availableVehicleProfilesLoading?: boolean;
    abstract availableColdchainProfiles: ColdchainProfileData[];
    abstract availableColdchainProfilesLoading?: boolean;
    abstract showColdchainUpdateConfirm?: boolean;
    abstract clientContactList: ReadOnlyContactList[];
    abstract clientContactListLoading?: boolean;
    abstract clientList: ReadOnlyContactList[];
    abstract consigneeList: ReadOnlyContactList[];
    abstract countryList: ReadOnlyCountry[];
    abstract countryListLoading?: boolean;
    abstract puescSecret?: ReadOnlyExternalSystemSecret;
    abstract externalSystemVehicleIds?: number[];
    /**
     * Filed that is set based on external system with name 'PUESC'
     */
    abstract externalSystemConnected: boolean;
    abstract externalSystemLoading?: boolean;
    abstract selectedPlaceId: string;
    abstract selectedTaskId: string;
    abstract transportInPoland: boolean;
    abstract defaultPuescActive?: boolean;
    abstract plCrosses?: BorderCrossTwoDirections[];
    abstract plCrossesLoading?: boolean;
    abstract puescValidationErrors?: PuescValidationErrors;
    abstract possibleTransports: PlannedTransport[];
    abstract selectedTransportType: TransportType;
    /**
     * specific field for trailer,
     * when user select trailer "noCriteria" or "withoutTrailer" it is the same value,
     * we allow puesc transport without trailer but it has which has to be selected by user
     */
    abstract puescNoTrailer?: boolean;
    abstract defaultMonitoredObjectId?: number;
    protected abstract _customPlacesRta: Set<string>;
    protected abstract _trips: TripInDatabase[];
    protected abstract _onMarkerDragEndInterval?: NodeJS.Timeout;
    protected abstract _debounceUpdatePlaceActiveIds: string[];
    protected abstract _tmpWayPointPlaceId?: string;
    protected abstract _newPointMarkerAllowed: boolean;
    protected abstract _fuelStations: PoiModelMap[];
    protected abstract _parkings: PoiModelMap[];
    protected abstract _pois: PoiModelMap[];
    protected abstract _washers: PoiModelMap[];
    protected abstract _fuelStationsLoadedWhileRender: boolean;
    protected abstract _parkingsLoadedWhileRender: boolean;
    protected abstract _poisLoadedWhileRender: boolean;
    protected abstract _washersLoadedWhileRender: boolean;
    protected abstract _fuelDataFilter: Fuels;
    protected abstract _serviceDataFilter: Services;
    protected abstract _useLocaleStorage: boolean;

    abstract get useTrafficMode(): boolean;
    abstract get tollCostEnable(): boolean;
    abstract get isPuescTransport(): boolean;
    abstract get isPuescTransportPossible(): boolean;

    abstract get selectedTrailerId(): number | undefined;
    abstract get selectedVehicleId(): number | undefined;
    abstract get selectedProfileId(): number | undefined;
    abstract get selectedPlace(): TransportPlace | undefined;
    abstract get selectedPlaceDisabled(): boolean;
    abstract get selectedTask(): TransportPlaceTask | undefined;
    abstract get goodsBySelectedTask(): Goods[];
    abstract get goodsWithAddressBySelectedPlace(): GoodsWithAddress[];
    /**
     * Returns all goods with address of current transport
     */
    abstract get goodsWithAddress(): GoodsWithAddress[];
    abstract get addressBySelectedPlace(): AddressStructured[] | undefined;
    abstract get alarmsBySelectedPlace(): TransportEventRule[] | undefined;
    abstract get driverNoteBySelectedTask(): string | undefined;
    abstract get clientContactBySelectedTask(): ReadOnlyContactList | undefined;
    abstract get hasAlarmRight(): boolean;

    abstract setFuelDataFilter(fuel: Fuels): void;
    abstract setServiceDataFilter(service: Services): void;
    abstract init(
        useLocaleStorage: boolean,
        planningMode: 'edit' | 'preview' | undefined,
        transportId?: string,
        transport?: Transport,
        monitoredObjectId?: number
    ): Promise<void>;

    // #region Methods that update "this.transport" field (there are also protected methods at the bottom)
    abstract setOmitParamsFromRouting(omit: boolean): void;
    abstract planRoute(transport: Transport, loading?: boolean): Promise<void>;
    abstract routing(transport: Transport, transportPlace?: TransportPlace[]): Promise<PlannedTransport[]>;
    abstract removePlaceFromTransport(id: string): void;
    abstract addPlaceToTransport(
        name: string,
        coordinates: LatLng,
        type: PlaceType,
        index?: number,
        polygon?: LatLng[][],
        id?: string
    ): Promise<string>;
    abstract addPlaceCrossingPointToTransport(markerId: string, coordinates: LatLng): Promise<void>;
    abstract updateCrossingPointLatLong(markerId: string, coordinates: LatLng, partialUpdate: boolean): Promise<void>;
    abstract updatePlaceLatLong(markerId: string, coordinates: LatLng, partialUpdate: boolean): Promise<void>;
    abstract saveSelectedTask(task: TransportPlaceTask, clientContact?: ReadOnlyContactList): Promise<void>;
    abstract removeSelectedTask(): void;
    abstract setClientContactId(): void;
    abstract updateTransportMonitoredObjectAndReroute(
        monitoredObjectId: number,
        type: MonitoredObjectFleetType
    ): Promise<void>;
    abstract setMonitoredObjectOnTransport(monitoredObjectId: number, type: MonitoredObjectFleetType): void;
    abstract removeMonitoredObjectOrProfileAndReroute(type: MonitoredObjectFleetType): Promise<void>;
    abstract removeMonitoredObjectFromTransport(type: MonitoredObjectFleetType): void;
    abstract changePlaceRta(placeId: string, value: moment.Moment): void;
    abstract changeTransportName(name?: string): void;
    abstract updateTransportNameByPlaces(): void;
    abstract setTransportCostPerKm(price: number, currency: string): void;
    abstract generateRoute(): Promise<void>;
    abstract updatePlaceOrder(oldIndex: number, newIndex: number): void;
    abstract updateTransportProfileAndReroute(desiredVehicleProfile?: number): Promise<void>;
    abstract setTransportDesiredProfileId(desiredVehicleProfile?: number): void;
    abstract removeTransportDesiredProfileId(): void;
    abstract onPuescSwitch(active: boolean): void;
    // #endregion

    abstract setPuescNoTrailer(noTrailer: boolean): void;
    abstract drawRouteOnMap(fetchLatestTrips?: boolean): Promise<void>;
    abstract onRouteChange(): void;
    abstract setSelectedPlaceId(placeId?: string): void;
    abstract setSelectedTaskId(taskId?: string): void;
    abstract reset(removeLocaleStorage: boolean): void;
    abstract updateClientContact(id: string, client: ReadOnlyContactList): Promise<ReadOnlyContactList>;
    abstract createClientContact(client: WriteOnlyContactList): Promise<ReadOnlyContactList>;
    abstract getTaskTypeSelectionIcon(taskType?: TransportPlaceTaskType): string;

    abstract validPuescTransport(): void;
    abstract saveTransport(): Promise<Transport>;
    protected abstract _createTransport(transport: Transport, firstDestAta?: string): Promise<Transport>;
    protected abstract _updateTransport(id: string, transport: Transport): Promise<Transport>;

    // #region Methods that fetch data that are needed to work with planner, do not return values, just setting local fields
    protected abstract _fetchAvailableVehicles(): Promise<void>;
    protected abstract _fetchVehiclesPricePerKm(): Promise<void>;
    protected abstract _fetchVehicleProfiles(): Promise<void>;
    protected abstract _fetchAvailableTrailers(): Promise<void>;
    protected abstract fetchClientContactList(): Promise<void>;
    protected abstract _fetchCountryList(): Promise<void>;
    protected abstract _fetchPlCrosses(): Promise<void>;
    protected abstract _fetchExternalSystemData(): void;
    protected abstract _fetchTrips(vehicleId: number, startTime: string, endTime: string): Promise<void>;
    // #endregion

    // #region Protected methods that update "this.transport" field
    protected abstract _setPlannedPossibleTransport(): void;
    protected abstract _addRouteToTransport(transport: Transport, route: PlannedTransport): void;
    protected abstract _removePuescData(): void;
    protected abstract _removeNotExistingUnloadedGoods(): void;
    // #endregion

    abstract poisAlongRoute(encodedRoutes: string[]): Promise<void>;
    protected abstract _getRoutingProfile(): VehicleProfileRoutingApi | undefined;
    protected abstract _transportInPoland(): void;
    protected abstract _lookPlCrosses(): void;
    protected abstract _getTransportGoods(): Goods[];
    protected abstract _updateTransportInLocalStorage(): void;
    protected abstract _generateId(): void;
    protected abstract _isMarkerDraggable(placeId?: string): boolean;
    protected abstract _planPartialRoute(places: TransportPlace[]): Promise<void>;
    protected abstract _loadMissingFuelStations(
        fuelStations: string[],
        retryCount: number
    ): Promise<void | object> | undefined;
    protected abstract _checkVehicleAvailability(): Promise<void>;
    protected abstract _loadTollCost(transport: Transport): void;
}
