import {
    ReadOnlyAddressNested,
    ReadOnlyAddressNestedTypeEnum,
    WriteOnlyAddressNestedTypeEnum
} from 'generated/new-main';
import { Logic } from 'logic/logic';

export interface PartnerAddressModel {
    id: string;
    street: string;
    houseNumber: string;
    city: string;
    country: number;
    zip: string;
    type: ReadOnlyAddressNestedTypeEnum;
}

export class PartnerAddressLogic {
    constructor(private _logic: Logic) {}

    toAddress(data: ReadOnlyAddressNested): PartnerAddressModel {
        return {
            id: data.id ?? '',
            city: data.city ?? '',
            street: data.street ?? '',
            houseNumber: data.houseNumber ?? '',
            zip: data.zip ?? '',
            type: data.type as ReadOnlyAddressNestedTypeEnum,
            country: data.country
        };
    }

    async addAddress(address: PartnerAddressModel, clientId?: number): Promise<PartnerAddressModel> {
        return await this._addAddress(address, clientId);
    }

    private async _addAddress(address: PartnerAddressModel, clientId?: number): Promise<PartnerAddressModel> {
        try {
            const response = await this._logic.api().addressApi.addressCreate({
                data: {
                    city: address.city,
                    street: address.street,
                    houseNumber: address.houseNumber,
                    zip: address.zip,
                    country: address.country,
                    clientId: clientId ? clientId : null,
                    type:
                        address.type === ReadOnlyAddressNestedTypeEnum.Correspondence
                            ? WriteOnlyAddressNestedTypeEnum.Correspondence
                            : WriteOnlyAddressNestedTypeEnum.Delivery
                }
            });
            return this.toAddress(response);
        } catch (err) {
            console.log('Partner client POST err:', err);
            throw err;
        }
    }
}

export const sampleAddresses: PartnerAddressModel[] = [
    {
        id: 'a',
        street: 'a',
        houseNumber: 'a',
        city: 'a',
        country: 1,
        zip: 'a',
        type: ReadOnlyAddressNestedTypeEnum.Correspondence
    },
    {
        id: 'a',
        street: 'a',
        houseNumber: 'a',
        city: 'a',
        country: 2,
        zip: 'a',
        type: ReadOnlyAddressNestedTypeEnum.Delivery
    }
];
