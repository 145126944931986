/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverInfoModel
 */
export interface DriverInfoModel {
    /**
     * 
     * @type {Date}
     * @memberof DriverInfoModel
     */
    cardValidityBegin: Date;
    /**
     * 
     * @type {Date}
     * @memberof DriverInfoModel
     */
    cardExpiryDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DriverInfoModel
     */
    cardHolderBirthDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DriverInfoModel
     */
    cardIssueDate: Date;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    holderFirstNames: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    holderSurname: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    cardIssuingAuthorityName: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    cardNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    cardHolderPrefferedLanguage: string;
    /**
     * 
     * @type {string}
     * @memberof DriverInfoModel
     */
    cardIssuerMemberState: string;
}

export function DriverInfoModelFromJSON(json: any): DriverInfoModel {
    return DriverInfoModelFromJSONTyped(json, false);
}

export function DriverInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardValidityBegin': (new Date(json['card_validity_begin'])),
        'cardExpiryDate': (new Date(json['card_expiry_date'])),
        'cardHolderBirthDate': (new Date(json['card_holder_birth_date'])),
        'cardIssueDate': (new Date(json['card_issue_date'])),
        'holderFirstNames': json['holder_first_names'],
        'holderSurname': json['holder_surname'],
        'cardIssuingAuthorityName': json['card_issuing_authority_name'],
        'cardNumber': json['card_number'],
        'cardHolderPrefferedLanguage': json['card_holder_preffered_language'],
        'cardIssuerMemberState': json['card_issuer_member_state'],
    };
}

export function DriverInfoModelToJSON(value?: DriverInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_validity_begin': (value.cardValidityBegin.toISOString()),
        'card_expiry_date': (value.cardExpiryDate.toISOString()),
        'card_holder_birth_date': (value.cardHolderBirthDate.toISOString()),
        'card_issue_date': (value.cardIssueDate.toISOString()),
        'holder_first_names': value.holderFirstNames,
        'holder_surname': value.holderSurname,
        'card_issuing_authority_name': value.cardIssuingAuthorityName,
        'card_number': value.cardNumber,
        'card_holder_preffered_language': value.cardHolderPrefferedLanguage,
        'card_issuer_member_state': value.cardIssuerMemberState,
    };
}


