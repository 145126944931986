/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GoogleDirectionRouteParameters,
    GoogleDirectionRouteParametersFromJSON,
    GoogleDirectionRouteParametersToJSON,
    GoogleDirectionsResponse,
    GoogleDirectionsResponseFromJSON,
    GoogleDirectionsResponseToJSON,
    InaccessiblePointError,
    InaccessiblePointErrorFromJSON,
    InaccessiblePointErrorToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    PlannedTransport,
    PlannedTransportFromJSON,
    PlannedTransportToJSON,
    RouteCostsDetails,
    RouteCostsDetailsFromJSON,
    RouteCostsDetailsToJSON,
    RoutePlannerRequestBody,
    RoutePlannerRequestBodyFromJSON,
    RoutePlannerRequestBodyToJSON,
    TollByRouteIdsRequestBody,
    TollByRouteIdsRequestBodyFromJSON,
    TollByRouteIdsRequestBodyToJSON,
    TollCostRequestBody,
    TollCostRequestBodyFromJSON,
    TollCostRequestBodyToJSON,
} from '../models';

export interface GoogleDirectionsRequest {
    googleDirectionRouteParameters: GoogleDirectionRouteParameters;
}

export interface HereDirectionsRequest {
    routePlannerRequestBody: RoutePlannerRequestBody;
}

export interface PtvDirectionsRequest {
    routePlannerRequestBody: RoutePlannerRequestBody;
}

export interface PtvTollRequest {
    routePlannerRequestBody: RoutePlannerRequestBody;
}

export interface PtvTollByRouteIdsRequest {
    tollByRouteIdsRequestBody: TollByRouteIdsRequestBody;
}

export interface RoadLordTollCostsRequest {
    tollCostRequestBody: Array<TollCostRequestBody>;
}

export interface SygicCoreDirectionsRequest {
    routePlannerRequestBody: RoutePlannerRequestBody;
}

/**
 * no description
 */
export class RoutingApi extends runtime.BaseAPI {

    /**
     */
    async googleDirectionsRaw(requestParameters: GoogleDirectionsRequest): Promise<runtime.ApiResponse<GoogleDirectionsResponse>> {
        if (requestParameters.googleDirectionRouteParameters === null || requestParameters.googleDirectionRouteParameters === undefined) {
            throw new runtime.RequiredError('googleDirectionRouteParameters','Required parameter requestParameters.googleDirectionRouteParameters was null or undefined when calling googleDirections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/route/plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleDirectionRouteParametersToJSON(requestParameters.googleDirectionRouteParameters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GoogleDirectionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async googleDirections(requestParameters: GoogleDirectionsRequest): Promise<GoogleDirectionsResponse> {
        const response = await this.googleDirectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hereDirectionsRaw(requestParameters: HereDirectionsRequest): Promise<runtime.ApiResponse<Array<PlannedTransport>>> {
        if (requestParameters.routePlannerRequestBody === null || requestParameters.routePlannerRequestBody === undefined) {
            throw new runtime.RequiredError('routePlannerRequestBody','Required parameter requestParameters.routePlannerRequestBody was null or undefined when calling hereDirections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/route/here`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePlannerRequestBodyToJSON(requestParameters.routePlannerRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlannedTransportFromJSON));
    }

    /**
     */
    async hereDirections(requestParameters: HereDirectionsRequest): Promise<Array<PlannedTransport>> {
        const response = await this.hereDirectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ptvDirectionsRaw(requestParameters: PtvDirectionsRequest): Promise<runtime.ApiResponse<Array<PlannedTransport>>> {
        if (requestParameters.routePlannerRequestBody === null || requestParameters.routePlannerRequestBody === undefined) {
            throw new runtime.RequiredError('routePlannerRequestBody','Required parameter requestParameters.routePlannerRequestBody was null or undefined when calling ptvDirections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/route/ptv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePlannerRequestBodyToJSON(requestParameters.routePlannerRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlannedTransportFromJSON));
    }

    /**
     */
    async ptvDirections(requestParameters: PtvDirectionsRequest): Promise<Array<PlannedTransport>> {
        const response = await this.ptvDirectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ptvTollRaw(requestParameters: PtvTollRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.routePlannerRequestBody === null || requestParameters.routePlannerRequestBody === undefined) {
            throw new runtime.RequiredError('routePlannerRequestBody','Required parameter requestParameters.routePlannerRequestBody was null or undefined when calling ptvToll.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/toll/ptv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePlannerRequestBodyToJSON(requestParameters.routePlannerRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async ptvToll(requestParameters: PtvTollRequest): Promise<InlineResponse200> {
        const response = await this.ptvTollRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ptvTollByRouteIdsRaw(requestParameters: PtvTollByRouteIdsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.tollByRouteIdsRequestBody === null || requestParameters.tollByRouteIdsRequestBody === undefined) {
            throw new runtime.RequiredError('tollByRouteIdsRequestBody','Required parameter requestParameters.tollByRouteIdsRequestBody was null or undefined when calling ptvTollByRouteIds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/tollByRouteIds/ptv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TollByRouteIdsRequestBodyToJSON(requestParameters.tollByRouteIdsRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async ptvTollByRouteIds(requestParameters: PtvTollByRouteIdsRequest): Promise<InlineResponse200> {
        const response = await this.ptvTollByRouteIdsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async roadLordTollCostsRaw(requestParameters: RoadLordTollCostsRequest): Promise<runtime.ApiResponse<Array<RouteCostsDetails>>> {
        if (requestParameters.tollCostRequestBody === null || requestParameters.tollCostRequestBody === undefined) {
            throw new runtime.RequiredError('tollCostRequestBody','Required parameter requestParameters.tollCostRequestBody was null or undefined when calling roadLordTollCosts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/route/road-lord-toll-costs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tollCostRequestBody.map(TollCostRequestBodyToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RouteCostsDetailsFromJSON));
    }

    /**
     */
    async roadLordTollCosts(requestParameters: RoadLordTollCostsRequest): Promise<Array<RouteCostsDetails>> {
        const response = await this.roadLordTollCostsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sygicAuthRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/route/sygic-core/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async sygicAuth(): Promise<object> {
        const response = await this.sygicAuthRaw();
        return await response.value();
    }

    /**
     */
    async sygicCoreDirectionsRaw(requestParameters: SygicCoreDirectionsRequest): Promise<runtime.ApiResponse<Array<PlannedTransport>>> {
        if (requestParameters.routePlannerRequestBody === null || requestParameters.routePlannerRequestBody === undefined) {
            throw new runtime.RequiredError('routePlannerRequestBody','Required parameter requestParameters.routePlannerRequestBody was null or undefined when calling sygicCoreDirections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/route/sygic-core/plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePlannerRequestBodyToJSON(requestParameters.routePlannerRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlannedTransportFromJSON));
    }

    /**
     */
    async sygicCoreDirections(requestParameters: SygicCoreDirectionsRequest): Promise<Array<PlannedTransport>> {
        const response = await this.sygicCoreDirectionsRaw(requestParameters);
        return await response.value();
    }

}
