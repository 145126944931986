import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Select } from 'common/components/Form';
import { SelectOption } from 'common/components/Form/Select';

export interface ChartCompareModel {
    xIndex: number;
    xVal: string;
    yVal?: number;
    yLine: 'main' | 'compared';
}

export enum DriverBehaviorCompare {}
// ALLCLIENTSBEST = 'allClientsBest',
// ALLCLIENTSAVERAGE = 'allClientsAverage',
// COMPANYAVERAGE = 'companyAverage',
// COMPANYBEST = 'companyBest'

export enum ScoreTrend {
    LAST12MONTHS = 'last12Months'
}

interface Props {
    label?: string;
    useScore: boolean;
    drivers: {
        value: string;
        label: string;
    }[];
    width?: number;
    mainId: string;
    comparedDriverId?: string;
    color?: string;
    onCompareUsersChange?: () => void;
    onScoreTrendChange?: () => void;
}

export default function DriverBehaviorChartBar(props: Props) {
    const { t } = useTranslation();

    function scoreTrendOptions(): SelectOption[] {
        return Object.values(ScoreTrend).map(value => {
            return {
                label: t(`DriverBehaviorCharts.${value}`),
                value: value
            };
        });
    }

    return (
        <div className="driver-behavior-chart-bar" style={{ width: props.width ? `${props.width}px` : undefined }}>
            {props.label && (
                <Row justify="center">
                    <Col className="driver-behavior-chart-bar-label">{props.label}</Col>
                </Row>
            )}
            <Row justify={props.useScore ? 'space-between' : 'end'}>
                {props.useScore && (
                    <Col className="driver-behavior-chart-bar-dates">
                        <Row align="middle" gutter={16}>
                            <Col>
                                <label>{t('DriverBehaviorCharts.scoreTrend')}</label>
                            </Col>
                            <Col>
                                <Select
                                    className="driver-behavior-chart-bar-dates-select"
                                    options={scoreTrendOptions()}
                                    size="large"
                                    showArrow={true}
                                    placeholder={t('DriverBehaviorCharts.selectOptions')}
                                    value={ScoreTrend.LAST12MONTHS}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
                {props.comparedDriverId && (
                    <Col className="driver-behavior-chart-bar-legend">
                        <Row justify="space-between" gutter={32}>
                            <Col>
                                <span
                                    className="driver-behavior-chart-bar-legend-line driver-behavior-chart-bar-legend-line-solid"
                                    style={{ borderColor: props.color }}
                                />
                                {props.drivers?.find(d => d.value === props.mainId)?.label ?? ''}
                            </Col>
                            <Col className="driver-behavior-chart-bar-legend-separator">vs</Col>
                            <Col>
                                <span
                                    className="driver-behavior-chart-bar-legend-line driver-behavior-chart-bar-legend-line-dashed"
                                    style={{ borderColor: props.color }}
                                />
                                {props.drivers?.find(d => d.value === props.comparedDriverId)?.label ?? ''}
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
}
