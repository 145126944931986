/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyJSONWebTokenSerializerTelematics
 */
export interface WriteOnlyJSONWebTokenSerializerTelematics {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyJSONWebTokenSerializerTelematics
     */
    ssoId: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyJSONWebTokenSerializerTelematics
     */
    password: string;
}

export function WriteOnlyJSONWebTokenSerializerTelematicsFromJSON(json: any): WriteOnlyJSONWebTokenSerializerTelematics {
    return WriteOnlyJSONWebTokenSerializerTelematicsFromJSONTyped(json, false);
}

export function WriteOnlyJSONWebTokenSerializerTelematicsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyJSONWebTokenSerializerTelematics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ssoId': json['sso_id'],
        'password': json['password'],
    };
}

export function WriteOnlyJSONWebTokenSerializerTelematicsToJSON(value?: WriteOnlyJSONWebTokenSerializerTelematics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sso_id': value.ssoId,
        'password': value.password,
    };
}


