import { WithTranslation, withTranslation } from 'react-i18next';

import { TachoCardModel, TachoCardsLinks, UnpairType } from '../TachoCardsModule';
import LinkedItem, { LinkedItemType } from 'common/components/LinkedItem';
import { Role } from 'logic/auth';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    model?: TachoCardModel;
    demoMode?: boolean;
    roles: Role[];
    onUnpair?: (id: UnpairType, type: keyof TachoCardsLinks) => void;
}

function TachoCardsLinkedItems(props: Props) {
    function onUnpairButtonClick(model: UnpairType, type: keyof TachoCardsLinks): void {
        props.onUnpair?.(model, type);
    }

    const { t, model } = props;

    return (
        <>
            {model?.links &&
                Object.keys(model.links).map(
                    (key, i) =>
                        model.links?.[key as keyof TachoCardsLinks] && (
                            <div key={i}>
                                <LinkedItem
                                    parentId={String(model.id)}
                                    disabled={props.demoMode || !props.roles.includes(Role.TC_IE)}
                                    type={LinkedItemType.USER}
                                    typeName={t('common.user')}
                                    heading={model.links?.user?.name ?? ''}
                                    items={[
                                        {
                                            label: t(`common.name`),
                                            value: model.links?.user?.name ?? '',
                                            qa: qa.tacho.linkedItems.fieldName
                                        }
                                    ]}
                                    qa={qa.tacho.linkedItems.sectionUser}
                                    onUnpairClick={onUnpairButtonClick.bind(undefined, model, 'user')}
                                />
                            </div>
                        )
                )}
        </>
    );
}

export default withTranslation()(TachoCardsLinkedItems);
