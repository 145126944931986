/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChannelUsersPatch
 */
export interface ChannelUsersPatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelUsersPatch
     */
    insert?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChannelUsersPatch
     */
    remove?: Array<string> | null;
}

export function ChannelUsersPatchFromJSON(json: any): ChannelUsersPatch {
    return ChannelUsersPatchFromJSONTyped(json, false);
}

export function ChannelUsersPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelUsersPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insert': !exists(json, 'insert') ? undefined : json['insert'],
        'remove': !exists(json, 'remove') ? undefined : json['remove'],
    };
}

export function ChannelUsersPatchToJSON(value?: ChannelUsersPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insert': value.insert,
        'remove': value.remove,
    };
}


