/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringDeviceBinaryRightInput
 */
export interface MonitoringDeviceBinaryRightInput {
    /**
     * 
     * @type {string}
     * @memberof MonitoringDeviceBinaryRightInput
     */
    binaryRights: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDeviceBinaryRightInput
     */
    actionIds: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MonitoringDeviceBinaryRightInput
     */
    metadata: { [key: string]: string; };
}

export function MonitoringDeviceBinaryRightInputFromJSON(json: any): MonitoringDeviceBinaryRightInput {
    return MonitoringDeviceBinaryRightInputFromJSONTyped(json, false);
}

export function MonitoringDeviceBinaryRightInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringDeviceBinaryRightInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binaryRights': json['binary_rights'],
        'actionIds': json['action_ids'],
        'metadata': json['metadata'],
    };
}

export function MonitoringDeviceBinaryRightInputToJSON(value?: MonitoringDeviceBinaryRightInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binary_rights': value.binaryRights,
        'action_ids': value.actionIds,
        'metadata': value.metadata,
    };
}


