/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransportEventRuleConfig
 */
export interface TransportEventRuleConfig {
    /**
     * 
     * @type {string}
     * @memberof TransportEventRuleConfig
     */
    name: string;
    /**
     * 
     * @type {number | string | object}
     * @memberof TransportEventRuleConfig
     */
    value?: number | string | object;
}

export function TransportEventRuleConfigFromJSON(json: any): TransportEventRuleConfig {
    return TransportEventRuleConfigFromJSONTyped(json, false);
}

export function TransportEventRuleConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportEventRuleConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': !exists(json, 'value') ? undefined : json['value']
    };
}

export function TransportEventRuleConfigToJSON(value?: TransportEventRuleConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value 
    };
}


