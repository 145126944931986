/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyClient
 */
export interface WriteOnlyClient {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyClient
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    identificationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    deleted?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    address?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    fieldOfActivity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    billingCode?: string | null;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    correspondenceAddress?: object | null;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    contact?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    emailForInvocing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    taxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    bankingConnection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    timeZone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyClient
     */
    isPartner?: boolean;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    styles?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    logo?: string | null;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    metadata?: object;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyClient
     */
    costPerKm?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    fleetType?: WriteOnlyClientFleetTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyClient
     */
    forwardedToDff?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyClient
     */
    lastSyncWithDff?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    dffSyncTraceback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyClient
     */
    mamId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyClient
     */
    ewOffice?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyClient
     */
    externalSystem?: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyClient
     */
    referrer?: number | null;
}

export function WriteOnlyClientFromJSON(json: any): WriteOnlyClient {
    return WriteOnlyClientFromJSONTyped(json, false);
}

export function WriteOnlyClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'identificationNumber': !exists(json, 'identification_number') ? undefined : json['identification_number'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'fieldOfActivity': !exists(json, 'field_of_activity') ? undefined : json['field_of_activity'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'billingCode': !exists(json, 'billing_code') ? undefined : json['billing_code'],
        'correspondenceAddress': !exists(json, 'correspondence_address') ? undefined : json['correspondence_address'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'emailForInvocing': !exists(json, 'email_for_invocing') ? undefined : json['email_for_invocing'],
        'vatNumber': !exists(json, 'vat_number') ? undefined : json['vat_number'],
        'taxNumber': !exists(json, 'tax_number') ? undefined : json['tax_number'],
        'bankingConnection': !exists(json, 'banking_connection') ? undefined : json['banking_connection'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'isPartner': !exists(json, 'is_partner') ? undefined : json['is_partner'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'fleetType': !exists(json, 'fleet_type') ? undefined : json['fleet_type'],
        'forwardedToDff': !exists(json, 'forwarded_to_dff') ? undefined : json['forwarded_to_dff'],
        'lastSyncWithDff': !exists(json, 'last_sync_with_dff') ? undefined : (json['last_sync_with_dff'] === null ? null : new Date(json['last_sync_with_dff'])),
        'dffSyncTraceback': !exists(json, 'dff_sync_traceback') ? undefined : json['dff_sync_traceback'],
        'mamId': !exists(json, 'mam_id') ? undefined : json['mam_id'],
        'ewOffice': !exists(json, 'ew_office') ? undefined : json['ew_office'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
    };
}

export function WriteOnlyClientToJSON(value?: WriteOnlyClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_id': value.countryId,
        'external_id': value.externalId,
        'identification_number': value.identificationNumber,
        'deleted': value.deleted,
        'name': value.name,
        'address': value.address,
        'field_of_activity': value.fieldOfActivity,
        'language': value.language,
        'currency': value.currency,
        'billing_code': value.billingCode,
        'correspondence_address': value.correspondenceAddress,
        'contact': value.contact,
        'email_for_invocing': value.emailForInvocing,
        'vat_number': value.vatNumber,
        'tax_number': value.taxNumber,
        'banking_connection': value.bankingConnection,
        'time_zone': value.timeZone,
        'language_iso_639_1': value.languageIso6391,
        'is_partner': value.isPartner,
        'styles': value.styles,
        'logo': value.logo,
        'metadata': value.metadata,
        'cost_per_km': value.costPerKm,
        'fleet_type': value.fleetType,
        'forwarded_to_dff': value.forwardedToDff,
        'last_sync_with_dff': value.lastSyncWithDff === undefined ? undefined : (value.lastSyncWithDff === null ? null : value.lastSyncWithDff.toISOString()),
        'dff_sync_traceback': value.dffSyncTraceback,
        'mam_id': value.mamId,
        'ew_office': value.ewOffice,
        'external_system': value.externalSystem,
        'referrer': value.referrer,
    };
}

/**
* @export
* @enum {string}
*/
export enum WriteOnlyClientFleetTypeEnum {
    Trucks = 'trucks',
    LightVehicles = 'light_vehicles',
    Mixed = 'mixed'
}


