/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MaintenanceNotificationInDb,
    MaintenanceNotificationInDbFromJSON,
    MaintenanceNotificationInDbToJSON,
} from '../models';

export interface AcknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePostRequest {
    notificationId: string;
}

export interface GetMaintenanceNotificationV1MaintenanceNotificationMyGetRequest {
    subscribeDevice?: string;
    subscribeUser?: string;
}

/**
 * no description
 */
export class MaintenanceApi extends runtime.BaseAPI {

    /**
     * Acknowledge
     */
    async acknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePostRaw(requestParameters: AcknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling acknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/maintenance-notification/{notification_id}/acknowledge`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Acknowledge
     */
    async acknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePost(requestParameters: AcknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePostRequest): Promise<object> {
        const response = await this.acknowledgeV1MaintenanceNotificationNotificationIdAcknowledgePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Maintenance Notification
     */
    async getMaintenanceNotificationV1MaintenanceNotificationMyGetRaw(requestParameters: GetMaintenanceNotificationV1MaintenanceNotificationMyGetRequest): Promise<runtime.ApiResponse<Array<MaintenanceNotificationInDb>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/maintenance-notification/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaintenanceNotificationInDbFromJSON));
    }

    /**
     * Get Maintenance Notification
     */
    async getMaintenanceNotificationV1MaintenanceNotificationMyGet(requestParameters: GetMaintenanceNotificationV1MaintenanceNotificationMyGetRequest): Promise<Array<MaintenanceNotificationInDb>> {
        const response = await this.getMaintenanceNotificationV1MaintenanceNotificationMyGetRaw(requestParameters);
        return await response.value();
    }

}
