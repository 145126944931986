import { Alarm } from 'common/model/alarm';
import { AlarmType, TransportPlaceTask, TransportPlaceTaskType } from 'generated/backend-api';
import moment from 'moment';
import { pois } from './pois';
import { vehicles } from './vehicles';

const beforeTwentyMinutes = new Date(new Date().setMinutes(new Date().getMinutes() - 20));

const getDates = () => {
    const times = [
        [12, 31],
        [12, 43]
    ];

    return times.map(time => {
        const [hours, minutes] = time;
        return new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(hours, minutes));
    });
};

const dates = getDates();

export const alarms: Alarm[] = [
    {
        alarmType: AlarmType.PoiArrival,
        start: dates[0],
        monitoredObjectId: String(vehicles[0].id),
        clientId: vehicles[0].clientId,
        end: dates[0],
        canceled: false,
        lastGpsPointStartObj: {
            time: dates[0],
            lat: 41.464597,
            lng: 2.189833
        },
        lastGpsPointEndObj: {
            time: dates[0],
            lat: 41.464597,
            lng: 2.189833
        },
        alarmId: '1',
        localizedAddress: pois[4].address ?? '',
        startDateTime: moment.utc(dates[0]).format('L LT'),
        endDateTime: moment.utc(dates[0]).format('L LT'),
        registrationNumber: vehicles[0].registrationNumber
    },
    {
        alarmType: AlarmType.PoiDeparture,
        start: dates[1],
        monitoredObjectId: String(vehicles[0].id),
        clientId: vehicles[0].clientId,
        end: dates[1],
        canceled: false,
        lastGpsPointStartObj: {
            time: dates[1],
            lat: 41.464597,
            lng: 2.190833
        },
        lastGpsPointEndObj: {
            time: dates[1],
            lat: 41.464597,
            lng: 2.190833
        },
        alarmId: '2',
        localizedAddress: pois[4].address ?? '',
        startDateTime: moment.utc(dates[1]).format('L LT'),
        endDateTime: moment.utc(dates[1]).format('L LT'),
        registrationNumber: vehicles[0].registrationNumber
    },
    {
        alarmType: AlarmType.UnavailableGps,
        start: beforeTwentyMinutes,
        monitoredObjectId: String(vehicles[7].id),
        clientId: vehicles[7].clientId,
        end: beforeTwentyMinutes,
        canceled: false,
        lastGpsPointStartObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        lastGpsPointEndObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        alarmId: '10',
        localizedAddress: '45.818572 13.029482',
        startDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        endDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        registrationNumber: vehicles[7].registrationNumber
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureHigh,
        start: beforeTwentyMinutes,
        monitoredObjectId: String(vehicles[1].id),
        clientId: vehicles[1].clientId,
        end: beforeTwentyMinutes,
        canceled: false,
        acknowledged: true,
        lastGpsPointStartObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        lastGpsPointEndObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        alarmId: '11',
        localizedAddress: '45.818572 13.029482',
        startDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        endDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        registrationNumber: vehicles[1].registrationNumber,
        uniqueData: {
            transport_id: '64a4132ec29b6c2c17c4c7c7',
            place_id: '_1rrrtg7a7',
            temperature_sensor_serial_number: 'Thermoking 7',
            temperature_sensor_zone: 1,
            temperature_sensor_device_id: 'bedc5742-105a-4031-9db6-d44621a952f7',
            cold_chain_profile: {
                profile_id: '6ec823ca-9d60-446b-bf6e-3cd560a5dc1d',
                above_temperature_threshold: 2.4,
                below_temperature_threshold: -1,
                alarm_timer_seconds: null
            }
        }
    },
    {
        alarmType: AlarmType.TransportColdChainProfileTemperatureHigh,
        start: beforeTwentyMinutes,
        monitoredObjectId: String(vehicles[1].id),
        clientId: vehicles[1].clientId,
        end: beforeTwentyMinutes,
        canceled: false,
        lastGpsPointStartObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        lastGpsPointEndObj: {
            time: beforeTwentyMinutes,
            lat: 45.818572,
            lng: 13.029482
        },
        alarmId: '13',
        localizedAddress: '45.818572 13.029482',
        startDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        endDateTime: moment.utc(beforeTwentyMinutes).format('L LT'),
        registrationNumber: vehicles[1].registrationNumber,
        uniqueData: {
            transport_id: '64a4132ec29b6c2c17c4c7c7',
            place_id: '_1rrrtg7a7',
            temperature_sensor_serial_number: 'Thermoking 5',
            temperature_sensor_zone: 1,
            temperature_sensor_device_id: 'bedc5742-105a-4031-9db6-d44621a952f7',
            cold_chain_profile: {
                profile_id: '6ec823ca-9d60-446b-bf6e-3cd560a5dc1d',
                above_temperature_threshold: 2.3,
                below_temperature_threshold: -1,
                alarm_timer_seconds: null
            }
        }
    }
];

export const transportTasks: TransportPlaceTask[] = [
    {
        id: '1',
        type: TransportPlaceTaskType.Loading
    },
    {
        id: '2',
        type: TransportPlaceTaskType.Unloading
    },
    {
        id: '3',
        type: TransportPlaceTaskType.Refueling
    },
    {
        id: '4',
        type: TransportPlaceTaskType.Parking
    }
];

export const alarmsByPlace = {
    [TransportPlaceTaskType.Loading]: [
        { name: 'transport_poi_close', config: [{ name: 'duration', value: 1 }] },
        { name: 'transport_departure', config: [{ name: 'duration', value: 1 }] },
        { name: 'transport_arrival', config: [{ name: 'duration', value: 1 }] }
    ],
    [TransportPlaceTaskType.Unloading]: [
        { name: 'transport_corridor_leave', config: [{ name: 'distance', value: 1 }] },
        { name: 'transport_poi_close', config: [{ name: 'duration', value: 1 }] },
        { name: 'transport_departure', config: [{ name: 'duration', value: 1 }] },
        { name: 'transport_arrival', config: [{ name: 'duration', value: 1 }] }
    ]
};
