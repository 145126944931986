import { Component } from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { message, Row, Col } from 'antd';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { RouteNames, rbac } from 'App';
import { Logic } from 'logic/logic';
import { Role } from 'logic/auth';

import { DriverBehaviorModel } from 'common/model/statistics';
import DriverBehaviorList from './List';
import DriverBehaviorDetail, { DriverBehaviourRouteParams } from './Detail';
import DriverLeaderboardTrendsTable, {
    DriverLeaderboardTrendsTableFilter
} from 'modules/statistics/modules/driver-behavior-coach/components/DriverLeaderboardTrendsTable';
import { LayoutContent } from 'common/components/Layout/Content';
import qa from 'qa-selectors';
import DriversLeaderboardCard from 'modules/statistics/modules/driver-behavior-coach/components/DriversLeaderboardCard';
import { TrendChange } from 'common/components/Trend';
import { getIncreaseDirection, getIncreasePercent } from 'common/utils/components/Trends';
import { NoData } from 'common/components/NoData';
import UiTabBar from 'common/components/UiTabBar/UiTabBar';
import { capitalizeFirstLetter } from 'common/utils/textUtils';
import { DriverBehaviorNavTabs } from '../../components/DriverDetailNav';
import { calendar } from 'resources/images/driver-behavior';
import { DriverBehaviourContextualPeriod } from 'generated/backend-api';
import { DATE_FORMAT } from 'domain-constants';
import qs from 'qs';
import { Theme } from 'common/components/Settings';

export enum DriverBehaviorTrendTabs {
    Drivers = 'drivers',
    Improvers = 'improvers',
    WorstDrivers = 'worstDrivers'
}
interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
    theme: Theme;
}

interface State {
    data: DriverBehaviorModel[];
    leaderFilter: DriverLeaderboardTrendsTableFilter;
    loading: boolean;
    currentContextualData?: DriverBehaviourContextualPeriod[];
    selectedTrendTab: DriverBehaviorTrendTabs;
    selectedDetailTab: DriverBehaviorNavTabs;
}

class DriverBehaviorModule extends Component<Props, State> {
    subscriptionData?: Subscription;
    subscriptionLoading?: Subscription;
    subscriptionError?: Subscription;
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        this.state = {
            data: [],
            loading: true,
            leaderFilter: DriverLeaderboardTrendsTableFilter.Overal,
            selectedTrendTab: DriverBehaviorTrendTabs.Drivers,
            selectedDetailTab: DriverBehaviorNavTabs.basic
        };
    }

    componentDidMount() {
        this.subscriptionData = this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .data.subscribe(data => {
                this.setState({ data: data, loading: false });
            });

        this.subscriptionLoading = this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .loading.subscribe(loading => {
                this.setState({ loading });
            });

        this.subscriptionError = this.props.logic
            .driverBehaviorCoach()
            .trucks()
            .error.subscribe(error => {
                console.error(`Load data error, err: ${error}`);
                message.error(this.props.t('common.error.loadDataError'));
            });
        this.props.logic.settings().setProp('driverBehavior', { date: moment().utc().startOf('month').toISOString() });
        this.props.logic.driverBehaviorCoach().trucks().loadCurrentScoreData();
        this.props.logic
            .vehicles()
            .getMonitoredObjectFilters(true)
            .then(data => {
                this.props.logic.driverBehaviorCoach().trucks().setVehicles(data);
                this.props.logic
                    .driverBehaviorCoach()
                    .trucks()
                    .loadPerfectDriveScoreData(
                        this.props.logic.driverBehaviorCoach().trucks().getDate() ??
                            moment.utc().startOf('month').toISOString()
                    );
            });
    }

    componentDidUpdate(): void {
        const params: DriverBehaviourRouteParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (params.date && this.props.location.pathname === RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST) {
            this.props.logic
                .settings()
                .setProp('driverBehavior', { date: moment(params.date).utc().startOf('month').toISOString() });
        }
    }

    componentWillUnmount() {
        this.subscriptionData?.unsubscribe();
        this.subscriptionLoading?.unsubscribe();
        this.subscriptionError?.unsubscribe();
    }

    handlerTrendTabClick = (tab: string) => {
        this.setState({
            selectedTrendTab: DriverBehaviorTrendTabs[capitalizeFirstLetter(tab)]
        });
    };

    render() {
        const roles = this._logic.auth().roles();
        const { t } = this.props;

        const contextualData = this._logic.driverBehaviorCoach().trucks().getContextualData();
        const date = moment().format('YYYY-MM');
        const current = contextualData?.find(d => d.start === date);
        const previous = contextualData?.find(d => d.start === moment(date).subtract(1, 'month').format('YYYY-MM'));

        const trendTabs = [
            { key: DriverBehaviorTrendTabs.Drivers, label: t('DriverBehavior.topDrivers') },
            { key: DriverBehaviorTrendTabs.Improvers, label: t('DriverBehavior.topImprovers') },
            { key: DriverBehaviorTrendTabs.WorstDrivers, label: t('DriverBehavior.worstDrivers') }
        ];

        return (
            <LayoutContent
                className="driver-behavior"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <Switch>
                        <Route
                            exact
                            path={RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST}
                            render={rbac(
                                roles,
                                [Role.DBH_R],
                                <DriverBehaviorList logic={this._logic} leaderFilter={this.state.leaderFilter} />
                            )}
                        />
                        <Route
                            exact
                            path={`${RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL}/:id`}
                            render={rbac(
                                roles,
                                [Role.DBH_R],
                                <DriverBehaviorDetail
                                    logic={this._logic}
                                    selectedTab={this.state.selectedDetailTab}
                                    onDetailTabChange={this._onDetailTabChange}
                                    onBackButtonClick={this._onBackButtonClick}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        <Redirect to={RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST} />
                    </Switch>
                }
                extra={
                    this.state.selectedDetailTab !== DriverBehaviorNavTabs.coach
                        ? [
                              current ? (
                                  <div className="driver-behavior-panel">
                                      <Row align="middle" justify="center" gutter={8}>
                                          <Row
                                              className="driver-behavior-panel-calendar"
                                              align="middle"
                                              justify="center"
                                          >
                                              <img src={calendar} alt="calendar" />
                                              <span>{moment(current.start).format('MMMM YYYY')}</span>
                                          </Row>
                                      </Row>
                                      <Row gutter={8}>
                                          <Col span={8}>
                                              <DriversLeaderboardCard
                                                  type="fleetVehicles"
                                                  value={Math.round(current.clientAvgDriverOveralScore)}
                                                  trend={
                                                      previous && {
                                                          direction: getIncreaseDirection(
                                                              current.clientAvgDriverOveralScore,
                                                              previous.clientAvgDriverOveralScore
                                                          ),
                                                          value: getIncreasePercent(
                                                              current.clientAvgDriverOveralScore,
                                                              previous.clientAvgDriverOveralScore
                                                          ),
                                                          trendChangeForIncrease: TrendChange.POSITIVE
                                                      }
                                                  }
                                                  theme={this.props.theme}
                                              />
                                          </Col>
                                          <Col span={8}>
                                              <DriversLeaderboardCard
                                                  type="ewVehicles"
                                                  value={Math.round(current.crossSystemAvgDriverOveralScore)}
                                                  trend={
                                                      previous && {
                                                          direction: getIncreaseDirection(
                                                              current.crossSystemAvgDriverOveralScore,
                                                              previous.crossSystemAvgDriverOveralScore
                                                          ),
                                                          value: getIncreasePercent(
                                                              current.crossSystemAvgDriverOveralScore,
                                                              previous.crossSystemAvgDriverOveralScore
                                                          ),
                                                          trendChangeForIncrease: TrendChange.POSITIVE
                                                      }
                                                  }
                                                  theme={this.props.theme}
                                              />
                                          </Col>
                                          <Col span={8}>
                                              <DriversLeaderboardCard
                                                  type="ewDriver"
                                                  value={Math.round(current.crossSystemBestDriverOveralScore)}
                                                  trend={
                                                      previous && {
                                                          direction: getIncreaseDirection(
                                                              current.crossSystemBestDriverOveralScore,
                                                              previous.crossSystemBestDriverOveralScore
                                                          ),
                                                          value: getIncreasePercent(
                                                              current.crossSystemBestDriverOveralScore,
                                                              previous.crossSystemBestDriverOveralScore
                                                          ),
                                                          trendChangeForIncrease: TrendChange.POSITIVE
                                                      }
                                                  }
                                                  theme={this.props.theme}
                                              />
                                          </Col>
                                      </Row>

                                      <Row className="driver-behavior-panel-leaders">
                                          <Row>
                                              <span className="driver-behavior-panel-leaders-title">
                                                  {t('DriverBehavior.driverCharts')}
                                              </span>
                                          </Row>
                                          <Row>
                                              <UiTabBar
                                                  className="driver-behavior-panel-leaders-tab-group"
                                                  options={trendTabs}
                                                  selectedOption={String(this.state.selectedTrendTab)}
                                                  onSelect={this.handlerTrendTabClick}
                                              />
                                          </Row>

                                          {this.state.selectedTrendTab === DriverBehaviorTrendTabs.Drivers && (
                                              <DriverLeaderboardTrendsTable
                                                  data={this._logic
                                                      .driverBehaviorCoach()
                                                      .trucks()
                                                      .selectTopDriversTrends(this.state.leaderFilter)}
                                                  leaderFilter={this.state.leaderFilter}
                                                  loading={false}
                                                  showHeader={false}
                                                  showTrend={true}
                                                  onRowClick={this._onTableRowClick}
                                                  onFilterChange={this._onFilterChange}
                                                  className="drivers-leaderboard-table-top-drivers"
                                                  qa={qa.driverBehaviour.side.topDrivers}
                                              />
                                          )}

                                          {this.state.selectedTrendTab === DriverBehaviorTrendTabs.Improvers && (
                                              <DriverLeaderboardTrendsTable
                                                  data={this._logic
                                                      .driverBehaviorCoach()
                                                      .trucks()
                                                      .selectTopImproversDriversTrends(this.state.leaderFilter)}
                                                  leaderFilter={this.state.leaderFilter}
                                                  loading={false}
                                                  showHeader={false}
                                                  showTrend={true}
                                                  onRowClick={this._onTableRowClick}
                                                  onFilterChange={this._onFilterChange}
                                                  qa={qa.driverBehaviour.side.topImprovers}
                                              />
                                          )}

                                          {this.state.selectedTrendTab === DriverBehaviorTrendTabs.WorstDrivers && (
                                              <DriverLeaderboardTrendsTable
                                                  data={this._logic
                                                      .driverBehaviorCoach()
                                                      .trucks()
                                                      .selectWorstDriversTrends(this.state.leaderFilter)}
                                                  leaderFilter={this.state.leaderFilter}
                                                  loading={false}
                                                  showHeader={false}
                                                  showTrend={true}
                                                  onRowClick={this._onTableRowClick}
                                                  onFilterChange={this._onFilterChange}
                                                  qa={qa.driverBehaviour.side.worstDrivers}
                                              />
                                          )}
                                      </Row>
                                  </div>
                              ) : (
                                  <NoData />
                              )
                          ]
                        : undefined
                }
            />
        );
    }

    private _onTableRowClick = (id: string, name?: string) => {
        this.props.logic.driverBehaviorCoach().trucks().setSelectedDriverId(id);
        this.props.logic.driverBehaviorCoach().trucks().setSelectedDriverName(name);
        if (id === this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()) {
            this.props.logic.driverBehaviorCoach().trucks().setComparedDriverId();
        }

        this.props.history.push({
            pathname: RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL + '/' + id,
            search: qs.stringify({
                date: moment().startOf('month').format(DATE_FORMAT),
                comparedDate: this.props.logic.driverBehaviorCoach().trucks().getComparedDate()
                    ? moment(this.props.logic.driverBehaviorCoach().trucks().getComparedDate())
                          .startOf('month')
                          .format(DATE_FORMAT)
                    : undefined,
                comparedDriverId: this.props.logic.driverBehaviorCoach().trucks().getComparedDriverId()
            })
        });
    };

    private _onFilterChange = (value: DriverLeaderboardTrendsTableFilter) => {
        this.setState({
            leaderFilter: value
        });
    };

    private _onDetailTabChange = (tab: DriverBehaviorNavTabs) => {
        this.setState({
            selectedDetailTab: tab
        });
    };

    private _onBackButtonClick = () => {
        this._onDetailTabChange(DriverBehaviorNavTabs.basic);
    };
}

export default withRouter(withTranslation()(DriverBehaviorModule));
