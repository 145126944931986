import { Tooltip } from 'common/components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as icons from 'resources/images/dispatcher-board';

interface Props {
    children?: ReactNode;
}

const DFFJitPayExplanationTooltip = (props: Props) => {
    const { t } = useTranslation();

    const content = (
        <div className="dff-jit-pay-tooltip">
            <div className="dff-jit-pay-tooltip-background">
                <img src={icons.jitPayLogoBackground} height={192} alt="JitPay" />
            </div>
            <div className="dff-jit-pay-tooltip-logo">
                <img src={icons.jitPayLogo} height={78} alt="JitPay" />
            </div>
            <div className="dff-jit-pay-tooltip-title">
                <span>{t('JitPayExplanationTooltip.title')}</span>
            </div>
            <div className="dff-jit-pay-tooltip-item">
                <i className="fa fa-check-circle" />
                <span>{t('JitPayExplanationTooltip.item1')}</span>
            </div>
            <div className="dff-jit-pay-tooltip-item">
                <i className="fa fa-check-circle" />
                <span>{t('JitPayExplanationTooltip.item2')}</span>
            </div>
            <div className="dff-jit-pay-tooltip-item">
                <i className="fa fa-check-circle" />
                <span>{t('JitPayExplanationTooltip.item3')}</span>
            </div>
        </div>
    );

    return (
        <Tooltip overlay={content} overlayClassName={'dff-jit-pay-tooltip'}>
            {props?.children}
        </Tooltip>
    );
};

export default DFFJitPayExplanationTooltip;
