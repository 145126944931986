import { Component, ReactNode } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';
import { Button, Typography } from 'common/components';
import Collapse from 'common/components/Collapse';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { steeringWheel } from 'resources/images/common';
import { obu, users, fuelCards, tachographCards, vehicles, partners } from 'resources/images/sidebar';
import { trailerDynamic, trailer } from 'resources/images/vehicle-detail';

interface LinkedItemOpt {
    label: string;
    value: string;
    customName?: string;
    qa?: string;
    ellipses?: boolean;
}

export enum LinkedItemType {
    OBU = 'obu',
    VEHICLE = 'vehicle',
    LIGHT_VEHICLE = 'vehicle',
    PARTNER = 'partner',
    TRAILER = 'trailer',
    TRAILER_DYNAMIC = 'trailerDynamic',
    FLEET = 'fleet',
    FUEL_CARD = 'fuel-card',
    TACHOGRAPH_CARD = 'tachograph-card',
    EW_FLEET_DRIVER = 'ew-fleet-driver',
    USER = 'user',
    DEFAULT_DRIVER = 'default-driver',
    DEFAULT_VEHICLE = 'default-vehicle',
    APP = 'app'
}

interface Props extends WithTranslation {
    key?: string;
    parentId: string;
    typeName: string;
    heading?: ReactNode;
    type?: LinkedItemType;
    items?: LinkedItemOpt[];
    unpairHide?: boolean;
    disabled?: boolean;
    qa?: string;
    unpairLoading?: boolean;
    onUnpairClick?: () => void;
}

interface State {
    expand: boolean;
}

class LinkedItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expand: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.parentId !== this.props.parentId) {
            this.setState({
                expand: false
            });
        }
    }

    render() {
        return (
            <div className="linked-item" data-qa={this.props.qa} key={this.props.key}>
                <Collapse
                    activeKey={[this.state.expand ? '1' : '']}
                    expandIconPosition="right"
                    bordered={true}
                    onChange={() => this._setExpand()}
                >
                    <Collapse.Panel
                        key={'1'}
                        header={
                            this.state.expand ? (
                                <Row justify="space-between" style={{ width: 'calc(100% - 30px)' }}>
                                    <Col>
                                        <Row gutter={10}>
                                            {this.props.type && (
                                                <Col>
                                                    <img src={this._getIcon()} alt={this.props.type} />
                                                </Col>
                                            )}
                                            <Col>
                                                <h3 data-qa={qa.common.linkedItem.fieldName}>{this.props.typeName}</h3>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {!this.props.unpairHide && (
                                        <Col>
                                            <Button
                                                type="default"
                                                qa={qa.common.linkedItem.btnUnpair}
                                                loading={this.props.unpairLoading}
                                                onClick={this.props.onUnpairClick}
                                                className="linked-item-unpair"
                                                disabled={this.props.disabled}
                                            >
                                                {this.props.t('common.unpair')}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            ) : (
                                <Row gutter={10}>
                                    {this.props.type && (
                                        <Col>
                                            <img src={this._getIcon()} alt={this.props.type} />
                                        </Col>
                                    )}
                                    <Col>
                                        <h3 data-qa={qa.common.linkedItem.fieldType}>{this.props.typeName}</h3>
                                    </Col>
                                    {this.props.heading && (
                                        <Col className="linked-item-heading" data-qa={qa.common.linkedItem.fieldName}>
                                            <Typography.Text
                                                className="linked-item-text-ellipsis"
                                                ellipsis={{ tooltip: this.props.heading }}
                                            >
                                                {this.props.heading}
                                            </Typography.Text>
                                        </Col>
                                    )}
                                </Row>
                            )
                        }
                    >
                        {this.props.items?.map((d, j) => (
                            <Row key={j} className="linked-item-row" gutter={[10, 0]}>
                                <Col className="linked-item-row-label">{d.label}:</Col>
                                <Col
                                    className={cn('linked-item-row-value', {
                                        'linked-item-row-value-ellipsis': d.ellipses
                                    })}
                                    data-qa={d.qa ? d.qa : qa.common.linkedItem.fieldItem}
                                >
                                    {d.ellipses ? (
                                        <Typography.Text
                                            className="linked-item-text-ellipsis"
                                            ellipsis={{ tooltip: d.customName ?? d.value }}
                                        >
                                            {d.customName ?? d.value}
                                        </Typography.Text>
                                    ) : (
                                        d.customName ?? d.value
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }

    private _setExpand = () => {
        this.setState({
            expand: !this.state.expand
        });
    };

    private _getIcon() {
        switch (this.props.type) {
            case LinkedItemType.DEFAULT_DRIVER:
                return steeringWheel;
            case LinkedItemType.TRAILER:
                return trailer;
            case LinkedItemType.TRAILER_DYNAMIC:
                return trailerDynamic;
            case LinkedItemType.DEFAULT_VEHICLE:
            case LinkedItemType.PARTNER:
                return partners;
            case LinkedItemType.VEHICLE:
                return vehicles;
            case LinkedItemType.OBU:
                return obu;
            case LinkedItemType.USER:
                return users;
            case LinkedItemType.TACHOGRAPH_CARD:
                return tachographCards;
            case LinkedItemType.FUEL_CARD:
                return fuelCards;
            default:
                return undefined;
        }
    }
}
export default withTranslation()(LinkedItem);
