import { useEffect } from 'react';

const ActiveDot = (props: any) => {
    const { cx, cy, payload, onActiveDotMount, strokeColor, index } = props;
    useEffect(() => {
        onActiveDotMount?.(payload, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <svg x={cx - 6} y={cy - 6} width="12" height="12" viewBox="0 0 12 12" fill="white">
            <circle cx="6" cy="6" r="5" fill="white" stroke={strokeColor} strokeWidth="2" />
        </svg>
    );
};

export default ActiveDot;
