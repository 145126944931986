/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum LoadType {
    Pallets = 'pallets',
    Boxes = 'boxes',
    Rolls = 'rolls',
    Barrel = 'barrel',
    OversizedCargo = 'oversized_cargo',
    Containter2040 = 'containter20_40',
    Other = 'other'
}

export function LoadTypeFromJSON(json: any): LoadType {
    return LoadTypeFromJSONTyped(json, false);
}

export function LoadTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadType {
    return json as LoadType;
}

export function LoadTypeToJSON(value?: LoadType | null): any {
    return value as any;
}

