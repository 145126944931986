import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { PartnerUserModel } from 'logic/partner/logic/partner-user';
import { StringValidator, FormValidator } from 'validators';

export interface UserForm {
    id: string;
    ssoId: string;
    name: string;
    nameErr: string;
    surname: string;
    surnameErr: string;
    email: string;
    emailErr: string;
    phoneNumber: string;
    phoneNumberErr: string;
    note: string;
    clientId?: string;
}

export const defaultValidationErrors = {
    nameErr: '',
    surnameErr: '',
    emailErr: '',
    phoneNumberErr: ''
};

export const defaultInputs: PartnerUserModel = {
    id: '',
    ssoId: '',
    name: '',
    surname: ''
};

export const nameValidator = new StringValidator(
    {
        required: true,
        regexp: /^\w+( +\w+)*$/
    },
    { required: 'required' }
);

export const surnameValidator = new StringValidator(
    {
        required: true,
        regexp: /^\w+( +\w+)*$/
    },
    { required: 'required' }
);

export const emailValidator = new StringValidator({
    required: false,
    regexp: /^$|(^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,6})$/
});

export const phoneNumberValidator = new StringValidator({
    required: false,
    regexp: /^$|(?:\+:\d{1,3}|0\d{1,3}|00\d{1,2})/
});

export interface UserFormValidator {
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
}

export const formValidator = new FormValidator<UserFormValidator>()
    .addValidator('name', nameValidator)
    .addValidator('surname', surnameValidator)
    .addValidator('email', emailValidator)
    .addValidator('phoneNumber', phoneNumberValidator);

interface Props extends WithTranslation {
    model: PartnerUserModel;
    clients?: PartnerCompanySelectModel[];
    onFormCancel?: () => void;
    onFormSubmit?: (model: PartnerUserModel) => void;
    onClientSearch?: (text: string) => void;
}

interface State {
    form?: UserForm;
}

class UsersDetail extends Component<Props, State> {
    userModel?: PartnerUserModel;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;

        return (
            <div className="partner-sidepanel">
                <div className="partner-detail t-bar-block t-col t-overflow-y">
                    <form className="t-small panel-detail">
                        <div className="t-bar-block">
                            <div className="t-row">
                                <div className="t-half t-bold">{t('common.name')}</div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">{this.props.model?.name}</span>
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-bold">{t('common.surname')}</div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">{this.props.model?.surname}</span>
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-bold">{t('PartnerUsers.note')}</div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">{this.props.model?.note}</span>
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-bold">{t('common.email')}</div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">
                                        {this.props.model?.contact?.emails?.[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-bold">{t('common.phone')}</div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">
                                        {this.props.model?.contact?.phone_numbers?.[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="t-row">
                                <div className="t-half t-bold" title={t('PartnerUsers.contact')}>
                                    {t('Partner.client')}
                                </div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">{this.props.model?.clientName}</span>
                                </div>
                            </div>
                            <div className="t-row t-hide">
                                <div className="t-half t-bold" title={t('Partner.sso')}>
                                    {t('Partner.sso')}
                                </div>
                                <div className="t-half">
                                    <span className="t-input t-padding-small">{this.props.model?.ssoId}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withTranslation()(UsersDetail);
