import { UserRoleFormModel } from 'common/forms/UserRoleForm';
import { Logic } from '../../logic';

export class UserRoles {
    constructor(private _logic: Logic) {}

    public async getUserRoles() {
        const userRolesResponse = await this._logic.api().userRoleApi.userRoleList({});
        const sortedUserRoles = userRolesResponse.results.sort((a, b) => {
            const aName: string = (a.name ?? '').toLowerCase();
            const bName: string = (b.name ?? '').toLowerCase();
            if (aName > bName) {
                return 1;
            } else if (aName < bName) {
                return -1;
            } else {
                return 0;
            }
        });
        return sortedUserRoles;
    }

    public async createUserRole(roleData: UserRoleFormModel, rights: string[]) {
        try {
            if (!roleData.label) throw new Error(`role data has to be filled, actual data: ${roleData}`);
            return await this._logic.api().userRoleApi.userRoleCreate({
                data: {
                    label: roleData.label,
                    description: roleData.description,
                    userRights: rights,
                    client: this._logic.auth().client()?.id
                }
            });
        } catch (err) {
            console.error('Create user role failed, err: ', err);
            throw err;
        }
    }

    public updateUserRole(roleId: string, roleData: UserRoleFormModel, rights: string[]) {
        try {
            if (!roleData.label) throw new Error(`role data has to be filled, actual data: ${roleData}`);
            return this._logic.api().userRoleApi.userRoleUpdate({
                id: roleId,
                data: {
                    label: roleData.label,
                    description: roleData.description,
                    userRights: rights,
                    client: this._logic.auth().client()?.id
                }
            });
        } catch (err) {
            console.error('Update user role failed, err: ', err);
            throw err;
        }
    }

    public async deleteUserRole(roleId: string) {
        try {
            await this._logic.api().userRoleApi.userRoleDelete({
                id: roleId
            });
        } catch (err) {
            console.error('Delete user role failed, err: ', err);
            throw err;
        }
    }
}
