/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Rate,
    RateFromJSON,
    RateFromJSONTyped,
    RateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ManualRate
 */
export interface ManualRate {
    /**
     * 
     * @type {number}
     * @memberof ManualRate
     */
    byUser: number;
    /**
     * 
     * @type {Date}
     * @memberof ManualRate
     */
    byUserAt: Date;
    /**
     * 
     * @type {Rate}
     * @memberof ManualRate
     */
    domestic?: Rate;
    /**
     * 
     * @type {Rate}
     * @memberof ManualRate
     */
    foreign?: Rate;
    /**
     * 
     * @type {string}
     * @memberof ManualRate
     */
    id: string;
}

export function ManualRateFromJSON(json: any): ManualRate {
    return ManualRateFromJSONTyped(json, false);
}

export function ManualRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'byUser': json['by_user'],
        'byUserAt': (new Date(json['by_user_at'])),
        'domestic': !exists(json, 'domestic') ? undefined : RateFromJSON(json['domestic']),
        'foreign': !exists(json, 'foreign') ? undefined : RateFromJSON(json['foreign']),
        'id': json['id'],
    };
}

export function ManualRateToJSON(value?: ManualRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'by_user': value.byUser,
        'by_user_at': (value.byUserAt.toISOString()),
        'domestic': RateToJSON(value.domestic),
        'foreign': RateToJSON(value.foreign),
        'id': value.id,
    };
}


