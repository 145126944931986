import moment, { Moment } from 'moment';
import { Logic } from 'logic/logic';
import { PairingLink } from 'common/model/partner-pairing';

export interface CcrModel {
    id: string;
    ident: string;
    sn: string;
    createdOn: Moment;
    links?: PairingLink[];
}

export class PartnerCcrLogic {
    constructor(private _logic: Logic) {}

    async pairCcrToCompany(_ccrId: string, _companyId: string): Promise<boolean> {
        return true;
    }

    async getCcrList(_clientId?: string): Promise<CcrModel[]> {
        try {
            // const response = await this._logic.api().ccrApi.ccrList({
            //    client: clientId
            // });
            // return response.results.map(this._toCcr);
            return [].map(this._toCcr);
        } catch (err) {
            console.log('CCR list GET err:', err);
            throw err;
        }
    }

    async deleteCcr(_ccr: CcrModel): Promise<Boolean> {
        try {
            // const response = await this._logic.api().ccrApi.ccrDelete({ id: Number(ccr.id) });
            console.log('delete CCR');
            return true;
        } catch (err) {
            console.log('Ccr DELETE err:', err);
            throw err;
        }
    }

    private _toCcr(_data: any): CcrModel {
        return {
            id: 'ccr-a',
            ident: 'CCR - a',
            sn: '13213213213213213',
            createdOn: moment()
        };
    }
}

export const sampleCcr: CcrModel[] = [
    {
        id: 'ccr-a',
        ident: 'CCR - a',
        sn: '13213213213213213',
        createdOn: moment()
    },
    {
        id: 'ccr-b',
        ident: 'CCR - b',
        sn: '13213213213213213',
        createdOn: moment()
    }
];
