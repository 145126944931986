/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOSettingFloat,
    TCOSettingFloatFromJSON,
    TCOSettingFloatFromJSONTyped,
    TCOSettingFloatToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleSettingsLNG
 */
export interface VehicleSettingsLNG {
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    purchaseCost: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    annualTollCost: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    annualInsuranceCost: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    annualOtherCost: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    annualServiceAndMaintenanceCost: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    fuelPrice: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    fuelAverageConsumption: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    gCo2PerUnitProduction: TCOSettingFloat;
    /**
     * 
     * @type {TCOSettingFloat}
     * @memberof VehicleSettingsLNG
     */
    residualValue: TCOSettingFloat;
}

export function VehicleSettingsLNGFromJSON(json: any): VehicleSettingsLNG {
    return VehicleSettingsLNGFromJSONTyped(json, false);
}

export function VehicleSettingsLNGFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleSettingsLNG {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseCost': TCOSettingFloatFromJSON(json['purchase_cost']),
        'annualTollCost': TCOSettingFloatFromJSON(json['annual_toll_cost']),
        'annualInsuranceCost': TCOSettingFloatFromJSON(json['annual_insurance_cost']),
        'annualOtherCost': TCOSettingFloatFromJSON(json['annual_other_cost']),
        'annualServiceAndMaintenanceCost': TCOSettingFloatFromJSON(json['annual_service_and_maintenance_cost']),
        'fuelPrice': TCOSettingFloatFromJSON(json['fuel_price']),
        'fuelAverageConsumption': TCOSettingFloatFromJSON(json['fuel_average_consumption']),
        'gCo2PerUnitProduction': TCOSettingFloatFromJSON(json['g_co2_per_unit_production']),
        'residualValue': TCOSettingFloatFromJSON(json['residual_value']),
    };
}

export function VehicleSettingsLNGToJSON(value?: VehicleSettingsLNG | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchase_cost': TCOSettingFloatToJSON(value.purchaseCost),
        'annual_toll_cost': TCOSettingFloatToJSON(value.annualTollCost),
        'annual_insurance_cost': TCOSettingFloatToJSON(value.annualInsuranceCost),
        'annual_other_cost': TCOSettingFloatToJSON(value.annualOtherCost),
        'annual_service_and_maintenance_cost': TCOSettingFloatToJSON(value.annualServiceAndMaintenanceCost),
        'fuel_price': TCOSettingFloatToJSON(value.fuelPrice),
        'fuel_average_consumption': TCOSettingFloatToJSON(value.fuelAverageConsumption),
        'g_co2_per_unit_production': TCOSettingFloatToJSON(value.gCo2PerUnitProduction),
        'residual_value': TCOSettingFloatToJSON(value.residualValue),
    };
}


