import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { message } from 'antd';
import { debounce } from 'debounce';
import moment, { Moment } from 'moment';
import { DriverCardsRemoteMemory } from './ui/DriverCardsRemoteMemory';
import { Logic } from 'logic/logic';
import { DriverCardsRemoteMemoryModel } from 'logic/management/management-driver-cards-remote-memory';
import { DocsUserGuide } from 'modules/docs/DocsModule';
import { confDefault } from 'conf';
import { downloadFile } from 'common/utils/fileUtils';

type CcrRouteParams = {
    ccr?: string;
};

export enum CCRState {
    ConnectedDriver = 'connected_driver',
    ConnectedCompany = 'connected_company',
    Connected = 'connected',
    Unknown = 'unknown',
    Disconnected = 'disconnected'
}

export interface CcrModel {
    id: string;
    serialNumber: string;
    state: CCRState;
}

export interface CcrModelInput {
    id?: string;
    serialNumber: string;
}

export interface MemoryCard {
    id: string;
    monitoredObject?: {
        id: string;
        registrationNumber: string;
    };
    driver?: {
        name: string;
        id: string;
    };
    date: string;
    demand: boolean;
    downloadedFile: string;
    downloadStarted?: string;
    downloadRequested?: string;
    dataActivityStart: string;
    dataActivityEnd: string;
}

interface Props extends RouteComponentProps<CcrRouteParams>, WithTranslation {
    logic: Logic;
}

interface State {
    search: { text: string };
    startDate: string;
    table: {
        data: DriverCardsRemoteMemoryModel[];
        loadingDemandIds: string[];
        loading: boolean;
    };
    ccr: {
        open: boolean;
        edit: boolean;
        loading: boolean;
        data: CcrModel[];
    };
    helper?: {
        content: string;
    };
}

class DriversCardsRemoteMemoryModule extends Component<Props, State> {
    private logic: Logic;

    constructor(props: Props) {
        super(props);

        this.state = {
            search: { text: '' },
            startDate: moment.utc().startOf('month').toISOString(),
            table: {
                data: [],
                loadingDemandIds: [],
                loading: true
            },
            ccr: {
                open: false,
                edit: this.props.logic.auth().impersonator(),
                loading: false,
                data: []
            }
        };

        this.logic = this.props.logic;
    }

    componentDidMount() {
        (window as any).app.modul = this;

        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .getTableData(
                {
                    timestampFrom: moment.utc(this.state.startDate).toDate(),
                    timestampTo: moment.utc(this.state.startDate).add(1, 'month').startOf('month').toDate()
                },
                this.state.search.text
            )
            .then(data =>
                this.setState({
                    table: { data, loadingDemandIds: [], loading: false }
                })
            )
            .catch(err => {
                console.error(`Load data error, err: ${err}`);
                message.error(this.props.t('common.error.loadDataError'));
                this.setState(state => ({
                    table: {
                        ...state.table,
                        loading: false
                    }
                }));
            });

        this.logic
            .ccr()
            .getCCRs()
            .then(data => {
                this.setState(state => ({
                    ccr: {
                        ...state.ccr,
                        data
                    }
                }));
                if (data.length > 0 && !this.logic.demo().isActive) {
                    this.logic.ccr().init();
                }
            })
            .catch(e => console.error(e));

        this.logic.ccr().onData(data => {
            this.setState(state => ({
                ccr: {
                    ...state.ccr,
                    data
                }
            }));
        });
    }

    render() {
        return (
            <DriverCardsRemoteMemory
                search={this.state.search}
                table={{
                    ...this.state.table,
                    onDayDownloadClick: this._onDayDownloadClick,
                    onRemoteDownloadClick: this._onRemoteDownloadClick,
                    onMonthlyDownloadClick: this._onMonthlyDownloadClick
                }}
                helper={this.state.helper}
                ccr={this.state.ccr}
                startDate={this.state.startDate}
                demoMode={this.logic.demo().isActive}
                onSearchChange={this._onSearchChange}
                onBarHelperClick={this._onBarHelperClick}
                onBarNextChange={this._onNextChange}
                onBarPreviousChange={this._onPreviousChange}
                onBarDatePickerChange={this._onDatePickerChange}
                onCcrTableAdd={this._onCcrTableAdd}
                onCcrTableRemove={this._onCcrTableRemove}
                onBarCcrClick={this._onBarCCRClick}
                onBarDownloadAllClick={this._onBarDownloadAllClick}
                onHelperClose={this._onHelperClose}
            />
        );
    }

    private _onBarDownloadAllClick = () => {
        let documentIds: string[] = [];
        this.state.table.data
            .filter(entry => entry.cards.length > 0)
            .forEach(entry => {
                entry.cards.forEach(data => {
                    if (!data.demand && data.downloadedFile && data.driver?.id) {
                        documentIds.push(data.id);
                    }
                });
            });
        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .downloadFiles(documentIds)
            .then(result => {
                if (result.url) {
                    const filename = result.filename;
                    const headers = new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        Authorization: `Bearer ${this.logic.auth().token()}`
                    });
                    fetch(this.logic.conf.api.backend.basePath + result.url, {
                        headers
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            downloadFile(blob, filename);
                            message.success(this.props.t('common.downloadSuccessMsg'));
                        })
                        .catch(err => {
                            message.error(this.props.t('common.downloadErrorMsg'));
                            console.error('Fetch archive file err', err);
                        });
                }
            })
            .catch(err => {
                message.error(this.props.t('common.downloadErrorMsg'));
                console.error('Download files err', err);
            });
    };

    private _onMonthlyDownloadClick = (id: string) => {
        let documentIds: string[] = [];
        this.state.table.data
            .filter(entry => entry.id === id)
            .forEach(entry => {
                entry.cards.forEach(data => {
                    if (!data.demand && data.downloadedFile) {
                        documentIds.push(data.id);
                    }
                });
            });

        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .downloadFiles(documentIds)
            .then(result => {
                if (result.url) {
                    const filename = result.filename;
                    const headers = new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        Authorization: `Bearer ${this.logic.auth().token()}`
                    });
                    fetch(this.logic.conf.api.backend.basePath + result.url, {
                        headers
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            downloadFile(blob, filename);
                            message.success(this.props.t('common.downloadSuccessMsg'));
                        })
                        .catch(err => {
                            message.error(this.props.t('common.downloadErrorMsg'));
                            console.error('Fetch archive file err', err);
                        });
                }
            })
            .catch(err => {
                message.error(this.props.t('common.downloadErrorMsg'));
                console.error('Download files err', err);
            });
    };

    private _onDayDownloadClick = async (documentId: string) => {
        try {
            const result = await this.logic.dispatcherKitDriverCardsRemoteMemory().getDtcoLink(documentId);

            if (result.url) {
                const filename = result.filename;
                const headers = new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Content-Type'
                });
                fetch(result.url, {
                    headers
                })
                    .then(response => response.blob())
                    .then(blob => {
                        downloadFile(blob, filename);
                        message.success(this.props.t('common.downloadSuccessMsg'));
                    })
                    .catch(err => {
                        message.error(this.props.t('common.downloadErrorMsg'));
                        console.error('Fetch archive file err', err);
                    });
            }
        } catch (err) {
            message.error(this.props.t('common.downloadErrorMsg'));
            console.error('Download file err', err);
        }
    };

    private _onRemoteDownloadClick = (userId: string) => {
        this.setState(
            state => ({
                ...state,
                table: {
                    ...state.table,
                    loadingDemandIds: state.table.loadingDemandIds.concat(userId)
                }
            }),
            () => {
                this.logic
                    .dispatcherKitDriverCardsRemoteMemory()
                    .createDownloadDemand(userId)
                    .then(cardsDemanded => {
                        const data = this.state.table.data.map(tableCell => ({
                            ...tableCell,
                            cardsDemanded: tableCell.id === userId ? [cardsDemanded] : tableCell.cardsDemanded
                        }));
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                loadingDemandIds: state.table.loadingDemandIds.filter(id => id !== userId),
                                data
                            }
                        }));
                    });
            }
        );
    };

    private _onSearchChange = debounce((text: string) => {
        this.setState(() => ({
            search: {
                text
            }
        }));
        this.logic
            .dispatcherKitDriverCardsRemoteMemory()
            .search(text)
            .then(data => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        data,
                        loading: false
                    }
                }));
            });
    }, 500);

    private _onNextChange = () => {
        const startDate = moment.utc(this.state.startDate).add(1, 'month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitDriverCardsRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data =>
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }))
                    )
                    .catch(e => console.error(e))
        );
    };

    private _onPreviousChange = () => {
        const startDate = moment.utc(this.state.startDate).subtract(1, 'month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitDriverCardsRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data =>
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }))
                    )
                    .catch(e => console.error(e))
        );
    };

    private _onDatePickerChange = (date: Moment) => {
        const startDate = moment.utc(date.toDate()).startOf('month').toISOString();
        this.setState(
            state => ({ startDate, table: { ...state.table, loading: true } }),
            () =>
                this.logic
                    .dispatcherKitDriverCardsRemoteMemory()
                    .getTableData(
                        {
                            timestampFrom: moment.utc(this.state.startDate).toDate(),
                            timestampTo: moment.utc(this.state.startDate).add(1, 'month').toDate()
                        },
                        this.state.search.text
                    )
                    .then(data =>
                        this.setState(state => ({
                            table: {
                                ...state.table,
                                data,
                                loading: false
                            }
                        }))
                    )
                    .catch(e => console.error(e))
        );
    };

    private _onCcrTableAdd = (input: CcrModelInput) => {
        this.logic
            .ccr()
            .assignCCR(input.serialNumber)
            .then(newCCR => {
                this.setState(state => ({
                    ccr: {
                        ...state.ccr,
                        data: [...state.ccr.data, newCCR]
                    }
                }));
            });
    };

    private _onCcrTableRemove = (id: string) => {
        this.logic
            .ccr()
            .unassignCCR(id)
            .then(res => {
                this.setState(state => ({
                    ccr: {
                        ...state.ccr,
                        data: state.ccr.data.filter(ccr => ccr.id !== res.id)
                    }
                }));
            });
    };

    private _onBarCCRClick = () => {
        this.setState(state => ({
            ccr: {
                ...state.ccr,
                open: !state.ccr.open
            }
        }));
    };

    private _onBarHelperClick = () => {
        const module: DocsUserGuide = 'tachoremotedownload';

        const language = confDefault.langsDocs.includes(i18n.language) ? i18n.language : 'en';

        fetch(`${this.logic.conf.docs.path}${language}/${module}.html`).then(response => {
            response.text().then(content => {
                this.setState({
                    helper: {
                        content
                    }
                });
            });
        });
    };

    private _onHelperClose = () => {
        this.setState({
            helper: undefined
        });
    };
}

export default withTranslation()(withRouter(DriversCardsRemoteMemoryModule));
