/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrRemoveUserToUserRoleBody
 */
export interface AddOrRemoveUserToUserRoleBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddOrRemoveUserToUserRoleBody
     */
    userRoleIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddOrRemoveUserToUserRoleBody
     */
    action?: AddOrRemoveUserToUserRoleBodyActionEnum;
}

export function AddOrRemoveUserToUserRoleBodyFromJSON(json: any): AddOrRemoveUserToUserRoleBody {
    return AddOrRemoveUserToUserRoleBodyFromJSONTyped(json, false);
}

export function AddOrRemoveUserToUserRoleBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrRemoveUserToUserRoleBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userRoleIds': json['user_role_ids'],
        'action': !exists(json, 'action') ? undefined : json['action'],
    };
}

export function AddOrRemoveUserToUserRoleBodyToJSON(value?: AddOrRemoveUserToUserRoleBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_role_ids': value.userRoleIds,
        'action': value.action,
    };
}

/**
* @export
* @enum {string}
*/
export enum AddOrRemoveUserToUserRoleBodyActionEnum {
    Add = 'add',
    Remove = 'remove'
}


