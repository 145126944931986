/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RawDataPointCanDistanceModel
 */
export interface RawDataPointCanDistanceModel {
    /**
     * 
     * @type {string}
     * @memberof RawDataPointCanDistanceModel
     */
    type: RawDataPointCanDistanceModelTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof RawDataPointCanDistanceModel
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointCanDistanceModel
     */
    raw: number;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointCanDistanceModel
     */
    kilometers?: number;
}

export function RawDataPointCanDistanceModelFromJSON(json: any): RawDataPointCanDistanceModel {
    return RawDataPointCanDistanceModelFromJSONTyped(json, false);
}

export function RawDataPointCanDistanceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawDataPointCanDistanceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'time': (new Date(json['time'])),
        'raw': json['raw'],
        'kilometers': !exists(json, 'kilometers') ? undefined : json['kilometers'],
    };
}

export function RawDataPointCanDistanceModelToJSON(value?: RawDataPointCanDistanceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'time': (value.time.toISOString()),
        'raw': value.raw,
        'kilometers': value.kilometers,
    };
}

/**
* @export
* @enum {string}
*/
export enum RawDataPointCanDistanceModelTypeEnum {
    CanDistance = 'can_distance'
}


