/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PuescStatusResponse,
    PuescStatusResponseFromJSON,
    PuescStatusResponseToJSON,
} from '../models';

export interface GetPuescUserStatusV2ActualVehicleStatePuescUserStatusGetRequest {
    deadMansSwitchThresholdSeconds?: number;
    moids?: Array<string>;
    clientId?: number;
}

/**
 * no description
 */
export class PuescApi extends runtime.BaseAPI {

    /**
     * Get Puesc User Status
     */
    async getPuescUserStatusV2ActualVehicleStatePuescUserStatusGetRaw(requestParameters: GetPuescUserStatusV2ActualVehicleStatePuescUserStatusGetRequest): Promise<runtime.ApiResponse<Array<PuescStatusResponse>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deadMansSwitchThresholdSeconds !== undefined) {
            queryParameters['dead_mans_switch_threshold_seconds'] = requestParameters.deadMansSwitchThresholdSeconds;
        }

        if (requestParameters.moids) {
            queryParameters['moids'] = requestParameters.moids;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/actual-vehicle-state/puesc-user-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PuescStatusResponseFromJSON));
    }

    /**
     * Get Puesc User Status
     */
    async getPuescUserStatusV2ActualVehicleStatePuescUserStatusGet(requestParameters: GetPuescUserStatusV2ActualVehicleStatePuescUserStatusGetRequest): Promise<Array<PuescStatusResponse>> {
        const response = await this.getPuescUserStatusV2ActualVehicleStatePuescUserStatusGetRaw(requestParameters);
        return await response.value();
    }

}
