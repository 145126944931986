/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MonitoredObjectFleetType {
    LightVehicle = 'light_vehicle',
    Vehicle = 'vehicle',
    Trailer = 'trailer'
}

export function MonitoredObjectFleetTypeFromJSON(json: any): MonitoredObjectFleetType {
    return MonitoredObjectFleetTypeFromJSONTyped(json, false);
}

export function MonitoredObjectFleetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFleetType {
    return json as MonitoredObjectFleetType;
}

export function MonitoredObjectFleetTypeToJSON(value?: MonitoredObjectFleetType | null): any {
    return value as any;
}

