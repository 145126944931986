import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { Role } from 'logic/auth';
import Button from 'common/components/Button';
import { FuelCardModel } from '../FuelCardsModule';
import qa from 'qa-selectors';

interface Props {
    model?: FuelCardModel;
    demoMode?: boolean;
    roles: Role[];
    onUpdateClick?: () => void;
    onPairingClick?: () => void;
    onDeleteClick?: () => void;
}

function FuelCardsActions(props: Props) {
    const { t } = useTranslation();
    const { demoMode } = props;

    return (
        <div className="action-buttons">
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={demoMode || !props.roles.includes(Role.EX_W)}
                        qa={qa.fuel.detail.btnUpdate}
                        icon={<i className="fas fa-fw fa-pencil-alt" />}
                        onClick={props.onUpdateClick}
                    >
                        {t('ManagementFuelCards.updateData')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={
                            moment
                                .utc(props.model?.expirationDate)
                                .endOf('day')
                                .isBefore(moment.utc().startOf('day')) ||
                            demoMode ||
                            !props.roles.includes(Role.EX_W)
                        }
                        icon={<i className="fas fa-fw fa-link" />}
                        qa={qa.fuel.detail.btnAddLinkedItem}
                        onClick={props.onPairingClick}
                    >
                        {t('ManagementFuelCards.addAnotherItem')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        type="primary"
                        disabled={demoMode || !props.roles.includes(Role.EX_W)}
                        icon={<i className="fas fa-fw fa-trash-alt" />}
                        qa={qa.fuel.detail.btnDelete}
                        onClick={props.onDeleteClick}
                    >
                        {t('ManagementFuelCards.deleteFuelCard')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default FuelCardsActions;
