/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceActualVehicleStateModelsVehicleStateData
 */
export interface ServiceActualVehicleStateModelsVehicleStateData {
    /**
     * 
     * @type {Date}
     * @memberof ServiceActualVehicleStateModelsVehicleStateData
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof ServiceActualVehicleStateModelsVehicleStateData
     */
    ignition: number;
}

export function ServiceActualVehicleStateModelsVehicleStateDataFromJSON(json: any): ServiceActualVehicleStateModelsVehicleStateData {
    return ServiceActualVehicleStateModelsVehicleStateDataFromJSONTyped(json, false);
}

export function ServiceActualVehicleStateModelsVehicleStateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceActualVehicleStateModelsVehicleStateData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': (new Date(json['time'])),
        'ignition': json['ignition'],
    };
}

export function ServiceActualVehicleStateModelsVehicleStateDataToJSON(value?: ServiceActualVehicleStateModelsVehicleStateData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': (value.time.toISOString()),
        'ignition': value.ignition,
    };
}


