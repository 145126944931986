/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DriverBehaviourEventIntervalType {
    AggressiveTakeoff = 'aggressive_takeoff',
    DangerousTurnCountry = 'dangerous_turn_country',
    DangerousTurnHighway = 'dangerous_turn_highway',
    DangerousBrakingCity = 'dangerous_braking_city',
    DangerousBrakingCountry = 'dangerous_braking_country',
    DangerousBrakingHighway = 'dangerous_braking_highway'
}

export function DriverBehaviourEventIntervalTypeFromJSON(json: any): DriverBehaviourEventIntervalType {
    return DriverBehaviourEventIntervalTypeFromJSONTyped(json, false);
}

export function DriverBehaviourEventIntervalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourEventIntervalType {
    return json as DriverBehaviourEventIntervalType;
}

export function DriverBehaviourEventIntervalTypeToJSON(value?: DriverBehaviourEventIntervalType | null): any {
    return value as any;
}

