import { JourneyStatisticsResponseStats } from 'generated/backend-api';
import moment from 'moment';
import { users } from './users';
import { vehicles } from './vehicles';
import { fuelConsumptions } from './fuel-consumption';
import { DATE_TIME_FORMAT } from 'domain-constants';

const averageSpeedKmph = [75.5, 74.3, 79.1, 55.25, 73.12, 65.84, 66.09, 76.05, 86.85, 70.5, 65.4];
const maxSpeedKmph = [95, 99, 105, 80, 70, 84, 66, 81, 88, 99, 109];
const odometerStates = [
    102195, 23650, 85650, 12356, 230655, 160117, 63244, 100325, 65321, 29912, 18109, 221471, 233651, 223665
];

const activities: JourneyStatisticsResponseStats[] = fuelConsumptions
    .sort((a, b) => (moment(a.dateFrom).isBefore(b.dateFrom) ? 0 : -1))
    .map((fc, i) => {
        const duration =
            fc.entries?.[0] && fc.entries?.[0].stats.idleTimeSeconds
                ? fc.entries?.[0].stats.idleTimeSeconds
                : undefined;
        const distance =
            fc.entries?.[0] && fc.entries?.[0].stats.distanceKilometers
                ? fc.entries?.[0].stats.distanceKilometers / 1000
                : undefined;
        return {
            activityTimeSeconds: duration,
            averageSpeedKmph: averageSpeedKmph[i % averageSpeedKmph.length],
            stoppedTimeSeconds:
                fc.entries?.[0] && fc.entries?.[0].stats.idleTimeSeconds
                    ? fc.entries?.[0].stats.idleTimeSeconds / 3
                    : undefined,
            consumptionLiters:
                fc.entries?.[0] && fc.entries?.[0].stats.consumptionLiters
                    ? fc.entries?.[0].stats.consumptionLiters
                    : undefined,
            consumptionLitersPer100km:
                fc.entries?.[0] && fc.entries?.[0].stats.consumptionLiters && distance
                    ? (fc.entries?.[0].stats.consumptionLiters / distance) * 100
                    : undefined,
            distanceKilometers: distance,
            idleTimeSeconds:
                fc.entries?.[0] && fc.entries?.[0].stats.idleTimeSeconds
                    ? fc.entries?.[0].stats.idleTimeSeconds
                    : undefined,
            maxSpeedKmph: maxSpeedKmph[i % maxSpeedKmph.length],
            ntrips: 1,
            activityStart: moment(fc.dateFrom).toDate(),
            activityEnd: moment(fc.dateFrom).add(duration, 'seconds').toDate(),
            timestampFrom: moment(fc.dateFrom).startOf('day').toDate(),
            timestampTo: moment(fc.dateFrom).endOf('day').toDate(),
            driverId: fc.entries?.[0] && fc.entries?.[0].driverId ? fc.entries?.[0].driverId : undefined,
            monitoredObjectId: String(fc.monitoredObjectId) ?? undefined,
            odometerEnd: odometerStates[i % odometerStates.length],
            odometerStart: odometerStates[i % odometerStates.length] - (distance ?? 0)
        } as JourneyStatisticsResponseStats;
    }) as JourneyStatisticsResponseStats[];

const summaryDistanceKilometers = activities
    .map(d => d.distanceKilometers ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0);
const summaryConsumptionLiters = activities
    .map(d => d.consumptionLiters ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0);
const summary: JourneyStatisticsResponseStats[] = [
    {
        distanceKilometers: summaryDistanceKilometers,
        activityTimeSeconds: activities
            .map(d => d.activityTimeSeconds ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0),
        idleTimeSeconds: activities.map(d => d.idleTimeSeconds ?? 0).reduce((partialSum, a) => partialSum + a, 0),
        stoppedTimeSeconds: activities.map(d => d.stoppedTimeSeconds ?? 0).reduce((partialSum, a) => partialSum + a, 0),
        averageSpeedKmph: averageSpeedKmph.reduce((partialSum, a) => partialSum + a, 0) / averageSpeedKmph.length,
        maxSpeedKmph: maxSpeedKmph.reduce((partialSum, a) => partialSum + a, 0) / maxSpeedKmph.length,
        consumptionLiters: summaryConsumptionLiters,
        consumptionLitersPer100km: (summaryConsumptionLiters / summaryDistanceKilometers) * 100,
        consumptionIdleLitersPer1h: 0, //TODO
        consumptionIdleLiters: 0, //TODO
        ntrips: activities.length,
        timestampFrom: activities[0].timestampFrom,
        timestampTo: activities[0].timestampTo,
        co2Emission: activities[0].co2Emission
    }
];

const daysKeys = [...new Set(activities.map(d => moment(d.timestampFrom).format(DATE_TIME_FORMAT)))];

export const journeysStatistics = {
    byDays: daysKeys.map(d => {
        const activitiesDays = activities.filter(a => moment(a.timestampFrom).format(DATE_TIME_FORMAT) === d);
        const distanceKilometers = activitiesDays
            .map(d => d.distanceKilometers ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        const consumptionLiters = activitiesDays
            .map(d => d.consumptionLiters ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        return {
            distanceKilometers,
            activityTimeSeconds: activitiesDays
                .map(d => d.activityTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            idleTimeSeconds: activitiesDays
                .map(d => d.idleTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            stoppedTimeSeconds: activitiesDays
                .map(d => d.stoppedTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            averageSpeedKmph: averageSpeedKmph.reduce((partialSum, a) => partialSum + a, 0) / averageSpeedKmph.length,
            maxSpeedKmph: maxSpeedKmph.reduce((partialSum, a) => partialSum + a, 0) / maxSpeedKmph.length,
            consumptionLiters,
            consumptionLitersPer100km: (consumptionLiters / distanceKilometers) * 100,
            ntrips: activitiesDays.length,
            timestampFrom: moment(d, DATE_TIME_FORMAT).toDate(),
            timestampTo: moment(d, DATE_TIME_FORMAT).toDate(),
            activityStart: activitiesDays[0].activityStart ?? moment().toDate(),
            activityEnd: activitiesDays[activitiesDays.length - 1].activityEnd ?? moment().toDate(),
            co2Emission: activitiesDays.map(d => d.co2Emission ?? 0).reduce((partialSum, a) => partialSum + a, 0)
        };
    }) as JourneyStatisticsResponseStats[],
    byMonths: summary as JourneyStatisticsResponseStats[],
    byVehicles: vehicles.map((vehicle, i) => {
        const daysFiltered = activities.filter(d => d.monitoredObjectId === String(vehicle.id));
        const distanceKilometers = daysFiltered
            .map(d => d.distanceKilometers ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        const consumptionLiters = daysFiltered
            .map(d => d.consumptionLiters ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        return {
            monitoredObjectId: String(vehicle.id),
            distanceKilometers,
            activityTimeSeconds: daysFiltered
                .map(d => d.activityTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            idleTimeSeconds: daysFiltered.map(d => d.idleTimeSeconds ?? 0).reduce((partialSum, a) => partialSum + a, 0),
            stoppedTimeSeconds: daysFiltered
                .map(d => d.stoppedTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            averageSpeedKmph:
                daysFiltered.map(d => d.distanceKilometers ?? 0).reduce((partialSum, a) => partialSum + a, 0) > 0
                    ? averageSpeedKmph[vehicle.id ?? 1 - 1]
                    : undefined,
            maxSpeedKmph:
                daysFiltered.map(d => d.distanceKilometers ?? 0).reduce((partialSum, a) => partialSum + a, 0) > 0
                    ? maxSpeedKmph[vehicle.id ?? 1 - 1]
                    : undefined,
            consumptionLiters,
            consumptionLitersPer100km: (consumptionLiters / distanceKilometers) * 100,
            activityStart: daysFiltered?.[0]?.timestampFrom ?? moment().toDate(),
            activityEnd: daysFiltered?.[0]?.timestampTo ?? moment().toDate(),
            odometerEnd: odometerStates[i % odometerStates.length],
            co2Emission: daysFiltered?.[0]?.co2Emission
        };
    }) as JourneyStatisticsResponseStats[],
    byentries: users.map(driver => {
        const daysFiltered = activities.filter(d => d.driverId === driver.id);
        const consumptionLiters = daysFiltered
            .map(d => d.consumptionLiters ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        const distanceKilometers = daysFiltered
            .map(d => d.distanceKilometers ?? 0)
            .reduce((partialSum, a) => partialSum + a, 0);
        return {
            driverId: driver.id,
            distanceKilometers,
            activityTimeSeconds: daysFiltered
                .map(d => d.activityTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            idleTimeSeconds: daysFiltered.map(d => d.idleTimeSeconds ?? 0).reduce((partialSum, a) => partialSum + a, 0),
            stoppedTimeSeconds: daysFiltered
                .map(d => d.stoppedTimeSeconds ?? 0)
                .reduce((partialSum, a) => partialSum + a, 0),
            averageSpeedKmph:
                daysFiltered.map(d => d.distanceKilometers ?? 0).reduce((partialSum, a) => partialSum + a, 0) > 0
                    ? averageSpeedKmph[driver.id ?? 1 - 1]
                    : undefined,
            maxSpeedKmph:
                daysFiltered.map(d => d.distanceKilometers ?? 0).reduce((partialSum, a) => partialSum + a, 0) > 0
                    ? maxSpeedKmph[driver.id ?? 1 - 1]
                    : undefined,
            consumptionLiters,
            consumptionLitersPer100km: (consumptionLiters / distanceKilometers) * 100,
            timestampFrom: daysFiltered?.[0]?.timestampFrom ?? moment().toDate(),
            timestampTo: daysFiltered?.[0]?.timestampTo ?? moment().toDate(),
            co2Emission: daysFiltered?.[0]?.co2Emission
        };
    }) as JourneyStatisticsResponseStats[],
    summary: summary as JourneyStatisticsResponseStats[]
};
