import { google } from 'google-maps';

export function getCenterBoundsWithDistance(
    google: google,
    gpsData: google.maps.LatLng,
    distanceInMeters = 2000
): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();
    const geometry = google.maps.geometry.spherical;

    bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 0));
    bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, -90));
    bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 180));
    bounds.extend(geometry.computeOffset(gpsData, distanceInMeters, 90));

    return bounds;
}
