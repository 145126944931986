import cn from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CcrModel } from 'logic/partner/logic/partner-ccr';
import { Table } from 'common/components';
import { ColumnType } from 'antd/lib/table';
import { TABLE_SCROLL_Y_DEFAULT } from 'domain-constants';

interface Props extends WithTranslation {
    loading: boolean;
    data?: CcrModel[];
    selected?: CcrModel;
    onTableRowSelect?: (id: string) => void;
}

function CcrTable(props: Props) {
    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    const { t, loading, data, selected } = props;

    return (
        <div className="tlm-services-table partner-main-table">
            <Table<CcrModel>
                loading={loading}
                dataSource={data?.map(e => ({ ...e, key: e.id }))}
                columns={createColumns()}
                scroll={{ y: TABLE_SCROLL_Y_DEFAULT }}
                onRow={record => ({
                    onClick: () => onClickRow?.(record.id.toString()),
                    className: cn({
                        'ant-table-row-selected': selected?.id === record.id
                    })
                })}
            />
        </div>
    );

    function createColumns() {
        return [
            {
                title: t('common.id'),
                dataIndex: 'ident',
                key: 'ident',
                width: 30,
                render: ident => ident
            },
            {
                title: t('Partner.sn'),
                dataIndex: 'sn',
                key: 'sn',
                width: 60,
                render: sn => sn
            },
            {
                title: t('Partner.createdOn'),
                dataIndex: 'createdOn',
                key: 'createdOn',
                width: 60,
                render: createdOn => createdOn.format('L')
            }
        ] as ColumnType<CcrModel>[];
    }
}

export default withTranslation()(CcrTable);
