/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExpensesItems
 */
export interface ExpensesItems {
    /**
     * 
     * @type {string}
     * @memberof ExpensesItems
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ExpensesItems
     */
    priceWoVat: number;
    /**
     * 
     * @type {string}
     * @memberof ExpensesItems
     */
    type: ExpensesItemsTypeEnum;
}

export function ExpensesItemsFromJSON(json: any): ExpensesItems {
    return ExpensesItemsFromJSONTyped(json, false);
}

export function ExpensesItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpensesItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'priceWoVat': json['price_wo_vat'],
        'type': json['type'],
    };
}

export function ExpensesItemsToJSON(value?: ExpensesItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'price_wo_vat': value.priceWoVat,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum ExpensesItemsTypeEnum {
    Part = 'part',
    Labour = 'labour',
    Other = 'other'
}


