import { Button, Table } from 'common/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { ColumnsType } from 'antd/lib/table';
import { formatDuration, formatDurationWithSeconds } from 'common/utils/dateTimeUtils';
import { TABLE_SCROLL_Y_SMALL } from 'domain-constants';

export interface DiemsTableData {
    id: string;
    name: string;
    surname: string;
    stayAbroadTime: number;
    stayLocalTime: number;
    countries: string;
    pocketMoney: string;
    rate: string;
    shortening: string;
    vouchers: string;
}

interface Props {
    loading: boolean;
    data: DiemsTableData[];
    isPolishDiems: boolean;
    onRowClick?: (id: string) => void;
    onShowDetailClick?: (id: string) => void;
    onSelectedRowChange?: (ids: string[]) => void;
}
export default function AllowanceTable(props: Props) {
    const { t } = useTranslation();
    const [state, setState] = React.useState<{ selectedRowKeys: React.ReactText[] }>({
        selectedRowKeys: []
    });
    const onSelectChange = (selectedRowKeys: React.ReactText[]) => {
        setState({ selectedRowKeys });
        props.onSelectedRowChange?.(selectedRowKeys as string[]);
    };
    const rowSelection = {
        selectedRowKeys: state.selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 24
    };
    return (
        <div className="allowance-table">
            <Table<DiemsTableData>
                columns={getColumns()}
                rowSelection={rowSelection}
                loading={props.loading}
                scroll={{ y: TABLE_SCROLL_Y_SMALL }}
                dataSource={props.data.map(d => ({ ...d, key: d.id }))}
                onRow={record => ({
                    onClick: () => props.onRowClick?.(record.id)
                })}
            />
        </div>
    );

    function getColumns(): ColumnsType<DiemsTableData> {
        const columns: ColumnsType<DiemsTableData> = [
            {
                title: t('Allowance.table.name'),
                key: 'name',
                dataIndex: 'name',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldName}>{data}</span>
            },
            {
                title: t('Allowance.table.surname'),
                key: 'surname',
                dataIndex: 'surname',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldSurname}>{data}</span>
            },
            {
                title: t('Allowance.table.stayAbroadTime'),
                key: 'stayAbroadTime',
                dataIndex: 'stayAbroadTime',
                width: 80,
                align: 'center',
                render: data => (
                    <span
                        data-qa={qa.allowances.table.fieldAbroadTravel}
                        title={formatDurationWithSeconds((data ?? 0) * 1000)}
                    >
                        {formatDuration((data ?? 0) * 1000)}
                    </span>
                )
            },
            {
                title: t('Allowance.table.countries'),
                key: 'countries',
                dataIndex: 'countries',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldCountries}>{data}</span>
            },
            {
                title: t('Allowance.table.stayLocalTime'),
                key: 'stayLocalTime',
                dataIndex: 'stayLocalTime',
                width: 80,
                align: 'center',
                render: data => (
                    <span
                        data-qa={qa.allowances.table.fieldDomesticTravel}
                        title={formatDurationWithSeconds((data ?? 0) * 1000)}
                    >
                        {formatDuration((data ?? 0) * 1000)}
                    </span>
                )
            },
            {
                title: t('Allowance.table.rate'),
                key: 'rate',
                dataIndex: 'rate',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldDailyRate}>{data}</span>
            },
            {
                title: t('Allowance.table.pocketMoney'),
                key: 'pocketMoney',
                dataIndex: 'pocketMoney',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldPocketMoney}>{data}</span>
            },
            {
                title: t('Allowance.table.shortening'),
                key: 'shortening',
                dataIndex: 'shortening',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldMealShortage}>{data}</span>
            },
            {
                title: t('Allowance.table.vouchers'),
                key: 'vouchers',
                dataIndex: 'vouchers',
                width: 80,
                align: 'center',
                render: data => <span data-qa={qa.allowances.table.fieldTotalAllowance}>{data}</span>
            },
            {
                title: '',
                key: 'action',
                width: 80,
                align: 'center',
                render: record => {
                    return (
                        <Button
                            size="middle"
                            type="link"
                            onClick={() => {
                                props.onShowDetailClick?.(record.id);
                            }}
                            qa={qa.allowances.table.btnShowDetail}
                        >
                            {t('Allowance.table.showDetail')}
                        </Button>
                    );
                }
            }
        ];

        if (props.isPolishDiems) {
            return columns.filter(c => c.key !== 'pocketMoney');
        }
        return columns;
    }
}
