/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressClient,
    AddressClientFromJSON,
    AddressClientFromJSONTyped,
    AddressClientToJSON,
    ReadOnlyUserTokenUser,
    ReadOnlyUserTokenUserFromJSON,
    ReadOnlyUserTokenUserFromJSONTyped,
    ReadOnlyUserTokenUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyUserToken
 */
export interface ReadOnlyUserToken {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserToken
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserToken
     */
    readonly startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserToken
     */
    readonly endTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserToken
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    tokenType: ReadOnlyUserTokenTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    holder?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    userSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserToken
     */
    userDeleted?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserToken
     */
    userActive?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserToken
     */
    userSsoId?: string | null;
    /**
     * 
     * @type {ReadOnlyUserTokenUser}
     * @memberof ReadOnlyUserToken
     */
    user?: ReadOnlyUserTokenUser;
    /**
     * 
     * @type {AddressClient}
     * @memberof ReadOnlyUserToken
     */
    client?: AddressClient;
}

export function ReadOnlyUserTokenFromJSON(json: any): ReadOnlyUserToken {
    return ReadOnlyUserTokenFromJSONTyped(json, false);
}

export function ReadOnlyUserTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'token': json['token'],
        'tokenType': json['token_type'],
        'holder': !exists(json, 'holder') ? undefined : json['holder'],
        'userSurname': !exists(json, 'user_surname') ? undefined : json['user_surname'],
        'userName': !exists(json, 'user_name') ? undefined : json['user_name'],
        'userDeleted': !exists(json, 'user_deleted') ? undefined : json['user_deleted'],
        'userActive': !exists(json, 'user_active') ? undefined : json['user_active'],
        'userSsoId': !exists(json, 'user_sso_id') ? undefined : json['user_sso_id'],
        'user': !exists(json, 'user') ? undefined : ReadOnlyUserTokenUserFromJSON(json['user']),
        'client': !exists(json, 'client') ? undefined : AddressClientFromJSON(json['client']),
    };
}

export function ReadOnlyUserTokenToJSON(value?: ReadOnlyUserToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'token_type': value.tokenType,
        'holder': value.holder,
        'user_surname': value.userSurname,
        'user_name': value.userName,
        'user_deleted': value.userDeleted,
        'user_active': value.userActive,
        'user_sso_id': value.userSsoId,
        'user': ReadOnlyUserTokenUserToJSON(value.user),
        'client': AddressClientToJSON(value.client),
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyUserTokenTokenTypeEnum {
    Ibutton = 'ibutton ',
    Rfid = 'rfid',
    DriverCard = 'driver_card',
    Pin = 'pin',
    App = 'app',
    DefaultDriver = 'default_driver'
}


