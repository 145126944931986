import { RadioChangeEvent } from 'antd/lib/radio';
import { EnhancedRadioGroupProps } from 'common/components/Form/Radio';
import { Field, FieldProps, getIn } from 'formik';
import { ReactNode } from 'react';

import { Radio } from '../../components/Form';

interface Props {
    name: string;
    label?: ReactNode;
    options: any[];
}

function RadioField({ name, ...bag }: Props) {
    return (
        <Field name={name}>
            {({ field: { ...fieldBag }, form }: FieldProps<any>) => {
                const handleChange = (e: RadioChangeEvent) => {
                    form.setFieldValue(name, e.target.value);
                };

                return (
                    <Radio
                        // error={form.errors[name]}
                        {...fieldBag}
                        {...bag}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
}

interface GroupProps extends EnhancedRadioGroupProps {
    name: string;
    label?: ReactNode;
}

RadioField.Group = function ({ name, ...props }: GroupProps) {
    return (
        <Field name={name}>
            {({ field: { ...restField }, form }: FieldProps<any>) => {
                const handleChange = (e: RadioChangeEvent) => {
                    form.setFieldValue(name, e.target.value);
                };

                return (
                    <Radio.Group
                        error={getIn(form.touched, name) && getIn(form.errors, name)}
                        {...restField}
                        {...props}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
};

export default RadioField;
