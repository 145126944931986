import moment from 'moment';
import {
    AUTO_ZOOM_OUT_INTERVAL,
    INCREASE_VEHICLE_UPDATES_INTERVAL,
    MAX_BIWEEKLY_RIDE,
    NO_DATA_MOVING,
    NO_DATA_STANDING
} from 'domain-constants';
import {
    TrackingModel,
    TachoStatus,
    VehicleDelayedFilterCode,
    VehicleStatusFilterCode,
    TrackingType,
    VehicleTransport,
    MonitoredObjectType,
    MonitoredObjectTypeName,
    NoGPSStatus
} from 'common/model/tracking';
import {
    VehicleStateObject,
    VehicleTransportObject,
    MonitoredObjectTypeObject,
    VehicleDriverObject
} from 'generated/graphql';
import { MapLogic } from './map/map';
import { VehicleModelMap } from './map/logic/vehicles';
import { TransportModel, PlacesModel } from 'common/model/transports';
import { Logic } from './logic';
import { ControlPanel } from 'modules/map/components/MapControlsBar';
import { AddressIdentification, VehicleIdentification } from 'common/components/Settings';
import { toAddress } from '../common/utils/address';
import { actual } from '../common/utils/actual';
import { AlarmType, Transport, TransportState, TripInDatabase } from '../generated/backend-api';
import { latLngFromGeoJsonPointType } from '../common/utils/geo-utils';
import { NotificationMessage } from './notification-eio';
import { Role } from './auth';
import { geoAlarmTypes } from './map/logic/routing';
import { Alarm } from 'common/model/alarm';
import { Conf } from 'conf';
import { search } from '../common/utils/search';
import { ignitionToStatus } from 'common/utils/mappers';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { DriverBehaviorTrendsModel } from 'common/model/statistics';

export interface TrackingFilter {
    delay?: VehicleDelayedFilterCode;
    status?: VehicleStatusFilterCode;
    monitoredObjectGroupsChecked?: string[];
    fuelLow?: boolean;
    alarms?: boolean;
    aetrLow?: boolean;
}

export class TrackingLogic {
    private _logic: Logic;
    private _interval?: NodeJS.Timeout;
    private _zoomOutInterval?: NodeJS.Timeout;
    private _filter?: TrackingFilter;
    private _trackingType: TrackingType;
    private _active: boolean;
    private _map: MapLogic;
    private _vehicles: TrackingModel[];
    private _searchText: string;
    private _dataLoading: boolean;

    private _selectedVehicle?: TrackingModel;
    private _transport?: TransportModel;
    private _trips: TripInDatabase[];
    private _geoAlarms: Alarm[];
    private _driverBehaviorTrends: DriverBehaviorTrendsModel[];

    private _addressIdentification?: AddressIdentification;
    private _vehicleIdentification?: VehicleIdentification;
    private _prevSelectedVehicles: string[];

    private _onInitVehicles?: (vehicles: TrackingModel[]) => void;
    private _onData?: (filtered: TrackingModel[], data: TrackingModel[]) => void;
    private _onLoading?: (isLoading: boolean) => void;

    constructor(logic: Logic) {
        this._logic = logic;
        const settings = this._logic.settings().getProps();
        this._trackingType = 'TRACKING_UNDEFINED';
        this._active = false;
        this._dataLoading = false;
        this._vehicles = [];
        this._prevSelectedVehicles = [];
        this._map = this._logic.map();
        this._searchText = '';
        this._trips = [];
        this._geoAlarms = [];
        this._addressIdentification = settings.addressIdentification;
        this._vehicleIdentification = settings.vehicleIdentification;
        this._filter = settings.tracking.filter;
        this._driverBehaviorTrends = [];
    }

    async init(): Promise<void> {
        if (this._logic.demo().isActive) {
            return this._initDemoMode();
        } else {
            return this._init();
        }
    }

    getVehiclesData() {
        return this._vehicles;
    }

    private _initDemoMode() {
        this._active = true;
        this._dataLoading = true;
        this._onLoading?.(this._dataLoading);

        this._addressIdentification = this._logic.settings().getProp('addressIdentification');
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');
        this._vehicles = this.sortVehicles(
            this._logic.demo().data.vehicleStates.map(state =>
                this.toTrackingModel(
                    state,
                    this._logic
                        .demo()
                        .data.alarms.filter(alarm =>
                            [
                                AlarmType.UnavailableGps,
                                AlarmType.TransportColdChainProfileTemperatureHigh,
                                AlarmType.TransportColdChainProfileTemperatureLow
                            ].includes(alarm.alarmType)
                        )
                )
            )
        );

        this._initMap();
        this._map.vehicles().fitVehicles();
        const selected = this._vehicles.find(v => v.selected && v.centered);
        selected && this._map.vehicles().fitVehicle(selected.id);
        this._dataLoading = false;
        this._onLoading?.(this._dataLoading);
        this._onInitVehicles?.(this._vehicles);

        this.getPuescStatus();
        if (this._logic.auth().roles().includes(Role.OAC_R)) {
            this.getPuescStatus();
        }

        if (this._logic.auth().roles().includes(Role.DBH_R)) {
            this._logic
                .driverBehavior()
                .trucks()
                .dataTrends.subscribe(data => {
                    this._vehicles = this._vehicles.map<TrackingModel>(v => ({
                        ...v,
                        driverBehaviorTrends:
                            v.monitoredObjectType?.name === FleetType.VEHICLE
                                ? data.find(d => d.driverId === v.driverId)
                                : undefined
                    }));
                    this._updateData();
                });
            this._logic.driverBehavior().trucks().loadTrendsData(moment.utc().startOf('month').toISOString());
        }
    }

    private async _init(): Promise<void> {
        this._active = true;
        this._dataLoading = true;
        this._onLoading?.(this._dataLoading);

        this._addressIdentification = this._logic.settings().getProp('addressIdentification');
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');

        this._logic.settings().onChange(async prop => {
            if (prop.vehicleIdentification) {
                this._vehicleIdentification = prop.vehicleIdentification;
                await this.init();
                this.unselectVehicleCheckAll();
            }
        });
        const vd = await this._logic.vehiclesState().getData(this._logic.notification().device!);
        this._vehicles = this.sortVehicles(vd.map(v => this.toTrackingModel(v, this._logic.alarms().instantAlarms())));

        // add driver behavior
        if (this._logic.auth().roles().includes(Role.DBH_R)) {
            this._logic
                .driverBehavior()
                .vehicles()
                .data.subscribe(data => {
                    this._vehicles = this._vehicles.map<TrackingModel>(v => ({
                        ...v,
                        driverBehaviorLightVehicle:
                            v.monitoredObjectType?.name === FleetType.LIGHT_VEHICLE
                                ? data?.find(d => d.driverId === Number(v.driverId))
                                : undefined
                    }));
                });
            this._logic.driverBehavior().vehicles().loadData(moment.utc().startOf('month').toISOString());
        }

        this._initMap();
        this._map.vehicles().fitVehicles();

        const selected = this._vehicles.find(v => v.selected && v.centered);
        selected && this._map.vehicles().fitVehicle(selected.id);
        this._setVehicleUpdatesInterval(selected?.id);

        this._logic
            .notification()
            .onConnect()
            .subscribe(async () => {
                const vd = await this._logic.vehiclesState().getData(this._logic.notification().device!);

                this._vehicles = this.sortVehicles(
                    this._vehicles.map(v => {
                        const update: VehicleStateObject | undefined = vd.find(u => u.monitoredObjectId === v.id);
                        if (update) {
                            const vehicle: TrackingModel = {
                                ...this.toTrackingModel(update, this._logic.alarms().instantAlarms(), v),
                                selected: v.selected,
                                checked: v.checked,
                                route: v.route,
                                centered: v.centered,
                                monitoredObjectType: v.monitoredObjectType,
                                charging: v.charging,
                                monitoredObjectFuelType: v.monitoredObjectFuelType,
                                driverBehaviorTrends: v.driverBehaviorTrends,
                                driverBehaviorLightVehicle: v.driverBehaviorLightVehicle,
                                monitoredObjectGroups: v.monitoredObjectGroups,
                                puescStatus: v.puescStatus,
                                trailersName:
                                    v.trailers?.map(t =>
                                        VehicleIdentification.RegistrationNumber
                                            ? t.registrationNumber
                                            : t.customLabel || t.registrationNumber
                                    ) ?? [],
                                RN:
                                    this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                                        ? v.RN
                                        : v.customLabel || v.RN
                            };
                            return vehicle;
                        } else {
                            return v;
                        }
                    })
                );

                this._updateMap();
            });
        if (this._logic.auth().roles().includes(Role.OAC_R)) {
            const data = await this._logic.externalSystem().loadData();
            const secret = data.secrets.find(s => s.externalSystemAccess?.externalSystemName === 'PUESC');
            if (secret) {
                this.getPuescStatus();
                setInterval(async () => {
                    this.getPuescStatus();
                }, 60000); // 1min
            }
        }

        if (this._logic.auth().roles().includes(Role.IA_R)) {
            this._logic.alarms().alarmsUpdates.subscribe(() => {
                if (this._logic.alarms().instantAlarms().length === 0) {
                    this._vehicles = this._vehicles.map(v => {
                        return {
                            ...v,
                            alarms: []
                        };
                    });
                }

                this._vehicles = this._vehicles.map(v => {
                    return {
                        ...v,
                        alarms: this._logic
                            .alarms()
                            .instantAlarms()
                            .filter(a => a.monitoredObjectId === v.id)
                    };
                });

                this._updateMap();
            });

            this._logic.notification().on('alarms', _message => {
                this._vehicles = this._vehicles.map(v => {
                    return {
                        ...v,
                        alarms: this._logic
                            .alarms()
                            .instantAlarms()
                            .filter(a => {
                                const alarmStartTime = moment.utc(a.startDateTime);
                                const vehicleLastGpsTime = moment.utc(v.gpsDataTime);
                                return a.monitoredObjectId === v.id && vehicleLastGpsTime.isBefore(alarmStartTime);
                            })
                    };
                });
                this._updateMap();
            });
        }

        this._logic.notification().on('actual-vehicle-state', message => this.handleActualVehicleStateUpdate(message));

        this._dataLoading = false;
        this._onLoading?.(this._dataLoading);
        this._onInitVehicles?.(this._vehicles);
        // this._logic.notification().on('settings', msg => this._logic.settings().setProps(msg.data));
    }

    handleActualVehicleStateUpdate(message: NotificationMessage<{ key: VehicleStateObject }>) {
        const updates = message.data ? Object.values(message.data) : undefined;
        this._vehicles = this.sortVehicles(
            this._vehicles.map(v => {
                const update: VehicleStateObject | undefined = updates?.find(u => u.monitoredObjectId === v.id);
                if (update) {
                    const vehicle: TrackingModel = {
                        ...this.toTrackingModel(update, this._logic.alarms().instantAlarms(), v),
                        selected: v.selected,
                        checked: v.checked,
                        route: v.route,
                        centered: v.centered,
                        puescStatus: v.puescStatus,
                        monitoredObjectType: v.monitoredObjectType,
                        trailersName:
                            v.trailers?.map(t =>
                                VehicleIdentification.RegistrationNumber
                                    ? t.registrationNumber
                                    : t.customLabel || t.registrationNumber
                            ) ?? [],
                        monitoredObjectGroups: v.monitoredObjectGroups,
                        driverBehaviorTrends: v.driverBehaviorTrends,
                        driverBehaviorLightVehicle: v.driverBehaviorLightVehicle,
                        contactsData: v.contactsData,
                        RN:
                            this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                                ? v.RN
                                : v.customLabel || v.RN
                    };
                    return vehicle;
                } else {
                    return v;
                }
            })
        );

        this._updateMap();
    }

    tableOn(): void {
        this._map.tableOn();
        this._map.vehicles().fitVehicles();
    }

    tableOff(): void {
        this._map.tableOff();
        this._map.vehicles().fitVehicles();
    }

    acknowledgeAlarms(ids: string[]) {
        this._logic.alarms().markAlarmsAsSeen(ids);
    }

    destroy(): void {
        this.unselectVehicleCheckAll();
        this._setVehicleUpdatesInterval();
        this._active = false;
        this._logic.map().sideBarControlsOffResetState();
        this._onData = undefined;
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._map.vehicles().hide();
        this._map.routing().destroy();
        this._logic.map().setControlCenterVisible(false);
    }

    resetMap(): void {
        this._logic.map().resetPois();
        this._map.routing().renderRoute([], [], []);
        this._map.vehicles().hide();
    }

    search(text: string) {
        this._searchText = text;
        this._initMap();
        this._map.vehicles().fitVehicles();
    }

    searchText(): string {
        return this._searchText;
    }

    unselectVehicleCheckAll() {
        this._setVehicleUpdatesInterval();
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            selected: false,
            checked: true,
            centered: false,
            route: false
        }));
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._map.routing().destroy();
        this._logic.map().sideBarControlsOff(true, ControlPanel.VEHICLE);
        this._logic.map().vehicleCardData();
        this._updateMap();
        this._map.vehicles().fitVehicles();
    }

    centerOffVehicles() {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            centered: false
        }));
        if (this._vehicles.find(vehicle => vehicle.selected)) {
            this._initMap();
        } else {
            this._updateMap();
        }
        if (this._active && this._vehicles.find(v => v.selected)) {
            this._logic.map().setControlCenterVisible(true);
        }
    }

    unselectVehicle() {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            checked: this._prevSelectedVehicles.length > 1 ? this._prevSelectedVehicles.includes(v.id) : true,
            selected: false,
            centered: false,
            route: false
        }));
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._map.routing().destroy();
        this._logic.map().sideBarControlsOff(true, ControlPanel.VEHICLE);
        this._logic.map().vehicleCardData();
        this._updateMap();
        this._map.vehicles().fitVehicles();
        this._selectedVehicle = undefined;
        this._setVehicleUpdatesInterval();
    }

    renderRouteOfVehicle(vehicle: TrackingModel): void {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            checked:
                !this._transport?.vehicle || this._transport.vehicle !== vehicle.id
                    ? v.id === vehicle.id
                        ? true
                        : false
                    : true,
            route:
                (!this._transport?.vehicle || this._transport.vehicle !== v.id) && v.id === vehicle.id ? true : false,
            selected:
                (!this._transport?.vehicle || this._transport?.vehicle !== v.id) && v.id === vehicle.id ? true : false,
            centered: false
        }));
        this._selectedVehicle = this._vehicles.find(
            v => (!this._transport?.vehicle || this._transport?.vehicle !== v.id) && v.id === vehicle.id
        );

        this._map.routing().destroy();

        const activeTransport = vehicle?.activeTransports?.sort((a, b) =>
            moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1
        )?.[0];
        if ((!this._transport?.vehicle || this._transport.vehicle !== vehicle.id) && activeTransport?.id) {
            this._prevSelectedVehicles = [...new Set([...this._prevSelectedVehicles, vehicle.id])];

            this._initMap();

            const v = this._logic.tracking().loadVehicle(vehicle.id);
            this._setVehicleUpdatesInterval(vehicle.id);
            this._logic.map().vehicleCardData(v);
            this._logic.map().vehicleOn();

            this._loadTransport(activeTransport.id).then(t => {
                this._drawRouteOnMap();

                if (this._logic.auth().roles().includes(Role.IA_R)) {
                    // Alarms render
                    const start = moment.utc(t.places?.[0].ata ? t.places?.[0].ata : t.places?.[0].rta).toISOString();
                    const end = moment.utc().toISOString();

                    this._logic
                        .alarms()
                        .getAlarms({
                            dateFrom: moment.utc(start).toDate(),
                            dateTo: moment.utc(end).toDate(),
                            monitoredObjectId: Number(vehicle.id),
                            active: false
                        })
                        .then(t => {
                            this._geoAlarms = t.filter(a =>
                                [
                                    AlarmType.CorridorLeave,
                                    AlarmType.PoiArrival,
                                    AlarmType.PoiClose,
                                    AlarmType.PoiDeparture
                                ].includes(a.alarmType)
                            );
                            this._map.routing().renderAlarms(this._geoAlarms, geoAlarmTypes);
                        });
                }
            });
        } else {
            this._geoAlarms = [];
            this._transport = undefined;
            this._trips = [];
            this.selectVehicle(vehicle.id);
        }
    }

    isVehicleGPSInValid(id: string) {
        const vehicle = this._vehicles.find(v => v.id === id);
        return !vehicle?.GPS?.lat || !vehicle?.GPS?.lng;
    }

    enableVehicleCentered(): void {
        if (this._selectedVehicle) {
            this.selectVehicle(this._selectedVehicle.id);
        }
    }

    async selectVehicle(id: string) {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            selected: v.id === id,
            checked: v.id === id,
            centered: v.id === id,
            route: false
        }));
        this._selectedVehicle = this._vehicles.find(v => v.id === id);
        this._setVehicleUpdatesInterval(id);
        if (!this._selectedVehicle?.invalid) {
            this._selectedVehicle!.driverBehaviorTrends = await this._logic
                .tracking()
                .getDriverBehaviorTrends(this._selectedVehicle?.aetrData?.[0].driverTachocard);
            this._logic.map().vehicleCardData(this._logic.tracking().loadVehicle(id));
            this._logic.map().vehicleOn();
        } else {
            this._logic.map().sideBarControlsOff();
        }
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._map.routing().destroy();
        this._initMap();
        this._map.vehicles().fitVehicle(id);
        this._setVehicleContactData();
    }

    trackAll(): void {
        if (this._vehicles.every(v => v.checked)) {
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                centered: false,
                selected: false,
                checked: false,
                route: false
            }));
        } else {
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                centered: false,
                selected: false,
                checked: true,
                route: false
            }));
        }
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._prevSelectedVehicles = [];
        this._logic.map().sideBarControlsOff(true, ControlPanel.VEHICLE);
        this._logic.map().vehicleCardData(undefined);
        this._map.routing().destroy();
        this._initMap();
        this._map.vehicles().fitVehicles();
        this._selectedVehicle = undefined;
    }

    toggleTrackedVehicle(id: string, checked: boolean): void {
        this._prevSelectedVehicles = [...new Set([...this._prevSelectedVehicles, id])];
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            checked: v.id === id ? checked : v.checked,
            selected: false,
            route: false
        }));
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._selectedVehicle = undefined;
        this._map.routing().destroy();
        this._map.sideBarControlsOff();
        this._initMap();
    }

    selectTrackedVehicles(ids: string[]): void {
        this._prevSelectedVehicles = [...new Set([...this._prevSelectedVehicles, ...ids])];
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            checked: ids.includes(v.id),
            selected: false,
            route: false
        }));
        this._geoAlarms = [];
        this._transport = undefined;
        this._trips = [];
        this._selectedVehicle = undefined;
        this._map.routing().destroy();
        this._map.sideBarControlsOff();
        this._initMap();
    }

    filterVehicles(filter: TrackingFilter): void {
        this._filter = filter;
        this._initMap();
        this._map.vehicles().fitVehicles();
    }

    onInitVehicles(cb: (vehicles: TrackingModel[]) => void) {
        this._onInitVehicles = cb;
    }
    onData(cb: (filtered: TrackingModel[], raw: TrackingModel[]) => void): void {
        this._onData = cb;
    }

    onLoading(cb: (isLoading: boolean) => void): void {
        this._onLoading = cb;
    }

    getTrackingType(): TrackingType {
        return this._trackingType;
    }

    getDataLoading() {
        return this._dataLoading;
    }

    getMapData(): TrackingModel[] {
        const filtered = this._filterVehicles(this._vehicles).filter(v => v.checked && v.GPS?.lat && v.GPS?.lng);
        return this._searchVehicles(filtered);
    }

    destroyAdvancedTracking() {
        this._onData = undefined;
        this._logic.map().resetPois();
        this._map.routing().renderRoute([], [], []);
        this._map.vehicles().hide();
    }

    loadVehicle(id: string) {
        return this._vehicles.find(v => v.id === id);
    }

    updateMap() {
        this._updateMap();
    }

    toMapModel(item: TrackingModel): VehicleModelMap {
        return {
            id: item.id,
            name: item.RN,
            trailers: item.trailers?.map(t => t.registrationNumber) ?? [],
            selected: item.selected,
            centered: item.centered,
            route: item.route,
            moving: item.status ? [1, 2].includes(item.status) : false,
            status: item.status,
            angle: item.GPS?.angle,
            position: {
                lat: item.GPS?.lat,
                lng: item.GPS?.lng
            },
            alarms: item.alarms,
            charging: item.charging,
            fuelType: item.monitoredObjectFuelType,
            noGpsStatus: item.noGPSStatus
        };
    }

    toTrackingModel(vs: VehicleStateObject, alarms: Alarm[], currentVehicle?: TrackingModel): TrackingModel {
        const status = ignitionToStatus(vs?.stateData?.ignition);

        const obj: TrackingModel = {
            id: String(vs.monitoredObjectId),
            checked: true,
            route: false,
            centered: false,
            RN:
                this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                    ? (vs.rn as string)
                    : (vs.customLabel as string) ?? (vs.rn as string),
            monitoredObjectType: this._toMonitoredObjectType(vs.monitoredObjectType),
            monitoredObjectFuelType: vs.monitoredObjectFuelType ?? currentVehicle?.monitoredObjectFuelType,
            trailersName:
                vs.trailers?.map(t =>
                    this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                        ? (t.registrationNumber as string)
                        : (t.customLabel as string) ?? (t.registrationNumber as string)
                ) ?? [],
            trailers: vs.trailers
                ? vs.trailers.map(t => ({
                      id: t.id ?? '',
                      customLabel: t.customLabel ?? '',
                      registrationNumber: t.registrationNumber ?? ''
                  }))
                : currentVehicle?.trailers,
            addressStructured: vs.address_structured ?? [],
            location: toAddress(
                this._logic.auth().user().lang,
                this._logic.auth().client()!,
                vs.address_structured,
                this._addressIdentification!,
                vs.address
            ),
            monitoredObjectGroups: vs.monitoredObjectGroups ?? [],
            status: status,
            ETA: '',
            RTA: '',
            actual: '',
            firstRTA: '',
            gpsDataTime: vs.gpsData?.time ?? undefined,
            arrivalTimes: {
                eta: undefined,
                rta: undefined
            },
            ODO: String(vs.odometer) ?? '-',
            tank: vs.fuelTanks?.reduce((a, c) => a + (c.level ?? 0), 0) ?? 0,
            tankSize: vs.tankSize ?? 0,
            speed: vs.gpsData?.speed ? vs.gpsData.speed : undefined,
            aetrData: vs.drivers
                ? vs.drivers?.map(driver => this.setAetrData(driver))
                : vs.driver
                ? [this.setAetrData(vs.driver)]
                : [],
            contactsData: currentVehicle?.contactsData,
            selected: false,
            noGPSStatus:
                this._logic.conf.settings.warningSwitzerland &&
                vs.address_structured &&
                vs.address_structured.some(a => a.countryCode === 'CH')
                    ? NoGPSStatus.SwitzerlandUnavailable
                    : undefined,
            invalid: vs.gpsData?.time
                ? (status !== 0 &&
                      moment.duration(moment().diff(moment(vs.gpsData?.time).toDate())).asSeconds() > NO_DATA_MOVING) ||
                  (status === 0 &&
                      moment.duration(moment().diff(moment(vs.gpsData?.time).toDate())).asSeconds() > NO_DATA_STANDING)
                : false,
            GPS: vs.gpsData
                ? {
                      lat: vs.gpsData.lat ? Number(vs.gpsData.lat) : undefined,
                      lng: vs.gpsData.lon ? Number(vs.gpsData.lon) : undefined,
                      angle: vs.gpsData.angle ? Number(vs.gpsData.angle) : undefined,
                      speed: vs.gpsData.speed ? Number(vs.gpsData.speed) : undefined
                  }
                : undefined,
            activeTransports:
                vs.activeTransports?.map(
                    t =>
                        ({
                            firstRta: t.firstRta ?? '',
                            id: t.id ?? '',
                            name: t.name ?? '',
                            nextWaypoint: t.nextWaypoint ?? {},
                            activeEventRules: t.activeEventRules
                        } as VehicleTransport)
                ) ?? [],
            alarms: alarms.filter(a => a.monitoredObjectId === String(vs.monitoredObjectId)),
            puescStatus: currentVehicle?.puescStatus,
            lastChangeState: vs.last_change_state ?? undefined,
            charging: vs.charging ?? undefined,
            weight: vs.weight ?? undefined,
            adblue: vs.adblue_level ?? undefined,
            carBattery: vs.car_battery ?? undefined,
            externalDevices: vs.external_devices?.map(externalDevice => externalDevice),
            ...this.setAetrData(vs.driver)
        };

        const actualTransport: VehicleTransportObject =
            vs.activeTransports && vs.activeTransports.length > 0
                ? vs.activeTransports?.sort((a, b) => (moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1))?.[0]
                : {};

        if (actualTransport) {
            obj.actual = actual(actualTransport?.nextWaypoint?.rta, actualTransport?.nextWaypoint?.eta);
            if (actualTransport.nextWaypoint) {
                obj.destination = toAddress(
                    this._logic.auth().user().lang,
                    this._logic.auth().client()!,
                    actualTransport?.nextWaypoint?.addressStructured,
                    this._addressIdentification!,
                    actualTransport?.nextWaypoint?.name
                );
                obj.ETA = actualTransport.nextWaypoint.eta ?? '';
                obj.RTA = actualTransport.nextWaypoint.rta ?? '';
                obj.arrivalTimes.eta = actualTransport.nextWaypoint.eta ?? '';
                obj.arrivalTimes.rta = actualTransport.nextWaypoint.rta ?? '';
                obj.firstRTA = actualTransport.firstRta ?? '';
                obj.destinationAddressStructured = actualTransport?.nextWaypoint?.addressStructured ?? [];
            }
        }

        return obj;
    }

    private _setVehicleContactData() {
        const driverIds = this._selectedVehicle?.aetrData.map(aetr => aetr.driverId).toString();
        if (driverIds !== '') {
            if (this._logic.demo().isActive) {
                this._vehicles = this._vehicles.map<TrackingModel>(v =>
                    v.id === this._selectedVehicle?.id
                        ? {
                              ...v,
                              contactsData: v.aetrData.map(aetr => {
                                  const phoneNumbers = this._logic
                                      .demo()
                                      .data.usersSimpleInfo.find(user => String(user.id) === aetr.driverId)
                                      ?.contact?.phoneNumbers;
                                  return {
                                      driverId: aetr.driverId,
                                      driverPhone: phoneNumbers ? phoneNumbers.toString() : undefined
                                  };
                              })
                          }
                        : v
                );
                return;
            }

            this._logic
                .api()
                .newUserApi.userGetUsersSimpleInfo({
                    idIn: driverIds
                })
                .then(res => {
                    this._vehicles = this._vehicles.map<TrackingModel>(v =>
                        v.id === this._selectedVehicle?.id
                            ? {
                                  ...v,
                                  contactsData: v.aetrData.map(aetr => {
                                      const phoneNumbers = res.find(user => String(user.id) === aetr.driverId)?.contact
                                          ?.phoneNumbers;
                                      return {
                                          driverId: aetr.driverId,
                                          driverPhone: phoneNumbers ? phoneNumbers.toString() : undefined
                                      };
                                  })
                              }
                            : v
                    );
                });
        }
    }

    private setAetrData(driver?: VehicleDriverObject | null): {
        tacho?: TachoStatus | undefined;
        driverName: string;
        driverId: string;
        driverTachocard: string;
        nextBreak?: string;
        drivingDuration?: number;
        workingDuration?: number;
        restingDuration?: number;
        dailyUtilizationLeft?: number;
        dailyUtilizationMaxHours?: number;
        dailyDriveLeft?: number;
        dailyDriveMaxHours?: number;
        biweeklyDrivingLeft?: number;
        activityStartTime?: string;
        timeLeftToday?: number;
    } {
        const actualDrivingDuration =
            driver?.state_new?.activity === 'driving' && driver?.state_new?.timestamp
                ? moment.duration(moment.utc().diff(moment.utc(driver?.state_new?.timestamp).toDate())).asSeconds()
                : 0 ?? undefined;

        let dailyUtilizationLeft;
        let dailyDriveLeft;
        if (driver?.state_new?.activity === 'resting' && driver?.state_new?.dailyUtilizationMaxHours) {
            const currentRestPeriodDuration = moment.duration(moment.utc().diff(driver?.state_new?.activityStartTime));
            const nextDailyRestPerionDuration = (24 - driver?.state_new?.dailyUtilizationMaxHours) * 3600;
            if (currentRestPeriodDuration.asSeconds() >= nextDailyRestPerionDuration) {
                dailyUtilizationLeft = driver?.state_new?.dailyUtilizationMaxHours * 3600;
                dailyDriveLeft = (driver?.state_new?.dailyDriveMaxHours ?? 0) * 3600;
            }
        }
        if (!dailyUtilizationLeft) {
            dailyUtilizationLeft = Math.max(
                0,
                driver?.state_new?.dailyUtilizationEnd
                    ? moment.duration(moment.utc(driver?.state_new?.dailyUtilizationEnd).diff(moment.utc())).asSeconds()
                    : 0
            );
        }
        if (!dailyDriveLeft) {
            const driving = Math.max((driver?.state_new?.drivingDuration ?? 0) + actualDrivingDuration ?? 0, 0);
            const dailyDriveMaxSeconds = (driver?.state_new?.dailyDriveMaxHours ?? 0) * 3600;
            dailyDriveLeft = dailyDriveMaxSeconds >= driving ? dailyDriveMaxSeconds - driving : 0;
        }

        const biweeklyDrivingDuration = (driver?.state_new?.drivingDuration14days ?? 0) + actualDrivingDuration;
        const biweeklyDriving: number = Math.max(biweeklyDrivingDuration ?? 0, 0);
        const biweeklyDrivingLeft = MAX_BIWEEKLY_RIDE >= biweeklyDriving ? MAX_BIWEEKLY_RIDE - biweeklyDriving : 0;

        return {
            drivingDuration: (driver?.state_new?.drivingDuration ?? 0) + actualDrivingDuration,
            workingDuration: driver?.state_new?.workingDuration ?? 0,
            restingDuration: driver?.state_new?.restingDuration || undefined,
            dailyUtilizationLeft,
            dailyUtilizationMaxHours: driver?.state_new?.dailyUtilizationMaxHours ?? 0,
            dailyDriveLeft,
            dailyDriveMaxHours: driver?.state_new?.dailyDriveMaxHours ?? 0,
            biweeklyDrivingLeft,
            activityStartTime: driver?.state_new?.activityStartTime || undefined,
            timeLeftToday: driver?.state
                ? Math.round(
                      (36000 -
                          ((driver.state.activity === 'driving'
                              ? Math.abs(
                                    moment
                                        .duration(moment(driver!.state!.activityStartTime!).diff(moment()))
                                        .asSeconds()
                                )
                              : 0) +
                              driver.state.drivingDuration!)) /
                          3600
                  )
                : undefined,
            tacho: driver?.state_new?.activity ? (driver.state_new.activity as TachoStatus) : undefined,
            nextBreak: driver?.state_new?.nextBreakStartTime ?? '',
            driverName: driver?.name ?? '',
            driverId: driver?.id?.toString() ?? '',
            driverTachocard: driver?.driver_token?.toString() ?? ''
        };
    }

    settings() {
        return this._logic.settings().getProp('tracking');
    }

    setSettings(settings: Partial<Conf['settings']['tracking']>) {
        const originalSettings = this._logic.settings().getProp('tracking');
        const modifiedSettings = {
            ...originalSettings,
            ...settings,
            filter: {
                ...originalSettings.filter,
                ...settings.filter
            }
        };
        this._logic.settings().setProp('tracking', { ...modifiedSettings });
    }

    private _updateData() {
        this._onData?.(this._searchVehicles(this._filterVehicles(this._vehicles)), this._vehicles);
    }

    private _searchVehicles(vehicles: TrackingModel[]): TrackingModel[] {
        if (this._searchText.length > 0) {
            return search(this._searchText, ['driverName', 'destination', 'RN', 'location'], vehicles);
        } else {
            return vehicles;
        }
    }

    private _filterVehicles(vehicles: TrackingModel[]): TrackingModel[] {
        const { delay, status, monitoredObjectGroupsChecked, fuelLow, alarms, aetrLow } = {
            ...this.settings(),
            ...this._filter
        };
        const { tankSizeLimit, vehicleLimit, lightVehicleLimit } =
            this._logic.conf.settings.tracking.smartFilter.fuelLow;

        return vehicles
            .filter(m =>
                monitoredObjectGroupsChecked && monitoredObjectGroupsChecked.length > 0
                    ? m.monitoredObjectGroups
                          ?.map(t => String(t.id))
                          .some(moGroupId => monitoredObjectGroupsChecked?.some(checked => checked === moGroupId))
                    : true
            )
            .filter(m => {
                // @JaroSpanko
                // While any filter is active, invalid vehicles are hidden
                if (
                    (status !== VehicleStatusFilterCode.ALL_VEHICLE_STATUSES ||
                        delay !== VehicleDelayedFilterCode.ALL_ETAS) &&
                    (m.invalid || !m.location)
                ) {
                    return false;
                }

                return true;
            })
            .filter(m => {
                switch (status) {
                    case VehicleStatusFilterCode.ALL_VEHICLE_STATUSES:
                        return m;
                    case VehicleStatusFilterCode.VEHICLES_MOVING:
                        return m.status === 1 || m.status === 2;
                    case VehicleStatusFilterCode.VEHICLES_STANDING:
                        return m.status === 0;
                    default:
                        return m;
                }
            })
            .filter(m => {
                switch (delay) {
                    case VehicleDelayedFilterCode.ALL_ETAS:
                        return m;
                    case VehicleDelayedFilterCode.LESS_THAN_30_MINUTES_LATE:
                        if (m.arrivalTimes.eta && m.arrivalTimes.rta) {
                            const rtaWith30min = moment.utc(m.arrivalTimes.rta).add(30, 'minutes');
                            return (
                                moment.utc(m.arrivalTimes.eta).isBefore(rtaWith30min) &&
                                moment.utc(m.arrivalTimes.eta).isAfter(moment.utc(m.arrivalTimes.rta))
                            );
                        } else {
                            return false;
                        }
                    case VehicleDelayedFilterCode.MORE_THAN_30_MINUTES_LATE:
                        if (m.arrivalTimes.eta && m.arrivalTimes.rta) {
                            const rtaWith30min = moment.utc(m.arrivalTimes.rta).add(30, 'minutes');
                            return moment.utc(m.arrivalTimes.eta).isAfter(rtaWith30min);
                        } else {
                            return false;
                        }
                    case VehicleDelayedFilterCode.ON_TIME:
                        return m.actual === VehicleDelayedFilterCode.ON_TIME;
                    case VehicleDelayedFilterCode.RTA_NOT_ENTERED:
                        return !m.RTA || m.RTA === '-';
                    case VehicleDelayedFilterCode.LATE:
                        if (m.arrivalTimes.eta && m.arrivalTimes.rta) {
                            return moment.utc(m.arrivalTimes.eta).isAfter(moment.utc(m.arrivalTimes.rta));
                        } else {
                            return false;
                        }
                    default:
                        return m;
                }
            })
            .filter(m => {
                if (fuelLow) {
                    return !m.invalid &&
                        m.location &&
                        m.tank > 0 &&
                        ((m.tankSize && m.tank < m.tankSize * tankSizeLimit) ||
                            (!m.tankSize &&
                                m.tank <
                                    (m.monitoredObjectType?.name === MonitoredObjectTypeName.LIGHT_VEHICLE
                                        ? lightVehicleLimit
                                        : vehicleLimit)))
                        ? m
                        : false;
                } else {
                    return m;
                }
            })
            .filter(m => {
                if (alarms) {
                    return m.alarms.length ? m : false;
                } else {
                    return m;
                }
            })
            .filter(m => {
                if (aetrLow) {
                    return !m.invalid &&
                        m.location &&
                        (m.drivingDuration ?? 0) / ((m.dailyDriveMaxHours ?? 0) * 3600) > 0.75
                        ? m
                        : false;
                } else {
                    return m;
                }
            });
    }

    private _initMap() {
        if (this._active) {
            this._updateData();
            const vehicleData = this.getMapData().map(this.toMapModel);
            this._map.vehicles().setData(vehicleData);
            this._map.vehicles().show();
            this._logic.map().setControlCenterVisible(false);
        }
    }

    private _updateMap() {
        if (this._active) {
            this._updateData();
            const selectedVehicle = this._vehicles.find(v => v.id === this._selectedVehicle?.id);

            if (this.getMapData().length) {
                let data = this.getMapData().map(this.toMapModel);
                if (selectedVehicle) {
                    data = [this.toMapModel(selectedVehicle), ...data];
                }
                this._map.vehicles().update(data);
            } else if (selectedVehicle) {
                const data = [this.toMapModel(selectedVehicle)];
                this._map.vehicles().update(data);
            }

            if (this._transport && this._selectedVehicle) {
                const vehicle = this._vehicles.find(v => v.id === this._selectedVehicle?.id);
                const activeTransport = vehicle?.activeTransports?.sort((a, b) =>
                    moment(a.firstRta).isAfter(b.firstRta) ? 1 : -1
                );
                const transport = activeTransport?.[0]?.id;
                if (!transport) {
                    this._map.routing().destroy();
                }
            }
        }
    }

    private async _loadTransport(id: string): Promise<Transport> {
        const selectedBeforeFetch = this._vehicles.find(v => v.selected);
        const t = await this._fetchTransport(id);
        this._map.routing().destroy();
        if (
            t?.places?.[0].rta &&
            t?.places?.[t.places.length - 1].rta &&
            t.monitoredObjects?.find(t => t.endTime !== null)?.monitoredObjectId
        ) {
            const vehicleId = String(t.monitoredObjects?.find(t => t.endTime !== null)?.monitoredObjectId);
            this._transport = {
                name: t.name ?? '',
                vehicle: vehicleId ?? '',
                firstPlaceRta: t?.places?.[0].rta.toISOString(),
                lastPlaceRta: t?.places?.[t.places.length - 1].rta?.toISOString(),
                state: t.state ?? TransportState.Accepted,
                users: [],
                places:
                    t.places?.map(
                        p =>
                            ({
                                id: p.id ?? '',
                                center: latLngFromGeoJsonPointType(p.center ?? {}),
                                name: p.name ?? '',
                                note: p.note ?? '',
                                route: p.route,
                                eventStates: p.eventStates ?? [],
                                crossingPoints: p.crossingPoints
                            } as PlacesModel)
                    ) ?? []
            };

            const start = moment.utc(t.places?.[0].ata ? t.places?.[0].ata : t.places?.[0].rta).toISOString();

            const end = moment.utc().toISOString();

            this._trips = await this._fetchTrips(vehicleId, start, end);

            const checkedMultiple = this._vehicles.reduce<number>((acc, curr) => (curr.checked ? acc + 1 : acc), 0);
            const selectionChanged = this._vehicles.find(v => v.selected)?.id !== selectedBeforeFetch?.id;

            // while other action was made, don't render the route:
            // - selection of other vehicle
            // - checking more vehicles
            if (checkedMultiple > 1 || selectionChanged) {
                return t;
            }
            return t;
        }
        return t ?? {};
    }

    private _drawRouteOnMap() {
        if (!this._transport) {
            return;
        }

        const places = this._transport.places.map(place => ({
            id: place.id,
            lat: place.center.lat,
            lng: place.center.lng,
            disabledDragging: true,
            crossingPoints: place.crossingPoints
        }));

        const routes = this._transport.places
            .filter(p => p.route)
            .map(p => ({ route: p.route!, disabledDragging: true }));

        const trips = this._trips.reduce<string[]>((a, c) => {
            if (c.metadata?.polyline) {
                a.push(c.metadata.polyline);
            }
            return a;
        }, []);

        const vehicle = this._vehicles.filter(v => v.selected)[0];

        this._map.routing().renderRoute(places, routes, trips, true, true, this.toMapModel(vehicle));
    }

    private async _fetchTransport(transportId: string) {
        if (this._logic.demo().isActive) {
            return this._logic.demo().data.transports.find(t => t.id === transportId);
        }

        const transport = await this._logic.api().transportApi.findOneV1TransportsTransportIdGet({ transportId });
        return transport;
    }

    private async _fetchTrips(vehicleId: string, startTime: string, endTime: string): Promise<TripInDatabase[]> {
        try {
            if (this._logic.demo().isActive) {
                return this._logic
                    .demo()
                    .data.trips.filter(t => t.monitoredObjectId === vehicleId)
                    .filter(
                        t =>
                            moment(t.startTime) >= moment(startTime) &&
                            moment(t.startTime) < moment(endTime) &&
                            moment(t.endTime) > moment(startTime) &&
                            moment(t.endTime) <= moment(endTime)
                    );
            }

            const res = await this._logic.api().tripApi.getTripsApiV2TripsGet({
                dateFrom: moment(startTime).toDate(),
                dateTo: moment(endTime).toDate(),
                monitoredObjectId: vehicleId,
                wrapArray: true,
                sort: 1
            });

            // TODO: Remove when API will work like id should
            return (res.trips.filter(r => r) || []).filter(v => v.monitoredObjectId?.toString() === vehicleId);
        } catch (err) {
            console.log('Get trips err', err);
            throw err;
        }
    }

    private _toMonitoredObjectType(
        monitoredObjectType: MonitoredObjectTypeObject | undefined | null
    ): MonitoredObjectType {
        return {
            id: monitoredObjectType?.id ?? '',
            label: monitoredObjectType?.label ?? '',
            name: monitoredObjectType?.name ?? ''
        };
    }

    private _setVehicleUpdatesInterval(monitoredObjectId?: string | undefined): void {
        if (this._interval) {
            clearInterval(this._interval);
            this._interval = undefined;
        }
        if (this._zoomOutInterval) {
            clearInterval(this._zoomOutInterval);
            this._zoomOutInterval = undefined;
        }
        if (monitoredObjectId) {
            this._logic.vehiclesState().increaseVehicleUpdates(monitoredObjectId);
            this._interval = setInterval(() => {
                this._logic.vehiclesState().increaseVehicleUpdates(monitoredObjectId);
            }, INCREASE_VEHICLE_UPDATES_INTERVAL);
            this._zoomOutInterval = setInterval(() => {
                this.unselectVehicleCheckAll();
            }, AUTO_ZOOM_OUT_INTERVAL);
        }
    }

    private sortVehicles(vehicles: TrackingModel[]): TrackingModel[] {
        let vehiclesFiltered: {
            vehiclesNoGPS: TrackingModel[];
            vehiclesInvalid: TrackingModel[];
            vehiclesLightVehicle: TrackingModel[];
            vehiclesWithDestination: TrackingModel[];
            vehiclesOthers: TrackingModel[];
        } = {
            vehiclesNoGPS: [],
            vehiclesInvalid: [],
            vehiclesLightVehicle: [],
            vehiclesWithDestination: [],
            vehiclesOthers: []
        };
        vehicles.forEach(vehicle => {
            if (!(vehicle.GPS?.lng && vehicle.GPS.lat)) {
                vehiclesFiltered.vehiclesNoGPS.push(vehicle);
            } else if (vehicle.invalid) {
                vehiclesFiltered.vehiclesInvalid.push(vehicle);
            } else if (vehicle.monitoredObjectType?.name === MonitoredObjectTypeName.LIGHT_VEHICLE) {
                vehiclesFiltered.vehiclesLightVehicle.push(vehicle);
            } else if (vehicle.destination) {
                vehiclesFiltered.vehiclesWithDestination.push(vehicle);
            } else {
                vehiclesFiltered.vehiclesOthers.push(vehicle);
            }
        });

        return [
            ...vehiclesFiltered.vehiclesOthers.sort((a, b) => a.RN.localeCompare(b.RN)),
            ...vehiclesFiltered.vehiclesWithDestination.sort((a, b) => a.RN.localeCompare(b.RN)),
            ...vehiclesFiltered.vehiclesLightVehicle.sort((a, b) => a.RN.localeCompare(b.RN)),
            ...vehiclesFiltered.vehiclesInvalid.sort((a, b) => a.RN.localeCompare(b.RN)),
            ...vehiclesFiltered.vehiclesNoGPS.sort((a, b) => a.RN.localeCompare(b.RN))
        ];
    }

    private async getPuescStatus() {
        if (this._logic.demo().isActive) {
            const resDemo = this._logic.demo().data.puescStatus;
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                puescStatus: resDemo.find(a => a.monitoredObjectId === v.id)
            }));
        } else {
            const res = await this._logic.api().puescApi.getPuescUserStatusV2ActualVehicleStatePuescUserStatusGet({});
            this._vehicles = this._vehicles.map(v => ({
                ...v,
                puescStatus: res.find(a => a.monitoredObjectId === v.id)
            }));
        }
        this._updateData();
    }

    async getDriverBehaviorTrends(tachoCard?: string) {
        if (this._logic.auth().roles().includes(Role.DBH_R) && tachoCard) {
            const data = await this._logic
                .driverBehaviorCoach()
                .trucks()
                .loadPerfectDriveScoreData(moment().toISOString(), tachoCard);
            return data?.[0];
        }
        return undefined;
    }
}
