import { Logic } from '../logic';
import moment from 'moment';
import {
    MonitoredObjectOperationalCost,
    ReadOnlyCompanyOperationalCost,
    CompanyCosts,
    MonitoredObjectOperationalCosts,
    Cost as MainDataNewCost
} from 'generated/new-main';
import { DATE_FORMAT } from 'domain-constants';
import { AvailableCurrencies } from 'common/model/currency';
import { MoPricePerKmWithOperationalProfile, PricePerKmResult, MonitoredObjectFleetType } from 'generated/backend-api';
import { message } from 'antd';

export enum CostUnit {
    PerMonth = 'perMonth',
    PerQuartal = 'perQuartal',
    PerHalfYear = 'perHalfYear',
    PerYear = 'perYear',
    PerKilometer = 'perKilometer'
}

export interface Cost {
    currency: string;
    value: number;
    unit: CostUnit;
}

export interface CostFleet extends Cost {
    fleetType: MonitoredObjectFleetType;
}

export interface CostStructure extends MoPricePerKmWithOperationalProfile {
    selected: boolean;
}

export interface FixedCost {
    salaries: Cost;
    operations: Cost;
    crmInsurance: Cost;
    other: Cost;
}

export interface FixedCostModel {
    dateFrom?: string;
    dateTo?: string;
    costs?: FixedCost;
    defaults?: FixedCost;
}

export interface OperationalCost {
    id?: number;
    fuel: Cost;
    lngCng: Cost;
    electro: Cost;
    additives: Cost;
    oil: Cost;
    tires: Cost;
    maintenance: Cost;
    liabilityInsurance: Cost;
    collisionInsurance: Cost;
    personalInjuryInsurance: Cost;
    parking: Cost;
    cabinHeating: Cost;
    telecomunicationsAndMonitoring: Cost;
    washing: Cost;
    ewToll?: Cost;
    ewTelematics?: Cost;
    selected?: boolean;
}

export interface OperationalCostFleet extends OperationalCost {
    fleetType: MonitoredObjectFleetType;
}

export interface OperationalCostModel {
    dateFrom: string;
    dateTo?: string;
    costs?: OperationalCost;
    defaultsFleet?: OperationalCostFleet[];
}

export class StatisticsCompanyProfileLogic {
    constructor(private _logic: Logic) {}

    // Logic for default AVG MarketCost
    avgMarketCost = (
        pricePerKm: PricePerKmResult | undefined,
        fleetType: MonitoredObjectFleetType
    ): { costPerKm: number; currency: AvailableCurrencies } => {
        const marketAvg = pricePerKm?.marketAvgPricePerKmOfFleetTypes?.find(d => d.fleetType === fleetType);
        if (marketAvg && marketAvg.avgPricePerKm > 0 && marketAvg.numberOfCompanies >= 5) {
            return { costPerKm: marketAvg.avgPricePerKm, currency: marketAvg.currency as AvailableCurrencies };
        } else {
            const defaultMarketAvg = pricePerKm?.defaultMarketAvgPricePerKmOfFleetTypes?.find(
                d => d.fleetType === fleetType
            );
            return {
                costPerKm: defaultMarketAvg?.avgPricePerKm ?? 0,
                currency: (defaultMarketAvg?.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
            };
        }
    };

    priceOfKmByClient = (
        pricePerKm: PricePerKmResult | undefined,
        fleetType: MonitoredObjectFleetType
    ): { costPerKm: number; currency: AvailableCurrencies } | undefined => {
        const pricePerKmClient = pricePerKm?.avgPricePerKmOfFleetTypes?.find(
            pricePerKm => pricePerKm.fleetType === fleetType
        );
        if (pricePerKmClient?.avgPricePerKm && pricePerKmClient.avgPricePerKm > 0) {
            return {
                costPerKm: pricePerKmClient.avgPricePerKm,
                currency: (pricePerKmClient?.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
            };
        } else {
            return undefined;
        }
    };

    pricePerKmByVehicleOrClientOrMarketAvg = (
        pricePerKm: PricePerKmResult | undefined,
        fleetType: MonitoredObjectFleetType,
        monitoredObjectId: number
    ): { costPerKm: number; currency: AvailableCurrencies } => {
        const pricePerKmMobject = pricePerKm?.mobjects.find(
            pricePerKmObject => pricePerKmObject.monitoredObjectId === monitoredObjectId
        );
        if (pricePerKmMobject && pricePerKmMobject.avgPricePerKm > 0) {
            return {
                costPerKm: pricePerKmMobject?.avgPricePerKm ?? 0,
                currency: (pricePerKmMobject?.currency as AvailableCurrencies) ?? AvailableCurrencies.EUR
            };
        }
        const pricePerKmByClient = this.priceOfKmByClient(pricePerKm, fleetType);
        if (pricePerKmByClient?.costPerKm && pricePerKmByClient.costPerKm > 0) {
            return pricePerKmByClient;
        } else {
            return this.avgMarketCost(pricePerKm, fleetType);
        }
    };

    async computeCosts() {
        const resp = await this._logic.api().costsApi.computeCostsV1CostsComputeCostsPost({
            dateFrom: moment().subtract(1, 'month').toDate(),
            dateTo: moment().toDate()
        });
        return { value: resp.avgPricePerKm, currency: resp.currency };
    }

    async pricePerKmCostStructure(
        monitoredObjectIds: number[],
        onlyPrices: boolean = false
    ): Promise<PricePerKmResult> {
        if (this._logic.demo().isActive) {
            const demoResp: PricePerKmResult = this._logic.demo().data.costs;
            return demoResp;
        } else {
            try {
                const resp = await this._logic.api().costsApi.pricePerKmV1CostsPricePerKmGet({
                    mobjectIds: monitoredObjectIds.length ? monitoredObjectIds : undefined,
                    onlyPrices
                });
                return resp;
            } catch (err) {
                console.error('Price per Km cost structure GET err:', err);
                message.error(`Price per Km cost structure GET err:, ${err}`);
                throw err;
            }
        }
    }

    async monitoredObjectOperationalCostCreate(monitoredObjectId: number, data: OperationalCostModel) {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostCreate({
                    data: {
                        monitoredObject: monitoredObjectId,
                        costs: data.costs as MonitoredObjectOperationalCosts,
                        dateFrom: moment(data.dateFrom).toDate(),
                        dateTo: moment(data.dateTo).toDate()
                    }
                });
            return response;
        } catch (err) {
            console.log('MonitoredObject operational cost CREATE err:', err);
            message.error(`MonitoredObject operational cost CREATE err:, ${err}`);
            throw err;
        }
    }

    async monitoredObjectOperationalCostDelete(operationalCostId: number) {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostDelete({
                    id: operationalCostId
                });
            return response;
        } catch (err) {
            console.error('MonitoredObject operational cost DELETE err:', err);
            message.error(`MonitoredObject operational cost DELETE err:, ${err}`);
            throw err;
        }
    }

    async monitoredObjectOperationalCostCreateMany(
        data: {
            monitoredObjectIds: number[];
            operationalCostData: OperationalCost;
        }[]
    ): Promise<OperationalCostModel[]> {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostCreateMany({
                    data: data.map(d => ({
                        monitoredObjects: d.monitoredObjectIds,
                        costs: d.operationalCostData
                    }))
                });
            return response.map(this._toOperationalCost);
        } catch (err) {
            console.log('MonitoredObject many operational cost CREATE err:', err);
            throw err;
        }
    }

    async monitoredObjectOperationalCostList(
        monitoredObjectIds?: string[],
        active?: boolean
    ): Promise<OperationalCostModel[]> {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostList({
                    mobjectIn: monitoredObjectIds ? monitoredObjectIds.toString() : undefined,
                    active: active
                });
            return response.results.map(this._toOperationalCost);
        } catch (err) {
            console.log('MonitoredObject operational cost list GET err:', err);
            message.error(`MonitoredObject operational cost list GET err:, ${err}`);
            throw err;
        }
    }

    async monitoredObjectOperationalCostRead(monitoredObjectId: string): Promise<OperationalCostModel> {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostRead({
                    id: Number(monitoredObjectId)
                });
            return this._toOperationalCost(response);
        } catch (err) {
            console.log('MonitoredObject operational cost GET err:', err);
            message.error(`MonitoredObject operational cost GET err:, ${err}`);
            throw err;
        }
    }
    async monitoredObjectOperationalCostDefaultMarketAverages(
        currency = AvailableCurrencies.EUR
    ): Promise<OperationalCost> {
        try {
            const response = await this._logic
                .api()
                .newMonitoredObjectOperationalCostApi.monitoredObjectOperationalCostDefaultMarketAverage({});

            const exchangeRate = (this._logic.poi().currencies ?? []).find(e => e.code === currency);
            if (exchangeRate) {
                const [exchange] = exchangeRate.latestExchangeRate ?? [];
                const entries = Object.entries(response.costs) as Array<[keyof CompanyCosts, MainDataNewCost]>;
                const resp = entries.map(([k, v]) => [
                    k,
                    {
                        ...v,
                        currency,
                        value: currency === 'EUR' ? v.value : Math.round(v.value * (exchange.rate ?? 1) * 100) / 100
                    } as MainDataNewCost
                ]);
                return Object.fromEntries(resp);
            }

            return response.costs as OperationalCost;
        } catch (err) {
            console.log('MonitoredObject operational cost default market averages GET err:', err);
            message.error(`MonitoredObject operational cost default market averages GET err:, ${err}`);
            throw err;
        }
    }

    async companyOperationalCostList(active?: boolean): Promise<FixedCostModel[]> {
        try {
            const response = await this._logic.api().newCompanyOperationalCost.companyOperationalCostList({
                active
            });
            return response.results.map(this._toFixedCost);
        } catch (err) {
            console.log('Company operational cost default market averages  GET err:', err);
            message.error(`Company operational cost default market averages  GET err:, ${err}`);
            throw err;
        }
    }

    async companyOperationalCostCreate(data: FixedCostModel): Promise<FixedCostModel> {
        try {
            const response = await this._logic.api().newCompanyOperationalCost.companyOperationalCostCreate({
                data: {
                    costs: data.costs as CompanyCosts
                }
            });
            return this._toFixedCost(response);
        } catch (err) {
            console.log('Company operational cost CREATE err:', err);
            throw err;
        }
    }

    async companyOperationalCostDelete(fixedCostId: number): Promise<boolean> {
        try {
            const response = await this._logic.api().newCompanyOperationalCost.companyOperationalCostDelete({
                id: fixedCostId
            });
            return response ? true : false;
        } catch (err) {
            console.log('Company operational cost  DELETE err:', err);
            message.error(`Company operational cost DELETE err:, ${err}`);
            throw err;
        }
    }

    async companyOperationalCostDefaultMarketAverages(currency = AvailableCurrencies.EUR): Promise<FixedCost> {
        try {
            const response = await this._logic
                .api()
                .newCompanyOperationalCost.companyOperationalCostDefaultMarketAverage({});

            const exchangeRate = (this._logic.poi().currencies ?? []).find(e => e.code === currency);
            if (exchangeRate) {
                const entries = Object.entries(response.costs) as Array<[keyof CompanyCosts, MainDataNewCost]>;
                const resp = entries.map(([k, v]) => [
                    k,
                    {
                        ...v,
                        currency,
                        value: currency === 'EUR' ? v.value : Math.round(v.value * exchangeRate.number * 100) / 100
                    } as MainDataNewCost
                ]);
                return Object.fromEntries(resp);
            }

            return response.costs as FixedCost;
        } catch (err) {
            console.log('Company operational cost default market averages  GET err:', err);
            message.error(`Company operational cost default market averages  GET err:, ${err}`);
            throw err;
        }
    }

    private _toOperationalCost(data: MonitoredObjectOperationalCost): OperationalCostModel {
        return {
            dateFrom: moment(data.dateFrom).format(DATE_FORMAT) ?? '',
            dateTo: moment(data.dateTo).format(DATE_FORMAT) ?? '',
            costs: data.costs as OperationalCost
        };
    }
    private _toFixedCost(data: ReadOnlyCompanyOperationalCost): FixedCostModel {
        return {
            dateFrom: moment(data.dateFrom).format(DATE_FORMAT) ?? '',
            dateTo: moment(data.dateTo).format(DATE_FORMAT) ?? '',
            costs: data.costs as FixedCost
        };
    }
}
