import React from 'react';
import cn from 'classnames';
import { Menu } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AlarmType } from 'generated/backend-api';
import Dropdown from '../Dropdown';
import { Alarm } from 'common/model/alarm';
import { AlarmUniqueData, getColdchainAlarmDescription } from 'modules/alarms/ui/AlarmBlock';

interface Props {
    alarms: Alarm[];
    onAlarmDismiss?: (id: string[]) => void;
}

export default function AlarmsInfoIcon({ alarms = [], onAlarmDismiss }: Props) {
    const { t } = useTranslation();

    function alarmTypeToTranslation(type: AlarmType) {
        switch (type) {
            case AlarmType.BatteryLow:
                return 'batteryVoltageDrop';
            case AlarmType.ConnectionLoss:
                return 'lossOfComunicationVehicle';
            case AlarmType.UnavailableGps:
                return 'lossOfGps';
            case AlarmType.TransportColdChainProfileTemperatureHigh:
                return 'transportColdChainProfileTemperatureHigh';
            case AlarmType.TransportColdChainProfileTemperatureLow:
                return 'transportColdChainProfileTemperatureLow';
            default:
                return 'lossOfComunicationVehicle';
        }
    }

    return (
        <Dropdown
            overlay={
                <Menu>
                    {alarms.map(alarm => (
                        <Menu.Item
                            className={cn({
                                'alarms-info-warning': alarm.acknowledged && !alarm.canceled
                            })}
                            key={alarm.alarmId}
                        >
                            {`${t(`Alarms.${alarmTypeToTranslation(alarm.alarmType)}`)}${getColdchainAlarmDescription(
                                alarm.alarmType,
                                alarm?.uniqueData as AlarmUniqueData
                            )} ${alarm.startDateTime ? moment.utc(alarm.startDateTime).local().format('L LT') : ''}`}
                        </Menu.Item>
                    ))}
                </Menu>
            }
            placement="bottomLeft"
            overlayClassName={cn('alarms-info-icon-list', {
                'alarms-info-icon-list-unseen': alarms.some(a => !a.acknowledged)
            })}
        >
            <i
                onClick={() => onAlarmDismiss?.(alarms.map(a => a.alarmId))}
                className={cn('alarms-info-icon fa fa-exclamation-triangle', {
                    'alarms-info-icon-unseen': alarms.some(a => !a.acknowledged)
                })}
            />
        </Dropdown>
    );
}
