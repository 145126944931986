import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { Col, Row } from 'antd';
import qa from 'qa-selectors';
import { trailer, truckCabin } from 'resources/images/common';
import { FleetType } from 'modules/management/modules/fleet/FleetModule';
import { getMonitoredObjectZoneImage } from 'common/utils/components/Coldchain';
import { Button, Loading } from 'common/components';
import { ColdchainTemperatureSensorModel } from '../../coldchain-logic';
import { COLD_CHAIN_PROFILE_ID, degC } from 'domain-constants';
import numeral from 'numeral';
import { NoData } from 'common/components/NoData';
import React, { Fragment } from 'react';
import { ReadOnlyMonitoredObjectFeSb, ReadOnlyProfileSerializer } from 'generated/new-main';
import { presentationChart } from 'resources/images/coldchain';

interface Props extends WithTranslation {
    tableRowRef?: React.RefObject<HTMLTableRowElement>;
    scrolledToId?: string;
    data: ColdchainTemperatureSensorModel[];
    vehicles: ReadOnlyMonitoredObjectFeSb[];
    trailers: ReadOnlyMonitoredObjectFeSb[];
    profiles: ReadOnlyProfileSerializer[];
    loading?: boolean;
    onRowFleetClick?: (monitoredObjectId: number) => void;
    onRowDetailClick?: (monitoredObjectId: number, sensorZone?: number, sensorSerialNumbers?: string[]) => void;
}

function ColdchainTable(props: Props) {
    const tableHead = (
        <>
            <colgroup>
                <col style={{ width: '250px' }} />
                <col style={{ width: '50px' }} />
                <col style={{ width: '330px' }} />
                <col style={{ width: '276px' }} />
                <col style={{ width: '200px' }} />
                <col style={{ width: '460px' }} />
                <col style={{ width: '120px' }} />
            </colgroup>
            <thead className="ant-table-thead">
                <tr>
                    <th className="ant-table-cell" data-qa={qa.coldChain.table.headingRn}>
                        {props.t('common.vehicle')} / {props.t('common.trailer')}
                    </th>
                    <th className="ant-table-cell" data-qa={qa.coldChain.table.headingZone}>
                        {props.t('Coldchain.zones')}
                    </th>
                    <th className="ant-table-cell" data-qa={qa.coldChain.table.headingName}>
                        {props.t('Coldchain.name')}
                    </th>
                    <th className="ant-table-cell" data-qa={qa.coldChain.table.headingType}>
                        {props.t('common.type')}
                    </th>
                    <th
                        className="ant-table-cell coldchain-table-temperature"
                        data-qa={qa.coldChain.table.headingTemperature}
                    >
                        {props.t('Coldchain.temperature')}
                    </th>
                    <th className="ant-table-cell" data-qa={qa.coldChain.table.headingProfile}>
                        {props.t('Coldchain.currentAlertsConfiguration')}
                    </th>
                    <th className="ant-table-cell" />
                </tr>
            </thead>
        </>
    );

    if (props.loading) {
        return <Loading />;
    }

    if (!props.data.length) {
        return (
            <div className="ant-table coldchain-table">
                <div className="ant-table-container">
                    <table>{tableHead}</table>
                </div>
                <NoData />
            </div>
        );
    }

    return (
        <div className="ant-table coldchain-table">
            <div className="ant-table-container">
                <table>
                    {tableHead}
                    <tbody className="ant-table-tbody">
                        {props.data.map((sensor, index) => {
                            const sensorsInZone = props.data.filter(
                                d => d.monitoredObject === sensor.monitoredObject && d.sensorZone === sensor.sensorZone
                            );

                            const allSerialNumbersInZone = sensorsInZone.map(d => d.serialNumber);

                            const monitoredObject = props.vehicles
                                .concat(props.trailers)
                                .find(d => d.id === sensor.monitoredObject);

                            const externalDevicesData: ColdchainTemperatureSensorModel[] = props.data.filter(
                                d => d.monitoredObject === sensor.monitoredObject
                            );

                            const previous: ColdchainTemperatureSensorModel | undefined =
                                externalDevicesData.length > 1 && index !== undefined
                                    ? props.data[index - 1]
                                    : undefined;
                            const zones = externalDevicesData.map(d => d.sensorZone ?? 0);
                            const zoneMax = Math.max(...zones);

                            const rowSpanMo =
                                previous?.monitoredObject === sensor.monitoredObject ? 0 : externalDevicesData.length;
                            const rowSpanZone =
                                previous?.monitoredObject === sensor.monitoredObject &&
                                previous?.sensorZone === sensor.sensorZone
                                    ? 0
                                    : sensorsInZone.length;

                            const uniqueProfilesForZone = [
                                ...new Set(
                                    sensorsInZone.reduce((uniqueProfiles: string[], zoneSensor) => {
                                        let profilesIds: string[] = [];
                                        zoneSensor?.monitoredObjectState?.activeTransports?.forEach(activeTransport => {
                                            activeTransport?.activeEventRules?.forEach(rule => {
                                                const profileId: string =
                                                    rule?.name ===
                                                    `transport_cold_chain_zone_${zoneSensor.sensorZone ?? 1}`
                                                        ? String(
                                                              rule?.config?.find(
                                                                  item =>
                                                                      item.name === COLD_CHAIN_PROFILE_ID && item.value
                                                              )?.value
                                                          ) ?? ''
                                                        : '';
                                                profilesIds = [...profilesIds, profileId];
                                            });
                                        });
                                        return [...uniqueProfiles, ...profilesIds];
                                    }, [])
                                )
                            ].filter(profile => profile);

                            const transportsForZone = uniqueProfilesForZone.map(profileId => {
                                return sensorsInZone.map(zoneSensor =>
                                    zoneSensor?.monitoredObjectState?.activeTransports?.map(activeTransport => {
                                        return activeTransport?.activeEventRules?.find(rule => {
                                            const ruleProfileIds = rule?.config?.map(item =>
                                                item.name === COLD_CHAIN_PROFILE_ID ? String(item.value) : undefined
                                            );
                                            return (
                                                rule?.name ===
                                                    `transport_cold_chain_zone_${zoneSensor.sensorZone ?? 1}` &&
                                                ruleProfileIds?.includes(profileId)
                                            );
                                        });
                                    })
                                ); // Only show "unique profiles"
                            });

                            const alertConfigurationColumn = uniqueProfilesForZone?.map(uniqueProfile => {
                                const rule = transportsForZone.map(activeEventRules =>
                                    activeEventRules.find(rules =>
                                        rules?.map(rule =>
                                            rule?.config
                                                ?.map(item =>
                                                    item.name === COLD_CHAIN_PROFILE_ID ? String(item.value) : undefined
                                                )
                                                .includes(uniqueProfile)
                                                ? rule
                                                : undefined
                                        )
                                    )
                                )?.[0]?.[0]; // UNIQUE PROFILES for zone

                                if (rule?.name !== `transport_cold_chain_zone_${sensor.sensorZone ?? 1}`) {
                                    return null;
                                }
                                console.log('rule', rule);
                                const thresholdsBelow = rule?.config?.find(
                                    item => item.name === 'below_temperature_threshold'
                                )?.value;
                                const thresholdAbove = rule?.config?.find(
                                    item => item.name === 'above_temperature_threshold'
                                )?.value;
                                const thresholdTimer = rule?.config?.find(
                                    item => item.name === 'alarm_timer_seconds'
                                )?.value;
                                const profile = props.profiles.find(
                                    p =>
                                        p.id &&
                                        String(
                                            rule?.config?.find(item =>
                                                item.name === COLD_CHAIN_PROFILE_ID ? String(item.value) : undefined
                                            )?.value
                                        ) === p.id
                                );

                                return (
                                    profile?.name && (
                                        <div key={`${index}-${profile.id}`}>
                                            {profile.name}
                                            {thresholdsBelow || thresholdAbove || thresholdTimer ? (
                                                <>
                                                    {' '}
                                                    / {props.t('Coldchain.temperature')}{' '}
                                                    {thresholdsBelow ? (
                                                        <Fragment key="threshold-below">
                                                            <span className="coldchain-configuration-text">
                                                                {props.t('common.below')}
                                                            </span>{' '}
                                                            <span data-qa={qa.coldChain.table.fieldTemperatureBelow}>
                                                                {numeral(thresholdsBelow).format('0,0')}
                                                            </span>{' '}
                                                            {degC}
                                                        </Fragment>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {thresholdAbove ? (
                                                        <Fragment key="threshold-above">
                                                            {thresholdsBelow ? <>, </> : ''}
                                                            <span className="coldchain-configuration-text">
                                                                {props.t('common.above')}
                                                            </span>{' '}
                                                            <span data-qa={qa.coldChain.table.fieldTemperatureAbove}>
                                                                {numeral(thresholdAbove).format('0,0')}
                                                            </span>{' '}
                                                            {degC}
                                                        </Fragment>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {thresholdTimer ? (
                                                        <span
                                                            key="threshold-timer"
                                                            data-qa={qa.coldChain.table.fieldAlarmTimerSeconds}
                                                        >
                                                            {thresholdsBelow || thresholdAbove ? <>, </> : ''}
                                                            {props.t('Coldchain.thresholdTimer', {
                                                                timer: numeral(Number(thresholdTimer) / 60).format(
                                                                    '0,0'
                                                                )
                                                            })}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                    )
                                );
                            });

                            return (
                                <Fragment key={index}>
                                    {rowSpanMo > 0 && (
                                        <tr className="coldchain-table-row-space">
                                            <th className="ant-table-cell" colSpan={5}>
                                                {' '}
                                            </th>
                                        </tr>
                                    )}
                                    <tr
                                        key={index}
                                        ref={props.tableRowRef}
                                        className={cn('ant-table-row', {
                                            'ant-table-row-group-first':
                                                index !== undefined &&
                                                props.data[index]?.monitoredObject !==
                                                    props.data[index - 1]?.monitoredObject,
                                            'ant-table-row-group-last':
                                                index + 1 > props.data.length - 1 ||
                                                props.data[index]?.monitoredObject !==
                                                    props.data[index + 1]?.monitoredObject
                                        })}
                                    >
                                        {rowSpanMo > 0 && (
                                            <td
                                                className="ant-table-cell coldchain-table-cell-label pointer"
                                                rowSpan={rowSpanMo}
                                                onClick={() =>
                                                    props.onRowDetailClick?.(
                                                        sensor.monitoredObject,
                                                        sensor.sensorZone,
                                                        allSerialNumbersInZone
                                                    )
                                                }
                                            >
                                                <Row data-qa={qa.coldChain.table.fieldName} gutter={8} align="middle">
                                                    <Col>
                                                        <img
                                                            src={
                                                                (monitoredObject?.fleetType as FleetType) ===
                                                                FleetType.VEHICLE
                                                                    ? truckCabin
                                                                    : trailer
                                                            }
                                                            alt="vehicle-trailer"
                                                        />
                                                    </Col>
                                                    <Col>{monitoredObject?.registrationNumber}</Col>
                                                </Row>
                                            </td>
                                        )}
                                        {rowSpanZone > 0 && sensor.sensorZone && (
                                            <td
                                                className="ant-table-cell coldchain-table-cell-label pointer"
                                                rowSpan={rowSpanZone}
                                                onClick={() =>
                                                    props.onRowDetailClick?.(
                                                        sensor.monitoredObject,
                                                        sensor.sensorZone,
                                                        allSerialNumbersInZone
                                                    )
                                                }
                                            >
                                                <Row align="middle" justify="center">
                                                    <Col>
                                                        <img
                                                            src={getMonitoredObjectZoneImage(
                                                                (monitoredObject?.fleetType as FleetType) ===
                                                                    FleetType.VEHICLE
                                                                    ? 'truck'
                                                                    : 'trailer',
                                                                sensor.sensorZone,
                                                                zoneMax
                                                            )}
                                                            alt="zone"
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        )}
                                        <td
                                            className="ant-table-cell pointer"
                                            onClick={() =>
                                                props.onRowDetailClick?.(sensor.monitoredObject, sensor.sensorZone, [
                                                    sensor.serialNumber
                                                ])
                                            }
                                        >
                                            <span data-qa={qa.coldChain.table.fieldName}>{sensor.sensorName}</span>
                                        </td>
                                        <td
                                            className="ant-table-cell pointer"
                                            onClick={() =>
                                                props.onRowDetailClick?.(sensor.monitoredObject, sensor.sensorZone, [
                                                    sensor.serialNumber
                                                ])
                                            }
                                        >
                                            <span data-qa={qa.coldChain.table.fieldType}>
                                                {props.t(`common.${sensor.sensorType}`)}
                                            </span>
                                        </td>
                                        <td
                                            className="ant-table-cell coldchain-table-temperature pointer"
                                            onClick={() =>
                                                props.onRowDetailClick?.(sensor.monitoredObject, sensor.sensorZone, [
                                                    sensor.serialNumber
                                                ])
                                            }
                                        >
                                            {sensor.temperature !== undefined && (
                                                <span data-qa={qa.coldChain.table.fieldTemperature}>
                                                    {numeral(sensor.temperature).format('0,0.0')} {degC}
                                                </span>
                                            )}
                                        </td>
                                        {rowSpanZone > 0 && sensor.sensorZone && (
                                            <td
                                                className="ant-table-cell pointer"
                                                rowSpan={rowSpanZone}
                                                onClick={() =>
                                                    props.onRowDetailClick?.(
                                                        sensor.monitoredObject,
                                                        sensor.sensorZone,
                                                        allSerialNumbersInZone
                                                    )
                                                }
                                            >
                                                {alertConfigurationColumn}
                                            </td>
                                        )}
                                        {rowSpanMo > 0 && (
                                            <td rowSpan={rowSpanMo}>
                                                <Row justify="center" gutter={[8, 8]}>
                                                    <Col>
                                                        <Button
                                                            type="default"
                                                            icon={<img src={truckCabin} alt="fleet" />}
                                                            size="middle"
                                                            title={props.t('common.fleet')}
                                                            onClick={() =>
                                                                props.onRowFleetClick?.(sensor.monitoredObject)
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type="default"
                                                            icon={<img src={presentationChart} alt="chart" />}
                                                            size="middle"
                                                            title={props.t('Coldchain.timelineReport')}
                                                            onClick={() =>
                                                                props.onRowDetailClick?.(
                                                                    sensor.monitoredObject,
                                                                    sensor.sensorZone,
                                                                    allSerialNumbersInZone
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        )}
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default withTranslation()(ColdchainTable);
