/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20024,
    InlineResponse20024FromJSON,
    InlineResponse20024ToJSON,
    InlineResponse20025,
    InlineResponse20025FromJSON,
    InlineResponse20025ToJSON,
    MonitoredObjectGroup,
    MonitoredObjectGroupFromJSON,
    MonitoredObjectGroupToJSON,
    MonitoredObjectGroupReplaceAssets,
    MonitoredObjectGroupReplaceAssetsFromJSON,
    MonitoredObjectGroupReplaceAssetsToJSON,
    MonitoredObjectGroupReplaceAssetsUsers,
    MonitoredObjectGroupReplaceAssetsUsersFromJSON,
    MonitoredObjectGroupReplaceAssetsUsersToJSON,
    MonitoredObjectIdList,
    MonitoredObjectIdListFromJSON,
    MonitoredObjectIdListToJSON,
    ReadOnlyMonitoredObjectGroup,
    ReadOnlyMonitoredObjectGroupFromJSON,
    ReadOnlyMonitoredObjectGroupToJSON,
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyMonitoredObjectGroupNestedFromJSON,
    ReadOnlyMonitoredObjectGroupNestedToJSON,
    WriteOnlyMonitoredObjectGroup,
    WriteOnlyMonitoredObjectGroupFromJSON,
    WriteOnlyMonitoredObjectGroupToJSON,
} from '../models';

export interface MonitoredObjectGroupAddAssetRequest {
    id: number;
    data: MonitoredObjectIdList;
}

export interface MonitoredObjectGroupCreateRequest {
    data: WriteOnlyMonitoredObjectGroup;
}

export interface MonitoredObjectGroupDeleteRequest {
    id: number;
}

export interface MonitoredObjectGroupListRequest {
    nameIcontains?: string;
    nameIexact?: string;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupNestedRequest {
    nameIcontains?: string;
    nameIexact?: string;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupNestedSingleRequest {
    id: number;
}

export interface MonitoredObjectGroupPartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroup;
}

export interface MonitoredObjectGroupReadRequest {
    id: number;
}

export interface MonitoredObjectGroupRemoveAssetRequest {
    id: number;
    data: MonitoredObjectIdList;
}

export interface MonitoredObjectGroupReplaceAssetRequest {
    id: number;
    data: MonitoredObjectGroupReplaceAssets;
}

export interface MonitoredObjectGroupReplaceAssetUserRequest {
    id: number;
    data: MonitoredObjectGroupReplaceAssetsUsers;
}

export interface MonitoredObjectGroupUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroup;
}

/**
 * no description
 */
export class MonitoredObjectGroupApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectGroupAddAssetRaw(requestParameters: MonitoredObjectGroupAddAssetRequest): Promise<runtime.ApiResponse<MonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupAddAsset.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupAddAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/add-asset/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectIdListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupAddAsset(requestParameters: MonitoredObjectGroupAddAssetRequest): Promise<MonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupAddAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupCreateRaw(requestParameters: MonitoredObjectGroupCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupCreate(requestParameters: MonitoredObjectGroupCreateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupDeleteRaw(requestParameters: MonitoredObjectGroupDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupDelete(requestParameters: MonitoredObjectGroupDeleteRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupListRaw(requestParameters: MonitoredObjectGroupListRequest): Promise<runtime.ApiResponse<InlineResponse20024>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20024FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupList(requestParameters: MonitoredObjectGroupListRequest): Promise<InlineResponse20024> {
        const response = await this.monitoredObjectGroupListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupNestedRaw(requestParameters: MonitoredObjectGroupNestedRequest): Promise<runtime.ApiResponse<InlineResponse20025>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20025FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupNested(requestParameters: MonitoredObjectGroupNestedRequest): Promise<InlineResponse20025> {
        const response = await this.monitoredObjectGroupNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupNestedSingleRaw(requestParameters: MonitoredObjectGroupNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/nested/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupNestedFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupNestedSingle(requestParameters: MonitoredObjectGroupNestedSingleRequest): Promise<ReadOnlyMonitoredObjectGroupNested> {
        const response = await this.monitoredObjectGroupNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupPartialUpdateRaw(requestParameters: MonitoredObjectGroupPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupPartialUpdate(requestParameters: MonitoredObjectGroupPartialUpdateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupReadRaw(requestParameters: MonitoredObjectGroupReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupRead(requestParameters: MonitoredObjectGroupReadRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupRemoveAssetRaw(requestParameters: MonitoredObjectGroupRemoveAssetRequest): Promise<runtime.ApiResponse<MonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupRemoveAsset.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupRemoveAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/remove-asset/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectIdListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupRemoveAsset(requestParameters: MonitoredObjectGroupRemoveAssetRequest): Promise<MonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupRemoveAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupReplaceAssetRaw(requestParameters: MonitoredObjectGroupReplaceAssetRequest): Promise<runtime.ApiResponse<MonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupReplaceAsset.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupReplaceAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/replace-asset/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectGroupReplaceAssetsToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupReplaceAsset(requestParameters: MonitoredObjectGroupReplaceAssetRequest): Promise<MonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupReplaceAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupReplaceAssetUserRaw(requestParameters: MonitoredObjectGroupReplaceAssetUserRequest): Promise<runtime.ApiResponse<MonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupReplaceAssetUser.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupReplaceAssetUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/replace-asset-user/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectGroupReplaceAssetsUsersToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupReplaceAssetUser(requestParameters: MonitoredObjectGroupReplaceAssetUserRequest): Promise<MonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupReplaceAssetUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupUpdateRaw(requestParameters: MonitoredObjectGroupUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupUpdate(requestParameters: MonitoredObjectGroupUpdateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupUpdateRaw(requestParameters);
        return await response.value();
    }

}
