/* tslint:disable */
/* eslint-disable */
/**
 * ald-routing-api
 * Node.js OpenAPI implemented by TSOA
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Price,
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
    RouteCostsDetailsCountrySegment,
    RouteCostsDetailsCountrySegmentFromJSON,
    RouteCostsDetailsCountrySegmentFromJSONTyped,
    RouteCostsDetailsCountrySegmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface RouteCostsDetailsCountry
 */
export interface RouteCostsDetailsCountry {
    /**
     * 
     * @type {Price}
     * @memberof RouteCostsDetailsCountry
     */
    price: Price;
    /**
     * 
     * @type {number}
     * @memberof RouteCostsDetailsCountry
     */
    tollDistance: number;
    /**
     * 
     * @type {string}
     * @memberof RouteCostsDetailsCountry
     */
    countryCode: string;
    /**
     * 
     * @type {Array<RouteCostsDetailsCountrySegment>}
     * @memberof RouteCostsDetailsCountry
     */
    segments: Array<RouteCostsDetailsCountrySegment>;
}

export function RouteCostsDetailsCountryFromJSON(json: any): RouteCostsDetailsCountry {
    return RouteCostsDetailsCountryFromJSONTyped(json, false);
}

export function RouteCostsDetailsCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteCostsDetailsCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': PriceFromJSON(json['price']),
        'tollDistance': json['toll_distance'],
        'countryCode': json['countryCode'],
        'segments': ((json['segments'] as Array<any>).map(RouteCostsDetailsCountrySegmentFromJSON)),
    };
}

export function RouteCostsDetailsCountryToJSON(value?: RouteCostsDetailsCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': PriceToJSON(value.price),
        'toll_distance': value.tollDistance,
        'countryCode': value.countryCode,
        'segments': ((value.segments as Array<any>).map(RouteCostsDetailsCountrySegmentToJSON)),
    };
}


