import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { CheckboxGroup, CheckboxGroupOpts } from 'common/components/CheckboxGroup';
import qa from 'qa-selectors';
import TableFilter from 'common/components/TableFilter';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    driversOpts: CheckboxGroupOpts;
    vehiclesOpts: CheckboxGroupOpts;
    driversChecked: string[];
    vehiclesChecked: string[];
    onConfirm?: (driversChecked: string[], vehiclesChecked: string[]) => void;
    onCancel?: () => void;
}

interface State {
    driversChecked: string[];
    vehiclesChecked: string[];
}

class AetrFilter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            driversChecked: props.driversChecked,
            vehiclesChecked: props.vehiclesChecked
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.driversChecked.length !== this.props.driversChecked.length) {
            this.setState({ driversChecked: this.props.driversChecked });
        }
        if (prevProps.vehiclesChecked.length !== this.props.vehiclesChecked.length) {
            this.setState({ vehiclesChecked: this.props.vehiclesChecked });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <TableFilter
                className="aetr-filter"
                qa={qa.aetr.filter.section}
                heading={t('AetrFilter.name')}
                body={
                    <Row gutter={[24, 24]}>
                        {this.props.driversOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.drivers')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allDrivers')}
                                    options={this.props.driversOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.driversChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxDriversChange}
                                />
                            </Col>
                        )}
                        {this.props.vehiclesOpts.length > 0 && (
                            <Col>
                                <CheckboxGroup
                                    heading={t('common.vehicles')}
                                    scrolled={true}
                                    labelCheckAll={t('common.allVehicles')}
                                    options={this.props.vehiclesOpts.map(v => ({
                                        code: v.code,
                                        label: v.label,
                                        checked: this.state.vehiclesChecked.includes(v.code)
                                    }))}
                                    onChange={this._onCheckboxVehiclesChange}
                                />
                            </Col>
                        )}
                    </Row>
                }
                cancelButton={{
                    onClick: this._onCancel,
                    qa: qa.aetr.filter.btnCancel
                }}
                submitButton={{
                    onClick: this._onConfirmClick,
                    qa: qa.aetr.filter.btnSubmit,
                    disabled: this.props.demoMode
                }}
                resetButton={{
                    onClick: this._onResetClick
                }}
            />
        );
    }

    private _onResetClick = (): void => {
        const defaults = confDefault.settings.statistics.aetr.filter;
        this.setState({
            driversChecked: defaults.drivers,
            vehiclesChecked: defaults.vehicles
        });
    };

    private _onConfirmClick = (): void => {
        this.props.onConfirm?.(this.state.driversChecked, this.state.vehiclesChecked);
    };

    private _onCancel = (): void => {
        this.props.onCancel?.();
    };

    private _onCheckboxDriversChange = (driversChecked: string[]) => {
        this.setState({
            driversChecked
        });
    };

    private _onCheckboxVehiclesChange = (vehiclesChecked: string[]) => {
        this.setState({
            vehiclesChecked
        });
    };
}

export default withTranslation()(AetrFilter);
