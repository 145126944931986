import { WithTranslation, withTranslation } from 'react-i18next';

import Confirm, { MessageType } from 'common/components/Confirm';

import { Pairing, PairingData, UserLinks, Unpairing, UserFilter } from '../UsersModule';
import UsersCreateEdit from './users-create-edit';
import UsersPairing from './UsersPairing';
import UsersTable from './user-table';
import {
    ReadOnlyMonitoredObjectGroupNested,
    ReadOnlyUser,
    ReadOnlyUserNested,
    ReadOnlyUserRight,
    ReadOnlyUserRole,
    UserTokenTokenTypeEnum
} from 'generated/new-main/models';
import { UserProfileFormModel } from 'common/forms/UserProfileForm';
import { LayoutContent } from 'common/components/Layout/Content';
import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import UsersLinkedItems from './UsersLinkedItems';
import { Role } from 'logic/auth';
import { AutoCompleteAntd, Button, Modal } from 'common/components';
import { Col, Input, Row } from 'antd';
import { CustomPlace } from 'generated/backend-api';
import { PlaceSuggestion } from 'generated/graphql';
import HelperModal from 'common/components/HelperModal';
import TableBar from 'common/components/TableBar';
import Collapse from 'common/components/Collapse';
import { LayoutSidePanel } from 'common/components/Layout/SidePanel';
import qa from 'qa-selectors';
import UsersActions from './UsersActions';
import UsersDetailCard from './UsersDetailCard';
import { users } from 'resources/images/sidebar';
import * as CommonIcons from 'resources/images/common';
import GroupsAssignmentForm, {
    GroupsAssignmentFormModel
} from 'common/forms/GroupsAssignmentForm/GroupsAssignmentForm';
import FilterButton from 'common/components/FilterButton';

interface Props extends WithTranslation {
    loading?: boolean;
    lang: string;
    data?: PaginatedResponse<ReadOnlyUserNested[]>;
    pairing?: Pairing;
    remove?: ReadOnlyUserNested;
    userPasswordReset?: ReadOnlyUser;
    search?: { text: string };
    selected?: ReadOnlyUserNested;
    userCustomPlaces?: CustomPlace[];
    unpairing?: Unpairing;
    invitedToEWFleetDrivers?: string[];
    invite: boolean;
    userProfileForm: {
        loading?: boolean;
        isOpen: boolean;
        mode: 'EDIT' | 'CREATE';
    };
    userRights?: ReadOnlyUserRight[];
    userRoles?: ReadOnlyUserRole[];
    demoMode?: boolean;
    roles: Role[];
    placeOfWorkDelete?: {
        placeOfWork?: CustomPlace;
        loading?: boolean;
    };
    placesOfWorkAdd?: {
        visible?: boolean;
        placesForAdd?: PlaceSuggestion[];
        placesForAddSelected?: PlaceSuggestion[];
        loading?: boolean;
    };
    lastCreatedUser?: ReadOnlyUser;
    helper?: {
        content: string;
    };
    userRemoveLoading: boolean;
    userPasswordLoading: boolean;
    pairLoading: boolean;
    unpairLoading: boolean;
    assignToGroup: {
        open: boolean;
        loading: boolean;
        monitoredObjectGroups: ReadOnlyMonitoredObjectGroupNested[];
    };
    usersFilter: UserFilter;
    onPaginationChange: ((pagination: PaginationParams) => void) | undefined;
    onBackButtonClick: () => void;
    onCancelNewUserButtonClick: () => void;
    onBarCreateNewUserButtonClick?: () => void;
    onDetailCloseClick?: () => void;
    onPairingCancelButtonClick?: () => void;
    onPairingConfirmButtonClick?: () => void;
    onPairingItemSelect?: (selected: PairingData) => void;
    onPairingSearchInputChange?: (text: string) => void;
    onPairingSubmitButtonClick?: () => void;
    onPairingTypeChange?: (linkType: keyof UserLinks) => void;
    onPairNewAssetButtonClick?: () => void;
    onSearchButtonClick?: () => void;
    onSearchInputChange?: (text: string) => void;
    onTableRowSelect?: (id?: number) => void;
    onUnpairAssetButtonCancelClick?: () => void;
    onUnpairAssetButtonClick?: (model: ReadOnlyUser, type: keyof UserLinks, unpairId?: string) => void;
    onUnpairAssetButtonConfirmClick?: () => void;
    onUserFormSubmit: (model: UserProfileFormModel) => Promise<boolean>;
    onInviteToEWFleetDriver?: (model: ReadOnlyUser) => void;
    onUserRemoveButtonClick?: (model: ReadOnlyUser) => void;
    onUserRemoveButtonCancel?: () => void;
    onUserRemoveButtonConfirm?: () => void;
    onUserPlaceOfWorkRemoveButtonClick?: (placeOfWorkId: string) => void;
    onUserPlaceOfWorkRemoveButtonCancel?: () => void;
    onUserPlaceOfWorkRemoveButtonConfirm?: () => void;
    onUserPlacesOfWorkAddButtonClick?: () => void;
    onUserPlacesOfWorkAddButtonCancel?: () => void;
    onUserPlacesOfWorkAddButtonConfirm?: () => void;
    onUserPlacesOfWorkAddSearch?: (search: string) => void;
    onUserPlacesOfWorkAddSelect?: (placeOfWorkId: string) => void;
    onUserPlacesOfWorkAddDeleteButtonClick?: (placeOfWorkId: string) => void;
    onUserRolesChange?: (roles: string[]) => void;
    onUserPasswordResetClick?: (model: ReadOnlyUser) => void;
    onUserPasswordResetConfirm?: () => void;
    onUserPasswordResetCancel?: () => void;
    onHelperClick?: () => void;
    onHelperClose?: () => void;
    onActionsEditNewUserButtonClick?: () => void;
    onActionsInviteToEWFleetDriver?: () => void;
    onActionsLinkNewAssetButtonClick?: () => void;
    onActionsPasswordResetClick?: () => void;
    onActionsPlaceOfWorkUsersAddButtonClick?: () => void;
    onActionsUserRemoveButtonClick?: () => void;
    onGroupAssignmentSubmit: (values: GroupsAssignmentFormModel) => Promise<boolean>;
    onGroupAssignmentCloseClick: () => void;
    onActionsAssignToVehicleGroupClick: () => void;
    onUsersFilterClick: (filter: UserFilter) => void;
}

function Users(props: Props) {
    const { t } = props;

    const _onUserPlacesOfWorkAddPlaceDeleteButtonClick = (placeOfWorkId: string) => {
        props.onUserPlacesOfWorkAddDeleteButtonClick?.(placeOfWorkId);
    };

    const _onUserPlaceOfWorkAddPlaceSelect = (placeName: string, place: { key: string; value: string }) => {
        props.onUserPlacesOfWorkAddSelect?.(place.key);
    };

    const _onUserPlaceOfWorkAddPlaceSearch = (search: string) => {
        props.onUserPlacesOfWorkAddSearch?.(search);
    };

    const _onFilterButton = (selected: boolean, filter: UserFilter) => {
        if (props.usersFilter === UserFilter.All) {
            if (selected) {
                props.onUsersFilterClick(filter);
            } else {
                props.onUsersFilterClick(filter === UserFilter.Drivers ? UserFilter.Users : UserFilter.Drivers);
            }
        } else {
            props.onUsersFilterClick(UserFilter.All);
        }
    };

    return (
        <>
            {props.pairing && (
                <UsersPairing
                    data={props.pairing.data}
                    model={props.selected}
                    search={props.pairing.search}
                    selected={props.pairing.selected}
                    type={props.pairing.type}
                    onCancelButtonClick={props.onPairingCancelButtonClick}
                    onConfirmButtonClick={props.onPairingConfirmButtonClick}
                    onPairingItemSelect={props.onPairingItemSelect}
                    onPairingTypeChange={props.onPairingTypeChange}
                    onSearchInputChange={props.onPairingSearchInputChange}
                />
            )}
            {/*
                TODO:
                - in furure for multiple type of pairing
                - switch messages based on model type
            */}
            {props.pairing?.confirm && (
                <Confirm
                    // TODO: another model
                    message={
                        props.pairing.type === 'monitoredObject'
                            ? props.pairing.foundPair
                                ? t('ManagementPairing.monitoredObjectPaired')
                                : t('ManagementPairing.monitoredObject')
                            : props.selected?.userTokens?.some(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                            ? t('ManagementPairing.unpairFirstTachograph')
                            : t('ManagementPairing.tachograph')
                    }
                    type={MessageType.INFO}
                    loading={props.pairLoading}
                    confirmDisabled={
                        props.pairing?.type === 'tachoCard' &&
                        props.selected?.userTokens?.some(t => t.tokenType === UserTokenTokenTypeEnum.DriverCard)
                    }
                    onCancel={props.onPairingCancelButtonClick}
                    onConfirm={props.onPairingSubmitButtonClick}
                />
            )}
            {props.unpairing && (
                <Confirm
                    message={
                        props.unpairing.type === 'tachoCard'
                            ? t('ManagementUnpairing.tachograph')
                            : props.unpairing.type === 'monitoredObject'
                            ? t('ManagementUnpairing.vehicle')
                            : t('ManagementUnpairing.app')
                    }
                    type={MessageType.INFO}
                    loading={props.unpairLoading}
                    onCancel={props.onUnpairAssetButtonCancelClick}
                    onConfirm={props.onUnpairAssetButtonConfirmClick}
                />
            )}
            {props.remove && (
                <Confirm
                    danger
                    header={t('ManagementUsers.deleteHeader')}
                    message={`${t('ManagementUsers.deleteConfirm')} ${props.selected?.name} ${props.selected?.surname}`}
                    type={MessageType.WARNING}
                    loading={props.userRemoveLoading}
                    confirmLabel={props.t('common.delete')}
                    onCancel={props.onUserRemoveButtonCancel}
                    onConfirm={props.onUserRemoveButtonConfirm}
                />
            )}
            {props.userPasswordReset && (
                <Confirm
                    header={t('ManagementUsers.passwordReset')}
                    message={`${t('ManagementUsers.passwordResetConfirm')} ${props.userPasswordReset.name} ${
                        props.userPasswordReset.surname
                    }`}
                    type={MessageType.WARNING}
                    loading={props.userPasswordLoading}
                    onCancel={props.onUserPasswordResetCancel}
                    onConfirm={props.onUserPasswordResetConfirm}
                />
            )}

            {props.placesOfWorkAdd?.visible && (
                <Modal
                    className="users-place-of-works-add"
                    width={350}
                    visible={true}
                    centered={true}
                    closable={false}
                    okText={t('common.confirm')}
                    title={t('ManagementUsers.addPlacesOfWorkHeader')}
                    okButtonProps={{ loading: props.placesOfWorkAdd.loading }}
                    onOk={props.onUserPlacesOfWorkAddButtonConfirm}
                    onCancel={props.onUserPlacesOfWorkAddButtonCancel}
                >
                    <Row>
                        {`${t('ManagementUsers.addPlacesOfWorkConfirm')} ${props.selected?.name} ${
                            props.selected?.surname
                        }`}
                    </Row>
                    <Row>
                        <AutoCompleteAntd
                            autoFocus
                            options={props.placesOfWorkAdd?.placesForAdd?.map((place, index) => ({
                                value: place.label ?? index,
                                key: place.id ?? index
                            }))}
                            style={{ width: '100%' }}
                            onSearch={_onUserPlaceOfWorkAddPlaceSearch}
                            onSelect={(val, option) =>
                                _onUserPlaceOfWorkAddPlaceSelect(val, option as { key: string; value: string })
                            }
                        >
                            <Input.Search size="large" placeholder={t('ManagementUsers.placeOfWorkSearch')} />
                        </AutoCompleteAntd>
                    </Row>
                    <div className="place-of-work-users">
                        {props.placesOfWorkAdd?.placesForAddSelected &&
                            props.placesOfWorkAdd.placesForAddSelected?.length > 0 &&
                            props.placesOfWorkAdd.placesForAddSelected?.map((placeOfWork, index) => (
                                <Row key={placeOfWork.id ?? index} data-qa={qa.users.addPlaceOfWork.sectionPlace}>
                                    <Col data-qa={qa.users.addPlaceOfWork.place.fieldName}>{placeOfWork.label}</Col>
                                    <Col className="place-of-work-users-delete">
                                        <Button
                                            type="link"
                                            size="middle"
                                            qa={qa.users.addPlaceOfWork.place.btnDelete}
                                            icon={<img src={CommonIcons.iconClose} alt="remove" />}
                                            onClick={() =>
                                                _onUserPlacesOfWorkAddPlaceDeleteButtonClick(
                                                    placeOfWork.id ?? String(index)
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            ))}
                    </div>
                </Modal>
            )}

            {props.placeOfWorkDelete && (
                <Confirm
                    danger
                    header={t('ManagementUsers.deletePlaceOfWorkHeader')}
                    message={`${t('ManagementUsers.deletePlaceOfWorkConfirm')} ${props.selected?.name} ${
                        props.selected?.surname
                    } : ${props.placeOfWorkDelete?.placeOfWork?.address}`}
                    confirmDisabled={props.placeOfWorkDelete.loading}
                    type={MessageType.WARNING}
                    confirmLabel={props.t('common.delete')}
                    onCancel={props.onUserPlaceOfWorkRemoveButtonCancel}
                    onConfirm={props.onUserPlaceOfWorkRemoveButtonConfirm}
                />
            )}
            <LayoutContent
                className="management-users no-padding"
                mainSizes={{ xs: 24, sm: 24, md: 18 }}
                extraSizes={[{ xs: 24, sm: 24, md: 6 }]}
                main={
                    <>
                        <TableBar
                            search={props.search}
                            filters={[
                                <Col>{t('common.filter')}</Col>,
                                <FilterButton
                                    label={t('common.users')}
                                    defaultSelected={props.usersFilter === UserFilter.Users}
                                    disabled={props.demoMode}
                                    onButtonClick={selected => _onFilterButton(selected, UserFilter.Users)}
                                />,
                                <FilterButton
                                    label={t('common.drivers')}
                                    defaultSelected={props.usersFilter === UserFilter.Drivers}
                                    disabled={props.demoMode}
                                    onButtonClick={selected => _onFilterButton(selected, UserFilter.Drivers)}
                                />
                            ]}
                            heading={t('NavBar.users')}
                            onSearchChange={props.onSearchInputChange}
                            onHelperClick={props.onHelperClick}
                            actions={[
                                <Button
                                    type="dashed"
                                    size="large"
                                    onClick={props.onBarCreateNewUserButtonClick}
                                    disabled={props.demoMode || !props.roles.includes(Role.USR_W)}
                                    qa={qa.users.btnNew}
                                >
                                    {t('ManagementUsers.createUser')}
                                </Button>
                            ]}
                        />

                        <UsersTable
                            lastCreatedUser={props.lastCreatedUser}
                            loading={props.loading}
                            onPaginationChange={props.onPaginationChange}
                            data={props.data}
                            selected={props.selected}
                            groups={props.assignToGroup.monitoredObjectGroups}
                            onTableRowSelect={props.onTableRowSelect}
                        />
                    </>
                }
                extra={[
                    props.userProfileForm.isOpen && props.userProfileForm.mode === 'CREATE' ? (
                        <LayoutSidePanel
                            header={t('ManagementUsers.createUser')}
                            body={
                                <div className="create-new-user">
                                    <UsersCreateEdit
                                        lang={props.lang}
                                        userRights={props.userRights ?? []}
                                        userRoles={props.userRoles ?? []}
                                        mode={props.userProfileForm.mode}
                                        loading={props.userProfileForm.loading}
                                        onBackButtonClick={props.onBackButtonClick}
                                        onCancelButtonClick={props.onCancelNewUserButtonClick}
                                        onSubmitButtonClick={props.onUserFormSubmit}
                                        onUserRolesChange={props.onUserRolesChange}
                                    />
                                </div>
                            }
                        />
                    ) : props.selected && props.assignToGroup.open ? (
                        <>
                            <LayoutSidePanel
                                headerIcon={users}
                                header={`${props.selected.name} ${props.selected.surname}`}
                                body={
                                    <>
                                        <GroupsAssignmentForm
                                            header={props.t('ManagementUsers.assignVehicleTitle')}
                                            loading={props.assignToGroup.loading}
                                            initialValues={{
                                                monitoredObjectGroupIds: props.selected?.monitoredObjectGroups
                                            }}
                                            monitoredObjectGroupsOptions={props.assignToGroup.monitoredObjectGroups.map(
                                                group => {
                                                    return {
                                                        value: group.id ?? 0,
                                                        label: group.name
                                                    };
                                                }
                                            )}
                                            onSubmit={props.onGroupAssignmentSubmit}
                                            onCancel={props.onGroupAssignmentCloseClick}
                                        />
                                    </>
                                }
                            />
                        </>
                    ) : props.selected ? (
                        <LayoutSidePanel
                            header={`${props.selected.name} ${props.selected.surname}`}
                            headerIcon={users}
                            body={
                                <>
                                    <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={['1', '2']}>
                                        <Collapse.Panel header={props.t('common.detail')} key="1">
                                            {props.userProfileForm.isOpen ? (
                                                <UsersCreateEdit
                                                    lang={props.lang}
                                                    data={props.selected}
                                                    userRights={props.userRights ?? []}
                                                    userRoles={props.userRoles ?? []}
                                                    mode={props.userProfileForm.mode}
                                                    loading={props.userProfileForm.loading}
                                                    onBackButtonClick={props.onBackButtonClick}
                                                    onCancelButtonClick={props.onCancelNewUserButtonClick}
                                                    onSubmitButtonClick={props.onUserFormSubmit}
                                                    onUserRolesChange={props.onUserRolesChange}
                                                />
                                            ) : (
                                                <UsersDetailCard
                                                    data={props.selected}
                                                    groups={props.assignToGroup.monitoredObjectGroups}
                                                    demoMode={props.demoMode}
                                                    userCustomPlaces={props.userCustomPlaces}
                                                    onPlaceOfWorkUsersDeleteButtonClick={
                                                        props.onUserPlaceOfWorkRemoveButtonClick
                                                    }
                                                />
                                            )}
                                        </Collapse.Panel>
                                        {!props.userProfileForm.isOpen ? (
                                            <Collapse.Panel header={props.t('ManagementFleet.linkedItems')} key="2">
                                                <UsersLinkedItems
                                                    model={props.selected}
                                                    userTokens={props.selected?.userTokens}
                                                    demoMode={props.demoMode}
                                                    onUnlinkAssetButtonClick={props.onUnpairAssetButtonClick}
                                                />
                                            </Collapse.Panel>
                                        ) : (
                                            <></>
                                        )}
                                    </Collapse>
                                </>
                            }
                            footer={
                                !props.userProfileForm.isOpen && (
                                    <UsersActions
                                        demoMode={props.demoMode}
                                        roles={props.roles}
                                        invite={props.invite}
                                        invitedToEWFleetDrivers={props.invitedToEWFleetDrivers}
                                        model={props.selected}
                                        onEditNewUserButtonClick={props.onActionsEditNewUserButtonClick}
                                        onInviteToEWFleetDriver={props.onActionsInviteToEWFleetDriver}
                                        onLinkNewAssetButtonClick={props.onActionsLinkNewAssetButtonClick}
                                        onPasswordResetClick={props.onActionsPasswordResetClick}
                                        onPlaceOfWorkUsersAddButtonClick={props.onActionsPlaceOfWorkUsersAddButtonClick}
                                        onUserRemoveButtonClick={props.onActionsUserRemoveButtonClick}
                                        onAssignToVehicleGroupClick={props.onActionsAssignToVehicleGroupClick}
                                    />
                                )
                            }
                        />
                    ) : null
                ]}
            />

            <HelperModal
                name="users"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </>
    );
}

export default withTranslation()(Users);
