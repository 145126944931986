import { Subject } from 'rxjs';
import i18n from 'i18next';
import {
    DriverBehaviorModel,
    DriverBehaviorModelDetail,
    DriverBehaviorTrendsModel,
    DriverBehaviourTrendsScoreModel,
    DriverLeaderboardTrendsTableModel
} from 'common/model/statistics';
import { Logic } from '../logic';
import { DriverBehaviourContextualPeriod, DriverBehaviourScore } from 'generated/backend-api';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import moment from 'moment';
import { MONTH_YEAR_FORMAT } from 'domain-constants';
import { roundToStep } from 'common/utils/averages';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';
import numeral from 'numeral';

export class DriverBehaviorTrucks {
    private _data?: DriverBehaviorModel[];
    private _dataTrends?: DriverBehaviorTrendsModel[];
    private _dataContextual?: DriverBehaviourContextualPeriod[];
    private _logic: Logic;

    data = new Subject<DriverBehaviorModel[]>();
    dataTrends = new Subject<DriverBehaviorTrendsModel[]>();
    dataContextual = new Subject<DriverBehaviourContextualPeriod[]>();

    loading = new Subject<boolean>();
    error = new Subject<any>();
    exportListProcessing = new Subject<boolean>();
    exportDetailProcessing = new Subject<boolean>();
    date?: string;
    selectedDriverId?: string;
    comparedDriverId?: string;

    constructor(logic: Logic) {
        this._logic = logic;
    }

    selectWorstDrivers = () => {
        if (!this._data) return [];
        return [...this._data]
            .sort((a, b) => a.score - b.score)
            .map((d, index) => ({ ...d, rank: index + 1 }))
            .slice(0, 5);
    };

    selectWorstDriversTrends = () => {
        if (!this._dataTrends) return [];
        return this._dataTrends
            .map(
                d =>
                    ({
                        id: d.driverId ?? '',
                        name: d.name ?? '',
                        score: d.scores[0].score ?? 0,
                        value: d.scores[0].score ?? 0
                    } as DriverLeaderboardTrendsTableModel)
            )
            .sort((a, b) => (a.value ?? 0) - (b.value ?? 0))
            .map((d, i) => ({ ...d, rank: i + 1 }))
            .slice(0, 5);
    };

    selectTopDrivers = () => {
        if (!this._data) return [];
        return [...this._data].sort((a, b) => b.score - a.score).slice(0, 5);
    };

    selectTopDriversTrends = () => {
        if (!this._dataTrends) return [];
        return this._dataTrends
            .map(
                d =>
                    ({
                        id: d.driverId ?? '',
                        name: d.name ?? '',
                        score: d.scores[0].score ?? 0,
                        value: d.scores[0].score ?? 0
                    } as DriverLeaderboardTrendsTableModel)
            )
            .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
            .map((d, i) => ({ ...d, rank: i + 1 }))
            .slice(0, 5);
    };

    selectTopImproversDrivers = () => {
        if (!this._data) return [];
        const improvement = (newValue: number, oldValue: number) =>
            ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100;

        return this._data
            .filter(e => e.oldScore)
            .sort((a, b) => improvement(a.score, a.oldScore!) - improvement(b.score, b.oldScore!))
            .map((d, index) => ({ ...d, rank: index + 1 }))
            .slice(0, 5);
    };

    selectTopImproversDriversTrends = () => {
        if (!this._dataTrends) return [];
        const improvement = (newValue: number, oldValue: number) =>
            ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100;

        return this._dataTrends
            ?.map(
                d =>
                    ({
                        id: d.driverId ?? '',
                        name: d.name ?? '',
                        score: d.scores[0].score ?? 0,
                        value:
                            d.scores[1].score && d.scores[0].score
                                ? improvement(d.scores[0].score, d.scores[1].score)
                                : undefined
                    } as DriverLeaderboardTrendsTableModel)
            )
            .filter(d => !!d.value)
            .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
            .map((d, i) => ({ ...d, rank: i + 1 }))
            .slice(0, 5);
    };

    getByDriverId(id: string) {
        return (this._data ?? []).find(e => e.driverId === Number(id));
    }

    getDataTrendsByDriverId(id: string) {
        return (this._dataTrends ?? []).find(e => e.driverId === id);
    }

    getDataTrendsDrivers() {
        return (this._dataTrends ?? []).filter(e => e.driverId).map(e => ({ value: e.driverId ?? '', label: e.name }));
    }

    getContextualData() {
        return this._dataContextual ?? [];
    }

    getDate() {
        return this.date;
    }

    setSelectedDriverId(id?: string) {
        this.selectedDriverId = id;
    }

    setComparedDriverId(id?: string) {
        this.comparedDriverId = id;
    }

    async loadData(date: string) {
        this.date = date;
        this.loading.next(true);

        if (this._logic.demo().isActive) {
            this._data = this._logic.demo().data.driverBehaviorTrucks;
        } else {
            try {
                const resp = await this._logic.api().driverBehaviourApi.getDriverBehaviourV1DriverbehaviourMyGet({
                    fromT: new Date(date)
                });
                this._data = resp.act.map(score => {
                    const prevScore = resp.prev.find(ps => ps.generalData.driver1.id === score.generalData.driver1.id);
                    return this._toTruckModel(score, resp.numberOfDrivers, prevScore);
                });
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this.data.next(this._data ?? []);
        this.loading.next(false);
    }

    async loadTrendsData(date: string) {
        this.date = date;
        this.loading.next(true);

        const months = new Array(13).fill(null).map((_, i) => moment(date).subtract(i, 'months').format('YYYY-MM-DD'));

        if (this._logic.demo().isActive) {
            const resp = this._logic.demo().data.driverBehaviorTrendsTrucks;
            this._dataTrends = resp.data
                ?.find(month => month.start === months[0])
                ?.score.map((score, i) => ({
                    id: i.toString(),
                    name: `${score.generalData.driver1.name} ${score.generalData.driver1.surname}`,
                    scores: months.map(month => {
                        const driverMonthScore = resp.data
                            ?.find(d => d.start === month)
                            ?.score.find(s => s.generalData.driver1.id === score.generalData.driver1.id);
                        return this._toTruckTrendModel(month, driverMonthScore);
                    }),
                    driverId: score.generalData.driver1.id?.toString(),
                    rank: score.rank,
                    totalDrivers: resp.numberOfDrivers ?? 0,
                    tachocard: score.generalData.driver1.tachoCard
                }))
                .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));
            this._dataContextual = resp.contextualData;
        } else {
            try {
                const resp = await this._logic
                    .api()
                    .driverBehaviourApi.getDriverBehaviourTrendsV1DriverbehaviourMyTrendsGet({
                        fromT: moment(date).subtract(13, 'months').startOf('month').format('YYYY-MM-DD'),
                        toT: moment(date).startOf('month').add(1, 'month').format('YYYY-MM-DD')
                    });

                const responseData = resp.data;
                responseData?.sort((a, b) => (moment(b.start).isBefore(moment(a.start)) ? 0 : 1));

                const responseContextualData = resp.contextualData?.filter(d => months.slice(0, 2).includes(d.start));
                responseContextualData?.sort((a, b) => (b.start.localeCompare(a.start) ? 1 : 0));

                this._dataTrends = resp.data
                    ?.find(month => month.start === months[0])
                    ?.score.map((score, i) => ({
                        id: i.toString(),
                        name: `${score.generalData.driver1.name} ${score.generalData.driver1.surname}`,
                        scores: months.map(month => {
                            const driverMonthScore = resp.data
                                ?.find(d => d.start === month)
                                ?.score.find(s => s.generalData.driver1.id === score.generalData.driver1.id);
                            return this._toTruckTrendModel(month, driverMonthScore);
                        }),
                        driverId: score.generalData.driver1.id?.toString(),
                        rank: score.rank,
                        totalDrivers: resp.numberOfDrivers ?? 0,
                        tachocard: score.generalData.driver1.tachoCard
                    }));
                this._dataContextual = responseContextualData;
            } catch (err) {
                this.error.next(err);
                this.loading.next(false);
            }
        }

        this.dataTrends.next(this._dataTrends ?? []);
        this.loading.next(false);
    }

    async downloadDriverBehaviorDetailExport() {
        if (!this.selectedDriverId) {
            throw new Error('No id provided');
        }

        this.exportDetailProcessing.next(true);
        const data = this.getDataTrendsByDriverId(this.selectedDriverId);
        const dataCurrent = data?.scores[0];
        const dataPrevious = data?.scores[1];

        if (!dataCurrent) {
            throw new Error(`No driver with id: ${this.selectedDriverId}`);
        }
        const workbook: Workbook = await fromBlankAsync();
        const sheet = workbook
            .sheet(0)
            .name(normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${data.name}`));
        const dateStr = moment(this.date).format(MONTH_YEAR_FORMAT);

        const headDescription = [[i18n.t('DriverDetailModule.title'), '', data.name, '', dateStr], []];
        const statsGroupTitle = [[i18n.t('DriverScoreStats.title')]];
        const statsTitle = [
            [
                '',
                i18n.t('DriverScoreStats.driveScore'),
                i18n.t('DriverScoreStats.ecoScore'),
                i18n.t('DriverScoreStats.wearTearScore'),
                i18n.t('DriverBehaviorDistanceChart.title'),
                i18n.t('DriveRank.title')
            ]
        ];
        const driverDataGroupTitle = [[i18n.t('DriverBehaviorDetail.driveData.title')]];
        const driverDataTitle = [
            [
                '',
                i18n.t('DriverBehaviorDetail.options.general.fullDistance'),
                i18n.t('DriverBehaviorDetail.options.general.driverTime'),
                i18n.t('DriverBehaviorDetail.options.general.consumption'),
                i18n.t('DriverBehaviorDetail.options.general.avgSpeed'),
                i18n.t('DriverBehaviorDetail.options.general.engineOnTime'),
                i18n.t('DriverBehaviorDetail.options.general.idlingTime'),
                i18n.t('DriverBehaviorDetail.options.general.cruiseControlTime'),
                i18n.t('DriverBehaviorDetail.options.general.cntTakeoff')
            ]
        ];

        const ecoGroupTitle = [[i18n.t('DriverBehaviorDetail.ecoData.title')]];
        const ecoTitle = [
            [
                '',
                i18n.t('DriverBehaviorDetail.options.general.idleConsumption'),
                i18n.t('DriverBehaviorDetail.options.general.rpmOverTime'),
                i18n.t('DriverBehaviorDetail.options.general.constantAccelerationTime'),
                i18n.t('DriverBehaviorDetail.options.general.driveTime85'),
                i18n.t('DriverBehaviorDetail.options.general.driveTimeWithoutConsumptionWithEcoroll'),
                i18n.t('DriverBehaviorDetail.options.general.accelerationTimeWithCruiseControl'),
                i18n.t('DriverBehaviorDetail.options.general.kickdownTime'),
                i18n.t('DriverBehaviorDetail.options.general.maxRPN')
            ]
        ];

        const wearTearGroupTitle = [[i18n.t('DriverBehaviorDetail.wearTearData.title')]];
        const wearTearTitle = [
            [
                '',
                i18n.t('DriverBehaviorDetail.options.general.avgParkingBreakCount'),
                i18n.t('DriverBehaviorDetail.options.general.avgRetarderCount'),
                i18n.t('DriverBehaviorDetail.options.general.avgServiceBrakeCount'),
                i18n.t('DriverBehaviorDetail.options.general.serviceBrakeVsRetarderPercentage'),
                i18n.t('DriverBehaviorDetail.options.general.retarderTime'),
                i18n.t('DriverBehaviorDetail.options.general.distanceWithRetarder'),
                i18n.t('DriverBehaviorDetail.options.general.distanceWithServiceBrake'),
                i18n.t('DriverBehaviorDetail.options.general.maxSpeedWithParkingBrake')
            ]
        ];

        if (!dataCurrent.distance) {
            return;
        }

        const max =
            dataCurrent &&
            Object.keys(dataCurrent.distance)
                .map(key => ({
                    label: key,
                    value: dataCurrent.distance?.[key]
                }))
                .find(
                    d =>
                        d.value ===
                        Math.max(
                            dataCurrent.distance?.city ?? 0,
                            dataCurrent.distance?.highway ?? 0,
                            dataCurrent.distance?.noCity ?? 0
                        )
                );

        const statsGroupBody = [[roundToStep(dataCurrent.score ?? 0, 0.1)]];
        const statsBody = [
            [
                roundToStep(dataCurrent.driveScore ?? 0, 0.1),
                roundToStep(dataCurrent.ecoScore ?? 0, 0.1),
                roundToStep(dataCurrent.wearTearScore ?? 0, 0.1),
                max ? `${Math.round((max.value / dataCurrent.distance.summary) * 100)}%` : '',
                data.rank
            ]
        ];

        const rounded = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;
        const duration = (value: number) => {
            const formattedValue = moment.duration(value, 's').format('HH:mm:ss', { trim: false });
            return `${formattedValue.split(':')[0]}h${formattedValue.split(':')[1]}m${formattedValue.split(':')[2]}s`;
        };
        const getIncreaseInPercent = (newValue?: number, oldValue?: number) => {
            const value = newValue && oldValue ? ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100 : 0;
            return value === 0 ? '-' : `${Math.round((value + Number.EPSILON) * 10) / 10}`;
        };

        const driverDataGroupBody = [[]];
        const driverDataBody = dataCurrent.detail
            ? [
                  [
                      `${numeral(rounded(dataCurrent.detail.fullDistance)).format('0,0.00')}km`,
                      duration(dataCurrent.detail.driverTime),
                      `${rounded(dataCurrent.detail.consumption)}l`,
                      `${numeral(rounded(dataCurrent.detail.avgSpeed)).format('0,0.00')}km/h`,
                      duration(dataCurrent.detail.engineOnTime),
                      duration(dataCurrent.detail.idlingTime),
                      duration(dataCurrent.detail.cruiseControlTime),
                      rounded(dataCurrent.detail.cntTakeoff)
                  ]
              ]
            : [];
        const driverDataIncreaseBody = [
            [
                getIncreaseInPercent(dataCurrent.detail?.fullDistance, dataPrevious?.detail?.fullDistance),
                getIncreaseInPercent(dataCurrent.detail?.driverTime, dataPrevious?.detail?.driverTime),
                getIncreaseInPercent(dataCurrent.detail?.consumption, dataPrevious?.detail?.consumption),
                getIncreaseInPercent(dataCurrent.detail?.avgSpeed, dataPrevious?.detail?.avgSpeed),
                getIncreaseInPercent(dataCurrent.detail?.engineOnTime, dataPrevious?.detail?.engineOnTime),
                getIncreaseInPercent(dataCurrent.detail?.idlingTime, dataPrevious?.detail?.idlingTime),
                getIncreaseInPercent(dataCurrent.detail?.cruiseControlTime, dataPrevious?.detail?.cruiseControlTime),
                getIncreaseInPercent(dataCurrent.detail?.cntTakeoff, dataPrevious?.detail?.cntTakeoff)
            ]
        ];

        const ecoGroupBody = [[]];
        const ecoBody = dataCurrent.detail
            ? [
                  [
                      `${rounded(dataCurrent.detail.idleConsumption)}l`,
                      duration(dataCurrent.detail.rpmOverTime),
                      duration(dataCurrent.detail.constantAccelerationTime),
                      duration(dataCurrent.detail.driveTime85),
                      duration(dataCurrent.detail.driveTimeWithoutConsumptionWithEcoroll),
                      duration(dataCurrent.detail.accelerationTimeWithCruiseControl),
                      duration(dataCurrent.detail.kickdownTime),
                      rounded(dataCurrent.detail.maxRPN)
                  ]
              ]
            : [];
        const ecoIncreaseBody = [
            [
                getIncreaseInPercent(dataCurrent.detail?.idleConsumption, dataPrevious?.detail?.idleConsumption),
                getIncreaseInPercent(dataCurrent.detail?.rpmOverTime, dataPrevious?.detail?.rpmOverTime),
                getIncreaseInPercent(
                    dataCurrent.detail?.constantAccelerationTime,
                    dataPrevious?.detail?.constantAccelerationTime
                ),
                getIncreaseInPercent(dataCurrent.detail?.driveTime85, dataPrevious?.detail?.driveTime85),
                getIncreaseInPercent(
                    dataCurrent.detail?.driveTimeWithoutConsumptionWithEcoroll,
                    dataPrevious?.detail?.driveTimeWithoutConsumptionWithEcoroll
                ),
                getIncreaseInPercent(
                    dataCurrent.detail?.accelerationTimeWithCruiseControl,
                    dataPrevious?.detail?.accelerationTimeWithCruiseControl
                ),
                getIncreaseInPercent(dataCurrent.detail?.kickdownTime, dataPrevious?.detail?.kickdownTime),
                getIncreaseInPercent(dataCurrent.detail?.maxRPN, dataPrevious?.detail?.maxRPN)
            ]
        ];

        const wearTearGroupBody = [[]];
        const wearTearBody = dataCurrent.detail
            ? [
                  [
                      rounded(dataCurrent.detail.avgParkingBreakCount),
                      rounded(dataCurrent.detail.avgRetarderCount),
                      rounded(dataCurrent.detail.avgServiceBrakeCount),
                      `${numeral(rounded(dataCurrent.detail.serviceBrakeVsRetarderPercentage)).format('0,0.00')}%`,
                      duration(dataCurrent.detail.retarderTime),
                      `${numeral(rounded(dataCurrent.detail.distanceWithRetarder)).format('0,0.00')}km`,
                      `${numeral(rounded(dataCurrent.detail.distanceWithServiceBrake)).format('0,0.00')}km`,
                      `${numeral(rounded(dataCurrent.detail.maxSpeedWithParkingBrake)).format('0,0.00')}km/h`
                  ]
              ]
            : [];
        const wearTearIncreaseBody = [
            [
                getIncreaseInPercent(
                    dataCurrent.detail?.avgParkingBreakCount,
                    dataPrevious?.detail?.avgParkingBreakCount
                ),
                getIncreaseInPercent(dataCurrent.detail?.avgRetarderCount, dataPrevious?.detail?.avgRetarderCount),
                getIncreaseInPercent(
                    dataCurrent.detail?.avgServiceBrakeCount,
                    dataPrevious?.detail?.avgServiceBrakeCount
                ),
                getIncreaseInPercent(
                    dataCurrent.detail?.serviceBrakeVsRetarderPercentage,
                    dataPrevious?.detail?.serviceBrakeVsRetarderPercentage
                ),
                getIncreaseInPercent(dataCurrent.detail?.retarderTime, dataPrevious?.detail?.retarderTime),
                getIncreaseInPercent(
                    dataCurrent.detail?.distanceWithRetarder,
                    dataPrevious?.detail?.distanceWithRetarder
                ),
                getIncreaseInPercent(
                    dataCurrent.detail?.distanceWithServiceBrake,
                    dataPrevious?.detail?.distanceWithServiceBrake
                ),
                getIncreaseInPercent(
                    dataCurrent.detail?.maxSpeedWithParkingBrake,
                    dataPrevious?.detail?.maxSpeedWithParkingBrake
                )
            ]
        ];

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true,
            wrapText: true
        };
        const dataStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            },
            horizontalAlignment: 'center'
        };

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;

        const statsGroupTitleStartRow = 3;
        const statsGroupTitleEndRow = 3;
        const statsTitleStartRow = 4;
        const statsTitleEndRow = 4;
        const statsGroupBodyStartRow = 4;
        const statsGroupBodyEndRow = 4;
        const statsBodyStartRow = 5;
        const statsBodyEndRow = 5;

        const driverDataGroupTitleStartRow = 7;
        const driverDataGroupTitleEndRow = 7;
        const driverDataTitleStartRow = 8;
        const driverDataTitleEndRow = 8;
        const driverDataGroupBodyStartRow = 8;
        const driverDataGroupBodyEndRow = 8;
        const driverDataBodyStartRow = 9;
        const driverDataBodyEndRow = 9;
        const driverDataIncreaseBodyStartRow = 10;
        const driverDataIncreaseBodyEndRow = 10;

        const ecoGroupTitleStartRow = 11;
        const ecoGroupTitleEndRow = 11;
        const ecoTitleStartRow = 12;
        const ecoTitleEndRow = 12;
        const ecoGroupBodyStartRow = 12;
        const ecoGroupBodyEndRow = 12;
        const ecoBodyStartRow = 13;
        const ecoBodyEndRow = 13;
        const ecoIncreaseBodyStartRow = 14;
        const ecoIncreaseBodyEndRow = 14;

        const wearTearGroupTitleStartRow = 15;
        const wearTearGroupTitleEndRow = 15;
        const wearTearTitleStartRow = 16;
        const wearTearTitleEndRow = 16;
        const wearTearGroupBodyStartRow = 16;
        const wearTearGroupBodyEndRow = 16;
        const wearTearBodyStartRow = 17;
        const wearTearBodyEndRow = 17;
        const wearTearIncreaseBodyStartRow = 18;
        const wearTearIncreaseBodyEndRow = 18;

        const workbookDescriptionRange = sheet.range(descriptionStartRow, 1, descriptionEndRow, 9);

        const workbookStatsGroupTitleRange = sheet.range(statsGroupTitleStartRow, 1, statsGroupTitleEndRow, 9);
        const workbookStatsTitleRange = sheet.range(statsTitleStartRow, 1, statsTitleEndRow, 9);
        const workbookStatsGroupBodyRange = sheet.range(statsGroupBodyStartRow, 1, statsGroupBodyEndRow, 1);
        const workbookStatsBodyRange = sheet.range(statsBodyStartRow, 2, statsBodyEndRow, 9);

        const workbookDriverDataGroupTitleRange = sheet.range(
            driverDataGroupTitleStartRow,
            1,
            driverDataGroupTitleEndRow,
            9
        );
        const workbookDriverDataTitleRange = sheet.range(driverDataTitleStartRow, 1, driverDataTitleEndRow, 9);
        const workbookDriverDataGroupBodyRange = sheet.range(
            driverDataGroupBodyStartRow,
            1,
            driverDataGroupBodyEndRow,
            1
        );
        const workbookDriverDataBodyRange = sheet.range(driverDataBodyStartRow, 2, driverDataBodyEndRow, 9);
        const workbookDriverDataIncreaseBodyRange = sheet.range(
            driverDataIncreaseBodyStartRow,
            2,
            driverDataIncreaseBodyEndRow,
            9
        );

        const workbookEcoGroupTitleRange = sheet.range(ecoGroupTitleStartRow, 1, ecoGroupTitleEndRow, 9);
        const workbookEcoTitleRange = sheet.range(ecoTitleStartRow, 1, ecoTitleEndRow, 9);
        const workbookEcoGroupBodyRange = sheet.range(ecoGroupBodyStartRow, 1, ecoGroupBodyEndRow, 1);
        const workbookEcoBodyRange = sheet.range(ecoBodyStartRow, 2, ecoBodyEndRow, 9);
        const workbookEcoIncreaseBodyRange = sheet.range(ecoIncreaseBodyStartRow, 2, ecoIncreaseBodyEndRow, 9);

        const workbookWearTearGroupTitleRange = sheet.range(wearTearGroupTitleStartRow, 1, wearTearGroupTitleEndRow, 9);
        const workbookWearTearTitleRange = sheet.range(wearTearTitleStartRow, 1, wearTearTitleEndRow, 9);
        const workbookWearTearGroupBodyRange = sheet.range(wearTearGroupBodyStartRow, 1, wearTearGroupBodyEndRow, 1);
        const workbookWearTearBodyRange = sheet.range(wearTearBodyStartRow, 2, wearTearBodyEndRow, 9);
        const workbookWearTearIncreaseBodyRange = sheet.range(
            wearTearIncreaseBodyStartRow,
            2,
            wearTearIncreaseBodyEndRow,
            9
        );

        workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);

        workbookStatsGroupTitleRange.style(titleDescriptionStyle).value(statsGroupTitle);
        workbookStatsTitleRange.style(titleDescriptionStyle).value(statsTitle);
        workbookStatsGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(statsGroupBody);
        workbookStatsBodyRange.style(dataStyle).value(statsBody);

        workbookDriverDataGroupTitleRange.style(titleDescriptionStyle).value(driverDataGroupTitle);
        workbookDriverDataTitleRange.style(titleDescriptionStyle).value(driverDataTitle);
        workbookDriverDataGroupBodyRange
            .style({ ...dataStyle, verticalAlignment: 'center' })
            .value(driverDataGroupBody);
        workbookDriverDataBodyRange.style(dataStyle).value(driverDataBody);
        workbookDriverDataIncreaseBodyRange.style(dataStyle).value(driverDataIncreaseBody);

        workbookEcoGroupTitleRange.style(titleDescriptionStyle).value(ecoGroupTitle);
        workbookEcoTitleRange.style(titleDescriptionStyle).value(ecoTitle);
        workbookEcoGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(ecoGroupBody);
        workbookEcoBodyRange.style(dataStyle).value(ecoBody);
        workbookEcoIncreaseBodyRange.style(dataStyle).value(ecoIncreaseBody);

        workbookWearTearGroupTitleRange.style(titleDescriptionStyle).value(wearTearGroupTitle);
        workbookWearTearTitleRange.style(titleDescriptionStyle).value(wearTearTitle);
        workbookWearTearGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(wearTearGroupBody);
        workbookWearTearBodyRange.style(dataStyle).value(wearTearBody);
        workbookWearTearIncreaseBodyRange.style(dataStyle).value(wearTearIncreaseBody);

        [
            {
                body: driverDataIncreaseBody[0],
                startRow: driverDataIncreaseBodyStartRow
            },
            {
                body: ecoIncreaseBody[0],
                startRow: ecoIncreaseBodyStartRow
            },
            {
                body: wearTearIncreaseBody[0],
                startRow: wearTearIncreaseBodyStartRow
            }
        ].forEach(increase => {
            increase.body.forEach((val, index) => {
                // +2: data start from col 2
                const cell = sheet.row(increase.startRow).cell(index + 2);
                if (isNaN(parseFloat(val))) return;
                cell.style({ ...dataStyle, fontColor: parseFloat(val) > 0 ? '4caf50' : 'ff3823' });
            });
        });

        // header title
        sheet.range(1, 1, 1, 2).merged(true);
        sheet.range(1, 3, 1, 4).merged(true);

        // stats title
        // sheet.range(3, 1, 3, 8).merged(true);

        // first row group items
        sheet.range(4, 1, 5, 1).merged(true);
        sheet.range(8, 1, 10, 1).merged(true);
        sheet.range(12, 1, 14, 1).merged(true);
        sheet.range(16, 1, 18, 1).merged(true);

        [25, 20, 20, 20, 20, 20, 20, 20, 20].forEach((width, i) => {
            sheet.column(i + 1).width(width);
        });

        sheet.row(8).height(15 * 3);
        sheet.row(12).height(15 * 4);
        sheet.row(16).height(15 * 4);
        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('DriverDetailModule.title')} - ${data?.name} ${dateStr}.xlsx`);
        this.exportListProcessing.next(false);
        this.exportDetailProcessing.next(false);
    }

    async downloadDriverBehaviorListExport() {
        this.exportListProcessing.next(true);
        const workbook: Workbook = await fromBlankAsync();
        const sheetLeadBoard = workbook.sheet(0).name(normalizeExportSheetName(i18n.t('DriverListModule.title')));
        const sheetTopDrivers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.topDrivers')), 1);
        const sheetTopImprovers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.topImprovers')), 2);
        const sheetWorstDrivers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.worstDrivers')), 3);

        const allSheets = [sheetLeadBoard, sheetTopDrivers, sheetTopImprovers, sheetWorstDrivers];

        const dateStr = moment(this.date).format(MONTH_YEAR_FORMAT);
        const headDescription = [[i18n.t('DriverListModule.title'), '', dateStr], []];
        const leaderBoardHead = [
            [
                i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.name').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.score').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.driveScore').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.ecoScore').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.wearTearScore').toUpperCase()
            ]
        ];

        const top5Head = [
            [
                i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.name').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.score').toUpperCase()
            ]
        ];

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true
        };
        const dataStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            },
            horizontalAlignment: 'center'
        };

        const leaderBoardBody: any[][] = [];
        this._dataTrends?.forEach(driverBehavior => {
            leaderBoardBody.push([
                driverBehavior.rank,
                driverBehavior.name,
                roundToStep(driverBehavior.scores[0].score ?? 0, 0.1),
                roundToStep(driverBehavior.scores[0].driveScore ?? 0, 0.1),
                roundToStep(driverBehavior.scores[0].ecoScore ?? 0, 0.1),
                roundToStep(driverBehavior.scores[0].wearTearScore ?? 0, 0.1)
            ]);
        });
        const topDriversBody: any[][] = [];
        this.selectTopDriversTrends().forEach(driverBehavior => {
            topDriversBody.push([driverBehavior.rank, driverBehavior.name, roundToStep(driverBehavior.score, 0.1)]);
        });
        const topImproversDriversBody: any[][] = [];
        this.selectTopImproversDriversTrends().forEach(driverBehavior => {
            topImproversDriversBody.push([
                driverBehavior.rank,
                driverBehavior.name,
                roundToStep(driverBehavior.score, 0.1)
            ]);
        });
        const worstDriversBody: any[][] = [];
        this.selectWorstDriversTrends().forEach(driverBehavior => {
            worstDriversBody.push([driverBehavior.rank, driverBehavior.name, roundToStep(driverBehavior.score, 0.1)]);
        });

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;

        const tableTitleStartRow = descriptionEndRow + 1;
        const tableTitleEndRow = tableTitleStartRow;

        allSheets.forEach((sheet, index) => {
            sheet.range(1, 1, 1, 2).merged(true);
            let workbookDescriptionRange;
            let workbookTableTitleRange;
            if (index === 0) {
                workbookDescriptionRange = sheet.range(descriptionStartRow, 1, tableTitleEndRow, 6);
                workbookTableTitleRange = sheet.range(tableTitleStartRow, 1, tableTitleEndRow, 6);
                [15, 25, 15, 15, 15, 20].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            } else {
                workbookDescriptionRange = sheet.range(descriptionStartRow, 1, tableTitleEndRow, 3);
                workbookTableTitleRange = sheet.range(tableTitleStartRow, 1, tableTitleEndRow, 3);
                [15, 25, 15].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            }

            workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);
            workbookTableTitleRange.style({ ...titleDescriptionStyle, bottomBorder: true });

            if (index === 0) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + leaderBoardBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 6);

                workbookTableTitleRange.value(leaderBoardHead);
                workbookTableBodyRange.style(dataStyle).value(leaderBoardBody);
            } else if (index === 1) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + topDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(topDriversBody);
            } else if (index === 2) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + topImproversDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(topImproversDriversBody);
            } else if (index === 3) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + worstDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(worstDriversBody);
            }
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('DriverListModule.title')} ${dateStr}.xlsx`);
        this.exportListProcessing.next(false);
    }

    private _toTruckTrendModel(month: string, score?: DriverBehaviourScore): DriverBehaviourTrendsScoreModel {
        return score
            ? {
                  start: month,
                  score: score.overalScore,
                  driveScore: score.driveScore,
                  ecoScore: score.ecoScore,
                  wearTearScore: score.wearTearScore,
                  driveKm: score?.generalData.distance,
                  distance: {
                      highway: score.driveData.highway.distance,
                      city: score.driveData.city.distance,
                      noCity: score.driveData.nocity.distance,
                      other: score.driveData.other.distance,
                      summary: score.driveData.summary.distance
                  },
                  detail: this._toTruckDetail(score)
              }
            : {
                  start: month
              };
    }

    private _toTruckModel(
        score: DriverBehaviourScore,
        totalDrivers: number,
        prevScore?: DriverBehaviourScore
    ): DriverBehaviorModel {
        return {
            id: score.id,
            rank: score.rank ?? 0,
            name: `${score.generalData.driver1.name} ${score.generalData.driver1.surname}`,
            driverId: score.generalData.driver1.id,
            score: score.overalScore / 20,
            oldScore: prevScore?.overalScore ? prevScore?.overalScore / 20 : undefined,
            driveScore: score.driveScore / 20,
            ecoScore: score.ecoScore / 20,
            wearTearScore: score.wearTearScore / 20,
            detail: this._toTruckDetail(score),
            prevDetail: prevScore && this._toTruckDetail(prevScore),
            totalDrivers,
            distance: {
                highway: score.driveData.highway.distance,
                city: score.driveData.city.distance,
                noCity: score.driveData.nocity.distance,
                other: score.driveData.other.distance,
                summary: score.driveData.summary.distance
            }
        };
    }

    private _toTruckDetail(score: DriverBehaviourScore): DriverBehaviorModelDetail {
        return {
            fullDistance: score.generalData.distance,
            driverTime: score.generalData.driveTime,
            avgElevation: 13500,
            avgSpeed: score.driveData.summary.avgSpeed,
            avgWeight: 14500,
            brakingDistance: 567,
            driverPredictability: 3600,
            distanceEcoroll: 230,
            consumptionRPM: 34,
            rpmOptimum: 1200,
            consumption: score.generalData.consumption,
            engineOnTime: score.generalData.engineOnTime,
            idlingTime: score.generalData.idlingTime,
            cruiseControlTime: score.driveData.summary.cruiseControlTime,
            cntTakeoff: score.driveData.summary.cntTakeoff,
            idleConsumption: score.savingData.idleConsumption,
            rpmOverTime: score.savingData.rpmOverTime,
            constantAccelerationTime: score.savingData.constantAccelerationTime,
            driveTime85: score.savingData.driveTime85,
            driveTimeWithoutConsumptionWithEcoroll: score.savingData.driveTimeWithoutConsumptionWithEcoroll,
            accelerationTimeWithCruiseControl: score.savingData.accelerationTimeWithCruiseControl,
            kickdownTime: score.savingData.kickdownTime,
            maxRPN: score.savingData.maxRpm,
            avgParkingBreakCount: Math.round(score.wearData.avgParkingBreakCount),
            avgRetarderCount: Math.round(score.wearData.avgRetarderCount),
            avgServiceBrakeCount: Math.round(score.wearData.avgServiceBrakeCount),
            serviceBrakeVsRetarderPercentage: score.wearData.serviceBrakeVsRetarderPercentage,
            retarderTime: score.wearData.retarderTime,
            distanceWithRetarder: score.wearData.distanceWithRetarder,
            distanceWithServiceBrake: score.wearData.distanceWithServiceBrake,
            maxSpeedWithParkingBrake: score.wearData.maxSpeedWithParkingBrake,
            wtdBrakingDistance: 12,
            wtdBrakingService: 87,
            wtdAvgService: 4,
            wtdParkingBreak: 45,
            wtdMaxSpeed: 41,
            wtdEngineBrake: 7,
            wtdDistanceTravelled: 6,
            wtdMaxRPM: 3200
        };
    }

    saveDateToSettings(date?: string) {
        this._logic.settings().setProp('driverBehavior', {
            date: date
        });
    }
}
