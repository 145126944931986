import { GraphInfo } from 'generated/backend-api-live';

export const journeyGraphData: GraphInfo[] = [
    {
        lats: [],
        lons: [],
        timestamps: [1664533638, 1664537358, 1664544858, 1664554578, 1664562198, 1664575158],
        speeds: [107, 132, 11, 45, 67, 110],
        fuelLevels: [80, 76, 54, 40, 31, 17]
    }
];
