import React, { useState } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import OutlineButton from '../OutlineButton';

export interface PlannerContentContainerProps extends WithTranslation {
    title?: React.ReactNode;
    subtitle?: string;
    className?: string;
    expandable?: boolean;
    expanded?: boolean;
    showMore?: boolean;
    showLess?: boolean;
    moreHeight?: number;
    beforeTitle?: React.ReactNode;
    afterTitle?: React.ReactNode;
    headerInfo?: React.ReactNode;
    collapseHeight?: number;
    collapsed?: boolean;
    onExpandedClick?: (state: boolean) => void;
}

const PlannerContentContainer = (props: React.PropsWithChildren<PlannerContentContainerProps>) => {
    const expandable = (props.expandable ?? true) && props.children ? true : false;
    const moreHeight: string = props.moreHeight ? String(props.moreHeight) + 'px' : '100px';
    const collapseHeight = props.collapseHeight ? String(props.collapseHeight) + 'px' : '0px';

    const [expanded, setExpanded] = useState(expandable ? props.expanded ?? true : true);
    const [showMore, setShowMore] = useState(props.showMore && expandable && props.showLess);

    const handleExpandClick = () => {
        if (props.expandable ?? true) {
            setExpanded(!expanded);
            props.onExpandedClick?.(!expanded);
        }
    };

    const handlerHeaderClick = () => {
        handleExpandClick();
    };

    const handleShowMoreClick = () => {
        setShowMore(!showMore);
    };

    const handleShowLessClick = () => {
        setShowMore(!showMore);
    };

    const style = {
        height: expanded ? (showMore ? moreHeight : 'auto') : collapseHeight
    };

    return (
        <Row
            className={cn(`planner-content-container ${props.className}`, {
                'planner-content-container-content-expandable': expandable,
                'planner-content-container-content-show': expanded,
                'planner-content-container-content-more': props.showMore,
                'planner-content-container-content-hide': style.height === collapseHeight,
                'planner-content-container-content-collapse-height': props.collapseHeight
            })}
        >
            {props.showMore && showMore && (
                <Row align="middle" className="show-more">
                    <Col onClick={handleShowMoreClick}>{props.t('DriverBehaviorCollapse.open')}</Col>
                </Row>
            )}
            {(expandable || props.title || props.beforeTitle || props.afterTitle) && (
                <Row align="middle" className="planner-content-container-header" onClick={handlerHeaderClick}>
                    <Row align="middle" className="planner-content-container-header-content">
                        <Row align="middle" className="planner-content-container-title">
                            {props.beforeTitle}
                            <span>{props.title}</span>
                            {props.afterTitle}
                        </Row>
                        {expandable && (
                            <OutlineButton
                                isDown={expanded}
                                size="small"
                                className="open-planner-content-container"
                                onClick={handleExpandClick}
                            />
                        )}
                    </Row>
                </Row>
            )}
            {props.headerInfo && <Row className="planner-content-container-header-info">{props.headerInfo}</Row>}
            <Row className="planner-content-container-content" style={style}>
                {props.children}
                {expandable && props.showMore && !showMore && props.showLess && (
                    <Row align="middle" className="show-less">
                        <Col onClick={handleShowLessClick}>{props.t('DriverBehaviorCollapse.close')}</Col>
                    </Row>
                )}
            </Row>
        </Row>
    );
};

export default withTranslation()(PlannerContentContainer);
