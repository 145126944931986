import React, { FormEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuperAdminUserModel } from '../SuperAdminModule';
import { Loading } from 'common/components/Loading';
import SuperAdminUserSearch from './SuperAdminUserSearch';
import { Checkbox } from 'common/components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cn from 'classnames';
import { NoData } from 'common/components/NoData';

interface Props extends WithTranslation {
    search?: { text: string };
    ssoUsers?: boolean;
    data: SuperAdminUserModel[];
    loading: boolean;
    onCancel?: () => void;
    onSearchChange: (text: string) => void;
    onSsoUserChecboxChange: (value: boolean) => void;
    onUserSubmit?: (user: SuperAdminUserModel) => void;
}

const head = ['user', 'name', 'surname', ''];

function SuperAdminUserWindow(props: Props) {
    function onCancel(event: FormEvent) {
        event.preventDefault();
        props.onCancel?.();
    }

    function onUserSubmit(user: SuperAdminUserModel) {
        props.onUserSubmit?.(user);
    }

    function onSsoUserChecboxChange(e: CheckboxChangeEvent) {
        props.onSsoUserChecboxChange?.(e.target.checked);
    }

    const t = (key: string) => (key ? props.t(`UsersTable.${key}`) : '');
    const minId = Math.min(...props.data.map(row => Number(row.id)));
    return (
        <div className="superadmin-main-form">
            <div className="users-bar t-bar">
                <h1>
                    {props.t('NavBar.users')}
                    <i className="fas fa-times t-btn t-right t-ripple t-hover-primary" onClick={onCancel} />
                </h1>
                <Checkbox className="t-bar" defaultChecked={props.ssoUsers} onChange={onSsoUserChecboxChange}>
                    {props.t('UsersTable.onlySSOUsers')}
                </Checkbox>
                {props.search && !props.ssoUsers && (
                    <SuperAdminUserSearch text={props.search.text} onChange={props.onSearchChange} />
                )}
            </div>
            <div className="superadmin-user-table">
                {props.loading ? (
                    <Loading />
                ) : (
                    <table className="t-table-all t-small t-striped">
                        <thead>
                            <tr>
                                {head.map(colName => (
                                    <th key={colName}>{t(colName)}</th>
                                ))}
                            </tr>
                        </thead>

                        {props.data?.length > 0 ? (
                            <tbody>
                                {props.data.map(row => (
                                    <tr
                                        key={row.id}
                                        className={`t-hover-light-primary ${cn({
                                            superadmin: Number(row.id) === minId
                                        })}`}
                                    >
                                        <td>{row.username}</td>
                                        <td>{row.name}</td>
                                        <td>{row.surname}</td>
                                        <td className="t-center t-hover-primary t-button">
                                            <i aria-hidden="true" onClick={onUserSubmit.bind(undefined, row)}>
                                                login
                                            </i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={head[1].length}>
                                        <NoData />
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(SuperAdminUserWindow);
