import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DriverBehaviorRankModel, PerfectDriveDriverDetailModel } from 'common/model/statistics';
import qa from 'qa-selectors';
import { Button } from 'common/components';
import ChartLineTrends from 'common/components/ChartLineTrends';
import cn from 'classnames';
import DriverBehaviorChartBar from 'modules/statistics/modules/driver-behavior-coach/components/DriverBehaviorChartBar';
import ChartCompare, { ChartCompareModel } from 'common/components/ChartCompare';
import { Colors } from '../DriverScoreProgress';
import { TrendScoreValues } from '../../DriverBehaviorModule';
import DriveRank from '../DriveRank';
import DriverScoreCard, { ScoreCardColor } from '../DriverScoreCard';
import { getDriverScoreData } from '../DriverScoreCard/scoreDriver';
import { DriverBehaviorNavTabs } from '../DriverDetailNav';
import { formatDriverBehaviorName } from 'logic/driver-behavior-coach/driver-behavior-trucks';
import { Theme } from 'common/components/Settings';

enum ScoreToColor {
    overalScore = 'lightblue',
    driveScore = 'blue',
    ecoScore = 'green',
    wearTearScore = 'yellow'
}

interface Props {
    selectedTab?: DriverBehaviorNavTabs;
    data?: PerfectDriveDriverDetailModel;
    comparedData?: PerfectDriveDriverDetailModel;
    theme: Theme;
    drivers: {
        value: string;
        label: string;
    }[];
}

export default function DriverTrendsScoreStats(props: Props) {
    const { t } = useTranslation();
    const [selectedScore, setSelectedScore] = useState<string>('');

    function getData(fieldName?: string): any[] {
        let driverData = [];

        if (props.data) {
            if (fieldName) {
                driverData.push(getDriverScoreData(props.data, fieldName));
            } else {
                driverData.push({
                    name: formatDriverBehaviorName(props.data.name, props.data.surname, props.data.tachocard),
                    rank: props.data.scores?.[0].rank,
                    totalDrivers: props.data.scores?.[0].numberOfDrivers
                } as DriverBehaviorRankModel);
            }
        }

        if (props.comparedData) {
            if (fieldName) {
                driverData.push(getDriverScoreData(props.comparedData, fieldName));
            } else {
                driverData.push({
                    name: formatDriverBehaviorName(
                        props.comparedData.name,
                        props.comparedData.surname,
                        props.comparedData.tachocard
                    ),
                    rank: props.comparedData?.scores?.[0]?.rank,
                    totalDrivers: props.comparedData?.scores?.[0]?.numberOfDrivers
                } as DriverBehaviorRankModel);
            }
        }

        return driverData;
    }

    function toggleChart(score: string) {
        setSelectedScore(score && score !== selectedScore ? score : '');
    }

    const activeColor =
        ScoreToColor[selectedScore] === 'lightblue'
            ? Colors.Lightblue
            : ScoreToColor[selectedScore] === 'blue'
            ? Colors.Blue
            : ScoreToColor[selectedScore] === 'green'
            ? Colors.Green
            : ScoreToColor[selectedScore] === 'purple'
            ? Colors.Purple
            : Colors.Yellow;

    return (
        <div
            className={cn('driver-score-stats', {
                'driver-score-stats-selected': selectedScore
            })}
        >
            <div className="driver-score-stats-trends">
                <DriveRank driversList={getData()} />

                <Button
                    className={cn('driver-score-stats-button', {
                        'driver-score-stats-button-selected': selectedScore === 'overalScore'
                    })}
                    block
                    type="link"
                    onClick={() => toggleChart('overalScore')}
                >
                    <DriverScoreCard
                        className="overall-score"
                        color={ScoreCardColor.Lightblue}
                        driversList={getData('overalScore')}
                        label={t('DriverBehaviorTable.filters.overal')}
                        selected={selectedScore === 'overalScore'}
                        trend={true}
                        size={120}
                        important={true}
                        qa={qa.driverBehaviour.trucks.detail.fieldScore}
                        theme={props.theme}
                    />
                </Button>

                <Button
                    className={cn('driver-score-stats-button', {
                        'driver-score-stats-button-selected': selectedScore === TrendScoreValues.driveScore
                    })}
                    block
                    type="link"
                    onClick={() => toggleChart(TrendScoreValues.driveScore)}
                >
                    <DriverScoreCard
                        label={t('DriverBehaviorTable.filters.driving')}
                        driversList={getData(TrendScoreValues.driveScore)}
                        size={120}
                        color={ScoreCardColor.Blue}
                        trend={true}
                        selected={selectedScore === TrendScoreValues.driveScore}
                        qa={qa.driverBehaviour.trucks.detail.fieldDrive}
                        theme={props.theme}
                    />
                </Button>

                <Button
                    className={cn('driver-score-stats-button', {
                        'driver-score-stats-button-selected': selectedScore === TrendScoreValues.ecoScore
                    })}
                    block
                    type="link"
                    onClick={() => toggleChart(TrendScoreValues.ecoScore)}
                >
                    <DriverScoreCard
                        label={t('DriverBehaviorTable.filters.eco')}
                        driversList={getData(TrendScoreValues.ecoScore)}
                        size={120}
                        color={ScoreCardColor.Green}
                        trend={true}
                        selected={selectedScore === TrendScoreValues.ecoScore}
                        qa={qa.driverBehaviour.trucks.detail.fieldEco}
                        theme={props.theme}
                    />
                </Button>
                <Button
                    className={cn('driver-score-stats-button', {
                        'driver-score-stats-button-selected': selectedScore === TrendScoreValues.wearTearScore
                    })}
                    block
                    type="link"
                    onClick={() => toggleChart(TrendScoreValues.wearTearScore)}
                >
                    <DriverScoreCard
                        label={t('DriverBehaviorTable.filters.wearTear')}
                        driversList={getData(TrendScoreValues.wearTearScore)}
                        size={120}
                        color={ScoreCardColor.Yellow}
                        trend={true}
                        selected={selectedScore === TrendScoreValues.wearTearScore}
                        qa={qa.driverBehaviour.trucks.detail.fieldWearTear}
                        theme={props.theme}
                    />
                </Button>
            </div>
            {selectedScore && (
                <div className={`driver-score-stats-chart ${selectedScore}`}>
                    {props.data?.id && (
                        <>
                            <DriverBehaviorChartBar
                                label={t('DriverBehaviorCharts.scoreTrend')}
                                drivers={props.drivers}
                                mainId={props.data.id}
                                comparedDriverId={props.comparedData?.id}
                                color={activeColor}
                                useScore={false}
                            />
                            {props.comparedData ? (
                                <ChartCompare
                                    color={activeColor}
                                    theme={props.theme}
                                    data={[
                                        ...props.data.history
                                            .map(
                                                (d, i) =>
                                                    ({
                                                        xIndex: i,
                                                        xVal:
                                                            i === 0
                                                                ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                                : i === 11
                                                                ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                                : i < 12
                                                                ? moment(d.month).format('MMM').toUpperCase()
                                                                : undefined,
                                                        yVal: d[selectedScore],
                                                        yLine: 'main'
                                                    } as ChartCompareModel)
                                            )
                                            .reverse(),
                                        ...props.comparedData.history
                                            .map(
                                                (d, i) =>
                                                    ({
                                                        xIndex: i,
                                                        xVal:
                                                            i === 0
                                                                ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                                : i === 11
                                                                ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                                : i < 12
                                                                ? moment(d.month).format('MMM').toUpperCase()
                                                                : undefined,
                                                        yVal: d[selectedScore],
                                                        yLine: 'compared'
                                                    } as ChartCompareModel)
                                            )
                                            .reverse()
                                    ]}
                                    height={270}
                                    lineWidth={2}
                                />
                            ) : (
                                <ChartLineTrends
                                    size="large"
                                    theme={props.theme}
                                    showPoints={true}
                                    pointRadius={1}
                                    data={props.data.history
                                        .map((d, i) => {
                                            return {
                                                xIndex: i,
                                                yVal: d[selectedScore],
                                                xVal:
                                                    i === 0
                                                        ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                        : i === 11
                                                        ? moment(d.month).format('MMM YYYY').toUpperCase()
                                                        : i < 12
                                                        ? moment(d.month).format('MMM').toUpperCase()
                                                        : '',
                                                trendVal:
                                                    props.data?.history[i]?.[selectedScore] &&
                                                    props.data?.history[i + 1]?.[selectedScore]
                                                        ? props.data?.history[i][selectedScore] >
                                                          props.data?.history[i + 1][selectedScore]
                                                            ? 'up'
                                                            : props.data?.history[i][selectedScore] <
                                                              props.data?.history[i + 1][selectedScore]
                                                            ? 'down'
                                                            : undefined
                                                        : undefined
                                            };
                                        })
                                        .reverse()}
                                    height={290}
                                    maxY={100}
                                    color={activeColor}
                                    lineWidth={2}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
