import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import qa from 'qa-selectors';
import { cup } from 'resources/images/driver-behavior';

interface Props {
    value: ReactNode;
    total: ReactNode;
}

export default function DriveRank({ value, total }: Props) {
    const { t } = useTranslation();
    return (
        <Row className="drive-rank-trends" gutter={[4, 0]} align="middle">
            <Col>
                <img src={cup} alt="cup" />
            </Col>
            <Col className="drive-rank-value" data-qa={qa.driverBehaviour.trucks.detail.rank}>
                {value}
            </Col>
            <Col className="drive-rank-total">
                {t('DriveRank.outOf')} <span data-qa={qa.driverBehaviour.trucks.detail.total}>{total}</span>
            </Col>
        </Row>
    );
}
