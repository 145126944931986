/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalSystemSecretMetadata,
    ExternalSystemSecretMetadataFromJSON,
    ExternalSystemSecretMetadataFromJSONTyped,
    ExternalSystemSecretMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface WriteOnlyExternalSystemSecret
 */
export interface WriteOnlyExternalSystemSecret {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyExternalSystemSecret
     */
    externalSystemAccessId: string;
    /**
     * 
     * @type {ExternalSystemSecretMetadata}
     * @memberof WriteOnlyExternalSystemSecret
     */
    metadata?: ExternalSystemSecretMetadata;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyExternalSystemSecret
     */
    secret?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyExternalSystemSecret
     */
    client: number;
}

export function WriteOnlyExternalSystemSecretFromJSON(json: any): WriteOnlyExternalSystemSecret {
    return WriteOnlyExternalSystemSecretFromJSONTyped(json, false);
}

export function WriteOnlyExternalSystemSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyExternalSystemSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalSystemAccessId': json['external_system_access_id'],
        'metadata': !exists(json, 'metadata') ? undefined : ExternalSystemSecretMetadataFromJSON(json['metadata']),
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'client': json['client'],
    };
}

export function WriteOnlyExternalSystemSecretToJSON(value?: WriteOnlyExternalSystemSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_system_access_id': value.externalSystemAccessId,
        'metadata': ExternalSystemSecretMetadataToJSON(value.metadata),
        'secret': value.secret,
        'client': value.client,
    };
}


