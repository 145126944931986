import { NoData } from 'common/components/NoData';
import { GraphKey } from '../../JourneyGraph';
import { useComplexGraphData } from '../utils/useComplexGraphData';
import { ExternalDevicesConfig } from '../utils/useExternalDevicesConfig';
import ComplexGraph from './externalDevicesGraph/ComplexGraph';
import SimpleGraph from './SimpleGraph';
import { JourneyGraphDataModel } from '../../../JourneysModule';

interface Props {
    data?: JourneyGraphDataModel;
    selectedSection: GraphKey;
    onActiveDotMount(p: { key: string | number; value: string | number }, i: number): void;
    onActiveDotDestroy?(): void;
    activeGraphDotIndex?: number;
    externalDevicesConfig: ExternalDevicesConfig;
    seriesLabels?: {
        [key: string]: string;
    };
}

const GraphSwitch = ({
    selectedSection,
    data,
    onActiveDotMount,
    onActiveDotDestroy,
    activeGraphDotIndex,
    externalDevicesConfig,
    seriesLabels
}: Props) => {
    const { getFormattedExternalDevicesData } = useComplexGraphData(data);

    if (selectedSection === GraphKey.externalDevices) {
        if ((data?.timestampsTemperatures?.length ?? 0) === 0) {
            return <NoData />;
        }

        return (
            <ComplexGraph
                externalDevicesConfig={externalDevicesConfig}
                data={getFormattedExternalDevicesData(externalDevicesConfig)}
                seriesLabels={seriesLabels}
            />
        );
    }

    if ((data?.timestamps?.length ?? 0) === 0) {
        return <NoData />;
    }

    return (
        <SimpleGraph
            data={data}
            onActiveDotMount={onActiveDotMount}
            selectedSection={selectedSection}
            onActiveDotDestroy={onActiveDotDestroy}
            activeGraphDotIndex={activeGraphDotIndex}
        />
    );
};

export default GraphSwitch;
