import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { SelectField } from 'common/fields';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';
import React from 'react';
import { SelectOption } from 'common/components/Form/Select';

export interface GroupsAssignmentFormModel {
    monitoredObjectGroupIds: number[];
}

interface Props extends WithTranslation {
    initialValues?: Partial<GroupsAssignmentFormModel>;
    monitoredObjectGroupsOptions: SelectOption[];
    header: string;
    loading: boolean;
    onSubmit: (values: GroupsAssignmentFormModel) => void;
    onCancel: () => void;
}

interface State {}

class GroupsAssignmentForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const schema = Yup.object().shape({
            vehicleGroupIds: Yup.array()
        });

        return (
            <>
                <Formik<GroupsAssignmentFormModel>
                    onSubmit={this._handleSubmit}
                    validationSchema={schema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    enableReinitialize
                    initialValues={{
                        monitoredObjectGroupIds: this.props.loading
                            ? []
                            : this.props.initialValues?.monitoredObjectGroupIds ?? []
                    }}
                >
                    {(formik: FormikProps<GroupsAssignmentFormModel>) => {
                        return (
                            <Form className="vehicle-group-form">
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col span={24}>
                                        <label className="assign-label">{this.props.header}</label>
                                    </Col>
                                </Row>
                                <Row className="form-row" gutter={[20, 10]}>
                                    <Col span={24}>
                                        <SelectField
                                            loading={this.props.loading}
                                            size="large"
                                            mode="multiple"
                                            options={this.props.monitoredObjectGroupsOptions}
                                            name="monitoredObjectGroupIds"
                                            label={this.props.t('common.groups')}
                                            placeholder={this.props.t('common.groups')}
                                        />
                                    </Col>
                                </Row>
                                <FormActions
                                    cancelQa={qa.fleet.form.btnCancel}
                                    submitQa={qa.fleet.form.btnSubmit}
                                    submitLoading={formik.isSubmitting}
                                    onCancelClick={this.props.onCancel}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </>
        );
    }

    private _handleSubmit = (values: GroupsAssignmentFormModel) => {
        return this.props.onSubmit?.(values);
    };
}

export default withTranslation()(GroupsAssignmentForm);
