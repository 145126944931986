/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleSettingsDiesel,
    VehicleSettingsDieselFromJSON,
    VehicleSettingsDieselFromJSONTyped,
    VehicleSettingsDieselToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOSettingArrayDiesel
 */
export interface TCOSettingArrayDiesel {
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayDiesel
     */
    type: TCOSettingArrayDieselTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayDiesel
     */
    defaultValue: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayDiesel
     */
    hint: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCOSettingArrayDiesel
     */
    units: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayDiesel
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TCOSettingArrayDiesel
     */
    visible: boolean;
    /**
     * 
     * @type {VehicleSettingsDiesel}
     * @memberof TCOSettingArrayDiesel
     */
    values: VehicleSettingsDiesel;
}

export function TCOSettingArrayDieselFromJSON(json: any): TCOSettingArrayDiesel {
    return TCOSettingArrayDieselFromJSONTyped(json, false);
}

export function TCOSettingArrayDieselFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOSettingArrayDiesel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'defaultValue': json['default_value'],
        'hint': json['hint'],
        'units': json['units'],
        'required': json['required'],
        'visible': json['visible'],
        'values': VehicleSettingsDieselFromJSON(json['values']),
    };
}

export function TCOSettingArrayDieselToJSON(value?: TCOSettingArrayDiesel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'default_value': value.defaultValue,
        'hint': value.hint,
        'units': value.units,
        'required': value.required,
        'visible': value.visible,
        'values': VehicleSettingsDieselToJSON(value.values),
    };
}

/**
* @export
* @enum {string}
*/
export enum TCOSettingArrayDieselTypeEnum {
    Integer = 'integer',
    Float = 'float',
    Array = 'array'
}


