import { AXIS_COLORS } from './constants/colors';

export type GraphConfigParams = {
    marging?: { top: number; right: number; left: number; bottom: number };
    dataKeyX?: string;
    fontSize?: number;
    dataKeyY?: string;
    axisStroke?: string;
};

export type SpecifycSettings = {
    lineStroke: string;
    tooltipValueUnit: string;
    qa?: string;
};

export const getGraphConfig = (params?: GraphConfigParams, specifycSettings?: SpecifycSettings) => {
    const generalSetting = {
        marging: params?.marging ?? { top: 10, right: 10, left: -30, bottom: 10 },
        dataKeyX: params?.dataKeyX ?? 'key',
        fontSize: { fontSize: params?.fontSize ?? 10 },
        dataKeyY: params?.dataKeyY ?? 'value',
        axisStroke: params?.axisStroke ?? AXIS_COLORS.primary
    };

    return {
        ...generalSetting,
        ...specifycSettings
    };
};
