import numeral from 'numeral';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
    valueUnit?: string;
    seriesLabels?: {
        [key: string]: string;
    };
    color?: string;
    complex?: boolean;
    colors?: Record<string, string>;
}

const GraphTooltip = ({
    active,
    payload,
    label,
    valueUnit,
    color,
    complex,
    colors,
    seriesLabels: seriesLabel
}: CustomTooltipProps) => {
    if (active) {
        if (complex) {
            return (
                <div className="journey-graph-custom-tooltip">
                    <p className="custom-tooltip-label">{label}</p>
                    {payload?.map((item, index) => (
                        <div key={`tooltip-value-${index}`} className="tooltip-mapped-values">
                            <p>{item.name ? seriesLabel?.[item.name] : item.name}</p>
                            <p
                                className="custom-tooltip-value"
                                style={{ color: item.dataKey ? colors?.[item.dataKey] : color, marginLeft: 12 }}
                            >{`${numeral(payload?.[index].value).format('0,0.0')} ${valueUnit}`}</p>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className="journey-graph-custom-tooltip">
                <p className="custom-tooltip-label">{label}</p>
                <p className="custom-tooltip-value" style={{ color }}>{`${payload?.[0].value} ${valueUnit}`}</p>
            </div>
        );
    }

    return null;
};

export default GraphTooltip;
