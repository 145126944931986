import { ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Search, { SearchData } from 'common/components/Search';
import ButtonHelper from '../ButtonHelper';
import cn from 'classnames';
import { Button } from '..';
import qa from 'qa-selectors';
import { filter } from 'resources/images/common';
import Badge from 'common/components//Badge';

interface Props {
    heading: ReactNode;
    labels?: ReactNode[];
    labelsRight?: ReactNode[];
    className?: string;
    search?: SearchData;
    filters?: ReactNode[];
    actions?: ReactNode[];
    backButton?: {
        onClick?: () => void;
        qa?: string;
        title?: string;
    };
    datePicker?: ReactNode;
    searchInput?: {
        disabled?: boolean;
    };
    filter?: {
        active?: boolean;
        onClick?: () => void;
        qa?: string;
        activeCount?: number;
        toHeader?: boolean;
        resetButton?: {
            onClick?: () => void;
            qa?: string;
        };
    };
    onSearchChange?: (text: string) => void;
    onHelperClick?: () => void;
}

export default function TableBar(props: Props) {
    const { t } = useTranslation();

    return (
        <div
            className={cn('table-bar', props.className, {
                'table-bar-single-row': props.filter?.toHeader
            })}
        >
            <Row justify="space-between" align="middle">
                <Col>
                    <Row gutter={16} align="bottom">
                        <Col>
                            <Typography.Title level={1}>
                                {props.backButton?.onClick && (
                                    <Button
                                        type="text"
                                        size="large"
                                        icon={
                                            <i
                                                className="table-bar-back-btn fas fa-arrow-left"
                                                data-qa={props.backButton.qa ?? qa.common.btnBack}
                                            />
                                        }
                                        title={props.backButton.title ?? t('common.back')}
                                        onClick={props.backButton.onClick}
                                    />
                                )}
                                {props.heading}
                            </Typography.Title>
                        </Col>
                        {props.labels
                            ?.filter(d => d)
                            .map((d, i) => (
                                <Col className="table-bar-label" key={i}>
                                    {d}
                                </Col>
                            ))}
                    </Row>
                </Col>
                <Col>
                    <Row gutter={16} justify="end">
                        {props.filter?.onClick && props.filter.toHeader && (
                            <>
                                <Col className="table-bar-filter-header">
                                    <Button
                                        type={
                                            props.filter.activeCount && props.filter.activeCount > 0
                                                ? 'primary'
                                                : 'default'
                                        }
                                        size="middle"
                                        icon={<img src={filter} alt="filter" />}
                                        qa={props.filter.qa}
                                        onClick={props.filter?.onClick}
                                    />
                                    {props.filter.activeCount && props.filter.activeCount > 0 ? (
                                        <Badge count={props.filter.activeCount} className="table-bar-filter-count" />
                                    ) : null}
                                </Col>
                                {props.filter.activeCount &&
                                props.filter.activeCount > 0 &&
                                props.filter?.resetButton?.onClick ? (
                                    <Col>
                                        <Button type="link" size="middle" onClick={props.filter?.resetButton?.onClick}>
                                            {t('common.resetFilter')}
                                        </Button>
                                    </Col>
                                ) : null}
                            </>
                        )}
                        {props.labelsRight?.length && props.labelsRight?.length > 0 && (
                            <Col>
                                {props.labelsRight
                                    ?.filter(d => d)
                                    .map((d, i) => (
                                        <Col className="table-bar-label-right" key={i}>
                                            {d}
                                        </Col>
                                    ))}
                            </Col>
                        )}
                        {props.onHelperClick && (
                            <Col>
                                <ButtonHelper onClick={props.onHelperClick} />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row justify="space-between" align="bottom" className="table-bar-filters-actions" gutter={[0, 10]}>
                <Col span={!props.actions ? 24 : undefined}>
                    <Row gutter={16} align="middle">
                        {props.onSearchChange && (
                            <Col>
                                <Search
                                    searchActive={true}
                                    search={props.search}
                                    onSearch={props.onSearchChange}
                                    disabled={props.searchInput?.disabled}
                                />
                            </Col>
                        )}
                        {props.datePicker && <Col>{props.datePicker}</Col>}
                        {props.filter?.onClick && props.filter.toHeader !== true && (
                            <>
                                <Col>
                                    <Button
                                        type={
                                            props.filter.activeCount && props.filter.activeCount > 0
                                                ? 'primary'
                                                : 'default'
                                        }
                                        size="middle"
                                        icon={<img src={filter} alt="filter" />}
                                        qa={props.filter.qa}
                                        onClick={props.filter?.onClick}
                                    />
                                    {props.filter.activeCount && props.filter.activeCount > 0 ? (
                                        <Badge count={props.filter.activeCount} className="table-bar-filter-count" />
                                    ) : null}
                                </Col>
                                {props.filter.activeCount &&
                                props.filter.activeCount > 0 &&
                                props.filter?.resetButton?.onClick ? (
                                    <Col>
                                        <Button
                                            type="link"
                                            onClick={props.filter?.resetButton?.onClick}
                                            qa={qa.common.bar.btnReset}
                                        >
                                            {t('common.resetFilter')}
                                        </Button>
                                    </Col>
                                ) : null}
                            </>
                        )}
                        {props.filters
                            ?.filter(d => d)
                            .map((d, i) => (
                                <Col
                                    span={
                                        !props.datePicker && !props.onSearchChange && props.filters?.length === 1
                                            ? 24
                                            : undefined
                                    }
                                    key={i}
                                >
                                    {d}
                                </Col>
                            ))}
                    </Row>
                </Col>
                {props.actions && (
                    <Col>
                        <Row gutter={10} align="middle">
                            {props.actions?.map((d, i) => (
                                <Col key={i}>{d}</Col>
                            ))}
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
}
