import cn from 'classnames';
import { MouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'common/components';

import { FleetModel, FleetLinks, ToBeUnpaired, FleetType, ModuleToKey } from '../FleetModule';
import { moduleResolver, Role } from 'logic/auth';
import Tag from 'common/components/Tag';
import { Tooltip, Button, Popover } from 'common/components';
import qa from 'qa-selectors';
import { DATE_TIME_FORMAT, TABLE_SCROLL_Y_WITHOUT_PAGGING } from 'domain-constants';
import moment from 'moment';
import * as iconModule from 'resources/images/modules';
import { ReadOnlyMonitoredObjectGroupNested } from 'generated/new-main/models';

interface Props extends WithTranslation {
    selected?: FleetModel;
    data?: FleetModel[];
    loading?: boolean;
    roles: Role[];
    groups: ReadOnlyMonitoredObjectGroupNested[];
    demoMode?: boolean;
    tableRowRef?: React.RefObject<HTMLTableRowElement>;
    onTableRowSelect?: (id: string) => void;
    onUnpairTrailerFromVehicleIconClick?: (type: keyof FleetLinks, data: ToBeUnpaired) => void;
}

function FleetTable(props: Props) {
    const { t } = props;

    function onClickRow(id: string) {
        props.onTableRowSelect?.(id);
    }

    function onClickUnpairTrailer(vehicleId: string, trailerId: string, e: MouseEvent) {
        e.stopPropagation();
        if (props.roles.includes(Role.V_IE)) {
            props.onUnpairTrailerFromVehicleIconClick?.('trailers', { id: trailerId, idToUnpair: vehicleId });
        }
    }

    function moduleTag(module: number) {
        return (
            <Tooltip
                key={module}
                title={`${t('common.modul')} ${module} - ${t(
                    `UserRights.modules.${ModuleToKey[`module${module}`]}.name`
                )}`}
                placement="top"
                mouseEnterDelay={0}
                overlayClassName="management-fleet-module-tooltip"
            >
                <Tag qa={qa.fleet.table.module[module]}>
                    <img
                        className="icon-module"
                        src={iconModule[`icon_module_${module}`]}
                        alt={`${t('common.modul')} ${module}`}
                    />
                </Tag>
            </Tooltip>
        );
    }

    function createColumns(): ColumnsType<FleetModel> {
        return [
            {
                title: '',
                width: 25,
                render: (_, record) => (
                    <>
                        {(record.type === FleetType.VEHICLE || record.type === FleetType.LIGHT_VEHICLE) && (
                            <span>
                                {record.disabledAt && (
                                    <Popover
                                        content={t('ManagementFleet.vehicleDisabledAt', {
                                            disabledAt: moment(record.disabledAt).format(DATE_TIME_FORMAT)
                                        })}
                                    >
                                        <i className={'fa fa-ban'} />
                                    </Popover>
                                )}
                            </span>
                        )}
                        {record.type === FleetType.TRAILER && record.links?.fleet?.[0]?.id && (
                            <Button
                                type="link"
                                className="management-fleet-table-unpair"
                                disabled={props.demoMode}
                                qa={qa.fleet.table.btnUnpairTrailer}
                                onClick={e =>
                                    onClickUnpairTrailer(String(record.links?.fleet?.[0]?.id), String(record.id), e)
                                }
                            >
                                <img src="/img-svg/icn-link-trailer.svg" alt="trailer" />
                            </Button>
                        )}
                    </>
                )
            },
            {
                title: t('common.rn'),
                width: 80,
                render: (_, record) => (
                    <Tooltip placement="topLeft" title={record.registrationNumber} qa={qa.fleet.table.fieldRN}>
                        {record.registrationNumber}
                    </Tooltip>
                )
            },
            {
                title: t('common.name'),
                width: 60,
                render: (_, record) => (
                    <Tooltip placement="topLeft" title={record.name} qa={qa.fleet.table.fieldName}>
                        {record.name}
                    </Tooltip>
                )
            },
            {
                title: t('common.manufacturer'),
                dataIndex: 'manufacturer',
                key: 'manufacturer',
                width: 60,
                render: (_, record) => (
                    <Tooltip placement="topLeft" title={record.manufacturer} qa={qa.fleet.table.fieldManufacturer}>
                        {record.manufacturer}
                    </Tooltip>
                )
            },
            {
                title: t('common.type'),
                width: 40,
                render: (_, record) => (
                    <span data-qa={qa.fleet.table.type[record.type]}>
                        {record.monitoredObjectType?.name &&
                            t(`Partner.${record.monitoredObjectType?.name?.toLowerCase()}`)}
                    </span>
                )
            },
            {
                title: t('common.fuelCard'),
                width: 75,
                render: (_, record) => (
                    <span data-qa={qa.fleet.table.fieldFuelCards}>
                        {record.fuelCards?.map(f => f.number).join(', ')}
                    </span>
                )
            },
            {
                title: t('common.defaultDriver'),
                dataIndex: 'defaultDriver',
                key: 'defaultDriver',
                width: 50,
                render: (_, record) => (
                    <span data-qa={qa.fleet.table.fieldDefaultDriver}>{record.defaultDriver?.name ?? '-'}</span>
                )
            },
            {
                title: t('common.modul'),
                width: 70,
                render: (_, record) => (
                    <span
                        className="management-fleet-table-services-tags management-fleet-module"
                        data-qa={qa.fleet.table.tagModule}
                    >
                        {record.services && moduleResolver(record.services)?.map(module => moduleTag(module))}
                    </span>
                )
            },
            {
                title: t('common.groups'),
                width: 70,
                render: (_, record) => (
                    <span className="management-fleet-table-services-tags">
                        {record.monitoredObjectGroups?.map((group, i) => (
                            <Tag key={i} qa={qa.fleet.table.tagGroup}>
                                {group.name}
                            </Tag>
                        ))}
                    </span>
                )
            }
        ];
    }

    return (
        <Table<FleetModel>
            loading={props.loading}
            dataSource={props.data?.map((item, index) => ({ ...item, key: `row_${index}` }))}
            columns={createColumns()}
            scroll={{ y: TABLE_SCROLL_Y_WITHOUT_PAGGING }}
            className="management-fleet-table"
            onRow={record => ({
                ref: record.id === props.selected?.id ? props.tableRowRef : undefined,
                onClick: () => onClickRow?.(String(record.id)),
                'data-qa':
                    record.type === FleetType.VEHICLE
                        ? qa.fleet.table.vehicle
                        : record.type === FleetType.LIGHT_VEHICLE
                        ? qa.fleet.table.lightVehicle
                        : qa.fleet.table.trailer,
                className: cn({
                    'ant-table-row-sub': record.type === FleetType.TRAILER && record.links?.fleet?.[0]?.id,
                    'ant-table-row-lead':
                        record.type === FleetType.VEHICLE ||
                        record.type === FleetType.LIGHT_VEHICLE ||
                        (record.type === FleetType.TRAILER && !record.links?.fleet?.[0]?.id),
                    'ant-table-row-selected': props.selected?.id === record.id
                })
            })}
        />
    );
}

export default withTranslation()(FleetTable);
