import ReactDOM from 'react-dom';
import { Conf, confDefault } from './conf';
import i18nInit from './i18n';
import { Logic } from 'logic/logic';
import App from 'App';
import { scripts } from 'load';
import { Loading } from 'common/components/Loading';
import { exponea } from 'logic/exponea';
import moment from 'moment';
import qs from 'qs';
import { toJS } from 'mobx';
import CustomerAccessModule from 'modules/customer-access';
import { Suspense } from 'react';
import { Theme } from 'common/components/Settings';

window.onerror = (message, source, lineno, colno, error) => {
    if (error?.stack) {
        console.error(message, source, lineno, colno, error);
        // TODO: send error to rever for processing
        // http.post(conf.onerror).send({
        //     stack: error.stack,
        //     bundle,
        //     source
        // });
    }
};

(window as any).toJS = toJS;

const embedded = window.location !== window.parent.location ? true : false;
const embeddedMap = embedded && window.location.hash.includes('journeys-activity') ? true : false;
const theme = embedded ? (window.name?.includes('light') ? Theme.Light : Theme.Dark) : undefined;

async function main() {
    ReactDOM.render(<Loading dark fullSize />, document.getElementById('root'));

    const conf: Conf = { ...confDefault, ...(window as any).CONF };
    const logic = new Logic(conf);

    logic.settings().setProp('tracking', conf.settings.tracking);

    const auth = logic.auth();
    await auth.init();

    logic.demo().active.subscribe(() => {
        main();
    });
    logic.ga().initialize(auth.user().id);
    logic.exponea().initialize(auth.user().id);

    const authToken = async (): Promise<string | undefined> => {
        await auth.updateToken();
        return auth.token();
    };

    logic.notification().connect(authToken).catch(console.error);
    // TODO: Remove if when dff will be fully tested
    if (conf.useDFFTransports) {
        logic.notificationDFF().connect(authToken).catch(console.error);
    }

    logic.api().init(authToken);
    try {
        logic.auth().serializeBinaryRights();
    } catch (err) {
        console.log('Cannot serialize binary rights', err);
    }

    await auth.loadUser();
    await auth.loadClient();

    logic.ga().pushEvent('client-actions', 'login', `${logic.auth().client()?.name} ${logic.auth().client()?.id}`);
    logic.exponea().trackEvent(exponea.module.clientLogin, {
        client: { name: logic.auth().client()?.name, id: logic.auth().client()?.id },
        user: auth.user().id
    });
    logic.exponea().updateAttributes({
        name: auth.user().name,
        user_id: auth.user().id,
        user_email: auth.user().email,
        company_name: auth.client()?.name,
        company_id: auth.client()?.id,
        language: auth.user().lang
    });

    const i18n = i18nInit(logic.auth().user().lang ?? conf.lang);

    (window as any).app = {
        version: conf.version,
        conf,
        i18n,
        logic,
        moment,
        test: () => scripts(['https://cdn.jsdelivr.net/npm/tape-standalone@4.4.0/tape.js', 'test.js'])
    };

    // Run remote test
    // console: app.logic.notification().send({ type: 'test' }, app.logic.auth().user().id);
    logic.notification().on('test', msg => {
        console.log(msg.type);
        (window as any).app.test();
        // Send result to server
    });

    // Request remote API stats
    // console: app.logic.notification().send({ type: 'api-stats', data: app.logic.auth().user().id }, app.logic.auth().user().id);
    logic.notification().on('api-stats', msg => {
        const senderId = msg.data as string;
        const stats = (logic.apollo() as any).stats;
        console.log(msg.type, stats);
        logic.notification().send({ type: 'api-stats-res', data: stats }, senderId);
    });

    logic.notification().on('api-stats-res', msg => {
        console.log(msg.type, msg.data);
    });

    ReactDOM.render(
        <Suspense fallback="Loading...">
            <App logic={logic} embedded={embedded} embeddedMap={embeddedMap} theme={theme} />
        </Suspense>,
        document.getElementById('root')
    );
}

// FIXME: This should be moved to new app
async function customerAccess(code: string) {
    const conf: Conf = { ...confDefault, ...(window as any).CONF };
    const logic = new Logic(conf);

    const customerAccess = logic.customerAccessLogic();

    const authToken = async (): Promise<string | undefined> => {
        const token = await customerAccess.getTokenFromCode(code);
        return token;
    };

    logic.notification().guestUser = `${Math.random().toString(36).substr(2)}-${Date.now().toString(36)}`;
    logic.notification().connect(authToken, 2e3).catch(console.error);
    logic.api().init(authToken);

    const i18n = i18nInit(logic.auth().user().lang ?? conf.lang);

    (window as any).app = {
        version: conf.version,
        conf,
        i18n,
        logic,
        moment,
        test: () => scripts(['https://cdn.jsdelivr.net/npm/tape-standalone@4.4.0/tape.js', 'test.js'])
    };

    ReactDOM.render(
        <CustomerAccessModule code={code} logic={logic} embedded={embedded} />,
        document.getElementById('root')
    );
}

if (window.location.href.includes('customer-access-preview')) {
    const params = qs.parse(window.location.search.substring(1));
    if (params.code) {
        customerAccess(params.code as string);
    } else {
        alert('Access code missing');
    }
} else {
    main();
}
