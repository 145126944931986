/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20017,
    InlineResponse20017FromJSON,
    InlineResponse20017ToJSON,
    MaintenanceTask,
    MaintenanceTaskFromJSON,
    MaintenanceTaskToJSON,
    MaintenanceTaskDeleteList,
    MaintenanceTaskDeleteListFromJSON,
    MaintenanceTaskDeleteListToJSON,
    MaintenanceTaskList,
    MaintenanceTaskListFromJSON,
    MaintenanceTaskListToJSON,
    MaintenanceTaskSummaryList,
    MaintenanceTaskSummaryListFromJSON,
    MaintenanceTaskSummaryListToJSON,
    MaintenanceTasksComplete,
    MaintenanceTasksCompleteFromJSON,
    MaintenanceTasksCompleteToJSON,
    NotificationReceivers,
    NotificationReceiversFromJSON,
    NotificationReceiversToJSON,
    ReadOnlyMaintenanceTask,
    ReadOnlyMaintenanceTaskFromJSON,
    ReadOnlyMaintenanceTaskToJSON,
    WriteOnlyMaintenanceTask,
    WriteOnlyMaintenanceTaskFromJSON,
    WriteOnlyMaintenanceTaskToJSON,
} from '../models';

export interface MaintenanceTaskCompleteRequest {
    data: MaintenanceTasksComplete;
}

export interface MaintenanceTaskCreateRequest {
    data: WriteOnlyMaintenanceTask;
}

export interface MaintenanceTaskCreateManyRequest {
    data: MaintenanceTaskList;
}

export interface MaintenanceTaskDeleteRequest {
    id: string;
}

export interface MaintenanceTaskDeleteManyRequest {
    data: MaintenanceTaskDeleteList;
}

export interface MaintenanceTaskListRequest {
    client?: string;
    dueDateGte?: string;
    dueDateLte?: string;
    textSearch?: string;
    intervalIsDate?: boolean;
    intervalIsKm?: boolean;
    taskTypeIds?: string;
    limit?: number;
    offset?: number;
    includeStates?: Array<string>;
    monitoredObjects?: Array<number>;
    users?: Array<number>;
    taskId?: string;
}

export interface MaintenanceTaskPartialUpdateRequest {
    id: string;
    data: MaintenanceTask;
    forceUpdate?: boolean;
}

export interface MaintenanceTaskReadRequest {
    id: string;
}

export interface MaintenanceTaskSummaryRequest {
    client?: string;
    dueDateGte?: string;
    dueDateLte?: string;
    textSearch?: string;
    intervalIsDate?: boolean;
    intervalIsKm?: boolean;
    taskTypeIds?: string;
    limit?: number;
    offset?: number;
}

export interface MaintenanceTaskUpdateRequest {
    id: string;
    data: WriteOnlyMaintenanceTask;
}

export interface MaintenanceTaskUpdateNotificationRecieversRequest {
    id: string;
}

/**
 * no description
 */
export class MaintenanceTaskApi extends runtime.BaseAPI {

    /**
     */
    async maintenanceTaskCompleteRaw(requestParameters: MaintenanceTaskCompleteRequest): Promise<runtime.ApiResponse<Array<MaintenanceTask>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/complete/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MaintenanceTasksCompleteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaintenanceTaskFromJSON));
    }

    /**
     */
    async maintenanceTaskComplete(requestParameters: MaintenanceTaskCompleteRequest): Promise<Array<MaintenanceTask>> {
        const response = await this.maintenanceTaskCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskCreateRaw(requestParameters: MaintenanceTaskCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMaintenanceTask>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMaintenanceTaskToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMaintenanceTaskFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskCreate(requestParameters: MaintenanceTaskCreateRequest): Promise<ReadOnlyMaintenanceTask> {
        const response = await this.maintenanceTaskCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskCreateManyRaw(requestParameters: MaintenanceTaskCreateManyRequest): Promise<runtime.ApiResponse<Array<MaintenanceTask>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/create-many/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MaintenanceTaskListToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaintenanceTaskFromJSON));
    }

    /**
     */
    async maintenanceTaskCreateMany(requestParameters: MaintenanceTaskCreateManyRequest): Promise<Array<MaintenanceTask>> {
        const response = await this.maintenanceTaskCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskDeleteRaw(requestParameters: MaintenanceTaskDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMaintenanceTask>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMaintenanceTaskFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskDelete(requestParameters: MaintenanceTaskDeleteRequest): Promise<ReadOnlyMaintenanceTask> {
        const response = await this.maintenanceTaskDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskDeleteManyRaw(requestParameters: MaintenanceTaskDeleteManyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskDeleteMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/delete-many/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: MaintenanceTaskDeleteListToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async maintenanceTaskDeleteMany(requestParameters: MaintenanceTaskDeleteManyRequest): Promise<void> {
        await this.maintenanceTaskDeleteManyRaw(requestParameters);
    }

    /**
     */
    async maintenanceTaskListRaw(requestParameters: MaintenanceTaskListRequest): Promise<runtime.ApiResponse<InlineResponse20017>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.dueDateGte !== undefined) {
            queryParameters['due_date__gte'] = requestParameters.dueDateGte;
        }

        if (requestParameters.dueDateLte !== undefined) {
            queryParameters['due_date__lte'] = requestParameters.dueDateLte;
        }

        if (requestParameters.textSearch !== undefined) {
            queryParameters['text_search'] = requestParameters.textSearch;
        }

        if (requestParameters.intervalIsDate !== undefined) {
            queryParameters['interval_is_date'] = requestParameters.intervalIsDate;
        }

        if (requestParameters.intervalIsKm !== undefined) {
            queryParameters['interval_is_km'] = requestParameters.intervalIsKm;
        }

        if (requestParameters.taskTypeIds !== undefined) {
            queryParameters['task_type_ids'] = requestParameters.taskTypeIds;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.includeStates) {
            queryParameters['include_states'] = requestParameters.includeStates.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.monitoredObjects) {
            queryParameters['monitored_objects'] = requestParameters.monitoredObjects.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.users) {
            queryParameters['users'] = requestParameters.users.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.taskId !== undefined) {
            queryParameters['task_id'] = requestParameters.taskId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskList(requestParameters: MaintenanceTaskListRequest): Promise<InlineResponse20017> {
        const response = await this.maintenanceTaskListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskPartialUpdateRaw(requestParameters: MaintenanceTaskPartialUpdateRequest): Promise<runtime.ApiResponse<MaintenanceTask>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.forceUpdate !== undefined) {
            queryParameters['force_update'] = requestParameters.forceUpdate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MaintenanceTaskToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MaintenanceTaskFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskPartialUpdate(requestParameters: MaintenanceTaskPartialUpdateRequest): Promise<MaintenanceTask> {
        const response = await this.maintenanceTaskPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskReadRaw(requestParameters: MaintenanceTaskReadRequest): Promise<runtime.ApiResponse<ReadOnlyMaintenanceTask>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMaintenanceTaskFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskRead(requestParameters: MaintenanceTaskReadRequest): Promise<ReadOnlyMaintenanceTask> {
        const response = await this.maintenanceTaskReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskSummaryRaw(requestParameters: MaintenanceTaskSummaryRequest): Promise<runtime.ApiResponse<MaintenanceTaskSummaryList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.dueDateGte !== undefined) {
            queryParameters['due_date__gte'] = requestParameters.dueDateGte;
        }

        if (requestParameters.dueDateLte !== undefined) {
            queryParameters['due_date__lte'] = requestParameters.dueDateLte;
        }

        if (requestParameters.textSearch !== undefined) {
            queryParameters['text_search'] = requestParameters.textSearch;
        }

        if (requestParameters.intervalIsDate !== undefined) {
            queryParameters['interval_is_date'] = requestParameters.intervalIsDate;
        }

        if (requestParameters.intervalIsKm !== undefined) {
            queryParameters['interval_is_km'] = requestParameters.intervalIsKm;
        }

        if (requestParameters.taskTypeIds !== undefined) {
            queryParameters['task_type_ids'] = requestParameters.taskTypeIds;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/summary/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MaintenanceTaskSummaryListFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskSummary(requestParameters: MaintenanceTaskSummaryRequest): Promise<MaintenanceTaskSummaryList> {
        const response = await this.maintenanceTaskSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskUpdateRaw(requestParameters: MaintenanceTaskUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMaintenanceTask>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling maintenanceTaskUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMaintenanceTaskToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMaintenanceTaskFromJSON(jsonValue));
    }

    /**
     */
    async maintenanceTaskUpdate(requestParameters: MaintenanceTaskUpdateRequest): Promise<ReadOnlyMaintenanceTask> {
        const response = await this.maintenanceTaskUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async maintenanceTaskUpdateNotificationRecieversRaw(requestParameters: MaintenanceTaskUpdateNotificationRecieversRequest): Promise<runtime.ApiResponse<Array<NotificationReceivers>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling maintenanceTaskUpdateNotificationRecievers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/maintenance-task/{id}/receivers/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationReceiversFromJSON));
    }

    /**
     */
    async maintenanceTaskUpdateNotificationRecievers(requestParameters: MaintenanceTaskUpdateNotificationRecieversRequest): Promise<Array<NotificationReceivers>> {
        const response = await this.maintenanceTaskUpdateNotificationRecieversRaw(requestParameters);
        return await response.value();
    }

}
