import { DefaultDriverInfo, DefaultDriverInfoFromJSON } from 'generated/backend-api';
import { users } from './users';
import { vehicles } from './vehicles';

export const defaultDrivers: DefaultDriverInfo[] = [
    {
        driver_id: users[0].id,
        monitored_object_id: String(vehicles[0].id),
        name: users[0].name,
        surname: users[0].surname,
        registration_number: vehicles[0].registrationNumber
    },
    {
        driver_id: users[1].id,
        monitored_object_id: String(vehicles[1].id),
        name: users[1].name,
        surname: users[1].surname,
        registration_number: vehicles[1].registrationNumber
    },
    {
        driver_id: users[2].id,
        monitored_object_id: String(vehicles[2].id),
        name: users[2].name,
        surname: users[2].surname,
        registration_number: vehicles[2].registrationNumber
    },
    {
        driver_id: users[5].id,
        monitored_object_id: String(vehicles[5].id),
        name: users[5].name,
        surname: users[5].surname,
        registration_number: vehicles[5].registrationNumber
    }
].map(DefaultDriverInfoFromJSON);
