import { PaginatedResponse, PaginationParams } from 'common/model/pagination';
import { ContactListListTypeEnum, ContactListListTypeOverlapEnum } from 'generated/new-main';
import { ReadOnlyContactList, WriteOnlyContactList } from 'generated/new-main/models';
import { Logic } from '../logic';

export class ClientContactLogic {
    constructor(private _logic: Logic) {}

    async getPaginatedContactList(
        paginationParams: PaginationParams,
        textSearch?: string,
        type?: ContactListListTypeEnum[],
        countriesIds?: number[]
    ): Promise<PaginatedResponse<ReadOnlyContactList[]>> {
        try {
            const result = await this._logic.api().ContactListApi.contactListList({
                ...paginationParams,
                textSearch,
                type,
                countryIn: countriesIds
            });
            return {
                data: result.results,
                total: result.count,
                ...paginationParams
            };
        } catch (err) {
            console.error("Can't get client contact list err:", err);
            throw err;
        }
    }

    async getAllClientContactList(type?: ContactListListTypeEnum[]): Promise<ReadOnlyContactList[]> {
        try {
            const result = await this._logic.api().ContactListApi.contactListList({
                limit: 9999,
                offset: 0,
                type
            });
            return result.results;
        } catch (err) {
            console.error("Can't get client contact list err:", err);
            throw err;
        }
    }

    async getAllClientContactListByType(type?: ContactListListTypeOverlapEnum[]): Promise<ReadOnlyContactList[]> {
        try {
            const result = await this._logic.api().ContactListApi.contactListList({
                limit: 9999,
                offset: 0,
                typeOverlap: type
            });
            return result.results;
        } catch (err) {
            console.error("Can't get client contact list err:", err);
            throw err;
        }
    }

    async createClientContact(client: WriteOnlyContactList): Promise<ReadOnlyContactList> {
        try {
            return await this._logic.api().ContactListApi.contactListCreate({
                data: client
            });
        } catch (err) {
            console.error("Can't create client contact err:", err);
            throw err;
        }
    }

    async updateClientContact(id: string, client: WriteOnlyContactList): Promise<ReadOnlyContactList> {
        try {
            return await this._logic.api().ContactListApi.contactListPartialUpdate({
                id,
                data: client
            });
        } catch (err) {
            console.error("Can't update client contact err:", err);
            throw err;
        }
    }

    async removeClientContact(id: string) {
        try {
            return await this._logic.api().ContactListApi.contactListDelete({
                id
            });
        } catch (err) {
            console.error("Can't remove client contact err:", err);
            throw err;
        }
    }
}
