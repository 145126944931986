import React, { Component } from 'react';
import { RouteNames } from 'App';
import { Role } from 'logic/auth';
import Clock from '../Clock';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import cn from 'classnames';
import qa from 'qa-selectors';
import Settings, { AddressIdentification, VehicleIdentification, Theme } from '../Settings';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import * as dbIcons from 'resources/images/dispatcher-board';
import Badge from '../Badge';

interface NavItemsProps {
    aligned?: 'right' | 'left';
    className?: string;
    qa?: string;
}

interface BarProps {
    color?: string;
    children?: React.ReactNode;
    qa?: string;
}

interface BarItemButtonProps extends NavItemsProps {
    link?: string;
    isNavLink?: boolean;
    children: string | React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface MessagingIconButtonProps extends NavItemsProps {
    title?: string;
    link?: string;
    icon: string;
    count?: number;
    active?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    qa?: string;
    qaCount?: string;
}

interface BarItemIconProps extends NavItemsProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    icon: string;
    title: string;
    children?: React.ReactNode;
    qa?: string;
}

interface BarDropdownProps extends NavItemsProps {
    label: string;
    link?: string;
    activeLinks?: string[];
    horizontal?: boolean;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface BarItemProps extends NavItemsProps {
    children?: React.ReactNode;
}

export function Bar(props: BarProps) {
    const { children } = props;
    return (
        <div className="navbar t-bar">
            <input id="navbar-menu-checkbox" type="checkbox" />
            <label className="navbar-menu" htmlFor="navbar-menu-checkbox">
                <div className="navbar-menu-btn">
                    <i data-qa={qa.navbar.menuOpenerButton} className="navbar-menu-icon fas fa-bars" />
                </div>
                {children}
            </label>
        </div>
    );
}

export function BarItem(props: BarItemProps) {
    const { children, aligned, className } = props;
    return (
        <div className={`t-bar-item ${aligned ? 't-' + aligned : ''} ${className ? className : ''}`}>{children}</div>
    );
}

export function BarItemButton(props: BarItemButtonProps) {
    const { children, link, aligned, onClick, className, isNavLink } = props;
    return link ? (
        <NavLink
            to={link}
            onClick={onClick}
            data-qa={props.qa}
            activeClassName={isNavLink ? 'active' : 'active-not-selected'}
            className={`t-bar-item t-button ${aligned ? 't-' + aligned : ''}  ${className ? className : ''}`}
        >
            {children}
        </NavLink>
    ) : (
        <div
            onClick={onClick}
            data-qa={props.qa}
            className={`t-bar-item t-button ${aligned ? 't-' + aligned : ''}  ${className ? className : ''}`}
        >
            {children}
        </div>
    );
}

export function BarIconButton(props: MessagingIconButtonProps) {
    const { aligned, icon, count, active, onClick, title, className } = props;
    return (
        <span
            data-qa={props.qa}
            title={title}
            className={cn(`${className} pointer`, {
                active,
                count,
                't-right': aligned === 'right',
                't-left': aligned === 'left'
            })}
            onClick={onClick}
        >
            <i className={`fa fa-${icon}`} />
            <Badge qa={props.qaCount} count={count} size="small" className={`${className}-badge`} overflowCount={99} />
        </span>
    );
}

export function BarItemIcon(props: BarItemIconProps) {
    const { aligned, icon, title, onClick, className, children } = props;
    return (
        <span
            data-qa={props.qa}
            onClick={onClick}
            className={cn('t-bold t-bar-item t-button ', className, aligned ? 't-' + aligned : '')}
        >
            <i title={title} className={cn('fas', `fa-${icon}`)} />
            &nbsp;{children}
        </span>
    );
}

export function BarDropdown(props: BarDropdownProps) {
    const { label, children, aligned, className, onClick, horizontal, activeLinks, link } = props;
    const location = useLocation();

    const isActive = () => {
        if (!activeLinks) {
            return false;
        }
        return activeLinks.some(activeLink => location.pathname.toLocaleLowerCase().startsWith(activeLink));
    };
    return (
        <div
            onClick={onClick}
            className={cn('t-bar-item t-button t-dropdown-hover', aligned ? 't-' + aligned : '', {
                active: isActive()
            })}
        >
            <NavLink to={link ?? ''} activeClassName="active-not-selected" isActive={isActive} onClick={onClick}>
                <button data-qa={props.qa} className={`t-button ${className ? className : ''}`}>
                    {label}
                    {/* <i className="fa fa-caret-down"></i> */}
                </button>
            </NavLink>
            <div className="dropdown-wrapper">
                <div
                    className={cn('t-dropdown-content t-card-4', {
                        't-bar-block': !horizontal
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
    newMessagesLength?: number;
    newAlarmsLength?: number;
    newMaintenanceNotificationsLength?: number;
    alarmsActive: boolean;
    maintenanceNotificationsActive: boolean;
    messagesActive: boolean;
    docsActive: boolean;
    settingsVisible: boolean;
    addressIdentification?: AddressIdentification;
    vehicleIdentification?: VehicleIdentification;
    theme?: Theme;
    lang: string;
    demoMode?: boolean;
    companyName?: string;
    onLogout?: () => void;
    onLangChange?: (lang: string) => void;
    onMessaging?: () => void;
    onAlarmsClick?: () => void;
    onMaintenanceNotificationsClick?: () => void;
    onDocsClick?: () => void;
    onSettingsToggle?: () => void;
    onAddressIdentificationChange: (setting: AddressIdentification) => void;
    onVehicleIdentificationChange: (setting: VehicleIdentification) => void;
    onThemeChange: (setting: Theme) => void;
    onDemoModeToggle: (active: boolean) => void;
    onCompanyNameCopy?: () => void;
}

interface State {
    logo?: string;
}

class NavBar extends Component<Props, State> {
    pageGroupRoles = {
        pageRolePlanner: {
            [RouteNames.SCHEDULING_PLANNER]: [Role.PLN_R],
            [RouteNames.SCHEDULING_PLANNER_NEW]: [Role.PLN_R_2],
            [RouteNames.SCHEDULING_ORDERS]: [Role.ROW_R]
        },

        pageRoleStatistics: {
            [RouteNames.STATISTICS_COMPANY_PROFILE]: [Role.CP_R, Role.DBHD_R],
            [RouteNames.STATISTICS_JOURNEYS_ACTIVITY]: [Role.JA_R],
            [RouteNames.STATISTICS_FUEL_CONSUMPTION]: [Role.FC_R],
            [RouteNames.STATISTICS_AETR]: [Role.AEI_R],
            [RouteNames.STATISTICS_EXPENSES]: [Role.EX_R],
            [RouteNames.STATISTICS_DRIVER_BEHAVIOR]: [Role.DBH_R],
            [RouteNames.STATISTICS_BORDER_CROSSING]: [Role.BC_R],
            [RouteNames.STATISTICS_MAINTENANCE]: [Role.MTN_R],
            [RouteNames.STATISTICS_ALLOWANCES]: [Role.DIT_R],
            [RouteNames.STATISTICS_STATISTIC_REPORT]: [Role.FC_R],
            [RouteNames.STATISTICS_COLD_CHAIN]: [Role.CLD_R]
        },

        pageRoleSettings: {
            [RouteNames.SETTINGS_FLEET]: [Role.V_R],
            [RouteNames.SETTINGS_ROLES]: [Role.USR_W],
            [RouteNames.SETTINGS_USERS]: [Role.USR_R],
            [RouteNames.SETTINGS_FUEL_CARDS]: [Role.EX_R],
            [RouteNames.SETTINGS_TACHO_CARDS]: [Role.TC_R],
            [RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY]: [Role.CRD_R],
            [RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY]: [Role.TRD_R],
            [RouteNames.SETTINGS_CUSTOM_PLACES]: [Role.POI_R],
            [RouteNames.SETTINGS_SYSTEM_CONNECTIONS]: [Role.CA_R, Role.CAC_R, Role.OAC_R, Role.PUESC]
        }
    };

    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        const roles = this.props.logic.auth().roles();
        const realUserRoles = this.props.logic.auth().roles(true);
        const superadmin = this.props.logic.auth().superadmin();
        const user = this.props.logic.auth().user();

        const { t } = this.props;

        return (
            <>
                <Bar>
                    <BarItemButton
                        qa={qa.navbar.logo}
                        className="navbar-logo"
                        link={[Role.LM_R].some(r => roles.includes(r)) ? RouteNames.DEFAULT : undefined}
                        onClick={e =>
                            [Role.LM_R].some(r => roles.includes(r)) &&
                            this._onBarItemButtonClick(e, RouteNames.TRACKING)
                        }
                    >
                        {this.state.logo ? (
                            <img className="logo" alt="" src={this.state.logo} />
                        ) : (
                            <div className="logo" />
                        )}
                    </BarItemButton>
                    {superadmin && (
                        <BarItemButton
                            className="super-admin"
                            aligned="left"
                            link={RouteNames.SUPERADMIN}
                            onClick={this._onBarItemButtonClick}
                        >
                            {t('NavBar.superAdmin')}
                        </BarItemButton>
                    )}
                    {[Role.LM_R, Role.LT_R].some(r => roles.includes(r)) && (
                        <BarItemButton
                            className="navbar-map"
                            aligned="left"
                            link={RouteNames.TRACKING}
                            isNavLink
                            qa={qa.navbar.btnMap}
                            onClick={this._onBarItemButtonClick}
                        >
                            {t('NavBar.mapView')}
                        </BarItemButton>
                    )}
                    {[Role.BRD_R].some(r => roles.includes(r)) && (
                        <BarItemButton
                            className="navbar-dispatcher-board"
                            aligned="left"
                            link={RouteNames.SCHEDULING_DISPATCHER_BOARD}
                            isNavLink
                            qa={qa.navbar.btnDispatcherBoard}
                            onClick={this._onBarItemButtonClick}
                        >
                            {t('NavBar.dispatcherBoard')}
                            {this.props.logic.auth().roles(true).includes(Role.DFF_R) && (
                                <img className="dff-badge" src={dbIcons.badge} alt="DFF" />
                            )}
                        </BarItemButton>
                    )}
                    {[Role.PLN_R, Role.PLN_R_2, Role.ROW_R].some(r => roles.includes(r)) && (
                        <BarDropdown
                            link={this._getDefaultDropdownLink(this.pageGroupRoles.pageRolePlanner)}
                            className="navbar-scheduling"
                            label={t('NavBar.scheduling')}
                            aligned="left"
                            activeLinks={[RouteNames.SCHEDULING_PLANNER, RouteNames.SCHEDULING_ORDERS]}
                            qa={qa.navbar.btnScheduling}
                            onClick={this._onBarItemButtonClick}
                        >
                            {[Role.PLN_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-scheduling-planner"
                                    link={RouteNames.SCHEDULING_PLANNER}
                                    qa={qa.navbar.scheduling.btnPlanner}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.planner')}
                                </BarItemButton>
                            )}
                            {this.props.logic.conf.settings.plannerOldAvailable &&
                                [Role.PLN_R_2].some(r => roles.includes(r)) && (
                                    <BarItemButton
                                        className="navbar-scheduling-planner-2"
                                        link={RouteNames.SCHEDULING_PLANNER_NEW}
                                        qa={qa.navbar.scheduling.btnPlanner2}
                                        onClick={this._onBarItemButtonClick}
                                    >
                                        {t('NavBar.planner-2')}
                                    </BarItemButton>
                                )}
                            {[Role.ROW_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-scheduling-route-overview"
                                    link={RouteNames.SCHEDULING_ORDERS}
                                    onClick={this._onBarItemButtonClick}
                                    qa={qa.navbar.scheduling.btnRouteOverview}
                                >
                                    {t('NavBar.routeOverview')}
                                </BarItemButton>
                            )}
                        </BarDropdown>
                    )}
                    {[
                        Role.CP_R,
                        Role.JA_R,
                        Role.FC_R,
                        Role.EX_R,
                        Role.AEI_R,
                        Role.AEI_R,
                        Role.DRE_R,
                        Role.BC_R,
                        Role.MTN_R,
                        Role.DIT_R,
                        Role.CLD_R
                    ].some(r => roles.includes(r)) && (
                        <BarDropdown
                            link={this._getDefaultDropdownLink(this.pageGroupRoles.pageRoleStatistics)}
                            className="navbar-statistics"
                            label={t('NavBar.statistics')}
                            aligned="left"
                            activeLinks={[
                                RouteNames.STATISTICS_COMPANY_PROFILE,
                                RouteNames.STATISTICS_COMPANY_PROFILE_COSTS,
                                RouteNames.STATISTICS_COMPANY_PROFILE_DASHBOARD,
                                RouteNames.STATISTICS_COMPANY_PROFILE_TOTAL_COST_OF_OWNERSHIP,
                                RouteNames.STATISTICS_JOURNEYS_ACTIVITY,
                                RouteNames.STATISTICS_FUEL_CONSUMPTION,
                                RouteNames.STATISTICS_AETR,
                                RouteNames.STATISTICS_EXPENSES,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_DETAIL,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_TRUCKS_LIST,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_DETAIL,
                                RouteNames.STATISTICS_DRIVER_BEHAVIOR_VEHICLES_LIST,
                                RouteNames.STATISTICS_BORDER_CROSSING,
                                RouteNames.STATISTICS_MAINTENANCE,
                                RouteNames.STATISTICS_ALLOWANCES,
                                RouteNames.STATISTICS_ALLOWANCES_LIST,
                                RouteNames.STATISTICS_ALLOWANCES_DRIVER_DETAIL,
                                RouteNames.STATISTICS_ALLOWANCES_DRIVER_DETAIL_PARAM,
                                RouteNames.STATISTICS_STATISTIC_REPORT,
                                RouteNames.STATISTICS_COLD_CHAIN
                            ]}
                            qa={qa.navbar.btnStatistics}
                            onClick={this._onBarItemButtonClick}
                        >
                            {[Role.CP_R, Role.DBHD_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-company-profile"
                                    link={RouteNames.STATISTICS_COMPANY_PROFILE}
                                    qa={qa.navbar.statistics.btnCompanyProfile}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.companyProfile')}
                                </BarItemButton>
                            )}
                            {[Role.JA_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-journeys"
                                    link={RouteNames.STATISTICS_JOURNEYS_ACTIVITY}
                                    qa={qa.navbar.statistics.btnJourneysActivity}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.journeysActivity')}
                                </BarItemButton>
                            )}
                            {[Role.FC_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-fuelConsumptions"
                                    link={RouteNames.STATISTICS_FUEL_CONSUMPTION}
                                    qa={qa.navbar.statistics.btnFuelConsumption}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.fuelConsumptions')}
                                </BarItemButton>
                            )}
                            {[Role.AEI_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-aetr"
                                    link={RouteNames.STATISTICS_AETR}
                                    qa={qa.navbar.statistics.btnAetr}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.AETR')}
                                </BarItemButton>
                            )}
                            {[Role.EX_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-expenses"
                                    link={RouteNames.STATISTICS_EXPENSES}
                                    qa={qa.navbar.statistics.btnExpenses}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.expenses')}
                                </BarItemButton>
                            )}
                            {[Role.DBH_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-driver-behavior"
                                    link={RouteNames.STATISTICS_DRIVER_BEHAVIOR}
                                    qa={qa.navbar.statistics.btnDriverBehavior}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.driverBehavior')}
                                </BarItemButton>
                            )}
                            {[Role.BC_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-border-crossing"
                                    link={RouteNames.STATISTICS_BORDER_CROSSING}
                                    qa={qa.navbar.statistics.btnBorderCrossing}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.borderCrossing')}
                                </BarItemButton>
                            )}
                            {[Role.MTN_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-maintenance"
                                    link={RouteNames.STATISTICS_MAINTENANCE}
                                    qa={qa.navbar.statistics.btnMaintenance}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.maintanance')}
                                </BarItemButton>
                            )}
                            {[Role.DIT_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-allowances"
                                    link={RouteNames.STATISTICS_ALLOWANCES}
                                    qa={qa.navbar.statistics.btnAllowances}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.allowances')}
                                </BarItemButton>
                            )}
                            {[Role.FC_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-statistic-report"
                                    link={RouteNames.STATISTICS_STATISTIC_REPORT}
                                    qa={qa.navbar.statistics.btnStatisticReport}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.statisticReport')}
                                </BarItemButton>
                            )}
                            {[Role.CLD_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-statistics-cold-chain"
                                    link={RouteNames.STATISTICS_COLD_CHAIN}
                                    qa={qa.navbar.statistics.btnColdchain}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.coldchain')}
                                </BarItemButton>
                            )}
                        </BarDropdown>
                    )}
                    <div className="navbar-distancer t-bar-item" />
                    {[
                        Role.V_R,
                        Role.USR_R,
                        Role.EX_R,
                        Role.TC_R,
                        Role.CRD_R,
                        Role.TRD_R,
                        Role.POI_R,
                        Role.CA_R,
                        Role.CAC_R,
                        Role.OAC_R,
                        Role.PUESC,
                        Role.CL_R
                    ].some(r => roles.includes(r)) && (
                        <BarDropdown
                            link={this._getDefaultDropdownLink(this.pageGroupRoles.pageRoleSettings)}
                            className="navbar-settings"
                            label={t('common.settings')}
                            aligned="left"
                            activeLinks={[
                                RouteNames.SETTINGS_FLEET,
                                RouteNames.SETTINGS_ROLES,
                                RouteNames.SETTINGS_USERS,
                                RouteNames.SETTINGS_FUEL_CARDS,
                                RouteNames.SETTINGS_TACHO_CARDS,
                                RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY,
                                RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY,
                                RouteNames.SETTINGS_CUSTOM_PLACES,
                                RouteNames.SETTINGS_SYSTEM_CONNECTIONS,
                                RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_API,
                                RouteNames.SETTINGS_SYSTEM_CONNECTIONS_CUSTOMER_ACCESS,
                                RouteNames.SETTINGS_SYSTEM_CONNECTIONS_OTHER_SYSTEMS,
                                RouteNames.SETTINGS_CONTACT_LIST
                            ]}
                            qa={qa.navbar.btnSettings}
                            onClick={this._onBarItemButtonClick}
                        >
                            {[Role.V_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-vehicles"
                                    link={RouteNames.SETTINGS_FLEET}
                                    qa={qa.navbar.settings.btnFleet}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('common.fleet')}
                                </BarItemButton>
                            )}
                            {[Role.USR_W].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-roles"
                                    link={RouteNames.SETTINGS_ROLES}
                                    qa={qa.navbar.settings.btnRoles}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.roles')}
                                </BarItemButton>
                            )}
                            {[Role.USR_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-users"
                                    link={RouteNames.SETTINGS_USERS}
                                    qa={qa.navbar.settings.btnUsers}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.users')}
                                </BarItemButton>
                            )}
                            {[Role.EX_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-fuel-cards"
                                    link={RouteNames.SETTINGS_FUEL_CARDS}
                                    qa={qa.navbar.settings.btnFuelCards}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('common.fuelCards')}
                                </BarItemButton>
                            )}
                            {[Role.TC_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-tacho-cards"
                                    link={RouteNames.SETTINGS_TACHO_CARDS}
                                    qa={qa.navbar.settings.btnTachographCards}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('common.tachoCards')}
                                </BarItemButton>
                            )}
                            {[Role.CRD_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-driverCardsRemoteMemory"
                                    link={RouteNames.SETTINGS_DRIVER_CARDS_REMOTE_MEMORY}
                                    qa={qa.navbar.settings.btnDriverCardsRemoteMemory}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.driverCardsRemoteMemory')}
                                </BarItemButton>
                            )}
                            {[Role.TRD_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-vehiclesRemoteMemory"
                                    link={RouteNames.SETTINGS_VEHICLES_REMOTE_MEMORY}
                                    qa={qa.navbar.settings.btnVehiclesRemoteMemory}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.vehiclesRemoteMemory')}
                                </BarItemButton>
                            )}
                            {[Role.POI_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-poi"
                                    link={RouteNames.SETTINGS_CUSTOM_PLACES}
                                    qa={qa.navbar.settings.btnCustomPlaces}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.poi')}
                                </BarItemButton>
                            )}
                            {[Role.CA_R, Role.CAC_R, Role.OAC_R, Role.PUESC].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-system-connections"
                                    link={RouteNames.SETTINGS_SYSTEM_CONNECTIONS}
                                    qa={qa.navbar.settings.btnSystemConnections}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.systemConnections')}
                                </BarItemButton>
                            )}
                            {[Role.CL_R].some(r => roles.includes(r)) && (
                                <BarItemButton
                                    className="navbar-settings-contacts"
                                    link={RouteNames.SETTINGS_CONTACT_LIST}
                                    qa={qa.navbar.settings.btnContacts}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('NavBar.contacts')}
                                </BarItemButton>
                            )}
                        </BarDropdown>
                    )}
                    {[Role.PM_R].some(r => roles.includes(r)) ? (
                        <BarDropdown
                            className="navbar-partner-module"
                            label={t('common.partnerModule')}
                            link={RouteNames.PARTNER_PARTNERS}
                            aligned="left"
                            activeLinks={[
                                RouteNames.PARTNER_PARTNERS,
                                RouteNames.PARTNER_COMPANIES,
                                RouteNames.PARTNER_WHITELABEL,
                                RouteNames.PARTNER_VEHICLES,
                                RouteNames.PARTNER_OBU,
                                RouteNames.PARTNER_CCR,
                                RouteNames.PARTNER_INVOICE,
                                RouteNames.PARTNER_USERS
                            ]}
                            qa={qa.navbar.btnPartner}
                            onClick={this._onBarItemButtonClick}
                        >
                            {/* TODO create own file with parnterModules like settings*/}
                            {superadmin && (
                                <BarItemButton
                                    className="navbar-partner-partners"
                                    link={RouteNames.PARTNER_PARTNERS}
                                    qa={qa.navbar.partner.btnPartners}
                                    onClick={this._onBarItemButtonClick}
                                >
                                    {t('common.partners')}
                                </BarItemButton>
                            )}
                            <BarItemButton
                                className="navbar-partner-companies"
                                link={RouteNames.PARTNER_COMPANIES}
                                qa={qa.navbar.partner.btnCompanies}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.companies')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-whitelabel"
                                link={RouteNames.PARTNER_WHITELABEL}
                                qa={qa.navbar.partner.btnWhitelabel}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.whitelabel')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-vehicles"
                                link={RouteNames.PARTNER_VEHICLES}
                                qa={qa.navbar.partner.btnVehicles}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.vehicles')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-obu"
                                link={RouteNames.PARTNER_OBU}
                                qa={qa.navbar.partner.btnObu}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.obu')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-ccr"
                                link={RouteNames.PARTNER_CCR}
                                qa={qa.navbar.partner.btnCcr}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.companyCardReader')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-invoice t-hide"
                                link={RouteNames.PARTNER_INVOICE}
                                qa={qa.navbar.partner.btnInvoices}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.invoices')}
                            </BarItemButton>
                            <BarItemButton
                                className="navbar-partner-users"
                                link={RouteNames.PARTNER_USERS}
                                qa={qa.navbar.partner.btnUsers}
                                onClick={this._onBarItemButtonClick}
                            >
                                {t('common.users')}
                            </BarItemButton>
                        </BarDropdown>
                    ) : (
                        ''
                    )}
                    <BarItem className="navbar-time" aligned="right">
                        <Clock interval={30e3} format={'LT'} formatLong={'LLL'} />
                    </BarItem>
                    <BarItemIcon
                        qa={qa.navbar.userSettingsButton}
                        title={user.name}
                        icon="user-cog"
                        aligned="right"
                        onClick={this.props.onSettingsToggle}
                        className="navbar-user-settings"
                    >
                        <span className="navbar-user-settings-name">
                            {this.props.logic.demo().isActive ? (
                                <span className="navbar-user-settings-name-demo">
                                    {t('settings.items.demoMode.title')}
                                </span>
                            ) : user.name.slice(25, user.name.length).length > 0 ? (
                                `${user.name.slice(0, 25)}...`
                            ) : (
                                user.name
                            )}
                        </span>
                    </BarItemIcon>
                    {[Role.IA_R].some(r => roles.includes(r)) && (
                        <BarIconButton
                            title={t('NavBar.alarms')}
                            aligned="right"
                            icon="exclamation-triangle"
                            active={this.props.alarmsActive}
                            count={this.props.newAlarmsLength}
                            className="navbar-alarms"
                            onClick={this.props.onAlarmsClick}
                        />
                    )}
                    {[Role.MTN_N].some(r => roles.includes(r)) && (
                        <BarIconButton
                            title={t('NavBar.maintenanceNotifications')}
                            aligned="right"
                            icon="bell"
                            active={this.props.maintenanceNotificationsActive}
                            count={this.props.newMaintenanceNotificationsLength}
                            className="navbar-maintenance-notifications"
                            onClick={this.props.onMaintenanceNotificationsClick}
                        />
                    )}
                    {[Role.LMM_R, Role.LMT_R].some(r => roles.includes(r)) && this.props.logic.conf.messaging.uri && (
                        <BarIconButton
                            qa={qa.navbar.messagingButton}
                            qaCount={qa.navbar.messagingCount}
                            title={t('NavBar.messaging')}
                            aligned="right"
                            icon="envelope"
                            active={this.props.messagesActive}
                            count={this.props.newMessagesLength}
                            className="navbar-messaging"
                            onClick={this._onMessagingClick}
                        />
                    )}
                    <BarIconButton
                        qa={qa.navbar.docsButton}
                        title={t('common.helper')}
                        aligned="right"
                        icon="question-circle"
                        active={this.props.docsActive}
                        className="navbar-docs"
                        onClick={this._onDocsClick}
                    />
                </Bar>
                {this.props.settingsVisible && (
                    <Settings
                        addressIdentification={this.props.addressIdentification!}
                        vehicleIdentification={this.props.vehicleIdentification!}
                        theme={this.props.theme!}
                        lang={this.props.lang}
                        demoMode={this.props.demoMode}
                        demoVisible={realUserRoles.includes(Role.DM_R) && !realUserRoles.includes(Role.DM_RD)}
                        languages={this.props.logic.conf.langs}
                        companyName={this.props.companyName}
                        onAddressIdentificationChange={this.props.onAddressIdentificationChange}
                        onVehicleIdentificationChange={this.props.onVehicleIdentificationChange}
                        onThemeChange={this.props.onThemeChange}
                        onCancel={this.props.onSettingsToggle}
                        onLogout={this.props.onLogout}
                        onLanguageChange={this.props.onLangChange}
                        onDemoModeToggle={this.props.onDemoModeToggle}
                        onCompanyNameCopy={this.props.onCompanyNameCopy}
                    />
                )}
            </>
        );
    }

    private _onBarItemButtonClick = (event: React.MouseEvent<HTMLElement>, routeName?: RouteNames): void => {
        if (this.props.docsActive) {
            this.props.onDocsClick?.();
        }
        if (routeName === RouteNames.TRACKING) {
            this.props.logic.tracking().setSettings(this.props.logic.conf.settings.tracking);
        }

        event.stopPropagation();
    };

    private _onMessagingClick = () => {
        this.props.onMessaging?.();
    };

    private _getDefaultDropdownLink = (pageRole: { [key: string]: Role[] }) => {
        const roles = this.props.logic.auth().roles();
        let resultPage: string = Object.keys(pageRole)[0];
        Object.keys(pageRole).some(key => {
            if (pageRole[key].some(r => roles.includes(r))) {
                resultPage = key;
                return true;
            }
            return false;
        });

        return resultPage;
    };
    private _onDocsClick = () => {
        this.props.onDocsClick?.();
    };
}

export default withRouter(withTranslation()(NavBar));
