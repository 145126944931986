/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserTokenPatch
 */
export interface UserTokenPatch {
    /**
     * 
     * @type {string}
     * @memberof UserTokenPatch
     */
    token: string;
}

export function UserTokenPatchFromJSON(json: any): UserTokenPatch {
    return UserTokenPatchFromJSONTyped(json, false);
}

export function UserTokenPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTokenPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function UserTokenPatchToJSON(value?: UserTokenPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}


