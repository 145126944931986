/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LatLng,
    LatLngFromJSON,
    LatLngFromJSONTyped,
    LatLngToJSON,
    VehicleProperties,
    VehiclePropertiesFromJSON,
    VehiclePropertiesFromJSONTyped,
    VehiclePropertiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    externalCompanyId?: string;
    /**
     * 
     * @type {LatLng}
     * @memberof Vehicle
     */
    location: LatLng;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    elevation?: number;
    /**
     * 
     * @type {VehicleProperties}
     * @memberof Vehicle
     */
    properties?: VehicleProperties;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    integratorId?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    companyId?: string;
}

export function VehicleFromJSON(json: any): Vehicle {
    return VehicleFromJSONTyped(json, false);
}

export function VehicleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vehicle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': json['externalId'],
        'externalCompanyId': !exists(json, 'externalCompanyId') ? undefined : json['externalCompanyId'],
        'location': LatLngFromJSON(json['location']),
        'elevation': !exists(json, 'elevation') ? undefined : json['elevation'],
        'properties': !exists(json, 'properties') ? undefined : VehiclePropertiesFromJSON(json['properties']),
        'integratorId': !exists(json, 'integratorId') ? undefined : json['integratorId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
    };
}

export function VehicleToJSON(value?: Vehicle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'externalCompanyId': value.externalCompanyId,
        'location': LatLngToJSON(value.location),
        'elevation': value.elevation,
        'properties': VehiclePropertiesToJSON(value.properties),
        'integratorId': value.integratorId,
        'companyId': value.companyId,
    };
}


