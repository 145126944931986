/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface HistoryItem
 */
export interface HistoryItem {
    /**
     * 
     * @type {Location}
     * @memberof HistoryItem
     */
    l: Location;
}

export function HistoryItemFromJSON(json: any): HistoryItem {
    return HistoryItemFromJSONTyped(json, false);
}

export function HistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'l': LocationFromJSON(json['L']),
    };
}

export function HistoryItemToJSON(value?: HistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'L': LocationToJSON(value.l),
    };
}


