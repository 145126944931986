/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryForUserBackendInfo,
    CountryForUserBackendInfoFromJSON,
    CountryForUserBackendInfoFromJSONTyped,
    CountryForUserBackendInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientForUserBackendInfo
 */
export interface ClientForUserBackendInfo {
    /**
     * 
     * @type {number}
     * @memberof ClientForUserBackendInfo
     */
    readonly id?: number;
    /**
     * 
     * @type {CountryForUserBackendInfo}
     * @memberof ClientForUserBackendInfo
     */
    country?: CountryForUserBackendInfo;
    /**
     * 
     * @type {string}
     * @memberof ClientForUserBackendInfo
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientForUserBackendInfo
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof ClientForUserBackendInfo
     */
    costPerKm?: object | null;
}

export function ClientForUserBackendInfoFromJSON(json: any): ClientForUserBackendInfo {
    return ClientForUserBackendInfoFromJSONTyped(json, false);
}

export function ClientForUserBackendInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientForUserBackendInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'country': !exists(json, 'country') ? undefined : CountryForUserBackendInfoFromJSON(json['country']),
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'name': json['name'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
    };
}

export function ClientForUserBackendInfoToJSON(value?: ClientForUserBackendInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': CountryForUserBackendInfoToJSON(value.country),
        'language_iso_639_1': value.languageIso6391,
        'name': value.name,
        'cost_per_km': value.costPerKm,
    };
}


