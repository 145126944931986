/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20048,
    InlineResponse20048FromJSON,
    InlineResponse20048ToJSON,
    ReadOnlyVehicleProfileSerializer,
    ReadOnlyVehicleProfileSerializerFromJSON,
    ReadOnlyVehicleProfileSerializerToJSON,
    VehicleProfile,
    VehicleProfileFromJSON,
    VehicleProfileToJSON,
    VehicleProfileCreateDefault,
    VehicleProfileCreateDefaultFromJSON,
    VehicleProfileCreateDefaultToJSON,
    WriteOnlyVehicleProfileSerializer,
    WriteOnlyVehicleProfileSerializerFromJSON,
    WriteOnlyVehicleProfileSerializerToJSON,
} from '../models';

export interface VehicleProfileCreateRequest {
    data: WriteOnlyVehicleProfileSerializer;
}

export interface VehicleProfileCreateDefaultRequest {
    data: VehicleProfileCreateDefault;
}

export interface VehicleProfileDeleteRequest {
    id: number;
}

export interface VehicleProfileListRequest {
    limit?: number;
    offset?: number;
}

export interface VehicleProfilePartialUpdateRequest {
    id: number;
    data: WriteOnlyVehicleProfileSerializer;
}

export interface VehicleProfileReadRequest {
    id: number;
}

export interface VehicleProfileUpdateRequest {
    id: number;
    data: WriteOnlyVehicleProfileSerializer;
}

/**
 * no description
 */
export class VehicleProfileApi extends runtime.BaseAPI {

    /**
     */
    async vehicleProfileCreateRaw(requestParameters: VehicleProfileCreateRequest): Promise<runtime.ApiResponse<ReadOnlyVehicleProfileSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling vehicleProfileCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyVehicleProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyVehicleProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfileCreate(requestParameters: VehicleProfileCreateRequest): Promise<ReadOnlyVehicleProfileSerializer> {
        const response = await this.vehicleProfileCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleProfileCreateDefaultRaw(requestParameters: VehicleProfileCreateDefaultRequest): Promise<runtime.ApiResponse<VehicleProfile>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling vehicleProfileCreateDefault.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/default/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleProfileCreateDefaultToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleProfileFromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfileCreateDefault(requestParameters: VehicleProfileCreateDefaultRequest): Promise<VehicleProfile> {
        const response = await this.vehicleProfileCreateDefaultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleProfileDeleteRaw(requestParameters: VehicleProfileDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleProfileDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async vehicleProfileDelete(requestParameters: VehicleProfileDeleteRequest): Promise<void> {
        await this.vehicleProfileDeleteRaw(requestParameters);
    }

    /**
     */
    async vehicleProfileListRaw(requestParameters: VehicleProfileListRequest): Promise<runtime.ApiResponse<InlineResponse20048>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20048FromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfileList(requestParameters: VehicleProfileListRequest): Promise<InlineResponse20048> {
        const response = await this.vehicleProfileListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleProfilePartialUpdateRaw(requestParameters: VehicleProfilePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyVehicleProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleProfilePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling vehicleProfilePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyVehicleProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyVehicleProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfilePartialUpdate(requestParameters: VehicleProfilePartialUpdateRequest): Promise<ReadOnlyVehicleProfileSerializer> {
        const response = await this.vehicleProfilePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleProfileReadRaw(requestParameters: VehicleProfileReadRequest): Promise<runtime.ApiResponse<ReadOnlyVehicleProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleProfileRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyVehicleProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfileRead(requestParameters: VehicleProfileReadRequest): Promise<ReadOnlyVehicleProfileSerializer> {
        const response = await this.vehicleProfileReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleProfileUpdateRaw(requestParameters: VehicleProfileUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyVehicleProfileSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleProfileUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling vehicleProfileUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/vehicle-profile/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyVehicleProfileSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyVehicleProfileSerializerFromJSON(jsonValue));
    }

    /**
     */
    async vehicleProfileUpdate(requestParameters: VehicleProfileUpdateRequest): Promise<ReadOnlyVehicleProfileSerializer> {
        const response = await this.vehicleProfileUpdateRaw(requestParameters);
        return await response.value();
    }

}
