import React from 'react';
import cn from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import qa from 'qa-selectors';
import { Modal, DatePicker, Button, Switch } from 'common/components';
import { useLogic } from 'App';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { Col, Row, message } from 'antd';
import { getBlobFromByteArray, getByteArrayFromFile } from 'common/utils/fileUtils';
import { Role } from 'logic/auth';
import { DateRange } from 'common/model/date-time';
import { DATE_FORMAT, DWL_LOCK } from 'domain-constants';
import moment, { Moment } from 'moment';
import TableBar from 'common/components/TableBar';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';
import { ExpensesFilterModel } from '../../ExpensesModule';
import { ExpenseSource, ExpenseState, ExpenseType } from 'generated/graphql';
import ExpensesFilter from '../ExpensesFilter';
import { confDefault } from 'conf';
import { arrayEquals } from 'common/utils/textUtils';
import * as icons from 'resources/images/common';

interface OwnProps {
    cargo?: boolean;
    roles: Role[];
    dateOpen?: boolean;
    dateChanged?: boolean;
    dateRange: DateRange;
    filterOpen?: boolean;
    backUrl?: boolean;
    lang: string;
    type?: ExpenseType;
    filter: ExpensesFilterModel;
    export: {
        enabled: boolean;
        loading: boolean;
    };
    fuelOnly?: boolean;
    demoMode?: boolean;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onFuelOnlyChange?: (checked: boolean) => void;
    onCargoClick?: () => void;
    onFilterClick?: () => void;
    onBackUrlClick?: () => void;
    onResetClick?: () => void;
    onImportSuccess?: () => void;
    onHelperClick?: () => void;
    onFilterConfirm?: (
        vehiclesChecked: string[],
        type?: ExpenseType,
        payment?: ExpenseState,
        source?: ExpenseSource
    ) => void;
    onFilterCancel?: () => void;
    onExportClick?: () => void;
}

type State = { modalIsOpen: boolean; loading?: boolean; file?: UploadFile };
type Props = OwnProps & WithTranslation;

function ExpensesBar(props: Props) {
    const defaults = confDefault.settings.statistics.expenses.filter;
    const [state, setState] = React.useState<State>({
        modalIsOpen: false
    });
    const logic = useLogic();

    function onImportTemplateClick() {
        setState(state => ({
            ...state,
            modalIsOpen: true
        }));
    }

    function onCancel() {
        setState({
            modalIsOpen: false,
            file: undefined
        });
    }

    function onSubmit() {
        if (state.file?.originFileObj) {
            setState(state => ({
                ...state,
                loading: true
            }));
            getByteArrayFromFile(state.file.originFileObj)
                .then(fileByteArray => {
                    logic
                        .statisticsExpenses()
                        .importExpense(fileByteArray)
                        .then(res => {
                            if (res?.data) {
                                const blob = getBlobFromByteArray(res.data);
                                let link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = `${state.file?.name}`;
                                document.body.appendChild(link);
                                link.dispatchEvent(
                                    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
                                );
                                link.remove();
                                window.URL.revokeObjectURL(link.href);

                                message.error(props.t('ExpensesBar.message.importError'));
                            } else if (res?.status === 'false') {
                                message.error(props.t('ExpensesBar.message.importInternalError'));
                            } else {
                                props.onImportSuccess?.();
                                message.success(props.t('ExpensesBar.message.importSuccess'));
                            }

                            setState(state => ({
                                ...state,
                                modalIsOpen: false,
                                loading: false,
                                file: undefined
                            }));
                        })
                        .catch(err => {
                            console.error(err);
                            message.error(props.t('ExpensesBar.message.importInternalError'));
                            setState(state => ({
                                ...state,
                                modalIsOpen: false,
                                loading: false,
                                file: undefined
                            }));
                        });
                })
                .catch(err => {
                    console.error(err);
                    message.error(props.t('ExpensesBar.message.importInternalError'));
                    setState(state => ({
                        ...state,
                        modalIsOpen: false,
                        loading: false,
                        file: undefined
                    }));
                });
        }
    }

    function onRemoveUpload() {
        setState(state => ({
            ...state,
            file: undefined
        }));
    }

    function onBeforeUpload(file: RcFile) {
        setState(state => ({
            ...state,
            file: {
                uid: file.uid,
                name: file.name,
                originFileObj: file,
                size: file.size,
                type: file.type
            }
        }));

        return false;
    }

    function _onDateRangeChange(values: [Moment | null, Moment | null] | null): void {
        if (values) {
            const [start, end] = values;
            props.onDateRangeChange?.({
                start: start ? start.format(DATE_FORMAT) : props.dateRange.start,
                end: end ? end.format(DATE_FORMAT) : props.dateRange.end
            });
        }
    }

    return (
        <>
            <TableBar
                heading={props.t('ExpensesBar.title')}
                className="expenses-bar"
                filter={{
                    activeCount:
                        (arrayEquals(props.filter.vehiclesChecked, defaults.vehicles) ||
                        props.filter.vehiclesChecked.length === props.filter.vehiclesOpts.length
                            ? 0
                            : 1) +
                        (props.filter.type !== defaults.type ? 1 : 0) +
                        (props.filter.payment !== defaults.payment ? 1 : 0) +
                        (props.filter.source !== defaults.source ? 1 : 0),
                    onClick: props.onFilterClick,
                    qa: qa.expenses.btnFilter,
                    resetButton: {
                        onClick: props.onResetClick
                    }
                }}
                datePicker={
                    <DatePicker.DateTimeRangePicker
                        defaultValue={[
                            moment(props.dateRange.start, DATE_FORMAT),
                            moment(props.dateRange.end, DATE_FORMAT)
                        ]}
                        minDate={
                            props.filter.fullDWL
                                ? moment()
                                      .subtract(DWL_LOCK - 1, 'days')
                                      .endOf('day')
                                      .toDate()
                                : undefined
                        }
                        onChange={_onDateRangeChange}
                        trigger={
                            <ButtonDateRangeTimeTrigger
                                dateRange={props.dateRange}
                                changed={props.dateChanged}
                                qa={qa.common.dateTimeRangePicker.btn}
                            />
                        }
                    />
                }
                backButton={
                    props.backUrl && props.roles.includes(Role.JA_R)
                        ? {
                              onClick: props.onBackUrlClick,
                              qa: qa.expenses.btnBack,
                              title: props.t('ExpensesBar.backToJourneys')
                          }
                        : undefined
                }
                actions={[
                    props.roles.includes(Role.EX_IE) && (
                        <Button
                            type="primary"
                            size="large"
                            disabled={!props.export.enabled}
                            loading={props.export.loading}
                            qa={qa.expenses.btnExport}
                            icon={<img src={icons.export} alt={props.t('common.export')} />}
                            onClick={props.onExportClick}
                        >
                            {props.t('common.export')}
                        </Button>
                    ),
                    props.roles.includes(Role.EX_IE) && (
                        <Button
                            type="dashed"
                            title={props.t('ExpensesBar.importIconTitle')}
                            qa={qa.expenses.btnFileImport}
                            icon={<img src={icons.importManual} alt={props.t('ExpensesBar.importIconTitle')} />}
                            onClick={onImportTemplateClick}
                        >
                            {props.t('ExpensesBar.importIconTitle')}
                        </Button>
                    ),
                    props.roles.includes(Role.EX_W) && (
                        <Button
                            type="dashed"
                            title={props.t('ExpensesCargo.createCargo')}
                            className={cn({
                                active: props.cargo
                            })}
                            qa={qa.expenses.btnCargo}
                            onClick={props.onCargoClick}
                            icon={<img src={icons.plusCircleFilled} alt={props.t('ExpensesCargo.createCargo')} />}
                        >
                            {props.t('ExpensesCargo.createCargo')}
                        </Button>
                    )
                ]}
                filters={[
                    <Row align="middle" className="expenses-bar-filter">
                        <Col>
                            <Switch
                                size="small"
                                disabled={props.filterOpen}
                                onChange={props.onFuelOnlyChange}
                                checked={props.fuelOnly}
                                qa={qa.expenses.filter.switchFuelOnly}
                            />
                        </Col>
                        <Col>{props.t('ExpensesBar.fuelOnly')}</Col>
                    </Row>
                ]}
                onHelperClick={props.onHelperClick}
            />
            {props.filterOpen && (
                <ExpensesFilter
                    demoMode={props.demoMode}
                    vehiclesOpts={props.filter.vehiclesOpts}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    type={props.filter.type}
                    payment={props.filter.payment}
                    source={props.filter.source}
                    onConfirm={props.onFilterConfirm}
                    onCancel={props.onFilterCancel}
                />
            )}
            <Modal
                className="import-expense"
                destroyOnClose
                title={props.t('ExpensesBar.importIconTitle')}
                closable={false}
                okButtonProps={{
                    disabled: !state.file
                }}
                confirmLoading={state.loading}
                onOk={onSubmit}
                okText={props.t('common.confirm')}
                cancelText={props.t('common.cancel')}
                onCancel={onCancel}
                visible={state?.modalIsOpen}
            >
                <>
                    <Dragger
                        fileList={state.file ? [state.file] : []}
                        multiple={false}
                        accept=".xls,.xlsx"
                        onRemove={onRemoveUpload}
                        beforeUpload={onBeforeUpload}
                    >
                        {props.t('ExpensesBar.dropContent')}
                    </Dragger>

                    <div
                        className="import-expense-download-link"
                        onClick={() => {
                            logic.statisticsExpenses().downloadExpensesTemplate(logic.statisticsExpenses());
                        }}
                    >
                        {props.t('ExpensesBar.exportBtn')}
                    </div>
                </>
            </Modal>
        </>
    );
}

export default withTranslation()(ExpensesBar);
