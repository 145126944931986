/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20013,
    InlineResponse20013FromJSON,
    InlineResponse20013ToJSON,
    ReadOnlyExternalSystemSecret,
    ReadOnlyExternalSystemSecretFromJSON,
    ReadOnlyExternalSystemSecretToJSON,
    WriteOnlyExternalSystemSecret,
    WriteOnlyExternalSystemSecretFromJSON,
    WriteOnlyExternalSystemSecretToJSON,
} from '../models';

export interface ExternalSystemSecretCreateRequest {
    data: WriteOnlyExternalSystemSecret;
}

export interface ExternalSystemSecretDeleteRequest {
    id: number;
}

export interface ExternalSystemSecretListRequest {
    externalSystemAccessExternalSystemName?: string;
    deleted?: number;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface ExternalSystemSecretPartialUpdateRequest {
    id: number;
    data: WriteOnlyExternalSystemSecret;
}

export interface ExternalSystemSecretReadRequest {
    id: number;
}

export interface ExternalSystemSecretUpdateRequest {
    id: number;
    data: WriteOnlyExternalSystemSecret;
}

/**
 * no description
 */
export class ExternalSystemSecretApi extends runtime.BaseAPI {

    /**
     */
    async externalSystemSecretCreateRaw(requestParameters: ExternalSystemSecretCreateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemSecret>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemSecretCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemSecretToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemSecretFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretCreate(requestParameters: ExternalSystemSecretCreateRequest): Promise<ReadOnlyExternalSystemSecret> {
        const response = await this.externalSystemSecretCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemSecretDeleteRaw(requestParameters: ExternalSystemSecretDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemSecret>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemSecretDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemSecretFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretDelete(requestParameters: ExternalSystemSecretDeleteRequest): Promise<ReadOnlyExternalSystemSecret> {
        const response = await this.externalSystemSecretDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemSecretListRaw(requestParameters: ExternalSystemSecretListRequest): Promise<runtime.ApiResponse<InlineResponse20013>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.externalSystemAccessExternalSystemName !== undefined) {
            queryParameters['external_system_access__external_system_name'] = requestParameters.externalSystemAccessExternalSystemName;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20013FromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretList(requestParameters: ExternalSystemSecretListRequest): Promise<InlineResponse20013> {
        const response = await this.externalSystemSecretListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemSecretPartialUpdateRaw(requestParameters: ExternalSystemSecretPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemSecret>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemSecretPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemSecretPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemSecretToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemSecretFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretPartialUpdate(requestParameters: ExternalSystemSecretPartialUpdateRequest): Promise<ReadOnlyExternalSystemSecret> {
        const response = await this.externalSystemSecretPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemSecretReadRaw(requestParameters: ExternalSystemSecretReadRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemSecret>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemSecretRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemSecretFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretRead(requestParameters: ExternalSystemSecretReadRequest): Promise<ReadOnlyExternalSystemSecret> {
        const response = await this.externalSystemSecretReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemSecretUpdateRaw(requestParameters: ExternalSystemSecretUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystemSecret>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemSecretUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemSecretUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system-secret/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemSecretToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemSecretFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemSecretUpdate(requestParameters: ExternalSystemSecretUpdateRequest): Promise<ReadOnlyExternalSystemSecret> {
        const response = await this.externalSystemSecretUpdateRaw(requestParameters);
        return await response.value();
    }

}
