import React, { Component } from 'react';
import AlarmBlock, { AlarmUniqueData } from '../AlarmBlock';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alarm } from 'common/model/alarm';

interface Props extends WithTranslation {
    open: boolean;
    alarms: Alarm[];
    onMarkAsSeen: (id: string) => void;
    demoMode?: boolean;
}

interface State {}

class Alarms extends Component<Props, State> {
    render() {
        const { open, alarms, t } = this.props;
        return open ? (
            <div className="alarms">
                {(alarms || []).map((alarm, index) => (
                    <AlarmBlock
                        key={index}
                        id={alarm.alarmId}
                        type={alarm.alarmType}
                        uniqueData={alarm?.uniqueData as AlarmUniqueData}
                        acknowledged={alarm.acknowledged || false}
                        vehicle={alarm.registrationNumber}
                        startDateTime={alarm.startDateTime}
                        onMarkAsSeen={this._onMarkAsSeen}
                        address={alarm.localizedAddress}
                        demoMode={this.props.demoMode}
                    />
                ))}
                {alarms.length === 0 && <div className="alarms-empty">{t('Alarm.empty')}</div>}
            </div>
        ) : null;
    }

    private _onMarkAsSeen = (id: string) => {
        this.props.onMarkAsSeen?.(id);
    };
}

export default withTranslation()(Alarms);
