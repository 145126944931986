/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AETRDailyData,
    AETRDailyDataFromJSON,
    AETRDailyDataFromJSONTyped,
    AETRDailyDataToJSON,
    AETRInterval,
    AETRIntervalFromJSON,
    AETRIntervalFromJSONTyped,
    AETRIntervalToJSON,
    AETRWeeklyData,
    AETRWeeklyDataFromJSON,
    AETRWeeklyDataFromJSONTyped,
    AETRWeeklyDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface AETRReport
 */
export interface AETRReport {
    /**
     * 
     * @type {string}
     * @memberof AETRReport
     */
    tachographCard: string;
    /**
     * 
     * @type {Date}
     * @memberof AETRReport
     */
    timestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof AETRReport
     */
    userId?: number;
    /**
     * 
     * @type {AETRInterval}
     * @memberof AETRReport
     */
    lastActivity: AETRInterval;
    /**
     * 
     * @type {Array<AETRWeeklyData>}
     * @memberof AETRReport
     */
    weeklyData?: Array<AETRWeeklyData>;
    /**
     * 
     * @type {AETRDailyData}
     * @memberof AETRReport
     */
    currentDay?: AETRDailyData;
}

export function AETRReportFromJSON(json: any): AETRReport {
    return AETRReportFromJSONTyped(json, false);
}

export function AETRReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): AETRReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tachographCard': json['tachograph_card'],
        'timestamp': (new Date(json['timestamp'])),
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'lastActivity': AETRIntervalFromJSON(json['last_activity']),
        'weeklyData': !exists(json, 'weekly_data') ? undefined : ((json['weekly_data'] as Array<any>).map(AETRWeeklyDataFromJSON)),
        'currentDay': !exists(json, 'current_day') ? undefined : AETRDailyDataFromJSON(json['current_day']),
    };
}

export function AETRReportToJSON(value?: AETRReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tachograph_card': value.tachographCard,
        'timestamp': (value.timestamp.toISOString()),
        'user_id': value.userId,
        'last_activity': AETRIntervalToJSON(value.lastActivity),
        'weekly_data': value.weeklyData === undefined ? undefined : ((value.weeklyData as Array<any>).map(AETRWeeklyDataToJSON)),
        'current_day': AETRDailyDataToJSON(value.currentDay),
    };
}


