import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import { DatePicker } from 'common/components';
import { DATE_FORMAT } from 'domain-constants';
import moment, { Moment } from 'moment';
import * as CommonIcons from 'resources/images/common';
import CetWarning from '../AllowanceCETWarning/AllowanceCETWarning';
import ImportExport from '../AllowanceImportRules/ImportExport';
import qa from 'qa-selectors';
import TableBar from 'common/components/TableBar';
import ButtonDateRangeTimeTrigger from 'common/components/ButtonDateRangeTimeTrigger';

interface Props {
    title?: string;
    isSuperAdmin?: boolean;
    dateRange: {
        start: string;
        end: string;
    };
    exportLoading?: boolean;
    showBackButton?: boolean;
    demoMode?: boolean;
    loading?: boolean;
    onSupplySwitchChange?: (checked: boolean) => void;
    onCalendarChange?: (values: [Moment | null, Moment | null] | null) => void;
    onExportClick?: () => void;
    onBulkEdit?: () => void;
    onBackButtonCLick?: () => void;
    onHelperClick?: () => void;
}

export default function AllowanceBar(props: Props) {
    const { t } = useTranslation();

    const onExportClick = (): void => {
        props.onExportClick?.();
    };

    return (
        <TableBar
            heading={props.title ?? ''}
            backButton={{
                onClick: props.onBackButtonCLick,
                qa: qa.allowances.bar.btnBack
            }}
            filters={[
                <DatePicker.DateTimeRangePicker
                    size="large"
                    defaultValue={[
                        moment.utc(props.dateRange.start, DATE_FORMAT),
                        moment.utc(props.dateRange.end, DATE_FORMAT)
                    ]}
                    loading={props.demoMode || props.loading}
                    onChange={props.onCalendarChange}
                    format={'L'}
                    disabledDate={d => !d || d.isAfter(moment())}
                    disabled={props.demoMode}
                    trigger={
                        <ButtonDateRangeTimeTrigger
                            dateRange={props.dateRange}
                            qa={qa.common.dateTimeRangePicker.btn}
                        />
                    }
                />,
                <CetWarning />
            ]}
            actions={[
                <Button
                    size="large"
                    type="primary"
                    disabled={props.loading}
                    onClick={props.onBulkEdit}
                    qa={qa.allowances.bar.btnEdit}
                >
                    {t('Allowance.bar.bulkEdit')}
                </Button>,
                props.isSuperAdmin && <ImportExport demoMode={props.demoMode} />,
                <Button
                    size="large"
                    type="primary"
                    loading={props.exportLoading}
                    disabled={props.loading}
                    onClick={onExportClick}
                    qa={qa.allowances.bar.btnExport}
                    icon={<img alt="edit" src={CommonIcons.export} />}
                >
                    {t('Allowance.bar.export')}
                </Button>
            ]}
            onHelperClick={props.onHelperClick}
        />
    );
}
