import moment from 'moment';
import { Subject } from 'rxjs';
import {
    AlarmInDatabaseWithGPSInfo,
    AlarmType,
    AlarmInDatabaseWithGPSInfoFromJSON,
    GetAlarmsV1AlarmsMyGetRequest
} from 'generated/backend-api';
import { Logic } from './logic';
import { Alarm } from 'common/model/alarm';
import { toAddress } from '../common/utils/address';
import { AddressIdentification } from 'common/components/Settings';
import { VehicleStateObject } from 'generated/graphql';
import { NotificationMessage } from './notification-eio';
import { alarms } from 'common/demo/alarms';

export class AlarmsLogic {
    readonly alarmsUpdates: Subject<AlarmInDatabaseWithGPSInfo[]>;

    private _logic: Logic;
    alarms: Alarm[];

    constructor(logic: Logic) {
        this._logic = logic;
        this.alarms = [];
        this.alarmsUpdates = new Subject<AlarmInDatabaseWithGPSInfo[]>();
    }

    /**
     * Inits alarms, watch for updates and resubscribe on notification api reconnect
     */
    init() {
        if (this._logic.demo().isActive) {
            this.alarms = alarms;
        } else {
            this._getAndSubscribeToAlarms();

            this._logic
                .notification()
                .onConnect()
                .subscribe(() => {
                    this._getAndSubscribeToAlarms();
                });

            this._watchAlarmUpdates();
        }
    }

    /**
     * Returns instant alarms if present
     * types: BatteryLow, ConnectionLoss, UnavailableGps
     */
    instantAlarms(): Alarm[] {
        return (this.alarms ?? []).filter(a =>
            [AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(a.alarmType)
        );
    }

    /**
     * Returns all alarms if present
     */
    getAllAlarms(): Alarm[] {
        return this.alarms ?? [];
    }

    /**
     * Returns Instant and geolocation alarms not acknowledged
     */
    getInstantAndGeolocationAlarmsNotAcknowledged(): Alarm[] {
        return (
            (this.alarms ?? []).filter(
                a =>
                    [
                        AlarmType.CorridorLeave,
                        AlarmType.PoiArrival,
                        AlarmType.PoiClose,
                        AlarmType.PoiDeparture,
                        AlarmType.BatteryLow,
                        AlarmType.ConnectionLoss,
                        AlarmType.UnavailableGps,
                        AlarmType.TransportColdChainProfileTemperatureHigh,
                        AlarmType.TransportColdChainProfileTemperatureLow
                    ].includes(a.alarmType) && !a.acknowledged
            ) ?? []
        );
    }

    /**
     * Marks alarms as seen and notifies subscribers on change
     */
    markAlarmsAsSeen(ids: string[]) {
        Promise.all(
            ids.map(id =>
                this._logic.api().backendApi.acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePost({
                    alarmId: id
                })
            )
        ).then(async () => {
            this._getAndSubscribeToAlarms();
        });
    }

    private _areDatesBetween = (
        comparedStart?: Date,
        comparedEnd?: Date,
        comparedToStart?: Date,
        comparedToEnd?: Date
    ) => {
        if (comparedStart && comparedEnd && comparedToStart && comparedToEnd) {
            if (
                (moment(moment(comparedStart)).isSame(moment(moment(comparedToStart)), 'day') &&
                    moment(moment(comparedEnd)).isSame(moment(moment(comparedToEnd)), 'day')) ||
                moment(moment(comparedStart)).isBetween(
                    moment(moment(comparedToStart)),
                    moment(moment(comparedToEnd)),
                    'day'
                )
            ) {
                return true;
            }

            return false;
        }

        return false;
    };

    async getAlarms(filters: GetAlarmsV1AlarmsMyGetRequest): Promise<Alarm[]> {
        try {
            if (this._logic.demo().isActive) {
                return alarms
                    .filter(alarm => this._areDatesBetween(alarm.start, alarm.end, filters.dateFrom, filters.dateTo))
                    .filter(alarm => Number(alarm.monitoredObjectId) === Number(filters.monitoredObjectId));
            } else {
                return (await this._logic.api().backendApi.getAlarmsV1AlarmsMyGet(filters)).map(alarm =>
                    this._toAlarm(alarm)
                );
            }
        } catch (err) {
            console.error('can not get alarms, err: ', err);
            return [];
        }
    }

    /**
     * Watches for alarms updates and merge local date, notifies on change all alarmsUpdates subscribers
     */
    private _watchAlarmUpdates() {
        this._logic.notification().on('alarms', n => {
            if (n.data?.alarms && Array.isArray(n.data.alarms)) {
                const alarmsUpdates = (n.data.alarms as any[]).map(a => AlarmInDatabaseWithGPSInfoFromJSON(a));

                if (alarmsUpdates.length === 0) {
                    return;
                }

                let newAlarms: Alarm[] = this.alarms;

                alarmsUpdates.forEach(update => {
                    const index = newAlarms.findIndex(a => a.alarmId === update.alarmId);

                    if (index !== -1) {
                        newAlarms[index] = this._toAlarm(update);
                    } else {
                        newAlarms = [this._toAlarm(update), ...newAlarms];
                    }
                });

                // MFR 24.2.2021 Filter InstantAlarms only with not end Time and only 24h old
                this.alarms = newAlarms.filter(
                    a =>
                        ![AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(
                            a.alarmType
                        ) ||
                        ([AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(
                            a.alarmType
                        ) &&
                            a.startDateTime &&
                            moment.utc(a.startDateTime).isBetween(moment.utc().subtract(1, 'day'), moment.utc()) &&
                            !a.end)
                );

                // MFR 17.3 2021 Filter InstantAlarms when older as Vehicle GpsDataTime
                this.alarms = this.alarms.filter(alarm => {
                    if (
                        [AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(
                            alarm.alarmType
                        )
                    ) {
                        const vehicle = this._logic
                            .tracking()
                            .getVehiclesData()
                            .find(v => v.id === alarm.monitoredObjectId);
                        const vehicleLastGpsTime = vehicle?.gpsDataTime ? moment.utc(vehicle?.gpsDataTime) : undefined;
                        const alarmStartTime = moment.utc(alarm.startDateTime);

                        return !vehicle || !vehicleLastGpsTime || vehicleLastGpsTime.isBefore(alarmStartTime);
                    } else {
                        return true;
                    }
                });

                this.alarmsUpdates.next(this.alarms);
            }
        });

        this._logic
            .notification()
            .on('actual-vehicle-state', (message: NotificationMessage<{ key: VehicleStateObject }>) => {
                const updates = message.data ? Object.values(message.data) : undefined;
                this.alarms = this.alarms.filter(alarm => {
                    if (
                        [AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(
                            alarm.alarmType
                        )
                    ) {
                        const updatedVehicle: VehicleStateObject | undefined = updates?.find(
                            update => update.monitoredObjectId === alarm.monitoredObjectId
                        );
                        const vehicleLastGpsTime = updatedVehicle?.gpsData?.time
                            ? moment.utc(updatedVehicle?.gpsData?.time)
                            : undefined;
                        const alarmStartTime = moment.utc(alarm.startDateTime);
                        return !updatedVehicle || !vehicleLastGpsTime || vehicleLastGpsTime.isBefore(alarmStartTime);
                    } else {
                        return true;
                    }
                });
                this.alarmsUpdates.next(this.alarms);
            });
    }

    /**
     * Gets current alarms list and subscribes for updates
     */
    private async _getAndSubscribeToAlarms() {
        if (this._logic.demo().isActive) {
            return;
        }
        let alarms: AlarmInDatabaseWithGPSInfo[] = [];
        try {
            const params = {
                clientId: this._logic.auth().client()?.id!,
                subscribeDevice: this._logic.notification().device,
                subscribeUser: this._logic.auth().keycloak.tokenParsed?.['sub'],
                active: false
            };
            alarms = await this._logic.api().backendApi.getAlarmsV1AlarmsMyGet(params);
        } catch (e) {
            alarms = [];
        }

        // MFR 24.2.2021 Filter InstantAlarms only with not end Time and only 24h old
        this.alarms = alarms
            .map(a => this._toAlarm(a))
            .filter(
                a =>
                    ![AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(a.alarmType) ||
                    ([AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(a.alarmType) &&
                        a.startDateTime &&
                        moment.utc(a.startDateTime).isBetween(moment.utc().subtract(1, 'day'), moment.utc()) &&
                        !a.end)
            );

        // MFR 17.3 2021 Filter InstantAlarms when older as Vehicle GpsDataTime
        this.alarms = this.alarms.filter(alarm => {
            if ([AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.UnavailableGps].includes(alarm.alarmType)) {
                const vehicle = this._logic
                    .tracking()
                    .getVehiclesData()
                    .find(v => v.id === alarm.monitoredObjectId);
                const vehicleLastGpsTime = vehicle?.gpsDataTime ? moment.utc(vehicle?.gpsDataTime) : undefined;
                const alarmStartTime = moment.utc(alarm.startDateTime);
                return !vehicle || !vehicleLastGpsTime || vehicleLastGpsTime.isBefore(alarmStartTime);
            } else {
                return true;
            }
        });

        this.alarmsUpdates.next(this.alarms);
    }

    private _toAlarm(alarm: AlarmInDatabaseWithGPSInfo): Alarm {
        return {
            ...alarm,
            alarmId: alarm.alarmId || (alarm as any).alarm_id,
            localizedAddress: alarm?.addressStart?.[0]?.address
                ? toAddress(
                      this._logic.auth().user().lang,
                      this._logic.auth().client()!,
                      alarm.addressStart,
                      AddressIdentification.Address,
                      alarm.addressStart?.[0]?.address
                  )
                : alarm.lastGpsPointStartObj?.lat && alarm.lastGpsPointStartObj?.lng
                ? `${alarm.lastGpsPointStartObj?.lat} ${alarm.lastGpsPointStartObj?.lng}`
                : 'Unavailable address',
            startDateTime: alarm.start
                ? moment(moment.utc(moment(alarm.start).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : undefined,
            endDateTime: alarm.end
                ? moment(moment.utc(moment(alarm.end).format('YYYY-MM-DD HH:mm:ss')).toDate())
                      .local()
                      .toISOString()
                : undefined
        };
    }
}
