import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import TableBar from 'common/components/TableBar';
import ColdchainFilter from 'modules/statistics/modules/coldchain/components/coldchain-filter';
import { WithLogic, withLogicContext } from 'App';
import { SearchData } from 'common/components/Search';
import qa from 'qa-selectors';
import { confDefault } from 'conf';
import { arrayEquals } from 'common/utils/textUtils';

interface Props extends WithTranslation, WithLogic {
    demoMode?: boolean;
    search?: SearchData;
    heading?: string;
    filter: {
        open: boolean;
        vehiclesChecked: string[];
        trailersChecked: string[];
        profilesChecked: string[];
        showDeleted: boolean;
    };
    backButton?: {
        onClick?: () => void;
        qa?: string;
        title?: string;
    };
    className?: string;
    onBarSearchChange?: (text: string) => void;
    onBarFilterClick?: () => void;
    onBarResetClick?: () => void;
    onFilterConfirmClick?: (
        vehiclesChecked: string[],
        trailersChecked: string[],
        profilesChecked: string[],
        showDeleted: boolean
    ) => void;
    onFilterCancelClick?: () => void;
}

function ColdchainBar(props: Props) {
    const defaults = confDefault.settings.statistics.coldChain.filter;

    const coldChainTrailers = props.logic
        .coldchainLogic()
        .trailers.filter(t =>
            props.logic.coldchainLogic().mobjectsTemperatureSensors.find(ts => ts.monitoredObject === t.id)
        );

    const coldChainVehicles = props.logic
        .coldchainLogic()
        .vehicles.filter(t =>
            props.logic.coldchainLogic().mobjectsTemperatureSensors.find(ts => ts.monitoredObject === t.id)
        );

    return (
        <>
            <TableBar
                searchInput={{ disabled: props.demoMode }}
                heading={props.heading ?? ''}
                search={props.search}
                onSearchChange={props.onBarSearchChange}
                backButton={props.backButton}
                className={props.className}
                filter={{
                    activeCount:
                        (arrayEquals(props.filter.vehiclesChecked, defaults.vehicles) || !coldChainVehicles.length
                            ? 0
                            : 1) +
                        (arrayEquals(props.filter.trailersChecked, defaults.trailers) || !coldChainTrailers.length
                            ? 0
                            : 1) +
                        (arrayEquals(props.filter.profilesChecked, defaults.profiles) ||
                        !props.logic.coldchainLogic().profiles.length
                            ? 0
                            : 1) +
                        (props.filter.showDeleted ? 1 : 0),
                    onClick: props.onBarFilterClick,
                    qa: qa.coldChain.bar.btnFilter,
                    resetButton: {
                        onClick: props.onBarResetClick
                    }
                }}
            />
            {props.filter.open && (
                <ColdchainFilter
                    vehicleOpts={coldChainVehicles.map(v => ({
                        code: String(v.id),
                        label: v.registrationNumber,
                        checked: props.filter.trailersChecked.includes(String(v.id))
                    }))}
                    vehiclesChecked={props.filter.vehiclesChecked}
                    trailerOpts={coldChainTrailers.map(v => ({
                        code: String(v.id),
                        label: v.registrationNumber,
                        checked: props.filter.trailersChecked.includes(String(v.id))
                    }))}
                    trailersChecked={props.filter.trailersChecked}
                    profileOpts={props.logic.coldchainLogic().profiles.map(v => ({
                        code: v.id ?? '',
                        label: v.name ?? '',
                        checked: props.filter.profilesChecked.includes(v.id ?? '')
                    }))}
                    profilesChecked={props.filter.profilesChecked}
                    showDeleted={props.filter.showDeleted}
                    demoMode={props.demoMode}
                    onConfirm={props.onFilterConfirmClick}
                    onCancel={props.onFilterCancelClick}
                />
            )}
        </>
    );
}

export default withTranslation()(withLogicContext(observer(ColdchainBar)));
