import PartnerPairs from 'modules/partner/components/PartnerPairs';
import { PartnerPairingKey } from 'common/model/partner-pairing';
import { PairingItemType } from 'logic/partner/partner';
import { ObuModel } from 'logic/partner/logic/partner-obu';

interface Props {
    model: ObuModel;
    onUnpair?: (linked: PairingItemType) => void;
}

export default function ObuLinkedItems(props: Props) {
    function onUnpair(id: string, key: PartnerPairingKey) {
        const linked: PairingItemType = {
            id,
            key
        };
        props.onUnpair?.(linked);
    }

    return (
        <div className="partner-pairing-list">
            {props.model?.links && <PartnerPairs links={props.model?.links} onUnpair={onUnpair} />}
        </div>
    );
}
