// TODO: After integration move to common forms
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import { SelectField } from 'common/fields';
import { SelectOption } from 'common/components/Form/Select';
import qa from 'qa-selectors';
import { Col, Row } from 'antd';
import { ExternalSystemSecretMetadata } from 'generated/new-main/models';

export interface SystemConnectFormValues {
    id?: number;
    externalSystemId: string;
    vehicles: number[];
    vehiclesGroup?: number[];
    metadata?: ExternalSystemSecretMetadata;
    automaticVehicleAdd?: boolean;
}

interface Props {
    initialValues?: Partial<SystemConnectFormValues>;
    vehicleOptions: SelectOption[];
    vehicleGroupsOptions: SelectOption[];
    onSubmit?: (values: SystemConnectFormValues) => void;
    onCancel?: () => void;
    onActivatePuesc?: (values: SystemConnectFormValues) => void;
}

export default function SystemConnectForm({
    initialValues,
    onSubmit,
    onCancel,
    vehicleOptions,
    vehicleGroupsOptions
}: Props) {
    const { t } = useTranslation();
    const shape = {
        vehicles: Yup.array(Yup.number().required(t('common.required')))
    };
    const schema = Yup.object().shape(shape);

    function handleOnSubmit(values: SystemConnectFormValues) {
        onSubmit?.(values);
    }

    return (
        <Formik<SystemConnectFormValues>
            initialValues={{
                externalSystemId: initialValues?.externalSystemId ?? '',
                vehicles: initialValues?.vehicles ?? [],
                vehiclesGroup: initialValues?.vehiclesGroup ?? []
            }}
            onSubmit={handleOnSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<SystemConnectFormValues>) => (
                <div className="other-systems-form">
                    <Form>
                        <SelectField
                            mode="multiple"
                            options={vehicleOptions}
                            defaultValue={initialValues?.vehicles}
                            name="vehicles"
                            label={t('SystemConnectForm.vehicle')}
                            placeholder={t('SystemConnectForm.vehicle')}
                            qa={qa.systemConnections.form.selectVehicles}
                        />
                        <Row className="form-row" gutter={[10, 20]}>
                            <Col span={24}>
                                <SelectField
                                    mode="multiple"
                                    options={vehicleGroupsOptions}
                                    defaultValue={initialValues?.vehiclesGroup}
                                    name="vehiclesGroup"
                                    label={t('SystemConnectForm.vehicleGroups')}
                                    placeholder={t('SystemConnectForm.vehicleGroups')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10} justify="end">
                            <Col>
                                <Button
                                    type="default"
                                    onClick={onCancel}
                                    block
                                    qa={qa.systemConnections.form.btnFormCancel}
                                >
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={formik.isSubmitting}
                                    htmlType="submit"
                                    type="primary"
                                    block
                                    qa={qa.systemConnections.form.btnFormSubmit}
                                >
                                    {t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
