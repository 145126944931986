/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ADRClass,
    ADRClassFromJSON,
    ADRClassFromJSONTyped,
    ADRClassToJSON,
    Accessories,
    AccessoriesFromJSON,
    AccessoriesFromJSONTyped,
    AccessoriesToJSON,
    CargoSpace,
    CargoSpaceFromJSON,
    CargoSpaceFromJSONTyped,
    CargoSpaceToJSON,
    MonitoredObjectType,
    MonitoredObjectTypeFromJSON,
    MonitoredObjectTypeFromJSONTyped,
    MonitoredObjectTypeToJSON,
    TruckBody,
    TruckBodyFromJSON,
    TruckBodyFromJSONTyped,
    TruckBodyToJSON,
    WayOfLoading,
    WayOfLoadingFromJSON,
    WayOfLoadingFromJSONTyped,
    WayOfLoadingToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleProperties
 */
export interface VehicleProperties {
    /**
     * 
     * @type {MonitoredObjectType}
     * @memberof VehicleProperties
     */
    type: MonitoredObjectType;
    /**
     * 
     * @type {TruckBody}
     * @memberof VehicleProperties
     */
    truckBody: TruckBody;
    /**
     * 
     * @type {Array<ADRClass>}
     * @memberof VehicleProperties
     */
    adrClasses?: Array<ADRClass>;
    /**
     * 
     * @type {Array<Accessories>}
     * @memberof VehicleProperties
     */
    accessories?: Array<Accessories>;
    /**
     * 
     * @type {Array<WayOfLoading>}
     * @memberof VehicleProperties
     */
    waysOfLoading?: Array<WayOfLoading>;
    /**
     * 
     * @type {CargoSpace}
     * @memberof VehicleProperties
     */
    cargoSpace?: CargoSpace;
    /**
     * 
     * @type {number}
     * @memberof VehicleProperties
     */
    weightF1?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleProperties
     */
    weightF2?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleProperties
     */
    weightF3?: number;
}

export function VehiclePropertiesFromJSON(json: any): VehicleProperties {
    return VehiclePropertiesFromJSONTyped(json, false);
}

export function VehiclePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': MonitoredObjectTypeFromJSON(json['type']),
        'truckBody': TruckBodyFromJSON(json['truckBody']),
        'adrClasses': !exists(json, 'adrClasses') ? undefined : ((json['adrClasses'] as Array<any>).map(ADRClassFromJSON)),
        'accessories': !exists(json, 'accessories') ? undefined : ((json['accessories'] as Array<any>).map(AccessoriesFromJSON)),
        'waysOfLoading': !exists(json, 'waysOfLoading') ? undefined : ((json['waysOfLoading'] as Array<any>).map(WayOfLoadingFromJSON)),
        'cargoSpace': !exists(json, 'cargoSpace') ? undefined : CargoSpaceFromJSON(json['cargoSpace']),
        'weightF1': !exists(json, 'weightF1') ? undefined : json['weightF1'],
        'weightF2': !exists(json, 'weightF2') ? undefined : json['weightF2'],
        'weightF3': !exists(json, 'weightF3') ? undefined : json['weightF3'],
    };
}

export function VehiclePropertiesToJSON(value?: VehicleProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MonitoredObjectTypeToJSON(value.type),
        'truckBody': TruckBodyToJSON(value.truckBody),
        'adrClasses': value.adrClasses === undefined ? undefined : ((value.adrClasses as Array<any>).map(ADRClassToJSON)),
        'accessories': value.accessories === undefined ? undefined : ((value.accessories as Array<any>).map(AccessoriesToJSON)),
        'waysOfLoading': value.waysOfLoading === undefined ? undefined : ((value.waysOfLoading as Array<any>).map(WayOfLoadingToJSON)),
        'cargoSpace': CargoSpaceToJSON(value.cargoSpace),
        'weightF1': value.weightF1,
        'weightF2': value.weightF2,
        'weightF3': value.weightF3,
    };
}


