/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectGroup
 */
export interface MonitoredObjectGroup {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectGroup
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectGroup
     */
    color?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectGroup
     */
    monitoredObjectGroupType?: number | null;
    /**
     * 
     * @type {Client}
     * @memberof MonitoredObjectGroup
     */
    client?: Client;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectGroup
     */
    readonly deleted?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObjectGroup
     */
    automaticVehicleAdd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObjectGroup
     */
    readonly deletable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectGroup
     */
    clientId?: number;
}

export function MonitoredObjectGroupFromJSON(json: any): MonitoredObjectGroup {
    return MonitoredObjectGroupFromJSONTyped(json, false);
}

export function MonitoredObjectGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'monitoredObjectGroupType': !exists(json, 'monitored_object_group_type') ? undefined : json['monitored_object_group_type'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'automaticVehicleAdd': !exists(json, 'automatic_vehicle_add') ? undefined : json['automatic_vehicle_add'],
        'deletable': !exists(json, 'deletable') ? undefined : json['deletable'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function MonitoredObjectGroupToJSON(value?: MonitoredObjectGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color': value.color,
        'monitored_object_group_type': value.monitoredObjectGroupType,
        'client': ClientToJSON(value.client),
        'automatic_vehicle_add': value.automaticVehicleAdd,
        'client_id': value.clientId,
    };
}


