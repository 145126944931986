/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PerfectDrivePeriod,
    PerfectDrivePeriodFromJSON,
    PerfectDrivePeriodToJSON,
    PerfectDriveScoreContextualPeriod,
    PerfectDriveScoreContextualPeriodFromJSON,
    PerfectDriveScoreContextualPeriodToJSON,
    PerfectDriveScorePeriod,
    PerfectDriveScorePeriodFromJSON,
    PerfectDriveScorePeriodToJSON,
} from '../models';

export interface GetPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGetRequest {
    month: string;
}

export interface PerfectDriveReportV1DriverbehaviourPerfectDriveReportGetRequest {
    month: string;
    history?: number;
    tachocards?: Array<string>;
}

export interface PerfectDriveScoreV1DriverbehaviourPerfectDriveScoreGetRequest {
    month: string;
    history?: number;
    tachocard?: string;
    old?: boolean;
}

/**
 * no description
 */
export class PerfectDriveApi extends runtime.BaseAPI {

    /**
     * Return aggregated scoring for larger scope
     * Get Perfect Drive Contextual
     */
    async getPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGetRaw(requestParameters: GetPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGetRequest): Promise<runtime.ApiResponse<Array<PerfectDriveScoreContextualPeriod>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling getPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/perfect-drive/contextual`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PerfectDriveScoreContextualPeriodFromJSON));
    }

    /**
     * Return aggregated scoring for larger scope
     * Get Perfect Drive Contextual
     */
    async getPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGet(requestParameters: GetPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGetRequest): Promise<Array<PerfectDriveScoreContextualPeriod>> {
        const response = await this.getPerfectDriveContextualV1DriverbehaviourPerfectDriveContextualGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns monthly perfect drive data with scores and ranking
     * Perfect Drive Report
     */
    async perfectDriveReportV1DriverbehaviourPerfectDriveReportGetRaw(requestParameters: PerfectDriveReportV1DriverbehaviourPerfectDriveReportGetRequest): Promise<runtime.ApiResponse<Array<PerfectDrivePeriod>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling perfectDriveReportV1DriverbehaviourPerfectDriveReportGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.history !== undefined) {
            queryParameters['history'] = requestParameters.history;
        }

        if (requestParameters.tachocards) {
            queryParameters['tachocards'] = requestParameters.tachocards;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/perfect-drive/report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PerfectDrivePeriodFromJSON));
    }

    /**
     * Returns monthly perfect drive data with scores and ranking
     * Perfect Drive Report
     */
    async perfectDriveReportV1DriverbehaviourPerfectDriveReportGet(requestParameters: PerfectDriveReportV1DriverbehaviourPerfectDriveReportGetRequest): Promise<Array<PerfectDrivePeriod>> {
        const response = await this.perfectDriveReportV1DriverbehaviourPerfectDriveReportGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return scoring data for month and additional history of months
     * Perfect Drive Score
     */
    async perfectDriveScoreV1DriverbehaviourPerfectDriveScoreGetRaw(requestParameters: PerfectDriveScoreV1DriverbehaviourPerfectDriveScoreGetRequest): Promise<runtime.ApiResponse<Array<PerfectDriveScorePeriod>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling perfectDriveScoreV1DriverbehaviourPerfectDriveScoreGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.history !== undefined) {
            queryParameters['history'] = requestParameters.history;
        }

        if (requestParameters.tachocard !== undefined) {
            queryParameters['tachocard'] = requestParameters.tachocard;
        }

        if (requestParameters.old !== undefined) {
            queryParameters['old'] = requestParameters.old;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviour/perfect-drive/score`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PerfectDriveScorePeriodFromJSON));
    }

    /**
     * Return scoring data for month and additional history of months
     * Perfect Drive Score
     */
    async perfectDriveScoreV1DriverbehaviourPerfectDriveScoreGet(requestParameters: PerfectDriveScoreV1DriverbehaviourPerfectDriveScoreGetRequest): Promise<Array<PerfectDriveScorePeriod>> {
        const response = await this.perfectDriveScoreV1DriverbehaviourPerfectDriveScoreGetRaw(requestParameters);
        return await response.value();
    }

}
