import { useState } from 'react';
import cn from 'classnames';
import { Moment } from 'moment';
import moment from 'moment';
import { Row, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    SortableElement,
    SortableContainer,
    SortEvent,
    SortEventWithTag,
    SortEndHandler,
    SortEnd
} from 'react-sortable-hoc';
import { Button, DatePicker, Switch, Alert } from 'common/components';

import { AddressFormatter } from 'common/utils/components/AddressFormatter';
import { TIME_FORMAT_SHORT } from 'domain-constants';
import qa from 'qa-selectors';
import * as PlannerIcons from 'resources/images/planner';
import { WithLogic, withLogicContext } from 'App';
import { observer } from 'mobx-react';
import { PuescWarning } from '../PuescWarning/PuescWarning';
import { TransportPlace } from 'generated/backend-api/models';
import { latLngFromGeoJsonPointType } from 'common/utils/geo-utils';
import numeral from 'numeral';
import TransportRoute from '../TransportRoute/index.';
import { AvailableCurrencies } from 'common/model/currency';
import PlaceLocationEdit from '../PlaceLocationEdit';
import { RouteSelectData } from 'modules/routing/places-autocomplete-new/PlacesAutocompleteModule';
import OutlineButton from '../OutlineButton';
import { getParents } from 'common/utils';
import { iconClose } from 'resources/images/common';
interface Props extends WithLogic {
    sorting: boolean;
    disabled?: boolean;
    placeSelectedIndex?: number;
    routeLoading?: boolean;
    routeSelectedId?: string;
    demoMode?: boolean;
    isPuescTransportPossible?: boolean;
    puescActive?: boolean;
    currency?: AvailableCurrencies;
    showToll?: boolean;
    openPlacesDisabled?: boolean;
    onPuescChange?: (active: boolean) => void;
    onSortEnd?: SortEndHandler;
    onDeleteClick?: (id: string) => void;
    onAddNewTask?: (placeId?: string) => void;
    onEditTask?: (placeId: string, taskId: string) => void;
    onRouteClick?: (place: TransportPlace) => void;
    onOpenDescriptionClick?: (state: boolean) => void;
    onPlaceLocationChange?: (value: RouteSelectData, placeId: string | undefined, index: number) => void;
    onPlaceEdited?: () => void;
    onAlertCloseClick?: () => void;
}

const PlacesConfigurator = SortableContainer(
    observer((props: Props) => {
        const [placesExpanded, setPlacesExpanded] = useState(false);
        const places = props.logic.plannerLogic().transport.places ?? [];
        const { t } = useTranslation();
        const totalDistance = places.reduce((a, c) => a + (c.distance ?? 0), 0);

        function selectPlaceBeforeRta(index: number) {
            return (
                (places[index - 1] &&
                    places[index - 1].rta &&
                    moment(places[index - 1].rta).add(places[index - 1].duration || undefined)) ||
                undefined
            );
        }
        const showPuescWarningToIndex = places.findIndex(
            place =>
                !!(
                    place.route &&
                    props.logic.plannerLogic().isPuescTransport &&
                    props.logic.map().routing().polylineInPoland(place.route)
                )
        );

        const handleRouteClick = (place: TransportPlace) => {
            props.onRouteClick?.(place);
        };

        return (
            <div
                className={cn('places-configurator-new', {
                    'places-configurator-disabled': props.disabled
                })}
            >
                {places.length > 0 && (
                    <div className="places-configurator-new-head">
                        <Row align="middle">
                            <Col xs={2} className="only-text">
                                {t('routing.places.heading.arrival')}
                            </Col>
                            <Col xs={4} />
                            <Col xs={8} className="only-text">
                                {t('routing.places.heading.location')}
                            </Col>
                            <Col xs={8}>
                                {props.isPuescTransportPossible && (
                                    <div className="switch-puesc">
                                        <Switch
                                            size="small"
                                            onChange={props.onPuescChange}
                                            checked={props.puescActive}
                                        />
                                        <span className="puesc-description">{t('PlannerBar.puesc')}</span>
                                    </div>
                                )}
                            </Col>
                            {places.length > 1 && (
                                <Col xs={2}>
                                    <OutlineButton
                                        size="small"
                                        className="open-place-description"
                                        isDown={placesExpanded}
                                        onClick={() => {
                                            setPlacesExpanded(!placesExpanded);
                                            props.onOpenDescriptionClick?.(!placesExpanded);
                                        }}
                                        disabled={props.openPlacesDisabled}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                )}

                <div
                    className={cn('places-configurator-new-placeholder', {
                        'places-configurator-new-placeholder-sorting': props.sorting,
                        'places-configurator-new-placeholder-route-expanded': placesExpanded,
                        'places-configurator-new-placeholder-route-expanded-puesc-warning':
                            placesExpanded && showPuescWarningToIndex !== undefined
                    })}
                >
                    {places.map((place, index) => (
                        <div
                            key={'_placeholder' + place.id}
                            className={cn('places-configurator-new-placeholder-item', {
                                'places-configurator-new-placeholder-item-puesc-warning':
                                    index === showPuescWarningToIndex
                            })}
                        />
                    ))}
                </div>
                {places.map((place, index) => {
                    return props.disabled ? (
                        <Place
                            showPuescWarning={index === showPuescWarningToIndex}
                            placesExpanded={placesExpanded}
                            disabled={props.disabled}
                            isActive={props.logic.plannerLogic().selectedPlaceId === place.id}
                            hasRoute={(place.route ?? '') !== ''}
                            key={place.id}
                            order={index}
                            count={places.length}
                            placeBeforeRta={selectPlaceBeforeRta(index)}
                            plannerDisabled={
                                props.disabled ||
                                (typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number' &&
                                    index === 0) ||
                                props.routeLoading
                            } // First waypoint cant change when you go from addDestination MFR 28.5.2021
                            addNewTaskDisabled={props.disabled || props.routeLoading}
                            onPlaceChange={(data: RouteSelectData) => {
                                props.onPlaceLocationChange?.(data, place.id, index);
                            }}
                            onEdited={props.onPlaceEdited}
                            showRoute={false}
                            onAlertShowRouteClick={() => {
                                setPlacesExpanded(!placesExpanded);
                                props.onOpenDescriptionClick?.(!placesExpanded);
                            }}
                            onAlertCloseClick={props.onAlertCloseClick}
                            {...{ index, totalDistance, data: place, places, ...props }}
                        />
                    ) : (
                        <SortablePlace
                            showPuescWarning={index === showPuescWarningToIndex}
                            placesExpanded={placesExpanded}
                            selected={props.placeSelectedIndex === index}
                            disabled={props.disabled}
                            isActive={props.logic.plannerLogic().selectedPlaceId === place.id}
                            hasRoute={(place.route ?? '') !== ''}
                            onRouteClick={handleRouteClick}
                            key={place.id}
                            order={index}
                            count={places.length}
                            placeBeforeRta={selectPlaceBeforeRta(index)}
                            plannerDisabled={
                                props.disabled ||
                                (typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number' &&
                                    index === 0) ||
                                props.routeLoading
                            } // First waypoint cant change when you go from addDestination MFR 28.5.2021
                            addNewTaskDisabled={props.disabled || props.routeLoading}
                            showRoute={true}
                            onPlaceChange={(data: RouteSelectData) => {
                                props.onPlaceLocationChange?.(data, place.id, index);
                            }}
                            onEdited={props.onPlaceEdited}
                            onAlertShowRouteClick={() => {
                                setPlacesExpanded(!placesExpanded);
                                props.onOpenDescriptionClick?.(!placesExpanded);
                            }}
                            onAlertCloseClick={props.onAlertCloseClick}
                            {...{ index, totalDistance, data: place, places, ...props }}
                        />
                    );
                })}
            </div>
        );
    })
);

interface PlaceProps extends Props {
    showPuescWarning: boolean;
    order: number;
    count: number;
    data: TransportPlace;
    isActive: boolean;
    hasRoute: boolean;
    totalDistance: number;
    placeBeforeRta?: Moment;
    plannerDisabled?: boolean;
    placeId?: string;
    addNewTaskDisabled?: boolean;
    places: TransportPlace[];
    placesExpanded: boolean;
    showRoute: boolean;
    selected?: boolean;
    editable?: boolean;
    onRouteClick?: (place: TransportPlace) => void;
    onPlaceChange?: (value: RouteSelectData) => void;
    onEdited?: () => void;
    onAlertShowRouteClick?: () => void;
    onAlertCloseClick?: () => void;
}

const SortablePlace = SortableElement((props: PlaceProps) => <Place {...props} />);

const Place = observer(({ data, order, places = [], placesExpanded, ...props }: PlaceProps) => {
    const momentRta = moment(data.rta).local();
    const [edited, setEdited] = useState(false);

    const { t } = useTranslation();

    let breakTime = 0;
    if (order === 0) {
        places.forEach(place => {
            breakTime +=
                place.listOfWtmBreakRest?.reduce((partialSum, item) => {
                    return partialSum + (item['activityEnd'] - item['activityStart']);
                }, 0) ?? 0;
        });
    }

    const onRtaChange = (newRTA: Moment | null) => {
        if (newRTA && data.id) {
            const index = props.logic.plannerLogic().transport.places?.findIndex(p => p.id === data.id) ?? -1;

            if (index > 0) {
                const prevPlace = props.logic.plannerLogic().transport.places?.[index - 1];
                const oldRTD = prevPlace?.rtd
                    ? moment(prevPlace?.rtd).add(prevPlace?.duration, 'seconds')
                    : moment(prevPlace?.rta).add(prevPlace?.duration, 'seconds');

                const aetrBreaksTime =
                    prevPlace?.listOfWtmBreakRest?.reduce((partialSum, item) => {
                        return partialSum + (item['activityEnd'] - item['activityStart']);
                    }, 0) ?? 0;

                const difference = moment(newRTA).subtract(aetrBreaksTime, 'seconds').diff(oldRTD);

                if (prevPlace) {
                    prevPlace.durationBuffer = 0;
                    if (difference > 0) {
                        prevPlace.durationBuffer = difference / 1000;
                    }
                }
            }
            props.logic.plannerLogic().changePlaceRta(data.id, newRTA);
            props.logic.plannerLogic().planRoute(props.logic.plannerLogic().transport);
            props.logic.plannerLogic().drawRouteOnMap();
        }
    };

    function disabledDate(current: Moment): boolean {
        const placeBefore = places[order - 1];
        const now = moment().startOf('day');
        const isBefore = current.isBefore(now);
        const isBeforePlaceBeforeRTA = placeBefore
            ? current.isBefore(
                  moment(placeBefore.rtd ?? placeBefore.rta).subtract(placeBefore.durationBuffer ?? 0, 'seconds')
              )
            : false;

        if (isBefore || isBeforePlaceBeforeRTA) {
            return true;
        }

        return false;
    }

    const handleRouteClick = (place: TransportPlace) => {
        props.onRouteClick?.(place);
    };

    const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
        setEdited(true);
        e.preventDefault();
        e.stopPropagation();
        props.onEdited?.();
    };

    const handleEditCancelClick = () => {
        setEdited(false);
    };

    const handlePlaceClicked = (data: TransportPlace, event: React.MouseEvent) => {
        if (!props.addNewTaskDisabled) {
            if (!getParents(event.target, '.ant-picker-wrapper') && !getParents(event.target, '.ant-picker-dropdown')) {
                props.onAddNewTask?.(data.id);
            }
        }
    };

    const puescValidationTaskError =
        props.logic.plannerLogic().puescValidationErrors?.loadingTask === false ||
        props.logic.plannerLogic().puescValidationErrors?.unloadingTask === false ||
        props.logic.plannerLogic().puescValidationErrors?.allGoodsUnloaded === false;
    const puescValidationTaskMessage = (
        <div>
            {props.logic.plannerLogic().puescValidationErrors?.loadingTask === false && (
                <>
                    {t('Planner.puesc.noLoadingTaskSelected')}
                    <br />
                </>
            )}
            {props.logic.plannerLogic().puescValidationErrors?.unloadingTask === false && (
                <>
                    {t('Planner.puesc.noUnloadingTaskSelected')}
                    <br />
                </>
            )}
            {props.logic.plannerLogic().puescValidationErrors?.allGoodsUnloaded === false && (
                <>
                    {t('Planner.puesc.noAllGodsUnloaded')}
                    <br />
                </>
            )}
        </div>
    );

    const disabled =
        props.logic.plannerLogic().getPlaceDisabled(places[order]) ||
        (order === 0 && typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number');

    const distance = places.slice(0, order).reduce((a, c) => (a = a + (c.distance ?? 0)), 0);

    return (
        <div
            className={cn('places-configurator-new-place-wrapper', {
                'places-configurator-new-place-wrapper-route-expanded': placesExpanded && props.hasRoute,
                'places-configurator-new-place-wrapper-inactive-sort': props.routeLoading
            })}
            data-qa={qa.planner.places.sectionPlace}
        >
            <div
                className={cn('places-configurator-new-place', {
                    'places-configurator-new-place-sorting': props.sorting,
                    'places-configurator-new-place-disabled': disabled,
                    'places-configurator-new-place-active': props.isActive,
                    'places-configurator-new-place-puesc': props.showPuescWarning,
                    'places-configurator-new-place-selected': props.selected
                })}
                onClick={event => {
                    handlePlaceClicked(data, event);
                }}
            >
                <Row className="places-configurator-new-place-waypoint" align="middle" gutter={4}>
                    <Col xs={2}>
                        <DatePicker.DateTimePicker
                            showTime={{ format: TIME_FORMAT_SHORT }}
                            disabled={disabled || props.plannerDisabled}
                            defaultValue={momentRta}
                            disabledDate={disabledDate}
                            onChange={onRtaChange}
                            showNow={order === 0}
                            qa={qa.planner.places.btnDatepicker}
                            trigger={
                                <div
                                    className={cn('places-configurator-new-place-date', {
                                        'fixed-rta': data.fixedRta ?? false
                                    })}
                                >
                                    <div
                                        className="places-configurator-new-place-date-time"
                                        data-qa={qa.planner.places.fieldTime}
                                    >
                                        {momentRta.format('HH:mm')}
                                    </div>
                                    <div
                                        className="places-configurator-new-place-date-day"
                                        data-qa={qa.planner.places.fieldDate}
                                    >
                                        {momentRta.format('DD.MM.')}
                                    </div>
                                </div>
                            }
                        />
                    </Col>
                    <Col xs={4}>
                        <div className="places-configurator-new-place-distance">
                            <div className={cn('places-configurator-new-place-distance-icon')}>
                                {data?.tasks && data.tasks.length > 0 ? (
                                    data.tasks.length > 1 ? (
                                        <Tooltip
                                            title={data.tasks?.map(task => (
                                                <img
                                                    src={props.logic.plannerLogic().getTaskTypeSelectionIcon(task.type)}
                                                    className="places-configurator-new-place-distance-icon-tooltip"
                                                    alt="tasks"
                                                    key={task.id}
                                                    data-qa={qa.planner.places.btnTasks}
                                                />
                                            ))}
                                        >
                                            {data.tasks.length}
                                        </Tooltip>
                                    ) : (
                                        <img
                                            src={props.logic
                                                .plannerLogic()
                                                .getTaskTypeSelectionIcon(data.tasks[0]?.type)}
                                            alt="tasks"
                                            data-qa={qa.planner.places.btnTasks}
                                        />
                                    )
                                ) : (
                                    <i className="fa fa-map-marker" />
                                )}
                            </div>
                            <span data-qa={qa.planner.places.fieldDistance}>
                                {distance && numeral((distance ?? 0) / 1000).format('0,0')}
                            </span>{' '}
                            {t('common.km')}
                        </div>
                    </Col>
                    <Col xs={12}>
                        {!edited && (
                            <div className="places-configurator-place-name">
                                {data.name ? (
                                    <Tooltip
                                        title={
                                            <AddressFormatter
                                                defaultAddress={data.name ?? ''}
                                                addressStructured={data.addressStructured}
                                            />
                                        }
                                    >
                                        {data.name}
                                    </Tooltip>
                                ) : (
                                    <AddressFormatter
                                        defaultAddress={data.name ?? ''}
                                        addressStructured={data.addressStructured}
                                        qa={qa.planner.places.fieldName}
                                    />
                                )}
                            </div>
                        )}
                        {edited && (
                            <PlaceLocationEdit
                                name={data.name}
                                onChange={(value: RouteSelectData) => {
                                    props.onPlaceChange?.(value);
                                }}
                                onCancel={handleEditCancelClick}
                            />
                        )}
                    </Col>
                    <Col xs={6}>
                        <div className="places-configurator-new-place-actions">
                            {!disabled && !props.plannerDisabled && (props.editable ?? true) && !edited && (
                                <Button
                                    onClick={handleEditClick}
                                    data-qa={qa.planner.places.btnEdit}
                                    disabled={props.logic.plannerLogic().tollCostLoading}
                                    size="middle"
                                    type="link"
                                    key="edit"
                                    icon={
                                        <img
                                            src={PlannerIcons.EditLocation}
                                            className="places-configurator-place-actions-edit"
                                            alt="edit"
                                        />
                                    }
                                />
                            )}
                            {!disabled && !props.plannerDisabled && (
                                <Button
                                    onClick={e => {
                                        props.onDeleteClick?.(data.id ?? '');
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    data-qa={qa.planner.places.btnRemove}
                                    size="middle"
                                    type="link"
                                    key="delete"
                                    icon={
                                        <img
                                            src={PlannerIcons.PlaceTrash}
                                            className="places-configurator-place-actions-remove"
                                            alt="delete"
                                        />
                                    }
                                />
                            )}
                            {!props.addNewTaskDisabled && (
                                <img
                                    className="places-configurator-detail-arrow"
                                    src={PlannerIcons.RouteArrowRight}
                                    alt="arrow-right-icon"
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {props.logic.settings().getProp('planner').hideAetrBreaksAlert !== true &&
            places.length > 1 &&
            breakTime ? (
                <Alert
                    message={
                        <Row justify="space-between" align="middle">
                            <Col span={19}>{t('Planner.aetrBreaksMessage')}</Col>
                            <Col span={5} className="alert-notice-link">
                                {!placesExpanded && (
                                    <Button type="link" onClick={props.onAlertShowRouteClick}>
                                        {t('Planner.showRouteDetails')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    }
                    className="alert-notice"
                    closeText={<img src={iconClose} alt="close" />}
                    closable
                    onClose={props.onAlertCloseClick}
                />
            ) : null}
            {placesExpanded && props.hasRoute && (
                <TransportRoute
                    key={'route_' + props.placeId}
                    logic={props.logic}
                    selected={data.id === props.routeSelectedId}
                    transportPlace={data}
                    show={props.showRoute}
                    showToll={props.showToll}
                    disabled={disabled || props.plannerDisabled}
                    currency={props.currency}
                    onRowClick={handleRouteClick}
                />
            )}
            {placesExpanded && places.length - 1 > order && (
                <>
                    {props.showPuescWarning && (
                        <div
                            className="places-configurator-new-place-puesc-warning"
                            data-qa={qa.planner.places.fieldPuescWarning}
                        >
                            <PuescWarning size="medium" />
                            <label>{t('common.puesc')}</label>
                            <div className="places-configurator-place-puesc-warning-info">
                                <Tooltip placement="right" title={t('Planner.puesc.puescRequirements')}>
                                    <img src={PlannerIcons.PuescInfo} alt="puesc-info" />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    {props.logic.plannerLogic().isPuescTransport &&
                        props.logic
                            .map()
                            .routing()
                            .pointInPoland(latLngFromGeoJsonPointType(data.center ?? {})) && (
                            <PuescWarning
                                tooltip={puescValidationTaskError ? puescValidationTaskMessage : undefined}
                                type={puescValidationTaskError ? 'error' : 'warning'}
                                size="small"
                            />
                        )}
                    <div />
                    {/* TODO: There will be detailed description of place */}
                </>
            )}
        </div>
    );
});

function shouldCancelStart(e: SortEvent | SortEventWithTag) {
    const tag = (e as SortEventWithTag).target.tagName.toLowerCase();
    const el = e.target as HTMLElement;

    return (
        tag === 'img' ||
        tag === 'input' ||
        String(el.className).startsWith('places-configurator-place-date') ||
        String(el.className).startsWith('ant')
    );
}

const SortedPlacesConfigurator = ({ onSortEnd, ...props }: Omit<Props, 'sorting'>) => {
    const [sorting, setIsSorting] = useState(false);

    function handleSortStart() {
        setIsSorting(true);
    }

    function handleSortEnd(sort: SortEnd, event: SortEvent) {
        if (
            (sort.newIndex === 0 && typeof props.logic.plannerLogic().lockedMonitoredObjectId === 'number') ||
            sort.oldIndex === sort.newIndex
        ) {
            // the first element is "unsortable"
            setIsSorting(false);
            return;
        }
        setIsSorting(false);
        onSortEnd?.(sort, event);
    }

    return (
        <PlacesConfigurator
            lockAxis="y"
            distance={1}
            placeSelectedIndex={props.placeSelectedIndex}
            showToll={props.showToll}
            helperClass="places-configurator-new-place-dragged"
            shouldCancelStart={shouldCancelStart}
            onSortStart={handleSortStart}
            onSortEnd={handleSortEnd}
            sorting={sorting}
            {...props}
        />
    );
};

export default withLogicContext(observer(SortedPlacesConfigurator));
