/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDeviceType,
    ExternalDeviceTypeFromJSON,
    ExternalDeviceTypeFromJSONTyped,
    ExternalDeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoredObjectFleetDynamicDataExternalDevices
 */
export interface MonitoredObjectFleetDynamicDataExternalDevices {
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectFleetDynamicDataExternalDevices
     */
    deviceId?: string;
    /**
     * 
     * @type {ExternalDeviceType}
     * @memberof MonitoredObjectFleetDynamicDataExternalDevices
     */
    deviceType: ExternalDeviceType;
}

export function MonitoredObjectFleetDynamicDataExternalDevicesFromJSON(json: any): MonitoredObjectFleetDynamicDataExternalDevices {
    return MonitoredObjectFleetDynamicDataExternalDevicesFromJSONTyped(json, false);
}

export function MonitoredObjectFleetDynamicDataExternalDevicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectFleetDynamicDataExternalDevices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'deviceType': ExternalDeviceTypeFromJSON(json['device_type']),
    };
}

export function MonitoredObjectFleetDynamicDataExternalDevicesToJSON(value?: MonitoredObjectFleetDynamicDataExternalDevices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'device_type': ExternalDeviceTypeToJSON(value.deviceType),
    };
}


