import React, { ReactNode } from 'react';
import { Radio } from 'antd';
import { RadioGroupProps, RadioProps } from 'antd/lib/radio';
import cn from 'classnames';
import { Control } from '../Control';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
interface Props extends RadioProps {
    qa?: string;
    horizontal?: boolean;
}

function EnhancedRadio({ qa, ...props }: Props) {
    return (
        <Radio
            className={cn({
                'ant-radio-horizontal': props.horizontal
            })}
            data-qa={qa}
            {...props}
        />
    );
}

export interface EnhancedRadioGroupProps extends RadioGroupProps {
    horizontal?: boolean;
    label?: ReactNode;
    error?: ReactNode;
    qa?: string;
    disabled?: boolean;
}

EnhancedRadio.Group = function ({ label, error, qa, ...props }: EnhancedRadioGroupProps) {
    return (
        <Control label={label} error={error} data-qa={qa}>
            <Radio.Group
                className={cn({
                    'ant-radio-horizontal': props.horizontal
                })}
                {...props}
            />
        </Control>
    );
};

interface ButtonProps extends RadioButtonProps {
    qa?: string;
}

EnhancedRadio.Button = function ({ qa, ...props }: ButtonProps) {
    return <Radio data-qa={qa} {...props} />;
};

export default EnhancedRadio;
