/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TCOOutputDetails,
    TCOOutputDetailsFromJSON,
    TCOOutputDetailsFromJSONTyped,
    TCOOutputDetailsToJSON,
    TCOOutputDetailsDiesel,
    TCOOutputDetailsDieselFromJSON,
    TCOOutputDetailsDieselFromJSONTyped,
    TCOOutputDetailsDieselToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCOOutputTotalCosts
 */
export interface TCOOutputTotalCosts {
    /**
     * 
     * @type {TCOOutputDetails}
     * @memberof TCOOutputTotalCosts
     */
    lngVehicleDetails: TCOOutputDetails;
    /**
     * 
     * @type {TCOOutputDetailsDiesel}
     * @memberof TCOOutputTotalCosts
     */
    dieselVehicleDetails: TCOOutputDetailsDiesel;
}

export function TCOOutputTotalCostsFromJSON(json: any): TCOOutputTotalCosts {
    return TCOOutputTotalCostsFromJSONTyped(json, false);
}

export function TCOOutputTotalCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCOOutputTotalCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lngVehicleDetails': TCOOutputDetailsFromJSON(json['lng_vehicle_details']),
        'dieselVehicleDetails': TCOOutputDetailsDieselFromJSON(json['diesel_vehicle_details']),
    };
}

export function TCOOutputTotalCostsToJSON(value?: TCOOutputTotalCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lng_vehicle_details': TCOOutputDetailsToJSON(value.lngVehicleDetails),
        'diesel_vehicle_details': TCOOutputDetailsDieselToJSON(value.dieselVehicleDetails),
    };
}


