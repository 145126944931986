import { WithTranslation, withTranslation } from 'react-i18next';
import moment from 'moment';

import { Col, Row } from 'antd';
import { Card } from 'common/components';
import { BorderCrossTwoDirections } from 'generated/backend-api';
import { getCountryName } from 'common/model/countries';
import * as borderCrossesIcon from 'resources/images/border-crosses';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    data: BorderCrossTwoDirections;
}

function BorderCrossesInfobox(props: Props) {
    const { t } = props;
    const getTime = (minutes: number = 0): string => {
        if (minutes < 60) {
            return `${minutes}m`;
        } else {
            const hours = Math.floor(minutes / 60);
            const min = minutes - hours * 60;
            return `${hours}:${min > 0 ? min : '00'}h`;
        }
    };

    const getDelay = (delay: number = 0): string => {
        if (delay > 90) {
            return 'red';
        } else if (delay <= 90 && delay >= 30) {
            return 'orange';
        } else {
            return 'green';
        }
    };

    const validateData = (dataTime: Date | undefined): boolean => {
        if (dataTime && moment(dataTime).isAfter(moment().subtract(360, 'minutes'))) {
            return true;
        } else {
            return false;
        }
    };

    const direction1Color = validateData(props.data.direction1?.lastData)
        ? getDelay(props.data.direction1?.delay)
        : 'gray';
    const direction2Color = validateData(props.data.direction2?.lastData)
        ? getDelay(props.data.direction2?.delay)
        : 'gray';

    return (
        <div className="map-border-crosses-infowindow" data-qa={qa.map.borderCrossingInfoWidnow}>
            <Card
                title={
                    props.data.names.length > 0
                        ? props.data.names.toString().replace(',', ' - ')
                        : props.data.direction1?.countries
                              .map(country => getCountryName(country))
                              .toString()
                              .replace(',', ' - ')
                }
                type="inner"
                bordered={false}
            >
                <Row align="middle">
                    <Col className={`delay-${direction1Color}`}>
                        <Row className="map-border-crosses-infowindow-country">
                            {getCountryName(
                                props.data.direction1?.countries.find(
                                    country => country !== props.data.direction1?.countryExit
                                )
                            )}
                            <img src={borderCrossesIcon[`${direction1Color}Vector`]} alt="" />
                            {getCountryName(props.data.direction1?.countryExit)}
                        </Row>
                    </Col>
                    <Col flex="auto">
                        <Row align="middle" justify="end" className="map-border-crosses-infowindow-waiting-time">
                            {validateData(props.data.direction1?.lastData) ? (
                                <>
                                    {t('BorderCrossesInfoWindow.waitingTime')}:
                                    <div className="time">{getTime(props.data.direction1?.delay)}</div>
                                </>
                            ) : (
                                t('BorderCrossesInfoWindow.noValidData')
                            )}
                        </Row>
                    </Col>
                </Row>
                {props.data.direction2 && (
                    <>
                        <hr />
                        <Row align="middle">
                            <Col className={`delay-${direction2Color}`}>
                                <Row className="map-border-crosses-infowindow-country">
                                    {getCountryName(
                                        props.data.direction2?.countries.find(
                                            country => country !== props.data.direction2?.countryExit
                                        )
                                    )}
                                    <img src={borderCrossesIcon[`${direction2Color}Vector`]} alt="" />
                                    {getCountryName(props.data.direction2?.countryExit)}
                                </Row>
                            </Col>
                            <Col flex="auto">
                                <Row
                                    align="middle"
                                    justify="end"
                                    className="map-border-crosses-infowindow-waiting-time"
                                >
                                    {validateData(props.data.direction2?.lastData) ? (
                                        <>
                                            {t('BorderCrossesInfoWindow.waitingTime')}:
                                            <div className="time">{getTime(props.data.direction2?.delay)}</div>
                                        </>
                                    ) : (
                                        t('BorderCrossesInfoWindow.noValidData')
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
            <div className="arrow" />
        </div>
    );
}

export default withTranslation()(BorderCrossesInfobox);
