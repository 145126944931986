/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    ReadOnlyCurrency,
    ReadOnlyCurrencyFromJSON,
    ReadOnlyCurrencyToJSON,
    WriteOnlyCurrency,
    WriteOnlyCurrencyFromJSON,
    WriteOnlyCurrencyToJSON,
} from '../models';

export interface CurrencyCreateRequest {
    data: WriteOnlyCurrency;
}

export interface CurrencyDeleteRequest {
    id: number;
}

export interface CurrencyListRequest {
    name?: string;
    code?: string;
    limit?: number;
    offset?: number;
}

export interface CurrencyPartialUpdateRequest {
    id: number;
    data: WriteOnlyCurrency;
}

export interface CurrencyReadRequest {
    id: number;
}

export interface CurrencyUpdateRequest {
    id: number;
    data: WriteOnlyCurrency;
}

/**
 * no description
 */
export class CurrencyApi extends runtime.BaseAPI {

    /**
     */
    async currencyCreateRaw(requestParameters: CurrencyCreateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrency>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async currencyCreate(requestParameters: CurrencyCreateRequest): Promise<ReadOnlyCurrency> {
        const response = await this.currencyCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyDeleteRaw(requestParameters: CurrencyDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyCurrency>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async currencyDelete(requestParameters: CurrencyDeleteRequest): Promise<ReadOnlyCurrency> {
        const response = await this.currencyDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyListRaw(requestParameters: CurrencyListRequest): Promise<runtime.ApiResponse<InlineResponse2006>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     */
    async currencyList(requestParameters: CurrencyListRequest): Promise<InlineResponse2006> {
        const response = await this.currencyListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyPartialUpdateRaw(requestParameters: CurrencyPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrency>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async currencyPartialUpdate(requestParameters: CurrencyPartialUpdateRequest): Promise<ReadOnlyCurrency> {
        const response = await this.currencyPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyReadRaw(requestParameters: CurrencyReadRequest): Promise<runtime.ApiResponse<ReadOnlyCurrency>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async currencyRead(requestParameters: CurrencyReadRequest): Promise<ReadOnlyCurrency> {
        const response = await this.currencyReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async currencyUpdateRaw(requestParameters: CurrencyUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCurrency>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling currencyUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling currencyUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/currency/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCurrencyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async currencyUpdate(requestParameters: CurrencyUpdateRequest): Promise<ReadOnlyCurrency> {
        const response = await this.currencyUpdateRaw(requestParameters);
        return await response.value();
    }

}
