import { AetrFilterModel, AetrModel } from '../AetrModule';
import AetrTable from './AetrTable';
import AetrBar from './AetrBar';
import { Role } from 'logic/auth';
import HelperModal from 'common/components/HelperModal';

interface Props {
    bar: {
        filterOpen?: boolean;
    };
    search?: { text: string };
    filter: AetrFilterModel;
    helper?: {
        content: string;
    };
    table: {
        data: AetrModel[];
        previous?: AetrModel[];
        openedWeeks: string[];
        openedDays: string[];
        loading: boolean;
    };
    roles: Role[];
    demoMode?: boolean;
    onSearch?: () => void;
    onBarSearchChange?: (text: string) => void;
    onBarSearchEsc?: () => void;
    onBarFilterClick?: () => void;
    onBarHelperClick?: () => void;
    onBarResetClick?: () => void;
    onFilterCancel?: () => void;
    onFilterConfirm?: (driversChecked: string[], vehiclesChecked: string[]) => void;
    onHelperClose?: () => void;
    onTableWeeksExpand?: (keyWeek: string, tachographCard: string) => void;
    onTableDaysExpand?: (keyDay: string, tachographCard: string, week: string) => void;
}

export function Aetr(props: Props) {
    return (
        <div className="aetr page t-default">
            <AetrBar
                search={props.search}
                filter={props.filter}
                filterOpen={props.bar.filterOpen}
                roles={props.roles}
                onFilterClick={props.onBarFilterClick}
                onHelperClick={props.onBarHelperClick}
                onResetClick={props.onBarResetClick}
                onSearchChange={props.onBarSearchChange}
                onSearchEsc={props.onBarSearchEsc}
                onFilterConfirm={props.onFilterConfirm}
                onFilterCancel={props.onFilterCancel}
            />

            {props.table && (
                <AetrTable
                    data={props.table.data}
                    previous={props.table.previous}
                    openedWeeks={props.table.openedWeeks}
                    openedDays={props.table.openedDays}
                    loading={props.table.loading}
                    onWeeksExpand={props.onTableWeeksExpand}
                    onDaysExpand={props.onTableDaysExpand}
                />
            )}

            <HelperModal
                name="aetr"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}
