import { Logic } from 'logic/logic';

export class StatisticsColdchainLogic {
    constructor(private _logic: Logic) {}

    async getColdchain() {
        try {
            return undefined;
        } catch (err) {
            console.error('Get maintenance task list err', err);
            throw err;
        }
    }

    settings(): {
        vehicles: string[];
        trailers: string[];
        profiles: string[];
    } {
        const settings = this._logic.settings().getProp('statistics').coldChain?.filter;
        const filter = {
            vehicles: settings?.vehicles ?? [],
            trailers: settings?.trailers ?? [],
            profiles: settings?.profiles ?? []
        };
        return filter;
    }

    setSettings = (settings: { vehicles?: string[]; trailers?: string[]; profiles?: string[] }) => {
        const originalSettings = this._logic.settings().getProp('statistics').coldChain?.filter;
        const modifiedSettings = {
            vehicles: settings.vehicles ?? originalSettings?.vehicles ?? [],
            trailers: settings.trailers ?? originalSettings?.trailers ?? [],
            profiles: settings.profiles ?? originalSettings?.profiles ?? []
        };
        this._logic.settings().setProp('statistics', {
            ...this._logic.settings().getProp('statistics'),
            coldChain: {
                filter: modifiedSettings
            }
        });
    };

    destroy() {}
}
