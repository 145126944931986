/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PerfectDriveDriver,
    PerfectDriveDriverFromJSON,
    PerfectDriveDriverFromJSONTyped,
    PerfectDriveDriverToJSON,
} from './';

/**
 * Represents perfect drive data for a driver enriched by - ranking - number of drivers in - ...
 * @export
 * @interface PerfectDrivePeriod
 */
export interface PerfectDrivePeriod {
    /**
     * 
     * @type {string}
     * @memberof PerfectDrivePeriod
     */
    month: string;
    /**
     * 
     * @type {Array<PerfectDriveDriver>}
     * @memberof PerfectDrivePeriod
     */
    driverData?: Array<PerfectDriveDriver>;
    /**
     * 
     * @type {number}
     * @memberof PerfectDrivePeriod
     */
    numberOfDrivers?: number;
}

export function PerfectDrivePeriodFromJSON(json: any): PerfectDrivePeriod {
    return PerfectDrivePeriodFromJSONTyped(json, false);
}

export function PerfectDrivePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfectDrivePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'driverData': !exists(json, 'driver_data') ? undefined : ((json['driver_data'] as Array<any>).map(PerfectDriveDriverFromJSON)),
        'numberOfDrivers': !exists(json, 'number_of_drivers') ? undefined : json['number_of_drivers'],
    };
}

export function PerfectDrivePeriodToJSON(value?: PerfectDrivePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'driver_data': value.driverData === undefined ? undefined : ((value.driverData as Array<any>).map(PerfectDriveDriverToJSON)),
        'number_of_drivers': value.numberOfDrivers,
    };
}


