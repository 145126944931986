/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CostUnit,
    CostUnitFromJSON,
    CostUnitFromJSONTyped,
    CostUnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperationalCost
 */
export interface OperationalCost {
    /**
     * 
     * @type {string}
     * @memberof OperationalCost
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof OperationalCost
     */
    value: number;
    /**
     * 
     * @type {CostUnit}
     * @memberof OperationalCost
     */
    unit: CostUnit;
}

export function OperationalCostFromJSON(json: any): OperationalCost {
    return OperationalCostFromJSONTyped(json, false);
}

export function OperationalCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationalCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'value': json['value'],
        'unit': CostUnitFromJSON(json['unit']),
    };
}

export function OperationalCostToJSON(value?: OperationalCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'value': value.value,
        'unit': CostUnitToJSON(value.unit),
    };
}


