import { ReadOnlyVehicleProfileSerializerEuroClassEnum } from 'generated/new-main';
import { VehicleProfile } from 'modules/routing/planner-new/planner-logic';

export const vehicleProfiles: VehicleProfile[] = [
    {
        id: 1001,
        isDefault: false,
        euroClass: ReadOnlyVehicleProfileSerializerEuroClassEnum.EEV,
        numberOfAxles: 5,
        weight: 2,
        name: 'Mercedes profile',
        height: 4,
        length: 16.5,
        width: 2.55,
        pricePerKm: 1.2,
        trailersCount: 1,
        tunnel: 'A'
    },
    {
        id: 1002,
        isDefault: false,
        euroClass: ReadOnlyVehicleProfileSerializerEuroClassEnum.EUROI,
        numberOfAxles: 4,
        weight: 1,
        name: 'MANN profile',
        height: 4,
        length: 16.5,
        width: 2.55,
        pricePerKm: 2,
        trailersCount: 1,
        tunnel: 'A'
    },
    {
        id: 1003,
        isDefault: false,
        euroClass: '',
        numberOfAxles: 4,
        weight: 8,
        name: 'Scania profile',
        height: 3.7,
        length: 14.5,
        width: 2.32,
        pricePerKm: 1.7,
        trailersCount: 2,
        tunnel: 'B'
    },
    {
        id: 1004,
        isDefault: true,
        euroClass: ReadOnlyVehicleProfileSerializerEuroClassEnum.EUROVI,
        numberOfAxles: 5,
        weight: 40,
        name: 'default_vehicle_profile_truck_40t',
        height: 4,
        length: 16.5,
        width: 2.55,
        pricePerKm: 1.3,
        trailersCount: 1,
        tunnel: 'A'
    }
];
