/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChannelMessagesResponse
 */
export interface ChannelMessagesResponse {
    /**
     * 
     * @type {Array<Message>}
     * @memberof ChannelMessagesResponse
     */
    messages: Array<Message>;
    /**
     * 
     * @type {string}
     * @memberof ChannelMessagesResponse
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelMessagesResponse
     */
    channel: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelMessagesResponse
     */
    timestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof ChannelMessagesResponse
     */
    count: number;
}

export function ChannelMessagesResponseFromJSON(json: any): ChannelMessagesResponse {
    return ChannelMessagesResponseFromJSONTyped(json, false);
}

export function ChannelMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelMessagesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'user': json['user'],
        'channel': json['channel'],
        'timestamp': (new Date(json['timestamp'])),
        'count': json['count'],
    };
}

export function ChannelMessagesResponseToJSON(value?: ChannelMessagesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': ((value.messages as Array<any>).map(MessageToJSON)),
        'user': value.user,
        'channel': value.channel,
        'timestamp': (value.timestamp.toISOString()),
        'count': value.count,
    };
}


