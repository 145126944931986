import { Col, Row } from 'antd';
import { AvailableCurrencies } from 'common/model/currency';
import { M, T } from 'domain-constants';
import numeral from 'numeral';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VehicleProfile } from '../../planner-logic';

interface Props extends WithTranslation {
    profile: VehicleProfile;
    currency?: AvailableCurrencies;
}

const VehicleProfileDetail = (props: Props) => {
    const { t } = props;
    const name = props.profile.isDefault ? t('VehicleProfile.defaultProfileName') : props.profile.name;
    return (
        <div className="vehicle-profile-info">
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.name')}</Col>
                <Col span={12}>{name}</Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.tunnel')}</Col>
                <Col span={12} flex="end">
                    {props.profile.tunnel}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.trailerCount')}</Col>
                <Col span={12}>{props.profile.trailersCount}</Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.numberOfAxles')}</Col>
                <Col span={12}>{props.profile.numberOfAxles}</Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.pricePerKm')}</Col>
                <Col span={12}>
                    {numeral(props.profile.pricePerKm).format('0,0.00')} {props.currency}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.length')}</Col>
                <Col span={12}>
                    {props.profile.length} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.height')}</Col>
                <Col span={12}>
                    {props.profile.height} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.width')}</Col>
                <Col span={12}>
                    {props.profile.width} {M}
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>{t('VehicleProfileForm.weight')}</Col>
                <Col span={12}>
                    {props.profile.weight} {T}
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(VehicleProfileDetail);
