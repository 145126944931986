/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiemsRateForInterval,
    DiemsRateForIntervalFromJSON,
    DiemsRateForIntervalFromJSONTyped,
    DiemsRateForIntervalToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiemsRulesPLFixedRatesRes
 */
export interface DiemsRulesPLFixedRatesRes {
    /**
     * 
     * @type {Array<DiemsRateForInterval>}
     * @memberof DiemsRulesPLFixedRatesRes
     */
    foreignRates: Array<DiemsRateForInterval>;
    /**
     * 
     * @type {Array<DiemsRateForInterval>}
     * @memberof DiemsRulesPLFixedRatesRes
     */
    domesticRates: Array<DiemsRateForInterval>;
    /**
     * 
     * @type {Array<DiemsRateForInterval>}
     * @memberof DiemsRulesPLFixedRatesRes
     */
    domesticUnfinishedDaysInLongTrips: Array<DiemsRateForInterval>;
    /**
     * 
     * @type {string}
     * @memberof DiemsRulesPLFixedRatesRes
     */
    foreignCurrency?: DiemsRulesPLFixedRatesResForeignCurrencyEnum;
}

export function DiemsRulesPLFixedRatesResFromJSON(json: any): DiemsRulesPLFixedRatesRes {
    return DiemsRulesPLFixedRatesResFromJSONTyped(json, false);
}

export function DiemsRulesPLFixedRatesResFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiemsRulesPLFixedRatesRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'foreignRates': ((json['foreign_rates'] as Array<any>).map(DiemsRateForIntervalFromJSON)),
        'domesticRates': ((json['domestic_rates'] as Array<any>).map(DiemsRateForIntervalFromJSON)),
        'domesticUnfinishedDaysInLongTrips': ((json['domestic_unfinished_days_in_long_trips'] as Array<any>).map(DiemsRateForIntervalFromJSON)),
        'foreignCurrency': !exists(json, 'foreign_currency') ? undefined : json['foreign_currency'],
    };
}

export function DiemsRulesPLFixedRatesResToJSON(value?: DiemsRulesPLFixedRatesRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'foreign_rates': ((value.foreignRates as Array<any>).map(DiemsRateForIntervalToJSON)),
        'domestic_rates': ((value.domesticRates as Array<any>).map(DiemsRateForIntervalToJSON)),
        'domestic_unfinished_days_in_long_trips': ((value.domesticUnfinishedDaysInLongTrips as Array<any>).map(DiemsRateForIntervalToJSON)),
        'foreign_currency': value.foreignCurrency,
    };
}

/**
* @export
* @enum {string}
*/
export enum DiemsRulesPLFixedRatesResForeignCurrencyEnum {
    EUR = 'EUR',
    PLN = 'PLN'
}


