import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { JourneysSumModel } from '../JourneysModule';
import { KM, L, H, M, KG } from 'domain-constants';
import numeral from 'numeral';
import { Role } from 'logic/auth';
import { Button } from 'common/components';
import qa from 'qa-selectors';
import { MoneyEuroFilled } from 'resources/images/journeys-activity';

interface Props extends WithTranslation {
    sum: JourneysSumModel;
    roles: Role[];
    onShowExpensesClick: () => void;
    onShowFuelClick: () => void;
}

const distance = (distance: number) => distance / 1000;
const timeFromTimestamp = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    return `${hours < 10 ? '0' : ''}${hours}:${min < 10 ? '0' : ''}${min}`;
};

function JourneysSum(props: Props) {
    function onShowExpenses() {
        props.onShowExpensesClick?.();
    }

    function onShowFuelClick() {
        props.onShowFuelClick?.();
    }

    const { t, sum } = props;
    return (
        <>
            <div className="journeys-sum-title">{t('common.summary')} </div>
            <div className="journeys-sum t-default t-bar">
                <table className="journeys-sum-table t-table t-bar-item">
                    <thead>
                        <tr>
                            <th className="t-center" title={t('JourneysTable.duration')}>
                                {t('JourneysTable.duration')}
                                <div className="units">
                                    ({H}:{M})
                                </div>
                            </th>
                            <th className="t-center" title={t('JourneysTable.stopped')}>
                                {t('JourneysTable.stopped')}
                                <div className="units">
                                    ({H}:{M})
                                </div>
                            </th>
                            <th className="t-center" title={t('JourneysTable.distance')}>
                                {t('JourneysTable.distance')}
                                <div className="units">({KM})</div>
                            </th>
                            <th className="t-center" title={t('JourneysTable.odometerEnd')}>
                                {t('JourneysTable.odometerEnd')}
                                <div className="units">({KM})</div>
                            </th>
                            <th className="t-center" title={t('JourneysTable.AFCL100km')}>
                                {t('JourneysTable.AFCL100km')}
                                <div className="units">
                                    ({L} / 100{KM})
                                </div>
                            </th>
                            <th className="t-center" title={t('JourneysTable.consumption')}>
                                {t('JourneysTable.consumption')}
                                <div className="units">
                                    <div className="units">({L})</div>
                                </div>
                            </th>
                            {(sum.units ?? 0) > 0 && (
                                <th className="t-center" title={t('JourneysTable.refueledLitres')}>
                                    {t('JourneysTable.refueledLitres')}
                                    <div className="units"> ({L})</div>
                                </th>
                            )}
                            <th className="t-center" title={t('JourneysTable.refueledLitres')}>
                                {t('JourneysTable.co2')}
                                <div className="units">({KG})</div>
                            </th>
                            {sum.privateDistance ? (
                                <>
                                    <th className="t-center" title={t('JourneysTable.businessDistance')}>
                                        {t('JourneysTable.businessDistance')}
                                        <div className="units"> {KM}</div>
                                    </th>
                                    <th className="t-center" title={t('JourneysTable.privateDistance')}>
                                        {t('JourneysTable.privateDistance')}
                                        <div className="units"> {KM}</div>
                                    </th>
                                </>
                            ) : (
                                <></>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldDuration}>
                                {timeFromTimestamp(sum.duration)}
                            </td>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldStopped}>
                                {timeFromTimestamp(sum.stopped)}
                            </td>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldDistance}>
                                {numeral(distance(sum.distance)).format('0,0.0')}
                            </td>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldOdometerEnd}>
                                {numeral(distance(sum.odometerEnd)).format('0,0')}
                            </td>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldAfc}>
                                {numeral(sum.afcL100Km).format('0,0.0')}
                            </td>
                            <td className="t-center" data-qa={qa.journeys.summary.fieldConsumption}>
                                {numeral(sum.consumption).format('0,0.0')}
                            </td>
                            {(sum.units ?? 0) > 0 && (
                                <td className="t-center" data-qa={qa.journeys.summary.fieldUnits}>
                                    {numeral(sum.units).format('0,0.00')}
                                </td>
                            )}
                            <td className="t-center" data-qa={qa.journeys.summary.fieldCo2}>
                                {numeral(sum.co2Emission / 1000).format('0,0.0')}
                            </td>
                            {sum.privateDistance ? (
                                <>
                                    <td className="t-center" data-qa={qa.journeys.summary.fieldBusinessDistance}>
                                        {numeral(distance(sum.businessDistance ?? 0)).format('0,0.00')}
                                    </td>
                                    <td className="t-center" data-qa={qa.journeys.summary.fieldPrivateDistance}>
                                        {numeral(distance(sum.privateDistance)).format('0,0.00')}
                                    </td>
                                </>
                            ) : (
                                <></>
                            )}
                        </tr>
                    </tbody>
                </table>

                <div className="journeys-sum-buttons">
                    <Button
                        type="default"
                        onClick={onShowExpenses}
                        disabled={![Role.EX_R, Role.JA_W].every(r => props.roles.includes(r))}
                        title={t('JourneysTable.showExpenses')}
                        qa={qa.journeys.summary.btnExpenses}
                        icon={<img src={MoneyEuroFilled} alt="money-eur" />}
                    />
                    <Button
                        type="default"
                        onClick={onShowFuelClick}
                        title={t('JourneysTable.showFuelConsumption')}
                        disabled={![Role.FC_R, Role.JA_W].every(r => props.roles.includes(r))}
                        qa={qa.journeys.summary.btnFuelConsumption}
                        icon={<i className="fas fa-fw fa-gas-pump" />}
                    />
                </div>
            </div>
        </>
    );
}

export default withTranslation()(JourneysSum);
