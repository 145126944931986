import { AuthConf, Role, User } from './auth';
import { EWClient } from '../generated/main-data-api';
import { PartnerCompanyModel, BillingCycle, CompanyType } from './partner/logic/partner-partners';
import moment from 'moment';
import { AvailableCurrencies } from 'common/model/currency';

export class AuthMock {
    private _token: string;
    private _conf: AuthConf;

    keycloak = {
        tokenParsed: {
            sub: ''
        }
    };

    constructor(conf: AuthConf) {
        this._conf = conf;
        this._token = conf.mockedSSO?.token;
    }

    loadNewClient() {}

    loadUser() {}

    newUser() {}

    init() {
        return new Promise<boolean>(resolve => {
            resolve(true);
        });
    }

    client(): EWClient {
        return {
            id: 2,
            geocodingLanguages: ['en'] as any[],
            language: 'en' as any
        };
    }

    newClient(): PartnerCompanyModel {
        return {
            billingCode: '90',
            billingCycle: BillingCycle.Monthly,
            companyType: CompanyType.Individual,
            creationDate: moment(),
            currency: AvailableCurrencies.EUR,
            discount: 0,
            id: 'd',
            name: '',
            identificationNumber: '',
            terminationDate: moment(),
            vatNumber: '',
            vehiclesNo: 0,
            address: []
        };
    }

    loadClient(): Promise<void> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 10);
        });
    }

    impersonator() {
        return true;
    }

    impersonate() {}

    login() {}

    updateToken() {
        return new Promise<string>(resolve => {
            resolve(this._token);
        });
    }

    logout() {
        return new Promise<void>(resolve => {
            resolve();
        });
    }

    roles(): Role[] {
        return Object.values(Role).filter(role => role !== Role.DM_RD);
    }

    superadmin() {
        return false;
    }

    token() {
        return this._token;
    }

    user(): User {
        return {
            id: '5544',
            name: 'Eurowag mock',
            email: 'ew@ew.com',
            lang: 'en',
            roles: this.roles()
        };
    }

    userProfile() {
        return new Promise<void>(resolve => {
            resolve();
        });
    }
}
