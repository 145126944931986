/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TCOInput,
    TCOInputFromJSON,
    TCOInputToJSON,
    TCOOutput,
    TCOOutputFromJSON,
    TCOOutputToJSON,
    TCOSettingsOutput,
    TCOSettingsOutputFromJSON,
    TCOSettingsOutputToJSON,
} from '../models';

export interface TcoCalculateRequest {
    data: TCOInput;
}

/**
 * no description
 */
export class TcoApi extends runtime.BaseAPI {

    /**
     */
    async tcoCalculateRaw(requestParameters: TcoCalculateRequest): Promise<runtime.ApiResponse<TCOOutput>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling tcoCalculate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tco/calculate/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCOInputToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TCOOutputFromJSON(jsonValue));
    }

    /**
     */
    async tcoCalculate(requestParameters: TcoCalculateRequest): Promise<TCOOutput> {
        const response = await this.tcoCalculateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async tcoGetSettingsRaw(): Promise<runtime.ApiResponse<TCOSettingsOutput>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tco/settings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TCOSettingsOutputFromJSON(jsonValue));
    }

    /**
     */
    async tcoGetSettings(): Promise<TCOSettingsOutput> {
        const response = await this.tcoGetSettingsRaw();
        return await response.value();
    }

}
