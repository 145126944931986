import moment from 'moment';
import cn from 'classnames';
import { Button } from 'common/components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'domain-constants';
import { calendar, swapRight } from 'resources/images/common';
import { Col, Row } from 'antd';
import { ReactNode } from 'react';

interface Props {
    dateRange?: {
        start: string;
        end: string;
    };
    customLabel?: ReactNode;
    changed?: boolean;
    format?: string;
    withTime?: boolean;
    qa?: string;
    size?: 'small' | 'large';
}

export default function ButtonDateRangeTimeTrigger({ ...props }: Props) {
    return (
        <Button
            type="default"
            className={cn([
                'button-date-range-picker',
                {
                    'button-date-range-picker-changed': props.changed,
                    'button-date-range-picker-small': props.size === 'small',
                    'button-date-range-picker-large': props.size === 'large'
                }
            ])}
            qa={props.qa}
        >
            <Row justify="space-between" align="middle">
                <Col>
                    {props.dateRange && (
                        <Row gutter={6}>
                            <Col>
                                {moment(props.dateRange.start, props.withTime ? DATE_TIME_FORMAT : DATE_FORMAT).format(
                                    props.format ?? 'L'
                                )}
                            </Col>
                            <Col>
                                <img src={swapRight} className="calendar" alt="swap" />
                            </Col>
                            <Col>
                                {moment(props.dateRange.end, props.withTime ? DATE_TIME_FORMAT : DATE_FORMAT).format(
                                    props.format ?? 'L'
                                )}
                            </Col>
                        </Row>
                    )}
                    {props.customLabel}
                </Col>
                <Col>
                    <img src={calendar} className="calendar" alt="calendar" />
                </Col>
            </Row>
        </Button>
    );
}
