import { interval, Observable, Subject, Subscription } from 'rxjs';
import { buffer } from 'rxjs/operators';
import moment from 'moment';
import { TransportState, Transport, TransportPlace } from 'generated/backend-api';
import {
    GetProposalsV3ProposalsMyGetRequest,
    NegotiationState,
    ProposalForClient,
    ProposalForClientFromJSON,
    UnitCodeFluids
} from 'generated/dff-api';
import { EventRuleNameType, PlaceType } from 'generated/graphql';

import { Logic } from './logic';
import { PlacesModel, TransportModel } from 'common/model/transports';
import { toGeoJsonPointTypeInput, toGeoJsonPolygonTypeInput } from 'common/utils/geo-utils';
import { AvailableCurrencies } from 'common/model/currency';
import { NotificationMessage } from 'logic/notification-eio';
import { getCountryIso2FromIso3 } from 'common/model/countries';
import i18n from 'i18next';
import notification, { NotificationPlacement } from 'antd/lib/notification';

export interface TransEUCompanyInfo {
    name?: string;
    employeeCount?: string;
    address?: string;
    vatNumber?: string;
    paymentStatus?: string;
    contactNumber?: string;
    contactPerson?: string;
    email?: string;
    taxNumber?: string;
}

export class DffProposalsLogic {
    readonly proposalsUpdates: Subject<ProposalForClient[]>;
    readonly logic: Logic;

    private _proposals: ProposalForClient[];
    private _updatesInterval = 10e3;
    private _monitoredVehicles: number[] = [];
    private _proposalUpdateSub?: Subscription;
    private _proposalsUpdateCb?: (n: NotificationMessage<any>) => void;

    constructor(logic: Logic) {
        this.logic = logic;
        this._proposals = [];
        this.proposalsUpdates = new Subject<ProposalForClient[]>();
    }

    get proposals() {
        return this._proposals;
    }

    /**
     * Inits proposals, watch for updates and resubscribe on notification api reconnect
     */
    init(vehicles: number[]) {
        this._monitoredVehicles = vehicles;

        if (this.logic.demo().isActive) {
            this._proposals = this.logic.demo().data.proposals;
            this.proposalsUpdates.next(this.proposals);
            this._watchProposalsUpdates();
            return;
        }

        this._getAndSubscribeToProposals();

        this.logic
            .notification()
            .onConnect()
            .subscribe(() => {
                this._getAndSubscribeToProposals();
            });

        this._watchProposalsUpdates();
    }

    async getRouteFormFirstToLastPlace(transport: TransportModel): Promise<{ route: string; distance: number }> {
        let api = undefined;
        // TODO: This will be replaced by route from DFF this is just POC
        switch (this.logic.conf.api?.routing?.service) {
            case 'ptv':
                api = this.logic.api().routingApi.ptvDirections.bind(this.logic.api().routingApi);
                break;
            case 'here':
                api = this.logic.api().routingApi.hereDirections.bind(this.logic.api().routingApi);
                break;
            default:
                api = this.logic.api().routingApi.sygicCoreDirections.bind(this.logic.api().routingApi);
        }
        const [route] = await api({
            routePlannerRequestBody: {
                wayPoints: transport.places.map(p => ({
                    id: p.id,
                    departure: p.rta,
                    lat: p.center.lat,
                    lng: p.center.lng,
                    name: p.name
                })),
                profile: undefined
            }
        });
        const lastPlace = route.places[route.places.length - 1];

        if (lastPlace.route && lastPlace.distance) {
            return { route: lastPlace.route, distance: lastPlace.distance };
        }

        throw new Error('Unable to plan transport');
    }

    dffProposalToTransportModel(dffT: ProposalForClient, companyInfo?: TransEUCompanyInfo): TransportModel {
        const toPickup = dffT.pickup;
        const toDelivery = dffT.delivery?.[dffT.delivery.length - 1];
        const distance = toPickup?.distanceKm ? toPickup?.distanceKm * 1e3 : 0; //km to m
        const transportDistance = toDelivery?.distanceKm ? toDelivery?.distanceKm * 1e3 : 0; //km to m
        const cost = toPickup?.expenseItems.reduce((sum, current) => sum + current.value, 0) || 0;
        const transportCosts = toDelivery?.expenseItems.reduce((sum, current) => sum + current.value, 0) || 0;
        const costPerKm = transportDistance ? (transportCosts ?? 0) / (transportDistance / 1e3) : 0;
        const totalDuration = (toDelivery?.duration?.driving ?? 0) + (toPickup?.duration?.driving ?? 0);
        const trailer = 'trailer';
        const pickupDuration = (toPickup?.duration?.driving ?? 0) / 1e3;

        const score = dffT.score ?? this._getScore(dffT);
        const stars = dffT.scoreStars ?? this._getRatingInStars(score);

        const profit = this.logic.demo().isActive
            ? this.logic.demo().data.demoProposals.find(p => p.id === +dffT.proposalId)?.profit
            : undefined;

        const finalPrice = this.logic.demo().isActive
            ? this.logic.demo().data.demoProposals.find(p => p.id === +dffT.proposalId)?.final_price
            : dffT.negotiatedPrice?.value || dffT.shipment.maxPrice?.value;

        const unloadingPlace = dffT.shipment.unloadingDetails?.[dffT.shipment.unloadingDetails.length - 1];
        const loadWeightValue =
            dffT.shipment.loadingDetails?.loads.reduce((a, c) => a + (c.weight.value ?? 0), 0) * 1e3 ?? 0; //t to kg
        const loadFluidVolume = dffT.shipment.loadingDetails?.loads.reduce(
            (a, c) => a + (c.volumeFluids?.value ?? 0),
            0
        );

        return {
            id: dffT.proposalId,
            name: `proposal-${dffT.proposalId}`,
            firstPlaceRta: moment(dffT.shipment.loadingDetails?.loadingWindow.availableFrom).toISOString(),
            lastPlaceRta: moment(unloadingPlace.loadingWindow.availableUntil).toISOString(),
            vehicle: dffT.vehicle.externalId ?? dffT.vehicle.id,
            costPerKm: {
                cost: costPerKm,
                currency: AvailableCurrencies.EUR
            },
            metadata: {
                loadWeightUnit: 'TNE',
                loadWeightValue,
                distanceToTransport: distance,
                transportDistance: transportDistance,
                pickupCosts: cost,
                transportCosts,
                trailer,
                totalDuration,
                pickupDuration,
                expiration: moment(dffT.shipment.receiveProposalsUntil).toISOString(),
                score: {
                    value: score,
                    stars: stars
                },
                profit,
                finalPrice,
                timestamp: dffT.updatedAt ? moment(dffT.updatedAt).toISOString() : undefined,
                companyInfo,
                proposal: {
                    state: dffT.state,
                    load: {
                        fluidVolume: {
                            value: loadFluidVolume,
                            unit: UnitCodeFluids.Litre
                        },
                        weight: loadWeightValue
                    },
                    vehicle: {
                        ...dffT.vehicle.properties!
                    },
                    places: [dffT.shipment.loadingDetails, ...dffT.shipment.unloadingDetails],
                    useJitpay: dffT.useJitpay,
                    shipmentId: dffT.shipmentId,
                    attachments: dffT.shipment.attachments,
                    negotiableAttributes: dffT.shipment.negotiableAttributes
                }
            },
            state: TransportState.Accepted,
            users: [
                {
                    name: '',
                    surname: '',
                    id: ''
                }
            ],
            places: [
                {
                    addressStructured: [
                        {
                            country: getCountryIso2FromIso3(dffT.shipment.loadingDetails?.location.address.countryCode),
                            countryCode: getCountryIso2FromIso3(
                                dffT.shipment.loadingDetails?.location.address.countryCode
                            ), // based on data, this is country_code
                            postalCode: dffT.shipment.loadingDetails?.location.address.postalCode ?? '',
                            address: dffT.shipment.loadingDetails?.location.address.city ?? '',
                            lang: 'en',
                            route: '',
                            streetAddress: dffT.shipment.loadingDetails?.location.address.street,
                            town: dffT.shipment.loadingDetails?.location.address.city ?? ''
                        }
                    ],
                    alarms: [],
                    center: {
                        lat: dffT.shipment.loadingDetails?.location.lat ?? 0,
                        lng: dffT.shipment.loadingDetails?.location.lon ?? 0
                    },
                    id: '',
                    polygon: [
                        this.logic
                            .map()
                            .poi()
                            .getCoordinatesFromPoint(
                                dffT.shipment.loadingDetails?.location.lat ?? 0,
                                dffT.shipment.loadingDetails?.location.lon ?? 0,
                                200
                            )
                    ],
                    rta: moment(dffT.shipment.loadingDetails?.loadingWindow.availableFrom).toISOString()
                },
                {
                    addressStructured: [
                        {
                            country: getCountryIso2FromIso3(unloadingPlace?.location.address.countryCode),
                            countryCode: getCountryIso2FromIso3(unloadingPlace?.location.address.countryCode), // based on data, this is country_code
                            postalCode: unloadingPlace?.location.address.postalCode,
                            address: unloadingPlace?.location.address.city,
                            lang: 'en',
                            route: '',
                            streetAddress: unloadingPlace?.location.address.street,
                            town: unloadingPlace?.location.address.city
                        }
                    ],
                    alarms: [],
                    center: {
                        lat: unloadingPlace?.location.lat ?? 0,
                        lng: unloadingPlace?.location.lon ?? 0
                    },
                    id: '',
                    polygon: [
                        this.logic
                            .map()
                            .poi()
                            .getCoordinatesFromPoint(
                                unloadingPlace?.location.lat ?? 0,
                                unloadingPlace?.location.lon ?? 0,
                                200
                            )
                    ],
                    rta: moment(unloadingPlace.loadingWindow.availableFrom).toISOString()
                }
            ]
        };
    }

    proposalTransportToTransport(transport: TransportModel, route: string, distance: number) {
        transport.places[transport.places.length - 1].route = route;
        transport.places[transport.places.length - 1].distance = distance;
        const firstRta = transport.firstPlaceRta ? new Date(transport.firstPlaceRta) : undefined;
        const data: Transport = {
            name: (transport.name || '').replace('proposal', 'transport'),
            firstRta,
            lastRta: transport.lastPlaceRta ? new Date(transport.lastPlaceRta) : undefined,
            monitoredObjects: [
                {
                    startTime: moment.utc().toDate(),
                    endTime: undefined,
                    monitoredObjectId: Number(transport.vehicle),
                    primary: true
                }
            ],
            state: this.logic.transportLogic().getTransportState(!!transport.vehicle, transport.firstPlaceRta),
            client: transport.client,
            costPerKm: transport.costPerKm
                ? {
                      value: transport.costPerKm.cost,
                      currency: transport.costPerKm.currency
                  }
                : undefined,
            tollCost: transport.tollCost ?? undefined,
            // TODO: ET-4068 HOTFIX CHANGED ROUTE, DISTANCE DURATION 13.01.2020 MFRENAK wrong route, distance, duration saving
            places: transport.places.map((p: PlacesModel, index): TransportPlace => {
                const nextPlace: PlacesModel | undefined = transport.places[index + 1];
                return {
                    id: p.id,
                    name: p.name ?? '',
                    center: toGeoJsonPointTypeInput(p.center ?? {}),
                    polygon: toGeoJsonPolygonTypeInput(p.polygon ?? {}),
                    type: p.type ?? PlaceType.Waypoint,
                    rta: p.rta ? moment.utc(p.rta).toDate() : undefined,
                    rtd: p.rtd ? moment.utc(p.rtd).toDate() : undefined,
                    ata: p.ata ? moment.utc(p.ata).toDate() : undefined,
                    eta: p.eta ? moment.utc(p.eta).toDate() : undefined,
                    eventStates: p.eventStates
                        ? p.eventStates?.map(e => ({
                              eventRuleName: e.eventRuleName ?? '',
                              state: e.state ?? 0
                          }))
                        : [],
                    eventRules: p.alarms.map(a => ({
                        name: a.name as unknown as EventRuleNameType,
                        config: a.config ? [a.config] : []
                    })),
                    distance: nextPlace?.distance,
                    duration: nextPlace?.duration,
                    tasks: p.tasks,
                    route: nextPlace?.route,
                    addressStructured: p.addressStructured
                };
            })
        };

        return data;
    }

    destroy() {
        this._proposalUpdateSub?.unsubscribe();
        this.logic.notification().off('proposals', this._proposalsUpdateCb);
    }

    async getTransportOrderPdf(shipmentId: string, attachmentId: string) {
        return this.logic.api().dffShipmentApi.attachmentV3ProposalsShipmentId({ shipmentId, attachmentId });
    }

    /**
     * Watches for proposals updates
     */
    private _watchProposalsUpdates() {
        type BufferedUpdate<T> = {
            data: T;
            timestamp: string;
        };

        const proposalsUpdate$ = new Observable<BufferedUpdate<ProposalForClient>>(subscriber => {
            if (this.logic.demo().isActive) {
                setInterval(() => {
                    const updates = this.logic.demo().data.rawProposals.map(p => ProposalForClientFromJSON(p));
                    const timestamp = new Date().toISOString();
                    for (const update of updates) {
                        subscriber.next({
                            data: {
                                ...update,
                                updatedAt: Math.random() < 0.5 ? moment().toDate() : update.updatedAt
                            },
                            timestamp
                        });
                    }
                }, 1e3);
            } else {
                this._proposalsUpdateCb = (n: NotificationMessage<any>) => {
                    if (n.data && Array.isArray(n.data)) {
                        const updates = n.data.map(p => ProposalForClientFromJSON(p));
                        const timestamp = new Date().toISOString();
                        for (const update of updates) {
                            subscriber.next({ data: update, timestamp });
                        }
                    }
                };

                this.logic.notification().on('proposals', this._proposalsUpdateCb);
            }
        });

        const buffered$ = proposalsUpdate$.pipe(buffer(interval(this._updatesInterval)));

        this._proposalUpdateSub = buffered$.subscribe(data => {
            const groupedUpdates = data.reduce<{ [id: string]: BufferedUpdate<ProposalForClient>[] }>((a, c) => {
                if (a[c.data.proposalId]) {
                    a[c.data.proposalId].push(c);
                } else {
                    a[c.data.proposalId] = [c];
                }
                return a;
            }, {});

            const latestUpdates = Object.values(groupedUpdates).reduce<ProposalForClient[]>((a, c) => {
                const [mostRecentUpdate] = c.sort((a, b) => (moment(a.timestamp).isBefore(b.timestamp) ? 0 : -1));
                if (mostRecentUpdate) {
                    a.push(mostRecentUpdate.data);
                }
                return a;
            }, []);

            const newProposals = [...this.proposals];

            latestUpdates.forEach(updatedProposal => {
                const index = newProposals.findIndex(
                    a =>
                        String(a.proposalId) === String(updatedProposal.proposalId) &&
                        String(a.vehicle.id) === String(updatedProposal.vehicle.id)
                );

                if (index !== -1) {
                    if (
                        newProposals[index].state !== updatedProposal.state &&
                        [NegotiationState.Declined, NegotiationState.Completed].some(
                            state => state === updatedProposal.state
                        )
                    ) {
                        const notificationConf = {
                            message: i18n.t(`proposalNotification.${updatedProposal.state}`, {
                                shipmentId: updatedProposal.shipmentId
                            }),
                            duration: null,
                            placement: 'bottomRight' as NotificationPlacement
                        };

                        updatedProposal.state === NegotiationState.Completed
                            ? notification.success(notificationConf)
                            : notification.warning(notificationConf);
                    }

                    newProposals[index] = updatedProposal;
                } else {
                    (updatedProposal.updatedAt as Date) = moment().subtract(10, 'seconds').toDate();
                    newProposals.push(updatedProposal);
                }
            });

            if (this.logic.demo().isActive) {
                // Demonstrating disappearing animation in demo mode
                const randomProposalIndex = Math.floor(Math.random() * newProposals.length);
                const newExpiration = moment().subtract('1', 'hour').toDate();
                newProposals[randomProposalIndex].shipment.receiveProposalsUntil = newExpiration;
            }

            this._proposals = newProposals;
            this.proposalsUpdates.next(this.proposals);
        });
    }

    /**
     * Gets current proposals list and subscribes for updates
     */
    private async _getAndSubscribeToProposals() {
        let proposals: ProposalForClient[] = [];
        try {
            const params: GetProposalsV3ProposalsMyGetRequest = {
                subscribeDevice: this.logic.notification().device,
                subscribeUser: this.logic.auth().keycloak.tokenParsed?.['sub'],
                monitoredObjectIds: this._monitoredVehicles.length > 0 ? this._monitoredVehicles.map(String) : undefined
            };

            proposals = await this.logic.api().dffProposalApi.getProposalsV3ProposalsMyGet({
                ...params,
                monitoredObjectIds: this._monitoredVehicles.map(v => String(v))
            });
        } catch (e) {
            console.error(e);
            proposals = [];
        }
        this._proposals = proposals;
        this.proposalsUpdates.next(this.proposals);
    }

    private _getScore(proposal: ProposalForClient, debug = false): number {
        const toPickup = proposal.pickup;
        const toDelivery = proposal.delivery?.[proposal.delivery.length - 1];
        const totalTransportDistance = toDelivery?.distanceKm ?? 0;
        const distanceToPickup = toPickup?.distanceKm ?? 0;
        const weight = proposal.shipment.loadingDetails?.loads.reduce((a, c) => a + (c.weight.value ?? 0), 0) ?? 0;
        const timeToPickup = (toPickup?.duration?.driving ?? 0) / 1e3 / 60 / 60; // ms to hours
        const differenceNowToLoading = Math.abs(
            moment
                .duration(moment().diff(moment(proposal.shipment.loadingDetails?.loadingWindow.availableFrom)))
                .asHours()
        );

        const distanceRating = weight * totalTransportDistance - distanceToPickup * 0.7 * 20;
        const timeRating = (differenceNowToLoading - timeToPickup) * 6 * 20;
        const score = distanceRating - timeRating;

        if (debug) {
            console.log('totalTransportDistance in km    :', totalTransportDistance);
            console.log('distanceToPickup in km          :', distanceToPickup);
            console.log('weight in tonnes                :', weight);
            console.log('time to pickup in hours         :', timeToPickup);
            console.log('differenceNowToLoading in hours :', differenceNowToLoading);
            console.log('distance rating                 :', distanceRating);
            console.log('time rating                     :', timeRating);
            console.log('total score                     :', score);
            console.log('stars                           :', this._getRatingInStars(score));
        }

        return score;
    }

    private _getRatingInStars(score: number): 1 | 2 | 3 | 4 | 5 {
        if (score > 24000) {
            return 5;
        } else if (score > 20000) {
            return 4;
        } else if (score > 15000) {
            return 3;
        } else if (score > 10000) {
            return 2;
        } else {
            return 1;
        }
    }
}
