import { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { RecipientsSearchResult } from '../MaintenanceForm';
import { Button } from 'common/components';
import MultiSelectAutocompleteField from 'common/fields/AutoCompleteMultiSelect';

export interface RecipientModel {
    name?: string;
    email?: boolean;
    userId?: number;
    userRoleId?: string;
}

interface Props extends WithTranslation {
    initialValues?: Partial<RecipientModel>;
    recipientsSearchResultsOpts?: RecipientsSearchResult[];
    onSearchRecipientTextChange?: (value: string) => void;
    onSubmit: (values: RecipientModel) => Promise<boolean>;
}

function MaintenanceRecipientForm(props: Props) {
    let form: FormikProps<RecipientModel>;

    const [recipientAutocompleteValue, setRecipientAutocompleteValue] = useState<string>('');

    function handleOnSubmit(values: RecipientModel) {
        setRecipientAutocompleteValue('');
        props.onSubmit?.(values);
        form.setFieldValue('email', true);
        form.resetForm();
    }

    const renderItem = (item: RecipientsSearchResult, index: number) => ({
        key: index,
        value: item.name,
        label: (
            <Row justify="space-between" key={index}>
                <Col>{item.name}</Col>
                <Col>{item.userId ? props.t('common.userWithoutUppercase') : props.t('common.role')}</Col>
            </Row>
        )
    });

    return (
        <Formik<RecipientModel>
            initialValues={{
                name: props.initialValues?.name,
                userId: props.initialValues?.userId,
                userRoleId: props.initialValues?.userRoleId,
                email: props.initialValues?.email
            }}
            onSubmit={handleOnSubmit}
            validationSchema={Yup.object().shape({
                name: Yup.string().when([], {
                    is: () => form.values.email,
                    then: Yup.string()
                        .email(props.t('Maintenance.task.errorMsg.notValidEmailOrUser'))
                        .required(props.t('common.required')),
                    otherwise: Yup.string().required(props.t('common.required'))
                })
            })}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
        >
            {(formik: FormikProps<RecipientModel>) => {
                form = formik;
                return (
                    <Form className="maintenance-recipient-form">
                        <Row gutter={[12, 12]} justify="space-between">
                            <Col flex="auto">
                                <MultiSelectAutocompleteField
                                    search={false}
                                    name="name"
                                    onSelect={(_val, option) => {
                                        const recipientNew = props.recipientsSearchResultsOpts?.find(
                                            (r, i) => i === option.key
                                        );
                                        if (recipientNew) {
                                            formik.setFieldValue('userId', recipientNew.userId, true);
                                            formik.setFieldValue('userRoleId', recipientNew.userRoleId, true);
                                            formik.setFieldValue('email', recipientNew.email, true);
                                            formik.setFieldValue('name', recipientNew.name, true);
                                        }
                                    }}
                                    label={props.t('Maintenance.task.recipients')}
                                    placeholderText={props.t('Maintenance.task.placeholderRecipients')}
                                    autoFocus
                                    options={
                                        recipientAutocompleteValue !== ''
                                            ? props.recipientsSearchResultsOpts?.map((r, index) => renderItem(r, index))
                                            : []
                                    }
                                    value={recipientAutocompleteValue}
                                    onChange={val => {
                                        setRecipientAutocompleteValue(val);
                                        formik.setFieldValue('name', val, true);
                                        formik.setFieldValue('userId', undefined);
                                        formik.setFieldValue('userRoleId', undefined);
                                        formik.setFieldValue('email', true, true);
                                    }}
                                    onSearch={props.onSearchRecipientTextChange}
                                />
                            </Col>
                            <Col flex="100px">
                                <Button
                                    type="default"
                                    size="large"
                                    block
                                    className="empty-label"
                                    onClick={e => {
                                        e.preventDefault();
                                        formik.submitForm();
                                    }}
                                    disabled={formik.isSubmitting}
                                    htmlType="submit"
                                >
                                    {props.t('common.add')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(MaintenanceRecipientForm);
