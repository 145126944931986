/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileDeviceHasMobileAppCreateMany
 */
export interface MobileDeviceHasMobileAppCreateMany {
    /**
     * 
     * @type {Array<string>}
     * @memberof MobileDeviceHasMobileAppCreateMany
     */
    mobileDevices: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MobileDeviceHasMobileAppCreateMany
     */
    mobileDeviceDeviceIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceHasMobileAppCreateMany
     */
    mobileApp: string;
    /**
     * 
     * @type {boolean}
     * @memberof MobileDeviceHasMobileAppCreateMany
     */
    all: boolean;
    /**
     * 
     * @type {number}
     * @memberof MobileDeviceHasMobileAppCreateMany
     */
    clientId?: number | null;
}

export function MobileDeviceHasMobileAppCreateManyFromJSON(json: any): MobileDeviceHasMobileAppCreateMany {
    return MobileDeviceHasMobileAppCreateManyFromJSONTyped(json, false);
}

export function MobileDeviceHasMobileAppCreateManyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceHasMobileAppCreateMany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobileDevices': json['mobile_devices'],
        'mobileDeviceDeviceIds': json['mobile_device_device_ids'],
        'mobileApp': json['mobile_app'],
        'all': json['all'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function MobileDeviceHasMobileAppCreateManyToJSON(value?: MobileDeviceHasMobileAppCreateMany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile_devices': value.mobileDevices,
        'mobile_device_device_ids': value.mobileDeviceDeviceIds,
        'mobile_app': value.mobileApp,
        'all': value.all,
        'client_id': value.clientId,
    };
}


