import { useState } from 'react';
import TableFilter from 'common/components/TableFilter';
import qa from 'qa-selectors';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Checkbox } from 'common/components';
import { ColdchainAlertFilterParams } from '../../ColdchainModule';
import { confDefault } from 'conf';

interface Props extends WithTranslation {
    demoMode?: boolean;
    filter: ColdchainAlertFilterParams;
    onConfirm?: (alertsFilter: ColdchainAlertFilterParams) => void;
    onCancel?: () => void;
}

function ColdchainAlertFilter(props: Props) {
    const { t } = props;

    const [alertingChecked, setAlertingChecked] = useState(props.filter.alerting);
    function handleAlertingChange() {
        setAlertingChecked(!alertingChecked);
    }

    const [resolvedChecked, setResolvedChecked] = useState(props.filter.resolved);
    function handleResolvedChange() {
        setResolvedChecked(!resolvedChecked);
    }

    function onConfirmClick(): void {
        props.onConfirm?.({
            alerting: alertingChecked,
            resolved: resolvedChecked
        });
    }

    function onResetClick(): void {
        const defaults = confDefault.settings.statistics.coldChain.filter.alerts;
        setAlertingChecked(defaults.alerting);
        setResolvedChecked(defaults.resolved);
    }

    return (
        <TableFilter
            className="coldchain-filter coldchain-alert-filter"
            qa={qa.coldChain.alertFilter.section}
            heading={t('Coldchain.alertFilter.name')}
            body={
                <Row gutter={[24, 24]}>
                    <Col>
                        <label className="group-title">{t('Coldchain.alertFilter.groupStatus.name')}</label>
                        <Row>
                            <Checkbox checked={alertingChecked} onChange={handleAlertingChange}>
                                {t('Coldchain.alertFilter.groupStatus.alerting')}
                            </Checkbox>
                        </Row>
                        <Row>
                            <Checkbox checked={resolvedChecked} onChange={handleResolvedChange}>
                                {t('Coldchain.alertFilter.groupStatus.resolved')}
                            </Checkbox>
                        </Row>
                    </Col>
                </Row>
            }
            cancelButton={{
                onClick: props.onCancel,
                qa: qa.coldChain.alertFilter.btnCancel
            }}
            submitButton={{
                onClick: onConfirmClick,
                qa: qa.coldChain.alertFilter.btnSubmit,
                disabled: props.demoMode
            }}
            resetButton={{
                onClick: onResetClick
            }}
        />
    );
}

export default withTranslation()(ColdchainAlertFilter);
