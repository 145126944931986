import React, { Component } from 'react';
import { Modal } from '..';
import { StazkaExportModalModel, StazkaExportModalStateModel } from 'common/model/stazka-export-modal';
import moment, { Moment } from 'moment';
import { Select } from '../Form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row } from 'antd';
import { DatePicker } from 'common/components';
import { searched } from 'common/utils/search';
import { TIME_FORMAT_SHORT } from 'domain-constants';

interface Props extends WithTranslation, StazkaExportModalModel {
    onConfirm?: (data: StazkaExportModalStateModel) => void;
    onCancel?: () => void;
}
interface State extends StazkaExportModalStateModel {}

class StazkaExportModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            startDateTime: props.startDateTime,
            endDateTime: props.endDateTime
        };
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({
                startDateTime: this.props.startDateTime,
                endDateTime: this.props.endDateTime,
                vehicleId: undefined
            });
        }
    }

    render() {
        const { t } = this.props;

        return this.props.visible ? (
            <Modal
                className="stazka-export-modal"
                width={350}
                visible={true}
                centered={true}
                closable={false}
                okText={t('common.confirm')}
                okButtonProps={{
                    loading: this.props.loading,
                    disabled: !this.state.vehicleId && !this.props.transport?.id
                }}
                title={this.props.title ? this.props.title : t('StazkaExportModal.title')}
                onOk={this._onConfirm}
                onCancel={this._onCancel}
            >
                {this.props.transport?.id ? (
                    <Row>
                        <label>
                            {t('StazkaExportModal.transportMessage')}: {this.props.transport.name}
                        </label>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <label>{t('common.vehicle')}:</label>
                            <Select
                                defaultValue={this.state.vehicleId}
                                onChange={this._onVehicleSelect}
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => searched(input, String(option?.children))}
                                allowClear={true}
                                loading={this.props.loading}
                                options={this.props.monitredObjects.map(monitoredObject => ({
                                    value: monitoredObject.id ?? 0,
                                    label: monitoredObject.registrationNumber
                                }))}
                            />
                        </Row>
                        <Row>
                            <label>{t('common.startDate')}:</label>
                            <DatePicker.DateTimePicker
                                defaultValue={moment(this.state.startDateTime)}
                                onChange={this._onStartDateTime}
                                showTime={{ format: TIME_FORMAT_SHORT }}
                                trigger={
                                    <div className="t-input">{moment(this.state.startDateTime).format('L LT')}</div>
                                }
                            />
                        </Row>
                        <Row>
                            <label>{t('common.endDate')}:</label>
                            <DatePicker.DateTimePicker
                                defaultValue={moment(this.state.endDateTime)}
                                onChange={this._onEndDateTime}
                                showTime={{ format: TIME_FORMAT_SHORT }}
                                trigger={<div className="t-input">{moment(this.state.endDateTime).format('L LT')}</div>}
                            />
                        </Row>
                    </>
                )}
            </Modal>
        ) : (
            <></>
        );
    }

    private _onConfirm = () => {
        this.props.onConfirm?.({ ...this.state, transportId: this.props.transport?.id });
    };

    private _onCancel = () => {
        this.props.onCancel?.();
    };

    private _onVehicleSelect = (vehicleId: string) => {
        this.setState({
            vehicleId
        });
    };

    private _onStartDateTime = (startDateTime: Moment | null) => {
        if (startDateTime) {
            this.setState({
                startDateTime: startDateTime.toISOString()
            });
        }
    };

    private _onEndDateTime = (endDateTime: Moment | null) => {
        if (endDateTime) {
            this.setState({
                endDateTime: endDateTime.toISOString()
            });
        }
    };
}

export default withTranslation()(StazkaExportModal);
