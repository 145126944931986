/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobileApp,
    MobileAppFromJSON,
    MobileAppFromJSONTyped,
    MobileAppToJSON,
    MobileDevice,
    MobileDeviceFromJSON,
    MobileDeviceFromJSONTyped,
    MobileDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MobileDeviceHasMobileApp
 */
export interface MobileDeviceHasMobileApp {
    /**
     * 
     * @type {MobileApp}
     * @memberof MobileDeviceHasMobileApp
     */
    mobileApp: MobileApp;
    /**
     * 
     * @type {MobileDevice}
     * @memberof MobileDeviceHasMobileApp
     */
    mobileDevice: MobileDevice;
    /**
     * 
     * @type {Date}
     * @memberof MobileDeviceHasMobileApp
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileDeviceHasMobileApp
     */
    upgradedAt?: Date;
}

export function MobileDeviceHasMobileAppFromJSON(json: any): MobileDeviceHasMobileApp {
    return MobileDeviceHasMobileAppFromJSONTyped(json, false);
}

export function MobileDeviceHasMobileAppFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceHasMobileApp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobileApp': MobileAppFromJSON(json['mobile_app']),
        'mobileDevice': MobileDeviceFromJSON(json['mobile_device']),
        'createdAt': (new Date(json['created_at'])),
        'upgradedAt': !exists(json, 'upgraded_at') ? undefined : (new Date(json['upgraded_at'])),
    };
}

export function MobileDeviceHasMobileAppToJSON(value?: MobileDeviceHasMobileApp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile_app': MobileAppToJSON(value.mobileApp),
        'mobile_device': MobileDeviceToJSON(value.mobileDevice),
        'created_at': (value.createdAt.toISOString()),
        'upgraded_at': value.upgradedAt === undefined ? undefined : (value.upgradedAt.toISOString()),
    };
}


