import { ReactNode } from 'react';
import { DriverBehaviorCoachDataMap } from '../DriverBehaviorCoach';
import { DriverStatValueFormat } from '../DriverStatValue';
import { H, KM, M } from 'domain-constants';
import { Tooltip } from 'common/components';
import { information } from 'resources/images/driver-behavior';
import { translateFormatedText } from 'common/utils/textUtils';
import DriverBehaviorIconExclamation from './DriverBehaviorIconExclamation';
import i18next from 'i18next';
import { DriverBehaviorDataMapModel } from '../../modules/Trucks/Detail';

interface Props {
    dataMap: DriverBehaviorCoachDataMap;
    showExclamation?: boolean;
}
export const getUnit = (unit: DriverStatValueFormat | undefined): string => {
    switch (unit) {
        case 'percentage':
            return '[%]';
        case 'litres':
            return `[${i18next.t('common.metrics.liters')}]`;
        case 'litresPerKm':
            return `[${i18next.t('common.metrics.liters')}/100${KM}]`;
        case 'duration':
            return '[hh:mm:ss]';
        case 'm':
            return `[${M}]`;
        case 'mPerKm':
            return `[m/100${KM}]`;
        case 'km':
            return `[${KM}]`;
        case 'distance':
            return `[${KM}]`;
        case 'count':
            return `[${i18next.t('common.count')}]`;
        case 'countPerKm':
            return `[${i18next.t('common.count')}/100${KM}]`;
        case 'kmPerHour':
            return `[${KM}/${H}]`;
        case 'ratio':
            return `[${i18next.t('common.metrics.ratio')}]`;
        case 'rpm':
            return `[${i18next.t('common.metrics.rpm')}]`;
        default:
            return '';
    }
};

export function getCoachTableLabel(dataMap: DriverBehaviorCoachDataMap | DriverBehaviorDataMapModel) {
    return `${translateFormatedText(dataMap?.title)} ${getUnit(dataMap?.unit)} ${
        dataMap?.postfix ? translateFormatedText(String(dataMap.postfix)) : ''
    }`;
}

export function DriverBehaviorCoachTableLabel(props: Props) {
    const getLabel = (dataMap: DriverBehaviorCoachDataMap, showExclamation?: boolean): ReactNode => {
        return (
            <>
                {translateFormatedText(dataMap.title)}
                <span className="postfix">
                    {getUnit(dataMap.unit)} {dataMap.postfix ? translateFormatedText(dataMap.postfix) : ''}
                </span>
                {dataMap.info && (
                    <Tooltip title={translateFormatedText(dataMap.info)}>
                        <img className="info" src={information} alt="information" />
                    </Tooltip>
                )}
                {showExclamation && <DriverBehaviorIconExclamation />}
            </>
        );
    };

    return <div>{getLabel(props.dataMap, props.showExclamation)}</div>;
}
