import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import WhitelabelTemplates from './WhitelabelTemplates';
import WhitelabelTemplate from './WhitelabelTemplate';
import { WhitelabelModel, WhitelabelTemplateModel } from 'logic/partner/logic/partner-whitelabel';
import { PaginatedResponse } from 'common/model/pagination';
import Confirm, { MessageType } from 'common/components/Confirm';
import TableBar from 'common/components/TableBar';

interface Props extends WithTranslation {
    search?: { text: string };
    loading?: boolean;
    templates?: PaginatedResponse<WhitelabelTemplateModel[]>;
    companies?: PartnerCompanySelectModel[];
    selectedCompany?: PartnerCompanySelectModel;
    selectedTemplate?: WhitelabelTemplateModel;
    delete?: WhitelabelTemplateModel;
    active?: WhitelabelModel;
    logo?: string;
    removeLoading?: boolean;
    onBarCompanyFilterChange?: (id: string) => void;
    onBarCompanyFilterCancel?: () => void;
    onBarCompanyFilterSearch?: (value: string) => void;
    onTemplatesRowAction?: (id: string) => void;
    onTemplatesRowDelete?: (id: string) => void;
    onTemplatesRowDeactivate?: (id: string) => void;
    onTemplatesRowActivate?: (id: string) => void;
    onTemplateSubmit?: (model: WhitelabelTemplateModel) => void;
    onTemplateClose?: () => void;
    onLogoSubmit?: (logo?: string) => void;
    onDeleteConfirm?: () => void;
    onDeleteCancel?: () => void;
}

function Whitelabel(props: Props) {
    const { t } = props;

    return (
        <>
            {props.delete && (
                <Confirm
                    danger
                    header={t('Whitelabel.deleteHeader')}
                    message={`${t('Whitelabel.deleteConfirm')} ${props.delete?.name}`}
                    type={MessageType.WARNING}
                    loading={props.removeLoading}
                    confirmLabel={props.t('common.delete')}
                    onConfirm={props.onDeleteConfirm}
                    onCancel={props.onDeleteCancel}
                />
            )}

            <div className="partner-whitelabel page">
                <TableBar heading={t('common.whitelabel')} />
                <div className="whitelabel-content">
                    <WhitelabelTemplates
                        data={props.templates}
                        loading={props.loading}
                        selected={props.selectedTemplate ? props.selectedTemplate.id : undefined}
                        active={props.active ? props.active : undefined}
                        logo={props.logo}
                        onRowAction={props.onTemplatesRowAction}
                        onRowDelete={props.onTemplatesRowDelete}
                        onRowActivate={props.onTemplatesRowActivate}
                        onRowDeactivate={props.onTemplatesRowDeactivate}
                        onLogoSubmit={props.onLogoSubmit}
                    />
                    {props.selectedTemplate && (
                        <WhitelabelTemplate
                            model={props.selectedTemplate}
                            onSubmit={props.onTemplateSubmit}
                            onClose={props.onTemplateClose}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default withTranslation()(Whitelabel);
