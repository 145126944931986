/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Channel
 */
export interface Channel {
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    creator: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Channel
     */
    users: Array<string>;
}

export function ChannelFromJSON(json: any): Channel {
    return ChannelFromJSONTyped(json, false);
}

export function ChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Channel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'created': (new Date(json['created'])),
        'creator': json['creator'],
        'users': json['users'],
    };
}

export function ChannelToJSON(value?: Channel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'created': (value.created.toISOString()),
        'creator': value.creator,
        'users': value.users,
    };
}


