import React from 'react';
import MessagingChannels from './MessagingChannels';
import MessagingChat from './MessagingChat';
import { Message, UploadAttachmentResp, MessagePayload } from 'logic/messaging';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ChannelDriverInfo } from '../MessagingModule';
import { Col, Row } from 'antd';
import { Role } from 'logic/auth';
import HelperModal from 'common/components/HelperModal';

interface Props extends WithTranslation {
    checkedChannels?: string[];
    inputRef: React.RefObject<HTMLInputElement>;
    channels: {
        channels: ChannelDriverInfo[];
        search?: string;
        selectedChannel?: string;
    };
    chat: {
        search?: string;
        title: string;
        userId: string;
        driverId: string;
        messages?: Message[];
        filtered?: Message[];
        messageIndexRef: number;
    };
    roles: Role[];
    helper?: {
        content: string;
    };
    uploadAttachment?: (attachment: File | Blob) => Promise<UploadAttachmentResp>;
    onChannelsUserNameFromId: (userId: string) => string;
    onChannelsSelect?: (channel: ChannelDriverInfo) => void;
    onChannelsSearchClick?: () => void;
    onChannelsSearch?: (text: string) => void;
    onChannelsGroupMessageClick?: () => void;
    onChannelsCheck?: (channel: ChannelDriverInfo) => void;
    onChannelsUncheck?: (channel: ChannelDriverInfo) => void;
    onChannelLastMessageUnsee?: (channel: ChannelDriverInfo) => void;
    onChatMessageUnsee?: (messageId: string) => void;
    onChatMessage?: (text: string, payload?: MessagePayload[]) => void;
    onChatGroupMessage?: (text: string, payload?: MessagePayload[]) => void;
    onChatSearchClick?: () => void;
    onChatSearch?: (text: string) => void;
    onChatFocus?: () => void;
    onChatScrollTop?: () => void;
    onBarHelperClick?: () => void;
    onHelperClose?: () => void;
    demoMode?: boolean;
}

function Messaging(props: Props) {
    return (
        <div className="module module-right messaging">
            <div className="page">
                <Row>
                    <Col span="10">
                        <MessagingChannels
                            channels={props.channels.channels}
                            checkedChannels={props.checkedChannels}
                            selectedChannel={props.channels.selectedChannel}
                            search={props.channels.search}
                            onSelect={props.onChannelsSelect}
                            onSearchClick={props.onChannelsSearchClick}
                            onSearch={props.onChannelsSearch}
                            onGroupMessageClick={props.onChannelsGroupMessageClick}
                            onCheck={props.onChannelsCheck}
                            onLastMessageUnsee={props.onChannelLastMessageUnsee}
                            onUncheck={props.onChannelsUncheck}
                            onBarHelperClick={props.onBarHelperClick}
                            demoMode={props.demoMode}
                        />
                    </Col>
                    <Col span="14">
                        <MessagingChat
                            inputRef={props.inputRef}
                            title={props.chat.title}
                            messages={props.chat.filtered ?? props.chat.messages}
                            userId={props.chat.userId}
                            driverId={props.chat.driverId}
                            checkedDriversIds={props.checkedChannels}
                            search={props.chat.search}
                            messageIndexRef={props.chat.messageIndexRef}
                            roles={props.roles}
                            uploadAttachment={props.uploadAttachment}
                            onUserNameFromId={props.onChannelsUserNameFromId}
                            onMessageUnsee={props.onChatMessageUnsee}
                            onMessage={props.onChatMessage}
                            onGroupMessage={props.onChatGroupMessage}
                            onSearchClick={props.onChatSearchClick}
                            onSearch={props.onChatSearch}
                            onChatFocus={props.onChatFocus}
                            onScrollTop={props.onChatScrollTop}
                            demoMode={props.demoMode}
                        />
                    </Col>
                </Row>
            </div>
            <HelperModal
                name="messaging"
                content={props.helper?.content ?? ''}
                onClose={props.onHelperClose}
                visible={!!props.helper}
            />
        </div>
    );
}

export default withTranslation()(Messaging);
