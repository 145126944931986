/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleDistance
 */
export interface VehicleDistance {
    /**
     * 
     * @type {number}
     * @memberof VehicleDistance
     */
    monitoredObjectId: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDistance
     */
    gpsDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDistance
     */
    canDistance?: number;
}

export function VehicleDistanceFromJSON(json: any): VehicleDistance {
    return VehicleDistanceFromJSONTyped(json, false);
}

export function VehicleDistanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDistance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitoredObjectId'],
        'gpsDistance': !exists(json, 'gps_distance') ? undefined : json['gps_distance'],
        'canDistance': !exists(json, 'can_distance') ? undefined : json['can_distance'],
    };
}

export function VehicleDistanceToJSON(value?: VehicleDistance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoredObjectId': value.monitoredObjectId,
        'gps_distance': value.gpsDistance,
        'can_distance': value.canDistance,
    };
}


