/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GraphInfo
 */
export interface GraphInfo {
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphInfo
     */
    lats?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphInfo
     */
    lons?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphInfo
     */
    timestamps: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphInfo
     */
    speeds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GraphInfo
     */
    fuelLevels?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GraphInfo
     */
    polyline?: string;
}

export function GraphInfoFromJSON(json: any): GraphInfo {
    return GraphInfoFromJSONTyped(json, false);
}

export function GraphInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lats': !exists(json, 'lats') ? undefined : json['lats'],
        'lons': !exists(json, 'lons') ? undefined : json['lons'],
        'timestamps': json['timestamps'],
        'speeds': !exists(json, 'speeds') ? undefined : json['speeds'],
        'fuelLevels': !exists(json, 'fuel_levels') ? undefined : json['fuel_levels'],
        'polyline': !exists(json, 'polyline') ? undefined : json['polyline'],
    };
}

export function GraphInfoToJSON(value?: GraphInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lats': value.lats,
        'lons': value.lons,
        'timestamps': value.timestamps,
        'speeds': value.speeds,
        'fuel_levels': value.fuelLevels,
        'polyline': value.polyline,
    };
}


