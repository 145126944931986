/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverBehaviourContextualPeriod
 */
export interface DriverBehaviourContextualPeriod {
    /**
     * 
     * @type {string}
     * @memberof DriverBehaviourContextualPeriod
     */
    start: string;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourContextualPeriod
     */
    crossSystemBestDriverOveralScore: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourContextualPeriod
     */
    crossSystemAvgDriverOveralScore: number;
    /**
     * 
     * @type {number}
     * @memberof DriverBehaviourContextualPeriod
     */
    clientAvgDriverOveralScore: number;
}

export function DriverBehaviourContextualPeriodFromJSON(json: any): DriverBehaviourContextualPeriod {
    return DriverBehaviourContextualPeriodFromJSONTyped(json, false);
}

export function DriverBehaviourContextualPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBehaviourContextualPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'crossSystemBestDriverOveralScore': json['cross_system_best_driver_overal_score'],
        'crossSystemAvgDriverOveralScore': json['cross_system_avg_driver_overal_score'],
        'clientAvgDriverOveralScore': json['client_avg_driver_overal_score'],
    };
}

export function DriverBehaviourContextualPeriodToJSON(value?: DriverBehaviourContextualPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'cross_system_best_driver_overal_score': value.crossSystemBestDriverOveralScore,
        'cross_system_avg_driver_overal_score': value.crossSystemAvgDriverOveralScore,
        'client_avg_driver_overal_score': value.clientAvgDriverOveralScore,
    };
}


