import { useTranslation } from 'react-i18next';
import { LayoutDetail } from 'common/components/Layout/Detail';
import Descriptions from 'common/components/Descriptions';
import { PartnerCompanyModel } from 'logic/partner/logic/partner-partners';
import { CountrySelectModel, CurrencySelectModel } from 'logic/partner/partner';
import qa from 'qa-selectors';

interface Props {
    data: PartnerCompanyModel;
    currencyCounter?: CurrencySelectModel[];
    countryCounter?: CountrySelectModel[];
}

export default function PartnersDetailCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="trailer-detail-card">
            <LayoutDetail
                firstLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>
                            <span data-qa={qa.partner.partners.detail.fieldName}>{props.data.name}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.billingCode')}>
                            <span data-qa={qa.partner.partners.detail.fieldInvoiceCode}>{props.data.billingCode}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.identificationNumber')}>
                            <span data-qa={qa.partner.partners.detail.fieldIdentificationNumber}>
                                {props.data.identificationNumber}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.vat')}>
                            <span data-qa={qa.partner.partners.detail.fieldIdentificationNumber}>
                                {props.data.vatNumber}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.creationDate')}>
                            <span data-qa={qa.partner.partners.detail.fieldCreationDate}>
                                {props.data.creationDate.format('L')}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                firstRight={
                    <Descriptions layout="vertical" column={1} title="">
                        <Descriptions.Item label={t('Partner.terminationDate')}>
                            <span data-qa={qa.partner.partners.detail.fieldTerminationDate}>
                                {props.data.terminationDate.format('L')}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.companyType')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyType}>
                                {t(`PartnerPartners.${String(props.data.companyType)}`)}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.currency')}>
                            <span data-qa={qa.partner.partners.detail.fieldCurrency}>
                                {props.currencyCounter?.find(c => c.id === props.data.currency)?.code ??
                                    props.data.currency}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.billingCycle')}>
                            <span data-qa={qa.partner.partners.detail.fieldBillingCycle}>
                                {t(`Partner.${String(props.data.billingCycle)}`)}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondTitle={t('Partner.companyAddress')}
                secondLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('PartnerPartners.street')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyStreet}>
                                {props.data.address?.[0]?.street}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.streetNo')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyStreetNo}>
                                {props.data.address?.[0]?.houseNumber}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.city')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyCity}>
                                {props.data.address?.[0]?.city}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                secondRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('PartnerPartners.country')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyCountry}>
                                {props.countryCounter?.find(c => c.id === props.data.address?.[0]?.country)?.name}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.zipCode')}>
                            <span data-qa={qa.partner.partners.detail.fieldCompanyZipCode}>
                                {props.data.address?.[0]?.zip}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                thirdTitle={t('common.contact')}
                thirdLeft={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('common.name')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonName}>
                                {props.data.contactPerson?.[0]?.name}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.surname')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonSurname}>
                                {props.data.contactPerson?.[0]?.surname}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.email')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonEmail}>
                                {props.data.contactPerson?.[0]?.email}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common.phone')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonPhone}>
                                {props.data.contactPerson?.[0]?.cellPhone}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('Partner.position')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonPosition}>
                                {props.data.contactPerson?.[0]?.position}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
                thirdRight={
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label={t('PartnerPartners.street')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonStreet}>
                                {props.data.contactPerson?.[0]?.address?.[0]?.street}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.streetNo')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonStreetNo}>
                                {props.data.contactPerson?.[0]?.address?.[0]?.street}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.city')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonCity}>
                                {props.data.contactPerson?.[0]?.address?.[0]?.city}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('PartnerPartners.country')}>
                            <span data-qa={qa.partner.partners.detail.fieldPersonCountry}>
                                {
                                    props.countryCounter?.find(
                                        c => c.id === props.data.contactPerson?.[0]?.address?.[0]?.country
                                    )?.name
                                }
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                }
            />
        </div>
    );
}
