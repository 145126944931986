/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20049,
    InlineResponse20049FromJSON,
    InlineResponse20049ToJSON,
    ReadOnlyWhiteLabelTemplate,
    ReadOnlyWhiteLabelTemplateFromJSON,
    ReadOnlyWhiteLabelTemplateToJSON,
    WriteOnlyWhiteLabelTemplate,
    WriteOnlyWhiteLabelTemplateFromJSON,
    WriteOnlyWhiteLabelTemplateToJSON,
} from '../models';

export interface WhiteLabelTemplateCreateRequest {
    data: WriteOnlyWhiteLabelTemplate;
}

export interface WhiteLabelTemplateDeleteRequest {
    id: string;
}

export interface WhiteLabelTemplateListRequest {
    clientOrNull?: string;
    limit?: number;
    offset?: number;
}

export interface WhiteLabelTemplatePartialUpdateRequest {
    id: string;
    data: WriteOnlyWhiteLabelTemplate;
}

export interface WhiteLabelTemplateReadRequest {
    id: string;
}

export interface WhiteLabelTemplateUpdateRequest {
    id: string;
    data: WriteOnlyWhiteLabelTemplate;
}

/**
 * no description
 */
export class WhiteLabelTemplateApi extends runtime.BaseAPI {

    /**
     */
    async whiteLabelTemplateCreateRaw(requestParameters: WhiteLabelTemplateCreateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabelTemplate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelTemplateCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelTemplateFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplateCreate(requestParameters: WhiteLabelTemplateCreateRequest): Promise<ReadOnlyWhiteLabelTemplate> {
        const response = await this.whiteLabelTemplateCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelTemplateDeleteRaw(requestParameters: WhiteLabelTemplateDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabelTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelTemplateDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelTemplateFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplateDelete(requestParameters: WhiteLabelTemplateDeleteRequest): Promise<ReadOnlyWhiteLabelTemplate> {
        const response = await this.whiteLabelTemplateDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelTemplateListRaw(requestParameters: WhiteLabelTemplateListRequest): Promise<runtime.ApiResponse<InlineResponse20049>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientOrNull !== undefined) {
            queryParameters['client_or_null'] = requestParameters.clientOrNull;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20049FromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplateList(requestParameters: WhiteLabelTemplateListRequest): Promise<InlineResponse20049> {
        const response = await this.whiteLabelTemplateListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelTemplatePartialUpdateRaw(requestParameters: WhiteLabelTemplatePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabelTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelTemplatePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelTemplatePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelTemplateFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplatePartialUpdate(requestParameters: WhiteLabelTemplatePartialUpdateRequest): Promise<ReadOnlyWhiteLabelTemplate> {
        const response = await this.whiteLabelTemplatePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelTemplateReadRaw(requestParameters: WhiteLabelTemplateReadRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabelTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelTemplateRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelTemplateFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplateRead(requestParameters: WhiteLabelTemplateReadRequest): Promise<ReadOnlyWhiteLabelTemplate> {
        const response = await this.whiteLabelTemplateReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelTemplateUpdateRaw(requestParameters: WhiteLabelTemplateUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabelTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelTemplateUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelTemplateUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label-template/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelTemplateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelTemplateFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelTemplateUpdate(requestParameters: WhiteLabelTemplateUpdateRequest): Promise<ReadOnlyWhiteLabelTemplate> {
        const response = await this.whiteLabelTemplateUpdateRaw(requestParameters);
        return await response.value();
    }

}
