import * as React from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import qa from 'qa-selectors';
import { formatDuration, formatDurationWithSeconds } from 'common/utils/dateTimeUtils';

export interface DiemsSummary {
    localStay: number;
    countries: string;
    abroadStay: number;
    pocketMoney: {
        currency: string;
        value: number;
    }[];
    dailyRates: {
        currency: string;
        value: number;
    }[];
    allowanceSummary?: {
        currency: string;
        value: number;
    };
}

interface Props extends Partial<DiemsSummary> {}
export default function AllowanceSummary(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="allowance-summary">
            <div className="summary-box">
                <div className="title">{t('Allowance.summary.stayLocalTime')}</div>
                <div className="units">
                    ({t('common.days')[0].toLowerCase()}:{t('common.hours')[0].toLowerCase()}:
                    {t('common.minutes')[0].toLowerCase()})
                </div>
                <div
                    className="value"
                    data-qa={qa.allowances.summary.fieldDomesticTravel}
                    title={formatDurationWithSeconds((props.localStay ?? 0) * 1000)}
                >
                    {formatDuration((props.localStay ?? 0) * 1000)}
                </div>
            </div>
            <div className="summary-box">
                <div className="title">{t('Allowance.summary.countries')}</div>
                <div className="units" />
                <div className="value" data-qa={qa.allowances.summary.fieldCountries}>
                    {props.countries}
                </div>
            </div>
            <div className="summary-box">
                <div className="title">{t('Allowance.summary.stayAbroadTime')}</div>
                <div className="units">
                    ({t('common.days')[0].toLowerCase()}:{t('common.hours')[0].toLowerCase()}:
                    {t('common.minutes')[0].toLowerCase()})
                </div>
                <div
                    className="value"
                    data-qa={qa.allowances.summary.fieldAbroadTravel}
                    title={formatDurationWithSeconds((props.abroadStay ?? 0) * 1000)}
                >
                    {formatDuration((props.abroadStay ?? 0) * 1000)}
                </div>
            </div>
            {props.pocketMoney?.map((pm, index) => (
                <div className="summary-box" key={`pm-${index}`}>
                    <div className="title">{t('Allowance.summary.pocketMoney')}</div>
                    <div className="units" data-qa={qa.allowances.summary.fieldAllowanceCurrency}>
                        {pm.currency}
                    </div>
                    <div className="value" data-qa={qa.allowances.summary.fieldAllowance}>
                        {pm.value}
                    </div>
                </div>
            ))}
            {props.dailyRates?.map((rate, index) => (
                <div className="summary-box" key={`rate-${index}`}>
                    <div className="title">{t('Allowance.summary.allowance')}</div>
                    <div className="units" data-qa={qa.allowances.summary.fieldTotalAllowanceCurrency}>
                        ({rate.currency})
                    </div>
                    <div className="value" data-qa={qa.allowances.summary.fieldTotalAllowance}>
                        {rate.value}
                    </div>
                </div>
            ))}
            {props.allowanceSummary && (
                <div className="summary-box">
                    <div className="title">{t('Allowance.summary.allowanceTotal')}</div>
                    <div className="units" data-qa={qa.allowances.summary.fieldSummaryCurrency}>
                        {props.allowanceSummary?.currency}
                    </div>
                    <div className="value" data-qa={qa.allowances.summary.fieldSummary}>
                        {numeral(props.allowanceSummary?.value).format('0,0.00')}
                    </div>
                </div>
            )}
        </div>
    );
}
