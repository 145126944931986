const mapStyleDefault: google.maps.MapTypeStyle[] = [
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#646464'
            }
        ]
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#e78692'
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#e78692'
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#e78692'
            }
        ]
    },
    {
        featureType: 'landscape',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#a0a0a0'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        stylers: [
            {
                color: '#efefef'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'landscape.natural.landcover',
        stylers: [
            {
                color: '#ebedeb'
            }
        ]
    },
    {
        featureType: 'landscape.natural.terrain',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575'
            }
        ]
    },
    {
        featureType: 'poi.attraction',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.attraction',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'inherit'
            }
        ]
    },
    {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [
            {
                color: '#e78692'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi.government',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.government',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.medical',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.medical',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                visibility: '#e5e5e5'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.place_of_worship',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.place_of_worship',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.school',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.school',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 35
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                saturation: -100
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada'
            }
        ]
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        stylers: [
            {
                color: '#ccd8e9'
            },
            {
                visibility: 'on'
            }
        ]
    }
];

const mapStyleHybrid: google.maps.MapTypeStyle[] = [
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry'
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill'
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'shown'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry'
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    }
];

interface MapStyles {
    default: google.maps.MapTypeStyle[];
    hybrid: google.maps.MapTypeStyle[];
}

export const mapStyles: MapStyles = {
    default: mapStyleDefault,
    hybrid: mapStyleHybrid
};
