/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NegotiationOffer,
    NegotiationOfferFromJSON,
    NegotiationOfferToJSON,
} from '../models';

export interface AcceptV3ProposalsProposalIdAcceptPatchRequest {
    proposalId: string;
    useJitpay?: boolean;
}

export interface DeclineV3ProposalsProposalIdDeclinePatchRequest {
    proposalId: string;
}

export interface OfferV3ProposalsProposalIdOfferPatchRequest {
    proposalId: string;
    negotiationOffer: NegotiationOffer;
}

/**
 * no description
 */
export class DffNegotiationApi extends runtime.BaseAPI {

    /**
     * Accept
     */
    async acceptV3ProposalsProposalIdAcceptPatchRaw(requestParameters: AcceptV3ProposalsProposalIdAcceptPatchRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling acceptV3ProposalsProposalIdAcceptPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.useJitpay !== undefined) {
            queryParameters['use_jitpay'] = requestParameters.useJitpay;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/{proposal_id}/accept`.replace(`{${"proposal_id"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Accept
     */
    async acceptV3ProposalsProposalIdAcceptPatch(requestParameters: AcceptV3ProposalsProposalIdAcceptPatchRequest): Promise<object> {
        const response = await this.acceptV3ProposalsProposalIdAcceptPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Decline
     */
    async declineV3ProposalsProposalIdDeclinePatchRaw(requestParameters: DeclineV3ProposalsProposalIdDeclinePatchRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling declineV3ProposalsProposalIdDeclinePatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/{proposal_id}/decline`.replace(`{${"proposal_id"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Decline
     */
    async declineV3ProposalsProposalIdDeclinePatch(requestParameters: DeclineV3ProposalsProposalIdDeclinePatchRequest): Promise<object> {
        const response = await this.declineV3ProposalsProposalIdDeclinePatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Offer
     */
    async offerV3ProposalsProposalIdOfferPatchRaw(requestParameters: OfferV3ProposalsProposalIdOfferPatchRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling offerV3ProposalsProposalIdOfferPatch.');
        }

        if (requestParameters.negotiationOffer === null || requestParameters.negotiationOffer === undefined) {
            throw new runtime.RequiredError('negotiationOffer','Required parameter requestParameters.negotiationOffer was null or undefined when calling offerV3ProposalsProposalIdOfferPatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/proposals/{proposal_id}/offer`.replace(`{${"proposal_id"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NegotiationOfferToJSON(requestParameters.negotiationOffer),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Offer
     */
    async offerV3ProposalsProposalIdOfferPatch(requestParameters: OfferV3ProposalsProposalIdOfferPatchRequest): Promise<object> {
        const response = await this.offerV3ProposalsProposalIdOfferPatchRaw(requestParameters);
        return await response.value();
    }

}
