import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import qa from 'qa-selectors';
import { Button } from 'common/components';
import DriveRank from '../DriveRank';
import * as icons from 'resources/images/common';
import TableBar from 'common/components/TableBar';
import DtcoCalendar from 'common/components/DtcoCalendar';

interface Props {
    name: ReactNode;
    avatar?: string;
    date?: string;
    tachocard?: string;
    export: {
        processing: boolean;
        enable: boolean;
    };
    rank?: {
        value: number;
        total: number;
    };
    demoMode?: boolean;
    onBackButtonClick?: () => void;
    onExportClick?: () => any;
    onDateRangeChange?: (value: string) => void;
    onBarDateNextClick?: () => void;
    onBarDatePreviousClick?: () => void;
    onHelperClick?: () => void;
}

export default function DriverDetailNav(props: Props) {
    const { t } = useTranslation();

    function onDateRangeChange(from: Moment | null) {
        if (from) {
            props.onDateRangeChange?.(from?.toISOString());
        }
    }

    return (
        <>
            <TableBar
                heading={<span data-qa={qa.driverBehaviour.nav.fieldName}>{props.name}</span>}
                labels={[props.tachocard && <span className="driver-detail-nav-tachocard">{props.tachocard}</span>]}
                labelsRight={[props.rank && <DriveRank value={props.rank.value} total={props.rank.total} />]}
                backButton={{ onClick: props.onBackButtonClick }}
                className="driver-detail-nav"
                filters={[
                    <DtcoCalendar
                        startDate={props.date ?? moment().toISOString()}
                        onNextChange={props.onBarDateNextClick}
                        onPreviousChange={props.onBarDatePreviousClick}
                        onDatePickerChange={onDateRangeChange}
                    />
                ]}
                actions={[
                    <Button
                        className="driver-detail-nav-export-btn"
                        type="primary"
                        disabled={props.demoMode || !props.export.enable}
                        loading={props.export.processing}
                        icon={<img src={icons.export} alt={t('common.export')} />}
                        onClick={props.onExportClick}
                    >
                        {t('common.export')}
                    </Button>
                ]}
                onHelperClick={props.onHelperClick}
            />
        </>
    );
}
