import { Subject } from 'rxjs';
import i18n from 'i18next';
import { DriverBehaviorLightVehicleModel } from 'common/model/statistics';
import { DriverBehaviourLightVehiclesDriverInDb } from 'generated/backend-api';
import { Logic } from '../logic';
import { fromBlankAsync, Workbook } from 'xlsx-populate';
import { MONTH_YEAR_FORMAT } from 'domain-constants';
import moment from 'moment';
import { roundToStep } from 'common/utils/averages';
import { downloadFile, normalizeExportSheetName } from 'common/utils/fileUtils';

export class DriverBehaviorVehicles {
    private _data?: DriverBehaviorLightVehicleModel[];
    private _logic: Logic;

    data = new Subject<DriverBehaviorLightVehicleModel[]>();
    loading = new Subject<boolean>();
    error = new Subject<any>();
    exportListProcessing = new Subject<boolean>();
    exportDetailProcessing = new Subject<boolean>();
    date?: string;

    constructor(logic: Logic) {
        this._logic = logic;
    }

    getData() {
        return this._data;
    }

    getById(id: string) {
        return (this._data ?? []).find(e => e.id === id);
    }

    async loadData(date: string) {
        this.date = date;
        this.loading.next(true);

        if (this._logic.demo().isActive) {
            this._data = this._logic.demo().data.driverBehaviorVehicles;
        } else {
            try {
                const resp = await this._logic
                    .api()
                    .driverBehaviourLightVehiclesApi.getDriverBehaviourV1DriverbehaviourlightMyGet({
                        fromT: new Date(date)
                    });

                this._data = resp.act.map(score => {
                    const prevScore = resp.prev.find(ps => ps.driver?.id === score.driver?.id);
                    return this._toLightVehicleModel(score, resp.numberOfDrivers, prevScore);
                });
            } catch (err) {
                this.loading.next(false);
                this.error.next(err);
            }
        }
        this.data.next(this._data ?? []);
        this.loading.next(false);
    }

    selectWorstDrivers = () => {
        if (!this._data) return [];
        return [...this._data]
            .sort((a, b) => a.score - b.score)
            .map((d, index) => ({ ...d, rank: index + 1 }))
            .slice(0, 5);
    };

    selectTopDrivers = () => {
        if (!this._data) return [];
        return [...this._data]
            .sort((a, b) => b.score - a.score)
            .map((d, index) => ({ ...d, rank: index + 1 }))
            .slice(0, 5);
    };

    selectTopImproversDrivers = () => {
        if (!this._data) return [];
        const improvement = (newValue: number, oldValue: number) =>
            ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100;

        return this._data
            .filter(e => e.oldScore)
            .sort((a, b) => improvement(a.score, a.oldScore!) - improvement(b.score, b.oldScore!))
            .map((d, index) => ({ ...d, rank: index + 1 }))
            .slice(0, 5);
    };

    async downloadDriverBehaviorDetailExport(id?: string) {
        if (!id) {
            throw new Error('No id provided');
        }
        this.exportDetailProcessing.next(true);
        const data = this.getById(id);
        if (!data) {
            throw new Error(`No driver with id: ${id}`);
        }
        const workbook: Workbook = await fromBlankAsync();
        const sheet = workbook
            .sheet(0)
            .name(normalizeExportSheetName(`${i18n.t('DriverDetailModule.title')} - ${data.name}`));
        const dateStr = moment(this.date).format(MONTH_YEAR_FORMAT);

        const headDescription = [[i18n.t('DriverDetailModule.title'), '', data.name, '', dateStr], []];
        const statsGroupTitle = [[i18n.t('DriverScoreStats.title')]];
        const statsTitle = [
            [
                '',
                i18n.t('DriverBehaviorLightTable.cols.economyScore'),
                i18n.t('DriverScoreStats.ecologyScore'),
                i18n.t('DriverScoreStats.safetyScore'),
                i18n.t('DriverBehaviorDistanceChart.title'),
                i18n.t('DriveRank.title')
            ]
        ];
        const economyGroupTitle = [[i18n.t('DriverBehaviorLightTable.cols.economyScore')]];
        const economyTitle = [
            [
                '',
                i18n.t('DriverBehaviorVehicleDetail.options.economy.dangerousBrakingPenalty'),
                i18n.t('DriverBehaviorVehicleDetail.options.economy.cityAggresiveTakeoffPenalty'),
                i18n.t('DriverBehaviorVehicleDetail.options.economy.countryDangerousTurnPenalty'),
                i18n.t('DriverBehaviorVehicleDetail.options.economy.highwayDangerousTurnPenalty')
            ]
        ];
        const ecologyGroupTitle = [[i18n.t('DriverBehaviorVehicleDetail.ecology.title')]];
        const ecologyTitle = [['', i18n.t('DriverBehaviorVehicleDetail.options.ecology.ecologyScore')]];

        const safetyGroupTitle = [[i18n.t('DriverBehaviorVehicleDetail.safety.title')]];
        const safetyTitle = [['', i18n.t('DriverBehaviorVehicleDetail.options.safety.highwayDangerousSpeedPenalty')]];

        const getIncreaseInPercent = (newValue?: number, oldValue?: number) => {
            const value = newValue && oldValue ? ((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100 : 0;
            return value === 0 ? '-' : `${Math.round((value + Number.EPSILON) * 10) / 10}`;
        };
        const max =
            data &&
            Object.keys(data.distance)
                .map(key => ({
                    label: key,
                    value: data.distance[key]
                }))
                .find(d => d.value === Math.max(data.distance.city, data.distance.highway, data.distance.noCity));
        const sum = data && data.distance.city + data.distance.highway + data.distance.noCity;
        const statsGroupBody = [[roundToStep(data.score, 0.5)]];
        const statsBody = [
            [
                roundToStep(data.harshEventsScore, 0.5),
                roundToStep(data?.ecologyScore, 0.5),
                roundToStep(data?.safetyScore, 0.5),
                max ? `${Math.round((max.value / sum) * 100)}%` : '',
                data?.rank
            ]
        ];

        const economyGroupBody = [[]];
        const economyBody = [
            [
                roundToStep(data.detail.dangerousBrakingPenalty, 0.5),
                roundToStep(data.detail.cityAggresiveTakeoffPenalty, 0.5),
                roundToStep(data.detail.countryDangerousTurnPenalty, 0.5),
                roundToStep(data.detail.highwayDangerousTurnPenalty, 0.5)
            ]
        ];
        const economyIncreaseBody = [
            [
                getIncreaseInPercent(data.detail.dangerousBrakingPenalty, data.prevDetail?.dangerousBrakingPenalty),
                getIncreaseInPercent(
                    data.detail.cityAggresiveTakeoffPenalty,
                    data.prevDetail?.cityAggresiveTakeoffPenalty
                ),
                getIncreaseInPercent(
                    data.detail.countryDangerousTurnPenalty,
                    data.prevDetail?.countryDangerousTurnPenalty
                ),
                getIncreaseInPercent(
                    data.detail.highwayDangerousTurnPenalty,
                    data.prevDetail?.highwayDangerousTurnPenalty
                )
            ]
        ];

        const ecologyGroupBody = [[]];
        const ecologyBody = [[roundToStep(data.ecologyScore, 0.5)]];
        const ecologyIncreaseBody = [['-']]; // theres no prev info about ecology

        const safetyGroupBody = [[]];
        const safetyBody = [[roundToStep(data.detail.highwayDangerousSpeedPenalty, 0.5)]];
        const safetyIncreaseBody = [
            [
                getIncreaseInPercent(
                    data.detail.highwayDangerousSpeedPenalty,
                    data.prevDetail?.highwayDangerousSpeedPenalty
                )
            ]
        ];

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true,
            wrapText: true
        };
        const dataStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            },
            horizontalAlignment: 'center'
        };

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;

        const statsGroupTitleStartRow = 3;
        const statsGroupTitleEndRow = 3;
        const statsTitleStartRow = 4;
        const statsTitleEndRow = 4;
        const statsGroupBodyStartRow = 4;
        const statsGroupBodyEndRow = 4;
        const statsBodyStartRow = 5;
        const statsBodyEndRow = 5;

        const economyGroupTitleStartRow = 7;
        const economyGroupTitleEndRow = 7;
        const economyTitleStartRow = 8;
        const economyTitleEndRow = 8;
        const economyGroupBodyStartRow = 8;
        const economyGroupBodyEndRow = 8;
        const economyBodyStartRow = 9;
        const economyBodyEndRow = 9;
        const economyIncreaseBodyStartRow = 10;
        const economyIncreaseBodyEndRow = 10;

        const ecologyGroupTitleStartRow = 11;
        const ecologyGroupTitleEndRow = 11;
        const ecologyTitleStartRow = 12;
        const ecologyTitleEndRow = 12;
        const ecologyGroupBodyStartRow = 12;
        const ecologyGroupBodyEndRow = 12;
        const ecologyBodyStartRow = 13;
        const ecologyBodyEndRow = 13;
        const ecologyIncreaseBodyStartRow = 14;
        const ecologyIncreaseBodyEndRow = 14;

        const safetyGroupTitleStartRow = 15;
        const safetyGroupTitleEndRow = 15;
        const safetyTitleStartRow = 16;
        const safetyTitleEndRow = 16;
        const safetyGroupBodyStartRow = 16;
        const safetyGroupBodyEndRow = 16;
        const safetyBodyStartRow = 17;
        const safetyBodyEndRow = 17;
        const safetyIncreaseBodyStartRow = 18;
        const safetyIncreaseBodyEndRow = 18;

        const workbookDescriptionRange = sheet.range(descriptionStartRow, 1, descriptionEndRow, 6);

        const workbookStatsGroupTitleRange = sheet.range(statsGroupTitleStartRow, 1, statsGroupTitleEndRow, 6);
        const workbookStatsTitleRange = sheet.range(statsTitleStartRow, 1, statsTitleEndRow, 6);
        const workbookStatsGroupBodyRange = sheet.range(statsGroupBodyStartRow, 1, statsGroupBodyEndRow, 1);
        const workbookStatsBodyRange = sheet.range(statsBodyStartRow, 2, statsBodyEndRow, 6);

        const workbookEconomyGroupTitleRange = sheet.range(economyGroupTitleStartRow, 1, economyGroupTitleEndRow, 6);
        const workbookEconomyTitleRange = sheet.range(economyTitleStartRow, 1, economyTitleEndRow, 6);
        const workbookEconomyGroupBodyRange = sheet.range(economyGroupBodyStartRow, 1, economyGroupBodyEndRow, 1);
        const workbookEconomyBodyRange = sheet.range(economyBodyStartRow, 2, economyBodyEndRow, 6);
        const workbookEconomyIncreaseBodyRange = sheet.range(
            economyIncreaseBodyStartRow,
            2,
            economyIncreaseBodyEndRow,
            6
        );

        const workbookEcologyGroupTitleRange = sheet.range(ecologyGroupTitleStartRow, 1, ecologyGroupTitleEndRow, 6);
        const workbookEcologyTitleRange = sheet.range(ecologyTitleStartRow, 1, ecologyTitleEndRow, 6);
        const workbookEcologyGroupBodyRange = sheet.range(ecologyGroupBodyStartRow, 1, ecologyGroupBodyEndRow, 1);
        const workbookEcologyBodyRange = sheet.range(ecologyBodyStartRow, 2, ecologyBodyEndRow, 6);
        const workbookEcologyIncreaseBodyRange = sheet.range(
            ecologyIncreaseBodyStartRow,
            2,
            ecologyIncreaseBodyEndRow,
            6
        );

        const workbookSafetyGroupTitleRange = sheet.range(safetyGroupTitleStartRow, 1, safetyGroupTitleEndRow, 6);
        const workbookSafetyTitleRange = sheet.range(safetyTitleStartRow, 1, safetyTitleEndRow, 6);
        const workbookSafetyGroupBodyRange = sheet.range(safetyGroupBodyStartRow, 1, safetyGroupBodyEndRow, 1);
        const workbookSafetyBodyRange = sheet.range(safetyBodyStartRow, 2, safetyBodyEndRow, 6);
        const workbookSafetyIncreaseBodyRange = sheet.range(safetyIncreaseBodyStartRow, 2, safetyIncreaseBodyEndRow, 6);

        workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);

        workbookStatsGroupTitleRange.style(titleDescriptionStyle).value(statsGroupTitle);
        workbookStatsTitleRange.style(titleDescriptionStyle).value(statsTitle);
        workbookStatsGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(statsGroupBody);
        workbookStatsBodyRange.style(dataStyle).value(statsBody);

        workbookEconomyGroupTitleRange.style(titleDescriptionStyle).value(economyGroupTitle);
        workbookEconomyTitleRange.style(titleDescriptionStyle).value(economyTitle);
        workbookEconomyGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(economyGroupBody);
        workbookEconomyBodyRange.style(dataStyle).value(economyBody);
        workbookEconomyIncreaseBodyRange.style(dataStyle).value(economyIncreaseBody);

        workbookEcologyGroupTitleRange.style(titleDescriptionStyle).value(ecologyGroupTitle);
        workbookEcologyTitleRange.style(titleDescriptionStyle).value(ecologyTitle);
        workbookEcologyGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(ecologyGroupBody);
        workbookEcologyBodyRange.style(dataStyle).value(ecologyBody);
        workbookEcologyIncreaseBodyRange.style(dataStyle).value(ecologyIncreaseBody);

        workbookSafetyGroupTitleRange.style(titleDescriptionStyle).value(safetyGroupTitle);
        workbookSafetyTitleRange.style(titleDescriptionStyle).value(safetyTitle);
        workbookSafetyGroupBodyRange.style({ ...dataStyle, verticalAlignment: 'center' }).value(safetyGroupBody);
        workbookSafetyBodyRange.style(dataStyle).value(safetyBody);
        workbookSafetyIncreaseBodyRange.style(dataStyle).value(safetyIncreaseBody);

        [
            {
                body: economyIncreaseBody[0],
                startRow: economyIncreaseBodyStartRow
            },
            {
                body: ecologyGroupBody[0],
                startRow: ecologyIncreaseBodyStartRow
            },
            {
                body: safetyGroupBody[0],
                startRow: safetyIncreaseBodyStartRow
            }
        ].forEach(increase => {
            increase.body.forEach((val, index) => {
                // +2: data start from col 2
                const cell = sheet.row(increase.startRow).cell(index + 2);
                if (isNaN(parseFloat(val))) return;
                cell.style({ ...dataStyle, fontColor: parseFloat(val) > 0 ? '4caf50' : 'ff3823' });
            });
        });

        // header title
        sheet.range(1, 1, 1, 2).merged(true);
        sheet.range(1, 3, 1, 4).merged(true);

        // stats title
        // sheet.range(3, 1, 3, 6).merged(true);

        // first row group items
        sheet.range(4, 1, 5, 1).merged(true);
        sheet.range(8, 1, 10, 1).merged(true);
        sheet.range(12, 1, 14, 1).merged(true);
        sheet.range(16, 1, 18, 1).merged(true);

        [25, 20, 20, 20, 20, 20].forEach((width, i) => {
            sheet.column(i + 1).width(width);
        });

        sheet.row(8).height(15 * 3);
        sheet.row(16).height(15 * 2);
        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('DriverDetailModule.title')} - ${data?.name} ${dateStr}.xlsx`);
        this.exportListProcessing.next(false);
        this.exportDetailProcessing.next(false);
    }

    async downloadDriverBehaviorListExport() {
        this.exportListProcessing.next(true);
        const workbook: Workbook = await fromBlankAsync();
        const sheetLeadBoard = workbook.sheet(0).name(normalizeExportSheetName(i18n.t('DriverListModule.title')));
        const sheetTopDrivers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.topDrivers')), 1);
        const sheetTopImprovers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.topImprovers')), 2);
        const sheetWorstDrivers = workbook.addSheet(normalizeExportSheetName(i18n.t('DriverBehavior.worstDrivers')), 3);

        const allSheets = [sheetLeadBoard, sheetTopDrivers, sheetTopImprovers, sheetWorstDrivers];

        const dateStr = moment(this.date).format(MONTH_YEAR_FORMAT);
        const headDescription = [[i18n.t('DriverListModule.title'), '', dateStr], []];
        const leaderBoardHead = [
            [
                i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                i18n.t('DriverBehaviorLightTable.cols.name').toUpperCase(),
                i18n.t('DriverBehaviorLightTable.cols.score').toUpperCase(),
                i18n.t('DriverBehaviorLightTable.cols.economyScore').toUpperCase(),
                i18n.t('DriverBehaviorLightTable.cols.ecologyScore').toUpperCase(),
                i18n.t('DriverBehaviorLightTable.cols.safetyScore').toUpperCase()
            ]
        ];

        const top5Head = [
            [
                i18n.t('DriverBehaviorTable.cols.rank').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.name').toUpperCase(),
                i18n.t('DriverBehaviorTable.cols.score').toUpperCase()
            ]
        ];

        const titleDescriptionStyle = {
            fill: {
                color: 'FFFFFF',
                type: 'solid'
            },
            horizontalAlignment: 'center',
            bold: true
        };
        const dataStyle = {
            fill: {
                color: 'bdbdbd',
                type: 'solid'
            },
            horizontalAlignment: 'center'
        };

        const leaderBoardBody: any[][] = [];
        this._data?.forEach(driverBehavior => {
            leaderBoardBody.push([
                driverBehavior.rank,
                driverBehavior.name,
                roundToStep(driverBehavior.score, 0.1),
                roundToStep(driverBehavior.harshEventsScore, 0.1),
                roundToStep(driverBehavior.ecologyScore, 0.1),
                roundToStep(driverBehavior.safetyScore, 0.1)
            ]);
        });
        const topDriversBody: any[][] = [];
        this.selectTopDrivers().forEach(driverBehavior => {
            topDriversBody.push([driverBehavior.rank, driverBehavior.name, roundToStep(driverBehavior.score, 0.1)]);
        });
        const topImproversDriversBody: any[][] = [];
        this.selectTopImproversDrivers().forEach(driverBehavior => {
            topImproversDriversBody.push([
                driverBehavior.rank,
                driverBehavior.name,
                roundToStep(driverBehavior.score, 0.1)
            ]);
        });
        const worstDriversBody: any[][] = [];
        this.selectWorstDrivers().forEach(driverBehavior => {
            worstDriversBody.push([driverBehavior.rank, driverBehavior.name, roundToStep(driverBehavior.score, 0.1)]);
        });

        const descriptionStartRow = 1;
        const descriptionEndRow = 2;

        const tableTitleStartRow = descriptionEndRow + 1;
        const tableTitleEndRow = tableTitleStartRow;

        allSheets.forEach((sheet, index) => {
            sheet.range(1, 1, 1, 2).merged(true);
            let workbookDescriptionRange;
            let workbookTableTitleRange;
            if (index === 0) {
                workbookDescriptionRange = sheet.range(descriptionStartRow, 1, tableTitleEndRow, 6);
                workbookTableTitleRange = sheet.range(tableTitleStartRow, 1, tableTitleEndRow, 6);
                [15, 25, 20, 20, 20, 20].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            } else {
                workbookDescriptionRange = sheet.range(descriptionStartRow, 1, tableTitleEndRow, 3);
                workbookTableTitleRange = sheet.range(tableTitleStartRow, 1, tableTitleEndRow, 3);
                [15, 25, 15].forEach((width, i) => {
                    sheet.column(i + 1).width(width);
                });
            }

            workbookDescriptionRange.style(titleDescriptionStyle).value(headDescription);
            workbookTableTitleRange.style({ ...titleDescriptionStyle, bottomBorder: true });

            if (index === 0) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + leaderBoardBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 6);

                workbookTableTitleRange.value(leaderBoardHead);
                workbookTableBodyRange.style(dataStyle).value(leaderBoardBody);
            } else if (index === 1) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + topDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(topDriversBody);
            } else if (index === 2) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + topImproversDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(topImproversDriversBody);
            } else if (index === 3) {
                const tableDataStartRow = tableTitleEndRow + 1;
                const tableDataEndRow = tableDataStartRow + worstDriversBody.length - 1;
                const workbookTableBodyRange = sheet.range(tableDataStartRow, 1, tableDataEndRow, 3);

                workbookTableTitleRange.value(top5Head);
                workbookTableBodyRange.style(dataStyle).value(worstDriversBody);
            }
        });

        // create and download file
        const blob = (await workbook.outputAsync()) as Blob;
        downloadFile(blob, `${i18n.t('DriverListModule.title')} ${dateStr}.xlsx`);
        this.exportListProcessing.next(false);
    }

    private _toLightVehicleModel(
        detail: DriverBehaviourLightVehiclesDriverInDb,
        totalDrivers: number,
        prevDetail?: DriverBehaviourLightVehiclesDriverInDb
    ): DriverBehaviorLightVehicleModel {
        return {
            id: detail.id,
            rank: detail.rank,
            score: detail.score.overall,
            oldScore: prevDetail?.score.overall,
            harshEventsScore: detail.score.economy,
            ecologyScore: detail.score.ecology,
            safetyScore: detail.score.safety,
            totalDrivers,
            name: `${detail.driver?.name} ${detail.driver?.surname}`,
            driverId: detail.driver?.id,
            detail: {
                ...detail.ratings,
                dangerousBrakingPenalty:
                    (detail.ratings.cityDangerousBreakingPenalty +
                        detail.ratings.highwayDangerousBreakingPenalty +
                        detail.ratings.countryDangerousBreakingPenalty) /
                    3
            },
            prevDetail: prevDetail?.ratings
                ? {
                      ...prevDetail.ratings,
                      dangerousBrakingPenalty:
                          (detail.ratings.cityDangerousBreakingPenalty +
                              detail.ratings.highwayDangerousBreakingPenalty +
                              detail.ratings.countryDangerousBreakingPenalty) /
                          3
                  }
                : undefined,
            distance: {
                city: detail.sumCityDuration,
                highway: detail.sumHighwayDuration,
                noCity: detail.sumCountryDuration,
                other: 0,
                summary: 0
            }
        };
    }
}
