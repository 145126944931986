/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReadOnlyDiemsRatesCountry,
    ReadOnlyDiemsRatesCountryFromJSON,
    ReadOnlyDiemsRatesCountryFromJSONTyped,
    ReadOnlyDiemsRatesCountryToJSON,
    ReadOnlyDiemsRatesCurrency,
    ReadOnlyDiemsRatesCurrencyFromJSON,
    ReadOnlyDiemsRatesCurrencyFromJSONTyped,
    ReadOnlyDiemsRatesCurrencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReadOnlyDiemsRates
 */
export interface ReadOnlyDiemsRates {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDiemsRates
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    readonly deleted?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    intervalFrom: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    intervalTo: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    replacementByMealsRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDiemsRates
     */
    replacementByMealsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRates
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRates
     */
    end: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyDiemsRates
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {ReadOnlyDiemsRatesCountry}
     * @memberof ReadOnlyDiemsRates
     */
    country?: ReadOnlyDiemsRatesCountry;
    /**
     * 
     * @type {ReadOnlyDiemsRatesCountry}
     * @memberof ReadOnlyDiemsRates
     */
    foreignCountry?: ReadOnlyDiemsRatesCountry;
    /**
     * 
     * @type {ReadOnlyDiemsRatesCurrency}
     * @memberof ReadOnlyDiemsRates
     */
    currency?: ReadOnlyDiemsRatesCurrency;
}

export function ReadOnlyDiemsRatesFromJSON(json: any): ReadOnlyDiemsRates {
    return ReadOnlyDiemsRatesFromJSONTyped(json, false);
}

export function ReadOnlyDiemsRatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyDiemsRates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'rate': json['rate'],
        'intervalFrom': json['interval_from'],
        'intervalTo': json['interval_to'],
        'replacementByMealsRate': json['replacement_by_meals_rate'],
        'replacementByMealsCount': json['replacement_by_meals_count'],
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'country': !exists(json, 'country') ? undefined : ReadOnlyDiemsRatesCountryFromJSON(json['country']),
        'foreignCountry': !exists(json, 'foreign_country') ? undefined : ReadOnlyDiemsRatesCountryFromJSON(json['foreign_country']),
        'currency': !exists(json, 'currency') ? undefined : ReadOnlyDiemsRatesCurrencyFromJSON(json['currency']),
    };
}

export function ReadOnlyDiemsRatesToJSON(value?: ReadOnlyDiemsRates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rate': value.rate,
        'interval_from': value.intervalFrom,
        'interval_to': value.intervalTo,
        'replacement_by_meals_rate': value.replacementByMealsRate,
        'replacement_by_meals_count': value.replacementByMealsCount,
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
        'country': ReadOnlyDiemsRatesCountryToJSON(value.country),
        'foreign_country': ReadOnlyDiemsRatesCountryToJSON(value.foreignCountry),
        'currency': ReadOnlyDiemsRatesCurrencyToJSON(value.currency),
    };
}


