import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { InputField } from 'common/fields';
import { Button } from 'common/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import qa from 'qa-selectors';

export interface UserRoleFormModel {
    label?: string;
    description?: string | null;
}

interface Props extends WithTranslation {
    initialValues?: Partial<UserRoleFormModel>;
    onSubmit?: (values: UserRoleFormModel) => Promise<boolean>;
    onCancel?: () => void;
}

function UserRoleForm(props: Props) {
    const schema = Yup.object().shape({
        label: Yup.string().required(props.t('common.required')),
        description: Yup.string()
    });

    function handleSubmit(values: UserRoleFormModel) {
        return props.onSubmit?.(values);
    }

    return (
        <Formik<UserRoleFormModel>
            initialValues={{
                description: props.initialValues?.description,
                label: props.initialValues?.label
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<UserRoleFormModel>) => {
                return (
                    <Form className="user-role-form">
                        <Row className="form-row" gutter={[10, 10]}>
                            <Col xl={24} xxl={6}>
                                {props.t('common.label')}
                            </Col>
                            <Col xl={24} xxl={18}>
                                <InputField
                                    name="label"
                                    placeholder={props.t('common.label')}
                                    qa={qa.roles.detail.inputLabel}
                                />
                            </Col>
                        </Row>
                        <Row className="form-row" gutter={[10, 10]}>
                            <Col xl={24} xxl={6}>
                                {props.t('common.description')}
                            </Col>
                            <Col xl={24} xxl={18}>
                                <InputField
                                    name="description"
                                    placeholder={props.t('common.description')}
                                    qa={qa.roles.detail.inputDescription}
                                />
                            </Col>
                        </Row>

                        <Row className="form-buttons">
                            <Button onClick={props.onCancel} qa={qa.roles.detail.btnFormCancel}>
                                {props.t('common.cancel')}
                            </Button>
                            <Button
                                loading={formik.isSubmitting}
                                type="primary"
                                onClick={formik.submitForm}
                                qa={qa.roles.detail.btnFormSubmit}
                            >
                                {props.t('common.confirm')}
                            </Button>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(UserRoleForm);
