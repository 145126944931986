/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RawDataPointFuelConsumptionModel
 */
export interface RawDataPointFuelConsumptionModel {
    /**
     * 
     * @type {string}
     * @memberof RawDataPointFuelConsumptionModel
     */
    type: RawDataPointFuelConsumptionModelTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof RawDataPointFuelConsumptionModel
     */
    time: Date;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointFuelConsumptionModel
     */
    raw: number;
    /**
     * 
     * @type {number}
     * @memberof RawDataPointFuelConsumptionModel
     */
    liters?: number;
}

export function RawDataPointFuelConsumptionModelFromJSON(json: any): RawDataPointFuelConsumptionModel {
    return RawDataPointFuelConsumptionModelFromJSONTyped(json, false);
}

export function RawDataPointFuelConsumptionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawDataPointFuelConsumptionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'time': (new Date(json['time'])),
        'raw': json['raw'],
        'liters': !exists(json, 'liters') ? undefined : json['liters'],
    };
}

export function RawDataPointFuelConsumptionModelToJSON(value?: RawDataPointFuelConsumptionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'time': (value.time.toISOString()),
        'raw': value.raw,
        'liters': value.liters,
    };
}

/**
* @export
* @enum {string}
*/
export enum RawDataPointFuelConsumptionModelTypeEnum {
    FuelConsumption = 'fuel_consumption'
}


