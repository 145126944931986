/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: mojmir.studenovsky@eurowag.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Accessories {
    Lift = 'lift',
    TruckCrane = 'truck_crane',
    TirCable = 'tir_cable',
    Coilmulde = 'coilmulde',
    TrackingSystem = 'tracking_system',
    WalkingFloor = 'walking-floor',
    HookLift = 'hook-lift',
    SwapBodySystem = 'swap-body-system',
    Spacious = 'spacious'
}

export function AccessoriesFromJSON(json: any): Accessories {
    return AccessoriesFromJSONTyped(json, false);
}

export function AccessoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Accessories {
    return json as Accessories;
}

export function AccessoriesToJSON(value?: Accessories | null): any {
    return value as any;
}

