/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20042,
    InlineResponse20042FromJSON,
    InlineResponse20042ToJSON,
    ReadOnlyUserHasMonitoredObjectGroup,
    ReadOnlyUserHasMonitoredObjectGroupFromJSON,
    ReadOnlyUserHasMonitoredObjectGroupToJSON,
    UserHasMonitoredObjectGroup,
    UserHasMonitoredObjectGroupFromJSON,
    UserHasMonitoredObjectGroupToJSON,
    UserHasMonitoredObjectGroupMany,
    UserHasMonitoredObjectGroupManyFromJSON,
    UserHasMonitoredObjectGroupManyToJSON,
    WriteOnlyUserHasMonitoredObjectGroup,
    WriteOnlyUserHasMonitoredObjectGroupFromJSON,
    WriteOnlyUserHasMonitoredObjectGroupToJSON,
} from '../models';

export interface UserHasMonitoredObjectGroupCreateRequest {
    data: WriteOnlyUserHasMonitoredObjectGroup;
}

export interface UserHasMonitoredObjectGroupCreateManyRequest {
    data: UserHasMonitoredObjectGroupMany;
}

export interface UserHasMonitoredObjectGroupDeleteRequest {
    id: string;
}

export interface UserHasMonitoredObjectGroupListRequest {
    limit?: number;
    offset?: number;
}

export interface UserHasMonitoredObjectGroupPartialUpdateRequest {
    id: string;
    data: WriteOnlyUserHasMonitoredObjectGroup;
}

export interface UserHasMonitoredObjectGroupReadRequest {
    id: string;
}

export interface UserHasMonitoredObjectGroupRemoveManyRequest {
    data: UserHasMonitoredObjectGroupMany;
}

export interface UserHasMonitoredObjectGroupUpdateRequest {
    id: string;
    data: WriteOnlyUserHasMonitoredObjectGroup;
}

/**
 * no description
 */
export class UserHasMonitoredObjectGroupApi extends runtime.BaseAPI {

    /**
     */
    async userHasMonitoredObjectGroupCreateRaw(requestParameters: UserHasMonitoredObjectGroupCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserHasMonitoredObjectGroup>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userHasMonitoredObjectGroupCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserHasMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserHasMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async userHasMonitoredObjectGroupCreate(requestParameters: UserHasMonitoredObjectGroupCreateRequest): Promise<ReadOnlyUserHasMonitoredObjectGroup> {
        const response = await this.userHasMonitoredObjectGroupCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupCreateManyRaw(requestParameters: UserHasMonitoredObjectGroupCreateManyRequest): Promise<runtime.ApiResponse<Array<UserHasMonitoredObjectGroup>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userHasMonitoredObjectGroupCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/create-many/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserHasMonitoredObjectGroupManyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserHasMonitoredObjectGroupFromJSON));
    }

    /**
     */
    async userHasMonitoredObjectGroupCreateMany(requestParameters: UserHasMonitoredObjectGroupCreateManyRequest): Promise<Array<UserHasMonitoredObjectGroup>> {
        const response = await this.userHasMonitoredObjectGroupCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupDeleteRaw(requestParameters: UserHasMonitoredObjectGroupDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userHasMonitoredObjectGroupDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userHasMonitoredObjectGroupDelete(requestParameters: UserHasMonitoredObjectGroupDeleteRequest): Promise<void> {
        await this.userHasMonitoredObjectGroupDeleteRaw(requestParameters);
    }

    /**
     */
    async userHasMonitoredObjectGroupListRaw(requestParameters: UserHasMonitoredObjectGroupListRequest): Promise<runtime.ApiResponse<InlineResponse20042>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20042FromJSON(jsonValue));
    }

    /**
     */
    async userHasMonitoredObjectGroupList(requestParameters: UserHasMonitoredObjectGroupListRequest): Promise<InlineResponse20042> {
        const response = await this.userHasMonitoredObjectGroupListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupPartialUpdateRaw(requestParameters: UserHasMonitoredObjectGroupPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserHasMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userHasMonitoredObjectGroupPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userHasMonitoredObjectGroupPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserHasMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserHasMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async userHasMonitoredObjectGroupPartialUpdate(requestParameters: UserHasMonitoredObjectGroupPartialUpdateRequest): Promise<ReadOnlyUserHasMonitoredObjectGroup> {
        const response = await this.userHasMonitoredObjectGroupPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupReadRaw(requestParameters: UserHasMonitoredObjectGroupReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserHasMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userHasMonitoredObjectGroupRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserHasMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async userHasMonitoredObjectGroupRead(requestParameters: UserHasMonitoredObjectGroupReadRequest): Promise<ReadOnlyUserHasMonitoredObjectGroup> {
        const response = await this.userHasMonitoredObjectGroupReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupRemoveManyRaw(requestParameters: UserHasMonitoredObjectGroupRemoveManyRequest): Promise<runtime.ApiResponse<Array<UserHasMonitoredObjectGroup>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userHasMonitoredObjectGroupRemoveMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/remove-many/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UserHasMonitoredObjectGroupManyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserHasMonitoredObjectGroupFromJSON));
    }

    /**
     */
    async userHasMonitoredObjectGroupRemoveMany(requestParameters: UserHasMonitoredObjectGroupRemoveManyRequest): Promise<Array<UserHasMonitoredObjectGroup>> {
        const response = await this.userHasMonitoredObjectGroupRemoveManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userHasMonitoredObjectGroupUpdateRaw(requestParameters: UserHasMonitoredObjectGroupUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserHasMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userHasMonitoredObjectGroupUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userHasMonitoredObjectGroupUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-has-monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserHasMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserHasMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async userHasMonitoredObjectGroupUpdate(requestParameters: UserHasMonitoredObjectGroupUpdateRequest): Promise<ReadOnlyUserHasMonitoredObjectGroup> {
        const response = await this.userHasMonitoredObjectGroupUpdateRaw(requestParameters);
        return await response.value();
    }

}
