/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DrivingModeReportSummary,
    DrivingModeReportSummaryFromJSON,
    DrivingModeReportSummaryFromJSONTyped,
    DrivingModeReportSummaryToJSON,
    DrivingModeReportType,
    DrivingModeReportTypeFromJSON,
    DrivingModeReportTypeFromJSONTyped,
    DrivingModeReportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DrivingMode
 */
export interface DrivingMode {
    /**
     * 
     * @type {DrivingModeReportType}
     * @memberof DrivingMode
     */
    drivingModeReportType1: DrivingModeReportType;
    /**
     * 
     * @type {DrivingModeReportType}
     * @memberof DrivingMode
     */
    drivingModeReportType2: DrivingModeReportType;
    /**
     * 
     * @type {DrivingModeReportType}
     * @memberof DrivingMode
     */
    drivingModeReportType3: DrivingModeReportType;
    /**
     * 
     * @type {DrivingModeReportType}
     * @memberof DrivingMode
     */
    drivingModeReportType4: DrivingModeReportType;
    /**
     * 
     * @type {DrivingModeReportSummary}
     * @memberof DrivingMode
     */
    drivingModeReportSummary: DrivingModeReportSummary;
}

export function DrivingModeFromJSON(json: any): DrivingMode {
    return DrivingModeFromJSONTyped(json, false);
}

export function DrivingModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrivingMode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drivingModeReportType1': DrivingModeReportTypeFromJSON(json['driving_mode_report_type_1']),
        'drivingModeReportType2': DrivingModeReportTypeFromJSON(json['driving_mode_report_type_2']),
        'drivingModeReportType3': DrivingModeReportTypeFromJSON(json['driving_mode_report_type_3']),
        'drivingModeReportType4': DrivingModeReportTypeFromJSON(json['driving_mode_report_type_4']),
        'drivingModeReportSummary': DrivingModeReportSummaryFromJSON(json['driving_mode_report_summary']),
    };
}

export function DrivingModeToJSON(value?: DrivingMode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driving_mode_report_type_1': DrivingModeReportTypeToJSON(value.drivingModeReportType1),
        'driving_mode_report_type_2': DrivingModeReportTypeToJSON(value.drivingModeReportType2),
        'driving_mode_report_type_3': DrivingModeReportTypeToJSON(value.drivingModeReportType3),
        'driving_mode_report_type_4': DrivingModeReportTypeToJSON(value.drivingModeReportType4),
        'driving_mode_report_summary': DrivingModeReportSummaryToJSON(value.drivingModeReportSummary),
    };
}


