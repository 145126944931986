import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button } from '../../components';
import { InputField, SelectField } from '../../fields';
import qa from 'qa-selectors';
import { ReadOnlyContactList, ReadOnlyCountry } from 'generated/new-main';
import { ClientContactForm, ContactList } from 'common/model/client-contact';

interface Props extends WithTranslation {
    type?: 'default' | 'edit';
    size?: 'default' | 'small';
    initialClientContact?: Partial<ReadOnlyContactList>;
    countries: ReadOnlyCountry[];
    demoMode?: boolean;
    onSubmit: (values: ContactList) => Promise<boolean>;
    onCancel?: () => void;
    isClient?: boolean;
}

function ClientForm({ onSubmit, initialClientContact, t, onCancel, type, countries, demoMode, isClient, size }: Props) {
    const countriesOptions = countries.map(country => {
        return {
            value: country.id,
            label: country.name
        };
    });

    const supplierSchema = Yup.object().shape(
        {
            name: Yup.string().required(t('common.required')),

            businessId: Yup.string(),
            vat: Yup.string(),
            taxId: Yup.string(),
            otherIdentificationNumber: Yup.string(),
            countryId: Yup.number(),
            city: Yup.string(),
            postalCode: Yup.string(),
            street: Yup.string(),
            houseNumber: Yup.string(),
            premisesNumber: Yup.string()
        },
        [
            ['otherIdentificationNumber', 'taxId'],
            ['otherIdentificationNumber', 'vat'],
            ['otherIdentificationNumber', 'businessId'],
            ['taxId', 'vat'],
            ['taxId', 'businessId'],
            ['vat', 'businessId']
        ]
    );

    const clientSchema = Yup.object().shape(
        {
            name: Yup.string().required(t('common.required')),

            businessId: Yup.string().when(['vat', 'taxId', 'otherIdentificationNumber'], {
                is: (vat: string, taxId: string, otherIdentificationNumber: string) => {
                    return !vat && !taxId && !otherIdentificationNumber;
                },
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            vat: Yup.string().when(['businessId', 'taxId', 'otherIdentificationNumber'], {
                is: (businessId: string, taxId: string, otherIdentificationNumber: string) => {
                    return !businessId && !taxId && !otherIdentificationNumber;
                },
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            taxId: Yup.string().when(['vat', 'businessId', 'otherIdentificationNumber'], {
                is: (vat: string, businessId: string, otherIdentificationNumber: string) => {
                    return !vat && !businessId && !otherIdentificationNumber;
                },
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            otherIdentificationNumber: Yup.string().when(['vat', 'businessId', 'taxId'], {
                is: (vat: string, businessId: string, taxId: string) => {
                    return !vat && !businessId && !taxId;
                },
                then: Yup.string().required(t('common.required')),
                otherwise: Yup.string().notRequired()
            }),
            countryId: Yup.number().required(t('common.required')),
            city: Yup.string(),
            postalCode: Yup.string(),
            street: Yup.string(),
            houseNumber: Yup.string(),
            premisesNumber: Yup.string()
        },
        [
            ['otherIdentificationNumber', 'taxId'],
            ['otherIdentificationNumber', 'vat'],
            ['otherIdentificationNumber', 'businessId'],
            ['taxId', 'vat'],
            ['taxId', 'businessId'],
            ['vat', 'businessId']
        ]
    );

    const onClientContactFormSubmit = (clientContact: ClientContactForm) => {
        return onSubmit?.({
            ...clientContact,
            country: countries.find(c => c.id === clientContact.countryId)
        });
    };

    const formCols = {
        basicInformation: {
            title: 24,
            name: size === 'small' ? 24 : 6,
            businessId: size === 'small' ? 12 : 5,
            vat: size === 'small' ? 12 : 5,
            taxId: size === 'small' ? 12 : 4,
            otherIdentificationNumber: size === 'small' ? 12 : 4
        },
        addressInformation: {
            title: 24,
            countryId: size === 'small' ? 12 : 8,
            city: size === 'small' ? 12 : 8,
            postalCode: 8,
            street: size === 'small' ? 16 : 8,
            houseNumber: size === 'small' ? 12 : 8,
            premisesNumber: size === 'small' ? 12 : 8
        }
    };

    return (
        <Formik<ClientContactForm>
            initialValues={{
                name: initialClientContact?.name ?? '',
                businessId: initialClientContact?.businessId ?? '',
                vat: initialClientContact?.vat ?? '',
                taxId: initialClientContact?.taxId ?? '',
                otherIdentificationNumber: initialClientContact?.otherIdentificationNumber ?? '',

                countryId: initialClientContact?.country?.id,
                city: initialClientContact?.city ?? '',
                postalCode: initialClientContact?.postalCode ?? '',
                street: initialClientContact?.street ?? '',
                houseNumber: initialClientContact?.houseNumber ?? '',
                premisesNumber: initialClientContact?.premisesNumber ?? ''
            }}
            onSubmit={onClientContactFormSubmit}
            validationSchema={isClient ? clientSchema : supplierSchema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<ClientContactForm>) => (
                <div className="client-form">
                    <Form>
                        <Row gutter={[20, 10]} className="form-row form-row-title">
                            <Col span={formCols.basicInformation.title}>
                                <span>{t('ClientContactListForm.basicInformationTitle')}</span>
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row form-row-content form-row-content-basic-information">
                            <Col span={formCols.basicInformation.name}>
                                <InputField
                                    size="large"
                                    name="name"
                                    label={t('ClientContactListForm.companyName')}
                                    placeholder={t('ClientContactListForm.companyName')}
                                />
                            </Col>
                            <Col span={formCols.basicInformation.businessId}>
                                <InputField
                                    size="large"
                                    name="businessId"
                                    label={t('ClientContactListForm.businessId')}
                                    placeholder={t('ClientContactListForm.businessId')}
                                />
                            </Col>
                            <Col span={formCols.basicInformation.vat}>
                                <InputField
                                    size="large"
                                    name="vat"
                                    label={t('ClientContactListForm.vat')}
                                    placeholder={t('ClientContactListForm.vat')}
                                />
                            </Col>
                            <Col span={formCols.basicInformation.taxId}>
                                <InputField
                                    size="large"
                                    name="taxId"
                                    label={t('ClientContactListForm.taxId')}
                                    placeholder={t('ClientContactListForm.taxId')}
                                />
                            </Col>
                            <Col span={formCols.basicInformation.otherIdentificationNumber}>
                                <InputField
                                    size="large"
                                    name="otherIdentificationNumber"
                                    label={t('ClientContactListForm.otherIdentificationNumber')}
                                    placeholder={t('ClientContactListForm.otherIdentificationNumber')}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row form-row-title">
                            <Col span={formCols.addressInformation.title}>
                                {t('ClientContactListForm.addressOfResidenceTitle')}
                            </Col>
                        </Row>

                        <Row gutter={[20, 10]} className="form-row">
                            <Col span={formCols.addressInformation.countryId}>
                                <SelectField
                                    //@ts-ignore
                                    autoComplete="nope"
                                    size="large"
                                    // @ts-ignore
                                    options={countriesOptions}
                                    name="countryId"
                                    label={t('ClientContactListForm.country')}
                                    placeholder={t('ClientContactListForm.country')}
                                    showSearch
                                />
                            </Col>
                            <Col span={formCols.addressInformation.city}>
                                <InputField
                                    size="large"
                                    name="city"
                                    label={t('ClientContactListForm.city')}
                                    placeholder={t('ClientContactListForm.city')}
                                />
                            </Col>
                            <Col span={formCols.addressInformation.postalCode}>
                                <InputField
                                    size="large"
                                    name="postalCode"
                                    label={t('ClientContactListForm.postalCode')}
                                    placeholder={t('ClientContactListForm.postalCode')}
                                />
                            </Col>

                            <Col span={formCols.addressInformation.street}>
                                <InputField
                                    size="large"
                                    name="street"
                                    label={t('ClientContactListForm.street')}
                                    placeholder={t('ClientContactListForm.street')}
                                />
                            </Col>
                            <Col span={formCols.addressInformation.houseNumber}>
                                <InputField
                                    size="large"
                                    name="houseNumber"
                                    label={t('ClientContactListForm.houseNumber')}
                                    placeholder={t('ClientContactListForm.houseNumber')}
                                />
                            </Col>
                            <Col span={formCols.addressInformation.premisesNumber}>
                                <InputField
                                    size="large"
                                    name="premisesNumber"
                                    label={t('ClientContactListForm.premisesNumber')}
                                    placeholder={t('ClientContactListForm.premisesNumber')}
                                />
                            </Col>
                        </Row>

                        <Row justify="end" gutter={12}>
                            <Col>
                                <Button onClick={onCancel} qa={qa.planner.createVehicleProfile.btnCancel}>
                                    {t('common.cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                    qa={qa.planner.createVehicleProfile.btnSubmit}
                                    disabled={demoMode}
                                >
                                    {type === 'edit' ? t('common.edit') : t('common.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default withTranslation()(ClientForm);
