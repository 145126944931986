/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * Auto-generated openapi scheme for main-data-new API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyDriverApplication
 */
export interface WriteOnlyDriverApplication {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyDriverApplication
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDriverApplication
     */
    pushId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDriverApplication
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyDriverApplication
     */
    imei?: string | null;
}

export function WriteOnlyDriverApplicationFromJSON(json: any): WriteOnlyDriverApplication {
    return WriteOnlyDriverApplicationFromJSONTyped(json, false);
}

export function WriteOnlyDriverApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyDriverApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'pushId': !exists(json, 'push_id') ? undefined : json['push_id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
    };
}

export function WriteOnlyDriverApplicationToJSON(value?: WriteOnlyDriverApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'push_id': value.pushId,
        'version': value.version,
        'imei': value.imei,
    };
}


