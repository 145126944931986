import React from 'react';
import { SelectValue } from 'antd/lib/select';
import { WithTranslation, withTranslation } from 'react-i18next';

import { PartnerCompanySelectModel } from 'logic/partner/logic/partner-partners';
import { Select } from 'common/components/Form';
import { Button } from 'common/components';

import { debounce } from '../../../debounce';
import { Row } from 'antd';
import Col from 'antd/es/grid/col';
import qa from 'qa-selectors';

interface Props extends WithTranslation {
    selectedCompany?: PartnerCompanySelectModel;
    companies?: PartnerCompanySelectModel[];
    onCompanyFilterChange?: (id: string) => void;
    onCompanyFilterSearch?: (text: string) => void;
    onCompanyFilterCancel?: () => void;
}

function PartnerTableBar(props: Props) {
    const { t } = props;

    function onCompanyFilterChange(value: SelectValue) {
        props.onCompanyFilterChange?.(value as string);
    }
    function onCompanyFilterCancel() {
        props.onCompanyFilterCancel?.();
    }

    const onCompanySearch = debounce((text: string) => {
        props.onCompanyFilterSearch?.(text);
    }, 4e2);

    return (
        <span className="partner-bar">
            <Row gutter={10}>
                {props.companies && (
                    <Col>
                        <Select
                            showSearch
                            placeholder={t('common.select')}
                            size="large"
                            value={props.selectedCompany?.id ?? undefined}
                            filterOption={false}
                            options={props.companies.map(v => ({
                                value: v.id,
                                label: v.label,
                                qa: qa.partner.filter.optionPartner
                            }))}
                            qa={qa.partner.filter.selectPartner}
                            onChange={onCompanyFilterChange}
                            onSearch={onCompanySearch}
                        />
                    </Col>
                )}
                {props.companies && props.selectedCompany && (
                    <Col>
                        <Button
                            type="link"
                            icon={
                                <img src="/img-svg/icn-close.svg" alt={t('common.cancel')} title={t('common.cancel')} />
                            }
                            qa={qa.partner.filter.btnUnselectPartner}
                            onClick={onCompanyFilterCancel}
                        />
                    </Col>
                )}
            </Row>
        </span>
    );
}

export default withTranslation()(PartnerTableBar);
