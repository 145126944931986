import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import { SearchData } from '../RouteOverviewModule';

interface OwnProps {
    search?: SearchData;
    onSubmit?: (data: SearchData) => void;
}

type Props = OwnProps & WithTranslation;

function RouteOverviewSearch(props: Props) {
    const { t } = props;

    const buttonsText = [
        t('common.all'),
        t('RouteOverviewSearch.vehicles'),
        t('RouteOverviewSearch.drivers'),
        t('RouteOverviewSearch.position')
    ];

    function onInputLostFocus(e: FocusEvent<HTMLInputElement>) {
        const text = e.currentTarget.value;
        props.onSubmit?.({ ...props.search, text }); // debounce
    }

    function onInputChange(e: ChangeEvent<HTMLInputElement>) {
        const text = e.currentTarget.value;
        props.onSubmit?.({ ...props.search, text }); // debounce
    }

    function onEnter(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            const text = e.currentTarget.value;
            props.onSubmit?.({ ...props.search, text }); // debounce
        }
    }

    function onSearchTypeClick(type: number) {
        props.onSubmit?.({ ...props.search, type }); // debounce
    }

    const { text, type } = props.search!;

    return (
        <div className="route-overview-search t-small t-right t-third t-default t-border">
            <div className="t-row t-padding t-center">
                <input
                    className="t-input"
                    type="text"
                    name="search"
                    placeholder={`${t('common.search')}...`}
                    defaultValue={text}
                    onKeyDown={onEnter}
                    onChange={onInputChange}
                    onBlur={onInputLostFocus}
                />
            </div>
            <div className="t-row t-padding t-center">
                {buttonsText.map((text, i) => (
                    <div key={i} className="t-quarter t-row-padding">
                        <button
                            className={cn(
                                't-btn',
                                't-ripple',
                                't-hover-secondary',
                                type === i ? 't-primary' : 't-light-secondary'
                            )}
                            onClick={onSearchTypeClick.bind(undefined, i)}
                        >
                            {text}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default withTranslation()(RouteOverviewSearch);
